import React, { useMemo, useState } from 'react';
import { css } from '@emotion/core';
import TaxRateCaptureSettingDomain from 'domain/master/sales/taxRateCaptureSetting';
import { Column } from 'react-table';
import DataTable from 'components/organismos/DataTable/DataTable';
import { useOrgTreesByCategoryOptions } from 'hooks/useOrgTreesOptions';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import { Link } from 'react-router-dom';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Select from 'components/atoms/Select';
// import PageTitle from 'components/atoms/PageTitle';
import TaxRateCaptureSettingAddForm from './TaxRateCaptureSettingAddForm';
import useTaxRateList from './hooks';

const TaxRateCaptureSettingForm = () => {
  if (sessionStorage.getItem('selectedOrgCode') === null) {
    sessionStorage.setItem('selectedOrgCode', sessionStorage.getItem('loginUser.orgCode')!);
  }
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('selectedOrgCode')!);
  const [isCreate, setIsCreate] = useState(true);
  const [taxRateObj, setTaxRateObj] = useState(TaxRateCaptureSettingDomain.generateInitial());

  const orgTreesOptions = useOrgTreesByCategoryOptions();
  const { taxRateList, setTaxRateList } = useTaxRateList(orgCode);

  const columns: Array<Column<TaxRateCaptureSettingDomain>> = useMemo(() => [
    {
      Header: 'POS',
      accessor: 'posName',
    },
    {
      Header: '税率１',
      accessor: 'taxType1Str',
    },
    {
      Header: '税率２',
      accessor: 'taxType2Str',
    },
    {
      Header: '税率３',
      accessor: 'taxType3Str',
    },
    {
      Header: '税率４',
      accessor: 'taxType4Str',
    },
    {
      Header: '税率５',
      accessor: 'taxType5Str',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: TaxRateCaptureSettingDomain } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              to="#"
              onClick={() => {
                const data : TaxRateCaptureSettingDomain = new TaxRateCaptureSettingDomain(
                  { ...cell.row.original.getRawData() },
                );
                setIsCreate(false);
                setTaxRateObj(data);
              }}
            >
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>

          </div>
        );
      },
    },
  ], []);
  return (
    <>
      <FlexBox
        customStyle={css({
          padding: '8px',
        })}
      >
        <FlexBoxItem basis="100px">
          <BodyText>
            組織
          </BodyText>
        </FlexBoxItem>
        <FlexBoxItem basis="400px">
          <Select
            name="orgCode"
            value={orgCode}
            setValue={(v) => {
              sessionStorage.setItem('selectedOrgCode', v);
              setorgCode(v);
              setTaxRateObj(TaxRateCaptureSettingDomain.generateInitial());
              setIsCreate(true);
            }}
            options={orgTreesOptions}
          />
        </FlexBoxItem>
      </FlexBox>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
        <DataTable
          columns={columns}
          data={taxRateList}
        />
      </div>
      <TaxRateCaptureSettingAddForm
        taxRateList={taxRateList}
        setTaxRateList={setTaxRateList}
        isCreateProp={isCreate}
        setIsCreateProp={setIsCreate}
        taxRateObj={taxRateObj}
        setTaxRateObj={setTaxRateObj}
      />
    </>
  );
};

export default TaxRateCaptureSettingForm;
