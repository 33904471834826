import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { getSelManager } from 'api/organization';
import ManagerDomain from 'domain/manager';

export const useStaffList = (
  addStaff: (arg: Array<ManagerDomain>) => void,
  openHandler: (arg: boolean) => void,
) => {
  const { orgCode } = useParams<{orgCode: string}>();
  const [staffList, setStaffList] = useState<Array<ManagerDomain>|null>(null);
  const [checkedStaffCodeList, setCheckedStaffCodeList] = useState<Array<string>>([]);
  const [orgMgrCode, setOrgMgrCode] = useState(orgCode);

  useEffect(() => {
    const fetchStaffList = async () => {
      const fetchedStaffList = await getSelManager(orgMgrCode);
      setStaffList(fetchedStaffList.map((fetchStaff) => new ManagerDomain(fetchStaff)));
    };
    fetchStaffList();
  }, [orgMgrCode, setStaffList]);

  /**
   * チェックボックスのハンドラー
   */
  const checkHandler = useCallback((staffCode: string) => {
    const isChecked = checkedStaffCodeList.includes(staffCode);
    if (isChecked) {
      // チェック済みならリストからはずす
      setCheckedStaffCodeList(checkedStaffCodeList.filter(
        (checkedStaffCode) => checkedStaffCode !== staffCode,
      ));
      return;
    }
    setCheckedStaffCodeList(checkedStaffCodeList.concat(staffCode));
  }, [checkedStaffCodeList]);

  /**
   * 追加実行処理
   */
  const onSubmit = useCallback(() => {
    if (!staffList) {
      return;
    }
    addStaff(staffList.filter((staff) => checkedStaffCodeList.includes(staff.staffCode)));
    //addStaff(staffList.filter((staff) => staff.staffCode));

    // チェック済みリストからはずす
    setCheckedStaffCodeList([]);

    // モーダルを閉じる
    openHandler(false);
    // eslint-disable-next-line
  }, [addStaff, checkedStaffCodeList, openHandler, staffList]);

  return {
    staffList,
    checkedStaffCodeList,
    checkHandler,
    onSubmit,
    orgMgrCode,
    setOrgMgrCode,
  };
};

export default {};
