import React from 'react';
import TargetApplicationTemplate from 'components/organismos/organization/TargetApplication';

const TargetApplicationForm: React.FC<{isEditable?: number}> = ({isEditable = 0}) => {
    return (
      <React.Fragment>
        <TargetApplicationTemplate isEditable={isEditable}/>
      </React.Fragment>
    );
  };

export default TargetApplicationForm;
