import React, { useCallback, useState, useEffect, useReducer, useRef } from 'react';
import Action, { types } from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/actions';
import useToastNotification from 'hooks/useToastNotification';
import { useContext } from 'react';
import { LoadingContext } from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/MasterShopFaceRegistrationFormV1';

import {
    State
} from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/type';

export const useFaceRegisterList = (dispatch: any, state: any) => {

    const canvasRef1 = useRef<HTMLCanvasElement>(null);

    const { successNotification, errorNotification } = useToastNotification();
    const contextObject = useContext(LoadingContext);

    const [count, setCount] = useState(3);
    const [showMessage, setShowMessage] = useState(false);

    const handleUploadFile = (file: any) => {
        // setOpenCamera(false);
        let fileData = URL.createObjectURL(file);
        dispatch({ type: types.SET_TMP_IMAGE_MODAL, payload: fileData });
        var reader = new FileReader();
        reader.readAsDataURL(file);
        if (!file.type.match(/image.*/)) {
            return false;
        }
        return true;
    }

    const handleOpenCamera = () => {
        dispatch({ type: types.SET_OPEN_CAMERA });
    }

    const onUserMediaError = (err: any) => {
        if (err) {
          errorNotification("カメラへのアクセスに失敗しました。");
          dispatch({ type: types.ON_ERROR_CAMERA });
        }
    };

    const handleUndoModal = () => {
        if(state.openCamera){
            dispatch({ type: types.CLOSE_CAMERA });
            return;
        }
        if(state.openModalCrop){
            dispatch({ type: types.SET_TMP_IMAGE_MODAL, payload: undefined });
            return;
        }
        dispatch({ type: types.SET_MODAL_CHOOSE_ACTION, payload: {open: false, indexAction: 0} });
    };

    const countTimeCap = (webcamRef: any) => {
        setTimeout(() => {
            capture(webcamRef);
        }, 3000);
    };
    
    const capture = useCallback((webcamRef) => {
        dispatch({ type: types.SET_ENABLE_BUTTON_CAP, payload: false });
        dispatch({ type: types.SET_TMP_IMAGE_MODAL, payload: undefined });
        // setOpenCropModal(false);
        const base64string = webcamRef.current?.getScreenshot();

        if (!canvasRef1.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef1.current;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return;
        }

        const img = new Image();
        img.crossOrigin = "";

        // eslint-disable-next-line no-loop-func
        img.onload = () => {
            const w = img.width;
            const h = img.height;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = w;
            canvas.height = h;
            ctx.transform(1, 0, 0, 1, 0, 0);
            ctx.drawImage(img, 0, 0, w, h);
        //   setImageCap(img);
            canvas.toBlob((blob: any) => {
                dispatch({ type: types.SET_TMP_IMAGE_MODAL, payload: URL.createObjectURL(blob) });
                dispatch({ type: types.SET_ENABLE_BUTTON_CAP, payload: true });
                dispatch({ type: types.CLOSE_CAMERA});
            // setOpenCropModal(true);
            // setDisableButtonCap(false);
            // setCapturedPhoto(true);
            // setIsCapture(false);
            // setDisableButtonCap(false);
            // setOpenCamera(false);
            }, "image/jpeg");
        };
        img.onerror = () => {
            dispatch({ type: types.SET_ENABLE_BUTTON_CAP, payload: true });
        };
        img.src = base64string;
    }, []);

    const countDown = () => {
        dispatch({ type: types.SET_ENABLE_BUTTON_CAP, payload: false });
        dispatch({ type: types.SET_SHOW_MESSAGE, payload: true });
        const funcInterval = setInterval(() => {
            setCount((prevCount) => {
                return prevCount - 1;
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(funcInterval);
            setCount(3);
            dispatch({ type: types.SET_SHOW_MESSAGE, payload: false });
            dispatch({ type: types.SET_ENABLE_BUTTON_CAP, payload: true });
        }, 4000);
    };
    return {
        handleUploadFile,
        state,
        dispatch,
        handleOpenCamera,
        onUserMediaError,
        handleUndoModal,
        canvasRef1,
        countTimeCap,
        capture,
        countDown,
        count
    };
};




export default useFaceRegisterList;
