import moment from "moment";
import { ContextMenuType } from "components/molecules/ContextMenu/type";

const getContextMenuButtons = (
  domHitory: any,
  modifierApplicationId: any,
  orgCode: string,
  staffCode: string,
  staffName: string,
  modifierApplicationStatus: number | null,
  holidayApplicationStatus: number | null,
  allowanceApplicationStatus: number | null,
  overworkApplicationStatus: number | null,
  holidayworkApplicationStatus: number | null,
  transferApplicationStatus: number | null,
  attendanceOrgFix: string,
  useOverTimeApplication: any,
  necessaryHolidayWorkApplication: any,
  useAllowanceApplication: any,
  useTransferApplication: any,
  achievementId: any,
  targetDate: any,
  stampVersion: any,
  dispStaffName?: string,
  filterTargetDateFrom?: Date,
  filterTargetDateTo?: Date,
  filterOrgCode?: string,
  filterStaffCode?: string,
  belongOrgFix?: string,
  applying?: any,
  shiftTime?: any,
  stampApplicationStatus?: any,
  hasError?: any,
  holidayId?: any,
  isHolidayWork?: any,
  workSystem?: number,
  useAutoRess?: number,
  shiftId?: string,
  shiftHolidayId?: string,
  indexRow?: any,
  overtimeApplicationOrgCode?: string,
  holidayWorkApplicationOrgCode?: string,
  startTime?: string,
  endTime?: string,
  isApply?: boolean,
  useStockPaidApp?: boolean,
  stockModiferAppId?: string,
  stockPaidHolidayApplicationStatus?: number | null,
) => {
  const attendError = window.location.href.indexOf("attendError") > 0;
  const isHoliday = holidayId && !isHolidayWork;
  const isAttendError = !(!startTime && !endTime);
  const stampAmendmentButton: ContextMenuType = {
    isRed: modifierApplicationStatus === 0,
    isSelectable:
      ([0, 1].includes(applying) || (attendError && isAttendError) || hasError === "1") &&
      ((achievementId !== null && holidayApplicationStatus === null) ||
        modifierApplicationStatus !== null ||
        attendanceOrgFix === "1") &&
      !isHoliday,
    label: "修正申請",
    isHidden: false,
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendStampModificationApplication`;
      setSessionStorage(link, stampAmendmentButton.stamptype);
      sessionStorage.setItem("stamp.workSystem", String(workSystem));
      sessionStorage.setItem("stamp.useAutoRess", String(useAutoRess));
      sessionStorage.setItem("stamp.action", "edit");
      domHitory.push(link);
    },
  };

  const stampAddButton: ContextMenuType = {
    isRed: false,
    isSelectable: attendanceOrgFix !== "1" || (attendError && !isApply) || isHoliday,
    label: "追加申請",
    isHidden: false,
    stamptype: 0,
    onClick: () => {
      const link = `${window.location.pathname}/attendStampModificationApplication`;
      sessionStorage.setItem("stamp.workSystem", String(workSystem));
      sessionStorage.setItem("stamp.useAutoRess", String(useAutoRess));
      sessionStorage.setItem("stamp.action", "add");
      setSessionStorage(link, stampAddButton.stamptype);
      domHitory.push(link);
    },
  };

  const holidayAddButton: ContextMenuType = {
    isRed: false,
    isSelectable: true,
    label: "追加申請",
    isHidden: false,
    stamptype: 0,
    onClick: () => {
      const link = `${window.location.pathname}/attendHolidayApplication`;
      setSessionStorage(link, holidayAddButton.stamptype);
      domHitory.push(link);
    },
  };

  const holidayAmendmentButton: ContextMenuType = {
    isRed: modifierApplicationStatus === 0,
    isSelectable: achievementId && holidayId,
    label: "修正申請",
    isHidden: false,
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendHolidayApplication`;
      setSessionStorage(link, holidayAmendmentButton.stamptype);
      domHitory.push(link);
    },
  };

  const stampButtons: ContextMenuType = {
    isRed: false,
    isSelectable: true,
    label: "打刻修正申請",
    isHidden: false,
    stamptype: 1,
    subMenu: [stampAmendmentButton, stampAddButton],
  };

  const stampWorkScheduleChangeApplicationButtons: ContextMenuType = {
    isRed: false,
    isSelectable: true,
    label: "勤務予定変更申請",
    isHidden: false,
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendWorkScheduleChangeApplication`;
      setSessionStorage(link, stampWorkScheduleChangeApplicationButtons.stamptype, "", shiftId);
      domHitory.push(link);
    },
  };

  const applyLeaveButton: ContextMenuType = {
    isRed: holidayApplicationStatus === 0,
    isSelectable: attendanceOrgFix !== "1",
    label: "休暇申請",
    isHidden: false,
    stamptype: 1,
    subMenu: [holidayAmendmentButton, holidayAddButton],
  };

  const applyOvertimeButton: ContextMenuType = {
    isRed: overworkApplicationStatus === 0,
    isSelectable: overworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
    isHidden: useOverTimeApplication === 0,
    label: "残業申請",
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendOvertimeApplication`;
      setSessionStorage(link, applyOvertimeButton.stamptype, shiftTime, "", overtimeApplicationOrgCode, "");
      domHitory.push(link);
    },
  };

  const applyAllowanceButton: ContextMenuType = {
    isRed: allowanceApplicationStatus === 0,
    isSelectable: allowanceApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
    isHidden: useAllowanceApplication === 0,
    label: "手当申請",
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendAllowanceApplication`;
      setSessionStorage(link, applyAllowanceButton.stamptype);
      domHitory.push(link);
    },
  };

  const applyHolidayWorkButton: ContextMenuType = {
    isRed: holidayworkApplicationStatus === 0,
    isSelectable: holidayworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
    isHidden: necessaryHolidayWorkApplication === false,
    label: "休日出勤申請",
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendHolidayworkApplication`;
      setSessionStorage(link, applyHolidayWorkButton.stamptype, "", "", "", holidayWorkApplicationOrgCode);
      domHitory.push(link);
    },
  };

  const applyTransferButton: ContextMenuType = {
    isRed: transferApplicationStatus === 0,
    isSelectable: transferApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
    isHidden: useTransferApplication === 0,
    label: "振替申請",
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendTransferApplication`;
      setSessionStorage(link || "", applyTransferButton.stamptype);
      domHitory.push(link);
    },
  };

  const applyStockPaidButton: ContextMenuType = {
    isRed: stockPaidHolidayApplicationStatus === 0,
    isSelectable: stockPaidHolidayApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
    isHidden: useStockPaidApp === false,
    label: "積立有給申請",
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendStockPaidApplication`;
      setSessionStorage(link || "", applyStockPaidButton.stamptype);
      sessionStorage.setItem("application.modifierApplicationId", stockModiferAppId || "");
      domHitory.push(link);
    },
  };

  const setSessionStorage = (
    link: string,
    stamptype: number,
    shiftTime?: string,
    shiftId?: string,
    overtimeApplicationOrgCode?: string,
    holidayWorkApplicationOrgCode?: string,
  ) => {
    if (shiftTime) {
      sessionStorage.setItem("attendStampList.coreTime", shiftTime);
    } else {
      sessionStorage.setItem("attendStampList.coreTime", "");
    }

    // 自画面用パラメータ
    if (filterTargetDateFrom) {
      sessionStorage.setItem("attendStampList.targetDateFrom", moment(filterTargetDateFrom).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.targetDateTo", moment(filterTargetDateTo).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.orgCode", String(filterOrgCode));
      sessionStorage.setItem("attendStampList.staffCode", String(filterStaffCode));
    }

    // Common parameters
    if (!orgCode) {
      sessionStorage.setItem("application.orgCode", "");
    } else if (orgCode) {
      sessionStorage.setItem("application.orgCode", orgCode);
    } else if (filterOrgCode) {
      sessionStorage.setItem("application.orgCode", filterOrgCode);
    }
    sessionStorage.setItem("application.staffCode", String(staffCode));
    if (dispStaffName) {
      sessionStorage.setItem("application.dispStaffName", String(dispStaffName));
    } else {
      sessionStorage.setItem("application.dispStaffName", `${staffCode} ${staffName}`);
    }
    sessionStorage.setItem("application.targetDate", moment(targetDate).format("YYYY-MM-DD"));
    sessionStorage.setItem("application.returnDestination", window.location.pathname);
    sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);

    // Set highlight action
    sessionStorage.setItem("highlightIndex", indexRow);
    sessionStorage.setItem("highlightAction", "false");
    switch (link) {
      case `${window.location.pathname}/attendTransferApplication`:
        if (stamptype === 1) {
          sessionStorage.setItem("application.modifierApplicationId", modifierApplicationId || "");
          sessionStorage.setItem("application.achievementId", achievementId || "");
        } else {
          sessionStorage.removeItem("application.modifierApplicationId");
          sessionStorage.removeItem("application.achievementId");
        }
        break;
      case `${window.location.pathname}/attendWorkScheduleChangeApplication`:
      case `${window.location.pathname}/attendStampModificationApplication`:
        sessionStorage.removeItem("application.achievementId");
        sessionStorage.removeItem("application.stampVersion");
        sessionStorage.removeItem("application.modifierApplicationId");
        if (stamptype === 1) {
          // 修正申請
          sessionStorage.setItem("application.achievementId", achievementId || "");
          sessionStorage.setItem("application.shiftId", shiftId || "");
          sessionStorage.setItem("application.stampVersion", stampVersion || "");
          sessionStorage.setItem("application.modifierApplicationId", modifierApplicationId || "");
        }
        break;
      case `${window.location.pathname}/attendOvertimeApplication`:
        sessionStorage.setItem("application.orgCode", orgCode || "");
        if (stamptype === 1) {
          sessionStorage.setItem("application.editOvertime", "1");
        }
        break;
      case `${window.location.pathname}/attendHolidayworkApplication`:
        sessionStorage.setItem("application.orgCode", orgCode || "");
        if (stamptype === 1) {
          sessionStorage.setItem("application.editHolidaywork", "1");
        }
        break;
      case `${window.location.pathname}/attendHolidayApplication`:
        if (stamptype === 1) {
          sessionStorage.setItem("application.achievementId", achievementId || "");
        } else {
          sessionStorage.removeItem("application.achievementId");
        }
        break;

      default:
        // do nothing
        break;
    }
  };

  const orgCodeUser = sessionStorage.getItem("loginUser.orgCode");

  if (workSystem === 1 && !shiftHolidayId) {
    // if (filterOrgCode !== orgCodeUser && !shiftTime) {
    //   return [
    //     stampButtons,
    //     applyLeaveButton,
    //     applyOvertimeButton,
    //     applyHolidayWorkButton,
    //     applyTransferButton
    //   ];
    // }
    return [
      stampButtons,
      stampWorkScheduleChangeApplicationButtons,
      applyLeaveButton,
      applyOvertimeButton,
      applyAllowanceButton,
      applyHolidayWorkButton,
      applyTransferButton,
      applyStockPaidButton,
    ];
  }
  return [
    stampButtons,
    applyLeaveButton,
    applyOvertimeButton,
    applyAllowanceButton,
    applyHolidayWorkButton,
    applyTransferButton,
    applyStockPaidButton,
  ];
};

export default getContextMenuButtons;
