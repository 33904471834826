import { useState, useCallback, useEffect } from "react";
import { postCsvImport, Error, getImportHistory } from "api/organization";
import useToastNotification from "hooks/useToastNotification";
import { DetailRole, getDetailRole } from "api/masterRole";

export const useOrganizationCsvForm = () => {
  // アップロードタイプ
  const [uploadType, setUploadType] = useState("0");
  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);
  const [importHistory, setImportHistory] = useState([]);
  const [uploadTypeItems, setUploadTypeItems] = useState<{ label: string; value: string }[]>([
    {
      label: "組織情報",
      value: "0",
    },
    // {
    //   label: 'マネージャー権限',
    //   value: '1',
    // },
  ]);

  // アップロードファイル
  const [uploadFile, setUploadFile] = useState<File>();

  // アップロードエラー
  const [errors, setErrors] = useState<Array<string>>();

  // アップロード実行処理
  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }

    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    // エラー表示を初期化
    setErrors(undefined);
    setConfirmModalOpen(false);
    setBlocking(true);
    postCsvImport(uploadFile, uploadType)
      .then((response: any) => {
        setBlocking(false);
        setUploadFile(undefined);
        if (response.status !== 200) {
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification("インポートが完了しました。");
        }
      })
      .catch((error: any) => {
        setUploadFile(undefined);
        setBlocking(false);
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [uploadFile, confirmModalOpen, uploadType, errorNotification, successNotification]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  useEffect(() => {
    getImportHistory().then((response: any) => {
      setImportHistory(response);
    });

    getDetailRole("001").then((response: DetailRole) => {
      if (response) {
        setUploadTypeItems([
          {
            label: "組織情報",
            value: "0",
          },
          // {
          //   label: 'マネージャー権限',
          //   value: '1',
          // },
          ...(response.usabilityGeneralItem1 === 1
            ? [
                {
                  label: "特賃日割当情報",
                  value: "2",
                },
              ]
            : []),
        ]);
      }
    });
  }, []);

  return {
    uploadType,
    setUploadType,
    uploadTypeItems,
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    blocking,
    confirmModalOpen,
    closeConfirmModal,
    importHistory,
    setImportHistory,
  };
};

export default {};
