/**
 * API 手当マスタ
 */

export interface AttendAllowance {
  allowanceId: string;
  allowanceCode: string;
  allowanceName: string;
  dispMessage: string;
  dispUnit: string;
  createUser: string,
  updateUser: string
}

export default class AttendAllowanceDomain {
  constructor(private rawData: AttendAllowance) {
    // do nothing
  }

  static generateInitial(): AttendAllowanceDomain {
    return new AttendAllowanceDomain({
      allowanceId: '',
      allowanceCode: '',
      allowanceName: '',
      dispMessage: '',
      dispUnit: '',
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): AttendAllowance {
    return this.rawData;
  }

  get allowanceId(): string {
    return this.rawData.allowanceId;
  }

  set allowanceId(allowanceId: string) {
    this.rawData.allowanceId = allowanceId;
  }

  get allowanceCode(): string {
    return this.rawData.allowanceCode;
  }

  set allowanceCode(allowanceCode: string) {
    this.rawData.allowanceCode = allowanceCode;
  }

  get allowanceName(): string {
    return this.rawData.allowanceName;
  }

  set allowanceName(allowanceName: string) {
    this.rawData.allowanceName = allowanceName;
  }

  get dispMessage(): string {
    return this.rawData.dispMessage;
  }

  set dispMessage(dispMessage: string) {
    this.rawData.dispMessage = dispMessage;
  }

  get dispUnit(): string {
    return this.rawData.dispUnit;
  }

  set dispUnit(dispUnit: string) {
    this.rawData.dispUnit = dispUnit;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }

  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }
}
