/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { grayScale, textFontSize, iconColor } from "components/styles";

const styles = {
  wrapper: css({
    display: "flex",
    maxWidth: "182px",
  }),
  input: css({
    flex: 1,
    width: "32px",
    padding: "7px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    color: grayScale.gray100,
    border: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px",
    outline: "none",
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "7px",
    fontSize: textFontSize.re,
    color: grayScale.gray100,

    borderLeft: "none",
    borderRadius: "4px",
  }),
  grayOut: css({
    background: `${grayScale.gray05}`,
  }),
  fullWidth: css({
    width: "100%",
    maxWidth: "100%",
  }),
  noLabel: css({
    borderRight: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px",
  }),
};

const MoneyInput: React.FC<{
  name: string;
  value?: any;
  label?: string;
  isFullW?: boolean;
  readOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  customStyleLabel?: SerializedStyles;
  widthInput?: string;
  height?: string;
  flexNone?: boolean;
  wrapInput?: SerializedStyles;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isInteger?: boolean;
  maxLength?: number;
}> = ({
  wrapInput,
  name,
  value = "",
  label = null,
  onChange,
  onBlur,
  isInteger,
  isFullW = false,
  readOnly = false,
  min,
  max,
  step,
  placeholder,
  customStyleLabel = css({}),
  widthInput = "60px",
  height = "",
  flexNone = false,
}) => {
  return (
    <div css={css(styles.wrapper, readOnly ? styles.grayOut : {}, isFullW ? styles.fullWidth : {}, wrapInput)}>
      <input
        type="number"
        css={css(
          styles.input,
          readOnly ? styles.grayOut : {},
          label === null ? styles.noLabel : {},
          isFullW ? styles.fullWidth : {},
          { width: widthInput, height: height ? `calc(${height} - 16px)` : "" },
          flexNone ? { flex: "none" } : {},
        )}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        min={min}
        max={max}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.charCode == 46 && isInteger) {
            e.preventDefault();
            return false;
          }
        }}
        placeholder={placeholder}
        step={step}
        maxLength={max?.toString().length}
        onInput={(e: any) => {
          if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
          }
          if (Number(e.target.value) === 0 || e.target.value === "") {
            e.target.value = "0";
          } else {
            e.target.value = e.target.value.replace(/^0+/, "");
          }
        }}
      />
      {label && <div css={[styles.addon, customStyleLabel]}>{label}</div>}
    </div>
  );
};

export default MoneyInput;
