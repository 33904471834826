import React from "react";

import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from "components/atoms/TabControl";
// import OrganizationInfoTable from 'components/organismos/organization/OrganizationInfoTable';
import OrganizationInputForm from "components/organismos/organization/OrganizationInputForm";

import BlockUI from "components/molecules/BlockUi";
import { useOrganizationEdit } from "./hooks";
import { Row, Col } from "react-grid-system";
import FormPadding from "components/atoms/Form/FormPadding";
import TargetApplicationForm from "../TargetApplicationForm";
import FormTitle from "components/atoms/Form/FormTitle";
import OrganizationSpecialSalaryEditForm from "components/organismos/organization/OrganizationSpecialSalaryEditForm/OrganizationSpecialSalaryEditForm";

type SelectType = "basic" | "manager" | "specialSalary" | "approval";
const SelectType: { [key in SelectType]: SelectType } = {
  basic: "basic",
  manager: "manager",
  specialSalary: "specialSalary",
  approval: "approval",
};

const OrganizationEditPage: React.FC = () => {
  const {
    organizationDomain,
    setOrganizationDomain,
    historyList,
    setHistoryList,
    blocking,
    setBlocking,
    detailRole,
    isFetchHistory,
  } = useOrganizationEdit();
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.basic);
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE == "true" || false;
  return (
    <SidebarTemplate pageTitle="組織設定">
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle title="組織編集" bold={true} />
          <FormPadding customStyle={{ padding: "0 30px" }}>
            <Row>
              <Col md={5} xs={12}>
                <TabControl
                  items={[
                    {
                      value: SelectType.basic,
                      display: "基本情報",
                    },
                    // {
                    //   value: SelectType.manager,
                    //   display: 'マネージャー管理情報',
                    // },
                    {
                      value: SelectType.approval,
                      display: "申請ルート",
                    },
                    ...(detailRole.usabilityGeneralItem1 === 1
                      ? [
                          {
                            value: SelectType.specialSalary,
                            display: "特賃日割当情報",
                          },
                        ]
                      : []),
                  ]}
                  setValue={setSelectType as (arg: string) => void}
                  currentValue={selectType}
                  isFixed={false}
                />
              </Col>
            </Row>
          </FormPadding>
          {selectType === SelectType.basic && (
            <>
              {/* <OrganizationInfoTable
                    organizationDomain={organizationDomain}
                  /> */}

              <OrganizationInputForm
                initialOrganizationDomain={organizationDomain || undefined}
                setInitialOrganizationDomain={setOrganizationDomain}
                historyList={historyList}
                setHistoryList={setHistoryList}
                isEditMode={true}
                isDisabledInfo={isAuthorizedFromPeople}
                isEditable={detailRole.editable}
                setBlocking={setBlocking}
                isFetchHistory={isFetchHistory}
              />
            </>
          )}
          {/* {
            selectType === SelectType.manager
            && (
              <OrganizationManagerEditForm
                managerList={managerList}
                seniorManagerList={seniorManagerList}
                setManagerList={setManagerList}
                setSeniorManagerList={setSeniorManagerList}
                isEditMode={true}
                isEditable={detailRole.editable}
              />
            )
          } */}
          {selectType === SelectType.approval && <TargetApplicationForm isEditable={detailRole.editable} />}

          {selectType === SelectType.specialSalary && (
            <OrganizationSpecialSalaryEditForm isEditable={detailRole.editable} isEditMode={true} />
          )}
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default OrganizationEditPage;
