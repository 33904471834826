import React from 'react';

import BaseButton, { ButtonProps } from './BaseButton';
import { css } from '@emotion/core';

/**
 * プライマリボタン
 *
 * @see https://sketch.cloud/s/2b3Pe/a/k9qkrA
 */

const baseButtonStyle = css({
    outline:'none',
    border:'none',
    fontWeight:'normal',
    lineHeight:'24px'
});
  
const SelectFileButton: React.FC<ButtonProps> = ({
  text, onClick, disabled, ghost, children, fullwidth = false, minWidth='160px', customStyle
}) => (
  <BaseButton 
    css={baseButtonStyle}
    color={{
      baseColor: 'transparent',
      textColor: '#007BC3',
      ghostColor: 'transparent',
      ghostTextColor: '#007BC3',
      hoverColor: 'transparent',
      focusColor: 'transparent',
    }}
    text={text}
    onClick={onClick}
    disabled={disabled}
    ghost={ghost}
    fullwidth={fullwidth}
    minWidth={minWidth}
    isUploadFileButton={true}
    customStyle={customStyle}
  >
    {children}
  </BaseButton>
);

export default SelectFileButton;
