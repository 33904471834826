/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useCallback, useContext, useEffect} from "react";
import { useFormik } from "formik";
import { handleRecorderById, handleConfirmRecorder, getSpecifyCodeByBarcodeId, getInfomationOrgLocalStorage } from "api/timeRecorder";
import useToastNotification from "hooks/useToastNotification";
import ConfirmRecorderByBarcodeIdDomain from "domain/timeRecorder/confirmRecorderByBarcodeIdDomain";
import RecorderByBarcodeIdDomain from "domain/timeRecorder/recorderByBarcodeIdDomain";

import InfoStaffTimeRecorderDomain from "domain/timeRecorder/infoStaffTimeRecorderDomain";
import { CurrentTimeDomain } from "domain/master/attend/currentTime";
import { TimeRecorderContext } from "components/organismos/RecorderV2/RecorderV2";

const { errorNotification, successNotification } = useToastNotification();

export const useBarcodeRecorder = (
  dataSettings: any,
  resultCurrentTime: CurrentTimeDomain,
  setIsLoading: any,
  fetchHistoryData: any
) => {
  const {
    stampOrgCode,
    setStampOrgCode,
    stampOrgName,
    setStampOrgName,
    loginOrgCode,
    loginOrgName,
  } = dataSettings;

  // get local storage
  const contextObject = useContext(TimeRecorderContext);  
  const {companyCode, shopCode, stampByBelongOrg} = contextObject;

  const [isConfirm, setIsConfirm] = useState(false);

  //Use New UI Remote
  const [isRemote, setIsRemote] = useState<boolean>(false);

  // ID
  const [inputBarcodeId, setInputBarcodeId] = useState("");
  const [barcodeId, setBarcodeId] = useState("");

  // Staff
  const [stampMode, setStampMode] = useState(0);
  const [staffName, setStaffName] = useState("");

  // type
  const [lastStampType, setLastStampType] = useState<any>();
  const [stampType, setStampType] = useState<any>();

  
  const [businessList, setBusinessList] = useState<any>();
  const [businessName, setBusinessName] = useState("")

  const onSubmit = async (values: ConfirmRecorderByBarcodeIdDomain) => {
    setIsLoading(true);
    try {
      await handleRecorderById(shopCode, companyCode, values.getRawData()).then(
        (response: InfoStaffTimeRecorderDomain) => {
          setIsConfirm(true);
          const stampStaffData = response.stampStaffList[0];
          setLastStampType(stampStaffData.lastStampType);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setStaffName(stampStaffData.staffName);
          setStaffCode(stampStaffData.staffCode);
          setBarcodeId(stampStaffData.barcodeId);
          setStampOrgCode(stampStaffData.orgCode);
          setStampOrgName(stampStaffData.orgName);
          setIsRemote(stampStaffData.isRemote);
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.message) {
        errorNotification(error.response.data.message);
        return;
      }
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByBarcodeIdDomain.generateInitial(),
    onSubmit,
  });
  const handleSubmitBarcode = useCallback(() => {
    setIsLoading(true);
    setStampMode(3);
    formik.setFieldValue("stampMode", stampMode);
    formik.setFieldValue("stampByBelongOrg", stampByBelongOrg);
    formik.setFieldValue("orgName", loginOrgName);
    formik.setFieldValue("orgCode", loginOrgCode);
    formik.setFieldValue("loginOrgName", loginOrgName);
    formik.setFieldValue("loginOrgCode", loginOrgCode);
    formik.setFieldValue("staffName", staffName);
    formik.setFieldValue("barcodeId", barcodeId);
    formik.setFieldValue("stampTime", String(resultCurrentTime.serverDateTime));
    formik.submitForm();
  }, [
    barcodeId,
    formik,
    loginOrgCode,
    loginOrgName,
    resultCurrentTime.serverDateTime,
    staffName,
    stampByBelongOrg,
    stampMode,
    stampOrgCode,
    stampOrgName,
  ]);

  const [,setStaffCode] = useState("");
  const [businessCnt, setBusinessCnt] = useState(0);
  //submit
  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, businessName?: string, flagRemote?: boolean) => {
      if(flagRemote){
        formikConfirm.setFieldValue("isRemote", true);  
      }else{
        formikConfirm.setFieldValue("isRemote", isRemote);  
      }
      setBusinessName(businessName||"");
      setStampType(stampType);
      formikConfirm.setFieldValue("orgCode", loginOrgCode);
      formikConfirm.setFieldValue("orgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgCode", loginOrgCode);
      formikConfirm.setFieldValue("staffName", staffName);
      formikConfirm.setFieldValue("barcodeId", barcodeId);
      formikConfirm.setFieldValue("inputBarcodeId", inputBarcodeId);
      formikConfirm.setFieldValue(
        "stampTime",
        String(resultCurrentTime.serverDateTime)
      );
      formikConfirm.setFieldValue("stampType", stampType);
      formikConfirm.setFieldValue("businessId", businessId);
      formikConfirm.setFieldValue("stampMode", stampMode);
      formikConfirm.submitForm();
    },
    [
      barcodeId,
      inputBarcodeId,
      loginOrgCode,
      loginOrgName,
      resultCurrentTime.serverDateTime,
      staffName,
      stampMode,
      stampOrgCode,
      stampOrgName,
    ]
  );

  const onSubmitConfirm = async (values: RecorderByBarcodeIdDomain) => {
    setIsLoading(true);
    handleConfirmRecorder(shopCode, companyCode, values.getRawData(), 0).then(
      (response: any) => {
        successNotification(
          response.stampResultInfo.replace("<h4>", "").replace("</h4>", "")
        );
        setIsConfirm(false);
        fetchHistoryData();
        formik.resetForm();
        formikConfirm.resetForm();
        setInputBarcodeId("");
        setIsLoading(false);
      }
    ).catch((error: any) => {
      setIsLoading(false);
      setIsConfirm(false);
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorNotification(
            error.response.data.errors.map((x: any) => x.defaultMessage)
          );
        } else if (error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification(error.response.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
  };

  const formikConfirm = useFormik({
    initialValues: RecorderByBarcodeIdDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirm(values);
    },
  });

  return {
    handleSubmitBarcode,
    formik,
    businessList,
    lastStampType,
    setIsConfirm,
    isConfirm,
    staffName,
    businessCnt,
    formikConfirm,
    handleRecorder,
    stampOrgCode,
    businessName,
    stampType,
  };
};

export const useBarcodeRecorderEmptySpecifyCode = (
  resultCurrentTime: CurrentTimeDomain,
  setIsLoading: any,
  fetchHistoryData: any
) => {

  // get local storage
  const contextObject = useContext(TimeRecorderContext);  
  const {companyCode, shopCode, stampByBelongOrg} = contextObject;

  const [isConfirm, setIsConfirm] = useState(false);

  //Use New UI Remote
  const [isRemote, setIsRemote] = useState<boolean>(false);

  // ID
  const [inputBarcodeId, setInputBarcodeId] = useState("");
  const [barcodeId, setBarcodeId] = useState("");

  // Staff
  const [stampMode, setStampMode] = useState(0);
  const [staffName, setStaffName] = useState("");

  // type
  const [lastStampType, setLastStampType] = useState<any>();
  const [stampType, setStampType] = useState<any>();

  const [businessList, setBusinessList] = useState<any>();
  const [businessName, setBusinessName] = useState("")

  const [stampOrgCode, setStampOrgCode] = useState("");
  const [stampOrgName, setStampOrgName] = useState("");
  const [loginOrgCode, setLoginOrgCode] = useState("");
  const [loginOrgName, setLoginOrgName] = useState("");

  const onSubmit = async (values: ConfirmRecorderByBarcodeIdDomain) => {
    setIsLoading(true);
    try {
      await handleRecorderById(shopCode, companyCode, values.getRawData()).then(
        (response: InfoStaffTimeRecorderDomain) => {
          setIsConfirm(true);
          const stampStaffData = response.stampStaffList[0];
          setLastStampType(stampStaffData.lastStampType);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setStaffName(stampStaffData.staffName);
          setStaffCode(stampStaffData.staffCode);
          setBarcodeId(stampStaffData.barcodeId);
          setStampOrgCode(stampStaffData.orgCode);
          setStampOrgName(stampStaffData.orgName);
          setIsRemote(stampStaffData.isRemote);
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.message) {
        errorNotification(error.response.data.message);
        return;
      }
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByBarcodeIdDomain.generateInitial(),
    onSubmit,
  });
  const handleSubmitBarcode = useCallback((orgCode, orgName) => {
    setIsLoading(true);
    setStampMode(3);
    formik.setFieldValue("stampMode", 3);
    formik.setFieldValue("stampByBelongOrg", stampByBelongOrg);
    formik.setFieldValue("orgName", orgName);
    formik.setFieldValue("orgCode", orgCode);
    formik.setFieldValue("loginOrgName", orgName);
    formik.setFieldValue("loginOrgCode", orgCode);
    formik.setFieldValue("staffName", staffName);
    formik.setFieldValue("barcodeId", barcodeId);
    formik.setFieldValue("stampTime", String(resultCurrentTime.serverDateTime));
    formik.submitForm();
  }, [
    barcodeId,
    formik,
    loginOrgCode,
    loginOrgName,
    resultCurrentTime.serverDateTime,
    staffName,
    stampByBelongOrg,
    stampMode,
    stampOrgCode,
    stampOrgName,
  ]);

  const [,setStaffCode] = useState("");
  const [businessCnt, setBusinessCnt] = useState(0);
  //submit
  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, businessName?: string, flagRemote?: boolean) => {
      if(flagRemote){
        formikConfirm.setFieldValue("isRemote", true);  
      }else{
        formikConfirm.setFieldValue("isRemote", isRemote);  
      }
      setBusinessName(businessName||"");
      setStampType(stampType);
      formikConfirm.setFieldValue("orgCode", loginOrgCode);
      formikConfirm.setFieldValue("orgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgCode", loginOrgCode);
      formikConfirm.setFieldValue("staffName", staffName);
      formikConfirm.setFieldValue("barcodeId", barcodeId);
      formikConfirm.setFieldValue("inputBarcodeId", inputBarcodeId);
      formikConfirm.setFieldValue(
        "stampTime",
        String(resultCurrentTime.serverDateTime)
      );
      formikConfirm.setFieldValue("stampType", stampType);
      formikConfirm.setFieldValue("businessId", businessId);
      formikConfirm.setFieldValue("stampMode", stampMode);
      formikConfirm.submitForm();
    },
    [
      barcodeId,
      inputBarcodeId,
      loginOrgCode,
      loginOrgName,
      resultCurrentTime.serverDateTime,
      staffName,
      stampMode,
      stampOrgCode,
      stampOrgName,
    ]
  );

  const onSubmitConfirm = async (values: RecorderByBarcodeIdDomain) => {
    setIsLoading(true);
    handleConfirmRecorder(shopCode, companyCode, values.getRawData(), 0).then(
      (response: any) => {
        successNotification(
          response.stampResultInfo.replace("<h4>", "").replace("</h4>", "")
        );
        setIsConfirm(false);
        fetchHistoryData();
        formik.resetForm();
        formikConfirm.resetForm();
        setInputBarcodeId("");
        setIsLoading(false);
      }
    ).catch((error: any) => {
      setIsLoading(false);
      setIsConfirm(false);
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorNotification(
            error.response.data.errors.map((x: any) => x.defaultMessage)
          );
        } else if (error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification(error.response.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
  };

  const formikConfirm = useFormik({
    initialValues: RecorderByBarcodeIdDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirm(values);
    },
  });

  const fetchDataOrg = useCallback(async (specifyCode: string) => {
    setIsLoading(true);
    if(specifyCode){
      try {
        getInfomationOrgLocalStorage(companyCode, specifyCode).then(
          (response: any) => {
            setStampOrgCode(response.shopCode);
            setStampOrgName(response.shopName);
            setLoginOrgCode(response.shopCode);
            setLoginOrgName(response.shopName);
            handleSubmitBarcode(response.shopCode, response.shopName);
            // handleSubmitICCard(response.shopCode,response.shopName)
          }
        );
      } catch (error) {
        fetchHistoryData();
        errorNotification("サーバー側でエラーが発生しました。");
      }
      finally{
        setIsLoading(false);
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);

  const fetchSpecifyCodeByBarcodeId = useCallback(
    (barcodeId: string) => {
      setIsLoading(true);
      getSpecifyCodeByBarcodeId(companyCode, barcodeId).then(
        (response: any) => {
          fetchDataOrg(response.specifyCode);
          setIsLoading(false);
        }
      ).catch((error: any) => {
        
        setIsLoading(false);
        if (error.response.status === 400) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            errorNotification(
              error.response.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.response.data.defaultMessage) {
            errorNotification(error.response.data.defaultMessage);
          } else {
            errorNotification(error.response.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
      setIsLoading(false);
    },
    [formik.values.inputBarcodeId],
  )
  const handleSubmit = () => {
    if (formik.values.inputBarcodeId) {
      fetchSpecifyCodeByBarcodeId(formik.values.inputBarcodeId)
    } else {
      errorNotification("打刻IDを入力してください。");
    }
  };

  return {
    handleSubmitBarcode,
    formik,
    businessList,
    lastStampType,
    setIsConfirm,
    isConfirm,
    staffName,
    businessCnt,

    formikConfirm,
    handleRecorder,
    stampOrgCode,
    businessName,
    stampType,
    handleSubmit
  };
};
export default useBarcodeRecorder;
