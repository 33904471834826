/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import FlexBox from 'components/atoms/FlexBox';
import { useOrgTreesByCategoryNotAllOptions } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import DatePicker from 'components/molecules/DatePicker';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import useMonthlyReport, { useGetListMonthlyReport } from './hooks';
import MonthlySalesManagementTable from './MonthlySalesManagementTable';

const YearlySalesManagementLayout: React.FC<{
  fiscalYear:any,
}> = ({
  fiscalYear,
}) => {
  const fiscalStartMonth = moment(fiscalYear.fiscalStartMonth, 'YYYY-MM-DD').toString();
  const fiscalEndMonth = moment(fiscalYear.fiscalEndMonth, 'YYYY-MM-DD').toString();
  const datefrom = new Date(fiscalStartMonth);
  const dateto = new Date(fiscalEndMonth);
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateto);

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  const [isShowContentAction, setIsShowContentAction] = useState(false);

  const orgTreesOptions = useOrgTreesByCategoryNotAllOptions(
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
  );

  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : '');
  const { monthlyReport, getIsLoading } = useMonthlyReport(String(orgCode), moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'));
  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM')}~${moment(targetDateTo).format('YYYY/MM')}`;
  const {
    action,
    setAction,
    ActionType,
  } = useGetListMonthlyReport();
  const { errorNotification } = useToastNotification();

  const getFetchOption = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      return false;
    }
    setAction(ActionType.getFetchOption);
    setIsShowContentAction(true);
  };

  let startDate = new Date(targetDateFrom);
  const endDate = new Date(targetDateTo);

  const targetPeriod:any[] = [];
  const targetColumn = [];
  let monthCnt = 0;
  for (; startDate.getTime() <= endDate.getTime(); startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0)) {
    monthCnt += 1;
    targetPeriod.push(moment(startDate, 'YYYY/MM/DD').format('YYYY年MM月'));
    targetColumn.push("<th class='text-center'>純売上</th><th class='text-center'>売上予算</th><th class='text-center'>予実達成率</th><th class='text-center'>前年売上</th><th class='text-center'>前年対比</th>");
  }

  const targetDataColumn = [];
  for (let idx = 0; idx <= monthCnt; idx += 1) {
    targetDataColumn.push('純売上');
    targetDataColumn.push('売上予算');
    targetDataColumn.push('予実達成率');
    targetDataColumn.push('前年売上');
    targetDataColumn.push('前年対比');
  }

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem width="100px">
            <FormLabel label="対象期間" />
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={targetDateFrom}
              changeDate={(date: Date) => setTargetDateFrom(date)}
              isFullWidth={true}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <span style={{ margin: '0 30px' }}>～</span>
          </FlexBoxItem>
          <FlexBoxItem>
            <DatePicker
              dateFormat="yyyy年MM月"
              date={targetDateTo}
              changeDate={(date: Date) => setTargetDateTo(date)}
              isFullWidth={true}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="106px">
            <FormLabel
              label="組織名"
            />
          </FlexBoxItem>
          <FlexBoxItem width="100%">
            <VerticalLabelMutilSelectForm
              label=""
              name="orgCode"
              values={orgTreesOptions.filter((option) => {
                if (orgCode?.split(',').includes(option.value)) {
                  return option;
                }
              })}
              setValue={(val: any) => {
                setOrgCode(val.slice(0, -1));
              }}
              options={orgTreesOptions}
              isMulti={true}
            />
          </FlexBoxItem>
        </FlexBox>
        <FormField>
          <FormSubmitArea>
            <div style={{ marginRight: '12px', marginTop: '-15px' }}>
              <PrimaryButton
                text="売上管理表 "
                onClick={getFetchOption}
              />
            </div>
          </FormSubmitArea>
        </FormField>

        <div>
          { isShowContentAction && action === ActionType.getFetchOption
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlySalesManagementTable
                   orgCodeNames={orgCode}
                   monthlyReport={monthlyReport}
                   targetPeriodData={targetPeriodData}
                   targetPeriod={targetPeriod}
                   targetColumn={targetColumn}
                   targetDataColumn={targetDataColumn}
                   yearlyStartDate={targetDateFrom}
                   yearlyEndDate={targetDateTo}
                 />
               </BlockUI>
             </div>
           </div>
           )}
        </div>

      </FormContents>
    </div>
  );
};

export default YearlySalesManagementLayout;
