import ApiClient from "./ApiClient";

export const getAllowance = async (params: { orgCode: string; targetDate: string; staffCode: string }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const response = await ApiClient.get(`/v1/attendAllowance/allowance/${companyCode}`, {}, params);
  return response.data;
};

export const getList = async (companyCode: string): Promise<any> => {
  const params = {};
  const response = await ApiClient.get(`/v1/attendAllowance/list/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateAllowance = async (
  companyCode: string,
  isAccepted: boolean,
  data: any,
  isEdit: boolean,
) => {
  const staffName = sessionStorage.getItem("loginUser.staffName") || "";
  const params = {
    createUser: staffName,
    updateUser: staffName,
    allowanceCode: data.allowanceCode.trim(),
    allowanceName: data.allowanceName.trim(),
    dispMessage: data.dispMessage.trim(),
    dispUnit: data.dispUnit.trim(),
    allowanceId: data.allowanceId,
  };
  const query = {
    isAccepted,
  };

  const response = await ApiClient.post(`/v1/attendAllowance/${companyCode}`, query, params);
  return response.data;
};

export const deleteById = async (companyCode: string, allowanceId: string): Promise<any> => {
  const params = {
    allowanceId,
  };
  const reponse = await ApiClient.delete(`/v1/attendAllowance/${companyCode}`, params);
  return reponse.data;
};

export const getAttendAllowance = async (companyCode: string, allowanceId: string): Promise<any> => {
  const params = { allowanceId: allowanceId };

  const response = await ApiClient.get(`/v1/attendAllowance/${companyCode}`, params);
  return response.data;
};

export const createAllowanceApplication = async (params: {
  orgCode: string;
  staffCode: string;
  targetDate: string;
  version: number;
  buttonType: number;
  modifierApplicationId: string;
  detailList: {
    allowanceId: string;
    allowanceValue: string;
    comment: string;
  }[];
}) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginUserCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const postParams = {
    ...params,
    modifierApplicationId: params.buttonType === 0 ? null : params.modifierApplicationId,
    loginUserCode: loginUserCode,
  };

  const response = await ApiClient.post(`/v1/attendAllowance/stamp/${companyCode}`, {}, postParams);
  return response.data;
};
