/** @jsx jsx */
import React, { useCallback, useState, useEffect, useReducer, useRef } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { productColor, textFontSize, utilityColor, grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import useToastNotification from "hooks/useToastNotification";
import FaceRegisterBox from './FaceRegisterBox';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Modal from "components/molecules/Modal";
import BaseButton, { PrimaryButton } from "components/atoms/Button";
import {
  Face,
} from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/type';
import FileSelectBox from "components/molecules/FileSelectBox";
import useFaceRegisterList from './hook';
import { types } from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/actions';
import CropperCustom from './CropperCustom';
import Webcam from "react-webcam";
import { useContext } from 'react';
import { LoadingContext } from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/MasterShopFaceRegistrationFormV1';

const videoConstraints = {
  width: 400,
  height: 250,
  facingMode: "user",
};

const styles = {
  listBox: css({
    flexWrap: 'wrap',
    padding: '0 80px',
  }),
  selectionBox: css({
    width: "40%",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: '#F8F8F8',
    border: '1px solid #DCDCDC',
    borderRadius: '2px',
    color: productColor.primary,
    cursor: 'pointer',
    '&:hover, &:focus': {
      opacity: 0.8,
      backgroundColor: '#F8F8F8',
      color: productColor.primary,
    }
  }),
  noteImage: css({
    fontSize: "12px",
    color: "#ED5D5D",
    marginBottom: "15px",
    lineHeight: "22px",
  }),
  fileUploadButton: css`  
    background: transparent;
  `,
  containerSelection: css`
    position: relative;
    margin: 40px 0;
    width: 600px;
    :after{
      content: "";
      position: absolute;
      height: 1px;
      width: 60%;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #d0d0d0;
    }
  `,
  cropContainer: css({
    height: "250px",
    position: "relative",
    margin: "auto",
  }, css`
    .reactEasyCrop_CropArea::before, .reactEasyCrop_CropArea::after{
      border: 0;
    }  
  `),
  slider: css({
    background: "#007BC3",
    WebkitAppearance: "none",
    height: "2px",
    outline: "none !important",
    MozAppearance: "none",
    appearance: "none",
    border: "none",
    borderRadius: "30px",
    width: "400px",
    maxWidth: "100%",
    cursor: "pointer",
    margin: "40px 0 30px"
  }),
  imageCropped: css({
    cursor: "pointer",
    height: "100%",
    margin: "auto",
  }),
  message: css({
    width: '400px',
    margin: 'auto',
    visibility: 'hidden',
  }),
  showMessage: css({
    visibility: 'visible',
  }),
};

type FaceRegisterListProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  state: any,
  dispatch: any,
  maxLength?: number,  
}

const { errorNotification, successNotification } = useToastNotification();

const FaceRegisterList: React.FC<FaceRegisterListProps> = ({
  setIsLoading, maxLength = 6, dispatch, state
}) => {

  const webcamRef = useRef(null);
  const contextObject = useContext(LoadingContext);

  const {
    handleUploadFile,
    handleOpenCamera,
    onUserMediaError,
    handleUndoModal,
    canvasRef1,
    countTimeCap,
    capture,
    countDown,
    count
  } = useFaceRegisterList(dispatch, state);
  

  return (
    <React.Fragment>
      <FlexBox customStyle={styles.listBox}>
        {state.listFace.map((face: Face, index: number) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <React.Fragment>
              {index !== 0 && (
                <FaceRegisterBox
                  dispatch={dispatch}
                  face={face}
                  index={index}
                  key={index}
                />
              )}
            </React.Fragment>
          )
        })}
        {
          state.listFace.length < maxLength 
          // && state.listFace[state.listFace.length - 1]?.blob !== "" 
          && (
            <span
              onClick={() => {
                dispatch({ type: types.SET_MODAL_CHOOSE_ACTION, payload: {open: true, indexAction: state.listFace.length} });
              }}>
              <Icon
                type="addCircle"
                color=""
                size="25px"
                margin="0 0 0 20px"
              />
            </span>
          )
        }
      </FlexBox>
      <Modal
        closeHandler={() => {
          handleUndoModal();
        }}
        open={state.openModalChooseAction}
        title="顔写真の登録"
        submitText=""
        onSubmit={() => {
        }}
        closeText="キャンセル"
        customStyleFooter={css`
          margin-top: 0;
          div {
            margin: 0;
          }
        `}
      >
        { state.openCamera && (
          <React.Fragment>
            <FlexBox justifyContent="center">
              <div style={{border: '1px solid rgb(220,220,220)', height: '250px'}}>
                <Webcam
                  screenshotQuality={1}
                  style={{
                  }}
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={400}
                  height={250}
                  videoConstraints={videoConstraints}
                  onUserMediaError={onUserMediaError}
                  mirrored={true}
                />
                <canvas style={{ display: "none", width: "auto", height: "300px" }} ref={canvasRef1} className="canvas" /> 
              </div>
            </FlexBox>
            <FlexBox customStyle={css([styles.message, state.showMessage ? styles.showMessage : {}])}>
              <BodyText size="re" color={grayScale.gray50}>
                撮影開始まで{count}秒
              </BodyText>
            </FlexBox>
            <FlexBox justifyContent="center" customStyle={css({marginTop: '20px'})}>
              <PrimaryButton
                text="撮影する"
                disabled={!state.enableButtonCap}
                onClick={() => {
                  countTimeCap(webcamRef);
                  countDown();
                }}
              />
            </FlexBox>
          </React.Fragment>
        )}
        { state.openModalCrop && ( 
          <React.Fragment>
            <CropperCustom
              tempModalFile={state.tmpImageModal}
              onCroppedImage={(image: any) => {
                dispatch({ type: types.SET_CROPPED_IMAGE, payload: image});
              }}
            />
          </React.Fragment>
        )}
        { !state.openCamera && !state.openModalCrop && (
          <FlexBox justifyContent="space-around" customStyle={styles.containerSelection}>
          {/* <BaseButton
            customStyle={styles.selectionBox}
            text="画像をアップロード"
          /> */}
          <FileSelectBox
            text="画像をアップロード"
            setFile={(file) => handleUploadFile(file)}
            value=""
            ghost={true}
            accept="image/jpeg,image/png"
            customStyle={styles.fileUploadButton}
            customStyleContainer={styles.selectionBox}
          />
          <BaseButton
            customStyle={styles.selectionBox}
            text="撮影する"
            onClick={() => {
              handleOpenCamera();
            }}
          />
        </FlexBox>
        )}
      </Modal>
    </React.Fragment>
  )
};

export default FaceRegisterList;
