/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { StaffDailyShift } from 'api/shift';

import { productColor } from 'components/styles';
import ShiftBar from './ShiftBar';

import { useShiftDayCalendarContext } from './hooks';

const styles = {
  shiftAddArea: css({
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    height: '32px',
  }),
  shiftAddButton: css({
    padding: 0,
    margin: 0,
    height: '100%',
    border: 'none',
    ':focus': {
      outline: 'none',
    },
  }),
  shitBar: css({
    backgroundColor: productColor.primary,
    borderRadius: '4px',
  }),
};

const ShiftRow: React.FC<{
  /**
   * シフトデータ
   */
  staffDailyShift: StaffDailyShift;
}> = ({
  staffDailyShift,
}) => {
  const {
    quarterHourPixel, dispStartHour, dispHour, showShiftAddPanelAction,
  } = useShiftDayCalendarContext();

  // workSystem=1(変形労働)の場合のみシフトの変更が可能
  const canShiftChange = React.useMemo(() => staffDailyShift.workSystem === 1,
    [staffDailyShift.workSystem]);

  const hourRange = Array(dispHour + 1).fill(dispStartHour).map((x, y) => x + y);

  return (
    <React.Fragment>
      <div css={styles.shiftAddArea}>
        {hourRange.map((hour) => (
          <div
            key={hour}
            css={{ height: '100%', width: quarterHourPixel * 4, flexShrink: 0 }}
          >
            {[0, 15, 30, 45].map((minute) => (
              <button
                key={minute}
                type="button"
                css={css(styles.shiftAddButton, {
                  width: quarterHourPixel,
                  borderBottom: `1px solid ${productColor.primaryM90}`,
                  background: 'white',
                })}
                onClick={(e) => {
                  if (!canShiftChange) {
                    return;
                  }
                  showShiftAddPanelAction(
                    hour,
                    minute,
                    staffDailyShift,
                  );
                }}
              >
                {' '}
              </button>
            ))}
          </div>
        ))}
      </div>

      <div
        // 表示時間+1 * 4(15分単位のため) * 15分毎のピクセル数
        css={{ width: (dispHour + 1) * 4 * quarterHourPixel }}
      >
        {staffDailyShift.shiftDailyList.map((shift) => (
          <ShiftBar
            key={shift.shiftId}
            shiftDaily={shift}
            employmentId={staffDailyShift.employmentId}
            belongOrgCode={staffDailyShift.belongOrgCode}
            canShiftChange={canShiftChange}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default ShiftRow;
