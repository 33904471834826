import { useFormik } from 'formik';
import { useState, useCallback } from 'react';
import useToastNotification from 'hooks/useToastNotification';
import { importFileExceltoBudgetYear, Error } from 'api/salesBudgetYear';

export const useSaleBudgetImport = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const [errors, setErrors] = useState<Array<string>>();
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [inputValue, setValueInput] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);

  const optionsFormatType = [
    {
      label: '給与',
      value: '0',
    },
    {
      label: '賞与',
      value: '1',
    },
  ];

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  if (uploadFile) {
    const fileType = uploadFile.type;
    if (
      fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      && fileType !== 'application/vnd.ms-excel'
      && fileType !== 'text/xls'
      && fileType !== 'text/xlsx'
    ) {
      errorNotification('CSVファイルを選択してください。');
      setUploadFile(undefined);
      setValueInput('');
    }
  }

  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }
    setErrors(undefined);
    setBlocking(true);

    const targetYear = `${sessionStorage.getItem('dateSelect')}/01/01`;
    const orgCode = sessionStorage.getItem('orgCode');

    importFileExceltoBudgetYear(uploadFile, orgCode, targetYear)
      .then((response: any) => {
        setBlocking(false);
        setConfirmModalOpen(false);
        if (response.status !== 200) {
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification('インポートが完了しました。');
        }
      }).catch((error: any) => {
        setBlocking(false);
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [errorNotification, successNotification, uploadFile]);

  const formik = useFormik({
    initialValues: {
      formatType: 0,
    },
    onSubmit,
  });


  const handleSubmit = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  return {
    optionsFormatType,
    formik,
    setUploadFile,
    uploadFile,
    onSubmit,
    errors,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    closeConfirmModal,
    inputValue,
    handleSubmit,
    blocking,
  };
};
export default useSaleBudgetImport;
