import React, { useState , useEffect } from 'react';
import Icon from 'components/atoms/Icon';
import {
  grayScale,
} from 'components/styles';

const MAX_VALUE = 2099;
const MIN_VALUE = 1900;

function YearInput({
  value, openPanel, selected, clear, onChange, disabled,
}) {
  const selectedClass = selected ? 'imput-wrapper-selected' : '';
  
  const [date, setDate] = useState(value);

  const validateYear = (year) => { 
    return   Number(year) >= MIN_VALUE 
          && Number(year) <= MAX_VALUE
          && /^\d{4}$/.test(year);
  }

  useEffect(() => {
    setDate(value);
  }, [value])
  return (
    <div className={`input-wrapper ${selectedClass}`}>
      <input
        // onChange={onChange}
        onClick={() => openPanel(true)}
        className="year-input"
        value={`${date === 0 ? '' : date}`}
        type="text"
        placeholder="yyyy"
        maxLength={4}
        onInput={(e) => {
          
          let tmpValue = e.target.value;
          if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
          }
          if (Number(e.target.value) === 0 || e.target.value === "") {
            e.target.value = "";
          } else {
          }
          setDate(e.target.value);
          if(validateYear(e.target.value)){
            onChange(Number(tmpValue));
          }
        }}
        onBlur={() => {
          if(!validateYear(date)){
            setDate(String(value));
          }
        }}
      />
      <div onClick={openPanel}>
        <Icon type="calendar" color={grayScale.gray100}/>
      </div>
    </div>
  );
}

export default YearInput;
