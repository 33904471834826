import { useEffect } from "react";

const useClearFilterStorage = () => {
    const previous = sessionStorage.getItem('path') || '';

    useEffect(() => {
        for (const key in sessionStorage) {
            if (sessionStorage.hasOwnProperty(key)) {
              if (key.search(`sessionData${previous}`) !== -1 && 'loginUser'.indexOf(key) === -1) {
                sessionStorage.removeItem(key);
              }
            }
        }
    }, [])

}

export default useClearFilterStorage;