import ApiClient from "api/ApiClient";
import {
    ITransfer,
    IStamp,
    IHoliday,
    IOvertime,
    IHolidayWork,
    IchangeLog,
    I36Agreement,
    IWorkSchedule
} from "./interface";

// Applying
export const getApplyingStampList = async (params?: any): Promise<IStamp[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationStampList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingHolidayList = async (params?: any): Promise<IHoliday[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationHolidayList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingOverTimeList = async (params?: any): Promise<IOvertime[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationOverTimeList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedAgreement36List = async (params?: any): Promise<I36Agreement[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/approval36AgreementsList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedWorkScheduleChangeList = async (params?: any): Promise<IWorkSchedule[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/approvalWorkPlanList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingAgreement36List = async (params?: any): Promise<I36Agreement[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/application36AgreementsList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingWorkScheduleChangeList = async (params?: any): Promise<IWorkSchedule[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationWorkPlanList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingHolidayWorkList = async (params?: any): Promise<IHolidayWork[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/applicationHolidayWorkList/${companyCode}`, {}, params);
    
    return data;
};
export const getApplyingTransferList = async (params?: any): Promise<ITransfer[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/approval/applicationTransferList/${companyCode}`, {}, params);
    
    return data;
};

// Approved
export const getApprovedStampList = async (params?: any): Promise<IStamp[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/approvalStampList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedHolidayList = async (params?: any): Promise<IHoliday[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/approvalHolidayList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedOverTimeList = async (params?: any): Promise<IOvertime[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/approvalOverTimeList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedHolidayWorkList = async (params?: any): Promise<IHolidayWork[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/approvalHolidayWorkList/${companyCode}`, {}, params);
    
    return data;
};
export const getApprovedTransferList = async (params?: any): Promise<ITransfer[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/approval/approvalTransferList/${companyCode}`, {}, params);
    
    return data;
};

// Rejected
export const getRejectedStampList = async (params?: any): Promise<IStamp[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/rejectionStampList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedHolidayList = async (params?: any): Promise<IHoliday[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/rejectionHolidayList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedOverTimeList = async (params?: any): Promise<IOvertime[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/rejectionOverTimeList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedAgreement36List = async (params?: any): Promise<I36Agreement[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/rejection36AgreementsList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedWorkScheduleChangeList = async (params?: any): Promise<IWorkSchedule[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/rejectionWorkPlanList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedHolidayWorkList = async (params?: any): Promise<IHolidayWork[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const {data} = await ApiClient.get(`/v1/attendModifier/approval/rejectionHolidayWorkList/${companyCode}`, {}, params);
    
    return data;
};
export const getRejectedTransferList = async (params?: any): Promise<ITransfer[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/approval/rejectionTransferList/${companyCode}`, {}, params);
    
    return data;
};

//Stamp Approve
export const postApproveStamp = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/stamp/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveStamp = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/stamps/${companyCode}`, {}, params);
    return data;
};

//Holiday Approve
export const postApproveHoliday = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
    params = {
        ...params,
        currentStaffCode,
        currentOrgCode
    }
    const { data } = await ApiClient.post(`/v1/approval/holiday/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveHoliday = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holidays/${companyCode}`, {}, params);
    return data;
};

//Overtime Approve
export const postApproveOvertime = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/overtime/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveOvertime = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/overtimes/${companyCode}`, {}, params);
    return data;
};

//Agreement 36 Approve
export const postApproveAgreement36 = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/36Agreements/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveAgreement36 = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/36AgreementsList/${companyCode}`, {}, params);
    return data;
};

//HolidayWork Approve
export const postApproveHolidayWork = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holidaywork/${companyCode}`, {}, params);
    return data;
};
export const postBulkApproveHolidayWork = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/holidayworks/${companyCode}`, {}, params);
    return data;
};

//Transfer Approve
export const postApproveTransfer = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/transfer/${companyCode}`, {}, params);
    return data;
};

export const postBulkApproveTransfer = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/transfers/${companyCode}`, {}, params);
    return data;
};

//Work Plan Approve
export const postApproveWorkSchedule = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/workPlan/${companyCode}`, {}, params);
    return data;
};

export const postBulkApproveWorkSchedule = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.post(`/v1/approval/workPlans/${companyCode}`, {}, params);
    return data;
};

//Get applying-stamplist History
export const getApplyingStampListHistory = async (params?: any): Promise<IchangeLog[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/stampHistoryListByAppId/${companyCode}`, {}, params);
    // data.sort((a:IchangeLog, b:IchangeLog) => (a.version - b.version));
    return data;
};

//Get applying-holidayWork History
export const getApplyingHolidayWorkListHistory = async (params?: any): Promise<IchangeLog[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendModifier/holidayWorkHistoryList/${companyCode}`, {}, params);
    // data.sort((a:IchangeLog, b:IchangeLog) => (a.version - b.version));
    return data;
};

//Get applying-overTime History
export const getApplyingOverTimeListHistory = async (params?: any): Promise<IchangeLog[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const { data } = await ApiClient.get(`/v1/attendOvertime/overtimeHistoryList/${companyCode}`, {}, params);
    // data.sort((a:IchangeLog, b:IchangeLog) => (a.v   ersion - b.version));
    return data;
};
export const postApproveStockPaidHoliday = async (params?: any): Promise<any[]> => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
    params = {
        ...params,
        currentStaffCode,
        currentOrgCode
    }
    const { data } = await ApiClient.post(`/v1/approval/stockPaidholiday/${companyCode}`, {}, params);
    return data;
};

