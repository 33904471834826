/** @jsx jsx */
import React, { ReactNode } from "react";
import { css, jsx } from "@emotion/core";
import { EventWrapperProps } from "react-big-calendar";

import { productColor, textColor, textFontSize } from "components/styles";
import { HopeShiftDomain } from "domain/master/general/hopeShift";

const createStyle = (color: string = productColor.primaryM95, hopeShift?: HopeShiftDomain) =>
  css({
    fontSize: textFontSize.re,
    width: "100%",
    "& > *": {
      backgroundColor: color || productColor.primaryM80,
      color: textColor.main,
      textAlign: "center",
      borderRadius: "4px",
      border: hopeShift?.fixStatus === 9 && hopeShift?.hopeShiftPatternType !== 0 ? "1px solid #0D4796" : "none",
    },
    pointerEvents: "none",
  });

/**
 * イベントラッパー
 * - eventにcolorが指定されている場合に設定する
 */
const EventWrapper: React.FC<EventWrapperProps> = (props) => {
  const { children, event } = props as EventWrapperProps & {
    children: ReactNode;
    event: Event & { color?: string; hopeShift?: HopeShiftDomain };
  };

  return <div css={createStyle(event.color, event.hopeShift)}>{children}</div>;
};

export default EventWrapper;
