/** @jsx jsx */
import React, { Fragment, useEffect, useState, useRef } from "react";
import { css, jsx } from "@emotion/core";
import { useMonthlyShiftContext } from "./contextHook";
import Table from "components/molecules/Table";
import { utilityColor, grayScale } from "components/styles";
import Item from "components/molecules/CalendarTimeline/Item";
import Icon from "components/atoms/Icon";
import ContextMenuShift from "components/molecules/ContextMenu/ContextMenuShift";
import { createPortal } from "react-dom";
import ReactTooltip from "react-tooltip";
import { Row, Col } from "react-grid-system";
import { getRoundShiftPatterns, fetchMonthlyShift } from "../api";
import IconLabelButton from "components/molecules/IconLabelButton";
import moment from "moment";
import "./style.scss";
import { Dropdown } from "react-bootstrap";
import ChevronDown from "components/atoms/Icon/ChevronDown";
import BodyText from "components/atoms/BodyText";
import { photoPath } from "hooks/usePhotoPath";
import PhotoFrame from "components/molecules/PhotoFrame";
import { isIPad13 } from "react-device-detect";
import BlockUI from "components/molecules/BlockUi";
import TextTooltip from "components/molecules/TextTooltip";

const TableMonthlyShift: React.FC<{ showLaborCost: boolean }> = ({ showLaborCost }) => {
  const {
    detailRole,
    setRemote,
    useSales,
    history,
    filters: { stateFilter, pageTotal, pageIndex, setPageIndex },

    monthlyCalendar: {
      monthlyShifts,
      staffs,
      setStaffs,
      shopPlan,
      setSelectedItem,
      setSelectedCell,
      shiftConfirm,
      onClickAttendStampList,
      setDailyShiftConfirmModal,
      setSelectedDay,
      achievementConfirm,
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      setContextMenuX,
      contextMenuY,
      setContextMenuY,
      getMenuItemButtons,
      getMenuPerformanceButtons,
      getMenuTimelineButtons,
      menuTimelineButtons,
      setMenuTimelineButtons,
      menuPerformanceButtons,
      setMenuPerformanceButtons,
      menuItemButtons,
      setMenuItemButtons,
    },
    shiftPattern: { setShiftPatternOptions },
  } = useMonthlyShiftContext();
  let bodyNode = document.body as HTMLBodyElement;

  //toggle table header
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isExpand, setExpand] = React.useState(false);

  const mainTable = useRef<any>(Object());

  useEffect(() => {
    if (mainTable) {
      mainTable.current.onscroll = function() {
        if (pageIndex < pageTotal) {
          if (mainTable) {
            // go item 17 set variable to call api
            if (mainTable.current.scrollHeight - mainTable.current.scrollTop < mainTable.current.clientHeight + 315) {
              setIsLoadMore(true);
            }
          }
        }
      };
    }
  }, [pageIndex, pageTotal]);

  useEffect(() => {
    if (isLoadMore && pageIndex < pageTotal) {
      const pageItem = 20;
      let employmentIds = stateFilter.employmentOptions.reduce(
        (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
        "1",
      );
      fetchMonthlyShift(
        stateFilter.dateFromStr,
        stateFilter.dateToStr,
        stateFilter.selectedOrg,
        stateFilter.isAttendExists,
        employmentIds,
        "",
        pageIndex,
        pageItem,
      )
        .then((response) => {
          setStaffs([...staffs, ...response.staffInfo]);
          setPageIndex(pageIndex + 1);
        })
        .finally(() => {
          setIsLoadMore(false);
        });
    }
  }, [pageTotal, isLoadMore]);

  const prefix = "monthlyShift";
  const highlightAction = sessionStorage.getItem("highlightAction") || false;
  const highlightIndex = sessionStorage.getItem("highlightIndex") || "";
  const elmntLine1 = document.getElementById(prefix + "_line1_" + highlightIndex);
  const elmntLine2 = document.getElementById(prefix + "_line2_" + highlightIndex);
  const elmntLine3 = document.getElementById(prefix + "_line3_" + highlightIndex);

  useEffect(() => {
    if (highlightAction === "true") {
      if (elmntLine1 && elmntLine2 && elmntLine3) {
        elmntLine1.classList.add("hightlight");
        elmntLine2.classList.add("hightlight");
        elmntLine3.classList.add("hightlight");
        elmntLine3.scrollIntoView({ block: "center" });
        setTimeout(() => {
          elmntLine1.classList.remove("hightlight");
          elmntLine2.classList.remove("hightlight");
          elmntLine3.classList.remove("hightlight");
          sessionStorage.setItem("highlightAction", "false");
          sessionStorage.setItem("highlightIndex", "");
        }, 3000);
      }
    }
  }, [elmntLine1, elmntLine2, elmntLine3, highlightAction]);

  return (
    <Fragment>
      {/* /.Table header */}
      <div>
        <BlockUI blocking={isLoadMore}>
          <div
            id="main-table"
            ref={mainTable}
            style={{
              overflowX: "auto",
              maxHeight: isIPad13 ? "auto" : "93vh",
              borderTop: "1px solid #ccc",
              borderLeft: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            {isPerformanceMenuVisible &&
              createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuPerformanceButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setPerformanceMenuVisible}
                  />
                </div>,
                bodyNode,
              )}
            {isItemMenuVisible &&
              createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuItemButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setItemMenuVisible}
                  />
                </div>,
                bodyNode,
              )}
            {isTimelineMenuVisible &&
              createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuTimelineButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setTimelineMenuVisible}
                  />
                </div>,
                bodyNode,
              )}
            <Table
              className="table__monthly-shift"
              css={css({
                td: { textAlign: "center", lineHeight: "11px" },
                border: "none",
              })}
            >
              <colgroup>
                <col style={{ width: "40px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "65px" }} />
                <col style={{ width: "65px" }} />
                {shopPlan.map((number, i) => (
                  <col key={i} style={{ width: "100px" }} />
                ))}
                <col style={{ width: "100px" }} />
                <col style={{ width: "50px" }} />
                <col style={{ width: "50px" }} />
              </colgroup>

              <thead>
                <tr style={{ lineHeight: 0 }}>
                  <Table.HeaderCell
                    colSpan={3}
                    rowSpan={2}
                    className="freeze-col border-r-none"
                    customStyle={css({ left: 0, boxShadow: "inset 0 -1px 0 #dcdcdc !important" })}
                  />
                  <Table.HeaderCell
                    className="freeze-col border-b-none border-l-none"
                    customStyle={css({ left: 165, boxShadow: "inset 1px 0px 0 #dcdcdc !important" })}
                  />
                  {monthlyShifts?.targetDateList?.map((date, i) => {
                    let formattedDate = moment(date).format("YYYY-MM-DD");
                    return (
                      <Table.HeaderCell key={i} rowSpan={2} style={{ zIndex: 80 - i }}>
                        <Dropdown
                          as="span"
                          css={{ display: "inline-block" }}
                          onToggle={() => {
                            setSelectedDay(moment(date).format("YYYY/MM/DD"));
                          }}
                          drop="right"
                        >
                          <Dropdown.Toggle variant="success" id="dropdown-basic" as="span">
                            <span
                              css={css({
                                marginLeft: "5px",
                                cursor: "pointer",
                                "& > svg": {
                                  width: "10px",
                                  height: "16px",
                                  verticalAlign: "sub",
                                  "&>path": { fill: grayScale.gray01 },
                                },
                              })}
                            >
                              {moment(date).format("M/D ddd")} <ChevronDown />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ width: "135px", zIndex: 100 }} className="custom-position-dropdown">
                            {[
                              {
                                value: "workSchedule",
                                label: "シフトの確定",
                                disabled: shiftConfirm[formattedDate] || achievementConfirm[formattedDate],
                                onClick: () => {
                                  setDailyShiftConfirmModal(true);
                                },
                              },
                              {
                                value: "chevronRight",
                                label: "日次シフトへ移動",
                                disabled: false,
                                onClick: () => {
                                  sessionStorage.setItem(
                                    "attendStampList.dailyShift.targetDateFrom",
                                    moment(date).format("YYYY-MM-DD"),
                                  );
                                  sessionStorage.setItem(
                                    "attendStampList.dailyShift.orgCode",
                                    stateFilter.selectedOrg,
                                  );
                                  history.push("/dailyShift");
                                },
                              },
                            ].map((item, index) => (
                              <Dropdown.Item
                                key={index.toString()}
                                style={{
                                  padding: "15px 10px",
                                  color: grayScale.gray01,
                                  textAlign: "left",
                                }}
                                onClick={item.disabled ? () => {} : item.onClick}
                              >
                                <BodyText color={item.disabled ? grayScale.gray03 : grayScale.gray01}>
                                  {item.label}
                                </BodyText>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.HeaderCell>
                    );
                  })}
                  <Table.HeaderCell rowSpan={2}>時間数合計</Table.HeaderCell>
                  <Table.HeaderCell colSpan={2}>公休数</Table.HeaderCell>
                </tr>
                <tr style={{ lineHeight: 0 }}>
                  <Table.HeaderCell
                    className="freeze-col border-t-none border-l-none"
                    customStyle={css({ left: 165, boxShadow: "inset 1px -1px 0 #dcdcdc !important" })}
                  />
                  <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>法定</Table.HeaderCell>
                  <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>所定</Table.HeaderCell>
                </tr>
              </thead>
              <tbody>
                {staffs.map((staff, indexStaff) => {
                  return (
                    <Fragment key={indexStaff}>
                      {/* RowId1 */}
                      <tr id={"monthlyShift_line1_" + indexStaff}>
                        <Table.Cell
                          className="freeze-col"
                          colSpan={3}
                          rowSpan={3}
                          customStyle={css({
                            left: 0,

                            boxShadow: "inset 1px -1px 0 #dcdcdc !important",
                          })}
                          style={{
                            textAlign: "left",
                            padding: "10px 0 10px 20px",
                            lineHeight: "18px",
                            fontSize: "12px",
                          }}
                        >
                          <div className="d-flex" style={{ alignItems: "center" }}>
                            <div
                              style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                height: "55px",
                                width: "55px",
                              }}
                            >
                              <PhotoFrame
                                src={photoPath(staff)}
                                size="55px"
                                isCircle={true}
                                onErrorIconColor="black"
                              />
                            </div>
                            {!staff.isSupportStaff && detailRole.linkAvailable1 && (
                              <IconLabelButton
                                onClick={() => onClickAttendStampList(staff)}
                                text="詳細"
                                isIcon={false}
                              />
                            )}
                          </div>
                          <div
                            css={css`
                              display: -webkit-box;
                              -webkit-line-clamp: 3;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                            `}
                          >
                            <TextTooltip
                              text={staff.employmentName}
                              textDis={<span>{staff.employmentName}</span>}
                              customCss={css({
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "140px",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                                margin: "0px auto",
                              })}
                            />
                          </div>
                          <div
                            css={css`
                              display: -webkit-box;
                              -webkit-line-clamp: 3;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                            `}
                          >
                            <TextTooltip
                              text={staff.staffName}
                              textDis={<span>{staff.staffName}</span>}
                              customCss={css({
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "140px",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                                margin: "0px auto",
                              })}
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell
                          className="freeze-col border-b-none border-l-none"
                          customStyle={css({ left: 165, boxShadow: "inset 0px -1px 0 #dcdcdc !important" })}
                        >
                          希望
                        </Table.Cell>
                        {staff.dateInfo.map((dateinfo, i) => {
                          let hopeTitle = "";
                          let hopeDate = dateinfo.hope?.shiftTimePdfDisp;
                          if (dateinfo.hope) {
                            switch (dateinfo.hope.hopeShiftPatternType) {
                              case 1:
                                hopeTitle = "o";
                                break;

                              case 2:
                                hopeTitle = "×";
                                break;

                              case 4:
                                hopeTitle = dateinfo.hope.holidayName || "";
                                break;

                              case 5:
                                hopeTitle = dateinfo.hope.holidayName || "";
                                break;

                              default:
                                hopeTitle = dateinfo.hope.shiftPatternName || "-";
                                break;
                            }
                          }
                          return (
                            <Table.Cell key={i} style={{ padding: "8px 5px", lineHeight: "18px" }}>
                              {hopeTitle && (
                                <div
                                  data-tip={`${hopeTitle}(${hopeDate})`}
                                  data-for={`row1-${i}-${hopeTitle}(${hopeDate})`}
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {hopeTitle}
                                  <ReactTooltip html={true} id={`row1-${i}-${hopeTitle}(${hopeDate})`} />
                                </div>
                              )}
                            </Table.Cell>
                          );
                        })}

                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </tr>

                      {/* RowId2 */}
                      <tr id={"monthlyShift_line2_" + indexStaff}>
                        <Table.Cell
                          className="freeze-col border-b-none border-l-none"
                          customStyle={css({ left: 165, boxShadow: "inset 0px -1px 0 #dcdcdc !important" })}
                        >
                          シフト
                        </Table.Cell>
                        {staff.dateInfo.map((dateinfo, dateInfoIndex) => {
                          let formattedDate = moment(dateinfo.targetDate).format("YYYY-MM-DD");
                          return (
                            <Table.Cell
                              key={dateInfoIndex}
                              style={{ padding: "8px 5px" }}
                              className={
                                shiftConfirm[formattedDate] ||
                                dateinfo.isAchievementConfirm ||
                                dateinfo.workSystem === 0 ||
                                dateinfo.workSystem === 2 ||
                                dateinfo.workSystem === 3
                                  ? ""
                                  : "cursor-pointer on-hover"
                              }
                              onClick={async (event: React.MouseEvent) => {
                                if (!detailRole.editable) return;
                                setRemote(dateinfo.useRemote);
                                if (
                                  shiftConfirm[formattedDate] ||
                                  dateinfo.isAchievementConfirm ||
                                  dateinfo.workSystem === 0 ||
                                  dateinfo.workSystem === 2 ||
                                  dateinfo.workSystem === 3
                                ) {
                                  return;
                                }
                                if (dateinfo.isAchievementConfirm || dateinfo.shiftList.length == 0) {
                                  setContextMenuX(event.pageX);
                                  setContextMenuY(event.pageY);

                                  //fetch Shiftpattern
                                  try {
                                    var shiftPatternData = await getRoundShiftPatterns(
                                      stateFilter.selectedOrg,
                                      dateinfo.employmentId,
                                      moment(dateinfo.targetDate).format("YYYY-MM-DD"),
                                    );
                                  } catch (error) {
                                    shiftPatternData = [];
                                  }

                                  setShiftPatternOptions(shiftPatternData);

                                  let timelineButtons = getMenuTimelineButtons(
                                    staff.staffCode,
                                    staff.staffName,
                                    dateinfo.targetDate,
                                    shiftPatternData,
                                    dateinfo.distinctionHoliday,
                                    dateInfoIndex,
                                  );

                                  setMenuTimelineButtons(timelineButtons);
                                  setSelectedCell({
                                    staffCode: staff.staffCode,
                                    staffName: staff.staffName,
                                    targetDate: moment(dateinfo.targetDate).format("YYYY/MM/DD HH:mm:ss"),
                                  });
                                  setTimelineMenuVisible(true);
                                }
                              }}
                            >
                              <div className="cell__itemWrapper">
                                {dateinfo.shiftList &&
                                  dateinfo.shiftList.map((shift, i) => {
                                    let title = "";
                                    if (shift.holidayName) {
                                      let holidayType = shift.isLegal ? " (法定)" : " (所定)";
                                      title = shift.distinctionHoliday
                                        ? shift.holidayName + holidayType
                                        : shift.holidayName;
                                    } else if (shift.shiftPatternId) {
                                      title = `${shift.shiftPatternName}(${shift.shiftTimePdfDisp})`;
                                    } else {
                                      title = shift.shiftTimePdfDisp;
                                    }

                                    // 他店舗に出勤する所属スタッフは支援先の店舗名を付加
                                    if (shift.orgCode !== stateFilter.selectedOrg) {
                                      title += ` (${shift.orgName})`;
                                    }

                                    return (
                                      <Item
                                        key={i}
                                        id={shift.id}
                                        title={title}
                                        disable={
                                          shiftConfirm[formattedDate] ||
                                          dateinfo.isAchievementConfirm ||
                                          dateinfo.isSupportStaff ||
                                          dateinfo.workSystem === 0 ||
                                          dateinfo.workSystem === 2 ||
                                          dateinfo.workSystem === 3
                                        }
                                        bgColor={shift.holidayBgcolor}
                                        textColor={shift.holidayForecolor}
                                        onClick={async (event) => {
                                          if (!detailRole.editable) return;
                                          setContextMenuX(event.pageX);
                                          setContextMenuY(event.pageY);

                                          //fetch Shiftpattern
                                          try {
                                            var shiftPatternData = await getRoundShiftPatterns(
                                              stateFilter.selectedOrg,
                                              dateinfo.employmentId,
                                              moment(dateinfo.targetDate).format("YYYY-MM-DD"),
                                            );
                                          } catch (error) {
                                            shiftPatternData = [];
                                          }

                                          setShiftPatternOptions(shiftPatternData);
                                          let itemButtons = getMenuItemButtons(
                                            staff.staffCode,
                                            staff.staffName,
                                            dateinfo.targetDate,
                                            shiftPatternData,
                                            dateinfo.distinctionHoliday,
                                            shift.orgCode,
                                            dateInfoIndex,
                                          );
                                          setMenuItemButtons(itemButtons);

                                          setSelectedCell({
                                            staffCode: staff.staffCode,
                                            staffName: staff.staffName,
                                            targetDate: moment(dateinfo.targetDate).format("YYYY/MM/DD HH:mm:ss"),
                                          });
                                          setSelectedItem(shift);
                                          setItemMenuVisible(true);
                                        }}
                                      />
                                    );
                                  })}
                              </div>
                              {dateinfo.shiftAlertMessage && (
                                <Col md={12}>
                                  <div
                                    data-tip={dateinfo.shiftAlertMessage || ""}
                                    data-for={`shiftalert-${indexStaff}-${dateinfo.targetDate}`}
                                    css={css({
                                      width: "fit-content",
                                      display: "inline-flex",
                                    })}
                                  >
                                    <Icon type="warning" color={utilityColor.yellow} verticalAlign="text-bottom" />
                                    <ReactTooltip html={true} id={`shiftalert-${indexStaff}-${dateinfo.targetDate}`} />
                                  </div>
                                </Col>
                              )}
                            </Table.Cell>
                          );
                        })}

                        <Table.Cell>{staff.shiftWorkTime}</Table.Cell>
                        <Table.Cell>{staff.shiftLegalHolidayCount}</Table.Cell>
                        <Table.Cell>{staff.shiftPrescribedHolidayCount}</Table.Cell>
                      </tr>

                      {/* RowId3 */}
                      <tr id={"monthlyShift_line3_" + indexStaff}>
                        <Table.Cell
                          className="freeze-col border-b-none border-l-none"
                          customStyle={css({ left: 165, boxShadow: "inset 0px -1px 0 #dcdcdc !important" })}
                        >
                          実績
                        </Table.Cell>
                        {monthlyShifts?.workTimeInfo?.daily.length > 0 &&
                          staff.dateInfo.map((dateinfo, i) => (
                            <Table.Cell
                              key={i}
                              style={{ padding: "8px 5px" }}
                              className={!dateinfo.isAchievementConfirm ? "cursor-pointer on-hover" : ""}
                              onClick={(event: React.MouseEvent) => {
                                if (!detailRole.editable) return;
                                if (!dateinfo.isAchievementConfirm) {
                                  let performanceButtons = getMenuPerformanceButtons(
                                    dateinfo.achievementList[0]?.modifierApplicationId || null, //modifierApplicationId : any
                                    staff.orgCode,
                                    staff.staffCode,
                                    staff.staffName,
                                    null, //modifierApplicationStatus: number | null
                                    dateinfo.achievementList[0]?.holidayApplicationStatus, //holidayApplicationStatus: number | null,
                                    null, //overworkApplicationStatus: number | null,
                                    null, //holidayworkApplicationStatus: number | null,
                                    null, //useAllowanceApplication: number | null,
                                    null, //transferApplicationStatus: number | null,
                                    "", //attendanceOrgFix: string,
                                    dateinfo.useOverTimeApplication, //useOverTimeApplication: any,
                                    dateinfo.necessaryHolidayWorkApplication, //necessaryHolidayWorkApplication: any,
                                    dateinfo.useAllowanceApplication, //useAllowanceApplication: any,
                                    dateinfo.useTransferApplication, //useTransferApplication: any,
                                    dateinfo.achievementList[0]?.idToString || null, //achievementId: any,
                                    dateinfo.targetDate,
                                    dateinfo.achievementList[0]?.stampVersion || null,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    dateinfo.achievementList[0]?.holidayId,
                                    dateinfo.achievementList[0]?.isHolidayWork,
                                    staff.dateInfo[0].workSystem,
                                    indexStaff,
                                    staff.dateInfo[0]?.overtimeApplicationOrgCode,
                                    staff.dateInfo[0]?.holidayWorkApplicationOrgCode,
                                    dateinfo.achievementList[0]?.orgCode || staff.dateInfo[i]?.belongOrgCode,
                                    Boolean(dateinfo?.useStockPaidApp),
                                    dateinfo?.stockModiferAppId,
                                    dateinfo?.stockPaidHolidayApplicationStatus,
                                  );
                                  setMenuPerformanceButtons(performanceButtons);
                                  setContextMenuX(event.pageX);
                                  setContextMenuY(event.pageY);
                                  setPerformanceMenuVisible(true);
                                }
                              }}
                            >
                              <Row key={i} style={{ lineHeight: "18px" }}>
                                <Col>
                                  {dateinfo.achievementList &&
                                    dateinfo.achievementList
                                      .map((achievement, i) => {
                                        let title = "";
                                        if (achievement.holidayName) {
                                          let holidayTypeName = achievement.transferType !== 0 ? " (振替休日) " : "";

                                          if (
                                            achievement.holidayType === 1 ||
                                            achievement.holidayType == 2 ||
                                            achievement.holidayType === 4 ||
                                            achievement.holidayType == 7 ||
                                            achievement.holidayType == 8 ||
                                            achievement.holidayType == 9
                                          ) {
                                            switch (achievement.holidayDigestiveUnit) {
                                              case 0:
                                                holidayTypeName = " (全)";
                                                break;
                                              case 2:
                                                holidayTypeName = " (時間)";
                                                break;
                                              case 3:
                                                holidayTypeName = " (午前)";
                                                break;

                                              case 4:
                                                holidayTypeName = " (午後)";
                                                break;

                                              default:
                                                break;
                                            }
                                          }

                                          title = achievement.isHolidayWork
                                            ? "休日出勤"
                                            : achievement.holidayName + holidayTypeName;
                                        } else if (achievement.workTimeDisp !== "0") {
                                          title = achievement.workTimeDisp || "";
                                        }
                                        return [achievement, title];
                                      })
                                      .reduce((unique: any, item: any) => {
                                        if (
                                          unique.find((u: any) => {
                                            return u[1] === item[1];
                                          })
                                        ) {
                                          return unique;
                                        }
                                        return [...unique, item];
                                      }, [])
                                      .map((item: any) => {
                                        return (
                                          <Fragment key={i}>
                                            <div
                                              data-tip={item[1]}
                                              data-for={`row3-${indexStaff}-${item[0].targetDate}-${item[1]}`}
                                              style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {item[1]}
                                              <ReactTooltip
                                                html={true}
                                                id={`row3-${indexStaff}-${item[0].targetDate}-${item[1]}`}
                                              />
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                </Col>
                                {dateinfo.isAchievementConfirm && (
                                  <Icon type="singlecheckRounded" color="#34a659" size="18px" />
                                )}
                                {dateinfo.achievementErrorMessage && (
                                  <Col md={12}>
                                    <div
                                      data-tip={dateinfo.achievementErrorMessage || ""}
                                      data-for={`error-${indexStaff}-${dateinfo.targetDate}`}
                                      css={css({
                                        width: "fit-content",
                                        display: "inline-flex",
                                      })}
                                    >
                                      <Icon type="remove" color="" verticalAlign="text-bottom" />
                                      <ReactTooltip html={true} id={`error-${indexStaff}-${dateinfo.targetDate}`} />
                                    </div>
                                  </Col>
                                )}
                                {dateinfo.achievementAlertMessage && (
                                  <Col md={12}>
                                    <div
                                      data-tip={dateinfo.achievementAlertMessage || ""}
                                      data-for={`alert-${indexStaff}-${dateinfo.targetDate}`}
                                      css={css({
                                        width: "fit-content",
                                        display: "inline-flex",
                                      })}
                                    >
                                      <Icon type="warning" color={utilityColor.yellow} verticalAlign="text-bottom" />
                                      <ReactTooltip html={true} id={`alert-${indexStaff}-${dateinfo.targetDate}`} />
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Table.Cell>
                          ))}
                        <Table.Cell>{staff.achievementWorkTime}</Table.Cell>
                        <Table.Cell>{staff.achievementLegalHolidayCount}</Table.Cell>
                        <Table.Cell>{staff.achievementPrescribedHolidayCount}</Table.Cell>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>

              {/* SUM BOTTOM */}
              <tbody>
                {!showLaborCost && (
                  <tr>
                    <Table.Cell colSpan={3} customStyle={css({ left: 0 })} className="freeze-col">
                      時間数合計 (h)
                    </Table.Cell>
                    <Table.Cell
                      className="freeze-col border-b-none border-l-none"
                      customStyle={css({ left: 165, boxShadow: "inset 0px -1px 0 #dcdcdc !important" })}
                    />

                    {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                      <Table.Cell key={i} style={{ lineHeight: "18px" }}>
                        {item.workTimeAchievementShopDaily} ({item.workTimeShiftShopDaily})
                      </Table.Cell>
                    ))}

                    <Table.Cell style={{ padding: "14px 5px", lineHeight: "18px" }}>
                      {monthlyShifts.workTimeInfo &&
                        `${monthlyShifts.workTimeInfo?.workTimeAchievementShopMonthly} (${monthlyShifts.workTimeInfo?.workTimeShiftShopMonthly})`}
                    </Table.Cell>
                    <Table.Cell>-</Table.Cell>
                    <Table.Cell>-</Table.Cell>
                  </tr>
                )}

                {showLaborCost && (
                  <React.Fragment>
                    <tr>
                      <Table.Cell
                        rowSpan={isExpand ? (useSales ? 10 : 7) : useSales ? 4 : 3}
                        className="freeze-col border-b-none"
                        customStyle={css({ left: 0, boxShadow: "inset 0px -1px 0 #dcdcdc !important" })}
                      ></Table.Cell>
                      <Table.Cell
                        colSpan={3}
                        className="freeze-col"
                        customStyle={css`
                          text-align: left !important;
                          cursor: pointer;
                          left: 40px;
                          box-shadow: inset 0px -1px 0 #dcdcdc !important;
                        `}
                        onClick={() => setExpand((prev) => !prev)}
                      >
                        合計
                        <Icon
                          type={isExpand ? "chevronUp" : "chevronDown"}
                          color={grayScale.gray100}
                          size="15px"
                          margin="0 0 0 8px"
                          verticalAlign="middle"
                        />
                      </Table.Cell>

                      <Table.Cell colSpan={monthlyShifts?.workTimeInfo?.daily.length + 3}></Table.Cell>
                    </tr>

                    {isExpand && (
                      <React.Fragment>
                        <tr>
                          <Table.Cell
                            rowSpan={useSales ? 3 : 2}
                            className="freeze-col"
                            customStyle={css({
                              fontSize: 13,
                              lineHeight: "18px !important",
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 40,
                            })}
                          >
                            正社員
                          </Table.Cell>
                          <Table.Cell
                            className="freeze-col"
                            customStyle={css({
                              lineHeight: "18px !important",
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 100,
                            })}
                          >
                            時間数 (h)
                          </Table.Cell>

                          <Table.Cell
                            rowSpan={useSales ? 3 : 2}
                            className="freeze-col"
                            customStyle={css({
                              lineHeight: "18px !important",
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 165,
                            })}
                          >
                            {`実績 \n(予定)`}
                          </Table.Cell>

                          {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              <span>
                                {`${item.workTimeAchievementShopDailyEmployee ||
                                  "0:00"} (${item.workTimeShiftShopDailyEmployee || "0:00"})`}
                              </span>
                            </Table.Cell>
                          ))}

                          <Table.Cell
                            style={{
                              padding: "14px 5px",
                              lineHeight: "18px",
                              textAlign: "right",
                            }}
                          >
                            {monthlyShifts.workTimeInfo && (
                              <span>
                                {`${monthlyShifts?.workTimeInfo?.workTimeAchievementShopMonthlyEmployee ||
                                  "0:00"} (${monthlyShifts?.workTimeInfo?.workTimeShiftShopMonthlyEmployee ||
                                  "0:00"})`}
                              </span>
                            )}
                          </Table.Cell>

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>
                        <tr>
                          <Table.Cell
                            className="freeze-col"
                            customStyle={css({ boxShadow: "inset 0px -1px 0 #dcdcdc !important", left: 100 })}
                          >
                            人件費
                          </Table.Cell>

                          {monthlyShifts?.laborCostInfo?.daily.map((item, i) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              <span>
                                {`${item.laborCostAchievementShopDailyEmployee ||
                                  0} (${item.laborCostShiftShopDailyEmployee || 0})`}
                              </span>
                            </Table.Cell>
                          ))}

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>

                        {useSales && (
                          <tr>
                            <Table.Cell
                              className="freeze-col"
                              customStyle={css({
                                lineHeight: "18px !important",
                                boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                                left: 100,
                              })}
                            >
                              人件費率
                            </Table.Cell>

                            {monthlyShifts?.laborCostInfo?.daily.map((item: any, i: number) => (
                              <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                                <span>
                                  {`${item.laborCostRateAchievementShopDailyEmployee ||
                                    "0.00%"} (${item.laborCostRateShiftShopDailyEmployee || "0.00%"})`}
                                </span>
                              </Table.Cell>
                            ))}

                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                          </tr>
                        )}

                        <tr>
                          <Table.Cell
                            rowSpan={useSales ? 3 : 2}
                            customStyle={css({
                              fontSize: 11,
                              lineHeight: "18px !important",
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 40,
                            })}
                            className="freeze-col"
                          >
                            アルバイト他
                          </Table.Cell>
                          <Table.Cell
                            className="freeze-col"
                            customStyle={css({
                              lineHeight: "18px !important",
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 100,
                            })}
                          >
                            時間数 (h)
                          </Table.Cell>

                          <Table.Cell
                            rowSpan={useSales ? 3 : 2}
                            className="freeze-col"
                            customStyle={css({
                              lineHeight: "18px !important",
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 165,
                            })}
                          >
                            {`実績 \n(予定)`}
                          </Table.Cell>

                          {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              <span>
                                {`${item.workTimeAchievementShopDailyPart ||
                                  "0:00"} (${item.workTimeShiftShopDailyPart || "0:00"})`}
                              </span>
                            </Table.Cell>
                          ))}

                          <Table.Cell
                            style={{
                              padding: "14px 5px",
                              lineHeight: "18px",
                              textAlign: "right",
                            }}
                          >
                            {monthlyShifts.workTimeInfo && (
                              <span>
                                {`${monthlyShifts.workTimeInfo?.workTimeAchievementShopMonthlyPart ||
                                  "0:00"} (${monthlyShifts.workTimeInfo?.workTimeShiftShopMonthlyPart || "0:00"})`}
                              </span>
                            )}
                          </Table.Cell>

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>
                        <tr>
                          <Table.Cell
                            className="freeze-col"
                            customStyle={css({ boxShadow: "inset 0px -1px 0 #dcdcdc !important", left: 100 })}
                          >
                            人件費
                          </Table.Cell>

                          {monthlyShifts?.laborCostInfo?.daily.map((item, i) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              <span>
                                {`${item.laborCostAchievementShopDailyPart || 0} (${item.laborCostShiftShopDailyPart ||
                                  0})`}
                              </span>
                            </Table.Cell>
                          ))}

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>

                        {useSales && (
                          <tr>
                            <Table.Cell
                              className="freeze-col"
                              customStyle={css({
                                lineHeight: "18px !important",
                                boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                                left: 100,
                              })}
                            >
                              人件費率
                            </Table.Cell>

                            {monthlyShifts?.laborCostInfo?.daily.map((item: any, i: number) => (
                              <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                                <span>
                                  {`${item.laborCostRateAchievementShopDailyPart ||
                                    "0.00%"} (${item.laborCostRateShiftShopDailyPart || "0.00%"})`}
                                </span>
                              </Table.Cell>
                            ))}

                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                          </tr>
                        )}
                      </React.Fragment>
                    )}

                    <tr>
                      <Table.Cell
                        rowSpan={useSales ? 3 : 2}
                        className="freeze-col"
                        customStyle={css({
                          boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                          left: 40,
                        })}
                      >
                        合計
                      </Table.Cell>
                      <Table.Cell
                        className="freeze-col"
                        customStyle={css({
                          lineHeight: "18px !important",
                          boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                          left: 100,
                        })}
                      >
                        時間数 (h)
                      </Table.Cell>

                      <Table.Cell
                        rowSpan={useSales ? 3 : 2}
                        className="freeze-col"
                        customStyle={css({
                          lineHeight: "18px !important",
                          boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                          left: 165,
                        })}
                      >
                        {`実績 \n(予定)`}
                      </Table.Cell>

                      {monthlyShifts?.workTimeInfo?.daily.map((item, i) => (
                        <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                          {`${item.workTimeAchievementShopDaily || "0:00"} (${item.workTimeShiftShopDaily || "0:00"})`}
                        </Table.Cell>
                      ))}

                      <Table.Cell
                        style={{
                          padding: "14px 5px",
                          lineHeight: "18px",
                          textAlign: "right",
                        }}
                      >
                        {monthlyShifts.workTimeInfo && (
                          <span>
                            {`${monthlyShifts.workTimeInfo?.workTimeAchievementShopMonthly || "0:00"} (${monthlyShifts
                              .workTimeInfo?.workTimeShiftShopMonthly || "0:00"})`}
                          </span>
                        )}
                      </Table.Cell>

                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>-</Table.Cell>
                    </tr>
                    <tr>
                      <Table.Cell
                        className="freeze-col"
                        customStyle={css({ left: 100, boxShadow: "inset 0px -1px 0 #dcdcdc !important" })}
                      >
                        人件費
                      </Table.Cell>

                      {monthlyShifts?.laborCostInfo?.daily.map((item: any, i: number) => (
                        <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                          <span>
                            {`${item.laborCostAchievementShopDaily || 0} (${item.laborCostShiftShopDaily || 0})`}
                          </span>
                        </Table.Cell>
                      ))}

                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>-</Table.Cell>
                    </tr>

                    {useSales && (
                      <tr>
                        <Table.Cell
                          className="freeze-col"
                          customStyle={css({
                            lineHeight: "18px !important",
                            boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                            left: 100,
                          })}
                        >
                          人件費率
                        </Table.Cell>

                        {monthlyShifts?.laborCostInfo?.daily.map((item: any, i: number) => (
                          <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                            <span>
                              {`${item.laborCostRateAchievementShopDaily ||
                                "0.00%"} (${item.laborCostRateShiftShopDaily || "0.00%"})`}
                            </span>
                          </Table.Cell>
                        ))}

                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </tr>
                    )}
                    {useSales && (
                      <React.Fragment>
                        <tr>
                          <Table.Cell
                            colSpan={3}
                            rowSpan={4}
                            className="freeze-col"
                            customStyle={css({
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 0,
                            })}
                          >
                            売上
                          </Table.Cell>
                          <Table.Cell
                            customStyle={css({
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 165,
                            })}
                            className="freeze-col border-l-none"
                          >
                            前年
                          </Table.Cell>

                          {monthlyShifts?.salesInfo?.salesPrevAchievementDailyList.map(
                            (netSales: string, i: number) => (
                              <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                                {netSales || 0}
                              </Table.Cell>
                            ),
                          )}

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>
                        <tr>
                          <Table.Cell
                            customStyle={css({
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 165,
                            })}
                            className="freeze-col"
                          >
                            予算
                          </Table.Cell>

                          {monthlyShifts?.salesInfo?.salesBudgetDailyList.map((budgetSales: string, i: number) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              {budgetSales || 0}
                            </Table.Cell>
                          ))}

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>
                        <tr>
                          <Table.Cell
                            customStyle={css({
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 165,
                            })}
                            className="freeze-col"
                          >
                            実績
                          </Table.Cell>

                          {monthlyShifts?.salesInfo?.salesAchievementDailyList.map((netSales: string, i: number) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              {netSales || 0}
                            </Table.Cell>
                          ))}

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>
                        <tr>
                          <Table.Cell
                            customStyle={css({
                              boxShadow: "inset 0px -1px 0 #dcdcdc !important",
                              left: 165,
                            })}
                            className="freeze-col"
                          >
                            予実比
                          </Table.Cell>

                          {monthlyShifts?.salesInfo?.salesBudgetRateShopDailyList.map((rate: string, i: number) => (
                            <Table.Cell key={i} style={{ lineHeight: "18px", textAlign: "right" }}>
                              {rate || "0.00%"}
                            </Table.Cell>
                          ))}

                          <Table.Cell>-</Table.Cell>
                          <Table.Cell>-</Table.Cell>
                        </tr>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </tbody>

              {/* ./SUM BOTTOM */}
            </Table>
          </div>
        </BlockUI>
      </div>
    </Fragment>
  );
};

export default TableMonthlyShift;
