import React from "react";
import AttendStampAggregationView from "components/organismos/master/attend/AttendStampAggregation";
import SidebarTemplate from "components/templates/SidebarTemplate";

const AttendStampAggregation = () => {
  const attendFlag = sessionStorage.getItem("attendStampList.attendFlag") || "0";

  return (
    <SidebarTemplate pageTitle={attendFlag === "0" ? "打刻データ集計" : "勤務組織打刻データ集計"}>
      <AttendStampAggregationView />
    </SidebarTemplate>
  );
};

export default AttendStampAggregation;
