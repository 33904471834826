import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface SpecialHolidayStatus {
  cnt: number,
  specialHolidayGrantId: string,
  staffCode: string,
  staffName: string,
  companyCode: string,
  hireDate: Date,
  orgCode: string,
  orgName: string,
  employmentCode: string,
  holidayId: string,
  holidayCode: string,
  holidayName: string,
  autoGrantDateMonth: number,
  autoGrantDateDay: number,
  enteringGrantDay1: number,
  enteringGrantDay2: number,
  enteringGrantDay3: number,
  grantDaysNum: number,
  effectiveStartDate: Date,
  effectiveEndDate: Date,
  useStartDate: Date,
  useEndDate: Date,
  grantDaysNumSum: number,
  autoGrantType: number,
  grantTypeStr: string,
  grantDate: Date,
  workingMonths: number,
  lastDigestedDaysNum: number,
  lastExtinctionDaysNum: number,
  startDate: Date,
  endDate: Date,
  nextGrantDate: Date,
  nextGrantDays: number,
  nextGrantType: number,
  grantType: any,
  firstGrantDaysNum: any,
  lastGrantDaysNum: any,
  isFirstGrant: any,
  useEnteringGrantFlg1: boolean,
  useEnteringGrantFlg2: boolean,
  useEnteringGrantFlg3: boolean,
  useGrantFlg: boolean,
  enteringGrantDate1: any,
  enteringGrantDate2: any,
  enteringGrantDate3: any,
  currentGrantDate: Date,
  currentGrantEndDate: Date,
  digestedDaysNum: number,
  remainDaysNum: number,
  extinctionDaysNum: any,

}


export default class SpecialHolidayStatusDomain {
  constructor(private rawData: SpecialHolidayStatus) {
    // do nothing
  }

  static generateInitial(): SpecialHolidayStatusDomain {
    return new SpecialHolidayStatusDomain({
      cnt: 0,
      specialHolidayGrantId: '',
      staffCode: '',
      staffName: '',
      companyCode: '',
      hireDate: new Date(),
      orgCode: '',
      orgName: '',
      employmentCode: '',
      holidayId: '',
      holidayCode: '',
      holidayName: '',
      autoGrantDateMonth: 0,
      autoGrantDateDay: 0,
      enteringGrantDay1: 0,
      enteringGrantDay2: 0,
      enteringGrantDay3: 0,
      grantDaysNum: 0,
      effectiveStartDate: new Date(),
      effectiveEndDate: new Date(),
      useStartDate: new Date(),
      useEndDate: new Date(),
      grantDaysNumSum: 0,
      autoGrantType: 0,
      grantTypeStr: '',
      grantDate: new Date(),
      workingMonths: 0,
      lastDigestedDaysNum: 0,
      lastExtinctionDaysNum: 0,
      startDate: new Date(),
      endDate: new Date(),
      nextGrantDate: new Date(),
      nextGrantDays: 0,
      nextGrantType: 0,
      grantType: null,
      firstGrantDaysNum: null,
      lastGrantDaysNum: null,
      isFirstGrant: null,
      useEnteringGrantFlg1: false,
      useEnteringGrantFlg2: false,
      useEnteringGrantFlg3: false,
      useGrantFlg: true,
      enteringGrantDate1: null,
      enteringGrantDate2: null,
      enteringGrantDate3: null,
      currentGrantDate: new Date(),
      currentGrantEndDate: new Date(),
      digestedDaysNum: 0,
      remainDaysNum: 0,
      extinctionDaysNum: null,
    });
  }

  getRawData(): SpecialHolidayStatus {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  // get

  get specialHolidayGrantId():string {
    return this.rawData.specialHolidayGrantId;
  }

  get companyCode():string {
    return this.rawData.companyCode;
  }

  get hireDate():string {
    return moment(moment(this.rawData.hireDate).toDate()).format('YYYY年MM月DD日');
  }

  get employmentCode():string {
    return this.rawData.employmentCode;
  }

  get holidayId():string {
    return this.rawData.holidayId;
  }

  set holidayId(holidayId:string) {
    this.rawData.holidayId = holidayId;
  }

  get holidayCode():string {
    return this.rawData.holidayCode;
  }

  get holidayName():string {
    return this.rawData.holidayName;
  }

  get autoGrantDateMonth():number {
    return this.rawData.autoGrantDateMonth;
  }

  get cnt():number {
    return this.rawData.cnt;
  }

  get autoGrantDateDay():number {
    return this.rawData.autoGrantDateDay;
  }

  get enteringGrantDay1():number {
    return this.rawData.enteringGrantDay1;
  }

  get enteringGrantDay2():number {
    return this.rawData.enteringGrantDay2;
  }

  get enteringGrantDay3():number {
    return this.rawData.enteringGrantDay3;
  }

  get grantDaysNum():number {
    return this.rawData.grantDaysNum;
  }

  get effectiveStartDate():string {
    if (!this.rawData.effectiveStartDate) {
      return '';
    }
    return moment(moment(this.rawData.effectiveStartDate).toDate()).format('YYYY年MM月DD日');
  }

  get effectiveEndDate():string {
    if (!this.rawData.effectiveEndDate) {
      return '';
    }
    return moment(moment(this.rawData.effectiveEndDate).toDate()).format('YYYY年MM月DD日');
  }

  get useStartDate():string {
    if (!this.rawData.useStartDate) {
      return '';
    }
    return moment(moment(this.rawData.useStartDate).toDate()).format('YYYY年MM月DD日');
  }

  get useEndDate():string {
    if (!this.rawData.useEndDate) {
      return '';
    }
    return moment(moment(this.rawData.useEndDate).toDate()).format('YYYY年MM月DD日');
  }

  get grantDaysNumSum():number {
    return this.rawData.grantDaysNumSum;
  }

  get grantDaysNumSumStr():string {
    if (!this.rawData.grantDaysNumSum) {
      return '-';
    }
    return `${this.rawData.grantDaysNumSum}日`;
  }

  get autoGrantType():number {
    return this.rawData.autoGrantType;
  }

  get grantTypeStr():string {
    return this.rawData.grantTypeStr;
  }

  get grantDate():string {
    if (!this.rawData.grantDate) {
      return '';
    }
    return moment(moment(this.rawData.grantDate).toDate()).format('YYYY年MM月DD日');
  }

  get workingMonths():number {
    return this.rawData.workingMonths;
  }

  get lastDigestedDaysNum():number {
    return this.rawData.lastDigestedDaysNum;
  }

  get lastExtinctionDaysNum():number {
    return this.rawData.lastExtinctionDaysNum;
  }

  get startDate():string {
    if (!this.rawData.startDate) {
      return '';
    }
    return moment(moment(this.rawData.startDate).toDate()).format('YYYY年MM月DD日');
  }

  get endDate():string {
    if (!this.rawData.startDate) {
      return '';
    }
    return moment(moment(this.rawData.endDate).toDate()).format('YYYY年MM月DD日');
  }

  get nextGrantDate():string {
    if (!this.rawData.nextGrantDate) {
      return '';
    }
    return moment(moment(this.rawData.nextGrantDate).toDate()).format('YYYY年MM月DD日');
  }

  get nextGrantDays():number {
    return this.rawData.nextGrantDays;
  }

  get nextGrantDaysStr():string {
    return `${this.rawData.nextGrantDays}日`;
  }

  get nextGrantType():number {
    return this.rawData.nextGrantType;
  }

  get grantType():string {
    return this.rawData.grantType;
  }

  get firstGrantDaysNum():string {
    return this.rawData.firstGrantDaysNum;
  }

  get lastGrantDaysNum():string {
    return this.rawData.lastGrantDaysNum;
  }

  get isFirstGrant():string {
    return this.rawData.isFirstGrant;
  }

  get useEnteringGrantFlg1():boolean {
    return this.rawData.useEnteringGrantFlg1;
  }

  get useEnteringGrantFlg2():boolean {
    return this.rawData.useEnteringGrantFlg2;
  }

  get useEnteringGrantFlg3():boolean {
    return this.rawData.useEnteringGrantFlg3;
  }

  get useGrantFlg():boolean {
    return this.rawData.useGrantFlg;
  }

  get enteringGrantDate1():string {
    return this.rawData.enteringGrantDate1;
  }

  get enteringGrantDate2():string {
    return this.rawData.enteringGrantDate2;
  }

  get enteringGrantDate3():string {
    return this.rawData.enteringGrantDate3;
  }

  get currentGrantDate():string {
    return moment(moment(this.rawData.currentGrantDate).toDate()).format('YYYY年MM月DD日');
  }

  get currentGrantEndDate():string {
    return moment(moment(this.rawData.currentGrantEndDate).toDate()).format('YYYY年MM月DD日');
  }

  get digestedDaysNum():number {
    return this.rawData.digestedDaysNum;
  }

  get digestedDaysNumStr():string {
    return `${this.rawData.digestedDaysNum}日`;
  }

  get remainDaysNum():number {
    return this.rawData.remainDaysNum;
  }

  get remainDaysNumStr():string {
    return `${this.rawData.remainDaysNum}日`;
  }

  get extinctionDaysNum():string {
    return this.rawData.extinctionDaysNum;
  }

  // custom data use DataTable
}
