/** @jsx jsx */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useContext } from "react";
import { grayScale } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import PrimaryButton, { InfoButton, DeleteButton } from "components/atoms/Button";
import { css, jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import TextForm from "components/molecules/TextForm";
import Checkbox from "components/molecules/Checkbox";
import ToastModal from "components/organismos/ToastModal";
import Modal from "components/molecules/Modal";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import { useApplyingTransferForm } from "./hook";
import { postApprovedStockPaid, postBulkApproveStockPaid } from "../api";
import BlockUI from "components/molecules/BlockUi";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import IconLabelButton from "components/molecules/IconLabelButton";
import { attendApplicationApprovalContext } from "../hooks";
import { renderError } from "utility/renderMessage";
import { calcTime } from "utility/dateUtil";

const { errorNotification, successNotification } = useToastNotification();

const StockPaidHoliday: React.FC = () => {
  const context = useContext(attendApplicationApprovalContext);
  const {
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeConfirmApplyModal,
    extractSingleStockPaidApproveData,
    extractBulkStockPaidApproveData,
    extractSingleStockPaidRejectData,
    rejectReasonOnchange,
    buttonType,
    setButtonType,
    selectedIndex,
    setSelectedIndex,
    changeReason,
    setChangeReason,
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    selectChangelog,
    setSelectChangelog,
    fetchStockPaid,
  } = useApplyingTransferForm(context.applyingStockPaidList);

  const handleSubmit = async () => {
    const remainingList = context.applyingStockPaidList.filter((item, position) => !selectedIndex.includes(position)); // remove checked requests
    const checkedList = context.applyingStockPaidList.filter((item, position) => selectedIndex.includes(position)); // checked requests

    try {
      if (buttonType === 1) {
        rejectRequest(checkedList, remainingList);
      } else {
        approveRequest(checkedList, remainingList);
      }
    } catch (error) {
      errorNotification(error.message);
    }
    setConfirmApplyModalOpen(false);
    setSelectedIndex([]);
  };

  const approveRequest = async (checkedList: any[], remainingList: any[]) => {
    context.setIsLoading(true);
    if (checkedList.length > 1) {
      await postBulkApproveStockPaid(extractBulkStockPaidApproveData(checkedList))
        .then(() => {
          context.setIsLoading(false);
          successNotification("承認しました。");
          context.setApplyingStockPaidList(remainingList);
        })
        .catch((error: any) => {
          context.setIsLoading(false);
          const mess = renderError(error);
          errorNotification(mess);
        });
    } else {
      await postApprovedStockPaid(extractSingleStockPaidApproveData(checkedList))
        .then(() => {
          context.setIsLoading(false);
          successNotification("承認しました。");
          context.setApplyingStockPaidList(remainingList);
        })
        .catch((error: any) => {
          context.setIsLoading(false);
          const mess = renderError(error);
          errorNotification(mess);
        });
    }
  };

  const rejectRequest = async (checkedList: any[], remainingList: any[]) => {
    context.setIsLoading(true);
    if (!checkedList[0].rejectReason) {
      context.setIsLoading(false);
      errorNotification("差戻コメントを入力してください。");
      return;
    }
    try {
      await postApprovedStockPaid(extractSingleStockPaidRejectData(checkedList));
      context.setIsLoading(false);
      successNotification("差戻しました。");
      context.setApplyingStockPaidList(remainingList);
    } catch (error) {
      const mess = renderError(error);
      errorNotification(mess);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(false);
    setSelectedIndex([]);
  }, [context.applyingHolidayList]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Checkbox
            id="checkbox_stockPaid_all"
            name="checkbox_stockPaid_all"
            value=""
            checked={isChecked}
            isCheckBoxTable={true}
            onChange={() => {
              setIsChecked(!isChecked);
              if (!isChecked) {
                const indexArr: any = [];
                const data = document.querySelectorAll(".rowDataIndex1");
                data.forEach((e) => {
                  indexArr.push(Number(e.getAttribute("row-data-index")));
                });
                setSelectedIndex(indexArr);
                return false;
              }
              setSelectedIndex([]);
            }}
          />
        ),
        disableSortBy: true,
        id: "",
        accessor: "checkbox",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              id={`checkbox_stockPaid_${cell.row.index}`}
              name="name"
              label=""
              value={`${cell.row.index}`}
              checked={selectedIndex.includes(cell.row.index)}
              isCheckBoxTable={true}
              onChange={() => {
                if (selectedIndex.includes(cell.row.index)) {
                  setSelectedIndex(selectedIndex.filter((selected) => selected !== cell.row.index));
                } else {
                  setSelectedIndex([...selectedIndex, cell.row.index]);
                }
              }}
            />
            <input type="hidden" className="rowDataIndex1" row-data-index={cell.row.index} />
          </div>
        ),
      },
      {
        Header: "決裁",
        id: "decision",
        accessor: "decision",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div>
            <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
              <InfoButton
                text="承認"
                onClick={() => {
                  setButtonType(3);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
                customStyle={css({
                  marginRight: "15px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  height: "34px",
                })}
                minWidth="40px"
              />
              <DeleteButton
                text="差戻"
                onClick={() => {
                  setChangeReason("");
                  setButtonType(1);
                  setSelectedIndex([cell.row.index]);
                  // setIsDelete([cell.row.index])
                  setConfirmApplyModalOpen(true);
                }}
                customStyle={css({
                  borderRadius: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  height: "34px",
                })}
                minWidth="40px"
              />
            </FlexBox>
          </div>
        ),
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: "対象期間",
        id: "",
        accessor: "targetStartDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <div>
              <div>
                {cell.row.original.applyTargetDateType == 0
                  ? cell.row.original.targetStartDate
                  : cell.row.original.targetStartDate + " 〜 " + cell.row.original.targetEndDate}
              </div>
              <IconLabelButton
                onClick={() => {
                  fetchStockPaid(cell.row.original.modifierApplicationId);
                  setChangeLogModalOpen(true);
                  setSelectChangelog("0");
                }}
                text="更新履歴"
                textAlign="center"
                isIcon={false}
              />
            </div>
          </div>
        ),
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "休暇",
        accessor: "holidayName",
      },
      {
        Header: "休暇単位",
        accessor: "holidayDigestiveUnitName",
      },
      {
        Header: "開始時間",
        accessor: "holidayStartHm",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {(() => {
              if (
                (cell.row.original.holidayDigestiveUnit == 2 ||
                  cell.row.original.holidayDigestiveUnit == 3 ||
                  cell.row.original.holidayDigestiveUnit == 4) &&
                cell.row.original.holidayStartHm
              ) {
                if (
                  moment(cell.row.original.holidayStartHm).format("YYYYMMDD") !==
                  moment(cell.row.original.targetDate).format("YYYYMMDD")
                ) {
                  return `${moment(cell.row.original.holidayStartHm).format("HH:mm")}(翌)`;
                }
                return moment(cell.row.original.holidayStartHm).format("HH:mm");
              }
              return "";
            })()}
          </div>
        ),
      },
      {
        Header: "終了時間",
        accessor: "holidayEndHm",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {(() => {
              if (
                (cell.row.original.holidayDigestiveUnit == 2 ||
                  cell.row.original.holidayDigestiveUnit == 3 ||
                  cell.row.original.holidayDigestiveUnit == 4) &&
                cell.row.original.holidayEndHm
              ) {
                if (
                  moment(cell.row.original.holidayEndHm).format("YYYYMMDD") !==
                  moment(cell.row.original.targetDate).format("YYYYMMDD")
                ) {
                  return `${moment(cell.row.original.holidayEndHm).format("HH:mm")}(翌)`;
                }
                return moment(cell.row.original.holidayEndHm).format("HH:mm");
              }
              return "";
            })()}
          </div>
        ),
      },
      {
        Header: "取得時間",
        accessor: "holidayTimes",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {cell.row.original.holidayDigestiveUnit === 2 &&
              cell.row.original.holidayTimes &&
              calcTime(context.detailRole.usabilityGeneralItem1, cell.row.original.holidayTimes)}
          </div>
        ),
      },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.applyingStockPaidList, selectedIndex, fetchStockPaid, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <div
      css={css`
        table th:nth-of-type(1) {
          width: 40px;
        }
        table th:nth-of-type(2) {
          width: 180px;
        }
        table th:nth-of-type(3) {
          width: 140px;
        }
        table th:nth-of-type(4) {
          width: 140px;
        }
        table th:nth-of-type(5) {
          width: 140px;
        }
        table th:nth-of-type(6) {
          width: 140px;
        }
        table th:nth-of-type(7) {
          width: 170px;
        }
        table th:nth-of-type(8) {
          width: 80px;
        }
        table th:nth-of-type(9) {
          width: 80px;
        }
        table th:nth-of-type(10) {
          width: 80px;
        }
        table th:nth-of-type(11) {
          width: 120px;
        }
        table th:nth-of-type(12) {
          width: 120px;
        }
        table th:nth-of-type(13) {
          width: 80px;
        }
        table th:nth-of-type(14) {
          width: 200px;
        }
        .labelReject {
          margin-top: 40px;
          text-align: left;
          padding: 0 60px;
        }
        #rejectReason {
          height: 64px;
          margin-top: 10px;
          width: 100%;
          color: #333333;
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          border-radius: 2px;
          padding: 5px;
          font-size: 14px;
          font-family: san-serif;
        }
        #rejectReason:focus {
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          border-radius: 2px;
        }
      `}
    >
      <Accordion text={`積立有給申請 (${context.applyingStockPaidList.length || 0}件）`} callBack={() => {}}>
        <PrimaryButton
          text="一括承認"
          onClick={() => {
            setButtonType(2);
            setConfirmApplyModalOpen(true);
          }}
          disabled={selectedIndex.length === 0}
          customStyle={css({ float: "right", marginRight: "15px" })}
        />
        <BlockUI blocking={context.isLoading}>
          <DataTable
            onClickPagination={() => {
              setIsChecked(false);
              setSelectedIndex([]);
            }}
            iconSize="16px"
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            tableId={1}
            columns={columns}
            data={context.applyingStockPaidList}
            isGlobalFilter={true}
            sortBy={[
              { id: "targetStartDate", desc: true },
              { id: "targetPerson", desc: true },
            ]}
          />
        </BlockUI>
      </Accordion>

      <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

      <Modal
        open={confirmApplyModalOpen}
        closeHandler={closeConfirmApplyModal}
        onSubmit={handleSubmit}
        title={buttonType === 3 ? "申請の承認" : buttonType === 1 ? "申請の差戻" : "確認メッセージ"}
        // eslint-disable-next-line no-nested-ternary
        typeSubmit={buttonType === 1 ? "reject" : "confirm"}
        // eslint-disable-next-line no-nested-ternary
        note={
          buttonType === 0
            ? "承認します。よろしいですか？"
            : buttonType === 1
            ? "申請を差戻します。よろしいですか？"
            : buttonType === 3
            ? "申請を承認します。よろしいですか？"
            : "一括承認します。よろしいですか？"
        }
        submitText={buttonType === 1 ? "差戻する" : buttonType === 3 ? "承認する" : "承認"}
      >
        {buttonType === 1 && (
          <React.Fragment>
            <div className="labelReject">
              <TextForm
                name="rejectReason"
                label="差戻コメント"
                value={changeReason}
                placeHolder=""
                errorMsg=""
                onChange={(e) => {
                  rejectReasonOnchange(selectedIndex[0], e);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>

      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} request="積立有給申請" />
        </Modal>
      </div>
      {/* ./Change log  */}
    </div>
  );
};

export default StockPaidHoliday;
