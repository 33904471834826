/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { setConfiguration } from "react-grid-system";
import moment from "moment";
// import { withAuthenticator } from 'aws-amplify-react'; // ログイン画面のモジュールの読み込み
import Amplify from "aws-amplify"; // amplifyのモジュールの読み込み

import ToastContainerWrapper from "components/molecules/ToastNotification";
import OrganizationAddPage from "components/pages/master/general/organization/OrganizationAddPage";
import OrganizationsPage from "components/pages/master/general/organization/OrganizationsPage";
import MasterRoleSettingPage from "components/pages/master/general/masterRole/MasterRoleSettingPage";
import MasterRoleImportPage from "components/pages/master/general/masterRole/MasterRoleImportPage";
import MasterRoleAddPage from "components/pages/master/general/masterRole/MasterRoleAddPage";
import MasterRoleEditPage from "components/pages/master/general/masterRole/MasterRoleEditPage";
import OrganizationManagersPage from "components/pages/master/general/organization/OrganizationManagersPage";
import AreasPage from "components/pages/master/sales/area/AreasPage";
import AreasAddPage from "components/pages/master/sales/area/AreasAddPage";
import AreasImportPage from "components/pages/master/sales/area/AreasImportPage";
import BusinessCategoryAddPage from "components/pages/master/sales/businessCategory/BusinessCategoryAddPage";
import BusinessCategoryPage from "components/pages/master/sales/businessCategory/BusinessCategoryPage";
import BusinessCategoryImportPage from "components/pages/master/sales/businessCategory/BusinessCategoryImportPage";
import LoginPage from "components/pages/login/LoginPage";
import SignUpPage from "components/pages/login/SignUpPage";
import ActivationPage from "components/pages/login/ActivationPage";
import ForgotPasswordPage from "components/pages/login/ForgotPasswordPage";
import ForgotPasswordSubmitPage from "components/pages/login/ForgotPasswordSubmitPage";
import EmploymentFormAddPage from "components/pages/master/laborAgreement/employment/EmploymentFormAddPage";
import EmploymentFormPage from "components/pages/master/laborAgreement/employment/EmploymentFormPage";
import ExecutiveIndexPage from "components/pages/master/laborAgreement/Executive/ExecutiveIndexPage";
import ExecutiveFormAddPage from "components/pages/master/laborAgreement/Executive/ExecutiveFormAddPage";
import ExecutiveImportPage from "components/pages/master/laborAgreement/Executive/ExecutiveFormImportPage";
import HolidayFormAddPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/HolidayImportPage";
import HolidayCreatePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/HolidayCreatePage";
import VacationCreatePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/VacationCreatePage";
import VacationMasterPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/VacationMasterPage";
import BusinessMasterPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/BusinessMasterPage";
import BusinessCreatePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/BusinessCreatePage";
import AdvertisingAddPage from "components/pages/master/sales/advertisingMaster/AdvertisingAddPage";
import FiscalYearsPage from "components/pages/master/sales/fiscalYearMaster/FiscalYearsPage";
import FiscalYearAddPage from "components/pages/master/sales/fiscalYearMaster/FiscalYearAddPage";
import TimeSettingPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/TimeSettingsPage";
import MasterSettingAgreement36Create from "components/pages/master/MasterSettingAgreement36CreatePage";
import MasterSettingAgreement36 from "components/pages/master/MasterSettingAgreement36Page";
import AttendAgreement36 from "components/pages/attend/AttendAgreement36Page";
import AttendAgreement36ApplicationPage from "components/pages/attend/AttendAgreement36ApplicationPage";
import MonthlyAgreement36Page from "components/pages/attend/MonthlyAgreement36Page";
import YearlyAgreement36Page from "components/pages/attend/YearlyAgreement36Page";
import CallbackLogin from "components/pages/login/CallbackLogin";
import AttendAllowanceCreatePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/AttendAllowanceCreatePage";
import AttendAllowancePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/AttendAllowancePage";

import MasterOrganizeSettingPage from "components/pages/master/laborAgreement/MasterOrganizeSettingPage";

// Setting IC Card
import MasterICCardSettingPage from "components/pages/master/MasterICCardSettingPage";
import MasterICCardStaffSettingPage from "components/pages/master/MasterICCardStaffSettingPage";

// Import IC Card
import MasterICCardImportPage from "components/pages/master/MasterICCardImportPage";

import TaxRateCaptureSetting from "components/pages/master/sales/taxRateCaptureSetting";
// master term
import TermMasterAddPage from "components/pages/master/attend/term/TermMasterAddPage";

// master paid holiday pattern
import PaidHolidayPatternAddPage from "components/pages/master/attend/PaidHolidayPattern/PaidHolidayPatternAddPage";

// master working hours
import WorkingHoursAddPage from "components/pages/master/attend/working_hours/WorkingHoursAddPage";

// master salary csv setting
import SalarryCsvSettingFormPage from "components/pages/master/laborAgreement/SalaryCsvSetting/SalarryCsvSettingFormPage";

// master import Time output layout
import TimeOutputLayoutAddPage from "components/pages/master/attend/TimeOutputLayout/TimeOutputLayoutAddPage";
import TimeOutputLayoutListPage from "components/pages/master/attend/TimeOutputLayout/TimeOutputLayoutListPage";

// master import  Attendance Master
import AttendanceMasterPage from "components/pages/master/attend/AttendanceMaster/AttendanceMasterPage";
import AttendanceAddPage from "components/pages/master/attend/AttendanceMaster/AttendanceAddPage";

// attend holiday manegement
import HolidayManagementPage from "components/pages/master/attend/HolidayManagementPage/HolidayManagementPage";
import HolidayManagementImportPage from "components/pages/master/attend/HolidayManagementPage/HolidayManagementImportPage";

// sales report
import SalesMonthlyReportPage from "components/pages/master/storeManagement/salesReport/SalesMonthlyReportPage";
import SalesDailyReportPage from "components/pages/master/storeManagement/salesReport/SalesDailyReportPage";

// attend time recorder
// import AttendTimeRecorderPage from 'components/pages/master/attend/AttendTimeRecorder/AttendTimeRecorderPage';

// attend application approval
import AttendApplicationApprovalPage from "components/pages/master/attend/AttendApplicationApproval";

// attend application approval
import AttendApplicationListPage from "components/pages/master/attend/AttendApplicationListPage";

// approval

import ApprovalModificationApplicationPage from "components/pages/master/attend/ApprovalApplicationPage/ApprovalModificationApplicationPage";
import ApprovalOvertimeApplicationPage from "components/pages/master/attend/ApprovalApplicationPage/ApprovalOvertimeApplicationPage";
import ApprovalHolidayworkApplicationPage from "components/pages/master/attend/ApprovalApplicationPage/ApprovalHolidayworkApplicationPage";
import ApprovalTransferApplicationPage from "components/pages/master/attend/ApprovalApplicationPage/ApprovalTransferApplicationPage";
import ApprovalHolidayApplicationPage from "components/pages/master/attend/ApprovalApplicationPage/ApprovalHolidayApplicationPage";

import OrganizationSpecialSalaryPage from "components/pages/master/general/organization/OrganizationSpecialSalaryPage";
import OrganizationEditPage from "components/pages/master/general/organization/OrganizationEditPage";
import WorkingDaysCreatePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PrescribedDaysCreatePage/WorkingDaysCreatePage";
import WorkingDaysEditPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PrescribedDaysEditPage/WorkingDaysEditPage";
import PrescribedDaysPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PrescribedDaysPage";
import AdvertisingMasterPage from "components/pages/master/sales/advertisingMaster";
import OrganizationCsvUploadPage from "components/pages/master/general/organization/OrganizationCsvUploadPage/OrganizationCsvUploadPage";
import AdvertisingImportCreatePage from "components/pages/master/sales/advertisingMaster/AdvertisingImportCreatePage";
import AgreementMasterPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage";
import PaymentTypePage from "components/pages/master/sales/paymentTypeMaster";
import MediaMasterCreatePage from "components/pages/master/sales/paymentTypeMaster/MediaMasterCreatePage";
import PaymentImportCreatePage from "components/pages/master/sales/paymentTypeMaster/PaymentImportCreatePage";
import StaffsPage from "components/pages/master/general/staff/StaffsPage";
import StaffEditPage from "components/pages/master/general/staff/StaffEditPage";
import StaffCsvUploadPage from "components/pages/master/general/staff/StaffCsvUploadPage/StaffCsvUploadPage";
import StaffAddPage from "components/pages/master/general/staff/StaffAddPage";
import ShiftPatternAddPage from "components/pages/master/attend/ShiftPatternPage/ShiftPatternAddPage/ShiftPatternAddPage";
import SalesMenuType from "components/pages/master/sales/salesMenuType";
import HeadquartersFinalPage from "components/pages/master/attend/HeadquartersFinalPage";
import AttendAchievementLockPage from "components/pages/master/attend/AttendAchievementLock";
import AttendMonthlyList from "components/pages/master/attend/AttendMonthlyList";
import AttendResult from "components/pages/master/attend/AttendResult";
import PersonalSetting from "components/pages/personalSetting/PersonalSetting";
import AttendStampList from "components/pages/master/attend/AttendStampList";
import StampModificationPage from "components/pages/master/attend/StampModificationPage";
import HolidayApplicationPage from "components/pages/master/attend/HolidayApplicationPage";
import AllowanceApplicationPage from "components/pages/master/attend/AllowanceApplicationPage";
import OvertimeApplicationPage from "components/pages/master/attend/OvertimeApplicationPage";
import HolidayWorkApplicationPage from "components/pages/master/attend/HolidayWorkApplicationPage";
import TransferApplicationPage from "components/pages/master/attend/TransferApplicationPage";
import AttendHelpListPage from "components/pages/master/attend/AttendHelpListPage";
import AttendAchievementOutput from "components/pages/master/attend/AttendAchievementOutput";
import ShiftPage from "components/pages/master/ShiftPage";
// TimeRecalculation
import TimeRecalculationPage from "components/pages/master/atendanceManagement/TimeRecalculationPage";
import PayMeCooperationPage from "components/pages/master/atendanceManagement/PayMeCooperationPage";
import MonthlyReportPage from "components/pages/master/storeManagement/report/MonthlyReportPage";

import AttendPayslipImport from "components/pages/master/atendanceManagement/AttendPayslipImport";

// salesBudgetYear
import salesBudgetYear from "components/pages/master/storeManagement/salesBudgetYear";
import salesBudgetYearImport from "components/pages/master/storeManagement/salesBudgetYearImport";
import TimeRecorderPage from "components/pages/master/attend/TimeRecorder/TimeRecorderPage";
import StampHistoryPage from "components/pages/master/atendanceManagement/StampHistoryPage";
import GpsStampHistoryPage from "components/pages/attend/GpsStampHistoryPage";
import KaoriaoHistoryPage from "components/pages/attend/KaoriaoHistoryPage";
// import StampImportPage from 'components/pages/attend/StampImportPage';

// new time recorder
import RecorderPage from "components/pages/timeRecorder/RecorderPage";
import RecorderPageV1 from "components/pages/timeRecorder_v1";
import RecorderPageV2 from "components/pages/timeRecorder_v2";

import WorkingStatusPage from "components/pages/master/workingStatus/WorkingStatusPage";
import SessionExpireError from "components/pages/error/SessionExpireError";
import SalesBudgetMonth from "components/pages/master/storeManagement/salesBudgetMonth/salesBudgetMonth";
// import DashboardPage from 'components/pages/master/general/dashboard/DashboardPage';
// import StampApprovalPage from 'components/pages/attend/approval/stamp';
// import HolidayApprovalPage from 'components/pages/attend/approval/holiday';
// import OvertimeApprovalPage from 'components/pages/attend/approval/overtime';
// import HolidayworkApprovalPage from 'components/pages/attend/approval/holidaywork/HolidayworkApprovalPage';
// import TransferApprovalPage from 'components/pages/attend/approval/transfer';
import SeparateBillPage from "components/pages/master/storeManagement/report/ SeparateBillReport/ SeparateBillPage";
import YearlySalesManagementPage from "components/pages/master/storeManagement/report/YearlySalesManagementPage";
import FormByProduct from "components/pages/master/storeManagement/report/FormByProduct";
import TimeRecorderGPSPage from "components/pages/attend/timeRecorderGPS";
import TimeListPage from "components/pages/master/storeManagement/report/TimeList";
import MasterShopSpecifyPage from "components/pages/master/laborAgreement/MasterShopSpecifyPage";
import AttendanceErrorPage from "components/pages/master/attendanceRecord/AttendanceErrorPage";
import MasterShopFaceRegistrationPage from "components/pages/master/laborAgreement/MasterShopFaceRegistrationPage";
import MasterShopFaceRegistrationPageV1 from "components/pages/master/laborAgreement/MasterShopFaceRegistrationPageV1";
import HopeShiftPage from "components/pages/master/attend/HopeShiftPage";
import ShiftViewPage from "components/pages/master/attend/ShiftViewPage";
import HelpPage from "components/pages/help";
import TimeOutputItemPage from "components/pages/master/attend/TimeOutputLayout/TimeOutputItemPage";
import DayOutputItemPage from "components/pages/master/attend/TimeOutputLayout/DayOutputItemPage";
import DailyShift from "components/pages/master/shift/daily";
import MonthlyShift from "components/pages/master/shift/monthly";
import MonthlyShiftImportLayoutPage from "components/pages/master/shift/monthly/MonthlyShiftImportForm/MonthlyShiftImportLayoutPage";
import PrivateRoute from "./PrivateRoute";
import SpecialSalaryPatternAddPage from "./components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/SpecialSalaryPatternAddPage";
import SpecialSalaryPatternEditPage from "./components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/SpecialSalaryPatternEditPage";

import ShiftPatternPage from "./components/pages/master/attend/ShiftPatternPage/ShiftPatternIndexPage";
import HolidayMasterPage from "./components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/HolidayMasterPage";
import PaidGrantPatternPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PaidGrantPatternPage";
import TimeZonePage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/TimeZonePage";
import PrescribedHoursPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/PrescribedHoursPage";
import TargetApplicationForm from "components/pages/master/general/organization/TargetApplicationForm";
import PaidHolidayAlertPage from "components/pages/master/attend/PaidHolidayAlert";
import WorkScheduleChangeApplication from "components/pages/master/attend/WorkScheduleChangeApplication/ApprovalModificationApplicationPage/WorkScheduleChangeApplicationPage";
import Logout from "components/pages/LogoutPage";
import Modal from "components/molecules/Modal";
import PanelDescription from "components/atoms/PanelDescription";
import PeopleTenantPage from "components/pages/login/PeopleTenantPage/PeopleTenantPage";
import DeliousAuthPage from "components/pages/login/DeliousAuthPage/DeliousAuthPage";

import ExpiredPage from "components/pages/ExpiredPage";
import SingleLogout from "components/pages/SingleLogoutPage";
import ErrorPage from "components/pages/login/ErrorPage";

import AttendCSVOutputPage from "components/pages/master/attend/AttendCSVOutputPage";
import AttendApplicationApprovalDetailPage from "components/pages/master/attend/AttendApplicationApprovalDetail/AttendApplicationApprovalDetailPage";

import NotFound from "components/pages/NotFound";
import AttendApplicationApprovalV3Page from "components/pages/master/attend/AttendApplicationApprovalV3";
import AttendApplicationListV3Page from "components/pages/master/attend/AttendApplicationListV3Page";
import StockPaidPage from "components/pages/attend/StockPaidPage";
import AttendStampAggregation from "components/pages/master/attend/AttendStampAggregation";
import SpecialSalaryPatternPage from "components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/SpecialSalaryPatternPage";
import { getCognitoInfo } from "api/login";
import { generateCognitoInfo } from "utility/generateCognitoInfo";
import { getAuthorizedFromDelious } from "utility";

const logoFlag = process.env.REACT_APP_LOGO_FLAG;

// [SHOP]

// react-grid-systemの設定
setConfiguration({
  gutterWidth: 0,
});

// momentの日本語設定
moment.locale("ja", {
  months: ["１月", "2月", "3月", "4月", "5月", "６月", "７月", "８月", "９月", "１０月", "１１月", "１２月"],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});

const App: React.FC = () => {
  // const isListeningStorageChange  = sessionStorage.getItem('isListeningStorageChange') || false;
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE == "true" || false;
  const [open, setOpen] = React.useState<boolean>(false);
  const [isFetchCognitoInfo, setIsFetchCognitoInfo] = React.useState<boolean>(false);
  const checkUserLog = () => {
    if (window.location.pathname.startsWith("/login") || window.location.pathname.startsWith("/expired")) return;
    let isLogged = localStorage.getItem("loginUser.isLogged");
    let mail = sessionStorage.getItem("loginUser.mail");
    if (isLogged === "true" || mail == null) {
    } else if (isLogged === "false" || isLogged === null) {
      sessionStorage.setItem("isExpired", "true");
      if (isAuthorizedFromPeople) {
        window.location.replace("/expired");
      } else {
        setOpen(true);
      }
    }
  };
  // if (!isListeningStorageChange){
  window.removeEventListener("storage", checkUserLog);
  window.addEventListener("storage", checkUserLog);

  // sessionStorage.setItem('isListeningStorageChange', 'true');
  // }
  const logoutHandler = function() {
    setOpen(false);
    if (isAuthorizedFromPeople) {
      window.location.replace("/singleLogout");
    } else {
      window.location.replace("/login");
    }
  };

  useEffect(() => {
    let mail = sessionStorage.getItem("loginUser.mail") || "";
    let companyCode =
      sessionStorage.getItem("loginUser.companyCode") || localStorage.getItem("loginUser.companyCode") || "";

    if (mail || companyCode) {
      getCognitoInfo(companyCode, mail)
        .then((res) => {
          localStorage.setItem("loginUser.companyCode", res.companyCode);
          sessionStorage.setItem("loginUser.companyCode", res.companyCode);

          // 設定情報をAmplifyに反映させる
          Amplify.configure(generateCognitoInfo(res));
        })
        .finally(() => {
          setIsFetchCognitoInfo(true);
        });
    } else {
      setIsFetchCognitoInfo(true);
    }
  }, []);

  if (!isFetchCognitoInfo) return null;

  return (
    <>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
      </head>
      <Router>
        <Switch>
          {isAuthorizedFromPeople ? (
            ""
          ) : (
            <Route exact={true} path={getAuthorizedFromDelious() ? "/login/:companyCode" : "/login"}>
              <LoginPage />
            </Route>
          )}
          {!isAuthorizedFromPeople ? (
            ""
          ) : (
            <Route exact={true} path="/people/:tenant">
              <PeopleTenantPage />
            </Route>
          )}
          {!isAuthorizedFromPeople ? (
            ""
          ) : (
            <Route exact={true} path="/error">
              <ErrorPage />
            </Route>
          )}
          <Route exact={true} path="/sso/login/:companyCode/">
            <DeliousAuthPage />
          </Route>
          <Route exact={true} path="/404">
            <NotFound />
          </Route>
          <Route exact={true} path={getAuthorizedFromDelious() ? "/signup/:companyCode/" : "/signup"}>
            <SignUpPage />
          </Route>
          <Route exact={true} path="/activation/:mailAddress?">
            <ActivationPage />
          </Route>
          <Route exact={true} path={getAuthorizedFromDelious() ? "/forgotPassword/:companyCode" : "/forgotPassword"}>
            <ForgotPasswordPage />
          </Route>
          <Route
            exact={true}
            path={
              getAuthorizedFromDelious()
                ? "/forgotPasswordSubmit/:companyCode/:mailAddress"
                : "/forgotPasswordSubmit/:mailAddress"
            }
          >
            <ForgotPasswordSubmitPage />
          </Route>
          // Help
          <PrivateRoute path="/help" component={HelpPage} />
          // Staff
          <PrivateRoute path="/staffs/add" component={StaffAddPage} />
          <PrivateRoute path="/staffs/csv" component={StaffCsvUploadPage} />
          <PrivateRoute path="/staffs/:staffCode" component={StaffEditPage} />
          <PrivateRoute path="/staffs" component={StaffsPage} />
          // Organization
          <PrivateRoute path="/organizations/targetApplication" component={TargetApplicationForm} />
          <PrivateRoute path="/organizations/add" component={OrganizationAddPage} />
          <PrivateRoute path="/organizations/csv" component={OrganizationCsvUploadPage} />
          <PrivateRoute path="/organizations/:orgCode/specialsalary" component={OrganizationSpecialSalaryPage} />
          <PrivateRoute path="/organizations/:orgCode/managers" component={OrganizationManagersPage} />
          <PrivateRoute path="/organizations/:orgCode" component={OrganizationEditPage} />
          <PrivateRoute path="/organizations" component={OrganizationsPage} />
          <PrivateRoute path="/masterRoleSetting/import" component={MasterRoleImportPage} />
          <PrivateRoute path="/masterRoleSetting/add" component={MasterRoleAddPage} />
          <PrivateRoute path="/masterRoleSetting/copy/:roleId" component={MasterRoleAddPage} />
          <PrivateRoute
            path="/masterRoleSetting/:action/:roleName/:roleCode/:roleId/"
            component={MasterRoleEditPage}
          />
          <PrivateRoute path="/masterRoleSetting" component={MasterRoleSettingPage} />
          <PrivateRoute path="/businessMaster/edit/:businessId" component={BusinessCreatePage} />
          <PrivateRoute path="/businessMaster/create" component={BusinessCreatePage} />
          <PrivateRoute path="/businessMaster" component={BusinessMasterPage} />
          <PrivateRoute path="/vacationMaster/edit/:vacationId" component={VacationCreatePage} />
          <PrivateRoute path="/vacationMaster/create" component={VacationCreatePage} />
          <PrivateRoute path="/vacationMaster" component={VacationMasterPage} />
          <PrivateRoute path="/attendAllowance/edit/:allowanceId" component={AttendAllowanceCreatePage} />
          <PrivateRoute path="/attendAllowance/create" component={AttendAllowanceCreatePage} />
          <PrivateRoute path="/attendAllowance" component={AttendAllowancePage} />
          <PrivateRoute path="/holiday/edit/:publicHolidayId" component={HolidayCreatePage} />
          <PrivateRoute path="/holiday/create" component={HolidayCreatePage} />
          <PrivateRoute path="/holiday/import" component={HolidayFormAddPage} />
          <PrivateRoute path="/holiday" component={HolidayMasterPage} />
          <PrivateRoute path="/agreementMaster" component={AgreementMasterPage} />
          <PrivateRoute path="/executive/add" component={ExecutiveFormAddPage} />
          <PrivateRoute path="/executive/import" component={ExecutiveImportPage} />
          <PrivateRoute path="/executive/edit/:companyCode/:executiveId" component={ExecutiveFormAddPage} />
          <PrivateRoute path="/executive" component={ExecutiveIndexPage} />
          // Master attend setting
          <PrivateRoute path="/masterAttendSetting" component={TimeSettingPage} />
          // Master setting Agreement 36
          <PrivateRoute
            path="/masterSettingAgreement36/edit/:agreementsId"
            component={MasterSettingAgreement36Create}
          />
          <PrivateRoute path="/masterSettingAgreement36/create" component={MasterSettingAgreement36Create} />
          <PrivateRoute path="/masterSettingAgreement36" component={MasterSettingAgreement36} />
          <PrivateRoute path="/monthlyAgreement36" component={MonthlyAgreement36Page} />
          <PrivateRoute path="/yearlyAgreement36" component={YearlyAgreement36Page} />
          <PrivateRoute exact={true} path="/agreement36" component={AttendAgreement36} />
          <PrivateRoute
            path="/agreement36/attendAgreement36Application"
            component={AttendAgreement36ApplicationPage}
          />
          // master attend employment
          <PrivateRoute path="/masterEmploymentForm/add" component={EmploymentFormAddPage} />
          <PrivateRoute
            path="/masterEmploymentForm/edit/:isEdit/:employmentId/:applyStartDate"
            component={EmploymentFormAddPage}
          />
          <PrivateRoute exact={true} path="/masterWorkingDays" component={PrescribedDaysPage} />
          <PrivateRoute path="/masterWorkingDays/add" component={WorkingDaysCreatePage} />
          <PrivateRoute path="/masterWorkingDays/edit/:workingDaysId/:tabIndex" component={WorkingDaysEditPage} />
          <PrivateRoute exact={true} path="/masterSpecialSalaryPattern" component={SpecialSalaryPatternPage} />
          <PrivateRoute path="/masterSpecialSalaryPattern/add" component={SpecialSalaryPatternAddPage} />
          <PrivateRoute
            path="/masterSpecialSalaryPattern/edit/:specialSalaryPatternId/:tabIndex"
            component={SpecialSalaryPatternEditPage}
          />
          <PrivateRoute path="/masterFiscalYear/add" component={FiscalYearAddPage} />
          <PrivateRoute path="/masterFiscalYear/edit/:fiscalYear" component={FiscalYearAddPage} />
          <PrivateRoute path="/masterFiscalYear" component={FiscalYearsPage} />
          <PrivateRoute path="/salesMenuType" component={SalesMenuType} />
          <PrivateRoute path="/attendHeadquartersFinal/attendMonthlyList" component={AttendMonthlyList} />
          <PrivateRoute path="/attendHeadquartersFinal" component={HeadquartersFinalPage} />
          <PrivateRoute path="/attendAchievementLock" component={AttendAchievementLockPage} />
          <PrivateRoute
            path="/attendStampList/attendWorkScheduleChangeApplication"
            component={WorkScheduleChangeApplication}
          />
          <PrivateRoute path="/attendStampList/attendStampModificationApplication" component={StampModificationPage} />
          <PrivateRoute path="/attendStampList/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute path="/attendStampList/attendAllowanceApplication" component={AllowanceApplicationPage} />
          <PrivateRoute path="/attendStampList/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute path="/attendStampList/attendHolidayworkApplication" component={HolidayWorkApplicationPage} />
          <PrivateRoute path="/attendStampList/attendTransferApplication" component={TransferApplicationPage} />
          <PrivateRoute path="/attendStampList/attendStockPaidApplication" component={StockPaidPage} />
          <PrivateRoute path="/attendStampList" component={AttendStampList} />
          <PrivateRoute path="/attendStampAggregation" component={AttendStampAggregation} />
          <PrivateRoute path="/attendResult" component={AttendResult} />
          <PrivateRoute path="/personalSetting" component={PersonalSetting} />
          <PrivateRoute path="/masterOrganizeSetting" component={MasterOrganizeSettingPage} />
          // master paid salary pattern
          <PrivateRoute path="/masterWorkingHours/add" component={WorkingHoursAddPage} />
          <PrivateRoute path="/masterWorkingHours/:typeButton/:workingHoursId" component={WorkingHoursAddPage} />
          <PrivateRoute path="/masterWorkingHours" component={PrescribedHoursPage} />
          // master shift pattern
          <PrivateRoute path="/masterShiftPattern/add" component={ShiftPatternAddPage} />
          <PrivateRoute path="/masterShiftPattern/:typeButton/:shiftPatternId" component={ShiftPatternAddPage} />
          <PrivateRoute path="/masterShiftPattern" component={ShiftPatternPage} />
          // Attend help list
          <PrivateRoute path="/attendHelpList" component={AttendHelpListPage} />
          // AttendAchievementOutput
          <PrivateRoute path="/attendAchievementOutput" component={AttendAchievementOutput} />
          // salesBudgetYear
          <PrivateRoute path="/salesBudgetYear" component={salesBudgetYear} />
          <PrivateRoute path="/salesBudgetYearImport" component={salesBudgetYearImport} />
          <PrivateRoute path="/salesBudgetMonth" component={SalesBudgetMonth} />
          // sales report
          <PrivateRoute path="/salesMonthlyReport" component={SalesMonthlyReportPage} />
          <PrivateRoute path="/salesDailyReport" component={SalesDailyReportPage} />
          // Attend Application Approval
          <PrivateRoute
            exact
            path="/attendApplicationApproval/:applicationType/:modifierApplicationId"
            component={AttendApplicationApprovalDetailPage}
          />
          <PrivateRoute
            exact
            path="/attendApplicationApproval/:applicationType"
            component={AttendApplicationApprovalDetailPage}
          />
          <PrivateRoute
            path="/attendApplicationApproval/:targetDateFrom?/:targetDateTo?/:viewPeriod?"
            component={AttendApplicationApprovalV3Page}
          />
          <PrivateRoute path="/attendApplicationApprovalV3" component={AttendApplicationApprovalPage} />
          // Attend Application list
          <PrivateRoute
            path="/attendApplicationList/:applicationType/:modifierApplicationId"
            component={AttendApplicationApprovalDetailPage}
          />
          <PrivateRoute
            path="/attendApplicationList/attendStampModificationApplication"
            component={StampModificationPage}
          />
          <PrivateRoute path="/attendApplicationList/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute
            path="/attendApplicationList/attendAllowanceApplication"
            component={AllowanceApplicationPage}
          />
          <PrivateRoute path="/attendApplicationList/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute
            path="/attendApplicationList/attendHolidayworkApplication"
            component={HolidayWorkApplicationPage}
          />
          <PrivateRoute path="/attendApplicationList/attendTransferApplication" component={TransferApplicationPage} />
          <PrivateRoute
            path="/attendApplicationList/attendAgreement36Application"
            component={AttendAgreement36ApplicationPage}
          />
          <PrivateRoute
            path="/attendApplicationList/attendWorkScheduleChangeApplication"
            component={WorkScheduleChangeApplication}
          />
          <PrivateRoute path="/attendApplicationList/attendStockPaidApplication" component={StockPaidPage} />
          <PrivateRoute
            path="/attendApplicationList/:applicationType"
            component={AttendApplicationApprovalDetailPage}
          />
          <PrivateRoute path="/attendApplicationList" component={AttendApplicationListV3Page} />
          <PrivateRoute path="/attendApplicationListV3" component={AttendApplicationListPage} />
          // [Shop]
          <PrivateRoute
            path="/masterShopFaceRegistration"
            component={logoFlag === "0" ? MasterShopFaceRegistrationPageV1 : MasterShopFaceRegistrationPage}
          />
          // Attend holiday management
          <PrivateRoute
            path="/attendHolidayManagement/attendHolidayManagementImport/:formatType"
            component={HolidayManagementImportPage}
          />
          <PrivateRoute path="/attendHolidayManagement" component={HolidayManagementPage} />
          // Paid Holiday Alert
          <PrivateRoute path="/paidHolidayAlert" component={PaidHolidayAlertPage} />
          // master paid holiday pattern
          <PrivateRoute path="/masterPaidHolidayPattern/add" component={PaidHolidayPatternAddPage} />
          <PrivateRoute
            path="/masterPaidHolidayPattern/edit/:paidHolidayPatternId"
            component={PaidHolidayPatternAddPage}
          />
          <PrivateRoute path="/masterPaidHolidayPattern" component={PaidGrantPatternPage} />
          <PrivateRoute
            path="/salesPaymentMedia/mediaMaster/edit/:paymentMediaMstCode"
            component={MediaMasterCreatePage}
          />
          <PrivateRoute path="/salesPaymentMedia/mediaMaster/add" component={MediaMasterCreatePage} />
          <PrivateRoute
            path="/salesPaymentMedia/paymentSetting/edit/:importCode/:postType/:orgCode"
            component={PaymentImportCreatePage}
          />
          <PrivateRoute path="/salesPaymentMedia/paymentSetting/add/:orgCode" component={PaymentImportCreatePage} />
          <PrivateRoute path="/salesPaymentMedia/:currentTab" component={PaymentTypePage} />
          // master time output layout
          <PrivateRoute path="/masterTimeOutputLayout/add" component={TimeOutputLayoutAddPage} />
          <PrivateRoute path="/masterTimeOutputLayout/edit/:layoutId" component={TimeOutputLayoutAddPage} />
          <PrivateRoute path="/masterTimeOutputLayout/masterDayOutputItem" component={DayOutputItemPage} />
          <PrivateRoute path="/masterTimeOutputLayout/masterTimeOutputItem" component={TimeOutputItemPage} />
          <PrivateRoute path="/masterTimeOutputLayout" component={TimeOutputLayoutListPage} />
          // // tinh nang moi 打刻設定
          <PrivateRoute path="/stampIpSetting/add" component={AttendanceAddPage} />
          <PrivateRoute path="/stampIpSetting/edit/:stampIpId" component={AttendanceAddPage} />
          <PrivateRoute path="/stampIpSetting" component={AttendanceMasterPage} />
          //AttendPayslipImport
          <PrivateRoute path="/AttendPayslipImport" component={AttendPayslipImport} />
          // master term
          <PrivateRoute path="/masterTerm/add" component={TermMasterAddPage} />
          <PrivateRoute path="/masterTerm/edit/:termId" component={TermMasterAddPage} />
          <PrivateRoute path="/masterTerm" component={TimeZonePage} />
          // master IC Card
          <PrivateRoute path="/masterICCard/staff/:staffCode" component={MasterICCardStaffSettingPage} />
          <PrivateRoute path="/masterICCard/import" component={MasterICCardImportPage} />
          <PrivateRoute path="/masterICCard" component={MasterICCardSettingPage} />
          // master Salary Csv Setting
          <PrivateRoute path="/masterSalaryCsvSetting" component={SalarryCsvSettingFormPage} />
          // advertising media
          <PrivateRoute
            path="/salesAdvertisingMedia/advertisingMaster/edit/:advertisingMediaMstCode"
            component={AdvertisingAddPage}
          />
          <PrivateRoute path="/salesAdvertisingMedia/advertisingMaster/add" component={AdvertisingAddPage} />
          <PrivateRoute
            path="/salesAdvertisingMedia/advertisingSetting/edit/:importCode/:postType/:orgCode"
            component={AdvertisingImportCreatePage}
          />
          <PrivateRoute
            path="/salesAdvertisingMedia/advertisingSetting/add/:orgCode"
            component={AdvertisingImportCreatePage}
          />
          <PrivateRoute path="/salesAdvertisingMedia/:currentTab" component={AdvertisingMasterPage} />
          ///taxRateCaptureSetting
          <PrivateRoute path="/taxRateCaptureSetting" component={TaxRateCaptureSetting} />
          //masterShopSpecify
          <PrivateRoute path="/masterShopSpecify" component={MasterShopSpecifyPage} />
          <PrivateRoute path="/masterEmploymentForm" component={EmploymentFormPage} />
          <PrivateRoute path="/areas/add" component={AreasAddPage} />
          <PrivateRoute path="/areas/import" component={AreasImportPage} />
          <PrivateRoute path="/areas/edit/:companyCode/:areaId" component={AreasAddPage} />
          <PrivateRoute path="/areas" component={AreasPage} />
          <PrivateRoute path="/businessCategories/import" component={BusinessCategoryImportPage} />
          <PrivateRoute
            path="/businessCategories/edit/:companyCode/:businessCategoryId"
            component={BusinessCategoryAddPage}
          />
          <PrivateRoute path="/businessCategories/add" component={BusinessCategoryAddPage} />
          <PrivateRoute path="/businessCategories" component={BusinessCategoryPage} />
          // TimeRecalculation
          <PrivateRoute path="/timeRecalculation" component={TimeRecalculationPage} />
          <PrivateRoute path="/payMeCooperation" component={PayMeCooperationPage} />
          //
          <PrivateRoute path="/stampHistory" component={StampHistoryPage} />
          <PrivateRoute path="/GpsStampHistory" component={GpsStampHistoryPage} />
          <PrivateRoute path="/kaoiroHistory" component={KaoriaoHistoryPage} />
          {/* <PrivateRoute path="/stampImport" component={StampImportPage} /> */}
          //Working Status
          <PrivateRoute path="/workAchievement" component={WorkingStatusPage} />
          // report
          <PrivateRoute path="/monthlyReport" component={MonthlyReportPage} />
          <PrivateRoute path="/monthlyDailyList" component={SeparateBillPage} />
          <PrivateRoute path="/yearlyList" component={YearlySalesManagementPage} />
          <PrivateRoute path="/itemList" component={FormByProduct} />
          <PrivateRoute path="/timeList" component={TimeListPage} />
          <PrivateRoute path="/attendError/attendStampModificationApplication" component={StampModificationPage} />
          <PrivateRoute path="/attendError/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute path="/attendError/attendAllowanceApplication" component={AllowanceApplicationPage} />
          <PrivateRoute path="/attendError/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute path="/attendError/attendHolidayworkApplication" component={HolidayWorkApplicationPage} />
          <PrivateRoute path="/attendError/attendTransferApplication" component={TransferApplicationPage} />
          <PrivateRoute path="/attendError/attendStockPaidApplication" component={StockPaidPage} />
          <PrivateRoute
            path="/attendError/attendWorkScheduleChangeApplication"
            component={WorkScheduleChangeApplication}
          />
          <PrivateRoute path="/attendError" component={AttendanceErrorPage} />
          // CSV output
          <PrivateRoute path="/attendCSVOutput" component={AttendCSVOutputPage} />
          // Hope Shift
          <PrivateRoute path="/hopeShift" component={HopeShiftPage} />
          // Shift View
          <PrivateRoute path="/shiftView" component={ShiftViewPage} />
          // payment type master // シフト管理
          <PrivateRoute path="/shift" component={ShiftPage} />
          <PrivateRoute path="/monthlyShift/attendStampModificationApplication" component={StampModificationPage} />
          <PrivateRoute path="/monthlyShift/attendHolidayApplication" component={HolidayApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendAllowanceApplication" component={AllowanceApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendOvertimeApplication" component={OvertimeApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendHolidayworkApplication" component={HolidayWorkApplicationPage} />
          <PrivateRoute path="/monthlyShift/attendTransferApplication" component={TransferApplicationPage} />
          <PrivateRoute
            path="/monthlyShift/attendWorkScheduleChangeApplication"
            component={WorkScheduleChangeApplication}
          />
          <PrivateRoute path="/monthlyShift/attendStockPaidApplication" component={StockPaidPage} />
          <PrivateRoute path="/monthlyShift/import" component={MonthlyShiftImportLayoutPage} />
          <PrivateRoute path="/monthlyShift" component={MonthlyShift} />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendStampModificationApplication"
            component={StampModificationPage}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendHolidayApplication"
            component={HolidayApplicationPage}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendAllowanceApplication"
            component={AllowanceApplicationPage}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendOvertimeApplication"
            component={OvertimeApplicationPage}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendHolidayworkApplication"
            component={HolidayWorkApplicationPage}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendTransferApplication"
            component={TransferApplicationPage}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendWorkScheduleChangeApplication"
            component={WorkScheduleChangeApplication}
          />
          <PrivateRoute
            path="/dailyShift/:orgCode?/:targetDate?/attendStockPaidApplication"
            component={StockPaidPage}
          />
          <PrivateRoute path="/dailyShift/:orgCode?/:targetDate?" component={DailyShift} />
          // ID,顔認証打刻
          <Route exact={true} path="/timeRecorder">
            <TimeRecorderPage />
          </Route>
          <Route exact={true} path="/recorder_1">
            <RecorderPage />
          </Route>
          <Route exact={true} path="/recorder_2">
            <RecorderPageV2 />
          </Route>
          <Route exact={true} path="/callback">
            <CallbackLogin />
          </Route>
          {isAuthorizedFromPeople ? <Route exact={true} path="/logout" component={Logout} /> : ""}
          {isAuthorizedFromPeople ? <Route exact={true} path="/singleLogout" component={SingleLogout} /> : ""}
          <Route exact={true} path="/expired" component={ExpiredPage} />
          <PrivateRoute path="/recorder" component={RecorderPageV1} />
          <PrivateRoute path="/404" component={NotFound} />
          // GPS打刻
          <PrivateRoute path="/timeRecorderGps" component={TimeRecorderGPSPage} />
          // 打刻修正申請承認依頼メール
          <PrivateRoute
            path="/approvalModificationApplication/:applicationId"
            component={ApprovalModificationApplicationPage}
          />
          // 休暇申請承認依頼メール
          <PrivateRoute path="/approvalHolidayApplication/:applicationId" component={ApprovalHolidayApplicationPage} />
          // 残業申請承認依頼メール
          <PrivateRoute
            path="/approvalOvertimeApplication/:applicationId"
            component={ApprovalOvertimeApplicationPage}
          />
          // 休日出勤申請承認依頼メール
          <PrivateRoute
            path="/approvalHolidayworkApplication/:applicationId"
            component={ApprovalHolidayworkApplicationPage}
          />
          // 振替休日申請承認依頼メール
          <PrivateRoute
            path="/approvalTransferApplication/:applicationId"
            component={ApprovalTransferApplicationPage}
          />
          <PrivateRoute
            exact
            path={
              getAuthorizedFromDelious()
                ? "/login/:companyCode/:approvalMode/:applicationId"
                : "/login/:approvalMode/:applicationId"
            }
          >
            <Redirect to="/agreement36" />
          </PrivateRoute>
          <Route path="/expire">
            <SessionExpireError />
          </Route>
          // Default view
          <PrivateRoute exact path="/">
            <Redirect to="/agreement36" />
          </PrivateRoute>
          <Redirect from="*" to="/404" />
        </Switch>
      </Router>
      <ToastContainerWrapper />
      <Modal
        open={open}
        closeHandler={logoutHandler}
        title={"警告"}
        submitText={"OK"}
        onSubmit={logoutHandler}
        closeText={""}
      >
        <PanelDescription>{"セッションが切断されました。ログイン画面に戻ります。"}</PanelDescription>
      </Modal>
    </>
  );
};
// export default withAuthenticator(App);
export default App;
