import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterRoleImportForm from 'components/organismos/master/masterRole/MasterRoleImportForm';

const MasterRoleSettingPage: React.FC = () => {

  return (
    <SidebarTemplate pageTitle="権限インポート">
      <MasterRoleImportForm/>
    </SidebarTemplate>
  );
};

export default MasterRoleSettingPage;
