import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalaryCsvSettingAddForm from 'components/organismos/master/labor/SalaryCsvSettingAddForm';

const SalaryCsvSettingAddPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="給与CSV設定"
  >
    <SalaryCsvSettingAddForm />
  </SidebarTemplate>
);

export default SalaryCsvSettingAddPage;
