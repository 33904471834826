import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import { createOrUpdateWorkingDays, getWorkingDaysById } from 'api/workingDay';
import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import useToastNotification from 'hooks/useToastNotification';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

type WorkingDaysDomainnKey = keyof Pick<WorkingDaysDomain, 'workingDaysPatternCode'|'workingDaysPatternName'>;

//
export const useWorkingDaysDomainConfigForm = (workingDaysId?: string) => {
  // history
  const history = useHistory();

  const [blocking, setBlocking] = useState(false);

  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterWorkingDays);

  // modal confirm
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // Modal alert
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const orgTreesOptions = useOrgTreesOptions();

  const [openManagerAddModal, setOpenManagerAddModal] = useState<boolean>(false);


  // handle submit form
  const onSubmit = async (values: WorkingDaysDomain) => {
    if (values.workingDaysPatternCode === null) {
      errorNotification('パターンコードを入力してください。');
      return;
    }
    if (values.workingDaysPatternName === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    let convertData = values.getRawData();

    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).workingDays;
    delete (convertData as any).workingDaysDetails.id;
    delete (convertData as any).inputOrgList;

    convertData.companyCode = companyCode;
    convertData.createUser = loginUser;
    convertData.updateUser = loginUser;

    if (convertData.orgDaysList.length > 0 && convertData.orgDaysList[0].id.orgCode === '') {
      // delete (convertData as any).orgDaysList;
      convertData.inputOrgList = null;
    } else {
      (convertData as any).orgDaysList.forEach((value: string, index: number) => {
        convertData = {
          ...convertData,
          [`inputOrgList[${index}]`]: value,
        };
      });
    }
    // delete (convertData as any).orgDaysList;

    // check process
    try {
      const response = await createOrUpdateWorkingDays(companyCode, convertData, 1);
      setConfirmModalOpen(false);
      if (!response.errors) {
        successNotification('更新しました。');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: WorkingDaysDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });
  const targetYear = moment().format('YYYY');

  //
  useEffect(() => {
    if (workingDaysId !== undefined) {
      handleGetWorkingDays(targetYear, workingDaysId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetYear, workingDaysId]);

  const addManager = useCallback((orgList: Array<SelfOrganizationDomain>) => {
    if (!formik.values.orgDaysList) {
      return;
    }
    const managerDataList : Array<{ id: { orgCode: string }, orgName: string}> = orgList.map((org) => {return { id: { orgCode: org.orgCode }, orgName: org.orgName}});
    formik.setFieldValue('orgDaysList', formik.values.orgDaysList.concat(managerDataList))
  }, [formik]);

  const deleteOrgDaysList = useCallback((index: string) => {
    if (!formik.values.orgDaysList) {
      return;
    }
    formik.setFieldValue('orgDaysList', formik.values.orgDaysList.filter((item) => item.id.orgCode !== index));

  }, [formik]);

  /**
   * handle get working days & set formik data
   *
   * @param targetYear
   * @param workingDaysId
  */
  const handleGetWorkingDays = async (targetYearStr: any, id: any) => {
    setBlocking(true);
    getWorkingDaysById(targetYearStr, id).then((response: any) => {
      setBlocking(false);
      if (response) {
        const { workingDaysDetails } = response;
        if (workingDaysDetails != null) {
          formik.setValues(new WorkingDaysDomain(response));
          formik.setFieldValue('targetYearStr', targetYearStr);
        } else {
          const initalWorkingDay : WorkingDaysDomain = response;
          initalWorkingDay.workingDaysDetails = WorkingDaysDomain.generateInitial()
            .workingDaysDetails;
          formik.setValues(new WorkingDaysDomain(initalWorkingDay));
          formik.setFieldValue('targetYearStr', targetYearStr);
        }
      } else {
        // setValueWorkingHours([]);
      }
      return true;
    });
  };

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  //
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    setToastModalOpen,
    handleGetWorkingDays,
    orgTreesOptions,
    history,
    openManagerAddModal,
    setOpenManagerAddModal,
    addManager,
    deleteOrgDaysList,
    blocking,
    detailRole
  };
};

export default {
  useWorkingDaysDomainConfigForm,
};
