import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterRoleEditForm from 'components/organismos/master/masterRole/MasterRoleEditForm';

const MasterRoleEditPage: React.FC = () => {

  return (
    <SidebarTemplate pageTitle="権限インポート">
      <MasterRoleEditForm/>
    </SidebarTemplate>
  );
};

export default MasterRoleEditPage;
