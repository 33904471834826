/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { productColor, textColor, grayScale } from 'components/styles';
import BaseButton, { ButtonProps } from './BaseButton';
import Icon from 'components/atoms/Icon';
import { IconType } from 'components/atoms/Icon';

const style = css({
  backgroundColor: '#F8F8F8',
  color: '#007BC3',
  borderRadius: 0,
  border: '0',
  ':hover, :focus': {
    backgroundColor: '#F8F8F8',
    color: '#007BC3',
    opacity: 0.8
  },
})

const styleIcon = css`
  svg{
    padding-bottom: 8px;
    margin-right: 8px;
  }
`

interface DownLoadButton extends ButtonProps {
  iconType?: IconType,
  height?: string
}

const DownLoadButton: React.FC<DownLoadButton> = ({
  text, onClick, disabled, ghost, children, fullwidth = false, minWidth='120px', customStyle = css({}), iconType
}) => (
  <BaseButton
    color={{
      baseColor: productColor.primary,
      textColor: textColor.inversed,
      ghostColor: productColor.primary,
      ghostTextColor: productColor.primary,
      hoverColor: disabled ? grayScale.gray20 : productColor.primaryHover,
      focusColor: productColor.primary,
    }}
    text={text}
    onClick={onClick}
    disabled={disabled}
    ghost={ghost}
    fullwidth={fullwidth}
    minWidth={minWidth}
    customStyle={css([style, customStyle])}
  >
    {iconType && 
      <Icon type={iconType} color="#007BC3" size="20px" customStyle={styleIcon}/>
    }
    {children}
  </BaseButton>
);

export default DownLoadButton;