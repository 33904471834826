/** @jsx jsx */
import React, { useEffect } from "react";
import { jsx, css } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import { logout } from "api/login";
import { Auth } from "aws-amplify";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import clearLocalStorageAfterLogout from "hooks/useClearLocalStorageAfterLogout";

const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE || false;
const peopleURL = process.env.REACT_APP_PEOPLE_URL || "";

const style = {
  wrapper: css({
    width: "100%",
    height: "100vh",
    margin: "0px auto",
    backgroundImage: "url(/login-back-e6ac60b256d2f031c73e8613933e2e8e3e3c8988062d2c05ec8d76bfe535a8ce.svg)",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    overflowX: "hidden",
    minWidth: "320px",
  }),
  center: css({
    top: "50%",
    width: "100%",
    position: "absolute",
    left: "0%",
    height: "20rem",
    marginTop: "-11rem",
  }),
  textCenter: css({
    textAlign: "center",
    color: "#007BC3",
    fontSize: "14px",
  }),
  paddingTop3: css({
    paddingTop: "3rem",
  }),
  paddingTop1: css({
    paddingTop: "1rem",
  }),
  paddingTop15: css({
    paddingTop: "1.5rem",
  }),
};

const Logout: React.FC = () => {
  const [blocking, setBlocking] = React.useState<boolean>(true);
  const codeVerifier = localStorage.getItem("code_verifier") || "";

  React.useEffect(() => {
    let deliousToken = localStorage.getItem("DELIOUS_TOKEN") || "";

    if (deliousToken) {
      sessionStorage.clear();
      clearLocalStorageAfterLogout();
      window.removeEventListener("storage", () => {});
      window.location.href = "/login";
    } else if (isAuthorizedFromPeople !== "true") {
      Auth.signOut();
      sessionStorage.clear();
      clearLocalStorageAfterLogout();
      window.removeEventListener("storage", () => {});
      window.location.href = "/login";
    } else {
      var tenant = localStorage.getItem("PEOPLE_TENANT") || "";
      var redirectPeopleLogout = peopleURL;
      if (tenant !== "") {
        // redirectPeopleLogout += "/"+ "ppstg_hinata"+"/"+"logout";
        redirectPeopleLogout += "/" + tenant + "/" + "single_logout";
        logout()
          .then(() => {
            clearLocalStorageAfterLogout();
            sessionStorage.clear();
            if (isAuthorizedFromPeople === "true") {
              setBlocking(false);
              // setTimeout(() => {
              window.location.href = redirectPeopleLogout;
              //   document.getElementById('tFrame')?.setAttribute('src', redirectPeopleLogout);
              // },3000)
            } else {
              setBlocking(false);
              window.location.href = "/";
            }
            if (codeVerifier !== "") {
              localStorage.setItem("code_verifier", codeVerifier);
            }
          })
          .catch(() => {
            clearLocalStorageAfterLogout();
            sessionStorage.clear();
            if (isAuthorizedFromPeople === "true") {
              setBlocking(false);
              // setTimeout(() => {
              window.location.href = redirectPeopleLogout;
              // document.getElementById('tFrame')?.setAttribute('src', redirectPeopleLogout);
              // },3000);
            } else {
              setBlocking(false);
              window.location.href = "/";
            }
            if (codeVerifier !== "") {
              localStorage.setItem("code_verifier", codeVerifier);
            }
          });
      } else {
        clearLocalStorageAfterLogout();
        sessionStorage.clear();
        setBlocking(false);
        if (codeVerifier !== "") {
          localStorage.setItem("code_verifier", codeVerifier);
        }
        window.location.href = redirectPeopleLogout;
      }
    }
  }, []);
  useEffect(() => {
    document.body.style.overflowY = "scroll";
    return () => {
      document.body.style.overflowY = "null";
    };
  }, []);

  return (
    <BlockUI blocking={blocking}>
      <div css={style.wrapper}>
        {/* <div css={style.center}> */}
        <FlexBox customStyle={css(style.center)} alignItems="center" direction="column" justifyContent="center">
          <FlexBoxItem customStyle={css({ marginTop: "-31px" })}>
            <h1 css={css({ margin: "0" })}>
              <img alt="Cydas" src="favicon.ico" css={css({ height: "48px" })} />
            </h1>
          </FlexBoxItem>
          <FlexBoxItem customStyle={css(style.paddingTop15)}>
            <p css={style.textCenter}>ログアウトしました。</p>
          </FlexBoxItem>
          {/*
            <FlexBoxItem customStyle={css(style.paddingTop3)}>
              <Button
                text="ログインへ"
                onClick={()=>{window.location.href="/"}}
                minWidth="20rem"

              />
            </FlexBoxItem>
            */}
        </FlexBox>
        <iframe id="tFrame" hidden={true}></iframe>

        {/* </div> */}
      </div>
    </BlockUI>
  );
};

export default Logout;
