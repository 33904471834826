import React, { useState, useRef, RefObject, useEffect, createContext, useContext, useReducer } from "react";
import {
  MonthlyShiftContextType,
  IMonthlyShift,
  StaffInfo,
  IShopPlan,
  ShiftPattern,
  EmploymentOptionType,
} from "../type";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import { ContextMenuType } from "components/molecules/ContextMenu/type";
import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import { fetchMonthlyShift, fetchDrawingInfo, downloadMonthlyShiftPdf, downloadMonthlyShiftExcel } from "../api";
import { addShiftPattern } from "api/shiftPattern";
import { deleteShiftV1, ConfirmType, confirmMonthlyLockOutV1, registerHoliday, copyFromPreviousDay } from "api/shift";
import { shiftConfirmReleaseV1, shiftConfirmV1 } from "api/shiftConfirm";
import { getEmploymentSelectListV2 } from "api/employment";
import { OptionType } from "components/atoms/Select";
import { fetchShopPlan } from "../api";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { getTreesOrgSelectRange } from "api/organization";

export const monthlyShiftContext = createContext<MonthlyShiftContextType>({} as MonthlyShiftContextType);

export const useMonthlyShiftContext = (): MonthlyShiftContextType => {
  const context = useContext(monthlyShiftContext);
  return context;
};

export type StateMonthlyReducerType = {
  orgs: OptionType[];
  employmentOptions: EmploymentOptionType[];
  selectedOrg: string;
  dateFromStr: string;
  dateToStr: string;
  isAttendExists: string;
  viewPeriod: string;
};

export const useInitialState = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [remote, setRemote] = useState<boolean>(false);
  const [useSales, setUseSales] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loadEmploymentDone, setLoadEmploymentDone] = useState<boolean>(false);
  const [checkall, setCheckall] = useState<boolean>(
    sessionStorage.getItem(`0/sessionData${window.location.pathname}.employmentOptions`) === "all",
  );
  const { errorNotification, successNotification } = useToastNotification();
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const history = useHistory();
  const { detailRole } = useGetDetailRole(setLoading, functionCode.monthlyShift);

  /* -------------------------------------------------------------------------- */
  /*                                Filter states                               */
  /* -------------------------------------------------------------------------- */
  // const [dateFromStr, setDateFromStr] = useState<string>(sessionStorage.getItem("attendStampList.monthlyShift.targetDateFrom") || "");
  // const [dateToStr, setDateToStr] = useState<string>(sessionStorage.getItem("attendStampList.monthlyShift.targetDateTo") || "");
  // const [viewPeriod, setViewPeriod] = useState<string>(sessionStorage.getItem(`0/sessionData${window.location.pathname}.viewPeriod`) || "weekly");
  // const [isAttendExists, setAttendExists] = useState<string>(sessionStorage.getItem("attendStampList.attendFlag") || "0");
  const [orgs, setOrgs] = useState<OptionType[]>([]);
  const [pageTotal, setPageTotal] = useState<Number>(0);

  // const [selectedOrg, setSelectedOrg] = useState<string>(
  //   sessionStorage.getItem("attendStampList.monthlyShift.orgCode") || sessionStorage.getItem("loginUser.orgCode") || ""
  // );
  // const [employmentOptions, setEmploymentOptions] = useState<EmploymentOptionType[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                                   Reducer                                  */
  /* -------------------------------------------------------------------------- */
  const defaultStateReducer = {
    orgs: [],
    employmentOptions: [],
    selectedOrg:
      sessionStorage.getItem("attendStampList.monthlyShift.orgCode") ||
      sessionStorage.getItem("loginUser.orgCode") ||
      "",
    dateFromStr: sessionStorage.getItem("attendStampList.monthlyShift.targetDateFrom") || "",
    dateToStr: sessionStorage.getItem("attendStampList.monthlyShift.targetDateTo") || "",
    isAttendExists: sessionStorage.getItem("attendStampList.attendFlag") || "0",
    viewPeriod: sessionStorage.getItem(`attendStampList.monthlyShift.viewPeriod`) || "",
  };

  const reducer = (stateFilter: StateMonthlyReducerType, { type, payload }: any): StateMonthlyReducerType => {
    let tmpState = { ...stateFilter };
    switch (type) {
      case "ON_CHANGE_STATE":
        return { ...tmpState, ...payload };
      case "ON_CHANGE_ORG":
        return { ...tmpState, orgs: payload };
      case "ON_CHANGE_EMPLOYMENT_OPTIONS":
        return { ...tmpState, employmentOptions: payload };
      case "ON_CHANGE_ATTEND_EXISTS":
        return { ...tmpState, isAttendExists: payload };
      case "ON_CHANGE_DATE_FROM":
        return { ...tmpState, dateFromStr: payload };
      case "ON_CHANGE_DATE_TO":
        return { ...tmpState, dateToStr: payload };
      case "ON_CHANGE_SELECTED_ORG":
        return { ...tmpState, selectedOrg: payload };
      case "ON_CHANGE_VIEW_PERIOD":
        return { ...tmpState, viewPeriod: payload };
      default:
        return tmpState;
    }
  };

  const [stateFilter, dispatch] = useReducer(reducer, defaultStateReducer);

  /* -------------------------------------------------------------------------- */
  /*                               Calendar states                              */
  /* -------------------------------------------------------------------------- */
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedCell, setSelectedCell] = useState<any>();
  const [monthlyShifts, setMonthlyShifts] = useState<IMonthlyShift>({} as IMonthlyShift);
  const [staffs, setStaffs] = useState<StaffInfo[]>([]);
  const [shopPlan, setShopPlan] = useState<IShopPlan[]>([]);
  const [openDailyShiftConfirmModal, setDailyShiftConfirmModal] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>("");

  const [pageIndex, setPageIndex] = useState(1);

  /* -------------------------------------------------------------------------- */
  /*                          ShiftPattern modal states                         */
  /* -------------------------------------------------------------------------- */
  const [openShiftPatternModal, setShiftPatternModal] = useState<boolean>(false);
  const [isAchievementConfirm, setIsAchievementConfirm] = useState<boolean>(false);
  const [shiftPatternOptions, setShiftPatternOptions] = useState<ShiftPattern[]>([]);
  const [achievementConfirm, setAchievementConfirm] = useState<ConfirmType>({} as ConfirmType);
  const [shiftConfirm, setShiftConfirm] = useState<ConfirmType>({} as ConfirmType);

  /* -------------------------------------------------------------------------- */
  /*                             ContextMenu states                             */
  /* -------------------------------------------------------------------------- */
  const [isPerformanceMenuVisible, setPerformanceMenuVisible] = useState<boolean>(false);
  const [isItemMenuVisible, setItemMenuVisible] = useState<boolean>(false);
  const [isTimelineMenuVisible, setTimelineMenuVisible] = useState<boolean>(false);
  const contextMenuRef = useRef() as RefObject<any>;
  const [contextMenuX, setContextMenuX] = useState<number>(0);
  const [contextMenuY, setContextMenuY] = useState<number>(0);
  const [menuTimelineButtons, setMenuTimelineButtons] = useState<ContextMenuType[]>([]);
  const [menuPerformanceButtons, setMenuPerformanceButtons] = useState<ContextMenuType[]>([]);
  const [menuItemButtons, setMenuItemButtons] = useState<ContextMenuType[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                              CALENDAR FUNCTIONS                            */
  /* -------------------------------------------------------------------------- */
  const deleteItem = async (shiftId: string) => {
    setLoading(true);
    let updateUser = sessionStorage.getItem("loginUser.staffName")!;

    try {
      await deleteShiftV1(shiftId, updateUser);
      await reFetchMonthlyShifts();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const submitShiftLockout = async () => {
    if (shiftConfirm.isConfirmedAllDays) {
      await shiftConfirmReleaseV1(
        stateFilter.selectedOrg,
        moment(stateFilter.dateFromStr).format("YYYY/MM/DD"),
        moment(stateFilter.dateToStr).format("YYYY/MM/DD"),
      );
      errorNotification("シフト確定を解除しました。");
    } else {
      let targetStaffCodes = staffs.map((staff) => staff.staffCode);
      await shiftConfirmV1(
        stateFilter.selectedOrg,
        moment(stateFilter.dateFromStr).format("YYYY/MM/DD"),
        moment(stateFilter.dateToStr).format("YYYY/MM/DD"),
        targetStaffCodes,
      );
      successNotification("シフトを確定しました。");
    }
    confirmMonthlyLockOutV1(stateFilter.selectedOrg, stateFilter.dateFromStr, stateFilter.dateToStr).then(
      (response) => {
        setAchievementConfirm(response.achievementConfirm);
        setShiftConfirm(response.shiftConfirm);
      },
    );
    setOpenConfirmModal(false);
  };

  const submitDayShiftLockout = async () => {
    try {
      setDailyShiftConfirmModal(false);
      setLoading(true);
      let targetStaffCodes = staffs.map((staff) => staff.staffCode);
      await shiftConfirmV1(stateFilter.selectedOrg, selectedDay, selectedDay, targetStaffCodes);
      await reFetchMonthlyShifts(true);
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const reFetchMonthlyShifts = async (fetchDrawingData: boolean = false) => {
    if (fetchDrawingData) {
      confirmMonthlyLockOutV1(stateFilter.selectedOrg, stateFilter.dateFromStr, stateFilter.dateToStr).then(
        (response) => {
          setAchievementConfirm(response.achievementConfirm);
          setShiftConfirm(response.shiftConfirm);
        },
      );
    }
    let employmentIds = stateFilter.employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      "1",
    );
    let monthlyData = await fetchMonthlyShift(
      stateFilter.dateFromStr,
      stateFilter.dateToStr,
      stateFilter.selectedOrg,
      stateFilter.isAttendExists,
      employmentIds,
    );
    setStaffs(monthlyData.staffInfo);
    setIsAchievementConfirm(monthlyData.isAchievementConfirm);
    setPageTotal(monthlyData.page.totalPages || 1);
    setMonthlyShifts(monthlyData);
  };

  const downloadPdf = async () => {
    setLoading(true);
    let employmentIds = stateFilter.employmentOptions.reduce(
      (totalStr, current) => (current.checked ? `${current.id},${totalStr}` : totalStr),
      "",
    );
    downloadMonthlyShiftPdf(
      stateFilter.selectedOrg,
      stateFilter.dateFromStr,
      stateFilter.dateToStr,
      employmentIds,
      stateFilter.isAttendExists,
    )
      .catch((error) => {
        errorNotification("対象月、対象組織において在籍するスタッフがいませんでした。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadExcel = async () => {
    setLoading(true);
    let employmentIds = stateFilter.employmentOptions.reduce(
      (totalStr, current) => (current.checked ? `${current.id},${totalStr}` : totalStr),
      "",
    );
    downloadMonthlyShiftExcel(
      stateFilter.selectedOrg,
      stateFilter.dateFromStr,
      stateFilter.dateToStr,
      employmentIds,
      stateFilter.isAttendExists,
    )
      .catch((error) => {
        errorNotification("対象月、対象組織において在籍するスタッフがいませんでした。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickAttendStampList = (staff: StaffInfo) => {
    // 打刻データ確認のセッション情報をセット
    sessionStorage.setItem("attendStampList.viewPeriod", "daily");
    sessionStorage.setItem("attendStampList.isGoBack", "true");

    sessionStorage.setItem("attendStampList.orgCode", stateFilter.selectedOrg);
    sessionStorage.setItem("attendStampList.staffCode", staff.staffCode);
    sessionStorage.setItem("attendStampList.targetDateFrom", moment(stateFilter.dateFromStr).format("YYYY-MM-DD"));
    sessionStorage.setItem("attendStampList.targetDateTo", moment(stateFilter.dateToStr).format("YYYY-MM-DD"));
    sessionStorage.setItem("attendStampList.closingDate", stateFilter.dateToStr);
    sessionStorage.setItem("attendStampList.employmentId", staff.employmentId);
    sessionStorage.setItem("attendStampList.attendFlag", stateFilter.isAttendExists);
    sessionStorage.setItem(`0/sessionData${window.location.pathname}.viewPeriod`, stateFilter.viewPeriod);
    sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);

    sessionStorage.setItem("attendStampList.targetMonth", moment(stateFilter.dateFromStr).format("YYYY-MM-DD"));
    history.push(`/attendStampList`);
  };

  /* -------------------------------------------------------------------------- */
  /*                           SHIFTPATTERN FUNCTIONS                           */
  /* -------------------------------------------------------------------------- */
  const getWorkdayOptions = (distinctionHoliday: boolean) => {
    if (distinctionHoliday) {
      return [
        { value: "normal", label: "通常" },
        { value: "legal", label: "公休（法定休日）" },
        { value: "scheduled", label: "公休（所定休日）" },
      ];
    }

    return [
      { value: "normal", label: "通常" },
      { value: "legal", label: "公休" },
    ];
  };

  /* -------------------------------------------------------------------------- */
  /*                            CONTEXTMENU FUNCTIONS                           */
  /* -------------------------------------------------------------------------- */
  const handleClickOutside = (event: any) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setPerformanceMenuVisible(false);
      setItemMenuVisible(false);
      setTimelineMenuVisible(false);
    }
  };

  const getMenuPerformanceButtons = (
    modifierApplicationId: any,
    orgCode: string,
    staffCode: string,
    staffName: string,
    modifierApplicationStatus: number | null,
    holidayApplicationStatus: number | null,
    overworkApplicationStatus: number | null,
    holidayworkApplicationStatus: number | null,
    allowanceApplicationStatus: number | null,
    transferApplicationStatus: number | null,
    attendanceOrgFix: string,
    useOverTimeApplication: any,
    necessaryHolidayWorkApplication: any,
    useAllowanceApplication: any,
    useTransferApplication: any,
    achievementId: any,
    targetDate: any,
    stampVersion: any,
    dispStaffName?: string,
    filterTargetDateFrom?: Date,
    filterTargetDateTo?: Date,
    filterOrgCode?: string,
    filterStaffCode?: string,
    belongOrgFix?: string,
    applying?: any,
    holidayId?: any,
    isHolidayWork?: any,
    workSystem?: number,
    index?: number,
    overtimeApplicationOrgCode?: string,
    holidayWorkApplicationOrgCode?: string,
    belongOrgCode?: string,
    useStockPaidApp?: boolean,
    stockModiferAppId?: string,
    stockPaidHolidayApplicationStatus?: number | null,
  ) => {
    const isHoliday = holidayId && !isHolidayWork;

    const stampAmendmentButton: ContextMenuType = {
      isRed: modifierApplicationStatus === 0,
      isSelectable:
        ((achievementId !== null && (holidayApplicationStatus === null || holidayApplicationStatus === undefined)) ||
          modifierApplicationStatus !== null ||
          attendanceOrgFix === "1") &&
        !isHoliday,
      label: "修正申請",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendStampModificationApplication`;
        sessionStorage.setItem("stamp.workSystem", String(workSystem));
        setSessionStorage(link, stampAmendmentButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const stampAddButton: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "追加申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendStampModificationApplication`;
        sessionStorage.setItem("stamp.workSystem", String(workSystem));
        setSessionStorage(link, stampAddButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const stampButtons: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "打刻修正申請",
      isHidden: false,
      stamptype: 1,
      subMenu: [stampAmendmentButton, stampAddButton],
    };

    const holidayAddButton: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "追加申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendHolidayApplication`;
        setSessionStorage(link, holidayAddButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const holidayAmendmentButton: ContextMenuType = {
      isRed: modifierApplicationStatus === 0,
      isSelectable: achievementId && holidayId,
      label: "修正申請",
      isHidden: false,
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendHolidayApplication`;
        setSessionStorage(link, holidayAmendmentButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyLeaveButton: ContextMenuType = {
      isRed: holidayApplicationStatus === 0,
      isSelectable: true,
      label: "休暇申請",
      isHidden: false,
      stamptype: 0,
      subMenu: [holidayAmendmentButton, holidayAddButton],
    };

    const applyOvertimeButton: ContextMenuType = {
      isRed: overworkApplicationStatus === 0,
      isSelectable: overworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useOverTimeApplication === 0,
      label: "残業申請",
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendOvertimeApplication`;
        setSessionStorage(link, applyOvertimeButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyAllowanceButton: ContextMenuType = {
      isRed: allowanceApplicationStatus === 0,
      isSelectable: allowanceApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useAllowanceApplication === 0,
      label: "手当申請",
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendAllowanceApplication`;
        setSessionStorage(link, applyAllowanceButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyHolidayWorkButton: ContextMenuType = {
      isRed: holidayworkApplicationStatus === 0,
      isSelectable: holidayworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: necessaryHolidayWorkApplication === false,
      label: "休日出勤申請",
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendHolidayworkApplication`;
        setSessionStorage(link, applyHolidayWorkButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyTransferButton: ContextMenuType = {
      isRed: transferApplicationStatus === 0,
      isSelectable: transferApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useTransferApplication === 0,
      label: "振替申請",
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendTransferApplication`;
        setSessionStorage(link || "", applyTransferButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyStockPaidHolidayButton: ContextMenuType = {
      isRed: stockPaidHolidayApplicationStatus === 0,
      isSelectable: stockPaidHolidayApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useStockPaidApp === false,
      label: "積立有給申請",
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendStockPaidApplication`;
        setSessionStorage(link, applyStockPaidHolidayButton.stamptype, targetDate);
        sessionStorage.setItem("application.modifierApplicationId", stockModiferAppId || "");
        history.push(link);
      },
    };

    const setSessionStorage = (link: string, stamptype: number, targetDate: string, belongOrgCode?: string) => {
      // 自画面用パラメータ
      if (filterTargetDateFrom) {
        sessionStorage.setItem("attendStampList.targetDateFrom", moment(filterTargetDateFrom).format("YYYY-MM-DD"));
        sessionStorage.setItem("attendStampList.targetDateTo", moment(filterTargetDateTo).format("YYYY-MM-DD"));
        sessionStorage.setItem("attendStampList.orgCode", String(filterOrgCode));
        sessionStorage.setItem("attendStampList.staffCode", String(filterStaffCode));
      }

      // Common parameters
      if (orgCode) {
        sessionStorage.setItem("application.orgCode", orgCode);
      } else if (filterOrgCode) {
        sessionStorage.setItem("application.orgCode", filterOrgCode);
      }
      sessionStorage.setItem("application.staffCode", String(staffCode));
      if (dispStaffName) {
        sessionStorage.setItem("application.dispStaffName", String(dispStaffName));
      } else {
        sessionStorage.setItem("application.dispStaffName", `${staffCode} ${staffName}`);
      }
      sessionStorage.setItem("application.targetDate", moment(targetDate).format("YYYY-MM-DD"));
      sessionStorage.setItem("application.returnDestination", window.location.pathname);
      sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);

      // Set highlight action
      sessionStorage.setItem("highlightIndex", String(index));
      sessionStorage.setItem("highlightAction", "false");
      switch (link) {
        case `${window.location.pathname}/attendStampModificationApplication`:
          sessionStorage.removeItem("application.achievementId");
          sessionStorage.removeItem("application.stampVersion");
          sessionStorage.removeItem("application.modifierApplicationId");

          // if( modifierApplicationId ){
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
            // 修正申請
            sessionStorage.setItem("application.achievementId", achievementId || "");
            sessionStorage.setItem("application.stampVersion", stampVersion || "");
            sessionStorage.setItem("application.modifierApplicationId", modifierApplicationId || "");
          }
          break;
        case `${window.location.pathname}/attendHolidayworkApplication`:
          if (stamptype === 1) {
            sessionStorage.setItem("application.orgCode", belongOrgCode || "");
            sessionStorage.setItem("application.editHolidaywork", "1");
          } else {
            sessionStorage.removeItem("application.orgCode");
            sessionStorage.removeItem("application.editHolidaywork");
          }
          break;
        case `${window.location.pathname}/attendOvertimeApplication`:
          if (stamptype === 1) {
            sessionStorage.setItem("application.orgCode", belongOrgCode || "");
            sessionStorage.setItem("application.editOvertime", "1");
          } else {
            sessionStorage.removeItem("application.orgCode");
            sessionStorage.removeItem("application.editOvertime");
          }
          break;
        case `${window.location.pathname}/attendHolidayApplication`:
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
            sessionStorage.setItem("application.achievementId", achievementId);
          } else {
            sessionStorage.removeItem("application.achievementId");
          }
          break;
        case `${window.location.pathname}/attendTransferApplication`:
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
          } else {
            sessionStorage.removeItem("application.achievementId");
          }
          break;

        default:
          // do nothing
          break;
      }
    };

    return [
      stampButtons,
      applyLeaveButton,
      applyOvertimeButton,
      applyAllowanceButton,
      applyHolidayWorkButton,
      applyTransferButton,
      applyStockPaidHolidayButton,
    ];
  };

  const getMenuItemButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
    orgCode: string,
    index: number,
  ): ContextMenuType[] => {
    const editBtn = {
      isRed: false,
      isSelectable: stateFilter.selectedOrg === orgCode,
      label: "シフトを編集する",
      isHidden: stateFilter.selectedOrg !== orgCode,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
      },
    };
    const deleteBtn = {
      isRed: false,
      isSelectable: stateFilter.selectedOrg === orgCode,
      label: "シフトを削除する",
      isHidden: stateFilter.selectedOrg !== orgCode,
      icon: <Icon type="delete" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setOpenDeleteModal(true);
      },
    };

    return [
      editBtn,
      deleteBtn,
      ...getMenuTimelineButtons(staffCode, staffName, targetDate, shiftPatternOptions, distinctionHoliday),
    ];
  };

  const getMenuTimelineButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
    index?: number,
  ): ContextMenuType[] => {
    const addShift = {
      label: "シフトを追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: () => {
        setSelectedItem(null);
        setShiftPatternModal(true);
      },
    };
    const takeHoliday = distinctionHoliday
      ? {
          label: "公休にする",
          isRed: false,
          isSelectable: true,
          stamptype: 0,
          subMenu: [
            {
              label: "法定休日",
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: async () => {
                let user = sessionStorage.getItem("loginUser.staffName")!;
                try {
                  setLoading(true);
                  await registerHoliday(
                    stateFilter.selectedOrg,
                    staffCode,
                    moment(targetDate).format("YYYY/MM/DD"),
                    true,
                    user,
                    user,
                  );
                  await reFetchMonthlyShifts();
                } catch (error) {
                  errorNotification(error.response.data.message);
                } finally {
                  setLoading(false);
                }
              },
            },
            {
              label: "所定休日",
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: async () => {
                // let user = sessionStorage.getItem("loginUser.staffName")!;
                try {
                  setLoading(true);
                  await registerHoliday(
                    stateFilter.selectedOrg,
                    staffCode,
                    moment(targetDate).format("YYYY/MM/DD"),
                    false,
                    staffName,
                    staffName,
                  );
                  await reFetchMonthlyShifts();
                } catch (error) {
                  errorNotification(error.response.data.message);
                } finally {
                  setLoading(false);
                }
              },
            },
          ],
        }
      : {
          label: "公休にする",
          isRed: false,
          isSelectable: true,
          stamptype: 0,
          onClick: async () => {
            try {
              setLoading(true);
              await registerHoliday(
                stateFilter.selectedOrg,
                staffCode,
                moment(targetDate).format("YYYY/MM/DD"),
                true,
                staffName,
                staffName,
              );
              await reFetchMonthlyShifts();
            } catch (error) {
              errorNotification(error.response.data.message);
            } finally {
              setLoading(false);
            }
          },
        };

    const addFromShiftPattern = {
      label: "シフトパターンから追加する",
      isRed: false,
      isSelectable: true,
      showSearchBox: true,
      stamptype: 0,
      subMenu:
        shiftPatternOptions.length > 0
          ? shiftPatternOptions.map((pattern) => ({
              label: pattern.shiftPatternName,
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: () => {
                handleAddFromShiftPattern(pattern.shiftPatternId, staffCode, targetDate);
              },
            }))
          : [
              {
                label: "データがありません",
                isRed: false,
                isSelectable: false,
                stamptype: 0,
              },
            ],
    };

    const copy = {
      label: "前日のコピー",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: async () => {
        let user = sessionStorage.getItem("loginUser.staffName")!;
        try {
          setLoading(true);
          await copyFromPreviousDay(stateFilter.selectedOrg, staffCode, moment(targetDate).format("YYYY/MM/DD"), user);
          await reFetchMonthlyShifts();
        } catch (error) {
          errorNotification(error.response.data.message);
        } finally {
          setLoading(false);
        }
      },
    };

    return [addShift, takeHoliday, addFromShiftPattern, copy];
  };

  const handleAddFromShiftPattern = async (shiftPatternId: string, staffCode: string, targetDate: string) => {
    let createUser = sessionStorage.getItem("loginUser.staffName")!,
      updateUser = sessionStorage.getItem("loginUser.staffName")!;
    setLoading(true);
    try {
      await addShiftPattern(
        stateFilter.selectedOrg,
        staffCode,
        moment(targetDate).format("YYYY/MM/DD"),
        shiftPatternId,
        createUser,
        updateUser,
      );
      await reFetchMonthlyShifts();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmploymentOptions = async (selectedOrg: string, dateFromStr: string, dateToStr: string) => {
    // let employmentList = await getEmploymentSelectList();
    let employmentList = await getEmploymentSelectListV2(selectedOrg, dateFromStr, dateToStr);

    const selectedEmploymentOptions =
      sessionStorage.getItem(`attendStampList.monthlyShift.employmentOptions`) ?? "all";

    const sessionList = selectedEmploymentOptions.split(",");

    let formatOptionType = employmentList?.map((opt) => ({
      id: opt.employmentId,
      label: opt.employmentName,
      value: opt.employmentCode,
      checked:
        selectedEmploymentOptions === "all" || sessionList.length === 0 || sessionList.includes(opt.employmentId),
    }));

    dispatch({
      type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
      payload: formatOptionType,
    });
    setLoadEmploymentDone(true);
    return formatOptionType;
  };

  const fetchDrawingData = async (selectedOrg: string, dateFromStr: string, viewPeriodStr: string) => {
    setLoading(true);

    let { dateFrom, dateTo, viewPeriod, useSales } = await fetchDrawingInfo(
      selectedOrg,
      dateFromStr || "",
      viewPeriodStr,
    );
    dispatch({
      type: "ON_CHANGE_DATE_FROM",
      payload: dateFrom,
    });
    if (dateFrom) {
      dispatch({
        type: "ON_CHANGE_DATE_TO",
        payload: dateTo,
      });
    }
    dispatch({
      type: "ON_CHANGE_VIEW_PERIOD",
      payload: viewPeriod,
    });

    return { dateFrom, dateTo, viewPeriod, useSales };
  };

  const fetchMonthly = async (
    selectedOrg: string,
    dateFromStr: string,
    dateToStr: string,
    isAttendExists: string,
    employmentOptions: OptionType[],
  ) => {
    setLoading(true);

    //check confirmed dates
    confirmMonthlyLockOutV1(selectedOrg, dateFromStr, dateToStr).then((response) => {
      setAchievementConfirm(response.achievementConfirm);
      setShiftConfirm(response.shiftConfirm);
    });

    //fetch Shopplan
    fetchShopPlan(dateFromStr, dateToStr, selectedOrg).then((response) => {
      setShopPlan(response);
    });

    //fetch Monthly shift
    let employmentIds = employmentOptions.reduce(
      (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
      "1",
    );
    let monthlyData = await fetchMonthlyShift(dateFromStr, dateToStr, selectedOrg, isAttendExists, employmentIds);
    setIsAchievementConfirm(monthlyData.isAchievementConfirm);
    setStaffs(monthlyData.staffInfo);
    setMonthlyShifts(monthlyData);
    setPageTotal(monthlyData.page.totalPages);
    setLoading(false);
  };

  // FETCH ORGANIZATION OPTIONS
  const fetchOrg = async (dateFromStr: string, dateToStr: string) => {
    getTreesOrgSelectRange(false, functionCode.monthlyShift, dateFromStr, dateToStr).then((response) => {
      let formattedOrgs = response.map((item) => ({
        value: String(item.orgCode),
        label: item.orgName,
      }));
      dispatch({
        type: "ON_CHANGE_ORG",
        payload: formattedOrgs,
      });
    });
  };

  useEffect(() => {
    fetchDrawingData(defaultStateReducer.selectedOrg, stateFilter.dateFromStr, stateFilter.viewPeriod).then(
      ({ dateFrom, dateTo, useSales }) => {
        fetchOrg(dateFrom, dateTo);
        fetchEmploymentOptions(defaultStateReducer.selectedOrg, dateFrom, dateTo).then((employmentOptions) => {
          const selectedEmploymentOptions =
            sessionStorage.getItem(`attendStampList.monthlyShift.employmentOptions`) ?? "all";

          const sessionList = selectedEmploymentOptions.split(",");

          const formatOptionType = employmentOptions.map((opt) => ({
            ...opt,
            checked:
              selectedEmploymentOptions === "all" ||
              sessionList.length === 0 ||
              selectedEmploymentOptions.split(",").includes(opt.id),
          }));

          dispatch({
            type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
            payload: formatOptionType,
          });

          fetchMonthly(
            defaultStateReducer.selectedOrg,
            dateFrom,
            dateTo,
            defaultStateReducer.isAttendExists,
            formatOptionType,
          );
        });

        setUseSales(useSales === 1);
      },
    );
  }, []);

  // useEffect(() => {
  //   const fetchDrawingData = async () => {
  //     setLoading(true);

  //     let { dateFrom, dateTo } = await fetchDrawingInfo(selectedOrg, stateFilter.dateFromStr || "", viewPeriod);
  //     setDateFromStr(dateFrom);
  //     if (dateFromStr) {
  //       setDateToStr(dateTo);
  //     }
  //   };

  //   fetchDrawingData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stateFilter.dateFromStr, viewPeriod]);

  return {
    detailRole,
    remote,
    setRemote,
    useSales,
    isLoading,
    setLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openRegisterModal,
    setOpenRegisterModal,
    openDeleteModal,
    setOpenDeleteModal,
    companyCode,
    history,
    loadEmploymentDone,
    fetchOrg,
    fetchDrawingData,
    fetchEmploymentOptions,
    fetchMonthly,
    filters: {
      // dateFromStr,
      // setDateFromStr,
      // dateToStr,
      // setDateToStr,
      // isAttendExists,
      // setAttendExists,
      // selectedOrg,
      // setSelectedOrg,
      // employmentOptions,
      // setEmploymentOptions,
      // viewPeriod,
      // setViewPeriod,
      dispatch,
      stateFilter,
      fetchMonthly,
      orgs,
      setOrgs,
      checkall,
      setCheckall,
      pageTotal,
      setPageTotal,
      pageIndex,
      setPageIndex,
      setLoadEmploymentDone,
    },
    monthlyCalendar: {
      monthlyShifts,
      setMonthlyShifts,
      staffs,
      setStaffs,
      shopPlan,
      setShopPlan,
      deleteItem,
      selectedItem,
      setSelectedItem,
      selectedCell,
      setSelectedCell,
      achievementConfirm,
      setAchievementConfirm,
      shiftConfirm,
      setShiftConfirm,
      submitShiftLockout,
      submitDayShiftLockout,
      downloadPdf,
      downloadExcel,
      reFetchMonthlyShifts,
      onClickAttendStampList,
      openDailyShiftConfirmModal,
      setDailyShiftConfirmModal,
      selectedDay,
      setSelectedDay,
      isAchievementConfirm,
      setIsAchievementConfirm,
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      openShiftPatternModal,
      setShiftPatternModal,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      setContextMenuX,
      contextMenuY,
      setContextMenuY,
      menuTimelineButtons,
      setMenuTimelineButtons,
      menuPerformanceButtons,
      setMenuPerformanceButtons,
      menuItemButtons,
      setMenuItemButtons,
      getMenuPerformanceButtons,
      getMenuItemButtons,
      getMenuTimelineButtons,
    },
    shiftPattern: {
      shiftPatternOptions,
      setShiftPatternOptions,
      openShiftPatternModal,
      setShiftPatternModal,
      getWorkdayOptions,
    },
  };
};
