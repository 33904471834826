/** @jsx jsx */
import React, { useContext } from "react";
import { grayScale } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import { useApplyingTransferForm } from "../ApplyingTab/hook";
import IconLabelButton from "components/molecules/IconLabelButton";
import Modal from "components/molecules/Modal";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import { attendApplicationApprovalContext } from "../hooks";

const WorkScheduleChangeApplication: React.FC = () => {
  const context = useContext(attendApplicationApprovalContext);

  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLogWorkScheduleChange,
  } = useApplyingTransferForm(context.processingWorkScheduleChangeList);

  const columns = React.useMemo(
    () => [
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: (cell: any) => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <div>
              <div>{cell.row.original.targetDate}</div>
              <IconLabelButton
                onClick={() => {
                  fetchChangeLogWorkScheduleChange(cell.row.original.rawData);
                  setChangeLogModalOpen(true);
                  setSelectChangelog("0");
                }}
                text="更新履歴"
                textAlign="center"
                isIcon={false}
              />
            </div>
          </div>
        ),
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "出勤組織",
        accessor: "shiftOrgName",
      },
      {
        Header: "開始時間", // starttime
        accessor: "stampListStartTime", // .startTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>{cell.row.original.stampListStartTime}</div>
        ),
      },
      {
        Header: "終了時間", // endtime
        accessor: "stampListEndTime", // .endTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>
            {cell.row.original.stampListEndTime && cell.row.original.stampListEndTime}
          </div>
        ),
      },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    [fetchChangeLogWorkScheduleChange, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <React.Fragment>
      <div
        css={css`
          table th:nth-of-type(1) {
            width: 140px;
          }
          table th:nth-of-type(2) {
            width: 140px;
          }
          table th:nth-of-type(3) {
            width: 120px;
          }
          table th:nth-of-type(4) {
            width: 140px;
          }
          table th:nth-of-type(5) {
            width: 80px;
          }
          table th:nth-of-type(6) {
            width: 80px;
          }
          table th:nth-of-type(7) {
            width: 140px;
          }
          table th:nth-of-type(8) {
            width: 140px;
          }
          table th:nth-of-type(9) {
            width: 140px;
          }
          table th:nth-of-type(10) {
            width: 200px;
          }
        `}
      >
        <Accordion
          text={`勤務予定変更申請 (${context.processingWorkScheduleChangeList.length}件）`}
          callBack={() => {}}
        >
          <DataTable
            columns={columns}
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            data={context.processingWorkScheduleChangeList}
            isGlobalFilter={true}
            sortBy={[
              { id: "targetDate", desc: true },
              { id: "targetPerson", desc: true },
            ]}
          />
        </Accordion>
      </div>
      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} isWorkPlan={true} />
        </Modal>
      </div>
      {/* ./Change log  */}
    </React.Fragment>
  );
};

export default WorkScheduleChangeApplication;
