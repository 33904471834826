
import { State, Face } from './type';

export const types = {
  SET_STATE: 'SET_STATE' as const,
  SET_LIST_FACE: 'SET_LIST_FACE' as const,
  SET_MODAL_CHOOSE_ACTION: 'SET_MODAL_CHOOSE_ACTION' as const,
  ADD_FACE: 'ADD_FACE' as const,
  CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION' as const,
  SET_TMP_IMAGE_MODAL: 'SET_TMP_IMAGE_MODAL' as const,
  SET_CROP: 'SET_CROP' as const,
  SET_ROTATION: 'SET_ROTATION' as const,
  SET_CROPPED_IMAGE: 'SET_CROPPED_IMAGE' as const,
  SET_OPEN_CAMERA: 'SET_OPEN_CAMERA' as const,
  ON_ERROR_CAMERA: 'ON_ERROR_CAMERA' as const,
  CLOSE_CAMERA: 'CLOSE_CAMERA' as const,
  REMOVE_DEPEND_IMAGE: 'REMOVE_DEPEND_IMAGE' as const,
  REMOVE_STAFF_IMAGE: 'REMOVE_STAFF_IMAGE' as const,
  SET_ENABLE_BUTTON_CAP: 'SET_ENABLE_BUTTON_CAP' as const,
  SET_SHOW_MESSAGE: 'SET_SHOW_MESSAGE' as const,
};

export const setState = (payload: State) => ({
  type: types.SET_STATE,
  payload,
});

export const setListFace = (payload: Array<Face>) => ({
  type: types.SET_LIST_FACE,
  payload,
});

export const setModalChooseAction = (payload: {open: boolean, indexAction: number}) => ({
  type: types.SET_MODAL_CHOOSE_ACTION,
  payload,
});

export const addFace = (payload: {face: Face, defaultFace: Face, maxLength: number}) => ({
  type: types.ADD_FACE,
  payload,
});

export const changeDescription = (payload: {index: number, description: string}) => ({
  type: types.CHANGE_DESCRIPTION,
  payload,
});

export const setTmpImageModal = (payload: string|undefined) => ({
  type: types.SET_TMP_IMAGE_MODAL,
  payload,
});

export const setCrop = (payload: any) => ({
  type: types.SET_CROP,
  payload,
});

export const setRotation = (payload: any) => ({
  type: types.SET_ROTATION,
  payload,
});

export const setCroppedImage = (payload: any) => ({
  type: types.SET_CROPPED_IMAGE,
  payload,
});

export const setOpenCamera = () => ({
  type: types.SET_OPEN_CAMERA,
});

export const onErrorCamera = () => ({
  type: types.ON_ERROR_CAMERA,
});

export const closeCamera = () => ({
  type: types.CLOSE_CAMERA,
});

export const removeDependImage = (payload: number) => ({
  type: types.REMOVE_DEPEND_IMAGE,
  payload
});

export const removeStaffImage = () => ({
  type: types.REMOVE_STAFF_IMAGE,
});

export const setEnableButtonCap = (payload: boolean) => ({
  type: types.SET_ENABLE_BUTTON_CAP,
  payload
});

export const setShowMessage = (payload: boolean) => ({
  type: types.SET_SHOW_MESSAGE,
  payload
});

type Action = (
  | ReturnType<typeof setState>
  | ReturnType<typeof setModalChooseAction>
  | ReturnType<typeof addFace>
  | ReturnType<typeof changeDescription>
  | ReturnType<typeof setTmpImageModal>
  | ReturnType<typeof setCrop>
  | ReturnType<typeof setRotation>
  | ReturnType<typeof setCroppedImage>
  | ReturnType<typeof setOpenCamera>
  | ReturnType<typeof onErrorCamera>
  | ReturnType<typeof closeCamera>
  | ReturnType<typeof removeStaffImage>
  | ReturnType<typeof removeDependImage>
  | ReturnType<typeof setEnableButtonCap>
  | ReturnType<typeof setShowMessage>
  | ReturnType<typeof setListFace>
);

export default Action;
