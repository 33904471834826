/* eslint-disable no-return-assign */
import { getSalesPlanDaily } from 'api/salesReport';
import {
  useEffect, useState, useCallback,
} from 'react';
import useToastNotification from 'hooks/useToastNotification';


// data general key name
const generalKey = [
  'amountOfSalesTotal',
  'amountOfSalesAdvertisement',
  'amountOfSalesFreeTotal',
  'standardCostTotal',
  'standardCostFood',
  'standardCostDrink',
  'standardCostRate',
  'grossProfitTotal',
  'grossProfitRate',
  'laborCostsTotal',
  'laborCostsEmployee',
  'laborCostsParttime',
  'laborCostsRate',
  'advertisingExpense',
  'rentTotal',
  'rentRate',
  'otherExpensesTotal',
  'otherExpensesVariableCosts',
  'otherExpensesFixedCost',
  'otherExpensesRate',
  'operatingIncomeTotal',
  'operatingIncomeRate',
  'rowBlank_01',
  'purchaseAmountTotal',
  'purchaseAmountFood',
  'purchaseAmountDrink',
  'purchaseAmountOhter',
  'rowBlank_02',
  'pricePerCustomer',
  'numberOfCustomers',
  'numberOfGroups',
  'averageNumberOfGuestPerGroup',
  'TurnoverRate',
  'porofitMarginAmount',
  'porofitMarginRate',
  'contributionMarginAmount',
  'contributionMarginRate',
  'breakEvenPointSales',
  'breakEvenPointNumberOfGuest',
  'laborCostRateTotal',
  'laborCostRateEmployee',
  'laborCostRatPartTime',
  'totalWorkingHoursTotal',
  'totalWorkingHoursEmployee',
  'totalWorkingHoursPartTime',
  'numberOfEmployeesTotal',
  'numberOfEmployeesEmployee',
  'numberOfEmployeesPartTime',
  'whenPeopleAreHigh',
  'humanTimeProductivity',
  'numberOfPickUps',
  'laborProductivity',
  'laborShare',
  'FLCost',
  'FLRate',
  'FLACost',
  'FLARate',
  'FLARCost',
  'FLARRate',
  'FLAROCost',
  'FLARORate',
];

export const useSalesMonthlyReport = () => {
  const { errorNotification } = useToastNotification();

  const targetOrgCode = sessionStorage.getItem('SalesDailyReport.targetOrgCode') || '';
  const targetDay = sessionStorage.getItem('SalesDailyReport.targetDay') || new Date();

  //
  const [dataKeyName, setDataKeyName] = useState<Array<any>>([]);
  const [generalData, setGeneralData] = useState<Array<any>>([]);
  const [reportByTimeOfDay, SetReportByTimeOfDay] = useState([]);
  const [cashRegisterReport, SetCashRegisterReport] = useState([]);
  const [grandTotalnData, setGrandTotalnData] = useState([]);
  const [sameDayData, setSameDayData] = useState([]);

  const [advertisingData, setAdvertisingData] = useState([]);
  const [countAdvertising, setCountAdvertising] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  // データの取得
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    getSalesPlanDaily(
      targetOrgCode,
      new Date(targetDay),
    ).then((data: any) => {
      setGeneralData(data);
      // handle data
      if (data) {
        updateCountDayAndAdvertising(data);
      }
      setIsLoading(false);
    })
      .catch((exception : any) => {
        setIsLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  const updateCountDayAndAdvertising = useCallback(async (data: any) => {
    dataCalculation(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  const dataCalculation = useCallback(async (dailyData: any) => {
    let tmpCountAdvertising = 0;
    let advertisingData: any = [];
    // update count advertising
    if (dailyData.accumulated_data && dailyData.accumulated_data.advertising) {
      // advertisingData
      advertisingData = dailyData.accumulated_data.advertising;
      tmpCountAdvertising = dailyData.accumulated_data.advertising.length;
    }
    setAdvertisingData(advertisingData);
    setCountAdvertising(tmpCountAdvertising);

    // レジレポート
    const tmpCashRegisterReport: any = {};
    if (dailyData && dailyData.cash_data) {
      const tmpCheckoutInformation: any = {};
      let tmpCashSales = 0;
      const tmpKeyArr: any = [];
      dailyData.cash_data.payment_media.map((obj_payment_media: any, index: number) => {
        if (obj_payment_media.payment_media_category_code !== null) {
          if (!tmpCheckoutInformation[obj_payment_media.payment_media_category_code]) {
            tmpCheckoutInformation[obj_payment_media.payment_media_category_code] = [];
            tmpCheckoutInformation[obj_payment_media.payment_media_category_code].data = [];
          }
          tmpCheckoutInformation[obj_payment_media.payment_media_category_code].category_name = obj_payment_media.payment_media_category_name;
          tmpCheckoutInformation[obj_payment_media.payment_media_category_code].data.push(obj_payment_media);
          if (tmpKeyArr.indexOf(obj_payment_media.payment_media_category_code) === -1) {
            tmpKeyArr.push(obj_payment_media.payment_media_category_code);
          }
        } else {
          tmpKeyArr.push(index);
          tmpCheckoutInformation[index] = [];
          tmpCheckoutInformation[index].category_name = null;
          tmpCheckoutInformation[index].data = obj_payment_media;
        }

        // calc total cash sales
        if (obj_payment_media.payment_media_mst_code === '000') {
          tmpCashSales += obj_payment_media.payment_media_amount;
        }
        return true;
      });
      tmpCashRegisterReport.checkoutInformation = [];
      tmpCashRegisterReport.countPaymentMedia = dailyData.cash_data.payment_media.length;
      dailyData.cash_data.deposit_withdrawal.cash_sales = tmpCashSales;

      tmpKeyArr.map((val_tmp: any, tmp_key: number) => {
        const obj_tmp = tmpCheckoutInformation[val_tmp];
        if (obj_tmp.data && obj_tmp.data.length) {
          let tmpObj: any = {};
          obj_tmp.data.map((obj_sub_tmp: any, tmp_sub_key: number) => {
            tmpObj = {};
            if (tmp_sub_key === 0) {
              tmpObj.rowspan = obj_tmp.data.length;
              tmpObj.colspan = 1;
              tmpObj.category_name = obj_tmp.category_name;
            } else {
              tmpObj.rowspan = 1;
              tmpObj.colspan = 1;
              tmpObj.category_name = null;
            }

            tmpObj.data = obj_sub_tmp;
            tmpCashRegisterReport.checkoutInformation.push(tmpObj);
            return true;
          });
        } else {
          obj_tmp.rowspan = 1;
          obj_tmp.colspan = 2;
          tmpCashRegisterReport.checkoutInformation.push(obj_tmp);
        }
        return true;
      });
    }
    SetCashRegisterReport(tmpCashRegisterReport);


    // 時間帯別レポート
    const tmpreportByTimeOfDay: any = [];
    if (dailyData && dailyData.time_data) {
      dailyData.time_data.map((obj_time_of_day: any, index: number) => {
        tmpreportByTimeOfDay.push([
          obj_time_of_day.target_time, // 時間
          numberFormater(obj_time_of_day.amount), // 売上高
          numberFormater(getRatioLabel(obj_time_of_day.cost, obj_time_of_day.amount)), // 標準原価%
          numberFormater(obj_time_of_day.amount - obj_time_of_day.cost), // 売上総利益
          numberFormater(obj_time_of_day.customers), // 組数
          numberFormater(obj_time_of_day.guest_cnt), // 客数
          numberFormater(getDivision(obj_time_of_day.guest_cnt, dailyData.daily_data.budget.number_of_seat)), // 回転率
          numberFormater(getDivision(obj_time_of_day.amount, obj_time_of_day.guest_cnt, 0)), // 客単価>売上高
          getRatioLabel(obj_time_of_day.cost, obj_time_of_day.amount), // 客単価>標準原価%
          numberFormater(getDivision(obj_time_of_day.amount, obj_time_of_day.guest_cnt, 0) - (getDivision(obj_time_of_day.amount, obj_time_of_day.guest_cnt, 0) * getDivision(obj_time_of_day.cost, obj_time_of_day.amount, 0))), // 客単価>売上総利益
        ]);
        return true;
      });
    }
    SetReportByTimeOfDay(tmpreportByTimeOfDay);

    // set default
    const tmpDataKeyName: any = [];
    const tmpGrandTotalnData: any = [];
    const tmpSameDayData: any = [];
    setDataKeyName([]);

    //
    // handle
    generalKey.map((value) => {
      // push data 売上高>広告
      if (value === 'amountOfSalesAdvertisement') {
        tmpDataKeyName.push('amountOfSalesAdvertisementTotal');
        tmpGrandTotalnData.amountOfSalesAdvertisementTotal = [];
        tmpSameDayData.amountOfSalesAdvertisementTotal = [];
        const tmpTotal = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };

        if (tmpCountAdvertising) {
          // push data 売上高>広告 for 累計
          dailyData.accumulated_data.advertising.map((obj_adv: any, key_adv: number) => {
            tmpTotal.achievement += obj_adv.advertising_media_amount;
            tmpTotal.budget += obj_adv.budget_advertising_media_amount;
            tmpTotal.monthly_budget += obj_adv.monthly_budget_advertising_media_amount;
            tmpDataKeyName.push(`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`);
            tmpGrandTotalnData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`] = [];

            tmpGrandTotalnData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`].push(
              obj_adv.advertising_media_amount,
              obj_adv.budget_advertising_media_amount,
              getRatioLabel(obj_adv.advertising_media_amount, obj_adv.budget_advertising_media_amount),
              obj_adv.monthly_budget_advertising_media_amount,
              getRatioLabel(obj_adv.advertising_media_amount, obj_adv.monthly_budget_advertising_media_amount),
            );

            // push data 売上高>広告 for 当日
            tmpSameDayData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`] = [];
            tmpTotal.achievement_same_day += dailyData.daily_data.advertising[key_adv].advertising_media_amount;
            tmpTotal.budget_same_day += dailyData.daily_data.advertising[key_adv].budget_advertising_media_amount;
            tmpSameDayData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`].push(
              dailyData.daily_data.advertising[key_adv].advertising_media_amount,
              dailyData.daily_data.advertising[key_adv].budget_advertising_media_amount,
              getRatioLabel(dailyData.daily_data.advertising[key_adv].advertising_media_amount, dailyData.daily_data.advertising[key_adv].budget_advertising_media_amount),
            );
            return true;
          });
        }
        // push data 売上高>広告>合計 for 累計
        tmpGrandTotalnData.amountOfSalesAdvertisementTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
          tmpTotal.monthly_budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.monthly_budget),
        );

        // push data 売上高>広告>合計 for 当日
        tmpSameDayData.amountOfSalesAdvertisementTotal.push(
          tmpTotal.achievement_same_day,
          tmpTotal.budget_same_day,
          getRatioLabel(tmpTotal.achievement_same_day, tmpTotal.budget_same_day),
        );

        // push data 売上高>広告
      } else if (value === 'advertisingExpense') {
        tmpDataKeyName.push('advertisingExpenseTotal');
        tmpGrandTotalnData.advertisingExpenseTotal = [];
        tmpSameDayData.advertisingExpenseTotal = [];
        const tmpTotal = {
          achievement: 0,
          budget: 0,
          monthly_budget: 0,
          achievement_same_day: 0,
          budget_same_day: 0,
        };

        if (tmpCountAdvertising) {
          // push data 広告宣伝費 for accumulated_data
          dailyData.accumulated_data.advertising.map((obj_adv: any, key_adv: number) => {
            tmpTotal.achievement += obj_adv.advertising_media_expense;
            tmpTotal.budget += obj_adv.budget_advertising_media_expense;
            tmpTotal.monthly_budget += obj_adv.monthly_budget_advertising_media_expense;
            tmpDataKeyName.push(`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`);
            tmpGrandTotalnData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`] = [];

            tmpGrandTotalnData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`].push(
              obj_adv.advertising_media_expense,
              obj_adv.budget_advertising_media_expense,
              getRatioLabel(obj_adv.advertising_media_expense, obj_adv.budget_advertising_media_expense),
              obj_adv.monthly_budget_advertising_media_expense,
              getRatioLabel(obj_adv.advertising_media_expense, obj_adv.monthly_budget_advertising_media_expense),
            );

            // push data 広告宣伝費 for 当日
            tmpSameDayData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`] = [];
            tmpTotal.achievement_same_day += dailyData.daily_data.advertising[key_adv].advertising_media_expense;
            tmpTotal.budget_same_day += dailyData.daily_data.advertising[key_adv].budget_advertising_media_expense;
            tmpSameDayData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`].push(
              dailyData.daily_data.advertising[key_adv].advertising_media_expense,
              dailyData.daily_data.advertising[key_adv].budget_advertising_media_expense,
              getRatioLabel(dailyData.daily_data.advertising[key_adv].advertising_media_expense, dailyData.daily_data.advertising[key_adv].budget_advertising_media_expense),
            );
            return true;
          });
        }
        // push data 広告宣伝費>合計 for 累計
        tmpGrandTotalnData.advertisingExpenseTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
          tmpTotal.monthly_budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.monthly_budget),
        );

        // push data 広告宣伝費>合計 for 当日
        tmpSameDayData.advertisingExpenseTotal.push(
          tmpTotal.achievement_same_day,
          tmpTotal.budget_same_day,
          getRatioLabel(tmpTotal.achievement_same_day, tmpTotal.budget_same_day),
        );

        tmpDataKeyName.push('advertisingExpenseRate');
        tmpGrandTotalnData.advertisingExpenseRate = [];
        tmpSameDayData.advertisingExpenseRate = [];

        tmpGrandTotalnData.advertisingExpenseRate.push(
          getRatioLabel(tmpGrandTotalnData.advertisingExpenseTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
          getRatioLabel(tmpGrandTotalnData.advertisingExpenseTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
          '-',
          getRatioLabel(tmpGrandTotalnData.advertisingExpenseTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
          '-',
        );

        tmpSameDayData.advertisingExpenseRate.push(
          getRatioLabel(tmpSameDayData.advertisingExpenseTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
          getRatioLabel(tmpSameDayData.advertisingExpenseTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
          '-',
        );
      } else {
        tmpDataKeyName.push(value);
        tmpGrandTotalnData[value] = [];
        tmpSameDayData[value] = [];
        let tmpMonthlyBudget: any = 0;
        let tmpPerformance: any = 0;
        let tmpBudget: any = 0;
        let tmpTotal = 0;
        switch (value) {
          // 売上高>合計
          case 'amountOfSalesTotal':

            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.net_sales,
              dailyData.accumulated_data.budget.sales_budget_total,
              getRatioLabel(dailyData.accumulated_data.budget.net_sales, dailyData.accumulated_data.budget.sales_budget_total),
              dailyData.accumulated_data.budget.monthly_sales_budget_total,
              getRatioLabel(dailyData.accumulated_data.budget.net_sales, dailyData.accumulated_data.budget.monthly_sales_budget_total),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.net_sales,
              dailyData.daily_data.budget.sales_budget_total,
              getRatioLabel(dailyData.daily_data.budget.net_sales, dailyData.daily_data.budget.sales_budget_total),
            );

            break;
            // 売上高>フリー>合計
          case 'amountOfSalesFreeTotal':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.amountOfSalesAdvertisementTotal[0];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              dailyData.accumulated_data.budget.sales_budget_free,
              getRatioLabel(tmpPerformance, dailyData.accumulated_data.budget.sales_budget_free),
              dailyData.accumulated_data.budget.monthly_sales_budget_free,
              getRatioLabel(tmpPerformance, dailyData.accumulated_data.budget.monthly_sales_budget_free),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.amountOfSalesAdvertisementTotal[0];
            tmpSameDayData[value].push(
              tmpPerformance,
              dailyData.daily_data.budget.sales_budget_free,
              getRatioLabel(tmpPerformance, dailyData.daily_data.budget.sales_budget_free),
            );

            break;
            // 標準原価>合計
          case 'standardCostTotal':
            // tmpTotal = dailyData.accumulated_data.budget.food_menu_cost + dailyData.accumulated_data.budget.drink_menu_cost;
            tmpTotal = dailyData.accumulated_data.budget.menu_cost_total;
            tmpGrandTotalnData[value].push(
              tmpTotal,
              dailyData.accumulated_data.budget.sales_budget_cost_total,
              getRatioLabel(tmpTotal, dailyData.accumulated_data.budget.sales_budget_cost_total),
              dailyData.accumulated_data.budget.monthly_sales_budget_cost_total,
              getRatioLabel(tmpTotal, dailyData.accumulated_data.budget.monthly_sales_budget_cost_total),
            );

            tmpSameDayData[value].push(
              (dailyData.daily_data.budget.menu_cost_total),
              dailyData.daily_data.budget.sales_budget_cost_total,
              getRatioLabel((dailyData.daily_data.budget.menu_cost_total), dailyData.daily_data.budget.sales_budget_cost_total),
            );
            // tmpSameDayData[value].push(
            //    (dailyData.daily_data.budget.food_menu_cost + dailyData.daily_data.budget.drink_menu_cost),
            //    dailyData.daily_data.budget.sales_budget_cost_total,
            //    getRatioLabel((dailyData.daily_data.budget.food_menu_cost + dailyData.daily_data.budget.drink_menu_cost), dailyData.daily_data.budget.sales_budget_cost_total),
            // );


            break;
            // 標準原価>フード
          case 'standardCostFood':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.food_menu_cost,
              dailyData.accumulated_data.budget.sales_budget_food_cost,
              getRatioLabel(dailyData.accumulated_data.budget.food_menu_cost, dailyData.accumulated_data.budget.sales_budget_food_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_food_cost,
              getRatioLabel(dailyData.accumulated_data.budget.food_menu_cost, dailyData.accumulated_data.budget.monthly_sales_budget_food_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.food_menu_cost,
              dailyData.daily_data.budget.sales_budget_food_cost,
              getRatioLabel(dailyData.daily_data.budget.food_menu_cost, dailyData.daily_data.budget.sales_budget_food_cost),
            );


            break;
            // 標準原価>ドリンク
          case 'standardCostDrink':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.drink_menu_cost,
              dailyData.accumulated_data.budget.sales_budget_drink_cost,
              getRatioLabel(dailyData.accumulated_data.budget.drink_menu_cost, dailyData.accumulated_data.budget.sales_budget_drink_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost,
              getRatioLabel(dailyData.accumulated_data.budget.drink_menu_cost, dailyData.accumulated_data.budget.monthly_sales_budget_drink_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.drink_menu_cost,
              dailyData.daily_data.budget.sales_budget_drink_cost,
              getRatioLabel(dailyData.daily_data.budget.drink_menu_cost, dailyData.daily_data.budget.sales_budget_drink_cost),
            );


            break;
            // 標準原価>率
          case 'standardCostRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.standardCostTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.standardCostTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.standardCostTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.standardCostTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.standardCostTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;
            // 売上総利益>合計
          case 'grossProfitTotal':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.standardCostTotal[0];
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_gross_profit;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_gross_profit;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.standardCostTotal[0];
            tmpBudget = dailyData.daily_data.budget.sales_budget_gross_profit;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;
            // 売上総利益>率
          case 'grossProfitRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.grossProfitTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.grossProfitTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.grossProfitTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.grossProfitTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.grossProfitTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;
            // 人件費>合計
          case 'laborCostsTotal':
            tmpPerformance = dailyData.accumulated_data.labor_cost.employee_labor_cost + dailyData.accumulated_data.labor_cost.part_labor_cost;
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_labor_cost_total;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_labor_cost_total;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = dailyData.daily_data.labor_cost.employee_labor_cost + dailyData.daily_data.labor_cost.part_labor_cost;
            tmpBudget = dailyData.daily_data.budget.sales_budget_labor_cost_total;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;
            // 人件費>社員
          case 'laborCostsEmployee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.employee_labor_cost,
              dailyData.accumulated_data.budget.sales_budget_employee_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.employee_labor_cost, dailyData.accumulated_data.budget.sales_budget_employee_labor_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_employee_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.employee_labor_cost, dailyData.accumulated_data.budget.monthly_sales_budget_employee_labor_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.employee_labor_cost,
              dailyData.daily_data.budget.sales_budget_employee_labor_cost,
              getRatioLabel(dailyData.daily_data.labor_cost.employee_labor_cost, dailyData.daily_data.budget.sales_budget_employee_labor_cost),
            );
            break;
            // 人件費>バイト
          case 'laborCostsParttime':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.part_labor_cost,
              dailyData.accumulated_data.budget.sales_budget_part_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.part_labor_cost, dailyData.accumulated_data.budget.sales_budget_part_labor_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_part_labor_cost,
              getRatioLabel(dailyData.accumulated_data.labor_cost.part_labor_cost, dailyData.accumulated_data.budget.monthly_sales_budget_part_labor_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.part_labor_cost,
              dailyData.daily_data.budget.sales_budget_part_labor_cost,
              getRatioLabel(dailyData.daily_data.labor_cost.part_labor_cost, dailyData.daily_data.budget.sales_budget_part_labor_cost),
            );

            break;
            // 人件費>率
          case 'laborCostsRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.laborCostsTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.laborCostsTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;

            // 家賃>合計
          case 'rentTotal':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.sales_budget_land_rent,
              dailyData.accumulated_data.budget.sales_budget_land_rent,
              getRatioLabel(dailyData.accumulated_data.budget.sales_budget_land_rent, dailyData.accumulated_data.budget.sales_budget_land_rent),
              dailyData.accumulated_data.budget.monthly_sales_budget_land_rent,
              getRatioLabel(dailyData.accumulated_data.budget.sales_budget_land_rent, dailyData.accumulated_data.budget.monthly_sales_budget_land_rent),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.sales_budget_land_rent,
              dailyData.daily_data.budget.sales_budget_land_rent,
              getRatioLabel(dailyData.daily_data.budget.sales_budget_land_rent, dailyData.daily_data.budget.sales_budget_land_rent),
            );

            break;

            // 家賃>率
          case 'rentRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.rentTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.rentTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.rentTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.rentTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.rentTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;
            // その他経費>合計
          case 'otherExpensesTotal':
            tmpPerformance = dailyData.accumulated_data.budget.sales_budget_other_fixed_cost + dailyData.accumulated_data.budget.sales_budget_other_variable_costs;
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_other_expenses_total;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_other_fixed_cost + dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = dailyData.daily_data.budget.sales_budget_other_fixed_cost + dailyData.daily_data.budget.sales_budget_other_variable_costs;
            tmpBudget = dailyData.daily_data.budget.sales_budget_other_expenses_total;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

            // その他経費>変動費
          case 'otherExpensesVariableCosts':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.sales_budget_other_variable_costs,
              dailyData.accumulated_data.budget.sales_budget_other_variable_costs,
              getRatioLabel(dailyData.accumulated_data.budget.sales_budget_other_variable_costs, dailyData.accumulated_data.budget.sales_budget_other_variable_costs),
              dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs,
              getRatioLabel(dailyData.accumulated_data.budget.sales_budget_other_variable_costs, dailyData.accumulated_data.budget.monthly_sales_budget_other_variable_costs),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.sales_budget_other_variable_costs,
              dailyData.daily_data.budget.sales_budget_other_variable_costs,
              getRatioLabel(dailyData.daily_data.budget.sales_budget_other_variable_costs, dailyData.daily_data.budget.sales_budget_other_variable_costs),
            );


            break;

            // その他経費>固定費
          case 'otherExpensesFixedCost':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.sales_budget_other_fixed_cost,
              dailyData.accumulated_data.budget.sales_budget_other_fixed_cost,
              getRatioLabel(dailyData.accumulated_data.budget.sales_budget_other_fixed_cost, dailyData.accumulated_data.budget.sales_budget_other_fixed_cost),
              dailyData.accumulated_data.budget.monthly_sales_budget_other_fixed_cost,
              getRatioLabel(dailyData.accumulated_data.budget.sales_budget_other_fixed_cost, dailyData.accumulated_data.budget.monthly_sales_budget_other_fixed_cost),
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.sales_budget_other_fixed_cost,
              dailyData.daily_data.budget.sales_budget_other_fixed_cost,
              getRatioLabel(dailyData.daily_data.budget.sales_budget_other_fixed_cost, dailyData.daily_data.budget.sales_budget_other_fixed_cost),
            );

            break;

            // その他経費>率
          case 'otherExpensesRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.otherExpensesTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.otherExpensesTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.otherExpensesTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.otherExpensesTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.otherExpensesTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );

            break;

            // 営業利益>合計
          case 'operatingIncomeTotal':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - tmpGrandTotalnData.standardCostTotal[0] - tmpGrandTotalnData.laborCostsTotal[0] - tmpGrandTotalnData.advertisingExpenseTotal[0] - tmpGrandTotalnData.rentTotal[0] - tmpGrandTotalnData.otherExpensesTotal[0];
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_operating_income;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_operating_income;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - tmpSameDayData.standardCostTotal[0] - tmpSameDayData.laborCostsTotal[0] - tmpSameDayData.advertisingExpenseTotal[0] - tmpSameDayData.rentTotal[0] - tmpSameDayData.otherExpensesTotal[0];
            tmpBudget = dailyData.daily_data.budget.sales_budget_operating_income;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

            // 営業利益>率
          case 'operatingIncomeRate':
            /*
            tmpMonthlyBudget = getRatioLabel((tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.otherExpensesTotal[3]), tmpGrandTotalnData.grossProfitTotal[3]);
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.operatingIncomeTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.operatingIncomeTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.operatingIncomeTotal[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.operatingIncomeTotal[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            */
            tmpMonthlyBudget = getRatioLabel(
              tmpGrandTotalnData.operatingIncomeTotal[3], 
              tmpGrandTotalnData.grossProfitTotal[3]
            );
            tmpGrandTotalnData[value].push(
              getRatioLabel(
                tmpGrandTotalnData.operatingIncomeTotal[0], 
                tmpGrandTotalnData.grossProfitTotal[0]
              ),
              getRatioLabel(
                tmpGrandTotalnData.operatingIncomeTotal[1], 
                tmpGrandTotalnData.grossProfitTotal[1]
              ),
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(
                tmpSameDayData.operatingIncomeTotal[0], 
                tmpSameDayData.grossProfitTotal[0]
              ),
              getRatioLabel(
                tmpSameDayData.operatingIncomeTotal[1], 
                tmpSameDayData.grossProfitTotal[1]
              ),
              '-',
            );
            break;

            // 仕入額>合計
          case 'purchaseAmountTotal':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.purchase_sales_total_cost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.purchase_sales_total_cost,
              '-',
              '-',
            );

            break;

            // 仕入額>フード
          case 'purchaseAmountFood':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.purchase_food_cost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.purchase_food_cost,
              '-',
              '-',
            );

            break;

            // 仕入額>ドリンク
          case 'purchaseAmountDrink':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.purchase_drink_cost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.purchase_drink_cost,
              '-',
              '-',
            );

            break;

            // 仕入額>その他
          case 'purchaseAmountOhter':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.purchase_other_cost,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.purchase_other_cost,
              '-',
              '-',
            );

            break;

            // 客単価
          case 'pricePerCustomer':
            tmpPerformance = getDivision(tmpGrandTotalnData.amountOfSalesTotal[0], dailyData.accumulated_data.budget.guest_cnt, 0);
            // tmpBudget = dailyData.accumulated_data.budget.per_customer_price;

            tmpBudget = getDivision(dailyData.accumulated_data.budget.sales_budget_total, dailyData.accumulated_data.budget.sales_budget_guest_cnt, 0);

            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_per_customer_price;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = getDivision(tmpSameDayData.amountOfSalesTotal[0], dailyData.daily_data.budget.guest_cnt, 0);
            tmpBudget = dailyData.daily_data.budget.per_customer_price;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

            // 客数
          case 'numberOfCustomers':
            tmpPerformance = dailyData.accumulated_data.budget.guest_cnt;
            tmpBudget = dailyData.accumulated_data.budget.sales_budget_guest_cnt;
            tmpMonthlyBudget = dailyData.accumulated_data.budget.monthly_sales_budget_guest_cnt;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = dailyData.daily_data.budget.guest_cnt;
            tmpBudget = dailyData.daily_data.budget.sales_budget_guest_cnt;
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

            // 組数
          case 'numberOfGroups':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.budget.customers,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.budget.customers,
              '-',
              '-',
            );
            break;

            // 組あたり平均人数
          case 'averageNumberOfGuestPerGroup':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.numberOfCustomers[0], tmpGrandTotalnData.numberOfGroups[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.numberOfCustomers[0], tmpSameDayData.numberOfGroups[0], 0),
              '-',
              '-',
            );

            break;

            // 回転率
          case 'TurnoverRate':
            tmpGrandTotalnData[value].push(
              getDivision(dailyData.accumulated_data.budget.guest_cnt, dailyData.accumulated_data.budget.number_of_seat, 2),
              getDivision(dailyData.accumulated_data.budget.sales_budget_guest_cnt, dailyData.accumulated_data.budget.number_of_seat, 2),
              '-',
              getDivision(dailyData.accumulated_data.budget.monthly_sales_budget_guest_cnt, dailyData.accumulated_data.budget.monthly_number_of_seat, 2),
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(dailyData.daily_data.budget.guest_cnt, dailyData.daily_data.budget.number_of_seat, 2),
              getDivision(dailyData.daily_data.budget.sales_budget_guest_cnt, dailyData.daily_data.budget.number_of_seat, 2),
              '-',
            );
            break;

            // 限界利益>額
          case 'porofitMarginAmount':
            tmpPerformance = tmpGrandTotalnData.amountOfSalesTotal[0] - (tmpGrandTotalnData.laborCostsParttime[0] + tmpGrandTotalnData.otherExpensesVariableCosts[0]);
            tmpBudget = tmpGrandTotalnData.amountOfSalesTotal[1] - (tmpGrandTotalnData.laborCostsParttime[1] + tmpGrandTotalnData.otherExpensesVariableCosts[1]);
            tmpMonthlyBudget = tmpGrandTotalnData.amountOfSalesTotal[3] - (tmpGrandTotalnData.laborCostsParttime[3] + tmpGrandTotalnData.otherExpensesVariableCosts[3]);

            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.amountOfSalesTotal[0] - (tmpSameDayData.laborCostsParttime[0] + tmpSameDayData.otherExpensesVariableCosts[0]);
            tmpBudget = tmpSameDayData.amountOfSalesTotal[1] - (tmpSameDayData.laborCostsParttime[1] + tmpSameDayData.otherExpensesVariableCosts[1]);


            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

            // 限界利益>額
          case 'porofitMarginRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.porofitMarginAmount[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.porofitMarginAmount[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.porofitMarginAmount[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );


            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.porofitMarginAmount[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.porofitMarginAmount[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

            // 貢献利益>率
          case 'contributionMarginAmount':
            tmpPerformance = tmpGrandTotalnData.porofitMarginAmount[0] - (tmpGrandTotalnData.laborCostsEmployee[0] + tmpGrandTotalnData.advertisingExpenseTotal[0] + tmpGrandTotalnData.rentTotal[0] + tmpGrandTotalnData.otherExpensesFixedCost[0]);
            tmpBudget = tmpGrandTotalnData.porofitMarginAmount[1] - (tmpGrandTotalnData.laborCostsEmployee[1] + tmpGrandTotalnData.advertisingExpenseTotal[1] + tmpGrandTotalnData.rentTotal[1] + tmpGrandTotalnData.otherExpensesFixedCost[1]);
            tmpMonthlyBudget = tmpGrandTotalnData.porofitMarginAmount[3] - (tmpGrandTotalnData.laborCostsEmployee[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.otherExpensesFixedCost[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.porofitMarginAmount[0] - (tmpSameDayData.laborCostsEmployee[0] + tmpSameDayData.advertisingExpenseTotal[0] + tmpSameDayData.rentTotal[0] + tmpSameDayData.otherExpensesFixedCost[0]);
            tmpBudget = tmpSameDayData.porofitMarginAmount[1] - (tmpSameDayData.laborCostsEmployee[1] + tmpSameDayData.advertisingExpenseTotal[1] + tmpSameDayData.rentTotal[1] + tmpSameDayData.otherExpensesFixedCost[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

            // 貢献利益>率
          case 'contributionMarginRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.contributionMarginAmount[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.contributionMarginAmount[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.contributionMarginAmount[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.contributionMarginAmount[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.contributionMarginAmount[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

            // 損益分岐点>売上
          case 'breakEvenPointSales':

            tmpPerformance = getDivision((tmpGrandTotalnData.laborCostsEmployee[0] + tmpGrandTotalnData.advertisingExpenseTotal[0] + tmpGrandTotalnData.rentTotal[0] + tmpGrandTotalnData.otherExpensesFixedCost[0]), tmpGrandTotalnData.porofitMarginRate[0], 0);
            tmpBudget = getDivision((tmpGrandTotalnData.laborCostsEmployee[1] + tmpGrandTotalnData.advertisingExpenseTotal[1] + tmpGrandTotalnData.rentTotal[1] + tmpGrandTotalnData.otherExpensesFixedCost[1]), tmpGrandTotalnData.porofitMarginRate[1], 0);
            tmpMonthlyBudget = getDivision((tmpGrandTotalnData.laborCostsEmployee[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.otherExpensesFixedCost[3]), tmpGrandTotalnData.porofitMarginRate[3], 0);

            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = getDivision((tmpSameDayData.laborCostsEmployee[0] + tmpSameDayData.advertisingExpenseTotal[0] + tmpSameDayData.rentTotal[0] + tmpSameDayData.otherExpensesFixedCost[0]), tmpSameDayData.porofitMarginRate[0], 0);
            tmpBudget = getDivision((tmpSameDayData.laborCostsEmployee[1] + tmpSameDayData.advertisingExpenseTotal[1] + tmpSameDayData.rentTotal[1] + tmpSameDayData.otherExpensesFixedCost[1]), tmpSameDayData.porofitMarginRate[1], 0);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            break;

            // 損益分岐点>客数
          case 'breakEvenPointNumberOfGuest':
            tmpPerformance = getDivision(tmpGrandTotalnData.breakEvenPointSales[0], tmpGrandTotalnData.pricePerCustomer[0], 0);
            tmpBudget = getDivision(tmpGrandTotalnData.breakEvenPointSales[1], tmpGrandTotalnData.pricePerCustomer[1], 0);
            tmpMonthlyBudget = getDivision(tmpGrandTotalnData.breakEvenPointSales[3], tmpGrandTotalnData.pricePerCustomer[3], 0);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(tmpPerformance, tmpMonthlyBudget),
            );

            tmpPerformance = getDivision(tmpSameDayData.breakEvenPointSales[0], tmpSameDayData.pricePerCustomer[0], 0);
            tmpBudget = getDivision(tmpSameDayData.breakEvenPointSales[1], tmpSameDayData.pricePerCustomer[1], 0);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

            // 人件費率>合計
          case 'laborCostRateTotal':
            tmpPerformance = getRatioLabel(tmpGrandTotalnData.laborCostsTotal[0], tmpGrandTotalnData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpGrandTotalnData.laborCostsTotal[1], tmpGrandTotalnData.amountOfSalesTotal[1]);
            tmpMonthlyBudget = getRatioLabel(tmpGrandTotalnData.laborCostsTotal[3], tmpGrandTotalnData.amountOfSalesTotal[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpPerformance = getRatioLabel(tmpSameDayData.laborCostsTotal[0], tmpSameDayData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpSameDayData.laborCostsTotal[1], tmpSameDayData.amountOfSalesTotal[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );
            break;

            // 人件費率>社員
          case 'laborCostRateEmployee':
            tmpPerformance = getRatioLabel(tmpGrandTotalnData.laborCostsEmployee[0], tmpGrandTotalnData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpGrandTotalnData.laborCostsEmployee[1], tmpGrandTotalnData.amountOfSalesTotal[1]);
            tmpMonthlyBudget = getRatioLabel(tmpGrandTotalnData.laborCostsEmployee[3], tmpGrandTotalnData.amountOfSalesTotal[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpPerformance = getRatioLabel(tmpSameDayData.laborCostsEmployee[0], tmpSameDayData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpSameDayData.laborCostsEmployee[1], tmpSameDayData.amountOfSalesTotal[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );
            break;

            // 人件費率>バイト
          case 'laborCostRatPartTime':
            tmpPerformance = getRatioLabel(tmpGrandTotalnData.laborCostsParttime[0], tmpGrandTotalnData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpGrandTotalnData.laborCostsParttime[1], tmpGrandTotalnData.amountOfSalesTotal[1]);
            tmpMonthlyBudget = getRatioLabel(tmpGrandTotalnData.laborCostsParttime[3], tmpGrandTotalnData.amountOfSalesTotal[3]);
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
              tmpMonthlyBudget,
              '-',
            );

            tmpPerformance = getRatioLabel(tmpSameDayData.laborCostsParttime[0], tmpSameDayData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpSameDayData.laborCostsParttime[1], tmpSameDayData.amountOfSalesTotal[1]);
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );
            break;

            // 総労働時間>合計
          case 'totalWorkingHoursTotal':
            tmpPerformance = dailyData.accumulated_data.labor_cost.employee_total_time + dailyData.accumulated_data.labor_cost.part_total_time;
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              '-',
              '-',
              '-',
              '-',
            );

            tmpPerformance = dailyData.daily_data.labor_cost.employee_total_time + dailyData.daily_data.labor_cost.part_total_time;
            tmpSameDayData[value].push(
              tmpPerformance,
              '-',
              '-',
            );
            break;

            // 総労働時間>社員
          case 'totalWorkingHoursEmployee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.employee_total_time,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.employee_total_time,
              '-',
              '-',
            );
            break;

            // 総労働時間>バイト
          case 'totalWorkingHoursPartTime':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.part_total_time,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.part_total_time,
              '-',
              '-',
            );
            break;

            // 勤務人数>合計
          case 'numberOfEmployeesTotal':
            tmpGrandTotalnData[value].push(
              (dailyData.accumulated_data.labor_cost.employee_count + dailyData.accumulated_data.labor_cost.part_count),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              (dailyData.daily_data.labor_cost.employee_count + dailyData.daily_data.labor_cost.part_count),
              '-',
              '-',
            );
            break;

            // 勤務人数>社員
          case 'numberOfEmployeesEmployee':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.employee_count,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.employee_count,
              '-',
              '-',
            );
            break;

            // 勤務人数>バイト
          case 'numberOfEmployeesPartTime':
            tmpGrandTotalnData[value].push(
              dailyData.accumulated_data.labor_cost.part_count,
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              dailyData.daily_data.labor_cost.part_count,
              '-',
              '-',
            );
            break;

            // 人時売上高
          case 'whenPeopleAreHigh':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.amountOfSalesTotal[0], tmpGrandTotalnData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.amountOfSalesTotal[0], tmpSameDayData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
            );
            break;

            // 人時生産性
          case 'humanTimeProductivity':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.grossProfitTotal[0], tmpGrandTotalnData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.grossProfitTotal[0], tmpSameDayData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
            );
            break;

            // 人時接客数
          case 'numberOfPickUps':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.numberOfCustomers[0], tmpGrandTotalnData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.numberOfCustomers[0], tmpSameDayData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
            );
            break;

            // 労働生産性
          case 'laborProductivity':
            tmpGrandTotalnData[value].push(
              getDivision(tmpGrandTotalnData.grossProfitTotal[0], tmpGrandTotalnData.numberOfEmployeesTotal[0], 0),
              '-',
              '-',
              '-',
              '-',
            );

            tmpSameDayData[value].push(
              getDivision(tmpSameDayData.grossProfitTotal[0], tmpSameDayData.numberOfEmployeesTotal[0], 0),
              '-',
              '-',
            );
            break;

            // 労働分配率
          case 'laborShare':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[0], tmpGrandTotalnData.grossProfitTotal[0]),
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[1], tmpGrandTotalnData.grossProfitTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.laborCostsTotal[3], tmpGrandTotalnData.grossProfitTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.laborCostsTotal[0], tmpSameDayData.grossProfitTotal[0]),
              getRatioLabel(tmpSameDayData.laborCostsTotal[1], tmpSameDayData.grossProfitTotal[1]),
              '-',
            );
            break;

            // FL>Cost
          case 'FLCost':
            tmpPerformance = tmpGrandTotalnData.standardCostTotal[0] + tmpGrandTotalnData.laborCostsTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.standardCostTotal[0] + tmpSameDayData.laborCostsTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

            // FL>Rate
          case 'FLRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLCost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLCost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLCost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLCost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLCost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

            // FLA>Cost
          case 'FLACost':
            tmpPerformance = tmpGrandTotalnData.standardCostTotal[0] + tmpGrandTotalnData.laborCostsTotal[0] + tmpGrandTotalnData.advertisingExpenseTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1] + tmpGrandTotalnData.advertisingExpenseTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.standardCostTotal[0] + tmpSameDayData.laborCostsTotal[0] + tmpSameDayData.advertisingExpenseTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1] + tmpSameDayData.advertisingExpenseTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

            // FLA>Rate
          case 'FLARate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLACost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLACost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLACost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLACost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLACost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

            // FLAR>Cost
          case 'FLARCost':
            tmpPerformance = tmpGrandTotalnData.standardCostTotal[0] + tmpGrandTotalnData.laborCostsTotal[0] + tmpGrandTotalnData.advertisingExpenseTotal[0] + tmpGrandTotalnData.rentTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1] + tmpGrandTotalnData.advertisingExpenseTotal[1] + tmpGrandTotalnData.rentTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.standardCostTotal[0] + tmpSameDayData.laborCostsTotal[0] + tmpSameDayData.advertisingExpenseTotal[0] + tmpSameDayData.rentTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1] + tmpSameDayData.advertisingExpenseTotal[1] + tmpSameDayData.rentTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

            // FLAR>Rate
          case 'FLARRate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLARCost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLARCost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLARCost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLARCost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLARCost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

            // FLARO>Cost
          case 'FLAROCost':
            tmpPerformance = tmpGrandTotalnData.standardCostTotal[0] + tmpGrandTotalnData.laborCostsTotal[0] + tmpGrandTotalnData.advertisingExpenseTotal[0] + tmpGrandTotalnData.rentTotal[0] + tmpGrandTotalnData.otherExpensesTotal[0];
            tmpBudget = tmpGrandTotalnData.standardCostTotal[1] + tmpGrandTotalnData.laborCostsTotal[1] + tmpGrandTotalnData.advertisingExpenseTotal[1] + tmpGrandTotalnData.rentTotal[1] + tmpGrandTotalnData.otherExpensesTotal[1];
            tmpMonthlyBudget = tmpGrandTotalnData.standardCostTotal[3] + tmpGrandTotalnData.laborCostsTotal[3] + tmpGrandTotalnData.advertisingExpenseTotal[3] + tmpGrandTotalnData.rentTotal[3] + tmpGrandTotalnData.otherExpensesTotal[3];
            tmpGrandTotalnData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
              tmpMonthlyBudget,
              getRatioLabel(getRatioLabel(tmpPerformance, tmpBudget), tmpMonthlyBudget),
            );

            tmpPerformance = tmpSameDayData.standardCostTotal[0] + tmpSameDayData.laborCostsTotal[0] + tmpSameDayData.advertisingExpenseTotal[0] + tmpSameDayData.rentTotal[0] + tmpSameDayData.otherExpensesTotal[0];
            tmpBudget = tmpSameDayData.standardCostTotal[1] + tmpSameDayData.laborCostsTotal[1] + tmpSameDayData.advertisingExpenseTotal[1] + tmpSameDayData.rentTotal[1] + tmpSameDayData.otherExpensesTotal[1];
            tmpSameDayData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );
            break;

            // FLARO>Rate
          case 'FLARORate':
            tmpGrandTotalnData[value].push(
              getRatioLabel(tmpGrandTotalnData.FLAROCost[0], tmpGrandTotalnData.amountOfSalesTotal[0]),
              getRatioLabel(tmpGrandTotalnData.FLAROCost[1], tmpGrandTotalnData.amountOfSalesTotal[1]),
              '-',
              getRatioLabel(tmpGrandTotalnData.FLAROCost[3], tmpGrandTotalnData.amountOfSalesTotal[3]),
              '-',
            );

            tmpSameDayData[value].push(
              getRatioLabel(tmpSameDayData.FLAROCost[0], tmpSameDayData.amountOfSalesTotal[0]),
              getRatioLabel(tmpSameDayData.FLAROCost[1], tmpSameDayData.amountOfSalesTotal[1]),
              '-',
            );
            break;

          default:
            break;
        }
      }
      return true;
    });

    // set
    setGrandTotalnData(tmpGrandTotalnData);
    setSameDayData(tmpSameDayData);
    setDataKeyName(tmpDataKeyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countAdvertising]);

  useEffect(() => {
    // get data
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'percent',
  });

  const getRatioLabel = (numerator: any, denominator: any) => {
    let result = 0;
    if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
      result = 0;
    } else if (isNaN(numerator) || isNaN(denominator)) {
      result = 0;
    } else if (numerator === 0 || denominator === 0) {
      result = 0;
    } else {
      result = numerator / denominator;
    }
    return formatterPercent.format(result);
  };

  const getDivision = (numerator: any, denominator: any, fixed?: any) => {
    let rsNumber = 0;
    let tmp: Array<any> = [];
    if (String(numerator).indexOf('%') !== -1) {
      tmp = String(numerator).split('%');
      numerator = tmp[0] / 100;
    }
    if (String(denominator).indexOf('%') !== -1) {
      tmp = String(denominator).split('%');
      denominator = tmp[0] / 100;
    }
    if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
      rsNumber = 0;
    } else if (isNaN(numerator) || isNaN(denominator)) {
      rsNumber = 0;
    } else if (numerator === 0 || denominator === 0) {
      rsNumber = 0;
    } else {
      rsNumber = numerator / denominator;
    }
    if (typeof fixed === 'undefined') {
      tmp = String(rsNumber).split('.');
      fixed = 0;
      if (tmp[1] && tmp[1].length >= 3) {
        fixed = 3;
      } else if (tmp[1]) {
        fixed = tmp[1].length;
      }
    }
    return Number(parseFloat(String(rsNumber)).toFixed(fixed));
  };

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };


  return {
    targetDay,
    targetOrgCode,
    countAdvertising,
    formatter,
    formatterPercent,
    numberFormater,
    generalData,
    dataKeyName,
    grandTotalnData,
    sameDayData,
    reportByTimeOfDay,
    advertisingData,
    isLoading,
    setIsLoading,
    cashRegisterReport,
  };
};

export const eventScroll = (e: { target: any; }) => {
  const { target } = e;
  const layout_sale_right_01 = document.getElementById('layout_sale_right_01');
  const layout_sale_right_02 = document.getElementById('layout_sale_right_02');
  const layout_sale_02_buttom_right_set_scroll = document.getElementById('layout_sale_02_buttom_right_set_scroll');
  if (layout_sale_right_01 && layout_sale_right_02 && layout_sale_02_buttom_right_set_scroll) {
    layout_sale_right_01.scrollLeft = target.scrollLeft;
    layout_sale_right_02.scrollLeft = target.scrollLeft;
    layout_sale_02_buttom_right_set_scroll.scrollLeft = target.scrollLeft;
  }
};


export default useSalesMonthlyReport;
