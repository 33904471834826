import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import {
  getInfomationOrgLocalStorage,
  getHistoryRecorder,
  handleRecorderById,
  handleConfirmRecorder,
  handleSubmitFaceImage,
} from "api/timeRecorder";
import useToastNotification from "hooks/useToastNotification";
import OrganizationTimeRecorderDomain from "domain/organizationTimeRecorder";
import ConfirmRecorderByBarcodeIdDomain from "domain/timeRecorder/confirmRecorderByBarcodeIdDomain";
import RecorderByBarcodeIdDomain from "domain/timeRecorder/recorderByBarcodeIdDomain";

import StampHistoryListRecorderDomain from "domain/timeRecorder/stampHistoryListRecorderDomain";
import InfoStaffTimeRecorderDomain from "domain/timeRecorder/infoStaffTimeRecorderDomain";
import ConfirmRecorderByFaceDomain from "domain/timeRecorder/confirmRecorderByFaceDomain";

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";

const { errorNotification, successNotification } = useToastNotification();

export const useAttendTimeRecorder = () => {
  // get local storage
  const storageData = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  const { specifyCode, companyCode, stampByBelongOrg } = storageData ? JSON.parse(storageData) : "";
  const [useFaceStamp, setUseFaceStamp] = useState(0);
  const [useBarcodeStamp, setUseBarcodeStamp] = useState(0);
  const historyListRecorderInit = StampHistoryListRecorderDomain.generateInitial();
  const [stampHistoryList, setStampHistoryList] = useState<Array<StampHistoryListRecorderDomain>>([
    historyListRecorderInit,
  ]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [shopCode, setShopCode] = useState("");

  const initOrgTimeRecorder = OrganizationTimeRecorderDomain.generateInitial();
  const [orgTimeRecorder, setOrgTimeRecorder] = useState<OrganizationTimeRecorderDomain>(initOrgTimeRecorder);

  const [inputBarcodeId, setInputBarcodeId] = useState("");
  // const [dateStr, setDateStr] = useState('');
  // const [timeStr, setTimeStr] = useState('');
  const [dateFormat] = useState("");
  const [timeFormat] = useState("");
  const [isPhoto, setIsPhoto] = useState(false);

  const [staffName, setStaffName] = useState("");
  const [staffCode, setStaffCode] = useState("");
  const [lastStampType, setLastStampType] = useState();
  const [businessCnt, setBusinessCnt] = useState(0);
  const [businessList, setBusinessList] = useState();
  const [stampMode, setStampMode] = useState(0);

  const [stampOrgCode, setStampOrgCode] = useState(sessionStorage.getItem("login.shopCode") || "");
  const [stampOrgName, setStampOrgName] = useState(sessionStorage.getItem("login.shopName") || "");
  const [loginOrgCode, setLoginOrgCode] = useState(sessionStorage.getItem("login.shopCode") || "");
  const [loginOrgName, setLoginOrgName] = useState(sessionStorage.getItem("login.shopName") || "");
  const [barcodeId, setBarcodeId] = useState("");

  const [isSubmitting, setIsSubmitting] = useState();

  // const timeCount = 0;
  let serverTime: any;
  const [serverTimeState] = useState(new Date());

  const fetchData = useCallback(async () => {
    try {
      getInfomationOrgLocalStorage(companyCode, specifyCode).then((response: any) => {
        setOrgTimeRecorder(response);
        setUseFaceStamp(response.useFaceStamp);
        setUseBarcodeStamp(response.useBarcodeStamp);
        sessionStorage.setItem("login.shopCode", response.shopCode);
        setShopCode(response.shopCode);
        sessionStorage.setItem("login.shopName", response.shopName);
        setShopCode(response.shopCode);
        setStampOrgCode(response.shopCode);
        setStampOrgName(response.shopName);
        setLoginOrgCode(response.shopCode);
        setLoginOrgName(response.shopName);
        const loginUser = {
          companyCode: response.companyCode,
          useSales: response.useSales,
          useAttend: response.useAttend,
          useAssistSystem: response.useAssistSystem,
          useFaceStamp: response.useFaceStamp,
          useBarcodeStamp: response.useBarcodeStamp,
          useInsideLoginFaceRecorderStamp: response.useInsideLoginFaceRecorderStamp,
          useInsideLoginBarcodeStamp: response.useInsideLoginBarcodeStamp,
        };
        sessionStorage.setItem("loginUser", JSON.stringify(loginUser));

        try {
          getHistoryRecorder(companyCode, stampByBelongOrg ? "" : response.shopCode).then((responseHistory: any) => {
            setStampHistoryList(responseHistory);
          });
        } catch (error) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
    } catch (error) {
      errorNotification("サーバー側でエラーが発生しました。");
    }
    setLoading(false);
  }, [companyCode, specifyCode, stampByBelongOrg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // // const fetchCurrentTime = useCallback(async () => {
  // //   let timeFlg = false;
  // //   // setTimeFormat('サーバ時間取得中・・・');
  // //   if (dateStr == null || timeStr == null || timeCount === 0 || timeCount === 60
  // //     || Date.now() > (serverTime.current + (60 * 1000))) {
  // //     try {
  // //       await getCurrentTimeSystem(
  // //       ).then((response: any) => {
  // //         setDateStr(response[0]);
  // //         setTimeStr(response[1]);
  // //         serverTime = response[2];
  // //         setServerTimeState(response[2]);
  // //         timeFlg = true;
  // //       });
  // //     } catch (error) {
  // //       setDateStr('');
  // //       setTimeStr('');
  // //       serverTime = '';
  // //     }
  // //     timeCount = 0;
  // //   }

  // //   if (timeFlg === false) {
  // //     let now = null;
  // //     // サーバーから取得した日時がある場合は1秒up
  // //     if (serverTime) {
  // //       now = new Date(serverTime);
  // //       now.setSeconds(now.getSeconds() + 1);
  // //       serverTime = now;
  // //       setServerTimeState(now);

  // //       const dayOfTheWeek = ['日', '月', '火', '水', '木', '金', '土'];
  // //       let dateFormatTemplate = 'YYYY年MM月DD日（WW曜日）';
  // //       let timeFormatTemplate = 'hh：mm：ss';

  // //       // 日時を表示用フォーマットに変換して画面に反映
  // //       dateFormatTemplate = dateFormatTemplate.replace(/YYYY/g, String(now.getFullYear()));
  // //       dateFormatTemplate = dateFormatTemplate.replace(/MM/g, (`0${now.getMonth() + 1}`).slice(-2));
  // //       dateFormatTemplate = dateFormatTemplate.replace(/DD/g, (`0${now.getDate()}`).slice(-2));
  // //       dateFormatTemplate = dateFormatTemplate.replace(/WW/g, dayOfTheWeek[now.getDay()]);
  // //       timeFormatTemplate = timeFormatTemplate.replace(/hh/g, (`0${now.getHours()}`).slice(-2));
  // //       timeFormatTemplate = timeFormatTemplate.replace(/mm/g, (`0${now.getMinutes()}`).slice(-2));
  // //       timeFormatTemplate = timeFormatTemplate.replace(/ss/g, (`0${now.getSeconds()}`).slice(-2));
  // //       // htmlの内容を更新
  // //       setDateFormat(dateFormatTemplate);
  // //       setTimeFormat(timeFormatTemplate);
  // //     }
  // //     // if (!now) {
  // //     //   setTimeFormat('サーバ時間取得中・・・');
  // //     // }
  // //   }
  // //   timeCount += 1;

  // //   setTimeout(() => {
  // //     fetchCurrentTime();
  // //   }, 1000);
  // // }, []);

  // useEffect(() => {
  //   fetchCurrentTime();
  // }, [fetchCurrentTime]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (values: ConfirmRecorderByBarcodeIdDomain) => {
    try {
      await handleRecorderById(shopCode, companyCode, values.getRawData()).then(
        (response: InfoStaffTimeRecorderDomain) => {
          setIsConfirm(true);
          const stampStaffData = response.stampStaffList[0];
          setLastStampType(stampStaffData.lastStampType);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setStaffName(stampStaffData.staffName);
          setStaffCode(stampStaffData.staffCode);
          setBarcodeId(stampStaffData.barcodeId);
          setStampOrgCode(stampStaffData.orgCode);
          setStampOrgName(stampStaffData.orgName);
          setLoading(false);
        },
      );
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        errorNotification(error.response.data.message);
        return;
      }
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByBarcodeIdDomain.generateInitial(),
    onSubmit,
  });

  const onSubmitConfirm = async (values: RecorderByBarcodeIdDomain) => {
    try {
      handleConfirmRecorder(shopCode, companyCode, values.getRawData()).then((response: any) => {
        successNotification(response.stampResultInfo.replace("<h4>", "").replace("</h4>", ""));
        setIsSubmitting(false);
      });
    } catch (error) {
      setIsSubmitting(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const onSubmitConfirmFace = async (values: ConfirmRecorderByFaceDomain) => {
    try {
      await handleConfirmRecorder(shopCode, companyCode, values.getRawData()).then((response: any) => {
        successNotification(response.stampResultInfo.replace("<h4>", "").replace("</h4>", ""));
        setIsSubmitting(false);
      });
    } catch (error) {
      setIsSubmitting(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formikConfirm = useFormik({
    initialValues: RecorderByBarcodeIdDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirm(values);
    },
  });

  const formikConfirmFace = useFormik({
    initialValues: ConfirmRecorderByFaceDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirmFace(values);
    },
  });

  const handleSubmitBarcode = useCallback(() => {
    setLoading(true);
    setStampMode(3);
    formik.setFieldValue("stampMode", stampMode);
    formik.setFieldValue("stampByBelongOrg", stampByBelongOrg);
    formik.setFieldValue("orgName", stampOrgName);
    formik.setFieldValue("orgCode", stampOrgCode);
    formik.setFieldValue("loginOrgName", loginOrgName);
    formik.setFieldValue("loginOrgCode", loginOrgCode);
    formik.setFieldValue("staffName", staffName);
    formik.setFieldValue("barcodeId", barcodeId);
    formik.setFieldValue("stampTime", String(serverTimeState));
    formik.submitForm();
  }, [
    barcodeId,
    formik,
    loginOrgCode,
    loginOrgName,
    serverTimeState,
    staffName,
    stampByBelongOrg,
    stampMode,
    stampOrgCode,
    stampOrgName,
  ]);

  // Image
  const [tmpFilePath, setTmpFilePath] = useState("");
  const [stampStaffList, setStampStaffList] = useState();
  const [useRecess, setUseRecess] = useState(true);
  const [faceId, setFaceId] = useState("");
  const [mistake, setMistake] = useState<any>("");
  const [similarity, setSimilarity] = useState("");
  const [photoFullpath, setPhotoFullpath] = useState();
  const [currentStaffIdx, setCurrentStaffIdx] = useState(0);

  const handleSubmitImage = async (file: any) => {
    setLoading(true);
    let formDataImage: any = {};
    formDataImage = {
      orgCode: stampOrgCode,
      orgName: stampOrgName,
      loginOrgCode,
      loginOrgName,
      tmpFilePath,
      staffName,
      faceId,
      mistake,
      stampMode: 1,
      stampByBelongOrg,
      similarity,
      stampTime: String(new Date()),
      photo: file,
    };

    try {
      await handleSubmitFaceImage(shopCode, companyCode, formDataImage).then((response: any) => {
        if (!response.errors) {
          setTmpFilePath(response.tmpFilePastampStaffListth);
          setStampStaffList(response.stampStaffList);
          const stampStaffData = response.stampStaffList[0];
          // eslint-disable-next-line no-shadow

          setLastStampType(stampStaffData.lastStampType);
          setStaffName(stampStaffData.staffName);
          setStaffCode(stampStaffData.staffCode);
          setStampOrgCode(stampStaffData.orgCode);
          setStampOrgName(stampStaffData.orgName);
          setFaceId(stampStaffData.faceId);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setUseRecess(stampStaffData.useRecess);
          setCurrentStaffIdx(currentStaffIdx);
          setSimilarity(stampStaffData.similarity);
          setMistake(response.mistake);
          setPhotoFullpath(stampStaffData.photoFullpath);
          setIsConfirm(true);
          setIsPhoto(true);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        const err = error.response.data.message.replace(/<br>/gi, "\n");
        errorNotification(err);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const handleMistake = () => {
    const nextStaffIdx = currentStaffIdx + 1;
    if (nextStaffIdx >= stampStaffList.length) {
      errorNotification(
        "類似するスタッフが存在しませんでした。<br>スタッフ設定画面にて顔写真を更新してから打刻を行ってください。",
      );
      setIsPhoto(false);
      setIsConfirm(false);
      setPhotoFullpath("");
    } else {
      const stampData = stampStaffList[nextStaffIdx];
      setLastStampType(stampData.lastStampType);
      setUseRecess(stampData.useRecess);
      setStaffName(stampData.staffName);
      setStampOrgCode(stampData.orgCode);
      setStampOrgName(stampData.orgName);
      setFaceId(stampData.faceId);
      setPhotoFullpath(stampData.photoFullpath);
      setBusinessCnt(stampData.businessList.length);
      setBusinessList(stampData.businessList);
      setCurrentStaffIdx(nextStaffIdx);
      setMistake(true);
      setSimilarity(stampData.similarity);
    }
  };
  return {
    inputBarcodeId,
    setInputBarcodeId,
    specifyCode,
    companyCode,
    orgTimeRecorder,
    dateFormat,
    timeFormat,
    useFaceStamp,
    useBarcodeStamp,
    formik,
    stampByBelongOrg,
    stampOrgName,
    loginOrgCode,
    loginOrgName,
    barcodeId,
    serverTime,
    stampOrgCode,
    stampHistoryList,
    serverTimeState,
    isConfirm,
    staffName,
    staffCode,
    businessCnt,
    lastStampType,
    setLastStampType,
    businessList,
    stampMode,
    setStampMode,
    handleSubmitBarcode,
    formikConfirm,
    isLoading,
    setLoading,
    isSubmitting,
    setIsSubmitting,
    handleSubmitImage,
    photoFullpath,
    useRecess,
    formikConfirmFace,
    tmpFilePath,
    faceId,
    similarity,
    mistake,
    isPhoto,
    setIsPhoto,
    handleMistake,
    setIsConfirm,
    getHistoryRecorder,
    setStampHistoryList,
    shopCode,
    errorNotification,
    setPhotoFullpath,
  };
};
export default useAttendTimeRecorder;
