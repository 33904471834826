import ApiClient from './ApiClient';

export const getApprovalFlowList = async (belongOrgCode?: any, typeApplication?: any): Promise<Array<any>> => {
    // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const params = {
        belongOrgCode,
        typeApplication
    };
    const response = await ApiClient.get(`/v1/approval/aprrovelFlowList/${companyCode}`, params);
    return response.data;
};


export const aprrovelFlowConfiguration = async (companyCode: string, approvalFlowListData?: any) => {
    const params = {};
    const response = await ApiClient.post(`/v1/approval/aprrovelFlowConfiguration/${companyCode}`, params, approvalFlowListData);
    return response.data; 
};


export const applyingAprrovelFlowList = async (belongOrgCode?: any) => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const params = {
        belongOrgCode
    };
    const response = await ApiClient.get(`/v1/approval/applyingAprrovelFlowList/${companyCode}`, params);
    return response.data; 
};


export const listByOrgCode = async (orgCode?: any) => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const params = {
        orgCode
    };
    const response = await ApiClient.get(`/v1/executive/listByOrgCode/${companyCode}`, params);
    return response.data; 
};


export default getApprovalFlowList;

