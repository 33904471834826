import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendanceLayoutList from 'components/organismos/master/attend/AttendanceForm/AttendanceLayoutList';

const AttendanceMasterPage: React.FC = () => (
  <SidebarTemplate>
    <AttendanceLayoutList />
  </SidebarTemplate>
);

export default AttendanceMasterPage;
