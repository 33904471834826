import React, { useEffect } from "react";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import ApprovedTransferDomain from "../domain/approvedTransferDomain";
import ApprovedStampDomain from "../domain/approvedStampDomain";
import ApprovedHolidayDomain from "../domain/approvedHolidayDomain";
import ApprovedOvertimeDomain from "../domain/approvedOvertimeDomain";
import ApprovedHolidayWorkDomain from "../domain/approvedHolidayWorkDomain";
import Approved36AgreementDomain from "../domain/approved36AgreementDomain";
import ApprovedWorkScheduleChangeDomain from "../domain/approvedWorkScheduleChange";
import OvertimeAccordion from "./OvertimeAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";
import { OptionType } from 'components/atoms/Select';

const ApprovedTab: React.FC<{
  stampList: ApprovedStampDomain[];
  holidayList: ApprovedHolidayDomain[];
  overtimeList: ApprovedOvertimeDomain[];
  holidayWorkList: ApprovedHolidayWorkDomain[];
  transferList: ApprovedTransferDomain[];
  agreement36List: Approved36AgreementDomain[];
  workScheduleChangeList: ApprovedWorkScheduleChangeDomain[];
  reasonOptionList: OptionType[];
  reasonId:any;
  setReasonId:any;
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleChangeList,
  reasonOptionList,
  reasonId,
  setReasonId,
}) => {
  
  return (
    <div>
      <StampAccordion 
        list={stampList} 
        reasonOptionList={reasonOptionList}
        reasonId={reasonId}
        setReasonId={setReasonId}
      />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />

      <Agreement36Accordion list={agreement36List} />
      
      <WorkScheduleChangeApplicationAccordion list={workScheduleChangeList} />

    </div>
  );
};

export default ApprovedTab;
