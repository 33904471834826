/** @jsx jsx */
import React, { useState, createContext } from "react";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import moment from "moment";
import useLoginUser from "hooks/useLoginUser";
import Appbar from "components/organismos/Appbar";
import FormLabel from "components/atoms/Form/FormLabel";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from "components/atoms/TabControl";
import BarcodeRecorder from "./BarcodeRecorder";
import ICCardRecorder from "./ICCardRecorder";
import ICCardRecorderEmptySpecifyCode from "./ICCardRecorder/ICCardRecorderEmptySpecifyCode";
import FaceRecorder from "./FaceRecorder";
import DataTable from "components/organismos/DataTable/DataTable";
import useRecorder from "./hook";
import { Column } from "react-table";
import { utilityColor } from "components/styles";
import ReactTooltip from "react-tooltip";
import BodyText from "components/atoms/BodyText";
import ImageModel from "../ImageModel";

import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import TextTooltip from "components/molecules/TextTooltip";
import BarcodeRecorderEmptySpecifyCode from "./BarcodeRecorder/BarcodeRecorderEmptySpecifyCode";
import FaceRecorderEmptySpecifyCode from "./FaceRecorder/FaceRecorderEmptySpecifyCode";
import { SelectType } from "./hook";
import StaffSettingPanel from "components/organismos/Appbar/StaffSettingPanel";
import useComponentVisible from "hooks/useComponentVisible";
import { MobileSidebar } from "components/templates/MobileSidebar";
import useIsMobile from "hooks/useIsMobile";
import FormTitle from "components/atoms/Form/FormTitle";

const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  backHistory: css(
    {
      position: "relative",
      top: "24px",
      left: "-65px",
      display: "inline-block",
    },
    css`
      &:hover svg {
        opacity: 0.7;
      }
    `,
  ),
  container: css({
    maxWidth: "1140px",
    margin: "auto",
    width: "calc(100% - 30px)",
  }),
  shopName: css({
    paddingTop: "12px",
    marginRight: isIpad ? "20px" : "150px",
    fontSize: "18px",
    color: "black",
  }),
  titleForm: css({
    justifyContent: "center",
    color: "#ff6347",
    fontSize: isIpad ? "28px" : "45px",
    fontWeight: "bold",
    marginTop: "-10px",
  }),
  titleLabel: css({
    color: "#333333",
    fontSize: "19px",
    justifyContent: "center",
    fontWeight: "bold",
    width: "60%",
    borderBottom: "1px solid #e6e6e6",
    margin: "auto",
    paddingBottom: "15px",
  }),
};

const tabControl = css`
  display: flex;
  justify-content: space-between;
  margin-left: ${isIpad ? "0" : "100px"};
  div {
    width: 290px;
  }
  label {
    border: 0;
  }
  label:hover {
    background: #e7f1fd;
    border-radius: 10px;
    border: 0;
  }
`;
const dataTable = css`
  margin-top: 24px;
  padding-bottom: 50px;
  table tbody td,
  table thead th {
    text-align: left;
  }
  table thead th {
    font-weight: bold;
  }
  table tr td,
  table tr th {
    &:first-of-type {
      border-left: 0 !important;
    }
    &:last-of-type {
      border-right: 0 !important;
    }
  }
`;

const cellImage = css`
  div {
    height: 55px;
    width: 55px;
    margin-right: 8px;
  }
  img {
    border-radius: 50%;
  }
`;

//Context global variable
type contextType = {
  companyCode: string;
  shopCode: string;
  stampByBelongOrg: number;
};

export const TimeRecorderContext = createContext<contextType>({} as contextType);

const RecorderV2: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { staffName } = useLoginUser();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean | null>(null);

  const columns: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: () => "氏名",
        accessor: "staffName",
        id: "staffName",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          const [imageOpen, setImageOpen] = useState(false);

          const { staffCode, staffName, stampTime, stampName } = cell.row.original;

          const imageURL = cell.row.original.photoFullpath;

          return (
            <FlexBox customStyle={css({ display: `${isIpad ? "block" : ""}` })}>
              <div>
                {imageURL && (
                  <React.Fragment>
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        overflowWrap: "anywhere",
                        justifyContent: `${isIpad ? "center" : ""}`,
                      }}
                    >
                      <img
                        src={imageURL}
                        alt={imageURL}
                        width={55}
                        height={55}
                        style={{
                          cursor: "pointer",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                        onClick={() => setImageOpen(true)}
                      />
                      <ImageModel
                        open={imageOpen}
                        closeHandler={() => {
                          setImageOpen(false);
                        }}
                        imageURL={imageURL}
                        descript={
                          staffCode +
                          "/" +
                          staffName +
                          "/" +
                          stampName +
                          "/" +
                          moment(stampTime).format("YYYY/MM/DD HH:mm")
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
              <TextTooltip
                text={cell.row.original.staffName}
                customCss={css({
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: `${imageURL ? "70px" : "130px"}`,
                  whiteSpace: "nowrap",
                  display: "block",
                  margin: "0px auto",
                })}
              />
            </FlexBox>
          );
        },
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "スタッフコード",
        accessor: "staffCode",
      },
      {
        Header: "打刻方法",
        accessor: "stampModeName",
        id: "stampModeName",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          return (
            <React.Fragment>
              <div style={{ width: "100%" }}>{cell.row.original.stampModeName}</div>
              {cell.row.original.errorMessage !== null && cell.row.original.errorMessage !== "" && (
                <BodyText customStyle={css({ fontSize: "13px", color: "#333333" })}>
                  {cell.row.original.cardId}
                </BodyText>
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: () => "打刻種別",
        accessor: "errorMessage",
        id: "errorMessage",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          const { stampName, isRemote } = cell.row.original;
          if (cell.row.original.errorMessage === null || cell.row.original.errorMessage === "") {
            return (
              <div>
                {stampName === "出勤"
                  ? isRemote
                    ? stampName + " (テレワーク)"
                    : stampName + " (オフィス)"
                  : stampName}
              </div>
            );
          }
          if (cell.row.original.errorMessage !== null && cell.row.original.errorMessage !== "") {
            return (
              <span>
                <div css={css({ color: utilityColor.note })} data-tip={cell.row.original.errorMessage}>
                  {stampName}
                </div>
                <ReactTooltip html={true} />
              </span>
            );
          }
        },
      },
      {
        Header: () => "打刻時刻",
        accessor: "stampTime",
        id: "stampTime",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) =>
          cell.row.original.stampTime ? moment(cell.row.original.stampTime).format("YYYY/MM/DD HH:mm:ss") : "",
      },
    ],
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    stampHistoryList,
    currentDay,
    timeNow,
    second,
    stampOrgCode,
    stampOrgName,
    loginOrgCode,
    loginOrgName,
    setStampOrgCode,
    setStampOrgName,
    setLoginOrgCode,
    setLoginOrgName,
    resultCurrentTime,
    useFaceStamp,
    useBarcodeStamp,
    useICCardStamp,
    disabledRecorder,
    fetchHistoryData,
    companyCode,
    specifyCode,
    setItems,
    items,
    setSelectType,
    selectType,
  } = useRecorder(setIsLoading);

  const dataTimes = {
    timeNow,
    currentDay,
    second,
    resultCurrentTime,
  };
  const dataSettings = {
    stampOrgCode,
    setStampOrgCode,
    stampOrgName,
    setStampOrgName,
    loginOrgCode,
    setLoginOrgCode,
    loginOrgName,
    setLoginOrgName,
    useFaceStamp,
    useBarcodeStamp,
    useICCardStamp,
  };
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <React.Fragment>
      <TimeRecorderContext.Provider
        value={{
          companyCode: companyCode,
          shopCode: stampOrgCode,
          stampByBelongOrg: 0,
        }}
      >
        <BlockUI blocking={isLoading}>
          <Appbar
            staffName={staffName}
            title="打刻"
            changeVisibleEvent={(visible: boolean) => {
              if (isMobile) {
                setShowMobileMenu(visible);
              } else {
                setIsComponentVisible(visible);
              }
            }}
          />
          <div css={styles.container}>
            <div css={styles.backHistory} onClick={() => history.push("/login")}>
              <Icon type="back" color="#666" />
            </div>
            <FormTitle title="打刻" bold customStyle={css(isMobile ? { padding: "8px 0 0 0", margin: 0 } : {})} />
            {Number(useBarcodeStamp) !== 0 || Number(useFaceStamp) !== 0 || Number(useICCardStamp) !== 0 ? (
              <FormContents>
                <div css={tabControl}>
                  {items && (
                    <TabControl
                      styleActive={{
                        backgroundColor: "#E7F1FD",
                        borderRadius: "10px",
                      }}
                      items={items}
                      setValue={setSelectType as (arg: string) => void}
                      currentValue={selectType}
                    ></TabControl>
                  )}
                </div>
                <FlexBox customStyle={styles.titleForm}>登園・退園登録</FlexBox>
                {selectType === SelectType.icCard && (
                  <React.Fragment>
                    {specifyCode === "" ? (
                      <ICCardRecorderEmptySpecifyCode
                        dataTimes={dataTimes}
                        setIsLoading={setIsLoading}
                        fetchHistoryData={fetchHistoryData}
                      />
                    ) : (
                      <React.Fragment>
                        {Boolean(useICCardStamp) && (
                          <ICCardRecorder
                            dataTimes={dataTimes}
                            dataSettings={dataSettings}
                            setIsLoading={setIsLoading}
                            fetchHistoryData={fetchHistoryData}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {selectType === SelectType.id && (
                  <React.Fragment>
                    {specifyCode === "" ? (
                      <BarcodeRecorderEmptySpecifyCode
                        dataTimes={dataTimes}
                        setIsLoading={setIsLoading}
                        fetchHistoryData={fetchHistoryData}
                      />
                    ) : (
                      <React.Fragment>
                        {Boolean(useBarcodeStamp) && (
                          <BarcodeRecorder
                            dataTimes={dataTimes}
                            dataSettings={dataSettings}
                            setIsLoading={setIsLoading}
                            disabledRecorder={disabledRecorder}
                            fetchHistoryData={fetchHistoryData}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {selectType === SelectType.face && (
                  <React.Fragment>
                    {specifyCode === "" ? (
                      <FaceRecorderEmptySpecifyCode
                        dataTimes={dataTimes}
                        setIsLoading={setIsLoading}
                        fetchHistoryData={fetchHistoryData}
                      />
                    ) : (
                      <React.Fragment>
                        {Boolean(useFaceStamp) && (
                          <FaceRecorder
                            dataTimes={dataTimes}
                            dataSettings={dataSettings}
                            setIsLoading={setIsLoading}
                            disabledRecorder={disabledRecorder}
                            fetchHistoryData={fetchHistoryData}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </FormContents>
            ) : (
              ""
            )}
            <div css={dataTable}>
              <FormContents customStyle={css({ borderRadius: "3px" })}>
                <div css={css({ marginLeft: "28px" })}>
                  <FormLabel label="打刻履歴" customStyle={css({ fontWeight: "bold" })} />
                </div>
                <DataTable
                  columns={columns}
                  data={stampHistoryList}
                  isGlobalFilter={true}
                  globalSearchStyle={css({
                    marginLeft: "28px",
                    borderRadius: "3px",
                  })}
                />
              </FormContents>
            </div>
          </div>
        </BlockUI>
        <div ref={ref} css={css({ position: "absolute", top: 0, right: "2rem" })}>
          {isComponentVisible && <StaffSettingPanel staffName={staffName} />}
        </div>
      </TimeRecorderContext.Provider>
      {isMobile && <MobileSidebar isMenuOpen={showMobileMenu} setMenuOpen={(open) => setShowMobileMenu(open)} />}
    </React.Fragment>
  );
};

export default RecorderV2;
