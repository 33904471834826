import { useStaffsForOrgCodeOptions } from "hooks/useStaffsOptions";
import { useState, useEffect } from "react";
import getPaidHolidayAlert from "api/paidHolidayAlert";
import useToastNotification from "hooks/useToastNotification";

const usePaidHolidayAlert = () => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const siteId = sessionStorage.getItem('loginUser.site') || '';

    const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
    const [staffCode, setStaffCode] = useState(sessionStorage.getItem('loginUser.staffCode') || '');
    const staffsForOrgCodeOptions = useStaffsForOrgCodeOptions(orgCode);
    const [listPaidHolidayAlert, setPaidHolidayAlert] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { errorNotification } = useToastNotification();
 
    useEffect(() => {
        setLoading(true);
        getPaidHolidayAlert(companyCode, orgCode, staffCode, siteId).then((response) => {
            setPaidHolidayAlert(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.message) {
                const err = error.response.data.message.replace(/<br>/gi, '\n');
                errorNotification(err);
            } else {
                errorNotification('サーバー側でエラーが発生しました。');
                throw error;
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyCode, orgCode, staffCode, siteId]);

    return {
        orgCode,
        staffCode,
        setOrgCode,
        staffsForOrgCodeOptions,
        setStaffCode,
        listPaidHolidayAlert,
        isLoading
    }
}
export default usePaidHolidayAlert;
