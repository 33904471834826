import { useState, useMemo, useCallback } from "react";

import OrganizationDomain from "domain/organization";
import * as api from "api/organization";
import useToastNotification from "hooks/useToastNotification";

/**
 * 組織削除処理
 */
export const useOrganizationDelete = () => {
  const [deleteTargetOrganization, setDeleteTargetOrganization] = useState<OrganizationDomain | null>(null);
  const { successNotification, errorNotification } = useToastNotification();

  // 削除モーダルの開閉状態
  const isOpenDeleteModal = useMemo(() => !!deleteTargetOrganization, [deleteTargetOrganization]);

  // 削除モーダルを閉じる
  const closeDeleteModal = useCallback(() => setDeleteTargetOrganization(null), []);

  // 削除実行
  const onSubmitDeleteOrganization = useCallback(() => {
    if (!deleteTargetOrganization) {
      return;
    }
    api
      .deleteOrganization(deleteTargetOrganization.orgCode)
      .then(() => {
        setDeleteTargetOrganization(null);
        successNotification("削除しました。");
        window.location.href = "/organizations";
        // 削除後にデータを再取得
      })
      .catch((error) => {
        setDeleteTargetOrganization(null);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [deleteTargetOrganization, errorNotification, successNotification]);

  return {
    deleteTargetOrganization,
    setDeleteTargetOrganization,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteOrganization,
  };
};

export const useDownload = (noManager: boolean, orgStatus: string, unsetTargetDate: Date) => {
  const downloadOrganization = useCallback(() => {
    api.downloadOrganizationCsvV1(Number(orgStatus), new Date());
  }, [orgStatus, unsetTargetDate]);
  const downloadManagerCsv = useCallback(() => {
    api.downloadManagerCsv(noManager, Number(orgStatus), new Date(), noManager ? unsetTargetDate : undefined);
  }, [noManager, orgStatus, unsetTargetDate]);

  const downloadSpecialSalaryCsv = useCallback(() => {
    api.downloadSpecialSalaryCsv(Number(orgStatus), new Date());
  }, [orgStatus, unsetTargetDate]);

  return {
    downloadOrganization,
    downloadManagerCsv,
    downloadSpecialSalaryCsv,
  };
};

export default {};
