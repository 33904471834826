/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const style = (width:string) => css({
  padding: '10px 12px',
  maxHeight: 'calc(100vh - 40vh)',
  overflow: 'auto',
  width: width,
  margin: 'auto',
  minWidth: '300px',
});

const Body: React.FC<{ width?: string }> = ({ children, width = 'max-content' }) => (
  <div css={style(width)}>
    {children}
  </div>
);

export default Body;
