import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import React from 'react';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useAdvertisingAddForm } from './hooks';

const AdvertisingMasterAddForm = () => {
  const { advertisingMediaMstCode } = useParams();
  const {
    confirmModalOpen, formik, history, closeConfirmModal,
    toastMessage, toastModalOpen, closeToastModel,
    confirmModalContent, confirmModalResendOpen, closeConfirmResendModal, submitConfirmResendModal,
  } = useAdvertisingAddForm(advertisingMediaMstCode);


  return (
    <FormContents>
      <p>
        {advertisingMediaMstCode ? '' : '広告媒体を登録します。コード、名称、並び順を入力してください。'}
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              name="advertisingMediaMstCode"
              label="コード"
              required={true}
              value={formik.values.advertisingMediaMstCode}
              onChange={formik.handleChange}
              errorMsg={formik.errors.advertisingMediaMstCode}
              disable={!!advertisingMediaMstCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="advertisingMediaMstName"
              label="名称"
              required={true}
              value={formik.values.advertisingMediaMstName}
              onChange={formik.handleChange}
              errorMsg={formik.errors.advertisingMediaMstName}
            />
          </FormField>

          <FormField>
            <VerticalLabelFormLayout
              label={(
                <FormLabel
                  label="並び順"
                  required={true}
                />
                        )}
              input={(
                <HourInput
                  name="dispOrder"
                  value={formik.values.dispOrder}
                  onChange={formik.handleChange}
                  min={0}
                />
                        )}
              errorMsg={formik.errors.dispOrder}
            />

          </FormField>
        </div>
        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            <Button
              text={advertisingMediaMstCode ? '更新' : '広告媒体を登録'}
              onClick={formik.handleSubmit}
            />
          </div>
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.goBack();
            }}
            ghost={true}
          />
        </FormSubmitArea>

      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={advertisingMediaMstCode ? ActionType.UPDATE : ActionType.CREATE}
      />

      <ConfirmModal
        title="確認"
        content={confirmModalContent}
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={submitConfirmResendModal}
        items={[]}
      />
      <ToastModal
        open={toastModalOpen}
        closeHandler={closeToastModel}
        title={toastMessage}
      />
    </FormContents>

  );
};

export default AdvertisingMasterAddForm;
