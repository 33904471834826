/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useEffect, useReducer, createContext } from "react";
import useToastNotification from "hooks/useToastNotification";
import { getDetailRole, 
  DetailRole, 
  getDetailFunctionRole,
  postMasterEditRole
} from 'api/masterRole';
import { useHistory, useParams } from "react-router-dom";
import { reducer, initialState } from './reducer'
import { types } from './actions'
import { DetailRoleFunction, RoleFunctionData } from './type';

const { errorNotification, successNotification } = useToastNotification();

const defaultDetailRole = {
  roleId: '',
  functionCode: '005',		
  available: false,		
  editable: 0,		
  importFlag: 0,
  downloadFlag: 0,		
  accessRange: 1,		
  linkAvailable1: false,
  linkAvailable2: false,
  linkAvailable3: false,
  linkAvailable4: false,
  linkAvailable5: false,
  usabilityGeneralItem1: null,
  usabilityGeneralItem2: null,
  usabilityGeneralItem3: null,
  usabilityGeneralItem4: null,
  usabilityGeneralItem5: null,
  usabilityGeneralItem6: null,
  usabilityGeneralItem7: null,
  usabilityGeneralItem8: null,
  usabilityGeneralItem9: null,
  usabilityGeneralItem10: null,
}

export const useMasterRoleEditForm = () => {
  // redux
  const [state, dispatch] = useReducer(reducer, initialState);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const { action, roleName, roleCode, roleId } = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [detailRole, setDetailRole] = useState<DetailRole>(defaultDetailRole);

  const getPostData = (functionList: RoleFunctionData[]) => {
    let tmpList = JSON.parse(JSON.stringify(functionList));
    tmpList = tmpList.map((f: RoleFunctionData) => {
      return {
        accessRange: Number(f.accessRange),
        accessUnderOrganization: Boolean(f.accessUnderOrganization),
        available: Boolean(f.available),
        downloadFlag: Number(f.downloadFlag),
        editable: Number(f.editable),
        functionCode: f.functionCode,
        functionMasterId: f.functionMasterId,
        functionName: f.functionName,
        importFlag: Number(f.importFlag),
        isDefault: Boolean(f.isDefault),
        menuGroup: Number(f.menuGroup),
        roleDetailId: f.roleDetailId,
        usabilityGeneralItem1: Number(f.usabilityGeneralItem1),
        usabilityGeneralItem2: Number(f.usabilityGeneralItem2),
        usabilityGeneralItem3: Number(f.usabilityGeneralItem3),
        usabilityGeneralItem4: Number(f.usabilityGeneralItem4),
        usabilityGeneralItem5: Number(f.usabilityGeneralItem5),
        usabilityGeneralItem6: Number(f.usabilityGeneralItem6),
        usabilityGeneralItem7: Number(f.usabilityGeneralItem7),
        usabilityGeneralItem8: Number(f.usabilityGeneralItem8),
        usabilityGeneralItem9: Number(f.usabilityGeneralItem9),
        usabilityGeneralItem10: Number(f.usabilityGeneralItem10),
        usabilityGeneralItem1Name: f.usabilityGeneralItem1Name,
        usabilityGeneralItem2Name: f.usabilityGeneralItem2Name,
        usabilityGeneralItem3Name: f.usabilityGeneralItem3Name,
        usabilityGeneralItem4Name: f.usabilityGeneralItem4Name,
        usabilityGeneralItem5Name: f.usabilityGeneralItem5Name,
        usabilityGeneralItem6Name: f.usabilityGeneralItem6Name,
        usabilityGeneralItem7Name: f.usabilityGeneralItem7Name,
        usabilityGeneralItem8Name: f.usabilityGeneralItem8Name,
        usabilityGeneralItem9Name: f.usabilityGeneralItem9Name,
        usabilityGeneralItem10Name: f.usabilityGeneralItem10Name,
        useAccessRange: Number(f.useAccessRange),
        useDownload: Number(f.useDownload),
        useEditable: Number(f.useEditable),
        useImport: Number(f.useImport),
      }
    })
    return tmpList;
  }

  const handleSubmit = async () => {
    setIsOpenModal(false);
    setIsLoading(true);
    let params = {
      id: state.data.id,
      roleCode: state.data.roleCode,
      roleName: state.data.roleName,
      accessLevel: state.data.accessLevel,
      administrator: state.data.administrator,
      roleDetailList: getPostData(state.data.roleFunctinList),
    }

    postMasterEditRole(params).then((response: any) => {
      successNotification("更新しました。");
      setIsLoading(false);
      history.push('/masterRoleSetting')
    })
    .catch((error: any) => {
      setIsLoading(false);
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);          
        } else if (error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification(error.response.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    })
  };

  const fetchFunctionRole = useCallback(async () => {
    setIsLoading(true);
    getDetailRole('005').then((response: DetailRole) => {
      if(response){
        setDetailRole(response);
      }
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, []);

  // FETCH DATA EDIT
  const fetchDetailRole = useCallback(async () => {
    setIsLoading(true);
    getDetailFunctionRole(roleId || "").then((response: DetailRoleFunction) => {
      dispatch({ type: types.SET_DATA, payload: response });
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, []);
  
  useEffect(() => {
    if(roleId){
      fetchDetailRole();
    }
  }, [roleId])
  useEffect(() => {
    fetchFunctionRole();
  }, [])
  // FETCH DATA EDIT
  


  return {
    isLoading,
    setIsLoading,
    roleName,
    roleCode,
    state,
    dispatch,
    detailRole,
    isOpenModal,
    setIsOpenModal,
    handleSubmit,
    action
  };
};
export default useMasterRoleEditForm;
