import ApiClient from "./ApiClient";

export interface AchievementConfirm {
  orgName: string;
  orgCode: string;
  closingDate: string;
  dateFrom: string;
  dateTo: string;
  confirmStatus: string;
  unConfirmFlag: string;
  ownCnt: string;
}

export interface IProcessingDownload {
  companyCode?: string;
  targetDateFrom?: string;
  targetDateTo?: string;
  orgCode?: string;
  viewPeriod?: string;
  closingDate?: string;
  employmentId?: string;
  outputLayoutId?: string;
  pageId: number;
  createUser: string;
  attendFlag?: number;
  flagDownloadAll?: number;
  targetDate?: string;
  staffCode?: string;
}

export const getList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  const paramsObj = {
    ...params,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v2/achievementConfirm/${companyCode}`, {}, paramsObj);
  return response.data;
};

export const getAchivementHistoryDownloadList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/achivementHistoryDownloadList/${companyCode}`, {}, params);
  return response.data;
};

export const deleteAchivementHistoryDownloadById = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.delete(`/v1/deleteAchivementHistoryDownloadById/${companyCode}`, params);
  return response.data;
};

export const cancelAchivementHistoryDownloadById = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.delete(`/v1/cancelAchivementHistoryDownloadById/${companyCode}`, params);
  return response.data;
};

export const searchDate = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    ...params,
    closeDateFlg: false,
    targetDate: "",
  };

  const response = await ApiClient.get(`/v1/employments/searchDate/${companyCode}`, {}, query);
  return response.data;
};

export const getListClosingDate = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/employments/selectListClosingDate/${companyCode}`, {}, query);
  return response.data;
};

export const getListEmployment = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/employments/selectListRangeAndClosingDate/${companyCode}`, {}, query);
  return response.data;
};

export const getEmployment = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/employments/applied/all/${companyCode}`, {}, query);
  return response.data;
};

export const getOrganizationsTrees = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  let response;
  if (query.siteType == "PERSONAL") {
    response = await ApiClient.get(`/v1/organizations/current/${companyCode}`, {}, query);
  } else {
    response = await ApiClient.get(`/v2/organizations/trees/${companyCode}`, {}, query);
  }
  return response.data;
};

export const getOrganizationsCurrentTrees = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/organizations/current/${companyCode}`, {}, query);
  return response.data;
};

export const getListStaff = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/staffs/list/${companyCode}`, {}, query);
  return response.data;
};

export const getListStaffSelectBox = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v2/staffs/list/selectbox/${companyCode}`, {}, query);
  return response.data;
};

export const getStamplist = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/stamplist/list/${companyCode}`, {}, query);
  return response.data;
};

export const getHolidayUsingStatus = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const currentStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const query = {
    ...params,
    currentStaffCode,
  };

  const response = await ApiClient.get(`/v1/stamplist/holiday/usingstatus/${companyCode}`, {}, query);
  return response.data;
};

export const getStampSubTotal = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    ...params,
  };

  const response = await ApiClient.get(`/v1/stamplist/subtotal/${companyCode}`, {}, query);
  return response.data;
};

export const getListOutputLayout = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const response = await ApiClient.get(`/v1/outputlayouts/${companyCode}`, {}, {});
  return response.data;
};

export const achievementConfirm = async (params: any, url: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const reponse = await ApiClient.post(`/v1/achievementConfirm/${url}/${companyCode}`, {}, query);
  return reponse.data;
};

export const achievementConfirmStamp = async (params: any, url: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const reponse = await ApiClient.post(`/v1/achievementConfirmStamp/${url}/${companyCode}`, {}, query);
  return reponse.data;
};

export const postCopyAchievement = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };

  const reponse = await ApiClient.post(`/v2/stamplist/copyAchievement/${companyCode}`, {}, query);
  return reponse.data;
};

export const getCsvOutput = async (params: any, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  const query = {
    ...params,
    loginStaffCode,
  };

  await ApiClient.get(`/v1/achievementConfirm/csvOutput/${companyCode}`, {}, query);
};

export const postProcessingDownloadAchievement = async (params: IProcessingDownload): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    ...params,
  };

  const reponse = await ApiClient.post(`/v1/processingDownloadAchievement/${companyCode}`, {}, query);
  return reponse.data;
};

export const getAchievementDownloadFileCSV = async (params: any, fileName: string): Promise<void> => {
  console.log("test", fileName);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    ...params,
  };
  await ApiClient.downloadCsvNoConvert(`/v1/downloadFileAchivementHistoryById/${companyCode}`, query, fileName);
};

export const getTimeList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const query = {
    ...params,
    loginStaffCode,
  };

  const reponse = await ApiClient.get(`/v2/achievementConfirm/timeDayList/${companyCode}`, {}, query);
  return reponse.data;
};

export const getErrorInfoCsv = async (params: any, fileName: string): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  const query = {
    ...params,
    loginStaffCode,
  };

  await ApiClient.get(`/v1/achievementConfirm/errorInfo/${companyCode}`, {}, query);
};
