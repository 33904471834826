import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';

const useTimeListReport = (orgCode: string, monthlyStartDate: string, startTime:string,
  monthlyEndDate: string, endTime:string, flag:number) => {
  const [getItemTimeList, setItemTimeList] = useState<Array<MonthlyReportDomain>>([]);
  const onSubmit = () => {};
  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    let path = 'ordertime/salesmanagemet';
    if (flag === 2) {
      path = 'ordertime/item/salesmanagemet';
    }

    const dateOfweek = [
      {
        timePub: formik.values.timePub,
        timeSat: formik.values.timeSat,
        timeSun: formik.values.timeSun,
        timeMon: formik.values.timeMon,
        timeTue: formik.values.timeTue,
        timeWed: formik.values.timeWed,
        timeTur: formik.values.timeTur,
        timeFri: formik.values.timeFri,
      },
    ];
    setIsLoading(true);
    api.getTimeListReport(companyCode, orgCode, monthlyStartDate, startTime, monthlyEndDate, endTime, path, dateOfweek).then((response: any) => {
      if (isSubscribed) {
        setItemTimeList(response);
      }
      setIsLoading(false);
    });
    return () => { isSubscribed = false; };
  }, [startTime, endTime, monthlyStartDate, monthlyEndDate, formik.values.timePub, formik.values.timeSat, formik.values.timeSun, formik.values.timeMon, formik.values.timeTue, formik.values.timeWed, formik.values.timeTur, formik.values.timeFri, orgCode, flag, formik.values]);
  return {
    getItemTimeList, formik, getIsLoading,
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useDownload = () => {
  const downloadMonthlyReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '時間別日別売上.csv');
  };

  const downloadMonthlyReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '時間別日別売上.xlsx');
  };

  const downloadCntOrderReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '商品時間別.csv');
  };

  const downloadCntOrderReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '商品時間別.xlsx');
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadCntOrderReportCsv,
    downloadCntOrderReportExcel,
  };
};

export default useTimeListReport;
