/** @jsx jsx */
import React, { useState, useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import { grayScale } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import AcquisitionStatusNursingCareDomain from "domain/master/holidayManagement/nursingHolidayDigestion";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import moment from "moment";

import { useAcquisitionStatusForm, useDownloadNursingCareHistory } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import CalendarForm from "components/molecules/CalendarForm";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import BlockUI from "components/molecules/BlockUi";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "200px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
    color: "#666666",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
        text-align: center;
      }
    `,
  ),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
  transferDayNumb: string;
  holidayName: string;
  endDate: Date;
  startDate: Date;
  effectiveStartDate: Date;
}

const AcquisitionStatusForm: React.FC<{ orgTreesOptions: any }> = ({ orgTreesOptions }) => {
  const {
    acquisitionStatusFormList,
    orgCode,
    setOrgCode,
    dateFrom,
    dateTo,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    modalOpen,
    setModalOpen,
    holidayType,
    setHolidayType,
    holidayOptions,
    detailItems,
    callGetAcquisitionStatusDetail,
    underFlag,
    setUnderFlag,
  } = useAcquisitionStatusForm();
  const contextObject = useContext(HolidayManagementContext);

  const { downloadCsv, blocking } = useDownloadNursingCareHistory(
    orgCode || "",
    targetDateFrom || dateFrom,
    targetDateTo || dateTo,
    underFlag || 0,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAcquisitionStatusDetail = async (data: any) => {
    setDataDetail(data);
    callGetAcquisitionStatusDetail(data.staffCode);
    setModalOpen(true);
  };

  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: "",
    staffName: "",
    transferDayNumb: "",
    holidayName: "",
    endDate: new Date(),
    startDate: new Date(),
    effectiveStartDate: new Date(),
  });

  //
  const columns: Array<Column<AcquisitionStatusNursingCareDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: AcquisitionStatusNursingCareDomain;
          };
          data: Array<AcquisitionStatusNursingCareDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: AcquisitionStatusNursingCareDomain;
          };
          data: Array<AcquisitionStatusNursingCareDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: AcquisitionStatusNursingCareDomain;
          };
          data: Array<AcquisitionStatusNursingCareDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
            <IconLabelButton
              onClick={() => handleAcquisitionStatusDetail(cell.row.original)}
              text="編集"
              isIcon={false}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: AcquisitionStatusNursingCareDomain;
          };
          data: Array<AcquisitionStatusNursingCareDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "休暇タイプ",
        id: "holidayType",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; data: Array<any> }) => (
          <React.Fragment>
            {cell.row.original.holidayType === 7 && "介護休暇"}
            {cell.row.original.holidayType === 8 && "子の看護休暇"}
          </React.Fragment>
        ),
      },
      {
        Header: "休暇コード",
        accessor: "holidayCode",
      },
      {
        Header: "休暇名",
        accessor: "holidayName",
      },
      {
        Header: () => "取得日数",
        id: "digestedTime",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; data: Array<any> }) => (
          <React.Fragment>
            {`${cell.row.original.digestedDaysNum || "0"}日${cell.row.original.digestedTimeHour || "0"}時間`}
          </React.Fragment>
        ),
      },
    ],
    [targetDateFrom, targetDateTo, holidayType],
  );

  return (
    <BlockUI blocking={blocking}>
      <Accordion text="取得状況" callBack={() => {}}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="100%">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                {/* <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateFrom)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateFrom(date)} />
                </div>
                <div css={styles.betweenDays}>
                  ～
                </div>
                <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateTo)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateTo(date)} />
                </div> */}
                <FlexBoxItem>
                  <CalendarRangeV2
                    startDate={new Date(targetDateFrom || dateFrom)}
                    onChangeStart={(date: Date) => setTargetDateFrom(moment(date).format("YYYY/MM/DD"))}
                    endDate={new Date(targetDateTo || dateTo)}
                    onChangeEnd={(date) => {
                      if (date) {
                        setTargetDateTo(moment(date).format("YYYY/MM/DD"));
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode"
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag10"}
                name={"underFlag10"}
                label={"配下指定"}
                value={`underFlag10`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>

            <SelectForm
              label="休暇タイプ"
              width="900px"
              name="holidayType"
              value={String(holidayType)}
              setValue={(val: string) => setHolidayType(val)}
              options={holidayOptions}
            />
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 200px;
              }
              table th:nth-of-type(2) {
                width: 200px;
              }
              table th:nth-of-type(3) {
                width: 200px;
              }
              table th:nth-of-type(4) {
                width: 200px;
              }
              table th:nth-of-type(5) {
                width: 150px;
              }
              table th:nth-of-type(6) {
                width: 200px;
              }
              table th:nth-of-type(7) {
                width: 200px;
              }
              table th:nth-of-type(8) {
                width: 150px;
              }
              table tr td:nth-of-type(8) {
                text-align: center;
              }
              table tr th {
                text-align: center;
              }
              table tr td {
                text-align: left;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadCsv(holidayType || "7")}
                  iconType="download"
                  text="ダウンロード"
                />
                {/* <IconLabelButton onClick={() => downloadHolidayManagementGrantHistory(true)} iconType="download" text="全組織ダウンロード" /> */}
              </FormSubmitArea>
            ) : null}
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={acquisitionStatusFormList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={10}
              />
            </div>
          </div>
        </div>
        <Modal
          open={modalOpen}
          closeHandler={() => setModalOpen(false)}
          title="取得状況詳細"
          submitText=""
          onSubmit={() => {}}
          closeText={"閉じる"}
        >
          <div style={{ width: "350px", margin: "0 auto", fontSize: "14px" }} css={styles.table}>
            <VerticalLabelTextLayout label="スタッフコード" variable={dataDetail.staffCode} />
            <VerticalLabelTextLayout label="スタッフ名" variable={dataDetail.staffName} />

            <VerticalLabelTextLayout
              label="期間"
              variable={`${moment(targetDateFrom || dateFrom).format("YYYY年MM月DD日")}～${moment(
                targetDateTo || dateTo,
              ).format("YYYY年MM月DD日")}`}
            />
            {dataDetail.effectiveStartDate && (
              <VerticalLabelTextLayout
                label="有給付与日"
                variable={moment(dataDetail.effectiveStartDate).format("YYYY年MM月DD日")}
              />
            )}
            <VerticalLabelTextLayout label="休暇名" variable={dataDetail.holidayName} />

            <table className="table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>日付</th>
                  <th>取得日数</th>
                </tr>
              </thead>
              <tbody>
                {detailItems.map((item: any) => (
                  <tr>
                    <td>{moment(item.digestedDate).format("YYYY年MM月DD日")}</td>
                    <td>{item.digestedNum}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </Accordion>
    </BlockUI>
  );
};
export default AcquisitionStatusForm;
