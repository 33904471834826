import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getAllRolesV1, DetailRoleFunction } from 'api/role';

export const useRolesOptions = (): any => {
  // const [hqRoleOptions, setHqRoleOptions] = useState<Array<OptionType>>([]);
  // const [shopRoleOptions, setShopRoleOptions] = useState<Array<OptionType>>([]);
  // const [personalRoleOptions, setPersonalRoleOptions] = useState<Array<OptionType>>([]);
  const [roleOptions, setRoleOptions] = useState<Array<OptionType>>([]);

  useEffect(() => {
    /*
    getAllRoles().then((roles : AllRole) => {
      const personalRoleList = roles.personalRoleList.map((role) => ({
        value: role.roleId,
        label: role.roleName,
      }));
      let roleList = personalRoleList.concat();
      personalRoleList.unshift({
        value: '',
        label: '-',
      });

      const shopRoleList = roles.shopRoleList.map((role) => ({
        value: role.roleId,
        label: role.roleName,
      }));
      roleList = roleList.concat(shopRoleList);
      shopRoleList.unshift({
        value: '',
        label: '-',
      });

      const hqRoleList = roles.hqRoleList.map((role) => ({
        value: role.roleId,
        label: role.roleName,
      }));
      roleList = roleList.concat(hqRoleList);
      hqRoleList.unshift({
        value: '',
        label: '-',
      });

      sessionStorage.setItem('personalRole', personalRoleList[1].value);

      setHqRoleOptions(hqRoleList);
      setShopRoleOptions(shopRoleList);
      setPersonalRoleOptions(personalRoleList);
      setRoleOptions(roleList);
    });
    */
    getAllRolesV1().then((roles : Array<DetailRoleFunction>) => {
      const roleOptions = roles.map((role) => ({
        value: role.id.roleId,
        label: role.roleCode + ' ' + role.roleName,
      }));
      setRoleOptions(roleOptions);
    })
  }, []);
  return {
    roleOptions,
  };
};

export default useRolesOptions;
