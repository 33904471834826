/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import moment from "moment";
import React from "react";
import { IchangeLog } from "../interface";

const styleTable = css`
	th {
		width: 100px !important;
	}
	td {
		text-align: center;
	}
`;

const AttendStockPaidModal: React.FC<{
  changeLogData: IchangeLog[];
  selectChangelog: string;
  customStyle?: any;
}> = ({ changeLogData, selectChangelog, customStyle }) => {


  return (
    <React.Fragment>
      {changeLogData[Number(selectChangelog)]?.modifyStampAppData && (
        <FormPadding customStyle={customStyle}>
          <div css={styleTable}>
            <Table customStyle={css({ width: "auto", padding: "14px 10px" })}>
              <thead>
                <tr>
                  <Table.HeaderCell>対象期間</Table.HeaderCell>
                  <Table.HeaderCell>休暇</Table.HeaderCell>
                  <Table.HeaderCell>休暇単位</Table.HeaderCell>
                  <Table.HeaderCell>開始時間</Table.HeaderCell>
                  <Table.HeaderCell>終了時間</Table.HeaderCell>
                  <Table.HeaderCell>取得時間</Table.HeaderCell>

                </tr>
              </thead>
              <tbody>
                {changeLogData && changeLogData[Number(selectChangelog)].modifyStampAppData?.length! > 0 ? (
                  changeLogData[Number(selectChangelog)].modifyStampAppData?.map((ver: any, i) => {
                    let startDate = '';
                    let endDate = '';

                    if (moment(ver.holidayStartHm).format("YYYY-MM-DD") !== moment(ver.targetStartDate).format("YYYY-MM-DD")) {
                      startDate = `${moment(ver.holidayStartHm).format("HH:mm")} (翌)`
                    }
                    else {
                      startDate = `${moment(ver.holidayStartHm).format("HH:mm")}`
                    }
                    if (moment(ver.holidayEndHm).format("YYYY-MM-DD") !== moment(ver.targetEndDate).format("YYYY-MM-DD")) {
                      endDate = `${moment(ver.holidayEndHm).format("HH:mm")} (翌)`
                    }
                    else {
                      endDate = `${moment(ver.holidayEndHm).format("HH:mm")}`
                    }
                    return (
                      <tr key={ver.achievementId}>
                        <Table.Cell>
                          <BodyText>{ver.applyTargetDateType === 0 ? moment(ver.targetStartDate).format("YYYY/MM/DD") : `${moment(ver.targetStartDate).format("YYYY/MM/DD")} ~ ${moment(ver.targetEndDate).format("YYYY/MM/DD")}`}</BodyText>
                        </Table.Cell>
                        <Table.Cell>
                          <BodyText>{ver.holidayName ? ver.holidayName : "-"}</BodyText>
                        </Table.Cell>
                        <Table.Cell>
                          <BodyText>{ver.holidayDigestiveUnitName ? ver.holidayDigestiveUnitName : "-"}</BodyText>
                        </Table.Cell>
                        <Table.Cell>
                          <BodyText>
                            {ver.holidayStartHm ? startDate : "-"}
                          </BodyText>
                        </Table.Cell>
                        <Table.Cell>
                          <BodyText>
                            {ver.holidayStartHm ? endDate : "-"}
                          </BodyText>
                        </Table.Cell>
                        <Table.Cell>
                          <BodyText>
                            {ver.holidayDigestiveUnit === 2 && ver.holidayTimes ? `${ver.holidayTimes}時間` : "-"}
                          </BodyText>
                        </Table.Cell>
                      </tr>
                    )
                  }
                  )
                ) : (
                  <tr>
                    <Table.Cell>
                      <BodyText>
                        {changeLogData[Number(selectChangelog)].applicationDate
                          ? changeLogData[Number(selectChangelog)].applicationDate
                          : "-"}
                      </BodyText>
                    </Table.Cell>
                    <Table.Cell>
                      <BodyText>-</BodyText>
                    </Table.Cell>
                    <Table.Cell>
                      <BodyText>-</BodyText>
                    </Table.Cell>
                    <Table.Cell>
                      <BodyText>-</BodyText>
                    </Table.Cell>
                    <Table.Cell>
                      <BodyText>-</BodyText>
                    </Table.Cell>
                    <Table.Cell>
                      <BodyText>-</BodyText>
                    </Table.Cell>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </FormPadding>
      )}
    </React.Fragment>
  );
};

export default AttendStockPaidModal;
