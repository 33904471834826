import { useState, useEffect, createContext, useContext } from "react";
import useToastNotification from "hooks/useToastNotification";

import {
  getEmployment,
  getStampSubTotal,
  getHolidayUsingStatus,
  getOrganizationsTrees,
  getStamplist,
  getListStaffSelectBox,
} from "api/achievementConfirm";
import moment from "moment";
import { getTreesOrgSelectRange } from "api/organization";
import { IStampSubTotal, IHolidayUsingStatus, IStampList, IStaff, IOrganizationsTree } from "./interface";
import { functionCode } from "const";
import { renderError } from "utility/renderMessage";
import useIsMobile from "hooks/useIsMobile";

export const getDateByClosingDate = (
  viewPeriod: string,
  closingDate: number,
  targetMonth: Date,
  isSelectCalendar: boolean,
  targetDateFrom: Date,
  targetDateTo: Date,
) => {
  let startOfMonth: any;
  let endOfMonth: any;
  let targetDateFromStr = "";
  let targetDateToStr = "";
  const searchTargetDateFrom = sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateFrom") || "";
  const searchTargetDateTo = sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateTo") || "";
  const isGoBack = sessionStorage.getItem("headquartersFinal.monthlyList.isGoBack");

  if (viewPeriod === "daily") {
    targetDateFromStr = moment(new Date(targetDateFrom)).format("YYYY-MM-DD");
    targetDateToStr = moment(new Date(targetDateTo)).format("YYYY-MM-DD");
  } else if (searchTargetDateFrom && searchTargetDateTo && isGoBack === "true") {
    targetDateFromStr = moment(new Date(searchTargetDateFrom)).format("YYYY-MM-DD");
    targetDateToStr = moment(new Date(searchTargetDateTo)).format("YYYY-MM-DD");
    sessionStorage.setItem("headquartersFinal.monthlyList.isGoBack", "false");
  } else {
    if (closingDate === 0 || closingDate === null) {
      startOfMonth = moment(targetMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      endOfMonth = moment(targetMonth)
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      targetMonth.setDate(closingDate);
      startOfMonth = moment(targetMonth)
        .add(1, "day")
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      endOfMonth = moment(targetMonth).format("YYYY-MM-DD");
    }
    targetDateFromStr = moment(new Date(startOfMonth)).format("YYYY-MM-DD");
    targetDateToStr = moment(new Date(endOfMonth)).format("YYYY-MM-DD");
  }

  return {
    targetDateFromStr,
    targetDateToStr,
  };
};

type ContextType = {
  targetDateFrom: Date;
  setTargetDateFrom: React.Dispatch<React.SetStateAction<Date>>;

  targetDateTo: Date;
  setTargetDateTo: React.Dispatch<React.SetStateAction<Date>>;

  staffCode: string;
  setStaffCode: React.Dispatch<React.SetStateAction<string>>;

  targetMonth: Date;
  setTargetMonth: React.Dispatch<React.SetStateAction<Date>>;

  orgCode: string;
  setOrgCode: React.Dispatch<React.SetStateAction<string>>;

  selectOrgCode: string;
  setSelectOrgCode: React.Dispatch<React.SetStateAction<string>>;

  viewPeriod: string;
  setViewPeriod: React.Dispatch<React.SetStateAction<string>>;

  flagReLoad: number | undefined;
  setFlagReload: React.Dispatch<React.SetStateAction<number | undefined>>;
};
// export const initStates = {
//   targetDateFrom: new Date(),
//   targetDateTo: new Date(),
//   staffCode: '',
//   targetMonth: '',
//   orgCode: '',
//   selectOrgCode: '',
//   viewPeriod: 'monthly',
//   flagReLoad: false
// }

const setInitClosingDate = () => {
  const newDate = new Date();
  const currentDay = Number(moment(newDate).format("DD"));
  const closingDate = Number(sessionStorage.getItem("loginUser.closingDate"));
  let firstDayStr: any;
  let lastDayStr: any;
  const searchTargetDateFrom = sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateFrom") || "";
  const searchTargetDateTo = sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateTo") || "";
  if (searchTargetDateFrom && searchTargetDateTo) {
    firstDayStr = searchTargetDateFrom;
    lastDayStr = searchTargetDateTo;
    sessionStorage.setItem("attendStampList.attendStampForm", firstDayStr);
    sessionStorage.setItem("attendStampList.attendStampTo", lastDayStr);
  } else {
    if (closingDate === 0) {
      firstDayStr = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      lastDayStr = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (currentDay > closingDate) {
      newDate.setDate(closingDate);
      firstDayStr = moment(newDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
      lastDayStr = moment(newDate)
        .add(1, "month")
        .format("YYYY-MM-DD");
    } else {
      newDate.setDate(closingDate);
      firstDayStr = moment(newDate)
        .subtract(1, "month")
        .add(1, "day")
        .format("YYYY-MM-DD");
      lastDayStr = moment(newDate).format("YYYY-MM-DD");
    }
  }

  return {
    firstDayStr,
    lastDayStr,
  };
};
const { errorNotification } = useToastNotification();

export const attendStampContext = createContext<ContextType>({} as ContextType);
export const useAttendStampContext = (): ContextType => {
  const context = useContext(attendStampContext);
  return context;
};

export const useIntialState = () => {
  const { firstDayStr, lastDayStr } = setInitClosingDate();
  const isMobile = useIsMobile();
  const [targetDateFrom, setTargetDateFrom] = useState(
    sessionStorage.getItem("attendStampList.targetDateFrom")
      ? new Date(sessionStorage.getItem("attendStampList.targetDateFrom")!)
      : new Date(firstDayStr),
  );
  const [targetDateTo, setTargetDateTo] = useState(
    sessionStorage.getItem("attendStampList.targetDateTo")
      ? new Date(sessionStorage.getItem("attendStampList.targetDateTo")!)
      : new Date(lastDayStr),
  );
  const [staffCode, setStaffCode] = useState(
    sessionStorage.getItem("attendStampList.staffCode")! || sessionStorage.getItem("loginUser.staffCode")!,
  );
  const [targetMonth, setTargetMonth] = useState(
    new Date(sessionStorage.getItem("attendStampList.targetMonth") || new Date(lastDayStr)),
  );
  const [orgCode, setOrgCode] = useState(
    sessionStorage.getItem("attendStampList.orgCode")! || sessionStorage.getItem("loginUser.orgCode")!,
  );
  const [selectOrgCode, setSelectOrgCode] = useState(
    sessionStorage.getItem("attendStampList.orgCode")! || sessionStorage.getItem("loginUser.orgCode")!,
  );
  const [viewPeriod, setViewPeriod] = useState(
    isMobile
      ? "daily"
      : sessionStorage.getItem("headquartersFinal.viewPeriod") ||
          sessionStorage.getItem("attendStampList.viewPeriod") ||
          "monthly",
  );
  const [flagReLoad, setFlagReload] = useState<number | undefined>();
  const [isReloader, setIsReloader] = useState(false);

  const [orgCodeList, setOrgCodeList] = useState<any>([]);
  const [staffList, setstaffList] = useState<any>([]);

  return {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffCode,
    setStaffCode,
    targetMonth,
    setTargetMonth,
    orgCode,
    setOrgCode,
    selectOrgCode,
    setSelectOrgCode,
    viewPeriod,
    setViewPeriod,
    flagReLoad,
    setFlagReload,
    setIsReloader,
    isReloader,
    orgCodeList,
    setOrgCodeList,
    staffList,
    setstaffList,
  };
};

export const useStampListForm = (
  closingDate: number,
  attendFlag: string,
  selectedEmploymentId: string,
  setSubTotalTime: any,
  setSubTotalDay: any,
  setHolidaySubtotalItem: any,
  setAttendHolidays: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setTerm: any,
  setBusiness: any,
  isSelectCalendar: boolean,
  setOldSelectOrgCode: any,
  targetDateFrom: Date,
  setTargetDateFrom: React.Dispatch<React.SetStateAction<Date>>,
  targetDateTo: Date,
  setTargetDateTo: React.Dispatch<React.SetStateAction<Date>>,
  staffCode: string,
  setStaffCode: React.Dispatch<React.SetStateAction<string>>,
  targetMonth: Date,
  setTargetMonth: React.Dispatch<React.SetStateAction<Date>>,
  orgCode: string,
  setOrgCode: React.Dispatch<React.SetStateAction<string>>,
  selectOrgCode: string,
  setSelectOrgCode: React.Dispatch<React.SetStateAction<string>>,
  viewPeriod: string,
  setViewPeriod: React.Dispatch<React.SetStateAction<string>>,
  flagReLoad: number | undefined,
  setFlagReload: React.Dispatch<React.SetStateAction<number | undefined>>,
  isReloader: boolean,
  setIsReloader: React.Dispatch<React.SetStateAction<boolean>>,
  setOrgCodeList: React.Dispatch<React.SetStateAction<any>>,
  oldOrgCode: string,
  setStaffList: React.Dispatch<React.SetStateAction<any>>,
  setClosingDateV2: React.Dispatch<React.SetStateAction<number>>,
) => {
  const [employment, setEmployment] = useState(Object());

  // Stamp
  const [stampItems, setStampItems] = useState([]);
  const [stampBusiness, setStampBusiness] = useState([]);
  const [stampTerm, setStampTerm] = useState([]);
  const [dispStampTimeOrg, setDispStampTimeOrg] = useState(0);

  // HolidayUsingStatus
  const [paidHolidayGrantData, setPaidHolidayGrantData] = useState(Object());
  const [specialHolidayGrantList, setSpecialHolidayGrantList] = useState([]);
  const [substituteHolidayUsingStatusList, setSubstituteHolidayUsingStatusList] = useState([]);
  const [transferHolidayUsingStatusList, setTransferHolidayUsingStatusList] = useState([]);
  const [nursingCareHolidayData, setNursingCareHolidayDataList] = useState([]);
  const [nursingHolidayData, setNursingHolidayDataList] = useState([]);
  const [isConfirmError, setIsConfirmError] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isConfirmAchievement, setIsConfirmAchievement] = useState(false);

  const { targetDateFromStr, targetDateToStr } = getDateByClosingDate(
    viewPeriod,
    closingDate,
    targetMonth,
    isSelectCalendar,
    targetDateFrom,
    targetDateTo,
  );

  const resetFormValues = (resetStaffList: boolean, notify: boolean) => {
    if (notify) {
      errorNotification("選択した期間は組織がありません。");
      sessionStorage.setItem("attendStampList.targetDateFrom", targetDateFromStr);
      sessionStorage.setItem("attendStampList.targetDateTo", targetDateToStr);
    }
    setStampBusiness([]);
    setIsLoading(false);
    setStampTerm([]);
    setSubTotalTime({});
    setSubTotalDay({});
    setHolidaySubtotalItem([]);
    setAttendHolidays([]);
    setStampItems([]);
    if (resetStaffList) {
      setStaffList([]);
    }
    setStaffCode("");
    setOldSelectOrgCode(selectOrgCode);
  };

  useEffect(() => {
    const params = {
      orgCode,
      targetDateFrom: moment(new Date(targetDateFromStr)).format("YYYY年MM月DD日"),
      targetDateTo: moment(new Date(targetDateToStr)).format("YYYY年MM月DD日"),
      staffCode: staffCode || sessionStorage.getItem("loginUser.staffCode"),
    };

    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }
    if (isReloader === true) {
      getEmployment(params)
        .then((response: any) => {
          setEmployment(response);
          const { targetDateFromStr, targetDateToStr } = getDateByClosingDate(
            viewPeriod,
            response.closingDate,
            targetMonth,
            isSelectCalendar,
            targetDateFrom,
            targetDateTo,
          );
          setTargetDateFrom(new Date(targetDateFromStr));
          setTargetDateTo(new Date(targetDateToStr));
          setClosingDateV2(response.closingDate);
          setIsLoading(true);
          getTreesOrgSelectRange(
            true,
            functionCode.attendStampList,
            moment(targetDateFromStr).format("YYYY-MM-DD"),
            moment(targetDateToStr).format("YYYY-MM-DD"),
          )
            .then((response) => {
              setOrgCodeList(
                response.map((item: any) => ({
                  value: String(item.orgCode),
                  label: item.orgName,
                })),
              );

              if (response.filter((orgItem) => orgItem.orgCode === selectOrgCode).length === 0) {
                resetFormValues(true, false);
                return;
              }

              // Staffs
              const staff = fetchStaffs({
                targetDateFrom: new Date(targetDateFromStr),
                targetDateTo: new Date(targetDateToStr),
                orgCode: response.length == 1 ? response[0].orgCode : selectOrgCode,
                attendFlag,
                selectedEmploymentId,
                staffCode,
                setStaffList,
              });

              staff
                .then((resStaff: any) => {
                  if (
                    response.length > 0 &&
                    resStaff.staffListTmp.filter((staffFilter: any) => staffFilter.value === staffCode).length === 0
                  ) {
                    resetFormValues(false, false);
                    return;
                  }
                })
                .catch((error) => {
                  const mess = renderError(error);
                  errorNotification(mess);
                });

              if (response.length === 0) {
                resetFormValues(false, true);
                return;
              } else if (oldOrgCode !== selectOrgCode) {
                resetFormValues(false, false);
                return;
              }

              // Stamplist
              fetchStampList({
                targetDateFrom: new Date(targetDateFromStr),
                targetDateTo: new Date(targetDateToStr),
                attendFlag,
                staffCode,
                orgCode: response.length == 1 ? response[0].orgCode : selectOrgCode,
                setStampItems,
                setStampBusiness,
                setStampTerm,
                setIsConfirmError,
                setIsConfirmAchievement,
                setSubTotalTime,
                setSubTotalDay,
                setHolidaySubtotalItem,
                setAttendHolidays,
                errorNotification,
                setIsLoading,
                setDispStampTimeOrg,
              });

              // holidayUsingStatus
              fetchHolidayUsingStatus({
                targetDateFrom: new Date(targetDateFromStr),
                targetDateTo: new Date(targetDateToStr),
                staffCode,
                setPaidHolidayGrantData,
                setSpecialHolidayGrantList,
                setSubstituteHolidayUsingStatusList,
                setTransferHolidayUsingStatusList,
                setNursingCareHolidayDataList,
                setNursingHolidayDataList,
                currentOrgCode: response.length == 1 ? response[0].orgCode : selectOrgCode,
              });

              // stampSubTotal
              fetchStampSubTotal({
                staffCode,
                orgCode: response.length == 1 ? response[0].orgCode : selectOrgCode,
                attendFlag,
                setTerm,
                setBusiness,
                setAttendHolidays,
                setSubTotalTime,
                setSubTotalDay,
                setHolidaySubtotalItem,
                targetDateFrom: new Date(targetDateFromStr),
                targetDateTo: new Date(targetDateToStr),
              });
            })
            .catch((error) => {
              setIsLoading(false);
              const mess = renderError(error);
              errorNotification(mess);
            });
        })
        .catch((error) => {
          const mess = renderError(error);
          errorNotification(mess);
        });

      setIsReloader(false);
    } else {
      setIsLoading(false);
    }
  }, [isReloader]);

  return {
    employment,
    setEmployment,
    closingDate,
    setViewPeriod,
    setTargetMonth,
    viewPeriod,
    targetMonth,
    orgCode,
    setStaffList,
    stampItems,
    stampBusiness,
    stampTerm,
    paidHolidayGrantData,
    specialHolidayGrantList,
    substituteHolidayUsingStatusList,
    transferHolidayUsingStatusList,
    nursingCareHolidayData,
    nursingHolidayData,
    flagReLoad,
    targetDateFromStr,
    targetDateToStr,
    isConfirmError,
    setIsReload,
    isReload,
    isConfirmAchievement,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffCode,
    setStaffCode,
    setOrgCode,
    selectOrgCode,
    setSelectOrgCode,
    setFlagReload,
    dispStampTimeOrg,
  };
};

export const fetchStaffs = async (iStaff: IStaff) => {
  const { targetDateFrom, targetDateTo, orgCode, attendFlag, selectedEmploymentId, staffCode, setStaffList } = iStaff;

  const params = {
    orgCode,
    targetDayFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
    targetDayTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
    attendStaff: attendFlag,
    stateStatus: "1, 3, 4",
    employmentId: selectedEmploymentId,
    loginStaffCode: sessionStorage.getItem("loginUser.staffCode"),
    functionCode: functionCode.attendStampList,
  };

  let staffListTmp;
  await getListStaffSelectBox(params)
    .then((response: any) => {
      staffListTmp = response.map((item: any) => ({
        value: String(item.staffCode),
        label: `${item.staffCode} ${item.staffName}`,
      }));
      setStaffList(staffListTmp);
    })
    .catch((error) => {
      const mess = renderError(error);
      errorNotification(mess);
    });

  return {
    staffListTmp,
  };
};

export const fetchStampList = async (iStampList: IStampList) => {
  const {
    targetDateFrom,
    targetDateTo,
    attendFlag,
    staffCode,
    orgCode,
    setStampItems,
    setStampBusiness,
    setStampTerm,
    setIsConfirmError,
    setIsConfirmAchievement,
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
    setAttendHolidays,
    errorNotification,
    setIsLoading,
    setDispStampTimeOrg,
  } = iStampList;

  const params = {
    periodFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
    periodTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
    attendFlag,
    staffCode,
    orgCode: orgCode,
    loginStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
  };

  if (staffCode) {
    await getStamplist(params)
      .then((data: any) => {
        setStampItems(data.attendStampDataList);
        setStampBusiness(data.business);
        setStampTerm(data.term);
        setIsConfirmError(data.isConfirmError);
        setIsConfirmAchievement(data.isAchivementConfirm);
        setDispStampTimeOrg(data.dispStampTimeOrg);
      })
      .catch((error) => {
        setStampBusiness([]);
        setStampTerm([]);
        setSubTotalTime({});
        setSubTotalDay({});
        setHolidaySubtotalItem([]);
        setAttendHolidays([]);
        setStampItems([]);
        const mess = renderError(error, true);
        if (mess) {
          errorNotification(mess);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
};

export const fetchHolidayUsingStatus = (iHolidayUsingStatus: IHolidayUsingStatus) => {
  const {
    targetDateFrom,
    targetDateTo,
    staffCode,
    setPaidHolidayGrantData,
    setSpecialHolidayGrantList,
    setSubstituteHolidayUsingStatusList,
    setTransferHolidayUsingStatusList,
    setNursingCareHolidayDataList,
    setNursingHolidayDataList,
    currentOrgCode,
  } = iHolidayUsingStatus;

  const params = {
    targetDateFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
    targetDateTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
    staffCode,
    currentOrgCode,
  };

  getHolidayUsingStatus(params)
    .then((data: any) => {
      if (data.paidHolidayGrantData != null) {
        setPaidHolidayGrantData(data.paidHolidayGrantData);
      } else {
        setPaidHolidayGrantData([]);
      }

      if (data.specialHolidayGrantList != null) {
        setSpecialHolidayGrantList(data.specialHolidayGrantList);
      } else {
        setSpecialHolidayGrantList([]);
      }
      if (data.substituteHolidayUsingStatusList != null) {
        setSubstituteHolidayUsingStatusList(data.substituteHolidayUsingStatusList);
      } else {
        setSubstituteHolidayUsingStatusList([]);
      }

      if (data.transferHolidayUsingStatusList != null) {
        setTransferHolidayUsingStatusList(data.transferHolidayUsingStatusList);
      } else {
        setTransferHolidayUsingStatusList([]);
      }

      if (data.nursingCareHolidayData != null) {
        setNursingCareHolidayDataList(data.nursingCareHolidayData);
      } else {
        setNursingCareHolidayDataList([]);
      }

      if (data.nursingHolidayData != null) {
        setNursingHolidayDataList(data.nursingHolidayData);
      } else {
        setNursingHolidayDataList([]);
      }
    })
    .catch((error) => {
      const mess = renderError(error);
      errorNotification(mess);
    });
};

export const fetchStampSubTotal = (iStampSubTotal: IStampSubTotal) => {
  const {
    orgCode,
    targetDateFrom,
    targetDateTo,
    staffCode,
    attendFlag,
    setBusiness,
    setTerm,
    setAttendHolidays,
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
  } = iStampSubTotal;
  const params = {
    orgCode,
    periodFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
    periodTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
    staffCode,
    attendFlag,
  };
  if (staffCode) {
    getStampSubTotal(params)
      .then((response: any) => {
        setBusiness(response.business);
        setTerm(response.term);
        setAttendHolidays(response.attendHolidays);
        setSubTotalTime(response.attendStampSubTotalTime[0]);
        setSubTotalDay(response.attendStampSubTotalDay[0]);
        setHolidaySubtotalItem(response.holidaySubtotalItem);
      })
      .catch((error) => {
        const mess = renderError(error, true);
        if (mess) {
          errorNotification(mess);
        }
      });
  }
};

export const fetchOrganizationsTrees = (IOrganizationsTree: IOrganizationsTree) => {
  const { siteType, orgCode, targetDateFrom, targetDateTo, staffCode, setOrgCodeList } = IOrganizationsTree;

  const params = {
    siteType: String(siteType) === "3" ? "HQ" : String(siteType) === "2" ? "SHOP" : "PERSONAL",
    orgCode,
    targetDayFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
    targetDayTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
    staffCode,
  };

  getOrganizationsTrees(params)
    .then((response: any) => {
      setOrgCodeList(
        response.map((item: any) => ({
          value: String(item.orgCode),
          label: `${item.orgCode} ${item.orgName}`,
        })),
      );
    })
    .catch((error) => {
      const mess = renderError(error);
      errorNotification(mess);
    });
};

export const downloadOriginalPDF = async (response: any, downloadFileName: string) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const file = new Blob([bom, response], {
    type: "application/pdf",
  });
  saveAs(file, downloadFileName);
};

export const eventScroll = (e: { target: any }) => {
  const { target } = e;
  const contentRight = document.getElementById("bottomRightAreaTime");
  const headRight = document.getElementById("topRightAreaTime");
  const scrollBottom = document.getElementById("scrollBottom");
  if (contentRight && headRight && scrollBottom) {
    contentRight.scrollLeft = target.scrollLeft;
    headRight.scrollLeft = target.scrollLeft;
    scrollBottom.scrollLeft = target.scrollLeft;
  }
};

export default {
  useStampListForm,
  eventScroll,
};
