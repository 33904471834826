/** @jsx jsx */
import { useState, useCallback, useMemo } from "react";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import { DeleteButton, InfoButton } from "components/atoms/Button";
import DataTable from "components/organismos/DataTable/DataTable";
import SpecialDateMasterDomain from "domain/master/labor/specialDateMaster";
import { deleteById } from "api/specialDateMaster";
import { grayScale, textFontSize } from "components/styles";
import useToastNotification from "hooks/useToastNotification";
import { useSpecialSalaryPattern } from "./hooks";
import SidebarTemplate from "components/templates/SidebarTemplate";
import BlockUI from "components/molecules/BlockUi";
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import FormPadding from "components/atoms/Form/FormPadding";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import IconLabelButton from "components/molecules/IconLabelButton";
import Modal from "components/molecules/Modal";

const SpecialSalaryPatternPage = () => {
  const { specialList, setSpecialList, isLoading, setLoading, detailRole } = useSpecialSalaryPattern();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [selectedSpecialMaster, setSelectedSpecialMaster] = useState(SpecialDateMasterDomain.generateInitial());

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((special: SpecialDateMasterDomain) => {
    setConfirmModalOpen(true);
    setSelectedSpecialMaster(special);
  }, []);

  const deleteSpecialMaster = useCallback(() => {
    setLoading(true);
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    deleteById(companyCode, selectedSpecialMaster.specialSalaryPatternId)
      .then((response: any) => {
        setSpecialList(
          specialList.filter(
            (special) => special.specialSalaryPatternId !== selectedSpecialMaster.specialSalaryPatternId,
          ),
        );
        setLoading(false);
        setConfirmModalOpen(false);
        successNotification("削除しました。");
      })
      .catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} <br>`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    errorNotification,
    selectedSpecialMaster.specialSalaryPatternId,
    setSpecialList,
    specialList,
    successNotification,
  ]);

  const columns: Array<Column<SpecialDateMasterDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialDateMasterDomain };
          data: Array<SpecialDateMasterDomain>;
        }) => {
          return (
            <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
              <Link to={`/masterSpecialSalaryPattern/edit/${cell.row.original.specialSalaryPatternId}/0`}>
                <InfoButton
                  text={detailRole.editable ? `編集` : `参照`}
                  minWidth={"40px"}
                  customStyle={css({
                    borderRadius: "3px",
                    fontSize: "12px",
                    lineHeight: "20px",
                    padding: "0 5px",
                    height: "24px",
                  })}
                />
              </Link>
              {detailRole.editable ? (
                <DeleteButton
                  text="削除"
                  onClick={() => openConfirmModal(cell.row.original)}
                  minWidth={"40px"}
                  customStyle={css({
                    borderRadius: "3px",
                    fontSize: "12px",
                    lineHeight: "20px",
                    padding: "0 5px",
                    height: "24px",
                  })}
                />
              ) : null}
            </FlexBox>
          );
        },
      },
      {
        Header: "コード",
        accessor: "specialSalaryPatternCode",
      },
      {
        Header: "パターン名",
        accessor: "specialSalaryPatternName",
      },
    ],
    [openConfirmModal, detailRole],
  );

  return (
    <SidebarTemplate>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle bold={true} title="特賃日設定" />
          <FormPadding customStyle={css({ paddingLeft: "35px" })}>
            <div>
              {detailRole.editable === 1 && (
                <FlexBoxItem margin="15px 0" customStyle={css({ display: "flex" })}>
                  <Link to="/masterSpecialSalaryPattern/add">
                    <IconLabelButton
                      onClick={() => {}}
                      iconType="addCircle"
                      text="追加"
                      padding={"6px 0"}
                      width="auto"
                    />
                  </Link>
                </FlexBoxItem>
              )}
              <Modal
                typeSubmit="reject"
                open={confirmModalOpen}
                closeHandler={closeConfirmModal}
                submitText="削除する"
                title="特賃日設定を削除"
                onSubmit={deleteSpecialMaster}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: grayScale.gray60,
                    fontSize: textFontSize.re,
                  }}
                >
                  特賃日設定を削除します。よろしいですか？
                </div>
              </Modal>
            </div>
          </FormPadding>
          <div
            css={css`
              table {
                table-layout: auto;
              }
              table th:nth-of-type(1) {
                width: 100px;
              }
              table th:nth-of-type(2) {
                width: 200px;
                text-align: left;
              }
              table th:nth-of-type(3) {
                width: calc(100% - 300px);
                text-align: left;
              }
            `}
          >
            <div style={{ overflowX: "auto", whiteSpace: "nowrap", margin: "15px" }}>
              <DataTable
                columns={columns}
                data={specialList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                })}
              />
            </div>
          </div>
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default SpecialSalaryPatternPage;
