import { useState, useEffect } from 'react';

import { getList } from 'api/attendAllowance';
import AttendAllowanceDomain from 'domain/attendAllowance';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useAttendAllowance = () => {
  const [blocking, setBlocking] = useState(false);


  const { detailRole } = useGetDetailRole(setBlocking, functionCode.attendAllowance);

  const [allowanceList, setAllowanceList] = useState<Array<AttendAllowanceDomain>>([]);
  useEffect(() => {
    setBlocking(true);
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      setBlocking(false);
      if (isSubscribed) {
        setAllowanceList(response.map((result) => new AttendAllowanceDomain(result)));
      }
    });
    return () => {
      setBlocking(false);
      isSubscribed = false; };
  }, []);
  return { allowanceList, setAllowanceList , blocking, setBlocking, detailRole};
};

export default useAttendAllowance;
