import { useState, useCallback, useContext } from "react";

import * as api from "api/staff";
import ToastNotification from "components/molecules/ToastNotification";
import StaffDomain from "domain/staff";
import useToastNotification from "hooks/useToastNotification";
import { useHistory } from "react-router-dom";
import { functionCode } from "const";

export const useStaffDelete = (fetchData: () => Promise<void>, setIsLoading: any) => {
  const history = useHistory();
  const [deleteTargetStaff, setDeleteTargetStaff] = useState<StaffDomain | null>(null);
  const { successNotification, errorNotification } = useToastNotification();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const closeDeleteModal = useCallback(() => setIsOpenDeleteModal(!isOpenDeleteModal), [isOpenDeleteModal]);

  const onSubmitDeleteStaff = useCallback(() => {
    if (isOpenDeleteModal === false) {
      setIsOpenDeleteModal(true);
      return false;
    } else {
      setIsLoading(true);
      if (deleteTargetStaff) {
        api
          .deleteStaff(deleteTargetStaff.staffCode)
          .then(() => {
            setDeleteTargetStaff(null);
            successNotification("削除しました。");
            history.push("/staffs");
            // 削除後にデータを再取得
            fetchData();
            setIsLoading(false);
          })
          .catch((error) => {
            setIsOpenDeleteModal(false);
            setIsLoading!(false);
            setDeleteTargetStaff(null);

            if (error.response && error.response.data && error.response.data.errors) {
              let msgError = "";
              error.response.data.errors.map((item: { defaultMessage: string }) => {
                msgError += `${item.defaultMessage} \n`;
                return msgError;
              });
              errorNotification(msgError);
            } else {
              errorNotification("サーバー側でエラーが発生しました。");
            }
          });
      }
    }
  }, [isOpenDeleteModal, deleteTargetStaff, history, errorNotification, fetchData, successNotification]);

  return {
    deleteTargetStaff,
    setDeleteTargetStaff,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  };
};

export const useDownload = (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  underFlag: number,
) => {
  const downloadStaffCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error("状態を選択してください。");
      return;
    }
    api.downloadStaffCsvV1(orgCode, executiveId, employmentId, stateStatus, functionCode.staffs, underFlag);
  }, [employmentId, executiveId, orgCode, stateStatus, underFlag]);
  const downloadStaffManagerCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error("状態を選択してください。");
      return;
    }
    api.downloadStaffManagerCsvV1(orgCode, executiveId, employmentId, stateStatus, functionCode.staffs, underFlag);
  }, [employmentId, executiveId, orgCode, stateStatus, underFlag]);

  const downloadStaffSalaryCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error("状態を選択してください。");
      return;
    }
    api.downloadStaffSalaryCsv(orgCode, executiveId, employmentId, stateStatus, functionCode.staffs, underFlag);
  }, [employmentId, executiveId, orgCode, stateStatus, underFlag]);

  const downloadStaffHolidayCsv = useCallback(() => {
    if (stateStatus.length === 0) {
      ToastNotification.error("状態を選択してください。");
      return;
    }
    api.downloadStaffHolidayCsvV1(orgCode, executiveId, employmentId, stateStatus, functionCode.staffs, underFlag);
  }, [employmentId, executiveId, orgCode, stateStatus, underFlag]);

  return {
    downloadStaffCsv,
    downloadStaffManagerCsv,
    downloadStaffSalaryCsv,
    downloadStaffHolidayCsv,
  };
};

export default {};
