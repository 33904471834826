/** @jsx jsx */

import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import SidebarTemplate from "components/templates/SidebarTemplate";
import { Container, Row, Col } from "react-grid-system";
import FormContents from "components/atoms/Form/FormContents";
import BodyText from "components/atoms/BodyText";
import moment from "moment";
import BlockUI from "components/molecules/BlockUi";
import { css, jsx } from "@emotion/core";
import ToastModal from "components/organismos/ToastModal";
import ConfirmModal from "components/organismos/ConfirmModal";
import Button, { PrimaryButton, DeleteButton } from "components/atoms/Button";
import productColor, { grayScale, textColor, textFontSize } from "components/styles";
import useAllowanceApplicationForm from "./hooks";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable";
import FormPadding from "components/atoms/Form/FormPadding";
import TabControl from "components/atoms/TabControl";
import useIsMobile from "hooks/useIsMobile";
import FlexBox from "components/atoms/FlexBox";
import AllowanceDetailEdit from "components/pages/master/attend/AllowanceApplicationPage/AllowanceDetailEdit";
import AttendAllowanceModal from "components/molecules/ChangeLogModal/Template/AttendAllowanceModal";

const AllowanceApplicationPage: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    isLoading,
    orgTreesOptions,
    formik,
    staffName,
    toastModalOpen,
    toastMessage,
    closeConfirmModal,
    confirmModalOpen,
    setToastModalOpen,
    selectChangelog,
    setSelectChangelog,
    orgCode,
    setOrgCode,
  } = useAllowanceApplicationForm();

  const styles = useMemo(
    () => ({
      container: {
        marginTop: "30px",
      },
      colorText: css({
        color: grayScale.gray100,
      }),
      approvalWrap: css({
        padding: "0px",
        marginBottom: "15px",
        marginTop: "0px",
      }),
      formContent: {
        paddingBottom: "20px",
      },
      rowPadding: css({
        paddingBottom: "16px",
      }),
      table: css(
        {
          width: "100%",
          borderLeft: "1px solid #ddd",
          borderRight: "1px solid #ddd",
        },
        css({
          ["table"]: {
            width: "100%",
          },
          ["table tr th"]: {
            fontWeight: "bold",
            textAlign: "left",
          },
          ["table tr td"]: {
            textAlign: "left",
          },
          ["table tr th:nth-child(1)"]: {
            minWidth: isMobile ? "80px" : "150px",
          },
          ["table tr th:nth-child(2)"]: {
            minWidth: isMobile ? "100px" : "250px",
          },
          ["table tr th:nth-child(3)"]: {
            width: isMobile ? "auto" : "150px",
          },
          ["table tr th:nth-child(4)"]: {
            width: "auto",
          },
        }),
      ),
    }),
    [isMobile],
  );

  return (
    <SidebarTemplate pageTitle="手当申請">
      <BlockUI blocking={isLoading}>
        <FormContents
          customStyle={css({
            paddingBottom: isMobile ? "8px" : "",
          })}
        >
          <FormTitle
            title="手当申請"
            bold={true}
            customStyle={css(isMobile ? { padding: "8px 16px 16px 16px", margin: 0 } : {})}
          />
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormPadding customStyle={css(isMobile ? { padding: "0 16px 16px 16px" } : { paddingLeft: "35px" })}>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>日時</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText customStyle={styles.colorText}>
                      {moment(formik.values.targetDate).format("YYYY年MM月DD日(ddd)")}
                    </BodyText>
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText color={textColor.mobileTitle}>{staffName}</BodyText>
                  </Col>
                </Row>

                <Row css={styles.rowPadding}>
                  <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                    <BodyText>出勤組織</BodyText>
                  </Col>
                  <Col md={10}>
                    <Row>
                      {
                        <Col md={4}>
                          <SelectForm
                            label=""
                            name="orgCode"
                            value={String(orgCode)}
                            setValue={(val: string) => setOrgCode(val)}
                            options={orgTreesOptions}
                            disable={formik.values.modifierApplicationId ? true : false}
                            width={isMobile ? "100%" : "800px"}
                          />
                        </Col>
                      }
                    </Row>
                  </Col>
                </Row>

                <Row css={styles.rowPadding}>
                  <Col xs={12}>
                    <div css={styles.table}>
                      <table className="table-bordered">
                        <thead>
                          <tr>
                            <th>手当</th>
                            {!isMobile && <th>申請内容</th>}
                            <th>申請値</th>
                            <th>備考</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.detailList?.map((detail, index: number) => (
                            <AllowanceDetailEdit
                              key={index.toString()}
                              formik={formik}
                              detail={detail}
                              index={index}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>

                {isMobile ? (
                  <FormPadding customStyle={css({ padding: "0 16px 16px 16px" })}>
                    <div css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr);", gap: 8 }}>
                      {!(formik.values.modifiyStatus === 1 && formik.values.applicationStatus === 0) && (
                        <PrimaryButton
                          ghost={false}
                          disabled={formik.values.applicationStatus === 0}
                          text="申請する"
                          onClick={() => {
                            formik.setFieldValue("buttonType", 0);
                            formik.handleSubmit();
                          }}
                        />
                      )}
                      {formik.values.applicationStatus === 0 && (
                        <DeleteButton
                          ghost={false}
                          text="申請キャンセル"
                          onClick={() => {
                            formik.setFieldValue("buttonType", 1);
                            formik.handleSubmit();
                          }}
                        />
                      )}
                      {formik.values.deleteAvailable === true && (
                        <DeleteButton
                          ghost={false}
                          text="削除申請"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue("buttonType", 2);
                          }}
                        />
                      )}

                      <Button
                        customStyle={css({
                          border: `1px solid ${productColor.primary}`,
                        })}
                        onClick={() => history.goBack()}
                        text="キャンセル"
                        ghost={true}
                      />
                    </div>
                  </FormPadding>
                ) : (
                  <React.Fragment>
                    <Row>
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          css={css`
                            .btnAction:nth-of-type(2) {
                              margin-left: 10px;
                            }
                            .btnAction:nth-of-type(3) {
                              margin-left: 10px;
                            }
                          `}
                        >
                          <FlexBox>
                            {(formik.values.modifiyStatus === null ||
                              formik.values.modifiyStatus === 0 ||
                              (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0)) && (
                              <div className="btnAction">
                                <PrimaryButton
                                  ghost={false}
                                  disabled={formik.values.applicationStatus === 0}
                                  text="申請する"
                                  onClick={() => {
                                    formik.setFieldValue("buttonType", 0);
                                    formik.handleSubmit();
                                  }}
                                />
                              </div>
                            )}
                            {formik.values.applicationStatus === 0 && (
                              <div className="btnAction">
                                <DeleteButton
                                  ghost={false}
                                  text="申請キャンセル"
                                  onClick={() => {
                                    formik.setFieldValue("buttonType", 1);
                                    formik.handleSubmit();
                                  }}
                                />
                              </div>
                            )}
                            {formik.values.deleteAvailable === true && (
                              <div className="btnAction">
                                <DeleteButton
                                  ghost={false}
                                  text="削除申請"
                                  onClick={() => {
                                    formik.handleSubmit();
                                    formik.setFieldValue("buttonType", 2);
                                  }}
                                />
                              </div>
                            )}
                          </FlexBox>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "30px",
                        }}
                      >
                        <FlexBox>
                          <Button onClick={() => history.goBack()} text="キャンセル" ghost={true} />
                        </FlexBox>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </FormPadding>
            </Container>
          </form>
        </FormContents>
      </BlockUI>

      {!isMobile && (
        <div style={{ paddingTop: 20 }}>
          <FormContents customStyle={styles.formContent}>
            <Row style={{ padding: "10px 30px" }}>
              <Col
                md={2}
                xs={12}
                style={{
                  fontWeight: "bold",
                  fontSize: textFontSize.md,
                  lineHeight: "24px",
                  paddingBottom: "20px",
                  color: textColor.main,
                }}
              >
                更新履歴
              </Col>
            </Row>
            <TabControl
              isTabModal={true}
              isFixed={false}
              items={formik.values.historyList.map((log, index: number) => {
                return {
                  value: String(index),
                  display: `ver${log.version}`,
                };
              })}
              setValue={setSelectChangelog as (arg: string) => void}
              currentValue={selectChangelog}
              styleNotActive={css({
                borderRadius: 0,
                border: "none",
                width: "100px",
                flex: "unset",
              })}
              styleActive={css({
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #007BC3",
                width: "100px",
                flex: "unset",
              })}
            />
            {formik.values.historyList.length > 0 &&
              formik.values.historyList.map((history, index: number) => (
                <div
                  style={{
                    display: selectChangelog !== String(index) ? "none" : "block",
                  }}
                >
                  <React.Fragment>
                    <FormPadding
                      customStyle={{
                        fontSize: "14px",
                        color: textColor.mobileTitle,
                      }}
                    >
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請種別:
                        </Col>
                        <Col md={10} xs={12}>
                          {history.modifiyStatus == 0 ? "申請" : "削除"}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請者:
                        </Col>
                        <Col md={10} xs={12}>
                          {history.applicationStaffCode && `${history.applicationStaffCode} `}
                          {history.applicationStaffName && history.applicationStaffName}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請日時:
                        </Col>
                        <Col md={10} xs={12}>
                          {history.applicationDate && moment(history.applicationDate).format("YYYY/MM/DD HH:mm:ss")}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "10px",
                            color: textColor.main,
                          }}
                        >
                          出勤組織:
                        </Col>
                        <Col md={10} xs={12}>
                          {history.orgCode && history.orgCode}
                          {history.orgName && history.orgName}
                        </Col>
                      </Row>
                      <Row>
                        <AttendAllowanceModal details={history.detailList} customStyle={styles.approvalWrap} />
                      </Row>
                      {history.approvalHistoryList.length > 0 && (
                        <Row>
                          <HistoryApprovalTable
                            historyData={history.approvalHistoryList}
                            applicationStatus={history.applicationStatus}
                          />
                        </Row>
                      )}
                    </FormPadding>
                  </React.Fragment>
                </div>
              ))}
          </FormContents>
        </div>
      )}

      <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        title={
          formik.values.buttonType === 0
            ? formik.values.modifiyStatus === 0
              ? "手当修正を申請"
              : "手当を申請"
            : formik.values.buttonType === 1
            ? "手当キャンセルを申請"
            : "削除手当を申請"
        }
        content={
          formik.values.buttonType === 0
            ? formik.values.modifiyStatus === 0
              ? "手当修正を申請します。よろしいですか？"
              : "手当を申請します。よろしいですか？"
            : formik.values.buttonType === 1
            ? "手当キャンセルを申請します。よろしいですか？"
            : "削除手当を申請します。よろしいですか？"
        }
        submitText="申請する"
        typeSubmit={formik.values.buttonType === 0 ? "confirm" : "reject"}
      />
    </SidebarTemplate>
  );
};

export default AllowanceApplicationPage;
