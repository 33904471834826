/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import MediaMasterDomain from 'domain/master/sales/media';
import { deleteMediaMaster } from 'api/mediaMaster';
import useToastNotification from 'hooks/useToastNotification';
import useMediaMasterList from './hooks';

const MediaMasterPage: React.FC = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent] = useState('');
  const [selectedObj, setSelectedObj] = useState(MediaMasterDomain.generateInitial());
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [orgCode, setorgCode] = useState('');
  const { successNotification, errorNotification } = useToastNotification();

  const { mediaMasterList, setMediaMasterList } = useMediaMasterList(orgCode);

  // Set default selected org
  useMemo(() => {
    setorgCode(sessionStorage.getItem('selectedOrgCode')!);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const openConfirmModal = useCallback((payment : MediaMasterDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(payment);
  }, []);
  // console.log(selectedObj);
  const deletePaymentMediaOrg = useCallback(() => {
    const staffName = sessionStorage.getItem('loginUser.staffName') || '';
    const submitObj = {
      paymentMediaMstCode: selectedObj.paymentMediaMstCode,
      id: {
        companyCode: selectedObj.companyCode,
        orgCode,
        posType: selectedObj.dispOrder,
      },
      createUser: staffName,
      updateUser: staffName,
    };
    deleteMediaMaster(confirmModalResendOpen ? '1' : submitObj.paymentMediaMstCode).then((Response: any) => {
      setMediaMasterList(mediaMasterList.filter(
        (mediaMaster) => mediaMaster.paymentMediaMstCode !== selectedObj.paymentMediaMstCode,
      ));
      setConfirmModalOpen(false);
      // setToastMessage('削除しました。');
      // setToastModalOpen(true);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        // setToastMessage(stringErr);
        // setToastModalOpen(true);
        errorNotification(stringErr);
      } else if ((error.response && error.response.data && error.response.data.message)) {
        const stringErr = error.response.data.message;
        //   setToastMessage(stringErr);
        //   setToastModalOpen(true);
        // } else {
        //   setToastMessage('サーバー側でエラーが発生しました。');
        //   setToastModalOpen(true);
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    });
  }, [selectedObj.paymentMediaMstCode, selectedObj.companyCode,
    selectedObj.dispOrder, orgCode, confirmModalResendOpen,
    setMediaMasterList, mediaMasterList, successNotification, errorNotification]);

  const columns: Array<Column<MediaMasterDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'paymentMediaMstCode',
    },
    {
      Header: '名称',
      accessor: 'paymentMediaMstName',
    },
    {
      Header: '並び順',
      accessor: 'dispOrder',
    },
    {
      Header: () => null,
      id: 'actionButton',
      accessor: 'paymentMediaMstCode',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: MediaMasterDomain } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/salesPaymentMedia/mediaMaster/edit/${cell.row.original.paymentMediaMstCode}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>

            <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                onMouseEnter={() => setIsHoverDelete(true)}
                onMouseLeave={() => setIsHoverDelete(false)}
              >
                <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
          </div>
        );
      },
    },
  ], [openConfirmModal]);
  return (
    <div>
      <PageTitle
        title=""
        rightContents={(
          <Link to="/salesPaymentMedia/mediaMaster/add">
            <span style={{ marginRight: '12px' }}>
              <PrimaryButton
                ghost={false}
                text="新規支払メディア作成"
              />
            </span>
          </Link>
    )}
      />

      <div
        css={css`
            table th:nth-of-type(1) {
              width: 150px;
            }
            table th:nth-of-type(2) {
              width: 300px;
            }
            table th:nth-of-type(3) {
              width: 100px;
            }
            table th:nth-of-type(4) {
              width: 80px;
            }
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={mediaMasterList}
            isGlobalFilter={true}
          />
        </div>
      </div>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deletePaymentMediaOrg}
        title="確認メッセージ"
        content="削除します。よろしいですか？"
        submitText="削除"
        items={[]}
      />
      <ConfirmModal
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={deletePaymentMediaOrg}
        title="確認"
        content={confirmModalContent}
        items={[]}
      />
      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />
    </div>
  );
};

export default MediaMasterPage;
