import React, { useMemo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, SubActionButton, SecondaryButton } from "components/atoms/Button";
import { useWorkingDaysDomainConfigForm } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormField from "components/atoms/Form/FormField";
import { css } from "@emotion/core";
import WorkingDaysDomain from "domain/master/labor/workingDays";
import Modal from "components/molecules/Modal";
import { deleteWorkingDayById } from "api/workingDay";
import useToastNotification from "hooks/useToastNotification";
import ConfirmModal from "components/organismos/ConfirmModal";
import BlockUI from "components/molecules/BlockUi";
import OrganizationAddModal from "components/organismos/master/general/staff/OrganizationAddModal/OrganizationAddModal";

const PrescribedDaysConfigForm: React.FC = () => {
  const { workingDaysId } = useParams();
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    history,
    setOpenManagerAddModal,
    openManagerAddModal,
    addManager,
    deleteOrgDaysList,
    blocking,
    detailRole,
    // orgTreesOptions,
  } = useWorkingDaysDomainConfigForm(workingDaysId);
  const { successNotification, errorNotification } = useToastNotification();

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedPrescribed, setSelectedPrescribed] = useState(WorkingDaysDomain.generateInitial());
  const closeConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModalOpen(false);
  }, []);
  const openConfirmDeleteModal = useCallback((workingDay: WorkingDaysDomain) => {
    setConfirmDeleteModalOpen(true);
    setSelectedPrescribed(workingDay);
  }, []);

  const deletePrescribed = useCallback(() => {
    deleteWorkingDayById(selectedPrescribed.workingDaysId)
      .then(() => {
        setConfirmDeleteModalOpen(false);
        successNotification("削除しました。");
        history.push("/masterWorkingDays");
      })
      .catch((error) => {
        setConfirmDeleteModalOpen(false);
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          const listErr = error.response.data.errors;
          let stringErr = "";
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [errorNotification, selectedPrescribed.workingDaysId, successNotification, history]);

  const columns: Array<Column<{ id: { orgCode: string }; orgName: string }>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        Cell: (cell: {
          row: { isExpanded: boolean; original: { id: { orgCode: string }; orgName: string } };
          data: Array<{ id: { orgCode: string }; orgName: string }>;
        }) => {
          return detailRole.editable === 1 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FlexBox>
                <FlexBoxItem marginLeft="5px">
                  <SubActionButton
                    action={1}
                    text="削除"
                    onClick={() => {
                      deleteOrgDaysList(cell.row.original.id.orgCode);
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
          ) : null;
        },
      },
      {
        Header: "組織コード",
        accessor: "id.orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
    ],
    [deleteOrgDaysList, detailRole],
  );
  return (
    <>
      <BlockUI blocking={blocking}>
        <div style={{ marginLeft: "27px", width: "80px", paddingBottom: "10px", paddingTop: "30px" }}>
          {detailRole.editable === 1 && (
            <IconLabelButton onClick={() => setOpenManagerAddModal(true)} iconType="addCircle" text="追加" />
          )}
          <FormField>
            <OrganizationAddModal
              selectedOrgDaysList={formik.values.orgDaysList.map((item) => item.id.orgCode)}
              open={openManagerAddModal}
              openHandler={setOpenManagerAddModal}
              addOrganization={addManager}
            />
          </FormField>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {/* <VerticalLabelMutilSelectForm
          label="組織選択"
          name="orgDaysList"
          values={orgTreesOptions.length === 0 || (formik.values.orgDaysList.length === 1 && formik.values.orgDaysList[0].id.orgCode === '') ? undefined : formik.values.orgDaysList.map((orgObject) => {
            const selectedOrgCode = orgTreesOptions.find(
              (orgOption) => orgOption.value === orgObject.id.orgCode,
            );
            if (selectedOrgCode) {
              return selectedOrgCode;
            }
            return new Option();
          })}
          setValue={(val: string) => {
            formik.setFieldValue('orgDaysList', val.slice(0, -1).split(',').map((orgCode) => ({ id: { orgCode } })));
          }}
          options={orgTreesOptions}
          isMulti={true}
        /> */}
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <DataTable className="table-no-border-left-right" columns={columns} data={formik.values.orgDaysList} />
          </div>

          <div style={{ paddingTop: "50px", paddingBottom: "70px", display: "table", margin: "0 auto" }}>
            <FormSubmitArea>
              <FlexBox>
                {detailRole.editable === 1 && (
                  <FlexBoxItem customStyle={css({ marginRight: "15px" })}>
                    <Button customStyle={css("width: 170px;")} text="組織を割当" onClick={formik.handleSubmit} />
                  </FlexBoxItem>
                )}
                {(() => {
                  if (workingDaysId && detailRole.editable === 1) {
                    return (
                      <FlexBoxItem>
                        <SecondaryButton
                          customStyle={css("width: 170px;")}
                          text="削除"
                          onClick={() => {
                            openConfirmDeleteModal(formik.values);
                          }}
                        />
                      </FlexBoxItem>
                    );
                  }
                })()}
              </FlexBox>
              <div style={{ paddingTop: "5px", display: "table", margin: "0 auto" }}>
                <PrimaryButton
                  text="戻る"
                  onClick={() => {
                    history.replace("/masterWorkingDays");
                  }}
                  ghost={true}
                />
              </div>
            </FormSubmitArea>
          </div>
        </form>
        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="組織割当情報を更新"
          content="組織割当情報を更新します。よろしいですか？"
          submitText="更新する"
          onSubmit={formik.handleSubmit}
        />
        <Modal
          open={confirmDeleteModalOpen}
          closeHandler={closeConfirmDeleteModal}
          title="所定労働日数を削除"
          submitText="削除する"
          note="所定労働日数を削除します。よろしいですか？"
          onSubmit={deletePrescribed}
          typeSubmit="reject"
        >
          <div style={{ height: "20px" }}></div>
        </Modal>
      </BlockUI>
    </>
  );
};
export default PrescribedDaysConfigForm;
