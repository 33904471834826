import React, { SVGProps } from 'react';

const LogoDropdown: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="130" height="14" viewBox="0 0 130 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.46543 0.0722656C7.83428 0.0722656 10.572 0.504685 10.572 2.09029C10.572 2.73889 10.1156 3.31549 9.43125 3.31549C8.6708 3.31549 8.13848 2.66679 6.54151 2.66679C4.1841 2.66679 2.9674 4.54069 2.9674 6.77489C2.9674 8.93699 4.1841 10.7388 6.54151 10.7388C8.13848 10.7388 8.74688 10.0181 9.5834 10.0181C10.4198 10.0181 10.8001 10.8108 10.8001 11.2433C10.8001 12.973 7.91035 13.3333 6.54151 13.3333C2.6632 13.3333 0.00158964 10.4505 0.00158964 6.70279C-0.0744866 2.95509 2.58713 0.0722656 6.46543 0.0722656Z" fill="#007BC3"/>
    <path d="M15.4371 7.13539L11.863 2.37869C11.7108 2.16249 11.6348 1.87424 11.6348 1.58595C11.6348 0.865241 12.2432 0.288671 13.0036 0.288671C13.5359 0.288671 13.9162 0.504891 14.1443 0.865241L16.8819 4.61289L19.6196 0.721101C19.8477 0.360751 20.304 0.144531 20.7603 0.144531C21.5208 0.144531 22.1291 0.721101 22.1291 1.44181C22.1291 1.73009 22.0531 2.01839 21.9009 2.23459L18.3268 6.99129V11.82C18.3268 12.4687 17.9466 13.1173 16.8819 13.1173C15.8933 13.1173 15.4371 12.4687 15.4371 11.82V7.13539Z" fill="#007BC3"/>
    <path d="M23.4219 1.7297C23.4219 0.936922 24.0303 0.360352 24.8668 0.360352H27.9847C32.2432 0.360352 34.6766 2.9549 34.6766 6.9188C34.6766 10.6665 32.0911 13.1169 28.2128 13.1169H24.9428C24.3345 13.1169 23.4219 12.8286 23.4219 11.7476V1.7297ZM26.2355 10.6665H28.1367C30.5702 10.6665 31.7109 8.9368 31.7109 6.7747C31.7109 4.4684 30.5702 2.8108 27.9847 2.8108H26.2355V10.6665Z" fill="#007BC3"/>
    <path d="M39.0132 1.58575C39.3174 0.720895 40.0778 0.0722656 41.0665 0.0722656C42.0551 0.0722656 42.7394 0.720895 43.1197 1.58575L46.846 11.3874C46.998 11.6757 46.998 11.8919 46.998 12.0361C46.998 12.7568 46.3896 13.1892 45.7052 13.1892C44.9448 13.1892 44.4885 12.8288 44.3364 12.2523L43.8041 10.5946H38.5569L37.9485 12.2523C37.7965 12.8288 37.3402 13.1892 36.5798 13.1892C35.8193 13.1892 35.2109 12.6847 35.2109 11.964C35.2109 11.6757 35.287 11.4595 35.363 11.3874L39.0132 1.58575ZM39.2414 8.14419H42.8155L41.0665 2.88299L39.2414 8.14419Z" fill="#007BC3"/>
    <path d="M56.4273 1.87385C56.4273 2.5946 55.9711 3.1711 55.1346 3.1711C54.3741 3.1711 53.7657 2.5946 52.6251 2.5946C51.7886 2.5946 51.1041 3.027 51.1041 3.7477C51.1041 5.6215 57.4159 4.3963 57.4159 9.0809C57.4159 11.6755 55.2106 13.2611 52.549 13.2611C51.1041 13.2611 47.9102 12.9728 47.9102 11.2431C47.9102 10.5224 48.3665 9.9458 49.203 9.9458C50.1155 9.9458 51.1802 10.6665 52.3969 10.6665C53.6896 10.6665 54.298 10.0179 54.298 9.153C54.298 7.063 47.9862 8.2882 47.9862 4.108C47.9862 1.58556 50.1916 0 52.7011 0C53.7657 0.07207 56.4273 0.5045 56.4273 1.87385Z" fill="#007BC3"/>
    <path d="M62.1152 1.09969V12.9997H63.0121V8.40969H66.5996C68.9315 8.40969 70.6356 6.79469 70.6356 4.75469C70.6356 2.71469 68.9315 1.09969 66.5996 1.09969H62.1152ZM66.5996 1.94969C68.4831 1.94969 69.7387 3.13969 69.7387 4.75469C69.7387 6.36969 68.4831 7.55969 66.5996 7.55969H63.0121V1.94969H66.5996ZM74.4858 12.1497V7.21969H80.5846V6.36969H74.4858V1.94969H82.0196V1.09969H73.5889V12.9997H82.1989V12.1497H74.4858ZM95.8293 7.04969C95.8293 10.0247 93.4078 12.3197 90.3584 12.3197C87.309 12.3197 84.8875 10.0247 84.8875 7.04969C84.8875 4.07469 87.309 1.77969 90.3584 1.77969C93.4078 1.77969 95.8293 4.07469 95.8293 7.04969ZM96.7262 7.04969C96.7262 3.64969 93.8921 0.929688 90.3584 0.929688C86.8606 0.929688 83.9906 3.64969 83.9906 7.04969C83.9906 10.4497 86.8426 13.1697 90.3584 13.1697C93.8921 13.1697 96.7262 10.4497 96.7262 7.04969ZM99.7767 1.09969V12.9997H100.674V8.40969H104.261C106.593 8.40969 108.297 6.79469 108.297 4.75469C108.297 2.71469 106.593 1.09969 104.261 1.09969H99.7767ZM104.261 1.94969C106.144 1.94969 107.4 3.13969 107.4 4.75469C107.4 6.36969 106.144 7.55969 104.261 7.55969H100.674V1.94969H104.261ZM118.874 12.9997V12.1497H112.147V1.09969H111.25V12.9997H118.874ZM122.185 12.1497V7.21969H128.284V6.36969H122.185V1.94969H129.719V1.09969H121.288V12.9997H129.898V12.1497H122.185Z" fill="#22AEE5"/>
  </svg>

);

export default LogoDropdown;
