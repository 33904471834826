/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { RecorderButton, ICCardConnectButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import Icon from 'components/atoms/Icon';

const isIpad = window.matchMedia("(max-width: 1024px)").matches;
const styles = {
  formRecorder: css({
    textAlign: "center",
  }),
  dateForm: css({
    width: `${isIpad ? '' : '420px'}`,
    margin: "auto",
    fontWeight: 'bold'
  }),
  formContainer: css({
    marginTop: "15px",
    borderRadius: "3px",
    paddingBottom: isIpad ? "20px" : "50px",
    display: isIpad ? 'flex' : ''
  }),
  imageButton: css({
    width: "100%",
    textAlign: 'center',
    height: '80px',
  }),
  icContainer: css({
    width: `${isIpad ? '380px' : '600px'}`,
    height: '335px',
    margin: 'auto',
    border: '1px solid #DCDCDC',
    paddingTop: '10px',
    position: 'relative'
  }),
  icButton: css({
    position: 'absolute',
    top: '10px',
    right: '10px',  
  }),
  ipadTimeContainer: css({
    display: "flex",
    flexWrap: "wrap",
    alignSelf: "center",
    width: "300px",
    marginLeft: '40px'
  })
};

const timeCss = css`
  align-items: baseline;
  justify-content: space-between;
  margin: auto;
  width: ${isIpad ? 'auto' : '350px'};
  color: red;
  line-height: ${isIpad ? '80px' : '100px'};
  span:first-of-type {
    font-size: ${isIpad ? '64px' : '100px'};
    margin-right: 10px;
    font-weight: bold;
    color: ${grayScale.gray100};
  }
  span:last-of-type {
    font-size: 32px;
    color: ${grayScale.gray100};
  }
`;
const btnContainer = css`
  flex-wrap: wrap;
  width: 700px;
  justify-content: space-between;
  margin: 30px auto 30px auto;
  height: auto;
  button {
    border-radius: 3px;
    border: 0;
    height: 120px;
    font-size: 24px;
    height: 120px;
    margin-bottom: 20px;
  }
  button:hover {
    opacity: 0.9;
  }
`;

const ICCardRecorderUI: React.FC<{
  dataTimes: any,
  isConfirm: boolean,
  staffName: string,
  lastStampType: any,
  handleRecorder: any,
  detectCard: any,
  isConnectICCardUsb: any
}> = ({
  dataTimes,
  isConfirm,
  staffName,
  lastStampType,
  handleRecorder,
  detectCard,
  isConnectICCardUsb
}) => {

  return (
    <React.Fragment>
      <div css={styles.formContainer}>
         <div css={isIpad ? styles.ipadTimeContainer : {}}>
            {dataTimes.currentDay ? (
              <React.Fragment>
                <FlexBox customStyle={styles.dateForm}>
                  <BodyText size="lg" color={grayScale.gray100} bold={true}>
                    {dataTimes.currentDay}
                  </BodyText>
                </FlexBox>
                <FlexBox customStyle={timeCss}>
                  <BodyText>{dataTimes.timeNow}</BodyText>
                  <BodyText>{dataTimes.second}</BodyText>
                </FlexBox>
              </React.Fragment>
            ) : (
              <div
                style={{
                  fontSize: isIpad ? '30px' : '80px',
                  textAlign: 'center'
                }}
              >
                サーバ時間取得中・・・
              </div>
            )}
          </div>

        {!isConfirm && (
          <div css={styles.icContainer}>
            <FlexBox justifyContent="center">              
              <FlexBoxItem><Icon type="iCCard" color="" size="250px 280px"/></FlexBoxItem>
              <FlexBoxItem customStyle={styles.icButton}>
                <ICCardConnectButton
                  disabled={isConnectICCardUsb}
                  onClick={() => {
                    detectCard();
                  }}
                />
              </FlexBoxItem>
            </FlexBox>
            <FlexBox justifyContent="center">
              <BodyText bold={true} size="re" customStyle={css({paddingTop: '10px', textAlign: 'center'})} >
                {
                  isConnectICCardUsb 
                    ? "カードリーダにカードをかざしてください" 
                    : <React.Fragment>
                        カードリーダーが接続されていません<br/>
                        「Pasoriと接続」ボタンを押して、接続してください。
                      </React.Fragment>

                }
              </BodyText>
            </FlexBox>
          </div>
        )}
        {isConfirm && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <BodyText color={grayScale.gray100} size="xxl" bold={true}>
              {staffName}
              {
                (lastStampType === -1 || lastStampType === 3) && "さん、出勤打刻を押してください。"
              }
              {
                (lastStampType === 0 || lastStampType === 2) && "さん、退勤打刻または休憩開始を押してください。"
              }
              {
                lastStampType === 1 && "さん、休憩終了を押してください。"
              }
            </BodyText>
          </div>
        )}
        {isConfirm && (
          <React.Fragment>
            <FlexBox customStyle={btnContainer}>
              {(lastStampType === -1 || lastStampType === 3) && (
                <FlexBoxItem basis="48%">
                  <RecorderButton
                    text="出勤"
                    onClick={() => handleRecorder(0)}
                    icon="checkInOffice"
                    background="#007BC3"
                  />
                </FlexBoxItem>
              )}
              {(lastStampType === 0 || lastStampType === 2) && (
                <FlexBoxItem basis="48%">
                  <RecorderButton
                    text="退勤"
                    onClick={() => handleRecorder(3)}
                    icon="checkOut"
                    background="#666666"
                  />
                </FlexBoxItem>
              )}

              {(lastStampType === 0 || lastStampType === 2) && (
                <RecorderButton
                  text="休憩開始"
                  onClick={() => handleRecorder(1)}
                  icon="breakStart"
                  background="#00B172"
                />
              )}

              {lastStampType === 1 && (
                <FlexBoxItem basis="48%">
                  <RecorderButton
                    text="休憩終了"
                    onClick={() => handleRecorder(2)}
                    icon="breakEnd"
                    background="#8B2E9C"
                  />
                </FlexBoxItem>
              )}
            </FlexBox>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ICCardRecorderUI;
