/** @jsx jsx */
import React, { useState, useEffect } from "react";

import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import FormLabel from "components/atoms/Form/FormLabel";
import TextForm from "components/molecules/TextForm";
import FormHelp from "components/atoms/Form/FormHelp";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import HourInput from "components/atoms/Form/HourInput";
import FlexBox from "components/atoms/FlexBox";
import FormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import { gridCol, grayScale } from "components/styles";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import moment from "moment";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const styles = {
  help: css({
    color: "red",
    bottom: "-13px",
    left: "10px",
    position: "relative",
  }),
  span: css({
    margin: "0px 10px",
    position: "relative",
    top: "-2px",
  }),
  addon: css({
    color: grayScale.gray100,
    padding: "7px",
  }),
};

const EmploymentBasicSettings: React.FC<{
  formik: any;
}> = ({ formik }) => {
  const hourStartDefault = formik.values.midnightStartTimeMoment.format("HH") || "00:00";
  const timeStartDefault = formik.values.midnightStartTimeMoment.format("mm") || "00:00";
  const hourEndDefault = formik.values.midnightEndTimeMoment.format("HH") || "00:00";
  const timeEndDefault = formik.values.midnightEndTimeMoment.format("mm") || "00:00";

  const [contractWorkHourDayHours, setContractWorkHourDayHours] = useState<any>("00");
  const [contractWorkHourDayMinutes, setContractWorkHourDayMinutes] = useState<any>("00");
  // const contractWorkHourDayMinutes = moment().startOf('day').add(formik.values.contractWorkHourDay,'minutes').format('mm') || '00';
  useEffect(() => {
    setContractWorkHourDayHours(
      moment()
        .startOf("day")
        .add(formik.values.contractWorkHourDay, "minutes")
        .format("HH") || "00",
    );
    setContractWorkHourDayMinutes(
      moment()
        .startOf("day")
        .add(formik.values.contractWorkHourDay, "minutes")
        .format("mm") || "00",
    );
  }, [formik.values.contractWorkHourDay]);

  useEffect(() => {
    formik.setFieldValue("contractWorkHourDayHours", contractWorkHourDayHours);
    formik.setFieldValue("contractWorkHourDayMinutes", contractWorkHourDayMinutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractWorkHourDayHours, contractWorkHourDayMinutes]);

  return (
    <React.Fragment>
      <FormTitle title="基本設定" bold={true} customStyle={css({ marginLeft: "0" })} />
      <div>
        <FormField>
          <TextForm
            name="employmentCode"
            label="雇用形態コード"
            required={true}
            value={formik.values.employmentCode}
            onChange={formik.handleChange}
            placeHolder="雇用形態コードを入力して下さい"
            errorMsg={formik.errors.employmentCode}
          />
        </FormField>
        <FormField>
          <TextForm
            name="employmentName"
            label="雇用形態名"
            required={true}
            value={formik.values.employmentName}
            onChange={formik.handleChange}
            placeHolder="変形労働外"
            errorMsg={formik.errors.employmentName}
          />
        </FormField>
        {/*
          <FormField>
            <div style={{ marginRight: '12px' }}>
              <DatePickerForm
                required={true}
                dateFormat="yyyy/MM"
                label="雇用形態の適用開始年月"
                showMonthYearPicker={true}
                isFullWidth={true}
                date={formik.values.applyDate}
                changeDate={(date: Date) => formik.setFieldValue('applyDate', date)}
              />
              <div style={{ marginTop: '4px' }}>
                <span style={{ color: 'red', marginTop: '4px' }}>{formik.errors.applyDate}</span>
              </div>
            </div>
          </FormField> */}
        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid06 }}>
            <FormLayout
              label={<FormLabel label="営業日切替時刻" />}
              input={
                <FlexBox>
                  <HourInput
                    name="closingHour"
                    value={formik.values.closingHour}
                    label="時"
                    min={0}
                    max={23}
                    onChange={formik.handleChange}
                  />
                </FlexBox>
              }
            />
          </div>
        </FormField>
        <FormField>
          <FlexBox>
            <FlexBoxItem width="90px">
              <FormLayout
                label={<FormLabel label="勤怠の締め日" />}
                input={
                  <FlexBox>
                    <HourInput
                      name="closingDate"
                      value={formik.values.closingDate}
                      label="日"
                      min={0}
                      max={31}
                      onChange={formik.handleChange}
                    />
                  </FlexBox>
                }
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span>
                <FormHelp content="0を入力すると月末という扱いになります。" customStyle={styles.help} />
              </span>
            </FlexBoxItem>
          </FlexBox>
        </FormField>
        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="週の締め曜日"
              name="closingDayOfWeek"
              value={String(formik.values.closingDayOfWeek)}
              setValue={(val: string) => formik.setFieldValue("closingDayOfWeek", val)}
              options={[
                { label: "日曜日", value: "0" },
                { label: "月曜日", value: "1" },
                { label: "火曜日", value: "2" },
                { label: "水曜日", value: "3" },
                { label: "木曜日", value: "4" },
                { label: "金曜日", value: "5" },
                { label: "土曜日", value: "6" },
              ]}
              isSearchable={false}
            />
          </div>
        </FormField>
        <FormField displayBlock={true}>
          <FormLabel label="深夜勤務時間帯" />
          <FlexBox>
            <FlexBoxItem width="70px">
              <VerticalLabelSelectForm
                label=""
                name="midnightStartTime"
                value={String(hourStartDefault)}
                setValue={(val: string) => {
                  formik.setFieldValue("midnightStartTime", val ? val + ":" + timeStartDefault : "00:00");
                }}
                options={hourFormOptions}
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span css={styles.span}>:</span>
            </FlexBoxItem>
            <FlexBoxItem width="70px">
              <VerticalLabelSelectForm
                label=""
                name="midnightStartTime2"
                value={String(timeStartDefault)}
                setValue={(val: string) => {
                  formik.setFieldValue("midnightStartTime", val ? hourStartDefault + ":" + val : "00:00");
                }}
                options={timeFormOptions}
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span css={styles.span}>〜</span>
            </FlexBoxItem>
            <FlexBoxItem width="70px">
              <VerticalLabelSelectForm
                label=""
                name="midnightEndTime"
                value={String(hourEndDefault)}
                setValue={(val: string) => {
                  formik.setFieldValue("midnightEndTime", val ? val + ":" + timeEndDefault : "00:00");
                }}
                options={hourFormOptions}
              />
            </FlexBoxItem>
            <span css={styles.span}>:</span>
            <FlexBoxItem width="70px">
              <VerticalLabelSelectForm
                label=""
                name="midnightEndTime"
                value={String(timeEndDefault)}
                setValue={(val: string) => {
                  formik.setFieldValue("midnightEndTime", val ? hourEndDefault + ":" + val : "00:00");
                }}
                options={timeFormOptions}
              />
            </FlexBoxItem>
          </FlexBox>
        </FormField>

        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid06 }}>
            <FormLayout
              label={<FormLabel label="日の契約労働時間" />}
              input={
                <FlexBox>
                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="contractWorkHourDayHours"
                      value={String(contractWorkHourDayHours)}
                      setValue={(val: string) => {
                        setContractWorkHourDayHours(val);
                        // formik.setFieldValue('contractWorkHourDay', parseInt(contractWorkHourDayHours)*60 + parseInt(contractWorkHourDayMinutes));
                      }}
                      options={hourFormOptions}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}>時間</BodyText>
                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="contractWorkHourDayMinutes"
                      value={String(contractWorkHourDayMinutes)}
                      setValue={(val: string) => {
                        setContractWorkHourDayMinutes(val);
                        // formik.setFieldValue('contractWorkHourDay', parseInt(contractWorkHourDayHours)*60 + parseInt(contractWorkHourDayMinutes));
                      }}
                      options={timeFormOptions}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}>分</BodyText>
                </FlexBox>
              }
            />
          </div>
        </FormField>

        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="週の契約労働日数"
              name="contractWorkDateNumWeek"
              value={String(formik.values.contractWorkDateNumWeek)}
              setValue={(val: string) => formik.setFieldValue("contractWorkDateNumWeek", val)}
              options={[
                { label: "5日以上", value: "5" },
                { label: "4日", value: "4" },
                { label: "3日", value: "3" },
                { label: "2日", value: "2" },
                { label: "1日", value: "1" },
              ]}
            />
          </div>
        </FormField>

        {<FormField displayBlock={true}>
            <div style={{ marginRight: '12px', width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="労働形態"
                name="workingForm"
                value={String(formik.values.workingForm)}
                setValue={(val: string) => formik.setFieldValue('workingForm', val)}
                options={[
                  { label: 'フルタイム', value: '0' },
                  { label: 'パートタイム', value: '1' },
                ]}
              />
            </div>
          </FormField>}
        {/* <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelMutilSelectForm
                label="使用する業務"
                name="businesses"
                placeholderCus={'業務内容を選択してください'}
                values={formik.values.businesses.map(
                  (business: AttendBusiness) => businessOptions.find(
                    (item) => business.businessId === item.value,
                  ),
                )}
                setValue={(val: any) => {
                  if (val !== '') {
                    formik.setFieldValue('businesses', val.slice(0, -1).split(',').map(
                      (id: string) => ({ businessId: id }),
                    ));
                  } else {
                    formik.setFieldValue('businesses', []);
                  }
                }}
                options={businessOptions}
                note=""
                isMulti={true}
              />
            </div>
          </FormField> */}
      </div>
    </React.Fragment>
  );
};

export default EmploymentBasicSettings;
