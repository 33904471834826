/** @jsx jsx */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { jsx, SerializedStyles } from "@emotion/core";

import Icon, { IconType } from "components/atoms/Icon";
import ReactTooltip from "react-tooltip";

export type IconTooltipProps = {
  iconType: IconType;
  text: string;
  color: string;
  customCss?: SerializedStyles;
  wrapCustomCss?: SerializedStyles;
  place?: "top" | "right" | "bottom" | "left" | undefined;
};

/**
 * ツールチップのついたアイコン
 */
const IconTooltip: React.FC<IconTooltipProps> = ({
  iconType,
  text,
  color,
  customCss,
  place = "top",
  wrapCustomCss,
}) => {
  const rand = 1 + Math.random() * (1 - 10000);
  return (
    <div className="tooltip" css={wrapCustomCss}>
      <a data-tip="true" css={customCss} data-for={`tooltip${rand}`}>
        <Icon type={iconType} color={color} />
      </a>
      <ReactTooltip id={`tooltip${rand}`} place={place}>
        {text}
      </ReactTooltip>
    </div>
  );
};

export default IconTooltip;
