/** @jsx jsx */
import React from "react";
import BodyText from "components/atoms/BodyText";
import { css, jsx } from "@emotion/core";
import Table from "components/molecules/Table";
import { statusApproval } from "components/styles";
import moment from "moment";
import TextTooltip from "../TextTooltip";
import { sortBy } from "lodash";
import LastCellFormat from "./LastCellFormat";

const styleTable = css`
  th {
    width: 80px !important;
  }
  td {
    text-align: center;
  }
`;

const character = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];

const HistoryApprovalTable: React.FC<{ historyData?: any, applicationStatus?: number }> = ({
  historyData,
  applicationStatus
}) => {
  const listLevel = Array.from(
    new Set(historyData?.map((item: any) => item.approvalLevel))
  ); 

  return (
    <div css={styleTable}>
      <Table customStyle={css({ width: "100%" })}>
        <thead>
          <tr>
            <Table.HeaderCell />
            <Table.HeaderCell>組織</Table.HeaderCell>
            <Table.HeaderCell>役職</Table.HeaderCell>
            <Table.HeaderCell>氏名</Table.HeaderCell>
            <Table.HeaderCell>承認日時</Table.HeaderCell>
            <Table.HeaderCell>ステータス</Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {listLevel.map((level: any) => {

            let listAtLevel = historyData.filter((stamp: any) => {
              return stamp.approvalLevel === level;
            }); 
            let listNonApproval = historyData.filter((stamp: any) => {
              return stamp.approvalLevel === level && stamp.status === 3;
            }); 

            let priorStatuses = listAtLevel.filter((item:any) => 
              {
                if (item.status == 1 || item.status == 2) return item;
              }
            );
            let priorStatus=applicationStatus;
            
            if(priorStatuses && priorStatuses.length>0){
              priorStatus=priorStatuses[0].status;
            }

            let rejectReasonMessage = '';
            return (                            
              sortBy( listAtLevel, 'rejectReason' ).map((item:any, index:Number)=>{
                rejectReasonMessage = item.rejectReason;
                return(
                  <tr>
                    {
                      (index == 0 && listAtLevel.length>1)?
                      <Table.Cell rowSpan={listAtLevel.length} >{level !== 99 ? `第${character[level - 1]}承認者` : '管理者'}</Table.Cell>
                      :listAtLevel.length<=1?
                      <Table.Cell>{level !== 99 ? `第${character[level - 1]}承認者` : '管理者'}</Table.Cell>
                      :""
                    }
                    <Table.Cell>{item.orgName&&item.orgName!=""?item.orgName:"-"}</Table.Cell>
                    <Table.Cell>{item.executiveName&&item.executiveName!=""?item.executiveName:"-"}</Table.Cell>
                    <Table.Cell>{item.staffName&&item.staffName!=""?item.staffName:"-"}</Table.Cell>
                    <Table.Cell>
                      {item.approvalDate
                        ? moment(item.approvalDate).format(
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        : "-"}
                    </Table.Cell>
                    <LastCellFormat
                      index={index}
                      lengthListAtLevel={listAtLevel.length}
                      statusApproval={statusApproval}
                      item={item}
                      priorStatus={priorStatus}
                      applicationStatus={applicationStatus}
                    /> 
                </tr>
                );
              })
            )
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default HistoryApprovalTable;
