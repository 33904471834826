/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";

import productColor, { utilityColor, iconColor, grayScale } from "components/styles";
import Icon from "components/atoms/Icon";
import BodyText from "components/atoms/BodyText";
import { notMinScreen } from "utility/mediaQueries";
import useIsMobile from "hooks/useIsMobile";

const styles = {
  label: css({
    alignItems: "center",
    display: "inline-flex",
    cursor: "pointer",
  }),
  icon: css({
    paddingRight: "9px",
  }),
  input: css({
    display: "none",
  }),
  labelTxt: css({
    marginLeft: "5px",
    color: grayScale.gray100,
    whiteSpace: "nowrap",
  }),
  labelTxtDisable: css({
    color: grayScale.gray50,
    whiteSpace: "nowrap",
    [notMinScreen("md")]: {
      marginLeft: "5px",
    },
  }),
  cursorDisabled: css({
    cursor: "not-allowed",
  }),
};

const Checkbox: React.FC<{
  id: string;
  name: string;
  label?: string;
  value: string;
  checked: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isCheckBoxTable?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customStyleLabel?: SerializedStyles;
  customStyle?: SerializedStyles;
  className?: string;
  isVertical?: boolean;
}> = ({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  readOnly = false,
  disabled = false,
  isCheckBoxTable = false,
  customStyleLabel,
  customStyle,
  className,
  isVertical = false,
}) => {
  return (
    <React.Fragment>
      <label
        css={css(
          [
            styles.label,
            disabled || readOnly ? styles.cursorDisabled : {},
            isVertical ? { flexDirection: "column" } : {},
          ],
          customStyle,
        )}
        htmlFor={id}
      >
        {checked ? (
          <Icon
            type="checkboxOn"
            color={isCheckBoxTable ? grayScale.gray50 : utilityColor.black}
            disabled={disabled}
          />
        ) : (
          <Icon
            type="checkboxOff"
            color={readOnly ? iconColor.gray : isCheckBoxTable ? grayScale.gray50 : utilityColor.black}
            disabled={disabled}
          />
        )}
        <input
          css={styles.input}
          type="checkbox"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={readOnly ? () => {} : onChange}
          readOnly={readOnly}
          disabled={disabled}
          className={className}
        />
        {label && (
          <BodyText size="re" color={readOnly ? iconColor.gray : productColor.primary}>
            <span
              css={css([
                disabled ? styles.labelTxtDisable : styles.labelTxt,
                customStyleLabel,
                isVertical ? { marginLeft: 0 } : {},
              ])}
            >
              {label}
            </span>
          </BodyText>
        )}
      </label>
    </React.Fragment>
  );
};

export default Checkbox;
