import { useState, useEffect, useRef, RefObject } from 'react';

/**
 * コンポーネント外部クリック時に非表示にする
 *
 * @param initialIsVisible 初期表示
 */
const useComponentVisible = (initialIsVisible: boolean) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef() as RefObject<any>;

  const handleClickOutside = (event: any) => {
    if (!ref.current) {
      return;
    }
    // @ts-ignore
    if (!(ref.current.contains(event.target))) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

export default useComponentVisible;
