import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PageTitle from 'components/atoms/PageTitle';
import PaymentImportCreateForm from 'components/organismos/master/sales/PaymentImportCreateForm';

const PaymentImportCreatePage: React.FC = () => (
  <SidebarTemplate>
    <PageTitle title="支払メディア取込設定" />
    <PaymentImportCreateForm />

  </SidebarTemplate>
);
export default PaymentImportCreatePage;
