import ApiClient from './ApiClient';

export const getAgreement36Application = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/36Agreements/overtime/${companyCode}`, {}, params);
  return response.data;
};

export const postAgreement36Application = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v2/36Agreements/overtime/${companyCode}`, {}, params);
  return response.data;
};

export default {
  getAgreement36Application,
  postAgreement36Application,
};
