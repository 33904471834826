import React from 'react';

import ManagerDomain from 'domain/manager';

import OrganizationManagerList from 'components/organismos/organization/OrganizationManagerList';
import OrganizationManagerAddModal from 'components/organismos/organization/OrganizationManagerAddModal';

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useManagerListHandler, useStaffAddModal } from './hooks';
import { css } from '@emotion/core';

const OrganizationManagerEditForm: React.FC<{
  managerList: Array<ManagerDomain>|null;
  seniorManagerList: Array<ManagerDomain>|null;
  setManagerList: (arg: Array<ManagerDomain>) => void;
  setSeniorManagerList: (arg: Array<ManagerDomain>) => void;
  isEditMode?: boolean;
  isEditable?: number;
}> = ({
  managerList, seniorManagerList,
  setManagerList, setSeniorManagerList,
  isEditMode = false,
  isEditable
}) => {
  const {
    setManager, 
    // setSeniorManager, 
    deleteManager, 
    // deleteSeniorManager, 
    addManager,
    addSeniorManager, 
    onSubmit,
    confirmModalOpen, closeConfirmModal,
  } = useManagerListHandler(managerList, setManagerList, seniorManagerList, setSeniorManagerList,
    isEditMode);
  const modal = useStaffAddModal();

  return (
    <>
      <OrganizationManagerList
        prefix="マネージャー"
        managerList={managerList}
        setManager={setManager}
        deleteManager={deleteManager}
        openModal={() => modal.setOpenManagerAddModal(true)}
      />

      {/* マネージャーの追加モーダル */}
      <OrganizationManagerAddModal
        open={modal.openManagerAddModal}
        openHandler={modal.setOpenManagerAddModal}
        addManagerList={addManager}
      />

      {/* <LableTitle title="シニアマネージャー管理情報" customStyle={{ padding: '30px 0 0 60px' }} />
      <OrganizationManagerList
        prefix="シニアマネージャー"
        managerList={seniorManagerList}
        setManager={setSeniorManager}
        deleteManager={deleteSeniorManager}
        openModal={() => modal.setOpenSeniorManagerAddModal(true)}
      /> */}

      {/* シニアマネージャーの追加モーダル */}
      <OrganizationManagerAddModal
        open={modal.openSeniorManagerAddModal}
        openHandler={modal.setOpenSeniorManagerAddModal}
        addManagerList={addSeniorManager}
      />

      <FormSubmitArea customStyle={css({ textAlign: 'center' })}>
        <div>
          <Button
            customStyle={css({ marginRight: '10px' })}
            text={isEditMode ? '更新' : '登録'}
            onClick={onSubmit}
            minWidth="160px"
          />
          <Link to="/organizations">
            <Button
              text='一覧へ戻る'
              minWidth="160px"
            />
          </Link>
        </div>
          <Button
            text="キャンセル"
            ghost={true}
            onClick={ () => { window.location.reload() } }
            minWidth="160px"
          />
      </FormSubmitArea>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={onSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />
    </>
  );
};

export default OrganizationManagerEditForm;
