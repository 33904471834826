import moment from "moment";

import {
  Organization,
  Manager,
  EditManager,
  OrganizationManagerBasic,
  OrganizationSalaryBasic,
  OrganizationBasic,
} from "api/organization";
import { SpecialSalaryItem } from "domain/master/labor/organizationSpecialSalary";

export enum OrganizationCategory {
  COMPANY = 0,
  DEPARTMENT = 1,
  STORE = 2,
}
export enum OrganizationCategoryLabel {
  "オフィス" = 0,
  "部署" = 1,
  "組織" = 2,
}

export type StoreState = false | true;

export default class OrganizationDomain {
  private rawData: Organization;

  constructor(rawData: Organization) {
    // 日付のフォーマットを変換しておく
    this.rawData = {
      ...rawData,
      id: {
        ...rawData.id,
        applyStartDate: convertDateToStringYYYYMMDD(rawData.id.applyStartDate),
      },
      // applyEndDate: rawData.applyEndDate && convertDateToStringYYYYMMDD(rawData.applyEndDate),
      // createDate: rawData.createDate && convertDateToStringYYYYMMDD(rawData.createDate),
    };
  }

  static generateInitial(): OrganizationDomain {
    return new OrganizationDomain({
      id: {
        orgCode: "",
        applyStartDate: moment().format("YYYY/MM/DD"),
      },
      orgName: "",
      orgNameKana: "",
      category: 2,
      managerSetting: undefined,
      orgSpecialList: undefined,
      closeFlag: false,
      areaId: "",
      prefectureCode: "",
      parentOrgCode: "",
      employmentId: "",
      workingDaysId: "",
      businessCategoryId: "",
      posCode: "",
      numberOfSeat: 0,
      floorSpace: 0,
      dispStartHour: 0,
      dispHour: 0,
    });
  }

  getRawData(): Organization {
    return this.rawData;
  }

  getBasicPostData(): OrganizationBasic {
    return {
      id: {
        orgCode: this.rawData.id.orgCode,
        applyStartDate: this.rawData.id.applyStartDate,
      },
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      parentOrgCode: this.rawData.parentOrgCode || "",
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      dispStartHour: Number(this.rawData.dispStartHour),
      dispHour: this.rawData.dispHour,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
    };
  }

  getManagersPostData(managerList: Array<Manager>, seniorManagerList: Array<Manager>): OrganizationManagerBasic {
    // TODo
    // const postRawData = this.getBasicPostData();
    return {
      // ...postRawData,
      id: {
        orgCode: this.rawData.id.orgCode,
        applyStartDate: this.rawData.id.applyStartDate,
      },
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      ...OrganizationDomain.convertToEditManager(this.orgCode, managerList, seniorManagerList),
    };
  }

  getSpecialSalaryPostData(editSpecialList: SpecialSalaryItem[]): OrganizationSalaryBasic {
    return {
      id: this.rawData.id,
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      editSpecialList: editSpecialList,
    };
  }

  clearOrgSpecialList() {
    this.rawData.orgSpecialList = [];
  }

  static convertToEditManager(
    orgCode: string,
    managerList: Array<Manager>,
    seniorManagerList: Array<Manager>,
  ): { editManagerList: Array<EditManager>; editSeniorManagerList: Array<EditManager> } {
    return {
      editManagerList: managerList.map(
        (manager): EditManager => ({
          orgCode,
          staffCode: manager.staffCode,
          managerType: "0",
          hireDateStr: manager.hireDate ? moment(manager.hireDate).format("YYYY/MM/DD") : "",
          retirementDateStr: manager.retirementDate ? moment(manager.retirementDate).format("YYYY/MM/DD") : "",
          startDateStr: moment(manager.startDate).format("YYYY/MM/DD"),
          endDateStr: manager.endDate ? moment(manager.endDate).format("YYYY/MM/DD") : "",
        }),
      ),
      editSeniorManagerList: seniorManagerList.map(
        (manager): EditManager => ({
          orgCode,
          staffCode: manager.staffCode,
          managerType: "1",
          hireDateStr: manager.hireDate ? moment(manager.hireDate).format("YYYY/MM/DD") : "",
          retirementDateStr: manager.retirementDate ? moment(manager.retirementDate).format("YYYY/MM/DD") : "",
          startDateStr: moment(manager.startDate).format("YYYY/MM/DD"),
          endDateStr: manager.endDate ? moment(manager.endDate).format("YYYY/MM/DD") : "",
        }),
      ),
    };
  }

  get orgCode(): string {
    return this.rawData.id.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.id.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get orgNameKana(): string {
    return this.rawData.orgNameKana;
  }

  set orgNameKana(orgNameKana: string) {
    this.rawData.orgNameKana = orgNameKana;
  }

  get category(): number {
    return this.rawData.category;
  }

  get categoryLabel(): string {
    return OrganizationCategoryLabel[this.rawData.category];
  }

  set category(category: number) {
    this.rawData.category = Number(category);
  }

  get managerSetting(): any {
    return this.rawData.managerSetting;
  }

  get orgSpecialList(): any {
    return this.rawData.orgSpecialList;
  }

  get applyStartDate(): Date {
    return moment(this.rawData.id.applyStartDate).toDate();
  }

  get applyStartDateText(): string {
    return this.rawData.id.applyStartDate;
  }

  set applyStartDate(date: Date) {
    this.rawData.id.applyStartDate = moment(date).format("YYYY/MM/DD");
  }

  // get applyEndDate(): Date {
  //   return moment(this.rawData.applyEndDate).toDate();
  // }

  // set applyEndDate(date: Date) {
  //   this.rawData.applyEndDate = moment(date).format('YYYY/MM/DD');
  // }

  get dispStartHour(): number {
    return this.rawData.dispStartHour;
  }

  set dispStartHour(dispStartHour: number) {
    this.rawData.dispStartHour = dispStartHour;
  }

  get dispHour(): number {
    return this.rawData.dispHour;
  }

  set dispHour(dispHour: number) {
    this.rawData.dispHour = dispHour;
  }

  get closeFlag(): StoreState {
    return this.rawData.closeFlag ? true : false;
  }

  set closeFlag(closeFlag: StoreState) {
    this.rawData.closeFlag = closeFlag === true;
  }

  get closeFlagLabel(): string {
    return this.rawData.closeFlag ? "廃止" : "設立";
  }

  get areaId(): string {
    return this.rawData.areaId || "";
  }

  set areaId(areaId: string) {
    this.rawData.areaId = areaId;
  }

  get prefectureCode(): string {
    return this.rawData.prefectureCode || "";
  }

  set prefectureCode(prefectureCode: string) {
    this.rawData.prefectureCode = prefectureCode;
  }

  get parentOrgCode(): string {
    return this.rawData.parentOrgCode || "";
  }

  set parentOrgCode(parentOrgCode: string) {
    this.rawData.parentOrgCode = parentOrgCode;
  }

  get employmentId(): string {
    return this.rawData.employmentId || "";
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get workingDaysId(): string {
    return this.rawData.workingDaysId || "";
  }

  set workingDaysId(workingDaysId: string) {
    this.rawData.workingDaysId = workingDaysId;
  }

  get businessCategoryId(): string {
    return this.rawData.businessCategoryId || "";
  }

  set businessCategoryId(businessCategoryId: string) {
    this.rawData.businessCategoryId = businessCategoryId;
  }

  get posCode(): string {
    return this.rawData.posCode || "";
  }

  set posCode(posCode: string) {
    this.rawData.posCode = posCode;
  }

  get numberOfSeat(): string {
    return this.rawData.numberOfSeat ? String(this.rawData.numberOfSeat) : "";
  }

  set numberOfSeat(numberOfSeat: string) {
    this.rawData.numberOfSeat = Number(numberOfSeat);
  }

  get floorSpace(): string {
    return this.rawData.floorSpace ? String(this.rawData.floorSpace) : "";
  }

  set floorSpace(floorSpace: string) {
    this.rawData.floorSpace = Number(floorSpace);
  }
}

const convertDateToStringYYYYMMDD = (dateStr?: string) => {
  if (!dateStr) {
    return "";
  }
  return moment(dateStr).format("YYYY/MM/DD");
};
