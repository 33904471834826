/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import PrimaryButton from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import BodyText from "components/atoms/BodyText";
import BlockUI from "components/molecules/BlockUi";
import SidebarTemplate from "components/templates/SidebarTemplate";
import MobileContainer from "./MobileContainer";
import ActionBar from "./ActionBar";
import { useSchedulePage } from "./hooks";
import ScheduleMobileCalendar from "components/organismos/master/attend/Schedule/ScheduleMobileCalendar";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import { ScheduleStatus, ScheduleStatusColor } from "api/schedule";

const styles = {
  captionWrapper: css({
    justifyContent: "space-around",
    margin: "1rem 0",
  }),
  captionColor: (color: string, border?: string) =>
    css({
      background: color,
      width: "18px",
      height: "18px",
      border: `1px solid ${border || color}`,
    }),
  calendar: (isHasActionBar: boolean) =>
    css({
      ["& .rbc-date-cell"]: {
        ["& button"]: {
          ["& div"]: {
            height: "40px !important",
            width: "40px !important",
            lineHeight: "40px !important",
            borderRadius: "0 !important",
          },
        },
      },
      ["& .rbc-event"]: {
        borderRadius: "0 !important",
      },
      marginBottom: isHasActionBar ? 221 : 0,
    }),
};

const captionBar = [
  { name: "実績", color: ScheduleStatusColor[ScheduleStatus.ACTUAL], border: "" },
  { name: "確定", color: ScheduleStatusColor[ScheduleStatus.APPROVED], border: "" },
  { name: "未確定", color: ScheduleStatusColor[ScheduleStatus.NOT_APPROVED], border: "" },
  { name: "申請中", color: ScheduleStatusColor[ScheduleStatus.CHANGE_REQUEST_IN_PROGRESS], border: "" },
  { name: "休暇", color: ScheduleStatusColor[ScheduleStatus.DAY_OFF], border: "" },
  { name: "未提出", color: ScheduleStatusColor[ScheduleStatus.UNSUBMITED], border: "#0D4796" },
];

const ScheduleMobilePage: React.FC = () => {
  const { state, setter } = useSchedulePage();
  const [isHasActionBar, setIsHasActionBar] = useState(false);

  /**
   * FOR MOBILE
   */
  return (
    <SidebarTemplate pageTitle="マイシフト">
      <BlockUI blocking={state.isLoading}>
        <MobileContainer>
          <FlexBox customStyle={css({ justifyContent: "space-between" })}>
            <FlexBoxItem
              customStyle={css({
                margin: "0 24px",
              })}
            >
              <CalendarMonth
                date={state.datePickerValue}
                setDate={(date) => {
                  setter.setDatePickerValue(date);
                  setter.setDate(date);
                }}
                openActionChevron
              />
            </FlexBoxItem>

            <PrimaryButton text="提出" minWidth="150px" onClick={setter.onSubmitHopeShifts} />
          </FlexBox>

          <div
            css={css({
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: 4,
              margin: "8px 0",
            })}
          >
            <BodyText size="md" customStyle={css({ marginRight: 16 })}>
              合計勤務予定時間
            </BodyText>
            <BodyText size="xl2" bold={true}>
              {state.operationInfo.monthTotalworkTimeHour || 0}
            </BodyText>
            <BodyText size="md">時間</BodyText>
            <BodyText size="xl2" bold={true}>
              {state.operationInfo.monthTotalworkTimeMin || 0}
            </BodyText>
            <BodyText size="md">分</BodyText>
          </div>

          <FlexBox customStyle={styles.captionWrapper}>
            {captionBar.map((item, index) => (
              <FlexBox key={index.toString()}>
                <div css={styles.captionColor(item.color, item.border)} />
                <BodyText size="sm">
                  &nbsp;
                  {item.name}
                </BodyText>
              </FlexBox>
            ))}
          </FlexBox>

          <div css={styles.calendar(isHasActionBar)}>
            <ScheduleMobileCalendar
              events={state.events}
              date={state.date}
              datePickerValue={state.datePickerValue}
              setDate={setter.setDate}
              setDatePickerValue={setter.setDatePickerValue}
              setIsHasActionBar={setIsHasActionBar}
            />
          </div>
          {isHasActionBar && (
            <ActionBar
              setSelectedHopeShift={setter.setSelectedHopeShift}
              shiftPatternList={state.shiftPatternList}
              selectedHopeShift={state.selectedHopeShift}
              shiftPatternOptions={state.shiftPatternOptions}
              hopeShiftList={state.hopeShiftList}
              setIsHasActionBar={setIsHasActionBar}
              state={state}
              setter={setter}
            />
          )}
        </MobileContainer>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default ScheduleMobilePage;
