import reducer from './reducer';
import * as icCardActions from './actions';

export interface ICCardStateType {
  isConnectICCardUsb: boolean;
  disabledDetect: boolean;
  deviceICCard: USBDevice|null;
  cardId: string;
}

export {
  icCardActions,
};

export default reducer;
