/** @jsx jsx */
import React, { useState } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';
import { utilityColor } from 'components/styles';
import DataTable from 'components/organismos/DataTable/DataTable';
import StaffDomain from 'domain/staff';
import ButtonNavigation from './ButtonNavigation';
import { useDownload } from './hooks';
import OrganizationFilter from './OrganizationFilter';
import EmploymentFilter from './EmploymentFilter';
import ExecutiveFilter from './ExecutiveFilter';
import StateStatusFilter from './StateStatusFilter';
import FormContentFilter from 'components/atoms/Form/FormContentFilter';
import SubActionButton from 'components/atoms/Button/SubActionButton';
import { useHistory } from 'react-router-dom';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormContents from 'components/atoms/Form/FormContents';
import { DetailRole } from 'api/masterRole';

const styles = {
  bgWhite: css({
    backgroundColor: utilityColor.white,
    border: '1px solid #dcdcdc'
  }),
  flexboxCenter: css({
    textAlign: 'center',
    justifyContent: 'center'
  }),
  table: css({},
    css`
    table{
      table-layout:auto;
    }
    table tr th:nth-child(1),
    table tr td:nth-child(1){
      width: 40px;
      text-align:center;
      white-space: nowrap;
    }
  `),
  searchBox: css({
    zIndex: 'revert'
  }, css`
    z-index:1;
  `)
}

const StaffListTable: React.FC<{
  staffList: Array<StaffDomain>;
  fetchData: () => Promise<void>;
  executiveId: string;
  setExecutiveId: (arg: string) => void;
  employmentId: string;
  setEmploymentId: (arg: string) => void;
  orgCode: string;
  setOrgCode: (arg: string) => void;
  stateStatus: Array<number>;
  setStateStatus: (arg: Array<number>) => void;
  detailRole: DetailRole,
  underFlag: number,
  setUnderFlag: React.Dispatch<React.SetStateAction<number|null>>
}> = ({
  staffList, fetchData,
  executiveId, setExecutiveId,
  employmentId, setEmploymentId,
  orgCode, setOrgCode,
  stateStatus, setStateStatus, detailRole,
  underFlag, setUnderFlag
}) => {
    const history = useHistory();
    const {
      downloadStaffCsv, downloadStaffManagerCsv, downloadStaffSalaryCsv, downloadStaffHolidayCsv
    } = useDownload(orgCode, executiveId, employmentId, stateStatus, underFlag);


    const columns: Array<Column<StaffDomain>> = React.useMemo(() => [
      {
        Header: () => null,
        accessor: 'orgCode',
        id: 'actionButton',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, values: StaffDomain } }) => {
          return (
            <React.Fragment>
              <SubActionButton action={0} text={detailRole.editable === 1 ? '編集' : '参照'} onClick={() => {
                history.push(`/staffs/${cell.row.values.staffCode}`);
              }} />
            </React.Fragment>
          );
        },
      },
      {
        Header: '組織コード',
        accessor: 'orgCode',
      },
      {
        Header: '組織名',
        accessor: 'orgName',
      },
      {
        Header: 'スタッフコード',
        accessor: 'staffCode',
      },
      {
        Header: 'スタッフ名',
        accessor: 'staffName',
      },

      {
        Header: '役職コード',
        accessor: 'executiveCode',
      },

      {
        Header: '役職名',
        accessor: 'executiveName',
      },
      {
        Header: '雇用形態コード',
        accessor: 'employmentCode',
      },
      {
        Header: '雇用形態',
        accessor: 'employmentName',
      },
      {
        Header: '状態',
        accessor: 'stateStatusDisp',
      },
      {
        Header: '',
        accessor: 'mail',
        show: false
      },
    ], [history, detailRole]);

    return (
      <FormContents customStyle={styles.bgWhite}>
        <FormTitle bold={true} title="スタッフマスタ" />
        <FormContentFilter customStyles={css({ paddingTop: '10px', maxWidth: '1050px' })}>
          <OrganizationFilter
            orgCode={orgCode}
            setOrgCode={(arg: string) => {
              setOrgCode(arg);
            }}
            stateStatus={stateStatus}
            defaultValue={sessionStorage.getItem('loginUser.orgCode')}
            underFlag={underFlag}
            setUnderFlag={setUnderFlag}
          />

          <ExecutiveFilter
            executiveId={executiveId}
            setExecutiveId={(arg: string) => {
              setExecutiveId(arg);
            }}
          />

          <EmploymentFilter
            employmentId={employmentId}
            setEmploymentId={(arg: string) => {
              setEmploymentId(arg);
            }}
          />

          <StateStatusFilter
            stateStatus={stateStatus}
            setStateStatus={(arg: Array<number>) => {
              setStateStatus(arg);
            }}
          />
        </FormContentFilter>
        <div>
          <ButtonNavigation
            downloadStaffCsv={downloadStaffCsv}
            downloadStaffSalaryCsv={downloadStaffSalaryCsv}
            downloadStaffManagerCsv={downloadStaffManagerCsv}
            downloadStaffHolidayCsv={downloadStaffHolidayCsv}
            detailRole={detailRole}
          />
          
          <div className="table-bordered" css={styles.table} style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={staffList}
              isGlobalFilter={true}
              minWidth="100%"
              globalSearchStyle={styles.searchBox}
              
            />
          </div>
        </div>
      </FormContents>
    );
  };

export default StaffListTable;
