import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Row, Col } from "react-grid-system";
import BodyText from "components/atoms/BodyText";
import TabControl from "components/atoms/TabControl";
import BlockUI from "components/molecules/BlockUi";
import { css } from "@emotion/core";
import { useGetClosingList, useGetEmployments, eventScroll, useGetTimeList } from "./hooks";
import ToastModal from "../../../ToastModal";
import TimeTable from "./TimeTable";
import DayTable from "./DayTable";
import FormPadding from "components/atoms/Form/FormPadding";
import { grayScale } from "components/styles";
import LableTitle from "components/atoms/LableTitle";
import IconLabelButton from "components/molecules/IconLabelButton";
import SelectForm from "components/molecules/SelectForm";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

type SelectTab = "tab_time_table" | "tab_day_table";
const SelectTab: { [key in SelectTab]: SelectTab } = {
  tab_time_table: "tab_time_table",
  tab_day_table: "tab_day_table",
};

const AttendMonthlyList: React.FC = () => {
  const history = useHistory();
  const [toastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.tab_time_table);
  const [isDetailRoleLoading, setDetailRoleLoading] = useState(false);
  const [isGetTimeListLoading, setGetTimeListLoading] = useState(false);

  useEffect(() => {
    if (
      !sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateTo") ||
      !sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateTo") ||
      !sessionStorage.getItem("headquartersFinal.monthlyList.selectOrgCode")
    ) {
      history.push("/agreement36");
    }
  }, [history]);

  const [targetDateFrom, setTargetDateFrom] = useState(
    new Date(sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateFrom")!),
  );
  const [targetDateTo, setTargetDateTo] = useState(
    new Date(sessionStorage.getItem("headquartersFinal.monthlyList.searchTargetDateTo")!),
  );
  const [selectedClosingDate, setSelectedClosingDate] = useState(
    sessionStorage.getItem("headquartersFinal.monthlyList.selClosingDate") || "-1",
  );
  const [selectedEmploymentId, setSelectedEmploymentId] = useState(
    sessionStorage.getItem("headquartersFinal.monthlyList.searchEmploymentId") || "",
  );
  const [attendFlag] = useState(sessionStorage.getItem("headquartersFinal.monthlyList.attendFlag") || "0");
  const [selectOrgCode] = useState(sessionStorage.getItem("headquartersFinal.monthlyList.selectOrgCode") || "");
  const [selectOrgName] = useState(sessionStorage.getItem("headquartersFinal.monthlyList.selectOrgName") || "");
  const [selectedFilterError, setSelectedFilterError] = useState<any>("");
  const { closingDateList } = useGetClosingList(targetDateFrom);
  const { employmentList } = useGetEmployments(targetDateFrom, targetDateTo, selectedClosingDate);
  const { detailRole } = useGetDetailRole(setDetailRoleLoading, functionCode.attendHeadquartersFinal);

  const {
    term,
    business,
    subTotalTimeList,
    subTotalDayList,
    holidaySubtotalItem,
    detailFlag,
    useAllowance,
  } = useGetTimeList(
    setGetTimeListLoading,
    targetDateFrom,
    targetDateTo,
    selectedClosingDate,
    attendFlag,
    selectOrgCode,
    selectedEmploymentId,
  );

  useEffect(() => {}, [selectedFilterError]);

  return (
    <BlockUI blocking={isDetailRoleLoading || isGetTimeListLoading}>
      <FormContents>
        <FormPadding>
          <Row>
            <Col md={12}>
              <strong>
                <LableTitle customStyle={{ paddingLeft: "0px" }} title="勤怠データ確認" />
              </strong>
            </Col>
            <Col md={2}>
              <div style={{ marginTop: "10px" }}>
                <BodyText>期間</BodyText>
              </div>
            </Col>
            <Col md={10}>
              <FlexBox>
                <FlexBoxItem>
                  <CalendarRangeV2
                    width="auto"
                    startDate={targetDateFrom}
                    onChangeStart={setTargetDateFrom}
                    endDate={targetDateTo}
                    onChangeEnd={(date) => {
                      if (date) {
                        setTargetDateTo(date);
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
          </Row>
          <Row style={{ margin: "10px 0" }}>
            <Col style={{ display: "flex", alignItems: "center" }} md={2}>
              <BodyText>組織名</BodyText>
            </Col>
            <Col md={10}>
              <BodyText color={grayScale.gray100}>{selectOrgName}</BodyText>
            </Col>
          </Row>
          <Row style={{ margin: "10px 0" }}>
            <Col style={{ display: "flex", alignItems: "center" }} md={2}>
              <BodyText>締日・雇用形態</BodyText>
            </Col>
            <Col md={10}>
              <Row>
                <Col md={3}>
                  <FlexBox>
                    <FlexBoxItem grow={0.5}>
                      <SelectForm
                        label=""
                        name="closingDate"
                        value={selectedClosingDate}
                        setValue={(v) => {
                          setSelectedEmploymentId("");
                          setSelectedClosingDate(v);
                        }}
                        options={[...[{ value: "-1", label: "-" }], ...closingDateList]}
                      />
                    </FlexBoxItem>
                    <span>&nbsp;日</span>
                  </FlexBox>
                </Col>
                <Col md={4}>
                  <SelectForm
                    label=""
                    name="attendEmployment"
                    value={selectedEmploymentId}
                    setValue={(v) => setSelectedEmploymentId(v)}
                    options={[...[{ value: "", label: "-" }], ...employmentList]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", alignItems: "center" }} md={2}>
              <BodyText>月次提出状況</BodyText>
            </Col>
            <Col md={10}>
              <Row>
                <Col md={4}>
                  <FlexBox>
                    <FlexBoxItem grow={0.5}>
                      <SelectForm
                        label=""
                        name="isError"
                        value={String(selectedFilterError)}
                        setValue={(value) => {
                          setSelectedFilterError(value === "true" ? true : value === "false" ? false : "");
                        }}
                        options={[
                          {
                            label: "-",
                            value: "",
                          },
                          {
                            label: "未提出のスタッフ",
                            value: "false",
                          },
                          {
                            label: "提出済のスタッフ",
                            value: "true",
                          },
                        ]}
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormPadding>
        <div style={{ marginTop: "30px" }}>
          <TabControl
            items={[
              {
                value: SelectTab.tab_time_table,
                display: "集計時間",
              },
              {
                value: SelectTab.tab_day_table,
                display: "集計日数",
              },
            ]}
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={true}
            customStyle={css({
              margin: "0px",
              padding: "8px 16px",
              boxSizing: "border-box",
              maxWidth: "100%",
              overflow: "auto",
            })}
          />
          {selectTab === SelectTab.tab_time_table && (
            <div
              style={{
                marginTop: "20px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              <TimeTable
                eventScroll={eventScroll}
                subTotalTimeList={
                  selectedFilterError === ""
                    ? subTotalTimeList
                    : subTotalTimeList.filter((value: any) => value.isConfirmError === selectedFilterError)
                }
                business={business || []}
                term={term}
                targetDateFrom={targetDateFrom}
                targetDateTo={targetDateTo}
                selectedEmploymentId={selectedEmploymentId}
                selectOrgCode={selectOrgCode}
                selectedClosingDate={selectedClosingDate}
                attendFlag={attendFlag}
                detailRole={detailRole}
                detailFlag={detailFlag}
              />
            </div>
          )}
          {selectTab === SelectTab.tab_day_table && (
            <div
              style={{
                marginTop: "20px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              <DayTable
                eventScroll={eventScroll}
                holidaySubtotalItem={holidaySubtotalItem}
                subTotalDayList={subTotalDayList}
                useAllowance={useAllowance}
              />
            </div>
          )}
        </div>
        <Row>
          <FlexBox alignItems="center" customStyle={css({ width: "100%", margin: "20px 0 20px 30px" })}>
            <FlexBoxItem>
              <IconLabelButton
                onClick={() => history.push("/attendHeadquartersFinal")}
                iconType="arrowLeft"
                text="戻る"
              />
            </FlexBoxItem>
          </FlexBox>
        </Row>
        <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />
      </FormContents>
    </BlockUI>
  );
};

export default AttendMonthlyList;
