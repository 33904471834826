/** @jsx jsx */
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { css, jsx } from "@emotion/core";
import { utilityColor } from "components/styles";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import useComponentVisible from "hooks/useComponentVisible";
import Icon from "components/atoms/Icon";
import { SubActionButton } from "components/atoms/Button";
import ContextMenuBox from "./ContextMenuBox";
import { ContextMenuType } from "./type";
import ButtonText from "components/atoms/Button/ButtonText";

const style = {
  contextMenu: css`
    border-radius: 3px;
    > div {
      background-color: white;
    }
  `,
};

const ContextMenu: React.FC<{
  text: string;
  buttons: ContextMenuType[];
  action?: number;
  disabled?: boolean;
  position?: "bottom" | "right";
  customStyle?: any;
  isButtonText?: boolean;
  hiddenDropIcon?: boolean;
}> = ({ text, buttons, action = 0, disabled, position, customStyle, isButtonText, hiddenDropIcon = false }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const bodyNode = document.querySelector("body") as HTMLBodyElement;
  const [parentEle, setParentEle] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setParentEle(ref.current);
  }, []);

  const handleClickMenu = useCallback(() => {
    setIsComponentVisible(!isComponentVisible);
  }, [ref]);

  return (
    <FlexBox
      customStyle={css({
        justifyContent: "center",
      })}
    >
      <FlexBoxItem>
        <div ref={ref}>
          {isButtonText ? (
            <ButtonText
              customStyle={customStyle}
              onClick={disabled ? () => {} : handleClickMenu}
              text={text}
              action={action}
            >
              <Icon type="download" margin={"10px"} color={"#007BC3"} />
              {text}
              {!hiddenDropIcon && <Icon type="dropdown" size="10px" color={"#007BC3"} />}
            </ButtonText>
          ) : (
            <SubActionButton
              customStyle={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
                ...customStyle,
              }}
              onClick={disabled ? () => {} : handleClickMenu}
              text={text}
              action={action}
            >
              {text}
              {!hiddenDropIcon && (
                <Icon
                  type="dropdown"
                  customStyle={css({ display: "flex", alignItems: "center" })}
                  size="10px"
                  color={utilityColor.white}
                />
              )}
            </SubActionButton>
          )}

          {ReactDOM.createPortal(
            <div className="contextMenu" css={style.contextMenu}>
              <ContextMenuBox
                items={buttons}
                setIsComponentVisible={setIsComponentVisible}
                isVisible={isComponentVisible}
                parentEle={parentEle}
                parentRef={ref}
                position={position}
              />
            </div>,
            bodyNode,
          )}
        </div>
      </FlexBoxItem>
    </FlexBox>
  );
};

export default ContextMenu;
