/* eslint-disable eqeqeq */
/** @jsx jsx */
import React, { useRef, useEffect, useState, createRef, useMemo, Fragment } from "react";
import { css, jsx } from "@emotion/core";
import { grayScale, gridCol, utilityColor } from "components/styles";
import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import IconTooltip from "components/molecules/IconTooltip";
import ContextMenu from "components/molecules/ContextMenu";
import getContextMenuButtons from "components/molecules/ContextMenu/getContextMenuButtons";
import TextTooltip from "components/molecules/TextTooltip";

const styles = {
  actualHours: {
    fontSize: "10px",
    marginTop: "-12px",
    color: grayScale.gray100,
    borderTop: `1px solid ${grayScale.gray30}`,
  },
};

const AttendStampTable: React.FC<{
  eventScroll: any;
  business: Array<any>;
  term: Array<any>;
  targetDateFrom: Date;
  targetDateTo: Date;
  selectOrgCode: String;
  attendFlag: String;
  stampItems: any;
  emp: any;
  staffCode: any;
  staffList: any;
  remote: boolean;
  viewPeriod: string;
  isConfirmError: boolean;
  editable: number;
  dispStampTimeOrg: number;
  allowanceApplicationStatus: number;
}> = ({
  eventScroll,
  business,
  term,
  targetDateFrom,
  targetDateTo,
  selectOrgCode,
  attendFlag,
  stampItems,
  emp,
  staffCode,
  staffList,
  remote,
  viewPeriod,
  isConfirmError,
  editable,
  dispStampTimeOrg,
  allowanceApplicationStatus,
}) => {
  const history = useHistory();
  const refTableScroll = useRef(Object());
  const refTableContent = useRef(Object());
  const refTableContentBody = useRef(Object());
  // const refContextMenu = useRef(Object());
  const [refTrContent, setRefTrContent] = useState([]);
  const [heights, setHeights] = useState([]);
  const [offsetWidthTable, setOffsetWidthTable] = useState(0);
  // const [menuType] = useState('SIMPLE');
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  useEffect(() => {
    const width = refTableContentBody.current.offsetWidth - refTableContentBody.current.clientWidth;
    setScrollBarWidth(width);
  }, [refTableContentBody.current.offsetWidth]);

  useMemo(() => {
    setRefTrContent(stampItems.map(() => createRef()));
  }, [stampItems]);

  function getHolidayText(holiday: any) {
    if (
      (holiday.holidayType == 1 ||
        holiday.holidayType == 2 ||
        holiday.holidayType == 4 ||
        holiday.holidayType == 7 ||
        holiday.holidayType == 8 ||
        holiday.holidayType == 9) &&
      holiday.holidayDigestiveUnit == 0
    ) {
      return " (全)";
    }

    if (
      (holiday.holidayType == 1 ||
        holiday.holidayType == 2 ||
        holiday.holidayType == 4 ||
        holiday.holidayType == 7 ||
        holiday.holidayType == 8 ||
        holiday.holidayType == 9) &&
      holiday.holidayDigestiveUnit == 3
    ) {
      return " (午前)";
    }

    if (
      (holiday.holidayType == 1 ||
        holiday.holidayType == 2 ||
        holiday.holidayType == 4 ||
        holiday.holidayType == 7 ||
        holiday.holidayType == 8 ||
        holiday.holidayType == 9) &&
      holiday.holidayDigestiveUnit == 4
    ) {
      return " (午後)";
    }

    if (
      (holiday.holidayType == 1 || holiday.holidayType == 7 || holiday.holidayType == 8) &&
      holiday.holidayDigestiveUnit == 2
    ) {
      return " (時間)";
    }

    return "";
  }

  // function getHolidayStyle(holidayColor: any, holidayApplyStatus: any, holidayType: any,
  //   holidayDeleted: any, hasData: any) {
  //   if (!hasData) {
  //     return {};
  //   }
  //   if (holidayDeleted) {
  //     return {};
  //   }
  //   if (holidayColor != '' && holidayType == '0') {
  //     // return { backgroundColor: holidayColor };
  //     return {};
  //   } if (holidayColor != '' && holidayApplyStatus == '0') {
  //     return { backgroundColor: 'rgb(60,189,172)' };
  //     // return { backgroundColor: holidayColor, border: '5px solid rgb(60,189,172)' };
  //   } if (holidayColor != '' && holidayApplyStatus == '1') {
  //     return { backgroundColor: '#f2f8fc' };
  //     // return { backgroundColor: holidayColor, border: '5px solid rgb(60,77,189)' };
  //   }

  //   return {};
  //   // return { backgroundColor: holidayColor };
  // }

  function getStampTimeStyle(alertStatus: any, applyStatus: any, hasDispData: any) {
    if (!hasDispData) {
      return {};
    }

    // エラー
    if (alertStatus == "2") {
      return { backgroundColor: "#fdefef" };
      // return { border: '5px solid rgb(189,60,77)' };
    }

    // 申請ステータスをみない
    if (applyStatus == -1) {
      // 申請中
    } else if (applyStatus == 0) {
      // return { backgroundColor: 'rgb(60,189,172)' };
      // return { border: '5px solid rgb(60,189,172)' };
      return { backgroundColor: "#e6fff7" };
    } else if (applyStatus == 1) {
      // 承認
      return { backgroundColor: "#f2f8fc" };
      // return { backgroundColor: '#ffffe6' };
      // return { border: '5px solid rgb(60,77,189)' };
    }
    // アラート
    if (alertStatus == "1") {
      // return { backgroundColor: 'rgb(189,172,60)' };
      // return { border: '5px solid rgb(189,172,60)' };
      return { backgroundColor: "#ffffe6" };
    }

    return {};
  }

  function getStartTimeStyle(alertStatus: any, applyStatus: any, hasDispData: any, isOfficeWork?: boolean) {
    if (!hasDispData) {
      return {};
    }

    if (!isOfficeWork) {
      return { backgroundColor: "#ff9494a3" };
    }

    // エラー
    if (alertStatus == "2") {
      return { backgroundColor: "#fdefef" };
      // return { border: '5px solid rgb(189,60,77)' };
    }

    // 申請ステータスをみない
    if (applyStatus == -1) {
      // 申請中
    } else if (applyStatus == 0) {
      // return { backgroundColor: 'rgb(60,189,172)' };
      // return { border: '5px solid rgb(60,189,172)' };
      return { backgroundColor: "#e6fff7" };
    } else if (applyStatus == 1) {
      // 承認
      return { backgroundColor: "#f2f8fc" };
      // return { backgroundColor: '#ffffe6' };
      // return { border: '5px solid rgb(60,77,189)' };
    }
    // アラート
    if (alertStatus == "1") {
      // return { backgroundColor: 'rgb(189,172,60)' };
      // return { border: '5px solid rgb(189,172,60)' };
      return { backgroundColor: "#ffffe6" };
    }

    return {};
  }

  function getStampTimeStyleCustom(alertStatus: any, applyStatus: any, hasDispData: any) {
    if (!hasDispData) {
      return {};
    }

    // エラー
    if (alertStatus == "2") {
      return { backgroundColor: "#fdefef" };
    }

    // アラート
    if (alertStatus == "1") {
      return { backgroundColor: "#ffffe6" };
    }

    // 申請ステータスをみない
    if (applyStatus == -1) {
      // 申請中
    } else if (applyStatus == 0) {
      return { backgroundColor: "#e6fff7" };
    } else if (applyStatus == 1) {
      // 承認
      return { backgroundColor: "#f2f8fc" };
    }

    return {};
  }

  function getStampTimeStyleDisplayableEmpty(
    alertStatus: any,
    applyStatus: any,
    hasDispData: any,
    targetErrorFlg: any,
    modifierDeleted: any,
  ) {
    // 削除申請時は何も表示しない
    if (modifierDeleted) {
      return {};
    }
    // データの有無にかかわらず表示可能なエラー
    if (alertStatus == "2" && targetErrorFlg) {
      return { backgroundColor: "#fdefef" };
    }
    if (hasDispData) {
      // エラー
      if (alertStatus == "2") {
        return { backgroundColor: "#fdefef" };
      }
      // 申請中
      if (applyStatus == 0) {
        return { backgroundColor: "#e6fff7" };
      }
      if (applyStatus == 1) {
        // 承認
        return { backgroundColor: "#f2f8fc" };
      }
      // アラート
      if (alertStatus == "1") {
        return { backgroundColor: "#ffffe6" };
      }
    }
    return {};
  }

  function getEndTimeStyleDisplayable(
    alertStatus: any,
    applyStatus: any,
    hasDispData: any,
    targetErrorFlg: any,
    modifierDeleted: any,
    isOfficeWork?: boolean,
  ) {
    // 削除申請時は何も表示しない
    if (modifierDeleted) {
      return {};
    }

    if (!isOfficeWork) {
      return { backgroundColor: "#ff9494a3" };
    }

    // データの有無にかかわらず表示可能なエラー
    if (alertStatus == "2" && targetErrorFlg) {
      return { backgroundColor: "#fdefef" };
    }
    if (hasDispData) {
      // エラー
      if (alertStatus == "2") {
        return { backgroundColor: "#fdefef" };
      }
      // 申請中
      if (applyStatus == 0) {
        return { backgroundColor: "#e6fff7" };
      }
      if (applyStatus == 1) {
        // 承認
        return { backgroundColor: "#f2f8fc" };
      }
      // アラート
      if (alertStatus == "1") {
        return { backgroundColor: "#ffffe6" };
      }
    }
    return {};
  }

  function getStyleAertUnder18MidnightWorkFlg(flag: any) {
    if (flag) {
      return { backgroundColor: "rgb(189,172,60)" };
      // return { border: '5px solid rgb(189,172,60)' };
    }

    return {};
  }

  function getStyleMaxHeight(index: number) {
    if (heights[index]) {
      return { height: `${heights[index]}px` };
    }

    return {};
  }

  function getStyleWeekDayColor(color: string) {
    if (color === "white") {
      return {};
    }

    if (color === "rgba(255,182,193,0.25)") {
      return { color: "#ED5D5D" };
    }

    if (color === "rgba(173,216,230,0.25)") {
      return { color: "#3aaee6" };
    }

    return {};
  }

  useEffect(() => {
    const width = refTableScroll.current ? refTableScroll.current.offsetWidth : 0;
    setOffsetWidthTable(width);

    const nextHeights: any = refTrContent && refTrContent.map((ref: any) => ref.current && ref.current.clientHeight);
    setHeights(nextHeights);
  }, [refTableScroll.current.offsetWidth, refTrContent, refTableContent.current.clientHeight]);

  const staff = staffList.filter((staff: any) => staff.value === staffCode);
  const dispStaffName = staff[0] ? staff[0].label : "";

  const prefixLeft = "attendStampList";
  const prefixRight = "attendStampListRight";

  const highlightAction = sessionStorage.getItem("highlightAction") || false;
  const highlightIndex = sessionStorage.getItem("highlightIndex") || "";
  const elmntLeft = document.getElementById(prefixLeft + "_row_" + highlightIndex);
  const elmntRight = document.getElementById(prefixRight + "_row_" + highlightIndex);

  useEffect(() => {
    if (highlightAction === "true") {
      if (elmntLeft && elmntRight) {
        elmntRight.scrollIntoView({ block: "center" });
        elmntLeft.classList.add("hightlight");
        elmntRight.classList.add("hightlight");
        setTimeout(() => {
          elmntLeft.classList.remove("hightlight");
          elmntRight.classList.remove("hightlight");
          sessionStorage.setItem("highlightAction", "false");
          sessionStorage.setItem("highlightIndex", "");
        }, 3000);
      }
    }
  }, [elmntLeft, elmntRight, highlightAction]);

  const rowEmpty = () => {
    const cells = [];
    const length = document.getElementById("top-right-time")?.getElementsByTagName("th").length;
    if (length) {
      for (let i = 0; i < length; i++) {
        cells.push(<td className="head-line1-height"></td>);
      }
    }
    return cells;
  };

  return (
    <div className="wrap-attend-stamp-list">
      <div style={{ display: "flex" }}>
        <div style={{ minWidth: "240px" }} className="top-left-area-time">
          <table
            className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-left-none max-content"
            style={{ maxWidth: "240px" }}
          >
            <thead>
              <tr>
                <th className="head-line4-height border-left-none" style={{ width: "110px" }}>
                  修正
                </th>
                <th className="head-line4-height" style={{ width: "130px", textAlign: "left" }}>
                  日付
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="topRightAreaTime" onScroll={eventScroll} style={{ width: "calc(100% - 240px)", overflow: "auto" }}>
          <div className="max-content" ref={refTableScroll}>
            <table
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-right-none"
              id="top-right-time"
            >
              <thead>
                <tr>
                  <th className="head-line4-height" style={{ width: "40px" }}>
                    確定
                  </th>
                  {viewPeriod === "monthly" && (
                    <th className="head-line4-height" style={{ width: "40px" }}>
                      提出
                    </th>
                  )}

                  <th style={{ width: "180px" }}>勤務予定</th>
                  <th style={{ width: "150px" }}>休暇</th>
                  {/* {emp.distinctionHoliday && (
                      <th style={{ width: "60px" }}>
                        休日
                        <br />
                      種別
                      </th>
                    )} */}
                  <th style={{ width: "80px" }}>出勤</th>
                  <th style={{ width: "80px" }}>退勤</th>
                  <th style={{ width: "180px" }}>休憩時刻</th>
                  <th style={{ width: "60px" }}>
                    通常
                    <br />
                    勤務
                  </th>
                  {emp.handleLegalInOvertime == 0 && (
                    <th style={{ width: "60px" }}>
                      普通
                      <br />
                      残業
                    </th>
                  )}
                  {emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: "60px" }}>
                      法定内
                      <br />
                      残業
                    </th>
                  )}
                  {emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: "60px" }}>
                      法定外
                      <br />
                      残業
                    </th>
                  )}
                  {emp.useExtraOverTime && (
                    <th style={{ width: "60px" }}>
                      割増
                      <br />
                      残業1
                    </th>
                  )}
                  {emp.useExtraOverTime && emp.extraOverTime2 > 0 && (
                    <th style={{ width: "60px" }}>
                      割増
                      <br />
                      残業2
                    </th>
                  )}
                  {emp.useExtraOverTime && emp.extraOverTime3 > 0 && (
                    <th style={{ width: "60px" }}>
                      割増
                      <br />
                      残業3
                    </th>
                  )}

                  <th style={{ width: "60px" }}>深夜</th>
                  {emp.handleLegalInOvertime == 0 && (
                    <th style={{ width: "60px" }}>
                      深夜
                      <br />
                      残業
                    </th>
                  )}
                  {emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: "60px" }}>
                      深夜
                      <br />
                      法定内
                      <br />
                      残業
                    </th>
                  )}
                  {emp.handleLegalInOvertime == 1 && (
                    <th style={{ width: "60px" }}>
                      深夜
                      <br />
                      法定外
                      <br />
                      残業
                    </th>
                  )}
                  {emp.useExtraOverTime && (
                    <th style={{ width: "60px" }}>
                      深夜
                      <br />
                      割増
                      <br />
                      残業1
                    </th>
                  )}
                  {emp.useExtraOverTime && emp.extraOverTime2 > 0 && (
                    <th style={{ width: "60px" }}>
                      深夜
                      <br />
                      割増
                      <br />
                      残業2
                    </th>
                  )}
                  {(emp.useExtraOverTime && emp.extraOverTime3) > 0 && (
                    <th style={{ width: "60px" }}>
                      深夜
                      <br />
                      割増
                      <br />
                      残業3
                    </th>
                  )}
                  {emp.useOverTimeApplication != 0 && (
                    <th style={{ width: "60px" }}>
                      未承認
                      <br />
                      残業
                    </th>
                  )}
                  <th style={{ width: "60px" }}>休憩</th>
                  <th style={{ width: "60px" }}>遅刻</th>
                  <th style={{ width: "60px" }}>早退</th>
                  <th style={{ width: "60px" }}>みなし時間</th>
                  <th style={{ width: "60px" }}>
                    総労働
                    <br />
                    時間
                  </th>
                  {business &&
                    business.map((businessItem: any) => (
                      <th key={businessItem.businessCode} style={{ width: "120px" }}>
                        {businessItem.businessName}
                      </th>
                    ))}
                  {term &&
                    term.map((termItem: any) => (
                      <th key={termItem.termId} style={{ width: "120px" }}>
                        {termItem.termName}
                      </th>
                    ))}
                  <th style={{ width: "200px" }}>出勤組織</th>

                  {allowanceApplicationStatus === 1 && (
                    <th
                      style={{
                        width: "160px",
                      }}
                    >
                      手当申請
                    </th>
                  )}

                  {remote && (
                    <React.Fragment>
                      <th style={{ width: "80px" }}>オフィス</th>
                      <th style={{ width: "100px" }}>{companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"}</th>
                    </React.Fragment>
                  )}
                  <th className="border-right-none" style={{ width: "400px" }}>
                    備考
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div ref={refTableContentBody} className="table-content">
        <div style={{ display: "flex", width: gridCol.grid12 }}>
          <div style={{ minWidth: "240px" }} className="bottomLeftAreaTime">
            <table
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-left-none max-content"
              style={{ maxWidth: "240px" }}
            >
              <tbody>
                {/* fix width */}
                <tr style={{ visibility: "collapse" }}>
                  <td
                    style={{
                      width: "110px",
                      height: "0px",
                      padding: "0px",
                      border: "none",
                    }}
                  />
                  <td
                    style={{
                      width: "130px",
                      height: "0px",
                      padding: "0px",
                      border: "none",
                    }}
                  />
                </tr>
                {stampItems &&
                  stampItems.map((item: any, index: any) => {
                    let workSystem = 0;
                    let useAutoRess = 0;

                    if (item.stampEmployment) {
                      workSystem = item.stampEmployment.workSystem;
                      useAutoRess = item.stampEmployment.useAutoRess;
                    }
                    let contextMenuButtons = getContextMenuButtons(
                      history,
                      item.modifierApplicationId,
                      item.orgCode,
                      staffCode,
                      "",
                      item.modifierApplicationStatus,
                      item.holidayApplicationStatus,
                      item.allowanceApplicationStatus,
                      item.overworkApplicationStatus,
                      item.holidayworkApplicationStatus,
                      item.transferApplicationStatus,
                      item.attendanceOrgFix,
                      item.stampEmployment?.useOverTimeApplication,
                      item.stampEmployment?.necessaryHolidayWorkApplication,
                      item.stampEmployment?.useAllowanceApplication,
                      item.stampEmployment?.useTransferApplication,
                      item.achievementId,
                      item.targetDate,
                      item.stampVersion,
                      dispStaffName,
                      targetDateFrom,
                      targetDateTo,
                      String(selectOrgCode),
                      staffCode,
                      item.belongOrgFix,
                      item.applying,
                      item.shiftTime,
                      null,
                      null,
                      item.holidayId,
                      item.holidayWorkFlg,
                      workSystem,
                      useAutoRess,
                      item.shiftId,
                      item.shiftHolidayId,
                      index,
                      item.overtimeApplicationOrgCode,
                      item.holidayWorkApplicationOrgCode,
                      "",
                      "",
                      false,
                      Boolean(item?.stampEmployment?.useStockPaidApp),
                      item?.stockModiferAppId,
                      item?.stockPaidHolidayApplicationStatus,
                    );

                    let contextMenuAction: any;
                    if (item.stampEmployment?.workSystem !== 1) {
                      if (item.applying != 0 && item.employmentId != null) {
                        contextMenuAction = (
                          <ContextMenu
                            text="申請"
                            buttons={contextMenuButtons}
                            disabled={item.attendanceOrgFix == "1" || item.belongOrgFix == "1" || item.isConfirmError}
                          />
                        );
                      } else if (item.applying == 0 && item.employmentId != null) {
                        contextMenuAction = <ContextMenu action={3} text="申請中" buttons={contextMenuButtons} />;
                      }
                    } else {
                      if (item.workPlanApplicationStatus != 0) {
                        if (item.applying == 0 && item.employmentId != null) {
                          contextMenuAction = <ContextMenu action={3} text="申請中" buttons={contextMenuButtons} />;
                        } else {
                          contextMenuAction = (
                            <ContextMenu
                              text="申請"
                              buttons={contextMenuButtons}
                              disabled={
                                item.attendanceOrgFix == "1" || item.belongOrgFix == "1" || item.isConfirmError
                              }
                            />
                          );
                        }
                      } else {
                        if (item.workPlanApplicationStatus == 0 || item.applying === 0) {
                          contextMenuAction = <ContextMenu action={3} text="申請中" buttons={contextMenuButtons} />;
                        }
                      }
                    }

                    return (
                      <React.Fragment key={String(index)}>
                        {/* { backgroundColor: item.weekdayColor, ...getStyleMaxHeight(index) } */}
                        <tr style={getStyleMaxHeight(index)} id={(prefixLeft || "") + "_row_" + index}>
                          <td className="head-line1-height border-left-none" style={{ width: "110px" }}>
                            {editable === 1 && contextMenuAction}
                          </td>
                          {(item.alert == "0" || !item.alert || item.modifierDeleted) && (
                            <td style={{ textAlign: "left", ...getStyleWeekDayColor(item.weekdayColor) }}>
                              {item.day}
                            </td>
                          )}
                          {item.alert == "1" && !item.modifierDeleted && (
                            <td style={{ textAlign: "left" }}>
                              <span style={getStyleWeekDayColor(item.weekdayColor)}>{item.day}</span>
                              {(item.alertMsg != "" && item.holidayId && item.achievementId) ||
                              (item.alertMsg != "" && item.achievementId) ||
                              item.errorStampCheckinCheckout ? (
                                <IconTooltip
                                  iconType="warning"
                                  text={item.alertMsg.replace("</br>", "\n") || ""}
                                  color={utilityColor.yellow}
                                  wrapCustomCss={css({
                                    display: "inline",
                                    marginRight: "3px",
                                    whiteSpace: "pre-wrap",
                                  })}
                                  customCss={css({ margin: "0" })}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          )}
                          {item.alert == "2" && !item.modifierDeleted && (
                            <td style={{ textAlign: "left" }}>
                              <span style={getStyleWeekDayColor(item.weekdayColor)}>{item.day}</span>
                              {(item.alertMsg != "" && item.holidayId && item.achievementId) ||
                              (item.alertMsg != "" && item.achievementId) ||
                              item.errorStampCheckinCheckout ? (
                                <IconTooltip
                                  iconType="warning"
                                  text={item.alertMsg.replace("</br>", "\n") || ""}
                                  color={utilityColor.error}
                                  wrapCustomCss={css({
                                    display: "inline",
                                    marginRight: "3px",
                                    whiteSpace: "pre-wrap",
                                  })}
                                  customCss={css({ margin: "0", display: "inline", marginRight: "3px" })}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div
            id="bottomRightAreaTime"
            style={{ width: "calc(100% - 240px)", overflow: "auto" }}
            onScroll={eventScroll}
          >
            <div style={{ width: `${offsetWidthTable - scrollBarWidth}px` }}>
              <table
                ref={refTableContent}
                className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-right-none"
                id="bottom-right-time"
              >
                <tbody>
                  {/* fix width */}
                  <tr style={{ visibility: "collapse" }}>
                    <td
                      style={{
                        width: "40px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    {viewPeriod === "monthly" && (
                      <td
                        style={{
                          width: "40px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    <td
                      style={{
                        width: "180px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "150px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    {/* {emp.distinctionHoliday && (
                        <td
                          style={{
                            width: "60px",
                            height: "0px",
                            padding: "0px",
                            border: "none",
                          }}
                        />
                      )} */}
                    <td
                      style={{
                        width: "80px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "80px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "180px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    {emp.handleLegalInOvertime == 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.handleLegalInOvertime == 1 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.handleLegalInOvertime == 1 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.useExtraOverTime && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.useExtraOverTime && emp.extraOverTime2 > 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.useExtraOverTime && emp.extraOverTime3 > 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}

                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    {emp.handleLegalInOvertime == 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.handleLegalInOvertime == 1 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.handleLegalInOvertime == 1 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.useExtraOverTime && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.useExtraOverTime && emp.extraOverTime2 > 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {(emp.useExtraOverTime && emp.extraOverTime3) > 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    {emp.useOverTimeApplication != 0 && (
                      <td
                        style={{
                          width: "60px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}
                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    <td
                      style={{
                        width: "60px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                    {business &&
                      business.map((businessItem: any) => (
                        <td
                          key={businessItem.businessCode}
                          style={{
                            width: "120px",
                            height: "0px",
                            padding: "0px",
                            border: "none",
                          }}
                        />
                      ))}
                    {term &&
                      term.map((termItem: any) => (
                        <td
                          key={termItem.termId}
                          style={{
                            width: "120px",
                            height: "0px",
                            padding: "0px",
                            border: "none",
                          }}
                        />
                      ))}
                    <td
                      style={{
                        width: "200px",
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />

                    {allowanceApplicationStatus === 1 && (
                      <td
                        style={{
                          width: "160px",
                          height: "0px",
                          padding: "0px",
                          border: "none",
                        }}
                      />
                    )}

                    {remote && (
                      <React.Fragment>
                        <td
                          style={{
                            width: "40px",
                            height: "0px",
                            padding: "0px",
                            border: "none",
                          }}
                        />
                        <td
                          style={{
                            width: "40px",
                            height: "0px",
                            padding: "0px",
                            border: "none",
                          }}
                        />
                      </React.Fragment>
                    )}
                    <td
                      style={{
                        width: `${400 - scrollBarWidth - 1}px`,
                        height: "0px",
                        padding: "0px",
                        border: "none",
                      }}
                    />
                  </tr>
                  {stampItems &&
                    stampItems.map((item: any, index: any) => {
                      let colorBackgroundItem: any;

                      const backgroundApproved = css({
                        width: "180px",
                        backgroundColor: "#f2f8fc",
                      });

                      const backgroundApplying = css({
                        width: "180px",
                        backgroundColor: "#e6fff7",
                      });

                      const backgroundNormal = css({
                        width: "180px",
                      });

                      const backgroundApplyingDeleted = css({
                        width: "180px",
                        backgroundColor: "#d9d9d9",
                      });

                      if (item.stampEmployment?.workSystem === 1) {
                        if (item.workPlanApplicationStatus != 0) {
                          if (item.workPlanApplicationStatus === 1) {
                            colorBackgroundItem = backgroundApproved;
                          } else {
                            colorBackgroundItem = backgroundNormal;
                          }
                        } else {
                          if (item.workPlanApplicationStatus == 0) {
                            if (item.workPlanModifiyStatus === 1) {
                              colorBackgroundItem = backgroundApplyingDeleted;
                            } else {
                              colorBackgroundItem = backgroundApplying;
                            }
                          }
                        }
                      }
                      return (
                        <tr ref={refTrContent[index]} key={String(index)} id={(prefixRight || "") + "_row_" + index}>
                          {item.employmentId ? (
                            <React.Fragment>
                              {item.attendanceOrgFix != "1" && item.belongOrgFix != "1" && (
                                <td className="head-line1-height" />
                              )}
                              {(item.attendanceOrgFix == "1" || item.belongOrgFix == "1") && (
                                <td className="head-line1-height">
                                  <Icon type="singlecheckRounded" color="#34a659" size="20px" />
                                </td>
                              )}

                              {viewPeriod === "monthly" && (
                                <td className="head-line1-height" css={{ width: "40px" }}>
                                  {isConfirmError && item.employmentId && (
                                    <Icon type="singlecheckRounded" color="#34a659" size="20px" />
                                  )}
                                </td>
                              )}
                              {/* <!--勤務予定 --> */}
                              <td css={item.shiftTime ? colorBackgroundItem : { width: "180px" }}>
                                <TextTooltip
                                  text={item.shiftTime}
                                  customCss={css({
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "160px",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                    margin: "0px auto",
                                  })}
                                />
                              </td>

                              {/* <!--休暇 --> */}
                              {!item.holidayDeleted && (
                                <td
                                  style={
                                    {
                                      //color: item.holidayForeColor,
                                      //...getHolidayStyle(item.holidayBgColor, item.holidayApplicationStatus, item.holidayType, item.holidayDeleted, item.holidayName)
                                    }
                                  }
                                >
                                  {!item.holidayDeleted && item.holidayName != null ? (
                                    <TextTooltip
                                      text={item.holidayName + getHolidayText(item)}
                                      textDis={
                                        <div
                                          className="ellipsis headline-cell-width-115"
                                          style={{
                                            background: item.holidayBgColor,
                                            borderRadius: "3px",
                                            padding: "2px 7px",
                                            color: item.holidayForeColor,
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "115px",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            margin: "0px auto",
                                          }}
                                        >
                                          <span>{item.holidayName}</span>
                                        </div>
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              )}
                              {item.holidayDeleted && <td />}
                              {/* <!--休日種別 --> */}
                              {/* {
                                    (item.stampEmployment != null && (item.stampEmployment.distinctionHoliday && item.isLegal && item.transferType != '1' && item.holidayType == '0')) && <td>法定</td>
                                  }
                                  {
                                    (item.stampEmployment != null && (item.stampEmployment.distinctionHoliday && !item.isLegal && item.holidayName && item.transferType != '1' && item.holidayType == '0')) && <td>所定</td>
                                  }
                                  {
                                    (item.stampEmployment != null && (item.stampEmployment.distinctionHoliday && (!item.holidayName || item.transferType == '1' || item.holidayType != '0'))) && <td />
                                  }
                                  {
                                    (item.stampEmployment != null && (!item.stampEmployment.distinctionHoliday && emp.distinctionHoliday && item.holidayType == '0' && (item.holidayName && item.transferType != '1'))) && <td>法定</td>
                                  }
                                  {
                                    (item.stampEmployment != null && (!item.stampEmployment.distinctionHoliday && emp.distinctionHoliday && item.holidayType != '0' && !(item.holidayName && item.transferType != '1'))) && <td />
                                  }
                                  {
                                    item.stampEmployment == null && <td />
                                  } */}

                              {/* <!--出勤 --> */}
                              <td
                                style={getStartTimeStyle(
                                  item.startTimeAlert,
                                  item.modifierApplicationStatus,
                                  !item.modifierDeleted && item.startTime,
                                  item.startTimeIsOfficeWork,
                                )}
                                css={css({ padding: "0 10px!important" })}
                              >
                                {!item.modifierDeleted &&
                                item.holidayDigestiveUnit != 1 &&
                                item.holidayDigestiveUnit != 2 ? (
                                  dispStampTimeOrg == 1 && item.startTimeOrg ? (
                                    <div style={{ height: "30px" }}>
                                      <p style={{ marginTop: "-8px" }}>{item.startTime}</p>
                                      <p style={styles.actualHours}>{item.startTimeOrg}</p>
                                    </div>
                                  ) : (
                                    <span>{item.startTime}</span>
                                  )
                                ) : null}
                              </td>

                              {/* <!--退勤 --> */}
                              <td
                                style={getEndTimeStyleDisplayable(
                                  item.endTimeAlert,
                                  item.modifierApplicationStatus,
                                  !item.modifierDeleted && item.endTime,
                                  item.errorEndStampFlg,
                                  item.modifierDeleted,
                                  item.endTimeIsOfficeWork,
                                )}
                                css={css({ padding: "0 10px!important" })}
                              >
                                {!item.modifierDeleted &&
                                item.holidayDigestiveUnit != 1 &&
                                item.holidayDigestiveUnit != 2 ? (
                                  dispStampTimeOrg == 1 && item.endTimeOrg ? (
                                    <div style={{ height: "30px" }}>
                                      <p style={{ marginTop: "-8px" }}>{item.endTime}</p>
                                      <p style={styles.actualHours}>{item.endTimeOrg}</p>
                                    </div>
                                  ) : (
                                    <span>{item.endTime}</span>
                                  )
                                ) : null}
                              </td>

                              {/* <!--休憩時刻 --> */}
                              <td
                                css={css({ height: "45px" })}
                                style={getStampTimeStyleDisplayableEmpty(
                                  item.recessStartTimeAlert,
                                  item.modifierApplicationStatus,
                                  !item.modifierDeleted && item.recess != null && Object.keys(item.recess).length,
                                  item.errorRecessEndStampFlg,
                                  item.modifierDeleted,
                                )}
                              >
                                {!item.modifierDeleted &&
                                  item.recess &&
                                  item.recess.map((recess: any, index2: any) => (
                                    <React.Fragment>
                                      <p
                                        key={String(index2)}
                                        style={{ paddingBottom: "0.1px", height: `${recess.startTime}` && "10px" }}
                                      >
                                        <div
                                          className="shadow-sm bg-primary text-white"
                                          style={{
                                            borderRadius: "4px",
                                            margin: "5px",
                                            marginTop: `${recess.startTime}` && "-5px",
                                          }}
                                        >
                                          {recess.startTime}～{recess.endTime}
                                        </div>
                                      </p>
                                    </React.Fragment>
                                  ))}
                              </td>

                              {/* <!--普通時間 --> */}
                              <td>{!item.modifierDeleted && <span>{item.daytime}</span>}</td>

                              {/* <!--残業（法定内/法定外の区別なし） --> */}
                              {item.stampEmployment != null &&
                                item.stampEmployment.handleLegalInOvertime == 0 &&
                                emp.handleLegalInOvertime == 1 && <td />}
                              {item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 0 && (
                                <td
                                  style={getStampTimeStyle(
                                    item.normalNonLegalOverTimeAlert,
                                    item.applyoverworkApplicationStatusing,
                                    !item.modifierDeleted && item.normalNonLegalOverTime,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.normalNonLegalOverTime}</span>}
                                </td>
                              )}

                              {/* <!--法定内残業時間（法定内/法定外の区別あり） --> */}
                              {item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 1 && (
                                <td>{!item.modifierDeleted && <span>{item.normalLegalOverTime}</span>}</td>
                              )}

                              {/* <!--法定外残業時間（法定内/法定外の区別あり） --> */}
                              {item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 1 && (
                                <td
                                  style={getStampTimeStyle(
                                    item.normalNonLegalOverTimeAlert,
                                    item.overworkApplicationStatus,
                                    !item.modifierDeleted && item.normalNonLegalOverTime,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.normalNonLegalOverTime}</span>}
                                </td>
                              )}

                              {/* <!--割増残業時間1 --> */}
                              {item.stampEmployment != null && item.stampEmployment.useExtraOverTime && (
                                <td
                                  style={getStampTimeStyle(
                                    item.premiumOverTime1Alert,
                                    item.overworkApplicationStatus,
                                    !item.modifierDeleted && item.premiumOverTime1,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.premiumOverTime1}</span>}
                                </td>
                              )}
                              {item.stampEmployment != null &&
                                !item.stampEmployment.useExtraOverTime &&
                                emp.useExtraOverTime && <td />}

                              {/* <!--割増残業時間2 --> */}
                              {item.stampEmployment != null &&
                                item.stampEmployment.useExtraOverTime &&
                                item.stampEmployment.extraOverTime2 > 0 && (
                                  <td
                                    style={getStampTimeStyle(
                                      item.premiumOverTime2Alert,
                                      item.overworkApplicationStatus,
                                      !item.modifierDeleted && item.premiumOverTime2,
                                    )}
                                  >
                                    {!item.modifierDeleted && <span>{item.premiumOverTime2}</span>}
                                  </td>
                                )}
                              {item.stampEmployment != null &&
                                !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime2 > 0) &&
                                emp.useExtraOverTime &&
                                emp.extraOverTime2 > 0 && <td />}

                              {/* <!--割増残業時間3 --> */}
                              {item.stampEmployment != null &&
                                item.stampEmployment.useExtraOverTime &&
                                item.stampEmployment.extraOverTime3 > 0 && (
                                  <td
                                    style={getStampTimeStyle(
                                      item.premiumOverTime3Alert,
                                      item.overworkApplicationStatus,
                                      !item.modifierDeleted && item.premiumOverTime3,
                                    )}
                                  >
                                    {!item.modifierDeleted && <span>{item.premiumOverTime3}</span>}
                                  </td>
                                )}
                              {item.stampEmployment != null &&
                                !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime3 > 0) &&
                                emp.useExtraOverTime &&
                                emp.extraOverTime3 > 0 && <td />}

                              {/* <!--深夜時間 --> */}
                              <td style={getStyleAertUnder18MidnightWorkFlg(item.alertUnder18MidnightWorkFlg)}>
                                {!item.modifierDeleted && <span>{item.midnightTime}</span>}
                              </td>

                              {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
                              {item.stampEmployment != null &&
                                item.stampEmployment.handleLegalInOvertime == 0 &&
                                emp.handleLegalInOvertime == 1 && <td />}
                              {item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 0 && (
                                <td
                                  style={getStampTimeStyle(
                                    item.midnightNonLegalOverTimeAlert,
                                    -1,
                                    !item.modifierDeleted && item.midnightNonLegalOverTime,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.midnightNonLegalOverTime}</span>}
                                </td>
                              )}

                              {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
                              {item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 1 && (
                                <td
                                  style={getStampTimeStyle(
                                    item.midnightLegalOverTimeAlert,
                                    -1,
                                    !item.modifierDeleted && item.midnightLegalOverTime,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.midnightLegalOverTime}</span>}
                                </td>
                              )}

                              {/* <!--深夜法定外残業時間（法定内の区別あり） --> */}
                              {item.stampEmployment != null && item.stampEmployment.handleLegalInOvertime == 1 && (
                                <td
                                  style={getStampTimeStyle(
                                    item.midnightNonLegalOverTimeAlert,
                                    item.overworkApplicationStatus,
                                    !item.modifierDeleted && item.midnightNonLegalOverTime,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.midnightNonLegalOverTime}</span>}
                                </td>
                              )}

                              {/* <!--深夜割増残業時間1 --> */}
                              {item.stampEmployment != null && item.stampEmployment.useExtraOverTime && (
                                <td
                                  style={getStampTimeStyle(
                                    item.midnightPremiumOverTime1Alert,
                                    item.overworkApplicationStatus,
                                    !item.modifierDeleted && item.midnightPremiumOverTime1,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.midnightPremiumOverTime1}</span>}
                                </td>
                              )}

                              {/* <!--深夜割増残業時間1 --> */}
                              {item.stampEmployment != null &&
                                !item.stampEmployment.useExtraOverTime &&
                                emp.useExtraOverTime && <td />}

                              {/* <!--深夜割増残業時間2 --> */}
                              {item.stampEmployment != null &&
                                item.stampEmployment.useExtraOverTime &&
                                item.stampEmployment.extraOverTime2 > 0 && (
                                  <td
                                    style={getStampTimeStyle(
                                      item.midnightPremiumOverTime2Alert,
                                      item.overworkApplicationStatus,
                                      !item.modifierDeleted && item.midnightPremiumOverTime2,
                                    )}
                                  >
                                    {!item.modifierDeleted && <span>{item.midnightPremiumOverTime2}</span>}
                                  </td>
                                )}
                              {item.stampEmployment != null &&
                                !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime2 > 0) &&
                                emp.useExtraOverTime &&
                                emp.extraOverTime2 > 0 && <td />}

                              {/* <!--深夜割増残業時間3 --> */}
                              {item.stampEmployment != null &&
                                item.stampEmployment.useExtraOverTime &&
                                item.stampEmployment.extraOverTime3 > 0 && (
                                  <td
                                    style={getStampTimeStyle(
                                      item.midnightPremiumOverTime3Alert,
                                      item.overworkApplicationStatus,
                                      !item.modifierDeleted && item.midnightPremiumOverTime3,
                                    )}
                                  >
                                    {!item.modifierDeleted && <span>{item.midnightPremiumOverTime3}</span>}
                                  </td>
                                )}
                              {item.stampEmployment != null &&
                                !(item.stampEmployment.useExtraOverTime && item.stampEmployment.extraOverTime3 > 0) &&
                                emp.useExtraOverTime &&
                                emp.extraOverTime3 > 0 && <td />}

                              {/* <!--未承認残業時間 --> */}
                              {item.stampEmployment != null && item.stampEmployment.useOverTimeApplication != 0 && (
                                <td
                                  style={getStampTimeStyleCustom(
                                    item.unapprovedOverTimeAlert,
                                    item.overworkApplicationStatus,
                                    !item.modifierDeleted && item.unapprovedOverTime,
                                  )}
                                >
                                  {!item.modifierDeleted && <span>{item.unapprovedOverTime}</span>}
                                </td>
                              )}
                              {item.stampEmployment != null &&
                                item.stampEmployment.useOverTimeApplication == 0 &&
                                emp.useOverTimeApplication != 0 && <td />}

                              <td>{!item.modifierDeleted && <span>{item.recessTime}</span>}</td>
                              <td>{!item.modifierDeleted && <span>{item.lateTime}</span>}</td>
                              <td>{!item.modifierDeleted && <span>{item.earlyTime}</span>}</td>
                              <td>
                                {!item.modifierDeleted && !item.holidayDeleted && <span>{item.deemedTime}</span>}
                              </td>
                              <td>
                                {!item.modifierDeleted && !item.holidayDeleted && <span>{item.allWorkTime}</span>}
                              </td>

                              {business &&
                                business.map((businessItem: any, busIndex: any) => (
                                  <td key={businessItem.businessCode}>
                                    {item.achievementId && !item.modifierDeleted && (
                                      <span>
                                        {item.business && item.business[busIndex] && item.business[busIndex].time}
                                      </span>
                                    )}
                                  </td>
                                ))}
                              {term &&
                                term.map((termItem: any, tIndex: any) => (
                                  <td key={termItem.termId}>
                                    {item.achievementId && !item.modifierDeleted && (
                                      <span>{item.term && item.term[tIndex] && item.term[tIndex].time}</span>
                                    )}
                                  </td>
                                ))}

                              <td>{item.orgName}</td>

                              {allowanceApplicationStatus === 1 && (
                                <td>
                                  {item.allowanceDataList?.map((item: any) => (
                                    <div
                                      key={item.id}
                                    >{`${item.allowanceName}： ${item.allowanceValue}${item.dispUnit} `}</div>
                                  ))}
                                </td>
                              )}

                              {remote && (
                                <React.Fragment>
                                  <td style={{ width: "80px" }}>{item.officeTime}</td>
                                  <td style={{ width: "100px" }}>{item.remoteTime}</td>
                                </React.Fragment>
                              )}

                              <td style={{ textAlign: "left", paddingRight: "35px" }}>
                                {item.stampApplicationNote && (
                                  <div>
                                    修正申請：
                                    {item.stampApplicationNote}
                                  </div>
                                )}
                                {item.workPlanApplicationNote && (
                                  <div>
                                    予定申請：
                                    {item.workPlanApplicationNote}
                                  </div>
                                )}
                                {item.holidayApplicationNote && (
                                  <div>
                                    休暇申請：
                                    {item.holidayApplicationNote}
                                  </div>
                                )}
                                {item.holidayApplicationTime && <div>{item.holidayApplicationTime}</div>}
                                {item.overtimeApplicationNote && (
                                  <div>
                                    残業申請：
                                    {item.overtimeApplicationNote}
                                  </div>
                                )}
                                {item.holidayWorkApplicationNote && (
                                  <div>
                                    休日出勤申請：
                                    {item.holidayWorkApplicationNote}
                                  </div>
                                )}
                                {item.transferApplicationNote && (
                                  <div>
                                    振替申請：
                                    {item.transferApplicationNote}
                                  </div>
                                )}
                                {item.stockPaidHolidayApplicationNote && (
                                  <div>
                                    積立有給申請：
                                    {item.stockPaidHolidayApplicationNote}
                                  </div>
                                )}
                              </td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>{rowEmpty()}</React.Fragment>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="content-scroll-bottom" onScroll={eventScroll}>
        <div
          id="scrollBottom"
          style={{
            width: "calc(100% - 240px)",
            float: "right",
            height: "24px",
            overflow: "auto",
          }}
        >
          {business && term && <div className="scroll" style={{ width: `${offsetWidthTable - 15}px` }} />}
        </div>
      </div>
    </div>
  );
};
export default AttendStampTable;
