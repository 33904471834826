/** @jsx jsx */
import moment from "moment";
import React, { useEffect, createContext } from "react";
import { css, jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormLabel from "components/atoms/Form/FormLabel";
import TabControl from "components/atoms/TabControl";
import BlockUI from "components/molecules/BlockUi";
import CalendarForm from "components/molecules/CalendarForm";
import { utilityColor } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import ApplyingTab from "./ApplyingTab";
import ApprovedTab from "./ApprovedTab";
import RejectedTab from "./RejectedTab";
import FormContents from "components/atoms/Form/FormContents";
import useAttendApplicationApproval from "./hooks";
import EmploymentFilter from "../HopeShiftForm/EmploymentFilter";
import OrganizationFilter from "../HopeShiftForm/OrganizationFilter";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import { DetailRole } from "api/masterRole";

type SelectType = "applying" | "approved" | "rejected";

const SelectType: { [key in SelectType]: SelectType } = {
  applying: "applying",
  approved: "approved",
  rejected: "rejected",
};

// create style form
const styles = {
  container: css({
    paddingBottom: "5px",
    backgroundColor: utilityColor.white,
  }),
  label: css({
    flexBasis: "100px",
  }),
  digestionDays: css({
    width: "200px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
  }),
  wrapAccordion: css({
    marginTop: "-16px",
  }),
};

type AttendApplicationListContextType = {
  detailRole: DetailRole;
};

export const AttendApplicationListContext = createContext<
  AttendApplicationListContextType
>({} as AttendApplicationListContextType);

const AttendApplicationList: React.FC = () => {
  const [selectType, setSelectType] = React.useState<SelectType>(
    SelectType.applying
  );

  const {
    isLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,
    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,
    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,

    fetchData,

    fetchApprovedStampList,
    fetchApprovedHolidayList,
    fetchApprovedOvertimeList,
    fetchApprovedHolidayWorkList,
    fetchApprovedTransferList,
    fetchApprovedAgreement36List,
    fetchApprovedWorkScheduleChangeList,

    fetchRejectedStampList,
    fetchRejectedHolidayList,
    fetchRejectedOvertimeList,
    fetchRejectedHolidayWorkList,
    fetchRejectedTransferList,
    fetchRejectedAgreement36List,
    fetchRejectedWorkScheduleChangeList,

    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,
    detailRole,
  } = useAttendApplicationApproval();
  // const employmentOptions = useEmploymentOptions();
  // const orgTreesOptions = useOrgTreesOptionsAll(undefined, undefined, false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);

  return (
    <AttendApplicationListContext.Provider value={{ detailRole }}>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle title="勤怠申請一覧" bold={true} />

          <div css={styles.container}>
            <div style={{ margin: "30px" }}>
              <FlexBox customStyle={css({ padding: "8px" })}>
                <FlexBoxItem basis="100px">
                  <FormLabel label="期間" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <CalendarRangeV2
                    startDate={targetDateFrom}
                    onChangeStart={(date: Date) => {
                      setTargetDateFrom(date);
                      sessionStorage.setItem(
                        "applicationTargetDayFrom",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    endDate={targetDateTo}
                    onChangeEnd={(date) => {
                      if (date) {
                        setTargetDateTo(date);
                        sessionStorage.setItem(
                          "applicationTargetDayTo",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }
                    }}
                  />
                </FlexBoxItem>
                {/* <FlexBoxItem basis="200px">
								<CalendarForm
									label=""
									required={true}
									date={targetDateFrom}
									dateFormat="YYYY年MM月DD日"
									changeDate={(date: Date) => {
										setTargetDateFrom(date);
										sessionStorage.setItem("applicationTargetDayFrom", moment(date).format("YYYY-MM-DD"));
									}}
								/>
							</FlexBoxItem>
							<div css={styles.betweenDays}>～</div>
							<FlexBoxItem basis="200px">
								<CalendarForm
									label=""
									required={true}
									date={new Date(targetDateTo)}
									dateFormat="YYYY年MM月DD日"
									changeDate={(date: Date) => {
										setTargetDateTo(date);
										sessionStorage.setItem("applicationTargetDayTo", moment(date).format("YYYY-MM-DD"));
									}}
								/>
							</FlexBoxItem> */}
              </FlexBox>
              <OrganizationFilter orgCode="" setOrgCode={(arg: string) => {}} />
              <EmploymentFilter
                employmentId={
                  sessionStorage.getItem("loginUser.employmentId") || ""
                }
                setEmploymentId={(arg: string) => {}}
              />
            </div>
            <TabControl
              items={[
                {
                  value: SelectType.applying,
                  display: `申請中(${applyingStampList.length +
                    applyingHolidayList.length +
                    applyingOvertimeList.length +
                    applyingHolidayWorkList.length +
                    applyingTransferList.length +
                    applyingAgreement36List.length +
                    applyingWorkScheduleChangeList.length}件)`,
                },
                {
                  value: SelectType.approved,
                  display: `承認済(${approvedStampList.length +
                    approvedHolidayList.length +
                    approvedOvertimeList.length +
                    approvedHolidayWorkList.length +
                    approvedTransferList.length +
                    approvedAgreement36List.length +
                    approvedWorkScheduleChangeList.length}件)`,
                },
                {
                  value: SelectType.rejected,
                  display: `差戻(${rejectedStampList.length +
                    rejectedHolidayList.length +
                    rejectedOvertimeList.length +
                    rejectedHolidayWorkList.length +
                    rejectedTransferList.length +
                    rejectedAgreement36List.length +
                    rejectedWorkScheduleChangeList.length}件)`,
                },
              ]}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              //   customStyle={css({
              // 	margin: "0px",
              // 	padding: "8px 16px",
              // 	boxSizing: "border-box",
              // 	width: "100%",
              // 	overflow: "auto",
              //   })}
            />
          </div>

          <div css={styles.wrapAccordion}>
            {/* 要承認 */}
            {selectType === SelectType.applying && (
              <ApplyingTab
                stampList={applyingStampList}
                holidayList={applyingHolidayList}
                overtimeList={applyingOvertimeList}
                holidayWorkList={applyingHolidayWorkList}
                transferList={applyingTransferList}
                agreement36List={applyingAgreement36List}
                workScheduleList={applyingWorkScheduleChangeList}
                // fetch approved API
                fetchApprovedStampList={fetchApprovedStampList}
                fetchApprovedHolidayList={fetchApprovedHolidayList}
                fetchApprovedOvertimeList={fetchApprovedOvertimeList}
                fetchApprovedHolidayWorkList={fetchApprovedHolidayWorkList}
                fetchApprovedTransferList={fetchApprovedTransferList}
                fetchApprovedAgreement36List={fetchApprovedAgreement36List}
                fetchApprovedWorkScheduleList={
                  fetchApprovedWorkScheduleChangeList
                }
                // fecth rejected API
                fetchRejectedStampList={fetchRejectedStampList}
                fetchRejectedHolidayList={fetchRejectedHolidayList}
                fetchRejectedOvertimeList={fetchRejectedOvertimeList}
                fetchRejectedHolidayWorkList={fetchRejectedHolidayWorkList}
                fetchRejectedTransferList={fetchRejectedTransferList}
                fetchRejectedAgreement36List={fetchRejectedAgreement36List}
                fetchRejectedWorkScheduleList={
                  fetchRejectedWorkScheduleChangeList
                }
                // set list
                setApplyingTransferList={setApplyingTransferList}
                setApplyingStampList={setApplyingStampList}
                setApplyingHolidayList={setApplyingHolidayList}
                setApplyingOvertimeList={setApplyingOvertimeList}
                setApplyingHolidayWorkList={setApplyingHolidayWorkList}
                setApplyingAgreement36List={setApplyingAgreement36List}
                setApplyingWorkScheduleList={setApplyingWorkScheduleChangeList}
              />
            )}

            {/* 承認済 */}
            {selectType === SelectType.approved && (
              <ApprovedTab
                stampList={approvedStampList}
                holidayList={approvedHolidayList}
                overtimeList={approvedOvertimeList}
                holidayWorkList={approvedHolidayWorkList}
                transferList={approvedTransferList}
                agreement36List={approvedAgreement36List}
                workScheduleList={approvedWorkScheduleChangeList}
              />
            )}

            {/* 差戻済 */}
            {selectType === SelectType.rejected && (
              <RejectedTab
                stampList={rejectedStampList}
                holidayList={rejectedHolidayList}
                overtimeList={rejectedOvertimeList}
                holidayWorkList={rejectedHolidayWorkList}
                transferList={rejectedTransferList}
                agreement36List={rejectedAgreement36List}
                workScheduleList={rejectedWorkScheduleChangeList}
              />
            )}
          </div>
        </FormContents>
      </BlockUI>
    </AttendApplicationListContext.Provider>
  );
};

export default AttendApplicationList;
