import { hokuyoCompanyCode } from 'const';

export const checkPermissionAccessHokuyo = () => {
    const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = 'win-board-localstorage-shop-info';
    const shopCodeSpecify = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
    const flagHokuyo = shopCodeSpecify && JSON.parse(shopCodeSpecify).companyCode === hokuyoCompanyCode;
    return flagHokuyo && process.env.REACT_APP_LOGO_FLAG === '0';
}

export const checkPermissionAccessRecorder = () => {
    const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = 'win-board-localstorage-shop-info';
    const shopCodeSpecify = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
    return shopCodeSpecify;
}