import React, { SVGProps } from 'react';

const Onboarding: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M32.2005 31.5802L27.6805 27.0602C27.2705 26.6502 27.2705 25.9802 27.6805 25.5702C28.0905 25.1602 28.7605 25.1602 29.1705 25.5702L35.4905 31.8902C35.9005 32.3002 35.9005 32.9702 35.4905 33.3802L29.1705 39.7002C28.7605 40.1102 28.0905 40.1102 27.6805 39.7002C27.2705 39.2902 27.2705 38.6202 27.6805 38.2102L32.2005 33.6902H22.1105C21.5305 33.6902 21.0605 33.2202 21.0605 32.6402C21.0605 32.0602 21.5305 31.5902 22.1105 31.5902H32.2005V31.5802Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.83 21.14L23.35 18.8C25.79 16.87 27.36 13.89 27.36 10.53C27.37 4.71 22.66 0 16.84 0C11.03 0 6.32 4.71 6.32 10.53C6.32 13.9 7.9 16.9 10.37 18.83L3.09 22.14C1.21 22.98 0 24.86 0 26.92V28.42C0 31.33 2.36 33.68 5.26 33.68H15.79C16.37 33.68 16.84 33.21 16.84 32.63C16.84 32.05 16.37 31.58 15.79 31.58H5.26C3.52 31.58 2.1 30.17 2.1 28.42V26.92C2.1 25.68 2.82 24.56 3.95 24.05L12.54 20.14C13.85 20.73 15.3 21.05 16.83 21.05C18.36 21.05 19.82 20.72 21.13 20.13L27.99 23.07C28.52 23.3 29.14 23.05 29.37 22.52C29.62 21.98 29.37 21.37 28.83 21.14ZM16.84 18.95C12.19 18.95 8.42 15.18 8.42 10.53C8.42 5.88 12.19 2.11 16.84 2.11C21.49 2.11 25.26 5.88 25.26 10.53C25.26 15.18 21.49 18.95 16.84 18.95Z" fill="#007BC3"/>
  </svg>
);

export default Onboarding;
