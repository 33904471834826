import React, { useMemo, useState } from 'react';
import TabControl from 'components/atoms/TabControl';
import LableTitle from 'components/atoms/LableTitle';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import HolidayMasterPage from './HolidayMasterPage';
import SpecialDateMasterPage from './SpecialDateMasterPage';
import BusinessMasterPage from './BusinessMasterPage';
import VacationMasterPage from './VacationMasterPage';
import TimeZonePage from './TimeZonePage';
import PrescribedHoursPage from './PrescribedHoursPage';
import PaidGrantPatternPage from './PaidGrantPatternPage';
import TimeSettingPage from './TimeSettingsPage';
import PrescribedDaysPage from './PrescribedDaysPage';

const AgreementMasterPage: React.FC = () => {
  const currentTab = sessionStorage.getItem('currentTab') ? sessionStorage.getItem('currentTab')! : '1';
  const [value, setValue] = useState(currentTab);
  const [toastMessage, setToastMessage] = useState('');

  const result = useMemo(() => {
    sessionStorage.setItem('currentTab', value);

    switch (value) {
      case '1': {
        setToastMessage('労務規約項目マスタは雇用形態を登録するのに必要な項目です。');
        return <HolidayMasterPage />;
      }
      case '2': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <SpecialDateMasterPage />;
      }
      case '3': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <VacationMasterPage />;
      }
      case '4': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <PaidGrantPatternPage />;
      }
      case '5': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <BusinessMasterPage />;
      }
      case '6': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <TimeZonePage />;
      }
      case '7': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <PrescribedDaysPage />;
      }
      case '8': {
        setToastMessage('労務規約項目マスタは給与算出の際に登録が必要になる項目です。');
        return <PrescribedHoursPage />;
      }
      default:
        return <TimeSettingPage />;
    }
  }, [value]);

  return (
    <SidebarTemplate pageTitle="労務規約項目マスタ">
      <LableTitle title={toastMessage} />
      <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <TabControl
          items={[
            { value: '1', display: '祝日' },
            { value: '2', display: '特賃日' },
            { value: '3', display: '休暇' },
            { value: '4', display: '有給' },
            { value: '5', display: '業務' },
            { value: '6', display: '時間帯' },
            { value: '7', display: '所定日数' },
            { value: '8', display: '所定時間' },
            { value: '9', display: '勤怠設定' },
          ]}
          setValue={(val: string) => {
            if (val === '1') {
              sessionStorage.setItem('selectedOrgCodeHolidayMaster', 'all');
              sessionStorage.setItem('selectedYearHolidayMaster', new Date().getFullYear().toString());
            }
            setValue(val);
          }}
          currentValue={value}
        />
      </div>

      <div>
        {result}
      </div>
    </SidebarTemplate>

  );
};

export default AgreementMasterPage;
