/* eslint-disable eqeqeq */
import React, { useCallback, useRef, useEffect, useState } from "react";
import { gridCol, utilityColor } from "components/styles";
import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import moment from "moment";
import { ContextMenuTrigger, ContextMenu, MenuItem, SubMenu } from "react-contextmenu";
import { css } from "@emotion/core";

const tableStyle = css({
  width: "100%",
  overflow: "hidden",
});

const theadStyle = css({
  display: "table",
  width: "100%",
  tableLayout: "fixed",
});

const tbodyStyle = css({
  display: "block",
  maxHeight: "calc(100dvh - 315px - 65px - 40px)",
  overflowY: "auto",
});

const trStyle = css({
  display: "table",
  width: "100%",
  tableLayout: "fixed",
  height: "3rem",
});

const thStyle = css({
  padding: "1rem",
  height: "40px",
  borderCollapse: "collapse",
});

const tdStyle = css({
  padding: "1rem",
  borderCollapse: "collapse",
});

const AttendStampMobileTableNoData: React.FC<{
  eventScroll: any;
  business: Array<any>;
  term: Array<any>;
  targetDateFrom: Date;
  targetDateTo: Date;
  selectOrgCode: String;
  attendFlag: String;
  stampItems: any;
  emp: any;
  staffCode: any;
  staffList: any;
}> = ({
  eventScroll,
  business,
  term,
  targetDateFrom,
  targetDateTo,
  selectOrgCode,
  attendFlag,
  stampItems,
  emp,
  staffCode,
  staffList,
}) => {
  const history = useHistory();
  const refTableScroll = useRef(Object());
  const refContextMenu = useRef(Object());
  const [offsetWidthTable, setOffsetWidthTable] = useState(0);
  const [menuType] = useState("SIMPLE");

  const handleClickMenu: (e: any, item: any) => void = useCallback(
    (e, item) => {
      const { key, currentItem } = item;
      const targetDate = moment(currentItem.targetDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      const dispStaffName = staffList.filter((staff: any) => staff.value === staffCode)[0].label;
      const orgCode = String(selectOrgCode);

      // 自画面用パラメータ
      sessionStorage.setItem("attendStampList.targetDateFrom", moment(targetDateFrom).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.targetDateTo", moment(targetDateTo).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.orgCode", orgCode);
      sessionStorage.setItem("attendStampList.staffCode", staffCode);
      sessionStorage.setItem("attendStampList.dispStaffName", dispStaffName);
      sessionStorage.setItem("attendStampList.attendFlag", String(attendFlag));

      // 各申請画面に渡すパラメータ
      sessionStorage.setItem("application.staffCode", staffCode);
      sessionStorage.setItem("application.dispStaffName", dispStaffName);
      sessionStorage.setItem("application.targetDate", targetDate);
      if (currentItem.orgCode && key == "applicationModify1") {
        // 修正申請
        sessionStorage.setItem("application.orgCode", currentItem.orgCode);
      } else if (key == "application3") {
        // 残業申請
        sessionStorage.setItem("application.orgCode", currentItem.overtimeApplicationOrgCode);
      } else if (key == "application4") {
        // 休日出勤申請
        sessionStorage.setItem("application.orgCode", currentItem.holidayWorkApplicationOrgCode);
      } else {
        sessionStorage.setItem("application.orgCode", orgCode);
      }

      if (key === "applicationModify1" || key === "applicationModify2") {
        const { achievementId, modifierApplicationId, stampVersion, shiftId } = currentItem;

        // 打刻申請用パラメータ
        if (achievementId && key == "applicationModify1") {
          sessionStorage.setItem("application.achievementId", achievementId);
        } else {
          sessionStorage.removeItem("application.achievementId");
        }
        if (stampVersion && key == "applicationModify1") {
          sessionStorage.setItem("application.stampVersion", stampVersion);
        } else {
          sessionStorage.removeItem("application.stampVersion");
        }
        if (modifierApplicationId && key == "applicationModify1") {
          sessionStorage.setItem("application.modifierApplicationId", modifierApplicationId);
        } else {
          sessionStorage.removeItem("application.modifierApplicationId");
        }
        if (shiftId && key == "applicationModify2") {
          sessionStorage.setItem("application.shiftId", shiftId);
        } else {
          sessionStorage.removeItem("application.shiftId");
        }

        history.push("/attendStampModificationApplication");
      } else if (key === "application2") {
        // 休暇申請
        sessionStorage.setItem("application.achievementId", currentItem.achievementId);
        history.push("/attendHolidayApplication");
      } else if (key === "application3") {
        // 残業申請
        history.push("/attendOvertimeApplication");
      } else if (key === "application4") {
        // 休日出勤申請
        history.push("/attendHolidayworkApplication");
      } else if (key === "application5") {
        // 振替申請
        history.push("/attendTransferApplication");
      }
    },
    [attendFlag, history, selectOrgCode, staffCode, staffList, targetDateFrom, targetDateTo],
  );

  function statusMenuItem(status: boolean) {
    const items: any = {
      applicationModify1: status,
      applicationModify2: status,
      application2: status,
      application3: status,
      application4: status,
      application5: status,
    };

    return items;
  }
  const [menuItemVisible, setMenuItemVisible] = useState(statusMenuItem(false));
  const [menuItemDisable, setMenuItemDisable] = useState(statusMenuItem(true));

  const [currentItem, setCurrentItem] = useState(Object());
  const handleDispMenu: (e: any, data: any) => void = useCallback(
    (e, data) => {
      if (data.belongOrgFix === "1") {
        setMenuItemVisible(statusMenuItem(false));
        setMenuItemDisable(statusMenuItem(true));
      } else {
        setCurrentItem(data);
        setMenuItemVisible({
          applicationModify1: true,
          applicationModify2: true,
          application2: attendFlag == "0",
          application3: data.stampEmployment.useOverTimeApplication != 0,
          application4: data.stampEmployment.necessaryHolidayWorkApplication == true && attendFlag == "0",
          application5: data.stampEmployment.useTransferApplication != 0 && attendFlag == "0",
        });
        setMenuItemDisable({
          applicationModify1:
            data.achievementId === undefined || data.hasStampFlg == false || data.attendanceOrgFix == "1",
          applicationModify2: false,
          application2: data.attendanceOrgFix == "1",
          application3: data.attendanceOrgFix == "1",
          application4: data.attendanceOrgFix == "1",
          application5: data.attendanceOrgFix == "1",
        });
      }

      const element = refContextMenu.current.elem;
      const ev = new MouseEvent("contextmenu", {
        bubbles: true,
        cancelable: false,
        view: window,
        button: 2,
        buttons: 0,
        clientX: e.target.getBoundingClientRect().x + 50,
        clientY: e.target.getBoundingClientRect().y,
      });

      e.target.click();
      element.dispatchEvent(ev);
    },
    [attendFlag],
  );

  useEffect(() => {
    const width = refTableScroll.current ? refTableScroll.current.offsetWidth : 0;
    setOffsetWidthTable(width);
  }, [refTableScroll.current.offsetWidth]);

  return (
    <div className="wrap-attend-stamp-list">
      <div style={{ display: "none" }}>
        <ContextMenuTrigger ref={refContextMenu} id={menuType} holdToDisplay={1000}>
          &nbsp;
        </ContextMenuTrigger>
      </div>
      <div
        style={{
          display:
            menuItemVisible.applicationModify1 ||
            menuItemVisible.applicationModify2 ||
            menuItemVisible.application2 ||
            menuItemVisible.application3 ||
            menuItemVisible.application4 ||
            menuItemVisible.application5
              ? "block"
              : "none",
        }}
      >
        <ContextMenu id={menuType}>
          {(menuItemVisible.applicationModify1 || menuItemVisible.applicationModify2) && (
            <SubMenu title="打刻修正申請">
              {menuItemVisible.applicationModify1 && (
                <MenuItem
                  disabled={menuItemDisable.applicationModify1}
                  onClick={handleClickMenu}
                  data={{ key: "applicationModify1", currentItem }}
                >
                  修正申請
                </MenuItem>
              )}
              {menuItemVisible.applicationModify2 && (
                <MenuItem
                  disabled={menuItemDisable.applicationModify2}
                  onClick={handleClickMenu}
                  data={{ key: "applicationModify2", currentItem }}
                >
                  追加申請
                </MenuItem>
              )}
            </SubMenu>
          )}
          {menuItemVisible.application2 && (
            <div>
              <MenuItem divider={true} />
              <MenuItem
                disabled={menuItemDisable.application2}
                onClick={handleClickMenu}
                data={{ key: "application2", currentItem }}
              >
                休暇申請
              </MenuItem>
            </div>
          )}
          {menuItemVisible.application3 && (
            <div>
              <MenuItem divider={true} />
              <MenuItem
                disabled={menuItemDisable.application3}
                onClick={handleClickMenu}
                data={{ key: "application3", currentItem }}
              >
                残業申請
              </MenuItem>
            </div>
          )}
          {menuItemVisible.application4 && (
            <div>
              <MenuItem divider={true} />
              <MenuItem
                disabled={menuItemDisable.application4}
                onClick={handleClickMenu}
                data={{ key: "application4", currentItem }}
              >
                休日出勤申請
              </MenuItem>
            </div>
          )}
          {menuItemVisible.application5 && (
            <div>
              <MenuItem divider={true} />
              <MenuItem
                disabled={menuItemDisable.application5}
                onClick={handleClickMenu}
                data={{ key: "application5", currentItem }}
              >
                振替申請
              </MenuItem>
            </div>
          )}
        </ContextMenu>
      </div>
      <div style={{ display: "flex" }}>
        <div className="top-left-area-time">
          <table
            className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-right-none"
            style={{ width: gridCol.grid12 }}
          >
            <thead>
              <tr>
                <th className="head-line4-height" style={{ width: "80px", height: "115px" }}>
                  修正
                </th>
                <th className="head-line4-height" style={{ width: "120px" }}>
                  日付
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="topRightAreaTime" onScroll={eventScroll} style={{ width: "calc(100% - 197px)", overflow: "auto" }}>
          <div className="maxContent" ref={refTableScroll}>
            <table
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-left-none"
              id="top-right-time"
            >
              <thead>
                <tr>
                  <th className="head-line4-height" style={{ width: "40px", height: "115px" }}>
                    確定
                  </th>
                  <th style={{ width: "180px" }}>勤務予定</th>
                  <th style={{ width: "100px" }}>休暇</th>
                  {emp.distinctionHoliday && (
                    <th style={{ width: "60px" }}>
                      休日
                      <br />
                      種別
                    </th>
                  )}
                  <th style={{ width: "60px" }}>休憩</th>
                  <th style={{ width: "80px" }}>出勤</th>
                  <th style={{ width: "80px" }}>退勤</th>

                  {business &&
                    business.map((businessItem: any) => (
                      <th key={businessItem.businessCode} style={{ width: "120px" }}>
                        {businessItem.businessName}
                      </th>
                    ))}
                  {term &&
                    term.map((termItem: any) => (
                      <th key={termItem.termId} style={{ width: "120px" }}>
                        {termItem.termName}
                      </th>
                    ))}
                  <th style={{ width: "200px" }}>出勤組織</th>
                  <th style={{ width: "400px" }}>備考</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div className="table-content">
        <div style={{ display: "inline-flex", width: gridCol.grid12 }}>
          <div className="bottomLeftAreaTime">
            <table className="table-condensed nowrap" css={tableStyle}>
              <thead css={theadStyle}>
                <tr>
                  <th css={thStyle} className="border-left-none" style={{ width: 68 }}>
                    修正
                  </th>
                  <th css={thStyle} style={{ width: 70 }}>
                    日付
                  </th>
                  <th css={thStyle} style={{ width: "auto" }}>
                    勤務予定
                  </th>
                  <th css={thStyle} style={{ width: 59 }}>
                    休暇
                  </th>
                  <th css={thStyle} style={{ width: 50 }}>
                    出勤
                  </th>
                  <th css={thStyle} className="border-right-none" style={{ width: 50 }}>
                    退勤
                  </th>
                </tr>
              </thead>
              <tbody css={tbodyStyle}>
                {stampItems?.map((item: any, index: any) => {
                  return (
                    <tr key={"_row_" + index} css={trStyle}>
                      <td className="head-line1-height" style={{ width: "80px" }}>
                        {item.applying != 0 && item.employmentId != null && (
                          <button className="button-type-2" type="button" onClick={(e) => handleDispMenu(e, item)}>
                            申請
                          </button>
                        )}
                        {item.applying == 0 && item.employmentId != null && (
                          <button className="button-type-1" type="button" onClick={(e) => handleDispMenu(e, item)}>
                            申請中
                          </button>
                        )}
                      </td>
                      {(item.alert == "0" || !item.alert || item.modifierDeleted) && (
                        <td style={{ width: "120px" }}>{item.day}</td>
                      )}
                      {item.alert == "1" && !item.modifierDeleted && (
                        <td style={{ width: "90px" }}>
                          {item.day}
                          <div title={item.alertMsg}>
                            <Icon type="warning" color={utilityColor.yellow} size="20px" />
                          </div>
                        </td>
                      )}
                      {item.alert == "2" && !item.modifierDeleted && (
                        <td style={{ width: "90px" }}>
                          {item.day}
                          <div title={item.alertMsg}>
                            <Icon type="remove" color={utilityColor.error} size="20px" />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttendStampMobileTableNoData;
