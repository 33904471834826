import { useEffect, useState } from 'react';
import AttendHelpListDomain from 'domain/master/attend/attendHelpList';
import attendHelpList, { subTotalList, searchDate } from 'api/attendHelpList';
import { useFormik } from 'formik';
import { getTrees } from 'api/organization';
import { OptionType } from 'components/atoms/Select';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';


let errors = 0;

export const useAttendHelpList = () => {
  const [getSearchDate, setSearchDate] = useState([]);

  const onSubmit = () => {
  };

  const { errorNotification } = useToastNotification();

  const formik = useFormik({
    initialValues: AttendHelpListDomain.generateInitial(),
    onSubmit,
    validateOnChange: true,
    validate: async (values) => {
      const targetDateFrom = moment(new Date(values.targetDateFrom)).format('YYYY-MM-DD');
      const targetDateTo = moment(new Date(values.targetDateTo)).format('YYYY-MM-DD');
      const dateFrom = moment([new Date(targetDateFrom).getFullYear(), new Date(targetDateFrom).getMonth(), new Date(targetDateFrom).getDate()]);
      const dateTo = moment([new Date(targetDateTo).getFullYear(), new Date(targetDateTo).getMonth(), new Date(targetDateTo).getDate()]);
      if (dateTo.diff(dateFrom, 'days') > 365) {
        errorNotification('対象期間の範囲は１年以内で入力してください');
        errors = 1;
        return false;
      }
      errors = 0;
    },
  });

  useEffect(() => {
    searchDate(false, '')
      .then((response: any) => {
        setSearchDate(response);
        if (response) {
          formik.setFieldValue('setTargetDateFrom', new Date(new Date(response[0]).getFullYear(), new Date(response[0]).getMonth(), new Date(response[0]).getDate()));
          formik.setFieldValue('setTargetDateTo', new Date(new Date(response[1]).getFullYear(), new Date(response[1]).getMonth(), new Date(response[1]).getDate()));
        }
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formik,
    getSearchDate
  };
};

export const useGetAttendHelpList = (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: string,
  supportDestinationOrg: string,
) => {
  const [getAttendHelpList, setAttendHelpList] = useState<Array<AttendHelpListDomain>>([]);
  useEffect(() => {
    if (!errors) {
      attendHelpList(targetDateFrom, targetDateTo, supportSourceOrg, supportDestinationOrg).then((response: Array<any>) => {
        setAttendHelpList(response.map((result) => new AttendHelpListDomain(result)));
      });
    }
  }, [supportDestinationOrg, supportSourceOrg, targetDateFrom, targetDateTo]);
  return { getAttendHelpList, setAttendHelpList };
};

export const useGetSubTotalList = (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: string,
  supportDestinationOrg: string,
) => {
  const [getSubTotalList, setSubTotalList] = useState();
  useEffect(() => {
    if (!errors) {
      subTotalList(targetDateFrom, targetDateTo, supportSourceOrg, supportDestinationOrg)
        .then((response: any) => {
          setSubTotalList(response);
        });
    }
  }, [supportDestinationOrg, supportSourceOrg, targetDateFrom, targetDateTo]);
  return { getSubTotalList, setSubTotalList };
};

export const useSearchDate = (closeDateFlg: any, targetDate?: any) => {
  const [getSearchDate, setSearchDate] = useState([]);
  useEffect(() => {
    searchDate(closeDateFlg, targetDate || '')
      .then((response: any) => {
        if (response !== undefined) {
          setSearchDate(response);
        }
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { getSearchDate };
};

export const useGetTrees = (targetDateFrom: any, targetDateTo: any) => {
  const [getOrgTree, setOrgTree] = useState<Array<OptionType>>([]);

  useEffect(() => {
    if (!errors) {
      getTrees(targetDateFrom, targetDateTo).then((response) => {
        const orgs = response.map((res) => ({
          value: res.orgCode,
          label: `${res.orgCode} ${res.orgName}`,
        }));
        orgs.unshift({
          value: '',
          label: '-',
        });
        setOrgTree(orgs);
      });
    }
  }, [targetDateFrom, targetDateTo]);
  return {
    getOrgTree,
  };
};

export default useAttendHelpList;
