import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { createOrUpdateAttendBusiness, getAttendBusiness } from 'api/businessMaster';
import { useHistory } from 'react-router-dom';
import BusinessDomain from 'domain/master/labor/business';
import useToastNotification from 'hooks/useToastNotification';

type BusinessDomainKey = keyof Pick<BusinessDomain, 'businessCode'|'businessName'|'businessBgColor'>;

export const useBusinessAddForm = (businessId?: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [business, setBusiness] = useState(BusinessDomain.generateInitial());
  const [submitedBusinessG, setSubmitedBusinessG] = useState({});
  const { successNotification, errorNotification } = useToastNotification();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.businessCode === null) {
      errorNotification('業務コードを入力してください。');
      return;
    }
    if (val.businessName === null) {
      errorNotification('日付を入力してく業務名を入力してください。ださい。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const submitedBusiness = {
      businessId: val.businessId,
      businessCode: val.businessCode,
      businessName: val.businessName,
      businessBgColor: val.businessBgColor,
      createUser: staffName,
      updateUser: staffName,
    };
    createOrUpdateAttendBusiness(companyCode, false, submitedBusiness)
      .then((response: any) => {
        successNotification('登録しました。');
        history.goBack();
      })
      .catch((error: any) => {
        if (error.response.status === 406) {
          const errorString : string = error.response.data.errors[0].defaultMessage;

          setSubmitedBusinessG(submitedBusiness);
          setConfirmModalOpen(false);
          errorNotification(errorString);
        } else {
          setConfirmModalOpen(false);
          if (error.response.data != null && error.response.data.errors.count !== 0) {
            errorNotification(error.response.data.errors[0].defaultMessage);
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: business,
    onSubmit,
  });

  useEffect(() => {
    if (businessId !== undefined) {
      getAttendBusiness(companyCode, businessId).then((response: any) => {
        setBusiness(new BusinessDomain(response));
        formik.setValues(new BusinessDomain(response));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
    setConfirmModalResendOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const submitConfirmResendModal = useCallback(() => {
    createOrUpdateAttendBusiness(companyCode, true, submitedBusinessG)
      .then((response: any) => {
        history.goBack();
      })
      .catch((error2: any) => {
        if (error2.response.data.errors.count !== 0) {
          errorNotification(error2.response.data.errors[0].defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    setConfirmModalResendOpen(false);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode, history, submitedBusinessG]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    confirmModalResendOpen,
    closeConfirmResendModal,
    submitConfirmResendModal,
    confirmModalContent,
    history,
  };
};


export default useBusinessAddForm;
