import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface StockPaidHolidayGrantHistory {
    StockPaidHolidayGrantHistoryId: string,
    companyCode: string,
    orgCode: string,
    orgName: string,
    staffCode: string,
    staffName: string,
    grantDate: Date,
    grantDateStr: string,
    disposalDate: Date,
    disposalDateStr: string,
    grantDaysNum: string,
    remainDaysNum: string,
}


export default class StockPaidHolidayGrantHistoryDomain {
  constructor(private rawData: StockPaidHolidayGrantHistory) {
    // do nothing
  }

  static generateInitial(): StockPaidHolidayGrantHistoryDomain {
    return new StockPaidHolidayGrantHistoryDomain({
      StockPaidHolidayGrantHistoryId: '',
      companyCode: '',
      orgCode: '',
      orgName: '',
      staffCode: '',
      staffName: '',
      grantDate: new Date(),
      grantDateStr: '',
      disposalDate: new Date(),
      disposalDateStr: '',
      grantDaysNum: '0',
      remainDaysNum: '0',
    });
  }

  getRawData(): StockPaidHolidayGrantHistory {
    return this.rawData;
  }

  get StockPaidHolidayGrantHistoryId(): string {
    return this.rawData.StockPaidHolidayGrantHistoryId;
  }

  set StockPaidHolidayGrantHistoryId(StockPaidHolidayGrantHistoryId: string) {
    this.rawData.StockPaidHolidayGrantHistoryId = StockPaidHolidayGrantHistoryId;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  // get
  get grantDate(): Date {
    return this.rawData.grantDate;
  }

  get grantDateStr(): string {
    return this.rawData.grantDateStr;
  }

  get grantDateToStr(): string {
    return moment(moment(this.rawData.grantDate).toDate()).format('YYYY/MM/DD');
  }

  get disposalDate(): Date {
    return this.rawData.disposalDate;
  }

  get disposalDateStr(): string {
    return this.rawData.disposalDateStr;
  }

  set grantDaysNum(grantDaysNum:string) {
    this.rawData.grantDaysNum = grantDaysNum;
  }

  get grantDaysNum():string {
    return `${this.rawData.grantDaysNum}日`;
  }

  set remainDaysNum(remainDaysNum:string) {
    this.rawData.remainDaysNum = remainDaysNum;
  }

  get remainDaysNum():string {
    return `${this.rawData.remainDaysNum}日`;
  }
  
}