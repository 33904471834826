/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textColor } from 'components/styles';

const styles = {
  pageTitle: css({
    padding: '15px 10px',
    // display: 'flex',
    // alignItems: 'center',
  }),
  title: css({
    padding: '0',
    margin: '0',
    color: textColor.main,
    fontSize: '16px',
    lineHeight: '24px'
  }),

};

const LableTitle: React.FC<{
  title: string;
  customStyle?:any,
}> = ({
  title,
  customStyle,
}) => (
  <section css={[styles.pageTitle,customStyle]}>
    <div css={styles.title}>
      {title}
    </div>
  </section>
);

export default LableTitle;
