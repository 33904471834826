import axios from "axios";
import ApiClient from "./ApiClient";
import { stringify } from "querystring";

export const getItem = async (mail: string, siteType: string, companyCode: string): Promise<any> => {
  // TODO 仮リクエスト
  const params = {
    mail,
    siteType,
  };

  const response = await ApiClient.get(companyCode ? `/v3/loginuser/${companyCode}` : "/v2/loginuser", params);

  return response.data;
};

export const getInfomationOrgLocalStorage = async (companyCode: string, specifyCode: string): Promise<Array<any>> => {
  const params = {
    specifyCode,
  };
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/whereami/${companyCode}`, {
    params,
    data: {},
  });
  return response.data;
};

export const isValidToken = async (): Promise<Array<any>> => {
  const params = {};
  const response = await ApiClient.get("/v1/tokenExpiration", params);
  return response.data;
};

export const getUserLoginStatus = async (): Promise<any> => {
  const params = {};

  const companyCode = sessionStorage.getItem("loginUser.companyCode");

  const response = await ApiClient.get(`/v2/loginStatus/${companyCode}`, params);
  return response.data;
};

export const logout = async (): Promise<any> => {
  const params = {};

  const companyCode = sessionStorage.getItem("loginUser.companyCode");

  const response = await ApiClient.get(`/v3/logout/${companyCode}`, params);

  return response.data;
};

export default getItem;

export const saveLoginHistory = async (
  mail: String,
  loginResult: boolean,
  failPassword: String,
  failReason: String,
  companyCode: String,
): Promise<any> => {
  const prams = {
    mail,
    loginResult,
    failPassword,
    failReason,
    companyCode: companyCode ? companyCode : '',
  };
  const reponse = await ApiClient.postLoginHistory("/v1/login/history", {}, prams);
  return reponse.data;
};

export const saveAccessHistory = async (companyCode: String, staffCode: String, accessUrl: String): Promise<any> => {
  if (companyCode === "" || staffCode === "") {
    return null;
  }
  const prams = {
    companyCode,
    staffCode,
    accessUrl,
  };
  const reponse = await ApiClient.post("/v1/access/history", {}, prams);
  return reponse.data;
};

// sign up
export const signUpSingleVersion = async (mail: string, password: string, companyCode: string): Promise<any> => {
  const params = {
    mail,
    password,
    companyCode: companyCode ? companyCode : undefined,
  };
  const response = await ApiClient.postSignUp(`/v2/signup`, {}, params);

  return response;
};

export type CognitoInfo = {
  cognitoIdentityPoolId: string;
  cognitoClientId: string;
  cognitoResion: string;
  cognitoUserPoolId: string;
  companyCode: string;
};

export const getCognitoInfo = async (companyCode: string, mail: string): Promise<CognitoInfo> => {
  const params = {
    mail,
    companyCode,
  };

  try {
    const response = await ApiClient.getNoAuthV2(`/v1/cognito/info?${stringify(params)}`);

    return response.data as CognitoInfo;
  } catch (error) {
    return {
      cognitoIdentityPoolId: "",
      cognitoClientId: "",
      cognitoResion: "",
      cognitoUserPoolId: "",
      companyCode: "",
    };
  }
};
