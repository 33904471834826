import React from "react";
import { useParams } from "react-router-dom";
import FormContents from "components/atoms/Form/FormContents";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import useMediaMasterForm from "./hooks";

const PaymentImportCreateForm: React.FC = () => {
  const { paymentMediaMstCode } = useParams();

  const {
    formik,
    confirmModalOpen,
    contentModalCustom,
    closeConfirmModal,
    history,
    confirmModalCustomOpen,
    closeConfirmModalCustom,
  } = useMediaMasterForm();

  // const postTypeOptions = useMediaCaregoryOptions();
  return (
    <FormContents>
      <p>支払メディアを登録します。コード、名称、並び順を入力してください。</p>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <TextForm
              label="コード"
              name="paymentMediaMstCode"
              value={formik.values.paymentMediaMstCode}
              onChange={formik.handleChange}
              errorMsg={formik.errors.paymentMediaMstCode}
              required={true}
              disable={!!paymentMediaMstCode}
            />
          </FormField>
          <FormField>
            <TextForm
              label="名称"
              name="paymentMediaMstName"
              value={formik.values.paymentMediaMstName}
              onChange={formik.handleChange}
              errorMsg={formik.errors.paymentMediaMstName}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              type="number"
              label="並び順"
              min="0"
              name="dispOrder"
              value={formik.values.dispOrder}
              onChange={formik.handleChange}
              errorMsg={formik.errors.dispOrder}
              required={true}
            />
          </FormField>
        </div>

        <FormSubmitArea>
          <div style={{ marginRight: "12px" }}>
            <Button text={paymentMediaMstCode ? "更新" : "支払メディアを登録"} onClick={formik.handleSubmit} />
          </div>
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.replace("/salesPaymentMedia/paymentMaster");
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={paymentMediaMstCode ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmModal
        title="確認メッセージ"
        open={confirmModalCustomOpen}
        closeHandler={closeConfirmModalCustom}
        submitText={paymentMediaMstCode ? "更新" : "登録"}
        content={contentModalCustom}
        onSubmit={formik.handleSubmit}
      />
    </FormContents>
  );
};
export default PaymentImportCreateForm;
