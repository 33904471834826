import React, { SVGProps } from 'react';
 
 const CheckInRemote: React.FC<SVGProps<SVGSVGElement>> = props => (
   <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g style={{mixBlendMode: 'soft-light'}}>
       <path fillRule="evenodd" clipRule="evenodd" d="M67.603 37.5358L57.233 32.8222C60.7432 30.0767 62.9995 25.8019 62.9995 21.0001C62.9995 12.7158 56.2838 6.00008 47.9995 6.00008C39.7152 6.00008 32.9995 12.7158 32.9995 21.0001C32.9995 25.8019 35.2558 30.0767 38.766 32.8222L28.396 37.5358C27.5889 37.9027 26.8689 38.4015 26.2569 38.9999H32.4222L41.8838 34.6992C43.7517 35.5344 45.8216 35.9987 48 35.9987C50.1784 35.9987 52.2483 35.5344 54.1162 34.6992L66.3621 40.2656C67.9686 40.9958 69 42.5976 69 44.3622V46.4987C69 48.984 66.9853 50.9987 64.5 50.9987H57.7414V54.0001H64.4995C68.6416 54.0001 71.9995 50.6422 71.9995 46.5001V44.3636C71.9995 41.4225 70.2805 38.7528 67.603 37.5358ZM48 33C54.6274 33 60 27.6274 60 21C60 14.3726 54.6274 9.00002 48 9.00002C41.3726 9.00002 36 14.3726 36 21C36 27.6274 41.3726 33 48 33Z" fill="white"/>
       <path fillRule="evenodd" clipRule="evenodd" d="M7.50059 45.0001C7.50059 40.858 10.8585 37.5001 15.0006 37.5001H51.0006C55.1427 37.5001 58.5006 40.858 58.5006 45.0001V63.0001C58.5006 67.1422 55.1427 70.5001 51.0006 70.5001H15.0006C10.8585 70.5001 7.50059 67.1422 7.50059 63.0001V45.0001ZM15.0006 40.5001C12.5153 40.5001 10.5006 42.5148 10.5006 45.0001V63.0001C10.5006 65.4854 12.5153 67.5001 15.0006 67.5001H51.0006C53.4859 67.5001 55.5006 65.4854 55.5006 63.0001V45.0001C55.5006 42.5148 53.4859 40.5001 51.0006 40.5001H15.0006Z" fill="white"/>
       <path fillRule="evenodd" clipRule="evenodd" d="M9 64.4999C8.17157 64.4999 7.5 65.1715 7.5 65.9999C7.5 66.8284 8.17157 67.4999 9 67.4999H57C57.8284 67.4999 58.5 66.8284 58.5 65.9999C58.5 65.1715 57.8284 64.4999 57 64.4999H55.7368V61.4999H57C59.4853 61.4999 61.5 63.5147 61.5 65.9999C61.5 68.4852 59.4853 70.4999 57 70.4999H9C6.51472 70.4999 4.5 68.4852 4.5 65.9999C4.5 63.5147 6.51472 61.4999 9 61.4999H10.2632V64.4999H9Z" fill="white"/>
       <path fillRule="evenodd" clipRule="evenodd" d="M32.9994 52.5C32.171 52.5 31.4994 53.1716 31.4994 54C31.4994 54.8284 32.171 55.5 32.9994 55.5C33.8278 55.5 34.4994 54.8284 34.4994 54C34.4994 53.1716 33.8278 52.5 32.9994 52.5ZM28.4994 54C28.4994 51.5147 30.5141 49.5 32.9994 49.5C35.4847 49.5 37.4994 51.5147 37.4994 54C37.4994 56.4853 35.4847 58.5 32.9994 58.5C30.5141 58.5 28.4994 56.4853 28.4994 54Z" fill="white"/>
       <path fillRule="evenodd" clipRule="evenodd" d="M26.9994 7.5C14.573 7.5 4.49941 17.5736 4.49941 30C4.49941 30.8284 3.82784 31.5 2.99941 31.5C2.17099 31.5 1.49941 30.8284 1.49941 30C1.49941 15.9167 12.9162 4.5 26.9994 4.5C27.8278 4.5 28.4994 5.17157 28.4994 6C28.4994 6.82843 27.8278 7.5 26.9994 7.5Z" fill="white"/>
       <path fillRule="evenodd" clipRule="evenodd" d="M27 14.0391C18.1885 14.0391 11.0454 21.1822 11.0454 29.9936C11.0454 30.822 10.3738 31.4936 9.54541 31.4936C8.71698 31.4936 8.04541 30.822 8.04541 29.9936C8.04541 19.5253 16.5316 11.0391 27 11.0391C27.8284 11.0391 28.5 11.7106 28.5 12.5391C28.5 13.3675 27.8284 14.0391 27 14.0391Z" fill="white"/>
       <path fillRule="evenodd" clipRule="evenodd" d="M26.9988 20.5898C21.8023 20.5898 17.5897 24.8024 17.5897 29.9989C17.5897 30.8273 16.9182 31.4989 16.0897 31.4989C15.2613 31.4989 14.5897 30.8273 14.5897 29.9989C14.5897 23.1455 20.1455 17.5898 26.9988 17.5898C27.8273 17.5898 28.4988 18.2613 28.4988 19.0898C28.4988 19.9182 27.8273 20.5898 26.9988 20.5898Z" fill="white"/>
     </g>
   </svg>
 );
 
 export default CheckInRemote;