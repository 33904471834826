/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import FormContents from 'components/atoms/Form/FormContents';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams, useHistory } from 'react-router-dom';
import FlexBox from 'components/atoms/FlexBox';
import CalendarForm from 'components/molecules/CalendarForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import Modal from 'components/molecules/Modal';
import EmploymentInputAddForm from './EmploymentInputAddForm';
import EmploymentInputHintDisplay from './EmploymentInputHintDisplay';
import { useEmploymentAddForm } from './hooks';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BlockUI from 'components/molecules/BlockUi';
import CalendarMonth from 'components/molecules/CalendarV1/CalendarMonth';

const styles = {
  flexBox: css({
    justifyContent: 'center',
  }),
  addon: css({
    display: 'block',
  }, css`
      label[class*="css"]{
        margin-bottom:20px
      }
    `
  ),
  calendar: css({
    position: 'relative',
    top: '-25px',
  }, css`
    input[disabled]{
      cursor:not-allowed;
      background-color:#dcdcdc
    }
  `)
}

const EmploymentAddForm: React.FC = () => {
  const history = useHistory();
  const { isEdit, employmentId, applyStartDate } = useParams();
  const {
    formik, confirmModalOpen, 
    closeConfirmModal, termMst, 
    stepper, setStepper, 
    setApplicationDate, applicationDate, 
    setIsSubmit, blocking, 
    applyDateChoose,
    setApplyDateChoose,
    detailRole
  } = useEmploymentAddForm(Number(isEdit), employmentId, applyStartDate);
  return (
    <BlockUI blocking={blocking}>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ float: 'left', overflow: 'auto', width: '100%' }}>
            <div style={{ minWidth: '100%' }}>
              <FormContents>
                <EmploymentInputAddForm formik={formik} termMst={termMst} stepper={stepper} setStepper={setStepper} setIsSubmit={setIsSubmit} />
                <FormSubmitArea customStyle={css({margin: '20px 0 70px'})}>
                  <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <FlexBox
                      customStyle={styles.flexBox}
                    >
                      {
                        ((isEdit === '1' || !employmentId) && stepper > 1) ? (
                          <FlexBoxItem width="140px">
                            <Button text={'前へ'}
                              onClick={() =>
                                setStepper(stepper - 1)
                              } />
                          </FlexBoxItem>
                        ) : ''
                      }
                      {
                        (stepper === 8 && (isEdit === '1' || !employmentId)) ? (
                          <FlexBoxItem width="140px">
                            <Button text="登録"
                              onClick={() => {
                                setIsSubmit(true);
                                formik.handleSubmit()
                              }} />
                          </FlexBoxItem>
                        ) : ''
                      }
                      {
                        (stepper >= 1 && stepper !== 8 && (isEdit === '1' || !employmentId)) ? (
                          <FlexBoxItem width="140px">
                            <Button text="次へ"
                              onClick={() => {
                                setIsSubmit(true);
                                formik.handleSubmit()
                              }} />
                          </FlexBoxItem>
                        ) : ''
                      }
                      {
                        (employmentId && isEdit === '2' && detailRole.editable === 1) ? (
                          <FlexBoxItem width="140px">
                            <Button text="更新" onClick={() => { setIsSubmit(true); formik.handleSubmit() }} />
                          </FlexBoxItem>
                        ) : ''
                      }
                      <FlexBoxItem width="140px"><Button text={'一覧へ戻る'} onClick={() => history.goBack()} /></FlexBoxItem>
                    </FlexBox>
                    <Link to="/masterEmploymentForm">
                      <Button
                        text="キャンセル"
                        ghost={true}
                      />
                    </Link>
                  </div>
                  <Modal
                    open={confirmModalOpen}
                    closeHandler={closeConfirmModal}
                    title='適用年月を入力してください'
                    submitText={employmentId && isEdit === '2' ? '更新' : '登録'}
                    onSubmit={() => {
                      setIsSubmit(true);
                      formik.handleSubmit();
                    }
                    }
                    closeText='閉じる'
                  >
                    <div style={{
                      width: '300px', margin: '0 auto', marginTop: '15px'
                    }}>
                      <FlexBox>
                        <FlexBoxItem width="50%">
                          <RadioSelectForm
                            label=''
                            name="applyDate"
                            additionStyle={styles.addon}
                            items={[
                              {
                                label: '月指定',
                                value: '0'
                              },
                              {
                                label: '翌月',
                                value: '1',
                              },
                            ]}
                            value={String(applicationDate)}
                            setValue={(e) => {
                              setApplicationDate(Number(e.target.value));
                            }}
                          />
                        </FlexBoxItem>
                        <FlexBoxItem width="50%">
                          <div css={styles.calendar}>
                            {/* <CalendarForm
                              readOnly={applicationDate ? true : false}
                              required={false}
                              dateFormat="YYYY/MM"
                              label=''
                              date={applyDateChoose}
                              changeDate={(date: Date) => setApplyDateChoose(date)}
                            /> */}
                            <CalendarMonth 
                              modal={true}
                              readOnly={applicationDate ? true : false}
                              date={applyDateChoose}
                              setDate={setApplyDateChoose}
                            />
                          </div>
                        </FlexBoxItem>
                      </FlexBox>
                      <span style={{ color: 'red', marginTop: '4px' }}>{formik.errors.applyDate}</span>
                    </div>
                  </Modal>
                </FormSubmitArea>
              </FormContents>
            </div>
          </div>
          <div style={{ right: '0', position: 'sticky', top: '0' }}>
            <EmploymentInputHintDisplay formik={formik} />
          </div>
          <div style={{ clear: 'both' }}> </div>
        </form>
      </div>
    </BlockUI>
  );
};

export default EmploymentAddForm;
