
import { State, DetailRoleFunction} from './type';

export const types = {
  SET_STATE: 'SET_STATE' as const,
  SET_DATA: 'SET_DATA' as const,
  ON_CHANGE: 'ON_CHANGE' as const,
  ON_CHANGE_RADIO: 'ON_CHANGE_RADIO' as const,
};

export const setState = (payload: State) => ({
  type: types.SET_STATE,
  payload,
});

export const setData = (payload: DetailRoleFunction) => ({
  type: types.SET_DATA,
  payload,
});
export const onChange = (payload: {path: string, data: any}) => ({
  type: types.ON_CHANGE,
  payload,
});
export const onChangeRadio = (payload: {functionMasterId: string, label: string, data: any}) => ({
  type: types.ON_CHANGE_RADIO,
  payload,
});

type Action = (
  | ReturnType<typeof setState>
  | ReturnType<typeof setData>
  | ReturnType<typeof onChange>
  | ReturnType<typeof onChangeRadio>
);

export default Action;
