/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import moment from "moment";
import React from "react";
import { IchangeLog } from "../interface";

const AttendStampModal: React.FC<{
  changeLogData: IchangeLog[];
  selectChangelog: string;
  isStamp: boolean,
  customStyle?: any
}> = ({
  changeLogData,
  selectChangelog,
  isStamp,
  customStyle
}) => {
    return (
      <React.Fragment>
        {
          changeLogData[Number(selectChangelog)]?.stampList && (
            <FormPadding customStyle={customStyle}>
              <div className="modal-table">
                <Table customStyle={css({ width: "auto" })}>
                  <thead>
                    <tr>
                      <Table.HeaderCell customStyle={css({ width: "25%" })}>
                        対象日付
												</Table.HeaderCell>
                      <Table.HeaderCell customStyle={css({ width: "25%" })}>
                        開始時間
												</Table.HeaderCell>
                      <Table.HeaderCell customStyle={css({ width: "25%" })}>
                        終了時間
												</Table.HeaderCell>
                      {isStamp && changeLogData[Number(selectChangelog)].useRemote && (
                        <Table.HeaderCell customStyle={css({ width: "140px!important" })}>
                          オフィス勤務
                        </Table.HeaderCell>
                      )}

                    </tr>
                  </thead>
                  <tbody>
                    {changeLogData &&
                      changeLogData[Number(selectChangelog)]?.stampList.length > 0 &&
                      changeLogData[Number(selectChangelog)]?.stampList.map((stamp, i) => (
                        <tr key={stamp.stampId}>
                          <Table.Cell customStyle={css({ textAlign: "center", width: "90px" })}>
                            {moment(stamp.startTime).format("YYYY/MM/DD")}
                          </Table.Cell>

                          <Table.Cell
                            customStyle={css({ textAlign: "center", width: "90px" })}>
                            {moment(stamp.startTime).format("HH:mm:ss")}
                          </Table.Cell>

                          <Table.Cell
                            customStyle={css({ textAlign: "center", width: "90px" })}>
                            {stamp.endTime ? moment(stamp.endTime).format("HH:mm:ss") : null}
                          </Table.Cell>

                          {isStamp && changeLogData[Number(selectChangelog)].useRemote && (
                            <Table.Cell customStyle={css({ textAlign: "center", width: "90px" })}>
                              {stamp.isRemote ? "なし" : "あり"}
                            </Table.Cell>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </FormPadding>
          )
        }
      </React.Fragment>
    );
  };

export default AttendStampModal;
