/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useCallback, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { handleRecorderByICCard, handleConfirmRecorder } from "api/timeRecorder";
import useToastNotification from "hooks/useToastNotification";
import RecorderByICCardDomain from "domain/timeRecorder/recorderByICCardDomain";
import ConfirmRecorderByICCardDomain from "domain/timeRecorder/confirmRecorderByICCardDomain";

import InfoStaffTimeRecorderDomain from "domain/timeRecorder/infoStaffTimeRecorderDomain";
import { CurrentTimeDomain } from "domain/master/attend/currentTime";
import { TimeRecorderContext } from "components/organismos/RecorderV2/RecorderV2";
import * as icCard from 'hooks/useICCard';
import useAudio from 'hooks/useAudio';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { useDispatch } from 'react-redux';
import { icCardActions } from 'modules/ui/icCard';
import {
  getSpecifyCodeByICCard,
  getInfomationOrgLocalStorage
} from "api/timeRecorder";

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";

const { errorNotification, successNotification } = useToastNotification();
const AUTO_PAUSE_DETECT_CARD = true;

export const useICCardRecorder = (
  dataSettings: any,
  resultCurrentTime: CurrentTimeDomain,
  setIsLoading: any,
  fetchHistoryData: any
) => {

  const {
    stampOrgCode,
    setStampOrgCode,
    stampOrgName,
    setStampOrgName,
    loginOrgCode,
    setLoginOrgCode,
    loginOrgName,
    setLoginOrgName,
  } = dataSettings
  // redux
  const dispatch = useDispatch();

  const playAudioSuccess = useAudio('/audio/soundDetectSuccess.mp3').playAudio;
  
  const playAudioError = useAudio('/audio/soundDetectError.mp3').playAudio;

  const isConnectICCardUsb = useSelector((state:Store) => state.ui.icCard.isConnectICCardUsb);
  const deviceICCard = useSelector((state:Store) => state.ui.icCard.deviceICCard);
  const cardId = useSelector((state:Store) => state.ui.icCard.cardId);

  // get local storage
  const contextObject = useContext(TimeRecorderContext);  
  const {companyCode, shopCode, stampByBelongOrg} = contextObject;

  const [isConfirm, setIsConfirm] = useState(false);
  const [isConnectUsb, setIsConnectUsb] = useState(false);
  const [actionDetect, setActionDetect] = useState(false);

  //Use New UI Remote
  const [newUiRemote, setNewUiRemote] = useState<boolean>(false);
  const [isRemote, setIsRemote] = useState<boolean>(false);

  // ID
  const [inputBarcodeId, setInputBarcodeId] = useState("");
  const [barcodeId, setBarcodeId] = useState("");

  // Staff
  const [stampMode, setStampMode] = useState(4);
  const [staffName, setStaffName] = useState("");

  // type
  const [lastStampType, setLastStampType] = useState<any>();
  const [stampType, setStampType] = useState<any>();

  const [disabledICCard, setDisabledICCard] = useState<boolean>(false);
  
  const [businessList, setBusinessList] = useState<any>();
  const [businessName, setBusinessName] = useState("")
  
  const onSubmit = async (values: ConfirmRecorderByICCardDomain) => {
    setIsLoading(true);
    try {
      await handleRecorderByICCard(shopCode, companyCode, values.getRawData()).then(
        (response: InfoStaffTimeRecorderDomain) => {
          setIsConfirm(true);
          playAudioSuccess();
          const stampStaffData = response.stampStaffList[0];
          setLastStampType(stampStaffData.lastStampType);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setBarcodeId(stampStaffData.barcodeId);
          setNewUiRemote(stampStaffData.useRemote);
          setIsRemote(stampStaffData.isRemote);
          setStaffName(stampStaffData.staffName)
          setIsLoading(false);
        }
      );
    } catch (error) {
      fetchHistoryData();
      playAudioError();
      setTimeout(() => {
        dispatch(icCardActions.setDisabledDetect(false));
        dispatch(icCardActions.setHardCardId(''));
      }, 3000);
      setIsLoading(false);
      if (error.response.data.message) {
        errorNotification(error.response.data.message);
        return;
      }
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByICCardDomain.generateInitial(),
    onSubmit,
  });
  const handleSubmitICCard = useCallback((cardId: string) => {
    setIsLoading(true);
    setStampMode(4); 
    formik.setFieldValue("stampMode", stampMode);
    formik.setFieldValue("stampByBelongOrg", stampByBelongOrg);
    formik.setFieldValue("orgName", loginOrgName);
    formik.setFieldValue("orgCode", loginOrgCode);
    formik.setFieldValue("loginOrgName", loginOrgName);
    formik.setFieldValue("loginOrgCode", loginOrgCode);
    formik.setFieldValue("staffName", staffName);
    formik.setFieldValue("cardId", cardId);
    formik.setFieldValue("inputCardId", cardId);
    formik.setFieldValue("stampTime", String(resultCurrentTime.serverDateTime));
    formik.submitForm();
  }, [
    barcodeId,
    formik,
    loginOrgCode,
    loginOrgName,
    resultCurrentTime.serverDateTime,
    staffName,
    stampByBelongOrg,
    stampMode,
    stampOrgCode,
    stampOrgName,
  ]);

  const [,setStaffCode] = useState("");
  const [businessCnt, setBusinessCnt] = useState(0);
  //submit
  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, businessName?: string, flagRemote?: boolean) => {
      if(flagRemote){
        formikConfirm.setFieldValue("isRemote", true);  
      }else{
        formikConfirm.setFieldValue("isRemote", isRemote);  
      }
      setBusinessName(businessName||"");
      setStampType(stampType);
      formikConfirm.setFieldValue("orgCode", loginOrgCode);
      formikConfirm.setFieldValue("orgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgCode", loginOrgCode);
      formikConfirm.setFieldValue("staffName", staffName);
      formikConfirm.setFieldValue("cardId", cardId);
      formik.setFieldValue("inputCardId", cardId);
      formikConfirm.setFieldValue(
        "stampTime",
        String(resultCurrentTime.serverDateTime)
      );
      formikConfirm.setFieldValue("stampType", stampType);
      formikConfirm.setFieldValue("businessId", businessId);
      formikConfirm.setFieldValue("stampMode", stampMode);
      
      formikConfirm.submitForm();
      setIsConnectUsb(true);
      // setCodeICCard('');
    },
    [
      barcodeId,
      inputBarcodeId,
      loginOrgCode,
      loginOrgName,
      resultCurrentTime.serverDateTime,
      staffName,
      stampMode,
      stampOrgCode,
      stampOrgName,
      cardId
    ]
  );

  const onSubmitConfirm = async (values: RecorderByICCardDomain) => {
    setIsLoading(true);
    handleConfirmRecorder(shopCode, companyCode, values.getRawData(), 0).then(
      (response: any) => {
        successNotification(
          response.stampResultInfo.replace("<h4>", "").replace("</h4>", "")
        );
        dispatch(icCardActions.setDisabledDetect(false));
        dispatch(icCardActions.setHardCardId(''));
        setIsConfirm(false);
        fetchHistoryData();
        formik.resetForm();
        formikConfirm.resetForm();
        setInputBarcodeId("");
        setIsLoading(false);
      }
    ).catch((error: any) => {
      fetchHistoryData();
      dispatch(icCardActions.setDisabledDetect(false));
      dispatch(icCardActions.setHardCardId(''));
      setIsLoading(false);
      setIsConfirm(false);
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorNotification(
            error.response.data.errors.map((x: any) => x.defaultMessage)
          );
        } else if (error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification(error.response.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
  };

  const formikConfirm = useFormik({
    initialValues: RecorderByICCardDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirm(values);
    },
  });
  
  const detectCard = () => {
    try {
      if(!deviceICCard){
        icCard.connectDevice(dispatch);
      }
      setActionDetect(true);
      // icCard.detectCard(deviceICCard, dispatch);  
      // icCard.detectCard(setCodeICCard, setIsConnectUsb, dispatch);
    } catch (error) {
      errorNotification(error);
    }
  }
  
  useEffect(() => {
    if(deviceICCard && actionDetect && dispatch){
      icCard.detectCard(deviceICCard, dispatch, AUTO_PAUSE_DETECT_CARD);
    }
  }, [deviceICCard, actionDetect, dispatch])

  useEffect(() => {
    if(cardId){
      handleSubmitICCard(cardId);
    }
  }, [cardId])
  
  useEffect(() => {
    return () =>{
      dispatch(icCardActions.setDisabledDetect(false));
      dispatch(icCardActions.setHardCardId(''));
    }
  },[])
 

  // useEffect(() => {
  //   if(isConnectUsb && codeICCard){
  //     playAudio();
      
  //     handleSubmitICCard(codeICCard);
  //     setIsConnectUsb(false);
  //     setDisabledICCard(true);
  //   }
  // }, [isConnectUsb, codeICCard])

  return {
    formik,
    businessList,
    lastStampType,
    setIsConfirm,
    isConfirm,
    staffName,
    businessCnt,
    formikConfirm,
    handleRecorder,
    stampOrgCode,
    businessName,
    stampType,
    newUiRemote,
    isConnectUsb,
    setIsConnectUsb,
    detectCard,
    disabledICCard,
    isConnectICCardUsb
  };
};

export const useICCardRecorderEmptySpecifyCode = (
  resultCurrentTime: CurrentTimeDomain,
  setIsLoading: any,
  fetchHistoryData: any
) => {
  // redux
  const dispatch = useDispatch();

  const playAudioSuccess = useAudio('/audio/soundDetectSuccess.mp3').playAudio;
  const playAudioError = useAudio('/audio/soundDetectError.mp3').playAudio;

  const isConnectICCardUsb = useSelector((state:Store) => state.ui.icCard.isConnectICCardUsb);
  const deviceICCard = useSelector((state:Store) => state.ui.icCard.deviceICCard);
  const cardId = useSelector((state:Store) => state.ui.icCard.cardId);
  const [actionDetect, setActionDetect] = useState(false);

  // get local storage
  const contextObject = useContext(TimeRecorderContext);  
  const {companyCode, stampByBelongOrg} = contextObject;

  const [isConfirm, setIsConfirm] = useState(false);
  const [isConnectUsb, setIsConnectUsb] = useState(false);

  //Use New UI Remote
  const [newUiRemote, setNewUiRemote] = useState<boolean>(false);
  const [isRemote, setIsRemote] = useState<boolean>(false);

  // Staff
  const [stampMode, setStampMode] = useState(4);
  const [staffName, setStaffName] = useState("");
  const [specifyCode, setSpecifyCode] = useState("");
  const [shopCode, setShopCode] = useState("");
  const [shopName, setShopName] = useState("");
  const [stampOrgCode, setStampOrgCode] = useState("");
  const [stampOrgName, setStampOrgName] = useState("");
  const [loginOrgCode, setLoginOrgCode] = useState("");
  const [loginOrgName, setLoginOrgName] = useState("");

  // type
  const [lastStampType, setLastStampType] = useState<any>();
  const [stampType, setStampType] = useState<any>();

  
  const [businessList, setBusinessList] = useState<any>();
  const [businessName, setBusinessName] = useState("")
  
  const [codeICCard, setCodeICCard] = useState('');
  const [disabledICCard, setDisabledICCard] = useState<boolean>(false);

  const fetchDataOrg = useCallback(async (specifyCode: string) => {
    setIsLoading(true);
    if(specifyCode){
      try {
        getInfomationOrgLocalStorage(companyCode, specifyCode).then(
          (response: any) => {
            setStampOrgCode(response.shopCode);
            setStampOrgName(response.shopName);
            setLoginOrgCode(response.shopCode);
            setLoginOrgName(response.shopName);
            handleSubmitICCard(response.shopCode,response.shopName)
          }
        );
      } catch (error) {
        fetchHistoryData();
        dispatch(icCardActions.setDisabledDetect(false));
        dispatch(icCardActions.setHardCardId(''));
        setIsConnectUsb(true);
        setCodeICCard('');
        errorNotification("サーバー側でエラーが発生しました。");
      }
      finally{
        setIsLoading(false);
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode, specifyCode, cardId]);

  const onSubmit = async (values: ConfirmRecorderByICCardDomain) => {
    setIsLoading(true);
    try {
      await handleRecorderByICCard(shopCode, companyCode, values.getRawData()).then(
        (response: InfoStaffTimeRecorderDomain) => {
          setIsConfirm(true);
          const stampStaffData = response.stampStaffList[0];
          setLastStampType(stampStaffData.lastStampType);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setStaffName(stampStaffData.staffName);
          setStaffCode(stampStaffData.staffCode);
          setStampOrgCode(stampStaffData.orgCode);
          setStampOrgName(stampStaffData.orgName);
          setNewUiRemote(stampStaffData.useRemote);
          setIsRemote(stampStaffData.isRemote);
          setIsLoading(false);
        }
      );
    } catch (error) {
      
      setTimeout(() => {
        dispatch(icCardActions.setDisabledDetect(false));
        dispatch(icCardActions.setHardCardId(''));
      }, 3000);
      setIsLoading(false);
      if (error.response.data.message) {
        errorNotification(error.response.data.message);
        return;
      }
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByICCardDomain.generateInitial(),
    onSubmit,
  });
  const handleSubmitICCard = useCallback((shopCode: string, shopName: string) => {
    setStampMode(4); 
    formik.setFieldValue("stampMode", 4);
    formik.setFieldValue("stampByBelongOrg", stampByBelongOrg);
    formik.setFieldValue("orgName", shopName);
    formik.setFieldValue("orgCode", shopCode);
    formik.setFieldValue("loginOrgName", shopName);
    formik.setFieldValue("loginOrgCode", shopCode);
    formik.setFieldValue("staffName", staffName);
    formik.setFieldValue("cardId", cardId);
    formik.setFieldValue("inputCardId", cardId);
    formik.setFieldValue("stampTime", String(resultCurrentTime.serverDateTime));
    formik.submitForm();
  }, [
    formik,
    loginOrgCode,
    loginOrgName,
    resultCurrentTime.serverDateTime,
    staffName,
    stampByBelongOrg,
    stampMode,
    cardId
  ]);

  const [,setStaffCode] = useState("");
  const [businessCnt, setBusinessCnt] = useState(0);
  //submit
  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, businessName?: string, flagRemote?: boolean) => {
      
      if(flagRemote){
        formikConfirm.setFieldValue("isRemote", true);  
      }else{
        formikConfirm.setFieldValue("isRemote", isRemote);  
      }
      setBusinessName(businessName||"");
      setStampType(stampType);
      formikConfirm.setFieldValue("orgCode", loginOrgCode);
      formikConfirm.setFieldValue("orgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgName", loginOrgName);
      formikConfirm.setFieldValue("loginOrgCode", loginOrgCode);
      formikConfirm.setFieldValue("staffName", staffName);
      formikConfirm.setFieldValue("cardId", cardId);
      formik.setFieldValue("inputCardId", cardId);
      formikConfirm.setFieldValue(
        "stampTime",
        String(resultCurrentTime.serverDateTime)
      );
      formikConfirm.setFieldValue("stampType", stampType);
      formikConfirm.setFieldValue("businessId", businessId);
      formikConfirm.setFieldValue("stampMode", stampMode);
      formikConfirm.submitForm();
      setIsConnectUsb(true);
      setCodeICCard('');
    },
    [
      loginOrgCode,
      loginOrgName,
      resultCurrentTime.serverDateTime,
      staffName,
      stampMode,
      stampOrgCode,
      stampOrgName,
      cardId
    ]
  );

  const onSubmitConfirm = async (values: RecorderByICCardDomain) => {
    setIsLoading(true);
    handleConfirmRecorder(shopCode, companyCode, values.getRawData(), 0).then(
      (response: any) => {
        dispatch(icCardActions.setDisabledDetect(false));
        dispatch(icCardActions.setHardCardId(''));
        successNotification(
          response.stampResultInfo.replace("<h4>", "").replace("</h4>", "")
        );
        setIsConfirm(false);
        fetchHistoryData();
        formik.resetForm();
        formikConfirm.resetForm();
        setIsLoading(false);
        setCodeICCard('');
      }
    ).catch((error: any) => {
      dispatch(icCardActions.setDisabledDetect(false));
      dispatch(icCardActions.setHardCardId(''));
      setIsLoading(false);
      setIsConfirm(false);
      setIsConnectUsb(true);
      setCodeICCard('');
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorNotification(
            error.response.data.errors.map((x: any) => x.defaultMessage)
          );
        } else if (error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification(error.response.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
  };

  const formikConfirm = useFormik({
    initialValues: RecorderByICCardDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirm(values);
    },
  });

  const detectCard = () => {
    try {
      if(!deviceICCard){
        icCard.connectDevice(dispatch);
      }
      setActionDetect(true);
      // icCard.detectCard(deviceICCard, dispatch);  
      // icCard.detectCard(setCodeICCard, setIsConnectUsb, dispatch);
    } catch (error) {
      errorNotification(error);
    }
  }
  
  useEffect(() => {
    if(deviceICCard && actionDetect && dispatch){
      icCard.detectCard(deviceICCard, dispatch, AUTO_PAUSE_DETECT_CARD);
    }
  }, [deviceICCard, actionDetect, dispatch])

  useEffect(() => {
    if(cardId){
      fetchSpecifyCodeByICCard();
    }
  }, [cardId])

  useEffect(() => {
    return () =>{
      dispatch(icCardActions.setDisabledDetect(false));
      dispatch(icCardActions.setHardCardId(''));
    }
  },[])


  const fetchSpecifyCodeByICCard = useCallback(async () => {
    setIsLoading(true);
    getSpecifyCodeByICCard(companyCode, cardId).then(
      (response: any) => {
        fetchDataOrg(response.specifyCode);
        setIsLoading(false);
        playAudioSuccess();
      }
    ).catch((error: any) => {
      playAudioError();
      setTimeout(() => {
        dispatch(icCardActions.setDisabledDetect(false));
        dispatch(icCardActions.setHardCardId(''));  
      }, 3000);
      setIsLoading(false);
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId]);

  return {
    formik,
    businessList,
    lastStampType,
    setIsConfirm,
    isConfirm,
    staffName,
    businessCnt,
    formikConfirm,
    handleRecorder,
    stampOrgCode,
    businessName,
    stampType,
    newUiRemote,
    isConnectUsb,
    setIsConnectUsb,
    detectCard,
    disabledICCard,
    isConnectICCardUsb
  };
};
export default useICCardRecorder;
