/** @jsx jsx */
import { css,jsx } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable"; 
import { textFontSize } from "components/styles";
import React from "react";
import { IchangeLog } from "../interface";

const AttendHistoryApprovalModal: React.FC<{
  changeLogData: IchangeLog[];
  selectChangelog?: string;
  isStamp?: boolean;
  customStyle?:any
}> = ({
  changeLogData,
  selectChangelog,
  customStyle
}) => {
  return (
    <React.Fragment>
      {
        changeLogData && changeLogData[Number(selectChangelog)]?.approvalHistoryList?.length > 0 && (
          <FormPadding customStyle={customStyle}>
            <HistoryApprovalTable
              historyData={changeLogData[Number(selectChangelog)].approvalHistoryList}
              applicationStatus={changeLogData[Number(selectChangelog)].applicationStatus}
            />
          </FormPadding>
        )
      }
    </React.Fragment>
  );
};

export default AttendHistoryApprovalModal;
