/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { SalarySupportStaff } from "domain/master/general/salaryDataStaff";
import { useOrganizationSupportEdit } from "./hooks";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import { textFontSize } from "components/styles";
import Icon from "components/atoms/Icon";
import MoneyInput from "components/atoms/Form/MoneyInput";

const OrganizationSupportEdit: React.FC<{
  isShowHourlyWageAddition: boolean;
  support: SalarySupportStaff;
  setSupport: (manager: SalarySupportStaff) => void;
  deleteSupport: () => void;
}> = ({ isShowHourlyWageAddition, support, deleteSupport, setSupport }) => {
  const {
    addHourSalary,
    supportTransportation,
    changeAddHourlySalary,
    changeSupportTransportation,
  } = useOrganizationSupportEdit(support, setSupport);
  return (
    <tr>
      <td>{support.orgName}</td>
      <td>
        {isShowHourlyWageAddition && (
          <MoneyInput
            name="addHourlySalary"
            value={addHourSalary}
            label="円"
            onChange={(e) => {
              changeAddHourlySalary(Number(e.target.value));
            }}
            min={0}
            max={999999999}
          />
        )}
      </td>
      <td>
        <MoneyInput
          name="supportTransportation"
          value={supportTransportation}
          label="円"
          onChange={(e) => {
            changeSupportTransportation(Number(e.target.value));
          }}
          min={0}
          max={999999999}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <ClickableIconButton onClick={deleteSupport}>
          <Icon type="delete" color={textFontSize.re} />
        </ClickableIconButton>
      </td>
    </tr>
  );
};

export default OrganizationSupportEdit;
