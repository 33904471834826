/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { utilityColor } from "components/styles";
import Table from "components/molecules/Table";
import PrimaryButton from "components/atoms/Button";
import moment from "moment";
import SinglecheckRounded from "components/atoms/Icon/SinglecheckRounded";
import { IWorkingStatus } from "../../interface";
import IconTooltip from "components/molecules/IconTooltip";
import ContextMenu from "components/molecules/ContextMenu";
import getContextMenuButtons from "components/molecules/ContextMenu/getContextMenuButtons";
import { useHistory } from "react-router-dom";

const headers = ["", "", "日付", "出勤状況", "出勤組織", "出勤時間", "退勤時間", "休憩時間", "総労働時間"];

const styles = {
  table: css`
    table {
      margin-top: 15px;
      th {
        white-space: nowrap;
      }
      min-width: 792px;
    }
    table th:nth-of-type(1) {
      width: 20px;
    }
    table th:nth-of-type(2) {
      width: 120px;
    }
    table th:nth-of-type(3) {
      width: 80px;
    }
    table th:nth-of-type(4) {
      width: 120px;
    }
    table th:nth-of-type(5) {
      width: 80px;
    }
    table th:nth-of-type(6) {
      width: 80px;
    }
    table th:nth-of-type(7) {
      width: 80px;
    }
    table th:nth-of-type(8) {
      width: 80px;
    }
    table th:nth-of-type(9) {
      width: 120px;
    }
    .check {
      svg {
        path:last-child {
          fill: green;
        }
      }
    }
    button {
      width: 120px;
    }
  `,
};

const WorkRecord: React.FC<{ list: IWorkingStatus }> = ({ list }) => {
  const history = useHistory();
  return (
    <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
      <div css={styles.table}>
        <Table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.staffStampList &&
              list.staffStampList.map((cell, index) => {
                let contextMenuButtons = getContextMenuButtons(
                  history,
                  cell.modifierApplicationId,
                  cell.orgCode || sessionStorage.getItem("loginUser.orgCode") || "",
                  list.getLoginStaffCcde,
                  list.getLoginStaffName,
                  cell.modifierApplicationStatus,
                  cell.holidayApplicationStatus,
                  cell.allowanceApplicationStatus,
                  cell.overworkApplicationStatus,
                  cell.holidayworkApplicationStatus,
                  cell.transferApplicationStatus,
                  cell.attendanceOrgFix,
                  list.employment?.useOverTimeApplication,
                  list.employment?.necessaryHolidayWorkApplication,
                  list.employment?.useAllowanceApplication,
                  list.employment?.useTransferApplication,
                  cell.achievementId,
                  cell.targetDate,
                  cell.stampVersion,
                );
                return (
                  <tr key={index}>
                    <Table.Cell>
                      <span className="alert" data-tip={cell.alertMsg}>
                        {cell.alert === "1" && (
                          <IconTooltip
                            iconType="warning"
                            text={cell.alertMsg || ""}
                            color={utilityColor.yellow}
                            customCss={css({ margin: "0" })}
                          />
                        )}
                        {cell.alert === "2" && (
                          <IconTooltip
                            iconType="warning"
                            text={cell.alertMsg || ""}
                            color={utilityColor.error}
                            customCss={css({ margin: "0" })}
                          />
                        )}
                      </span>
                      <span className="check">
                        {(cell.attendanceOrgFix === "1" || cell.belongOrgFix === "1") && <SinglecheckRounded />}
                      </span>
                    </Table.Cell>
                    <Table.Cell customStyle={css({ overflow: "visible" })}>
                      {cell.belongOrgFix === "1" ? (
                        <PrimaryButton disabled={true} text="修正申請 ›" />
                      ) : (
                        <ContextMenu text="修正申請 ›" buttons={contextMenuButtons} />
                      )}
                    </Table.Cell>
                    <Table.Cell customStyle={css({ textAlign: "left" })}>
                      {moment(cell.targetDate).format("YYYY/MM/DD")}
                    </Table.Cell>
                    <Table.Cell customStyle={css({ textAlign: "left" })}>{cell.workStatus}</Table.Cell>
                    <Table.Cell>{cell.orgName}</Table.Cell>
                    <Table.Cell customStyle={css({ textAlign: "left" })}>{cell.startTime}</Table.Cell>
                    <Table.Cell customStyle={css({ textAlign: "left" })}>{cell.endTime}</Table.Cell>
                    <Table.Cell customStyle={css({ textAlign: "left" })}>
                      {cell.recessTime && `${cell.recessTime}`}
                    </Table.Cell>
                    <Table.Cell customStyle={css({ textAlign: "left" })}>
                      {cell.allWorkTime && `${cell.allWorkTime}`}
                    </Table.Cell>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default WorkRecord;
