import React, { useEffect } from "react";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import ApprovedTransferDomain from "../domain/approvedTransferDomain";
import ApprovedStampDomain from "../domain/approvedStampDomain";
import ApprovedHolidayDomain from "../domain/approvedHolidayDomain";
import ApprovedOvertimeDomain from "../domain/approvedOvertimeDomain";
import ApprovedAttendAllowanceDomain from "../domain/approvedAttendAllowanceDomain";
import ApprovedHolidayWorkDomain from "../domain/approvedHolidayWorkDomain";
import Approved36AgreementDomain from "../domain/approved36AgreementDomain";
import ApprovedWorkScheduleChangeDomain from "../domain/approvedWorkScheduleChange";
import ApprovedStockPaidDomain from "../domain/approvedStockPaidDomain";
import OvertimeAccordion from "./OvertimeAccordion";
import AttendAllowanceAccordion from "./AttendAllowanceAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";
import StockPaidHoliday from "./StockPaidHoliday";

const ApprovedTab: React.FC<{
  stampList: ApprovedStampDomain[];
  holidayList: ApprovedHolidayDomain[];
  overtimeList: ApprovedOvertimeDomain[];
  attendAllowanceList: ApprovedAttendAllowanceDomain[];
  holidayWorkList: ApprovedHolidayWorkDomain[];
  transferList: ApprovedTransferDomain[];
  agreement36List: Approved36AgreementDomain[];
  workScheduleList: ApprovedWorkScheduleChangeDomain[];
  stockPaidList: ApprovedStockPaidDomain[];
}> = ({
  stampList,
  holidayList,
  overtimeList,
  attendAllowanceList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleList,
  stockPaidList,
}) => {
  useEffect(() => {}, [stampList, holidayList, overtimeList, holidayWorkList, transferList]);

  return (
    <div>
      <StampAccordion list={stampList} />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <AttendAllowanceAccordion list={attendAllowanceList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />

      <Agreement36Accordion list={agreement36List} />

      <WorkScheduleChangeApplicationAccordion list={workScheduleList} />

      <StockPaidHoliday list={stockPaidList} />
    </div>
  );
};

export default ApprovedTab;
