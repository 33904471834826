/** @jsx jsx */
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import SecondaryButton from 'components/atoms/Button/SecondaryButton';
import { css, jsx } from '@emotion/core';
import { productColor } from 'components/styles';
import TextForm from 'components/molecules/TextForm';
import Modal from 'components/molecules/Modal';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useApprovalModificationApplication } from 'components/organismos/master/attend/ApprovalApplication/hooks';
import TabControl from 'components/atoms/TabControl';
import BlockUI from 'components/molecules/BlockUi';
import HistoryModalModificationApplication from './HistoryModalModificationApplication';

const styles = {
  line: css({
    marginBottom: '15px',
  }),

  hour: css({
    marginBottom: '10px',
  }),

  bodyTextTime: css({
    backgroundColor: productColor.primary,
    color: 'white',
    fontSize: '12px',
    padding: '5px',
    borderRadius: '5px',
  }),

  rejectInput: css`
    :not(input){
      font-weight: bold;
    }
    margin-top: 50px;
  `,
  buttonHistory: css`
    button{
      background-color: #f39c12;
      border-color: #e08e0b;
      font-size: 12px;
      padding-left: 5px;
      padding-right: 5px;
      line-height: 25px;
    }
    button:hover, button:active, button:focus{
      background-color: #f39c12;
      opacity: 0.8;
      border-color: #e08e0b; 
    }
  `,
};

const ApprovalModificationApplication:React.FC = () => {
  const {
    applicationId,
  } = useParams();

  const {
    modalApproveOpen,
    setModalApproveOpen,
    setModalRejectOpen,
    closeApproveModal,
    closeRejectModal,
    closeModalHistoryOpen,
    modalRejectOpen,
    approvalData,
    rejectReason,
    setRejectReason,
    callApproveStampApplication,
    callRejectStampApplication,
    applicationStatus,
    modalHistoryOpen,
    setModalHistoryOpen,
    historyList,
    getStampTime,
    isLoading,
  } = useApprovalModificationApplication(applicationId);

  const handleSubmitApproveModal = () => {
    callApproveStampApplication();
    closeApproveModal();
  };
  const handleSubmitRejectModal = () => {
    callRejectStampApplication();
    closeRejectModal();
  };
  const handleModalHistoryOpen = () => {
    setModalHistoryOpen(true);
    setSelectTab(historyList.length.toString());
  };

  const [selectTab, setSelectTab] = React.useState();

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="申請者"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.applicationStaffCode}
              {' '}
              {approvalData.applicationStaffName}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="申請日時"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {moment(approvalData.applicationDate).isValid() ? moment(approvalData.applicationDate).format('YYYY/MM/DD HH:mm:ss') : ''}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="対象者"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.staffCode}
              {' '}
              {approvalData.staffName}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="対象日"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {moment(approvalData.targetDate).isValid() ? moment(approvalData.targetDate).format('YYYY/MM/DD') : ''}
            </BodyText>
          </FlexBoxItem>
          <FlexBoxItem marginLeft="20px">
            <div css={styles.buttonHistory}>
              <PrimaryButton
                text="更新履歴"
                onClick={() => handleModalHistoryOpen()}
                minWidth="60%"
              />
            </div>
          </FlexBoxItem>

        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="申請種別"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.modifiyStatus === 0 && '申請'}
              {approvalData.modifiyStatus === 1 && '削除'}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="出勤組織"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {`${approvalData.orgCode} ${approvalData.orgName}`}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="時間"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            {approvalData.stampList.map((element: any) => (
              <React.Fragment>
                <div css={styles.hour}>
                  <BodyText customStyle={styles.bodyTextTime}>
                    {getStampTime(element.startTime, element.endTime, approvalData.targetDate)}
                    {element.businessName || ''}
                  </BodyText>
                </div>
              </React.Fragment>
            ))}
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="200px">
            <FormLabel
              label="申請理由"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.applicationReason}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <div css={styles.rejectInput}>
          <TextForm
            name="rejectReason"
            label={`${approvalData.applicationStatus === 2 ? '差戻者 : ' : '承認者 : '}  ${approvalData.approvalStaffCode}  ${approvalData.approvalStaffName}`}
            value={rejectReason || ''}
            onChange={(e) => setRejectReason(e.target.value)}
            placeHolder="差戻コメントを入力"
            disable={applicationStatus !== 0}
          />
        </div>
        <FlexBox customStyle={css({
          justifyContent: 'center',
          marginTop: '30px',
        })}
        >
          { applicationStatus === -1
              && (
                <React.Fragment>
                  <BodyText color="#a94442" bold={true}>申請データが見つかりません。</BodyText>
                </React.Fragment>
              )}
          { applicationStatus === 0
              && (
                <React.Fragment>
                  <FlexBoxItem marginRight="5px">
                    <PrimaryButton
                      text="承認"
                      onClick={() => setModalApproveOpen(true)}
                      minWidth="60%"
                    />
                  </FlexBoxItem>
                  <FlexBoxItem marginLeft="5px">
                    <SecondaryButton
                      text="差戻"
                      onClick={() => setModalRejectOpen(true)}
                    />
                  </FlexBoxItem>
                </React.Fragment>
              )}
          { applicationStatus === 1
              && (
                <React.Fragment>
                  <BodyText color="#a94442" bold={true}>この申請は既に承認済みです</BodyText>
                </React.Fragment>
              )}
          { applicationStatus === 2
              && (
                <React.Fragment>
                  <BodyText color="#a94442" bold={true}>この申請は既に差戻済みです</BodyText>
                </React.Fragment>
              )}
        </FlexBox>
      </FormContents>
      <Modal
        open={modalApproveOpen}
        closeHandler={closeApproveModal}
        title="承認します。よろしいですか？"
        submitText="承認"
        onSubmit={handleSubmitApproveModal}
        
      />
      <Modal
        open={modalRejectOpen}
        closeHandler={closeRejectModal}
        title="差戻します。よろしいですか？"
        submitText="差戻"
        onSubmit={handleSubmitRejectModal}
        typeSubmit="reject"
      />
      <Modal
        open={modalHistoryOpen}
        closeHandler={closeModalHistoryOpen}
        title="更新履歴"
        submitText="閉じる"
        onSubmit={closeModalHistoryOpen}
      >
        <div css={css({ fontSize: '15px' })}>
          <TabControl
            items={
            historyList.map((history: any) => ({
              value: `${history.version}`,
              display: `ver${history.version}`,
            }))
          }
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={true}
          />
        </div>
        {historyList.map((history: any) => (
          selectTab === `${history.version}`
                && (
                <HistoryModalModificationApplication
                  history={history}
                />
                )
        ))}
      </Modal>
    </BlockUI>
  );
};

export default ApprovalModificationApplication;
