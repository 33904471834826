import reducer from './reducer';
import * as generalActions from './actions';
import LoginUserDomain from 'domain/loginUser';

export interface GeneralType {
  loginUser: LoginUserDomain;
}

export {
  generalActions,
};

export default reducer;
