import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getDigestionItemsForSubstituteHolidayStatus,
  downloadSubstituteHolidayCsv,
  getSubstituteDigestedtHistoryDetail,
} from 'api/holidayManagement';
import moment from 'moment';
import SubstituteHolidayStatusDomain from 'domain/master/holidayManagement/substituteHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export interface DetailItems {
  staffCode: string,
  staffName: string,
  orgCode: string,
  orgName: string,
  grantDaysNum: string,
  digestedDaysNum: string,
  extinctionDaysNum: string,
  substituteDigestedtHistoryList: [],
  fromDate: Date,
  toDate: Date,
  effectiveStartDate: Date
}


export const useHolidayManagementHolidayDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [substituteHolidayStatusList, setSubstituteHolidayStatusList] = useState<Array<SubstituteHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementHoliday.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const date = moment(new Date()).format('YYYY/MM/DD');
  const [searchDate, setSearchDate] = useSaveStateStorage(date, "holidayManagementHoliday.searchDate") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    staffCode: '',
    staffName: '',
    orgCode: '',
    orgName: '',
    grantDaysNum: '',
    digestedDaysNum: '',
    extinctionDaysNum: '',
    substituteDigestedtHistoryList: [],
    fromDate: new Date(),
    toDate: new Date(),
    effectiveStartDate: new Date()
  });
  const { errorNotification } = useToastNotification();
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementHoliday.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  const contextObject = useContext(HolidayManagementContext);

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getDigestionItemsForSubstituteHolidayStatus(
        orgCode !== 'all' ? orgCode : '',
        searchDate || date,
        underFlag || 0
      );
      contextObject.setIsLoading(false);
      setSubstituteHolidayStatusList(response.map((result) => new SubstituteHolidayStatusDomain(result)));
    }
  }, [orgCode, searchDate, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetSubstituteDigestedtHistoryDetail = useCallback(async (staffCode: string) => {
    try {
      await getSubstituteDigestedtHistoryDetail(
        staffCode,
        searchDate || date,
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, searchDate]);

  return {
    substituteHolidayStatusList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    date,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callGetSubstituteDigestedtHistoryDetail,
    underFlag,
    setUnderFlag
  };
};


export const useDownloadHolidayManagementHoliday = (
  orgCode: string,
  targetDate: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadHolidayManagementHoliday = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadSubstituteHolidayCsv(
      isDownloadAll ? '' : orgCode,
      targetDate,
      underFlag
    ).then(() => { 
      setBlocking(false);
    });
  }, [orgCode, targetDate, underFlag]);

  return {
    blocking,
    downloadHolidayManagementHoliday,
  };
};

export default {
  useHolidayManagementHolidayDomainForm,
};
