/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { useState, useEffect } from "react";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

import {
  getApprovedTransferList,
  getApprovedStampList,
  getApprovedHolidayList,
  getApprovedOverTimeList,
  getApprovedAgreement36List,
  getApprovedHolidayWorkList,
  getApprovedWorkScheduleChangeList,
  getApprovedStockPaidList,
  getRejectedStampList,
  getRejectedHolidayList,
  getRejectedOverTimeList,
  getRejectedHolidayWorkList,
  getRejectedTransferList,
  getRejectedAgreement36List,
  getRejectedWorkScheduleChangeList,
  getRejectedStockPaidList,
  getApplyingStampList,
  getApplyingHolidayList,
  getApplyingHolidayWorkList,
  getApplyingOverTimeList,
  getApplyingTransferList,
  getApplyingAgreement36List,
  getApplyingWorkScheduleChangeList,
  getApplyingStockPaidList,
  getApplyingAttendAllowanceList,
  getApprovedAttendAllowanceList,
  getRejectedAttendAllowanceList,
} from "./api";
import ApprovedTransferDomain from "./domain/approvedTransferDomain";
import ApprovedStampDomain from "./domain/approvedStampDomain";
import ApprovedHolidayDomain from "./domain/approvedHolidayDomain";
import ApprovedOvertimeDomain from "./domain/approvedOvertimeDomain";
import ApprovedAttendAllowanceDomain from "./domain/approvedAttendAllowanceDomain";
import Approved36AgreementDomain from "./domain/approved36AgreementDomain";
import ApprovedHolidayWorkDomain from "./domain/approvedHolidayWorkDomain";
import ApprovedWorkScheduleChangeDomain from "./domain/approvedWorkScheduleChange";
import RejectedStampDomain from "./domain/rejectedStampDomain";
import RejectedHolidayDomain from "./domain/rejectedHolidayDomain";
import RejectedOvertimeDomain from "./domain/rejectedOvertimeDomain";
import RejectedAttendAllowanceDomain from "./domain/rejectedAttendAllowanceDomain";
import RejectedHolidayWorkDomain from "./domain/rejectedHolidayWorkDomain";
import RejectedTransferDomain from "./domain/rejectedTransferDomain";
import RejectedAgreement36Domain from "./domain/rejectedAgreement36Domain";
import RejectedWorkScheduleChangeDomain from "./domain/rejectedWorkScheduleChange";
import ApplyingStampDomain from "./domain/applyingStampDomain";
import ApplyingHolidayDomain from "./domain/applyingHolidayDomain";
import ApplyingOvertimeDomain from "./domain/applyingOvertimeDomain";
import ApplyingAttendAllowanceDomain from "./domain/applyingAttendAllowanceDomain";
import ApplyingHolidayWorkDomain from "./domain/applyingHolidayWorkDomain";
import ApplyingTransferDomain from "./domain/applyingTransferDomain";
import Applying36AgreementDomain from "./domain/applying36AgreementDomain";
import ApplyingWorkScheduleChangeAppDomain from "./domain/applyingWorkScheduleChangeAppDomain";
import {
  IStamp,
  IHoliday,
  IOvertime,
  IHolidayWork,
  ITransfer,
  I36Agreement,
  IWorkSchedule,
  IAttendStockPaid,
} from "./interface";
import useToastNotification from "hooks/useToastNotification";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import { setInitClosingDate } from "../setInitClosingDate";
import { renderError } from "utility/renderMessage";
import ApplyingStockPaidDomain from "./domain/applyingStockPaidDomain";
import ApprovedStockPaidDomain from "./domain/approvedStockPaidDomain";
import RejectedStockPaidDomain from "./domain/rejectedStockPaidDomain";

export const useAttendApplicationApproval = () => {
  const { errorNotification } = useToastNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.attendApplicationList);

  // Applying tab
  const [applyingStampList, setApplyingStampList] = useState<ApplyingStampDomain[]>([]);
  const [applyingHolidayList, setApplyingHolidayList] = useState<ApplyingHolidayDomain[]>([]);
  const [applyingOvertimeList, setApplyingOvertimeList] = useState<ApplyingOvertimeDomain[]>([]);
  const [applyingHolidayWorkList, setApplyingHolidayWorkList] = useState<ApplyingHolidayWorkDomain[]>([]);
  const [applyingAttendAllowanceList, setApplyingAttendAllowanceList] = useState<ApplyingAttendAllowanceDomain[]>([]);
  const [applyingTransferList, setApplyingTransferList] = useState<ApplyingTransferDomain[]>([]);
  const [applyingAgreement36List, setApplyingAgreement36List] = useState<Applying36AgreementDomain[]>([]);
  const [applyingWorkScheduleChangeList, setApplyingWorkScheduleChangeList] = useState<
    ApplyingWorkScheduleChangeAppDomain[]
  >([]);
  const [applyingStockPaidList, setApplyingStockPaidList] = useState<ApplyingStockPaidDomain[]>([]);

  // Approved tab
  const [approvedStampList, setApprovedStampList] = useState<ApprovedStampDomain[]>([]);
  const [approvedHolidayList, setApprovedHolidayList] = useState<ApprovedHolidayDomain[]>([]);
  const [approvedOvertimeList, setApprovedOvertimeList] = useState<ApprovedOvertimeDomain[]>([]);
  const [approvedAttendAllowanceList, setApprovedAttendAllowanceList] = useState<ApprovedAttendAllowanceDomain[]>([]);
  const [approvedHolidayWorkList, setApprovedHolidayWorkList] = useState<ApprovedHolidayWorkDomain[]>([]);
  const [approvedTransferList, setApprovedTransferList] = useState<ApprovedTransferDomain[]>([]);
  const [approvedAgreement36List, setApprovedAgreement36List] = useState<Approved36AgreementDomain[]>([]);
  const [approvedWorkScheduleChangeList, setApprovedWorkScheduleChangeList] = useState<
    ApprovedWorkScheduleChangeDomain[]
  >([]);
  const [approvedStockPaidList, setApprovedStockPaidList] = useState<ApprovedStockPaidDomain[]>([]);

  // Rejected tab
  const [rejectedStampList, setRejectedStampList] = useState<RejectedStampDomain[]>([]);
  const [rejectedHolidayList, setRejectedHolidayList] = useState<RejectedHolidayDomain[]>([]);
  const [rejectedOvertimeList, setRejectedOvertimeList] = useState<RejectedOvertimeDomain[]>([]);
  const [rejectedAttendAllowanceList, setRejectedAttendAllowanceList] = useState<RejectedAttendAllowanceDomain[]>([]);
  const [rejectedHolidayWorkList, setRejectedHolidayWorkList] = useState<RejectedHolidayWorkDomain[]>([]);
  const [rejectedTransferList, setRejectedTransferList] = useState<RejectedTransferDomain[]>([]);
  const [rejectedAgreement36List, setRejectedAgreement36List] = useState<RejectedAgreement36Domain[]>([]);
  const [rejectedWorkScheduleChangeList, setRejectedWorkScheduleChangeList] = useState<
    RejectedWorkScheduleChangeDomain[]
  >([]);
  const [rejectedStockPaidList, setRejectedStockPaidList] = useState<RejectedStockPaidDomain[]>([]);

  const [period, setPeriod] = useState(sessionStorage.getItem("applicationListPeriod") || "monthly");
  const closingDate = useSelector((state: Store) => state.loginUser.loginUser.closingDate);
  const { firstDayStr, lastDayStr } = setInitClosingDate(closingDate, new Date());
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(
    new Date(sessionStorage.getItem("applicationTargetDayFrom") || new Date(firstDayStr)),
  );
  const [targetDateTo, setTargetDateTo] = useState<Date>(
    new Date(sessionStorage.getItem("applicationTargetDayTo") || new Date(lastDayStr)),
  );
  const [targetMonth, setTargetMonth] = useState<Date>(
    new Date(sessionStorage.getItem("applicationTargetMonth") || new Date(lastDayStr)),
  );

  const fetchData = async (selectType: string, period: string) => {
    setIsLoading(true);
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format("YYYY-MM-DD"),
      targetDateTo: moment(targetDateTo).format("YYYY-MM-DD"),
    };
    switch (selectType) {
      case "applying":
        fetchApplyingList(params);
        break;
      case "approved":
        fetchApprovedList(params);
        break;
      case "rejected":
        fetchRejectedList(params);
        break;

      default:
        break;
    }
  };

  // Applying
  const fetchApplyingList = async (params: any) => {
    try {
      await Promise.all([
        getApplyingStampList(params),
        getApplyingHolidayList(params),
        getApplyingOverTimeList(params),
        getApplyingAttendAllowanceList(params),
        getApplyingAgreement36List(params),
        getApplyingHolidayWorkList(params),
        getApplyingTransferList(params),
        getApplyingWorkScheduleChangeList(params),
        getApplyingStockPaidList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setApplyingStampList(stampRes.map((data) => new ApplyingStampDomain(data)));
            setApplyingHolidayList(holidayRes.map((data) => new ApplyingHolidayDomain(data)));
            setApplyingOvertimeList(overTimeRes.map((data) => new ApplyingOvertimeDomain(data)));
            setApplyingAttendAllowanceList(attendAllowanceRes.map((data) => new ApplyingAttendAllowanceDomain(data)));
            setApplyingAgreement36List(agreement36Res.map((data) => new Applying36AgreementDomain(data)));
            setApplyingHolidayWorkList(holidayWorkRes.map((data) => new ApplyingHolidayWorkDomain(data)));
            setApplyingTransferList(transferRes.map((data) => new ApplyingTransferDomain(data)));
            setApplyingWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new ApplyingWorkScheduleChangeAppDomain(data)),
            );
            setApplyingStockPaidList(stockPaidRef.map((data) => new ApplyingStockPaidDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });
    } finally {
      setIsLoading(false);
    }
  };

  // Approved
  const fetchApprovedList = async (params: any) => {
    try {
      await Promise.all([
        getApprovedStampList(params),
        getApprovedHolidayList(params),
        getApprovedOverTimeList(params),
        getApprovedAttendAllowanceList(params),
        getApprovedAgreement36List(params),
        getApprovedHolidayWorkList(params),
        getApprovedTransferList(params),
        getApprovedWorkScheduleChangeList(params),
        getApprovedStockPaidList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setApprovedStampList(stampRes.map((data) => new ApprovedStampDomain(data)));
            setApprovedHolidayList(holidayRes.map((data) => new ApprovedHolidayDomain(data)));
            setApprovedOvertimeList(overTimeRes.map((data) => new ApprovedOvertimeDomain(data)));
            setApprovedAttendAllowanceList(attendAllowanceRes.map((data) => new ApprovedAttendAllowanceDomain(data)));
            setApprovedAgreement36List(agreement36Res.map((data) => new Approved36AgreementDomain(data)));
            setApprovedHolidayWorkList(holidayWorkRes.map((data) => new ApprovedHolidayWorkDomain(data)));
            setApprovedTransferList(transferRes.map((data) => new ApprovedTransferDomain(data)));
            setApprovedWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new ApprovedWorkScheduleChangeDomain(data)),
            );
            setApprovedStockPaidList(stockPaidRef.map((data) => new ApprovedStockPaidDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });
    } finally {
      setIsLoading(false);
    }
  };

  // Rejected
  const fetchRejectedList = async (params: any) => {
    try {
      await Promise.all([
        getRejectedStampList(params),
        getRejectedHolidayList(params),
        getRejectedOverTimeList(params),
        getRejectedAttendAllowanceList(params),
        getRejectedAgreement36List(params),
        getRejectedHolidayWorkList(params),
        getRejectedTransferList(params),
        getRejectedWorkScheduleChangeList(params),
        getRejectedStockPaidList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setRejectedStampList(stampRes.map((data) => new RejectedStampDomain(data)));
            setRejectedHolidayList(holidayRes.map((data) => new RejectedHolidayDomain(data)));
            setRejectedOvertimeList(overTimeRes.map((data) => new RejectedOvertimeDomain(data)));
            setRejectedAttendAllowanceList(attendAllowanceRes.map((data) => new RejectedAttendAllowanceDomain(data)));
            setRejectedAgreement36List(agreement36Res.map((data) => new RejectedAgreement36Domain(data)));
            setRejectedHolidayWorkList(holidayWorkRes.map((data) => new RejectedHolidayWorkDomain(data)));
            setRejectedTransferList(transferRes.map((data) => new RejectedTransferDomain(data)));
            setRejectedWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new RejectedWorkScheduleChangeDomain(data)),
            );
            setRejectedStockPaidList(stockPaidRef.map((data) => new RejectedStockPaidDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    setIsLoading,
    period,
    setPeriod,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    targetMonth,
    setTargetMonth,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingAttendAllowanceList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,
    applyingStockPaidList,
    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingAttendAllowanceList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,
    setApplyingStockPaidList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedAttendAllowanceList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,
    approvedStockPaidList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedAttendAllowanceList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,
    rejectedStockPaidList,

    fetchData,
    fetchApplyingList,
    fetchApprovedList,
    fetchRejectedList,

    detailRole,
  };
};

export default useAttendApplicationApproval;
