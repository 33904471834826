/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { grayScale } from 'components/styles';

const styles = {
  wrapper: css({
    padding: '15px 20px',
    textAlign: 'center',
    fontSize: '24px',
    color: grayScale.gray100,
  }),
  closeButton: css({
    marginLeft: 'auto',
  }),
};

/**
 * モーダルのヘッダー
 *  - 閉じるボタンを持つ
 */
const Header: React.FC<{
  title: string,
  customTitle?: React.ReactNode
}> = ({ title, customTitle }) => (
  <div css={styles.wrapper}>
    {customTitle ? customTitle : title}
  </div>
);

export default Header;
