import React from "react";

import SidebarTemplate from "components/templates/SidebarTemplate";
import SpecialSalaryPatternInputForm from "components/organismos/master/labor/SpecialSalaryPatternInputForm";
// import SpecialSalaryPatternInputSettingForm from "components/organismos/master/labor/SpecialSalaryPatternInputSettingForm";
// import { useParams } from "react-router-dom";
import FormContents from "components/atoms/Form/FormContents";
// import TabControl from "components/atoms/TabControl";
import FormTitle from "components/atoms/Form/FormTitle";

// type SelectTab = "edit" | "setting";
// const SelectTab: { [key in SelectTab]: SelectTab } = {
//   edit: "edit",
//   setting: "setting",
// };

const SpecialSalaryPatternEditPage: React.FC = () => {
  // const { tabIndex } = useParams();
  // const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.edit);
  return (
    <SidebarTemplate>
      <FormContents>
        <FormTitle bold={true} title="特賃日設定編集" />
        <SpecialSalaryPatternInputForm />
        {/*<div style={{ paddingLeft: "35px" }}>
           <TabControl
            items={[
              {
                value: SelectTab.edit,
                display: "特賃日情報",
              },
              {
                value: SelectTab.setting,
                display: "組織割当情報",
              },
            ]}
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={true}
            type={1}
          />
          {selectTab === SelectTab.edit && <SpecialSalaryPatternInputForm />}
          {selectTab === SelectTab.setting && <SpecialSalaryPatternInputSettingForm />} 
        </div>*/}
      </FormContents>
    </SidebarTemplate>
  );
};

export default SpecialSalaryPatternEditPage;
