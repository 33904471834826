/** @jsx jsx */
import React, { ReactNode } from "react";
import { css, jsx } from "@emotion/core";
import moment from "moment";
import { productColor, textColor, textFontSize, grayScale } from "components/styles";
import ClickableIconButton from "components/atoms/ClickableIconButton";

const styles = {
  wrapper: css({
    width: "100%",
    padding: "5px 0 5px 5px",
  }),
  label: (isOffRange: boolean) =>
    css({
      width: "32px",
      height: "32px",
      lineHeight: "32px",
      textAlign: "center",
      margin: "auto",
      fontSize: textFontSize.re,
      color: isOffRange ? grayScale.gray30 : textColor.main,
    }),
  todayLabel: css({
    borderRadius: "9999px",
    backgroundColor: productColor.primary,
    color: textColor.inversed,
  }),
};

const MonthDateHeader: React.FC = (props) => {
  const { children, label, date, onDrillDown, isOffRange } = props as {
    children: ReactNode;
    label: string;
    date: Date;
    onDrillDown: () => void;
    isOffRange: boolean;
  };
  const isToday = moment(date).format("YYYYMMDD") === moment().format("YYYYMMDD");
  let isEditable = true;
  const hopeShiftList: Array<any> | undefined = sessionStorage.getItem("hopeShiftList")
    ? JSON.parse(sessionStorage.getItem("hopeShiftList")!)
    : undefined;
  if (hopeShiftList) {
    const rawHopeShiftList = hopeShiftList?.map((item) => item.rawData);
    const hopeShift = rawHopeShiftList?.find((item) => item.targetDate === moment(date).format("YYYY-MM-DD"));
    isEditable = hopeShift?.isEditable;
  }
  return (
    <ClickableIconButton additionalCss={styles.wrapper} onClick={onDrillDown}>
      <div
        css={css(
          styles.label(isOffRange),
          isToday ? styles.todayLabel : isEditable ? {} : { color: grayScale.gray30 },
        )}
      >
        {Number(label)}
      </div>
      {children}
    </ClickableIconButton>
  );
};

export default MonthDateHeader;
