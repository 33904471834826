import moment from 'moment';
import { ContextMenuType } from 'components/molecules/ContextMenu/type';

const getContextMenuButtonsAgreement36 = (
  history: any,
  monthOverConditionsHour: number
) => {


  const stampButtons: ContextMenuType = {
    isRed: false,
    isSelectable: true,
    label: `限度時間超過申請`,
    isHidden: false,
    stamptype: 1,
    onClick: () => {
      const link = `${window.location.pathname}/attendAgreement36Application`;
      history.push(link)
    },
  };

  return [
    stampButtons,
  ];
};

export default getContextMenuButtonsAgreement36;
