
export type ApprovalItem = {
  privilegeId?: any,
  orgCode?: any,
  executiveId?: any,
  executiveName?: any,
  approvalLevel?: any,
  status: number,
  isHiddenLabel?:boolean;
  exists?: boolean
}
export type ApprovalData = {
  companyCode: string,
  belongOrgCode: string,
  typeApplication: string,
  updateUser: string,
  approvalFlowListData: Array<ApprovalItem>
} 

export default class TargetApplicationDomain {
  constructor(private rawData: ApprovalData) {
    // do nothing
  }

  static generateInitial(): TargetApplicationDomain {
    return new TargetApplicationDomain({
      companyCode: '',
      belongOrgCode: '',
      typeApplication: '',
      updateUser: '',
      approvalFlowListData: []
    });
  }

  getRawData(): ApprovalData {
    return this.rawData;
  }

  get approvalFlowListData(): Array<ApprovalItem> {
    return this.rawData.approvalFlowListData;
  }

  set approvalFlowListData(approvalFlowListData: Array<ApprovalItem>) {
    this.rawData.approvalFlowListData = approvalFlowListData; 
  }
}
