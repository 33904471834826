/** @jsx jsx */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { jsx, SerializedStyles } from "@emotion/core";

import ReactTooltip from "react-tooltip";

export interface IconTooltipProps {
  text: string;
  textDis?: any;
  customCss?: SerializedStyles;
  wrapCustomCss?: SerializedStyles;
  place?: "top" | "right" | "bottom" | "left" | undefined;
  tooltipCSs?: SerializedStyles;
}

/**
 * ツールチップのついたアイコン
 */
const TextTooltip: React.FC<IconTooltipProps> = ({
  text,
  textDis,
  customCss,
  place = "top",
  wrapCustomCss,
  tooltipCSs,
}) => {
  const rand = 1 + Math.random() * (1 - 10000);
  return text !== "" ? (
    <div className="tooltip" css={wrapCustomCss}>
      <a data-tip="true" css={customCss} data-for={`tooltip${rand}`}>
        {textDis ? textDis : text}
      </a>
      <ReactTooltip html={true} id={`tooltip${rand}`} css={tooltipCSs} place={place}>
        {text}
      </ReactTooltip>
    </div>
  ) : (
    <div css={wrapCustomCss}>{textDis}</div>
  );
};

export default TextTooltip;
