/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import FiscalYearDomain from 'domain/master/sales/fiscalYear';
import useToastNotification from 'hooks/useToastNotification';
import { useFiscalYearList } from './hooks';
import Icon from '../../../../../atoms/Icon';
import { iconColor } from '../../../../../styles';
import ConfirmModal from '../../../../../organismos/ConfirmModal';
import { deleteById } from '../../../../../../api/fiscalYear';
import ToastModal from '../../../../../organismos/ToastModal';

const FiscalYearsPage: React.FC = () => {
  const { fiscalYearList, setFiscalYearList } = useFiscalYearList();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(FiscalYearDomain.generateInitial());
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const openConfirmModal = useCallback((fiscalYear : FiscalYearDomain) => {
    setConfirmModalOpen(true);
    setSelectedFiscalYear(fiscalYear);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const deleteFiscalYear = useCallback(
    () => {
      deleteById(selectedFiscalYear.fiscalYear).then((response:any) => {
        setFiscalYearList(fiscalYearList.filter((items) => items.fiscalYear
          !== selectedFiscalYear.fiscalYear));

        setConfirmModalOpen(false);
        // setToastMessage('削除しました。');
        // setToastModalOpen(true);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        // setToastMessage(error.reponse.data.error[0].defaultMessage);
        // setToastModalOpen(true);
        errorNotification(error.reponse.data.error[0].defaultMessage);
      });
    },
    [selectedFiscalYear.fiscalYear, setFiscalYearList,
      fiscalYearList, successNotification, errorNotification],
  );

  const columns: Array<Column<FiscalYearDomain>> = useMemo(() => [
    {
      Header: '会計年度',
      accessor: 'fiscalYearStr',
    },
    {
      Header: '期首年月',
      accessor: 'fiscalStartMonthStr',
    },
    {
      Header: '期末年月',
      accessor: 'fiscalEndMonthStr',
    },
    {
      Header: () => null,
      id: 'actionButton',
      accessor: 'fiscalYearStr',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, values: FiscalYearDomain, original: any }}) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/masterFiscalYear/edit/${cell.row.original.rawData.id.fiscalYear}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
            <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                onMouseEnter={() => setIsHoverDelete(true)}
                onMouseLeave={() => setIsHoverDelete(false)}
              >
                <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>
          </div>
        );
      },
    },
  ], [openConfirmModal]);

  return (
    <React.Fragment>
      <SidebarTemplate pageTitle="会計年度マスタ">
        <PageTitle
          title=""
          rightContents={(
            <Link to="/masterFiscalYear/add">
              <PrimaryButton
                ghost={false}
                text="新規登録"
              />
            </Link>
        )}
        />

        <div
          css={css`
            table th:nth-of-type(1) {
              width: 150px;
            }
            table th:nth-of-type(2) {
              width: 150px;
            }
            table th:nth-of-type(3) {
              width: 150px;
            }
            table th:nth-of-type(4) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={fiscalYearList}
              isGlobalFilter={true}
            />
          </div>
        </div>
        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteFiscalYear}
          title="確認メッセージ"
          content="削除します。よろしいですか？"
          submitText="削除"
          items={[]}
        />
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </SidebarTemplate>
    </React.Fragment>
  );
};

export default FiscalYearsPage;
