import React from 'react'
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ApprovalHolidayApplication from 'components/organismos/master/attend/ApprovalApplication/ApprovalHolidayApplication';

const ApprovalHolidayApplicationPage = () => {
  return(
    <SidebarTemplate pageTitle="休暇申請 承認・差戻">
      <ApprovalHolidayApplication/>
    </SidebarTemplate>
  )
}

export default ApprovalHolidayApplicationPage