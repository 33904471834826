/** @jsx jsx */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import { css, jsx } from "@emotion/core";
import { Container, Row, Col } from "react-grid-system";
import moment from "moment";
import BodyText from "components/atoms/BodyText";
import Button, { PrimaryButton, DeleteButton } from "components/atoms/Button";
import TextForm from "components/molecules/TextForm";
import BlockUI from "components/molecules/BlockUi";
import FormPadding from "components/atoms/Form/FormPadding";
import FlexBox from "components/atoms/FlexBox";
import productColor, { textFontSize, textColor, grayScale } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import Table from "components/molecules/Table";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import Checkbox from "components/molecules/Checkbox";
import useStockPaid from "./hook";
import ConfirmModal from "components/organismos/ConfirmModal";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable";
import TabControl from "components/atoms/TabControl";
import AttendStockPaidModal from "components/molecules/ChangeLogModal/Template/AttendStockPaidModal";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import Calendar from "components/molecules/CalendarV1";
import useIsMobile from "hooks/useIsMobile";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";
import MobileSelect from "components/atoms/MobileSelect";

const styles = {
  container: {
    marginBottom: "16px",
  },
  thead: css({
    width: "200px",
  }),
  tableContent: css({
    justifyContent: "center",
    alignItems: "center",
  }),
  colorText: css({
    color: grayScale.gray100,
  }),
  approvalWrap: css({
    padding: "0px",
    marginBottom: "15px",
    marginTop: "0px",
  }),
  formContent: {
    paddingBottom: "20px",
  },
  rowPadding: css({
    paddingBottom: "16px",
  }),
};

enum ActionType {
  Add = 0,
  Modify = 1,
  Delete = 2,
}

const StockPaidPage: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const {
    isLoading,
    staffName,
    targetDate,
    setTargetDate,
    targetForm,
    setTargetFrom,
    targetEnd,
    setTargetEnd,
    dataHistory,
    formik,
    holidayOptions,
    confirmModalOpen,
    closeConfirmModal,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogHoliday,
    stockModiferAppId,
    deemedMorningHalfHours,
    deemedAfternoonHalfHours,
  } = useStockPaid();

  useEffect(() => {
    fetchChangeLogHoliday();
  }, []);

  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    type: "",
  });

  useEffect(() => {
    renderModal(formik.values.attendType);
  }, [formik.values.attendType]);

  useEffect(() => {
    if (formik.values.holidayUnit === 0 || stockModiferAppId !== "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formik.values.holidayUnit]);

  const handleChangeHalfStartTime = (type: number, val: string) => {
    if (type === 0) {
      formik.setFieldValue("halfHolidayStartTimeHour", val);
    } else if (type === 1) {
      formik.setFieldValue("halfHolidayStartTimeMinute", val);
    }

    if (
      (formik.values.holidayUnit !== 3 && formik.values.holidayUnit !== 4) ||
      (formik.values.holidayUnit === 3 && deemedMorningHalfHours === 0) ||
      (formik.values.holidayUnit === 4 && deemedAfternoonHalfHours === 0)
    ) {
      return;
    }

    var minutes =
      Number(formik.values.halfHolidayStartTimeHour) * 60 + Number(formik.values.halfHolidayStartTimeMinute);
    var endMinutesCalc =
      minutes + (formik.values.holidayUnit === 3 ? deemedMorningHalfHours : deemedAfternoonHalfHours);

    var endHour = Math.trunc(endMinutesCalc / 60);
    var nextDay = 24 <= endHour ? true : formik.values.halfHolidayStartTimeNextDay;
    var endMinutes = endMinutesCalc - endHour * 60;
    endHour = 24 <= endHour ? endHour - 24 : endHour;

    formik.setFieldValue("halfHolidayEndTimeHour", endHour < 10 ? "0" + String(endHour) : String(endHour));
    formik.setFieldValue("halfHolidayEndTimeMinute", endMinutes < 10 ? "0" + String(endMinutes) : String(endMinutes));
    formik.setFieldValue("halfHolidayEndTimeNextDay", nextDay);
  };

  function handleChangeHolidayUnit(holidayUnit: string) {
    formik.setFieldValue("holidayUnit", Number(holidayUnit));
    formik.setFieldValue("halfHolidayStartTimeHour", "00");
    formik.setFieldValue("halfHolidayStartTimeMinute", "00");
    formik.setFieldValue("halfHolidayEndTimeHour", "00");
    formik.setFieldValue("halfHolidayEndTimeMinute", "00");
    formik.setFieldValue("halfHolidayStartTimeNextDay", false);
    formik.setFieldValue("halfHolidayEndTimeNextDay", false);
    if (holidayUnit === "0" || stockModiferAppId !== "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function renderModal(attendType: number) {
    switch (attendType) {
      case 0:
        setModal({
          title: "休暇を申請",
          content: "休暇を申請します。よろしいですか？",
          type: "confirm",
        });
        break;

      case 1:
        setModal({
          title: "休暇キャンセルを申請",
          content: "休暇キャンセルを申請します。よろしいですか？",
          type: "reject",
        });
        break;

      case 2:
        setModal({
          title: "削除休暇を申請",
          content: "削除休暇を申請します。よろしいですか？",
          type: "reject",
        });
        break;

      default:
        setModal({
          title: "休暇修正を申請",
          content: "休暇修正を申請します。よろしいですか？",
          type: "confirm",
        });
        break;
    }
  }

  return (
    <SidebarTemplate pageTitle="積立有給申請">
      <BlockUI blocking={isLoading}>
        <FormContents
          customStyle={css({
            paddingBottom: isMobile ? "8px" : "",
          })}
        >
          <FormTitle
            title="積立有給申請"
            bold={true}
            customStyle={css(isMobile ? { padding: "8px 16px 16px 16px", margin: 0 } : {})}
          />
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormPadding
                customStyle={css(
                  isMobile ? { padding: "0 16px 16px 16px" } : { paddingLeft: "35px", paddingBottom: 0 },
                )}
              >
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText customStyle={styles.colorText}>{`${staffName}`}</BodyText>
                  </Col>
                </Row>

                {isMobile ? (
                  <div css={css({ display: "flex", flexDirection: "column", gap: 16 })}>
                    <div css={css({ display: "flex", flexDirection: "row", gap: 32 })}>
                      <RadioSelectForm
                        label=""
                        name="day"
                        items={[
                          {
                            label: "１日",
                            value: "0",
                          },
                        ]}
                        value={String(formik.values.applyTargetDateType)}
                        setValue={(e) => formik.setFieldValue("applyTargetDateType", Number(e.target.value))}
                      />
                      <RadioSelectForm
                        label=""
                        name="daily"
                        items={[
                          {
                            label: "期間指定",
                            value: "1",
                          },
                        ]}
                        value={String(formik.values.applyTargetDateType)}
                        setValue={(e) => formik.setFieldValue("applyTargetDateType", Number(e.target.value))}
                      />
                    </div>
                    <div>
                      {formik.values.applyTargetDateType === 0 && (
                        <Calendar
                          date={new Date(targetDate)}
                          setDate={(date) => {
                            if (date) {
                              setTargetDate(date);
                              setTargetFrom(date);
                              formik.setFieldValue("targetStartDate", date);
                              setTargetEnd(date);
                              formik.setFieldValue("targetEndDate", date);
                            }
                            formik.setFieldValue("targetDate", date);
                          }}
                          readOnly={String(formik.values.applyTargetDateType) === "1"}
                        />
                      )}
                      {formik.values.applyTargetDateType === 1 && (
                        <CalendarRangeV2
                          startDate={targetForm}
                          onChangeStart={(date: Date) => {
                            setTargetFrom(date);
                            formik.setFieldValue("targetStartDate", date);
                          }}
                          endDate={targetEnd}
                          onChangeEnd={(date) => {
                            if (date) {
                              setTargetEnd(date);
                              formik.setFieldValue("targetEndDate", date);
                            }
                          }}
                          readOnly={String(formik.values.applyTargetDateType) === "0"}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <Row css={styles.rowPadding}>
                    <Col lg={2} md={3}>
                      <div style={{}}>
                        <BodyText>対象期間</BodyText>
                      </div>
                    </Col>
                    <Col md={10}>
                      <FlexBox>
                        <Col lg={2} md={3}>
                          <div style={{ marginTop: "-10px" }}>
                            <RadioSelectForm
                              label=""
                              name="day"
                              items={[
                                {
                                  label: "１日",
                                  value: "0",
                                },
                              ]}
                              value={String(formik.values.applyTargetDateType)}
                              setValue={(e) => formik.setFieldValue("applyTargetDateType", Number(e.target.value))}
                            />
                          </div>
                        </Col>
                        <Col lg={4} md={4}>
                          <FlexBox customStyle={css({ marginRight: "20px" })}>
                            <FlexBoxItem>
                              <Calendar
                                date={new Date(targetDate)}
                                setDate={(date) => {
                                  if (date) {
                                    setTargetDate(date);
                                    setTargetFrom(date);
                                    formik.setFieldValue("targetStartDate", date);
                                    setTargetEnd(date);
                                    formik.setFieldValue("targetEndDate", date);
                                  }
                                  formik.setFieldValue("targetDate", date);
                                }}
                                readOnly={String(formik.values.applyTargetDateType) === "1"}
                              />
                            </FlexBoxItem>
                          </FlexBox>
                        </Col>
                      </FlexBox>
                      <FlexBox customStyle={css({ marginTop: "10px" })}>
                        <Col lg={2} md={3}>
                          <div style={{ marginTop: "-20px" }}>
                            <RadioSelectForm
                              label=""
                              name="daily"
                              items={[
                                {
                                  label: "期間指定",
                                  value: "1",
                                },
                              ]}
                              value={String(formik.values.applyTargetDateType)}
                              setValue={(e) => formik.setFieldValue("applyTargetDateType", Number(e.target.value))}
                            />
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <CalendarRangeV2
                            startDate={targetForm}
                            onChangeStart={(date: Date) => {
                              setTargetFrom(date);
                              formik.setFieldValue("targetStartDate", date);
                            }}
                            endDate={targetEnd}
                            onChangeEnd={(date) => {
                              if (date) {
                                setTargetEnd(date);
                                formik.setFieldValue("targetEndDate", date);
                              }
                            }}
                            readOnly={String(formik.values.applyTargetDateType) === "0"}
                          />
                        </Col>
                      </FlexBox>
                    </Col>
                  </Row>
                )}
              </FormPadding>
              {isMobile ? (
                <FormPadding customStyle={css({ padding: "0 16px" })}>
                  <div css={css({ display: "flex", flexDirection: "column", gap: 16, paddingBottom: 16 })}>
                    <div>
                      <BodyText>休暇単位</BodyText>
                      <VerticalLabelSelectForm
                        label=""
                        name="holidayUnit"
                        value={String(formik.values.holidayUnit)}
                        setValue={(val: string) => handleChangeHolidayUnit(val)}
                        options={holidayOptions}
                        required={true}
                        disable={stockModiferAppId !== ""}
                        isSearchable={false}
                      />
                    </div>
                    {(stockModiferAppId || (!stockModiferAppId && !disabled)) && (
                      <div>
                        <BodyText>半休となる時間</BodyText>
                        <div css={css({ display: "flex", flexDirection: "column" })}>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="startHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(0, val);
                              }}
                              disabled={disabled}
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayStartTimeHour)}
                            />
                            <span>:</span>
                            <MobileSelect
                              id="startTime"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(1, val);
                              }}
                              disabled={disabled}
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayStartTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayStartTimeNextDay"
                              name="halfHolidayStartTimeNextDay"
                              label="翌日"
                              value=""
                              readOnly={disabled}
                              checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                              onChange={formik.handleChange}
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ margin: "0 10px" })}>～</BodyText>
                          </div>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="endHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeHour", val);
                              }}
                              disabled={disabled}
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayEndTimeHour)}
                            />
                            <span>:</span>
                            <MobileSelect
                              id="endTime"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeMinute", val);
                              }}
                              disabled={disabled}
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayEndTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayEndTimeNextDay"
                              name="halfHolidayEndTimeNextDay"
                              label="翌日"
                              value=""
                              readOnly={disabled}
                              checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                              onChange={formik.handleChange}
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ minWidth: "34px" })}></BodyText>
                          </div>
                        </div>
                      </div>
                    )}

                    <div>
                      <BodyText>申請理由</BodyText>
                      <TextForm
                        required={false}
                        name="applicationReason"
                        label=""
                        value={String(formik.values.applicationReason)}
                        onChange={formik.handleChange}
                        disable={stockModiferAppId !== "" && formik.values.applicationStatus === 0}
                      />
                    </div>
                  </div>
                </FormPadding>
              ) : (
                <React.Fragment>
                  <Row
                    style={{
                      margin: "0px 8px",
                    }}
                  >
                    <Table customStyle={styles.container}>
                      <thead>
                        <tr>
                          <Table.HeaderCell customStyle={styles.thead}>休暇単位</Table.HeaderCell>
                          <Table.HeaderCell>半休となる時間</Table.HeaderCell>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <Table.Cell>
                            <VerticalLabelSelectForm
                              label=""
                              name="holidayUnit"
                              value={String(formik.values.holidayUnit)}
                              setValue={(val: string) => handleChangeHolidayUnit(val)}
                              options={holidayOptions}
                              required={true}
                              disable={stockModiferAppId !== ""}
                              isSearchable={false}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                padding: "0 15%",
                              }}
                            >
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="startHour"
                                  disable={disabled}
                                  value={String(formik.values.halfHolidayStartTimeHour)}
                                  setValue={(val: string) => handleChangeHalfStartTime(0, val)}
                                  options={hourFormOptions}
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="startTime"
                                  disable={disabled}
                                  value={String(formik.values.halfHolidayStartTimeMinute)}
                                  setValue={(val: string) => handleChangeHalfStartTime(1, val)}
                                  options={timeFormOptions}
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="halfHolidayStartTimeNextDay"
                                  name="halfHolidayStartTimeNextDay"
                                  label="翌日"
                                  value=""
                                  readOnly={disabled}
                                  checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <BodyText customStyle={css({ margin: "0 10px" })}>~</BodyText>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="endHour"
                                  disable={disabled}
                                  value={String(formik.values.halfHolidayEndTimeHour)}
                                  setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeHour", val)}
                                  options={hourFormOptions}
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="endTime"
                                  disable={disabled}
                                  value={String(formik.values.halfHolidayEndTimeMinute)}
                                  setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeMinute", val)}
                                  options={timeFormOptions}
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="halfHolidayEndTimeNextDay"
                                  name="halfHolidayEndTimeNextDay"
                                  label="翌日"
                                  value=""
                                  readOnly={disabled}
                                  checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                          </Table.Cell>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                  <FormPadding customStyle={css({ paddingLeft: "35px", paddingBottom: 0 })}>
                    <Row css={styles.rowPadding}>
                      <Col
                        md={2}
                        xs={12}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <BodyText
                          customStyle={css(
                            isMobile
                              ? {
                                  marginBottom: "4px",
                                }
                              : {},
                          )}
                        >
                          申請理由
                        </BodyText>
                      </Col>
                      <Col md={6} xs={12}>
                        <TextForm
                          required={false}
                          name="applicationReason"
                          label=""
                          value={String(formik.values.applicationReason)}
                          onChange={formik.handleChange}
                          disable={stockModiferAppId !== "" && formik.values.applicationStatus === 0}
                        />
                      </Col>
                    </Row>
                  </FormPadding>
                </React.Fragment>
              )}

              {isMobile ? (
                <FormPadding customStyle={css({ padding: "0 16px" })}>
                  <div css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr);", gap: 8 }}>
                    {stockModiferAppId === "" && (
                      <PrimaryButton
                        ghost={false}
                        text="申請する"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("attendType", ActionType.Add);
                        }}
                      />
                    )}
                    {stockModiferAppId !== "" && formik.values.applicationStatus == 0 && (
                      <DeleteButton
                        ghost={false}
                        text="申請キャンセル"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("attendType", ActionType.Modify);
                        }}
                      />
                    )}
                    {stockModiferAppId !== "" && formik.values.applicationStatus == 1 && (
                      <DeleteButton
                        ghost={false}
                        text="削除申請"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("attendType", ActionType.Delete);
                        }}
                      />
                    )}

                    <Button
                      customStyle={css({
                        border: `1px solid ${productColor.primary}`,
                      })}
                      onClick={() => history.goBack()}
                      text="キャンセル"
                      ghost={true}
                    />
                  </div>
                </FormPadding>
              ) : (
                <React.Fragment>
                  <Row>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {stockModiferAppId === "" && (
                        <PrimaryButton
                          ghost={false}
                          text="申請する"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue("attendType", ActionType.Add);
                          }}
                        />
                      )}
                      {stockModiferAppId !== "" && formik.values.applicationStatus == 0 && (
                        <div className="btnAction">
                          <DeleteButton
                            ghost={false}
                            text="申請キャンセル"
                            onClick={() => {
                              formik.handleSubmit();
                              formik.setFieldValue("attendType", ActionType.Modify);
                            }}
                          />
                        </div>
                      )}
                      {stockModiferAppId !== "" && formik.values.applicationStatus == 1 && (
                        <span style={{ marginLeft: "12px" }}>
                          <DeleteButton
                            ghost={false}
                            text="削除申請"
                            onClick={() => {
                              formik.handleSubmit();
                              formik.setFieldValue("attendType", ActionType.Delete);
                            }}
                          />
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <FlexBox>
                        <Button onClick={() => history.goBack()} text="キャンセル" ghost={true} />
                      </FlexBox>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Container>
          </form>
        </FormContents>
      </BlockUI>
      {!isMobile && (
        <div style={{ paddingTop: 20 }}>
          <FormContents customStyle={styles.formContent}>
            <Row style={{ padding: "10px 30px" }}>
              <Col
                md={2}
                xs={12}
                style={{
                  fontWeight: "bold",
                  fontSize: textFontSize.md,
                  lineHeight: "24px",
                  paddingBottom: "20px",
                  color: textColor.main,
                }}
              >
                更新履歴
              </Col>
            </Row>
            <TabControl
              isTabModal={true}
              isFixed={false}
              items={dataHistory?.map((log, index: number) => {
                return {
                  value: String(index),
                  display: `ver${log.version}`,
                };
              })}
              setValue={setSelectChangelog as (arg: string) => void}
              currentValue={selectChangelog}
              styleNotActive={css({
                borderRadius: 0,
                border: "none",
                width: "100px",
                flex: "unset",
              })}
              styleActive={css({
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #007BC3",
                width: "100px",
                flex: "unset",
              })}
            />
            {dataHistory?.length > 0 &&
              dataHistory?.map((itemLog, index: number) => (
                <div
                  style={{
                    display: selectChangelog !== String(index) ? "none" : "block",
                  }}
                >
                  <React.Fragment>
                    <FormPadding
                      customStyle={{
                        fontSize: "14px",
                        color: textColor.mobileTitle,
                      }}
                    >
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請種別:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.modifiyStatus == 0 ? "申請" : "削除"}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請者:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationStaffCode && `${itemLog.applicationStaffCode} `}
                          {itemLog.applicationStaffName && itemLog.applicationStaffName}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請日時:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationDate && moment(itemLog.applicationDate).format("YYYY/MM/DD HH:mm:ss")}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "10px",
                            color: textColor.main,
                          }}
                        >
                          出勤組織:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.orgCode && itemLog.orgCode}
                          {itemLog.orgName && itemLog.orgName}
                        </Col>
                      </Row>
                      <Row>
                        <AttendStockPaidModal
                          changeLogData={[itemLog]}
                          selectChangelog={String(index)}
                          customStyle={styles.approvalWrap}
                        />
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          修正理由:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationReason && itemLog.applicationReason}
                        </Col>
                      </Row>
                      {dataHistory?.length > 0 && (
                        <Row>
                          <HistoryApprovalTable
                            historyData={itemLog?.approvalHistoryList}
                            applicationStatus={itemLog.applicationStatus}
                          />
                        </Row>
                      )}
                    </FormPadding>
                  </React.Fragment>
                </div>
              ))}
          </FormContents>
        </div>
      )}

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title={modal.title}
        content={modal.content}
        typeSubmit={modal.type}
        submitText={"申請する"}
        onSubmit={() => {
          formik.handleSubmit();
        }}
      />
    </SidebarTemplate>
  );
};

export default StockPaidPage;
