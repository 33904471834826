/* eslint-disable array-callback-return */
import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useFormik } from 'formik';
import WorkingHoursDomain from 'domain/master/attend/workingHours';
import { post, getWorkingHours } from 'api/workingHours';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

// declare validation form Working Hours Master
type WorkingHoursDomainnKey = keyof Pick<WorkingHoursDomain, 'workingHoursPatternCode' | 'workingHoursPatternName'>;

//
export const useWorkingHoursDomainAddForm = (workingHoursId: string, typeButton: string) => {
  // history
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterWorkingHours);

  //
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  /**
   * create list month
   */
  const arrMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  
  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);
  
  // value input working hours
  const [valueWorkingHours, setValueWorkingHours] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  // value input working minute
  const [valueWorkingMinuteForms, setValueWorkingMinuteForms] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);


  const [valueLegalWorkingHours, setValueLegalWorkingHours] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [valueLegalWorkingMinuteForms, setValueLegalWorkingMinuteForms] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); 

  // handle submit form
  const onSubmit = async (values: WorkingHoursDomain) => {
    if (values.workingHoursPatternCode === null) {
      errorNotification('パターンコードを入力してください。');
      return;
    }
    if (values.workingHoursPatternName === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    // TODO companyCodeの取得処理が必要
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    let convertData = values.getRawData();

    //
    let workingHoursDetailId = '';
    if ((convertData as any).workingHoursDetails) {
      //
      workingHoursDetailId = (convertData as any).workingHoursDetails.workingHoursDetailId || '';
    }

    //
    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).workingHoursDetails;
    delete (convertData as any).companyCode;

    //
    convertData.companyCode = companyCode;
    convertData.createUser = loginUser;
    convertData.updateUser = loginUser;

    //
    valueWorkingHours.forEach((value: any, index: number) => {
      convertData = {
        ...convertData,
        [`workingHoursForm${index + 1}`]: Number(value),
        [`workingHoursDetails.workingHoursForm${index + 1}`]: Number(value),
      };
    });

    //
    valueWorkingMinuteForms.forEach((value: any, index: number) => {
      convertData = {
        ...convertData,
        [`workingMinuteForm${index + 1}`]: Number(value),
        [`workingHoursDetails.workingMinuteForm${index + 1}`]: Number(value),

      };
    }); 
     //
     valueLegalWorkingHours.forEach((value: any, index: number) => {
      convertData = {
        ...convertData,
        [`legalWorkingHoursFrom${index + 1}`]: Number(value),
        [`workingHoursDetails.legalWorkingHoursFrom${index + 1}`]: Number(value),
      };
    });

    //
    valueLegalWorkingMinuteForms.forEach((value: any, index: number) => {
      convertData = {
        ...convertData,
        [`legalWorkingMinuteForm${index + 1}`]: Number(value),
        [`workingHoursDetails.legalWorkingMinuteFrom${index + 1}`]: Number(value),
      };
    }); 
    
    //
    convertData = {
      ...convertData,
      [`workingHoursDetails.${'targetYear'}`]: convertData.targetYear,
      [`workingHoursDetails.${'workingHoursId'}`]: typeButton === 'copy' ? '' : workingHoursId,
      [`workingHoursDetails.${'workingHoursDetailId'}`]: typeButton === 'copy' ? '' : workingHoursDetailId,
    };
 
    for(let i = 0; i < valueWorkingHours.length; i+=1) { 
      let scheduledWorkingHours = Number(valueWorkingHours[i]) + Number((0 + '.'+ valueWorkingMinuteForms[i] ));
      let legalWorkingHours = Number(valueLegalWorkingHours[i]) + Number((0 + '.'+ valueLegalWorkingMinuteForms[i])); 
      if((Number(scheduledWorkingHours) > Number(legalWorkingHours)) && Number(legalWorkingHours) > 0){
        errorNotification('法定労働時間値は無効なので、再度ご入力してください');
        return false;
      }
    } 

    // check process
    try {
      setBlocking(true);
      const response = await post(companyCode, convertData);
      if (!response.errors) {
        successNotification(typeButton === 'edit' ? '更新しました。' : '登録しました。');
        setBlocking(false);
        history.goBack();
      }
    } catch (error) {
      setBlocking(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: WorkingHoursDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
    // validate: async (values) => {
    //   const errors: {[key: string]: string} = {};
    //   try {
    //     await schema.validate(values, { abortEarly: false });
    //   } catch (error) {
    //     const validationError = error as Yup.ValidationError;
    //     validationError.inner.forEach((innerError) => {
    //       errors[innerError.path] = innerError.message;
    //     });
    //   }
    //   return errors;
    // },
    // TODO classのアクセサ経由でバリデーションができるようにする
  });

  const targetYear = moment().format('YYYY');

  //
  useEffect(() => {
    if (workingHoursId !== undefined) {
      handleGetWorkingHours(targetYear, workingHoursId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetYear, workingHoursId]);


  /**
   * handle get working hours & set formik data
   *
   * @param targetYear
   * @param workingHoursId
  */
  const handleGetWorkingHours = async (targetYear: any, workingHoursId: any) => {
    setBlocking(true);
    getWorkingHours(targetYear, workingHoursId).then((response: any) => {
      setBlocking(false);
      if (response && response.companyCode) {
        formik.setValues(new WorkingHoursDomain(response));
        if(typeButton === 'copy'){
          formik.setFieldValue('workingHoursPatternCode', '');
          formik.setFieldValue('workingHoursPatternName', '');
          formik.setFieldValue('workingHoursId', '');
        }
        formik.setFieldValue('targetYear', targetYear);
        //
        const { workingHoursDetails } = response;
        if (workingHoursDetails) {
          const itemsHour = [...valueWorkingHours];
          const itemsMinute = [...valueWorkingMinuteForms];

          const itemsLegalHour = [...valueLegalWorkingHours];
          const itemsLegalMinute = [...valueLegalWorkingMinuteForms];

          Object.keys(workingHoursDetails).forEach((key_obj) => {
            const value = workingHoursDetails[key_obj];
            if (key_obj.includes('workingHoursForm')) {
              const split_key_obj = key_obj.split('workingHoursForm');
              (itemsHour as any)[parseInt(split_key_obj[1]) - 1] = value;
            } 
            
            else if (key_obj.includes('workingMinuteForm')) {
              const split_key_obj = key_obj.split('workingMinuteForm');
              (itemsMinute as any)[parseInt(split_key_obj[1]) - 1] = value;
            } 
            
            else if(key_obj.includes('legalWorkingHoursFrom')) {
              const split_key_obj = key_obj.split('legalWorkingHoursFrom');
              (itemsLegalHour as any)[parseInt(split_key_obj[1]) - 1] = value;
            } 
            
            else if(key_obj.includes('legalWorkingMinuteFrom')) {
              const split_key_obj = key_obj.split('legalWorkingMinuteFrom');
              (itemsLegalMinute as any)[parseInt(split_key_obj[1]) - 1] = value;
            }
          });  
          setValueWorkingHours(itemsHour);
          setValueWorkingMinuteForms(itemsMinute);

          setValueLegalWorkingHours(itemsLegalHour);
          setValueLegalWorkingMinuteForms(itemsLegalMinute);

        } else {
          setValueWorkingHours([]);
          setValueWorkingMinuteForms([]);
          setValueLegalWorkingHours([]);
          setValueLegalWorkingMinuteForms([]);
        }
        return true;
      }
      return false;
    });
  };

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  //
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  //
  const confirmModalItems = useMemo(() => ([
    {
      key: 'パターンコード',
      value: formik.values.workingHoursPatternCode,
    },
    {
      key: 'パターン名',
      value: formik.values.workingHoursPatternName,
    },
    {
      key: '対象年',
      value: formik.values.targetYear,
    },

  ]), [formik]);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    confirmModalItems,
    toastModalOpen,
    closeToastModal,
    setToastModalOpen,
    arrMonth,
    valueWorkingHours,
    setValueWorkingHours,
    valueWorkingMinuteForms,
    setValueWorkingMinuteForms,
    handleGetWorkingHours,
    valueLegalWorkingHours,
    setValueLegalWorkingHours,
    valueLegalWorkingMinuteForms,
    setValueLegalWorkingMinuteForms,
    blocking,
    detailRole
  };
};

export default {
  useWorkingHoursDomainAddForm,
};
