import React, { SVGProps } from 'react';
 
 const CheckInOffice: React.FC<SVGProps<SVGSVGElement>> = props => (
   <svg width="71" height="68" viewBox="0 0 71 68" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path fillRule="evenodd" clipRule="evenodd" d="M60.6192 32.5593C61.205 31.9736 61.205 31.0238 60.6192 30.438L53.1193 22.9381C52.5335 22.3524 51.5838 22.3524 50.998 22.9381C50.4122 23.5239 50.4122 24.4737 50.998 25.0594L55.9373 29.9987L38.5719 29.9987C37.8618 29.9987 37.2862 30.6703 37.2862 31.4987C37.2862 32.3271 37.8618 32.9987 38.5719 32.9987L55.9373 32.9987L50.998 37.9379C50.4122 38.5237 50.4122 39.4735 50.998 40.0592C51.5838 40.645 52.5335 40.645 53.1193 40.0592L60.6192 32.5593Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M36.0009 1.49917C36.0009 0.670755 36.6724 -0.000808716 37.5008 -0.000808716L62.999 -0.000808716C67.141 -0.000808716 70.4987 3.35701 70.4987 7.49909V58.4984C70.4987 62.6405 67.141 65.9983 62.999 65.9983H37.5008C36.6724 65.9983 36.0009 65.3268 36.0009 64.4983C36.0009 63.6699 36.6724 62.9984 37.5008 62.9984H62.999C65.4842 62.9984 67.4988 60.9837 67.4988 58.4984V7.49909C67.4988 5.01384 65.4842 2.99915 62.999 2.99915H37.5008C36.6724 2.99915 36.0009 2.32759 36.0009 1.49917Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M27.0689 18.6731C26.4448 22.7679 22.6195 25.5815 18.5247 24.9575C14.4299 24.3335 11.6162 20.5081 12.2403 16.4133C12.8643 12.3185 16.6896 9.50489 20.7844 10.1289C24.8793 10.7529 27.6929 14.5783 27.0689 18.6731ZM18.9766 21.9918C21.4335 22.3662 23.7287 20.678 24.1031 18.2211C24.4776 15.7643 22.7894 13.469 20.3325 13.0946C17.8756 12.7202 15.5804 14.4084 15.206 16.8653C14.8316 19.3222 16.5197 21.6174 18.9766 21.9918Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M14.1583 27.6676C13.275 28.1254 12.84 28.749 12.7482 29.3512L10.8964 41.5028C10.7716 42.3217 11.3343 43.0868 12.1533 43.2116L18.0847 44.1155C18.9037 44.2403 19.6687 43.6776 19.7935 42.8586L21.6454 30.7071C21.7371 30.1048 21.5076 29.38 20.8008 28.6799C20.0952 27.9809 18.9872 27.3998 17.629 27.1928C16.2709 26.9858 15.04 27.2106 14.1583 27.6676ZM12.7778 25.0041C14.281 24.225 16.1633 23.9348 18.081 24.2271C19.9987 24.5193 21.709 25.357 22.9119 26.5485C24.1136 27.7388 24.8828 29.3758 24.6111 31.159L22.7593 43.3106C22.3848 45.7675 20.0896 47.4556 17.6327 47.0812L11.7013 46.1773C9.24443 45.8029 7.55625 43.5077 7.93066 41.0508L9.78247 28.8993C10.0542 27.1161 11.2761 25.7824 12.7778 25.0041Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M10.6863 42.8876C9.86429 42.7848 9.11461 43.3679 9.01186 44.1899L7.59972 55.4871C7.57084 55.7181 7.48855 55.9393 7.35938 56.133L1.7523 64.5437C1.29278 65.233 1.47904 66.1642 2.16832 66.6238C2.8576 67.0833 3.78889 66.897 4.24842 66.2077L9.8555 57.7971C10.243 57.2159 10.4899 56.5524 10.5765 55.8592L11.9887 44.562C12.0914 43.74 11.5083 42.9903 10.6863 42.8876Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M17.3296 44.5344C16.5887 44.9049 16.2883 45.8059 16.6588 46.5469L20.941 55.1113C21.0131 55.2555 21.0615 55.4104 21.0843 55.57L22.5154 65.5879C22.6326 66.408 23.3924 66.9779 24.2125 66.8607C25.0326 66.7436 25.6024 65.9838 25.4852 65.1637L24.0541 55.1458C23.9857 54.667 23.8406 54.2023 23.6243 53.7697L19.342 45.2053C18.9716 44.4643 18.0706 44.164 17.3296 44.5344Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M11.7003 28.4763C11.2033 27.8135 10.2631 27.6792 9.60034 28.1763L4.27341 32.1715C3.61315 32.6666 3.1018 33.3339 2.79528 34.1002L0.107752 40.819C-0.199915 41.5882 0.174204 42.4611 0.943369 42.7688C1.71253 43.0764 2.58548 42.7023 2.89314 41.9332L5.58068 35.2143C5.68285 34.9589 5.8533 34.7365 6.07339 34.5714L11.4003 30.5762C12.063 30.0792 12.1974 29.139 11.7003 28.4763Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M17.0633 29.7049C16.4164 30.2224 16.3116 31.1664 16.8291 31.8132L21.9283 38.1872C22.7822 39.2547 24.0751 39.8761 25.4421 39.8761L34.5001 39.8761C35.3286 39.8761 36.0001 39.2045 36.0001 38.3761C36.0001 37.5477 35.3286 36.8761 34.5001 36.8761L25.4421 36.8761C24.9865 36.8761 24.5555 36.669 24.2708 36.3132L19.1716 29.9392C18.6541 29.2923 17.7102 29.1874 17.0633 29.7049Z" fill="white"/>
   </svg>
 );
 
 export default CheckInOffice;