/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import Table from 'components/molecules/Table';
import SortIcon from 'components/molecules/SortIcon';

const style = css({
  position: 'sticky',
  // top: '48px', // Appbarのheight分下げる
});

interface TableHeaderCellProps {
  onClick: () => void;
  isSorted: boolean;
  isSortedDesc?: boolean;
  disableSortBy?: any;
}

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  children, onClick, isSorted, isSortedDesc, disableSortBy
}) => (
    <Table.HeaderCell
      onClick={onClick}
      customStyle={css(style, disableSortBy ? { cursor: 'auto !important' } : '')}
      className={disableSortBy ? 'disabledSort' : 'sort'}
    >
      {children}
      {isSorted
        && <SortIcon isSortedDesc={isSortedDesc} />}
    </Table.HeaderCell>
  );

export default TableHeaderCell;
