/** @jsx jsx */
import React from "react";
import { Column } from "react-table";
import { css, jsx } from "@emotion/core";
import { useHistory } from "react-router-dom";
import DataTable from "components/organismos/DataTable/DataTable";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import EmploymentDomain from "domain/employment";
import ConfirmModal from "components/organismos/ConfirmModal";
import { SubActionButton } from "components/atoms/Button";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import ApplyDateFilter from "./ApplyDateFilter";
import ButtonNavigation from "./ButtonNavigation";
import { useEmploymentDelete } from "./hooks";
import FormTitle from "components/atoms/Form/FormTitle";
import FormContents from "components/atoms/Form/FormContents";
import { DetailRole } from "api/masterRole";

const styles = {
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
  note: css({
    color: "#ED5D5D",
    marginLeft: "76px",
    fontSize: "12px",
    marginTop: "0px",
  }),
  formContentFilter: css({
    maxWidth: "700px",
    padding: "10px 35px",
  }),
  columnOne: css(
    {
      justifyContent: "center",
      width: "140px",
      margin: "0 auto",
    },
    css`
      button {
        white-space: nowrap;
      }
    `
  ),
};

const EmploymentListTable: React.FC<{
  employmentList: Array<EmploymentDomain>;
  fetchData: () => Promise<void>;
  allEmployment: boolean;
  toggleAllEmployment: () => void;
  applyDate: Date;
  setApplyDate: (arg: Date) => void;
  detailRole: DetailRole;
}> = ({
  employmentList,
  fetchData,
  allEmployment,
  toggleAllEmployment,
  applyDate,
  setApplyDate,
  detailRole,
}) => {
  const history = useHistory();
  const {
    // delEmployment,
    setDelEmployment,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteEmployment,
  } = useEmploymentDelete(fetchData);

  const columns: Array<Column<EmploymentDomain>> = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "employmentId",
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: EmploymentDomain };
        }) => {
          return (
            <FlexBox customStyle={styles.columnOne}>
              <FlexBoxItem width="36px">
                <SubActionButton
                  action={0}
                  text={detailRole.editable === 1 ? `編集` : `参照`}
                  onClick={() => {
                    history.push(
                      `/masterEmploymentForm/edit/2/${cell.row.original.employmentId}/${cell.row.original.applyStartDate}`
                    );
                  }}
                />
              </FlexBoxItem>
              {detailRole.editable === 1 ? (
                <React.Fragment>
                  <FlexBoxItem marginLeft="5px" width="36px">
                    <SubActionButton
                      action={1}
                      text="削除"
                      onClick={() => setDelEmployment(cell.row.original)}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem marginLeft="5px">
                    <SubActionButton
                      action={2}
                      text="コピー"
                      onClick={() => {
                        history.push(
                          `/masterEmploymentForm/edit/1/${cell.row.original.employmentId}/${cell.row.original.applyStartDate}`
                        );
                      }}
                    />
                  </FlexBoxItem>
                </React.Fragment>
              ) : null}
            </FlexBox>
          );
        },
      },
      {
        Header: "雇用形態コード",
        accessor: "employmentCode",
      },
      {
        Header: "雇用形態名",
        accessor: "employmentName",
      },
      {
        Header: "運用開始年月",
        accessor: "dispApplyDateStr",
      },
    ],
    [setDelEmployment, history, detailRole]
  );

  return (
    <FormContents>
      <FormTitle title="雇用形態マスタ" bold={true} />
      <div style={{ backgroundColor: "#fff" }}>
        <FormContentFilter customStyles={styles.formContentFilter}>
          <ApplyDateFilter
            allEmployment={allEmployment}
            toggleAllEmployment={toggleAllEmployment}
            defaultValue={new Date()}
            applyDate={applyDate}
            setApplyDate={setApplyDate}
          />
          <p css={styles.note}>
            適用年月を指定した場合、適用年月時点で有効な雇用形態の一覧を表示します。
          </p>
        </FormContentFilter>
        {detailRole.editable === 1 && (
          <div style={{ maxWidth: "140px" }}>
            <ButtonNavigation />
          </div>
        )}
        <div
          css={css`
            table {
              table-layout: auto;
            }
            table th:nth-of-type(1) {
              width: 140px;
            }
            table th:nth-of-type(2) {
              width: 100px;
              text-align: left;
            }
            table th:nth-of-type(3) {
              width: calc(100% - 340px);
              text-align: left;
            }
            table th:nth-of-type(4) {
              width: 100px;
              text-align: left;
            }
          `}
        >
          <DataTable
            columns={columns}
            data={employmentList}
            isGlobalFilter={true}
            globalSearchStyle={styles.filterSearch}
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
          />
        </div>
      </div>
      <ConfirmModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        title="雇用形態マスタを削除"
        content="雇用形態マスタを削除します。よろしいですか？"
        submitText="削除する"
        onSubmit={onSubmitDeleteEmployment}
        items={[]}
        typeSubmit="reject"
      />
    </FormContents>
  );
};

export default EmploymentListTable;
