/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { productColor, textColor, grayScale } from 'components/styles';
import BaseButton, { ButtonProps } from './BaseButton';

const style = css({ 
  width: '88px', 
  fontSize: '12px',
  padding: '0',
  lineHeight: '22px',
  borderRadius: '3px',
  '&:disabled': {
      background: '#AFDCEE'
  }
});


type ICCardConnectButtonProps = Omit<ButtonProps, 'text'> & { text?: string };

const ICCardConnectButton: React.FC<ICCardConnectButtonProps> = ({
  onClick, disabled, ghost, children, fullwidth = false, minWidth='120px', customStyle = css({})
}) => (
  <BaseButton
    color={{
      baseColor: productColor.primary,
      textColor: textColor.inversed,
      ghostColor: productColor.primary,
      ghostTextColor: productColor.primary,
      hoverColor: disabled ? grayScale.gray20 : productColor.primaryHover,
      focusColor: productColor.primary,
    }}
    text={disabled ? 'PaSoRiと接続中' : 'PaSoRiと接続'}
    onClick={onClick}
    disabled={disabled}
    ghost={ghost}
    fullwidth={fullwidth}
    minWidth={minWidth}
    customStyle={css([style, customStyle])}
  >
    {children}
  </BaseButton>
);

export default ICCardConnectButton;
