/** @jsx jsx */
import React from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/core';

const SpecialHolidayGrantTable: React.FC<{
  specialHolidayGrantList: any
}> = ({
  specialHolidayGrantList,
}) => (
  <div className="table-responsive">
    <div
      css={css`
      table thead tr th{
        height: 46px;
      }
      table tbody tr td{
        height: 32px;
      }
      `}
    >
    <table id="specialHolidayGrantList" className="table table-bordered table-condensed table-no-border-left-right" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
      <thead>
        <tr role="row">
          <th style={{ width: '9%' }}>休暇名</th>
          <th style={{ width: '9%' }}>付与数</th>
          <th style={{ width: '9%' }}>消化数</th>
          <th style={{ width: '9%' }}>残日数</th>
          <th style={{ width: '9%' }}>有効期間(開始)</th>
          <th style={{ width: '9%' }}>有効期間(終了)</th>
          <th style={{ width: '9%' }}>次回付与日</th>
          <th style={{ width: '9%' }}>次回予定付与日数</th>
        </tr>
      </thead>
      <tbody>
        {
        specialHolidayGrantList && specialHolidayGrantList.map((specialHoliday: any, index: any) => (
          <tr key={String(index)} role="row">
            <td>{specialHoliday.holidayName}</td>
            {
              specialHoliday.effectiveStartDate === null && <td>-</td>
            }
            {
              specialHoliday.effectiveStartDate !== null && (
                <td>
                  {specialHoliday.grantDaysNumSum}
                  日
                </td>
              )
            }
            <td>
              {specialHoliday.digestedDaysNum}
              日
            </td>
            <td>
              {specialHoliday.remainDaysNum}
              日
            </td>
            <td>{specialHoliday.useStartDate == null ? '-' : moment(specialHoliday.useStartDate).format('YYYY年MM月DD日')}</td>
            <td>{specialHoliday.useEndDate == null ? '-' : moment(specialHoliday.useEndDate).format('YYYY年MM月DD日')}</td>
            <td>{specialHoliday.nextGrantDate == null ? '' : moment(specialHoliday.nextGrantDate).format('YYYY年MM月DD日')}</td>
            <td>
              {specialHoliday.nextGrantDays}
              日
            </td>
          </tr>
        ))
      }
      </tbody>
    </table>
    </div>
  </div>
);
export default SpecialHolidayGrantTable;
