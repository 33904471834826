import moment from 'moment';

export const setInitClosingDate = (dateClosing: number, targetMonth: any) => {
    const currentDay = Number(moment(targetMonth).format("DD"));
    const closingDate = Number(dateClosing);
    let firstDayStr: any;
    let lastDayStr: any;
    const searchTargetDateFrom = sessionStorage.getItem('headquartersFinal.monthlyList.searchTargetDateFrom') || '';
    const searchTargetDateTo = sessionStorage.getItem('headquartersFinal.monthlyList.searchTargetDateTo') || '';
    if (searchTargetDateFrom && searchTargetDateTo) {
      firstDayStr = searchTargetDateFrom;
      lastDayStr = searchTargetDateTo;
      sessionStorage.setItem('applicationTargetDayFrom', firstDayStr);
      sessionStorage.setItem('applicationTargetDayTo', lastDayStr);
    } else {
      if (closingDate === 0) {
        firstDayStr = moment(targetMonth).startOf('month').format('YYYY-MM-DD');
        lastDayStr = moment(targetMonth).endOf('month').format('YYYY-MM-DD');
      } else if (currentDay > closingDate) {
        targetMonth.setDate(closingDate);
        firstDayStr = moment(targetMonth).add(1, 'day').format('YYYY-MM-DD');
        lastDayStr = moment(targetMonth).add(1, 'month').format('YYYY-MM-DD');
      } else {
        targetMonth.setDate(closingDate);
        firstDayStr = moment(targetMonth).subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD');
        lastDayStr = moment(targetMonth).format('YYYY-MM-DD');
      }
    }
  
    return {
      firstDayStr,
      lastDayStr
    }
  }