/** @jsx jsx */
import React, { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import useAttendCSVOutputForm from "./hooks";
import FormLabel from "components/atoms/Form/FormLabel";
import FormPadding from "components/atoms/Form/FormPadding";
import FormField from "components/atoms/Form/FormField";
import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { PrimaryButton } from "components/atoms/Button";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import Checkbox from "components/molecules/Checkbox";
import SalaryHistoryDownloadForm from "./SalaryHistoryDownloadForm";
import { getAchivementHistoryDownloadList } from "api/achievementConfirm";
import SalaryHistoryDownload from "domain/master/attend/salaryHistoryDownload";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";

type contextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const AttendCSVOutputFormContext = createContext<contextType>({} as contextType);

const AttendCSVOutputForm: React.FC = () => {
  const {
    isLoading,
    setIsLoading,
    orgTreesOptions,
    staffOptions,
    formik,
    employmentOptions,
    layoutIdOptions,
    underFlag,
    setUnderFlag,
    salaryHistoryDownloadList,
    setSalaryHistoryDownloadList,
    reloadHistory,
    setReloadHistory,
    isCancelRequest,
    setIsCancelRequest,
  } = useAttendCSVOutputForm();

  const { errorNotification } = useToastNotification();

  useEffect(() => {
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
    const params = {
      pageId: 3,
      loginStaffCode,
    };
    if (reloadHistory) {
      getAchivementHistoryDownloadList(params).then((response) => {
        setSalaryHistoryDownloadList(response.map((result: any) => new SalaryHistoryDownload(result)));
        setReloadHistory(false);
      });
    }
  }, [reloadHistory]);

  return (
    <AttendCSVOutputFormContext.Provider value={{ isLoading, setIsLoading }}>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle title="CSV出力" bold={true} />
          <FormPadding customStyle={css({ padding: "10px 45px 15px 35px" })}>
            <FlexBox>
              <FlexBoxItem basis="100px">
                <FormLabel label="期間" />
              </FlexBoxItem>
              <FlexBoxItem basis="150px">
                <RadioSelectForm
                  label=""
                  name="viewPeriod"
                  items={[
                    {
                      label: "月",
                      value: "monthly",
                    },
                    {
                      label: "日",
                      value: "daily",
                    },
                  ]}
                  value={formik.values.viewPeriod}
                  setValue={(e) => formik.setFieldValue("viewPeriod", e.target.value)}
                />
              </FlexBoxItem>
              {formik.values.viewPeriod === "monthly" && (
                <FlexBoxItem>
                  <FlexBox>
                    <CalendarMonth
                      date={formik.values.targetDateFrom}
                      setDate={(date: Date) => {
                        formik.setFieldValue("targetDateFrom", date || new Date());
                      }}
                    />
                    <span style={{ margin: "10px" }}>～</span>
                    <CalendarMonth
                      date={formik.values.targetDateTo}
                      setDate={(date: Date) => {
                        formik.setFieldValue("targetDateTo", date || new Date());
                      }}
                    />
                  </FlexBox>
                </FlexBoxItem>
              )}

              {formik.values.viewPeriod === "daily" && (
                <FlexBoxItem>
                  <CalendarRangeV2
                    startDate={formik.values.targetDateFrom}
                    onChangeStart={(date: Date) => {
                      formik.setFieldValue("targetDateFrom", date);
                    }}
                    endDate={formik.values.targetDateTo}
                    onChangeEnd={(date) => {
                      if (date && JSON.stringify(date) !== JSON.stringify(formik.values.targetDateTo)) {
                        formik.setFieldValue("targetDateTo", date);
                      }
                    }}
                    validateTime={(startDate, endDate) => {
                      let isValid = moment(endDate).diff(startDate, "year") < 1;
                      if (!isValid) {
                        errorNotification("対象期間の範囲は１年以内で入力してください");
                      }
                      return isValid;
                    }}
                  />
                </FlexBoxItem>
              )}
            </FlexBox>
            <FormField displayBlock={true}>
              <FlexBox>
                <SelectForm
                  label="組織"
                  name="orgCode"
                  value={formik.values.orgCode || ""}
                  setValue={(v) => {
                    formik.setFieldValue("orgCode", v);
                  }}
                  options={orgTreesOptions.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  placeholder=""
                  width="900px"
                  widthLabel="100px"
                  marginRight="30px"
                />
                <Checkbox
                  id={"underFlag"}
                  name={"underFlag"}
                  label={"配下指定"}
                  value={`underFlag`}
                  checked={Boolean(underFlag)}
                  onChange={() => {
                    setUnderFlag(underFlag === 1 ? 0 : 1);
                  }}
                />
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <FlexBox>
                <FlexBoxItem width="470px">
                  <SelectForm
                    label="雇用形態"
                    width="900px"
                    widthLabel="100px"
                    name="employmentId"
                    value={formik.values.employmentId || ""}
                    setValue={(v) => {
                      // sessionStorage.setItem('setEmploymentId', v);
                      // setEmploymentId(v);
                      formik.setFieldValue("employmentId", v);
                    }}
                    placeholder="雇用形態を選択してください。"
                    options={employmentOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <FlexBox>
                <FlexBoxItem width="470px">
                  <SelectForm
                    label="スタッフ"
                    width="900px"
                    widthLabel="100px"
                    name="staffCode"
                    value={formik.values.staffCode || ""}
                    setValue={(v) => {
                      formik.setFieldValue("staffCode", v);
                    }}
                    placeholder="スタッフを選択してください"
                    options={staffOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <FlexBox>
                <FlexBoxItem width="470px">
                  <SelectForm
                    label="出力レイアウト"
                    width="900px"
                    widthLabel="100px"
                    name="layoutId"
                    value={formik.values.layoutId || ""}
                    setValue={(v) => {
                      formik.setFieldValue("layoutId", v);
                    }}
                    placeholder="スタッフを選択してください"
                    options={layoutIdOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>
            <FormSubmitArea customStyle={css({ textAlign: "center", marginTop: "60px" })}>
              <PrimaryButton text={"出力"} onClick={formik.handleSubmit} />
            </FormSubmitArea>
          </FormPadding>
          <FlexBox customStyle={css({ width: "100%" })}>
            <SalaryHistoryDownloadForm
              salaryHistoryDownloadList={salaryHistoryDownloadList}
              setSalaryHistoryDownloadList={setSalaryHistoryDownloadList}
              setReloadHistory={setReloadHistory}
              pageId={3}
              isCancelRequest={isCancelRequest}
              setIsCancelRequest={setIsCancelRequest}
            />
          </FlexBox>
        </FormContents>
      </BlockUI>
    </AttendCSVOutputFormContext.Provider>
  );
};

export default AttendCSVOutputForm;
