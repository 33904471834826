import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getDigestionItemsForPaidHolidayStatus,
  downloadPaidHolidayStatusCsv,
  getPaidHolidayStatusDetail,
  saveUseItemForPaidHolidayStatus,
} from 'api/holidayManagement';
import PaidHolidayStatusDomain from 'domain/master/holidayManagement/paidHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export interface DetailItems {
  paidHolidayGrantId: string,
  staffCode: string,
  lastFirstGrantDaysNum: number,
  lastFirstGrantTimesNum: number,
  lastGrantDaysNum: number,
  lastGrantTimesNum: number,
  firstGrantDaysNum: number,
  firstGrantTimesNum: number,
  grantDaysNum: number,
  grantTimesNum: number,
  useLastFirstGrantFlg: boolean,
  useFirstGrantFlg: boolean,
  useCurrentGrantFlg: boolean,
  useLastGrantFlg: boolean,
}

export const useManagementInformationDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [paidHolidayStatusList, SetPaidHolidayStatusList] = useState<Array<PaidHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementPaid.managementInformation.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [employmentId, setOrgEmploymentId] = useSaveStateStorage("", "holidayManagementPaid.managementInformation.employmentId") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [extinctionDaysStart, setExtinctionDaysStart] = useSaveStateStorage("", "holidayManagementPaid.managementInformation.extinctionDaysStart") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [extinctionDaysEnd, setExtinctionDaysEnd] = useSaveStateStorage("", "holidayManagementPaid.managementInformation.extinctionDaysEnd") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [extinctionDaysStartTmp, setExtinctionDaysStartTmp] = useState('');
  const [extinctionDaysEndTmp, setExtinctionDaysEndTmp] = useState('');
  const [targetGrantTenDays, setTargetGrantTenDays] = useSaveStateStorage(false, "holidayManagementPaid.managementInformation.targetGrantTenDays") as [boolean|null, React.Dispatch<React.SetStateAction<boolean|null>>];
  const [untilNextGrantDate, setUntilNextGrantDate] = useState('');
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementPaid.managementInformation.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    paidHolidayGrantId: '',
    staffCode: '',
    lastFirstGrantDaysNum: 0,
    lastFirstGrantTimesNum: 0,
    lastGrantDaysNum: 4,
    lastGrantTimesNum: 3,
    firstGrantDaysNum: 0,
    firstGrantTimesNum: 0,
    grantDaysNum: 0,
    grantTimesNum: 0,
    useLastFirstGrantFlg: false,
    useFirstGrantFlg: false,
    useCurrentGrantFlg: false,
    useLastGrantFlg: false,
  });
  const { successNotification, errorNotification } = useToastNotification();

  const contextObject = useContext(HolidayManagementContext);

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      // const orgCodeList = orgTreesOptions.filter((orgOption: any) => orgOption.value !== '').map((value:any) =>  value.value ).join(",");
      const response = await getDigestionItemsForPaidHolidayStatus(
        orgCode !== 'all' ? orgCode : '',
        extinctionDaysStart || "",
        extinctionDaysEnd || "",
        employmentId || "",
        targetGrantTenDays || false,
        // untilNextGrantDate
        underFlag || 0,
        '',
      );
      contextObject.setIsLoading(false);
      SetPaidHolidayStatusList(response.map((result) => new PaidHolidayStatusDomain(result)));
    }
    
  }, [
    employmentId, 
    extinctionDaysEndTmp,
    // untilNextGrantDate, 
    extinctionDaysStartTmp,
    orgCode, targetGrantTenDays,
    underFlag
  ]);

  useEffect(() => {
    fetchData();
  }, [underFlag, fetchData, noManager, orgCode, employmentId, targetGrantTenDays]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callPaidHolidayStatusDetail = useCallback(async (staffCode: string, paidHolidayGrantId: string) => {
    try {
      await getPaidHolidayStatusDetail(staffCode, paidHolidayGrantId).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification]);

  //
  const callSaveUseItemForPaidHolidayStatus = useCallback(async () => {
    contextObject.setIsLoading(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const saveUser = sessionStorage.getItem('loginUser.staffCode') || '';
    const tmpDataPost = detailItems;
    (tmpDataPost as any).saveUser = saveUser;
    if (!(tmpDataPost as any).paidHolidayGrantId) {
      (tmpDataPost as any).paidHolidayGrantId = '';
    }
    try {
      await saveUseItemForPaidHolidayStatus(companyCode, tmpDataPost).then((response: any) => {
        setNoManager(!noManager);
        successNotification('更新しました。');
        setModalOpen(false);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    } finally {
      contextObject.setIsLoading(false);
    }
  }, [detailItems, errorNotification, noManager, successNotification]);

  return {
    paidHolidayStatusList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    extinctionDaysStart,
    setExtinctionDaysStart,
    extinctionDaysEnd,
    setExtinctionDaysEnd,
    targetGrantTenDays,
    setTargetGrantTenDays,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    setUntilNextGrantDate,
    untilNextGrantDate,
    extinctionDaysStartTmp,
    setExtinctionDaysStartTmp,
    extinctionDaysEndTmp,
    setExtinctionDaysEndTmp,
    detailRole: contextObject.detailRole,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagement = (
  orgCode: string,
  extinctionDaysStart: string,
  extinctionDaysEnd: string,
  employmentId: string,
  targetGrantTenDays: boolean,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadPaidHolidayStatus = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadPaidHolidayStatusCsv(
      isDownloadAll ? '' : orgCode,
      extinctionDaysStart,
      extinctionDaysEnd,
      employmentId,
      targetGrantTenDays,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [underFlag, employmentId, extinctionDaysEnd, extinctionDaysStart, orgCode, targetGrantTenDays]);

  return {
    downloadPaidHolidayStatus,
    blocking
  };
};

export default {
  useManagementInformationDomainForm,
};
