/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { grayScale } from 'components/styles';
import FullScreenOverlay from 'components/atoms/FullScreenOverlay';
import Panel from 'components/atoms/Panel';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';

const styles = {
  wrapper: css({
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  closeButton: css({
    marginLeft: 'auto',
  }),
  text: css({
    textAlign: 'center',
  }),
};

/**
 * Toast messages
 */
const ToastModal: React.FC<{
  open: boolean
  closeHandler: () => void
  title?: string
}> = ({
  open, closeHandler, title = '',
}) => (
  <FullScreenOverlay open={open}>
    <Panel>
      <div css={styles.wrapper}>
        <div>
          <p dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div css={styles.closeButton}>
          <ClickableIconButton onClick={closeHandler}>
            <Icon type="close" color={grayScale.gray100} />
          </ClickableIconButton>
        </div>
      </div>
    </Panel>
  </FullScreenOverlay>
);

export default ToastModal;
