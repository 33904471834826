
/**
 * API 勤怠再計算
 */
interface IDigestedDateItem {
  digestedDate: string;
  digestedNum: string;
}

export interface PaidHolidayDigestedtHistory {
    staffCode: string,
    staffName: string,
    orgCode: string,
    orgName: string,
    digestionDays: number,
    digestionTimes: number,
    fromDate: Date,
    toDate: Date,
    paidHolidayDigestionDetailList: IDigestedDateItem[],
    digestion: string,
    effectiveStartDate: Date
}


export default class PaidHolidayDigestedtHistoryDomain {
  constructor(private rawData: PaidHolidayDigestedtHistory) {
    // do nothing
  }

  static generateInitial(): PaidHolidayDigestedtHistoryDomain {
    return new PaidHolidayDigestedtHistoryDomain({
      staffCode: '',
      staffName: '',
      orgCode: '',
      orgName: '',
      digestionDays: 0,
      digestionTimes: 0,
      fromDate: new Date(),
      toDate: new Date(),
      paidHolidayDigestionDetailList: [],
      digestion: '',
      effectiveStartDate: new Date(),
    });
  }

  getRawData(): PaidHolidayDigestedtHistory {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get digestion(): string {
    return this.rawData.digestion;
  }

  set digestion(digestion: string) {
    this.rawData.digestion = digestion;
  }

  get effectiveStartDate(): Date {
    return this.rawData.effectiveStartDate;
  }

  set effectiveStartDate(effectiveStartDate: Date) {
    this.rawData.effectiveStartDate = effectiveStartDate;
  }

  get paidHolidayDigestionDetailList(): IDigestedDateItem[] {
    return this.rawData.paidHolidayDigestionDetailList;
  }

  set paidHolidayDigestionDetailList(paidHolidayDigestionDetailList: IDigestedDateItem[]) {
    this.rawData.paidHolidayDigestionDetailList = paidHolidayDigestionDetailList;
  }
}
