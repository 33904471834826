/** @jsx jsx */
import React from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/core';

const SubstituteHolidayTable: React.FC<{
substituteHolidayUsingStatusList: any
}> = ({
  substituteHolidayUsingStatusList,
}) => (
  <div className="table-responsive">
    <div
      css={css`
      table thead tr th{
        height: 46px;
      }
      table tbody tr td{
        height: 32px;
      }
      `}
    >
    <table id="substituteHolidayList" className="table table-bordered table-condensed table-no-border-left-right" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
      <thead>
        <tr role="row">
          <th style={{ width: '9%' }}>休暇名</th>
          <th style={{ width: '9%' }}>状態</th>
          <th style={{ width: '9%' }}>休日出勤日</th>
          <th style={{ width: '9%' }}>付与日数</th>
          <th style={{ width: '9%' }}>代休取得日</th>
          <th style={{ width: '9%' }}>残日数</th>
          <th style={{ width: '9%' }}>消滅日(消滅予定日)</th>
        </tr>
      </thead>
      <tbody>
        {
        substituteHolidayUsingStatusList && substituteHolidayUsingStatusList.map((substitute: any, index: any) => (
          <tr key={String(index)} role="row">
            <td>{substitute.holidayName}</td>
            <td>{substitute.status}</td>
            <td>{substitute.holidayWorkDate === '' ? '' : moment(substitute.holidayWorkDate).format('YYYY年MM月DD日')}</td>
            <td>{substitute.grantDaysNumStr}</td>
            <td>
              {substitute.substituteHolidayDate === '' ? '' : moment(substitute.substituteHolidayDate).format('YYYY年MM月DD日')}
              {substitute.substituteHolidayDate2 === null || substitute.substituteHolidayDate2 === '' ? '' : <br></br>}
              {substitute.substituteHolidayDate2 === null || substitute.substituteHolidayDate2 === '' ? '' : moment(substitute.substituteHolidayDate2).format('YYYY年MM月DD日')}
            </td>
            <td>{substitute.remainDaysNumStr}</td>
            <td>{substitute.disappearanceDate === '' ? '' : moment(substitute.disappearanceDate).format('YYYY年MM月DD日')}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
    </div>
  </div>
);
export default SubstituteHolidayTable;
