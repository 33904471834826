import React from 'react';

import productColor from 'components/styles';
import CircleButton from 'components/atoms/CircleButton';
import Icon, { IconType } from 'components/atoms/Icon';

interface CircleIconButtonProps {
  onClick: () => void;
  iconType: IconType;
  iconColor?: string;
  iconSize?: string;
  color?: string;
  borderColor?: string;
}

const CircleIconButton: React.FC<CircleIconButtonProps> = ({
  onClick, iconType, iconSize, iconColor = productColor.primary,
  color = productColor.primaryM95, borderColor = productColor.primaryM80,
}) => (
  <CircleButton onClick={onClick} color={color} borderColor={borderColor}>
    <Icon type={iconType} color={iconColor} size={iconSize}/>
  </CircleButton>
);

export default CircleIconButton;
