/** @jsx jsx */
import React from "react";

import FormLabel from "components/atoms/Form/FormLabel";
import TextInput from "components/atoms/Form/TextInput";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import { css, jsx, SerializedStyles } from "@emotion/core";
import productColor, { grayScale, textFontSize } from "components/styles";
import FlexBox from "components/atoms/FlexBox";
import IconTooltip from "./IconTooltip";

const styles = {
  wrapper: css({
    display: "flex",
  }),
  input: css({
    flex: 1,
    width: "32px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    border: `1px solid ${grayScale.gray20}`,
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    border: `1px solid ${grayScale.gray20}`,
    borderRadius: "0 4px 4px 0",
  }),
};

const TextForm: React.FC<{
  name: string;
  label: string;
  value: string;
  note?: string;
  type?: string;
  placeHolder?: string;
  groupText?: string;
  required?: boolean;
  showRequiredLabel?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  errorMsg?: string;
  disable?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  appendLabel?: string;
  toolTip?: string;
  customIconHelp?: any;
  customStyle?: SerializedStyles;
  customStyleLabel?: SerializedStyles;
  inputCenter?: boolean;
}> = ({
  name,
  label,
  type,
  value,
  onChange,
  onBlur,
  onKeyDown,
  errorMsg,
  placeHolder,
  groupText,
  required,
  disable,
  min,
  max,
  appendLabel,
  toolTip,
  maxLength,
  showRequiredLabel = true,
  customIconHelp,
  customStyle = css({}),
  customStyleLabel = css({}),
  inputCenter = false,
}) => (
  <VerticalLabelFormLayout
    label={
      <div style={label === "" ? { display: "none" } : {}}>
        <FlexBox>
          <FormLabel
            label={label}
            required={required}
            appendLabel={appendLabel}
            showRequiredLabel={showRequiredLabel}
            customStyle={customStyleLabel}
          />
          {toolTip && (
            <div css={customIconHelp}>
              <IconTooltip iconType="help" text={toolTip} color="#000" />
            </div>
          )}
        </FlexBox>
      </div>
    }
    input={
      <div css={styles.wrapper}>
        <TextInput
          min={min}
          max={max}
          maxLength={maxLength}
          name={name}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeHolder={placeHolder}
          type={type}
          disable={disable}
          customStyle={disable ? css({ backgroundColor: grayScale.gray05 }) : css({}, customStyle)}
        />

        {groupText && <div css={styles.addon}>{groupText}</div>}
      </div>
    }
    errorMsg={errorMsg}
    inputCenter={inputCenter}
  />
);

export default TextForm;
