import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AreasAddForm from 'components/organismos/master/sales/AreasAddForm';
import { useParams } from 'react-router-dom';

const AreasAddPage: React.FC = () => {
  const { areaId } = useParams();
  return (
    <SidebarTemplate pageTitle={areaId ? 'エリア編集' : 'エリア作成'}>
      <AreasAddForm />
    </SidebarTemplate>
  );
};

export default AreasAddPage;
