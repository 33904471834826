/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const MAX_VALUE = 2099;
const MIN_VALUE = 1900;


const validateYear = (year) => { 
  return   Number(year) >= MIN_VALUE 
        && Number(year) <= MAX_VALUE
}

function Year({ year, selectedYear, choiseYear }) {
  const selectedYearClass = year === selectedYear ? 'selected-year' : '';

  function clickHandler(event) {
    choiseYear(year);
  }

  return (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div className={`year-wrapper ${selectedYearClass} ${validateYear(year) ? '' : 'out-side-year'}`} year={year}>
      <a onClick={(e) => clickHandler(e)} className={`year`}>
        {year}
      </a>
    </div>
  );
}

export default Year;
