/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

// import { textFontSize } from 'components/styles';
import Table from 'components/molecules/Table';

// const styles = {
//   wrapper: css({
//     display: 'block',
//   }),
//   container: css({
//     paddingInlineStart: 0,
//     listStyle: 'none',
//     columnCount: 2,
//   }),
//   title: css({
//     fontSize: textFontSize.md,
//     lineHeight: '19px',
//     display: 'flex',
//     alignItems: 'center',
//   }),
//   line: css({
//     marginBottom: '12px',
//   }),
//   value: css({
//     marginRight: '6px',
//     fontSize: textFontSize.re,
//   }),
//   label: css({
//     marginLeft: '6px',
//     fontSize: textFontSize.re,
//   }),
// };

interface ListItems {
  label: string;
  value: string;
}

const ListVerticalSeperator: React.FC<{
  title?: string,
  items: Array<ListItems>;
}> = ({ title, items }) => (
  // <div css={styles.wrapper}>
  //   <h2 css={styles.title}>{title}</h2>
  //   <ul css={styles.container}>
  //     {items.map((item) => (
  //       <li css={styles.line} key={item.value}>
  //         <span css={styles.value}>
  //           {item.value}
  //         </span>
  //         <span css={styles.label}>
  //           {item.label}
  //         </span>
  //       </li>
  //     ))}
  //   </ul>
  // </div>

  <div
    css={css`
    table th, table td{
      text-align: left
    }
    table th:nth-of-type(1){
      width: 300px;
    }
    table td:nth-of-type(1){
      width: 300px
    }
    tbody {
      display:block;
      height:500px;
      overflow:auto;
    }
    thead tr, tbody tr {
      display:table;
      width:100%;
    }
    `}
  >
  <Table className="table-no-border-left-right">
    <thead>
      <tr>
        <Table.HeaderCell>
          日付
        </Table.HeaderCell>
        <Table.HeaderCell>
          祝日名
        </Table.HeaderCell>
      </tr>
    </thead>
    <tbody>
      {items.map((item) => (
        <tr>
          <Table.Cell>
            {item.value}
          </Table.Cell>
          <Table.Cell>
            {item.label}
          </Table.Cell>
        </tr>
      ))}
    </tbody>
    </Table>
  </div>
);

export default ListVerticalSeperator;
