import { useState, useEffect } from "react";

import { getRoundShiftPatterns } from "components/pages/master/shift/api";
import { ShiftPattern } from "components/pages/master/shift/type";

export const useRoundShiftPatternOptions = (orgCode: string, employmentId: string | null, targetDate: string) => {
  const [roundSiftPatternOptions, setRoundSiftPatternOptions] = useState<Array<ShiftPattern>>([]);
  useEffect(() => {
    if (!employmentId) {
      return;
    }

    getRoundShiftPatterns(orgCode, employmentId, targetDate).then((shiftPatterns) => {
      setRoundSiftPatternOptions(shiftPatterns);
    });
  }, [orgCode, targetDate, employmentId]);
  return roundSiftPatternOptions;
};
