/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const FlexBoxItem: React.FC<{
  grow?: number
  shrink?: number
  basis?: string
  marginLeft?: string
  marginRight?: string
  width? : string
  margin?: string
  flex?: string
  alignSelf?: string
  height?: string
  maxWidth?: string
  textAlign?: any
  customStyle?: SerializedStyles
}> = ({
  children, grow = 0, shrink = 1, basis = 'auto',
  marginLeft, marginRight, width, margin, flex = 'left',
  alignSelf = 'auto', height = 'auto', maxWidth = '', textAlign = '', customStyle = css({})
}) => (
  <div
    css={[css({
      flexGrow: grow,
      flexShrink: shrink,
      flexBasis: basis,
      marginLeft,
      width,
      marginRight,
      margin,
      flex,
      alignSelf,
      height,
      maxWidth,
      textAlign,
    }), customStyle]}
  >
    {children}
  </div>
);

export default FlexBoxItem;
