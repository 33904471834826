import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import Panel from 'components/atoms/Panel';
import ShiftCalendarToolbar from 'components/organismos/ShiftCalendarToolbar';
import ShiftMonthCalendar from 'components/organismos/ShiftMonthCalendar';
import ShiftDayCalendar from 'components/organismos/ShiftDayCalendar';
import ShiftCostInfo from 'components/organismos/ShiftCostInfo';
import ShiftConfirmModal from 'components/organismos/ShiftConfirmModal';

import { useShiftPage } from './hooks';

// コンテンツの最小幅
const contentsMinWidth = 800;

// シフトコスト情報(右側サイドバー)の幅
const shiftCostInfoWidth = 250;

const ShiftPage: React.FC = () => {
  const {
    state, setter, actions,
  } = useShiftPage();
  return (
    <SidebarTemplate
      pageTitle="シフト管理"
    >
      <Container fluid={true}>
        <Row style={{ overflowX: 'scroll', flexWrap: 'nowrap' }}>
          <Col
            style={{
              minWidth: contentsMinWidth,
              marginTop: 20,
              padding: '0 16px',
              boxSizing: 'border-box',
            }}
          >
            <Panel padding="8px 20px" height="500px">
              <ShiftCalendarToolbar
                date={state.date}
                setDate={setter.setDate}
                view={state.view}
                setView={setter.setView}
                orgOptions={state.orgOptions}
                orgCode={state.orgCode}
                setOrgCode={setter.setOrgCode}
                businessMasterOptions={state.businessMasterOptions}
                businessIds={state.businessIds}
                setBusinessIds={setter.setBusinessIds}
              />

              {/* 月表示 */}
              {state.view === 'month' && (
              <ShiftMonthCalendar
                orgCode={state.orgCode}
                date={state.date}
                setDate={setter.setDate}
                setView={setter.setView}
              />
              )}

              {/* 日表示 */}
              {state.view === 'day' && (
              <ShiftDayCalendar
                orgCode={state.orgCode}
                date={state.date}
                dispStartHour={state.dispStartHour}
                dispHour={state.dispHour}
                businessIds={state.businessIds}
              />
              )}
            </Panel>
          </Col>

          <Col
            width={shiftCostInfoWidth}
            style={{ marginTop: 20 }}
          >
            <ShiftCostInfo
              shiftSubmit={actions.onClickShiftConfirm}
              cost={state.shiftCostRate}
              shiftHopeRates={state.shiftHopeRates}
              width={shiftCostInfoWidth}
            />
          </Col>
        </Row>
      </Container>

      <ShiftConfirmModal
        open={state.openShiftConfirmModal}
        closehandler={() => {
          setter.setShiftConfirmModalFlag(false);
        }}
        shiftSubmit={actions.shiftSubmit}
        dayFrom={state.dayFrom}
        dayTo={state.dayTo}
        setDayTo={setter.setDayTo}
      />
    </SidebarTemplate>
  );
};

export default ShiftPage;
