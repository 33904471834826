/* eslint-disable no-nested-ternary */
import {
  createContext, useEffect, useState, useCallback, useContext, useMemo,
} from 'react';
import moment from 'moment';

import {
  getDaily, postShift, getShift, postShiftMove, deleteShift, getDailyAddStaffs,
  ShiftDetail, Shift, ShiftMovePostData, StaffDailyShift,
  ShiftPostData, ShiftDeleteQueryParameter, ShiftDailyStaff,
} from 'api/shift';
import useToastNotification from 'hooks/useToastNotification';
import { useEmploymentShiftPatternOptions } from 'hooks/useShiftPatternOptions';
import { OptionType } from 'components/atoms/Select';
import { convertDateStringToMoment, convertMomentToYYYYMMDD } from 'utility/dateUtil';
import { useAttendTypeOptions } from 'hooks/useAttendTypeOptions';

const quarterHourPixel = 12;

const getDefaultShiftDetailList = (date: Date): Array<ShiftDetail> => ([{
  businessId: '',
  startTime: moment(date).hour(0).minutes(0).second(0).format('HH:mm:ss'),
  endTime: moment(date).hour(0).minutes(0).second(0).format('HH:mm:ss'),
  isStartTimeNextDay: false,
  isEndTimeNextDay: false,
  createUser: '',
  updateUser: '',
}]);

type StaffInfo = {
  staffName: string;
  staffCode: string;
  employmentId: string;
  belongOrgCode: string;
}

type ShiftAddPanelState = {
  isShow: boolean;
  defaultStartDate: Date;
  staff?: StaffInfo;
}

type ShiftEditPanelState = {
  isShow: boolean;
  shiftId: string;
  employmentId: string;
}

type ShiftDayCalendarContext = {
  /**
   * 15分あたりのピクセル数
   */
  quarterHourPixel: number;
  /**
   * ローディング中のスタッフコード
   */
  loadingStaffCodes: Array<string>;
  loadingStaffStart: (staffCode: string) => void;
  loadingStaffEnd: (staffCode: string) => void;
  /**
   * 対象日
   */
  date: Date;
  /**
   * 組織コード
   */
  orgCode: string;
  /**
   * 時間範囲の開始
   */
  dispStartHour: number;
  /**
   * 時間範囲
   */
  dispHour: number;
  /**
   * シフトデータ
   */
  staffDailyShifts: Array<StaffDailyShift>;
  /**
   * シフトデータの取得
   */
  fetchStaffDailyShifts: () => void;
  /**
   * シフトデータの更新
   */
  setStaffDailyShift: (arg: StaffDailyShift) => void;
  /**
   * 対象スタッフのシフトデータを削除
   */
  deleteStaffDailyShift: (staffCode: string) => void;
  /**
   * シフト追加パネルの状態
   */
  shiftAddPanelState: ShiftAddPanelState;
  /**
   * シフト追加パネルを表示
   */
  showShiftAddPanelAction: (
    hour: number,
    minutes: number,
    staff?: StaffInfo,
  ) => void;
  /**
   * シフト追加パネルを隠す
   */
  hideShiftAddPanelAction: () => void;
  /**
   * シフト編集パネルの状態
   */
  shiftEditPanelState: ShiftEditPanelState;
  /**
   * シフト編集パネルを表示
   */
  showShiftEditPanelAction: (
    shiftId: string,
      employmentId: string,
  ) => void;
  /**
   * シフト編集パネルを隠す
   */
  hideShiftEditPanelAction: () => void;
}

export const shiftDayCalendarContext = createContext<ShiftDayCalendarContext>(
  {} as ShiftDayCalendarContext,
);

/**
 * シフト 日表示カレンダーのContextを取得
 * (子コンポーネントで使用する)
 */
export const useShiftDayCalendarContext = (): ShiftDayCalendarContext => {
  const context = useContext(shiftDayCalendarContext);
  return context;
};

/**
 * シフト 日表示カレンダーのContextの初期値を取得
 *
 * @param orgCode 組織コード
 * @param date 対象日付
 * @param businessIds 表示中の業務ID一覧
 */
export const useInitialShiftDay = (
  orgCode: string,
  date: Date,
  dispStartHour: number,
  dispHour: number,
  businessIds: Array<string>,
): ShiftDayCalendarContext => {
  const [loadingStaffCodes, setLoadingStaffCodes] = useState<Array<string>>([]);
  const loadingStaffStart = useCallback((staffCode: string) => {
    setLoadingStaffCodes(loadingStaffCodes.concat([staffCode]));
  }, [loadingStaffCodes]);
  const loadingStaffEnd = useCallback((staffCode: string) => {
    setLoadingStaffCodes(loadingStaffCodes.filter((loadingStaffCode) => loadingStaffCode !== staffCode));
  }, [loadingStaffCodes]);

  const [staffDailyShifts, setStaffDailyShifts] = useState<Array<StaffDailyShift>>([]);

  // シフト追加パネルの状態
  const [shiftAddPanelState, setShiftAddPanelState] = useState<ShiftAddPanelState>({
    isShow: false,
    defaultStartDate: date,
  });

  // シフト編集パネルの状態
  const [shiftEditPanelState, setShiftEditPanelState] = useState<ShiftEditPanelState>({
    isShow: false,
    shiftId: '',
    employmentId: '',
  });

  // シフトデータの取得
  const fetchStaffDailyShifts = useCallback(async () => {
    if (!orgCode) {
      return;
    }
    const targetDay = moment(date).format('YYYY-MM-DD');
    setStaffDailyShifts(await getDaily(orgCode, targetDay, businessIds.join()));
  }, [businessIds, date, orgCode]);

  // シフトデータの更新
  const setStaffDailyShift = useCallback((staffDailyShift: StaffDailyShift) => {
    setStaffDailyShifts(staffDailyShifts.map((currentStaffDailyShift) => {
      if (staffDailyShift.staffCode === currentStaffDailyShift.staffCode) {
        return staffDailyShift;
      }
      return currentStaffDailyShift;
    }));
  }, [staffDailyShifts]);

  // シフトデータの更新
  const deleteStaffDailyShift = useCallback((staffCode: string) => {
    setStaffDailyShifts(staffDailyShifts.filter((currentStaffDailyShift) => {
      if (staffCode === currentStaffDailyShift.staffCode) {
        return false;
      }
      return true;
    }));
  }, [staffDailyShifts]);

  useEffect(() => {
    fetchStaffDailyShifts();
  }, [orgCode, date, businessIds, fetchStaffDailyShifts]);

  // 組織コード、日付、業務が変更されたらパネルを閉じる
  useEffect(() => {
    setShiftAddPanelState({
      isShow: false,
      defaultStartDate: date,
    });
  }, [orgCode, date, businessIds]);

  // シフト追加パネル表示
  const showShiftAddPanelAction = useCallback((
    hour: number,
    minute: number,
    staff?: StaffInfo,
  ) => {
    setShiftAddPanelState({
      isShow: true,
      defaultStartDate: moment(date).hour(hour).minute(minute).second(0).toDate(),
      staff,
    });
  }, [date]);

  // シフト編集パネル表示
  const showShiftEditPanelAction = useCallback((
    shiftId: string,
    employmentId: string,
  ) => {
    setShiftEditPanelState({
      isShow: true,
      shiftId,
      employmentId,
    });
  }, []);

  const hideShiftAddPanelAction = useCallback(() => {
    setShiftAddPanelState({
      ...shiftAddPanelState,
      isShow: false,
    });
  }, [shiftAddPanelState]);

  const hideShiftEditPanelAction = useCallback(() => {
    setShiftEditPanelState({
      isShow: false,
      shiftId: '',
      employmentId: '',
    });
  }, []);

  return {
    quarterHourPixel,
    loadingStaffCodes,
    loadingStaffStart,
    loadingStaffEnd,
    orgCode,
    date,
    dispStartHour,
    dispHour,
    staffDailyShifts,
    fetchStaffDailyShifts,
    setStaffDailyShift,
    deleteStaffDailyShift,
    shiftAddPanelState,
    showShiftAddPanelAction,
    hideShiftAddPanelAction,
    shiftEditPanelState,
    showShiftEditPanelAction,
    hideShiftEditPanelAction,
  };
};

/**
 * for ShiftAddPanel hooks
 */
export const useShiftAddPanel = () => {
  const {
    date, orgCode, shiftAddPanelState,
  } = useShiftDayCalendarContext();

  const shiftAddAction = useShiftAddAction();

  // 編集項目
  const [targetOrgCode, setTargetOrgCode] = useState(orgCode);
  const [businessId, setBusinessId] = useState('');
  const [staffCode, setStaffCode] = useState<string>('');
  const [attendType, setAttendType] = useState<string>('0');
  const [shiftPatternId, setShiftPatternId] = useState<string>('');
  const [shiftDetailList, setShiftDetailList] = useState<Array<ShiftDetail>>([]);

  // スタッフ一覧
  const [staffList, setStaffList] = useState<Array<ShiftDailyStaff>>();
  const [shiftDailyStaffOptions, setShiftDailyStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getDailyAddStaffs(orgCode, targetOrgCode, moment(date).format('YYYY-MM-DD')).then((dailyAddStaffs) => {
      setStaffList(dailyAddStaffs);
      setShiftDailyStaffOptions(dailyAddStaffs.map((staff) => ({
        value: staff.staffCode,
        label: staff.staffName,
      })));
    });
  }, [date, orgCode, targetOrgCode]);
  // シフトパターン取得のためにemploymentId, belongOrgCodeを保存しておく
  const employmentId = useMemo(() => staffList?.find((staff) => staff.staffCode === staffCode)?.employmentId || '', [staffCode, staffList]);
  const belongOrgCode = useMemo(() => staffList?.find((staff) => staff.staffCode === staffCode)?.belongOrgCode || '', [staffCode, staffList]);
  const distinctionHoliday = useMemo(() => staffList?.find((staff) => staff.staffCode === staffCode)?.distinctionHoliday || '', [staffCode, staffList]);

  const closingHour = useMemo(
    () => staffList?.find((staff) => staff.staffCode === staffCode)?.closingHour || 0,
    [staffCode, staffList],
  );

  // 勤務日種別
  const attendTypeOptions = useAttendTypeOptions(!!distinctionHoliday);
  const isPublicHoliday = useMemo(() => attendType !== '0', [attendType]);

  // シフトパターン
  // console.log(`shiftAddPanelState.staff?.employmentId = ${shiftAddPanelState.staff?.employmentId}`);
  // console.log(`employmentId = ${employmentId}`);
  // console.log(`belongOrgCode = ${belongOrgCode}`);
  const { shiftPatterns, shiftPatternOptions } = useEmploymentShiftPatternOptions(
    employmentId || shiftAddPanelState.staff?.employmentId || '',
    belongOrgCode || shiftAddPanelState.staff?.belongOrgCode || '',
  );
  // console.log(`shiftPatterns.length = ${shiftPatterns.length}`);
  // シフトパターンが選択されたらシフト時間を設定する
  useEffect(() => {
    if (isPublicHoliday) {
      // 勤務日種別が公休だったらシフトパターン、シフト時間をリセット
      setShiftPatternId('');
      setShiftDetailList(getDefaultShiftDetailList(date));
      return;
    }
    if (!shiftPatternId) {
      return;
    }
    setShiftDetailList(
      shiftPatterns.find(
        (shiftPattern) => shiftPattern.shiftPatternId === shiftPatternId,
      )?.attendShiftPatternDetails || [],
    );
  }, [date, isPublicHoliday, shiftPatternId, shiftPatterns]);

  const addShiftAction = useCallback(() => {
    if (!shiftAddPanelState.staff?.staffCode && !staffCode) {
      return;
    }
    shiftAddAction(
      shiftAddPanelState.staff?.staffCode || staffCode,
      closingHour,
      Number(attendType),
      shiftPatternId,
      shiftDetailList,
    );
  }, [
    shiftAddPanelState.staff,
    staffCode,
    shiftAddAction,
    closingHour,
    attendType,
    shiftPatternId,
    shiftDetailList,
  ]);

  return {
    state: {
      targetOrgCode,
      shiftDetailsList: shiftDetailList,
      businessId,
      staffCode,
      employmentId,
      attendType,
      isPublicHoliday,
      shiftPatternId,
    },
    options: {
      shiftDailyStaffOptions,
      shiftPatternOptions,
      attendTypeOptions,
    },
    setter: {
      setTargetOrgCode,
      setBusinessId,
      setStaffCode,
      setAttendType,
      setShiftPatternId,
      setShiftDetailsList: setShiftDetailList,
    },
    actions: {
      addShiftAction,
    },
  };
};

/**
 * シフト編集パネルのhooks
 */
export const useShiftEditPanel = (shiftId: string) => {
  const { date, shiftEditPanelState } = useShiftDayCalendarContext();

  // APIリクエスト用に保存しておく
  const [shift, setShift] = useState<Shift|undefined>(undefined);

  // 削除確認の表示
  const [showDeleteConfirm, setDeleteConfirmShowFlag] = useState(false);

  // 編集項目
  const [attendType, setAttendType] = useState<string>('');
  const [shiftDetailList, setShiftDetailList] = useState<Array<ShiftDetail>>([]);

  // 公休かどうか
  const isPublicHoliday = useMemo(() => attendType !== '0', [attendType]);

  // シフトパターン
  const [shiftPatternId, setShiftPatternId] = useState<string>('');
  const {
    shiftPatterns, shiftPatternOptions,
  } = useEmploymentShiftPatternOptions(shiftEditPanelState.employmentId || '', shift?.belongOrgCode || '');

  const shiftEditAction = useShiftEditAction();
  const shiftDeleteAction = useShiftDeleteAction();

  useEffect(() => {
    getShift(shiftId).then((res) => {
      setShift(res);

      // holidayIdがあれば公休
      // isLeagal:trueであれば公休（法定）
      let fetchAttendType = res.attendType;
      if (res.holidayId) {
        fetchAttendType = res.isLegal ? 1 : 2;
      }

      setAttendType(String(fetchAttendType));
      setShiftPatternId(res.shiftPatternId || '');
      setShiftDetailList(res.shiftDetailList.map((shiftDetail) => ({
        ...shiftDetail,
        startTime: moment(shiftDetail.startTime).format('HH:mm:ss'),
        endTime: moment(shiftDetail.endTime).format('HH:mm:ss'),
      })));
    });
  }, [shiftId]);

  // 勤務日種別
  const attendTypeOptions = useAttendTypeOptions(!!shift?.distinctionHoliday);

  // シフトパターンが選択されたらシフト時間を設定する
  useEffect(() => {
    if (isPublicHoliday) {
      // 勤務日種別が公休だったらシフトパターン、シフト時間をリセット
      setShiftPatternId('');
      setShiftDetailList(getDefaultShiftDetailList(date));
      return;
    }
    if (!shiftPatternId) {
      return;
    }
    setShiftDetailList(
      shiftPatterns.find(
        (shiftPattern) => shiftPattern.shiftPatternId === shiftPatternId,
      )?.attendShiftPatternDetails || [],
    );
  }, [date, isPublicHoliday, shiftPatternId, shiftPatterns]);

  const editShiftAction = useCallback(() => {
    if (!shift) {
      return;
    }
    shiftEditAction(
      shift.staffCode,
      shift.shiftId,
      shiftPatternId,
      shift.closingHour,
      Number(attendType),
      shiftDetailList,
    );
  }, [attendType, shift, shiftDetailList, shiftEditAction, shiftPatternId]);

  const deleteShiftAction = useCallback(() => {
    if (!shift) {
      return;
    }
    shiftDeleteAction(
      shift.staffCode,
      shift.shiftId,
    );
  }, [shift, shiftDeleteAction]);

  return {
    state: {
      targetDate: shift ? moment(shift.targetDate).format('YYYY/MM/DD') : '',
      staffCode: shift?.staffCode,
      staffName: shift?.staffName,
      attendType,
      isPublicHoliday,
      orgName: shift?.orgName,
      employmentId: '',
      shiftPatternId,
      shiftDetailList,
      showDeleteConfirm,
    },
    setter: {
      setAttendType,
      setShiftPatternId,
      setShiftDetailList,
      setDeleteModalShowFlag: setDeleteConfirmShowFlag,
    },
    actions: {
      editShiftAction,
      deleteShiftAction,
    },
    options: {
      shiftPatternOptions,
      attendTypeOptions,
    },
  };
};

/**
 * シフト一覧の中から開始、終了時間を決定する
 */
const getStartAndEndTime = (targetDate: Date, shiftDailyList: Array<ShiftDetail>) => {
  if (shiftDailyList.length === 0) {
    return {};
  }
  // 対象日付の時刻に変換
  const convertTimeToMoment = (dateStr: string, nextDay: boolean) => {
    let timeMoment = convertDateStringToMoment(dateStr);
    if (nextDay) {
      timeMoment = timeMoment.add(1, 'days');
    }
    console.log(`timeMoment = ${timeMoment}`);
    return moment(timeMoment).hour(timeMoment.hour()).minutes(timeMoment.minutes()).second(0);
  };
  let firstStartTime = convertTimeToMoment(
    moment(targetDate).format('YYYY/MM/DD') + ' ' + shiftDailyList[0].startTime, shiftDailyList[0].isStartTimeNextDay);
  let latestEndTime = convertTimeToMoment(
    moment(targetDate).format('YYYY/MM/DD') + ' ' + shiftDailyList[0].endTime, shiftDailyList[0].isEndTimeNextDay);
  
  shiftDailyList.forEach((shiftDaily) => {
    if (firstStartTime.diff(
      convertTimeToMoment(moment(targetDate).format('YYYY/MM/DD') + ' ' + shiftDaily.startTime, shiftDaily.isStartTimeNextDay), 'minutes') > 0
    ) {
      firstStartTime = convertTimeToMoment(moment(targetDate).format('YYYY/MM/DD') + ' ' + shiftDaily.startTime, shiftDaily.isEndTimeNextDay);
    }
    if (latestEndTime.diff(
      convertTimeToMoment(moment(targetDate).format('YYYY/MM/DD') + ' ' + shiftDaily.endTime, shiftDaily.isEndTimeNextDay), 'minutes') < 0
    ) {
      latestEndTime = convertTimeToMoment(moment(targetDate).format('YYYY/MM/DD') + ' ' + shiftDaily.endTime, shiftDaily.isEndTimeNextDay);
    }
  });
  return {
    startTime: firstStartTime.format('YYYY/MM/DD HH:mm'),
    endTime: latestEndTime.format('YYYY/MM/DD HH:mm'),
  };
};

/**
 * シフト追加処理
 */
export const useShiftAddAction = () => {
  const {
    orgCode, date, hideShiftAddPanelAction, fetchStaffDailyShifts,
  } = useShiftDayCalendarContext();
  const { errorNotification } = useToastNotification();
console.log(date)
  // シフトの追加
  const addShiftAction = useCallback((
    staffCode: string,
    closingHour: number,
    attendType: number,
    shiftPatternId: string,
    shiftDetailList: Array<ShiftDetail>,
  ) => {
    const startAndEndTime = getStartAndEndTime(date, shiftDetailList);
    const isPublicHoliday = attendType !== 0;
    const closingStartTime = convertMomentToYYYYMMDD(moment(date).hour(closingHour).minutes(0).second(0));
    const closingEndTime = convertMomentToYYYYMMDD(moment(date).hour(closingHour).minutes(0).add(1, 'day').second(0));
    const startTime = isPublicHoliday
      ? closingStartTime
      : startAndEndTime.startTime;
    const endTime = isPublicHoliday
      ? closingEndTime
      : startAndEndTime.endTime;
    console.log(`startTime = ${startTime}`);
    console.log(`endTime = ${endTime}`);

    const shiftPostData: ShiftPostData = {
      orgCode,
      staffCode,
      targetDate: moment(date).format('YYYY/MM/DD'),
      procType: 0, // 0:シフト登録
      attendType,
      shiftPatternId,
      startTime,
      endTime,
      shiftDetailList: shiftDetailList.map((shiftDetail) => {
        const startTimeMoment = convertDateStringToMoment(shiftDetail.startTime);
        const endTimeMoment = convertDateStringToMoment(shiftDetail.endTime);
        return {
          ...shiftDetail,
          startTime: isPublicHoliday
            ? closingStartTime
            : !shiftDetail.isStartTimeNextDay
              ? convertMomentToYYYYMMDD(
                moment(date).hour(startTimeMoment.hour()).minutes(startTimeMoment.minutes()).second(0),
              )
              : convertMomentToYYYYMMDD(
                moment(date).add(1, 'days').hour(startTimeMoment.hour()).minutes(startTimeMoment.minutes()).second(0),
              ),
          endTime: isPublicHoliday
            ? closingEndTime
            : !shiftDetail.isEndTimeNextDay
              ? convertMomentToYYYYMMDD(
                moment(date).hour(endTimeMoment.hour()).minutes(endTimeMoment.minutes()).second(0),
              )
              : convertMomentToYYYYMMDD(
                moment(date).add(1, 'days').hour(endTimeMoment.hour()).minutes(endTimeMoment.minutes()).second(0),
              ),
          createDate: convertMomentToYYYYMMDD(moment()),
          updateDate: convertMomentToYYYYMMDD(moment()),
        };
      }),
    };

    postShift(shiftPostData).then(() => {
      fetchStaffDailyShifts();
      hideShiftAddPanelAction();
    }).catch(() => {
      errorNotification('シフトの追加に失敗しました。');
    });
  }, [date, errorNotification, fetchStaffDailyShifts, hideShiftAddPanelAction, orgCode]);

  return addShiftAction;
};

/**
 * シフトバー更新処理
 */
export const useShiftBarChangeAction = () => {
  const {
    date, orgCode, hideShiftAddPanelAction, staffDailyShifts, setStaffDailyShift, loadingStaffStart, loadingStaffEnd,
  } = useShiftDayCalendarContext();

  const { errorNotification } = useToastNotification();

  // シフトの更新(シフトバーの更新)
  const changeShiftBarAction = useCallback((
    moveType: number,
    staffCode: string,
    shiftId: string,
    startTime: string,
    endTime: string,
  ) => {
    // ローディング中にする
    loadingStaffStart(staffCode);

    const shiftMovePostData: ShiftMovePostData = {
      orgCode,
      staffCode,
      targetDate: moment(date).format('YYYY/MM/DD'),
      shiftId,
      startTime,
      endTime,
    };

    postShiftMove(moveType, shiftMovePostData).then((response) => {
      // データ更新
      setStaffDailyShift(response[0]);

      // モーダルを閉じる
      hideShiftAddPanelAction();

      // ローディング解除
      loadingStaffEnd(staffCode);
    }).catch(() => {
      // エラ〜メッセージを表示
      errorNotification('シフトの更新に失敗しました。');

      // 失敗したらデータを戻す
      const staffDailyShift = staffDailyShifts.find(
        (currentStaffDailyShift) => currentStaffDailyShift.staffCode === staffCode,
      );
      if (staffDailyShift) {
        setStaffDailyShift(staffDailyShift);
      }

      loadingStaffEnd(staffCode);
    });
  }, [
    loadingStaffStart, orgCode, date,
    setStaffDailyShift, hideShiftAddPanelAction, loadingStaffEnd, errorNotification, staffDailyShifts,
  ]);

  return changeShiftBarAction;
};

/**
 * シフト更新処理
 */
export const useShiftEditAction = () => {
  const {
    loadingStaffStart, loadingStaffEnd, date, orgCode, setStaffDailyShift, hideShiftEditPanelAction,
  } = useShiftDayCalendarContext();
  const { successNotification, errorNotification } = useToastNotification();

  // シフトの更新
  const changeShiftAction = useCallback((
    staffCode: string,
    shiftId: string,
    shiftPatternId: string,
    closingHour: number,
    attendType: number,
    shiftDetailList: Array<ShiftDetail>,
  ) => {
    // モーダルを閉じる
    hideShiftEditPanelAction();

    // ローディング中にする
    loadingStaffStart(staffCode);

    const startAndEndTime = getStartAndEndTime(date, shiftDetailList);
    const isPublicHoliday = attendType !== 0;
    const closingStartTime = convertMomentToYYYYMMDD(moment(date).hour(closingHour).minutes(0).second(0));
    const closingEndTime = convertMomentToYYYYMMDD(moment(date).hour(closingHour).minutes(0).second(0).add(1, 'day'));
    const startTime = isPublicHoliday
      ? closingStartTime
      : startAndEndTime.startTime;
    const endTime = isPublicHoliday
      ? closingEndTime
      : startAndEndTime.endTime;
    const shiftPostData: ShiftPostData = {
      orgCode,
      staffCode,
      targetDate: moment(date).format('YYYY/MM/DD'),
      procType: 1, // 1:シフト更新
      attendType,
      shiftId,
      shiftPatternId,
      startTime,
      endTime,
      shiftDetailList: shiftDetailList.map((shiftDetail) => {
        const startTimeMoment = convertDateStringToMoment(shiftDetail.startTime);
        const endTimeMoment = convertDateStringToMoment(shiftDetail.endTime);
        return {
          ...shiftDetail,
          startTime: convertMomentToYYYYMMDD(
            isPublicHoliday
              ? moment(date).hour(closingHour).minutes(0).second(0)
              : !shiftDetail.isStartTimeNextDay
                ? moment(date).hour(startTimeMoment.hour()).minutes(startTimeMoment.minutes()).second(0)
                : moment(date).add(1, 'day').hour(startTimeMoment.hour()).minutes(startTimeMoment.minutes()).second(0),
          ),
          endTime: convertMomentToYYYYMMDD(
            isPublicHoliday
              ? moment(date).hour(closingHour).minutes(0).add(1, 'day').second(0) // 1日後にする
              : !shiftDetail.isEndTimeNextDay
                ? moment(date).hour(endTimeMoment.hour()).minutes(endTimeMoment.minutes()).second(0)
                : moment(date).add(1, 'day').hour(endTimeMoment.hour()).minutes(endTimeMoment.minutes()).second(0),
          ),
          createDate: convertMomentToYYYYMMDD(moment()),
          updateDate: convertMomentToYYYYMMDD(moment()),
        };
      }),
    };

    postShift(shiftPostData).then((staffDailyShifts) => {
      setStaffDailyShift(staffDailyShifts[0]);

      successNotification('シフトを更新しました。');

      // ローディング解除
      loadingStaffEnd(staffCode);
    }).catch(() => {
      errorNotification('シフトの更新に失敗しました。');

      loadingStaffEnd(staffCode);
    });
  }, [date, errorNotification, hideShiftEditPanelAction, loadingStaffEnd, loadingStaffStart, orgCode, setStaffDailyShift, successNotification]);

  return changeShiftAction;
};

/**
 * シフト削除処理
 */
export const useShiftDeleteAction = () => {
  const {
    loadingStaffStart, loadingStaffEnd, date, orgCode, hideShiftEditPanelAction, setStaffDailyShift, deleteStaffDailyShift,
  } = useShiftDayCalendarContext();
  const { successNotification, errorNotification } = useToastNotification();

  // シフトの削除
  const deleteShiftAction = useCallback((
    staffCode: string,
    shiftId: string,
  ) => {
    loadingStaffStart(staffCode);
    hideShiftEditPanelAction();

    const shiftDeleteQuery: ShiftDeleteQueryParameter = {
      orgCode,
      staffCode,
      targetDay: moment(date).format('YYYY-MM-DD'),
      shiftId,
    };

    deleteShift(shiftDeleteQuery).then((staffDailyShifts) => {
      successNotification('シフトを削除しました');

      if (staffDailyShifts.length === 0) {
        // レスポンスが空配列なら一覧から削除
        deleteStaffDailyShift(staffCode);
      } else {
        setStaffDailyShift(staffDailyShifts[0]);
      }

      // ローディング解除
      loadingStaffEnd(staffCode);
    }).catch(() => {
      errorNotification('シフトの削除に失敗しました。');

      loadingStaffEnd(staffCode);
    });
  }, [
    loadingStaffStart, loadingStaffEnd,
    hideShiftEditPanelAction, orgCode, date,
    deleteStaffDailyShift, setStaffDailyShift,
    successNotification, errorNotification,
  ]);

  return deleteShiftAction;
};

export default {};
