import { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  getHistory, postManagers, getSelManager,
} from 'api/organization';
import ManagerDomain from 'domain/manager';
import useToastNotification from 'hooks/useToastNotification';
import OrganizationDomain from 'domain/organization';

/**
 * 内部状態
 */
const useOrganizationManagersState = () => {
  const [managerList, setManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [seniorManagerList, setSeniorManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [staffList, setStaffList] = useState<Array<ManagerDomain>|null>(null);
  return {
    state: {
      managerList,
      seniorManagerList,
      staffList,
    },
    setManagerList,
    setSeniorManagerList,
    setStaffList,
  };
};

/**
 * ハンドラー
 */
const useOrganizationManagersHandler = (
  orgCode: string,
  managerList: Array<ManagerDomain>|null,
  seniorManagerList: Array<ManagerDomain>|null,
) => {
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();

  // 保存
  const onSubmit = useCallback((isMoveTo: boolean = false) => () => {
    const postOrganizations = async () => {
      const organizationHistoryList = await getHistory(orgCode);
      const organization = new OrganizationDomain(organizationHistoryList[0]);
      if (!organization || !managerList || !seniorManagerList) {
        errorNotification('サーバー側でエラーが発生しました。');
        return;
      }

      const postData = organization.getManagersPostData(
        managerList.map((manager) => manager.rawDate),
        seniorManagerList.map((seniorManager) => seniorManager.rawDate),
      );

      postManagers(postData).then(() => {
        successNotification('更新しました。');
        if (isMoveTo) {
          // 特賃日設定へ遷移
          history.push(`/organizations/${orgCode}/specialsalary`);
        }
      }).catch(() => {
        errorNotification('サーバー側でエラーが発生しました。');
      });
    };
    postOrganizations();
  }, [errorNotification, history, managerList, orgCode, seniorManagerList, successNotification]);

  return {
    onSubmit,
  };
};

export const useOrganizationManagers = () => {
  const { orgCode } = useParams<{orgCode: string}>();
  const {
    state, setManagerList, setSeniorManagerList, setStaffList,
  } = useOrganizationManagersState();

  // 初期化
  useEffect(() => {
    const fetchData = async () => {
      const organizationHistoryList = await getHistory(orgCode);
      const organizationHistory = organizationHistoryList[0];

      setManagerList(organizationHistory && organizationHistory.managerList
        ? organizationHistory.managerList.map((manager) => new ManagerDomain(manager))
        : []);
      setSeniorManagerList(organizationHistory && organizationHistory.seniorManagerList
        ? organizationHistory.seniorManagerList.map(
          (seniorManager) => new ManagerDomain(seniorManager),
        )
        : []);
    };
    fetchData();
  }, [orgCode, setManagerList, setSeniorManagerList, setStaffList]);

  useEffect(() => {
    const fetchStaffList = async () => {
      const staffList = await getSelManager(orgCode);
      setStaffList(staffList.map((staff) => new ManagerDomain(staff)));
    };
    fetchStaffList();
  }, [orgCode, setStaffList]);

  // Handler
  const handler = useOrganizationManagersHandler(
    orgCode,
    state.managerList,
    state.seniorManagerList,
  );

  return {
    state,
    handler,
    setManagerList,
    setSeniorManagerList,
  };
};

export default {};
