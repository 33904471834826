/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { Detail } from "domain/master/attend/allowanceApplication";
import TextInput from "components/atoms/Form/TextInput";
import useIsMobile from "hooks/useIsMobile";
import { Popover } from "components/atoms/Popover";
import MoneyInput from "components/atoms/Form/MoneyInput";

const AllowanceDetailEdit: React.FC<{
  formik: any;
  detail: Detail;
  index: number;
}> = ({ formik, detail, index }) => {
  const isMobile = useIsMobile();

  return (
    <tr>
      <td>
        {isMobile ? (
          <Popover
            trigger={
              <div
                style={{
                  textDecoration: "underline",
                }}
              >
                {detail.allowanceName}
              </div>
            }
          >
            <div
              style={{
                fontSize: "14px",
                fontStyle: "normal",
              }}
            >
              {detail.dispMessage.replace("</br>", "\n") || ""}
            </div>
          </Popover>
        ) : (
          detail.allowanceName
        )}
      </td>
      {!isMobile && <td>{detail.dispMessage}</td>}

      <td>
        <MoneyInput
          name="allowanceValue"
          value={detail.allowanceValue}
          label={detail.dispUnit}
          onChange={(e) => formik.setFieldValue(`detailList[${index}].allowanceValue`, Number(e.target.value))}
          min={0}
          max={99999999}
        />
      </td>
      <td>
        <TextInput
          name="allowanceValue"
          value={detail.comment}
          onChange={(e) => formik.setFieldValue(`detailList[${index}].comment`, e.target.value)}
        />
      </td>
    </tr>
  );
};

export default AllowanceDetailEdit;
