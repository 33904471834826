import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterRoleSettingForm from 'components/organismos/master/masterRole/MasterRoleSettingForm';

const MasterRoleSettingPage: React.FC = () => {

  return (
    <SidebarTemplate pageTitle="権限マスタ">
      <MasterRoleSettingForm/>
    </SidebarTemplate>
  );
};

export default MasterRoleSettingPage;
