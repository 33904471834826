import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendApplicationList from 'components/organismos/master/attend/AttendApplicationList';

const AttendApplicationListPage = () => (
  <SidebarTemplate>
    <AttendApplicationList />
  </SidebarTemplate>
);

export default AttendApplicationListPage;
