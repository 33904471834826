
import { useState, useEffect } from 'react';

import { getList } from 'api/prescribedHours';
import PrescribedHoursDomain from 'domain/master/labor/prescribedHours';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const usePrescribedHoursList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterWorkingHours);

  const [prescribedList, setPrescribedList] = useState<Array<PrescribedHoursDomain>>([]);
  useEffect(() => {
    setIsLoading(true);
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      setIsLoading(false);
      if (isSubscribed) {
        setPrescribedList(response.map((result) => new PrescribedHoursDomain(result)));
      }
    });
    return () => { isSubscribed = false; };
  }, []);
  return { prescribedList, setPrescribedList, isLoading, detailRole };
};

export default usePrescribedHoursList;
