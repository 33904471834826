/** @jsx jsx */

import React from "react";
import { css, jsx } from "@emotion/core";

const AllowanceTable: React.FC<{
  allowanceData: any;
}> = ({ allowanceData }) => (
  <div className="table-responsive" style={{ width: "50%" }}>
    <div
      css={css`
        table thead tr th {
          height: 46px;
        }
        table tbody tr td {
          height: 32px;
        }
      `}
    >
      <table
        id="allowanceList"
        className="table table-bordered table-condensed table-no-border-left-right"
        cellSpacing="0"
        style={{ whiteSpace: "nowrap", width: "100%" }}
      >
        <thead>
          <tr role="row">
            <th style={{ width: "50%" }}>手当名</th>
            <th style={{ width: "50%" }}>申請値合計</th>
          </tr>
        </thead>
        <tbody>
          {allowanceData.map((data: any) => (
            <tr role="row" key={data.id}>
              <td>{data.allowanceName}</td>

              <td>{`${data.allowanceValue}${data.dispUnit}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default AllowanceTable;
