import React, { useEffect, useState } from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import SalesBudgetYearLayout from 'components/organismos/master/storeManagement/salesBudgetYear/salesBudgetYearLayout';
import { getFiscalYearFromDate } from 'api/salesBudgetYear';

const SalesBudgetYear = () => {  
  const [getYear,setYear] = useState();
  useEffect(() => {
    getFiscalYearFromDate().then((response) =>{
      setYear(response.id.fiscalYear);
    });
  });

  return (
    <SidebarTemplate pageTitle="予算編成">
      { getYear ? <SalesBudgetYearLayout getFiscalYear={getYear} /> : ''  }
    </SidebarTemplate>
  )
}
export default SalesBudgetYear;
