/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Panel from "components/atoms/Panel";
import ScheduleCalendarToolbar from "components/organismos/master/attend/Schedule/ScheduleCalendarToolbar";
import ScheduleMonthCalendar from "components/organismos/master/attend/Schedule/ScheduleMonthCalendar";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import BodyText from "components/atoms/BodyText";
import PrimaryButton from "components/atoms/Button";
import { utilityColor } from "components/styles";
import moment from "moment";
import BlockUI from "components/molecules/BlockUi";
import { HopeShiftDomain } from "domain/master/general/hopeShift";
import { AttendShiftPatternDetails } from "domain/master/general/weekShiftPatternDataStaff";
import { ScheduleStatus } from "api/schedule";
import TextForm from "components/molecules/TextForm";
import { useSchedulePage } from "./hooks";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const ScheduleWebPage: React.FC = () => {
  const { state, setter } = useSchedulePage();

  const valueSelectType =
    state.selectedHopeShift?.shiftPatternId &&
    state.shiftPatternOptions.map((item) => item.value).includes(state.selectedHopeShift?.shiftPatternId)
      ? state.selectedHopeShift?.shiftPatternId
      : state.flgCanWork
      ? "CANWORK"
      : state.selectedHopeShift?.hopeShiftPatternType === 1 ||
        state.selectedHopeShift?.fixStatus === ScheduleStatus.DAY_OFF
      ? "CANTWORK"
      : state.selectedHopeShift?.hopeShiftPatternType === 2
      ? "CANTWORK"
      : state.selectedHopeShift?.hopeShiftPatternType === 1
      ? "CANWORK"
      : state.selectedHopeShift?.startTime
      ? "CANWORK"
      : undefined;
  return (
    <BlockUI blocking={state.isLoading}>
      <FlexBox>
        <div
          style={{
            minWidth: 800,
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <FlexBoxItem basis="75%">
            <Panel margin="8px 0px 8px 16px" padding="8px 20px">
              <ScheduleCalendarToolbar
                date={state.datePickerValue}
                setDatePickerValue={setter.setDatePickerValue}
                setDate={setter.setDate}
              />

              <div
                css={css({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  gap: 4,
                  margin: "8px 0",
                })}
              >
                <BodyText size="md" customStyle={css({ marginRight: 16 })}>
                  合計勤務予定時間
                </BodyText>
                <BodyText size="xl2" bold={true}>
                  {state.operationInfo.monthTotalworkTimeHour || 0}
                </BodyText>
                <BodyText size="md">時間</BodyText>
                <BodyText size="xl2" bold={true}>
                  {state.operationInfo.monthTotalworkTimeMin || 0}
                </BodyText>
                <BodyText size="md">分</BodyText>
              </div>

              {/* 月表示 */}
              <ScheduleMonthCalendar
                events={state.events}
                date={state.date}
                datePickerValue={state.datePickerValue}
                setDate={setter.setDate}
                setDatePickerValue={setter.setDatePickerValue}
              />
            </Panel>
          </FlexBoxItem>

          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "8px 16px",
              flexBasis: "25%",
              gap: 12,
            })}
          >
            <div
              css={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                padding: "20px 24px",
                boxSizing: "border-box",
                backgroundColor: utilityColor.white,
                gap: 8,
              })}
            >
              <BodyText size="re">希望シフト変更</BodyText>
              <BodyText size="md" customStyle={css({ textAlign: "left" })}>
                {moment(state.date).format("YYYY年MM月DD日")}
              </BodyText>

              <VerticalLabelSelectForm
                label=""
                placeholder="-"
                name="shift"
                width="100%"
                value={valueSelectType ?? ""}
                setValue={(val: string) => {
                  setter.setFlgCanWork(false);
                  if (val === "") {
                  } else if (val === "CANWORK") {
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      note: state.selectedHopeShift ? state.selectedHopeShift.note : "",
                    });
                    setter.setFlgCanWork(true);
                    setter.setSelectedHopeShift(editedHopeShift);
                  } else if (val === "CANTWORK") {
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: null,
                      endTime: null,
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 2,
                      note: "",
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  } else {
                    const selectedShiftPattern = state.shiftPatternList.filter(
                      (item) => item.shiftPatternId === val,
                    )[0];

                    // Get end time
                    const shiftDetailEnd: AttendShiftPatternDetails =
                      selectedShiftPattern.attendShiftPatternDetails[
                        selectedShiftPattern.attendShiftPatternDetails.length - 1
                      ];
                    const [hours, minutes, seconds] = shiftDetailEnd.endTime.split(":");
                    const endTime = new Date(state.selectedHopeShift!.targetDate);

                    // NOTE: 翌日チェックはBack側でやる
                    // if (shiftDetailEnd.isEndTimeNextDay) {
                    //   endTime.setDate(endTime.getDate() + 1);
                    // }
                    endTime.setHours(Number(hours));
                    endTime.setMinutes(Number(minutes));
                    endTime.setSeconds(Number(seconds));
                    // Get start time
                    const shiftDetailStart: AttendShiftPatternDetails =
                      selectedShiftPattern.attendShiftPatternDetails[0];
                    const [h, m, s] = shiftDetailStart.startTime.split(":");
                    const startTime = new Date(state.selectedHopeShift!.targetDate);

                    // NOTE: 翌日チェックはBack側でやる
                    // if (shiftDetailStart.isStartTimeNextDay) {
                    //   startTime.setDate(endTime.getDate() + 1);
                    // }

                    startTime.setHours(Number(h));
                    startTime.setMinutes(Number(m));
                    startTime.setSeconds(Number(s));

                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: selectedShiftPattern.shiftPatternId,
                      startTime,
                      endTime,
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 3,
                      note: "",
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }
                }}
                options={[...state.shiftPatternOptions]}
                disable={!state.selectedHopeShift?.isEditable}
                menuPlacement="bottom"
                isSearchable={false}
              />

              <div css={css({ width: "100%", display: "flex", flexDirection: "column" })}>
                <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                  <VerticalLabelSelectForm
                    label=""
                    name="midnightStartHour"
                    width="100%"
                    value={state.startTimeGlobal ? state.startTimeGlobal.format("HH") : "00"}
                    setValue={(val: string) => {
                      if (!state.selectedHopeShift?.startTime || val === "") return;
                      const tmpVal = state.startTimeGlobal
                        .hour(Number(val))
                        .minutes(state.startTimeGlobal.toDate().getMinutes());
                      setter.setStartTimeGlobal(tmpVal);
                      const editedHopeShift = new HopeShiftDomain({
                        ...state.selectedHopeShift!.getRawData(),
                        shiftPatternId: "",
                        startTime: tmpVal.toDate(),
                        endTime: state.endTimeGlobal.toDate(),
                        fixStatus: ScheduleStatus.UNSUBMITED,
                        hopeShiftPatternType: 1,
                        // businessId: state.businessId,
                      });
                      setter.setSelectedHopeShift(editedHopeShift);
                    }}
                    options={hourFormOptions}
                    disable={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                    menuPlacement="bottom"
                    isSearchable={false}
                  />
                  <BodyText customStyle={css({ margin: "0 2px 4px 2px" })}>:</BodyText>
                  <VerticalLabelSelectForm
                    name="midnightStartTime"
                    width="100%"
                    value={state.startTimeGlobal ? state.startTimeGlobal.format("mm") : "00"}
                    setValue={(val: string) => {
                      if (!state.selectedHopeShift?.startTime || val === "") return;
                      const tmpVal = state.startTimeGlobal
                        .hour(state.startTimeGlobal.toDate().getHours())
                        .minutes(Number(val));
                      setter.setStartTimeGlobal(tmpVal);
                      const editedHopeShift = new HopeShiftDomain({
                        ...state.selectedHopeShift!.getRawData(),
                        shiftPatternId: "",
                        startTime: tmpVal.toDate(),
                        endTime: state.endTimeGlobal.toDate(),
                        fixStatus: ScheduleStatus.UNSUBMITED,
                        hopeShiftPatternType: 1,
                        // businessId: state.businessId,
                      });
                      setter.setSelectedHopeShift(editedHopeShift);
                    }}
                    options={timeFormOptions}
                    disable={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                    menuPlacement="bottom"
                    isSearchable={false}
                  />
                  <BodyText customStyle={css({ margin: "0 10px" })}>～</BodyText>
                </div>
                <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                  <VerticalLabelSelectForm
                    name="midnightEndHour"
                    width="100%"
                    value={state.endTimeGlobal ? state.endTimeGlobal.format("HH") : "00"}
                    setValue={(val) => {
                      if (!state.selectedHopeShift?.endTime || val === "") return;
                      const tmpVal = state.endTimeGlobal
                        .hour(Number(val))
                        .minutes(state.endTimeGlobal.toDate().getMinutes());
                      setter.setEndTimeGlobal(tmpVal);
                      const editedHopeShift = new HopeShiftDomain({
                        ...state.selectedHopeShift!.getRawData(),
                        shiftPatternId: "",
                        startTime: state.startTimeGlobal.toDate(),
                        endTime: tmpVal.toDate(),
                        fixStatus: ScheduleStatus.UNSUBMITED,
                        hopeShiftPatternType: 1,
                        // businessId: state.businessId,
                      });
                      setter.setSelectedHopeShift(editedHopeShift);
                    }}
                    disable={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                    options={hourFormOptions}
                    menuPlacement="bottom"
                    isSearchable={false}
                  />
                  <BodyText customStyle={css({ margin: "0 2px 4px 2px" })}>:</BodyText>
                  <VerticalLabelSelectForm
                    name="midnightEndTime"
                    width="100%"
                    value={state.endTimeGlobal ? state.endTimeGlobal.format("mm") : "00"}
                    setValue={(val) => {
                      if (!state.selectedHopeShift?.endTime || val === "") return;
                      const tmpVal = state.endTimeGlobal
                        .hour(state.endTimeGlobal.toDate().getHours())
                        .minutes(Number(val));
                      setter.setEndTimeGlobal(tmpVal);
                      const editedHopeShift = new HopeShiftDomain({
                        ...state.selectedHopeShift!.getRawData(),
                        shiftPatternId: "",
                        startTime: state.startTimeGlobal.toDate(),
                        endTime: tmpVal.toDate(),
                        fixStatus: ScheduleStatus.UNSUBMITED,
                        hopeShiftPatternType: 1,
                        // businessId: state.businessId,
                      });
                      setter.setSelectedHopeShift(editedHopeShift);
                    }}
                    disable={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                    options={timeFormOptions}
                    menuPlacement="bottom"
                    isSearchable={false}
                  />
                  <BodyText customStyle={css({ minWidth: "34px" })}></BodyText>
                </div>
              </div>

              {state.businessOptions.length > 1 && (
                <VerticalLabelSelectForm
                  label=""
                  placeholder="-"
                  name="businessId"
                  width="100%"
                  value={state.businessId}
                  setValue={(businessId) => {
                    setter.setBusinessId(businessId);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      // businessId, // TODO: 仮決め
                      note: state.selectedHopeShift ? state.selectedHopeShift.note : "",
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }}
                  options={state.businessOptions.filter((item: any) => {
                    if (valueSelectType === "CANWORK" && item.label === "休憩") {
                      return false;
                    }
                    return true;
                  })}
                  disable={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                  menuPlacement="bottom"
                  isSearchable={false}
                />
              )}
              <TextForm
                name="comment"
                label="コメント"
                value={state.note}
                onChange={(e) => {
                  setter.setNote(e.target.value);
                }}
                onBlur={(e) => {
                  if (state.selectedHopeShift?.note !== e.target.value) {
                    setter.setNote(e.target.value);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: state.selectedHopeShift ? state.selectedHopeShift.shiftPatternId : "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      // businessId: state.businessId, // TODO: 仮決め
                      note: e.target.value,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }
                }}
                disable={!state.selectedHopeShift?.isEditable}
              />
            </div>

            <div
              css={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                boxSizing: "border-box",
                gap: 8,
              })}
            >
              <PrimaryButton text="提出" fullwidth={true} onClick={setter.onSubmitHopeShifts} />
            </div>
          </div>
        </div>
      </FlexBox>
    </BlockUI>
  );
};

export default ScheduleWebPage;
