import React, { SVGProps } from 'react';

const ManagementUser: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M26.1557 31.8814L33.069 35.0238C34.854 35.8352 36 37.6149 36 39.5757V41C36 43.7614 33.7614 46 31 46H9C6.23858 46 4 43.7614 4 41V39.5757C4 37.6149 5.14601 35.8352 6.93099 35.0238L13.8443 31.8814C11.5042 30.0511 10 27.2012 10 24C10 18.4772 14.4772 14 20 14C25.5228 14 30 18.4772 30 24C30 27.2012 28.4958 30.0511 26.1557 31.8814ZM15.9225 33.1328L7.75859 36.8437C6.68761 37.3305 6 38.3984 6 39.5748V40.9991C6 42.656 7.34315 43.9991 9 43.9991H31C32.6569 43.9991 34 42.656 34 40.9991V39.5748C34 38.3984 33.3124 37.3305 32.2414 36.8437L24.0775 33.1328C22.8322 33.6896 21.4523 33.9991 20 33.9991C18.5477 33.9991 17.1678 33.6896 15.9225 33.1328ZM28 24C28 28.4183 24.4183 32 20 32C15.5817 32 12 28.4183 12 24C12 19.5817 15.5817 16 20 16C24.4183 16 28 19.5817 28 24Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.3937 4C26.7833 4 22.7806 6.79148 20.9085 10.8554C20.6774 11.357 20.0834 11.5764 19.5818 11.3453C19.0802 11.1142 18.8609 10.5202 19.092 10.0186C21.2664 5.29848 25.9461 2 31.3937 2C38.9363 2 45.0002 8.28789 45.0002 15.9916C45.0002 22.1785 41.0953 27.4541 35.6508 29.3C35.1278 29.4773 34.56 29.1971 34.3827 28.674C34.2054 28.151 34.4856 27.5832 35.0087 27.4059C39.634 25.8378 43.0002 21.3292 43.0002 15.9916C43.0002 9.3358 37.7759 4 31.3937 4Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31 17L31 8C31 7.44772 31.4477 7 32 7C32.5523 7 33 7.44772 33 8L33 17C33 17.5523 32.5523 18 32 18C31.4477 18 31 17.5523 31 17Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32 16L38 16C38.5523 16 39 16.4477 39 17C39 17.5523 38.5523 18 38 18L32 18C31.4477 18 31 17.5523 31 17C31 16.4477 31.4477 16 32 16Z" fill="#003993"/>
  </svg>
  
);

export default ManagementUser;
