/* eslint-disable camelcase */
import moment from 'moment';
import ApiClient from './ApiClient';

export type Budget = {
  net_sales: number;
  menu_cost_total: number;
  land_rent: number;
  guest_cnt: number;
  sales_budget_total: number;
  sales_budget_cost_total: number;
  sales_budget_part_labor_cost: number;
  sales_budget_other_variable_costs: number;
  sales_budget_employee_labor_cost: number;
  sales_budget_land_rent: number;
  sales_budget_other_fixed_cost: number;
  other_expenses_total: number;
  sales_budget_guest_cnt: number;
  per_customer_price: number;
}

export type LoborCost = {
  employee_labor_cost: number;
  part_labor_cost: number;
}

export type Advertising = {
  advertising_media_expense: number;
  budget_advertising_media_expense: number;
}

export type GeneralReportMonth = {
  budget: Budget;
  labor_cost: any;
  advertising: Array<Advertising>;
}

export type GeneralReport = {
  days: any;
  month: GeneralReportMonth;
}

export const getSalesPlanMonthly = async (
  orgCode: string,
  targetMonth: Date,
): Promise<GeneralReport> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetMonth: moment(new Date(targetMonth.getFullYear(), targetMonth.getMonth(), 1)).format('YYYY-MM-DD'),
  };
  const response = await ApiClient.get(`/v1/report/general/${companyCode}`, params);
  return response.data;
};

export const getSalesPlanDaily = async (orgCode: string, targetDay: Date): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
  };
  const response = await ApiClient.get(`/v1/report/daily_detail/${companyCode}`, params);
  return response.data;
};

export const updateCommentForSale = async (
  orgCode: string,
  targetDay: Date,
  budgetProgressComment: string,
  storeOperationComment: string,
  otherComment: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const updateUser = sessionStorage.getItem('loginUser.staffName');
  const params = {
    orgCode,
    updateUser,
    targetDay: moment(targetDay).format('YYYY-MM-DD'),
    budgetProgressComment,
    storeOperationComment,
    otherComment,
  };
  const response = await ApiClient.get(`/v1/report/daily_detail/update_comment/${companyCode}`, params);
  return response.data;
};

export default {
  getSalesPlanMonthly,
};
