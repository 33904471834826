/** @jsx jsx */
import React, { useState, useContext } from "react";
import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import HourInput from "components/atoms/Form/HourInput";
import Checkbox from "components/molecules/Checkbox";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { grayScale, productColor } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";

import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isIPad13, isTablet, isSafari } from "react-device-detect";
import { useManagementInformationDomainForm, useDownloadHolidayManagement } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import ManagementNursingHolidayStatusDomain from "domain/master/holidayManagement/managementNursingHolidayStatus";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "150px",
    width: "150px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "150px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
    color: "#666666",
  }),
  detail: css({
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  input: css({
    maxWidth: "500px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 20px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  table: css(
    {
      color: "#666666",
      margin: "0 auto",
    },
    css`
      table.tblPopup tr {
        display: inline-grid;
        margin-bottom: 10px;
      }
      table.tblPopup tr td {
        margin-bottom: 5px;
      }
    `,
  ),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
}

const ManagementInformationForm: React.FC<{
  employmentOptions: any;
  orgTreesOptions: any;
}> = ({ employmentOptions, orgTreesOptions }) => {
  const {
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    extinctionDaysStart,
    setExtinctionDaysStart,
    extinctionDaysEnd,
    setExtinctionDaysEnd,
    targetGrantTenDays,
    setTargetGrantTenDays,
    modalOpen,
    setModalOpen,
    holidayType,
    setHolidayType,
    holidayOptions,
    managementNursingHolidayStatus,
    underFlag,
    setUnderFlag,
    // setUntilNextGrantDate,
    // untilNextGrantDate
  } = useManagementInformationDomainForm();
  const contextObject = useContext(HolidayManagementContext);

  const { downloadNursingCareHolidayStatus, blocking } = useDownloadHolidayManagement(
    orgCode || "",
    holidayType || "7",
    underFlag || 0,
  );
  //
  const columns: Array<Column<ManagementNursingHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          data: Array<ManagementNursingHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          data: Array<ManagementNursingHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          data: Array<ManagementNursingHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          data: Array<ManagementNursingHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "休暇タイプ",
        id: "holidayType",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          data: Array<ManagementNursingHolidayStatusDomain>;
        }) => (
          <React.Fragment>
            {cell.row.original.holidayType === 7 && "介護休暇"}
            {cell.row.original.holidayType === 8 && "子の看護休暇"}
          </React.Fragment>
        ),
      },
      {
        Header: "休暇コード",
        accessor: "holidayCode",
      },
      {
        Header: "休暇名",
        accessor: "holidayName",
      },
      {
        Header: "取得可能日数",
        accessor: "yearUseDays",
      },
      {
        Header: () => "取得可能日数",
        accessor: "yearUseDays",
        id: "yearUseDays",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => `${cell.row.original.yearUseDays}日`,
      },
      {
        Header: () => "取得日数",
        accessor: "digestedTimeNum",
        id: "digestedTimeNum",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => `${cell.row.original.digestedDaysNum || "0"}日${cell.row.original.digestedTimeHour || "0"}時間`,
      },
      {
        Header: () => "残日数",
        accessor: "remainTimesNum",
        id: "remainTimesNum",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => `${cell.row.original.remainDaysNum || "0"}日${cell.row.original.remainTimesNum || "0"}時間`,
      },
      {
        Header: () => "残日数",
        accessor: "remainTimesNum",
        id: "remainTimesNum",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => `${cell.row.original.remainDaysNum || "0"}日${cell.row.original.remainTimesNum || "0"}時間`,
      },
      {
        Header: () => "1年とする期間(開始)",
        accessor: "targetStartDate",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => `${moment(moment(cell.row.original.targetStartDate).toDate()).format("YYYY年MM月DD日") || ""}`,
      },
      {
        Header: () => "1年とする期間(終了)",
        accessor: "targetEndDate",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => `${moment(moment(cell.row.original.targetEndDate).toDate()).format("YYYY年MM月DD日") || ""}`,
      },
      {
        Header: () => "備考",
        accessor: "targetEndDateToStr",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: ManagementNursingHolidayStatusDomain;
          };
          page: [];
        }) => (
          <React.Fragment>
            {cell.row.original.notes?.map((element: any) => {
              let targetDate = moment(moment(element.targetDate).toDate()).format("YYYY年MM月DD日") || "";
              return (
                <FlexBox justifyContent="center">
                  {`${targetDate}：取得可能日数を${element.yearUseDays}日に変更`}
                </FlexBox>
              );
            })}
          </React.Fragment>
        ),
      },
      // {
      //   Header: '取得日数',
      //   accessor: 'digestedTime',
      //   Cell:(cell: {
      //     row: { isExpanded: boolean, original: any },
      //     data: Array<any>
      //   }) => {

      //   }
      // },
    ],
    [],
  );

  const history = useHistory();
  return (
    <BlockUI blocking={blocking}>
      <Accordion text="管理情報" callBack={() => {}} defaultExpand={false}>
        <div css={styles.wrapAccordion}>
          <FormContentFilter maxWidth="100%">
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode001"
                value={String(orgCode)}
                width="900px"
                marginRight="30px"
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
                widthLabel="100px"
              />
              <Checkbox
                id={"underFlag9"}
                name={"underFlag9"}
                label={"配下指定"}
                value={`underFlag9`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
            <SelectForm
              label="休暇タイプ"
              name="holidayType"
              width="900px"
              value={String(holidayType)}
              setValue={(val: string) => setHolidayType(val)}
              options={holidayOptions}
            />
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 140px;
              }
              table th:nth-of-type(2) {
                width: 150px;
              }
              table th:nth-of-type(3) {
                width: 140px;
              }
              table th:nth-of-type(4) {
                width: 150px;
              }
              table th:nth-of-type(5) {
                width: 140px;
              }
              table tr td:nth-of-type(5) {
                text-align: left;
              }
              table th:nth-of-type(6) {
                width: 150px;
              }
              table tr td:nth-of-type(6) {
                text-align: left;
              }
              table th:nth-of-type(7) {
                width: 150px;
              }
              table tr td:nth-of-type(7) {
                text-align: left;
              }
              table th:nth-of-type(8) {
                width: 150px;
              }
              table th:nth-of-type(9) {
                width: 150px;
              }
              table th:nth-of-type(10) {
                width: 180px;
              }
              table th:nth-of-type(11) {
                width: 180px;
              }
              table th:nth-of-type(12) {
                width: 180px;
              }
              table th:nth-of-type(13) {
                width: 350px;
              }
              table tr td:nth-of-type(13) {
                text-align: left;
              }
              table tr td,
              table tr th {
                text-align: center;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadNursingCareHolidayStatus()}
                  iconType="download"
                  text="ダウンロード"
                />
              </FormSubmitArea>
            ) : null}

            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={managementNursingHolidayStatus}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={9}
              />
            </div>
          </div>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default ManagementInformationForm;
