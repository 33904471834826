/** @jsx jsx */
import { useState, useMemo } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { Column } from "react-table";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import BodyText from "components/atoms/BodyText";
import FormField from "components/atoms/Form/FormField";
import FormPadding from "components/atoms/Form/FormPadding";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { ICCardConnectButton } from "components/atoms/Button";
import { SecondaryButton } from "components/atoms/Button";
import FormLabel from "components/atoms/Form/FormLabel";
import { grayScale, productColor } from "components/styles";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import IconLabelButton from "components/molecules/IconLabelButton";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import Modal from "components/molecules/Modal";
import TextForm from "components/molecules/TextForm";
import SelectPortal from "components/atoms/SelectPortal";
import useMasterICCardStaffSettingForm from "./hooks";
import Button from "components/atoms/Button";
import { useHistory } from "react-router-dom";

const styles = {
  deleteBtn: css`
    background-color: ${productColor.deleteAction};
    padding: 0;
    text-align: center;
    height: 24px;
    border-radius: 3px;
    color: white;
    line-height: 0;
    font-size: 12px;
    &:hover,
    &:focus {
      opacity: 0.9;
      background-color: ${productColor.deleteAction};
      cursor: pointer;
    }
  `,
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  containerFormAdd: css({
    backgroundColor: "#F8F8F8",
    border: "1px solid #999999",
    padding: "10px",
    width: "570px",
    height: "auto",
  }),
  labelFormAdd: css({
    fontSize: "16px",
    fontWeight: "bold",
  }),
  buttonConnect: css({
    height: "38px",
    lineHeight: "0",
    width: "50px",
    minWidth: "100px",
    fontWeight: "bold",
    padding: "0",
    marginLeft: "35px",
  }),
};

const MasterICCardStaffSettingForm = () => {
  const {
    isLoading,
    detectCard,
    listICCardByStaff,
    registerStaffICCard,
    fetchUnRegisterCard,
    listICCardUnRegister,
    staffLabel,
    orgLabel,
    idCode,
    setIdCode,
    openModalAddCode,
    setOpenModalAddCode,
    openModalSelectCode,
    setOpenModalSelectCode,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    deleteCode,
    setDeleteCode,
    deleteStaffICCard,
    isConnectICCardUsb,
  } = useMasterICCardStaffSettingForm();

  const columns: Array<Column<any>> = useMemo(
    () => [
      {
        Header: () => null,
        accessor: "cardId",
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any }; data: Array<any> }) => (
          <SecondaryButton
            minWidth="36px"
            onClick={() => {
              setIsOpenDeleteModal(true);
              setDeleteCode(cell.row.original.cardId);
            }}
            customStyle={styles.deleteBtn}
            text="削除"
          />
        ),
      },
      {
        Header: "カード番号",
        accessor: "cardId",
      },
    ],
    [setIsOpenDeleteModal, setDeleteCode],
  );

  const history = useHistory();

  const handleAddCode = () => {
    setOpenModalAddCode(true);
  };

  const handleSelectCode = () => {
    fetchUnRegisterCard();
    setOpenModalSelectCode(true);
  };

  const handleRegisterStaffICCard = () => {
    registerStaffICCard(idCode);
    setOpenModalAddCode(false);
  };

  const handleSelectStaffICCard = () => {
    registerStaffICCard(idCode);
    setOpenModalSelectCode(false);
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents customStyle={{ paddingBottom: "45px" }}>
        <FormTitle
          title="ICカード一覧"
          bold={true}
          customStyle={css`
            margin: 16px 35px;
          `}
        />
        <FormPadding customStyle={{ padding: "0 55px" }}>
          <FlexBox justifyContent="space-between">
            <FlexBoxItem>
              <BodyText size="sm" color="#007BC3">
                打刻に利用するICカードの登録を行えます。複数のICカードを紐付けることも可能です。
              </BodyText>
            </FlexBoxItem>
          </FlexBox>
        </FormPadding>
        <FormField displayBlock={true} customStyle={css({ margin: "50px 35px 40px 35px" })}>
          <FlexBox>
            <FlexBoxItem width="150px">
              <FormLabel label="組織" />
            </FlexBoxItem>
            <FlexBoxItem margin="0 0 8px 0">
              <BodyText color={grayScale.gray100}>{orgLabel}</BodyText>
            </FlexBoxItem>
          </FlexBox>
          <FlexBox>
            <FlexBoxItem width="150px">
              <FormLabel label="スタッフ" />
            </FlexBoxItem>
            <FlexBoxItem margin="0 0 8px 0">
              <BodyText color={grayScale.gray100}>{staffLabel}</BodyText>
            </FlexBoxItem>
          </FlexBox>
        </FormField>
        <div
          css={css`
            table th:nth-of-type(1) {
              width: 60px;
              padding: 0;
            }
            table tr td:nth-of-type(1) {
              text-align: center;
            }
            table th:nth-of-type(2) {
              width: 100%;
            }
          `}
        >
          <FormSubmitArea customStyle={styles.customStyle}>
            <IconLabelButton onClick={() => handleAddCode()} iconType="addCircle" text="カードIDで登録" />
            <IconLabelButton onClick={() => handleSelectCode()} iconType="addCircle" text="読取済みのカードから登録" />
          </FormSubmitArea>
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <DataTable
              className="table-no-border-left-right"
              columns={columns}
              data={listICCardByStaff}
              isGlobalFilter={true}
              minWidth="824px"
              globalSearchStyle={css({ marginLeft: "35px" })}
            />
          </div>
        </div>
        <FormSubmitArea customStyle={css({ textAlign: "center", marginTop: "30px" })}>
          <Button onClick={() => history.push("/masterICCard")} text="キャンセル" ghost={true} />
        </FormSubmitArea>
      </FormContents>
      <Modal
        open={openModalAddCode}
        closeHandler={() => {
          setOpenModalAddCode(false);
          setIdCode("");
        }}
        title="ICカードIDで登録"
        submitText="保存"
        onSubmit={() => handleRegisterStaffICCard()}
        typeSubmit="confirm"
        customStyleFooter={css`
          button {
            min-width: 150px;
          }
        `}
      >
        <div css={styles.containerFormAdd}>
          <FlexBox justifyContent="space-between">
            <FormLabel label="カード番号(IDm)" customStyle={styles.labelFormAdd} />
            <ICCardConnectButton
              disabled={isConnectICCardUsb}
              onClick={() => {
                detectCard();
              }}
            />
          </FlexBox>
          <BodyText color="#F21B1B" size="sm">
            カード番号を入力するかカードリーダでカードを読み取って登録してください
          </BodyText>
          <FlexBox customStyle={css({ marginTop: "5px" })}>
            <div style={{ width: "auto" }}>
              <TextForm
                required={false}
                name="idCode"
                label=""
                value={idCode}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (e.currentTarget.value.includes(" ")) {
                    e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
                  }
                  setIdCode(e.target.value);
                }}
                placeHolder="カード番号(IDm)"
                customStyle={css({
                  width: "300px",
                  height: "40px",
                })}
              />
            </div>
          </FlexBox>
        </div>
      </Modal>

      <Modal
        open={openModalSelectCode}
        closeHandler={() => setOpenModalSelectCode(false)}
        title="読取済みのカードから登録"
        submitText="保存"
        onSubmit={() => {
          setOpenModalSelectCode(false);
          handleSelectStaffICCard();
        }}
        typeSubmit="confirm"
        customStyleFooter={css`
          button {
            min-width: 150px;
          }
        `}
      >
        <div css={styles.containerFormAdd}>
          <FlexBox>
            <FormLabel label="カード番号" customStyle={styles.labelFormAdd} />
          </FlexBox>
          <BodyText color="#F21B1B" size="sm">
            カードリーダーにタッチしたカードかつまだどの従業員にも紐付けられていないカードがリストから選択して登録することも可能です。
          </BodyText>
          <FlexBox>
            <div style={{ width: "auto", marginTop: "10px" }}>
              <SelectPortal
                name="orgCode"
                value={String(idCode)}
                setValue={(v) => {
                  setIdCode(v);
                }}
                options={listICCardUnRegister}
                width="300px"
              />
            </div>
          </FlexBox>
        </div>
      </Modal>
      <Modal
        open={isOpenDeleteModal}
        closeHandler={() => setIsOpenDeleteModal(false)}
        title="確認メッセージ"
        note="ICカード番号を削除します。よろしいですか？"
        submitText="削除"
        onSubmit={() => {
          setIsOpenDeleteModal(false);
          deleteStaffICCard(deleteCode);
        }}
        typeSubmit="reject"
      ></Modal>
    </BlockUI>
  );
};

export default MasterICCardStaffSettingForm;
