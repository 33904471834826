/** @jsx jsx */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from "react";

import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import FormLabel from "components/atoms/Form/FormLabel";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import MultipleCheckboxForm from "components/molecules/MultipleCheckboxForm";
import HourInput from "components/atoms/Form/HourInput";
import VerticalLabelMutilSelectForm from "components/molecules/VerticalLabelMutilSelectForm";
import FlexBox from "components/atoms/FlexBox";
import ColorForm from "components/molecules/ColorForm";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import useHolidayOptions from "hooks/useHolidayOptions";
import usePaidHolidayPatternOptions from "hooks/usePaidHolidayPatternOptions";
import { useHqStaffsOptions } from "hooks/useStaffsOptions";
import { AttendHoliday, NoticeStaff } from "domain/employment";
import { gridCol } from "components/styles";
import BodyText from "components/atoms/BodyText";
import { css, jsx } from "@emotion/core";
import RadioSelectForm from "components/molecules/RadioSelectForm";

const FLEX_TIME = 3;

const EmploymentVacationSettings: React.FC<{
  formik: any;
}> = ({ formik }) => {
  const holidayOptions = useHolidayOptions();
  const paidHolidayPatternOptions = usePaidHolidayPatternOptions();
  const hqStaffsOptions = useHqStaffsOptions();

  const arrayLegalDate = [
    "letPubBeLegalHoliday",
    "letSatBeLegalHoliday",
    "letSunBeLegalHoliday",
    "letMonBeLegalHoliday",
    "letTueBeLegalHoliday",
    "letWedBeLegalHoliday",
    "letTurBeLegalHoliday",
    "letFriBeLegalHoliday",
  ];
  const arrayNonLegalDate = [
    "letPubBeNonLegalHoliday",
    "letSatBeNonLegalHoliday",
    "letSunBeNonLegalHoliday",
    "letMonBeNonLegalHoliday",
    "letTueBeNonLegalHoliday",
    "letWedBeNonLegalHoliday",
    "letTurBeNonLegalHoliday",
    "letFriBeNonLegalHoliday",
  ];

  function renderListOption(number: number) {
    const arr = [];
    for (var i = 1; i <= number; i++) {
      arr.push(new Option(i.toString()));
    }
    return arr;
  }

  return (
    <React.Fragment>
      <FormTitle title="休暇設定" bold={true} customStyle={css({ marginLeft: "0" })} />
      <div>
        <VerticalLabelMutilSelectForm
          label="使用する休暇"
          name="holidays"
          values={formik.values.holidays.map((holiday: AttendHoliday) =>
            holidayOptions.find((item) => holiday.holidayId === item.value),
          )}
          setValue={(val: any) => {
            if (val !== "") {
              formik.setFieldValue(
                "holidays",
                val
                  .slice(0, -1)
                  .split(",")
                  .map((id: string) => ({ holidayId: id })),
              );
              formik.setFieldValue("attendHolidayIds", val.slice(0, -1).split(","));
            } else {
              formik.setFieldValue("holidays", []);
            }
          }}
          options={holidayOptions}
          isMulti={true}
        />
        <FormField>
          <div style={{ width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="休日出勤申請機能"
              name="necessaryHolidayWorkApplication"
              options={[
                {
                  label: "使用しない",
                  value: "false",
                },
                {
                  label: "使用する",
                  value: "true",
                },
              ]}
              value={String(formik.values.necessaryHolidayWorkApplication)}
              setValue={(val) => formik.setFieldValue("necessaryHolidayWorkApplication", val)}
              isSearchable={false}
            />
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid06 }}>
            <ColorForm
              name="holidayWorkBgColor"
              label="休日出勤の背景色"
              color={formik.values.holidayWorkBgColor}
              isFullW={true}
              onChange={(color) => {
                formik.setFieldValue("holidayWorkBgColor", color);
              }}
            />
            <ColorForm
              name="holidayWorkForeColor"
              label="休日出勤の文字色"
              color={formik.values.holidayWorkForeColor}
              isFullW={true}
              onChange={(color) => {
                formik.setFieldValue("holidayWorkForeColor", color);
              }}
            />
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="休日を法定休日と所定休日で区別する"
              name="distinctionHoliday"
              options={[
                {
                  label: "しない",
                  value: "false",
                },
                {
                  label: "する",
                  value: "true",
                },
              ]}
              value={String(formik.values.distinctionHoliday)}
              setValue={(val) => formik.setFieldValue("distinctionHoliday", val)}
              isSearchable={false}
            />
          </div>
        </FormField>
        <div style={[0, 2, FLEX_TIME].includes(Number(formik.values.workSystem)) ? {} : { display: "none" }}>
          <FormField>
            <MultipleCheckboxForm
              name="letBeLegalHoliday"
              label="特定の曜日を法定休日とする"
              items={[
                {
                  id: "letPubBeLegalHoliday",
                  label: "祝日",
                  value: formik.values.letPubBeLegalHoliday,
                },
                {
                  id: "letSatBeLegalHoliday",
                  label: "土曜日",
                  value: formik.values.letSatBeLegalHoliday,
                },
                {
                  id: "letSunBeLegalHoliday",
                  label: "日曜日",
                  value: formik.values.letSunBeLegalHoliday,
                },
                {
                  id: "letMonBeLegalHoliday",
                  label: "月曜日",
                  value: formik.values.letMonBeLegalHoliday,
                },
                {
                  id: "letTueBeLegalHoliday",
                  label: "火曜日",
                  value: formik.values.letTueBeLegalHoliday,
                },
                {
                  id: "letWedBeLegalHoliday",
                  label: "水曜日",
                  value: formik.values.letWedBeLegalHoliday,
                },
                {
                  id: "letTurBeLegalHoliday",
                  label: "木曜日",
                  value: formik.values.letTurBeLegalHoliday,
                },
                {
                  id: "letFriBeLegalHoliday",
                  label: "金曜日",
                  value: formik.values.letFriBeLegalHoliday,
                },
              ]}
              setValue={(e) => {
                let indexDay = arrayLegalDate.indexOf(e.target.id);
                if (e.target.value === "false") {
                  formik.setFieldValue(arrayNonLegalDate[indexDay], false);
                }
                formik.setFieldValue(String(e.target.id), !(e.target.value === "true"));
              }}
            />
          </FormField>
        </div>

        <div
          style={
            [0, 2, FLEX_TIME].includes(Number(formik.values.workSystem)) &&
            String(formik.values.distinctionHoliday) === "true"
              ? {}
              : { display: "none" }
          }
        >
          <FormField>
            <MultipleCheckboxForm
              name="letBeNonLegalHoliday"
              label="特定の曜日を所定休日とする"
              items={[
                {
                  id: "letPubBeNonLegalHoliday",
                  label: "祝日",
                  value: formik.values.letPubBeNonLegalHoliday,
                },
                {
                  id: "letSatBeNonLegalHoliday",
                  label: "土曜日",
                  value: formik.values.letSatBeNonLegalHoliday,
                },
                {
                  id: "letSunBeNonLegalHoliday",
                  label: "日曜日",
                  value: formik.values.letSunBeNonLegalHoliday,
                },
                {
                  id: "letMonBeNonLegalHoliday",
                  label: "月曜日",
                  value: formik.values.letMonBeNonLegalHoliday,
                },
                {
                  id: "letTueBeNonLegalHoliday",
                  label: "火曜日",
                  value: formik.values.letTueBeNonLegalHoliday,
                },
                {
                  id: "letWedBeNonLegalHoliday",
                  label: "水曜日",
                  value: formik.values.letWedBeNonLegalHoliday,
                },
                {
                  id: "letTurBeNonLegalHoliday",
                  label: "木曜日",
                  value: formik.values.letTurBeNonLegalHoliday,
                },
                {
                  id: "letFriBeNonLegalHoliday",
                  label: "金曜日",
                  value: formik.values.letFriBeNonLegalHoliday,
                },
              ]}
              setValue={(e) => {
                let indexDay = arrayNonLegalDate.indexOf(e.target.id);
                if (e.target.value === "false") {
                  formik.setFieldValue(arrayLegalDate[indexDay], false);
                }
                formik.setFieldValue(String(e.target.id), !(e.target.value === "true"));
              }}
            />
          </FormField>
        </div>
        <FormField>
          <div style={{ width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="自動有給管理"
              name="autoPaidManageFunc"
              options={[
                {
                  label: "しない",
                  value: "false",
                },
                {
                  label: "する",
                  value: "true",
                },
              ]}
              value={String(formik.values.autoPaidManageFunc)}
              setValue={(val) => formik.setFieldValue("autoPaidManageFunc", val)}
              isSearchable={false}
            />
          </div>
        </FormField>
        <div style={String(formik.values.autoPaidManageFunc) === "true" ? {} : { display: "none" }}>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="有給付与方法"
                name="paidProvisionMethod"
                options={[
                  {
                    label: "有給起算日基準で付与",
                    value: "0",
                  },
                  {
                    label: "指定日で付与",
                    value: "1",
                  },
                ]}
                value={String(formik.values.paidProvisionMethod)}
                setValue={(val) => formik.setFieldValue("paidProvisionMethod", val)}
                isSearchable={false}
              />
            </div>
          </FormField>
          <div style={String(formik.values.paidProvisionMethod) === "1" ? {} : { display: "none" }}>
            <FormField>
              <VerticalLabelFormLayout
                label={<FormLabel label="有給付与指定日" required={false} />}
                input={
                  <FlexBox>
                    <BodyText customStyle={css({ marginRight: "5px" })}>毎年</BodyText>
                    <div style={{ width: "65px" }}>
                      <VerticalLabelSelectForm
                        label=""
                        name="paidBonusAwardDateMonth"
                        value={
                          formik.values.paidBonusAwardDateMonth === 0
                            ? "1"
                            : formik.values.paidBonusAwardDateMonth.toString()
                        }
                        setValue={(val: string) => {
                          formik.setFieldValue("paidBonusAwardDateMonth", Number(val));
                        }}
                        options={[
                          new Option("1"),
                          new Option("2"),
                          new Option("3"),
                          new Option("4"),
                          new Option("5"),
                          new Option("6"),
                          new Option("7"),
                          new Option("8"),
                          new Option("9"),
                          new Option("10"),
                          new Option("11"),
                          new Option("12"),
                        ]}
                        isSearchable={false}
                      />
                    </div>
                    <BodyText customStyle={css({ margin: "5px" })}>月</BodyText>
                    <div style={{ width: "65px" }}>
                      <VerticalLabelSelectForm
                        label=""
                        name="paidBonusAwardDateDay"
                        value={
                          formik.values.paidBonusAwardDateDay === 0
                            ? "1"
                            : formik.values.paidBonusAwardDateDay.toString()
                        }
                        setValue={(val: string) => {
                          formik.setFieldValue("paidBonusAwardDateDay", Number(val));
                        }}
                        options={[
                          new Option("1"),
                          new Option("2"),
                          new Option("3"),
                          new Option("4"),
                          new Option("5"),
                          new Option("6"),
                          new Option("7"),
                          new Option("8"),
                          new Option("9"),
                          new Option("10"),
                          new Option("11"),
                          new Option("12"),
                          new Option("13"),
                          new Option("14"),
                          new Option("15"),
                          new Option("16"),
                          new Option("17"),
                          new Option("18"),
                          new Option("19"),
                          new Option("20"),
                          new Option("21"),
                          new Option("22"),
                          new Option("23"),
                          new Option("24"),
                          new Option("25"),
                          new Option("26"),
                          new Option("27"),
                          new Option("28"),
                          new Option("29"),
                          new Option("30"),
                          new Option("31"),
                        ]}
                      />
                    </div>
                    <BodyText customStyle={css({ marginLeft: "5px" })}>日</BodyText>
                  </FlexBox>
                }
              />
            </FormField>

            <VerticalLabelFormLayout
              label={<FormLabel customStyle={css({ color: "#666666" })} label="初回付与と優先順位" />}
              input={
                <FlexBox
                  customStyle={css({
                    color: "#666666",
                    fontSize: "14px",
                  })}
                >
                  <div style={{ marginRight: "10px" }}>初回付与は有給起算日から</div>
                  <div style={{ width: "200px", marginRight: "10px" }}>
                    <VerticalLabelSelectForm
                      label=""
                      name="paidHolidayInitialGrantMonths"
                      value={String(formik.values.paidHolidayInitialGrantMonths)}
                      setValue={(val: string) => formik.setFieldValue("paidHolidayInitialGrantMonths", val)}
                      options={[
                        {
                          label: "0ヶ月",
                          value: "0",
                        },
                        {
                          label: "1ヶ月",
                          value: "1",
                        },
                        {
                          label: "2ヶ月",
                          value: "2",
                        },
                        {
                          label: "3ヶ月",
                          value: "3",
                        },
                        {
                          label: "4ヶ月",
                          value: "4",
                        },
                        {
                          label: "5ヶ月",
                          value: "5",
                        },
                        {
                          label: "6ヶ月",
                          value: "6",
                        },
                      ]}
                      isSearchable={false}
                    />
                  </div>
                  <div style={{ margin: "0 10px" }}>で</div>
                  <HourInput
                    name="paidHolidayInitialGrantDays"
                    value={formik.values.paidHolidayInitialGrantDays}
                    label="日"
                    onChange={formik.handleChange}
                    customStyleLabel={css({ color: "#666666" })}
                    height="42px"
                  />
                  <div style={{ marginLeft: "10px" }}>付与する</div>
                </FlexBox>
              }
            />
            <FormField>
              <div style={{ width: gridCol.grid06 }}>
                <VerticalLabelSelectForm
                  label=""
                  name="paidHolidayInitialType"
                  options={[
                    {
                      label: "有給付与指定日を優先",
                      value: "0",
                    },
                    {
                      label: "有給起算日から指定の付与日を優先",
                      value: "1",
                    },
                  ]}
                  value={String(formik.values.paidHolidayInitialType)}
                  setValue={(val) => formik.setFieldValue("paidHolidayInitialType", val)}
                  isSearchable={false}
                />
              </div>
            </FormField>
          </div>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="有給付与パターン"
                name="paidHolidayPatternId"
                value={String(formik.values.paidHolidayPatternId)}
                setValue={(val: string) => formik.setFieldValue("paidHolidayPatternId", val)}
                options={paidHolidayPatternOptions}
              />
            </div>
          </FormField>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="有給付与日数計算方法"
                name="calcMethodPaidGrantDays"
                options={[
                  {
                    label: "週の契約労働日数を使用",
                    value: "0",
                  },
                  {
                    label: "労働日数を使用",
                    value: "1",
                  },
                ]}
                value={String(formik.values.calcMethodPaidGrantDays)}
                setValue={(val) => formik.setFieldValue("calcMethodPaidGrantDays", val)}
                isSearchable={false}
              />
            </div>
          </FormField>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelMutilSelectForm
                label="自動有給付与結果通知先"
                name="noticeStaffs"
                values={formik.values.noticeStaffs.map((noticeStaff: NoticeStaff) =>
                  hqStaffsOptions.find((item) => noticeStaff.staffCode === item.value),
                )}
                setValue={(val: any) => {
                  if (val) {
                    formik.setFieldValue(
                      "noticeStaffs",
                      val
                        .slice(0, -1)
                        .split(",")
                        .map((id: string) => ({ staffCode: id })),
                    );
                  } else {
                    formik.setFieldValue("noticeStaffs", []);
                  }
                }}
                options={hqStaffsOptions}
                isMulti={true}
              />
            </div>
          </FormField>
        </div>

        <FormField customStyle={css({ flexWrap: "wrap" })}>
          <FlexBox customStyle={css({ width: "100%" })}>
            <FormLabel label="有給の時間単位休暇の最大取得日数" />
          </FlexBox>
          <div style={{ width: "104px" }}>
            <HourInput
              name="timeHolidayMaxDay"
              value={formik.values.timeHolidayMaxDay}
              label="日"
              min={0}
              max={99}
              onChange={formik.handleChange}
              customStyleLabel={css({ color: "#666666" })}
              height="42px"
            />
          </div>
        </FormField>

        <FormField>
          <div style={{ width: gridCol.grid06 }}>
            <VerticalLabelSelectForm
              label="振替申請機能"
              name="useTransferApplication"
              options={[
                {
                  label: "使用しない",
                  value: "0",
                },
                {
                  label: "使用する",
                  value: "1",
                },
              ]}
              value={String(formik.values.useTransferApplication)}
              setValue={(val) => formik.setFieldValue("useTransferApplication", val)}
              isSearchable={false}
            />
          </div>
        </FormField>

        <div style={String(formik.values.useTransferApplication) === "1" ? {} : { display: "none" }}>
          <FormField customStyle={css({ display: "block" })}>
            <div style={{ width: gridCol.grid06 }}>
              <RadioSelectForm
                label="振替日の制限"
                name="useChoosePeriodTime"
                items={[
                  {
                    label: "しない",
                    value: "false",
                  },
                  {
                    label: "する",
                    value: "true",
                  },
                ]}
                value={String(formik.values.useChoosePeriodTime)}
                setValue={formik.handleChange}
              />
            </div>
            {String(formik.values.useChoosePeriodTime) === "true" && (
              <FlexBox customStyle={css({ marginTop: "15px" })}>
                <BodyText customStyle={css({ marginRight: "5px" })}>振替申請の申請期間を前後</BodyText>
                <div style={{ width: "73px" }}>
                  <HourInput
                    name="periodTime"
                    value={formik.values.periodTime}
                    label=""
                    min={1}
                    onChange={formik.handleChange}
                  />
                </div>
                <BodyText customStyle={css({ margin: "5px" })}>日以内とする</BodyText>
              </FlexBox>
            )}
          </FormField>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <ColorForm
                name="transferWorkBgColor"
                label="振替出勤日の背景色"
                color={formik.values.transferWorkBgColor}
                isFullW={true}
                onChange={(color) => {
                  formik.setFieldValue("transferWorkBgColor", color);
                }}
              />
              <ColorForm
                name="transferWorkForecolor"
                label="振替出勤日の文字色"
                color={formik.values.transferWorkForeColor}
                isFullW={true}
                onChange={(color) => {
                  formik.setFieldValue("transferWorkForeColor", color);
                }}
              />
              <ColorForm
                name="transferHolidayBgColor"
                label="振替休日の背景色"
                color={formik.values.transferHolidayBgColor}
                isFullW={true}
                onChange={(color) => {
                  formik.setFieldValue("transferHolidayBgColor", color);
                }}
              />
              <ColorForm
                name="transferHolidayForeColor"
                label="振替休日の文字色"
                color={formik.values.transferHolidayForeColor}
                isFullW={true}
                onChange={(color) => {
                  formik.setFieldValue("transferHolidayForeColor", color);
                }}
              />
            </div>
          </FormField>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmploymentVacationSettings;
