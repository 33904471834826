/* eslint-disable array-callback-return */
import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { createOrUpdateVacation, getAttendVacation } from 'api/vacation';
import { useHistory } from 'react-router-dom';
import VacationDomain, { HolidayType } from 'domain/vacation';
import useToastNotification from 'hooks/useToastNotification';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import useExecutiveOptions from 'hooks/useExecutiveOptions';
import { useStaffsForOrgCodeOptions } from 'hooks/useStaffsOptions';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

type VacationDomainKey = keyof Pick<VacationDomain, 'holidayCode'|'holidayName'>;

export const useVacationAddForm = (vacationId?: string) => {
  const [blocking, setBlocking] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [vacation, setVacation] = useState(VacationDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  
  const orgTreesOptions = useOrgTreesOptionsAll(undefined, undefined, undefined, true);
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);

  const sessionStorageOrgCode2 = sessionStorage.getItem('loginUser.orgCode') || '';
  const [orgCode2, setOrgCode2] = useState(sessionStorageOrgCode2);

  const executiveOptions = useExecutiveOptions(true);
  const [executiveId, setExecutiveId] = useState<any>(sessionStorage.getItem('loginUser.executiveId') || '');

  const staffsForOrgCodeOptions = useStaffsForOrgCodeOptions(orgCode2);
  const sessionStoragestaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const [staffCode, setStaffCode] = useState(sessionStoragestaffCode);

  const { detailRole } = useGetDetailRole(setBlocking, functionCode.vacationMaster);

  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.holidayCode === null) {
      errorNotification('休暇コードを入力してください。');
      return;
    }
    if (val.holidayName === null) {
      errorNotification('休暇名を入力してください。');
      return;
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    let submitVacation : any;
    if (vacationId) {
      submitVacation = val.getRawDataWithoutNullData();
      submitVacation.createUser = staffName;
      submitVacation.updateUser = staffName;
    } else {
      if (Number(val.holidayType) === HolidayType.TEXIU) {
        submitVacation = val.getRawDataCreationTexiu();
      } else {
        submitVacation = val.getRawDataCreation();
      }

      submitVacation.createUser = staffName;
      submitVacation.updateUser = staffName;
    }
    submitVacation.noticeStaffs = submitVacation.noticeStaffs.toString();

    if( [HolidayType.PAID_1_DAY, HolidayType.TAKECARE_RELATIVE, HolidayType.TAKECARE_CHILD, HolidayType.TEXIU].indexOf(Number(val.holidayType)) !== -1){
      submitVacation.deemedWorkingHours = 0;
      submitVacation.deemedWorkingMinutes = 0;
      submitVacation.useEmploymentDeemedWorkingHours = false;
    }

    if( [HolidayType.TEXIU].indexOf(Number(val.holidayType)) !== -1){
      submitVacation.validityPeriodStartMonth = submitVacation.validityPeriodStartMonth || 1;
      submitVacation.validityPeriodStartDay = submitVacation.validityPeriodStartDay || 1;
      submitVacation.validityPeriodEndMonth = submitVacation.validityPeriodEndMonth || 12;
      submitVacation.validityPeriodEndDay = submitVacation.validityPeriodEndDay || 31;
    }

    if( [HolidayType.TEXIU, HolidayType.TAKECARE_RELATIVE, HolidayType.TAKECARE_CHILD, HolidayType.RESERVED_PAID].indexOf(Number(val.holidayType)) !== -1){
      if (submitVacation.paidFlag === 0) {
        submitVacation.isWorkTime = 0;
      }
    } else if (HolidayType.PAID_1_DAY === Number(val.holidayType) ) {
      submitVacation.paidFlag = 1;
    } else {
      submitVacation.paidFlag = 0;
      submitVacation.isWorkTime = 0;
    }


    createOrUpdateVacation(companyCode, false, submitVacation)
      .then((response: any) => {
        successNotification('登録しました。');
        history.push('/vacationMaster');
      })
      .catch((error: any) => {
        if (error.response.status === 406) {
          const errorString : string = error.response.data.errors[0].defaultMessage;
          setVacation(submitVacation);
          setConfirmModalOpen(false);
          errorNotification(errorString);
        } else {
          setConfirmModalOpen(false);
          if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
            const listErr = error.response.data.errors;
            let stringErr = '';
            listErr.map((element: any, index: any) => {
              stringErr += `${element.defaultMessage}<br />`;
            });
            errorNotification(stringErr);
          } else {
            errorNotification('Something wrong. please try again');
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: vacation || VacationDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (vacationId !== undefined) {
      setBlocking(true);
      getAttendVacation(companyCode, vacationId).then((response: any) => {
        setVacation(new VacationDomain(response));
        formik.setValues(new VacationDomain(response));
        //set value default when api return []
        // formik.setValues(VacationDomain.generateInitial());
        setBlocking(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacationId]);
  console.log("formil.value", formik.values);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
    setConfirmModalResendOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const submitConfirmResendModal = useCallback(() => {
    createOrUpdateVacation(companyCode, true, vacation)
      .then((response: any) => {
        history.goBack();
      })
      .catch((error2: any) => {
        if (error2.response.data.errors.length !== 0) {
          errorNotification(error2.response.data.errors[error2.response.data.errors.length - 1]
            .defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    setConfirmModalResendOpen(false);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode, history, vacation]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    confirmModalResendOpen,
    closeConfirmResendModal,
    submitConfirmResendModal,
    confirmModalContent,
    history,
    blocking,
    orgTreesOptions,
    executiveOptions,
    staffsForOrgCodeOptions,
    orgCode, setOrgCode,
    executiveId, setExecutiveId,
    staffCode, setStaffCode,
    orgCode2, setOrgCode2,
    detailRole,
  };
};


export default useVacationAddForm;
