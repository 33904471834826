import Actions, { types } from './actions';
import { AppBarType } from '.';

const initialState: AppBarType = {
  photoPath: "",
};

const reducer = (state = initialState, action: Actions): AppBarType => {
  const newState = { ...state };
  switch (action.type) {
    case types.CHANGE_AVATAR:
      newState.photoPath = action.payload;
      return newState;
    default:
      return state;
  }
};

export default reducer;
