import { useState, useCallback } from "react";
import { useFormik } from "formik";
import { handleConfirmRecorder, handleSubmitFaceImage } from "api/timeRecorder";
import useToastNotification from "hooks/useToastNotification";

import StampHistoryListRecorderDomain from "domain/timeRecorder/stampHistoryListRecorderDomain";
import ConfirmRecorderByFaceDomain from "domain/timeRecorder/confirmRecorderByFaceDomain";
import { CurrentTimeDomain } from "domain/master/attend/currentTime";

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";

const { errorNotification, successNotification } = useToastNotification();

export const useFaceRecorder = (
  dataSettings: any,
  resultCurrentTime: CurrentTimeDomain,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  shopCode: string,
  fetchHistoryData: any,
) => {
  const { stampOrgCode, setStampOrgCode, stampOrgName, setStampOrgName, loginOrgCode, loginOrgName } = dataSettings;

  // get local storage
  const storageData = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  const { specifyCode, companyCode, stampByBelongOrg } = storageData ? JSON.parse(storageData) : "";
  const historyListRecorderInit = StampHistoryListRecorderDomain.generateInitial();
  const [stampHistoryList, setStampHistoryList] = useState<Array<StampHistoryListRecorderDomain>>([
    historyListRecorderInit,
  ]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSelectBaby, setIsSelectBaby] = useState(false);

  const [staffName, setStaffName] = useState("");
  const [staffCode, setStaffCode] = useState("");
  const [lastStampType, setLastStampType] = useState<number>();
  const [businessCnt, setBusinessCnt] = useState(0);
  const [businessList, setBusinessList] = useState();

  // const timeCount = 0;
  let serverTime: any;
  const [serverTimeState] = useState(new Date());

  // Image
  const [tmpFilePath, setTmpFilePath] = useState("");
  const [stampStaffList, setStampStaffList] = useState<any>();
  const [useRecess, setUseRecess] = useState(true);
  const [faceId, setFaceId] = useState("");
  const [mistake, setMistake] = useState<any>("");
  const [similarity, setSimilarity] = useState("");
  const [photoFullpath, setPhotoFullpath] = useState("");

  //Use New UI Remote
  const [isRemote, setIsRemote] = useState<boolean>(false);

  const handleSubmitFace = async (file: any) => {
    setIsLoading(true);
    let formDataImage: any = {
      orgCode: loginOrgCode,
      orgName: loginOrgName,
      loginOrgCode,
      loginOrgName,
      tmpFilePath,
      staffName,
      faceId,
      mistake,
      stampMode: 1,
      stampByBelongOrg,
      similarity,
      stampTime: String(resultCurrentTime.serverDateTime),
      photo: file,
    };
    try {
      await handleSubmitFaceImage(shopCode, companyCode, formDataImage).then((response: any) => {
        if (!response.errors) {
          setTmpFilePath(response.tmpFilePath);
          setStampStaffList(response.stampStaffList);
          setMistake(response.mistake);
          if (response.stampStaffList.length === 1) {
            let stampStaffData = response.stampStaffList[0];
            setLastStampType(stampStaffData.lastStampType);
            setStaffName(stampStaffData.staffName);
            setStaffCode(stampStaffData.staffCode);
            setStampOrgCode(stampStaffData.orgCode);
            setStampOrgName(stampStaffData.orgName);
            setFaceId(stampStaffData.faceId);
            setBusinessCnt(stampStaffData.businessList.length);
            setBusinessList(stampStaffData.businessList);
            setUseRecess(stampStaffData.useRecess);
            setSimilarity(stampStaffData.similarity);
            setPhotoFullpath(stampStaffData.photoFullpath);
            setIsRemote(stampStaffData.isRemote);
            setIsConfirm(true);
            setIsSelectBaby(false);
          } else {
            setIsSelectBaby(true);
          }
        }
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        const err = error.response.data.message.replace(/<br>/gi, "\n");
        errorNotification(err);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const handleSelectBaby = (index: number) => {
    const stampStaffData = stampStaffList[index];
    // eslint-disable-next-line no-shadow

    setLastStampType(stampStaffData.lastStampType);
    setStaffName(stampStaffData.staffName);
    setStaffCode(stampStaffData.staffCode);
    setStampOrgCode(stampStaffData.orgCode);
    setStampOrgName(stampStaffData.orgName);
    setFaceId(stampStaffData.faceId);
    setBusinessCnt(stampStaffData.businessList.length);
    setBusinessList(stampStaffData.businessList);
    setUseRecess(stampStaffData.useRecess);
    setSimilarity(stampStaffData.similarity);
    setPhotoFullpath(stampStaffData.photoFullpath);
    setIsRemote(stampStaffData.isRemote);
    setIsConfirm(true);
    setIsSelectBaby(false);
  };

  // Recorder

  const onSubmitConfirmFace = async (values: ConfirmRecorderByFaceDomain) => {
    setIsLoading(true);
    handleConfirmRecorder(shopCode, companyCode, values.getRawData(), 0)
      .then((response: any) => {
        successNotification(response.stampResultInfo.replace("<h4>", "").replace("</h4>", ""));
        setStaffName("");
        fetchHistoryData();
        formik.resetForm();
        setIsConfirm(false);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setStaffName("");
        setIsLoading(false);
        if (error.response.status === 400) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            errorNotification(error.response.data.errors.map((x: any) => x.defaultMessage));
          } else if (error.response.data.defaultMessage) {
            errorNotification(error.response.data.defaultMessage);
          } else {
            errorNotification(error.response.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByFaceDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirmFace(values);
    },
  });

  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, flagRemote?: boolean) => {
      if (flagRemote) {
        formik.setFieldValue("isRemote", true);
      } else {
        formik.setFieldValue("isRemote", isRemote);
      }
      formik.setFieldValue("orgCode", loginOrgCode);
      formik.setFieldValue("orgName", loginOrgName);
      formik.setFieldValue("loginOrgName", loginOrgName);
      formik.setFieldValue("loginOrgCode", loginOrgCode);
      formik.setFieldValue("staffName", staffName);
      formik.setFieldValue("tmpFilePath", tmpFilePath);
      formik.setFieldValue("faceId", faceId);
      formik.setFieldValue("similarity", similarity);
      formik.setFieldValue("mistake", mistake || "");
      formik.setFieldValue("stampTime", String(serverTimeState));
      formik.setFieldValue("stampType", stampType);
      formik.setFieldValue("businessId", businessId);
      formik.setFieldValue("stampMode", 1);
      formik.submitForm();
    },
    [
      isRemote,
      faceId,
      formik,
      loginOrgCode,
      loginOrgName,
      mistake,
      serverTimeState,
      similarity,
      staffName,
      stampOrgCode,
      stampOrgName,
      tmpFilePath,
    ],
  );

  const handleMistake = () => {
    if (stampStaffList.length === 1) {
      setIsSelectBaby(false);
      setIsConfirm(false);
      errorNotification(
        "類似するスタッフが存在しませんでした。<br>スタッフ設定画面にて顔写真を更新してから打刻を行ってください。",
      );
      setPhotoFullpath("");
      formik.resetForm();
      setStaffName("");
    } else {
      setIsSelectBaby(true);
      setIsConfirm(false);
    }
  };
  return {
    specifyCode,
    companyCode,
    stampByBelongOrg,
    stampOrgName,
    loginOrgCode,
    loginOrgName,
    serverTime,
    stampOrgCode,
    stampHistoryList,
    serverTimeState,
    isConfirm,
    staffName,
    staffCode,
    businessCnt,
    lastStampType,
    setLastStampType,
    businessList,
    photoFullpath,
    useRecess,
    formik,
    tmpFilePath,
    faceId,
    similarity,
    mistake,
    handleMistake,
    setIsConfirm,
    setStampHistoryList,
    shopCode,
    errorNotification,
    setPhotoFullpath,
    handleSubmitFace,
    handleRecorder,
    isSelectBaby,
    stampStaffList,
    handleSelectBaby,
  };
};

export const useFaceRecorderEmptySpecifyCode = (
  resultCurrentTime: CurrentTimeDomain,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  fetchHistoryData: any,
) => {
  // get local storage
  const storageData = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  const { specifyCode, companyCode, stampByBelongOrg } = storageData ? JSON.parse(storageData) : "";
  const historyListRecorderInit = StampHistoryListRecorderDomain.generateInitial();
  const [stampHistoryList, setStampHistoryList] = useState<Array<StampHistoryListRecorderDomain>>([
    historyListRecorderInit,
  ]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSelectBaby, setIsSelectBaby] = useState(false);

  const [staffName, setStaffName] = useState("");
  const [staffCode, setStaffCode] = useState("");
  const [lastStampType, setLastStampType] = useState<number>();
  const [businessCnt, setBusinessCnt] = useState(0);
  const [businessList, setBusinessList] = useState();

  // const timeCount = 0;
  let serverTime: any;
  const [serverTimeState] = useState(new Date());

  // Image
  const [tmpFilePath, setTmpFilePath] = useState("");
  const [stampStaffList, setStampStaffList] = useState<any>();
  const [useRecess, setUseRecess] = useState(true);
  const [faceId, setFaceId] = useState("");
  const [mistake, setMistake] = useState<any>("");
  const [similarity, setSimilarity] = useState("");
  const [photoFullpath, setPhotoFullpath] = useState("");

  const [stampOrgCode, setStampOrgCode] = useState("");
  const [stampOrgName, setStampOrgName] = useState("");
  const [loginOrgCode, setLoginOrgCode] = useState("");
  const [loginOrgName, setLoginOrgName] = useState("");

  //Use New UI Remote
  const [isRemote, setIsRemote] = useState<boolean>(false);

  const handleSubmitFace = async (file: any) => {
    setIsLoading(true);
    let formDataImage: any = {
      orgCode: "",
      orgName: "",
      loginOrgCode: "",
      loginOrgName: "",
      tmpFilePath,
      staffName,
      faceId,
      mistake,
      stampMode: 1,
      stampByBelongOrg,
      similarity,
      stampTime: String(resultCurrentTime.serverDateTime),
      photo: file,
    };
    try {
      await handleSubmitFaceImage("", companyCode, formDataImage).then((response: any) => {
        if (!response.errors) {
          setTmpFilePath(response.tmpFilePath);
          setStampStaffList(response.stampStaffList);
          setMistake(response.mistake);
          if (response.stampStaffList.length === 1) {
            let stampStaffData = response.stampStaffList[0];
            setLastStampType(stampStaffData.lastStampType);
            setStaffName(stampStaffData.staffName);
            setStaffCode(stampStaffData.staffCode);
            setStampOrgCode(stampStaffData.orgCode);
            setStampOrgName(stampStaffData.orgName);
            setLoginOrgCode(stampStaffData.orgCode);
            setLoginOrgName(stampStaffData.orgName);
            setFaceId(stampStaffData.faceId);
            setBusinessCnt(stampStaffData.businessList.length);
            setBusinessList(stampStaffData.businessList);
            setUseRecess(stampStaffData.useRecess);
            setSimilarity(stampStaffData.similarity);
            setPhotoFullpath(stampStaffData.photoFullpath);
            setIsRemote(stampStaffData.isRemote);
            setIsConfirm(true);
            setIsSelectBaby(false);
          } else {
            setIsSelectBaby(true);
          }
        }
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        const err = error.response.data.message.replace(/<br>/gi, "\n");
        errorNotification(err);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const handleSelectBaby = (index: number) => {
    const stampStaffData = stampStaffList[index];
    // eslint-disable-next-line no-shadow

    setLastStampType(stampStaffData.lastStampType);
    setStaffName(stampStaffData.staffName);
    setStaffCode(stampStaffData.staffCode);
    setStampOrgCode(stampStaffData.orgCode);
    setStampOrgName(stampStaffData.orgName);
    setLoginOrgCode(stampStaffData.orgCode);
    setLoginOrgName(stampStaffData.orgName);
    setFaceId(stampStaffData.faceId);
    setBusinessCnt(stampStaffData.businessList.length);
    setBusinessList(stampStaffData.businessList);
    setUseRecess(stampStaffData.useRecess);
    setSimilarity(stampStaffData.similarity);
    setPhotoFullpath(stampStaffData.photoFullpath);
    setIsRemote(stampStaffData.isRemote);
    setIsConfirm(true);
    setIsSelectBaby(false);
  };

  // Recorder

  const onSubmitConfirmFace = async (values: ConfirmRecorderByFaceDomain) => {
    setIsLoading(true);
    handleConfirmRecorder("", companyCode, values.getRawData(), 0)
      .then((response: any) => {
        successNotification(response.stampResultInfo.replace("<h4>", "").replace("</h4>", ""));
        setStaffName("");
        fetchHistoryData();
        formik.resetForm();
        setIsConfirm(false);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setStaffName("");
        setIsLoading(false);
        if (error.response.status === 400) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            errorNotification(error.response.data.errors.map((x: any) => x.defaultMessage));
          } else if (error.response.data.defaultMessage) {
            errorNotification(error.response.data.defaultMessage);
          } else {
            errorNotification(error.response.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByFaceDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirmFace(values);
    },
  });

  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, flagRemote?: boolean) => {
      if (flagRemote) {
        formik.setFieldValue("isRemote", true);
      } else {
        formik.setFieldValue("isRemote", isRemote);
      }
      formik.setFieldValue("orgCode", loginOrgCode);
      formik.setFieldValue("orgName", loginOrgName);
      formik.setFieldValue("loginOrgName", loginOrgName);
      formik.setFieldValue("loginOrgCode", loginOrgCode);
      formik.setFieldValue("staffName", staffName);
      formik.setFieldValue("tmpFilePath", tmpFilePath);
      formik.setFieldValue("faceId", faceId);
      formik.setFieldValue("similarity", similarity);
      formik.setFieldValue("mistake", mistake || "");
      formik.setFieldValue("stampTime", String(serverTimeState));
      formik.setFieldValue("stampType", stampType);
      formik.setFieldValue("businessId", businessId);
      formik.setFieldValue("stampMode", 1);
      formik.submitForm();
    },
    [
      isRemote,
      faceId,
      formik,
      loginOrgCode,
      loginOrgName,
      mistake,
      serverTimeState,
      similarity,
      staffName,
      stampOrgCode,
      stampOrgName,
      tmpFilePath,
    ],
  );

  const handleMistake = () => {
    if (stampStaffList.length === 1) {
      setIsSelectBaby(false);
      setIsConfirm(false);
      errorNotification(
        "類似するスタッフが存在しませんでした。<br>スタッフ設定画面にて顔写真を更新してから打刻を行ってください。",
      );
      setPhotoFullpath("");
      formik.resetForm();
      setStaffName("");
    } else {
      setIsSelectBaby(true);
      setIsConfirm(false);
    }
  };
  return {
    specifyCode,
    companyCode,
    stampByBelongOrg,
    stampOrgName,
    loginOrgCode,
    loginOrgName,
    serverTime,
    stampOrgCode,
    stampHistoryList,
    serverTimeState,
    isConfirm,
    staffName,
    staffCode,
    businessCnt,
    lastStampType,
    setLastStampType,
    businessList,
    photoFullpath,
    useRecess,
    formik,
    tmpFilePath,
    faceId,
    similarity,
    mistake,
    handleMistake,
    setIsConfirm,
    setStampHistoryList,
    errorNotification,
    setPhotoFullpath,
    handleSubmitFace,
    handleRecorder,
    isSelectBaby,
    stampStaffList,
    handleSelectBaby,
  };
};
export default useFaceRecorder;
