import React from 'react';
import Recorder from 'components/organismos/Recorder';
import { useHistory } from "react-router-dom";
import { checkPermissionAccessRecorder } from 'hooks/checkPermissionAcess'

const RecorderPage = () => {
  const history = useHistory();

  if(!checkPermissionAccessRecorder()){
    history.push('/404');
  }
  return <Recorder/>
};

export default RecorderPage;
