/** @jsx jsx */
import React, { useContext } from "react";
import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import { grayScale } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import { Column } from "react-table";
import { useGrantHistoryDomainForm, useDownloadHolidayManagementGrantHistory } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import CalendarForm from "components/molecules/CalendarForm";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import BlockUI from "components/molecules/BlockUi";
import StockPaidHolidayGrantHistoryDomain from "domain/master/holidayManagement/stockpaidHolidayGrantHistory";
import Checkbox from "components/molecules/Checkbox";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { HolidayManagementContext } from "../../HolidayManagementForm";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "200px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
    color: "#666666",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
};

const GrantHistoryForm: React.FC<{ orgTreesOptions: any }> = ({ orgTreesOptions }) => {
  const {
    paidHolidayGrantHistoryList,
    orgCode,
    setOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    underFlag,
    setUnderFlag,
  } = useGrantHistoryDomainForm();

  const { downloadHolidayManagementGrantHistory, blocking } = useDownloadHolidayManagementGrantHistory(
    orgCode,
    targetDateFrom,
    targetDateTo,
    underFlag,
  );
  const contextObject = useContext(HolidayManagementContext);

  //
  const columns: Array<Column<StockPaidHolidayGrantHistoryDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: StockPaidHolidayGrantHistoryDomain;
          };
          data: Array<StockPaidHolidayGrantHistoryDomain>;
        }) => (
          <FlexBox>
            {cell.row.original.orgCode} {cell.row.original.orgName}
          </FlexBox>
        ),
      },
      {
        Header: "スタッフ",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: StockPaidHolidayGrantHistoryDomain;
          };
          data: Array<StockPaidHolidayGrantHistoryDomain>;
        }) => (
          <FlexBox>
            {cell.row.original.staffCode} {cell.row.original.staffName}
          </FlexBox>
        ),
      },
      {
        Header: "付与日",
        id: "grantDateToStr",
        accessor: "grantDateToStr",
      },
      {
        Header: "付与日数",
        id: "grantDaysNum",
        accessor: "grantDaysNum",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: StockPaidHolidayGrantHistoryDomain;
          };
          data: Array<StockPaidHolidayGrantHistoryDomain>;
        }) => <FlexBox customStyle={styles.center}>{cell.row.original.grantDaysNum}</FlexBox>,
      },
      {
        Header: "付与時残日数",
        id: "remainDaysNum",
        accessor: "remainDaysNum",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: StockPaidHolidayGrantHistoryDomain;
          };
          data: Array<StockPaidHolidayGrantHistoryDomain>;
        }) => <FlexBox customStyle={styles.center}>{cell.row.original.remainDaysNum}</FlexBox>,
      },
    ],
    [],
  );

  return (
    <BlockUI blocking={blocking}>
      <Accordion text="付与履歴" callBack={() => {}}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="1100px">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                {/* <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateFrom)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateFrom(date)} />
                </div>
                <div css={styles.betweenDays}>
                  ～
                </div>
                <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateTo)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateTo(date)} />
                </div> */}
                <FlexBoxItem>
                  <CalendarRangeV2
                    startDate={new Date(targetDateFrom)}
                    onChangeStart={(date: Date) => setTargetDateFrom(date)}
                    endDate={new Date(targetDateTo)}
                    onChangeEnd={(date) => {
                      if (date) {
                        setTargetDateTo(date);
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode002"
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag"}
                name={"underFlag"}
                label={"配下指定"}
                value={`underFlag`}
                checked={Boolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 150px;
              }
              table th:nth-of-type(2) {
                width: 150px;
              }
              table th:nth-of-type(3) {
                width: 100px;
              }
              table th:nth-of-type(4) {
                width: 100px;
              }
              table th:nth-of-type(5) {
                width: 100px;
              }
              table td:nth-of-type(3) {
                text-align: center;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag === 1 && (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementGrantHistory()}
                  iconType="download"
                  text="ダウンロード"
                />
              </FormSubmitArea>
            )}
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={paidHolidayGrantHistoryList}
                isGlobalFilter={true}
                globalSearchStyle={styles.globalSearch}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
              />
            </div>
          </div>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default GrantHistoryForm;
