import React from "react";
import SidebarTemplate from "components/templates/SidebarTemplate";
import useIsMobile from "hooks/useIsMobile";
import ScheduleWebPage from "components/organismos/master/attend/HopeShiftForm/ScheduleWebPage";
import ScheduleMobilePage from "components/organismos/master/attend/HopeShiftForm/ScheduleMobilePage";

const HopeShiftPage: React.FC = () => {
  const isMobile = useIsMobile();

  return <SidebarTemplate>{isMobile ? <ScheduleMobilePage /> : <ScheduleWebPage />}</SidebarTemplate>;
};

export default HopeShiftPage;
