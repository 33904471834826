/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Table from "components/molecules/Table";
import "./style.scss";
import { isIPad13 } from "react-device-detect";

const TableTotalValueMonthlyShift: React.FC<{ useSales: boolean; summaryInfo: { [key: string]: string } }> = ({
  useSales,
  summaryInfo,
}) => {
  // const prefix = "monthlyShift";
  // const highlightAction = sessionStorage.getItem("highlightAction") || false;
  // const highlightIndex = sessionStorage.getItem("highlightIndex") || "";
  // const elmntLine1 = document.getElementById(prefix + "_line1_" + highlightIndex);
  // const elmntLine2 = document.getElementById(prefix + "_line2_" + highlightIndex);
  // const elmntLine3 = document.getElementById(prefix + "_line3_" + highlightIndex);

  // useEffect(() => {
  //   if (highlightAction === "true") {
  //     if (elmntLine1 && elmntLine2 && elmntLine3) {
  //       elmntLine1.classList.add("hightlight");
  //       elmntLine2.classList.add("hightlight");
  //       elmntLine3.classList.add("hightlight");
  //       elmntLine3.scrollIntoView({ block: "center" });
  //       setTimeout(() => {
  //         elmntLine1.classList.remove("hightlight");
  //         elmntLine2.classList.remove("hightlight");
  //         elmntLine3.classList.remove("hightlight");
  //         sessionStorage.setItem("highlightAction", "false");
  //         sessionStorage.setItem("highlightIndex", "");
  //       }, 3000);
  //     }
  //   }
  // }, [elmntLine1, elmntLine2, elmntLine3, highlightAction]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 8,
        margin: "0 16px",
      }}
    >
      {useSales && (
        <div
          style={{
            maxHeight: isIPad13 ? "auto" : "93vh",
            borderTop: "1px solid #ccc",
          }}
        >
          <Table
            css={css({
              td: { textAlign: "center", lineHeight: "11px" },
              width: "260px",
              border: "none",
            })}
          >
            <thead>
              <tr style={{ lineHeight: 0 }}>
                <Table.HeaderCell colSpan={2} className="border-t-none" customStyle={css({ padding: "14px 0" })}>
                  売上
                </Table.HeaderCell>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Table.Cell style={{ width: 80 }}>前年実績</Table.Cell>
                <Table.Cell style={{ width: 90 }}>{summaryInfo?.salesAchievementTotalPreviousYear || 0}</Table.Cell>
              </tr>

              {/* RowId2 */}
              <tr id={"monthlyShift_line2"}>
                <Table.Cell>予算</Table.Cell>

                <Table.Cell>{summaryInfo?.salesPlanTotal || 0}</Table.Cell>
              </tr>

              {/* RowId3 */}
              <tr id={"monthlyShift_line3"}>
                <Table.Cell>実績</Table.Cell>

                <Table.Cell>{summaryInfo?.salesAchievementTotal || 0}</Table.Cell>
              </tr>

              {/* RowId4 */}
              <tr id={"monthlyShift_line4"}>
                <Table.Cell>予実比</Table.Cell>

                <Table.Cell>{summaryInfo?.salesPlanAchievementRate || "0.00%"}</Table.Cell>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      <div
        style={{
          overflowX: "auto",
          maxHeight: isIPad13 ? "auto" : "93vh",
          borderTop: "1px solid #ccc",
          width: "100%",
        }}
      >
        <Table
          css={css({
            td: { textAlign: "center", lineHeight: "11px" },
            border: "none",
            tableLayout: "auto",
            th: {
              minWidth: "90px", // セルの最小幅を設定
            },
          })}
        >
          <colgroup>
            <col style={{ width: "65px" }} />
          </colgroup>

          <thead>
            <tr style={{ lineHeight: 0, height: 34 }}>
              <Table.HeaderCell className="border-t-none"></Table.HeaderCell>
              <Table.HeaderCell
                colSpan={useSales ? 3 : 2}
                className="border-t-none border-l-none"
                customStyle={css({ padding: "14px 0" })}
              >
                正社員
              </Table.HeaderCell>
              <Table.HeaderCell
                colSpan={useSales ? 3 : 2}
                className="border-t-none border-l-none"
                customStyle={css({ padding: "14px 0" })}
              >
                アルバイト他
              </Table.HeaderCell>
              <Table.HeaderCell
                colSpan={useSales ? 3 : 2}
                className="border-t-none border-l-none"
                customStyle={css({ padding: "14px 0" })}
              >
                合計
              </Table.HeaderCell>
            </tr>
            <tr style={{ lineHeight: 0, height: 34 }}>
              <Table.HeaderCell className="border-t-none"></Table.HeaderCell>
              <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>勤務時間(h)</Table.HeaderCell>
              <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
              {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
              <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>勤務時間(h)</Table.HeaderCell>
              <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
              {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
              <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>勤務時間(h)</Table.HeaderCell>
              <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
              {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
            </tr>
          </thead>
          <tbody>
            {useSales && (
              <tr>
                <Table.Cell style={{ borderLeft: "1px solid #ccc" }}>予算</Table.Cell>

                <Table.Cell>-</Table.Cell>
                <Table.Cell>{summaryInfo?.laborCostBudgetShopMonthlyEmployee || 0}</Table.Cell>
                <Table.Cell>{summaryInfo?.laborCostRateBudgetShopMonthlyEmployee || "0.00%"}</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>{summaryInfo?.laborCostBudgetShopMonthlyPart || 0}</Table.Cell>
                <Table.Cell>{summaryInfo?.laborCostRateBudgetShopMonthlyPart || "0.00%"}</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>{summaryInfo?.laborCostBudgetShopMonthly || 0}</Table.Cell>
                <Table.Cell>{summaryInfo?.laborCostRateBudgetShopMonthly || "0.00%"}</Table.Cell>
              </tr>
            )}

            {/* RowId2 */}
            <tr>
              <Table.Cell style={{ borderLeft: "1px solid #ccc" }}>シフト</Table.Cell>

              <Table.Cell>{summaryInfo?.workTimeShiftShopMonthlyEmployee || "0:00"}</Table.Cell>
              <Table.Cell>{summaryInfo?.laborCostShiftShopMonthlyEmployee || 0}</Table.Cell>
              {useSales && <Table.Cell>{summaryInfo?.laborCostRateShiftShopMonthlyEmployee || "0.00%"}</Table.Cell>}
              <Table.Cell>{summaryInfo?.workTimeShiftShopMonthlyPart || "0:00"}</Table.Cell>
              <Table.Cell>{summaryInfo?.laborCostShiftShopMonthlyPart || 0}</Table.Cell>
              {useSales && <Table.Cell>{summaryInfo?.laborCostRateShiftShopMonthlyPart || "0.00%"}</Table.Cell>}
              <Table.Cell>{summaryInfo?.workTimeShiftShopMonthly || "0:00"}</Table.Cell>
              <Table.Cell>{summaryInfo?.laborCostShiftShopMonthly || 0}</Table.Cell>
              {useSales && <Table.Cell>{summaryInfo?.laborCostRateShiftShopMonthly || "0.00%"}</Table.Cell>}
            </tr>

            {/* RowId3 */}
            <tr>
              <Table.Cell style={{ borderLeft: "1px solid #ccc" }}>実績</Table.Cell>

              <Table.Cell>{summaryInfo?.workTimeAchievementShopMonthlyEmployee || "0:00"}</Table.Cell>
              <Table.Cell>{summaryInfo?.laborCostAchievementShopMonthlyEmployee || 0}</Table.Cell>
              {useSales && (
                <Table.Cell>{summaryInfo?.laborCostRateAchievementShopMonthlyEmployee || "0.00%"}</Table.Cell>
              )}
              <Table.Cell>{summaryInfo?.workTimeAchievementShopMonthlyPart || "0:00"}</Table.Cell>
              <Table.Cell>{summaryInfo?.laborCostAchievementShopMonthlyPart || 0}</Table.Cell>
              {useSales && <Table.Cell>{summaryInfo?.laborCostRateAchievementShopMonthlyPart || "0.00%"}</Table.Cell>}
              <Table.Cell>{summaryInfo?.workTimeAchievementShopMonthly || "0:00"}</Table.Cell>
              <Table.Cell>{summaryInfo?.laborCostAchievementShopMonthly || 0}</Table.Cell>
              {useSales && <Table.Cell>{summaryInfo?.laborCostRateAchievementShopMonthly || "0.00%"}</Table.Cell>}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TableTotalValueMonthlyShift;
