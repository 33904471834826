/** @jsx jsx */
import React, { useState, useContext } from "react";
import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import HourInput from "components/atoms/Form/HourInput";
import FlexBox from "components/atoms/FlexBox";
import { useHistory } from "react-router-dom";
import { grayScale, productColor } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isIPad13, isTablet, isSafari } from "react-device-detect";
import { useManagementInformationDomainForm, useDownloadHolidayManagement } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import StockPaidHolidayStatusDomain from "domain/master/holidayManagement/stockPaidHolidayStatus";
import BodyText from "components/atoms/BodyText";
import FormLabel from "components/atoms/Form/FormLabel";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import TextTooltip from "components/molecules/TextTooltip";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "150px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "150px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
    color: "#666666",
  }),
  detail: css({
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  input: css({
    maxWidth: "500px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 20px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  table: css(
    {
      color: "#666666",
      margin: "0 auto",
    },
    css`
      table.tblPopup tr {
        display: inline-grid;
        margin-bottom: 10px;
      }
      table.tblPopup tr td {
        margin-bottom: 5px;
      }
    `,
  ),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
}

const ManagementInformationForm: React.FC<{ orgTreesOptions: any }> = ({ orgTreesOptions }) => {
  const {
    paidHolidayStatusList,
    orgCode,
    setOrgCode,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callStockPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    underFlag,
    setUnderFlag,
  } = useManagementInformationDomainForm();
  const { downloadPaidHolidayStatus, blocking } = useDownloadHolidayManagement(orgCode || "", underFlag || 0);

  // handlePaidHolidayStatusDetail
  const handlePaidHolidayStatusDetail = async (data: any) => {
    // callStockPaidHolidayStatusDetail(data.staffCode, data.stockPaidlHolidayGrantId);
    callStockPaidHolidayStatusDetail(data.staffCode);
    setModalOpen(true);
  };

  const contextObject = useContext(HolidayManagementContext);

  //
  const columns: Array<Column<StockPaidHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: StockPaidHolidayStatusDomain };
          data: Array<StockPaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: StockPaidHolidayStatusDomain };
          data: Array<StockPaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: StockPaidHolidayStatusDomain };
          data: Array<StockPaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
            {contextObject.detailRole.editable === 1 && (
              <IconLabelButton
                onClick={() => handlePaidHolidayStatusDetail(cell.row.original)}
                text="編集"
                isIcon={false}
              />
            )}
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: StockPaidHolidayStatusDomain };
          data: Array<StockPaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "150px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "積立有給残日数",
        id: "grantDaysNum",
        accessor: "grantDaysNum",
        Cell: (cell: {
          row: { isExpanded: boolean; original: StockPaidHolidayStatusDomain };
          data: Array<StockPaidHolidayStatusDomain>;
        }) => <FlexBox customStyle={styles.center}>{cell.row.original.grantDaysNum}</FlexBox>,
      },
    ],
    [handlePaidHolidayStatusDetail, contextObject.detailRole],
  );

  const history = useHistory();
  return (
    <BlockUI blocking={blocking}>
      <Accordion text="管理情報" callBack={() => {}} defaultExpand={false}>
        <div css={styles.wrapAccordion}>
          <FormContentFilter maxWidth="100%">
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode"
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag11"}
                name={"underFlag11"}
                label={"配下指定"}
                value={`underFlag11`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 150px;
              }
              table th:nth-of-type(2) {
                width: 150px;
              }
              table th:nth-of-type(3) {
                width: 150px;
              }
              table th:nth-of-type(4) {
                width: 150px;
              }
              table th:nth-of-type(5) {
                width: 140px;
              }
              table tr td,
              table tr th {
                text-align: center;
              }
            `}
          >
            <FormSubmitArea customStyle={styles.customStyle}>
              {contextObject.detailRole.editable === 1 && contextObject.detailRole.importFlag === 1 ? (
                <IconLabelButton
                  onClick={() => history.push("/attendHolidayManagement/attendHolidayManagementImport/StockPaid")}
                  iconType="addCircle"
                  text="インポート"
                />
              ) : null}
              {contextObject.detailRole.downloadFlag === 1 ? (
                <IconLabelButton onClick={() => downloadPaidHolidayStatus()} iconType="download" text="ダウンロード" />
              ) : null}
            </FormSubmitArea>
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={paidHolidayStatusList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={11}
              />
            </div>
          </div>

          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="積立有給編集"
            submitText="登録する"
            closeText="キャンセル"
            onSubmit={callSaveUseItemForPaidHolidayStatus}
          >
            {detailItems && (
              <div style={{ width: "350px", margin: "0 auto", fontSize: "14px" }} css={styles.table}>
                <VerticalLabelTextLayout label={`スタッフコード`} variable={detailItems.staffCode} />
                <VerticalLabelTextLayout label={`スタッフ名`} variable={detailItems.staffName} />
                <FlexBox>
                  <div style={{ width: "120px", marginRight: "15px" }}>
                    <FormLabel label="積立有給残日数" />
                    <HourInput
                      name="useItemsGrantDaysNum"
                      value={Number(detailItems.grantDaysNum)}
                      onChange={(e) =>
                        setDetailItems({
                          ...detailItems,
                          grantDaysNum: Number(e.target.value),
                        })
                      }
                      label="日"
                      min={0}
                      step={0.5}
                    />
                  </div>
                </FlexBox>
                {/* <table className="tblPopup" style={{ width: '100%', fontSize: '14px' }}>
                    <tbody>
                      {detailItems.grantDaysNum
                        && (
                          <tr>
                            <td className="active" style={{ color: '#666666' }}>当年付与数</td>
                            <td>
                              <FlexBox>
                                <div style={{ width: '120px', marginRight: '15px' }}>
                                  <HourInput
                                    name="useItemsGrantDaysNum"
                                    value={Number(detailItems.grantDaysNum)}
                                    onChange={(e) => setDetailItems(
                                      {
                                        ...detailItems,
                                        grantDaysNum: Number(e.target.value),
                                      },
                                    )}
                                    label="日"
                                    min={0}
                                    step={0.5}
                                  />
                                </div>
                              </FlexBox>

                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table> */}
              </div>
            )}
          </Modal>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default ManagementInformationForm;
