import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import SeparateBillLayout from 'components/organismos/master/storeManagement/report/SeparateBill/SeparateBillLayout';

const SeparateBillPage = () => (
  <SidebarTemplate pageTitle="月次日別帳票">
    <SeparateBillLayout />
  </SidebarTemplate>
);
export default SeparateBillPage;
