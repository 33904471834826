/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import ClickableButton from "components/atoms/ClickableIconButton";
import HeaderBar from "components/atoms/HeaderBar";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import PhotoFrame from "components/molecules/PhotoFrame";
import { Link } from "react-router-dom";
import Icon from "components/atoms/Icon";
import { grayScale } from "components/styles";
import useLoginUser from "hooks/useLoginUser";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import Dropdown from "components/molecules/Dropdown";
import IconLabelButton from "components/molecules/IconLabelButton";
import { checkPermissionAccessHokuyo } from "hooks/checkPermissionAcess";
import useIsMobile from "hooks/useIsMobile";

/**
 * アプリケーション共通のヘッダーバー
 */
const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";

const Appbar: React.FC<{
  staffName: string;
  title: string;
  showMobileMenu?: boolean | null;
  changeVisibleEvent?: (visible: boolean) => void;
}> = ({ staffName, showMobileMenu, changeVisibleEvent }) => {
  const photoPathGlobal = useSelector((state: Store) => state.ui.appBar.photoPath);
  const isMobile = useIsMobile();
  const history = useHistory();
  const shopCodeSpecify = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);

  const isLogin = useLoginUser().staffName ? 1 : 0;
  const showGpsStamp = sessionStorage.getItem("loginUser.useGpsStamp") === "true" ? true : false;

  // Inside Flag Recorder
  const showInsideFaceStamp =
    sessionStorage.getItem("loginUser.useInsideLoginFaceRecorderStamp") === "true" ? true : false;
  const showInsideIdStamp = sessionStorage.getItem("loginUser.useInsideLoginBarcodeStamp") === "true" ? true : false;

  const defaultPageUrl = "/agreement36";
  const logoFlag = process.env.REACT_APP_LOGO_FLAG;

  const dismissAll = () => toast.dismiss();

  return (
    <HeaderBar>
      <div
        css={css({
          display: "flex",
          alignItems: "center",
          gap: 32,
        })}
      >
        <Link to={defaultPageUrl} onClick={dismissAll}>
          {logoFlag === "0" ? (
            <img src="/menu_logo.gif" css={css({ marginLeft: isMobile ? 4 : "2rem", height: "4.3rem" })} />
          ) : (
            <img src="/wsp_01.png" css={css({ marginLeft: isMobile ? 4 : "2rem", height: "3.5rem" })} />
          )}
        </Link>
        {/* {title && <label>{title}</label>} */}
      </div>

      <FlexBox customStyle={css({ marginRight: isMobile ? 0 : "2rem" })}>
        {!isMobile ? (
          <div>
            {isLogin
              ? (showGpsStamp || showInsideFaceStamp || showInsideIdStamp) && (
                  <FlexBoxItem margin="0 0 6px 1rem">
                    <div
                      className="timeRecorder"
                      css={css({ textDecoration: "none", cursor: "pointer" })}
                      onClick={() => history.push(isLogin ? "/recorder" : "/recorder_1")}
                    >
                      <Icon type="timeRecorder" color="" />
                      <span
                        css={css({
                          fontSize: "12px",
                          marginLeft: "5px",
                          color: grayScale.gray01,
                        })}
                      >
                        打刻
                      </span>
                    </div>
                  </FlexBoxItem>
                )
              : shopCodeSpecify && (
                  <FlexBoxItem margin="0 0 6px 1rem">
                    {checkPermissionAccessHokuyo() ? (
                      <div className="timeRecorder" css={css({ textDecoration: "none", cursor: "pointer" })}>
                        <Dropdown
                          hasIconDropDown={true}
                          customStyle={css`
                            button {
                              display: inline-flex;
                              align-items: center;
                            }
                            * {
                              color: ${grayScale.gray01} !important;
                            }
                          `}
                          items={[
                            {
                              value: "0",
                              label: "従業員むけ",
                              onClick: () => history.push("/recorder_1"),
                            },
                            {
                              value: "1",
                              label: "園児向け",
                              onClick: () => history.push("/recorder_2"),
                            },
                          ]}
                        >
                          <IconLabelButton iconType="timeRecorder" onClick={() => {}} text="タイムレコーダー" />
                        </Dropdown>
                      </div>
                    ) : (
                      <div
                        className="timeRecorder"
                        css={css({ textDecoration: "none", cursor: "pointer", marginRight: isMobile ? 8 : 0 })}
                        onClick={() => history.push(isLogin ? "/recorder" : "/recorder_1")}
                      >
                        <Icon type="timeRecorder" color="" />
                        <span
                          css={css({
                            fontSize: "12px",
                            marginLeft: "5px",
                            color: grayScale.gray01,
                          })}
                        >
                          タイムレコーダー
                        </span>
                      </div>
                    )}
                  </FlexBoxItem>
                )}
          </div>
        ) : (
          <div>
            {!isLogin && shopCodeSpecify && (
              <FlexBoxItem margin="0 0 6px 1rem">
                {checkPermissionAccessHokuyo() ? (
                  <div className="timeRecorder" css={css({ textDecoration: "none", cursor: "pointer" })}>
                    <Dropdown
                      hasIconDropDown={true}
                      customStyle={css`
                        button {
                          display: inline-flex;
                          align-items: center;
                        }
                        * {
                          color: ${grayScale.gray01} !important;
                        }
                      `}
                      items={[
                        {
                          value: "0",
                          label: "従業員むけ",
                          onClick: () => history.push("/recorder_1"),
                        },
                        {
                          value: "1",
                          label: "園児向け",
                          onClick: () => history.push("/recorder_2"),
                        },
                      ]}
                    >
                      <IconLabelButton iconType="timeRecorder" onClick={() => {}} text="タイムレコーダー" />
                    </Dropdown>
                  </div>
                ) : (
                  <div
                    className="timeRecorder"
                    css={css({ textDecoration: "none", cursor: "pointer", marginRight: isMobile ? 8 : 0 })}
                    onClick={() => history.push(isLogin ? "/recorder" : "/recorder_1")}
                  >
                    <Icon type="timeRecorder" color="" />
                    <span
                      css={css({
                        fontSize: "12px",
                        marginLeft: "5px",
                        color: grayScale.gray01,
                      })}
                    >
                      タイムレコーダー
                    </span>
                  </div>
                )}
              </FlexBoxItem>
            )}
          </div>
        )}

        {!isMobile && (
          <FlexBoxItem marginLeft="1rem">
            {logoFlag === "0" ? (
              <img alt="Cydas" src="/menu_logo.gif" css={css({ height: "4.3rem" })} />
            ) : (
              <img
                alt="Cydas"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAAAOCAYAAAB95wG7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATDSURBVHgB5VfNbiJHEP66By7OIbyBcZ6APUTaVWwyKPcEP8HaTwDcLA8RoDVOpBzMPoHJE5jcozAyrDaHSOYNTA65EymWIjMznarumWaGmXWwVjnlk8ae6Z/qquqqrwoBRnfWor99KFQAMUa4HuD7xpKnajf3VTjimuaqiFRncXwwwYdwNnPhoAeBmpYlsIISEyvvbFqFdKYQcoLhYSe7l+ac0jW9VfAQNDBqrODNrwBVy6xTJBPqJ1zWx3gK3dkNrW2SPaPcWQna0wr2SmS7OoEQVaMvFngMOvihsRA4n7Ex/ewuscTD+kXtq89ek0P6WmE7JcaLr/dPcwd5t2SIaKMYKzL4AHuyTY7p6RFyNL6rjzb759daSX0GGgjov4MpPgjSMVw3kkvM4PyWjb2230qdFjrTm9/lnJ/SV5KQk/ycqmLPaZJQ8nzKMeag/Hp2cOIYoVY6+lQ0ICPjKOMxQhSN9e0wpOjpaEmMSRwDtcTFkZ+RL+BrmVoezSc6OqU7KyOzXrx+8ntzZs2eqfA2oy9Fbkkf8jFg5Wzk0SFBSLdZX9p5Dl0GpwnfiHc7IG0pysjpnEZn01MTTco4lvdvI1I/4vJobL+7sxEtb2kZUvKltDP6AK5+FxjQup7+Zj2MDgZphz2Gx5xG28dKenwUIQp9RFjh3yAd174rDHJhzgqllRpyKpGxBq6+/eSClBwUpsk2Lo7aNgKFzEaFcRbiCOzbdXsym/IiZVu51NN8uW0awuB0E6p2o1FSES9E6q0dV+TISB1k1ipshK7DnPcLUQ7bqTPjtKW0GR6OsCtCIuVkv/du344L5xvzIn39L4rMRQj5ZWZ/ZPezvU3Nb978XnNfnKpSO2FYP6CQPiaFO5oM2eMEqkxLetraIZFqLJrVBo9lDnHEp/a9IDQL0adIEiJF6mqpK9pzIFIXKiJzYfr24ygUcXRKmVRXl9LRtXuYoDkIMoHBe4n7nPIU5+9qJTv+8y8LiPI+3nu+HeMSr0Rt8dsf5tubmxAeHm4MC9WfRK7xPN3g8IvfsQuYdL1ZYt1yp3TKopIbcZhHFL8Rd5WodXhP7Ue0goxWurWIIhdpGjFB0Ef3tmnKPii6RFU7SagraRdKyjuhRvh8uCltegNXke0nBYlNtKggX+L/K9j0IfwVLNAnwhW6ujKI7IN7/YjozvRvvEe2CmVd1Knvqp8gpGKw4SLXOOfl8IT+nuj3krrCc1AOxylybOVKK1eJArL7KOjWIamyxFVM+I/cehREUxYVm1pFev2dKUArk1YSPTskKDdfvnHx67c+dgHzR7Y835PyE+KUZdxHuHpdd9bI9S+7QlJF8uaGUDk6bCRw6Q8MV21KM/EZjguk3MSpxdHl45Pylc6C7mxFVdk3+oJ7u6RATEp4NWzleh0pubs8wK7g8tydVeKewrC/yf0NgmCJ50AGdIsxJSrl2nErlog0ov6EuYpTap1UTfq5cnHo5+R5M05/Ny791AqoipZlfuZs6csFIhhIyIKOl5316k0z0wskEKq492Fy4zadbyVZo+gQ7jq5AhYRrm7S2MhUu5Dgkiufrjh+9qE04qr6EL4waxBXP+pw1RNVT0n+fbXQ6xgl5kfRyenLnTLLfn6B+H/hH+lLKIqrL0MiAAAAAElFTkSuQmCC"
              />
            )}
          </FlexBoxItem>
        )}

        {!isMobile && (
          <FlexBoxItem marginLeft="1rem">
            <ClickableButton
              onClick={() => {
                window.open(
                  logoFlag === "0" ? "https://help2.am.winboard.biz/" : "https://help.hinata.cydas.com/",
                  "_blank",
                );
              }}
            >
              <Icon type="help" size="32px" color="black" />
            </ClickableButton>
          </FlexBoxItem>
        )}

        {isMobile && isLogin ? (
          <ClickableButton onClick={() => changeVisibleEvent?.(!showMobileMenu)}>
            <Icon type={showMobileMenu ? "close" : "hamburger"} size="32px" color="black" />
          </ClickableButton>
        ) : (
          <div>
            {staffName && (
              <FlexBoxItem marginLeft="1rem">
                <ClickableButton onClick={() => changeVisibleEvent?.(true)}>
                  <PhotoFrame src={photoPathGlobal} size="32px" isCircle={true} onErrorIconColor="black" />
                </ClickableButton>
              </FlexBoxItem>
            )}
          </div>
        )}
      </FlexBox>
    </HeaderBar>
  );
};

export default Appbar;
