/* eslint-disable array-callback-return */
/** @jsx jsx */
import { useState, useMemo } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import PaymeCoordinationDomain from 'domain/master/atendenceManagement/paymeCoordination';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { useEmploymentListDateRange } from 'hooks/useEmploymentOptions';
import { PrimaryButton } from 'components/atoms/Button';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { css, jsx } from '@emotion/core';
import usePaymeCoordination, { downloadCSV } from './hooks';
import SelectForm from 'components/molecules/SelectForm';

const PayMeCooperationFrom = () => {
  // const today = new Date();
  const d = new Date();
  d.setDate(d.getDate() - 1);
  const [targetDateFrom, setTargetDateFrom] = useState(d);
  const [targetDateTo, setTargetDateTo] = useState(d);


  const employmentOptions = useEmploymentListDateRange(targetDateFrom, targetDateTo);

  const [employmentId, setEmploymentId] = useState(sessionStorage.getItem('selectedEmployment') ? sessionStorage.getItem('selectedEmployment') : '');
  const [employmentIdAfter, setemploymentIdAfter] = useState(sessionStorage.getItem('selectedEmploymentAfter') ? sessionStorage.getItem('selectedEmploymentAfter') : '');

  const orgTreesOptions = useOrgTreesOptionsAll(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'));
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');

  let setlabelOrgCode = '';
  orgTreesOptions.filter((item:any) => {
    if (item.value === orgCode) {
      setlabelOrgCode = item.label;
    }
    return setlabelOrgCode;
  });

  const { paymeCoordination } = usePaymeCoordination(orgCode || '', moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'), employmentId?.slice(0, -1) || '');

  // employment delete combobox selected
  const selectedEmploymentIds : Array<string> = employmentId ? employmentId.split(',') : [];
  let newSelectedEmploymentId : string = '';
  selectedEmploymentIds.map((item: string) => {
    if (employmentOptions.map((option) => option.value).includes(item)) {
      newSelectedEmploymentId += `${item},`;
    }
  });
  if (employmentId !== newSelectedEmploymentId) {
    setEmploymentId(newSelectedEmploymentId);
  }

  // employment after empployment null and select targetDateFrom,targetDateTo
  const employmentIdAfterstr : Array<string> = employmentIdAfter ? employmentIdAfter.split(',') : [];
  let newSelectedEmploymentId2 : string = '';
  employmentIdAfterstr.map((item: string) => {
    if (employmentOptions.map((option) => option.value).includes(item)) {
      newSelectedEmploymentId2 += `${item},`;
    }
  });
  if (employmentId !== newSelectedEmploymentId2) {
    setEmploymentId(newSelectedEmploymentId2);
  }


  const columns: Array<Column<PaymeCoordinationDomain>> = useMemo(() => [
    {
      Header: '社員番号',
      accessor: 'employeeNumber',
    },
    {
      Header: '勤務日',
      accessor: 'workingDay',
    },
    {
      Header: '出勤時刻',
      accessor: 'attendance',
    },
    {
      Header: '退勤時刻',
      accessor: 'departureTime',
    },
    {
      Header: '実労働時間',
      accessor: 'actualWorkingHours',
    },
  ], []);


  return (
    <div>
      <FormContents>
        <FlexBox>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label="対象期間"
            date={targetDateFrom}
            changeDate={(date: Date) => { setTargetDateFrom(date); }}
            isFullWidth={true}
          />
          <span style={{ margin: '0 30px', marginTop: '24px' }}>～</span>
          <DatePickerForm
            dateFormat="yyyy年MM月dd日"
            label="対象期間"
            date={targetDateTo}
            changeDate={(date: Date) => { setTargetDateTo(date); }}
            isFullWidth={true}
          />
        </FlexBox>
        <SelectForm
          label="対象組織"
          name="orgCode"
          value={String(orgCode)}
          setValue={(v) => {
            setorgCode(v);
          }}
          options={orgTreesOptions}
          description=""
        />

        <VerticalLabelMutilSelectForm
          label="雇用形態"
          name="employmentId"
          values={employmentOptions.filter((option) => {
            if (employmentId?.split(',').includes(option.value)) {
              return option;
            }
          })}
          setValue={(val: any) => {
            setEmploymentId(val.slice(0, -1));
            setemploymentIdAfter(val);
          }}
          options={employmentOptions}
          description=""
          isMulti={true}
        />

        <FormField>
          <FormSubmitArea>
            <PrimaryButton
              text="CSV出力 "
              onClick={() => {
                downloadCSV(
                  moment(targetDateFrom).format('YYYY-MM-DD'),
                  moment(targetDateTo).format('YYYY-MM-DD'),
                  String(orgCode),
                  String(employmentId),
                  setlabelOrgCode,
                );
              }}
            />
          </FormSubmitArea>
        </FormField>

        <div
          css={css`
              table th:nth-of-type(1) {
                width: 100px;
              }
              table th:nth-of-type(2) {
                width: 100px;
              }
              table th:nth-of-type(3) {
                width: 100px;
              }
              table th:nth-of-type(4) {
                width: 100px;
              }
              table th:nth-of-type(5) {
                width: 100px;
              }
            `}
        >

          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={paymeCoordination}
              isGlobalFilter={true}
              minWidth="540px"
            />
          </div>
        </div>
      </FormContents>
    </div>
  );
};

export default PayMeCooperationFrom;
