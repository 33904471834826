/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import SelectForm from "components/molecules/SelectForm";
import { SalaryTermStaff } from "domain/master/general/salaryDataStaff";
import { OptionType } from "components/atoms/Select";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import { textFontSize } from "components/styles";
import Icon from "components/atoms/Icon";
import MoneyInput from "components/atoms/Form/MoneyInput";

const SalaryTermForm: React.FC<{
  termOptions: Array<OptionType>;
  salaryTerm: SalaryTermStaff;
  setSalaryTerm: (salaryTerm: SalaryTermStaff) => void;
  deleteSalaryTerm: () => void;
}> = ({ termOptions, salaryTerm, deleteSalaryTerm, setSalaryTerm }) => (
  <tr>
    <td>
      <SelectForm
        label=""
        isHiddenLabel={true}
        name="termId"
        value={salaryTerm.id.termId}
        setValue={(v) => {
          setSalaryTerm({
            ...salaryTerm,
            id: {
              staffSalaryId: salaryTerm.id.staffSalaryId,
              termId: v,
            },
          });
        }}
        options={termOptions}
      />
    </td>
    <td>
      <MoneyInput
        name="selectSalary.limitTransportation"
        value={salaryTerm.addHourlySalary}
        label="円"
        onChange={(e) => {
          setSalaryTerm({
            ...salaryTerm,
            addHourlySalary: Number(e.target.value),
          });
        }}
        min={0}
        max={999999999}
      />
    </td>
    <td style={{ textAlign: "center" }}>
      <ClickableIconButton onClick={deleteSalaryTerm}>
        <Icon type="delete" color={textFontSize.re} />
      </ClickableIconButton>
    </td>
  </tr>
);

export default SalaryTermForm;
