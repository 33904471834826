import { useState, useEffect } from 'react';
import { getList } from 'api/executive';

export const useExecutiveOptions = (isNotOptionAll?: boolean): Array<any> => {
  const [executiveOptions, setExecutiveOptions] = useState<Array<any>>([]);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  useEffect(() => {
    getList(companyCode).then((response: Array<any>) => {
      const executiveO = response.map((item) => ({
        value: item.executiveId,
        label: `${item.executiveCode} ${item.executiveName}`,
        executiveCode: item.executiveCode,
        executiveName: item.executiveName,
      })); 
      
      if (!isNotOptionAll) {
        executiveO.unshift({
          value: '',
          label: '-',
          executiveCode: '',
          executiveName: '',
        });
      }
      setExecutiveOptions(executiveO); 
    });
  }, [companyCode]);
  return executiveOptions;
};

export default useExecutiveOptions;
