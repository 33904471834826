/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import ActivationInputForm from 'components/organismos/login/ActivationForm';
import LoginFormContainer from 'components/organismos/login/LoginFormContainer';

const ActivationPage: React.FC = () => (
  <React.Fragment>
    <LoginFormContainer 
      titleForm="認証コード確認"
      noteForm= "認証コードを送信しました<br/>メッセージに表示されている認証コードを入力してください"
    >
      <ActivationInputForm/>
    </LoginFormContainer>  
  </React.Fragment>
);

export default ActivationPage;
