import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getAllAdminOrAttendanceStaff, getHqStaff, getStaffListForOrgCode, getStaffSelectBoxListForOrgCode } from 'api/staff';


/**
 * Get all admin or attendance staffs
 */
export const useStaffsOptions = (): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getAllAdminOrAttendanceStaff().then((trees) => {
      setStaffOptions(trees.map((staff) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
      })));
    });
  }, []);
  return staffOptions;
};

export const useHqStaffsOptions = (): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getHqStaff().then((trees) => {
      setStaffOptions(trees.map((staff) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
      })));
    });
  }, []);
  return staffOptions;
};

/**
 * Get all staff for 対象組織
 */
export const useStaffsForOrgCodeOptions = (orgCode: string, haveAllOption?: boolean): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getStaffListForOrgCode(orgCode).then((trees) => {
      let tmpStaffOptions = trees.map((staff: any) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
        staffName: staff.staffName,
      }))
      if(haveAllOption){
        tmpStaffOptions.unshift({
          value: '',
          label: '-',
          staffName:'',
        })
      }
      setStaffOptions(tmpStaffOptions);
    });
  }, [orgCode]);
  return staffOptions;
};

export const useStaffsSelectBoxForOrgCodeOptions = (orgCode: string, functionCode: string, underFlag: number, haveAllOption?: boolean): Array<OptionType> => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const status = [1, 3, 4];
    const stateStatus : any = [];
    status.forEach((el:any)=>{
      stateStatus.push(el);
    })
    const params = {
      orgCode: orgCode,
      stateStatus: stateStatus,
      loginStaffCode: loginStaffCode,
      functionCode: functionCode,
      underFlag: underFlag
    }
    getStaffSelectBoxListForOrgCode(params).then((trees) => {
      let tmpStaffOptions = trees.map((staff: any) => ({
        value: staff.staffCode,
        label: `${staff.staffCode} ${staff.staffName}`,
      }))
      if(haveAllOption){
        tmpStaffOptions.unshift({
          value: 'all',
          label: '-'
        })
      }
      setStaffOptions(tmpStaffOptions);
    });
  }, [orgCode, underFlag]);
  return staffOptions;
};

export default useStaffsOptions;
