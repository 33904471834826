import TimeSetting from 'domain/master/labor/timeSetting';
import ApiClient from './ApiClient';
// TimeSetting
export const UpdateTimeSetting = async (companyCode: string,
  TimeSettingObj: TimeSetting) => {
  const query = {};
  const params = TimeSettingObj;

  const response = await ApiClient.post(`/v1/attendSetting/${companyCode}`, query, params);
  return response.data;
};

export const getTimeSetting = async (companyCode: string): Promise<any> => {
  const params = {};

  const response = await ApiClient.get(`/v1/attendSetting/${companyCode}`, params);
  return response.data;
};

// AttendSettingOrganization
export const updateAttendSettingOrganization = async (companyCode: string,
  orgCode: string, TimeSettingObj: any) => {
  const query = { orgCode };
  const params = TimeSettingObj;

  const response = await ApiClient.postJsonData(`/v1/attend_setting_organization/${companyCode}`, query, params);
  return response.data;
};

export const getAttendSettingOrganization = async (companyCode: string,
  orgCode: string): Promise<any> => {
  const params = { orgCode };

  const response = await ApiClient.get(`/v1/attend_setting_organization/${companyCode}`, params);
  return response.data;
};


export default {
  UpdateTimeSetting,
};
