import React, {useEffect} from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import { useOrgTreesOptionsForStaffMst } from 'hooks/useOrgTreesOptions';
import SelectForm from 'components/molecules/SelectForm';
import { functionCode } from 'const';
import Checkbox from 'components/molecules/Checkbox';


const OrganizationFilter: React.FC<{
  orgCode: string;
  setOrgCode: (orgCode: string) => void;
  id?: string;
  stateStatus : Array<number>;
  defaultValue?: any,
  underFlag: number;
  setUnderFlag: React.Dispatch<React.SetStateAction<number|null>>
}> = ({ orgCode, setOrgCode, id= '0', stateStatus, defaultValue, underFlag, setUnderFlag }) => {
  const orgTreesOptions = useOrgTreesOptionsForStaffMst(true, stateStatus, functionCode.staffs);
  return (
    <FlexBox
      customStyle={css({
        padding: '8px',
      })}
    >
      <FlexBoxItem width="100px">
        <BodyText>
          部署・組織
        </BodyText>
      </FlexBoxItem>
      <FlexBoxItem width="800px" customStyle={css({marginRight: '30px'})}>
        <SelectForm
          name="orgState"
          value={String(orgCode)}
          setValue={(arg: string) => { setOrgCode(arg); }}
          options={orgTreesOptions}
          label=""
        />
      </FlexBoxItem>
      <Checkbox
        id={'underFlag'}
        name={'underFlag'}
        label={"配下指定"}
        value={`underFlag`}
        checked={Boolean(underFlag)}
        onChange={() => {
          setUnderFlag(underFlag === 1 ? 0 : 1)
        }}
      />

    </FlexBox>
  );
};

export default OrganizationFilter;
