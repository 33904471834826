/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { grayScale, utilityColor, textFontSize } from 'components/styles';
import Icon from 'components/atoms/Icon';
import useComponentVisible from 'hooks/useComponentVisible';
import DropdownBox from 'components/molecules/Dropdown/DropdownBox';
import DropdownItem, { DropdownItemProps } from 'components/molecules/Dropdown/DropdownItem';
import CircleIconButton from 'components/molecules/CircleIconButton';

const styles = {
  dropdown: css({
    position: 'relative',
    textAlign: 'left',
  }),
  dropdownButton: css({
    display: 'inline',
    cursor: 'pointer',
    border: 'none',
    padding: '0 4px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    backgroundColor: 'transparent',
  }),
};

const Dropdown: React.FC<{
  items: Array<DropdownItemProps>;
  setValue?: (value: number|string) => void;
  isIconButton?: boolean,
  isBottom?:boolean,
  hasIconDropDown?: boolean,
  customStyle?: SerializedStyles
}> = ({
  children,
  items,
  setValue,
  isIconButton = false,
  hasIconDropDown = true, 
  customStyle={}
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onClickHandler = React.useCallback((value: number|string) => {
    if (setValue) setValue(value);
    setIsComponentVisible(false);
  }, [setIsComponentVisible, setValue]);


  return (
    <div css={css([styles.dropdown, customStyle])} ref={ref}>
      {isIconButton
        ? <CircleIconButton iconType="dropdown" iconColor={grayScale.gray100} borderColor={utilityColor.transparent} color={grayScale.gray10} onClick={() => setIsComponentVisible(!isComponentVisible)} />
        : hasIconDropDown ? (
          <button type="button" onClick={() => setIsComponentVisible(!isComponentVisible)} css={styles.dropdownButton}>
            {children}
            <Icon type="dropdown" color={grayScale.gray100} />
          </button>
        ) : (
          <button type="button" onClick={() => setIsComponentVisible(!isComponentVisible)} css={styles.dropdownButton}>
            {children}
          </button>
        )
      }

      <DropdownBox isVisible={isComponentVisible}>
        {items.map((item) => (
          <DropdownItem
            key={item.value}
            value={item.value}
            label={item.label}
            linkTo={item.linkTo}
            onClick={() => {
              onClickHandler(item.value);
              if (item.onClick) item.onClick();
            }}
          />
        ))}
      </DropdownBox>
    </div>
  );
};

export default Dropdown;
