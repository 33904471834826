import React, { Fragment } from "react";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import AttendAllowanceAccordion from "./AttendAllowanceAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";
import StockPaidHoliday from "./StockPaidHoliday";

const ApprovedTab: React.FC = () => {
  return (
    <Fragment>
      <StampAccordion />
      <HolidayAccordion />
      <OvertimeAccordion />
      <AttendAllowanceAccordion />
      <HolidayWorkAccordion />
      <TransferAccordion />
      <Agreement36Accordion />
      <WorkScheduleChangeApplicationAccordion />
      <StockPaidHoliday />
    </Fragment>
  );
};

export default ApprovedTab;
