
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterICCardStaffSettingForm from 'components/organismos/master/MasterICCardStaffSettingForm';

const MasterICCardStaffSettingPage: React.FC = () => {
    return(
        <SidebarTemplate pageTitle="顔写真登録">
            <MasterICCardStaffSettingForm/>
        </SidebarTemplate>
    )
}

export default MasterICCardStaffSettingPage;
