/**
 * API 勤怠再計算
 */

export interface StampHistoryListRecorder {
  stampHistoryId: string;
  staffCode: string;
  stampTime: any;
  stampType: number;
  stampMode: number;
  stampModeName: string;
  stampName: string;
  photoFullpath: string;
  staffName: string;
  orgCode: string;
  orgName: string;
  isMask: boolean;
  temperature: number;
  errorMessage: string;
}

export default class StampHistoryListRecorderDomain {
  constructor(private rawData: StampHistoryListRecorder) {
    // do nothing
  }

  static generateInitial(): StampHistoryListRecorderDomain {
    return new StampHistoryListRecorderDomain({
      stampHistoryId: "",
      staffCode: "",
      stampTime: null,
      stampType: 0,
      stampMode: 0,
      stampModeName: "",
      stampName: "",
      photoFullpath: "",
      staffName: "",
      orgCode: "",
      orgName: "",
      isMask: false,
      temperature: 0,
      errorMessage: "",
    });
  }

  getRawData(): StampHistoryListRecorder {
    return this.rawData;
  }

  get stampHistoryId(): string {
    return this.rawData.stampHistoryId;
  }

  set stampHistoryId(stampHistoryId: string) {
    this.rawData.stampHistoryId = stampHistoryId;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get stampTime(): Date {
    return this.rawData.stampTime;
  }

  set stampTime(stampTime: Date) {
    this.rawData.stampTime = stampTime;
  }

  get stampType(): number {
    return this.rawData.stampType;
  }

  set stampType(stampType: number) {
    this.rawData.stampType = stampType;
  }

  get stampMode(): number {
    return this.rawData.stampMode;
  }

  set stampMode(stampMode: number) {
    this.rawData.stampMode = stampMode;
  }

  get stampModeName(): string {
    return this.rawData.stampModeName;
  }

  set stampModeName(stampModeName: string) {
    this.rawData.stampModeName = stampModeName;
  }

  get stampName(): string {
    return this.rawData.stampName;
  }

  set stampName(stampName: string) {
    this.rawData.stampName = stampName;
  }

  get photoFullpath(): string {
    return this.rawData.photoFullpath;
  }

  set photoFullpath(photoFullpath: string) {
    this.rawData.photoFullpath = photoFullpath;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get isMask(): boolean {
    return this.rawData.isMask;
  }

  set isMask(isMask: boolean) {
    this.rawData.isMask = isMask;
  }

  get temperature(): number {
    return this.rawData.temperature;
  }

  set temperature(temperature: number) {
    this.rawData.temperature = temperature;
  }

  get errorMessage(): string {
    return this.rawData.errorMessage;
  }

  set errorMessage(errorMessage: string) {
    this.rawData.errorMessage = errorMessage;
  }
}
