import React from "react";
import { css } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BodyText from "components/atoms/BodyText";
import { grayScale } from "components/styles";
import useIsMobile from "hooks/useIsMobile";
import { Row, Col } from "react-grid-system";

const OrganizationFilter: React.FC<{
  orgCode?: string;
  setOrgCode?: (orgCode: string) => void;
}> = () => {
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      {isMobile ? (
        <React.Fragment>
          <Row>
            <Col md={1.5} xs={12} style={{ display: "flex", alignItems: "center" }}>
              <BodyText>組織名</BodyText>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col md={6} xs={12}>
              <BodyText color={grayScale.gray100}>
                {`${sessionStorage.getItem("loginUser.orgCode") || ""} ${sessionStorage.getItem("loginUser.orgName") ||
                  ""}`}
              </BodyText>
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <FlexBox
          customStyle={css({
            padding: "8px",
          })}
        >
          <FlexBoxItem basis="100px">
            <BodyText>組織名</BodyText>
          </FlexBoxItem>

          <FlexBoxItem basis="400px">
            <BodyText color={grayScale.gray100}>
              {`${sessionStorage.getItem("loginUser.orgCode") || ""} ${sessionStorage.getItem("loginUser.orgName") ||
                ""}`}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
      )}
    </React.Fragment>
  );
};

export default OrganizationFilter;
