/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/** @jsx jsx*/
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import { utilityColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormLabel from 'components/atoms/Form/FormLabel';
import { useParams } from 'react-router-dom';
import useHolidayManagementImportCsvForm from './hooks';
import FormPadding from 'components/atoms/Form/FormPadding';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import ImportCSVForm from 'components/molecules/ImportCSVForm';
import FlexBox from 'components/atoms/FlexBox';
import useClearFilterStorage from 'hooks/useClearFilterStorage';

const wrap = {
  padding: '10px 35px 30px'
}
 
const styles = {
  fileUpload: css({
    backgroundColor: '#fff',
    padding: '15px 0px',
    maxWidth: '650px'
  }),
  groupButton: css({
    marginTop: '15px'
  }),
  formField: css({
    display: 'flex',
    justifyContent: 'center'
  })
}
const HolidayManagementImportForm: React.FC = () => {
  const {
    formatType,
  } = useParams();

  useClearFilterStorage();
  const {
    setUploadFile, uploadFile, onSubmit, errors, confirmModalOpen, closeConfirmModal, handleSubmit, inputValue, blocking
  } = useHolidayManagementImportCsvForm(formatType);
  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormTitle title="登録するデータ" bold={true} />
        <FormPadding customStyle={wrap}>
          <FormLabel label={`データ種別：${formatType === 'Paid' ? '有給' : (formatType === 'StockPaid' ? '積立有給' :'特休')}`} />
          <ImportCSVForm 
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            errors={errors}
            textImport="CSVファイルをドラッグ＆ドロップ  または  クリックしてファイルを選択してください"
          />
              
          <FormSubmitArea customStyle={styles.groupButton}>
            <FlexBox justifyContent="center">
              <PrimaryButton
                text="インポート"
                onClick={handleSubmit}
                disabled={!uploadFile}
              />
            </FlexBox>
            <FlexBox justifyContent="center">
              <PrimaryButton
                text="キャンセル"
                ghost={true}
                // eslint-disable-next-line no-restricted-globals
                onClick={() => history.back()}
              />
            </FlexBox>
          </FormSubmitArea>
          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            title="確認メッセージ"
            onSubmit={onSubmit}
            content="ファイルをアップロードします。よろしいですか？"
            items={[]}
          />
        </FormPadding>
      </FormContents>
    </BlockUI>
  );
};


export default HolidayManagementImportForm;
