/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import {grayScale, utilityColor } from 'components/styles';
import HourInput from 'components/atoms/Form/HourInput';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import OrgStaffExcutiveSelectForm from 'components/molecules/OrgStaffExcutiveSelectForm';
import BodyText from 'components/atoms/BodyText';
const styles = {
  help: css({
    color: 'red',
    bottom: '-13px',
    left: '10px',
    position: 'relative'
  }),
  span: css({
    margin: '0px 10px',
    position: 'relative',
    top: '-2px'
  }),
  addon: css({
    color: grayScale.gray100,
    padding: '7px'
  }),
  formBody: css({
    padding: '5px 40px',
    margin: '0px',
    paddingBottom: '30px'
  }),
  formHeader: css({
    margin: '16px 0px'
  })
}

const DetailRersvedPaid: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: (paidCalculationStandard: boolean) => void;
  paidFlag: number;
  setPaidFlag: (paidFlag: number) => void;
  isWorkTime: number;
  setIsWorkTime: (isWorkTime: number) => void;
  unUsableOfHalfHoliday: boolean,
  setUnUsableOfHalfHoliday: any,
  maxYearStockDays: number,
  setMaxYearStockDays: (maxYearStockDays: number) => void;
  maxAllStockDays : number,
  setMaxAllStockDays: (maxAllStockDays: number) => void;
  maxStockAge: number,
  setMaxStockAge: (maxStockAge: number) => void;
  appUserType: number,
  setAppUserType: (appUserType: number) => void;
  orgTreesOptions: any,
  executiveOptions: any,
  staffsForOrgCodeOptions: any,
  orgCode: any,
  setOrgCode: any,
  orgCode2: any,
  setOrgCode2: any,
  executiveId:any, 
  setExecutiveId: any,
  staffCode:any, 
  setStaffCode: any,
  // appUserList: Array<OrgStaffExcutive>,
  appUserList: any,
  setAppUserList: any,
  appUserUpdateList: any,
  setAppUserUpdateList: any,

}> = ({
  paidCalculationStandard, setPaidCalculationStandard,
  paidFlag, setPaidFlag,
  isWorkTime, setIsWorkTime,
  unUsableOfHalfHoliday, setUnUsableOfHalfHoliday,
  maxYearStockDays, setMaxYearStockDays,
  maxAllStockDays, setMaxAllStockDays,
  maxStockAge, setMaxStockAge,
  appUserType, setAppUserType,
  orgTreesOptions,
  executiveOptions,
  staffsForOrgCodeOptions,
  orgCode,
  setOrgCode,
  orgCode2,
  setOrgCode2,
  executiveId, setExecutiveId,
  staffCode, setStaffCode,
  appUserList,
  setAppUserList,
  appUserUpdateList,
  setAppUserUpdateList,
}) =>{
  // useEffect(() => {
  //   setStaffCode(undefined); 
  // }, [setStaffCode, orgCode]);
  return (
    <FormContents isSubForm={true} customStyle={styles.formBody}>
      <FormTitle
        title="積立有給詳細設定"
        note=""
        customStyle={styles.formHeader}
      />
       <FormField>
        <FlexBoxItem width="150px">
          <FormLabel label="年間積立数の上限" showRequiredLabel={true}/>
        </FlexBoxItem>
        <HourInput
            name="maxYearStockDays"
            value={maxYearStockDays}
            label="日"
            onChange={(e) => {
              setMaxYearStockDays(Number(e.target.value));
            }}
            min={0}
            max={999}
          />
          <BodyText customStyle={css({paddingTop:"10px", paddingLeft:'42px'})} color={utilityColor.error} size="sm">
              ※0日の場合チェックしません
          </BodyText>
       </FormField>
      
      <FormField>
        <FlexBoxItem width="150px">
          <FormLabel label="総積立数の上限"/>
        </FlexBoxItem>
        <HourInput
          name="maxAllStockDays"
          value={maxAllStockDays}
          label="日"
          onChange={(e) => {
            setMaxAllStockDays(Number(e.target.value));
          }}
          min={0}
          max={999}
        />
         <BodyText customStyle={css({paddingTop:"10px", paddingLeft:'42px'})} color={utilityColor.error} size="sm">
            ※0日の場合チェックしません
          </BodyText>
      </FormField>
      <FormField>
      <FlexBoxItem width="150px">
        <FormLabel label="積立を行う年齢の上限"/>
      </FlexBoxItem>
        <HourInput
          name="maxStockAge"
          value={maxStockAge}
          label="歳まで"
          onChange={(e) => {
            setMaxStockAge(Number(e.target.value));
          }}
          min={0}
          max={999}
        />
         <BodyText customStyle={css({paddingTop:"10px", paddingLeft:'15px'})} color={utilityColor.error} size="sm">
            ※0歳の場合チェックしません
          </BodyText>
      </FormField>
      <FormField>
        <RadioSelectForm
          label="有給算出基準"
          subLabel=""
          items={[
            {
              label: '加算しない',
              value: '0',
            },
            {
              label: '加算する',
              value: '1',
            },
          ]}
          name="paidCalculationStandard"
          value={paidCalculationStandard ? '1' : '0'}
          setValue={(e) => {
            setPaidCalculationStandard(e.target.value !== '0');
          }}
        />
      </FormField>

      <FormField>
        <RadioSelectForm
          label="有給・無給区分"
          subLabel=""
          items={[
            {
              label: '無給',
              value: '0',
            },
            {
              label: '有給',
              value: '1',
            },
          ]}
          name="paidFlag"
          value={String(paidFlag)}
          setValue={(e) => {
            setPaidFlag(Number(e.target.value));
          }}
        />
      </FormField>

      { paidFlag === 1 && ( 
        <FormField>
          <RadioSelectForm
            label="みなし時間を労働時間に"
            subLabel=""
            items={[
              {
                label: '含まない',
                value: '0',
              },
              {
                label: '含む',
                value: '1',
              },
            ]}
            name="isWorkTime"
            value={String(isWorkTime)}
            setValue={(e) => {
              setIsWorkTime(Number(e.target.value));
            }}
          />
        </FormField>
      )}
  
      <FormField>
        <RadioSelectForm
          label="半休の使用可否"
          subLabel=""
          items={[
            {
              label: '使用不可',
              value: '0',
            },
            {
              label: '使用可',
              value: '1',
            },
          ]}
          name="unUsableOfHalfHoliday"
          value={unUsableOfHalfHoliday ? '1' : '0'}
          setValue={(e) => {
            setUnUsableOfHalfHoliday(e.target.value !== '0');
          }}
        />
      </FormField>
      <FormField>
          <RadioSelectForm
            label="申請が行えるスタッフ"
            items={[
              {
                label: '全員',
                value: '0',
              },
              {
                label: '特定スタッフ',
                value: '1',
              },
            ]}
            name="appUserType"
            value={appUserType ? '1' : '0'}
            setValue={(e) => {
              setAppUserType(Number(e.target.value));
            }}
          />
        </FormField>
        { appUserType === 1 && (
          <FormContents isSubForm={true}>
            <OrgStaffExcutiveSelectForm
            name="orgStaffExcutive"
            executiveOptions={executiveOptions}
            orgTreesOptions={orgTreesOptions}
            staffsForOrgCodeOptions={staffsForOrgCodeOptions}
            orgCode={orgCode}
            setOrgCode={setOrgCode}
            orgCode2={orgCode2}
            setOrgCode2={setOrgCode2}
            executiveId={executiveId}
            setExecutiveId={setExecutiveId}
            staffCode={staffCode}
            setStaffCode={setStaffCode}
            isMulti={true}
            values={appUserList}
            setValue={setAppUserList}
            setAppUserUpdateList={setAppUserUpdateList}
          />
          </FormContents>
        )}
  
    </FormContents>
  );
} 
export default DetailRersvedPaid;
