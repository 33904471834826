import {useState, useCallback, useEffect, useContext} from 'react';

import {
  getStockPaidHolidayStatus,
  getStockPaidHolidayStatusDetail,
  saveUseItemForStockPaidHolidayStatus,
  downloadStockPaidHolidayStatusCsv,
} from 'api/holidayManagement';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import StockPaidHolidayStatusDomain from 'domain/master/holidayManagement/stockPaidHolidayStatus';
import useSaveStateStorage from 'hooks/useSaveFilterStorage';
export interface DetailItems {
  // stockPaidlHolidayGrantId: string;
  staffCode: string,
  staffName: string,
  grantDaysNum: number,
}

export const useManagementInformationDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  const [paidHolidayStatusList, SetPaidHolidayStatusList] = useState<Array<StockPaidHolidayStatusDomain>>([]);

  // const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementStockPaid.managementInformation.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [employmentId, setOrgEmploymentId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    // stockPaidlHolidayGrantId:'',
    staffCode:'',
    staffName:'',
    grantDaysNum: 0,
  });
  const { successNotification, errorNotification } = useToastNotification();

  const contextObject = useContext(HolidayManagementContext);
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementStockPaid.managementInformation.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getStockPaidHolidayStatus(
        orgCode !== 'all' ? orgCode : '',
        underFlag || 0
      )
      contextObject.setIsLoading(false);
      SetPaidHolidayStatusList(response.map((result) => new StockPaidHolidayStatusDomain(result)));
    }
  }, [orgCode, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, orgCode, underFlag]);

  // const toggleNoManager = useCallback(() => {
  //   setNoManager(!noManager);
  // }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callStockPaidHolidayStatusDetail = useCallback(async (staffCode: string) => {
    try {
      await getStockPaidHolidayStatusDetail(staffCode).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification]);
  //
  const callSaveUseItemForPaidHolidayStatus = useCallback(async () => {
    contextObject.setIsLoading(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const saveUser = sessionStorage.getItem('loginUser.staffCode') || '';
    const tmpDataPost = {
      staffCode: detailItems.staffCode,
      // stockPaidlHolidayGrantId: detailItems.stockPaidlHolidayGrantId ? detailItems.stockPaidlHolidayGrantId : 'null',
      grantDaysNum: detailItems.grantDaysNum,
      saveUser
    } ;

    try {
      const response = await saveUseItemForStockPaidHolidayStatus(companyCode, tmpDataPost);
      if (!response.errors) {
        // setNoManager(!noManager);
        successNotification('更新しました。');
        setModalOpen(false);

        contextObject.setIsLoading(true);
        const response = await getStockPaidHolidayStatus(
          (orgCode !== 'all' && orgCode !== null) ? orgCode : '',
          underFlag || 0
        )
        contextObject.setIsLoading(false);
        SetPaidHolidayStatusList(response.map((result) => new StockPaidHolidayStatusDomain(result)));
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    } finally {
      contextObject.setIsLoading(false);
    }

    
  }, [detailItems, errorNotification,
    //  noManager, 
     successNotification, underFlag]);

  return {
    paidHolidayStatusList,
    // toggleNoManager,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,

    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callStockPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagement = (
  orgCode: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadPaidHolidayStatus = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadStockPaidHolidayStatusCsv(
      isDownloadAll ? '' : orgCode,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [ orgCode, underFlag ]);

  return {
    downloadPaidHolidayStatus,
    blocking
  };
};

export default {
  useManagementInformationDomainForm,
};
