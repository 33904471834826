import { useState, useEffect, useCallback, useMemo } from 'react';
import TransferApplicationDomain from 'domain/master/attend/attendTransferApplication';
import { useHistory } from 'react-router-dom';
import { getTransfer, postTransfer } from 'api/attendTransferApplication';
import { useFormik } from 'formik';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import { getApplyingTransferListHistory } from 'api/getHistoryApplication';
import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import { getTreeCurrentByStaff, getTreesOrgCode, getTreesOrgCodeNoRole } from 'api/organization';
import { OptionType } from 'components/atoms/Select';
import { useOrgAllSelect } from 'hooks/useOrgTreesOptions';

export const TransferApplicationForm = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [targetDate] = useState(sessionStorage.getItem('application.targetDate') || '');
  const [staffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectStaffCode] = useState(sessionStorage.getItem('application.staffCode') || '');
  const [achievementId] = useState(sessionStorage.getItem('application.achievementId') || '');
  const [dispStaffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem('application.orgCode') || '');
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [isGetTransferSuccees, setIsGetTransferSuccees] = useState(true);
  const { errorNotification, successNotification } = useToastNotification();
  const [selectChangelog, setSelectChangelog] = useState<string>("0");
  // const [orgTreesOptions, setOrgTreesOptions] = useState<Array<OptionType>>([]);
  const orgTreesOptions = useOrgAllSelect(false, moment(`${targetDate}`).format('YYYY-MM-DD'), moment(`${targetDate}`).format('YYYY-MM-DD'));
 
  // useMemo(async() => {
  //   await getTreesOrgCodeNoRole(String(targetDate), String(targetDate)).then((trees) => {
  //     getTreeCurrentByStaff(selectStaffCode, String(targetDate), String(targetDate)).then((orgCurrent) => {
  //       if(orgCurrent && !achievementId) {
  //         setSelectOrgCode(orgCurrent[0].orgCode); 
  //       }
  //     });

  //     const listOrgCode = trees.map((tree) => ({
  //       value: tree.orgCode,
  //       label: `${tree.orgCode} ${tree.orgName}`,
  //     }));
  //     setOrgTreesOptions(listOrgCode);
  //     setIsReloader(true);
  //   });
  // },[])

  const onSubmit = async (values: TransferApplicationDomain) => {
    if (values.applicationReason === null) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmApplyModalOpen) {
      setConfirmApplyModalOpen(true);
      return;
    }

    const {
      modifierApplicationId, version, orgName, buttonType,
      applicationReason, staffCode, targetDateStr, transferHolidayDate, applicationStatus, modifiyStatus,
    } = values.getRawData();
    const dataPost = {
      transferHolidayDateStr: transferHolidayDate == null ? moment(new Date(targetDate)).format('YYYY年MM月DD日') : moment(transferHolidayDate).format('YYYY年MM月DD日'),
      modifierApplicationId: modifierApplicationId || '',
      version: version || '',
      orgCode: selectOrgCode,
      orgName,
      staffCode,
      buttonType,
      achievementId: achievementId || '',
      targetDateStr: moment(new Date(targetDateStr)).format('YYYY年MM月DD日'),
      loginUserCode: sessionStorage.getItem('loginUser.staffCode') || '',
      applicationReason,
      applicationStatus,
      modifiyStatus,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      applicationStaffCode: sessionStorage.getItem('loginUser.staffCode') || ''
    };

    try {
      setLoading(true);
      setConfirmApplyModalOpen(false);
      const response = await postTransfer(dataPost);
      if (!response.errors) {
        sessionStorage.setItem('highlightAction', 'true');
        history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '');
        successNotification('申請しました。');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmApplyModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: TransferApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const fetchChangeLogTransfer = async (orgCode?: string, targetDate?: string, staffCode?: string): Promise<any[]> => {
    return await getApplyingTransferListHistory({ orgCode, targetDate, staffCode });
  };

  useEffect(() => { 
    setLoading(true); 
    getTransfer({
      orgCode: selectOrgCode,
      targetDate,
      staffCode: selectStaffCode,
    }).then((data: any) => {
      formik.setValues(new TransferApplicationDomain(data));
      if(!data.orgCode) {
        formik.setFieldValue('orgCode', selectOrgCode);
      }
      setLoading(false);
    }).catch(() => {
      setIsGetTransferSuccees(false);
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgCode, selectStaffCode, staffName, targetDate]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  return {
    formik,
    selectOrgCode,
    setSelectOrgCode,
    staffName,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetTransferSuccees,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogTransfer,
    orgTreesOptions,
  };
};

export default TransferApplicationForm;
