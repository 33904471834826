import React from 'react';

import Warning from 'components/atoms/Icon/Warning';
import BlockUI from 'components/molecules/BlockUi';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useOrganizationCsvForm } from './hooks';
import { css } from '@emotion/core';
import DataTable from 'components/organismos/DataTable/DataTable';
import moment from 'moment';
import { grayScale } from 'components/styles';

export type UploadType = 'organization' | 'manager' | 'specialday';

type SelectType = 'csv' | 'csvHistory';
const SelectType: { [key in SelectType]: SelectType } = {
  csv: 'csv',
  csvHistory: 'csvHistory'
};

const styles = {
  table: css`
    table th:nth-of-type(1) {
        width: 90px;
    }
    table th:nth-of-type(2) {
        width: 100px;
    }
    table th:nth-of-type(3) {
        width: 100px;
    }
    table th:nth-of-type(4) {
        width: 100px;
    }
    table th:nth-of-type(5) {
        width: 100px;
    }
    table th:nth-of-type(6) {
        width: 140px;
    }
    .svg-red path:last-child {
        fill: red;
    }
`,
};

const StaffCsvHistory: React.FC = () => {

  const {
    onSubmit, blocking,
    confirmModalOpen, closeConfirmModal, importHistory
  } = useOrganizationCsvForm();
  const columns = React.useMemo(
    () => [
      {
        Header: () => 'データ種別',
        id: 'formatType',
        accessor: 'formatType',
        Cell: (cell: {
          row: { isExpanded: boolean; original: any; index: number };
          data: any;
        }) => (
            <div style={{ textAlign: 'center' }}>
              {cell.row.original.formatType === 0 && 'スタッフ情報'}
              {cell.row.original.formatType === 1 && '介護・看護休暇情報'}
              {cell.row.original.formatType === 2 && '兼務情報'}
            </div>
          ),
      },
      {
        Header: 'ファイル名',
        accessor: 'importFileName',
      },
      {
        Header: () => '処理結果',
        id: 'result',
        accessor: 'result',
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => (
          <div
            style={{ textAlign: 'center' }}
            className={`svg-${
              cell.row.original.result === 1 && 'red'
              }`}
          >
            {cell.row.original.result === 1 && <Warning />}
            <span style={{ marginTop: '-3px', marginLeft: '3px' }}>{cell.row.original.result === 1 ? '失敗' : '成功'}</span>
          </div>
        ),
      },
      {
        Header: 'エラー内容',
        accessor: 'errDetailDisp',
      },
      {
        Header: '実施日時',
        accessor: 'updateDate',
        Cell: (cell: { row: { isExpanded: boolean, original: any } }) => (
          <div>
            {moment(cell.row.original.updateDate).format('YYYY/MM/DD')} <br />
            <span style={{ color: grayScale.gray50 }}>{moment(cell.row.original.updateDate).format('H:m:s')}</span>
          </div>
        ),
      },
      {
        Header: '実施者',
        accessor: 'updateUser',
      },
    ], [],
  );

  return (
    <BlockUI blocking={blocking}>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginTop: '15px' }}>
        <div css={styles.table}>
          <DataTable iconSize="16px"
            className="table-no-border-left-right"
            globalSearchStyle={css({ marginLeft: '20px' })}
            columns={columns} data={importHistory}
            isGlobalFilter={true}
            minWidth="1600px" />
        </div>
      </div>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={onSubmit}
        actionType={ActionType.IMPORT}
      />
    </BlockUI>
  );
};

export default StaffCsvHistory;
