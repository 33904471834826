import React from 'react'
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ApprovalTransferApplication from 'components/organismos/master/attend/ApprovalApplication/ApprovalTransferApplication';

const ApprovalTransferApplicationPage = () => {
  return(
    <SidebarTemplate pageTitle="振替申請 承認・差戻">
      <ApprovalTransferApplication/>
    </SidebarTemplate>
  )
}

export default ApprovalTransferApplicationPage