/* eslint-disable max-len */
/** @jsx jsx */
import React from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { useHistory } from "react-router-dom";
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Modal from "components/molecules/Modal";
import { useMasterRoleSettingForm } from "./hooks";
import { Column } from 'react-table';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { isSafari, isIPad13, isTablet } from 'react-device-detect';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Role } from 'api/masterRole';
import { SubActionButton } from 'components/atoms/Button';

const styles = {
    customStyle: css({
        display: 'flex',
        justifyContent: ((isSafari && isTablet) || isIPad13) ? 'flex-start' : 'flex-end',
        paddingRight: ((isSafari && isTablet) || isIPad13) ? '10px' : '30px',
    }),
    columnOne: css({
        margin: '0 auto'
      }, css`
        button{
          white-space:nowrap;
        }
    `)
};

const MasterRoleSettingForm = () => {

    const history = useHistory();
    const {
        isLoading,
        downloadCsvRole,
        listRole,
        detailRole,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        setSelectedRoleID,
        handleDeleteRole
    } = useMasterRoleSettingForm()

    const columns: Array<Column<Array<Role>>> = React.useMemo(() => [
        {
          Header: () => null,
          accessor: 'orgCode',
          id: 'actionButton',
          disableSortBy: true,
          Cell: (cell: { row: { isExpanded: boolean, original: Role } }) => {
            return (
                <FlexBox customStyle={styles.columnOne}>
                    {detailRole.editable === 1 ? (
                        <React.Fragment>
                            <FlexBoxItem width='36px'>
                                <SubActionButton action={0} text="編集" onClick={() => {
                                    history.push(`/masterRoleSetting/edit/${cell.row.original.roleName}/${cell.row.original.roleCode || 'undefined'}/${cell.row.original.id?.roleId}`);
                                }} />
                            </FlexBoxItem>
                            {cell.row.original.systemRole === false && (
                                <FlexBoxItem marginLeft="5px" width='36px'>
                                    <SubActionButton action={1} text="削除" onClick={() => {
                                        setSelectedRoleID(cell.row.original.id?.roleId);
                                        setIsOpenDeleteModal(true);
                                    }} />
                                </FlexBoxItem>    
                            )}
                            
                            <FlexBoxItem marginLeft="5px">
                                <SubActionButton action={2} text="コピー" onClick={() => {
                                    history.push(`/masterRoleSetting/copy/${cell.row.original.id?.roleId}`);
                                }} />
                            </FlexBoxItem>
                        </React.Fragment>
                    ) : (
                        <FlexBoxItem width='36px'>
                            <SubActionButton action={0} text="参照" onClick={() => {
                                history.push(`/masterRoleSetting/view/${cell.row.original.roleName}/${cell.row.original.roleCode}/${cell.row.original.id?.roleId}`);
                            }} />
                        </FlexBoxItem>
                    )}      
                </FlexBox>
            );
          },
        },
        {
          Header: '権限コード',
          accessor: 'roleCode',
        },
        {
          Header: '権限名',
          accessor: 'roleName',
        },
        {
          Header: '権限レベル',
          accessor: 'accessLevel',
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ], [detailRole]);

    return (
    <BlockUI blocking={isLoading}>
        <div>
        <FormContents customStyle={{ paddingBottom: '50px' }}>
            <FormTitle title="権限マスタ" bold={true} customStyle={css`margin: 16px 35px;`} />
            <div
                css={css`
                    margin-top: 30px;
                    table th:nth-of-type(1), tbody tr td:nth-of-type(1) {
                        width: ${detailRole.editable === 1 ? '150px' : '100px'};
                    }
                    table th:nth-of-type(2) {
                        width: 250px;
                    }
                `}
            >
                <FormSubmitArea customStyle={styles.customStyle}>
                    {detailRole.editable === 1 && (
                        <React.Fragment>
                            <IconLabelButton onClick={() => {
                                history.push(`/masterRoleSetting/add`);
                            }} iconType="addCircle" text="追加" />
                            {detailRole.importFlag === 1 && (
                                <IconLabelButton onClick={() => {
                                    history.push(`/masterRoleSetting/import`);
                                }} iconType="addCircle" text="インポート" />
                            )}
                        </React.Fragment>
                    )}
                    {detailRole.downloadFlag === 1 && (
                        <IconLabelButton onClick={() => downloadCsvRole()} iconType="download" text="CSVダウンロード" />
                    )}
                </FormSubmitArea>
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    <DataTable
                        className="table-no-border-left-right"
                        columns={columns}
                        data={listRole || []}
                        isGlobalFilter={true}
                        minWidth="824px"
                        globalSearchStyle={css({ marginLeft: '35px' })}
                        useSession={true}
                    />
                </div>
                <Modal
                    open={isOpenDeleteModal}
                    closeHandler={() => setIsOpenDeleteModal(false)}
                    title="確認メッセージ"
                    note="権限を削除します。よろしいでしょうか。？"
                    submitText="削除"
                    onSubmit={() => {
                        handleDeleteRole();
                        setIsOpenDeleteModal(false);
                    }}
                    typeSubmit="reject"
                >
                </Modal>
            </div>
        </FormContents>
        </div>
    </BlockUI>
    );
};

export default MasterRoleSettingForm;
