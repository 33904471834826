/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import { deleteAttendBusinessById } from 'api/businessMaster';
import LableTitle from 'components/atoms/LableTitle';
import BusinessDomain from 'domain/master/labor/business';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';

import { useBusinessList } from './hooks';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const BusinessMasterPage: React.FC = () => {
  const { businessList, setBusinessList } = useBusinessList();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(BusinessDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((business : BusinessDomain) => {
    setConfirmModalOpen(true);
    setSelectedBusiness(business);
  }, []);

  const deleteUpdateHoliday = useCallback(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    deleteAttendBusinessById(companyCode, selectedBusiness.businessId).then((Response: any) => {
      setBusinessList(businessList.filter((business) => business.businessId
                                            !== selectedBusiness.businessId));
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} <br>`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [businessList, errorNotification, selectedBusiness.businessId,
    setBusinessList, successNotification]);

  const columns: Array<Column<BusinessDomain>> = useMemo(() => [
    {
      Header: 'コード',
      accessor: 'businessCode',
    },
    {
      Header: '業務名',
      accessor: 'businessName',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: BusinessDomain },
        data: Array<BusinessDomain>
      }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        const [isHoverDelete, setIsHoverDelete] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to={`/businessMaster/edit/${cell.row.original.businessId}`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />

              </span>
            </Link>
            <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                onMouseEnter={() => setIsHoverDelete(true)}
                onMouseLeave={() => setIsHoverDelete(false)}
              >
                <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link>

          </div>
        );
      },
    },
  ], [openConfirmModal]);
  return (
    <React.Fragment>
    <SidebarTemplate pageTitle="">
        <LableTitle title="業務ごとに時給を設定することができるようになります。またシフト上からどの業務を行うかを設定して、各業務ごとの予実管理として利用できるようになります。" />
        <PageTitle
          title=""
          rightContents={(
            <Link to="/businessMaster/create">
              <span style={{ marginRight: '12px' }}>
                <PrimaryButton
                  ghost={false}
                  text="新規業務作成"
                />
              </span>
            </Link>
    )}
        />
        <div
          css={css`
            table th:nth-of-type(1) {
              width: 100px;
            }
            table th:nth-of-type(2) {
              width: 200px;
            }
            table th:nth-of-type(3) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable columns={columns} data={businessList} />
          </div>
        </div>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={deleteUpdateHoliday}
          actionType={ActionType.DELETE}
        />
    </SidebarTemplate>
    </React.Fragment>
  );
};

export default BusinessMasterPage;
