import ApiClient from './ApiClient';

export const getHolidays = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/holidays/attends/list/byemployment/${companyCode}`, {}, params);
  return response.data;
};

export const getEmployment = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/employments/applied/${companyCode}`, {}, params);
  return response.data;
};

export const getAttendSetting = async (params?: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendSetting/${companyCode}`, {}, params);
  return response.data;
};

export const getCurrentHolidayApplication = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendHolidays/currentHolidayApplid/${companyCode}`, {}, params);
  return response.data;
};

export const getAttendHolidayList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendHolidays/appliedHistoryByDay/${companyCode}`, {}, params);
  return response.data;
};

export const getSubstituteList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendholidays/substituteList/${companyCode}`, {}, params);
  return response.data;
};

export const getRoundTimeList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/employments/roundTime/${companyCode}`, {}, params);
  return response.data;
};

export const createHolidayApplicaiton = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const applicationStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    currentStaffCode,
    currentOrgCode,
    applicationStaffCode
  }
  const response = await ApiClient.post(`/v3/attendHolidays/stamp/${companyCode}`, {}, params);
  return response.data;
};
