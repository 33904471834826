import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import PaidHolidayPatternAddForm from 'components/organismos/master/attend/PaidHolidayPatternAddForm';


const PaidHolidayPatternAddPage: React.FC = () => {
  return (
    <SidebarTemplate>
      <PaidHolidayPatternAddForm />
    </SidebarTemplate>
  );
};

export default PaidHolidayPatternAddPage;
