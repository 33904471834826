/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import moment from "moment";
import React from "react";
import { Detail } from "domain/master/attend/allowanceApplication";

const styleTable = css`
  th {
    width: 100px !important;
  }
  td {
    text-align: center;
  }
`;

const AttendAllowanceModal: React.FC<{
  details: Detail[];
  customStyle?: any;
}> = ({ details, customStyle }) => {
  return (
    <React.Fragment>
      <FormPadding customStyle={customStyle}>
        <div css={styleTable}>
          <Table
            customStyle={css({
              width: "auto",
              padding: "14px 10px",
            })}
          >
            <thead>
              <tr>
                <Table.HeaderCell>手当</Table.HeaderCell>
                <Table.HeaderCell>申請値</Table.HeaderCell>
                <Table.HeaderCell>備考</Table.HeaderCell>
              </tr>
            </thead>
            <tbody>
              {details.map((detail) => (
                <tr key={detail.allowanceApplicationDetailId}>
                  <Table.Cell>
                    <BodyText>{detail.allowanceName}</BodyText>
                  </Table.Cell>
                  <Table.Cell>
                    <BodyText>
                      {detail.allowanceValue}
                      {detail.dispUnit}
                    </BodyText>
                  </Table.Cell>
                  <Table.Cell>
                    <BodyText>{detail.comment || ""}</BodyText>
                  </Table.Cell>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </FormPadding>
    </React.Fragment>
  );
};

export default AttendAllowanceModal;
