/** @jsx jsx */
import React, { useEffect, useState, useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import productColor, { grayScale } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import PaidHolidayDigestedtHistoryDomain from "domain/master/holidayManagement/paidHolidayDigestedtHistory";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import moment from "moment";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import { DetailItems, useAcquisitionStatusDomainForm, useDownloadHolidayManagementAcquisitionStatus } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import Calendar from "components/molecules/CalendarV1";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import AcquisitionStatusFormDetail from "./AcquisitionStatusFormDetail";
import { substractYearWithDate } from "utility/dateUtil";
import BlockUI from "components/molecules/BlockUi";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  detail: css({
    backgroundColor: "transparent",
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  selectDay: css({
    minWidth: "100px",
    width: "170px",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
        text-align: center;
      }
    `,
  ),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  note: css({
    paddingLeft: "5px",
    fontSize: "14px",
    color: "#666666",
    position: "relative",
  }),
  modal: css({
    padding: "0px 0px 16px",
    margin: "0px",
    fontSize: "14px",
    textAlign: "center",
    color: "rgb(102, 102, 102)",
  }),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
  transferDayNumb: string;
}

const AcquisitionStatusForm: React.FC<{ orgTreesOptions: any }> = ({ orgTreesOptions }) => {
  const {
    paidHolidayDigestedtHistoryList,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    date,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setOrgCodeName,
    orgCodeName,
    modalOpenDownload,
    setModalOpenDownload,
    underFlag,
    setUnderFlag,
  } = useAcquisitionStatusDomainForm();

  const {
    downloadHolidayManagementAcquisitionStatus,
    blocking,
    downloadPaidAttendHolidayManagement,
  } = useDownloadHolidayManagementAcquisitionStatus(
    orgCode || "",
    searchDate || date,
    orgCodeName,
    setModalOpenDownload,
    underFlag || 0,
  );

  const contextObject = useContext(HolidayManagementContext);
  const [dataDetail, setDataDetail] = useState<DetailItems>({} as DetailItems);

  const handleTransferHolidayStatusDetail = async (data: any) => {
    setDataDetail(data.rawData);
    setModalOpen(true);
  };

  const columns: Array<Column<PaidHolidayDigestedtHistoryDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: PaidHolidayDigestedtHistoryDomain;
          };
          data: Array<PaidHolidayDigestedtHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: PaidHolidayDigestedtHistoryDomain;
          };
          data: Array<PaidHolidayDigestedtHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: PaidHolidayDigestedtHistoryDomain;
          };
          data: Array<PaidHolidayDigestedtHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
            <IconLabelButton
              onClick={() => handleTransferHolidayStatusDetail(cell.row.original)}
              text="詳細"
              isIcon={false}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: PaidHolidayDigestedtHistoryDomain;
          };
          data: Array<PaidHolidayDigestedtHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "合計",
        accessor: "digestion",
      },
    ],
    [],
  );

  useEffect(() => {
    let str = sessionStorage.getItem("loginUser.orgCode") + " " + sessionStorage.getItem("loginUser.orgName");
    let orgCodeFilter = orgTreesOptions.filter((option: any) => {
      if (option.value === orgCode) {
        return true;
      }
    });
    if (orgCodeFilter[0] !== "" && orgCodeFilter[0] !== undefined) {
      setOrgCodeName(orgCodeFilter[0].label);
    } else {
      setOrgCodeName(str);
    }
  }, [orgCode]);
  return (
    <BlockUI blocking={blocking}>
      <Accordion text="取得状況" callBack={() => {}}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="100%">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                <div css={styles.selectDay}>
                  <Calendar
                    date={new Date(searchDate || date)}
                    setDate={(date) => {
                      if (date) {
                        setSearchDate(moment(date).format("YYYY/MM/DD"));
                      }
                    }}
                  />
                </div>
                <div css={styles.note}>から過去1年間</div>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                name="orgCode003"
                value={String(orgCode)}
                setValue={(val: string) => {
                  setOrgCode(val);
                }}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag2"}
                name={"underFlag2"}
                label={"配下指定"}
                value={`underFlag2`}
                checked={Boolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 200px;
              }
              table th:nth-of-type(2) {
                width: 200px;
              }
              table th:nth-of-type(3) {
                width: 200px;
              }
              table th:nth-of-type(4) {
                width: 200px;
              }
              table th:nth-of-type(5) {
                width: 160px;
              }
              table tr td:nth-of-type(5) {
                text-align: center;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementAcquisitionStatus()}
                  iconType="download"
                  text="ダウンロード"
                />
                <IconLabelButton
                  onClick={() => {
                    if (!modalOpenDownload) {
                      setModalOpenDownload(true);
                      return false;
                    }
                  }}
                  iconType="download"
                  text="有給管理簿"
                />
              </FormSubmitArea>
            ) : null}
            <div
              className="table-bordered"
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                marginTop: "10px",
              }}
            >
              <DataTable
                columns={columns}
                data={paidHolidayDigestedtHistoryList}
                isGlobalFilter={true}
                globalSearchStyle={styles.globalSearch}
                tableId={3}
              />
            </div>
          </div>
          <Modal
            open={modalOpenDownload}
            closeHandler={() => setModalOpenDownload(!modalOpenDownload)}
            title="確認メッセージ"
            submitText="ダウンロード"
            onSubmit={() => {
              downloadPaidAttendHolidayManagement();
            }}
            closeText={"キャンセル"}
          >
            <p css={styles.modal}>
              有給付与日が
              {substractYearWithDate(new Date(searchDate || date), 1)}
              のデータを出力します。
            </p>
          </Modal>

          {/* Form Detail */}
          <AcquisitionStatusFormDetail isOpen={modalOpen} setIsClose={closeModal} detail={dataDetail} />
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default AcquisitionStatusForm;
