/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/** @jsx jsx */
import React, {
  useMemo, useState, useCallback,
} from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import Icon from 'components/atoms/Icon';
import { iconColor } from 'components/styles';
import IconLabelButton from 'components/molecules/IconLabelButton';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BusinessCategoryDomain from 'domain/master/sales/businessCategory';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { deleteById, downloadCSV } from 'api/businessCategories';
import ToastModal from 'components/organismos/ToastModal';
import useAreaList from 'components/organismos/master/sales/BusinessCategoryListTable/hooks';
import useToastNotification from 'hooks/useToastNotification';

const BusinessCategoryListTable: React.FC = () => {
  const { businessCategoryList, setBusinessCategoryList } = useAreaList();
  const [valueBusinessCategoryId, setValue] = useState('');
  const [isHover, setHover] = useState('');

  const [isHover2, setHover2] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((item : BusinessCategoryDomain) => {
    setValue(item.businessCategoryId);
    setConfirmModalOpen(true);
  }, []);

  const downloadFileCSV = useCallback(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    downloadCSV(companyCode);
  }, []);

  const deleteBusinessCategoryById = useCallback(() => {
    if (businessCategoryList) {
      deleteById(valueBusinessCategoryId).then((response: any) => {
        setBusinessCategoryList(businessCategoryList.filter((businessCategory) => businessCategory.businessCategoryId !== valueBusinessCategoryId));
        setConfirmModalOpen(false);
        // setToastMessage('削除しました。');
        // setToastModalOpen(true);
        successNotification('削除しました。');
      }).catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgError += `${item.defaultMessage} </br>`;
            return msgError;
          });
          //   setToastMessage(msgError);
          //   setToastModalOpen(true);
          // } else {
          //   setToastMessage('サーバー側でエラーが発生しました。');
          //   setToastModalOpen(true);
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    }
  }, [businessCategoryList, valueBusinessCategoryId, setBusinessCategoryList, successNotification, errorNotification]);

  const columns: Array<Column<BusinessCategoryDomain>> = useMemo(() => [
    {
      Header: '業態コード',
      accessor: 'businessCategoryCode',
    },
    {
      Header: '業態名',
      accessor: 'businessCategoryName',
    },
    {
      Header: () => null,
      accessor: 'businessCategoryCode',
      id: 'actionButton',
      Cell: (cell: { row :{ isExpanded: boolean, original: BusinessCategoryDomain },
          data: Array<BusinessCategoryDomain> }) => (
            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
              <Link to={`/businessCategories/edit/${cell.row.original.companyCode}/${cell.row.original.businessCategoryId}`}>
                <span
                  style={{ marginLeft: '20px' }}
                  onMouseOver={() => { setHover((cell.row.original.businessCategoryId)); }}
                  onMouseOut={() => { setHover(''); }}
                >
                  <Icon type="edit" color={(isHover && isHover === (cell.row.original.businessCategoryId)) ? iconColor.grayhover : iconColor.gray} />
                </span>
              </Link>
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                role="presentation"
                onMouseOver={() => { setHover2((cell.row.original.businessCategoryId)); }}
                onFocus={() => {}}
                onMouseOut={() => { setHover2(''); }}
                onClick={() => { openConfirmModal(cell.row.original); }}
                onBlur={() => {}}
              >
                <Icon type="delete" color={(isHover2 && isHover2 === (cell.row.original.businessCategoryId)) ? iconColor.grayhover : iconColor.gray} />
              </span>
            </div>
      ),
    },
  ], [isHover, isHover2, openConfirmModal]);
  return (
    <React.Fragment>
      <div>
        <PageTitle
          title=""
          rightContents={(
            <Link to="/businessCategories/add">
              <PrimaryButton
                ghost={false}
                text="新規業態作成"
              />
            </Link>
            )}
        >
          <FlexBox>
            <FlexBoxItem>
              <IconLabelButton
                onClick={downloadFileCSV}
                iconType="download"
                text="CSVダウンロード"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <Link to="/businessCategories/import">
                <PrimaryButton
                  ghost={false}
                  text="インポート"
                />
              </Link>
            </FlexBoxItem>
          </FlexBox>
        </PageTitle>

        <div
          css={css`
            table th:nth-of-type(1) {
              width: 100px;
            }
            table th:nth-of-type(2) {
              width: 200px;
            }
            table th:nth-of-type(3) {
              width: 80px;
            }
          `}
        >
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
            <DataTable
              columns={columns}
              data={businessCategoryList}
              isGlobalFilter={true}
            />
          </div>
        </div>

        {/* confirm modal */}
        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="確認メッセージ"
          content="削除します。よろしいですか？"
          submitText="削除"
          onSubmit={deleteBusinessCategoryById}
          items={[]}
        />

        {/* ToastModal */}
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </div>
    </React.Fragment>
  );
};

export default BusinessCategoryListTable;
