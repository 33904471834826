// @ts-nocheck
import { icCardActions } from 'modules/ui/icCard';
import {ArukasNFCLiteS} from "../utility/ArukasNFCLiteS";
import ToastNotification from 'components/molecules/ToastNotification';

export async function sleep(msec: any) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

export async function send(device: any, data: any) {
  let uint8a = new Uint8Array(data);
  await device.transferOut(2, uint8a);
  await sleep(10);
}

export async function receive(device: any, len: any) {
  let data = await device.transferIn(1, len);
  await sleep(10);
  let arr = [];
  for (let i = data.data.byteOffset; i < data.data.byteLength; i++) {
    arr.push(data.data.getUint8(i));
  }
  return arr;
}

function _binToString( argData ) {
  for (let i = 0; i < argData.length; i++) {
    if ( argData[i] == 0x00 ) {
      argData[i] = 0x20 ;
    }
  }
  let text_decoder = new TextDecoder( "utf-8" );
  let retVal = text_decoder.decode( Uint8Array.from( argData ).buffer );
  retVal = retVal.trim(' ') ;
  return retVal ;
}

function _def_val(param, def)
{
  return (param === undefined) ? def : param;
}

function _array_slice(array, offset, length)
{
  let result;

  length = _def_val(length, array.length - offset);
  result = [];
  _array_copy(result, 0, array, offset, length);

  return result;
}

function _bytes2hexs(bytes, sep) {
  let str;

  sep = _def_val(sep, ' ');

  return bytes.map(function(byte) {
    str = byte.toString(16);
    return byte < 0x10 ? '0'+str : str;
  }).join(sep).toUpperCase();
}

function _array_tohexs(array, offset, length, sep )
{
  let temp;

  offset = _def_val(offset, 0);
  length = _def_val(length, array.length - offset);
  sep = _def_val(sep, '');

  temp = _array_slice(array, offset, length );
  return _bytes2hexs(temp, sep);
}

function _array_copy(dest, dest_offset, src, src_offset, length)
{
  let idx;

  src_offset = _def_val(src_offset, 0);
  length = _def_val(length, src.length);

  for (idx = 0; idx < length; idx++) {
    dest[dest_offset + idx] = src[src_offset + idx];
  }

  return dest;
}

export async function close(device: any) {
  try {
    if (device.opened) {
      device.close();
    }
  } catch (e) {
    console.log(e);
  }
}

export async function session(device: any, dispatch: any, flagAutoPause?: boolean) {
  await send(device, [0x00, 0x00, 0xff, 0x00, 0xff, 0x00]);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x03, 0x00, 0xfd, 0xd6, 0x2a, 0x01, 0xff, 0x00]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x03, 0x00, 0xfd, 0xd6, 0x06, 0x00, 0x24, 0x00]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x03, 0x00, 0xfd, 0xd6, 0x06, 0x00, 0x24, 0x00]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x06, 0x00, 0xfa, 0xd6, 0x00, 0x01, 0x01, 0x0f, 0x01, 0x18, 0x00]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x28, 0x00, 0xd8, 0xd6, 0x02, 0x00, 0x18, 0x01, 0x01, 0x02, 0x01, 0x03, 0x00, 0x04, 0x00, 0x05, 0x00, 0x06, 0x00, 0x07, 0x08, 0x08, 0x00, 0x09, 0x00, 0x0a, 0x00, 0x0b, 0x00, 0x0c, 0x00, 0x0e, 0x04, 0x0f, 0x00, 0x10, 0x00, 0x11, 0x00, 0x12, 0x00, 0x13, 0x06, 0x4b, 0x00]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x04, 0x00, 0xfc, 0xd6, 0x02, 0x00, 0x18, 0x10, 0x00]);
  await receive(device, 6);
  await receive(device, 13);
  await send(device, [0x00, 0x00, 0xff, 0xff, 0xff, 0x0a, 0x00, 0xf6, 0xd6, 0x04, 0x6e, 0x00, 0x06, 0x00, 0xff, 0xff, 0x01, 0x00, 0xb3, 0x00]);
  await receive(device, 6);
  let idm = (await receive(device, 37)).slice(17, 25);
  if (idm.length > 0) {
    let idmStr = '';
    for (let i = 0; i < idm.length; i++) {
      if (idm[i] < 16) {
        idmStr += '0';
      }
      idmStr += idm[i].toString(16).toUpperCase();
    }

    if(dispatch){
      dispatch(icCardActions.setIsConnectICCardUsb(true));
      dispatch(icCardActions.setCardId(idmStr));
    }
    if(flagAutoPause){
      dispatch(icCardActions.setDisabledDetect(true));
    }
  } else {
  }
}

export async function connectDevice(dispatch: any) {
  let device;
  try {
    device = await navigator.usb.requestDevice({ filters: []})
    await device.open();
    dispatch(icCardActions.setIsConnectICCardUsb(true));
    dispatch(icCardActions.setDeviceICCard(device));
  } catch (e) {
    dispatch(icCardActions.setIsConnectICCardUsb(false));
    dispatch(icCardActions.setDeviceICCard(null));
    alert(e);
    throw e;
  }
}

const S300_IC_CARD = {
  productId: 3529,
  vendorId: 1356,
}

const handleS300Polling = async (device: any, dispatch: any, flagAutoPause?: boolean) => {
  
  await device.selectConfiguration(1);
  
  try {
    await device.claimInterface(1);
  } catch (error) {
    ToastNotification.error("別画面で接続されています。接続済みの画面をクローズしてください".split(/(?:<br\/>|<br>|<\/br>|<br \/>)/g).join('\n'));
    dispatch(icCardActions.setIsConnectICCardUsb(false));
    dispatch(icCardActions.setDeviceICCard(null));
  }
  let SvIDm = '' ,
    ChattTime = 5 * 1000 ,													// チャタリング防止時間 5秒
    ChattTimer = null,
    AbortProc = false;

  let arukasNFC = new ArukasNFCLiteS( { warning: false } ) ;
  /* Read Block */
  let ReadOption = {
    Block : [ 0x0B, 0x0C, 0x0D ] ,	// Specify the block address to read
  }
  let readCount = 0;

  await arukasNFC.connectUSBDevice(device) ;									// Connect USB Device
  await arukasNFC.openUSBDevice() ;										// Open USB Device

  for( ; !AbortProc  ; ) {
    try {

      let pollingRes = await arukasNFC.pollingLiteS() ;
      arukasNFC.putErrorMsg( pollingRes.Error ) ;

      if ( arukasNFC.FelicaConfig.Polling === true ) {

        let chattSts = false ;											// チャタリング防止
        let strIDm = _array_tohexs( arukasNFC.FelicaConfig.IDm, 0, arukasNFC.FelicaConfig.IDm.length, '' ) ;
        if ( strIDm != SvIDm ) {												// 保存したIDmと異なっていればチャタリングロック解除
          chattSts = true ;
          clearTimeout( ChattTimer ) ;										// 保存したIDmと異なっていれば、チャタリング防止タイマー停止
          SvIDm = strIDm ;													// IDmを保存
          ChattTimer = setTimeout( () => { SvIDm = '' ; }, ChattTime ) ;		// チャタリング防止タイマー タイム・アウトしたら保存したIDmをクリアしてチャタリングロックを解除する
        }

        if ( chattSts ) {
          if ( arukasNFC.FelicaConfig.SystemCode[0] === 0x88 &&				// カードのシステムコードから FeliCa Lite-S かの判定
            arukasNFC.FelicaConfig.SystemCode[1] === 0xB4 ) {			// FeliCa Lite-S以外のカードはIDmを使います

            let ret = await arukasNFC.readLiteS( ReadOption ) ;				// Lite-S の読み込み
            if ( ret.Error === arukasNFC.ERRORFREE ) {
              console.log('Reading...' + ++readCount)
              console.log('Send    : ' + ret.CommandString +
                '\nReceive : ' + _array_tohexs(ret.Data,0,ret.Data.length,' ') + '\n' + ret.DataString + '\nIDm [' + strIDm + ']') ;
            } else {
              
              
              arukasNFC.putErrorMsg( ret.Error ) ;
            }

          } else {
            console.log('Reading...' + ++readCount) ;		// Lite-S以外はIDmを表示
            console.log('Send    : ' +
              '\nReceive : IDm [' + strIDm + ']')
            if(dispatch){
              dispatch(icCardActions.setIsConnectICCardUsb(true));
              dispatch(icCardActions.setCardId(strIDm));
            }

            if(flagAutoPause){
              dispatch(icCardActions.setDisabledDetect(true));
            }
          }
          console.log('Success');
        } else {
          console.log('Chattering');
        }
      } else {     
        console.log('Polling Failed');
      }
      await arukasNFC.sleep(500) ;
    } catch(error) {
      console.error( error ) ;
      break;
    }
  }

  /* close() */
  await arukasNFC.closeUSBDevice();

  console.log('[Polling to Reading a FeliCa Lite-S Card] End');
}

const handleS380Polling = async (device: any, dispatch: any, flagAutoPause?: boolean) => {
  await device.selectConfiguration(1);
  
  try {
    await device.claimInterface(0);
  } catch (error) {
    ToastNotification.error("別画面で接続されています。接続済みの画面をクローズしてください".split(/(?:<br\/>|<br>|<\/br>|<br \/>)/g).join('\n'));
    dispatch(icCardActions.setIsConnectICCardUsb(false));
    dispatch(icCardActions.setDeviceICCard(null));
  }

  do {
    await session(device, dispatch, flagAutoPause);
    await sleep(500);
  } while (true);
}

export async function detectCard(device: any, dispatch?: any, flagAutoPause?: boolean) {
  
  try {
    
    if (device.productId === S300_IC_CARD.productId && device.vendorId === S300_IC_CARD.vendorId) {
      
      await handleS300Polling(device, dispatch, flagAutoPause);
      
    } else {
      await handleS380Polling(device, dispatch, flagAutoPause);
    }

  } catch (e) {
   
    dispatch(icCardActions.setIsConnectICCardUsb(false));
    dispatch(icCardActions.setDeviceICCard(null));
    await close(device);
  }
}


