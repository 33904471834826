/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/core';
import { grayScale , textFontSize } from 'components/styles';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: grayScale.gray60
  }),
  text: css({
    marginBottom: '20px',
    color: grayScale.gray100,
    fontSize: textFontSize.re
  }), 
};

/**
 * フォームのレイアウト
 *  - display: flexでレイアウト
 *  - ラベルとtextの間にマージンをとる
 */
const VerticalLabelTextLayout: React.FC<{
  label: ReactNode; 
  variable?: any;
}> = ({
  label, variable,
}) => (
  <div css={css(styles.wrapper)}>
    {label}
    <div css={styles.text}>
      {variable}
    </div> 
  </div>
);

export default VerticalLabelTextLayout;
