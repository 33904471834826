import ApiClient from './ApiClient';

export const getTableAgreement36Data = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  params = {
    ...params,
    currentStaffCode,
    currentOrgCode
  }
  const response = await ApiClient.get(`/v1/36Agreements/tableAgreement36/${companyCode}`, {}, params);
  return response.data;
};

export const getAlertAgreement36 = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v1/36Agreements/myAlert/${companyCode}`, {}, params);
  return response.data;
};

export const getTableAlertAgreement36Data = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v2/36Agreements/alertCount/${companyCode}`, {}, params);
  return response.data;
};

export const getMonthAgreement36Data = async (targetMonth: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetMonth
  }
  const response = await ApiClient.get(`/v2/attend36Agreements/month/${companyCode}`, {}, params);
  return response.data;
};

export const getYearAgreement36Data = async (targetYear: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetYear
  }
  const response = await ApiClient.get(`/v1/attend36Agreements/${companyCode}`, {}, params);
  return response.data;
};

export const getMonthInfoAgreement36Data = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v2/36Agreements/monthlyInfo/${companyCode}`, {}, params);
  return response.data;
};

export const getYearInfoAgreement36Data = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v2/36Agreements/yearlyInfo/${companyCode}`, {}, params);
  return response.data;
};


export const getDetailStaffAgreement36 = async (staffCode: string, agreementsId : string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
    agreementsId,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v2/36Agreements/staffDetail/${companyCode}`, {}, params);
  return response.data;
};