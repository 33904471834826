/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsx jsx */
import React, { useState } from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import { SubActionButton } from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import useTermOptions from 'hooks/useTermOptions';
import { AttendEmploymentTermData } from 'domain/employment';
import IconLabelButton from 'components/molecules/IconLabelButton';
import Select from 'components/atoms/Select';
import { Terms } from 'domain/master/attend/terms';
import { css, jsx } from '@emotion/core';

const style = {
  button: css({
    margin: '50px 0px 20px 30px',
    width: '80px'
  },
    css` 
    .button-action button:hover{
      background-color:transparent;
    }
  `),
}
const EmploymentOtherSettings: React.FC<{
  formik: any,
  termMst: Array<Terms>,
  // editEmployment: EmploymentDomain
}> = ({
  formik,
  termMst,
}) => {
    const termOptions = useTermOptions();

    const [selTermLength, setSelTermLength] = useState(formik.values.terms.length);
    const recessTimeAlertItems = [];
    const recessTimeAlert = 3;
    for (let i = 0; i < recessTimeAlert;) {
      recessTimeAlertItems.push(i);
      i += 1;
    }

    const handleAddTerm = () => {
      formik.values.terms.push({
        id: {
          termId: '',
          employmentId: formik.values.employmentId,
          applyStartDate: formik.values.applyStartDate
        },
        termName: '',
        targetType: '',
        startTime: '',
        endTime: '',
        addHourlySalary: 0,
      });
      setSelTermLength(formik.values.terms.length);
    };

    const handleDelTerm = (index: number) => {
      formik.setFieldValue(
        'terms',
        formik.values.terms.filter((term: AttendEmploymentTermData, idx: number) => idx !== index),
      );
      setSelTermLength(formik.values.terms.length);
    };
    const changeSalary = (index: number, e: any) => {
      const term: AttendEmploymentTermData = formik.values.terms[index];
      term.addHourlySalary = e.target.value;
      formik.values.terms.splice(index, 1, term);
      formik.setFieldValue('terms', formik.values.terms);
    };
    return (
      <React.Fragment>
        <div css={style.button}>
          <div className="button-action">
            <IconLabelButton onClick={handleAddTerm} iconType="addCircle" text="追加" />
          </div>
        </div>
        <div style={{ clear: 'both' }}> </div>
        <div style={{ padding: '10px 0px', minHeight: '500px', marginBottom: '30px' }}>
          <table className="table-bordered" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ width: '50px' }}></th>
                <th style={{ textAlign: 'left' }}>名称</th>
                <th style={{ textAlign: 'left' }}>対象</th>
                <th style={{ textAlign: 'left' }}>開始時刻</th>
                <th style={{ textAlign: 'left' }}>終了時刻</th>
                {/* <th style={{ textAlign: 'left' }}>加算額</th> */}
              </tr>
            </thead>
            <tbody>
              {
                formik.values.terms.map((value: AttendEmploymentTermData, index: number) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center' }}>
                      <SubActionButton action={1} text="削除" onClick={() => handleDelTerm(index)} />
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <Select
                        name={`sel_termId_${String(index)}`}
                        value={String(value.id.termId)}
                        setValue={(val: string) => {
                          const term: AttendEmploymentTermData = formik.values.terms[index];
                          if (val.length > 0) {
                            const tgtTerm: Array<Terms> = termMst.filter(
                              (item: Terms) => item.termId === val,
                            );
                            term.id.termId = val;
                            term.targetType = tgtTerm ? String(tgtTerm[0].targetType) : '';
                            term.startTime = tgtTerm ? tgtTerm[0].startTime : '';
                            term.endTime = tgtTerm ? tgtTerm[0].endTime : '';
                          } else {
                            term.id.termId = '';
                            term.targetType = '';
                            term.startTime = '';
                            term.endTime = '';
                          }
                          formik.values.terms.splice(index, 1, term);
                          formik.setFieldValue('terms', formik.values.terms);
                        }}
                        options={termOptions}
                      />
                    </td>
                    <td style={{ width: '200px' }}>
                      <FormLabel
                        label={
                          String(value.targetType) === '0'
                            ? '対象：平日・休日'
                            : String(value.targetType) === '1'
                              ? '対象：平日のみ'
                              : String(value.targetType) === '2'
                                ? '対象：休日のみ'
                                : ''
                        }
                      />
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <FormLabel
                        label={value.startTime}
                      />
                    </td>
                    <td style={{ textAlign: 'left' }} >
                      <FormLabel
                        label={value.endTime}
                      />
                    </td>
                    {/* <td>
                      <HourInput
                        name={`terms_${String(index)}`}
                        value={String(value.addHourlySalary)}
                        label="円"
                        min={0}
                        onChange={(e) => changeSalary(index, e)}
                        placeholder="時間帯加算額"
                      />
                    </td> */}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

export default EmploymentOtherSettings;
