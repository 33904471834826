
import moment from 'moment';
import ApiClient from './ApiClient';

export const getMediaMst = async (companyCode: string, orgCode: string, paths:any): Promise<any> => {
  const params = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/mediaMst/${companyCode}`, params);
  return response.data;
};

export const getmonthlyReport = async (companyCode: string, orgCode: string,
  monthlyStartDate: string, monthlyEndDate:string, paths:any, monthlyComparePrevYear?:any): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };

  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/${companyCode}`, params);
  return response.data;
};

export const getItemsSalesOrderReport = async (companyCode: string, orgCode: string,
  monthlyStartDate: string, startTime: string, monthlyEndDate:string, endTime: string, paths:any, dayOfweek:any, monthlyComparePrevYear?:any): Promise<any> => {
  const {
    itemPub, itemSat, itemSun, itemMon, itemTue, itemWed, itemTur, itemFri,
  } = dayOfweek[0];
  const params = {
    orgCode,
    startDate: monthlyStartDate,
    startTime,
    endDate: monthlyEndDate,
    endTime,
    itemPub,
    itemSat,
    itemSun,
    itemMon,
    itemTue,
    itemWed,
    itemTur,
    itemFri,
  };

  const response = await ApiClient.get(`/v1/sales/list/standard/${paths}/${companyCode}`, params);
  return response.data;
};

export const getTimeListReport = async (companyCode: string, orgCode: string,
  monthlyStartDate: string, startTime: string, monthlyEndDate:string, endTime: string, paths:any, dayOfweek:any, monthlyComparePrevYear?:any): Promise<any> => {
  const {
    timePub,
    timeSat,
    timeSun,
    timeMon,
    timeTue,
    timeWed,
    timeTur,
    timeFri,
  } = dayOfweek[0];

  const params = {
    orgCode,
    startDate: monthlyStartDate,
    startTime,
    endDate: monthlyEndDate,
    endTime,
    timePub,
    timeSat,
    timeSun,
    timeMon,
    timeTue,
    timeWed,
    timeTur,
    timeFri,
  };


  const response = await ApiClient.get(`/v1/sales/list/standard/${paths}/${companyCode}`, params);
  return response.data;
};

export const getYearlyReport = async (companyCode: string, orgCode: string,
  yearlyStartDate: string, yearlyEndDate:string, paths:any, monthlyComparePrevYear?:any): Promise<any> => {
  const params = {
    orgCode,
    yearlyStartDate,
    yearlyEndDate,
  };

  const response = await ApiClient.get(`/v1/sales/list/standard/yearly/${paths}/${companyCode}`, params);
  return response.data;
};

export const getFiscalYear = async (companyCode: string): Promise<any> => {
  const params = {
    date: moment(new Date()).format('YYYY-MM-DD'),
  };

  const response = await ApiClient.get(`/v1/fiscalYear/targetdate/${companyCode}`, params);
  return response.data;
};


export const getBudgetPerfomanceManagement = async (companyCode: string, orgCode: string,
  monthlyStartDate: string, monthlyEndDate:string, paths:any, monthlyComparePrevYear?:any): Promise<any> => {
  const params = {
    orgCode,
    monthlyStartDate,
    monthlyEndDate,
    monthlyComparePrevYear,
  };
  console.log(`/v1/sales/list/standard/monthly/${paths}/${companyCode}`);
  const response = await ApiClient.get(`/v1/sales/list/standard/monthly/${paths}/${companyCode}`, params);
  return response.data;
};

export const downloadMonthlyReportCsv = async (monthlyReport: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, monthlyReport, fileName);
};

export const downloadBudgetPerformanceManagementCsv = async (monthlyReport: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadCsvPost(`/v1/reportExportFile/csv/${companyCode}`, {}, monthlyReport, fileName);
};

export const downloadMonthlyReportExcel = async (monthlyReport: any, fileName:any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/reportExportFile/excel/${companyCode}`, {}, monthlyReport, fileName);
};

export default getmonthlyReport;
