/** @jsx jsx */
import React, { useState, useMemo, useEffect } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import {
  useOrgTreesOptionsShopV1,
} from "hooks/useOrgTreesOptions";
import moment from "moment";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import FormField from "components/atoms/Form/FormField";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import SelectForm from "components/molecules/SelectForm";
import useToastNotification from "hooks/useToastNotification";
import { getSearchDateClosing } from "api/stampHistory";
import { useStaffSelectBoxRange } from "hooks/useEmploymentOptions";
import BlockUI from "components/molecules/BlockUi";
import { css, jsx } from "@emotion/core";
import useKaioriHistory from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import FormTitle from "components/atoms/Form/FormTitle";
import TextTooltip from "components/molecules/TextTooltip";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import { functionCode } from "const";
import StatusTemperature from "components/molecules/StatusTemperature";
import ReactTooltip from "react-tooltip";
import { utilityColor } from "components/styles";
import ImageModel from "components/organismos/ImageModel";
import StampHistoryListRecorderDomain from "domain/timeRecorder/stampHistoryListRecorderDomain";

const styles = {
  table: css({
  },css`
    table{
      table-layout: auto
    }
  `),
  temperatureFormat: (temperature: number, temperatureNormal: number) => css({
    color: temperature > temperatureNormal ? '#CC4D4D' : '#4DCC62',
  })
}

const isIpad = window.matchMedia("(max-width: 1024px)").matches;
const KaorioHistoryLayout = () => {
  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth();
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(y, m, 1));
  const [targetDateTo, setTargetDateTo] = useState(new Date(y, m + 1, 0));

  const [isDisplayTemperature] = useState<boolean>(sessionStorage.getItem('isDisplayTemperature') === 'true'?  true : false);
  const [isDisplayTemperatureStatus] = useState<boolean>(sessionStorage.getItem('isDisplayTemperatureStatus') === 'true'?  true : false);
  const [temperatureNormal] = useState<number>(Number(sessionStorage.getItem('loginUser.temperature')) || 0 );

  useEffect(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    getSearchDateClosing(companyCode).then((data) => {
      const dateFrom = new Date();
      setTargetDateFrom(new Date(data[0]));
      setTargetDateTo(new Date(data[1]));
      if (data[0] == null || data[1] == null) {
        setTargetDateFrom(
          new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1)
        );
        setTargetDateTo(
          new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0)
        );
      }
    });
  }, []);

  const { errorNotification } = useToastNotification();

  const orgTreesOptions = useOrgTreesOptionsShopV1(
    true,
    functionCode.kaoiroHistory,
    moment(targetDateFrom).format("YYYY-MM-DD"),
    moment(targetDateTo).format("YYYY-MM-DD")
  );
  const [orgCode, setorgCode] = useState(
    sessionStorage.getItem("loginUser.orgCode") || ""
  );

  const [staffCode, setStaffCode] = useState("");

  const { kaioriHistory, blocking, underFlag, setUnderFlag } = useKaioriHistory(
    orgCode || "",
    staffCode,
    moment(targetDateFrom).format("YYYY年MM月DD日"),
    moment(targetDateTo).format("YYYY年MM月DD日")
  );

  const useStaffOptions = useStaffSelectBoxRange(
    targetDateFrom,
    targetDateTo,
    orgCode,
    true,
    functionCode.kaoiroHistory,
    underFlag
  );

  // 対象日の範囲が１年を超えていた場合、エラーとする
  const [stampHistoryNull, setstampHistoryNull] = useState(true);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  useEffect(() => {
    const startDay = new Date(
      Date.parse(moment(targetDateFrom, "YYYY年MM月DD日").format("YYYY/MM/DD"))
    );
    const endDay = new Date(
      moment(targetDateTo, "YYYY年MM月DD日").format("YYYY/MM/DD")
    );
    const startDayNextYear = new Date(
      startDay.getFullYear() + 1,
      startDay.getMonth(),
      startDay.getDate()
    );
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      errorNotification("期間の範囲は１年以内で入力してください");
      setstampHistoryNull(false);
    } else {
      setstampHistoryNull(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);

 
  const columns: Array<Column<StampHistoryListRecorderDomain>> = React.useMemo(
    () => [
      {
        Header: () => "氏名",
        accessor: "staffName",
        id: "staffName",
        overFlow: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => {
          const [imageOpen, setImageOpen] = useState(false);
          const {
            staffCode,
            staffName,
            stampTime,
            stampName,
          } = cell.row.original;
          const imageURL = cell.row.original.photoFullpath;
          return (
            <FlexBox customStyle={css({ display: `${isIpad ? "block" : ""}` })}>
              <div>
                {imageURL && (
                  <React.Fragment>
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        overflowWrap: "anywhere",
                        justifyContent: `${isIpad ? "center" : ""}`,
                      }}
                    >
                      <img
                        src={"data:image/png;base64," + imageURL}
                        alt={"avatar"}
                        width={55}
                        height={55}
                        style={{
                          cursor: "pointer",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                        onClick={() => setImageOpen(true)}
                      />
                      
                      <ImageModel
                        open={imageOpen}
                        closeHandler={() => {
                          setImageOpen(false);
                        }}
                        imageURL={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                        descript={staffCode + "/" + staffName + "/" + stampName + "/" + moment(stampTime).format("YYYY/MM/DD HH:mm")}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
              <TextTooltip
                text={cell.row.original.staffName}
                customCss={css({
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: `${imageURL ? "70px" : "130px"}`,
                  whiteSpace: "nowrap",
                  display: "block",
                  margin: "0px auto",
                })}
              />
            </FlexBox>
          );
        },
      },
      {
        Header: "スタッフコード",
        accessor: "staffCode",
        overFlow: true,
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
        overFlow: true,
      },
      {
        Header: "組織名",
        accessor: "orgName",
        overFlow: true,
      },
      {
        Header: () => "打刻種別",
        accessor: "errorMessage",
        id: "errorMessage",
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => {
          const { stampName, isRemote } = cell.row.original;
          if (
            cell.row.original.errorMessage === null ||
            cell.row.original.errorMessage === ""
          ) {
            return (
              <div>
                {stampName === "出勤"
                  ? isRemote
                    ? stampName +
                      `${
                        companyCode === "HPo6wkyuze"
                          ? " (在宅)"
                          : " (テレワーク)"
                      }`
                    : stampName + " (オフィス)"
                  : stampName}
              </div>
            );
          }
          if (
            cell.row.original.errorMessage !== null &&
            cell.row.original.errorMessage !== ""
          ) {
            return (
              <span>
                <div
                  css={css({ color: utilityColor.note })}
                  data-tip={cell.row.original.errorMessage}
                >
                  {stampName}
                </div>
                <ReactTooltip html={true} />
              </span>
            );
          }
        },
      },
      {
        Header: () => "打刻時刻",
        accessor: "stampTime",
        id: "stampTime",
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => cell.row.original.stampTime ? moment(cell.row.original.stampTime).format("YYYY/MM/DD HH:mm:ss") : "",
      },
      {
        Header: () => "体温",
        accessor: "temperature",
        id: "temperature",
        show:  isDisplayTemperature,
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => {
          return <span css={styles.temperatureFormat(cell.row.original.temperature, temperatureNormal)}>{cell.row.original.temperature}</span>
        },
      },
      {
        Header: () => "通行状態",
        accessor: "temperature_status",
        id: "temperature_status",
        show: isDisplayTemperatureStatus,
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => {
          return <StatusTemperature
            isMask={cell.row.original.isMask}
            temperature={cell.row.original.temperature}
            temperatureNormal={temperatureNormal}
          />
        },
      },
    ],
    []
  );

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents customStyle={{ marginBottom: "50px" }}>
          <FormTitle
            title="Kaoiro打刻実績"
            bold={true}
            customStyle={css`
              margin: 16px 35px;
            `}
          />
          <FormPadding customStyle={{ padding: "0 0 0 35px" }}>
            <FormField>
              <FlexBox>
                <FlexBoxItem width="100px">
                  <FormLabel label="期間" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <CalendarRangeV2
                    width="auto"
                    startDate={targetDateFrom}
                    onChangeStart={setTargetDateFrom}
                    endDate={targetDateTo}
                    onChangeEnd={(date) => {
                      if (date) {
                        setTargetDateTo(date);
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <FlexBox>
                <SelectForm
                  label="組織"
                  name="orgCode"
                  value={String(orgCode)}
                  setValue={(v) => {
                    setorgCode(v);
                  }}
                  options={orgTreesOptions}
                  description=""
                  width="900px"
                  widthLabel="100px"
                  marginRight="30px"
                />
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <SelectForm
                label="スタッフ"
                name="staffCode"
                value={String(staffCode)}
                setValue={(v) => {
                  setStaffCode(v);
                }}
                options={useStaffOptions}
                description=""
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
            </FormField>
          </FormPadding>
          <div css={styles.table}>
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <DataTable
                className="table-no-border-left-right"
                columns={columns}
                data={stampHistoryNull ? kaioriHistory : []}
                isGlobalFilter={true}
                globalSearchStyle={css({ marginLeft: "35px" })}
                minWidth="1080px"
              />
            </div>
          </div>
        </FormContents>
      </div>
    </BlockUI>
  );
};

export default KaorioHistoryLayout;
