/** @jsx jsx */
import React from "react";
import moment from "moment";
import { css, jsx } from "@emotion/core";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "center",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "center",
});

const TransferHolidayTable: React.FC<{
  transferHolidayUsingStatusList: any;
}> = ({ transferHolidayUsingStatusList }) => (
  <div
    css={css`
      table thead tr th {
        height: 46px;
      }
      table tbody tr td {
        height: 32px;
      }
    `}
  >
    <table
      id="transferHolidayList"
      className="table table-bordered table-condensed table-no-border-left-right"
      cellSpacing="0"
      style={{ whiteSpace: "nowrap", width: "100%" }}
    >
      <tbody>
        <tr role="row">
          <td>
            <div css={thStyle}>振替出勤日</div>
          </td>
          <td>
            <div css={thStyle}>振替休日</div>
          </td>
        </tr>

        {transferHolidayUsingStatusList?.map((transfer: any, index: any) => (
          <tr role="row" key={String(index)}>
            <td>
              <div css={tdStyle}>
                {transfer.transferWorkDate === "" ? "" : moment(transfer.transferWorkDate).format("YYYY年MM月DD日")}
              </div>
            </td>
            <td>
              <div css={tdStyle}>
                {transfer.transferHolidayDate === ""
                  ? ""
                  : moment(transfer.transferHolidayDate).format("YYYY年MM月DD日")}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
export default TransferHolidayTable;
