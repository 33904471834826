/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react';
import BodyText from 'components/atoms/BodyText';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { PrimaryButton, SecondaryButton } from 'components/atoms/Button';
import TextForm from 'components/molecules/TextForm';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import TimeRecorderbar from 'components/organismos/TimeRecorderbar/TimeRecorderbar';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import { utilityColor } from 'components/styles';
import BlockUI from 'components/molecules/BlockUi';
import Webcam from 'react-webcam';
import Modal from 'components/molecules/Modal';
import FormContents from 'components/atoms/Form/FormContents';
import useAttendTimeRecorder from './hooks';
import AttendTimeRecorderConfirm from './AttendTimeRecorderConfirm/AttendTimeRecorderConfirm';
import TimeRecorderTimeHeader from './TimeRecorderTimeHeader/TimeRecorderTimeHeader';

const styles = {

  header: css({
    margin: '29px 0 32px 0',
    textAlign: 'center',
  }),

  container: css({
    padding: isMobileOnly ? '0' : '0 34px 0 34px',
    flexWrap: 'wrap',
    marginBottom: '50px',
  }),

  timeTitle: css({
    fontSize: '64px',
  }),
  imageUploadContainer: css({
    width: isMobileOnly ? '90%' : '90%',
    marginTop: '8px',
    maxWidth: '90%',
    backgroundColor: '#E5E5E5',
    height: isMobileOnly ? '255px' : '90%',
    marginBottom: '8px',
  }),
  imageConfirmMobile: css({
    width: '120px',
    height: '120px',
  }),
  imageConfirm: css({
    marginTop: '0',
  }),
  justifyCenter: css({
    justifyContent: 'center',
  }),

  button: css({
    fontSize: '64px',
  }),

  staffName: css({
    textAlign: 'center',
    fontSize: '36px',
  }),

  staffCode: css({
    textAlign: 'center',
    fontSize: '36px',
  }),

  containerStaffConfirm: css({
    width: '50%',
  }),

  imageContainerConfirmMobile: css({
    width: '90%',
    margin: 'auto',
  }),
  canvas: css({
    width: '100%',
    height: '100%',
    border: '1px solid #000',
  }),
  errCamera: css({
    width: '400px',
    margin: 'auto',
    backgroundColor: '#f39c12',
    color: 'white',
    textAlign: 'center',
    padding: ' 15px',
    marginBottom: '10px',
    maxWidth: 'calc( 100% - 30px )',
  }),
  fadeOut: css({
    opacity: '0',
    transition: '2s',
  }),

};

const buttonSubmitContainer = css`
  text-align: center;
  width: ${isMobileOnly ? '100%' : '50%'};
  button {
    height: 55px;
    margin-bottom: 15px;
    width: 70%;
    padding-top: ${isMobileOnly ? '3px' : '0'};
  }
  button[disabled]:hover{
    background-color: #D6D6D6;
    border: 1px solid #EAEAEA;
    color: #ADADAD;
  }
`;


const tableHistory = css`
  margin-top: ${isMobileOnly ? '50px' : '0'};
  overflow-x: scroll;
  width: 100%;
  max-width: 100vw;
  div{
    width: max-content;
  }
  table tbody tr td:last-child {
    text-overflow: inherit !important;
    overflow: inherit !important;
  }
  table tbody tr td{
    height:40px;
  }
  table{
    width: auto;
    min-width: 100%;
  }
  table img{
    margin-top: 5px;
  }
`;


const buttonIdContainer = css`
  button {
    height: 40px;
    width: 70px;
  }
  input {
    height: 40px;
    background-color: #E5E5E5;
  }
  padding: 0 10px 0 10px;
`;

const buttonMistake = css`
  button {
    width: 50%;
    margin-top:10px; 
  }
  text-align: center;
`;

const modalImage = css`
  div:not(:first-child) div button{
    display: none;
  }
`;

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: 'user',
  border: '1px solid #000',
};
const AttendTimeRecorder: React.FC = () => {
  // アップロードファイル
  const canvasRef1 = useRef<HTMLCanvasElement>(null);
  const webcamRef = useRef(null);
  const [errCamera, setErrCamera] = useState('');
  const [fadeOutErr, setFadeOutErr] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imgModal, setImgModal] = useState<any>();


  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOutErr(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);


  const {
    orgTimeRecorder,
    useFaceStamp,
    useBarcodeStamp,
    stampOrgName,
    loginOrgCode,
    loginOrgName,
    barcodeId,
    formik,
    stampOrgCode,
    stampHistoryList,
    serverTimeState,
    isConfirm,
    staffName,
    businessCnt,
    lastStampType,
    setLastStampType,
    businessList,
    stampMode,
    handleSubmitBarcode,
    isLoading,
    handleSubmitImage,
    setStampMode,
    photoFullpath,
    useRecess,
    tmpFilePath,
    faceId,
    similarity,
    mistake,
    isPhoto,
    setIsPhoto,
    handleMistake,
    setIsConfirm,
    setStampHistoryList,
    setPhotoFullpath,
  } = useAttendTimeRecorder();
  const handleOpenImageModal = (image: string) => {
    setOpenModal(true);
    setImgModal(image);
  };
  const columns: Array<Column<any>> = React.useMemo(() => [
    {
      Header: () => null,
      accessor: 'photoFullpath',
      id: 'photoFullpath',
      Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => {
        const imageURL = cell.row.original.photoFullpath;
        return imageURL ? (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img src={imageURL} alt={imageURL} width={30} height={30} onClick={() => handleOpenImageModal(cell.row.original)} />
        ) : '';
      },
    },
    {
      Header: '氏名',
      accessor: 'staffName',
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
    },
    {
      Header: '打刻方法',
      accessor: 'stampModeName',
    },
    {
      Header: () => '打刻種別',
      accessor: 'errorMessage',
      id: 'errorMessage',
      Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => {
        if (cell.row.original.errorMessage === null || cell.row.original.errorMessage === '') {
          return cell.row.original.stampName;
        }
        if (cell.row.original.errorMessage !== null && cell.row.original.errorMessage !== '') {
          return (
            <span>
              <div css={css({ color: utilityColor.error })} data-tip={cell.row.original.errorMessage}>{cell.row.original.stampName}</div>
              <ReactTooltip html={true} />
            </span>
          );
        }
      },
    },
    {
      Header: () => '打刻時刻',
      accessor: 'stampTime',
      id: 'stampTime',
      Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => (
        cell.row.original.stampTime ? moment(cell.row.original.stampTime).format('YYYY/MM/DD HH:mm:ss') : ''
      ),
    },
  ], []);
  // eslint-disable-next-line no-shadow
  const capture = (webcamRef: any) => {
    setStampMode(1);
    setDisableButtonCap(true);

    //  img 64
    const base64string = webcamRef.current.getScreenshot();
    if (!canvasRef1.current) {
      setDisableButtonCap(false);
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef1.current;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      setDisableButtonCap(false);
      return;
    }
    const img = new Image();
    img.crossOrigin = '';

    // eslint-disable-next-line no-loop-func
    img.onload = () => {
      const w = img.width;
      const h = img.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = w;
      canvas.height = h;
      ctx.transform(-1, 0, 0, 1, w, 0);
      ctx.drawImage(img, 0, 0, w, h);
      canvas.toBlob((blob: any) => {
        handleSubmitImage(blob);
        setDisableButtonCap(false);
      }, 'image/jpeg');
    };
    img.onerror = () => {
      setDisableButtonCap(false);
    };
    img.src = base64string;
  };


  const onUserMediaError = (err: any) => {
    if (err) {
      setErrCamera('カメラへのアクセスに失敗しました。');
      setDisableButtonCap(true);
    }
  };

  const [disableButtonCap, setDisableButtonCap] = useState(false);
  return (
    <BlockUI blocking={isLoading}>
      <TimeRecorderbar shopName={orgTimeRecorder.shopName} />
      {/* <AttendTimeRecorderHeader dateFormat={dateFormat} timeFormat={timeFormat} /> */}
      <TimeRecorderTimeHeader />
      {errCamera
        && (
          <div css={[styles.errCamera, fadeOutErr ? styles.fadeOut : {}]}>{errCamera}</div>
        )}
      <FlexBox alignItems={(!isConfirm || stampMode !== 3) ? 'flex-start' : 'center'} customStyle={styles.container}>
        <FlexBoxItem basis={isMobileOnly ? '100%' : '50%'} width={isMobileOnly ? '100%' : '50%'}>
          <FlexBox customStyle={styles.justifyCenter}>
            {
              (!isConfirm || stampMode !== 3)
              && (
                <div css={[styles.imageUploadContainer,
                  isConfirm && styles.imageConfirm,
                  (isMobileOnly && isConfirm) ? styles.imageConfirmMobile : {},
                ]}
                >
                  {
                    !isPhoto
                    && (
                      <Webcam
                        audio={false}
                        height="100%"
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        videoConstraints={videoConstraints}
                        onUserMediaError={onUserMediaError}
                        mirrored={true}
                      />
                    )
                  }
                  { photoFullpath
                    && (<img width="100%" height="100%" alt="" src={photoFullpath} />)}

                  <canvas ref={canvasRef1} id="canvas1" css={styles.canvas} style={{ display: 'none' }} />
                </div>
              )
            }

            {isMobileOnly && isConfirm && (
              <div css={styles.containerStaffConfirm}>
                <div css={styles.staffName}>{`${staffName}さん、出勤打刻を押してください。`}</div>
                {/* <div css={styles.staffCode}>{staffCode}</div> */}
              </div>
            )}
          </FlexBox>
          <FlexBox customStyle={styles.justifyCenter}>
            <div css={buttonSubmitContainer}>
              {
              !isConfirm
                ? (
                  <PrimaryButton
                    text="撮影"
                    disabled={!useFaceStamp || disableButtonCap}
                    onClick={() => capture(webcamRef)}
                    minWidth="70%"
                  />
                )
                : (<span css={styles.staffName}>{!isMobileOnly ? `${staffName}さん` : ''}</span>)
            }
            </div>
          </FlexBox>

          <div css={buttonIdContainer}>
            <FlexBox customStyle={styles.justifyCenter}>
              {
              !isConfirm
                ? (
                  <React.Fragment>
                    <FlexBoxItem>
                      <TextForm
                        name="inputBarcodeId"
                        label=""
                        value={formik.values.inputBarcodeId}
                        onChange={formik.handleChange}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <PrimaryButton
                        text="ID打刻"
                        onClick={handleSubmitBarcode}
                        minWidth="60%"
                        disabled={!useBarcodeStamp}
                      />
                    </FlexBoxItem>
                  </React.Fragment>
                )
                : (<span css={styles.staffCode}>{!isMobileOnly ? 'お疲れ様です。' : ''}</span>)
            }
            </FlexBox>
          </div>
          { photoFullpath
            && (
              <div css={buttonMistake}>
                <SecondaryButton
                  text="人違い"
                  onClick={() => handleMistake()}
                  minWidth="70%"
                />
              </div>
            )}
        </FlexBoxItem>
        {!isConfirm
          ? (
            <FlexBoxItem basis={isMobileOnly ? '100%' : '50%'} width={isMobileOnly ? '100%' : '50%'}>
              <FormContents>
                <div css={tableHistory}>
                  <BodyText size="xl2">打刻履歴</BodyText>
                  <DataTable
                    columns={columns}
                    data={stampHistoryList}
                    isGlobalFilter={true}
                  />
                </div>
              </FormContents>
            </FlexBoxItem>
          )
          : (
            <AttendTimeRecorderConfirm
              businessCnt={businessCnt}
              stampOrgCode={stampOrgCode}
              stampOrgName={stampOrgName}
              lastStampType={lastStampType}
              setLastStampType={setLastStampType}
              setIsConfirm={setIsConfirm}
              setStampHistoryList={setStampHistoryList}
              businessList={businessList}
              barcodeId={barcodeId}
              staffName={staffName}
              inputBarcodeId={formik.values.inputBarcodeId}
              formik={formik}
              serverTimeState={serverTimeState}
              loginOrgName={loginOrgName}
              loginOrgCode={loginOrgCode}
              stampMode={stampMode}
              useRecess={useRecess}
              tmpFilePath={tmpFilePath}
              faceId={faceId}
              similarity={similarity}
              mistake={mistake}
              setPhotoFullpath={setPhotoFullpath}
              setIsPhoto={setIsPhoto}
            />
          )}
      </FlexBox>
      <div css={modalImage}>
        <Modal
          open={openModal}
          closeHandler={() => setOpenModal(false)}
          title=""
          submitText=""
          
          onSubmit={() => setOpenModal(false)}
        >
          {!!imgModal
          && (
            <React.Fragment>
              <img src={imgModal.photoFullpath} alt={imgModal.photoFullpath} />
              <br />
              {imgModal.staffCode}
              /
              { imgModal.staffName }
              /
              {imgModal.stampName}
              /
              { moment(imgModal.stampTime).format('YYYY/MM/DD HH:mm') }
            </React.Fragment>
          )}

        </Modal>
      </div>
    </BlockUI>
  );
};

export default AttendTimeRecorder;
