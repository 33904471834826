/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import TextFormSearch from "components/molecules/TextFormSearch";
import { notMinScreen } from "utility/mediaQueries";

export type GlobalFilterProps = {
  state: any;
  setGlobalFilter: any;
  tableId?: number;
};

const GlobalFilter: React.FC<GlobalFilterProps> = ({ state, setGlobalFilter, tableId }) => (
  <div className="input-search">
    <TextFormSearch
      name="globalFilter"
      label=""
      customStyle={css({
        border: "none",
        [notMinScreen("md")]: {
          borderRadius: "10px",
        },
      })}
      placeHolder="絞り込み検索"
      value={state.globalFilter || ""}
      onChange={(e) => {
        setGlobalFilter(e.target.value || undefined);
        sessionStorage.setItem(`${tableId}/sessionData${window.location.pathname}.globalFilterTxt`, e.target.value);
      }}
    />
  </div>
);
export default GlobalFilter;
