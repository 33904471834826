/* eslint-disable max-len */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import StaffDomain from "domain/staff";
import { getSpecificStaff, getHolidaysOptions } from "api/staff";
import useRolesOptions from "hooks/useRoleOptions";
import useBusinessMasterOptions from "hooks/useBusinessMasterOptions";
import useExecutiveOptions from "hooks/useExecutiveOptions";
import { OptionType } from "components/atoms/Select";

import { getEmploymentSelectListYm } from "api/employment";
import { fetchBreakUUID } from "api/shiftPattern";
import ShiftPatternDomain from "domain/master/attend/shiftPattern";
import { getListICCardByStaff } from "api/iCCard";
import useTermMasterByCompanyCodeOptions from "hooks/useTermMasterOptions";
import moment from "moment";
import { functionCode } from "const";
import { getTreesOrgSelectRange } from "api/organization";

interface holidayType {
  holidayId: string;
  holidayType: number;
  holidayTypeStr: string;
  holidayCode: string;
  holidayName: string;
}

export const useStaffEdit = () => {
  const { staffCode } = useParams<{ staffCode: string }>();
  const [staffDomain, setStaffDomain] = useState<StaffDomain>(StaffDomain.generateInitial());
  const [holidayOptions, setHolidayOptions] = useState<Array<OptionType>>([]);
  const [holidayTypeOptions, setHolidayTypeOptions] = useState<Array<holidayType>>([]);

  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  const [orgTargetDate, setOrgTargetDate] = useState<Date>(new Date());
  const [empTargetDate, setEmpTargetDate] = useState<Date>(new Date());
  const [breakBusiness, setBreakBusiness] = useState<ShiftPatternDomain>(ShiftPatternDomain.generateInitial());
  const [isLoading, setIsLoading] = useState(false);
  const { roleOptions } = useRolesOptions();
  const businessOptions = useBusinessMasterOptions();
  const termOptions = useTermMasterByCompanyCodeOptions();
  const executiveOptions = useExecutiveOptions();
  const trialPeriodOptions: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({
    value: item.toString(),
    label: item === 0 ? "なし" : `${item}ヶ月`,
  }));
  const [modalOrgTreeOptions, setModalOrgTreeOptions] = useState<Array<OptionType>>([]);

  useEffect(() => {
    setIsLoading(true);
    if (staffCode) {
      const fetchData = async () => {
        const staffResult = await getSpecificStaff(staffCode);
        const staffDomainResult = new StaffDomain(staffResult);
        getListICCardByStaff(staffCode || "")
          .then((response: any) => {
            staffDomainResult.icCardListData = response.map((card: any) => {
              return {
                cardId: card.cardId,
              };
            });
            setIsLoading!(false);
          })
          .catch((error: any) => {
            setIsLoading!(false);
          });
        setStaffDomain(staffDomainResult);
        setOrgTargetDate(staffDomainResult.hireDate!);
        setEmpTargetDate(staffDomainResult.empApplyDate!);
      };
      fetchData();
    }
  }, [staffCode]);

  useEffect(() => {
    fetchBreakUUID().then((response: ShiftPatternDomain) => {
      sessionStorage.setItem("breakTimeBusinessId", response.businessId);
      setBreakBusiness(response);
    });
  }, []);

  useEffect(() => {
    getHolidaysOptions().then((holidays) => {
      const holidayOption = holidays.map((holiday: any) => ({
        value: holiday.holidayId,
        label: `${holiday.holidayName}`,
      }));

      const holidayTypeOption = holidays.map((holiday: any) => ({
        holidayType: holiday.holidayType,
        holidayId: `${holiday.holidayId}`,
        holidayTypeStr: holiday.holidayTypeStr,
        holidayCode: holiday.holidayCode,
        holidayName: holiday.holidayName,
      }));

      holidayOption.unshift({
        value: "",
        label: "-",
      });

      setHolidayTypeOptions(holidayTypeOption);
      setHolidayOptions(holidayOption);
    });
  }, []);

  useEffect(() => {
    getEmploymentSelectListYm(empTargetDate).then((employments) => {
      const employmentO = employments.map((employment) => ({
        value: employment.employmentId,
        label: `${employment.employmentCode} ${employment.employmentName}`,
      }));
      employmentO.unshift({
        value: "",
        label: "-",
      });
      if (!employmentO.map((item) => item.value).includes(staffDomain.employmentId)) {
        const newStaff = staffDomain.getRawData();
        newStaff.employmentId = "";
        setStaffDomain(new StaffDomain(newStaff));
      }
      setEmploymentOptions(employmentO);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empTargetDate]);

  useEffect(() => {
    // modalOrgTreeOptions
    getTreesOrgSelectRange(
      false,
      functionCode.staffs,
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
    ).then((trees) => {
      const treesOrg = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));
      setModalOrgTreeOptions(treesOrg);
    });
  }, []);

  return {
    isLoading,
    staffDomain,
    setStaffDomain,
    roleOptions,
    businessOptions,
    termOptions,
    trialPeriodOptions,
    employmentOptions,
    executiveOptions,
    setOrgTargetDate,
    setEmpTargetDate,
    breakBusiness,
    setIsLoading,
    holidayOptions,
    holidayTypeOptions,
    modalOrgTreeOptions,
  };
};

export default useStaffEdit;
