/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable no-self-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { getBiasSetting, salesBudgetPlanDaily, updateSalesBudgetMonth } from 'api/salesBudgetYear';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import SalesBudgetMonthDomain from 'domain/master/storeManagement/salesBudgetMonth';
import useToastNotification from 'hooks/useToastNotification';

export const useSalesBudgetMonth = () => {
  const [isLoading, setIsLoading] = useState(true);

  let amountDayInMonth = 0;
  let sumOfAdvertisingSale = 0;
  const [getSumOfAdvertisingSale, setSumOfAdvertisingSale] = useState(0);

  let sumOfAdvertisingExpense = 0;
  const [getSumOfAdvertisingExpense, setSumOfAdvertisingExpense] = useState(0);

  const listSumOfAdvertisingEachDay: any[] = [];
  const [getListSumOfAdvertisingEachDay, setListSumOfAdvertisingEachDay] = useState();

  let listSumOfAdvertisingExpenseEachDay: any[] = [];
  const [getListSumOfAdvertisingExpenseEachDay, setListSumOfAdvertisingExpenseEachDay] = useState();

  const listSumOfAdvertisingSaleAndSaleBudgetFree: any[] = [];
  const [getListSumOfAdvertisingSaleAndSaleBudgetFree, setListSumOfAdvertisingSaleAndSaleBudgetFree] = useState();

  let sumOfSaleBudgetFreeRow = 0;
  let sumOfFoodCostRow = 0;
  let sumOfDrinkCostRow = 0;
  const listSumOfFoodCostAndDrinkCost: any[] = [];
  const [getListSumOfFoodCostAndDrinkCost, setListSumOfFoodCostAndDrinkCost] = useState();

  let sumOfTotalFoodCostAndDrinkCost = 0;
  const [getSumOfTotalFoodCostAndDrinkCost, setSumOfTotalFoodCostAndDrinkCost] = useState(0);

  const listGrossProfit: any[] = [];
  const [getListGrossProfit, setListGrossProfit] = useState();

  let sumOfListGrossProfit = 0;
  const [getSumOfListGrossProfit, setSumOfListGrossProfit] = useState(0);

  const listRateOfGrossProfit: any[] = [];
  const [getListRateOfGrossProfit, setListRateOfGrossProfit] = useState();


  let rateOfSumGrossProfitAndSumAmountOfSale = 0;
  const [getRateOfSumGrossProfitAndSumAmountOfSale, setRateOfSumGrossProfitAndSumAmountOfSale] = useState(0);

  let sumOfEmployeeLaborCostRow = 0;
  const [getSumOfEmployeeLaborCostRow, setSumOfEmployeeLaborCostRow] = useState(0);

  let sumOfPartLaborCostRow = 0;
  const [getSumOfPartLaborCostRow, setSumOfPartLaborCostRow] = useState(0);

  const listSumOfEmployeeLaborCostAndPartLaborCost: any[] = [];
  const [getListSumOfEmployeeLaborCostAndPartLaborCost, setListSumOfEmployeeLaborCostAndPartLaborCost] = useState();

  let sumOfTotalEmployeeLaborCostAndPartLaborCost = 0;
  const [getSumOfTotalEmployeeLaborCostAndPartLaborCost, setSumOfTotalEmployeeLaborCostAndPartLaborCost] = useState(0);

  let sumOfLandRentRow = 0;
  const [getSumOfLandRentRow, setSumOfLandRentRow] = useState(0);

  let sumOfOtherVariableCostsRow = 0;
  const [getSumOfOtherVariableCostsRow, setSumOfOtherVariableCostsRow] = useState(0);

  let sumOfOtherFixedCostsRow = 0;
  const [getSumOfOtherFixedCostsRow, setSumOfOtherFixedCostsRow] = useState(0);

  const listSumOfOtherVariableCostsAndOtherFixedCosts: any[] = [];
  const [getListSumOfOtherVariableCostsAndOtherFixedCosts, setListSumOfOtherVariableCostsAndOtherFixedCosts] = useState();
  let sumOfTotalOtherVariableCostsAndOtherFixedCosts = 0;
  const [getSumOfTotalOtherVariableCostsAndOtherFixedCosts, setSumOfTotalOtherVariableCostsAndOtherFixedCosts] = useState(0);


  const listOfOperatingIncome: any[] = [];
  const [getListOfOperatingIncome, setListOfOperatingIncome] = useState();

  let totalOfOperatingIncome = 0;
  const [getTotalOfOperatingIncome, setTotalOfOperatingIncome] = useState(0);

  const listOfRateOperatingIncome: any[] = [];
  const [getListOfRateOperatingIncome, setListOfRateOperatingIncome] = useState();

  let rateOfOperationIncome = 0;

  const [getRateOfOperationIncome, setRateOfOperationIncome] = useState(0);

  const listNumberOfCustomers: any[] = [];
  const [getListNumberOfCustomers, setListNumberOfCustomers] = useState();


  let sumOfNumberOfCustomers = 0;
  const [getSumOfNumberOfCustomers, setSumOfNumberOfCustomers] = useState(0);

  const listOfTurnoverRate: any[] = [];
  const [getListOfTurnoverRate, setListOfTurnoverRate] = useState();

  let sumOfTurnoverRate = 0;

  const [getSumOfTurnoverRate, setSumOfTurnoverRate] = useState(0);

  const listOfMarginalProfit: any[] = [];
  const [getListOfMarginalProfit, setListOfMarginalProfit] = useState();


  let sumOfMarginalProfit = 0;
  const [getSumOfMarginalProfit, setSumOfMarginalProfit] = useState(0);

  const listRateOfMarginalProfit: any[] = [];
  const [getListRateOfMarginalProfit, setListRateOfMarginalProfit] = useState();

  let sumOfRateMarginalProfit = 0;
  const [getSumOfRateMarginalProfit, setSumOfRateMarginalProfit] = useState(0);

  const listOfContributionMargin: any[] = [];
  const [getListOfContributionMargin, setListOfContributionMargin] = useState();

  let sumOfContributionMargin = 0;
  const [getSumOfContributionMargin, setSumOfContributionMargin] = useState(0);

  const listOfRateContributionMargin: any[] = [];
  const [getListOfRateContributionMargin, setListOfRateContributionMargin] = useState();

  let sumOfRateContributionMargin = 0;
  const [getSumOfRateContributionMargin, setSumOfRateContributionMargin] = useState(0);


  const listOfBreakEventPointSale: any[] = [];
  const [getListOfBreakEventPointSale, setListOfBreakEventPointSale] = useState();

  let sumOfBreakEventPointSale = 0;
  const [getSumOfBreakEventPointSale, setSumOfBreakEventPointSale] = useState(0);

  const listOfBreakEventPointNumber: any[] = [];
  const [getListOfBreakEventPointNumber, setListOfBreakEventPointNumber] = useState();

  let sumOfBreakEventPointNumber = 0;
  const [getSumOfBreakEventPointNumber, setSumOfBreakEventPointNumber] = useState(0);


  const listOfEmployeeCostRatio: any[] = [];
  const [getListOfEmployeeCostRatio, setListOfEmployeeCostRatio] = useState();


  let sumOfEmployeeCostRatio = 0;
  const [getSumOfEmployeeCostRatio, setSumOfEmployeeCostRatio] = useState(0);

  const listOfPartCostRatio: any[] = [];
  const [getListOfPartCostRatio, setListOfPartCostRatio] = useState();

  let sumOfPartCostRatio = 0;
  const [getSumOfPartCostRatio, setSumOfPartCostRatio] = useState(0);


  const listSumOfEmployeeAndPartCostRatio: any[] = [];
  const [getListSumOfEmployeeAndPartCostRatio, setListSumOfEmployeeAndPartCostRatio] = useState();

  let sumOfSumOfEmployeeAndPartCostRatio = 0;
  const [getSumOfSumOfEmployeeAndPartCostRatio, setSumOfSumOfEmployeeAndPartCostRatio] = useState(0);


  const listOfLaborShare: any[] = [];
  const [getListOfLaborShare, setListOfLaborShare] = useState();

  let sumOfLaborShare = 0;
  const [getSumOfLaborShare, setSumOfLaborShare] = useState(0);


  const listOfFLCost: any[] = [];
  const [getListOfFLCost, setListOfFLCost] = useState();

  let sumOfFLCost = 0;
  const [getSumOfFLCost, setSumOfFLCost] = useState(0);

  const listOfFLCostRate: any[] = [];
  const [getListOfFLCostRate, setListOfFLCostRate] = useState();

  let sumOfFLCostRate = 0;
  const [getSumOfFLCostRate, setSumOfFLCostRate] = useState(0);


  const listOfFLACost: any[] = [];
  const [getListOfFLACost, setListOfFLACost] = useState();

  let sumOfFLACost = 0;
  const [getSumOfFLACost, setSumOfFLACost] = useState(0);


  const listOfFLACostRate: any[] = [];
  const [getListOfFLACostRate, setListOfFLACostRate] = useState();

  let sumOfFLACostRate = 0;
  const [getSumOfFLACostRate, setSumOfFLACostRate] = useState(0);

  const listOfFLARCost: any[] = [];
  const [getListOfFLARCost, setListOfFLARCost] = useState();

  let sumOfFLARCost = 0;
  const [getSumOfFLARCost, setSumOfFLARCost] = useState(0);

  const listOfFLARCostRate: any[] = [];
  const [getListOfFLARCostRate, setListOfFLARCostRate] = useState();

  let sumOfFLARCostRate = 0;
  const [getSumOfFLARCostRate, setSumOfFLARCostRate] = useState(0);

  const listOfFLAROCost: any[] = [];
  const [getListOfFLAROCost, setListOfFLAROCost] = useState();

  let sumOfFLAROCost = 0;
  const [getSumOfFLAROCost, setSumOfFLAROCost] = useState(0);

  const listOfFLAROCostRate: any[] = [];
  const [getListOfFLAROCostRate, setListOfFLAROCostRate] = useState();

  let sumOfFLAROCostRate = 0;
  const [getSumOfFLAROCostRate, setSumOfFLAROCostRate] = useState(0);

  let sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow = 0;
  const [getSumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow, setSumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow] = useState(0);

  let amountOfBusyDay = 0;
  const [getAmountOfBusyDay, setAmountOfBusyDay] = useState(0);
  let amountOfNormalDay = 0;
  const [getAmountOfNormalDay, setAmountOfNormalDay] = useState(0);
  let amountOfQuietDay = 0;
  const [getAmountOfQuietDay, setAmountOfQuietDay] = useState(0);
  let amountOfHolidayDay = 0;
  const [getAmountOfHolidayDay, setAmountOfHolidayDay] = useState(0);

  let amountOfSaleBusyDay = 0;
  const [getAmountOfSaleBusyDay, setAmountOfSaleBusyDay] = useState(0);

  let amountOfSaleNormalDay = 0;
  const [getAmountOfSaleNormalDay, setAmountOfSaleNormalDay] = useState(0);

  let amountOfSaleQuietDay = 0;
  const [getAmountOfSaleQuietDay, setAmountOfSaleQuietDay] = useState(0);

  let amountOfSaleHolidayDay = 0;
  const [getAmountOfSaleHolidayDay, setAmountOfSaleHolidayDay] = useState(0);

  let amountOfSaleEachBusyDay = 0;
  const [getAmountOfSaleEachBusyDay, setAmountOfSaleEachBusyDay] = useState(0);

  let amountOfSaleEachNormalDay = 0;
  const [getAmountOfSaleEachNormalDay, setAmountOfSaleEachNormalDay] = useState(0);

  let amountOfSaleEachQuietDay = 0;
  const [getAmountOfSaleEachQuietDay, setAmountOfSaleEachQuietDay] = useState(0);

  let amountOfSaleEachHolidayDay = 0;
  const [getAmountOfSaleEachHolidayDay, setAmountOfSaleEachHolidayDay] = useState(0);

  let amountOfStandardCostBusyDay = 0;
  const [getAmountOfStandardCostBusyDay, setAmountOfStandardCostBusyDay] = useState(0);

  let amountOfStandardCostNormalDay = 0;
  const [getAmountOfStandardCostNormalDay, setAmountOfStandardCostNormalDay] = useState(0);

  let amountOfStandardCostQuietDay = 0;
  const [getAmountOfStandardCostQuietDay, setAmountOfStandardCostQuietDay] = useState(0);

  let amountOfStandardCostHolidayDay = 0;
  const [getAmountOfStandardCostHolidayDay, setAmountOfStandardCostHolidayDay] = useState(0);

  let amountOfStandardCostEachBusyDay = 0;
  const [getAmountOfStandardCostEachBusyDay, setAmountOfStandardCostEachBusyDay] = useState(0);

  let amountOfStandardCostEachNormalDay = 0;
  const [getAmountOfStandardCostEachNormalDay, setAmountOfStandardCostEachNormalDay] = useState(0);

  let amountOfStandardCostEachQuietDay = 0;
  const [getAmountOfStandardCostEachQuietDay, setAmountOfStandardCostEachQuietDay] = useState(0);

  let amountOfStandardCostEachHolidayDay = 0;
  const [getAmountOfStandardCostEachHolidayDay, setAmountOfStandardCostEachHolidayDay] = useState(0);

  let amountOfGrossProfitBusyDay = 0;
  const [getAmountOfGrossProfitBusyDay, setAmountOfGrossProfitBusyDay] = useState(0);

  let amountOfGrossProfitNormalDay = 0;
  const [getAmountOfGrossProfitNormalDay, setAmountOfGrossProfitNormalDay] = useState(0);

  let amountOfGrossProfitQuietDay = 0;
  const [getAmountOfGrossProfitQuietDay, setAmountOfGrossProfitQuietDay] = useState(0);

  let amountOfGrossProfitHolidayDay = 0;
  const [getAmountOfGrossProfitHolidayDay, setAmountOfGrossProfitHolidayDay] = useState(0);

  let amountOfGrossProfitEachBusyDay = 0;
  const [getAmountOfGrossProfitEachBusyDay, setAmountOfGrossProfitEachBusyDay] = useState(0);

  let amountOfGrossProfitEachNormalDay = 0;
  const [getAmountOfGrossProfitEachNormalDay, setAmountOfGrossProfitEachNormalDay] = useState(0);

  let amountOfGrossProfitEachQuietDay = 0;
  const [getAmountOfGrossProfitEachQuietDay, setAmountOfGrossProfitEachQuietDay] = useState(0);

  let amountOfGrossProfitEachHolidayDay = 0;
  const [getAmountOfGrossProfitEachHolidayDay, setAmountOfGrossProfitEachHolidayDay] = useState(0);

  let amountOfEmployeeCostBusyDay = 0;
  const [getAmountOfEmployeeCostBusyDay, setAmountOfEmployeeCostBusyDay] = useState(0);

  let amountOfEmployeeCostNormalDay = 0;
  const [getAmountOfEmployeeCostNormalDay, setAmountOfEmployeeCostNormalDay] = useState(0);

  let amountOfEmployeeCostQuietDay = 0;
  const [getAmountOfEmployeeCostQuietDay, setAmountOfEmployeeCostQuietDay] = useState(0);

  let amountOfEmployeeCostHolidayDay = 0;
  const [getAmountOfEmployeeCostHolidayDay, setAmountOfEmployeeCostHolidayDay] = useState(0);


  let amountOfEmployeeCostEachBusyDay = 0;
  const [getAmountOfEmployeeCostEachBusyDay, setAmountOfEmployeeCostEachBusyDay] = useState(0);

  let amountOfEmployeeCostEachNormalDay = 0;
  const [getAmountOfEmployeeCostEachNormalDay, setAmountOfEmployeeCostEachNormalDay] = useState(0);

  let amountOfEmployeeCostEachQuietDay = 0;
  const [getAmountOfEmployeeCostEachQuietDay, setAmountOfEmployeeCostEachQuietDay] = useState(0);

  let amountOfEmployeeCostEachHolidayDay = 0;
  const [getAmountOfEmployeeCostEachHolidayDay, setAmountOfEmployeeCostEachHolidayDay] = useState(0);

  let amountOfPartLaborCostBusyDay = 0;
  const [getAmountOfPartLaborCostBusyDay, setAmountOfPartLaborCostBusyDay] = useState(0);

  let amountOfPartLaborCostNormalDay = 0;
  const [getAmountOfPartLaborCostNormalDay, setAmountOfPartLaborCostNormalDay] = useState(0);

  let amountOfPartLaborCostQuietDay = 0;
  const [getAmountOfPartLaborCostQuietDay, setAmountOfPartLaborCostQuietDay] = useState(0);

  let amountOfPartLaborCostHolidayDay = 0;
  const [getAmountOfPartLaborCostHolidayDay, setAmountOfPartLaborCostHolidayDay] = useState(0);


  let amountOfPartLaborCostEachBusyDay = 0;
  const [getAmountOfPartLaborCostEachBusyDay, setAmountOfPartLaborCostEachBusyDay] = useState(0);

  let amountOfPartLaborCostEachNormalDay = 0;
  const [getAmountOfPartLaborCostEachNormalDay, setAmountOfPartLaborCostEachNormalDay] = useState(0);

  let amountOfPartLaborCostEachQuietDay = 0;
  const [getAmountOfPartLaborCostEachQuietDay, setAmountOfPartLaborCostEachQuietDay] = useState(0);

  let amountOfPartLaborCostEachHolidayDay = 0;
  const [getAmountOfPartLaborCostEachHolidayDay, setAmountOfPartLaborCostEachHolidayDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostBusyDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostBusyDay, setAmountOfEmployeeCostAndPartLaborCostBusyDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostNormalDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostNormalDay, setAmountOfEmployeeCostAndPartLaborCostNormalDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostQuietDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostQuietDay, setAmountOfEmployeeCostAndPartLaborCostQuietDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostHolidayDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostHolidayDay, setAmountOfEmployeeCostAndPartLaborCostHolidayDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostEachBusyDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostEachBusyDay, setAmountOfEmployeeCostAndPartLaborCostEachBusyDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostEachNormalDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostEachNormalDay, setAmountOfEmployeeCostAndPartLaborCostEachNormalDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostEachQuietDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostEachQuietDay, setAmountOfEmployeeCostAndPartLaborCostEachQuietDay] = useState(0);

  let amountOfEmployeeCostAndPartLaborCostEachHolidayDay = 0;
  const [getAmountOfEmployeeCostAndPartLaborCostEachHolidayDay, setAmountOfEmployeeCostAndPartLaborCostEachHolidayDay] = useState(0);

  let amountOfAdvertisingExpenseBusyDay = 0;
  const [getAmountOfAdvertisingExpenseBusyDay, setAmountOfAdvertisingExpenseBusyDay] = useState(0);

  let amountOfAdvertisingExpenseNormalDay = 0;
  const [getAmountOfAdvertisingExpenseNormalDay, setAmountOfAdvertisingExpenseNormalDay] = useState(0);

  let amountOfAdvertisingExpenseQuietDay = 0;
  const [getAmountOfAdvertisingExpenseQuietDay, setAmountOfAdvertisingExpenseQuietDay] = useState(0);

  let amountOfAdvertisingExpenseHolidayDay = 0;
  const [getAmountOfAdvertisingExpenseHolidayDay, setAmountOfAdvertisingExpenseHolidayDay] = useState(0);

  let amountOfAdvertisingExpenseEachBusyDay = 0;
  const [getAmountOfAdvertisingExpenseEachBusyDay, setAmountOfAdvertisingExpenseEachBusyDay] = useState(0);

  let amountOfAdvertisingExpenseEachNormalDay = 0;
  const [getAmountOfAdvertisingExpenseEachNormalDay, setAmountOfAdvertisingExpenseEachNormalDay] = useState(0);

  let amountOfAdvertisingExpenseEachQuietDay = 0;
  const [getAmountOfAdvertisingExpenseEachQuietDay, setAmountOfAdvertisingExpenseEachQuietDay] = useState(0);

  let amountOfAdvertisingExpenseEachHolidayDay = 0;
  const [getAmountOfAdvertisingExpenseEachHolidayDay, setAmountOfAdvertisingExpenseEachHolidayDay] = useState(0);

  let amountOfLandRentBusyDay = 0;
  const [getAmountOfLandRentBusyDay, setAmountOfLandRentBusyDay] = useState(0);

  let amountOfLandRentNormalDay = 0;
  const [getAmountOfLandRentNormalDay, setAmountOfLandRentNormalDay] = useState(0);

  let amountOfLandRentQuietDay = 0;
  const [getAmountOfLandRentQuietDay, setAmountOfLandRentQuietDay] = useState(0);

  let amountOfLandRentHolidayDay = 0;
  const [getAmountOfLandRentHolidayDay, setAmountOfLandRentHolidayDay] = useState(0);

  let amountOfLandRentEachBusyDay = 0;
  const [getAmountOfLandRentEachBusyDay, setAmountOfLandRentEachBusyDay] = useState(0);

  let amountOfLandRentEachNormalDay = 0;
  const [getAmountOfLandRentEachNormalDay, setAmountOfLandRentEachNormalDay] = useState(0);

  let amountOfLandRentEachQuietDay = 0;
  const [getAmountOfLandRentEachQuietDay, setAmountOfLandRentEachQuietDay] = useState(0);

  let amountOfLandRentEachHolidayDay = 0;
  const [getAmountOfLandRentEachHolidayDay, setAmountOfLandRentEachHolidayDay] = useState(0);

  let amountOfOtherFixedCostBusyDay = 0;
  const [getAmountOfOtherFixedCostBusyDay, setAmountOfOtherFixedCostBusyDay] = useState(0);

  let amountOfOtherFixedCostNormalDay = 0;
  const [getAmountOfOtherFixedCostNormalDay, setAmountOfOtherFixedCostNormalDay] = useState(0);

  let amountOfOtherFixedCostQuietDay = 0;
  const [getAmountOfOtherFixedCostQuietDay, setAmountOfOtherFixedCostQuietDay] = useState(0);

  let amountOfOtherFixedCostHolidayDay = 0;
  const [getAmountOfOtherFixedCostHolidayDay, setAmountOfOtherFixedCostHolidayDay] = useState(0);

  let amountOfOtherFixedCostEachBusyDay = 0;
  const [getAmountOfOtherFixedCostEachBusyDay, setAmountOfOtherFixedCostEachBusyDay] = useState(0);

  let amountOfOtherFixedCostEachNormalDay = 0;
  const [getAmountOfOtherFixedCostEachNormalDay, setAmountOfOtherFixedCostEachNormalDay] = useState(0);

  let amountOfOtherFixedCostEachQuietDay = 0;
  const [getAmountOfOtherFixedCostEachQuietDay, setAmountOfOtherFixedCostEachQuietDay] = useState(0);

  let amountOfOtherFixedCostEachHolidayDay = 0;
  const [getAmountOfOtherFixedCostEachHolidayDay, setAmountOfOtherFixedCostEachHolidayDay] = useState(0);

  let amountOfOtherVariableCostBusyDay = 0;
  const [getAmountOfOtherVariableCostBusyDay, setAmountOfOtherVariableCostBusyDay] = useState(0);

  let amountOfOtherVariableCostNormalDay = 0;
  const [getAmountOfOtherVariableCostNormalDay, setAmountOfOtherVariableCostNormalDay] = useState(0);

  let amountOfOtherVariableCostQuietDay = 0;
  const [getAmountOfOtherVariableCostQuietDay, setAmountOfOtherVariableCostQuietDay] = useState(0);

  let amountOfOtherVariableCostHolidayDay = 0;
  const [getAmountOfOtherVariableCostHolidayDay, setAmountOfOtherVariableCostHolidayDay] = useState(0);

  let amountOfOtherVariableCostEachBusyDay = 0;
  const [getAmountOfOtherVariableCostEachBusyDay, setAmountOfOtherVariableCostEachBusyDay] = useState(0);

  let amountOfOtherVariableCostEachNormalDay = 0;
  const [getAmountOfOtherVariableCostEachNormalDay, setamountOfOtherVariableCostEachNormalDay] = useState(0);

  let amountOfOtherVariableCostEachQuietDay = 0;
  const [getAmountOfOtherVariableCostEachQuietDay, setAmountOfOtherVariableCostEachQuietDay] = useState(0);

  let amountOfOtherVariableCostEachHolidayDay = 0;
  const [getAmountOfOtherVariableCostEachHolidayDay, setAmountOfOtherVariableCostEachHolidayDay] = useState(0);

  let amountOfOtherFixedAndVariableCostBusyDay = 0;
  const [getAmountOfOtherFixedAndVariableCostBusyDay, setAmountOfOtherFixedAndVariableCostBusyDay] = useState(0);

  let amountOfOtherFixedAndVariableCostNormalDay = 0;
  const [getAmountOfOtherFixedAndVariableCostNormalDay, setAmountOfOtherFixedAndVariableCostNormalDay] = useState(0);

  let amountOfOtherFixedAndVariableCostQuietDay = 0;
  const [getAmountOfOtherFixedAndVariableCostQuietDay, setAmountOfOtherFixedAndVariableCostQuietDay] = useState(0);

  let amountOfOtherFixedAndVariableCostHolidayDay = 0;
  const [getAmountOfOtherFixedAndVariableCostHolidayDay, setAmountOfOtherFixedAndVariableCostHolidayDay] = useState(0);

  let amountOfOtherFixedAndVariableCostEachBusyDay = 0;
  const [getAmountOfOtherFixedAndVariableCostEachBusyDay, setAmountOfOtherFixedAndVariableCostEachBusyDay] = useState(0);

  let amountOfOtherFixedAndVariableCostEachNormalDay = 0;
  const [getAmountOfOtherFixedAndVariableCostEachNormalDay, setAmountOfOtherFixedAndVariableCostEachNormalDay] = useState(0);

  let amountOfOtherFixedAndVariableCostEachQuietDay = 0;
  const [getAmountOfOtherFixedAndVariableCostEachQuietDay, setAmountOfOtherFixedAndVariableCostEachQuietDay] = useState(0);

  let amountOfOtherFixedAndVariableCostEachHolidayDay = 0;
  const [getAmountOfOtherFixedAndVariableCostEachHolidayDay, setAmountOfOtherFixedAndVariableCostEachHolidayDay] = useState(0);

  let amountOperatingIncomeBusyDay = 0;
  const [getAmountOperatingIncomeBusyDay, setAmountOperatingIncomeBusyDay] = useState(0);

  let amountOperatingIncomeNormalDay = 0;
  const [getAmountOperatingIncomeNormalDay, setAmountOperatingIncomeNormalDay] = useState(0);

  let amountOperatingIncomeQuietDay = 0;
  const [getAmountOperatingIncomeQuietDay, setAmountOperatingIncomeQuietDay] = useState(0);

  let amountOperatingIncomeHolidayDay = 0;
  const [getAmountOperatingIncomeHolidayDay, setAmountOperatingIncomeHolidayDay] = useState(0);


  let amountOperatingIncomeEachBusyDay = 0;
  const [getAmountOperatingIncomeEachBusyDay, setAmountOperatingIncomeEachBusyDay] = useState(0);

  let amountOperatingIncomeEachNormalDay = 0;
  const [getAmountOperatingIncomeEachNormalDay, setAmountOperatingIncomeEachNormalDay] = useState(0);

  let amountOperatingIncomeEachQuietDay = 0;
  const [getAmountOperatingIncomeEachQuietDay, setAmountOperatingIncomeEachQuietDay] = useState(0);

  let amountOperatingIncomeEachHolidayDay = 0;
  const [getAmountOperatingIncomeEachHolidayDay, setAmountOperatingIncomeEachHolidayDay] = useState(0);

  let totalSale = 0;
  const [getTotalSale, setTotalSale] = useState(0);

  let sumOfOperatingIncomeMonth = 0;
  const [getSumOfOperatingIncomeMonth, setSumOfOperatingIncomeMonth] = useState(0);

  let totalSaleEachDay = 0;
  const [getTotalSaleEachDay, setTotalSaleEachDay] = useState(0);

  let standardCostEachDay = 0;
  const [getStandardCostEachDay, setStandardCostEachDay] = useState(0);

  let GrossProfitEachDay = 0;
  const [getGrossProfitEachDay, setGrossProfitEachDay] = useState(0);

  let EmployeeLaborCostEachDay = 0;
  const [getEmployeeLaborCostEachDay, setEmployeeLaborCostEachDay] = useState(0);

  let PartLaborCostEachDay = 0;
  const [getPartLaborCostEachDay, setPartLaborCostEachDay] = useState(0);

  let EmployeeAndPartLaborCostEachDay = 0;
  const [getEmployeeAndPartLaborCostEachDay, setEmployeeAndPartLaborCostEachDay] = useState(0);

  let AdvertisingExpenseEachDay = 0;
  const [getAdvertisingExpenseEachDay, setAdvertisingExpenseEachDay] = useState(0);

  let LandRentEachDay = 0;
  const [getLandRentEachDay, setLandRentEachDay] = useState(0);

  let OtherFixedCostEachDay = 0;
  const [getOtherFixedCostEachDay, setOtherFixedCostEachDay] = useState(0);

  let OtherVariableCostEachDay = 0;
  const [getOtherVariableCostEachDay, setOtherVariableCostEachDay] = useState(0);

  let OtherFixedAndVariableCostEachDay = 0;
  const [getOtherFixedAndVariableCostEachDay, setOtherFixedAndVariableCostEachDay] = useState(0);

  let OperatingIncomeEachDay = 0;
  const [getOperatingIncomeEachDay, setOperatingIncomeEachDay] = useState(0);

  let totalBias = 0;
  const [getTotalBias, setTotalBias] = useState(0);

  let advertisingSaleData: any[] = [];
  const [getAdvertisingSaleData, setAdvertisingSaleData] = useState();

  let saleBudgetListData: any[] = [];
  const [getSaleBudgetListData, setSaleBudgetListData] = useState();

  let busyBias = 0;
  const [getBusyBias, setBusyBias] = useState(0);

  let normalBias = 0;
  const [getNormalBias, setNormalBias] = useState(0);

  let quietBias = 0;
  const [getQuietBias, setQuietBias] = useState(0);

  let holidayBias = 0;
  const [getHolidayBias, setHolidayBias] = useState(0);

  let saleBudgetAdvertisingMonthData: any[] = [];
  const [getSaleBudgetAdvertisingMonthData, setSaleBudgetAdvertisingMonthData] = useState();

  let salesPlanDailyData: any[] = [];
  const [getSalesPlanDailyData, setSalesPlanDailyData] = useState();

  let saleBudgetMonthData: any[] = [];
  const [getSaleBudgetMonthData, setSaleBudgetMonthData] = useState();

  let advertisingExpenseData: any[] = [];
  const [getAdvertisingExpenseData, setAdvertisingExpenseData] = useState();

  let perCustomerPrice = 0;
  const [getPerCustomerPrice, setPerCustomerPrice] = useState(0);

  let numberOfSeat = 0;
  const [getNumberOfSeat, setNumberOfSeat] = useState(0);

  const [getListBiasSetting, setListBiasSetting] = useState();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const arrayIndexHoliday: any[] = [];

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const onSubmit = async (values: SalesBudgetMonthDomain) => {
    setConfirmModalOpen(true);
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      let listData = '';
      const createUser = sessionStorage.getItem('loginUser.staffName') || '';
      const updateUser = sessionStorage.getItem('loginUser.staffName') || '';
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      const orgCode = sessionStorage.getItem('orgCode') || '';
      const targetMonth = `${sessionStorage.getItem('month_selected')}` || '';

      const {
        // eslint-disable-next-line no-shadow
        saleBudgetListData, saleBudgetAdvertisingMonthData, advertisingSaleData, advertisingExpensesData,
      } = formik.values;
      const listSumOfAdvertisingEachDay = getListSumOfAdvertisingEachDay;
      const listSumOfAdvertisingSaleAndSaleBudgetFree = getListSumOfAdvertisingSaleAndSaleBudgetFree;
      const listSumOfFoodCostAndDrinkCost = getListSumOfFoodCostAndDrinkCost;
      const listSumOfEmployeeLaborCostAndPartLaborCost = getListSumOfEmployeeLaborCostAndPartLaborCost;
      const listGrossProfit = getListGrossProfit;
      const listSumOfOtherVariableCostsAndOtherFixedCosts = getListSumOfOtherVariableCostsAndOtherFixedCosts;
      const listOfOperatingIncome = getListOfOperatingIncome;
      const listNumberOfCustomers = getListNumberOfCustomers;
      for (let i = 0; i < saleBudgetListData.length; i += 1) {
        listData += `&listSalesBudgetDaily[${i}].createUser=${createUser}`;
        listData += `&listSalesBudgetDaily[${i}].updateUser=${updateUser}`;
        listData += `&listSalesBudgetDaily[${i}].id.companyCode=${companyCode}`;
        listData += `&listSalesBudgetDaily[${i}].id.orgCode=${orgCode}`;
        listData += `&listSalesBudgetDaily[${i}].id.targetDay=${saleBudgetListData[i][0]}`;
        listData += `&listSalesBudgetDaily[${i}].status=${saleBudgetListData[i][20]}`;
        listData += `&listSalesBudgetDaily[${i}].salesBudgetTotal=${listSumOfAdvertisingSaleAndSaleBudgetFree[i]}`;
        listData += `&listSalesBudgetDaily[${i}].salesBudgetAdvertising=${listSumOfAdvertisingEachDay[i]}`;
        listData += `&listSalesBudgetDaily[${i}].salesBudgetFree=${saleBudgetListData[i][3]}`;
        listData += `&listSalesBudgetDaily[${i}].costTotal=${listSumOfFoodCostAndDrinkCost[i]}`;
        listData += `&listSalesBudgetDaily[${i}].foodCost=${saleBudgetListData[i][5]}`;
        listData += `&listSalesBudgetDaily[${i}].drinkCost=${saleBudgetListData[i][6]}`;
        listData += `&listSalesBudgetDaily[${i}].grossProfit=${listGrossProfit[i]}`;
        listData += `&listSalesBudgetDaily[${i}].laborCostTotal=${listSumOfEmployeeLaborCostAndPartLaborCost[i]}`;
        listData += `&listSalesBudgetDaily[${i}].employeeLaborCost=${saleBudgetListData[i][9]}`;
        listData += `&listSalesBudgetDaily[${i}].partLaborCost=${saleBudgetListData[i][10]}`;
        listData += `&listSalesBudgetDaily[${i}].advertisingMediaExpense=${saleBudgetListData[i][11]}`;
        listData += `&listSalesBudgetDaily[${i}].landRent=${saleBudgetListData[i][12]}`;
        listData += `&listSalesBudgetDaily[${i}].otherExpensesTotal=${listSumOfOtherVariableCostsAndOtherFixedCosts[i]}`;
        listData += `&listSalesBudgetDaily[${i}].otherVariableCosts=${saleBudgetListData[i][14]}`;
        listData += `&listSalesBudgetDaily[${i}].otherFixedCost=${saleBudgetListData[i][15]}`;
        listData += `&listSalesBudgetDaily[${i}].operatingIncome=${listOfOperatingIncome[i]}`;
        listData += `&listSalesBudgetDaily[${i}].perCustomerPrice=${saleBudgetListData[i][17]}`;
        listData += `&listSalesBudgetDaily[${i}].guestCnt=${listNumberOfCustomers[i]}`;
        for (let j = 0; j < saleBudgetAdvertisingMonthData.length; j += 1) {
          listData += `&listDailyAdvertisingMedia[${j}][${i}].id.advertisingMediaMstCode=${saleBudgetAdvertisingMonthData[j][0]}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].id.companyCode=${companyCode}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].id.orgCode=${orgCode}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].id.targetDay=${saleBudgetListData[i][0]}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].createUser=${createUser}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].updateUser=${updateUser}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].advertisingMediaAmount=${advertisingSaleData[j][i + 3]}`;
          listData += `&listDailyAdvertisingMedia[${j}][${i}].advertisingMediaExpense=${advertisingExpensesData[j][i + 3]}`;
        }
      }

      listData += `&salesBudgetDailyBias.id.companyCode=${companyCode}`;
      listData += `&salesBudgetDailyBias.id.orgCode=${orgCode}`;
      listData += `&salesBudgetDailyBias.id.targetMonth=${moment(targetMonth, 'YYYY/MM/DD').format('YYYY-MM-DD')}`;
      listData += `&salesBudgetDailyBias.createUser=${updateUser}`;
      listData += `&salesBudgetDailyBias.updateUser=${updateUser}`;
      listData += `&salesBudgetDailyBias.busy=${formik.values.busyBias}`;
      listData += `&salesBudgetDailyBias.normal=${formik.values.normalBias}`;
      listData += `&salesBudgetDailyBias.quiet=${formik.values.quietBias}`;
      listData += `&salesBudgetDailyBias.holiday=${formik.values.holidayBias}`;

      const response = await updateSalesBudgetMonth(companyCode, listData);
      if (!response.errors) {
        setConfirmModalOpen(false);
        successNotification('登録しました。');
      } else if (response && response.data && response.data.errors) {
        const listErr = response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: SalesBudgetMonthDomain.generateInitial(),
    onSubmit,
  });

  const handleChangeSelect = () => {
    setVariable();
    calculatorTableDaily(true);
  };

  const handleChangeInputBias = () => {
    setVariable();
    calculatorTableDaily(true);
  };

  useEffect(() => {
    const orgCode = sessionStorage.getItem('orgCode');
    const targetMonth = sessionStorage.getItem('month_selected');
    const convertTargetMonth = moment(targetMonth?.toString()).format('YYYY/MM/DD');

    getBiasSetting(orgCode, convertTargetMonth).then((response: any) => {
      setListBiasSetting(response);
      formik.setFieldValue('busyBias', response.busy);
      formik.setFieldValue('normalBias', response.normal);
      formik.setFieldValue('quietBias', response.quiet);
      formik.setFieldValue('holidayBias', response.holiday);
    });

    salesBudgetPlanDaily(orgCode, convertTargetMonth).then((response: any) => {
      formik.setFieldValue('advertisingSaleData', response.advertisingSaleData);
      formik.setFieldValue('salesPlanDailyDateData', response.salesPlanDailyDateData);
      formik.setFieldValue('saleBudgetListData', response.saleBudgetListData);
      formik.setFieldValue('saleBudgetAdvertisingMonthData', response.saleBudgetAdvertisingMonthData);
      formik.setFieldValue('advertisingExpensesData', response.advertisingExpensesData);
      formik.setFieldValue('saleBudgetMonthData', response.saleBudgetMonthData);

      setVariable();
      calculatorTableDaily();
      setIsLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setListBiasSetting]);

  const setVariable = () => {
    salesPlanDailyData = formik.values.salesPlanDailyDateData;
    advertisingExpenseData = formik.values.advertisingExpensesData;
    saleBudgetAdvertisingMonthData = formik.values.saleBudgetAdvertisingMonthData;
    saleBudgetListData = formik.values.saleBudgetListData;
    perCustomerPrice = saleBudgetListData[1][17];
    numberOfSeat = saleBudgetListData[1][19];
    advertisingSaleData = formik.values.advertisingSaleData;
    saleBudgetMonthData = formik.values.saleBudgetMonthData;
    amountDayInMonth = saleBudgetListData.length;
  };

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };

  const calculatorTableDaily = (calculateAdvertisingFree?:boolean) => {
    setPerCustomerPrice(perCustomerPrice);
    busyBias = parseInt(formik.values.busyBias);
    normalBias = parseInt(formik.values.normalBias);
    quietBias = parseInt(formik.values.quietBias);
    holidayBias = parseInt(formik.values.holidayBias);

    for (let i = 0; i < saleBudgetListData.length; i += 1) {
      switch (saleBudgetListData[i][20]) {
        case 0:
          amountOfBusyDay += 1;
          break;
        case 1:
          amountOfNormalDay += 1;
          break;
        case 2:
          amountOfQuietDay += 1;
          break;
        case 3:
          amountOfHolidayDay += 1;
          break;
      }
    }

    totalBias = busyBias * amountOfBusyDay + normalBias * amountOfNormalDay + quietBias * amountOfQuietDay + holidayBias * amountOfHolidayDay;
    // ////////////////////////

    if (calculateAdvertisingFree) {
      advertisingSaleData = advertisingSaleData;
      for (let i = 0; i < saleBudgetAdvertisingMonthData.length; i += 1) {
        for (let j = 0; j < salesPlanDailyData.length; j += 1) {
          let biasValue = 0;
          switch (saleBudgetListData[j][20]) {
            case 0:
              biasValue = busyBias;
              break;
            case 1:
              biasValue = normalBias;
              break;
            case 2:
              biasValue = quietBias;
              break;
            case 3:
              biasValue = holidayBias;
              break;
          }
          advertisingSaleData[i][j + 3] = Math.round(numberFormater(saleBudgetAdvertisingMonthData[i][3] * (biasValue) / totalBias));

          if (j === (salesPlanDailyData.length - 1)) { // #78
            advertisingSaleData[i][j + 3] = numberFormater(saleBudgetAdvertisingMonthData[i][3] - sumOfArray(advertisingSaleData[i].slice(3, salesPlanDailyData.length + 2)));
          }
        }
      }

      let lastDate3Value = saleBudgetMonthData[0][3];
      let lastDate5Value = saleBudgetMonthData[0][5];
      let lastDate6Value = saleBudgetMonthData[0][6];
      let lastDate9Value = saleBudgetMonthData[0][9];
      let lastDate10Value = saleBudgetMonthData[0][10];
      let lastDate14Value = saleBudgetMonthData[0][14];
      let lastDate15Value = saleBudgetMonthData[0][15];
      let lastDate12Value = saleBudgetMonthData[0][12];

      for (let j = 0; j < saleBudgetListData.length; j += 1) {
        let biasValue = 0;
        switch (saleBudgetListData[j][20]) {
          case 0:
            biasValue = busyBias;
            break;
          case 1:
            biasValue = normalBias;
            break;
          case 2:
            biasValue = quietBias;
            break;
          case 3:
            biasValue = holidayBias;
            break;
        }

        if (j === (salesPlanDailyData.length - 1)) { // #78
          saleBudgetListData[j][3] = lastDate3Value;
          saleBudgetListData[j][5] = lastDate5Value;
          saleBudgetListData[j][6] = lastDate6Value;
          saleBudgetListData[j][9] = lastDate9Value;
          saleBudgetListData[j][10] = lastDate10Value;
          saleBudgetListData[j][14] = lastDate14Value;
          saleBudgetListData[j][15] = lastDate15Value;
          saleBudgetListData[j][12] = lastDate12Value;
        } else {
          saleBudgetListData[j][3] = Math.round((saleBudgetMonthData[0][3] * (biasValue) / totalBias));
          saleBudgetListData[j][5] = Math.round((saleBudgetMonthData[0][5] * (biasValue) / totalBias));
          saleBudgetListData[j][6] = Math.round((saleBudgetMonthData[0][6] * (biasValue) / totalBias));
          saleBudgetListData[j][9] = saleBudgetListData[j][20] === 3 ? 0 : Math.round((saleBudgetMonthData[0][9] / (salesPlanDailyData.length - amountOfHolidayDay)));
          saleBudgetListData[j][10] = Math.round((saleBudgetMonthData[0][10] * (biasValue) / totalBias));
          saleBudgetListData[j][14] = Math.round((saleBudgetMonthData[0][14] * (biasValue) / totalBias));
          saleBudgetListData[j][15] = Math.round(saleBudgetMonthData[0][15] / salesPlanDailyData.length);
          lastDate3Value -= saleBudgetListData[j][3];
          lastDate5Value -= saleBudgetListData[j][5];
          lastDate6Value -= saleBudgetListData[j][6];
          lastDate9Value -= saleBudgetListData[j][9];
          lastDate10Value -= saleBudgetListData[j][10];
          lastDate14Value -= saleBudgetListData[j][14];
          lastDate15Value -= saleBudgetListData[j][15];
          lastDate12Value -= saleBudgetListData[j][12];
        }
        if (saleBudgetListData[j][20] === 3) {
          arrayIndexHoliday.push(j);
        }
      }
      advertisingSaleData = advertisingSaleData;
      saleBudgetListData = saleBudgetListData;
    }

    if (advertisingSaleData.length !== 0) {
      for (let i = 0; i < advertisingSaleData.length; i += 1) {
        if (advertisingSaleData[i][1] !== 'total') {
          sumOfAdvertisingSale += (saleBudgetAdvertisingMonthData.length > 0) ? saleBudgetAdvertisingMonthData[i][3] : 0;
          for (let j = 0; j < salesPlanDailyData.length; j += 1) {
            listSumOfAdvertisingEachDay[j] = (listSumOfAdvertisingEachDay[j] ? listSumOfAdvertisingEachDay[j] : 0) + advertisingSaleData[i][j + 3];
          }
        }
      }
    } else {
      for (let j = 0; j < salesPlanDailyData.length; j += 1) {
        listSumOfAdvertisingEachDay[j] = 0;
      }
    }

    if (advertisingExpenseData.length) {
      for (let i = 0; i < advertisingExpenseData.length; i += 1) {
        if (advertisingExpenseData[i][1] !== 'total') {
          sumOfAdvertisingExpense += (saleBudgetAdvertisingMonthData.length > 0) ? saleBudgetAdvertisingMonthData[i][4] : 0;
        } else {
          listSumOfAdvertisingExpenseEachDay = advertisingExpenseData[i].slice(3);
        }
      }
    } else {
      for (let j = 0; j < salesPlanDailyData.length; j += 1) {
        listSumOfAdvertisingExpenseEachDay[j] = 0;
      }
    }

    for (let i = 0; i < saleBudgetListData.length; i += 1) {
      const listSumOfAdvertisingSaleAndSaleBudgetFreeItem = listSumOfAdvertisingEachDay[i] + saleBudgetListData[i][3];
      listSumOfAdvertisingSaleAndSaleBudgetFree.push(listSumOfAdvertisingSaleAndSaleBudgetFreeItem);
      // calculate for listSumOfFoodCostAndDrinkCost
      listSumOfFoodCostAndDrinkCost.push(saleBudgetListData[i][5] + saleBudgetListData[i][6]);
    }

    advertisingSaleData.map((data: any, number: any) => {
      if (data[1] !== 'total') {
        data.map((dayData: any, index: any) => {
          if (index !== 0 && index !== 1 && index !== 2) {
            if (arrayIndexHoliday && arrayIndexHoliday[0] !== 0 && (arrayIndexHoliday[0] - 1) === (index - 3)) {
              advertisingSaleData[number][arrayIndexHoliday[0] + 2] += advertisingSaleData[number][arrayIndexHoliday[arrayIndexHoliday.length - 1] + 3];
              advertisingSaleData[number][arrayIndexHoliday[arrayIndexHoliday.length - 1] + 3] = 0;
            } else if (arrayIndexHoliday && arrayIndexHoliday[0] === 0 && (arrayIndexHoliday[1] - 1) === (index - 3)) {
              advertisingSaleData[number][arrayIndexHoliday[1] + 2] += advertisingSaleData[number][arrayIndexHoliday[arrayIndexHoliday.length - 1] + 3];
              advertisingSaleData[number][arrayIndexHoliday[arrayIndexHoliday.length - 1] + 3] = 0;
            }
          }
        });
      }
    });

    console.log(arrayIndexHoliday);
    console.log(advertisingSaleData);

    for (let i = 0; i < saleBudgetListData.length; i += 1) {
      if (arrayIndexHoliday && arrayIndexHoliday[0] !== 0 && (arrayIndexHoliday[0] - 1) === i) {
        listSumOfAdvertisingSaleAndSaleBudgetFree[arrayIndexHoliday[0] - 1] += listSumOfAdvertisingSaleAndSaleBudgetFree[arrayIndexHoliday[arrayIndexHoliday.length - 1]];
        listSumOfAdvertisingSaleAndSaleBudgetFree[arrayIndexHoliday[arrayIndexHoliday.length - 1]] = 0;

        listSumOfAdvertisingEachDay[arrayIndexHoliday[0] - 1] += listSumOfAdvertisingEachDay[arrayIndexHoliday[arrayIndexHoliday.length - 1]];
        listSumOfAdvertisingEachDay[arrayIndexHoliday[arrayIndexHoliday.length - 1]] = 0;

        saleBudgetListData[arrayIndexHoliday[0] - 1][3] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][3];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][3] = 0;
      } else if (arrayIndexHoliday && arrayIndexHoliday[0] === 0 && (arrayIndexHoliday[1] - 1) === i) {
        listSumOfAdvertisingSaleAndSaleBudgetFree[arrayIndexHoliday[1] - 1] += listSumOfAdvertisingSaleAndSaleBudgetFree[arrayIndexHoliday[arrayIndexHoliday.length - 1]];
        listSumOfAdvertisingSaleAndSaleBudgetFree[arrayIndexHoliday[arrayIndexHoliday.length - 1]] = 0;

        listSumOfAdvertisingEachDay[arrayIndexHoliday[1] - 1] += listSumOfAdvertisingEachDay[arrayIndexHoliday[arrayIndexHoliday.length - 1]];
        listSumOfAdvertisingEachDay[arrayIndexHoliday[arrayIndexHoliday.length - 1]] = 0;

        saleBudgetListData[arrayIndexHoliday[1] - 1][3] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][3];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][3] = 0;
      }

      // calculate for sumOfSaleBudgetFreeRow
      sumOfSaleBudgetFreeRow += saleBudgetListData[i][3];
      // calculate for sumOfFoodCostRow
      sumOfFoodCostRow += saleBudgetListData[i][5];
      // calculate for sumOfDrinkCostRow
      sumOfDrinkCostRow += saleBudgetListData[i][6];

      if (arrayIndexHoliday && arrayIndexHoliday[0] !== 0 && (arrayIndexHoliday[0] - 1) === i) {
        console.log(listSumOfFoodCostAndDrinkCost);
        listSumOfFoodCostAndDrinkCost[arrayIndexHoliday[arrayIndexHoliday.length - 1]] = 0;

        saleBudgetListData[arrayIndexHoliday[0] - 1][5] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][5];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][5] = 0;

        saleBudgetListData[arrayIndexHoliday[0] - 1][6] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][6];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][6] = 0;

        saleBudgetListData[arrayIndexHoliday[0] - 1][9] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][9];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][9] = 0;

        saleBudgetListData[arrayIndexHoliday[0] - 1][10] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][10];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][10] = 0;
      } else if (arrayIndexHoliday && arrayIndexHoliday[0] === 0 && (arrayIndexHoliday[1] - 1) === i) {
        listSumOfFoodCostAndDrinkCost[arrayIndexHoliday[arrayIndexHoliday.length - 1]] = 0;

        saleBudgetListData[arrayIndexHoliday[1] - 1][5] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][5];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][5] = 0;

        saleBudgetListData[arrayIndexHoliday[1] - 1][6] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][6];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][6] = 0;

        saleBudgetListData[arrayIndexHoliday[1] - 1][9] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][9];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][9] = 0;

        saleBudgetListData[arrayIndexHoliday[1] - 1][10] += saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][10];
        saleBudgetListData[arrayIndexHoliday[arrayIndexHoliday.length - 1]][10] = 0;
      }

      // calculate for listGrossProfit
      listGrossProfit.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] - listSumOfFoodCostAndDrinkCost[i]);
      // calculate for listRateOfGrossProfit
      listRateOfGrossProfit.push(listGrossProfit[i] ? listGrossProfit[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for sumOfEmployeeLaborCostRow
      sumOfEmployeeLaborCostRow = saleBudgetMonthData[0][9];
      // calculate for sumOfPartLaborCostRow
      sumOfPartLaborCostRow = saleBudgetMonthData[0][10];
      // calculate for listSumOfEmployeeLaborCostAndPartLaborCost
      listSumOfEmployeeLaborCostAndPartLaborCost.push(saleBudgetListData[i][9] + saleBudgetListData[i][10]);
      // calculate for sumOfLandRentRow
      sumOfLandRentRow = saleBudgetMonthData[0][12];
      // calculate for sumOfOtherVariableCostsRow
      sumOfOtherVariableCostsRow = saleBudgetMonthData[0][14];
      // calculate for sumOfOtherFixedCostsRow
      sumOfOtherFixedCostsRow = saleBudgetMonthData[0][15];
      // calculate for listSumOfOtherVariableCostsAndOtherFixedCosts
      listSumOfOtherVariableCostsAndOtherFixedCosts.push(saleBudgetListData[i][15] + saleBudgetListData[i][14]);
      // calculate for listOfOperatingIncome
      listOfOperatingIncome.push(listGrossProfit[i] - listSumOfEmployeeLaborCostAndPartLaborCost[i] - listSumOfAdvertisingExpenseEachDay[i] - saleBudgetListData[i][12] - listSumOfOtherVariableCostsAndOtherFixedCosts[i]);
      // calculate for totalOfRateOperatingIncome
      // listOfRateOperatingIncome.push( listGrossProfit[i] ? ((listSumOfEmployeeLaborCostAndPartLaborCost[i] + listSumOfAdvertisingExpenseEachDay[i] + saleBudgetListData[i][12] + listSumOfOtherVariableCostsAndOtherFixedCosts[i]) / listGrossProfit[i]) : 0  )
      listOfRateOperatingIncome.push(listGrossProfit[i] ? (listOfOperatingIncome[i] / listGrossProfit[i]) : 0);
      // calculate for listNumberOfCustomers
      listNumberOfCustomers.push(Math.round(perCustomerPrice ? (listSumOfAdvertisingSaleAndSaleBudgetFree[i] / perCustomerPrice) : 0));
      // calculate for listOfTurnoverRate
      listOfTurnoverRate.push(numberOfSeat ? (listNumberOfCustomers[i] / numberOfSeat) : 0);
      // calculate for listOfMarginalProfit
      listOfMarginalProfit.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] - (saleBudgetListData[i][5] + saleBudgetListData[i][6] + saleBudgetListData[i][10] + saleBudgetListData[i][14]));
      // calculate for listRateOfMarginalProfit
      listRateOfMarginalProfit.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listOfMarginalProfit[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfContributionMargin
      listOfContributionMargin.push(listOfMarginalProfit[i] - (saleBudgetListData[i][15] + saleBudgetListData[i][12] + listSumOfAdvertisingExpenseEachDay[i] + saleBudgetListData[i][9]));
      // calculate for listOfRateContributionMargin
      listOfRateContributionMargin.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listOfContributionMargin[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfBreakEventPointSale
      listOfBreakEventPointSale.push(Math.round(listRateOfMarginalProfit[i] ? (saleBudgetListData[i][9] + listSumOfAdvertisingExpenseEachDay[i] + saleBudgetListData[i][12] + saleBudgetListData[i][15]) / listRateOfMarginalProfit[i] : 0));
      // calculate for listOfBreakEventPointNumber
      listOfBreakEventPointNumber.push(Math.round(perCustomerPrice ? listOfBreakEventPointSale[i] / perCustomerPrice : 0));
      // calculate for listOfEmployeeCostRatio
      listOfEmployeeCostRatio.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? saleBudgetListData[i][9] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfPartCostRatio
      listOfPartCostRatio.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? saleBudgetListData[i][10] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listSumOfEmployeeAndPartCostRatio
      listSumOfEmployeeAndPartCostRatio.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listSumOfEmployeeLaborCostAndPartLaborCost[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfLaborShare
      listOfLaborShare.push(listGrossProfit[i] ? listSumOfEmployeeLaborCostAndPartLaborCost[i] / listGrossProfit[i] : 0);
      // calculate for listOfFLCost
      listOfFLCost.push(listSumOfFoodCostAndDrinkCost[i] + listSumOfEmployeeLaborCostAndPartLaborCost[i]);
      // calculate for listOfFLCostRate
      listOfFLCostRate.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listOfFLCost[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfFLACost
      listOfFLACost.push(listSumOfFoodCostAndDrinkCost[i] + listSumOfEmployeeLaborCostAndPartLaborCost[i] + listSumOfAdvertisingExpenseEachDay[i]);
      // calculate for listOfFLACostRate
      listOfFLACostRate.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listOfFLACost[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfFLARCost
      listOfFLARCost.push(listSumOfFoodCostAndDrinkCost[i] + listSumOfEmployeeLaborCostAndPartLaborCost[i] + listSumOfAdvertisingExpenseEachDay[i] + saleBudgetListData[i][12]);
      // calculate for listOfFLACostRate
      listOfFLARCostRate.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listOfFLARCost[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for listOfFLAROCost
      listOfFLAROCost.push(listSumOfFoodCostAndDrinkCost[i] + listSumOfEmployeeLaborCostAndPartLaborCost[i] + listSumOfAdvertisingExpenseEachDay[i] + saleBudgetListData[i][12] + listSumOfOtherVariableCostsAndOtherFixedCosts[i]);
      // calculate for listOfFLAOCostRate
      listOfFLAROCostRate.push(listSumOfAdvertisingSaleAndSaleBudgetFree[i] ? listOfFLAROCost[i] / listSumOfAdvertisingSaleAndSaleBudgetFree[i] : 0);
      // calculate for amountDay

      switch (saleBudgetListData[i][20]) {
        case 0:
          amountOfSaleBusyDay += listSumOfAdvertisingSaleAndSaleBudgetFree[i] || 0;
          amountOfStandardCostBusyDay += listSumOfFoodCostAndDrinkCost[i] || 0;
          amountOfGrossProfitBusyDay += listGrossProfit[i] || 0;
          amountOfEmployeeCostBusyDay += saleBudgetListData[i][9] || 0;
          amountOfPartLaborCostBusyDay += saleBudgetListData[i][10] || 0;
          amountOfAdvertisingExpenseBusyDay += listSumOfAdvertisingExpenseEachDay[i] || 0;
          amountOfLandRentBusyDay += saleBudgetListData[i][12] || 0;
          amountOfOtherFixedCostBusyDay += saleBudgetListData[i][15] || 0;
          amountOfOtherVariableCostBusyDay += saleBudgetListData[i][14] || 0;
          break;
        case 1:
          amountOfSaleNormalDay += listSumOfAdvertisingSaleAndSaleBudgetFree[i] || 0;
          amountOfStandardCostNormalDay += listSumOfFoodCostAndDrinkCost[i] || 0;
          amountOfGrossProfitNormalDay += listGrossProfit[i] || 0;
          amountOfEmployeeCostNormalDay += saleBudgetListData[i][9] || 0;
          amountOfPartLaborCostNormalDay += saleBudgetListData[i][10] || 0;
          amountOfAdvertisingExpenseNormalDay += listSumOfAdvertisingExpenseEachDay[i] || 0;
          amountOfLandRentNormalDay += saleBudgetListData[i][12] || 0;
          amountOfOtherFixedCostNormalDay += saleBudgetListData[i][15] || 0;
          amountOfOtherVariableCostNormalDay += saleBudgetListData[i][14] || 0;
          break;
        case 2:
          amountOfSaleQuietDay += listSumOfAdvertisingSaleAndSaleBudgetFree[i] || 0;
          amountOfStandardCostQuietDay += listSumOfFoodCostAndDrinkCost[i] || 0;
          amountOfGrossProfitQuietDay += listGrossProfit[i] || 0;
          amountOfEmployeeCostQuietDay += saleBudgetListData[i][9] || 0;
          amountOfPartLaborCostQuietDay += saleBudgetListData[i][10] || 0;
          amountOfAdvertisingExpenseQuietDay += listSumOfAdvertisingExpenseEachDay[i] || 0;
          amountOfLandRentQuietDay += saleBudgetListData[i][12] || 0;
          amountOfOtherFixedCostQuietDay += saleBudgetListData[i][15] || 0;
          amountOfOtherVariableCostQuietDay += saleBudgetListData[i][14] || 0;
          break;
        case 3:
          amountOfSaleHolidayDay += listSumOfAdvertisingSaleAndSaleBudgetFree[i] || 0;
          amountOfStandardCostHolidayDay += listSumOfFoodCostAndDrinkCost[i] || 0;
          amountOfGrossProfitHolidayDay += listGrossProfit[i] || 0;
          amountOfEmployeeCostHolidayDay += saleBudgetListData[i][9] || 0;
          amountOfPartLaborCostHolidayDay += saleBudgetListData[i][10] || 0;
          amountOfAdvertisingExpenseHolidayDay += listSumOfAdvertisingExpenseEachDay[i] || 0;
          amountOfLandRentHolidayDay += saleBudgetListData[i][12] || 0;
          amountOfOtherFixedCostHolidayDay += saleBudgetListData[i][15] || 0;
          amountOfOtherVariableCostHolidayDay += saleBudgetListData[i][14] || 0;
          break;
      }
    }

    // calculate for sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow
    sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow = sumOfAdvertisingSale + saleBudgetMonthData[0][3];
    // calculate for sumOfTotalFoodCostAndDrinkCost
    sumOfTotalFoodCostAndDrinkCost = saleBudgetMonthData[0][5] + saleBudgetMonthData[0][6];
    // calculate for sumOfListGrossProfit
    sumOfListGrossProfit = sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow - sumOfTotalFoodCostAndDrinkCost;
    // calculate for sumOfTotalEmployeeLaborCostAndPartLaborCost
    sumOfTotalEmployeeLaborCostAndPartLaborCost = saleBudgetMonthData[0][9] + saleBudgetMonthData[0][10];
    // calculate for sumOfTotalOtherVariableCostsAndOtherFixedCosts
    sumOfTotalOtherVariableCostsAndOtherFixedCosts = saleBudgetMonthData[0][14] + saleBudgetMonthData[0][15];
    // calculate for totalOfOperatingIncome
    totalOfOperatingIncome = sumOfListGrossProfit - sumOfTotalEmployeeLaborCostAndPartLaborCost - sumOfAdvertisingExpense - saleBudgetMonthData[0][12] - sumOfTotalOtherVariableCostsAndOtherFixedCosts;
    //  calculate for rateOfOperationIncome
    // rateOfOperationIncome = sumOfListGrossProfit ? (sumOfTotalEmployeeLaborCostAndPartLaborCost + sumOfAdvertisingExpense + saleBudgetMonthData[0][12] + sumOfTotalOtherVariableCostsAndOtherFixedCosts) / sumOfListGrossProfit : 0
    rateOfOperationIncome = sumOfListGrossProfit ? (totalOfOperatingIncome / sumOfListGrossProfit) : 0;
    // calculate for sumOfNumberOfCustomers
    sumOfNumberOfCustomers = Math.round(perCustomerPrice ? sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow / perCustomerPrice : 0);
    // calculate for sumOfTurnoverRate
    sumOfTurnoverRate = numberOfSeat ? (sumOfNumberOfCustomers / numberOfSeat) : 0;
    // calculate for sumOfMarginalProfit
    sumOfMarginalProfit = sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow - (saleBudgetMonthData[0][5] + saleBudgetMonthData[0][6] + saleBudgetMonthData[0][10] + saleBudgetMonthData[0][14]);
    // calculate for sumOfRateMarginalProfit
    sumOfRateMarginalProfit = sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow ? (sumOfMarginalProfit / sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow) : 0;
    // calculate for sumOfContributionMargin
    sumOfContributionMargin = (sumOfMarginalProfit - (saleBudgetMonthData[0][15] + saleBudgetMonthData[0][12] + sumOfAdvertisingExpense + saleBudgetMonthData[0][9]));
    // calculate for sumOfRateContributionMargin
    sumOfRateContributionMargin = sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow ? (sumOfContributionMargin / (sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow)) : 0;
    // calculate for sumOfBreakEventPointSale
    sumOfBreakEventPointSale = Math.round(sumOfRateMarginalProfit ? ((saleBudgetMonthData[0][9] + sumOfAdvertisingExpense + saleBudgetMonthData[0][12] + saleBudgetMonthData[0][15]) / sumOfRateMarginalProfit) : 0);
    // calculate for sumOfBreakEventPointNumber
    sumOfBreakEventPointNumber = Math.round(perCustomerPrice ? (sumOfBreakEventPointSale / perCustomerPrice) : 0);
    // calculate for sumOfEmployeeCostRatio
    sumOfEmployeeCostRatio = (sumOfAdvertisingSale + saleBudgetMonthData[0][3]) ? (saleBudgetMonthData[0][9] / (sumOfAdvertisingSale + saleBudgetMonthData[0][3])) : (sumOfAdvertisingSale + saleBudgetMonthData[0][3]);
    // calculate for sumOfPartCostRatio
    sumOfPartCostRatio = (sumOfAdvertisingSale + saleBudgetMonthData[0][3]) ? (saleBudgetMonthData[0][10] / (sumOfAdvertisingSale + saleBudgetMonthData[0][3])) : 0;
    // sumOfSumOfEmployeeAndPartCostRatio
    sumOfSumOfEmployeeAndPartCostRatio = (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow) ? (sumOfTotalEmployeeLaborCostAndPartLaborCost / (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow)) : 0;
    // calculate for sumOfFLCost
    sumOfFLCost = (sumOfTotalFoodCostAndDrinkCost + sumOfTotalEmployeeLaborCostAndPartLaborCost);
    // calculate for sumOfFLCostRate
    sumOfFLCostRate = (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow) ? (sumOfFLCost / (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow)) : 0;
    // calculate for sumOfFLACost
    sumOfFLACost = (sumOfTotalFoodCostAndDrinkCost + sumOfTotalEmployeeLaborCostAndPartLaborCost + sumOfAdvertisingExpense);
    // calculate for sumOfFLACostRate
    sumOfFLACostRate = (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow) ? (sumOfFLACost / (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow)) : 0;
    // calculate for sumOfFLARCost
    sumOfFLARCost = (sumOfTotalFoodCostAndDrinkCost + sumOfTotalEmployeeLaborCostAndPartLaborCost + sumOfAdvertisingExpense + sumOfLandRentRow);
    // calculate for sumOfFLARCostRate
    sumOfFLARCostRate = (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow) ? (sumOfFLARCost / (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow)) : 0;
    // calculate for sumOfFLAROCost
    sumOfFLAROCost = (sumOfTotalFoodCostAndDrinkCost + sumOfTotalEmployeeLaborCostAndPartLaborCost + sumOfAdvertisingExpense + sumOfLandRentRow + sumOfTotalOtherVariableCostsAndOtherFixedCosts);
    // calculate for sumOfFLAROCostRate
    sumOfFLAROCostRate = (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow) ? (sumOfFLAROCost / (sumOfAdvertisingSale + sumOfSaleBudgetFreeRow)) : 0;

    // calculate for rateOfSumGrossProfitAndSumAmountOfSale
    rateOfSumGrossProfitAndSumAmountOfSale = sumOfListGrossProfit ? (sumOfListGrossProfit / (sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow)) : 0;
    // calculate for sumOfLaborShare
    sumOfLaborShare = sumOfListGrossProfit ? (sumOfTotalEmployeeLaborCostAndPartLaborCost / sumOfListGrossProfit) : 0;

    // calculate for amountOfEmployeeCostAndPartLaborCostBusyDay
    amountOfEmployeeCostAndPartLaborCostBusyDay = amountOfEmployeeCostBusyDay + amountOfPartLaborCostBusyDay;
    amountOfEmployeeCostAndPartLaborCostNormalDay = amountOfEmployeeCostNormalDay + amountOfPartLaborCostNormalDay;
    amountOfEmployeeCostAndPartLaborCostQuietDay = amountOfEmployeeCostQuietDay + amountOfPartLaborCostQuietDay;
    amountOfEmployeeCostAndPartLaborCostHolidayDay = amountOfEmployeeCostHolidayDay + amountOfPartLaborCostHolidayDay;
    // calculate for amountOfOtherFixedAndVariableCostBusyDay
    amountOfOtherFixedAndVariableCostBusyDay = amountOfOtherVariableCostBusyDay + amountOfOtherFixedCostBusyDay;
    amountOfOtherFixedAndVariableCostNormalDay = amountOfOtherFixedCostNormalDay + amountOfOtherVariableCostNormalDay;
    amountOfOtherFixedAndVariableCostQuietDay = amountOfOtherFixedCostQuietDay + amountOfOtherVariableCostQuietDay;
    amountOfOtherFixedAndVariableCostHolidayDay = amountOfOtherFixedCostHolidayDay + amountOfOtherVariableCostHolidayDay;
    // callculate for amountOperatingIncomeBusyDay
    amountOperatingIncomeBusyDay = amountOfGrossProfitBusyDay - amountOfEmployeeCostAndPartLaborCostBusyDay - amountOfOtherFixedAndVariableCostBusyDay - amountOfAdvertisingExpenseBusyDay - amountOfLandRentBusyDay;
    amountOperatingIncomeNormalDay = amountOfGrossProfitNormalDay - amountOfEmployeeCostAndPartLaborCostNormalDay - amountOfOtherFixedAndVariableCostNormalDay - amountOfAdvertisingExpenseNormalDay - amountOfLandRentNormalDay;
    amountOperatingIncomeQuietDay = amountOfGrossProfitQuietDay - amountOfEmployeeCostAndPartLaborCostQuietDay - amountOfOtherFixedAndVariableCostQuietDay - amountOfAdvertisingExpenseQuietDay - amountOfLandRentQuietDay;
    amountOperatingIncomeHolidayDay = amountOfGrossProfitHolidayDay - amountOfEmployeeCostAndPartLaborCostHolidayDay - amountOfOtherFixedAndVariableCostHolidayDay - amountOfAdvertisingExpenseHolidayDay - amountOfLandRentHolidayDay;
    // calculate for amountOfSaleEachBusyDay
    amountOfSaleEachBusyDay = amountOfBusyDay ? Math.round(amountOfSaleBusyDay / amountOfBusyDay) : 0;
    amountOfSaleEachNormalDay = amountOfNormalDay ? Math.round(amountOfSaleNormalDay / amountOfNormalDay) : 0;
    amountOfSaleEachQuietDay = amountOfQuietDay ? Math.round(amountOfSaleQuietDay / amountOfQuietDay) : 0;
    amountOfSaleEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfSaleHolidayDay / amountOfHolidayDay) : 0;

    amountOfStandardCostEachBusyDay = amountOfBusyDay ? Math.round(amountOfStandardCostBusyDay / amountOfBusyDay) : 0;
    amountOfStandardCostEachNormalDay = amountOfNormalDay ? Math.round(amountOfStandardCostNormalDay / amountOfNormalDay) : 0;
    amountOfStandardCostEachQuietDay = amountOfQuietDay ? Math.round(amountOfStandardCostQuietDay / amountOfQuietDay) : 0;
    amountOfStandardCostEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfStandardCostHolidayDay / amountOfHolidayDay) : 0;

    amountOfGrossProfitEachBusyDay = amountOfBusyDay ? Math.round(amountOfGrossProfitBusyDay / amountOfBusyDay) : 0;
    amountOfGrossProfitEachNormalDay = amountOfNormalDay ? Math.round(amountOfGrossProfitNormalDay / amountOfNormalDay) : 0;
    amountOfGrossProfitEachQuietDay = amountOfQuietDay ? Math.round(amountOfGrossProfitQuietDay / amountOfQuietDay) : 0;
    amountOfGrossProfitEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfGrossProfitHolidayDay / amountOfHolidayDay) : 0;

    amountOfEmployeeCostEachBusyDay = amountOfBusyDay ? Math.round(amountOfEmployeeCostBusyDay / amountOfBusyDay) : 0;
    amountOfEmployeeCostEachNormalDay = amountOfNormalDay ? Math.round(amountOfEmployeeCostNormalDay / amountOfNormalDay) : 0;
    amountOfEmployeeCostEachQuietDay = amountOfQuietDay ? Math.round(amountOfEmployeeCostQuietDay / amountOfQuietDay) : 0;
    amountOfEmployeeCostEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfEmployeeCostHolidayDay / amountOfHolidayDay) : 0;

    amountOfPartLaborCostEachBusyDay = amountOfBusyDay ? Math.round(amountOfPartLaborCostBusyDay / amountOfBusyDay) : 0;
    amountOfPartLaborCostEachNormalDay = amountOfNormalDay ? Math.round(amountOfPartLaborCostNormalDay / amountOfNormalDay) : 0;
    amountOfPartLaborCostEachQuietDay = amountOfQuietDay ? Math.round(amountOfPartLaborCostQuietDay / amountOfQuietDay) : 0;
    amountOfPartLaborCostEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfPartLaborCostHolidayDay / amountOfHolidayDay) : 0;

    amountOfEmployeeCostAndPartLaborCostEachBusyDay = amountOfEmployeeCostEachBusyDay + amountOfPartLaborCostEachBusyDay;
    amountOfEmployeeCostAndPartLaborCostEachNormalDay = amountOfEmployeeCostEachNormalDay + amountOfPartLaborCostEachNormalDay;
    amountOfEmployeeCostAndPartLaborCostEachQuietDay = amountOfEmployeeCostEachQuietDay + amountOfPartLaborCostEachQuietDay;
    amountOfEmployeeCostAndPartLaborCostEachHolidayDay = amountOfEmployeeCostEachHolidayDay + amountOfPartLaborCostEachHolidayDay;

    amountOfAdvertisingExpenseEachBusyDay = amountOfBusyDay ? Math.round(amountOfAdvertisingExpenseBusyDay / amountOfBusyDay) : 0;
    amountOfAdvertisingExpenseEachNormalDay = amountOfNormalDay ? Math.round(amountOfAdvertisingExpenseNormalDay / amountOfNormalDay) : 0;
    amountOfAdvertisingExpenseEachQuietDay = amountOfQuietDay ? Math.round(amountOfAdvertisingExpenseQuietDay / amountOfQuietDay) : 0;
    amountOfAdvertisingExpenseEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfAdvertisingExpenseHolidayDay / amountOfHolidayDay) : 0;

    amountOfLandRentEachBusyDay = amountOfBusyDay ? Math.round(amountOfLandRentBusyDay / amountOfBusyDay) : 0;
    amountOfLandRentEachNormalDay = amountOfNormalDay ? Math.round(amountOfLandRentNormalDay / amountOfNormalDay) : 0;
    amountOfLandRentEachQuietDay = amountOfQuietDay ? Math.round(amountOfLandRentQuietDay / amountOfQuietDay) : 0;
    amountOfLandRentEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfLandRentHolidayDay / amountOfHolidayDay) : 0;

    amountOfOtherFixedCostEachBusyDay = amountOfBusyDay ? Math.round(amountOfOtherFixedCostBusyDay / amountOfBusyDay) : 0;
    amountOfOtherFixedCostEachNormalDay = amountOfNormalDay ? Math.round(amountOfOtherFixedCostNormalDay / amountOfNormalDay) : 0;
    amountOfOtherFixedCostEachQuietDay = amountOfQuietDay ? Math.round(amountOfOtherFixedCostQuietDay / amountOfQuietDay) : 0;
    amountOfOtherFixedCostEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfOtherFixedCostHolidayDay / amountOfHolidayDay) : 0;

    amountOfOtherVariableCostEachBusyDay = amountOfBusyDay ? Math.round(amountOfOtherVariableCostBusyDay / amountOfBusyDay) : 0;
    amountOfOtherVariableCostEachNormalDay = amountOfNormalDay ? Math.round(amountOfOtherVariableCostNormalDay / amountOfNormalDay) : 0;
    amountOfOtherVariableCostEachQuietDay = amountOfQuietDay ? Math.round(amountOfOtherVariableCostQuietDay / amountOfQuietDay) : 0;
    amountOfOtherVariableCostEachHolidayDay = amountOfHolidayDay ? Math.round(amountOfOtherVariableCostHolidayDay / amountOfHolidayDay) : 0;

    amountOfOtherFixedAndVariableCostEachBusyDay = amountOfOtherFixedCostEachBusyDay + amountOfOtherVariableCostEachBusyDay;
    amountOfOtherFixedAndVariableCostEachNormalDay = amountOfOtherFixedCostEachNormalDay + amountOfOtherVariableCostEachNormalDay;
    amountOfOtherFixedAndVariableCostEachQuietDay = amountOfOtherFixedCostEachQuietDay + amountOfOtherVariableCostEachQuietDay;
    amountOfOtherFixedAndVariableCostEachHolidayDay = amountOfOtherFixedCostEachHolidayDay + amountOfOtherVariableCostEachHolidayDay;

    amountOperatingIncomeEachBusyDay = amountOfGrossProfitEachBusyDay - amountOfEmployeeCostAndPartLaborCostEachBusyDay - amountOfOtherFixedAndVariableCostEachBusyDay - amountOfAdvertisingExpenseEachBusyDay - amountOfLandRentEachBusyDay;
    amountOperatingIncomeEachNormalDay = amountOfGrossProfitEachNormalDay - amountOfEmployeeCostAndPartLaborCostEachNormalDay - amountOfOtherFixedAndVariableCostEachNormalDay - amountOfAdvertisingExpenseEachNormalDay - amountOfLandRentEachNormalDay;
    amountOperatingIncomeEachQuietDay = amountOfGrossProfitEachQuietDay - amountOfEmployeeCostAndPartLaborCostEachQuietDay - amountOfOtherFixedAndVariableCostEachQuietDay - amountOfAdvertisingExpenseEachQuietDay - amountOfLandRentEachQuietDay;
    amountOperatingIncomeEachHolidayDay = amountOfGrossProfitEachHolidayDay - amountOfEmployeeCostAndPartLaborCostEachHolidayDay - amountOfOtherFixedAndVariableCostEachHolidayDay - amountOfAdvertisingExpenseEachHolidayDay - amountOfLandRentEachHolidayDay;

    totalSale = sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow;
    sumOfOperatingIncomeMonth = sumOfListGrossProfit - sumOfTotalEmployeeLaborCostAndPartLaborCost - sumOfAdvertisingExpense - sumOfLandRentRow - sumOfTotalOtherVariableCostsAndOtherFixedCosts;
    totalSaleEachDay = Math.round(totalSale / amountDayInMonth);
    standardCostEachDay = Math.round(sumOfTotalFoodCostAndDrinkCost / amountDayInMonth);
    GrossProfitEachDay = Math.round(sumOfListGrossProfit / amountDayInMonth);
    EmployeeLaborCostEachDay = Math.round(saleBudgetMonthData[0][9] / amountDayInMonth);
    PartLaborCostEachDay = Math.round(sumOfPartLaborCostRow / amountDayInMonth);
    EmployeeAndPartLaborCostEachDay = EmployeeLaborCostEachDay + PartLaborCostEachDay;
    AdvertisingExpenseEachDay = Math.round((sumOfAdvertisingExpense / amountDayInMonth));
    LandRentEachDay = Math.round(sumOfLandRentRow / amountDayInMonth);
    OtherFixedCostEachDay = Math.round(sumOfOtherFixedCostsRow / amountDayInMonth);
    OtherVariableCostEachDay = Math.round(sumOfOtherVariableCostsRow / amountDayInMonth);
    OtherFixedAndVariableCostEachDay = Math.round(sumOfTotalOtherVariableCostsAndOtherFixedCosts / amountDayInMonth);
    OperatingIncomeEachDay = GrossProfitEachDay - EmployeeAndPartLaborCostEachDay - AdvertisingExpenseEachDay - LandRentEachDay - OtherFixedAndVariableCostEachDay;
    setListSumOfAdvertisingSaleAndSaleBudgetFree(listSumOfAdvertisingSaleAndSaleBudgetFree);
    setListSumOfAdvertisingEachDay(listSumOfAdvertisingEachDay);
    setListGrossProfit(listGrossProfit);
    setListSumOfEmployeeLaborCostAndPartLaborCost(listSumOfEmployeeLaborCostAndPartLaborCost);
    setSumOfAdvertisingSale(sumOfAdvertisingSale);
    setAmountOfBusyDay(amountOfBusyDay);
    setAmountOfNormalDay(amountOfNormalDay);
    setAmountOfQuietDay(amountOfQuietDay);
    setAmountOfHolidayDay(amountOfHolidayDay);
    setAmountOfSaleBusyDay(amountOfSaleBusyDay);
    setAmountOfSaleNormalDay(amountOfSaleNormalDay);
    setAmountOfSaleQuietDay(amountOfSaleQuietDay);
    setAmountOfSaleHolidayDay(amountOfSaleHolidayDay);
    setAmountOfSaleEachBusyDay(amountOfSaleEachBusyDay);
    setAmountOfSaleEachNormalDay(amountOfSaleEachNormalDay);
    setAmountOfSaleEachQuietDay(amountOfSaleEachQuietDay);
    setAmountOfSaleEachHolidayDay(amountOfSaleEachHolidayDay);
    setAmountOfStandardCostBusyDay(amountOfStandardCostBusyDay);
    setAmountOfStandardCostNormalDay(amountOfStandardCostNormalDay);
    setAmountOfStandardCostQuietDay(amountOfStandardCostQuietDay);
    setAmountOfStandardCostHolidayDay(amountOfStandardCostHolidayDay);
    setAmountOfStandardCostEachBusyDay(amountOfStandardCostEachBusyDay);
    setAmountOfStandardCostEachNormalDay(amountOfStandardCostEachNormalDay);
    setAmountOfStandardCostEachQuietDay(amountOfStandardCostEachQuietDay);
    setAmountOfStandardCostEachHolidayDay(amountOfStandardCostEachHolidayDay);
    setAmountOfGrossProfitBusyDay(amountOfGrossProfitBusyDay);
    setAmountOfGrossProfitNormalDay(amountOfGrossProfitNormalDay);
    setAmountOfGrossProfitQuietDay(amountOfGrossProfitQuietDay);
    setAmountOfGrossProfitHolidayDay(amountOfGrossProfitHolidayDay);
    setAmountOfGrossProfitEachBusyDay(amountOfGrossProfitEachBusyDay);
    setAmountOfGrossProfitEachNormalDay(amountOfGrossProfitEachNormalDay);
    setAmountOfGrossProfitEachQuietDay(amountOfGrossProfitEachQuietDay);
    setAmountOfGrossProfitEachHolidayDay(amountOfGrossProfitEachHolidayDay);
    setAmountOfEmployeeCostBusyDay(amountOfEmployeeCostBusyDay);
    setAmountOfEmployeeCostNormalDay(amountOfEmployeeCostNormalDay);
    setAmountOfEmployeeCostQuietDay(amountOfEmployeeCostQuietDay);
    setAmountOfEmployeeCostHolidayDay(amountOfEmployeeCostHolidayDay);
    setAmountOfEmployeeCostEachBusyDay(amountOfEmployeeCostEachBusyDay);
    setAmountOfEmployeeCostEachNormalDay(amountOfEmployeeCostEachNormalDay);
    setAmountOfEmployeeCostEachQuietDay(amountOfEmployeeCostEachQuietDay);
    setAmountOfEmployeeCostEachHolidayDay(amountOfEmployeeCostEachHolidayDay);
    setAmountOfPartLaborCostBusyDay(amountOfPartLaborCostBusyDay);
    setAmountOfPartLaborCostNormalDay(amountOfPartLaborCostNormalDay);
    setAmountOfPartLaborCostQuietDay(amountOfPartLaborCostQuietDay);
    setAmountOfPartLaborCostHolidayDay(amountOfPartLaborCostHolidayDay);
    setAmountOfPartLaborCostEachBusyDay(amountOfPartLaborCostEachBusyDay);
    setAmountOfPartLaborCostEachNormalDay(amountOfPartLaborCostEachNormalDay);
    setAmountOfPartLaborCostEachQuietDay(amountOfPartLaborCostEachQuietDay);
    setAmountOfPartLaborCostEachHolidayDay(amountOfPartLaborCostEachHolidayDay);
    setAmountOfEmployeeCostAndPartLaborCostBusyDay(amountOfEmployeeCostAndPartLaborCostBusyDay);
    setAmountOfEmployeeCostAndPartLaborCostNormalDay(amountOfEmployeeCostAndPartLaborCostNormalDay);
    setAmountOfEmployeeCostAndPartLaborCostQuietDay(amountOfEmployeeCostAndPartLaborCostQuietDay);
    setAmountOfEmployeeCostAndPartLaborCostHolidayDay(amountOfEmployeeCostAndPartLaborCostHolidayDay);
    setAmountOfEmployeeCostAndPartLaborCostEachBusyDay(amountOfEmployeeCostAndPartLaborCostEachBusyDay);
    setAmountOfEmployeeCostAndPartLaborCostEachNormalDay(amountOfEmployeeCostAndPartLaborCostEachNormalDay);
    setAmountOfEmployeeCostAndPartLaborCostEachQuietDay(amountOfEmployeeCostAndPartLaborCostEachQuietDay);
    setAmountOfEmployeeCostAndPartLaborCostEachHolidayDay(amountOfEmployeeCostAndPartLaborCostEachHolidayDay);
    setAmountOfAdvertisingExpenseBusyDay(amountOfAdvertisingExpenseBusyDay);
    setAmountOfAdvertisingExpenseNormalDay(amountOfAdvertisingExpenseNormalDay);
    setAmountOfAdvertisingExpenseQuietDay(amountOfAdvertisingExpenseQuietDay);
    setAmountOfAdvertisingExpenseHolidayDay(amountOfAdvertisingExpenseHolidayDay);
    setAmountOfAdvertisingExpenseEachBusyDay(amountOfAdvertisingExpenseEachBusyDay);
    setAmountOfAdvertisingExpenseEachNormalDay(amountOfAdvertisingExpenseEachNormalDay);
    setAmountOfAdvertisingExpenseEachQuietDay(amountOfAdvertisingExpenseEachQuietDay);
    setAmountOfAdvertisingExpenseEachHolidayDay(amountOfAdvertisingExpenseEachHolidayDay);
    setAmountOfLandRentBusyDay(amountOfLandRentBusyDay);
    setAmountOfLandRentNormalDay(amountOfLandRentNormalDay);
    setAmountOfLandRentQuietDay(amountOfLandRentQuietDay);
    setAmountOfLandRentHolidayDay(amountOfLandRentHolidayDay);
    setAmountOfLandRentEachBusyDay(amountOfLandRentEachBusyDay);
    setAmountOfLandRentEachNormalDay(amountOfLandRentEachNormalDay);
    setAmountOfLandRentEachQuietDay(amountOfLandRentEachQuietDay);
    setAmountOfLandRentEachHolidayDay(amountOfLandRentEachHolidayDay);
    setAmountOfOtherFixedCostBusyDay(amountOfOtherFixedCostBusyDay);
    setAmountOfOtherFixedCostNormalDay(amountOfOtherFixedCostNormalDay);
    setAmountOfOtherFixedCostQuietDay(amountOfOtherFixedCostQuietDay);
    setAmountOfOtherFixedCostHolidayDay(amountOfOtherFixedCostHolidayDay);
    setAmountOfOtherFixedCostEachBusyDay(amountOfOtherFixedCostEachBusyDay);
    setAmountOfOtherFixedCostEachNormalDay(amountOfOtherFixedCostEachNormalDay);
    setAmountOfOtherFixedCostEachQuietDay(amountOfOtherFixedCostEachQuietDay);
    setAmountOfOtherFixedCostEachHolidayDay(amountOfOtherFixedCostEachHolidayDay);
    setAmountOfOtherVariableCostBusyDay(amountOfOtherVariableCostBusyDay);
    setAmountOfOtherVariableCostNormalDay(amountOfOtherVariableCostNormalDay);
    setAmountOfOtherVariableCostQuietDay(amountOfOtherVariableCostQuietDay);
    setAmountOfOtherVariableCostHolidayDay(amountOfOtherVariableCostHolidayDay);
    setAmountOfOtherVariableCostEachBusyDay(amountOfOtherVariableCostEachBusyDay);
    setamountOfOtherVariableCostEachNormalDay(amountOfOtherVariableCostEachNormalDay);
    setAmountOfOtherVariableCostEachQuietDay(amountOfOtherVariableCostEachQuietDay);
    setAmountOfOtherVariableCostEachHolidayDay(amountOfOtherVariableCostEachHolidayDay);
    setAmountOfOtherFixedAndVariableCostBusyDay(amountOfOtherFixedAndVariableCostBusyDay);
    setAmountOfOtherFixedAndVariableCostNormalDay(amountOfOtherFixedAndVariableCostNormalDay);
    setAmountOfOtherFixedAndVariableCostQuietDay(amountOfOtherFixedAndVariableCostQuietDay);
    setAmountOfOtherFixedAndVariableCostHolidayDay(amountOfOtherFixedAndVariableCostHolidayDay);
    setAmountOfOtherFixedAndVariableCostEachBusyDay(amountOfOtherFixedAndVariableCostEachBusyDay);
    setAmountOfOtherFixedAndVariableCostEachNormalDay(amountOfOtherFixedAndVariableCostEachNormalDay);
    setAmountOfOtherFixedAndVariableCostEachQuietDay(amountOfOtherFixedAndVariableCostEachQuietDay);
    setAmountOfOtherFixedAndVariableCostEachHolidayDay(amountOfOtherFixedAndVariableCostEachHolidayDay);
    setAmountOperatingIncomeBusyDay(amountOperatingIncomeBusyDay);
    setAmountOperatingIncomeNormalDay(amountOperatingIncomeNormalDay);
    setAmountOperatingIncomeQuietDay(amountOperatingIncomeQuietDay);
    setAmountOperatingIncomeHolidayDay(amountOperatingIncomeHolidayDay);
    setAmountOperatingIncomeEachBusyDay(amountOperatingIncomeEachBusyDay);
    setAmountOperatingIncomeEachNormalDay(amountOperatingIncomeEachNormalDay);
    setAmountOperatingIncomeEachQuietDay(amountOperatingIncomeEachQuietDay);
    setAmountOperatingIncomeEachHolidayDay(amountOperatingIncomeEachHolidayDay);
    setTotalSale(totalSale);
    setSumOfOperatingIncomeMonth(sumOfOperatingIncomeMonth);
    setTotalSaleEachDay(totalSaleEachDay);
    setStandardCostEachDay(standardCostEachDay);
    setGrossProfitEachDay(GrossProfitEachDay);
    setEmployeeLaborCostEachDay(EmployeeLaborCostEachDay);
    setPartLaborCostEachDay(PartLaborCostEachDay);
    setEmployeeAndPartLaborCostEachDay(EmployeeAndPartLaborCostEachDay);
    setAdvertisingExpenseEachDay(AdvertisingExpenseEachDay);
    setLandRentEachDay(LandRentEachDay);
    setOtherFixedCostEachDay(OtherFixedCostEachDay);
    setOtherVariableCostEachDay(OtherVariableCostEachDay);
    setOtherFixedAndVariableCostEachDay(OtherFixedAndVariableCostEachDay);
    setOperatingIncomeEachDay(OperatingIncomeEachDay);
    setSumOfTotalFoodCostAndDrinkCost(sumOfTotalFoodCostAndDrinkCost);
    setSumOfListGrossProfit(sumOfListGrossProfit);
    setSumOfTotalEmployeeLaborCostAndPartLaborCost(sumOfTotalEmployeeLaborCostAndPartLaborCost);
    setTotalBias(totalBias);
    setSumOfEmployeeLaborCostRow(sumOfEmployeeLaborCostRow);
    setSumOfPartLaborCostRow(sumOfPartLaborCostRow);
    setSumOfAdvertisingExpense(sumOfAdvertisingExpense);
    setSumOfLandRentRow(sumOfLandRentRow);
    setSumOfOtherVariableCostsRow(sumOfOtherVariableCostsRow);
    setSumOfOtherFixedCostsRow(sumOfOtherFixedCostsRow);
    setListSumOfFoodCostAndDrinkCost(listSumOfFoodCostAndDrinkCost);
    setListRateOfGrossProfit(listRateOfGrossProfit);
    setListSumOfAdvertisingExpenseEachDay(listSumOfAdvertisingExpenseEachDay);
    setListSumOfOtherVariableCostsAndOtherFixedCosts(listSumOfOtherVariableCostsAndOtherFixedCosts);
    setListOfOperatingIncome(listOfOperatingIncome);
    setListOfRateOperatingIncome(listOfRateOperatingIncome);
    setListNumberOfCustomers(listNumberOfCustomers);
    setListOfTurnoverRate(listOfTurnoverRate);
    setListOfMarginalProfit(listOfMarginalProfit);
    setListRateOfMarginalProfit(listRateOfMarginalProfit);
    setListOfContributionMargin(listOfContributionMargin);
    setListOfRateContributionMargin(listOfRateContributionMargin);
    setListOfBreakEventPointSale(listOfBreakEventPointSale);
    setListOfBreakEventPointNumber(listOfBreakEventPointNumber);
    setListSumOfEmployeeAndPartCostRatio(listSumOfEmployeeAndPartCostRatio);
    setListOfEmployeeCostRatio(listOfEmployeeCostRatio);
    setListOfPartCostRatio(listOfPartCostRatio);
    setListOfLaborShare(listOfLaborShare);
    setListOfFLCost(listOfFLCost);
    setListOfFLCostRate(listOfFLCostRate);
    setListOfFLACost(listOfFLACost);
    setListOfFLACostRate(listOfFLACostRate);
    setListOfFLARCost(listOfFLARCost);
    setListOfFLARCostRate(listOfFLARCostRate);
    setListOfFLAROCost(listOfFLAROCost);
    setListOfFLAROCostRate(listOfFLAROCostRate);
    setSumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow(sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow);

    setRateOfSumGrossProfitAndSumAmountOfSale(rateOfSumGrossProfitAndSumAmountOfSale);
    setSumOfTotalOtherVariableCostsAndOtherFixedCosts(sumOfTotalOtherVariableCostsAndOtherFixedCosts);
    setTotalOfOperatingIncome(totalOfOperatingIncome);
    setRateOfOperationIncome(rateOfOperationIncome);
    setSumOfNumberOfCustomers(sumOfNumberOfCustomers);
    setSumOfTurnoverRate(sumOfTurnoverRate);
    setSumOfMarginalProfit(sumOfMarginalProfit);
    setSumOfRateMarginalProfit(sumOfRateMarginalProfit);
    setSumOfContributionMargin(sumOfContributionMargin);
    setSumOfRateContributionMargin(sumOfRateContributionMargin);
    setSumOfBreakEventPointSale(sumOfBreakEventPointSale);
    setSumOfBreakEventPointNumber(sumOfBreakEventPointNumber);
    setSumOfSumOfEmployeeAndPartCostRatio(sumOfSumOfEmployeeAndPartCostRatio);
    setSumOfEmployeeCostRatio(sumOfEmployeeCostRatio);
    setSumOfPartCostRatio(sumOfPartCostRatio);
    setSumOfLaborShare(sumOfLaborShare);
    setSumOfFLCost(sumOfFLCost);
    setSumOfFLCostRate(sumOfFLCostRate);
    setSumOfFLACostRate(sumOfFLACostRate);
    setSumOfFLARCost(sumOfFLARCost);
    setSumOfFLARCostRate(sumOfFLARCostRate);
    setSumOfFLAROCostRate(sumOfFLAROCostRate);
    setSumOfFLAROCost(sumOfFLAROCost);
    setSumOfFLACost(sumOfFLACost);
  };

  return {
    getListBiasSetting,
    confirmModalOpen,
    closeConfirmModal,
    arrayIndexHoliday,
    getRows: {
      getAmountOfBusyDay,
      getAmountOfNormalDay,
      getAmountOfQuietDay,
      getAmountOfHolidayDay,
      getAmountOfSaleBusyDay,
      getAmountOfSaleNormalDay,
      getAmountOfSaleQuietDay,
      getAmountOfSaleHolidayDay,
      getAmountOfSaleEachBusyDay,
      getAmountOfSaleEachNormalDay,
      getAmountOfSaleEachQuietDay,
      getAmountOfSaleEachHolidayDay,
      getAmountOfStandardCostBusyDay,
      getAmountOfStandardCostNormalDay,
      getAmountOfStandardCostQuietDay,
      getAmountOfStandardCostHolidayDay,
      getAmountOfStandardCostEachBusyDay,
      getAmountOfStandardCostEachNormalDay,
      getAmountOfStandardCostEachQuietDay,
      getAmountOfStandardCostEachHolidayDay,
      getAmountOfGrossProfitBusyDay,
      getAmountOfGrossProfitNormalDay,
      getAmountOfGrossProfitQuietDay,
      getAmountOfGrossProfitHolidayDay,
      getAmountOfGrossProfitEachBusyDay,
      getAmountOfGrossProfitEachNormalDay,
      getAmountOfGrossProfitEachQuietDay,
      getAmountOfGrossProfitEachHolidayDay,
      getAmountOfEmployeeCostBusyDay,
      getAmountOfEmployeeCostNormalDay,
      getAmountOfEmployeeCostQuietDay,
      getAmountOfEmployeeCostHolidayDay,
      getAmountOfEmployeeCostEachBusyDay,
      getAmountOfEmployeeCostEachNormalDay,
      getAmountOfEmployeeCostEachQuietDay,
      getAmountOfEmployeeCostEachHolidayDay,
      getAmountOfPartLaborCostBusyDay,
      getAmountOfPartLaborCostNormalDay,
      getAmountOfPartLaborCostQuietDay,
      getAmountOfPartLaborCostHolidayDay,
      getAmountOfPartLaborCostEachBusyDay,
      getAmountOfPartLaborCostEachNormalDay,
      getAmountOfPartLaborCostEachQuietDay,
      getAmountOfPartLaborCostEachHolidayDay,
      getAmountOfEmployeeCostAndPartLaborCostBusyDay,
      getAmountOfEmployeeCostAndPartLaborCostNormalDay,
      getAmountOfEmployeeCostAndPartLaborCostQuietDay,
      getAmountOfEmployeeCostAndPartLaborCostHolidayDay,
      getAmountOfEmployeeCostAndPartLaborCostEachBusyDay,
      getAmountOfEmployeeCostAndPartLaborCostEachNormalDay,
      getAmountOfEmployeeCostAndPartLaborCostEachQuietDay,
      getAmountOfEmployeeCostAndPartLaborCostEachHolidayDay,
      getAmountOfAdvertisingExpenseBusyDay,
      getAmountOfAdvertisingExpenseNormalDay,
      getAmountOfAdvertisingExpenseQuietDay,
      getAmountOfAdvertisingExpenseHolidayDay,
      getAmountOfAdvertisingExpenseEachBusyDay,
      getAmountOfAdvertisingExpenseEachNormalDay,
      getAmountOfAdvertisingExpenseEachQuietDay,
      getAmountOfAdvertisingExpenseEachHolidayDay,
      getAmountOfLandRentBusyDay,
      getAmountOfLandRentNormalDay,
      getAmountOfLandRentQuietDay,
      getAmountOfLandRentHolidayDay,
      getAmountOfLandRentEachBusyDay,
      getAmountOfLandRentEachNormalDay,
      getAmountOfLandRentEachQuietDay,
      getAmountOfLandRentEachHolidayDay,
      getAmountOfOtherFixedCostBusyDay,
      getAmountOfOtherFixedCostNormalDay,
      getAmountOfOtherFixedCostQuietDay,
      getAmountOfOtherFixedCostHolidayDay,
      getAmountOfOtherFixedCostEachBusyDay,
      getAmountOfOtherFixedCostEachNormalDay,
      getAmountOfOtherFixedCostEachQuietDay,
      getAmountOfOtherFixedCostEachHolidayDay,
      getAmountOfOtherVariableCostBusyDay,
      getAmountOfOtherVariableCostNormalDay,
      getAmountOfOtherVariableCostQuietDay,
      getAmountOfOtherVariableCostHolidayDay,
      getAmountOfOtherVariableCostEachBusyDay,
      getAmountOfOtherVariableCostEachNormalDay,
      getAmountOfOtherVariableCostEachQuietDay,
      getAmountOfOtherVariableCostEachHolidayDay,
      getAmountOfOtherFixedAndVariableCostBusyDay,
      getAmountOfOtherFixedAndVariableCostNormalDay,
      getAmountOfOtherFixedAndVariableCostQuietDay,
      getAmountOfOtherFixedAndVariableCostHolidayDay,
      getAmountOfOtherFixedAndVariableCostEachBusyDay,
      getAmountOfOtherFixedAndVariableCostEachNormalDay,
      getAmountOfOtherFixedAndVariableCostEachQuietDay,
      getAmountOfOtherFixedAndVariableCostEachHolidayDay,
      getAmountOperatingIncomeBusyDay,
      getAmountOperatingIncomeNormalDay,
      getAmountOperatingIncomeQuietDay,
      getAmountOperatingIncomeHolidayDay,
      getAmountOperatingIncomeEachBusyDay,
      getAmountOperatingIncomeEachNormalDay,
      getAmountOperatingIncomeEachQuietDay,
      getAmountOperatingIncomeEachHolidayDay,
      getTotalSale,
      getSumOfOperatingIncomeMonth,
      getTotalSaleEachDay,
      getStandardCostEachDay,
      getGrossProfitEachDay,
      getEmployeeLaborCostEachDay,
      getPartLaborCostEachDay,
      getEmployeeAndPartLaborCostEachDay,
      getAdvertisingExpenseEachDay,
      getLandRentEachDay,
      getOtherFixedCostEachDay,
      getOtherVariableCostEachDay,
      getOtherFixedAndVariableCostEachDay,
      getOperatingIncomeEachDay,
      getTotalBias,
      getAdvertisingSaleData,
      getSaleBudgetListData,
      getBusyBias,
      getNormalBias,
      getQuietBias,
      getHolidayBias,
      getSaleBudgetAdvertisingMonthData,
      getSalesPlanDailyData,
      getSaleBudgetMonthData,
      getAdvertisingExpenseData,
      getPerCustomerPrice,
      getNumberOfSeat,
      getSumOfTotalFoodCostAndDrinkCost,
      getSumOfListGrossProfit,
      getSumOfTotalEmployeeLaborCostAndPartLaborCost,
      getSumOfEmployeeLaborCostRow,
      getSumOfPartLaborCostRow,
      getSumOfAdvertisingExpense,
      getSumOfLandRentRow,
      getSumOfOtherVariableCostsRow,
      getSumOfOtherFixedCostsRow,
      getListSumOfAdvertisingSaleAndSaleBudgetFree,
      getListSumOfAdvertisingEachDay,
      getListSumOfFoodCostAndDrinkCost,
      getListGrossProfit,
      getListRateOfGrossProfit,
      getListSumOfEmployeeLaborCostAndPartLaborCost,
      getListSumOfAdvertisingExpenseEachDay,
      getListSumOfOtherVariableCostsAndOtherFixedCosts,
      getListOfOperatingIncome,
      getListOfRateOperatingIncome,
      getListNumberOfCustomers,
      getListOfTurnoverRate,
      getListOfMarginalProfit,
      getListRateOfMarginalProfit,
      getListOfRateContributionMargin,
      getListOfBreakEventPointSale,
      getListOfBreakEventPointNumber,
      getListSumOfEmployeeAndPartCostRatio,
      getListOfEmployeeCostRatio,
      getListOfPartCostRatio,
      getListOfLaborShare,
      getListOfFLCost,
      getListOfFLCostRate,
      getListOfFLACost,
      getListOfFLACostRate,
      getListOfFLARCost,
      getListOfFLARCostRate,
      getListOfFLAROCost,
      getListOfFLAROCostRate,
      getListOfContributionMargin,
      getSumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow,
      getSumOfAdvertisingSale,
      getRateOfSumGrossProfitAndSumAmountOfSale,
      getSumOfTotalOtherVariableCostsAndOtherFixedCosts,
      getTotalOfOperatingIncome,
      getRateOfOperationIncome,
      getSumOfNumberOfCustomers,
      getSumOfTurnoverRate,
      getSumOfMarginalProfit,
      getSumOfRateMarginalProfit,
      getSumOfContributionMargin,
      getSumOfRateContributionMargin,
      getSumOfBreakEventPointSale,
      getSumOfBreakEventPointNumber,
      getSumOfSumOfEmployeeAndPartCostRatio,
      getSumOfEmployeeCostRatio,
      getSumOfPartCostRatio,
      getSumOfLaborShare,
      getSumOfFLCost,
      getSumOfFLCostRate,
      getSumOfFLACostRate,
      getSumOfFLARCost,
      getSumOfFLARCostRate,
      getSumOfFLAROCostRate,
      getSumOfFLAROCost,
      getSumOfFLACost,
    },
    handleChangeInputBias,
    isLoading,
    formik,
    handleChangeSelect,
  };
};
const sumOfArray = (array: any) => array.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
export default { useSalesBudgetMonth };
