/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const MonthDayTable: React.FC<{
  subTotalDay: any;
  holidaySubtotalItem: any;
  countHolidayColspan: any;
  remote: boolean;
}> = ({ subTotalDay, holidaySubtotalItem, countHolidayColspan, remote }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  return (
    <div
      css={css`
        table thead tr th {
          height: 46px;
        }
        table thead tr td div {
          text-align: center;
        }
        table tbody tr td {
          height: 32px;
        }
      `}
    >
      <table
        id="monthHourList"
        className="table table-bordered table-condensed"
        cellSpacing="0"
        style={{ whiteSpace: "nowrap", width: "100%" }}
      >
        <thead>
          <tr>
            <td colSpan={2}>
              <div css={thStyle}>
                出勤日数{remote && `(オフィス/${companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"})`}
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr role="row">
            <td style={{ width: "170px" }}>
              <div css={thStyle}>平日</div>
            </td>
            <td>
              {subTotalDay != null && (
                <div css={tdStyle}>
                  {subTotalDay.normalWork}
                  {remote && parseInt(subTotalDay.normalWork) > 0
                    ? "(" + parseInt(subTotalDay.officeWork) + "/" + parseInt(subTotalDay.remoteWork) + ")"
                    : ""}
                </div>
              )}
            </td>
          </tr>
          {subTotalDay.distinctionHoliday ? (
            <React.Fragment>
              <tr role="row">
                <td style={{ width: "170px" }}>
                  <div css={thStyle}>法定休日</div>
                </td>
                <td>
                  {subTotalDay != null && (
                    <div css={tdStyle}>
                      {subTotalDay.legalHolidayWork}
                      {remote && parseInt(subTotalDay.legalHolidayWork) > 0
                        ? "(" +
                          parseInt(subTotalDay.legalOfficeHolidayWork) +
                          "/" +
                          parseInt(subTotalDay.legalRemoteHolidayWork) +
                          ")"
                        : ""}
                    </div>
                  )}
                </td>
              </tr>
              <tr role="row">
                <td style={{ width: "170px" }}>
                  <div css={thStyle}>所定休日</div>
                </td>
                <td>
                  {subTotalDay != null && (
                    <div css={tdStyle}>
                      {subTotalDay.nonLegalHolidayWork}
                      {remote && parseInt(subTotalDay.nonLegalHolidayWork) > 0
                        ? "(" +
                          parseInt(subTotalDay.nonLegalOfficeHolidayWork) +
                          "/" +
                          parseInt(subTotalDay.nonLegalRemoteHolidayWork) +
                          ")"
                        : ""}
                    </div>
                  )}
                </td>
              </tr>
            </React.Fragment>
          ) : (
            <tr role="row">
              <td style={{ width: "170px" }}>
                <div css={thStyle}>休日</div>
              </td>
              <td>
                {subTotalDay != null && (
                  <div css={tdStyle}>
                    {subTotalDay.holidayWork}
                    {remote && parseInt(subTotalDay.legalHolidayWork) > 0
                      ? "(" +
                        parseInt(subTotalDay.legalOfficeHolidayWork) +
                        "/" +
                        parseInt(subTotalDay.legalRemoteHolidayWork) +
                        ")"
                      : ""}
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table
        id="monthHourList"
        className="table table-bordered table-condensed"
        cellSpacing="0"
        style={{ whiteSpace: "nowrap", width: "100%" }}
      >
        <thead>
          <tr>
            <td colSpan={2}>
              <div css={thStyle}>休暇日数</div>
            </td>
          </tr>
        </thead>
        <tbody>
          {holidaySubtotalItem?.map((item: any, index: any) => (
            <tr key={String(index)} role="row">
              <td style={{ width: "170px" }}>
                <div css={thStyle}>
                  {item.holidayName}
                  {subTotalDay.distinctionHoliday && item.holidayType === 0 && item.isLegal ? "（法定）" : ""}
                  {subTotalDay.distinctionHoliday && item.holidayType === 0 && !item.isLegal ? "（所定）" : ""}
                </div>
              </td>
              <td>
                {subTotalDay != null && (
                  <div css={tdStyle}>{subTotalDay.attendHolidaySubtotals[index].resultHoliday}</div>
                )}
              </td>
            </tr>
          ))}
          <tr role="row">
            <td style={{ width: "170px" }}>
              <div css={thStyle}>遅刻</div>
            </td>
            <td>{subTotalDay != null && <div css={tdStyle}>{subTotalDay.late}</div>}</td>
          </tr>
          <tr role="row">
            <td style={{ width: "170px" }}>
              <div css={thStyle}>早退</div>
            </td>
            <td>{subTotalDay != null && <div css={tdStyle}>{subTotalDay.earlier}</div>}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default MonthDayTable;
