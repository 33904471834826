/* eslint-disable max-len */
/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import BodyText from 'components/atoms/BodyText';
import FormPadding from 'components/atoms/Form/FormPadding';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { useHistory } from "react-router-dom";
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { PrimaryButton } from "components/atoms/Button";
import useToastNotification from 'hooks/useToastNotification';
import Modal from "components/molecules/Modal";
import { importCSV, Error } from "api/masterRole";
import ImportCSVForm from 'components/molecules/ImportCSVForm';




const MasterRoleImportForm = () => {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState<File>();
    const [errors, setErrors] = useState<Array<string>>();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const { successNotification, errorNotification } = useToastNotification();

    const onSubmit = useCallback(() => {
        setIsLoading(true);
        if (!uploadFile) {
          return;
        }
        setConfirmModalOpen(false);
        setErrors(undefined);
    
        importCSV(uploadFile)
          .then((response: any) => {
            setUploadFile(undefined);
            setIsLoading(false);
            if (response.status !== 200) {
              setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
            } else {
              successNotification('インポートが完了しました');
            }
          }).catch((error: any) => {
            setUploadFile(undefined);
            setIsLoading(false);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
            } else {
                errorNotification('サーバー側でエラーが発生しました。');
            }
          });
      }, [errorNotification, successNotification, uploadFile]);

    const handleSubmit = useCallback(() => {
        setConfirmModalOpen(true);
    }, []);
    
    const closeConfirmModal = useCallback(() => {
        setConfirmModalOpen(false);
    }, []);

    return (
    <BlockUI blocking={isLoading}>
        <div>
        <FormContents customStyle={{ paddingBottom: '50px' }}>
            <FormTitle title="権限インポート" bold={true} customStyle={css`margin: 16px 35px;`} />
            <FormPadding customStyle={{ padding: '0 30px' }}>
                
                <ImportCSVForm
                    uploadFile={uploadFile}
                    setUploadFile={setUploadFile}
                    errors={errors}
                />
                
                <FormSubmitArea customStyle={css({ margin: '30px 0' })}>
                    <FlexBox justifyContent="center">
                        <PrimaryButton
                            onClick={() => handleSubmit()}
                            text="インポート"
                            disabled={!uploadFile}
                        />
                    </FlexBox>
                    <FlexBox justifyContent="center">
                        <PrimaryButton
                            onClick={() => history.push('/masterRoleSetting')}
                            text="キャンセル"
                            ghost={true}
                        />
                    </FlexBox>
                </FormSubmitArea>
                <Modal
                    open={confirmModalOpen}
                    closeHandler={closeConfirmModal}
                    title="ファイルをアップロード"
                    submitText="アップロードする"
                    note="ファイルをアップロードします。よろしいですか？"
                    onSubmit={onSubmit}
                >
                    <div style={{ height: "40px" }}></div>
                </Modal>
            </FormPadding>
        </FormContents>
        </div>
    </BlockUI>
    );
};

export default MasterRoleImportForm;
