import { TablItem } from "components/atoms/TabBar";
import React, { useState, createContext, Dispatch, SetStateAction, useContext } from "react";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { DetailRole } from "api/masterRole";

type contextType = {
  selectType: SelectType;
  setSelectType: Dispatch<SetStateAction<SelectType>>;
  tabItems: TablItem[];
  setTabItems: Dispatch<SetStateAction<TablItem[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  detailRole: DetailRole;
};

export const LoadingContextAdd = createContext<contextType>({} as contextType);

export const useStaffAddPageContext = (): contextType => {
  const context = useContext(LoadingContextAdd);
  return context;
};

export type SelectType = "staff" | "affiliation" | "employment" | "laborCost" | "workPattern" | "longTermCareandNurse";
export const SelectType: { [key in SelectType]: SelectType } = {
  staff: "staff",
  affiliation: "affiliation",
  employment: "employment",
  laborCost: "laborCost",
  workPattern: "workPattern",
  longTermCareandNurse: "longTermCareandNurse",
};

export const useInitialStaffAddPageState = () => {
  const [selectType, setSelectType] = useState<SelectType>(SelectType.staff);
  const [tabItems, setTabItems] = useState<Array<TablItem>>([{ value: SelectType.staff, display: "スタッフ情報" }]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.staffs);

  return {
    selectType,
    setSelectType,
    tabItems,
    setTabItems,
    isEditMode,
    setIsEditMode,
    isLoading,
    setIsLoading,
    detailRole,
  };
};
