/** @jsx jsx */
import React from 'react';
import moment from 'moment';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';

const NursingCareHolidayDataTable: React.FC<{
  nursingCareHolidayData: any
}> = ({
  nursingCareHolidayData,
}) => (
      <div className="table-responsive">
        <div
          css={css`
  table thead tr th{
    height: 46px;
  }
  table tbody tr td{
    height: 32px;
  }
  `}
        >
          <table id="specialHolidayGrantList" className="table table-bordered table-condensed table-no-border-left-right" cellSpacing="0" style={{ whiteSpace: 'nowrap', width: '100%' }}>
            <thead>
              <tr role="row">
                <th style={{ width: '9%' }}>休暇コード</th>
                <th style={{ width: '9%' }}>休暇名</th>
                <th style={{ width: '9%' }}>取得可能日数</th>
                <th style={{ width: '9%' }}>取得日数</th>
                <th style={{ width: '9%' }}>残日数</th>
                <th style={{ width: '9%' }}>1年とする期間(開始)</th>
                <th style={{ width: '9%' }}>1年とする期間(終了)</th>
                <th style={{ width: '9%' }}>備考</th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td>{nursingCareHolidayData.holidayCode || ''}</td>
                <td>{nursingCareHolidayData.holidayName || ''}</td>
                <td>{nursingCareHolidayData.yearUseDays || 0}日</td>
                <td>{nursingCareHolidayData.digestedDaysNum || 0}日{nursingCareHolidayData.digestedTimeHour || 0}時間</td>
                <td>{nursingCareHolidayData.remainDaysNum || 0}日{nursingCareHolidayData.remainTimesNum || 0}時間</td>
                <td>{nursingCareHolidayData.targetStartDate == null ? '' : moment(nursingCareHolidayData.targetStartDate).format('YYYY年MM月DD日')}</td>
                <td>{nursingCareHolidayData.targetEndDate == null ? '' : moment(nursingCareHolidayData.targetEndDate).format('YYYY年MM月DD日')}</td>
                <td>
                  {
                    nursingCareHolidayData.notes?.map((element: any) => {
                      let targetDate = moment(moment(element.targetDate).toDate()).format('YYYY年MM月DD日') || '';
                      return (
                        <FlexBox justifyContent="center">
                          {`${targetDate}：取得可能日数を${element.yearUseDays}日に変更`}
                        </FlexBox>
                      )
                    })
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
export default NursingCareHolidayDataTable;
