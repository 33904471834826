/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsx jsx */
import React, { Dispatch, ReducerAction } from "react";
import { jsx, css } from "@emotion/core";
import IconLabelButton from "components/molecules/IconLabelButton";
import Separater from "components/atoms/Separator";
import { ShiftDetail } from "api/shift";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BodyText from "components/atoms/BodyText";
import moment from "moment";
import Checkbox from "components/molecules/Checkbox";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";

const ShiftPatternDetailList: React.FC<{
  shiftDetail: ShiftDetail[];
  isDisable: boolean;
  dispatch: Dispatch<ReducerAction<any>>;
  remote: boolean;
}> = ({ shiftDetail = [], isDisable, dispatch, remote }) => {
  const hourOptions = Array(24)
    .fill(1)
    .map((item, index) => ({
      label: index.toLocaleString("en-US", { minimumIntegerDigits: 2 }),
      value: index.toLocaleString("en-US", { minimumIntegerDigits: 2 }),
    }));

  const minuteOptions = Array(60)
    .fill(1)
    .map((item, index) => ({
      label: index.toLocaleString("en-US", { minimumIntegerDigits: 2 }),
      value: index.toLocaleString("en-US", { minimumIntegerDigits: 2 }),
    }));

  return (
    <section className="shiftPattern">
      <div>
        {shiftDetail.map((detail, key) => (
          <div key={key} className="shiftPattern__row">
            <FlexBox>
              <FlexBoxItem basis="auto" grow={3}>
                <FlexBox customStyle={css({ marginBottom: "10px" })}>
                  <VerticalLabelFormLayout
                    label="開始時間"
                    input={
                      <FlexBox>
                        <FlexBoxItem width="65px">
                          <VerticalLabelSelectForm
                            name="startHour"
                            value={moment(detail.startTime).format("HH")}
                            options={hourOptions}
                            disable={isDisable || key !== 0}
                            placeholder={"00"}
                            setValue={(value) => {
                              dispatch({
                                type: "START_HOUR_ONCHANGE",
                                payload: {
                                  index: key,
                                  startTime: moment(detail.startTime)
                                    .hour(Number(value))
                                    .format("YYYY/MM/DD HH:mm:ss"),
                                },
                              });
                            }}
                          />
                        </FlexBoxItem>
                        <FlexBoxItem width="1rem" textAlign="center">
                          <BodyText>:</BodyText>
                        </FlexBoxItem>
                        <FlexBoxItem width="65px">
                          <VerticalLabelSelectForm
                            name="startMinute"
                            value={moment(detail.startTime).format("mm")}
                            options={minuteOptions}
                            disable={isDisable || key !== 0}
                            placeholder={"00"}
                            setValue={(value) => {
                              dispatch({
                                type: "START_HOUR_ONCHANGE",
                                payload: {
                                  index: key,
                                  startTime: moment(detail.startTime)
                                    .minute(Number(value))
                                    .format("YYYY/MM/DD HH:mm:ss"),
                                },
                              });
                            }}
                          />
                        </FlexBoxItem>
                        <FlexBoxItem marginLeft="0.5rem">
                          <Checkbox
                            id={`openCheckbox${key}`}
                            name="isStartTimeNextDay"
                            label="翌日"
                            value="1"
                            checked={detail.isStartTimeNextDay}
                            disabled={isDisable || key !== 0}
                            readOnly={isDisable || key !== 0}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              dispatch({
                                type: "START_NEXTDAY_ONCHANGE",
                                payload: {
                                  index: key,
                                  isStartTimeNextDay: e.target.checked,
                                },
                              });
                            }}
                          />
                        </FlexBoxItem>
                      </FlexBox>
                    }
                  />

                  <VerticalLabelFormLayout
                    label="終了時間"
                    input={
                      <FlexBox>
                        <FlexBoxItem width="65px">
                          <VerticalLabelSelectForm
                            name="endHour"
                            value={moment(detail.endTime).format("HH")}
                            options={hourOptions}
                            disable={isDisable}
                            placeholder={"00"}
                            setValue={(value) => {
                              dispatch({
                                type: "END_HOUR_ONCHANGE",
                                payload: {
                                  index: key,
                                  endTime: moment(detail.endTime)
                                    .hour(Number(value))
                                    .format("YYYY/MM/DD HH:mm:ss"),
                                },
                              });
                            }}
                          />
                        </FlexBoxItem>
                        <FlexBoxItem width="1rem" textAlign="center">
                          <BodyText>:</BodyText>
                        </FlexBoxItem>
                        <FlexBoxItem width="65px">
                          <VerticalLabelSelectForm
                            name="endMinute"
                            value={moment(detail.endTime).format("mm")}
                            options={minuteOptions}
                            disable={isDisable}
                            placeholder={"00"}
                            setValue={(value) => {
                              dispatch({
                                type: "END_HOUR_ONCHANGE",
                                payload: {
                                  index: key,
                                  endTime: moment(detail.endTime)
                                    .minute(Number(value))
                                    .format("YYYY/MM/DD HH:mm:ss"),
                                },
                              });
                            }}
                          />
                        </FlexBoxItem>
                        <FlexBoxItem marginLeft="0.5rem">
                          <Checkbox
                            id={`closeCheckbox${key}`}
                            name="isEndTimeNextDay"
                            label="翌日"
                            value="1"
                            checked={detail.isEndTimeNextDay}
                            disabled={isDisable}
                            readOnly={isDisable}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              dispatch({
                                type: "END_NEXTDAY_ONCHANGE",
                                payload: {
                                  index: key,
                                  isEndTimeNextDay: e.target.checked,
                                },
                              });
                            }}
                          />
                        </FlexBoxItem>
                      </FlexBox>
                    }
                  />
                </FlexBox>
              </FlexBoxItem>

              <FlexBoxItem basis="auto" grow={1} textAlign="center" customStyle={css({ marginTop: "15px" })}>
                <Checkbox
                  id={`break_${key}`}
                  label="休憩"
                  name="breakTime"
                  value="3"
                  checked={detail.businessId ? true : false}
                  disabled={isDisable}
                  readOnly={isDisable}
                  onChange={(event) => {
                    dispatch({
                      type: "IS_BREAK_ONCHANGE",
                      payload: {
                        index: key,
                        businessId: event.target.checked ? sessionStorage.getItem("loginUser.breakId") : "",
                      },
                    });
                  }}
                />
              </FlexBoxItem>
              {remote && (
                <FlexBoxItem
                  basis="auto"
                  grow={1}
                  textAlign="center"
                  customStyle={css({ marginTop: "15px", display: "none" })}
                >
                  <Checkbox
                    id={`isRemote_${key}`}
                    label="オフィス勤務"
                    name="isRemote"
                    value=""
                    checked={!detail.isRemote ? true : false}
                    disabled={isDisable}
                    readOnly={isDisable}
                    onChange={(event) => {
                      dispatch({
                        type: "IS_REMOTE_ONCHANGE",
                        payload: {
                          index: key,
                          isRemote: !event.target.checked ? true : false,
                        },
                      });
                    }}
                  />
                </FlexBoxItem>
              )}
              <FlexBoxItem basis="auto" grow={1} textAlign="center">
                <ClickableIconButton
                  disabled={
                    key === 0 || key < shiftDetail.length - 1 || (detail.isStartTimeNextDay && detail.isEndTimeNextDay)
                  }
                  additionalCss={css({ marginTop: "15px" })}
                  onClick={() => {
                    dispatch({
                      type: "DELETE_SHIFTDETAILLIST",
                      payload: key,
                    });
                  }}
                >
                  <Icon
                    type="closeCircle"
                    color=""
                    disabled={
                      key === 0 ||
                      key < shiftDetail.length - 1 ||
                      (detail.isStartTimeNextDay && detail.isEndTimeNextDay)
                    }
                  />
                </ClickableIconButton>
              </FlexBoxItem>
            </FlexBox>
          </div>
        ))}
      </div>

      <Separater margin="1.2rem 0 0.5rem 0" />
      <IconLabelButton
        onClick={() => {
          dispatch({
            type: "ADD_SHIFTDETAILLIST",
          });
        }}
        iconType="addCircle"
        text="その他のシフト時間を追加する"
        disabled={isDisable}
        width="auto"
      />
    </section>
  );
};

export default ShiftPatternDetailList;
