import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getAcquisitionStatusNursingCareList,
  getNursingCareDigestionItemsDetail,
  downloadNursingCareHistoryCsv,
} from 'api/holidayManagement';
import AcquisitionStatusNursingCareDomain from 'domain/master/holidayManagement/nursingHolidayDigestion';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export interface DetailItem {
  digestedDate: any,
  digestedNum: any,
}

export const useAcquisitionStatusForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [acquisitionStatusFormList, setAcquisitionStatusFormList] = useState<Array<any>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementNursingCare.acquisitionStatus.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const dateFrom = moment(moment().subtract(1,'year').add(1, 'days').toDate()).format("YYYY/MM/DD");
  const dateTo = moment(new Date()).format("YYYY/MM/DD");
  const [targetDateFrom, setTargetDateFrom] = useSaveStateStorage(dateFrom, "holidayManagementNursingCare.acquisitionStatus.targetDateFrom") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [targetDateTo, setTargetDateTo] = useSaveStateStorage(dateTo, "holidayManagementNursingCare.acquisitionStatus.targetDateTo") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [holidayType, setHolidayType] = useSaveStateStorage('7', "holidayManagementNursingCare.acquisitionStatus.holidayType") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const holidayOptions = [
    {
      value : '7',
      label: '介護休暇'
    },
    {
      value : '8',
      label: '子の看護休暇'
    }
  ];

  const [detailItems, setDetailItems] = useState<DetailItem[]>([]);

  const contextObject = useContext(HolidayManagementContext);
  const { errorNotification } = useToastNotification();
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementNursingCare.acquisitionStatus.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getAcquisitionStatusNursingCareList(
        orgCode !== 'all' ? orgCode : '',
        targetDateFrom || dateFrom,
        targetDateTo || dateTo,
        Number(holidayType) || 7,
        underFlag || 0
      );
      contextObject.setIsLoading(false);
      setAcquisitionStatusFormList(response.map((result) => new AcquisitionStatusNursingCareDomain(result)));
    }
  }, [orgCode, targetDateFrom, targetDateTo, holidayType, underFlag]);

  //
  const callGetAcquisitionStatusDetail = useCallback(async (staffCode: string) => {
    try {
      await getNursingCareDigestionItemsDetail(
        staffCode,
        Number(holidayType),
        targetDateFrom || dateFrom,
        targetDateTo || dateTo,
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, targetDateFrom, targetDateTo, holidayType]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, targetDateFrom, targetDateTo, holidayType, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    acquisitionStatusFormList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    dateFrom,
    dateTo,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    modalOpen,
    setModalOpen,
    closeModal,
    holidayType,
    setHolidayType,
    holidayOptions,
    detailItems,
    callGetAcquisitionStatusDetail,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadNursingCareHistory = (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);

  const downloadCsv = useCallback((holidayType: string) => {
    setBlocking(true);
    downloadNursingCareHistoryCsv(
      Number(holidayType),
      (orgCode !== 'all' && orgCode !== null) ? orgCode : '',
      targetDateFrom,
      targetDateTo,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  return {
    downloadCsv,
    blocking
  };
};

export default {
  useAcquisitionStatusForm,
};
