import ApiClient from './ApiClient';

export type attendHelpList = {
    targetDateFrom: any;
    targetDateTo: any;
    supportSourceOrg: any;
    supportDestinationOrg:any;
    closeDateFlg:any;
    targetDate:any;
}

export const attendHelpList = async (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDateFrom,
    targetDateTo,
    supportSourceOrg,
    supportDestinationOrg,
  };
  const response = await ApiClient.get(`/v1/support/list/${companyCode}`, params);
  return response.data;
};

export const searchDate = async (
  closeDateFlg: any,
  targetDate: any, 
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
    closeDateFlg,
    targetDate, 
  };
  const response = await ApiClient.get(`/v1/employments/searchDate/${companyCode}`, params);
  return response.data;
};

export const subTotalList = async (
  targetDateFrom: any,
  targetDateTo: any,
  supportSourceOrg: any,
  supportDestinationOrg: any,
): Promise<Array<attendHelpList>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    targetDateFrom,
    targetDateTo,
    supportSourceOrg,
    supportDestinationOrg,
  };
  const response = await ApiClient.get(`/v1/support/subtotal/${companyCode}`, params);
  return response.data;
};

export default attendHelpList;
