/** @jsx jsx */
import React, { useEffect } from "react";
import { jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormLabel from "components/atoms/Form/FormLabel";
import TabControl from "components/atoms/TabControl";
import FormContents from "components/atoms/Form/FormContents";
import moment from "moment";
import BlockUI from "components/molecules/BlockUi";
import useToastNotification from "hooks/useToastNotification";
import ApplyingTab from "./ApplyingTab";
import ApprovedTab from "./ApprovedTab";
import RejectedTab from "./RejectedTab";
import CalendarForm from "components/molecules/CalendarForm";
import useAttendApplicationApproval from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import FormTitle from "components/atoms/Form/FormTitle";
import { css } from "@emotion/core";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";

type SelectType = "applying" | "approved" | "rejected";

const SelectType: { [key in SelectType]: SelectType } = {
  applying: "applying",
  approved: "approved",
  rejected: "rejected",
};

const AttendApplicationApproval: React.FC = () => {
  const [selectType, setSelectType] = React.useState<SelectType>(
    SelectType.applying
  );

  const {
    isLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    initialDateFrom,
    setInitialDateFrom,
    initialDateTo,
    setInitialDateTo,

    applyingReasonId,
    setApplyingReasonId,
    approvedReasonId,
    setApprovedReasonId,
    rejectedReasonId,
    setRejectedReasonId,
    reasonOptionList,
    setReasonOptionList,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,

    fetchData,
    fetchApplyingReason,
    fetchApprovedReason,
    fetchRejectedReason,

    fetchApprovedStampList,
    fetchApprovedHolidayList,
    fetchApprovedOvertimeList,
    fetchApprovedAgreement36List,
    fetchApprovedHolidayWorkList,
    fetchApprovedTransferList,
    fetchApprovedWorkScheduleChangeList,

    fetchRejectedStampList,
    fetchRejectedHolidayList,
    fetchRejectedOvertimeList,
    fetchRejectedHolidayWorkList,
    fetchRejectedTransferList,
    fetchRejectedAgreement36List,
    fetchRejectedWorkScheduleChangeList,

    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,
  } = useAttendApplicationApproval();
  const { errorNotification } = useToastNotification();

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }
    fetchApplyingReason(applyingReasonId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyingReasonId]);

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }
    fetchApprovedReason(approvedReasonId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedReasonId]);

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }
    fetchRejectedReason(rejectedReasonId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedReasonId]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle
          title="勤怠承認"
          bold={true}
          customStyle={css`
            margin: 16px 45px;
          `}
        />
        <FormPadding>
          <FlexBox>
            <FlexBoxItem marginRight="15px">
              <FormLabel label="期間 " />
            </FlexBoxItem>
            <FlexBoxItem>
              <CalendarRangeV2
                width="auto"
                startDate={targetDateFrom}
                onChangeStart={(date) => {
                  if (date.getTime() !== targetDateFrom.getTime()) {
                    const selectedDate = date.getDate();
                    const selectedMonth = date.getMonth();
                    const selectedYear = date.getFullYear();
                    const nextMonthFrom = moment(initialDateFrom)
                      .year(selectedYear)
                      .month(selectedMonth)
                      .date(selectedDate)
                      .toDate();
                    setTargetDateFrom(nextMonthFrom);
                    setInitialDateFrom(nextMonthFrom);
                  }
                }}
                endDate={targetDateTo}
                onChangeEnd={(date) => {
                  if (date) {
                    if (date.getTime() !== targetDateTo.getTime()) {
                      const selectedDate = date.getDate();
                      const selectedMonth = date.getMonth();
                      const selectedYear = date.getFullYear();
                      const nextMonthTo = moment(initialDateTo)
                        .year(selectedYear)
                        .month(selectedMonth)
                        .date(selectedDate)
                        .toDate();
                      setTargetDateTo(nextMonthTo);
                      setInitialDateTo(nextMonthTo);
                    }
                  }
                }}
              />
            </FlexBoxItem>
            {/* <FlexBoxItem>
							<CalendarForm
								required={true}
								label=""
								date={targetDateFrom}
								dateFormat="YYYY年MM月DD日"
								changeDate={(date: Date) => {
									const selectedDate = date.getDate();
									const selectedMonth = date.getMonth();
									const selectedYear = date.getFullYear();
									const nextMonthFrom = moment(initialDateFrom)
										.year(selectedYear)
										.month(selectedMonth)
										.date(selectedDate)
										.toDate();
									setTargetDateFrom(nextMonthFrom);
									setInitialDateFrom(nextMonthFrom);
								}}
							/>
						</FlexBoxItem>
						<FlexBoxItem marginRight="15px" marginLeft="15px">
							<FormLabel label=" - " />
						</FlexBoxItem>
						<FlexBoxItem>
							<CalendarForm
								required={true}
								label=""
								date={targetDateTo}
								dateFormat="YYYY年MM月DD日"
								changeDate={(date: Date) => {
									const selectedDate = date.getDate();
									const selectedMonth = date.getMonth();
									const selectedYear = date.getFullYear();
									const nextMonthTo = moment(initialDateTo)
										.year(selectedYear)
										.month(selectedMonth)
										.date(selectedDate)
										.toDate();
									setTargetDateTo(nextMonthTo);
									setInitialDateTo(nextMonthTo);
								}}
							/>
						</FlexBoxItem> */}
          </FlexBox>
        </FormPadding>

        <TabControl
          items={[
            {
              value: SelectType.applying,
              display: `未承認(${applyingStampList.length +
                applyingHolidayList.length +
                applyingOvertimeList.length +
                applyingHolidayWorkList.length +
                applyingTransferList.length +
                applyingAgreement36List.length +
                applyingWorkScheduleChangeList.length}件)`,
            },
            {
              value: SelectType.approved,
              display: `承認済み(${approvedStampList.length +
                approvedHolidayList.length +
                approvedOvertimeList.length +
                approvedHolidayWorkList.length +
                approvedAgreement36List.length +
                approvedTransferList.length +
                approvedWorkScheduleChangeList.length}件)`,
            },
            {
              value: SelectType.rejected,
              display: `差戻(${rejectedStampList.length +
                rejectedHolidayList.length +
                rejectedOvertimeList.length +
                rejectedHolidayWorkList.length +
                rejectedAgreement36List.length +
                rejectedTransferList.length +
                rejectedWorkScheduleChangeList.length}件)`,
            },
          ]}
          setValue={setSelectType as (arg: string) => void}
          currentValue={selectType}
          customStyle={css({
            margin: "0px",
            padding: "8px 16px",
            boxSizing: "border-box",
            width: "100%",
            overflow: "auto",
          })}
        />

        <div>
          {/* 要承認 */}
          {selectType === SelectType.applying && (
            <ApplyingTab
              stampList={applyingStampList}
              holidayList={applyingHolidayList}
              overtimeList={applyingOvertimeList}
              holidayWorkList={applyingHolidayWorkList}
              transferList={applyingTransferList}
              agreement36List={applyingAgreement36List}
              workScheduleChangeList={applyingWorkScheduleChangeList}
              reasonOptionList={reasonOptionList}
              reasonId={applyingReasonId}
              setReasonId={setApplyingReasonId}
              // fetch approved API
              fetchApprovedStampList={fetchApprovedStampList}
              fetchApprovedHolidayList={fetchApprovedHolidayList}
              fetchApprovedOvertimeList={fetchApprovedOvertimeList}
              fetchApprovedHolidayWorkList={fetchApprovedHolidayWorkList}
              fetchApprovedTransferList={fetchApprovedTransferList}
              fetchApprovedAgreement36List={fetchApprovedAgreement36List}
              fetchApprovedWorkScheduleChangeList={
                fetchApprovedWorkScheduleChangeList
              }
              // fecth rejected API
              fetchRejectedStampList={fetchRejectedStampList}
              fetchRejectedHolidayList={fetchRejectedHolidayList}
              fetchRejectedOvertimeList={fetchRejectedOvertimeList}
              fetchRejectedHolidayWorkList={fetchRejectedHolidayWorkList}
              fetchRejectedTransferList={fetchRejectedTransferList}
              fetchRejectedAgreement36List={fetchRejectedAgreement36List}
              fetchRejectedWorkScheduleChangeList={
                fetchRejectedWorkScheduleChangeList
              }
              // set list
              setApplyingTransferList={setApplyingTransferList}
              setApplyingStampList={setApplyingStampList}
              setApplyingHolidayList={setApplyingHolidayList}
              setApplyingOvertimeList={setApplyingOvertimeList}
              setApplyingHolidayWorkList={setApplyingHolidayWorkList}
              setApplyingAgreement36List={setApplyingAgreement36List}
              setApplyingWorkScheduleChangeList={
                setApplyingWorkScheduleChangeList
              }
            />
          )}

          {/* 承認済 */}
          {selectType === SelectType.approved && (
            <ApprovedTab
              stampList={approvedStampList}
              holidayList={approvedHolidayList}
              overtimeList={approvedOvertimeList}
              holidayWorkList={approvedHolidayWorkList}
              transferList={approvedTransferList}
              agreement36List={approvedAgreement36List}
              workScheduleChangeList={approvedWorkScheduleChangeList}
              reasonOptionList={reasonOptionList}
              reasonId={approvedReasonId}
              setReasonId={setApprovedReasonId}
            />
          )}

          {/* 差戻済 */}
          {selectType === SelectType.rejected && (
            <RejectedTab
              stampList={rejectedStampList}
              holidayList={rejectedHolidayList}
              overtimeList={rejectedOvertimeList}
              holidayWorkList={rejectedHolidayWorkList}
              transferList={rejectedTransferList}
              agreement36List={rejectedAgreement36List}
              workScheduleChangeList={rejectedWorkScheduleChangeList}
              reasonOptionList={reasonOptionList}
              reasonId={rejectedReasonId}
              setReasonId={setRejectedReasonId}
            />
          )}
        </div>
      </FormContents>
    </BlockUI>
  );
};

export default AttendApplicationApproval;
