/* eslint-disable array-callback-return */
/** @jsx jsx */

import React, { useEffect } from "react";
import YearPicker from "components/molecules/YearPicker/YearPicker";

import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button from "components/atoms/Button";
import PrimaryButton from "components/atoms/Button";
import HourInput from "components/atoms/Form/HourInput";
import productColor, { grayScale, textFontSize } from "components/styles";
import { css, jsx } from "@emotion/core";
import { getWorkingHoursPreviousYear } from "api/workingHours";
import { useHistory } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import { useWorkingHoursDomainAddForm } from "./hooks";
import BodyText from "components/atoms/BodyText";
import Modal from "components/molecules/Modal";
import FormTitle from "components/atoms/Form/FormTitle";
import BlockUI from "components/molecules/BlockUi";

// create style form
const styles = {
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  textInput: css({
    width: "440px",
    margin: "0 auto 10px auto",
    flexWrap: "wrap",
  }),
  input: css({
    flex: 1,
    width: "100px",
    padding: "7px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: "none",
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px 0 0 4px",
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
  }),
  cellTable: css([
    css({
      width: "100%",
      border: "none",
      borderSpacing: "0px 1.5px",
      overflow: "scroll",
      fontSize: "14px",
    }),
    css`
      tbody td,
      thead th {
        border-bottom: 1px solid #dcdcdc;
        padding: 10px;
      }

      tbody td:first-of-type,
      thead th:first-of-type {
        text-align: center;
        border-right: 1px solid #dcdcdc;
      }
      tbody td:not(:first-of-type),
      thead th:not(:first-of-type) {
        padding-left: 60px;
      }
      thead th {
        border-top: 1px solid #dcdcdc;
        font-weight: bold;
      }
    `,
  ]),
};

const WorkingHoursInputAddForm: React.FC<{ workingHoursId: string, typeButton: string }> = ({
  workingHoursId,
  typeButton
}) => {
  const {
    formik,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    arrMonth,
    valueWorkingHours,
    setValueWorkingHours,

    valueLegalWorkingHours,
    setValueLegalWorkingHours,

    valueWorkingMinuteForms,
    setValueWorkingMinuteForms,

    valueLegalWorkingMinuteForms,
    setValueLegalWorkingMinuteForms,

    handleGetWorkingHours,
    blocking,
    detailRole
  } = useWorkingHoursDomainAddForm(workingHoursId, typeButton);

  const { errorNotification } = useToastNotification();

  // history
  const history = useHistory();

  //
  let workingHoursDetails = [
    {
      month: 0,
      workingHoursForm: 0,
      workingMinuteForm: 0,
    },
  ];

  workingHoursDetails = [];

  arrMonth.map((month) => {
    const tmpMonthDetail = {
      month,
      workingHoursForm: 0,
      workingMinuteForm: 0,
    };
    workingHoursDetails.push(tmpMonthDetail);
    //
    return true;
  });

  //
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  handle on change input working hours && working minute on form
   */
  const handleChange = async (e: any) => {
    const checkName = e.target.name.split("_");
    if (checkName[0] === "workingHoursForm") {
      const items = [...valueWorkingHours];
      (items as any)[checkName[1]] = e.target.value;
      setValueWorkingHours(items);
    } 

    else if(checkName[0] === "workingMinuteForm"){
      const items = [...valueWorkingMinuteForms];
      (items as any)[checkName[1]] = e.target.value;
      setValueWorkingMinuteForms(items);
    }  

    else if(checkName[0] === "legalWorkingHoursFrom"){
      const items = [...valueLegalWorkingHours];
      (items as any)[checkName[1]] = e.target.value;
      setValueLegalWorkingHours(items);
    }  

    else if(checkName[0] === "legalWorkingMinuteFrom"){
      const items = [...valueLegalWorkingMinuteForms];
      (items as any)[checkName[1]] = e.target.value;
      setValueLegalWorkingMinuteForms(items);
    }
  };

  /**
   *  handle back
   */
  const handleBack = async () => {
    history.replace("/masterWorkingHours");
  };

  /**
   *
   *
   */
  const handleChangeYear = async (year: any) => {
    // set year
    formik.setFieldValue("targetYear", year);

    // update working detail
    await handleGetWorkingHours(year, workingHoursId);
  };

  /**
   *  handle on click button 対象年の前年の所定労働時間を設定
   */
  const handleSetWorkingHours = async (e: any) => {
    //
    const rawData = formik.values.getRawData();
    const { targetYear, workingHoursId } = rawData;
    const workingHoursDetailId =
      (rawData as any).workingHoursDetails &&
      (rawData as any).workingHoursDetails.workingHoursDetailId
        ? (rawData as any).workingHoursDetails.workingHoursDetailId
        : "";
    getWorkingHoursPreviousYear(
      targetYear,
      workingHoursId,
      workingHoursDetailId
    )
      .then((response: any) => {
        if (response) {
          const { workingHoursDetails } = response;
          if (workingHoursDetails) {
            const itemsHour = [...valueWorkingHours];
            const itemsMinute = [...valueWorkingMinuteForms];
            const itemsHourLegal = [...valueLegalWorkingHours];
            const itemsMinuteLegal = [...valueLegalWorkingMinuteForms];
            Object.keys(workingHoursDetails).forEach((key_obj) => {
              const value = workingHoursDetails[key_obj];
              if (key_obj.includes("workingHoursForm")) {
                const split_key_obj = key_obj.split("workingHoursForm");
                (itemsHour as any)[parseInt(split_key_obj[1]) - 1] = value;
              } else if (key_obj.includes("workingMinuteForm")) {
                const split_key_obj = key_obj.split("workingMinuteForm");
                (itemsMinute as any)[parseInt(split_key_obj[1]) - 1] = value;
              } else if (key_obj.includes("legalWorkingHoursFrom")) {
                const split_key_obj = key_obj.split("legalWorkingHoursFrom");
                (itemsHourLegal as any)[parseInt(split_key_obj[1]) - 1] = value;
              } else if (key_obj.includes("legalWorkingMinuteFrom")) {
                const split_key_obj = key_obj.split("legalWorkingMinuteFrom");
                (itemsMinuteLegal as any)[parseInt(split_key_obj[1]) - 1] = value;
              }
            });

            setValueWorkingHours(itemsHour);
            setValueWorkingMinuteForms(itemsMinute);

            setValueLegalWorkingHours(itemsHourLegal);
            setValueLegalWorkingMinuteForms(itemsMinuteLegal);
          }
        }
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = "";
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
          throw error;
        }
      });
  };

  return (
    <BlockUI blocking={blocking}>
    <FormTitle bold={true} title={`${workingHoursId ? "所定労働時間編集" : "所定労働時間作成"}`} />
    <div style={{ marginBottom: "65px" }}>
      <form onSubmit={formik.handleSubmit} style={{ marginTop: "40px" }}>
        <div>
          <FormField customStyle={styles.textInput}>
            <TextForm
              name="workingHoursPatternCode"
              label="パターンコード"
              value={String(formik.values.workingHoursPatternCode)}
              onChange={formik.handleChange}
              showRequiredLabel={false}
              errorMsg={formik.errors.workingHoursPatternCode}
              required={true}
            />
          </FormField>

          <FormField customStyle={styles.textInput}>
            <TextForm
              name="workingHoursPatternName"
              label="パターン名"
              value={String(formik.values.workingHoursPatternName)}
              onChange={formik.handleChange}
              showRequiredLabel={false}
              errorMsg={formik.errors.workingHoursPatternName}
              required={true}
              customStyle={styles.textInput}
            />
          </FormField>

          <FormField customStyle={styles.textInput}>
            <BodyText
              customStyle={css({ width: "100%", marginBottom: "15px" })}
            >
              対象年
            </BodyText>
            <div
              style={{
                paddingRight: "10px",
                width: "100px",
                marginTop: "-5px",
              }}
            >
              <YearPicker
                selectedYear={formik.values.targetYear}
                onChange={(year: Date) => handleChangeYear(year)}
              />
            </div>
          </FormField>
          {(typeButton === 'edit' && detailRole.editable === 1) ? (
            <FormField customStyle={styles.textInput}>
              <div
                onClick={handleSetWorkingHours}
                style={{
                  color: productColor.primary,
                  fontSize: textFontSize.sm,
                  cursor: "grab",
                }}
              >
                前年の内容を取り込む
              </div>
            </FormField>
          ) : (
            ""
          )}

          <div>
            <table className="table table-bordered" css={styles.cellTable} style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>月</th>
                  <th style={{ textAlign: "left", width: "35%" }}>
                    所定労働時間
                  </th>
                  <th style={{ textAlign: "left", width: "35%" }}>
                    法定労働時間
                  </th>
                </tr>
              </thead>
              <tbody>
                {workingHoursDetails.map((item, k_detail) => (
                  <tr style={{ backgroundColor: "#ffffff" }}>
                    <td>{item.month}月</td>
                    <td>
                      <div style={{ width: "310px" }}>
                        <div style={{ float: "left", width: "140px" }}>
                          <HourInput
                            name={`workingHoursForm_${k_detail}`}
                            value={
                              valueWorkingHours[k_detail]
                                ? Number(valueWorkingHours[k_detail])
                                : 0
                            }
                            label="時間"
                            onChange={handleChange}
                            isFullW={true}
                            min={0}
                            max={999}
                            widthInput="50px"
                            flexNone={true}
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "10px",
                            float: "left",
                            width: "120px",
                          }}
                        >
                          <HourInput
                            flexNone={true}
                            name={`workingMinuteForm_${k_detail}`}
                            value={
                              valueWorkingMinuteForms[k_detail]
                                ? Number(valueWorkingMinuteForms[k_detail])
                                : 0
                            }
                            label="分"
                            onChange={handleChange}
                            min={0}
                            max={59}
                            widthInput="50px"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ width: "310px" }}>
                        <div style={{ float: "left", width: "140px" }}>
                          <HourInput
                            name={`legalWorkingHoursFrom_${k_detail}`}
                            value={
                              valueLegalWorkingHours[k_detail]
                                ? Number(valueLegalWorkingHours[k_detail])
                                : 0
                            }
                            label="時間"
                            onChange={handleChange}
                            isFullW={true}
                            min={0}
                            max={999}
                            widthInput="50px"
                            flexNone={true}
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "10px",
                            float: "left",
                            width: "120px",
                          }}
                        >
                          <HourInput
                            flexNone={true}
                            name={`legalWorkingMinuteFrom_${k_detail}`}
                            value={
                              valueLegalWorkingMinuteForms[k_detail]
                                ? Number(valueLegalWorkingMinuteForms[k_detail])
                                : 0
                            }
                            label="分"
                            onChange={handleChange}
                            min={0}
                            max={59}
                            widthInput="50px"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <FormSubmitArea
            customStyle={css({ textAlign: "center", marginTop: "60px" })}
          >
            {detailRole.editable === 1 && (
              <div>
                <Button text={`${typeButton === 'edit' ? '更新' : '登録する'}`} onClick={formik.handleSubmit} />
              </div>
            )}
            <PrimaryButton
              text="キャンセル"
              onClick={() => handleBack()}
              ghost={true}
            />
          </FormSubmitArea>
        </div>

        <Modal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title={typeButton === 'edit' ? "所定労働時間を更新" : "所定労働時間を登録"}
          note={
            typeButton === 'edit'
              ? "所定労働時間を更新します。よろしいですか？"
              : "所定労働時間を登録します。よろしいですか？"
          }
          submitText={typeButton === 'edit' ? "更新する" : "登録する"}
        />
      </form>
    </div>
    </BlockUI>
  );
};

export default WorkingHoursInputAddForm;
