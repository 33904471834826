/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Panel from "components/atoms/Panel";

const styles = {
  wrapper: css({
    zIndex: 101,
    position: "absolute",
    top: "52px",
    right: "4px",
  }),
  body: css({
    boxShadow: "0px 4px 20px rgba(102, 102, 102, 0.3)",
    borderRadius: "3px",
  }),
};

/**
 * 画面右上のパネル
 */
const AppNotificationPanel: React.FC = ({ children }) => (
  <div css={styles.wrapper}>
    <Panel width="260px" height="300px" customStyle={styles.body}>
      {children}
    </Panel>
  </div>
);

export default AppNotificationPanel;
