import { CognitoInfo } from "api/login";
import awsExports from "aws-exports"; // 設定情報を読み込みます。

export const generateCognitoInfo = (info: CognitoInfo) => {
  // APIから取得情報を基本的に返すが、値がセットされていない場合は環境ファイルから設定する。
  return {
    aws_project_region: info.cognitoResion || awsExports.aws_project_region,
    aws_cognito_identity_pool_id: info.cognitoIdentityPoolId || awsExports.aws_cognito_identity_pool_id,
    aws_cognito_region: info.cognitoResion || awsExports.aws_cognito_region,
    aws_user_pools_id: info.cognitoUserPoolId || awsExports.aws_user_pools_id,
    aws_user_pools_web_client_id: info.cognitoClientId || awsExports.aws_user_pools_web_client_id,
    aws_cognito_allow_admin_create_user: awsExports.aws_cognito_allow_admin_create_user,
    oauth: {},
  };
};
