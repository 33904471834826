/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import Icon from 'components/atoms/Icon';

interface PhotoFrameProps {
  src: string,
  size?: string,
  isCircle?: boolean;
  onErrorIconColor?: string;
}

const createStyle = (size: string, isCircle: boolean) => css({
  width: size,
  height: size,
  borderRadius: isCircle ? '50%' : '0%',
  display: 'block'
});

const PhotoFrame: React.FC<PhotoFrameProps> = ({
  src, size = '24px', isCircle = false, onErrorIconColor = "white"
}) => {
  const [errored, setErrored] = useState(false);
  const onError = () => {
    setErrored(true);
  }

  useEffect(() => {
    setErrored(false);
  }, [src])

  return (
    errored ?
      <Icon type="avatar" size={size} color={onErrorIconColor} />
      :
      <img
        css={createStyle(size, isCircle)}
        src={src}
        onError={() => onError()}
        alt="avatar"
      />
  );
};

export default PhotoFrame;
