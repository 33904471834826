import 'babel-polyfill';
import 'element-closest';
import './index.css';
import React, { Component } from 'react';
import { css, jsx } from '@emotion/core';
import YearInput from './components/YearInput';
import PickerPanel from './components/PickerPanel/index';
import 'font-awesome/css/font-awesome.min.css';
import Icon from 'components/atoms/Icon';

const chevronLeftStyle = css({
  position: 'absolute',
  left: '-30px',
  top: '50%',
  transform: 'translateY(-60%)',
})
const chevronRightStyle = css({
  position: 'absolute',
  right: '-26px',
  top: '50%',
  transform: 'translateY(-60%)',
})
class YearPicker extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      currentYear: props.selectedYear ? Number(props.selectedYear) : new Date().getFullYear(),
      yearIsSelected: false,
      selectedYear: props.selectedYear ? Number(props.selectedYear) : new Date().getFullYear(),
      panelIsOpen: false,
      panelTop: 0,
      panelLeft: 0,
      oldYear: props.selectedYear ? Number(props.selectedYear) : new Date().getFullYear(),
      disabled: props.disabled ? props.disabled : false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  panelPosition = () => {
    const picker = document.querySelector('.year-picker');
    let X = 0;
    let Y = 0;
    let elementHeight = 0;
    let elementWidth = 0;

    if (picker) {
      X = picker.getBoundingClientRect().left;
      Y = picker.getBoundingClientRect().bottom;

      elementHeight = picker.getBoundingClientRect().height; // Высота элемента
      elementWidth = picker.getBoundingClientRect().width; // Ширина элемента
    }


    const windowHeight = window.innerHeight; // высота окна браузера
    const windowWidth = window.innerWidth; // ширина окна браузера

    const topTrue = Y - elementHeight - 10 > 220;
    const halfTopTrue = Y - elementHeight - 10 > 110;
    const botTrue = windowHeight - Y - 10 > 220;
    const halfBotTrue = windowHeight - Y - 10 > 110;
    const leftTrue = X + elementHeight / 2 > 120;
    const rightTrue = windowWidth - X - elementWidth / 2 > 120;

    if (topTrue && !botTrue && leftTrue && rightTrue) {
      const top = -230;
      const left = -120 + elementWidth / 2;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (!topTrue && botTrue && rightTrue && leftTrue) {
      const top = elementHeight + 10;
      const left = -120 + elementWidth / 2;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (halfBotTrue && halfTopTrue && leftTrue && !rightTrue) {
      const top = -110 + elementHeight / 2;
      const left = -250;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (halfBotTrue && halfTopTrue && !leftTrue && rightTrue) {
      const top = -110 + elementHeight / 2;
      const left = elementWidth + 10;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (!topTrue && botTrue && leftTrue && !rightTrue) {
      const top = 0;
      const left = -250;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (topTrue && !rightTrue && leftTrue && !botTrue) {
      const top = -220 + elementHeight;
      const left = -250;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (!topTrue && rightTrue && !leftTrue && botTrue) {
      const top = 0;
      const left = elementWidth + 10;
      this.setState({ panelTop: top, panelLeft: left });
    } else if (topTrue && rightTrue && !leftTrue && !botTrue) {
      const top = -220 + elementHeight;
      const left = elementWidth + 10;
      this.setState({ panelTop: top, panelLeft: left });
    }
  };

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.panelPosition();

      document.addEventListener(
        'scroll',
        (event) => {
          if (this._isMounted) {
            this.panelPosition();
          }
        },
      );

      document.addEventListener(
        'click',
        (event) => {
          if (this._isMounted) {
            if (!event.target.closest('.year-picker')) {
              this.closePanel();
            }
          }
        },
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.oldYear === nextProps.selectedYear) {
      return;
    }

    this.setState({
      selectedYear: Number(nextProps.selectedYear),
      currentYear: Number(nextProps.selectedYear),
      yearIsSelected: true,
      oldYear: Number(nextProps.selectedYear),
    });
  }

  openPanel = (event) => {
    this.panelPosition();
    this.setState({ panelIsOpen: true });
  };

  closePanel = (event) => {
    this.setState({ panelIsOpen: false });
  };

  callback = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.currentYear);
    }
  }

  choiseYear = (year) => {
    this.setState({
      selectedYear: year,
      currentYear: year,
      yearIsSelected: true,
    }, () => this.callback());
    this.closePanel();
  };

  clearYear = () => {
    this.setState({
      selectedYear: new Date().getFullYear(),
      currentYear: '',
      yearIsSelected: false,
    });
  };

  increaseYear = (event) => {
    this.setState({ selectedYear: this.state.selectedYear + 1 });
  };

  decreaseYear = (event) => {
    this.setState({ selectedYear: this.state.selectedYear - 1 });
  };

  jumpForward = (event) => {
    this.setState({ selectedYear: this.state.selectedYear + 5 });
  };

  jumpBackward = (event) => {
    this.setState({ selectedYear: this.state.selectedYear - 5 });
  };

  thisYear = (event) => {
    const year = new Date().getFullYear();
    this.setState({
      currentYear: year,
      selectedYear: year,
      yearIsSelected: true,
    });
    this.closePanel();
  };

  render() {
    return (
      <div style={{position: 'relative', width: 'fit-content'}}>
        {this.props.openActionChevron && (
          <Icon type="chevronLeft" color="" size="24px" verticalAlign="middle" customStyle={chevronLeftStyle}
            onClick={() => {
              this.choiseYear(this.state.currentYear - 1);
            }}
          />
        )}
        <div
          className={`year-picker ${this.state.disabled ? 'year-picker__disabled' : ''}`}
          style={this.props.customStyle}
        >
         
          <YearInput
            value={this.state.currentYear}
            openPanel={this.openPanel}
            selected={this.state.yearIsSelected}
            clear={this.clearYear}
            disabled={this.state.disabled}
            onChange={this.choiseYear}
          />
          <PickerPanel
            isOpen={this.state.panelIsOpen}
            selectedYear={this.state.selectedYear}
            currentYear={this.state.currentYear}
            increaseYear={this.increaseYear}
            decreaseYear={this.decreaseYear}
            jumpForward={this.jumpForward}
            jumpBackward={this.jumpBackward}
            thisYear={this.thisYear}
            choiseYear={this.choiseYear}
            top={this.state.panelTop}
            left={this.state.panelLeft}
            onClose={this.closePanel}
          />
        </div>
        {this.props.openActionChevron && (
          <Icon type="chevronRight" color="" size="24px" verticalAlign="middle" customStyle={chevronRightStyle}
            onClick={() => {
              // let nextDate = actionChevron(dateInternal, modeAction, 'next')
              // setDateInternal(nextDate);
              // setDate(nextDate);
              this.choiseYear(this.state.currentYear + 1)
            }}
          />
        )}
      </div>
    );
  }
}

export default YearPicker;
