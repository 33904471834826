/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import productColor, { textFontSize, textColor, utilityColor, grayScale } from 'components/styles';
import BodyText from '../BodyText';

const styles = {
  label: css({
    fontSize: textFontSize.re,
    color: grayScale.gray01,
    marginBottom: '10px',
    display: 'inline-block'
  }),
  subText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01, 
    padding:' 3px 10px',
    marginLeft: '10px',
    display: 'inline-block',
    marginBottom: '2px'
  }),
};

const FormLabel: React.FC<{
  label: string;
  subText?: string;
  required?: boolean;
  appendLabel?: string;
  customStyle?: SerializedStyles;
  showRequiredLabel?: boolean;
}> = ({
  label, subText, required, appendLabel, customStyle = css({}), showRequiredLabel= true,
}) => (
  <React.Fragment>
    <span css={[styles.label, customStyle]}>
      {label}
      { appendLabel ? <BodyText color={utilityColor.error} size="sm">{appendLabel}</BodyText> : '' }
      { showRequiredLabel && required && <span css={css(styles.subText, { color: utilityColor.white, background: productColor.secondary, borderRadius: '3px',
    padding: '3px', marginLeft: '10px' })}>*必須</span>}
    </span>
    {subText && <span css={styles.subText}>{subText}</span>}
  </React.Fragment>
);

export default FormLabel;
