/** @jsx jsx */

import React, { useEffect } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import { css, jsx } from "@emotion/core";
import Checkbox from "components/molecules/Checkbox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import BlockUI from "components/molecules/BlockUi";
import FormLabel from "components/atoms/Form/FormLabel";
import useAchievementErrorState from "../hook";
import ContextMenu from "components/molecules/ContextMenu";
import getContextMenuButtons from "components/molecules/ContextMenu/getContextMenuButtons";
import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-grid-system";
import BodyText from "components/atoms/BodyText";
import Radio from "components/molecules/Radio";
import FormPadding from "components/atoms/Form/FormPadding";
import DataTable from "components/organismos/DataTable/DataTable";
import FormTitle from "components/atoms/Form/FormTitle";
import IconLabelButton from "components/molecules/IconLabelButton";
import { downloadAlertCSV, downloadErrorCSVV2 } from "api/attendError";
import IconTooltip from "components/molecules/IconTooltip";
import { utilityColor } from "components/styles";
import { isTablet, isSafari } from "react-device-detect";
import SelectForm from "components/molecules/SelectForm";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import { functionCode } from "const";
import useIsMobile from "hooks/useIsMobile";

const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  table: css({
    "table th:nth-of-type(1)": {
      width: "90px",
    },
    "table th:nth-of-type(2)": {
      width: "100px",
    },
    "table th:nth-of-type(3)": {
      width: "100px",
    },
    "table th:nth-of-type(4)": {
      width: "100px",
    },
    "table th:nth-of-type(5)": {
      width: "100px",
    },
    "table th:nth-of-type(6)": {
      width: "90px",
    },
    "table th:nth-of-type(7)": {
      width: "100px",
    },
    "table th:nth-of-type(8)": {
      width: "80px",
    },
    "table th:nth-of-type(9)": {
      width: "80px",
    },
    "table td:nth-of-type(10), table th:nth-of-type(10)": {
      width: "140px",
      "text-align": "center",
    },
    "table td:nth-of-type(11), table th:nth-of-type(11)": {
      width: "95px",
      "text-align": "center",
    },
    "table th:nth-of-type(12)": {
      width: "60px",
    },
    "table th:nth-of-type(13)": {
      width: "500px",
    },
    ".svg-red path:last-child": {
      fill: "red",
    },
    ".svg-yellow path:last-child": {
      fill: "#f2c94c",
    },
  }),
  mobileTable: css({
    "table th:nth-of-type(1)": {
      width: "32px",
    },
    "table th:nth-of-type(2)": {
      width: "82px",
    },
    "table th:nth-of-type(3)": {
      width: "auto",
    },
    ".svg-red path:last-child": {
      fill: "red",
    },
    ".svg-yellow path:last-child": {
      fill: "#f2c94c",
    },
  }),
  flexBox: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  customStyle: css(
    {
      display: "flex",
      marginLeft: "0",
      justifyContent: (isSafari && isTablet) || isIpad ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIpad ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIpad ? "0px" : "-40px",
    },
    css`
      button {
        width: auto;
        margin-left: 12px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
};

const AttendanceError: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { errorNotification } = useToastNotification();
  const refixTable = "attendError";
  const workSystem = sessionStorage.getItem("stamp.workSystem");

  const {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    isFilter,
    setIsFilter,
    errorList,
    isLoading,
    setIsLoading,
    fetchData,
    isOT,
    setOT,
    isHolidayWork,
    setHolidayWork,
    isBreak,
    setBreak,
    isWorkingTogether,
    setWorkingTogether,
    isUnder18,
    setUnder18,
    isForeigner,
    setForeigner,
    isInterval,
    setInterval,
    staffsForOrgCodeOptions,
    staffCode,
    setStaffCode,
    detailRole,
    underFlag,
    setUnderFlag,
  } = useAchievementErrorState();

  const storeOptions = useOrgTreesOptionsShopV1(
    true,
    functionCode.attendError,
    moment(targetDateFrom).format("YYYY-MM-DD"),
    moment(targetDateTo).format("YYYY-MM-DD"),
  );

  const downloadFileErrorCSV = async () => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const targetDateFrom = sessionStorage.getItem("attendError.targetDateFrom") || "";
    const targetDateTo = sessionStorage.getItem("attendError.targetDateTo") || "";
    const searchType = 0;
    setIsLoading(true);
    await downloadErrorCSVV2(
      staffCode === "all" || staffCode === "" ? "" : staffCode,
      companyCode,
      orgCode,
      targetDateFrom,
      targetDateTo,
      searchType,
      underFlag,
    ).then((response: any) => {
      setIsLoading(false);
    });
  };

  const downloadFileAlertCSV = async () => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const targetDateFrom = sessionStorage.getItem("attendError.targetDateFrom") || "";
    const targetDateTo = sessionStorage.getItem("attendError.targetDateTo") || "";
    const searchType = 1;
    setIsLoading(true);
    await downloadAlertCSV(
      staffCode === "all" || staffCode === "" ? "" : staffCode,
      companyCode,
      orgCode,
      targetDateFrom,
      targetDateTo,
      searchType,
      isOT,
      isHolidayWork,
      isBreak,
      isWorkingTogether,
      isUnder18,
      isForeigner,
      isInterval,
      underFlag,
    ).then((response: any) => {
      setIsLoading(false);
    });
  };

  function getStampTimeStyle(haveError: any, haveAlert: any, applicationStatus: any, haveContent: any) {
    if (!haveContent) {
    }

    if (haveError === true) {
      // エラー
      return { backgroundColor: "#fdefef" };
    }

    if (applicationStatus == "0") {
      // 申請中
      return { backgroundColor: "#e6fff7" };
    } else if (applicationStatus == "1") {
      // 承認
      return { backgroundColor: "#f2f8fc" };
    }

    if (haveAlert === true) {
      // アラート
      return { backgroundColor: "rgb(189,172,60)" };
    }

    return {};
  }

  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }
    fetchData();
    // eslint-disable-next-line
  }, [
    staffCode,
    orgCode,
    targetDateFrom,
    targetDateTo,
    isFilter,
    isOT,
    isHolidayWork,
    isBreak,
    isWorkingTogether,
    isUnder18,
    isForeigner,
    isInterval,
    underFlag,
  ]);

  const mobileColumns = React.useMemo(
    () => [
      {
        Header: isMobile ? "申請" : "修正",
        accessor: "history",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number } }) => (
          <div>
            {cell.row.original.stampApplicationStatus !== 0 && detailRole.editable !== 0 && (
              <ContextMenu
                text={isMobile ? "" : "申請"}
                buttons={getContextMenuButtons(
                  history,
                  cell.row.original.modifierApplicationId,
                  cell.row.original.orgCode,
                  cell.row.original.staffCode,
                  cell.row.original.staffName,
                  null,
                  cell.row.original.holidayApplicationStatus,
                  cell.row.original.allowanceApplicationStatus,
                  cell.row.original.overtimeApplicationStatus,
                  cell.row.original.holidayworkApplicationStatus,
                  null,
                  "",
                  cell.row.original.useOverTimeApplication,
                  cell.row.original.necessaryHolidayWorkApplication,
                  cell.row.original.useAllowanceApplication,
                  cell.row.original.useTransferApplication,
                  cell.row.original.achievementId,
                  cell.row.original.targetDate,
                  cell.row.original.stampVersion,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.original.stampApplicationStatus,
                  cell.row.original.hasError,
                  undefined,
                  undefined,
                  Number(workSystem),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.index,
                  cell.row.original.orgCode,
                  cell.row.original.orgCode,
                  cell.row.original.startTime,
                  cell.row.original.endTime,
                  false,
                  cell.row.original.useStockPaidApp,
                )}
              />
            )}
            {cell.row.original.stampApplicationStatus === 0 && detailRole.editable !== 0 && (
              <ContextMenu
                text={isMobile ? "" : "申請中"}
                action={3}
                buttons={getContextMenuButtons(
                  history,
                  cell.row.original.modifierApplicationId,
                  cell.row.original.orgCode,
                  cell.row.original.staffCode,
                  cell.row.original.staffName,
                  null,
                  cell.row.original.holidayApplicationStatus,
                  cell.row.original.allowanceApplicationStatus,
                  cell.row.original.overtimeApplicationStatus,
                  cell.row.original.holidayworkApplicationStatus,
                  null,
                  "",
                  cell.row.original.useOverTimeApplication,
                  cell.row.original.necessaryHolidayWorkApplication,
                  cell.row.original.useAllowanceApplication,
                  cell.row.original.useTransferApplication,
                  cell.row.original.achievementId,
                  cell.row.original.targetDate,
                  cell.row.original.stampVersion,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.original.stampApplicationStatus,
                  cell.row.original.hasError,
                  undefined,
                  undefined,
                  Number(workSystem),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.index,
                  cell.row.original.orgCode,
                  cell.row.original.orgCode,
                  cell.row.original.startTime,
                  cell.row.original.endTime,
                  true,
                )}
              />
            )}
          </div>
        ),
      },
      {
        Header: "日付",
        accessor: "targetDate",
        isSorted: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div>{moment(cell.row.original.targetDate).format("MM/DD(ddd)")}</div>
        ),
      },
      {
        Header: "エラー内容",
        accessor: "errorMessage",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div>{cell.row.original.errorMessage.replace(/<\/br>/gi, "\n")}</div>
        ),
      },
    ],

    // eslint-disable-next-line
    [detailRole],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "種別",
        accessor: "hasError",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          return (
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <span style={{ marginTop: "-3px", marginLeft: "3px" }}>
                {cell.row.original.hasError === "1" ? "エラー" : "アラート"}
              </span>
              <div>
                <IconTooltip
                  iconType="warning"
                  text={cell.row.original.errorMessage.replace(/<\/br>/gi, "\n") || ""}
                  color={cell.row.original.hasError === "1" ? utilityColor.error : utilityColor.yellow}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: "修正",
        accessor: "history",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number } }) => (
          <div>
            {cell.row.original.stampApplicationStatus !== 0 && detailRole.editable !== 0 && (
              <ContextMenu
                text="申請"
                buttons={getContextMenuButtons(
                  history,
                  cell.row.original.modifierApplicationId,
                  cell.row.original.orgCode,
                  cell.row.original.staffCode,
                  cell.row.original.staffName,
                  null,
                  cell.row.original.holidayApplicationStatus,
                  cell.row.original.allowanceApplicationStatus,
                  cell.row.original.overtimeApplicationStatus,
                  cell.row.original.holidayworkApplicationStatus,
                  cell.row.original.transferApplicationStatus,
                  "",
                  cell.row.original.useOverTimeApplication,
                  cell.row.original.necessaryHolidayWorkApplication,
                  cell.row.original.useAllowanceApplication,
                  cell.row.original.useTransferApplication,
                  cell.row.original.achievementId,
                  cell.row.original.targetDate,
                  cell.row.original.stampVersion,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.original.stampApplicationStatus,
                  cell.row.original.hasError,
                  undefined,
                  undefined,
                  Number(workSystem),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.index,
                  cell.row.original.orgCode,
                  cell.row.original.orgCode,
                  cell.row.original.startTime,
                  cell.row.original.endTime,
                  false,
                  cell.row.original.useStockPaidApp,
                )}
              />
            )}
            {cell.row.original.stampApplicationStatus === 0 && detailRole.editable !== 0 && (
              <ContextMenu
                text="申請中"
                action={3}
                buttons={getContextMenuButtons(
                  history,
                  cell.row.original.modifierApplicationId,
                  cell.row.original.orgCode,
                  cell.row.original.staffCode,
                  cell.row.original.staffName,
                  null,
                  cell.row.original.holidayApplicationStatus,
                  cell.row.original.allowanceApplicationStatus,
                  cell.row.original.overtimeApplicationStatus,
                  cell.row.original.holidayworkApplicationStatus,
                  cell.row.original.transferApplicationStatus,
                  "",
                  cell.row.original.useOverTimeApplication,
                  cell.row.original.necessaryHolidayWorkApplication,
                  cell.row.original.useAllowanceApplication,
                  cell.row.original.useTransferApplication,
                  cell.row.original.achievementId,
                  cell.row.original.targetDate,
                  cell.row.original.stampVersion,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.original.stampApplicationStatus,
                  cell.row.original.hasError,
                  undefined,
                  undefined,
                  Number(workSystem),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  cell.row.index,
                  cell.row.original.orgCode,
                  cell.row.original.orgCode,
                  cell.row.original.startTime,
                  cell.row.original.endTime,
                  true,
                )}
              />
            )}
          </div>
        ),
      },
      {
        Header: "日付",
        accessor: "targetDate",
        isSorted: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div>{moment(cell.row.original.targetDate).format("MM/DD(ddd)")}</div>
        ),
      },
      {
        Header: "組織",
        accessor: "orgName",
        isSorted: true,
      },
      {
        Header: "スタッフ名",
        accessor: "staffName",
        isSorted: true,
      },
      {
        Header: "勤務予定",
        accessor: "shiftTime",
      },
      {
        Header: "休暇",
        accessor: "holidayWorkFlg",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "center" }}>
            {cell.row.original.holidayWorkFlg && (
              <span
                style={{
                  background: cell.row.original.holidayBgColor,
                  borderRadius: "3px",
                  padding: "2px 7px",
                  color: cell.row.original.holidayForeColor,
                }}
              >
                休日出勤
              </span>
            )}
            {!cell.row.original.holidayWorkFlg && cell.row.original.holiday && (
              <span
                style={{
                  background: cell.row.original.holidayBgColor,
                  borderRadius: "3px",
                  padding: "2px 7px",
                  color: cell.row.original.holidayForeColor,
                }}
              >
                {cell.row.original.holiday}
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "出勤",
        accessor: "startTime",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              ...getStampTimeStyle(
                cell.row.original.errorHolidayWorkApplication ||
                  cell.row.original.errorDuplicate ||
                  cell.row.original.errorSametimeStamp,
                cell.row.original.alertContinuousWork ||
                  cell.row.original.alertUnder18MidnightWork ||
                  cell.row.original.alertForeignerOver28Hours ||
                  cell.row.original.alertForeignerValidityPeriod,
                cell.row.original.stampApplicationStatus,
                !!cell.row.original.startTime,
              ),
            }}
          >
            {cell.row.original.startTime}
          </div>
        ),
      },
      {
        Header: "退勤",
        accessor: "endTime",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div
            style={{
              textAlign: "center",
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: cell.row.original.startTime && !cell.row.original.endTime ? "#fdefef" : "",
              ...getStampTimeStyle(
                cell.row.original.errorHolidayWorkApplication ||
                  cell.row.original.errorDuplicate ||
                  cell.row.original.errorSametimeStamp,
                cell.row.original.alertContinuousWork ||
                  cell.row.original.alertUnder18MidnightWork ||
                  cell.row.original.alertForeignerOver28Hours ||
                  cell.row.original.alertForeignerValidityPeriod,
                cell.row.original.stampApplicationStatus,
                !!cell.row.original.endTime,
              ),
            }}
          >
            {cell.row.original.endTime}
          </div>
        ),
      },
      {
        Header: "休憩",
        accessor: "recess",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div>{cell.row.original.recess?.map((recess: any) => `${recess.startTime}～${recess.endTime || ""}\n`)}</div>
        ),
      },
      {
        Header: "未承認残業",
        accessor: "unapprovedOverTime",
      },
      {
        Header: "残業",
        accessor: "overtime",
      },
      {
        Header: "エラー内容",
        accessor: "errorMessage",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div>{cell.row.original.errorMessage.replace(/<\/br>/gi, "\n")}</div>
        ),
      },
    ],

    // eslint-disable-next-line
    [detailRole],
  );

  return (
    <BlockUI blocking={isLoading}>
      <FormContents
        customStyle={css({
          paddingBottom: isMobile ? "8px" : "",
        })}
      >
        <FormTitle
          title="勤怠エラー実績"
          bold={true}
          customStyle={css(isMobile ? { padding: "8px 16px 0 16px", margin: 0 } : {})}
        />
        <Container fluid={true}>
          <FormPadding customStyle={css(isMobile ? { padding: "0 16px 0px 16px" } : { paddingLeft: "35px" })}>
            {isMobile ? (
              <React.Fragment>
                <Row style={{ marginTop: "10px" }}>
                  <Col md={12} xs={12}>
                    <FormLabel label="期間" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={12}>
                    <CalendarRangeV2
                      startDate={targetDateFrom}
                      onChangeStart={(date: Date) => {
                        setTargetDateFrom(date);
                        sessionStorage.setItem("attendError.targetDateFrom", moment(date).format("YYYY-MM-DD"));
                      }}
                      endDate={targetDateTo}
                      onChangeEnd={(date) => {
                        if (date) {
                          setTargetDateTo(date);
                          sessionStorage.setItem("attendError.targetDateTo", moment(date).format("YYYY-MM-DD"));
                        }
                      }}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Row style={{ marginTop: "10px" }}>
                <Col md={12} xs={12}>
                  <FlexBox customStyle={css({ flexWrap: "wrap" })}>
                    <FlexBoxItem width="100px">
                      <FormLabel label="期間" />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <CalendarRangeV2
                        startDate={targetDateFrom}
                        onChangeStart={(date: Date) => {
                          setTargetDateFrom(date);
                          sessionStorage.setItem("attendError.targetDateFrom", moment(date).format("YYYY-MM-DD"));
                        }}
                        endDate={targetDateTo}
                        onChangeEnd={(date) => {
                          if (date) {
                            setTargetDateTo(date);
                            sessionStorage.setItem("attendError.targetDateTo", moment(date).format("YYYY-MM-DD"));
                          }
                        }}
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </Col>
              </Row>
            )}
            {detailRole.accessRange !== 0 && !isMobile && (
              <Row>
                <Col md={12} xs={12}>
                  <FlexBox>
                    <SelectForm
                      label="組織名"
                      name="orgCode"
                      value={
                        sessionStorage.getItem("attendError.orgCode")
                          ? String(sessionStorage.getItem("attendError.orgCode"))
                          : orgCode
                      }
                      setValue={(val: string) => {
                        if (val !== orgCode) {
                          setOrgCode(val);
                          setStaffCode("all");
                          sessionStorage.setItem("attendError.staffCode", "all");
                          sessionStorage.setItem("attendError.orgCode", val);
                        }
                      }}
                      width="900px"
                      widthLabel="100px"
                      marginRight="30px"
                      options={storeOptions}
                      required={false}
                    />
                    <Checkbox
                      id={"underFlag"}
                      name={"underFlag"}
                      label={"配下指定"}
                      value={`underFlag`}
                      checked={Boolean(underFlag)}
                      onChange={() => {
                        setUnderFlag(underFlag === 1 ? 0 : 1);
                      }}
                    />
                  </FlexBox>
                </Col>
              </Row>
            )}
            {/* staff filter */}

            {!isMobile && (
              <Row>
                <Col md={6} xs={12}>
                  <FlexBox>
                    <FlexBoxItem width="100px">
                      <FormLabel label="スタッフ" />
                    </FlexBoxItem>

                    <FlexBoxItem grow={1} width="150px">
                      {detailRole.accessRange !== 0 ? (
                        <SelectForm
                          label=""
                          name="staffCode"
                          value={
                            sessionStorage.getItem("attendError.staffCode")
                              ? String(sessionStorage.getItem("attendError.staffCode"))
                              : staffCode
                          }
                          setValue={(v) => {
                            setStaffCode(v);
                            sessionStorage.setItem("attendError.staffCode", v);
                          }}
                          options={staffsForOrgCodeOptions}
                          placeholder="スタッフを選択してください"
                          width="800px"
                        />
                      ) : (
                        <BodyText color="#333333">
                          {staffsForOrgCodeOptions.length > 0 &&
                            staffsForOrgCodeOptions.find((staff: any) => staff.value === staffCode)?.label}
                        </BodyText>
                      )}
                    </FlexBoxItem>
                  </FlexBox>
                </Col>
              </Row>
            )}

            {isMobile ? (
              <React.Fragment>
                <Row style={{ marginTop: "10px" }}>
                  <Col md={12} xs={12}>
                    <FormLabel label="対象実績" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={12}>
                    <FlexBox customStyle={css({ flexWrap: "wrap" })}>
                      <FlexBoxItem grow={1}>
                        <Radio
                          id="error"
                          name="error"
                          label="エラー"
                          value="error"
                          checked={isFilter}
                          onChange={() => {
                            sessionStorage.setItem("attendError.isFilter", String(!isFilter));
                            setIsFilter(!isFilter);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1}>
                        <Radio
                          id="alert"
                          name="error"
                          label="アラート"
                          value="alert"
                          checked={!isFilter}
                          onChange={() => {
                            sessionStorage.setItem("attendError.isFilter", String(!isFilter));
                            setIsFilter(!isFilter);
                          }}
                        />
                      </FlexBoxItem>
                    </FlexBox>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Row style={{ marginTop: "10px" }}>
                <Col lg={10} md={12}>
                  <FlexBox customStyle={css({ flexWrap: "wrap" })}>
                    <FlexBoxItem width="100px">
                      <FormLabel customStyle={css({ margin: "5px 0 0 0" })} label="対象実績" />
                    </FlexBoxItem>
                    <FlexBoxItem grow={1}>
                      <Radio
                        id="error"
                        name="error"
                        label="エラー"
                        value="error"
                        checked={isFilter}
                        onChange={() => {
                          sessionStorage.setItem("attendError.isFilter", String(!isFilter));
                          setIsFilter(!isFilter);
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem grow={1}>
                      <Radio
                        id="alert"
                        name="error"
                        label="アラート"
                        value="alert"
                        checked={!isFilter}
                        onChange={() => {
                          sessionStorage.setItem("attendError.isFilter", String(!isFilter));
                          setIsFilter(!isFilter);
                        }}
                      />
                    </FlexBoxItem>
                    <React.Fragment>
                      <BodyText> ( </BodyText>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showOT"
                          name="showError"
                          label="残業"
                          value="OT"
                          checked={isOT}
                          disabled={isFilter}
                          onChange={() => {
                            sessionStorage.setItem("dataError.OT", String(!isOT));
                            setOT(!isOT);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showErrorHolidayWork"
                          name="showHolidayWork"
                          label="休日出勤"
                          value="HolidayWork"
                          disabled={isFilter}
                          checked={isHolidayWork}
                          onChange={() => {
                            sessionStorage.setItem("dataError.HolidayWork", String(!isHolidayWork));
                            setHolidayWork(!isHolidayWork);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showErrorBreak"
                          name="showBreak"
                          label="休憩"
                          value="Break"
                          disabled={isFilter}
                          checked={isBreak}
                          onChange={() => {
                            sessionStorage.setItem("dataError.Break", String(!isBreak));
                            setBreak(!isBreak);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showErrorWorkingTogether"
                          name="showWorkingTogether"
                          label="連勤"
                          disabled={isFilter}
                          value="WorkingTogether"
                          checked={isWorkingTogether}
                          onChange={() => {
                            sessionStorage.setItem("dataError.WorkingTogether", String(!isWorkingTogether));
                            setWorkingTogether(!isWorkingTogether);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showErrorUnder18"
                          name="showUnder18"
                          label="18歳未満"
                          disabled={isFilter}
                          value="Under18"
                          checked={isUnder18}
                          onChange={() => {
                            sessionStorage.setItem("dataError.Under18", String(!isUnder18));
                            setUnder18(!isUnder18);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showErrorForeigner"
                          name="showForeigner"
                          label="外国人"
                          disabled={isFilter}
                          value="Foreigner"
                          checked={isForeigner}
                          onChange={() => {
                            sessionStorage.setItem("dataError.Foreigner", String(!isForeigner));
                            setForeigner(!isForeigner);
                          }}
                        />
                      </FlexBoxItem>
                      <FlexBoxItem grow={1} textAlign="center">
                        <Checkbox
                          id="showErrorInterval"
                          name="showInterval"
                          label="勤務間インターバル"
                          disabled={isFilter}
                          value="Interval"
                          checked={isInterval}
                          onChange={() => {
                            sessionStorage.setItem("dataError.Interval", String(!isInterval));
                            setInterval(!isInterval);
                          }}
                        />
                      </FlexBoxItem>
                      <BodyText> ) </BodyText>
                    </React.Fragment>
                  </FlexBox>
                </Col>
              </Row>
            )}
          </FormPadding>
        </Container>

        {detailRole.downloadFlag === 1 && !isMobile && (
          <FlexBox
            customStyle={css({
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "28px",
            })}
          >
            <FlexBoxItem>
              <IconLabelButton
                onClick={() => {
                  isFilter ? downloadFileErrorCSV() : downloadFileAlertCSV();
                }}
                iconType="download"
                text="CSVダウンロード"
              />
            </FlexBoxItem>
          </FlexBox>
        )}
        <div css={isMobile ? styles.mobileTable : styles.table}>
          <DataTable
            iconSize="16px"
            containerStyle={css({
              overflowX: "auto",
              border: "none",
            })}
            controllerStyle={css({
              padding: "16px !important",
            })}
            minWidth={isMobile ? "100%" : undefined}
            columns={isMobile ? mobileColumns : columns}
            data={errorList}
            isHiddenPagination={isMobile}
            initPageSize={isMobile ? 100 : 20} // NOTE: 暫定で100件表示
            isGlobalFilter={true}
            prefix={refixTable}
            highlightStatus={true}
            highlightAction={sessionStorage.getItem("highlightAction") || ""}
            highlightIndex={sessionStorage.getItem("highlightIndex") || ""}
            sortBy={[
              { id: "targetDate", desc: true },
              { id: "orgName", desc: true },
              { id: "staffName", desc: true },
            ]}
          />
        </div>
      </FormContents>
    </BlockUI>
  );
};
export default AttendanceError;
