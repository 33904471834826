/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';

import Modal from 'components/molecules/Modal';
import Table from 'components/molecules/Table';

import ManagerDomain from 'domain/manager';
import OrganizationFilter from './OrganizationFilter';
import { useStaffList } from './hooks';
import Checkbox from 'components/molecules/Checkbox';

const OrganizationManagerAddModal: React.FC<{
    open: boolean;
    openHandler: (arg: boolean) => void;
    addManagerList: (arg: Array<ManagerDomain>) => void
}> = ({
  open, openHandler, addManagerList,
}) => {
  const {
    staffList, onSubmit, orgMgrCode, setOrgMgrCode, checkedStaffCodeList, checkHandler
  } = useStaffList(addManagerList, openHandler);
  const [openMenu, setOpenMenu] = useState(false);
  
  return (
    <div
    css={css`
    table th:nth-of-type(1) {
      width: 40px;
    }
  `}
  >
    <Modal
      open={open}
      closeHandler={() => openHandler(false)}
      title="スタッフ選択"
      submitText="閉じる"
      onSubmit={onSubmit}
      customStyle={css({ width: '700px' })}
    >
      <OrganizationFilter
        orgCode={orgMgrCode}
        setOrgCode={(arg : string) => {
          setOrgMgrCode(arg);
        }}
      />
      <div style={{height: '450px'}}>
        <Table>
        <thead>
          <tr>
            <Table.HeaderCell>
            </Table.HeaderCell>
            <Table.HeaderCell>
              スタッフコード
            </Table.HeaderCell>
            <Table.HeaderCell>
              スタッフ名
            </Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {staffList && staffList.length > 0 && (
            staffList.map((staff, index) => (
              <tr key={index.toString()}>
                <Table.Cell customStyle={css({ width: '8px' })}>
                  <Checkbox
                    id={staff.staffCode}
                    name={staff.staffCode}
                    value={staff.staffCode}
                    checked={checkedStaffCodeList.includes(staff.staffCode)}
                    onChange={() => checkHandler(staff.staffCode)}
                  />
                </Table.Cell>
                <Table.Cell>
                  {staff.staffCode}
                </Table.Cell>
                <Table.Cell>
                  {staff.staffName}
                </Table.Cell>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      </div>
    </Modal>
    </div>
  );
};

export default OrganizationManagerAddModal;
