import React, { useState, useEffect } from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import FormContents from 'components/atoms/Form/FormContents';
import OrganizationInputForm from 'components/organismos/organization/OrganizationInputForm';
import OrganizationDomain from 'domain/organization';
import { Row, Col } from 'react-grid-system';
import FormPadding from 'components/atoms/Form/FormPadding';
import TabControl from 'components/atoms/TabControl';
import FormTitle from 'components/atoms/Form/FormTitle';
import BlockUI from 'components/molecules/BlockUi';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

type SelectType = 'basic'|'manager'|'specialSalary';
const SelectType: {[key in SelectType]: SelectType} = {
  basic: 'basic',
  manager: 'manager',
  specialSalary: 'specialSalary',
};

const OrganizationAddPage: React.FC = () => {
  const [historyList, setHistoryList] = useState<Array<OrganizationDomain>>([]);
  const [organizationDomain, setOrganizationDomain] = useState<OrganizationDomain>(
    OrganizationDomain.generateInitial(),
  );
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.basic);
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.organizations);

  return (
    <SidebarTemplate
      pageTitle="新規組織登録"
    >
      {/* <FormContents>
        <FormTitle
          title="登録項目"
          note="* 全て必須"
        />

        <OrganizationInputForm
          initialOrganizationDomain={organizationDomain || undefined}
          setInitialOrganizationDomain={setOrganizationDomain}
          historyList={historyList}
          setHistoryList={setHistoryList}
        />
      </FormContents> */}
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle title="組織作成" bold={true} />
          <FormPadding customStyle={{ padding: '0 30px' }}>            
            <Row>
              <Col md={3} xs={12}>
                <TabControl
                  items={[
                    {
                      value: SelectType.basic,
                      display: '基本情報',
                    },
                    // {
                    //   value: SelectType.manager,
                    //   display: 'マネージャー管理情報',
                    // },
                    // {
                    //   value: SelectType.specialSalary,
                    //   display: '特賃日割当情報',
                    // },
                  ]}
                  setValue={setSelectType as (arg: string) => void}
                  currentValue={selectType}
                  isFixed={false}
                />
              </Col>
            </Row>
          </FormPadding>
          {selectType === SelectType.basic
              && (
                <>
                  {/* <OrganizationInfoTable
                    organizationDomain={organizationDomain}
                  /> */}

                  <OrganizationInputForm
                    initialOrganizationDomain={organizationDomain || undefined}
                    setInitialOrganizationDomain={setOrganizationDomain}
                    historyList={historyList}
                    setHistoryList={setHistoryList}
                    isEditable={detailRole.editable}
                    setBlocking={setBlocking}
                  />
                </>
              )}
          {/* {selectType === SelectType.manager
              && (
              <OrganizationManagerEditForm
                managerList={managerList}
                seniorManagerList={seniorManagerList}
                setManagerList={setManagerList}
                setSeniorManagerList={setSeniorManagerList}
                isEditMode={true}
              />
              )} */}
          {/* {selectType === SelectType.specialSalary
              && (
              <OrganizationSpecialSalaryEditForm
                isEditMode={true}
              />
              )} */}
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default OrganizationAddPage;
