/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/** @jsx jsx */

import React, { useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { jsx } from '@emotion/core';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import FormLabel from 'components/atoms/Form/FormLabel';
import { Row, Col } from 'react-grid-system';
import FormPadding from 'components/atoms/Form/FormPadding';
import SelectForm from 'components/molecules/SelectForm';
import usePaidHolidayAlert from './hooks';
import PaidHolidayStatusDomain from 'domain/master/holidayManagement/paidHolidayStatus';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { Column } from 'react-table';
import DataTable from 'components/organismos/DataTable/DataTable';
import BlockUI from 'components/molecules/BlockUi';

const PaidHolidayAlert: React.FC = () => {
  const {
    setOrgCode,
    staffsForOrgCodeOptions,
    setStaffCode,
    orgCode,
    staffCode,
    listPaidHolidayAlert,
    isLoading
  } = usePaidHolidayAlert();
  useEffect(() => {
  }, []);

  const storeOptions = useOrgTreesOptions();

  const columns: Array<Column<PaidHolidayStatusDomain>> = React.useMemo(() => [
    {
      Header: () => 'スタッフコード',
      id: 'staffCode',
      accessor: 'staffCode',
      Cell: (cell: {
        row: { isExpanded: boolean, original: PaidHolidayStatusDomain },
        data: Array<PaidHolidayStatusDomain>
      }) => (
          <span>
            {cell.row.original.staffCode}
          </span>
        ),
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
    },
    {
      Header: '付与日',
      accessor: 'effectiveStartDateToStr',
    },
    {
      Header: '前年繰越数',
      accessor: 'carriedOverLastYear',
    },
    {
      Header: '当年付与数',
      accessor: 'numberGrantedThisYear',
    },
    {
      Header: '消化数',
      accessor: 'digestionNumber',
    },
    {
      Header: '残有給数',
      accessor: 'remainingPaid',
    },
    {
      Header: '次回付与日',
      accessor: 'nextGrantDate',
    },
    {
      Header: '次回予定付与日数',
      accessor: 'nextGrantDaysToStr',
    },
    {
      Header: '次回繰越日数',
      accessor: 'nextRolloverDays',
    },
    {
      Header: '次回消滅日数',
      accessor: 'nextExpirationDate',
    },
    {
      Header: '警告メッセージ',
      accessor: 'alertMessage',
    },
  ], []);
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormPadding>
          <Row>
            <Col md={6} xs={12}>
              <FlexBox>
                <FlexBoxItem width="100px">
                  <FormLabel
                    label="組織名"
                  />
                </FlexBoxItem>

                <FlexBoxItem grow={1} width="150px">
                  <SelectForm
                    label=""
                    name="orgCode"
                    value={orgCode}
                    setValue={(val: string) => {
                      setOrgCode(val);
                    }}
                    options={storeOptions}
                    required={false}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
          </Row>
        </FormPadding>
        <FormPadding>
          <Row>
            <Col md={6} xs={12}>
              <SelectForm
                label="スタッフ"
                name="staffCode"
                placeholder="スタッフを選択"
                value={staffCode}
                setValue={setStaffCode}
                options={staffsForOrgCodeOptions}
              />
            </Col>
          </Row>
        </FormPadding>
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <DataTable
            data={listPaidHolidayAlert}
            iconSize="16px"
            className='table-no-border-left-right'
            columns={columns}
            isGlobalFilter={true}
            sortBy={[
              { id: 'targetDate', desc: true },
              { id: 'orgName', desc: true },
              { id: 'staffName', desc: true }
            ]}
          />
        </div>
      </FormContents>
    </BlockUI>
  );
};
export default PaidHolidayAlert;
