/** @jsx jsx */

import React, { useEffect } from "react";
import { Column } from "react-table";

import Table from "components/molecules/Table";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { useDataTable } from "./hooks";
import TableHeaderCell from "./TableHeaderCell";
import Controllbar from "./Controllbar";
import ControllbarPagination from "./ControllbarPagination";
import TextTooltip from "components/molecules/TextTooltip";

const style = {
  tableTr: css({
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: " 12px 0",
    fontSize: "14px",
  }),
  backgroundWhite: css({
    backgroundColor: "#FFF",
  }),
  cursor: css({
    whiteSpace: "nowrap",
    margin: "0 16px",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    ["table tr th"]: {
      cursor: "pointer",
    },
  }),
};

interface SortBy {
  id: string;
  desc: boolean;
}

interface DataTableProps {
  columns: Array<Column<any>>;
  data: Array<any>;
  isGlobalFilter?: any;
  minWidth?: string;
  sortBy?: Array<SortBy>;
  useSession?: boolean;
  tableId?: number;
  globalSearchStyle?: any;
  controllerStyle?: any;
  className?: any;
  iconSize?: string;
  searchRight?: boolean;
  isHiddenPagination?: boolean;
  onClickPagination?: () => void;
  customStyle?: SerializedStyles;
  containerStyle?: SerializedStyles;
  prefix?: any;
  highlightStatus?: boolean;
  highlightAction?: any;
  highlightIndex?: any;
  initPageSize?: number;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  iconSize,
  data,
  isGlobalFilter,
  minWidth = isGlobalFilter ? "620px" : "488px",
  sortBy,
  useSession = true,
  tableId = 0,
  globalSearchStyle,
  controllerStyle,
  className = "",
  searchRight = false,
  isHiddenPagination,
  onClickPagination,
  customStyle,
  containerStyle,
  prefix,
  highlightStatus,
  highlightAction,
  highlightIndex,
  initPageSize = 20,
}) => {
  let globalFilterTxt = "";
  let initPageIndex = 0;

  if (useSession) {
    globalFilterTxt =
      sessionStorage.getItem(`${tableId}/sessionData${window.location.pathname}.globalFilterTxt`) || "";
    initPageSize =
      Number(sessionStorage.getItem(`${tableId}/sessionData${window.location.pathname}.initPageSize`)) || initPageSize;
    initPageIndex =
      Number(sessionStorage.getItem(`${tableId}/sessionData${window.location.pathname}.initPageIndex`)) || 0;
  }
  if (data.length <= initPageIndex * initPageSize) {
    if (data.length % initPageSize === 0) {
      initPageIndex = data.length / initPageSize === 0 ? data.length / initPageSize : data.length / initPageSize - 1;
    } else {
      initPageIndex = Math.floor(data.length / initPageSize);
    }
  }

  const { headerGroups, prepareRow, pagination, page, filter, arrOverflow } = useDataTable(
    columns,
    data,
    isGlobalFilter,
    initPageSize,
    initPageIndex,
    sortBy,
    globalFilterTxt,
  );

  useEffect(() => {
    pagination.gotoPage(1);
    sessionStorage.setItem(`${tableId}/sessionData${window.location.pathname}.initPageIndex`, "0");
  }, [globalFilterTxt]);

  const elmnt = document.getElementById(prefix + "_row_" + highlightIndex);
  useEffect(() => {
    if (highlightAction === "true" && highlightStatus) {
      if (elmnt) {
        elmnt.scrollIntoView({ block: "center" });
        elmnt.classList.add("hightlight");
        setTimeout(() => {
          elmnt.classList.remove("hightlight");
          sessionStorage.setItem("highlightAction", "false");
          sessionStorage.setItem("highlightIndex", "");
        }, 3000);
      }
    }
  }, [elmnt, highlightAction]);

  return (
    <React.Fragment>
      <div>
        <Controllbar
          searchRight={searchRight}
          iconSize={iconSize}
          globalSearchStyle={globalSearchStyle}
          controllerStyle={controllerStyle}
          isGlobalFilter={isGlobalFilter}
          filter={filter}
          pagination={pagination}
          tableId={tableId}
        />
        <div className="table-container" css={[style.cursor, containerStyle]}>
          <Table className={className} customStyle={css([{ minWidth, whiteSpace: "nowrap" }, customStyle])}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <TableHeaderCell
                        key={headerProps.key}
                        onClick={(headerProps as any).onClick}
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        disableSortBy={column.disableSortBy}
                      >
                        {column.render("Header")}
                      </TableHeaderCell>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {page.length > 0 ? (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      id={(prefix || "") + "_" + row.getRowProps().key}
                      key={row.getRowProps().key}
                      style={{
                        backgroundColor: "#ffffff",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {row.cells.map((cell, indexCell) =>
                        arrOverflow[indexCell] === true ? (
                          <Table.Cell key={cell.getCellProps().key}>
                            <TextTooltip
                              text={cell.value}
                              textDis={
                                <div
                                  className="ellipsis headline-cell-width-120"
                                  style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                >
                                  {cell.render("Cell")}
                                </div>
                              }
                            />
                          </Table.Cell>
                        ) : (
                          <Table.Cell key={cell.getCellProps().key}>{cell.render("Cell")}</Table.Cell>
                        ),
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr css={style.backgroundWhite}>
                  <td colSpan={columns.length} css={style.tableTr} style={{ textAlign: "center" }}>
                    データはありません。
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {!isHiddenPagination && pagination.total ? (
          <ControllbarPagination
            onClickPagination={onClickPagination}
            isGlobalFilter={isGlobalFilter}
            pagination={pagination}
            tableId={tableId}
          />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default DataTable;
