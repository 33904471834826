import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getManagementNursingHolidayStatus,
  downloadNursingCareHolidayStatusCSV
} from 'api/holidayManagement';
import ManagementNursingHolidayStatusDomain from 'domain/master/holidayManagement/managementNursingHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export const useManagementInformationDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementNursingCare.managementInformation.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [employmentId, setOrgEmploymentId] = useState('');
  const [extinctionDaysStart, setExtinctionDaysStart] = useState('');
  const [extinctionDaysEnd, setExtinctionDaysEnd] = useState('');
  const [targetGrantTenDays, setTargetGrantTenDays] = useState(false);
  const [untilNextGrantDate, setUntilNextGrantDate] = useState('');
  const [managementNursingHolidayStatus, setManagementNursingHolidayStatus] = useState<ManagementNursingHolidayStatusDomain[]>([])

  const [holidayType, setHolidayType] = useSaveStateStorage('7', "holidayManagementNursingCare.managementInformation.holidayType") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const holidayOptions = [
    {
      value : '7',
      label: '介護休暇'
    },
    {
      value : '8',
      label: '子の看護休暇'
    }
  ];

  const [modalOpen, setModalOpen] = useState(false);

  const contextObject = useContext(HolidayManagementContext);
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementNursingCare.managementInformation.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      await getManagementNursingHolidayStatus(
        holidayType || '7',
        orgCode !== 'all' ? orgCode : '',
        underFlag || 0
      ).then((response: any) => {
        setManagementNursingHolidayStatus(response);
      });
      contextObject.setIsLoading(false);
    }
  }, [
    orgCode, holidayType, underFlag
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, orgCode, holidayType, underFlag]);

  return {
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    extinctionDaysStart,
    setExtinctionDaysStart,
    extinctionDaysEnd,
    setExtinctionDaysEnd,
    targetGrantTenDays,
    setTargetGrantTenDays,
    modalOpen,
    setModalOpen,
    setUntilNextGrantDate,
    untilNextGrantDate,
    holidayType,
    setHolidayType,
    holidayOptions,
    managementNursingHolidayStatus,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagement = (
  orgCode: string,
  holidayType: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadNursingCareHolidayStatus = useCallback(() => {
    setBlocking(true);
    downloadNursingCareHolidayStatusCSV(
      Number(holidayType),
      orgCode !== 'all' ? orgCode : '',
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [orgCode, holidayType, underFlag]);

  return {
    downloadNursingCareHolidayStatus,
    blocking
  };
};

export default {
  useManagementInformationDomainForm,
};
