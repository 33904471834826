import moment from 'moment';
import ApiClient from './ApiClient';

export type AttendErrorPersonal = {
  targetDate: string;
  errorMessage: string;
}

export type AttendErrorOgr = {
  orgName: string;
  orgCode: string;
  errCount: number;
}

export const downloadErrorCSV = async (
  staffCode: string,
  companyCode: string,
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  searchType: number
): Promise<void> => {
  const params = {
    staffCode,
    orgCode,
    targetDateFrom,
    targetDateTo,
    searchType
  };
  await ApiClient.downloadCsv(`/v1/attendAchievementError/export/csv/${companyCode}`, params, getDownloadErrorCsvFileName('エラー_', targetDateFrom, targetDateTo));
};

export const downloadErrorCSVV2 = async (
  staffCode: string,
  companyCode: string,
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  searchType: number,
  underFlag: number
): Promise<void> => {
  const staffCodeLogin = sessionStorage.getItem('loginUser.staffCode') || '';
  const orgCodeLogin = sessionStorage.getItem('loginUser.orgCode') || '';
  const allOrganize = sessionStorage.getItem('loginUser.allOrganizationAccess') || '';
  const params = {
    staffCodeLogin,
    orgCodeLogin,
    allOrganize,
    staffCode,
    orgCode,
    targetDateFrom,
    targetDateTo,
    searchType,
    underFlag
  };
  await ApiClient.downloadCsv(`/v2/attendAchievementError/export/csv/${companyCode}`, params, getDownloadErrorCsvFileName('エラー_', targetDateFrom, targetDateTo));
};


const getDownloadErrorCsvFileName = (prefix: string, targetDateFrom: string, targetDateTo: string) => `${prefix}${moment(targetDateFrom).format("YYYYMMDD") + '_' + moment(targetDateTo).format("YYYYMMDD") }.csv`;

export const downloadAlertCSV = async (
  staffCode: string,
  companyCode: string,
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  searchType: number,
  toSearchOvertimeAlert: boolean = true,
  toSearchHolidayworkAlert: boolean = true,
  toSearchRecessAlert: boolean = true,
  toSearchContinuousWorkAlert: boolean = true,
  toSearchUnder18MidnightWorkAlert: boolean = true,
  toSearchForeignerAlert: boolean = true,
  toSearchIntervalAlert: boolean = true,
  underFlag: number
): Promise<void> => {
  const staffCodeLogin = sessionStorage.getItem('loginUser.staffCode') || '';
  const orgCodeLogin = sessionStorage.getItem('loginUser.orgCode') || '';
  const allOrganize = sessionStorage.getItem('loginUser.allOrganizationAccess') || '';
  const params = {
    staffCodeLogin,
    orgCodeLogin,
    allOrganize,
    staffCode,
    orgCode,
    targetDateFrom,
    targetDateTo,
    searchType,
    toSearchOvertimeAlert,
    toSearchHolidayworkAlert,
    toSearchRecessAlert,
    toSearchContinuousWorkAlert,
    toSearchUnder18MidnightWorkAlert,
    toSearchForeignerAlert,
    toSearchIntervalAlert,
    underFlag
  };
  await ApiClient.downloadCsv(`/v2/attendAchievementError/export/csv/${companyCode}`, params, getDownloadAlertCsvFileName('アラート_', targetDateFrom, targetDateTo));
};
const getDownloadAlertCsvFileName = (prefix: string, targetDateFrom: string, targetDateTo: string) => `${prefix}${moment(targetDateFrom).format("YYYYMMDD") + '_' + moment(targetDateTo).format("YYYYMMDD") }.csv`;

export const getAttendErrorPersonal = async (): Promise<Array<AttendErrorPersonal>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
  };
  const response = await ApiClient.get(`/v2/attendErrorPersonalInfoOnIcon/${companyCode}`, params, undefined);
  return response.data;
};

export const getAttendErrorOrg = async (): Promise<Array<AttendErrorOgr>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    staffCode,
  };
  const response = await ApiClient.get(`/v2/attendErrorInfoOnIcon/${companyCode}`, params, undefined);
  return response.data;
};

export const downloadCSV = async (
  companyCode: string,
): Promise<void> => {
  const params = {};
  await ApiClient.downloadCsv(`/v1/executive/outputCsv/${companyCode}`, params, getDownloadCsvFileName('情報_'));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

export default {};
