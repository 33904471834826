import React from 'react';
import {
  PieChart as RechartsPieChart, Pie, Sector, Cell,
} from 'recharts';

import {
  grayScale, textColor, textFontSize, fontFamily,
} from 'components/styles';

export type Data = {
  name: string;
  value: number;
}

export type LegendData = {
  name: string;
  value: string;
}

const renderActiveShape = (
  centerLabel: string,
  centerText: string,
  bottomLabel: string,
  bottomText: string,
) => (props: any) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy - 36}
        dy={8}
        textAnchor="middle"
        fill={textColor.subText01}
        style={{
          fontWeight: 'bold',
          fontFamily,
          fontSize: textFontSize.xs,
        }}
      >
        {centerLabel}
      </text>

      <text
        x={cx}
        y={cy - 12}
        dy={8}
        textAnchor="middle"
        fill={textColor.main}
        style={{
          fontFamily,
          fontSize: textFontSize.xl2,
        }}
      >
        {centerText}
      </text>

      <text
        x={cx}
        y={cy + 12}
        dy={8}
        textAnchor="middle"
        fill={textColor.subText01}
        style={{
          fontFamily,
          fontSize: textFontSize.sm,
        }}
      >
        {bottomLabel}
      </text>
      <text
        x={cx}
        y={cy + 28}
        dy={8}
        textAnchor="middle"
        fill={textColor.subText01}
        style={{
          fontFamily,
          fontSize: textFontSize.sm,
        }}
      >
        {bottomText}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const renderLegendShape = (
  legentText: string,
  legendValue: string,
) => (props: any) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill,
  } = props;

  return (
    <g>
      {/* 凡例のtext */}
      <text
        x={cx + 32}
        y={cy - 16}
        dy={8}
        textAnchor="start"
        fill={textColor.main}
        style={{
          fontFamily,
          fontSize: textFontSize.re,
        }}
      >
        {legentText}
      </text>

      {/* 数値 */}
      <text
        x={cx + 36}
        y={cy + 4}
        dy={8}
        textAnchor="left"
        fill={textColor.main}
        style={{
          fontFamily,
          fontSize: textFontSize.sm,
        }}
      >
        {legendValue}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const PieChart: React.FC<{
  width: number|string;
  colorSet: Array<string>;
  data: Array<Data>;
  centerLabel: string,
  centerText: string,
  bottomLabel: string,
  bottomText: string,
  legendWidth?: number,
  legendData?: Array<LegendData>;
}> = ({
  width,
  colorSet,
  data,
  centerLabel,
  centerText,
  bottomLabel,
  bottomText,
  legendWidth = 120,
  legendData,
}) => {
  // const total = React.useMemo(
  //   () => data.reduce((acc, dataElement) => acc + dataElement.value, 0),
  //   [data],
  // );
  // console.log(total);

  const existValueData = React.useMemo(() => data.filter((dataElement) => dataElement.value > 0), [data]);

  return (
    <RechartsPieChart
      // @ts-ignore
      width={width}
      height={200}
      onMouseEnter={() => {}}
    >
      <Pie
        // データ
        data={existValueData.length > 0 ? data : data.concat([{ name: '-', value: 1 }])}
        // 値のキー
        dataKey="value"

        activeIndex={1}
        activeShape={renderActiveShape(centerLabel, centerText, bottomLabel, bottomText)}

        // グラフの位置
        cx={100}
        cy={100}

        // グラフの開始・終了位置
        startAngle={90}
        endAngle={-270}

        outerRadius={95}
        innerRadius={65}

        paddingAngle={0}

        fill={grayScale.gray05}
      >
        {
          data.map((entry, index) => <Cell key={`cell-${entry.name}`} fill={colorSet[index % colorSet.length]} />)
        }
      </Pie>

      {(legendData || []).map((dataElement, dataIndex) => {
        if (dataElement.name === '-') {
          return null;
        }
        return (
          <Pie
            data={[
              { value: 25 },
              { value: 75 },
            ]}

            cx={240 + (dataIndex >= 3 ? legendWidth : 0)}
            cy={70 * (dataIndex > 2 ? dataIndex - 3 : dataIndex) + 30}

            startAngle={90}
            endAngle={-270}

            innerRadius={18}
            outerRadius={25}

            paddingAngle={0}
            dataKey="value"
            isAnimationActive={false}

            activeIndex={0}
            activeShape={renderLegendShape(dataElement.name, dataElement.value)}
          >
            <Cell fill={colorSet[dataIndex]} />
            <Cell fill={grayScale.gray20} />
          </Pie>
        );
      })}
    </RechartsPieChart>
  );
};

export default PieChart;
