import React, { SVGProps } from 'react';

const Back: React.FC<SVGProps<SVGSVGElement>> = props => (
    <svg className="icon-back" version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="2133.000000pt" height="2133.000000pt" viewBox="0 0 2133.000000 2133.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,2133.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M60 17520 l0 -770 9568 -2 9567 -3 80 -28 c121 -43 209 -99 305 -196
            95 -95 150 -188 192 -321 l23 -75 0 -4695 0 -4695 -23 -75 c-42 -133 -97 -226
            -192 -321 -96 -97 -184 -153 -305 -196 l-80 -28 -8130 -3 -8130 -2 1738 1737
            1737 1738 -543 543 -542 542 -2663 -2663 c-1464 -1464 -2662 -2667 -2662
            -2672 0 -6 1199 -1209 2665 -2675 l2665 -2665 540 540 c297 297 540 544 540
            550 0 6 -781 791 -1735 1745 l-1735 1735 8162 5 c7771 5 8168 6 8268 23 148
            25 298 62 420 103 135 46 378 164 500 244 559 367 924 937 1021 1595 18 127
            19 254 19 4895 0 4641 -1 4768 -19 4895 -37 248 -102 457 -211 680 -252 514
            -691 918 -1219 1124 -169 66 -304 101 -526 139 -93 15 -769 17 -9697 19
            l-9598 3 0 -770z"/>
        </g>
    </svg>
);

export default Back;
