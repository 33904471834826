/** @jsx jsx */
import React, { useState, useMemo } from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import { css, jsx } from '@emotion/core';
import { grayScale, textFontSize, utilityColor } from 'components/styles';
import { ChromePicker } from 'react-color';

const styles = {
  wrapper: css({
    display: 'flex',
    maxWidth: '300px',
  }),
  wrapperFullWidth: css({
    display: 'flex',
    width: '100%',
  }),
  input: css({
    flex: 1,
    width: '300px',
    padding: '8px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: utilityColor.black,
    border: `1px solid ${grayScale.gray03}`,
    boxSizing: `border-box`,
    borderRadius: `2px`,
    lineHeight: '22px',
  }),
  cover: css({
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }),
  wrapInputColor: css({
    margin: '17px 0px'
  }),
  palette: css({
    position: 'absolute',
    zIndex: 10,
  }),
};

const ColorForm: React.FC<{
  name: string;
  label: string;
  color: string;
  isFullW?: boolean;
  required?: boolean;
  onChange: (color: any) => void;
  errorMsg?: string;
}> = ({
  name, label, onChange, errorMsg, required, isFullW = false, color,
}) => {
    const [pickerVisible, setPickerVisible] = useState(false);
    const [colorPicker, setColorPicker] = useState(color);
    useMemo(() => {
      setColorPicker(color);
    }, [color]);
    return (
      <div css={styles.wrapInputColor}>
        <VerticalLabelFormLayout
          label={(
            <FormLabel
              label={label}
              required={required}
            />
          )}
          input={(
            <div css={css(isFullW ? styles.wrapperFullWidth : styles.wrapper)}>
              <input
                css={css(styles.input, isFullW ? {
                  width: '100%',
                  backgroundColor: colorPicker,
                } : { backgroundColor: colorPicker })}
                readOnly={true}
                value={colorPicker}
                name={name}
                onFocus={() => { setPickerVisible(true); }}
              // onBlur={(e) => {
              //   onChange(e);
              //   setPickerVisible(false);
              // }}
              />
            </div>
          )}
          errorMsg={errorMsg}
        />
        {pickerVisible === true ? (
          <React.Fragment>
            <div css={css(styles.cover)} onClick={(e) => setPickerVisible(false)} />
            <ChromePicker
              css={css(styles.palette)}
              color={colorPicker || '#ffffff'}
              onChange={(e) => {
                onChange(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`);
                setColorPicker(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`);
              }}
            />
          </React.Fragment>
        ) : ''}
      </div>
    );
  };

export default ColorForm;
