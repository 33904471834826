/** @jsx jsx */
import React, { useState, createContext, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import moment from "moment";
import useLoginUser from "hooks/useLoginUser";
import Appbar from "components/organismos/Appbar";
import FormLabel from "components/atoms/Form/FormLabel";
import FormContents from "components/atoms/Form/FormContents";
import { grayScale } from "components/styles";
import TabControl from "components/atoms/TabControl";
import BarcodeRecorder from "./BarcodeRecorder";
import FaceRecorder from "./FaceRecorder";
import GPSRecorder from "./GPSRecorder";
import DataTable from "components/organismos/DataTable/DataTable";
import useRecorder from "./hook";
import { Column } from "react-table";
import { utilityColor } from "components/styles";
import ReactTooltip from "react-tooltip";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";
import BodyText from "components/atoms/BodyText";
import {
  useOrgCode,
  useOrgName,
  getBarcodeStamp,
  getFaceStamp,
  getCompanyCode,
  getStaffCode,
} from "hooks/useSessionLoginUser";
import { CurrentTimeDomain } from "domain/master/attend/currentTime";
import ImageModel from "../ImageModel";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import TextTooltip from "components/molecules/TextTooltip";
import SelectForm from "components/molecules/SelectForm";
import Checkbox from "components/molecules/Checkbox";
import { notMinScreen } from "utility/mediaQueries";
import useIsMobile from "hooks/useIsMobile";
import DataTableMobile from "components/organismos/DataTable/DataTableMobile";
import StaffSettingPanel from "components/organismos/Appbar/StaffSettingPanel";
import useComponentVisible from "hooks/useComponentVisible";
import { MobileSidebar } from "components/templates/MobileSidebar";
import FormTitle from "components/atoms/Form/FormTitle";
const isIpad = window.matchMedia("(max-width: 1024px)").matches;

type SelectType = "id" | "face" | "gps";

const SelectType: { [key in SelectType]: SelectType } = {
  id: "id",
  face: "face",
  gps: "gps",
};

const styles = {
  backHistory: css({
    position: "relative",
    top: "24px",
    left: "-65px",
    display: "inline-block",
    ["&:hover svg"]: {
      opacity: 0.7,
    },
  }),

  container: css({
    maxWidth: "1140px",
    margin: "auto",
    width: "calc(100% - 30px)",
    display: "flex",
    gap: "16px",
    flexDirection: "column",
  }),

  tabControl: css({
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "100px",
    ["div"]: {
      width: "290px",
    },
    ["label"]: {
      border: 0,
    },
    ["label:hover"]: {
      background: "#e7f1fd",
      borderRadius: "10px",
      border: 0,
    },
    [notMinScreen("lg")]: {
      marginLeft: "0px",
    },
  }),

  tableCells: css({
    ["table th:nth-of-type(1)"]: {
      width: "110px",
    },
    ["table th:nth-of-type(2)"]: {
      width: "90px",
    },
    ["table th:nth-of-type(3)"]: {
      width: "90px",
    },
    ["table th:nth-of-type(4)"]: {
      width: "80px",
    },
    ["table th:nth-of-type(5)"]: {
      width: "80px",
    },
    ["table th:nth-of-type(6)"]: {
      width: "65px",
    },
    ["table th:nth-of-type(7)"]: {
      width: "80px",
    },
  }),
};

//Context global variable
type contextType = {
  companyCode: string;
  stampByBelongOrg: number;
  stampOrgCode: string;
  stampOrgName: string;
  loginOrgCode: string;
  loginOrgName: string;
  useFaceStamp: number;
  useBarcodeStamp: number;
  barcodeId: string;
  staffName: string;
  staffCode: string;
  resultCurrentTime: CurrentTimeDomain;
  remote: boolean;
  setRemote: any;
};

export const TimeRecorderContext = createContext<contextType>({} as contextType);

const Recorder_v1: React.FC = () => {
  const { staffName } = useLoginUser();
  const history = useHistory();
  const showFaceStamp = sessionStorage.getItem("loginUser.useInsideLoginFaceRecorderStamp") === "true" ? true : false;
  const showGpsStamp = sessionStorage.getItem("loginUser.useGpsStamp") === "true" ? true : false;
  const showIdStamp = sessionStorage.getItem("loginUser.useInsideLoginBarcodeStamp") === "true" ? true : false;
  const isMobile = useIsMobile();

  const [tabs, setTabs] = useState<{ value: SelectType; display: string }[]>([]);

  useEffect(() => {
    const tempTabs = [];

    if (showIdStamp) {
      tempTabs.push({
        value: SelectType.id,
        display: "ID",
      });
    }
    if (showFaceStamp && !isMobile) {
      tempTabs.push({
        value: SelectType.face,
        display: "顔認証",
      });
    }
    if (showGpsStamp) {
      tempTabs.push({
        value: SelectType.gps,
        display: "GPS",
      });
    }

    setSelectType(tempTabs[0]?.value);
    setTabs(tempTabs);
  }, [showIdStamp, showFaceStamp, showGpsStamp, isMobile]);

  const [selectType, setSelectType] = useState<SelectType>(SelectType.id);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean | null>(null);

  const orgTreesOptions = useOrgTreesOptionsShopV1(true, functionCode.recorder, undefined, undefined);

  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const columns: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: () => "氏名",
        accessor: "staffName",
        id: "staffName",
        overFlow: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          const [imageOpen, setImageOpen] = useState(false);

          const { staffCode, staffName, stampTime, stampName } = cell.row.original;

          const imageURL = cell.row.original.photoFullpath;

          return (
            <FlexBox customStyle={css({ display: `${isIpad ? "block" : ""}` })}>
              <div>
                {imageURL && (
                  <React.Fragment>
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: `${isIpad ? "center" : ""}`,
                      }}
                    >
                      <img
                        src={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                        alt={imageURL}
                        width={55}
                        height={55}
                        style={{
                          cursor: "pointer",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                        onClick={() => setImageOpen(true)}
                      />
                      <ImageModel
                        open={imageOpen}
                        closeHandler={() => {
                          setImageOpen(false);
                        }}
                        imageURL={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                        descript={
                          staffCode +
                          "/" +
                          staffName +
                          "/" +
                          stampName +
                          "/" +
                          moment(stampTime).format("YYYY/MM/DD HH:mm")
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
              <TextTooltip
                text={staffName}
                customCss={css({
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: `${imageURL ? "70px" : "130px"}`,
                  whiteSpace: "nowrap",
                  display: "block",
                  margin: "0px auto",
                })}
              />
            </FlexBox>
          );
        },
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "スタッフコード",
        accessor: "staffCode",
      },
      {
        Header: "打刻方法",
        accessor: "stampModeName",
        id: "stampModeName",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          return (
            <React.Fragment>
              <div style={{ width: "100%" }}>{cell.row.original.stampModeName}</div>
              {cell.row.original.errorMessage !== null && cell.row.original.errorMessage !== "" && (
                <BodyText customStyle={css({ fontSize: "13px", color: "#333333" })}>
                  {cell.row.original.cardId}
                </BodyText>
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: () => "打刻種別",
        accessor: "errorMessage",
        id: "errorMessage",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          const { stampName, isRemote } = cell.row.original;
          if (cell.row.original.errorMessage === null || cell.row.original.errorMessage === "") {
            return (
              <div>
                {stampName === "出勤"
                  ? isRemote
                    ? stampName + `${companyCode === "HPo6wkyuze" ? " (在宅)" : " (テレワーク)"}`
                    : stampName + " (オフィス)"
                  : stampName}
              </div>
            );
          }
          if (cell.row.original.errorMessage !== null && cell.row.original.errorMessage !== "") {
            return (
              <span>
                <div css={css({ color: utilityColor.note })} data-tip={cell.row.original.errorMessage}>
                  {stampName}
                </div>
                <ReactTooltip html={true} />
              </span>
            );
          }
        },
      },
      {
        Header: () => "打刻時刻",
        accessor: "stampTime",
        id: "stampTime",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) =>
          cell.row.original.stampTime ? moment(cell.row.original.stampTime).format("YYYY/MM/DD HH:mm:ss") : "",
      },
    ],
    [],
  );

  const mobileColumns: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: () => "氏名",
        accessor: "staffName",
        id: "staffName",
        overFlow: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          const [imageOpen, setImageOpen] = useState(false);

          const { staffCode, staffName, stampTime, stampName } = cell.row.original;

          const imageURL = cell.row.original.photoFullpath;

          return (
            <FlexBox customStyle={css({ display: `${isIpad ? "block" : ""}` })}>
              <div>
                {imageURL && (
                  <React.Fragment>
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: `${isIpad ? "center" : ""}`,
                      }}
                    >
                      <img
                        src={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                        alt={imageURL}
                        width={55}
                        height={55}
                        style={{
                          cursor: "pointer",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                        onClick={() => setImageOpen(true)}
                      />
                      <ImageModel
                        open={imageOpen}
                        closeHandler={() => {
                          setImageOpen(false);
                        }}
                        imageURL={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                        descript={
                          staffCode +
                          "/" +
                          staffName +
                          "/" +
                          stampName +
                          "/" +
                          moment(stampTime).format("YYYY/MM/DD HH:mm")
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
              <TextTooltip
                text={staffName}
                customCss={css({
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: `${imageURL ? "70px" : "130px"}`,
                  whiteSpace: "nowrap",
                  display: "block",
                  margin: "0px auto",
                })}
              />
            </FlexBox>
          );
        },
      },

      {
        Header: () => "打刻種別",
        accessor: "errorMessage",
        id: "errorMessage",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) => {
          const { stampName, isRemote } = cell.row.original;
          if (cell.row.original.errorMessage === null || cell.row.original.errorMessage === "") {
            return (
              <div>
                {stampName === "出勤"
                  ? isRemote
                    ? stampName + `${companyCode === "HPo6wkyuze" ? " (在宅)" : " (テレワーク)"}`
                    : stampName + " (オフィス)"
                  : stampName}
              </div>
            );
          }
          if (cell.row.original.errorMessage !== null && cell.row.original.errorMessage !== "") {
            return (
              <span>
                <div css={css({ color: utilityColor.note })} data-tip={cell.row.original.errorMessage}>
                  {stampName}
                </div>
                <ReactTooltip html={true} />
              </span>
            );
          }
        },
      },
      {
        Header: () => "打刻時刻",
        accessor: "stampTime",
        id: "stampTime",
        Cell: (cell: { row: { isExpanded: boolean; original: any }; page: [] }) =>
          cell.row.original.stampTime ? moment(cell.row.original.stampTime).format("YYYY/MM/DD HH:mm:ss") : "",
      },
    ],
    [],
  );

  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.recorder);

  const [remote, setRemote] = useState(false);
  const {
    stampHistoryList,
    currentDay,
    timeNow,
    second,
    resultCurrentTime,
    selectedOrg,
    setSelectedOrg,
    fetchHistoryData,
    underFlag,
    setUnderFlag,
  } = useRecorder(setIsLoading);

  const dataTimes = {
    timeNow,
    currentDay,
    second,
    resultCurrentTime,
  };

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <React.Fragment>
      <TimeRecorderContext.Provider
        value={{
          companyCode: getCompanyCode(),
          stampByBelongOrg: 0,
          stampOrgCode: useOrgCode(),
          stampOrgName: useOrgName(),
          loginOrgCode: useOrgCode(),
          loginOrgName: useOrgName(),
          useFaceStamp: getFaceStamp(),
          useBarcodeStamp: getBarcodeStamp(),
          barcodeId: "",
          staffCode: getStaffCode(),
          staffName,
          resultCurrentTime,
          remote,
          setRemote,
        }}
      >
        <BlockUI blocking={isLoading}>
          <Appbar
            staffName={staffName}
            title="打刻"
            changeVisibleEvent={(visible: boolean) => {
              if (isMobile) {
                setShowMobileMenu(visible);
              } else {
                setIsComponentVisible(visible);
              }
            }}
          />
          <div css={styles.container}>
            {!isMobile && (
              <div css={styles.backHistory} onClick={() => history.goBack()}>
                <Icon type="back" color="#666" />
              </div>
            )}
            <FormTitle title="打刻" bold customStyle={css(isMobile ? { padding: "8px 0 0 0", margin: 0 } : {})} />
            <FormContents>
              <div css={styles.tabControl}>
                <TabControl
                  styleActive={{
                    backgroundColor: "#E7F1FD",
                    borderRadius: "10px",
                  }}
                  items={tabs}
                  setValue={setSelectType as (arg: string) => void}
                  currentValue={selectType}
                />
              </div>

              {selectType === SelectType.id && (
                <BarcodeRecorder
                  dataTimes={dataTimes}
                  setIsLoading={setIsLoading}
                  fetchHistoryData={fetchHistoryData}
                />
              )}

              {selectType === SelectType.face && !isMobile && showFaceStamp && (
                <FaceRecorder dataTimes={dataTimes} setIsLoading={setIsLoading} fetchHistoryData={fetchHistoryData} />
              )}

              {selectType === SelectType.gps && showGpsStamp && (
                <GPSRecorder dataTimes={dataTimes} setIsLoading={setIsLoading} fetchHistoryData={fetchHistoryData} />
              )}
            </FormContents>

            {detailRole.available === true && (
              <FormContents customStyle={css({ borderRadius: "3px" })}>
                <div
                  css={css({
                    marginLeft: "28px",
                    [notMinScreen("md")]: {
                      marginLeft: "0px",
                      padding: "8px",
                    },
                  })}
                >
                  <FormLabel label="打刻履歴" customStyle={css({ fontWeight: "bold" })} />
                  {isMobile ? (
                    <FlexBox
                      customStyle={css({
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "8px",
                      })}
                    >
                      <SelectForm
                        name="orgState"
                        value={String(selectedOrg)}
                        setValue={(arg: string) => {
                          setSelectedOrg(arg);
                        }}
                        options={orgTreesOptions}
                        width="100%"
                        label=""
                        widthLabel="100px"
                        marginRight="30px"
                      />
                      <Checkbox
                        id={"underFlag"}
                        name={"underFlag"}
                        label={"配下指定"}
                        value={`underFlag`}
                        checked={Boolean(underFlag)}
                        onChange={() => {
                          setUnderFlag(underFlag === 1 ? 0 : 1);
                        }}
                      />
                    </FlexBox>
                  ) : (
                    <FlexBox customStyle={css({ marginTop: "10px" })}>
                      <SelectForm
                        name="orgState"
                        value={String(selectedOrg)}
                        setValue={(arg: string) => {
                          setSelectedOrg(arg);
                        }}
                        options={orgTreesOptions}
                        width="800px"
                        label=""
                        widthLabel="100px"
                        marginRight="30px"
                      />
                      <Checkbox
                        id={"underFlag"}
                        name={"underFlag"}
                        label={"配下指定"}
                        value={`underFlag`}
                        checked={Boolean(underFlag)}
                        onChange={() => {
                          setUnderFlag(underFlag === 1 ? 0 : 1);
                        }}
                      />
                    </FlexBox>
                  )}
                </div>
                {isMobile ? (
                  <DataTableMobile
                    columns={
                      remote
                        ? mobileColumns
                        : mobileColumns.filter((item) => {
                            return item.accessor !== "isRemote";
                          })
                    }
                    data={stampHistoryList}
                    isGlobalFilter={true}
                    globalSearchStyle={css({
                      borderRadius: "3px",
                    })}
                  />
                ) : (
                  <div css={styles.tableCells}>
                    <DataTable
                      columns={
                        remote
                          ? columns
                          : columns.filter((item) => {
                              return item.accessor !== "isRemote";
                            })
                      }
                      data={stampHistoryList}
                      isGlobalFilter={true}
                      globalSearchStyle={css({
                        marginLeft: "28px",
                        borderRadius: "3px",
                      })}
                    />
                  </div>
                )}
              </FormContents>
            )}
          </div>
        </BlockUI>
        <div ref={ref} css={css({ position: "absolute", top: 0, right: "2rem" })}>
          {isComponentVisible && <StaffSettingPanel staffName={staffName} />}
        </div>
      </TimeRecorderContext.Provider>
      {isMobile && <MobileSidebar isMenuOpen={showMobileMenu} setMenuOpen={(open) => setShowMobileMenu(open)} />}
    </React.Fragment>
  );
};

export default Recorder_v1;
