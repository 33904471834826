import { useState, useEffect, useReducer } from 'react';
import { getSpecificStaff, postStaffPhoto } from 'api/staff';
import useToastNotification from 'hooks/useToastNotification';
import { useOrgTreesOptionsShopV1 } from 'hooks/useOrgTreesOptions';
import { useStaffsForOrgCodeOptions } from 'hooks/useStaffsOptions';
import { useDispatch } from 'react-redux';
import { uiAppBarActions } from 'modules/ui/appBar';
import { Face, State } from './type';
import { reducer } from './reducer'
import { types } from './actions'
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const defaultFace = {
  blob: '',
  description: ''
}

export interface SupportStaffPhoto{ 
  photo : Blob;
  description: string;
  supportStaffId: string
}

export const defaultFaceList = [
  defaultFace,
  defaultFace,
  defaultFace,
  defaultFace,
];
  
export const defaultState: State = {
  listFace: defaultFaceList,
  openModalChooseAction: false,
  indexAction: 0,
  tmpImageModal: undefined,
  openModalCrop: false,
  openCamera: false,
  enableButtonCap: true,
  showMessage: false,
}



export const useMasterShopFaceRegistrationFormV1 = () => {
  // redux
  const dispatchRedux = useDispatch();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterShopFaceRegistration);
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const orgTreesOptions = useOrgTreesOptionsShopV1(false, functionCode.masterShopFaceRegistration, undefined, undefined);
  const [staffCode, setStaffCode] = useState<any>(sessionStorage.getItem('loginUser.staffCode') || '');
  const staffsForOrgCodeOptions = useStaffsForOrgCodeOptions(orgCode, false);
  const { successNotification, errorNotification } = useToastNotification();
  const [initFaceList, setInitFaceList] = useState(defaultFaceList);
  const [objectPhotoSubmit, setObjectPhotoSubmit] = useState<any>({});
  const [staff, setStaff] = useState();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const getObjectListFace = async (listFace: Array<Face>) => {
    let blobStaff = null;
    let objectStaff: any = {};
    let [firstFace, ...dependFace] = listFace;
    dependFace = dependFace.filter((item) => item.blob !== "");

    if(firstFace.blob){
      blobStaff = await fetch(firstFace.blob).then(r => r.blob());
    }
    var objectSubmit = () => {
      return Promise.all(dependFace.map(async (face, index): Promise<any> => {
        let blob = null;
        blob = await fetch(face.blob).then(r => r.blob());
        // supportStaffPhoto.push({
        //   `photo${index + 1}` : blob,
        //   description: face.description,
        //   supportStaffId: ''
        // })
        
        objectStaff[`supportStaffPhoto${index + 1}`] = blob;
        objectStaff[`supportStaffDescription${index + 1}`] = face.description || "";
      }));
    }
    await objectSubmit();

    objectStaff = {
      ...objectStaff,
      nameplate: '1.jpg',
      photo1: blobStaff,
    }
    return objectStaff;  
  }

  const getBlobFromUrl = async (response: any) => {
    let blob: Blob;
    let tmpListFace: Array<Face> = [];
    let listImage = []
    if(response.nameplate){
      let image = `${process.env.REACT_APP_PHOTO_BASE_URL}/${response.id.companyCode}/${staffCode}/${response.nameplate || "1.jpg"}?${Date.now()}`;
      listImage.push({
        image,
        description: ''
      });
    }else{
      listImage.push({
        image:'',
        description: ''
      });
    }
    response.supportStaff.map((staff: any) => {
      let imageSupport = `${process.env.REACT_APP_PHOTO_BASE_URL}/${response.id.companyCode}/${staffCode}/support_staff/${staff.fileNo+ '.jpg'}?${Date.now()}`;
      listImage.push({
        image: imageSupport,
        description: staff.description || ""
      });
    })

    if(listImage.length < 5){
      listImage = listImage.concat([{
        image: '',
        description: ''
      },{
        image: '',
        description: ''
      },{
        image: '',
        description: ''
      },{
        image: '',
        description: ''
      }]).slice(0,4);
    }

    // console.log('listImage', listImage);
    
    let data = await Promise.all(
      listImage.map((image) => {
        if(image.image){
          return fetch(image.image)
          .then(response => {
            if(response.ok === false){
              return null;
            }
            return response.blob();
          })
          .then(function(myBlob) {
            var objectURL = URL.createObjectURL(myBlob);
            return {
              blob: objectURL,
              description: image.description || ""
            };
          })
          .catch((error: any) => {
            return {
              blob: '',
              description: ''
            };
          });
        }else{
          return {
            blob: '',
            description: ''
          }
        }
      })
    );
    setInitFaceList(data);
    dispatch({ type: types.SET_LIST_FACE, payload: data});
  }
  const handleSubmit = async () => {
    let objPhoto = await getObjectListFace(state.listFace);
    setIsLoading(true);
    if (!staffCode) {
      setIsLoading(false);
      errorNotification('スタッフを選択してください。');
      return;
    }
    let convertData: any = {};
    convertData = {
      'id.staffCode': staffCode,
      personalRole: staff?.personalRole,
      shopRole: staff?.shopRole,
      hqRole: staff?.hqRole,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      useSupportStaff: true,
    };
    convertData = {
      ...convertData,
      ...objPhoto
    }
    console.log('convertData', convertData);
    try {
      const response = await postStaffPhoto(convertData);
      setIsLoading(false);
      if (!response.errors) {
        successNotification('登録しました。');
        let image = `${process.env.REACT_APP_PHOTO_BASE_URL}/${response.staff.id.companyCode}/${response.staff.id.staffCode}/${response.staff.nameplate || "1.jpg"}?${Date.now()}`;
        if(staffCode === sessionStorage.getItem('loginUser.staffCode')){
          dispatchRedux(uiAppBarActions.changeAvatar(image));
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        const err = error.response.data.message.replace(/<br>/gi, '\n');
        errorNotification(err);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  useEffect(() => {
    if (staffCode) {
      setIsLoading(true);
      getSpecificStaff(staffCode).then((response: any) => {
        setStaff(response);
        getBlobFromUrl(response);
        setIsLoading(false);
        if(response.nameplate){
          getBlobFromUrl(response)
        }else{
          dispatch({ type: types.SET_LIST_FACE, payload: defaultFaceList});
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffCode]);

  return {
    errorNotification,
    companyCode,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    staffCode,
    setStaffCode,
    staffsForOrgCodeOptions,
    isLoading,
    setIsLoading,
    handleSubmit,
    state,
    dispatch,
    initFaceList,
    detailRole
  };
};


export default useMasterShopFaceRegistrationFormV1;
