import ApiClient from "api/ApiClient";
import { IAttendAchievementError } from "./interface";

export const getAttendAchievementError = async (staffCode: string, orgCode: string, targetDateFrom: string, targetDateTo: string, searchType: number, 
  toSearchOvertimeAlert: boolean,
  toSearchHolidayworkAlert: boolean,
  toSearchRecessAlert: boolean,
  toSearchContinuousWorkAlert: boolean,
  toSearchUnder18MidnightWorkAlert: boolean,
  toSearchForeignerAlert: boolean,
  ): Promise<IAttendAchievementError[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  let params = { staffCode, orgCode, targetDateFrom, targetDateTo, searchType};
  if (toSearchOvertimeAlert && searchType){
    params = {...params, ...{toSearchOvertimeAlert}}
  }
  if (toSearchHolidayworkAlert && searchType){
    params = {...params, ...{toSearchHolidayworkAlert}}
  }
  if (toSearchRecessAlert && searchType){
    params = {...params, ...{toSearchRecessAlert}}
  }
  if (toSearchContinuousWorkAlert && searchType){
    params = {...params, ...{toSearchContinuousWorkAlert}}
  }
  if (toSearchUnder18MidnightWorkAlert && searchType){
    params = {...params, ...{toSearchUnder18MidnightWorkAlert}}
  }
  if (toSearchForeignerAlert && searchType){
    params = {...params, ...{toSearchForeignerAlert}}
  }
  
  const {data} = await ApiClient.get(`/v1/attendAchievementError/${companyCode}`, params);

  return data;
};

export const getAttendAchievementErrorV3 = async (staffCode: string, orgCode: string, targetDateFrom: string, targetDateTo: string, searchType: number, 
  toSearchOvertimeAlert: boolean,
  toSearchHolidayworkAlert: boolean,
  toSearchRecessAlert: boolean,
  toSearchContinuousWorkAlert: boolean,
  toSearchUnder18MidnightWorkAlert: boolean,
  toSearchForeignerAlert: boolean,
  toSearchIntervalAlert: boolean,
  underFlag: number
  ): Promise<IAttendAchievementError[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCodeLogin = sessionStorage.getItem('loginUser.staffCode') || '';
  const orgCodeLogin = sessionStorage.getItem('loginUser.orgCode') || '';
  const allOrganize = sessionStorage.getItem('loginUser.allOrganizationAccess') || '';
  let params = {
    staffCodeLogin,
    orgCodeLogin,
    allOrganize,
    staffCode,
    orgCode, 
    targetDateFrom, 
    targetDateTo, 
    searchType,
    underFlag
  };
  if (toSearchOvertimeAlert && searchType){
    params = {...params, ...{toSearchOvertimeAlert}}
  }
  if (toSearchHolidayworkAlert && searchType){
    params = {...params, ...{toSearchHolidayworkAlert}}
  }
  if (toSearchRecessAlert && searchType){
    params = {...params, ...{toSearchRecessAlert}}
  }
  if (toSearchContinuousWorkAlert && searchType){
    params = {...params, ...{toSearchContinuousWorkAlert}}
  }
  if (toSearchUnder18MidnightWorkAlert && searchType){
    params = {...params, ...{toSearchUnder18MidnightWorkAlert}}
  }
  if (toSearchForeignerAlert && searchType){
    params = {...params, ...{toSearchForeignerAlert}}
  }
  if (toSearchIntervalAlert && searchType){
    params = {...params, ...{toSearchIntervalAlert}}
  }
  
  const {data} = await ApiClient.get(`/v3/attendAchievementError/${companyCode}`, params);

  return data;
};