import React, { SVGProps } from 'react';

const MBOCloud: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.24 0C1 0 0 1 0 2.24V35.42C0 36.66 1 37.66 2.24 37.66C3.48 37.66 4.48 36.66 4.48 35.42V2.24C4.48 1 3.47 0 2.24 0Z" fill="#003993"/>
    <path d="M31.751 1.86967C31.011 1.48967 30.121 1.53967 29.431 2.01967C28.081 2.95967 26.611 3.44967 25.171 3.44967H25.161C23.701 3.44967 22.481 2.87967 21.111 2.01967C19.531 1.02967 17.761 0.439667 16.021 0.299667C13.221 0.079667 10.381 0.999667 8.41096 2.78967C7.49096 3.61967 7.42096 5.03967 8.25096 5.95967C9.08096 6.87967 10.501 6.94967 11.411 6.11967C12.451 5.17967 14.101 4.64967 15.641 4.77967C16.671 4.86967 17.781 5.23967 18.751 5.84967C20.421 6.88967 22.501 7.98967 25.181 7.94967C26.291 7.94967 27.401 7.77967 28.481 7.43967V19.5597C27.411 20.1197 26.281 20.4097 25.181 20.4097H25.171C23.711 20.4097 22.491 19.8397 21.121 18.9797C19.541 17.9897 17.781 17.3997 16.031 17.2597C13.231 17.0297 10.401 17.9597 8.42096 19.7397C7.50096 20.5697 7.43096 21.9897 8.26096 22.9097C9.09096 23.8297 10.511 23.8997 11.421 23.0697C12.461 22.1297 14.121 21.5997 15.651 21.7297C16.681 21.8197 17.791 22.1897 18.761 22.7997C20.431 23.8397 22.501 24.8997 25.181 24.8997C25.191 24.8997 25.191 24.8997 25.201 24.8997C27.561 24.8997 29.921 24.1197 32.021 22.6697C32.621 22.2497 32.981 21.5597 32.981 20.8297V3.85967C32.951 3.02967 32.491 2.26967 31.751 1.86967Z" fill="#007BC3"/>
  </svg>
);

export default MBOCloud;
