import ApiClient from './ApiClient';
import { stringify } from 'querystring';

export const getAchievementCsvLayout = async ():
 Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/achievementCsv/outputLayout/${companyCode}`, {});

  return response.data;
};

export const exportAchievementCsvOutput = async (
  params: any
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.get(`/v1/achievementCsv/csvOutput/${companyCode}`, params, {});
};
