/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { useState, useEffect, useContext, createContext, useMemo } from "react";

import { searchDate } from "api/achievementConfirm";
import {
  getApprovedTransferList,
  getApprovedStampList,
  getApprovedHolidayList,
  getApprovedOverTimeList,
  getApprovedHolidayWorkList,
  getApprovedAgreement36List,
  getApprovedWorkScheduleChangeList,
  getApprovedStockPaidHolidayList,
  getRejectedStampList,
  getRejectedHolidayList,
  getRejectedOverTimeList,
  getRejectedHolidayWorkList,
  getRejectedTransferList,
  getRejectedAgreement36List,
  getRejectedWorkScheduleChangeList,
  getRejectedStockPaidHolidayList,
  getApplyingStampList,
  getApplyingHolidayList,
  getApplyingHolidayWorkList,
  getApplyingOverTimeList,
  getApplyingAgreement36List,
  getApplyingWorkScheduleChangeList,
  getApplyingTransferList,
  getApplyingStockPaidList,
  getProcessingStampList,
  getProcessingHolidayList,
  getProcessingOverTimeList,
  getProcessingHolidayWorkList,
  getProcessingTransferList,
  getProcessingAgreement36List,
  getProcessingWorkScheduleChangeList,
  getProcessingStockPaidHolidayList,
  getApplyingAttendAllowanceList,
  getProcessingAttendAllowanceList,
  getApprovedAttendAllowanceList,
  getRejectedAttendAllowanceList,
} from "./api";
import ApprovedTransferDomain from "./domain/approvedTransferDomain";
import ApprovedStampDomain from "./domain/approvedStampDomain";
import ApprovedHolidayDomain from "./domain/approvedHolidayDomain";
import ApprovedOvertimeDomain from "./domain/approvedOvertimeDomain";
import ApprovedAttendAllowanceDomain from "components/organismos/master/attend/AttendApplicationApprovalV3/domain/approvedAttendAllowanceDomain";
import Approved36AgreementDomain from "./domain/approved36AgreementDomain";
import ApprovedHolidayWorkDomain from "./domain/approvedHolidayWorkDomain";
import ApprovedWorkScheduleChangeDomain from "./domain/approvedWorkScheduleChange";
import RejectedStampDomain from "./domain/rejectedStampDomain";
import RejectedHolidayDomain from "./domain/rejectedHolidayDomain";
import RejectedOvertimeDomain from "./domain/rejectedOvertimeDomain";
import RejectedAttendAllowanceDomain from "components/organismos/master/attend/AttendApplicationApprovalV3/domain/rejectedAttendAllowanceDomain";
import Rejected36AgreementDomain from "./domain/rejected36AgreementDomain";
import RejectedHolidayWorkDomain from "./domain/rejectedHolidayWorkDomain";
import RejectedTransferDomain from "./domain/rejectedTransferDomain";
import RejectedWorkScheduleChangeDomain from "./domain/rejectedWorkScheduleChange";
import ApplyingStampDomain from "./domain/applyingStampDomain";
import ApplyingHolidayDomain from "./domain/applyingHolidayDomain";
import ApplyingOvertimeDomain from "./domain/applyingOvertimeDomain";
import ApplyingAttendAllowanceDomain from "components/organismos/master/attend/AttendApplicationApprovalV3/domain/applyingAttendAllowanceDomain";
import Applying36AgreementDomain from "./domain/applying36AgreementDomain";
import ApplyingWorkScheduleChangeAppDomain from "./domain/applyingWorkScheduleChangeAppDomain";
import ApplyingHolidayWorkDomain from "./domain/applyingHolidayWorkDomain";
import ApplyingTransferDomain from "./domain/applyingTransferDomain";
import ProcessingStampDomain from "./domain/processingStampDomain";
import ProcessingHolidayDomain from "./domain/processingHolidayDomain";
import ProcessingOvertimeDomain from "./domain/processingOvertimeDomain";
import ProcessingAttendAllowanceDomain from "components/organismos/master/attend/AttendApplicationApprovalV3/domain/processingAttendAllowanceDomain";
import ProcessingHolidayWorkDomain from "./domain/processingHolidayWorkDomain";
import ProcessingTransferDomain from "./domain/processingTransferDomain";
import Processing36AgreementDomain from "./domain/processing36AgreementDomain";
import ProcessingWorkScheduleChangeAppDomain from "./domain/processingWorkScheduleChangeAppDomain";
import ProcessingStockPaidHolidayDomain from "./domain/processingStockPaidHolidayDomain";
import { IStamp, IApplicationApproval } from "./interface";
import { getReasonList } from "api/stampModification";
import { OptionType } from "components/atoms/Select";
import useToastNotification from "hooks/useToastNotification";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import { setInitClosingDate } from "../setInitClosingDate";
import ApplyingStockPaidDomain from "./domain/applyingStockPaidDomain";
import ApprovedStockPaidDomain from "./domain/approvedStockPaidDomain";
import RejectedStockPaidDomain from "./domain/rejectedStockPaidDomain";
import { renderError } from "utility/renderMessage";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { useParams } from "react-router-dom";

export const attendApplicationApprovalContext = createContext<IApplicationApproval>({} as IApplicationApproval);
export const useAttendApplicationApprovalContext = (): IApplicationApproval => {
  const context = useContext(attendApplicationApprovalContext);
  return context;
};

export const useInitialApplicationApproval = () => {
  const params = useParams<any>();

  const initDateFrom = sessionStorage.getItem("attendApplicationApproval.targetDateFrom");
  const initDateTo = sessionStorage.getItem("attendApplicationApproval.targetDateTo");
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("attendApplicationApproval.targetDateFrom");
      sessionStorage.removeItem("attendApplicationApproval.targetDateTo");
    };
  }, []);
  const closingDate = useSelector((state: Store) => state.loginUser.loginUser?.closingDate);
  const { firstDayStr, lastDayStr } = setInitClosingDate(closingDate, new Date());
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(
    new Date(sessionStorage.getItem("approvalV3TargetDayFrom") || params.targetDateFrom || firstDayStr),
  );
  const [targetDateTo, setTargetDateTo] = useState<Date>(
    new Date(sessionStorage.getItem("approvalV3TargetDayTo") || params.targetDateTo || lastDayStr),
  );
  const [targetMonth, setTargetMonth] = useState<Date>(
    new Date(sessionStorage.getItem("approvalV3TargetMonth") || new Date(lastDayStr)),
  );
  const [viewPeriod, setViewPeriod] = useState(sessionStorage.getItem("applicationListPeriod") || "dateAll");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const [reasonOptionList, setReasonOptionList] = useState<Array<OptionType>>([]);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.attendApplicationApproval);

  // Applying tab
  const [applyingStampList, setApplyingStampList] = useState<ApplyingStampDomain[]>([]);
  const [applyingHolidayList, setApplyingHolidayList] = useState<ApplyingHolidayDomain[]>([]);
  const [applyingOvertimeList, setApplyingOvertimeList] = useState<ApplyingOvertimeDomain[]>([]);
  const [applyingAttendAllowanceList, setApplyingAttendAllowanceList] = useState<ApplyingAttendAllowanceDomain[]>([]);
  const [applyingHolidayWorkList, setApplyingHolidayWorkList] = useState<ApplyingHolidayWorkDomain[]>([]);
  const [applyingTransferList, setApplyingTransferList] = useState<ApplyingTransferDomain[]>([]);
  const [applyingAgreement36List, setApplyingAgreement36List] = useState<Applying36AgreementDomain[]>([]);
  const [applyingWorkScheduleChangeList, setApplyingWorkScheduleChangeList] = useState<
    ApplyingWorkScheduleChangeAppDomain[]
  >([]);
  const [applyingStockPaidList, setApplyingStockPaidList] = useState<ApplyingStockPaidDomain[]>([]);
  const [applyingReasonId, setApplyingReasonId] = useState<string>("all");

  // Processing tab
  const [processingStampList, setProcessingStampList] = useState<ProcessingStampDomain[]>([]);
  const [processingHolidayList, setProcessingHolidayList] = useState<ProcessingHolidayDomain[]>([]);
  const [processingOvertimeList, setProcessingOvertimeList] = useState<ProcessingOvertimeDomain[]>([]);
  const [processingAttendAllowanceList, setProcessingAttendAllowanceList] = useState<
    ProcessingAttendAllowanceDomain[]
  >([]);
  const [processingHolidayWorkList, setProcessingHolidayWorkList] = useState<ProcessingHolidayWorkDomain[]>([]);
  const [processingTransferList, setProcessingTransferList] = useState<ProcessingTransferDomain[]>([]);
  const [processingAgreement36List, setProcessingAgreement36List] = useState<Processing36AgreementDomain[]>([]);
  const [processingWorkScheduleChangeList, setProcessingWorkScheduleChangeList] = useState<
    ProcessingWorkScheduleChangeAppDomain[]
  >([]);
  const [processingStockPaidHolidayList, setProcessingStockPaidHolidayList] = useState<
    ProcessingStockPaidHolidayDomain[]
  >([]);
  const [processingReasonId, setProcessingReasonId] = useState<string>("all");

  // Approved tab
  const [approvedStampList, setApprovedStampList] = useState<ApprovedStampDomain[]>([]);
  const [approvedHolidayList, setApprovedHolidayList] = useState<ApprovedHolidayDomain[]>([]);
  const [approvedOvertimeList, setApprovedOvertimeList] = useState<ApprovedOvertimeDomain[]>([]);
  const [approvedAttendAllowanceList, setApprovedAttendAllowanceList] = useState<ApprovedAttendAllowanceDomain[]>([]);
  const [approvedHolidayWorkList, setApprovedHolidayWorkList] = useState<ApprovedHolidayWorkDomain[]>([]);
  const [approvedTransferList, setApprovedTransferList] = useState<ApprovedTransferDomain[]>([]);
  const [approvedAgreement36List, setApprovedAgreement36List] = useState<Approved36AgreementDomain[]>([]);
  const [approvedWorkScheduleChangeList, setApprovedWorkScheduleChangeList] = useState<
    ApprovedWorkScheduleChangeDomain[]
  >([]);
  const [approvedStockPaidList, setApprovedStockPaidList] = useState<ApprovedStockPaidDomain[]>([]);
  const [approvedReasonId, setApprovedReasonId] = useState<string>("all");

  // Rejected tab
  const [rejectedStampList, setRejectedStampList] = useState<RejectedStampDomain[]>([]);
  const [rejectedHolidayList, setRejectedHolidayList] = useState<RejectedHolidayDomain[]>([]);
  const [rejectedOvertimeList, setRejectedOvertimeList] = useState<RejectedOvertimeDomain[]>([]);
  const [rejectedAttendAllowanceList, setRejectedAttendAllowanceList] = useState<RejectedAttendAllowanceDomain[]>([]);
  const [rejectedHolidayWorkList, setRejectedHolidayWorkList] = useState<RejectedHolidayWorkDomain[]>([]);
  const [rejectedTransferList, setRejectedTransferList] = useState<RejectedTransferDomain[]>([]);
  const [rejectedAgreement36List, setRejectedAgreement36List] = useState<Rejected36AgreementDomain[]>([]);
  const [rejectedStockPaidList, setRejectedStockPaidList] = useState<RejectedStockPaidDomain[]>([]);
  const [rejectedWorkScheduleChangeList, setRejectedWorkScheduleChangeList] = useState<
    RejectedWorkScheduleChangeDomain[]
  >([]);
  const [rejectedReasonId, setRejectedReasonId] = useState<string>("all");

  const [countApplying, setCountApplying] = useState<number>(0);
  const [countApproval, setCountApproval] = useState<number>(0);
  const [countReject, setCountReject] = useState<number>(0);
  const [countProcessing, setCountProcessing] = useState<number>(0);

  const [typeReason, setTypeReason] = useState<string>("");
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [flag, setflag] = useState<string>("applying");

  const { errorNotification } = useToastNotification();
  const type1_2 = ["applying", "processing"];
  const type3_4 = ["approved", "rejected"];

  useMemo(async () => {
    let userPaymentDay = []; // empty on subsequent change
    if (isFirstRender) {
      userPaymentDay = await searchDate({ staffCode });
      if (initDateFrom) {
        userPaymentDay[0] = initDateFrom;
      }
      if (initDateTo) {
        userPaymentDay[1] = initDateTo;
      }
      // setTargetDateFrom(moment(userPaymentDay[0]).toDate());
      // setTargetDateTo(moment(userPaymentDay[1]).toDate());
      setIsFirstRender(false);
    }
  }, []);

  useEffect(() => {
    const viewPeriod = sessionStorage.getItem("applicationListPeriod");

    if (!viewPeriod && params.viewPeriod !== undefined) {
      sessionStorage.setItem("applicationListPeriod", params.viewPeriod);
      setViewPeriod(params.viewPeriod);
    }
  }, [params]);

  const fetchData = async (flag: string) => {
    if (!isFirstRender) {
      setIsLoading(true);

      const params = {
        staffCode,
        targetDateFrom:
          viewPeriod === "dateAll" && type1_2.includes(flag) ? "" : moment(targetDateFrom).format("YYYY-MM-DD"),
        targetDateTo:
          viewPeriod === "dateAll" && type1_2.includes(flag) ? "" : moment(targetDateTo).format("YYYY-MM-DD"),
      };

      const paramStamp = {
        ...params,
        reasonId: applyingReasonId ? applyingReasonId : "all",
      };

      switch (flag) {
        case "applying":
          fetchApplyingList(params, paramStamp);
          break;
        case "processing":
          fetchProcessList(params, paramStamp);
          break;
        case "approved":
          fetchApprovedList(params, paramStamp);
          break;
        case "rejected":
          fetchRejectedList(params, paramStamp);
          break;
        default:
          break;
      }
    }
  };
  // Applying
  const fetchApplyingList = async (params: any, paramStamp: any) => {
    try {
      await Promise.all([
        getApplyingStampList(paramStamp),
        getApplyingHolidayList(params),
        getApplyingOverTimeList(params),
        getApplyingAttendAllowanceList(params),
        getApplyingAgreement36List(params),
        getApplyingHolidayWorkList(params),
        getApplyingTransferList(params),
        getApplyingWorkScheduleChangeList(params),
        getApplyingStockPaidList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setApplyingStampList(stampRes.map((data) => new ApplyingStampDomain(data)));
            setApplyingHolidayList(holidayRes.map((data) => new ApplyingHolidayDomain(data)));
            setApplyingOvertimeList(overTimeRes.map((data) => new ApplyingOvertimeDomain(data)));
            setApplyingAttendAllowanceList(attendAllowanceRes.map((data) => new ApplyingAttendAllowanceDomain(data)));
            setApplyingAgreement36List(agreement36Res.map((data) => new Applying36AgreementDomain(data)));
            setApplyingHolidayWorkList(holidayWorkRes.map((data) => new ApplyingHolidayWorkDomain(data)));
            setApplyingTransferList(transferRes.map((data) => new ApplyingTransferDomain(data)));
            setApplyingWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new ApplyingWorkScheduleChangeAppDomain(data)),
            );
            setApplyingStockPaidList(stockPaidRef.map((data) => new ApplyingStockPaidDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });

      if (applyingStampList.length === 0) {
        getReasonList(1).then((response: any) => {
          if (response && response.length) {
            let tmpReason = response.map((obj: any) => ({
              value: obj.reasonId === null ? "" : obj.reasonId,
              label: obj.applicationReason === null ? "" : obj.applicationReason,
            }));
            tmpReason.unshift({ value: "all", label: "全て" });
            setReasonOptionList(tmpReason);
          }
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Process
  const fetchProcessList = async (params: any, paramStamp: any) => {
    try {
      await Promise.all([
        getProcessingStampList(paramStamp),
        getProcessingHolidayList(params),
        getProcessingOverTimeList(params),
        getProcessingAttendAllowanceList(params),
        getProcessingAgreement36List(params),
        getProcessingHolidayWorkList(params),
        getProcessingTransferList(params),
        getProcessingWorkScheduleChangeList(params),
        getProcessingStockPaidHolidayList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setProcessingStampList(stampRes.map((data) => new ProcessingStampDomain(data)));
            setProcessingHolidayList(holidayRes.map((data) => new ProcessingHolidayDomain(data)));
            setProcessingOvertimeList(overTimeRes.map((data) => new ProcessingOvertimeDomain(data)));
            setProcessingAttendAllowanceList(
              attendAllowanceRes.map((data) => new ProcessingAttendAllowanceDomain(data)),
            );
            setProcessingAgreement36List(agreement36Res.map((data) => new Processing36AgreementDomain(data)));
            setProcessingHolidayWorkList(holidayWorkRes.map((data) => new ProcessingHolidayWorkDomain(data)));
            setProcessingTransferList(transferRes.map((data) => new ProcessingTransferDomain(data)));
            setProcessingWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new ProcessingWorkScheduleChangeAppDomain(data)),
            );
            setProcessingStockPaidHolidayList(stockPaidRef.map((data) => new ProcessingStockPaidHolidayDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });
    } finally {
      setIsLoading(false);
    }
  };

  // Approved
  const fetchApprovedList = async (params: any, paramStamp: any) => {
    try {
      await Promise.all([
        getApprovedStampList(paramStamp),
        getApprovedHolidayList(params),
        getApprovedOverTimeList(params),
        getApprovedAttendAllowanceList(params),
        getApprovedAgreement36List(params),
        getApprovedHolidayWorkList(params),
        getApprovedTransferList(params),
        getApprovedWorkScheduleChangeList(params),
        getApprovedStockPaidHolidayList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setApprovedStampList(stampRes.map((data) => new ApprovedStampDomain(data)));
            setApprovedHolidayList(holidayRes.map((data) => new ApprovedHolidayDomain(data)));
            setApprovedOvertimeList(overTimeRes.map((data) => new ApprovedOvertimeDomain(data)));
            setApprovedAttendAllowanceList(attendAllowanceRes.map((data) => new ApprovedAttendAllowanceDomain(data)));
            setApprovedAgreement36List(agreement36Res.map((data) => new Approved36AgreementDomain(data)));
            setApprovedHolidayWorkList(holidayWorkRes.map((data) => new ApprovedHolidayWorkDomain(data)));
            setApprovedTransferList(transferRes.map((data) => new ApprovedTransferDomain(data)));
            setApprovedWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new ApprovedWorkScheduleChangeDomain(data)),
            );
            setApprovedStockPaidList(stockPaidRef.map((data) => new ApprovedStockPaidDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });
    } finally {
      setIsLoading(false);
    }
  };

  // Rejected
  const fetchRejectedList = async (params: any, paramStamp: any) => {
    try {
      await Promise.all([
        getRejectedStampList(paramStamp),
        getRejectedHolidayList(params),
        getRejectedOverTimeList(params),
        getRejectedAttendAllowanceList(params),
        getRejectedAgreement36List(params),
        getRejectedHolidayWorkList(params),
        getRejectedTransferList(params),
        getRejectedWorkScheduleChangeList(params),
        getRejectedStockPaidHolidayList(params),
      ])
        .then(
          ([
            stampRes,
            holidayRes,
            overTimeRes,
            attendAllowanceRes,
            agreement36Res,
            holidayWorkRes,
            transferRes,
            workScheduleChangeRef,
            stockPaidRef,
          ]) => {
            setRejectedStampList(stampRes.map((data) => new RejectedStampDomain(data)));
            setRejectedHolidayList(holidayRes.map((data) => new RejectedHolidayDomain(data)));
            setRejectedOvertimeList(overTimeRes.map((data) => new RejectedOvertimeDomain(data)));
            setRejectedAttendAllowanceList(attendAllowanceRes.map((data) => new RejectedAttendAllowanceDomain(data)));
            setRejectedAgreement36List(agreement36Res.map((data) => new Rejected36AgreementDomain(data)));
            setRejectedHolidayWorkList(holidayWorkRes.map((data) => new RejectedHolidayWorkDomain(data)));
            setRejectedTransferList(transferRes.map((data) => new RejectedTransferDomain(data)));
            setRejectedWorkScheduleChangeList(
              workScheduleChangeRef.map((data) => new RejectedWorkScheduleChangeDomain(data)),
            );
            setRejectedStockPaidList(stockPaidRef.map((data) => new RejectedStockPaidDomain(data)));
          },
        )
        .catch((err) => {
          const mess = renderError(err);
          errorNotification(mess);
        });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(flag);
  }, [isFirstRender, targetDateFrom, targetDateTo, flag, viewPeriod, targetMonth]);

  const filterReasonStamp = async (type: string, reasonId: string) => {
    const params = {
      staffCode,
      targetDateFrom:
        viewPeriod === "dateAll" && type1_2.includes(flag) ? "" : moment(targetDateFrom).format("YYYY-MM-DD"),
      targetDateTo:
        viewPeriod === "dateAll" && type1_2.includes(flag) ? "" : moment(targetDateTo).format("YYYY-MM-DD"),
      reasonId: reasonId,
    };
    setIsLoading(true);
    switch (type) {
      case "apply":
        await getApplyingStampList(params)
          .then((response: any) => {
            setApplyingStampList(response.map((data: IStamp) => new ApplyingStampDomain(data)));
          })
          .catch((err) => {
            setIsLoading(true);
            const mess = renderError(err);
            errorNotification(mess);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case "processing":
        await getProcessingStampList(params)
          .then((response: any) => {
            setProcessingStampList(response.map((data: IStamp) => new ProcessingStampDomain(data)));
          })
          .catch((err) => {
            setIsLoading(true);
            const mess = renderError(err);
            errorNotification(mess);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case "approved":
        await getApprovedStampList(params)
          .then((response: any) => {
            setApprovedStampList(response.map((data: IStamp) => new ApprovedStampDomain(data)));
          })
          .catch((err) => {
            setIsLoading(true);
            const mess = renderError(err);
            errorNotification(mess);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case "reject":
        await getRejectedStampList(params)
          .then((response: any) => {
            setRejectedStampList(response.map((data: IStamp) => new RejectedStampDomain(data)));
          })
          .catch((err) => {
            setIsLoading(true);
            const mess = renderError(err);
            errorNotification(mess);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    filterReasonStamp(typeReason, applyingReasonId);
  }, [applyingReasonId]);

  useEffect(() => {
    filterReasonStamp(typeReason, processingReasonId);
  }, [processingReasonId]);

  useEffect(() => {
    filterReasonStamp(typeReason, approvedReasonId);
  }, [approvedReasonId]);

  useEffect(() => {
    filterReasonStamp(typeReason, rejectedReasonId);
  }, [rejectedReasonId]);

  return {
    initDateFrom,
    initDateTo,
    staffCode,

    isLoading,
    setIsLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    viewPeriod,
    setViewPeriod,

    applyingReasonId,
    setApplyingReasonId,
    approvedReasonId,
    setApprovedReasonId,
    rejectedReasonId,
    setRejectedReasonId,
    reasonOptionList,
    setReasonOptionList,
    processingReasonId,
    setProcessingReasonId,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingAttendAllowanceList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,
    applyingStockPaidList,

    processingStampList,
    processingHolidayList,
    processingTransferList,
    processingAgreement36List,
    processingWorkScheduleChangeList,
    processingOvertimeList,
    processingAttendAllowanceList,
    processingHolidayWorkList,
    processingStockPaidHolidayList,

    setProcessingHolidayWorkList,
    setProcessingTransferList,
    setProcessingAgreement36List,
    setProcessingWorkScheduleChangeList,
    setProcessingHolidayList,
    setProcessingOvertimeList,
    setProcessingAttendAllowanceList,

    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingAttendAllowanceList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,
    setApplyingStockPaidList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedAttendAllowanceList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,
    approvedStockPaidList,

    setApprovedStampList,
    setApprovedHolidayList,
    setApprovedOvertimeList,
    setApprovedAttendAllowanceList,
    setApprovedHolidayWorkList,
    setApprovedTransferList,
    setApprovedAgreement36List,
    setApprovedWorkScheduleChangeList,
    setApprovedStockPaidList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedAttendAllowanceList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,
    rejectedStockPaidList,

    setRejectedStampList,
    setRejectedHolidayList,
    setRejectedOvertimeList,
    setRejectedAttendAllowanceList,
    setRejectedHolidayWorkList,
    setRejectedTransferList,
    setRejectedAgreement36List,
    setRejectedWorkScheduleChangeList,
    setRejectedStockPaidList,

    countApplying,
    setCountApplying,
    countProcessing,
    setCountProcessing,
    countApproval,
    setCountApproval,
    countReject,
    setCountReject,
    typeReason,
    setTypeReason,
    flag,
    setflag,
    targetMonth,
    setTargetMonth,
    type1_2,
    type3_4,
    detailRole,
  };
};
