/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import Amplify, { Auth } from "aws-amplify";

import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import PasswordForm from "components/molecules/PasswordForm";
import Button from "components/atoms/Button";
import { useHistory, Link, useParams } from "react-router-dom";
import { getCognitoInfo, getItem, saveLoginHistory } from "api/login";
import LoginUserDomain from "domain/loginUser";
import useToastNotification from "hooks/useToastNotification";
import BlockUI from "components/molecules/BlockUi";
import moment from "moment";
import { useDispatch } from "react-redux";
import { uiAppBarActions } from "modules/ui/appBar";
import { loginUserActions } from "modules/loginUser";
import { generateCognitoInfo } from "utility/generateCognitoInfo";
import { getAuthorizedFromDelious } from "utility";

const styles = {
  formFotter: css({
    padding: "0.5em 0.5em",
    textAlign: "center",
    marginTop: "15px",
    fontSize: "14px",
  }),
  formHeader: css({
    padding: "0.5em 0.5em",
    textAlign: "right",
  }),
  "formFotter a": css({
    textDecoration: "none",
    color: "#0070d2",
  }),
  "signUp a": css({
    textDecoration: "none",
    color: "#0070d2",
    fontSize: "12px",
  }),
  signUp: css({
    paddingTop: "10px",
    borderTop: "1px solid #DCDCDC",
    marginTop: "35px",
    textAlign: "center",
  }),
  formInput: css`
    input {
      height: 45px;
    }
    padding: 8px 0;
  `,
};

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("形式がemailではありません")
    .required("メールアドレスを入力してください"),
  password: getAuthorizedFromDelious()
    ? Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
          "パスワードは大文字小文字数字記号をすべて含む8文字以上で入力して下さい",
        )
        .required("パスワードを入力してください")
    : Yup.string()
        .min(6, "パスワードは6文字以上で入力して下さい")
        .required("パスワードを入力してください"),
});

const LoginInputForm: React.FC = () => {
  // redux
  const dispatch = useDispatch();
  const [blocking, setBlocking] = useState(false);
  const { errorNotification } = useToastNotification();
  const { companyCode } = useParams<any>();
  const history = useHistory();

  const submitForm = (e: any) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", submitForm);

    return () => {
      window.removeEventListener("keydown", submitForm);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previous = sessionStorage.getItem("path") || "";
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      setBlocking(true);

      getCognitoInfo(companyCode ?? "", formik.values.email).then((res) => {
        localStorage.setItem("loginUser.companyCode", res.companyCode);
        sessionStorage.setItem("loginUser.companyCode", res.companyCode);

        // 設定情報をAmplifyに反映させる
        Amplify.configure(generateCognitoInfo(res));

        Auth.signIn(formik.values.email, formik.values.password)
          .then((user: any) => {
            sessionStorage.setItem("isGoBack", String(0));
            localStorage.setItem("loginUser.isLogged", "true");

            if (user.challengeName === "SMS_MFA" || user.challengeName === "SOFTWARE_TOKEN_MFA") {
              // Do something...
              console.log(`user.challengeName = ${user.challengeName}`);
            } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
              // Do something...
              console.log(`user.challengeName = ${user.challengeName}`);
            } else if (user.challengeName === "MFA_SETUP") {
              // Do something...
              console.log(`user.challengeName = ${user.challengeName}`);
            } else {
              getItem(formik.values.email, "HQ", companyCode ?? "")
                .then((response: any) => {
                  const loginUser = new LoginUserDomain(response);
                  dispatch(loginUserActions.setLoginUser(loginUser));
                  // Save staff permission to session
                  localStorage.setItem("loginUser.companyCode", loginUser.companyCode);

                  sessionStorage.setItem("loginUser.mail", loginUser.mail);
                  sessionStorage.setItem("loginUser.companyCode", loginUser.companyCode);
                  sessionStorage.setItem("loginUser.staffCode", loginUser.staffCode);
                  sessionStorage.setItem("loginUser.staffName", loginUser.staffName);
                  sessionStorage.setItem(
                    "loginUser.isHavingApprovalPrivilege",
                    String(loginUser.isHavingApprovalPrivilege),
                  );

                  sessionStorage.setItem("loginUser.orgCode", loginUser.orgCode);
                  sessionStorage.setItem("loginUser.orgName", loginUser.orgName);
                  sessionStorage.setItem("loginUser.personalRole", loginUser.personalRole);
                  sessionStorage.setItem("loginUser.administrator", String(loginUser.administrator));
                  sessionStorage.setItem("loginUser.useGpsStamp", String(loginUser.useGpsStamp));
                  sessionStorage.setItem("loginUser.useFaceStamp", String(loginUser.useFaceStamp));
                  sessionStorage.setItem("loginUser.useBarcodeStamp", String(loginUser.useBarcodeStamp));
                  sessionStorage.setItem("loginUser.barcodeId", String(loginUser.barcodeId));

                  sessionStorage.setItem("loginUser.workSystem", String(loginUser.workSystem));
                  sessionStorage.setItem("loginUser.barcodeId", loginUser.barcodeId);
                  sessionStorage.setItem("loginUser.isAlertClosingDate", String(loginUser.isAlertClosingDate));

                  const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL;
                  const photoPath = `${domainUrl}/${sessionStorage.getItem(
                    "loginUser.companyCode",
                  )}/${sessionStorage.getItem("loginUser.staffCode")}/1.jpg?${moment()}`;
                  sessionStorage.setItem("loginUser.photoPath", photoPath);
                  dispatch(uiAppBarActions.changeAvatar(photoPath));

                  sessionStorage.setItem("loginUser.closingDate", String(loginUser.closingDate));

                  setBlocking(false);
                  if (loginUser.retired) {
                    errorNotification("退職済みのためログインできません");
                    saveLoginHistory(
                      formik.values.email,
                      false,
                      "",
                      "退職済みのためログインできません",
                      loginUser.companyCode,
                    );
                  } else {
                    saveLoginHistory(formik.values.email, true, "", "", loginUser.companyCode);

                    if (
                      previous &&
                      ![
                        "/attendStampList/attendStampModificationApplication",
                        "/attendStampList/attendWorkScheduleChangeApplication",
                        "/attendStampList/attendHolidayApplication",
                        "/attendStampList/attendOvertimeApplication",
                        "/attendStampList/attendHolidayworkApplication",
                        "/attendStampList/attendTransferApplication",
                        "/attendError/attendStampModificationApplication",
                        "/attendError/attendHolidayApplication",
                        "/attendError/attendOvertimeApplication",
                        "/attendError/attendHolidayworkApplication",
                        "/attendError/attendTransferApplication",
                        "/attendApplicationList/attendStampModificationApplication",
                      ].includes(previous)
                    ) {
                      history.push(previous);
                    } else {
                      history.push("/agreement36");
                    }
                  }
                })
                .catch((error: any) => {
                  setBlocking(false);
                  errorNotification("スタッフ情報が登録されていないためログインできません");
                  saveLoginHistory(
                    formik.values.email,
                    false,
                    "",
                    "スタッフ情報が登録されていないためログインできません",
                    companyCode,
                  );
                });
            }
          })
          .catch((err: any) => {
            setBlocking(false);
            if (err.code === "UserNotConfirmedException") {
              console.error("ユーザー登録の途中です");
              errorNotification("ユーザー登録の途中です");
              saveLoginHistory(formik.values.email, false, "", "ユーザー登録の途中です", companyCode);
            } else if (err.code === "PasswordResetRequiredException") {
              console.error("パスワードを変更する必要があります");
              errorNotification("パスワードを変更する必要があります");
              saveLoginHistory(formik.values.email, false, "", "パスワードを変更する必要があります", companyCode);
            } else if (err.code === "NotAuthorizedException") {
              console.error("ユーザー名またはパスワードが異なります");
              errorNotification("ユーザー名またはパスワードが異なります");
              saveLoginHistory(
                formik.values.email,
                false,
                formik.values.password,
                "ユーザー名またはパスワードが異なります",
                companyCode,
              );
            } else if (err.code === "UserNotFoundException") {
              console.error("ユーザー名またはパスワードが異なります");
              errorNotification("ユーザー名またはパスワードが異なります");
              saveLoginHistory(
                formik.values.email,
                false,
                formik.values.password,
                "ユーザー名またはパスワードが異なります",
                companyCode,
              );
            } else {
              console.error(err);
              console.error("予期しないエラーが発生しました error");
              errorNotification("予期しないエラーが発生しました");
              saveLoginHistory(
                formik.values.email,
                false,
                formik.values.password,
                "予期しないエラーが発生しました",
                companyCode,
              );
            }
          });
      });
    },
  });
  return (
    <BlockUI blocking={blocking}>
      <form onSubmit={formik.handleSubmit}>
        <FormField customStyle={styles.formInput}>
          <TextForm
            name="email"
            label=""
            placeHolder="メールアドレス"
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMsg={formik.errors.email}
          />
        </FormField>

        <FormField customStyle={styles.formInput}>
          <PasswordForm
            name="password"
            label=""
            placeHolder="パスワード"
            value={formik.values.password}
            onChange={formik.handleChange}
            errorMsg={formik.errors.password}
            showLabel={false}
          />
        </FormField>
        <FormSubmitArea>
          <div css={css({ textAlign: "center", width: "100%", marginTop: "22px" })}>
            <Button text="ログイン" onClick={formik.handleSubmit} minWidth="160px" />
          </div>
        </FormSubmitArea>

        <div css={styles.formFotter}>
          <Link
            to={getAuthorizedFromDelious() ? `/forgotPassword/${companyCode}` : "/forgotPassword"}
            css={styles["formFotter a"]}
          >
            パスワードを忘れた方はこちら
          </Link>
        </div>
        <div css={styles.signUp}>
          <Link to={getAuthorizedFromDelious() ? `/signup/${companyCode}` : "/signup"} css={styles["signUp a"]}>
            ユーザー登録はこちらから
          </Link>
        </div>
      </form>
    </BlockUI>
  );
};

export default LoginInputForm;
