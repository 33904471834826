import moment from "moment";
import { number } from "yup";
const dateFormat = "DD/MM/YYYY";

export interface HolidayItem {
  attendType?: number,
  staffCode: string,
  modifierApplicationId: string,
  applyTargetDateType: number,
  targetDate: string,
  targetStartDate: string,
  targetEndDate: string,
  holidayUnit: number,
  halfHolidayStartTime: string,
  halfHolidayStartTimeHour?: string,
  halfHolidayStartTimeMinute?: string,
  halfHolidayStartTimeNextDay: boolean,
  halfHolidayEndTime: string,
  halfHolidayEndTimeHour?: string,
  halfHolidayEndTimeMinute?: string,
  halfHolidayEndTimeNextDay: boolean,
  applicationReason: string,
  updateUser: string
  currentStaffCode: string,
  currentOrgCode: string,
  deleteApplicationAvailable: boolean,
  applicationStatus: number,
}

export default class AttendStockPaidDomain {
  constructor(private rawData: HolidayItem) {
    // do nothing
  }

  static generateInitial(): AttendStockPaidDomain {
    return new AttendStockPaidDomain({
      attendType: 0,
      staffCode: '',
      modifierApplicationId: '',
      applyTargetDateType: 0,
      targetDate: '',
      targetStartDate: '',
      targetEndDate: '',
      holidayUnit: 0,
      halfHolidayStartTime: '00:00',
      halfHolidayStartTimeHour: '00',
      halfHolidayStartTimeMinute: '00',
      halfHolidayStartTimeNextDay: false,
      halfHolidayEndTime: '00:00',
      halfHolidayEndTimeHour: '00',
      halfHolidayEndTimeMinute: '00',
      halfHolidayEndTimeNextDay: false,
      applicationReason: '',
      updateUser: '',
      currentStaffCode: '',
      currentOrgCode: '',
      deleteApplicationAvailable: false,
      applicationStatus: 0,
    });
  }

  getRawData(): HolidayItem {
    return this.rawData;
  }

  get attendType(): number {
    return Number(this.rawData.attendType);
  }

  set attendType(attendType: number) {
    this.rawData.attendType = attendType;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get applyTargetDateType(): number {
    return this.rawData.applyTargetDateType;
  }

  set applyTargetDateType(applyTargetDateType: number) {
    this.rawData.applyTargetDateType = applyTargetDateType;
  }

  get targetDate(): string {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: string) {
    this.rawData.targetDate = targetDate;
  }

  get targetStartDate(): string {
    return this.rawData.targetStartDate;
  }

  set targetStartDate(targetStartDate: string) {
    this.rawData.targetStartDate = targetStartDate;
  }

  get targetEndDate(): string {
    return this.rawData.targetEndDate;
  }

  set targetEndDate(targetEndDate: string) {
    this.rawData.targetEndDate = targetEndDate;
  }

  get holidayUnit(): number {
    return Number(this.rawData.holidayUnit);
  }
  set holidayUnit(holidayUnit: number) {
    this.rawData.holidayUnit = holidayUnit;
  }

  get halfHolidayStartTime(): string {
    return String(this.rawData.halfHolidayStartTime);
  }

  set halfHolidayStartTime(halfHolidayStartTime: string) {
    this.rawData.halfHolidayStartTime = halfHolidayStartTime;
  }

  get halfHolidayStartTimeHour(): string {
    return String(this.rawData.halfHolidayStartTimeHour);
  }

  set halfHolidayStartTimeHour(halfHolidayStartTimeHour: string) {
    this.rawData.halfHolidayStartTimeHour = halfHolidayStartTimeHour;
  }

  get halfHolidayStartTimeMinute(): string {
    return String(this.rawData.halfHolidayStartTimeMinute);
  }

  set halfHolidayStartTimeMinute(halfHolidayStartTimeMinute: string) {
    this.rawData.halfHolidayStartTimeMinute = halfHolidayStartTimeMinute;
  }

  get halfHolidayStartTimeNextDay(): boolean {
    return Boolean(this.rawData.halfHolidayStartTimeNextDay);
  }

  set halfHolidayStartTimeNextDay(halfHolidayStartTimeNextDay: boolean) {
    this.rawData.halfHolidayStartTimeNextDay = halfHolidayStartTimeNextDay;
  }

  get halfHolidayEndTime(): string {
    return String(this.rawData.halfHolidayEndTime);
  }

  set halfHolidayEndTime(halfHolidayEndTime: string) {
    this.rawData.halfHolidayEndTime = halfHolidayEndTime;
  }

  get halfHolidayEndTimeHour(): string {
    return String(this.rawData.halfHolidayEndTimeHour);
  }

  set halfHolidayEndTimeHour(halfHolidayEndTimeHour: string) {
    this.rawData.halfHolidayEndTimeHour = halfHolidayEndTimeHour;
  }

  get halfHolidayEndTimeMinute(): string {
    return String(this.rawData.halfHolidayEndTimeMinute);
  }

  set halfHolidayEndTimeMinute(halfHolidayEndTimeMinute: string) {
    this.rawData.halfHolidayEndTimeMinute = halfHolidayEndTimeMinute;
  }

  get halfHolidayEndTimeNextDay(): boolean {
    return Boolean(this.rawData.halfHolidayEndTimeNextDay);
  }

  set halfHolidayEndTimeNextDay(halfHolidayEndTimeNextDay: boolean) {
    this.rawData.halfHolidayEndTimeNextDay = halfHolidayEndTimeNextDay;
  }

  get applicationReason(): string {
    return this.rawData.applicationReason;
  }

  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get currentStaffCode(): string {
    return this.rawData.currentStaffCode;
  }

  set currentStaffCode(currentStaffCode: string) {
    this.rawData.currentStaffCode = currentStaffCode;
  }

  get currentOrgCode(): string {
    return this.rawData.currentOrgCode;
  }

  set currentOrgCode(currentOrgCode: string) {
    this.rawData.currentOrgCode = currentOrgCode;
  }

  get deleteApplicationAvailable(): boolean {
    return Boolean(this.rawData.deleteApplicationAvailable);
  }

  set deleteApplicationAvailable(deleteApplicationAvailable: boolean) {
    this.rawData.deleteApplicationAvailable = deleteApplicationAvailable;
  }

  get applicationStatus(): number {
    return this.rawData.applicationStatus;
  }

  set applicationStatus(applicationStatus: number) {
    this.rawData.applicationStatus = applicationStatus;
  }

}