import { AttendAchievementLock } from "api/attendAchievementLock";

export default class AttendAchievementLockDomain {
  constructor(private rawData: AttendAchievementLock) {
    //
  }

  static generateInitial(): AttendAchievementLockDomain {
    return new AttendAchievementLockDomain({
      id: "",
      targetDate: new Date(),
      orgCode: "",
      orgName: "",
      lockStatus: "0",
      errorCount: 0,
      alertOvertimeApplicationCount: 0,
      alertHolidayworkApplicationCount: 0,
      disapprovalCount: 0,
      availableDailyShift: 0,
    });
  }

  getRawData(): AttendAchievementLock {
    return this.rawData;
  }

  get targetDate(): Date {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: Date) {
    this.rawData.targetDate = targetDate;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get lockStatus(): string {
    return this.rawData.lockStatus;
  }

  set lockStatus(lockStatus: string) {
    this.rawData.lockStatus = lockStatus;
  }

  get errorCount(): number {
    return this.rawData.errorCount;
  }

  set errorCount(errorCount: number) {
    this.rawData.errorCount = errorCount;
  }

  get alertOvertimeApplicationCount(): number {
    return this.rawData.alertOvertimeApplicationCount;
  }

  set alertOvertimeApplicationCount(alertOvertimeApplicationCount: number) {
    this.rawData.alertOvertimeApplicationCount = alertOvertimeApplicationCount;
  }

  get alertHolidayworkApplicationCount(): number {
    return this.rawData.alertHolidayworkApplicationCount;
  }

  set alertHolidayworkApplicationCount(alertHolidayworkApplicationCount: number) {
    this.rawData.alertHolidayworkApplicationCount = alertHolidayworkApplicationCount;
  }

  get disapprovalCount(): number {
    return this.rawData.disapprovalCount;
  }

  set disapprovalCount(disapprovalCount: number) {
    this.rawData.disapprovalCount = disapprovalCount;
  }

  get availableDailyShift(): number {
    return this.rawData.availableDailyShift;
  }

  set availableDailyShift(availableDailyShift: number) {
    this.rawData.availableDailyShift = availableDailyShift;
  }
}
