/** @jsx jsx */
import React, { useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import { grayScale } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import PaidHolidayGrantHistoryDomain from "domain/master/holidayManagement/paidHolidayGrantHistory";
import { Column } from "react-table";
import RadioSelectForm from "components/molecules/RadioSelectForm";

import { useGrantHistoryDomainForm, useDownloadHolidayManagementGrantHistory } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import CalendarForm from "components/molecules/CalendarForm";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import BlockUI from "components/molecules/BlockUi";
import TextTooltip from "components/molecules/TextTooltip";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import { useOrgAllSelect } from "hooks/useOrgTreesOptions";
import moment from "moment";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "200px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
    color: "#666666",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
};

const GrantHistoryForm: React.FC<{
  arrGrantResult: any;
  orgTreesOptions: any;
}> = ({ arrGrantResult, orgTreesOptions }) => {
  const {
    paidHolidayGrantHistoryList,
    orgCode,
    setOrgCode,
    startYear,
    endYear,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    paidGrantResult,
    setPaidGrantResult,
    underFlag,
    setUnderFlag,
  } = useGrantHistoryDomainForm();

  const { downloadHolidayManagementGrantHistory, blocking } = useDownloadHolidayManagementGrantHistory(
    orgCode || "",
    targetDateFrom || startYear,
    targetDateTo || endYear,
    paidGrantResult || "0",
    underFlag || 0,
  );
  const contextObject = useContext(HolidayManagementContext);

  const columns: Array<Column<PaidHolidayGrantHistoryDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayGrantHistoryDomain };
          data: Array<PaidHolidayGrantHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "80px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayGrantHistoryDomain };
          data: Array<PaidHolidayGrantHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "80px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayGrantHistoryDomain };
          data: Array<PaidHolidayGrantHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "80px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayGrantHistoryDomain };
          data: Array<PaidHolidayGrantHistoryDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "80px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "付与日",
        accessor: "grantDateToStr",
      },
      {
        Header: "付与結果",
        accessor: "grantResultStr",
      },
      {
        Header: "付与種別",
        accessor: "grantTypeStr",
      },
      {
        Header: "繰越日数",
        accessor: "lastCarryOverDaysNumStr",
      },
      {
        Header: "付与日数",
        accessor: "grantDaysNumStr",
      },
      {
        Header: "前年消化日数",
        accessor: "lastDigestedDaysNumStr",
      },
      {
        Header: "消滅日数",
        accessor: "lastExtinctionDaysNumStr",
      },
      {
        Header: "エラー内容",
        accessor: "grantErrorMessage",
      },
    ],
    [],
  );

  return (
    <BlockUI blocking={blocking}>
      <Accordion text="付与履歴" callBack={() => {}}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="100%">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="付与日" />
                </div>
                {/* <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateFrom)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateFrom(date)} />
                </div>
                <div css={styles.betweenDays}>
                  ～
            </div>
                <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateTo)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateTo(date)} />
                </div> */}
                <CalendarRangeV2
                  startDate={new Date(targetDateFrom || startYear)}
                  onChangeStart={(date: Date) => setTargetDateFrom(moment(date).format("YYYY/MM/DD"))}
                  endDate={new Date(targetDateTo || endYear)}
                  onChangeEnd={(date) => {
                    if (date) {
                      setTargetDateTo(moment(date).format("YYYY/MM/DD"));
                    }
                  }}
                />
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode002"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
              <Checkbox
                id={"underFlag1"}
                name={"underFlag1"}
                label={"配下指定"}
                value={`underFlag1`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  console.log("1212");
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="付与結果" />
                </div>
                <div css={styles.select}>
                  <RadioSelectForm
                    label=""
                    items={arrGrantResult}
                    name="targetType"
                    value={String(paidGrantResult)}
                    setValue={(e) => setPaidGrantResult(e.target.value)}
                  />
                </div>
              </FlexBox>
            </div>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 140px;
              }
              table th:nth-of-type(2) {
                width: 100px;
              }
              table th:nth-of-type(3) {
                width: 140px;
              }
              table th:nth-of-type(4) {
                width: 100px;
              }
              table th:nth-of-type(5) {
                width: 140px;
              }
              table th:nth-of-type(6) {
                width: 100px;
                text-align: center;
              }
              table th:nth-of-type(7) {
                width: 100px;
                text-align: center;
              }
              table th:nth-of-type(8) {
                width: 100px;
                text-align: center;
              }
              table th:nth-of-type(9) {
                width: 100px;
                text-align: center;
              }
              table th:nth-of-type(10) {
                width: 150px;
                text-align: center;
              }
              table th:nth-of-type(11) {
                width: 100px;
                text-align: center;
              }
              table th:nth-of-type(12) {
                width: 200px;
                white-space: pre-line;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementGrantHistory()}
                  iconType="download"
                  text="ダウンロード"
                />
                {/* <IconLabelButton onClick={() => downloadHolidayManagementGrantHistory(true)} iconType="download" text="全組織ダウンロード" /> */}
              </FormSubmitArea>
            ) : null}
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={paidHolidayGrantHistoryList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={2}
              />
            </div>
          </div>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default GrantHistoryForm;
