/** @jsx jsx */
import React, { Dispatch, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormField from "components/atoms/Form/FormField";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { SecondaryButton, PrimaryButton } from "components/atoms/Button";
import StaffDomain from "domain/staff";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import { EmploymentDataStaffDomain } from "domain/master/general/employmentDataStaff";
import { useEmploymentForm } from "./hooks";
import { TablItem } from "components/atoms/TabBar";
import { css, jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import useStaffList from "components/pages/master/general/staff/StaffsPage/hooks";
import { useStaffDelete } from "../StaffListTable/hooks";
import FormLabel from "components/atoms/Form/FormLabel";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BodyText from "components/atoms/BodyText";
import FormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import { grayScale, gridCol } from "components/styles";
import HourInput from "components/atoms/Form/HourInput";
import SelectForm from "components/molecules/SelectForm";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const styles = {
  form: css({
    maxWidth: "600px",
    margin: "0 auto",
    padding: "30px",
  }),
  centerBox: css({
    justifyContent: "center",
    marginTop: "30px",
  }),
  calendar: css({
    maxWidth: "200px",
  }),
  span: css({
    margin: "0px 10px",
    position: "relative",
    top: "-2px",
  }),
  addon: css({
    color: grayScale.gray100,
    padding: "7px",
    marginLeft: "5px",
  }),
  error: css({
    marginTop: "4px",
    color: "red",
  }),
};

type SelectType = "staff" | "affiliation" | "employment" | "laborCost" | "workPattern" | "longTermCareandNurse";
const SelectType: { [key in SelectType]: SelectType } = {
  staff: "staff",
  affiliation: "affiliation",
  employment: "employment",
  laborCost: "laborCost",
  workPattern: "workPattern",
  longTermCareandNurse: "longTermCareandNurse",
};

const EmploymentInfoForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setEmpTargetDate?: React.Dispatch<React.SetStateAction<Date>>;
  employmentOptions: Array<any>;
  isDisabledInfo?: boolean;
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
}> = ({
  initialStaffDomain,
  isEditMode = false,
  tabItems,
  setInitialStaffDomain,
  setSelectType,
  setTabItems,
  setEmpTargetDate,
  employmentOptions,
  isDisabledInfo = false,
  setIsLoading,
}) => {
  const { formik, confirmModalOpen, closeConfirmModal, contextObject, validate } = useEmploymentForm(
    initialStaffDomain,
    isEditMode,
    setIsLoading,
  );

  const dataDeemedWorkingHour = Number(formik.values.deemedWorkingHours) || 0;
  const calcDeemedWorkingH = Math.trunc(dataDeemedWorkingHour ? dataDeemedWorkingHour / 60 : 0);
  const calcDeemedWorkingM = dataDeemedWorkingHour - calcDeemedWorkingH * 60;
  const dataDeemedMorningHalfHour = Number(formik.values.deemedMorningHalfHours) || 0;
  const calcDeemedMorningHalfH = Math.trunc(dataDeemedMorningHalfHour ? dataDeemedMorningHalfHour / 60 : 0);
  const calcDeemedMorningHalfM = dataDeemedMorningHalfHour - calcDeemedMorningHalfH * 60;
  const dataDeemedAfternoonHalfHour = Number(formik.values.deemedAfternoonHalfHours) || 0;
  const calcDeemedAfternoonHalfH = Math.trunc(dataDeemedAfternoonHalfHour ? dataDeemedAfternoonHalfHour / 60 : 0);
  const calcDeemedAfternoonHalfM = dataDeemedAfternoonHalfHour - calcDeemedAfternoonHalfH * 60;
  const [deemedWorkingHours, setDeemedWorkingHours] = useState<string>(
    calcDeemedWorkingH ? String(calcDeemedWorkingH < 10 ? "0" + calcDeemedWorkingH : calcDeemedWorkingH) : "",
  );
  const [deemedWorkingMinutes, setDeemedWorkingMinutes] = useState<string>(
    calcDeemedWorkingM ? String(calcDeemedWorkingM < 10 ? "0" + calcDeemedWorkingM : calcDeemedWorkingM) : "",
  );
  const [deemedMorningHalfHours, setDeemedMorningHalfHours] = useState<string>(
    calcDeemedMorningHalfH
      ? String(calcDeemedMorningHalfH < 10 ? "0" + calcDeemedMorningHalfH : calcDeemedMorningHalfH)
      : "",
  );
  const [deemedMorningHalfMinutes, setDeemedMorningHalfMinutes] = useState<string>(
    calcDeemedMorningHalfM
      ? String(calcDeemedMorningHalfM < 10 ? "0" + calcDeemedMorningHalfM : calcDeemedMorningHalfM)
      : "",
  );
  const [deemedAfternoonHalfHours, setDeemedAfternoonHalfHours] = useState<string>(
    calcDeemedAfternoonHalfH
      ? String(calcDeemedAfternoonHalfH < 10 ? "0" + calcDeemedAfternoonHalfH : calcDeemedAfternoonHalfH)
      : "",
  );
  const [deemedAfternoonHalfMinutes, setDeemedAfternoonHalfMinutes] = useState<string>(
    calcDeemedAfternoonHalfM
      ? String(calcDeemedAfternoonHalfM < 10 ? "0" + calcDeemedAfternoonHalfM : calcDeemedAfternoonHalfM)
      : "",
  );

  const columns: Array<Column<EmploymentDataStaffDomain>> = React.useMemo(
    () => [
      {
        Header: "適用開始月",
        accessor: "dispApplyDate",
      },
      {
        Header: "雇用形態コード",
        accessor: "employmentCode",
      },
      {
        Header: "雇用形態名",
        accessor: "employmentName",
      },
      {
        Header: "みなし時間",
        accessor: "dispDeemedWorkingHours",
      },
      {
        Header: "時間単位休暇の１日の時間数",
        accessor: "dispTimeHolidayHourOfDay",
      },
    ],
    [],
  );

  const { fetchData } = useStaffList();
  const {
    setDeleteTargetStaff,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  } = useStaffDelete(fetchData, setIsLoading);

  const convertToMinutes = (hours: number, minutes: number) => {
    return hours * 60 + minutes;
  };

  function convertToTime(sec: any, type: string) {
    let time = "";
    let number = Number(sec);
    const calcDeemedWorkingH = Math.trunc(number ? number / 60 : 0);
    const calcDeemedWorkingM = number - calcDeemedWorkingH * 60;
    switch (type) {
      case "hours":
        time = calcDeemedWorkingH
          ? String(calcDeemedWorkingH < 10 ? "0" + calcDeemedWorkingH : calcDeemedWorkingH)
          : "00";
        break;
      case "minutes":
        time = calcDeemedWorkingM
          ? String(calcDeemedWorkingM < 10 ? "0" + calcDeemedWorkingM : calcDeemedWorkingM)
          : "00";
        break;
      default:
        break;
    }
    return time.toString();
  }

  useEffect(() => {
    const minutes = convertToMinutes(Number(deemedWorkingHours), Number(deemedWorkingMinutes));
    formik.setFieldValue("deemedWorkingHours", minutes);
  }, [deemedWorkingHours, deemedWorkingMinutes]);

  useEffect(() => {
    const minutes = convertToMinutes(Number(deemedMorningHalfHours), Number(deemedMorningHalfMinutes));
    formik.setFieldValue("deemedMorningHalfHours", minutes);
  }, [deemedMorningHalfHours, deemedMorningHalfMinutes]);

  useEffect(() => {
    const minutes = convertToMinutes(Number(deemedAfternoonHalfHours), Number(deemedAfternoonHalfMinutes));
    formik.setFieldValue("deemedAfternoonHalfHours", minutes);
  }, [deemedAfternoonHalfHours, deemedAfternoonHalfMinutes]);

  return (
    <React.Fragment>
      {formik.values.employmentDataList && formik.values.employmentDataList.length !== 0 && (
        <div className="table-bordered">
          <DataTable columns={columns} data={formik.values.employmentDataDomainList} isHiddenPagination={false} />
        </div>
      )}

      <form onSubmit={formik.handleSubmit} css={styles.form}>
        <FormField>
          <div css={styles.calendar}>
            {/* <CalendarForm
                dateFormat={isEditMode ? 'YYYY年MM月' : 'YYYY/MM/DD'}
                label="運用開始日"
                date={formik.values.empApplyDate}
                changeDate={(date: Date) => {
                  if (setEmpTargetDate) {
                    if (!date) {
                      setEmpTargetDate(new Date());
                    } else { setEmpTargetDate(date); }
                  }
                  formik.setFieldValue('empApplyDate', date);
                }}
                required={true}
                readOnly={!isEditMode}
              /> */}
            <VerticalLabelFormLayout
              label={<FormLabel label="運用開始日" required={true} />}
              input={
                <CalendarMonth
                  date={formik.values.empApplyDate}
                  setDate={(date: Date) => {
                    if (setEmpTargetDate) {
                      if (!date) {
                        setEmpTargetDate(new Date());
                      } else {
                        setEmpTargetDate(date);
                      }
                    }
                    formik.setFieldValue("empApplyDate", date);
                  }}
                  // readOnly={!isEditMode}
                />
              }
            />
          </div>
        </FormField>

        <FormField displayBlock={true}>
          <SelectForm
            label="雇用形態"
            name="employmentId"
            value={formik.values.employmentId}
            setValue={(val: string) => {
              formik.setFieldValue("employmentId", val);
            }}
            options={employmentOptions}
            errorMsg={formik.errors.employmentId}
            required={true}
            isDisplayBlock={true}
            widthLabel="auto"
          />
        </FormField>
        <FormField displayBlock={true}>
          <RadioSelectForm
            label="みなし時間"
            name="useEmploymentDeemedWorkingHours"
            items={[
              {
                label: "設定する",
                value: "0",
              },
              {
                label: "雇用形態に設定されている日の契約労働時間をみなし勤務時間とする",
                value: "1",
              },
            ]}
            value={String(formik.values.useEmploymentDeemedWorkingHours ? 1 : 0)}
            setValue={(e) => {
              formik.setFieldError("deemedWorkingHours", "");
              formik.setFieldValue("useEmploymentDeemedWorkingHours", e.target.value === "0" ? false : 1);
              if (Number(e.target.value) === 1) {
                formik.setFieldValue("deemedWorkingHours", 0);
              }
            }}
          />
          {formik.values.useEmploymentDeemedWorkingHours === false && (
            <FormField displayBlock={true}>
              <div style={{ marginRight: "12px" }}>
                <FormLayout
                  label={<FormLabel label="" />}
                  input={
                    <FlexBox>
                      <FlexBoxItem width="70px">
                        <VerticalLabelSelectForm
                          label=""
                          name="deemedWorkingHours"
                          value={
                            String(deemedWorkingHours) || convertToTime(formik.values.deemedWorkingHours, "hours")
                          }
                          setValue={(val: string) => {
                            setDeemedWorkingHours(val);
                          }}
                          options={hourFormOptions}
                        />
                      </FlexBoxItem>
                      <BodyText customStyle={styles.addon}>時間</BodyText>
                      <FlexBoxItem width="70px">
                        <VerticalLabelSelectForm
                          label=""
                          name="deemedWorkingMinutes"
                          value={
                            String(deemedWorkingMinutes) || convertToTime(formik.values.deemedWorkingHours, "minutes")
                          }
                          setValue={(val: string) => {
                            setDeemedWorkingMinutes(val);
                          }}
                          options={timeFormOptions}
                        />
                      </FlexBoxItem>
                      <BodyText customStyle={styles.addon}>分</BodyText>
                    </FlexBox>
                  }
                />
              </div>
            </FormField>
          )}
          {formik.errors.deemedWorkingHours && <span css={styles.error}> {formik.errors.deemedWorkingHours} </span>}
        </FormField>
        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid12 }}>
            <FormLayout
              label={<FormLabel label="半休みなし時間" />}
              note="設定時間以上の半休申請を不可とします。設定がない場合はチェックを行いません。"
              input={
                <FlexBox>
                  <BodyText customStyle={styles.addon}>午前</BodyText>
                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="deemedMorningHalfHours"
                      value={
                        String(deemedMorningHalfHours) || convertToTime(formik.values.deemedMorningHalfHours, "hours")
                      }
                      setValue={(val: string) => {
                        setDeemedMorningHalfHours(val);
                      }}
                      options={hourFormOptions}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}>時間</BodyText>
                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="deemedMorningHalfMinutes"
                      value={
                        String(deemedMorningHalfMinutes) ||
                        convertToTime(formik.values.deemedMorningHalfHours, "minutes")
                      }
                      setValue={(val: string) => {
                        setDeemedMorningHalfMinutes(val);
                      }}
                      options={timeFormOptions}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}>分</BodyText>
                  <BodyText customStyle={styles.addon}>午後</BodyText>
                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="deemedAfternoonHalfHours"
                      value={
                        String(deemedAfternoonHalfHours) ||
                        convertToTime(formik.values.deemedAfternoonHalfHours, "hours")
                      }
                      setValue={(val: string) => {
                        setDeemedAfternoonHalfHours(val);
                      }}
                      options={hourFormOptions}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}>時間</BodyText>
                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="deemedAfternoonHalfMinutes"
                      value={
                        String(deemedAfternoonHalfMinutes) ||
                        convertToTime(formik.values.deemedAfternoonHalfHours, "minutes")
                      }
                      setValue={(val: string) => {
                        setDeemedAfternoonHalfMinutes(val);
                      }}
                      options={timeFormOptions}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}>分</BodyText>
                </FlexBox>
              }
            />
          </div>
        </FormField>
        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid06 }}>
            <FormLayout
              label={<FormLabel label="時間単位休暇の１日の時間数" />}
              input={
                <FlexBox>
                  <FlexBoxItem width="70px">
                    <HourInput
                      name="timeHolidayHourOfDay"
                      label=""
                      value={String(formik.values.timeHolidayHourOfDay || 0)}
                      onChange={(val) => {
                        const { value } = val.target;
                        formik.setFieldValue("timeHolidayHourOfDay", Number(value));
                      }}
                      min={0}
                      max={24}
                      isFullW={true}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}> 時間</BodyText>
                </FlexBox>
              }
            />
          </div>
        </FormField>
        <FormField displayBlock={true}>
          <div style={{ marginRight: "12px", width: gridCol.grid06 }}>
            <FormLayout
              label={<FormLabel label="1日で使用できる時間休暇の最大時間数" />}
              note="0の場合はエラーチェックを行いません"
              input={
                <FlexBox>
                  <FlexBoxItem width="70px">
                    <HourInput
                      name="timeHolidayMaximumHour"
                      label=""
                      value={String(formik.values.timeHolidayMaximumHour || 0)}
                      onChange={(val) => {
                        const { value } = val.target;
                        formik.setFieldValue("timeHolidayMaximumHour", Number(value));
                      }}
                      min={0}
                      max={24}
                      isFullW={true}
                    />
                  </FlexBoxItem>
                  <BodyText customStyle={styles.addon}> 時間</BodyText>
                </FlexBox>
              }
            />
          </div>
        </FormField>

        <FormSubmitArea customStyle={css({ padding: "30px", paddingBottom: "0px" })}>
          <div style={{ marginRight: "12px" }}>
            {contextObject.detailRole.editable === 1 && (
              <FlexBox customStyle={styles.centerBox}>
                <Button text={isEditMode ? "雇用情報を登録" : "登録"} onClick={formik.handleSubmit} minWidth="150px" />
                {isEditMode && !isDisabledInfo ? (
                  <div style={{ marginLeft: "12px", textAlign: "center" }}>
                    <SecondaryButton
                      minWidth="150px"
                      text="削除"
                      onClick={() => {
                        setIsOpenDeleteModal(true);
                        setDeleteTargetStaff(formik.values);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {!isEditMode && (
                  <div style={{ marginLeft: "12px" }}>
                    {contextObject.detailRole.usabilityGeneralItem1 === 1 ? (
                      <Button
                        text="人件費設定の入力へ"
                        onClick={async () => {
                          await formik.validateForm();

                          if (!validate()) return;

                          if (tabItems.length <= 3) {
                            setInitialStaffDomain(formik.values);

                            setTabItems([
                              {
                                value: SelectType.staff,
                                display: "スタッフ情報",
                              },
                              {
                                value: SelectType.affiliation,
                                display: "所属情報",
                              },
                              {
                                value: SelectType.employment,
                                display: "雇用情報",
                              },
                              {
                                value: SelectType.laborCost,
                                display: "人件費情報",
                              },
                            ]);
                          }
                          setSelectType(SelectType.laborCost);
                        }}
                      />
                    ) : (
                      <Button
                        text="勤務パターンの入力へ"
                        onClick={async () => {
                          await formik.validateForm();

                          if (!validate()) return;

                          // Set salary apply day
                          formik.values.selectSalary.applyStartDate = formik.values.hireDate;

                          if (tabItems.length <= 3) {
                            setInitialStaffDomain(formik.values);

                            setTabItems([
                              {
                                value: SelectType.staff,
                                display: "スタッフ情報",
                              },
                              {
                                value: SelectType.affiliation,
                                display: "所属情報",
                              },
                              {
                                value: SelectType.employment,
                                display: "雇用情報",
                              },
                              {
                                value: SelectType.workPattern,
                                display: "勤務パターン",
                              },
                            ]);
                          }
                          setSelectType(SelectType.workPattern);
                        }}
                      />
                    )}
                  </div>
                )}
              </FlexBox>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="/staffs">
              <PrimaryButton text="スタッフ一覧に戻る" ghost={true} minWidth="150px" />
            </Link>
          </div>
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
        typeSubmit="reject"
      />
    </React.Fragment>
  );
};

export default EmploymentInfoForm;
