
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterICCardSettingForm from 'components/organismos/master/MasterICCardSettingForm';

const MasterICCardSettingPage = () => (
    <SidebarTemplate pageTitle="顔写真登録">
        <MasterICCardSettingForm/>
    </SidebarTemplate>
);

export default MasterICCardSettingPage;
