/** @jsx jsx */
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { css, jsx } from "@emotion/core";
import SidebarTemplate from "components/templates/SidebarTemplate";
import BlockUI from "components/molecules/BlockUi";
import FormContents from "components/atoms/Form/FormContents";
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from "../ReactCalendarTimeline/index";
import { utilityColor, grayScale, textFontSize } from "components/styles";
import ContextMenuShift from "components/molecules/ContextMenu/ContextMenuShift";
import {
  TimelineItemBase,
  ItemContext,
  GetItemsProps,
  GetResizeProps,
  ItemRendererGetResizePropsReturnType,
  TimelineGroupCustom,
} from "types/react-calendar-timeline";
import ModalRegisterShift from "./Modals/ModalRegisterShift";
import Modal from "components/molecules/Modal";
import { dailyShiftContext, useInitialState } from "./contextHook";
import Filters from "./Filters";
import IconLabelButton from "components/molecules/IconLabelButton";
import { photoPath } from "hooks/usePhotoPath";
import "../ReactCalendarTimeline/lib/Timeline.scss";
import PhotoFrame from "components/molecules/PhotoFrame";
import Icon from "components/atoms/Icon";
import ReactTooltip from "react-tooltip";
import FormTitle from "components/atoms/Form/FormTitle";
import TextTooltip from "components/molecules/TextTooltip";
import TableTotalValueDailyShift from "components/pages/master/shift/daily/TableTotalValueDailyShift";
import Accordion from "components/molecules/Accordion";

const DailyShift: React.FC = () => {
  const contextInitialState = useInitialState();
  const {
    detailRole,
    isLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openDeleteModal,
    setOpenDeleteModal,
    dailyShift,
    useSales,
    showLaborCost,
    headerRef,
    filters: { submitShiftLockout },
    calendar: {
      items,
      deleteItem,
      groups,
      selectedItem,
      setSelectedItem,
      selectedItemIdArr,
      defaultTimeStart,
      defaultTimeEnd,
      sidebarName,
      sidebarType,
      sidebarHeaderWidth,
      sidebarHourHeaderRightWidth,
      sidebarAllowanceHeaderRightWidth,
      onTimeChange,
      onItemMove,
      onItemSelect,
      onItemClick,
      onCanvasClick,
      onItemResize,
      moveResizeValidator,
      onClickAttendStampList,
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      contextMenuY,
      menuTimelineButtons,
      menuPerformanceButtons,
      menuItemButtons,
    },
    shiftPattern: { openShiftPatternModal },
  } = contextInitialState;

  let bodyNode = document.body as HTMLBodyElement;

  const prefix = "dailyShift";
  const prefixLeft = "dailyShift_left";
  const prefixRight = "dailyShift_right";

  const highlightAction = sessionStorage.getItem("highlightAction") || false;
  const highlightIndex = sessionStorage.getItem("highlightIndex") || "";
  const elmnt = document.getElementById(prefix + "_row_" + highlightIndex);
  const elmntLeft = document.getElementById(prefixLeft + "_" + highlightIndex);
  const elmntRight = document.getElementById(prefixRight + "_" + highlightIndex);

  useEffect(() => {
    if (highlightAction === "true") {
      if (elmnt && elmntLeft && elmntRight) {
        elmnt.classList.add("hightlight");
        elmntLeft.classList.add("hightlight");
        elmntRight.classList.add("hightlight");
        elmntRight.scrollIntoView({ block: "center" });

        setTimeout(() => {
          elmnt.classList.remove("hightlight");
          elmntLeft.classList.remove("hightlight");
          elmntRight.classList.remove("hightlight");

          sessionStorage.setItem("highlightAction", "false");
          sessionStorage.setItem("highlightIndex", "");
        }, 3000);
      }
    }
  }, [elmnt, elmntLeft, elmntRight, highlightAction]);

  // マスク処理はバックエンド側に移譲
  // const isShowStaffLaborCost = (workingForm?: number | null) => {
  // if (workingForm === Values.WorkingForm.FullTime.value && detailRole.usabilityGeneralItem4 === 1) return true;
  // if (workingForm === Values.WorkingForm.PartTime.value && detailRole.usabilityGeneralItem3 === 1) return true;
  // return false;
  // };

  const convertNumberByPrecision = (num: any, defaultValue = "-", precision = 3, delimiter = ",") => {
    if (!num) {
      return defaultValue ?? num;
    }
    function reverse(s: string) {
      return s
        .split("")
        .reverse()
        .join("");
    }
    let numStr = String(num);
    if (num.length <= precision || numStr.includes(delimiter)) {
      return numStr;
    }
    let res = "";
    numStr = reverse(numStr);
    while (numStr.length >= precision) {
      res += `${delimiter}${numStr.substr(0, precision)}`;
      numStr = numStr.substr(precision, numStr.length);
    }
    if (numStr.length > 0) {
      res += `${delimiter}${numStr}`;
    }
    res = res.substr(1, res.length);
    return reverse(res);
  };

  return (
    <SidebarTemplate pageTitle="シフト管理">
      <BlockUI blocking={isLoading}>
        <dailyShiftContext.Provider value={contextInitialState}>
          <FormContents>
            <FormTitle
              title="日次勤怠"
              bold={true}
              customStyle={css`
                margin: 16px 45px;
              `}
            />
            <Filters />

            {isPerformanceMenuVisible &&
              createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuPerformanceButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setPerformanceMenuVisible}
                  />
                </div>,
                bodyNode,
              )}
            {isItemMenuVisible &&
              createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuItemButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setItemMenuVisible}
                  />
                </div>,
                bodyNode,
              )}
            {isTimelineMenuVisible &&
              createPortal(
                <div className="contextMenu">
                  <ContextMenuShift
                    buttons={menuTimelineButtons}
                    pageX={contextMenuX}
                    pageY={contextMenuY}
                    parentRef={contextMenuRef}
                    setIsComponentVisible={setTimelineMenuVisible}
                  />
                </div>,
                bodyNode,
              )}

            {showLaborCost && (
              <Accordion
                text="当日計・累計"
                color={grayScale.gray100}
                backgroundColor="transparent"
                callBack={() => {}}
                defaultExpand
                showBorder={false}
              >
                <TableTotalValueDailyShift useSales={useSales} dailyShift={dailyShift} />
              </Accordion>
            )}

            {defaultTimeStart && defaultTimeEnd && groups.length > 0 && (
              <div ref={headerRef}>
                <Timeline
                  lineHeight={48}
                  itemHeightRatio={0.5}
                  rightSidebarWidth={
                    sidebarHourHeaderRightWidth + (showLaborCost ? sidebarAllowanceHeaderRightWidth : 0)
                  }
                  sidebarWidth={sidebarHeaderWidth}
                  groups={groups}
                  items={items}
                  defaultTimeStart={defaultTimeStart}
                  defaultTimeEnd={defaultTimeEnd}
                  minZoom={12 * 60 * 60 * 1000}
                  maxZoom={12 * 60 * 60 * 1000}
                  canChangeGroup={false}
                  onTimeChange={onTimeChange}
                  onItemMove={onItemMove}
                  onItemResize={onItemResize}
                  moveResizeValidator={moveResizeValidator}
                  onItemDeselect={(e: React.SyntheticEvent) => {
                    setSelectedItem({} as TimelineItemBase<number>); //reset
                  }}
                  selected={selectedItemIdArr}
                  onItemSelect={onItemSelect}
                  onItemClick={onItemClick}
                  onCanvasClick={onCanvasClick}
                  groupRenderer={(groupObj: { group: TimelineGroupCustom; isRightSidebar: any }) => {
                    const { group, isRightSidebar } = groupObj;
                    return isRightSidebar ? (
                      <div
                        className="rct-sidebar-row__block"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="rct-sidebar-row__block"
                          style={{
                            textAlign: "center",
                            width: `${sidebarHourHeaderRightWidth}px`,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div className="rct-sidebar-row__block-child" style={{}}>
                            {group.rightData && group.rightData[0]}
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{ borderTop: `1px solid ${grayScale.gray20}`, width: "100%" }}
                          >
                            {group.rightData && group.rightData[1]}
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{ borderTop: `1px solid ${grayScale.gray20}`, width: "100%" }}
                          >
                            {group.rightData && group.rightData[2]}
                          </div>
                        </div>
                        {showLaborCost && (
                          <React.Fragment>
                            <div
                              className="rct-sidebar-row__block"
                              style={{
                                textAlign: "center",
                                width: `${sidebarAllowanceHeaderRightWidth / 4}px`,
                                borderLeft: `1px solid ${grayScale.gray20}`,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="rct-sidebar-row__block-child" style={{ width: "100%" }}>
                                -
                              </div>
                              <div
                                className="rct-sidebar-row__block-child"
                                style={{ borderTop: `1px solid ${grayScale.gray20}`, width: "100%" }}
                              >
                                {group.dateInfo[0].dailyLaborCostShift}
                              </div>
                              <div
                                className="rct-sidebar-row__block-child"
                                style={{ borderTop: `1px solid ${grayScale.gray20}`, width: "100%" }}
                              >
                                {group.dateInfo[0].dailyLaborCostAchievement}
                              </div>
                            </div>
                            <div
                              className="rct-sidebar-row__block"
                              style={{
                                textAlign: "center",
                                width: `${sidebarAllowanceHeaderRightWidth / 4}px`,
                                borderLeft: `1px solid ${grayScale.gray20}`,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="rct-sidebar-row__block-child" style={{ width: "100%" }}>
                                -
                              </div>
                              <div
                                className="rct-sidebar-row__block-child"
                                style={{ borderTop: `1px solid ${grayScale.gray20}`, width: "100%" }}
                              >
                                {group.laborCostShiftCumulativePeriod}
                              </div>
                              <div
                                className="rct-sidebar-row__block-child"
                                style={{ borderTop: `1px solid ${grayScale.gray20}`, width: "100%" }}
                              >
                                {group.laborCostAchievementCumulativePeriod}
                              </div>
                            </div>

                            <div
                              className="rct-sidebar-row__block"
                              style={{
                                textAlign: "center",
                                width: `${sidebarAllowanceHeaderRightWidth / 4}px`,
                                borderLeft: `1px solid ${grayScale.gray20}`,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="rct-sidebar-row__block-child" style={{ width: "100%" }}>
                                -
                              </div>
                              <div
                                className="rct-sidebar-row__block-child"
                                style={{
                                  borderTop: `1px solid ${grayScale.gray20}`,
                                  height: "66.6666%",
                                  textAlign: "center",
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div>{convertNumberByPrecision(group.dateInfo[0].dailyHourlySalary)}</div>
                              </div>
                            </div>
                            <div
                              className="rct-sidebar-row__block"
                              style={{
                                textAlign: "center",
                                width: `${sidebarAllowanceHeaderRightWidth / 4}px`,
                                borderLeft: `1px solid ${grayScale.gray20}`,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="rct-sidebar-row__block-child" style={{ width: "100%" }}>
                                -
                              </div>
                              <div
                                className="rct-sidebar-row__block-child"
                                style={{
                                  borderTop: `1px solid ${grayScale.gray20}`,
                                  height: "66.6666%",
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {group.dateInfo &&
                                    group.dateInfo[0] &&
                                    convertNumberByPrecision(group.dateInfo[0].dailyTransportation)}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    ) : (
                      <div className="rct-sidebar-group" style={{ display: "flex" }}>
                        <div
                          className="rct-sidebar-row__block"
                          style={{
                            width: `${sidebarName}px`,
                            padding: "10px 0 10px 20px",
                            whiteSpace: "pre-wrap",
                            lineHeight: "22px",
                            alignSelf: "center",
                            fontSize: textFontSize.sm,
                          }}
                        >
                          <div className="d-flex" style={{ alignItems: "center" }}>
                            <div
                              style={{
                                borderRadius: "50%",
                                overflow: "hidden",
                                height: "55px",
                                width: "55px",
                              }}
                            >
                              <PhotoFrame
                                src={photoPath(group)}
                                size="55px"
                                isCircle={true}
                                onErrorIconColor="black"
                              />
                            </div>
                            {!group.isSupportStaff && detailRole.linkAvailable1 && (
                              <IconLabelButton
                                onClick={() => {
                                  onClickAttendStampList(group);
                                }}
                                text="詳細"
                                isIcon={false}
                              />
                            )}
                          </div>
                          <div
                            css={css`
                              display: -webkit-box;
                              -webkit-line-clamp: 3;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                            `}
                          >
                            <TextTooltip
                              text={group.employmentName}
                              textDis={<span>{group.employmentName}</span>}
                              customCss={css({
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "140px",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                                margin: "0px auto",
                              })}
                            />
                          </div>
                          <div
                            css={css`
                              display: -webkit-box;
                              -webkit-line-clamp: 3;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                            `}
                          >
                            <TextTooltip
                              text={group.staffName}
                              textDis={<span>{group.staffName}</span>}
                              customCss={css({
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "140px",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                                margin: "0px auto",
                              })}
                            />
                          </div>
                        </div>
                        <div
                          className="rct-sidebar-row__block"
                          style={{
                            width: `${sidebarType}px`,
                            textAlign: "center",
                            borderLeft: `1px solid ${grayScale.gray20}`,
                            borderRight: `1px solid ${grayScale.gray20}`,
                          }}
                        >
                          <div className="rct-sidebar-row__block-child" style={{}}>
                            希望
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{ borderTop: `1px solid ${grayScale.gray20}` }}
                          >
                            {group.dateInfo[0].shiftAlertMessage && (
                              <div
                                data-tip={group.dateInfo[0].shiftAlertMessage}
                                data-for={`shiftalert-${group.staffCode}`}
                                css={css({
                                  width: "fit-content",
                                  display: "inline-flex",
                                  paddingRight: "3px",
                                })}
                              >
                                <Icon
                                  type="warning"
                                  color={utilityColor.yellow}
                                  verticalAlign="text-bottom"
                                  size="18px"
                                />
                                <ReactTooltip html={true} id={`shiftalert-${group.staffCode}`} />
                              </div>
                            )}
                            シフト
                          </div>
                          <div
                            className="rct-sidebar-row__block-child"
                            style={{ borderTop: `1px solid ${grayScale.gray20}` }}
                          >
                            {group.dateInfo[0].achievementErrorMessage && (
                              <div
                                data-tip={group.dateInfo[0].achievementErrorMessage}
                                data-for={`error-${group.staffCode}`}
                                css={css({
                                  width: "fit-content",
                                  display: "inline-flex",
                                  paddingRight: "3px",
                                })}
                              >
                                <Icon type="remove" color="" verticalAlign="text-bottom" size="18px" />
                                <ReactTooltip html={true} id={`error-${group.staffCode}`} />
                              </div>
                            )}
                            {group.dateInfo[0].isAchievementConfirm && (
                              <div
                                css={css({
                                  width: "fit-content",
                                  display: "inline-flex",
                                  paddingRight: "3px",
                                })}
                              >
                                <Icon
                                  type="singlecheckRounded"
                                  color="#34a659"
                                  verticalAlign="text-bottom"
                                  size="18px"
                                />
                              </div>
                            )}
                            {group.dateInfo[0].achievementAlertMessage && (
                              <div
                                data-tip={group.dateInfo[0].achievementAlertMessage}
                                data-for={`alert-${group.staffCode}`}
                                css={css({
                                  width: "fit-content",
                                  display: "inline-flex",
                                  paddingRight: "3px",
                                })}
                              >
                                <Icon
                                  type="warning"
                                  color={utilityColor.yellow}
                                  verticalAlign="text-bottom"
                                  size="18px"
                                />
                                <ReactTooltip html={true} id={`alert-${group.staffCode}`} />
                              </div>
                            )}
                            実績
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  itemRenderer={(itemRenderer: {
                    item: TimelineItemBase<number>;
                    itemContext: ItemContext;
                    getItemProps: (props: GetItemsProps) => {};
                    getItemBreaktimeProps: (props: GetItemsProps) => {};
                    getResizeProps: (propsOverride?: GetResizeProps) => ItemRendererGetResizePropsReturnType;
                  }) => {
                    const { item, itemContext, getItemProps, getResizeProps, getItemBreaktimeProps } = itemRenderer;
                    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
                    return (
                      <div
                        {...getItemProps({
                          ...item.itemProps,
                          style: {
                            color: "white",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 4,
                            borderLeftWidth: itemContext.selected ? 1 : 0,
                            borderRightWidth: itemContext.selected ? 1 : 1,
                            ...item.itemProps?.style,
                          },
                        })}
                      >
                        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

                        <div
                          style={{
                            color: item.holidayForecolor ? String(item.holidayForecolor) : "white",
                            background: item.holidayBgcolor ? String(item.holidayBgcolor) : "none",
                            height: itemContext.dimensions.height,
                            overflow: "hidden",
                            paddingLeft: 3,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            position: "relative",
                          }}
                        >
                          {item.title}
                          {item.breaks &&
                            item.breaks.map((br, i) => {
                              return (
                                <div
                                  key={i}
                                  {...getItemBreaktimeProps({
                                    style: { background: "white" },
                                    breakId: i,
                                  })}
                                ></div>
                              );
                            })}
                        </div>

                        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
                      </div>
                    );
                  }}
                  canMove={detailRole.editable}
                  useResizeHandle={detailRole.editable}
                  canSelect={detailRole.editable}
                >
                  <TimelineHeaders
                    className="sticky"
                    calendarHeaderStyle={{ color: grayScale.gray60 }}
                    style={{
                      background: utilityColor.white,
                      position: "sticky",
                      top: 0,
                      zIndex: 82,
                    }}
                  >
                    <SidebarHeader>
                      {({ getRootProps }) => {
                        return (
                          <div {...getRootProps()} style={{ display: "flex" }}>
                            <div
                              className="rct-sidebar-row__block"
                              style={{
                                width: `${sidebarName}px`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "14px",
                              }}
                            >
                              氏名
                            </div>
                            <div
                              className="rct-sidebar-row__block"
                              style={{
                                width: `${sidebarType}px`,
                                borderLeft: `1px solid ${grayScale.gray20}`,
                                borderRight: `1px solid ${grayScale.gray20}`,
                              }}
                            ></div>
                          </div>
                        );
                      }}
                    </SidebarHeader>
                    <SidebarHeader variant="right">
                      {({ getRootProps, data }) => {
                        return (
                          <React.Fragment>
                            <div
                              {...getRootProps()}
                              style={{
                                maxWidth: `${sidebarHourHeaderRightWidth + 1}px`,
                                width: "100%",
                                textAlign: "center",
                                borderLeft: `1px solid ${grayScale.gray20}`,
                                lineHeight: "22px",
                                fontSize: "12px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderBottom: `1px solid ${grayScale.gray20}`,
                                  height: "100%",
                                }}
                              >
                                時間 (h)
                              </div>
                              <div style={{ height: "100%" }}>当日計</div>
                            </div>
                            {showLaborCost && (
                              <div
                                {...getRootProps()}
                                style={{
                                  maxWidth: `${sidebarAllowanceHeaderRightWidth}px`,
                                  width: "100%",
                                  textAlign: "center",
                                  borderLeft: `1px solid ${grayScale.gray20}`,
                                  lineHeight: "22px",
                                  fontSize: "12px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    borderBottom: `1px solid ${grayScale.gray20}`,
                                    height: "100%",
                                  }}
                                >
                                  人件費
                                </div>
                                <div
                                  {...getRootProps()}
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    lineHeight: "22px",
                                    fontSize: "12px",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      width: 70,
                                    }}
                                  >
                                    当日計
                                  </div>
                                  <div
                                    style={{
                                      height: "100%",
                                      width: 70,
                                      borderLeft: `1px solid ${grayScale.gray20}`,
                                    }}
                                  >
                                    累計
                                  </div>
                                  <div
                                    style={{
                                      height: "100%",
                                      width: 70,
                                      borderLeft: `1px solid ${grayScale.gray20}`,
                                    }}
                                  >
                                    時給
                                  </div>
                                  <div
                                    style={{
                                      height: "100%",
                                      width: 70,
                                      borderLeft: `1px solid ${grayScale.gray20}`,
                                    }}
                                  >
                                    日額交通費
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      }}
                    </SidebarHeader>
                    <DateHeader
                      unit="hour"
                      labelFormat="H"
                      height={47}
                      style={{
                        color: grayScale.gray03,
                        backgroundColor: utilityColor.white,
                      }}
                    />
                  </TimelineHeaders>
                </Timeline>
              </div>
            )}
          </FormContents>

          {/* Confirm shift modal */}
          <Modal
            open={openConfirmModal}
            closeHandler={() => {
              setOpenConfirmModal(false);
            }}
            title="シフトの確定"
            note={
              dailyShift?.isShiftConfirm
                ? "シフト確定を解除します。よろしいですか？"
                : "シフトを確定します。よろしいですか？"
            }
            submitText={dailyShift?.isShiftConfirm ? "解除する" : "確定する"}
            onSubmit={submitShiftLockout}
            closeText="キャンセル"
            typeSubmit={
              (dailyShift?.isShiftConfirm
                ? "シフト確定を解除します。よろしいですか？"
                : "シフトを確定します。よろしいですか？") === "シフト確定を解除します。よろしいですか？"
                ? "reject"
                : "confirm"
            }
          />

          {/* Confirm delete modal */}
          <Modal
            open={openDeleteModal}
            closeHandler={() => {
              setOpenDeleteModal(false);
            }}
            title="シフトの削除"
            note="シフトを削除します。よろしいですか？"
            submitText="削除する"
            onSubmit={() => {
              deleteItem(selectedItem?.idToString!);
              setOpenDeleteModal(false);
            }}
            closeText="キャンセル"
            typeSubmit={"reject"}
          />

          {openShiftPatternModal && <ModalRegisterShift />}
        </dailyShiftContext.Provider>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default DailyShift;
