/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import Select, { components } from "react-select";
import CustomSelect, { OptionType } from "components/atoms/Select";

import { ValueType } from "react-select/src/types";
import moment from "moment";
import { grayScale, productColor, textFontSize, textColor, utilityColor } from "components/styles";
import FormLabel from "components/atoms/Form/FormLabel";
import Icon from "components/atoms/Icon";
import DateTimeSelectFormLayout from "components/atoms/Form/DateTimeSelectFormLayout";
import ReactDatePicker from "react-datepicker";
import "./styles.css";
import "./CalendarV1/style.scss";
import { addAYear, subAYear } from "components/molecules/CalendarV1/Helpper";
import { Option, IndicatorsContainer, Input, MenuComponent } from "components/molecules/CalendarV1/Component";

const styles = {
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: "4px",
  }),
  wrapDatePicker: css({
    height: "0px",
    overflow: "hidden",
  }),
};

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    "& > div": {
      paddingLeft: 0,
      direction: "ltr",
    },
  }),
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    cursor: "pointer",
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796",
    borderRadius: "12px",
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: "21px",
    paddingRight: "6px",
    ":hover": {
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: "14px",
  }),
};

const MIN_YEAR = 1900;
const MAX_YEAR = 2099;

const listMonth = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

const rangeLimitTime = {
  minTime: moment()
    .set("year", MIN_YEAR)
    .startOf("year")
    .toDate(),
  maxTime: moment()
    .set("year", MAX_YEAR)
    .endOf("year")
    .toDate(),
};

const DateTimeSelectForm: React.FC<{
  label: string;
  name: string;
  dateFormat: string;
  labelFormat: string;
  valueFormat: string;
  values: Array<OptionType>;
  setValue: (val: any) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
}> = ({ label, name, dateFormat, valueFormat, labelFormat, values, setValue, description, isMulti, note }) => {
  const getValue = (): ValueType<OptionType> => values;
  const [optionsYear, setOptionsYear] = useState<Array<OptionType>>([]);
  const optionsMonth = listMonth.map((Hours: any) => ({
    value: Hours,
    label: Hours,
  }));

  const [isOpenDatePicker, setIsOpenDatePicker] = React.useState(false);
  const onFocus = () => {
    setIsOpenDatePicker(true);
  };
  const closeDatePicker = () => {
    setIsOpenDatePicker(!isOpenDatePicker);
  };

  const handleChangeDatePicker = (date: Date) => {
    const tmp = {
      value: moment(date, valueFormat).format(valueFormat),
      label: moment(date, labelFormat).format(labelFormat),
    };
    if (!values.find((i: any) => i.value === tmp.value)) {
      setValue([...values, tmp]);
    }

    setIsOpenDatePicker(false);
  };

  const onChange = (option: any) => {
    if (option) {
      setValue(option);
    } else {
      setValue([]);
    }
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Icon type="calendar" color={grayScale.gray100} />
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={grayScale.gray100} />
    </components.MultiValueRemove>
  );

  const validateYear = (date: Date) => {
    if (date.getFullYear() < MIN_YEAR || date.getFullYear() > MAX_YEAR) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    var listYear = [];
    for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
      listYear.push(i);
    }
    setOptionsYear(
      listYear.map((year) => {
        return {
          value: `${year}`,
          label: `${year}`,
        };
      }),
    );
  }, []);

  return (
    <div css={styles.container}>
      <DateTimeSelectFormLayout
        label={<FormLabel label={label} />}
        input={
          <div css={styles.select}>
            <div css={styles.wrapDatePicker}>
              <ReactDatePicker
                // readOnly={readOnly}
                strictParsing
                maxDate={rangeLimitTime.maxTime}
                minDate={rangeLimitTime.minTime}
                onChange={handleChangeDatePicker}
                open={isOpenDatePicker}
                onClickOutside={closeDatePicker}
                onCalendarClose={closeDatePicker}
                selected={new Date()}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                dateFormat={dateFormat}
                onSelect={(_, e: any) => {
                  if (e.constructor.name !== "SyntheticEvent") {
                    setIsOpenDatePicker(false);
                  }
                }}
                popperModifiers={{
                  offset: {
                    enabled: false,
                  },
                  flip: { enabled: false },
                }}
                showMonthDropdown
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  decreaseYear,
                  increaseYear,
                }) => {
                  return (
                    <div>
                      <div className="calendar__year">
                        <span
                          className="calendar__arrow calendar__arrow--left"
                          onClick={() => {
                            let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                            tmpDate = subAYear(tmpDate);
                            if (validateYear(tmpDate)) {
                              decreaseYear();
                            }
                          }}
                        ></span>
                        <div
                          css={css`
                            div {
                              justify-content: center;
                              border: 0;
                              :hover {
                                border: 0;
                              }
                            }
                          `}
                        >
                          <CustomSelect
                            name="select-month"
                            className="calendar__year-value"
                            value={String(date.getFullYear())}
                            setValue={(arg: string) => changeYear(Number(arg))}
                            options={optionsYear}
                            controlStyle={{
                              width: "70px",
                              border: "0",
                              backgroundColor: "transparent",
                            }}
                            components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                            maxMenuHeight={200}
                            customMenuStyle={{
                              marginTop: "0",
                            }}
                            isSearchable={false}
                          />
                        </div>
                        <span
                          className="calendar__arrow calendar__arrow--right"
                          onClick={() => {
                            let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                            tmpDate = addAYear(tmpDate);
                            if (validateYear(tmpDate)) {
                              increaseYear();
                            }
                          }}
                        ></span>
                      </div>
                      <div className="calendar__month">
                        <span className="calendar__arrow calendar__arrow--left" onClick={decreaseMonth}></span>
                        <div
                          css={css`
                            div {
                              justify-content: center;
                              border: 0;
                              :hover {
                                border: 0;
                                cursor: pointer;
                              }
                            }
                          `}
                        >
                          <CustomSelect
                            name="select-month"
                            className="calendar__month-value"
                            value={String(date.getMonth() + 1)}
                            setValue={(arg: string) => changeMonth(Number(arg) - 1)}
                            options={optionsMonth}
                            controlStyle={{
                              width: "50px",
                              border: "0",
                              backgroundColor: "transparent",
                            }}
                            components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                            maxMenuHeight={200}
                            customMenuStyle={{
                              marginTop: "0",
                            }}
                            isSearchable={false}
                          />
                        </div>
                        <span className="calendar__arrow calendar__arrow--right" onClick={increaseMonth}></span>
                      </div>
                    </div>
                  );
                }}
              >
                <div
                  className="label-current"
                  onClick={() => {
                    handleChangeDatePicker(new Date());
                    setIsOpenDatePicker(false);
                  }}
                >
                  今日
                </div>
              </ReactDatePicker>
            </div>
            <Select
              onFocus={onFocus}
              styles={selectStyles}
              name={name}
              value={getValue()}
              onChange={onChange}
              placeholder="選択してください"
              isMulti={isMulti}
              isClearable={false}
              menuIsOpen={false}
              isRtl={true}
              components={{ DropdownIndicator, IndicatorSeparator: () => null, MultiValueRemove }}
            />
          </div>
        }
      />
      {note && (
        <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />
      )}
    </div>
  );
};

export default DateTimeSelectForm;
