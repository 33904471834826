import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface PaidHolidayStatus {
    paidHolidayPatternId: string,
    effectiveStartDate: Date,
    orgCode: string,
    orgName: string,
    staffCode: string,
    staffName: string,
    paidHolidayGrantId: string,
    useLastGrantFlg: string,
    useLastFirstGrantFlg: boolean,
    lastGrantDaysNumSum: string,
    lastGrantTimesNumSum: string,
    useCurrentGrantFlg: string,
    grantDaysNumSum: string,
    grantTimesNumSum: string,
    useFirstGrantFlg: string,
    digestedDaysNum: number,
    digestedTimesNum: number,
    remainDaysNum: number,
    remainTimesNum: number,
    nextGrantDate: string,
    nextGrantDays: string,
    nextCarryOverDaysNum: number,
    nextCarryOverFirstDaysNum: number,
    nextCarryOverTimesNum: number,
    nextCarryOverFirstTimesNum: number,
    nextExtinctionDaysNum: number,
    nextExtinctionTimesNum: number,
    createUser: string,
    updateUser: string,

}


export default class PaidHolidayStatusDomain {
  constructor(private rawData: PaidHolidayStatus) {
    // do nothing
  }

  static generateInitial(): PaidHolidayStatusDomain {
    return new PaidHolidayStatusDomain({
      paidHolidayPatternId: '',
      effectiveStartDate: new Date(),
      orgCode: '',
      orgName: '',
      staffCode: '',
      staffName: '',
      paidHolidayGrantId: '',
      useLastGrantFlg: '',
      useLastFirstGrantFlg: false,
      lastGrantDaysNumSum: '',
      lastGrantTimesNumSum: '',
      useCurrentGrantFlg: '',
      grantDaysNumSum: '',
      grantTimesNumSum: '',
      useFirstGrantFlg: '',
      digestedDaysNum: 0,
      digestedTimesNum: 0,
      remainDaysNum: 0,
      remainTimesNum: 0,
      nextGrantDate: '',
      nextGrantDays: '',
      nextCarryOverDaysNum: 0,
      nextCarryOverFirstDaysNum: 0,
      nextCarryOverTimesNum: 0,
      nextCarryOverFirstTimesNum: 0,
      nextExtinctionDaysNum: 0,
      nextExtinctionTimesNum: 0,
      createUser: '',
      updateUser: '',

    });
  }

  getRawData(): PaidHolidayStatus {
    return this.rawData;
  }

  get paidHolidayPatternId(): string {
    return this.rawData.paidHolidayPatternId;
  }

  set paidHolidayPatternId(paidHolidayPatternId: string) {
    this.rawData.paidHolidayPatternId = paidHolidayPatternId;
  }

  get effectiveStartDate(): Date {
    return this.rawData.effectiveStartDate;
  }

  get effectiveStartDateToStr(): string {
    return this.rawData.effectiveStartDate ? moment(moment(this.rawData.effectiveStartDate).toDate()).format('YYYY年MM月DD日') : '';
  }

  set effectiveStartDate(date: Date) {
    this.rawData.effectiveStartDate = date;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get paidHolidayGrantId():string {
    return this.rawData.paidHolidayGrantId;
  }

  set paidHolidayGrantId(paidHolidayGrantId:string) {
    this.rawData.paidHolidayGrantId = paidHolidayGrantId;
  }
  
  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  // get value field
  get useLastGrantFlg(): string {
    return this.rawData.useLastGrantFlg;
  }

  get useLastFirstGrantFlg(): boolean {
    return this.rawData.useLastFirstGrantFlg;
  }

  get lastGrantDaysNumSum(): string {
    return this.rawData.lastGrantDaysNumSum;
  }

  get lastGrantTimesNumSum(): string {
    return this.rawData.lastGrantTimesNumSum;
  }

  get useCurrentGrantFlg(): string {
    return this.rawData.useCurrentGrantFlg;
  }

  get grantDaysNumSum(): string {
    return this.rawData.grantDaysNumSum;
  }

  get grantTimesNumSum(): string {
    return this.rawData.grantTimesNumSum;
  }

  get useFirstGrantFlg(): string {
    return this.rawData.useFirstGrantFlg;
  }

  get digestedDaysNum(): number {
    return this.rawData.digestedDaysNum;
  }


  get remainDaysNum(): number {
    return this.rawData.remainDaysNum;
  }

  get remainTimesNum(): number {
    return this.rawData.remainTimesNum;
  }

  get nextGrantDate(): string {
    return moment(moment(this.rawData.nextGrantDate).toDate()).format('YYYY年MM月DD日');
  }

  get nextGrantDays(): string {
    return this.rawData.nextGrantDays;
  }

  get nextGrantDaysToStr(): string {
    return `${this.rawData.nextGrantDays}日`;
  }

  get nextCarryOverDaysNum(): number {
    return this.rawData.nextCarryOverDaysNum;
  }

  get nextCarryOverFirstDaysNum(): number {
    return this.rawData.nextCarryOverFirstDaysNum;
  }

  get nextCarryOverTimesNum(): number {
    return this.rawData.nextCarryOverTimesNum;
  }

  get nextCarryOverFirstTimesNum(): number {
    return this.rawData.nextCarryOverFirstTimesNum;
  }

  get nextExtinctionDaysNum(): number {
    return this.rawData.nextExtinctionDaysNum;
  }

  get nextExtinctionTimesNum(): number {
    return this.rawData.nextExtinctionTimesNum;
  }

  // custom data use DataTable
  get carriedOverLastYear(): string {
    return paidHolidayTime(
      this.rawData.useLastGrantFlg,
      this.rawData.useLastFirstGrantFlg,
      this.rawData.lastGrantDaysNumSum,
      this.rawData.lastGrantTimesNumSum,
    );
  }

  get numberGrantedThisYear(): string {
    return paidHolidayTime(
      this.rawData.useCurrentGrantFlg,
      this.rawData.useFirstGrantFlg,
      this.rawData.grantDaysNumSum,
      this.rawData.grantTimesNumSum,
    );
  }

  get digestionNumber(): string {
    return `${holidayTime(this.rawData.digestedDaysNum)}日${this.rawData.digestedTimesNum}時間`;
  }

  get remainingPaid(): string {
    return `${holidayTime(this.rawData.remainDaysNum)}日${this.rawData.remainTimesNum}時間`;
  }

  get nextRolloverDays(): string {
    return `${holidayTime(this.rawData.nextCarryOverDaysNum + this.rawData.nextCarryOverFirstDaysNum)}日${this.rawData.nextCarryOverTimesNum + this.rawData.nextCarryOverFirstTimesNum}時間`;
  }

  get nextExpirationDate(): string {
    return `${holidayTime(this.rawData.nextExtinctionDaysNum)}日${this.rawData.nextExtinctionTimesNum}時間`;
  }
}

const paidHolidayTime = (flg1: any, flg2: any, day: any, time: any) => {
  if (flg1 || flg2) {
    return `${holidayTime(day)}日${time}時間`;
  } if (!flg1 && !flg2) {
    return '-';
  }
  return '';
};

const holidayTime = (time: any) => {
  if (!time) {
    return '0.0';
  }
  return (time).toFixed(1);
};
