/** @jsx jsx */
import React, { useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { useHistory } from "react-router-dom";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormPadding from "components/atoms/Form/FormPadding";
import { Row, Col } from "react-grid-system";
import BodyText from "components/atoms/BodyText";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import PrimaryButton, { SecondaryButton } from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import IconLabelButton from "components/molecules/IconLabelButton";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import { useMonthlyShiftContext } from "./contextHook";
import moment from "moment";
import Checkbox from "components/molecules/Checkbox";
import Calendar from "components/molecules/CalendarV1";
import SelectForm from "components/molecules/SelectForm";
import ModalRegisterStaff from "components/pages/master/shift/monthly/Modals/ModalRegisterStaff";

const Filters: React.FC = () => {
  const {
    detailRole,
    setOpenConfirmModal,
    setOpenRegisterModal,
    openRegisterModal,
    loadEmploymentDone,
    fetchOrg,
    fetchDrawingData,
    fetchEmploymentOptions,
    fetchMonthly,
    filters: { dispatch, stateFilter, checkall, setCheckall, setPageIndex },
    monthlyCalendar: { shiftConfirm, downloadPdf, downloadExcel, isAchievementConfirm },
  } = useMonthlyShiftContext();

  const history = useHistory();

  return (
    <FormPadding isSubForm={window.screen.width < 800}>
      <Row>
        <Col md={2} lg={1}>
          <div css={css({ marginTop: "18px" })}>
            <BodyText>期間</BodyText>
          </div>
        </Col>
        <Col md={10} lg={11}>
          <FlexBox>
            <Col md={3} xl={2}>
              <FlexBox>
                <FlexBoxItem>
                  <Calendar
                    openActionChevron={true}
                    modeAction={stateFilter.viewPeriod === "monthly" ? "month" : "week"}
                    date={moment(stateFilter.dateFromStr).toDate()}
                    setDate={(date) => {
                      if (date) {
                        let newdate = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
                        dispatch({
                          type: "ON_CHANGE_DATE_FROM",
                          payload: newdate,
                        });
                        if (newdate !== stateFilter.dateFromStr) {
                          fetchDrawingData(stateFilter.selectedOrg, newdate, stateFilter.viewPeriod).then(
                            ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
                              fetchOrg(dateFrom, dateTo);
                              fetchEmploymentOptions(stateFilter.selectedOrg, dateFrom, dateTo).then(
                                (employmentOptions: any) => {
                                  fetchMonthly(
                                    stateFilter.selectedOrg,
                                    dateFrom,
                                    dateTo,
                                    stateFilter.isAttendExists,
                                    employmentOptions,
                                  );
                                },
                              );
                            },
                          );
                        }
                        setPageIndex(1);
                        sessionStorage.setItem("attendStampList.monthlyShift.targetDateFrom", newdate);
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
            <Col md={9}>
              <div css={css({ display: "flex", alignItems: "baseline", marginLeft: "20px", marginTop: "-8px" })}>
                <RadioSelectForm
                  label=""
                  name="haveShiftRecord"
                  items={[
                    {
                      label: "1週間表示",
                      value: "weekly",
                    },
                    {
                      label: "1ヶ月表示",
                      value: "monthly",
                    },
                  ]}
                  value={stateFilter.viewPeriod}
                  setValue={(e) => {
                    dispatch({
                      type: "ON_CHANGE_VIEW_PERIOD",
                      payload: e.target.value,
                    });
                    fetchDrawingData(stateFilter.selectedOrg, stateFilter.dateFromStr, e.target.value).then(
                      ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
                        fetchEmploymentOptions(stateFilter.selectedOrg, dateFrom, dateTo).then(
                          (employmentOptions: any) => {
                            fetchMonthly(
                              stateFilter.selectedOrg,
                              dateFrom,
                              dateTo,
                              stateFilter.isAttendExists,
                              employmentOptions,
                            );
                          },
                        );
                      },
                    );
                    setPageIndex(1);
                    sessionStorage.setItem(`attendStampList.monthlyShift.viewPeriod`, e.target.value);
                  }}
                />
                <Checkbox
                  id="1"
                  name="isAttendExists"
                  label="シフト・実績有る人のみ表示"
                  value={"1"}
                  checked={stateFilter.isAttendExists === "1" ? true : false}
                  onChange={() => {
                    sessionStorage.setItem(
                      "attendStampList.attendFlag",
                      stateFilter.isAttendExists === "1" ? "0" : "1",
                    );
                    setPageIndex(1);
                    dispatch({
                      type: "ON_CHANGE_ATTEND_EXISTS",
                      payload: stateFilter.isAttendExists === "1" ? "0" : "1",
                    });
                    fetchMonthly(
                      stateFilter.selectedOrg,
                      stateFilter.dateFromStr,
                      stateFilter.dateToStr,
                      stateFilter.isAttendExists === "1" ? "0" : "1",
                      stateFilter.employmentOptions,
                    );
                  }}
                />
              </div>
            </Col>
          </FlexBox>
        </Col>
      </Row>

      <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: "12px" })}>
            <BodyText>組織</BodyText>
          </div>
        </Col>
        <Col lg={3} md={3}>
          <SelectForm
            label=""
            width="800px"
            name="attendEmployment"
            value={stateFilter.selectedOrg}
            setValue={(orgCode) => {
              dispatch({
                type: "ON_CHANGE_SELECTED_ORG",
                payload: orgCode,
              });
              fetchDrawingData(orgCode, stateFilter.dateFromStr, stateFilter.viewPeriod).then(
                ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
                  fetchEmploymentOptions(orgCode, dateFrom, dateTo).then((employmentOptions: any) => {
                    fetchMonthly(orgCode, dateFrom, dateTo, stateFilter.isAttendExists, employmentOptions);
                  });
                },
              );
              setPageIndex(1);
              sessionStorage.setItem("attendStampList.monthlyShift.orgCode", orgCode);
            }}
            options={stateFilter.orgs}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: "8px" })}>
            <BodyText>雇用形態</BodyText>
          </div>
        </Col>
        <Col lg={11} md={10} css={css({ display: "flex", flexWrap: "wrap" })}>
          <div css={css({ padding: "8px 10px 5px 0" })}>
            <Checkbox
              id={"all"}
              name={"checkall"}
              label="すべて"
              value={"1"}
              checked={checkall}
              onChange={(event) => {
                let newEmploymentOptions = stateFilter.employmentOptions.map((option) => {
                  option.checked = event.target.checked;
                  return option;
                });
                dispatch({
                  type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
                  payload: newEmploymentOptions,
                });

                sessionStorage.setItem(`attendStampList.monthlyShift.employmentOptions`, "all");

                fetchMonthly(
                  stateFilter.selectedOrg,
                  stateFilter.dateFromStr,
                  stateFilter.dateToStr,
                  stateFilter.isAttendExists,
                  newEmploymentOptions,
                );
                setPageIndex(1);
                setCheckall(event.target.checked);
              }}
            />
          </div>
          {stateFilter.employmentOptions.map((option, key) => {
            return (
              <div key={key} css={css({ padding: "8px 10px 5px 0" })}>
                <Checkbox
                  id={option.id}
                  name={option.label}
                  label={option.label}
                  value={option.value}
                  checked={option.checked}
                  onChange={(event) => {
                    let newEmploymentOptions = [...stateFilter.employmentOptions];
                    newEmploymentOptions[key].checked = event.target.checked;
                    setPageIndex(1);
                    dispatch({
                      type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
                      payload: newEmploymentOptions,
                    });

                    sessionStorage.setItem(
                      `attendStampList.monthlyShift.employmentOptions`,
                      newEmploymentOptions
                        .filter((option) => option.checked)
                        .map((option) => option.id)
                        .join(),
                    );

                    fetchMonthly(
                      stateFilter.selectedOrg,
                      stateFilter.dateFromStr,
                      stateFilter.dateToStr,
                      stateFilter.isAttendExists,
                      newEmploymentOptions,
                    );
                  }}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      <Row>
        <FlexBox customStyle={css({ margin: "1rem 0", width: "100%", justifyContent: "flex-end" })}>
          <PrimaryButton
            customStyle={css({ marginRight: "10px" })}
            text="支援スタッフ追加"
            onClick={() => {
              setOpenRegisterModal(true);
            }}
          />
          {shiftConfirm?.isConfirmedAllDays ? (
            <React.Fragment>
              {detailRole.usabilityGeneralItem2 ? (
                <SecondaryButton
                  text="シフト確定解除"
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {detailRole.usabilityGeneralItem1 ? (
                <PrimaryButton
                  text="シフト確定"
                  disabled={isAchievementConfirm || shiftConfirm["isConfirmedAllDays"]}
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                />
              ) : null}
            </React.Fragment>
          )}
          {detailRole.downloadFlag ? (
            <ClickableIconButton
              onClick={downloadPdf}
              additionalCss={css({ margin: "0 0 0 10px", cursor: "pointer" })}
            >
              <span className="print">
                <Icon type="printer" color="" size="24px" />
              </span>
            </ClickableIconButton>
          ) : null}

          {detailRole.editable === 1 && detailRole.importFlag ? (
            <FlexBoxItem>
              <IconLabelButton
                onClick={() => history.push("/monthlyShift/import")}
                iconType="addCircle"
                text="インポート"
              />
            </FlexBoxItem>
          ) : null}

          {detailRole.downloadFlag ? (
            <FlexBoxItem>
              <IconLabelButton onClick={downloadExcel} iconType="download" text="Excelダウンロード" />
            </FlexBoxItem>
          ) : null}
        </FlexBox>
      </Row>
      {openRegisterModal && <ModalRegisterStaff />}
    </FormPadding>
  );
};

export default Filters;
