/* eslint-disable max-len */
import ApiClient from './ApiClient';

export const getListTimeRecalculation = async (companyCode: string): Promise<any> => {
  const response = await ApiClient.get(`/v1/attend/latestRecalcute/${companyCode}`, {});
  return response.data;
};

export const getEmploymentOptions = async (targetYmFrom: string, targetYmTo: string):Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/employments/listByMonthRange/${companyCode}?targetYmFrom=${targetYmFrom}&targetYmTo=${targetYmTo}`, {});
  return response.data;
};

export const getStaffOptions = async (orgCode: string, targetDayFrom: string, targetDayTo: string, stateStatus: number, targetMonthType: number):Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/staffs/list/${companyCode}?orgCode=${orgCode}&targetDayFrom=${targetDayFrom}&targetDayTo=${targetDayTo}&stateStatus=${stateStatus}&targetMonthType=${targetMonthType}`, {});
  return response.data;
};

export const getStaffAccessableOptionsV2 = async (targetDayFrom: string, targetDayTo: string, orgCode: string, employmentId: string, addAllItem: boolean, functionCode: string, underFlag: number):Promise<Array<any>> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const param = {
    loginStaffCode,
    targetDayFrom,
    targetDayTo,
    orgCode,
    employmentId,
    addAllItem,
    functionCode,
    underFlag
  }
  const response = await ApiClient.get(`/v2/staffs/accessable/list/${companyCode}`, param);
  return response.data;
};


export const getStaffAccessableOptions = async (loginStaffCode: string, orgCode: string, targetDayFrom: string, targetDayTo: string, employmentId: string, addAllItem: boolean, functionCode: string, underFlag: number):Promise<Array<any>> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const param = {
    loginStaffCode,
    targetDayFrom,
    targetDayTo,
    orgCode,
    employmentId,
    addAllItem,
    functionCode,
    underFlag
  }
  const response = await ApiClient.get(`/v2/staffs/accessable/list/${companyCode}`, param);
  return response.data;
};

export const getRecalculateTime = async (timeRecal: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode  = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    ...timeRecal,
    loginStaffCode
  }
  const reponse = await ApiClient.post(`/v2/attend/recalcuteInfo/${companyCode}`, {}, params);
  return reponse.data;
};

// 勤怠再計算前処理
export const preRecalculate = async (timeRecal: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.post(`/v1/attend/preRecalcute/${companyCode}`, {}, timeRecal);
  return reponse.data;
};

// 勤怠再計算処理
export const recalculate = async (timeRecal: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const data = {
    recalculationId: timeRecal,
  };
  const reponse = await ApiClient.postMutipartData(`/v1/attend/recalcute/${companyCode}`, {}, data);
  return reponse.data;
};

export default getListTimeRecalculation;
