import reducer from './reducer';
import * as uiAppBarActions from './actions';

export interface AppBarType {
  photoPath: string;
}

export {
  uiAppBarActions,
};

export default reducer;
