/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import Select, { components, OptionTypeBase } from 'react-select';
import { ValueType } from 'react-select/src/types';
import FlexBox from 'components/atoms/FlexBox';
import {grayScale, productColor, textFontSize, textColor} from 'components/styles';
import Icon from 'components/atoms/Icon';
import  { SubActionButton } from '../atoms/Button';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import SelectForm from './SelectForm';
import { useState } from 'react';
import { useEffect } from 'react';
import BodyText from 'components/atoms/BodyText';
export interface OptionType extends OptionTypeBase {
  value: string,
  label: string,
}

const styles = {
  container: css({
    padding: '4px 0',
    marginTop:'-10px'
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    paddingTop:'8px',
    paddingBottom:'8px',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  selectDateStyles: css({
    width: '70px',
  }),
  wrapSameDays: css({
    display: 'flex',
    padding: '10px 0',
  }),
};

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    '& > div': {
      paddingLeft: '2px',
      direction: 'ltr',
    },
  }),

  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796',
    borderRadius: '12px',
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: '21px',
    paddingRight: '6px',
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: '14px',
  }),
};

const OrgStaffExcutiveSelectForm: React.FC<{
  name: string;
  values: Array<OptionType>;
  setValue: (val: any) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
  orgTreesOptions: any,
  executiveOptions: any,
  staffsForOrgCodeOptions: any,
  orgCode: any,
  setOrgCode: any,
  orgCode2: any,
  setOrgCode2: any,
  executiveId:any, setExecutiveId: any,
  staffCode:any, setStaffCode: any,
  setAppUserUpdateList: any,
}> = ({
  name, values, setValue, isMulti,
  orgTreesOptions,
  executiveOptions,
  staffsForOrgCodeOptions,
  orgCode,
  setOrgCode,
  orgCode2,
  setOrgCode2,
  executiveId, setExecutiveId,
  staffCode, setStaffCode,
  setAppUserUpdateList,
}) => {

  const [orgCodeName, setOrgCodeName] = useState(`${sessionStorage.getItem('loginUser.orgCode')} ${sessionStorage.getItem('loginUser.orgName')}` || '');
  const [executiveCodeName, setExecutiveCodeName] = useState(`${sessionStorage.getItem('loginUser.executiveCode')} ${sessionStorage.getItem('loginUser.executiveName')}` || '');
  const [staffCodeName, setStaffCodeName] = useState(`${sessionStorage.getItem('loginUser.staffCode')} ${sessionStorage.getItem('loginUser.staffName')}` || '');
  const [executiveCode, setExecutiveCode] = useState(`${sessionStorage.getItem('loginUser.executiveCode')}` || '');
  const [orgName, setOrgName] = useState(`${sessionStorage.getItem('loginUser.orgName')}` || '');
  const [executiveName, setExecutiveName] = useState(` ${sessionStorage.getItem('loginUser.executiveName')}` || '');
  const [staffName, setStaffName] = useState(`${sessionStorage.getItem('loginUser.staffName')}` || '');

  const [newValue, setNewValue] = useState( values? values.map((item) => ({
    value: item.dispCode,
    label: `${item.dispCode} ${item.dispName}`,
    dispCode: item.dispCode,
    appUserType: item.appUserType ? item.appUserType: 0,
    executiveId: item.executiveId ? item.executiveId: '',
  })) :[]);

  const getValue = (): ValueType<OptionType> => newValue;

  useEffect(() => {
    setAppUserUpdateList(newValue);
  }, [newValue]);

  const onChange = (option: any) => {
    if (option) {
      // setValue(option);
      setNewValue(option);
      setAppUserUpdateList(option);
    } else {
      // setValue([]);
      setAppUserUpdateList([]);
      setNewValue([]);
    }
  };


  useEffect(() => {
    let orgCodeFilter = orgTreesOptions.filter((option: any) => {
      if(option.value === orgCode){
        return true;
      }
    }); 
    if(orgCodeFilter[0] !== '' && orgCodeFilter[0] !== undefined){
      setOrgCodeName(orgCodeFilter[0].label);
      setOrgName(orgCodeFilter[0].orgName)
    }
  },[orgCode]);


  useEffect(() => {
    let executiveFilter = executiveOptions.filter((option: any) =>{
      if(option.value === executiveId){
        return true;
      }
    })
    if(executiveFilter[0] !== '' && executiveFilter[0] !== undefined){
      setExecutiveCodeName(executiveFilter[0].label);
      setExecutiveCode(executiveFilter[0].executiveCode);
      setExecutiveName(executiveFilter[0].executiveName);
    }
  },[executiveId]);

  useEffect(() => {
    let staffFilter = staffsForOrgCodeOptions.filter((option: any) =>{
      if(option.value === staffCode){
        return true;
      }
    })
    if(staffFilter[0] !== '' && staffFilter[0] !== undefined){
      setStaffCodeName(staffFilter[0].label);
      setStaffName(staffFilter[0].staffName);
    }
  },[staffCode]);

  const handleAdd = (flag: number) =>{
    let data = {
      value: `${orgCode}`,
      label: `${orgCodeName}`,
      dispCode: `${orgCode}`,
      dispName: `${orgName}`,
      appUserType: 0,
      executiveId: "null",
    };
    if (flag == 2 ){
      data = {
        value: `${executiveId}`,
        label: `${executiveCodeName}`,
        dispCode: `${executiveCode}`,
        dispName: `${executiveName}`,
        appUserType: 1,
        executiveId: `${executiveId}`,
      };
    } else if(flag == 3) {
       data = {
        value: `${staffCode}`,
        label: `${staffCodeName}`,
        dispCode: `${staffCode}`,
        dispName: `${staffName}`,
        appUserType: 2,
        executiveId: "null",
      };
    }

    if (!newValue.find((i: any) => i.value === data.value)) {
      setNewValue([...newValue, data]);
      // setValue([...newValue, data]);
      setAppUserUpdateList([...newValue, data]);

    }
  }


  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={grayScale.gray100} />
    </components.MultiValueRemove>
  );

  return (
    <div css={styles.container}>
          <div>
            <FlexBox>
              <FlexBoxItem width="250px">
                <BodyText>組織</BodyText>
              </FlexBoxItem>
            </FlexBox>
            <FlexBox>
              <FlexBoxItem width="495px">
                <SelectForm
                  label=""
                  name="orgCode"
                  value={String(orgCode)}
                  setValue={(val: string) =>{
                    setOrgCode(val);
                  } }
                  options={orgTreesOptions}
                />
              </FlexBoxItem>
              <FlexBoxItem marginLeft="auto">
                <SubActionButton
                  action={0}
                  text="追加"
                  onClick={() => handleAdd(1)}
                  width="50px"
                />
              </FlexBoxItem>  
            </FlexBox>
            <FlexBox>
              <FlexBoxItem width="480px">
                <BodyText>役職</BodyText>
              </FlexBoxItem>
            </FlexBox>      
            <FlexBox>
              <FlexBoxItem width="495px">
                <SelectForm
                  label=""
                  name="executive"
                  value={executiveId}
                  setValue={(arg: string) => { setExecutiveId(arg); }}
                  options={executiveOptions}
                  placeholder="executive"
                />
              </FlexBoxItem> 
              <FlexBoxItem marginLeft="auto">
                <SubActionButton
                  action={0}
                  text="追加"
                  onClick={() => handleAdd(2)}
                  width="50px"
                />
              </FlexBoxItem>   
            </FlexBox>
           
            <FlexBox>
              <FlexBoxItem width="243px" marginRight="10px">
                <BodyText>組織</BodyText>
              </FlexBoxItem>
              <FlexBoxItem width="242px">
                <BodyText>スタッフ</BodyText>
              </FlexBoxItem>
            </FlexBox>      

            <FlexBox>
              <FlexBoxItem width="243px" marginRight="10px">
                <SelectForm
                  label=""
                  name="orgCode2"
                  value={String(orgCode2)}
                  setValue={(val: string) => setOrgCode2(val)}
                  options={orgTreesOptions}
                />
              </FlexBoxItem>
              <FlexBoxItem width="243px">
                <SelectForm
                  label=""
                  name="staffCode"
                  placeholder="スタッフを選択"
                  value={staffCode}
                  setValue={setStaffCode}
                  options={staffsForOrgCodeOptions}
                  />
              </FlexBoxItem>  
              <FlexBoxItem customStyle={css({marginLeft:'12px'})}>
                <SubActionButton
                    action={0}
                    text="追加"
                    onClick={() => handleAdd(3)}
                    width="50px"
                />
              </FlexBoxItem>    
            </FlexBox>

            <div css={styles.select}>
              <Select
                styles={selectStyles}
                name={name}
                value={getValue()}
                onChange={onChange}
                placeholder=""
                isMulti={isMulti}
                isClearable={false}
                menuIsOpen={false}
                components={{DropdownIndicator:() => null, IndicatorSeparator: () => null, MultiValueRemove }}
              />
            </div>

          </div>
    </div>
  );
};

export default OrgStaffExcutiveSelectForm;
