/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import HourInput from 'components/atoms/Form/HourInput';
import { gridCol, grayScale } from 'components/styles';

const styles = {
  help: css({
    color: 'red',
    bottom: '-13px',
    left: '10px',
    position: 'relative'
  }),
  span: css({
    margin: '0px 10px',
    position: 'relative',
    top: '-2px'
  }),
  addon: css({
    color: grayScale.gray100,
    padding: '7px'
  }),
  formBody: css({
    padding: '5px 40px',
    margin: '0px',
    paddingBottom: '30px'
  }),
  formHeader: css({
    margin: '16px 0px'
  })
}

const DetailPaid1DayForm: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: (paidCalculationStandard: boolean) => void;
  isWorkTime: number;
  setIsWorkTime: (isWorkTime: number) => void;
  unUsableOfTrialPeriod: boolean;
  setUnUsableOfTrialPeriod: (unUsableOfTrialPeriod: boolean) => void;
  useEmploymentDeemedWorkingHours: boolean;
  setUseEmploymentDeemedWorkingHours: (useEmploymentDeemedWorkingHours: boolean) => void;
  deemedWorkingHours: number,
  setDeemedWorkingHours: (deemedWorkingHours: number) => void
  deemedWorkingMinutes: number,
  setDeemedWorkingMinutes: (deemedWorkingMinutes: number) => void
  unUsableOfHalfHoliday: boolean,
  setUnUsableOfHalfHoliday: any,
  unUsableOfTimeHoliday: boolean,
  setUnUsableOfTimeHoliday: any,
}> = ({
  paidCalculationStandard, setPaidCalculationStandard,
  isWorkTime, setIsWorkTime,
  unUsableOfTrialPeriod, setUnUsableOfTrialPeriod,
  useEmploymentDeemedWorkingHours, setUseEmploymentDeemedWorkingHours,
  deemedWorkingHours, setDeemedWorkingHours,
  deemedWorkingMinutes, setDeemedWorkingMinutes,
  unUsableOfHalfHoliday, setUnUsableOfHalfHoliday,
  unUsableOfTimeHoliday, setUnUsableOfTimeHoliday
}) => (
  <FormContents isSubForm={true} customStyle={styles.formBody}>
    <FormTitle
      title="有給詳細設定"
      note=""
      customStyle={styles.formHeader}
    />
    <FormField>
      <RadioSelectForm
        label="有給算出基準：勤務日数への加算"
        subLabel=""
        items={[
          {
            label: '加算しない',
            value: '0',
          },
          {
            label: '加算する',
            value: '1',
          },
        ]}
        name="xx"
        value={paidCalculationStandard ? '1' : '0'}
        setValue={(e) => {
          setPaidCalculationStandard(e.target.value !== '0');
        }}
      />
    </FormField>
    {/* { paidCalculationStandard === true && (
      <FormContents isSubForm={true}>
        <div >
          <FormField>
            <RadioSelectForm
              additionStyle={{display: 'inline-block'}}
              label="有給時みなし勤務時間"
              subLabel=""
              items={[
                {
                  label: '設定する',
                  value: '0',
                  children: (
                    <FormField>
                      <FlexBox>
                        <HourInput
                          name="hour"
                          value={deemedWorkingHours}
                          label="時間"
                          onChange={(e) => {
                            setDeemedWorkingHours(Number(e.target.value));
                          }}
                          min={0}
                          max={23}
                        />
                        <HourInput
                          name="minute"
                          value={deemedWorkingMinutes}
                          label="分"
                          onChange={(e) => {
                            setDeemedWorkingMinutes(Number(e.target.value));
                          }}
                          min={0}
                          max={59}
                        />
                      </FlexBox>
                    </FormField>
                  )
                },
                {
                  label: '雇用形態に設定されている日の契約労働時間をみなし勤務時間とする',
                  value: '1',
                },
              ]}
              name="yy"
              value={useEmploymentDeemedWorkingHours ? '1' : '0'}
              setValue={(e) => { setUseEmploymentDeemedWorkingHours(e.target.value !== '0'); }}
            />
          </FormField>
        </div>
      </FormContents>
    )} */}

    <FormField>
      <RadioSelectForm
        label="みなし時間を労働時間に"
        subLabel=""
        items={[
          {
            label: '含まない',
            value: '0',
          },
          {
            label: '含む',
            value: '1',
          },
        ]}
        name="isWorkTime"
        value={String(isWorkTime)}
        setValue={(e) => {
          setIsWorkTime(Number(e.target.value));
        }}
      />
    </FormField>

    <FormField>
      <RadioSelectForm
        label="半休の使用可否"
        subLabel=""
        items={[
          {
            label: '使用不可',
            value: '0',
          },
          {
            label: '使用可',
            value: '1',
          },
        ]}
        name="unUsableOfHalfHoliday"
        value={unUsableOfHalfHoliday ? '1' : '0'}
        setValue={(e) => {
          setUnUsableOfHalfHoliday(e.target.value !== '0');
        }}
      />
    </FormField>

    <FormField>
      <RadioSelectForm
        label="時間単位の休暇の使用可否"
        subLabel=""
        items={[
          {
            label: '使用不可',
            value: '0',
          },
          {
            label: '使用可',
            value: '1',
          },
        ]}
        name="unUsableOfTimeHoliday"
        value={unUsableOfTimeHoliday ? '1' : '0'}
        setValue={(e) => {
          setUnUsableOfTimeHoliday(e.target.value !== '0');
        }}
      />
    </FormField>

    <FormField>
      <RadioSelectForm
        label="試用期間中の有給使用可否"
        subLabel=""
        items={[
          {
            label: '使用不可',
            value: '1',
          },
          {
            label: '使用可',
            value: '0',
          },
        ]}
        name="unUsableOfTrialPeriod"
        value={unUsableOfTrialPeriod ? '1' : '0'}
        setValue={(e) => { setUnUsableOfTrialPeriod(e.target.value !== '0'); }}
      />
    </FormField>

  </FormContents>
);
export default DetailPaid1DayForm;
