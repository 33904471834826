import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getDigestionItemsForSpecialHolidayStatus,
  downloadSpecialHolidayStatusCsv,
  getSpecialHolidayStatusDetail,
  saveUseItemForSpecialHoliday,
} from 'api/holidayManagement';
import SpecialHolidayStatusDomain from 'domain/master/holidayManagement/specialHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export interface DetailItems {
  specialHolidayGrantId: string,
  holidayId: string,
  holidayName: string,
  staffCode: string,
  staffName: string,
  enteringGrantDay1: number,
  enteringGrantDay2: number,
  enteringGrantDay3: number,
  grantDaysNum: number,
  useEnteringGrantFlg1: boolean,
  useEnteringGrantFlg2: boolean,
  useEnteringGrantFlg3: boolean,
  useGrantFlg: boolean,
}

export const useManagementInformationDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [paidHolidayStatusList, SetPaidHolidayStatusList] = useState<Array<SpecialHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementSpecialHoliday.managementInformation.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [employmentId, setOrgEmploymentId] = useSaveStateStorage("", "holidayManagementSpecialHoliday.managementInformation.employmentId") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [specialManageEmpSelect, setSpecialManageEmpSelect] = useSaveStateStorage("", "holidayManagementSpecialHoliday.managementInformation.specialManageEmpSelect") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    specialHolidayGrantId: '',
    holidayId: '',
    holidayName: '',
    staffCode: '',
    staffName: '',
    enteringGrantDay1: 0,
    enteringGrantDay2: 0,
    enteringGrantDay3: 0,
    grantDaysNum: 0,
    useEnteringGrantFlg1: false,
    useEnteringGrantFlg2: false,
    useEnteringGrantFlg3: false,
    useGrantFlg: false,
  });
  const { successNotification, errorNotification } = useToastNotification();
  const [underFlag, setUnderFlag] = useSaveStateStorage("", "holidayManagementSpecialHoliday.managementInformation.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  const contextObject = useContext(HolidayManagementContext);

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getDigestionItemsForSpecialHolidayStatus(
        orgCode !== 'all' ? orgCode : '',
        employmentId || '',
        specialManageEmpSelect || '',
        underFlag || 0
      );
      contextObject.setIsLoading(false);
      SetPaidHolidayStatusList(response.map((result) => new SpecialHolidayStatusDomain(result)));
    }
  }, [employmentId, orgCode, specialManageEmpSelect, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, employmentId, specialManageEmpSelect]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callPaidHolidayStatusDetail = useCallback(async (
    staffCode: string,
    staffName: string,
    holidayId: string,
    holidayName: string,
    specialHolidayGrantId: string,
  ) => {
    try {
      await getSpecialHolidayStatusDetail(
        staffCode,
        staffName,
        holidayId,
        holidayName,
        specialHolidayGrantId,
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification]);

  //
  const callSaveUseItemForPaidHolidayStatus = useCallback(async () => {
    contextObject.setIsLoading(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const saveUser = sessionStorage.getItem('loginUser.staffCode') || '';
    const tmpDataPost = detailItems;
    (tmpDataPost as any).saveUser = saveUser;
    if (!(tmpDataPost as any).specialHolidayGrantId) {
      (tmpDataPost as any).specialHolidayGrantId = '';
    }
    try {
      await saveUseItemForSpecialHoliday(companyCode, tmpDataPost).then((response: any) => {
        setNoManager(!noManager);
        successNotification('更新しました。');
        setModalOpen(false);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }finally{
      contextObject.setIsLoading(false);
    }
  }, [detailItems, errorNotification, noManager, successNotification]);

  return {
    paidHolidayStatusList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    specialManageEmpSelect,
    setSpecialManageEmpSelect,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagement = (
  orgCode: string,
  employmentId: string,
  specialManageEmpSelect: string,
  underFlag: number
) => {

  const [blocking, setBlocking] = useState(false);

  const downloadPaidHolidayStatus = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadSpecialHolidayStatusCsv(
      isDownloadAll ? '' : orgCode,
      employmentId,
      specialManageEmpSelect,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [employmentId, specialManageEmpSelect, orgCode, underFlag]);

  return {
    blocking,
    downloadPaidHolidayStatus
  };
};

export default {
  useManagementInformationDomainForm,
};
