/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import { gridCol } from 'components/styles';
import { Terms } from 'domain/master/attend/terms';
import { jsx, css } from '@emotion/core';
import FlexBoxItem from 'components/atoms/FlexBoxItem';

const EmploymentAlertSettings: React.FC<{
  formik: any,
  termMst: Array<Terms>,
}> = ({
  formik,
}) => {
    const recessTimeAlertItems = [];
    const recessTimeAlert = 3;
    for (let i = 0; i < recessTimeAlert;) {
      recessTimeAlertItems.push(i);
      i += 1;
    }

    return (
      <React.Fragment>
        <div>
          <FormTitle title="アラート設定" bold={true} customStyle={css({marginLeft: '0'})}/>
          {/* <div>
        <FormField>
            <FlexBox>
              <FlexBoxItem width="150px">
                <FormLabel
                  label="次回付与日までに"
                />
              </FlexBoxItem>
              <FlexBoxItem width="80px">
                <div style={{ margin: '0px 10px' }}>
                  <HourInput
                    name="nextGrantDateAlert"
                    label=""
                    value={String(formik.values.nextGrantDateAlert)}
                    onChange={formik.handleChange} 
                  />
                </div>
              </FlexBoxItem> 
            </FlexBox>
          </FormField>
          <FormField>
            <FlexBox>
              <FlexBoxItem width="150px">
                <FormLabel
                  label="当年付与数"
                />
              </FlexBoxItem>
              <FlexBoxItem width="80px">
                <div style={{ margin: '0px 10px' }}>
                  <HourInput
                    name="currentGrantDayNumberAlert"
                    label=""
                    value={String(formik.values.currentGrantDayNumberAlert)}
                    onChange={formik.handleChange} 
                  />
                </div>
              </FlexBoxItem> 
            </FlexBox>
          </FormField>
          <FormField>
            <FlexBox>
              <FlexBoxItem width="150px">
                <FormLabel
                  label="消化数"
                />
              </FlexBoxItem>
              <FlexBoxItem width="80px">
                <div style={{ margin: '0px 10px' }}>
                  <HourInput
                    name="digestionDayNumberAlert"
                    label=""
                    value={String(formik.values.digestionDayNumberAlert)}
                    onChange={formik.handleChange} 
                  />
                </div>
              </FlexBoxItem> 
            </FlexBox>
          </FormField>
        </div> */}
          <p style={{ color: '#666666', margin: '30px 0px -5px' }}>残業</p>
          <div>
            <FormField>
              <FlexBox>
                <FlexBoxItem>
                  <FormLabel
                    label="1.当月残業アラート設定"
                  />
                </FlexBoxItem>
                <FlexBoxItem width="80px">
                  <div style={{ margin: '0px 10px' }}>
                    <HourInput
                      name="overTimeAlert"
                      label=""
                      value={String(formik.values.overTimeAlert)}
                      onChange={formik.handleChange}
                      min={0}
                      max={744}
                    />
                  </div>
                </FlexBoxItem>
                <FlexBoxItem>
                  <FormLabel
                    customStyle={css({ marginLeft: '15px' })}
                    label="時間を超える残業をアラートとする"
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>

            <FormField>
              <FlexBox>
                <FlexBoxItem>
                  <FormLabel
                    label="2.当月残業アラート設定"
                  />
                </FlexBoxItem>
                <FlexBoxItem width="80px">
                  <div style={{ margin: '0px 10px' }}>
                    <HourInput
                      name="overTimeAlert2"
                      label=""
                      value={String(formik.values.overTimeAlert2)}
                      onChange={formik.handleChange}
                      min={0}
                      max={744}
                    />
                  </div>
                </FlexBoxItem>
                <FlexBoxItem>
                  <FormLabel
                    customStyle={css({ marginLeft: '15px' })}
                    label="時間を超える残業をアラートとする"
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>

            <FormField>
              <FlexBox>
                <FlexBoxItem>
                  <FormLabel
                    label="3.当月残業アラート設定"
                  />
                </FlexBoxItem>
                <FlexBoxItem width="80px">
                  <div style={{ margin: '0px 10px' }}>
                    <HourInput
                      name="overTimeAlert3"
                      label=""
                      value={String(formik.values.overTimeAlert3)}
                      onChange={formik.handleChange}
                      min={0}
                      max={744}
                    />
                  </div>
                </FlexBoxItem>

                <FlexBoxItem>
                  <FormLabel
                    customStyle={css({ marginLeft: '15px' })}
                    label="時間を超える残業をアラートとする"
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>

            <div style={{ width: gridCol.grid12 }}>
              <div style={{ color: 'red', fontSize: '14px' }}>
                ※「0」を入力すると残業アラートが無効になります。
            </div>
            </div>

            {
              recessTimeAlertItems.map((value, key) => (
                <FormField key={key}>
                  <div className="col col-lg-6" style={{ marginRight: '12px' }}>
                    <FormLayout
                      label={(
                        <FormLabel
                          label={`休憩${String(value + 1)}`}
                        />
                      )}
                      input={(
                        <FlexBox>
                          <div style={{ width: '70px' }}>
                            <HourInput
                              name={`recessTimeAlertWorkTime${String(value + 1)}`}
                              value={
                                value === 0
                                  ? formik.values.recessTimeAlertWorkTime1
                                  : value === 1
                                    ? formik.values.recessTimeAlertWorkTime2
                                    : formik.values.recessTimeAlertWorkTime3
                              }
                              label=""
                              min={0}
                              max={1440}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <div style={{ margin: '0px 10px' }}>
                            <FormLabel
                              label="分をこえる勤務で"
                            />
                          </div>
                          <div style={{ width: '70px' }}>
                            <HourInput
                              name={`recessTimeAlertTime${String(value + 1)}`}
                              value={
                                value === 0
                                  ? formik.values.recessTimeAlertTime1
                                  : value === 1
                                    ? formik.values.recessTimeAlertTime2
                                    : formik.values.recessTimeAlertTime3
                              }
                              label=""
                              min={0}
                              max={1440}
                              onChange={formik.handleChange}
                            />
                          </div>
                          <div style={{ margin: '0px 10px' }}>
                            <FormLabel
                              label="分未満の休憩をアラートとする"
                            />
                          </div>
                        </FlexBox>
                      )}
                    />
                  </div>
                </FormField>
              ))
            }

            <FormField>
              <FormLayout
                label={(
                  <FormLabel
                    label="連勤"
                  />
                )}
                input={(
                  <FlexBox>
                    <FlexBoxItem width="100px">
                      <HourInput
                        name="continuousWorkAlert"
                        label=""
                        value={formik.values.continuousWorkAlert}
                        onChange={formik.handleChange}
                        min={0}
                        max={31}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <div style={{ lineHeight: '45px', marginLeft: '10px' }}>
                        <FormLabel
                          label="連勤以上をアラートとする"
                        />
                      </div>
                    </FlexBoxItem>
                  </FlexBox>
                )}
              />
            </FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ color: 'red', fontSize: '14px' }}>
                ※「0」を入力すると連勤チェックは行いません。
              </div>
            </div>

            <FormField >
              <div className="col col-lg-6" style={{ marginTop: '20px', marginRight: '12px' }}>
                <FormLayout
                  label={(
                    <FormLabel
                      label='勤務間インターバル'
                    />
                  )}
                  input={(
                    <FlexBox>
                      <div style={{ width: '70px' }}>
                        <HourInput
                          name='intervalAlertHour'
                          value={formik.values.intervalAlertHour}
                          label=""
                          min={0}
                          max={24}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div style={{ margin: '0px 10px' }}>
                        <FormLabel
                          label="時間"
                        />
                      </div>
                      <div style={{ width: '70px' }}>
                        <HourInput
                          name='intervalAlertMin'
                          value={formik.values.intervalAlertMin}
                          label=""
                          min={0}
                          max={59}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div style={{ margin: '0px 10px' }}>
                        <FormLabel
                          label="分未満の休息時間をアラートとする"
                        />
                      </div>
                    </FlexBox>
                  )}
                />
              </div>
            </FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ color: 'red', fontSize: '14px' }}>
                ※「0」を入力すると勤務間インターバルチェックは行いません。
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  };

export default EmploymentAlertSettings;
