/** @jsx jsx */
import { useState, useCallback, useMemo } from "react";
import { Column } from "react-table";
import { css, jsx } from "@emotion/core";
import DataTable from "components/organismos/DataTable/DataTable";
import { deleteById } from "api/attendAllowance";
import useToastNotification from "hooks/useToastNotification";
import { useAttendAllowance } from "./hooks";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useHistory } from "react-router-dom";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { SubActionButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import AttendAllowanceDomain from "domain/attendAllowance";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";

const styles = {
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
};

const AttendAllowancePage = () => {
  const history = useHistory();
  const { allowanceList, setAllowanceList, blocking, setBlocking, detailRole } = useAttendAllowance();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedAttendAllowance, setSelectedAttendAllowance] = useState(AttendAllowanceDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((allowance: AttendAllowanceDomain) => {
    setConfirmModalOpen(true);
    setSelectedAttendAllowance(allowance);
  }, []);

  const deleteAttendAllowance = useCallback(() => {
    setConfirmModalOpen(false);
    setBlocking(true);
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    deleteById(companyCode, selectedAttendAllowance.allowanceId)
      .then((response: any) => {
        setAllowanceList(
          allowanceList.filter((allowance) => allowance.allowanceId !== selectedAttendAllowance.allowanceId),
        );
        setBlocking(false);
        successNotification("削除しました。");
      })
      .catch((error) => {
        setBlocking(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage}\n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    selectedAttendAllowance.allowanceId,
    setAllowanceList,
    successNotification,
    allowanceList,
    setBlocking,
  ]);
  const columns: Array<Column<AttendAllowanceDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAllowanceDomain } }) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FlexBox>
                <FlexBoxItem marginLeft="5px">
                  <SubActionButton
                    action={0}
                    text={detailRole.editable === 1 ? `編集` : `参照`}
                    onClick={() => {
                      history.push(`/attendAllowance/edit/${cell.row.original.allowanceId}`);
                    }}
                  />
                </FlexBoxItem>
                {detailRole.editable === 1 ? (
                  <FlexBoxItem marginLeft="5px">
                    <SubActionButton
                      action={1}
                      text="削除"
                      onClick={() => {
                        openConfirmModal(cell.row.original);
                      }}
                    />
                  </FlexBoxItem>
                ) : null}
              </FlexBox>
            </div>
          );
        },
      },
      {
        Header: "コード",
        accessor: "allowanceCode",
      },
      {
        Header: "手当名称",
        accessor: "allowanceName",
      },
    ],
    [openConfirmModal, history, detailRole],
  );

  return (
    <SidebarTemplate>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle bold={true} title="手当マスタ" />
          {detailRole.editable === 1 && (
            <FormSubmitArea customStyle={css({ margin: "8px 0 0 25px" })}>
              <IconLabelButton
                onClick={() => history.push("/attendAllowance/create")}
                iconType="addCircle"
                text="追加"
              />
            </FormSubmitArea>
          )}
          <div
            css={css`
              table {
                table-layout: auto;
              }
              table th:nth-of-type(1) {
                width: 100px;
              }
              table th:nth-of-type(2) {
                width: 100px;
                text-align: left;
              }
              table th:nth-of-type(3) {
                width: calc(100% - 200px);
                text-align: left;
              }
            `}
          >
            <div className="table-bordered" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <DataTable
                columns={columns}
                data={allowanceList}
                isGlobalFilter={true}
                globalSearchStyle={styles.filterSearch}
              />
            </div>
          </div>
          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            title="手当マスタを削除"
            content="手当マスタを削除します。よろしいですか？"
            submitText="削除する"
            onSubmit={deleteAttendAllowance}
            typeSubmit={"reject"}
          />
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default AttendAllowancePage;
