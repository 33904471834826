/** @jsx jsx */
import React, { useCallback, useState, useEffect } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { productColor, textFontSize, utilityColor, grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import { ICCardByStaffDomain } from "domain/master/icCard/listICCardByStaff";
import Modal from "components/molecules/Modal";
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import TextForm from 'components/molecules/TextForm';
import FormLabel from 'components/atoms/Form/FormLabel';
import { PrimaryButton } from "components/atoms/Button";
import useToastNotification from "hooks/useToastNotification";
import * as icCard from 'hooks/useICCard';
import { getDetailCardId } from 'api/iCCard';
import useAudio from 'hooks/useAudio';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { useDispatch } from 'react-redux';
import { uiAppBarActions } from 'modules/ui/appBar';
import { icCardActions } from 'modules/ui/icCard';
import { ICCardConnectButton } from "components/atoms/Button";

const AUTO_PAUSE_DETECT_CARD = false;

const styles = {
  containerInput: css({
    boxSizing: "border-box",
    width: "100%",
    fontSize: "14px",
    fontFamily: "inherit",
    padding: "2px",
    border: "1px solid #DCDCDC",
    borderRadius: "3px",
    color: "#333333",
  }),
  item: css({
    margin: "10px",
    display: "inline-block",
    padding: "7px 25px 7px 7px",
    position: "relative",
    backgroundColor: "hsl(0,0%,90%)",
    borderRadius: "12px",
    background: "#e6f2ff",
    color: "#007BC3"
  }),
  closeIcon: css({
    position: "absolute",
    right: "3px",
    top: "53%",
    transform: "translateY(-50%)",
  }),
  addIcon: css({
    "&:hover,&:focus": css({
      opacity: '0.8'
    }),
    "svg": css({
      marginBottom: "4px",
    })
  }),
  containerFormAdd: css({
    backgroundColor: '#F8F8F8',
    border: '1px solid #999999',
    padding: '10px',
    width: '570px',
    height: 'auto'
  }),
  labelFormAdd: css({
    fontSize: '16px',
    fontWeight: 'bold'
  }),
  buttonConnect: css({
    height: '38px',
    lineHeight: '0',
    width: '50px',
    minWidth: '100px',
    fontWeight: 'bold',
    padding: '0',
    marginLeft: '35px'
})
};

interface ICCardInputProps {
  listICCard: Array<any>,
  staffCode: string,
  staffName: string,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  customStyle?: SerializedStyles,
  formik: any
}
const { errorNotification, successNotification } = useToastNotification();

const ICCardInput: React.FC<ICCardInputProps> = ({
  listICCard, staffCode, staffName, setIsLoading, customStyle, formik
}) => {

  // redux
  const dispatch = useDispatch();

  const [openModalAddCode, setOpenModalAddCode] = useState(false);
  const [isConnectUsb, setIsConnectUsb] = useState(false);
  const [codeICCard, setCodeICCard] = useState('')
  const [idCode, setIdCode] = useState('');
  const playAudioSuccess = useAudio('/audio/soundDetectSuccess.mp3').playAudio;

  const isConnectICCardUsb = useSelector((state:Store) => state.ui.icCard.isConnectICCardUsb);
  const deviceICCard = useSelector((state:Store) => state.ui.icCard.deviceICCard);
  const cardId = useSelector((state:Store) => state.ui.icCard.cardId);
  const [actionDetect, setActionDetect] = useState(false);

  const detectCard = () => {
    try {
      if(!deviceICCard){
        icCard.connectDevice(dispatch);
      }
      setActionDetect(true);
      // icCard.detectCard(deviceICCard, dispatch);
      // icCard.detectCard(setCodeICCard, setIsConnectUsb, dispatch);
    } catch (error) {
      errorNotification(error);
    }
  }

  useEffect(() => {
    if(deviceICCard && actionDetect && dispatch){
      icCard.detectCard(deviceICCard, dispatch, AUTO_PAUSE_DETECT_CARD);
    }
  }, [deviceICCard, actionDetect, dispatch])

  useEffect(() => {
    return () =>{
      dispatch(icCardActions.setDisabledDetect(false));
      dispatch(icCardActions.setHardCardId(''));
    }
  }, [])

  useEffect(() => {
    if(cardId){
      playAudioSuccess();
      setIdCode(cardId);
    }
  }, [cardId])

  const handleAddCode = () => {
    dispatch(icCardActions.setDisabledDetect(false));
    setOpenModalAddCode(true);
  }

  const handleRegisterStaffICCard = async () => {
    setIsLoading!(true);
    if (listICCard.filter((card) => card.cardId === idCode).length > 0){
      setIsLoading!(false);
      errorNotification('スタッフはすでにこのカードを持っています。');
      return;
    };
    const response = await getDetailCardId(idCode);
    if(response && response.cardId !== null && response.staffCode !== staffCode){
      setIsLoading!(false);
      errorNotification('このカードは他のスタッフに登録されています。');
      return;
    }
    setIsLoading!(false);
    let tmpList = [...listICCard];
    let tmpItem: any;
    if(listICCard.length > 0){
      tmpItem = JSON.parse(JSON.stringify(tmpList[0]));
      tmpItem.cardId = idCode;
    }else{
      tmpItem = {
        cardId: idCode,
      }
    }
    tmpList.push(tmpItem);
    formik.setFieldValue('icCardListData', tmpList);
    setOpenModalAddCode(false);
    setIdCode('');
  }
  const handleDeletedICCard = (cardId: string) => {
    let tmpList = [...listICCard];
    tmpList = tmpList.filter((item) => item.cardId !== cardId);
    formik.setFieldValue('icCardListData', tmpList);
  }

  return (
    <React.Fragment>
      <div css={css([styles.containerInput, customStyle])}>
        {listICCard.map((card) => (
          <div css={styles.item} key={card.cardId}>
            <div>
              {card.cardId}
            </div>
            <span onClick={() => handleDeletedICCard(card.cardId)}>
              <Icon type="close" color={productColor.primary} customStyle={styles.closeIcon} />
            </span>
          </div>
        ))}
          <div css={css({
            display: 'inline-block',
            margin: '10px'
          })}>
            <span onClick={() => handleAddCode()}>
              <Icon size="23px" type={"addCircle"} color="" verticalAlign="middle" customStyle={styles.addIcon}/>
            </span>
          </div>
      </div>
      <Modal
        open={openModalAddCode}
        closeHandler={() => {
          dispatch(icCardActions.setDisabledDetect(true));
          dispatch(icCardActions.setHardCardId(''));
          setOpenModalAddCode(false);
          setIdCode('');
        }}
        title="ICカードIDで登録"
        disabledSubmit={!/\S/.test(idCode)}
        submitText="保存"
        onSubmit={() => handleRegisterStaffICCard()}
        typeSubmit="confirm"
        customStyleFooter={css`
          button{
              min-width: 150px;
          }
        `}
        >
        <BodyText bold={true}>スタッフ</BodyText>
        <BodyText>： {staffCode} {staffName}</BodyText>
        <div css={styles.containerFormAdd}>
          <FlexBox justifyContent="space-between">
              <FormLabel label="カード番号(IDm)" customStyle={styles.labelFormAdd}/>
              <ICCardConnectButton
                disabled={isConnectICCardUsb}
                onClick={() => {
                    detectCard();
                }}
              />
          </FlexBox>
          <BodyText color='#F21B1B' size="sm">カード番号を入力するかカードリーダでカードを読み取って登録してください</BodyText>
          <FlexBox customStyle={css({marginTop: '5px'})}>
              <div style={{width: 'auto'}}>
                <TextForm
                  required={false}
                  name="idCode"
                  label=""
                  value={idCode}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                        e.preventDefault();
                      }
                  }}
                  onChange={(e) => {
                      if(e.currentTarget.value.includes(" ")) {
                          e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
                      }
                      setIdCode(e.target.value)}
                  }
                  placeHolder="カード番号(IDm)"
                  customStyle={css({
                      width: '300px',
                      height: '40px',
                  })}
                />
              </div>
          </FlexBox>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ICCardInput;
