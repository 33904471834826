import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getDigestionItemsForPaidHolidayDigestedtHistory,
  downloadPaidHolidayDigestionCsv,
  getDigestionItemsDetail,
  getPaidAttendManagementList,
  downloadPaidAttendHolidayManagementExcel
} from 'api/holidayManagement';
import moment from 'moment';
import PaidHolidayDigestedtHistoryDomain from 'domain/master/holidayManagement/paidHolidayDigestedtHistory';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useSaveStateStorage, { useSaveStateDateStorage } from 'hooks/useSaveFilterStorage';

export interface DetailItems {
  staffCode: string,
  staffName: string,
  orgCode: string,
  orgName: string,
  digestionDays: number,
  digestionTimes: number,
  fromDate: Date,
  toDate: Date,
  paidHolidayDigestionDetailList: [],
  digestion: string,
  effectiveStartDate: Date
}

export const useAcquisitionStatusDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [paidHolidayDigestedtHistoryList, setPaidHolidayDigestedtHistoryList] = useState<Array<PaidHolidayDigestedtHistoryDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementPaid.acquisitionStatusForm.underFlag") as [number | null, React.Dispatch<React.SetStateAction<number | null>>];

  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementPaid.acquisitionStatusForm.orgCode") as [string | null, React.Dispatch<React.SetStateAction<string | null>>];

  const [orgCodeName, setOrgCodeName] = useState(sessionStorageOrgCode);
  const date = moment(new Date()).format('YYYY/MM/DD');
  const [searchDate, setSearchDate] = useSaveStateStorage(date, "holidayManagementSpecialHoliday.acquisitionStatus.searchDate") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenDownload, setModalOpenDownload] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    staffCode: '',
    staffName: '',
    orgCode: '',
    orgName: '',
    digestionDays: 0,
    digestionTimes: 0,
    fromDate: new Date(),
    toDate: new Date(),
    paidHolidayDigestionDetailList: [],
    digestion: '',
    effectiveStartDate: new Date()
  });
  const { errorNotification } = useToastNotification();

  const contextObject = useContext(HolidayManagementContext);

  // データの取得
  const fetchData = useCallback(async () => {
    if (orgCode !== null) {
      contextObject.setIsLoading(true);
      const response = await getDigestionItemsForPaidHolidayDigestedtHistory(
        orgCode !== 'all' ? orgCode : '',
        searchDate || date,
        underFlag || 0
      );
      contextObject.setIsLoading(false);
      setPaidHolidayDigestedtHistoryList(response.map((result) => new PaidHolidayDigestedtHistoryDomain(result)));
    }
  }, [orgCode, searchDate, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    paidHolidayDigestedtHistoryList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    searchDate,
    setSearchDate,
    date,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    setOrgCodeName,
    orgCodeName,
    setModalOpenDownload,
    modalOpenDownload,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagementAcquisitionStatus = (
  orgCode: string,
  targetDate: string,
  orgCodeName: string,
  setModalOpenDownload: any,
  underFlag: number
) => {

  const [blocking, setBlocking] = useState(false);

  const downloadHolidayManagementAcquisitionStatus = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadPaidHolidayDigestionCsv(
      isDownloadAll ? '' : orgCode,
      targetDate,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [orgCode, targetDate, underFlag]);

  const downloadPaidAttendHolidayManagement = useCallback(() => {

    const headerInformation = [
      ['全体', '', '', '', '', '', '', '', '', '', '', '', '', '', '有給取得義務情報', '', '', '', ''],
      ['組織コード', '組織名', '社員番号', '氏名', '入社日', '雇用形態', '付与日', '付与日数', '失効日', '前年繰越日数', '有休／時間休取得日',
        '有給取得日数', '有給残日数', '有給取得率', '取得義務期間', '有給取得日', '取得日数', '取得義務日数', '要取得有給残日数'],
    ];
    const searchResult: any[] = [];
    const mergedRegions: any[] = [
      [3, 3, 0, 13],
      [3, 3, 14, 18],
    ];

    setBlocking(true);

    const daysNumDecimal = (val: number) => {
      return val.toFixed(1) + '日';
    }

    const hoursNumDecimal = (val: number) => {
      return val.toFixed(1) + 'h';
    }

    getPaidAttendManagementList(orgCode, String(moment(moment(targetDate).toDate()).format('YYYY/MM/DD')), underFlag).then((resp: any) => {
      resp.forEach((e: any) => {
        const {
          staffCode, staffName, hireDateStr, employmentName, effectiveStartDateStr, grantDaysNum, grantTimesNum, effectiveEndDateStr,
          lastGrantDaysNum, lastGrantTimesNum, allPaidHolidayList, digestedTimeHour, rateOfPaidHoliday, paidHolidayList, digestedDaysNum, obligationDays,
          remainDaysNum, remainTimesNumHour, orgCode, orgName
        } = e;
        searchResult.push([
          orgCode,
          orgName,
          staffCode,
          staffName,
          hireDateStr,
          employmentName,
          effectiveStartDateStr,
          daysNumDecimal(grantDaysNum) + "" + hoursNumDecimal(grantTimesNum),
          effectiveEndDateStr,
          daysNumDecimal(lastGrantDaysNum) + "" + hoursNumDecimal(lastGrantTimesNum),
          allPaidHolidayList.length > 0 ? allPaidHolidayList.join('\r\n') : '',
          daysNumDecimal(digestedDaysNum) + "" + hoursNumDecimal(digestedTimeHour),
          daysNumDecimal(remainDaysNum) + "" + hoursNumDecimal(remainTimesNumHour),
          rateOfPaidHoliday + '%',
          effectiveStartDateStr + "〜" + effectiveEndDateStr,
          paidHolidayList.length > 0 ? paidHolidayList.join('\r\n') : '',
          daysNumDecimal(digestedDaysNum),
          daysNumDecimal(obligationDays),
          obligationDays < digestedDaysNum ? daysNumDecimal(0) : daysNumDecimal(obligationDays - digestedDaysNum)
        ]);
      });
    }).finally(() => {
      const dataExcel = {
        formName: '有給管理簿',
        targetPeriod: '対象期間: ' + moment(targetDate, 'YYYY-MM-DD HH:mm:ss').subtract(1, 'years').add(1, 'days').format('YYYY年MM月DD日') + '〜' + moment(targetDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY年MM月DD日'),
        organizationName: '組織名: ' + orgCodeName,
        headerInformation,
        searchResult,
        mergedRegions,
      };
      downloadPaidAttendHolidayManagementExcel(dataExcel, '有給管理簿');
      setBlocking(false);
      setModalOpenDownload(false);
    });
  }, [orgCode, targetDate, orgCodeName, setModalOpenDownload, underFlag]);

  return {
    downloadHolidayManagementAcquisitionStatus,
    blocking,
    downloadPaidAttendHolidayManagement
  };
};

export default {
  useAcquisitionStatusDomainForm,
};
