/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';

import { utilityColor } from 'components/styles';
import Calendar from 'components/molecules/Calendar';
import Icon from 'components/atoms/Icon';

import { useShiftMonthCalendarContext } from './hooks';

const styles = {
  warning: css({
    position: 'relative',
    top: '-26px',
    left: '20px',
  }),
};

const ShiftMonthCalendarDateHeader: React.FC = (props) => {
  const { events } = useShiftMonthCalendarContext();
  const { date } = props as {
    date: Date;
  };

  // 対象日付のeventデータを取得して、アラートを出すかのフラグを取り出す
  const targetEvent = React.useMemo(() => events.filter((event) => moment(event.start).isSame(moment(date), 'day'))[0], [date, events]);
  const isAlert = targetEvent?.isAlert;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Calendar.DateHeader {...props}>
      {isAlert && (
        <div css={styles.warning}>
          <Icon type="warning" color={utilityColor.error} />
        </div>
      )}
    </Calendar.DateHeader>
  );
};

export default ShiftMonthCalendarDateHeader;
