
/** @jsx jsx */

import React, { useState, forwardRef, useEffect, useRef } from "react";
import { components } from 'react-select';
import { css, jsx } from '@emotion/core';
import { FixedSizeList as List } from "react-window";

export const Option = (props: any) => {
    const ref = useRef<null | HTMLElement>(null);
    useEffect(() => {
        if (props.isSelected) {
            ref?.current?.scrollIntoView({block: 'nearest', inline: 'end' });
        }
    }, [props.isSelected]);

    return (
        <components.Option {...props} innerRef={ref} />
    );
};

export const Input = (props: any) => {

    return (
        <div className="input-container-react-select">
            <components.Input {...props} />
        </div>
    );
};

export const IndicatorsContainer = (props: any) => {
    return (
        <div className="indicators-container">
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

export const MenuComponent = (props: any) => {
    const ref = useRef<null | HTMLElement>(null);
    return (
        <div className="menu-container">
            <components.MenuList {...props} listRef={ref}/>
        </div>
    );
};

export const SingleValueContainer = (props: any) => {
    return (
        <div className="single-value-custom">
            <components.SingleValue {...props} />
        </div>
    );
};