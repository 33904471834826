import ApiClient from './ApiClient';

export interface PosType {
  posNo: number;
  posName: string;
  dispOrder: number;
}

export const getListAdvertisingMediaOrganization = async (orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { orgCode };

  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaOrganization/${companyCode}`, params);
  return response.data;
};

export const deleteAdvertisingMediaOrganization = async (isConfirmed: number,
  advertisingMediaOrganization: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isConfirmed,
  };

  const response = await ApiClient.post(`/v1/sales/master/advertisingMediaOrganization/delete/${companyCode}`, params, advertisingMediaOrganization);
  return response.data;
};

export const createOrUpdateAdvertisingMediaOrganization = async (isCreate: boolean,
  advertisingMediaOrganization: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 1 : 0,
  };
  const response = await ApiClient.post(`/v1/sales/master/advertisingMediaOrganization/${companyCode}`, params, advertisingMediaOrganization);
  return response.data;
};

export const getAdvertisingMediaOrganizationList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaOrganizationMstToSelect/${companyCode}`, {});
  return response.data;
};

export const getPostTypeList = async (): Promise<Array<PosType>> => {
  const response = await ApiClient.get('/v1/posType', {});
  return response.data;
};

export default getListAdvertisingMediaOrganization;
