import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AreasIndexPage from 'components/organismos/master/sales/AreasIndexForm';

const AreasPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="エリアマスタ"
  >
    <AreasIndexPage />
  </SidebarTemplate>
);

export default AreasPage;
