/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import DatePicker from './DatePicker';

const styles = {
  datePicker: css({
    marginTop: '4px',
    width: '148px',
  }),
  wrapper: css({
    width: '100%',
  }),
};

const DatePickerForm: React.FC<{
  label: string;
  date?: Date;
  dateFormat?: string;
  required?: boolean;
  isFullWidth?: boolean;
  showMonthYearPicker?: boolean;
  changeDate: (arg: Date) => void;
  errorMsg?: string;
  disable? : boolean;
  isShowDateOnDisable?: boolean;
  readOnly?: boolean;
}> = ({
  label, date, changeDate, dateFormat, isFullWidth = false, showMonthYearPicker = false,
  required, errorMsg, disable, isShowDateOnDisable, readOnly,
}) => (
  <VerticalLabelFormLayout
    label={(
      <FormLabel
        label={label}
        required={required}
      />
  )}
    input={(
      <div css={styles.datePicker}>
        <DatePicker
          dateFormat={dateFormat}
          date={date}
          changeDate={changeDate}
          showMonthYearPicker={showMonthYearPicker}
          isFullWidth={isFullWidth}
          disabled={disable}
          isShowDateOnDisable={isShowDateOnDisable}
          readOnly={readOnly}
        />
      </div>
  )}
    errorMsg={errorMsg}
  />
);

export default DatePickerForm;
