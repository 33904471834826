import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ApprovalOvertimeApplication from 'components/organismos/master/attend/ApprovalApplication/ApprovalOvertimeApplication';

const ApprovalOvertimeApplicationPage = () => (
  <SidebarTemplate pageTitle="残業申請 承認・差戻">
    <ApprovalOvertimeApplication />
  </SidebarTemplate>
);

export default ApprovalOvertimeApplicationPage;
