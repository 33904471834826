
export type StoreShiftView = {
  businessName: string,
  endTime: Date,
  isEndNextDay: boolean,
  isHelp: boolean,
  isStartNextDay: boolean,
  orgCode: string,
  orgName: string,
  startTime: Date,
}


export class StoreShiftViewDomain {
  constructor(private rawData: StoreShiftView) {
    // do nothing
  }

  static generateInitial(): StoreShiftViewDomain {
    return new StoreShiftViewDomain({
      businessName: '',
      endTime: new Date(),
      isEndNextDay: false,
      isHelp: false,
      isStartNextDay: false,
      orgCode: sessionStorage.getItem('loginUser.orgCode') || '',
      orgName: sessionStorage.getItem('loginUser.orgName') || '',
      startTime: new Date(),
    });
  }

  public getRawData() {
    return this.rawData;
  }

  get businessName(): string {
    return this.rawData.businessName;
  }

  set businessName(businessName: string) {
    this.rawData.businessName = businessName;
  }

  get isEndNextDay(): boolean {
    return this.rawData.isHelp;
  }

  set isEndNextDay(isEndNextDay: boolean) {
    this.rawData.isEndNextDay = isEndNextDay;
  }

  get isHelp(): boolean {
    return this.rawData.isHelp;
  }

  set isHelp(isHelp: boolean) {
    this.rawData.isHelp = isHelp;
  }

  get isStartNextDay(): boolean {
    return this.rawData.isHelp;
  }

  set isStartNextDay(isStartNextDay: boolean) {
    this.rawData.isStartNextDay = isStartNextDay;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }


  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get startTime(): Date {
    return this.rawData.startTime;
  }

  set startTime(startTime: Date) {
    this.rawData.startTime = startTime;
  }

  get endTime(): Date {
    return this.rawData.endTime;
  }

  set endTime(endTime: Date) {
    this.rawData.endTime = endTime;
  }
}
