
/**
 * APIのデータ型
 */
export interface BusinessCategory {
  companyCode: string;
  businessCategoryId: string;
  businessCategoryCode: string;
  businessCategoryName: string;
  createUser: string;
  updateUser: string;
}

export default class BusinessCategoryDomain {
  constructor(private rawData: BusinessCategory) {
    // do nothing
  }

  static generateInitial(): BusinessCategoryDomain {
    return new BusinessCategoryDomain({
      companyCode: sessionStorage.getItem('loginUser.companyCode') || '',
      businessCategoryId: '',
      businessCategoryCode: '',
      businessCategoryName: '',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    });
  }

  getRawData(): BusinessCategory {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get businessCategoryId(): string {
    return this.rawData.businessCategoryId;
  }

  set businessCategoryId(businessCategoryId: string) {
    this.rawData.businessCategoryId = businessCategoryId;
  }

  get businessCategoryCode(): string {
    return this.rawData.businessCategoryCode;
  }

  set businessCategoryCode(businessCategoryCode: string) {
    this.rawData.businessCategoryCode = businessCategoryCode;
  }

  get businessCategoryName(): string {
    return this.rawData.businessCategoryName;
  }

  set businessCategoryName(businessCategoryName: string) {
    this.rawData.businessCategoryName = businessCategoryName;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }
}
