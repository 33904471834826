/**
 * APIのデータ型
 */
interface Area {
  companyCode: string;
  areaCode: string;
  areaName: string;
  areaId: string;
  isEdit:boolean;
  data: any,
  createUser: string,
  updateUser: string
}

export default class AreaDomain {
  constructor(private rawData: Area) {
    // do nothing
  }

  static generateInitial(): AreaDomain {
    return new AreaDomain({
      areaCode: '',
      areaName: '',
      companyCode: '',
      areaId: '',
      isEdit: false,
      data: [],
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): Area {
    return this.rawData;
  }

  get areaCode(): string {
    return this.rawData.areaCode;
  }

  set areaCode(areaCode: string) {
    this.rawData.areaCode = areaCode;
  }

  get areaName(): string {
    return this.rawData.areaName;
  }

  set areaName(areaName: string) {
    this.rawData.areaName = areaName;
  }

  get areaId(): string {
    return this.rawData.areaId;
  }

  set areaId(areaId: string) {
    this.rawData.areaId = areaId;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get createUser():string {
    return this.rawData.createUser;
  }

  set createUser(createUser:string) {
    this.rawData.createUser = createUser;
  }


  get updateUser():string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser:string) {
    this.rawData.updateUser = updateUser;
  }

  get isEdit(): boolean {
    return this.rawData.isEdit;
  }
}
