/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useCallback } from "react";
import useToastNotification from "hooks/useToastNotification";
import * as icCard from 'hooks/useICCard';
import { useOrgTreesOptionsShopV1 } from 'hooks/useOrgTreesOptions';
import { getStaffICCard } from 'api/iCCard';
import { StaffICCardDomain } from "domain/master/icCard/staffICCard";
import { getListICCardByStaff, postRegisterStaffBulkICCard } from 'api/iCCard';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import useSaveStateStorage from "hooks/useSaveFilterStorage";

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";

const { errorNotification, successNotification } = useToastNotification();

export const useMasterICCardSettingForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [isConnectUsb, setIsConnectUsb] = useState(false);
  const [codeICCard, setCodeICCard] = useState('')
  const orgTreesOptions = useOrgTreesOptionsShopV1(true, functionCode.masterICCard, undefined, undefined);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "masterICCard.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [isLoading, setIsLoading] = useState(false);
  const [listStaffICCard, setListStaffICCard] = useState<Array<StaffICCardDomain>>([]);
  const [listStaffICCardFilter, setListStaffICCardFilter] = useState<Array<StaffICCardDomain>>([]);
  const [filterListHaveNotCard, setFilterListHaveNotCard] = useState(sessionStorage.getItem('masterICCard.filterListHaveNotCard') || '0')
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterICCard);
  const [underFlag, setUnderFlag] = useState(0);

  const detectCard = () => {
    try {
      icCard.detectCard(setCodeICCard, setIsConnectUsb);
    } catch (error) {
      errorNotification(error);
    }
  }
  
  const fetchStaffICCard = useCallback(async () => {
    if (orgCode === null || orgCode === '') {
      return;
    }
    setIsLoading(true);
    getStaffICCard(
      (orgCode !== 'all' && orgCode !== null) ? orgCode : '',
      functionCode.masterICCard,
      underFlag
    ).then((response: any) => {
      setListStaffICCard(response);
      setListStaffICCardFilter(response.filter((staff: any) => staff.cardNumber === '' ));
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    })
    ;

  }, [orgCode, underFlag, functionCode]);

  useEffect(() => {
    fetchStaffICCard();
  }, [orgCode, underFlag])

  const registerStaffBulkICCard = useCallback(async (staffCode: string, listICCardByStaff: any) => {
    let listCard = listICCardByStaff.map((card: any) => card.cardId);
    setIsLoading!(true);
    postRegisterStaffBulkICCard(
      listCard.toString(),
      staffCode || "",
    ).then((response: any) => {
      setIsLoading!(false);
    })
    .catch((error: any) => {
      setIsLoading!(false);
      if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }      
    });
  }, []);


  return {
    isConnectUsb,
    setIsConnectUsb,
    codeICCard,
    setCodeICCard,
    detectCard,
    orgTreesOptions,
    orgCode,
    setOrgCode,
    listStaffICCard,
    isLoading,
    setIsLoading,
    registerStaffBulkICCard,
    fetchStaffICCard,
    filterListHaveNotCard,
    setFilterListHaveNotCard,
    listStaffICCardFilter,
    setListStaffICCard,
    setListStaffICCardFilter,
    detailRole,
    underFlag,
    setUnderFlag
  };
};
export default useMasterICCardSettingForm;
