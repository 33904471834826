import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'querystring';

const domainUrl = process.env.REACT_APP_API_BASE_URL;


export const generateRandomString = (length:number)=>{
    let text = "";
    const char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i=0; i < length; i++ )
    {  
      text += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return text;
};

export const hashSHA256 = (key:string)=>{
    const crypto = require("crypto");
    const sha256 = crypto.createHash("sha256");
    sha256.update(key);
    let URLSafeBase64 = require('urlsafe-base64');
    return URLSafeBase64.encode(sha256.digest("base64"));
};

export const getOAuthCode = (redirectURI:any, tenant:string):string=>{
    let codeVerifier = generateRandomString(43);
    localStorage.setItem('code_verifier', codeVerifier);
    let codeChallenge = hashSHA256(codeVerifier);
    const clientId = process.env.REACT_APP_PEOPLE_CLIENT_ID;
    const peopleURI = process.env.REACT_APP_PEOPLE_URL;
    if (tenant!=="" && tenant !== null){
      tenant = "/"+tenant;
    }
    return `${peopleURI}${tenant}/oauth?client_id=${clientId}&redirect_uri=${redirectURI}&code_challenge_method=S256&code_challenge=${codeChallenge}&scope=&state=test&response_type=code`;
    // return `${peopleURI}/ppstg_hinata/oauth?client_id=${clientId}&redirect_uri=${redirectURI}&code_challenge_method=S256&code_challenge=${codeChallenge}&scope=&state=test&response_type=code`;
    
};


export const getOAuthCodeV2 = (redirectURI:any):string=>{
  let codeVerifier = generateRandomString(43);
  localStorage.setItem('code_verifier', codeVerifier);
  let codeChallenge = hashSHA256(codeVerifier);
  const clientId = process.env.REACT_APP_PEOPLE_CLIENT_ID;
  const peopleURI = process.env.REACT_APP_PEOPLE_URL;
  return `${peopleURI}/oauth?client_id=${clientId}&redirect_uri=${redirectURI}&code_challenge_method=S256&code_challenge=${codeChallenge}&scope=&state=test&response_type=code`;
  
};

export const getOAuthToken = async (
        code:any, redirectURI:any
    ): Promise<any> => {
      const peopleURI = process.env.REACT_APP_PEOPLE_URL;
    // const url = `${peopleURI}/oauth2/token`;
    const url = `${peopleURI}/api/oauth/token`;
    const codeVerifier = localStorage.getItem('code_verifier');
    const clientId = process.env.REACT_APP_PEOPLE_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_PEOPLE_CLIENT_SECRET;
    let params = {
        grant_type:"authorization_code",
        code,
        code_verifier:codeVerifier,
        client_id:clientId,
        client_secret:clientSecret,
        redirect_uri:redirectURI
    }
    const requestUrl = `${url}?${stringify(params)}`;
    let authorization = btoa(`${clientId}:${clientSecret}`);
    const config : AxiosRequestConfig = {
      headers: {
        'Content-type':'application/x-www-form-urlencoded'
      }
    };
    // localStorage.removeItem('code_verifier');
    const response = await axios.post(requestUrl, {}, config);
    return response;
};

export const getPeopleUserInfo = async (
    token: any,
    email: string,
  ): Promise<any> => {
    localStorage.removeItem('code_verifier');
    const peopleURI = process.env.REACT_APP_PEOPLE_URL;
    const url = `${peopleURI}/ppstg_hinata/oauth/userInfo`;
  
    const config : AxiosRequestConfig = {
      headers: {
        'Content-type':'application/x-www-form-urlencoded',
        'Authorization':`Bearer ${token}`
      }
    };
  
    // const response = await axios.get(url, config);
    const userId = localStorage.getItem('PEOPLE_USER_ID');
    const tenant = localStorage.getItem('PEOPLE_TENANT');
    let response = new Promise((resolve, reject)=>{
      const demoResult={
        status:200,
        data:{
          email,
          user_id:userId,
          tenant:tenant
        }
      };
      resolve(demoResult);
    });
    return response;
  };

export default generateRandomString;