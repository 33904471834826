/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { gridCol } from 'components/styles';
import IconTooltip from 'components/molecules/IconTooltip';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { eventScroll } from './hooks';

const BudgetYearTable: React.FC<{
  listAdvertising: any;
  listAdvertisingExpense: any;
  salesBudgetMonthly: any;
  CheckExist: any;
  getTotal: any;
  getInputDisable: any;
  formik: any;
  calculatorTable: any;
  getListAdvertisingApplyPeriod: any;
}> = ({
  listAdvertising,
  listAdvertisingExpense,
  salesBudgetMonthly,
  CheckExist,
  getTotal,
  getInputDisable,
  formik,
  calculatorTable,
  getListAdvertisingApplyPeriod,
}) => {
  const history = useHistory();
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formatterDouble = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'percent',
  });

  const handleClickMonth = (e: any) => {
    sessionStorage.setItem('month_selected', e);
    return history.push('/salesBudgetMonth');
  };

  const handleInputChange = (name: any, value: any) => {
    const { target } = value;
    if (target.value) {
      formik.setFieldValue(name, Number(target.value));
    }
    calculatorTable(formik.values);
  };

  const orgCode = sessionStorage.getItem('orgCode');

  const listAdvertisingDisabled: any[] = [];
  const listAdvertisingExpenseDisabled: any[] = [];

  salesBudgetMonthly.map((element: any, i: any) => {
    listAdvertising.map((item: any, j: any) => {
      if (getListAdvertisingApplyPeriod) {
        for (let k = 0; k < getListAdvertisingApplyPeriod.length; k += 1) {
          if (listAdvertising[j][0] === getListAdvertisingApplyPeriod[k][0]) {
            if (
              moment(salesBudgetMonthly[i].id.targetMonth).isBetween(getListAdvertisingApplyPeriod[k][1], getListAdvertisingApplyPeriod[k][2])
            ) {
              listAdvertisingDisabled[listAdvertising[j][0] + i] = false;
              listAdvertisingExpenseDisabled[listAdvertising[j][0] + i] = false;
            } else {
              listAdvertisingDisabled[listAdvertising[j][0] + i] = true;
              listAdvertisingExpenseDisabled[listAdvertising[j][0] + i] = true;
            }
            break;
          }
        }
      }
    });
  });

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };

  const isRetina = window.devicePixelRatio > 1;

  return (
    <div className="wrap-budget">
      <div className="headTop" style={{ display: 'flex' }}>
        <div style={{ width: '250px', borderRight: '5px solid #d2d6de' }}>
          <table
            className="table table-bordered table-striped table-condensed text-nowrap"
            style={{ width: gridCol.grid12 }}
          >
            <tbody>
              <tr style={{ textAlign: 'center' }}>
                <td className="text-center">項目</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="headRight" style={isRetina ? {} : { width: 'calc(100% - 276px)' }}>
          <div style={{ width: '1700px', marginRight: '15px' }}>
            <table
              className="table table-bordered table-striped table-condensed text-nowrap"
              style={{ width: '1650px' }}
            >
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <th className="text-center width_row_contents">合計 </th>
                  {salesBudgetMonthly.map((item: any, e: any) => (
                    <th
                      key={e}
                      className="text-center width_row_contents"
                      onClick={() => (CheckExist !== 1 && orgCode
                        ? handleClickMonth(item.id.targetMonth)
                        : '')}
                      style={
                          CheckExist !== 1 && orgCode
                            ? {
                              color: 'blue',
                              textDecoration: 'underline',
                              textDecorationColor: 'blue',
                              cursor: 'pointer',
                            }
                            : {}
                        }
                    >
                      {moment(item.id.targetMonth, 'YYYY/MM/DD').format('M月')}
                    </th>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="table-content">
        <div style={{ display: 'inline-flex', width: gridCol.grid12 }}>
          <div
            className="contentLeft"
            style={{
              width: '250px',
              borderRight: '5px solid rgb(210, 214, 222)',
            }}
          >
            <table
              className="table table-bordered table-striped  table-condensed text-nowrap"
              style={{ width: gridCol.grid12 }}
            >
              <tbody>
                <tr>
                  <td
                    className="text-center active"
                    rowSpan={listAdvertising.length + 3}
                  >
                    売上高
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上高（広告）項目の総計"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active"
                    rowSpan={listAdvertising.length + 1}
                  >
                    広告
                  </td>
                  <td className="text-center active">
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上高（広告）項目の総計"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                {listAdvertising.map((item: any, e: number) => (
                  <tr key={e}>
                    <td className="text-center active height_row_contents">
                      <span>
                        {item[1].length <= 8 ? (
                          item[1]
                        ) : (
                          <span data-tip={item[1]}>
                            {`${item[1].slice(0, 8)}...`}
                          </span>
                        )}
                      </span>
                      <ReactTooltip />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="text-center active height_row_contents">
                    フリー
                  </td>
                  <td className="text-center active height_row_contents">
                    合計
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={3}>
                    標準原価
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="標準原価（フード）＋ 標準原価（ドリンク）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: 'none' }} />
                  <td
                    className="text-center active height_row_contents"
                    colSpan={2}
                  >
                    フード
                  </td>
                </tr>
                <tr>
                  <td style={{ display: 'none' }} />
                  <td
                    className="text-center active height_row_contents"
                    colSpan={2}
                  >
                    ドリンク
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    売上総利益
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上高（合計）- 標準原価（合計）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上総利益 / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={3}>
                    人件費
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="人件費（社員）＋ 人件費（バイト）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active height_row_contents"
                    colSpan={2}
                  >
                    社員
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active height_row_contents"
                    colSpan={2}
                  >
                    バイト
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active"
                    rowSpan={listAdvertisingExpense.length + 1}
                  >
                    広告宣伝費
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="広告宣伝費項目の総計"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                {listAdvertisingExpense.map((item: any, e: number) => (
                  <tr key={e}>
                    <td
                      className="text-center active height_row_contents"
                      colSpan={2}
                    >
                      <span>
                        {item[1].length <= 8 ? (
                          item[1]
                        ) : (
                          <span data-tip={item[1]}>
                            {`${item[1].slice(0, 8)}...`}
                          </span>
                        )}
                      </span>
                      <ReactTooltip />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="text-center active height_row_contents">
                    家賃
                  </td>
                  <td className="text-center active" colSpan={2}>
                    合計
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={3}>
                    その他経費
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="その他経費（変動費）＋ その他経費（固定費）の総計"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active height_row_contents"
                    colSpan={2}
                  >
                    変動費
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active height_row_contents"
                    colSpan={2}
                  >
                    固定費
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    営業利益
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上総利益（合計）- 人件費（合計）- 広告宣伝費（合計）- 家賃（合計）- その他経費（合計）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="営業利益 / 売上総利益（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center no-border split_sale_repport"
                    colSpan={3}
                  >
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-center active height_row_contents"
                    colSpan={3}
                  >
                    客単価
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={3}>
                    <div className="icon-tooltip">
                      客数&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上高（合計）/ 客単価"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={3}>
                    <div className="icon-tooltip">
                      回転率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="客数 / 座席数"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    限界利益
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      額&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="売上高（合計）- 標準原価（合計）+ 人件費（バイト）＋ その他経費（変動費））"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="限界利益 / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    貢献利益
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      額&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="限界利益 -（人件費（社員）＋ 広告宣伝費（合計）＋ 家賃（合計）＋ その他経費（固定費））"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="貢献利益 / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    損益分岐点
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      売上&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="（人件費（社員）＋ 広告宣伝費（合計）＋ 家賃（合計）＋ その他経費（固定費））/ 限界利益（率）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      客数&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="損益分岐点売上 / 客単価"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={3}>
                    人件費率
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      合計&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="人件費（合計）/ 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      社員&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="人件費（社員）/ 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      バイト&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="人件費（バイト）/ 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={3}>
                    <div className="icon-tooltip">
                      労働分配率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="人件費（合計）/ 売上総利益（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    FL
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      コスト&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="標準原価（合計）＋ 人件費（合計）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="FLコスト / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    FLA
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      コスト&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="標準原価（合計）＋ 人件費（合計）＋ 広告宣伝費（合計）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="FLAコスト / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    FLAR
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      コスト&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="標準原価（合計）＋ 人件費（合計）＋ 広告宣伝費（合計）＋ 家賃（合計）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="FLARコスト / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" rowSpan={2}>
                    FLARO
                  </td>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      コスト&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="標準原価（合計）＋ 人件費（合計）＋ 広告宣伝費（合計）＋ 家賃（合計）＋ その他経費（合計）"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-center active" colSpan={2}>
                    <div className="icon-tooltip">
                      率&nbsp;
                      <IconTooltip
                        iconType="help"
                        text="FLAROコスト / 売上高（合計）の％表示"
                        color="#000"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            id="contentRight"
            className="tabLeft"
            style={{ width: 'calc(100% - 250px)', overflowX: 'auto', marginRight: '4px' }}
            onScroll={eventScroll}
          >
            <div style={{ width: '1700px', marginRight: '15px' }}>
              <table
                className="table table-bordered table-striped table-condensed text-nowrap"
                style={{ width: '1650px' }}
                onScroll={eventScroll}
              >
                <tbody>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="salesBudgetTotal">
                        {
                          formatter.format(getTotal.getSalesBudgetTotal || 0)
                        }

                      </span>
                    </td>
                    {
                        formik.values.salesBudgetAdvertising.map(
                          (item: any, index: any) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`salesBudgetTotal${index}`}>
                                {
                                  formatter.format(item + formik.values.salesBudgetFree[index] || 0)
                                }
                              </span>
                            </td>
                          ),
                        )
                      }
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="salesBudgetAdvertising">
                        {
                            formatter.format(getTotal.getSalesBudgetAdvertising)
                          }
                      </span>
                    </td>
                    {
                        formik.values.salesBudgetAdvertising.map(
                          (item: any, index: any) => (
                            <td
                              className="text-right width_row_contents"
                              key={index}
                            >
                              <span id={`salesBudgetAdvertising${index}`}>
                                {formatter.format(item || 0)}
                              </span>
                            </td>
                          ),
                        )
                      }
                  </tr>
                  {
                      formik.values.listAdvertising.map(
                        (element: any, numberElement: any) => (
                          <tr key={numberElement}>
                            <td className="text-right width_row_contents">
                              <span id={`Advertising${element[0]}`}>
                                {getTotal.getAdvertisingTotal ? formatter.format(getTotal.getAdvertisingTotal[numberElement]) : 0}
                              </span>
                            </td>
                            {// eslint-disable-next-line array-callback-return
                              element.map((item: any, index: any) => {
                                if (
                                  index !== 0
                                  && index !== 1
                                  && index !== 2
                                  && item !== undefined
                                  && item !== null
                                ) {
                                  return (
                                    <td
                                      className="text-right width_row_contents"
                                      key={index}
                                    >
                                      <input
                                        type="number"
                                        className="form-control input-sm"
                                        id={`Advertising${element[0]}${index - 3}`}
                                        style={{ textAlign: 'right' }}
                                        value={item || 0}
                                        tabIndex={(listAdvertising.length * 2 + 9) * (index - 3) + numberElement + 1}
                                        disabled={
                                          !!(listAdvertisingDisabled[element[0] + (index - 3)] === true || getInputDisable)
                                        }
                                        onChange={(val) => handleInputChange(`listAdvertising[${numberElement}][${index}]`, val)}
                                      />
                                    </td>
                                  );
                                }
                              })
                            }
                          </tr>
                        ),
                      )
                    }
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="salesBudgetFree">
                        {formatter.format(getTotal.getSalesBudgetFree) || 0}
                      </span>
                    </td>
                    {
                        salesBudgetMonthly.map((item: any, index: any) => (
                          <td className="text-right width_row_contents" key={index}>
                            <input
                              type="number"
                              min={0}
                              tabIndex={
                                (listAdvertising.length * 2 + 9) * index + listAdvertising.length + 1
                              }
                              className="form-control input-sm"
                              id={`salesBudgetFree${index}`}
                              style={{ textAlign: 'right' }}
                              value={
                                formik.values.salesBudgetFree[index] || 0
                              }
                              onChange={(val) => handleInputChange(`salesBudgetFree[${index}]`, val)}
                              disabled={getInputDisable}
                            />
                          </td>
                        ))
                      }
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="costTotal">
                        {
                            formatter.format(getTotal.getFoodTotal + getTotal.getDrinkCostTotal) || 0
                          }
                      </span>
                    </td>
                    {
                        formik.values.foodCost.map((item: any, index: any) => (
                          <td className="text-right width_row_contents" key={index}>
                            <span id={`costTotal${index}`}>
                              {formatter.format(
                                item + formik.values.drinkCost[index] || 0,
                              )}
                            </span>
                          </td>
                        ))
                      }
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="foodCost">
                        {
                            formatter.format(getTotal.getFoodTotal) || 0
                          }
                      </span>
                    </td>
                    {
                        salesBudgetMonthly.map((item: any, index: any) => (
                          <td className="text-right width_row_contents" key={index}>
                            <input
                              type="number"
                              min={0}
                              tabIndex={(listAdvertising.length * 2 + 9) * index + listAdvertising.length + 2}
                              className="form-control input-sm"
                              id={`foodCost${index}`}
                              style={{ textAlign: 'right' }}
                              value={formik.values.foodCost[index] || 0}
                              onChange={(val) => handleInputChange(`foodCost[${index}]`, val)}
                              disabled={getInputDisable}
                            />
                          </td>
                        ))
                      }
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="drinkCost">
                        {
                            formatter.format(getTotal.getDrinkCostTotal || 0)
                          }
                      </span>
                    </td>
                    {
                        salesBudgetMonthly.map((item: any, index: any) => (
                          <td className="text-right width_row_contents" key={index}>
                            <input
                              type="number"
                              min={0}
                              tabIndex={
                                (listAdvertising.length * 2 + 9) * index
                                + listAdvertising.length
                                + 3
                              }
                              className="form-control input-sm"
                              id={`drinkCost${index}`}
                              style={{ textAlign: 'right' }}
                              value={
                                formik.values.drinkCost[
                                  index
                                ] || 0
                              }
                              onChange={(val) => handleInputChange(`drinkCost[${index}]`, val)}
                              disabled={getInputDisable}
                            />
                          </td>
                        ))
                      }
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="grossProfit">
                        {formatter.format(getTotal.getSalesBudgetTotal - (getTotal.getFoodTotal + getTotal.getDrinkCostTotal) || 0)}
                      </span>
                    </td>
                    {formik.values.salesBudgetTotal.map(
                      (item: any, index: any) => (
                        <td
                          className="text-right width_row_contents"
                          key={index}
                        >
                          <span id={`grossProfit${index}`}>
                            {formatter.format(formik.values.salesBudgetAdvertising[index] + formik.values.salesBudgetFree[index] - (formik.values.foodCost[index] + formik.values.drinkCost[index]) || 0)}
                          </span>
                        </td>
                      ),
                    )}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="rateGrossProfit">
                        {getTotal.getRateGrossProfit ? formatterPercent.format(numberFormater(getTotal.getRateGrossProfit) || 0) : formatterPercent.format(0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`rateGrossProfit${index}`}>
                          { formatterPercent.format(getTotal.getRateGrossProfitItems ? numberFormater(getTotal.getRateGrossProfitItems[index]) : 0) }
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="laborCostTotal">
                        {formatter.format(getTotal.getLaborCostTotals)}
                      </span>
                    </td>
                    {formik.values.employeeLaborCost.map(
                      (item: any, index: any) => (
                        <td
                          className="text-right width_row_contents"
                          key={index}
                        >
                          <span id={`laborCostTotal${index}`}>
                            {formatter.format(
                              item + formik.values.partLaborCost[index],
                            )}
                          </span>
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="employeeLaborCost">
                        {formatter.format(getTotal.getEmployeeLaborCost)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <input
                          type="number"
                          min={0}
                          tabIndex={
                              (listAdvertising.length * 2 + 9) * index
                              + listAdvertising.length
                              + 4
                            }
                          className="form-control input-sm"
                          id={`employeeLaborCost${index}`}
                          style={{ textAlign: 'right' }}
                          value={
                              formik.values.employeeLaborCost[index] || 0
                            }
                          onChange={(val) => handleInputChange(
                            `employeeLaborCost[${index}]`,
                            val,
                          )}
                          disabled={getInputDisable}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="partLaborCost">
                        {formatter.format(getTotal.getPartLaborCost) || 0}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <input
                          type="number"
                          min={0}
                          tabIndex={
                              (listAdvertising.length * 2 + 9) * index
                              + listAdvertising.length
                              + 5
                            }
                          className="form-control input-sm"
                          id={`partLaborCost${index}`}
                          style={{ textAlign: 'right' }}
                          value={
                              formik.values.partLaborCost[index] || 0
                            }
                          onChange={(val) => handleInputChange(`partLaborCost[${index}]`, val)}
                          disabled={getInputDisable}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="advertisingMediaExpense">
                        {formatter.format(
                          getTotal.getAdvertisingMediaExpenseTotal,
                        ) || 0}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`advertisingMediaExpense${index}`}>
                          {formatter.format(
                            formik.values.advertisingMediaExpense[index] || 0,
                          )}
                        </span>
                      </td>
                    ))}
                  </tr>
                  {formik.values.listAdvertisingExpense.map(
                    (element: any, numberAdExpense: any) => (
                      <tr key={numberAdExpense}>
                        <td className="text-right width_row_contents">
                          <span id={`AdvertisingExpense${element[0]}`}>
                            {getTotal.getAdvertisingExpenseTotal
                              ? formatter.format(
                                getTotal.getAdvertisingExpenseTotal[numberAdExpense],
                              )
                              : 0}
                          </span>
                        </td>
                        {element.map((item: any, index: number) => {
                          if (
                            index !== 0
                              && index !== 1
                              && index !== 2
                              && item !== undefined
                              && item !== null
                          ) {
                            return (
                              <td
                                className="text-right width_row_contents"
                                key={index}
                              >
                                <input
                                  type="number"
                                  min={0}
                                  tabIndex={
                                      (listAdvertising.length * 2 + 9)
                                      * (index - 3)
                                      + listAdvertising.length + 5 + numberAdExpense + 1
                                    }
                                  className="form-control input-sm"
                                  id={`AdvertisingExpense${element[0]}${index - 3}`}
                                  style={{ textAlign: 'right' }}
                                  value={item || 0}
                                  onChange={(val) => handleInputChange(`listAdvertisingExpense[${numberAdExpense}][${index}]`, val)}
                                  disabled={
                                    !!(listAdvertisingExpenseDisabled[element[0] + (index - 3)] === true || getInputDisable)
                                  }
                                />
                              </td>
                            );
                          }
                          return true;
                        })}
                      </tr>
                    ),
                  )}
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="landRent">
                        {formatter.format(getTotal.getLandRent) || 0}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <input
                          type="number"
                          min={0}
                          tabIndex={
                              (listAdvertising.length * 2 + 9) * index
                              + listAdvertising.length * 2
                              + 5
                              + 1
                            }
                          className="form-control input-sm"
                          id={`landRent${index}`}
                          style={{ textAlign: 'right' }}
                          value={
                              formik.values.listStoremanagement.landRent[index]
                              || 0
                            }
                          onChange={(val) => handleInputChange(`landRent[${index}]`, val)}
                          disabled={getInputDisable}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="otherExpensesTotal">
                        {formatter.format(
                          getTotal.getOtherVariableCostsTotal
                            + getTotal.getOtherFixedCostTotal,
                        ) || 0}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`otherExpensesTotal${index}`}>
                          {formatter.format(formik.values.otherVariableCosts[index] + formik.values.otherFixedCost[index])}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="otherVariableCosts">
                        {formatter.format(numberFormater(getTotal.getOtherVariableCostsTotal) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <input
                          type="number"
                          min={0}
                          tabIndex={
                              (listAdvertising.length * 2 + 9) * index
                              + listAdvertising.length * 2
                              + 5
                              + 2
                            }
                          className="form-control input-sm"
                          id={`otherVariableCosts${index}`}
                          style={{ textAlign: 'right' }}
                          value={formik.values.otherVariableCosts[index] || 0}
                          onChange={(val) => handleInputChange(
                            `otherVariableCosts[${index}]`,
                            val,
                          )}
                          disabled={getInputDisable}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="otherFixedCost">
                        {formatter.format(numberFormater(getTotal.getOtherFixedCostTotal) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <input
                          type="number"
                          min={0}
                          tabIndex={
                              (listAdvertising.length * 2 + 9) * index
                              + listAdvertising.length * 2
                              + 5
                              + 3
                            }
                          className="form-control input-sm"
                          id={`otherFixedCost${index}`}
                          style={{ textAlign: 'right' }}
                          value={formik.values.otherFixedCost[index] || 0}
                          onChange={(val) => handleInputChange(`otherFixedCost[${index}]`, val)}
                          disabled={getInputDisable}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="operatingIncome">
                        {formatter.format(
                          numberFormater(getTotal.getSalesBudgetTotal
                            - (getTotal.getFoodTotal
                              + getTotal.getDrinkCostTotal)
                            - getTotal.getLaborCostTotals
                            - getTotal.getAdvertisingMediaExpenseTotal
                            - getTotal.getLandRent
                            - (getTotal.getOtherVariableCostsTotal
                              + getTotal.getOtherFixedCostTotal)) || 0,
                        )}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`operatingIncome${index}`}>
                          {
                            formatter.format(getTotal.getOperatingIncomeItems ? numberFormater(getTotal.getOperatingIncomeItems[index]) : 0)
                          }
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="rateOperatingIncome">
                        {
                          formatterPercent.format(
                            numberFormater((getTotal.getSalesBudgetTotal
                                - (getTotal.getFoodTotal
                                  + getTotal.getDrinkCostTotal)
                                - getTotal.getLaborCostTotals
                                - getTotal.getAdvertisingMediaExpenseTotal
                                - getTotal.getLandRent
                                - (getTotal.getOtherVariableCostsTotal
                                  + getTotal.getOtherFixedCostTotal))
                              / (getTotal.getSalesBudgetTotal - (getTotal.getFoodTotal + getTotal.getDrinkCostTotal)))
                            || 0,
                          )
                        }
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`rateOperatingIncome${index}`}>
                          {
                            formatterPercent.format(getTotal.getRateOperatingIncomeItems ? numberFormater(getTotal.getRateOperatingIncomeItems[index]) : 0)
                          }
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      className="text-center no-border split_sale_repport"
                      colSpan={salesBudgetMonthly.length + 1}
                    >
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="perCustomerPrice">
                        {formatter.format(Math.round(numberFormater(((getTotal.getSalesBudgetAdvertising) + (getTotal.getSalesBudgetFree)) / (getTotal.getGuestCntTotal)) || 0))}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <input
                          type="number"
                          min={0}
                          tabIndex={
                              (listAdvertising.length * 2 + 9) * index
                              + listAdvertising.length * 2
                              + 5
                              + 4
                            }
                          className="form-control input-sm"
                          id={`perCustomerPrice${index}`}
                          style={{ textAlign: 'right' }}
                          value={getTotal.getPerCustomerPriceItems ? parseInt(numberFormater(getTotal.getPerCustomerPriceItems[index])) : 0}
                          onChange={(val) => handleInputChange(`perCustomerPrice[${index}]`, val)}
                          disabled={getInputDisable}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="guestCnt">
                        {formatter.format(getTotal.getGuestCntTotal || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`guestCnt${index}`}>
                          {
                            getTotal.getGuestCntItems ? formatter.format(getTotal.getGuestCntItems[index]) : 0
                          }
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="turnoverRate">
                        {formatterDouble.format(getTotal.getTurnoverRateTotal || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`turnoverRate${index}`}>
                          { getTotal.getTurnoverRateTotalItems ? formatterDouble.format(numberFormater((getTotal.getTurnoverRateTotalItems[index]))) : 0 }
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="marginalProfitTotal">
                        {formatter.format(getTotal.getMarginalProfitTotal || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`marginalProfitTotal${index}`}>
                          {formatter.format(getTotal.getMarginalProfitTotalItems ? getTotal.getMarginalProfitTotalItems[index] : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="marginalProfitRate">
                        {formatterPercent.format(numberFormater(getTotal.getMarginalProfitRate) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`marginalProfitRate${index}`}>
                          {formatterPercent.format(getTotal.getMarginalProfitRateItems ? numberFormater(getTotal.getMarginalProfitRateItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="contributionMarginTotal">
                        {formatter.format(
                          getTotal.getContributionMarginTotal || 0,
                        )}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`contributionMarginTotal${index}`}>
                          {formatter.format(getTotal.getContributionMarginTotalItems ? numberFormater(getTotal.getContributionMarginTotalItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="contributionMarginRate">
                        {formatterPercent.format(numberFormater(getTotal.getContributionMarginRate) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`contributionMarginRate${index}`}>
                          {formatterPercent.format(getTotal.getContributionMarginRateItems ? numberFormater(getTotal.getContributionMarginRateItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="bePointSales">
                        {formatter.format(numberFormater(getTotal.getBePointSales) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`bePointSales${index}`}>
                          {getTotal.getBePointSalesItems
                            ? formatter.format(
                              numberFormater(getTotal.getBePointSalesItems[index]) || 0,
                            )
                            : 0}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="bePointGuestCnt">
                        {formatter.format(numberFormater(getTotal.getBePointSales / (Math.round(numberFormater(((getTotal.getSalesBudgetAdvertising) + (getTotal.getSalesBudgetFree)) / (getTotal.getGuestCntTotal)))))) }
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`bePointGuestCnt${index}`}>
                          {
                            getTotal.getBePointGuestCntCalculatorItems ? formatter.format((numberFormater(getTotal.getBePointGuestCntCalculatorItems[index])) || 0) : 0
                          }
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="totalPersonRate">
                        {formatterPercent.format(numberFormater(getTotal.getTotalPersonRate) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`totalPersonRate${index}`}>
                          {formatterPercent.format(getTotal.getPersonRateItems ? numberFormater(getTotal.getPersonRateItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="employeePersonRate">
                        {formatterPercent.format(
                          numberFormater(getTotal.getEmployeePersonRate) || 0,
                        )}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`employeePersonRate${index}`}>
                          {formatterPercent.format(getTotal.getEmployeePersonRateItems ? numberFormater(getTotal.getEmployeePersonRateItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="partPersonRate">
                        {formatterPercent.format(numberFormater(getTotal.getPartPersonRate) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`partPersonRate${index}`}>
                          {formatterPercent.format(getTotal.getPartPersonRateItems ? numberFormater(getTotal.getPartPersonRateItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="laborShare">
                        {formatterPercent.format(numberFormater(getTotal.getLaborShare) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`laborShare${index}`}>
                          {formatterPercent.format(getTotal.getLaborShareItems ? numberFormater(getTotal.getLaborShareItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="costFL">
                        {formatter.format(numberFormater(getTotal.getCostFL) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`costFL${index}`}>
                          {formatter.format(getTotal.getCostFLItems ? numberFormater(getTotal.getCostFLItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="rateFL">
                        {formatterPercent.format(numberFormater(getTotal.getRateFL) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`rateFL${index}`}>
                          {formatterPercent.format(getTotal.getRateFLItems ? numberFormater(getTotal.getRateFLItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="costFLA">
                        {formatter.format(getTotal.getCostFLA || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`costFLA${index}`}>
                          {formatter.format(getTotal.getCostFLAItems ? numberFormater(getTotal.getCostFLAItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="rateFLA">
                        {formatterPercent.format(numberFormater(getTotal.getRateFLA) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`rateFLA${index}`}>
                          {formatterPercent.format(getTotal.getRateFLAItems ? numberFormater(getTotal.getRateFLAItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="costFLAR">
                        {formatter.format(numberFormater(getTotal.getCostFLAR) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`costFLAR${index}`}>
                          {formatter.format(
                            getTotal.getCostFLARItems
                              ? numberFormater(getTotal.getCostFLARItems[index])
                              : 0,
                          )}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="rateFLAR">
                        {formatterPercent.format(numberFormater(getTotal.getRateFLAR) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`rateFLAR${index}`}>
                          {formatterPercent.format(getTotal.getRateFLARItems ? numberFormater(getTotal.getRateFLARItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="text-right width_row_contents">
                      <span id="costFLARO">
                        {formatter.format(getTotal.getCostFLARO || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`costFLARO${index}`}>
                          { formatter.format(getTotal.getCostFLAROItems ? numberFormater(getTotal.getCostFLAROItems[index]) : 0)}
                        </span>
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-right width_row_contents">
                      <span id="rateFLARO">
                        {formatterPercent.format(numberFormater(getTotal.getRateFLARO) || 0)}
                      </span>
                    </td>
                    {salesBudgetMonthly.map((item: any, index: any) => (
                      <td className="text-right width_row_contents" key={index}>
                        <span id={`rateFLARO${index}`}>
                          {formatterPercent.format(
                            getTotal.getRateFLAROItems
                              ? numberFormater(getTotal.getRateFLAROItems[index])
                              : 0,
                          )}
                        </span>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-bottom" onScroll={eventScroll}>
        <div style={{ width: '250px', float: 'left' }} />
        <div
          style={{
            width: 'calc(100% - 260px)',
            float: 'right',
            height: '24px',
            overflow: 'auto',
          }}
          id="scrollBottom"
        >
          <div className="scroll" />
        </div>
      </div>
    </div>
  );
};
export default BudgetYearTable;
