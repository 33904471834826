/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useEffect } from "react";
import useToastNotification from "hooks/useToastNotification";
import { useHistory, useParams } from "react-router-dom";
import { MasterAddRoleDomain } from 'domain/master/general/masterRole/masterAddRole';
import { useFormik } from 'formik';
import { postMasterAddRole } from 'api/masterRole'
import {  
  getDetailFunctionRole,
} from 'api/masterRole';
import { DetailRoleFunction } from "api/role";

const { errorNotification, successNotification } = useToastNotification();

export const useMasterRoleAddForm = () => {
  
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { roleId } = useParams();

  // FETCH DATA EDIT
  const fetchDetailRole = useCallback(async () => {
    setIsLoading(true);
    getDetailFunctionRole(roleId || "").then((response: DetailRoleFunction) => {
      console.log('accessLevel', response.accessLevel)
      formik.setFieldValue('accessLevel', Number(response.accessLevel));
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, []);
  
  useEffect(() => {
    if(roleId){
      fetchDetailRole();
    }
  }, [roleId])

  const onSubmit = async (values: MasterAddRoleDomain) => {
    let params = values.getRawData();
    params.id.roleId = roleId || null;
    try {
      setIsLoading(true);
      setConfirmModalOpen(false);
      const response = await postMasterAddRole(params);
      if (!response.errors) {
        successNotification('登録しました。');
        history.push(`/masterRoleSetting/edit/${response?.roleName}/${response?.roleCode}/${response?.id?.roleId}`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }

  const formik = useFormik({
    initialValues: MasterAddRoleDomain.generateInitial(),
    onSubmit,
  });

  return {
    isLoading,
    setIsLoading,
    history,
    confirmModalOpen,
    setConfirmModalOpen,
    formik
  };
};
export default useMasterRoleAddForm;
