import Actions, { types } from './actions';
import { State } from './type';
import { defaultState, defaultFaceList, defaultFace } from './hooks';

export const reducer = (state: State, action: Actions): State => {
  let tmpFaces = JSON.parse(JSON.stringify([...state.listFace]));
  
  switch (action.type) {
    case types.SET_STATE:
      return action.payload;
    case types.SET_LIST_FACE:
      return {
        ...state, 
        listFace: action.payload
      };
    case types.SET_MODAL_CHOOSE_ACTION:
      return {
        ...state,
        openModalChooseAction: action.payload.open,
        indexAction: action.payload.indexAction
      }
    case types.ADD_FACE:
      if(state.listFace.length >= action.payload.maxLength) return state;
      let newState = {
        ...state, 
        listFace: [...state.listFace, action.payload.defaultFace],
      };
      return newState;
    case types.CHANGE_DESCRIPTION:      
      tmpFaces[action.payload.index].description = action.payload.description;
      return {
        ...state,
        listFace: tmpFaces
      };
    case types.SET_TMP_IMAGE_MODAL:
      return {
        ...state,
        tmpImageModal: action.payload,
        openModalCrop: action.payload ? true : false,
      };
    case types.SET_CROPPED_IMAGE:
      if(state.indexAction === tmpFaces.length){
        tmpFaces.push(defaultState);
      }
      tmpFaces[state.indexAction].blob = action.payload;
      return {
        ...state,
        listFace: tmpFaces,
        openModalChooseAction: false,
        openModalCrop: false,
      };
    case types.SET_OPEN_CAMERA:
      return {
        ...state,
        listFace: tmpFaces,
        openCamera: true,
      };
    case types.ON_ERROR_CAMERA:
      return {
        ...state,
        listFace: tmpFaces,
        tmpImageModal: undefined,
        // openCamera: false,
      };
    case types.CLOSE_CAMERA:
      return {
        ...state,
        listFace: tmpFaces,
        // tmpImageModal: undefined,Z
        openCamera: false,
      };
    case types.SET_ENABLE_BUTTON_CAP:
      return {
        ...state,
        enableButtonCap: action.payload
      };
    case types.SET_SHOW_MESSAGE:
      return {
        ...state,
        showMessage: action.payload
      };
    case types.REMOVE_DEPEND_IMAGE:
      if(tmpFaces.length <= 4){
        tmpFaces[action.payload] = defaultFace;
      }else{
        tmpFaces.splice(action.payload, 1);  
      }
      return {
        ...state,
        listFace: tmpFaces,
        // tmpImageModal: undefined,Z
      };
    case types.REMOVE_STAFF_IMAGE:
      tmpFaces[0] = defaultFace;
      return {
        ...state,
        listFace: tmpFaces,
        // tmpImageModal: undefined,Z
      };
    default:
      return state;
  }
};

export default reducer;
