/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import PrimaryButton from "components/atoms/Button";
import FormField from "components/atoms/Form/FormField";
import FileSelectButton from "components/molecules/FileSelectButton";
import { utilityColor } from "components/styles";
import BodyText from "components/atoms/BodyText";
import useExecutiveCsvForm from "./hooks";
import FlexBox from "components/atoms/FlexBox";
import { useHistory } from "react-router-dom";
import Modal from "components/molecules/Modal";
import FormPadding from "components/atoms/Form/FormPadding";
import ImportCSVForm from "components/molecules/ImportCSVForm";

const style = {
  uploadFileCSV: css({
    marginTop: '45px',
    height: '105px',
    width: '400px',
    border: '1px solid #dcdcdc',
    borderRadius: '2px',
    backgroundColor: '#f8f8f8',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  }, css`
  span {
    position: absolute;
    left: 95px;
  }
  button {
    margin-left: 35px;
  }
`),
};

const ExecutiveImportCSV: React.FC = () => {
  const history = useHistory();
  const {
    setUploadFile,
    uploadFile,
    onSubmit,
    errors,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
  } = useExecutiveCsvForm();
  return (
    <div style={{ textAlign: "center" }}>
      <FormPadding customStyle={{ padding: '0 30px' }}>
      <ImportCSVForm
        uploadFile={uploadFile}
        setUploadFile={setUploadFile}
        errors={errors}
      />
      <FormSubmitArea customStyle={css({ margin: '30px 0' })}>
        <FlexBox justifyContent="center">
          <PrimaryButton
            text="インポート"
            onClick={handleSubmit}
            disabled={!uploadFile}
          />
        </FlexBox>
        <FlexBox justifyContent="center">
          <PrimaryButton
            text="キャンセル"
            ghost={true}
            // eslint-disable-next-line no-restricted-globals
            onClick={() => history.push("/executive")}
          />
        </FlexBox>
      </FormSubmitArea>
      <Modal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="ファイルをアップロード"
        submitText="アップロードする"
        note="ファイルをアップロードします。よろしいですか？"
        onSubmit={onSubmit}
      >
        <div style={{ height: "40px" }}></div>
      </Modal>
      </FormPadding>
    </div>
  );
};

export default ExecutiveImportCSV;
