import ApiClient from './ApiClient';
export interface AttendanceMaster {
  stampIpId:     string;
  ipAddress:     string;
  subnetMask:    number;
  stampIPDetail: StampIPDetail[];
}
export interface StampIPDetail {
  orgCode: string;
  orgName: string;
  checkAll?: boolean;
}

export const getAttendStampIpList = async (): Promise<Array<AttendanceMaster>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attend/stamp/ip/list/${companyCode}`, {});
  return response.data;
};

export const deleteAttendStampIPById = async (stampIpId: string): Promise<any> => {
  const params = {
    stampIpId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.delete(`/v1/attend/stamp/ip/delete/${companyCode}`,params);
  return reponse.data;
};

export const getAttendSettingIPById = async (stampIpId: string): Promise<Array<AttendanceMaster>> => {
  const params = {
    stampIpId,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attend/stamp/ip/${companyCode}`, params);
  return response.data;
};

/**
 * createOrUpdateAttendStampIp
 *
 * @param companyCode
 * @param dataTimeOutputLayout
 */
 export const createOrUpdateAttendStampIp = async (companyCode: string, dataTimeOutputLayout: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/attend/stamp/ip/${companyCode}`, query, dataTimeOutputLayout);
  return response.data;
};
