/** @jsx jsx */
import React from 'react';

import { css, jsx } from '@emotion/core';
import ManagementInformationForm from './ManagementInformationForm/ManagementInformationForm';
import AcquisitionStatusForm from './AcquisitionStatusForm/AcquisitionStatusForm';
import GrantHistoryForm from './GrantHistoryForm/GrantHistoryForm';

// create style form
const styles = {
  form: css({
    marginTop: '-16px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
};

const HolidayManagementSpecialHolidayForm: React.FC<
{
  arrGrantResult: any,
  employmentOptions: any,
  orgTreesOptions: any,
  specialHolidayOptions: any,
  currentAccordionList: any,
  setCurrentAccordionList: any
}
  > = ({
    orgTreesOptions, arrGrantResult, employmentOptions, specialHolidayOptions, currentAccordionList, setCurrentAccordionList,
  }) =>
  //
    (
      <div css={css(styles.form)}>
        <ManagementInformationForm
          employmentOptions={employmentOptions}
          orgTreesOptions={orgTreesOptions}
          specialHolidayOptions={specialHolidayOptions}
          currentAccordionList={currentAccordionList}
          setCurrentAccordionList={setCurrentAccordionList}
        />

        <GrantHistoryForm
          arrGrantResult={arrGrantResult}
          orgTreesOptions={orgTreesOptions}
          currentAccordionList={currentAccordionList}
          setCurrentAccordionList={setCurrentAccordionList}
        />

        <AcquisitionStatusForm
          orgTreesOptions={orgTreesOptions}
          currentAccordionList={currentAccordionList}
          setCurrentAccordionList={setCurrentAccordionList}
        />
      </div>
    );
export default HolidayManagementSpecialHolidayForm;
