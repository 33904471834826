/** @jsx jsx */
import React from "react";
import moment from "moment";
import { css, jsx } from "@emotion/core";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const SubstituteHolidayTable: React.FC<{
  substituteHolidayUsingStatusList: any;
}> = ({ substituteHolidayUsingStatusList }) => (
  <div
    css={css`
      table thead tr th {
        height: 46px;
      }
      table tbody tr td {
        height: 32px;
      }
    `}
  >
    <table
      id="substituteHolidayList"
      className="table table-bordered table-condensed"
      cellSpacing="0"
      style={{ whiteSpace: "nowrap", width: "100%" }}
    >
      <tbody>
        {substituteHolidayUsingStatusList?.map((substitute: any) => (
          <React.Fragment>
            <tr role="row">
              <td colSpan={2} style={{ width: "auto" }}>
                <div css={[thStyle, css({ textAlign: "center" })]}>{substitute.holidayName}</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "140px" }}>
                <div css={thStyle}>状態</div>
              </td>
              <td>
                <div css={tdStyle}>{substitute.status}</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "140px" }}>
                <div css={thStyle}>休日出勤日</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {substitute.holidayWorkDate === ""
                    ? ""
                    : moment(substitute.holidayWorkDate).format("YYYY年MM月DD日")}
                </div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "140px" }}>
                <div css={thStyle}>付与日数</div>
              </td>
              <td>
                <div css={tdStyle}>{substitute.grantDaysNumStr}</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "140px" }}>
                <div css={thStyle}>代休取得日</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {substitute.substituteHolidayDate === ""
                    ? ""
                    : moment(substitute.substituteHolidayDate).format("YYYY年MM月DD日")}
                  {substitute.substituteHolidayDate2 === null || substitute.substituteHolidayDate2 === "" ? (
                    ""
                  ) : (
                    <br></br>
                  )}
                  {substitute.substituteHolidayDate2 === null || substitute.substituteHolidayDate2 === ""
                    ? ""
                    : moment(substitute.substituteHolidayDate2).format("YYYY年MM月DD日")}
                </div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "140px" }}>
                <div css={thStyle}>残日数</div>
              </td>
              <td>
                <div css={tdStyle}>{substitute.remainDaysNumStr}</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "140px" }}>
                <div css={thStyle}>消滅日(消滅予定日)</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {substitute.disappearanceDate === ""
                    ? ""
                    : moment(substitute.disappearanceDate).format("YYYY年MM月DD日")}
                </div>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
);
export default SubstituteHolidayTable;
