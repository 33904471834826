import React from 'react';

import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { useFiscalYearAddForm } from './hooks';
import YearPickerForm from '../../../../molecules/YearPickerForm';
import FormField from '../../../../atoms/Form/FormField';
import DatePickerForm from '../../../../molecules/DatePickerForm';
import { gridCol } from '../../../../styles';

const FiscalYearInputAddForm: React.FC<{}> = () => {
  const {
    formik, confirmModalOpen, closeConfirmModal, fiscalYear, handleBack,
    confirmModalContent,
    confirmModalSubmitText,
  } = useFiscalYearAddForm();
  return (
    <>

      <form onSubmit={formik.handleSubmit}>
        <div>

          <FormField>
            <YearPickerForm
              name="fiscalYear"
              label="年度"
              selectedYear={Number(formik.values.fiscalYear)}
              onChange={(year: any) => formik.setFieldValue('fiscalYear', year)}
              errorMsg={formik.errors.fiscalYear}
              required={true}
              disabled={!!fiscalYear}
            />
          </FormField>

          <FormField>
            <div style={{ marginRight: '12px', width: gridCol.grid02 }}>
              <DatePickerForm
                required={true}
                dateFormat="yyyy年MM月"
                label="期首年月"
                showMonthYearPicker={true}
                isFullWidth={true}
                date={formik.values.fiscalStartMonth}
                changeDate={(date: Date) => formik.setFieldValue('fiscalStartMonth', date)}
              />
              <div style={{ marginTop: '4px' }}>
                <span style={{ color: 'red', marginTop: '4px' }}>{formik.errors.fiscalStartMonth}</span>
              </div>
            </div>
          </FormField>

          <FormField>
            <div style={{ marginRight: '12px', width: gridCol.grid02 }}>
              <DatePickerForm
                required={true}
                dateFormat="yyyy年MM月"
                label="期末年月"
                date={formik.values.fiscalEndMonth}
                showMonthYearPicker={true}
                isFullWidth={true}
                changeDate={(date: Date) => formik.setFieldValue('fiscalEndMonth', date)}
              />
              <div style={{ marginTop: '4px' }}>
                <span style={{ color: 'red', marginTop: '4px' }}>{formik.errors.fiscalEndMonth}</span>
              </div>
            </div>
          </FormField>

          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <Button
                text={fiscalYear ? '更新' : '会計年度を登録'}
                onClick={() => formik.handleSubmit()}
              />
            </div>
            <Button
              text="戻る"
              ghost={true}
              onClick={handleBack}
            />
          </FormSubmitArea>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title="確認メッセージ"
          content={confirmModalContent}
          submitText={confirmModalSubmitText}
        />
      </form>
    </>
  );
};

export default FiscalYearInputAddForm;
