import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getGrantItemsForPaidHolidayGrantHistory,
  downloadPaidHolidayGrantHistoryCsv,
} from 'api/holidayManagement';
import PaidHolidayGrantHistoryDomain from 'domain/master/holidayManagement/paidHolidayGrantHistory';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';
import moment from 'moment';

export const useGrantHistoryDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [paidHolidayGrantHistoryList, setPaidHolidayGrantHistoryList] = useState<Array<PaidHolidayGrantHistoryDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementPaid.grantHistory.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const startYear = moment(new Date).startOf('year').format("YYYY/MM/DD");
  const endYear = moment(new Date).endOf('year').format("YYYY/MM/DD");
  const [targetDateFrom, setTargetDateFrom] = useSaveStateStorage(startYear, "holidayManagementPaid.grantHistory.targetDateFrom") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [targetDateTo, setTargetDateTo] = useSaveStateStorage(endYear, "holidayManagementPaid.grantHistory.targetDateTo") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [paidGrantResult, setPaidGrantResult] = useSaveStateStorage('0', "holidayManagementPaid.grantHistory.paidGrantResult") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementPaid.grantHistory.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  const contextObject = useContext(HolidayManagementContext);

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getGrantItemsForPaidHolidayGrantHistory(
        orgCode !== 'all' ? orgCode : '',
        targetDateFrom || startYear,
        targetDateTo || endYear,
        paidGrantResult || '0',
        underFlag || 0
      );
      contextObject.setIsLoading(false);
      setPaidHolidayGrantHistoryList(response.map((result) => new PaidHolidayGrantHistoryDomain(result)));
    }
  }, [orgCode, paidGrantResult, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, paidGrantResult, targetDateFrom, targetDateTo, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    paidHolidayGrantHistoryList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    startYear,
    endYear,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    paidGrantResult,
    setPaidGrantResult,
    modalOpen,
    setModalOpen,
    closeModal,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagementGrantHistory = (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  paidGrantResult: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadHolidayManagementGrantHistory = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadPaidHolidayGrantHistoryCsv(
      isDownloadAll ? '' : orgCode,
      new Date(targetDateFrom),
      new Date(targetDateTo),
      paidGrantResult,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [orgCode, paidGrantResult, targetDateFrom, targetDateTo, underFlag]);

  return {
    downloadHolidayManagementGrantHistory,
    blocking
  };
};

export default {
  useGrantHistoryDomainForm,
};
