/** @jsx jsx */
import React from "react";

import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FlexBox from "components/atoms/FlexBox";
import { gridCol } from "components/styles";
import HourInput from "components/atoms/Form/HourInput";
import { css, jsx } from "@emotion/core";

const styles = {
  flexbox: css({
    position: "relative",
    top: "-14px",
  }),
};

const FLEX_TIME = 3;

const EmploymentWorkSystemAndBreakSettings: React.FC<{
  formik: any;
}> = ({ formik }) => {
  return (
    <React.Fragment>
      <FormTitle title="丸め設定" bold={true} customStyle={css({ marginLeft: "0" })} />
      <div>
        <FormField>
          <RadioSelectForm
            label="出社/休憩終了打刻時間の秒数の丸め処理"
            name="roundMethodStartStamp"
            items={[
              {
                label: "切り捨てる",
                value: "0",
              },
              {
                label: "切り上げる",
                value: "1",
              },
            ]}
            value={String(formik.values.roundMethodStartStamp)}
            setValue={formik.handleChange}
          />
        </FormField>
        <FormField>
          <RadioSelectForm
            label="退社/休憩開始打刻時間の秒数の丸め処理"
            name="roundMethodEndStamp"
            items={[
              {
                label: "切り捨てる",
                value: "0",
              },
              {
                label: "切り上げる",
                value: "1",
              },
            ]}
            value={String(formik.values.roundMethodEndStamp)}
            setValue={formik.handleChange}
          />
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid04 }}>
            <VerticalLabelSelectForm
              label="出勤/退勤打刻の丸め単位"
              name="roundUnitStartStamp"
              value={String(formik.values.roundUnitStartStamp)}
              setValue={(val: string) => formik.setFieldValue("roundUnitStartStamp", val)}
              options={[
                {
                  label: "01",
                  value: "1",
                },
                {
                  label: "05",
                  value: "5",
                },
                {
                  label: "10",
                  value: "10",
                },
                {
                  label: "15",
                  value: "15",
                },
                {
                  label: "30",
                  value: "30",
                },
              ]}
              isSearchable={false}
            />
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid04 }}>
            <VerticalLabelSelectForm
              label="休憩開始/終了打刻の丸め単位"
              name="roundUnitRecessStamp"
              value={String(formik.values.roundUnitRecessStamp)}
              setValue={(val: string) => formik.setFieldValue("roundUnitRecessStamp", val)}
              options={[
                {
                  label: "01",
                  value: "1",
                },
                {
                  label: "05",
                  value: "5",
                },
                {
                  label: "10",
                  value: "10",
                },
                {
                  label: "15",
                  value: "15",
                },
                {
                  label: "30",
                  value: "30",
                },
              ]}
              isSearchable={false}
            />
          </div>
        </FormField>
        {![2, FLEX_TIME].includes(Number(formik.values.workSystem)) ? (
          <React.Fragment>
            <FormField>
              <RadioSelectForm
                label="出勤時間をシフトの開始時間に丸める"
                name="roundShiftStartTime"
                items={[
                  {
                    label: "丸めない",
                    value: "0",
                  },
                  {
                    label: "丸める",
                    value: "1",
                  },
                ]}
                value={String(formik.values.roundShiftStartTime)}
                setValue={formik.handleChange}
                note={
                  <div>
                    {" "}
                    「丸める」を選択するとシフトの開始時間に出勤時間を丸めます。
                    <br /> シフトの開始時間より出勤時刻が早い場合のみ適用されます。
                  </div>
                }
              />
            </FormField>
            <div style={{ display: "inline-flex" }}>
              <FormField>
                <RadioSelectForm
                  label="退勤時間をシフトの終了時間に丸める"
                  name="roundShiftEndTime"
                  items={[
                    {
                      label: "丸めない",
                      value: "0",
                    },
                    {
                      label: "丸める",
                      value: "1",
                    },
                    {
                      label: "シフト終了時間から",
                      value: "2",
                    },
                  ]}
                  value={String(formik.values.roundShiftEndTime)}
                  setValue={formik.handleChange}
                  note={
                    <div>
                      「丸める」を選択するとシフトの終了時間に退勤時間を丸めます。 <br />{" "}
                      シフトの終了時間より退勤時刻が遅い場合のみ適用されます。
                    </div>
                  }
                />
              </FormField>
              <div style={String(formik.values.roundShiftEndTime) === "2" ? {} : { display: "none" }} />
              <FlexBox customStyle={styles.flexbox}>
                <div style={{ width: "100%" }}>
                  <HourInput
                    name="roundEndElapsedTime"
                    value={formik.values.roundEndElapsedTime}
                    label="分未満まで丸める"
                    min={0}
                    wrapInput={css({ maxWidth: "240px" })}
                    max={9999}
                    onChange={(val) => {
                      if (val.target.value === "" || Number(val.target.value) < 0) {
                        formik.setFieldValue("roundEndElapsedTime", 0);
                      } else {
                        formik.setFieldValue("roundEndElapsedTime", val.target.value);
                      }
                    }}
                  />
                </div>
              </FlexBox>
            </div>
          </React.Fragment>
        ) : null}
        <FormField>
          <RadioSelectForm
            label="月次集計時に実績を丸める"
            name="useRoundMonthlyStatistic"
            items={[
              {
                label: "丸めない",
                value: "0",
              },
              {
                label: "丸める",
                value: "1",
              },
            ]}
            value={String(Number(formik.values.useRoundMonthlyStatistic))}
            setValue={(e) => formik.setFieldValue("useRoundMonthlyStatistic", e.target.value === "1")}
          />
        </FormField>
        {formik.values.useRoundMonthlyStatistic && (
          <div>
            <FormField>
              <div style={{ width: gridCol.grid04 }}>
                <VerticalLabelSelectForm
                  label="実績の丸め単位"
                  name="roundUnitMonthlyStatistic"
                  value={String(formik.values.roundUnitMonthlyStatistic)}
                  setValue={(val: string) => formik.setFieldValue("roundUnitMonthlyStatistic", val)}
                  options={[
                    {
                      label: "01",
                      value: "1",
                    },
                    {
                      label: "05",
                      value: "5",
                    },
                    {
                      label: "10",
                      value: "10",
                    },
                    {
                      label: "15",
                      value: "15",
                    },
                    {
                      label: "30",
                      value: "30",
                    },
                  ]}
                  isSearchable={false}
                />
              </div>
            </FormField>
            <FormField>
              <RadioSelectForm
                label="実績の丸め処理"
                name="roundMethodMonthlyStatistic"
                items={[
                  {
                    label: "切り捨てる",
                    value: "0",
                  },
                  {
                    label: "四捨五入",
                    value: "1",
                  },
                  {
                    label: "切り上げる",
                    value: "2",
                  },
                ]}
                value={String(formik.values.roundMethodMonthlyStatistic)}
                setValue={formik.handleChange}
              />
            </FormField>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default EmploymentWorkSystemAndBreakSettings;
