/** @jsx jsx */
import React from "react";

import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton } from "components/atoms/Button";
import { Link, useParams } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import FormContents from "components/atoms/Form/FormContents";
import { useExecutiveAddForm } from "./hooks";
import Modal from "components/molecules/Modal";
import BlockUI from "components/molecules/BlockUi";

const styles = {
  formContainer: css(
    {
      width: "450px",
      margin: "auto",
      paddingBottom: "60px",
    },
    css`
      button {
        width: 160px;
      }
    `
  ),
};

const ExecutiveAddForm: React.FC = () => {
  const { executiveId } = useParams();
  const { formik, confirmModalOpen, closeConfirmModal, isLoading, detailRole } = useExecutiveAddForm(
    executiveId
  );

  return (
    <BlockUI blocking={isLoading}>
      <FormContents
        titleForm={executiveId ? "役職編集" : "役職作成"}
        customTitleStyle={css({ margin: "35px 0 40px 35px " })}
      >
        <form css={styles.formContainer}>
          <FormField customStyle={css({ marginBottom: "10px" })}>
            <TextForm
              name="executiveCode"
              label="役職コード"
              value={formik.values.executiveCode}
              required={true}
              showRequiredLabel={false}
              onChange={formik.handleChange}
              errorMsg={formik.errors.executiveCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="executiveName"
              label="役職名"
              value={formik.values.executiveName}
              required={true}
              showRequiredLabel={false}
              onChange={formik.handleChange}
              errorMsg={formik.errors.executiveName}
            />
          </FormField>
          <FormSubmitArea
            customStyle={css({ textAlign: "center", marginTop: "60px" })}
          >
            {detailRole.editable === 1 && (
              <div style={{ width: "100%" }}>
                <PrimaryButton
                  text={executiveId ? "更新" : "役職マスタを登録"}
                  onClick={formik.handleSubmit}
                />
              </div>
            )}
            <Link to="/executive">
              <Button text="キャンセル" ghost={true} />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <Modal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title={executiveId ? "役職マスタを更新" : "役職マスタを登録"}
        submitText={executiveId ? "更新する" : "登録する"}
        note={
          executiveId
            ? "役職マスタを更新します。よろしいですか？"
            : "役職マスタを登録します。よろしいですか？"
        }
        onSubmit={formik.submitForm}
      >
        <div style={{ height: "40px" }}></div>
      </Modal>
    </BlockUI>
  );
};

export default ExecutiveAddForm;
