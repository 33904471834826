/** @jsx jsx */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Column } from "react-table";
import React, { useMemo, useCallback, useState } from "react";
import { css, jsx } from "@emotion/core";
import LableTitle from "components/atoms/LableTitle";
import PageTitle from "components/atoms/PageTitle";
import { Link } from "react-router-dom";
import PrimaryButton, { SubActionButton } from "components/atoms/Button";
import DataTable from "components/organismos/DataTable/DataTable";
import { iconColor } from "components/styles";
import Icon from "components/atoms/Icon";
import WorkingDaysDomain from "domain/master/labor/workingDays";
import { deleteWorkingDayById } from "api/workingDay";
import ConfirmActionModal, {
  ActionType,
} from "components/organismos/ConfirmActionModal";
import useToastNotification from "hooks/useToastNotification";
import FormContents from "components/atoms/Form/FormContents";
import UsePrescribedWorkingDaysList from "./hooks";
import SidebarTemplate from "components/templates/SidebarTemplate";
import ClickableLink from "components/atoms/ClickableLink";
import BodyText from "components/atoms/BodyText";
import productColor, { textColor } from "components/styles";
import { SecondaryButton } from "components/atoms/Button";
import { useHistory } from "react-router-dom";
import IconLabelButton from "components/molecules/IconLabelButton";
import Modal from "components/molecules/Modal";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";

const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  actionBtn: `
    padding: 0;
    width: 40px;
    text-align: center;
    height: 24px;
    border-radius: 3px;
    color: white;
    margin-left: 4px;
    line-height: 0;
    font-size: 12px;
    &:hover,
    &:focus {
      opacity: 0.9;
      cursor: pointer;
    }
  `,
  editBtn: `
    background-color: ${productColor.editAction};
    &:hover,
    &:focus {
      background-color: ${productColor.editAction};
    }
  `,
  deleteBtn: `
    background-color: ${productColor.deleteAction};
    &:hover,
    &:focus {
      background-color: ${productColor.deleteAction};
    }
  `,
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
};
const PrescribedDaysPage = () => {
  const {
    workingDayList,
    setWorkingDayList,
    blocking,
    setBlocking,
    detailRole,
  } = UsePrescribedWorkingDaysList();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedPrescribed, setSelectedPrescribed] = useState(
    WorkingDaysDomain.generateInitial()
  );
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((workingDay: WorkingDaysDomain) => {
    setConfirmModalOpen(true);
    setSelectedPrescribed(workingDay);
  }, []);

  const deletePrescribed = useCallback(() => {
    setBlocking(true);
    setConfirmModalOpen(false);
    deleteWorkingDayById(selectedPrescribed.workingDaysId)
      .then(() => {
        setWorkingDayList(
          workingDayList.filter(
            (prescribed: WorkingDaysDomain) =>
              prescribed.workingDaysId !== selectedPrescribed.workingDaysId
          )
        );
        setBlocking(false);
        successNotification("削除しました。");
      })
      .catch((error) => {
        setBlocking(false);
        if (
          error.response.data.errors != null &&
          error.response.data.errors.length !== 0
        ) {
          const listErr = error.response.data.errors;
          let stringErr = "";
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    selectedPrescribed.workingDaysId,
    setWorkingDayList,
    successNotification,
    workingDayList,
    setBlocking,
  ]);

  const columns: Array<Column<WorkingDaysDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        disableSortBy: true,
        id: "actionButton",
        Cell: (cell: {
          row: { isExpanded: boolean; original: WorkingDaysDomain };
          data: Array<WorkingDaysDomain>;
        }) => {
          const [isHoverEdit, setIsHoverEdit] = useState(false);
          const [isHoverDelete, setIsHoverDelete] = useState(false);
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FlexBox>
                <FlexBoxItem marginLeft="5px">
                  <SubActionButton
                    action={0}
                    text={detailRole.editable === 1 ? `編集` : `参照`}
                    onClick={() => {
                      history.push(
                        `/masterWorkingDays/edit/${cell.row.original.workingDaysId}/0`
                      );
                    }}
                  />
                </FlexBoxItem>
                {detailRole.editable === 1 ? (
                  <FlexBoxItem marginLeft="5px">
                    <SubActionButton
                      action={1}
                      text="削除"
                      onClick={() => {
                        openConfirmModal(cell.row.original);
                      }}
                    />
                  </FlexBoxItem>
                ) : null}
              </FlexBox>

              {/* <Link to={`/masterWorkingDays/edit/${cell.row.original.workingDaysId}/0`}>
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />

              </span>
            </Link> */}
              {/* <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
              <span
                style={{ marginLeft: '20px', marginRight: '10px' }}
                onMouseEnter={() => setIsHoverDelete(true)}
                onMouseLeave={() => setIsHoverDelete(false)}
              >
                <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
              </span>
            </Link> */}
            </div>
          );
        },
      },
      {
        Header: "パターンコード",
        accessor: "workingDaysPatternCode",
      },
      {
        Header: "パターン名",
        accessor: "workingDaysPatternName",
      },
    ],
    [openConfirmModal, history, detailRole]
  );
  return (
    <SidebarTemplate pageTitle="役職マスタ">
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle bold={true} title="所定労働日数マスタ" />
          <div>
            {detailRole.editable === 1 && (
              <div
                style={{
                  margin: "20px 25px",
                  width: "80px",
                  paddingBottom: "30px",
                }}
              >
                <IconLabelButton
                  onClick={() => history.push("/masterWorkingDays/add")}
                  iconType="addCircle"
                  text="追加"
                />
              </div>
            )}
            <div
              css={css`
                table {
                  table-layout: auto;
                }
                table th:nth-of-type(1) {
                  width: 100px;
                }
                table th:nth-of-type(2) {
                  width: 100px;
                  text-align: left;
                }
                table th:nth-of-type(3) {
                  width: calc(100% - 200px);
                  text-align: left;
                }
              `}
            >
              <DataTable
                columns={columns}
                data={workingDayList}
                isGlobalFilter={true}
                globalSearchStyle={styles.filterSearch}
                containerStyle={css({
                  overflowX: "auto",
                })}
              />
            </div>
            <Modal
              open={confirmModalOpen}
              closeHandler={closeConfirmModal}
              title="所定労働日数を削除"
              submitText="削除する"
              note="所定労働日数を削除します。よろしいですか？"
              onSubmit={deletePrescribed}
              typeSubmit="reject"
            >
              <div style={{ height: "20px" }}></div>
            </Modal>
          </div>
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default PrescribedDaysPage;
