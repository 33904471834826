
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterOrganizeSettingLayout from 'components/organismos/master/labor/MasterOrganizeSettingLayout';

const MasterOrganizeSettingPage = () => (
  <SidebarTemplate pageTitle="組織設定マスタ">
    <MasterOrganizeSettingLayout />
  </SidebarTemplate>

);

export default MasterOrganizeSettingPage;
