import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from "components/atoms/TabControl";
import HolidayManagementSpecialHolidayForm from "./HolidayManagementSpecialHolidayForm/HolidayManagementSpecialHolidayForm";
import HolidayManagementRestForm from "./HolidayManagementRestForm/HolidayManagementRestForm";
import HolidayManagementPaidForm from "./HolidayManagementPaidForm/HolidayManagementPaidForm";
import HolidayManagementHolidayForm from "./HolidayManagementHolidayForm/HolidayManagementHolidayForm";
import { useHolidayManagementDomainForm } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import { css } from "@emotion/core";
import HolidayManagementNursingCareForm from "./HolidayManagementNursingCareForm";
import HolidayManagementStockPaid from "./HolidayManagementStockPaid/HolidayManagementStockPaid";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { DetailRole } from "api/masterRole";

type SelectType =
  | "paid"
  | "specialHoliday"
  | "holiday"
  | "rest"
  | "nursingCare"
  | "stockPaid";

const SelectType: { [key in SelectType]: SelectType } = {
  paid: "paid",
  specialHoliday: "specialHoliday",
  holiday: "holiday",
  rest: "rest",
  nursingCare: "nursingCare",
  stockPaid: "stockPaid",
};

//Context global variable
type contextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  detailRole: DetailRole;
};

export const HolidayManagementContext = createContext<contextType>(
  {} as contextType
);

const HolidayManagementForm: React.FC = () => {
  const {
    arrGrantResult,
    orgTreesOptions,
    employmentOptions,
    specialHolidayOptions,
  } = useHolidayManagementDomainForm();
  //
  const [selectType, setSelectType] = useState<SelectType>(SelectType.paid);
  const [currentAccordionList, setCurrentAccordionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(
    setIsLoading,
    functionCode.attendHolidayManagement
  );

  return (
    // eslint-disable-next-line react/jsx-fragments
    <HolidayManagementContext.Provider
      value={{
        isLoading,
        setIsLoading,
        detailRole,
      }}
    >
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle
            title="休暇実績"
            bold={true}
            customStyle={css`
              margin: 16px 48px;
            `}
          />
          <div style={{ padding: "20px" }}>
            <TabControl
              items={[
                {
                  value: SelectType.paid,
                  display: "有給",
                },
                {
                  value: SelectType.specialHoliday,
                  display: "特休",
                },
                {
                  value: SelectType.holiday,
                  display: "代休",
                },
                {
                  value: SelectType.rest,
                  display: "振休",
                },
                {
                  value: SelectType.nursingCare,
                  display: "介護・看護",
                },
                {
                  value: SelectType.stockPaid,
                  display: "積立有給",
                },
              ]}
              setValue={(value: any) => {
                setSelectType(value);
              }}
              currentValue={selectType}
              customStyle={css({
                margin: "0px",
                padding: "8px 16px",
                boxSizing: "border-box",
                maxWidth: "100%",
                overflow: "auto",
              })}
            />
          </div>
          <div>
            {/* 有給・管理情報 */}
            {selectType === SelectType.paid && (
              <HolidayManagementPaidForm
                arrGrantResult={arrGrantResult}
                orgTreesOptions={orgTreesOptions}
                employmentOptions={employmentOptions}
                currentAccordionList={currentAccordionList}
                setCurrentAccordionList={setCurrentAccordionList}
              />
            )}
            {/* 特休・管理情報 */}
            {selectType === SelectType.specialHoliday && (
              <HolidayManagementSpecialHolidayForm
                arrGrantResult={arrGrantResult}
                orgTreesOptions={orgTreesOptions}
                employmentOptions={employmentOptions}
                specialHolidayOptions={specialHolidayOptions}
                currentAccordionList={currentAccordionList}
                setCurrentAccordionList={setCurrentAccordionList}
              />
            )}

            {/* 代休・取得状況 */}
            {selectType === SelectType.holiday && (
              <HolidayManagementHolidayForm orgTreesOptions={orgTreesOptions} />
            )}

            {/* 振休・取得状況 */}
            {selectType === SelectType.rest && (
              <HolidayManagementRestForm orgTreesOptions={orgTreesOptions} />
            )}

            {/* 振休・取得状況 */}
            {selectType === SelectType.nursingCare && (
              <HolidayManagementNursingCareForm
                arrGrantResult={arrGrantResult}
                orgTreesOptions={orgTreesOptions}
                employmentOptions={employmentOptions}
                currentAccordionList={currentAccordionList}
                setCurrentAccordionList={setCurrentAccordionList}
              />
            )}
            {selectType === SelectType.stockPaid && (
              <HolidayManagementStockPaid orgTreesOptions={orgTreesOptions} />
            )}
          </div>
        </FormContents>
      </BlockUI>
    </HolidayManagementContext.Provider>
  );
};

export default HolidayManagementForm;
