/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import { getList } from 'api/executive';
import ExecutiveDomain from 'domain/master/labor/executive';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useExecutiveList = () => {
  const [executiveList, setExecutiveList] = useState<Array<ExecutiveDomain>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.executive);

  useEffect(() => {
    setIsLoading(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      setIsLoading(false);
      setExecutiveList(response.map((result) => new ExecutiveDomain(result)));
    });
  }, []);
  return { executiveList, setExecutiveList, isLoading, setIsLoading, detailRole };
};


export default useExecutiveList;
