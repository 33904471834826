/** @jsx jsx */
import React, { useCallback, useState, useEffect } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { productColor, utilityColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FileSelectButton from '../FileSelectButton';
import BodyText from 'components/atoms/BodyText';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormField from 'components/atoms/Form/FormField';


const styles = {
  uploadButton: css({
      marginTop: '25px',
      justifyContent: "center"
  }),
  fileSelectButton: css({
      minWidth: '400px',
      width: 'fit-content',
      padding: '0 30px',
      margin: 'auto',
      height: '107px'
  }),
  noteText: css({
      fontSize: '12px',
      color: '#E95562'
  }),
  noteLabel: css({
      justifyContent: "center",
  }),
};

interface ImportCSVFormProps {
  uploadFile?: File,
  setUploadFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  errors?: Array<string>,
  textImport?: string
}

const ImportCSVForm: React.FC<ImportCSVFormProps> = ({
  uploadFile, setUploadFile, errors, textImport = "CSVファイルをアップロード"
}) => {

  return (
    <React.Fragment>
      <FlexBox customStyle={styles.uploadButton} >
        <FileSelectButton
          text={textImport}
          ghost={true}
          setFile={setUploadFile}
          accept="text/csv,.csv,application/vnd.ms-excel"
          customStyle={styles.fileSelectButton}
        />
      </FlexBox>
      <FlexBox justifyContent="center">
          <BodyText customStyle={styles.noteText}>CSVファイルの1行目（ヘッダー行）はインポートされません。</BodyText>
      </FlexBox>
      <FlexBox customStyle={styles.noteLabel}>
          <FlexBoxItem basis="400px">
              {uploadFile ? (
                  <FormField displayBlock={true} customStyle={css({textAlign: 'center'})}>
                      <div>{uploadFile?.name}</div>
                      </FormField>
                  ) : null}
          </FlexBoxItem>
      </FlexBox>
      <FlexBox>
          {
              errors ? (
                  <FormField displayBlock={true}>
                      {errors.map((error) => (
                          <div key={error}>
                            <BodyText size="sm" color={utilityColor.error}>{error}</BodyText>
                          </div>
                          ))} 
                  </FormField>
              ) : null
          }
      </FlexBox>
    </React.Fragment>
  );
};

export default ImportCSVForm;
