import React, { SVGProps } from 'react';

const CheckboxOn: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 0H15.5C16.8807 0 18 1.11929 18 2.5V15.5C18 16.8807 16.8807 18 15.5 18H2.5C1.11929 18 0 16.8807 0 15.5V2.5C0 1.11929 1.11929 0 2.5 0ZM2.5 1C1.67157 1 1 1.67157 1 2.5V15.5C1 16.3284 1.67157 17 2.5 17H15.5C16.3284 17 17 16.3284 17 15.5V2.5C17 1.67157 16.3284 1 15.5 1H2.5ZM12.8536 6.14645C12.6583 5.95118 12.3417 5.95118 12.1464 6.14645L7.5 10.7929L5.85355 9.14645C5.65829 8.95118 5.34171 8.95118 5.14645 9.14645C4.95118 9.34171 4.95118 9.65829 5.14645 9.85355L7.14645 11.8536C7.34171 12.0488 7.65829 12.0488 7.85355 11.8536L12.8536 6.85355C13.0488 6.65829 13.0488 6.34171 12.8536 6.14645Z" fill="#45D1C9"/>
  </svg>
);

export default CheckboxOn;


