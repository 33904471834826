/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { RecorderButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import { grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import useGPSRecorder from "./hooks";
import { styles } from "components/organismos/RecorderV1/styles";
import useIsMobile from "hooks/useIsMobile";

const GPSRecorder: React.FC<{
  dataTimes: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHistoryData: any;
}> = ({ dataTimes, setIsLoading, fetchHistoryData }) => {
  const { stampStaff, handleStampEvent } = useGPSRecorder(setIsLoading, fetchHistoryData);
  const isMobile = useIsMobile();
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const notCheckIn = stampStaff?.lastStampType === -1 || stampStaff?.lastStampType === 3;
  const checkedIn = stampStaff?.lastStampType === 0 || stampStaff?.lastStampType === 2;
  const onBreak = stampStaff?.useRecess && stampStaff?.lastStampType === 1;
  const isCustom = companyCode === "HPo6wkyuze";

  return (
    <React.Fragment>
      <div css={styles.formContainer}>
        <div>
          {dataTimes.currentDay ? (
            <React.Fragment>
              <FlexBox customStyle={styles.dateForm}>
                <BodyText size="lg" color={grayScale.gray100} bold={true}>
                  {dataTimes.currentDay}
                </BodyText>
              </FlexBox>
              <FlexBox customStyle={styles.time}>
                <BodyText>{dataTimes.timeNow}</BodyText>
                <BodyText>{dataTimes.second}</BodyText>
              </FlexBox>
            </React.Fragment>
          ) : (
            <div css={styles.loadingText}>サーバ時間取得中・・・</div>
          )}
        </div>
        <React.Fragment>
          {/* in */}
          {/* Default UI */}
          {notCheckIn && !stampStaff?.useRemote && (
            <div css={styles.buttonContainer}>
              <RecorderButton
                text="出勤"
                onClick={() => handleStampEvent("in", "")}
                icon="checkInOffice"
                background="#007BC3"
              />
            </div>
          )}

          {/* New UI */}
          {notCheckIn && stampStaff?.useRemote && (
            <div css={styles.buttonContainer}>
              <RecorderButton
                text="オフィス出勤"
                onClick={() => handleStampEvent("in", "", false)}
                icon="checkInOffice"
                background="#007BC3"
              />
              <RecorderButton
                text={isCustom ? "在宅勤務" : "テレワーク出勤"}
                textMin={isCustom ? "(自宅待機含む)" : ""}
                onClick={() => handleStampEvent("in", "", true)}
                icon="checkInRemote"
                background="#F8BF2D"
              />
            </div>
          )}

          {/* out */}

          {checkedIn && (
            <div css={styles.buttonContainer}>
              <RecorderButton
                text="退勤"
                onClick={() => handleStampEvent("out", "")}
                icon="checkOut"
                background="#666666"
              />

              {stampStaff?.useRecess ? (
                <RecorderButton
                  text="休憩開始"
                  onClick={() => handleStampEvent("recess_in", "")}
                  icon="breakStart"
                  background="#00B172"
                />
              ) : null}

              <RecorderButton
                text="現在地登録"
                onClick={() => handleStampEvent("current", "")}
                icon="registerLocation"
                background="#FB7971"
              />
            </div>
          )}

          {onBreak && (
            <div css={styles.buttonContainer}>
              <RecorderButton
                text="休憩終了"
                onClick={() => handleStampEvent("recess_out", "")}
                icon="breakEnd"
                background="#8B2E9C"
              />

              <RecorderButton
                text="現在地登録"
                onClick={() => handleStampEvent("current", "")}
                icon="registerLocation"
                background="#FB7971"
              />
            </div>
          )}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default GPSRecorder;
