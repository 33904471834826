
/**
 * API 勤怠再計算
 */

export interface OrganizationTimeRecorder {
  shopCode: string,
  companyCode: string,
  useAssistSystem: boolean,
  useAttend: boolean,
  useFaceStamp: 0 | 1,
  useBarcodeStamp: 0 | 1,
  shopName: string,
  useSales: boolean
}


export default class OrganizationTimeRecorderDomain {
  constructor(private rawData: OrganizationTimeRecorder) {
  // do nothing
  }

  static generateInitial(): OrganizationTimeRecorderDomain {
    return new OrganizationTimeRecorderDomain({
      shopCode: '',
      companyCode: '',
      useAssistSystem: false,
      useAttend: false,
      useFaceStamp: 0,
      useBarcodeStamp: 0,
      shopName: '',
      useSales: false,
    });
  }

  getRawData(): OrganizationTimeRecorder {
    return this.rawData;
  }

  get shopCode():string {
    return this.rawData.shopCode;
  }

  set shopCode(shopCode:string) {
    this.rawData.shopCode = shopCode;
  }

  get companyCode():string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode:string) {
    this.rawData.companyCode = companyCode;
  }

  get useAssistSystem():boolean {
    return this.rawData.useAssistSystem;
  }

  set useAssistSystem(useAssistSystem:boolean) {
    this.rawData.useAssistSystem = useAssistSystem;
  }

  get useAttend():boolean {
    return this.rawData.useAttend;
  }

  set useAttend(useAttend:boolean) {
    this.rawData.useAttend = useAttend;
  }

  get useFaceStamp():0|1 {
    return this.rawData.useFaceStamp;
  }

  set useFaceStamp(useFaceStamp:0|1) {
    this.rawData.useFaceStamp = useFaceStamp;
  }

  get useBarcodeStamp():0|1 {
    return this.rawData.useBarcodeStamp;
  }

  set useBarcodeStamp(useBarcodeStamp:0|1) {
    this.rawData.useBarcodeStamp = useBarcodeStamp;
  }

  get shopName():string {
    return this.rawData.shopName;
  }

  set shopName(shopName:string) {
    this.rawData.shopName = shopName;
  }

  get useSales():boolean {
    return this.rawData.useSales;
  }

  set useSales(useSales:boolean) {
    this.rawData.useSales = useSales;
  }
  // get

// custom data use DataTable
}
