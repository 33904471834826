export const renderError = (error: any, isHiddenMessage?: boolean) => {
  let msgError = "";
  if (error.response && error.response.data && error.response.data.errors) {
    error.response.data.errors.map((item: { defaultMessage: string }) => {
      msgError += `${item.defaultMessage} <br />`;
    });
  } else if (error.response && error.response.data && error.response && error.response.data.defaultMessage) {
    msgError = error.response.data.defaultMessage;
  } else {
    if (!isHiddenMessage) {
      msgError = "サーバー側でエラーが発生しました。";
    }
  }
  return msgError;
};
