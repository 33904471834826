/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { RecorderButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import { grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import useBarcodeRecorder from "./hooks";
import useIsMobile from "hooks/useIsMobile";
import { styles } from "components/organismos/RecorderV1/styles";

const BarcodeRecorder: React.FC<{
  dataTimes: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHistoryData: any;
}> = ({ dataTimes, setIsLoading, fetchHistoryData }) => {
  const { isConfirm, lastStampType, handleRecorder, newUiRemote } = useBarcodeRecorder(
    () => {},
    setIsLoading,
    fetchHistoryData,
  );
  const isMobile = useIsMobile();
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const notCheckIn = lastStampType === -1 || lastStampType === 3;
  const checkedIn = lastStampType === 0 || lastStampType === 2;
  const onBreak = lastStampType === 1;
  const isCustom = companyCode === "HPo6wkyuze";

  return (
    <React.Fragment>
      <div css={styles.formContainer}>
        <div>
          {!dataTimes.currentDay && <div css={styles.loadingText}>サーバ時間取得中・・・</div>}

          <FlexBox customStyle={styles.dateForm}>
            <BodyText size="lg" color={grayScale.gray100} bold={true}>
              {dataTimes.currentDay}
            </BodyText>
          </FlexBox>
          <FlexBox customStyle={styles.time}>
            <BodyText>{dataTimes.timeNow}</BodyText>
            <BodyText>{dataTimes.second}</BodyText>
          </FlexBox>
        </div>
        {isConfirm && (
          <React.Fragment>
            {notCheckIn && !newUiRemote && (
              <div css={styles.buttonContainer}>
                <RecorderButton
                  text="出勤"
                  onClick={() => handleRecorder(0)}
                  icon="checkInOffice"
                  background="#007BC3"
                />
              </div>
            )}

            {notCheckIn && newUiRemote && (
              <div css={styles.buttonContainer}>
                <RecorderButton
                  text="オフィス出勤"
                  onClick={() => handleRecorder(0)}
                  icon="checkInOffice"
                  background="#007BC3"
                />
                <RecorderButton
                  text={isCustom ? "在宅勤務" : "テレワーク出勤"}
                  textMin={isCustom ? "(自宅待機含む)" : ""}
                  onClick={() => handleRecorder(0, undefined, undefined, true)}
                  icon="checkInRemote"
                  background="#F8BF2D"
                />
              </div>
            )}

            {checkedIn && (
              <div css={styles.buttonContainer}>
                <RecorderButton text="退勤" onClick={() => handleRecorder(3)} icon="checkOut" background="#666666" />
                <RecorderButton
                  text="休憩開始"
                  onClick={() => handleRecorder(1)}
                  icon="breakStart"
                  background="#00B172"
                />
              </div>
            )}

            {onBreak && (
              <div css={styles.buttonContainer}>
                <RecorderButton
                  text="休憩終了"
                  onClick={() => handleRecorder(2)}
                  icon="breakEnd"
                  background="#8B2E9C"
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default BarcodeRecorder;
