
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterShopFaceRegistrationFormV1 from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1';

const MasterShopFaceRegistrationPageV1 = () => (
  <SidebarTemplate pageTitle="顔写真登録">
    <MasterShopFaceRegistrationFormV1 />
  </SidebarTemplate>
);

export default MasterShopFaceRegistrationPageV1;
