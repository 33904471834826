/** @jsx jsx */
import React, { useEffect } from 'react';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import HourInput from 'components/atoms/Form/HourInput';
import FormLabel from 'components/atoms/Form/FormLabel';
import TextInput from 'components/atoms/Form/TextInput';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import { css, jsx } from '@emotion/core';
import Checkbox from 'components/molecules/Checkbox';
import SelectForm from 'components/molecules/SelectForm';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import { gridCol, grayScale } from 'components/styles';

const days = [new Option('1'), new Option('2'), new Option('3'), new Option('4'),
new Option('5'), new Option('6'), new Option('7'), new Option('8'),
new Option('9'), new Option('10'), new Option('11'), new Option('12'),
new Option('13'), new Option('14'), new Option('15'), new Option('16'),
new Option('17'), new Option('18'), new Option('19'), new Option('20'),
new Option('21'), new Option('22'), new Option('23'), new Option('24'),
new Option('25'), new Option('26'), new Option('27'), new Option('28'),
new Option('29'), new Option('30'), new Option('31')];

const months = [new Option('1'), new Option('2'), new Option('3'), new Option('4'),
new Option('5'), new Option('6'), new Option('7'), new Option('8'),
new Option('9'), new Option('10'), new Option('11'), new Option('12')];

const styles = {
  help: css({
    color: 'red',
    bottom: '-13px',
    left: '10px',
    position: 'relative'
  }),
  span: css({
    margin: '0px 10px',
    position: 'relative',
    top: '-2px'
  }),
  addon: css({
    color: grayScale.gray100,
    padding: '7px'
  }),
  formBody: css({
    padding: '5px 40px',
    margin: '0px',
    paddingBottom: '30px'
  }),
  formHeader: css({
    margin: '16px 0px'
  })
}


const daysInMonth = (month: number) => {
  const dateCurrent = new Date();
  return new Date(dateCurrent.getFullYear(), month, 0).getDate();
}

const DetailTexiuForm: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: (paidCalculationStandard: boolean) => void;
  paidFlag: number;
  setPaidFlag: (paidFlag: number) => void;
  isWorkTime: number;
  setIsWorkTime: (isWorkTime: number) => void;
  unUsableOfTrialPeriod: boolean;
  setUnUsableOfTrialPeriod: (unUsableOfTrialPeriod: boolean) => void;
  useEmploymentDeemedWorkingHours: boolean;
  setUseEmploymentDeemedWorkingHours: (useEmploymentDeemedWorkingHours: boolean) => void;
  deemedWorkingHours: number,
  setDeemedWorkingHours: (deemedWorkingHours: number) => void
  deemedWorkingMinutes: number,
  setDeemedWorkingMinutes: (deemedWorkingMinutes: number) => void
  autoGrantHoliday: boolean;
  setAutoGrantHoliday: (autoGrantHoliday: boolean) => void;
  autoGrantDateMonth: number,
  setAutoGrantDateMonth: (autoGrantDateMonth: number) => void
  autoGrantDateDay: number,
  setAutoGrantDateDay: (autoGrantDateDay: number) => void
  autoGrantHolidayType: number,
  setAutoGrantHolidayType: (autoGrantHolidayType: number) => void
  autoGrantDay: number | '',
  setAutoGrantDay: (autoGrantDay: number | '') => void
  autoGrantWorkingStartMonths1: number | '',
  setAutoGrantWorkingStartMonths1: (autoGrantWorkingStartMonths1: number | '') => void
  autoGrantWorkingEndMonths1: number | '',
  setAutoGrantWorkingEndMonths1: (autoGrantWorkingEndMonths1: number | '') => void
  autoGrantWorkingDay1: number | '',
  setAutoGrantWorkingDay1: (autoGrantWorkingDay1: number | '') => void
  autoGrantWorkingStartMonths2: number | '',
  setAutoGrantWorkingStartMonths2: (autoGrantWorkingStartMonths2: number | '') => void
  autoGrantWorkingEndMonths2: number | '',
  setAutoGrantWorkingEndMonths2: (autoGrantWorkingEndMonths2: number | '') => void
  autoGrantWorkingDay2: number | '',
  setAutoGrantWorkingDay2: (autoGrantWorkingDay2: number | '') => void
  autoGrantWorkingStartMonths3: number | '',
  setAutoGrantWorkingStartMonths3: (autoGrantWorkingStartMonths3: number | '') => void
  autoGrantWorkingEndMonths3: number | '',
  setAutoGrantWorkingEndMonths3: (autoGrantWorkingEndMonths3: number | '') => void
  autoGrantWorkingDay3: number | '',
  setAutoGrantWorkingDay3: (autoGrantWorkingDay1: number | '') => void
  enteringGrantHoliday: boolean,
  setEnteringGrantHoliday: (enteringGrantHoliday: boolean) => void
  firstGrantType: number,
  setFirstGrantType: (firstGrantType: number) => void
  enteringGrantDay1: number | '',
  setEnteringGrantDay1: (enteringGrantDay1: number | '') => void
  enteringGrantDay2: number | '',
  setEnteringGrantDay2: (enteringGrantDay2: number | '') => void
  enteringGrantDay3: number | '',
  setEnteringGrantDay3: (enteringGrantDay3: number | '') => void
  includingTrialPeriod: boolean;
  setIncludingTrialPeriod: (includingTrialPeriod: boolean) => void;
  noticeStaffs: Array<any>;
  setNoticeStaffs: (noticeStaffs: Array<any>) => void;
  listStaff: Array<any>;
  unUsableOfHalfHoliday: boolean;
  setUnUsableOfHalfHoliday: (unUsableOfHalfHoliday: boolean) => void;
  validityPeriodStartMonth: number;
  setValidityPeriodStartMonth: (validityPeriodStartMonth: number | '') => void;
  validityPeriodStartDay: number;
  setValidityPeriodStartDay: (validityPeriodStartDay: number | '') => void;
  validityPeriodEndMonth: number;
  setValidityPeriodEndMonth: (validityPeriodEndMonth: number | '') => void;
  validityPeriodEndDay: number;
  setValidityPeriodEndDay: (validityPeriodEndDay: number | '') => void;
}> = ({
  paidCalculationStandard, setPaidCalculationStandard,
  paidFlag, setPaidFlag,
  isWorkTime, setIsWorkTime,
  unUsableOfTrialPeriod, setUnUsableOfTrialPeriod,
  useEmploymentDeemedWorkingHours, setUseEmploymentDeemedWorkingHours,
  deemedWorkingHours, setDeemedWorkingHours,
  deemedWorkingMinutes, setDeemedWorkingMinutes,
  autoGrantHoliday, setAutoGrantHoliday,
  autoGrantDateMonth, setAutoGrantDateMonth,
  autoGrantDateDay, setAutoGrantDateDay,
  autoGrantHolidayType, setAutoGrantHolidayType,
  autoGrantDay, setAutoGrantDay,
  autoGrantWorkingStartMonths1, setAutoGrantWorkingStartMonths1,
  autoGrantWorkingEndMonths1, setAutoGrantWorkingEndMonths1,
  autoGrantWorkingDay1, setAutoGrantWorkingDay1,
  autoGrantWorkingStartMonths2, setAutoGrantWorkingStartMonths2,
  autoGrantWorkingEndMonths2, setAutoGrantWorkingEndMonths2,
  autoGrantWorkingDay2, setAutoGrantWorkingDay2,
  autoGrantWorkingStartMonths3, setAutoGrantWorkingStartMonths3,
  autoGrantWorkingEndMonths3, setAutoGrantWorkingEndMonths3,
  autoGrantWorkingDay3, setAutoGrantWorkingDay3,
  enteringGrantHoliday, setEnteringGrantHoliday,
  firstGrantType, setFirstGrantType,
  enteringGrantDay1, setEnteringGrantDay1,
  enteringGrantDay2, setEnteringGrantDay2,
  enteringGrantDay3, setEnteringGrantDay3,
  includingTrialPeriod, setIncludingTrialPeriod,
  noticeStaffs, setNoticeStaffs,
  listStaff,
  unUsableOfHalfHoliday, setUnUsableOfHalfHoliday,
  validityPeriodStartMonth, setValidityPeriodStartMonth,
  validityPeriodStartDay, setValidityPeriodStartDay,
  validityPeriodEndMonth, setValidityPeriodEndMonth,
  validityPeriodEndDay, setValidityPeriodEndDay,
}) => {

    useEffect(() => { 
      setValidityPeriodEndDay(daysInMonth(validityPeriodEndMonth));
    },[validityPeriodStartMonth, validityPeriodEndMonth]);

    return (
      <FormContents isSubForm={true} customStyle={styles.formBody}>
        <FormTitle
          title="特休詳細設定"
          note=""
          customStyle={styles.formHeader}
        />
        <FormField>
          <RadioSelectForm
            label="有給算出基準：勤務日数への加算"
            subLabel=""
            items={[
              {
                label: '加算しない',
                value: '0',
              },
              {
                label: '加算する',
                value: '1',
              },
            ]}
            name="paidCalculationStandard"
            value={paidCalculationStandard ? '1' : '0'}
            setValue={(e) => {
              setPaidCalculationStandard(e.target.value !== '0');
            }}
          />
        </FormField>
        {/* { paidCalculationStandard === true && (
      <FormContents isSubForm={true}>
          <div
         
          >
        <FormField>
          <RadioSelectForm
            additionStyle={{display: 'inline-block'}}
            label="特休時みなし勤務時間"
            subLabel=""
            items={[
              {
                label: '設定する',
                value: '0',
                children: (
                  <FormField>
                    <FlexBox>
                      <HourInput
                        name="deemedWorkingHours"
                        value={deemedWorkingHours}
                        label="時間"
                        onChange={(e) => {
                          setDeemedWorkingHours(Number(e.target.value));
                        }}
                        min={0}
                        max={23}
                      />
                      <HourInput
                        name="deemedWorkingMinutes"
                        value={deemedWorkingMinutes}
                        label="分"
                        onChange={(e) => {
                          setDeemedWorkingMinutes(Number(e.target.value));
                        }}
                        min={0}
                        max={59}
                      />
                    </FlexBox>
                  </FormField>
                )
              },
              {
                label: '雇用形態に設定されている日の契約労働時間をみなし勤務時間とする',
                value: '1',
              },
            ]}
            name="useEmploymentDeemedWorkingHours"
            value={useEmploymentDeemedWorkingHours ? '1' : '0'}
            setValue={(e) => { setUseEmploymentDeemedWorkingHours(e.target.value !== '0'); }}
          />

        </FormField>
        </div>
      </FormContents>
    )} */}
        <FormField>
          <RadioSelectForm
            label="有給・無給区分"
            subLabel=""
            items={[
              {
                label: '無給',
                value: '0',
              },
              {
                label: '有給',
                value: '1',
              },
            ]}
            name="paidFlag"
            value={String(paidFlag)}
            setValue={(e) => {
              setPaidFlag(Number(e.target.value));
            }}
          />
        </FormField>
        { paidFlag === 1 && (
          <FormField>
            <RadioSelectForm
              label="みなし時間を労働時間に"
              subLabel=""
              items={[
                {
                  label: '含まない',
                  value: '0',
                },
                {
                  label: '含む',
                  value: '1',
                },
              ]}
              name="zz"
              value={String(isWorkTime)}
              setValue={(e) => {
                setIsWorkTime(Number(e.target.value));
              }}
            />
          </FormField>
        )}
        <FormField>
          <RadioSelectForm
            label="半休の使用可否"
            subLabel=""
            items={[
              {
                label: '使用不可',
                value: '0',
              },
              {
                label: '使用可',
                value: '1',
              },
            ]}
            name="unUsableOfHalfHoliday"
            value={unUsableOfHalfHoliday ? '1' : '0'}
            setValue={(e) => {
              setUnUsableOfHalfHoliday(Boolean(e.target.value !== '0'));
            }}
          />
        </FormField>

        <FormField>
          <RadioSelectForm
            label="試用期間中の特休使用可否"
            subLabel=""
            items={[
              {
                label: '使用不可',
                value: '1',
              },
              {
                label: '使用可',
                value: '0',
              },
            ]}
            name="unUsableOfTrialPeriod"
            value={unUsableOfTrialPeriod ? '1' : '0'}
            setValue={(e) => { setUnUsableOfTrialPeriod(e.target.value !== '0'); }}
          />
        </FormField>


        <FormField>
          <RadioSelectForm
            label="特休の自動付与：年に1回自動付与する"
            subLabel=""
            items={[
              {
                label: '付与しない',
                value: '0',
              },
              {
                label: '付与する',
                value: '1',
              },
            ]}
            name="autoGrantHoliday"
            value={autoGrantHoliday ? '1' : '0'}
            setValue={(e) => { setAutoGrantHoliday(e.target.value !== '0'); }}
          />
        </FormField>

        { autoGrantHoliday === true && (
          <FormContents isSubForm={true}>
            <FormField>
              <VerticalLabelFormLayout
                label={(
                  <FormLabel
                    label="特休の自動付与日"
                    required={false}
                  />
                )}
                input={(
                  <FlexBox>
                    <BodyText customStyle={css({ marginRight: '10px' })}>
                      毎年
                </BodyText>
                    <SelectForm
                      customStyle={{ marginLeft: '5px' }}
                      label=""
                      name="autoGrantDateMonth"
                      value={autoGrantDateMonth === 0 ? '1' : autoGrantDateMonth.toString()}
                      setValue={(e) => { setAutoGrantDateMonth(Number(e)); }}
                      options={months}
                    />
                    <BodyText customStyle={css({ marginLeft: '5px', marginRight: '20px' })}>
                      月
                </BodyText>

                    <SelectForm
                      customStyle={{ marginRight: '5px', marginLeft: '5px' }}
                      label=""
                      name="autoGrantDateDay"
                      value={autoGrantDateDay === 0 ? '1' : autoGrantDateDay.toString()}
                      setValue={(e) => { setAutoGrantDateDay(Number(e)); }}
                      options={days.filter((day) => Number(day.value) <= daysInMonth(autoGrantDateMonth))}
                    />
                    <BodyText>
                      日
                </BodyText>
                  </FlexBox>
                )}
              />
            </FormField>
            <FormField>
              <VerticalLabelFormLayout
                label={(
                  <FormLabel
                    label="特休の使用期間"
                    required={false}
                  />
                )}
                input={(
                  <FlexBox>
                    <SelectForm
                      customStyle={{ marginLeft: '5px' }}
                      label=""
                      name="validityPeriodStartMonth"
                      value={validityPeriodStartMonth === 0 ? '1' : validityPeriodStartMonth.toString()}
                      setValue={(e) => { setValidityPeriodStartMonth(Number(e)); }}
                      options={months}
                    />
                    <BodyText customStyle={css({ marginLeft: '5px', marginRight: '20px' })}>
                      月
                </BodyText>

                    <SelectForm
                      customStyle={{ marginRight: '5px', marginLeft: '5px' }}
                      label=""
                      name="validityPeriodStartDay"
                      value={validityPeriodStartDay === 0 ? '1' : validityPeriodStartDay.toString()}
                      setValue={(e) => { setValidityPeriodStartDay(Number(e)); }}
                      options={days.filter((day) => Number(day.value) <= daysInMonth(validityPeriodStartMonth))}
                    />
                    <BodyText>日から</BodyText>

                    <SelectForm
                      customStyle={{ marginLeft: '5px' }}
                      label=""
                      name="validityPeriodEndMonth"
                      value={validityPeriodEndMonth === 0 ? '12' : validityPeriodEndMonth.toString()}
                      setValue={(e) => { setValidityPeriodEndMonth(Number(e)); }}
                      options={months}
                    />
                    <BodyText customStyle={css({ marginLeft: '5px', marginRight: '20px' })}>
                      月
                </BodyText>
                    <SelectForm
                      customStyle={{ marginLeft: '5px' }}
                      label=""
                      name="validityPeriodEndDay"
                      value={validityPeriodEndDay === 0 ? daysInMonth(validityPeriodEndMonth).toString() : validityPeriodEndDay.toString()}
                      setValue={(e) => { setValidityPeriodEndDay(Number(e)); }}
                      options={days.filter((day) => Number(day.value) <= daysInMonth(validityPeriodEndMonth))}
                    />
                    <BodyText customStyle={css({ marginLeft: '5px', marginRight: '20px' })}>
                      日まで使用可能
                </BodyText>
                  </FlexBox>
                )}
              />
            </FormField>
            <FormField>
              <RadioSelectForm
                label="特休の自動付与日数"
                subLabel=""
                items={[
                  {
                    label: '全スタッフに一律設定',
                    value: '0',
                  },
                  {
                    label: '勤続年数による',
                    value: '1',
                  },
                ]}
                name="autoGrantHolidayType"
                value={autoGrantHolidayType === 1 ? '1' : '0'}
                setValue={(e) => { setAutoGrantHolidayType(Number(e.target.value)); }}
              />
            </FormField>

            { autoGrantHolidayType === 0 && (
              <FormContents customTitleStyle={css('display: none;')} customStyle={{ border: 'none' }} isSubForm={true}>
                <FormField>
                  <FlexBox>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantDay"
                        label=""
                        value={String(autoGrantDay) === 'null' ? '' : String(autoGrantDay)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantDay('');
                          } else {
                            setAutoGrantDay(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <BodyText customStyle={css({ marginLeft: '5px' })}>
                      日
                </BodyText>
                  </FlexBox>
                </FormField>
              </FormContents>
            )}

            { autoGrantHolidayType === 1 && (
              <FormContents customTitleStyle={css('display: none;')} customStyle={{ border: 'none' }} isSubForm={true}>
                <FormField>
                  <FlexBox>
                    <FlexBoxItem basis='50%'>
                      <BodyText customStyle={css({ marginRight: '2px' })}>
                        勤続年数
                </BodyText>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingStartMonths1"
                        label=""
                        value={String(autoGrantWorkingStartMonths1) === 'null' ? '' : String(autoGrantWorkingStartMonths1)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingStartMonths1('');
                          } else {
                            setAutoGrantWorkingStartMonths1(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem basis='40%'>

                      <BodyText customStyle={css({ marginLeft: '5px' })}>
                        ヶ月～
                </BodyText>
                    </FlexBoxItem>

                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingEndMonths1"
                        label=""
                        value={String(autoGrantWorkingEndMonths1) === 'null' ? '' : String(autoGrantWorkingEndMonths1)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingEndMonths1('');
                          } else {
                            setAutoGrantWorkingEndMonths1(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem basis='65%'>

                      <BodyText customStyle={css({ marginLeft: '5px' })}>
                        ヶ月までは
                </BodyText>
                    </FlexBoxItem>

                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingDay1"
                        label=""
                        value={String(autoGrantWorkingDay1) === 'null' ? '' : String(autoGrantWorkingDay1)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingDay1('');
                          } else {
                            setAutoGrantWorkingDay1(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <BodyText customStyle={css({ marginLeft: '5px' })}>
                      日
                </BodyText>
                  </FlexBox>
                </FormField>
                <FormField>
                  <FlexBox>
                    <FlexBoxItem basis='50%'>
                      <BodyText customStyle={css({ marginRight: '2px' })}>
                        勤続年数
                  </BodyText>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingStartMonths2"
                        label=""
                        value={String(autoGrantWorkingStartMonths2) === 'null' ? '' : String(autoGrantWorkingStartMonths2)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingStartMonths2('');
                          } else {
                            setAutoGrantWorkingStartMonths2(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem basis='40%'>
                      <BodyText customStyle={css({ marginLeft: '5px' })}>
                        ヶ月～
                  </BodyText>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingEndMonths2"
                        label=""
                        value={String(autoGrantWorkingEndMonths2) === 'null' ? '' : String(autoGrantWorkingEndMonths2)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingEndMonths2('');
                          } else {
                            setAutoGrantWorkingEndMonths2(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem basis='65%'>
                      <BodyText customStyle={css({ marginLeft: '5px' })}>
                        ヶ月までは
                  </BodyText>
                    </FlexBoxItem>

                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingDay2"
                        label=""
                        value={String(autoGrantWorkingDay2) === 'null' ? '' : String(autoGrantWorkingDay2)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingDay2('');
                          } else {
                            setAutoGrantWorkingDay2(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <BodyText customStyle={css({ marginLeft: '5px' })}>
                      日
                </BodyText>
                  </FlexBox>
                </FormField>
                <FormField>
                  <FlexBox>
                    <FlexBoxItem basis='50%'>
                      <BodyText customStyle={css({ marginRight: '2px' })}>
                        勤続年数
                  </BodyText>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingStartMonths3"
                        label=""
                        value={String(autoGrantWorkingStartMonths3) === 'null' ? '' : String(autoGrantWorkingStartMonths3)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingStartMonths3('');
                          } else {
                            setAutoGrantWorkingStartMonths3(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem basis='40%'>
                      <BodyText customStyle={css({ marginLeft: '5px' })}>
                        ヶ月～
                  </BodyText>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingEndMonths3"
                        label=""
                        value={String(autoGrantWorkingEndMonths3) === 'null' ? '' : String(autoGrantWorkingEndMonths3)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingEndMonths3('');
                          } else {
                            setAutoGrantWorkingEndMonths3(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem basis='65%'>
                      <BodyText customStyle={css({ marginLeft: '5px' })}>
                        ヶ月までは
                  </BodyText>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextInput
                        name="autoGrantWorkingDay3"
                        label=""
                        value={String(autoGrantWorkingDay3) === 'null' ? '' : String(autoGrantWorkingDay3)}
                        onChange={(e) => {
                          if (isNaN(Number(e.target.value)) || e.target.value === '') {
                            setAutoGrantWorkingDay3('');
                          } else {
                            setAutoGrantWorkingDay3(Number(e.target.value));
                          }
                        }}
                      />
                    </FlexBoxItem>
                    <BodyText customStyle={css({ marginLeft: '5px' })}>
                      日
                </BodyText>
                  </FlexBox>
                </FormField>

                <FormField>
                  <Checkbox
                    id="includingTrialPeriod"
                    name="includingTrialPeriod"
                    label="試用期間を勤続年数に含む"
                    value={String(includingTrialPeriod)}
                    checked={includingTrialPeriod}
                    onChange={(e) => {
                      setIncludingTrialPeriod(e.target.value === 'false');
                    }}
                  />

                </FormField>
              </FormContents>
            )}

            <FormLabel label="特休の有効期間：" />
            <FormField>
              <RadioSelectForm
                label="入社時の付与"
                subLabel=""
                items={[
                  {
                    label: '付与しない',
                    value: '0',
                  },
                  {
                    label: '付与する',
                    value: '1',
                  },
                ]}
                name="enteringGrantHoliday"
                value={enteringGrantHoliday ? '1' : '0'}
                setValue={(e) => { setEnteringGrantHoliday(e.target.value !== '0'); }}
              />
            </FormField>
            { enteringGrantHoliday && (
                <FormField>
                <RadioSelectForm
                  label="初回付与の優先順位"
                  subLabel=""
                  items={[
                    {
                      label: '一斉付与日を優先',
                      value: '0',
                    },
                    {
                      label: '入社時の付与日を優先',
                      value: '1',
                    },
                  ]}
                  name="firstGrantType"
                  value={firstGrantType === 1 ? '1' : '0'}
                  setValue={(e) => { setFirstGrantType(Number(e.target.value)); }}
                />
              </FormField>
            )}

            { enteringGrantHoliday && (
              <FormContents customStyle={{ border: 'none' }} isSubForm={true}>
                <VerticalLabelFormLayout
                  label={<FormLabel
                    label="付与日数">
                  </FormLabel>}
                  input={(
                    <div>
                      <FormField>
                        <FlexBox>
                          <BodyText customStyle={css({ width: '150px' })}>
                            入社日に
                        </BodyText>
                          <TextInput
                            name="enteringGrantDay1"
                            label=""
                            value={String(enteringGrantDay1) === 'null' ? '' : String(enteringGrantDay1)}
                            onChange={(e) => {
                              if (isNaN(Number(e.target.value)) || e.target.value === '') {
                                setEnteringGrantDay1('');
                              } else {
                                setEnteringGrantDay1(Number(e.target.value));
                              }
                            }}
                          />
                          <BodyText customStyle={css({ marginLeft: '5px' })}>日</BodyText>
                        </FlexBox>
                      </FormField>
                      <FormField>
                        <FlexBox>
                          <BodyText customStyle={css({ width: '150px' })}>
                            入社3ヵ月後に
                        </BodyText>
                          <TextInput
                            name="enteringGrantDay2"
                            label=""
                            value={String(enteringGrantDay2) === 'null' ? '' : String(enteringGrantDay2)}
                            onChange={(e) => {
                              if (isNaN(Number(e.target.value)) || e.target.value === '') {
                                setEnteringGrantDay2('');
                              } else {
                                setEnteringGrantDay2(Number(e.target.value));
                              }
                            }}
                          />
                          <BodyText customStyle={css({ marginLeft: '5px' })}>日</BodyText>
                        </FlexBox>
                      </FormField>
                      <FormField>
                        <FlexBox>
                          <BodyText customStyle={css({ width: '150px' })}>
                            入社6ヵ月後に
                        </BodyText>
                          <TextInput
                            name="enteringGrantDay3"
                            label=""
                            value={String(enteringGrantDay3) === 'null' ? '' : String(enteringGrantDay3)}
                            onChange={(e) => {
                              if (isNaN(Number(e.target.value)) || e.target.value === '') {
                                setEnteringGrantDay3('');
                              } else {
                                setEnteringGrantDay3(Number(e.target.value));
                              }
                            }}
                          />
                          <BodyText customStyle={css({ marginLeft: '5px' })}>日</BodyText>
                        </FlexBox>
                      </FormField>

                      <FormLabel label="入社時に付与する特休の有効期間：  次回の特休の自動付与日前日まで" />

                    </div>
                  )}
                />
              </FormContents>
            )}

            <div style={{ width: '100%' }}>
              <VerticalLabelMutilSelectForm
                label="自動特休付与結果通知先"
                name="organization"
                values={noticeStaffs.map(
                  (staffId) => listStaff.find((staffx) => staffx.value === staffId),
                )}
                setValue={(val: string) => { setNoticeStaffs(val.slice(0, -1).split(',')); }}
                options={listStaff}
                isMulti={true}
              />
            </div>
          </FormContents>
        )}
      </FormContents>
    )
  }
export default DetailTexiuForm;
