import React, { SVGProps } from "react";

const ChevronDown: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5555 0.210666C12.9225 -0.103828 13.4748 -0.0613366 13.7893 0.305573C14.1038 0.672482 14.0613 1.22487 13.6944 1.53936L7.56944 6.78935C7.24176 7.07022 6.75824 7.07022 6.43056 6.78935L0.305573 1.53936C-0.0613366 1.22487 -0.103828 0.672482 0.210666 0.305573C0.52516 -0.0613366 1.07755 -0.103828 1.44446 0.210666L7 4.97256L12.5555 0.210666Z"
      fill={props.color || "white"}
    />
  </svg>
);

export default ChevronDown;
