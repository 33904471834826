/** @jsx jsx */
import React from "react";
import FullScreenOverlay from "./FullScreenOverlay";
import Panel from "components/atoms/Panel";
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";
import BodyText from "components/atoms/BodyText";
import { css, jsx } from "@emotion/core";

const ModalForm: React.FC<{
  open: boolean;
  closeHandler: () => void;
  title: string;
  submitText: string;
  closeText?: string;
  onSubmit: () => void;
  width?: string;
  typeSubmit?: string;
  note?: string;
  contentWidth?: string;
}> = ({
  children,
  open,
  closeHandler,
  title,
  submitText,
  onSubmit,
  closeText = "キャンセル",
  note = "",
  width,
  typeSubmit = "confirm",
  contentWidth = "100%",
}) => (
  <FullScreenOverlay open={open}>
    <Panel width={width ? width : "486px"}>
      <Header title={title} />
      <Body width={contentWidth}>
        <div css={css({ width: "100%", textAlign: "center" })}>
          <BodyText>{note}</BodyText>
        </div>
        {children}
      </Body>
      <Footer submitText={submitText} closeText={closeText} onSubmit={onSubmit} closeHandler={closeHandler} typeSubmit={typeSubmit} />
    </Panel>
  </FullScreenOverlay>
);

export default ModalForm;
