/** @jsx jsx */
import React from "react";
import { grayScale } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Approved36AgreementDomain from "../domain/approved36AgreementDomain";
import Modal from "components/molecules/Modal";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useApplyingTransferForm } from "../ApplyingTab/hook";
import ChangeLogModal from "components/molecules/ChangeLogModal";

const Agreement36Accordion: React.FC<{ list: Approved36AgreementDomain[] }> = ({ list }) => {
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLogAgreement36,
  } = useApplyingTransferForm(list);
  const columns = React.useMemo(
    () => [
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: (any: any) => "対象月",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <div>
                <div>{cell.row.original.targetDate}</div>
                <IconLabelButton
                  onClick={() => {
                    fetchChangeLogAgreement36(cell.row.original.rawData);
                    setChangeLogModalOpen(true);
                    setSelectChangelog("0");
                  }}
                  text="更新履歴"
                  textAlign="center"
                  isIcon={false}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: (any: any) => "見込み残業時間",
        id: "",
        accessor: "applicationExpectedOvertimeHours",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => {
          return <div style={{ textAlign: "center" }}>{cell.row.original.applicationExpectedOvertimeHours}時間</div>;
        },
      },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    [fetchChangeLogAgreement36, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <React.Fragment>
      <div
        css={css`
          table th:nth-of-type(1) {
            width: 140px;
          }
          table th:nth-of-type(2) {
            width: 140px;
          }
          table th:nth-of-type(3) {
            width: 140px;
          }
          table th:nth-of-type(4) {
            width: 140px;
          }
          table th:nth-of-type(5) {
            width: 100px;
          }
          table th:nth-of-type(6) {
            width: 200px;
          }
        `}
      >
        <Accordion text={`限度時間超過申請 (${list.length}件）`} callBack={() => {}}>
          <DataTable
            columns={columns}
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            data={list}
            isGlobalFilter={true}
            sortBy={[{ id: "approvalDate", desc: true }]}
          />
        </Accordion>
      </div>
      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} request="45時間超過申請" />
        </Modal>
      </div>
      {/* ./Change log  */}
    </React.Fragment>
  );
};

export default Agreement36Accordion;
