import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendAgreement36 from 'components/organismos/AttendAgreement36';

const AttendAgreement36Page: React.FC = () => (
  <SidebarTemplate
    pageTitle="GPS打刻"
  >
    <AttendAgreement36 />
  </SidebarTemplate>
);

export default AttendAgreement36Page;
