
import axios from 'axios';
import { TimeSetting } from 'domain/master/labor/timeSetting';
import ApiClient from './ApiClient';

const domainUrl = process.env.REACT_APP_API_BASE_URL;

// get Org by LocalStorage
export const getInfomationOrgLocalStorage = async (
  companyCode : string,
  specifyCode: string,
): Promise<Array<any>> => {
  const params = {
    specifyCode,
  };
  
  const response = await axios.get(`${domainUrl}/v1/whereami/${companyCode}`, {
    params,
    data: {},
  });
  return response.data;
};

// get SpecifyCode By ICCard
export const getSpecifyCodeByICCard = async (
  companyCode : string,
  codeICCard : string,
): Promise<Array<any>> => {
  const params = {
    cardId: codeICCard,
  };
  
  const response = await ApiClient.getNoAuth('', `/v1/icCard/specifyCodeByCardId/${companyCode}`, params);

  return response.data;
};

// get flags recorder by company code
export const getTimeSettingNoAuth = async (
  companyCode : string,
): Promise<TimeSetting> => {

  const response = await ApiClient.getNoAuth('', `/v1/attendSetting/${companyCode}`, {});

  return response.data;
};


// get SpecifyCode By BarcodeId
export const getSpecifyCodeByBarcodeId = async (
  companyCode : string,
  barcodeId : string,
): Promise<Array<any>> => {
  const params = {
    barcodeId,
  };
  
  const response = await ApiClient.getNoAuth('', `/v1/attendStamp/specifyCode/${companyCode}`, params);

  return response.data;
};

// get current time
export const getCurrentTimeSystem = async (
): Promise<Array<any>> => {
  const params = {
  };
  const response = await axios.get(`${domainUrl}/v1/attendStamp/currentTime`, {
    params,
    data: {},
  });
  return response.data;
};

// get history recorder
export const getHistoryRecorder = async (
  companyCode: string,
  selectedOrg: string,
): Promise<Array<any>> => {
  const params = {
    orgCode: selectedOrg,
  };
  const response = await ApiClient.getNoAuth(selectedOrg, `/v1/attendStamp/history/${companyCode}`, params);

  return response.data;
};


// Recorder by Id
// export const handleRecorderById = async (companyCode: string,
//   data: any) => {
//   const params = data;
//   const response = await ApiClient.post(`v1/attendStamp/barcode/${companyCode}`, {}, params);
//   return response.data;
// };


export const handleRecorderById = async (
  shopCode: string,
  companyCode: string,
  data: any,
) : Promise<any> => {
  const response = await ApiClient.postMutipartDataNoAuth(`/v1/attendStamp/barcode/${companyCode}`, {}, data, shopCode);
  return response.data;
};

export const handleRecorderByICCard = async (
  shopCode: string,
  companyCode: string,
  data: any,
) : Promise<any> => {
  const response = await ApiClient.postMutipartDataNoAuth(`/v1/attendStamp/iccard/${companyCode}`, {}, data, shopCode);
  return response.data;
};

export const handleConfirmRecorder = async (
  shopCode: string,
  companyCode: string,
  data: any,
  domain?: number // 0: Ecomtrue || 1: Cydas
) : Promise<any> => {
  const dataPost = {
    ...data,
    ...(domain !== undefined ? {domain} : {})
  }
  const response = await ApiClient.postMutipartDataNoAuth(`/v1/attendStamp/stamp/${companyCode}`, {}, dataPost, shopCode);
  return response.data;
};

export const handleConfirmRecorder_v1 = async (
  companyCode: string,
  data: any,
) : Promise<any> => {
  const response = await ApiClient.postMutipartData(`/v2/attendStamp/stamp/${companyCode}`, {}, data);
  return response.data;
};



export const handleSubmitFaceImage = async (shopCode: string, companyCode: string, dataPost: any): Promise<any> => {
  // const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartDataNoAuth(`/v1/attendStamp/${companyCode}`, {}, dataPost, shopCode);
  return response.data;
};


export default {
  getInfomationOrgLocalStorage,
};
