/** @jsx jsx */
import React from "react";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import Checkbox from "components/molecules/Checkbox";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { css, jsx } from "@emotion/core";
import { Row, Container, Col, useScreenClass } from "react-grid-system";
import { textFontSize, grayScale } from "components/styles";
import { useShiftPatternDetailDomainForm } from "./hooks";
import Table from "components/molecules/Table";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import IconLabelButton from "components/molecules/IconLabelButton";
import BodyText from "components/atoms/BodyText";
import SelectForm from "components/molecules/SelectForm";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const tableStyle = css({
  textAlign: "left",
});

const styles = {
  buttonDelete: css({ alignItems: "right", display: "flex" }),
  grayoutButton: css({
    opacity: "0.5",
    cursor: "not-allowed",
  }),
  isStartTimeNextDay: css({
    marginRight: "20px",
  }),
  shiftPattern: css`
    table {
      color: red;
      td,
      th {
        border: 0;
      }
      th {
        padding: 14px 0px;
        color: #333333;
        font-weight: normal;
      }
      tr td {
        padding: 0 85px 0 0;
        div {
          justify-content: flex-start !important;
        }
      }
    }
  `,
  groupDetail: css({
    padding: "20px 0",
    borderTop: `1px solid ${grayScale.gray20}`,
  }),
};

const ShiftPatternDetailsV2: React.FC<{
  shiftPatternDetailsList: any;
  attendBusinessOptions: any;
  listTimes: any;
  isCreate?: boolean;
  isDisableAll?: boolean;
  setShiftPatternDetailsList: (dataList: any) => void;
  remote?: boolean;
}> = ({
  shiftPatternDetailsList,
  setShiftPatternDetailsList,
  listTimes,
  attendBusinessOptions,
  isCreate = true,
  isDisableAll = false,
  remote,
}) => {
  const { detailList, setDetailList, convertDataOuput, isHaveBusiness } = useShiftPatternDetailDomainForm(
    listTimes,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    attendBusinessOptions,
    isCreate,
  );

  const handleAddShiftPatternDetails = () => {
    if (isDisableAll) {
      return;
    }
    let defaultBusinessId = "";
    if (attendBusinessOptions.length) {
      defaultBusinessId = attendBusinessOptions[0].value;
    }

    const lastIndex = detailList.length - 1;
    const tmpPatternDetails = [...detailList];

    tmpPatternDetails.push({
      shiftPatternDetailId: "",
      shiftPatternId: detailList[lastIndex].shiftPatternId,
      businessId: defaultBusinessId,
      startHourForm: detailList[lastIndex].endHourForm,
      startTimeForm: detailList[lastIndex].endTimeForm,
      isStartTimeNextDay: detailList[lastIndex].isEndTimeNextDay,
      endHourForm: "00",
      endTimeForm: "00",
      isEndTimeNextDay: false,
      createUser: "",
      updateUser: "",
      isRemote: false,
    });

    setDetailList(tmpPatternDetails);
    convertDataOuput(tmpPatternDetails);
  };

  /**
   * 終了時間を変更（次行があれば開始時間に反映）
   *
   */
  const handleChangeStartEndTime = (indexShiftPattern: number, nameField: string, value: any) => {
    if (isDisableAll) {
      return;
    }

    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        item[nameField] = value;
        return true;
      }
      return false;
    });
    const arrOnChange = ["endHourForm", "endTimeForm", "isEndTimeNextDay"];
    if (arrOnChange.indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
   * handleDeleteShift
   *
   */
  const handleDeleteShift = () => {
    if (isDisableAll) {
      return;
    }
    const tmpList = [...detailList];
    tmpList.splice(tmpList.length - 1, 1);
    setDetailList(tmpList);
    convertDataOuput(tmpList);
  };

  /**
   * handleChangeCheckboxNextDay
   *
   */
  const handleChangeCheckboxNextDay = (indexShiftPattern: number, nameField: string, isChange?: boolean) => {
    if (isDisableAll || isChange) return;
    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        if (item[nameField] === true) {
          item[nameField] = false;
        } else {
          item[nameField] = true;
        }
        return true;
      }
      return false;
    });
    if (["endHourForm", "endTimeForm", "isEndTimeNextDay"].indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
   * 時間を変更（リストボックスの値を変更）
   *
   */
  const onHandleHiddenButton = (indexShiftPattern: number) => {
    if (detailList.length === 1 || detailList.length !== indexShiftPattern + 1) {
      return true;
    }
    return false;
  };

  const screenClass = useScreenClass();

  return (
    <div css={styles.shiftPattern}>
      <Container fluid={true}>
        {detailList.map((shiftPattern: any, keyShiftPattern: number) => (
          <div key={keyShiftPattern} css={styles.groupDetail}>
            <BodyText>シフト時間{keyShiftPattern + 1}</BodyText>
            <Table css={{ border: "none" }}>
              <thead>
                <tr>
                  <Table.HeaderCell customStyle={tableStyle}>開始時間</Table.HeaderCell>
                  <Table.HeaderCell customStyle={tableStyle}>終了時間</Table.HeaderCell>
                  <Table.HeaderCell customStyle={tableStyle} style={{ width: "70px" }}></Table.HeaderCell>
                  <Table.HeaderCell customStyle={tableStyle} style={{ width: "125px" }}></Table.HeaderCell>
                  <Table.HeaderCell customStyle={css({ width: "30px", textAlign: "center" })}></Table.HeaderCell>
                </tr>
              </thead>
              <tbody>
                <tr key={keyShiftPattern}>
                  <Table.Cell>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Col xs={12} md={5}>
                        <VerticalLabelSelectForm
                          label=""
                          name={`startHour${keyShiftPattern}`}
                          value={String(
                            shiftPattern.startHourForm === "Invalid date" || shiftPattern.startHourForm === ""
                              ? "00"
                              : shiftPattern.startHourForm,
                          )}
                          setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "startHourForm", val)}
                          options={hourFormOptions}
                          disable={keyShiftPattern !== 0 || isDisableAll}
                          menuPlacement="bottom"
                        />
                      </Col>
                      <span style={{ padding: "0 5px" }}>:</span>
                      <Col xs={12} md={5}>
                        <VerticalLabelSelectForm
                          name={`startTime${keyShiftPattern}`}
                          value={String(
                            shiftPattern.startTimeForm === "Invalid date" || shiftPattern.startTimeForm === ""
                              ? "00"
                              : shiftPattern.startTimeForm,
                          )}
                          setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "startTimeForm", val)}
                          options={timeFormOptions}
                          disable={keyShiftPattern !== 0 || isDisableAll}
                          placeholder="分"
                          menuPlacement="bottom"
                        />
                      </Col>
                      <Col xs={12} md={1} style={{ paddingLeft: "7px" }}>
                        <Checkbox
                          id={`isStartTimeNextDay${keyShiftPattern}`}
                          name={`isStartTimeNextDay${keyShiftPattern}`}
                          label="翌日"
                          value="true"
                          checked={Boolean(shiftPattern.isStartTimeNextDay)}
                          onChange={() =>
                            handleChangeCheckboxNextDay(keyShiftPattern, "isStartTimeNextDay", keyShiftPattern !== 0)
                          }
                          readOnly={keyShiftPattern !== 0 || isDisableAll}
                        />
                      </Col>
                    </Row>
                  </Table.Cell>
                  <Table.Cell>
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Col xs={12} md={5}>
                        <VerticalLabelSelectForm
                          name={`endHour${keyShiftPattern}`}
                          value={String(
                            shiftPattern.endHourForm === "Invalid date" || shiftPattern.endHourForm === ""
                              ? "00"
                              : shiftPattern.endHourForm,
                          )}
                          setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "endHourForm", val)}
                          options={hourFormOptions}
                          disable={isDisableAll}
                          menuPlacement="bottom"
                        />
                      </Col>
                      <span style={{ padding: "0 5px" }}>:</span>
                      <Col xs={12} md={5}>
                        <VerticalLabelSelectForm
                          name={`endTime${keyShiftPattern}`}
                          value={String(
                            shiftPattern.endTimeForm === "Invalid date" || shiftPattern.endTimeForm === ""
                              ? "00"
                              : shiftPattern.endTimeForm,
                          )}
                          setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "endTimeForm", val)}
                          options={timeFormOptions}
                          disable={isDisableAll}
                          placeholder="分"
                          menuPlacement="bottom"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        style={{
                          display: "flex",
                          textAlignLast: "center",
                          paddingLeft: "7px",
                        }}
                      >
                        <Checkbox
                          id={`isEndTimeNextDay${keyShiftPattern}`}
                          name={`isEndTimeNextDay${keyShiftPattern}`}
                          label="翌日"
                          value="true"
                          checked={Boolean(shiftPattern.isEndTimeNextDay)}
                          onChange={() => handleChangeCheckboxNextDay(keyShiftPattern, "isEndTimeNextDay")}
                          readOnly={isDisableAll}
                        />
                      </Col>
                    </Row>
                  </Table.Cell>
                  <Table.Cell>
                    <Col
                      md={12}
                      xl={12}
                      lg={12}
                      style={{
                        paddingBottom: ["lg", "xl"].includes(screenClass)
                          ? ""
                          : ["md"].includes(screenClass)
                          ? ""
                          : "8%",
                      }}
                    >
                      <Checkbox
                        id={`businessId${keyShiftPattern}`}
                        name={`businessId${keyShiftPattern}`}
                        label="休憩"
                        value={sessionStorage.getItem("loginUser.breakId") || ""}
                        checked={
                          shiftPattern.businessId === sessionStorage.getItem("loginUser.breakId")
                            ? !isHaveBusiness
                            : isHaveBusiness
                        }
                        onChange={(event) => {
                          let isChecked = event.target.checked;
                          handleChangeStartEndTime(
                            keyShiftPattern,
                            "businessId",
                            isChecked ? sessionStorage.getItem("loginUser.breakId") : "",
                          );
                        }}
                      />
                    </Col>
                  </Table.Cell>
                  {remote && (
                    <Table.Cell customStyle={css({ display: "none" })}>
                      <Col
                        md={12}
                        xl={12}
                        lg={12}
                        style={{
                          paddingBottom: ["lg", "xl"].includes(screenClass)
                            ? ""
                            : ["md"].includes(screenClass)
                            ? ""
                            : "8%",
                        }}
                      >
                        <Checkbox
                          id={`isRemote${keyShiftPattern}`}
                          name={`isRemote${keyShiftPattern}`}
                          label="オフィス勤務"
                          value={""}
                          checked={!shiftPattern.isRemote}
                          onChange={(event) => {
                            let isChecked = event.target.checked;
                            handleChangeStartEndTime(keyShiftPattern, "isRemote", !isChecked);
                          }}
                        />
                      </Col>
                    </Table.Cell>
                  )}
                  <Table.Cell customStyle={css({ width: "30px", textAlign: "center" })}>
                    <Col
                      xs={1}
                      md={1}
                      xl={1}
                      lg={1}
                      style={{
                        paddingBottom: ["lg", "xl"].includes(screenClass)
                          ? ""
                          : ["md"].includes(screenClass)
                          ? ""
                          : "8%",
                      }}
                    >
                      <div
                        css={css(
                          styles.buttonDelete,
                          onHandleHiddenButton(keyShiftPattern) ? styles.grayoutButton : {},
                        )}
                      >
                        <ClickableIconButton
                          onClick={() => (onHandleHiddenButton(keyShiftPattern) ? () => {} : handleDeleteShift())}
                        >
                          <Icon type="closeCircle" color={textFontSize.re} />
                        </ClickableIconButton>
                      </div>
                    </Col>
                  </Table.Cell>
                </tr>
              </tbody>
            </Table>
          </div>
        ))}

        <Row>
          <Col xs={12} md={12}>
            <FormSubmitArea
              customStyle={css({
                borderTop: `1px solid ${grayScale.gray20}`,
                paddingTop: "20px",
              })}
            >
              <IconLabelButton
                iconType="clone"
                onClick={handleAddShiftPatternDetails}
                text="その他のシフト時間を追加する"
                width="auto"
              />
            </FormSubmitArea>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShiftPatternDetailsV2;
