/** @jsx jsx */
import React, { useRef, useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import { utilityColor } from "components/styles";
import { useHistory } from "react-router-dom";
import ContextMenu from "components/molecules/ContextMenu";
import getContextMenuButtons from "components/molecules/ContextMenu/getContextMenuButtons";
import Icon from "components/atoms/Icon";
import { Popover } from "components/atoms/Popover";

const tableStyle = css({
  width: "100%",
  overflow: "hidden",
});

const theadStyle = css({
  display: "table",
  width: "100%",
  tableLayout: "fixed",
});

const tbodyStyle = css({
  display: "block",
  // maxHeight: "calc(100dvh - 315px - 65px - 40px)",
  // overflowY: "auto",
});

const trStyle = css({
  display: "table",
  width: "100%",
  tableLayout: "fixed",
  height: "3rem",
});

const thStyle = css({
  padding: "1rem",
  height: "40px",
  borderCollapse: "collapse",
});

const tdStyle = css({
  padding: "1rem",
  borderCollapse: "collapse",
});

const AttendStampMobileTable: React.FC<{
  eventScroll: any;
  business: Array<any>;
  term: Array<any>;
  targetDateFrom: Date;
  targetDateTo: Date;
  selectOrgCode: String;
  attendFlag: String;
  stampItems: any;
  emp: any;
  staffCode: any;
  staffList: any;
  remote: boolean;
  viewPeriod: string;
  isConfirmError: boolean;
  editable: number;
  dispStampTimeOrg: number;
  allowanceApplicationStatus: number;
}> = ({
  targetDateFrom,
  targetDateTo,
  selectOrgCode,
  stampItems,
  staffCode,
  staffList,
  editable,
  allowanceApplicationStatus,
}) => {
  const history = useHistory();
  const ref = useRef<any>(null);

  function getHolidayText(holiday: any) {
    if (
      (holiday.holidayType == 1 ||
        holiday.holidayType == 2 ||
        holiday.holidayType == 4 ||
        holiday.holidayType == 7 ||
        holiday.holidayType == 8 ||
        holiday.holidayType == 9) &&
      holiday.holidayDigestiveUnit == 0
    ) {
      return "";
    }

    if (
      (holiday.holidayType == 1 ||
        holiday.holidayType == 2 ||
        holiday.holidayType == 4 ||
        holiday.holidayType == 7 ||
        holiday.holidayType == 8 ||
        holiday.holidayType == 9) &&
      holiday.holidayDigestiveUnit == 3
    ) {
      return " (午前)";
    }

    if (
      (holiday.holidayType == 1 ||
        holiday.holidayType == 2 ||
        holiday.holidayType == 4 ||
        holiday.holidayType == 7 ||
        holiday.holidayType == 8 ||
        holiday.holidayType == 9) &&
      holiday.holidayDigestiveUnit == 4
    ) {
      return " (午後)";
    }

    if (
      (holiday.holidayType == 1 || holiday.holidayType == 7 || holiday.holidayType == 8) &&
      holiday.holidayDigestiveUnit == 2
    ) {
      return " (時間)";
    }

    return "";
  }

  function getStartTimeStyle(alertStatus: any, applyStatus: any, hasDispData: any, isOfficeWork?: boolean) {
    if (!hasDispData) {
      return {};
    }

    if (!isOfficeWork) {
      return { backgroundColor: "#ff9494a3" };
    }

    // エラー
    if (alertStatus == "2") {
      return { backgroundColor: "#fdefef" };
    }

    // 申請ステータスをみない
    if (applyStatus == -1) {
      // 申請中
    } else if (applyStatus == 0) {
      return { backgroundColor: "#e6fff7" };
    } else if (applyStatus == 1) {
      // 承認
      return { backgroundColor: "#f2f8fc" };
    }
    // アラート
    if (alertStatus == "1") {
      return { backgroundColor: "#ffffe6" };
    }

    return {};
  }

  function getEndTimeStyleDisplayable(
    alertStatus: any,
    applyStatus: any,
    hasDispData: any,
    targetErrorFlg: any,
    modifierDeleted: any,
    isOfficeWork?: boolean,
  ) {
    // 削除申請時は何も表示しない
    if (modifierDeleted) {
      return {};
    }

    if (!isOfficeWork) {
      return { backgroundColor: "#ff9494a3" };
    }

    // データの有無にかかわらず表示可能なエラー
    if (alertStatus == "2" && targetErrorFlg) {
      return { backgroundColor: "#fdefef" };
    }
    if (hasDispData) {
      // エラー
      if (alertStatus == "2") {
        return { backgroundColor: "#fdefef" };
      }
      // 申請中
      if (applyStatus == 0) {
        return { backgroundColor: "#e6fff7" };
      }
      if (applyStatus == 1) {
        // 承認
        return { backgroundColor: "#f2f8fc" };
      }
      // アラート
      if (alertStatus == "1") {
        return { backgroundColor: "#ffffe6" };
      }
    }
    return {};
  }

  function getStyleWeekDayColor(color: string) {
    if (color === "white") {
      return {};
    }

    if (color === "rgba(255,182,193,0.25)") {
      return { color: "#ED5D5D" };
    }

    if (color === "rgba(173,216,230,0.25)") {
      return { color: "#3aaee6" };
    }

    return {};
  }

  function convertDateFormat(inputDate: string): string {
    // 正規表現を使って日付と曜日を抽出
    const datePattern = /(\d+)月(\d+)日\((.+)\)/;
    const match = inputDate.match(datePattern);

    if (!match) {
      throw new Error("Invalid date format");
    }

    // 抽出した値を変数に格納
    const [, month, day, dayOfWeek] = match;

    // 新しい形式で日付を組み立て
    return `${month}/${day}(${dayOfWeek})`;
  }

  const staff = staffList.filter((staff: any) => staff.value === staffCode);
  const dispStaffName = staff[0] ? staff[0].label : "";

  const rowEmpty = () => {
    const cells = [];
    const length = document.getElementById("top-right-time")?.getElementsByTagName("th").length;
    if (length) {
      for (let i = 0; i < length; i++) {
        cells.push(<td className="head-line1-height"></td>);
      }
    }
    return cells;
  };

  return (
    <div ref={ref} className="wrap-attend-stamp-list">
      <table className="table-condensed nowrap" css={tableStyle}>
        <thead css={theadStyle}>
          <tr>
            <th css={thStyle} className="border-left-none" style={{ width: 40 }}>
              申請
            </th>
            <th css={thStyle} style={{ width: 70 }}>
              日付
            </th>
            <th css={thStyle} style={{ width: "auto" }}>
              予定 | 休暇
            </th>
            <th css={thStyle} style={{ width: 50 }}>
              出勤
            </th>
            <th css={thStyle} className="border-right-none" style={{ width: 50 }}>
              退勤
            </th>
          </tr>
        </thead>
        <tbody css={tbodyStyle}>
          {stampItems?.map((item: any, index: any) => {
            let workSystem = 0;
            let useAutoRess = 0;

            if (item.stampEmployment) {
              workSystem = item.stampEmployment.workSystem;
              useAutoRess = item.stampEmployment.useAutoRess;
            }
            let contextMenuButtons = getContextMenuButtons(
              history,
              item.modifierApplicationId,
              item.orgCode,
              staffCode,
              "",
              item.modifierApplicationStatus,
              item.holidayApplicationStatus,
              item.allowanceApplicationStatus,
              item.overworkApplicationStatus,
              item.holidayworkApplicationStatus,
              item.transferApplicationStatus,
              item.attendanceOrgFix,
              item.stampEmployment?.useOverTimeApplication,
              item.stampEmployment?.necessaryHolidayWorkApplication,
              item.stampEmployment?.useAllowanceApplication,
              item.stampEmployment?.useTransferApplication,
              item.achievementId,
              item.targetDate,
              item.stampVersion,
              dispStaffName,
              targetDateFrom,
              targetDateTo,
              String(selectOrgCode),
              staffCode,
              item.belongOrgFix,
              item.applying,
              item.shiftTime,
              null,
              null,
              item.holidayId,
              item.holidayWorkFlg,
              workSystem,
              useAutoRess,
              item.shiftId,
              item.shiftHolidayId,
              index,
              item.overtimeApplicationOrgCode,
              item.holidayWorkApplicationOrgCode,
              "",
              "",
              false,
              Boolean(item?.stampEmployment?.useStockPaidApp),
              item?.stockModiferAppId,
              item?.stockPaidHolidayApplicationStatus,
            );

            let contextMenuAction: any;
            if (item.stampEmployment?.workSystem !== 1) {
              if (item.applying != 0 && item.employmentId != null) {
                contextMenuAction = (
                  <ContextMenu
                    text=""
                    isButtonText={false}
                    buttons={contextMenuButtons}
                    disabled={item.attendanceOrgFix == "1" || item.belongOrgFix == "1" || item.isConfirmError}
                  />
                );
              } else if (item.applying == 0 && item.employmentId != null) {
                contextMenuAction = (
                  <ContextMenu isButtonText={false} action={3} text="" buttons={contextMenuButtons} />
                );
              }
            } else {
              if (item.workPlanApplicationStatus != 0) {
                if (item.applying == 0 && item.employmentId != null) {
                  contextMenuAction = (
                    <ContextMenu isButtonText={false} action={3} text="" buttons={contextMenuButtons} />
                  );
                } else {
                  contextMenuAction = (
                    <ContextMenu
                      text=""
                      buttons={contextMenuButtons}
                      disabled={item.attendanceOrgFix == "1" || item.belongOrgFix == "1" || item.isConfirmError}
                    />
                  );
                }
              } else {
                if (item.workPlanApplicationStatus == 0 || item.applying === 0) {
                  contextMenuAction = (
                    <ContextMenu isButtonText={false} action={3} text="" buttons={contextMenuButtons} />
                  );
                }
              }
            }

            let colorBackgroundItem: any;

            if (item.stampEmployment?.workSystem === 1) {
              if (item.workPlanApplicationStatus != 0) {
                if (item.workPlanApplicationStatus === 1) {
                  const backgroundApproved = css({
                    backgroundColor: "#f2f8fc",
                  });
                  colorBackgroundItem = backgroundApproved;
                } else {
                  const backgroundNormal = css({});
                  colorBackgroundItem = backgroundNormal;
                }
              } else {
                if (item.workPlanApplicationStatus == 0) {
                  if (item.workPlanModifiyStatus === 1) {
                    const backgroundApplyingDeleted = css({
                      backgroundColor: "#d9d9d9",
                    });

                    colorBackgroundItem = backgroundApplyingDeleted;
                  } else {
                    const backgroundApplying = css({
                      backgroundColor: "#e6fff7",
                    });
                    colorBackgroundItem = backgroundApplying;
                  }
                }
              }
            }

            return (
              <tr key={"_row_" + index} css={trStyle}>
                <td css={tdStyle} className="head-line1-height border-left-none" style={{ width: 40 }}>
                  {editable === 1 && contextMenuAction}
                </td>
                <td css={tdStyle} className="head-line1-height" style={{ width: 70 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <span
                      style={{
                        ...getStyleWeekDayColor(item.weekdayColor),
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        wordBreak: "break-all",
                      }}
                    >
                      {convertDateFormat(item.day)}
                    </span>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 2 }}>
                      {item.alert == "1" &&
                        !item.modifierDeleted &&
                        ((item.alertMsg != "" && item.holidayId && item.achievementId) ||
                          (item.alertMsg != "" && item.achievementId) ||
                          item.errorStampCheckinCheckout) && (
                          <React.Fragment>
                            <Popover
                              trigger={
                                <div>
                                  <Icon type="warning" color={utilityColor.yellow} />
                                </div>
                              }
                            >
                              <div
                                style={{
                                  color: "#FF7373",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "18px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {item.alertMsg.replace("</br>", "\n") || ""}
                              </div>
                            </Popover>
                          </React.Fragment>
                        )}

                      {item.alert == "2" &&
                        !item.modifierDeleted &&
                        ((item.alertMsg != "" && item.holidayId && item.achievementId) ||
                          (item.alertMsg != "" && item.achievementId) ||
                          item.errorStampCheckinCheckout) && (
                          <React.Fragment>
                            <Popover
                              trigger={
                                <div>
                                  <Icon type="warning" color={utilityColor.error} />
                                </div>
                              }
                            >
                              <div
                                style={{
                                  color: "#FF7373",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "18px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {item.alertMsg.replace("</br>", "\n") || ""}
                              </div>
                            </Popover>
                          </React.Fragment>
                        )}

                      {allowanceApplicationStatus === 1 && (item.allowanceDataList?.length ?? 0) > 0 && (
                        <React.Fragment>
                          <Popover
                            trigger={
                              <div>
                                <Icon type="info" color={utilityColor.transparent} />
                              </div>
                            }
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "18px",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {item.allowanceDataList?.map((item: any) => (
                                <div
                                  key={item.id}
                                >{`${item.allowanceName}： ${item.allowanceValue}${item.dispUnit} `}</div>
                              ))}
                            </div>
                          </Popover>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </td>

                {item.employmentId ? (
                  <React.Fragment>
                    {/* <!--勤務予定 | 休暇 --> */}
                    <td
                      className="head-line1-height"
                      css={{ ...tdStyle, ...(item.shiftTime ? colorBackgroundItem : {}) }}
                      style={{ width: "auto" }}
                    >
                      {item.holidayApplicationTime ? (
                        <Popover
                          trigger={
                            <div
                              style={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                wordBreak: "break-all",
                                textDecoration: "underline",
                              }}
                            >
                              {((item.holidayName ?? "") + getHolidayText(item) ?? "") || item.shiftTime}
                            </div>
                          }
                        >
                          <div
                            style={{
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item.holidayApplicationTime}
                          </div>
                        </Popover>
                      ) : (
                        <span
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            wordBreak: "break-all",
                          }}
                        >
                          {((item.holidayName ?? "") + getHolidayText(item) ?? "") || item.shiftTime}
                        </span>
                      )}

                      {/* NOTE: 実績またはシフトがある かつ 組織が異なる場合表示する */}
                      {/* NOTE: 判定用にorgName !== null を用いる */}
                      {item.orgName !== null && selectOrgCode !== item.orgCode && (
                        <Popover
                          trigger={
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textDecoration: "underline",
                              }}
                            >
                              {`（${item.orgName}）`}
                            </div>
                          }
                        >
                          <div
                            style={{
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item.orgName}
                          </div>
                        </Popover>
                      )}
                    </td>

                    {/* <!--出勤 --> */}
                    <td
                      css={tdStyle}
                      className="head-line1-height"
                      style={{
                        width: 50,
                        ...getStartTimeStyle(
                          item.startTimeAlert,
                          item.modifierApplicationStatus,
                          !item.modifierDeleted && item.startTime,
                          item.startTimeIsOfficeWork,
                        ),
                      }}
                    >
                      {!item.modifierDeleted && item.holidayDigestiveUnit != 1 && item.holidayDigestiveUnit != 2 ? (
                        <span
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.startTime}
                        </span>
                      ) : null}
                    </td>

                    {/* <!--退勤 --> */}
                    <td
                      css={tdStyle}
                      className="head-line1-height border-right-none"
                      style={{
                        width: 50,
                        ...getEndTimeStyleDisplayable(
                          item.endTimeAlert,
                          item.modifierApplicationStatus,
                          !item.modifierDeleted && item.endTime,
                          item.errorEndStampFlg,
                          item.modifierDeleted,
                          item.endTimeIsOfficeWork,
                        ),
                      }}
                    >
                      {!item.modifierDeleted && item.holidayDigestiveUnit != 1 && item.holidayDigestiveUnit != 2 ? (
                        <span
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.endTime}
                        </span>
                      ) : null}
                    </td>
                  </React.Fragment>
                ) : (
                  <React.Fragment>{rowEmpty()}</React.Fragment>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default AttendStampMobileTable;
