import React from "react";

import SidebarTemplate from "components/templates/SidebarTemplate";
import SpecialSalaryPatternInputForm from "components/organismos/master/labor/SpecialSalaryPatternInputForm";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from "components/atoms/TabControl";
import FormTitle from "components/atoms/Form/FormTitle";

type SelectTab = "add";
const SelectTab: { [key in SelectTab]: SelectTab } = {
  add: "add",
};

const SpecialSalaryPatternAddPage: React.FC = () => {
  const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.add);

  return (
    <SidebarTemplate>
      <FormContents>
        <FormTitle bold={true} title="特賃日設定作成" />
        {/* <div style={{ paddingLeft: "35px" }}>
          <TabControl
            items={[
              {
                value: SelectTab.add,
                display: "特賃日情報",
              },
            ]}
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={true}
            type={1}
          />
        </div> */}
        <SpecialSalaryPatternInputForm />
      </FormContents>
    </SidebarTemplate>
  );
};

export default SpecialSalaryPatternAddPage;
