/** @jsx jsx */
import React, { useState, useMemo, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import { useOrgTreesOptionsShopV1, useOrgTreesOptionsAllNoRole } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import SelectForm from 'components/molecules/SelectForm';
import StampHistoryDomain from 'domain/master/atendenceManagement/stampHistory';
import useToastNotification from 'hooks/useToastNotification';
import { getSearchDateClosing } from 'api/stampHistory';
import { grayScale } from 'components/styles';
import BlockUI from 'components/molecules/BlockUi';
import { css, jsx } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import useStampHistory from './hooks';
import FormPadding from 'components/atoms/Form/FormPadding';
import CalendarForm from 'components/molecules/CalendarForm';
import Icon from 'components/atoms/Icon';
import ImageModel from 'components/organismos/ImageModel';
import FormTitle from 'components/atoms/Form/FormTitle';
import BodyText from "components/atoms/BodyText";
import TextTooltip from 'components/molecules/TextTooltip';
import CalendarRangeV2 from 'components/molecules/CalendarV1/CalendarRangeV2';
import { functionCode } from 'const';
import Checkbox from 'components/molecules/Checkbox';

const isIpad = window.matchMedia('(max-width: 1024px)').matches;
const StampHistoryLayout = () => {
  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth();
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(y, m, 1));
  const [targetDateTo, setTargetDateTo] = useState(new Date(y, m + 1, 0));

  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getSearchDateClosing(companyCode).then((data) => {
      const dateFrom = new Date();
      setTargetDateFrom(new Date(data[0]));
      setTargetDateTo(new Date(data[1]));
      if (data[0] == null || data[1] == null) {
        setTargetDateFrom(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1));
        setTargetDateTo(new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0));
      }
    });
  }, []);

  const { errorNotification } = useToastNotification();

  // const orgTreesOptions = useOrgTreesOptionsAll(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'), undefined, sessionStorage.getItem('loginUser.site') === '2',true)
  const orgTreesOptionsNoRole = useOrgTreesOptionsAllNoRole(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'), undefined, sessionStorage.getItem('loginUser.site') === '2',true);
  const orgTreesOptions = useOrgTreesOptionsShopV1(true, functionCode.stampHistory, moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'));

  const [orgCode, setorgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');

  const { stampHistory, blocking, underFlag, setUnderFlag } = useStampHistory(orgCode || '', moment(targetDateFrom).format('YYYY年MM月DD日'),
    moment(targetDateTo).format('YYYY年MM月DD日'));

  // 対象日の範囲が１年を超えていた場合、エラーとする
  const [stampHistoryNull, setstampHistoryNull] = useState(true);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  useEffect(() => {
    const startDay = new Date(Date.parse(moment(targetDateFrom, 'YYYY年MM月DD日').format('YYYY/MM/DD')));
    const endDay = new Date(moment(targetDateTo, 'YYYY年MM月DD日').format('YYYY/MM/DD'));
    const startDayNextYear = new Date(
      startDay.getFullYear() + 1, startDay.getMonth(), startDay.getDate(),
    );
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      errorNotification('期間の範囲は１年以内で入力してください');
      setstampHistoryNull(false);
    } else {
      setstampHistoryNull(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);


  const columns: Array<Column<StampHistoryDomain>> = useMemo(() => [
    {
      Header: '氏名',
      accessor: 'staffName',
      id: 'imageURL',
      overFlow: true,
      Cell: (cell: { row: { isExpanded: boolean, original: any }, page: [] }) => {
        const [imageOpen, setImageOpen] = useState(false);

        const { staffCode, staffName, stampTime, stampName } = cell.row.original;

        const imageURL = cell.row.original.photoFullpath;

        return (
          <React.Fragment>
            <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
              {imageURL !== null && (
                <div>
                  <img 
                    src={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                    alt={imageURL}
                    width={55}
                    height={55}
                    onClick={() => setImageOpen(true)}
                    style={{
                      cursor: 'pointer',
                      borderRadius: '50%',
                      marginRight: '15px'
                    }} />
                  <ImageModel
                    open={imageOpen}
                    closeHandler={() => { setImageOpen(false) }}
                    imageURL={cell.row.original.stampMode === 5 ? "data:image/png;base64," + imageURL : imageURL}
                    descript={staffCode + '/' + staffName + '/' + stampName + '/' + moment(stampTime).format('YYYY/MM/DD HH:mm')}
                  />
                </div>
              )}
              <TextTooltip text={staffName} textDis={(
                <div className="ellipsis headline-cell-width-120"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: `${imageURL !== null ? '110px' : '180px'}`
                  }}>
                  <span>{staffName}</span>
                </div>
              )} />  
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
      overFlow: true
    },
    {
      Header: '組織コード',
      accessor: 'orgCode',
      overFlow: true
    },
    {
      Header: '組織名',
      accessor: 'orgName',
      overFlow: true
    },
    {
      Header: '打刻方法',
      accessor: 'stampModeName',
      id: 'stampModeName',
      Cell: (cell: { row: { isExpanded: boolean, original: any }, page: [] }) => {
        return (
          <React.Fragment>
            <div style={{ width: '100%' }}>{cell.row.original.stampModeName}</div>
            {(
              cell.row.original.errorMessage !== null &&
              cell.row.original.errorMessage !== ""
            ) && (
                <BodyText customStyle={css({ fontSize: '13px', color: '#333333' })}>{cell.row.original.cardId}</BodyText>
              )}
          </React.Fragment>
        );
      },
    },
    // {
    //   Header: '撮影写真',
    //   id: 'imageURL',
    //   accessor: 'orgCode',
    //   Cell: (cell:{ row:{isExpanded: boolean, original: any }, page:[]}) => {
    //     const [imageOPen, setimageOpen] = useState(false);

    //     const { staffCode, staffName, stampTime, stampName }  = cell.row.original;

    //     const imageURL = cell.row.original.photoFullpath;

    //     return (
    //       <React.Fragment>
    //         { imageURL !== null
    //         && (
    //         <div style={{ textAlign: 'center' }}>
    //             <img src={imageURL} alt={imageURL} width={40} height={30} onClick={() => setimageOpen(true)} style={{cursor: 'pointer'}}/>
    //           <ImageModel
    //             open={imageOPen}
    //             closeHandler={() => {setimageOpen(false)}}
    //             imageURL={imageURL}
    //             descript={staffCode+'/'+staffName+'/'+stampName+'/'+ moment(stampTime).format('YYYY/MM/DD HH:mm')}
    //           />
    //         </div>
    //         )}
    //       </React.Fragment>
    //     );
    //   },
    // },
    {
      Header: '打刻種別',
      accessor: 'stampName',
      Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
        const { errorMessage } = cell.row.original;
        const { stampName, isRemote } = cell.row.original.rawData;
        return (
          <div>
            <div>
              {(errorMessage === null || errorMessage === '') && (
                <div>
                  {stampName === '出勤' ? (isRemote ? stampName + `${companyCode === 'HPo6wkyuze' ? ' (在宅)' : ' (テレワーク)'}` : stampName + ' (オフィス)') : stampName}
                </div>
              )}
            </div>
            <div>
              {errorMessage !== null && errorMessage !== '' && (
                <div data-tip={errorMessage}>
                  <Icon type="error" color="" margin="0 6px 0 0" />
                  {stampName}
                  <ReactTooltip html={true} />
                </div>
              )}
            </div>


          </div>

        );
      },
    },
    {
      Header: '打刻時刻',
      accessor: 'stampTimeStr',
    },
  ], []);

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents customStyle={{ marginBottom: '50px' }}>
          <FormTitle title="打刻実績" bold={true} customStyle={css`margin: 16px 35px;`} />
          <FormPadding customStyle={{ padding: '0 0 0 35px' }}>
            <FormField>
              <FlexBox>
                <FlexBoxItem width="100px">
                  <FormLabel
                    label="期間"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <CalendarRangeV2 
                    width="auto"
                    startDate={targetDateFrom}
                    onChangeStart={setTargetDateFrom}
                    endDate={targetDateTo}
                    onChangeEnd={(date) => {
                      if(date){
                        setTargetDateTo(date)
                      }
                    }}
                  />
                </FlexBoxItem>
                {/* <FlexBoxItem width="160px">
                  <CalendarForm
                    required={true}
                    label=""
                    date={targetDateFrom}
                    dateFormat="YYYY年MM月DD日"
                    changeDate={(date: Date) => {
                      setTargetDateFrom(date);
                    }}
                  />
                </FlexBoxItem>
                <div css={css({ width: '50px', textAlign: 'center', color: grayScale.gray20 })}>
                  ～
              </div>
                <FlexBoxItem width="160px">
                  <CalendarForm
                    required={true}
                    label=""
                    date={targetDateTo}
                    dateFormat="YYYY年MM月DD日"
                    changeDate={(date: Date) => {
                      setTargetDateTo(date);
                    }}
                  />
                </FlexBoxItem> */}
              </FlexBox>
            </FormField>

            <FlexBox>
              <SelectForm
                label="組織"
                name="orgCode"
                value={String(orgCode)}
                setValue={(v) => {
                  setorgCode(v);
                }}
                options={orgTreesOptions}
                description=""
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
              <Checkbox
                id={'underFlag'}
                name={'underFlag'}
                label={"配下指定"}
                value={`underFlag`}
                checked={Boolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1)
                }}
              />
            </FlexBox>
          </FormPadding>
          <div
            css={css`
              margin-top: 20px;
              table tbody td, table thead th{
                text-align: left;
              }
              table thead th{
                font-weight: bold;
              }
              table th:nth-of-type(1) {
                width: 110px;
              }
              table th:nth-of-type(2) {
                width: 90px;
              }
              table th:nth-of-type(3) {
                width: 90px;
              }
              table th:nth-of-type(4) {
                width: 80px;
              }
              table th:nth-of-type(5) {
                width: 80px;
              }
              table th:nth-of-type(6) {
                width: 65px;
              }
              table th:nth-of-type(7) {
                width: 80px;
              }
            `}
          >
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                className="table-no-border-left-right table-stamp-history"
                columns={columns}
                data={stampHistoryNull ? stampHistory : []}
                isGlobalFilter={true}
                minWidth="824px"
                globalSearchStyle={css({ marginLeft: '35px' })}
              />
            </div>
          </div>

        </FormContents>
      </div>
    </BlockUI>
  );
};

export default StampHistoryLayout;
