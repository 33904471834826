/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import useMonthlyAgreement36Form, { MonthlyInfoAgreement36Data } from './hooks';
import CalendarForm from 'components/molecules/CalendarForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import FormPadding from 'components/atoms/Form/FormPadding';
import FormLabel from 'components/atoms/Form/FormLabel';
import productColor from 'components/styles';
import SelectForm, { OptionType } from "components/molecules/SelectForm";
import Checkbox from 'components/molecules/Checkbox';
import { Column } from 'react-table';
import DataTable from 'components/organismos/DataTable/DataTable';
import TextTooltip from 'components/molecules/TextTooltip';
import Modal from 'components/molecules/Modal';
import BodyText from 'components/atoms/BodyText';
import ModalDetailStaff from './../ModalDetailStaff'
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import moment from 'moment';
import CalendarMonth from 'components/molecules/CalendarV1/CalendarMonth';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import Item from 'components/molecules/CalendarTimeline/Item';

const MonthlyAgreement36Form: React.FC = () => {

  const styles = {
    detailText: css({
      color: productColor.primary,
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer'
      }
    }),
    tableModal: css({
      maxWidth: '1000px'
    }),
    labelTable: css({
      margin: '20px 0'
    }),
    labelRadio: css({
      marginBottom: '10px'
    })

  }

  const getOverTimeFlag = (flag: number) => {
    let tmpStyle = {
      margin: '-14px -10px',
      padding: '14px'
    }
    switch (flag) {
      case 1:
        return { ...tmpStyle, backgroundColor: productColor.copyAction }
      case 2:
        return { ...tmpStyle, backgroundColor: productColor.secondary }
      default:
        return tmpStyle
    }
  }

  const getOverHolidayTimeFlag = (flag: number) => {
    let tmpStyle = {
      margin: '-14px -10px',
      padding: '14px'
    }
    switch (flag) {
      case 1:
        return { ...tmpStyle, backgroundColor: productColor.copyAction }
      case 2:
        return { ...tmpStyle, backgroundColor: productColor.secondary }
      default:
        return tmpStyle
    }
  }

  const getStyleLabel = (index: number, maxLength: number) => {
    switch (index) {
      case maxLength - 2:
        return css({ color: productColor.copyAction })
      case maxLength - 1:
        return css({ color: productColor.secondary })
      default:
        return css({})
    }
  }


  const {
    isLoading,
    applyDate,
    setApplyDate,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    monthlyInfoData,
    isOpenModal,
    setIsOpenModal,
    handleOpenDetail,
    staffDetail,
    detailRole,
    underFlag,
    setUnderFlag,
    agreementsId,
    setAgreementsId,
    agreementsOptions,
    overTime,
    setOverTime, 
    overTimeOptions,
    overHolidayTime,
    setOverHolidayTime,
    overHolidayTimeOptions,
  } = useMonthlyAgreement36Form();


  const columns: Array<Column<MonthlyInfoAgreement36Data>> = React.useMemo(() => [
    {
      Header: "スタッフ名",
      accessor: 'x',
      disableSortBy: true,
      id: 'actionButton',
      Cell: (cell: { row: { isExpanded: boolean, original: MonthlyInfoAgreement36Data } }) => {
        // eslint-disable-next-line
        return (
          <FlexBox>
            <TextTooltip text={cell.row.original.staffName} textDis={(
              <div className="ellipsis headline-cell-width-120"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '140px'
                }}>
                <span>{cell.row.original.staffName}</span>
              </div>
            )} />
            <BodyText customStyle={styles.detailText} onClick={() => handleOpenDetail(cell.row.original.staffCode, cell.row.original.agreementsId)}>
              詳細
            </BodyText>
          </FlexBox>
        );
      },
    },
    {
      Header: '組織名',
      accessor: 'orgName',
    },
    {
      Header: '出勤予定残日数',
      accessor: 'remainDays',
      Cell: (cell: { row: { isExpanded: boolean, original: MonthlyInfoAgreement36Data } }) => {
        return (
          `${cell.row.original.remainDays ? cell.row.original.remainDays + '日' : ''}`
        )
      },
    },
    {
      Header: '法定外残業時間',
      accessor: 'overTimeHour',
      Cell: (cell: { row: { isExpanded: boolean, original: MonthlyInfoAgreement36Data } }) => {
        return (
          <div css={css(getOverTimeFlag(cell.row.original.overTimeFlag))}>
            {`${cell.row.original.overTimeHour}`}
          </div>
        )
      },
    },
    {
      Header: '休日出勤労働時間',
      accessor: 'holidayTimeHour',
      Cell: (cell: { row: { isExpanded: boolean, original: MonthlyInfoAgreement36Data } }) => {
        return `${cell.row.original.holidayTimeHour}`
      },
    },
    {
      Header: '合計',
      accessor: 'overHolidayTimeHour',
      Cell: (cell: { row: { isExpanded: boolean, original: MonthlyInfoAgreement36Data } }) => {
        return (
          <div css={css(getOverHolidayTimeFlag(cell.row.original.overHolidayTimeFlag))}>
            {`${cell.row.original.overHolidayTimeHour}`}
          </div>
        )
      },
    },
  ], [handleOpenDetail]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents customStyle={css({
        paddingBottom: '35px'
      })}>
        <FormTitle bold={true} title="月次" />
        <FormPadding customStyle={css({ marginBottom: '20px' })}>
          <FlexBox customStyle={css({ margin: '10px 0' })}>
            <FlexBoxItem>
              <FormLabel label="営業月" customStyle={css({ width: '100px', margin: '0' })} />
            </FlexBoxItem>
            <FlexBoxItem>
              <CalendarMonth 
              openActionChevron={true}
              date={applyDate}
              setDate={setApplyDate}
              />
            </FlexBoxItem>
            {/* <FlexBoxItem>
              <ClickableIconButton onClick={() => {
                setApplyDate(new Date(moment(applyDate).subtract(1, 'month').format('YYYY/MM/01')));
              }} additionalCss={css({ marginLeft: '-35px' })}>
                <Icon type="chevronLeft" color="" size="24px" />
              </ClickableIconButton>
            </FlexBoxItem>
            <FlexBoxItem width="160px">
              <CalendarForm
                required={true}
                label=""
                date={applyDate}
                dateFormat="YYYY/MM"
                changeDate={(date: Date) => {
                  if (date.getMonth() !== applyDate.getMonth() || date.getFullYear() !== applyDate.getFullYear()) {
                    setApplyDate(date)
                  }
                }} />
            </FlexBoxItem>
            <FlexBoxItem>
              <ClickableIconButton onClick={() => {
                setApplyDate(new Date(moment(applyDate).add(1, 'month').format('YYYY/MM/01')));
              }}>
                <Icon type="chevronRight" color="" size="24px" />
              </ClickableIconButton>
            </FlexBoxItem> */}
          </FlexBox>
          {detailRole.accessRange !== 0 && (
            <FlexBox customStyle={css({ margin: '10px 0px' })}>
                <SelectForm
                  label="36協定"
                  width="900px"
                  widthLabel="100px"
                  name="agreementsId"
                  value={String(agreementsId)}
                  setValue={(v) => {
                    setAgreementsId(v);
                  }}
                  options={agreementsOptions}
                />
            </FlexBox>
            )}
          {detailRole.accessRange !== 0 && (
            <FlexBox customStyle={css({ margin: '10px 0' })}>
              <SelectForm
                label="組織"
                name="orgCode"
                value={String(orgCode)}
                setValue={(v) => {
                  setOrgCode(v);
                }}
                options={orgTreesOptions}
                description=""
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
              <Checkbox
                id={'underFlag'}
                name={'underFlag'}
                label={"配下指定"}
                value={`underFlag`}
                checked={Boolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1)
                }}
              />
            </FlexBox>
          )}
          <FlexBox customStyle={css({ margin: '10px 0' })}>
            <FormLabel label="法定外残業" customStyle={css({ margin: '0', width: '120px' })} />
            <div css={styles.labelRadio}>
              <RadioSelectForm css={styles.labelRadio}
                label=""
                items={overTimeOptions}
                name="overTime"
                value={String(overTime)}
                setValue={(e) => {setOverTime(Number(e.target.value)); }}
            />
            </div>
          </FlexBox>
          <FlexBox customStyle={css({ margin: '10px 0' })}>
            <FormLabel label="法定外と休日出勤" customStyle={css({ margin: '0', width: '120px' })} />
            <div css={styles.labelRadio}>
            <RadioSelectForm
                label=""
                items={overHolidayTimeOptions}
                name="overHolidayTime"
                value={String(overHolidayTime)}
                setValue={(e) => {setOverHolidayTime(Number(e.target.value)); }}
            />
            </div>
          </FlexBox>
        </FormPadding>
        <div css={css`
          overflow-x: auto;
          white-space: nowrap;
          table th:nth-of-type(1) {
            width: 200px;
          }
          table td:nth-of-type(n+3){
            text-align: center
          }
        `}>
          <DataTable className="table-no-border-left-right"
            columns={columns}
            data={monthlyInfoData || []}
            isGlobalFilter={false}
            minWidth="632px"
          />
        </div>

      </FormContents>
      <Modal
        open={isOpenModal}
        closeHandler={() => setIsOpenModal(false)}
        title="時間外労働一覧"
        submitText=""
        closeText="閉じる"
        onSubmit={() => { }}
      >
        {!isLoading && staffDetail && staffDetail.length > 0 && (
          <ModalDetailStaff
            staffDetail={staffDetail}
          />
        )}
      </Modal>
    </BlockUI>
  );
};

export default MonthlyAgreement36Form;
