import React, { SVGProps } from 'react';
 
 const Capture: React.FC<SVGProps<SVGSVGElement>> = props => (
   <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g style={{mixBlendMode: 'soft-light'}}>
       <path fillRule="evenodd" clipRule="evenodd" d="M3 58.5V22.5C3 18.3579 6.35786 15 10.5 15H16.2889C17.7935 15 19.1985 14.248 20.0331 12.9962L22.4707 9.33975C23.8617 7.25326 26.2035 6 28.7111 6H43.2889C45.7965 6 48.1383 7.25326 49.5293 9.33975L51.9669 12.9962C52.8015 14.248 54.2065 15 55.7111 15H61.5C65.6421 15 69 18.3579 69 22.5V58.5C69 62.6421 65.6421 66 61.5 66H10.5C6.35786 66 3 62.6421 3 58.5ZM6 58.5C6 60.9853 8.01472 63 10.5 63H61.5C63.9853 63 66 60.9853 66 58.5V22.5C66 20.0147 63.9853 18 61.5 18H55.7111C53.2035 18 50.8617 16.7467 49.4707 14.6603L47.0331 11.0038C46.1985 9.75196 44.7935 9 43.2889 9H28.7111C27.2065 9 25.8015 9.75196 24.9669 11.0038L22.5293 14.6603C21.1383 16.7467 18.7965 18 16.2889 18H10.5C8.01472 18 6 20.0147 6 22.5V58.5ZM18 39C18 48.9411 26.0589 57 36 57C45.9411 57 54 48.9411 54 39C54 29.0589 45.9411 21 36 21C26.0589 21 18 29.0589 18 39ZM36 54C44.2843 54 51 47.2843 51 39C51 30.7157 44.2843 24 36 24C27.7157 24 21 30.7157 21 39C21 47.2843 27.7157 54 36 54ZM60 21H57C55.3431 21 54 22.3431 54 24V27C54 28.6569 55.3431 30 57 30H60C61.6569 30 63 28.6569 63 27V24C63 22.3431 61.6569 21 60 21ZM57 24V27H60V24H57ZM6 10.5C6 11.3284 6.67157 12 7.5 12H13.5C14.3284 12 15 11.3284 15 10.5C15 9.67157 14.3284 9 13.5 9H7.5C6.67157 9 6 9.67157 6 10.5Z" fill="white"/>
     </g>
   </svg>
 
 
 );
 
 export default Capture;