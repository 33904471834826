import { Staff } from "domain/staff";
import { AxiosResponse } from "axios";
import moment from "moment";
import { stringify } from "querystring";
import { WeekShiftPatternDataStaff } from "domain/master/general/weekShiftPatternDataStaff";
import ApiClient from "./ApiClient";

export type Error = {
  error: string;
  defaultMessage: string;
};

export type StaffHolidayType = {
  companyCode: string;
  staffHolidayId: string;
  staffCode: string;
};

export const postStaffInfo = async (inputType: number, staff: Staff) => {
  const query = {
    inputType,
    tabIndex: 0,
    staffCode: staff.id.staffCode,
  };
  const response = await post(staff, query);
  return response.data;
};

export const postAffiliateInfo = async (inputType: number, staff: Staff) => {
  const query = {
    inputType,
    tabIndex: 1,
    staffCode: staff.id.staffCode,
  };
  const response = await post(staff, query);
  return response.data;
};

export const postLongTermCareAndNurse = async (inputType: number, staff: Staff) => {
  const query = {
    inputType,
    tabIndex: 6,
    staffCode: staff.id.staffCode,
  };
  const response = await post(staff, query);
  return response.data;
};

export const postEmploymentInfo = async (inputType: number, staff: Staff) => {
  const query = {
    inputType,
    tabIndex: 2,
    staffCode: staff.id.staffCode,
  };
  const response = await post(staff, query);
  return response.data;
};

export const postSalaryInfo = async (inputType: number, staff: Staff) => {
  const query = {
    inputType,
    tabIndex: 3,
    staffCode: staff.id.staffCode,
  };
  const response = await post(staff, query);
  return response.data;
};

export const postWorkPatternInfo = async (inputType: number, staff: Staff) => {
  const query = {
    inputType,
    tabIndex: 4,
    staffCode: staff.id.staffCode,
  };
  const response = await post(staff, query);
  return response.data;
};

const post = async (staff: Staff, query: { [key: string]: string | number }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.postMutipartData(`/v1/staffs/${companyCode}`, query, staff);
  return response;
};

export const getAllAdminOrAttendanceStaff = async (): Promise<Array<Staff>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/staffs/adminOrAttendanceRole/${companyCode}`, {});
  return response.data;
};

export const getHqStaff = async (): Promise<Array<Staff>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/staffs/hqRole/${companyCode}`, {});
  return response.data;
};

export const getHolidaysOptions = async (): Promise<Array<Staff>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/holidays/attends/list/nursing/${companyCode}`, {});
  return response.data;
};

export const getListStaff = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
): Promise<Array<Staff>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
  };
  const queryStringParams = stringify(params);
  const response = await ApiClient.get(`/v1/staffs/list/${companyCode}`, {}, queryStringParams);
  return response.data;
};

export const getListStaffV1 = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  functionCode: string,
  underFlag?: number,
): Promise<Array<Staff>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgCode: orgCode === "all" ? "" : orgCode,
    executiveId,
    employmentId,
    stateStatus,
    loginStaffCode,
    functionCode,
    underFlag,
  };
  const queryStringParams = stringify(params);
  const response = await ApiClient.get(`/v2/staffs/list/${companyCode}`, {}, queryStringParams);
  return response.data;
};

export const getStaffListForOrgCode = async (orgCode: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(
    `/v1/staffs/list/${companyCode}?orgCode=${orgCode}&stateStatus=1&stateStatus=3&stateStatus=4`,
    {},
  );
  return response.data;
};

export const getStaffSelectBoxListForOrgCode = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const queryStringParams = stringify(params);
  const response = await ApiClient.get(`/v2/staffs/list/selectbox/${companyCode}`, {}, queryStringParams);
  return response.data;
};

export const getStaffSelectBoxRange = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const paramsObj = {
    ...params,
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v2/staffs/list/selectbox/${companyCode}`, {}, paramsObj);
  return response.data;
};

export const getStaffList = async (
  orgCode: string,
  targetDayFrom: string,
  targetDayTo: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(
    `/v1/staffs/list/${companyCode}?orgCode=${orgCode}&targetDayFrom=${targetDayFrom}&targetDayTo=${targetDayTo}&stateStatus=1&stateStatus=2&stateStatus=3&stateStatus=4`,
    {},
  );
  return response.data;
};

export const getLoginStaff = async () => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const response = await ApiClient.get(
    `/v1/staffs/${companyCode}`,
    {},
    {
      staffCode,
    },
  );
  return response.data;
};

export const getSpecificStaff = async (staffCode: string) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const response = await ApiClient.get(
    `/v3/staffs/${companyCode}`,
    {},
    {
      staffCode,
      loginStaffCode,
    },
  );
  return response.data;
};

export const getOrganizationDataList = async (staffCode: string) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(
    `/v2/staffs/${companyCode}`,
    {},
    {
      staffCode,
    },
  );
  return response.data;
};

/**
 * CSV Import & download
 */
export const postStaffCsvImport = async (formatType: string, uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffName = sessionStorage.getItem("loginUser.staffName") || "";
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE === "true" || false;
  const query = {
    formatType,
    createUser: staffName,
    updateUser: staffName,
    isAuthorizedFromPeople,
  };
  const response = await ApiClient.postFile(`/v1/staffs/csv/${companyCode}`, query, "uploadFile", uploadFile);
  return response;
};

export const downloadStaffCsv = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v1/staffs/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("スタッフ情報_"),
  );
};

export const downloadStaffCsvV1 = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  functionCode: string,
  underFlag?: number,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
    loginStaffCode,
    functionCode,
    underFlag,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v2/staffs/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("スタッフ情報_"),
  );
};

export const downloadStaffHolidayCsv = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v1/staffs/holiday/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("介護・看護休暇情報_"),
  );
};

export const downloadStaffHolidayCsvV1 = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  functionCode: string,
  underFlag: number,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
    loginStaffCode,
    functionCode,
    underFlag,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v2/staffs/holiday/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("介護・看護休暇情報_"),
  );
};

export const downloadStaffSalaryCsv = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  functionCode: string,
  underFlag: number,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
    loginStaffCode,
    functionCode,
    underFlag,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v2/staffs/salary/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("スタッフ人件費情報_"),
  );
};

export const downloadStaffManagerCsv = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v1/staffs/manager/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("兼務情報_"),
  );
};

export const downloadStaffManagerCsvV1 = async (
  orgCode: string,
  executiveId: string,
  employmentId: string,
  stateStatus: Array<number>,
  functionCode: string,
  underFlag?: number,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgCode,
    executiveId,
    employmentId,
    stateStatus,
    loginStaffCode,
    functionCode,
    underFlag,
  };
  const queryStringParams = stringify(params);
  await ApiClient.downloadOriginalCsv(
    `/v2/staffs/concurrent/csv/${companyCode}`,
    queryStringParams,
    getDownloadCsvFileName("兼務情報_"),
  );
};

export const deleteStaff = async (staffCode: string): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  await ApiClient.delete(`/v1/staffs/${companyCode}`, { staffCode });
};

export const deleteWeekShiftPatternStaff = async (
  staffWeekShiftId: string,
): Promise<Array<WeekShiftPatternDataStaff>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.delete(`/v1/staffs/patterns/${companyCode}`, { staffWeekShiftId });
  return response.data;
};

export const getShiftPatternStaff = async (
  orgCode: string,
  employmentId: string,
  staffCode: string,
  isEditMode: boolean,
): Promise<Staff> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    inputType: isEditMode ? 1 : 0,
    staffCode: isEditMode ? staffCode : "",
    orgCode: orgCode,
    employmentId,
  };
  const queryStringParams = stringify(params);
  const response = await ApiClient.get(`/v1/staffs/shiftpattern/${companyCode}`, {}, queryStringParams);
  return response.data;
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format("YYYYMMDDHHmmss")}.csv`;

// スタッフ情報の登録
export const postStaffPhoto = async (dataPost: any): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    dataType: 1,
  };

  const response = await ApiClient.postMutipartData(`/v1/staffs/photos/${companyCode}`, params, dataPost);
  return response.data;
};

export const getImportHistory = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/staffs/csv/import/history/${companyCode}`, {});
  return response.data;
};

export const deleteStaffHoliday = async (params: StaffHolidayType): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.delete(`/v1/staffs/holiday/${companyCode}`, params);
  return response.data;
};

export default {
  getAllAdminOrAttendanceStaff,
  getHqStaff,
};
