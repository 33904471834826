/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Select, { components, OptionTypeBase } from "react-select";
import { ValueType, MenuPlacement } from "react-select/src/types";

import { grayScale, productColor, textFontSize, textColor, utilityColor } from "components/styles";
import FormLabel from "components/atoms/Form/FormLabel";
import Icon from "components/atoms/Icon";
import VerticalLabelFormLayoutV2 from "components/atoms/Form/VerticalLabelFormLayoutV2";

export interface OptionType extends OptionTypeBase {
	value: string;
	label: string;
}

const styles = {
	container: css({
		padding: "4px 0"
	}),
	label: css({
		flexBasis: "100px"
	}),
	select: css({
		flexGrow: 4,
		fontSize: textFontSize.re
	}),
	noteText: css({
		fontSize: textFontSize.sm,
		color: textColor.subText01,
		marginLeft: "4px"
	})
};

// Convert select to text
const ghostStyle = () => css`
	div {
		&:hover,
		&:focus {
			border: 0;
		}
		svg {
			display: none;
		}
		background-color: transparent;
		color: ${grayScale.gray100};
		border: 0;
		margin: 0;
		padding: 0;
	}
`;

const selectStyles = {
	control: (oldStyles: any) => ({
		...oldStyles,
		"& > div": {
			paddingRight: 0
		},
		border: `1px solid ${grayScale.gray03}`,
		borderRadius: "2px",
		boxShadow: "none",
		"&:hover": {
			border: `1px solid ${grayScale.gray03}`
		}
	}),
	dropdownIndicator: (provided: any, state: any) => ({
		...provided,
		transform: state.selectProps.menuIsOpen && "rotate(180deg)",
		cursor: "pointer"
	}),
	valueContainer: (provided: any) => ({
		...provided,
		minHeight: "40px"
	}),
	option: (provided: any, state: any) => ({
		...provided,
		fontSize: textFontSize.re,
		backgroundColor: (() => {
			if (state.isSelected) return productColor.primary;
			if (state.isFocused) return productColor.primaryM80;
			return "";
		})(),
		color: state.isSelected ? textColor.inversed : textColor.main
	}),
	input: (oldStyles: any) => ({
		...oldStyles
	}),
	multiValue: (oldStyles: any) => ({
		...oldStyles,
		background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796",
		borderRadius: "12px"
	}),
	multiValueLabel: (oldStyles: any) => ({
		...oldStyles,
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "14px",
		display: "flex",
		alignItems: "center",
		paddingLeft: "8px",
		color: productColor.primary
	}),
	multiValueRemove: (oldStyles: any) => ({
		...oldStyles,
		lineHeight: "21px",
		paddingRight: "6px",
		":hover": {
			backgroundColor: "transparent",
			cursor: "pointer"
		}
	}),
	placeholder: (oldStyles: any) => ({
		...oldStyles,
		fontSize: "14px"
	}),
	menu: (oldStyles: any) => ({ ...oldStyles, zIndex: 9999 })
};

/**
 * 選択式フォーム
 * TODO: セレクトボックスのスタイルがデフォルトのままなので、ちゃんとカスタマイズする
 */
const VerticalLabelSelectFormV2: React.FC<{
	label?: string;
	name: string;
	value: string;
	options: Array<OptionType>;
	setValue: (val: string) => void;
	description?: string;
	note?: string;
	isMulti?: boolean;
	errorMsg?: string;
	required?: boolean;
	disable?: boolean;
	placeholder?: string;
	menuPlacement?: MenuPlacement;
	zIndex?: number;
	ghost?: boolean;
}> = ({
	label,
	name,
	value,
	options,
	setValue,
	description,
	isMulti,
	note,
	errorMsg,
	required,
	disable,
	placeholder,
	menuPlacement = "auto",
	zIndex = 2,
	ghost = false
}) => {
	const getValue = (): ValueType<OptionType> => {
		if (options) {
			return options.find(option => option.value === value);
		}
		return "" as any;
	};

	const onChange = (option: any) => {
		if (!option) {
			setValue("");
		} else if (Array.isArray(option) && option.length) {
			const array = option.map(opt => (opt as OptionType).value);
			setValue(`${array.toString()},`);
		} else {
			setValue((option as OptionType).value);
		}
	};

	const MultiValueRemove = (props: any) => (
		<components.MultiValueRemove {...props}>
			<Icon type="close" color={grayScale.gray100} />
		</components.MultiValueRemove>
	);

	return (
		<div css={styles.container}>
			<VerticalLabelFormLayoutV2
				label={label && <FormLabel label={label} required={required} />}
				input={
					<div css={styles.select}>
						<Select
							styles={selectStyles}
							name={name}
							value={getValue() || null}
							onChange={onChange}
							options={options}
							placeholder={placeholder || "選択してください"}
							isMulti={isMulti}
							isClearable={false}
							components={{ IndicatorSeparator: () => null, MultiValueRemove }}
							isDisabled={disable || ghost}
							menuPlacement={menuPlacement}
							css={ghost ? ghostStyle : {}}
						/>
					</div>
				}
				errorMsg={errorMsg}
				zIndex={zIndex}
			/>
			{note && (
				<span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />
			)}
		</div>
	);
};

export default VerticalLabelSelectFormV2;
