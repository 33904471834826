/** @jsx jsx */
import React from "react";

import ConfirmModal from "components/organismos/ConfirmModal";
import FormContents from "components/atoms/Form/FormContents";
import ListVerticalSeparator from "components/molecules/ListVerticalSeparator";
import PrimaryButton from "components/atoms/Button";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { useOrgAllTable } from "hooks/useOrgTreesOptions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useHolidayAddForm } from "./hooks";
import { jsx, css } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import Checkbox from "components/molecules/Checkbox";
import LableTitle from "components/atoms/LableTitle";
import FormTitle from "components/atoms/Form/FormTitle";

const HolidayImportForm: React.FC = () => {
  const targetYear = sessionStorage.getItem("selectedYearHolidayMaster") || moment().format("YYYY");
  const selectedStartDate = `${targetYear}-01-01`;
  const selectedEndDate = `${targetYear}-12-31`;
  const orgTreesOptions = useOrgAllTable(false, selectedStartDate, selectedEndDate);
  const {
    formik,
    holidayList,
    confirmModalOpen,
    closeConfirmModal,
    selectedIndex,
    setSelectedIndex,
    selectedOrgCodes,
    setSelectedOrgCodes,
  } = useHolidayAddForm();

  // history
  const history = useHistory();

  /**
   *  handle back
   */
  const handleBack = async () => {
    history.goBack();
  };

  return (
    <div>
      <FormContents>
        <FormTitle bold={true} title="祝日取り込み" />
        <FormPadding>
          <LableTitle title="表示されている祝日を登録します。" />
        </FormPadding>

        <div>
          <ListVerticalSeparator title="取り込み祝日リスト" items={holidayList} />
        </div>

        <FormPadding customStyle={{ marginTop: "20px" }}>
          <LableTitle title="表示されている祝日を割り当てる組織をチェックしてください。" />
        </FormPadding>

        <div
          css={css`
            table th {
              text-align: left;
            }
            table th:nth-of-type(1),
            table td:nth-of-type(1) {
              width: 40px;
              text-align: center;
            }
            table th:nth-of-type(2),
            table td:nth-of-type(2) {
              width: 400px;
            }
            tbody {
              display: block;
              height: 500px;
              overflow: auto;
            }
            thead tr,
            tbody tr {
              display: table;
              width: 100%;
            }
          `}
        >
          <Table className="table-no-border-left-right">
            <thead>
              <tr>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>組織コード</Table.HeaderCell>
                <Table.HeaderCell>組織名</Table.HeaderCell>
              </tr>
            </thead>
            <tbody>
              <tr key="all">
                <Table.Cell customStyle={css({ width: "8px" })}>
                  <Checkbox
                    id="checkbox_stamp_all"
                    name="checkbox_stamp_all"
                    value="all"
                    checked={orgTreesOptions.length === selectedIndex.length && orgTreesOptions.length !== 0}
                    isCheckBoxTable={true}
                    onChange={(e) => {
                      if (orgTreesOptions.length === selectedIndex.length) {
                        setSelectedIndex([]);
                        formik.setFieldValue("isAll", false);
                      } else {
                        const allIndex = Object.keys(orgTreesOptions).map(Number);
                        setSelectedIndex(allIndex);
                        formik.setFieldValue("isAll", e.target.value === "all");
                      }
                    }}
                  />
                </Table.Cell>
                <Table.Cell colSpan={2} customStyle={css({ textAlign: "center" })}>
                  全ての組織
                </Table.Cell>
              </tr>
              {orgTreesOptions &&
                orgTreesOptions.length > 0 &&
                orgTreesOptions.map((org, index) => (
                  <tr key={index.toString()}>
                    <Table.Cell customStyle={css({ width: "8px" })}>
                      <Checkbox
                        id={org.value}
                        name={org.value}
                        value={org.value}
                        checked={selectedIndex.includes(index)}
                        isCheckBoxTable={true}
                        onChange={(e) => {
                          if (selectedIndex.includes(index)) {
                            setSelectedIndex(selectedIndex.filter((selected) => selected !== index));
                            setSelectedOrgCodes(selectedOrgCodes.filter((selected) => selected !== e.target.value));
                          } else {
                            setSelectedIndex([...selectedIndex, index]);
                            setSelectedOrgCodes([...selectedOrgCodes, e.target.value]);
                          }
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>{org.value}</Table.Cell>
                    <Table.Cell>{org.label}</Table.Cell>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <FormSubmitArea customStyle={css({ textAlign: "center", marginTop: "50px" })}>
          <div>
            {holidayList && holidayList.length ? (
              <PrimaryButton text="更新" onClick={formik.handleSubmit} minWidth="122px" />
            ) : (
              <PrimaryButton text="登録" minWidth="122px" disabled={true} />
            )}

            {/* <SecondaryButton
            text={'削除'}
            onClick={() => {}}
            minWidth="120px"
            customStyle={css({ marginLeft: '10px' })}
          /> */}
          </div>
          <PrimaryButton text="キャンセル" onClick={() => handleBack()} ghost={true} />

          <div></div>
        </FormSubmitArea>
      </FormContents>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        title="祝日マスタを登録"
        content="祝日マスタを登録します。よろしいですか？"
        submitText="登録する"
      />
    </div>
  );
};

export default HolidayImportForm;
