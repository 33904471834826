/* eslint-disable max-len */
import { useState, useCallback, useEffect } from "react";
import { getShiftViewList, getShiftViewDetail } from "api/shiftView";
import { ShiftViewDomain } from "domain/master/attend/shiftView";
import { StoreShiftViewDomain } from "domain/master/attend/storeShiftView";

export const useShiftList = () => {
  const [shiftList, setShiftList] = useState<Array<ShiftViewDomain>>([]);
  const [date, setDate] = useState<Date>(new Date(sessionStorage.getItem("shiftView.targetDate") || new Date()));
  const [blocking, setBlocking] = useState(false);
  const [detailShiftModalOpen, setDetailShiftModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedTimeString, setSelectedTimeString] = useState("");

  // Get list staff
  const fetchData = useCallback(async () => {
    setBlocking(true);

    getShiftViewList(sessionStorage.getItem("loginUser.orgCode") || "", date)
      .then((response: Array<any>) => {
        const shiftLst = response.map((shift) => {
          const shiftView: ShiftViewDomain = new ShiftViewDomain(shift);
          return shiftView;
        });
        if (shiftLst) {
          setShiftList(shiftLst);
        } else {
          setShiftList([]);
        }
        setBlocking(false);
      })
      .catch(() => {
        setShiftList([]);
        setBlocking(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    selectedTimeString,
    setSelectedTimeString,
    selectedDate,
    setSelectedDate,
    detailShiftModalOpen,
    setDetailShiftModalOpen,
    shiftList,
    setShiftList,
    fetchData,
    date,
    setDate,
    blocking,
  };
};

export const useShiftDetail = (targetDate: string) => {
  const [storeList, setStoreList] = useState<Array<StoreShiftViewDomain> | null>(null);

  useEffect(() => {
    const fetchStaffList = async () => {
      const fetchedDetails = await getShiftViewDetail(new Date(targetDate));
      if (fetchedDetails.storeList) {
        setStoreList(fetchedDetails.storeList.map((store: any) => new StoreShiftViewDomain(store)));
      }
    };
    fetchStaffList();
  }, [targetDate]);

  return {
    storeList,
  };
};
export default useShiftList;
