/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import AppNotificationPanel from "components/molecules/AppNotificationPanel";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import PrimaryButton from "components/atoms/Button";
import BodyText from "components/atoms/BodyText";
import ClickableLink from "components/atoms/ClickableLink";
import PhotoFrame from "components/molecules/PhotoFrame";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import { useStaffSetting } from "components/organismos/Appbar/hooks";

const StaffSettingPanel: React.FC<{
  staffName: string;
}> = ({ staffName }) => {
  const history = useHistory();
  const goToPersonalSetting = () => {
    history.push("/personalSetting");
  };
  const photoPathGlobal = useSelector((state: Store) => state.ui.appBar.photoPath);
  const { logoutAction } = useStaffSetting();

  return (
    <AppNotificationPanel>
      <FlexBox direction="column" alignItems="center" customStyle={css({ padding: "2rem" })}>
        <FlexBoxItem>
          <PhotoFrame src={photoPathGlobal} size="90px" isCircle={true} onErrorIconColor="black" />
        </FlexBoxItem>
        <FlexBoxItem margin="0.5rem 0 0">
          <BodyText size="lg">{staffName}</BodyText>
        </FlexBoxItem>

        <FlexBoxItem margin="1rem 0 0">
          <PrimaryButton text="スタッフ設定" onClick={goToPersonalSetting} />
        </FlexBoxItem>
        <FlexBoxItem margin="0.5rem 0 0">
          <ClickableLink onClick={logoutAction}>ログアウト</ClickableLink>
        </FlexBoxItem>
      </FlexBox>
    </AppNotificationPanel>
  );
};

export default StaffSettingPanel;
