import React, { useState, useEffect, useCallback } from 'react';

import { 
  getDetailRole, 
  DetailRole, 
} from 'api/masterRole';

export const useGetDetailRole = (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, functionCode: string): {
  detailRole: DetailRole,
  loadedRole: boolean
} => {

  const defaultDetailRole = {
    roleId: '',
    functionCode: functionCode,		
    available: false,		
    editable: 0,
    importFlag: 0,		
    downloadFlag: 0,		
    accessRange: 0,
    linkAvailable1: true,
    linkAvailable2: false,
    linkAvailable3: false,
    linkAvailable4: false,
    linkAvailable5: false,
    usabilityGeneralItem1: null,
    usabilityGeneralItem2: null,
    usabilityGeneralItem3: null,
    usabilityGeneralItem4: null,
    usabilityGeneralItem5: null,
    usabilityGeneralItem6: null,
    usabilityGeneralItem7: null,
    usabilityGeneralItem8: null,
    usabilityGeneralItem9: null,
    usabilityGeneralItem10: null,
  }

  const [detailRole, setDetailRole] = useState<DetailRole>(defaultDetailRole);
  const [loadedRole, setLoadedRole] = useState<boolean>(false);
  const fetchFunctionRole = useCallback(async () => {
    setIsLoading(true);
    getDetailRole(functionCode).then((response: DetailRole) => {
      if(response){
        setDetailRole(response);
      }
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, []);
  
  useEffect(() => {
    fetchFunctionRole();
  }, [])

  useEffect(() => {
    if(detailRole.roleId){
      setLoadedRole(true);
    }
  }, [detailRole])

  return { 
    detailRole,
    loadedRole
  };
};

export default useGetDetailRole;
