/** @jsx jsx */

import React, { useState, useRef, useCallback, useEffect } from "react";
import FormContents from "components/atoms/Form/FormContents";
import { Container, Row, Col } from "react-grid-system";
import BodyText from "components/atoms/BodyText";
import { PrimaryButton, SecondaryButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import FormField from "components/atoms/Form/FormField";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import SelectForm from "components/molecules/SelectForm";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import Webcam from "react-webcam";
import { isIPad13 } from "react-device-detect";
import { css, jsx } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import LableTitle from "components/atoms/LableTitle";
import { grayScale } from "components/styles";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import { PersonalSettingForm } from "./hooks";
import ToastModal from "../../ToastModal";
import FileSelectButton from "components/molecules/FileSelectButton";
import Cropper from "react-easy-crop";
import getCroppedImg from "hooks/useCropImage";
import Modal from "components/molecules/Modal";
import useIsMobile from "hooks/useIsMobile";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";

const videoConstraints = {
  width: 400,
  height: 250,
  facingMode: "user",
};

const PersonalSetting: React.FC = () => {
  const isMobile = useIsMobile();
  const [croppedImage, setCroppedImage] = useState<any>(sessionStorage.getItem("loginUser.photoPath"));
  const [beforeCroppedImage, setBeforeCroppedImage] = useState<any>(sessionStorage.getItem("loginUser.photoPath"));
  const [changeImage, setChangeImage] = useState(false);
  const webcamRef = useRef(Object());
  const fileInputRef = useRef(Object());
  const [tempFile, setTempFile] = useState<any>("");
  const [tempModalFile, setTempModalFile] = useState<any>(sessionStorage.getItem("loginUser.photoPath"));
  const [capturedPhoto, setCapturedPhoto] = useState(false);
  const [imageCap, setImageCap] = useState<any>();
  const [imageUpload, setImageUpload] = useState<any>();
  const canvasRef1 = useRef<HTMLCanvasElement>(null);
  const [isCapture, setIsCapture] = useState(false);
  // crop
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [disableButtonCap, setDisableButtonCap] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isNotExistImage, setIsNotExistImage] = useState(false);

  const checkChangeImage = (cropUrl: string) => {
    let urlSession = sessionStorage.getItem("loginUser.photoPath") || "";
    return urlSession.slice(0, urlSession.lastIndexOf("?")) !== cropUrl.slice(0, cropUrl.lastIndexOf("?"));
  };

  const handleNotExistImage = (e: any) => {
    e.target.style.display = "none";
    setIsNotExistImage(true);
  };
  const handleExistImage = (e: any) => {
    e.target.style.display = "block";
    setIsNotExistImage(false);
  };

  const resetForm = () => {
    setOpenCamera(false);
    setImageUpload(null);
    setTempFile("");
    setOpenCropModal(false);
    setIsCapture(false);
    setDisableButtonCap(false);
    setCapturedPhoto(false);
    setImageCap(null);
    if (checkChangeImage(croppedImage)) {
      setCroppedImage(sessionStorage.getItem("loginUser.photoPath") || "");
      setTempModalFile(sessionStorage.getItem("loginUser.photoPath") || "");
    } else {
      setCroppedImage("");
      setTempModalFile("");
    }
    setBeforeCroppedImage("");
  };

  const showCroppedImage = useCallback(async (): Promise<any> => {
    setOpenCropModal(false);
    setBeforeCroppedImage(tempModalFile);
    try {
      const croppedImageData = await getCroppedImg(tempModalFile, croppedAreaPixels, rotation);
      setCroppedImage(croppedImageData);
      setChangeImage(true);
      return croppedImageData;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const CONTAINER_SIZE = 230;
  const CONTAINER_OUT_SIZE = 250;

  const {
    formik,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    closeConfirmApplyModal,
    isLoading,
    handlePostPhoto,
    errorNotification,
  } = PersonalSettingForm(
    croppedImage,
    resetForm,
    showCroppedImage,
    setCroppedImage,
    setChangeImage,
    setBeforeCroppedImage,
  );

  // countdown
  const [count, setCount] = useState(3);
  const [showMessage, setShowMessage] = useState(false);
  const countDown = () => {
    setDisableButtonCap(true);
    setShowMessage(true);
    const funcInterval = setInterval(() => {
      setCount((prevCount) => {
        return prevCount - 1;
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(funcInterval);
      setCount(3);
      setShowMessage(false);
      setDisableButtonCap(false);
    }, 4000);
  };
  const countTimeCap = (webcamRef: any) => {
    setTimeout(() => {
      capture(webcamRef);
    }, 3000);
  };

  const onUserMediaError = (err: any) => {
    if (err) {
      errorNotification("カメラへのアクセスに失敗しました。");
      resetForm();
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCamera = () => {
    setOpenCamera(true);
    setOpenCropModal(false);
    setDisableButtonCap(false);
    setCapturedPhoto(false);
    setIsCapture(true);
  };

  const styles = {
    colorText: css({
      color: grayScale.gray100,
    }),
    photoDefault: css({
      height: "calc( 100% - 2px )",
      top: "1px",
      position: "absolute",
    }),
    fileUploadButton: css`
      background: transparent;
      button {
        padding-left: 27px;
        width: 100%;
        text-align: left;
      }
    `,
    photoCap: css({
      width: "auto",
      height: "100%",
      margin: "auto",
      maxWidth: "100%",
    }),
    canvas: css({
      width: "auto",
      height: "100%",
      margin: "auto",
    }),
    slider: css({
      background: "#007BC3",
      WebkitAppearance: "none",
      height: "2px",
      outline: "none !important",
      MozAppearance: "none",
      appearance: "none",
      border: "none",
      borderRadius: "30px",
      width: "400px",
      maxWidth: "100%",
      cursor: "pointer",
      margin: "40px 0 30px",
    }),
    showMessage: css({
      position: "absolute",
      bottom: "-30px",
      left: "0",
      display: "none",
    }),
    buttonBack: css({
      position: "absolute",
      bottom: "-30px",
      right: "0",
      minWidth: "fit-content",
      border: "0",
      lineHeight: "20px",
      padding: "0",
    }),
    cropContainer: css(
      {
        height: "250px",
        position: "relative",
        margin: "auto",
      },
      css`
        .reactEasyCrop_CropArea::before,
        .reactEasyCrop_CropArea::after {
          border: 0;
        }
      `,
    ),
    buttonAction: css({
      width: "fit-content",
      margin: "0",
      padding: "0",
      minWidth: "fit-content",
    }),
    uploadFile: css([
      {
        position: "absolute",
        zIndex: 1,
        backgroundColor: "transparent",
        width: "fit-content",
        paddingLeft: "50px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      css`
        button {
          padding-left: 10px;
        }
      `,
    ]),
    imageCropped: css({
      cursor: "pointer",
      height: "100%",
      margin: "auto",
    }),
    isHover: css({
      opacity: "0.1",
      cursor: "pointer",
      transition: "opacity 0.3s",
    }),
    noteImage: css({
      fontSize: "12px",
      color: "#ED5D5D",
      marginBottom: "15px",
      lineHeight: "22px",
    }),
  };

  const capture = useCallback(
    (webcamRef) => {
      setDisableButtonCap(true);
      setTempModalFile("");
      setOpenCropModal(false);
      const base64string = webcamRef.current?.getScreenshot();

      if (!canvasRef1.current) {
        return;
      }

      const canvas: HTMLCanvasElement = canvasRef1.current;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        return;
      }

      const img = new Image();
      img.crossOrigin = "";

      // eslint-disable-next-line no-loop-func
      img.onload = () => {
        const w = img.width;
        const h = img.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = w;
        canvas.height = h;
        ctx.transform(1, 0, 0, 1, 0, 0);
        ctx.drawImage(img, 0, 0, w, h);
        setImageCap(img);
        canvas.toBlob((blob: any) => {
          setTempModalFile(URL.createObjectURL(blob));
          setOpenCropModal(true);
          setDisableButtonCap(false);
          setCapturedPhoto(true);
          setIsCapture(false);
          setDisableButtonCap(false);
          setOpenCamera(false);
        }, "image/jpeg");
      };
      img.onerror = () => {
        setDisableButtonCap(false);
      };
      img.src = base64string;
    },
    [handlePostPhoto],
  );

  const onImageChange = (file: any) => {
    // setOpenCamera(false);
    setTempModalFile(URL.createObjectURL(file));
    setOpenCropModal(true);
    setIsCapture(false);
    // var width = 400;
    // var img = document.getElementById("image");
    var reader = new FileReader();

    reader.onload = function(e) {
      setTempModalFile(e.target!!.result || "");
    };

    reader.readAsDataURL(file);
    if (!file.type.match(/image.*/)) {
      return false;
    }
    setCapturedPhoto(false);
    setImageCap(null);
    setImageUpload(file);
    return true;
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormPadding customStyle={css(isMobile ? { padding: "8px 16px 0 16px", margin: 0 } : { paddingRight: "0" })}>
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <Row>
                <Col lg={5} md={12} sm={12} xs={12}>
                  <strong>
                    <LableTitle
                      customStyle={isMobile ? { padding: "0px" } : { paddingLeft: "0px", marginTop: "15px" }}
                      title="スタッフ設定"
                    />
                  </strong>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={4} xs={12}>
                      <BodyText>所属組織</BodyText>
                    </Col>
                    <Col md={8} xs={12}>
                      <BodyText customStyle={styles.colorText}>{formik.values.orgName}</BodyText>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={4} xs={12}>
                      <BodyText>スタッフコード</BodyText>
                    </Col>
                    <Col md={8} xs={12}>
                      <BodyText customStyle={styles.colorText}>{formik.values.staffCode}</BodyText>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={4} xs={12}>
                      <BodyText>氏名</BodyText>
                    </Col>
                    <Col md={8} xs={12}>
                      <BodyText customStyle={styles.colorText}>{formik.values.staffName}</BodyText>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col md={4} xs={12}>
                      <BodyText>メールアドレス</BodyText>
                    </Col>
                    <Col md={8} xs={12}>
                      <BodyText customStyle={styles.colorText}>{formik.values.mail}</BodyText>
                    </Col>
                  </Row>
                  <strong>
                    <LableTitle customStyle={{ paddingLeft: "0px", marginTop: "25px" }} title="シフト開始 通知設定" />
                  </strong>
                  <Row>
                    <Col lg={12} md={12}>
                      <FormField displayBlock={true}>
                        <FlexBox>
                          <FlexBoxItem>
                            <BodyText>シフト開始前通知</BodyText>
                          </FlexBoxItem>
                          <FlexBoxItem marginLeft="34px">
                            <RadioSelectForm
                              label=""
                              name="notifyBeforeShiftStart"
                              items={[
                                { value: "1", label: "する" },
                                { value: "0", label: "しない" },
                              ]}
                              value={String(formik.values.attendNoticeSetting.notifyBeforeShiftStart ? "1" : "0")}
                              setValue={(e) =>
                                formik.setFieldValue(
                                  "attendNoticeSetting.notifyBeforeShiftStart",
                                  e.target.value === "1",
                                )
                              }
                              additionStyle={{ marginTop: "0px" }}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      </FormField>
                    </Col>
                  </Row>
                  <Row>
                    {formik.values.attendNoticeSetting.notifyBeforeShiftStart ? (
                      <Col md={12}>
                        <FormField displayBlock={true}>
                          <FlexBox>
                            <FlexBoxItem>
                              <BodyText>シフト開始の</BodyText>
                            </FlexBoxItem>
                            <FlexBoxItem margin="0 4px">
                              <VerticalLabelSelectForm
                                label=""
                                name="notifyToStampForgot"
                                width="70px"
                                value={
                                  String(formik.values.attendNoticeSetting.minutesBeforeShiftStart) === "0"
                                    ? "30"
                                    : String(formik.values.attendNoticeSetting.minutesBeforeShiftStart)
                                }
                                setValue={(val) => {
                                  formik.setFieldValue("attendNoticeSetting.minutesBeforeShiftStart", val);
                                }}
                                options={[
                                  { value: "30", label: "30" },
                                  { value: "60", label: "60" },
                                  { value: "90", label: "90" },
                                ]}
                                isSearchable={false}
                              />
                            </FlexBoxItem>
                            <FlexBoxItem>
                              <BodyText>分前に、メールで通知します。</BodyText>
                            </FlexBoxItem>
                          </FlexBox>
                        </FormField>
                      </Col>
                    ) : (
                      <BodyText>シフト開始前にメール通知を受け取る場合、「通知する」を選択します。</BodyText>
                    )}
                  </Row>
                  <strong>
                    <LableTitle customStyle={{ paddingLeft: "0px", marginTop: "15px" }} title="打刻忘れ 通知設定" />
                  </strong>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <FormField displayBlock={true}>
                        <FlexBox>
                          <FlexBoxItem>
                            <BodyText>打刻忘れ通知</BodyText>
                          </FlexBoxItem>
                          <FlexBoxItem marginLeft="60px">
                            <RadioSelectForm
                              label=""
                              name="notifyToStampForgot"
                              items={[
                                { value: "1", label: "する" },
                                { value: "0", label: "しない" },
                              ]}
                              value={formik.values.attendNoticeSetting.notifyToStampForgot ? "1" : "0"}
                              setValue={(e) =>
                                formik.setFieldValue("attendNoticeSetting.notifyToStampForgot", e.target.value === "1")
                              }
                              additionStyle={{ marginTop: "0px" }}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      </FormField>
                    </Col>
                    <Col xs={12}>
                      <BodyText>
                        {formik.values.attendNoticeSetting.notifyToStampForgot
                          ? "シフト開始時間を過ぎても打刻されていない場合、メールで通知します。"
                          : "打刻忘れのメール通知を受け取る場合、「通知する」を選択します。"}
                      </BodyText>
                    </Col>
                  </Row>
                </Col>
                <Col lg={7} md={12} sm={12} xs={12}>
                  <strong>
                    <LableTitle customStyle={{ paddingLeft: "0px", marginTop: "15px" }} title="顔写真登録" />
                  </strong>
                  <Col lg={12} md={12} xs={12} style={{ paddingTop: "18px" }}>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "400px",
                          flex: "none",
                          height: "250px",
                          flexWrap: "wrap",
                          border: croppedImage && !isNotExistImage ? "none" : "1px solid rgb(220, 220, 220)",
                        }}
                      >
                        {!isCapture ? (
                          <React.Fragment>
                            <div
                              style={{ height: "250px" }}
                              onMouseOver={() => setIsHover(true)}
                              onMouseLeave={() => setIsHover(false)}
                            >
                              {croppedImage && (
                                <img
                                  src={croppedImage}
                                  width={250}
                                  alt="cropped image"
                                  css={css([styles.imageCropped, isHover ? styles.isHover : {}])}
                                  onError={handleNotExistImage}
                                  onLoad={handleExistImage}
                                />
                              )}
                              <FileSelectButton
                                text="ファイルを選択"
                                setFile={(file) => onImageChange(file)}
                                value=""
                                ghost={true}
                                accept="image/jpeg,image/png"
                                customStyle={styles.fileUploadButton}
                                customStyleContainer={styles.uploadFile}
                                hidden={!isHover && croppedImage !== "" && !isNotExistImage}
                              />
                            </div>
                            <FlexBox
                              customStyle={css({
                                width: "250px",
                                margin: "auto",
                                justifyContent: "space-between",
                              })}
                            >
                              <PrimaryButton
                                text="編集"
                                ghost={true}
                                customStyle={css([
                                  styles.buttonAction,
                                  croppedImage === "" || isNotExistImage || !checkChangeImage(croppedImage)
                                    ? { visibility: "hidden" }
                                    : {},
                                ])}
                                onClick={() => {
                                  setOpenCropModal(true);
                                  if (beforeCroppedImage) {
                                    setTempModalFile(beforeCroppedImage);
                                  }
                                }}
                                fullwidth={true}
                              />
                              <SecondaryButton
                                text={checkChangeImage(croppedImage) ? "キャンセル" : "削除"}
                                customStyle={css([
                                  styles.buttonAction,
                                  croppedImage === "" || isNotExistImage ? { display: "none" } : {},
                                ])}
                                ghost={true}
                                onClick={() => {
                                  resetForm();
                                }}
                              />
                            </FlexBox>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {isCapture && (
                              <React.Fragment>
                                <Webcam
                                  screenshotQuality={1}
                                  style={{}}
                                  audio={false}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  width={400}
                                  height={250}
                                  videoConstraints={videoConstraints}
                                  onUserMediaError={onUserMediaError}
                                  mirrored={true}
                                />
                                {showMessage ? (
                                  <FlexBox
                                    customStyle={css([
                                      styles.showMessage,
                                      openCamera && showMessage ? { display: "block" } : {},
                                    ])}
                                  >
                                    <BodyText size="re" color={grayScale.gray50}>
                                      撮影開始まで{count}秒
                                    </BodyText>
                                  </FlexBox>
                                ) : (
                                  <FlexBoxItem>
                                    <SecondaryButton
                                      text="キャンセル"
                                      customStyle={styles.buttonBack}
                                      onClick={() => {
                                        setIsCapture(false);
                                      }}
                                      ghost={true}
                                    />
                                  </FlexBoxItem>
                                )}
                              </React.Fragment>
                            )}
                            <canvas
                              style={{ display: "none", width: "auto", height: "300px" }}
                              ref={canvasRef1}
                              className="canvas"
                            />
                          </React.Fragment>
                        )}
                      </Col>
                      <Col style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}>
                        <Row style={{ width: "100%" }}>
                          <Col style={{ margin: "0 0 10px 0" }} lg={12} md={12}>
                            <FlexBox>
                              <BodyText customStyle={styles.noteImage}>
                                ※明るく鮮明な写真をご用意ください
                                <br />
                                ※ファイル形式：jpg,jpeg,png
                              </BodyText>
                            </FlexBox>
                            {isCapture ? (
                              <PrimaryButton
                                text="撮影開始"
                                onClick={() => {
                                  countTimeCap(webcamRef);
                                  countDown();
                                }}
                                minWidth="154px"
                                disabled={disableButtonCap}
                              />
                            ) : (
                              <PrimaryButton
                                text="撮影する"
                                onClick={() => {
                                  onCamera();
                                  setIsCapture(true);
                                }}
                              />
                            )}
                          </Col>

                          <input
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            type="file"
                            onChange={(file) => onImageChange(file)}
                            accept=".jpg,.png,image/jpeg,image/png"
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: isIPad13 ? "100px 0" : "50px 0",
                  }}
                >
                  <PrimaryButton
                    minWidth="154px"
                    ghost={false}
                    text="更新する"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    // disabled={!enableSubmit}
                    customStyle={css({ padding: "0 50px" })}
                  />
                </Col>
              </Row>
            </Container>
          </form>

          <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

          <ConfirmModal
            open={confirmApplyModalOpen}
            closeHandler={closeConfirmApplyModal}
            onSubmit={() => {
              formik.handleSubmit();
            }}
            title="確認メッセージ"
            content="更新します。よろしいですか？"
            submitText="登録する"
          />

          <Modal
            open={openCropModal}
            closeHandler={() => {
              setOpenCropModal(false);
              setCapturedPhoto(false);
            }}
            title={"顔写真の登録"}
            submitText={"切り取る"}
            onSubmit={() => showCroppedImage()}
            closeText={"キャンセル"}
          >
            <FlexBox customStyle={styles.cropContainer}>
              <Cropper
                image={tempModalFile}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1 / 1}
                minZoom={minZoom}
                maxZoom={3}
                cropSize={{
                  width: 230,
                  height: 230,
                }}
                style={{
                  cropAreaStyle: {
                    color: "rgba(0, 0, 0, 0.3)",
                  } as React.CSSProperties,
                  mediaStyle: {
                    maxWidth: "100%",
                    maxHeight: "100%",
                  },
                  containerStyle: {
                    width: "400px",
                    margin: "auto",
                  },
                }}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onMediaLoaded={(mediaSize) => {
                  setCrop({ x: 0, y: 0 });
                  // Adapt zoom based on media size to fit max height
                  if (mediaSize.naturalHeight > CONTAINER_SIZE) {
                    setZoom(CONTAINER_SIZE / CONTAINER_OUT_SIZE);
                    setMinZoom(CONTAINER_SIZE / CONTAINER_OUT_SIZE);
                  } else {
                    if (mediaSize.naturalHeight < mediaSize.naturalWidth) {
                      setZoom(CONTAINER_SIZE / mediaSize.naturalHeight);
                      setMinZoom(CONTAINER_SIZE / mediaSize.naturalHeight);
                    } else {
                      setZoom(CONTAINER_SIZE / mediaSize.naturalWidth);
                      setMinZoom(CONTAINER_SIZE / mediaSize.naturalWidth);
                    }
                  }
                }}
              />
            </FlexBox>
            <FlexBox justifyContent="center">
              <input
                type="range"
                css={styles.slider}
                aria-labelledby="Zoom"
                value={zoom}
                min={minZoom}
                max={3}
                step={0.01}
                onChange={(zoom) => setZoom(Number(zoom.target.value))}
              />
            </FlexBox>
          </Modal>
        </FormPadding>
      </FormContents>

      {/* {isMobileOnly && <BottomBar />} */}
    </BlockUI>
  );
};

export default PersonalSetting;
