/** @jsx jsx */
import React, { Dispatch } from "react";
import { Link } from "react-router-dom";
import FormField from "components/atoms/Form/FormField";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, SecondaryButton } from "components/atoms/Button";
import FormTitle from "components/atoms/Form/FormTitle";
import StaffDomain from "domain/staff";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import { OrganizationDataStaffDomain } from "domain/master/general/organizationDataStaff";
import { getOrganizationInfo } from "api/organization";
import moment from "moment";
import { useAffiliationForm } from "./hooks";
import { TablItem } from "components/atoms/TabBar";
import { css, jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import useStaffList from "components/pages/master/general/staff/StaffsPage/hooks";
import { useStaffDelete } from "../StaffListTable/hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import SelectForm from "components/molecules/SelectForm";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import FormLabel from "components/atoms/Form/FormLabel";
import Calendar from "components/molecules/CalendarV1";
import ConcurrentModal from "./ConcurrentModal";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import { textFontSize } from "components/styles";
import Table from "components/molecules/Table";

type SelectType = "staff" | "affiliation" | "employment" | "laborCost" | "workPattern" | "longTermCareandNurse";
const SelectType: { [key in SelectType]: SelectType } = {
  staff: "staff",
  affiliation: "affiliation",
  employment: "employment",
  laborCost: "laborCost",
  workPattern: "workPattern",
  longTermCareandNurse: "longTermCareandNurse",
};

const styles = {
  form: css({
    maxWidth: "800px",
    margin: "0 auto",
    padding: "30px",
  }),
  table: css({
    ["table tr th"]: {
      fontWeight: "bold",
      textAlign: "left",
    },
    ["table tr td"]: {
      textAlign: "left",
    },
  }),
  note: css({
    margin: "50px 0px 20px",
    paddingLeft: "30px",
    display: "block",
  }),
  buttonAdd: css({
    maxWidth: "190px",
    marginLeft: "25px",
    marginTop: "15px",
  }),
  buttonSubmit: css({
    margin: "0 auto",
  }),
  centerBox: css({
    justifyContent: "center",
  }),
  checkbox: css({
    paddingLeft: "15px",
  }),
};

const AffiliationInfoForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setOrgTargetDate?: React.Dispatch<React.SetStateAction<Date>>;
  executiveOptions: Array<any>;
  isDisabledInfo?: boolean;
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
}> = ({
  initialStaffDomain,
  isEditMode = false,
  tabItems,
  setInitialStaffDomain,
  setSelectType,
  setTabItems,
  setOrgTargetDate,
  executiveOptions,
  isDisabledInfo = false,
  setIsLoading,
}) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    contextObject,
    openConcurrentAddModal,
    setOpenConcurrentAddModal,
    orgTreeOptions,
    onLoadTreesOrg,
    modalOrgTreeOptions,
  } = useAffiliationForm(initialStaffDomain, isEditMode, setInitialStaffDomain, setIsLoading);

  const columns: Array<Column<OrganizationDataStaffDomain>> = React.useMemo(
    () => [
      {
        Header: "異動日",
        accessor: "applyStartDateStr",
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "役職コード",
        accessor: "executiveCode",
      },

      {
        Header: "役職名",
        accessor: "executiveName",
      },
    ],
    [],
  );

  const { fetchData } = useStaffList();
  const {
    setDeleteTargetStaff,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  } = useStaffDelete(fetchData, setIsLoading);

  return (
    <React.Fragment>
      {formik.values.organizationDataList && formik.values.organizationDataList.length !== 0 && (
        <div className="table-bordered" css={styles.table}>
          <DataTable columns={columns} data={formik.values.organizationDataDomainList} isHiddenPagination={false} />
        </div>
      )}

      <form onSubmit={formik.handleSubmit} css={styles.form}>
        <FormTitle title="基本情報" bold={true} customStyle={css({ marginLeft: "0" })} />
        <FormField>
          <VerticalLabelFormLayout
            label={<FormLabel label="異動日" required={true} />}
            input={
              <Calendar
                date={formik.values.orgApplyDate}
                setDate={(date) => {
                  if (date && date.getTime() !== formik.values.orgApplyDate?.getTime()) {
                    if (setOrgTargetDate) {
                      setOrgTargetDate(date);
                    }
                    formik.setFieldValue("orgApplyDate", date);
                    onLoadTreesOrg(formik.values.orgApplyDate);
                  }
                }}
                readOnly={isDisabledInfo}
              />
            }
            errorMsg={formik.errors.orgApplyDate}
          />
        </FormField>

        <FormField displayBlock={true}>
          <SelectForm
            label="組織"
            name="organization"
            value={formik.values.orgCode}
            setValue={(val: string) => {
              formik.values.orgCode = val;

              formik.setFieldValue("orgCode", val);
              setInitialStaffDomain(formik.values);
            }}
            options={orgTreeOptions}
            note={
              isEditMode
                ? " 組織を変更すると、異動前組織のシフトパターンは削除されます。勤務パターンを確認してください。"
                : ""
            }
            errorMsg={formik.errors.orgCode}
            required={true}
            disable={isDisabledInfo}
            isDisplayBlock={true}
            width="800px"
          />
        </FormField>

        <FormField displayBlock={true}>
          <SelectForm
            label="役職"
            name="executiveId"
            value={formik.values.executiveId}
            setValue={(val: string) => {
              formik.setFieldValue("executiveId", val);
            }}
            options={executiveOptions}
            disable={isDisabledInfo}
            isDisplayBlock={true}
          />
        </FormField>
      </form>

      <FormTitle title="兼務設定" bold={true} customStyle={css({ marginLeft: "20px" })} />
      <div className="table-bordered">
        <div
          className="table-container"
          css={[
            styles.table,
            css({
              margin: "0 16px",
              borderLeft: "1px solid #ddd",
              borderRight: "1px solid #ddd",
              ["table tr th:nth-child(1)"]: {
                width: "auto",
              },
              ["table tr th:nth-child(2)"]: {
                width: "auto",
              },
              ["table tr th:nth-child(3)"]: {
                width: "240px",
              },
            }),
          ]}
        >
          <Table>
            <thead>
              <tr>
                <th>組織</th>
                <th>役職</th>
                <th>期間</th>
                <th style={{ width: "70px" }}></th>
              </tr>
            </thead>
            <tbody>
              {formik.values.concurrentList?.map((concurrent, index) => (
                <tr>
                  <td>{concurrent.orgName.trim()}</td>
                  <td>{concurrent.executiveName}</td>
                  <td>
                    <FlexBox>
                      <CalendarRangeV2
                        startDate={concurrent.applyStartDate ? new Date(concurrent.applyStartDate) : new Date()}
                        endDate={
                          concurrent.applyEndDate && new Date(concurrent.applyEndDate).getFullYear() !== 9999
                            ? new Date(concurrent.applyEndDate)
                            : undefined
                        }
                        onChangeStart={(date: Date) => {
                          let tmpConcurrentList = [...formik.values.concurrentList];
                          tmpConcurrentList[index].applyStartDate = date;
                          formik.setFieldValue("concurrentList", tmpConcurrentList);
                        }}
                        onChangeEnd={(date) => {
                          let tmpConcurrentList = [...formik.values.concurrentList];
                          tmpConcurrentList[index].applyEndDate = date;
                          formik.setFieldValue("concurrentList", tmpConcurrentList);
                        }}
                        canNullEndDate={true}
                      />
                    </FlexBox>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <ClickableIconButton
                      onClick={() => {
                        let tmpConcurrentList = [...formik.values.concurrentList];
                        tmpConcurrentList.splice(index, 1);
                        formik.setFieldValue("concurrentList", tmpConcurrentList);
                      }}
                    >
                      <Icon type="delete" color={textFontSize.re} />
                    </ClickableIconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div css={styles.buttonAdd}>
        <IconLabelButton onClick={() => setOpenConcurrentAddModal(true)} iconType="addCircle" text="兼務組織追加" />
      </div>

      <FormSubmitArea customStyle={css({ padding: "30px" })}>
        <div style={{ marginRight: "12px", textAlign: "center" }}>
          {contextObject.detailRole.editable === 1 && (
            <FlexBox customStyle={styles.centerBox}>
              <Button text={isEditMode ? "所属情報を登録" : "登録"} onClick={formik.handleSubmit} minWidth="150px" />
              {isEditMode && !isDisabledInfo ? (
                <div style={{ marginLeft: "12px", textAlign: "center" }}>
                  <SecondaryButton
                    minWidth="150px"
                    text="削除"
                    onClick={() => {
                      setIsOpenDeleteModal(true);
                      setDeleteTargetStaff(formik.values);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {!isEditMode && (
                <div style={{ marginLeft: "12px", textAlign: "center" }}>
                  <Button
                    text="雇用情報の入力へ"
                    onClick={() => {
                      if (formik.values.orgApplyDate && formik.values.orgCode) {
                        formik.values.empApplyDate = formik.values.hireDate;
                        getOrganizationInfo(
                          formik.values.orgCode,
                          moment(formik.values.hireDate).format("YYYY-MM-DD"),
                        ).then((response: any) => {
                          if (response.employmentId) {
                            formik.setFieldValue("employmentId", response.employmentId);
                          } else {
                            formik.setFieldValue("employmentId", "");
                          }

                          if (tabItems.length <= 2) {
                            setInitialStaffDomain(formik.values);
                            setTabItems([
                              {
                                value: SelectType.staff,
                                display: "スタッフ情報",
                              },
                              {
                                value: SelectType.affiliation,
                                display: "所属情報",
                              },
                              {
                                value: SelectType.employment,
                                display: "雇用情報",
                              },
                            ]);
                          }
                          setSelectType(SelectType.employment);
                        });
                      } else {
                        formik.validateForm();
                      }
                    }}
                  />
                </div>
              )}
            </FlexBox>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/staffs">
            <PrimaryButton text="スタッフ一覧に戻る" ghost={true} minWidth="150px" />
          </Link>
        </div>
      </FormSubmitArea>
      <ConcurrentModal
        openModal={openConcurrentAddModal}
        setOpenModal={setOpenConcurrentAddModal}
        organizationOptions={modalOrgTreeOptions}
        executiveOptions={executiveOptions}
        onSubmit={(concurrent) => {
          let tmpConcurrentList = [...formik.values.concurrentList];
          tmpConcurrentList.push(concurrent);
          formik.setFieldValue("concurrentList", tmpConcurrentList);
        }}
      />
      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
        typeSubmit="reject"
      />
    </React.Fragment>
  );
};

export default AffiliationInfoForm;
