import React from 'react';

import { useParams } from 'react-router-dom';
import TimeOutputLayoutInputAddForm from './TimeOutputLayoutInputAddForm';

const TimeOutputLayoutAddForm: React.FC = () => {
  const { layoutId } = useParams();
  return (
    <div>
      <TimeOutputLayoutInputAddForm layoutId={layoutId || ''} />
    </div>
  );
};

export default TimeOutputLayoutAddForm;
