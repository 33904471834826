import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import FormContents from "components/atoms/Form/FormContents";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, SecondaryButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import { useAttendAllowanceAddForm } from "./hooks";
import { css } from "@emotion/core";
import { deleteById } from "api/attendAllowance";
import useToastNotification from "hooks/useToastNotification";
import BlockUI from "components/molecules/BlockUi";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import FormTitle from "components/atoms/Form/FormTitle";
import AttendAllowanceDomain from "domain/attendAllowance";

const AttendAllowanceCreateForm: React.FC = () => {
  const { allowanceId } = useParams();
  const { formik, confirmModalOpen, closeConfirmModal, history, blocking, detailRole } = useAttendAllowanceAddForm(
    allowanceId,
  );

  const [selectedAttendAllowance, setSelectedAttendAllowance] = useState(AttendAllowanceDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const closeConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModalOpen(false);
  }, []);

  const openConfirmDeleteModal = useCallback((allowance) => {
    setConfirmDeleteModalOpen(true);
    setSelectedAttendAllowance(allowance);
  }, []);

  const deleteAttendAllowance = useCallback(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    deleteById(companyCode, selectedAttendAllowance.allowanceId)
      .then((response: any) => {
        setConfirmDeleteModalOpen(false);
        successNotification("削除しました。");
        history.push("/attendAllowance");
      })
      .catch((error) => {
        setConfirmDeleteModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage}\n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [errorNotification, selectedAttendAllowance.allowanceId, successNotification, history]);

  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormTitle bold={true} title={`${allowanceId ? "手当編集" : "手当作成"}`} />
        <div style={{ paddingTop: "80px" }}>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ margin: "0 auto", width: "60%" }}>
              <FormField>
                <TextForm
                  name="allowanceCode"
                  label="コード"
                  value={formik.values.allowanceCode}
                  required={true}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.allowanceCode}
                />
              </FormField>

              <FormField>
                <TextForm
                  name="allowanceName"
                  label="手当名称"
                  value={formik.values.allowanceName}
                  required={true}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.allowanceName}
                />
              </FormField>

              <FormField>
                <TextForm
                  name="dispMessage"
                  label="表示メッセージ"
                  value={formik.values.dispMessage}
                  required={true}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.dispMessage}
                />
              </FormField>

              <FormField>
                <TextForm
                  name="dispUnit"
                  label="申請単位"
                  value={formik.values.dispUnit}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.dispUnit}
                />
              </FormField>
            </div>

            <div style={{ paddingTop: "75px", margin: "0 auto", width: "60%" }}>
              <FormSubmitArea>
                {detailRole.editable === 1 && (
                  <div style={{ display: "table", margin: "0 auto" }}>
                    <FlexBox>
                      <FlexBoxItem customStyle={allowanceId ? css({ paddingRight: "15px" }) : css({})}>
                        <Button
                          customStyle={css("width: 170px;")}
                          text={allowanceId ? "更新" : "手当を登録"}
                          onClick={formik.handleSubmit}
                        />
                      </FlexBoxItem>
                      {/* {allowanceId && (
                        <FlexBoxItem>
                          <SecondaryButton
                            customStyle={css("width: 170px;")}
                            text="削除"
                            onClick={() => {
                              openConfirmDeleteModal(formik.values);
                            }}
                          />
                        </FlexBoxItem>
                      )} */}
                    </FlexBox>
                  </div>
                )}
                <div style={{ padding: "5px 0px 70px 0px", display: "table", margin: "0 auto" }}>
                  <PrimaryButton
                    text="キャンセル"
                    onClick={() => {
                      history.goBack();
                    }}
                    ghost={true}
                  />
                </div>
              </FormSubmitArea>
            </div>
          </form>
        </div>

        <ConfirmModal
          title={allowanceId ? "手当マスタを更新" : "手当マスタを登録"}
          content={
            allowanceId ? "手当マスタを更新します。よろしいですか。" : "手当マスタを登録します。よろしいですか？"
          }
          submitText={allowanceId ? "更新" : "登録する"}
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          items={[]}
        />

        <ConfirmModal
          title="手当マスタを削除"
          content="手当マスタを削除します。よろしいですか？"
          submitText="削除する"
          open={confirmDeleteModalOpen}
          closeHandler={closeConfirmDeleteModal}
          onSubmit={deleteAttendAllowance}
          items={[]}
        />
      </FormContents>
    </BlockUI>
  );
};
export default AttendAllowanceCreateForm;
