/** @jsx jsx */
import React, { Dispatch, SetStateAction } from "react";
import { css, jsx } from "@emotion/core";
import Modal from "components/molecules/Modal";
import moment from "moment";

import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import { DetailItems } from "./hooks";

// create style form
const styles = {
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
        text-align: center;
      }
    `
  ),
};

export interface IDataDetail {
  isOpen: boolean;
  setIsClose: Dispatch<SetStateAction<boolean>>;
  detail: DetailItems;
}

const AcquisitionStatusFormDetail = ({
  isOpen,
  setIsClose,
  detail,
}: IDataDetail) => {
  return (
    <Modal
      open={isOpen}
      closeHandler={() => setIsClose(!isOpen)}
      title="取得状況詳細"
      submitText=""
      onSubmit={() => {}}
      closeText={"閉じる"}
    >
      <div
        style={{ width: "350px", margin: "0 auto", fontSize: "14px" }}
        css={styles.table}
      >
        <VerticalLabelTextLayout
          label="スタッフコード"
          variable={detail.staffCode}
        />
        <VerticalLabelTextLayout
          label="スタッフ名"
          variable={detail.staffName}
        />

        <VerticalLabelTextLayout
          label="期間"
          variable={`${moment(detail.fromDate).format(
            "YYYY年MM月DD日"
          )}～${moment(detail.toDate).format("YYYY年MM月DD日")}`}
        />
        
        {detail.effectiveStartDate && (
          <VerticalLabelTextLayout
            label="有給付与日"
            variable={`${moment(detail.effectiveStartDate).format(
              "YYYY年MM月DD日"
            )}`}
          />
        )}

        <table className="table-bordered" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>日付</th>
              <th>消化日数</th>
            </tr>
          </thead>
          <tbody>
            {detail.paidHolidayDigestionDetailList &&
              detail.paidHolidayDigestionDetailList.map((item: any) => (
                <tr>
                  <td>{moment(item.digestedDate).format("YYYY年MM月DD日")}</td>
                  <td>{item.digestedNum}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
export default AcquisitionStatusFormDetail;
