import { useState, useEffect, useCallback, useMemo } from 'react';
import HolidayWorkApplicationDomain from 'domain/master/attend/attendHolidayWorkApplication';
import { useHistory } from 'react-router-dom';
import { useOrgAllSelect } from 'hooks/useOrgTreesOptions';
import { getHolidayWork, postHolidayWork } from 'api/attendHolidayWorkApplication';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { getApplyingHistoryWorkListHistory } from 'api/getHistoryApplication';
import moment from 'moment';

import useOrgTreesOptions from 'hooks/useOrgTreesOptions';
import { getTreeCurrentByStaff, getTreesOrgCode, getTreesOrgCodeNoRole } from 'api/organization';
import { OptionType } from 'components/atoms/Select';

type HolidayWorkApplicationKey = keyof Pick<HolidayWorkApplicationDomain, 'applicationReason'>;

export const HolidayWorkApplicationForm = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [targetDate] = useState(sessionStorage.getItem('application.targetDate') || '');
  const [staffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectStaffCode] = useState(sessionStorage.getItem('application.staffCode') || '');
  const [dispStaffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem('application.orgCode') || '');
  const orgTreesOptions = useOrgAllSelect(false, moment(`${targetDate}`).format('YYYY-MM-DD'), moment(`${targetDate}`).format('YYYY-MM-DD'));
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [isGetHolidayWorkSuccees, setIsGetHolidayWorkSuccees] = useState(true);
  const { errorNotification, successNotification } = useToastNotification();
  const [selectChangelog, setSelectChangelog] = useState<string>("0");
  const [isEditHolidaywork] = useState(sessionStorage.getItem('application.editHolidaywork') || '');
  
  // useMemo(async() => { 
  //   await getTreesOrgCodeNoRole(String(targetDate), String(targetDate)).then((trees) => {
  //     getTreeCurrentByStaff(selectStaffCode, String(targetDate), String(targetDate)).then((orgCurrent) => {
  //       if(!selectOrgCode || orgCurrent && !isEditHolidaywork) {
  //         setSelectOrgCode(orgCurrent[0].orgCode);
  //       }
  //     });
  //     const listOrgCode = trees.map((tree) => ({
  //       value: tree.orgCode,
  //       label: `${tree.orgCode} ${tree.orgName}`,
  //     }));
  //     setOrgTreesOptions(listOrgCode);
  //     setIsReloader(true);
  //   });
  // },[])

  const onSubmit = async (values: HolidayWorkApplicationDomain) => {

    if (values.applicationReason === null) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmApplyModalOpen) {
      setConfirmApplyModalOpen(true);
      return;
    }

    const {
      modifierApplicationId, version, orgName, buttonType, applicationReason, staffCode, targetDateStr,
    } = values.getRawData();

    const dataPost = {
      modifierApplicationId: modifierApplicationId || '',
      version: version || '',
      orgCode: selectOrgCode,
      orgName,
      staffCode,
      buttonType,
      targetDateStr,
      updateUserCode: sessionStorage.getItem('loginUser.staffCode') || '',
      applicationStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
      applicationReason,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };
    
    try {
      setLoading(true);
      setConfirmApplyModalOpen(false);
      const response = await postHolidayWork(dataPost);
      if (!response.errors) {
        sessionStorage.setItem('highlightAction', 'true');
        history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '');
        successNotification('申請しました。');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmApplyModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: HolidayWorkApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const fetchChangeLogHolidayWork = async (
    orgCode?: string,
    targetDate?: string,
    staffCode?: string
  ): Promise<any[]> => {
    return await getApplyingHistoryWorkListHistory({ orgCode, targetDate, staffCode });
  };

  useEffect(() => {
    setLoading(true);
    getHolidayWork({
      orgCode: selectOrgCode,
      targetDate,
      staffCode: selectStaffCode,
    }).then((data: any) => {
      formik.setValues(new HolidayWorkApplicationDomain(data));
      setLoading(false);
    }).catch(() => {
      setIsGetHolidayWorkSuccees(false);
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgCode]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  return {
    formik,
    orgTreesOptions,
    selectOrgCode,
    setSelectOrgCode,
    staffName,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetHolidayWorkSuccees,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogHolidayWork,
  };
};

export default HolidayWorkApplicationForm;
