/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Checkbox from 'components/molecules/Checkbox';
import BodyText from 'components/atoms/BodyText';
import CalendarMonth from 'components/molecules/CalendarV1/CalendarMonth';

const styles = {
  allEmployment: css({},
    css` 
   .checkbox-form span{
     font-size:14px;
     color:#999999
   } 
  `
  )
}

const ApplyDateFilter: React.FC<{
  allEmployment: boolean;
  toggleAllEmployment: () => void;
  applyDate: Date;
  // applyDateStr:string
  setApplyDate: (date: Date) => void;
  id?: string;
  defaultValue?: any
}> = ({
  allEmployment, toggleAllEmployment, applyDate, setApplyDate, id= '0', defaultValue 
}) => {

  useEffect(() => {
    if(applyDate !== null){
      sessionStorage.setItem(`${id}/sessionData${window.location.pathname}.selectApplyDateStaffMaster`, String(applyDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyDate])

  useEffect(() => {
    if(allEmployment !== null){
      sessionStorage.setItem(`${id}/sessionData${window.location.pathname}.selectAllEmploymentStaffMaster`, String(allEmployment));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployment])

  useEffect(() => {
    let session = sessionStorage.getItem(`${id}/sessionData${window.location.pathname}.selectApplyDateStaffMaster`);
    if(session !== null){
      setApplyDate(new Date(session));  
    }else{
      setApplyDate(defaultValue || "");
    }

    let sessionAllEmployment = sessionStorage.getItem(`${id}/sessionData${window.location.pathname}.selectAllEmploymentStaffMaster`);
    if(sessionAllEmployment === 'true'){
      toggleAllEmployment(); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return(
    <FlexBox
      customStyle={css({
        padding: '24px 8px 8px 0px',
      })}
    >
      <FlexBoxItem>
        <BodyText>適用年月</BodyText>
      </FlexBoxItem>
      <FlexBoxItem marginLeft="20px">
        <CalendarMonth 
          date={applyDate}
          setDate={setApplyDate}
        />
      </FlexBoxItem>
      <FlexBoxItem marginLeft="24px" customStyle={styles.allEmployment}>
        <div className="checkbox-form">
          <Checkbox
            id="allEmployment"
            name="allEmployment"
            value="allEmployment"
            label="全ての雇用形態を表示"
            checked={allEmployment!}
            onChange={toggleAllEmployment}
          />
        </div>
      </FlexBoxItem>
    </FlexBox>
  )
     
    }

export default ApplyDateFilter;
