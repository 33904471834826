
import { useState, useEffect, useCallback  } from 'react';

import { getList } from 'api/paidGrantPattern';
import PaidGrantPatternDomain from 'domain/master/labor/paidGrantPattern';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const usePaidGrantList = () => {
  const [paintGrant, setpaintGrant] = useState<Array<PaidGrantPatternDomain>>([]);
  const [isLoading, setLoading] = useState(true);
  const { detailRole } = useGetDetailRole(setLoading, functionCode.masterPaidHolidayPattern);

  const fetchData = useCallback(async () => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const getDetails = false;
    setLoading(true);
    const response = await getList(companyCode, getDetails);
    setpaintGrant(response.map((result: any) => new PaidGrantPatternDomain(result)));
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // useEffect(() => {
  //   let isSubscribed = true;
  //   const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  //   const getDetails = false;
  //   getList(companyCode, getDetails).then((response: Array<any>) => {
  //     if (isSubscribed) {
  //       setpaintGrant(response.map((result) => new PaidGrantPatternDomain(result)));
  //     }
  //   });
  //   return () => { isSubscribed = false; };
  // }, []);
  return { paintGrant, setpaintGrant, isLoading, setLoading, detailRole };
};


export default usePaidGrantList;
