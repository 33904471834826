/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';

import productColor, { grayScale } from 'components/styles';
import ClickableLink from 'components/atoms/ClickableLink';
import Icon, { IconType } from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';

interface IconLabelButtonProps {
  onClick: () => void;
  iconType?: IconType;
  iconColor?: string;
  text?: string;
  padding?: string
  margin?: string;
  disabled?: boolean;
  textColor?:string;
  isIcon?: boolean;
  textAlign?: string;
  width?: string;
}

const styles = {
  button: css`
    button:hover{
      background-color: transparent
    }
  `
}

const IconLabelButton: React.FC<IconLabelButtonProps> = ({
  onClick, iconType = "addCircle", iconColor, text, padding, margin, disabled, textColor, isIcon = true, textAlign = "left", width
}) => {
  const [isHover, setIsHover] = useState(false);
  
  return (
  <div onMouseEnter={(e) => setIsHover(true)} onMouseLeave={(e) => setIsHover(false)} css={css([styles.button, {width: width}])}>
    <ClickableLink customCss={css(width ? {width: width} : {})} onClick={onClick} padding={padding} margin={margin} disabled={disabled} textAlign={textAlign}>
      {
        isIcon && <Icon isHover={isHover} type={iconType} color={ iconColor || productColor.primary} disabled={disabled}/>
      }
      {text && (
        <BodyText customStyle={css({ 
          marginLeft: isIcon ? '8px' : '0px', 
          color: disabled ? grayScale.gray60 : isHover ? productColor.primaryHoverText : (textColor || productColor.primary) })} 
          size="re">
          {text}
        </BodyText>
      )}
    </ClickableLink>
  </div>
)};

export default IconLabelButton;
