import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import HolidayImportForm from 'components/organismos/master/labor/HolidayImportForm';

const HolidayImportPage: React.FC = () => (
  <SidebarTemplate>
    <HolidayImportForm />
  </SidebarTemplate>
);

export default HolidayImportPage;
