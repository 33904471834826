/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { css, jsx } from "@emotion/core";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { SubActionButton } from "components/atoms/Button";
import ToastModal from "components/organismos/ToastModal";
import DataTable from "components/organismos/DataTable/DataTable";
import ConfirmModal from "components/organismos/ConfirmModal";
import FormContents from "components/atoms/Form/FormContents";
import IconLabelButton from "components/molecules/IconLabelButton";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import AttendanceMasterDomain from "domain/master/attend/attendanceMaster";
import { useAttendList, useOrganizationList } from "./hooks";
import ReactTooltip from "react-tooltip";
import { getAttendStampIpList } from "api/attendanceLayout";
import { renderError } from "utility/renderMessage";

const styles = {
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
};
const customStyle = {
  marginBottom: "30px",
  borderRadius: "5px",
};
const layout = css`
  table tr th {
    text-align: left;
  }
  table tr th:nth-child(1) {
    width: 110px;
  }
  table tr th:nth-child(3) {
    width: 650px;
  }
  .button-action button:hover {
    background-color: transparent;
  }
`;

const table = css`
  table {
    table-layout: auto;
  }
  table th:nth-of-type(1) {
    width: 140px;
  }
  @media only screen and (max-width: 1200px) {
    table th:nth-of-type(1) {
      width: 190px;
    }
  }
  table th:nth-of-type(2) {
    width: 160px;
    text-align: left;
  }
  table th:nth-of-type(3) {
    text-align: left;
  }
`;

const AttendanceLayoutList = () => {
  const {
    selectedAttendanceLayout,
    attendanceLayoutList,
    deleteStampSettingIP,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    detailRole,
    toastMessage,
    isLoading,
    closeConfirmModal,
    openConfirmModal,
  } = useAttendList();

  const columns: Array<Column<AttendanceMasterDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendanceMasterDomain };
          data: Array<AttendanceMasterDomain>;
        }) => {
          return (
            <div
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: "130px",
              }}
            >
              <Link to={`/stampIpSetting/edit/${cell.row.original.stampIpId}`}>
                <SubActionButton
                  action={0}
                  text={detailRole.editable === 1 ? `編集` : `参照`}
                  onClick={() => console.log(1)}
                />
              </Link>
              {detailRole.editable === 1 ? (
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    openConfirmModal(cell.row.original);
                  }}
                >
                  <SubActionButton
                    action={1}
                    text="削除"
                    onClick={() => console.log(1)}
                  />
                </span>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: "IPアドレス",
        accessor: "ipAddress",
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendanceMasterDomain };
          data: Array<AttendanceMasterDomain>;
        }) => {
          return (
            <span>
              {cell.row.original.ipAddress + "/" + cell.row.original.subnetMask}
            </span>
          );
        },
      },
      {
        Header: "組織名",
        accessor: "orgNameList",
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendanceMasterDomain };
          data: Array<AttendanceMasterDomain>;
        }) => {
          return (
            <React.Fragment>
              <div
                data-tip={
                  cell.row.original.orgNameList === "全ての組織"
                    ? ""
                    : cell.row.original.orgNameList
                }
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "671px",
                }}
              >
                {cell.row.original.orgNameList}
              </div>
              <ReactTooltip html={true} css={{ maxWidth: "500px" }} />
            </React.Fragment>
          );
        },
      },
    ],
    [openConfirmModal, detailRole]
  );

  return (
    <BlockUI blocking={isLoading}>
      <React.Fragment>
        <div css={layout}>
          <FormContents customStyle={customStyle}>
            <FormTitle bold={true} title="打刻設定" />
            {detailRole.editable === 1 && (
              <div
                style={{
                  marginLeft: "27px",
                  width: "80px",
                  paddingBottom: "30px",
                }}
              >
                <Link
                  className="button-action"
                  to="/stampIpSetting/add"
                  style={{
                    textDecoration: "none",
                    color: "#007BC3",
                    display: "inline-block",
                  }}
                >
                  <IconLabelButton
                    onClick={() => console.log("edit")}
                    iconType="addCircle"
                    text="追加"
                  />
                </Link>
              </div>
            )}
            <div css={table}>
              <div
                className="table-bordered"
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                <DataTable
                  columns={columns}
                  data={attendanceLayoutList}
                  isGlobalFilter={true}
                  globalSearchStyle={styles.filterSearch}
                  // isHiddenPagination={true}
                />
              </div>
            </div>
            <ConfirmModal
              open={confirmModalOpen}
              closeHandler={closeConfirmModal}
              onSubmit={() =>
                deleteStampSettingIP(selectedAttendanceLayout.stampIpId)
              }
              title="IPアドレスの削除"
              content="IPアドレスを削除します。よろしいですか？"
              submitText="削除する"
              items={[]}
              typeSubmit={`reject`}
            />
            <ToastModal
              open={toastModalOpen}
              closeHandler={() => setToastModalOpen(false)}
              title={toastMessage}
            />
          </FormContents>
        </div>
      </React.Fragment>
    </BlockUI>
  );
};

export default AttendanceLayoutList;
