import { useState, useEffect, useCallback } from "react";
import { OptionType } from "components/atoms/Select";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import AttendHolidayApplicationDomain from "domain/master/attend/holidayApplication";
import {
  getHolidays,
  getCurrentHolidayApplication,
  getEmployment,
  createHolidayApplicaiton,
  getAttendHolidayList,
  getSubstituteList,
} from "api/attendHolidayApplicaiton";
import useToastNotification from "hooks/useToastNotification";
import { getApplyingHolidayListHistory } from "api/getHistoryApplication";
import { useOrgAllSelect } from "hooks/useOrgTreesOptions";

const weekDay = ["日", "月", "火", "水", "木", "金", "土"];
const publicHoliday = 0;
const otherThanSubstituteHoliday = 2;
const substituteHoliday = 3;

export const useHolidayApplicationForm = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem("application.orgCode") || "");
  const [targetDate] = useState(sessionStorage.getItem("application.targetDate") || "");
  const [targetDateFrom] = useState(sessionStorage.getItem("application.targetDate") || "");
  const [targetDateTo, setTargetDateTo] = useState(sessionStorage.getItem("application.targetDate") || "");
  const [staffName] = useState(sessionStorage.getItem("application.dispStaffName") || "");
  const [staffCode] = useState(sessionStorage.getItem("application.staffCode") || "");
  const [holidayOptions, setHolidayOptions] = useState<Array<OptionType>>([]);
  const [holidayUnitOptions, setHolidayUnitOptions] = useState<Array<OptionType>>([]);
  const [toastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);

  let ssAchievementId: any = "";
  if (
    sessionStorage.getItem("application.achievementId") &&
    sessionStorage.getItem("application.achievementId") != "null"
  ) {
    ssAchievementId = sessionStorage.getItem("application.achievementId");
  }
  const [achievementId] = useState(ssAchievementId || "");
  const [employment, setEmployment] = useState(Object()); // !! It is not being used
  const targetYmStr = moment(`${targetDateFrom}`, "YYYY-MM-DD").format("YYYY年MM月");

  const [attendSetting, setAttendSetting] = useState(Object());
  const [currentApplid, setCurrentApplid] = useState(Object());
  const [attendHolidayList, setAttendHolidayList] = useState([Array()]);
  const [substituteOptions, setSubstituteOptions] = useState<Array<OptionType>>([]);
  const [holidayDigestiveUnit, setHolidayDigestiveUnit] = useState("0");
  const [selectedTimeHoliday, setSelectedTimeHoliday] = useState(false);
  const [employmentId, setEmploymentId] = useState("");
  const [applyStartDate, setApplyStartDate] = useState("");
  const [deemedMorningHalfHours, setDeemedMorningHalfHours] = useState(0);
  const [deemedAfternoonHalfHours, setDeemedAfternoonHalfHours] = useState(0);
  const [updateUser] = useState(sessionStorage.getItem("loginUser.staffCode") || "");
  const [selectedHoliday, setSelectedHoliday] = useState(Object());
  const [substituteList, setSubstituteList] = useState<any>();
  const [holidayList, setHolidayList] = useState<Array<any>>([]);
  const [loadDoneCurrentApplication, setLoadDoneCurrentApplication] = useState<boolean>(false);
  const [startTime, setStartTime] = useState("");
  const [setStampTime] = useState("");
  const [selectChangelog, setSelectChangelog] = useState<string>("0");

  const [selectedSubstitute, setSelectedSubstitute] = useState("");
  const { successNotification, errorNotification } = useToastNotification();
  const [holidayStartDate, setHolidayStartDate] = useState("");
  const [holidayEndDate, setHolidayEndDate] = useState("");
  const orgTreesOptions = useOrgAllSelect(
    false,
    moment(`${targetDate}`).format("YYYY-MM-DD"),
    moment(`${targetDate}`).format("YYYY-MM-DD"),
  );
  const [isReloader, setIsReloader] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  const fetchChangeLogHoliday = async (applicationId?: string): Promise<any[]> => {
    return await getApplyingHolidayListHistory({ applicationId });
  };

  const fetchData = async () => {
    // getHolidays
    await handelGetHolidays();

    // getEmployment
    await handelGetEmployment();

    // getCurrentHolidayApplication
    await handleGetCurrentHolidayApplication();

    // getSubstituteList
    await handelGetSubstituteList();
  };

  const onSubmit = async (values: AttendHolidayApplicationDomain) => {
    let flagError = false;
    let msgError = "";
    if (values.applicationReason === "") {
      flagError = true;
      msgError += "申請理由を入力してください。<br />";
    }

    formik.setFieldValue("holidayId", selectedHoliday.value);
    formik.setFieldValue("orgCode", orgCode);

    let postData = values.getRawData();
    postData.fromDate = values.fromDate;
    postData.toDate = values.toDate;
    postData.targetDate = values.targetDate;
    postData.halfHolidayStartTimeNextDay = values.halfHolidayStartTimeNextDay;
    postData.halfHolidayEndTimeNextDay = values.halfHolidayEndTimeNextDay;
    let halfHolidayStartTimeHour = 0;
    let halfHolidayEndTimeHour = 0;

    if (postData.holidayUnit == 2) {
      if (values.stampTime === "") {
        flagError = true;
        msgError += "取得時間を入力してください。";
      }
    }

    if (postData.holidayUnit && [3, 4].indexOf(Number(postData.holidayUnit)) !== -1) {
      halfHolidayStartTimeHour =
        Number(postData.halfHolidayStartTimeHour) + (!postData.halfHolidayStartTimeNextDay ? 0 : 24);
      halfHolidayEndTimeHour =
        Number(postData.halfHolidayEndTimeHour) + (!postData.halfHolidayEndTimeNextDay ? 0 : 24);
      if (
        Number(halfHolidayStartTimeHour) > Number(halfHolidayEndTimeHour) ||
        (Number(halfHolidayStartTimeHour) === Number(halfHolidayEndTimeHour) &&
          Number(postData.halfHolidayStartTimeMinute) >= Number(postData.halfHolidayEndTimeMinute))
      ) {
        errorNotification("終了時間の大小関係が不正です。");
        return;
      }
    }

    if (flagError === true) {
      errorNotification(msgError);
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    if (postData.holidayUnit == 2) {
      postData.stampStartTime = postData.startHour + ":" + postData.startTime;
    }
    if (postData.holidayUnit && [3, 4].indexOf(Number(postData.holidayUnit)) !== -1) {
      postData.halfHolidayStartTime = postData.halfHolidayStartTimeHour + ":" + postData.halfHolidayStartTimeMinute;
      postData.halfHolidayEndTime = postData.halfHolidayEndTimeHour + ":" + postData.halfHolidayEndTimeMinute;
    } else {
      postData.halfHolidayStartTimeHour = "00";
      postData.halfHolidayStartTimeMinute = "00";
      postData.halfHolidayEndTimeHour = "00";
      postData.halfHolidayEndTimeMinute = "00";
      postData.halfHolidayStartTimeNextDay = false;
      postData.halfHolidayEndTimeNextDay = false;
    }

    //submit form
    try {
      setLoading(true);
      setConfirmModalOpen(false);
      const response = await createHolidayApplicaiton(postData);
      if (!response.errors) {
        sessionStorage.setItem("highlightAction", "true");
        history.push(sessionStorage.getItem("attendStampList.returnDestination")! || "");
      }
      successNotification("申請しました。");
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    setLoading(false);
    setConfirmModalOpen(false);
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = "";
      error.response.data.errors.map((item: { defaultMessage: string }) => {
        msgError += `${item.defaultMessage} <br />`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification("サーバー側でエラーが発生しました。");
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: AttendHolidayApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const handelGetHolidays = async () => {
    let params = {
      staffCode,
      targetDateStr: targetDateFrom,
    };
    let response = await getHolidays(params);
    console.log("insideGetHolidays: ", formik.values.fromDate !== formik.values.toDate);
    if (response.length) {
      const isPeriod = formik.values.fromDate !== formik.values.toDate;
      const excludeHolidays = isPeriod
        ? [publicHoliday, otherThanSubstituteHoliday]
        : [publicHoliday, substituteHoliday];
      let holidayList: any = response.filter((item: any) => !excludeHolidays.includes(item.holidayType));
      setHolidayList(holidayList);
      let firstHoliday: any = {};
      if (holidayList[0]) {
        firstHoliday = {
          value: !holidayList[0].holidayId ? "" : holidayList[0]?.holidayId,
          label: !holidayList[0].holidayName ? "" : holidayList[0]?.holidayName,
          holidayType: !holidayList[0].holidayType ? "" : holidayList[0]?.holidayType,
          autoGrantHoliday: !holidayList[0].autoGrantHoliday ? "" : holidayList[0]?.autoGrantHoliday,
          paidHalfHolidayStartTime: !holidayList[0].paidHalfHolidayStartTime
            ? ""
            : holidayList[0]?.paidHalfHolidayStartTime,
          paidHalfHolidayEndTime: !holidayList[0].paidHalfHolidayEndTime ? "" : holidayList[0]?.paidHalfHolidayEndTime,
        };
      }

      if (firstHoliday.value) {
        formik.setFieldValue("holidayId", firstHoliday.value);
      }

      getHolidayUnitOption(holidayList[0]);
      setSelectedHoliday(firstHoliday);
      let holidays = holidayList.map((holiday: any) => ({
        value: holiday.holidayId === null ? "" : holiday.holidayId,
        label: holiday.holidayName === null ? "" : holiday.holidayName,
      }));
      setHolidayOptions(holidays);
      let tmpHoliday = holidayList.map((holiday: any) => ({
        value: holiday.holidayId === null ? "" : holiday.holidayId,
        label: holiday.holidayName === null ? "" : holiday.holidayName,
        holidayType: holiday.holidayType === null ? "" : holiday.holidayType,
        autoGrantHoliday: holiday.autoGrantHoliday === null ? "" : holiday.autoGrantHoliday,
        paidHalfHolidayStartTime: holiday.paidHalfHolidayStartTime === null ? "" : holiday.paidHalfHolidayStartTime,
        paidHalfHolidayEndTime: holiday.paidHalfHolidayEndTime === null ? "" : holiday.paidHalfHolidayEndTime,
        unUsableOfHalfHoliday: holiday.unUsableOfHalfHoliday,
        unUsableOfTimeHoliday: holiday.unUsableOfTimeHoliday,
      }));
      setHolidays(tmpHoliday);
    }
  };

  const handelGetEmployment = async () => {
    try {
      let employmentParams = {
        orgCode,
        staffCode,
        targetYmStr,
        targetDateFrom: moment(targetDateFrom).format("YYYY年MM月DD日"),
        targetDateTo: moment(targetDateFrom).format("YYYY年MM月DD日"),
      };
      let response: any = await getEmployment(employmentParams);
      if (response && response.id.employmentId) {
        setEmployment(response);
        setEmploymentId(response.id.employmentId);
        setApplyStartDate(response.id.applyStartDate);
        setDeemedMorningHalfHours(response.deemedMorningHalfHours);
        setDeemedAfternoonHalfHours(response.deemedAfternoonHalfHours);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleGetCurrentHolidayApplication = async () => {
    try {
      let params = {
        staffCode,
        achievementId,
        targetDate: targetDateFrom,
      };
      let response: any = await getCurrentHolidayApplication(params);
      console.log("getCurrentHolidayApplication: ", response); // null on many fields
      if (response) {
        handleGetAttendHolidayList();
        setCurrentApplid(response);
        let selectedHolidayUnit = response.holidayDigestiveUnit;
        if (selectedHolidayUnit === 2) {
          setSelectedTimeHoliday(true);
        }
        setHolidayDigestiveUnit(selectedHolidayUnit);

        let tmpAchievementId = response.achievementId !== null ? response.achievementId : "";
        let tmpModifierApplicationId = response.modifierApplicationId !== null ? response.modifierApplicationId : "";
        let tmpVersion = response.version !== null ? response.version : "";
        let tmpApplicationReason = response.applicationReason !== null ? response.applicationReason : "";

        // let tmpHolidayDigestiveUnit = response.holidayDigestiveUnit !== null ? response.holidayDigestiveUnit : 0;
        let tmpStartHour = response.holidayStartHm !== null ? moment(response.holidayStartHm).format("HH") : "00";
        let tmpStartTime = response.holidayStartHm !== null ? moment(response.holidayStartHm).format("mm") : "00";
        let tmpHalfHolidayStartTimeHour =
          (response.holidayStartHm && holidayDigestiveUnit) !== null
            ? moment(response.holidayStartHm).format("HH")
            : "00";
        let tmpHalfHolidayStartTimeMinute =
          (response.holidayStartHm && holidayDigestiveUnit) !== null
            ? moment(response.holidayStartHm).format("mm")
            : "00";
        let tmpHalfHolidayEndTimeHour =
          (response.holidayEndHm && holidayDigestiveUnit) !== null ? moment(response.holidayEndHm).format("HH") : "00";
        let tmpHalfHolidayEndTimeMinute =
          (response.holidayEndHm && holidayDigestiveUnit) !== null ? moment(response.holidayEndHm).format("mm") : "00";

        let tmpNextDay =
          response.holidayStartHm === null || targetDateFrom != response.holidayStartHm.split("T")[0]
            ? response.holidayTimes
            : false;
        let tmpHolidayTimes = response.holidayTimes !== null ? response.holidayTimes : "";

        let tmpHolidayStartDate = moment(response.holidayStartHm).format("YYYY-MM-DD") || "";
        let tmpHolidayEndDate = moment(response.holidayEndHm).format("YYYY-MM-DD") || "";

        formik.setFieldValue("staffCode", staffCode);
        formik.setFieldValue("orgCode", orgCode);
        formik.setFieldValue("achievementId", tmpAchievementId);
        formik.setFieldValue("modifierApplicationId", tmpModifierApplicationId);
        formik.setFieldValue("modifiyStatus", response.modifiyStatus);
        formik.setFieldValue("version", tmpVersion);
        formik.setFieldValue("holidayId", response.holidayId);
        formik.setFieldValue("holidayUnit", response.holidayDigestiveUnit);
        formik.setFieldValue("updateUser", updateUser);
        formik.setFieldValue("applicationReason", tmpApplicationReason);

        formik.setFieldValue("startHour", tmpStartHour);
        formik.setFieldValue("startTime", tmpStartTime);
        formik.setFieldValue("halfHolidayStartTimeHour", tmpHalfHolidayStartTimeHour);
        formik.setFieldValue("halfHolidayStartTimeMinute", tmpHalfHolidayStartTimeMinute);
        formik.setFieldValue("halfHolidayEndTimeHour", tmpHalfHolidayEndTimeHour);
        formik.setFieldValue("halfHolidayEndTimeMinute", tmpHalfHolidayEndTimeMinute);
        formik.setFieldValue("nextDay", tmpNextDay);
        formik.setFieldValue("stampTime", tmpHolidayTimes);

        setHolidayStartDate(tmpHolidayStartDate);
        setHolidayEndDate(tmpHolidayEndDate);

        setLoadDoneCurrentApplication(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (holidayList.length > 0) {
      if (formik.values.holidayId && loadDoneCurrentApplication) {
        let holidayCurrent = holidayList.filter((holiday: any) => {
          return holiday.holidayId === formik.values.holidayId;
        })[0];
        if (holidayCurrent) {
          getHolidayUnitOption(holidayCurrent);
        }
        setLoadDoneCurrentApplication(false);
      }
    }
  }, [holidayList, formik.values.holidayId, loadDoneCurrentApplication]);

  const handleGetAttendHolidayList = async () => {
    try {
      let params = {
        achievementId,
        staffCode,
        targetDate: targetDateFrom,
      };
      let response = await getAttendHolidayList(params);
      if (response) {
        setAttendHolidayList(response);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handelGetSubstituteList = async () => {
    try {
      let params = {
        staffCode,
        targetDate: targetDateFrom,
      };

      let response: any = await getSubstituteList(params);
      if (response) {
        //setSubstituteOptions
        setSubstituteList(response);
        setSubstituteOptions(
          response.map((substitute: any) => ({
            value: `${substitute.holidaySubstituteGrantHistoryId}`,
            label: `${substituteDispFormatter(substitute.holidayWorkDate, substitute.effectiveEndDate)}`,
          })),
        );

        let tmpSelectedSubstitute = "";
        let result = response
          .filter((substitute: any) => substitute.substituteHolidayDate)
          .map((substitute: any) => substitute.holidaySubstituteGrantHistoryId);
        if (result.length <= 0) {
          tmpSelectedSubstitute = response[0].holidaySubstituteGrantHistoryId;
        } else {
          tmpSelectedSubstitute = result[0];
        }
        setSelectedSubstitute(tmpSelectedSubstitute);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const substituteDispFormatter = (holidayWorkDate: string, effectiveEndDate: string) => {
    let holidayWorkDateStr = moment(holidayWorkDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
    let effectiveEndDateStr = moment(effectiveEndDate, "YYYY-MM-DD").format("YYYY年MM月DD日");
    return holidayWorkDateStr + "(有効期限：" + effectiveEndDateStr + ")";
  };

  const getHolidayUnitOption = (holidayForm: any) => {
    let tmpSelectSubstitute;
    const isPeriod = formik.values.fromDate !== formik.values.toDate;
    if (formik.values.substituteId && substituteList) {
      tmpSelectSubstitute = substituteList.filter(
        (substitute: any) => substitute.holidaySubstituteGrantHistoryId === formik.values.substituteId,
      )[0];
    }
    const holidayUnitFullDay = [
      {
        value: "0",
        label: "全休",
      },
    ];
    const holidayUnitByTime = [
      {
        value: "2",
        label: "時間休",
      },
    ];
    const holidayUnitHalfDay = [
      {
        value: "3",
        label: "午前休",
      },
      {
        value: "4",
        label: "午後休",
      },
    ];

    let holidayOptions: any = [];
    if (!tmpSelectSubstitute || tmpSelectSubstitute.isDigestedStatus !== 1 || holidayForm.holidayType !== 2) {
      holidayOptions = [...holidayOptions, ...holidayUnitFullDay];
    }

    if (holidayForm?.unUsableOfHalfHoliday && !isPeriod) {
      holidayOptions = [...holidayOptions, ...holidayUnitHalfDay];
    }

    if (holidayForm?.unUsableOfTimeHoliday && !isPeriod) {
      holidayOptions = [...holidayOptions, ...holidayUnitByTime];
    }

    setHolidayUnitOptions(holidayOptions);
    if (isPeriod) {
      formik.setFieldValue("holidayUnit", holidayOptions[0].value);
    }
    return holidayOptions;
  };

  useEffect(() => {
    if (Object.keys(currentApplid).length !== 0 && substituteList) {
      let tmp = substituteList.filter(
        (substitute: any) => substitute.holidayWorkDate === currentApplid.holidayWorkDate,
      )[0];
      if (tmp) {
        setSelectedSubstitute(tmp.holidaySubstituteGrantHistoryId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApplid, substituteList]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    isLoading,
    formik,
    targetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffName,
    weekDay,
    fetchData,
    handelGetHolidays,
    holidayOptions,
    holidayUnitOptions,
    toastModalOpen,
    toastMessage,
    closeConfirmModal,
    confirmModalOpen,
    setToastModalOpen,
    holidayDigestiveUnit,
    employment,
    attendSetting,
    currentApplid,
    attendHolidayList,
    selectedTimeHoliday,
    startTime,
    employmentId,
    applyStartDate,
    deemedMorningHalfHours,
    deemedAfternoonHalfHours,
    selectedHoliday,
    holidays,
    setSelectedHoliday,
    setStampTime,
    onSubmit,
    substituteOptions,
    selectedSubstitute,
    setHolidayUnitOptions,
    getHolidayUnitOption,
    holidayList,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogHoliday,
    setSelectedSubstitute,
    substituteList,
    holidayStartDate,
    holidayEndDate,
    orgTreesOptions,
    orgCode,
    setOrgCode,
    setIsReloader,
  };
};

export default useHolidayApplicationForm;
