import { useState, useCallback, useEffect, Dispatch, useContext } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import StaffDomain from "domain/staff";
import { postSalaryInfo } from "api/staff";
import { SalarySupportStaff, SalaryTermStaff } from "domain/master/general/salaryDataStaff";
import { OptionType } from "components/atoms/Select";
import { LoadingContextAdd } from "components/pages/master/general/staff/StaffAddPage/hooks";

export const useSalaryForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>,
) => {
  const history = useHistory();
  const contextObject = useContext(LoadingContextAdd);
  const [openOrganizationAddModal, setOpenOrganizationAddModal] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async (values: StaffDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      if (setIsLoading) {
        setIsLoading(true);
      }
      const response = await postSalaryInfo(isEditMode ? 1 : 0, values.getStaffInfoPostData(isEditMode));

      setConfirmModalOpen(false);

      const updatedStaff = new StaffDomain(response.staff);

      // 以下の項目がレスポンスだと初期化されているため、既存の値を再セット
      updatedStaff.concurrentList = formik.values.concurrentList;
      updatedStaff.icCardListData = formik.values.icCardListData;
      updatedStaff.stateStatus = formik.values.stateStatus;
      updatedStaff.useRoleList = formik.values.useRoleList;
      updatedStaff.stateStatusDisp = formik.values.stateStatusDisp;
      updatedStaff.selectSalary = updatedStaff.selectSalary;

      formik.setValues(updatedStaff);

      if (!isEditMode) {
        sessionStorage.setItem("selectedOrgCodeStaffMaster", updatedStaff.orgCode);
      }
      successNotification(isEditMode ? "更新しました。" : "登録しました。");
      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStaffDomain]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const addOrganizationSupport = useCallback(
    (selectedOrg: OptionType) => {
      let tmpConcurrentList = [...formik.values.selectSalary.salarySupportDataList];
      tmpConcurrentList.push({
        staffSalaryId: "",
        orgCode: selectedOrg.value,
        orgName: selectedOrg.label.trim(),
        addHourlySalary: 0,
        supportTransportation: 0,
      });
      formik.setFieldValue("selectSalary.salarySupportDataList", tmpConcurrentList);
    },
    [formik],
  );

  const deleteOrganizationSupport = useCallback(
    (index: number) => () => {
      if (!formik.values.selectSalary.salarySupportDataList) {
        return;
      }
      formik.setFieldValue(
        "selectSalary.salarySupportDataList",
        formik.values.selectSalary.salarySupportDataList.filter((manager, idx) => idx !== index),
      );
    },
    [formik],
  );

  const setOrganizationSupport = useCallback(
    (index: number) => (newManager: SalarySupportStaff) => {
      if (!formik.values.selectSalary.salarySupportDataList) {
        return;
      }
      formik.setFieldValue(
        "selectSalary.salarySupportDataList",
        formik.values.selectSalary.salarySupportDataList.map((manager, idx) => (idx === index ? newManager : manager)),
      );
    },
    [formik],
  );

  // term form
  const addSalaryTerm = useCallback(() => {
    if (!formik.values.selectSalary.salaryTermList) {
      return;
    }
    const salaryTermData: SalaryTermStaff = {
      id: {
        staffSalaryId: "",
        termId: "",
      },
      addHourlySalary: 0,
    };
    formik.setFieldValue(
      "selectSalary.salaryTermList",
      formik.values.selectSalary.salaryTermList.concat(salaryTermData),
    );
  }, [formik]);

  const deleteSalaryTerm = useCallback(
    (index: number) => () => {
      if (!formik.values.selectSalary.salaryTermList) {
        return;
      }
      formik.setFieldValue(
        "selectSalary.salaryTermList",
        formik.values.selectSalary.salaryTermList.filter((manager, idx) => idx !== index),
      );
    },
    [formik],
  );

  const setSalaryTerm = useCallback(
    (index: number) => (newManager: SalaryTermStaff) => {
      if (!formik.values.selectSalary.salaryTermList) {
        return;
      }
      formik.setFieldValue(
        "selectSalary.salaryTermList",
        formik.values.selectSalary.salaryTermList.map((manager, idx) => (idx === index ? newManager : manager)),
      );
    },
    [formik],
  );

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    openOrganizationAddModal,
    setOpenOrganizationAddModal,
    addOrganizationSupport,
    deleteOrganizationSupport,
    setOrganizationSupport,
    addSalaryTerm,
    deleteSalaryTerm,
    setSalaryTerm,
    contextObject,
  };
};

export default {
  useSalaryForm,
};
