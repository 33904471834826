import React, { useCallback, useEffect, useState } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FormPadding from "components/atoms/Form/FormPadding";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Row, Col } from "react-grid-system";
import { css } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import BlockUI from "components/molecules/BlockUi";
import moment from "moment";
import { useStampListForm, eventScroll, downloadOriginalPDF, attendStampContext, useIntialState } from "./hooks";
import { achievementConfirmStamp, postCopyAchievement } from "api/achievementConfirm";
import MonthHourTable from "./MonthHourTable";
import MonthDayTable from "./MonthDayTable";
import PaidHolidayGranTable from "./PaidHolidayGranTable";
import SpecialHolidayGrantTable from "./SpecialHolidayGrantTable";
import SubstituteHolidayTable from "./SubstituteHolidayTable";
import TransferHolidayTable from "./TransferHolidayTable";
import AttendStampTable from "./AttendStampTable";
import AttendStampTableNoData from "./AttendStampTableNoData";
import { productColor } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import NursingCareHolidayDataTable from "./NursingCareHolidayDataTable";
import NursingHolidayDataTable from "./NursingHolidayDataTable";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import { exportFilePDFv2 } from "api/attendAchievementOutput";
import useToastNotification from "hooks/useToastNotification";
import Icon from "components/atoms/Icon";
import SelectForm from "components/molecules/SelectForm";

import PrimaryButton, { SecondaryButton } from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { renderError } from "utility/renderMessage";
import useIsMobile from "hooks/useIsMobile";
import AttendStampMobileTable from "components/organismos/master/attend/AttentStampForm/AttendStampMobileTable";
import AttendStampMobileTableNoData from "components/organismos/master/attend/AttentStampForm/AttendStampMobileTableNoData";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useHistory } from "react-router-dom";
import AllowanceTable from "components/organismos/master/attend/AttentStampForm/AllowanceTable";

const AttendStampForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.attendStampList);
  const isMobile = useIsMobile();
  const history = useHistory();

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmArchivementModal, setConfirmArchivementModal] = useState(false);
  const [typeConfirm, setTypeConfirm] = useState(1); //1: confirm, 2: unconfirm
  const [attendHolidays, setAttendHolidays] = useState([]);
  const [subTotalTime, setSubTotalTime] = useState(Object());
  const [subTotalDay, setSubTotalDay] = useState(Object());
  const [business, setBusiness] = useState([]);
  const [term, setTerm] = useState([]);
  const [holidaySubtotalItem, setHolidaySubtotalItem] = useState([]);
  const [closingDate, setClosingDate] = useState(Number(sessionStorage.getItem("loginUser.closingDate") || 0));
  const context = useIntialState();

  const {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffCode,
    setStaffCode,
    targetMonth,
    setTargetMonth,
    orgCode,
    setOrgCode,
    selectOrgCode,
    setSelectOrgCode,
    viewPeriod,
    setViewPeriod,
    flagReLoad,
    setFlagReload,
    isReloader,
    setIsReloader,
    orgCodeList,
    setOrgCodeList,
    staffList,
    setstaffList,
  } = context;

  const [oldOrgCode, setOldSelectOrgCode] = useState(
    sessionStorage.getItem("attendStampList.orgCode")! || sessionStorage.getItem("loginUser.orgCode")!,
  );
  const [tmpStaffCode, setTmpStaffCode] = useState(
    sessionStorage.getItem("attendStampList.staffCode")! || sessionStorage.getItem("loginUser.staffCode")!,
  );
  const [attendFlag] = useState(sessionStorage.getItem("attendStampList.attendFlag") || "0");
  const [closingDateV2, setClosingDateV2] = useState(0);
  const {
    employment,
    nursingCareHolidayData,
    nursingHolidayData,
    paidHolidayGrantData,
    specialHolidayGrantList,
    stampBusiness,
    stampItems,
    stampTerm,
    substituteHolidayUsingStatusList,
    transferHolidayUsingStatusList,
    isConfirmError,
    isConfirmAchievement,
    dispStampTimeOrg,
    isGoBack,
  } = useStampListForm(
    closingDate,
    attendFlag,
    "",
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
    setAttendHolidays,
    setIsLoading,
    setTerm,
    setBusiness,
    false,
    setOldSelectOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffCode,
    setStaffCode,
    targetMonth,
    setTargetMonth,
    orgCode,
    setOrgCode,
    selectOrgCode,
    setSelectOrgCode,
    viewPeriod,
    setViewPeriod,
    flagReLoad,
    setFlagReload,
    isReloader,
    setIsReloader,
    setOrgCodeList,
    oldOrgCode,
    setstaffList,
    setClosingDateV2,
  );

  function timeDisp(time: any) {
    if (time === 0 || !time) {
      return "-";
    }
    return time;
  }

  const { errorNotification, successNotification } = useToastNotification();

  useEffect(() => {}, [staffList]);

  const downloadPdf = async () => {
    setIsLoading(true);
    exportFilePDFv2(
      selectOrgCode,
      moment(targetDateFrom.toString()).format("YYYY-MM-DD"),
      moment(targetDateTo.toString()).format("YYYY-MM-DD"),
      staffCode,
    )
      .then((response: any) => {
        setIsLoading(false);
        downloadOriginalPDF(response, "勤怠実績出力.pdf");
      })
      .catch((error) => {
        setIsLoading(false);
        errorNotification("対象月、対象組織において在籍するスタッフがいませんでした。");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function countHolidayColspan(distinctionHoliday: any, attendHolidayList: any) {
    // // 法定休日 + 所定休日 or 休日
    // let result = distinctionHoliday ? 2 : 1;

    let result = 0;

    // 業務数
    result += attendHolidayList ? attendHolidayList.length : 0;
    // 遅刻 + 早退
    result += 2;
    return result;
  }

  const handleCopyAchievement = () => {
    setConfirmModal(false);
    setIsLoading(true);
    const params = {
      updateUser: sessionStorage.getItem("loginUser.staffCode") || "",
      periodFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
      periodTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
      staffCode,
    };
    postCopyAchievement(params)
      .then((response: any) => {
        setIsLoading(false);
        setIsReloader(true);
        if (response) {
          // setFlagReload(Number(new Date()));
          successNotification("実績が登録されました。");
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        const mess = renderError(error);
        errorNotification(mess);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {}, [nursingCareHolidayData, nursingHolidayData, closingDateV2]);

  useEffect(() => {
    if (viewPeriod !== null) {
      sessionStorage.setItem("attendStampList.viewPeriod", String(viewPeriod));
      sessionStorage.setItem("headquartersFinal.viewPeriod", "");
    }
  }, [viewPeriod]);

  useEffect(() => {
    let session = sessionStorage.getItem("attendStampList.viewPeriod");
    if (session !== null) {
      setViewPeriod(session);
    } else {
      setViewPeriod("monthly");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (targetMonth !== null) {
      sessionStorage.setItem("attendStampList.targetMonth", String(targetMonth));
    }
  }, [targetMonth]);

  useEffect(() => {
    let session = sessionStorage.getItem("attendStampList.targetMonth");
    if (session !== null) {
      setTargetMonth(new Date(session));
    } else {
      setTargetMonth(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (targetDateFrom !== null) {
      sessionStorage.setItem("attendStampList.targetDateFrom", String(targetDateFrom));
    }
  }, [targetDateFrom, viewPeriod]);

  useEffect(() => {
    let session = sessionStorage.getItem("attendStampList.targetDateFrom");
    if (session !== null) {
      setTargetDateFrom(new Date(session));
    } else {
      setTargetDateFrom(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (targetDateTo !== null) {
      sessionStorage.setItem("attendStampList.targetDateTo", String(targetDateTo));
    }
  }, [targetDateTo]);

  useEffect(() => {
    let session = sessionStorage.getItem("attendStampList.targetDateTo");
    if (session !== null) {
      setTargetDateTo(new Date(session));
    } else {
      setTargetDateTo(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paidHolidayGrantData !== null) {
      if (paidHolidayGrantData.nextCarryOverDaysNum === null) {
        paidHolidayGrantData.nextCarryOverDaysNum = 0.0;
      }
      if (paidHolidayGrantData.nextCarryOverFirstDaysNum === null) {
        paidHolidayGrantData.nextCarryOverFirstDaysNum = 0.0;
      }
      if (paidHolidayGrantData.nextCarryOverTimesNum === null) {
        paidHolidayGrantData.nextCarryOverTimesNum = 0;
      }
      if (paidHolidayGrantData.nextCarryOverFirstTimesNum === null) {
        paidHolidayGrantData.nextCarryOverFirstTimesNum = 0;
      }
    }
  }, [subTotalTime]);

  const handleConfirmAchievement = () => {
    if (!confirmArchivementModal) {
      setConfirmArchivementModal(true);
      return false;
    }

    const param = {
      companyCode: sessionStorage.getItem("loginUser.companyCode"),
      closingDate: closingDate,
      employmentId: "",
      orgCode: selectOrgCode,
      searchClosingDate: closingDate,
      staffCode: staffCode,
      targetDateFrom: moment(targetDateFrom).format("YYYY-MM-DD"),
      targetDateTo: moment(targetDateTo).format("YYYY-MM-DD"),
    };

    if (new Date(targetDateTo).getTime() > new Date().getTime()) {
      errorNotification("対象期間は不正のため、月次提出できません。");
      setConfirmArchivementModal(false);
      return false;
    }

    // setIsLoading(true);
    achievementConfirmStamp(param, typeConfirm === 1 ? "confirm" : "unconfirm")
      .then((response: any) => {
        // setIsLoading(false);
        if (typeConfirm === 1) {
          successNotification("月次提出しました。");
        } else {
          successNotification("月次提出取り下げました。");
        }
        setConfirmArchivementModal(false);
        setIsReloader(true);
      })
      .catch((error: any) => {
        setIsLoading(false);
        setConfirmArchivementModal(false);
        const mess = renderError(error);
        errorNotification(mess);
      });
  };

  const handleBack = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <BlockUI blocking={isLoading}>
      <attendStampContext.Provider value={context}>
        <FormContents>
          {isGoBack === "true" && (
            <FlexBox alignItems="center" customStyle={css({ width: "100%", margin: "0 0 0 30px" })}>
              <FlexBoxItem>
                <IconLabelButton onClick={handleBack} iconType="arrowLeft" text="戻る" />
              </FlexBoxItem>
            </FlexBox>
          )}

          <FormTitle
            title="打刻データ確認"
            bold={true}
            customStyle={css(isMobile ? { padding: "8px 16px 0 16px", margin: 0 } : {})}
          />
          <div className="wrap-attend-stamp-list">
            <FormPadding customStyle={css(isMobile ? { padding: "0 16px" } : { paddingLeft: "35px" })}>
              <Row>
                {!isMobile && (
                  <Col lg={2} md={3}>
                    <div style={{ marginTop: "10px" }}>
                      <BodyText>期間</BodyText>
                    </div>
                  </Col>
                )}
                {isMobile ? (
                  <Col md={2}>
                    <FlexBox customStyle={css({ margin: "10px 0" })}>
                      <Col lg={6} md={6}>
                        <FlexBox>
                          <FlexBoxItem>
                            <CalendarRangeV2
                              startDate={targetDateFrom}
                              onChangeStart={(date: Date) => {
                                sessionStorage.setItem("attendStampList.targetDateFrom", String(date));
                                setTargetDateFrom(date);
                                setIsReloader(true);
                              }}
                              endDate={targetDateTo}
                              onChangeEnd={(date) => {
                                if (date && JSON.stringify(date) !== JSON.stringify(targetDateTo)) {
                                  sessionStorage.setItem("attendStampList.targetDateTo", String(date));
                                  setTargetDateTo(date);
                                  setIsReloader(true);
                                }
                              }}
                              validateTime={(startDate, endDate) => {
                                let isValid = moment(endDate).diff(startDate, "year") < 1;
                                if (!isValid) {
                                  errorNotification("対象期間の範囲は１年以内で入力してください");
                                }
                                return isValid;
                              }}
                              readOnly={viewPeriod === "monthly"}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      </Col>
                    </FlexBox>
                  </Col>
                ) : (
                  <Col md={10}>
                    <FlexBox>
                      <Col lg={2} md={3}>
                        <div style={{ marginTop: "-20px" }}>
                          <RadioSelectForm
                            label=""
                            name="monthly"
                            items={[
                              {
                                label: "月指定",
                                value: "monthly",
                              },
                            ]}
                            value={viewPeriod}
                            setValue={(e) => {
                              setViewPeriod(e.target.value);
                              setIsReloader(true);
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={4}>
                        <FlexBox customStyle={css({ marginRight: "20px" })}>
                          <FlexBoxItem>
                            <CalendarMonth
                              date={new Date(targetMonth)}
                              setDate={(date) => {
                                setTargetMonth(date);
                                if (new Date(targetMonth).getTime() !== date.getTime()) {
                                  setIsReloader(true);
                                }
                              }}
                              readOnly={viewPeriod === "daily"}
                              openActionChevron={true}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      </Col>
                    </FlexBox>
                    <FlexBox customStyle={css({ margin: "10px 0" })}>
                      <Col lg={2} md={3}>
                        <div style={{ marginTop: "-20px" }}>
                          <RadioSelectForm
                            label=""
                            name="daily"
                            items={[
                              {
                                label: "期間指定",
                                value: "daily",
                              },
                            ]}
                            value={viewPeriod}
                            setValue={(e) => {
                              setViewPeriod(e.target.value);
                              setIsReloader(true);
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <FlexBox>
                          <FlexBoxItem>
                            <CalendarRangeV2
                              startDate={targetDateFrom}
                              onChangeStart={(date: Date) => {
                                sessionStorage.setItem("attendStampList.targetDateFrom", String(date));
                                setTargetDateFrom(date);
                                setIsReloader(true);
                              }}
                              endDate={targetDateTo}
                              onChangeEnd={(date) => {
                                if (date && JSON.stringify(date) !== JSON.stringify(targetDateTo)) {
                                  sessionStorage.setItem("attendStampList.targetDateTo", String(date));
                                  setTargetDateTo(date);
                                  setIsReloader(true);
                                }
                              }}
                              validateTime={(startDate, endDate) => {
                                let isValid = moment(endDate).diff(startDate, "year") < 1;
                                if (!isValid) {
                                  errorNotification("対象期間の範囲は１年以内で入力してください");
                                }
                                return isValid;
                              }}
                              readOnly={viewPeriod === "monthly"}
                            />
                          </FlexBoxItem>
                        </FlexBox>
                      </Col>
                    </FlexBox>
                  </Col>
                )}
              </Row>
              {detailRole.accessRange !== 0 && (
                <Row style={{ marginBottom: isMobile ? "4px" : "15px" }}>
                  {!isMobile && (
                    <Col style={{ display: "flex", alignItems: "center" }} lg={2} md={3}>
                      <BodyText>組織名</BodyText>
                    </Col>
                  )}
                  <Col lg={10} md={9}>
                    <Row>
                      <Col md={6}>
                        <SelectForm
                          label=""
                          name="selectOrgCode"
                          value={selectOrgCode}
                          setValue={(v) => {
                            setStaffCode(oldOrgCode === v ? tmpStaffCode : "");
                            setOldSelectOrgCode(selectOrgCode);
                            sessionStorage.setItem("attendStampList.orgCode", v);
                            setSelectOrgCode(v);
                            setIsReloader(true);
                          }}
                          width={isMobile ? "100%" : "800px"}
                          options={orgCodeList}
                          placeholder=""
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row>
                {!isMobile && (
                  <Col style={{ display: "flex", alignItems: "center" }} lg={2} md={3}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                )}
                <Col lg={10} md={9}>
                  <Row>
                    <Col md={6}>
                      {detailRole.accessRange !== 0 ? (
                        <SelectForm
                          label=""
                          name="staffCode"
                          value={staffCode}
                          setValue={(v) => {
                            sessionStorage.setItem("attendStampList.staffCode", v);
                            setStaffCode(v);
                            setTmpStaffCode(v);
                            setIsReloader(true);
                          }}
                          options={staffList}
                          width={isMobile ? "100%" : "800px"}
                          placeholder="スタッフを選択してください"
                        />
                      ) : (
                        <BodyText color="#333333">
                          {staffList.length > 0 && staffList.find((staff: any) => staff.value === staffCode)?.label}
                        </BodyText>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormPadding>
            {staffList.length === 0 || staffCode === "" || stampItems.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "normal",
                  fontFamily: "inherit",
                  margin: "30px 0",
                }}
              >
                <BodyText size="re" color="#666666">
                  データはありません。
                </BodyText>
              </div>
            ) : (
              <>
                <Row style={isMobile ? { marginBottom: 8 } : { marginBottom: "45px" }}>
                  <Col xs={12}>
                    <div className="panel panel-default">
                      <FlexBox
                        direction={isMobile ? "column" : "row"}
                        alignItems={isMobile ? "flex-start" : "center"}
                        justifyContent={isMobile ? "center" : "space-between"}
                        customStyle={css(isMobile ? { margin: "16px", gap: 8 } : { margin: "35px 45px 20px 0" })}
                      >
                        {!isMobile && (
                          <FormTitle
                            title={`日別データ${
                              Number(employment.workSystem) === 2
                                ? "（裁量労働のため、深夜時間は内訳表示となっています）"
                                : ""
                            }`}
                            bold={true}
                          />
                        )}

                        <FlexBox>
                          {isConfirmError &&
                            (viewPeriod === "monthly" ||
                              (isMobile && detailRole.usabilityGeneralItem2 !== 0 && detailRole.editable !== 0)) && (
                              <SecondaryButton
                                text="提出取り下げ"
                                customStyle={css({ marginRight: "15px" })}
                                disabled={
                                  new Date(targetDateTo).getTime() > new Date().getTime() || isConfirmAchievement
                                }
                                onClick={() => {
                                  handleConfirmAchievement();
                                  setTypeConfirm(2);
                                }}
                              />
                            )}
                          {!isConfirmError &&
                            (viewPeriod === "monthly" ||
                              (isMobile && detailRole.usabilityGeneralItem1 !== 0 && detailRole.editable !== 0)) && (
                              <PrimaryButton
                                text="月次提出"
                                customStyle={css({ marginRight: "15px" })}
                                disabled={
                                  new Date(targetDateTo).getTime() >=
                                    new Date(moment().format("YYYY/MM/DD")).getTime() || isConfirmAchievement
                                }
                                onClick={() => {
                                  handleConfirmAchievement();
                                  setTypeConfirm(1);
                                }}
                              />
                            )}
                          {attendFlag !== "1" &&
                            detailRole.usabilityGeneralItem3 !== 0 &&
                            detailRole.editable !== 0 && (
                              <PrimaryButton
                                text="シフト→実績"
                                disabled={[2, 3].indexOf(employment.workSystem) !== -1}
                                onClick={handleCopyAchievement}
                              />
                            )}
                          {staffList.length > 0 &&
                          detailRole.downloadFlag === 1 &&
                          staffCode !== "" &&
                          stampItems.length > 0 &&
                          !isMobile ? (
                            <Col md={1}>
                              <ClickableIconButton
                                onClick={downloadPdf}
                                additionalCss={css({
                                  margin: "7px 0 0 10px",
                                  cursor: "pointer",
                                })}
                              >
                                <span className="print">
                                  <Icon type="printer" color="" size="24px" />
                                </span>
                              </ClickableIconButton>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </FlexBox>
                      </FlexBox>

                      <div
                        style={{
                          marginRight: 16,
                          marginLeft: 16,
                          border: "1px solid #ddd",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            {stampItems && stampItems.length && staffCode != "" ? (
                              <React.Fragment>
                                {isMobile ? (
                                  <AttendStampMobileTable
                                    eventScroll={eventScroll}
                                    business={stampBusiness || []}
                                    term={stampTerm}
                                    targetDateFrom={targetDateFrom}
                                    targetDateTo={targetDateTo}
                                    selectOrgCode={selectOrgCode}
                                    attendFlag={attendFlag}
                                    stampItems={stampItems}
                                    emp={employment}
                                    staffCode={staffCode}
                                    staffList={staffList}
                                    remote={employment.isRemote}
                                    viewPeriod={viewPeriod}
                                    isConfirmError={isConfirmError}
                                    editable={detailRole.editable}
                                    dispStampTimeOrg={dispStampTimeOrg || 0}
                                    allowanceApplicationStatus={employment.useAllowanceApplication}
                                  />
                                ) : (
                                  <AttendStampTable
                                    eventScroll={eventScroll}
                                    business={stampBusiness || []}
                                    term={stampTerm}
                                    targetDateFrom={targetDateFrom}
                                    targetDateTo={targetDateTo}
                                    selectOrgCode={selectOrgCode}
                                    attendFlag={attendFlag}
                                    stampItems={stampItems}
                                    emp={employment}
                                    staffCode={staffCode}
                                    staffList={staffList}
                                    remote={employment.isRemote}
                                    viewPeriod={viewPeriod}
                                    isConfirmError={isConfirmError}
                                    editable={detailRole.editable}
                                    dispStampTimeOrg={dispStampTimeOrg || 0}
                                    allowanceApplicationStatus={employment.useAllowanceApplication}
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {isMobile ? (
                                  <AttendStampMobileTableNoData
                                    eventScroll={eventScroll}
                                    business={stampBusiness || []}
                                    term={stampTerm || []}
                                    targetDateFrom={targetDateFrom}
                                    targetDateTo={targetDateTo}
                                    selectOrgCode={selectOrgCode}
                                    attendFlag={attendFlag}
                                    stampItems={stampItems}
                                    emp={employment}
                                    staffCode={staffCode}
                                    staffList={staffList}
                                  />
                                ) : (
                                  <AttendStampTableNoData
                                    eventScroll={eventScroll}
                                    business={stampBusiness || []}
                                    term={stampTerm || []}
                                    targetDateFrom={targetDateFrom}
                                    targetDateTo={targetDateTo}
                                    selectOrgCode={selectOrgCode}
                                    attendFlag={attendFlag}
                                    stampItems={stampItems}
                                    emp={employment}
                                    staffCode={staffCode}
                                    staffList={staffList}
                                    isConfirmError={isConfirmError}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>

                {!isMobile && (
                  <>
                    <Row style={{ marginBottom: "45px" }}>
                      <Col xs={12}>
                        <div className="panel panel-default">
                          <FormTitle
                            title={`集計時間データ${
                              Number(employment.workSystem) === 2
                                ? "（裁量労働のため、深夜時間は内訳表示となっています）"
                                : ""
                            }`}
                            bold={true}
                          />
                          <Row>
                            <Col xs={12}>
                              <MonthHourTable
                                employment={employment}
                                business={business || []}
                                term={term || []}
                                timeDisp={timeDisp}
                                subTotalTime={subTotalTime}
                                remote={employment.isRemote}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "45px" }}>
                      <Col xs={12}>
                        <div className="panel panel-default">
                          <FormTitle title="集計日数データ" bold={true} />
                          <Row>
                            <Col xs={12}>
                              <MonthDayTable
                                subTotalDay={subTotalDay}
                                holidaySubtotalItem={holidaySubtotalItem}
                                countHolidayColspan={countHolidayColspan}
                                remote={employment.isRemote}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {employment.useAllowanceApplication === 1 && (subTotalDay.allowanceDataList?.length ?? 0) > 0 && (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <FormTitle title="手当申請状況" bold={true} />
                          <Row>
                            <Col xs={12}>
                              <AllowanceTable allowanceData={subTotalDay.allowanceDataList ?? []} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {employment.autoPaidManageFunc && attendFlag === "0" ? (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <div className="panel panel-default">
                            <FormTitle title="有給使用状況 (現時点)" bold={true} />

                            <Row>
                              <Col xs={12}>
                                <PaidHolidayGranTable paidHolidayGrantData={paidHolidayGrantData} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {specialHolidayGrantList.length > 0 && attendFlag === "0" && (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <div className="panel panel-default">
                            <FormTitle title="特休使用状況" bold={true} />
                            <Row>
                              <Col xs={12}>
                                <SpecialHolidayGrantTable specialHolidayGrantList={specialHolidayGrantList} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {substituteHolidayUsingStatusList.length > 0 && attendFlag === "0" && (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <div className="panel panel-default">
                            <FormTitle title="代休使用状況" bold={true} />

                            <Row>
                              <Col xs={12}>
                                <SubstituteHolidayTable
                                  substituteHolidayUsingStatusList={substituteHolidayUsingStatusList}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {employment.useTransferApplication &&
                    transferHolidayUsingStatusList.length > 0 &&
                    attendFlag === "0" ? (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <div className="panel panel-default">
                            <FormTitle title="振替状況" bold={true} />

                            <Row>
                              <Col xs={12}>
                                <TransferHolidayTable
                                  transferHolidayUsingStatusList={transferHolidayUsingStatusList}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {nursingHolidayData && Object.keys(nursingHolidayData).length > 0 && attendFlag === "0" ? (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <div className="panel panel-default">
                            <FormTitle title="介護休暇使用状況" bold={true} />
                            <Row>
                              <Col xs={12}>
                                <NursingHolidayDataTable nursingHolidayData={nursingHolidayData} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {nursingCareHolidayData && Object.keys(nursingCareHolidayData).length > 0 && attendFlag === "0" ? (
                      <Row style={{ marginBottom: "45px" }}>
                        <Col xs={12}>
                          <div className="panel panel-default">
                            <FormTitle title="看護休暇使用状況" bold={true} />
                            <Row>
                              <Col xs={12}>
                                <NursingCareHolidayDataTable nursingCareHolidayData={nursingCareHolidayData} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}

            <Modal
              open={confirmModal}
              closeHandler={() => setConfirmModal(false)}
              title="シフトから実績を作成"
              submitText="作成する"
              note="シフトから実績を作成します。よろしいですか？"
              onSubmit={() => {
                handleCopyAchievement();
              }}
            >
              <FlexBox
                customStyle={css({
                  color: productColor.deleteAction,
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                  fontSize: "14px",
                })}
              >
                ※実績の登録がないの日のみ作成します。
                <br />
                ※すでに実績が登録されている日は、
                <br />
                実績の変更はおこないません。
              </FlexBox>
            </Modal>

            <Modal
              open={confirmArchivementModal}
              typeSubmit={!isConfirmError ? "confirm" : "reject"}
              closeHandler={() => setConfirmArchivementModal(false)}
              title={!isConfirmError ? "実績提出の確認" : "提出取り下げの確認"}
              submitText={!isConfirmError ? "月次提出" : "提出取り下げ"}
              note={
                !isConfirmError ? "実績を提出します。よろしいでしょうか？" : "提出を取り下げます。よろしいでしょうか？"
              }
              onSubmit={() => {
                handleConfirmAchievement();
              }}
            />
          </div>
        </FormContents>
      </attendStampContext.Provider>
    </BlockUI>
  );
};

export default AttendStampForm;
