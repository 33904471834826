/* eslint-disable no-nested-ternary */
import React from "react";

import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import FormLabel from "components/atoms/Form/FormLabel";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import MultipleCheckboxForm from "components/molecules/MultipleCheckboxForm";
import HourInput from "components/atoms/Form/HourInput";
import FlexBox from "components/atoms/FlexBox";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import useWorkingHoursOptions from "hooks/useWorkingHoursOptions";
import { gridCol } from "components/styles";
import { css } from "@emotion/core";
import Checkbox from "components/molecules/Checkbox";
import VerticalLabelMutilSelectForm from "components/molecules/VerticalLabelMutilSelectForm";
import useEmploymentAllowances from "hooks/useEmploymentAllowances";
import { AttendAllowance } from "domain/attendAllowance";

const FLEX_TIME = 3;

const overTimeApplicationArr = [
  {
    label: "使用しない",
    value: "0",
  },
  {
    label: "使用する",
    value: "1",
  },
  {
    label: "シフト内の残業時間は認める",
    value: "2",
  },
];

const EmploymentOvertimeSettings: React.FC<{
  formik: any;
}> = ({ formik }) => {
  const workingHoursOptions = useWorkingHoursOptions();
  const employmentAllowances = useEmploymentAllowances();
  const extraOverTimeItems = [];

  const [isNumber] = React.useState(3);
  for (let i = 1; i <= isNumber; ) {
    extraOverTimeItems.push(i);
    i += 1;
  }

  let overtimeCalculationSetting = [
    { id: "calcOverTimeInMonth", label: "月単位で計算", value: formik.values.calcOverTimeInMonth },
    { id: "calcOverTimeInWeek", label: "週単位で計算", value: formik.values.calcOverTimeInWeek },
    { id: "calcOverTimeInDay", label: "日単位で計算", value: formik.values.calcOverTimeInDay },
  ];

  const overTimeApplicationList =
    String(formik.values.workSystem) === "3"
      ? overTimeApplicationArr.filter((val) => val.value !== "2")
      : overTimeApplicationArr;

  const showSpecifiedWorkingSetting =
    Number(formik.values.workSystem) === 3 || String(formik.values.calcOverTimeInMonth) === "true";

  return (
    <>
      <FormTitle title="残業・手当設定" bold={true} customStyle={css({ marginLeft: "0" })} />
      <div>
        <div style={{ width: gridCol.grid06 }}>
          <VerticalLabelSelectForm
            label="法定内残業時間の扱い"
            name="handleLegalInOvertime"
            options={[
              {
                label: "全て法定外残業として計上する",
                value: "0",
              },
              {
                label: "法定内残業と法定外残業で区別して計上する",
                value: "1",
              },
            ]}
            value={String(formik.values.handleLegalInOvertime)}
            setValue={(val) => formik.setFieldValue("handleLegalInOvertime", val)}
            note="※日の法定時間は8時間、週の法定時間は40時間となります。"
            isSearchable={false}
          />
        </div>

        <div style={[1, FLEX_TIME].includes(Number(formik.values.workSystem)) ? {} : { display: "none" }}>
          {Number(formik.values.workSystem) === 1 && (
            <FormField>
              {/* Confirm */}
              <MultipleCheckboxForm
                name="overtimeCalculationSetting"
                label="残業計算設定"
                items={overtimeCalculationSetting}
                setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === "true"))}
              />
            </FormField>
          )}

          {Number(formik.values.workSystem) === 3 && (
            <FormField>
              <RadioSelectForm
                label="残業計算設定"
                name="overtimeCalculationSetting"
                items={[
                  {
                    label: "月単位で計算",
                    value: "true",
                  },
                ]}
                value={String("true")}
                setValue={(e) => {
                  formik.setFieldValue("calcOverTimeInMonth", true);
                }}
              />
            </FormField>
          )}

          <div style={String(formik.values.calcOverTimeInMonth) === "true" ? {} : { display: "none" }}>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label={
                  Number(formik.values.workSystem) === 3 ? "月の所定労働時間&法定労働時間設定" : "月の所定労働時間設定"
                }
                name="workingHoursId"
                value={String(formik.values.workingHoursId)}
                setValue={(val: string) => formik.setFieldValue("workingHoursId", val)}
                options={workingHoursOptions}
                required={Number(formik.values.workSystem) === 3 ? true : false}
              />
            </div>
          </div>

          <div style={String(formik.values.calcOverTimeInWeek) === "true" ? {} : { display: "none" }}>
            <VerticalLabelFormLayout
              label={
                <div>
                  <div style={{ float: "left" }}>
                    <FormLabel label="週の所定労働時間設定" />
                  </div>
                </div>
              }
              input={
                <VerticalLabelSelectForm
                  label=""
                  name="letShiftPredeterminedWorkTimeOfWeek"
                  value={String(formik.values.letShiftPredeterminedWorkTimeOfWeek)}
                  setValue={(val: string) => formik.setFieldValue("letShiftPredeterminedWorkTimeOfWeek", val)}
                  options={[
                    { label: "週の所定労働時間を指定する", value: "0" },
                    { label: "1週間分のシフト時間を週の所定労働時間とする", value: "1" },
                    { label: "週の所定労働時間を限度としてシフト時間を所定労働時間とする", value: "2" },
                  ]}
                  note="※40時間を超える場合、「月単位で計算」も選択してください。選択がない場合、40時間を超える時間は法定外残業時間となります。"
                  isSearchable={false}
                />
              }
            />

            <div style={String(formik.values.letShiftPredeterminedWorkTimeOfWeek) !== "1" ? {} : { display: "none" }}>
              <FormField>
                <FlexBox>
                  <HourInput
                    name="prescribedWorkHourOfWeek"
                    value={String(formik.values.prescribedWorkHourOfWeek)}
                    label="時間"
                    onChange={formik.handleChange}
                    min={0}
                    max={168}
                  />
                </FlexBox>
              </FormField>
            </div>
          </div>
        </div>

        <div
          style={
            String(formik.values.workSystem) === "0" || String(formik.values.workSystem) === "2"
              ? {}
              : { display: "none" }
          }
        >
          <FormField>
            <RadioSelectForm
              label="週単位での法定労働時間外の計算"
              name="calcLegalOverTime"
              items={[
                {
                  label: "しない",
                  value: "0",
                },
                {
                  label: "する",
                  value: "1",
                },
              ]}
              value={String(formik.values.calcLegalOverTime)}
              setValue={formik.handleChange}
            />
          </FormField>
        </div>

        <div
          style={
            (String(formik.values.workSystem) === "1" && String(formik.values.calcOverTimeInWeek) === "true") ||
            (String(formik.values.workSystem) === "0" && String(formik.values.calcLegalOverTime) === "1") ||
            (String(formik.values.workSystem) === "2" && String(formik.values.calcLegalOverTime) === "1")
              ? {}
              : { display: "none" }
          }
        >
          <VerticalLabelFormLayout
            label={
              <div>
                <div style={{ float: "left" }}>
                  <FormLabel label="締め日をまたぐ週の計算方法" />
                </div>
              </div>
            }
            input={
              <VerticalLabelSelectForm
                label=""
                name="carryOverMonthMethod"
                value={String(formik.values.carryOverMonthMethod)}
                setValue={(val: string) => formik.setFieldValue("carryOverMonthMethod", val)}
                options={[
                  { label: "前月を含めて計算する", value: "0" },
                  { label: "日割りで計算する", value: "1" },
                ]}
                isSearchable={false}
              />
            }
          />
        </div>

        <div
          style={
            (String(formik.values.workSystem) === "1" &&
              String(formik.values.calcOverTimeInWeek) === "true" &&
              String(formik.values.carryOverMonthMethod) === "1") ||
            (String(formik.values.workSystem) === "0" &&
              String(formik.values.calcLegalOverTime) === "1" &&
              String(formik.values.carryOverMonthMethod) === "1") ||
            (String(formik.values.workSystem) === "2" &&
              String(formik.values.calcLegalOverTime) === "1" &&
              String(formik.values.carryOverMonthMethod) === "1")
              ? {}
              : { display: "none" }
          }
        >
          <RadioSelectForm
            label="締め日をまたぐ週の計算時の端数処理(小数第一位)"
            name="carryOverMonthRounding"
            items={[
              {
                label: "切り捨てる",
                value: "1",
              },
              {
                label: "切り上げる",
                value: "0",
              },
              {
                label: "四捨五入",
                value: "4",
              },
            ]}
            value={String(formik.values.carryOverMonthRounding)}
            setValue={formik.handleChange}
          />
        </div>

        <div style={String(formik.values.workSystem) === "2" ? {} : { display: "none" }}>
          <FormField>
            <FlexBox>
              <div style={{ width: "200px" }}>
                <FormLabel label="日の所定労働時間設定" />
                <HourInput
                  name="prescribedWorkHourOfDay"
                  value={String(formik.values.prescribedWorkHourOfDay)}
                  label="分"
                  onChange={formik.handleChange}
                  min={0}
                  max={1440}
                />
              </div>
            </FlexBox>
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === "1" ? {} : { display: "none" }}>
          <div style={String(formik.values.calcOverTimeInDay) === "true" ? {} : { display: "none" }}>
            <FormField displayBlock={true}>
              <VerticalLabelFormLayout
                label={
                  <div>
                    <div style={{ float: "left" }}>
                      <FormLabel label="日の所定労働時間設定" />
                    </div>
                  </div>
                }
                input={
                  <VerticalLabelSelectForm
                    label=""
                    name="letShiftPredeterminedWorkTimeOfDay"
                    value={String(formik.values.letShiftPredeterminedWorkTimeOfDay)}
                    setValue={(val: string) => formik.setFieldValue("letShiftPredeterminedWorkTimeOfDay", val)}
                    options={[
                      { label: "日の所定労働時間を指定する", value: "0" },
                      { label: "シフト時間を日の所定労働時間とする", value: "1" },
                      { label: "日の所定労働時間を限度としてシフト時間を所定時間とする", value: "2" },
                    ]}
                    note="※8時間を超える場合、「月単位で計算」も選択してください。選択がない場合、8時間を超える時間は法定外残業時間となります。"
                    isSearchable={false}
                  />
                }
              />
            </FormField>

            <div style={String(formik.values.letShiftPredeterminedWorkTimeOfDay) !== "1" ? {} : { display: "none" }}>
              <FormField>
                <FlexBox>
                  <div style={{ width: "120px" }}>
                    <HourInput
                      name="prescribedWorkHourOfDay"
                      value={String(formik.values.prescribedWorkHourOfDay)}
                      label="分"
                      onChange={formik.handleChange}
                      min={0}
                      max={1440}
                    />
                  </div>
                </FlexBox>
              </FormField>
            </div>

            <div style={String(formik.values.letShiftPredeterminedWorkTimeOfDay) !== "0" ? {} : { display: "none" }}>
              <FormField displayBlock={true}>
                <FlexBox>
                  <Checkbox
                    id="shiftStartOvertimeFlag"
                    name="shiftStartOvertimeFlag"
                    value={String(formik.values.shiftStartOvertimeFlag)}
                    label="シフト開始前時間を残業時間とする"
                    checked={String(formik.values.shiftStartOvertimeFlag) === "1" ? true : false}
                    onChange={() =>
                      formik.setFieldValue(
                        "shiftStartOvertimeFlag",
                        String(formik.values.shiftStartOvertimeFlag) === "1" ? "0" : "1",
                      )
                    }
                  />
                </FlexBox>
              </FormField>
            </div>
            <div style={String(formik.values.letShiftPredeterminedWorkTimeOfDay) !== "0" ? {} : { display: "none" }}>
              <FormField displayBlock={true}>
                <FlexBox>
                  <Checkbox
                    id="shiftEndOvertimeFlag"
                    name="shiftEndOvertimeFlag"
                    value={String(formik.values.shiftEndOvertimeFlag)}
                    label="シフト終了後時間を残業時間とする"
                    checked={String(formik.values.shiftEndOvertimeFlag) === "1" ? true : false}
                    onChange={() =>
                      formik.setFieldValue(
                        "shiftEndOvertimeFlag",
                        String(formik.values.shiftEndOvertimeFlag) === "1" ? "0" : "1",
                      )
                    }
                  />
                </FlexBox>
              </FormField>
            </div>
          </div>
        </div>
        <div
          style={
            String(formik.values.workSystem) !== "2"
              ? { width: gridCol.grid06, margin: "15px 0px" }
              : { display: "none" }
          }
        >
          <VerticalLabelSelectForm
            label="残業申請機能"
            name="useOverTimeApplication"
            options={overTimeApplicationList}
            value={
              overTimeApplicationList.filter((val) => val.value === String(formik.values.useOverTimeApplication))
                .length === 0
                ? "0"
                : String(formik.values.useOverTimeApplication)
            }
            setValue={(val) => formik.setFieldValue("useOverTimeApplication", val)}
          />
        </div>

        <div style={{ width: gridCol.grid06, margin: "15px 0px" }}>
          <VerticalLabelSelectForm
            label="割増残業機能"
            name="useExtraOverTime"
            options={[
              {
                label: "使用しない",
                value: "false",
              },
              {
                label: "使用する",
                value: "true",
              },
            ]}
            value={String(formik.values.useExtraOverTime)}
            setValue={(val) => formik.setFieldValue("useExtraOverTime", val)}
            isSearchable={false}
          />
        </div>

        <div style={String(formik.values.useExtraOverTime) === "true" ? {} : { display: "none" }}>
          {extraOverTimeItems.map((value, key) => (
            <div key={key} style={{ margin: "15px 0px" }}>
              <VerticalLabelFormLayout
                label={<FormLabel label={`割増残業${String(value)}`} />}
                input={
                  <FlexBox>
                    <FormLabel label="残業時間が" />
                    <div style={{ margin: "0 15px" }}>
                      <HourInput
                        name={`extraOverTime${String(value)}`}
                        value={
                          value === 1
                            ? formik.values.extraOverTime1
                            : value === 2
                            ? formik.values.extraOverTime2
                            : formik.values.extraOverTime3
                        }
                        label="時間"
                        min={0}
                        max={743}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <FormLabel customStyle={css({ marginBottom: "0px" })} label="を超える場合に計上する" />
                  </FlexBox>
                }
              />
            </div>
          ))}
        </div>

        <div style={{ width: gridCol.grid06, margin: "15px 0px" }}>
          <VerticalLabelSelectForm
            label="手当申請機能"
            name="useAllowanceApplication"
            options={[
              {
                label: "使用しない",
                value: "false",
              },
              {
                label: "使用する",
                value: "true",
              },
            ]}
            value={String(formik.values.useAllowanceApplication)}
            setValue={(val) => formik.setFieldValue("useAllowanceApplication", val)}
            isSearchable={false}
          />
        </div>

        <div style={String(formik.values.useAllowanceApplication) === "true" ? {} : { display: "none" }}>
          <div style={{ margin: "15px 0px" }}>
            <VerticalLabelMutilSelectForm
              label="使用する手当"
              name="allowances"
              values={
                formik.values.allowances?.map((allowance: AttendAllowance) =>
                  employmentAllowances.find((item) => allowance.allowanceId === item.value),
                ) ?? []
              }
              setValue={(val: any) => {
                if (val !== "") {
                  formik.setFieldValue(
                    "allowances",
                    val
                      .slice(0, -1)
                      .split(",")
                      .map((id: string) => ({ allowanceId: id })),
                  );
                  formik.setFieldValue("attendAllowanceIds", val.slice(0, -1).split(","));
                } else {
                  formik.setFieldValue("allowances", []);
                }
              }}
              options={employmentAllowances}
              isMulti={true}
            />
          </div>
        </div>

        <div
          style={
            showSpecifiedWorkingSetting
              ? {
                  border: "1px solid #ccc",
                  padding: "5px 10px 25px 20px",
                  borderRadius: "5px",
                }
              : {}
          }
        >
          {showSpecifiedWorkingSetting && (
            <div style={{ width: gridCol.grid12, margin: "15px 10px 5px 0px" }}>
              <VerticalLabelSelectForm
                label="途中入退社時の所定労働時間&法定労働時間設定"
                name="specifiedWorkingHours"
                options={[
                  {
                    label: "在籍日数の割合を使用する",
                    value: "0",
                  },
                  {
                    label: "勤務日数✕有給のみなし労働時間を使用する",
                    value: "1",
                  },
                ]}
                value={formik.values.specifiedWorkingHours ? String(formik.values.specifiedWorkingHours) : "0"}
                setValue={(val) => formik.setFieldValue("specifiedWorkingHours", Number(val))}
                note={
                  formik.values.specifiedWorkingHours === 1
                    ? "※勤務日数✕有給のみなし労働時間を使用するを選択する場合は有給のみなし時間または日の契約労働時間を設定してください。"
                    : ""
                }
                isSearchable={false}
              />
            </div>
          )}

          <div style={showSpecifiedWorkingSetting ? {} : { display: "none" }}>
            <RadioSelectForm
              label="端数処理(小数第一位)"
              name="carryOverMonthRounding"
              items={[
                {
                  label: "切り捨てる",
                  value: "1",
                },
                {
                  label: "切り上げる",
                  value: "0",
                },
                {
                  label: "四捨五入",
                  value: "4",
                },
              ]}
              value={String(formik.values.carryOverMonthRounding)}
              setValue={formik.handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentOvertimeSettings;
