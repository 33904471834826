import { IconType } from "components/atoms/Icon";

export type MenuItem = {
  value: string;
  label: string;
  functionCode: string;
};

export type MenuListType = {
  label: string;
  icon?: IconType;
  child: Array<MenuItem>;
};

export const menuList: Array<MenuListType> = [
  {
    label: "ホーム",
    icon: "menuHome",
    child: [
      {
        value: "agreement36",
        label: "ホーム",
        functionCode: "201",
      },
      {
        value: "monthlyAgreement36",
        label: "月次",
        functionCode: "201_1",
      },
      {
        value: "yearlyAgreement36",
        label: "年次",
        functionCode: "201_2",
      },
    ],
  },
  {
    label: "勤怠管理",
    icon: "menuPeople",
    child: [
      {
        value: "attendApplicationApproval",
        label: "承認",
        functionCode: "202",
      },
      {
        value: "attendError",
        label: "エラー",
        functionCode: "207",
      },
      {
        value: "dailyShift",
        label: "日次勤怠",
        functionCode: "215",
      },
      {
        value: "monthlyShift",
        label: "月次勤怠",
        functionCode: "216",
      },
      {
        value: "attendHolidayManagement",
        label: "休暇",
        functionCode: "208",
      },
      {
        value: "attendAchievementLock",
        label: "勤怠ロック",
        functionCode: "219",
      },
      {
        value: "attendHeadquartersFinal",
        label: "確定",
        functionCode: "209",
      },
      {
        value: "timeRecalculation",
        label: "再計算",
        functionCode: "210",
      },
      {
        value: "attendAchievementOutput",
        label: "印刷",
        functionCode: "211",
      },
      {
        value: "attendCSVOutput",
        label: "CSV出力",
        functionCode: "212",
      },
    ],
  },
  {
    label: "スタッフ勤怠",
    icon: "menuSchedule",
    child: [
      {
        value: "attendStampList",
        label: "打刻データ確認",
        functionCode: "203",
      },
      {
        value: "hopeShift",
        label: "希望シフト",
        functionCode: "204",
      },
      {
        value: "shiftView",
        label: "シフト閲覧",
        functionCode: "205",
      },
      {
        value: "attendApplicationList",
        label: "勤怠申請",
        functionCode: "206",
      },
    ],
  },
  {
    label: "打刻実績",
    icon: "menuFactCheck",
    child: [
      {
        value: "stampHistory",
        label: "打刻実績",
        functionCode: "213",
      },
      {
        value: "GpsStampHistory",
        label: "GPS打刻実績",
        functionCode: "214",
      },
      {
        value: "kaoiroHistory",
        label: "Kaoiro打刻実績",
        functionCode: "218",
      },
    ],
  },
  {
    label: "一般マスタ",
    icon: "menuBusiness",
    child: [
      {
        value: "organizations",
        label: "組織",
        functionCode: "001",
      },
      {
        value: "staffs",
        label: "スタッフ",
        functionCode: "002",
      },
      {
        value: "executive",
        label: "役職",
        functionCode: "003",
      },
      {
        value: "holiday",
        label: "祝日",
        functionCode: "004",
      },
      {
        value: "masterRoleSetting",
        label: "権限",
        functionCode: "005",
      },
    ],
  },

  {
    label: "勤怠マスタ",
    icon: "menuCalendar",
    child: [
      {
        value: "vacationMaster",
        label: "休暇",
        functionCode: "101",
      },
      {
        value: "masterPaidHolidayPattern",
        label: "有給付与パターン",
        functionCode: "102",
      },
      {
        value: "masterTerm",
        label: "時間帯",
        functionCode: "103",
      },
      {
        value: "masterSpecialSalaryPattern",
        label: "特賃日設定",
        functionCode: "115",
      },
      {
        value: "attendAllowance",
        label: "手当設定",
        functionCode: "116",
      },
      {
        value: "masterWorkingDays",
        label: "所定労働日数",
        functionCode: "104",
      },
      {
        value: "masterWorkingHours",
        label: "所定労働時間",
        functionCode: "105",
      },
      {
        value: "masterEmploymentForm",
        label: "雇用形態",
        functionCode: "106",
      },
      {
        value: "masterAttendSetting",
        label: "勤怠設定",
        functionCode: "107",
      },
      {
        value: "masterOrganizeSetting",
        label: "組織設定",
        functionCode: "107_1",
      },
      {
        value: "masterShiftPattern",
        label: "シフトパターン",
        functionCode: "108",
      },
      {
        value: "masterSettingAgreement36",
        label: "36協定アラート設定",
        functionCode: "109",
      },
      {
        value: "masterTimeOutputLayout",
        label: "勤怠確定出力レイアウト",
        functionCode: "112",
      },
    ],
  },
  {
    label: "打刻管理",
    icon: "menuSetting",
    child: [
      {
        value: "masterShopFaceRegistration",
        label: "顔写真登録",
        functionCode: "110",
      },
      {
        value: "masterICCard",
        label: "ICカード登録",
        functionCode: "111",
      },
      {
        value: "masterShopSpecify",
        label: "端末設定",
        functionCode: "113",
      },
      {
        value: "stampIpSetting",
        label: "打刻設定",
        functionCode: "114",
      },
    ],
  },
];

export const defaultFunction = ["201"];
