import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';

import HolidayManagementImportForm from 'components/organismos/master/attend/HolidayManagementImportForm';

const HolidayManagementImportPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="休暇実績インポート"
  >
    <HolidayManagementImportForm />
  </SidebarTemplate>
);

export default HolidayManagementImportPage;
