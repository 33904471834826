/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import { useDownload } from './hooks';

const MonthlyDaySeparateSalesManagement: React.FC<{
  monthlyReport: any,
  targetPeriodData: any
  getMediaMst: any,
  compareToPreviousYear: any,
  orgName: any
}> = ({
  monthlyReport,
  targetPeriodData,
  getMediaMst,
  compareToPreviousYear,
  orgName,
}) => {
  const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 0,
  });
  let mergedRegions: any[] = [];
  const codeColumnIndex: any[] = [];
  const totalInformation: any[] = [];
  const orgCodeArr: any[] = [];
  const searchResult: any[] = [];
  useEffect(() => {
    if (monthlyReport) {
      monthlyReport.map((item: any, index: any) => {
        if (item.targetDate !== null) {
          const mediaList: any[] = [];
          if (item.mediaList) {
            item.mediaList.map((mediaData: any) => (
              mediaList.push(mediaData.paymentMediaAmount)
            ));
          }
          const searchItem: any[] = [];
          searchItem.push(
            moment(item.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）'),
            formatter.format(item.netSales),
            formatter.format(item.tax),
            formatter.format(item.totalSales),
          );
          if (mediaList.length > 0) {
            mediaList.map((mediaData: any) => (
              searchItem.push(mediaData)
            ));
          }
          searchItem.push(
            formatter.format(item.guestCnt),
            formatter.format(item.perCustomerPrice),
            formatter.format(item.groupCnt),
            formatter.format(item.perGroupPrice),
            formatter.format(item.discountAmount),
            formatter.format(item.discountCnt),
            formatter.format(item.voidAmount),
            formatter.format(item.voidCnt),
            formatter.format(item.stopAccountAmount),
            formatter.format(item.stopAccountCnt),
            formatter.format(item.cancelMenuAmount),
            formatter.format(item.cancelMenuCnt),
            formatter.format(item.changeReserve),
            formatter.format(item.cashStock),
            formatter.format(item.cashExcessOrDeficiency),
            formatter.format(item.bankDeposit),
          );
          searchResult.push(searchItem);
        } else {
          const mediaList: any[] = [];
          if (item.mediaList) {
            item.mediaList.map((mediaData: any) => (
              mediaList.push(mediaData.paymentMediaAmount)
            ));
          }
          const searchItem: any[] = [];
          searchItem.push(
            '合計',
            formatter.format(item.netSales),
            formatter.format(item.tax),
            formatter.format(item.totalSales),
          );
          if (mediaList.length > 0) {
            mediaList.map((mediaData: any) => (
              searchItem.push(mediaData)
            ));
          }
          searchItem.push(
            formatter.format(item.guestCnt),
            formatter.format(item.perCustomerPrice),
            formatter.format(item.groupCnt),
            formatter.format(item.perGroupPrice),
            formatter.format(item.discountAmount),
            formatter.format(item.discountCnt),
            formatter.format(item.voidAmount),
            formatter.format(item.voidCnt),
            formatter.format(item.stopAccountAmount),
            formatter.format(item.stopAccountCnt),
            formatter.format(item.cancelMenuAmount),
            formatter.format(item.cancelMenuCnt),
            formatter.format(item.changeReserve),
            formatter.format(item.cashStock),
            formatter.format(item.cashExcessOrDeficiency),
            formatter.format(item.bankDeposit),
          );
          totalInformation.push(searchItem);
        }
      });
    }
  }, [formatter, monthlyReport, orgCodeArr, orgName, searchResult, totalInformation]);
  const targetStoresData = `対象組織：${orgName.split(' ')[1]}`;
  // const headerInformation = [
  //   // eslint-disable-next-line quotes
  //   ['', '売上', '', '', '', '', '', '', '割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', ''],
  //   ['日付', '純売上', '消費税', '総売上', '客数', '客単価', '組数', '組単価', '金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金'],
  // ];
  let headerInformation: any = [];
  let subHeader: any = [];
  subHeader.push('', '売上', '', '');
  subHeader = subHeader.concat(Array(getMediaMst.length + 4).fill(''));
  subHeader.push('割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', '');
  headerInformation.push(subHeader);
  if (getMediaMst.length > 0) {
    subHeader = [];
    subHeader.push('', '', '', '');
    subHeader.push('支払い種別');
    subHeader = subHeader.concat(Array(getMediaMst.length - 1).fill(''));
    subHeader.push('', '', '', '');
    subHeader = subHeader.concat(Array(12).fill(''));
    headerInformation.push(subHeader);
  }

  subHeader = ['日付', '純売上', '消費税', '総売上'];
  if (getMediaMst.length > 0) {
    getMediaMst.map((value: any) => {
      subHeader.push(value);
    });
  }
  subHeader.push('客数', '客単価', '組数', '組単価');
  subHeader.push('金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金');
  headerInformation.push(subHeader);

  const data = {
    footer: '',
    formName: '月次日別売上管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    compareToPreviousYear,
    headerInformation,
    searchResult,
    totalInformation,
  };

  headerInformation = [];
  subHeader = [];
  subHeader.push('日付', '売上', '', '');
  subHeader = subHeader.concat(Array(getMediaMst.length + 4).fill(''));
  subHeader.push('割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', '');
  headerInformation.push(subHeader);
  subHeader = [];
  subHeader.push('', '純売上', '消費税', '総売上');
  if (getMediaMst.length > 0) {
    subHeader.push('支払い種別');
    subHeader = subHeader.concat(Array(getMediaMst.length - 1).fill(''));
  }
  subHeader.push('客数', '客単価', '組数', '組単価');

  subHeader = subHeader.concat(Array(12).fill(''));
  headerInformation.push(subHeader);

  subHeader = [];
  subHeader = subHeader.concat(Array(4).fill(''));
  if (getMediaMst.length) {
    getMediaMst.map((value: any) => {
      subHeader.push(value);
    });
  }
  subHeader = subHeader.concat(Array(4).fill(''));
  subHeader.push('金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金');
  headerInformation.push(subHeader);

  const dataExcel = {
    formName: '月次日別売上管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    compareToPreviousYear,
    headerInformation,
    searchResult,
    totalInformation,
    mergedRegions,
    codeColumnIndex,
  };

  const handleExportCSV = () => downloadMonthlyReportCsv(data);
  const handleExportExcel = () => {
    if (mergedRegions) {
      mergedRegions = [];
    }
    mergedRegions.push([4, 6, 0, 0]);
    mergedRegions.push([5, 6, 1, 1]);
    mergedRegions.push([5, 6, 2, 2]);
    mergedRegions.push([5, 6, 3, 3]);
    mergedRegions.push([4, 4, 1, 7 + getMediaMst.length]);
    if (getMediaMst.length) {
      mergedRegions.push([5, 5, 4, 4 + getMediaMst.length - 1]);
      mergedRegions.push([5, 6, 4 + getMediaMst.length, 4 + getMediaMst.length]);
      mergedRegions.push([5, 6, 4 + getMediaMst.length + 1, 4 + getMediaMst.length + 1]);
      mergedRegions.push([5, 6, 4 + getMediaMst.length + 2, 4 + getMediaMst.length + 2]);
      mergedRegions.push([5, 6, 4 + getMediaMst.length + 3, 4 + getMediaMst.length + 3]);
      mergedRegions.push([4, 5, 8 + getMediaMst.length, 9 + getMediaMst.length]);
      mergedRegions.push([4, 5, 10 + getMediaMst.length, 11 + getMediaMst.length]);
      mergedRegions.push([4, 5, 12 + getMediaMst.length, 13 + getMediaMst.length]);
      mergedRegions.push([4, 5, 14 + getMediaMst.length, 15 + getMediaMst.length]);
      mergedRegions.push([4, 5, 16 + getMediaMst.length, 19 + getMediaMst.length]);
    } else {
      mergedRegions.push([5, 6, 4, 4]);
      mergedRegions.push([5, 6, 5, 5]);
      mergedRegions.push([5, 6, 6, 6]);
      mergedRegions.push([5, 6, 7, 7]);
      mergedRegions.push([4, 5, 8, 9]);
      mergedRegions.push([4, 5, 10, 11]);
      mergedRegions.push([4, 5, 12, 13]);
      mergedRegions.push([4, 5, 14, 15]);
      mergedRegions.push([4, 5, 16, 19]);
    }
    dataExcel.mergedRegions = mergedRegions;
    dataExcel.codeColumnIndex = [];
    // dataExcel.headerInformation = [
    //   ['日付', '売上', '', '', '', '', '', '', '割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', ''],
    //   ['', '純売上', '消費税', '総売上', '客数', '客単価', '組数', '組単価', '', '', '', '', '', '', '', '', '', '', '', ''],
    //   ['', '', '', '', '', '', '', '', '金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金'],
    // ];
    dataExcel.headerInformation = headerInformation;
    return downloadMonthlyReportExcel(dataExcel);
  };

  const widthScrollbar = monthlyReport ? ((20 + getMediaMst.length) * 131) + 100 : 0;
  const [getFlagBorder, setFlagBorder] = useState(0);
  const eventScroll = (e: { target: any }) => {
    const { target } = e;
    const wrapTblRight = document.getElementById('wrapTblRight');
    const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
    const jsScrollAction = document.getElementById('jsScrollAction');
    const wrapFootertblRight = document.getElementById('wrapFootertblRight');


    if (Number(target.scrollLeft) > 0) {
      setFlagBorder(1);
    } else {
      setFlagBorder(0);
    }

    if (wrapTblRight) {
      wrapTblRight.scrollLeft = target.scrollLeft;
    }
    if (wrapTblHeadRight) {
      wrapTblHeadRight.scrollLeft = target.scrollLeft;
    }
    if (jsScrollAction) {
      jsScrollAction.scrollLeft = target.scrollLeft;
    }
    if (wrapFootertblRight) {
      wrapFootertblRight.scrollLeft = target.scrollLeft;
    }
  };

  return (
    <div>
      <FlexBox>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportCSV}
            iconType="download"
            text=" CSV出力"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportExcel}
            iconType="download"
            text=" EXCEL出力"
          />
        </FlexBoxItem>
      </FlexBox>

      <div className="wrap_time_day_separate_sales wrap_monthly_day_separate_sales_management" style={{ overflow: 'hidden', marginTop: '15px' }}>
        <div className="contentHead" style={{ display: 'flex' }}>
          <div className="tblLeft" style={getFlagBorder ? { borderRight: '1px solid' } : {}}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} className="text-center" style={{ top: '17px', width: '100%', background: 'transparent' }}><span>売上</span></th>
                </tr>
                <tr>
                  <th className="text-center thead-bottom"><span>日付</span></th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tblRight" id="wrapTblHeadRight" style={(monthlyReport && monthlyReport.length > 17) ? { width: 'calc(100% - 211px)' } : { width: 'calc(100% - 194px)' }} onScroll={eventScroll}>
            <table>
              <thead>
                <tr>
                  <th colSpan={7 + getMediaMst.length} className="text-center">売上</th>
                  <th colSpan={2} rowSpan={getMediaMst.length > 0 ? 2 : 1} className="text-center"><span style={{ width: '100%' }}>割引券</span></th>
                  <th colSpan={2} rowSpan={getMediaMst.length > 0 ? 2 : 1} className="text-center"><span style={{ width: '100%' }}>VOID</span></th>
                  <th colSpan={2} rowSpan={getMediaMst.length > 0 ? 2 : 1} className="text-center"><span style={{ width: '100%' }}>会計中止</span></th>
                  <th colSpan={2} rowSpan={getMediaMst.length > 0 ? 2 : 1} className="text-center"><span style={{ width: '100%' }}>メニュー取消</span></th>
                  <th colSpan={4} rowSpan={getMediaMst.length > 0 ? 2 : 1} className="text-center"><span style={{ width: '100%' }}>レジ金</span></th>
                </tr>
                {
                    getMediaMst.length > 0
                      ? (
                        <tr>
                          <th colSpan={getMediaMst.length + 7} className="text-center"><span style={{ width: '100%' }}>支払い種別</span></th>
                        </tr>
                      )
                      : null
                  }
                <tr>
                  <th className="text-center"><span>純売上</span></th>
                  <th className="text-center"><span>消費税</span></th>
                  <th className="text-center"><span>総売上</span></th>
                  {
                      getMediaMst.map((media: any, index: any) => (
                        <th key={index} className="text-center"><span>{media}</span></th>
                      ))
                    }
                  <th className="text-center"><span>客数</span></th>
                  <th className="text-center"><span>客単価</span></th>
                  <th className="text-center"><span>組数</span></th>
                  <th className="text-center"><span>組単価</span></th>
                  <th className="text-center"><span>金額</span></th>
                  <th className="text-center"><span>枚数</span></th>
                  <th className="text-center"><span>金額</span></th>
                  <th className="text-center"><span>件数</span></th>
                  <th className="text-center"><span>金額</span></th>
                  <th className="text-center"><span>件数</span></th>
                  <th className="text-center"><span>金額</span></th>
                  <th className="text-center"><span>件数</span></th>
                  <th className="text-center"><span>釣銭準備金</span></th>
                  <th className="text-center"><span>現金在高</span></th>
                  <th className="text-center"><span>現金過不足</span></th>
                  <th className="text-center"><span>銀行入金</span></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <div>
            <div className="contentTable" style={{ display: 'flex' }}>
              <div className="tblLeft">
                <table>
                  <tbody>
                    {
                        monthlyReport ? monthlyReport.map((item: any, index: any) => {
                          if (item.targetDate !== null) {
                            return (
                              <tr key={index}>
                                <td className="text-center">{moment(item.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）')}</td>
                              </tr>
                            );
                          }
                        }) : null
                      }
                  </tbody>
                </table>
              </div>
              <div className="tblRight" id="wrapTblRight" onScroll={eventScroll} style={(monthlyReport && monthlyReport.length < 17) ? { width: 'calc(100% - 194px)' } : {}}>
                <table id="tblHeadTable" onScroll={eventScroll}>
                  <tbody>
                    {
                        monthlyReport && monthlyReport.map((item: any, index: any) => {
                          if (item.targetDate !== null) {
                            return (
                              <tr key={index}>
                                <td className="text-right"><span>{formatter.format(item.netSales)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.tax)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.totalSales)}</span></td>
                                {
                                  item.mediaList ? item.mediaList.map((mediaData: any, idx: any) => (
                                    <td className="text-right" key={idx}><span>{formatter.format(mediaData.paymentMediaAmount)}</span></td>
                                  )) : null
                                }
                                <td className="text-right"><span>{formatter.format(item.guestCnt)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.perCustomerPrice)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.groupCnt)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.perGroupPrice)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.discountAmount)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.discountCnt)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.voidAmount)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.voidCnt)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.stopAccountAmount)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.stopAccountCnt)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.cancelMenuAmount)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.cancelMenuCnt)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.changeReserve)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.cashStock)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.cashExcessOrDeficiency)}</span></td>
                                <td className="text-right"><span>{formatter.format(item.bankDeposit)}</span></td>
                              </tr>
                            );
                          }
                        })
                      }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {
            monthlyReport ? (monthlyReport.length > 0 ? (
              <div className="scroller">
                {/* <div className="scrollLeft" /> */}
                <div className="scrollRight" id="jsScrollAction" style={{ width: '100%', height: '16px' }} onScroll={eventScroll}>
                  <div className="scrollAction" style={{ width: `${widthScrollbar}px` }} />
                </div>
              </div>
            ) : null) : null
          }
        <div className="contentFooter">
          <div className="tblLeft">
            <table>
              <tfoot>
                {
                    monthlyReport && monthlyReport.map((slitDataTotal: any, index: any) => {
                      if (slitDataTotal.targetDate === null) {
                        return (
                          <tr key={index}>
                            <th className="text-center">合計</th>
                          </tr>
                        );
                      }
                    })
                  }
              </tfoot>
            </table>
          </div>
          <div className="tblRight" id="wrapFootertblRight" onScroll={eventScroll} style={(monthlyReport && monthlyReport.length > 17) ? { width: 'calc(100% - 211px)' } : { width: 'calc(100% - 194px)' }}>
            <table>
              <tfoot>
                {
                    monthlyReport && monthlyReport.map((slitDataTotal: any, index: any) => {
                      if (slitDataTotal.targetDate === null) {
                        return (
                          <tr key={index}>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.netSales)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.tax)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.totalSales)}</span></th>
                            {
                              slitDataTotal.mediaList ? slitDataTotal.mediaList.map((mediaData: any, index: any) => (
                                <th className="text-right" key={index}>
                                  <span>
                                    {formatter.format(mediaData.paymentMediaAmount)}
                                  </span>
                                </th>
                              )) : null
                            }
                            <th className="text-right"><span>{formatter.format(slitDataTotal.guestCnt)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.perCustomerPrice)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.groupCnt)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.perGroupPrice)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.discountAmount)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.discountCnt)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.voidAmount)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.voidCnt)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.stopAccountAmount)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.stopAccountCnt)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.cancelMenuAmount)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.cancelMenuCnt)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.changeReserve)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.cashStock)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.cashExcessOrDeficiency)}</span></th>
                            <th className="text-right"><span>{formatter.format(slitDataTotal.bankDeposit)}</span></th>
                          </tr>
                        );
                      }
                    })
                  }
              </tfoot>
            </table>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MonthlyDaySeparateSalesManagement;
