import React, { SVGProps } from 'react';

const Question: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.5 14.25V14.24C28.5 13.81 28.47 13.38 28.44 12.96C28.43 12.85 28.42 12.74 28.41 12.63C28.31 11.74 28.13 10.88 27.88 10.05C27.86 10 27.84 9.95 27.83 9.89C27.71 9.53 27.59 9.18 27.44 8.83C27.39 8.72 27.34 8.6 27.29 8.49C27.16 8.21 27.03 7.93 26.89 7.65C26.83 7.53 26.77 7.41 26.7 7.3C26.55 7.02 26.38 6.75 26.21 6.49C26.15 6.4 26.09 6.3 26.03 6.21C25.55 5.51 25 4.85 24.4 4.24C24.32 4.16 24.24 4.09 24.16 4.02C23.93 3.8 23.7 3.59 23.46 3.38C23.36 3.29 23.25 3.21 23.15 3.12C22.92 2.93 22.68 2.76 22.43 2.58C22.32 2.5 22.21 2.43 22.1 2.35C21.82 2.16 21.53 1.99 21.23 1.82C21.14 1.79 21.07 1.74 21 1.7C20.62 1.5 20.24 1.31 19.84 1.14C19.76 1.11 19.68 1.08 19.6 1.05C19.28 0.92 18.96 0.8 18.63 0.7C18.5 0.66 18.36 0.62 18.22 0.58C17.94 0.5 17.65 0.42 17.36 0.36C17.21 0.33 17.06 0.29 16.91 0.27C16.61 0.21 16.3 0.17 16 0.13C15.86 0.11 15.73 0.09 15.59 0.08C15.15 0.03 14.7 0 14.25 0C6.38 0 0 6.38 0 14.25C0 16.7 0.62 19.07 1.79 21.16L0.04 27.29C-0.16 28.01 0.5 28.67 1.21 28.46L7.34 26.71C8.21 27.19 9.13 27.58 10.08 27.87C10.9 28.12 11.74 28.29 12.61 28.39C12.73 28.4 12.85 28.42 12.97 28.43C13.38 28.47 13.8 28.49 14.22 28.49C14.23 28.49 14.24 28.49 14.25 28.49C22.12 28.5 28.5 22.12 28.5 14.25ZM7.2 24.78L2.33 26.17L3.72 21.3C3.79 21.05 3.76 20.78 3.62 20.55C2.5 18.66 1.9 16.5 1.9 14.25C1.9 7.43 7.43 1.9 14.25 1.9C21.07 1.9 26.6 7.43 26.6 14.25C26.6 21.07 21.07 26.6 14.25 26.6C12 26.6 9.84 26 7.95 24.87C7.72 24.74 7.45 24.7 7.2 24.78Z" fill="#007BC3"/>
    <path d="M36.2095 30.6602C37.3695 28.5602 37.9995 26.2002 37.9995 23.7502C37.9995 19.5802 36.1895 15.7802 33.2495 13.1502C32.8795 12.8202 32.3195 12.8602 31.9695 13.2102C31.5895 13.5902 31.5995 14.2102 31.9995 14.5602C34.5395 16.8402 36.0995 20.1402 36.0995 23.7502C36.0995 26.0002 35.4995 28.1502 34.3795 30.0402C34.2395 30.2702 34.1995 30.5402 34.2695 30.8002L35.6595 35.6702L30.7895 34.2802C30.5295 34.2102 30.2595 34.2502 30.0295 34.3902C28.1395 35.5102 25.9895 36.1102 23.7395 36.1102C20.1495 36.1102 16.8595 34.5702 14.5895 32.0502C14.2395 31.6602 13.6195 31.6502 13.2395 32.0202C12.8895 32.3702 12.8495 32.9402 13.1795 33.3002C15.8195 36.2002 19.5995 38.0002 23.7495 38.0002C26.1995 38.0002 28.5695 37.3802 30.6595 36.2102L36.7895 37.9602C37.5095 38.1602 38.1695 37.5002 37.9595 36.7902L36.2095 30.6602Z" fill="#003993"/>
  </svg>

);

export default Question;
