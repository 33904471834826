import moment from 'moment';
import { FiscalYear } from 'api/fiscalYear';

export default class FiscalYearDomain {
  private rawData: FiscalYear

  constructor(rawData: FiscalYear) {
    this.rawData = {
      ...rawData,
      id: {
        companyCode: rawData.id.companyCode,
        fiscalYear: rawData.id.fiscalYear,
      },
      fiscalYearStr: `${rawData.id.fiscalYear}年`,
      fiscalStartMonthStr: moment(rawData.fiscalStartMonth).format('YYYY年MM月'),
      fiscalEndMonthStr: moment(rawData.fiscalEndMonth).format('YYYY年MM月'),
    };
  }

  static generateInitial(): FiscalYearDomain {
    return new FiscalYearDomain({
      id: {
        companyCode: '',
        fiscalYear: moment().format('YYYY'),
      },
      fiscalStartMonth: moment().format('YYYY/MM/01'),
      fiscalEndMonth: moment().format('YYYY/MM/01'),
      createUser: '',
      updateUser: '',
      createDate: '',
      updateDate: '',
      isCreate: false,
      fiscalYearStr: '',
      fiscalStartMonthStr: '',
      fiscalEndMonthStr: '',
    });
  }

  getRawData(): FiscalYear {
    return this.rawData;
  }

  getRawDataPost(): any {
    return {
      fiscalYearStr: this.rawData.fiscalYearStr,
      fiscalStartMonthStr: this.rawData.fiscalStartMonthStr,
      fiscalEndMonthStr: this.rawData.fiscalEndMonthStr,
      isCreate: true,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  get companyCode(): string {
    return this.rawData.id.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.id.companyCode = companyCode;
  }

  get fiscalYear(): string {
    return `${this.rawData.id.fiscalYear}`;
  }

  set fiscalYear(fiscalYear: string) {
    this.rawData.id.fiscalYear = fiscalYear;
    this.rawData.fiscalYearStr = `${fiscalYear}年`;
  }

  get fiscalYearStr(): string {
    return `${this.rawData.fiscalYearStr}`;
  }

  get fiscalStartMonth(): Date {
    return new Date(this.rawData.fiscalStartMonth.replace(/-/g, '/'));
  }

  set fiscalStartMonth(date: Date) {
    this.rawData.fiscalStartMonth = date !== null ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
    this.rawData.fiscalStartMonthStr = date !== null ? moment(date).format('YYYY年MM月') : moment().format('YYYY年MM月');
  }

  get fiscalEndMonth(): Date {
    return new Date(this.rawData.fiscalEndMonth.replace(/-/g, '/'));
  }

  set fiscalEndMonth(date: Date) {
    this.rawData.fiscalEndMonth = date !== null ? moment(date).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
    this.rawData.fiscalEndMonthStr = date !== null ? moment(date).format('YYYY年MM月') : moment().format('YYYY年MM月');
  }

  get fiscalStartMonthStr(): string {
    return this.rawData.fiscalStartMonthStr;
  }

  get fiscalEndMonthStr(): string {
    return this.rawData.fiscalEndMonthStr;
  }
}
