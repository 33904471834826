import axios, { AxiosRequestConfig } from "axios";
import { stringify } from "qs";

const domainUrl = process.env.REACT_APP_API_BASE_URL;
const cognitoUrl = process.env.REACT_APP_COGNITO_URL;
const authorization = process.env.REACT_APP_AUTHORIZATION_CODE;

//Get applying-overTime History
export const getToken = async (query?: any): Promise<any> => {
  const url = `${cognitoUrl}/oauth2/token`;
  const requestUrl = query ? `${url}?${stringify(query)}` : url;

  const config: AxiosRequestConfig = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${authorization}`,
    },
    // validateStatus,
  };
  const response = await axios.post(requestUrl, {}, config);

  return response;
};

export const getUserInfo = async (token?: string): Promise<any> => {
  const url = `${cognitoUrl}/oauth2/userInfo`;

  const config: AxiosRequestConfig = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(url, config);
  return response;
};

export const getLoginUser = async (mail: string, token: string, companyCode?: string): Promise<any> => {
  // TODO 仮リクエスト
  const params = {
    mail,
    siteType: "HQ",
  };

  const url = domainUrl + (companyCode ? `/v3/loginuser/${companyCode}` : "/v2/loginuser");

  const config: AxiosRequestConfig = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Authorization: `${token}`,
      ...(companyCode ? { "Auth-Tenant": companyCode } : undefined),
    },
    params,
  };

  const response = await axios.get(url, config);

  return response;
};

export const getLoginUserPeople = async (mail: string): Promise<any> => {
  // TODO 仮リクエスト
  const email = mail;
  const userId = localStorage.getItem("PEOPLE_USER_ID");
  const tenant = localStorage.getItem("PEOPLE_TENANT");
  const clientId = process.env.REACT_APP_PEOPLE_CLIENT_ID;
  const params = {
    email,
    user_id: userId,
    tenant,
    client_id: clientId,
  };

  const url = domainUrl + "/v1/loginuserPeople";

  const config: AxiosRequestConfig = {
    headers: {
      // 'Content-type':'application/x-www-form-urlencoded',
      // 'Authorization':`${token}`
    },
    params,
  };
  // console.log(params)

  const response = await axios.get(url, config);

  return response;
};
