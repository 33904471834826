import React from 'react';
import RecorderV2 from 'components/organismos/RecorderV2';
import { useHistory } from "react-router-dom";
import { checkPermissionAccessHokuyo } from 'hooks/checkPermissionAcess'

const RecorderPage_v2 = () => {
  const history = useHistory();

  if(!checkPermissionAccessHokuyo()){
    history.push('/404');
  }
  return <RecorderV2/>
};

export default RecorderPage_v2;
