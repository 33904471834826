/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const style = css({
  maxWidth: '600px',
  padding: '30px',
  marginBottom: '30px',
});
/**
 * フォームのコンテンツ
 */
const FormContentFilter: React.FC<{
  customStyles?:any;
  maxWidth?: string;
}> = ({
  children, customStyles, maxWidth
}) => (
  <div css={css([style,customStyles, {maxWidth: maxWidth}])}>
    {children}
  </div>
);

export default FormContentFilter;
