import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import VacationCreateForm from 'components/organismos/master/labor/VacationCreateForm';

const VacationCreatePage: React.FC = () => {
  return (
    <SidebarTemplate>
      <VacationCreateForm />
    </SidebarTemplate>
  );
};
export default VacationCreatePage;
