/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import ClickableLink from 'components/atoms/ClickableLink';
import productColor, { textFontSize, grayScale } from 'components/styles';

const style = css({
  padding: '3px 5px',
  backgroundColor: '#ffff',
  fontSize: textFontSize.re,
  ':hover': {
    backgroundColor: productColor.primaryM95,
  },
});

export interface DropdownItemProps {
  /**
   * 値
   */
  value: number|string;
  /**
   * 表示名(指定されなければ value の値が表示される)
   */
  label?: string;
  /**
   * リンク先
   */
  linkTo?: string;
  /**
   * リンクでなければ必須
   */
  onClick?: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  value,
  label: display,
  linkTo,
  onClick,
}) => (
  <div css={style}>
    <ClickableLink to={linkTo} onClick={onClick} color={grayScale.gray100}>
      {display || value}
    </ClickableLink>
  </div>
);

export default DropdownItem;
