/** @jsx jsx */
import React from 'react';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import FormContents from 'components/atoms/Form/FormContents';
import { useOrgAllTable } from 'hooks/useOrgTreesOptions';
import ConfirmModal from 'components/organismos/ConfirmModal';
import moment from 'moment';
import { useHolidayAddForm } from './hooks';
import FormPadding from 'components/atoms/Form/FormPadding';
import { Row, Col } from 'react-grid-system';
import CalendarForm from 'components/molecules/CalendarForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { grayScale, textFontSize } from 'components/styles';
import { css, jsx } from '@emotion/core';
import Table from 'components/molecules/Table';
import Checkbox from 'components/molecules/Checkbox';
import LableTitle from 'components/atoms/LableTitle';
import FormTitle from 'components/atoms/Form/FormTitle';
import BlockUI from 'components/molecules/BlockUi';

const HolidayAddForm: React.FC<{publicHolidayId?: string}> = ({
  publicHolidayId,
}) => {
  const {
    formik, confirmModalOpen, closeConfirmModal, history, selectedIndex, setSelectedIndex, selectedOrgCodes, setSelectedOrgCodes,
    isLoading,
    detailRole
  } = useHolidayAddForm(publicHolidayId);
  const startAndEndDate = moment(formik.values.holidayDate).format('YYYY-MM-DD');
  const orgTreesOptions = useOrgAllTable(false, startAndEndDate, startAndEndDate);

  const style = {
    color: `${grayScale.gray60}`,
    fontSize: `${textFontSize.re}`
  };


  return (
    <BlockUI blocking={isLoading}>
    <FormContents>
      <FormTitle bold={true} title={`${publicHolidayId ? "祝日編集" : "祝日作成"}`} />
      <FormPadding>
        <form onSubmit={formik.handleSubmit}>
          <Row style={{ margin: '15px 0' }}>
            <Col md={1} xs={2}>
              <span style={style}>日付</span>
            </Col>
            <Col md={3} xs={6}>
              <FlexBoxItem width="160px" customStyle={css({ marginTop: '-10px' })}>
                <CalendarForm
                  label=""
                  dateFormat="YYYY/MM/DD"
                  date={formik.values.holidayDate}
                  changeDate={(date: Date) => formik.setFieldValue('holidayDate', date)}
                  required={true}
                />
              </FlexBoxItem>
            </Col>
          </Row>

          <Row style={{ margin: '15px 0' }}>
            <Col md={1} xs={2}>
              <span style={style}>祝日名</span>
            </Col>
            <Col md={3} xs={6}>
              <FlexBoxItem width="320px" customStyle={css({ marginTop: '-10px' })}>
                <TextForm
                  name="holidayName"
                  label=""
                  value={formik.values.holidayName}
                  onChange={formik.handleChange}
                  required={true}
                  placeHolder="元旦"
                />
              </FlexBoxItem>
            </Col>
          </Row>
          <div style={publicHolidayId !== '' ? { pointerEvents: 'none' } : {}} />

          {/* <div style={String(formik.values.isEdit) === 'true' ? {} : { display: 'none' }}>
            <p>
              組織：
              {formik.values.dispOrg}
            </p>
          </div> */}
          {/* <div style={String(formik.values.isEdit) === 'false' ? {} : { display: 'none' }}>
            <FormField>
              <RadioSelectForm
                label="組織選択"
                name="isAll"
                items={[
                  {
                    label: '全ての組織に設定',
                    value: 'all',
                  },
                  {
                    label: '組織を選択して設定',
                    value: 'selection',
                  },
                ]}
                value={String(formik.values.isAll ? 'all' : 'selection')}
                setValue={(e) => formik.setFieldValue('isAll', e.target.value === 'all')}
                readOnly={true}
              />
            </FormField>
          </div> */}

          {/* <div style={String(formik.values.isEdit) === 'false' && String(formik.values.isAll) === 'false' ? {} : { display: 'none' }}>
            <VerticalLabelMutilSelectForm
              label=""
              name="orgCodes"
              values={formik.values.orgCodes.map(
                (orgCode: string) => orgTreesOptions.find((item) => orgCode === item.value),
              )}
              setValue={(val: any) => {
                formik.setFieldValue('orgCodes', val.slice(0, -1).split(','));
              }}
              options={orgTreesOptions}
              isMulti={true}
              disable={formik.values.publicHolidayId !== ''}
            />
          </div> */}

        </form>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title={`${publicHolidayId ? '祝日マスタを更新' : '祝日マスタを登録'}`}
          content={`${publicHolidayId ? '祝日マスタを更新します。よろしいですか？' : '祝日マスタを登録します。よろしいですか？'}`}
          submitText={publicHolidayId ? '更新' : '登録する'}
        />
      </FormPadding>

      {(String(formik.values.publicHolidayId) === '' || String(formik.values.publicHolidayId) === undefined) && (
      <div>
        <FormPadding>
          <LableTitle title="表示されている祝日を割り当てる組織をチェックしてください。" />
        </FormPadding>
        <div
          css={css`
          table th{
            text-align: left
          }
          table th:nth-of-type(1), table td:nth-of-type(1){
            width: 40px;
            text-align: center
          }
          table th:nth-of-type(2), table td:nth-of-type(2){
            width: 400px;
          }
          tbody {
            display:block;
            height:500px;
            overflow:auto;
          }
          thead tr, tbody tr {
              display:table;
              width:100%;
          }
          `}
        >
          <Table className="table-no-border-left-right">
            <thead>
              <tr>
                <Table.HeaderCell>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  組織コード
                </Table.HeaderCell>
                <Table.HeaderCell>
                  組織名
                </Table.HeaderCell>
              </tr>
            </thead>
            <tbody>
              <tr key='all'>
                <Table.Cell customStyle={css({ width: '8px' })}>
                  <Checkbox
                    id="checkbox_stamp_all"
                    name="checkbox_stamp_all"
                    value='all'
                    checked={orgTreesOptions.length === selectedIndex.length && orgTreesOptions.length !== 0}
                    isCheckBoxTable={true}
                    onChange={(e) => {
                      if (orgTreesOptions.length === selectedIndex.length) {
                        setSelectedIndex([]);
                        formik.setFieldValue('isAll', false)
                      } else {
                        const allIndex = Object.keys(orgTreesOptions).map(Number);
                        setSelectedIndex(allIndex);
                        formik.setFieldValue('isAll', e.target.value === 'all')
                      }
                    }}
                  />
                </Table.Cell>
                <Table.Cell colSpan={2} customStyle={css({ textAlign: 'center' })}>全ての組織</Table.Cell>
              </tr>
              {orgTreesOptions && orgTreesOptions.length > 0 && (
                orgTreesOptions.map((org, index) => 
                  (
                  <tr key={index.toString()}>
                    <Table.Cell customStyle={css({ width: '8px' })}>
                      <Checkbox
                        id={org.value}
                        name={org.value}
                        value={org.value}
                        checked={selectedIndex.includes(index)}
                        isCheckBoxTable={true}
                        onChange={(e) => {
                          if (selectedIndex.includes(index)) {
                            setSelectedIndex(
                              selectedIndex.filter(
                                (selected) => selected !== index,
                              ),
                            );
                            setSelectedOrgCodes(
                              selectedOrgCodes.filter(
                                (selected) => selected !== e.target.value,
                              ),
                            );
                          } else {
                            setSelectedIndex([...selectedIndex, index]);
                            setSelectedOrgCodes([...selectedOrgCodes, e.target.value]);
                          }
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {org.value}
                    </Table.Cell>
                    <Table.Cell>
                      {org.label}
                    </Table.Cell>
                  </tr>
                ))
              )
              }
            </tbody>
          </Table>
        </div>
      </div>
      )}
      <FormSubmitArea customStyle={css({ textAlign: 'center', marginTop: '50px' })}>
        {detailRole.editable === 1 && (
          <div>
            <Button
              text={publicHolidayId ? '更新' : '登録'}
              onClick={formik.handleSubmit}
            />

            {/* <SecondaryButton
              text={'削除'}
              onClick={() => {}}
              minWidth="120px"
              customStyle={css({ marginLeft: '10px' })}
            /> */}
          </div>
        )}        
        <Button
          text="キャンセル"
          ghost={true}
          onClick={() => {
            history.goBack();
          }}
        />
      </FormSubmitArea>
    </FormContents>
    </BlockUI>
  );
};

export default HolidayAddForm;