/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

const style = css({
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
  zIndex: 100,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

const FullScreenOverlay: React.FC<{
  open: boolean;
}> = ({ children, open }) => {
  if (!open) {
    return null;
  }
  return <div css={style}>{children}</div>;
};

export default FullScreenOverlay;
