import moment from "moment";
import { HopeShiftDomain } from "domain/master/general/hopeShift";
import ApiClient from "./ApiClient";
import { viewPeriod } from "api/schedule";

export enum HopeShiftStatusLabel {
  "未提出 " = 0,
  "提出済" = 1,
  "シフト確定済" = 2,
  "未提出" = 9,
}

export enum HopeShiftMobileStatusLabel {
  "未提出 " = 0,
  "提出済" = 1,
  "確定済" = 2,
  "未提出" = 9,
}

export enum HopeShiftStatus {
  NO_REGISTER = 0,
  NOT_APPROVED = 1,
  APPROVED = 2,
  UNSUBMITED = 9,
}

export enum HopeShiftStatusColor {
  "#ED5D5D" = 0,
  "#45D1D1" = 1,
  "#22AEE5" = 2,
  "#ED5D5D " = 9,
}

type HopeShift = {
  aaHolidayName: string;
  bgColor: string;
  businessName: string;
  dayStatus: number;
  employmentId: string;
  endTime: Date;
  fixStatus: number;
  holidayName: string;
  hopeShiftId: string;
  hopeShiftPatternType: number;
  hopeShiftShortName: string;
  mobileBgColor: string;
  note: string;
  orgCode: string;
  shiftPatternId: string;
  staffCode: string;
  startTime: Date;
  targetDate: string;
  distinctionHoliday: boolean;
  useHoliday: boolean;
};

export type ShiftDetail = {
  startTime: string;
  isStartTimeNextDay: boolean;
  endTime: string;
  isEndTimeNextDay: boolean;
  businessId?: string;
  createUser?: string;
  updateUser?: string;
};

export type ShiftPostData = {
  orgCode: string;
  staffCode: string;
  targetDate: string;
  procType: number;
  attendType: number;
  shiftId?: string;
  shiftPatternId?: string;
  startTime?: string;
  endTime?: string;
  createUser?: string;
  updateUser?: string;
  shiftDetailList: Array<ShiftDetail>;
};

export const postHopeShifts = async (
  targetDate: Date,
  hopeShiftsPostData: HopeShiftDomain[],
): Promise<Array<HopeShift>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const targetDateStr = moment(targetDate).format("YYYY/MM/DD");

  const queryParam = {
    staffCode,
    targetDate: targetDateStr,
    viewPeriod: viewPeriod.MONTHLY,
  };

  const postData: any = {
    hopeShiftList: hopeShiftsPostData
      .map((item) => {
        const x = {
          ...item.getRawData(),
          startTime: item.startTime === null ? "" : moment(item.startTime).format("YYYY-MM-DDTHH:mm:ss"),
          endTime: item.endTime === null ? "" : moment(item.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        };

        delete (x as any).isEditable;
        delete (x as any).holidayName;
        delete (x as any).employmentId;

        return x;
      })
      .filter((item) => item.fixStatus === 9),
  };

  const response = await ApiClient.post(`/v2/personal/createHopeShifts/${companyCode}`, queryParam, postData);

  return response.data;
};

export default {};
