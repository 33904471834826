import React, { SVGProps } from 'react';

const HrWorkflow: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="41" height="46" viewBox="0 0 41 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.7098 35.8193L32.3398 33.3793C34.2298 31.7793 35.3098 29.4593 35.3098 26.9693C35.3098 22.3393 31.5398 18.5693 26.9098 18.5693C22.2798 18.5693 18.5098 22.3393 18.5098 26.9693C18.5098 29.4593 19.5898 31.7793 21.4798 33.3793L16.1098 35.8193C14.5798 36.5193 13.5898 38.0493 13.5898 39.7293V40.8993C13.5898 43.2693 15.5198 45.1993 17.8898 45.1993H35.9198C38.2898 45.1993 40.2198 43.2693 40.2198 40.8993V39.7293C40.2298 38.0493 39.2398 36.5193 37.7098 35.8193ZM23.5698 34.6693C25.6798 35.5893 28.1398 35.5893 30.2498 34.6693L36.8598 37.6693C37.6598 38.0393 38.1798 38.8393 38.1798 39.7293V40.8993C38.1798 42.1493 37.1698 43.1593 35.9198 43.1593H17.8998C16.6498 43.1593 15.6398 42.1493 15.6398 40.8993V39.7293C15.6398 38.8493 16.1598 38.0393 16.9598 37.6693L23.5698 34.6693ZM20.5498 26.9693C20.5498 23.4693 23.3998 20.6093 26.9098 20.6093C30.4198 20.6093 33.2698 23.4593 33.2698 26.9693C33.2698 30.4793 30.4198 33.3293 26.9098 33.3293C23.3998 33.3293 20.5498 30.4693 20.5498 26.9693Z" fill="#007BC3"/>
    <path d="M32.1895 15.3994C31.6795 15.3994 31.2695 15.8294 31.2695 16.3594V19.8094C31.9495 20.2294 32.5695 20.7394 33.1095 21.3294V16.3594C33.1095 15.8294 32.6995 15.3994 32.1895 15.3994Z" fill="#003993"/>
    <path d="M3.14 26.9099H18.52C18.52 26.2499 18.61 25.6099 18.76 24.9899H3.14L7.09 20.8699C7.45 20.4999 7.45 19.8899 7.09 19.5099C6.73 19.1399 6.15 19.1399 5.79 19.5099L0.27 25.2699C-0.09 25.6399 -0.09 26.2499 0.27 26.6299L5.79 32.3899C6.15 32.7599 6.73 32.7599 7.09 32.3899C7.45 32.0199 7.45 31.4099 7.09 31.0299L3.14 26.9099Z" fill="#003993"/>
    <path d="M0.92 18.2697C1.43 18.2697 1.84 17.8397 1.84 17.3097V11.5497C1.84 9.95969 3.08 8.66969 4.6 8.66969H29.97L26.02 12.7897C25.66 13.1597 25.66 13.7697 26.02 14.1497C26.38 14.5197 26.96 14.5197 27.32 14.1497L32.84 8.38969C33.2 8.01969 33.2 7.40969 32.84 7.02969L27.32 1.26969C26.96 0.899687 26.38 0.899687 26.02 1.26969C25.66 1.63969 25.66 2.24969 26.02 2.62969L29.97 6.74969H4.6C2.06 6.74969 0 8.89969 0 11.5497V17.3097C0 17.8397 0.41 18.2697 0.92 18.2697Z" fill="#003993"/>
  </svg>

);

export default HrWorkflow;
