/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { jsx } from "@emotion/core";
import FormContents from "components/atoms/Form/FormContents";
import BlockUI from "components/molecules/BlockUi";

import { useAttendApplicationApprovalDetail } from "./hooks";
import FormTitle from "components/atoms/Form/FormTitle";
import { css } from "@emotion/core";
import AttendStampTable from "./ApplicationList/AttendStampTable";
import PrimaryButton, { DeleteButton } from "components/atoms/Button";
import { Col, Row } from "react-grid-system";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Modal from "components/molecules/Modal";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import { useHistory, useLocation, useParams } from "react-router-dom";

const styles = {
  groupButton: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
  }),
  button: css({
    margin: "0px 8px",
  }),
};

const BUTTON = {
  APPROVAL: 3,
  REJECT: 1,
};

const AttendApplicationApprovalDetail: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { applicationType } = useParams();

  const {
    isLoading,
    setIsLoading,
    detail,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    handleSubmit,
    closeConfirmApplyModal,
    rejectReason,
    setRejectReason,
    buttonType,
    setButtonType,
    setDetail,
    setChangeLogModalOpen,
    setChangeLogData,
    changeLogModalOpen,
    errorNotification,
  } = useAttendApplicationApprovalDetail();

  const [isHasApproval, setIsHasApproval] = useState(true);
  const [detailFirstItem, setDetailFirstItem] = useState<any>();
  useEffect(() => {
    const listApproval: any = [];
    const executiveId = sessionStorage.getItem("loginUser.executiveId");
    const staffCode = sessionStorage.getItem("loginUser.staffCode");
    const approverNotSkipApproveFlag = sessionStorage.getItem("loginUser.approverNotSkipApproveFlag");

    if (detail) {
      detail[0]?.approvalHistoryList.map((approvalItem: any) => {
        listApproval.push(approvalItem.executiveId);
      });
      setDetailFirstItem(detail[0]);
      if (listApproval && !listApproval.includes(executiveId)) {
        setIsHasApproval(false);
        if (detail[0]?.applicationStatus === 0) {
          history.push("/404");
        }
      }
      if (location.pathname.split("/")[1] === "attendApplicationApproval" && !listApproval.includes(executiveId)) {
        history.push("/404");
      }

      if (approverNotSkipApproveFlag === "true") {
        if (
          location.pathname.split("/")[1] === "attendApplicationApproval" &&
          listApproval.includes(executiveId) &&
          detail[0].applicationStaffCode === staffCode
        ) {
          history.push("/404");
        }
      }

      if (
        location.pathname.split("/")[1] === "attendApplicationList" &&
        detail[0].applicationStatus !== 0 &&
        detail[0].staffCode !== staffCode
      ) {
        history.push("/404");
      }
    }
  }, [detail]);

  const getPanelTitle = (applicationType: any) => {
    if (applicationType) {
      switch (applicationType) {
        case "attendStamp":
          return "打刻修正申請";
        case "attendHoliday":
          return "休暇申請";
        case "attendOvertime":
          return "残業申請";
        case "attendHolidaywork":
          return "休日出勤申請";
        case "attendTransfer":
          return "振替申請";
        case "attendAgreement36":
          return "限度時間超過申請";
        case "attendWorkScheduleChange":
          return "勤務予定変更申請";
        case "attendStockPaidHoliday":
          return "積立有給申請";
        case "attendAllowance":
          return "手当申請";
        default:
          break;
      }
    }
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle
          title={getPanelTitle(applicationType) + " 承認・否決"}
          bold={true}
          customStyle={css`
            margin: 16px 45px;
          `}
        />
        <AttendStampTable
          attendStamp={detail}
          closeConfirmApplyModal={closeConfirmApplyModal}
          rejectReason={rejectReason}
          setRejectReason={setRejectReason}
          confirmApplyModalOpen={confirmApplyModalOpen}
          handleSubmit={handleSubmit}
          buttonType={buttonType}
          setDetail={setDetail}
          setChangeLogModalOpen={setChangeLogModalOpen}
          setChangeLogData={setChangeLogData}
          changeLogModalOpen={changeLogModalOpen}
        />

        {detailFirstItem?.applicationStatus === 0 && isHasApproval && (
          <div css={styles.groupButton}>
            <FormSubmitArea>
              <PrimaryButton
                ghost={false}
                text="承認"
                customStyle={styles.button}
                onClick={() => {
                  setConfirmApplyModalOpen(true);
                  setButtonType(BUTTON.APPROVAL);
                }}
              />
              <DeleteButton
                ghost={false}
                text="否決"
                customStyle={styles.button}
                onClick={() => {
                  setConfirmApplyModalOpen(true);
                  setButtonType(BUTTON.REJECT);
                }}
              />
            </FormSubmitArea>
          </div>
        )}

        {detailFirstItem?.applicationStatus === 1 && !isHasApproval && (
          <div css={styles.groupButton}>
            <span css={css({ fontsize: "14px", color: "red" })}>この申請は既に承認済みです</span>
          </div>
        )}

        {detailFirstItem?.applicationStatus === 2 && !isHasApproval && (
          <div css={styles.groupButton}>
            <span css={css({ fontsize: "14px", color: "red" })}>この申請は既に否決済みです</span>
          </div>
        )}

        {!detailFirstItem && (
          <div css={styles.groupButton}>
            <span css={css({ fontsize: "14px", color: "red" })}>申請データが見つかりません。</span>
          </div>
        )}
      </FormContents>
    </BlockUI>
  );
};

export default AttendApplicationApprovalDetail;
