/** @jsx jsx */
import React from "react";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import Checkbox from "components/molecules/Checkbox";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { css, jsx } from "@emotion/core";
import { Row, Container, Col, useScreenClass } from "react-grid-system";
import { textFontSize } from "components/styles";
import { useShiftPatternDetailDomainForm } from "./hooks";
import Table from "components/molecules/Table";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import IconLabelButton from "components/molecules/IconLabelButton";
import useIsMobile from "hooks/useIsMobile";
import BodyText from "components/atoms/BodyText";
import MobileSelect from "components/atoms/MobileSelect";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const tableStyle = css({
  textAlign: "left",
});

const styles = {
  buttonDelete: css({ alignItems: "right", display: "flex" }),
  grayoutButton: css({
    opacity: "0.5",
    cursor: "not-allowed",
  }),
};

const ShiftPatternDetailsStamp: React.FC<{
  shiftPatternDetailsList: any;
  attendBusinessOptions: any;
  listTimes: any;
  isCreate?: boolean;
  isDisableAll?: boolean;
  setShiftPatternDetailsList: (dataList: any) => void;
  isWorkScheduleChangeApplication?: boolean;
  remote?: boolean;
}> = ({
  shiftPatternDetailsList,
  setShiftPatternDetailsList,
  listTimes,
  attendBusinessOptions,
  isCreate = true,
  isDisableAll = false,
  isWorkScheduleChangeApplication,
}) => {
  const isMobile = useIsMobile();
  const { detailList, setDetailList, convertDataOuput, isHaveBusiness } = useShiftPatternDetailDomainForm(
    listTimes,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    attendBusinessOptions,
    isCreate,
  );

  const handleAddShiftPatternDetails = () => {
    if (isDisableAll) {
      return;
    }
    let defaultBusinessId = "";
    if (attendBusinessOptions.length) {
      defaultBusinessId = attendBusinessOptions[0].value;
    }

    const lastIndex = detailList.length - 1;
    const tmpPatternDetails = [...detailList];
    tmpPatternDetails.push({
      shiftPatternDetailId: "",
      shiftPatternId: detailList[lastIndex].shiftPatternId,
      businessId: defaultBusinessId,
      startHourForm: detailList[lastIndex].endHourForm,
      startTimeForm: detailList[lastIndex].endTimeForm,
      isStartTimeNextDay: detailList[lastIndex].isEndTimeNextDay,
      endHourForm: "00",
      endTimeForm: "00",
      isEndTimeNextDay: false,
      createUser: "",
      updateUser: "",
      isRemote: false,
    });

    setDetailList(tmpPatternDetails);
    convertDataOuput(tmpPatternDetails);
  };

  /**
   * 終了時間を変更（次行があれば開始時間に反映）
   *
   */
  const handleChangeStartEndTime = (indexShiftPattern: number, nameField: string, value: any) => {
    if (isDisableAll) {
      return;
    }

    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        item[nameField] = value;
        return true;
      }
      return false;
    });
    const arrOnChange = ["endHourForm", "endTimeForm", "isEndTimeNextDay"];
    if (arrOnChange.indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
   * handleDeleteShift
   *
   */
  const handleDeleteShift = () => {
    if (isDisableAll) {
      return;
    }
    const tmpList = [...detailList];
    tmpList.splice(tmpList.length - 1, 1);
    setDetailList(tmpList);
    convertDataOuput(tmpList);
  };

  /**
   * handleChangeCheckboxNextDay
   *
   */
  const handleChangeCheckboxNextDay = (indexShiftPattern: number, nameField: string, isChange?: boolean) => {
    if (isDisableAll || isChange) return;
    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        if (item[nameField] === true) {
          item[nameField] = false;
        } else {
          item[nameField] = true;
        }
        return true;
      }
      return false;
    });
    if (["endHourForm", "endTimeForm", "isEndTimeNextDay"].indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
   * 時間を変更（リストボックスの値を変更）
   *
   */
  const onHandleHiddenButton = (indexShiftPattern: number, isDisableAll: boolean) => {
    if (
      detailList.length === 1 ||
      detailList.length !== indexShiftPattern + 1 ||
      (detailList.length === indexShiftPattern + 1 && isDisableAll === true)
    ) {
      return true;
    }
    return false;
  };

  const screenClass = useScreenClass();

  const customTimeFormOptions = listTimes.map((time: string) => ({
    value: time,
    label: time,
  }));

  return (
    <Container fluid={true}>
      {isMobile ? (
        <React.Fragment>
          {detailList.map((shiftPattern: any, keyShiftPattern: number) => (
            <div
              css={css({
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #ddd",
                paddingBottom: 16,
                "&:not(:first-child)": {
                  paddingTop: 16,
                },
              })}
            >
              <BodyText customStyle={css({ marginBottom: 4 })}>開始・終了時間</BodyText>
              <div css={css({ display: "flex", flexDirection: "column" })}>
                <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                  <MobileSelect
                    id={`startHour${keyShiftPattern}`}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val === "") return;
                      handleChangeStartEndTime(keyShiftPattern, "startHourForm", val);
                    }}
                    disabled={keyShiftPattern !== 0 || isDisableAll}
                    options={hourFormOptions}
                    value={
                      shiftPattern.startHourForm === "Invalid date" || shiftPattern.startHourForm === ""
                        ? "00"
                        : shiftPattern.startHourForm
                    }
                  />
                  <span>:</span>
                  <MobileSelect
                    id={`startTime${keyShiftPattern}`}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val === "") return;
                      handleChangeStartEndTime(keyShiftPattern, "startTimeForm", val);
                    }}
                    disabled={keyShiftPattern !== 0 || isDisableAll}
                    options={customTimeFormOptions || timeFormOptions}
                    value={
                      shiftPattern.startTimeForm === "Invalid date" || shiftPattern.startTimeForm === ""
                        ? "00"
                        : shiftPattern.startTimeForm
                    }
                  />
                  <Checkbox
                    id={`isStartTimeNextDay${keyShiftPattern}`}
                    name={`isStartTimeNextDay${keyShiftPattern}`}
                    label="翌日"
                    value="true"
                    checked={Boolean(shiftPattern.isStartTimeNextDay)}
                    onChange={() =>
                      handleChangeCheckboxNextDay(keyShiftPattern, "isStartTimeNextDay", keyShiftPattern !== 0)
                    }
                    readOnly={keyShiftPattern !== 0 || isDisableAll}
                    isVertical={isMobile}
                  />
                  <BodyText customStyle={css({ margin: "0 10px" })}>～</BodyText>
                </div>
                <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                  <MobileSelect
                    id={`endHour${keyShiftPattern}`}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val === "") return;
                      handleChangeStartEndTime(keyShiftPattern, "endHourForm", val);
                    }}
                    disabled={isDisableAll}
                    options={hourFormOptions}
                    value={
                      shiftPattern.endHourForm === "Invalid date" || shiftPattern.endHourForm === ""
                        ? "00"
                        : shiftPattern.endHourForm
                    }
                  />
                  <span>:</span>
                  <MobileSelect
                    id={`endTime${keyShiftPattern}`}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val === "") return;
                      handleChangeStartEndTime(keyShiftPattern, "endTimeForm", val);
                    }}
                    disabled={isDisableAll}
                    options={customTimeFormOptions || timeFormOptions}
                    value={
                      shiftPattern.endTimeForm === "Invalid date" || shiftPattern.endTimeForm === ""
                        ? "00"
                        : shiftPattern.endTimeForm
                    }
                  />
                  <Checkbox
                    id={`isEndTimeNextDay${keyShiftPattern}`}
                    name={`isEndTimeNextDay${keyShiftPattern}`}
                    label="翌日"
                    value="true"
                    checked={Boolean(shiftPattern.isEndTimeNextDay)}
                    onChange={() => handleChangeCheckboxNextDay(keyShiftPattern, "isEndTimeNextDay")}
                    readOnly={isDisableAll}
                    isVertical={isMobile}
                  />
                  <BodyText customStyle={css({ minWidth: "34px" })}></BodyText>
                </div>
              </div>

              <div
                css={css({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 8,
                  alignItems: "center",
                })}
              >
                <Checkbox
                  id={`businessId${keyShiftPattern}`}
                  name={`businessId${keyShiftPattern}`}
                  label="休憩"
                  value={sessionStorage.getItem("loginUser.breakId") || ""}
                  checked={
                    shiftPattern.businessId === sessionStorage.getItem("loginUser.breakId")
                      ? !isHaveBusiness
                      : isHaveBusiness
                  }
                  onChange={(event) => {
                    let isChecked = event.target.checked;
                    handleChangeStartEndTime(
                      keyShiftPattern,
                      "businessId",
                      isChecked ? sessionStorage.getItem("loginUser.breakId") : "",
                    );
                  }}
                  readOnly={isDisableAll}
                />

                {!onHandleHiddenButton(keyShiftPattern, isDisableAll) && (
                  <div
                    css={css(
                      styles.buttonDelete,
                      onHandleHiddenButton(keyShiftPattern, isDisableAll) ? styles.grayoutButton : {},
                    )}
                  >
                    <ClickableIconButton
                      onClick={() =>
                        onHandleHiddenButton(keyShiftPattern, isDisableAll) ? () => {} : handleDeleteShift()
                      }
                    >
                      <Icon type="delete" color={textFontSize.re} disabled={isDisableAll} />
                    </ClickableIconButton>
                  </div>
                )}
              </div>
            </div>
          ))}
        </React.Fragment>
      ) : (
        <Table>
          <thead>
            <tr>
              <Table.HeaderCell customStyle={tableStyle}>開始時間</Table.HeaderCell>
              <Table.HeaderCell customStyle={tableStyle}>終了時間</Table.HeaderCell>
              <Table.HeaderCell customStyle={tableStyle} style={{ width: "70px" }}>
                休憩
              </Table.HeaderCell>
              <Table.HeaderCell customStyle={css({ width: "30px" })}></Table.HeaderCell>
            </tr>
          </thead>
          <tbody>
            {detailList.map((shiftPattern: any, keyShiftPattern: number) => (
              <tr key={keyShiftPattern}>
                <Table.Cell>
                  <Row
                    style={{
                      alignItems: "center",
                      justifyContent: "left",
                      display: "flex",
                    }}
                  >
                    <Col
                      style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: isMobile ? 2 : 8 }}
                      xs={12}
                    >
                      <VerticalLabelSelectForm
                        label=""
                        name={`startHour${keyShiftPattern}`}
                        width="100%"
                        value={String(
                          shiftPattern.startHourForm === "Invalid date" || shiftPattern.startHourForm === ""
                            ? "00"
                            : shiftPattern.startHourForm,
                        )}
                        setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "startHourForm", val)}
                        options={hourFormOptions}
                        disable={keyShiftPattern !== 0 || isDisableAll}
                        menuPlacement="bottom"
                      />
                      <span>:</span>
                      <VerticalLabelSelectForm
                        name={`startTime${keyShiftPattern}`}
                        width="100%"
                        value={String(
                          shiftPattern.startTimeForm === "Invalid date" || shiftPattern.startTimeForm === ""
                            ? "00"
                            : shiftPattern.startTimeForm,
                        )}
                        setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "startTimeForm", val)}
                        options={customTimeFormOptions || timeFormOptions}
                        disable={keyShiftPattern !== 0 || isDisableAll}
                        placeholder="分"
                        menuPlacement="bottom"
                      />
                      <Checkbox
                        id={`isStartTimeNextDay${keyShiftPattern}`}
                        name={`isStartTimeNextDay${keyShiftPattern}`}
                        label="翌日"
                        value="true"
                        checked={Boolean(shiftPattern.isStartTimeNextDay)}
                        onChange={() =>
                          handleChangeCheckboxNextDay(keyShiftPattern, "isStartTimeNextDay", keyShiftPattern !== 0)
                        }
                        readOnly={keyShiftPattern !== 0 || isDisableAll}
                        isVertical={isMobile}
                      />
                    </Col>
                  </Row>
                </Table.Cell>
                <Table.Cell>
                  <Row
                    style={{
                      alignItems: "center",
                      justifyContent: "left",
                      display: "flex",
                    }}
                  >
                    <Col style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }} xs={12}>
                      <VerticalLabelSelectForm
                        width="100%"
                        name={`endHour${keyShiftPattern}`}
                        value={String(
                          shiftPattern.endHourForm === "Invalid date" || shiftPattern.endHourForm === ""
                            ? "00"
                            : shiftPattern.endHourForm,
                        )}
                        setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "endHourForm", val)}
                        options={hourFormOptions}
                        disable={isDisableAll}
                        menuPlacement="bottom"
                      />
                      <span>:</span>
                      <VerticalLabelSelectForm
                        width="100%"
                        name={`endTime${keyShiftPattern}`}
                        value={String(
                          shiftPattern.endTimeForm === "Invalid date" || shiftPattern.endTimeForm === ""
                            ? "00"
                            : shiftPattern.endTimeForm,
                        )}
                        setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "endTimeForm", val)}
                        options={customTimeFormOptions || timeFormOptions}
                        disable={isDisableAll}
                        placeholder="分"
                        menuPlacement="bottom"
                      />
                      <Checkbox
                        id={`isEndTimeNextDay${keyShiftPattern}`}
                        name={`isEndTimeNextDay${keyShiftPattern}`}
                        label="翌日"
                        value="true"
                        checked={Boolean(shiftPattern.isEndTimeNextDay)}
                        onChange={() => handleChangeCheckboxNextDay(keyShiftPattern, "isEndTimeNextDay")}
                        readOnly={isDisableAll}
                        isVertical={isMobile}
                      />
                    </Col>
                  </Row>
                </Table.Cell>
                <Table.Cell>
                  <Col
                    md={12}
                    xl={12}
                    lg={12}
                    style={{
                      paddingBottom: ["lg", "xl"].includes(screenClass)
                        ? ""
                        : ["md"].includes(screenClass)
                        ? ""
                        : "8%",
                    }}
                  >
                    <Checkbox
                      id={`businessId${keyShiftPattern}`}
                      name={`businessId${keyShiftPattern}`}
                      label="休憩"
                      value={sessionStorage.getItem("loginUser.breakId") || ""}
                      checked={
                        shiftPattern.businessId === sessionStorage.getItem("loginUser.breakId")
                          ? !isHaveBusiness
                          : isHaveBusiness
                      }
                      onChange={(event) => {
                        let isChecked = event.target.checked;
                        handleChangeStartEndTime(
                          keyShiftPattern,
                          "businessId",
                          isChecked ? sessionStorage.getItem("loginUser.breakId") : "",
                        );
                      }}
                      readOnly={isDisableAll}
                      isVertical={isMobile}
                    />
                  </Col>
                </Table.Cell>

                <Table.Cell>
                  <Col
                    xs={1}
                    md={1}
                    xl={1}
                    lg={1}
                    style={{
                      paddingBottom: ["lg", "xl"].includes(screenClass)
                        ? ""
                        : ["md"].includes(screenClass)
                        ? ""
                        : "8%",
                    }}
                  >
                    <div
                      css={css(
                        styles.buttonDelete,
                        onHandleHiddenButton(keyShiftPattern, isDisableAll) ? styles.grayoutButton : {},
                      )}
                    >
                      <ClickableIconButton
                        onClick={() =>
                          onHandleHiddenButton(keyShiftPattern, isDisableAll) ? () => {} : handleDeleteShift()
                        }
                      >
                        <Icon type="delete" color={textFontSize.re} disabled={isDisableAll} />
                      </ClickableIconButton>
                    </div>
                  </Col>
                </Table.Cell>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Row style={{ marginTop: isMobile ? "16px" : "8px", marginBottom: "8px" }}>
        <Col xs={12} md={6}>
          <FormSubmitArea>
            <IconLabelButton
              iconType="clone"
              onClick={handleAddShiftPatternDetails}
              text={isWorkScheduleChangeApplication ? "勤務時間を追加する" : "打刻修正を追加する"}
              width="auto"
              disabled={isDisableAll}
            />
          </FormSubmitArea>
        </Col>
      </Row>
    </Container>
  );
};

export default ShiftPatternDetailsStamp;
