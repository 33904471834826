import ApiClient from './ApiClient';

export const getHolidayWork = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/attendHolidayworkApplication/holidaywork/${companyCode}`, {}, params);
  return response.data;
};

export const postHolidayWork = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v2/attendHolidayworkApplication/holidaywork/${companyCode}`, {}, params);
  return response.data;
};

export default {
  getHolidayWork,
  postHolidayWork,
};
