import moment from "moment";
import { IOvertime } from "../interface";

const dateFormat = "YYYY/MM/DD HH:mm";

/**
 * API getApprovedOvertimeList
 */
export default class ProcessingOvertimeDomain {
  constructor(private rawData: IOvertime) {
    // do nothing
  }

  getRawData(): IOvertime {
    return this.rawData;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }
  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get applicationDate(): string {
    return moment(this.rawData.applicationDate).format(dateFormat);
  }
  set applicationDate(applicationDate: string) {
    this.rawData.applicationDate = moment(applicationDate).format(dateFormat);
  }

  get applicationStaffCode(): string {
    return this.rawData.applicationStaffCode;
  }
  set applicationStaffCode(applicationStaffCode: string) {
    this.rawData.applicationStaffCode = applicationStaffCode;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }
  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }
  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get applicationStatus(): number {
    return this.rawData.applicationStatus;
  }
  set applicationStatus(applicationStatus: number) {
    this.rawData.applicationStatus = applicationStatus;
  }

  get modifiyStatus(): number {
    return this.rawData.modifiyStatus;
  }
  set modifiyStatus(modifiyStatus: number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get modifiyStatusName(): string {
    return this.rawData.modifiyStatusName;
  }
  set modifiyStatusName(modifiyStatusName: string) {
    this.rawData.modifiyStatusName = modifiyStatusName;
  }

  get applicationReason(): string {
    return this.rawData.applicationReason;
  }
  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }

  get rejectReason(): string | "" {
    if (this.rawData.rejectReason) {
      return this.rawData.rejectReason;
    }

    return "";
  }
  set rejectReason(rejectReason: string | "") {
    this.rawData.rejectReason = rejectReason;
  }

  get version(): number {
    return this.rawData.version;
  }
  set version(version: number) {
    this.rawData.version = version;
  }

  get approvalDate(): string {
    return moment(this.rawData.approvalDate).format(dateFormat);
  }
  set approvalDate(approvalDate: string) {
    this.rawData.approvalDate = moment(approvalDate).format(dateFormat);
  }

  get approvalStaffCode(): string {
    return this.rawData.approvalStaffCode;
  }
  set approvalStaffCode(approvalStaffCode: string) {
    this.rawData.approvalStaffCode = approvalStaffCode;
  }

  get applicationOvertime(): string {
    return `${this.rawData.applicationOvertime}分`;
  }
  set applicationOvertime(applicationOvertime: string) {
    this.rawData.applicationOvertime = applicationOvertime;
  }

  get unapprovedOvertime(): string {
    return `${this.rawData.unapprovedOvertime}分`;
  }
  set unapprovedOvertime(unapprovedOvertime: string) {
    this.rawData.unapprovedOvertime = unapprovedOvertime;
  }

  get targetDate(): string {
    return moment(this.rawData.targetDate).format("YYYY/MM/DD");
  }
  set targetDate(targetDate: string) {
    this.rawData.targetDate = moment(targetDate).format("YYYY/MM/DD");
  }

  get orgName(): string {
    return this.rawData.orgName;
  }
  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }
  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get applicationStaffName(): string {
    return this.rawData.applicationStaffName;
  }
  set applicationStaffName(applicationStaffName: string) {
    this.rawData.applicationStaffName = applicationStaffName;
  }

  get approvalStaffName(): string {
    return this.rawData.approvalStaffName;
  }
  set approvalStaffName(approvalStaffName: string) {
    this.rawData.approvalStaffName = approvalStaffName;
  }

  get authorizer(): string {
    return `${this.rawData.approvalStaffCode} ${this.rawData.approvalStaffName}`;
  }

  get applicant(): string {
    return `${this.rawData.applicationStaffCode} ${this.rawData.applicationStaffName}`;
  }

  get targetPerson(): string {
    return `${this.rawData.staffCode} ${this.rawData.staffName}`;
  }
}
