/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { layer } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import productColor, { textFontSize, grayScale } from 'components/styles';
import TextInput from 'components/atoms/Form/TextInput';

const styles = {
  box: (height: number,showSearchBox?: boolean) => css({
    position: 'absolute',
    top: `${height}px`,
    left: '100%',
    whiteSpace: 'nowrap',
    zIndex: 100,
    cursor: 'pointer',
    boxShadow: layer.layer06,
    maxHeight: '400px',
    minWidth: showSearchBox ? '230px' : '160px'
  }),
  subMenu: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 14px',
    backgroundColor: '#ffff',
    fontSize: textFontSize.re,
    ':hover': {
      backgroundColor: productColor.primaryM95,
    },
  }),
  boxSearch: (height: number) => css({
    top: `${height}px`,
    left: '100%',
    whiteSpace: 'nowrap',
    zIndex: 100,
    cursor: 'pointer',
    maxHeight: '300px',
    overflow: 'auto'
  }),
  wrapSearch: css({
    padding: '6px',
    background: '#fafbfc',
    position: 'relative'
  }),
  inputSearch: css({
    padding: '8px',
    borderColor: 'rgb(204, 204, 204)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px'
  }),
  iconSearch: css({
    position: 'absolute',
    right: '15px',
    top: '10px'
  }),
  boxShadow: css({
    boxShadow: layer.layer06,
  })
};

//Forked DropdownBox - add reponsive to position
const SubContextMenuShift: React.FC<{
  text: string;
  textSearch: string;
  setTextSearch: any;
  showSearchBox?: boolean;
}> = ({ children, text, textSearch, setTextSearch, showSearchBox }) => {
  const [subMenu, setSubMenu] = useState<HTMLElement | null>(null);
  const [subMenuHeight, setSubMenuHeight] = useState<number>(0);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  
  const onMouseEnter = () => {
    setShowMenu(true)
  }
  const onMouseLeave = () => {
    setShowMenu(false);
    setTextSearch('');
  }

  useEffect(() => {
    if (!subMenu) {
      return;
    }

    let parentElement = subMenu.parentElement;

    if (!parentElement) {
      return;
    }

    let parentRect = parentElement.getBoundingClientRect();
    let extraSpaceForDesktop = window.screen.width > 1200 ? 50 : 10;
    let remainHeight = window.screen.availHeight - parentRect.top - extraSpaceForDesktop;

    if (remainHeight < subMenu.clientHeight) {
      setSubMenuHeight(-subMenu.clientHeight + parentRect.height);
    } else {
      setSubMenuHeight(0);
    }
    
  }, [subMenu, textSearch]) 

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div css={styles.subMenu} >
        <BodyText color={grayScale.gray100}>{text}</BodyText>
        <Icon type="chevronRight" color={grayScale.gray60}></Icon>
      </div>
      {showMenu && (
        <div ref={(ref) => { setSubMenu(ref) }} css={styles.box(subMenuHeight, showSearchBox)}>
          {
            showSearchBox && subMenuHeight === 0 && (
              <div css={styles.wrapSearch}>
                <TextInput
                  value={textSearch}
                  name={'search'}
                  customStyle={styles.inputSearch}
                  onChange={(search: any) => {
                    setTextSearch(search.target.value);
                  }}
                  autoFocus={true}
                />
                <Icon customStyle={styles.iconSearch} type="search" color="" />
              </div>
            )
          }
          <div css={styles.boxSearch(subMenuHeight)}>
            {children}
          </div>

          {
            showSearchBox && subMenuHeight < 0 && (
              <div css={styles.wrapSearch}>
                <TextInput
                  value={textSearch}
                  name={'search'}
                  customStyle={styles.inputSearch}
                  onChange={(search: any) => {
                    setTextSearch(search.target.value);
                  }}
                  autoFocus={true}
                />
                <Icon customStyle={styles.iconSearch} type="search" color="" />
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

export default SubContextMenuShift;
