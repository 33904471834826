import {useState, useCallback, useEffect } from 'react';

import {getListAgreement} from 'api/master36Agreement';
import AttendSetting36AgreementDomain from 'domain/master/attend/attendSetting36Agreement';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useAtreement36Master = () => {
  const [agreementList, setAgreementList] = useState<Array<AttendSetting36AgreementDomain>>([]);
  const [targetYearFrom, setTargetYearFrom] = useState(Number(sessionStorage.getItem('masterAgreement36.targetYearFrom')) || new Date().getFullYear());
  const [targetYearTo, setTargetYearTo] = useState(Number(sessionStorage.getItem('masterAgreement36.targetYearTo')) || new Date().getFullYear());
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterSettingAgreement36);

 // データの取得
 const fetchData = useCallback(async () => {
  setBlocking(true);
  const response = await getListAgreement(
    targetYearFrom, targetYearTo,
  );
  setAgreementList(response.map((result) => new AttendSetting36AgreementDomain(result)));
  setBlocking(false);
}, [targetYearFrom, targetYearTo]);

useEffect(() => {
  fetchData();
}, [fetchData, targetYearFrom, targetYearTo]);


  return {
    agreementList,
    fetchData,
    targetYearFrom,
    targetYearTo,
    setAgreementList,
    detailRole,
    setTargetYearFrom,
    setTargetYearTo,
    blocking,
    setBlocking,
};

};

export default useAtreement36Master;
