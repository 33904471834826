/**
 * APIのデータ型
 */
interface LoginUser {
  staffCode: string;
  staffName: string;
  staffNameKana: string;
  mail: string;
  phoneNumber: string;
  orgCode: string;
  orgName: string;
  executiveCode: string;
  executiveName: string;
  employmentCode: string;
  employmentName: string;
  hqRole: string;
  shopRole: string;
  personalRole: string;
  companyCode: string;
  retirementDate: Date;
  allOrganizationAccess: boolean;
  paidControl: boolean;
  administrator: boolean;
  allStaffAccess: boolean;
  retired: boolean;
  accountingRole: boolean;
  attendanceRole: boolean;
  workSystem: number;
  useSales: boolean;
  useAttend: boolean;
  useSalaryPayslip: boolean;
  holdings: boolean;
  useGpsStamp: boolean;
  useFaceStamp: boolean;
  useBarcodeStamp: boolean;
  useInsideLoginBarcodeStamp: boolean;
  useInsideLoginFaceRecorderStamp: boolean;
  managed: any;
  site: number;
  isHavingApprovalPrivilege:boolean;
  isLogged:boolean;
  closingDate: number;
  barcodeId: string;
  isAlertClosingDate: boolean;
  executiveId: string;
  alertErrorStartDate: Date;
  alertErrorEndDate: Date;
  functionCodeList: Array<string>;
  temperature: number
}

export default class LoginUserDomain {
  constructor(private rawData: LoginUser) {
    // do nothing
  }

  static generateInitial(): LoginUserDomain {
    return new LoginUserDomain({
      staffCode: '',
      staffName: '',
      staffNameKana: '',
      mail: '',
      phoneNumber: '',
      orgCode: '',
      orgName: '',
      executiveCode: '',
      executiveName: '',
      employmentCode: '',
      employmentName: '',
      hqRole: '',
      shopRole: '',
      personalRole: '',
      companyCode: '',
      retirementDate: new Date(),
      allOrganizationAccess: false,
      paidControl: false,
      administrator: false,
      allStaffAccess: false,
      retired: false,
      accountingRole: false,
      attendanceRole: false,
      workSystem: 1,
      useSales: false,
      useAttend: false,
      useSalaryPayslip: false,
      holdings: false,
      useGpsStamp: false,
      useFaceStamp: false,
      useBarcodeStamp: false,
      useInsideLoginBarcodeStamp: false,
      useInsideLoginFaceRecorderStamp: false,
      managed: [],
      site: 1,
      isHavingApprovalPrivilege:false,
      temperature: 0,
      isLogged: false,
      closingDate: 1,
      barcodeId: '',
      isAlertClosingDate: false,
      executiveId: '',
      alertErrorStartDate: new Date(),
      alertErrorEndDate: new Date(),
      functionCodeList: [],
    });
  }

  getRawData(): LoginUser {
    return this.rawData;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  get staffNameKana(): string {
    return this.rawData.staffNameKana;
  }

  get mail(): string {
    return this.rawData.mail;
  }

  get phoneNumber(): string {
    return this.rawData.phoneNumber;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  get executiveCode(): string {
    return this.rawData.executiveCode;
  }

  get executiveName(): string {
    return this.rawData.executiveName;
  }

  get employmentCode(): string {
    return this.rawData.employmentCode;
  }

  get employmentName(): string {
    return this.rawData.employmentName;
  }

  get hqRole(): string {
    return this.rawData.hqRole;
  }

  get shopRole(): string {
    return this.rawData.shopRole;
  }

  get personalRole(): string {
    return this.rawData.personalRole;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  get retirementDate(): Date {
    return this.rawData.retirementDate;
  }

  get allOrganizationAccess(): boolean {
    return this.rawData.allOrganizationAccess;
  }

  get paidControl(): boolean {
    return this.rawData.paidControl;
  }

  get administrator(): boolean {
    return this.rawData.administrator;
  }

  get allStaffAccess(): boolean {
    return this.rawData.allStaffAccess;
  }

  get retired(): boolean {
    return this.rawData.retired;
  }

  get accountingRole(): boolean {
    return this.rawData.accountingRole;
  }

  get attendanceRole(): boolean {
    return this.rawData.attendanceRole;
  }

  get workSystem(): number {
    return this.rawData.workSystem;
  }

  get useSales(): boolean {
    return this.rawData.useSales;
  }

  get useAttend(): boolean {
    return this.rawData.useAttend;
  }

  get useSalaryPayslip(): boolean {
    return this.rawData.useSalaryPayslip;
  }

  get holdings(): boolean {
    return this.rawData.holdings;
  }

  get useGpsStamp(): boolean {
    return this.rawData.useGpsStamp;
  }

  get useInsideLoginBarcodeStamp(): boolean {
    return this.rawData.useInsideLoginBarcodeStamp;
  }

  get useInsideLoginFaceRecorderStamp(): boolean {
    return this.rawData.useInsideLoginFaceRecorderStamp;
  }

  get useFaceStamp(): boolean {
    return this.rawData.useFaceStamp;
  }

  get useBarcodeStamp(): boolean {
    return this.rawData.useBarcodeStamp;
  }

  get managed(): any {
    return this.rawData.managed;
  }

  get site(): number {
    return this.rawData.site;
  }

  get isHavingApprovalPrivilege(): boolean {
    return this.rawData.isHavingApprovalPrivilege;
  }

  get isLogged(): boolean {
    return this.rawData.isLogged;
  }

  get closingDate(): number {
    return this.rawData.closingDate;
  }

  get barcodeId(): string {
    return this.rawData.barcodeId;
  }

  get isAlertClosingDate(): boolean {
    return this.rawData.isAlertClosingDate;
  }

  get alertErrorStartDate(): Date {
    return this.rawData.alertErrorStartDate;
  }

  get alertErrorEndDate(): Date {
    return this.rawData.alertErrorEndDate;
  }

  get executiveId(): string {
    return this.rawData.executiveId;
  }

  get functionCodeList(): Array<string> {
    return this.rawData.functionCodeList;
  }

  get temperature(): number {
    return this.rawData.temperature;
  }
}
