import React, { useEffect, useState, useCallback } from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import { useParams, useHistory } from 'react-router-dom';
import { getOutputBaseItems, reflectData } from 'api/timeOutputLayout';
import useOutputBaseItems from 'hooks/useOutputBaseItems';
import Checkbox from 'components/molecules/Checkbox';
import { css } from '@emotion/core';
import productColor, {
    utilityColor,
    grayScale,
    textFontSize,
} from 'components/styles';
import LableTitle from 'components/atoms/LableTitle';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import { useTimeOutputLayoutDomainAddForm } from './hooks';
import ConfirmModal from 'components/organismos/ConfirmModal';
import useToastNotification from 'hooks/useToastNotification';

const baseItemDay = 2;
const checkboxOnChangePlus = 1;
const checkboxOnChangeMinus = 2;
// create style form
const styles = {
    wrapper: css({
        display: 'flex',
        width: '150px',
        float: 'left',
    }),
    input: css({
        flex: 1,
        width: '100px',
        padding: '7px',
        fontSize: textFontSize.re,
        fontFamily: 'inherit',
        color: productColor.primary,
        borderTop: `1px solid ${grayScale.gray10}`,
        borderRight: 'none',
        borderBottom: `1px solid ${grayScale.gray10}`,
        borderLeft: `1px solid ${grayScale.gray10}`,
        borderRadius: '4px 0 0 4px',
    }),
    addon: css({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100px',
        padding: '7px',
        color: productColor.primary,
        backgroundColor: productColor.primaryM95,
        borderTop: `1px solid ${grayScale.gray10}`,
        borderRight: `1px solid ${grayScale.gray10}`,
        borderBottom: `1px solid ${grayScale.gray10}`,
        borderLeft: 'none',
        borderRadius: '0 4px 4px 0',
    }),
    cellTable: css({
        width: '100%',
        border: 'none',
        borderSpacing: '0px 1.5px',
        overflow: 'scroll',
    }),
    noteText: css({
        fontSize: textFontSize.sm,
        color: utilityColor.error,
        marginLeft: '4px',
        float: 'right',
        marginTop: '-37px',
    }),
    labelTitle: {
        marginTop: '-25px',
        display: 'block',
        paddingLeft: '30px',
        paddingBottom: '30px'
    }
};

const DayOutputItemForm: React.FC = () => {
    const { layoutId } = useParams();
    const {
        setConfirmModalOpen,
        getOutputLayouts,
    } = useTimeOutputLayoutDomainAddForm(layoutId || '');
    const [baseItemType, setBaseItemType] = useState(baseItemDay);
    const outputBaseItemsOptions1 = useOutputBaseItems(baseItemType);
    const [outputBaseItems, setOutputBaseItems] = useState(outputBaseItemsOptions1);
    const { successNotification, errorNotification } = useToastNotification();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getOutputBaseItems(baseItemDay).then((response) => {
            setOutputBaseItems(response);
        });
        setBaseItemType(baseItemDay);
    }, []);


    /**
    *  handle on change
    */

    const [isChecked, setIsChecked] = useState(false);
    const handleChangePlusAndMinus = async (checkboxOnChange: any, baseItemId: any, e: any) => {
        const items = [...outputBaseItems];
        items.filter((item: any) => {
            if (item.id.baseItemId === baseItemId) {
                if (checkboxOnChange === checkboxOnChangePlus) {
                    if (item.plus === true) {
                        item.plus = false;
                    } else {
                        item.plus = true;
                        item.minus = false;
                    }

                } else if (item.minus === true) {
                    item.minus = false;
                } else {
                    item.minus = true;
                    item.plus = false;
                }
                return true;
            }
            return false;
        });

        setOutputBaseItems(items); 
        setIsChecked(false);
        items.map((item: any) => { 
            if(item.minus === true || item.plus === true){
                setIsChecked(true);
            }
            return true;
        })
    };

    const history = useHistory();
    const handleBack = async () => {
        history.goBack();
    };

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    /**
    *  handle submit
    */
    const handleSubmitAddOutputBaseItems = async (bItemType: any) => {
        let convertData = {};
        const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
        const loginUser = sessionStorage.getItem('loginUser.staffName') || '';


        outputBaseItems.forEach((item: any, index: number) => {
            delete (item as any).updateDate;
            delete (item as any).createDate;
            delete (item as any).createUser;
            delete (item as any).updateUser;
            delete (item as any).calculationType;
            delete (item as any).companyCode;
            delete (item as any).id.outputItemId;


            // if ((item as any).plus === true) {
            //     //
            //     (item as any).plus = 'on';
            //     checkFlg = true;
            // } 
            // else {
            //     delete (item as any).plus;
            // }

            // if ((item as any).minus === true) {
            //     //
            //     (item as any).minus = 'on';
            //     checkFlg = true;
            // } 
            // else {
            //     delete (item as any).minus;
            // }

            convertData = {
                ...convertData,
                [`baseItemList[${index}]`]: {
                    ...item,
                },
            };
        });


        if (!isChecked) {
            setModalOpen(false);
            errorNotification('出力項目を選択してください。');
            return;
        }


        convertData = {
            ...convertData,
            createUser: loginUser,
            updateUser: loginUser,
        };

        try {
            await reflectData(companyCode, convertData);
            successNotification('登録しました。');
            // outputBaseItems.forEach((item: any, index: number) => {
            //     delete (item as any).minus;
            //     delete (item as any).plus;
            // });
            getOutputLayouts(layoutId);
            setModalOpen(false);
        } catch (error) {
            setConfirmModalOpen(false);
            if (error.response && error.response.data && error.response.data.errors) {
                const listErr = error.response.data.errors;
                let stringErr = '';
                listErr.forEach((element: any) => {
                    stringErr += `${element.defaultMessage}<br />`;
                });
                errorNotification(stringErr);
            } else {
                errorNotification('サーバー側でエラーが発生しました。');
                throw error;
            }
        }
    };

    return (
        <div>
            <FormContents css={{ marginTop: '30px' }}>
                <strong style={styles.labelTitle}><LableTitle title="勤務日数項目追加" /></strong>
                <table style={{ width: '100%' }} className="table-bordered">
                    <thead>
                        <tr style={{
                            borderTop: '1px solid #CEDAEA',
                            borderBottom: '1px solid #CEDAEA',
                            backgroundColor: '#F2F5F9',
                            height: '40px',
                        }}>
                            <th style={{
                                textAlign: 'center',
                                width: '50px',
                            }}> ＋ </th>
                            <th style={{
                                textAlign: 'center',
                                width: '50px',
                            }}> － </th>
                            <th style={{
                                textAlign: 'left',
                                width: 'calc(100% - 100px)',
                            }} > 出力項目名 </th>
                        </tr>
                    </thead>
                </table>
                <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <table style={{ width: '100%' }} className="table-bordered">
                        <tbody>
                            {outputBaseItems.map((item: any, index: any) => (
                                <tr key={index} style={{ backgroundColor: '#ffffff' }}>
                                    <td style={{
                                        fontWeight: 'inherit',
                                        textAlign: 'center',
                                        width: '50px',
                                    }}>
                                        <Checkbox
                                            id={`baseItemPlus${item.id.baseItemId}`}
                                            name={`baseItem${item.id.baseItemId}`}
                                            value={item.id.baseItemId}
                                            checked={Boolean(item.plus)}
                                            onChange={(e) => handleChangePlusAndMinus(checkboxOnChangePlus, item.id.baseItemId, e)}
                                        />
                                    </td>
                                    <td style={{
                                        fontWeight: 'inherit',
                                        textAlign: 'center',
                                        width: '50px',
                                    }}>
                                        <Checkbox
                                            id={`baseItemMinus${item.id.baseItemId}`}
                                            name={`baseItemMinus${item.id.baseItemId}`}
                                            value={item.id.baseItemId}
                                            checked={Boolean(item.minus)}
                                            onChange={(e) => handleChangePlusAndMinus(checkboxOnChangeMinus, item.id.baseItemId, e)}
                                        />
                                    </td>
                                    <td style={{
                                        fontWeight: 'inherit',
                                        textAlign: 'left',
                                        width: 'calc(100% - 100px)',
                                    }}>
                                        {item.baseItemName}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </FormContents>
            <div style={{ textAlign: 'center', margin: '15px 0' }}>
                <div>
                    <Button
                        text="登録する"
                        onClick={() => setModalOpen(true)}
                    />
                </div>
                <div>
                    <PrimaryButton
                        text="キャンセル"
                        onClick={() => handleBack()}
                        ghost={true}
                    />
                </div>
            </div>
            <ConfirmModal
                open={modalOpen}
                closeHandler={closeModal}
                onSubmit={() => handleSubmitAddOutputBaseItems(baseItemDay)}
                title="勤務日数項目の登録"
                content={`勤務時間日数を登録します。よろしいですか？`}
                submitText={`登録する`}
            />
        </div>
    );
};

export default DayOutputItemForm;
