import { useEffect, useState } from 'react';

const useLoginUser = () => {
  const [staffName, setStaffName] = useState('');
  const [isBarcodeStamp, setBarcodeStamp] = useState('');
  const [isFaceStamp, setFaceStamp] = useState('');

  useEffect(() => {
    setStaffName(sessionStorage.getItem('loginUser.staffName') || '');
    // setLoginUser(sessionStorage.getItem('loginUser') || '');
    const sSloginUser = sessionStorage.getItem('loginUser');
    if(sSloginUser){
      const { useBarcodeStamp , useFaceStamp } = JSON.parse(sSloginUser || '');
      setBarcodeStamp(useBarcodeStamp);
      setFaceStamp(useFaceStamp);
    }
  }, []);

  return {
    staffName,
    isBarcodeStamp,
    isFaceStamp
  };
};

export default useLoginUser;
