/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { RecorderButton, PrimaryButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import Webcam from "react-webcam";
const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  formRecorder: css({
    textAlign: "center",
  }),
  dateForm: css({
    width: `${isIpad ? "" : "420px"}`,
    margin: "auto",
    flexWrap: "wrap",
  }),
  formContainer: css({
    marginTop: "20px",
    borderRadius: "3px",
    paddingBottom: isIpad ? "20px" : "50px",
    display: isIpad ? "flex" : "",
    flexWrap: "wrap",
  }),
  video: {
    border: "0",
    width: "100%",
    height: "100%",
  },
  infoFace: css({
    color: grayScale.gray100,
    fontWeight: 600,
    fontSize: "18px",
    width: "420px",
    marginBottom: "17px",
  }),
  canvas: css({
    width: "auto",
    height: "100%",
    border: "1px solid #000",
  }),
  imageButton: css({
    width: "100%",
    textAlign: "center",
    height: "80px",
  }),
  ipadTimeContainer: css({
    display: "flex",
    flexWrap: "wrap",
    alignSelf: "center",
    width: "300px",
    marginLeft: "20px",
  }),
  ipadButton: css({}),
  titleLabel: css({
    color: "#333333",
    fontSize: `${isIpad ? "15px" : "19px"}`,
    justifyContent: "center",
    fontWeight: "bold",
    width: "60%",
    borderBottom: "1px solid #e6e6e6",
    margin: "auto",
    paddingBottom: "15px",
  }),
};

const timeCss = css`
  align-items: baseline;
  justify-content: flex-start;
  margin: auto;
  width: 430px;
  color: red;
  line-height: ${isIpad ? "80px" : "100px"};
  span:first-of-type {
    font-size: ${isIpad ? "64px" : "100px"};
    margin-right: 10px;
    font-weight: bold;
    color: ${grayScale.gray100};
  }
  span:last-of-type {
    font-size: 32px;
    color: ${grayScale.gray100};
  }
`;
const btnContainer = css`
  flex-wrap: wrap;
  width: 700px;
  min-width: 738px;
  justify-content: space-around;
  margin: ${isIpad ? "20px auto 10px auto" : "20px auto 120px auto"};
  button {
    border-radius: 3px;
    border: 0;
    height: 120px;
    font-size: 24px;
  }
  button:disabled {
    background-color: ${grayScale.gray20};
  }
`;

const uploadContainer = css`
  height: 250px;
  width: 400px;
  margin: auto;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 2px;
  display: flex;
  align-items: center;
  position: relative;
  span {
    position: absolute;
    left: 120px;
  }
  button {
    margin-left: 35px;
  }
`;
const videoConstraints = {
  width: 400,
  height: 250,
  facingMode: "user",
};

const videoConstraintsIpadVertical = {
  width: 250,
  height: 400,
  facingMode: "user",
};

const FaceRecorderUI: React.FC<{
  stampOrgName: any,
  dataTimes: any,
  staffName: string,
  isConfirm: boolean,
  webcamRef: any,
  isVerticalIpad: boolean,
  onUserMediaError: any,
  photoFullpath: string,
  count: number,
  showMessage: boolean,
  countTimeCap: any,
  countDown: any,
  canvasRef1: any,
  isDisabledButton: boolean,
  disabledRecorder: boolean,
  lastStampType: any,
  handleRecorder: any,
  handleMistake: any,
  isSelectBaby: boolean,
  stampStaffList: any,
  handleSelectBaby: any
}> = ({
  stampOrgName,
  dataTimes,
  staffName,
  isConfirm,
  webcamRef,
  isVerticalIpad,
  onUserMediaError,
  photoFullpath,
  count,
  showMessage,
  countTimeCap,
  canvasRef1,
  isDisabledButton,
  disabledRecorder,
  countDown,
  lastStampType,
  handleRecorder,
  handleMistake,
  isSelectBaby,
  stampStaffList,
  handleSelectBaby
}) => {
  console.log('isDisabledButton', isDisabledButton)

  return (
    <React.Fragment>
      <FlexBox customStyle={styles.titleLabel}>
        {isSelectBaby || isConfirm ? "ご確認ください" : "お顔を写してください"}
      </FlexBox>
      <div css={styles.formContainer}>
        {!isSelectBaby ? (
          <React.Fragment>
            <FlexBox
              customStyle={css({
                flexWrap: "wrap",
                width: isIpad ? "100%" : "80%",
                margin: "auto",
                justifyContent: "center",
              })}
            >
              <FlexBoxItem
                customStyle={css(
                  isIpad
                    ? { width: "300px", flexBasis: isIpad ? "" : "50%" }
                    : {}
                )}
              >
                <div css={isIpad ? styles.ipadTimeContainer : {}}>
                  {dataTimes.currentDay ? (
                    <React.Fragment>
                      {staffName && (
                        <FlexBoxItem customStyle={styles.infoFace}>
                          {stampOrgName}
                          <br />
                          {staffName} 様
                        </FlexBoxItem>
                      )}
                      <FlexBox customStyle={styles.dateForm}>
                        <BodyText
                          size="lg"
                          color={grayScale.gray100}
                          bold={true}
                        >
                          {dataTimes.currentDay}
                        </BodyText>
                      </FlexBox>
                      <FlexBox customStyle={timeCss}>
                        <BodyText>{dataTimes.timeNow}</BodyText>
                        <BodyText>{dataTimes.second}</BodyText>
                      </FlexBox>
                    </React.Fragment>
                  ) : (
                    <div
                      style={{
                        fontSize: isIpad ? "30px" : "80px",
                        textAlign: "center",
                      }}
                    >
                      サーバ時間取得中・・・
                    </div>
                  )}
                </div>
              </FlexBoxItem>
              <FlexBoxItem
                customStyle={css(
                  isIpad
                    ? { width: "400px", flexBasis: isIpad ? "" : "50%" }
                    : {}
                )}
              >
                <div
                  css={[uploadContainer, isConfirm ? { border: "none" } : {}]}
                >
                  {!isConfirm ? (
                    <React.Fragment>
                      <Webcam
                          style={styles.video}
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                          onUserMediaError={onUserMediaError}
                          mirrored={true}
                      />
                      {/* {isVerticalIpad ? (
                        <Webcam
                          style={styles.video}
                          audio={false}
                          width={250}
                          height={400}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraintsIpadVertical}
                          onUserMediaError={onUserMediaError}
                          mirrored={true}
                        />
                      ) : (
                        <Webcam
                          style={styles.video}
                          audio={false}
                          width={400}
                          height={250}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                          onUserMediaError={onUserMediaError}
                          mirrored={true}
                        />
                      )} */}
                    </React.Fragment>
                  ) : (
                    <img
                      width="auto"
                      height="100%"
                      alt=""
                      src={photoFullpath}
                      css={css({ margin: "auto" })}
                    />
                  )}
                </div>
                <canvas
                  ref={canvasRef1}
                  id="canvas1"
                  css={styles.canvas}
                  style={{ display: "none" }}
                />
                {!isConfirm && (
                  <div
                    css={css({
                      width: "400px",
                      margin: "auto",
                      color: grayScale.gray50,
                    })}
                  >
                    {showMessage && (
                      <BodyText size="re" color={grayScale.gray50}>
                        撮影開始まで{count}秒
                      </BodyText>
                    )}
                  </div>
                )}
              </FlexBoxItem>
            </FlexBox>
            <FlexBox customStyle={btnContainer}>
              {!isConfirm ? (
                <FlexBoxItem basis="30%">
                  <RecorderButton
                    text="撮影する"
                    onClick={() => {
                      countTimeCap(webcamRef);
                      countDown();
                    }}
                    icon="capture"
                    background="#45D1D1"
                    disabled={isDisabledButton || disabledRecorder}
                    customStyle={isIpad ? styles.ipadButton : undefined}
                  />
                </FlexBoxItem>
              ) : (
                <React.Fragment>
                  <FlexBox
                    customStyle={css({
                      width: "100%",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                    })}
                  >
                    {(lastStampType === -1 || lastStampType === 3) && (
                      <FlexBoxItem basis="30%">
                        <RecorderButton
                          text="登園"
                          onClick={() => handleRecorder(0)}
                          icon="checkInOffice"
                          background="#007BC3"
                        />
                      </FlexBoxItem>
                    )}
                    {(lastStampType === 0 || lastStampType === 2) && (
                      <FlexBoxItem basis="30%">
                        <RecorderButton
                          text="退園"
                          onClick={() => handleRecorder(3)}
                          icon="checkOut"
                          background="#666666"
                        />
                      </FlexBoxItem>
                    )}
                    <FlexBoxItem basis="30%">
                      <RecorderButton
                        text="人違い"
                        onClick={() => handleMistake()}
                        icon="falsePerson"
                        background="#003993"
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </React.Fragment>
              )}
            </FlexBox>
          </React.Fragment>
        ) : (
          <FlexBox
            customStyle={css({
              flexWrap: "wrap",
              padding: "0 20px",
              width: "100%",
              boxSizing: "border-box",
            })}
          >
            {stampStaffList.map((baby: any, index: number) => {
              return (
                <FlexBoxItem
                  customStyle={css({ width: "250px", marginLeft: "20px" })}
                  key={index}
                >
                  <div
                    css={css({
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    })}
                  >
                    <BodyText bold={true} color="#333" size="md">
                      {baby.staffName}
                    </BodyText>
                    <br />
                    <BodyText bold={true} size="sm">
                      {baby.orgName}
                    </BodyText>
                  </div>
                  <div
                    css={css({
                      width: "250px",
                      height: "250px",
                      boxShadow: "0px 0px 0px 1px #f2f2f2 inset",
                    })}
                  >
                    <img
                      width="100%"
                      height="auto"
                      alt=""
                      src={baby.photoFullpath}
                      onError={(e) => {
                        if(baby.supportStaff){
                          let support = baby.supportStaff[0];
                          let imageSupport = `${process.env.REACT_APP_PHOTO_BASE_URL}/${support.companyCode}/${support.staffCode}/support_staff/${support.fileNo+ '.jpg'}?${Date.now()}`;
                          (e.target as HTMLSourceElement).src = imageSupport;
                        }
                      }}
                      css={css({ margin: "auto" })}
                    />
                  </div>
                  <FlexBox justifyContent="center">
                    <PrimaryButton
                      customStyle={css({
                        marginTop: "20px",
                        border: "0",
                        borderRadius: "3px",
                      })}
                      onClick={() => handleSelectBaby(index)}
                      text={"選択"}
                    />
                  </FlexBox>
                </FlexBoxItem>
              );
            })}
          </FlexBox>
        )}
      </div>
    </React.Fragment>
  );
};

export default FaceRecorderUI;
