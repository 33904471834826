import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getDigestionItemsForTransferHolidayStatus,
  downloadTransferHolidayCsv,
  getTransferHolidayStatusDetail,
} from 'api/holidayManagement';
import moment from 'moment';
import TransferHolidayStatusDomain from 'domain/master/holidayManagement/transferHolidayStatus';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export interface DetailItems {
  staffCode: string,
  staffName: string,
  orgCode: any,
  orgName: any,
  transferDayNumb: any,
  attendTransfer: [],
  targetDateFrom: Date,
  targetDateTo: Date,
  effectiveStartDate: Date
}

export const useHolidayManagementRestForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [transferHolidayStatusList, setTransferHolidayStatusList] = useState<Array<TransferHolidayStatusDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementRest.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const date = moment(new Date()).format('YYYY/MM/DD');
  const [searchDate, setSearchDate] = useSaveStateStorage(date, "holidayManagementRest.searchDate") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    staffCode: '',
    staffName: '',
    orgCode: '',
    orgName: '',
    transferDayNumb: '',
    attendTransfer: [],
    targetDateFrom: new Date(),
    targetDateTo: new Date(),
    effectiveStartDate: new Date()
  });
  const { errorNotification } = useToastNotification();

  const contextObject = useContext(HolidayManagementContext);
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementRest.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getDigestionItemsForTransferHolidayStatus(
        orgCode !== 'all' ? orgCode : '',
        searchDate || date,
        underFlag || 0
      );
      contextObject.setIsLoading(false);
      setTransferHolidayStatusList(response.map((result) => new TransferHolidayStatusDomain(result)));
    }
  }, [orgCode, searchDate, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetTransferHolidayStatusDetail = useCallback(async (staffCode: string) => {
    try {
      await getTransferHolidayStatusDetail(
        staffCode,
        searchDate || date,
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, searchDate]);

  return {
    transferHolidayStatusList,
    callGetTransferHolidayStatusDetail,
    toggleNoManager,
    orgCode,
    setOrgCode,
    date,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagementRest = (
  orgCode: string,
  targetDate: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadHolidayManagementRest = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadTransferHolidayCsv(
      isDownloadAll ? '' : orgCode,
      String(moment(moment(targetDate).toDate()).format('YYYY/MM/DD')),
      underFlag
    ).then(() => {
      setBlocking(false);
    });
  }, [orgCode, targetDate, underFlag]);

  return {
    blocking,
    downloadHolidayManagementRest
  };
};

export default {
  useHolidayManagementRestForm,
};
