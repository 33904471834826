/* eslint-disable max-len */
/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import BodyText from 'components/atoms/BodyText';
import FormPadding from 'components/atoms/Form/FormPadding';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { UploadButton } from "components/atoms/Button";
import { DownLoadButton } from "components/atoms/Button";
import { useHistory, useParams } from "react-router-dom";
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { PrimaryButton } from "components/atoms/Button";
import { downloadCSVTemplate, importCSV, Error } from "api/iCCard";
import FileSelectButton from "components/molecules/FileSelectButton";
import useToastNotification from 'hooks/useToastNotification';
import FormField from "components/atoms/Form/FormField";
import { utilityColor } from "components/styles";
import Modal from "components/molecules/Modal";
import ImportCSVForm from 'components/molecules/ImportCSVForm';

const styles = {
    noteForm: css({
       margin: '10px 0 0 20px',
       color: '#007BC3'
    }),
    titleForm: css({
        fontSize: '14px',
        fontWeight: 'bold',
        marginTop: '15px',
        color: '#666666'
    }),
    uploadButton: css({
        marginTop: '25px',
        justifyContent: "center"
    }),
    fileSelectButton: css({
        width: '400px',
        margin: 'auto',
        height: '107px'
    }),
    noteLabel: css({
        marginBottom: '50px',
        justifyContent: "center",
    }),
    noteText: css({
        fontSize: '12px',
        color: '#E95562'
    }),
}

const MasterICCardImportForm = () => {

    const history = useHistory();
    const [uploadFile, setUploadFile] = useState<File>();
    const { successNotification, errorNotification } = useToastNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<Array<string>>();

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const closeConfirmModal = useCallback(() => {
        setConfirmModalOpen(false);
      }, []);

    const downloadFileCSVTemplate = useCallback(() => {
        downloadCSVTemplate()
    }, []);

    const onSubmit = useCallback(() => {
        setIsLoading(true);
        if (!uploadFile) {
          return;
        }
        setConfirmModalOpen(false);
        setErrors(undefined);
    
        importCSV(uploadFile)
          .then((response: any) => {
            setIsLoading(false);
            setUploadFile(undefined);
            if (response.status !== 200) {
              setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
            } else {
              successNotification('インポートが完了しました');
            }
          }).catch((error: any) => {
            setIsLoading(false);
            setUploadFile(undefined);
            setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
            if (error.response && error.response.data && error.response.data.errors) {
            } else {
              errorNotification('サーバー側でエラーが発生しました。');
            }
          });
      }, [errorNotification, successNotification, uploadFile]);

    const handleSubmit = useCallback(() => {
        setConfirmModalOpen(true);
    }, []);

    return (
    <BlockUI blocking={isLoading}>
        <div>
        <FormContents customStyle={{ paddingBottom: '50px' }}>
            <FormTitle title="ICカードインポート" bold={true} customStyle={css`margin: 16px 35px;`} />
            <FormPadding customStyle={{ padding: '0 30px' }}>
                <FlexBox justifyContent='space-between'>
                    <FlexBoxItem>
                        <BodyText size='sm' color='#007BC3'>打刻に利用するICカードをCSVファイルから一括インポートできます。一人の従業員に複数のICカードを紐付けることも可能です。</BodyText>
                    </FlexBoxItem>
                    {/* <FlexBoxItem>
                        <ICCardConnectButton
                            disabled={isConnectUsb}
                            onClick={() => {}}
                        />
                    </FlexBoxItem> */}
                </FlexBox>
                <FlexBox>
                    <BodyText customStyle={styles.titleForm}>1. ICカード情報インポート用のCSVファイルを用意しましょう</BodyText>
                </FlexBox>
                <FlexBox>
                    <BodyText size="sm" customStyle={styles.noteForm}>下記のボタンから従業員情報をCSV形式でダウンロードし、Microsoft Excelなどで開いて編集してください。</BodyText>
                </FlexBox>
                <FlexBox customStyle={styles.uploadButton} >
                    <DownLoadButton
                        iconType="download"
                        onClick={() => downloadFileCSVTemplate()}
                        text="テンプレートダウンロード"
                        customStyle={css({
                            width: '400px',
                            height: "80px"
                        })}
                    />
                </FlexBox>
                <FlexBox justifyContent="center">
                    <BodyText customStyle={styles.noteText}>テンプレートダウンロードした後、２行目のサンプルデータを削除してください。</BodyText>
                </FlexBox>
                <FlexBox>
                    <BodyText customStyle={styles.titleForm}>2. 編集したCSVファイルをアップロードしてください</BodyText>
                </FlexBox>
                <ImportCSVForm
                    uploadFile={uploadFile}
                    setUploadFile={setUploadFile}
                    errors={errors}
                />
                
                <FormSubmitArea customStyle={css({ marginTop: "15px" })}>
                    <FlexBox justifyContent="center">
                        <PrimaryButton
                            onClick={() => handleSubmit()}
                            text="インポート"
                            disabled={!uploadFile}
                        />
                    </FlexBox>
                    <FlexBox justifyContent="center">
                        <PrimaryButton
                            onClick={() => history.push('/masterICCard')}
                            text="キャンセル"
                            ghost={true}
                        />
                    </FlexBox>
                </FormSubmitArea>
                <Modal
                    open={confirmModalOpen}
                    closeHandler={closeConfirmModal}
                    title="ファイルをアップロード"
                    submitText="アップロードする"
                    note="ファイルをアップロードします。よろしいですか？"
                    onSubmit={onSubmit}
                >
                    <div style={{ height: "40px" }}></div>
                </Modal>
            </FormPadding>
        </FormContents>
        </div>
    </BlockUI>
    );
};

export default MasterICCardImportForm;
