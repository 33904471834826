/** @jsx jsx */

import React, { useState, useEffect, useRef } from "react";
import Icon from "components/atoms/Icon";
import { utilityColor } from "components/styles";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import FlexBox from "components/atoms/FlexBox";
import Select, { OptionType } from "components/atoms/Select";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { DateRangePicker } from "./react-date-range-master/src/index";

import { Option, Input, IndicatorsContainer, MenuComponent } from "./Component";
import {
  formatDate,
  getEndDateCanNull,
  getOptionsYearEnd,
  getOptionsMonth,
  addAMonth,
  subAMonth,
  addAYear,
  subAYear,
  confirmState,
} from "./Helpper";

// Style library
import "./react-date-range-master/src/styles.scss"; // main css file
import "./react-date-range-master/src/theme/default.scss";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import useIsMobile from "hooks/useIsMobile";

const listMonth = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

const optionsMonth = listMonth.map((Hours: any) => ({
  value: Hours,
  label: Hours,
}));

type ModeChange = "addMonth" | "subMonth" | "addYear" | "subYear" | "setYear" | "setMonth" | "set";

const getMaxTime = (startTime: Date, endTime: Date) => {
  let tmpStartDate = new Date(JSON.parse(JSON.stringify(startTime)));
  let tmpEndDate = new Date(JSON.parse(JSON.stringify(endTime)));
  if (
    tmpEndDate.getFullYear() > tmpStartDate.getFullYear() ||
    (tmpEndDate.getFullYear() == tmpStartDate.getFullYear() && tmpEndDate.getMonth() > tmpStartDate.getMonth())
  ) {
    return tmpEndDate;
  }
  return addAMonth(tmpEndDate);
};
const MIN_YEAR = 1900;
const MAX_YEAR = 2099;

const CalendarRangeV2: React.FC<{
  readOnly?: boolean;
  width?: string;
  dateFormat?: string;
  startDate?: Date;
  onChangeStart: (date: Date) => void;
  endDate?: Date;
  canNullEndDate?: boolean;
  onChangeEnd: (date: Date | null) => void;
  customStyle?: SerializedStyles;
  validateTime?: (startDate: Date, endDate: Date) => boolean;
}> = ({
  dateFormat = "YYYY/MM/DD",
  startDate = new Date(),
  onChangeStart,
  onChangeEnd,
  readOnly = false,
  width = "240px",
  canNullEndDate = false,
  endDate = canNullEndDate ? undefined : new Date(),
  customStyle,
  validateTime,
}) => {
  const isMobile = useIsMobile();
  const [state, setState] = useState([
    {
      startDate: startDate || new Date(),
      endDate: endDate,
      key: "selection",
    },
  ]);

  const changeEndDate = (date: Date | null) => {
    let tmpState = [...state];
    if (date === null) {
      tmpState[0].endDate = undefined;
    } else {
      tmpState[0].endDate = date;
    }
    setState(tmpState);
  };

  const validateYear = (date: Date) => {
    if (date.getFullYear() < MIN_YEAR || date.getFullYear() > MAX_YEAR) {
      return false;
    }
    return true;
  };

  const [overFlowBottom, setOverFlowBottom] = useState(false);

  useEffect(() => {
    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  }, [startDate, endDate]);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [maxDate, setMaxDate] = useState(getMaxTime(startDate || new Date(), endDate || new Date()));

  const [rangeMonths, setRangeMonths] = useState(endDate ? endDate.getMonth() - (startDate?.getMonth() || 0) : 1);

  const [optionsYear, setOptionsYear] = useState<Array<OptionType>>([]);

  useEffect(() => {
    var listYear = [];
    for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
      listYear.push(i);
    }
    setOptionsYear(
      listYear.map((year) => {
        return {
          value: `${year}`,
          label: `${year}`,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (state[0].endDate === state[0].startDate && state[0].startDate.getMonth() === maxDate.getMonth()) {
      let tmpMaxDate = new Date(JSON.parse(JSON.stringify(maxDate)));
      setMaxDate(addAMonth(tmpMaxDate));
      setRangeMonths(1);
    }
    if (state[0].startDate !== state[0].endDate) {
      if (state[0].endDate) {
        setOpenCalendar(false);
      }
    } else if (canNullEndDate) {
      changeEndDate(null);
    }
  }, [state]);

  useEffect(() => {
    if (!openCalendar) {
      if (state[0].endDate && validateTime && !validateTime(state[0].startDate, state[0].endDate)) {
        setState([
          {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
          },
        ]);
        return;
      }
      onChangeStart(state[0].startDate);
      if (state[0].endDate) {
        if (state[0].endDate === undefined) {
          onChangeEnd(null);
        } else {
          onChangeEnd(state[0].endDate);
        }
      }
    }
  }, [openCalendar]);

  const handleChangeMaxDate = (mode: ModeChange, currentFocusedDate: Date, param?: string | Date) => {
    let tmpMaxDate = new Date(JSON.parse(JSON.stringify(maxDate)));

    switch (mode) {
      case "addYear":
        tmpMaxDate = addAYear(tmpMaxDate);
        break;
      case "subYear":
        tmpMaxDate = subAYear(tmpMaxDate);
        break;
      case "addMonth":
        tmpMaxDate = addAMonth(tmpMaxDate);
        break;
      case "subMonth":
        tmpMaxDate = subAMonth(tmpMaxDate);
        break;
      case "setYear":
        tmpMaxDate.setFullYear(Number(param));
        break;
      case "setMonth":
        tmpMaxDate.setMonth(Number(param) - 1);
        break;
      case "set":
        if (param && param instanceof Date) {
          tmpMaxDate = param;
        }
        break;
      default:
        break;
    }
    if (!validateYear(tmpMaxDate)) {
      return;
    }
    if (tmpMaxDate <= currentFocusedDate) {
      tmpMaxDate = new Date(JSON.parse(JSON.stringify(currentFocusedDate)));
      tmpMaxDate = addAMonth(tmpMaxDate);
    }
    setMaxDate(tmpMaxDate);
    let rangeMonths = 1;
    let rangeYears = 0;
    rangeMonths = tmpMaxDate?.getMonth() - currentFocusedDate?.getMonth();
    rangeYears = tmpMaxDate?.getFullYear() - currentFocusedDate?.getFullYear();
    setRangeMonths(rangeMonths + rangeYears * 12);
  };

  const wrapRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (wrapRef.current && !wrapRef.current?.contains(event.target)) {
      setOpenCalendar(false);
      if (state[0].startDate !== state[0].endDate) {
        setOpenCalendar(false);
        onChangeStart(state[0].startDate);
        if (state[0].endDate === undefined) {
          onChangeEnd(null);
        } else {
          onChangeEnd(state[0].endDate);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleChangeShowDate = (currentFocusedDate: Date) => {
    let tmpDate = new Date(JSON.parse(JSON.stringify(currentFocusedDate)));
    let tmpEndDate = new Date(JSON.parse(JSON.stringify(maxDate)));
    if (moment(currentFocusedDate).startOf("month") >= moment(maxDate).startOf("month")) {
      tmpDate = addAMonth(tmpDate);
      tmpEndDate = tmpDate;
      setMaxDate(tmpDate);
    }
    let rangeMonths = 1;
    let rangeYears = 0;
    rangeMonths = tmpEndDate?.getMonth() - currentFocusedDate?.getMonth();
    rangeYears = tmpEndDate?.getFullYear() - currentFocusedDate?.getFullYear();
    setRangeMonths(rangeMonths + rangeYears * 12);
  };

  const updateRangeMonths = (date: Date) => {
    let rangeMonths = 1;
    let rangeYears = 0;
    rangeMonths = maxDate?.getMonth() - date?.getMonth();
    rangeYears = maxDate?.getFullYear() - date?.getFullYear();
    setRangeMonths(rangeMonths + rangeYears * 12);
  };

  useEffect(() => {
    if (ref && ref.current) {
      let offsetBottom = window.innerHeight - ref.current?.offsetHeight - ref.current?.getBoundingClientRect().top;
      if (offsetBottom < 425 && ref.current?.getBoundingClientRect().top >= 425) {
        setOverFlowBottom(true);
      } else {
        setOverFlowBottom(false);
      }
    }
  }, [ref]);

  return (
    <div ref={ref} style={{ position: "relative" }} css={css(customStyle)}>
      <div
        className={`container-value-date-range ${readOnly ? "calendar-range-custom__readOnly" : ""}`}
        style={{
          width: width,
        }}
        onClick={() => {
          setOpenCalendar(true);
        }}
      >
        {`${formatDate(state[0].startDate, dateFormat)}`}
        <span style={{ margin: "10px" }}>～</span>
        {`${!canNullEndDate ? formatDate(state[0].endDate, dateFormat) : getEndDateCanNull(state, dateFormat)}`}
        <div className="icf-calendar">
          <Icon type="date" color={utilityColor.black} />
        </div>
      </div>
      {openCalendar && (
        <div ref={wrapRef}>
          <DateRangePicker
            onChangeFocusDate={(focusDate: Date) => {
              updateRangeMonths(focusDate);
            }}
            className={overFlowBottom ? "reverse-calendar" : ""}
            onChange={(item: any) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={isMobile ? 1 : rangeMonths > 1 ? rangeMonths + 1 : 2}
            ranges={state}
            direction="horizontal"
            navigatorRenderer={(...props: any) => {
              let changeShownDate = props[1];
              let currentFocusedDate = props[0];
              const handleChangeCurrentShowDate = (mode: ModeChange, param?: string | Date) => {
                let tmpCurrentDate = new Date(JSON.parse(JSON.stringify(currentFocusedDate)));
                switch (mode) {
                  case "addYear":
                    tmpCurrentDate = addAYear(tmpCurrentDate);
                    break;
                  case "subYear":
                    tmpCurrentDate = subAYear(tmpCurrentDate);
                    break;
                  case "addMonth":
                    tmpCurrentDate = addAMonth(tmpCurrentDate);
                    break;
                  case "subMonth":
                    tmpCurrentDate = subAMonth(tmpCurrentDate);
                    break;
                  case "setYear":
                    tmpCurrentDate.setFullYear(Number(param));
                    break;
                  case "setMonth":
                    tmpCurrentDate.setMonth(Number(param) - 1);
                    break;
                  case "set":
                    if (param && param instanceof Date) {
                      tmpCurrentDate = param;
                    }
                    break;
                  default:
                    break;
                }
                if (!validateYear(tmpCurrentDate)) {
                  return;
                }
                changeShownDate(tmpCurrentDate);
                handleChangeShowDate(tmpCurrentDate);
              };
              return (
                <React.Fragment>
                  <FlexBox justifyContent="space-between">
                    <FlexBoxItem width={isMobile ? "100%" : "50%"}>
                      <div>
                        <div className="calendar__year">
                          <span
                            className="calendar__arrow calendar__arrow--left"
                            onClick={() => {
                              handleChangeCurrentShowDate("subYear");
                            }}
                          ></span>
                          <div
                            css={css`
                              div {
                                justify-content: center;
                                border: 0;
                                :hover {
                                  border: 0;
                                }
                              }
                            `}
                          >
                            <Select
                              name="select-month"
                              className="calendar__year-value"
                              value={String(currentFocusedDate?.getFullYear())}
                              setValue={(arg: string) => {
                                handleChangeCurrentShowDate("setYear", arg);
                              }}
                              options={optionsYear}
                              controlStyle={{
                                width: "70px",
                                border: "0",
                                backgroundColor: "transparent",
                              }}
                              components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                              maxMenuHeight={200}
                              customMenuStyle={{
                                marginTop: "0",
                              }}
                              isSearchable={false}
                            />
                          </div>
                          <span
                            className="calendar__arrow calendar__arrow--right"
                            onClick={() => {
                              handleChangeCurrentShowDate("addYear");
                            }}
                          ></span>
                        </div>
                        <div className="calendar__month">
                          <span
                            className="calendar__arrow calendar__arrow--left"
                            onClick={() => {
                              handleChangeCurrentShowDate("subMonth");
                            }}
                          ></span>
                          <div
                            css={css`
                              div {
                                justify-content: center;
                                border: 0;
                                :hover {
                                  border: 0;
                                  cursor: pointer;
                                }
                              }
                            `}
                          >
                            <Select
                              name="select-month"
                              className="calendar__month-value"
                              value={String(currentFocusedDate?.getMonth() + 1)}
                              setValue={(arg: string) => {
                                handleChangeCurrentShowDate("setMonth", arg);
                              }}
                              options={optionsMonth}
                              controlStyle={{
                                width: "50px",
                                border: "0",
                                backgroundColor: "transparent",
                              }}
                              components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                              maxMenuHeight={200}
                              customMenuStyle={{
                                marginTop: "0",
                              }}
                              isSearchable={false}
                            />
                          </div>
                          <span
                            className="calendar__arrow calendar__arrow--right"
                            onClick={() => {
                              handleChangeCurrentShowDate("addMonth");
                            }}
                          ></span>
                        </div>
                      </div>
                    </FlexBoxItem>

                    {!isMobile && (
                      <FlexBoxItem width="50%">
                        <div>
                          <div className="calendar__year">
                            <span
                              className="calendar__arrow calendar__arrow--left"
                              onClick={() => {
                                handleChangeMaxDate("subYear", currentFocusedDate);
                              }}
                            ></span>
                            <div
                              css={css`
                                div {
                                  justify-content: center;
                                  border: 0;
                                  :hover {
                                    border: 0;
                                  }
                                }
                              `}
                            >
                              <Select
                                name="select-month"
                                className="calendar__year-value"
                                value={maxDate ? String(maxDate.getFullYear()) : String(startDate?.getFullYear())}
                                setValue={(arg: string) => handleChangeMaxDate("setYear", currentFocusedDate, arg)}
                                options={getOptionsYearEnd(optionsYear, currentFocusedDate)}
                                placeholder="チーフ"
                                controlStyle={{
                                  width: "70px",
                                  border: "0",
                                  backgroundColor: "transparent",
                                }}
                                components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                                maxMenuHeight={200}
                                customMenuStyle={{
                                  marginTop: "0",
                                }}
                                isSearchable={false}
                              />
                            </div>
                            <span
                              className="calendar__arrow calendar__arrow--right"
                              onClick={() => {
                                handleChangeMaxDate("addYear", currentFocusedDate);
                              }}
                            ></span>
                          </div>
                          <div className="calendar__month">
                            <span
                              className="calendar__arrow calendar__arrow--left"
                              onClick={() => {
                                handleChangeMaxDate("subMonth", currentFocusedDate);
                              }}
                            ></span>
                            <div
                              css={css`
                                div {
                                  justify-content: center;
                                  border: 0;
                                  :hover {
                                    border: 0;
                                    cursor: pointer;
                                  }
                                }
                              `}
                            >
                              <Select
                                name="select-month"
                                className="calendar__month-value"
                                value={
                                  maxDate ? String(maxDate.getMonth() + 1) : String((startDate?.getMonth() || 0) + 1)
                                }
                                setValue={(arg: string) => handleChangeMaxDate("setMonth", currentFocusedDate, arg)}
                                options={getOptionsMonth(optionsMonth, maxDate, currentFocusedDate || new Date())}
                                placeholder="チーフ"
                                controlStyle={{
                                  width: "50px",
                                  border: "0",
                                  backgroundColor: "transparent",
                                }}
                                components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                                maxMenuHeight={200}
                                customMenuStyle={{
                                  marginTop: "0",
                                }}
                                isSearchable={false}
                              />
                            </div>
                            <span
                              className="calendar__arrow calendar__arrow--right"
                              onClick={() => {
                                handleChangeMaxDate("addMonth", currentFocusedDate);
                              }}
                            ></span>
                          </div>
                        </div>
                      </FlexBoxItem>
                    )}
                  </FlexBox>
                  <div
                    className="label-current-range label-current"
                    css={css({
                      width: isMobile ? "100% !important" : "50% !important",
                    })}
                    onClick={() => {
                      handleChangeCurrentShowDate("set", new Date());
                      let tmpState = [...state];
                      if (tmpState[0].startDate === tmpState[0].endDate) {
                        tmpState[0].endDate = new Date();
                      } else if (tmpState[0].endDate && tmpState[0].endDate < new Date()) {
                        tmpState[0].startDate = tmpState[0].endDate;
                        tmpState[0].endDate = new Date();
                      } else {
                        tmpState[0].startDate = new Date();
                      }
                      tmpState = confirmState(tmpState);
                      setState(tmpState);
                    }}
                  >
                    今日
                  </div>
                  {!isMobile && (
                    <div
                      className="label-current-range label-current label-max-date"
                      onClick={() => {
                        handleChangeMaxDate("set", new Date());
                        let tmpState = [...state];
                        if (tmpState[0].startDate > new Date()) {
                          let tmpStart = state[0].startDate;
                          tmpState[0].startDate = new Date();
                          tmpState[0].endDate = tmpStart;
                        } else {
                          tmpState[0].endDate = new Date();
                        }
                        tmpState = confirmState(tmpState);
                        setState(tmpState);
                      }}
                    >
                      今日
                    </div>
                  )}
                </React.Fragment>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarRangeV2;
