import React from "react";

import Calendar from "components/molecules/Calendar";
import MonthEvent from "components/organismos/ShiftMonthCalendar/MonthEvent";
import MonthDateHeader from "./MonthDateHeader";
import MonthEventWrapper from "./MonthEventWrapper";

import { shiftMonthCalendarContext, useMonthCalendarEvents, useInitialShiftMonthCalendarContext } from "./hooks";

const ShiftMonthCalendar: React.FC<{
  date: Date;
  setDate: (date: Date) => void;
  setView: (view: "month" | "day") => void;
  orgCode: string;
}> = ({ date, setDate, setView, orgCode }) => {
  const context = useInitialShiftMonthCalendarContext();
  const { events, setEvents } = context;
  useMonthCalendarEvents(date, orgCode, setEvents);

  return (
    <shiftMonthCalendarContext.Provider value={context}>
      <Calendar
        date={date}
        setDate={setDate}
        events={events}
        views={["month"]}
        onDrillDown={(selectDate) => {
          setDate(selectDate);
          setView("day");
        }}
        components={{
          month: {
            event: MonthEvent,
            // Propsをそのまま渡すため
            // eslint-disable-next-line react/jsx-props-no-spreading
            dateHeader: MonthDateHeader,
          },
          // Toolbarは独自で作成するため潰す
          toolbar: () => <></>,
          eventWrapper: MonthEventWrapper,
        }}
      />
    </shiftMonthCalendarContext.Provider>
  );
};

export default ShiftMonthCalendar;
