/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { useDownload } from './hooks';

const MonthlySalesManagementTable: React.FC<{
  monthlyReport: any,
  targetPeriodData: any,
  orgLabel: any
}> = ({
  monthlyReport,
  targetPeriodData,
  orgLabel,
}) => {
  const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 0,
  });

  const [getOrgCodeName, setOrgCodeName] = useState('');
  const searchResult: any[] = [];
  useEffect(() => {
    monthlyReport.map((item: any, index: any) => {
      searchResult.push([
        item.orgCode || '',
        item.orgName || '',
        item.areaName || '',
        item.businessCategoryName || '',
        formatter.format(item.netSales) || 0,
        formatter.format(item.tax) || 0,
        formatter.format(item.totalSales) || 0,
        formatter.format(item.guestCnt) || 0,
        formatter.format(item.perCustomerPrice) || 0,
        formatter.format(item.customers) || 0,
        formatter.format(item.perGroupPrice) || 0,
        formatter.format(item.totalDiscountAmount) || 0,
        formatter.format(item.totalDiscountCnt) || 0,
        formatter.format(item.voidAmount) || 0,
        formatter.format(item.voidCnt) || 0,
        formatter.format(item.stopAccountAmount) || 0,
        formatter.format(item.stopAccountCnt) || 0,
        formatter.format(item.posMenuCancelAmount) || 0,
        formatter.format(item.posMenuCancelCnt) || 0,
        formatter.format(item.changeReserve) || 0,
        formatter.format(item.cashStock) || 0,
        formatter.format(item.excessAndDeficiency) || 0,
        formatter.format(item.bankDepositAmount) || 0,
      ]);
    });
    const str = orgLabel.join('、');
    setOrgCodeName(str);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyReport, orgLabel, searchResult, setOrgCodeName]);

  const targetStoresData = `対象組織：${getOrgCodeName}`;
  const headerInformation = [
    // eslint-disable-next-line quotes
    ["", "", "", "", '売上', "", "", "", "", "", "", '割引券', "", 'VOID', "", '会計中止', "", 'メニュー取消', "", 'レジ金', "", "", ""],
    ['組織コード', '組織名', 'エリア', '業態', '純売上', '消費税', '総売上', '客数', '客単価', '組数', '組単価', '金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金'],
  ];


  const codeColumnIndex: any[] = [];
  const data = {
    footer: '',
    formName: '月次売上管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    headerInformation,
    searchResult,
    totalInformation: [],
  };

  let mergedRegions: any[] = [];
  const dataExcel = {
    formName: '月次売上管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    headerInformation,
    searchResult,
    totalInformation: [],
    mergedRegions,
    codeColumnIndex,
  };

  const handleExportCSV = () => downloadMonthlyReportCsv(data);
  const handleExportExcel = () => {
    if (mergedRegions) {
      mergedRegions = [];
    }
    mergedRegions.push([3, 3, 4, 10]);
    mergedRegions.push([3, 3, 11, 12]);
    mergedRegions.push([3, 3, 13, 14]);
    mergedRegions.push([3, 3, 15, 16]);
    mergedRegions.push([3, 3, 17, 18]);
    mergedRegions.push([3, 3, 19, 22]);
    mergedRegions.push([3, 4, 0, 0]);
    mergedRegions.push([3, 4, 1, 1]);
    mergedRegions.push([3, 4, 2, 2]);
    mergedRegions.push([3, 4, 3, 3]);
    dataExcel.mergedRegions = mergedRegions;
    dataExcel.codeColumnIndex = [0];
    dataExcel.headerInformation = [
      [
        '組織コード', '組織名', 'エリア', '業態', '売上', '', '', '', '', '', '', '割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', '',
      ],
      [
        '', '', '', '', '純売上', '消費税', '総売上', '客数', '客単価', '組数', '組単価', '金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金',
      ],
    ];
    return downloadMonthlyReportExcel(dataExcel);
  };

  const tblRows = ['純売上', '消費税', '総売上', '客数', '客単価', '組数', '組単価', '金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金'];
  const widthScrollbar = (22 * 135) + 100;
  const [getFlagBorder, setFlagBorder] = useState(0);
  const eventScroll = (e: { target: any }) => {
    const { target } = e;
    const wrapTblRight = document.getElementById('wrapTblRight');
    const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
    const jsScrollAction = document.getElementById('jsScrollAction');

    if (Number(target.scrollLeft) > 0) {
      setFlagBorder(1);
    } else {
      setFlagBorder(0);
    }
    if (wrapTblRight) {
      wrapTblRight.scrollLeft = target.scrollLeft;
    }
    if (wrapTblHeadRight) {
      wrapTblHeadRight.scrollLeft = target.scrollLeft;
    }
    if (jsScrollAction) {
      jsScrollAction.scrollLeft = target.scrollLeft;
    }
  };
  return (
    <div>
      <FlexBox>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportCSV}
            iconType="download"
            text=" CSV出力"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportExcel}
            iconType="download"
            text=" EXCEL出力"
          />
        </FlexBoxItem>
      </FlexBox>
      <div className="wrap_time_day_separate_sales wrap_sales_management_table" style={{ overflow: 'hidden', marginTop: '15px' }}>
        <div className="contentHead" style={{ display: 'flex' }}>
          <div className="tblLeft" style={getFlagBorder ? { borderRight: '1px solid' } : {}}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} className="text-center"><span>組織コード</span></th>
                  <th rowSpan={2} className="text-center sticky-col"><span>組織名 </span></th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tblRight" id="wrapTblHeadRight" style={monthlyReport.length > 17 ? { width: 'calc(100% - 342px)' } : {}} onScroll={eventScroll}>
            <table style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th rowSpan={2}><span>エリア</span></th>
                  <th rowSpan={2}><span>業態</span></th>
                  <th colSpan={7}><span style={{ width: '100%' }}>売上</span></th>
                  <th colSpan={2}><span style={{ width: '100%' }}>割引券</span></th>
                  <th colSpan={2}><span style={{ width: '100%' }}>VOID</span></th>
                  <th colSpan={2}><span style={{ width: '100%' }}>会計中止</span></th>
                  <th colSpan={2}><span style={{ width: '100%' }}>メニュー取消</span></th>
                  <th colSpan={4}><span style={{ width: '100%' }}>レジ金</span></th>
                </tr>
                <tr>
                  {tblRows.map((item: any, index: any) => (<th key={index}><span>{item}</span></th>))}
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <div>
            <div className="contentTable">
              <div className="tblLeft">
                <table>
                  <tbody>
                    {
                      monthlyReport.map((item: any, index: any) => (
                        <tr key={index}>
                          <td style={{ width: '50%' }}>{item.orgCode}</td>
                          <td>{item.orgName}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="tblRight" id="wrapTblRight" onScroll={eventScroll}>
                <table id="tblHeadTable" onScroll={eventScroll}>
                  <tbody>
                    {
                      monthlyReport.map((item: any, index: any) => (
                        <tr key={index}>
                          <td><span className="text-center">{item.areaName}</span></td>
                          <td><span className="text-center">{item.businessCategoryName}</span></td>
                          <td><span>{formatter.format(item.netSales)}</span></td>
                          <td><span>{formatter.format(item.tax)}</span></td>
                          <td><span>{formatter.format(item.totalSales)}</span></td>
                          <td><span>{formatter.format(item.guestCnt)}</span></td>
                          <td><span>{formatter.format(item.perCustomerPrice)}</span></td>
                          <td><span>{formatter.format(item.customers)}</span></td>
                          <td><span>{formatter.format(item.perGroupPrice)}</span></td>
                          <td><span>{formatter.format(item.totalDiscountAmount)}</span></td>
                          <td><span>{formatter.format(item.totalDiscountCnt)}</span></td>
                          <td><span>{formatter.format(item.voidAmount)}</span></td>
                          <td><span>{formatter.format(item.voidCnt)}</span></td>
                          <td><span>{formatter.format(item.stopAccountAmount)}</span></td>
                          <td><span>{formatter.format(item.stopAccountCnt)}</span></td>
                          <td><span>{formatter.format(item.posMenuCancelAmount)}</span></td>
                          <td><span>{formatter.format(item.posMenuCancelCnt)}</span></td>
                          <td><span>{formatter.format(item.changeReserve)}</span></td>
                          <td><span>{formatter.format(item.cashStock)}</span></td>
                          <td><span>{formatter.format(item.excessAndDeficiency)}</span></td>
                          <td><span>{formatter.format(item.bankDepositAmount)}</span></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {
            monthlyReport.length > 0 ? (
              <div className="scroller">
                <div className="scrollLeft" style={{ width: '325px' }} />
                <div className="scrollRight" id="jsScrollAction" style={{ width: 'calc(100% - 325px)' }} onScroll={eventScroll}>
                  <div className="scrollAction" style={{ width: `${widthScrollbar}px` }} />
                </div>
              </div>
            ) : null
          }
      </div>

    </div>
  );
};

export default MonthlySalesManagementTable;
