/** @jsx jsx */
// import moment from 'moment';
import React, { useMemo, useState, useCallback } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import DataTable from 'components/organismos/DataTable/DataTable';
import HolidayMasterDomain from 'domain/master/labor/holidayMaster';
import { deleteById } from 'api/holidayMaster';
import { useOrgAllSelect } from 'hooks/useOrgTreesOptions';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import { useHolidayMasterList } from './hooks';
import SidebarTemplate from 'components/templates/SidebarTemplate';
// import CalendarForm from 'components/molecules/CalendarForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Row, Col } from 'react-grid-system';
import { grayScale, textFontSize } from 'components/styles';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FormContents from 'components/atoms/Form/FormContents';
import FormPadding from 'components/atoms/Form/FormPadding';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { DeleteButton, InfoButton } from 'components/atoms/Button';
import BlockUI from 'components/molecules/BlockUi';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import FormTitle from 'components/atoms/Form/FormTitle';
import ConfirmModal from 'components/organismos/ConfirmModal';
import SelectForm from 'components/molecules/SelectForm';
import useSaveStateStorage from 'hooks/useSaveFilterStorage';


const HolidayMasterPage: React.FC<{}> = () => {
  // const startOfYear = moment()
  //   .startOf('year')
  //   .format('YYYY-MM-DD');
  const [selectedYear, setSelectedYear] = useState(Number(sessionStorage.getItem('selectedYearHolidayMaster')));
  //const [selectedYear, setSelectedYear] = useState<Date>(new Date(startOfYear));
  const [orgCode, setorgCode] = useSaveStateStorage("", "selectedOrgCodeHolidayMaster") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  
  const selectedStartDate = `${selectedYear}-01-01`;
  const selectedEndDate = `${selectedYear}-12-31`;
  // const selectedStartDate = `${moment(selectedYear).format('YYYY')}-01-01`;
  // const selectedEndDate = `${moment(selectedYear).format('YYYY')}-12-31`;
  const orgTreesOptions = useOrgAllSelect(true, selectedStartDate, selectedEndDate);


  const { holidayList, setHolidayList, isLoading, detailRole } = useHolidayMasterList(
    String(orgCode), String(selectedYear),
  );


  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedHolidayMaster, setSelectedHolidayMaster] = useState(
    HolidayMasterDomain.generateInitial(),
  );
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((holiday : HolidayMasterDomain) => {
    setConfirmModalOpen(true);
    setSelectedHolidayMaster(holiday);
  }, []);

  const deleteUpdateHoliday = useCallback(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

    deleteById(
      companyCode, selectedHolidayMaster.publicHolidayDate,
      selectedHolidayMaster.publicHolidayName,
      (String(orgCode)),
    ).then((Response: any) => {
      setHolidayList(
        holidayList.filter(
          (holiday) => holiday.publicHolidayId !== selectedHolidayMaster.publicHolidayId,
        ),
      );
      // fix row is hidden alfter delete
      const old = orgCode;
      setorgCode('all');
      setorgCode(old);
      setConfirmModalOpen(false);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, holidayList, orgCode, selectedHolidayMaster.publicHolidayDate,
    selectedHolidayMaster.publicHolidayId, selectedHolidayMaster.publicHolidayName,
    setHolidayList, successNotification]);

  const columns: Array<Column<HolidayMasterDomain>> = useMemo(() => [
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: HolidayMasterDomain },
        data: Array<HolidayMasterDomain>
      }) => {
        return (
          <FlexBox customStyle={css({ justifyContent: 'space-evenly' })}>
            <Link to={`/holiday/edit/${`${cell.row.original.publicHolidayId}/${cell.row.original.publicHolidayDate}/${cell.row.original.publicHolidayName}/${orgCode}/false`}`}>
              <InfoButton
                text={detailRole.editable === 1 ? `編集` : `参照`}
                onClick={() => {
                  
                }}
                minWidth={'40px'}
                customStyle={css({ borderRadius: '3px', fontSize: '12px', lineHeight: '20px', padding: '0 5px', height: '24px' })}
              />
            </Link>
            {detailRole.editable === 1 ? (
              <DeleteButton
                text="削除"
                onClick={() => {
                  openConfirmModal(cell.row.original); 
                }}
                minWidth={'40px'}
                customStyle={css({ borderRadius: '3px', fontSize: '12px', lineHeight: '20px', padding: '0 5px', height: '24px' })}
              />
            ) : null}
            
          </FlexBox>
        );
      },
    },
    {
      Header: '日付',
      accessor: 'publicHolidayDateStr',
    },
    {
      Header: '祝日名',
      accessor: 'publicHolidayName',
    },
  ], [openConfirmModal, orgCode, detailRole]);

  const style = {
    color: `${grayScale.gray60}`,
    fontSize: `${textFontSize.re}`
  };

  return (
    <SidebarTemplate pageTitle="労務規約項目マスタ">
      <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle bold={true} title="祝日マスタ" />
        <FormPadding customStyle={css({padding: '10px 35px'})}>
          <Row style={{ margin: '15px 0' }}>
            <Col md={1} xs={2}>
              <span style={style}>対象年</span>
            </Col>
            <Col md={3} xs={6}>
              <FlexBoxItem width="160px" customStyle={css({ marginTop: '-10px' })}>
                {/* <CalendarForm
                  label=""
                  dateFormat="YYYY"
                  date={selectedYear}
                  changeDate={(year: any) => {
                    setSelectedYear(year);
                  }}
                /> */}
                <YearPicker
                  selectedYear={selectedYear}
                  onChange={(year: any) => {
                    sessionStorage.setItem('selectedYearHolidayMaster', String(year));
                    setSelectedYear(year);
                  }}
                />
              </FlexBoxItem>
            </Col>
          </Row>
          <Row>
            <Col md={1} xs={2}>
              <span style={style}>組織名</span>
            </Col>
            <Col md={3} xs={6}>
              <FlexBoxItem customStyle={css({ marginTop: '-10px' })}>
                <SelectForm
                  label=""
                  name="orgCode"
                  value={String(orgCode)}
                  setValue={(v) => {
                    sessionStorage.setItem('selectedOrgCodeHolidayMaster', v);
                    setorgCode(v);
                  }}
                  options={orgTreesOptions}
                  required={false}
                  width="900px"
                  widthLabel="100px"
                  marginRight="30px"
                  />
              </FlexBoxItem>
            </Col>
          </Row>
          <Row>
          {detailRole.editable === 1 && (
            <FlexBoxItem margin='30px 0' customStyle={css({ display: 'flex' })}>
              <Link to="/holiday/import">
                <IconLabelButton onClick={() => {}} iconType="addCircle" text="日本の祝日を取り込む" padding={'6px 0'} />
              </Link>
              <Link to="/holiday/create">
                <IconLabelButton onClick={() => {}} iconType="addCircle" text="独自の祝日を作成" padding={'6px 20px'} />
              </Link>
            </FlexBoxItem>
          )}
          </Row>
          </FormPadding>
      
      <div>
      <div
        css={css`
      table th{
        text-align: left
      }
      table th:nth-of-type(1) {
        width: 100px;
      }
      table th:nth-of-type(2) {
        width: 300px;
      }
      `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <DataTable className="table-no-border-left-right" columns={columns} data={holidayList} isGlobalFilter={true} searchRight={false} />
        </div>
      </div>
      <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={deleteUpdateHoliday}
            title="祝日を削除"
            content="祝日を削除します。よろしいですか？"
            submitText="削除"
            typeSubmit={'reject'}
      />
      {/* <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteUpdateHoliday}
        actionType={ActionType.DELETE}
      /> */}
    </div>
    </FormContents>   
    </BlockUI> 
  </SidebarTemplate>
  );
};

export default HolidayMasterPage;
