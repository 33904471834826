/** @jsx jsx */
import React from 'react';
import BodyText from 'components/atoms/BodyText';
import { css, jsx } from '@emotion/core';
import useAttendTimeRecorder from './hooks';

const styles = {

  header: css({
    margin: '29px 0 32px 0',
    textAlign: 'center',
  }),
  timeTitle: css({
    fontSize: '64px',
  }),
};
const TimeRecorderTimeHeader: React.FC = () => {
  const {
    currentDay,
    timeNow,
  } = useAttendTimeRecorder();

  return (
    <React.Fragment>
      <div css={styles.header}>
        <BodyText size="xxl">{currentDay}</BodyText>
        <br />
        <BodyText customStyle={styles.timeTitle}>{timeNow}</BodyText>
      </div>
    </React.Fragment>
  );
};

export default TimeRecorderTimeHeader;
