import ApiClient from './ApiClient';

export const getListMediaMaster = async (orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { orgCode };

  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, params);
  return response.data;
};

export const getMediaMaster = async (media: string) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { media };
  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, params);
  return response.data;
};

export const deleteMediaMaster = async (paymentMediaMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    paymentMediaMstCode,
  };

  const response = await ApiClient.delete(`/v1/paymentMediaRelation/delete/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateMediaMaster = async (isCreate: boolean, isConfirm: boolean, mediaMaster: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 1 : 0,
    isConfirmed: isConfirm ? 1 : 0,
  };
  const response = await ApiClient.post(`/v1/paymentMediaMstRelation/${companyCode}`, params, mediaMaster);
  return response.data;
};

export const getMediaMasterList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, {});
  return response.data;
};

export default getListMediaMaster;
