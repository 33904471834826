export const hokuyoCompanyCode = "HPo6wkyuze";

export const functionCode = {
  staffs: "002",
  masterRoleSetting: "005",
  organizations: "001",
  executive: "003",
  holiday: "004",
  vacationMaster: "101",
  masterPaidHolidayPattern: "102",
  masterTerm: "103",
  masterSpecialSalary: "115",
  masterWorkingDays: "104",
  masterWorkingHours: "105",
  masterEmploymentForm: "106",
  masterAttendSetting: "107",
  masterOrganizeSetting: "107_1",
  masterShiftPattern: "108",
  masterSettingAgreement36: "109",
  masterShopFaceRegistration: "110",
  masterICCard: "111",
  masterTimeOutputLayout: "112",
  stampIpSetting: "114",
  masterShopSpecify: "113",
  attendAllowance: "116",
  agreement36: "201",
  monthlyAgreement36: "201_1",
  yearlyAgreement36: "201_2",
  attendApplicationApproval: "202",
  attendStampList: "203",
  attendStampAggregation: "203_2",
  hopeShift: "204",
  shiftView: "205",
  attendApplicationList: "206",
  attendError: "207",
  attendHolidayManagement: "208",
  attendAchievementLock: "219",
  attendHeadquartersFinal: "209",
  timeRecalculation: "210",
  attendAchievementOutput: "211",
  attendCSVOutput: "212",
  stampHistory: "213",
  GpsStampHistory: "214",
  dailyShift: "215",
  monthlyShift: "216",
  recorder: "217",
  kaoiroHistory: "218",
};

/** 共通定数  */
export const Literal = {
  /** マスク */
  MASK: "****",
};

/** 区分値  */
export const Values = {
  /** 機能タイプ */
  FunctionType: {
    Master: { label: "マスタ", value: 1 },
    Attend: { label: "勤怠", value: 2 },
    Sales: { label: "売上", value: 3 },
    Dashboard: { label: "ダッシュボード", value: 4 },
  },

  /**
   * 店舗状態
   * ※ リストの並び順で定義
   */
  OrgStatus: {
    Open: { label: "開店", value: "0" },
    Scheduled: { label: "開店予定", value: "2" },
    Closed: { label: "閉店", value: "1" },
  },

  /**
   * マネージャータイプ
   * ※ リストの並び順で定義
   */
  ManagerType: {
    Manager: { label: "マネージャー", value: "0" },
    SeniorManager: { label: "シニアマネージャー", value: "1" },
  },

  /**
   * 労働形態
   */
  WorkingForm: {
    FullTime: { label: "フルタイム", value: 0 },
    PartTime: { label: "パートタイム", value: 1 },
  },

  /**
   * ユーザー登録状況
   */
  UserRegistrationStatus: {
    Unregistered: { label: "未登録", value: "0" },
    Pending: { label: "認証待ち", value: "1" },
    Complete: { label: "完了", value: "2" },
  },

  /**
   * 時間 10進表示時の端数処理
   */
  roundMethodOfThirdDecimalPlace: {
    RoundDown: { label: "切り捨て", value: "0" },
    RoundUp: { label: "切り上げ", value: "1" },
    RoundHalfUp: { label: "四捨五入", value: "2" },
  },

  /**
   * 税込区分
   */
  TaxExclude: {
    Excluded: { label: "税抜", value: "1" },
    Included: { label: "税込", value: "0" },
  },
};
