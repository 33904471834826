/** @jsx jsx */

import { useContext, useEffect, useState } from "react";
import "./MobileSidebar.css";
import Icon, { IconType } from "components/atoms/Icon";
import { useSelector } from "react-redux";
import { MenuListType, defaultFunction, menuList } from "components/templates/menuDefault";
import { Store } from "modules/store";
import React from "react";
import { css, jsx } from "@emotion/core";
import { toast } from "react-toastify";
import { MenuItem } from "components/molecules/SideMenu/SideMenu";
import { useHistory } from "react-router-dom";
import { useStaffSetting } from "components/organismos/Appbar/hooks";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import PhotoFrame from "components/molecules/PhotoFrame";
import BodyText from "components/atoms/BodyText";
import useLoginUser from "hooks/useLoginUser";
import { PrivateContext } from "PrivateRoute";

type MenuItemProps = {
  value?: number;
  id: string;
  secondValue?: number;
  display: string;
  icon: IconType | undefined;
  onClick?: () => void;
};

export const MobileSidebar = ({
  isMenuOpen,
  setMenuOpen,
}: {
  isMenuOpen: boolean | null;
  setMenuOpen: (open: boolean) => void;
}) => {
  let functionCodeList = useSelector((state: Store) => state.ui.general.loginUser.functionCodeList)?.concat(
    defaultFunction,
  );
  const history = useHistory();
  const { groupUrl } = useContext(PrivateContext);
  const logoFlag = process.env.REACT_APP_LOGO_FLAG;
  const { staffName } = useLoginUser();
  const isLogin = !!staffName;
  const showGpsStamp = sessionStorage.getItem("loginUser.useGpsStamp") === "true" ? true : false;

  // Inside Flag Recorder
  const showInsideFaceStamp =
    sessionStorage.getItem("loginUser.useInsideLoginFaceRecorderStamp") === "true" ? true : false;
  const showInsideIdStamp = sessionStorage.getItem("loginUser.useInsideLoginBarcodeStamp") === "true" ? true : false;
  const filterMenuByFunctionList = (list: Array<MenuListType>, functionCodeList: Array<string>) => {
    let menuFilter = list
      .map((menu) => {
        menu.child = menu.child.filter((item) => {
          return functionCodeList?.includes(item.functionCode);
        });
        return menu;
      })
      .filter((i) => i.child.length > 0);
    return menuFilter;
  };

  let menuListFilter = filterMenuByFunctionList(JSON.parse(JSON.stringify(menuList)), functionCodeList);

  const [menuActive, setMenuActive] = useState(menuListFilter);

  const [menuTop, setMenuTop] = useState<MenuItemProps[]>([]);

  const historyPush = (path: string) => {
    toast.dismiss();

    history.push(path);

    sessionStorage.setItem("attendStampList.attendFlag", String(0));
    sessionStorage.setItem("isGoBack", String(1));
    sessionStorage.setItem("attendError.orgCode", "");
    sessionStorage.removeItem("attendError.staffCode");
  };

  const photoPathGlobal = useSelector((state: Store) => state.ui.appBar.photoPath);
  const { logoutAction } = useStaffSetting();

  useEffect(() => {
    if (!staffName) return;

    let menu: MenuItemProps[] = [];

    if (isLogin && (showGpsStamp || showInsideFaceStamp || showInsideIdStamp)) {
      menu.push({
        display: "打刻",
        id: "recorder",
        icon: "clock",
        onClick: () => history.push(isLogin ? "/recorder" : "/recorder_1"),
      });
    }

    const activeHome = menuActive.find((menu) => menu.label === "ホーム");
    const activeHomeIndex = menuActive.findIndex((menu) => menu.label === "ホーム");

    if (activeHome) {
      const activeHomeSecondIndex = activeHome.child.findIndex((menu) => menu.label === "ホーム");

      if (activeHome) {
        menu.push({
          value: activeHomeIndex,
          secondValue: activeHomeSecondIndex,
          id: activeHome.child[activeHomeSecondIndex].value,
          display: activeHome.label,
          icon: activeHome.icon,
        });
      }
    }

    const activeAttendStaffStamp = menuActive.find((menu) => menu.label === "スタッフ勤怠");
    const activeAttendStaffStampIndex = menuActive.findIndex((menu) => menu.label === "スタッフ勤怠");

    if (activeAttendStaffStamp) {
      const activeAttendStampList = activeAttendStaffStamp.child.find((menu) => menu.value === "attendStampList");
      if (activeAttendStampList) {
        const activeAttendStampListSecondIndex = activeAttendStaffStamp.child.findIndex(
          (menu) => menu.value === "attendStampList",
        );

        menu = menu.concat([
          {
            value: activeAttendStaffStampIndex,
            id: activeAttendStampList.value,
            secondValue: activeAttendStampListSecondIndex,
            display: activeAttendStampList.label,
            icon: "factCheck",
          },
          {
            display: "打刻データ集計",
            icon: "analytics",
            id: "attendStampAggregation",
            onClick: () => historyPush("/attendStampAggregation"),
          },
        ]);
      }
      const activeHopeShift = activeAttendStaffStamp.child.find((menu) => menu.value === "hopeShift");

      if (activeHopeShift) {
        const activeHopeShiftSecondIndex = activeAttendStaffStamp.child.findIndex(
          (menu) => menu.value === "hopeShift",
        );

        menu.push({
          value: activeAttendStaffStampIndex,
          secondValue: activeHopeShiftSecondIndex,
          id: activeHopeShift.value,
          display: activeHopeShift.label,
          icon: "business",
        });
      }
      const activeShiftView = activeAttendStaffStamp.child.find((menu) => menu.value === "shiftView");

      if (activeShiftView) {
        const activeShiftViewSecondIndex = activeAttendStaffStamp.child.findIndex(
          (menu) => menu.value === "shiftView",
        );

        menu.push({
          value: activeAttendStaffStampIndex,
          secondValue: activeShiftViewSecondIndex,
          id: activeShiftView.value,
          display: activeShiftView.label,
          icon: "calendar",
        });
      }
    }

    const activeAttendManagement = menuActive.find((menu) => menu.label === "勤怠管理");
    const activeAttendManagementIndex = menuActive.findIndex((menu) => menu.label === "勤怠管理");

    if (activeAttendManagement) {
      const activeAttendError = activeAttendManagement.child.find((menu) => menu.value === "attendError");
      if (activeAttendError) {
        const activeAttendErrorSecondIndex = activeAttendManagement.child.findIndex(
          (menu) => menu.value === "attendError",
        );
        menu.push({
          value: activeAttendManagementIndex,
          secondValue: activeAttendErrorSecondIndex,
          id: activeAttendError.value,
          display: activeAttendError.label,
          icon: "warning",
        });
      }
    }

    menu = menu.concat([
      {
        display: "スタッフ設定",
        id: "personalSetting",
        icon: "settings",
        onClick: () => historyPush("/personalSetting"),
      },
      {
        display: "ヘルプ",
        id: "help",
        icon: "help",
        onClick: () => {
          window.open(
            logoFlag === "0" ? "https://help2.am.winboard.biz/" : "https://help.hinata.cydas.com/",
            "_blank",
          );
        },
      },
      {
        display: "ログアウト",
        id: "logout",
        icon: "logout",
        onClick: logoutAction,
      },
    ]);

    setMenuTop(menu);
  }, [staffName]);

  const checkActiveMenu = (menu: MenuItemProps[], group: string) => {
    return menu.findIndex((menu) => {
      return menu.id === group;
    });
  };

  return (
    <React.Fragment>
      {isMenuOpen && (
        <div
          onClick={() => setMenuOpen(false)}
          css={css({
            height: "calc(100dvh - 65px)",
            position: "fixed",
            top: 65,
            right: 0,
            width: "100vw",
            zIndex: 100,
          })}
        />
      )}
      <div
        css={css({
          height: "calc(100dvh - 65px)",
          position: "fixed",
          top: 65,
          right: -232,
          width: "232px",
          zIndex: 100,
          overflow: "auto",
          backgroundColor: "#F7FCFE",
        })}
        className={isMenuOpen === null ? "" : isMenuOpen ? "slideIn" : "slideOut"}
      >
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            padding: "12px 6px",
            borderBottom: "1px solid #ddd",
          })}
        >
          <FlexBox direction="column" alignItems="center">
            <FlexBoxItem>
              <PhotoFrame src={photoPathGlobal} size="48px" isCircle={true} onErrorIconColor="black" />
            </FlexBoxItem>
            <FlexBoxItem margin="0.5rem 0 0">
              <BodyText size="md">{staffName}</BodyText>
            </FlexBoxItem>
          </FlexBox>
        </div>
        <div
          css={css({
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          })}
        >
          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              width: "100%",
            })}
          >
            {menuTop.map((item, index) => (
              <MenuItem
                key={index}
                css={css({
                  width: "220px",
                  maxWidth: "100%",
                  backgroundColor: checkActiveMenu(menuTop, groupUrl) === index ? "#87CEFA" : "",
                })}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  } else {
                    if (item.value !== undefined && item.secondValue !== undefined) {
                      let targetURI: string;
                      if (item.value < menuActive.length) {
                        targetURI = menuActive[item.value]?.child[item.secondValue].value;
                      } else {
                        targetURI = menuListFilter[0]?.child[0].value;
                      }

                      historyPush(`/${targetURI}`);
                    }
                  }
                }}
              >
                <div
                  css={css({
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                  })}
                >
                  {item.icon && <Icon type={item.icon} size="24px" color="#666666" />}
                  {item.display}
                </div>
              </MenuItem>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
