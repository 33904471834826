/* eslint-disable max-len */
/* eslint-disable camelcase */
import moment from "moment";
import { OrganizationDataStaff, OrganizationDataStaffDomain } from "./master/general/organizationDataStaff";
import { EmploymentDataStaff, EmploymentDataStaffDomain } from "./master/general/employmentDataStaff";
import { SalaryDataStaffDomain, SalaryDataStaff } from "./master/general/salaryDataStaff";
import {
  WeekShiftPatternDataStaff,
  WeekShiftPatternDataStaffDomain,
} from "./master/general/weekShiftPatternDataStaff";
import { ShiftPattern } from "./master/attend/shiftPattern";
import { ManagerDataStaff, ManagerDataStaffDomain } from "./master/general/managerDataStaff";
import { Business } from "./master/attend/business";
import { object } from "yup";

export type StaffBusiness = {
  staff_business_id: string;
  staff_code: string;
  business_id: string;
  company_code: string;
  create_user: string;
  create_date: Date;
  update_user: string;
  update_date: Date;
};

export type StaffHoliday = {
  staffHolidayId?: string;
  companyCode?: string;
  staffCode?: string;
  holidayId?: string;
  applyStartDate?: Date;
  applyEndDate?: Date;
  yearUseDays?: number;
  holidayType?: number;
  holidayTypeStr?: string;
  holidayCode?: string;
  holidayName?: string;
};

export type StaffHolidayDataType = {
  staffHolidayId: string;
  holidayId: string;
  applyStartDate: Date;
  applyEndDate: Date | string;
  yearUseDays: number;
};

export type ConcurrentType = {
  staffConcurrentId: string;
  staffCode: string;
  staffName: string;
  staffOrgCode: string;
  staffOrgName: string;
  orgCode: string;
  orgName: string;
  executiveId?: any;
  executiveCode?: any;
  executiveName?: any;
  applyStartDate?: Date;
  applyEndDate?: Date | null;
};

export type CardListType = {
  cardId: string;
};

export type Staff = {
  id: {
    companyCode?: string;
    staffCode: string;
  };
  staffCode: string;
  staffName: string;
  staffNameKana: string;
  mail: string;
  barcodeId: string;
  hireDate?: Date;
  paidHolidayStartDate?: Date;
  retirementDate?: Date;
  birthDate?: Date;
  hqRole: string;
  shopRole: string;
  personalRole: string;
  orgCode: string;
  orgName: string;
  executiveId: string;
  executiveCode: string;
  executiveName: string;
  employmentId: string;
  employmentCode: string;
  employmentName: string;
  hqRoleName: string;
  shopRoleName: string;
  personalRoleName: string;
  stateStatus: number;
  stateStatusDisp: string;
  role: string;

  phoneNumber?: string;
  trialTerm?: number;
  gender?: number;
  nameplate?: string;
  nationality?: number;
  alienRegistrationCardExpirationDate?: Date;
  statusResidence?: number;
  createUser?: string;
  updateUser?: string;
  dispHireDate?: string;
  dispPaidHolidayStartDate?: string;
  dispRetirementDate?: string;
  dispBirthDate?: string;
  dispOrgApplyDate?: string;
  dispEmpApplyDate?: string;
  dispAlienRegistrationCardExpirationDate?: string;
  editStaffCode?: string;
  orgApplyDate?: Date;
  empApplyDate?: Date;
  empApplyEndDate?: Date;
  workingForm?: number;
  stateFlg?: boolean;
  useRemote: boolean;
  // newEmployment:
  // adoptionDate: Date,
  staffBusinessList: Array<StaffBusiness>;
  staffHolidayData: StaffHolidayDataType;
  staffHolidayList: Array<StaffHoliday>;
  organizationDataList: Array<OrganizationDataStaff>;
  employmentDataList: Array<EmploymentDataStaff>;
  salaryList: Array<SalaryDataStaff>;
  weekShiftPatternList: Array<WeekShiftPatternDataStaff>;
  roundTimeList: Array<string>;
  shiftPatternList: Array<ShiftPattern>;
  managerList: Array<ManagerDataStaff>;
  concurrentList: Array<ConcurrentType>;
  empBusinessList: Array<Business>;
  icCardListData: Array<CardListType>;

  staffHolidayId: string;
  holidayId: string;
  applyStartDate: Date;
  applyEndDate: Date | undefined;
  yearUseDays: number;
  useEmploymentDeemedWorkingHours: boolean;
  deemedWorkingHours: number;
  timeHolidayHourOfDay: number;
  useRoleList: boolean;
  timeHolidayMaximumHour: number;
  deemedMorningHalfHours: number;
  deemedAfternoonHalfHours: number;
};

export type StoreState = "open" | "close";

export default class StaffDomain {
  private rawData: Staff;

  private selectedSalary: SalaryDataStaffDomain;

  private selectedWeekShiftPattern: WeekShiftPatternDataStaffDomain;

  constructor(rawData: Staff) {
    this.rawData = {
      ...rawData,
      id: {
        staffCode: rawData.id ? rawData.id.staffCode : rawData.staffCode,
      },
    };
    if (this.rawData.salaryList && this.rawData.salaryList.length > 0) {
      this.selectedSalary = new SalaryDataStaffDomain({
        ...this.rawData.salaryList[0],
        salaryTermList:
          this.rawData.salaryList[0].salaryTermList?.map(({ id, addHourlySalary }) => ({
            id,
            addHourlySalary,
          })) ?? [],
        applyStartDate: this.rawData.salaryList[0].applyStartDate ?? rawData.hireDate,
      });
    } else {
      this.selectedSalary = SalaryDataStaffDomain.generateInitial(rawData.hireDate);
    }

    // Set default selected week shift pattern
    this.selectedWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();

    // DBから取得したロール情報は3つのサイトごとに分割されているためここで1つに統合する
    if (rawData.hqRole === "HQ000000000000000000000000000003") {
      this.rawData.role = "HQ000000000000000000000000000003";
    } else if (rawData.hqRole === "HQ000000000000000000000000000001") {
      this.rawData.role = "HQ000000000000000000000000000001";
    } else if (rawData.shopRole === "SHOP0000000000000000000000000003") {
      this.rawData.role = "SHOP0000000000000000000000000003";
    } else if (rawData.shopRole === "SHOP0000000000000000000000000001") {
      this.rawData.role = "SHOP0000000000000000000000000001";
    } else {
      this.rawData.role = "PERSONAL000000000000000000000001";
    }
  }

  static generateInitial(): StaffDomain {
    return new StaffDomain({
      id: {
        staffCode: "",
      },
      staffCode: "",
      staffName: "",
      staffNameKana: "",
      mail: "",
      barcodeId: "",
      hireDate: undefined,
      paidHolidayStartDate: undefined,
      retirementDate: undefined,
      birthDate: undefined,
      hqRole: "",
      shopRole: "",
      personalRole: sessionStorage.getItem("personalRole") || sessionStorage.getItem("loginUser.personalRole")!,
      orgCode: "",
      orgName: "",
      executiveId: "",
      executiveCode: "",
      executiveName: "",
      employmentId: "",
      employmentCode: "",
      employmentName: "",
      hqRoleName: "",
      shopRoleName: "",
      personalRoleName: "",
      stateStatus: 0,
      stateStatusDisp: "",
      role: "PERSONAL000000000000000000000001",
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      statusResidence: 1,
      trialTerm: 0,
      nationality: 1,
      staffBusinessList: [],
      organizationDataList: [],
      staffHolidayList: [],
      staffHolidayData: {
        staffHolidayId: "",
        holidayId: "",
        applyStartDate: new Date(),
        applyEndDate: "",
        yearUseDays: 0,
      },
      employmentDataList: [],
      salaryList: [],
      weekShiftPatternList: [],
      roundTimeList: [],
      shiftPatternList: [],
      managerList: [],
      concurrentList: [],
      empBusinessList: [],
      useRemote: false,
      staffHolidayId: "",
      holidayId: "",
      applyStartDate: new Date(),
      applyEndDate: undefined,
      yearUseDays: 0,
      useEmploymentDeemedWorkingHours: false,
      deemedWorkingHours: 0,
      timeHolidayHourOfDay: 0,
      timeHolidayMaximumHour: 0,
      deemedMorningHalfHours: 0,
      deemedAfternoonHalfHours: 0,
      icCardListData: [],
      useRoleList: false,
    });
  }

  getRawData(): Staff {
    return this.rawData;
  }

  getStaffInfoPostData(isEditMode?: boolean): any {
    const business: Array<any> = this.staffBusinessList.map((item) => ({
      businessId: item.business_id,
      companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
      staffCode: this.rawData.id.staffCode,
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
    }));

    // DBから取得したロール情報は3つのサイトごとに分割されているためここで1つに統合する
    if (this.role === "HQ000000000000000000000000000003") {
      this.hqRole = "HQ000000000000000000000000000003";
      this.shopRole = "SHOP0000000000000000000000000003";
    } else if (this.role === "HQ000000000000000000000000000001") {
      this.hqRole = "HQ000000000000000000000000000001";
      this.shopRole = "SHOP0000000000000000000000000001";
    } else if (this.role === "SHOP0000000000000000000000000003") {
      this.hqRole = "";
      this.shopRole = "SHOP0000000000000000000000000003";
    } else if (this.role === "SHOP0000000000000000000000000001") {
      this.hqRole = "";
      this.shopRole = "SHOP0000000000000000000000000001";
    } else {
      this.hqRole = "";
      this.shopRole = "";
    }

    const postRawData = {
      staffName: this.staffName,
      staffNameKana: this.staffNameKana,
      id: { staffCode: this.staffCode },
      beforeStaffCode: isEditMode ? (this.editStaffCode ? this.editStaffCode : null) : "",
      dispHireDate: this.dispHireDate || "",
      trialTerm: this.trialTerm,
      dispPaidHolidayStartDate: this.dispPaidHolidayStartDate || "",
      dispRetirementDate: this.dispRetirementDate || "",
      // retirementDate: this.rawData.retirementDate ? moment(this.rawData.retirementDate).format('YYYY/MM/DD') : '9999/12/31',
      mail: this.mail,
      // personalRole: this.personalRole,
      personalRole: this.personalRole,
      shopRole: this.shopRole,
      hqRole: this.hqRole,
      gender: this.gender || "",
      dispBirthDate: this.dispBirthDate || "",
      nationality: this.nationality,
      dispAlienRegistrationCardExpirationDate: this.dispAlienRegistrationCardExpirationDate || "",
      statusResidence: this.statusResidence,
      barcodeId: this.barcodeId,
      dispOrgApplyDate: this.dispOrgApplyDate,
      orgCode: this.orgCode,
      executiveId: this.executiveId,
      employmentId: this.employmentId,
      createUser: this.createUser,
      updateUser: this.updateUser,
      useRemote: this.useRemote,
      daysOfWeek: "",
      business: business.length === 0 ? "" : business,
      dispEmpApplyDate: this.dispEmpApplyDate,
      managerList: this.managerList,
      role: this.role,
      useEmploymentDeemedWorkingHours: this.useEmploymentDeemedWorkingHours || false,
      deemedWorkingHours: this.deemedWorkingHours || 0,
      timeHolidayHourOfDay: this.timeHolidayHourOfDay || 0,
      timeHolidayMaximumHour: this.timeHolidayMaximumHour || 0,
      deemedMorningHalfHours: this.deemedMorningHalfHours || 0,
      deemedAfternoonHalfHours: this.deemedAfternoonHalfHours || 0,
      icCardListData: this.icCardListData,
      useRoleList: this.useRoleList,
    };

    let object = {
      ...postRawData,
      ...this.getSubmitSalary(),
      ...this.getWorkPatternPostData(),
      concurrentList: [...this.formatConcurrentList(this.concurrentList)],
    };

    if (this.applyStartDate && this.holidayId) {
      object = {
        ...object,
        ...this.getLongTermCare(),
        ...this.getLongTermCareAndNurse(),
      };
    }

    return object;
  }

  private getLongTermCare(): any {
    return {
      staffHolidayId: this.staffHolidayId || "",
      holidayId: this.holidayId || "",
      applyStartDate: this.applyStartDate || "",
      applyEndDate: this.applyEndDate || "",
      yearUseDays: this.yearUseDays,
    };
  }

  private formatConcurrentList(concurrentList: Array<ConcurrentType>): any {
    return concurrentList.map((item) => {
      return {
        staffConcurrentId: item.staffConcurrentId,
        staffCode: item.staffCode,
        staffName: item.staffName,
        staffOrgCode: item.staffOrgCode,
        staffOrgName: item.staffOrgName,
        orgCode: item.orgCode,
        orgName: item.orgName,
        executiveId: item.executiveId,
        executiveCode: item.executiveCode,
        executiveName: item.executiveName,
        applyStartDate: moment(item.applyStartDate).format("YYYY/MM/DD") || "",
        applyEndDate: item.applyEndDate ? moment(item.applyEndDate).format("YYYY/MM/DD") : "9999/12/31",
      };
    });
  }

  private getLongTermCareAndNurse(): any {
    return {
      staffHolidayData: {
        staffHolidayId: this.staffHolidayId || "",
        holidayId: this.holidayId || "",
        applyStartDate: moment(this.applyStartDate).format("YYYY/MM/DD") || "",
        applyEndDate: this.applyEndDate ? moment(this.applyEndDate).format("YYYY/MM/DD") : "",
        yearUseDays: this.yearUseDays,
      },
    };
  }

  private getWorkPatternPostData(): any {
    const attendShiftPatternDetails: Array<any> = this.selectedWeekShiftPattern.attendShiftPatternDetails.map(
      (item) => ({
        shiftPatternDetailId: item.shiftPatternDetailId,
        shiftPatternId: item.shiftPatternId,
        startHourForm: item.startTime.substr(0, 2),
        startTimeForm: item.startTime.substr(3, 2),
        endHourForm: item.endTime.substr(0, 2),
        endTimeForm: item.endTime.substr(3, 2),
        createUser: item.createUser || sessionStorage.getItem("loginUser.staffName"),
        updateUser: item.updateUser || sessionStorage.getItem("loginUser.staffName"),
        isRemote: this.useRemote ? item.isRemote : false,
      }),
    );

    const shiftPattern = {
      shiftPatternId: this.selectWeekShiftPattern.shiftPatternId,
      shiftPatternCode: this.selectWeekShiftPattern.shiftPatternCode,
      shiftPatternName: this.selectWeekShiftPattern.shiftPatternName,
      shortName: this.selectWeekShiftPattern.shortName,
      holidayId: this.selectWeekShiftPattern.holidayId,
      attendType: this.selectWeekShiftPattern.attendType,
      attendShiftPatternDetails: this.selectedWeekShiftPattern.attendShiftPatternDetails.map((item) => ({
        startTime: item.startTime.substr(0, 5),
        endTime: item.endTime.substr(0, 5),
        isStartTimeNextDay: item.isStartTimeNextDay,
        isEndTimeNextDay: item.isEndTimeNextDay,
        businessId: item.businessId,
        isRemote: this.useRemote ? item.isRemote : false,
      })),
    };
    const registerDaysOfWeek = this.selectWeekShiftPattern.selectedDayOfWeeks
      .concat(this.selectWeekShiftPattern.dayOfWeek)
      .filter((item) => item !== undefined);
    return {
      attendShiftPatternDetails,
      shiftPattern,
      staffWeekShiftPatternId: this.selectWeekShiftPattern.staffWeekShiftPatternId,
      daysOfWeek: registerDaysOfWeek.length === 0 ? "" : registerDaysOfWeek,
    };
  }

  private getSubmitSalary(): any {
    const salarySupportData = this.selectSalary.salarySupportDataList.map((item) => ({
      id: { orgCode: item.orgCode },
      addHourlySalary: item.addHourlySalary,
      supportTransportation: item.supportTransportation,
    }));

    const salaryTermsData = this.selectSalary.salaryTermList.map((item) => ({
      id: { termId: item.id.termId },
      addHourlySalary: item.addHourlySalary,
    }));

    const salaryData = [
      {
        applyStartDateStr: this.selectedSalary.applyStartDateStr,
        staffSalaryId: this.selectedSalary.staffSalaryId,
        beforeApplyStartDateStr: this.selectedSalary.applyStartDateStr,
        salaryPayment: this.selectedSalary.salaryPayment,
        monthlyHourlySalary: this.selectedSalary.salaryPayment === 0 ? "" : this.selectedSalary.monthlyHourlySalary,
        hourlySalary: this.selectedSalary.salaryPayment === 0 ? "" : this.selectedSalary.hourlySalary,
        monthlySalary: this.selectedSalary.salaryPayment === 0 ? "" : this.selectedSalary.monthlySalary,
        dailySalary: this.selectedSalary.salaryPayment === 0 ? "" : this.selectedSalary.dailySalary,
        dailyHourlySalary: this.selectedSalary.salaryPayment === 0 ? "" : this.selectedSalary.dailyHourlySalary,
        transportationFlag: this.selectedSalary.transportationFlag,
        dailyTransportation:
          this.selectedSalary.transportationFlag === 0 ? "" : this.selectedSalary.dailyTransportation,
        monthlyTransportation:
          this.selectedSalary.transportationFlag === 0 ? "" : this.selectedSalary.monthlyTransportation,
        limitTransportation:
          this.selectedSalary.transportationFlag === 0 ? "" : this.selectedSalary.limitTransportation,
        trainingTermDay: "",
        trainingTermDivision: 1,
        trainingTermHour: "",
        trainingHourlyRate: "",
        trainingHourlyDivision: 1,
        hourlyTrainingFlag: 0,
        trainingHourlyDifference: this.selectedSalary.trainingHourlyDifference,
        salarySupportList: salarySupportData,
        salaryTermList: salaryTermsData,
      },
    ];
    return { salaryList: salaryData };
  }

  private getPostData(): Staff {
    // nullを除去する
    const postRawData: Staff = JSON.parse(JSON.stringify(this.rawData, (key, value) => (value === null ? "" : value)));

    return {
      ...postRawData,
    };
  }

  get staffCode(): string {
    return this.rawData.id.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.id.staffCode = staffCode;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get staffNameKana(): string {
    return this.rawData.staffNameKana;
  }

  set staffNameKana(staffNameKana: string) {
    this.rawData.staffNameKana = staffNameKana;
  }

  get mail(): string {
    return this.rawData.mail;
  }

  set mail(mail: string) {
    this.rawData.mail = mail;
  }

  get barcodeId(): string {
    return this.rawData.barcodeId;
  }

  set barcodeId(barcodeId: string) {
    this.rawData.barcodeId = barcodeId;
  }

  get hireDate(): Date | undefined {
    if (this.rawData.hireDate) {
      return moment(this.rawData.hireDate).toDate();
    }
    return undefined;
  }

  get dispHireDate(): string | undefined {
    if (this.rawData.hireDate) {
      return moment(this.rawData.hireDate).format("YYYY年MM月DD日");
    }
    return undefined;
  }

  set hireDate(hireDate: Date | undefined) {
    this.rawData.hireDate = hireDate;
  }

  get paidHolidayStartDate(): Date | undefined {
    if (this.rawData.paidHolidayStartDate) {
      return moment(this.rawData.paidHolidayStartDate).toDate();
    }
    return undefined;
  }

  get dispPaidHolidayStartDate(): string | undefined {
    if (this.rawData.paidHolidayStartDate) {
      return moment(this.rawData.paidHolidayStartDate).format("YYYY年MM月DD日");
    }
    return undefined;
  }

  set paidHolidayStartDate(paidHolidayStartDate: Date | undefined) {
    if (moment(paidHolidayStartDate).format("YYYY/MM/DD") === "9999/12/31") {
      this.rawData.paidHolidayStartDate = undefined;
    } else {
      this.rawData.paidHolidayStartDate = paidHolidayStartDate;
    }
  }

  get retirementDate(): Date | undefined {
    if (this.rawData.retirementDate) {
      return moment(this.rawData.retirementDate).toDate();
    }
    return undefined;
  }

  get dispRetirementDate(): string | undefined {
    if (this.rawData.retirementDate) {
      return moment(this.rawData.retirementDate).format("YYYY年MM月DD日");
    }
    return undefined;
  }

  set retirementDate(retirementDate: Date | undefined) {
    if (moment(retirementDate).format("YYYY/MM/DD") === "9999/12/31") {
      this.rawData.retirementDate = undefined;
    } else {
      this.rawData.retirementDate = retirementDate;
    }
  }

  get birthDate(): Date | undefined {
    if (this.rawData.birthDate) {
      return moment(this.rawData.birthDate).toDate();
    }
    return undefined;
  }

  get dispBirthDate(): string | undefined {
    if (this.rawData.birthDate) {
      return moment(this.rawData.birthDate).format("YYYY年MM月DD日");
    }
    return undefined;
  }

  set birthDate(birthDate: Date | undefined) {
    if (moment(birthDate).format("YYYY/MM/DD") === "9999/12/31") {
      this.rawData.birthDate = undefined;
    } else {
      this.rawData.birthDate = birthDate;
    }
  }

  get hqRole(): string {
    if (!this.rawData.hqRole) {
      return "";
    }
    return this.rawData.hqRole;
  }

  set hqRole(hqRole: string) {
    this.rawData.hqRole = hqRole;
  }

  get shopRole(): string {
    if (!this.rawData.shopRole) {
      return "";
    }
    return this.rawData.shopRole;
  }

  set shopRole(shopRole: string) {
    this.rawData.shopRole = shopRole;
  }

  get personalRole(): string {
    if (!this.rawData.personalRole) {
      return "";
    }
    return this.rawData.personalRole;
  }

  set personalRole(personalRole: string) {
    this.rawData.personalRole = personalRole;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get executiveId(): string {
    if (this.rawData.executiveId) {
      return this.rawData.executiveId;
    }
    return "";
  }

  set executiveId(executiveId: string) {
    this.rawData.executiveId = executiveId;
  }

  get executiveCode(): string {
    return this.rawData.executiveCode;
  }

  set executiveCode(executiveCode: string) {
    this.rawData.executiveCode = executiveCode;
  }

  get executiveName(): string {
    return this.rawData.executiveName;
  }

  set executiveName(executiveName: string) {
    this.rawData.executiveName = executiveName;
  }

  get staffHolidayId(): string {
    return this.rawData.staffHolidayId || "";
  }

  set staffHolidayId(staffHolidayId: string) {
    this.rawData.staffHolidayId = staffHolidayId;
  }

  get holidayId(): string {
    return this.rawData.holidayId || "";
  }

  set holidayId(holidayId: string) {
    this.rawData.holidayId = holidayId;
  }

  get applyStartDate(): Date {
    return this.rawData.applyStartDate || undefined;
  }

  set applyStartDate(applyStartDate: Date) {
    this.rawData.applyStartDate = applyStartDate;
  }

  get applyEndDate(): Date | undefined {
    return this.rawData.applyEndDate || undefined;
  }

  set applyEndDate(applyEndDate: Date | undefined) {
    this.rawData.applyEndDate = applyEndDate;
  }

  get employmentId(): string {
    return this.rawData.employmentId;
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get yearUseDays(): number {
    return this.rawData.yearUseDays || 0;
  }

  set yearUseDays(yearUseDays: number) {
    this.rawData.yearUseDays = yearUseDays;
  }

  get employmentCode(): string {
    return this.rawData.employmentCode;
  }

  set employmentCode(employmentCode: string) {
    this.rawData.employmentCode = employmentCode;
  }

  get employmentName(): string {
    return this.rawData.employmentName;
  }

  set employmentName(employmentName: string) {
    this.rawData.employmentName = employmentName;
  }

  get hqRoleName(): string {
    return this.rawData.hqRoleName;
  }

  set hqRoleName(hqRoleName: string) {
    this.rawData.hqRoleName = hqRoleName;
  }

  get shopRoleName(): string {
    return this.rawData.shopRoleName;
  }

  set shopRoleName(shopRoleName: string) {
    this.rawData.shopRoleName = shopRoleName;
  }

  get personalRoleName(): string {
    return this.rawData.personalRoleName;
  }

  set personalRoleName(personalRoleName: string) {
    this.rawData.personalRoleName = personalRoleName;
  }

  get stateStatus(): number {
    return this.rawData.stateStatus;
  }

  set stateStatus(stateStatus: number) {
    this.rawData.stateStatus = stateStatus;
  }

  get stateStatusDisp(): string {
    return this.rawData.stateStatusDisp;
  }

  set stateStatusDisp(stateStatusDisp: string) {
    this.rawData.stateStatusDisp = stateStatusDisp;
  }

  get phoneNumber(): string | undefined {
    return this.rawData.phoneNumber;
  }

  set phoneNumber(phoneNumber: string | undefined) {
    this.rawData.phoneNumber = phoneNumber;
  }

  get trialTerm(): number | undefined {
    return this.rawData.trialTerm;
  }

  set trialTerm(trialTerm: number | undefined) {
    this.rawData.trialTerm = trialTerm;
  }

  get gender(): number | undefined {
    return this.rawData.gender;
  }

  set gender(gender: number | undefined) {
    this.rawData.gender = gender;
  }

  get nationality(): number | undefined {
    return this.rawData.nationality;
  }

  set nationality(nationality: number | undefined) {
    this.rawData.nationality = nationality;
  }

  get alienRegistrationCardExpirationDate(): Date | undefined {
    if (this.rawData.alienRegistrationCardExpirationDate) {
      return moment(this.rawData.alienRegistrationCardExpirationDate).toDate();
    }
    return undefined;
  }

  get dispAlienRegistrationCardExpirationDate(): string | undefined {
    if (this.rawData.alienRegistrationCardExpirationDate) {
      return moment(this.rawData.alienRegistrationCardExpirationDate).format("YYYY年MM月DD日");
    }
    return undefined;
  }

  set alienRegistrationCardExpirationDate(alienRegistrationCardExpirationDate: Date | undefined) {
    this.rawData.alienRegistrationCardExpirationDate = alienRegistrationCardExpirationDate;
  }

  get statusResidence(): number | undefined {
    return this.rawData.statusResidence;
  }

  set statusResidence(statusResidence: number | undefined) {
    this.rawData.statusResidence = statusResidence;
  }

  get createUser(): string | undefined {
    return this.rawData.createUser;
  }

  set createUser(createUser: string | undefined) {
    this.rawData.createUser = createUser;
  }

  get useRemote(): boolean {
    return this.rawData.useRemote;
  }

  set useRemote(useRemote: boolean) {
    this.rawData.useRemote = useRemote;
  }

  get updateUser(): string | undefined {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string | undefined) {
    this.rawData.updateUser = updateUser;
  }

  get staffHolidayData(): StaffHolidayDataType {
    return this.rawData.staffHolidayData;
  }

  set staffHolidayData(staffHolidayData: StaffHolidayDataType) {
    this.rawData.staffHolidayData = staffHolidayData;
  }

  get orgApplyDate(): Date | undefined {
    if (this.rawData.orgApplyDate) {
      return moment(this.rawData.orgApplyDate).toDate();
    }
    return undefined;
  }

  set orgApplyDate(orgApplyDate: Date | undefined) {
    this.rawData.orgApplyDate = orgApplyDate;
  }

  get dispOrgApplyDate(): string | undefined {
    if (this.rawData.orgApplyDate) {
      return moment(this.rawData.orgApplyDate).format("YYYY年MM月DD日");
    }
    return undefined;
  }

  get empApplyDate(): Date | undefined {
    if (this.rawData.empApplyDate) {
      return moment(this.rawData.empApplyDate).toDate();
    }
    return new Date();
  }

  set empApplyDate(empApplyDate: Date | undefined) {
    this.rawData.empApplyDate = empApplyDate;
  }

  get dispEmpApplyDate(): string | undefined {
    if (this.rawData.empApplyDate) {
      return moment(this.rawData.empApplyDate).format("YYYY年MM月DD日");
    }
    return moment(new Date()).format("YYYY年MM月");
  }

  // editStaffCode?: string,
  // empApplyEndDate?: Date,
  // workingForm?: number,
  // stateFlg?: boolean,

  get staffBusinessList(): Array<StaffBusiness> {
    return this.rawData.staffBusinessList;
  }

  set staffBusinessList(staffBusinessList: Array<StaffBusiness>) {
    this.rawData.staffBusinessList = staffBusinessList;
  }

  get staffHolidayList(): Array<StaffHoliday> {
    return this.rawData.staffHolidayList;
  }

  set staffHolidayList(staffHolidayList: Array<StaffHoliday>) {
    this.rawData.staffHolidayList = staffHolidayList;
  }

  get organizationDataList(): Array<OrganizationDataStaff> {
    return this.rawData.organizationDataList;
  }

  get organizationDataDomainList(): Array<OrganizationDataStaffDomain> {
    return this.rawData.organizationDataList.map((item) => new OrganizationDataStaffDomain(item));
  }

  set organizationDataList(organizationDataList: Array<OrganizationDataStaff>) {
    this.rawData.organizationDataList = organizationDataList;
  }

  get employmentDataList(): Array<EmploymentDataStaff> {
    return this.rawData.employmentDataList;
  }

  get employmentDataDomainList(): Array<EmploymentDataStaffDomain> {
    return this.rawData.employmentDataList.map((item) => new EmploymentDataStaffDomain(item));
  }

  set employmentDataList(employmentDataList: Array<EmploymentDataStaff>) {
    this.rawData.employmentDataList = employmentDataList;
  }

  get selectSalary(): SalaryDataStaffDomain {
    return this.selectedSalary;
  }

  set selectSalary(selectSalary: SalaryDataStaffDomain) {
    this.selectedSalary = selectSalary;
  }

  get salaryList(): Array<SalaryDataStaff> {
    return this.rawData.salaryList;
  }

  get salaryDomainList(): Array<SalaryDataStaffDomain> {
    return this.rawData.salaryList.map((item) => new SalaryDataStaffDomain(item));
  }

  set salaryList(salaryList: Array<SalaryDataStaff>) {
    this.rawData.salaryList = salaryList;
  }

  get selectWeekShiftPattern(): WeekShiftPatternDataStaffDomain {
    return this.selectedWeekShiftPattern;
  }

  set selectWeekShiftPattern(selectWeekShiftPattern: WeekShiftPatternDataStaffDomain) {
    this.selectedWeekShiftPattern = selectWeekShiftPattern;
  }

  get weekShiftPatternList(): Array<WeekShiftPatternDataStaff> {
    return this.rawData.weekShiftPatternList;
  }

  get weekShiftPatternDomainList(): Array<WeekShiftPatternDataStaffDomain> {
    return this.rawData.weekShiftPatternList.map((item) => new WeekShiftPatternDataStaffDomain(item));
  }

  set weekShiftPatternList(weekShiftPatternList: Array<WeekShiftPatternDataStaff>) {
    this.rawData.weekShiftPatternList = weekShiftPatternList;
  }

  get empBusinessList(): Array<Business> {
    return this.rawData.empBusinessList;
  }

  set empBusinessList(empBusinessList: Array<Business>) {
    this.rawData.empBusinessList = empBusinessList;
  }

  get roundTimeList(): Array<string> {
    return this.rawData.roundTimeList;
  }

  set roundTimeList(roundTimeList: Array<string>) {
    this.rawData.roundTimeList = roundTimeList;
  }

  get shiftPatternList(): Array<ShiftPattern> {
    return this.rawData.shiftPatternList;
  }

  set shiftPatternList(shiftPatternList: Array<ShiftPattern>) {
    this.rawData.shiftPatternList = shiftPatternList;
  }

  get managerList(): Array<ManagerDataStaff> {
    return this.rawData.managerList;
  }

  get managerDomainList(): Array<ManagerDataStaffDomain> {
    return this.rawData.managerList.map((item) => new ManagerDataStaffDomain(item));
  }

  get concurrentList(): Array<ConcurrentType> {
    return this.rawData.concurrentList;
  }

  set concurrentList(concurrentList: Array<ConcurrentType>) {
    this.rawData.concurrentList = concurrentList;
  }

  set managerDomainList(managerDomainList: Array<ManagerDataStaffDomain>) {
    this.rawData.managerList = managerDomainList.map((item) => ({
      managerId: item.managerId,
      staffCode: item.staffCode,
      startDate: item.startDate,
      endDate: item.endDate,
      orgCode: item.orgCode,
      orgName: item.orgName,
      managerType: item.managerType,
      startDateStr: item.startDateStr,
      endDateStr: item.endDateStr,
    }));
  }

  set managerList(managerList: Array<ManagerDataStaff>) {
    this.rawData.managerList = managerList;
  }

  get role(): string {
    if (!this.rawData.role) {
      return "";
    }
    return this.rawData.role;
  }

  set role(role: string) {
    this.rawData.role = role;
  }

  get editStaffCode(): string | undefined {
    return this.rawData.editStaffCode;
  }

  set editStaffCode(editStaffCode: string | undefined) {
    this.rawData.editStaffCode = editStaffCode;
  }

  get useEmploymentDeemedWorkingHours(): boolean {
    return this.rawData.useEmploymentDeemedWorkingHours;
  }

  set useEmploymentDeemedWorkingHours(useEmploymentDeemedWorkingHours: boolean) {
    this.rawData.useEmploymentDeemedWorkingHours = useEmploymentDeemedWorkingHours;
  }

  get deemedWorkingHours(): number {
    return this.rawData.deemedWorkingHours;
  }

  set deemedWorkingHours(deemedWorkingHours: number) {
    this.rawData.deemedWorkingHours = deemedWorkingHours;
  }

  get timeHolidayHourOfDay(): number {
    return this.rawData.timeHolidayHourOfDay;
  }

  set timeHolidayHourOfDay(timeHolidayHourOfDay: number) {
    this.rawData.timeHolidayHourOfDay = timeHolidayHourOfDay;
  }

  get timeHolidayMaximumHour(): number {
    return this.rawData.timeHolidayMaximumHour;
  }

  set timeHolidayMaximumHour(timeHolidayMaximumHour: number) {
    this.rawData.timeHolidayMaximumHour = timeHolidayMaximumHour;
  }

  get deemedMorningHalfHours(): number {
    return this.rawData.deemedMorningHalfHours;
  }

  set deemedMorningHalfHours(deemedMorningHalfHours: number) {
    this.rawData.deemedMorningHalfHours = deemedMorningHalfHours;
  }

  get deemedAfternoonHalfHours(): number {
    return this.rawData.deemedAfternoonHalfHours;
  }

  set deemedAfternoonHalfHours(deemedAfternoonHalfHours: number) {
    this.rawData.deemedAfternoonHalfHours = deemedAfternoonHalfHours;
  }

  get icCardListData(): Array<CardListType> {
    return this.rawData.icCardListData;
  }

  set icCardListData(icCardListData: Array<CardListType>) {
    this.rawData.icCardListData = icCardListData;
  }

  get useRoleList(): boolean {
    return this.rawData.useRoleList;
  }

  set useRoleList(useRoleList: boolean) {
    this.rawData.useRoleList = useRoleList;
  }
}
