import React, { SVGProps } from 'react';

const DashboardDropdown: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M33.8703 11C33.4303 12.73 31.8603 14 30.0003 14C27.7903 14 26.0003 12.21 26.0003 10C26.0003 8.14 27.2703 6.57 29.0003 6.13V10C29.0003 10.55 29.4503 11 30.0003 11H33.8703ZM33.8703 9H31.0003V6.13C32.4103 6.49 33.5103 7.59 33.8703 9ZM36.0003 10C36.0003 13.31 33.3103 16 30.0003 16C26.6903 16 24.0003 13.31 24.0003 10C24.0003 6.69 26.6903 4 30.0003 4C33.3103 4 36.0003 6.69 36.0003 10ZM18.0003 23C18.0003 23.55 18.4503 24 19.0003 24C19.5503 24 20.0003 23.55 20.0003 23V13.55C20.0003 13 19.5503 12.55 19.0003 12.55C18.4503 12.55 18.0003 13 18.0003 13.55V23ZM11.0003 24C10.4503 24 10.0003 23.55 10.0003 23V15.55C10.0003 15 10.4503 14.55 11.0003 14.55C11.5503 14.55 12.0003 15 12.0003 15.55V23C12.0003 23.55 11.5503 24 11.0003 24ZM15.0003 24C14.4503 24 14.0003 23.55 14.0003 23V18C14.0003 17.45 14.4503 17 15.0003 17C15.5503 17 16.0003 17.45 16.0003 18V23C16.0003 23.55 15.5503 24 15.0003 24ZM7.00031 24C6.45031 24 6.00031 23.55 6.00031 23V20C6.00031 19.45 6.45031 19 7.00031 19C7.55031 19 8.00031 19.45 8.00031 20V23C8.00031 23.55 7.55031 24 7.00031 24ZM25.0003 18C24.4503 18 24.0003 18.45 24.0003 19C24.0003 19.55 24.4503 20 25.0003 20H35.0003C35.5503 20 36.0003 19.55 36.0003 19C36.0003 18.45 35.5503 18 35.0003 18H25.0003ZM24.0003 23C24.0003 22.45 24.4503 22 25.0003 22H35.0003C35.5503 22 36.0003 22.45 36.0003 23C36.0003 23.55 35.5503 24 35.0003 24H25.0003C24.4503 24 24.0003 23.55 24.0003 23ZM19.7103 7.29C20.1003 7.68 20.1003 8.31 19.7103 8.7L15.7103 12.7C15.4103 13 14.9403 13.08 14.5603 12.89L11.2003 11.21L6.71031 15.7C6.32031 16.09 5.69031 16.09 5.30031 15.7C4.91031 15.31 4.91031 14.68 5.30031 14.29L10.3003 9.29C10.6003 8.99 11.0703 8.91 11.4503 9.1L14.8103 10.78L18.3003 7.29C18.6803 6.9 19.3203 6.9 19.7103 7.29Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 2V26H40V2H2ZM1 0C0.45 0 0 0.45 0 1V27C0 27.55 0.45 28 1 28H41C41.55 28 42 27.55 42 27V1C42 0.45 41.55 0 41 0H1Z" fill="#0071BC"/>
    <path d="M0 31C0 30.45 0.45 30 1 30H9C9.55 30 10 30.45 10 31C10 31.55 9.55 32 9 32H1C0.45 32 0 31.55 0 31Z" fill="#0071BC"/>
    <path d="M6.5 26H9L6.5 31H4L6.5 26Z" fill="#0071BC"/>
    <path d="M42 31C42 30.45 41.55 30 41 30H33C32.45 30 32 30.45 32 31C32 31.55 32.45 32 33 32H41C41.55 32 42 31.55 42 31Z" fill="#0071BC"/>
    <path d="M35.5 26H33L35.5 31H38L35.5 26Z" fill="#0071BC"/>
  </svg>
  
);

export default DashboardDropdown;
