/** @jsx jsx */

import React from "react";

import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import MultipleCheckboxForm from "components/molecules/MultipleCheckboxForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton } from "components/atoms/Button";
import DateTimeSelectForm from "components/molecules/DateTimeSelectForm";
import MonthDateSelectForm from "components/molecules/MonthDateSelectForm";
import ConfirmModal from "components/organismos/ConfirmModal";
import { useParams } from "react-router-dom";
import { useSpecialSalaryPatternAddForm } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { css, jsx } from "@emotion/core";

const SpecialSalaryPatternInputForm: React.FC = () => {
  const { specialSalaryPatternId } = useParams();
  const {
    formik,
    detailRole,
    confirmModalOpen,
    closeConfirmModal,
    handleBack,
    blocking,
  } = useSpecialSalaryPatternAddForm();
  return (
    <BlockUI blocking={blocking}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ margin: "0 auto", width: "60%" }}>
          <FormField>
            <TextForm
              name="specialSalaryPatternCode"
              label="パターンコード"
              value={String(formik.values.specialSalaryPatternCode)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.specialSalaryPatternCode}
              required={true}
            />
          </FormField>

          <FormField>
            <TextForm
              name="specialSalaryPatternName"
              label="パターン名"
              value={String(formik.values.specialSalaryPatternName)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.specialSalaryPatternName}
              required={true}
            />
          </FormField>

          <FormField>
            <MultipleCheckboxForm
              name="category"
              label="指定された曜日を特賃日に設定"
              items={[
                {
                  id: "holidayFlag",
                  label: "祝日",
                  value: formik.values.holidayFlag,
                },
                {
                  id: "beforeHolidayFlag",
                  label: "祝前日",
                  value: formik.values.beforeHolidayFlag,
                },
                {
                  id: "saturdayFlag",
                  label: "土曜日",
                  value: formik.values.saturdayFlag,
                },
                {
                  id: "sundayFlag",
                  label: "日曜日",
                  value: formik.values.sundayFlag,
                },
                {
                  id: "mondayFlag",
                  label: "月曜日",
                  value: formik.values.mondayFlag,
                },
                {
                  id: "tuesdayFlag",
                  label: "火曜日",
                  value: formik.values.tuesdayFlag,
                },
                {
                  id: "wednesdayFlag",
                  label: "水曜日",
                  value: formik.values.wednesdayFlag,
                },
                {
                  id: "thursdayFlag",
                  label: "木曜日",
                  value: formik.values.thursdayFlag,
                },
                {
                  id: "fridayFlag",
                  label: "金曜日",
                  value: formik.values.fridayFlag,
                },
              ]}
              setValue={(e) => formik.setFieldValue(String(e.target.id), !(e.target.value === "true"))}
            />
          </FormField>

          <MonthDateSelectForm
            label="指定された日付を毎年の特賃日に設定"
            name="sameDays"
            dateFormat="MM/DD"
            labelFormat="MM月DD日"
            setValue={(items: any) => formik.setFieldValue("sameDays", items)}
            isMulti={true}
            values={formik.values.sameDays}
          />

          <DateTimeSelectForm
            label="指定された日付を特賃日に設定"
            name="fixedDays"
            dateFormat="yyyy/MM/dd"
            labelFormat="YYYY年MM月DD日"
            valueFormat="YYYY/MM/DD"
            setValue={(items: any) => formik.setFieldValue("fixedDays", items)}
            isMulti={true}
            values={formik.values.fixedDays}
          />

          <div style={{ paddingTop: "24px", margin: "0 auto", width: "60%" }}>
            <FormSubmitArea>
              <div style={{ display: "table", margin: "0 auto" }}>
                <FlexBox>
                  {detailRole.editable === 1 && (
                    <FlexBoxItem customStyle={specialSalaryPatternId ? css({ paddingRight: "15px" }) : css({})}>
                      <Button
                        customStyle={css("width: 170px;")}
                        text={specialSalaryPatternId ? "更新" : "特賃日を登録"}
                        onClick={formik.handleSubmit}
                      />
                    </FlexBoxItem>
                  )}
                  {/* {(() => {
                if (workingDaysId && detailRole.editable === 1) {
                  return (
                    <FlexBoxItem>
                      <SecondaryButton
                        customStyle={css("width: 170px;")}
                        text="削除"
                        onClick={() => {
                          openConfirmDeleteModal(formik.values);
                        }}
                      />
                    </FlexBoxItem>
                  );
                }
              })()} */}
                </FlexBox>
              </div>
              <div style={{ padding: "5px 0px 70px 0px", display: "table", margin: "0 auto" }}>
                <PrimaryButton text="キャンセル" onClick={handleBack} ghost={true} />
              </div>
            </FormSubmitArea>
          </div>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          title={`特賃日情報を${specialSalaryPatternId ? "更新" : "登録"}`}
          content={`特賃日情報を${specialSalaryPatternId ? "更新" : "登録"}します。よろしいですか？`}
          submitText={`${specialSalaryPatternId ? "更新" : "登録"}する`}
        />
      </form>
    </BlockUI>
  );
};

export default SpecialSalaryPatternInputForm;
