/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import PasswordForm from "components/molecules/PasswordForm";
import Button from "components/atoms/Button";
import { useHistory, useParams } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import BodyText from "components/atoms/BodyText";
import PrimaryButton from "components/atoms/Button";
import { signUpSingleVersion } from "api/login";
import { getAuthorizedFromDelious } from "utility";

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .required("メールアドレスを入力してください")
    .email("形式がemailではありません。"),
  password: getAuthorizedFromDelious()
    ? Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
          "パスワードは大文字小文字数字記号をすべて含む8文字以上で入力して下さい",
        )
        .required("パスワードを入力してください")
    : Yup.string()
        .min(6, "パスワードは6文字以上で入力して下さい")
        .required("パスワードを入力してください"),
  rePassword: getAuthorizedFromDelious()
    ? Yup.string()
        .required("パスワード(確認)を入力してください")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
          "パスワードは大文字小文字数字記号をすべて含む8文字以上で入力して下さい",
        )
        .oneOf([Yup.ref("password")], "パスワードと確認用のパスワードが異なっています")
    : Yup.string()
        .required("パスワード(確認)を入力してください")
        .min(6, "パスワードは6文字以上で入力して下さい")
        .oneOf([Yup.ref("password")], "パスワードと確認用のパスワードが異なっています"),
});

const SignUpInputForm: React.FC = () => {
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();
  const { companyCode } = useParams<any>();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rePassword: "",
    },
    onSubmit: (values) => {
      // awsExports.aws_cognito_allow_admin_create_user === "true" ? (
      signUpSingleVersion(formik.values.email, formik.values.password, companyCode ?? "")
        .then(() => {
          successNotification(`ユーザー登録が完了しました。`);
          history.push(getAuthorizedFromDelious() ? `/login/${companyCode}` : "/login");
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            let msgError = "";
            error.response.data.errors.map((item: { defaultMessage: string }) => {
              msgError += `${item.defaultMessage} </br>`;
              return msgError;
            });
            errorNotification(msgError);
          } else {
            errorNotification("ユーザー登録に失敗しました");
          }
          // if(error.code === "UsernameExistsException"){
          //   errorNotification('既にユーザー登録は完了していますので、ユーザ登録する必要はありません');
          // }
          // else if(error.code === "InvalidParameterException"){
          //   errorNotification('パスワードと確認用のパスワードが異なっています');
          // }
          // else if(error.code === "InvalidParameterException"){
          //   errorNotification('パスワードと確認用のパスワードが異なっています');
          // }
          // else {
          //   errorNotification('aaaユーザー登録に失敗しました');
          // }
        });
      // ):''
    },
    //   ): (
    //     Auth.signUp(formik.values.email, formik.values.password)
    //       .then((data : any) => {
    //         successNotification(`ユーザー登録が完了しました。`);
    //         history.push(`/login`);
    //       })
    //       .catch((err : any) => {
    //         if (err.code === 'UsernameExistsException') {
    //           errorNotification('既にユーザー登録は完了していますので、ユーザ登録する必要はありません');
    //         } else if (err.message === 'Username should be an email.') {
    //           errorNotification('正しいメールアドレスを入力して下さい');
    //         } else if (err.message === "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6") {
    //           errorNotification('パスワードは6文字以上で入力して下さい');
    //         } else if (err.code === 'InvalidParameterException') {
    //           errorNotification('パスワードと確認用のパスワードが異なっています');
    //         } else {
    //           errorNotification('ユーザー登録に失敗しました');
    //         }
    //       })
    //   );
    // },
    validationSchema: SignUpSchema,
  });

  const handleSubmitSignUp = async () => {
    try {
      // signUp(
      //   formik.values.email,
      //   formik.values.password,
      // ).then(() => {
      formik.handleSubmit();
      // })
      // .catch(() => {
      //   errorNotification('スタッフ情報が登録されていないためユーザー登録できません。');
      // });
    } catch (error) {
      errorNotification("サーバー側でエラーが発生しました。");
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormField>
        <TextForm
          name="email"
          label="メールアドレス"
          placeHolder="メールアドレスを入力"
          value={formik.values.email}
          onChange={formik.handleChange}
          errorMsg={formik.errors.email}
        />
      </FormField>
      <FormField>
        <PasswordForm
          name="password"
          label="パスワード"
          placeHolder="パスワードを入力"
          value={formik.values.password}
          onChange={formik.handleChange}
          errorMsg={formik.errors.password}
        />
      </FormField>

      <FormField displayBlock={true}>
        <BodyText size="re">パスワード(確認)</BodyText>
        <PasswordForm
          name="rePassword"
          label=""
          placeHolder="パスワード（確認）"
          value={formik.values.rePassword}
          onChange={formik.handleChange}
          errorMsg={formik.errors.rePassword}
        />
      </FormField>
      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%", marginTop: "20px" })}>
          <Button text="ユーザー登録" onClick={() => handleSubmitSignUp()} minWidth="160px" />
        </div>
        <div css={css({ textAlign: "center", width: "100%" })}>
          <PrimaryButton
            text="キャンセル"
            ghost={true}
            onClick={() => history.replace(getAuthorizedFromDelious() ? `/login/${companyCode}` : "/login")}
          />
        </div>
      </FormSubmitArea>
    </form>
  );
};

export default SignUpInputForm;
