import moment from 'moment';
import { ShiftView } from 'domain/master/attend/shiftView';
import ApiClient from './ApiClient';

export enum ShiftViewStatus {
  CONFIRM = 0,
  SELF_EMPLOYED = 1,
  ANOTHER_STORE = 2,
  HOLIDAY = 3,
}

export enum ShiftViewStatusColor {
  '#000000' = 0,
  '#dff0d8' = 1,
  '#edafe7' = 2,
  '#ED5D5D' = 3,
}


export const getShiftViewList = async (
  orgCode: string,
  targetDate: Date,
): Promise<Array<ShiftView>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateFrom = moment(new Date(targetDate.getFullYear(), targetDate.getMonth(), 1)).format('YYYY-MM-DD');
  const targetDateTo = moment(new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)).format('YYYY-MM-DD');


  const params = {
    orgCode,
    staffCode,
    targetMonth: '',
    targetDateFrom,
    targetDateTo,
    deviceType: 1,
  };
  const response = await ApiClient.get(`/v1/displayShifts/${companyCode}`, params, undefined);
  return response.data;
};

export const getShiftViewDetail = async (
  targetDate: Date,
): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const targetDateStr = moment(targetDate).format('YYYY-MM-DD');


  const params = {
    staffCode,
    targetDate: targetDateStr,
  };
  const response = await ApiClient.get(`/v1/displayShifts/detail/${companyCode}`, params, undefined);
  return response.data;
};

export default {};
