/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import { useDownload } from './hooks';

const MonthlySeparateForeCastManagemenTable: React.FC<{
  getBudgetPerfomanceManagement: any,
  targetPeriodData: any,
  orgName: any,
  compareToPreviousYear: any
}> = ({
  getBudgetPerfomanceManagement,
  targetPeriodData,
  orgName,
  compareToPreviousYear,
}) => {
  const { downloadBudgetPerfomanceManagementCSV, downloadBudgetPerfomanceManagementExcel } = useDownload();

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 0,
  });
  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
  });
  const [getOrgCodeName, setOrgCodeName] = useState('');
  console.log(getOrgCodeName);
  const orgCodeArr: any[] = [];
  const searchResult: any[] = [];

  let dateColumn: string;
  let netSalesColumn: string;
  let salesBudgetColumn: string;
  let lastyearSalesColumn: string;
  let actualAchievementRateColumn: string;
  let yearOnYearColumn: string;
  let cumulativeNetsalesColumn: string;
  let cumulativeSalesbudgetColumn: string;
  let cumulativePreviousyearsalesColumn: string;
  let actualAchievementrateColumn2: string;
  let yearOnYear2Column: string;
  let numberOfCustomersColumn: string;
  let targetNumberOfCustomersColumn: string;
  let previousYearNumberOfCustomersColumn: string;
  let actualAchievementRate2Column: string;
  let yearOnYear3Column: string;
  let unitPriceperCustomerColumn: string;
  let targetCustomerPriceColumn: string;
  let lastyearSunitPriceColumn: string;
  let purchaseColumn: string;
  let costRateColumn: string;
  let cumulativePurchaseColumn: string;
  let cumulativeCostRateColumn: string;
  let cumulativeTargetCostRateColumn: string;
  let cumulativePreviousYearCostRatioColumn: string;
  let cumulativeForecastDifferenceRateColumn: string;
  let cumulativeYearOnYearDifferenceColumn: string;
  let personnelexpensesColumn: string;
  let personnelexpenseratioColumn: string;
  let totallaborcostColumn: string;
  let cumulativepersonnelcostratioColumn: string;
  let cumulativetargetpersonnelexpenseratioColumn: string;
  let cumulativepersonnelcostratioforthepreviousyearColumn: string;
  let predictionrateColumn: string;
  let yoYchangeColumn: string;
  let cumulativeFixedCostColumn: string;
  let cumulativeVariableCostColumn: string;
  let cumulativeProfitColumn: string;
  let cumulativeTargetProfitColumn: string;
  let cumulativeActualAchievementRateColumn: string;

  useEffect(() => {
    if (getBudgetPerfomanceManagement) {
      getBudgetPerfomanceManagement.map((item: any, index: any) => {
        orgCodeArr.push(item.orgName);
      });
    }
    const str = orgCodeArr.join('、');
    setOrgCodeName(str);
  }, [getBudgetPerfomanceManagement, orgCodeArr]);

  const targetStoresData = `対象組織：${orgName.split(' ')[1]}`;
  const headerInformation = [
    ['', '売上', '', '', '', '', '', '', '', '', '', '客数', '', '', '', '', '', '', '', '仕入', '', '', '', '', '', '', '', '人件費', '', '', '', '', '', '', '', '経費', '', '利益', '', ''],
    ['日付', '純売上', '売上予算', '前年売上', '予実達成率', '前年対比', '累計純売上', '累計売上予算', '累計前年売上', '予実達成率', '前年対比', '客数', '目標客数', '前年客数', '予実達成率', '前年対比', '客単価', '目標客単価', '前年客単価', '仕入', '原価率', '累計仕入', '累計原価率', '累計目標原価率', '累計前年原価率', '予実差率', '前年差率', '人件費', '人件費率', '累計人件費', '累計人件費率', '累計目標人件費率', '累計前年人件費率', '予実差率', '前年差率', '累計固定費', '累計変動費', '累計利益', '累計目標利益', '累計予実達成率'],
  ];

  let mergedRegions: any[] = [];
  const codeColumnIndex: any[] = [];
  const totalInformation: any[] = [];
  const data = {
    footer: '',
    formName: '月次日別予実管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    compareToPreviousYear,
    headerInformation,
    searchResult,
    totalInformation,
  };

  const dataExcel = {
    formName: '月次日別予実管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    compareToPreviousYear,
    headerInformation,
    searchResult,
    totalInformation,
    mergedRegions,
    codeColumnIndex,
  };

  const handleExportBudgetPerfomanceManagementCSV = () => { downloadBudgetPerfomanceManagementCSV(data); };
  const handleExportBudgetPerfomanceManagementExcel = () => {
    if (mergedRegions) {
      mergedRegions = [];
    }
    mergedRegions.push([4, 5, 0, 0]);
    mergedRegions.push([4, 4, 1, 10]);
    mergedRegions.push([4, 4, 11, 18]);
    mergedRegions.push([4, 4, 19, 26]);
    mergedRegions.push([4, 4, 27, 34]);
    mergedRegions.push([4, 4, 35, 36]);
    mergedRegions.push([4, 4, 37, 39]);
    dataExcel.mergedRegions = mergedRegions;
    dataExcel.codeColumnIndex = [];
    dataExcel.headerInformation = [
      ['日付', '売上', '', '', '', '', '', '', '', '', '', '客数', '', '', '', '', '', '', '', '仕入', '', '', '', '', '', '', '', '人件費', '', '', '', '', '', '', '', '経費', '', '利益', '', ''],
      ['', '純売上', '売上予算', '前年売上', '予実達成率', '前年対比', '累計純売上', '累計売上予算', '累計前年売上', '予実達成率', '前年対比', '客数', '目標客数', '前年客数', '予実達成率', '前年対比', '客単価', '目標客単価', '前年客単価', '仕入', '原価率', '累計仕入', '累計原価率', '累計目標原価率', '累計前年原価率', '予実差率', '前年差率', '人件費', '人件費率', '累計人件費', '累計人件費率', '累計目標人件費率', '累計前年人件費率', '予実差率', '前年差率', '累計固定費', '累計変動費', '累計利益', '累計目標利益', '累計予実達成率'],
    ];
    return downloadBudgetPerfomanceManagementExcel(dataExcel);
  };

  const widthScrollbar = getBudgetPerfomanceManagement ? (40 * 131) + 150 : 0;
  const [getFlagBorder, setFlagBorder] = useState(0);
  const eventScroll = (e: { target: any }) => {
    const { target } = e;
    const wrapTblRight = document.getElementById('wrapTblRight');
    const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
    const jsScrollAction = document.getElementById('jsScrollAction');
    const wrapFootertblRight = document.getElementById('wrapFootertblRight');


    if (Number(target.scrollLeft) > 0) {
      setFlagBorder(1);
    } else {
      setFlagBorder(0);
    }

    if (wrapTblRight) {
      wrapTblRight.scrollLeft = target.scrollLeft;
    }
    if (wrapTblHeadRight) {
      wrapTblHeadRight.scrollLeft = target.scrollLeft;
    }
    if (jsScrollAction) {
      jsScrollAction.scrollLeft = target.scrollLeft;
    }
    if (wrapFootertblRight) {
      wrapFootertblRight.scrollLeft = target.scrollLeft;
    }
  };

  return (
    <div>
      <FlexBox>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportBudgetPerfomanceManagementCSV}
            iconType="download"
            text=" CSV出力"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportBudgetPerfomanceManagementExcel}
            iconType="download"
            text=" EXCEL出力"
          />
        </FlexBoxItem>
      </FlexBox>
      <div className="wrap_time_day_separate_sales wrap_monthly_day_separate_sales_management " style={{ overflow: 'hidden', marginTop: '15px' }}>
        <div className="contentHead" style={{ display: 'flex' }}>
          <div className="tblLeft" style={getFlagBorder ? { borderRight: '1px solid' } : {}}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} className="text-center" style={{ top: '16px', width: '100%', background: 'transparent' }}><span style={{ width: '100%' }}>売上</span></th>
                </tr>
                <tr>
                  <th className="text-center thead-bottom"><span>日付</span></th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tblRight" id="wrapTblHeadRight" style={(getBudgetPerfomanceManagement && getBudgetPerfomanceManagement.length > 17) ? { width: 'calc(100% - 211px)' } : { width: 'calc(100% - 194px)' }} onScroll={eventScroll}>
            <table>
              <thead>
                <tr>
                  <th colSpan={10} className="text-center"><span style={{ width: '100%' }}>売上</span></th>
                  <th colSpan={8} className="text-center"><span style={{ width: '100%' }}>客数</span></th>
                  <th colSpan={8} className="text-center"><span style={{ width: '100%' }}>仕入</span></th>
                  <th colSpan={8} className="text-center"><span style={{ width: '100%' }}>人件費</span></th>
                  <th colSpan={2} className="text-center"><span style={{ width: '100%' }}>経費</span></th>
                  <th colSpan={3} className="text-center"><span style={{ width: '100%' }}>利益</span></th>
                </tr>
                <tr>
                  <th className="text-center"><span>純売上</span></th>
                  <th className="text-center"><span>売上予算</span></th>
                  <th className="text-center"><span>前年売上</span></th>
                  <th className="text-center"><span>予実達成率</span></th>
                  <th className="text-center"><span>前年対比</span></th>
                  <th className="text-center"><span>累計純売上</span></th>
                  <th className="text-center"><span>累計売上予算</span></th>
                  <th className="text-center"><span>累計前年売上</span></th>
                  <th className="text-center"><span>予実達成率</span></th>
                  <th className="text-center"><span>前年対比</span></th>

                  <th className="text-center"><span>客数</span></th>
                  <th className="text-center"><span>目標客数</span></th>
                  <th className="text-center"><span>前年客数</span></th>
                  <th className="text-center"><span>予実達成率</span></th>
                  <th className="text-center"><span>前年対比</span></th>
                  <th className="text-center"><span>客単価</span></th>
                  <th className="text-center"><span>目標客単価</span></th>
                  <th className="text-center"><span>前年客単価</span></th>

                  <th className="text-center"><span>仕入</span></th>
                  <th className="text-center"><span>原価率</span></th>
                  <th className="text-center"><span>累計仕入</span></th>
                  <th className="text-center"><span>累計原価率</span></th>
                  <th className="text-center"><span>累計目標原価率</span></th>
                  <th className="text-center"><span>累計前年原価率</span></th>
                  <th className="text-center"><span>予実差率</span></th>
                  <th className="text-center"><span>前年差率</span></th>

                  <th className="text-center"><span>人件費</span></th>
                  <th className="text-center"><span>人件費率</span></th>
                  <th className="text-center"><span>累計人件費</span></th>
                  <th className="text-center"><span>累計人件費率</span></th>
                  <th className="text-center"><span style={{ width: '140px' }}>累計目標人件費率</span></th>
                  <th className="text-center"><span style={{ width: '140px' }}>累計前年人件費率</span></th>
                  <th className="text-center"><span>予実差率</span></th>
                  <th className="text-center"><span>前年差率</span></th>

                  <th className="text-center"><span>累計固定費</span></th>
                  <th className="text-center"><span>累計変動費</span></th>
                  <th className="text-center"><span>累計利益</span></th>
                  <th className="text-center"><span>累計目標利益</span></th>
                  <th className="text-center"><span>累計予実達成率</span></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <div>
            <div className="contentTable" style={{ display: 'flex' }}>
              <div className="tblLeft">
                <table>
                  <tbody>
                    {
                        getBudgetPerfomanceManagement.map((slitData: any, index: any) => {
                          // 日付
                          dateColumn = moment(slitData.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）');
                          // 純売上
                          netSalesColumn = formatter.format(slitData.netSales);
                          // 売上予算
                          salesBudgetColumn = formatter.format(slitData.salesBudget);
                          // 前年売上
                          lastyearSalesColumn = formatter.format(slitData.prevSales);
                          // 予実達成率
                          actualAchievementRateColumn = formatterPercent.format(0);
                          if (slitData.salesBudget !== 0) {
                            actualAchievementRateColumn = formatterPercent.format(slitData.netSales / slitData.salesBudget);
                          }
                          // 前年対比
                          yearOnYearColumn = formatterPercent.format(0);
                          if (slitData.prevSales !== 0) {
                            yearOnYearColumn = formatterPercent.format(slitData.netSales / slitData.prevSales);
                          }
                          // 累計純売上
                          cumulativeNetsalesColumn = formatter.format(slitData.cumulativeSales);
                          // 累計売上予算
                          cumulativeSalesbudgetColumn = formatter.format(slitData.cumulativeSalesBudget);
                          // 累計前年売上
                          cumulativePreviousyearsalesColumn = formatter.format(slitData.prevCumulativeSales);
                          // 予実達成率
                          actualAchievementrateColumn2 = formatterPercent.format(0);
                          if (slitData.cumulativeSalesBudget !== 0) {
                            actualAchievementrateColumn2 = formatterPercent.format(slitData.cumulativeSales / slitData.cumulativeSalesBudget);
                          }
                          // 前年対比
                          yearOnYear2Column = formatterPercent.format(0);
                          if (slitData.prevCumulativeSales !== 0) {
                            yearOnYear2Column = formatterPercent.format(slitData.cumulativeSales / slitData.prevCumulativeSales);
                          }
                          // 客数
                          numberOfCustomersColumn = formatter.format(slitData.guestCnt);
                          // 目標客数
                          targetNumberOfCustomersColumn = formatter.format(slitData.guestCntBudget);
                          // 前年客数
                          previousYearNumberOfCustomersColumn = formatter.format(slitData.prevGuestCnt);
                          // 予実達成率
                          actualAchievementRate2Column = formatterPercent.format(0);
                          if (slitData.guestCntBudget !== 0) {
                            actualAchievementRate2Column = formatterPercent.format(slitData.guestCnt / slitData.guestCntBudget);
                          }
                          // 前年対比
                          yearOnYear3Column = formatterPercent.format(0);
                          if (slitData.prevGuestCnt !== 0) {
                            yearOnYear3Column = formatterPercent.format(slitData.guestCnt / slitData.prevGuestCnt);
                          }
                          // 客単価
                          unitPriceperCustomerColumn = formatter.format(0);
                          if (slitData.guestCnt !== 0) {
                            unitPriceperCustomerColumn = formatter.format(slitData.netSales / slitData.guestCnt);
                          }
                          // 目標客単価
                          targetCustomerPriceColumn = formatter.format(0);
                          if (slitData.guestCntBudget !== 0) {
                            targetCustomerPriceColumn = formatter.format(slitData.salesBudget / slitData.guestCntBudget);
                          }
                          // 前年客単価
                          lastyearSunitPriceColumn = formatter.format(0);
                          if (slitData.prevGuestCnt !== 0) {
                            lastyearSunitPriceColumn = formatter.format(slitData.netSales / slitData.prevGuestCnt);
                          }
                          // 仕入
                          purchaseColumn = formatter.format(slitData.purchase);
                          // 原価率
                          costRateColumn = formatterPercent.format(0);
                          if (slitData.netSales !== 0) {
                            costRateColumn = formatterPercent.format(slitData.purchase / slitData.netSales);
                          }
                          // 累計仕入
                          cumulativePurchaseColumn = formatter.format(slitData.cumulativePurchase);
                          // 累計原価率
                          cumulativeCostRateColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSales !== 0) {
                            cumulativeCostRateColumn = formatterPercent.format(slitData.cumulativePurchase / slitData.cumulativeSales);
                          }
                          // 累計目標原価率
                          cumulativeTargetCostRateColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSalesBudget !== 0) {
                            cumulativeTargetCostRateColumn = formatterPercent.format(slitData.cumulativePurchaseBudget / slitData.cumulativeSalesBudget);
                          }
                          // 累計前年原価率
                          cumulativePreviousYearCostRatioColumn = formatterPercent.format(0);
                          if (slitData.prevCumulativeSales !== 0) {
                            formatterPercent.format(slitData.prevCumulativePurchase / slitData.prevCumulativeSales);
                          }
                          // 累計予実差率
                          cumulativeForecastDifferenceRateColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSales === 0 && slitData.cumulativeSalesBudget !== 0) {
                            cumulativeForecastDifferenceRateColumn = formatterPercent.format(-(slitData.cumulativePurchaseBudget / slitData.cumulativeSalesBudget));
                          } else if (slitData.cumulativeSales !== 0 && slitData.cumulativeSalesBudget === 0) {
                            cumulativeForecastDifferenceRateColumn = formatterPercent.format(slitData.cumulativePurchase / slitData.cumulativeSales);
                          } else if (slitData.cumulativeSales !== 0 && slitData.cumulativeSalesBudget !== 0) {
                            cumulativeForecastDifferenceRateColumn = formatterPercent.format((slitData.cumulativePurchase / slitData.cumulativeSales) - (slitData.cumulativePurchaseBudget / slitData.cumulativeSalesBudget));
                          }
                          // 累計前年差率
                          cumulativeYearOnYearDifferenceColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSales === 0 && slitData.prevCumulativeSales !== 0) {
                            cumulativeYearOnYearDifferenceColumn = formatterPercent.format(-(slitData.prevCumulativePurchase / slitData.prevCumulativeSales));
                          } else if (slitData.cumulativeSales !== 0 && slitData.prevCumulativeSales === 0) {
                            cumulativeYearOnYearDifferenceColumn = formatterPercent.format(slitData.cumulativePurchase / slitData.cumulativeSales);
                          } else if (slitData.cumulativeSales !== 0 && slitData.prevCumulativeSales !== 0) {
                            cumulativeYearOnYearDifferenceColumn = formatterPercent.format((slitData.cumulativePurchase / slitData.cumulativeSales) - (slitData.prevCumulativePurchase / slitData.prevCumulativeSales));
                          }
                          // 人件費
                          personnelexpensesColumn = formatter.format(slitData.laborCost);
                          // 人件費率
                          personnelexpenseratioColumn = formatterPercent.format(0);
                          if (slitData.netSales !== 0) {
                            personnelexpenseratioColumn = formatterPercent.format(slitData.laborCost / slitData.netSales);
                          }
                          // 累計人件費
                          totallaborcostColumn = formatter.format(slitData.cumulativeLaborCost);
                          // 累計人件費率

                          cumulativepersonnelcostratioColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSales !== 0) {
                            cumulativepersonnelcostratioColumn = formatterPercent.format(slitData.cumulativeLaborCost / slitData.cumulativeSales);
                          }
                          // 累計目標人件費率
                          cumulativetargetpersonnelexpenseratioColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSalesBudget !== 0) {
                            formatterPercent.format(slitData.cumulativeLaborCostBudget / slitData.cumulativeSalesBudget);
                          }
                          // 累計前年人件費率
                          cumulativepersonnelcostratioforthepreviousyearColumn = formatterPercent.format(0);
                          if (slitData.prevCumulativeSales !== 0) {
                            cumulativepersonnelcostratioforthepreviousyearColumn = formatterPercent.format(slitData.prevCumulativeLaborCost / slitData.prevCumulativeSales);
                          }
                          // 予実差率
                          predictionrateColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSales === 0 && slitData.cumulativeSalesBudget !== 0) {
                            predictionrateColumn = formatterPercent.format(-(slitData.cumulativeLaborCostBudget / slitData.cumulativeSalesBudget));
                          } else if (slitData.cumulativeSales !== 0 && slitData.cumulativeSalesBudget == 0) {
                            predictionrateColumn = formatterPercent.format(slitData.cumulativeLaborCost / slitData.cumulativeSales);
                          } else if (slitData.cumulativeSales !== 0 && slitData.cumulativeSalesBudget !== 0) {
                            predictionrateColumn = formatterPercent.format((slitData.cumulativeLaborCost / slitData.cumulativeSales) - (slitData.cumulativeLaborCostBudget / slitData.cumulativeSalesBudget));
                          }
                          // 前年差率
                          yoYchangeColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSales === 0 && slitData.prevCumulativeSales !== 0) {
                            yoYchangeColumn = formatterPercent.format(-(slitData.prevCumulativeLaborCost / slitData.prevCumulativeSales));
                          } else if (slitData.cumulativeSales !== 0 && slitData.prevCumulativeSales === 0) {
                            yoYchangeColumn = formatterPercent.format(slitData.cumulativeLaborCost / slitData.cumulativeSales);
                          } else if (slitData.cumulativeSales !== 0 && slitData.prevCumulativeSales !== 0) {
                            yoYchangeColumn = formatterPercent.format((slitData.cumulativeLaborCost / slitData.cumulativeSales) - (slitData.prevCumulativeLaborCost / slitData.prevCumulativeSales));
                          }
                          // 累計固定費
                          cumulativeFixedCostColumn = formatter.format(slitData.cumulativeFixCost);
                          // 累計変動費
                          cumulativeVariableCostColumn = formatter.format(slitData.cumulativeVariableCost);
                          // 累計利益
                          cumulativeProfitColumn = formatter.format(slitData.cumulativeSales - slitData.cumulativePurchase - slitData.cumulativeLaborCost - slitData.cumulativeFixCost - slitData.cumulativeVariableCost);
                          // 累計目標利益
                          cumulativeTargetProfitColumn = formatter.format(slitData.cumulativeSalesBudget - slitData.cumulativePurchaseBudget - slitData.cumulativeLaborCostBudget - slitData.cumulativeFixCost - slitData.cumulativeVariableCost);
                          // 累計予実達成率
                          cumulativeActualAchievementRateColumn = formatterPercent.format(0);
                          if (slitData.cumulativeSalesBudget - slitData.cumulativePurchaseBudget - slitData.cumulativeLaborCostBudget - slitData.cumulativeFixCost - slitData.cumulativeVariableCost !== 0) {
                            cumulativeActualAchievementRateColumn = formatterPercent.format((slitData.cumulativeSales - slitData.cumulativePurchase - slitData.cumulativeLaborCost - slitData.cumulativeFixCost - slitData.cumulativeVariableCost)
                              / (slitData.cumulativeSalesBudget - slitData.cumulativePurchaseBudget - slitData.cumulativeLaborCostBudget - slitData.cumulativeFixCost - slitData.cumulativeVariableCost));
                          }
                          if (slitData.targetDate) {
                            searchResult.push([
                              dateColumn,
                              netSalesColumn,
                              salesBudgetColumn,
                              lastyearSalesColumn,
                              actualAchievementRateColumn,
                              yearOnYearColumn,
                              cumulativeNetsalesColumn,
                              cumulativeSalesbudgetColumn,
                              cumulativePreviousyearsalesColumn,
                              actualAchievementrateColumn2,
                              yearOnYear2Column,
                              numberOfCustomersColumn,
                              targetNumberOfCustomersColumn,
                              previousYearNumberOfCustomersColumn,
                              actualAchievementRate2Column,
                              yearOnYear3Column,
                              unitPriceperCustomerColumn,
                              targetCustomerPriceColumn,
                              lastyearSunitPriceColumn,
                              purchaseColumn,
                              costRateColumn,
                              cumulativePurchaseColumn,
                              cumulativeCostRateColumn,
                              cumulativeTargetCostRateColumn,
                              cumulativePreviousYearCostRatioColumn,
                              cumulativeForecastDifferenceRateColumn,
                              cumulativeYearOnYearDifferenceColumn,
                              personnelexpensesColumn,
                              personnelexpenseratioColumn,
                              totallaborcostColumn,
                              cumulativepersonnelcostratioColumn,
                              cumulativetargetpersonnelexpenseratioColumn,
                              cumulativepersonnelcostratioforthepreviousyearColumn,
                              predictionrateColumn,
                              yoYchangeColumn,
                              cumulativeFixedCostColumn,
                              cumulativeVariableCostColumn,
                              cumulativeProfitColumn,
                              cumulativeTargetProfitColumn,
                              cumulativeActualAchievementRateColumn,
                            ]);
                          }
                          if (!slitData.targetDate) {
                            totalInformation.push([
                              '合計',
                              netSalesColumn,
                              salesBudgetColumn,
                              lastyearSalesColumn,
                              actualAchievementRateColumn,
                              yearOnYearColumn,
                              cumulativeNetsalesColumn,
                              cumulativeSalesbudgetColumn,
                              cumulativePreviousyearsalesColumn,
                              actualAchievementrateColumn2,
                              yearOnYear2Column,
                              numberOfCustomersColumn,
                              targetNumberOfCustomersColumn,
                              previousYearNumberOfCustomersColumn,
                              actualAchievementRate2Column,
                              yearOnYear3Column,
                              unitPriceperCustomerColumn,
                              targetCustomerPriceColumn,
                              lastyearSunitPriceColumn,
                              purchaseColumn,
                              costRateColumn,
                              cumulativePurchaseColumn,
                              cumulativeCostRateColumn,
                              cumulativeTargetCostRateColumn,
                              cumulativePreviousYearCostRatioColumn,
                              cumulativeForecastDifferenceRateColumn,
                              cumulativeYearOnYearDifferenceColumn,
                              personnelexpensesColumn,
                              personnelexpenseratioColumn,
                              totallaborcostColumn,
                              cumulativepersonnelcostratioColumn,
                              cumulativetargetpersonnelexpenseratioColumn,
                              cumulativepersonnelcostratioforthepreviousyearColumn,
                              predictionrateColumn,
                              yoYchangeColumn,
                              cumulativeFixedCostColumn,
                              cumulativeVariableCostColumn,
                              cumulativeProfitColumn,
                              cumulativeTargetProfitColumn,
                              cumulativeActualAchievementRateColumn,
                            ]);
                          }
                          if (slitData.targetDate) {
                            return (
                              <tr key={index}>
                                <td className="text-right">
                                  {dateColumn}
                                </td>
                              </tr>
                            );
                          }
                        })
                      }
                  </tbody>
                </table>
              </div>
              <div className="tblRight" id="wrapTblRight" onScroll={eventScroll} style={searchResult.length < 17 ? { width: 'calc(100% - 206px)' } : {}}>
                <table id="tblHeadTable" onScroll={eventScroll}>
                  <tbody>
                    {
                        searchResult ? searchResult.map((item: any, index: any) => {
                          if (item.targetDate !== null) {
                            return (
                              <tr key={index}>
                                {
                                  item.map((itemChild:any, indexChild:any) => {
                                    if (indexChild > 0) {
                                      return (
                                        <td style={((searchResult[0].length - 8) === indexChild || ((searchResult[0].length - 9) === indexChild)) ? { padding: '7px 15px' } : {}} className="text-right">
                                          <span>
                                            {itemChild}
                                          </span>
                                        </td>
                                      );
                                    }
                                  })
                                }
                              </tr>
                            );
                          }
                        }) : null
                      }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {
            getBudgetPerfomanceManagement.length > 0 ? (
              <div className="scroller">
                {/* <div className="scrollLeft" /> */}
                <div className="scrollRight" id="jsScrollAction" style={{ width: '100%', height: '16px' }} onScroll={eventScroll}>
                  <div className="scrollAction" style={{ width: `${widthScrollbar}px` }} />
                </div>
              </div>
            ) : null
          }

        <div className="contentFooter">
          <div className="tblLeft">
            <table>
              <tfoot>
                <tr>
                  {
                      totalInformation ? totalInformation[0].map((slitDataTotal: any, index: any) => {
                        if (index === 0) {
                          return (
                            <th className="text-center">
                              <span>{slitDataTotal}</span>
                            </th>
                          );
                        }
                      }) : null
                    }
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="tblRight" id="wrapFootertblRight" onScroll={eventScroll} style={(getBudgetPerfomanceManagement && getBudgetPerfomanceManagement.length > 17) ? { width: 'calc(100% - 211px)' } : { width: 'calc(100% - 194px)' }}>
            <table>
              <tfoot>
                <tr>
                  {
                      totalInformation ? totalInformation[0].map((slitDataTotal: any, index: any) => {
                        if (index !== 0) {
                          return (
                            <th className="text-right" style={((totalInformation[0].length - 8) === index || ((totalInformation[0].length - 9) === index)) ? { padding: '7px 15px' } : {}}>
                              <span>{slitDataTotal}</span>
                            </th>
                          );
                        }
                      }) : null
                    }
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlySeparateForeCastManagemenTable;
