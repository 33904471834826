/** @jsx jsx */
import React, { useEffect, useMemo } from "react";
import { jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import TabControl from "components/atoms/TabControl";
import FormContents from "components/atoms/Form/FormContents";
import moment from "moment";
import BlockUI from "components/molecules/BlockUi";
import useToastNotification from "hooks/useToastNotification";
import ApplyingTab from "./ApplyingTab";
import ApprovedTab from "./ApprovedTab";
import RejectedTab from "./RejectedTab";
import { attendApplicationApprovalContext, useInitialApplicationApproval } from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import FormTitle from "components/atoms/Form/FormTitle";
import { css } from "@emotion/core";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import { Col } from "react-grid-system";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import ProcessingTab from "./ProcessingTab";
import { setInitClosingDate } from "../setInitClosingDate";
import { useSelector } from "react-redux";
import { Store } from "modules/store";

type SelectType = "applying" | "processing" | "approved" | "rejected";

const SelectType: { [key in SelectType]: SelectType } = {
  applying: "applying",
  processing: "processing",
  approved: "approved",
  rejected: "rejected",
};

const AttendApplicationApprovalV3: React.FC = () => {
  const closingDate = useSelector((state: Store) => state.loginUser.loginUser.closingDate);
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.applying);
  const context = useInitialApplicationApproval();
  const {
    isLoading,
    setIsLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingAttendAllowanceList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,
    applyingStockPaidList,

    processingStampList,
    processingHolidayList,
    processingTransferList,
    processingAgreement36List,
    processingWorkScheduleChangeList,
    processingOvertimeList,
    processingAttendAllowanceList,
    processingHolidayWorkList,
    processingStockPaidHolidayList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedAttendAllowanceList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,
    approvedStockPaidList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedAttendAllowanceList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,
    rejectedStockPaidList,

    setflag,
    viewPeriod,
    setViewPeriod,
    targetMonth,
    setTargetMonth,
    type1_2,
  } = context;

  const { errorNotification } = useToastNotification();
  useEffect(() => {
    if (moment(targetDateTo).diff(targetDateFrom, "year") >= 1) {
      errorNotification("対象期間の範囲は１年以内で入力してください");
      return;
    }
  }, [targetDateFrom, targetDateTo]);

  function checkMonth(targetMonth: any) {
    const { firstDayStr, lastDayStr } = setInitClosingDate(closingDate, targetMonth);
    setTargetDateFrom(new Date(firstDayStr));
    setTargetDateTo(new Date(lastDayStr));
    sessionStorage.setItem("approvalV3TargetDayFrom", moment(firstDayStr).format("YYYY-MM-DD"));
    sessionStorage.setItem("approvalV3TargetDayTo", moment(lastDayStr).format("YYYY-MM-DD"));
  }

  function handleOnChange(e: any) {
    e.preventDefault();
    const value = e.target.value;
    setViewPeriod(value);
    sessionStorage.setItem("applicationListPeriod", value);
    if (value === "monthly") {
      checkMonth(targetMonth);
    }
  }
  return (
    <BlockUI blocking={isLoading}>
      <attendApplicationApprovalContext.Provider value={context}>
        <FormContents>
          <FormTitle
            title="勤怠承認"
            bold={true}
            customStyle={css`
              margin: 16px 45px;
            `}
          />
          <TabControl
            items={[
              {
                value: SelectType.applying,
                display:
                  context.flag === "applying"
                    ? `未承認(${applyingStampList.length +
                        applyingHolidayList.length +
                        applyingOvertimeList.length +
                        applyingAttendAllowanceList.length +
                        applyingHolidayWorkList.length +
                        applyingTransferList.length +
                        applyingAgreement36List.length +
                        applyingWorkScheduleChangeList.length +
                        applyingStockPaidList.length}件)`
                    : "未承認",
              },
              {
                value: SelectType.processing,
                display:
                  context.flag === "processing"
                    ? `進行中(${processingStampList.length +
                        processingHolidayList.length +
                        processingTransferList.length +
                        processingAgreement36List.length +
                        processingWorkScheduleChangeList.length +
                        processingOvertimeList.length +
                        processingAttendAllowanceList.length +
                        processingHolidayWorkList.length +
                        processingStockPaidHolidayList.length}件)`
                    : "進行中",
              },
              {
                value: SelectType.approved,
                display:
                  context.flag === "approved"
                    ? `承認済み(${approvedStampList.length +
                        approvedHolidayList.length +
                        approvedOvertimeList.length +
                        approvedAttendAllowanceList.length +
                        approvedHolidayWorkList.length +
                        approvedTransferList.length +
                        approvedAgreement36List.length +
                        approvedWorkScheduleChangeList.length +
                        approvedStockPaidList.length}件)`
                    : "承認済み",
              },
              {
                value: SelectType.rejected,
                display:
                  context.flag === "rejected"
                    ? `差戻(${rejectedStampList.length +
                        rejectedHolidayList.length +
                        rejectedOvertimeList.length +
                        rejectedAttendAllowanceList.length +
                        rejectedHolidayWorkList.length +
                        rejectedTransferList.length +
                        rejectedAgreement36List.length +
                        rejectedWorkScheduleChangeList.length +
                        rejectedStockPaidList.length}件)`
                    : "差戻",
              },
            ]}
            setValue={(value) => {
              if (value === "applying") {
                setflag("applying");
                setViewPeriod("dateAll");
              } else if (value === "processing") {
                setflag("processing");
                setViewPeriod("dateAll");
              } else if (value === "approved") {
                setflag("approved");
                setViewPeriod("monthly");
              } else {
                setflag("rejected");
                setViewPeriod("monthly");
              }
              setSelectType(value as SelectType);
            }}
            currentValue={selectType}
            customStyle={css({
              margin: "0px",
              padding: "8px 16px",
              boxSizing: "border-box",
              width: "100%",
              overflow: "auto",
            })}
          />

          <FormPadding>
            <Col md={10}>
              {type1_2.indexOf(selectType) !== -1 && (
                <FlexBox customStyle={css({ marginTop: "10px" })}>
                  <Col lg={2} md={3}>
                    <RadioSelectForm
                      label=""
                      name="all"
                      items={[
                        {
                          label: "全ての期間",
                          value: "dateAll",
                        },
                      ]}
                      value={viewPeriod}
                      setValue={(e) => {
                        setViewPeriod(e.target.value);
                        setIsLoading(true);
                      }}
                    />
                  </Col>
                </FlexBox>
              )}

              <FlexBox
                customStyle={css({
                  marginTop: type1_2.includes(selectType) ? "15px" : "20px",
                })}
              >
                <Col lg={2} md={3}>
                  <div style={{ marginTop: "-20px" }}>
                    <RadioSelectForm
                      label=""
                      name="monthly"
                      items={[
                        {
                          label: "月指定",
                          value: "monthly",
                        },
                      ]}
                      value={viewPeriod}
                      setValue={handleOnChange}
                    />
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <FlexBox customStyle={css({ marginRight: "20px" })}>
                    <FlexBoxItem>
                      <CalendarMonth
                        date={new Date(targetMonth)}
                        setDate={(date: any) => {
                          if (new Date(date).getTime() !== new Date(targetMonth).getTime()) {
                            setTargetMonth(date);
                            sessionStorage.setItem("approvalV3TargetMonth", date);
                            checkMonth(date);
                          }
                        }}
                        readOnly={viewPeriod === "daily" || viewPeriod === "dateAll"}
                        openActionChevron={true}
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </Col>
              </FlexBox>
              <FlexBox customStyle={css({ margin: "10px 0" })}>
                <Col lg={2} md={3}>
                  <div style={{ marginTop: "-20px" }}>
                    <RadioSelectForm
                      label=""
                      name="daily"
                      items={[
                        {
                          label: "期間",
                          value: "daily",
                        },
                      ]}
                      value={viewPeriod}
                      setValue={(e) => {
                        setViewPeriod(e.target.value);
                        setIsLoading(true);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <FlexBox>
                    <FlexBoxItem>
                      <CalendarRangeV2
                        startDate={targetDateFrom}
                        onChangeStart={(date: Date) => {
                          sessionStorage.setItem("approvalV3TargetDayFrom", moment(date).format("YYYY-MM-DD"));
                          setTargetDateFrom(date);
                        }}
                        endDate={targetDateTo}
                        onChangeEnd={(date) => {
                          if (date) {
                            sessionStorage.setItem("approvalV3TargetDayTo", moment(date).format("YYYY-MM-DD"));
                            setTargetDateTo(date);
                          }
                        }}
                        validateTime={(startDate, endDate) => {
                          let isValid = moment(endDate).diff(startDate, "year") < 1;
                          if (!isValid) {
                            errorNotification("対象期間の範囲は１年以内で入力してください");
                          }
                          return isValid;
                        }}
                        readOnly={viewPeriod === "monthly" || viewPeriod === "dateAll"}
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </Col>
              </FlexBox>
            </Col>
          </FormPadding>

          <div>
            {/* 要承認 */}
            {selectType === SelectType.applying && <ApplyingTab />}

            {/* 要承認 */}
            {selectType === SelectType.processing && <ProcessingTab />}

            {/* 承認済 */}
            {selectType === SelectType.approved && <ApprovedTab />}

            {/* 差戻済 */}
            {selectType === SelectType.rejected && <RejectedTab />}
          </div>
        </FormContents>
      </attendApplicationApprovalContext.Provider>
    </BlockUI>
  );
};

export default AttendApplicationApprovalV3;
