/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';

import { layer } from 'components/styles';

const styles = {
  box: (left: number, top: number) => css({
    position: 'absolute',
    top,
    left,
    whiteSpace: 'nowrap',
    zIndex: 1,
    cursor: 'pointer',
    boxShadow: layer.layer01,
  }),
};

const DropdownBox: React.FC<{ isVisible: boolean}> = ({ children, isVisible }) => {
  const [boxElement, setBoxElement] = useState<HTMLElement | null>(null);
  const [boxLeft, setBoxLeft] = useState<number>(0);
  const [boxTop, setBoxTop] = useState<number>(0);
  useEffect(() => {
    if (!boxElement) {
      return;
    }
    // 画面外に要素が出る場合に位置を調整する
    const clientRect = boxElement && boxElement.getBoundingClientRect();
    const rightPosition = window.parent.screen.width - (clientRect.right);
    if (rightPosition < 0) {
      setBoxLeft(rightPosition);
    }

    // 親要素に重なっていたら位置を調整する
    const parentElement = boxElement.offsetParent;
    const parentRect = parentElement?.getBoundingClientRect();
    if (!parentRect) {
      return;
    }

    const topPosition = (clientRect.top - parentRect.bottom);
    if (topPosition < 0) {
      setBoxTop(topPosition * -1 + 4);
    } 
    
  }, [boxElement]);

  return (
    <React.Fragment>
      {isVisible && (
        <div css={styles.box(boxLeft, boxTop)} ref={(ref) => setBoxElement(ref)}>
          {children}
        </div>
      )}
    </React.Fragment>
  );
};

export default DropdownBox;
