import React, { SVGProps } from 'react';

const MyList: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.31 0C1.03 0 0 1.03 0 2.31V4.62C0 5.89 1.03 6.93 2.31 6.93H4.62C5.89 6.93 6.93 5.9 6.93 4.62V2.31C6.92 1.03 5.89 0 4.62 0H2.31ZM2.31 4.62V2.31H4.62V4.62H2.31Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.31 11.54C1.03 11.54 0 12.57 0 13.85V16.16C0 17.43 1.03 18.47 2.31 18.47H4.62C5.89 18.47 6.93 17.44 6.93 16.16V13.85C6.93 12.58 5.9 11.54 4.62 11.54H2.31ZM2.31 16.15V13.84H4.62V16.15H2.31Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.31 23.0803C1.03 23.0803 0 24.1103 0 25.3803V27.6903C0 28.9703 1.03 30.0003 2.31 30.0003H4.62C5.89 30.0003 6.93 28.9703 6.93 27.6903V25.3803C6.93 24.1103 5.9 23.0703 4.62 23.0703H2.31V23.0803ZM2.31 27.6903V25.3803H4.62V27.6903H2.31Z" fill="#003993"/>
    <path d="M12.6891 4.60957C12.0491 4.60957 11.5391 4.08957 11.5391 3.45957C11.5391 2.81957 12.0591 2.30957 12.6891 2.30957H40.3791C41.0191 2.30957 41.5291 2.82957 41.5291 3.45957C41.5291 4.09957 41.0091 4.60957 40.3791 4.60957H12.6891Z" fill="#007BC3"/>
    <path d="M12.6891 16.1496C12.0491 16.1496 11.5391 15.6296 11.5391 14.9996C11.5391 14.3596 12.0591 13.8496 12.6891 13.8496H40.3791C41.0191 13.8496 41.5291 14.3696 41.5291 14.9996C41.5291 15.6396 41.0091 16.1496 40.3791 16.1496H12.6891Z" fill="#007BC3"/>
    <path d="M12.6891 27.6896C12.0491 27.6896 11.5391 27.1696 11.5391 26.5396C11.5391 25.8996 12.0591 25.3896 12.6891 25.3896H40.3791C41.0191 25.3896 41.5291 25.9096 41.5291 26.5396C41.5291 27.1796 41.0091 27.6896 40.3791 27.6896H12.6891Z" fill="#007BC3"/>
  </svg>
);

export default MyList;
