import Actions, { types } from './actions';
import { GeneralType } from '.';
import LoginUserDomain from 'domain/loginUser';

const initialState: GeneralType = {
  loginUser: LoginUserDomain.generateInitial(),
};

const reducer = (state = initialState, action: Actions): GeneralType => {
  const newState = { ...state };
  switch (action.type) {
    case types.SET_LOGIN_USER:
      newState.loginUser = action.payload;
      return newState;
    default:
      return state;
  }
};

export default reducer;
