/** @jsx jsx */
import React from 'react';

import { utilityColor } from 'components/styles';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import RadioSelector from 'components/molecules/RadioSelector';
import FileSelectButton from 'components/molecules/FileSelectButton';
import BodyText from 'components/atoms/BodyText';

import { useHistory } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useOrganizationCsvForm } from './hooks';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import LableTitle from 'components/atoms/LableTitle';
import FlexBox from 'components/atoms/FlexBox';
import FormPadding from 'components/atoms/Form/FormPadding';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ImportCSVForm from 'components/molecules/ImportCSVForm';

const style = {
  uploadFileCSV: css({
    height: '105px',
    width: '400px',
    border: '1px solid #dcdcdc',
    borderRadius: '2px',
    backgroundColor: '#f8f8f8',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  }, css`
  span {
    position: absolute;
    left: 95px;
  }
  button {
    margin-left: 35px;
  }
`),
  wrapUpload: css({
    maxWidth: '400px',
    margin: '30px auto 0 auto',
  }),
  wrap: css({
    padding: '15px 30px'
  }),
  noteLabel: css({
    justifyContent: "center",
}),
};

export type UploadType = '0' | '1' | '2';

const StaffCsvImportPage: React.FC = () => {
  const {
    confirmModalOpen, closeConfirmModal, uploadType, setUploadType,
    uploadTypeItems, uploadFile, setUploadFile, onSubmit, errors,blocking
  } = useOrganizationCsvForm();
  const history = useHistory();

  return (
    <BlockUI blocking={blocking}>
      <div css={style.wrap}>
        <FormField>
          <RadioSelector
            name="uploadType"
            items={uploadTypeItems}
            value={uploadType}
            setValue={(e) => setUploadType(e.target.value as UploadType)}
          />
        </FormField>
        <ImportCSVForm
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          errors={errors}
        />

        <FormSubmitArea customStyle={css({ margin: '30px auto', textAlign: 'center' })}>
          <div>
            <PrimaryButton
              text="インポート"
              onClick={onSubmit}
              disabled={!uploadFile}
            />
          </div>

          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.goBack();
            }}
            ghost={true}
          />

        </FormSubmitArea>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={onSubmit}
          actionType={ActionType.IMPORT}
        />
      </div>
    </BlockUI>
  );
};

export default StaffCsvImportPage;
