/** @jsx jsx */
import React, { useState, useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import productColor, { grayScale, textFontSize } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import TransferHolidayStatusDomain from "domain/master/holidayManagement/transferHolidayStatus";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import moment from "moment";

import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import { useHolidayManagementRestForm, useDownloadHolidayManagementRest } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import Calendar from "components/molecules/CalendarV1";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import { getBoolean } from "utility";

export interface DataDetail {
  staffCode: string;
  staffName: string;
  transferDayNumb: string;
}

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  input: css({
    flex: 1,
    width: "100px",
    padding: "7px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: "none",
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px 0 0 4px",
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  selectDay: css({
    minWidth: "100px",
    width: "170px",
  }),
  detail: css({
    backgroundColor: "white",
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
    border: "1px solid #dcdcdc",
  }),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
        font-size: 14px;
      }
    `,
  ),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  note: css({
    paddingLeft: "5px",
    fontSize: "14px",
    color: "#666666",
    position: "relative",
  }),
};

const HolidayManagementRestForm: React.FC<{ orgTreesOptions: any }> = ({ orgTreesOptions }) => {
  const {
    transferHolidayStatusList,
    callGetTransferHolidayStatusDetail,
    orgCode,
    setOrgCode,
    date,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    underFlag,
    setUnderFlag,
  } = useHolidayManagementRestForm();
  const contextObject = useContext(HolidayManagementContext);

  const { downloadHolidayManagementRest, blocking } = useDownloadHolidayManagementRest(
    orgCode || "",
    searchDate || date,
    underFlag || 0,
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: "",
    staffName: "",
    transferDayNumb: "",
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTransferHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callGetTransferHolidayStatusDetail(data.staffCode);
    setModalOpen(true);
  };

  //
  const columns: Array<Column<TransferHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: TransferHolidayStatusDomain };
          data: Array<TransferHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: TransferHolidayStatusDomain };
          data: Array<TransferHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: TransferHolidayStatusDomain };
          data: Array<TransferHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />

            <IconLabelButton
              onClick={() => handleTransferHolidayStatusDetail(cell.row.original)}
              text="編集"
              isIcon={false}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: TransferHolidayStatusDomain };
          data: Array<TransferHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "振替日数",
        accessor: "transferDayNumbStr",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "center" }}>{cell.row.original.transferDayNumbStr}</div>
        ),
      },
    ],
    [handleTransferHolidayStatusDetail],
  );

  return (
    <BlockUI blocking={blocking}>
      <div css={styles.wrapAccordion}>
        <div css={css(styles.form)}>
          <FormContentFilter maxWidth="100%">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                <div css={styles.selectDay}>
                  {/* <CalendarForm required={true} label="" date={new Date(searchDate)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setSearchDate(date)} /> */}
                  <Calendar
                    date={new Date(searchDate || date)}
                    setDate={(date) => {
                      if (date) {
                        setSearchDate(moment(date).format("YYYY/MM/DD"));
                      }
                    }}
                  />
                </div>
                <div css={styles.note}>から過去1年間</div>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode004"
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag12"}
                name={"underFlag12"}
                label={"配下指定"}
                value={`underFlag12`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 100px;
              }
              table th:nth-of-type(2) {
                width: 100px;
              }
              table th:nth-of-type(3) {
                width: 100px;
              }
              table th:nth-of-type(4) {
                width: 100px;
              }
              table th:nth-of-type(5) {
                width: 100px;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementRest()}
                  iconType="download"
                  text="ダウンロード"
                />
                {/* <IconLabelButton onClick={() => downloadHolidayManagementRest(true)} iconType="download" text="全組織ダウンロード" /> */}
              </FormSubmitArea>
            ) : null}
            <div className="table-bordered">
              <DataTable
                columns={columns}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                data={transferHolidayStatusList}
                isGlobalFilter={true}
                globalSearchStyle={styles.globalSearch}
                tableId={8}
              />
            </div>
          </div>

          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="取得状況詳細"
            submitText=""
            onSubmit={closeModal}
            closeText="閉じる"
          >
            <div style={{ width: "350px", margin: "0 auto", fontSize: "14px" }} css={styles.table}>
              <VerticalLabelTextLayout label={`スタッフコード`} variable={dataDetail.staffCode} />
              <VerticalLabelTextLayout label={`スタッフ名`} variable={dataDetail.staffName} />
              <VerticalLabelTextLayout
                label={`期間`}
                variable={`${moment(detailItems.targetDateFrom).format("YYYY年MM月DD日")}～${moment(
                  detailItems.targetDateTo,
                ).format("YYYY年MM月DD日")}`}
              />
              {detailItems.effectiveStartDate && (
                <VerticalLabelTextLayout
                  label="有給付与日"
                  variable={moment(detailItems.effectiveStartDate).format("YYYY年MM月DD日")}
                />
              )}
              <table className="table-bordered" style={{ width: "100%", fontSize: "14px" }}>
                <thead>
                  <tr>
                    <th>振替出勤日</th>
                    <th>振替休日</th>
                  </tr>
                </thead>
                <tbody>
                  {detailItems.attendTransfer &&
                    detailItems.attendTransfer.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{moment(item.targetDate).format("YYYY年MM月DD日")}</td>
                        <td>{moment(item.transferHolidayDate).format("YYYY年MM月DD日")}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </div>
    </BlockUI>
  );
};
export default HolidayManagementRestForm;
