/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

const style = css({
  padding: '10px 12px',
  maxHeight: 'calc(100vh - 45vh)',
  overflow: 'auto',
  width: 'auto',
  margin: 'auto',
  minWidth: '300px',
});

const Body: React.FC = ({ children }) => (
  <div css={style}>
    {children}
  </div>
);

export default Body;
