/** @jsx jsx */
import { useState, useCallback, useMemo } from "react";
import { Column } from "react-table";
import { css, jsx } from "@emotion/core";
import DataTable from "components/organismos/DataTable/DataTable";
import { deleteById } from "api/master36Agreement";
import useToastNotification from "hooks/useToastNotification";
import { useAtreement36Master } from "./hooks";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useHistory } from "react-router-dom";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { SubActionButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import AttendSetting36AgreementDomain from "domain/master/attend/attendSetting36Agreement";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import YearPickerForm from "components/molecules/YearPickerForm";
import BodyText from "components/atoms/BodyText";

const styles = {
  columnOne: css({}),
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
  label1: css({
    flexBasis: "100px",
  }),
};

const MasterSettingAgreement36Page = () => {
  const history = useHistory();
  const {
    agreementList,
    targetYearFrom,
    targetYearTo,
    setAgreementList,
    detailRole,
    setTargetYearFrom,
    setTargetYearTo,
    blocking,
    setBlocking,
  } = useAtreement36Master();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedAgreementMaster, setSelectedAgreementMaster] = useState(
    AttendSetting36AgreementDomain.generateInitial()
  );
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback(
    (agreement: AttendSetting36AgreementDomain) => {
      setConfirmModalOpen(true);
      setSelectedAgreementMaster(agreement);
    },
    []
  );

  const deleteAgreementMaster = useCallback(() => {
    setConfirmModalOpen(false);
    setBlocking(true);
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    deleteById(selectedAgreementMaster.agreementsId)
      .then((response: any) => {
        setAgreementList(
          agreementList.filter(
            (agreement) =>
              agreement.agreementsId !== selectedAgreementMaster.agreementsId
          )
        );
        setBlocking(false);
        successNotification("削除しました。");
      })
      .catch((error) => {
        setBlocking(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage}\n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    selectedAgreementMaster.agreementsId,
    setAgreementList,
    successNotification,
    agreementList,
    setBlocking,
  ]);
  const columns: Array<Column<AttendSetting36AgreementDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: AttendSetting36AgreementDomain;
          };
        }) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FlexBox customStyle={styles.columnOne}>
                <FlexBoxItem marginLeft="5px">
                  <SubActionButton
                    action={0}
                    text={detailRole.editable === 1 ? `編集` : `参照`}
                    onClick={() => {
                      history.push(
                        `/masterSettingAgreement36/edit/${cell.row.original.agreementsId}`
                      );
                    }}
                  />
                </FlexBoxItem>
                {detailRole.editable === 1 ? (
                  <FlexBoxItem marginLeft="5px">
                    <SubActionButton
                      action={1}
                      text="削除"
                      onClick={() => {
                        openConfirmModal(cell.row.original);
                      }}
                    />
                  </FlexBoxItem>
                ) : null}
              </FlexBox>
            </div>
          );
        },
      },
      {
        Header: "コード",
        accessor: "agreementsCode",
      },
      {
        Header: "名称",
        accessor: "agreementsName",
      },
      {
        Header: "対象年度",
        accessor: "targetYear",
      },
      {
        Header: "起算月",
        accessor: "startMonth",
      },
      {
        Header: "適用組織",
        accessor: "orgName",
      },
    ],
    [openConfirmModal, history, detailRole]
  );

  return (
    <SidebarTemplate>
      <BlockUI blocking={blocking}>
        <FormContents>
          <FormTitle bold={true} title="36協定アラートマスタ" />
          <FormField>
            <FlexBox>
              <div
                style={{
                  marginLeft: "40px",
                  marginRight: "30px",
                  width: "150px",
                }}
              >
                <BodyText>対象年度</BodyText>
              </div>
              <div>
                <YearPickerForm
                  name="targetYearFrom"
                  label=""
                  selectedYear={targetYearFrom}
                  onChange={(year: any) => {
                    if (year !== targetYearFrom) {
                      setTargetYearFrom(year);
                      sessionStorage.setItem(
                        "masterAgreement36.targetYearFrom",
                        String(year)
                      );
                    }
                  }}
                />
              </div>
              <span style={{ margin: "0 30px", marginTop: "5px" }}>～</span>
              <YearPickerForm
                name="targetYearTo"
                label=""
                selectedYear={targetYearTo}
                onChange={(year: any) => {
                  if (year !== targetYearTo) {
                    setTargetYearTo(year);
                    sessionStorage.setItem(
                      "masterAgreement36.targetYearTo",
                      String(year)
                    );
                  }
                  setTargetYearTo(year);
                }}
              />
            </FlexBox>
          </FormField>
          <div
            style={{ marginLeft: "27px", width: "80px", paddingBottom: "30px" }}
          >
            {detailRole.editable === 1 && (
              <IconLabelButton
                onClick={() => {
                  history.push("/masterSettingAgreement36/create");
                }}
                iconType="addCircle"
                text="追加"
              />
            )}
          </div>
          <div
            css={css`
              table {
                table-layout: auto;
              }
              table th:nth-of-type(1) {
                width: 100px;
              }
              table th:nth-of-type(2) {
                width: 100px;
                text-align: left;
              }
              table th:nth-of-type(3) {
                width: calc(100% - 440px);
                text-align: left;
              }
              table th:nth-of-type(4) {
                width: 80px;
                text-align: left;
              }
              table th:nth-of-type(5) {
                width: 80px;
                text-align: left;
              }
              table th:nth-of-type(6) {
                width: 80px;
                text-align: left;
              }
            `}
          >
            <DataTable
              columns={columns}
              data={agreementList}
              isGlobalFilter={true}
              globalSearchStyle={styles.filterSearch}
              containerStyle={css({
                overflowX: "auto",
              })}
            />
          </div>
          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            title="36協定アラートマスタを削除"
            content="36協定アラートマスタを削除します。よろしいですか？"
            submitText="削除する"
            onSubmit={deleteAgreementMaster}
            typeSubmit={"reject"}
          />
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default MasterSettingAgreement36Page;
