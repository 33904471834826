import ApiClient from "api/ApiClient";
import {
  IMonthlyShift,
  IDailyShift,
  IShopPlan,
  IDrawingInfo,
  IOrganizationV1,
  ShiftPattern,
  IDrawingInfoDaily,
} from "./type";
import { downloadOriginalPDF } from "api/personalSetting";
import { AxiosResponse } from "axios";
import moment from "moment";

export const getShiftPatterns = async (orgCode: string): Promise<ShiftPattern[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
  };
  let response = await ApiClient.get(`/v1/shift/patterns/available/${companyCode}`, params, {});

  return response.data;
};

export const fetchDrawingInfo = async (
  orgCode: string,
  dateFromStr: string,
  viewPeriod: string = "monthly",
): Promise<IDrawingInfo> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
    dateFromStr,
    viewPeriod,
  };
  let response = await ApiClient.get(`/v1/mws/drawingInfo/${companyCode}`, params, {});

  return response.data;
};

export const fetchDrawingInfoDaily = async (orgCode: string, targetDate: string): Promise<IDrawingInfoDaily> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
    targetDate,
  };
  let response = await ApiClient.get(`/v1/dws/drawingInfo/${companyCode}`, params, {});

  return response.data;
};

export const fetchShopPlan = async (
  targetDateFrom: string,
  targetDateTo: string,
  orgCode: string,
): Promise<IShopPlan[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
  };
  let response = await ApiClient.get(`/v1/shopPlan/${companyCode}`, params, {});

  return response.data;
};

export const fetchMonthlyShift = async (
  targetDateFrom: string,
  targetDateTo: string,
  orgCode: string,
  isAttendExists: string = "0",
  employments: string = "",
  addStaffCodes: any = "",
  pageIndex: number = 0,
  pageItem: number = 20,
): Promise<IMonthlyShift> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    addStaffCodes,
    isAttendExists,
    employments,
    pageIndex,
    pageItem,
    loginStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
  };

  let response = await ApiClient.get(`/v2/mws/info/${companyCode}`, params, {});

  return response.data;
};

export const fetchDailyShift = async (
  orgCode: string,
  targetDate: string,
  isAllEmployment: number,
  isAttendExists: string = "0",
  employments: string = "",
  loginStaffCode: string,
  addStaffCodes: string = "",
  cumulativeClosingDate: string = "0",
): Promise<IDailyShift> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
    targetDate,
    employments,
    addStaffCodes,
    cumulativeClosingDate,
    isAttendExists,
    loginStaffCode,
    isAllEmployment,
  };

  let response = await ApiClient.get(`/v1/dws/info/${companyCode}`, params, {});

  return response.data;
};

// export const fetchOrganizations = async (targetDateFrom?: String, targetDateTo?: String): Promise<IOrganizationV1[]> => {
//   const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
//   const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
//   const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

//   let siteType: String;
//   switch (Number(sessionStorage.getItem("loginUser.site"))) {
//     case 2:
//       siteType = "SHOP"
//       break;
//     case 3:
//       siteType = "HQ"
//       break;
//     default:
//       siteType = "PERSONAL";
//       break;
//   }

//   let params = {
//     orgCode,
//     staffCode,
//     siteType,
//     targetDayFrom: targetDateFrom,
//     targetDayTo: targetDateTo,
//   };

//   let response = await ApiClient.get(`/v2/organizations/trees/${companyCode}`, params, {});

//   return response.data;
// };

export const downloadMonthlyShiftPdf = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  employments: string = "",
  isAttendExists: string = "1",
  useSales: boolean = true,
  filename: string = "monthly_work_schedule",
) => {
  const orgName = orgCode;
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  let params = {
    orgCode,
    orgName,
    targetDateFrom,
    targetDateTo,
    employments,
    useSales,
    isAttendExists,
  };

  let response = await ApiClient.getDownloadFile(`/v1/mws/pdf/${companyCode}`, params, {});

  downloadOriginalPDF(response.data, `${filename}.pdf`);
};

export const downloadDailyShiftPdf = async (
  orgCode: string,
  targetDate: string,
  employments: string = "",
  isAttendExists: string = "1",
  useSales: boolean = true,
  filename: string = "daily_work_schedule",
) => {
  // const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  const orgName = orgCode;
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  let params = {
    orgCode,
    orgName,
    targetDate,
    employments,
    useSales,
    isAttendExists,
  };

  let response = await ApiClient.getDownloadFile(`/v1/dws/pdf/${companyCode}`, params, {});

  downloadOriginalPDF(response.data, `${filename}.pdf`);
};

export const downloadMonthlyShiftExcel = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  employments: string = "",
  isAttendExists: string = "1",
  filename: string = "",
) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  filename = orgCode + "_" + moment(targetDateFrom).format("YYYYMMDD") + "-" + moment(targetDateTo).format("YYYYMMDD");

  let params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    employments,
    isAttendExists,
  };

  let response = await ApiClient.getDownloadFile(`/v1/mws/export/excel/${companyCode}`, params, {});

  const blob = new Blob([response.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
  });
  saveAs(blob, filename);
};

/**
 * シフトインポート（Excel)
 */
export const importFileExcel = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const staffName = sessionStorage.getItem("loginUser.staffName") || "";
  const params = {
    staffCode: staffCode,
    staffName: staffName,
  };
  const response = await ApiClient.postFile(`/v1/mws/import/excel/${companyCode}`, params, "uploadFile", uploadFile);
  return response;
};

/**
 * 丸め処理済シフトパターン取得
 * @param orgCode
 */
export const getRoundShiftPatterns = async (
  orgCode: string,
  employmentId: string,
  targetDate: string,
): Promise<ShiftPattern[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  let params = {
    orgCode,
    employmentId,
    targetDate,
  };
  let response = await ApiClient.get(`/v1/shift/patterns/available/round/${companyCode}`, params, {});

  return response.data;
};
