/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-grid-system';

import { ShiftHopeRate } from 'api/shiftCost';

import { grayScale, textColor, utilityColor } from 'components/styles';
import PrimaryButton from 'components/atoms/Button';
import Panel from 'components/atoms/Panel';
import BodyText from 'components/atoms/BodyText';
import PhotoFrame from 'components/molecules/PhotoFrame';

const ShiftCostInfo: React.FC<{
  shiftSubmit: () => void;
  cost: number;
  shiftHopeRates: Array<ShiftHopeRate>;
  width: number;
}> = ({
  shiftSubmit, cost, shiftHopeRates, width,
}) => (
  <div css={{ width, padding: '0 16px 0 0', boxSizing: 'border-box' }}>
    {/* シフト確定ボタン */}
    <PrimaryButton
      text="シフト確定"
      onClick={shiftSubmit}
      minWidth="100%"
    />

    {/* 今月の人件費率 */}
    <Panel padding="0" margin="0" height="24px" width="100%" customStyle={css({ marginTop: '16px' })}>
      <Container>
        <Row>
          <Col style={{ padding: '8px' }}>
            <BodyText>
              今月の人件費率
            </BodyText>
          </Col>
          <Col css={{ padding: '8px' }}>
            <BodyText size="lg">
              {cost ? String(cost) : '-'}
              %
            </BodyText>
          </Col>
        </Row>
      </Container>
    </Panel>

    {/* 今月の稼ぎ叶え度 */}
    <Panel padding="0" margin="0" height="24px" width="100%" customStyle={css({ marginTop: '16px', overflowY: 'scroll', maxHeight: '542px' })}>
      <BodyText customStyle={css({ padding: '8px' })}>
        今月の稼ぎ叶え度
      </BodyText>

      <Container>
        {shiftHopeRates.map((shiftHope) => (
          <Row
            key={shiftHope.staffCode}
            align="center"
            style={{
              padding: '8px',
            }}
          >
            <Col>
              <PhotoFrame
                src={shiftHope.photoPath}
                size="40px"
                onErrorIconColor={grayScale.gray100}
                isCircle={true}
              />
            </Col>
            <Col>
              <div
                style={{
                  width: 80, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden',
                }}
                title={shiftHope.staffName}
              >
                <BodyText>
                  {shiftHope.staffName}
                </BodyText>
              </div>
              <div>
                <BodyText size="sm">
                  {shiftHope.shiftTimeDisp || '-'}
                  /
                  {shiftHope.hopeTimeDisp || '-'}
                </BodyText>
              </div>
            </Col>
            <Col
              style={{
                marginLeft: 'auto',
                textAlign: 'right',
              }}
            >
              <BodyText
                size="xxl"
                color={
                  !Number.isNaN(Number(shiftHope.hopeRateStr)) && Number(shiftHope.hopeRateStr) > 100
                    ? utilityColor.error
                    : textColor.main
                }
              >
                {shiftHope.hopeRateStr}
                %
              </BodyText>
            </Col>
          </Row>
        ))}
      </Container>
    </Panel>
  </div>
);

export default ShiftCostInfo;
