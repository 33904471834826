/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useState } from 'react';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import { searchDate } from 'api/achievementConfirm';
import {
  getApprovedTransferList,
  getApprovedStampList,
  getApprovedHolidayList,
  getApprovedOverTimeList,
  getApprovedAgreement36List,
  getApprovedHolidayWorkList,
  getApprovedWorkScheduleChangeList,
  getRejectedStampList,
  getRejectedHolidayList,
  getRejectedOverTimeList,
  getRejectedHolidayWorkList,
  getRejectedTransferList,
  getRejectedAgreement36List,
  getRejectedWorkScheduleChangeList,
  getApplyingStampList,
  getApplyingHolidayList,
  getApplyingHolidayWorkList,
  getApplyingOverTimeList,
  getApplyingTransferList,
  getApplyingAgreement36List,
  getApplyingWorkScheduleChangeList
} from './api';
import ApprovedTransferDomain from './domain/approvedTransferDomain';
import ApprovedStampDomain from './domain/approvedStampDomain';
import ApprovedHolidayDomain from './domain/approvedHolidayDomain';
import ApprovedOvertimeDomain from './domain/approvedOvertimeDomain';
import Approved36AgreementDomain from './domain/approved36AgreementDomain';
import ApprovedHolidayWorkDomain from './domain/approvedHolidayWorkDomain';
import ApprovedWorkScheduleChangeDomain from './domain/approvedWorkScheduleChange';
import RejectedStampDomain from './domain/rejectedStampDomain';
import RejectedHolidayDomain from './domain/rejectedHolidayDomain';
import RejectedOvertimeDomain from './domain/rejectedOvertimeDomain';
import RejectedHolidayWorkDomain from './domain/rejectedHolidayWorkDomain';
import RejectedTransferDomain from './domain/rejectedTransferDomain';
import RejectedAgreement36Domain from './domain/rejectedAgreement36Domain';
import RejectedWorkScheduleChangeDomain from './domain/rejectedWorkScheduleChange';
import ApplyingStampDomain from './domain/applyingStampDomain';
import ApplyingHolidayDomain from './domain/applyingHolidayDomain';
import ApplyingOvertimeDomain from './domain/applyingOvertimeDomain';
import ApplyingHolidayWorkDomain from './domain/applyingHolidayWorkDomain';
import ApplyingTransferDomain from './domain/applyingTransferDomain';
import Applying36AgreementDomain from './domain/applying36AgreementDomain';
import ApplyingWorkScheduleChangeAppDomain from './domain/applyingWorkScheduleChangeAppDomain';
import {
  IStamp,
  IHoliday,
  IOvertime,
  IHolidayWork,
  ITransfer,
  I36Agreement,
  IWorkSchedule
} from './interface';

export const useAttendApplicationApproval = () => {
  // Range Time
  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD');
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(new Date(sessionStorage.getItem('applicationTargetDayFrom') || startOfMonth));
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(sessionStorage.getItem('applicationTargetDayTo') || endOfMonth));

  // const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.attendApplicationList);

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  // Applying tab
  const [applyingStampList, setApplyingStampList] = useState<ApplyingStampDomain[]>([]);
  const [applyingHolidayList, setApplyingHolidayList] = useState<ApplyingHolidayDomain[]>([]);
  const [applyingOvertimeList, setApplyingOvertimeList] = useState<ApplyingOvertimeDomain[]>([]);
  const [applyingHolidayWorkList, setApplyingHolidayWorkList] = useState<ApplyingHolidayWorkDomain[]>([]);
  const [applyingTransferList, setApplyingTransferList] = useState<ApplyingTransferDomain[]>([]);
  const [applyingAgreement36List, setApplyingAgreement36List] = useState<Applying36AgreementDomain[]>([]);
  const [applyingWorkScheduleChangeList, setApplyingWorkScheduleChangeList] = useState<ApplyingWorkScheduleChangeAppDomain[]>([]);

  // Approved tab
  const [approvedStampList, setApprovedStampList] = useState<ApprovedStampDomain[]>([]);
  const [approvedHolidayList, setApprovedHolidayList] = useState<ApprovedHolidayDomain[]>([]);
  const [approvedOvertimeList, setApprovedOvertimeList] = useState<ApprovedOvertimeDomain[]>([]);
  const [approvedHolidayWorkList, setApprovedHolidayWorkList] = useState<ApprovedHolidayWorkDomain[]>([]);
  const [approvedTransferList, setApprovedTransferList] = useState<ApprovedTransferDomain[]>([]);
  const [approvedAgreement36List, setApprovedAgreement36List] = useState<Approved36AgreementDomain[]>([]);
  const [approvedWorkScheduleChangeList, setApprovedWorkScheduleChangeList] = useState<ApprovedWorkScheduleChangeDomain[]>([]);

  // Rejected tab
  const [rejectedStampList, setRejectedStampList] = useState<RejectedStampDomain[]>([]);
  const [rejectedHolidayList, setRejectedHolidayList] = useState<RejectedHolidayDomain[]>([]);
  const [rejectedOvertimeList, setRejectedOvertimeList] = useState<RejectedOvertimeDomain[]>([]);
  const [rejectedHolidayWorkList, setRejectedHolidayWorkList] = useState<RejectedHolidayWorkDomain[]>([]);
  const [rejectedTransferList, setRejectedTransferList] = useState<RejectedTransferDomain[]>([]);
  const [rejectedAgreement36List, setRejectedAgreement36List] = useState<RejectedAgreement36Domain[]>([]);
  const [rejectedWorkScheduleChangeList, setRejectedWorkScheduleChangeList] = useState<RejectedWorkScheduleChangeDomain[]>([]);

  const fetchData = async () => {
    setIsLoading(true);

    // let userPaymentDay = []; // empty on subsequent change
    // if (isFirstRender) {
    //   userPaymentDay = await searchDate({ staffCode });
    //   setIsFirstRender(false);
    //   setTargetDateFrom(moment(String(userPaymentDay[0]).replace(/\//g, '-')).toDate());
    //   setTargetDateTo(moment(String(userPaymentDay[1]).replace(/\//g, '-')).toDate());
    // }

    Promise.all([
      fetchApplyingList(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD')),
      fetchApprovedList(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD')),
      fetchRejectedList(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD')),
    ]).then(() => {
      setIsLoading(false);
    });
  };

  const fetchApplyingList = (
    initialDateFrom: string | null = null,
    initialDateTo: string | null = null,
  ) => {
    const params = {
      staffCode,
      targetDateFrom: initialDateFrom
        ? moment(initialDateFrom).format('YYYY-MM-DD')
        : moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: initialDateTo
        ? moment(initialDateTo).format('YYYY-MM-DD')
        : moment(targetDateTo).format('YYYY-MM-DD'),
    };

    Promise.all([
      getApplyingStampList(params),
      getApplyingHolidayList(params),
      getApplyingOverTimeList(params),
      getApplyingHolidayWorkList(params),
      getApplyingTransferList(params),
      getApplyingAgreement36List(params),
      getApplyingWorkScheduleChangeList(params),
    ]).then((result) => {
      const [
        stampList,
        holidayList,
        overtimeList,
        holidayWorkList,
        transferList,
        agreement36List,
        workScheduleChangeList
      ] = result;

      setApplyingStampList(stampList.map((data: IStamp) => new ApplyingStampDomain(data)));
      setApplyingHolidayList(holidayList.map((data: IHoliday) => new ApplyingHolidayDomain(data)));
      setApplyingOvertimeList(overtimeList.map((data: IOvertime) => new ApplyingOvertimeDomain(data)));
      setApplyingHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new ApplyingHolidayWorkDomain(data)));
      setApplyingTransferList(transferList.map((data: ITransfer) => new ApplyingTransferDomain(data)));
      setApplyingAgreement36List(agreement36List.map((data: I36Agreement) => new Applying36AgreementDomain(data)));
      setApplyingWorkScheduleChangeList(workScheduleChangeList.map((data: IWorkSchedule) => new ApplyingWorkScheduleChangeAppDomain(data)));
    });
  };

  const fetchApprovedList = async (
    initialDateFrom: any = null,
    initialDateTo: any = null,
  ) => {
    const params = {
      staffCode,
      targetDateFrom: initialDateFrom
        ? moment(initialDateFrom).format('YYYY-MM-DD')
        : moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: initialDateTo
        ? moment(initialDateTo).format('YYYY-MM-DD')
        : moment(targetDateTo).format('YYYY-MM-DD'),
    };

    await fetchApprovedStampList(params);
    await fetchApprovedHolidayList(params);
    await fetchApprovedOvertimeList(params);
    await fetchApprovedAgreement36List(params);
    await fetchApprovedHolidayWorkList(params);
    await fetchApprovedTransferList(params);
    await fetchApprovedWorkScheduleChangeList(params);
  };

  const fetchApprovedStampList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const stampList = await getApprovedStampList(params);
    setApprovedStampList(stampList.map((data: IStamp) => new ApprovedStampDomain(data)));
  };
  const fetchApprovedHolidayList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayList = await getApprovedHolidayList(params);
    setApprovedHolidayList(holidayList.map((data: IHoliday) => new ApprovedHolidayDomain(data)));
  };
  const fetchApprovedOvertimeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const overtimeList = await getApprovedOverTimeList(params);
    setApprovedOvertimeList(overtimeList.map((data: IOvertime) => new ApprovedOvertimeDomain(data)));
  };
  const fetchApprovedAgreement36List = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const agreement36List = await getApprovedAgreement36List(params);
    setApprovedAgreement36List(agreement36List.map((data: I36Agreement) => new Approved36AgreementDomain(data)));
  };
  const fetchApprovedWorkScheduleChangeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const workScheduleChangeList = await getApprovedWorkScheduleChangeList(params);
    setApprovedWorkScheduleChangeList(workScheduleChangeList.map((data: IWorkSchedule) => new ApprovedWorkScheduleChangeDomain(data)));
  };
  const fetchApprovedHolidayWorkList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayWorkList = await getApprovedHolidayWorkList(params);
    setApprovedHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new ApprovedHolidayWorkDomain(data)));
  };
  const fetchApprovedTransferList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const transferList = await getApprovedTransferList(params);
    setApprovedTransferList(transferList.map((data: ITransfer) => new ApprovedTransferDomain(data)));
  };

  const fetchRejectedList = async (
    initialDateFrom: any = null,
    initialDateTo: any = null,
  ) => {
    const params = {
      staffCode,
      targetDateFrom: initialDateFrom
        ? moment(initialDateFrom).format('YYYY-MM-DD')
        : moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: initialDateTo
        ? moment(initialDateTo).format('YYYY-MM-DD')
        : moment(targetDateTo).format('YYYY-MM-DD'),
    };

    await fetchRejectedStampList(params);
    await fetchRejectedHolidayList(params);
    await fetchRejectedOvertimeList(params);
    await fetchRejectedAgreement36List(params);
    await fetchRejectedHolidayWorkList(params);
    await fetchRejectedTransferList(params);
    await fetchRejectedWorkScheduleChangeList(params);
  };

  const fetchRejectedStampList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const stampList = await getRejectedStampList(params);
    setRejectedStampList(stampList.map((data: IStamp) => new RejectedStampDomain(data)));
  };
  const fetchRejectedHolidayList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayList = await getRejectedHolidayList(params);
    setRejectedHolidayList(holidayList.map((data: IHoliday) => new RejectedHolidayDomain(data)));
  };
  const fetchRejectedOvertimeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const overtimeList = await getRejectedOverTimeList(params);
    setRejectedOvertimeList(overtimeList.map((data: IOvertime) => new RejectedOvertimeDomain(data)));
  };
  const fetchRejectedHolidayWorkList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayWorkList = await getRejectedHolidayWorkList(params);
    setRejectedHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new RejectedHolidayWorkDomain(data)));
  };
  const fetchRejectedTransferList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const transferList = await getRejectedTransferList(params);
    setRejectedTransferList(transferList.map((data: ITransfer) => new RejectedTransferDomain(data)));
  };
  const fetchRejectedAgreement36List = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const agreement36List = await getRejectedAgreement36List(params);
    setRejectedAgreement36List(agreement36List.map((data: I36Agreement) => new RejectedAgreement36Domain(data)));
  };
  const fetchRejectedWorkScheduleChangeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      };
    }
    const workScheduleChangeList = await getRejectedWorkScheduleChangeList(params);
    setRejectedWorkScheduleChangeList(workScheduleChangeList.map((data: IWorkSchedule) => new RejectedWorkScheduleChangeDomain(data)));
  };

  return {
    isLoading,
    setIsLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,
    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,

    fetchData,
    fetchApplyingList,

    fetchApprovedList,
    fetchApprovedStampList,
    fetchApprovedHolidayList,
    fetchApprovedOvertimeList,
    fetchApprovedHolidayWorkList,
    fetchApprovedTransferList,
    fetchApprovedAgreement36List,
    fetchApprovedWorkScheduleChangeList,

    fetchRejectedList,
    fetchRejectedStampList,
    fetchRejectedHolidayList,
    fetchRejectedOvertimeList,
    fetchRejectedHolidayWorkList,
    fetchRejectedTransferList,
    fetchRejectedAgreement36List,
    fetchRejectedWorkScheduleChangeList,

    detailRole
  };
};

export default useAttendApplicationApproval;
