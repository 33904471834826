/** @jsx jsx */
import React from "react";
import moment from "moment";
import { css, jsx } from "@emotion/core";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const SpecialHolidayGrantTable: React.FC<{
  specialHolidayGrantList: any;
}> = ({ specialHolidayGrantList }) => (
  <div
    css={css`
      table thead tr th {
        height: 46px;
      }
      table tbody tr td {
        height: 32px;
      }
    `}
  >
    <table
      id="specialHolidayGrantList"
      className="table table-bordered table-condensed"
      cellSpacing="0"
      style={{ whiteSpace: "nowrap", width: "100%" }}
    >
      <tbody>
        {specialHolidayGrantList?.map((specialHoliday: any) => (
          <React.Fragment>
            <tr role="row">
              <td colSpan={2} style={{ width: "auto" }}>
                <div css={[thStyle, css({ textAlign: "center" })]}>{specialHoliday.holidayName}</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>付与数</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {specialHoliday.effectiveStartDate === null ? "-" : `${specialHoliday.grantDaysNumSum}日`}
                </div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>消化数</div>
              </td>
              <td>
                <div css={tdStyle}>{specialHoliday.digestedDaysNum}日</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>残日数</div>
              </td>
              <td>
                <div css={tdStyle}>{specialHoliday.remainDaysNum}日</div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>有効期間(開始)</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {specialHoliday.useStartDate == null
                    ? "-"
                    : moment(specialHoliday.useStartDate).format("YYYY年MM月DD日")}
                </div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>有効期間(終了)</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {specialHoliday.useEndDate == null
                    ? "-"
                    : moment(specialHoliday.useEndDate).format("YYYY年MM月DD日")}
                </div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>次回付与日</div>
              </td>
              <td>
                <div css={tdStyle}>
                  {specialHoliday.nextGrantDate == null
                    ? ""
                    : moment(specialHoliday.nextGrantDate).format("YYYY年MM月DD日")}
                </div>
              </td>
            </tr>
            <tr role="row">
              <td style={{ width: "129px" }}>
                <div css={thStyle}>次回予定付与日数</div>
              </td>
              <td>
                <div css={tdStyle}>{specialHoliday.nextGrantDays}日</div>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
);
export default SpecialHolidayGrantTable;
