/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormLabel from "components/atoms/Form/FormLabel";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import IconLabelButton from "components/molecules/IconLabelButton";
import Modal from "components/molecules/Modal";
import DataTable from "components/organismos/DataTable/DataTable";
import { grayScale } from "components/styles";
import React, { useContext } from "react";
import { useApplyingTransferForm } from "../ApplyingTab/hook";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import { attendApplicationApprovalContext } from "../hooks";

const StampAccordion: React.FC = () => {
  const context = useContext(attendApplicationApprovalContext);
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLog,
  } = useApplyingTransferForm(context.approvedStampList);

  const columns = React.useMemo(
    () => [
      {
        Header: "承認日時",
        accessor: "approvalDate",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: () => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <div>
              <div>{cell.row.original.targetDate}</div>
              <IconLabelButton
                onClick={() => {
                  fetchChangeLog(cell.row.original.modifierApplicationId);
                  setChangeLogModalOpen(true);
                  setSelectChangelog("0");
                }}
                text="更新履歴"
                textAlign="center"
                isIcon={false}
              />
            </div>
          </div>
        ),
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "出勤組織",
        accessor: "stampOrgName",
      },
      {
        Header: "開始時間", // starttime
        accessor: "stampListStartTime", // .startTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>{cell.row.original.stampListStartTime}</div>
        ),
      },
      {
        Header: "終了時間", // endtime
        accessor: "stampListEndTime", // .endTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>
            {cell.row.original.stampListEndTime && cell.row.original.stampListEndTime}
          </div>
        ),
      },
      // {
      //   Header: '業務内容', // business content
      //   accessor: 'stampListBusinessName', // .businessName
      //   Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
      //     <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
      //       {cell.row.original.stampListBusinessName}
      //     </div>
      //   ),
      // },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchChangeLog, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <div
      css={css`
        table th:nth-of-type(1) {
          width: 120px;
        }
        table th:nth-of-type(2) {
          width: 140px;
        }
        table th:nth-of-type(3) {
          width: 140px;
        }
        table th:nth-of-type(4) {
          width: 120px;
        }
        table th:nth-of-type(5) {
          width: 140px;
        }
        table th:nth-of-type(6) {
          width: 120px;
        }
        table th:nth-of-type(7) {
          width: 80px;
        }
        table th:nth-of-type(8) {
          width: 120px;
        }
        table th:nth-of-type(9) {
          width: 120px;
        }
        table th:nth-of-type(10) {
          width: 120px;
        }
        table th:nth-of-type(11) {
          width: 200px;
        }

        .modal-table th:nth-of-type(1) {
          width: 25%;
        }
        .modal-table th:nth-of-type(2) {
          width: 25%;
        }
        .modal-table th:nth-of-type(3) {
          width: 25%;
        }
        .modal-table th:nth-of-type(4) {
          width: 25%;
        }
      `}
    >
      <Accordion text={`打刻修正申請 (${context.approvedStampList.length || 0}件）`} callBack={() => {}}>
        <FlexBox customStyle={css({ padding: "10px 45px 15px 35px" })}>
          <FlexBoxItem marginRight="15px">
            <FormLabel label="申請事由" />
          </FlexBoxItem>
          <FlexBoxItem basis="300px">
            <VerticalLabelSelectForm
              label=""
              name="reasonId"
              value={String(context.approvedReasonId || "")}
              setValue={(v) => {
                context.setApprovedReasonId(v);
                context.setTypeReason("approved");
              }}
              options={context.reasonOptionList}
              isSearchable={false}
            />
          </FlexBoxItem>
        </FlexBox>

        <DataTable
          columns={columns}
          containerStyle={css({
            overflowX: "auto",
            marginTop: "15px",
          })}
          data={context.approvedStampList}
          isGlobalFilter={true}
          sortBy={[
            { id: "targetDate", desc: true },
            { id: "targetPerson", desc: true },
          ]}
        />
      </Accordion>

      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} isStamp={true} />
        </Modal>
      </div>
      {/* ./Change log  */}
    </div>
  );
};

export default StampAccordion;
