/** @jsx jsx */
import React, { useState } from "react";
import FormLabel from "components/atoms/Form/FormLabel";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, {
  PrimaryButton,
  SecondaryButton,
} from "components/atoms/Button";
import HourInput from "components/atoms/Form/HourInput";
import productColor, { grayScale, textFontSize } from "components/styles";
import ConfirmModal from "components/organismos/ConfirmModal";
import Modal from "components/molecules/Modal";
import { css, jsx } from "@emotion/core";
import { useHistory } from "react-router-dom";
import { usePaidHolidayPatternDomainAddForm } from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import { deleteById } from "api/paidGrantPattern";
import useToastNotification from "hooks/useToastNotification";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectFormV2 from "components/molecules/SelectFormV2";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

// create style form
const styles = {
  centerData: css({
    fontWeight: "inherit",
    textAlign: "center",
    border: "1px solid #DCDCDC",
    borderTop: "none",
    borderLeft: "none",
    padding: "10px",
  }),
  tableContainer: css({
    overflowX: "auto",
    marginLeft: "8px",
    marginRight: "8px",
  }),
  table: css({
    borderLeft: "1px solid #DCDCDC",
    width: "100%",
    borderSpacing: "0",
    fontSize: textFontSize.re,
    whiteSpace: "pre-wrap",
    tableLayout: "auto",
  }),
  topHeader: css({
    textAlign: "center",
    border: "1px solid #DCDCDC",
    borderLeft: "none",
    padding: "10px",
    minWidth: "100px",
  }),
  leftHeader: css({
    textAlign: "left",
    border: "1px solid #DCDCDC",
    borderLeft: "none",
    padding: "10px",
    width: "600px",
  }),
  leftSecondHeader: css({
    textAlign: "left",
    border: "1px solid #DCDCDC",
    borderLeft: "none",
    padding: "10px",
    borderTop: "none",
  }),
};

const PaidHolidayPatternInputAddForm: React.FC<{
  paidHolidayPatternId: string;
}> = ({ paidHolidayPatternId }) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    optionsStartMonths,
    arrStartMonth,
    valuePatternDetails,
    setValuePatternDetails,
    isOpenDeleteModal,
    closeDeleteModal,
  } = usePaidHolidayPatternDomainAddForm(paidHolidayPatternId);
  const { successNotification, errorNotification } = useToastNotification();
  // history
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { detailRole } = useGetDetailRole(
    setLoading,
    functionCode.masterPaidHolidayPattern
  );

  /**
   *  handle on change input pattern details
   */
  const handleChangePatternDetails = async (e: any) => {
    const checkName = e.target.name.split("_");
    if (checkName[1]) {
      const items = [...valuePatternDetails];
      (items as any)[checkName[1]][checkName[0]] = e.target.value;
      setValuePatternDetails(items);
    }
  };

  const deletePaidGrantpattern = () => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    setLoading(true);
    deleteById(companyCode, paidHolidayPatternId)
      .then((Response: any) => {
        setLoading(false);
        successNotification("削除しました。");
        history.goBack();
      })
      .catch((error) => {
        setLoading(false);
        closeDeleteModal(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  };

  /**
   *  handle back
   */
  const handleBack = async () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <BlockUI blocking={isLoading}>
        <FormTitle
          bold={true}
          title={`${
            paidHolidayPatternId
              ? "有給付与パターン編集"
              : "有給付与パターン作成"
          }`}
        />
        <form onSubmit={formik.handleSubmit}>
          <div>
            <FormPadding>
              <FormField>
                <TextForm
                  name="paidHolidayPatternCode"
                  label="パターンコード"
                  value={formik.values.paidHolidayPatternCode}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.paidHolidayPatternCode}
                  inputCenter={true}
                />
              </FormField>

              <FormField>
                <TextForm
                  name="paidHolidayPatternName"
                  label="パターン名"
                  value={formik.values.paidHolidayPatternName}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.paidHolidayPatternName}
                  inputCenter={true}
                />
              </FormField>

              <FormField>
                <div style={{ margin: "0 auto", width: "40%" }}>
                  <FormLabel label="付与開始勤務月数" />
                  <FormField>
                    <SelectFormV2
                      label=""
                      name="grantStartMonths"
                      value={String(formik.values.grantStartMonths)}
                      setValue={(grantStartMonths) => {
                        formik.setFieldValue(
                          "grantStartMonths",
                          grantStartMonths
                        );
                      }}
                      width="100px"
                      isHiddenLabel={true}
                      options={optionsStartMonths}
                    />
                    <FormLabel
                      customStyle={css({ paddingTop: "12px" })}
                      label="カ月"
                    />
                  </FormField>
                </div>
              </FormField>
            </FormPadding>

            <div css={styles.tableContainer}>
              <table css={styles.table}>
                <thead>
                  <tr
                    style={{
                      height: "40px",
                    }}
                  >
                    <th css={styles.topHeader} rowSpan={2}>
                      週所定労働日数
                    </th>
                    <th css={styles.topHeader} rowSpan={2}>
                      年所定労働日数
                    </th>
                    <th css={styles.leftHeader} colSpan={7}>
                      継続勤務年数
                    </th>
                  </tr>
                  <tr
                    style={{
                      height: "40px",
                    }}
                  >
                    {arrStartMonth.map((item) => (
                      <th css={styles.leftSecondHeader}>
                        {Number(formik.values.grantStartMonths) === 0
                          ? item[0]
                          : item[6]}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {valuePatternDetails.map(
                    (itemPatternDetail: any, key_patternDetail: any) => (
                      <tr
                        style={{
                          backgroundColor: "#ffffff",
                          textAlign: "center",
                        }}
                      >
                        <td css={styles.centerData}>
                          {itemPatternDetail.id.weeklyWorkingDays} 日
                        </td>
                        <td css={styles.centerData}>
                          {itemPatternDetail.yearlyWorkingDaysFrom}
                          日〜
                          {itemPatternDetail.yearlyWorkingDaysTo === 0
                            ? ""
                            : `${itemPatternDetail.yearlyWorkingDaysTo}日`}
                        </td>
                        {arrStartMonth.map((itemStartMonth, key_startMonth) => (
                          <td css={styles.centerData}>
                            <HourInput
                              name={`continuedServiceYears${key_startMonth}_${key_patternDetail}`}
                              value={Number(
                                itemPatternDetail[
                                  `continuedServiceYears${key_startMonth}`
                                ]
                              )}
                              label="日"
                              onChange={handleChangePatternDetails}
                              min={0}
                              max={99}
                            />
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <FormSubmitArea
              customStyle={css({ textAlign: "center", margin: "15px 0" })}
            >
              {detailRole.editable === 1 && (
                <div>
                  <Button
                    text={`${paidHolidayPatternId ? "更新" : "登録"}`}
                    onClick={formik.handleSubmit}
                    minWidth="120px"
                  />

                  {paidHolidayPatternId && (
                    <SecondaryButton
                      text={"削除"}
                      onClick={() => closeDeleteModal(true)}
                      minWidth="120px"
                      customStyle={css({ marginLeft: "10px" })}
                    />
                  )}
                </div>
              )}
              <PrimaryButton
                text="キャンセル"
                onClick={() => handleBack()}
                ghost={true}
              />
            </FormSubmitArea>
          </div>

          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={formik.handleSubmit}
            title={`${
              paidHolidayPatternId
                ? "有給付与パターンマスタを更新"
                : "有給付与パターンマスタを登録"
            }`}
            content={`${
              paidHolidayPatternId
                ? "有給付与パターンマスタを更新します。よろしいですか？"
                : "有給付与パターンマスタを登録します。よろしいですか？"
            }`}
            submitText={paidHolidayPatternId ? "更新する" : "登録する"}
          />

          <Modal
            typeSubmit="reject"
            open={isOpenDeleteModal}
            closeHandler={() => {
              closeDeleteModal(false);
            }}
            title="有給付与パターンマスタを削除"
            submitText="削除する"
            onSubmit={deletePaidGrantpattern}
          >
            <div
              style={{
                textAlign: "center",
                color: grayScale.gray60,
                fontSize: textFontSize.re,
              }}
            >
              有給付与パターンマスタを削除します。よろしいですか？
            </div>
          </Modal>
        </form>
      </BlockUI>
    </React.Fragment>
  );
};

export default PaidHolidayPatternInputAddForm;
