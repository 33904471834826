/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { useLocation, useHistory, useParams } from "react-router-dom";

const DeliousAuthPage: React.FC = () => {
  const { companyCode } = useParams<any>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();

  const token = query.get("token");
  if (token) {
    localStorage.setItem("DELIOUS_TOKEN", token);
    sessionStorage.setItem("loginUser.companyCode", companyCode ?? "");
    history.replace("/");
  }

  return <div></div>;
};

export default DeliousAuthPage;
