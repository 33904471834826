/** @jsx jsx */

import React, { PropsWithChildren } from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import Icon from "components/atoms/Icon";

const styles = {
  menuContainer: css({
    position: "fixed",
    top: 64,
    left: 0,
    height: "100svh",
    backgroundColor: "#F7FCFE",
    zIndex: 99,
    transition: "0.5s",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    overflowX: "hidden",
    transform: "translateX(0)",
  }),
};

export const MenuItem = styled.div({
  maxWidth: "146px",
  padding: "12px 0 12px 12px",
  color: "black",
  cursor: "pointer",
  borderBottom: "1px solid #ddd",
  "&:hover": {
    backgroundColor: "#E0FFFF",
  },
});

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  handleOpen: (width: number) => void;
};

const SideMenu: React.FC<PropsWithChildren<Props>> = ({ children, handleOpen }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      css={[
        styles.menuContainer,
        css({
          width: isOpen ? "292px" : "46px",
          animation: "0.5s forwards",
        }),
      ]}
    >
      <div
        css={css({
          padding: "12px 0",
        })}
        onClick={() => {
          setIsOpen((prev) => {
            handleOpen(!prev ? 292 : 46);

            return !prev;
          });
        }}
      >
        <Icon type={isOpen ? "chevronLeft" : "chevronRight"} size="32px" color="#666" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SideMenu;
