import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendCSVOutputForm from 'components/organismos/master/attend/AttendCSVOutputForm';

const AttendHelpListPage: React.FC = () => (
  <SidebarTemplate pageTitle="他組織支援">
    <AttendCSVOutputForm />
  </SidebarTemplate>
);

export default AttendHelpListPage;
