import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';

const useItemsSalesOrderlyReport = (orgCode: string, monthlyStartDate: string, startTime:string,
  monthlyEndDate: string, endTime:string, flag:number) => {
  const [getItemsSalesOrder, setItemsSalesOrder] = useState<Array<MonthlyReportDomain>>([]);
  const onSubmit = () => {};
  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    let path = 'salesmanagement/abcanalysis/item/sales';

    if (flag === 2) {
      path = 'salesmanagement/abcanalysis/item/quantity';
    } else if (flag === 3) {
      path = 'salesmanagement/abcanalysis/category/sales';
    } else if (flag === 4) {
      path = 'salesmanagemet/abcanalysis/category/quantity';
    }

    const dateOfweek = [
      {
        itemPub: formik.values.itemPub,
        itemSat: formik.values.itemSat,
        itemSun: formik.values.itemSun,
        itemMon: formik.values.itemMon,
        itemTue: formik.values.itemTue,
        itemWed: formik.values.itemWed,
        itemTur: formik.values.itemTur,
        itemFri: formik.values.itemFri,
      },
    ];
    setIsLoading(true);
    api.getItemsSalesOrderReport(companyCode, orgCode, monthlyStartDate, startTime, monthlyEndDate, endTime, path, dateOfweek).then((response: any) => {
      if (isSubscribed) {
        setItemsSalesOrder(response);
      }
      setIsLoading(false);
    });

    return () => { isSubscribed = false; };
  }, [orgCode, monthlyStartDate, flag, formik.values.itemPub, formik.values.itemSat, formik.values.itemSun, formik.values.itemMon, formik.values.itemTue, formik.values.itemWed, formik.values.itemTur, formik.values.itemFri, startTime, monthlyEndDate, endTime]);
  return {
    getItemsSalesOrder, formik, getIsLoading,
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useDownload = () => {
  const downloadMonthlyReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '商品別ABC売上順.csv');
  };

  const downloadMonthlyReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '商品別ABC売上順.xlsx');
  };

  const downloadCntOrderReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '商品別ABC数量順.csv');
  };

  const downloadCntOrderReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '商品別ABC数量順.xlsx');
  };

  const downloadCategorySalesOrderReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '部門別ABC売上順.csv');
  };

  const downloadCategorySalesOrderReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '部門別ABC売上順.xlsx');
  };

  const downloadCategoryCntOrderReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '部門別ABC数量順.csv');
  };

  const downloadCategoryCntOrderReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '部門別ABC数量順.xlsx');
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadCntOrderReportCsv,
    downloadCntOrderReportExcel,
    downloadCategorySalesOrderReportCsv,
    downloadCategorySalesOrderReportExcel,
    downloadCategoryCntOrderReportCsv,
    downloadCategoryCntOrderReportExcel,
  };
};

export default useItemsSalesOrderlyReport;
