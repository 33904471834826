import {
  useState, useCallback, useEffect, useMemo,
} from 'react';

import { useFormik } from 'formik';
import PaidHolidayPatternDomain from 'domain/master/attend/paidHolidayPattern';
import { savePaidHolidayPattern, getPaidHolidayPattern } from 'api/paidGrantPattern';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';

type PaidHolidayPatternDomainKey = keyof Pick<PaidHolidayPatternDomain, 'paidHolidayPatternCode'|'paidHolidayPatternName'>;

export const usePaidHolidayPatternDomainAddForm = (paidHolidayPatternId: string) => {
  //
  const history = useHistory();
  const { successNotification, errorNotification } = useToastNotification();
  const [isOpenDeleteModal, closeDeleteModal] = useState(false);

  /**
   * create list start month
   */
  const arrStartMonth = [
    { 0: '0年', 6: '6ヶ月' },
    { 0: '1年', 6: '1年6ヶ月' },
    { 0: '2年', 6: '2年6ヶ月' },
    { 0: '3年', 6: '3年6ヶ月' },
    { 0: '4年', 6: '4年6ヶ月' },
    { 0: '5年', 6: '5年6ヶ月' },
    { 0: '6年以上', 6: '6年6ヶ月以上' },
  ];

  // value pattern Details
  const [valuePatternDetails, setValuePatternDetails] = useState(
    [
      {
        id: { weeklyWorkingDays: 5, paidHolidayPatternId: 0 }, yearlyWorkingDaysFrom: 217, yearlyWorkingDaysTo: 0, continuedServiceYears0: 0, continuedServiceYears1: 0, continuedServiceYears2: 0, continuedServiceYears3: 0, continuedServiceYears4: 0, continuedServiceYears5: 0, continuedServiceYears6: 0,
      },
      {
        id: { weeklyWorkingDays: 4, paidHolidayPatternId: 0 }, yearlyWorkingDaysFrom: 169, yearlyWorkingDaysTo: 216, continuedServiceYears0: 0, continuedServiceYears1: 0, continuedServiceYears2: 0, continuedServiceYears3: 0, continuedServiceYears4: 0, continuedServiceYears5: 0, continuedServiceYears6: 0,
      },
      {
        id: { weeklyWorkingDays: 3, paidHolidayPatternId: 0 }, yearlyWorkingDaysFrom: 121, yearlyWorkingDaysTo: 168, continuedServiceYears0: 0, continuedServiceYears1: 0, continuedServiceYears2: 0, continuedServiceYears3: 0, continuedServiceYears4: 0, continuedServiceYears5: 0, continuedServiceYears6: 0,
      },
      {
        id: { weeklyWorkingDays: 2, paidHolidayPatternId: 0 }, yearlyWorkingDaysFrom: 73, yearlyWorkingDaysTo: 120, continuedServiceYears0: 0, continuedServiceYears1: 0, continuedServiceYears2: 0, continuedServiceYears3: 0, continuedServiceYears4: 0, continuedServiceYears5: 0, continuedServiceYears6: 0,
      },
      {
        id: { weeklyWorkingDays: 1, paidHolidayPatternId: 0 }, yearlyWorkingDaysFrom: 48, yearlyWorkingDaysTo: 72, continuedServiceYears0: 0, continuedServiceYears1: 0, continuedServiceYears2: 0, continuedServiceYears3: 0, continuedServiceYears4: 0, continuedServiceYears5: 0, continuedServiceYears6: 0,
      },
    ],
  ) as any;

  //
  const optionsStartMonths = [
    {
      label: '0',
      value: '0',
    },
    {
      label: '6',
      value: '6',
    },
  ];

  //
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  //
  const [toastModalOpen, setToastModalOpen] = useState(false);

  // TODO companyCodeの取得処理が必要
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  /*
  * onSubmit
  */
  const onSubmit = async (values: PaidHolidayPatternDomain) => {
    if (values.paidHolidayPatternCode === null) {
      errorNotification('パターンコードを入力してください。');
      return;
    }
    if (values.paidHolidayPatternName === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const dataPost = values.getRawData();

    //
    if ((dataPost as any).patternDetails) {
      //
      delete (dataPost as any).patternDetails;

      //
      delete (dataPost as any).createDate;
      delete (dataPost as any).updateDate;
      delete (dataPost as any).companyCode;
    }

    //
    let convertData = {
      pattern: dataPost,
    };

    //
    const staffName = sessionStorage.getItem('loginUser.staffName') || '';
    convertData = {
      ...convertData,
      [`pattern.${'createUser'}`]: staffName,
      [`pattern.${'updateUser'}`]: staffName,

    };

    valuePatternDetails.forEach((itemPatternDetail: any, key_patternDetail: any) => {
      arrStartMonth.forEach((itemStartMonth, key_startMonth) => {
        convertData = {
          ...convertData,
          [`patternDetails[${key_patternDetail}].continuedServiceYears${key_startMonth}`]: itemPatternDetail[`continuedServiceYears${key_startMonth}`],
        };
      });
      convertData = {
        ...convertData,
        [`patternDetails[${key_patternDetail}].id.weeklyWorkingDays`]: itemPatternDetail.id.weeklyWorkingDays,
        [`patternDetails[${key_patternDetail}].yearlyWorkingDaysFrom`]: itemPatternDetail.yearlyWorkingDaysFrom,
        [`patternDetails[${key_patternDetail}].yearlyWorkingDaysTo`]: itemPatternDetail.yearlyWorkingDaysTo,
        [`patternDetails[${key_patternDetail}].createUser`]: staffName,
        [`patternDetails[${key_patternDetail}].updateUser`]: staffName,
        [`patternDetails[${key_patternDetail}].id.paidHolidayPatternId`]: paidHolidayPatternId,
      };
    });

    try {
      const response = await savePaidHolidayPattern(companyCode, convertData);
      if (!response.errors) {
        sessionStorage.setItem('currentTab', '4');
        successNotification('登録しました。');
        history.goBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: PaidHolidayPatternDomain.generateInitial(),
    onSubmit,
    validationSchema: false,
    validateOnChange: false,
  });

  //
  useEffect(() => {
    //
    if (paidHolidayPatternId !== undefined) {
      getPaidHolidayPattern(companyCode, paidHolidayPatternId).then((response: any) => {
        if (response) {
          formik.setValues(new PaidHolidayPatternDomain(response));
          if (response.patternDetails) {
            setValuePatternDetails(response.patternDetails);
          }
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paidHolidayPatternId]);

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const confirmModalItems = useMemo(() => ([
    {
      key: 'パターンコード',
      value: formik.values.paidHolidayPatternCode,
    },
    {
      key: 'パターン名',
      value: formik.values.paidHolidayPatternName,
    },
    {
      key: '付与開始勤務月数',
      value: Number(formik.values.grantStartMonths) === 0 ? optionsStartMonths[0].label : optionsStartMonths[1].label,
    },
  ]), [formik.values, optionsStartMonths]);

  // 詳細項目入力を表示するかどうか
  const [detailMode, setDetailMode] = useState(false);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    confirmModalItems,
    toastModalOpen,
    // toastModalTitle,
    closeToastModal,
    optionsStartMonths,
    valuePatternDetails,
    setValuePatternDetails,
    arrStartMonth,
    isOpenDeleteModal,
    closeDeleteModal
  };
};

export default {
  usePaidHolidayPatternDomainAddForm,
};
