/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import PaginationCustom, { PaginationProps } from './Pagination';

const styles = {
  controllbar: css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    // whiteSpace: 'nowrap',
    // overflowX: 'auto',
  }),
  actionButton: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  pagenation: css({
    marginLeft: 'auto',
  }),
};

interface ControllbarProps {
  pagination: PaginationProps;
  isGlobalFilter: boolean;
  tableId: number;
  onClickPagination?:() => void
}

const ControllbarPagination: React.FC<ControllbarProps> = ({ pagination, tableId, onClickPagination }) => (
  <div css={styles.controllbar}>
    <div css={styles.pagenation}>
      <PaginationCustom
        total={pagination.total}
        pageSize={pagination.pageSize}
        pageIndex={pagination.pageIndex}
        pageCount={pagination.pageCount}
        nextPage={pagination.nextPage}
        canNextPage={pagination.canNextPage}
        previousPage={pagination.previousPage}
        canPreviousPage={pagination.canPreviousPage}
        setPageSize={pagination.setPageSize}
        gotoPage={pagination.gotoPage}
        tableId={tableId}
        onClickPagination={onClickPagination}
      />
    </div>
  </div>
);

export default ControllbarPagination;
