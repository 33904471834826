import { useState, useEffect } from "react";

import { OptionType } from "components/atoms/Select";
import { getList } from "api/attendAllowance";
import { AttendAllowance } from "domain/attendAllowance";

/**
 * 雇用手当の選択ボックス用データを取得
 */
export const useEmploymentAllowances = (): Array<OptionType> => {
  const [employmentAllowances, setEmploymentAllowances] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

    getList(companyCode).then((allowances) => {
      setEmploymentAllowances(
        allowances.map((allowance: AttendAllowance) => ({
          value: allowance.allowanceId,
          label: allowance.allowanceName,
        })),
      );
    });
  }, []);
  return employmentAllowances;
};

export default useEmploymentAllowances;
