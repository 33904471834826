import React from "react";
import FontAwesome from "react-fontawesome";

function ButtonArrow({ direction, onClick }) {
  let arrowClass = direction === "forward" ? "calendar__arrow--right" : "calendar__arrow--left";
  function handlerClick(e) {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  }

  return (
    // <button className={`nav-button  ${buttonClass}`} onClick={handlerClick}>
    //   <FontAwesome name={icon} size="2x" />
    // </button>
    <span className={`calendar__arrow ${arrowClass}`} onClick={handlerClick}>
    </span>
  );
}

export default ButtonArrow;
