
import {
  useState, useCallback, useEffect,
} from 'react';
import ShiftPatternDomain from 'domain/master/attend/shiftPattern';
import { useFormik } from 'formik';
import { getBusinessDataByEmploymentId } from 'api/businessMaster';
import useToastNotification from 'hooks/useToastNotification';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import { OptionType } from 'components/atoms/Select';
import { createOrUpdateShiftPattern, getShiftPatternById } from 'api/shiftPattern';
import { useHistory } from 'react-router-dom';
import { ShiftPatternDetailInput } from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails/hooks';
import moment from 'moment';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import {
  getTreesOrgSelectRange
} from 'api/organization';

type ShiftPatternDomainKey = keyof Pick<ShiftPatternDomain, 'shiftPatternCode' | 'shiftPatternName' | 'shortName'>;

export const useShiftPatternAddForm = (shiftPatternId: string, typeButton: string) => {
  //
  const sessionShiftPatternOrgCode = sessionStorage.getItem('shiftPatternOrgCode') || '';
  const sessionEmploymentIdSelected = (sessionStorage.getItem('employmentIdSelected') && (sessionStorage.getItem('employmentIdSelected') !== 'NaN')) ? String(sessionStorage.getItem('employmentIdSelected')) : String('all');
  // history
  const history = useHistory();
  //
  //const { employmentOptions, attendEmploymentList } = useEmploymentLatestOptions();
  const [employmentIdSelect, setEmploymentIdSelect] = useState(sessionEmploymentIdSelected);
  const [isAllPattern, setIsAllPattern] = useState<boolean>(false);

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  //
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [handleGetInfoShiftPattern, setHandleGetInfoShiftPattern] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { successNotification, errorNotification } = useToastNotification();

  //
  const [shiftPatternDetailsList, setShiftPatternDetailsList] = useState<Array<ShiftPatternDetailInput>>([]);
  //
  const [attendBusinessOptions, setAttendBusinessOptions] = useState<Array<OptionType>>([]);
  //
  //const [listTimes, setListTimes] = useState([]);
  const listTimes = [
    '00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55',
  ];
  //
  const [selectedOrgCode, setSelectedOrgCode] = useState(sessionShiftPatternOrgCode);

  // useRemote
  const [remote, setRemote] = useState(false);
  //
  const [isLoading, setIsLoading] = useState(false);

  const { detailRole, loadedRole } = useGetDetailRole(setIsLoading, functionCode.masterShiftPattern);
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);

  useEffect(() => {
    if(loadedRole){
      getTreesOrgSelectRange(detailRole.accessRange === 3, functionCode.masterShiftPattern, undefined, undefined).then((trees) => {
        if (!trees) {
          console.error('組織一覧が取得できません');
          return;
        }
        const orgs = trees.map((tree) => ({
          value: tree.orgCode,
          label: tree.orgName,
        }));
        
        setOrgTreesOptionsList(orgs);
      });
    }
  }, [loadedRole])

  const onSubmit = async (values: ShiftPatternDomain) => {
    if (values.shiftPatternCode === null) {
      errorNotification('シフトパターンコードを入力してください。');
      return;
    }

    if (values.shiftPatternName === null) {
      errorNotification('シフトパターン名を入力してください。');
      return;
    }

    // if (values.shortName === null) {
    //   errorNotification('短縮名を入力してください。');
    //   return;
    // }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    // TODO companyCodeの取得処理が必要
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    //
    let convertData = values.getRawData();

    if(!remote){
      convertData.attendShiftPatternDetails?.forEach((shiftPattern) => {
        shiftPattern.isRemote = false;
      })
    }

    //
    delete (convertData as any).createDate;
    delete (convertData as any).updateDate;
    delete (convertData as any).companyCode;
    delete (convertData as any).attendShiftPatternDetails;

    //
    (convertData as any).shiftPatternCode = (convertData as any).shiftPatternCode.trim();
    (convertData as any).shiftPatternName = (convertData as any).shiftPatternName.trim();
    // (convertData as any).shortName = (convertData as any).shortName.trim();

    //
    convertData = {
      ...convertData,
      updateUser: loginUser,
      createUser: loginUser,
    };

    shiftPatternDetailsList.forEach((item: any, index: number) => {
      delete (item as any).companyCode;
      delete (item as any).createDate;
      delete (item as any).updateDate;

      if (typeButton === 'copy') {
        (item as any).shiftPatternDetailId = '';
        (item as any).shiftPatternId = '';
      }

      const tmpDetail = {
        ...item,
        startTime: moment(item.startTime, 'HH:mm:ss').format('HH:mm'),
        endTime: moment(item.endTime, 'HH:mm:ss').format('HH:mm'),
        createUser: loginUser,
        updateUser: loginUser,
      };

      convertData = {
        ...convertData,
        [`attendShiftPatternDetails[${index}]`]: {
          ...tmpDetail,
        },
      };
    });
    // submit form
    try {
      setIsLoading(true);
      const response = await createOrUpdateShiftPattern(companyCode, convertData);
      if (!response.errors) {
        history.push('/masterShiftPattern');
        successNotification(typeButton === 'edit' ? '更新しました。' : '登録しました。');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: ShiftPatternDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  useEffect(() => {
    setIsLoading(true);
    if (shiftPatternId && shiftPatternId !== undefined) {
      if (!handleGetInfoShiftPattern) {
        getShiftPatternDetail(shiftPatternId);
      }
    } 
    formik.setFieldValue('orgCode', selectedOrgCode);
    formik.setFieldValue('employmentId', employmentIdSelect);
    setIsLoading(false);

//  if (employmentOptions && employmentOptions.length) {
//      // get info
//      if (shiftPatternId && shiftPatternId !== undefined) {
//        if (!handleGetInfoShiftPattern) {
//          getShiftPatternDetail(shiftPatternId);
//        }
//      } else {
//        let defaultEmploymentId = '';
//        if (employmentOptions[0]) {
//          defaultEmploymentId = employmentOptions[0].value;
//        }
//        setEmploymentIdSelect(defaultEmploymentId);
//      }

//      if (employmentIdSelect && employmentIdSelect !== 'all') {
//        let targetDateStr = moment().format("YYYY-MM-DD");
//        attendEmploymentList.filter((item: any) => {
//          if (employmentIdSelect === item.id.employmentId) {
//            targetDateStr = item.id.applyStartDate;
//            setRemote(item.isRemote);
//            return true;
//          }
//          return false;
//        });

//        handleGetRoundTimeList(employmentIdSelect, targetDateStr, formik.values.employmentId || "");
        //
//        handleGetBusinessDataByEmploymentId(employmentIdSelect).finally(() => {
//          setIsLoading(false);
//        }); 
//        formik.setFieldValue('orgCode', selectedOrgCode);
//        formik.setFieldValue('employmentId', employmentIdSelect);
//      }
//    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  funct call api & handle data
   *
   */
  const handleGetBusinessDataByEmploymentId = async (employmentId: any, isUpdate?: boolean) => {
    if (employmentId !== undefined) {
      await getBusinessDataByEmploymentId(employmentId).then((response: any) => {
        if (response && response.length) {
          let tmpAttendBusiness = response.map((business: any) => ({
            value: business.businessId,
            label: business.businessName,
          }));
          if (response.length <= 1) {
            tmpAttendBusiness = [
              {
                value: ' ',
                label: '',
              },
              ...tmpAttendBusiness,
            ];
          }
          setAttendBusinessOptions(tmpAttendBusiness);
        }
      });
    }
  };

  /**
   *  funct call api & handle data
   *
   */
  const getShiftPatternDetail = async (shiftPatternId: any) => {
    if (shiftPatternId && shiftPatternId !== undefined) {
      await getShiftPatternById(shiftPatternId).then((response: any) => {
        if (response && response.companyCode) {
          setEmploymentIdSelect(response.employmentId);
          // if typeButton is copy
          if (typeButton === 'copy') {
            response.shiftPatternId = null;
          }
          formik.setValues(new ShiftPatternDomain(response));
          if(typeButton === 'copy'){
            formik.setFieldValue("orgCode", sessionStorage.getItem('loginUser.orgCode'));
          }
          const { attendShiftPatternDetails } = response;
          //
          if (attendShiftPatternDetails) {
            setShiftPatternDetailsList(attendShiftPatternDetails.map((shiftPatternDetail: ShiftPatternDetailInput) => (shiftPatternDetail)));
          } else {
            setShiftPatternDetailsList([]);
          }
          setHandleGetInfoShiftPattern(true);
          setIsAllPattern(response.orgCode === 'all');
          return true;
        }
        return false;
      });
    }
  };

//  useEffect(() => {
//    if(attendEmploymentList && employmentIdSelect){
//      let targetDateStr: any ;
//      attendEmploymentList.filter((item: any) => {
//        if (employmentIdSelect === item.id.employmentId) {
//          targetDateStr = item.id.applyStartDate;
//          return true;
//        }
//        return false;
//      });    
//      handleGetRoundTimeList(employmentIdSelect, targetDateStr, "", true);
//    }
//  }, [attendEmploymentList, employmentIdSelect])

  /**
   *  funct call api & handle data
   *
   */
//  const handleGetRoundTimeList = async (employmentId: string, targetDateStr: string, staffCode: string, isUpdate?: boolean) => {
//    await getRoundTimeList(employmentId, targetDateStr, staffCode).then((response: any) => {
//      if (response && response.roundTimeList) {
//        setListTimes(response.roundTimeList);
//      }
//    });
//  };

  //
  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  //
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    orgTreesOptions,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    attendBusinessOptions,
    setAttendBusinessOptions,
    employmentIdSelect,
    setEmploymentIdSelect,
    selectedOrgCode,
    setSelectedOrgCode,
    listTimes,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    handleGetBusinessDataByEmploymentId,
    isLoading,
    isAllPattern,
    remote,
    setRemote,
    detailRole
  };
};

export default useShiftPatternAddForm;
