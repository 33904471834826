import React from 'react';
import Stepper from 'react-js-stepper';
import './styles.css';

interface StepperProps {
    steps:any;
    activeStep:number;
    showNumber:boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StepperForm: React.FC<StepperProps> = ({ 
    steps,
    activeStep,
    onChange,
    showNumber = false,
}) => { 

  const parent = document.querySelectorAll(".stepper-item");
  const parentOuter = document.querySelectorAll(".stepper-container>.stepper-item-outer");

  for (let i = 0; i < parent.length; ++i) {
    parent[i]?.classList.remove('active');
  } 

  for (let i = 0; i < activeStep; ++i) {
    parent[i]?.classList.add('active');
  }  
  
  for (let i = 0; i < parentOuter.length; ++i) {
    parentOuter[i]?.classList.remove('active');
  } 

  for (let i = 0; i < activeStep - 1; ++i) {
    parentOuter[i]?.classList.add('active');
  }  
  
  //onChange
  return (
    <Stepper 
      steps={steps} 
      activeStep={activeStep} 
      showNumber={showNumber} 
      onSelect={onChange} 
    />
  )
}

export default StepperForm;
