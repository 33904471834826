import React from "react";

import SidebarTemplate from "components/templates/SidebarTemplate";
import AttendanceError from "./AttendanceError";

const AttendanceErrorPage: React.FC = () => (
  <SidebarTemplate pageTitle="勤怠エラーリスト">
    <AttendanceError />
  </SidebarTemplate>
);

export default AttendanceErrorPage;
