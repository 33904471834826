import { useState, useEffect, useCallback } from "react";

import { getAttendAchievementLockList, modifyAttendAchievementLock, ModifyInfo } from "api/attendAchievementLock";
import AttendAchievementLockDomain from "domain/master/attend/attendAchievementLockDomain";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";

export type selectedRow = {
  rowIndex: number;
  targetDate: Date;
  orgCode: string;
  lockStatus: string;
  lockable: boolean;
};

export const getErrorMessageForResponse = (error: any) => {
  let errorMsg = "";
  if (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.map((item: { defaultMessage: string }) => {
        errorMsg += `${item.defaultMessage} <br />`;
      });
    } else if (error.response && typeof error.response.data === "string") {
      errorMsg += `${error.response.data}`;
    } else if (error.response && error.response.data && error.response.data.defaultMessage) {
      errorMsg = error.response.data.defaultMessage;
    }
  }
  return errorMsg || "サーバー側でエラーが発生しました。";
};

export const useAttendAchievementLock = () => {
  const functionType = 2;

  const sessionTargetDateFrom = sessionStorage.getItem("attend.attendAchievementLock.searchTargetDateFrom");
  const sessionTargetDateTo = sessionStorage.getItem("attend.attendAchievementLock.searchTargetDateTo");
  const sessionCategoryArr = JSON.parse(
    String(sessionStorage.getItem(`sessionOrganizationFileterTree${window.location.pathname}.Toggle`)),
  );
  const sessionLockStatus = sessionStorage.getItem("attend.attendAchievementLock.searchLockStatus");
  const sessionIsError = sessionStorage.getItem("attend.attendAchievementLock.searchIsError");
  const sessionIsAlertOvertime = sessionStorage.getItem("attend.attendAchievementLock.searchIsAlertOvertime");
  const sessionIsAlertHolidaywork = sessionStorage.getItem("attend.attendAchievementLock.searchIsAlertHolidaywork");
  const sessionIsDisapproval = sessionStorage.getItem("attend.attendAchievementLock.searchIsDisapproval");
  const sessionIsLockable = sessionStorage.getItem("attend.attendAchievementLock.searchIsLockable");

  const [isLoading, setLoading] = useState(false);
  const [isGetDetailRoleLoading, setGetDetailRoleLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { detailRole } = useGetDetailRole(setGetDetailRoleLoading, functionCode.attendAchievementLock);

  const [targetDateFrom, setTargetDateFrom] = useState(
    sessionTargetDateFrom
      ? new Date(sessionTargetDateFrom)
      : moment()
          .add(-1, "days")
          .toDate(),
  );
  const [targetDateTo, setTargetDateTo] = useState(sessionTargetDateTo ? new Date(sessionTargetDateTo) : new Date());
  const [categoryArr, setCategoryArr] = useState<Array<String>>(sessionCategoryArr || []);
  const [underFlag, setUnderFlag] = useState(0);
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem("loginUser.orgCode") || "");
  const [lockStatus, setLockStatus] = useState(sessionLockStatus || "0");
  const [isError, setIsError] = useState<boolean>(sessionIsError?.toLowerCase() === "true" || false);
  const [isAlertOvertime, setIsAlertOvertime] = useState<boolean>(
    sessionIsAlertOvertime?.toLowerCase() === "true" || false,
  );
  const [isAlertHolidaywork, setIsAlertHolidaywork] = useState<boolean>(
    sessionIsAlertHolidaywork?.toLowerCase() === "true" || false,
  );
  const [isDisapproval, setIsDisapproval] = useState<boolean>(sessionIsDisapproval?.toLowerCase() === "true" || false);
  const [isLockable, setIsLockable] = useState<boolean>(sessionIsLockable?.toLowerCase() === "true" || false);

  const [attendAchievementLockList, setAttendAchievementLockList] = useState<Array<AttendAchievementLockDomain>>([]);
  const [selectedRows, setSelectedRows] = useState<selectedRow[]>([]);

  const orgTreesOptions = useOrgTreesOptionsShopV1(
    true,
    functionCode.attendAchievementLock,
    moment(`${targetDateFrom}`).format("YYYY-MM-DD"),
    moment(`${targetDateTo}`).format("YYYY-MM-DD"),
  );

  const { successNotification, errorNotification } = useToastNotification();

  const fetchAttendAchievementLockList = useCallback(async () => {
    if (!isLoading) {
      setLoading(true);
    }
    try {
      let lockList = await getAttendAchievementLockList(
        targetDateFrom,
        targetDateTo,
        functionType,
        orgCode,
        underFlag,
        categoryArr,
        lockStatus,
        isError,
        isAlertOvertime,
        isAlertHolidaywork,
        isDisapproval,
        isLockable,
      );

      setAttendAchievementLockList(lockList.map((result) => new AttendAchievementLockDomain(result)));
      setSelectedRows([]);
      sessionStorage.setItem(
        "attend.attendAchievementLock.searchTargetDateFrom",
        moment(targetDateFrom).format("YYYY-MM-DD"),
      );
      sessionStorage.setItem(
        "attend.attendAchievementLock.searchTargetDateTo",
        moment(targetDateTo).format("YYYY-MM-DD"),
      );
      sessionStorage.setItem("attend.attendAchievementLock.searchLockStatus", lockStatus);
      sessionStorage.setItem("attend.attendAchievementLock.searchIsError", String(isError));
      sessionStorage.setItem("attend.attendAchievementLock.searchIsAlertOvertime", String(isAlertOvertime));
      sessionStorage.setItem("attend.attendAchievementLock.searchIsAlertHolidaywork", String(isAlertHolidaywork));
      sessionStorage.setItem("attend.attendAchievementLock.searchIsDisapproval", String(isDisapproval));
      sessionStorage.setItem("attend.attendAchievementLock.searchIsLockable", String(isLockable));
      setLoading(false);
    } catch (error) {
      const message = getErrorMessageForResponse(error);

      errorNotification(message);
    } finally {
      setLoading(false);
    }
  }, [
    targetDateFrom,
    targetDateTo,
    orgCode,
    categoryArr,
    lockStatus,
    isError,
    isAlertOvertime,
    isAlertHolidaywork,
    isDisapproval,
    isLockable,
    underFlag,
  ]);

  useEffect(() => {
    const from = moment(targetDateFrom);
    const to = moment(targetDateTo);
    if (from.isAfter(to)) {
      return;
    }
    if (to.diff(from, "days") > 30) {
      errorNotification("対象期間は1ヶ月以内で指定してください。");
      return;
    }
    setLoading(true);
    fetchAttendAchievementLockList();
  }, [
    targetDateFrom,
    targetDateTo,
    orgCode,
    categoryArr,
    lockStatus,
    isError,
    isAlertOvertime,
    isAlertHolidaywork,
    isDisapproval,
    isLockable,
    underFlag,
  ]);

  const lockAttendAchievement = useCallback(async () => {
    setConfirmModalOpen(false);
    const lockList = selectedRows
      .filter((r) => r.lockable && r.lockStatus === "0")
      .map((r) => ({ targetDate: moment(r.targetDate).format("YYYY-MM-DD"), orgCode: r.orgCode } as ModifyInfo));
    try {
      setLoading(true);
      await modifyAttendAchievementLock(lockList, "lock");
      const response = await getAttendAchievementLockList(
        targetDateFrom,
        targetDateTo,
        functionType,
        orgCode,
        underFlag,
        categoryArr,
        lockStatus,
        isError,
        isAlertOvertime,
        isAlertHolidaywork,
        isDisapproval,
        isLockable,
      );
      setAttendAchievementLockList(response.map((result: any) => new AttendAchievementLockDomain(result)));
      setSelectedRows([]);
    } catch (error) {
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    } finally {
      setLoading(false);
    }
  }, [selectedRows, attendAchievementLockList]);

  const unlockAttendAchievement = useCallback(async () => {
    const lockList = selectedRows
      .filter((r) => r.lockStatus === "1")
      .map((r) => ({ targetDate: moment(r.targetDate).format("YYYY-MM-DD"), orgCode: r.orgCode } as ModifyInfo));
    try {
      setLoading(true);
      await modifyAttendAchievementLock(lockList, "unlock");
      const response = await getAttendAchievementLockList(
        targetDateFrom,
        targetDateTo,
        functionType,
        orgCode,
        underFlag,
        categoryArr,
        lockStatus,
        isError,
        isAlertOvertime,
        isAlertHolidaywork,
        isDisapproval,
        isLockable,
      );
      setAttendAchievementLockList(response.map((result: any) => new AttendAchievementLockDomain(result)));
      setSelectedRows([]);
    } catch (error) {
      const message = getErrorMessageForResponse(error);
      errorNotification(message);
    } finally {
      setLoading(false);
    }
  }, [selectedRows, attendAchievementLockList]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    isLoading: isLoading || isGetDetailRoleLoading,
    setLoading,
    orgTreesOptions,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    categoryArr,
    setCategoryArr,
    orgCode,
    setOrgCode,
    underFlag,
    setUnderFlag,
    lockStatus,
    setLockStatus,
    isError,
    setIsError,
    isAlertOvertime,
    setIsAlertOvertime,
    isAlertHolidaywork,
    setIsAlertHolidaywork,
    isDisapproval,
    setIsDisapproval,
    isLockable,
    setIsLockable,
    attendAchievementLockList,
    setAttendAchievementLockList,
    selectedRows,
    setSelectedRows,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    lockAttendAchievement,
    unlockAttendAchievement,
    detailRole,
  };
};

export default {
  useAttendAchievementLock,
};
