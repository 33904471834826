/** @jsx jsx */
import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { productColor, textFontSize, utilityColor, grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import useToastNotification from "hooks/useToastNotification";
import FileSelectBox from "components/molecules/FileSelectBox";
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import Action, { types }  from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/actions';
import FlexBox from 'components/atoms/FlexBox';
import TextForm from "components/molecules/TextForm";
import PrimaryButton, { RecorderButton, SecondaryButton } from "components/atoms/Button";
import {
  Face,
  State
} from 'components/organismos/master/labor/MasterShopFaceRegistrationFormV1/type';
import Modal from "components/molecules/Modal";

const styles = {
  photoDefault: css({
    position: "absolute",
    height: "100%",
    left: "50%",
    transform: "translateX(-50%)"
  }),
  isHover: css({
    opacity: '0.1',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
  }),
  fileUploadButton: css`  
    background: transparent;
  `,
  uploadFile: css([{
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'transparent',
    width: "fit-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }, css`
    button{
      padding-left: 10px;
    }
  `]),
  containerBox: css({
    position: 'relative',
    height: '250px',
    boxSizing: 'border-box',
    width: '100%',
    border: '1px solid rgb(220, 220, 220)'
  }),
  container: css`
    padding: 0px 20px;
    width: 33%;
    box-sizing: border-box;
  `,
  description: css({
    padding: '3px',
    width: '205px',
    marginBottom: '5px'
  }),
  imageCropped: css({
    cursor: "pointer",
    height: "100%",
    margin: "auto",
    width: '100%'
  }),
  buttonAction: css({
    width: "fit-content", 
    margin: "0", 
    padding: "0", 
    minWidth: "fit-content",
  }),
};
const FaceRegisterBox: React.FC<{
  dispatch: React.Dispatch<Action>,
  face: Face,
  index: number,
  key: number,
  hiddenDescription?: boolean
}> = ({
  dispatch,
  face,
  index,
  key,
  hiddenDescription
}) => {
  const [isNotExistImage, setIsNotExistImage] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleNotExistImage = (e: any) => {
    e.target.style.display="none";
    setIsNotExistImage(true);
  }
  const handleExistImage = (e: any) => {
    e.target.style.display="block";
    setIsNotExistImage(false);
  }

  return(
    <FlexBoxItem customStyle={css([styles.container, index === 0 ? {width: '320px'} : {}])}>
      {!hiddenDescription && (
        <FlexBox customStyle={css(index === 0 ? {visibility: 'hidden'} : {})}>
          <div style={{width: '45px'}}>
            <BodyText size="sm">タグ：</BodyText>
          </div>
          <div>
            <TextForm
              name="description"
              label=""
              value={face.description}
              required={true}
              showRequiredLabel={false}
              onChange={(e) => {
                dispatch({ type: types.CHANGE_DESCRIPTION, payload: {index, description: e.target.value} });
              }}
              customStyle={styles.description}
            />
          </div>
        </FlexBox>
      )}
      <div css={css([styles.containerBox, index === 0 ? {height: '300px'} : {}])} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        {face.blob !== "" && (
          <img 
            src={face.blob}
            width={index === 0 ? 300 :250} 
            alt="cropped image"
            css={css([styles.imageCropped, isHover ? styles.isHover : {}])}
            onError={handleNotExistImage} onLoad={handleExistImage}
          />
        )}
        <FileSelectBox
          text="ファイルを選択"
          setFile={(file) => {}}
          value=""
          ghost={true}
          accept="image/jpeg,image/png"
          customStyle={styles.fileUploadButton}
          customStyleContainer={styles.uploadFile}
          onClick={() => {
            dispatch({ type: types.SET_MODAL_CHOOSE_ACTION, payload: {open: true, indexAction: index} });
          }}
          hidden={!isHover && face.blob !== "" && !isNotExistImage}
        />
      </div>

      <FlexBox justifyContent="flex-end">
        <SecondaryButton
          text={"削除"}
          customStyle={css([styles.buttonAction, (face.blob === "" || isNotExistImage) ? {visibility: 'hidden'} : {}])}
          ghost={true}
          onClick={() => {
            setOpenModal(true);
          }}
        />
        <Modal
            open={openModal}
            closeHandler={() => setOpenModal(false)}
            title="確認メッセージ"
            submitText="削除"
            typeSubmit="reject"
            closeText="キャンセル"
            onSubmit={() => { 
              setOpenModal(false);
              if(index === 0){
                dispatch({ type: types.REMOVE_STAFF_IMAGE});
              }else{
                dispatch({ type: types.REMOVE_DEPEND_IMAGE, payload: index });
              }
            }}
            note="顔写真を削除します。よろしいですか？"
          ></Modal>
      </FlexBox>
    </FlexBoxItem>
  )
};

export default FaceRegisterBox;
