/** @jsx jsx */
import React from "react";

import FormContents from "components/atoms/Form/FormContents";
import { css, jsx } from "@emotion/core";

import TabControl from "components/atoms/TabControl";
import StaffCsvImportPage from "./StaffCsvImportPage";
import StaffCsvHistory from "./StaffCsvHistory";
import FormTitle from "components/atoms/Form/FormTitle";
import FormPadding from "components/atoms/Form/FormPadding";
import { Row, Col } from "react-grid-system";

export type UploadType = "0" | "1" | "2" | "3";

type SelectType = "import" | "history";

const SelectType: { [key in SelectType]: SelectType } = {
  import: "import",
  history: "history",
};

const StaffCsvUploadForm: React.FC = () => {
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.import);
  return (
    <FormContents>
      <FormTitle bold={true} title="スタッフインポート" />
      <FormPadding customStyle={{ padding: "0 30px" }}>
        <Row>
          <Col md={4} xs={12}>
            <TabControl
              items={[
                {
                  value: SelectType.import,
                  display: "インポート",
                },
                {
                  value: SelectType.history,
                  display: "履歴参照",
                },
              ]}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={false}
              customStyle={css({
                margin: "0px",
                padding: "8px 16px",
                boxSizing: "border-box",
                maxWidth: "100%",
                overflow: "auto",
              })}
            />
          </Col>
        </Row>
      </FormPadding>
      <div>{selectType === "import" ? <StaffCsvImportPage /> : <StaffCsvHistory />}</div>
    </FormContents>
  );
};

export default StaffCsvUploadForm;
