/** @jsx jsx */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { grayScale, textFontSize } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import PrimaryButton, { InfoButton, DeleteButton } from "components/atoms/Button";
import Checkbox from "components/molecules/Checkbox";
import FlexBox from "components/atoms/FlexBox";
import { css, jsx } from "@emotion/core";
import Modal from "components/molecules/Modal";
import ToastModal from "components/organismos/ToastModal";
import useToastNotification from "hooks/useToastNotification";
import { useApplyingTransferForm } from "./hook";
import ApplyingOvertimeDomain from "../domain/applyingOvertimeDomain";
import { postBulkApproveOvertime, postApproveOvertime } from "../api";
import BlockUI from "components/molecules/BlockUi";
import TextForm from "components/molecules/TextForm";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import IconLabelButton from "components/molecules/IconLabelButton";

const { errorNotification, successNotification } = useToastNotification();

const OvertimeAccordion: React.FC<{
  list: ApplyingOvertimeDomain[];
  setApplyingOvertimeList: any;
  fetchApprovedList: any;
  fetchRejectedList: any;
}> = ({ list, setApplyingOvertimeList, fetchApprovedList, fetchRejectedList }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [isDelete, setIsDelete] = useState<any>();
  // const [changeReason, setChangeReason] = useState();
  const {
    toastMessage,
    setToastMessage,

    toastModalOpen,
    setToastModalOpen,

    confirmApplyModalOpen,
    setConfirmApplyModalOpen,

    closeConfirmApplyModal,

    extractSingleApproveData,
    extractBulkApproveData,
    extractSingleRejectData,

    rejectReasonOnchange,

    buttonType,
    setButtonType,
    selectedIndex,
    setSelectedIndex,
    changeReason,
    fetchChangeLogOverTime,
    setChangeLogModalOpen,
    setSelectChangelog,

    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    selectChangelog,
    setChangeReason,
  } = useApplyingTransferForm(list, setApplyingOvertimeList);

  const colorTextLabel = {
    color: `${grayScale.gray50}`,
  };

  const colorText = {
    color: `${grayScale.gray100}`,
  };

  const fontSizeData = {
    fontSize: `${textFontSize.re}`,
  };

  const handleSubmit = async () => {
    const remainingList = list.filter((item, position) => !selectedIndex.includes(position)); // remove checked requests
    const checkedList = list.filter((item, position) => selectedIndex.includes(position)); // checked requests

    try {
      if (buttonType === 1) {
        rejectRequest(checkedList, remainingList);
      } else {
        approveRequest(checkedList, remainingList);
      }
    } catch (error) {
      setToastMessage(error.message);
    }
    setConfirmApplyModalOpen(false);
    setSelectedIndex([]);
  };

  const approveRequest = async (checkedList: any[], remainingList: any[]) => {
    setIsLoading(true);
    if (checkedList.length > 1) {
      try {
        await postBulkApproveOvertime(extractBulkApproveData(checkedList));
        setIsLoading(false);
        successNotification("承認しました。");
        setApplyingOvertimeList(remainingList); // set new list
        fetchApprovedList(); // refetch API
      } catch (error) {
        setIsLoading(false);
        if (error.response.status !== 500 && error.response && error.response.data) {
          const listErr = error.response.data;
          listErr.map((element: any) => errorNotification(element.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
          throw error;
        }
      }
    } else {
      try {
        await postApproveOvertime(extractSingleApproveData(checkedList));
        setIsLoading(false);
        successNotification("承認しました。");
        setApplyingOvertimeList(remainingList); // set new list
        fetchApprovedList(); // refetch API
      } catch (error) {
        setIsLoading(false);
        if (error.response.status !== 500 && error.response && error.response.data) {
          const listErr = error.response.data;
          listErr.map((element: any) => errorNotification(element.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
          throw error;
        }
      }
    }
  };

  const rejectRequest = async (checkedList: any[], remainingList: any[]) => {
    setIsLoading(true);
    if (!checkedList[0].rejectReason) {
      setIsLoading(false);
      errorNotification("差戻コメントを入力してください。");
      return;
    }

    await postApproveOvertime(extractSingleRejectData(checkedList));
    setIsLoading(false);
    successNotification("差戻しました。");
    setApplyingOvertimeList(remainingList); // set new list
    fetchRejectedList(); // refetch API
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(false);
    setSelectedIndex([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchApprovedList]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Checkbox
            id="checkbox_overtime_all"
            name="checkbox_overtime_all"
            value=""
            checked={isChecked}
            isCheckBoxTable={true}
            onChange={() => {
              setIsChecked(!isChecked);
              if (!isChecked) {
                const indexArr: any = [];
                const data = document.querySelectorAll(".rowDataIndex2");
                data.forEach((e) => {
                  indexArr.push(Number(e.getAttribute("row-data-index")));
                });

                setSelectedIndex(indexArr);
                return false;
              }
              setSelectedIndex([]);
            }}
          />
        ),
        disableSortBy: true,
        id: "",
        accessor: "checkbox",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              id={`checkbox_overtime_${cell.row.index}`}
              name="name"
              label=""
              value={`${cell.row.index}`}
              checked={selectedIndex.includes(cell.row.index)}
              isCheckBoxTable={true}
              onChange={() => {
                if (selectedIndex.includes(cell.row.index)) {
                  setSelectedIndex(selectedIndex.filter((selected) => selected !== cell.row.index));
                } else {
                  setSelectedIndex([...selectedIndex, cell.row.index]);
                }
              }}
            />
            <input type="hidden" className="rowDataIndex2" row-data-index={cell.row.index} />
          </div>
        ),
      },
      {
        Header: "決裁",
        id: "",
        accessor: "decision",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div>
            <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
              <InfoButton
                text="承認"
                onClick={() => {
                  setButtonType(3);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
                customStyle={css({
                  marginRight: "15px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  height: "34px",
                })}
                minWidth="40px"
              />
              <DeleteButton
                text="差戻"
                onClick={() => {
                  setChangeReason("");
                  setButtonType(1);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
                customStyle={css({
                  borderRadius: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  height: "34px",
                })}
                minWidth="40px"
              />
            </FlexBox>
          </div>
        ),
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: (any: any) => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <div>
                <div>{cell.row.original.targetDate}</div>
                <IconLabelButton
                  onClick={() => {
                    fetchChangeLogOverTime(cell.row.original.rawData);
                    setChangeLogModalOpen(true);
                    setSelectChangelog("0");
                  }}
                  text="更新履歴"
                  textAlign="center"
                  isIcon={false}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "残業時間",
        accessor: "unapprovedOvertime",
      },
      {
        Header: "申請残業時間",
        accessor: "applicationOvertime",
      },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, selectedIndex],
  );

  return (
    <div
      css={css`
        table th:nth-of-type(1) {
          width: 40px;
        }
        table th:nth-of-type(2) {
          width: 180px;
        }
        table th:nth-of-type(3) {
          width: 140px;
        }
        table th:nth-of-type(4) {
          width: 140px;
        }
        table th:nth-of-type(5) {
          width: 140px;
        }
        table th:nth-of-type(6) {
          width: 140px;
        }
        table th:nth-of-type(7) {
          width: 80px;
        }
        table th:nth-of-type(8) {
          width: 80px;
        }
        table th:nth-of-type(9) {
          width: 80px;
        }
        table th:nth-of-type(10) {
          width: 80px;
        }
        table th:nth-of-type(11) {
          width: 200px;
        }
        .labelReject {
          margin-top: 40px;
          text-align: left;
          padding: 0 60px;
        }
        #rejectReason {
          height: 64px;
          margin-top: 10px;
          width: 100%;
          color: #333333;
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          border-radius: 2px;
          padding: 5px;
          font-size: 14px;
          font-family: san-serif;
        }
        #rejectReason:focus {
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          border-radius: 2px;
        }
      `}
    >
      <Accordion text={`残業申請 (${list.length}件）`} callBack={() => {}}>
        <PrimaryButton
          text="一括承認"
          onClick={() => {
            setButtonType(2);
            setConfirmApplyModalOpen(true);
          }}
          disabled={selectedIndex.length === 0}
          customStyle={css({ float: "right", marginRight: "15px" })}
        />
        <BlockUI blocking={isLoading}>
          <DataTable
            onClickPagination={() => {
              setIsChecked(false);
              setSelectedIndex([]);
            }}
            isHiddenPagination={false}
            iconSize="16px"
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            tableId={2}
            columns={columns}
            data={list}
            isGlobalFilter={true}
            sortBy={[{ id: "applicationDate", desc: false }]}
          />
        </BlockUI>
      </Accordion>
      <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

      <Modal
        open={confirmApplyModalOpen}
        closeHandler={closeConfirmApplyModal}
        onSubmit={handleSubmit}
        title={buttonType === 3 ? "申請の承認" : buttonType === 1 ? "申請の差戻" : "確認メッセージ"}
        // eslint-disable-next-line no-nested-ternary
        typeSubmit={buttonType === 1 ? "reject" : "confirm"}
        // eslint-disable-next-line no-nested-ternary
        note={
          buttonType === 0
            ? "承認します。よろしいですか？"
            : buttonType === 1
            ? "申請を差戻します。よろしいですか？"
            : buttonType === 3
            ? "申請を承認します。よろしいですか？"
            : "一括承認します。よろしいですか？"
        }
        submitText={buttonType === 1 ? "差戻する" : buttonType === 3 ? "承認する" : "承認"}
      >
        {buttonType === 1 && (
          <React.Fragment>
            <div className="labelReject">
              <TextForm
                name="rejectReason"
                label="差戻コメント"
                value={changeReason}
                placeHolder=""
                errorMsg=""
                onChange={(e) => {
                  rejectReasonOnchange(selectedIndex[0], e);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>
      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} request="残業申請" />
        </Modal>
      </div>
      {/* ./Change log  */}
    </div>
  );
};

export default OvertimeAccordion;
