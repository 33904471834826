import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import OrganizationDomain from 'domain/organization';
import { getHistory } from 'api/organization';
import ManagerDomain from 'domain/manager';
import { 
  getDetailRole, 
  DetailRole, 
} from 'api/masterRole';

const defaultDetailRole = {
  roleId: '',
  functionCode: '001',		
  available: false,		
  editable: 0,		
  importFlag: 0,
  downloadFlag: 0,
  accessRange: 1,
  linkAvailable1: false,
  linkAvailable2: false,
  linkAvailable3: false,
  linkAvailable4: false,
  linkAvailable5: false,
  usabilityGeneralItem1: null,
  usabilityGeneralItem2: null,
  usabilityGeneralItem3: null,
  usabilityGeneralItem4: null,
  usabilityGeneralItem5: null,
  usabilityGeneralItem6: null,
  usabilityGeneralItem7: null,
  usabilityGeneralItem8: null,
  usabilityGeneralItem9: null,
  usabilityGeneralItem10: null,
}

export const useOrganizationEdit = () => {
  const { orgCode } = useParams<{orgCode: string}>();
  const [organizationDomain, setOrganizationDomain] = useState<OrganizationDomain>(
    OrganizationDomain.generateInitial(),
  );
  const [managerList, setManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [seniorManagerList, setSeniorManagerList] = useState<Array<ManagerDomain>|null>(null);
  const [historyList, setHistoryList] = useState<Array<OrganizationDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  const [detailRole, setDetailRole] = useState<DetailRole>(defaultDetailRole);
  const [isFetchHistory, setIsFetchHistory] = useState<boolean>(true);
  

  useEffect(() => {
    const fetchData = async () => {
      setBlocking(true);
      const history = await getHistory(orgCode);
      
      setBlocking(false);
      setIsFetchHistory(false);

      if (history && history.length > 0) {
        setHistoryList(history.map((org) => new OrganizationDomain(org)));
        const organization = history[0];
        setOrganizationDomain(new OrganizationDomain(organization));
        if (organization.managerList && organization.managerList.length > 0) {
          setManagerList(organization.managerList.map((manager) => new ManagerDomain(manager)));
        } else {
          setManagerList([]);
        }
        if (organization.seniorManagerList && organization.seniorManagerList.length > 0) {
          setSeniorManagerList(organization.seniorManagerList.map(
            (seniorManager) => new ManagerDomain(seniorManager),
          ));
        } else {
          setSeniorManagerList([]);
        }
      } else {
        setHistoryList([]);
      }
    };
    fetchData();
  }, [orgCode]);

  useEffect(() => {
    const fetchFunctionRole = async () => {
      setBlocking(true);
      getDetailRole('001').then((response: DetailRole) => {
        if(response){
          setDetailRole(response);
        }
        setBlocking(false);
      })
      .catch((error: any) => {
        setBlocking(false);
      });
    };
    fetchFunctionRole();
  }, []);

  return {
    organizationDomain,
    setOrganizationDomain,
    managerList,
    setManagerList,
    seniorManagerList,
    setSeniorManagerList,
    historyList,
    setHistoryList,
    blocking,
    setBlocking,
    orgCode,
    detailRole,
    isFetchHistory
  };
};

export default {};
