import React from "react";
import ApplyingStampDomain from "../domain/applyingStampDomain";
import ApplyingHolidayDomain from "../domain/applyingHolidayDomain";
import ApplyingOvertimeDomain from "../domain/applyingOvertimeDomain";
import ApplyingHolidayWorkDomain from "../domain/applyingHolidayWorkDomain";
import ApplyingTransferDomain from "../domain/applyingTransferDomain";
import Applying36AgreementDomain from "../domain/applying36AgreementDomain";
import ApplyingWorkScheduleChangeAppDomain from "../domain/applyingWorkScheduleChangeAppDomain";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplication from "./WorkScheduleChangeApplicationAccordion";
import { OptionType } from 'components/atoms/Select';

const ApplyingTab: React.FC<{
  stampList: ApplyingStampDomain[];
  holidayList: ApplyingHolidayDomain[];
  overtimeList: ApplyingOvertimeDomain[];
  holidayWorkList: ApplyingHolidayWorkDomain[];
  transferList: ApplyingTransferDomain[];
  agreement36List: Applying36AgreementDomain[];
  workScheduleChangeList: ApplyingWorkScheduleChangeAppDomain[];
  reasonOptionList: OptionType[];
  reasonId:any;
  setReasonId:any;

  fetchApprovedStampList: any;
  fetchApprovedHolidayList: any;
  fetchApprovedOvertimeList: any;
  fetchApprovedHolidayWorkList: any;
  fetchApprovedTransferList: any;
  fetchApprovedAgreement36List: any;
  fetchApprovedWorkScheduleChangeList: any;

  fetchRejectedStampList: any;
  fetchRejectedHolidayList: any;
  fetchRejectedOvertimeList: any;
  fetchRejectedHolidayWorkList: any;
  fetchRejectedTransferList: any;
  fetchRejectedAgreement36List: any;
  fetchRejectedWorkScheduleChangeList: any;

  setApplyingTransferList: any;
  setApplyingStampList: any;
  setApplyingHolidayList: any;
  setApplyingOvertimeList: any;
  setApplyingHolidayWorkList: any;
  setApplyingAgreement36List: any;
  setApplyingWorkScheduleChangeList: any;
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleChangeList,
  reasonOptionList,
  reasonId,
  setReasonId,

  fetchApprovedStampList,
  fetchApprovedHolidayList,
  fetchApprovedOvertimeList,
  fetchApprovedHolidayWorkList,
  fetchApprovedTransferList,
  fetchApprovedAgreement36List,
  fetchApprovedWorkScheduleChangeList,

  fetchRejectedStampList,
  fetchRejectedHolidayList,
  fetchRejectedOvertimeList,
  fetchRejectedHolidayWorkList,
  fetchRejectedTransferList,
  fetchRejectedAgreement36List,
  fetchRejectedWorkScheduleChangeList,

  setApplyingTransferList,
  setApplyingStampList,
  setApplyingHolidayList,
  setApplyingOvertimeList,
  setApplyingHolidayWorkList,
  setApplyingAgreement36List,
  setApplyingWorkScheduleChangeList,
}) => {
  return (
    <div>
      <StampAccordion
        list={stampList}
        setApplyingStampList={setApplyingStampList}
        fetchApprovedList={fetchApprovedStampList}
        fetchRejectedList={fetchRejectedStampList}
        reasonOptionList={reasonOptionList}
        reasonId={reasonId}
        setReasonId={setReasonId}
      />

      <HolidayAccordion
        list={holidayList}
        setApplyingHolidayList={setApplyingHolidayList}
        fetchApprovedList={fetchApprovedHolidayList}
        fetchRejectedList={fetchRejectedHolidayList}
      />

      <OvertimeAccordion
        list={overtimeList}
        setApplyingOvertimeList={setApplyingOvertimeList}
        fetchApprovedList={fetchApprovedOvertimeList}
        fetchRejectedList={fetchRejectedOvertimeList}
      />

      <HolidayWorkAccordion
        list={holidayWorkList}
        setApplyingHolidayWorkList={setApplyingHolidayWorkList}
        fetchApprovedList={fetchApprovedHolidayWorkList}
        fetchRejectedList={fetchRejectedHolidayWorkList}
      />

      <TransferAccordion
        list={transferList}
        setApplyingTransferList={setApplyingTransferList}
        fetchApprovedList={fetchApprovedTransferList}
        fetchRejectedList={fetchRejectedTransferList}
      />

      <Agreement36Accordion
        list={agreement36List}
        setApplyingAgreement36List={setApplyingAgreement36List}
        fetchApprovedList={fetchApprovedAgreement36List}
        fetchRejectedList={fetchRejectedAgreement36List}
      />

      <WorkScheduleChangeApplication
        list={workScheduleChangeList}
        setApplyingWorkScheduleChangeList={setApplyingWorkScheduleChangeList}
        fetchApprovedList={fetchApprovedWorkScheduleChangeList}
        fetchRejectedList={fetchRejectedWorkScheduleChangeList}
      />
    </div>
  );
};

export default ApplyingTab;
