/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import Amplify, { Auth } from "aws-amplify";

import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import PasswordForm from "components/molecules/PasswordForm";
import Button, { PrimaryButton } from "components/atoms/Button";
import { useHistory, Link, useParams } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import { getCognitoInfo } from "api/login";
import { generateCognitoInfo } from "utility/generateCognitoInfo";
import { getAuthorizedFromDelious } from "utility";

const ForgotPasswordSubmitSchema = Yup.object().shape({
  email: Yup.string().required("メールアドレスを入力してください"),
  activationCode: Yup.string().required("認証コードを入力してください"),
  password: getAuthorizedFromDelious()
    ? Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
          "パスワードは大文字小文字数字記号をすべて含む8文字以上で入力して下さい",
        )
        .required("パスワードを入力してください")
    : Yup.string()
        .min(6, "パスワードは6文字以上で入力して下さい")
        .required("パスワードを入力してください"),
  rePassword: getAuthorizedFromDelious()
    ? Yup.string()
        .required("パスワード(確認)を入力してください")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
          "パスワードは大文字小文字数字記号をすべて含む8文字以上で入力して下さい",
        )
        .oneOf([Yup.ref("password")], "パスワードと確認用のパスワードが異なっています")
    : Yup.string()
        .required("パスワード(確認)を入力してください")
        .min(6, "パスワードは6文字以上で入力して下さい")
        .oneOf([Yup.ref("password")], "パスワードと確認用のパスワードが異なっています"),
});

const ForgotPasswordSubmitInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const { mailAddress, companyCode } = useParams<any>();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: mailAddress !== undefined ? mailAddress : "",
      activationCode: "",
      password: "",
      rePassword: "",
    },
    onSubmit: (values) => {
      getCognitoInfo(companyCode ?? "", formik.values.email).then((res) => {
        localStorage.setItem("loginUser.companyCode", res.companyCode);
        sessionStorage.setItem("loginUser.companyCode", res.companyCode);

        // 設定情報をAmplifyに反映させる
        Amplify.configure(generateCognitoInfo(res));

        Auth.forgotPasswordSubmit(formik.values.email, formik.values.activationCode, formik.values.password)
          .then((data: any) => {
            successNotification("パスワードの再設定が完了しました");
            history.push(getAuthorizedFromDelious() ? `/login/${companyCode}` : "/login");
          })
          .catch((exception: any) => {
            if (exception.code === "CodeMismatchException") {
              errorNotification("認証コードが間違っています");
            } else if (exception.code === "ExpiredCodeException") {
              errorNotification("認証コードの有効期限が切れていますので、再度パスワードの再設定をおこなってください");
            } else {
              errorNotification("パスワードの再設定に失敗しました");
            }
          });
      });
    },
    validationSchema: ForgotPasswordSubmitSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormField>
        <TextForm
          name="activationCode"
          label=""
          placeHolder="認証コード"
          value={formik.values.activationCode}
          onChange={formik.handleChange}
          errorMsg={formik.errors.activationCode}
        />
      </FormField>

      <FormField>
        <PasswordForm
          name="password"
          label=""
          placeHolder="パスワード"
          value={formik.values.password}
          onChange={formik.handleChange}
          errorMsg={formik.errors.password}
        />
      </FormField>

      <FormField>
        <PasswordForm
          name="rePassword"
          label=""
          placeHolder="パスワード（確認）"
          value={formik.values.rePassword}
          onChange={formik.handleChange}
          errorMsg={formik.errors.rePassword}
        />
      </FormField>

      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%", marginTop: "20px" })}>
          <Button text="パスワードを変更" onClick={formik.handleSubmit} />
        </div>
      </FormSubmitArea>
      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%" })}>
          <Link to={getAuthorizedFromDelious() ? `/login/${companyCode}` : "/login"}>
            <PrimaryButton ghost={true} text="キャンセル" />
          </Link>
        </div>
      </FormSubmitArea>
    </form>
  );
};

export default ForgotPasswordSubmitInputForm;
