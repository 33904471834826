import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import BusinessCategoryImportForm from 'components/organismos/master/sales/BusinessCategoryImportForm';


const BusinessCategoryImportPage: React.FC = () => (
  <SidebarTemplate pageTitle="業態情報インポート">
    <BusinessCategoryImportForm />
  </SidebarTemplate>
);


export default BusinessCategoryImportPage;
