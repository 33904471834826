import moment from 'moment';
import { OptionType } from 'components/atoms/Select';

export const DayOfWeek = [
    'S',
    'M',
    'T',
    'W',
    'T',
    'F',
    'S',
]

export type ModeAction = 'day' | 'year' | 'month' | 'week' ;
export type ModeType = 'next' | 'previous';

export const getPosition = (element: HTMLElement | null) => {

    let position = {
        top: 0,
        left: 0
    }
    if (element) {
        position = {
            top: element.getBoundingClientRect().bottom,
            left: element.getBoundingClientRect().left,
        };
    }
    return position;
}

export const togglePortal = () => {
    let portal = document.getElementById("calendar-portal");
    if (!portal) {
        return;
    }
    if (portal.style.display === "none") {
        portal.style.display = "block";
    } else {
        portal.style.display = "none";
    }
}

export const getDate = (date: any) => {
    if (Array.isArray(date)) {
        return date[0];
    }
    return date;
}

export const formatDate = (date: Date | null | undefined, dateFormat: string, canNullEndDate?: boolean) => {
    return date ? moment(date).format(dateFormat) : '';
}

export const getEndDateCanNull = (state: any, dateFormat: string) => {
    if(state && state[0] && state[0].startDate === state[0].endDate){
        return '';
    }
    return state[0].endDate ? moment(state[0].endDate).format(dateFormat) : '';
}

export const getOptionsYearEnd = (options: OptionType[], currentFocusedDate: Date | null) => {
    let currentYear = currentFocusedDate?.getFullYear() || new Date().getFullYear;
    return options.filter((option) => Number(option.value) >= currentYear);
}
export const getOptionsMonth = (options: OptionType[], maxDate: Date, currentFocusedDate: Date) => {

    let currentMonth = currentFocusedDate? currentFocusedDate.getMonth() : new Date().getMonth();

    if (maxDate.getFullYear() > currentFocusedDate?.getFullYear()) {
        return options;
    }
    return options.filter((option) => Number(option.value) - 1 > currentMonth);
}

export const addAMonth = (date: Date) => {
    return moment(date).add(1, 'month').toDate();
}
export const subAMonth = (date: Date) => {
    return moment(date).subtract(1, 'month').toDate();
}

export const addAYear = (date: Date) => {
    return moment(date).add(1, 'year').toDate();
}
export const subAYear = (date: Date) => {
    return moment(date).subtract(1, 'year').toDate();
}
export const addADay = (date: Date) => {
    return moment(date).add(1, 'day').toDate();
}
export const subADay = (date: Date) => {
    return moment(date).subtract(1, 'day').toDate();
}
export const addAWeek = (date: Date) => {
    return moment(date).add(1, 'week').toDate();
}
export const subAWeek = (date: Date) => {
    return moment(date).subtract(1, 'week').toDate();
}

export const actionChevron = (date: Date, mode: ModeAction, action: ModeType ) => {
    let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
    switch (mode) {
        case 'month':
          tmpDate = action === 'next' ? addAMonth(tmpDate) : subAMonth(tmpDate);
          break;
        case 'day':
          tmpDate = action === 'next' ? addADay(tmpDate) : subADay(tmpDate);
          break;
        case 'week':
          tmpDate = action === 'next' ? addAWeek(tmpDate) : subAWeek(tmpDate);
          break;
        default:
          break;
    }
    return tmpDate;
}

export const confirmState = (state: any) => {
    if(state[0].startDate <= state[0].endDate){
        return state;
    }

    let tmpDate = new Date(JSON.parse(JSON.stringify(state[0].startDate)));
    state[0].startDate = state[0].endDate;
    state[0].endDate = tmpDate;
    return state;
}