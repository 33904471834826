import React, { SVGProps } from "react";

const Logout: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_166_658)">
      <path
        d="M17 7.4538L15.59 8.8638L18.17 11.4538H8V13.4538H18.17L15.59 16.0338L17 17.4538L22 12.4538L17 7.4538ZM4 5.4538H12V3.4538H4C2.9 3.4538 2 4.3538 2 5.4538V19.4538C2 20.5538 2.9 21.4538 4 21.4538H12V19.4538H4V5.4538Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_166_658">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.453796)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Logout;
