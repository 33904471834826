import React, { SVGProps } from 'react';

const Nudge: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="35" height="48" viewBox="0 0 35 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.48 17.4199C25.76 18.4199 28.36 19.5699 29.44 20.1099C31.47 21.1199 32 23.0599 32 23.9999V26.9999H30V23.9999C30 23.5999 29.73 22.4799 28.55 21.8899C27.52 21.3799 24.98 20.2499 22.68 19.2499L23.48 17.4199ZM8.59 17.4199L9.4 19.2499L3.43 21.8999C3.21 22.0099 2.84 22.2699 2.52 22.6599C2.21 23.0399 2 23.4899 2 23.9999V26.9999C2 27.2699 2.12 27.7599 2.52 28.1799C2.9 28.5799 3.62 28.9999 5 28.9999H9V30.9999H5C3.18 30.9999 1.9 30.4199 1.08 29.5699C0.28 28.7399 0 27.7299 0 26.9999V23.9999C0 22.9099 0.46 22.0199 0.98 21.3899C1.49 20.7599 2.11 20.3299 2.56 20.0999L2.58 20.0899L8.59 17.4199Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16 2C11.03 2 7 6.03 7 11C7 15.97 11.03 20 16 20C16.62 20 17.22 19.94 17.8 19.82L18.2 21.78C17.49 21.92 16.75 22 16 22C9.92 22 5 17.08 5 11C5 4.92 9.92 0 16 0C22.08 0 27 4.92 27 11C27 14.6 25.27 17.79 22.6 19.8L21.4 18.2C23.59 16.56 25 13.94 25 11C25 6.03 20.97 2 16 2Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9793 30.09V15C16.9793 13.34 18.3193 12 19.9793 12C21.6393 12 22.9793 13.34 22.9793 15V22.17C23.2893 22.06 23.6293 22 23.9793 22C25.3593 22 26.5293 22.94 26.8693 24.21C27.2093 24.07 27.5793 24 27.9793 24C29.3593 24 30.5293 24.94 30.8693 26.21C31.2093 26.07 31.5793 26 31.9793 26C33.6393 26 34.9793 27.34 34.9793 29V37C34.9793 43.08 30.0593 48 23.9793 48C21.1493 48 18.4593 46.74 16.6593 44.56L7.82935 33.92C6.92935 32.84 6.74935 31.33 7.35935 30.07L7.42935 29.92C7.98935 28.75 9.17935 28 10.4793 28C11.6793 28 12.8493 28.42 13.7693 29.19L15.6193 30.73C15.9693 31.02 16.4893 30.98 16.7893 30.62C16.9093 30.48 16.9793 30.29 16.9793 30.09ZM18.9793 30.09V15C18.9793 14.45 19.4293 14 19.9793 14C20.5293 14 20.9793 14.45 20.9793 15V31C20.9793 31.55 21.4293 32 21.9793 32C22.5293 32 22.9793 31.55 22.9793 31V25C22.9793 24.45 23.4293 24 23.9793 24C24.5293 24 24.9793 24.45 24.9793 25V31C24.9793 31.55 25.4293 32 25.9793 32C26.5293 32 26.9793 31.55 26.9793 31V27C26.9793 26.45 27.4293 26 27.9793 26C28.5293 26 28.9793 26.45 28.9793 27V31C28.9793 31.55 29.4293 32 29.9793 32C30.5293 32 30.9793 31.55 30.9793 31V29C30.9793 28.45 31.4293 28 31.9793 28C32.5293 28 32.9793 28.45 32.9793 29V37C32.9793 41.97 28.9493 46 23.9793 46C21.7393 46 19.6193 45 18.1993 43.28L9.36935 32.64C8.96935 32.16 8.88935 31.49 9.15935 30.93L9.22935 30.78C9.45935 30.31 9.94935 30 10.4793 30C11.2193 30 11.9293 30.26 12.4893 30.73L14.3393 32.27C15.5393 33.27 17.3193 33.11 18.3193 31.91C18.7493 31.4 18.9793 30.76 18.9793 30.09Z" fill="#003993"/>
  </svg>
  
);

export default Nudge;
