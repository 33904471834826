import React, { SVGProps } from 'react';

const Survey: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13 19C13 20.66 11.66 22 10 22C8.34 22 7 20.66 7 19C7 17.34 8.34 16 10 16C11.66 16 13 17.34 13 19ZM15 19C15 21.76 12.76 24 10 24C7.24 24 5 21.76 5 19C5 16.24 7.24 14 10 14C12.76 14 15 16.24 15 19ZM7 28C6.45 28 6 28.45 6 29C6 29.55 6.45 30 7 30H25C25.55 30 26 29.55 26 29C26 28.45 25.55 28 25 28H7ZM7 34C6.45 34 6 34.45 6 35C6 35.55 6.45 36 7 36H25C25.55 36 26 35.55 26 35C26 34.45 25.55 34 25 34H7ZM6 41C6 40.45 6.45 40 7 40H15C15.55 40 16 40.45 16 41C16 41.55 15.55 42 15 42H7C6.45 42 6 41.55 6 41Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.7103 15.2903C28.1003 15.6803 28.1003 16.3103 27.7103 16.7003L23.7103 20.7003C23.3203 21.0903 22.6903 21.0903 22.3003 20.7003L20.3003 18.7003C19.9103 18.3103 19.9103 17.6803 20.3003 17.2903C20.6903 16.9003 21.3203 16.9003 21.7103 17.2903L23.0003 18.5903L26.2903 15.3003C26.6803 14.9003 27.3203 14.9003 27.7103 15.2903Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25 15C25 15.55 24.55 16 24 16H20C19.45 16 19 16.45 19 17V21C19 21.55 19.45 22 20 22H24C24.55 22 25 21.55 25 21C25 20.45 25.45 20 26 20C26.55 20 27 20.45 27 21C27 22.66 25.66 24 24 24H20C18.34 24 17 22.66 17 21V17C17 15.34 18.34 14 20 14H24C24.55 14 25 14.45 25 15Z" fill="#003993"/>
    <path d="M4 8.1C2.9 8.1 2 9.04 2 10.2V43.8C2 44.96 2.9 45.9 4 45.9H28C29.1 45.9 30 44.96 30 43.8V10.2C30 9.04 29.1 8.1 28 8.1V6C30.21 6 32 7.88 32 10.2V43.8C32 46.12 30.21 48 28 48H4C1.79 48 0 46.12 0 43.8V10.2C0 7.88 1.79 6 4 6V8.1Z" fill="#007BC3"/>
    <path d="M28 6H26V8.1H28V6Z" fill="#007BC3"/>
    <path d="M6 6H4V8.1H6V6Z" fill="#007BC3"/>
    <path d="M7 7.05C7 7.63 6.55 8.1 6 8.1C5.45 8.1 5 7.63 5 7.05C5 6.47 5.45 6 6 6C6.55 6 7 6.47 7 7.05Z" fill="#007BC3"/>
    <path d="M27 7.05C27 7.63 26.55 8.1 26 8.1C25.45 8.1 25 7.63 25 7.05C25 6.47 25.45 6 26 6C26.55 6 27 6.47 27 7.05Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22 6H10V8H22V6ZM10 4C8.9 4 8 4.9 8 6V8C8 9.1 8.9 10 10 10H22C23.1 10 24 9.1 24 8V6C24 4.9 23.1 4 22 4H10Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16 2C14.9 2 14 2.9 14 4H12C12 1.79 13.79 0 16 0C18.21 0 20 1.79 20 4H18C18 2.9 17.1 2 16 2Z" fill="#007BC3"/>
  </svg>
  
);

export default Survey;
