/**
 * API 特賃日パターンマスタ
 */
export interface SpecialDateMaster {
  specialSalaryPatternId: string;
  specialSalaryPatternCode: string;
  specialSalaryPatternName: string;
  patOrgList: [
    {
      specialSalaryPatternId: string;
      orgName: string;
    },
  ];

  holidayFlag: boolean;
  beforeHolidayFlag: boolean;
  saturdayFlag: boolean;
  sundayFlag: boolean;
  mondayFlag: boolean;
  tuesdayFlag: boolean;
  wednesdayFlag: boolean;
  thursdayFlag: boolean;
  fridayFlag: boolean;
}
export default class SpecialDateMasterDomain {
  constructor(private rawData: SpecialDateMaster) {
    //
  }

  static generateInitial(): SpecialDateMasterDomain {
    return new SpecialDateMasterDomain({
      specialSalaryPatternId: "",
      specialSalaryPatternCode: "",
      specialSalaryPatternName: "",
      patOrgList: [
        {
          specialSalaryPatternId: "",
          orgName: "",
        },
      ],
      holidayFlag: false,
      beforeHolidayFlag: false,
      saturdayFlag: false,
      sundayFlag: false,
      mondayFlag: false,
      tuesdayFlag: false,
      wednesdayFlag: false,
      thursdayFlag: false,
      fridayFlag: false,
    });
  }

  getRowData(): SpecialDateMaster {
    return this.rawData;
  }

  get specialSalaryPatternId(): string {
    return this.rawData.specialSalaryPatternId;
  }

  set specialSalaryPatternId(specialSalaryPatternId: string) {
    this.rawData.specialSalaryPatternId = specialSalaryPatternId;
  }

  get specialSalaryPatternCode(): string {
    return this.rawData.specialSalaryPatternCode;
  }

  set specialSalaryPatternCode(specialSalaryPatternCode: string) {
    this.rawData.specialSalaryPatternCode = specialSalaryPatternCode;
  }

  get specialSalaryPatternName(): string {
    return this.rawData.specialSalaryPatternName;
  }

  set specialSalaryPatternName(specialSalaryPatternName: string) {
    this.rawData.specialSalaryPatternName = specialSalaryPatternName;
  }

  get patOrgList(): string {
    let name = "";
    this.rawData.patOrgList.map((item) => {
      name += ` ${item.orgName},`;
      return name;
    });
    name = name.substring(0, name.length - 1);
    return name;
  }

  set patOrgList(orgName: string) {
    this.rawData.patOrgList[0].orgName = orgName;
  }

  get holidayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set holidayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get beforeHolidayFlag(): boolean {
    return this.rawData.beforeHolidayFlag;
  }

  set beforeHolidayFlag(beforeHolidayFlag: boolean) {
    this.rawData.beforeHolidayFlag = beforeHolidayFlag;
  }

  get saturdayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set saturdayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get sundayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set sundayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get mondayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set mondayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get tuesdayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set tuesdayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get wednesdayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set wednesdayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get thursdayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set thursdayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get fridayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set fridayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }
}
