/** @jsx jsx */
import moment from "moment";
import React, { useEffect, useState, createContext } from "react";
import { css, jsx } from "@emotion/core";
import TabControl from "components/atoms/TabControl";
import BlockUI from "components/molecules/BlockUi";
import { utilityColor } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import ApplyingTab from "./ApplyingTab";
import ApprovedTab from "./ApprovedTab";
import RejectedTab from "./RejectedTab";
import FormContents from "components/atoms/Form/FormContents";
import useAttendApplicationApproval from "./hooks";
import SelectCalendar from "./SelectCalendar/SelectCalendar";
import { setInitClosingDate } from "../setInitClosingDate";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import { DetailRole } from "api/masterRole";

type SelectType = "applying" | "approved" | "rejected";

const SelectType: { [key in SelectType]: SelectType } = {
  applying: "applying",
  approved: "approved",
  rejected: "rejected",
};

// create style form
const styles = {
  container: css({
    paddingBottom: "5px",
    backgroundColor: utilityColor.white,
  }),
  label: css({
    flexBasis: "100px",
  }),
  digestionDays: css({
    width: "200px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
  }),
  wrapAccordion: css({
    marginTop: "-16px",
  }),
};

type AttendApplicationListContextType = {
  detailRole: DetailRole;
};

export const AttendApplicationListContext = createContext<AttendApplicationListContextType>(
  {} as AttendApplicationListContextType,
);

const AttendApplicationListV3: React.FC = () => {
  const [selectType, setSelectType] = useState(SelectType.applying);

  const {
    isLoading,
    period,
    setPeriod,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    targetMonth,
    setTargetMonth,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingAttendAllowanceList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,
    applyingStockPaidList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedAttendAllowanceList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,
    approvedStockPaidList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedAttendAllowanceList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,
    rejectedStockPaidList,

    fetchData,

    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingAttendAllowanceList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,
    setApplyingStockPaidList,
    detailRole,
  } = useAttendApplicationApproval();

  const closingDate = useSelector((state: Store) => state.loginUser.loginUser.closingDate);
  const [value, setValue] = useState(sessionStorage.getItem("applicationListPeriod") || "monthly");

  useEffect(() => {
    fetchData(selectType, period);
  }, [selectType, period, targetDateFrom, targetDateTo, targetMonth]);

  function hanleOnChange(value: any) {
    setValue(value);
  }

  function resetDate() {
    setValue("monthly");
    sessionStorage.setItem("applicationListPeriod", "monthly");
    const { firstDayStr, lastDayStr } = setInitClosingDate(closingDate, targetMonth);
    setTargetDateFrom(new Date(firstDayStr));
    setTargetDateTo(new Date(lastDayStr));
    sessionStorage.setItem("applicationTargetDayFrom", moment(firstDayStr).format("YYYY-MM-DD"));
    sessionStorage.setItem("applicationTargetDayTo", moment(lastDayStr).format("YYYY-MM-DD"));
  }

  function handleChangeSelectType(type: any) {
    setSelectType(type);
    resetDate();
  }

  return (
    <AttendApplicationListContext.Provider value={{ detailRole }}>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle title="勤怠申請一覧" bold={true} />
          <div css={styles.container}>
            <TabControl
              items={[
                {
                  value: SelectType.applying,
                  display: `${
                    selectType === SelectType.applying
                      ? `申請中(${applyingStampList.length +
                          applyingHolidayList.length +
                          applyingOvertimeList.length +
                          applyingAttendAllowanceList.length +
                          applyingHolidayWorkList.length +
                          applyingTransferList.length +
                          applyingAgreement36List.length +
                          applyingWorkScheduleChangeList.length +
                          applyingStockPaidList.length}件)`
                      : "申請中"
                  }`,
                },
                {
                  value: SelectType.approved,
                  display: `${
                    selectType === SelectType.approved
                      ? `承認済(${approvedStampList.length +
                          approvedHolidayList.length +
                          approvedOvertimeList.length +
                          approvedAttendAllowanceList.length +
                          approvedHolidayWorkList.length +
                          approvedTransferList.length +
                          approvedAgreement36List.length +
                          approvedWorkScheduleChangeList.length +
                          approvedStockPaidList.length}件)`
                      : "承認済"
                  }`,
                },
                {
                  value: SelectType.rejected,
                  display: `${
                    selectType === SelectType.rejected
                      ? `差戻(${rejectedStampList.length +
                          rejectedHolidayList.length +
                          rejectedOvertimeList.length +
                          rejectedAttendAllowanceList.length +
                          rejectedHolidayWorkList.length +
                          rejectedTransferList.length +
                          rejectedAgreement36List.length +
                          rejectedWorkScheduleChangeList.length +
                          rejectedStockPaidList.length}件)`
                      : "差戻"
                  }`,
                },
              ]}
              setValue={handleChangeSelectType}
              currentValue={selectType}
              // customStyle={css({
              //   margin: "0px",
              //   padding: "8px 16px",
              //   boxSizing: "border-box",
              //   width: "100%",
              //   overflow: "auto",
              // })}
            />
          </div>

          <div css={styles.wrapAccordion}>
            <SelectCalendar
              value={value}
              onChange={hanleOnChange}
              setPeriod={setPeriod}
              targetDateFrom={targetDateFrom}
              setTargetDateFrom={setTargetDateFrom}
              targetDateTo={targetDateTo}
              setTargetDateTo={setTargetDateTo}
              targetMonth={targetMonth}
              setTargetMonth={setTargetMonth}
            />
            {/* 要承認 */}
            {selectType === SelectType.applying && (
              <ApplyingTab
                stampList={applyingStampList}
                holidayList={applyingHolidayList}
                overtimeList={applyingOvertimeList}
                attendAllowanceList={applyingAttendAllowanceList}
                holidayWorkList={applyingHolidayWorkList}
                transferList={applyingTransferList}
                agreement36List={applyingAgreement36List}
                workScheduleList={applyingWorkScheduleChangeList}
                stockPaidList={applyingStockPaidList}
                // set list
                setApplyingTransferList={setApplyingTransferList}
                setApplyingStampList={setApplyingStampList}
                setApplyingHolidayList={setApplyingHolidayList}
                setApplyingOvertimeList={setApplyingOvertimeList}
                setApplyingHolidayWorkList={setApplyingHolidayWorkList}
                setApplyingAgreement36List={setApplyingAgreement36List}
                setApplyingWorkScheduleList={setApplyingWorkScheduleChangeList}
                setApplyingStockPaidList={setApplyingStockPaidList}
              />
            )}

            {/* 承認済 */}
            {selectType === SelectType.approved && (
              <ApprovedTab
                stampList={approvedStampList}
                holidayList={approvedHolidayList}
                overtimeList={approvedOvertimeList}
                attendAllowanceList={approvedAttendAllowanceList}
                holidayWorkList={approvedHolidayWorkList}
                transferList={approvedTransferList}
                agreement36List={approvedAgreement36List}
                workScheduleList={approvedWorkScheduleChangeList}
                stockPaidList={approvedStockPaidList}
              />
            )}

            {/* 差戻済 */}
            {selectType === SelectType.rejected && (
              <RejectedTab
                stampList={rejectedStampList}
                holidayList={rejectedHolidayList}
                overtimeList={rejectedOvertimeList}
                attendAllowanceList={rejectedAttendAllowanceList}
                holidayWorkList={rejectedHolidayWorkList}
                transferList={rejectedTransferList}
                agreement36List={rejectedAgreement36List}
                workScheduleList={rejectedWorkScheduleChangeList}
                stockPaidList={rejectedStockPaidList}
              />
            )}
          </div>
        </FormContents>
      </BlockUI>
    </AttendApplicationListContext.Provider>
  );
};

export default AttendApplicationListV3;
