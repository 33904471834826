import { useState, useCallback, useEffect } from 'react';

import { getList, getListByYm } from 'api/employment';
import moment from 'moment';
import EmploymentDomain, { Employment } from 'domain/employment';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useEmploymentList = () => {
  const [employmentList, setEmploymentList] = useState<Array<EmploymentDomain>>([]);
  const [allEmployment, setAllEmployment] = useState<any>(null);
  const [applyDate, setApplyDate] = useState<any>(null);
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterEmploymentForm);


  // データの取得
  const fetchData = useCallback(async () => {
    if(applyDate){
      const applyDateStr : string = moment(applyDate).format('YYYY-MM-01');
      setBlocking(true);
      if (allEmployment) {
        const response = await getList();
        setEmploymentList(response.map((result: Employment) => new EmploymentDomain(result)));
      } else {
        const response = await getListByYm(
          applyDateStr,
        );
        setEmploymentList(response.map((result: Employment) => new EmploymentDomain(result)));
      }
      setBlocking(false);
    }
  }, [allEmployment, applyDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleAllEmployment = useCallback(() => {
    setAllEmployment(!allEmployment);
  }, [allEmployment]);

  return {
    employmentList,
    fetchData,
    allEmployment,
    toggleAllEmployment,
    applyDate,
    setApplyDate,
    blocking,
    detailRole
  };
};

export default {};
