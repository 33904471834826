/** @jsx jsx */
import { Route, RouteProps, Redirect } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import RevisionDomain from "domain/revision";
import _ from "lodash";
import React, { useState, useEffect, createContext } from "react";
import LoginUserDomain from "domain/loginUser";
import moment from "moment";
import { getRevision } from "./api/revision";
import { getLoginUser } from "api/singleLogin";
import useToastNotification from "hooks/useToastNotification";
import { useHistory, useLocation } from "react-router-dom";
import { getPeopleUserInfo, getOAuthCode } from "hooks/usePKCEOAuth";
import { Auth } from "aws-amplify";
import getItem, { getUserLoginStatus, saveAccessHistory, saveLoginHistory } from "api/login";
import { getBreakId } from "api/getBreakId";
import clearLocalStorageAfterLogout from "hooks/useClearLocalStorageAfterLogout";
import { useDispatch } from "react-redux";
import { uiAppBarActions } from "modules/ui/appBar";
import Modal from "components/molecules/Modal";
import { Link } from "react-router-dom";
import productColor, { grayScale } from "components/styles";
import Icon from "components/atoms/Icon";
import { generalActions } from "modules/ui/general";
import { functionCode } from "const";
import { loginUserActions } from "modules/loginUser";
import jwt_decode, { JwtHeader } from "jwt-decode";
import useIsMobile from "hooks/useIsMobile";
import jwt, { SigningKeyCallback } from "jsonwebtoken";
import axios from "axios";
import jwkToPem from "jwk-to-pem";

declare let gRevision: string;
const REVISION_KEY: string = "EC_APP_REVISION";

/**
 * リビジョン管理用 ローカルストレージ保存
 *
 * @param obj セッション格納オブジェクト
 */
function setLocalStorageRevision(obj: any) {
  localStorage.setItem(
    REVISION_KEY,
    JSON.stringify({ ...JSON.parse(localStorage.getItem(REVISION_KEY) || "{}"), ...obj }),
  );
}

/**
 * リビジョン管理用 ローカルストレージ取得
 *
 * @param key
 * @returns key セッションキー
 */
function getLocalStorageRevision(key: string) {
  if (
    !JSON.parse(localStorage.getItem(REVISION_KEY) || "{}") ||
    !JSON.parse(localStorage.getItem(REVISION_KEY) || "{}")[key]
  ) {
    return false;
  }
  return JSON.parse(localStorage.getItem(REVISION_KEY) || "{}")[key];
}

/**
 * ログイン中のユーザ情報を取得
 */
// async function getUserInfo() {
//   const userInfo = await Auth.currentAuthenticatedUser();
//   console.log(`userInfo = ${userInfo}`);
//   return userInfo;
// }

type PrivateContextType = {
  groupUrl: string;
};

export const PrivateContext = createContext<PrivateContextType>({} as PrivateContextType);

const PrivateRoute: React.FC<RouteProps> = (props) => {
  // redux
  const dispatch = useDispatch();

  const rest = _.omit(props, ["component"]);

  // let moveApproval: boolean = false;
  // const location = useLocation();
  const { errorNotification } = useToastNotification();
  const [loginUser, setLoginUser] = useState<LoginUserDomain | any>(null);
  const history = useHistory();
  const isMobile = useIsMobile();
  const [groupUrl, setGroupUrl] = useState<string>("");
  const redirectPeopleLogin = process.env.REACT_APP_PEOPLE_URL || "";
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE || false;
  const [isAlert, setIsAlert] = useState(false);

  let loginPath: string = "/login";
  let moveApproval: boolean = false;
  const location = useLocation();
  const previous = sessionStorage.getItem("path") || "";

  const queryParams = new URLSearchParams(location.search);
  const isNotAlertClosingDate = queryParams.get("isAlertClosingDate") === "false";

  useEffect(() => {
    if (!loginUser) return;

    let isAlerted = sessionStorage.getItem("loginUser.isAlerted") === "true";

    if (!isAlerted && !isNotAlertClosingDate && loginUser.isAlertClosingDate) {
      sessionStorage.setItem("loginUser.isAlerted", "true");
      setIsAlert(true);
    }

    if (isNotAlertClosingDate) {
      sessionStorage.setItem("loginUser.isAlerted", "true");
    }
  }, [loginUser]);

  useEffect(() => {
    if (isMobile || location.pathname !== "/personalSetting") {
      let url = location.pathname.substring(1).split("/")[0];
      if (loginUser?.functionCodeList?.includes(functionCode.masterAttendSetting) && url === "masterOrganizeSetting") {
        url = "masterAttendSetting";
      }
      setGroupUrl(url);
    }
  }, [location, loginUser]);

  useEffect(() => {
    let localAccessToken = localStorage.getItem("ACCESS_TOKEN");
    if (isAuthorizedFromPeople === "true" && localAccessToken !== null && localAccessToken !== undefined) {
      getUserLoginStatus()
        .then((res) => {
          let logStatus = res;
          localStorage.setItem("loginUser.isLogged", logStatus);
          if (logStatus === "false" || logStatus == false) {
            history.replace("/logout");
          }
        })
        .catch((err: any) => {
          console.log("checkLoginError", err.response);
          errorNotification(err.response.data.defaultMessage);
        });
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.split("/")[1] !== (previous ? previous.split("/")[1] : "")) {
      for (const key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key)) {
          if (key.search(`sessionData${previous}`) !== -1 && "loginUser".indexOf(key) === -1) {
            sessionStorage.removeItem(key);
          }
        }
      }
    }
    sessionStorage.setItem("path", location.pathname);
    window.addEventListener("beforeunload", () => {
      for (const key in sessionStorage) {
        if (sessionStorage.hasOwnProperty(key)) {
          if (key.search(`sessionData${previous}`) !== -1 && "loginUser".indexOf(key) === -1) {
            sessionStorage.removeItem(key);
          }
        }
      }
    });
  }, [previous, location]);

  function renderNewListfunctionCode(functionCode: any) {
    const newListfunctionCode: any = [];
    const objectInArray = Object.entries(functionCode);
    objectInArray.forEach(([key, value]) => {
      newListfunctionCode.push({
        id: value,
        key: key,
      });
    });
    return newListfunctionCode;
  }

  function checkScreenDefault(pathCurrent: any, resopnse: any) {
    let result = true;
    if (pathCurrent === "personalSetting") {
      result = false;
    }
    if (pathCurrent === "recorder") {
      if (!resopnse.useGpsStamp && !resopnse.useInsideLoginBarcodeStamp && !resopnse.useInsideLoginFaceRecorderStamp) {
        history.push("/404");
      } else {
        result = false;
      }
    }
    return result;
  }

  function checkPermissionUser(response: any) {
    const pathCurrent = location.pathname.split("/")[1];
    const newListfunctionCode = renderNewListfunctionCode(functionCode);

    const listScreen: any = [];
    newListfunctionCode.map((item: any) =>
      response.functionCodeList.map((option: any) => {
        if (item.id === option) {
          listScreen.push(item.key);
        }
      }),
    );
    if (listScreen.includes("masterAttendSetting")) {
      listScreen.push("masterOrganizeSetting");
    }
    if (pathCurrent && checkScreenDefault(pathCurrent, response)) {
      if (listScreen.indexOf(String(pathCurrent)) === -1) {
        // history.push("/404");
      }
    }
  }

  // Save values on screen during operation and assign default values when returning to the screen
  if (
    ["/agreementMaster", "/holiday/create", "/holiday/import", "/holiday/edit/:publicHolidayId", "/holiday"].indexOf(
      String(rest.path),
    ) !== -1
  ) {
    if (!sessionStorage.getItem("selectedOrgCodeHolidayMaster")) {
      sessionStorage.setItem("selectedOrgCodeHolidayMaster", "all");
    }
    if (!sessionStorage.getItem("selectedYearHolidayMaster")) {
      sessionStorage.setItem("selectedYearHolidayMaster", new Date().getFullYear().toString());
    }
  } else {
    sessionStorage.removeItem("selectedOrgCodeHolidayMaster");
    sessionStorage.removeItem("selectedYearHolidayMaster");
  }

  if (
    ["/masterShiftPattern", "/masterShiftPattern/add", "/masterShiftPattern/:typeButton/:shiftPatternId"].indexOf(
      String(rest.path),
    ) === -1
  ) {
    sessionStorage.removeItem("shiftPatternOrgCode");
  }

  //
  if (
    [
      "/organizations",
      "/organizations/add",
      "/organizations/csv",
      "/organizations/:orgCode/specialsalary",
      "/organizations/:orgCode/managers",
      "/organizations/:orgCode",
    ].indexOf(String(rest.path)) === -1
  ) {
    sessionStorage.removeItem("organizations.noManager");
    sessionStorage.removeItem("organizations.unsetTargetDate");
    sessionStorage.removeItem("organizations.orgStatus");
  }

  if (
    [
      "/masterEmploymentForm",
      "/masterEmploymentForm/add",
      "/masterEmploymentForm/edit/:isEdit/:employmentId/:applyStartDate",
    ].indexOf(String(rest.path)) === -1
  ) {
    sessionStorage.removeItem("masterEmploymentForm.applyDate");
    sessionStorage.removeItem("masterEmploymentForm.applyDate");
  }

  if (
    [
      "/attendResult",
      "/attendStampList",
      "/attendStampAggregation",
      "/attendStampModificationApplication",
      "/attendStampModificationApplication",
      "/attendHolidayApplication",
      "/attendOvertimeApplication",
      "/attendHolidayworkApplication",
      "/attendTransferApplication",
    ].indexOf(String(rest.path)) === -1
  ) {
    sessionStorage.removeItem("attendResult.selectOrgCode");
    sessionStorage.removeItem("attendResult.searchTargetDateFrom");
    sessionStorage.removeItem("attendResult.searchTargetDateTo");
  }

  if (
    ["/approvalModificationApplication/:applicationId"].indexOf(String(rest.path)) !== -1 ||
    ["/approvalHolidayApplication/:applicationId"].indexOf(String(rest.path)) !== -1 ||
    ["/approvalOvertimeApplication/:applicationId"].indexOf(String(rest.path)) !== -1 ||
    ["/approvalHolidayworkApplication/:applicationId"].indexOf(String(rest.path)) !== -1 ||
    ["/approvalTransferApplication/:applicationId"].indexOf(String(rest.path)) !== -1
  ) {
    moveApproval = true;
    loginPath = `/login${location.pathname}`;
  }

  saveAccessHistory(
    sessionStorage.getItem("loginUser.companyCode") || "",
    sessionStorage.getItem("loginUser.staffCode") || "",
    location.pathname,
  );

  // リビジョンチェック
  getRevision().then((response: any) => {
    const revision = new RevisionDomain(response);

    // 現在のリビジョンと取得したリビジョンが異なる場合、リロード
    if (gRevision !== revision.revision) {
      // 無限リロード対策（更新中フラグで管理する）
      const revisionChangingFl = getLocalStorageRevision("revision_changing_fl");
      setLocalStorageRevision({ revision_changing_fl: true });
      if (!revisionChangingFl) {
        // リビジョン更新
        window.location.reload(true);
      }
    } else {
      // リビジョンが一致
      // LocalStorageのリビジョン更新フラグをfalse
      setLocalStorageRevision({ revision_changing_fl: false });
    }
  });

  // SessionStorageに[loginUser.mail]が存在しない場合
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [userInfo, setUserInfo] = useState(null);
  const [accessToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [peopleAccessToken] = useState(localStorage.getItem("PEOPLE_ACCESS_TOKEN"));
  const [tenant] = useState(localStorage.getItem("PEOPLE_TENANT"));

  // const [validPeopleToken, isValidPeopleToken] = useState(false);

  const region = "ap-northeast-1";
  const url = `https://cognito-idp.${region}.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLS_ID ??
    ""}/.well-known/jwks.json`;

  const getKey = async (header: JwtHeader, callback: SigningKeyCallback) => {
    try {
      const response = await axios.get(url);

      const keys = response.data.keys;

      // @ts-ignore
      const signingKey = keys.find((key: any) => key.kid === header.kid);
      if (!signingKey) {
        throw new Error("Signing key not found");
      }

      const publicKey = jwkToPem(signingKey);

      callback(null, publicKey);
    } catch (err) {
      // @ts-ignore
      callback(err, undefined);
    }
  };

  useEffect(() => {
    // 既存ユーザのために、ローカルストレージに会社コードがない場合はセットしておく
    if (!localStorage.getItem("loginUser.companyCode")) {
      localStorage.setItem("loginUser.companyCode", sessionStorage.getItem("loginUser.companyCode") || "");
    }
  }, [location]);

  useEffect(() => {
    if (isAuthorizedFromPeople === "true") {
      const checkExpiredToken = sessionStorage.getItem("isExpired") || "false";

      if (peopleAccessToken && accessToken) {
        let idt = localStorage.getItem("ID_TOKEN") || "";
        let decoded = jwt_decode<{ [name: string]: string }>(idt);
        if (decoded === null || decoded.email === null || decoded.email === "") {
          errorNotification("Invalid Email.");
          setIsAuthenticated(true);
          history.replace("/logout");
          return;
        }
        let email = decoded.email;

        getPeopleUserInfo(peopleAccessToken, email)
          .then((response: any) => {
            if (response.status !== 200 || response.data["email"] === null || response.data["email"] === "null") {
              errorNotification("Invalid Email.");
              setIsAuthenticated(true);
              history.replace("/logout");
            }
            getLoginUser(response.data["email"], accessToken)
              .then((res: any) => {
                if (res.status !== 200) {
                  history.replace("/logout");
                } else {
                  setLoginUser(new LoginUserDomain(res.data));
                }
                setIsAuthenticated(true);
              })
              .catch((res) => {
                console.log(res);
                setIsAuthenticated(true);
                history.replace("/logout");
              });
          })
          .catch((res) => {
            console.log(res);
            setIsAuthenticated(true);
            history.replace("/logout");
          });
      } else if (checkExpiredToken == "true") {
        clearLocalStorageAfterLogout();
        sessionStorage.clear();
        history.replace("/expired");
        return;
      } else if (tenant === "" || tenant === null) {
        history.replace("/error");
        return;
      } else {
        let tenant = localStorage.getItem("PEOPLE_TENANT") || "";
        clearLocalStorageAfterLogout();
        sessionStorage.clear();
        let callback = window.location.origin + "/callback";
        // let getOAuthStr= getOAuthCodeV2(callback);
        let getOAuthStr = getOAuthCode(callback, tenant);
        // let getOAuthStr= "/error";
        window.location.href = getOAuthStr;
        return;
      }
    } else {
      // DeliousのSSOログインフロー
      const deliousToken = localStorage.getItem("DELIOUS_TOKEN") || "";
      if (deliousToken) {
        const companyCode = sessionStorage.getItem("loginUser.companyCode") ?? "";

        // トークンを検証
        jwt.verify(deliousToken, getKey, {}, (err, decoded: any) => {
          if (err) {
            clearLocalStorageAfterLogout();
            sessionStorage.clear();

            errorNotification("セッションがタイムアウトしました。再度ログインしてください。");

            history.replace(`/login/${companyCode}`);
          } else {
            localStorage.setItem("ID_TOKEN", deliousToken);
            const email = decoded.email;

            getLoginUser(email, deliousToken, companyCode)
              .then((res: any) => {
                if (res.status !== 200) {
                  history.replace("/logout");
                } else {
                  checkPermissionUser(res.data);
                  const loginUser = new LoginUserDomain(res.data);
                  setLoginUser(loginUser);

                  setIsAuthenticated(true);
                }
              })
              .catch((res) => {
                console.error(res);
                history.replace("/logout");
              });
          }
        });

        return;
      } else {
        // 通常の認証フロー
        Auth.currentAuthenticatedUser()
          .then((user: any) => {
            if (user) {
              const { attributes } = user;
              const companyCode = sessionStorage.getItem("loginUser.companyCode") ?? "";
              getItem(attributes.email, "HQ", companyCode)
                .then((response: any) => {
                  checkPermissionUser(response);
                  setLoginUser(new LoginUserDomain(response));
                  sessionStorage.setItem(
                    "loginUser.approverNotSkipApproveFlag",
                    String(response.approverNotSkipApproveFlag ? true : false),
                  );
                })
                .catch((error: any) => {
                  setIsAuthenticated(true);
                });
            } else {
              setIsAuthenticated(true);
            }
          })
          .catch((error: any) => {
            setIsAuthenticated(true);
          });
      }
    }
  }, [isAuthenticated, location]);
  const [hasLoginUser, setGetLoginUser] = useState(false);
  useEffect(() => {
    if (loginUser) {
      const deliousToken = localStorage.getItem("DELIOUS_TOKEN") || "";
      if (isAuthorizedFromPeople === "true") {
        localStorage.setItem("loginUser.isLogged", loginUser.isLogged);
      }

      if (deliousToken) {
        localStorage.setItem("loginUser.isLogged", "true");
      }

      // sessionStorage.setItem("loginUser.isLogged", loginUser.isLogged);
      dispatch(loginUserActions.setLoginUser(loginUser));
      sessionStorage.setItem("loginUser.mail", loginUser.mail);
      sessionStorage.setItem("loginUser.companyCode", loginUser.companyCode);
      sessionStorage.setItem("loginUser.staffCode", loginUser.staffCode);
      sessionStorage.setItem("loginUser.staffName", loginUser.staffName);
      sessionStorage.setItem("loginUser.isHavingApprovalPrivilege", String(loginUser.isHavingApprovalPrivilege));
      sessionStorage.setItem("loginUser.executiveId", loginUser.executiveId);
      sessionStorage.setItem("loginUser.executiveCode", loginUser.executiveCode);
      sessionStorage.setItem("loginUser.executiveName", loginUser.executiveName);
      sessionStorage.setItem("loginUser.orgCode", loginUser.orgCode);
      sessionStorage.setItem("loginUser.orgName", loginUser.orgName);
      sessionStorage.setItem("loginUser.personalRole", loginUser.personalRole);
      sessionStorage.setItem("loginUser.administrator", String(loginUser.administrator));
      sessionStorage.setItem("loginUser.useGpsStamp", String(loginUser.useGpsStamp));
      sessionStorage.setItem("loginUser.useFaceStamp", String(loginUser.useFaceStamp));
      sessionStorage.setItem("loginUser.useBarcodeStamp", String(loginUser.useBarcodeStamp));
      sessionStorage.setItem("loginUser.barcodeId", String(loginUser.barcodeId));
      sessionStorage.setItem(
        "loginUser.useInsideLoginFaceRecorderStamp",
        String(loginUser.useInsideLoginFaceRecorderStamp),
      );
      sessionStorage.setItem("loginUser.useInsideLoginBarcodeStamp", String(loginUser.useInsideLoginBarcodeStamp));
      sessionStorage.setItem("loginUser.allOrganizationAccess", String(loginUser.allOrganizationAccess));
      sessionStorage.setItem("loginUser.workSystem", String(loginUser.workSystem));
      sessionStorage.setItem("loginUser.barcodeId", String(loginUser.barcodeId));
      sessionStorage.setItem("loginUser.isAlertClosingDate", String(loginUser.isAlertClosingDate));
      sessionStorage.setItem("loginUser.alertErrorStartDate", String(loginUser.alertErrorStartDate));
      sessionStorage.setItem("loginUser.alertErrorEndDate", String(loginUser.alertErrorEndDate));
      sessionStorage.setItem("loginUser.employmentCode", String(loginUser.employmentCode));
      sessionStorage.setItem("loginUser.temperature", String(loginUser.temperature || 37.5));

      const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL;
      const photoPath = `${domainUrl}/${sessionStorage.getItem("loginUser.companyCode")}/${sessionStorage.getItem(
        "loginUser.staffCode",
      )}/1.jpg?${moment().valueOf()}`;
      if (!sessionStorage.getItem("loginUser.photoPath")) {
        sessionStorage.setItem("loginUser.photoPath", photoPath);
      }
      dispatch(uiAppBarActions.changeAvatar(photoPath));
      dispatch(generalActions.setLoginUser(loginUser));
      if (loginUser.retired) {
        errorNotification("退職済みのためログインできません");
        saveLoginHistory(loginUser.mail, false, "", "退職済みのためログインできません", loginUser.companyCode);
      } else {
        setGetLoginUser(true);
      }
      setIsAuthenticated(true);
      getBreakId().then((response) => {
        if (response) {
          sessionStorage.setItem("loginUser.breakId", response.businessId || "");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  // const user = getUserInfo();

  // useEffect(() => {
  //   Auth.currentAuthenticatedUser()
  //     .then((user: any) => {
  //       if (user) {
  //         const { attributes } = user;
  //         // const loginUserAuto = sessionStorage.getItem('loginUser');
  //         getItem(attributes.email, 'HQ')
  //           .then((response : any) => {
  //             const loginUser = new LoginUserDomain(response);
  //             // Save staff permission to session
  //             sessionStorage.setItem('loginUser.mail', loginUser.mail);
  //             sessionStorage.setItem('loginUser.companyCode', loginUser.companyCode);
  //             sessionStorage.setItem('loginUser.staffCode', loginUser.staffCode);
  //             sessionStorage.setItem('loginUser.staffName', loginUser.staffName);
  //             sessionStorage.setItem('loginUser.orgCode', loginUser.orgCode);
  //             sessionStorage.setItem('loginUser.personalRole', loginUser.personalRole);
  //             sessionStorage.setItem('loginUser.administrator', String(loginUser.administrator));
  //             sessionStorage.setItem('loginUser.site', String(loginUser.site));
  //             sessionStorage.setItem('loginUser.useGpsStamp', String(loginUser.useGpsStamp));
  //             const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL;
  //             const photoPath = `${domainUrl
  //             }/${sessionStorage.getItem('loginUser.companyCode')
  //             }/${sessionStorage.getItem('loginUser.staffCode')
  //             }/1.jpg?${moment().valueOf()}`;
  //             sessionStorage.setItem('loginUser.photoPath', photoPath);
  //             if (loginUser.retired) {
  //               console.error('退職済みのためログインできません');
  //             } else {
  //               setGetLoginUser(true);
  //             }
  //             // setUserInfo(user);
  //             setIsAuthenticated(true);
  //           })
  //           .catch((error: any) => {
  //             setIsAuthenticated(true);
  //           });
  //       } else {
  //         setIsAuthenticated(true);
  //       }
  //       // console.log(`user = ${user}`);
  //     })
  //     .catch((error: any) => {
  //       setIsAuthenticated(true);
  //     });
  //   // console.log(`isAuthenticated1 = ${isAuthenticated}`);
  // }, [isAuthenticated]);

  // // const state = useContext(LoginContext);

  if (!isAuthenticated) return null;
  return (
    <PrivateContext.Provider value={{ groupUrl }}>
      <Route
        {...rest}
        render={(innerProps) =>
          (isAuthorizedFromPeople === "true" && peopleAccessToken && accessToken) ||
          (isAuthorizedFromPeople !== "true" && hasLoginUser) ? (
            <Route {...props} />
          ) : (
            <Redirect
              to={{
                pathname:
                  isAuthorizedFromPeople === "true" ? redirectPeopleLogin : !moveApproval ? "/login" : loginPath,
                state: { from: innerProps.location },
              }}
            />
          )
        }
      />
      <Modal
        closeHandler={() => {
          setIsAlert(false);
        }}
        closeText=""
        open={isAlert}
        customTitle={
          <React.Fragment>
            <div
              style={{
                fontSize: "24px",
                color: grayScale.gray100,
                position: "relative",
                width: "max-content",
                margin: "auto",
                paddingLeft: "20px",
              }}
            >
              <Icon
                customStyle={css({
                  margin: "0 5px",
                  fontSize: "25px",
                  position: "absolute",
                  left: "-20px",
                  bottom: "4px",
                })}
                size="25px"
                type="warning"
                color={productColor.deleteAction}
              />
              エラー・アラート警告
            </div>
          </React.Fragment>
        }
        title=""
        submitText="閉じる"
        onSubmit={() => {
          setIsAlert(false);
        }}
      >
        <div style={{ textAlign: "center", fontSize: "14px", color: "rgb(102, 102, 102)" }}>
          勤怠締日の時点で、エラーやアラートがあると勤怠を締めることが
          <br />
          できませんので、締日までに必要な
          <Link
            to="/attendError"
            style={{ color: productColor.primary, textDecoration: "none" }}
            onClick={() => {
              setIsAlert(false);
              sessionStorage.setItem("attendError.staffCode", loginUser.staffCode);
              sessionStorage.setItem("attendError.directFromPopupAlert", "true");
            }}
          >
            申請
          </Link>
          をおこなってください。
        </div>
      </Modal>
    </PrivateContext.Provider>
  );
};

export default PrivateRoute;
