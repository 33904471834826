/** @jsx jsx */
import React from "react";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import Checkbox from "components/molecules/Checkbox";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { css, jsx } from "@emotion/core";
import { Row, Container, Col, useScreenClass } from "react-grid-system";
import { useShiftPatternDetailDomainForm } from "./hooks";
import { SubActionButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import IconLabelButton from "components/molecules/IconLabelButton";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const styles = {
  buttonDelete: css({
    alignItems: "center",
    display: "flex",
  }),
  grayoutButton: css({
    opacity: "0.5",
    height: "50px",
    display: "flex",
    alignItems: "center",
  }),
  isStartTimeNextDay: css({
    marginRight: "20px",
  }),
  column: css({
    width: "100px",
  }),
  label: css({
    color: "#333333",
  }),
};

const StaffShiftPatternDetails: React.FC<{
  shiftPatternDetailsList: any;
  attendBusinessOptions: any;
  listTimes: any;
  isCreate?: boolean;
  isDisableAll?: boolean;
  setShiftPatternDetailsList: (dataList: any) => void;
  remote: boolean;
}> = ({
  shiftPatternDetailsList,
  setShiftPatternDetailsList,
  listTimes,
  attendBusinessOptions,
  isCreate = true,
  isDisableAll = false,
  remote,
}) => {
  const { detailList, setDetailList, convertDataOuput, isHaveBusiness } = useShiftPatternDetailDomainForm(
    listTimes,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    attendBusinessOptions,
    isCreate,
  );

  const handleAddShiftPatternDetails = () => {
    if (isDisableAll) {
      return;
    }
    let defaultBusinessId = "";

    const lastIndex = detailList.length - 1;
    const tmpPatternDetails = [...detailList];

    tmpPatternDetails.push({
      shiftPatternDetailId: "",
      shiftPatternId: detailList[lastIndex].shiftPatternId,
      businessId: defaultBusinessId,
      startHourForm: detailList[lastIndex].endHourForm,
      startTimeForm: detailList[lastIndex].endTimeForm,
      isStartTimeNextDay: detailList[lastIndex].isEndTimeNextDay,
      endHourForm: "00",
      endTimeForm: "00",
      isEndTimeNextDay: false,
      createUser: "",
      updateUser: "",
      isRemote: false,
    });

    setDetailList(tmpPatternDetails);
    convertDataOuput(tmpPatternDetails);
  };

  /**
   * 終了時間を変更（次行があれば開始時間に反映）
   *
   */
  const handleChangeStartEndTime = (indexShiftPattern: number, nameField: string, value: any) => {
    if (isDisableAll) {
      return;
    }

    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        item[nameField] = value;
        return true;
      }
      return false;
    });
    const arrOnChange = ["endHourForm", "endTimeForm", "isEndTimeNextDay"];
    if (arrOnChange.indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
   * handleDeleteShift
   *
   */
  const handleDeleteShift = () => {
    if (isDisableAll) {
      return;
    }
    const tmpList = [...detailList];
    tmpList.splice(tmpList.length - 1, 1);
    setDetailList(tmpList);
    convertDataOuput(tmpList);
  };

  /**
   * handleChangeCheckboxNextDay
   *
   */
  const handleChangeCheckboxNextDay = (indexShiftPattern: number, nameField: string, isChange?: boolean) => {
    if (isDisableAll || isChange) return;
    const items = [...detailList];
    items.filter((item: any, index: number) => {
      if (index === indexShiftPattern) {
        if (item[nameField] === true) {
          item[nameField] = false;
        } else {
          item[nameField] = true;
        }
        return true;
      }
      return false;
    });
    if (["endHourForm", "endTimeForm", "isEndTimeNextDay"].indexOf(nameField) !== -1) {
      // 次行があれば開始時間に反映
      if (items.length - 1 > indexShiftPattern) {
        items[indexShiftPattern + 1].startHourForm = items[indexShiftPattern].endHourForm;
        items[indexShiftPattern + 1].startTimeForm = items[indexShiftPattern].endTimeForm;
        items[indexShiftPattern + 1].isStartTimeNextDay = items[indexShiftPattern].isEndTimeNextDay;
      }
    }
    setDetailList(items);
    convertDataOuput(items);
  };

  /**
   * 時間を変更（リストボックスの値を変更）
   *
   */
  const onHandleHiddenButton = (indexShiftPattern: number) => {
    if (detailList.length === 1 || detailList.length !== indexShiftPattern + 1) {
      return true;
    }
    return false;
  };

  const screenClass = useScreenClass();

  return (
    <Container fluid={true}>
      <div className="table-no-border" style={{ fontSize: "14px" }}>
        <div>
          {detailList.map((shiftPattern: any, keyShiftPattern: number) => (
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <div key={keyShiftPattern}>
                <Row>
                  <Col
                    lg={3}
                    md={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "15px",
                      marginBottom: "5px",
                      color: "#666666",
                      top: "-5px",
                    }}
                  >
                    シフト時間{keyShiftPattern + 1}
                  </Col>
                  <Col lg={9} md={12}>
                    <FlexBox direction={"row"}>
                      <FlexBoxItem width="280px">
                        <div css={styles.label}>開始時間</div>
                        <Row
                          style={{
                            alignItems: "center",
                            justifyContent: "left",
                            display: "flex",
                          }}
                        >
                          <Col xs={12} md={4}>
                            <VerticalLabelSelectForm
                              label=""
                              name={`startHour${keyShiftPattern}`}
                              value={String(
                                shiftPattern.startHourForm === "Invalid date" || shiftPattern.startHourForm === ""
                                  ? "00"
                                  : shiftPattern.startHourForm,
                              )}
                              setValue={(val: string) =>
                                handleChangeStartEndTime(keyShiftPattern, "startHourForm", val)
                              }
                              options={hourFormOptions}
                              disable={keyShiftPattern !== 0 || isDisableAll}
                              menuPlacement="bottom"
                            />
                          </Col>
                          <Col xs={12} md={1} style={{ textAlign: "center" }}>
                            :
                          </Col>
                          <Col xs={12} md={4}>
                            <VerticalLabelSelectForm
                              name={`startTime${keyShiftPattern}`}
                              value={String(
                                shiftPattern.startTimeForm === "Invalid date" || shiftPattern.startTimeForm === ""
                                  ? "00"
                                  : shiftPattern.startTimeForm,
                              )}
                              setValue={(val: string) =>
                                handleChangeStartEndTime(keyShiftPattern, "startTimeForm", val)
                              }
                              options={timeFormOptions}
                              disable={keyShiftPattern !== 0 || isDisableAll}
                              placeholder="分"
                              menuPlacement="bottom"
                            />
                          </Col>
                          <Col xs={12} md={3} style={{ paddingLeft: "7px" }}>
                            <Checkbox
                              id={`isStartTimeNextDay${keyShiftPattern}`}
                              name={`isStartTimeNextDay${keyShiftPattern}`}
                              label="翌日"
                              value="true"
                              checked={Boolean(shiftPattern.isStartTimeNextDay)}
                              onChange={() =>
                                handleChangeCheckboxNextDay(
                                  keyShiftPattern,
                                  "isStartTimeNextDay",
                                  keyShiftPattern !== 0,
                                )
                              }
                              disabled={keyShiftPattern !== 0 || isDisableAll}
                            />
                          </Col>
                        </Row>
                      </FlexBoxItem>
                      <FlexBoxItem width="280px" marginLeft="15px">
                        <div css={styles.label}>終了時間</div>
                        <Row
                          style={{
                            alignItems: "center",
                            justifyContent: "left",
                            display: "flex",
                          }}
                        >
                          <Col xs={12} md={4}>
                            <VerticalLabelSelectForm
                              name={`endHour${keyShiftPattern}`}
                              value={String(
                                shiftPattern.endHourForm === "Invalid date" || shiftPattern.endHourForm === ""
                                  ? "00"
                                  : shiftPattern.endHourForm,
                              )}
                              setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "endHourForm", val)}
                              options={hourFormOptions}
                              disable={isDisableAll}
                              menuPlacement="bottom"
                            />
                          </Col>
                          <Col xs={12} md={1} style={{ textAlign: "center" }}>
                            :
                          </Col>
                          <Col xs={12} md={4}>
                            <VerticalLabelSelectForm
                              name={`endTime${keyShiftPattern}`}
                              value={String(
                                shiftPattern.endTimeForm === "Invalid date" || shiftPattern.endTimeForm === ""
                                  ? "00"
                                  : shiftPattern.endTimeForm,
                              )}
                              setValue={(val: string) => handleChangeStartEndTime(keyShiftPattern, "endTimeForm", val)}
                              options={timeFormOptions}
                              disable={isDisableAll}
                              placeholder="分"
                              menuPlacement="bottom"
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            style={{ display: "flex", textAlignLast: "center", paddingLeft: "7px" }}
                          >
                            <Checkbox
                              id={`isEndTimeNextDay${keyShiftPattern}`}
                              name={`isEndTimeNextDay${keyShiftPattern}`}
                              label="翌日"
                              value="true"
                              checked={Boolean(shiftPattern.isEndTimeNextDay)}
                              onChange={() => handleChangeCheckboxNextDay(keyShiftPattern, "isEndTimeNextDay")}
                              disabled={isDisableAll}
                            />
                          </Col>
                        </Row>
                      </FlexBoxItem>
                      <FlexBoxItem width="80px" marginLeft="15px">
                        <div css={styles.label}>休憩</div>
                        <Col
                          md={12}
                          xl={12}
                          lg={12}
                          style={{
                            paddingBottom: ["lg", "xl"].includes(screenClass)
                              ? ""
                              : ["md"].includes(screenClass)
                              ? ""
                              : "8%",
                            height: "50px",
                            display: "flex",
                          }}
                        >
                          <Checkbox
                            id={`businessId${keyShiftPattern}`}
                            name={`businessId${keyShiftPattern}`}
                            label="休憩"
                            value={sessionStorage.getItem("loginUser.breakId") || ""}
                            checked={
                              shiftPattern.businessId === sessionStorage.getItem("loginUser.breakId")
                                ? !isHaveBusiness
                                : isHaveBusiness
                            }
                            onChange={(event) => {
                              let isChecked = event.target.checked;
                              handleChangeStartEndTime(
                                keyShiftPattern,
                                "businessId",
                                isChecked ? sessionStorage.getItem("loginUser.breakId") : "",
                              );
                            }}
                            disabled={isDisableAll}
                          />
                        </Col>
                      </FlexBoxItem>
                      {remote && (
                        <FlexBoxItem
                          width="125px"
                          marginRight="15px"
                          customStyle={css({ paddingBottom: "4px", display: "none" })}
                        >
                          <Col xs={12} md={4} style={{ paddingLeft: "7px", marginTop: "24px" }}>
                            <Checkbox
                              id={`isRemote${keyShiftPattern}`}
                              name={`isRemote${keyShiftPattern}`}
                              label="オフィス勤務"
                              value={""}
                              checked={!shiftPattern.isRemote}
                              onChange={(event) => {
                                let isChecked = event.target.checked;
                                handleChangeStartEndTime(keyShiftPattern, "isRemote", !isChecked);
                              }}
                              readOnly={isDisableAll}
                            />
                          </Col>
                        </FlexBoxItem>
                      )}
                      <FlexBoxItem width="100px">
                        <div
                          style={{ marginTop: "24px" }}
                          css={css(
                            styles.buttonDelete,
                            onHandleHiddenButton(keyShiftPattern) ? styles.grayoutButton : {},
                          )}
                        >
                          <SubActionButton
                            action={1}
                            text="行削除"
                            onClick={() => (onHandleHiddenButton(keyShiftPattern) ? () => {} : handleDeleteShift())}
                            disabled={isDisableAll}
                          />
                        </div>
                      </FlexBoxItem>
                    </FlexBox>
                  </Col>
                </Row>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Row>
        <Col xs={12} md={6}>
          <FormSubmitArea>
            <IconLabelButton
              iconType="clone"
              onClick={handleAddShiftPatternDetails}
              text="行追加"
              disabled={isDisableAll}
              width="fit-content"
            />
          </FormSubmitArea>
        </Col>
      </Row>
    </Container>
  );
};

export default StaffShiftPatternDetails;
