import moment from 'moment';

export type EmploymentDataStaff = {
  id: {
    companyCode: string,
    staffCode: string,
    applyStartDate: Date,
  },
  applyEndDate: Date,
  employmentId: string,
  employmentCode: string,
  employmentName: string,
  dispApplyDate?: any,
  useEmploymentDeemedWorkingHours:boolean,
  deemedWorkingHours: string,
  timeHolidayHourOfDay: string,
  dispDeemedWorkingHours: string,
  dispTimeHolidayHourOfDay: string,
}

export class EmploymentDataStaffDomain {
  constructor(private rawData: EmploymentDataStaff) {
    // do nothing
  }

  get applyStartDate(): Date {
    return this.rawData.id.applyStartDate;
  }

  get applyStartDateStr(): string {
    return moment(this.rawData.id.applyStartDate).format('YYYY年MM月');
  }
  
  set dispApplyDate(dispApplyDate: any) {
    this.rawData.dispApplyDate = dispApplyDate;
  }

  get dispApplyDate(): any {
    return moment(this.rawData.dispApplyDate).format('YYYY年MM月');
  }

  set applyStartDate(applyStartDate: Date) {
    this.rawData.id.applyStartDate = applyStartDate;
  }

  get applyEndDate(): Date {
    return this.rawData.applyEndDate;
  }

  get applyEndDateStr(): string {
    return moment(this.rawData.applyEndDate).format('YYYY年MM月DD日');
  }

  set applyEndDate(applyEndDate: Date) {
    this.rawData.applyEndDate = applyEndDate;
  }

  get employmentId(): string {
    return this.rawData.employmentId;
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get employmentCode(): string {
    return this.rawData.employmentCode;
  }

  set employmentCode(employmentCode: string) {
    this.rawData.employmentCode = employmentCode;
  }

  get employmentName(): string {
    return this.rawData.employmentName;
  }

  set employmentName(employmentName: string) {
    this.rawData.employmentName = employmentName;
  }

  get dispDeemedWorkingHours(): string {
    if(this.rawData.useEmploymentDeemedWorkingHours==true){
      return '雇用形態の日の契約労働時間';
    }else{
//      return Number(this.rawData.deemedWorkingHours)/60+'時間'+Number(this.rawData.deemedWorkingHours)%60+'分';
      return Math.trunc(Number(this.rawData.deemedWorkingHours)/60)+'時間'+Number(this.rawData.deemedWorkingHours)%60+'分';
    }
  }

  get dispTimeHolidayHourOfDay(): string {
    if(this.rawData.timeHolidayHourOfDay==undefined || this.rawData.timeHolidayHourOfDay==''){
      return '0時間';
    }else{
      return this.rawData.timeHolidayHourOfDay+'時間';
    }
  }

}
