import React from 'react';

import { grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';

interface SortIconProps {
  isSortedDesc?: boolean;
}

const SortIcon: React.FC<SortIconProps> = ({ isSortedDesc }) => (
  <>
    {
      isSortedDesc
        ? <Icon type="sortDesc" color={grayScale.gray100} />
        : <Icon type="sortAsc" color={grayScale.gray100} />
    }
  </>
);

export default SortIcon;
