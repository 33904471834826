/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import { logout } from 'api/login';
import Amplify, { Auth } from 'aws-amplify';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import Button from "components/atoms/Button";

const style={
  wrapper: css({   
    width: "100%",
    minHeight: "100vh",
    margin: "0px auto",
    backgroundImage: "url(/login-back-e6ac60b256d2f031c73e8613933e2e8e3e3c8988062d2c05ec8d76bfe535a8ce.svg)",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    overflowX: "hidden",
    minWidth: "320px",
    height: "100%"
  }),
  center: css({
    top:"50%",
    minWidth:"350px",
    width: "100%",
    position: "absolute",
    left: "0%",
    height: "20rem",
    marginTop: "-16rem"
  }),
  textCenter1: css({
    textAlign: "center",
    color: '#333333',
    fontSize: "19px",
    fontWeight: "bold",
    fontFamily:'"Noto Sans Japanese", 游ゴシック体, "Yu Gothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, sans-serif'
  }),
  textCenter2: css({
    textAlign: "center",
    color: '#8c8c8c',
    fontSize: "15px",
    lineHeight: '24px',
    fontFamily:'"Noto Sans Japanese", 游ゴシック体, "Yu Gothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, sans-serif;'
  }),
  paddingTop3: css({
    paddingTop:'3rem'
  }),
  logo: css({
    paddingTop: '12px'
  })
}

const ErrorPage: React.FC = () => {
  
  useEffect(() => {
    document.body.style.overflowY = 'scroll'
    return () => {
      document.body.style.overflowY = 'null';
    }
  }, [])
  return (
    <div css={style.wrapper}>
      <FlexBox customStyle={css(style.center)} alignItems="center"
        direction='column'
        justifyContent = 'center'> 
        <FlexBoxItem>
          <h1 css={css({ margin: "0" })}>
            <img alt="Cydas" src="favicon.ico" css={css({ height: "48px" })}/>
          </h1>
        </FlexBoxItem>
        <FlexBoxItem customStyle={style.logo}>
            <img src="icon-people.svg" alt="People Logo"/>
        </FlexBoxItem>
        <FlexBoxItem customStyle={css(style.paddingTop3)}>
          <p css={style.textCenter1}>ログイン情報を確認できませんでした</p>  
        </FlexBoxItem>
        <FlexBoxItem>
          <p css={style.textCenter2}>『HINATA』を開く場合はCYDAS PEOPLEへログインし、<br/>My PageのPlusメニュー『HINATA』から進んでください。</p>  
        </FlexBoxItem>
      </FlexBox>
    </div>
  );
};

export default ErrorPage;
