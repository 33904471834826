/** @jsx jsx */
import PaidGrantPatternDomain from "domain/master/labor/paidGrantPattern";
import { useMemo, useState, useCallback } from "react";
import { css, jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import { InfoButton, DeleteButton } from "components/atoms/Button";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import { deleteById } from "api/paidGrantPattern";
import useToastNotification from "hooks/useToastNotification";
import usePaidGrantList from "./hooks";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import FormPadding from "components/atoms/Form/FormPadding";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import IconLabelButton from "components/molecules/IconLabelButton";
import FlexBox from "components/atoms/FlexBox";
import Modal from "components/molecules/Modal";
import { grayScale, textFontSize } from "components/styles";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";

const PaidGrantPatternPage = () => {
  const {
    paintGrant,
    setpaintGrant,
    isLoading,
    setLoading,
    detailRole,
  } = usePaidGrantList();

  const [confirmModel, setConfirmModel] = useState(false);
  const [selectedPaidGrant, setselectedPaidGrant] = useState(
    PaidGrantPatternDomain.generateInitial()
  );
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModel = useCallback(() => {
    setConfirmModel(false);
  }, []);
  const openConfirmModal = useCallback((a: PaidGrantPatternDomain) => {
    setConfirmModel(true);
    setselectedPaidGrant(a);
  }, []);

  const deletePaidGrantpattern = useCallback(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    setLoading(true);
    deleteById(companyCode, selectedPaidGrant.paidHolidayPatternId)
      .then((Response: any) => {
        setpaintGrant(
          paintGrant.filter(
            (a) =>
              a.paidHolidayPatternId !== selectedPaidGrant.paidHolidayPatternId
          )
        );
        setLoading(false);
        setConfirmModel(false);
        successNotification("削除しました。");
      })
      .catch((error) => {
        setLoading(false);
        setConfirmModel(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    paintGrant,
    selectedPaidGrant.paidHolidayPatternId,
    setpaintGrant,
    successNotification,
    setLoading,
  ]);
  const columns: Array<Column<PaidGrantPatternDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidGrantPatternDomain };
          data: Array<PaidGrantPatternDomain>;
        }) => {
          return (
            <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
              <Link
                to={`/masterPaidHolidayPattern/edit/${cell.row.original.paidHolidayPatternId}`}
              >
                <InfoButton
                  text={detailRole.editable === 1 ? `編集` : `参照`}
                  onClick={() => {}}
                  minWidth={"40px"}
                  customStyle={css({
                    borderRadius: "3px",
                    fontSize: "12px",
                    lineHeight: "20px",
                    padding: "0 5px",
                    height: "24px",
                  })}
                />
              </Link>
              {detailRole.editable === 1 ? (
                <DeleteButton
                  text="削除"
                  onClick={() => {
                    openConfirmModal(cell.row.original);
                  }}
                  minWidth={"40px"}
                  customStyle={css({
                    borderRadius: "3px",
                    fontSize: "12px",
                    lineHeight: "20px",
                    padding: "0 5px",
                    height: "24px",
                  })}
                />
              ) : null}
            </FlexBox>
          );
        },
      },
      {
        Header: "パターンコード",
        accessor: "paidHolidayPatternCode",
      },
      {
        Header: "パターン名",
        accessor: "paidHolidayPatternName",
      },
    ],
    [openConfirmModal, detailRole]
  );

  return (
    <SidebarTemplate>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle bold={true} title="有給付与パターンマスタ" />
          <FormPadding customStyle={css({ paddingLeft: "35px" })}>
            <div>
              {detailRole.editable === 1 && (
                <FlexBoxItem
                  margin="15px 0"
                  customStyle={css({ display: "flex" })}
                >
                  <Link to="/masterPaidHolidayPattern/add">
                    <IconLabelButton
                      onClick={() => {}}
                      iconType="addCircle"
                      text="追加"
                      padding={"6px 0"}
                      width="auto"
                    />
                  </Link>
                </FlexBoxItem>
              )}
              <Modal
                typeSubmit="reject"
                open={confirmModel}
                closeHandler={closeConfirmModel}
                submitText="削除する"
                title="有給付与パターンマスタを削除"
                onSubmit={deletePaidGrantpattern}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: grayScale.gray60,
                    fontSize: textFontSize.re,
                  }}
                >
                  有給付与パターンマスタを削除します。よろしいですか？
                </div>
              </Modal>
            </div>
          </FormPadding>

          <div
            css={css`
              table {
                table-layout: auto;
              }
              table th:nth-of-type(1) {
                width: 100px;
              }
              table th:nth-of-type(2) {
                width: 100px;
                text-align: left;
              }
              table th:nth-of-type(3) {
                width: calc(100% - 200px);
                text-align: left;
              }
            `}
          >
            <DataTable
              columns={columns}
              data={paintGrant}
              isGlobalFilter={true}
              containerStyle={css({
                overflowX: "auto",
              })}
            />
          </div>
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default PaidGrantPatternPage;
