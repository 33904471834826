/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Table from "components/molecules/Table";
import ContextMenu from "components/molecules/ContextMenu";
import getContextMenuButtonsAgreement36 from "components/molecules/ContextMenu/getContextMenuButtonsAgreement36";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Agreement36Data } from "components/organismos/AttendAgreement36/hooks";

// Convert mm -> h:mm
const formatTime = (time: number) => {
  let hours = Math.floor(time / 60);
  let minutes = time % 60;
  return `${hours >= 10 ? hours : "0" + hours}:${minutes >= 10 ? minutes : "0" + minutes}`;
};

// Convert mm -> h:mm
const formatTimeUnApprove = (time: number) => {
  let hours = Math.floor(time / 60);
  let minutes = time % 60;
  return `${hours >= 10 ? hours : hours}:${minutes >= 10 ? minutes : "0" + minutes} 未承認`;
};

const formatTimeDayMinutes = (dayTmp: number) => {
  if (dayTmp === 0) {
    return "0.0日";
  } else if (!dayTmp) {
    return "-";
  } else {
    return dayTmp.toFixed(1) + "日";
  }
};

const styles = {
  warningCell: css({
    backgroundColor: "#FEF9EA",
  }),
  errorCell: css({
    backgroundColor: "#FDEFEF",
  }),
  grayCell: css({
    backgroundColor: "#F0F0F0",
  }),
};

const getBackgroundApplication = (status: number | null) => {
  switch (status) {
    case 1:
      return { backgroundColor: "rgb(242, 248, 252)" };
    case 0:
      return { backgroundColor: "rgb(230, 255, 247)" };
    default:
      return {};
  }
};

const MobileTableAgreement36: React.FC<{
  tableAgreement36Data: Agreement36Data;
  editable: number;
}> = ({ tableAgreement36Data, editable }) => {
  const history = useHistory();

  return (
    <div
      css={css({
        display: "flex",
        flexDirection: "column",
        gap: 16,
      })}
    >
      <div
        css={css`
          thead tr th {
            padding: 14px 5px;
          }
          td {
            text-align: center;
          }
          tbody tr td:first-child {
            border-left: none;
          }
        `}
      >
        <Table
          css={css({
            marginRight: 16,
            marginLeft: 16,
            width: "calc(100% - 32px)",
            ["thead tr th"]: {
              padding: "14px 5px",
            },
            ["td"]: {
              textAlign: "center",
            },
            ["tbody tr td:first-child"]: {
              borderLeft: "none",
            },
          })}
        >
          <thead>
            <tr>
              <Table.HeaderCell colSpan={8} css={css({ borderBottom: "1px solid #cccccc" })}>
                勤怠
              </Table.HeaderCell>
            </tr>
            <tr>
              <Table.HeaderCell colSpan={2}>
                限度時間
                <br />
                超過申請
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>総労働時間</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>法定外残業時間</Table.HeaderCell>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Table.Cell colSpan={2}>
                {(() => {
                  if (editable === 0) return null;
                  let contextMenuButtons = getContextMenuButtonsAgreement36(
                    history,
                    tableAgreement36Data.monthOverConditionsHour,
                  );
                  return (
                    <div>
                      {tableAgreement36Data.monthOverConditionsHour &&
                        tableAgreement36Data?.monthlyOverApplicationStatus !== 0 && (
                          <ContextMenu text="申請" buttons={contextMenuButtons} disabled={false} />
                        )}
                      {tableAgreement36Data.monthOverConditionsHour &&
                        tableAgreement36Data?.monthlyOverApplicationStatus === 0 && (
                          <ContextMenu action={3} text="申請中" buttons={contextMenuButtons} />
                        )}
                    </div>
                  );
                })()}
              </Table.Cell>
              <Table.Cell colSpan={2}>
                {tableAgreement36Data ? formatTime(Number(tableAgreement36Data.allWorkTime)) : "-"}
              </Table.Cell>
              <Table.Cell
                colSpan={4}
                css={getBackgroundApplication(tableAgreement36Data.monthlyOverApplicationStatus)}
              >
                {tableAgreement36Data?.daytimeWeekdayOvertime
                  ? `${formatTime(Number(tableAgreement36Data.daytimeWeekdayOvertime))} (${formatTimeUnApprove(
                      Number(tableAgreement36Data.dayTimeUnApprovedOverTime),
                    )})`
                  : "-"}
              </Table.Cell>
            </tr>
          </tbody>
        </Table>
      </div>
      <div
        css={css`
          thead tr th {
            padding: 14px 5px;
          }
          td {
            text-align: center;
          }
          tbody tr td:first-child {
            border-left: none;
          }
        `}
      >
        <Table
          css={css({
            marginRight: 16,
            marginLeft: 16,
            width: "calc(100% - 32px)",
            ["thead tr th"]: {
              padding: "14px 5px",
            },
            ["td"]: {
              textAlign: "center",
            },
            ["tbody tr td:first-child"]: {
              borderLeft: "none",
            },
          })}
        >
          <thead>
            <tr>
              <Table.HeaderCell colSpan={12} css={css({ borderBottom: "1px solid #cccccc" })}>
                休暇
              </Table.HeaderCell>
            </tr>
            <tr>
              <Table.HeaderCell colSpan={3}>有給残日数</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>
                有給取得日数 / <br />
                取得義務日数
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={5}>取得義務期間</Table.HeaderCell>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Table.Cell colSpan={3}>
                {tableAgreement36Data?.remainDaysNum
                  ? `${tableAgreement36Data.remainDaysNum && tableAgreement36Data.remainDaysNum.toFixed(1)}日\n${
                      tableAgreement36Data.remainTimesNum
                    }時間`
                  : "-"}
              </Table.Cell>
              <Table.Cell colSpan={4} css={tableAgreement36Data?.digestedDaysNum < 5 ? styles.errorCell : {}}>
                {formatTimeDayMinutes(tableAgreement36Data?.digestedDaysNum)} / 5.0日
              </Table.Cell>
              <Table.Cell colSpan={5} customStyle={css({ padding: "14px 5px" })}>
                {tableAgreement36Data?.effectiveStartDate && tableAgreement36Data?.nextGrantDate ? (
                  <React.Fragment>
                    {tableAgreement36Data.effectiveStartDate == null
                      ? "-"
                      : moment(tableAgreement36Data.effectiveStartDate).format("YYYY年MM月DD日")}
                    <br />〜<br />
                    {tableAgreement36Data.nextGrantDate == null
                      ? "-"
                      : moment(tableAgreement36Data.nextGrantDate).format("YYYY年MM月DD日")}
                  </React.Fragment>
                ) : (
                  "-"
                )}
              </Table.Cell>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MobileTableAgreement36;
