import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';

import LableTitle from 'components/atoms/LableTitle';
import FormContents from 'components/atoms/Form/FormContents';
import { useAreaAddForm } from './hooks';

const AreasAddForm: React.FC = () => {
  const { areaId } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useAreaAddForm(areaId);
  return (
    <div>
      <LableTitle title="エリアを登録します。エリアコードとエリア名を入力してください。" />
      <FormContents>
        <form>
          <FormField>
            <TextForm
              name="areaCode"
              label="エリアコード"
              value={formik.values.areaCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.areaCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="areaName"
              label="エリア名"
              value={formik.values.areaName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.areaName}
            />
          </FormField>
          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <Button
                text={areaId ? '更新' : 'エリアを登録'}
                onClick={formik.handleSubmit}
              />
            </div>
            <Link to="/areas">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={areaId ? ActionType.UPDATE : ActionType.CREATE}
      />

    </div>
  );
};

export default AreasAddForm;
