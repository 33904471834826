import React, { useState, useRef, RefObject, useEffect, createContext, useContext, useReducer } from "react";
import { TimelineItemBase, TimelineGroupCustom } from "types/react-calendar-timeline";
import { DailyShiftContextType, StaffInfo, ShiftPattern, EmploymentOptionType, IDailyShift } from "../type";
import moment, { Moment } from "moment";
import useToastNotification from "hooks/useToastNotification";
import { ContextMenuType } from "components/molecules/ContextMenu/type";
import { useHistory, useParams } from "react-router-dom";
import Icon from "components/atoms/Icon";
import { fetchDrawingInfoDaily, fetchDailyShift, downloadDailyShiftPdf, getRoundShiftPatterns } from "../api";
import { OpenDate, getEmploymentSelectListV2, getEmploymentSelectOpenDateList } from "api/employment";
import { registerHoliday, deleteShiftV1, postShiftV1 } from "api/shift";
import { addShiftPattern } from "api/shiftPattern";
import { shiftConfirmReleaseV1, shiftConfirmV1 } from "api/shiftConfirm";
import { OptionType } from "components/atoms/Select";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import { getTreesOrgSelectRange } from "api/organization";
import { debounce } from "lodash";

export const dailyShiftContext = createContext<DailyShiftContextType>({} as DailyShiftContextType);

export const useDailyShiftContext = (): DailyShiftContextType => {
  const context = useContext(dailyShiftContext);
  return context;
};

export type StateDailyReducerType = {
  orgs: OptionType[];
  employmentOptions: EmploymentOptionType[];
  selectedOrg: string;
  dateFromStr: string;
  isAttendExists: string;
  cumulativeClosingDate: string;
};

export const useInitialState = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isEmploymentSelectOpenDateLoading, setEmploymentSelectOpenDateLoading] = useState<boolean>(true);
  const [remote, setRemote] = useState<boolean>(true);
  const [useSales, setUseSales] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [checkall, setCheckall] = useState<boolean>(
    sessionStorage.getItem("attendStampList.dailyShift.employmentOptions") === "all",
  );
  const [dailyShift, setDailyShift] = useState<IDailyShift>({} as IDailyShift);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const history = useHistory();
  const params = useParams<any>();
  const { errorNotification, successNotification } = useToastNotification();
  const [loadEmploymentDone, setLoadEmploymentDone] = useState<boolean>(false);
  const { detailRole } = useGetDetailRole(setLoading, functionCode.dailyShift);
  const [employmentSelectOpenDateList, setEmploymentSelectOpenDateList] = useState<OpenDate[]>([]);
  const [selectedEmploymentSelectOpenDate, setSelectedEmploymentSelectOpenDate] = useState<OpenDate | null>(null);

  const headerRef = useRef<HTMLDivElement>(null);

  const showLaborCost = detailRole.usabilityGeneralItem3 === 1 || detailRole.usabilityGeneralItem4 === 1;

  useEffect(() => {
    const orgCode = sessionStorage.getItem("attendStampList.dailyShift.orgCode");

    if (!orgCode && params.orgCode !== undefined) {
      sessionStorage.setItem("attendStampList.dailyShift.orgCode", params.orgCode);
    }

    const targetDate = sessionStorage.getItem("attendStampList.dailyShift.targetDateFrom");

    if (!targetDate && params.targetDate !== undefined) {
      sessionStorage.setItem(
        "attendStampList.dailyShift.targetDateFrom",
        moment(params.targetDate).format("YYYY/MM/DD"),
      );
    }
  }, [params]);

  // REDUCER
  const defaultStateReducer = {
    orgs: [],
    employmentOptions: [],
    selectedOrg:
      sessionStorage.getItem("attendStampList.dailyShift.orgCode") ||
      sessionStorage.getItem("loginUser.orgCode") ||
      "",
    dateFromStr:
      sessionStorage.getItem("attendStampList.dailyShift.targetDateFrom") ||
      moment()
        .startOf("day")
        .format("YYYY-MM-DD"),
    isAttendExists: sessionStorage.getItem("attendStampList.attendFlag") || "0",
    cumulativeClosingDate: sessionStorage.getItem("attendStampList.dailyShift.cumulativeClosingDate") || "0",
  };
  // FETCH EMPLOYMENT FORM
  const fetchEmploymentOptions = async (selectedOrg: string, dateFromStr: string) => {
    let employmentList = await getEmploymentSelectListV2(selectedOrg, dateFromStr, dateFromStr);

    const selectedEmploymentOptions = sessionStorage.getItem(`attendStampList.dailyShift.employmentOptions`) ?? "all";

    const sessionList = selectedEmploymentOptions.split(",");

    let formatOptionType = employmentList?.map((opt) => ({
      id: opt.employmentId,
      label: opt.employmentName,
      value: opt.employmentCode,
      checked:
        selectedEmploymentOptions === "all" || sessionList.length === 0 || sessionList.includes(opt.employmentId),
    }));

    setLoadEmploymentDone(true);
    dispatch({
      type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
      payload: formatOptionType,
    });
    return formatOptionType;
  };

  const fetchDrawingInfo = async (
    selectedOrg: string,
    dateFromStr: string,
    isAttendExists: string,
    employmentOptions: OptionType[],
    employmentSelectOpenDate: string,
  ) => {
    setLoading(true);
    const drawingInfoDaily = await fetchDrawingInfoDaily(selectedOrg, moment(dateFromStr).format("YYYY-MM-DD"));
    const startHourNumber = drawingInfoDaily.timeHeaderList[0];
    const endHourNumber = drawingInfoDaily.timeHeaderList[drawingInfoDaily.timeHeaderList.length - 1];
    const defaultStart = moment(dateFromStr).set("hour", startHourNumber);
    const defaultEnd = moment(dateFromStr).set("hour", endHourNumber + 1);

    if (startHourNumber > endHourNumber) {
      defaultEnd.add("day", 1);
    }

    setDefaultTimeStart(defaultStart);
    setDefaultTimeEnd(defaultEnd);

    //if hour gap is greater than 12h then set defaultTimeEnd to 12h ahead
    //this is to enable scroll
    // if (defaultEnd.diff(defaultStart, "hours") > 12) {
    //   setDefaultTimeEnd(moment(defaultStart).add("hours", 12));
    // } else {
    //   setDefaultTimeEnd(defaultEnd);
    // }

    const calculatorItem =
      (headerRef.current?.getBoundingClientRect().width ?? 0) - sidebarRightWidth - sidebarHeaderWidth - 38;
    const itemCount = calculatorItem / widthItem;
    if (defaultEnd.diff(defaultStart, "hours") > itemCount) {
      setDefaultTimeEnd(moment(defaultStart).add("hours", itemCount));
    } else {
      setDefaultTimeEnd(defaultEnd);
    }

    setBoundaryStart(defaultStart.valueOf());
    setBoundaryEnd(defaultEnd.valueOf());
    setUseSales(drawingInfoDaily.useSales === 1);

    fetchDailyShiftData(
      defaultStart,
      defaultEnd,
      isAttendExists,
      employmentOptions,
      selectedOrg,
      dateFromStr,
      employmentSelectOpenDate,
    );
  };

  const fetchDailyShiftData = async (
    defaultStart: Moment = moment(boundaryStart),
    defaultEnd: Moment = moment(boundaryEnd),
    isAttendExists: string = stateFilter.isAttendExists,
    employmentOptions: OptionType[] = stateFilter.employmentOptions,
    selectedOrg: string = stateFilter.selectedOrg,
    dateFromStr: string = stateFilter.dateFromStr,
    cumulativeClosingDate: string = stateFilter.cumulativeClosingDate,
  ) => {
    try {
      setLoading(true);
      const employmentIds = employmentOptions?.reduce(
        (prev, current) => (current.checked ? `${current.id},${prev}` : prev),
        "1",
      );

      const isAllEmployment = employmentOptions.every((option) => option.checked);

      const dailyShiftData = await fetchDailyShift(
        selectedOrg,
        moment(dateFromStr).format("YYYY-MM-DD"),
        isAllEmployment ? 1 : 0,
        isAttendExists,
        employmentIds,
        loginStaffCode,
        "",
        cumulativeClosingDate,
      );
      setDailyShift(dailyShiftData);

      const newGroups = getGroups(
        dailyShiftData.staffInfo,
        dailyShiftData.isShiftConfirm,
        dailyShiftData.isAchievementConfirm,
      );
      const row1Items = getRow1Items(dailyShiftData.staffInfo, defaultStart, defaultEnd);
      const row2Items = getRow2Items(
        dailyShiftData.staffInfo,
        dailyShiftData.isShiftConfirm,
        defaultStart,
        defaultEnd,
        row1Items.length,
        selectedOrg,
      );
      const row3Items = getRow3Items(
        dailyShiftData.staffInfo,
        dailyShiftData.isAchievementConfirm,
        defaultStart,
        defaultEnd,
        row1Items.length + row2Items.length,
      );
      setGroups(newGroups);
      setItems([...row1Items, ...row2Items, ...row3Items]);
    } catch (error) {
      if (error.response.status === 500) {
        errorNotification("サーバー側からエラーが発生します。");
      } else {
        errorNotification("エラー");
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500); //delay to wait for timeline-react-calendar render
    }
  };

  const reducer = (stateFilter: StateDailyReducerType, { type, payload }: any): StateDailyReducerType => {
    let tmpState = { ...stateFilter };
    switch (type) {
      case "ON_CHANGE_STATE":
        return { ...tmpState, ...payload };
      case "ON_CHANGE_ORG":
        return { ...tmpState, orgs: payload };
      case "ON_CHANGE_EMPLOYMENT_OPTIONS":
        return { ...tmpState, employmentOptions: payload };
      case "ON_CHANGE_ATTEND_EXISTS":
        return { ...tmpState, isAttendExists: payload };
      case "ON_CHANGE_DATE_FROM":
        return { ...tmpState, dateFromStr: payload };
      case "ON_CHANGE_SELECTED_ORG":
        return { ...tmpState, selectedOrg: payload };
      case "ON_CHANGE_CUMULATIVE_CLOSING_DATE":
        const openDate = employmentSelectOpenDateList.find((openDate) => openDate.closingDate.toString() === payload);

        if (openDate) {
          setSelectedEmploymentSelectOpenDate(openDate);
        }

        return { ...tmpState, cumulativeClosingDate: payload };
      default:
        return tmpState;
    }
  };

  const [stateFilter, dispatch] = useReducer(reducer, defaultStateReducer);
  // REDUCER

  /* -------------------------------------------------------------------------- */
  /*                                Filter                                      */
  /* -------------------------------------------------------------------------- */
  // const [dateFromStr, setDateFromStr] = useState<string>(
  //   sessionStorage.getItem("attendStampList.dailyShift.targetDateFrom") ||
  //   moment()
  //     .startOf("day")
  //     .format("YYYY-MM-DD")
  // );
  const [dateToStr, setDateToStr] = useState<string>(
    sessionStorage.getItem("attendStampList.targetDateTo") ||
      moment()
        .endOf("day")
        .format("YYYY-MM-DD"),
  );
  // const [isAttendExists, setAttendExists] = useState<string>(sessionStorage.getItem("attendStampList.attendFlag") || "0");
  // const [orgs, setOrgs] = useState<OptionType[]>([]);
  // const [employmentOptions, setEmploymentOptions] = useState<EmploymentOptionType[]>([]);
  // const [selectedOrg, setSelectedOrg] = useState<string>(
  //   sessionStorage.getItem("attendStampList.dailyShift.orgCode") || sessionStorage.getItem("loginUser.orgCode") || ""
  // );

  const downloadPdf = async () => {
    setLoading(true);
    let employmentIds = stateFilter.employmentOptions.reduce(
      (totalStr, current) => (current.checked ? `${current.id},${totalStr}` : totalStr),
      "",
    );

    downloadDailyShiftPdf(
      stateFilter.selectedOrg,
      moment(stateFilter.dateFromStr).format("YYYY-MM-DD"),
      employmentIds,
      stateFilter.isAttendExists,
    )
      .catch((error) => {
        errorNotification("対象日、対象組織において在籍するスタッフがいませんでした。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitShiftLockout = async () => {
    if (dailyShift.isShiftConfirm) {
      await shiftConfirmReleaseV1(
        stateFilter.selectedOrg,
        moment(stateFilter.dateFromStr).format("YYYY/MM/DD"),
        moment(stateFilter.dateFromStr).format("YYYY/MM/DD"),
      );
      await fetchDailyShiftData();
      errorNotification("シフト確定を解除しました。");
    } else {
      let targetStaffCodes = groups.map((staff) => staff.staffCode);
      await shiftConfirmV1(
        stateFilter.selectedOrg,
        moment(stateFilter.dateFromStr).format("YYYY/MM/DD"),
        moment(stateFilter.dateFromStr).format("YYYY/MM/DD"),
        targetStaffCodes,
      );
      await fetchDailyShiftData();
      successNotification("シフトを確定しました。");
    }

    setOpenConfirmModal(false);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Calendar                                     */
  /* -------------------------------------------------------------------------- */
  const [items, setItems] = useState<TimelineItemBase<number>[]>([]);
  const [groups, setGroups] = useState<TimelineGroupCustom[]>([]);
  const [selectedItem, setSelectedItem] = useState<TimelineItemBase<number> | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<TimelineGroupCustom>({} as TimelineGroupCustom);
  const [defaultTimeStart, setDefaultTimeStart] = useState<Moment>(moment().startOf("day"));
  const [defaultTimeEnd, setDefaultTimeEnd] = useState<Moment>(moment().endOf("day"));
  const [boundaryStart, setBoundaryStart] = useState<number>(defaultTimeStart.valueOf());
  const [boundaryEnd, setBoundaryEnd] = useState<number>(defaultTimeEnd.valueOf());
  const [selectedItemIdArr, setSelectedItemIdArr] = useState<number[]>([]);
  const sidebarName = 160;
  const sidebarSupport = 0;
  const sidebarType = 100;
  const sidebarHeaderWidth = sidebarName + sidebarSupport + sidebarType;
  const sidebarHourHeaderRightWidth = 78;
  const sidebarAllowanceHeaderRightWidth = 280;
  const sidebarRightWidth = sidebarHourHeaderRightWidth + (showLaborCost ? sidebarAllowanceHeaderRightWidth : 0);
  const widthItem = 26;

  const getGroups = (staffInfo: StaffInfo[], isShiftConfirm: boolean, isAchievementConfirm: boolean) => {
    return staffInfo.map((staff, index) => {
      return {
        id: index + 1,
        isShiftConfirm: isShiftConfirm || staff.dateInfo[0].workSystem === 0,
        isAchievementConfirm: isAchievementConfirm,
        rightData: ["-", staff.dateInfo[0].workTimeShiftDisp, staff.dateInfo[0].workTimeAchievementDisp],
        ...staff,
      };
    });
  };

  const getRow1Items = (staffInfo: StaffInfo[], bounceTimeStart: Moment, bounceTimeEnd: Moment) => {
    let counter = 0;
    return staffInfo.reduce((total: TimelineItemBase<number>[], staff, staffIndex) => {
      if (staff.dateInfo[0].hope) {
        let hope = staff.dateInfo[0].hope;
        let title = "";
        let hopeStartTime = hope.startTime ? moment(hope.startTime) : moment(hope.targetDate).startOf("day");
        let hopeEndTime = hope.endTime ? moment(hope.endTime) : moment(hope.targetDate).endOf("day");

        switch (hope.hopeShiftPatternType) {
          case 1:
            title = "o";
            break;
          case 2:
            title = "出勤できません";
            break;
          case 4:
            title = hope.holidayName || "";
            break;
          case 5:
            title = hope.holidayName || "";
            break;
          default:
            title = `${hope.shiftPatternName} (${hope.shiftTimePdfDisp})`;
            break;
        }

        counter++;
        return total.concat({
          ...hope,
          id: counter,
          group: staffIndex + 1,
          rowId: 1,
          title: title,
          start_time: hopeStartTime < bounceTimeStart ? bounceTimeStart.valueOf() : hopeStartTime.valueOf(),
          end_time: hopeEndTime > bounceTimeEnd ? bounceTimeEnd.valueOf() : hopeEndTime.valueOf(),
          canMove: false,
          canResize: false,
          canSelect: false,
        });
      } else {
        return total;
      }
    }, []);
  };

  const getRow2Items = (
    staffInfo: StaffInfo[],
    isConfirm: boolean = false,
    bounceTimeStart: Moment,
    bounceTimeEnd: Moment,
    nextIndex: number,
    selectedOrg: string,
  ) => {
    return staffInfo.reduce((total: TimelineItemBase<number>[], staff, staffIndex) => {
      if (staff.dateInfo[0].shiftList.length > 0) {
        let shiftList = staff.dateInfo[0].shiftList.map((shift) => {
          let title = "";
          if (shift.holidayName) {
            title = shift.holidayName;
            if (shift.distinctionHoliday) {
              if (shift.isLegal) {
                title += "（法定）";
              } else {
                title += "（所定）";
              }
            }
          } else if (shift.shiftPatternId) {
            title = `${shift.shiftPatternName} (${shift.shiftTimePdfDisp})`;
          } else {
            title = shift.shiftTimePdfDisp;
          }

          // 他店舗に出勤する所属スタッフは支援先の店舗名を付加
          if (shift.orgCode !== selectedOrg) {
            title += ` (${shift.orgName})`;
          }

          nextIndex++;

          let freezeMove = moment(shift.endTime!) > bounceTimeEnd ? true : false;

          if ((shift.recessTime > 0 && shift.detailList) || shift.workSystem === 3) {
            let breaks = shift.detailList.reduce((arr: any[], detail) => {
              return detail.businessId
                ? arr.concat({
                    break_start: moment(detail.startTime).valueOf(),
                    break_end: moment(detail.endTime!).valueOf(),
                  })
                : arr;
            }, []);

            let breakStart = 0,
              breakEnd = 0;
            for (let i = 0; i < shift.detailList.length; i++) {
              let detail = shift.detailList[i];
              if (detail.businessId) {
                breakStart = breakStart === 0 ? moment(detail.startTime).valueOf() : breakStart;
                breakEnd = moment(detail.endTime!).valueOf();
              }
            }

            return {
              ...shift,
              id: nextIndex,
              group: staffIndex + 1,
              rowId: 2,
              title: title,
              start_time:
                moment(shift.startTime) < bounceTimeStart
                  ? bounceTimeStart.valueOf()
                  : moment(shift.startTime).valueOf(),
              end_time:
                moment(shift.endTime!) > bounceTimeEnd ? bounceTimeEnd.valueOf() : moment(shift.endTime!).valueOf(),
              break_start: breakStart,
              break_end: breakEnd,
              // canMove: !(isConfirm || shift.shiftPatternId || shift.holidayId || shift.workSystem === 0 || shift.workSystem === 2 || shift.workSystem === 3 || freezeMove || shift.orgCode !== selectedOrg),
              canMove: false,
              canResize: !(
                isConfirm ||
                shift.shiftPatternId ||
                shift.holidayId ||
                shift.workSystem === 0 ||
                shift.workSystem === 2 ||
                shift.workSystem === 3 ||
                shift.orgCode !== stateFilter.selectedOrg
              ),
              canSelect: !(
                isConfirm ||
                shift.workSystem === 0 ||
                shift.workSystem === 2 ||
                shift.workSystem === 3 ||
                shift.orgCode !== stateFilter.selectedOrg
              ),
              breaks,
            };
          }
          return {
            ...shift,
            id: nextIndex,
            group: staffIndex + 1,
            rowId: 2,
            title: title,
            start_time:
              moment(shift.startTime) < bounceTimeStart
                ? bounceTimeStart.valueOf()
                : moment(shift.startTime).valueOf(),
            end_time:
              moment(shift.endTime!) > bounceTimeEnd ? bounceTimeEnd.valueOf() : moment(shift.endTime!).valueOf(),
            // canMove: !(isConfirm || shift.shiftPatternId || shift.holidayId || shift.workSystem === 0 || shift.workSystem === 2 || shift.workSystem === 3 || freezeMove || shift.orgCode !== selectedOrg),
            canMove: false,
            canResize: !(
              isConfirm ||
              shift.shiftPatternId ||
              shift.holidayId ||
              shift.workSystem === 0 ||
              shift.workSystem === 2 ||
              shift.workSystem === 3 ||
              shift.orgCode !== stateFilter.selectedOrg
            ),
            canSelect: !(
              isConfirm ||
              shift.workSystem === 0 ||
              shift.workSystem === 2 ||
              shift.workSystem === 3 ||
              shift.orgCode !== stateFilter.selectedOrg
            ),
            breaks: [],
          };
        });

        return total.concat(shiftList);
      } else {
        return total;
      }
    }, []);
  };

  const getRow3Items = (
    staffInfo: StaffInfo[],
    isConfirm: boolean = false,
    bounceTimeStart: Moment,
    bounceTimeEnd: Moment,
    nextIndex: number,
  ) => {
    return staffInfo.reduce((total: TimelineItemBase<number>[], staff, staffIndex) => {
      if (staff.dateInfo[0].achievementList.length > 0) {
        let achievementList = staff.dateInfo[0].achievementList.map((achievement) => {
          let title = "";
          if (achievement.holidayName) {
            let holidayTypeName = "";

            if (
              achievement.holidayType === 1 ||
              achievement.holidayType == 2 ||
              achievement.holidayType == 4 ||
              achievement.holidayType == 7 ||
              achievement.holidayType == 8 ||
              achievement.holidayType == 9
            ) {
              switch (achievement.holidayDigestiveUnit) {
                case 0:
                  holidayTypeName = " (全)";
                  break;

                case 2:
                  holidayTypeName = " (時間)";
                  break;

                case 3:
                  holidayTypeName = " (午前)";
                  break;

                case 4:
                  holidayTypeName = " (午後)";
                  break;

                default:
                  break;
              }
            }

            title = achievement.isHolidayWork ? "休日出勤" : achievement.holidayName + holidayTypeName;
          } else {
            title = achievement.workTimeDisp || achievement.shiftTimePdfDisp;
          }
          nextIndex++;

          if (achievement.detailList.length > 0) {
            let breaks = achievement.detailList.reduce((arr: any[], detail) => {
              return detail.businessId
                ? arr.concat({
                    break_start: moment(detail.startTime).valueOf(),
                    break_end: moment(detail.endTime!).valueOf(),
                  })
                : arr;
            }, []);

            let breakStart = 0,
              breakEnd = 0;
            for (let i = 0; i < achievement.detailList.length; i++) {
              let detail = achievement.detailList[i];
              if (detail.businessId) {
                breakStart = breakStart === 0 ? moment(detail.startTime).valueOf() : breakStart;
                breakEnd = moment(detail.endTime!).valueOf();
              }
            }
            return {
              ...achievement,
              id: nextIndex,
              group: staffIndex + 1,
              rowId: 3,
              title: title,
              start_time:
                moment(achievement.startTime) < bounceTimeStart
                  ? bounceTimeStart.valueOf()
                  : moment(achievement.startTime).valueOf(),
              end_time:
                moment(achievement.endTime!) > bounceTimeEnd
                  ? bounceTimeEnd.valueOf()
                  : moment(achievement.endTime!).valueOf(),
              break_start: breakStart,
              break_end: breakEnd,
              canMove: false,
              canResize: false,
              canSelect: true,
              breaks,
            };
          }
          return {
            ...achievement,
            id: nextIndex,
            group: staffIndex + 1,
            rowId: 3,
            title: title,
            start_time:
              moment(achievement.startTime) < bounceTimeStart
                ? bounceTimeStart.valueOf()
                : moment(achievement.startTime).valueOf(),
            end_time:
              moment(achievement.endTime!) > bounceTimeEnd
                ? bounceTimeEnd.valueOf()
                : moment(achievement.endTime!).valueOf(),
            canMove: false,
            canResize: false,
            canSelect: true,
            breaks: [],
          };
        });

        return total.concat(achievementList);
      } else {
        return total;
      }
    }, []);
  };

  const onTimeChange = (visibleTimeStart: number, visibleTimeEnd: number, updateScrollCanvas: Function) => {
    if (visibleTimeStart < boundaryStart && visibleTimeEnd > boundaryEnd) {
      updateScrollCanvas(boundaryStart, boundaryEnd);
    } else if (visibleTimeStart < boundaryStart) {
      updateScrollCanvas(boundaryStart, boundaryStart + (visibleTimeEnd - visibleTimeStart));
    } else if (visibleTimeEnd > boundaryEnd) {
      updateScrollCanvas(boundaryEnd - (visibleTimeEnd - visibleTimeStart), boundaryEnd);
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  const onItemMove = async (itemId: number, dragTime: number, newGroupOrder: number) => {
    let newItems = JSON.parse(JSON.stringify(items)), //deep copy
      item = newItems[itemId - 1],
      newStartTimestamp: number = dragTime,
      timeGap: number = dragTime - item.start_time,
      newEndTimestamp: number = item.end_time + timeGap;

    //round-up start time & end time when move back from rightEdge
    if (newEndTimestamp === boundaryEnd && item.end_time !== boundaryEnd) {
      newStartTimestamp += 1000;
    }
    if (item.end_time === boundaryEnd && newEndTimestamp !== boundaryEnd) {
      newEndTimestamp += 1000;
    }
    //.

    if (item.break_start && item.break_end) {
      let timeStartToBreakStartRange = item.break_start - item.start_time,
        timeStartToBreakEndRange = item.break_end - item.start_time;

      item.break_start = newStartTimestamp + timeStartToBreakStartRange;
      item.break_end = newStartTimestamp + timeStartToBreakEndRange;
    }

    item.start_time = newStartTimestamp;
    item.end_time = newEndTimestamp;

    try {
      setLoading(true);
      let submitItem = formatSubmitObjItemMove(item, timeGap);

      setItems(newItems);
      await postShiftV1(submitItem);
      await fetchDailyShiftData();
    } catch (error) {
      errorNotification(error.response.data.message);
      setItems(items);
    } finally {
      setLoading(false);
      setSelectedItemIdArr([]);
    }
  };

  const onItemSelect = (itemId: number) => {
    setSelectedItemIdArr([itemId]);
  };

  //when item is already selected
  const onItemClick = async (itemId: string | number, e: MouseEvent) => {
    if (!detailRole.editable) return;
    let item = items.find((item) => item.id === itemId);
    let staff = groups.find((staff) => staff.id === item?.group);
    setSelectedItemIdArr([Number(itemId)]);
    if (staff && item) {
      setContextMenuY(e.pageY);
      setContextMenuX(e.pageX);
      setSelectedGroup(staff);

      if (item.rowId === 2) {
        setSelectedItem(item);
        try {
          var shiftPatterns = await getRoundShiftPatterns(
            stateFilter.selectedOrg,
            staff.dateInfo[0].employmentId,
            moment(stateFilter.dateFromStr).format("YYYY-MM-DD"),
          );
        } catch (error) {
          shiftPatterns = [];
        }
        setShiftPatternOptions(shiftPatterns);
        let itemButtons = getMenuItemButtons();
        setMenuItemButtons(itemButtons);
        setItemMenuVisible(true);
      }

      if (item.rowId === 3) {
        let performanceButtons = getMenuPerformanceButtons(
          item.modifierApplicationId, //modifierApplicationId : any
          stateFilter.selectedOrg,
          staff.staffCode,
          staff.staffName,
          null, //modifierApplicationStatus: number | null
          null, //holidayApplicationStatus: number | null,
          null, //overworkApplicationStatus: number | null,
          null, //allowanceApplicationStatus: number | null,
          null, //holidayworkApplicationStatus: number | null,
          null, //transferApplicationStatus: number | null,
          "1", //attendanceOrgFix: string,
          staff.dateInfo[0].useOverTimeApplication, //useOverTimeApplication: any,
          staff.dateInfo[0].necessaryHolidayWorkApplication, //necessaryHolidayWorkApplication: any,
          staff.dateInfo[0].useAllowanceApplication, //useAllowanceApplication: any,
          staff.dateInfo[0].useTransferApplication, //useTransferApplication: any,
          item.idToString, //achievementId: any,
          item.targetDate,
          item.stampVersion, //stampVersion: any,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          item.holidayId,
          item.isHolidayWork,
          staff.dateInfo[0].workSystem,
          staff.dateInfo[0]?.achievementList[0]?.belongOrgCode || staff.dateInfo[0].belongOrgCode,
          Boolean(staff.dateInfo[0]?.useStockPaidApp),
          staff.dateInfo[0]?.stockModiferAppId,
          staff.dateInfo[0]?.stockPaidHolidayApplicationStatus,
        );
        setMenuPerformanceButtons(performanceButtons);
        setPerformanceMenuVisible(true);
      }
    }
  };

  const onCanvasClick = async (groupIndex: number, time: number, e: any) => {
    if (!detailRole.editable) return;
    let rowId = e.target.getAttribute("rowid");
    let staff = groups[groupIndex - 1];
    setContextMenuY(e.pageY);
    setContextMenuX(e.pageX);
    setSelectedGroup(staff);
    setSelectedItem(null);

    if (
      rowId == 2 &&
      !dailyShift.isShiftConfirm &&
      !dailyShift.isAchievementConfirm &&
      ![0, 2, 3].includes(staff.dateInfo[0].workSystem) &&
      !staff.dateInfo[0].isAchievementConfirm
    ) {
      //fetch Shiftpattern
      try {
        var shiftPatterns = await getRoundShiftPatterns(
          stateFilter.selectedOrg,
          staff.dateInfo[0].employmentId,
          moment(stateFilter.dateFromStr).format("YYYY-MM-DD"),
        );
      } catch (error) {
        shiftPatterns = [];
      }
      setShiftPatternOptions(shiftPatterns);
      setRemote(staff.dateInfo[0].useRemote);
      let timelineButtons = getMenuTimelineButtons(
        staff.staffCode,
        staff.staffName,
        stateFilter.dateFromStr,
        shiftPatterns,
        staff.dateInfo[0].distinctionHoliday,
      );
      setMenuTimelineButtons(timelineButtons);
      setTimelineMenuVisible(true);
    }

    if (rowId == 3 && !dailyShift.isAchievementConfirm && !staff.dateInfo[0].isAchievementConfirm) {
      let performanceButtons = getMenuPerformanceButtons(
        "", //modifierApplicationId : any
        stateFilter.selectedOrg,
        staff.staffCode,
        staff.staffName,
        null, //modifierApplicationStatus: number | null
        null, //holidayApplicationStatus: number | null,
        null, //allowanceApplicationStatus: number | null,
        null, //overworkApplicationStatus: number | null,
        null, //holidayworkApplicationStatus: number | null,
        null, //transferApplicationStatus: number | null,
        "", //attendanceOrgFix: string,
        staff.dateInfo[0].useOverTimeApplication, //useOverTimeApplication: any,
        staff.dateInfo[0].necessaryHolidayWorkApplication, //necessaryHolidayWorkApplication: any,
        staff.dateInfo[0].useAllowanceApplication, //useAllowanceApplication: any,
        staff.dateInfo[0].useTransferApplication, //useTransferApplication: any,
        null, //achievementId: any,
        staff.dateInfo[0].targetDate,
        0, //stampVersion: any,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        staff.dateInfo[0].workSystem,
        staff.dateInfo[0]?.belongOrgCode,
        Boolean(staff.dateInfo[0]?.useStockPaidApp),
        staff.dateInfo[0]?.stockModiferAppId,
        staff.dateInfo[0]?.stockPaidHolidayApplicationStatus,
      );
      setMenuPerformanceButtons(performanceButtons);
      setPerformanceMenuVisible(true);
    }

    // Set highlight action
    sessionStorage.setItem("highlightIndex", String(staff.id));
    sessionStorage.setItem("highlightAction", "false");
  };

  const onItemResize = async (itemId: number, time: number, edge: string) => {
    let newItems = [...items],
      item = newItems[itemId - 1],
      selectedItemStart: number = item.start_time,
      newEndTimestamp: number = time;

    //round-up end time when resize from rightEdge to normal
    if (item.end_time === boundaryEnd && newEndTimestamp != boundaryEnd) {
      newEndTimestamp += 1000;
    }

    item.start_time = selectedItemStart;
    item.end_time = newEndTimestamp;
    item.title = `${moment(item.start_time)
      .format("HH:mm")
      .toString()} ~ ${moment(item.end_time)
      .format("HH:mm")
      .toString()}`;
    setLoading(true);
    setItems(newItems);

    let submitItem = formatSubmitObjItemResize(item, newEndTimestamp);
    await postShiftV1(submitItem);
    await fetchDailyShiftData();
  };

  const moveResizeValidator = (
    action: "move" | "resize",
    item: TimelineItemBase<number>,
    time: number,
    resizeEdge: "left" | "right",
  ): number => {
    let selectedItemDuration: number = item.end_time - item.start_time,
      itemsToCheck = items.filter(
        (itemToCheck) =>
          itemToCheck.rowId === item.rowId && itemToCheck.group === item.group && itemToCheck.id != item.id,
      );

    if (action === "resize") {
      //minimum resize of 1 hour
      if (time - item.start_time < 3600000) {
        time = item.start_time + 3600000;
      }

      //breaktime collision
      if (item.break_end && time < item.break_end) {
        time = item.break_end + 15 * 60 * 1000; //add 15minutes
      }

      if (resizeEdge === "left") {
        if (boundaryStart > time) {
          time = boundaryStart;
        }
      } else {
        //resizeEdge right
        if (boundaryEnd < time) {
          time = boundaryEnd;
        }

        // check collision
        for (let i = 0; i < itemsToCheck.length; i++) {
          const itemToCheck = itemsToCheck[i];

          if (time > itemToCheck.start_time && time < itemToCheck.end_time) {
            time = itemToCheck.start_time;
          } else if (item.start_time < itemToCheck.start_time && time > itemToCheck.end_time) {
            time = itemToCheck.start_time;
          }
        }
      }
    } else {
      //action === 'move'
      let newItemStart = time,
        newItemEnd = time + selectedItemDuration;

      if (!itemsToCheck.length) {
        //left Edge
        if (boundaryStart > time) {
          time = boundaryStart;
        }

        //right Edge
        if (boundaryEnd < time + selectedItemDuration) {
          time = boundaryEnd - selectedItemDuration;
        }
      } else {
        // check collision
        for (let i = 0; i < itemsToCheck.length; i++) {
          const itemToCheck = itemsToCheck[i];
          let remainingDurationLeftside = itemToCheck.start_time - boundaryStart,
            remainingDurationRightside = boundaryEnd - itemToCheck.end_time;

          //wrap around or contain inside
          if (
            (newItemStart <= itemToCheck.start_time && newItemEnd > itemToCheck.start_time) ||
            (newItemStart > itemToCheck.start_time && newItemStart < itemToCheck.end_time)
          ) {
            if (remainingDurationRightside <= selectedItemDuration) {
              time = itemToCheck.start_time - selectedItemDuration;
            } else {
              time = itemToCheck.end_time;
            }
          } else {
            //left Edge
            if (boundaryStart > time) {
              if (remainingDurationLeftside < selectedItemDuration) {
                time = itemToCheck.end_time;
              } else {
                time = boundaryStart;
              }
            }

            //right Edge
            if (boundaryEnd < time + selectedItemDuration) {
              if (remainingDurationRightside < selectedItemDuration) {
                time = itemToCheck.start_time - selectedItemDuration;
              } else {
                time = boundaryEnd - selectedItemDuration;
              }
            }
          }
        }
      }
    }
    return time;
  };
  const onClickAttendStampList = (staff: StaffInfo) => {
    // 自画面用パラメータ
    if (stateFilter.dateFromStr) {
      sessionStorage.setItem("attendStampList.targetDateFrom", moment(stateFilter.dateFromStr).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.targetDateTo", moment(stateFilter.dateFromStr).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.orgCode", stateFilter.selectedOrg);
      sessionStorage.setItem("attendStampList.staffCode", staff.staffCode);
    }

    // Common parameters
    sessionStorage.setItem("application.staffCode", staff.staffCode);
    sessionStorage.setItem("application.dispStaffName", `${staff.staffCode} ${staff.staffName}`);
    sessionStorage.setItem("application.targetDate", moment(stateFilter.dateFromStr).format("YYYY-MM-DD"));
    sessionStorage.setItem("application.returnDestination", window.location.pathname);
    sessionStorage.setItem("attendStampList.targetMonth", moment(stateFilter.dateFromStr).format("YYYY-MM-DD"));

    sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);
    sessionStorage.setItem("attendStampList.closingDate", stateFilter.dateFromStr);
    sessionStorage.setItem("attendStampList.employmentId", staff.employmentId);
    sessionStorage.setItem("attendStampList.attendFlag", stateFilter.isAttendExists);

    // 打刻データ確認のセッション情報をセット
    sessionStorage.setItem("attendStampList.isGoBack", "true");
    sessionStorage.setItem("attendStampList.viewPeriod", "daily");

    history.push(`/attendStampList`);
  };

  const deleteItem = async (shiftId: string) => {
    setLoading(true);
    let updateUser = sessionStorage.getItem("loginUser.staffName")!;

    try {
      await deleteShiftV1(shiftId, updateUser);
      await fetchDailyShiftData();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
      setSelectedItemIdArr([]);
    }
  };

  const formatSubmitObjItemMove = (shift: TimelineItemBase<number>, timeGap: number) => {
    let targetDateMoment = moment(shift.targetDate);

    return {
      shiftId: shift.idToString,
      orgCode: shift.orgCode,
      orgName: shift.orgName,
      belongOrgCode: shift.belongOrgCode,
      staffCode: shift.staffCode,
      staffName: shift.staffCode,
      targetDate: targetDateMoment.format("YYYY/MM/DD HH:mm:ss"),
      startTime: moment(shift.start_time).format("YYYY/MM/DD HH:mm:ss"),
      endTime: moment(shift.end_time).format("YYYY/MM/DD HH:mm:ss"),
      attendType: 0,
      procType: 1,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
      shiftDetailList: shift.detailList!.map((detail, index) => {
        let newStartTimeUnix = moment(detail.startTime).valueOf() + timeGap;
        let newEndTimeUnix = moment(detail.endTime!).valueOf() + timeGap;
        let forceStartTimeNextDay = false;
        let forceEndTimeNextDay = false;

        if (moment(newStartTimeUnix).isAfter(targetDateMoment, "day")) {
          forceStartTimeNextDay = true;
        }
        if (moment(newEndTimeUnix).isAfter(targetDateMoment, "day")) {
          forceEndTimeNextDay = true;
        }

        return {
          startTime: moment(newStartTimeUnix).format("YYYY/MM/DD HH:mm:ss"),
          isStartTimeNextDay: forceStartTimeNextDay,
          endTime: moment(newEndTimeUnix).format("YYYY/MM/DD HH:mm:ss"),
          isEndTimeNextDay: forceEndTimeNextDay,
          businessId: detail.businessId || "",
          createUser: sessionStorage.getItem("loginUser.staffName") || "",
          updateUser: sessionStorage.getItem("loginUser.staffName") || "",
          companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
          shiftDetailId: detail.detailId,
          shiftId: shift.idToString,
        };
      }),
    };
  };

  const formatSubmitObjItemResize = (shift: TimelineItemBase<number>, newEndTime: number) => {
    let detailListLength = shift.detailList.length;
    return {
      shiftId: shift.idToString,
      orgCode: shift.orgCode,
      orgName: shift.orgName,
      belongOrgCode: shift.belongOrgCode,
      staffCode: shift.staffCode,
      staffName: shift.staffCode,
      targetDate: moment(shift.targetDate).format("YYYY/MM/DD HH:mm:ss"),
      startTime: moment(shift.start_time).format("YYYY/MM/DD HH:mm:ss"),
      endTime: moment(newEndTime).format("YYYY/MM/DD HH:mm:ss"),
      attendType: 0,
      procType: 1,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
      shiftDetailList: shift.detailList.map((detail) => {
        return {
          startTime: moment(detail.startTime).format("YYYY/MM/DD HH:mm:ss"),
          isStartTimeNextDay: detail.isStartTimeNextDay,
          endTime: moment(!--detailListLength ? newEndTime : detail.endTime!).format("YYYY/MM/DD HH:mm:ss"), //edit last item only
          isEndTimeNextDay: detail.isEndTimeNextDay,
          businessId: detail.businessId || "",
          createUser: sessionStorage.getItem("loginUser.staffName") || "",
          updateUser: sessionStorage.getItem("loginUser.staffName") || "",
          companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
          shiftDetailId: detail.detailId,
          shiftId: shift.idToString,
        };
      }),
    };
  };

  /* -------------------------------------------------------------------------- */
  /*                          ShiftPattern modal                                */
  /* -------------------------------------------------------------------------- */
  const [openShiftPatternModal, setShiftPatternModal] = useState<boolean>(false);
  const [shiftPatternOptions, setShiftPatternOptions] = useState<ShiftPattern[]>([]);

  const getWorkdayOptions = (distinctionHoliday: boolean) => {
    if (distinctionHoliday) {
      return [
        { value: "normal", label: "通常" },
        { value: "legal", label: "公休（法定休日）" },
        { value: "scheduled", label: "公休（所定休日）" },
      ];
    }

    return [
      { value: "normal", label: "通常" },
      { value: "legal", label: "公休" },
    ];
  };

  /* -------------------------------------------------------------------------- */
  /*                             ContextMenu                                    */
  /* -------------------------------------------------------------------------- */
  const [isPerformanceMenuVisible, setPerformanceMenuVisible] = useState<boolean>(false);
  const [isItemMenuVisible, setItemMenuVisible] = useState<boolean>(false);
  const [isTimelineMenuVisible, setTimelineMenuVisible] = useState<boolean>(false);
  const contextMenuRef = useRef() as RefObject<HTMLElement>;
  const [contextMenuX, setContextMenuX] = useState<number>(0);
  const [contextMenuY, setContextMenuY] = useState<number>(0);
  const [menuTimelineButtons, setMenuTimelineButtons] = useState<ContextMenuType[]>([]);
  const [menuPerformanceButtons, setMenuPerformanceButtons] = useState<ContextMenuType[]>([]);
  const [menuItemButtons, setMenuItemButtons] = useState<ContextMenuType[]>([]);

  const handleClickOutside = (event: any) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setPerformanceMenuVisible(false);
      setItemMenuVisible(false);
      setTimelineMenuVisible(false);
    }
  };

  const getMenuPerformanceButtons = (
    modifierApplicationId: any,
    orgCode: string,
    staffCode: string,
    staffName: string,
    modifierApplicationStatus: number | null,
    holidayApplicationStatus: number | null,
    allowanceApplicationStatus: number | null,
    overworkApplicationStatus: number | null,
    holidayworkApplicationStatus: number | null,
    transferApplicationStatus: number | null,
    attendanceOrgFix: string,
    useOverTimeApplication: any,
    necessaryHolidayWorkApplication: any,
    useAllowanceApplication: any,
    useTransferApplication: any,
    achievementId: any,
    targetDate: any,
    stampVersion: any,
    dispStaffName?: string,
    filterTargetDateFrom?: Date,
    filterTargetDateTo?: Date,
    filterOrgCode?: string,
    filterStaffCode?: string,
    holidayId?: any,
    isHolidayWork?: any,
    workSystem?: number,
    belongOrgCode?: string,
    useStockPaidApp?: boolean,
    stockModiferAppId?: string,
    stockPaidHolidayApplicationStatus?: number | null,
  ) => {
    const isHoliday = holidayId && !isHolidayWork;
    const stampAmendmentButton: ContextMenuType = {
      isRed: modifierApplicationStatus === 0,
      isSelectable:
        ((achievementId !== null && (holidayApplicationStatus === null || holidayApplicationStatus === undefined)) ||
          modifierApplicationStatus !== null ||
          attendanceOrgFix === "1") &&
        !isHoliday,
      label: "修正申請",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendStampModificationApplication`;
        sessionStorage.setItem("stamp.workSystem", String(workSystem));
        setSessionStorage(link, stampAmendmentButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const stampAddButton: ContextMenuType = {
      isRed: false,
      isSelectable: attendanceOrgFix !== "1" || isHoliday,
      label: "追加申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendStampModificationApplication`;
        sessionStorage.setItem("stamp.workSystem", String(workSystem));
        setSessionStorage(link, stampAddButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const stampButtons: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "打刻修正申請",
      isHidden: false,
      stamptype: 1,
      subMenu: [stampAmendmentButton, stampAddButton],
    };

    const holidayAddButton: ContextMenuType = {
      isRed: false,
      isSelectable: true,
      label: "追加申請",
      isHidden: false,
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendHolidayApplication`;
        setSessionStorage(link, holidayAddButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const holidayAmendmentButton: ContextMenuType = {
      isRed: modifierApplicationStatus === 0,
      isSelectable: achievementId && holidayId,
      label: "修正申請",
      isHidden: false,
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendHolidayApplication`;
        setSessionStorage(link, holidayAmendmentButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyLeaveButton: ContextMenuType = {
      isRed: holidayApplicationStatus === 0,
      isSelectable: true,
      label: "休暇申請",
      isHidden: false,
      stamptype: 0,
      subMenu: [holidayAmendmentButton, holidayAddButton],
    };

    const applyOvertimeButton: ContextMenuType = {
      isRed: overworkApplicationStatus === 0,
      isSelectable: overworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useOverTimeApplication === 0,
      label: "残業申請",
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendOvertimeApplication`;
        setSessionStorage(link, applyOvertimeButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyAllowanceButton: ContextMenuType = {
      isRed: allowanceApplicationStatus === 0,
      isSelectable: allowanceApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useAllowanceApplication === 0,
      label: "手当申請",
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendAllowanceApplication`;
        setSessionStorage(link, applyAllowanceButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyHolidayWorkButton: ContextMenuType = {
      isRed: holidayworkApplicationStatus === 0,
      isSelectable: holidayworkApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: necessaryHolidayWorkApplication === false,
      label: "休日出勤申請",
      stamptype: 1,
      onClick: () => {
        const link = `${window.location.pathname}/attendHolidayworkApplication`;
        setSessionStorage(link, applyHolidayWorkButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyTransferButton: ContextMenuType = {
      isRed: transferApplicationStatus === 0,
      isSelectable: transferApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useTransferApplication === 0,
      label: "振替申請",
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendTransferApplication`;
        setSessionStorage(link || "", applyTransferButton.stamptype, targetDate, belongOrgCode);
        history.push(link);
      },
    };

    const applyStockPaidHolidayButton: ContextMenuType = {
      isRed: stockPaidHolidayApplicationStatus === 0,
      isSelectable: stockPaidHolidayApplicationStatus !== 0 || !(attendanceOrgFix === "1"),
      isHidden: useStockPaidApp === false,
      label: "積立有給申請",
      stamptype: 0,
      onClick: () => {
        const link = `${window.location.pathname}/attendStockPaidApplication`;
        setSessionStorage(link, applyStockPaidHolidayButton.stamptype, targetDate);
        sessionStorage.setItem("application.modifierApplicationId", stockModiferAppId || "");
        history.push(link);
      },
    };

    const setSessionStorage = (link: string, stamptype: number, targetDate: string, belongOrgCode?: string) => {
      // 自画面用パラメータ
      if (filterTargetDateFrom) {
        sessionStorage.setItem("attendStampList.targetDateFrom", moment(filterTargetDateFrom).format("YYYY-MM-DD"));
        sessionStorage.setItem("attendStampList.targetDateTo", moment(filterTargetDateTo).format("YYYY-MM-DD"));
        sessionStorage.setItem("attendStampList.orgCode", String(filterOrgCode));
        sessionStorage.setItem("attendStampList.staffCode", String(filterStaffCode));
      }

      // Common parameters
      if (orgCode) {
        sessionStorage.setItem("application.orgCode", orgCode);
      } else if (filterOrgCode) {
        sessionStorage.setItem("application.orgCode", filterOrgCode);
      }
      sessionStorage.setItem("application.staffCode", String(staffCode));
      if (dispStaffName) {
        sessionStorage.setItem("application.dispStaffName", String(dispStaffName));
      } else {
        sessionStorage.setItem("application.dispStaffName", `${staffCode} ${staffName}`);
      }
      sessionStorage.setItem("application.targetDate", moment(targetDate).format("YYYY-MM-DD"));
      sessionStorage.setItem("application.returnDestination", window.location.pathname);
      sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);

      switch (link) {
        case `${window.location.pathname}/attendStampModificationApplication`:
          sessionStorage.removeItem("application.achievementId");
          sessionStorage.removeItem("application.stampVersion");
          sessionStorage.removeItem("application.modifierApplicationId");
          if (stamptype === 1) {
            // 修正申請
            sessionStorage.setItem("application.achievementId", achievementId || "");
            sessionStorage.setItem("application.stampVersion", stampVersion || "");
            sessionStorage.setItem("application.modifierApplicationId", modifierApplicationId || "");
          } else {
            sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          }
          break;
        case `${window.location.pathname}/attendHolidayworkApplication`:
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
            sessionStorage.setItem("application.editHolidaywork", "1");
          } else {
            sessionStorage.removeItem("application.orgCode");
            sessionStorage.removeItem("application.editHolidaywork");
          }
          break;
        case `${window.location.pathname}/attendOvertimeApplication`:
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
            sessionStorage.setItem("application.editOvertime", "1");
          } else {
            sessionStorage.removeItem("application.editOvertime");
          }
          break;
        case `${window.location.pathname}/attendHolidayApplication`:
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
            sessionStorage.setItem("application.achievementId", achievementId || "");
          } else {
            sessionStorage.removeItem("application.achievementId");
          }
          break;
        case `${window.location.pathname}/attendTransferApplication`:
          sessionStorage.setItem("application.orgCode", belongOrgCode || "");
          if (stamptype === 1) {
          } else {
            sessionStorage.removeItem("application.achievementId");
          }
          break;
        default:
          // do nothing
          break;
      }
    };

    return [
      stampButtons,
      applyLeaveButton,
      applyOvertimeButton,
      applyAllowanceButton,
      applyHolidayWorkButton,
      applyTransferButton,
      applyStockPaidHolidayButton,
    ];
  };

  const getMenuItemButtons = (): ContextMenuType[] => {
    const editBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを編集する",
      isHidden: false,
      icon: <Icon type="edit" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
      },
    };
    const deleteBtn = {
      isRed: false,
      isSelectable: true,
      label: "シフトを削除する",
      isHidden: false,
      icon: <Icon type="delete" color="black" size="16px" verticalAlign="sub" />,
      stamptype: 0,
      onClick: () => {
        setOpenDeleteModal(true);
      },
    };

    return [editBtn, deleteBtn];
  };

  const getMenuTimelineButtons = (
    staffCode: string,
    staffName: string,
    targetDate: string,
    shiftPatternOptions: ShiftPattern[],
    distinctionHoliday: boolean,
  ): ContextMenuType[] => {
    const addShift = {
      label: "シフトを追加する",
      isRed: false,
      isSelectable: true,
      stamptype: 0,
      onClick: () => {
        setShiftPatternModal(true);
      },
    };
    const takeHoliday = distinctionHoliday
      ? {
          label: "公休にする",
          isRed: false,
          isSelectable: true,
          stamptype: 0,
          subMenu: [
            {
              label: "法定休日",
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: async () => {
                let user = sessionStorage.getItem("loginUser.staffName")!;
                try {
                  setLoading(true);
                  await registerHoliday(
                    stateFilter.selectedOrg,
                    staffCode,
                    moment(targetDate).format("YYYY/MM/DD"),
                    true,
                    user,
                    user,
                  );
                  await fetchDailyShiftData();
                } catch (error) {
                  errorNotification(error.response.data.message);
                } finally {
                  setLoading(false);
                }
              },
            },
            {
              label: "所定休日",
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: async () => {
                try {
                  setLoading(true);
                  await registerHoliday(
                    stateFilter.selectedOrg,
                    staffCode,
                    moment(targetDate).format("YYYY/MM/DD"),
                    false,
                    staffName,
                    staffName,
                  );
                  await fetchDailyShiftData();
                } catch (error) {
                  errorNotification(error.response.data.message);
                } finally {
                  setLoading(false);
                }
              },
            },
          ],
        }
      : {
          label: "公休にする",
          isRed: false,
          isSelectable: true,
          stamptype: 0,
          onClick: async () => {
            try {
              setLoading(true);
              await registerHoliday(
                stateFilter.selectedOrg,
                staffCode,
                moment(targetDate).format("YYYY/MM/DD"),
                true,
                staffName,
                staffName,
              );
              await fetchDailyShiftData();
            } catch (error) {
              errorNotification(error.response.data.message);
            } finally {
              setLoading(false);
            }
          },
        };

    const addFromShiftPattern = {
      label: "シフトパターンから追加する",
      isRed: false,
      isSelectable: true,
      showSearchBox: true,
      stamptype: 0,
      subMenu:
        shiftPatternOptions.length > 0
          ? shiftPatternOptions.map((pattern) => ({
              label: pattern.shiftPatternName,
              isRed: false,
              isSelectable: true,
              stamptype: 0,
              onClick: () => {
                handleAddFromShiftPattern(pattern.shiftPatternId, staffCode, targetDate);
              },
            }))
          : [
              {
                label: "データがありません",
                isRed: false,
                isSelectable: false,
                stamptype: 0,
              },
            ],
    };

    return [addShift, takeHoliday, addFromShiftPattern];
  };

  const handleAddFromShiftPattern = async (shiftPatternId: string, staffCode: string, targetDate: string) => {
    let createUser = sessionStorage.getItem("loginUser.staffName")!,
      updateUser = sessionStorage.getItem("loginUser.staffName")!;
    setLoading(true);
    try {
      await addShiftPattern(
        stateFilter.selectedOrg,
        staffCode,
        moment(targetDate).format("YYYY/MM/DD"),
        shiftPatternId,
        createUser,
        updateUser,
      );
      await fetchDailyShiftData();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FETCH ORGANIZATION OPTIONS
  const fetchOrg = async (dateFromStr: string) => {
    getTreesOrgSelectRange(false, functionCode.dailyShift, dateFromStr, dateFromStr).then((response) => {
      let formattedOrgs = response.map((item) => ({
        value: String(item.orgCode),
        label: item.orgName,
      }));
      dispatch({
        type: "ON_CHANGE_ORG",
        payload: formattedOrgs,
      });
    });
  };

  // useEffect(() => {
  //   if (showLaborCost) {
  //     getEmploymentSelectOpenDateList(stateFilter.dateFromStr).then((employmentSelectOpenDates) => {
  //       fetchEmploymentOptions(defaultStateReducer.selectedOrg, defaultStateReducer.dateFromStr).then(
  //         (employmentOptions) => {
  //           fetchDrawingInfo(
  //             defaultStateReducer.selectedOrg,
  //             defaultStateReducer.dateFromStr,
  //             defaultStateReducer.isAttendExists,
  //             employmentOptions,
  //             employmentSelectOpenDates[0]?.closingDate.toString() ?? "0",
  //           );
  //         },
  //       );
  //       fetchOrg(defaultStateReducer.dateFromStr);
  //     });
  //   } else {
  //     fetchEmploymentOptions(defaultStateReducer.selectedOrg, defaultStateReducer.dateFromStr).then(
  //       (employmentOptions) => {
  //         fetchDrawingInfo(
  //           defaultStateReducer.selectedOrg,
  //           defaultStateReducer.dateFromStr,
  //           defaultStateReducer.isAttendExists,
  //           employmentOptions,
  //           defaultStateReducer.cumulativeClosingDate,
  //         );
  //       },
  //     );
  //     fetchOrg(defaultStateReducer.dateFromStr);
  //   }
  // }, [showLaborCost]);

  useEffect(() => {
    setEmploymentSelectOpenDateLoading(true);

    if (showLaborCost) {
      fetchEmploymentOptions(stateFilter.selectedOrg, stateFilter.dateFromStr).then((employmentOptions) => {
        getEmploymentSelectOpenDateList(stateFilter.dateFromStr).then((employmentSelectOpenDates) => {
          setEmploymentSelectOpenDateList(employmentSelectOpenDates);

          const selectedDate = employmentSelectOpenDates.find(
            (date) =>
              date.closingDate.toString() ===
              sessionStorage.getItem("attendStampList.dailyShift.cumulativeClosingDate"),
          );

          if (selectedDate) {
            dispatch({
              type: "ON_CHANGE_CUMULATIVE_CLOSING_DATE",
              payload: selectedDate.closingDate.toString(),
            });
          } else if (employmentSelectOpenDates[0]) {
            dispatch({
              type: "ON_CHANGE_CUMULATIVE_CLOSING_DATE",
              payload: employmentSelectOpenDates[0].closingDate.toString(),
            });
          }

          Promise.all([
            fetchOrg(stateFilter.dateFromStr),
            fetchDrawingInfo(
              defaultStateReducer.selectedOrg,
              defaultStateReducer.dateFromStr,
              defaultStateReducer.isAttendExists,
              employmentOptions,
              employmentSelectOpenDates[0]?.closingDate.toString() ?? "0",
            ),
          ]).finally(() => {
            setEmploymentSelectOpenDateLoading(false);
          });
        });
      });
    } else {
      fetchEmploymentOptions(stateFilter.selectedOrg, stateFilter.dateFromStr).then((employmentOptions) => {
        Promise.all([
          fetchOrg(stateFilter.dateFromStr),
          fetchDrawingInfo(
            stateFilter.selectedOrg,
            stateFilter.dateFromStr,
            stateFilter.isAttendExists,
            employmentOptions,
            "0",
          ),
        ]).finally(() => {
          setEmploymentSelectOpenDateLoading(false);
        });
      });
    }
  }, [showLaborCost, stateFilter.dateFromStr]);

  useEffect(() => {
    try {
      setEmploymentSelectOpenDateLoading(true);

      if (showLaborCost) {
        fetchDrawingInfo(
          stateFilter.selectedOrg,
          stateFilter.dateFromStr,
          stateFilter.isAttendExists,
          stateFilter.employmentOptions,
          stateFilter.cumulativeClosingDate,
        );
      } else {
        fetchDrawingInfo(
          stateFilter.selectedOrg,
          stateFilter.dateFromStr,
          stateFilter.isAttendExists,
          stateFilter.employmentOptions,
          "0",
        );
      }
    } catch (error) {
    } finally {
      setEmploymentSelectOpenDateLoading(false);
    }
  }, [headerRef.current]);

  const fetchData = (cumulativeClosingDate: string) => {
    return fetchDrawingInfo(
      stateFilter.selectedOrg,
      stateFilter.dateFromStr,
      stateFilter.isAttendExists,
      stateFilter.employmentOptions,
      cumulativeClosingDate,
    );
  };

  const resizeTable = debounce(async () => {
    try {
      setLoading(true);

      if (showLaborCost) {
        await fetchData(stateFilter.cumulativeClosingDate);
        await fetchData(stateFilter.cumulativeClosingDate);
      } else {
        await fetchData("0");
        await fetchData("0");
      }
    } finally {
      setLoading(false);
      setEmploymentSelectOpenDateLoading(false);
    }
  }, 1000);

  useEffect(() => {
    window.addEventListener("resize", resizeTable);
    return () => window.removeEventListener("resize", resizeTable);
  }, []);

  return {
    detailRole,
    remote,
    useSales,
    setRemote,
    isLoading: isLoading || isEmploymentSelectOpenDateLoading,
    setLoading,
    openConfirmModal,
    setOpenConfirmModal,
    openRegisterModal,
    setOpenRegisterModal,
    openDeleteModal,
    setOpenDeleteModal,
    companyCode,
    dailyShift,
    setDailyShift,
    stateFilter,
    dispatch,
    loadEmploymentDone,
    fetchOrg,
    fetchEmploymentOptions,
    fetchDrawingInfo,
    employmentSelectOpenDateList,
    selectedEmploymentSelectOpenDate,
    setSelectedEmploymentSelectOpenDate,
    showLaborCost,
    headerRef,
    filters: {
      dateToStr,
      setDateToStr,
      downloadPdf,
      submitShiftLockout,
      checkall,
      setCheckall,
    },

    calendar: {
      items,
      setItems,
      deleteItem,
      groups,
      setGroups,
      selectedItem,
      setSelectedItem,
      selectedItemIdArr,
      setSelectedItemIdArr,
      selectedGroup,
      setSelectedGroup,
      defaultTimeStart,
      setDefaultTimeStart,
      defaultTimeEnd,
      setDefaultTimeEnd,
      sidebarName,
      sidebarSupport,
      sidebarType,
      sidebarHeaderWidth,
      sidebarHourHeaderRightWidth,
      sidebarAllowanceHeaderRightWidth,
      onTimeChange,
      onItemMove,
      onItemSelect,
      onItemClick,
      onCanvasClick,
      onItemResize,
      moveResizeValidator,
      onClickAttendStampList,
      fetchDailyShiftData,
    },
    contextMenu: {
      isPerformanceMenuVisible,
      setPerformanceMenuVisible,
      openShiftPatternModal,
      setShiftPatternModal,
      isItemMenuVisible,
      setItemMenuVisible,
      isTimelineMenuVisible,
      setTimelineMenuVisible,
      contextMenuRef,
      contextMenuX,
      setContextMenuX,
      contextMenuY,
      setContextMenuY,
      menuTimelineButtons,
      setMenuTimelineButtons,
      menuPerformanceButtons,
      setMenuPerformanceButtons,
      menuItemButtons,
      setMenuItemButtons,
      getMenuPerformanceButtons,
      getMenuItemButtons,
      getMenuTimelineButtons,
    },
    shiftPattern: {
      openShiftPatternModal,
      setShiftPatternModal,
      shiftPatternOptions,
      setShiftPatternOptions,
      getWorkdayOptions,
    },
  };
};
