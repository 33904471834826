/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import TextForm from "components/molecules/TextForm";
import { RecorderButton, PrimaryButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  formRecorder: css({
    textAlign: "center",
  }),
  dateForm: css({
    width: `${isIpad ? '100%' : '420px'}`,
    justifyContent: 'center',
    margin: "auto",
  }),
  formContainer: css({
    marginTop: "15px",
    borderRadius: "3px",
    paddingBottom: isIpad ? "20px" : "50px",
    display: isIpad ? 'flex' : '',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }),
  imageButton: css({
    width: "100%",
    textAlign: 'center',
    height: '80px',
  }),
  ipadTimeContainer: css({
    display: "flex",
    flexWrap: "wrap",
    alignSelf: "center",
    width: "100%",
    marginLeft: '20px',
    justifyContent: 'center'
  })
};

const inputForm = css`
  width: ${isIpad ? '420px' : '450px'};
  margin: auto;
  justify-content: space-between;
`;

const timeCss = css`
  align-items: baseline;
  justify-content: space-between;
  margin: auto;
  width: ${isIpad ? 'auto' : '350px'};
  color: red;
  line-height: ${isIpad ? '80px' : '100px'};
  span:first-of-type {
    font-size: ${isIpad ? '64px' : '100px'};
    margin-right: 10px;
    font-weight: bold;
    color: ${grayScale.gray100};
  }
  span:last-of-type {
    font-size: 32px;
    color: ${grayScale.gray100};
  }
`;
const btnContainer = css`
  flex-wrap: wrap;
  width: ${isIpad ? '500px' : '700px'};
  justify-content: space-between;
  margin: 30px auto 30px auto;
  height: auto;
  button {
    border-radius: 3px;
    border: 0;
    height: 120px;
    font-size: 24px;
    height: 120px;
    margin-bottom: 20px;
  }
  button:hover {
    opacity: 0.9;
  }
`;

const BarcodeRecorderUI: React.FC<{
  formik: any,
  dataTimes: any,
  isConfirm: boolean,
  handleSubmit: any,
  disabledRecorder: boolean,
  lastStampType: any,
  handleRecorder: any
}> = ({
  formik,
  dataTimes,
  isConfirm,
  handleSubmit,
  disabledRecorder,
  lastStampType,
  handleRecorder,
}) => {
  return (
    <React.Fragment>
      <div css={styles.formContainer}>
        <div css={isIpad ? styles.ipadTimeContainer : {}}>
          {dataTimes.currentDay ? (
            <React.Fragment>
              <FlexBox customStyle={styles.dateForm}>
                <BodyText size="lg" color={grayScale.gray100} bold={true}>
                  {dataTimes.currentDay}
                </BodyText>
              </FlexBox>
              <FlexBox customStyle={timeCss}>
                <BodyText>{dataTimes.timeNow}</BodyText>
                <BodyText>{dataTimes.second}</BodyText>
              </FlexBox>
            </React.Fragment>
          ) : (
            <div
              style={{
                fontSize: isIpad ? '30px' : '80px',
                textAlign: 'center',
                marginBottom: '20px'
              }}
            >
              サーバ時間取得中・・・
            </div>
          )}
        </div>

        {!isConfirm && (
          <FlexBox customStyle={inputForm}>
            <FlexBoxItem basis="270px">
              <TextForm
                name="inputBarcodeId"
                label=""
                value={formik.values.inputBarcodeId}
                onChange={formik.handleChange}
                placeHolder="IDを入力してください"
              />
            </FlexBoxItem>
            <PrimaryButton
              text="IDを入力"
              onClick={() => handleSubmit()}
              disabled={disabledRecorder}
              minWidth={isIpad ? "120px" : "160px"}
              customStyle={css({ fontWeight: "bold" })}
            />
          </FlexBox>
        )}
        {isConfirm && (
          <React.Fragment>
            <FlexBox customStyle={btnContainer}>
              {(lastStampType === -1 || lastStampType === 3) && (
                <FlexBoxItem basis="48%">
                  <RecorderButton
                    text="登園"
                    onClick={() => handleRecorder(0)}
                    icon="checkInOffice"
                    background="#007BC3"
                  />
                </FlexBoxItem>
              )}
              {(lastStampType === 0 || lastStampType === 2) && (
                <FlexBoxItem basis="48%">
                  <RecorderButton
                    text="退園"
                    onClick={() => handleRecorder(3)}
                    icon="checkOut"
                    background="#666666"
                  />
                </FlexBoxItem>
              )}
            </FlexBox>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default BarcodeRecorderUI;
