/** @jsx jsx */
import React, { useRef, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import Select, { components, OptionTypeBase } from "react-select";
import { ValueType, MenuPlacement } from "react-select/src/types";

import { grayScale, productColor, textFontSize, textColor, utilityColor } from "components/styles";
import FormLabel from "components/atoms/Form/FormLabel";
import Icon from "components/atoms/Icon";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import useIsMobile from "hooks/useIsMobile";
import { notMinScreen } from "utility/mediaQueries";

export interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}

const styles = {
  label: css({
    flexBasis: "100px",
  }),
  select: (disable?: boolean) =>
    css({
      flexGrow: 4,
      fontSize: textFontSize.re,
      cursor: disable ? "not-allowed" : "ponter",
    }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: "4px",
  }),
};

// Convert select to text
const ghostStyle = () => css`
  div {
    &:hover,
    &:focus {
      border: 0;
    }
    svg {
      display: none;
    }
    background-color: transparent;
    color: ${grayScale.gray100};
    border: 0;
    margin: 0;
    padding: 0;
  }
`;

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    "& > div": {
      paddingRight: 0,
    },
    border: `1px solid ${grayScale.gray03}`,
    borderRadius: "2px",
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${grayScale.gray03}`,
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    cursor: "pointer",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: "40px",
    padding: "2px 8px",
    [notMinScreen("md")]: {
      padding: "2px",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: textFontSize.re,
    backgroundColor: (() => {
      if (state.isSelected) return productColor.primary;
      if (state.isFocused) return productColor.primaryM80;
      return "";
    })(),
    color: state.isSelected ? textColor.inversed : textColor.main,
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796",
    borderRadius: "12px",
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: "21px",
    paddingRight: "6px",
    ":hover": {
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: "14px",
  }),
  menu: (oldStyles: any) => ({ ...oldStyles, zIndex: 9999 }),
};

/**
 * 選択式フォーム
 */
const VerticalLabelSelectForm: React.FC<{
  label?: string;
  name: string;
  value: string;
  options: Array<OptionType>;
  setValue: (val: string) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  errorMsg?: string;
  required?: boolean;
  disable?: boolean;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
  width?: string;
  ghost?: boolean;
}> = ({
  label,
  name,
  value,
  options,
  setValue,
  isMulti,
  isSearchable = true,
  note,
  errorMsg,
  required,
  disable,
  placeholder,
  menuPlacement = "auto",
  width,
  ghost = false,
}) => {
  const isMobile = useIsMobile();
  const getValue = (): ValueType<OptionType> => {
    if (options) {
      return options.find((option) => option.value === value);
    }
    return "" as any;
  };

  const onChange = (option: any) => {
    if (!option) {
      setValue("");
    } else if (Array.isArray(option) && option.length) {
      const array = option.map((opt) => (opt as OptionType).value);
      setValue(`${array.toString()},`);
    } else {
      setValue((option as OptionType).value);
    }
  };

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={grayScale.gray100} />
    </components.MultiValueRemove>
  );

  //auto scrollIntoView upon click
  const MenuList = (props: any) => {
    const ref = useRef<any>();

    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }, []);

    return <components.MenuList {...props} className={"menuList"} innerRef={ref} />;
  };

  return (
    <div css={css({ padding: "4px 0", width: width })}>
      <VerticalLabelFormLayout
        label={label && <FormLabel label={label} required={required} />}
        input={
          <div css={styles.select(disable)}>
            <Select
              styles={selectStyles}
              name={name}
              value={getValue() || null}
              onChange={onChange}
              options={options}
              placeholder={placeholder || "選択してください"}
              isMulti={isMulti}
              isClearable={false}
              isSearchable={isSearchable}
              components={{
                IndicatorSeparator: () => null,
                MultiValueRemove,
                DropdownIndicator: (props) => (
                  <components.DropdownIndicator
                    {...props}
                    css={css(isMobile ? { padding: "0px !important" } : undefined)}
                  />
                ),
                IndicatorsContainer: ({ children, ...others }) => <div {...others}>{children}</div>,
                MenuList,
              }}
              isDisabled={disable || ghost}
              menuPlacement={menuPlacement}
              closeMenuOnScroll={(event: any) => {
                if (event.target?.className?.split(" ").includes("menuList")) {
                  return false;
                } else {
                  return true;
                }
              }}
              menuPosition={"fixed"}
              maxMenuHeight={180}
              css={ghost ? ghostStyle : {}}
            />
          </div>
        }
        errorMsg={errorMsg}
      />
      {note && (
        <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />
      )}
    </div>
  );
};

export default VerticalLabelSelectForm;
