/** @jsx jsx */

import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import Icon from "components/atoms/Icon";
import { utilityColor } from "components/styles";
import { css, jsx } from "@emotion/core";
import Select, { OptionType } from "components/atoms/Select";
import moment from "moment";
import { getDate, addAYear, subAYear } from "./Helpper";
import { Option, IndicatorsContainer, SingleValueContainer } from "./Component";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const MIN_YEAR = 1900;
const MAX_YEAR = 2099;

const OptionalCalendarMonth: React.FC<{
  readOnly?: boolean;
  dateFormat?: string;
  date?: Date;
  setDate: (date: Date | undefined) => void;
  modal?: boolean;
  showDeleteIcon?: boolean;
}> = ({ readOnly = false, dateFormat = "yyyy/MM", date, setDate, modal = false, showDeleteIcon = false }) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRefContainer = useRef<HTMLDivElement>(null);

  const rangeLimitTime = {
    minTime: moment()
      .set("year", MIN_YEAR)
      .startOf("year")
      .toDate(),
    maxTime: moment()
      .set("year", MAX_YEAR)
      .endOf("year")
      .toDate(),
  };
  const validateYear = (date: Date) => {
    if (
      date.getFullYear() < MIN_YEAR ||
      date.getFullYear() > MAX_YEAR
      // || optionsYear.filter((item) =>item.value === String(date.getFullYear())).length === 0
    ) {
      return false;
    }
    return true;
  };

  const [optionsYear, setOptionsYear] = useState<Array<OptionType>>([]);
  useEffect(() => {
    var listYear = [];
    for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
      listYear.push(i);
    }
    setOptionsYear(
      listYear.map((year) => {
        return {
          value: `${year}`,
          label: `${year}`,
        };
      }),
    );
    // const years = Array.from(new Array(20),( val, index) => index + year);
    // setOptionsYear(years);
  }, []);

  useEffect(() => {
    if (openCalendar) {
      var months = Array.from(document.getElementsByClassName("react-datepicker__month-text"));
      months.forEach((month, index) => {
        month.innerHTML = `${index + 1}`;
      });
    } else {
      setDate(date);
    }
  }, [openCalendar]);

  const handleClickOutside = (event: any) => {
    if (wrapperRefContainer.current && !wrapperRefContainer.current?.contains(event.target)) {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <React.Fragment>
      <div
        className={`container-input-date-picker calendar-month__container_custom ${
          modal ? "customCalendarMonth__modal" : ""
        } ${readOnly ? "calendar-month__readOnly" : ""}`}
        ref={wrapperRefContainer}
      >
        <DatePicker
          readOnly={readOnly}
          strictParsing
          minDate={rangeLimitTime.minTime}
          maxDate={rangeLimitTime.maxTime}
          open={openCalendar}
          placeholderText=""
          selected={date}
          onChange={(date) => {
            setDate(getDate(date));
          }}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
          dateFormat={dateFormat}
          onCalendarClose={() => {
            setOpenCalendar(false);
          }}
          onClickOutside={() => {
            setOpenCalendar(false);
          }}
          onSelect={(date, e: any) => {
            if (e.constructor.name !== "SyntheticEvent") {
              setOpenCalendar(false);
            }
          }}
          onCalendarOpen={() => {
            setOpenCalendar(true);
          }}
          showMonthYearPicker={true}
          popperModifiers={{
            offset: {
              enabled: false,
            },
            flip: { enabled: false },
          }}
          renderCustomHeader={({ date, changeYear, decreaseYear, increaseYear }) => {
            return (
              <React.Fragment>
                <div className="calendar__year">
                  <span
                    className="calendar__arrow calendar__arrow--left"
                    onClick={() => {
                      let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                      tmpDate = subAYear(tmpDate);
                      if (validateYear(tmpDate)) {
                        decreaseYear();
                      }
                    }}
                  ></span>
                  <div
                    css={css`
                      div {
                        justify-content: center;
                        border: 0;
                        :hover {
                          border: 0;
                          cursor: pointer;
                        }
                      }
                    `}
                  >
                    <Select
                      name="select-month"
                      className="calendar__year-value"
                      value={String(date.getFullYear())}
                      setValue={(arg: string) => {
                        changeYear(Number(arg));
                      }}
                      options={optionsYear}
                      placeholder=""
                      controlStyle={{
                        width: "70px",
                        border: "0",
                        backgroundColor: "transparent",
                      }}
                      components={{ Option, IndicatorsContainer, SingleValueContainer }}
                      maxMenuHeight={200}
                      customMenuStyle={{
                        marginTop: "0",
                      }}
                      isSearchable={false}
                    />
                  </div>
                  <span
                    className="calendar__arrow calendar__arrow--right"
                    onClick={() => {
                      let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                      tmpDate = addAYear(tmpDate);
                      if (validateYear(tmpDate)) {
                        increaseYear();
                      }
                    }}
                  ></span>
                </div>
              </React.Fragment>
            );
          }}
        >
          <div
            className="label-current"
            onClick={() => {
              setDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
              setOpenCalendar(false);
            }}
          >
            今月
          </div>
        </DatePicker>
        <div className="icf-calendar" onClick={() => setOpenCalendar(true)} ref={wrapperRef}>
          <Icon type="date" color={utilityColor.black} />
        </div>
        {showDeleteIcon && (
          <div
            className="cancel-button-calendar"
            onClick={() => {
              setDate(undefined);
            }}
          >
            <Icon type="cancel" color={utilityColor.black} size="10px" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default OptionalCalendarMonth;
