/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import ExecutiveFormImportLayoutPage from 'components/organismos/Executive/ExecutiveFormImportLayoutPage/ExecutiveFormImportLayoutPage';


const ExecutiveFormImportPage: React.FC = () => (
  <SidebarTemplate pageTitle="役職情報インポート">
    <ExecutiveFormImportLayoutPage />
  </SidebarTemplate>
);


export default ExecutiveFormImportPage;
