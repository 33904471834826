import React from "react";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import AttendAllowanceAccordion from "./AttendAllowanceAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";

import RejectedStampDomain from "../domain/rejectedStampDomain";
import RejectedHolidayDomain from "../domain/rejectedHolidayDomain";
import RejectedOvertimeDomain from "../domain/rejectedOvertimeDomain";
import RejectedAttendAllowanceDomain from "../domain/rejectedAttendAllowanceDomain";
import RejectedHolidayWorkDomain from "../domain/rejectedHolidayWorkDomain";
import RejectedTransferDomain from "../domain/rejectedTransferDomain";
import RejectedAgreement36Domain from "../domain/rejectedAgreement36Domain";
import RejectedWorkScheduleChangeDomain from "../domain/rejectedWorkScheduleChange";
import StockPaidHoliday from "./StockPaidHoliday";
import RejectedStockPaidDomain from "../domain/rejectedStockPaidDomain";

const RejectedTab: React.FC<{
  stampList: RejectedStampDomain[];
  holidayList: RejectedHolidayDomain[];
  overtimeList: RejectedOvertimeDomain[];
  attendAllowanceList: RejectedAttendAllowanceDomain[];
  holidayWorkList: RejectedHolidayWorkDomain[];
  transferList: RejectedTransferDomain[];
  agreement36List: RejectedAgreement36Domain[];
  workScheduleList: RejectedWorkScheduleChangeDomain[];
  stockPaidList: RejectedStockPaidDomain[];
}> = ({
  stampList,
  holidayList,
  overtimeList,
  attendAllowanceList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleList,
  stockPaidList,
}) => {
  return (
    <div>
      <StampAccordion list={stampList} />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <AttendAllowanceAccordion list={attendAllowanceList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />

      <Agreement36Accordion list={agreement36List} />

      <WorkScheduleChangeApplicationAccordion list={workScheduleList} />

      <StockPaidHoliday list={stockPaidList} />
    </div>
  );
};

export default RejectedTab;
