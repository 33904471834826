/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import IconLabelButton from 'components/molecules/IconLabelButton';

const style = {
  button: css({
    margin: '-50px 0px 20px 30px',
    fontSize: '12px'
  },
    css` 
    .button-action button:hover{
      background-color:transparent;
    } 
  `),
}

const ButtonNavigation: React.FC<{
}> = () => (
  <div css={style.button}>
    <Link className="button-action" to="/masterEmploymentForm/add">
      <IconLabelButton onClick={() => console.log('edit')} iconType="addCircle" text="追加" />
    </Link>
  </div>
);

export default ButtonNavigation;
