/** @jsx jsx */
import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import TextForm from 'components/molecules/TextForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { SecondaryButton } from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import StaffDomain from 'domain/staff';
import { TablItem } from 'components/atoms/TabBar';
import ConfirmActionModal, { ActionType, ActionDelete } from 'components/organismos/ConfirmActionModal';
import { css, jsx } from '@emotion/core';
import { useStaffAddForm } from './hooks';
import FormContentFilter from 'components/atoms/Form/FormContentFilter';
import CalendarForm from 'components/molecules/CalendarForm';
// import SelectForm from 'components/molecules/SelectForm';
import useStaffList from 'components/pages/master/general/staff/StaffsPage/hooks';
import { useStaffDelete } from '../StaffListTable/hooks';
import BodyText from 'components/atoms/BodyText';
import { useStaffAddPageContext } from 'components/pages/master/general/staff/StaffAddPage/hooks';
import ICCardInput from 'components/molecules/ICCardInput';
import SelectForm from 'components/molecules/SelectForm';
import Calendar from 'components/molecules/CalendarV1';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import moment from 'moment';

type SelectType = 'staff' | 'affiliation' | 'employment' | 'laborCost' | 'workPattern' | 'longTermCareandNurse';
const SelectType: { [key in SelectType]: SelectType } = {
  staff: 'staff',
  affiliation: 'affiliation',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
  longTermCareandNurse: 'longTermCareandNurse'
};

const styles = {
  label: css({
    position: 'relative',
    top: '35px',
    marginRight: '15px'
  }),
  select: css({
    position: 'relative',
    top: '19px',
    width: '150px'
  }),
  selectForm: css({
    width: '50%'
  }),
  formContent: {
    padding: '0px 15px 25px',
    margin: '0px 0px 30px 0px'
  },
  formContentFilter: css({
    margin: '0 auto'
  }),
  formSubmit: css({
    margin: '0 auto',
    textAlign: 'center',
    padding: '30px'
  }),
  flexBoxCenter: css({
    justifyContent: 'center'
  }),
  labelRetire: css({
    marginBottom: '0px'
  })
}

const StaffInfoForm: React.FC<{
  initialStaffDomain: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setOrgTargetDate: React.Dispatch<React.SetStateAction<Date>>;
  setEmpTargetDate: React.Dispatch<React.SetStateAction<Date>>;
  trialPeriodOptions: Array<any>;
  businessOptions: Array<any>;
  roleOptions: Array<any>;
  isDisabledInfo?: boolean;
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  setOrgTargetDate, setEmpTargetDate,
  trialPeriodOptions,
  businessOptions,
  roleOptions,
  isDisabledInfo=false,
  setIsLoading
}) => {
  // const {isEditMode} = useStaffAddPageContext();
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    listICCardByStaff,
    setListICCardByStaff,
    contextObject
    } = useStaffAddForm(initialStaffDomain, isEditMode,setIsLoading);
  // const [isEmptyCalendarForm, setIsEmptyCalendarForm] = useState(false);
  // const [isEmptyCalendarForm2, setIsEmptyCalendarForm2] = useState(false);
  // const [isEmptyCalendarForm3, setIsEmptyCalendarForm3] = useState(false);
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE === 'true';

  const { fetchData } = useStaffList();
  const { setDeleteTargetStaff, isOpenDeleteModal, setIsOpenDeleteModal, closeDeleteModal, onSubmitDeleteStaff } = useStaffDelete(fetchData, setIsLoading);
  const getStateStatus = (hireDate: any, retirementDate: any) => {
    if(hireDate > new Date()) return "入社前";
    if(retirementDate === undefined && hireDate <= new Date()) return "在職";
    if(retirementDate !== undefined && hireDate <= new Date() && retirementDate >= new Date()) return "退職予定";
    if(retirementDate !== undefined && retirementDate < new Date) return "退職";
    return ""
  }
  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <FormContentFilter customStyles={styles.formContentFilter}>
          <FormTitle
            title="基本情報"
            bold={true}
            customStyle={css({marginLeft: '0'})}
          />
          <FormField>
            <TextForm
              name="staffName"
              label="姓・名"
              placeHolder="姓・名"
              value={formik.values.staffName}
              onChange={formik.handleChange}
              required={true}
              errorMsg={formik.errors.staffName}
              disable={isDisabledInfo}
            />
          </FormField>
          <FormField>
            <TextForm
              name="staffNameKana"
              label="せい・めい"
              placeHolder="せいめい"
              value={formik.values.staffNameKana || ''}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormField>
            <TextForm
              name="staffCode"
              label="スタッフコード"
              placeHolder="半角英数字20文字まで"
              value={formik.values.staffCode}
              onChange={formik.handleChange}
              required={true}
              errorMsg={formik.errors.staffCode}
              maxLength={20}
              disable={isDisabledInfo}
            />
          </FormField>
          <FormField>
            <FlexBox>
            <FormLabel
                  label="状態"
                  customStyle={styles.labelRetire}
                />
                {isEditMode && (
                  <BodyText customStyle={css({
                    marginLeft: '20px',
                    color:'#333333'
                  })}
                  >
                    {getStateStatus(formik.values.hireDate, formik.values.retirementDate)}
                </BodyText>
              )}
            </FlexBox>
          </FormField>
          <FormField>
          <div style={{ width: '100%' }}>
              <FlexBox>
                <FlexBoxItem basis="30%">
                  <VerticalLabelFormLayout
                    label={<FormLabel label="入社日" required={true} />}
                    input={
                      <Calendar
                        readOnly={isDisabledInfo}
                        date={formik.values.hireDate}
                        canNull={false}
                        setDate={(date) => {
                          if(date?.toString() !== formik.values.hireDate?.toString()){
                            if (formik.values.paidHolidayStartDate === null || formik.values.paidHolidayStartDate === undefined) {
                              formik.setFieldValue('paidHolidayStartDate', date);
                            }
                          }
                          if (date === null) {
                            formik.setFieldValue('hireDate', undefined);
                          } else {
                            if (!isEditMode) {
                              formik.values.selectSalary.applyStartDate = date;
                              setOrgTargetDate(date);
                              setEmpTargetDate(date);
                            }
                            formik.setFieldValue('hireDate', date);
                          }
                        }}
                      />
                    }
                  />
                  {/* <CalendarForm
                      dateFormat="YYYY/MM/DD"
                      label="入社日"
                      date={formik.values.hireDate}
                      isEmptyCalendarForm={!formik.values.hireDate ? true : false}
                      changeDate={(date: Date) => {
                        if (date === null) {
                          formik.setFieldValue('hireDate', undefined);
                        } else {
                          if (!isEditMode) {
                            formik.values.selectSalary.applyStartDate = date;
                            setOrgTargetDate(date);
                            setEmpTargetDate(date);
                          }
                          formik.setFieldValue('hireDate', date);
                        }
                        if (formik.values.paidHolidayStartDate === null || formik.values.paidHolidayStartDate === undefined) {
                          formik.setFieldValue('paidHolidayStartDate', date);
                        }
                    }}
                      required={true}
                      errorMsg={formik.errors.hireDate}
                      readOnly={isDisabledInfo}
                    /> */}
                </FlexBoxItem>
                <FlexBoxItem basis="70%" marginLeft="20px">
                  <FormField>
                    <FormLabel label="試用期間" customStyle={styles.label} />
                    <div css={styles.select}>
                      <VerticalLabelSelectForm
                        label=""
                        name="trialTerm"
                        value={String(formik.values.trialTerm)}
                        setValue={(val: string) => { formik.setFieldValue('trialTerm', Number(val)); }}
                        options={trialPeriodOptions}
                      />
                    </div>
                  </FormField>
                </FlexBoxItem>
              </FlexBox>
            </div>
          </FormField>
          <FormField>
            <FlexBoxItem basis="30%">
              <FormField>
                <FormLabel label="有給起算日" />
              </FormField>
              <Calendar
                date={formik.values.paidHolidayStartDate}
                canNull={true}
                setDate={(date) => {
                  if(date){
                    formik.setFieldValue('paidHolidayStartDate', date);
                  }else{
                    formik.setFieldValue('paidHolidayStartDate', moment('9999/12/31').toDate());
                  }
                }}
                hiddenButtonRemove={isAuthorizedFromPeople}
              />
            </FlexBoxItem>
            <FlexBoxItem basis="70%" marginLeft="20px">
              <FormField>
                <FormLabel
                  label=""
                  appendLabel='有給付与の基準日です。未設定の場合は、入社日を使用します' customStyle={styles.label}/>
              </FormField>
            </FlexBoxItem>
          </FormField>
          <FormField>
            <FlexBoxItem basis="30%">
              <FormField>
                <FormLabel label="退職日" />
              </FormField> 
              <Calendar
                date={formik.values.retirementDate}
                // canNull={!formik.values.retirementDate ? true : false}
                canNull={true}
                setDate={(date) => {
                  if(date){
                    formik.setFieldValue("retirementDate", date);
                  }else{
                    formik.setFieldValue("retirementDate", moment('9999/12/31').toDate());
                  }
                }}
                readOnly={isDisabledInfo}
                hiddenButtonRemove={isAuthorizedFromPeople}
              />
            </FlexBoxItem>
          </FormField>
          <FormField>
            <TextForm
              label="メールアドレス"
              name="mail"
              value={formik.values.mail || ''}
              onChange={formik.handleChange}
              placeHolder="半角英数字"
              toolTip="サインアップにはメールアドレスが必要になります。</br>
              （メールアドレスを削除するとログインできなくなります。</br>
              メールアドレス再設定後は、再度、サインアップから行うことになります）"
              customIconHelp={css({ marginBottom: '14px' })}
              disable={isDisabledInfo}
            />
          </FormField>
          <FormField displayBlock={true}>
            {(formik.values.useRoleList || !isEditMode) ? (
              <VerticalLabelSelectForm
                label="権限"
                name="personalRole"
                value={formik.values.personalRole}
                setValue={(val: string) => { 
                  formik.setFieldValue('personalRole', val);
                  formik.setFieldValue('role', val); 
                }}
                options={roleOptions}
              />
            ) : (
              <React.Fragment>
                <FormField>
                  <FormLabel label={"権限"}/>
                </FormField>
                <BodyText>{formik.values.personalRoleName}</BodyText>
              </React.Fragment>
            )}
            
          </FormField>
          <FormTitle
            title="労務管理情報"
            bold={true}
            customStyle={css({marginLeft: '0'})}
          />
          <FormField>
            <RadioSelectForm
              label="性別"
              items={[
                {
                  label: '男性',
                  value: '1',
                },
                {
                  label: '女性',
                  value: '2',
                },
              ]}
              name="gender"
              value={String(formik.values.gender)}
              setValue={(e) => { formik.setFieldValue('gender', Number(e.target.value)); }}
            />
          </FormField>

          <FormField>
            <FlexBoxItem basis="30%">
              {/* <CalendarForm
                  dateFormat="YYYY/MM/DD"
                  label="生年月日"
                  isEmptyCalendarForm={!formik.values.birthDate ? true : false}
                  date={formik.values.birthDate}
                  changeDate={(date: Date) => {
                    formik.setFieldValue('birthDate', date)
                  }
                  }
                  readOnly={isDisabledInfo}
                /> */}
              <FormField>
                <FormLabel label="生年月日" />
              </FormField>
              <Calendar
                date={formik.values.birthDate}
                canNull={true}
                setDate={(date) => {
                  if(date){
                    formik.setFieldValue("birthDate", date);
                  }else{
                    formik.setFieldValue('birthDate', moment('9999/12/31').toDate());
                  }
                }}
                readOnly={isDisabledInfo}
                maxYear={new Date().getFullYear()}
                hiddenButtonRemove={false}
              />
            </FlexBoxItem>
          </FormField>

          <FormField>
            <RadioSelectForm
              label="国籍"
              items={[
                {
                  label: '日本',
                  value: '1',
                },
                {
                  label: '日本以外',
                  value: '2',
                },
              ]}
              name="nationality"
              value={String(formik.values.nationality)}
              setValue={(e) => {
                formik.setFieldValue('nationality', Number(e.target.value));
              }}
              disabled={isDisabledInfo}
            />
          </FormField>

          {formik.values.nationality === 2 && (
            <FormContents customStyle={styles.formContent}>
              <FormField>
                <FlexBoxItem basis="40%">
                  {/* <CalendarForm
                      dateFormat="YYYY/MM/DD"
                      label="在留カード・証明書有効期限"
                      date={formik.values.alienRegistrationCardExpirationDate}
                      changeDate={(date: Date) => formik.setFieldValue('alienRegistrationCardExpirationDate', date)}
                      readOnly={isDisabledInfo}
                    /> */}
                  <FormField>
                    <FormLabel label="在留カード・証明書有効期限" />
                  </FormField>
                  <Calendar
                    date={formik.values.alienRegistrationCardExpirationDate}
                    setDate={(date) => {
                      if(date){
                        formik.setFieldValue('alienRegistrationCardExpirationDate', date)
                      }
                    }}
                    readOnly={isDisabledInfo}
                  />
                </FlexBoxItem>
              </FormField>
              <FormField>
                <RadioSelectForm
                  label="労働時間上限チェック(週28時間)"
                  items={[
                    {
                      label: 'しない',
                      value: '1',
                    },
                    {
                      label: 'する',
                      value: '2',
                    },
                  ]}
                  name="statusResidence"
                  value={String(formik.values.statusResidence)}
                  setValue={(e) => {
                    formik.setFieldValue('statusResidence', Number(e.target.value));
                  }}
                />
              </FormField>
            </FormContents>
          )}
          <FormTitle
            title="打刻情報"
            bold={true}
            customStyle={css({marginLeft: '0'})}
          />
          <FormField>
            <TextForm
              label="打刻ID"
              name="barcodeId"
              value={formik.values.barcodeId || ''}
              onChange={formik.handleChange}
            />
          </FormField>
          <FormField customStyle={css({flexWrap: 'wrap'})}>
              <FlexBox customStyle={css({width: '100%'})}>
                <FormLabel
                  label="カード番号(IDm)"
                />
            </FlexBox>
            <FlexBox customStyle={css({width: '100%'})}>
              <ICCardInput
                formik={formik}
                listICCard={formik.values.icCardListData || []}
                staffCode={formik.values.staffCode}
                staffName={formik.values.staffName}
                setIsLoading={setIsLoading}
              />
            </FlexBox>
          </FormField>
          {/* <FormTitle
              title="業務情報"
              bold={true}
            />
            <FormField displayBlock={true}>
              <VerticalLabelMutilSelectForm
                label="就業可能業務"
                name="staffBussinessList"
                values={formik.values.staffBusinessList.map(
                  (staffBusiness: StaffBusiness) => businessOptions.find(
                    (item) => staffBusiness.business_id === item.value,
                  ),
                )}
                setValue={(val: any) => {
                  if (val !== '') {
                    formik.setFieldValue('staffBusinessList', val.slice(0, -1).split(',').map(
                      (id: string) => ({ business_id: id }),
                    ));
                  } else {
                    formik.setFieldValue('staffBusinessList', []);
                  }
                }}
                options={businessOptions}
                note="就業可能な業務が使用できる雇用形態の設定が必要です。"
                isMulti={true}
              />
            </FormField> */}
          <FormSubmitArea customStyle={styles.formSubmit}>
            <FlexBox customStyle={styles.flexBoxCenter}>
              {contextObject?.detailRole.editable === 1 && (
                <React.Fragment>
                  <FlexBoxItem>
                    <div style={{ marginRight: '12px' }}>
                        <Button
                          text={isEditMode ? 'スタッフ情報の更新' : '所属情報の入力へ'}
                          minWidth="150px"
                          onClick={() => {
                            if (isEditMode) {
                              formik.handleSubmit();
                            } else if (formik.values.staffCode && formik.values.staffName 
                              && formik.values.hireDate) {
                              formik.values.orgApplyDate = formik.values.hireDate;
                              formik.values.empApplyDate = formik.values.hireDate;
                              formik.values.selectSalary.applyStartDate = formik.values.hireDate;

                              if (tabItems.length <= 1) {
                                setInitialStaffDomain(formik.values);
                                setTabItems([
                                  {
                                    value: SelectType.staff,
                                    display: 'スタッフ情報',
                                  },
                                  {
                                    value: SelectType.affiliation,
                                    display: '所属情報',
                                  }]);
                              }
                              setSelectType(SelectType.affiliation);
                            } else {
                              formik.handleSubmit();
                            }
                          }}
                        />
                      </div>
                  </FlexBoxItem>
                  {
                    isEditMode &&!isDisabledInfo?
                    <FlexBoxItem>
                      <div style={{ marginRight: '12px' }}>
                        <SecondaryButton
                          text="削除"
                          onClick={() => {
                            setIsOpenDeleteModal(true);
                            setDeleteTargetStaff(formik.values)
                          }}
                          minWidth="150px"
                        />
                      </div>
                    </FlexBoxItem>
                    : ''
                  }
                </React.Fragment>
              )}
              
            </FlexBox>
            <Link to="/staffs">
              <Button
                  text="キャンセル"
                  ghost={true}
                />
            </Link>
          </FormSubmitArea>
        </FormContentFilter>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
        typeSubmit="reject"
        actionDelete={ActionDelete.STAFF}
      />
    </React.Fragment>
  );
};

export default StaffInfoForm;
