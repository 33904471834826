/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { Attend36AgreementsAlertData } from './type';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import Table from 'components/molecules/Table';
import productColor from 'components/styles';

const styles = {
  detailText: css({
    color: productColor.primary,
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer'
    }
  }),
  tableModal: css({
    maxWidth: '1000px'
  }),
  labelTable: css({
    margin: '20px 0'
  })
}

const getOverTimeFlag = (flag: number) => {
  let tmpStyle = {
    margin: '-14px -10px',
    padding: '14px'
  }
  switch (flag) {
    case 1:
      return {...tmpStyle, backgroundColor: productColor.copyAction}
    case 2:
      return {...tmpStyle, backgroundColor: productColor.secondary}
    default:
      return tmpStyle
  }
}

const getOverHolidayTimeFlag = (flag: number) => {
  let tmpStyle = {
    margin: '-14px -10px',
    padding: '14px'
  }
  switch (flag) {
    case 1:
      return {...tmpStyle, backgroundColor: productColor.copyAction}
    case 2:
      return {...tmpStyle, backgroundColor: productColor.secondary}
    default:
      return tmpStyle
  }
}

const ModalDetailStaff: React.FC<{
  staffDetail: Array<Attend36AgreementsAlertData>
}> = ({
  staffDetail
}) => {

    return (
      <React.Fragment>
        <FlexBox customStyle={styles.labelTable}>
          <BodyText customStyle={css({marginRight: '8px', color: '#333333'})}>
            {staffDetail[0].orgCode}
          </BodyText>
          <BodyText customStyle={css({marginRight: '50px', color: '#333333'})}>
            {staffDetail[0].orgName}
          </BodyText>
          <BodyText customStyle={css({marginRight: '8px', color: '#333333'})}>
            {staffDetail[0].staffCode}
          </BodyText>
          <BodyText customStyle={css({color: '#333333'})}>
            {staffDetail[0].staffName}
          </BodyText>
        </FlexBox>
        <div css={css`
          table td:nth-of-type(n+2){
            text-align: center
          }
        `}>
          <Table css={styles.tableModal}>
            <thead>
              <tr>
                <Table.HeaderCell>年月</Table.HeaderCell>
                <Table.HeaderCell>法定外</Table.HeaderCell>
                <Table.HeaderCell style={{width: '120px'}}>法定外＋休日出勤</Table.HeaderCell>
                <Table.HeaderCell>２ヶ月平均</Table.HeaderCell>
                <Table.HeaderCell>3ヶ月平均</Table.HeaderCell>
                <Table.HeaderCell>4ヶ月平均</Table.HeaderCell>
                <Table.HeaderCell>5ヶ月平均</Table.HeaderCell>
                <Table.HeaderCell>6ヶ月平均</Table.HeaderCell>
              </tr>
            </thead>
            <tbody>
              {staffDetail?.map((item, index) => {
                let averageComponent = item.averageTimeHour.map((average, index) => (
                  <Table.Cell customStyle={css(getOverHolidayTimeFlag(item.averageTimeFlag[index]))}>
                    {average || ''}
                  </Table.Cell>
                ))
                return(
                  <tr key={index}>
                    <Table.Cell>
                      {moment(item.targetMonth).format('YYYY/MM')}
                    </Table.Cell>
                    <Table.Cell customStyle={css(getOverTimeFlag(item.overTimeFlag))}>
                      {item.overTimeHour}
                    </Table.Cell>
                    <Table.Cell customStyle={css(getOverHolidayTimeFlag(item.overHolidayTimeFlag))}>
                      {item.overHolidayTimeHour}
                    </Table.Cell>
                    {averageComponent}
                  </tr>
              )})
            }
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    )
  }

export default ModalDetailStaff;
