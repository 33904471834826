
import React from 'react';
import IconTooltip from 'components/molecules/IconTooltip';
import moment from 'moment';
import { eventScroll } from './hooks';

const SalesReportTable: React.FC<{
  grandTotalnData: any,
  sameDayData: any,
  generalData: any,
  dataKeyName: any,
  countAdvertising: any,
  advertisingData: any,
  targetDay: any
}> = ({
  grandTotalnData,
  sameDayData,
  generalData,
  dataKeyName,
  countAdvertising,
  advertisingData,
  targetDay,
}) => {
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };

  return (
    <div className="box-body" id="SalesDailyReport">
      <div className="row" id="layoutSale_02">
        <div className="col-md-12">
          <div id="resize-detect-container" className="tab-content">
            {/* レポート */}
            <div className="tab-pane active" id="search_tab_1">
              <div className="col-md-12">
                {/* ヘッダー部 */}
                <div className="row" id="layoutSale_01">
                  <div className="col-md-12">
                    <div className="row">
                      {/* 左上 ---------------------------------------------------------------------------------------*/}
                      <div className="col-xs-3 col-md-3 no-padding layout_sale_left">
                        <div className="box-body no-padding">
                          <div className="table-responsive no-padding">
                            <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="text-center height_row_plus" rowSpan={2}>項目</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* 右上 ---------------------------------------------------------------------------------------*/}
                      <div className="col-xs-9 col-md-9 no-padding overflow-hidden layout_sale_right" id="layout_sale_right_01">
                        <div className="layout_sale_right_content">
                          <div className="col-xs-12 col-md-12 no-padding">
                            <div className="box-body no-padding">
                              <div className="table-responsive no-padding layout-sale-right">
                                <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '800px' }}>
                                  <tbody>
                                    <tr>
                                      <td className="text-center" colSpan={3}>
                                        { moment(new Date(targetDay), 'YYYY-MM-DD').format('D') }
                                        日までの累計
                                      </td>
                                      <td className="text-center" colSpan={2}>月次</td>
                                      <td className="text-center" colSpan={3}>当日</td>
                                    </tr>
                                    <tr>
                                      <td className="text-center">実績</td>
                                      <td className="text-center">予算</td>
                                      <td className="text-center">進捗率</td>
                                      <td className="text-center">予算</td>
                                      <td className="text-center">達成率</td>
                                      <td className="text-center">実績</td>
                                      <td className="text-center">予算</td>
                                      <td className="text-center">達成率</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                    <div className="row" id="layoutSale_02_buttom" style={{ clear: 'both' }}>
                      {/* 左下 ---------------------------------------------------------------------------------------*/}
                      <div className="col-xs-3 col-md-3 no-padding layout_sale_left">
                        <div className="box-body no-padding">
                          <div className="table-responsive no-padding">
                            <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="text-center" rowSpan={(countAdvertising + 3)}>売上高</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="売上高（項目）＋売上高（フリー）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" rowSpan={(countAdvertising + 1)}>
                                    広告
                                  </td>
                                  <td className="text-center">
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="売上高（広告）項目の総計" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {(countAdvertising && advertisingData)
                                  ? advertisingData.map((advertising: any, keyTr: number) => (
                                    <tr key={keyTr}>
                                      <td className="text-center">
                                        { advertising.advertising_media_mst_name }
                                      </td>
                                    </tr>
                                  ))
                                  : ''}

                                <tr>
                                  <td className="text-center">フリー</td>
                                  <td className="text-center">合計</td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={4}>標準原価</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="標準原価（フード）＋標準原価（ドリンク）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>フード</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>ドリンク</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="標準原価（合計） / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>売上総利益</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="売上高（合計） - 標準原価（合計）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="売上総利益（合計） / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={4}>人件費</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="人件費（社員）＋人件費（バイト）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>社員</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>バイト</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="人件費（合計） / 売上高（合計）の％表示" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={(countAdvertising + 2)}>広告宣伝費</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="広告宣伝費項目の総計" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {(countAdvertising && advertisingData)
                                  ? advertisingData.map((advertising: any, keyTr: number) => (
                                    <tr key={keyTr}>
                                      <td className="text-center" colSpan={2}>
                                        { advertising.advertising_media_mst_name }
                                      </td>
                                    </tr>
                                  ))
                                  : ''}

                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="広告宣伝費（合計） / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>家賃</td>
                                  <td className="text-center" colSpan={2}>合計</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="家賃（合計） / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={4}>その他経費</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="その他経費（変動費）＋その他経費（固定費）の総計" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>変動費</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>固定費</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="その他経費（合計） / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>営業利益</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="売上総利益（合計） - 人件費（合計） - 広告宣伝費（合計） - 家賃（合計） - その他経費（合計）" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="営業利益（合計） / 売上総利益（合計）の％表示" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td className="text-center" rowSpan={4}>仕入額</td>
                                  <td className="text-center" colSpan={2}>合計</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>フード</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>ドリンク</td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>その他</td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      客単価&nbsp;
                                      <IconTooltip iconType="help" text="売上高（合計） / 客数" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      客数&nbsp;
                                      <IconTooltip iconType="help" text="POSの客数" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      組数&nbsp;
                                      <IconTooltip iconType="help" text="POSの会計数" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      組あたり平均人数
                                      <IconTooltip iconType="help" text="客数 / 組数" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      回転率&nbsp;
                                      <IconTooltip iconType="help" text="客数 / 座席数" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>
                                    限界利益
                                  </td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      額&nbsp;
                                      <IconTooltip iconType="help" text="売上高（合計） - （人件費（バイト） ＋その他経費（変動費））" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="限界利益 / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>貢献利益</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      額&nbsp;
                                      <IconTooltip iconType="help" text="限界利益 - （人件費（社員）＋広告宣伝費（合計）＋家賃（合計）＋その他経費（固定費））" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="貢献利益 / 売上高（合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>
                                    損益分岐点
                                  </td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      売上&nbsp;
                                      <IconTooltip iconType="help" text="（人件費（社員）＋広告宣伝費（合計）＋家賃（合計）＋その他経費（固定費）） / 限界利益（率）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      客数&nbsp;
                                      <IconTooltip iconType="help" text="損益分岐点売上 / 客単価" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={3}>人件費率</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="人件費（合計） / 売上高 （合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      社員&nbsp;
                                      <IconTooltip iconType="help" text="人件費（社員） / 売上高 （合計）の％表示" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      バイト&nbsp;
                                      <IconTooltip iconType="help" text="人件費（バイト） / 売上高 （合計）の％表示" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={3}>総労働時間</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="総労働時間（社員）＋総労働時間（バイト）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      社員&nbsp;
                                      <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      バイト&nbsp;
                                      <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={3}>勤務人数</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      合計&nbsp;
                                      <IconTooltip iconType="help" text="勤務人数（社員）＋勤務人数（バイト）" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      社員&nbsp;
                                      <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      バイト&nbsp;
                                      <IconTooltip iconType="help" text="勤怠システムから取得" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      人時売上高&nbsp;
                                      <IconTooltip iconType="help" text="売上高（合計） / 総労働時間（合計）" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      人時生産性&nbsp;
                                      <IconTooltip iconType="help" text="売上総利益（合計） / 総労働時間（合計）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      人時接客数&nbsp;
                                      <IconTooltip iconType="help" text="客数 / 総労働時間（合計）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      労働生産性&nbsp;
                                      <IconTooltip iconType="help" text="売上総利益（合計） / 勤怠人数（合計）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    <div className="icon-tooltip">
                                      労働分配率&nbsp;
                                      <IconTooltip iconType="help" text="人件費（合計） / 売上総利益（合計）の%表示" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>FL</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      コスト&nbsp;
                                      <IconTooltip iconType="help" text="標準原価（合計）＋人件費（合計）" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="FLコスト / 売上高（合計）" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>FLA</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      コスト&nbsp;
                                      <IconTooltip iconType="help" text="標準原価（合計）＋人件費（合計）＋広告宣伝費（合計）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="FLAコスト / 売上高（合計）" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>FLAR</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      コスト&nbsp;
                                      <IconTooltip iconType="help" text="標準原価（合計）＋人件費（合計）＋広告宣伝費（合計）＋家賃（合計）" color="#000" />
                                    </div>


                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="FLARコスト / 売上高（合計）" color="#000" />
                                    </div>

                                  </td>
                                </tr>
                                {/*  */}
                                <tr>
                                  <td className="text-center" rowSpan={2}>FLARO</td>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      コスト&nbsp;
                                      <IconTooltip iconType="help" text="標準原価（合計）＋人件費（合計）＋広告宣伝費（合計）＋家賃（合計）＋その他経費（合計）" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-center" colSpan={2}>
                                    <div className="icon-tooltip">
                                      率&nbsp;
                                      <IconTooltip iconType="help" text="FLAROコスト / 売上高（合計）" color="#000" />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* 右下 ---------------------------------------------------------------------------------------*/}
                      <div className="no-padding layout_sale_right" id="layout_sale_right_02">
                        <div className="layout_sale_right_content">
                          <div className="no-padding">
                            <div className="box-body no-padding">
                              <div className="table-responsive no-padding">
                                <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '800px' }}>
                                  <tbody>
                                    {
                                        dataKeyName.map((key_name: any, index: any) => (
                                          <tr key={index}>
                                            { (!grandTotalnData[key_name] || (grandTotalnData[key_name] && grandTotalnData[key_name].length === 0))
                                    && <td className="text-center no-border split_sale_repport" colSpan={5}>&nbsp;</td>}
                                            { (grandTotalnData[key_name] && grandTotalnData[key_name].length > 0)
                                                                            && <td className={(grandTotalnData[key_name][0] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(grandTotalnData[key_name][0]) }</td>}
                                            { (grandTotalnData[key_name] && grandTotalnData[key_name].length > 0)
                                                                            && <td className={(grandTotalnData[key_name][1] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(grandTotalnData[key_name][1]) }</td>}
                                            { (grandTotalnData[key_name] && grandTotalnData[key_name].length > 0)
                                                                            && <td className={(grandTotalnData[key_name][2] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(grandTotalnData[key_name][2]) }</td>}

                                            { (grandTotalnData[key_name] && grandTotalnData[key_name].length > 0)
                                                                            && <td className={(grandTotalnData[key_name][3] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(grandTotalnData[key_name][3]) }</td>}
                                            { (grandTotalnData[key_name] && grandTotalnData[key_name].length > 0)
                                                                            && <td className={(grandTotalnData[key_name][4] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(grandTotalnData[key_name][4]) }</td>}

                                            { (!sameDayData[key_name] || (sameDayData[key_name] && sameDayData[key_name].length === 0))
                                                                            && <td className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>}

                                            { (sameDayData[key_name] && sameDayData[key_name].length > 0)
                                                                            && <td className={(sameDayData[key_name][0] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(sameDayData[key_name][0]) }</td>}

                                            { (sameDayData[key_name] && sameDayData[key_name].length > 0)
                                                                            && <td className={(sameDayData[key_name][1] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(sameDayData[key_name][1]) }</td>}
                                            { (sameDayData[key_name] && sameDayData[key_name].length > 0)
                                                                            && <td className={(sameDayData[key_name][2] === '-') ? 'text-center' : 'text-right'}>{ numberFormater(sameDayData[key_name][2]) }</td>}


                                          </tr>
                                        ))
                                        }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="row" style={{ position: 'relative' }}>
                      <div className="col-xs-3 col-md-3 no-padding layout_sale_left" />
                      <div className="col-xs-9 col-md-9 no-padding layout_sale_right" id="layout_sale_02_buttom_right_set_scroll" onScroll={eventScroll}>
                        <div id="layout_sale_02_buttom_right_content_set_scroll_daily" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesReportTable;
