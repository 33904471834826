/** @jsx jsx */
import React, { useEffect } from "react";
import FormContents from "components/atoms/Form/FormContents";
import TextForm from "components/molecules/TextForm";
import FormField from "components/atoms/Form/FormField";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import PrimaryButton from "components/atoms/Button";
import { useHistory } from "react-router-dom";
import ConfirmModal from "components/organismos/ConfirmModal";
import ShiftPatternDetailsV2 from "components/organismos/master/labor/shiftPattern/ShiftPatternDetails/ShiftPatternDetailsV2";
import useShiftPatternAddForm from "./hooks";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";

const styles = {
  formContainer: css(
    {
      width: "800px",
      margin: "auto",
      paddingBottom: "60px",
    },
    css`
      button {
        width: auto;
      }
    `
  ),
};

const ShiftPatternAddPage: React.FC<{
  shiftPatternId: string;
  typeButton: string;
}> = ({ shiftPatternId, typeButton }) => {
  const {
    formik,
    orgTreesOptions,
    employmentIdSelect,
    confirmModalOpen,
    closeConfirmModal,
    shiftPatternDetailsList,
    setShiftPatternDetailsList,
    listTimes,
    attendBusinessOptions,
    isLoading,
    remote,
    detailRole,
  } = useShiftPatternAddForm(shiftPatternId, typeButton);

  // history
  const history = useHistory();

  /**
   *  useEffect
   *
   */
  useEffect(() => {
    //
  }, [employmentIdSelect, formik]);

  /**
   *  handle back
   *
   */
  const handleBack = async () => {
    history.push("/masterShiftPattern");
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle
          bold={true}
          title={shiftPatternId ? "シフトパターン編集" : "シフトパターン作成"}
        />
        <form onSubmit={formik.handleSubmit} css={styles.formContainer}>
          <FormField customStyle={css({ marginBottom: "10px" })}>
            <SelectForm
              label="適用組織"
              name="orgCode"
              value={String(formik.values.orgCode)}
              setValue={(val: string) => formik.setFieldValue("orgCode", val)}
              options={orgTreesOptions}
              required={true}
              width="100%"
              widthLabel="100%"
              isDisplayBlock={true}
            />
          </FormField>

          <FormField customStyle={css({ marginBottom: "10px" })}>
            <TextForm
              name="shiftPatternCode"
              label="シフトパターンコード"
              value={formik.values.shiftPatternCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.shiftPatternCode}
            />
          </FormField>
          <FormField customStyle={css({ marginBottom: "10px" })}>
            <TextForm
              name="shiftPatternName"
              label="シフトパターン名"
              value={formik.values.shiftPatternName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.shiftPatternName}
            />
          </FormField>
          <FormField>
            <ShiftPatternDetailsV2
              shiftPatternDetailsList={shiftPatternDetailsList}
              setShiftPatternDetailsList={setShiftPatternDetailsList}
              attendBusinessOptions={attendBusinessOptions}
              listTimes={listTimes}
              isCreate={Boolean(shiftPatternId || typeButton === "copy")}
              remote={remote}
            />
          </FormField>

          <FormSubmitArea
            customStyle={css({ textAlign: "center", marginTop: "20px" })}
          >
            {detailRole.editable === 1 && (
              <div>
                <PrimaryButton
                  ghost={false}
                  text={
                    !shiftPatternId || typeButton === "copy"
                      ? "登録する"
                      : "更新"
                  }
                  onClick={formik.handleSubmit}
                  minWidth="160px"
                  disabled={
                    formik.values.orgCode !== "all" ||
                    detailRole.accessRange === 3
                      ? false
                      : true
                  }
                />
              </div>
            )}
            <PrimaryButton
              ghost={true}
              text="キャンセル"
              onClick={() => handleBack()}
              minWidth="160px"
            />
          </FormSubmitArea>

          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={formik.handleSubmit}
            title={`${
              !shiftPatternId || typeButton === "copy"
                ? "シフトパターンの登録"
                : "シフトパターンマスタの更新"
            }`}
            content={`${
              !shiftPatternId || typeButton === "copy"
                ? "シフトパターンの登録をします。よろしいですか？"
                : "シフトパターンマスタを更新します。よろしいですか？"
            }`}
            submitText={
              !shiftPatternId || typeButton === "copy" ? "登録する" : "更新する"
            }
          />
        </form>
      </FormContents>
    </BlockUI>
  );
};

export default ShiftPatternAddPage;
