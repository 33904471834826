import React, { useState, createContext, Dispatch, SetStateAction, useContext } from "react";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from "components/atoms/TabControl";
import StaffInfoForm from "components/organismos/master/general/staff/StaffInfoForm";
import AffiliationInfoForm from "components/organismos/master/general/staff/AffiliationInfoForm";
// import StaffDomain from 'domain/staff';
import EmploymentInfoForm from "components/organismos/master/general/staff/EmploymentInfoForm";
import WorkPatternInfoForm from "components/organismos/master/general/staff/WorkPatternInfoForm";
import useStaffEdit from "../StaffEditPage/hooks";
import { TablItem } from "components/atoms/TabBar";
import BlockUI from "components/molecules/BlockUi";

import { LoadingContextAdd, useStaffAddPageContext, useInitialStaffAddPageState } from "./hooks";
import { SelectType } from "./hooks";
import LongTermCareAndNurse from "components/organismos/master/general/staff/LongTermCareAndNurse";
import FormTitle from "components/atoms/Form/FormTitle";
import SalaryInfoForm from "components/organismos/master/general/staff/SalaryInfoForm";

const StaffAddPage: React.FC = () => {
  const contextInitialState = useInitialStaffAddPageState();
  const {
    selectType,
    setSelectType,
    tabItems,
    setTabItems,
    isEditMode,
    setIsEditMode,
    isLoading,
    setIsLoading,
    detailRole,
  } = contextInitialState;

  const {
    staffDomain,
    setStaffDomain,
    trialPeriodOptions,
    businessOptions,
    executiveOptions,
    employmentOptions,
    setOrgTargetDate,
    setEmpTargetDate,
    breakBusiness,
    roleOptions,
    holidayOptions,
    holidayTypeOptions,
    modalOrgTreeOptions,
    termOptions,
  } = useStaffEdit();

  return (
    <SidebarTemplate pageTitle="スタッフ 登録">
      <LoadingContextAdd.Provider value={contextInitialState}>
        <BlockUI blocking={isLoading}>
          <FormContents>
            <FormTitle bold={true} title="スタッフ作成" />
            <TabControl
              items={tabItems}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={true}
            />
            {selectType === SelectType.staff && (
              <>
                <StaffInfoForm
                  initialStaffDomain={staffDomain}
                  setInitialStaffDomain={setStaffDomain}
                  isEditMode={isEditMode}
                  tabItems={tabItems}
                  setTabItems={setTabItems}
                  setSelectType={setSelectType}
                  setOrgTargetDate={setOrgTargetDate}
                  setEmpTargetDate={setEmpTargetDate}
                  trialPeriodOptions={trialPeriodOptions}
                  businessOptions={businessOptions}
                  roleOptions={roleOptions}
                  setIsLoading={setIsLoading}
                />
              </>
            )}
            {selectType === SelectType.affiliation && (
              <AffiliationInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={isEditMode}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                executiveOptions={executiveOptions}
                setIsLoading={setIsLoading}
              />
            )}
            {selectType === SelectType.employment && (
              <EmploymentInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={isEditMode}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                employmentOptions={employmentOptions}
                setIsLoading={setIsLoading}
              />
            )}
            {selectType === SelectType.laborCost && (
              <SalaryInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={isEditMode}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                termOptions={termOptions}
                modalOrgTreeOptions={modalOrgTreeOptions}
              />
            )}
            {selectType === SelectType.workPattern && (
              <WorkPatternInfoForm
                setInitialStaffDomain={setStaffDomain}
                initialStaffDomain={staffDomain}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                breakBusiness={breakBusiness}
                setIsLoading={setIsLoading}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
              />
            )}
            {selectType === SelectType.longTermCareandNurse && (
              <LongTermCareAndNurse
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={false}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setOrgTargetDate={setOrgTargetDate}
                executiveOptions={executiveOptions}
                setIsLoading={setIsLoading}
                holidayOptions={holidayOptions}
                holidayTypeOptions={holidayTypeOptions}
              />
            )}
          </FormContents>
        </BlockUI>
      </LoadingContextAdd.Provider>
    </SidebarTemplate>
  );
};

export default StaffAddPage;
