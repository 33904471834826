/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

interface StatusTemperatureProps {
  temperature: number;
  isMask: boolean;
  temperatureNormal: number;
}

const TEMPERATURE_TYPE = {
  NORMAL: "体温は正常です。",
  ABNORMAL: "体温は異常です。"
}

const MASK = {
  YES: "あり",
  NO: "なし"
}

const styles = {
  temperature: (temperature: number, temperatureNormal: number) => css({
    color: temperature > temperatureNormal ? '#CC4D4D' : '#4DCC62',
    display: 'inline-block',
  }),
}

const StatusTemperature: React.FC<StatusTemperatureProps> = ({ isMask, temperature, temperatureNormal }) => {
  return <React.Fragment>
    {
      temperature ? (
        <React.Fragment>
          <span css={styles.temperature(temperature, temperatureNormal)}>
            {temperature < temperatureNormal ? TEMPERATURE_TYPE.NORMAL : TEMPERATURE_TYPE.ABNORMAL}(マスク：{isMask === true ? MASK.YES : MASK.NO})
          </span>
        </React.Fragment>
      ) : '-'
    }
  </React.Fragment>
}

export default StatusTemperature;
