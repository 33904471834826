/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { grayScale, utilityColor } from "components/styles";
import CircleIconButton from "components/molecules/CircleIconButton";
import { isSafari } from "react-device-detect";
import { PaginationProps } from "./Pagination";
import GlobalFilter, { GlobalFilterProps } from "./GlobalFilter";
import { notMinScreen } from "utility/mediaQueries";

const styles = {
  controllbar: css({
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    padding: "8px 0",
    [notMinScreen("md")]: {
      width: "auto",
      padding: "8px",
    },
  }),
  controllbarRight: css({
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    padding: "8px 0",
    justifyContent: "space-between",
    marginRight: "20px",
    [notMinScreen("md")]: {
      width: "100%",
      padding: "8px",
    },
  }),
  actionButton: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [".input-search"]: {
      width: isSafari ? "230px" : "210px",
    },
    [".input-search input"]: {
      height: "32px",
      padding: 0,
      width: "100%",
    },
    ["button"]: {
      marginLeft: isSafari ? "0px" : "-15px",
    },
    [notMinScreen("md")]: {
      [".input-search"]: {
        width: "100%",
      },
      ["button"]: {
        marginLeft: "0px",
      },
    },
  }),
  pagenation: css({
    marginLeft: "auto",
  }),
};

interface ControllbarProps {
  pagination: PaginationProps;
  filter: GlobalFilterProps;
  iconSize?: string;
  isGlobalFilter: boolean;
  tableId: number;
  controllerStyle?: any;
  globalSearchStyle?: any;
  searchRight?: boolean;
}

const Controllbar: React.FC<ControllbarProps> = ({
  filter,
  isGlobalFilter,
  tableId,
  globalSearchStyle,
  controllerStyle,
  iconSize,
  searchRight = false,
}) => (
  <div css={[searchRight === true ? styles.controllbarRight : styles.controllbar, controllerStyle]}>
    {isGlobalFilter && (
      <div
        css={css(
          {
            minWidth: "270px",
            border: "1px solid #dcdcdc",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            marginLeft: "35px",
            [notMinScreen("md")]: {
              marginLeft: "0px",
              minWidth: "100%",
            },
          },
          globalSearchStyle,
        )}
      >
        <div css={styles.actionButton}>
          <CircleIconButton
            iconSize={iconSize}
            iconType="search"
            iconColor={grayScale.gray100}
            borderColor={utilityColor.transparent}
            onClick={() => {}}
          />
          <GlobalFilter state={filter.state} setGlobalFilter={filter.setGlobalFilter} tableId={tableId} />
        </div>
      </div>
    )}
  </div>
);

export default Controllbar;
