/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useEffect } from "react";

import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormField from "components/atoms/Form/FormField";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import FormLabel from "components/atoms/Form/FormLabel";
import HourInput from "components/atoms/Form/HourInput";
import BodyText from "components/atoms/BodyText";
import productColor, { grayScale } from "components/styles";
import FormContents from "components/atoms/Form/FormContents";
import Checkbox from "components/molecules/Checkbox";

const styles = {
  kaoiroContainer: css({
    borderTop: `1px solid ${grayScale.gray50}`,
    paddingTop: "45px",
    marginTop: "45px",
  }),
  kaoiroSubForm: css({
    margin: "20px 5px",
  }),
  warningText: css({
    marginLeft: "20px",
  }),
};

const EmploymentGPSStampSettings: React.FC<{
  formik: any;
}> = ({ formik }) => {
  const recessTimeAlertItems = [];
  const recessTimeAlert = 3;
  for (let i = 0; i < recessTimeAlert; ) {
    recessTimeAlertItems.push(i);
    i += 1;
  }

  return (
    <React.Fragment>
      <FormTitle title="ログイン後の打刻方法設定" bold={true} customStyle={css({ marginLeft: "0" })} />
      <FlexBox>
        <FlexBoxItem basis="50%">
          <VerticalLabelSelectForm
            label="GPS打刻設定"
            name="useGpsStamp"
            options={[
              {
                label: "使用しない",
                value: "0",
              },
              {
                label: "使用する",
                value: "1",
              },
            ]}
            value={String(formik.values.useGpsStamp)}
            setValue={(val) => formik.setFieldValue("useGpsStamp", val)}
            isSearchable={false}
          />
          <VerticalLabelSelectForm
            label="ID打刻設定"
            name="useInsideLoginBarcodeStamp"
            options={[
              {
                label: "使用しない",
                value: "0",
              },
              {
                label: "使用する",
                value: "1",
              },
            ]}
            value={String(formik.values.useInsideLoginBarcodeStamp)}
            setValue={(val) => formik.setFieldValue("useInsideLoginBarcodeStamp", val)}
            isSearchable={false}
          />

          <VerticalLabelSelectForm
            label="顔認証打刻設定"
            name="useInsideLoginFaceRecorderStamp"
            options={[
              {
                label: "使用しない",
                value: "0",
              },
              {
                label: "使用する",
                value: "1",
              },
            ]}
            value={String(formik.values.useInsideLoginFaceRecorderStamp)}
            setValue={(val) => formik.setFieldValue("useInsideLoginFaceRecorderStamp", val)}
            isSearchable={false}
          />
        </FlexBoxItem>
      </FlexBox>
      <FlexBox>
        <FlexBoxItem basis="50%">
          <FormTitle title="オフィス・テレワークの区別" bold={true} customStyle={css({ marginLeft: "0" })} />
          <RadioSelectForm
            label="出社打刻をオフィスとテレワークで区別して打刻する"
            name="isRemote"
            items={[
              {
                label: "しない",
                value: "false",
              },
              {
                label: "する",
                value: "true",
              },
            ]}
            value={String(formik.values.isRemote)}
            setValue={(e) => {
              formik.setFieldValue("isRemote", e.target.value === "true");
            }}
          />
        </FlexBoxItem>
      </FlexBox>
      <div css={styles.kaoiroContainer}>
        <FlexBox>
          <RadioSelectForm
            label="Kaoiro打刻機能"
            customStyleLabel={css({ fontWeight: "bold" })}
            subLabel=""
            items={[
              {
                label: "使用しない",
                value: "0",
              },
              {
                label: "使用する",
                value: "1",
              },
            ]}
            name="useKaoiruStamping"
            value={String(formik.values.useKaoiruStamping)}
            setValue={(e) => {
              formik.setFieldValue("useKaoiruStamping", Number(e.target.value));
              if (e.target.value === "1" && formik.values.kaoiruStampResetUnit === 0) {
                formik.setFieldValue("kaoiruStampResetUnit", 1);
              }
            }}
          />
        </FlexBox>
        {Boolean(formik.values.useKaoiruStamping) && (
          <FormContents isSubForm={true} customStyle={styles.kaoiroSubForm}>
            <FormField>
              <VerticalLabelFormLayout
                label={<FormLabel label="強制出社打刻判定" />}
                input={
                  <FlexBox>
                    <HourInput
                      name="kaoiruStampResetUnit"
                      value={formik.values.kaoiruStampResetUnit}
                      label="時間"
                      onChange={(e) => {
                        formik.setFieldValue("kaoiruStampResetUnit", Number(e.target.value));
                      }}
                      min={1}
                      max={48}
                    />
                    <BodyText size="sm" color={productColor.deleteAction} customStyle={styles.warningText}>
                      この時間以上を経過して打刻すると出勤打刻となります。
                    </BodyText>
                  </FlexBox>
                }
              />
            </FormField>
            <FormField>
              <VerticalLabelFormLayout
                label={<FormLabel label="休憩打刻判定" />}
                input={
                  <FlexBox>
                    <HourInput
                      name="kaoiruStampBreakUnit"
                      value={formik.values.kaoiruStampBreakUnit}
                      label="分以内の打刻は休憩時間と見なす"
                      onChange={(e) => {
                        formik.setFieldValue("kaoiruStampBreakUnit", Number(e.target.value));
                      }}
                      isFullW={true}
                      wrapInput={css({
                        width: "300px",
                      })}
                      min={0}
                      max={999}
                    />
                  </FlexBox>
                }
              />
            </FormField>
            <FormField>
              <VerticalLabelFormLayout
                label={<FormLabel label="発熱判定" />}
                input={
                  <FlexBox>
                    <HourInput
                      name="temperature"
                      widthInput="60px"
                      value={formik.values.temperature || 37.5}
                      label="°C 以上"
                      onChange={(e) => {
                        formik.setFieldValue("temperature", e.target.value);
                      }}
                      min={0}
                      max={99}
                      step={0.1}
                    />
                  </FlexBox>
                }
              />
            </FormField>
          </FormContents>
        )}
      </div>
    </React.Fragment>
  );
};

export default EmploymentGPSStampSettings;
