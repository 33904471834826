import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendAgreement36Application from 'components/organismos/AttendAgreement36Application';

const AttendAgreement36ApplicationPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="GPS打刻"
  >
    <AttendAgreement36Application />
  </SidebarTemplate>
);

export default AttendAgreement36ApplicationPage;
