/** @jsx jsx */
import React, { useState, useRef, useEffect } from "react";
import { jsx } from "@emotion/core";
import useToastNotification from "hooks/useToastNotification";
import { useFaceRecorderEmptySpecifyCode } from "./hooks";
import FaceRecorderUI from "./FaceRecorderUI";

const FaceRecorderEmptySpecifyCode: React.FC<{
  dataTimes: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHistoryData: any;
}> = ({
  dataTimes,
  setIsLoading,
  fetchHistoryData,
}) => {
  const {
    handleSubmitFace,
    isConfirm,
    photoFullpath,
    handleRecorder,
    staffName,
    lastStampType,
    handleMistake,
    isSelectBaby,
    stampStaffList,
    handleSelectBaby,
    stampOrgName
  } = useFaceRecorderEmptySpecifyCode(
    dataTimes.resultCurrentTime,
    setIsLoading,
    fetchHistoryData
  );

  const [isVerticalIpad, setIsVerticalIpad] = useState(true);

  const onOrientation = (orientation: number | string) => {
    if (Number(orientation) === 180 || Number(orientation) === 0) {
      setIsVerticalIpad(true);
    } else {
      setIsVerticalIpad(false);
    }
  };
  useEffect(() => {
    onOrientation(window.orientation);
    if (window) {
      window.addEventListener(
        "orientationchange",
        function() {
          // Announce the new orientation number
          onOrientation(window.orientation);
        },
        false
      );
    }
  }, []);

  //canvas
  const canvasRef1 = useRef<HTMLCanvasElement>(null);
  const webcamRef = useRef(null);

  const { errorNotification } = useToastNotification();

  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const onUserMediaError = (err: any) => {
    if (err) {
      errorNotification("カメラへのアクセスに失敗しました。");
      setIsDisabledButton(true);
    }
  };

  const [count, setCount] = useState(3);
  const [showMessage, setShowMessage] = useState(false);
  const countDown = () => {
    setIsDisabledButton(true);
    setShowMessage(true);
    const funcInterval = setInterval(() => {
      setCount((prevCount) => {
        return prevCount - 1;
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(funcInterval);
      setCount(3);
      setShowMessage(false);
    }, 4000);
  };

  const countTimeCap = (webcamRef: any) => {
    setTimeout(() => {
      capture(webcamRef);
    }, 3000);
  };

  const capture = (webcamRef: any) => {
    setIsLoading(true);
    setIsDisabledButton(true);

    //  img 64
    const base64string = webcamRef.current?.getScreenshot();
    if (!canvasRef1.current) {
      setIsDisabledButton(false);
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef1.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      setIsDisabledButton(false);
      return;
    }
    const img = new Image();
    img.crossOrigin = "";

    // eslint-disable-next-line no-loop-func
    img.onload = () => {
      const w = img.width;
      const h = img.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = w;
      canvas.height = h;
      ctx.transform(-1, 0, 0, 1, w, 0);
      ctx.drawImage(img, 0, 0, w, h);
      canvas.toBlob((blob: any) => {
        handleSubmitFace(blob);
        setIsDisabledButton(false);
      }, "image/jpeg");
    };
    img.onerror = () => {
      setIsDisabledButton(false);
    };
    img.src = base64string;
  };
  return (
    <FaceRecorderUI
      stampOrgName={stampOrgName}
      dataTimes={dataTimes}
      staffName={staffName}
      isConfirm={isConfirm}
      webcamRef={webcamRef}
      isVerticalIpad={isVerticalIpad}
      onUserMediaError={onUserMediaError}
      photoFullpath={photoFullpath}
      count={count}
      showMessage={showMessage}
      countTimeCap={countTimeCap}
      canvasRef1={canvasRef1}
      isDisabledButton={isDisabledButton}
      disabledRecorder={false}
      countDown={countDown}
      lastStampType={lastStampType}
      handleRecorder={handleRecorder}
      handleMistake={handleMistake}
      isSelectBaby={isSelectBaby}
      stampStaffList={stampStaffList}
      handleSelectBaby={handleSelectBaby}
    />
  );
};

export default FaceRecorderEmptySpecifyCode;
