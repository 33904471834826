import { css } from "@emotion/core";
import { grayScale } from "components/styles";
import { notMinScreen } from "utility/mediaQueries";

export const styles = {
  formRecorder: css({
    textAlign: "center",
  }),
  dateForm: css({
    width: "420px",
    justifyContent: "center",
    margin: "auto",
    [notMinScreen("lg")]: {
      width: "100%",
    },
  }),
  formContainer: css({
    marginTop: "15px",
    borderRadius: "3px",
    paddingBottom: "50px",
    [notMinScreen("lg")]: {
      paddingBottom: "20px",
    },
  }),
  loadingText: css({
    fontSize: "80px",
    textAlign: "center",
    marginBottom: "70px",
    [notMinScreen("md")]: {
      fontSize: "26px",
    },
  }),
  buttonContainer: css({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px auto",
    padding: "0px 8px",
    width: "700px",
    ["button"]: {
      borderRadius: "3px",
      border: 0,
    },
    ["button:hover"]: {
      opacity: 0.9,
    },
    [notMinScreen("lg")]: {
      width: "500px",
    },
    [notMinScreen("md")]: {
      width: "auto",
      rowGap: "16px",
    },
  }),
  imageButton: css({
    width: "100%",
    textAlign: "center",
    height: "80px",
  }),
  time: css({
    alignItems: "baseline",
    justifyContent: "center",
    margin: "auto",
    width: "350px",
    color: "red",
    lineHeight: "100px",
    [notMinScreen("lg")]: {
      width: "auto",
      lineHeight: "80px",
    },
    ["span:first-of-type"]: {
      fontSize: "100px",
      marginRight: "10px",
      fontWeight: "bold",
      color: grayScale.gray100,
      [notMinScreen("lg")]: {
        fontSize: "64px",
      },
    },
    ["span:last-of-type"]: {
      fontSize: "32px",
      color: grayScale.gray100,
    },
  }),
};
