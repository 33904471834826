/** @jsx jsx */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { css, jsx } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import TabControl from "components/atoms/TabControl";
import { grayScale, textFontSize } from "components/styles";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import { IchangeLog } from "./interface";
import AttendAgreement36Modal from "./Template/AttendAgreement36Modal";
import AttendHistoryApprovalModal from "./Template/AttendHistoryApprovalModal";
import AttendHolidayModal from "./Template/AttendHolidayModal";
import AttendHolidayworkModal from "./Template/AttendHolidayworkModal";
import AttendOvertimeModal from "./Template/AttendOvertimeModal";
import AttendStampModal from "./Template/AttendStampModal";
import AttendStockPaidModal from "./Template/AttendStockPaidModal";
import AttendTransferModal from "./Template/AttendTransferModal";
import AttendWorkPlanModal from "./Template/AttendWorkPlanModal";
import AttendAllowanceModal from "components/molecules/ChangeLogModal/Template/AttendAllowanceModal";

const ChangeLogModal: React.FC<{
  changeLogData: IchangeLog[];
  isStamp?: boolean;
  isWorkPlan?: boolean;
  request?: string;
}> = ({ changeLogData, isStamp = false, isWorkPlan = false, request = "" }) => {
  const [selectChangelog, setSelectChangelog] = useState<string>("0");

  return (
    <React.Fragment>
      <TabControl
        isTabModal={true}
        isFixed={false}
        items={changeLogData.map((log: IchangeLog, index: number) => ({
          value: String(index),
          display: `ver${log.version}`,
        }))}
        setValue={setSelectChangelog as (arg: string) => void}
        currentValue={selectChangelog}
        styleNotActive={css({ borderRadius: 0, border: "none" })}
        styleActive={css({
          borderRadius: 0,
          border: "none",
          borderBottom: "1px solid #007BC3",
        })}
      />
      <div>
        {changeLogData && changeLogData.length > 0 && (
          <React.Fragment>
            <FormPadding>
              <div>
                <Row>
                  <Col md={3} xs={4} style={{ color: `${grayScale.gray50}` }}>
                    <span style={{ fontSize: `${textFontSize.re}` }}>申請種別:</span>
                  </Col>
                  <Col md={9} xs={8} style={{ color: `${grayScale.gray100}` }}>
                    <span style={{ fontSize: `${textFontSize.re}` }}>
                      {`${changeLogData[Number(selectChangelog)].historyTypeName}`}
                    </span>
                  </Col>
                </Row>
              </div>
            </FormPadding>

            {(changeLogData[Number(selectChangelog)]?.achievementId || isStamp !== true) && (
              <React.Fragment>
                <FormPadding>
                  <div>
                    <Row>
                      <Col md={3} xs={4} style={{ color: `${grayScale.gray50}` }}>
                        <span style={{ fontSize: `${textFontSize.re}` }}>申請者:</span>
                      </Col>
                      <Col md={9} xs={8} style={{ color: `${grayScale.gray100}` }}>
                        <span style={{ fontSize: `${textFontSize.re}` }}>
                          {changeLogData[Number(selectChangelog)]?.applicationStaffCode
                            ? `${changeLogData[Number(selectChangelog)]?.applicationStaffCode} 
                                   ${changeLogData[Number(selectChangelog)]?.applicationStaffName}`
                            : null}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </FormPadding>
                <FormPadding>
                  <div>
                    <Row>
                      <Col md={3} xs={4} style={{ color: `${grayScale.gray50}` }}>
                        <span style={{ fontSize: `${textFontSize.re}` }}>申請日時:</span>
                      </Col>
                      <Col md={9} xs={8} style={{ color: `${grayScale.gray100}` }}>
                        <span style={{ fontSize: `${textFontSize.re}` }}>
                          {changeLogData[Number(selectChangelog)]?.applicationDate
                            ? `${moment(changeLogData[Number(selectChangelog)]?.applicationDate).format(
                                "YYYY/MM/DD HH:mm:ss",
                              )}`
                            : null}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </FormPadding>
              </React.Fragment>
            )}

            <FormPadding>
              <div>
                <Row>
                  <Col md={3} xs={4} style={{ color: `${grayScale.gray50}` }}>
                    <span style={{ fontSize: `${textFontSize.re}` }}>出勤組織:</span>
                  </Col>
                  <Col md={9} xs={8} style={{ color: `${grayScale.gray100}` }}>
                    <span style={{ fontSize: `${textFontSize.re}` }}>
                      {`
                          ${changeLogData[Number(selectChangelog)]?.orgCode} 
                          ${changeLogData[Number(selectChangelog)]?.orgName}
                        `}
                    </span>
                  </Col>
                </Row>
              </div>
            </FormPadding>

            {//WorkPlan
            isWorkPlan ? (
              <AttendWorkPlanModal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}

            {//Stamp
            isStamp ? (
              <AttendStampModal changeLogData={changeLogData} selectChangelog={selectChangelog} isStamp={isStamp} />
            ) : null}

            {//OverTime
            request === "残業申請" ? (
              <AttendOvertimeModal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}

            {console.log(changeLogData[Number(selectChangelog)])}

            {//Allowance
            request === "手当申請" ? (
              <AttendAllowanceModal
                details={changeLogData[Number(selectChangelog)].detailList.map((item: any) => ({
                  allowanceApplicationDetailId: item.allowanceApplicationDetailId,
                  modifierApplicationId: item.modifierApplicationId,
                  allowanceId: item.allowanceId,
                  allowanceName: item.allowanceName,
                  allowanceValue: item.allowanceValue,
                  dispUnit: item.dispUnit,
                  comment: item.comment,
                  dispMessage: item.dispMessage,
                }))}
              />
            ) : null}

            {//Holidaywork
            request === "休日出勤申請" ? (
              <AttendHolidayworkModal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}

            {//Holiday
            request === "休暇申請" ? (
              <AttendHolidayModal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}

            {//Transfer
            request === "振替申請" ? (
              <AttendTransferModal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}

            {//Agreement36
            request === "45時間超過申請" ? (
              <AttendAgreement36Modal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}
            {//stock paid holiday
            request === "積立有給申請" ? (
              <AttendStockPaidModal changeLogData={changeLogData} selectChangelog={selectChangelog} />
            ) : null}

            {request !== "手当申請" &&
              changeLogData &&
              (changeLogData[Number(selectChangelog)]?.achievementId || isStamp !== true) && (
                <FormPadding>
                  <div>
                    <Row>
                      <Col md={3} xs={4} style={{ color: `${grayScale.gray50}` }}>
                        <span style={{ fontSize: `${textFontSize.re}` }}>修正理由:</span>
                      </Col>
                      <Col md={9} xs={8} style={{ color: `${grayScale.gray100}` }}>
                        <span style={{ fontSize: `${textFontSize.re}` }}>
                          {changeLogData[Number(selectChangelog)].applicationReason
                            ? `${changeLogData[Number(selectChangelog)].applicationReason}`
                            : null}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </FormPadding>
              )}
            <FormPadding customStyle={css({ paddingBottom: "5px" })}>
              <Row>
                <Col md={3} xs={4} style={{ color: `${grayScale.gray50}` }}>
                  <span style={{ fontSize: `${textFontSize.re}` }}>承認状況:</span>
                </Col>
              </Row>
            </FormPadding>
            <AttendHistoryApprovalModal
              changeLogData={changeLogData}
              selectChangelog={selectChangelog}
              isStamp={isStamp}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ChangeLogModal;
