import ApiClient from './ApiClient';

export const getPaidHolidayAlert = async (companyCode: string, orgCode: string, staffCode: string, site: string): Promise<any> => {
  const params = {
    orgCode,
    staffCode,
    site,
  };
  const response = await ApiClient.get(`/v1/paidHolidayAlert/${companyCode}`, params);
  return response.data;
};


export default getPaidHolidayAlert;
