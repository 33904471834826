import React from "react";
import { Row, Col } from "react-grid-system";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { css } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import moment from "moment";
import EmploymentFilter from "../../HopeShiftForm/EmploymentFilter";
import OrganizationFilter from "../../HopeShiftForm/OrganizationFilter";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import { setInitClosingDate } from "../../setInitClosingDate";

const SelectCalendar: React.FC<{
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPeriod: any;
  targetDateFrom: any;
  setTargetDateFrom: any;
  targetDateTo: any;
  setTargetDateTo: any;
  targetMonth: any;
  setTargetMonth: any;
}> = ({
  value,
  onChange,
  setPeriod,
  targetDateFrom,
  setTargetDateFrom,
  targetDateTo,
  setTargetDateTo,
  targetMonth,
  setTargetMonth,
}) => {
  const closingDate = useSelector((state: Store) => state.loginUser.loginUser.closingDate);

  function checkMonth(targetMonth: any) {
    const { firstDayStr, lastDayStr } = setInitClosingDate(closingDate, targetMonth);
    setTargetDateFrom(new Date(firstDayStr));
    setTargetDateTo(new Date(lastDayStr));
    sessionStorage.setItem("applicationTargetDayFrom", moment(firstDayStr).format("YYYY-MM-DD"));
    sessionStorage.setItem("applicationTargetDayTo", moment(lastDayStr).format("YYYY-MM-DD"));
  }

  function handleOnChange(e: any) {
    e.preventDefault();
    const value = e.target.value;
    onChange(value);
    setPeriod(value);
    sessionStorage.setItem("applicationListPeriod", value);
    if (value === "monthly") {
      checkMonth(targetMonth);
    }
  }

  function hanldeChangeMonth(date: any) {
    if (new Date(date).getTime() !== new Date(targetMonth).getTime()) {
      setTargetMonth(date);
      sessionStorage.setItem("applicationTargetMonth", date);
      checkMonth(date);
    }
  }

  return (
    <div>
      <FormPadding customStyle={css({ marginTop: "35px" })}>
        <Row>
          <Col md={12}>
            <FlexBox>
              <Col lg={1.5} md={2}>
                <div style={{ marginTop: "-20px" }}>
                  <RadioSelectForm
                    label=""
                    name="monthly"
                    items={[
                      {
                        label: "月指定",
                        value: "monthly",
                      },
                    ]}
                    value={value}
                    setValue={handleOnChange}
                  />
                </div>
              </Col>
              <Col lg={4} md={4}>
                <FlexBox customStyle={css({ marginRight: "20px" })}>
                  <FlexBoxItem>
                    <CalendarMonth
                      date={targetMonth}
                      setDate={hanldeChangeMonth}
                      readOnly={value !== "monthly"}
                      openActionChevron={true}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </Col>
            </FlexBox>
            <FlexBox customStyle={css({ margin: "10px 0" })}>
              <Col lg={1.5} md={2}>
                <div style={{ marginTop: "-20px" }}>
                  <RadioSelectForm
                    label=""
                    name="daily"
                    items={[
                      {
                        label: "期間",
                        value: "daily",
                      },
                    ]}
                    value={value}
                    setValue={handleOnChange}
                  />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <FlexBox>
                  <FlexBoxItem>
                    <CalendarRangeV2
                      startDate={targetDateFrom}
                      onChangeStart={(date: Date) => {
                        setTargetDateFrom(date);
                        sessionStorage.setItem("applicationTargetDayFrom", moment(date).format("YYYY-MM-DD"));
                      }}
                      endDate={targetDateTo}
                      onChangeEnd={(date) => {
                        if (date) {
                          setTargetDateTo(date);
                          sessionStorage.setItem("applicationTargetDayTo", moment(date).format("YYYY-MM-DD"));
                        }
                      }}
                      readOnly={value !== "daily"}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </Col>
            </FlexBox>
          </Col>
        </Row>
        <OrganizationFilter orgCode="" setOrgCode={(arg: string) => {}} />
        <EmploymentFilter
          employmentId={sessionStorage.getItem("loginUser.employmentId") || ""}
          setEmploymentId={(arg: string) => {}}
        />
      </FormPadding>
    </div>
  );
};

export default SelectCalendar;
