import ApiClient from './ApiClient';

export const getListPaymentMediaOrganization = async (orgCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = { orgCode };

  const response = await ApiClient.get(`/v1/sales/master/paymentMediaOrganization/${companyCode}`, params);
  return response.data;
};

export const deletePaymentMediaOrganization = async (isConfirmed: number,
  paymentMediaOrganization: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isConfirmed,
  };

  const response = await ApiClient.post(`/v1/sales/master/paymentMediaOrganization/delete/${companyCode}`, params, paymentMediaOrganization);
  return response.data;
};

export const createOrUpdatePaymentMediaOrganization = async (isCreate: boolean,
  paymentMediaOrganization: any) : Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    isCreate: isCreate ? 1 : 0,
  };
  const response = await ApiClient.post(`/v1/sales/master/paymentMediaOrganization/${companyCode}`, params, paymentMediaOrganization);
  return response.data;
};

export const getPaymentMediaOrganizationList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/paymentMediaRelation/${companyCode}`, {});
  return response.data;
};


export const getPaymentMediaCategoryList = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/paymentMediaCategory/${companyCode}`, {});
  return response.data;
};


export default getListPaymentMediaOrganization;
