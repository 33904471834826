import { useState, useEffect, useCallback, useRef } from 'react';
import useToastNotification from 'hooks/useToastNotification';
import { getInfomationOrgLocalStorage, getHistoryRecorder, getTimeSettingNoAuth } from 'api/timeRecorder';
import { getCurrentTime } from 'api/attendStamp';
import StampHistoryListRecorderDomain from 'domain/timeRecorder/stampHistoryListRecorderDomain';
import moment from 'moment';
import { CurrentTimeDomain } from 'domain/master/attend/currentTime';
import { useOrgCode, useOrgName } from 'hooks/useSessionLoginUser';
import { TimeSetting } from 'domain/master/labor/timeSetting';
import { OptionType } from 'components/atoms/Select';

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = 'win-board-localstorage-shop-info';
const { errorNotification } = useToastNotification();

export type SelectType = 'id' | 'face' | 'gps' | 'icCard';
export const SelectType: { [key in SelectType]: SelectType } = {
  id: 'id',
  face: 'face',
  gps: 'gps',
  icCard: 'icCard',
};

export const useRecorder = (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  const storageData = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  let { specifyCode, companyCode } = storageData ? JSON.parse(storageData) : '';
  const [stampHistoryList, setStampHistoryList] = useState<Array<StampHistoryListRecorderDomain>>([
    StampHistoryListRecorderDomain.generateInitial(),
  ]);

  // disabled recorder
  const [disabledRecorder, setDisabledRecorder] = useState<boolean>(false);

  // Time
  const resultCurrentTime: CurrentTimeDomain = CurrentTimeDomain.generateInitial();
  const [currentDay, setCurrentDay] = useState('');
  const [timeNow, setTimeNow] = useState('');
  const [second, setSecond] = useState('');

  const [currentTime, setCurrentTime] = useState(CurrentTimeDomain.generateInitial());
  const startTime = useRef(Date.now());

  // Form
  const [stampOrgCode, setStampOrgCode] = useState(useOrgCode());
  const [stampOrgName, setStampOrgName] = useState(useOrgName());
  const [loginOrgCode, setLoginOrgCode] = useState(useOrgCode());
  const [loginOrgName, setLoginOrgName] = useState(useOrgName());

  // mode
  const [useFaceStamp, setUseFaceStamp] = useState<number | null>(null);
  const [useBarcodeStamp, setUseBarcodeStamp] = useState<number | null>(null);
  const [useICCardStamp, setUseICCardStamp] = useState<number | null>(null);

  //select org
  const [selectedOrg, setSelectedOrg] = useState(useOrgCode());

  // Get system time
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        !currentTime ||
        currentTime.dateString === '' ||
        currentTime.serverDateTime.getSeconds() === 0 ||
        Date.now() > startTime.current + 60 * 1000
      ) {
        getCurrentTime()
          .then((response: any) => {
            setCurrentDay(moment(new Date(response[2])).format('YYYY年MM月DD日 (ddd)'));
            setTimeNow(moment(new Date(response[2])).format('HH  :  mm'));
            setSecond(moment(currentTime.serverDateTime).format('ss'));
            resultCurrentTime.dateString = String(response[0]);
            resultCurrentTime.timeString = String(response[1]);
            resultCurrentTime.serverDateTime = new Date(response[2]);
            setCurrentTime(resultCurrentTime);
            startTime.current = Date.now();
          })
          .catch((error: any) => {
            currentTime.serverDateTime.setSeconds(currentTime.serverDateTime.getSeconds() + 1);
            setCurrentDay(moment(currentTime.serverDateTime).format('YYYY年MM月DD日 (ddd)'));
            setTimeNow(moment(currentTime.serverDateTime).format('HH  :  mm'));
            setSecond(moment(currentTime.serverDateTime).format('ss'));
          });
      } else {
        currentTime.serverDateTime.setMilliseconds(
          currentTime.serverDateTime.getMilliseconds() + Date.now() - startTime.current,
        );
        startTime.current = Date.now();
        setCurrentDay(moment(currentTime.serverDateTime).format('YYYY年MM月DD日 (ddd)'));
        setTimeNow(moment(currentTime.serverDateTime).format('HH  :  mm'));
        setSecond(moment(currentTime.serverDateTime).format('ss'));
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  const fetchHistoryData = () => {
    const shopCode = sessionStorage.getItem('login.shopCode') || '';
    try {
      getHistoryRecorder(companyCode, shopCode).then((responseHistory: any) => {
        setStampHistoryList(responseHistory);
      });
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    if (!companyCode && !specifyCode) {
      setIsLoading(false);
      errorNotification('組織を登録しませんので、ID打刻と顔認証打刻が行えません。');
      setDisabledRecorder(true);
      return;
    }
    if (specifyCode) {
      try {
        getInfomationOrgLocalStorage(companyCode, specifyCode).then((response: any) => {
          setUseFaceStamp(response.useFaceStamp);
          setUseBarcodeStamp(response.useBarcodeStamp);
          setUseICCardStamp(response.useICCardStamp);
          sessionStorage.setItem('login.shopCode', response.shopCode);
          sessionStorage.setItem('login.shopName', response.shopName);
          const loginUser = {
            companyCode: response.companyCode,
            useSales: response.useSales,
            useAttend: response.useAttend,
            useAssistSystem: response.useAssistSystem,
            useFaceStamp: response.useFaceStamp,
            useBarcodeStamp: response.useBarcodeStamp,
            useICCardStamp: response.useICCardStamp,
          };
          sessionStorage.setItem('loginUser', JSON.stringify(loginUser));

          // Set Value
          setStampOrgCode(response.shopCode);
          setStampOrgName(response.shopName);
          setLoginOrgCode(response.shopCode);
          setLoginOrgName(response.shopName);
          fetchHistoryData();
        });
      } catch (error) {
        errorNotification('サーバー側でエラーが発生しました。');
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response: TimeSetting = await getTimeSettingNoAuth(companyCode);
        if (response) {
          setUseFaceStamp(response.useFaceStamp);
          setUseBarcodeStamp(response.useBarcodeStamp);
          setUseICCardStamp(response.useICCardStamp);
        }
      } catch (error) {
        // @ts-ignore
        if (error.response && error.response.data && error.response.data.errors) {
          // @ts-ignore
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      }
      fetchHistoryData();
    }
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode, specifyCode, selectedOrg]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedOrg]);

  return {
    stampHistoryList,
    currentDay,
    timeNow,
    second,
    stampOrgCode,
    stampOrgName,
    loginOrgCode,
    loginOrgName,
    setStampOrgCode,
    setStampOrgName,
    setLoginOrgCode,
    setLoginOrgName,
    resultCurrentTime,
    useFaceStamp,
    useBarcodeStamp,
    useICCardStamp,
    disabledRecorder,
    selectedOrg,
    setSelectedOrg,
    fetchHistoryData,
    companyCode,
    specifyCode,
  };
};

export default useRecorder;
