/** @jsx jsx */
import React from "react";
import FullScreenOverlay from "components/atoms/FullScreenOverlay";
import Panel from "components/atoms/Panel";
import Header from "components/molecules/Modal/Header";
import Footer from "components/molecules/Modal/Footer";
import Body from "components/molecules/Modal/Body";
import BodyText from "components/atoms/BodyText";
import { css, jsx } from "@emotion/core";

/**
 * モーダル
 */
const Modal: React.FC<{
  open: boolean;
  closeHandler: () => void;
  title: string;
  customTitle?: React.ReactNode;
  submitText: string;
  closeText?: string;
  onSubmit: () => void;
  width?: string;
  typeSubmit?: string;
  note?: string;
  customStyle?: any;
  customStyleFooter?: any;
  disabledSubmit?: boolean;
}> = ({
  children,
  open,
  closeHandler,
  title,
  customTitle,
  submitText,
  onSubmit,
  closeText = "キャンセル",
  note = "",
  width,
  typeSubmit = "confirm",
  customStyle,
  customStyleFooter,
  disabledSubmit = false,
}) => (
  <FullScreenOverlay open={open}>
    <Panel width={width || "486px"} margin="auto 8px" customStyle={customStyle}>
      <Header title={title} customTitle={customTitle} />
      <Body>
        <div css={css({ width: "100%", textAlign: "center" })}>
          <BodyText>{note}</BodyText>
        </div>
        {children}
      </Body>
      <Footer
        submitText={submitText}
        closeText={closeText}
        onSubmit={onSubmit}
        closeHandler={closeHandler}
        typeSubmit={typeSubmit}
        customStyleFooter={customStyleFooter}
        disabledSubmit={disabledSubmit}
      />
    </Panel>
  </FullScreenOverlay>
);

export default Modal;
