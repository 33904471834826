/** @jsx jsx */
import React from 'react';

import { css, jsx } from '@emotion/core';
import ManagementInformationForm from './ManagementInformationForm/ManagementInformationForm';
import AcquisitionStatusForm from './AcquisitionStatusForm/AcquisitionStatusForm';
import GrantHistoryForm from './GrantHistoryForm/GrantHistoryForm';

// create style form
const styles = {
  form: css({
    marginTop: '-16px',
  }),
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  container: css({
    padding: '4px 0',
  }),
  label: css({
    flexBasis: '100px',
  }),
  select: css({
    flexGrow: 4,
    minWidth: '100px',
  }),
};

const HolidayManagementPaidForm: React.FC<
  {
    arrGrantResult: any,
    employmentOptions: any,
    orgTreesOptions: any,
    currentAccordionList: any,
    setCurrentAccordionList: any
  }
> = ({
  orgTreesOptions, arrGrantResult, employmentOptions,
}) => (
      <div css={css(styles.form)}>
        <ManagementInformationForm
          employmentOptions={employmentOptions}
          orgTreesOptions={orgTreesOptions}
        />

        <GrantHistoryForm
          arrGrantResult={arrGrantResult}
          orgTreesOptions={orgTreesOptions}
        />

        <AcquisitionStatusForm
          orgTreesOptions={orgTreesOptions}
        />
      </div>
    );
export default HolidayManagementPaidForm;
