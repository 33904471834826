/** @jsx jsx */
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import SecondaryButton from 'components/atoms/Button/SecondaryButton';
import { css, jsx } from '@emotion/core';
import TextForm from 'components/molecules/TextForm';
import moment from 'moment';
import Modal from 'components/molecules/Modal';
import { useApprovalHolidayApplication } from 'components/organismos/master/attend/ApprovalApplication/hooks';
import { useParams } from 'react-router-dom';
import BlockUI from 'components/molecules/BlockUi';

const styles = {
  line: css({
    marginBottom: '15px',
  }),

  rejectInput: css`
    :not(input) {
      font-weight: bold;
    }
    margin-top: 50px;
  `,
};

const ApprovalHolidayApplication: React.FC = () => {
  const { applicationId } = useParams();
  const {
    approvalData,
    nextDay,
    rejectReason,
    setRejectReason,
    applicationStatus,
    modalApproveOpen,
    closeApproveModal,
    modalRejectOpen,
    closeRejectModal,
    setModalApproveOpen,
    setModalRejectOpen,
    callApproveHolidayApplication,
    callRejectHolidayApplication,
    isLoading,
  } = useApprovalHolidayApplication(applicationId);

  const dispModifiyStatus = (status: number) => {
    switch (status) {
      case 0:
        return '申請';
      case 1:
        return '削除';
      default:
        return '';
    }
  };

  const dispHolidayUnit = (holidayUnit: number) => {
    switch (holidayUnit) {
      case 0:
        return '全日';
      case 1:
        return '半休';
      case 2:
        return '時間休';
      case 3:
        return '午前休';
      case 4:
        return '午後休';
      default:
        return '';
    }
  };
  const holidayTime = (time: number) => {
    if (!time) {
      return '';
    }
    return (time).toFixed(2);
  };

  const handleSubmitApproveModal = () => {
    callApproveHolidayApplication();
    closeApproveModal();
  };
  const handleSubmitRejectModal = () => {
    callRejectHolidayApplication();
    closeRejectModal();
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="組織名" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{`${approvalData.orgCode} ${approvalData.orgName}`}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="申請者" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{`${approvalData.applicationStaffCode} ${approvalData.applicationStaffName}`}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="申請日時" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.applicationDate != null
                  && (moment(approvalData.applicationDate).isValid()
                    ? moment(approvalData.applicationDate).format(
                      'YYYY/MM/DD HH:mm:ss',
                    )
                    : '')}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="対象者" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{`${approvalData.staffCode} ${approvalData.staffName}`}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="対象日" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.targetDate != null
                  && (moment(approvalData.targetDate).isValid()
                    ? moment(approvalData.targetDate).format('YYYY/MM/DD HH:mm:ss')
                    : '')}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="申請種別" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{dispModifiyStatus(approvalData.modifiyStatus)}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="休暇" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{approvalData.holidayName}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="休暇単位" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{dispHolidayUnit(approvalData.holidayDigestiveUnit)}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="半休・時間休の開始時間" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {(approvalData.holidayDigestiveUnit == 2 || approvalData.holidayDigestiveUnit == 3 || approvalData.holidayDigestiveUnit == 4 ) && approvalData.holidayStartHm
                ? moment(new Date(approvalData.holidayStartHm), 'YYYY-MM-DD HH:mm').format('HH:mm') + (nextDay ? '（翌）' : '')
                : ''}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="半休・時間休の終了時間" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {(approvalData.holidayDigestiveUnit == 2 || approvalData.holidayDigestiveUnit == 3 || approvalData.holidayDigestiveUnit == 4 )  && approvalData.holidayEndHm
                ? moment(new Date(approvalData.holidayEndHm), 'YYYY-MM-DD HH:mm').format('HH:mm') + (nextDay ? '（翌）' : '')
                : ''}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="取得時間" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{holidayTime(approvalData.holidayTimes)}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel label="申請理由" />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{approvalData.applicationReason}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <div css={styles.rejectInput}>
          <TextForm
            name="rejectReason"
            label={`${approvalData.applicationStatus === 2 ? '差戻者: ' : '承認者: '}  ${approvalData.approvalStaffCode}  ${approvalData.approvalStaffName}`}
            value={rejectReason || ''}
            disable={applicationStatus !== 0}
            onChange={(e) => setRejectReason(e.target.value)}
            placeHolder="差戻コメントを入力"
          />
        </div>

        <FlexBox customStyle={css({
          justifyContent: 'center',
          marginTop: '30px',
        })}
        >
          { applicationStatus === -1
              && (
                <React.Fragment>
                  <BodyText color="#a94442" bold={true}>申請データが見つかりません。</BodyText>
                </React.Fragment>
              )}
          { applicationStatus === 0
              && (
                <React.Fragment>
                  <FlexBoxItem marginRight="5px">
                    <PrimaryButton
                      text="承認"
                      onClick={() => setModalApproveOpen(true)}
                      minWidth="60%"
                    />
                  </FlexBoxItem>
                  <FlexBoxItem marginLeft="5px">
                    <SecondaryButton
                      text="差戻"
                      onClick={() => setModalRejectOpen(true)}
                    />
                  </FlexBoxItem>
                </React.Fragment>
              )}
          { applicationStatus === 1
              && (
                <React.Fragment>
                  <BodyText color="#a94442" bold={true}>この申請は既に承認済みです</BodyText>
                </React.Fragment>
              )}
          { applicationStatus === 2
              && (
                <React.Fragment>
                  <BodyText color="#a94442" bold={true}>この申請は既に差戻済みです</BodyText>
                </React.Fragment>
              )}

        </FlexBox>

        <Modal
          open={modalApproveOpen}
          closeHandler={closeApproveModal}
          title="承認します。よろしいですか？"
          submitText="承認"
          onSubmit={handleSubmitApproveModal}
          
        />
        <Modal
          open={modalRejectOpen}
          closeHandler={closeRejectModal}
          title="差戻します。よろしいですか？"
          submitText="差戻"
          onSubmit={handleSubmitRejectModal}
          
        />
      </FormContents>
    </BlockUI>
  );
};

export default ApprovalHolidayApplication;
