/** @jsx jsx */
import React, { useEffect } from "react";
import BodyText from "components/atoms/BodyText";
import { textColor } from "components/styles";
import { utilityColor } from "components/styles";
import Separater from "components/atoms/Separator";
import Table from "components/molecules/Table";
import { css, jsx } from "@emotion/core";
import PrimaryButton from "components/atoms/Button";
import BottomBar from "components/organismos/BottomBar";
import moment from "moment";
import DatePicker from "components/molecules/DatePicker";
import BlockUI from "components/molecules/BlockUi";
import MobileContainer from "../MobileContainer";
import useWorkingStatusMobileData from "./hooks";
import IconTooltip from "components/molecules/IconTooltip";
import ContextMenu from "components/molecules/ContextMenu";
import { useHistory } from "react-router-dom";
import getContextMenuButtons from "components/molecules/ContextMenu/getContextMenuButtons";

const styles = {
  table: css({
    "& > table": { marginTop: "15px" },
  }),
  tableCell: css({
    "&  button": {
      width: "100px",
    },
    overflow: "visible",
  }),
};

const WorkingStatusMobileLayout: React.FC = () => {
  const {
    isLoading,
    fetchList,
    getTargetDate,
    setTargetDate,
    getLoginStaffCcde,
    getLoginStaffName,
    staffStampList,
    employment,
    operationInfo,
    yearMonth,
  } = useWorkingStatusMobileData();
  const history = useHistory();

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTargetDate]);

  return (
    <BlockUI blocking={isLoading}>
      <div>
        <MobileContainer>
          <DatePicker
            dateFormat="yyyy/MM"
            date={yearMonth}
            changeDate={(date: Date) => {
              setTargetDate(date);
            }}
            showMonthYearPicker={true}
            customStyle={css({ width: "fit-content" })}
          />

          <div>
            {moment(yearMonth).format("M")}
            月の勤務状況
          </div>

          <div css={css({ marginTop: "0.5rem" })}>
            給与目安 &nbsp;
            <BodyText size="md">¥</BodyText>
            <BodyText size="xl" bold={true}>
              {`${Number(Math.floor(operationInfo.monthTotalCost / 1000) * 1000 || 0).toLocaleString()}`}
            </BodyText>
          </div>

          <div>
            <BodyText color={textColor.subText01}>
              合計給与予定 &nbsp;
              {`¥${Number(Math.floor(operationInfo.totalCost / 1000) * 1000 || 0).toLocaleString()}`}
            </BodyText>
          </div>

          <div css={css({ marginTop: "0.5rem" })}>
            勤務時間 &nbsp;
            <BodyText size="xl" bold={true}>
              {`${operationInfo.monthTotalworkTimeHour || 0}`}
            </BodyText>
            <BodyText size="md">時間</BodyText>
            <BodyText size="xl" bold={true}>
              {`${operationInfo.monthTotalworkTimeMin || 0}`}
            </BodyText>
            <BodyText size="md">分</BodyText>
          </div>

          <div>
            <BodyText color={textColor.subText01}>
              合計勤務時間予定 &nbsp;
              {`${operationInfo.totalWorkTimeHour || 0}時間${operationInfo.totalWorkTimeMin || 0}分`}
            </BodyText>
          </div>

          <Separater margin="1rem 0 2rem" />

          <div>
            {moment(yearMonth).format("M")}
            月の勤務実績
          </div>

          <div css={styles.table}>
            <Table>
              <thead>
                <tr>
                  <Table.HeaderCell customStyle={css({ width: "20px" })}></Table.HeaderCell>
                  <Table.HeaderCell customStyle={css({ width: "100px" })}>日付</Table.HeaderCell>
                  <Table.HeaderCell customStyle={css({ width: "120px" })}>時間</Table.HeaderCell>
                  <Table.HeaderCell />
                </tr>
              </thead>
              <tbody>
                {staffStampList &&
                  staffStampList.map((cell, index) => {
                    let contextMenuButtons = getContextMenuButtons(
                      history,
                      cell.modifierApplicationId,
                      cell.orgCode,
                      getLoginStaffCcde,
                      getLoginStaffName,
                      cell.modifierApplicationStatus,
                      cell.holidayApplicationStatus,
                      cell.overworkApplicationStatus,
                      cell.holidayworkApplicationStatus,
                      cell.allowanceApplicationStatus,
                      cell.transferApplicationStatus,
                      cell.attendanceOrgFix,
                      employment?.useOverTimeApplication,
                      employment?.necessaryHolidayWorkApplication,
                      employment?.useAllowanceApplication,
                      employment?.useTransferApplication,
                      cell.achievementId,
                      cell.targetDate,
                      cell.stampVersion,
                    );
                    return (
                      <tr key={index}>
                        <Table.Cell>
                          {cell.alert === "1" && (
                            <IconTooltip
                              iconType="warning"
                              text={cell.alertMsg || ""}
                              color={utilityColor.yellow}
                              customCss={css({ margin: "0" })}
                              place={"right"}
                            />
                          )}
                          {cell.alert === "2" && (
                            <IconTooltip
                              iconType="warning"
                              text={cell.alertMsg || ""}
                              color={utilityColor.error}
                              customCss={css({ margin: "0" })}
                              place={"right"}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>{moment(cell.targetDate).format("YYYY/MM/DD")}</Table.Cell>

                        <Table.Cell>
                          <BodyText>
                            {cell.startTime} {(cell.startTime || cell.endTime) && "~"} {cell.endTime}
                          </BodyText>
                        </Table.Cell>

                        <Table.Cell customStyle={styles.tableCell}>
                          {cell.belongOrgFix === "1" ? (
                            <PrimaryButton disabled={true} text="修正申請 ›" />
                          ) : (
                            <ContextMenu text="修正申請 ›" buttons={contextMenuButtons} />
                          )}
                        </Table.Cell>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </MobileContainer>
        <BottomBar />
      </div>
    </BlockUI>
  );
};

export default WorkingStatusMobileLayout;
