import React from 'react'
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendApplicationApprovalDetail from 'components/organismos/master/attend/AttendApplicationApprovalDetail';

const AttendApplicationApprovalDetailPage = () => {
  return(
    <SidebarTemplate pageTitle="勤怠承認">
      <AttendApplicationApprovalDetail/>
    </SidebarTemplate>
  )
}

export default AttendApplicationApprovalDetailPage