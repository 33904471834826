import ApiClient from "./ApiClient";

//Get applying-overTime History
export const getApplyingOverTimeListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/attendOvertime/overtimeHistoryList/${companyCode}`, {}, params);
  return data;
};

//Get applying-allowance History
export const getApplyingAllowanceListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/attendAllowance/allowanceHistoryList/${companyCode}`, {}, params);
  return data;
};

//Get applying-agreement History
export const getApplyingAgreement36ListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/36Agreements/overtimeHistoryList/${companyCode}`, {}, params);
  return data;
};

//Get applying-WorkSchedule History
export const getApplyingWorkScheduleChangeListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/attendWorkPlan/workPlanHistoryList/${companyCode}`, {}, params);
  return data;
};

// get apply history work

export const getApplyingHistoryWorkListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/attendHoliday/holidayWorkHistoryList/${companyCode}`, {}, params);
  // data.sort((a:IchangeLog, b:IchangeLog) => (a.v   ersion - b.version));
  return data;
};

// get apply transfer work
export const getApplyingTransferListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/attendTransfer/transferHistoryList/${companyCode}`, {}, params);
  // data.sort((a:IchangeLog, b:IchangeLog) => (a.v   ersion - b.version));
  return data;
};

// get apply transfer work
export const getApplyingHolidayListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(`/v1/attendModifier/modifierHistoryListByAppId/${companyCode}`, {}, params);
  // data.sort((a:IchangeLog, b:IchangeLog) => (a.version - b.version));
  return data;
};

// get application holiday list history
export const getStockPaidListHistory = async (params?: any): Promise<any[]> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { data } = await ApiClient.get(
    `/v1/attendStockPaidHoliday/stockPaidHolidayHistoryListByAppId/${companyCode}`,
    {},
    params,
  );
  // data.sort((a:IchangeLog, b:IchangeLog) => (a.version - b.version));
  return data;
};
