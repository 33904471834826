/* eslint-disable max-len */
/** @jsx jsx */
import React, { createContext } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Link, useHistory } from "react-router-dom";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Modal from "components/molecules/Modal";
import { useMasterRoleEditForm } from "./hooks";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import BodyText from "components/atoms/BodyText";
import FormPadding from "components/atoms/Form/FormPadding";
import TabControl from "components/atoms/TabControl";
import MasterBaseRole from "./Tabs/MasterBaseRole";
import MasterRole from "./Tabs/MasterRole";
import { State } from "./type";
import Action from "./actions";
import Button from "components/atoms/Button";

const styles = {
  customStyle: css({
    display: "flex",
    justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
    paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
  }),
  columnOne: css(
    {
      justifyContent: "center",
      margin: "0 auto",
    },
    css`
      button {
        white-space: nowrap;
      }
    `,
  ),
};

type SelectType =
  | "base"
  | "home"
  | "attend"
  | "employeeAttend"
  | "stampHistory"
  | "masterGeneral"
  | "masterAttendance"
  | "stampManagement";

const SelectType: { [key in SelectType]: SelectType } = {
  base: "base",
  home: "home",
  attend: "attend",
  employeeAttend: "employeeAttend",
  stampHistory: "stampHistory",
  masterGeneral: "masterGeneral",
  masterAttendance: "masterAttendance",
  stampManagement: "stampManagement",
};

// CREATE CONTEXT
type ContextType = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const EditRoleContext = createContext<ContextType>({} as ContextType);
// CREATE CONTEXT

const tabItems = [
  {
    value: SelectType.base,
    display: "基本項目",
  },
  {
    value: SelectType.home,
    display: "ホーム",
  },
  {
    value: SelectType.attend,
    display: "勤怠管理",
  },
  {
    value: SelectType.employeeAttend,
    display: "スタッフ勤怠",
  },
  {
    value: SelectType.stampHistory,
    display: "打刻実績",
  },
  {
    value: SelectType.masterGeneral,
    display: "一般マスタ",
  },
  {
    value: SelectType.masterAttendance,
    display: "勤怠マスタ",
  },
  {
    value: SelectType.stampManagement,
    display: "打刻管理",
  },
];

const MasterRoleEditForm = () => {
  const history = useHistory();
  const {
    isLoading,
    roleName,
    roleCode,
    state,
    dispatch,
    detailRole,
    isOpenModal,
    setIsOpenModal,
    handleSubmit,
    action,
  } = useMasterRoleEditForm();
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.base);

  return (
    <EditRoleContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <BlockUI blocking={isLoading}>
        <div>
          <FormContents customStyle={{ marginBottom: "50px" }}>
            <FormTitle
              title="権限編集"
              bold={true}
              customStyle={css`
                margin: 16px 35px;
              `}
            />
            <FormPadding customStyle={css({ padding: "0 35px 20px" })}>
              <FlexBox>
                <BodyText customStyle={css({ marginRight: "15px" })} bold={true}>{`${state.data.roleCode}:`}</BodyText>
                <BodyText>{roleName}</BodyText>
              </FlexBox>
            </FormPadding>
            <TabControl
              items={tabItems}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              customStyle={css({
                margin: "0px",
                padding: "8px 16px",
                boxSizing: "border-box",
                maxWidth: "100%",
                overflow: "auto",
              })}
            />
            <FormPadding customStyle={css({ marginTop: "20px" })}>
              {selectType === SelectType.base && <MasterBaseRole editable={detailRole.editable} />}
              {selectType !== SelectType.base && (
                <MasterRole
                  editable={detailRole.editable}
                  menuGroup={tabItems.findIndex((tab) => tab.value === selectType)}
                />
              )}
            </FormPadding>
            <FormSubmitArea customStyle={css({ margin: "20px 0 20px" })}>
              <div style={{ textAlign: "center", marginTop: "70px" }}>
                <FlexBox justifyContent="center">
                  {detailRole.editable === 1 && action === "edit" && (
                    <FlexBoxItem width="140px">
                      <Button text="更新" onClick={() => setIsOpenModal(true)} />
                    </FlexBoxItem>
                  )}
                  <FlexBoxItem width="140px">
                    <Button text={"一覧へ戻る"} onClick={() => history.push("/masterRoleSetting")} />
                  </FlexBoxItem>
                </FlexBox>
                <FlexBox justifyContent="center">
                  <Link to="/masterRoleSetting">
                    <Button text="キャンセル" ghost={true} />
                  </Link>
                </FlexBox>
              </div>
            </FormSubmitArea>
          </FormContents>
          <Modal
            open={isOpenModal}
            closeHandler={() => setIsOpenModal(false)}
            title="確認メッセージ"
            note="権限情報を更新します。よろしいですか？"
            submitText="更新する"
            onSubmit={() => {
              handleSubmit();
            }}
          ></Modal>
        </div>
      </BlockUI>
    </EditRoleContext.Provider>
  );
};

export default MasterRoleEditForm;
