import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import FormContents from "components/atoms/Form/FormContents";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, SecondaryButton } from "components/atoms/Button";
import VacationDomain, { HolidayType, HolidayTypeLabel } from "domain/vacation";
import ConfirmModal from "components/organismos/ConfirmModal";
import useStaffsOptions from "hooks/useStaffsOptions";
import { useVacationAddForm } from "./hooks";
import DetailPaid1DayForm from "./DetailPaid1DayForm";
import DetailPaidHalfDayForm from "./DetailPaidHalfDayForm";
import DetailHolidayForm from "./DetailHolidayForm";
import DetailTexiuForm from "./DetailTexiuForm";
import TakeCareRelativeForm from "./TakeCareRelativeForm";
import TakeCareChildForm from "./TakeCareChildForm";
import ColorForm from "components/molecules/ColorForm";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import { css } from "@emotion/core";
import { deleteById } from "api/vacationMaster";
import useToastNotification from "hooks/useToastNotification";
import BlockUI from "components/molecules/BlockUi";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import DetailRersvedPaid from "./DetailRersvedPaid";

const VacationCreateForm: React.FC = () => {
  const { vacationId } = useParams();
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    history,
    blocking,
    orgTreesOptions,
    executiveOptions,
    staffsForOrgCodeOptions,
    orgCode,
    setOrgCode,
    executiveId,
    setExecutiveId,
    staffCode,
    setStaffCode,
    orgCode2,
    setOrgCode2,
    detailRole,
    //closeConfirmResendModal, submitConfirmResendModal, confirmModalContent, confirmModalResendOpen,
  } = useVacationAddForm(vacationId);

  const staffList = useStaffsOptions();

  if (formik.values.deemedWorkingMinutes === undefined && formik.values.deemedWorkingHours !== undefined) {
    const wrongDeemedWorkingHours = formik.values.deemedWorkingHours;
    formik.setFieldValue("deemedWorkingHours", Math.trunc(wrongDeemedWorkingHours / 60));
    formik.setFieldValue("deemedWorkingMinutes", wrongDeemedWorkingHours % 60);
  }

  if (
    formik.values.conditionGrantSubstituteHoliday !== undefined &&
    formik.values.conditionGrantSubstituteHolidayHours === undefined &&
    formik.values.conditionGrantSubstituteHolidayMinutes === undefined
  ) {
    const { conditionGrantSubstituteHoliday } = formik.values;
    formik.setFieldValue("conditionGrantSubstituteHolidayHours", Math.trunc(conditionGrantSubstituteHoliday / 60));
    formik.setFieldValue("conditionGrantSubstituteHolidayMinutes", conditionGrantSubstituteHoliday % 60);
  }

  if (
    formik.values.conditionGrantSubstituteHalfHoliday !== undefined &&
    formik.values.conditionGrantSubstituteHalfHolidayHours === undefined &&
    formik.values.conditionGrantSubstituteHalfHolidayMinutes === undefined
  ) {
    const { conditionGrantSubstituteHalfHoliday } = formik.values;
    formik.setFieldValue(
      "conditionGrantSubstituteHalfHolidayHours",
      Math.trunc(conditionGrantSubstituteHalfHoliday / 60),
    );
    formik.setFieldValue("conditionGrantSubstituteHalfHolidayMinutes", conditionGrantSubstituteHalfHoliday % 60);
  }

  const [selectedVacationMaster, setSelectedVacationMaster] = useState(VacationDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const closeConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModalOpen(false);
  }, []);

  const openConfirmDeleteModal = useCallback((vacation) => {
    setConfirmDeleteModalOpen(true);
    setSelectedVacationMaster(vacation);
  }, []);

  const deleteVacationMaster = useCallback(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    deleteById(companyCode, selectedVacationMaster.holidayId)
      .then((response: any) => {
        setConfirmDeleteModalOpen(false);
        successNotification("削除しました。");
        history.push("/vacationMaster");
      })
      .catch((error) => {
        setConfirmDeleteModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage}\n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [errorNotification, selectedVacationMaster.holidayId, successNotification, history]);

  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormTitle bold={true} title={`${vacationId ? "休暇編集" : "休暇作成"}`} />
        <div style={{ paddingTop: "80px" }}>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ margin: "0 auto", width: "60%" }}>
              <FormField>
                <TextForm
                  name="holidayCode"
                  label="休暇コード"
                  value={formik.values.holidayCode}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.holidayCode}
                />
              </FormField>

              <FormField>
                <TextForm
                  name="holidayName"
                  label="休暇名"
                  value={formik.values.holidayName}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.holidayName}
                />
              </FormField>

              <FormField>
                <div style={{ width: "100%" }}>
                  <VerticalLabelSelectForm
                    label="休暇タイプ"
                    name="holidayType"
                    options={[
                      {
                        label: HolidayTypeLabel[HolidayType.PUBLIC_HOLIDAY],
                        value: HolidayType.PUBLIC_HOLIDAY.toString(),
                      },
                      {
                        label: HolidayTypeLabel[HolidayType.PAID_1_DAY],
                        value: HolidayType.PAID_1_DAY.toString(),
                      },
                      // {
                      //   label: HolidayTypeLabel[HolidayType.PAID_HALF_DAY],
                      //   value: HolidayType.PAID_HALF_DAY.toString(),
                      // },
                      {
                        label: HolidayTypeLabel[HolidayType.HOLIDAY],
                        value: HolidayType.HOLIDAY.toString(),
                      },
                      {
                        label: HolidayTypeLabel[HolidayType.TEXIU],
                        value: HolidayType.TEXIU.toString(),
                      },
                      {
                        label: HolidayTypeLabel[HolidayType.ABSENCE],
                        value: HolidayType.ABSENCE.toString(),
                      },
                      {
                        label: HolidayTypeLabel[HolidayType.TAKECARE_RELATIVE],
                        value: HolidayType.TAKECARE_RELATIVE.toString(),
                      },
                      {
                        label: HolidayTypeLabel[HolidayType.TAKECARE_CHILD],
                        value: HolidayType.TAKECARE_CHILD.toString(),
                      },
                      {
                        label: HolidayTypeLabel[HolidayType.RESERVED_PAID],
                        value: HolidayType.RESERVED_PAID.toString(),
                      },
                    ]}
                    value={String(formik.values.holidayType)}
                    setValue={(val) => formik.setFieldValue("holidayType", val)}
                  />
                </div>
              </FormField>

              <div style={{ paddingBottom: "20px" }}>
                {String(formik.values.holidayType) === HolidayType.PAID_1_DAY.toString() && (
                  <DetailPaid1DayForm
                    paidCalculationStandard={formik.values.paidCalculationStandard}
                    setPaidCalculationStandard={(val: boolean) => {
                      formik.setFieldValue("paidCalculationStandard", val);
                    }}
                    isWorkTime={formik.values.isWorkTime}
                    setIsWorkTime={(val: number) => {
                      formik.setFieldValue("isWorkTime", val);
                    }}
                    unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
                    setUnUsableOfTrialPeriod={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTrialPeriod", val);
                    }}
                    unUsableOfHalfHoliday={formik.values.unUsableOfHalfHoliday}
                    setUnUsableOfHalfHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfHalfHoliday", val);
                    }}
                    unUsableOfTimeHoliday={formik.values.unUsableOfTimeHoliday}
                    setUnUsableOfTimeHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTimeHoliday", val);
                    }}
                    useEmploymentDeemedWorkingHours={formik.values.useEmploymentDeemedWorkingHours}
                    setUseEmploymentDeemedWorkingHours={(val: boolean) => {
                      formik.setFieldValue("useEmploymentDeemedWorkingHours", val);
                    }}
                    deemedWorkingHours={formik.values.deemedWorkingHours}
                    setDeemedWorkingHours={(val: number) => {
                      formik.setFieldValue("deemedWorkingHours", val);
                    }}
                    deemedWorkingMinutes={formik.values.deemedWorkingMinutes}
                    setDeemedWorkingMinutes={(val: number) => {
                      formik.setFieldValue("deemedWorkingMinutes", val);
                    }}
                  />
                )}

                {/* { String(formik.values.holidayType) === HolidayType.PAID_HALF_DAY.toString() && (
              <DetailPaidHalfDayForm
                paidCalculationStandard={formik.values.paidCalculationStandard}
                setPaidCalculationStandard={(val: boolean) => {
                  formik.setFieldValue('paidCalculationStandard', val);
                }}
	          	isWorkTime={formik.values.isWorkTime}
	              setIsWorkTime={(val: number) => {
				               formik.setFieldValue('isWorkTime', val);
	            }}
                unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
                setUnUsableOfTrialPeriod={(val: boolean) => {
                  formik.setFieldValue('unUsableOfTrialPeriod', val);
                }}
                deemedWorkingHours={formik.values.deemedWorkingHours}
                setDeemedWorkingHours={(val: number) => {
                  formik.setFieldValue('deemedWorkingHours', val);
                }}
                deemedWorkingMinutes={formik.values.deemedWorkingMinutes}
                setDeemedWorkingMinutes={(val: number) => {
                  formik.setFieldValue('deemedWorkingMinutes', val);
                }}
                paidHalfHolidayStartTime={formik.values.paidHalfHolidayStartTime}
                setPaidHalfHolidayStartTime={(val: string) => {
                  formik.setFieldValue('paidHalfHolidayStartTime', val);
                }}
                paidHalfHolidayEndTime={formik.values.paidHalfHolidayEndTime}
                setPaidHalfHolidayEndTime={(val: string) => {
                  formik.setFieldValue('paidHalfHolidayEndTime', val);
                }}
                isPaidHalfHolidayStartTimeNextDay={formik.values.isPaidHalfHolidayStartTimeNextDay}
                setIsPaidHalfHolidayStartTimeNextDay={(val: boolean) => {
                  formik.setFieldValue('isPaidHalfHolidayStartTimeNextDay', val);
                }}
                isPaidHalfHolidayEndTimeNextDay={formik.values.isPaidHalfHolidayEndTimeNextDay}
                setIsPaidHalfHolidayEndTimeNextDay={(val: boolean) => {
                  formik.setFieldValue('isPaidHalfHolidayEndTimeNextDay', val);
                }}
              />
            )} */}

                {String(formik.values.holidayType) === HolidayType.HOLIDAY.toString() && (
                  <DetailHolidayForm
                    autoGrantHoliday={formik.values.autoGrantHoliday}
                    setAutoGrantHoliday={(val: boolean) => {
                      formik.setFieldValue("autoGrantHoliday", val);
                    }}
                    conditionGrantSubstituteHolidayHours={formik.values.conditionGrantSubstituteHolidayHours}
                    setConditionGrantSubstituteHolidayHours={(val: number) => {
                      formik.setFieldValue("conditionGrantSubstituteHolidayHours", val);
                    }}
                    conditionGrantSubstituteHolidayMinutes={formik.values.conditionGrantSubstituteHolidayMinutes}
                    setConditionGrantSubstituteHolidayMinutes={(val: number) => {
                      formik.setFieldValue("conditionGrantSubstituteHolidayMinutes", val);
                    }}
                    validityPeriod={formik.values.validityPeriod}
                    setValidityPeriod={(val: number) => {
                      formik.setFieldValue("validityPeriod", val);
                    }}
                    conditionGrantSubstituteHalfHolidayHours={formik.values.conditionGrantSubstituteHalfHolidayHours}
                    setConditionGrantSubstituteHalfHolidayHours={(val: number) => {
                      formik.setFieldValue("conditionGrantSubstituteHalfHolidayHours", val);
                    }}
                    conditionGrantSubstituteHalfHolidayMinutes={
                      formik.values.conditionGrantSubstituteHalfHolidayMinutes
                    }
                    setConditionGrantSubstituteHalfHolidayMinutes={(val: number) => {
                      formik.setFieldValue("conditionGrantSubstituteHalfHolidayMinutes", val);
                    }}
                  />
                )}

                {String(formik.values.holidayType) === HolidayType.TAKECARE_RELATIVE.toString() && (
                  <TakeCareRelativeForm
                    useEmploymentDeemedWorkingHours={formik.values.useEmploymentDeemedWorkingHours}
                    setUseEmploymentDeemedWorkingHours={(val: boolean) => {
                      formik.setFieldValue("useEmploymentDeemedWorkingHours", val);
                    }}
                    paidCalculationStandard={formik.values.paidCalculationStandard}
                    setPaidCalculationStandard={(val: boolean) => {
                      formik.setFieldValue("paidCalculationStandard", val);
                    }}
                    paidFlag={formik.values.paidFlag}
                    setPaidFlag={(val: number) => {
                      formik.setFieldValue("paidFlag", val);
                    }}
                    isWorkTime={formik.values.isWorkTime}
                    setIsWorkTime={(val: number) => {
                      formik.setFieldValue("isWorkTime", val);
                    }}
                    yearUseDays={formik.values.yearUseDays}
                    setYearUseDays={(val: number) => {
                      formik.setFieldValue("yearUseDays", val);
                    }}
                    unUsableOfHalfHoliday={formik.values.unUsableOfHalfHoliday}
                    setUnUsableOfHalfHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfHalfHoliday", val);
                    }}
                    unUsableOfTimeHoliday={formik.values.unUsableOfTimeHoliday}
                    setUnUsableOfTimeHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTimeHoliday", val);
                    }}
                    unUsableOfEntring6months={formik.values.unUsableOfEntring6months}
                    setUnUsableOfEntring6months={(val: boolean) => {
                      formik.setFieldValue("unUsableOfEntring6months", val);
                    }}
                    unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
                    setUnUsableOfTrialPeriod={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTrialPeriod", val);
                    }}
                  />
                )}

                {String(formik.values.holidayType) === HolidayType.TAKECARE_CHILD.toString() && (
                  <TakeCareChildForm
                    useEmploymentDeemedWorkingHours={formik.values.useEmploymentDeemedWorkingHours}
                    setUseEmploymentDeemedWorkingHours={(val: boolean) => {
                      formik.setFieldValue("useEmploymentDeemedWorkingHours", val);
                    }}
                    paidCalculationStandard={formik.values.paidCalculationStandard}
                    setPaidCalculationStandard={(val: boolean) => {
                      formik.setFieldValue("paidCalculationStandard", val);
                    }}
                    paidFlag={formik.values.paidFlag}
                    setPaidFlag={(val: number) => {
                      formik.setFieldValue("paidFlag", val);
                    }}
                    isWorkTime={formik.values.isWorkTime}
                    setIsWorkTime={(val: number) => {
                      formik.setFieldValue("isWorkTime", val);
                    }}
                    yearUseDays={formik.values.yearUseDays}
                    setYearUseDays={(val: number) => {
                      formik.setFieldValue("yearUseDays", val);
                    }}
                    unUsableOfHalfHoliday={formik.values.unUsableOfHalfHoliday}
                    setUnUsableOfHalfHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfHalfHoliday", val);
                    }}
                    unUsableOfTimeHoliday={formik.values.unUsableOfTimeHoliday}
                    setUnUsableOfTimeHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTimeHoliday", val);
                    }}
                    unUsableOfEntring6months={formik.values.unUsableOfEntring6months}
                    setUnUsableOfEntring6months={(val: boolean) => {
                      formik.setFieldValue("unUsableOfEntring6months", val);
                    }}
                    unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
                    setUnUsableOfTrialPeriod={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTrialPeriod", val);
                    }}
                  />
                )}

                {String(formik.values.holidayType) === HolidayType.TEXIU.toString() && (
                  <DetailTexiuForm
                    paidCalculationStandard={formik.values.paidCalculationStandard}
                    setPaidCalculationStandard={(val: boolean) => {
                      formik.setFieldValue("paidCalculationStandard", val);
                    }}
                    paidFlag={formik.values.paidFlag}
                    setPaidFlag={(val: number) => {
                      formik.setFieldValue("paidFlag", val);
                    }}
                    isWorkTime={formik.values.isWorkTime}
                    setIsWorkTime={(val: number) => {
                      formik.setFieldValue("isWorkTime", val);
                    }}
                    unUsableOfTrialPeriod={formik.values.unUsableOfTrialPeriod}
                    setUnUsableOfTrialPeriod={(val: boolean) => {
                      formik.setFieldValue("unUsableOfTrialPeriod", val);
                    }}
                    useEmploymentDeemedWorkingHours={formik.values.useEmploymentDeemedWorkingHours}
                    setUseEmploymentDeemedWorkingHours={(val: boolean) => {
                      formik.setFieldValue("useEmploymentDeemedWorkingHours", val);
                    }}
                    deemedWorkingHours={formik.values.deemedWorkingHours}
                    setDeemedWorkingHours={(val: number) => {
                      formik.setFieldValue("deemedWorkingHours", val);
                    }}
                    deemedWorkingMinutes={formik.values.deemedWorkingMinutes}
                    setDeemedWorkingMinutes={(val: number) => {
                      formik.setFieldValue("deemedWorkingMinutes", val);
                    }}
                    autoGrantHoliday={formik.values.autoGrantHoliday}
                    setAutoGrantHoliday={(val: boolean) => {
                      formik.setFieldValue("autoGrantHoliday", val);
                    }}
                    autoGrantDateMonth={formik.values.autoGrantDateMonth}
                    setAutoGrantDateMonth={(val: number) => {
                      formik.setFieldValue("autoGrantDateMonth", val);
                    }}
                    autoGrantDateDay={formik.values.autoGrantDateDay}
                    setAutoGrantDateDay={(val: number) => {
                      formik.setFieldValue("autoGrantDateDay", val);
                    }}
                    autoGrantHolidayType={formik.values.autoGrantHolidayType}
                    setAutoGrantHolidayType={(val: number) => {
                      formik.setFieldValue("autoGrantHolidayType", val);
                    }}
                    autoGrantDay={formik.values.autoGrantDay}
                    setAutoGrantDay={(val: number | "") => {
                      formik.setFieldValue("autoGrantDay", val);
                    }}
                    autoGrantWorkingStartMonths1={formik.values.autoGrantWorkingStartMonths1}
                    setAutoGrantWorkingStartMonths1={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingStartMonths1", val);
                    }}
                    autoGrantWorkingEndMonths1={formik.values.autoGrantWorkingEndMonths1}
                    setAutoGrantWorkingEndMonths1={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingEndMonths1", val);
                    }}
                    autoGrantWorkingDay1={formik.values.autoGrantWorkingDay1}
                    setAutoGrantWorkingDay1={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingDay1", val);
                    }}
                    autoGrantWorkingStartMonths2={formik.values.autoGrantWorkingStartMonths2}
                    setAutoGrantWorkingStartMonths2={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingStartMonths2", val);
                    }}
                    autoGrantWorkingEndMonths2={formik.values.autoGrantWorkingEndMonths2}
                    setAutoGrantWorkingEndMonths2={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingEndMonths2", val);
                    }}
                    autoGrantWorkingDay2={formik.values.autoGrantWorkingDay2}
                    setAutoGrantWorkingDay2={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingDay2", val);
                    }}
                    autoGrantWorkingStartMonths3={formik.values.autoGrantWorkingStartMonths3}
                    setAutoGrantWorkingStartMonths3={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingStartMonths3", val);
                    }}
                    autoGrantWorkingEndMonths3={formik.values.autoGrantWorkingEndMonths3}
                    setAutoGrantWorkingEndMonths3={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingEndMonths3", val);
                    }}
                    autoGrantWorkingDay3={formik.values.autoGrantWorkingDay3}
                    setAutoGrantWorkingDay3={(val: number | "") => {
                      formik.setFieldValue("autoGrantWorkingDay3", val);
                    }}
                    enteringGrantHoliday={formik.values.enteringGrantHoliday}
                    setEnteringGrantHoliday={(val: boolean) => {
                      formik.setFieldValue("enteringGrantHoliday", val);
                    }}
                    firstGrantType={formik.values.firstGrantType}
                    setFirstGrantType={(val: number) => {
                      formik.setFieldValue("firstGrantType", val);
                    }}
                    enteringGrantDay1={formik.values.enteringGrantDay1}
                    setEnteringGrantDay1={(val: number | "") => {
                      formik.setFieldValue("enteringGrantDay1", val);
                    }}
                    enteringGrantDay2={formik.values.enteringGrantDay2}
                    setEnteringGrantDay2={(val: number | "") => {
                      formik.setFieldValue("enteringGrantDay2", val);
                    }}
                    enteringGrantDay3={formik.values.enteringGrantDay3}
                    setEnteringGrantDay3={(val: number | "") => {
                      formik.setFieldValue("enteringGrantDay3", val);
                    }}
                    includingTrialPeriod={formik.values.includingTrialPeriod}
                    setIncludingTrialPeriod={(val: boolean) => {
                      formik.setFieldValue("includingTrialPeriod", val);
                    }}
                    noticeStaffs={formik.values.noticeStaffs}
                    setNoticeStaffs={(val: Array<any>) => {
                      formik.setFieldValue("noticeStaffs", val);
                    }}
                    listStaff={staffList}
                    unUsableOfHalfHoliday={formik.values.unUsableOfHalfHoliday}
                    setUnUsableOfHalfHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfHalfHoliday", val);
                    }}
                    validityPeriodStartMonth={formik.values.validityPeriodStartMonth}
                    setValidityPeriodStartMonth={(val: number | "") => {
                      formik.setFieldValue("validityPeriodStartMonth", val);
                    }}
                    validityPeriodStartDay={formik.values.validityPeriodStartDay}
                    setValidityPeriodStartDay={(val: number | "") => {
                      formik.setFieldValue("validityPeriodStartDay", val);
                    }}
                    validityPeriodEndMonth={formik.values.validityPeriodEndMonth}
                    setValidityPeriodEndMonth={(val: number | "") => {
                      formik.setFieldValue("validityPeriodEndMonth", val);
                    }}
                    validityPeriodEndDay={formik.values.validityPeriodEndDay}
                    setValidityPeriodEndDay={(val: number | "") => {
                      formik.setFieldValue("validityPeriodEndDay", val);
                    }}
                  />
                )}

                {String(formik.values.holidayType) === HolidayType.RESERVED_PAID.toString() && (
                  <DetailRersvedPaid
                    paidCalculationStandard={formik.values.paidCalculationStandard}
                    setPaidCalculationStandard={(val: boolean) => {
                      formik.setFieldValue("paidCalculationStandard", val);
                    }}
                    paidFlag={formik.values.paidFlag}
                    setPaidFlag={(val: number) => {
                      formik.setFieldValue("paidFlag", val);
                    }}
                    isWorkTime={formik.values.isWorkTime}
                    setIsWorkTime={(val: number) => {
                      formik.setFieldValue("isWorkTime", val);
                    }}
                    unUsableOfHalfHoliday={formik.values.unUsableOfHalfHoliday}
                    setUnUsableOfHalfHoliday={(val: boolean) => {
                      formik.setFieldValue("unUsableOfHalfHoliday", val);
                    }}
                    maxYearStockDays={formik.values.maxYearStockDays}
                    setMaxYearStockDays={(val: number) => {
                      formik.setFieldValue("maxYearStockDays", val);
                    }}
                    maxAllStockDays={formik.values.maxAllStockDays}
                    setMaxAllStockDays={(val: number) => {
                      formik.setFieldValue("maxAllStockDays", val);
                    }}
                    maxStockAge={formik.values.maxStockAge}
                    setMaxStockAge={(val: number) => {
                      formik.setFieldValue("maxStockAge", val);
                    }}
                    appUserType={formik.values.appUserType}
                    setAppUserType={(val: number) => {
                      formik.setFieldValue("appUserType", val);
                    }}
                    orgTreesOptions={orgTreesOptions}
                    executiveOptions={executiveOptions}
                    staffsForOrgCodeOptions={staffsForOrgCodeOptions}
                    orgCode={orgCode}
                    setOrgCode={setOrgCode}
                    orgCode2={orgCode2}
                    setOrgCode2={setOrgCode2}
                    executiveId={executiveId}
                    setExecutiveId={setExecutiveId}
                    staffCode={staffCode}
                    setStaffCode={(val: any) => {
                      setStaffCode(val);
                    }}
                    appUserList={formik.values.appUserList}
                    setAppUserList={(val: any) => {
                      formik.setFieldValue("appUserList", val);
                    }}
                    appUserUpdateList={formik.values.appUserUpdateList}
                    setAppUserUpdateList={(val: any) => {
                      formik.setFieldValue("appUserUpdateList", val);
                    }}
                  />
                )}
              </div>
              {String(formik.values.holidayType) === HolidayType.HOLIDAY.toString() && (
                <FormField>
                  <RadioSelectForm
                    label="半休の使用可否"
                    subLabel=""
                    items={[
                      {
                        label: "使用不可",
                        value: "0",
                      },
                      {
                        label: "使用可",
                        value: "1",
                      },
                    ]}
                    name="unUsableOfHalfHoliday"
                    value={formik.values.unUsableOfHalfHoliday ? "1" : "0"}
                    setValue={(e) => {
                      formik.setFieldValue("unUsableOfHalfHoliday", Boolean(e.target.value !== "0"));
                    }}
                  />
                </FormField>
              )}

              <p>カラー設定 </p>
              <ColorForm
                name="holidayBgColor"
                label="背景色"
                color={formik.values.holidayBgColor}
                isFullW={true}
                onChange={(color) => {
                  formik.setFieldValue("holidayBgColor", color);
                }}
              />

              <ColorForm
                name="holidayForecolor"
                label="文字色"
                color={formik.values.holidayForecolor}
                isFullW={true}
                onChange={(color) => {
                  formik.setFieldValue("holidayForecolor", color);
                }}
              />
              <div
                style={{
                  textAlign: "center",
                  height: "32px",
                  padding: "6px 10px 0px 10px",
                  float: "right",
                  backgroundColor: formik.values.holidayBgColor,
                  color: formik.values.holidayForecolor,
                }}
              >
                表示イメージ
              </div>
            </div>

            <div style={{ paddingTop: "75px", margin: "0 auto", width: "60%" }}>
              <FormSubmitArea>
                {detailRole.editable === 1 && (
                  <div style={{ display: "table", margin: "0 auto" }}>
                    <FlexBox>
                      <FlexBoxItem customStyle={vacationId ? css({ paddingRight: "15px" }) : css({})}>
                        <Button
                          customStyle={css("width: 170px;")}
                          text={vacationId ? "更新" : "休暇を登録"}
                          onClick={formik.handleSubmit}
                        />
                      </FlexBoxItem>
                      {(() => {
                        if (vacationId) {
                          return (
                            <FlexBoxItem>
                              <SecondaryButton
                                customStyle={css("width: 170px;")}
                                text="削除"
                                onClick={() => {
                                  openConfirmDeleteModal(formik.values);
                                }}
                              />
                            </FlexBoxItem>
                          );
                        }
                      })()}
                    </FlexBox>
                  </div>
                )}
                <div style={{ padding: "5px 0px 70px 0px", display: "table", margin: "0 auto" }}>
                  <PrimaryButton
                    text="キャンセル"
                    onClick={() => {
                      history.goBack();
                    }}
                    ghost={true}
                  />
                </div>
              </FormSubmitArea>
            </div>
          </form>
        </div>
        {/* <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={vacationId ? ActionType.UPDATE : ActionType.CREATE}
      /> */}

        <ConfirmModal
          title={vacationId ? "休暇マスターを更新" : "休暇マスタを登録"}
          content={
            vacationId ? "休暇マスターを更新します。よろしいですか。" : "休暇マスタを登録します。よろしいですか？"
          }
          submitText={vacationId ? "更新" : "登録する"}
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          items={[]}
        />

        <ConfirmModal
          title="休暇マスタを削除"
          content="休暇マスタを削除します。よろしいですか？"
          submitText="削除する"
          open={confirmDeleteModalOpen}
          closeHandler={closeConfirmDeleteModal}
          onSubmit={deleteVacationMaster}
          items={[]}
        />

        {/* <ConfirmModal
        title="test"
        content="test"
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={submitConfirmResendModal}
        items={[]}
      /> */}
      </FormContents>
    </BlockUI>
  );
};
export default VacationCreateForm;
