import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import YearlyAgreement36Form from 'components/organismos/AttendAgreement36/YearlyAgreement36Form';

const YearlyAgreement36Page: React.FC = () => (
  <SidebarTemplate
    pageTitle=""
  >
    <YearlyAgreement36Form />
  </SidebarTemplate>
);

export default YearlyAgreement36Page;
