/** @jsx jsx */
import React, { useMemo, useState, useCallback } from "react";
import { css, jsx } from "@emotion/core";
import { Column } from "react-table";
import DataTable from "components/organismos/DataTable/DataTable";
import productColor from "components/styles";
import IconLabelButton from "components/molecules/IconLabelButton";
import BlockUI from "components/molecules/BlockUi";
import ExecutiveDomain from "domain/master/labor/executive";
import { deleteById, downloadCSV } from "api/executive";
import ToastModal from "components/organismos/ToastModal";
import useExecutiveList from "components/organismos/Executive/ExecutiveIndexPage/hooks";
import useToastNotification from "hooks/useToastNotification";
import FormContents from "components/atoms/Form/FormContents";
import { SecondaryButton, SubActionButton } from "components/atoms/Button";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { useHistory } from "react-router-dom";
import { isTablet, isSafari } from "react-device-detect";
import Modal from "components/molecules/Modal";
import FormTitle from "components/atoms/Form/FormTitle";

const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  editBtn: css`
    background-color: ${productColor.editAction};
    padding: 0;
    text-align: center;
    height: 24px;
    border-radius: 3px;
    &:hover,
    &:focus {
      opacity: 0.9;
      background-color: ${productColor.editAction};
      cursor: pointer;
    }
  `,
  deleteBtn: css`
    background-color: ${productColor.deleteAction};
    padding: 0;
    text-align: center;
    height: 24px;
    border-radius: 3px;
    color: white;
    margin-left: 4px;
    line-height: 0;
    font-size: 12px;
    &:hover,
    &:focus {
      opacity: 0.9;
      background-color: ${productColor.deleteAction};
      cursor: pointer;
    }
  `,
  customStyle: css(
    {
      minHeight: "40px",
      display: "flex",
      marginLeft: "0",
      justifyContent: (isSafari && isTablet) || isIpad ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIpad ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIpad ? "0px" : "-40px",
    },
    css`
      button {
        width: auto;
        margin-left: 12px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
};
const ExecutiveIndexLayoutPage: React.FC = () => {
  const history = useHistory();
  const { executiveList, setExecutiveList, isLoading, setIsLoading, detailRole } = useExecutiveList();
  const [valueExecutiveId, setValue] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((item: ExecutiveDomain) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setValue(item.executiveId);
    setConfirmModalOpen(true);
  }, []);

  const downloadFileCSV = useCallback(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    downloadCSV(companyCode);
  }, []);

  const deleteExecutiveById = useCallback(() => {
    setIsLoading(true);
    if (executiveList) {
      deleteById(valueExecutiveId)
        .then((response: any) => {
          setExecutiveList(executiveList.filter((executive) => executive.executiveId !== valueExecutiveId));
          setConfirmModalOpen(false);
          successNotification("削除しました。");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setConfirmModalOpen(false);
          if (error.response && error.response.data && error.response.data.errors) {
            let msgError = "";
            error.response.data.errors.map((item: { defaultMessage: string }) => {
              msgError += `${item.defaultMessage} </br>`;
              return msgError;
            });
            errorNotification(msgError);
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueExecutiveId, executiveList]);

  const columns: Array<Column<ExecutiveDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        accessor: "action",
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: ExecutiveDomain }; data: Array<ExecutiveDomain> }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SubActionButton
              action={0}
              text={detailRole.editable === 1 ? `編集` : `参照`}
              onClick={() => {
                history.push(`/executive/edit/${cell.row.original.companyCode}/${cell.row.original.executiveId}`);
              }}
              width="50px"
            />
            {detailRole.editable === 1 ? (
              <SecondaryButton
                onClick={() => {
                  openConfirmModal(cell.row.original);
                }}
                customStyle={styles.deleteBtn}
                minWidth="50px"
                text="削除"
              />
            ) : null}
          </div>
        ),
      },
      {
        Header: "役職コード",
        accessor: "executiveCode",
      },
      {
        Header: "役職名",
        accessor: "executiveName",
      },
    ],
    [openConfirmModal, history, detailRole],
  );

  const sortBy = React.useMemo(
    () => [
      {
        id: "executiveCode",
        desc: false,
      },
    ],
    [],
  );
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle bold={true} title="役職マスタ" />
        <FormSubmitArea customStyle={styles.customStyle}>
          {detailRole.editable === 1 && (
            <React.Fragment>
              <IconLabelButton onClick={() => history.push("/executive/add")} iconType="addCircle" text="追加" />
              {detailRole.importFlag === 1 && (
                <IconLabelButton
                  onClick={() => history.push("/executive/import")}
                  iconType="addCircle"
                  text="インポート"
                />
              )}
            </React.Fragment>
          )}
          {detailRole.downloadFlag === 1 && (
            <IconLabelButton onClick={downloadFileCSV} iconType="download" text="CSVダウンロード" />
          )}
        </FormSubmitArea>

        <div
          css={css`
            table th:nth-of-type(1) {
              width: 120px;
            }
            table th:nth-of-type(2) {
              width: 300px;
            }
            table th:nth-of-type(3) {
              width: 500px;
            }
            table tbody td:not(:first-of-type),
            table thead th:not(:first-of-type) {
              text-align: left;
              padding-left: ${isIpad ? "10px" : "24px"};
            }
            table thead th {
              font-weight: bold;
            }
          `}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <DataTable
              className="table-no-border-left-right"
              columns={columns}
              data={executiveList}
              isGlobalFilter={true}
              globalSearchStyle={css({
                marginLeft: "37px",
                marginBottom: "10px",
              })}
              sortBy={sortBy}
            />
          </div>
        </div>
        {/* modal delete */}
        <Modal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="役職マスタを削除"
          submitText="削除する"
          note="役職マスタを削除します。よろしいですか？"
          onSubmit={deleteExecutiveById}
          typeSubmit="reject"
        >
          <div style={{ height: "40px" }}></div>
        </Modal>

        {/* ToastModal */}
        <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />
      </FormContents>
    </BlockUI>
  );
};

export default ExecutiveIndexLayoutPage;
