import { useState, useEffect } from 'react';
import moment from 'moment';

import { OptionType } from 'components/atoms/Select';
import { getAllAdminOrAttendanceStaff, getHqStaff, getStaffListForOrgCode, getStaffSelectBoxListForOrgCode } from 'api/staff';
import { getAgreementsForSelectBoxByMonth, getAgreementsForSelectBoxByYear } from 'api/master36Agreement';


export const useAgreementsOptionsByMonth = (targetMonth : Date, functionCode: string): Array<OptionType> => {
  const [agreementsOptions, setAgreementsOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const params = {
      targetMonth: moment(targetMonth).format('YYYY/MM/01'),
      functionCode,
    }; 
    getAgreementsForSelectBoxByMonth(params).then((response: any) => {
      setAgreementsOptions(
        response.map((item: any) => ({
          value: item.agreementsId,
          label: item.agreementsName,
        })));
    });
  }, [targetMonth, functionCode]);
  return agreementsOptions;
};

export const useAgreementsOptionsByYear = (targetYear : number,  functionCode: string): Array<OptionType> => {
    const [agreementsOptions, setAgreementsOptions] = useState<Array<OptionType>>([]);
    useEffect(() => {
        const params = {
            targetYear: targetYear,
            functionCode
          }; 
    getAgreementsForSelectBoxByYear(params).then((response: any) => {
        setAgreementsOptions(
          response.map((item: any) => ({
            value: item.agreementsId,
            label: item.agreementsName,
          })));
      });
    }, [targetYear, functionCode]);
    return agreementsOptions;
};
  

