/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import BodyText from 'components/atoms/BodyText';
import { utilityColor } from 'components/styles';
import { Link } from 'react-router-dom';

const BottomBar = () => {
  const barStyle = {
    wrap: css({
      backgroundColor: utilityColor.white,
      padding: '0 0.5rem',
      position: 'relative',
      marginBottom: '50px', // action bar
    }),
    fixedBottomBar: css({
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '45px',
      background: '#0D4796',
      margin: '0 -0.5rem',
      zIndex: 100,
    }),
    item: css({
      display: 'flex',
      textDecoration: 'none',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    fixedBottomBarButtons: css`
      height: 100%;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        &:first-of-type{
          border-right: 1px solid #fff;
        }
        :nth-of-type(2){
          border-right: 1px solid #fff;
        }
      },
    `,
  };

  return (
    <div css={barStyle.wrap}>
      <div css={barStyle.fixedBottomBar}>
        <FlexBox customStyle={barStyle.fixedBottomBarButtons}>
          <div>
            <Link css={barStyle.item} to="/workAchievement">
              <BodyText color="#fff">勤務状況</BodyText>
            </Link>
          </div>
          <div>
            <Link css={barStyle.item} to="/schedule">
              <BodyText color="#fff">スケジュール</BodyText>
            </Link>
          </div>
          <div>
            <Link css={barStyle.item} to="/personalSetting">
              <BodyText color="#fff">マイページ</BodyText>
            </Link>
          </div>
        </FlexBox>
      </div>
    </div>
  );
};
export default BottomBar;
