/** @jsx jsx */
import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { Column } from "react-table";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import SalaryHistoryDownload from "domain/master/attend/salaryHistoryDownload";
import SubActionButton, {
  ActionButton,
} from "components/atoms/Button/SubActionButton";
import ConfirmModal from "components/organismos/ConfirmModal";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  cancelAchivementHistoryDownloadById,
  deleteAchivementHistoryDownloadById,
  getAchievementDownloadFileCSV,
  getCsvOutput,
  postProcessingDownloadAchievement,
} from "api/achievementConfirm";
import useToastNotification from "hooks/useToastNotification";
import { useCallback } from "react";
import FormTitle from "components/atoms/Form/FormTitle";
import Icon from "components/atoms/Icon";
import { HeadquartersFinalFormContext } from "./HeadquartersFinalForm";

const logoFlag = process.env.REACT_APP_LOGO_FLAG;
const styles = {
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  labelPrimary: css({
    color: "#007BC3",
  }),
  labelError: css({
    color: "#E95562",
  }),
  labelErrorIcon: css({
    marginTop: "10px",
    display: "block",
    textAlign: "center",
    width: "100%",
    height: "15px",
  }),
};

const SalaryHistoryDownloadForm: React.FC<{
  salaryHistoryDownloadList: Array<SalaryHistoryDownload>;
  setSalaryHistoryDownloadList: Dispatch<
    SetStateAction<Array<SalaryHistoryDownload>>
  >;
  setReloadHistory: Dispatch<SetStateAction<boolean>>;
  pageId: number;
  isCancelRequest: boolean;
  setIsCancelRequest: Dispatch<SetStateAction<boolean>>
}> = ({
  salaryHistoryDownloadList,
  setSalaryHistoryDownloadList,
  setReloadHistory,
  pageId,
  isCancelRequest,
  setIsCancelRequest
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [achivementHistoryId, setAchivementHistoryId] = useState("");
  const { successNotification, errorNotification } = useToastNotification();
  const { setLoading } = useContext(HeadquartersFinalFormContext);
  
  const deleteHistoryById = (achivementHistoryId: string) => {
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || '';
    const params = {
      pageId: pageId,
      achievementHistoryDownloadId: achivementHistoryId,
      loginStaffCode
    };
    deleteAchivementHistoryDownloadById(params)
      .then((response) => {
        setSalaryHistoryDownloadList(
          response.map((result: any) => new SalaryHistoryDownload(result))
        );
        setReloadHistory(true);
        successNotification("勤怠データを削除しました。");
        setConfirmModalOpen(!confirmModalOpen);
      })
      .catch(() => {
        setConfirmModalOpen(false);
        errorNotification("勤怠データをまだ削除しません。");
      });
  };

  const cancelHistoryById = (achivementHistoryId: string) => {
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || '';
    const params = {
      pageId: pageId,
      achievementHistoryDownloadId: achivementHistoryId,
      loginStaffCode
    };
    cancelAchivementHistoryDownloadById(params)
      .then((response) => {
        setIsCancelRequest(true);
        setSalaryHistoryDownloadList(
          response.map((result: any) => new SalaryHistoryDownload(result))
        );
        setReloadHistory(true);
      })
      .catch(() => {});
  };

  const handleDownload = useCallback(
    (achivementHistoryId: string, fileName: string) => {
      const params = {
        companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
        achievementHistoryDownloadId: achivementHistoryId,
        pageId: pageId,
        fileName: fileName,
      };
      getAchievementDownloadFileCSV(params, fileName);
    },
    []
  );

  const reloadDownload = (data: any) => {
    const createUser = sessionStorage.getItem("loginUser.staffCode") || '';
    const targetDateFrom = moment(data.periodFrom).format("YYYY-MM-DD");
    const targetDateTo = moment(data.periodTo).format("YYYY-MM-DD");
    const fileName = data.file.split("/")[2];
    const param = {
      targetDateFrom: targetDateFrom,
      targetDateTo: targetDateTo,
      attendFlag: data.attendFlag,
      employmentId: data.employmentId || "",
      viewPeriod: data.viewPeriod === true ? 'monthly' : 'daily',
      closingDate: data.closingDate,
      orgCode: data.orgCode || "",
      outputLayoutId: data.layoutId || "",
      targetYmd: data.targetYmd || "",
      flagDownloadAll: 0,
      pageId: 1,
      createUser: createUser,
      isUpdate: 1,
      underFlag: 0
    };

    setLoading(true);
    postProcessingDownloadAchievement({
      ...param,
      ...{
        targetDate: "",
        staffCode: "",
        achievementDownloadId: data.attendAchievementDownloadId,
        fileName: fileName
      },
    }).then((response: any) => {
      setReloadHistory(true);
      setLoading(false);
      getCsvOutput(
        {
          ...param,
          ...{
            achievementDownloadId: response?.attendAchievementDownloadId || "",
            fileName: fileName,
          },
        },
        response.fileName
      )
        .then((response: any) => {
          setReloadHistory(true);
        })
        .catch((error) => {
          // setLoading(false);
          setReloadHistory(true);
          if(!isCancelRequest) {
            const { response } = error;
            if (response.status === 400) {
              if (response.data.errors && response.data.errors.length > 0) {
                errorNotification(
                  response.data.errors
                    .map((x: any) => x.defaultMessage)
                    .join("<br/>")
                );
              } else if (response.data.defaultMessage) {
                errorNotification(response.data.defaultMessage);
              } else {
                errorNotification(response.data.message);
              }
            } else {
              errorNotification("サーバー側でエラーが発生しました。");
            }
          } else {
            setIsCancelRequest(false);
          }
        });
    });
  };

  const columns: Array<Column<SalaryHistoryDownload>> = useMemo(
    () => [
      {
        Header: "種別",
        accessor: "type",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          return (
            <React.Fragment>
              {cell.row.original.type === 2 ?  "エラー情報出力" : ''}
              {cell.row.original.type === 2 ? '' : cell.row.original.attendFlag === 1 ? "勤務スタッフ出力" : ("所属スタッフ出力")}
              {cell.row.original.type === 2 ? '' : cell.row.original.type === 1 ? "\n(全組織)" : '\n(組織単位)'}
            </React.Fragment>
          )
        }
      },
      {
        Header: "出力レイアウト",
        accessor: "layoutName",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          cell.row.original.layoutName || "-",
      },
      {
        Header: "期間",
        accessor: "period",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          return (
            <React.Fragment>
              {
                cell.row.original.viewPeriod === 1 && ( 
                  moment(cell.row.original.periodTo).format("YYYY/MM") 
                )
              }
              {
                cell.row.original.viewPeriod === 0 && (
                  moment(cell.row.original.periodFrom).format("YYYY/MM/DD")+ " 〜 " + moment(cell.row.original.periodTo).format("YYYY/MM/DD")
                )
              }
            </React.Fragment>
          );
        },
      },
      {
        Header: "組織名",
        accessor: "orgName",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <React.Fragment>
            {cell.row.original.orgFullName}
          </React.Fragment>
        ),
      },
      {
        Header: "雇用形態",
        accessor: "employmentName",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          cell.row.original.employmentName || "-",
      },
      {
        Header: "作成日時",
        accessor: "createDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          moment(cell.row.original.createDate).format("YYYY年MM月DD日 HH:mm:ss") ||
          "-",
      },
      {
        Header: "ステータス",
        accessor: "statusDownload",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          return (
            <React.Fragment>
              {cell.row.original.statusDownload === 0 && logoFlag === "2" && (
                <div>
                   <img src="/cydas_loading_logo_2.gif" alt="loading" width={30} height={30}/>
                  <div>作成中</div>
                </div>
              )}
              {cell.row.original.statusDownload === 0  && logoFlag === "1" && (
                <div>
                  <img src="cydas_loading_logo.gif" height="30" />
                  <div>作成中</div>
                </div>
              )}
              {cell.row.original.statusDownload === 0 && logoFlag === "0" && (
                <div>
                  <img src="./am_loading_logo.gif" height="30" />
                  <div>作成中</div>
                </div>
              )}

              {cell.row.original.statusDownload === 1 && (
                <Link
                  to="#"
                  css={styles.labelPrimary}
                  onClick={() =>
                    handleDownload(
                      cell.row.original.attendAchievementDownloadId,
                      cell.row.original.file
                    )
                  }
                >
                  ダウンロードする
                </Link>
              )}

              {cell.row.original.statusDownload === 2 && (
                <div>
                  <Link to="#" css={styles.labelError} onClick={() => {}}>
                    作成が失敗しました
                  </Link>
                  <Icon
                    customStyle={styles.labelErrorIcon}
                    onClick={() => reloadDownload(cell.row.original.rawData)}
                    type={"reload"}
                    color={"#333333"}
                  />
                </div>
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <React.Fragment>
            {(cell.row.original.statusDownload === 1 || cell.row.original.statusDownload === 2) && (
                <SubActionButton
                  action={ActionButton.DELETE}
                  text="削除"
                  onClick={() => {
                    setConfirmModalOpen(!confirmModalOpen);
                    setAchivementHistoryId(
                      cell.row.original.rawData.attendAchievementDownloadId
                    );
                  }}
                />
              )}
            {(cell.row.original.statusDownload === 0) && (
              <SubActionButton
                action={ActionButton.DELETE}
                text="キャンセルする"
                onClick={() => {
                  cancelHistoryById(cell.row.original.attendAchievementDownloadId)
                }}
              />
            )}
          </React.Fragment>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%" }}>
        <FormTitle
          title="ダウンロードする"
          bold={true}
          customStyle={css({ marginBottom: 0 })}
        />
        <DataTable
          className="table-no-border-left-right tbl-salary-download"
          columns={columns}
          data={salaryHistoryDownloadList}
          isGlobalFilter={false}
          initPageSize={10}
          tableId={1}
        />
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={() => setConfirmModalOpen(!confirmModalOpen)}
        onSubmit={() => deleteHistoryById(achivementHistoryId)}
        title={"勤怠データを削除"}
        // eslint-disable-next-line no-nested-ternary
        content={"勤怠データを削除します。よろしいですか？"}
        // eslint-disable-next-line no-nested-ternary
        submitText={"削除する"}
        typeSubmit={"reject"}
      />
    </React.Fragment>
  );
};

export default SalaryHistoryDownloadForm;
