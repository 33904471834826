import React from "react";
import SidebarTemplate from "components/templates/SidebarTemplate";
import AttendAchievementLockForm from "components/organismos/master/attend/AttendAchievementLockForm";

const AttendAchievementLockPage = () => (
  <SidebarTemplate pageTitle="勤怠ロック">
    <AttendAchievementLockForm />
  </SidebarTemplate>
);

export default AttendAchievementLockPage;
