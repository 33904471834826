import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import DayOutputItemForm from 'components/organismos/master/attend/TimeOutputLayout/DayOutputItemForm';

const DayOutputItemPage: React.FC = () => { 
  return (
    <SidebarTemplate
      pageTitle={`勤務日数項目追加`}
    >
      <DayOutputItemForm />
    </SidebarTemplate>
  );
};

export default DayOutputItemPage;
