/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';

import {
  grayScale, textFontSize, utilityColor, layer,
} from 'components/styles';
import Icon from 'components/atoms/Icon';
import AddOnInput from 'components/atoms/Form/AddOnInput';

// カレンダーの日本語化
registerLocale('ja', ja);

const styles = {
  wrapper: css({
    '.react-datepicker-popper': {
      zIndex: 5,
    },
  }),
  icon: css({
    padding: '8px 0 8px 8px',
  }),
  input: css({
    width: '90px',
    padding: '0 8px',
    boxSizing: 'border-box',
    border: 'transparent',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    outline: 0,
    ':disabled': {
      backgroundColor: grayScale.gray05,
    },
  }),
  inputFullWidth: css({
    width: '100%',
  }),
  calendarContainer: css({
    overflow: 'hidden',
    padding: 0,
    backgroundColor: utilityColor.white,
    boxShadow: layer.layer01,
    zIndex: 99999,
    borderRadius: '4px',
  }),
  disabledAddOnInput: css({
    backgroundColor: grayScale.gray05,
  }),
};

const DatePicker: React.FC<{
  date?: Date;
  changeDate: (arg: Date) => void;
  dateFormat?: string;
  isFullWidth?: boolean;
  selectStart?: boolean;
  selectEnd?: boolean;
  showMonthYearPicker?: boolean;
  startDate?: Date;
  endDate?: Date;
  customStyle?: SerializedStyles;
  disabled?: boolean;
  isShowDateOnDisable? : boolean;
  readOnly? : boolean;
  showYearDropdown? : boolean;
  minDate?: Date
  maxDate?: Date
}> = ({
  date, changeDate,
  dateFormat = 'yyyy/MM/dd',
  isFullWidth = false,
  selectStart = false,
  selectEnd = false,
  showMonthYearPicker = false,
  startDate,
  endDate,
  customStyle,
  disabled = false,
  isShowDateOnDisable = false,
  readOnly = false,
  showYearDropdown = true,
  minDate, maxDate,
}) => (
  <div css={styles.wrapper}>

    <AddOnInput
      input={(
        <ReactDatePicker
        // settings
          locale="ja"
          dateFormat={dateFormat}
          selected={disabled && !isShowDateOnDisable ? undefined : date}
          onChange={(inputDate: Date) => changeDate(inputDate)}
          selectsStart={selectStart}
          selectsEnd={selectEnd}
          startDate={startDate}
          endDate={endDate}
          disabled={disabled}
          readOnly={readOnly}
          minDate={minDate}
          maxDate={maxDate}
        // styling
          css={css(styles.input, isFullWidth && styles.inputFullWidth)}
          showPopperArrow={false}
          showMonthYearPicker={showMonthYearPicker}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
        />
    )}
      addOn={(
        <div css={styles.icon}>
          <Icon type="calendar" color={grayScale.gray100} />
        </div>
      )}
      appended={false}
      customStyle={css(disabled ? styles.disabledAddOnInput : undefined, customStyle)}
    />
  </div>
);

export default DatePicker;
