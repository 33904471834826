import React from 'react';
import { css } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import { useOrgTreesOptionsAll } from 'hooks/useOrgTreesOptions';
import SelectForm from 'components/molecules/SelectForm';

const OrganizationFilter: React.FC<{
  orgCode: string;
  setOrgCode: (orgCode: string) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}> = ({ orgCode, setOrgCode, onMenuOpen, onMenuClose }) => {
  const orgTreesOptions = useOrgTreesOptionsAll(undefined, undefined, false, true);

  return (
    <FlexBox
      customStyle={css({
        padding: '8px',
      })}
    >
      <FlexBoxItem basis="50px">
        <BodyText>
          組織
        </BodyText>
      </FlexBoxItem>
      <FlexBoxItem basis="280px">
        <SelectForm
          name="orgState"
          value={String(orgCode)}
          setValue={(arg: string) => { setOrgCode(arg); }}
          options={orgTreesOptions}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
      </FlexBoxItem>
    </FlexBox>
  );
};

export default OrganizationFilter;
