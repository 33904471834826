import React, { SVGProps } from 'react';

const DiscoveryBoard: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28.3394 12.7595C28.6994 11.7995 27.7594 10.8495 26.7994 11.2195L16.1794 15.1995C15.9394 15.2895 15.7494 15.4395 15.6194 15.6295C15.4294 15.7595 15.2794 15.9495 15.1894 16.1895L11.2094 26.7995C10.8494 27.7595 11.7894 28.7095 12.7494 28.3395L23.3594 24.3595C23.5994 24.2695 23.7894 24.1195 23.9194 23.9295C24.1094 23.7995 24.2594 23.6095 24.3494 23.3695L28.3394 12.7595ZM16.6294 18.0495L21.4994 22.9195L13.6994 25.8395L16.6294 18.0495ZM22.9194 21.5095L25.8394 13.7095L18.0394 16.6295L22.9194 21.5095Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 38C29.94 38 38 29.94 38 20C38 10.06 29.94 2 20 2C10.06 2 2 10.06 2 20C2 29.94 10.06 38 20 38ZM20 40C31.05 40 40 31.05 40 20C40 8.95 31.05 0 20 0C8.95 0 0 8.95 0 20C0 31.05 8.95 40 20 40Z" fill="#0071BC"/>
    <path d="M19 1C19 0.45 19.45 0 20 0C20.55 0 21 0.45 21 1V5C21 5.55 20.55 6 20 6C19.45 6 19 5.55 19 5V1Z" fill="#0071BC"/>
    <path d="M19 35C19 34.45 19.45 34 20 34C20.55 34 21 34.45 21 35V39C21 39.55 20.55 40 20 40C19.45 40 19 39.55 19 39V35Z" fill="#0071BC"/>
    <path d="M39 19C39.55 19 40 19.45 40 20C40 20.55 39.55 21 39 21H35C34.45 21 34 20.55 34 20C34 19.45 34.45 19 35 19H39Z" fill="#0071BC"/>
    <path d="M5 19C5.55 19 6 19.45 6 20C6 20.55 5.55 21 5 21H1C0.45 21 0 20.55 0 20C0 19.45 0.45 19 1 19H5Z" fill="#0071BC"/>
    <path d="M32.1206 34.1196C32.5106 33.7296 33.1406 33.7296 33.5306 34.1196L39.7006 40.2897C40.0906 40.6797 40.0906 41.3097 39.7006 41.6997C39.3106 42.0897 38.6806 42.0897 38.2906 41.6997L32.1206 35.5296C31.7306 35.1396 31.7306 34.5096 32.1206 34.1196Z" fill="#0071BC"/>
  </svg>
);

export default DiscoveryBoard;
