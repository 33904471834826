/** @jsx jsx */

import React, { useState, forwardRef, useEffect, useRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import Icon from 'components/atoms/Icon';
import { utilityColor, grayScale } from 'components/styles';
import { css, jsx } from '@emotion/core';
import Select, { OptionType } from 'components/atoms/Select';
import { components } from 'react-select';
import moment from 'moment';
import { 
  getDate, 
  ModeAction, 
  actionChevron,
  addAYear,
  subAYear
 } from './Helpper'
import {
  Option,
  IndicatorsContainer,
  SingleValueContainer
} from './Component';


import "react-datepicker/dist/react-datepicker.css";
import "./style.scss"

const chevronLeftStyle = css({
  position: 'absolute',
  left: '-30px',
  top: '50%',
  transform: 'translateY(-60%)',
})
const chevronRightStyle = css({
  position: 'absolute',
  right: '-26px',
  top: '50%',
  transform: 'translateY(-60%)',
})
const readOnlyStyle = css({
  pointerEvents: 'none'
})
const MIN_YEAR = 1900;
const MAX_YEAR = 2099;

const CalendarMonth: React.FC<{
  readOnly?:boolean,
  dateFormat?: string,
  date?: Date,
  // setDate: React.Dispatch<React.SetStateAction<Date>>
  setDate: (date: Date) => void,
  modal?:boolean,
  openActionChevron?:boolean,
  modeAction?: ModeAction,
  limitYear?: number
}> = ({
  readOnly = false,
  dateFormat="yyyy/MM",
  date = new Date(),
  setDate,
  modal = false,
  openActionChevron = false,
  modeAction= "month",
  limitYear= 10,
}) => {

  const [openCalendar , setOpenCalendar ] = useState(false);
  const [dateInternal , setDateInternal] = useState<Date>(date);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRefContainer = useRef<HTMLDivElement>(null);
  
  const rangeLimitTime = {
    minTime: moment().set('year', MIN_YEAR).startOf('year').toDate(),
    maxTime: moment().set('year', MAX_YEAR).endOf('year').toDate(),
  }
  const validateYear = (date: Date) => {
    if(date.getFullYear() < MIN_YEAR 
      || date.getFullYear() > MAX_YEAR
      // || optionsYear.filter((item) =>item.value === String(date.getFullYear())).length === 0
    ){
      return false;
    }
    return true;
  }

  const [optionsYear, setOptionsYear] = useState<Array<OptionType>>([])
  useEffect(() => {
    var listYear = [];
    for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
      listYear.push(i);
    }
    setOptionsYear(listYear.map((year) => {
      return {
        value: `${year}`,
        label: `${year}`
      }
    }))
    // const years = Array.from(new Array(20),( val, index) => index + year);
    // setOptionsYear(years);
  }, [])

  useEffect(() => {
    if(openCalendar){
      var months = Array.from(document.getElementsByClassName("react-datepicker__month-text"));
      months.forEach((month, index) => {
        month.innerHTML = `${index+1}`;
      });
    }else{
      if(dateInternal){
        setDate(dateInternal);  
      }else{
        setDate(new Date());  
        setDateInternal(new Date())
      }
    }
  }, [openCalendar])


  const handleClickOutside = (event: any) => {
    if (wrapperRefContainer.current && !wrapperRefContainer.current?.contains(event.target)) {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  });

  return (
    <React.Fragment>
      <div className={`container-input-date-picker calendar-month__container_custom ${modal ? "customCalendarMonth__modal" : ''} ${readOnly ? 'calendar-month__readOnly' : ''}`} 
      ref={wrapperRefContainer}>
        {openActionChevron && (
          <Icon type="chevronLeft" color="" size="24px" verticalAlign="middle" customStyle={css([chevronLeftStyle, readOnly && readOnlyStyle])}
            onClick={() => {
              let prevDate = actionChevron(dateInternal, modeAction, 'previous')
              setDateInternal(prevDate)
              setDate(prevDate);
            }}
          />
        )}
        <DatePicker
          readOnly={readOnly}
          strictParsing
          minDate={rangeLimitTime.minTime}
          maxDate={rangeLimitTime.maxTime}
          open={openCalendar}
          placeholderText="yyyy/mm"
          selected={dateInternal}
          onChange={(date) => {
            setDateInternal(getDate(date));
          }}
          formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
          dateFormat={dateFormat}
          onCalendarClose={() => {
            setOpenCalendar(false);
          }}
          onClickOutside={() => {
            setOpenCalendar(false);
          }}
          onSelect={(date , e:any) =>  {
            if(e.constructor.name !== 'SyntheticEvent') {
              setOpenCalendar(false);
            }
          }}
          onCalendarOpen={() => {
            setOpenCalendar(true);
          }}
          showMonthYearPicker={true}
          popperModifiers={{
            offset: {
              enabled: false,
            },
            flip: { enabled: false },
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseYear,
            increaseYear,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
          }) => {
            return (
              <React.Fragment>
                <div className="calendar__year">
                  <span className="calendar__arrow calendar__arrow--left" onClick={() => {
                    let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                    tmpDate = subAYear(tmpDate);
                    if(validateYear(tmpDate)){
                      decreaseYear();
                    }
                  }}></span>
                  <div css={css`
                      div{
                        justify-content: center;
                        border: 0;
                        :hover{
                          border: 0;
                          cursor: pointer;
                        }
                      }
                    `}>
                      <Select
                        name="select-month"
                        className="calendar__year-value"
                        value={String(date.getFullYear())}
                        setValue={(arg: string) => {
                          changeYear(Number(arg));
                        }}
                        options={optionsYear}
                        placeholder="チーフ"
                        controlStyle={{
                          width: '70px',
                          border: '0',
                          backgroundColor: 'transparent',
                        }}
                        components={{ Option, IndicatorsContainer, SingleValueContainer }}
                        maxMenuHeight={200}
                        customMenuStyle={{
                          marginTop: '0',
                        }}
                        isSearchable={false}
                        
                      />
                  </div>
                  <span className="calendar__arrow calendar__arrow--right" onClick={() => {
                     let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                     tmpDate = addAYear(tmpDate);
                     if(validateYear(tmpDate)){
                      increaseYear();
                     }
                  }}></span>
                </div>
              </React.Fragment>
            )
          }}
        >
          <div className="label-current" onClick={() => {
            setDateInternal(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
            setOpenCalendar(false);
          }}>今月</div>
        </DatePicker>
        <div 
          className="icf-calendar" 
          onClick={() => setOpenCalendar(true)}
          ref={wrapperRef}
        >
          <Icon type="date" color={utilityColor.black} />
        </div>
        {openActionChevron && (
          <Icon type="chevronRight" color="" size="24px" verticalAlign="middle" customStyle={css([chevronRightStyle, readOnly && readOnlyStyle])}
            onClick={() => {
              let nextDate = actionChevron(dateInternal, modeAction, 'next')
              setDateInternal(nextDate);
              setDate(nextDate);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CalendarMonth;