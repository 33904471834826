/** @jsx jsx */
import React, { ReactNode } from "react";
import { css, jsx } from "@emotion/core";
import { EventWrapperProps } from "react-big-calendar";

import { productColor, textColor, textFontSize, utilityColor } from "components/styles";
import { HopeShiftDomain } from "domain/master/general/hopeShift";

const screenWidth = window.screen.width;
const createStyle = (color: string = productColor.primaryM95, hopeShift?: HopeShiftDomain) =>
  css(
    {
      fontSize: textFontSize.re,
      position: "absolute",
      top: 0,
      width: "46px",
      height: "50px",
      zIndex: -1,
      padding: "5px 0px 5px 6px",
      "& > *": {
        backgroundColor: color || productColor.primaryM80,
        color: textColor.main,
        textAlign: "center",
        height: "100%",
        borderRadius: "4px",
        border: hopeShift?.fixStatus === 9 && hopeShift?.hopeShiftPatternType !== 0 ? "1px solid #0D4796" : "none",
      },
    },
    screenWidth > 500
      ? { left: "50%", transform: "translateX(calc(-50% - 2px))" } // fix Ipad display
      : {},
  );

/**
 * イベントラッパー
 * - eventにcolorが指定されている場合に設定する
 */
const ScheduleEventWrapper: React.FC<EventWrapperProps> = (props) => {
  const { children, event } = props as EventWrapperProps & {
    children: ReactNode;
    event: Event & { color?: string; hopeShift?: HopeShiftDomain };
  };

  return <div css={createStyle(event.color, event.hopeShift)}>{children}</div>;
};

export default ScheduleEventWrapper;
