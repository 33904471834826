/** @jsx jsx */

import React, { PropsWithChildren } from "react";

import productColor, { utilityColor, customButtonColor } from "components/styles";
import { ButtonProps } from "./BaseButton";
import { css, jsx } from "@emotion/core";
import useIsMobile from "hooks/useIsMobile";

interface ActionButtonProps extends ButtonProps {
  action: ActionButton;
  width?: string;
  height?: string;
  marginLeft?: string;
}

export enum ActionButton {
  EDIT = 0,
  DELETE = 1,
  COPY = 2,
  Approve = 3,
  Comfirm = 4,
}

const hoverStyle = (color: string) =>
  css({
    ":hover": {
      backgroundColor: color + " !important",
    },
    ":focus": {
      backgroundColor: color + " !important",
    },
  });

const SubActionButton = ({
  children,
  text,
  onClick,
  disabled,
  action,
  width = "",
  customStyle,
  marginLeft,
}: PropsWithChildren<ActionButtonProps>) => {
  const isMobile = useIsMobile();
  let bgColor = "";
  let bgColorHover = "";
  if (disabled) {
    bgColor = customButtonColor.disabledSubButton;
  } else {
    switch (action) {
      case 0:
        bgColor = productColor.editAction;
        bgColorHover = isMobile ? "#0067C0" : productColor.editActionHover;
        break;
      case 1:
        bgColor = productColor.deleteAction;
        bgColorHover = productColor.deleteActionHover;
        break;
      case 2:
        bgColor = productColor.copyAction;
        bgColorHover = productColor.copyActionHover;
        break;
      case 3:
        bgColor = productColor.approveAction;
        bgColorHover = isMobile ? "#00B16B" : productColor.approveActionHover;
        break;
      case 4:
        bgColor = productColor.confirmAction;
        bgColorHover = productColor.confirmActionHover;
        break;
      default:
        bgColor = productColor.editAction;
        bgColorHover = productColor.editActionHover;
    }
  }

  return (
    <button
      type="button"
      onClick={() => (onClick ? onClick() : () => {})}
      disabled={disabled}
      style={{
        backgroundColor: bgColor,
        color: utilityColor.white,
        borderRadius: "3px",
        height: "24px",
        border: "none",
        cursor: disabled ? "not-allowed" : "pointer",
        fontSize: "12px",
        whiteSpace: "nowrap",
        marginLeft: marginLeft ? marginLeft : "",
        ...customStyle,
      }}
      css={[hoverStyle(bgColorHover), width ? { width: width } : {}]}
    >
      {children || text}
    </button>
  );
};

export default SubActionButton;
