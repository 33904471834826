/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import ForgotPasswordSubmitInputForm from 'components/organismos/login/ForgotPasswordSubmitForm';
import LoginFormContainer from 'components/organismos/login/LoginFormContainer';

const ForgotPasswordSubmitPage: React.FC = () => (
  <React.Fragment>
    <LoginFormContainer 
      titleForm="パスワードの変更"
      noteForm= "受信したメールアドレスに記載されている認証コードと新しいパスワードを入力して下さい。<br/>メールが届かない場合、迷惑メールフォルダに無いか確認して下さい。<br/>「hinata.resduc.com」のドメインからのメールを受信許可に設定して下さい。"
    >
      <ForgotPasswordSubmitInputForm/>
    </LoginFormContainer>  
  </React.Fragment>
);

export default ForgotPasswordSubmitPage;
