/** @jsx jsx */
import React, { useEffect, useState } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import {
  timeDisplayFormat,
  timeDisplayFormatLabel,
  roundMethodOfThirdDecimalPlace,
  roundMethodOfThirdDecimalPlaceLabel,
  dispStampTimeOrg,
  dispStampTimeOrgLabel,
  midnightBreakdownDisplay,
  midnightBreakdownDisplayLabel,
  useFaceStampLabel,
  useFaceStamp,
  useBarcodeStampLabel,
  useBarcodeStamp,
  useICCardStampLabel,
  useICCardStamp,
  useRemindErrorLabel,
  useRemindErrorDisplay,
  hopeShiftLimitFlagLabel,
  hopeShiftLimitFlag,
  mwsDisplayFlagLabel,
  mwsDisplayFlag,
  mwsDisplayStartDowLabel,
  mwsDisplayStartDow,
  shiftCreateFlag,
  shiftCreateFlagLabel,
  halhTimeHolidayDuplicateCheck,
  halhTimeHolidayDuplicateCheckLabel,
  autoPaidUseAttendanceRate,
  autoPaidUseAttendanceRateLabel,
  supportTransportationFlagCheckLabel,
  autoExtraHourlySalaryFlagCheckLabel,
  costRoundingFlagCheckLabel,
  monthlyProratedFlagCheckLabel,
} from "domain/master/labor/timeSetting";
import Button from "components/atoms/Button";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import productColor, { utilityColor, grayScale, textFontSize } from "components/styles";
import HourInput from "components/atoms/Form/HourInput";
import FlexBox from "components/atoms/FlexBox";
import FormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import FormLabel from "components/atoms/Form/FormLabel";
import SelectForm from "components/molecules/SelectForm";
import BodyText from "components/atoms/BodyText";
import ClickableLink from "components/atoms/ClickableLink";
import { useSettingUpdateForm } from "./hooks";
import { css, jsx } from "@emotion/core";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import { useHistory } from "react-router-dom";
import Modal from "components/molecules/Modal";
import BlockUI from "components/molecules/BlockUi";
import { OptionType } from "components/atoms/Select";
import FlexBoxItem from "components/atoms/FlexBoxItem";

const styles = {
  formContainer: css({
    width: "700px",
    margin: "auto",
    border: "0",
  }),
  hourInput: css`
    input {
      flex: auto;
      width: 50px;
    }
  `,
  radio: css`
    label span:first-child {
      padding-bottom: 3px;
    }
    label {
      margin-right: 20px;
    }
  `,
  label: css({
    position: "relative",
    top: "20px",
  }),
  help: css({
    color: "red",
    bottom: "-13px",
    left: "10px",
    position: "relative",
  }),
  span: css({
    marginRight: "20px",
    marginLeft: "4px",
    position: "relative",
    top: "-2px",
    fontSize: "14px",
    color: "#666666",
  }),
  addon: css({
    color: grayScale.gray100,
    padding: "7px",
  }),
  flexBox: css({
    marginBottom: "15px",
  }),
  linkOrg: css({
    color: productColor.primary,
    cursor: "pointer",
    "&:hover": {
      color: productColor.primaryHoverText,
    },
  }),
};

const listDays = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const listMonths = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const validateData = (value: number, valueDefault: string) => {
  return value > 0 ? (value < 10 ? "0" + Number(value) : value) : valueDefault;
};

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

const TimeSettingForm = () => {
  const history = useHistory();

  const { formik, confirmModalOpen, closeConfirmModal, isLoading, detailRole } = useSettingUpdateForm();

  const [monthFormOptions, setMonthFormOptions] = useState<Array<OptionType>>([]);
  const [dayFormOptions, setDayFormOptions] = useState<Array<OptionType>>([]);
  let nursingYearStartMonthDefault = validateData(formik.values.nursingYearStartMonth, "04");
  let nursingYearStartDayDefault = validateData(formik.values.nursingYearStartDay, "01");
  let nursingYearEndMonthDefault = validateData(formik.values.nursingYearEndMonth, "03");
  let nursingYearEndDayDefault = validateData(formik.values.nursingYearEndDay, "31");
  let nursingCareYearStartMonthDefault = validateData(formik.values.childNursingYearStartMonth, "04");
  let nursingCareYearStartDayDefault = validateData(formik.values.childNursingYearStartDay, "01");
  let nursingCareYearEndMonthDefault = validateData(formik.values.childNursingYearEndMonth, "03");
  let nursingCareYearEndDayDefault = validateData(formik.values.childNursingYearEndDay, "31");

  let [nursingYearStartDayDefaultLimit, setNursingYearStartDayDefaultLimit] = useState(31);
  let [nursingYearEndDayDefaultLimit, setNursingYearEndDayDefaultLimit] = useState(31);
  let [nursingCareYearStartDayDefaultLimit, setNursingCareYearStartDayDefaultLimit] = useState(31);
  let [nursingCareYearEndDayDefaultLimit, setNursingCareYearEndDayDefaultLimit] = useState(31);

  useEffect(() => {
    setNursingYearStartDayDefaultLimit(daysInMonth(Number(nursingYearStartMonthDefault), new Date().getFullYear()));
    setNursingYearEndDayDefaultLimit(daysInMonth(Number(nursingYearEndMonthDefault), new Date().getFullYear() + 1));
    setNursingCareYearStartDayDefaultLimit(
      daysInMonth(Number(nursingCareYearStartMonthDefault), new Date().getFullYear()),
    );
    setNursingCareYearEndDayDefaultLimit(
      daysInMonth(Number(nursingCareYearEndMonthDefault), new Date().getFullYear() + 1),
    );
  }, [
    nursingYearStartMonthDefault,
    nursingYearEndMonthDefault,
    nursingCareYearStartMonthDefault,
    nursingCareYearEndMonthDefault,
  ]);

  useEffect(() => {
    setMonthFormOptions(
      listMonths.map((Month: any) => ({
        value: Month,
        label: Month,
      })),
    );
    setDayFormOptions(
      listDays.map((Day: any) => ({
        value: Day,
        label: Day,
      })),
    );
  }, []);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents
        customStyle={{
          paddingBottom: "24px",
        }}
      >
        <FormTitle bold={true} title="勤怠設定マスタ" />
        <form onSubmit={formik.handleSubmit} css={styles.formContainer}>
          <div>
            <div style={{ borderBottom: "1px solid #dcdcdc" }}>
              <FormTitle
                title="エラー・アラート勤務通知設定"
                bold={true}
                customStyle={css({ margin: "26px 0 16px 0" })}
              />
              {/* remindDayBeforeAndAfter */}
              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="通知機能"
                  items={[
                    {
                      label: useRemindErrorLabel[useRemindErrorDisplay.useRemindError1],
                      value: "1",
                    },
                    {
                      label: useRemindErrorLabel[useRemindErrorDisplay.useRemindError0],
                      value: "0",
                    },
                  ]}
                  name="useRemindError"
                  value={String(formik.values.useRemindError)}
                  setValue={(e) => {
                    formik.setFieldValue("useRemindError", Number(e.target.value));
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>
              {formik.values.useRemindError === 1 && (
                <FormField>
                  <FormLayout
                    label={<FormLabel label="通知タイミング設定" />}
                    input={
                      <FlexBox customStyle={styles.hourInput}>
                        <FormLabel label="毎月、勤怠締め日の" customStyle={css({ margin: "0 15px 0 0" })} />
                        <HourInput
                          name="remindDayBeforeAndAfter"
                          value={formik.values.remindDayBeforeAndAfter ? formik.values.remindDayBeforeAndAfter : 0}
                          customStyleLabel={css({ width: "60px" })}
                          onChange={formik.handleChange}
                          min={1}
                          max={31}
                          widthInput="35px"
                          height="42px"
                        />
                        <BodyText customStyle={css({ marginLeft: "15px" })}>
                          日前後、エラー・アラートがあった場合はログイン後に警告とする
                        </BodyText>
                      </FlexBox>
                    }
                  />
                </FormField>
              )}
            </div>

            {/* timeDisplayFormat */}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <div style={{ width: "215px" }}>
                <VerticalLabelSelectForm
                  label="時間表示形式"
                  name="timeDisplayFormat"
                  value={String(formik.values.timeDisplayFormat)}
                  setValue={(val: string) => formik.setFieldValue("timeDisplayFormat", val)}
                  options={[
                    {
                      label: timeDisplayFormatLabel[timeDisplayFormat.time10],
                      value: "0",
                    },
                    {
                      label: timeDisplayFormatLabel[timeDisplayFormat.time60],
                      value: "1",
                    },
                  ]}
                  isSearchable={false}
                />
              </div>
            </FormField>
            {String(formik.values.timeDisplayFormat) === timeDisplayFormat.time10.toString() && (
              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="小数点第3位の処理設定"
                  items={[
                    {
                      label: roundMethodOfThirdDecimalPlaceLabel[roundMethodOfThirdDecimalPlace.roundDecimal0],
                      value: "0",
                    },
                    {
                      label: roundMethodOfThirdDecimalPlaceLabel[roundMethodOfThirdDecimalPlace.roundDecimal1],
                      value: "1",
                    },
                    {
                      label: roundMethodOfThirdDecimalPlaceLabel[roundMethodOfThirdDecimalPlace.roundDecimal2],
                      value: "2",
                    },
                  ]}
                  name=""
                  value={String(formik.values.roundMethodOfThirdDecimalPlace)}
                  setValue={(e) => {
                    formik.setFieldValue("roundMethodOfThirdDecimalPlace", e.target.value);
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>
            )}

            {/* dispStampTimeOrg */}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="実打刻時間を表示"
                items={[
                  {
                    label: dispStampTimeOrgLabel[dispStampTimeOrg.disp],
                    value: "1",
                  },
                  {
                    label: dispStampTimeOrgLabel[dispStampTimeOrg.noDisp],
                    value: "0",
                  },
                ]}
                name="dispStampTimeOrg"
                value={String(formik.values.dispStampTimeOrg)}
                setValue={(e) => {
                  formik.setFieldValue("dispStampTimeOrg", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            {/* midnightBreakdownDisplay */}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="深夜時間の内訳表示"
                items={[
                  {
                    label: midnightBreakdownDisplayLabel[midnightBreakdownDisplay.midnight1],
                    value: "true",
                  },
                  {
                    label: midnightBreakdownDisplayLabel[midnightBreakdownDisplay.midnight0],
                    value: "false",
                  },
                ]}
                name="midnightBreakdownDisplay"
                value={String(formik.values.midnightBreakdownDisplay)}
                setValue={(e) => {
                  formik.setFieldValue("midnightBreakdownDisplay", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            {/* longestWorkingHours */}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <div>
                <FormLayout
                  label={<FormLabel label="1日の連続最長勤務時間" />}
                  input={
                    <FlexBox customStyle={styles.hourInput}>
                      <HourInput
                        name="longestWorkingHours"
                        value={formik.values.longestWorkingHours ? formik.values.longestWorkingHours : 0}
                        label="時間"
                        customStyleLabel={css({ width: "60px" })}
                        onChange={formik.handleChange}
                        min={12}
                        max={48}
                        widthInput="35px"
                        height="42px"
                      />
                      <BodyText color={utilityColor.error} size="sm">
                        この時間以上を経過して打刻すると出勤打刻となります。
                      </BodyText>
                    </FlexBox>
                  }
                />
              </div>
            </FormField>
            <FormTitle title="タイムレコーダーの打刻設定" bold={true} customStyle={css({ margin: "26px 0 16px 0" })} />
            {/* useFaceStamp  */}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="顔認証打刻機能"
                items={[
                  {
                    label: useFaceStampLabel[useFaceStamp.useFaceStamp1],
                    value: "1",
                  },
                  {
                    label: useFaceStampLabel[useFaceStamp.useFaceStamp0],
                    value: "0",
                  },
                ]}
                name="useFaceStamp"
                value={String(formik.values.useFaceStamp)}
                setValue={(e) => {
                  formik.setFieldValue("useFaceStamp", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            {/* useBarcodeStamp*/}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="ID打刻機能"
                items={[
                  {
                    label: useBarcodeStampLabel[useBarcodeStamp.useBarcodeStamp1],
                    value: "1",
                  },
                  {
                    label: useBarcodeStampLabel[useBarcodeStamp.useBarcodeStamp0],
                    value: "0",
                  },
                ]}
                name="useBarcodeStamp"
                value={String(formik.values.useBarcodeStamp)}
                setValue={(e) => {
                  formik.setFieldValue("useBarcodeStamp", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            {/* useICCard */}
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="ICカード打刻機能"
                items={[
                  {
                    label: useICCardStampLabel[useICCardStamp.useICCardStamp1],
                    value: "1",
                  },
                  {
                    label: useICCardStampLabel[useICCardStamp.useICCardStamp0],
                    value: "0",
                  },
                ]}
                name="useICCardStamp"
                value={String(formik.values.useICCardStamp)}
                setValue={(e) => {
                  formik.setFieldValue("useICCardStamp", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            <FormTitle title="休暇設定" bold={true} customStyle={css({ margin: "26px 0 16px 0" })} />
            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="半休・時間休と打刻実績との時間重複"
                items={[
                  {
                    label: halhTimeHolidayDuplicateCheckLabel[halhTimeHolidayDuplicateCheck.error],
                    value: "0",
                  },
                  {
                    label: halhTimeHolidayDuplicateCheckLabel[halhTimeHolidayDuplicateCheck.noError],
                    value: "1",
                  },
                ]}
                name="halhTimeHolidayDuplicateCheck"
                value={String(formik.values.halhTimeHolidayDuplicateCheck)}
                setValue={(e) => {
                  formik.setFieldValue("halhTimeHolidayDuplicateCheck", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            <FormField customStyle={css({ marginBottom: "10px" })}>
              <RadioSelectForm
                label="有給休暇自動付与時の出勤率"
                items={[
                  {
                    label: autoPaidUseAttendanceRateLabel[autoPaidUseAttendanceRate.use],
                    value: "1",
                  },
                  {
                    label: autoPaidUseAttendanceRateLabel[autoPaidUseAttendanceRate.noUse],
                    value: "0",
                  },
                ]}
                name="autoPaidUseAttendanceRate"
                value={String(formik.values.autoPaidUseAttendanceRate)}
                setValue={(e) => {
                  formik.setFieldValue("autoPaidUseAttendanceRate", e.target.value);
                }}
                additionStyle={styles.radio}
              />
            </FormField>
            <div style={String(formik.values.autoPaidUseAttendanceRate) === "1" ? {} : { display: "none" }}>
              <FormField>
                <FormLayout
                  label={<FormLabel label="付与算定設定" />}
                  input={
                    <FlexBox customStyle={styles.hourInput}>
                      <FormLabel label="出勤率" customStyle={css({ margin: "0 15px 0 0" })} />
                      <HourInput
                        name="attendanceRate"
                        value={formik.values.attendanceRate ? formik.values.attendanceRate : 80}
                        customStyleLabel={css({ width: "60px" })}
                        onChange={formik.handleChange}
                        min={1}
                        max={100}
                        widthInput="35px"
                        height="42px"
                      />
                      <BodyText customStyle={css({ marginLeft: "15px" })}>%以上で付与</BodyText>
                      <BodyText customStyle={css({ marginLeft: "15px" })} color={utilityColor.note} size="sm">
                        付与日以前の実績が1年未満の場合、実績未登録期間についてはみなし出勤とします
                      </BodyText>
                    </FlexBox>
                  }
                />
              </FormField>
            </div>

            <div style={detailRole.usabilityGeneralItem1 === 1 ? {} : { display: "none" }}>
              <FlexBox>
                <FormTitle title="人件費設定" bold={true} customStyle={css({ margin: "16px 0 16px 0" })} />
                <BodyText customStyle={css({ marginLeft: "15px" })} color={utilityColor.note} size="sm">
                  人件費設定項目を変更すると、 登録時に当月分の人件費を再計算します。
                </BodyText>
              </FlexBox>

              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="人件費端数処理設定"
                  items={[
                    {
                      label: costRoundingFlagCheckLabel[0],
                      value: "0",
                    },
                    {
                      label: costRoundingFlagCheckLabel[1],
                      value: "1",
                    },
                    {
                      label: costRoundingFlagCheckLabel[4],
                      value: "4",
                    },
                  ]}
                  name="costRoundingFlag"
                  value={String(formik.values.costRoundingFlag)}
                  setValue={(e) => {
                    formik.setFieldValue("costRoundingFlag", e.target.value);
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>

              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="月給者人件費計上設定"
                  items={[
                    {
                      label: monthlyProratedFlagCheckLabel[0],
                      value: "0",
                    },
                    {
                      label: monthlyProratedFlagCheckLabel[1],
                      value: "1",
                    },
                    {
                      label: monthlyProratedFlagCheckLabel[2],
                      value: "2",
                    },
                  ]}
                  name="monthlyProratedFlag"
                  value={String(formik.values.monthlyProratedFlag)}
                  setValue={(e) => {
                    formik.setFieldValue("monthlyProratedFlag", e.target.value);
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>

              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="月給・日給者基礎賃金未入力時自動算出設定"
                  items={[
                    {
                      label: autoExtraHourlySalaryFlagCheckLabel[0],
                      value: "0",
                    },
                    {
                      label: autoExtraHourlySalaryFlagCheckLabel[1],
                      value: "1",
                    },
                  ]}
                  name="autoExtraHourlySalaryFlag"
                  value={String(formik.values.autoExtraHourlySalaryFlag)}
                  setValue={(e) => {
                    formik.setFieldValue("autoExtraHourlySalaryFlag", e.target.value);
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>

              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="月額交通費計上設定"
                  items={[
                    {
                      label: monthlyProratedFlagCheckLabel[0],
                      value: "0",
                    },
                    {
                      label: monthlyProratedFlagCheckLabel[1],
                      value: "1",
                    },
                    {
                      label: monthlyProratedFlagCheckLabel[2],
                      value: "2",
                    },
                  ]}
                  name="monthlyTransportationProratedFlag"
                  value={String(formik.values.monthlyTransportationProratedFlag)}
                  setValue={(e) => {
                    formik.setFieldValue("monthlyTransportationProratedFlag", e.target.value);
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>

              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="支援交通費計上設定"
                  items={[
                    {
                      label: supportTransportationFlagCheckLabel[0],
                      value: "0",
                    },
                    {
                      label: supportTransportationFlagCheckLabel[1],
                      value: "1",
                    },
                  ]}
                  name="supportTransportationFlag"
                  value={String(formik.values.supportTransportationFlag)}
                  setValue={(e) => {
                    formik.setFieldValue("supportTransportationFlag", e.target.value);
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>
            </div>

            <FlexBox>
              <FormTitle title="システムデフォルト設定" bold={true} customStyle={css({ margin: "16px 0" })} />
              <BodyText size="sm" customStyle={css({ marginLeft: "25px" })}>
                <FlexBox>
                  <ClickableLink to="/masterOrganizeSetting" padding="0 0">
                    <span style={{ fontSize: textFontSize.sm }}>組織設定マスタ</span>
                  </ClickableLink>
                  <span>で組織個別に設定されている場合は組織の設定を優先します。</span>
                </FlexBox>
              </BodyText>
            </FlexBox>

            <FormField>
              <RadioSelectForm
                label="承認者の、自身の申請の承認を許可"
                items={[
                  {
                    label: "する ",
                    value: "0",
                  },
                  {
                    label: "しない ",
                    value: "1",
                  },
                ]}
                name="approverNotSkipApproveFlag"
                value={String(formik.values.approverNotSkipApproveFlag === true ? "1" : "0")}
                setValue={(e) => {
                  formik.setFieldValue("approverNotSkipApproveFlag", Number(e.target.value) === 1 ? true : false);
                }}
                additionStyle={styles.radio}
              />
            </FormField>

            {/* dispStartHour and dispHour */}
            <FormField>
              <FormLayout
                label={<FormLabel label="日次勤怠表示時間" />}
                input={
                  <FlexBox>
                    <div style={{ float: "left", marginRight: "32px" }}>
                      <HourInput
                        name="dispStartHour"
                        value={formik.values.dispStartHour ? formik.values.dispStartHour : 0}
                        label="時から"
                        onChange={formik.handleChange}
                        min={0}
                        max={23}
                        widthInput="35px"
                        height="42px"
                      />
                    </div>
                    <div style={{ width: "65px" }}>
                      <VerticalLabelSelectForm
                        label=""
                        name="orgCode"
                        value={String(formik.values.dispHour)}
                        options={[
                          {
                            label: "12",
                            value: "12",
                          },
                          {
                            label: "18",
                            value: "18",
                          },
                          {
                            label: "24",
                            value: "24",
                          },
                        ]}
                        setValue={(hour) => {
                          formik.setFieldValue("dispHour", hour);
                        }}
                        description=""
                        width="65px"
                        isSearchable={false}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <BodyText color={grayScale.gray100}> 時間 </BodyText>
                    </div>
                  </FlexBox>
                }
              />
            </FormField>

            {/* mwsDisplayFlag */}
            <FormField>
              <VerticalLabelSelectForm
                label="月次勤怠表示設定"
                name="mwsDisplayFlag"
                value={String(formik.values.mwsDisplayFlag)}
                setValue={(val: string) => formik.setFieldValue("mwsDisplayFlag", val)}
                options={[
                  {
                    label: mwsDisplayFlagLabel[mwsDisplayFlag.mwsDisplayFlag0],
                    value: "0",
                  },
                  {
                    label: mwsDisplayFlagLabel[mwsDisplayFlag.mwsDisplayFlag1],
                    value: "1",
                  },
                ]}
                isSearchable={false}
              />
              {String(formik.values.mwsDisplayFlag) === mwsDisplayFlag.mwsDisplayFlag0.toString() && (
                <FlexBox customStyle={css({ margin: "auto 0 0 40px" })}>
                  <div style={{ width: "65px" }}>
                    <VerticalLabelSelectForm
                      label=""
                      name="mwsDisplayStartDow"
                      value={String(formik.values.mwsDisplayStartDow)}
                      width="65px"
                      options={[
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                          value: "0",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                          value: "1",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                          value: "2",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                          value: "3",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                          value: "4",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                          value: "5",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                          value: "6",
                        },
                      ]}
                      setValue={(m) => {
                        formik.setFieldValue("mwsDisplayStartDow", m);
                      }}
                      isSearchable={false}
                    />
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    <BodyText color={grayScale.gray100}>曜日～1週間表示</BodyText>
                  </div>
                </FlexBox>
              )}
              {String(formik.values.mwsDisplayFlag) === mwsDisplayFlag.mwsDisplayFlag1.toString() && (
                <FlexBox
                  customStyle={css({
                    marginTop: "auto",
                    paddingBottom: "4px",
                    marginLeft: "40px",
                  })}
                >
                  <HourInput
                    name="mwsDisplayStartDay1"
                    value={formik.values.mwsDisplayStartDay1 ? formik.values.mwsDisplayStartDay1 : 0}
                    label="日～1ヶ月表示"
                    onChange={formik.handleChange}
                    min={0}
                    max={31}
                    widthInput="35px"
                    height="42px"
                  />
                  <BodyText color={utilityColor.error} size="sm">
                    「0」を入力すると月末という扱いになります。
                  </BodyText>
                </FlexBox>
              )}
            </FormField>

            {/* hopeShiftLimitFlag*/}
            <FormField>
              <VerticalLabelSelectForm
                label="希望シフト提出期限"
                name="hopeShiftLimitFlag"
                value={String(formik.values.hopeShiftLimitFlag)}
                setValue={(val: string) => formik.setFieldValue("hopeShiftLimitFlag", val)}
                options={[
                  {
                    label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag0],
                    value: "0",
                  },
                  {
                    label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag1],
                    value: "1",
                  },
                  {
                    label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag2],
                    value: "2",
                  },
                  {
                    label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag3],
                    value: "3",
                  },
                ]}
                isSearchable={false}
              />
            </FormField>

            {/* - hopeShiftLimitFixedDay1 */}
            {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag1.toString() && (
              <FormField>
                <FlexBox>
                  <div style={{ marginRight: "12px" }}>
                    <BodyText> 前月 </BodyText>
                  </div>
                  <HourInput
                    name="hopeShiftLimitFixedDay1"
                    value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                    label="日まで"
                    onChange={formik.handleChange}
                    min={0}
                    max={31}
                    widthInput="35px"
                    height="42px"
                  />
                  <BodyText color={utilityColor.error} size="sm">
                    「0」を入力すると月末という扱いになります。
                  </BodyText>
                </FlexBox>
              </FormField>
            )}

            {/* hopeShiftLimitFixedDay1 - hopeShiftLimitFixedDay2*/}
            {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag2.toString() && (
              <React.Fragment>
                <div>
                  <FormField>
                    <FlexBox>
                      <div style={{ marginRight: "35px" }}>
                        <BodyText> 1日～15日分シフト </BodyText>
                      </div>
                      <div style={{ marginRight: "12px" }}>
                        <BodyText> 前月 </BodyText>
                      </div>
                      <HourInput
                        name="hopeShiftLimitFixedDay1"
                        value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                        label="日まで"
                        onChange={formik.handleChange}
                        min={0}
                        max={31}
                        widthInput="35px"
                        height="42px"
                      />
                    </FlexBox>
                  </FormField>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <FormField>
                    <FlexBox>
                      <div style={{ marginRight: "30px" }}>
                        <BodyText> 16日～末日分シフト </BodyText>
                      </div>
                      <div style={{ marginRight: "12px" }}>
                        <BodyText> 当月 </BodyText>
                      </div>
                      <HourInput
                        name="hopeShiftLimitFixedDay2"
                        value={formik.values.hopeShiftLimitFixedDay2 ? formik.values.hopeShiftLimitFixedDay2 : 0}
                        label="日まで"
                        onChange={formik.handleChange}
                        min={0}
                        max={31}
                        widthInput="35px"
                        height="42px"
                      />
                    </FlexBox>
                  </FormField>
                </div>

                <BodyText color={utilityColor.error} size="sm">
                  「0」を入力すると月末という扱いになります。
                </BodyText>
              </React.Fragment>
            )}

            {/* hopeShiftWeekStartDow - hopeShiftWeekOffset - hopeShiftWeekLimitDow*/}
            {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag3.toString() && (
              <React.Fragment>
                <FormField customStyle={css({ paddingBottom: "0" })}>
                  <FlexBox>
                    <div style={{ marginRight: "12px" }}>
                      <BodyText>対象シフト</BodyText>
                    </div>
                    <VerticalLabelSelectForm
                      label=""
                      name="hopeShiftWeekStartDow"
                      width="60px"
                      value={String(formik.values.hopeShiftWeekStartDow)}
                      options={[
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                          value: "0",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                          value: "1",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                          value: "2",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                          value: "3",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                          value: "4",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                          value: "5",
                        },
                        {
                          label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                          value: "6",
                        },
                      ]}
                      setValue={(m) => {
                        formik.setFieldValue("hopeShiftWeekStartDow", m);
                      }}
                      isSearchable={false}
                    />
                    <div style={{ marginLeft: "12px" }}>
                      <BodyText>曜日～1週間のシフト</BodyText>
                    </div>
                  </FlexBox>
                </FormField>
                <FormField customStyle={css({ paddingBottom: "0" })}>
                  <FlexBox>
                    <HourInput
                      name="hopeShiftWeekOffset"
                      value={formik.values.hopeShiftWeekOffset ? formik.values.hopeShiftWeekOffset : 0}
                      label="週前の"
                      onChange={formik.handleChange}
                      min={0}
                      max={5}
                      widthInput="35px"
                      height="42px"
                    />
                    <div style={{ marginLeft: "12px" }}>
                      <VerticalLabelSelectForm
                        label=""
                        name="hopeShiftWeekLimitDow"
                        width="60px"
                        value={String(formik.values.hopeShiftWeekLimitDow)}
                        options={[
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                            value: "0",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                            value: "1",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                            value: "2",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                            value: "3",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                            value: "4",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                            value: "5",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                            value: "6",
                          },
                        ]}
                        setValue={(m) => {
                          formik.setFieldValue("hopeShiftWeekLimitDow", m);
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <BodyText>曜日まで</BodyText>
                    </div>
                  </FlexBox>
                </FormField>
                <BodyText color={utilityColor.error} size="sm">
                  週の開始日は日曜とします。「0」週間前とすると、同じ週という扱いになります。
                </BodyText>
              </React.Fragment>
            )}

            {/* hopeShiftLimitFlag0  */}
            {String(formik.values.hopeShiftLimitFlag) !== hopeShiftLimitFlag.hopeShiftLimitFlag0.toString() && (
              <FormField>
                <RadioSelectForm
                  label="契約シフト自動作成"
                  items={[
                    {
                      label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag0],
                      value: "0",
                    },
                    {
                      label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag1],
                      value: "1",
                    },
                  ]}
                  name="shiftCreateFlag"
                  value={String(formik.values.shiftCreateFlag)}
                  setValue={(e) => {
                    formik.setFieldValue("shiftCreateFlag", e.target.value);
                  }}
                  note="希望シフト提出期限後に提出がないスタッフのシフトを契約勤務パターンから自動作成します。"
                  additionStyle={styles.radio}
                />
              </FormField>
            )}

            <FormField displayBlock={true}>
              <FormLabel label="介護休暇の１年度とする期間" />
              <FlexBox customStyle={styles.flexBox}>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="nursingYearStartMonth"
                    value={String(nursingYearStartMonthDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("nursingYearStartMonth", val);
                    }}
                    options={monthFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>月</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="nursingYearStartDay"
                    value={String(nursingYearStartDayDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("nursingYearStartDay", val);
                    }}
                    options={dayFormOptions.filter(
                      (dayOfOption) => Number(dayOfOption.value) <= nursingYearStartDayDefaultLimit,
                    )}
                  />
                </FlexBoxItem>

                <FlexBoxItem>
                  <span css={styles.span}>日</span>
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>〜</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="nursingYearEndMonth"
                    value={String(nursingYearEndMonthDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("nursingYearEndMonth", val);
                    }}
                    options={monthFormOptions}
                  />
                </FlexBoxItem>
                <span css={styles.span}>月</span>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="nursingYearEndDay"
                    value={String(nursingYearEndDayDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("nursingYearEndDay", val);
                    }}
                    options={dayFormOptions.filter(
                      (dayOfOption) => Number(dayOfOption.value) <= nursingYearEndDayDefaultLimit,
                    )}
                  />
                </FlexBoxItem>
                <span css={styles.span}>日</span>
              </FlexBox>
              <FormLabel label="子の看護休暇の１年度とする期間" />
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="childNursingYearStartMonth"
                    value={String(nursingCareYearStartMonthDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("childNursingYearStartMonth", val);
                    }}
                    options={monthFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>月</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="childNursingYearStartDay"
                    value={String(nursingCareYearStartDayDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("childNursingYearStartDay", val);
                    }}
                    options={dayFormOptions.filter(
                      (dayOfOption) => Number(dayOfOption.value) <= nursingCareYearStartDayDefaultLimit,
                    )}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>日</span>
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>〜</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="childNursingYearEndMonth"
                    value={String(nursingCareYearEndMonthDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("childNursingYearEndMonth", val);
                    }}
                    options={monthFormOptions}
                  />
                </FlexBoxItem>
                <span css={styles.span}>月</span>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="childNursingYearEndDay"
                    value={String(nursingCareYearEndDayDefault)}
                    setValue={(val: string) => {
                      formik.setFieldValue("childNursingYearEndDay", val);
                    }}
                    options={dayFormOptions.filter(
                      (dayOfOption) => Number(dayOfOption.value) <= nursingCareYearEndDayDefaultLimit,
                    )}
                  />
                </FlexBoxItem>
                <span css={styles.span}>日</span>
              </FlexBox>
            </FormField>

            <FormSubmitArea customStyle={css({ textAlign: "center", marginTop: "30px" })}>
              {detailRole.editable === 1 && (
                <React.Fragment>
                  <Button text="登録する" onClick={formik.handleSubmit} minWidth="160px" />
                  <br />
                </React.Fragment>
              )}
              {/* <PrimaryButton text="戻る" ghost={true} /> */}
              <Button onClick={() => history.goBack()} text="キャンセル" ghost={true} />
            </FormSubmitArea>
          </div>
        </form>
        <Modal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="勤務設定マスタの登録"
          submitText="登録する"
          closeText="キャンセル"
          note="勤務設定マスタを登録します。よろしいですか？"
          onSubmit={formik.handleSubmit}
        >
          <div style={{ height: "30px" }}></div>
        </Modal>
      </FormContents>
    </BlockUI>
  );
};

export default TimeSettingForm;
