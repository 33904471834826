/** @jsx jsx */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SidebarTemplate from "components/templates/SidebarTemplate";
import { Container, Row, Col } from "react-grid-system";
import FormContents from "components/atoms/Form/FormContents";
import BodyText from "components/atoms/BodyText";
import moment from "moment";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import BlockUI from "components/molecules/BlockUi";
import TextForm from "components/molecules/TextForm";
import { css, jsx } from "@emotion/core";
import ToastModal from "components/organismos/ToastModal";
import ConfirmModal from "components/organismos/ConfirmModal";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, DeleteButton } from "components/atoms/Button";
import productColor, { grayScale, textColor, textFontSize } from "components/styles";
import Checkbox from "components/molecules/Checkbox";
import useHolidayApplicationForm from "./hooks";
import Table from "components/molecules/Table";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";
import AttendHolidayModal from "components/molecules/ChangeLogModal/Template/AttendHolidayModal";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable";
import FormPadding from "components/atoms/Form/FormPadding";
import TabControl from "components/atoms/TabControl";
import Calendar from "components/molecules/CalendarV1";
import useIsMobile from "hooks/useIsMobile";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";
import MobileSelect from "components/atoms/MobileSelect";

const styles = {
  hideByHolidayType1: (holidayType: number) =>
    css(
      {
        display: holidayType === 1 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType2: (holidayType: number) =>
    css(
      {
        display: holidayType === 2 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType2Percent55: (holidayType: number, holidayUnit: number) =>
    css(
      {
        display: holidayType === 2 && [0].indexOf(holidayUnit) === -1 ? "" : "none",
        width: "55%",
      },
      styles.headerTitle,
    ),
  hideByHolidayType1NotHalf: (holidayType: number, holidayUnit: number) =>
    css(
      {
        display: holidayType === 1 && [0, 3, 4].indexOf(holidayUnit) === -1 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType1Half: (holidayType: number, holidayUnit: number) =>
    css(
      {
        display: holidayType === 1 && [3, 4].indexOf(holidayUnit) !== -1 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType4: (holidayType: number) =>
    css(
      {
        display: holidayType === 4 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType4Percent55: (holidayType: number, holidayUnit: number) =>
    css(
      {
        display: holidayType === 4 && [0].indexOf(holidayUnit) === -1 ? "" : "none",
        width: "55%",
      },
      styles.headerTitle,
    ),
  hideByHolidayType5: (holidayType: number) =>
    css(
      {
        display: holidayType === 5 ? "" : "none",
        borderRight: "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType6: (holidayType: number) =>
    css(
      {
        display: holidayType === 6 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideWithType2: (holidayType: number, autograntholiday: boolean) =>
    css(
      {
        display: holidayType === 2 && autograntholiday ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideWithType2NotAuto: (holidayType: number, autograntholiday: boolean) =>
    css(
      {
        display: holidayType === 2 && !autograntholiday ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType78: (holidayType: number) =>
    css(
      {
        display: [7, 8].indexOf(holidayType) !== -1 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType78NotHalf: (holidayType: number, holidayUnit: number) =>
    css(
      {
        display: [7, 8].indexOf(holidayType) !== -1 && [0, 3, 4].indexOf(holidayUnit) === -1 ? "" : "none",
      },
      styles.headerTitle,
    ),
  hideByHolidayType78Half: (holidayType: number, holidayUnit: number) =>
    css(
      {
        display: [7, 8].indexOf(holidayType) !== -1 && [3, 4].indexOf(holidayUnit) !== -1 ? "" : "none",
      },
      styles.headerTitle,
    ),
  rangeTimeHalfDay: css({
    width: "600px",
  }),
  headerTitle: css({
    textAlign: "left",
  }),
  formContent: {
    paddingBottom: "20px",
  },
  container: {
    marginBottom: "16px",
  },
  approvalWrap: css({
    padding: "0px",
    marginBottom: "15px",
    marginTop: "0px",
  }),
  rowPadding: css({
    paddingBottom: "16px",
  }),
};

enum ActionType {
  Add = 0,
  Modify = 1,
  Delete = 2,
}

const HolidayApplicationPage: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    isLoading,
    orgTreesOptions,
    formik,
    weekDay,
    staffName,
    holidayOptions,
    holidayUnitOptions,
    targetDateFrom,
    targetDateTo,
    setTargetDateTo,
    handelGetHolidays,
    toastModalOpen,
    toastMessage,
    closeConfirmModal,
    confirmModalOpen,
    setToastModalOpen,
    currentApplid,
    deemedMorningHalfHours,
    deemedAfternoonHalfHours,
    selectedHoliday,
    holidays,
    setSelectedHoliday,
    substituteOptions,
    selectedSubstitute,
    setSelectedSubstitute,
    getHolidayUnitOption,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogHoliday,
    substituteList,
    holidayStartDate,
    holidayEndDate,
    orgCode,
    setOrgCode,
    setIsReloader,
  } = useHolidayApplicationForm();

  const [changeLogData, setChangeLogData] = useState<Array<any>>([]);

  useEffect(() => {
    if (currentApplid) {
      fetchChangeLogHoliday(currentApplid.modifierApplicationId).then((res) => {
        setChangeLogData(res);
      });
    }
  }, [currentApplid]);

  useEffect(() => {
    if (formik.values.holidayUnit !== 2) {
      formik.setFieldValue("startHour", "00");
      formik.setFieldValue("startTime", "00");
      formik.setFieldValue("nextDay", false);
      formik.setFieldValue("stampTime", "");
    }
  }, [formik.values.holidayUnit]);

  const handleChangeTargetToDate = (newDate: string) => {
    // For avoiding AttendHolidayApplicationEditData NPE
    formik.setFieldValue("targetDate", targetDateFrom);
    formik.setFieldValue("fromDate", targetDateFrom);
    formik.setFieldValue("toDate", newDate);
    setTargetDateTo(newDate);
    handelGetHolidays();
  };

  const handleChangeHoliday = (holidayId: string) => {
    formik.setFieldValue("holidayId", holidayId);
    const tmpSelectedHoliday: any = holidays.filter((holiday: any) => holiday.value === holidayId);
    if (tmpSelectedHoliday[0]) {
      setSelectedHoliday(tmpSelectedHoliday[0]);
      let unitOptions = getHolidayUnitOption(tmpSelectedHoliday[0]);
      if (!formik.values.achievementId && unitOptions.length > 0) {
        formik.setFieldValue("holidayUnit", unitOptions[0].value);
      }
    }
  };
  const handleChangeHolidayUnit = (holidayUnit: string) => {
    formik.setFieldValue("holidayUnit", holidayUnit);
    if ([3, 4].indexOf(formik.values.holidayUnit) !== -1) {
      formik.setFieldValue("halfHolidayStartTimeHour", "00");
      formik.setFieldValue("halfHolidayStartTimeMinute", "00");
      formik.setFieldValue("halfHolidayEndTimeHour", "00");
      formik.setFieldValue("halfHolidayEndTimeMinute", "00");
      formik.setFieldValue("halfHolidayStartTimeNextDay", false);
      formik.setFieldValue("halfHolidayEndTimeNextDay", false);
    }
  };

  useEffect(() => {
    if (selectedSubstitute) {
      formik.setFieldValue("substituteId", selectedSubstitute);
    }

    if (currentApplid.holidayId !== null) {
      handleChangeHoliday(currentApplid.holidayId);
    } else {
      handleChangeHoliday(selectedHoliday.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApplid, selectedSubstitute, substituteList, formik.values.substituteId]);

  useEffect(() => {
    if (formik.values.holidayUnit !== 2) {
      formik.setFieldValue("startHour", "00");
      formik.setFieldValue("startTime", "00");
      formik.setFieldValue("nextDay", false);
      formik.setFieldValue("stampTime", "");
    }
  }, [formik.values.holidayUnit]);

  useEffect(() => {
    var startDate = new Date(holidayStartDate);
    var endDate = new Date(holidayEndDate);
    var target = new Date(targetDateFrom);

    if (startDate > target) {
      formik.values.halfHolidayStartTimeNextDay = true;
    }
    if (endDate > target) {
      formik.values.halfHolidayEndTimeNextDay = true;
    }
  }, [holidayStartDate, holidayEndDate]);

  const handleChangeHalfStartTime = (type: number, val: string) => {
    if (type === 0) {
      formik.setFieldValue("halfHolidayStartTimeHour", val);
    } else if (type === 1) {
      formik.setFieldValue("halfHolidayStartTimeMinute", val);
    }

    if (
      (formik.values.holidayUnit !== 3 && formik.values.holidayUnit !== 4) ||
      (formik.values.holidayUnit === 3 && deemedMorningHalfHours === 0) ||
      (formik.values.holidayUnit === 4 && deemedAfternoonHalfHours === 0)
    ) {
      return;
    }

    var minutes =
      Number(formik.values.halfHolidayStartTimeHour) * 60 + Number(formik.values.halfHolidayStartTimeMinute);
    var endMinutesCalc =
      minutes + (formik.values.holidayUnit === 3 ? deemedMorningHalfHours : deemedAfternoonHalfHours);

    var endHour = Math.trunc(endMinutesCalc / 60);
    var nextDay = 24 <= endHour ? true : formik.values.halfHolidayStartTimeNextDay;
    var endMinutes = endMinutesCalc - endHour * 60;
    endHour = 24 <= endHour ? endHour - 24 : endHour;

    formik.setFieldValue("halfHolidayEndTimeHour", endHour < 10 ? "0" + String(endHour) : String(endHour));
    formik.setFieldValue("halfHolidayEndTimeMinute", endMinutes < 10 ? "0" + String(endMinutes) : String(endMinutes));
    formik.setFieldValue("halfHolidayEndTimeNextDay", nextDay);
  };

  return (
    <SidebarTemplate pageTitle="休暇申請">
      <BlockUI blocking={isLoading}>
        <FormContents
          customStyle={css({
            paddingBottom: isMobile ? "8px" : "",
          })}
        >
          <FormTitle
            title="休暇申請"
            bold={true}
            customStyle={css(isMobile ? { padding: "8px 16px 16px 16px", margin: 0 } : {})}
          />
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormPadding customStyle={css(isMobile ? { padding: "0 16px 16px 16px" } : { paddingLeft: "35px" })}>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>日時</BodyText>
                  </Col>
                  <Col style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }} md={10} xs={12}>
                    <BodyText color={textColor.mobileTitle}>
                      {currentApplid.modifierApplicationId != null
                        ? moment(`${targetDateFrom}`, "YYYY-MM-DD").format(
                            `YYYY年MM月DD日(${weekDay[new Date(`${targetDateFrom}`).getDay()]})`,
                          )
                        : moment(`${targetDateFrom}`, "YYYY-MM-DD").format(
                            `YYYY年MM月DD日(${weekDay[new Date(`${targetDateFrom}`).getDay()]}) ~`,
                          )}
                    </BodyText>
                    {currentApplid.modifierApplicationId != null ? (
                      ""
                    ) : (
                      <Calendar
                        openActionChevron={false}
                        date={moment(targetDateTo).toDate()}
                        setDate={(date) => {
                          if (date) {
                            let newDate = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
                            handleChangeTargetToDate(newDate);
                          }
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText color={textColor.mobileTitle}>{staffName}</BodyText>
                  </Col>
                </Row>

                <Row css={styles.rowPadding}>
                  <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                    <BodyText>出勤組織</BodyText>
                  </Col>
                  <Col md={10}>
                    <Row>
                      {
                        <Col md={4}>
                          <SelectForm
                            label=""
                            name="orgCode"
                            value={String(orgCode)}
                            setValue={(val: string) => {
                              setOrgCode(val);
                              setIsReloader(true);
                            }}
                            options={orgTreesOptions}
                            disable={currentApplid.modifierApplicationId ? true : false}
                            width={isMobile ? "100%" : "800px"}
                          />
                        </Col>
                      }
                    </Row>
                  </Col>
                </Row>

                {isMobile ? (
                  <React.Fragment>
                    <Row css={styles.rowPadding}>
                      <Col xs={12}>
                        <BodyText>休暇</BodyText>
                      </Col>
                      <Col xs={12}>
                        <VerticalLabelSelectForm
                          label=""
                          name="holidayId"
                          value={String(selectedHoliday.value)}
                          setValue={(val: string) => handleChangeHoliday(val)}
                          options={holidayOptions}
                          required={true}
                          errorMsg={formik.errors.holidayId}
                          disable={
                            currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                          }
                          isSearchable={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col css={styles.hideByHolidayType1(selectedHoliday.holidayType)} xs={12}>
                        <BodyText>休暇単位</BodyText>
                      </Col>
                      <Col css={styles.hideByHolidayType78(selectedHoliday.holidayType)} xs={12}>
                        <BodyText>休暇単位</BodyText>
                      </Col>
                      <Col
                        css={
                          selectedHoliday.holidayType === 2
                            ? styles.hideByHolidayType2(selectedHoliday.holidayType)
                            : selectedHoliday.holidayType === 4
                            ? styles.hideByHolidayType4(selectedHoliday.holidayType)
                            : styles.hideByHolidayType2(selectedHoliday.holidayType)
                        }
                        xs={12}
                      >
                        <BodyText>休暇単位</BodyText>
                      </Col>
                    </Row>
                    <Row css={styles.rowPadding}>
                      <Col css={styles.hideByHolidayType1(selectedHoliday.holidayType)} xs={12}>
                        <VerticalLabelSelectForm
                          label=""
                          name="holidayUnit"
                          value={String(formik.values.holidayUnit)}
                          setValue={(val: string) => handleChangeHolidayUnit(val)}
                          options={holidayUnitOptions}
                          disable={
                            currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                          }
                          isSearchable={false}
                        />
                      </Col>
                      <Col css={styles.hideByHolidayType78(selectedHoliday.holidayType)} xs={12}>
                        <VerticalLabelSelectForm
                          label=""
                          name="holidayUnit"
                          value={String(formik.values.holidayUnit)}
                          setValue={(val: string) => handleChangeHolidayUnit(val)}
                          options={holidayUnitOptions}
                          disable={
                            currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                          }
                          isSearchable={false}
                        />
                      </Col>
                      <Col
                        css={[
                          selectedHoliday.holidayType === 2
                            ? styles.hideByHolidayType2(selectedHoliday.holidayType)
                            : selectedHoliday.holidayType === 4
                            ? styles.hideByHolidayType4(selectedHoliday.holidayType)
                            : styles.hideByHolidayType2(selectedHoliday.holidayType),
                        ]}
                        xs={12}
                      >
                        <VerticalLabelSelectForm
                          label=""
                          name="holidayUnit"
                          value={String(formik.values.holidayUnit)}
                          setValue={(val: string) => handleChangeHolidayUnit(val)}
                          options={holidayUnitOptions}
                          disable={
                            currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                          }
                          isSearchable={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        css={styles.hideByHolidayType1NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit)}
                        xs={12}
                      >
                        <BodyText>時間休の開始時間</BodyText>
                      </Col>
                      <Col
                        css={css([
                          styles.hideByHolidayType1Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rangeTimeHalfDay,
                        ])}
                        xs={12}
                      >
                        <BodyText>半休となる時間</BodyText>
                      </Col>
                      <Col
                        css={styles.hideWithType2(selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday)}
                        xs={12}
                      >
                        <BodyText>休日出勤日</BodyText>
                      </Col>
                      <Col
                        css={[
                          styles.hideWithType2(selectedHoliday.holidayType, selectedHoliday.autoGrantHoliday),
                          styles.rowPadding,
                        ]}
                        xs={12}
                      >
                        <VerticalLabelSelectForm
                          label=""
                          name="substituteId"
                          value={String(formik.values.substituteId)}
                          setValue={(val: string) => {
                            formik.setFieldValue("substituteId", val);
                            setSelectedSubstitute(val);
                          }}
                          options={substituteOptions}
                          disable={
                            currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                          }
                          isSearchable={false}
                        />
                      </Col>
                      <Col css={styles.hideByHolidayType6(selectedHoliday.holidayType)} xs={12}>
                        <BodyText>半休となる時間</BodyText>
                      </Col>
                      <Col
                        css={styles.hideByHolidayType78NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit)}
                        xs={12}
                      >
                        <BodyText>時間休の開始時間</BodyText>
                      </Col>
                      <Col
                        css={css([
                          styles.hideByHolidayType78Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rangeTimeHalfDay,
                        ])}
                        xs={12}
                      >
                        <BodyText>半休となる時間</BodyText>
                      </Col>
                      <Col
                        css={
                          selectedHoliday.holidayType === 2
                            ? styles.hideByHolidayType2Percent55(
                                selectedHoliday.holidayType,
                                formik.values.holidayUnit,
                              )
                            : selectedHoliday.holidayType === 4
                            ? styles.hideByHolidayType4Percent55(
                                selectedHoliday.holidayType,
                                formik.values.holidayUnit,
                              )
                            : styles.hideByHolidayType2Percent55(
                                selectedHoliday.holidayType,
                                formik.values.holidayUnit,
                              )
                        }
                        xs={12}
                      >
                        <BodyText>半休となる時間</BodyText>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        css={[
                          styles.hideByHolidayType1NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rowPadding,
                        ]}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                          <MobileSelect
                            id="startHour"
                            onChange={(e) => {
                              const val = e.target.value;
                              if (val === "") return;
                              formik.setFieldValue("startHour", val);
                            }}
                            disabled={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            options={hourFormOptions}
                            value={String(formik.values.startHour)}
                          />
                          <div>:</div>
                          <MobileSelect
                            id="startTime"
                            onChange={(e) => {
                              const val = e.target.value;
                              if (val === "") return;
                              formik.setFieldValue("startTime", val);
                            }}
                            disabled={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            options={timeFormOptions}
                            value={String(formik.values.startTime)}
                          />
                          <Checkbox
                            id="nextDay"
                            name="nextDay"
                            label="翌日"
                            value=""
                            checked={Boolean(formik.values.nextDay)}
                            onChange={formik.handleChange}
                            readOnly={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            disabled={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            isVertical={isMobile}
                          />
                        </div>
                      </Col>
                      <Col
                        css={css([
                          styles.hideByHolidayType1Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rangeTimeHalfDay,
                          styles.rowPadding,
                        ])}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "column" })}>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="halfHolidayStartTimeHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(0, val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayStartTimeHour)}
                            />
                            <div>:</div>
                            <MobileSelect
                              id="halfHolidayStartTimeMinute"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(1, val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayStartTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayStartTimeNextDay"
                              name="halfHolidayStartTimeNextDay"
                              label="翌日"
                              value=""
                              checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                              onChange={formik.handleChange}
                              readOnly={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ margin: "0 10px" })}>～</BodyText>
                          </div>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="halfHolidayEndTimeHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeHour", val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayEndTimeHour)}
                            />
                            <div>:</div>
                            <MobileSelect
                              id="halfHolidayEndTimeMinute"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeMinute", val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayEndTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayEndTimeNextDay"
                              name="halfHolidayEndTimeNextDay"
                              label="翌日"
                              value=""
                              checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                              onChange={formik.handleChange}
                              readOnly={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ minWidth: "34px" })}></BodyText>
                          </div>
                        </div>
                      </Col>
                      <Col css={[styles.hideByHolidayType6(selectedHoliday.holidayType), styles.rowPadding]} xs={12}>
                        <div
                          css={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            padding: "1vw",
                            textAlign: "center",
                          }}
                        >
                          <Col>
                            {selectedHoliday.paidHalfHolidayStartTime}-{selectedHoliday.paidHalfHolidayEndTime}
                          </Col>
                        </div>
                      </Col>
                      <Col
                        css={[
                          styles.hideByHolidayType78NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rowPadding,
                        ]}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                          <MobileSelect
                            id="startHour"
                            onChange={(e) => {
                              const val = e.target.value;
                              if (val === "") return;
                              formik.setFieldValue("startHour", val);
                            }}
                            disabled={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            options={hourFormOptions}
                            value={String(formik.values.startHour)}
                          />
                          <div>:</div>
                          <MobileSelect
                            id="startTime"
                            onChange={(e) => {
                              const val = e.target.value;
                              if (val === "") return;
                              formik.setFieldValue("startTime", val);
                            }}
                            disabled={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            options={timeFormOptions}
                            value={String(formik.values.startTime)}
                          />
                          <Checkbox
                            id="nextDay"
                            name="nextDay"
                            label="翌日"
                            value=""
                            checked={Boolean(formik.values.nextDay)}
                            onChange={formik.handleChange}
                            readOnly={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            disabled={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                            isVertical={isMobile}
                          />
                        </div>
                      </Col>
                      <Col
                        css={css([
                          styles.hideByHolidayType78Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rangeTimeHalfDay,
                          styles.rowPadding,
                        ])}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "column" })}>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="halfHolidayStartTimeHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(0, val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayStartTimeHour)}
                            />
                            <div>:</div>
                            <MobileSelect
                              id="halfHolidayStartTimeMinute"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(1, val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayStartTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayStartTimeNextDay"
                              name="halfHolidayStartTimeNextDay"
                              label="翌日"
                              value=""
                              checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                              onChange={formik.handleChange}
                              readOnly={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ margin: "0 10px" })}>～</BodyText>
                          </div>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="halfHolidayEndTimeHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeHour", val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayEndTimeHour)}
                            />
                            <div>:</div>
                            <MobileSelect
                              id="halfHolidayEndTimeMinute"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeMinute", val);
                              }}
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayEndTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayEndTimeNextDay"
                              name="halfHolidayEndTimeNextDay"
                              label="翌日"
                              value=""
                              checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                              onChange={formik.handleChange}
                              readOnly={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              disabled={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ minWidth: "34px" })}></BodyText>
                          </div>
                        </div>
                      </Col>
                      <Col
                        css={[
                          selectedHoliday.holidayType === 2
                            ? styles.hideByHolidayType2Percent55(
                                selectedHoliday.holidayType,
                                formik.values.holidayUnit,
                              )
                            : selectedHoliday.holidayType === 4
                            ? styles.hideByHolidayType4Percent55(
                                selectedHoliday.holidayType,
                                formik.values.holidayUnit,
                              )
                            : styles.hideByHolidayType2Percent55(
                                selectedHoliday.holidayType,
                                formik.values.holidayUnit,
                              ),
                          styles.rowPadding,
                        ]}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "column" })}>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="halfHolidayStartTimeHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(0, val);
                              }}
                              disabled={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayStartTimeHour)}
                            />
                            <div>:</div>
                            <MobileSelect
                              id="halfHolidayStartTimeMinute"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                handleChangeHalfStartTime(1, val);
                              }}
                              disabled={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayStartTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayStartTimeNextDay"
                              name="halfHolidayStartTimeNextDay"
                              label="翌日"
                              value=""
                              readOnly={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              disabled={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                              onChange={formik.handleChange}
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ margin: "0 10px" })}>～</BodyText>
                          </div>
                          <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                            <MobileSelect
                              id="halfHolidayEndTimeHour"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeHour", val);
                              }}
                              disabled={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              options={hourFormOptions}
                              value={String(formik.values.halfHolidayEndTimeHour)}
                            />
                            <div>:</div>
                            <MobileSelect
                              id="halfHolidayEndTimeMinute"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (val === "") return;
                                formik.setFieldValue("halfHolidayEndTimeMinute", val);
                              }}
                              disabled={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              options={timeFormOptions}
                              value={String(formik.values.halfHolidayEndTimeMinute)}
                            />
                            <Checkbox
                              id="halfHolidayEndTimeNextDay"
                              name="halfHolidayEndTimeNextDay"
                              label="翌日"
                              readOnly={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              disabled={
                                formik.values.holidayUnit === 0 ||
                                (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                              }
                              value=""
                              checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                              onChange={formik.handleChange}
                              isVertical={isMobile}
                            />
                            <BodyText customStyle={css({ minWidth: "34px" })}></BodyText>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        css={styles.hideByHolidayType1NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit)}
                        xs={12}
                      >
                        <BodyText>取得時間</BodyText>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        css={[
                          styles.hideByHolidayType1NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rowPadding,
                        ]}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                          <TextForm
                            name="stampTime"
                            label=""
                            value={formik.values.stampTime}
                            onChange={formik.handleChange}
                            disable={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                          />
                          <BodyText customStyle={css({ whiteSpace: "nowrap" })} color={grayScale.gray100}>
                            時間
                          </BodyText>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        css={styles.hideByHolidayType78NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit)}
                        xs={12}
                      >
                        <BodyText>取得時間</BodyText>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        css={[
                          styles.hideByHolidayType78NotHalf(selectedHoliday.holidayType, formik.values.holidayUnit),
                          styles.rowPadding,
                        ]}
                        xs={12}
                      >
                        <div css={css({ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" })}>
                          <TextForm
                            name="stampTime"
                            label=""
                            value={formik.values.stampTime}
                            onChange={formik.handleChange}
                            disable={
                              formik.values.holidayUnit !== 2 ||
                              (currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null)
                            }
                          />
                          <BodyText customStyle={css({ whiteSpace: "nowrap" })} color={grayScale.gray100}>
                            時間
                          </BodyText>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <Row>
                    <Table customStyle={styles.container}>
                      <thead>
                        <tr>
                          <Table.HeaderCell customStyle={styles.headerTitle}>休暇</Table.HeaderCell>
                          <Table.HeaderCell customStyle={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                            休暇単位
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={styles.hideByHolidayType1NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            時間休の開始時間
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={styles.hideByHolidayType1NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            取得時間
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={css([
                              styles.hideByHolidayType1Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                              styles.rangeTimeHalfDay,
                            ])}
                          >
                            半休となる時間
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={styles.hideWithType2(
                              selectedHoliday.holidayType,
                              selectedHoliday.autoGrantHoliday,
                            )}
                          >
                            休日出勤日
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={styles.hideByHolidayType5(selectedHoliday.holidayType)}
                          ></Table.HeaderCell>
                          <Table.HeaderCell customStyle={styles.hideByHolidayType6(selectedHoliday.holidayType)}>
                            半休となる時間
                          </Table.HeaderCell>
                          <Table.HeaderCell customStyle={styles.hideByHolidayType78(selectedHoliday.holidayType)}>
                            休暇単位
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={styles.hideByHolidayType78NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            時間休の開始時間
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={styles.hideByHolidayType78NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            取得時間
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={css([
                              styles.hideByHolidayType78Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                              styles.rangeTimeHalfDay,
                            ])}
                          >
                            半休となる時間
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={
                              selectedHoliday.holidayType === 2
                                ? styles.hideByHolidayType2(selectedHoliday.holidayType)
                                : selectedHoliday.holidayType === 4
                                ? styles.hideByHolidayType4(selectedHoliday.holidayType)
                                : styles.hideByHolidayType2(selectedHoliday.holidayType)
                            }
                          >
                            休暇単位
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            customStyle={
                              selectedHoliday.holidayType === 2
                                ? styles.hideByHolidayType2Percent55(
                                    selectedHoliday.holidayType,
                                    formik.values.holidayUnit,
                                  )
                                : selectedHoliday.holidayType === 4
                                ? styles.hideByHolidayType4Percent55(
                                    selectedHoliday.holidayType,
                                    formik.values.holidayUnit,
                                  )
                                : styles.hideByHolidayType2Percent55(
                                    selectedHoliday.holidayType,
                                    formik.values.holidayUnit,
                                  )
                            }
                          >
                            半休となる時間
                          </Table.HeaderCell>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <Table.Cell>
                            <VerticalLabelSelectForm
                              label=""
                              name="holidayId"
                              value={String(selectedHoliday.value)}
                              setValue={(val: string) => handleChangeHoliday(val)}
                              options={holidayOptions}
                              required={true}
                              errorMsg={formik.errors.holidayId}
                              disable={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                            />
                          </Table.Cell>
                          <Table.Cell customStyle={styles.hideByHolidayType1(selectedHoliday.holidayType)}>
                            <VerticalLabelSelectForm
                              label=""
                              name="holidayUnit"
                              value={String(formik.values.holidayUnit)}
                              setValue={(val: string) => handleChangeHolidayUnit(val)}
                              options={holidayUnitOptions}
                              disable={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isSearchable={false}
                            />
                          </Table.Cell>
                          <Table.Cell
                            customStyle={styles.hideByHolidayType1NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="startHour"
                                  value={String(formik.values.startHour)}
                                  setValue={(val: string) => formik.setFieldValue("startHour", val)}
                                  options={hourFormOptions}
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="startTime"
                                  value={String(formik.values.startTime)}
                                  setValue={(val: string) => formik.setFieldValue("startTime", val)}
                                  options={timeFormOptions}
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="nextDay"
                                  name="nextDay"
                                  label="翌日"
                                  value=""
                                  checked={Boolean(formik.values.nextDay)}
                                  onChange={formik.handleChange}
                                  readOnly={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                  disabled={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                  isVertical={isMobile}
                                />
                              </div>
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            customStyle={styles.hideByHolidayType1NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                fontSize: "14px",
                              }}
                            >
                              <Col md={9}>
                                <TextForm
                                  name="stampTime"
                                  label=""
                                  value={formik.values.stampTime}
                                  onChange={formik.handleChange}
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                />
                              </Col>
                              <Col md={3} style={{ paddingLeft: "10px" }}>
                                <BodyText
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                  color={grayScale.gray100}
                                >
                                  時間
                                </BodyText>
                              </Col>
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            customStyle={css([
                              styles.hideByHolidayType1Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                              styles.rangeTimeHalfDay,
                            ])}
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* Start Time */}
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayStartTimeHour"
                                  value={String(formik.values.halfHolidayStartTimeHour)}
                                  setValue={(val: string) => handleChangeHalfStartTime(0, val)}
                                  options={hourFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayStartTimeMinute"
                                  value={String(formik.values.halfHolidayStartTimeMinute)}
                                  setValue={(val: string) => handleChangeHalfStartTime(1, val)}
                                  options={timeFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="halfHolidayStartTimeNextDay"
                                  name="halfHolidayStartTimeNextDay"
                                  label="翌日"
                                  value=""
                                  checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                                  onChange={formik.handleChange}
                                  readOnly={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  disabled={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  isVertical={isMobile}
                                />
                              </div>
                              <BodyText customStyle={css({ margin: "0 10px" })}>~</BodyText>
                              {/* End Time */}
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayEndTimeHour"
                                  value={String(formik.values.halfHolidayEndTimeHour)}
                                  setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeHour", val)}
                                  options={hourFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayEndTimeMinute"
                                  value={String(formik.values.halfHolidayEndTimeMinute)}
                                  setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeMinute", val)}
                                  options={timeFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="halfHolidayEndTimeNextDay"
                                  name="halfHolidayEndTimeNextDay"
                                  label="翌日"
                                  value=""
                                  checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                                  onChange={formik.handleChange}
                                  readOnly={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  disabled={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  isVertical={isMobile}
                                />
                              </div>
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            customStyle={styles.hideWithType2(
                              selectedHoliday.holidayType,
                              selectedHoliday.autoGrantHoliday,
                            )}
                          >
                            <VerticalLabelSelectForm
                              label=""
                              name="substituteId"
                              value={String(formik.values.substituteId)}
                              setValue={(val: string) => {
                                formik.setFieldValue("substituteId", val);
                                setSelectedSubstitute(val);
                              }}
                              options={substituteOptions}
                              disable={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                            />
                          </Table.Cell>
                          <Table.Cell
                            customStyle={styles.hideByHolidayType5(selectedHoliday.holidayType)}
                          ></Table.Cell>
                          <Table.Cell customStyle={styles.hideByHolidayType6(selectedHoliday.holidayType)}>
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                padding: "1vw",
                                textAlign: "center",
                              }}
                            >
                              <Col>
                                {selectedHoliday.paidHalfHolidayStartTime}-{selectedHoliday.paidHalfHolidayEndTime}
                              </Col>
                            </div>
                          </Table.Cell>
                          <Table.Cell customStyle={styles.hideByHolidayType78(selectedHoliday.holidayType)}>
                            <VerticalLabelSelectForm
                              label=""
                              name="holidayUnit"
                              value={String(formik.values.holidayUnit)}
                              setValue={(val: string) => handleChangeHolidayUnit(val)}
                              options={holidayUnitOptions}
                              disable={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isSearchable={false}
                            />
                          </Table.Cell>
                          <Table.Cell
                            customStyle={styles.hideByHolidayType78NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="startHour"
                                  value={String(formik.values.startHour)}
                                  setValue={(val: string) => formik.setFieldValue("startHour", val)}
                                  options={hourFormOptions}
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="startTime"
                                  value={String(formik.values.startTime)}
                                  setValue={(val: string) => formik.setFieldValue("startTime", val)}
                                  options={timeFormOptions}
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="nextDay"
                                  name="nextDay"
                                  label="翌日"
                                  value=""
                                  checked={Boolean(formik.values.nextDay)}
                                  onChange={formik.handleChange}
                                  readOnly={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                  disabled={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                  isVertical={isMobile}
                                />
                              </div>
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            customStyle={styles.hideByHolidayType78NotHalf(
                              selectedHoliday.holidayType,
                              formik.values.holidayUnit,
                            )}
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                fontSize: "14px",
                              }}
                            >
                              <Col md={9}>
                                <TextForm
                                  name="stampTime"
                                  label=""
                                  value={formik.values.stampTime}
                                  onChange={formik.handleChange}
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                />
                              </Col>
                              <Col md={3} style={{ paddingLeft: "10px" }}>
                                <BodyText
                                  disable={
                                    formik.values.holidayUnit !== 2 ||
                                    (currentApplid.applicationStatus === 0 &&
                                      currentApplid.modifierApplicationId != null)
                                  }
                                  color={grayScale.gray100}
                                >
                                  時間
                                </BodyText>
                              </Col>
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            customStyle={css([
                              styles.hideByHolidayType78Half(selectedHoliday.holidayType, formik.values.holidayUnit),
                              styles.rangeTimeHalfDay,
                            ])}
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* Start Time */}
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayStartTimeHour"
                                  value={String(formik.values.halfHolidayStartTimeHour)}
                                  setValue={(val: string) => handleChangeHalfStartTime(0, val)}
                                  options={hourFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayStartTimeMinute"
                                  value={String(formik.values.halfHolidayStartTimeMinute)}
                                  setValue={(val: string) => handleChangeHalfStartTime(1, val)}
                                  options={timeFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="halfHolidayStartTimeNextDay"
                                  name="halfHolidayStartTimeNextDay"
                                  label="翌日"
                                  value=""
                                  checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                                  onChange={formik.handleChange}
                                  readOnly={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  disabled={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  isVertical={isMobile}
                                />
                              </div>
                              <BodyText customStyle={css({ margin: "0 10px" })}>~</BodyText>
                              {/* End Time */}
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayEndTimeHour"
                                  value={String(formik.values.halfHolidayEndTimeHour)}
                                  setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeHour", val)}
                                  options={hourFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ padding: "0 5px" }}>:</div>
                              <Col>
                                <VerticalLabelSelectForm
                                  label=""
                                  name="halfHolidayEndTimeMinute"
                                  value={String(formik.values.halfHolidayEndTimeMinute)}
                                  setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeMinute", val)}
                                  options={timeFormOptions}
                                  disable={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                />
                              </Col>
                              <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                  id="halfHolidayEndTimeNextDay"
                                  name="halfHolidayEndTimeNextDay"
                                  label="翌日"
                                  value=""
                                  checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                                  onChange={formik.handleChange}
                                  readOnly={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  disabled={
                                    currentApplid.applicationStatus === 0 &&
                                    currentApplid.modifierApplicationId != null
                                  }
                                  isVertical={isMobile}
                                />
                              </div>
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            customStyle={
                              selectedHoliday.holidayType === 2
                                ? styles.hideByHolidayType2(selectedHoliday.holidayType)
                                : selectedHoliday.holidayType === 4
                                ? styles.hideByHolidayType4(selectedHoliday.holidayType)
                                : styles.hideByHolidayType2(selectedHoliday.holidayType)
                            }
                          >
                            <VerticalLabelSelectForm
                              label=""
                              name="holidayUnit"
                              value={String(formik.values.holidayUnit)}
                              setValue={(val: string) => handleChangeHolidayUnit(val)}
                              options={holidayUnitOptions}
                              disable={
                                currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                              }
                              isSearchable={false}
                            />
                          </Table.Cell>
                          <Table.Cell
                            customStyle={
                              selectedHoliday.holidayType === 2
                                ? styles.hideByHolidayType2Percent55(
                                    selectedHoliday.holidayType,
                                    formik.values.holidayUnit,
                                  )
                                : selectedHoliday.holidayType === 4
                                ? styles.hideByHolidayType4Percent55(
                                    selectedHoliday.holidayType,
                                    formik.values.holidayUnit,
                                  )
                                : styles.hideByHolidayType2Percent55(
                                    selectedHoliday.holidayType,
                                    formik.values.holidayUnit,
                                  )
                            }
                          >
                            <div
                              css={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                css={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                {/* Start Time */}
                                <Col>
                                  <VerticalLabelSelectForm
                                    label=""
                                    name="halfHolidayStartTimeHour"
                                    disable={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    value={String(formik.values.halfHolidayStartTimeHour)}
                                    setValue={(val: string) => handleChangeHalfStartTime(0, val)}
                                    options={hourFormOptions}
                                  />
                                </Col>
                                <div style={{ padding: "0 5px" }}>:</div>
                                <Col>
                                  <VerticalLabelSelectForm
                                    label=""
                                    name="halfHolidayStartTimeMinute"
                                    disable={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    value={String(formik.values.halfHolidayStartTimeMinute)}
                                    setValue={(val: string) => handleChangeHalfStartTime(1, val)}
                                    options={timeFormOptions}
                                  />
                                </Col>
                                <div style={{ marginLeft: "10px" }}>
                                  <Checkbox
                                    id="halfHolidayStartTimeNextDay"
                                    name="halfHolidayStartTimeNextDay"
                                    label="翌日"
                                    value=""
                                    readOnly={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    disabled={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    checked={Boolean(formik.values.halfHolidayStartTimeNextDay)}
                                    onChange={formik.handleChange}
                                    isVertical={isMobile}
                                  />
                                </div>
                                <BodyText customStyle={css({ margin: "0 10px" })}>~</BodyText>
                                {/* End Time */}
                                <Col>
                                  <VerticalLabelSelectForm
                                    label=""
                                    name="halfHolidayEndTimeHour"
                                    disable={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    value={String(formik.values.halfHolidayEndTimeHour)}
                                    setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeHour", val)}
                                    options={hourFormOptions}
                                  />
                                </Col>
                                <div style={{ padding: "0 5px" }}>:</div>
                                <Col>
                                  <VerticalLabelSelectForm
                                    label=""
                                    name="halfHolidayEndTimeMinute"
                                    disable={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    value={String(formik.values.halfHolidayEndTimeMinute)}
                                    setValue={(val: string) => formik.setFieldValue("halfHolidayEndTimeMinute", val)}
                                    options={timeFormOptions}
                                  />
                                </Col>
                                <div style={{ marginLeft: "10px" }}>
                                  <Checkbox
                                    id="halfHolidayEndTimeNextDay"
                                    name="halfHolidayEndTimeNextDay"
                                    label="翌日"
                                    readOnly={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    disabled={
                                      formik.values.holidayUnit === 0 ||
                                      (currentApplid.applicationStatus === 0 &&
                                        currentApplid.modifierApplicationId != null)
                                    }
                                    value=""
                                    checked={Boolean(formik.values.halfHolidayEndTimeNextDay)}
                                    onChange={formik.handleChange}
                                    isVertical={isMobile}
                                  />
                                </div>
                              </div>
                            </div>
                          </Table.Cell>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                )}

                <Row css={styles.rowPadding}>
                  <Col
                    md={2}
                    xs={12}
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <BodyText
                      customStyle={css(
                        isMobile
                          ? {
                              marginBottom: "4px",
                            }
                          : {},
                      )}
                    >
                      申請理由
                    </BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <TextForm
                      name="applicationReason"
                      label=""
                      value={String(formik.values.applicationReason)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.applicationReason}
                      disable={currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null}
                    />
                  </Col>
                </Row>

                {isMobile ? (
                  <div css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr);", gap: 8 }}>
                    {!(currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null) && (
                      <PrimaryButton
                        disabled={currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null}
                        ghost={false}
                        text="申請する"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("attendType", ActionType.Add);
                        }}
                      />
                    )}
                    {currentApplid.achievementId !== null && currentApplid.applicationStatus === 0 && (
                      <DeleteButton
                        ghost={false}
                        text="申請キャンセル"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("attendType", ActionType.Modify);
                        }}
                      />
                    )}
                    {currentApplid.deleteApplicationAvailable === true && (
                      <DeleteButton
                        ghost={false}
                        text="削除申請"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("attendType", ActionType.Delete);
                        }}
                      />
                    )}

                    <Button
                      customStyle={css({
                        border: `1px solid ${productColor.primary}`,
                      })}
                      onClick={() => history.goBack()}
                      text="キャンセル"
                      ghost={true}
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <Row style={{ padding: "10px 30px" }}>
                      <Col
                        md={6}
                        offset={{ md: 3 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormSubmitArea>
                          {!(currentApplid.modifiyStatus === 1 && currentApplid.applicationStatus === 0) && (
                            <span style={{ marginRight: "12px" }}>
                              <PrimaryButton
                                disabled={
                                  currentApplid.applicationStatus === 0 && currentApplid.modifierApplicationId != null
                                }
                                ghost={false}
                                text="申請する"
                                onClick={() => {
                                  formik.handleSubmit();
                                  formik.setFieldValue("attendType", ActionType.Add);
                                }}
                              />
                            </span>
                          )}
                          {currentApplid.achievementId !== null && currentApplid.applicationStatus === 0 && (
                            <span style={{ marginRight: "12px" }}>
                              <DeleteButton
                                ghost={false}
                                text="申請キャンセル"
                                onClick={() => {
                                  formik.handleSubmit();
                                  formik.setFieldValue("attendType", ActionType.Modify);
                                }}
                              />
                            </span>
                          )}
                          {currentApplid.deleteApplicationAvailable === true && (
                            <span style={{ marginRight: "12px" }}>
                              <DeleteButton
                                ghost={false}
                                text="削除申請"
                                onClick={() => {
                                  formik.handleSubmit();
                                  formik.setFieldValue("attendType", ActionType.Delete);
                                }}
                              />
                            </span>
                          )}
                        </FormSubmitArea>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={6}
                        offset={{ md: 3 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ marginRight: "12px" }}>
                          <PrimaryButton onClick={() => history.goBack()} text="キャンセル" ghost={true} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </FormPadding>
            </Container>
          </form>
        </FormContents>
      </BlockUI>

      {!isMobile && (
        <div style={{ paddingTop: 20 }}>
          <FormContents customStyle={styles.formContent}>
            <Row style={{ padding: "10px 30px" }}>
              <Col
                md={2}
                xs={12}
                style={{
                  fontWeight: "bold",
                  fontSize: textFontSize.md,
                  lineHeight: "24px",
                  paddingBottom: "20px",
                  color: textColor.main,
                }}
              >
                更新履歴
              </Col>
            </Row>
            <TabControl
              isTabModal={true}
              isFixed={false}
              items={changeLogData.map((log, index: number) => {
                return {
                  value: String(index),
                  display: `ver${log.version}`,
                };
              })}
              setValue={setSelectChangelog as (arg: string) => void}
              currentValue={selectChangelog}
              styleNotActive={css({
                borderRadius: 0,
                border: "none",
                width: "100px",
                flex: "unset",
              })}
              styleActive={css({
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #007BC3",
                width: "100px",
                flex: "unset",
              })}
            />
            {changeLogData.length > 0 &&
              changeLogData.map((itemLog, index: number) => (
                /** 有給 - 介護休暇 - 子の看護休暇 */
                <div
                  style={{
                    display: selectChangelog !== String(index) ? "none" : "block",
                  }}
                >
                  <React.Fragment>
                    <FormPadding
                      customStyle={{
                        fontSize: "14px",
                        color: textColor.mobileTitle,
                      }}
                    >
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          {" "}
                          申請種別:{" "}
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.modifiyStatus == 0 ? "申請" : "削除"}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請者:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationStaffCode && `${itemLog.applicationStaffCode} `}
                          {itemLog.applicationStaffName && itemLog.applicationStaffName}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請日時:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationDate && moment(itemLog.applicationDate).format("YYYY/MM/DD HH:mm:ss")}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "10px",
                            color: textColor.main,
                          }}
                        >
                          出勤組織:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.orgCode && itemLog.orgCode}
                          {itemLog.orgName && itemLog.orgName}
                        </Col>
                      </Row>
                      <Row>
                        <AttendHolidayModal
                          changeLogData={changeLogData}
                          selectChangelog={String(index)}
                          customStyle={styles.approvalWrap}
                        />
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          修正理由:{" "}
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationReason && itemLog.applicationReason}
                        </Col>
                      </Row>
                      {changeLogData.length > 0 && (
                        <Row>
                          <HistoryApprovalTable
                            historyData={itemLog.approvalHistoryList}
                            applicationStatus={itemLog.applicationStatus}
                          />
                        </Row>
                      )}
                    </FormPadding>
                  </React.Fragment>
                </div>
              ))}
          </FormContents>
        </div>
      )}

      <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={() => {
          formik.handleSubmit();
        }}
        title={
          formik.values.attendType === 0 && currentApplid.achievementId === null
            ? "休暇を申請"
            : formik.values.attendType === 1
            ? "休暇キャンセルを申請"
            : formik.values.attendType === 2
            ? "削除休暇を申請"
            : "休暇修正を申請"
        }
        content={
          formik.values.attendType === 0 && currentApplid.achievementId === null
            ? "休暇を申請します。よろしいですか？"
            : formik.values.attendType === 1
            ? "休暇キャンセルを申請します。よろしいですか？"
            : formik.values.attendType === 2
            ? "削除休暇を申請します。よろしいですか？"
            : "休暇修正を申請します。よろしいですか？"
        }
        submitText={"申請する"}
        typeSubmit={
          formik.values.attendType === 0 && currentApplid.achievementId === null
            ? "confirm"
            : formik.values.attendType === 1
            ? "reject"
            : formik.values.attendType === 2
            ? "reject"
            : "confirm"
        }
      />
    </SidebarTemplate>
  );
};

export default HolidayApplicationPage;
