import ApiClient from './ApiClient';

export const getTransfer = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const response = await ApiClient.get(`/v1/attendTransferApplication/transfer/${companyCode}`, {}, params);
  return response.data;
};

export const postTransfer = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v2/attendTransferApplication/transfer/${companyCode}`, {}, params);
  return response.data;
};

export default {
  getTransfer,
  postTransfer,
};
