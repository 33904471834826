
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterICCardImportForm from 'components/organismos/master/MasterICCardImportForm';

const MasterICCardImportPage = () => (
    <SidebarTemplate pageTitle="顔写真登録">
        <MasterICCardImportForm/>
    </SidebarTemplate>
);

export default MasterICCardImportPage;
