/** @jsx jsx */
import PrescribedHours from "domain/master/labor/prescribedHours";
import { Column } from "react-table";
import { useMemo, useCallback, useState } from "react";
import { css, jsx } from "@emotion/core";
import { SubActionButton, SecondaryButton } from "components/atoms/Button";
import DataTable from "components/organismos/DataTable/DataTable";
import { deleteById } from "api/prescribedHours";
import FormContents from "components/atoms/Form/FormContents";
import useToastNotification from "hooks/useToastNotification";
import usePrescribedHoursList from "./hooks";
import SidebarTemplate from "components/templates/SidebarTemplate";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useHistory } from "react-router-dom";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import productColor from "components/styles";
import Modal from "components/molecules/Modal";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";

const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const styles = {
  actionBtn: `
  padding: 0;
  width: 40px;
  text-align: center;
  height: 24px;
  border-radius: 3px;
  color: white;
  margin-left: 4px;
  line-height: 0;
  font-size: 12px;
  &:hover,
  &:focus {
    opacity: 0.9;
    cursor: pointer;
  }
`,
  editBtn: `
  background-color: ${productColor.editAction};
  &:hover,
  &:focus {
    background-color: ${productColor.editAction};
  }
`,
  deleteBtn: `
  background-color: ${productColor.deleteAction};
  &:hover,
  &:focus {
    background-color: ${productColor.deleteAction};
  }
`,
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
  columnOne: css(
    {
      justifyContent: "center",
      width: "140px",
      margin: "0 auto",
    },
    css`
      button {
        white-space: nowrap;
      }
    `
  ),
};
const TimeZonePage = () => {
  const history = useHistory();
  const {
    prescribedList,
    setPrescribedList,
    isLoading,
    detailRole,
  } = usePrescribedHoursList();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedPrescribed, setSelectedPrescribed] = useState(
    PrescribedHours.generateInitial()
  );
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((prescribed: PrescribedHours) => {
    setConfirmModalOpen(true);
    setSelectedPrescribed(prescribed);
  }, []);

  const deletePrescribed = useCallback(() => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    deleteById(companyCode, selectedPrescribed.workingHoursId)
      .then((response: any) => {
        setPrescribedList(
          prescribedList.filter(
            (prescribed) =>
              prescribed.workingHoursId !== selectedPrescribed.workingHoursId
          )
        );
        setConfirmModalOpen(false);
        successNotification("削除しました。");
      })
      .catch((error) => {
        setConfirmModalOpen(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = "";
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    prescribedList,
    selectedPrescribed.workingHoursId,
    setPrescribedList,
    successNotification,
  ]);
  const columns: Array<Column<PrescribedHours>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: PrescribedHours };
          data: Array<PrescribedHours>;
        }) => {
          return (
            <FlexBox customStyle={styles.columnOne}>
              <FlexBoxItem width="36px">
                <SubActionButton
                  action={0}
                  text={detailRole.editable === 1 ? `編集` : `参照`}
                  onClick={() => {
                    history.push(
                      `/masterWorkingHours/edit/${cell.row.original.workingHoursId}`
                    );
                  }}
                />
              </FlexBoxItem>
              {detailRole.editable === 1 ? (
                <FlexBoxItem marginLeft="5px" width="36px">
                  <SubActionButton
                    action={1}
                    onClick={() => {
                      openConfirmModal(cell.row.original);
                    }}
                    text="削除"
                  />
                </FlexBoxItem>
              ) : null}
              {detailRole.editable === 1 ? (
                <FlexBoxItem marginLeft="5px">
                  <SubActionButton
                    action={2}
                    text="コピー"
                    onClick={() => {
                      history.push(
                        `/masterWorkingHours/copy/${cell.row.original.workingHoursId}`
                      );
                    }}
                  />
                </FlexBoxItem>
              ) : null}
            </FlexBox>
            // <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>

            //   <Link to={`/masterWorkingHours/edit/${cell.row.original.workingHoursId}`}>
            //     <span
            //       style={{ marginLeft: '20px' }}
            //       onMouseEnter={() => setIsHoverEdit(true)}
            //       onMouseLeave={() => setIsHoverEdit(false)}
            //     >
            //       <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
            //     </span>
            //   </Link>
            //   <Link to="#" onClick={() => { openConfirmModal(cell.row.original); }}>
            //     <span
            //       style={{ marginLeft: '20px', marginRight: '10px' }}
            //       onMouseEnter={() => setIsHoverDelete(true)}
            //       onMouseLeave={() => setIsHoverDelete(false)}
            //     >
            //       <Icon type="delete" color={isHoverDelete ? iconColor.grayhover : iconColor.gray} />
            //     </span>
            //   </Link>

            // </div>
          );
        },
      },
      {
        Header: "コード",
        accessor: "workingHoursPatternCode",
      },
      {
        Header: "パターン名",
        accessor: "workingHoursPatternName",
      },
    ],
    [openConfirmModal, history, detailRole]
  );
  return (
    <SidebarTemplate>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle bold={true} title="所定労働時間マスタ" />
          {detailRole.editable === 1 && (
            <FormSubmitArea
              customStyle={css({
                marginLeft: "25px",
                width: "80px",
                paddingBottom: "30px",
              })}
            >
              <IconLabelButton
                onClick={() => history.push("/masterWorkingHours/add")}
                iconType="addCircle"
                text="追加"
              />
            </FormSubmitArea>
          )}
          <div
            css={css`
              table {
                table-layout: auto;
              }
              table th:nth-of-type(1) {
                width: 140px;
              }
              table th:nth-of-type(2) {
                width: 100px;
                text-align: left;
              }
              table th:nth-of-type(3) {
                width: calc(100% - 240px);
                text-align: left;
              }
            `}
          >
            <DataTable
              columns={columns}
              data={prescribedList}
              isGlobalFilter={true}
              globalSearchStyle={styles.filterSearch}
              containerStyle={css({
                overflowX: "auto",
              })}
            />
          </div>
          <Modal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            title="所定労働時間を削除"
            submitText="削除する"
            note="所定労働時間を削除します。よろしいですか？"
            onSubmit={deletePrescribed}
            typeSubmit="reject"
          />
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default TimeZonePage;
