import React from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import IconLabelButton from "components/molecules/IconLabelButton";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import Dropdown from "components/molecules/Dropdown";
import productColor from "components/styles";
import Icon from "components/atoms/Icon";
import { DetailRole } from "api/masterRole";
import ContextMenu from "components/molecules/ContextMenu";

const styles = {
  customStyle: css(
    {
      marginRight: " 30px",
      justifyContent: "flex-end",
      zIndex: "revert",
      position: "relative",
    },
    css`
      button:hover {
        background-color: transparent;
      }
    `,
  ),
  customButton: css({
    backgroundColor: "transparent",
  }),
};

const ButtonNavigation: React.FC<{
  downloadOrganization: () => void;
  downloadManagerCsv: () => void;
  downloadSpecialSalaryCsv: () => void;
  detailRole: DetailRole;
}> = ({ downloadOrganization, downloadManagerCsv, downloadSpecialSalaryCsv, detailRole }) => {
  const history = useHistory();
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE === "true" || false;

  const contextMenuButtons = [
    {
      isRed: false,
      isSelectable: true,
      isHidden: false,
      label: "組織情報",
      stamptype: 0,
      onClick: () => {
        return downloadOrganization();
      },
    },
    ...(detailRole.usabilityGeneralItem1 === 1
      ? [
          {
            isRed: false,
            isSelectable: true,
            isHidden: false,
            label: "特賃日割当情報",
            stamptype: 0,
            onClick: () => {
              return downloadSpecialSalaryCsv();
            },
          },
        ]
      : []),
  ];

  return (
    <FlexBox customStyle={styles.customStyle}>
      {detailRole.editable === 1 && (
        <React.Fragment>
          <FlexBoxItem marginLeft="10px">
            {isAuthorizedFromPeople ? (
              ""
            ) : (
              <IconLabelButton onClick={() => history.push("/organizations/add")} iconType="addCircle" text="追加" />
            )}
          </FlexBoxItem>
          {detailRole.importFlag === 1 && (
            <FlexBoxItem marginLeft="10px">
              <IconLabelButton
                onClick={() => history.push("/organizations/csv")}
                iconType="addCircle"
                text="インポート"
              />
            </FlexBoxItem>
          )}
        </React.Fragment>
      )}
      {detailRole.downloadFlag === 1 && (
        <FlexBoxItem marginLeft="10px">
          {/* <IconLabelButton onClick={() => downloadStaffCsv()} iconType="download" text="CSVダウンロード" /> */}
          <ContextMenu
            text="CSVダウンロード"
            buttons={contextMenuButtons}
            position="bottom"
            customStyle={styles.customButton}
            isButtonText={true}
            hiddenDropIcon={true}
          />
        </FlexBoxItem>
      )}
    </FlexBox>
  );
};

export default ButtonNavigation;
