import React from 'react';

import { useParams } from 'react-router-dom';
import AttendanceInputAddForm from './AttendanceInputAddForm';

const AttendanceAddForm = () => {
  const { stampIpId } = useParams();
  return (
    <div>
      <AttendanceInputAddForm stampIpId={stampIpId || ''} />
    </div>
  );
};

export default AttendanceAddForm;
