/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { utilityColor } from "components/styles";

const style = {
  normal: css({
    backgroundColor: utilityColor.white,
    color: "black",
    height: "64px",
    position: "sticky",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    top: "0",
    zIndex: 100,
    borderBottom: "1px solid rgba(220,220,220, 0.8)",
  }),
};

const HeaderBar: React.FC = ({ children }) => (
  <div css={style.normal}>{children}</div>
);

export default HeaderBar;
