import React from 'react';
import { useParams } from 'react-router-dom';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PageTitle from 'components/atoms/PageTitle';
import MediaMasterCreateForm from 'components/organismos/master/sales/MediaMasterCreateForm';

const MediaMasterCreatePage: React.FC = () => {
  const {
    paymentMediaMstCode,
  } = useParams();
  return (
    <SidebarTemplate>
      <PageTitle title={paymentMediaMstCode ? '支払メディア編集' : '支払メディア作成'} />
      <MediaMasterCreateForm />

    </SidebarTemplate>
  );
};

export default MediaMasterCreatePage;
