import React from 'react';
import { useParams } from 'react-router-dom';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton } from 'components/atoms/Button';
import usePostTypeOptions from 'hooks/usePostTypeOptions';
import DatePickerForm from 'components/molecules/DatePickerForm';
import Checkbox from 'components/molecules/Checkbox';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import { css } from '@emotion/core';
import usePaymentMediaOptions from 'hooks/usePaymentMediaOptions';
import PaymentMediaOrganizationDomain from 'domain/master/sales/paymentMediaOrganization';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import usePaymentImportForm from './hooks';
import SelectForm from 'components/molecules/SelectForm';

const PaymentImportCreateForm: React.FC = () => {
  const { importCode, postType, orgCode } = useParams();
  const {
    formik, confirmModalOpen,
    closeConfirmModal, history,
  } = usePaymentImportForm(orgCode, importCode, postType);

  const postTypeOptions = usePostTypeOptions();
  const paymentMediaOptions = usePaymentMediaOptions();
  // Set default value
  if (formik.values.paymentMediaCode === '' && paymentMediaOptions.length !== 0) {
    formik.setFieldValue('paymentMediaCode', paymentMediaOptions[0].value);
  }
  if (formik.values.postType === '' && postTypeOptions.length !== 0) {
    formik.setFieldValue('postType', postTypeOptions[0].value);
  }
  return (
    <FormContents>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormField>
            <div style={{ width: '100%' }}>
              <VerticalLabelSelectForm
                label="支払メディア"
                name="paymentMediaCode"
                value={formik.values.paymentMediaCode}
                options={paymentMediaOptions}
                setValue={(val: string) => { formik.setFieldValue('paymentMediaCode', val); }}
                errorMsg={formik.errors.paymentMediaCode}
                required={true}
                disable={!!importCode}
              />
            </div>
          </FormField>
          <FormField>
            <div style={{ width: '100%' }}>
              <VerticalLabelSelectForm
                label="POS種別"
                name="postType"
                value={String(formik.values.postType)}
                options={postTypeOptions}
                setValue={(val: string) => { formik.setFieldValue('postType', Number(val)); }}
                errorMsg={formik.errors.postType}
                required={true}
                disable={!!importCode}
              />
            </div>
          </FormField>
          <FormField>
            <TextForm
              label="取込コード"
              name="importCode"
              value={formik.values.importCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('importCode', (e.target.value))}
              errorMsg={formik.errors.importCode}
              required={true}
              disable={!!importCode}
            />
          </FormField>

          <FormField>
            <FlexBox
              customStyle={css({ width: '50%' })}
            >
              <FlexBoxItem basis="80%">
                <DatePickerForm
                  dateFormat="yyyy年MM月"
                  label="適用開始月"
                  date={formik.values.applicableStartMonth}
                  changeDate={(date: Date) => {
                    if (date == null) {
                      formik.setFieldValue('applicableStartMonth', undefined);
                    } else {
                      formik.setFieldValue('applicableStartMonth', date);
                    }
                  }}
                  showMonthYearPicker={true}
                  required={true}
                  disable={!!importCode}
                  isShowDateOnDisable={true}
                />
              </FlexBoxItem>
              <FlexBoxItem basis="20%" marginLeft="20px" />

            </FlexBox>

          </FormField>
          <FormField>
            <FlexBox
              customStyle={css({ width: '50%' })}
            >
              <FlexBoxItem basis="80%">
                <DatePickerForm
                  dateFormat="yyyy年MM月"
                  label="適用終了月"
                  date={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-' ? undefined : formik.values.applicableEndMonth}
                  changeDate={(date: Date) => {
                    if (date == null) {
                      formik.setFieldValue('applicableEndMonth', new Date('9999-12-31'));
                    } else {
                      formik.setFieldValue('applicableEndMonth', date);
                    }
                  }}

                  showMonthYearPicker={true}
                  disable={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-'}
                  isShowDateOnDisable={false}
                />
              </FlexBoxItem>
              <FlexBoxItem basis="20%" marginLeft="20px">
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    id="unDecided"
                    name="unDecided"
                    label="未定"
                    value={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-' ? 'checked' : 'unChecked'}
                    checked={formik.values.applicableEndMonthStr === '9999年12月' || formik.values.applicableEndMonthStr === '-'}
                    onChange={(e) => {
                      if (e.target.value === 'checked') {
                        formik.setFieldValue('applicableEndMonth', new Date());
                      } else {
                        formik.setFieldValue('applicableEndMonth', new Date('9999-12-31'));
                      }
                    }}
                  />
                </div>
              </FlexBoxItem>
            </FlexBox>
          </FormField>

        </div>

        <FormSubmitArea>
          <div style={{ marginRight: '12px' }}>
            <Button
              text={importCode ? '更新' : '登録'}
              onClick={formik.handleSubmit}
            />
          </div>
          <div style={{ marginRight: '12px' }}>
            <Button
              text="クリア"
              onClick={() => {
                formik.setValues(PaymentMediaOrganizationDomain.generateInitial());
                history.replace(`/salesPaymentMedia/paymentSetting/add/${orgCode}`);
              }}
            />
          </div>
          <PrimaryButton
            text="戻る"
            onClick={() => {
              history.replace('/salesPaymentMedia/paymentSetting');
            }}
            ghost={true}
          />
        </FormSubmitArea>
      </form>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={importCode ? ActionType.UPDATE : ActionType.CREATE}
      />
    </FormContents>
  );
};
export default PaymentImportCreateForm;
