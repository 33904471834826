/** @jsx jsx */
import React, {
  useState, useEffect
} from 'react';

import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import productColor, {
  utilityColor,
  grayScale,
  textFontSize,
} from 'components/styles';
import { css, jsx } from '@emotion/core';
import ConfirmModal from 'components/organismos/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import TextInput from 'components/atoms/Form/TextInput';
import Checkbox from 'components/molecules/Checkbox';
import { useTimeOutputLayoutDomainAddForm } from './hooks';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import IconLabelButton from 'components/molecules/IconLabelButton';
import {
  isSafari, isIPad13, isTablet,
} from "react-device-detect";
import FormTitle from "components/atoms/Form/FormTitle";
import FormContents from 'components/atoms/Form/FormContents';
import BlockUI from 'components/molecules/BlockUi';

// create style form
const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  cellTable: css({
    '.sortable-ghost':{
      border: `dotted ${productColor.primary}`
    },
    width: '100%',
    border: 'none',
    borderSpacing: '0px 1.5px',
    overflow: 'scroll',
  }, css`
    @media only screen and (min-width:1580px){
      .widthDesktopLarge{
        width:calc(100vw - 500px) !important;
      }
    }
  `),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: '4px',
    float: 'right',
    marginTop: '-37px',
  }),
  title: css(
    {
      paddingLeft: '30px',
      marginTop: '30px',
      marginBottom: '0px',
      fontSize: '16px',
      color: '#666666'
    }
  ),
  customCheckbox: css({
    'path':{
      fill: productColor.primary
    }
  }),
  tableScroll: css({
    height: 'calc(50vh)',
    overflowY: 'auto',
    borderCollapse: 'collapse',
    borderTop: `1px solid ${grayScale.gray03}`,
    'thead th':{
      position: 'sticky',
      top: -1,
      zIndex: 2,
      borderTop: '0!important',
      borderBottom: '0!important',
      boxShadow: `inset 0 -1px 0 ${grayScale.gray03}`,
    },
    'tbody tr:first-child':{
      'td':{
        borderTop: '0!important'
      }
    }
  })
};
const customStyle = {
  marginBottom: '30px',
  borderRadius: '5px',
  padding: "0 30px",
}
//
const baseItemHour = 1;
const baseItemDay = 2;

const TimeOutputLayoutInputAddForm: React.FC<{ layoutId: string }> = ({
  layoutId,
}) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    detailList,
    setDetailList,
    getConfirmModalAddOutPutBaseItemsOpen,
    closeConfirmModalAddOutPutBaseItemsOpen,
    setConfirmModalAddOutPutBaseItemsOpen,
    isLoading,
    detailRole
  } = useTimeOutputLayoutDomainAddForm(layoutId);

  const [baseItemType, setBaseItemType] = useState(baseItemHour);
  const [arraysPicked, setArraysPicked] = useState([]);

  // history
  const history = useHistory();

  //
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  handle on change output for detail list
   */
  const handleChangeOutput = async (outputItemId: any, e: any) => {
    const items = [...detailList];
    items.filter((item: any) => {
      if (item.outputItemId === outputItemId) {
        if (item.output === true) {
          item.output = false;
        } else {
          item.output = true;
        }
        return true;
      }
      return false;
    });
    setArraysPicked(items)
  };

  /**
   *  handle on change item name  for detail list
   */
  const handleChangeItemName = async (outputItemId: any, e: any) => {
    const items = [...detailList];
    items.filter((item: any) => {
      if (item.outputItemId === outputItemId) {
        item.outputItemName = e.target.value;
        return true;
      }
      return false;
    });
    setDetailList(items);
  };

  /**
   *  handle click button add
   */
  const handleClickButtonAddOutputBaseItems = async (bItemType: any) => {
    setBaseItemType(bItemType);
    setConfirmModalAddOutPutBaseItemsOpen(true);
  };

  /**
   *  handle back
   */
  const handleBack = async () => {
    history.push('/masterTimeOutputLayout');
  };

  const handleSubmitPopup = () => {
    if (baseItemType === 1) {
      history.push('/masterTimeOutputLayout/masterTimeOutputItem');
    } else if (baseItemType === 2) {
      history.push('/masterTimeOutputLayout/masterDayOutputItem');
    }
  }

function handleNewDetailList(arraysPicked:any) {
  if(arraysPicked?.length > 0){
    setDetailList(arraysPicked);
  }
  setDetailList(detailList);
}

  return (
    <BlockUI blocking={isLoading}>
    <FormContents customStyle={customStyle}>
      <FormTitle bold={true} title="勤怠確定出力レイアウト" customStyle={css({marginLeft: '0'})}/>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div style={{ backgroundColor: '#fff', paddingBottom: '10px', border: '1px solid #dcdcdc' }}>
            <div style={{ padding: '30px', maxWidth: '400px' }}>
              <div style={{ marginBottom: '20px' }}>
                <FlexBox>
                  <FlexBoxItem width="150px">
                    <FormLabel label="レイアウトコード" />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <TextForm
                      name="layoutCode"
                      label=""
                      value={String(formik.values.layoutCode)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.layoutCode}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <FlexBox>
                  <FlexBoxItem width="150px">
                    <FormLabel label="レイアウト名" />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <TextForm
                      name="layoutName"
                      label=""
                      value={String(formik.values.layoutName)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.layoutName}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </div>
              {detailRole.editable === 1 && (
                <FormSubmitArea>
                  <div style={{ display: 'flex', fontSize: '14px' }} css={css`
                    .button-action button:hover{
                      background-color:transparent;
                    }
                  `}>
                    <div style={{ marginRight: '12px', marginLeft: '-10px' }} className="button-action">
                      <IconLabelButton iconType="addCircle" text="勤務時間項目を追加" onClick={() => handleClickButtonAddOutputBaseItems(baseItemHour)} />
                    </div>
                    <div className="button-action">
                      <IconLabelButton iconType="addCircle" text="勤務日数項目を追加" onClick={() => handleClickButtonAddOutputBaseItems(baseItemDay)} />
                    </div>
                  </div>
                </FormSubmitArea>
              )}
            </div>
            <div css={
              css`
                table{
                  border-collapse: collapse;
                  font-size:14px
                }
                table tr th{
                  border:1px solid #dcdcdc
                }
                table tr td{
                  border:1px solid #dcdcdc
                }
                table tr td:first-child{
                  border-left:0px;
                  text-align:center
                } 
                table tr td:last-child{
                  border-right:0px
                }
                .option-checkbox input{
                  max-width:70%
                }
                .message-note{
                  max-width: 90%;
                  margin: 0 auto;
                  color: #007BC3;
                  font-weight: normal;
                }
              `
            }>
              <div className="message-note">
                <p style={{ color: productColor.primary, fontSize: '14px' }}>並び順はドラッグ＆ドロップにより変更できます</p>
              </div>
            <div css={styles.tableScroll}>
              <table className="table-bordered" css={styles.cellTable}>
                <thead>
                  <tr>
                    <th style={{ width: '100px' }}>
                      <span>出力</span>
                    </th>
                    <th style={{ width: '400px', textAlign: 'left' }}>
                      <span>出力項目名</span>
                    </th>
                    <th className="widthDesktopLarge" style={{ width: 'calc(100vw - 500px)', textAlign: 'left' }}>
                      <span style={{ display: 'block' }}>出力内容</span>
                    </th>
                  </tr>
                </thead>
                    <ReactSortable list={detailList} setList={setDetailList} tag={'tbody'}
                    >
                      {detailList.map((item: any, index: any) => (
                        <tr key={index} style={{ backgroundColor: '#ffffff' }}>
                          <td style={{ padding: '10px', width: '100px', textAlign: 'center' }}>
                            <Checkbox
                              id={`itemOutput${item.outputItemId}`}
                              name={`itemOutput${item.outputItemId}`}
                              value={item.outputItemId}
                              checked={Boolean(item.output)}
                              onChange={(e) => handleChangeOutput(item.outputItemId, e)}
                              customStyle={styles.customCheckbox}
                            />
                          </td>
                          <td style={{ width: '400px', padding: '10px' }} className="option-checkbox">
                            <TextInput
                              name={`outputItemName${item.outputItemId}`}
                              value={item.outputItemName}
                              readOnly={!item.output}
                              customStyle={
                                !item.output
                                  ? css({ maxWidth: '70%', backgroundColor: grayScale.gray05 })
                                  : css({ border: `1px solid ${utilityColor.black}`})
                              }
                              onChange={(e) => handleChangeItemName(item.outputItemId, e)}
                            />
                          </td>
                          <td style={{ padding: '10px', width: 'calc(100vw - 500px)', verticalAlign: 'middle' }}>
                            <span style={{ display: 'block' }}>
                              {item.outputItemContent}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </ReactSortable>
              </table>
            </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', margin: '15px 0' }}>
            {detailRole.editable === 1 && (
              <div>
                <Button
                  text={layoutId ? '更新' : '登録'}
                  onClick={formik.handleSubmit}
                />
              </div>
            )}
            <div>
              <PrimaryButton
                text="キャンセル"
                onClick={() => handleBack()}
                ghost={true}
              />
            </div>
          </div>
        </div>

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={() => {
            formik.handleSubmit();
            handleNewDetailList(arraysPicked);
          }}
          title={layoutId ? '出力レイアウトの更新' : '出力レイアウトの登録'}
          content={layoutId ? `勤務確定出力レイアウトを更新します。よろしいですか？` : `勤務確定出力レイアウトを登録します。よろしいですか？`}
          submitText={layoutId ? '更新する' : '登録する'}
        />
        <ConfirmModal
          open={getConfirmModalAddOutPutBaseItemsOpen}
          closeHandler={closeConfirmModalAddOutPutBaseItemsOpen}
          onSubmit={handleSubmitPopup}
          title="編集内容の破棄"
          content={`編集中の内容は破棄されます。よろしいですか？`}
          submitText={`破棄`}
          typeSubmit={`reject`}
        />

      </form>
    </FormContents>
    </BlockUI>
  );
};

export default TimeOutputLayoutInputAddForm;
