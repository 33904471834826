/** @jsx jsx */

import React, { useEffect } from "react";

import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { PrimaryButton, SecondaryButton } from "components/atoms/Button";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import DataTable from "components/organismos/DataTable/DataTable";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import { useSpecialSalary } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import { Table } from "react-bootstrap";
import OrganizationSpecialSalaryEdit from "components/organismos/organization/OrganizationSpecialSalaryEditForm/OrganizationSpecialSalaryEdit/OrganizationSpecialSalaryEdit";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import { SpecialSalaryItem } from "domain/master/labor/organizationSpecialSalary";
import TabControl from "components/atoms/TabControl";
import FormPadding from "components/atoms/Form/FormPadding";
import FormLabel from "components/atoms/Form/FormLabel";
import FormField from "components/atoms/Form/FormField";
import Modal from "components/molecules/Modal";
import FormContents from "components/atoms/Form/FormContents";
import { Col, Row } from "react-grid-system";
import { textColor, textFontSize } from "components/styles";
import FlexBox from "components/atoms/FlexBox";
import OptionalCalendarMonth from "components/molecules/CalendarV1/OptionalCalendarMonth";
import moment from "moment";

const styles = {
  form: css({
    margin: "16px",
  }),
  table: css(
    {
      width: "100%",
      borderLeft: "1px solid #ddd",
      borderRight: "1px solid #ddd",
    },
    css`
      table {
        width: 100%;
      }
      table tr th {
        font-weight: bold;
      }
      table tr th,
      table tr td {
        text-align: left;
      }
      table tr th:nth-child(1) {
        width: auto;
      }
      table tr th:nth-child(2) {
        width: 180px;
      }
      table tr th:nth-child(3) {
        width: 30px;
      }
    `,
  ),
  buttonSubmit: css(
    {
      marginLeft: "30px",
    },
    css`
      button {
        width: auto;
        display: inline-block;
      }
    `,
  ),
  formContent: {
    paddingBottom: "20px",
  },
};

const OrganizationSpecialSalaryEditForm: React.FC<{
  isEditMode: boolean;
  isEditable: number;
}> = ({ isEditMode, isEditable }) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    confirmDeleteModalOpen,
    closeDeleteConfirmModal,
    setConfirmDeleteModalOpen,
    blocking,
    addSpecialSalary,
    deleteSpecialSalaryRow,
    onSubmitDeleteSpecialSalary,
    isEdit,
  } = useSpecialSalary(isEditMode);
  const [selectHistoryTab, setSelectHistoryTab] = React.useState<string>("");

  useEffect(() => {
    const orgApplyStartDateHistoryLt = formik.values.orgApplyStartDateHistoryLt ?? [];

    if (orgApplyStartDateHistoryLt.length > 0) {
      setSelectHistoryTab(orgApplyStartDateHistoryLt[0]);
    }
  }, [formik.values.orgApplyStartDateHistoryLt]);

  const columns: Array<Column<SpecialSalaryItem>> = [
    {
      Header: "適用期間",
      accessor: "applyMonth",
      Cell: (data: { row: { original: SpecialSalaryItem } }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            gap: "8px",
          }}
        >
          <span>{moment(data.row.original.id.applyStartMonth).format("YYYY年MM月")}</span>
          <span>-</span>
          <span>
            {data.row.original.applyEndMonth ? moment(data.row.original.applyEndMonth).format("YYYY年MM月") : ""}
          </span>
        </div>
      ),
    },
    {
      Header: "パターンコード",
      accessor: "specialSalaryPatternCode",
    },
    {
      Header: "パターン名",
      accessor: "specialSalaryPatternName",
    },
    {
      Header: "加算額",
      accessor: "addSalary",
    },
  ];

  return (
    <BlockUI blocking={blocking}>
      <form onSubmit={formik.handleSubmit} css={styles.form}>
        <FormField>
          <div style={{ marginBottom: "12px" }}>
            <VerticalLabelFormLayout
              label={<FormLabel label="適用期間" required={true} />}
              input={
                <FlexBox>
                  <OptionalCalendarMonth
                    date={formik.values.applyStartMonth ? new Date(formik.values.applyStartMonth) : undefined}
                    setDate={(date) => {
                      formik.setFieldValue("applyStartMonth", date);
                    }}
                  />
                  <span style={{ margin: "10px" }}>～</span>
                  <OptionalCalendarMonth
                    date={formik.values.applyEndMonth ? new Date(formik.values.applyEndMonth) : undefined}
                    setDate={(date) => {
                      formik.setFieldValue("applyEndMonth", date || null);
                    }}
                    showDeleteIcon
                  />
                </FlexBox>
              }
              errorMsg={formik.errors["applyStartMonth"] !== undefined ? "日付は必須です" : ""}
            />
          </div>
        </FormField>

        <div css={styles.table}>
          <Table className="table-bordered">
            <thead>
              <tr>
                <th>特賃日パターン</th>
                <th>加算額</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {formik.values.orgSpecialList?.map((specialSalary: any, index: number) => (
                <OrganizationSpecialSalaryEdit
                  key={index.toString()}
                  formik={formik}
                  specialSalary={specialSalary}
                  index={index}
                  deleteSpecialSalary={deleteSpecialSalaryRow(index)}
                />
              ))}
            </tbody>
          </Table>
        </div>
        <div css={styles.buttonSubmit}>
          <IconLabelButton onClick={addSpecialSalary} iconType="addCircle" text="特賃日情報追加" />
        </div>

        <FormSubmitArea customStyle={css({ textAlign: "center", marginTop: "20px" })}>
          <React.Fragment>
            {isEditable === 1 && (
              <React.Fragment>
                <PrimaryButton text={isEdit ? "更新" : "登録"} onClick={formik.handleSubmit} />
                {isEdit && (
                  <SecondaryButton
                    text="削除"
                    customStyle={css({ marginLeft: "15px" })}
                    minWidth="120px"
                    onClick={() => setConfirmDeleteModalOpen(true)}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
          <FormField displayBlock={true}>
            <Link to="/organizations">
              <PrimaryButton text="キャンセル" ghost={true} />
            </Link>
          </FormField>
        </FormSubmitArea>

        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={isEdit ? ActionType.UPDATE : ActionType.CREATE}
        />
        <Modal
          typeSubmit="reject"
          open={confirmDeleteModalOpen}
          closeHandler={closeDeleteConfirmModal}
          submitText="削除"
          title="組織を削除"
          onSubmit={onSubmitDeleteSpecialSalary}
        >
          <div
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: "14px",
              color: "#666666",
              marginBottom: "15px",
            }}
          >
            最新の割当情報を削除します。よろしいですか？
          </div>
        </Modal>
      </form>
      {selectHistoryTab &&
        formik.values.orgApplyStartDateHistoryLt &&
        formik.values.orgApplyStartDateHistoryLt.length > 0 && (
          <div style={{ paddingTop: 20 }}>
            <FormContents customStyle={styles.formContent}>
              <Row style={{ padding: "10px 30px" }}>
                <Col
                  md={2}
                  xs={12}
                  style={{
                    fontWeight: "bold",
                    fontSize: textFontSize.md,
                    lineHeight: "24px",
                    paddingBottom: "20px",
                    color: textColor.main,
                  }}
                >
                  割当履歴
                </Col>
              </Row>
              <TabControl
                items={formik.values.orgApplyStartDateHistoryLt.map((item) => ({
                  value: item,
                  display: item,
                }))}
                setValue={(value) => setSelectHistoryTab(value)}
                currentValue={selectHistoryTab}
                styleNotActive={css({ borderRadius: 0, border: "none" })}
                styleActive={css({
                  borderRadius: 0,
                  border: "none",
                  borderBottom: "1px solid #007BC3",
                })}
              />
            </FormContents>
          </div>
        )}

      {formik.values.orgSpecialHistoryMap &&
        formik.values.orgSpecialHistoryMap[selectHistoryTab] &&
        formik.values.orgSpecialHistoryMap[selectHistoryTab].length !== 0 && (
          <FormPadding>
            <DataTable columns={columns} data={formik.values.orgSpecialHistoryMap[selectHistoryTab]} />
          </FormPadding>
        )}
    </BlockUI>
  );
};

export default OrganizationSpecialSalaryEditForm;
