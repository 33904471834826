import React, { useState, useEffect } from 'react';

type StateType = string | number | Array<any> | Object;

const useSaveStateStorage = (defaultValue: StateType, name: string, id: number = 0) => {

  const [state, setState] = useState<null | typeof defaultValue>(null);

  const groupUrl = window.location.pathname.split('/')[1];
  useEffect(() => {
    if (state !== null) {
      sessionStorage.setItem(`${id}/sessionData/${groupUrl}.${name}`, JSON.stringify(state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    let sessionData = sessionStorage.getItem(`${id}/sessionData/${groupUrl}.${name}`);

    if (sessionData !== null) {
      setState(JSON.parse(sessionData));
    } else {
      setState(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return [state, setState];
}


export const useSaveStateDateStorage = (defaultValue: Date, name: string, id: number = 0) => {

  const [state, setState] = useState<Date>(new Date());

  const groupUrl = window.location.pathname.split('/')[1];
  useEffect(() => {
    let sessionData = sessionStorage.getItem(`${id}/sessionData/${groupUrl}.${name}`);
    if (sessionData !== null) {
      setState(JSON.parse(sessionData));
      return;
    }

    if (state !== null) {
      sessionStorage.setItem(`${id}/sessionData/${groupUrl}.${name}`, JSON.stringify(state));
    } else {
      setState(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return [state, setState];
}

export default useSaveStateStorage;