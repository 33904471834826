/** @jsx jsx */
import React, { useContext } from "react";
import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import productColor, { grayScale } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import moment from "moment";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import { useAcquisitionStatusDomainForm, useDownloadHolidayManagementAcquisitionStatus } from "./hooks";
import IconLabelButton from "components/molecules/IconLabelButton";
import CalendarForm from "components/molecules/CalendarForm";
import BlockUI from "components/molecules/BlockUi";
import StockPaidHolidayDigestedtHistoryDomain from "domain/master/holidayManagement/stockpaidHolidayDigestedtHistory";
import Checkbox from "components/molecules/Checkbox";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { HolidayManagementContext } from "../../HolidayManagementForm";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  detail: css({
    backgroundColor: "transparent",
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  digestionDays: css({
    width: "200px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
    color: "#666666",
  }),
  selectDay: css({
    minWidth: "100px",
    width: "170px",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
        text-align: center;
      }
    `,
  ),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  note: css({
    paddingLeft: "5px",
    fontSize: "14px",
    color: "#666666",
    position: "relative",
  }),
  modal: css({
    padding: "0px 0px 16px",
    margin: "0px",
    fontSize: "14px",
    textAlign: "center",
    color: "rgb(102, 102, 102)",
  }),
};

const AcquisitionStatusForm: React.FC<{ orgTreesOptions: any }> = ({ orgTreesOptions }) => {
  const {
    paidHolidayDigestedtHistoryList,
    callGetAcquisitionStatusDetail,
    orgCode,
    setOrgCode,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    setDetailItems,
    underFlag,
    setUnderFlag,
    dataDetail,
    setDataDetail,
  } = useAcquisitionStatusDomainForm();

  const { downloadHolidayManagementAcquisitionStatus, blocking } = useDownloadHolidayManagementAcquisitionStatus(
    orgCode,
    targetDateFrom,
    targetDateTo,
    underFlag,
  );

  //

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTransferHolidayStatusDetail = async (data: any) => {
    setDetailItems(data);
    setDataDetail(data);
    callGetAcquisitionStatusDetail(data.staffCode, targetDateFrom, targetDateTo);
    setModalOpen(true);
  };
  const contextObject = useContext(HolidayManagementContext);
  //
  const columns: Array<Column<StockPaidHolidayDigestedtHistoryDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: StockPaidHolidayDigestedtHistoryDomain;
          };
          data: Array<StockPaidHolidayDigestedtHistoryDomain>;
        }) => (
          <FlexBox>
            {cell.row.original.orgCode} {cell.row.original.orgName}
          </FlexBox>
        ),
      },
      {
        Header: "スタッフ",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: {
            isExpanded: boolean;
            original: StockPaidHolidayDigestedtHistoryDomain;
          };
          data: Array<StockPaidHolidayDigestedtHistoryDomain>;
        }) => (
          <FlexBox>
            {cell.row.original.staffCode} {cell.row.original.staffName}
            <IconLabelButton
              onClick={() => handleTransferHolidayStatusDetail(cell.row.original)}
              text="詳細"
              isIcon={false}
            />
          </FlexBox>
        ),
      },
      {
        Header: "取得日数",
        accessor: "total",
      },
    ],
    [handleTransferHolidayStatusDetail],
  );

  return (
    <BlockUI blocking={blocking}>
      <Accordion text="取得状況" callBack={() => {}}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="1100px">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                {/* <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateFrom)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateFrom(date)} />
                </div>
                <div css={styles.betweenDays}>
                  ～
                </div>
                <div css={styles.digestionDays}>
                  <CalendarForm required={true} label="" date={new Date(targetDateTo)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setTargetDateTo(date)} />
                </div> */}
                <FlexBoxItem>
                  <CalendarRangeV2
                    startDate={new Date(targetDateFrom)}
                    onChangeStart={(date: Date) => setTargetDateFrom(date)}
                    endDate={new Date(targetDateTo)}
                    onChangeEnd={(date) => {
                      if (date) {
                        setTargetDateTo(date);
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode003"
                width="900px"
                value={String(orgCode)}
                setValue={(val: string) => {
                  setOrgCode(val);
                }}
                options={orgTreesOptions}
                marginRight="30px"
              />
              <Checkbox
                id={"underFlag12"}
                name={"underFlag12"}
                label={"配下指定"}
                value={`underFlag12`}
                checked={Boolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 200px;
              }
              table th:nth-of-type(2) {
                width: 200px;
              }
              table th:nth-of-type(3) {
                width: 160px;
              }
              table td:nth-of-type(3) {
                text-align: center;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag === 1 && (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementAcquisitionStatus()}
                  iconType="download"
                  text="ダウンロード"
                />
              </FormSubmitArea>
            )}
            <div className="table-bordered">
              <DataTable
                columns={columns}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                data={paidHolidayDigestedtHistoryList}
                isGlobalFilter={true}
                globalSearchStyle={styles.globalSearch}
              />
            </div>
          </div>
          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="取得状況詳細"
            submitText=""
            onSubmit={() => {}}
            closeText={"閉じる"}
          >
            <div style={{ width: "900px", margin: "0 auto", fontSize: "14px" }} css={styles.table}>
              <VerticalLabelTextLayout label="スタッフコード" variable={dataDetail.staffCode} />
              <VerticalLabelTextLayout label="スタッフ名" variable={dataDetail.staffName} />

              <VerticalLabelTextLayout
                label="期間"
                variable={`${moment(detailItems.fromDate).format("YYYY年MM月DD日")}～${moment(
                  detailItems.toDate,
                ).format("YYYY年MM月DD日")}`}
              />

              <table className="table-bordered" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>日付</th>
                    <th>消化日数</th>
                  </tr>
                </thead>
                <tbody>
                  {detailItems.stockPaidHolidayDigestionDetailList &&
                    detailItems.stockPaidHolidayDigestionDetailList.map((item: any) => (
                      <tr>
                        <td>{moment(item.targetDate).format("YYYY年MM月DD日")}</td>
                        <td>{`${item.digestionDaysNum}日`}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default AcquisitionStatusForm;
