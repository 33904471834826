import SidebarTemplate from 'components/templates/SidebarTemplate';
import React from 'react';
import KaorioHistoryLayout from 'components/organismos/master/atendanceManagement/KaorioHistoryLayout';
 
const KaoriaoHistoryPage = () => (
  <SidebarTemplate>
    <KaorioHistoryLayout />
  </SidebarTemplate>
);

export default KaoriaoHistoryPage;
