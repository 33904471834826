/** @jsx jsx */
import React from "react";
import BodyText from "components/atoms/BodyText";
import { css, jsx } from "@emotion/core";
import Table from "components/molecules/Table";
import TextTooltip from "../TextTooltip"; 

const styles = {
  maxWidthTooltip: css({
    maxWidth: '200px'
  })
}

const LastCellFormat: React.FC<{
  index: Number,
  lengthListAtLevel: any,
  statusApproval: any,
  item: any
  priorStatus: any,
  applicationStatus: any,
}> = ({
  index,
  lengthListAtLevel,
  statusApproval,
  item,
  priorStatus,
  applicationStatus
}) => {

    const TableCellFormatLevelIsTwo = () => {
      if (priorStatus === 1) {
        return (
          <BodyText color={statusApproval.approved}>承認済み</BodyText>
        )
      } else {
        if (priorStatus === 2) {
          return (
            <React.Fragment>
              <BodyText color={statusApproval.rejected}>差戻</BodyText>
              <TextTooltip text={item.rejectReason} textDis={"コメント"} tooltipCSs={styles.maxWidthTooltip}/>
            </React.Fragment>
          )
        } else {
          if (item.status === 3) {
            return (
              <BodyText>-</BodyText>
            )
          } else {
            if (applicationStatus !== 4) {
              return (
                <BodyText color={statusApproval.doNotKnown}> 未承認 </BodyText>
              )
            } else {
              return (
                <BodyText>-</BodyText>
              )
            }
          }
        }
      }
    }

    const TableCellFormatLevelIsOne = () => {
      if (item.status === 1) {
        return (
          <BodyText color={statusApproval.approved}>
            承認済み
          </BodyText>
        )
      } else {
        if (item.status === 2) {
          return (
            <React.Fragment>
              <BodyText color={statusApproval.rejected}>差戻</BodyText>
              <TextTooltip text={item.rejectReason} textDis={"コメント"} tooltipCSs={styles.maxWidthTooltip}/>
            </React.Fragment>
          )
        } else if (item.status === 3) {
          return (
            <BodyText>-</BodyText>
          )
        } else if (applicationStatus !== 4) {
          return (
            <BodyText color={statusApproval.doNotKnown}>
              未承認
            </BodyText>
          )
        } else {
          return (
            <BodyText>-</BodyText>
          )
        }
      }
    }

    if (index === 0 && lengthListAtLevel > 1) {
      return (
        <Table.Cell rowSpan={lengthListAtLevel} >
          {TableCellFormatLevelIsTwo()}
        </Table.Cell>
      )
    } else {
      if ((lengthListAtLevel <= 1)) {
        return (
          <Table.Cell>
            {TableCellFormatLevelIsOne()}
          </Table.Cell>
        )
      } else {
        return (
          <React.Fragment></React.Fragment>
        )
      }
    }
  };

export default LastCellFormat;
