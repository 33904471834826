/* eslint-disable max-len */
/** @jsx jsx */
import { useEffect, useState } from "react";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";
import SelectForm from "components/molecules/SelectForm";
import FormField from "components/atoms/Form/FormField";

import {
  hopeShiftLimitFlagLabel,
  hopeShiftLimitFlag,
  //  mwsDisplayFlagLabel, mwsDisplayFlag,
  mwsDisplayStartDowLabel,
  mwsDisplayStartDow,
  shiftCreateFlag,
  shiftCreateFlagLabel,
} from "domain/master/labor/organizationSetting";
import FormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import HourInput from "components/atoms/Form/HourInput";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button from "components/atoms/Button";
import BodyText from "components/atoms/BodyText";
import { grayScale, utilityColor } from "components/styles";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import { useSettingUpdateForm } from "./hooks";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import { css, jsx } from "@emotion/core";
import { Row, Col } from "react-grid-system";
import { isIPad13 } from "react-device-detect";
import React from "react";
import { OptionType } from "components/atoms/Select";
import FormTitle from "components/atoms/Form/FormTitle";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import BlockUI from "components/molecules/BlockUi";
import { functionCode } from "const";

const styles = {
  form: css({
    // border: '1px solid #dcdcdc'
  }),
  formSubmit: css({
    textAlign: "center",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
  }),
  wrap: css({
    backgroundColor: "#fff",
    border: "1px solid #dcdcdc",
  }),
  formFilter: css({
    maxWidth: "90%",
    paddingTop: "10px",
    margin: "auto",
  }),
  colorRed: css({
    color: "#E95562",
    marginLeft: "20px",
    display: "block",
  }),
  flexBoxTop: css({
    alignItems: "flex-start",
  }),
  label: css({
    position: "relative",
    top: "20px",
  }),
  help: css({
    color: "red",
    bottom: "-13px",
    left: "10px",
    position: "relative",
  }),
  span: css({
    marginRight: "20px",
    marginLeft: "4px",
    position: "relative",
    top: "-2px",
    fontSize: "14px",
    color: "#666666",
  }),
  addon: css({
    color: grayScale.gray100,
    padding: "7px",
  }),
  flexBox: css({
    marginBottom: "15px",
  }),
  radio: css`
    label span:first-child {
      padding-bottom: 3px;
    }
    label {
      margin-right: 20px;
    }
  `,
  hourInput: css`
    input {
      flex: auto;
      width: 60px;
      margin-right: 15px;
    }
  `,
};

const listDays = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const listMonths = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const validateData = (value: number, valueDefault: string) => {
  return value > 0 ? (value < 10 ? "0" + Number(value) : value) : valueDefault;
};

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

const MasterOrganizeSettingLayout = () => {
  const orgTreesOptions = useOrgTreesOptionsShopV1(false, functionCode.masterAttendSetting, undefined, undefined);
  const [orgCode, setorgCode] = useState(sessionStorage.getItem("loginUser.orgCode") || "");
  const { formik, confirmModalOpen, closeConfirmModal, isLoading, detailRole } = useSettingUpdateForm(orgCode);

  const [monthFormOptions, setMonthFormOptions] = useState<Array<OptionType>>([]);
  const [dayFormOptions, setDayFormOptions] = useState<Array<OptionType>>([]);
  let nursingYearStartMonthDefault = validateData(formik.values.nursingYearStartMonth, "04");
  let nursingYearStartDayDefault = validateData(formik.values.nursingYearStartDay, "01");
  let nursingYearEndMonthDefault = validateData(formik.values.nursingYearEndMonth, "03");
  let nursingYearEndDayDefault = validateData(formik.values.nursingYearEndDay, "31");
  let nursingCareYearStartMonthDefault = validateData(formik.values.childNursingYearStartMonth, "04");
  let nursingCareYearStartDayDefault = validateData(formik.values.childNursingYearStartDay, "01");
  let nursingCareYearEndMonthDefault = validateData(formik.values.childNursingYearEndMonth, "03");
  let nursingCareYearEndDayDefault = validateData(formik.values.childNursingYearEndDay, "31");

  let [nursingYearStartDayDefaultLimit, setNursingYearStartDayDefaultLimit] = useState(31);
  let [nursingYearEndDayDefaultLimit, setNursingYearEndDayDefaultLimit] = useState(31);
  let [nursingCareYearStartDayDefaultLimit, setNursingCareYearStartDayDefaultLimit] = useState(31);
  let [nursingCareYearEndDayDefaultLimit, setNursingCareYearEndDayDefaultLimit] = useState(31);

  useEffect(() => {
    setNursingYearStartDayDefaultLimit(daysInMonth(Number(nursingYearStartMonthDefault), new Date().getFullYear()));
    setNursingYearEndDayDefaultLimit(daysInMonth(Number(nursingYearEndMonthDefault), new Date().getFullYear() + 1));
    setNursingCareYearStartDayDefaultLimit(
      daysInMonth(Number(nursingCareYearStartMonthDefault), new Date().getFullYear()),
    );
    setNursingCareYearEndDayDefaultLimit(
      daysInMonth(Number(nursingCareYearEndMonthDefault), new Date().getFullYear() + 1),
    );
  }, [
    nursingYearStartMonthDefault,
    nursingYearEndMonthDefault,
    nursingCareYearStartMonthDefault,
    nursingCareYearEndMonthDefault,
  ]);

  useEffect(() => {
    setMonthFormOptions(
      listMonths.map((Month: any) => ({
        value: Month,
        label: Month,
      })),
    );
    setDayFormOptions(
      listDays.map((Day: any) => ({
        value: Day,
        label: Day,
      })),
    );
  }, []);

  return (
    <BlockUI blocking={isLoading}>
      <div>
        <form onSubmit={formik.handleSubmit} css={styles.wrap}>
          <FormTitle bold={true} customStyle={css({ marginTop: "23px" })} title="組織設定" />
          <FormContentFilter customStyles={styles.formFilter}>
            <FormField>
              <FlexBox>
                <SelectForm
                  label="組織名"
                  name="orgCode"
                  value={String(orgCode)}
                  setValue={(v) => {
                    setorgCode(v);
                  }}
                  options={orgTreesOptions}
                  description=""
                  width="900px"
                />
              </FlexBox>
            </FormField>
            <div style={{ borderBottom: "1px solid #dcdcdc" }}>
              <FormTitle
                title="エラー・アラート勤務通知設定"
                bold={true}
                customStyle={css({ margin: "26px 0 16px 0" })}
              />
              {/* remindDayBeforeAndAfter */}
              <FormField customStyle={css({ marginBottom: "10px" })}>
                <RadioSelectForm
                  label="通知機能"
                  items={[
                    {
                      label: "使用する",
                      value: "1",
                    },
                    {
                      label: "使用しない",
                      value: "0",
                    },
                  ]}
                  name="useRemindError"
                  value={String(formik.values.useRemindError)}
                  setValue={(e) => {
                    formik.setFieldValue("useRemindError", Number(e.target.value));
                  }}
                  additionStyle={styles.radio}
                />
              </FormField>
              {formik.values.useRemindError === 1 && (
                <FormField>
                  <FormLayout
                    label={<FormLabel label="通知タイミング設定" />}
                    input={
                      <FlexBox customStyle={styles.hourInput}>
                        <FormLabel label="毎月、勤怠締め日の" customStyle={css({ margin: "0 15px 0 0" })} />
                        <HourInput
                          name="remindDayBeforeAndAfter"
                          value={formik.values.remindDayBeforeAndAfter ? formik.values.remindDayBeforeAndAfter : 0}
                          customStyleLabel={css({ width: "60px" })}
                          onChange={formik.handleChange}
                          min={1}
                          max={29}
                          widthInput="35px"
                          // height="42px"
                        />
                        <BodyText>日前後、エラー・アラートがあった場合はログイン後に警告とする</BodyText>
                      </FlexBox>
                    }
                  />
                </FormField>
              )}
            </div>
            <FormField>
              <FlexBox>
                <FlexBoxItem width="240px">
                  <FormLabel label="日次勤怠表示時間" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <FormLayout
                    label=""
                    input={
                      <FlexBox customStyle={styles.hourInput}>
                        <div style={{ float: "left", marginRight: "12px" }}>
                          <HourInput
                            name="dispStartHour"
                            value={formik.values.dispStartHour ? formik.values.dispStartHour : 0}
                            label="時から"
                            onChange={formik.handleChange}
                            min={0}
                            max={23}
                          />
                        </div>
                        <FlexBoxItem width="70px">
                          <VerticalLabelSelectForm
                            label=""
                            name="orgCode"
                            value={String(formik.values.dispHour)}
                            options={[
                              {
                                label: "12",
                                value: "12",
                              },
                              {
                                label: "18",
                                value: "18",
                              },
                              {
                                label: "24",
                                value: "24",
                              },
                            ]}
                            setValue={(hour) => {
                              formik.setFieldValue("dispHour", hour);
                            }}
                            description=""
                            isSearchable={false}
                          />
                        </FlexBoxItem>
                        <div style={{ marginLeft: "12px" }}>
                          <BodyText> 時間 </BodyText>
                        </div>
                      </FlexBox>
                    }
                  />
                </FlexBoxItem>
              </FlexBox>
            </FormField>

            <FormField>
              <FlexBox>
                <FlexBoxItem width="240px">
                  <FormLabel label="月次勤怠表示設定" />
                </FlexBoxItem>
                <FlexBoxItem width="200px">
                  <FormField>
                    <FlexBox>
                      <div style={{ width: "150px", marginRight: "60px" }}>
                        <VerticalLabelSelectForm
                          label=""
                          options={[
                            {
                              label: "1週間表示",
                              value: "0",
                            },
                            {
                              label: "1ヵ月表示",
                              value: "1",
                            },
                          ]}
                          name="hopeShiftLimitFlag"
                          value={formik.values.mwsDisplayFlag ? String(formik.values.mwsDisplayFlag) : "0"}
                          setValue={(e) => {
                            formik.setFieldValue("mwsDisplayFlag", e);
                          }}
                          isSearchable={false}
                        />
                      </div>
                    </FlexBox>
                  </FormField>
                </FlexBoxItem>
                <FlexBoxItem>
                  {Number(formik.values.mwsDisplayFlag) === 0 && (
                    <FormField customStyle={css({ alignItems: "center" })}>
                      <VerticalLabelSelectForm
                        width="70px"
                        label=""
                        name="hopeShiftWeekStartDow"
                        value={
                          formik.values.mwsDisplayStartDow !== null ? String(formik.values.mwsDisplayStartDow) : "0"
                        }
                        options={[
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                            value: "0",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                            value: "1",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                            value: "2",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                            value: "3",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                            value: "4",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                            value: "5",
                          },
                          {
                            label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                            value: "6",
                          },
                        ]}
                        setValue={(val: string) => {
                          formik.setFieldValue("mwsDisplayStartDow", val);
                        }}
                        isSearchable={false}
                      />
                      <BodyText customStyle={css({ marginLeft: "15px" })}> 日〜1ヵ月表示 </BodyText>
                    </FormField>
                  )}
                  {Number(formik.values.mwsDisplayFlag) === 1 && (
                    <React.Fragment>
                      <div style={{ marginTop: "20px" }}>
                        <HourInput
                          name="mwsDisplayStartDay1"
                          value={formik.values.mwsDisplayStartDay1 ? formik.values.mwsDisplayStartDay1 : 0}
                          label="日～1ヶ月表示"
                          onChange={(val) => formik.setFieldValue("mwsDisplayStartDay1", val.target.value)}
                          min={0}
                          max={31}
                        />
                        <BodyText color={utilityColor.error} size="sm">
                          ※「0」を入力すると月末という扱いになります。
                        </BodyText>
                      </div>
                    </React.Fragment>
                  )}
                </FlexBoxItem>
              </FlexBox>
            </FormField>

            <FormField>
              <Row css={styles.flexBoxTop}>
                <Col width="240px">
                  <FormLabel customStyle={styles.label} label="希望シフト提出期限" />
                </Col>
                <Col width="150px">
                  <VerticalLabelSelectForm
                    label=" "
                    options={[
                      {
                        label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag0],
                        value: "0",
                      },
                      {
                        label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag1],
                        value: "1",
                      },
                      {
                        label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag2],
                        value: "2",
                      },
                      {
                        label: hopeShiftLimitFlagLabel[hopeShiftLimitFlag.hopeShiftLimitFlag3],
                        value: "3",
                      },
                    ]}
                    name="hopeShiftLimitFlag"
                    value={formik.values.hopeShiftLimitFlag ? String(formik.values.hopeShiftLimitFlag) : "0"}
                    setValue={(e) => {
                      formik.setFieldValue("hopeShiftLimitFlag", e);
                    }}
                    isSearchable={false}
                  />
                </Col>
                <Col xs={12} sm={12} width={isIPad13 ? "100%" : "calc(100% - 370px)"}>
                  {/* - hopeShiftLimitFixedDay1 */}
                  {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag1.toString() && (
                    <div style={isIPad13 ? { marginTop: "15px" } : { padding: "0 30px", paddingBottom: "20px" }}>
                      <FormField>
                        <FlexBox>
                          <div style={{ marginRight: "12px", whiteSpace: "nowrap" }}>
                            <BodyText> 前月 </BodyText>
                          </div>
                          <HourInput
                            name="hopeShiftLimitFixedDay1"
                            value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                            label="日まで"
                            onChange={formik.handleChange}
                            min={0}
                            max={31}
                          />
                        </FlexBox>
                      </FormField>
                      <BodyText color={utilityColor.error} size="sm">
                        ※「0」を入力すると月末という扱いになります。
                      </BodyText>
                    </div>
                  )}

                  {/* hopeShiftLimitFixedDay1 - hopeShiftLimitFixedDay2*/}
                  {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag2.toString() && (
                    <div style={isIPad13 ? { marginTop: "15px" } : { padding: "0 30px", paddingBottom: "5px" }}>
                      <FormField displayBlock={true}>
                        <div>
                          <FlexBox>
                            <div style={{ marginRight: "35px", width: "173px", whiteSpace: "nowrap" }}>
                              <BodyText> 1日～15日分シフト </BodyText>
                            </div>
                            <div style={{ marginRight: "12px", whiteSpace: "nowrap" }}>
                              <BodyText> 前月 </BodyText>
                            </div>
                            <HourInput
                              name="hopeShiftLimitFixedDay1"
                              value={formik.values.hopeShiftLimitFixedDay1 ? formik.values.hopeShiftLimitFixedDay1 : 0}
                              label="日まで"
                              onChange={formik.handleChange}
                              min={0}
                              max={31}
                            />
                          </FlexBox>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <FlexBox>
                            <div style={{ marginRight: "30px", width: "178px", whiteSpace: "nowrap" }}>
                              <BodyText> 16日～末日分シフト </BodyText>
                            </div>
                            <div style={{ marginRight: "12px", whiteSpace: "nowrap" }}>
                              <BodyText> 当月 </BodyText>
                            </div>
                            <HourInput
                              name="hopeShiftLimitFixedDay2"
                              value={formik.values.hopeShiftLimitFixedDay2 ? formik.values.hopeShiftLimitFixedDay2 : 0}
                              label="日まで"
                              onChange={formik.handleChange}
                              min={0}
                              max={31}
                            />
                          </FlexBox>
                        </div>

                        <BodyText color={utilityColor.error} size="sm">
                          ※「0」を入力すると月末という扱いになります。
                        </BodyText>
                      </FormField>
                    </div>
                  )}
                  {/* hopeShiftWeekStartDow - hopeShiftWeekOffset - hopeShiftWeekLimitDow*/}
                  {String(formik.values.hopeShiftLimitFlag) === hopeShiftLimitFlag.hopeShiftLimitFlag3.toString() && (
                    <div style={isIPad13 ? { marginTop: "15px" } : { padding: "0 30px", paddingBottom: "20px" }}>
                      <FormField displayBlock={true}>
                        <FlexBox>
                          <div style={{ marginRight: "12px" }}>
                            <BodyText>対象シフト</BodyText>
                          </div>
                          <VerticalLabelSelectForm
                            width="70px"
                            label=""
                            name="hopeShiftWeekStartDow"
                            value={
                              formik.values.hopeShiftWeekStartDow !== null
                                ? String(formik.values.hopeShiftWeekStartDow)
                                : "0"
                            }
                            options={[
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                                value: "0",
                              },
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                                value: "1",
                              },
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                                value: "2",
                              },
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                                value: "3",
                              },
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                                value: "4",
                              },
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                                value: "5",
                              },
                              {
                                label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                                value: "6",
                              },
                            ]}
                            setValue={(val: string) => {
                              formik.setFieldValue("hopeShiftWeekStartDow", val);
                            }}
                            isSearchable={false}
                          />
                          <div style={{ marginLeft: "12px" }}>
                            <BodyText>曜日〜1週間表示</BodyText>
                          </div>
                        </FlexBox>
                        <FlexBox>
                          <HourInput
                            name="hopeShiftWeekOffset"
                            value={formik.values.hopeShiftWeekOffset ? formik.values.hopeShiftWeekOffset : 0}
                            label="週前の"
                            onChange={formik.handleChange}
                            min={0}
                            max={5}
                          />
                          <div style={{ marginLeft: "12px" }}>
                            <VerticalLabelSelectForm
                              width="70px"
                              label=""
                              name="hopeShiftWeekLimitDow"
                              value={
                                formik.values.hopeShiftWeekLimitDow !== null
                                  ? String(formik.values.hopeShiftWeekLimitDow)
                                  : "0"
                              }
                              options={[
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow0],
                                  value: "0",
                                },
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow1],
                                  value: "1",
                                },
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow2],
                                  value: "2",
                                },
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow3],
                                  value: "3",
                                },
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow4],
                                  value: "4",
                                },
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow5],
                                  value: "5",
                                },
                                {
                                  label: mwsDisplayStartDowLabel[mwsDisplayStartDow.mwsDisplayStartDow6],
                                  value: "6",
                                },
                              ]}
                              setValue={(val: string) => {
                                formik.setFieldValue("hopeShiftWeekLimitDow", val);
                              }}
                              isSearchable={false}
                            />
                          </div>
                          <div style={{ marginLeft: "12px" }}>
                            <BodyText>曜日まで</BodyText>
                          </div>
                        </FlexBox>
                        <BodyText color={utilityColor.error} size="sm" customStyle={css({ wordBreak: "break-all" })}>
                          ※週の開始日は日曜とします。「0」週間前とすると、同じ週という扱いになります。
                        </BodyText>
                      </FormField>
                    </div>
                  )}
                </Col>
              </Row>
            </FormField>

            {/* hopeShiftLimitFlag0  */}
            {String(formik.values.hopeShiftLimitFlag) !== hopeShiftLimitFlag.hopeShiftLimitFlag0.toString() && (
              <FormField displayBlock={true}>
                <FlexBox>
                  <FlexBoxItem width="240px">
                    <FormLabel label="契約シフト自動作成" />
                  </FlexBoxItem>
                  <FlexBoxItem width="150px">
                    <VerticalLabelSelectForm
                      label=""
                      options={[
                        {
                          label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag0],
                          value: "0",
                        },
                        {
                          label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag1],
                          value: "1",
                        },
                      ]}
                      name="shiftCreateFlag"
                      value={String(formik.values.shiftCreateFlag)}
                      setValue={(e) => {
                        formik.setFieldValue("shiftCreateFlag", e);
                      }}
                      note=""
                      isSearchable={false}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <FormLabel
                      customStyle={styles.colorRed}
                      label=" ※希望シフト提出期限後に提出がないスタッフのシフトを契約勤務パターンから自動作成します。"
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FormField>
            )}
            {false && (
              <FormField displayBlock={true}>
                <FlexBox>
                  <FlexBoxItem width="240px">
                    <FormLabel label="出勤予定者表示機能" />
                  </FlexBoxItem>
                  <FlexBoxItem width="150px">
                    <VerticalLabelSelectForm
                      label=""
                      options={[
                        {
                          label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag0],
                          value: "0",
                        },
                        {
                          label: shiftCreateFlagLabel[shiftCreateFlag.shiftCreateFlag1],
                          value: "1",
                        },
                      ]}
                      name="shiftStaffDisplayFlag"
                      value={String(formik.values.shiftStaffDisplayFlag)}
                      setValue={(e) => {
                        formik.setFieldValue("shiftStaffDisplayFlag", e);
                      }}
                      isSearchable={false}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FormField>
            )}
            <FormField displayBlock={true}>
              <FlexBox>
                <FlexBoxItem width="240px">
                  <FormLabel label="介護休暇の１年度とする期間" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <FlexBox customStyle={styles.flexBox}>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingYearStartMonth"
                        value={String(nursingYearStartMonthDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("nursingYearStartMonth", val);
                        }}
                        options={monthFormOptions}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <span css={styles.span}>月</span>
                    </FlexBoxItem>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingYearStartDay"
                        value={String(nursingYearStartDayDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("nursingYearStartDay", val);
                        }}
                        options={dayFormOptions.filter(
                          (dayOfOption) => Number(dayOfOption.value) <= nursingYearStartDayDefaultLimit,
                        )}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <span css={styles.span}>日</span>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <span css={styles.span}>〜</span>
                    </FlexBoxItem>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingYearEndMonth"
                        value={String(nursingYearEndMonthDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("nursingYearEndMonth", val);
                        }}
                        options={monthFormOptions}
                      />
                    </FlexBoxItem>
                    <span css={styles.span}>月</span>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingYearEndDay"
                        value={String(nursingYearEndDayDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("nursingYearEndDay", val);
                        }}
                        options={dayFormOptions.filter(
                          (dayOfOption) => Number(dayOfOption.value) <= nursingYearEndDayDefaultLimit,
                        )}
                      />
                    </FlexBoxItem>
                    <span css={styles.span}>日</span>
                  </FlexBox>
                </FlexBoxItem>
              </FlexBox>
              <FlexBox>
                <FlexBoxItem width="240px">
                  <FormLabel label="子の看護休暇の１年度とする期間" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <FlexBox>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingCareYearStartMonth"
                        value={String(nursingCareYearStartMonthDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("childNursingYearStartMonth", val);
                        }}
                        options={monthFormOptions}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <span css={styles.span}>月</span>
                    </FlexBoxItem>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingCareYearStartDay"
                        value={String(nursingCareYearStartDayDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("childNursingYearStartDay", val);
                        }}
                        options={dayFormOptions.filter(
                          (dayOfOption) => Number(dayOfOption.value) <= nursingCareYearStartDayDefaultLimit,
                        )}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <span css={styles.span}>日</span>
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <span css={styles.span}>〜</span>
                    </FlexBoxItem>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingCareYearEndMonth"
                        value={String(nursingCareYearEndMonthDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("childNursingYearEndMonth", val);
                        }}
                        options={monthFormOptions}
                      />
                    </FlexBoxItem>
                    <span css={styles.span}>月</span>
                    <FlexBoxItem width="70px">
                      <VerticalLabelSelectForm
                        label=""
                        name="nursingCareYearEndDay"
                        value={String(nursingCareYearEndDayDefault)}
                        setValue={(val: string) => {
                          formik.setFieldValue("childNursingYearEndDay", val);
                        }}
                        options={dayFormOptions.filter(
                          (dayOfOption) => Number(dayOfOption.value) <= nursingCareYearEndDayDefaultLimit,
                        )}
                      />
                    </FlexBoxItem>
                    <span css={styles.span}>日</span>
                  </FlexBox>
                </FlexBoxItem>
              </FlexBox>
            </FormField>
          </FormContentFilter>
          {detailRole.editable === 1 && (
            <FormSubmitArea>
              <FlexBox customStyle={styles.formSubmit}>
                <Button text="登録する" onClick={formik.handleSubmit} />
              </FlexBox>
            </FormSubmitArea>
          )}
        </form>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={ActionType.CREATE}
        />
      </div>
    </BlockUI>
  );
};

export default MasterOrganizeSettingLayout;
