/** @jsx jsx */
import React, { useEffect, useState } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FormPadding from "components/atoms/Form/FormPadding";
import { Row, Col } from "react-grid-system";
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import BlockUI from "components/molecules/BlockUi";
import { useStampListForm, attendStampContext, useIntialState } from "./hooks";
import MonthHourTable from "./MonthHourTable";
import MonthDayTable from "./MonthDayTable";
import PaidHolidayGranTable from "./PaidHolidayGranTable";
import SpecialHolidayGrantTable from "./SpecialHolidayGrantTable";
import SubstituteHolidayTable from "./SubstituteHolidayTable";
import TransferHolidayTable from "./TransferHolidayTable";
import FormTitle from "components/atoms/Form/FormTitle";
import NursingCareHolidayDataTable from "./NursingCareHolidayDataTable";
import NursingHolidayDataTable from "./NursingHolidayDataTable";
import SelectForm from "components/molecules/SelectForm";

import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import { Accordion } from "components/atoms/Accordion";
import AllowanceTable from "components/organismos/master/attend/AttendStampAggregation/AllowanceTable";

const AttendStampAggregation: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.attendStampList);

  const [attendHolidays, setAttendHolidays] = useState([]);
  const [subTotalTime, setSubTotalTime] = useState(Object());
  const [subTotalDay, setSubTotalDay] = useState(Object());
  const [business, setBusiness] = useState([]);
  const [term, setTerm] = useState([]);
  const [holidaySubtotalItem, setHolidaySubtotalItem] = useState([]);
  const [closingDate, setClosingDate] = useState(Number(sessionStorage.getItem("loginUser.closingDate") || 0));
  const context = useIntialState();

  const {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffCode,
    setStaffCode,
    targetMonth,
    setTargetMonth,
    orgCode,
    setOrgCode,
    selectOrgCode,
    setSelectOrgCode,
    setViewPeriod,
    flagReLoad,
    setFlagReload,
    isReloader,
    setIsReloader,
    orgCodeList,
    setOrgCodeList,
    staffList,
    setstaffList,
  } = context;

  const [oldOrgCode, setOldSelectOrgCode] = useState(
    sessionStorage.getItem("attendStampList.orgCode")! || sessionStorage.getItem("loginUser.orgCode")!,
  );
  const [tmpStaffCode, setTmpStaffCode] = useState(
    sessionStorage.getItem("attendStampList.staffCode")! || sessionStorage.getItem("loginUser.staffCode")!,
  );
  const [attendFlag] = useState(sessionStorage.getItem("attendStampList.attendFlag") || "0");
  const [closingDateV2, setClosingDateV2] = useState(0);
  const {
    employment,
    nursingCareHolidayData,
    nursingHolidayData,
    paidHolidayGrantData,
    specialHolidayGrantList,
    stampItems,
    substituteHolidayUsingStatusList,
    transferHolidayUsingStatusList,
  } = useStampListForm(
    closingDate,
    attendFlag,
    "",
    setSubTotalTime,
    setSubTotalDay,
    setHolidaySubtotalItem,
    setAttendHolidays,
    setIsLoading,
    setTerm,
    setBusiness,
    false,
    setOldSelectOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffCode,
    setStaffCode,
    targetMonth,
    setTargetMonth,
    orgCode,
    setOrgCode,
    selectOrgCode,
    setSelectOrgCode,
    "monthly",
    setViewPeriod,
    flagReLoad,
    setFlagReload,
    isReloader,
    setIsReloader,
    setOrgCodeList,
    oldOrgCode,
    setstaffList,
    setClosingDateV2,
  );

  function timeDisp(time: any) {
    if (time === 0 || !time) {
      return "-";
    }
    return time;
  }

  function countHolidayColspan(distinctionHoliday: any, attendHolidayList: any) {
    // 法定休日 + 所定休日 or 休日
    let result = distinctionHoliday ? 2 : 1;
    // 業務数
    result += attendHolidayList ? attendHolidayList.length : 0;
    // 遅刻 + 早退
    result += 2;
    return result;
  }

  useEffect(() => {
    setViewPeriod("monthly");
  }, []);

  useEffect(() => {
    if (targetMonth !== null) {
      sessionStorage.setItem("attendStampList.targetMonth", String(targetMonth));
    }
  }, [targetMonth]);

  useEffect(() => {
    let session = sessionStorage.getItem("attendStampList.targetMonth");
    if (session !== null) {
      setTargetMonth(new Date(session));
    } else {
      setTargetMonth(new Date());
    }

    setIsReloader(true);
  }, []);

  useEffect(() => {
    if (paidHolidayGrantData !== null) {
      if (paidHolidayGrantData.nextCarryOverDaysNum === null) {
        paidHolidayGrantData.nextCarryOverDaysNum = 0.0;
      }
      if (paidHolidayGrantData.nextCarryOverFirstDaysNum === null) {
        paidHolidayGrantData.nextCarryOverFirstDaysNum = 0.0;
      }
      if (paidHolidayGrantData.nextCarryOverTimesNum === null) {
        paidHolidayGrantData.nextCarryOverTimesNum = 0;
      }
      if (paidHolidayGrantData.nextCarryOverFirstTimesNum === null) {
        paidHolidayGrantData.nextCarryOverFirstTimesNum = 0;
      }
    }
  }, [subTotalTime]);

  return (
    <BlockUI blocking={isLoading}>
      <attendStampContext.Provider value={context}>
        <FormContents>
          <FormTitle title="打刻データ集計" bold={true} customStyle={css({ padding: "8px 16px 0 16px", margin: 0 })} />
          <div className="wrap-attend-stamp-list">
            <FormPadding customStyle={css({ padding: "16px" })}>
              <Row>
                <Col md={12}>
                  <div css={css({ marginLeft: "30px", marginBottom: "8px" })}>
                    <CalendarMonth
                      date={new Date(targetMonth)}
                      setDate={(date) => {
                        setTargetMonth(date);
                        if (new Date(targetMonth).getTime() !== date.getTime()) {
                          setIsReloader(true);
                        }
                      }}
                      openActionChevron={true}
                    />
                  </div>
                </Col>
              </Row>
              {detailRole.accessRange !== 0 && (
                <Row style={{ marginBottom: "4px" }}>
                  <Col lg={10} md={9}>
                    <Row>
                      <Col md={6}>
                        <SelectForm
                          label=""
                          name="selectOrgCode"
                          value={selectOrgCode}
                          setValue={(v) => {
                            setStaffCode(oldOrgCode === v ? tmpStaffCode : "");
                            setOldSelectOrgCode(selectOrgCode);
                            sessionStorage.setItem("attendStampList.orgCode", v);
                            setSelectOrgCode(v);
                            setIsReloader(true);
                          }}
                          width="100%"
                          options={orgCodeList}
                          placeholder=""
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={10} md={9}>
                  <Row>
                    <Col md={6}>
                      {detailRole.accessRange !== 0 ? (
                        <SelectForm
                          label=""
                          name="staffCode"
                          value={staffCode}
                          setValue={(v) => {
                            sessionStorage.setItem("attendStampList.staffCode", v);
                            setStaffCode(v);
                            setTmpStaffCode(v);
                            setIsReloader(true);
                          }}
                          options={staffList}
                          width={"100%"}
                          placeholder="スタッフを選択してください"
                        />
                      ) : (
                        <BodyText color="#333333">
                          {staffList.length > 0 && staffList.find((staff: any) => staff.value === staffCode)?.label}
                        </BodyText>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormPadding>
            {staffList.length === 0 || staffCode === "" || stampItems.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "normal",
                  fontFamily: "inherit",
                  padding: "30px",
                }}
              >
                <BodyText size="re" color="#666666">
                  データはありません。
                </BodyText>
              </div>
            ) : (
              <div css={css({ display: "flex", flexDirection: "column", gap: 4, padding: "0 16px 16px 16px" })}>
                <Row>
                  <Col xs={12}>
                    <Accordion
                      title={`集計時間データ${
                        Number(employment.workSystem) === 2
                          ? "（裁量労働のため、深夜時間は内訳表示となっています）"
                          : ""
                      }`}
                    >
                      <MonthHourTable
                        employment={employment}
                        business={business || []}
                        term={term || []}
                        timeDisp={timeDisp}
                        subTotalTime={subTotalTime}
                        remote={employment.isRemote}
                      />
                    </Accordion>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Accordion title="集計日数データ">
                      <MonthDayTable
                        subTotalDay={subTotalDay}
                        holidaySubtotalItem={holidaySubtotalItem}
                        countHolidayColspan={countHolidayColspan}
                        remote={employment.isRemote}
                      />
                    </Accordion>
                  </Col>
                </Row>
                {employment.useAllowanceApplication === 1 && subTotalDay.allowanceDataList.length > 0 && (
                  <Row>
                    <Col xs={12}>
                      <Accordion title="手当申請状況">
                        <AllowanceTable allowanceData={subTotalDay.allowanceDataList ?? []} />
                      </Accordion>
                    </Col>
                  </Row>
                )}
                {employment.autoPaidManageFunc && attendFlag === "0" && (
                  <Row>
                    <Col xs={12}>
                      <Accordion title="有給使用状況 (現時点)">
                        <PaidHolidayGranTable paidHolidayGrantData={paidHolidayGrantData} />
                      </Accordion>
                    </Col>
                  </Row>
                )}
                {specialHolidayGrantList.length > 0 && attendFlag === "0" && (
                  <Row>
                    <Col xs={12}>
                      <Accordion title="特休使用状況">
                        <SpecialHolidayGrantTable specialHolidayGrantList={specialHolidayGrantList} />
                      </Accordion>
                    </Col>
                  </Row>
                )}

                {substituteHolidayUsingStatusList.length > 0 && attendFlag === "0" && (
                  <Row>
                    <Col xs={12}>
                      <Accordion title="代休使用状況">
                        <SubstituteHolidayTable substituteHolidayUsingStatusList={substituteHolidayUsingStatusList} />
                      </Accordion>
                    </Col>
                  </Row>
                )}

                {employment.useTransferApplication === 1 &&
                  transferHolidayUsingStatusList.length > 0 &&
                  attendFlag === "0" && (
                    <Row>
                      <Col xs={12}>
                        <Accordion title="振替状況">
                          <TransferHolidayTable transferHolidayUsingStatusList={transferHolidayUsingStatusList} />
                        </Accordion>
                      </Col>
                    </Row>
                  )}

                {nursingHolidayData && Object.keys(nursingHolidayData).length > 0 && attendFlag === "0" && (
                  <Row>
                    <Col xs={12}>
                      <Accordion title="介護休暇使用状況">
                        <NursingHolidayDataTable nursingHolidayData={nursingHolidayData} />
                      </Accordion>
                    </Col>
                  </Row>
                )}
                {nursingCareHolidayData && Object.keys(nursingCareHolidayData).length > 0 && attendFlag === "0" && (
                  <Row>
                    <Col xs={12}>
                      <Accordion title="看護休暇使用状況">
                        <NursingCareHolidayDataTable nursingCareHolidayData={nursingCareHolidayData} />
                      </Accordion>
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </div>
        </FormContents>
      </attendStampContext.Provider>
    </BlockUI>
  );
};

export default AttendStampAggregation;
