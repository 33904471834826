/** @jsx jsx */
import React, { Dispatch } from "react";
import { Link } from "react-router-dom";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { SubActionButton, SecondaryButton } from "components/atoms/Button";
import StaffDomain from "domain/staff";
import { TablItem } from "components/atoms/TabBar";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import { utilityColor } from "components/styles";
import { SalaryDataStaffDomain } from "domain/master/general/salaryDataStaff";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import BodyText from "components/atoms/BodyText";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { useSalaryForm } from "./hooks";
import OrganizationSupportEdit from "../OrganizationAddModal/OrganizationSupportEdit";
import { css, jsx } from "@emotion/core";
import IconLabelButton from "components/molecules/IconLabelButton";
import useStaffList from "components/pages/master/general/staff/StaffsPage/hooks";
import { useStaffDelete } from "../StaffListTable/hooks";
import FormLabel from "components/atoms/Form/FormLabel";
import SalaryTermForm from "components/organismos/master/general/staff/SalaryInfoForm/SalaryTermForm";
import { OptionType } from "components/atoms/Select";
import OrganizationSelectModal from "components/organismos/master/general/staff/OrganizationAddModal/OrganizationSelectModal";
import Table from "components/molecules/Table";
import Calendar from "components/molecules/CalendarV1";
import MoneyInput from "components/atoms/Form/MoneyInput";

const styles = {
  form: css({
    margin: "0 auto",
  }),
  formContent: {
    padding: "0px 25px 30px",
  },
  flexbox: css({
    marginBottom: "15px",
  }),
  table: css({
    margin: "0 16px",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    ["table tr th"]: {
      fontWeight: "bold",
      textAlign: "left",
    },
    ["table tr td"]: {
      textAlign: "left",
    },
  }),
  buttonSubmit: css(
    {
      marginLeft: "30px",
    },
    css`
      button {
        width: auto;
        display: inline-block;
      }
    `,
  ),
  tableHead: css(
    {},
    css`
      table tr th {
        text-align: center;
      }
      table tr th:nth-child(1) {
        width: 60px;
      }
      table tr td:nth-child(1) {
        text-align: center;
      }
    `,
  ),
  centerBox: css({
    justifyContent: "center",
  }),
  radioGroup: css({
    position: "relative",
    top: "-5px",
  }),
  marginBotTop: css({
    margin: "15px 0px",
  }),
  marginTop: css({
    marginTop: "32px",
  }),
  wrapForm: css({
    padding: "40px",
  }),
  label: css({
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "30px",
    marginBottom: "0px",
    alignItems: "center",
  }),
};

type SelectType = "staff" | "affiliation" | "employment" | "laborCost" | "workPattern" | "longTermCareandNurse";
const SelectType: { [key in SelectType]: SelectType } = {
  staff: "staff",
  affiliation: "affiliation",
  employment: "employment",
  laborCost: "laborCost",
  workPattern: "workPattern",
  longTermCareandNurse: "longTermCareandNurse",
};

const SalaryInfoForm: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  termOptions: Array<any>;
  modalOrgTreeOptions: OptionType[];
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
}> = ({
  initialStaffDomain,
  isEditMode = false,
  tabItems,
  setInitialStaffDomain,
  setSelectType,
  setTabItems,
  termOptions,
  modalOrgTreeOptions,
  setIsLoading,
}) => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    openOrganizationAddModal,
    setOpenOrganizationAddModal,
    addOrganizationSupport,
    deleteOrganizationSupport,
    setOrganizationSupport,
    addSalaryTerm,
    deleteSalaryTerm,
    setSalaryTerm,
    contextObject,
  } = useSalaryForm(initialStaffDomain, isEditMode, setIsLoading);

  const columns: Array<Column<SalaryDataStaffDomain>> = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "x",
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: SalaryDataStaffDomain } }) => {
          return (
            <SubActionButton
              action={0}
              text="編集"
              onClick={() => {
                formik.setFieldValue("selectSalary", new SalaryDataStaffDomain({ ...cell.row.original.getRawData() }));
              }}
            />
          );
        },
      },
      {
        Header: "給与適用日",
        accessor: "applyStartDateStr",
      },
      {
        Header: "給与体系",
        accessor: "salaryPaymentStr",
      },
      {
        Header: "給与",
        accessor: "dispSalaryStr",
      },
      {
        Header: "日額交通費",
        accessor: "dailyTransportation",
      },
      {
        Header: "月額交通費",
        accessor: "monthlyTransportation",
      },
    ],
    [formik],
  );

  const { fetchData } = useStaffList();
  const {
    setDeleteTargetStaff,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
  } = useStaffDelete(fetchData, setIsLoading);

  return (
    <React.Fragment>
      {formik.values.salaryList && formik.values.salaryList.length !== 0 && (
        <div className="table-bordered" css={styles.tableHead}>
          <DataTable
            columns={columns}
            data={formik.values.salaryDomainList}
            isGlobalFilter={false}
            isHiddenPagination={true}
          />
        </div>
      )}

      <form onSubmit={formik.handleSubmit} css={styles.form}>
        <div css={styles.wrapForm}>
          {/* <FormTitle
              title="基本情報"  
            /> */}
          <FlexBox>
            <FlexBoxItem width="150px">
              <FormLabel label="給与適用日" required customStyle={styles.label} />
            </FlexBoxItem>
            <FlexBoxItem>
              <Calendar
                date={formik.values.selectSalary.applyStartDate}
                setDate={(date) => {
                  if (date && JSON.stringify(date) !== JSON.stringify(formik.values.applyStartDate)) {
                    formik.setFieldValue("selectSalary.applyStartDate", date);
                  }
                }}
                readOnly={!isEditMode}
              />
            </FlexBoxItem>
          </FlexBox>
          <FlexBox customStyle={styles.marginBotTop}>
            <FlexBoxItem width="150px">
              <FormLabel label="給与体系" required customStyle={styles.label} />
            </FlexBoxItem>
            <FlexBoxItem>
              <div css={styles.radioGroup}>
                <RadioSelectForm
                  label=""
                  required={true}
                  items={[
                    {
                      label: "設定なし",
                      value: "0",
                    },
                    {
                      label: "時給",
                      value: "1",
                    },
                    {
                      label: "月給",
                      value: "2",
                    },
                    {
                      label: "日給",
                      value: "3",
                    },
                  ]}
                  name="selectSalary.salaryPayment"
                  value={String(formik.values.selectSalary.salaryPayment)}
                  setValue={(e) => formik.setFieldValue("selectSalary.salaryPayment", Number(e.target.value))}
                />
              </div>
            </FlexBoxItem>
          </FlexBox>
          {formik.values.selectSalary.salaryPayment === 1 && (
            <FlexBox customStyle={styles.flexbox}>
              <FlexBoxItem basis="150px">
                <BodyText> 時給 </BodyText>
              </FlexBoxItem>
              <FlexBoxItem basis="200px">
                <MoneyInput
                  name="selectSalary.hourlySalary"
                  value={formik.values.selectSalary.hourlySalary || 0}
                  label="円"
                  onChange={formik.handleChange}
                  isFullW={false}
                  min={0}
                  max={999999999}
                />
              </FlexBoxItem>
            </FlexBox>
          )}
          {formik.values.selectSalary.salaryPayment === 2 && (
            <div>
              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 月給 </BodyText>
                </FlexBoxItem>
                <FlexBoxItem basis="200px">
                  <MoneyInput
                    name="selectSalary.monthlySalary"
                    value={formik.values.selectSalary.monthlySalary || 0}
                    label="円"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                </FlexBoxItem>
              </FlexBox>
              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 基礎賃金 </BodyText>
                </FlexBoxItem>

                <FlexBoxItem basis="200px">
                  <MoneyInput
                    name="selectSalary.monthlyHourlySalary"
                    value={formik.values.selectSalary.monthlyHourlySalary || 0}
                    label="円／時間"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
          )}

          {formik.values.selectSalary.salaryPayment === 3 && (
            <div>
              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 日給 </BodyText>
                </FlexBoxItem>
                <FlexBoxItem basis="200px">
                  <MoneyInput
                    name="selectSalary.dailySalary"
                    value={formik.values.selectSalary.dailySalary || 0}
                    label="円"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                </FlexBoxItem>
              </FlexBox>

              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 基礎賃金 </BodyText>
                </FlexBoxItem>

                <FlexBoxItem basis="200px">
                  <MoneyInput
                    name="selectSalary.dailyHourlySalary"
                    value={formik.values.selectSalary.dailyHourlySalary || 0}
                    label="円／時間"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
          )}
          <FlexBox>
            <FlexBoxItem width="150px">
              <FormLabel label="交通費区分" required customStyle={styles.label} />
            </FlexBoxItem>
            <FlexBoxItem>
              <div css={styles.radioGroup}>
                <RadioSelectForm
                  label=""
                  required={true}
                  items={[
                    {
                      label: "設定なし",
                      value: "0",
                    },
                    {
                      label: "日額",
                      value: "1",
                    },
                    {
                      label: "月額",
                      value: "2",
                    },
                  ]}
                  name="selectSalary.transportationFlag"
                  value={String(formik.values.selectSalary.transportationFlag)}
                  setValue={(e) => formik.setFieldValue("selectSalary.transportationFlag", Number(e.target.value))}
                />
              </div>
            </FlexBoxItem>
          </FlexBox>

          {formik.values.selectSalary.transportationFlag === 1 && (
            <div css={styles.marginBotTop}>
              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 交通費 </BodyText>
                </FlexBoxItem>
                <FlexBoxItem basis="200px">
                  <MoneyInput
                    name="selectSalary.dailyTransportation"
                    value={formik.values.selectSalary.dailyTransportation || 0}
                    label="円"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                </FlexBoxItem>
              </FlexBox>

              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 交通費上限額 </BodyText>
                </FlexBoxItem>

                <FlexBoxItem>
                  <MoneyInput
                    name="selectSalary.limitTransportation"
                    value={formik.values.selectSalary.limitTransportation || 0}
                    label="円"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                  <BodyText color={utilityColor.error} size="sm">
                    上限を設定しない場合は「0」を入力してください。
                  </BodyText>
                </FlexBoxItem>
              </FlexBox>
            </div>
          )}

          {formik.values.selectSalary.transportationFlag === 2 && (
            <div css={styles.marginBotTop}>
              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 交通費 </BodyText>
                </FlexBoxItem>
                <FlexBoxItem basis="200px">
                  <MoneyInput
                    name="selectSalary.monthlyTransportation"
                    value={formik.values.selectSalary.monthlyTransportation || 0}
                    label="円"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                </FlexBoxItem>
              </FlexBox>

              <FlexBox customStyle={styles.flexbox}>
                <FlexBoxItem basis="150px">
                  <BodyText> 交通費上限額 </BodyText>
                </FlexBoxItem>

                <FlexBoxItem>
                  <MoneyInput
                    name="selectSalary.limitTransportation"
                    value={formik.values.selectSalary.limitTransportation || 0}
                    label="円"
                    onChange={formik.handleChange}
                    min={0}
                    max={999999999}
                  />
                  <BodyText color={utilityColor.error} size="sm">
                    ※上限を設定しない場合は「0」を入力してください。
                  </BodyText>
                </FlexBoxItem>
              </FlexBox>
            </div>
          )}
        </div>
        {/* <FormTitle title="支援組織の設定" /> */}
        <div className="table-bordered">
          <div
            className="table-container"
            css={[
              styles.table,
              css({
                ["table tr th:nth-child(1)"]: {
                  width: "auto",
                },
                ["table tr th:nth-child(2)"]: {
                  width: "140px",
                },
                ["table tr th:nth-child(3)"]: {
                  width: "140px",
                },
                ["table tr th:nth-child(4)"]: {
                  width: "30px",
                },
              }),
            ]}
          >
            <Table>
              <thead>
                <tr>
                  <th>支援組織</th>
                  <th>時給加算額</th>
                  <th>支援交通費</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formik.values.selectSalary.salarySupportDataList?.map((support, index) => (
                  <OrganizationSupportEdit
                    key={index.toString()}
                    isShowHourlyWageAddition={formik.values.selectSalary.salaryPayment === 1}
                    support={support}
                    deleteSupport={deleteOrganizationSupport(index)}
                    setSupport={setOrganizationSupport(index)}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div css={styles.buttonSubmit}>
          <IconLabelButton
            onClick={() => {
              setOpenOrganizationAddModal(true);
            }}
            iconType="addCircle"
            text="支援組織追加"
          />
        </div>
        <OrganizationSelectModal
          openModal={openOrganizationAddModal}
          setOpenModal={setOpenOrganizationAddModal}
          organizationOptions={modalOrgTreeOptions}
          onSubmit={addOrganizationSupport}
        />

        {formik.values.selectSalary.salaryPayment === 1 && (
          <React.Fragment>
            <div className="table-bordered" css={styles.marginTop}>
              <div
                className="table-container"
                css={[
                  styles.table,
                  css({
                    ["table tr th:nth-child(1)"]: {
                      width: "auto",
                    },
                    ["table tr th:nth-child(2)"]: {
                      width: "140px",
                    },
                    ["table tr th:nth-child(3)"]: {
                      width: "30px",
                    },
                  }),
                ]}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>時間帯</th>
                      <th>時給加算額</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.selectSalary.salaryTermList.map((manager, index) => {
                      return (
                        <SalaryTermForm
                          termOptions={termOptions}
                          key={index.toString()}
                          salaryTerm={manager}
                          setSalaryTerm={setSalaryTerm(index)}
                          deleteSalaryTerm={deleteSalaryTerm(index)}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div css={styles.buttonSubmit}>
              <IconLabelButton onClick={addSalaryTerm} iconType="addCircle" text="時間帯追加" />
            </div>
          </React.Fragment>
        )}

        <FormSubmitArea customStyle={css({ padding: "30px", paddingBottom: "0px" })}>
          {contextObject.detailRole.editable === 1 && (
            <div style={{ marginRight: "12px", textAlign: "center", marginTop: "30px" }}>
              <FlexBox customStyle={styles.centerBox}>
                <Button text={isEditMode ? "人件費情報を登録" : "登録"} onClick={formik.handleSubmit} />
                {isEditMode ? (
                  <div style={{ marginLeft: "12px", textAlign: "center" }}>
                    <SecondaryButton
                      minWidth="150px"
                      text="削除"
                      onClick={() => {
                        setIsOpenDeleteModal(true);
                        setDeleteTargetStaff(formik.values);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!isEditMode && (
                  <div style={{ marginLeft: "12px" }}>
                    <Button
                      text="勤務パターンの入力へ"
                      onClick={() => {
                        if (formik.values.orgApplyDate && formik.values.orgCode) {
                          if (tabItems.length <= 4) {
                            setInitialStaffDomain(formik.values);
                            setTabItems([
                              {
                                value: SelectType.staff,
                                display: "スタッフ情報",
                              },
                              {
                                value: SelectType.affiliation,
                                display: "所属情報",
                              },
                              {
                                value: SelectType.employment,
                                display: "雇用情報",
                              },
                              {
                                value: SelectType.laborCost,
                                display: "人件費情報",
                              },
                              {
                                value: SelectType.workPattern,
                                display: "勤務パターン",
                              },
                            ]);
                          }
                          setSelectType(SelectType.workPattern);
                        } else {
                          formik.validateForm();
                        }
                      }}
                    />
                  </div>
                )}
              </FlexBox>
            </div>
          )}

          <div style={{ textAlign: "center" }}>
            <Link to="/staffs">
              <Button text="スタッフ一覧に戻る" ghost={true} />
            </Link>
          </div>
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
      />
      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
        typeSubmit="reject"
      />
    </React.Fragment>
  );
};

export default SalaryInfoForm;
