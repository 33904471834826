/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";

import { productColor, grayScale, textFontSize } from "components/styles";
import { isIPad13, isTablet } from "react-device-detect";

const styles1 = {
  wrapper: css({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    margin: "10px 30px",
  }),
  label: css({
    flex: 1,
    height: "24px",
    width: "100%",
    padding: "4px 12px",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: textFontSize.re,
  }),
  selected: css({
    border: `1px solid ${productColor.primary}`,
    borderRadius: "100px",
    color: productColor.primary,
    marginRight: "8px",
  }),
  notSelected: css({
    border: `1px solid ${grayScale.gray20}`,
    borderRadius: "100px",
    color: grayScale.gray50,
    marginRight: "8px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: productColor.primaryM95,
      border: `1px solid ${productColor.primary}`,
      color: productColor.primary,
    },
  }),
  radio: css({
    display: "none",
  }),
  tabModal: css({
    borderBottom: "1px solid #DCDCDC",
    display: "flex",
    backgroundColor: "transparent",
    margin: "10px 30px",
    overflowX: "auto",
  }),
  tabModalNotSelected: css({
    border: `1px solid ${grayScale.gray20}`,
    color: grayScale.gray50,
    marginRight: "8px",
    cursor: "pointer",
    ":hover": {
      color: productColor.primary,
    },
  }),
};

const styles2 = {
  wrapper: css({
    display: "flex",
    backgroundColor: "transparent",
    color: productColor.primary,
  }),
  label: css({
    flex: 1,
    height: "24px",
    width: "100%",
    padding: "4px",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: textFontSize.re,
  }),
  selected: css({
    borderBottom: `2px solid ${productColor.primary}`,
  }),
  notSelected: css({
    cursor: "pointer",
    ":hover": {
      backgroundColor: productColor.primaryM95,
      borderBottom: `2px solid ${productColor.primary}`,
    },
  }),
  radio: css({
    display: "none",
  }),
};

interface TablItem {
  value: string;
  display?: string;
}

const TabControl: React.FC<{
  items: Array<TablItem>;
  setValue: (value: string) => void;
  currentValue: string;
  isFixed?: boolean;
  type?: number;
  styleActive?: any;
  styleNotActive?: any;
  isTabModal?: boolean;
  customStyle?: SerializedStyles;
}> = ({
  items,
  setValue,
  currentValue,
  isFixed = true,
  isTabModal = false,
  type = 0,
  styleActive = {},
  styleNotActive = {},
  customStyle,
}) => {
  const styles = type === 0 ? styles1 : styles2;

  return (
    <div
      css={css([
        isFixed
          ? css(styles.wrapper, {
              width: (isIPad13 || isTablet ? 140 : 160) * items.length,
            })
          : isTabModal
          ? css(styles1.tabModal)
          : styles.wrapper,
        customStyle,
      ])}
    >
      {items.map((item) => (
        <React.Fragment key={item.value}>
          <label
            htmlFor={item.value}
            css={[
              css(
                styles.label,
                currentValue === item.value
                  ? styles.selected
                  : isTabModal
                  ? styles1.tabModalNotSelected
                  : styles.notSelected
              ),
              css(currentValue === item.value ? styleActive : styleNotActive),
            ]}
          >
            <input
              css={styles.radio}
              type="radio"
              id={item.value}
              value={item.value}
              checked={item.value === currentValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
            />
            {item.display || item.value}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default TabControl;
