/* eslint-disable @typescript-eslint/no-unused-vars */

/** @jsx jsx */
import React, { useEffect } from "react";

import { Terms } from "domain/master/attend/terms";
import EmploymentBasicSettings from "./EmploymentBasicSettings";
import EmploymentWorkSystemAndBreakSettings from "./EmploymentWorkSystemAndBreakSettings";
import EmploymentWorkTimeRoundingSettings from "./EmploymentWorkTimeRoundingSettings";
import EmploymentVacationSettings from "./EmploymentVacationSettings";
import EmploymentOvertimeSettings from "./EmploymentOvertimeSettings";
import EmploymentOtherSettings from "./EmploymentOtherSettings";
import StepperForm from "components/molecules/Stepper";
import { css, jsx } from "@emotion/core";
import EmploymentAlertSettings from "./EmploymentAlertSettings";
import EmploymentGPSStampSettings from "./EmploymentGPSStampSettings";
import { useEmploymentAddForm } from "./hooks";
import FormTitle from "components/atoms/Form/FormTitle";

const styles = {
  formContent: css({
    padding: "30px",
    maxWidth: "750px",
    margin: "0 auto",
  }),
  formContent100: css({
    padding: "0px",
    maxWidth: "100%",
    margin: "0 auto",
  }),
  stepper: css({
    maxWidth: "960px",
    margin: "0 auto",
    marginTop: "70px",
  }),
};

const step = [
  {
    title: "基本設定",
  },
  {
    title: "勤務体系・休憩設定",
  },
  {
    title: "丸め設定",
  },
  {
    title: "残業・手当設定",
  },
  {
    title: "休暇設定",
  },
  {
    title: "時間帯設定",
  },
  {
    title: "アラート設定",
  },
  {
    title: "打刻方法設定",
  },
];

const EmploymentInputAddForm: React.FC<{
  formik: any;
  termMst: Array<Terms>;
  stepper: any;
  setStepper: any;
  setIsSubmit: any;
}> = ({ formik, termMst, stepper, setIsSubmit }) => {
  useEffect(() => {
    const elements = document.getElementsByClassName("stepper-title");
    Array.from(elements).forEach((element, index) => {
      element.addEventListener("click", function(e) {
        if (formik.values.employmentId) {
          sessionStorage.setItem("flagStepper", String(Number(index + 1)));
          setIsSubmit(false);
          formik.handleSubmit();
        }
        return false;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.employmentId]);

  return (
    <div>
      <FormTitle bold={true} title={`${formik.values.employmentId ? "雇用形態編集" : "雇用形態作成"}`} />
      <div css={styles.stepper}>
        <StepperForm
          steps={step}
          activeStep={stepper}
          showNumber={false}
          onChange={(e) => {
            if (formik.values.employmentId) {
              sessionStorage.setItem("flagStepper", String(Number(e)));
              setIsSubmit(false);
              formik.handleSubmit();
            }
            return false;
          }}
        />
      </div>
      {stepper === 1 ? (
        <div css={styles.formContent}>
          <EmploymentBasicSettings formik={formik} />
        </div>
      ) : (
        ""
      )}
      {stepper === 2 ? (
        <div css={styles.formContent}>
          <EmploymentWorkSystemAndBreakSettings formik={formik} />
        </div>
      ) : (
        ""
      )}
      {stepper === 3 ? (
        <div css={styles.formContent}>
          <EmploymentWorkTimeRoundingSettings formik={formik} />
        </div>
      ) : (
        ""
      )}
      {stepper === 4 ? (
        <div css={styles.formContent}>
          <EmploymentOvertimeSettings formik={formik} />
        </div>
      ) : (
        ""
      )}
      {stepper === 5 ? (
        <div css={styles.formContent}>
          <EmploymentVacationSettings formik={formik} />
        </div>
      ) : (
        ""
      )}
      {stepper === 6 ? (
        <div css={styles.formContent100}>
          <EmploymentOtherSettings formik={formik} termMst={termMst} />
        </div>
      ) : (
        ""
      )}
      {stepper === 7 ? (
        <div css={styles.formContent}>
          <EmploymentAlertSettings formik={formik} termMst={termMst} />
        </div>
      ) : (
        ""
      )}
      {stepper === 8 ? (
        <div css={styles.formContent}>
          <EmploymentGPSStampSettings formik={formik} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default EmploymentInputAddForm;
