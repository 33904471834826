/* eslint-disable array-callback-return */
import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { createOrUpdateAllowance, getAttendAllowance } from 'api/attendAllowance';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import AttendAllowanceDomain from 'domain/attendAllowance';

type AttendAllowanceDomainKey = keyof Pick<AttendAllowanceDomain, 'allowanceCode'|'allowanceName'>;

export const useAttendAllowanceAddForm = (allowanceId?: string) => {
  let isEdit = false;
  if (allowanceId) {
    isEdit = true;
  } else {
    isEdit = false;
  }
  const [blocking, setBlocking] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [attendAllowance, setAttendAllowance] = useState(AttendAllowanceDomain.generateInitial());
  const { successNotification, errorNotification } = useToastNotification();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const { detailRole } = useGetDetailRole(setBlocking, functionCode.attendAllowance);

  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (/^ *$/.test(val.allowanceCode) && val.allowanceCode !== '') {
      errorNotification('コードに半角、全角スペースは入力できません。');
      return false;
    }
    if (/^ *$/.test(val.allowanceName) && val.allowanceName !== '') {
      errorNotification('手当名称に半角、全角スペースは入力できません。');
      return false;
    }
    if (/^ *$/.test(val.dispMessage) && val.dispMessage !== '') {
      errorNotification('表示メッセージに半角、全角スペースは入力できません。');
      return false;
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    createOrUpdateAllowance(companyCode, false, val.getRawData(), isEdit)
      .then((response: any) => {
        successNotification(allowanceId ? "更新しました。" : "登録しました。");
        history.push('/attendAllowance');
      })
      .catch((error: any) => {
        if (error.response.status === 406) {
          const errorString : string = error.response.data.errors[0].defaultMessage;
          setAttendAllowance(val.getRawData());
          setConfirmModalOpen(false);
          errorNotification(errorString);
        } else {
          setConfirmModalOpen(false);
          if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
            const listErr = error.response.data.errors;
            let stringErr = '';
            listErr.map((element: any, index: any) => {
              stringErr += `${element.defaultMessage}<br />`;
            });
            errorNotification(stringErr);
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: attendAllowance || AttendAllowanceDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (allowanceId !== undefined) {
      setBlocking(true);
      getAttendAllowance(companyCode, allowanceId).then((response: any) => {
        setAttendAllowance(new AttendAllowanceDomain(response));
        formik.setValues(new AttendAllowanceDomain(response));
        setBlocking(false);
      });
    }
  }, [allowanceId]);
  console.log("formil.value", formik.values);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
    setConfirmModalOpen(false);
    setConfirmModalResendOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const submitConfirmResendModal = useCallback(() => {
    createOrUpdateAllowance(companyCode, true, attendAllowance, isEdit)
      .then((response: any) => {
        history.goBack();
      })
      .catch((error2: any) => {
        if (error2.response.data.errors.length !== 0) {
          errorNotification(error2.response.data.errors[error2.response.data.errors.length - 1]
            .defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
    setConfirmModalResendOpen(false);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode, history, attendAllowance]);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    confirmModalResendOpen,
    closeConfirmResendModal,
    submitConfirmResendModal,
    confirmModalContent,
    history,
    blocking,
    detailRole,
  };
};


export default useAttendAllowanceAddForm;
