import ApiClient from './ApiClient';
import { StaffICCardDomain } from "domain/master/icCard/staffICCard";
import { ICCardByStaffDomain } from "domain/master/icCard/listICCardByStaff";
import { AxiosResponse } from 'axios';
import moment from 'moment';

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYY/MM/DD')}.csv`;

export const getStaffICCard = async (orgCode: string, functionCode: string, underFlag: number): Promise<Array<StaffICCardDomain>> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    loginStaffCode,
    functionCode,
    underFlag
  }
  const response = await ApiClient.get(`/v2/icCard/staffsByOrg/${companyCode}`, params);
  return response.data;
};

export const getDetailCardId = async (cardId: string): Promise<ICCardByStaffDomain> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    cardId
  }
  const response = await ApiClient.get(`/v1/icCard/${companyCode}`, params);
  return response.data;
};

export const getListICCardByStaff = async (staffCode: string): Promise<Array<ICCardByStaffDomain>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    staffCode
  }

  const response = await ApiClient.get(`/v1/icCard/listCardByStaffCode/${companyCode}`, params);
  return response.data;
};

export const getUnRegisterCard = async (): Promise<Array<StaffICCardDomain>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {

  }

  const response = await ApiClient.get(`/v1/icCard/list/unregister/${companyCode}`, params);
  return response.data;
};

export const postRegisterStaffICCard = async (idCode: string, staffCode: string): Promise<Array<ICCardByStaffDomain>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    staffCode,
    cardId: idCode,
    createUser: sessionStorage.getItem('loginUser.staffName') || "",
    updateUser: sessionStorage.getItem('loginUser.staffName') || "",
  }

  const response = await ApiClient.post(`/v1/icCard/registerCardForUser/${companyCode}`, params);
  return response.data;
};

export const postRegisterStaffBulkICCard = async (listCardId: string, staffCode: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    staffCode,
    cardIds: listCardId,
    createUser: sessionStorage.getItem('loginUser.staffName') || "",
    updateUser: sessionStorage.getItem('loginUser.staffName') || "",
  }

  const response = await ApiClient.post(`/v1/icCard/registerCardListForUser/${companyCode}`, params);
  return response.data;
};

export const postDeleteStaffICCard = async (idCode: string, staffCode: string): Promise<Array<ICCardByStaffDomain>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    cardId: idCode,
  }

  const response = await ApiClient.delete(`/v1/icCard/delete/${companyCode}`, params);
  return response.data;
};

export const downloadCSV = async (
  orgCode: string,
  functionCode: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  const params = {
    orgCode,
    loginStaffCode,
    functionCode,
    underFlag
  };
  await ApiClient.downloadCsv(`/v2/icCard/exportCsv/${companyCode}`, params, getDownloadCsvFileName('ic_card_list_'));
};

export const downloadCSVTemplate = async (
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  await ApiClient.downloadCsv(`/v1/icCard/exportCsv/default/${companyCode}`, {}, getDownloadCsvFileName('ic_card_list_'));
};

export const importCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/icCard/importCsv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}