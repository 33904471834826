/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import productColor, { textFontSize } from 'components/styles';
import Modal from 'components/molecules/Modal';
import PanelDescription from 'components/atoms/PanelDescription';

const styles = {
  item: css({
    display: 'flex',
    fontSize: textFontSize.re,
    padding: '8px 0',
    borderBottom: `1px solid ${productColor.primaryM90}`,
  }),
  description: css({}),
  itemValue: css({
    marginLeft: 'auto',
  }),
};

type ConfirmItem = {
  key: string
  value?: string|number
}

/**
 * 登録確認モーダル
 */
const ConfirmModal: React.FC<{
  open: boolean
  closeHandler: () => void
  onSubmit: () => void
  items?: Array<ConfirmItem>
  title?: string
  content?: string
  submitText? : string
  closeText? : string
  typeSubmit?: string
}> = ({
  open, closeHandler, onSubmit, items = [], title = '登録内容の確認', content = '以下の内容で登録します。登録内容を確認してください。', submitText = '保存', closeText, typeSubmit = 'confirm',
}) => (
  <Modal
    open={open}
    closeHandler={closeHandler}
    title={title}
    submitText={submitText}
    onSubmit={onSubmit}
    closeText={closeText}
    typeSubmit={typeSubmit}
  >
    <PanelDescription>
      {content}
    </PanelDescription>
    {items.map((item, index) => {
      if (!item.value) {
        // 入力値がないものは表示しない
        return null;
      }
      return (
        <div css={styles.item} key={index}>
          <strong>
            {item.key}
          </strong>
          <div css={styles.itemValue}>
            {item.value}
          </div>
        </div>
      );
    })}
  </Modal>
);

export default ConfirmModal;
