/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { PrimaryButton, SecondaryButton } from 'components/atoms/Button';

const styles = {
  wrapper: css({
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
  }),
  buttonContainer: css({
    margin: '0 auto 15px auto',
    width: '100%',
  }),
};


/**
 * モーダルのフッター
 *  - Submitボタンを持つ
 */
const Footer: React.FC<{
  submitText?: string
  closeText: string
  closeHandler: () => void
  onSubmit: () => void
  typeSubmit: string
}> = ({
  submitText, onSubmit, closeHandler, closeText, typeSubmit,
}) => (
  <div css={styles.wrapper}>
    <div css={styles.buttonContainer}>
      {submitText && typeSubmit === 'confirm'
      && (
      <PrimaryButton
        text={submitText}
        onClick={onSubmit}
        minWidth="200px"
      />
      )}

      {submitText && typeSubmit === 'reject'
      && (
      <SecondaryButton
        text={submitText}
        onClick={onSubmit}
        minWidth="200px"
      />
      )}

    </div>
    <div css={styles.buttonContainer}>
      {typeSubmit === 'confirm'
      && (
      <PrimaryButton
        text={closeText}
        onClick={closeHandler}
        minWidth="200px"
        ghost={true}
      />
      )}

      {typeSubmit === 'reject'
      && (
      <SecondaryButton
        text={closeText}
        onClick={closeHandler}
        minWidth="200px"
        ghost={true}
      />
      )}
    </div>
  </div>
);

export default Footer;
