/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import HeaderCell from "./HeaderCell";
import Cell from "./Cell";

const style = css({
  whiteSpace: "pre-wrap",
  width: "100%",
  border: "1px solid #ddd",
  borderSpacing: "0px 1.5px",
  borderCollapse: "collapse",
  tableLayout: "fixed",
});

interface TableProps {
  customStyle?: any;
  className?: string;
}

const Table: React.FC<TableProps> & {
  HeaderCell: typeof HeaderCell;
  Cell: typeof Cell;
} = ({ children, customStyle, className = "" }) => (
  <table className={className} css={css(style, customStyle)}>
    {children}
  </table>
);

Table.HeaderCell = HeaderCell;
Table.Cell = Cell;

export default Table;
