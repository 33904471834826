import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import ShiftPatternAddForm from 'components/organismos/master/labor/shiftPattern/ShiftPatternAddForm';
import { useParams } from 'react-router-dom';

const ShiftPatternAddPage: React.FC = () => {
  const {
    shiftPatternId,
  } = useParams();

  return (
    <SidebarTemplate pageTitle={shiftPatternId ? 'シフトパターンマスタ編集' : '新規シフトパターンマスタ作成'}>
      <ShiftPatternAddForm />
    </SidebarTemplate>
  );
};

export default ShiftPatternAddPage;
