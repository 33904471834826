import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

interface BusinessCategory {
  businessCategoryId: string;
  businessCategoryCode: string;
  businessCategoryName: string;
}

export const getList = async (): Promise<Array<BusinessCategory>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
  };
  const response = await ApiClient.get(`/v1/businessCategories/${companyCode}`, params);
  return response.data;
};

export default getList;

export const post = async (businessCategory: BusinessCategory) => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/businessCategories/${companyCode}`, {}, businessCategory);
  return response.data;
};

export const getBusinessCategoryById = async (businessCategoryId: string): Promise<any> => {
  const params = {
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/businessCategories/${companyCode}/${businessCategoryId}`, params);
  return response.data;
};

export const createOrUpdateBusinessCategory = async (companyCode: string, isAccepted: boolean,
  data: any, isEdit: boolean) => {
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
    businessCategoryCode: data.businessCategoryCode.trim(),
    businessCategoryName: data.businessCategoryName.trim(),
    businessCategoryId: data.businessCategoryId,
  };
  const query = {
    isAccepted,
  };
  const response = await ApiClient.post(`/v1/businessCategories/${companyCode}`, query, params);
  return response.data;
};

export const deleteById = async (businessCategoryId: string): Promise<any> => {
  const params = {
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.delete(`/v1/businessCategories/${companyCode}/${businessCategoryId}`, params);
  return response.data;
};

export const downloadCSV = async (
  companyCode: string,
): Promise<void> => {
  const params = {};
  await ApiClient.downloadCsv(`/v1/businessCategories/outputCsv/${companyCode}`, params, getDownloadCsvFileName('業態情報_'));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    formatType: 0,
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/businessCategories/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}
