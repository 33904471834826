/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import moment from "moment";
import React from "react";
import { IchangeLog } from "../interface";

const styleTable = css`
	th {
		width: 100px !important;
	}
	td {
		text-align: center;
	}
`;

const AttendAgreement36Modal: React.FC<{
	changeLogData: IchangeLog[];
	selectChangelog: string;
	customStyle?: any;
}> = ({ changeLogData, selectChangelog, customStyle }) => {
	return (
		<React.Fragment>
			{changeLogData[Number(selectChangelog)]?.attendModifyAgreementsAppData && (
				<FormPadding customStyle={customStyle}>
					<div css={styleTable}>
						<Table customStyle={css({ width: "auto", padding: "14px 10px" })}>
							<thead>
								<tr>
									<Table.HeaderCell>対象月</Table.HeaderCell>
									<Table.HeaderCell>見込み残業時間</Table.HeaderCell>
								</tr>
							</thead>
							<tbody>
								{changeLogData && changeLogData[Number(selectChangelog)].attendModifyAgreementsAppData?.length! > 0 ? (
									changeLogData[Number(selectChangelog)].attendModifyAgreementsAppData?.slice(-1).map((ver, i) => (
										<tr key={ver.modifierApplicationId}>
											<Table.Cell>
												<BodyText>{moment(ver.targetDate).format("YYYY/MM")}</BodyText>
											</Table.Cell>
											<Table.Cell>
												<BodyText>
													{ver.applicationExpectedOvertimeHours ? ver.applicationExpectedOvertimeHours : "0"}
													{"時間"}
												</BodyText>
											</Table.Cell>
										</tr>
									))
								) : (
									<tr>
										<Table.Cell>
											<BodyText>
												{changeLogData[Number(selectChangelog)].targetDate
													? changeLogData[Number(selectChangelog)].targetDate
													: "-"}
											</BodyText>
										</Table.Cell>
										<Table.Cell>
											<BodyText>-</BodyText>
										</Table.Cell>
										<Table.Cell>
											<BodyText>-</BodyText>
										</Table.Cell>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				</FormPadding>
			)}
		</React.Fragment>
	);
};

export default AttendAgreement36Modal;
