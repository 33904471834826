/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import moment from "moment";
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import TableAgreement36 from "./TableAgreement36";
import useAttendAgreement36 from "./hooks";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import FormPadding from "components/atoms/Form/FormPadding";
import FormLabel from "components/atoms/Form/FormLabel";
import Icon from "components/atoms/Icon";
import BodyText from "components/atoms/BodyText";
import productColor from "components/styles";
import { Link } from "react-router-dom";
import Table from "components/molecules/Table";
import SelectForm from "components/molecules/SelectForm";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import Checkbox from "components/molecules/Checkbox";
import useIsMobile from "hooks/useIsMobile";
import MobileTableAgreement36 from "components/organismos/AttendAgreement36/MobileTableAgreement36";

const AttendAgreement36: React.FC = () => {
  // アップロードファイル

  const styles = {
    title: css({
      fontSize: "18px",
      color: "#333333",
      fontWeight: 700,
      marginLeft: "0",
    }),
    errText: css({
      color: productColor.deleteAction,
      fontSize: "14px",
    }),
    textDecoration: css({
      textDecoration: "underline",
    }),
    alertLabel: css({
      margin: "0 15px",
    }),
    tableContainer: css({
      padding: "15px 35px",
      width: "calc( 50% - 70px )",
      alignSelf: "flex-start",
    }),
    tableContent: css`
      td {
        :last-child {
          color: ${productColor.primary};
        }
        text-align: center;
      }
    `,
    titleTable: css({
      fontWeight: "bold",
    }),
    errorNote: css({
      color: productColor.secondary,
    }),
    warningNote: css({
      color: productColor.copyAction,
    }),
    linkText: css({
      color: productColor.primary,
      fontSize: "14px",
    }),
  };

  const getStyleLabel = (index: number, lengthList: number) => {
    switch (index) {
      case lengthList - 2:
        return styles.warningNote;
      case lengthList - 1:
        return styles.errorNote;
      default:
        return {};
    }
  };

  const {
    isLoading,
    tableAgreement36Data,
    applyDate,
    setApplyDate,
    alertAgreement36Data,
    staffCode,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    myAlertAgreement36Data,
    dateFrom,
    dateTo,
    detailRole,
    underFlag,
    setUnderFlag,
    setIsReloader,
    agreementsId,
    setAgreementsId,
    agreementsOptions,
  } = useAttendAgreement36();
  const isMobile = useIsMobile();

  return (
    <BlockUI blocking={isLoading}>
      <FormContents
        customStyle={css({
          paddingBottom: isMobile ? "8px" : "35px",
        })}
      >
        <FormTitle
          bold={true}
          title="ホーム"
          customStyle={css(isMobile ? { padding: "8px 16px 0 16px", margin: 0 } : {})}
        />
        <FormPadding customStyle={css({ padding: "0 16px 16px 16px" })}>
          <FlexBox customStyle={css({ margin: "10px 0" })}>
            <FormLabel label="営業月" customStyle={css({ width: "100px", margin: "0" })} />
            <FlexBoxItem>
              <CalendarMonth
                openActionChevron={true}
                date={applyDate}
                setDate={(date: Date) => {
                  if (date.getMonth() !== applyDate.getMonth() || date.getFullYear() !== applyDate.getFullYear()) {
                    setApplyDate(date);
                    sessionStorage.setItem("agreement36.applyTargetDate", moment(date).format("YYYY/MM/DD"));
                  }
                }}
              />
            </FlexBoxItem>
          </FlexBox>

          <div>
            {alertAgreement36Data && alertAgreement36Data.errorCount > 0 && (
              <FlexBox>
                <Icon type="error" color={"red"} margin="0 0 4px 0" />
                <BodyText customStyle={styles.alertLabel}>打刻エラー</BodyText>
                {detailRole.linkAvailable2 ? (
                  <Link
                    to="/attendError"
                    css={css([styles.errText, styles.textDecoration])}
                    onClick={() => {
                      sessionStorage.setItem("attendError.staffCode", staffCode);
                      sessionStorage.setItem("attendError.isFilter", "true");
                      sessionStorage.setItem("attendError.targetDateFrom", dateFrom);
                      sessionStorage.setItem("attendError.targetDateTo", dateTo);
                      sessionStorage.setItem("attendError.orgCode", "all");
                    }}
                  >
                    {alertAgreement36Data.errorCount}件
                  </Link>
                ) : (
                  <BodyText customStyle={styles.errText}>{alertAgreement36Data.errorCount}件</BodyText>
                )}
              </FlexBox>
            )}
            {!isMobile &&
              alertAgreement36Data &&
              alertAgreement36Data.approvalCount > 0 &&
              alertAgreement36Data.isApproval === 1 && (
                <FlexBox>
                  <Icon type="error" color={"red"} margin="0 0 4px 0" />
                  <BodyText customStyle={styles.alertLabel}>未承認の勤怠申請</BodyText>
                  {detailRole.linkAvailable1 ? (
                    <Link
                      to="/attendApplicationApproval"
                      css={css([styles.errText, styles.textDecoration])}
                      onClick={() => {
                        sessionStorage.setItem("attendApplicationApproval.targetDateFrom", dateFrom);
                        sessionStorage.setItem("attendApplicationApproval.targetDateTo", dateTo);
                      }}
                    >
                      {alertAgreement36Data.approvalCount}件
                    </Link>
                  ) : (
                    <BodyText customStyle={styles.errText}>{alertAgreement36Data.approvalCount}件</BodyText>
                  )}
                </FlexBox>
              )}
            {alertAgreement36Data && alertAgreement36Data.alertCount > 0 && (
              <FlexBox>
                <Icon type="error" color={"red"} margin="0 0 4px 0" />
                <BodyText customStyle={styles.alertLabel}>その他アラート</BodyText>
                {detailRole.linkAvailable2 ? (
                  <Link
                    to="/attendError"
                    css={css([styles.errText, styles.textDecoration])}
                    onClick={() => {
                      sessionStorage.setItem("attendError.staffCode", staffCode);
                      sessionStorage.setItem("attendError.isFilter", "false");
                      sessionStorage.setItem("attendError.targetDateFrom", dateFrom);
                      sessionStorage.setItem("attendError.targetDateTo", dateTo);
                      sessionStorage.setItem("attendError.orgCode", "all");
                    }}
                  >
                    {alertAgreement36Data.alertCount}件
                  </Link>
                ) : (
                  <BodyText customStyle={styles.errText}>{alertAgreement36Data.alertCount}件</BodyText>
                )}
              </FlexBox>
            )}
          </div>
        </FormPadding>

        {tableAgreement36Data && (
          <React.Fragment>
            {isMobile ? (
              <MobileTableAgreement36 tableAgreement36Data={tableAgreement36Data} editable={detailRole.editable} />
            ) : (
              <TableAgreement36 tableAgreement36Data={tableAgreement36Data} editable={detailRole.editable} />
            )}
          </React.Fragment>
        )}
        {!isMobile && (
          <React.Fragment>
            {myAlertAgreement36Data && myAlertAgreement36Data.agreementsMst !== null && (
              <React.Fragment>
                <FormTitle title="勤怠アラート" bold={true} customStyle={css({ marginTop: "70px" })} />
                {detailRole.accessRange !== 0 && (
                  <FlexBox customStyle={css({ margin: "20px 35px" })}>
                    <SelectForm
                      label="36協定"
                      width="900px"
                      widthLabel="100px"
                      name="agreementsId"
                      value={String(agreementsId)}
                      setValue={(v) => {
                        setAgreementsId(v);
                      }}
                      options={agreementsOptions}
                    />
                  </FlexBox>
                )}
                {detailRole.accessRange !== 0 && (
                  <FlexBox customStyle={css({ margin: "20px 35px" })}>
                    <SelectForm
                      label="組織"
                      name="orgCode"
                      value={String(orgCode)}
                      setValue={(v) => {
                        setOrgCode(v);
                        setIsReloader(true);
                      }}
                      options={orgTreesOptions}
                      description=""
                      width="900px"
                      widthLabel="100px"
                      marginRight="30px"
                    />
                    <Checkbox
                      id={"underFlag"}
                      name={"underFlag"}
                      label={"配下指定"}
                      value={`underFlag`}
                      checked={Boolean(underFlag)}
                      onChange={() => {
                        setUnderFlag(underFlag === 1 ? 0 : 1);
                      }}
                    />
                  </FlexBox>
                )}
                <React.Fragment>
                  <FormTitle title="月次" bold={true} />
                  <FlexBox customStyle={css({ flexWrap: "wrap" })}>
                    {/* 月次 法定外  */}
                    <FlexBoxItem customStyle={styles.tableContainer}>
                      <FormLabel label="法定時間外労働" customStyle={styles.titleTable} />
                      <Table customStyle={styles.tableContent}>
                        <thead>
                          <tr>
                            <Table.HeaderCell>アラート内容</Table.HeaderCell>
                            <Table.HeaderCell>件数</Table.HeaderCell>
                          </tr>
                        </thead>
                        <tbody>
                          {myAlertAgreement36Data?.monthlyOvertimeAlertCntAry?.map((item, index) => (
                            <tr>
                              <Table.Cell
                                css={getStyleLabel(index, myAlertAgreement36Data?.monthlyOvertimeAlertCntAry?.length)}
                              >
                                {item[0]}時間を超過
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  to="/monthlyAgreement36"
                                  css={css([styles.linkText, styles.textDecoration])}
                                  onClick={() => {
                                    sessionStorage.setItem("monthlyAgreement36.agreementsId", agreementsId);
                                    sessionStorage.setItem(
                                      "monthlyAgreement36.applyDate",
                                      moment(applyDate).format("YYYY/MM/DD"),
                                    );
                                    sessionStorage.setItem("monthlyAgreement36.orgCode", orgCode);
                                    sessionStorage.setItem("monthlyAgreement36.underFlag", String(underFlag));
                                    sessionStorage.setItem("monthlyAgreement36.monthlyOverTime", String(item[0] * 60));
                                  }}
                                >
                                  {item[1]}件
                                </Link>
                              </Table.Cell>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FlexBoxItem>
                    {/* 月次 法定外と休日出勤 */}
                    <FlexBoxItem customStyle={styles.tableContainer}>
                      <FormLabel label="法定時間外労働と休日出勤の合計" customStyle={styles.titleTable} />
                      <Table customStyle={styles.tableContent}>
                        <thead>
                          <tr>
                            <Table.HeaderCell>アラート内容</Table.HeaderCell>
                            <Table.HeaderCell>件数</Table.HeaderCell>
                          </tr>
                        </thead>
                        <tbody>
                          {myAlertAgreement36Data?.overHolidayTimeAlertCntAry?.map((item, index) => (
                            <tr>
                              <Table.Cell
                                css={getStyleLabel(index, myAlertAgreement36Data?.overHolidayTimeAlertCntAry?.length)}
                              >
                                {item[0]}時間を超過
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  to="/monthlyAgreement36"
                                  css={css([styles.linkText, styles.textDecoration])}
                                  onClick={() => {
                                    sessionStorage.setItem("monthlyAgreement36.agreementsId", agreementsId);
                                    sessionStorage.setItem(
                                      "monthlyAgreement36.applyDate",
                                      moment(applyDate).format("YYYY/MM/DD"),
                                    );
                                    sessionStorage.setItem("monthlyAgreement36.orgCode", orgCode);
                                    sessionStorage.setItem("monthlyAgreement36.underFlag", String(underFlag));
                                    sessionStorage.setItem(
                                      "monthlyAgreement36.monthlyOverHolidayTime",
                                      String(item[0] * 60),
                                    );
                                  }}
                                >
                                  {item[1]}件
                                </Link>
                              </Table.Cell>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FlexBoxItem>
                  </FlexBox>
                  <FormTitle title="年次" bold={true} />
                  <FlexBox customStyle={css({ flexWrap: "wrap" })}>
                    {/* 年次 法定外 */}
                    <FlexBoxItem customStyle={styles.tableContainer}>
                      <FormLabel label="年間の法定時間外残業" customStyle={styles.titleTable} />
                      <Table customStyle={styles.tableContent}>
                        <thead>
                          <tr>
                            <Table.HeaderCell>アラート内容</Table.HeaderCell>
                            <Table.HeaderCell>件数</Table.HeaderCell>
                          </tr>
                        </thead>
                        <tbody>
                          {myAlertAgreement36Data.overtimeAlertCntAry?.map((item, index) => (
                            <tr>
                              <Table.Cell
                                css={getStyleLabel(index, myAlertAgreement36Data.overtimeAlertCntAry.length)}
                              >
                                {item[0]}時間を超過
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  to="/yearlyAgreement36"
                                  css={css([styles.linkText, styles.textDecoration])}
                                  onClick={() => {
                                    sessionStorage.setItem("yearlyAgreement36.agreementsId", agreementsId);
                                    sessionStorage.setItem(
                                      "yearlyAgreement36.applyYear",
                                      String(myAlertAgreement36Data.agreementsMst.targetYear),
                                    );
                                    sessionStorage.setItem("yearlyAgreement36.orgCode", orgCode);
                                    sessionStorage.setItem("yearlyAgreement36.underFlag", String(underFlag));
                                    sessionStorage.setItem("yearlyAgreement36.yearlyOverTime", String(item[0] * 60));
                                  }}
                                >
                                  {item[1]}件
                                </Link>
                              </Table.Cell>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FlexBoxItem>
                    {/* 年次 複数月 */}
                    <FlexBoxItem customStyle={styles.tableContainer}>
                      <FormLabel label="複数月の平均残業時間" customStyle={styles.titleTable} />
                      <Table customStyle={styles.tableContent}>
                        <thead>
                          <tr>
                            <Table.HeaderCell>アラート内容</Table.HeaderCell>
                            <Table.HeaderCell>件数</Table.HeaderCell>
                          </tr>
                        </thead>
                        <tbody>
                          {myAlertAgreement36Data.averageTimeAlertCntAry?.map((item, index) => (
                            <tr>
                              <Table.Cell
                                css={getStyleLabel(index, myAlertAgreement36Data.averageTimeAlertCntAry.length)}
                              >
                                {item[0]}時間を超過
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  to="/yearlyAgreement36"
                                  css={css([styles.linkText, styles.textDecoration])}
                                  onClick={() => {
                                    sessionStorage.setItem("yearlyAgreement36.agreementsId", agreementsId);
                                    sessionStorage.setItem(
                                      "yearlyAgreement36.applyYear",
                                      String(myAlertAgreement36Data.agreementsMst.targetYear),
                                    );
                                    sessionStorage.setItem("yearlyAgreement36.orgCode", orgCode);
                                    sessionStorage.setItem("yearlyAgreement36.underFlag", String(underFlag));
                                    sessionStorage.setItem("yearlyAgreement36.averageOverTime", String(item[0] * 60));
                                  }}
                                >
                                  {item[1]}件
                                </Link>
                              </Table.Cell>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FlexBoxItem>
                    {/* 年次 申請回数 */}
                    <FlexBoxItem customStyle={styles.tableContainer}>
                      <FormLabel
                        label={`法定時間外労働${myAlertAgreement36Data.agreementsMst.monthOverConditionsHour}時間超過申請`}
                        customStyle={styles.titleTable}
                      />
                      <Table customStyle={styles.tableContent}>
                        <thead>
                          <tr>
                            <Table.HeaderCell>アラート内容</Table.HeaderCell>
                            <Table.HeaderCell>件数</Table.HeaderCell>
                          </tr>
                        </thead>
                        <tbody>
                          {myAlertAgreement36Data?.overConditionsAlertCntAry?.map((item, index) => (
                            <tr>
                              <Table.Cell
                                css={getStyleLabel(index, myAlertAgreement36Data?.overConditionsAlertCntAry?.length)}
                              >
                                {item[0]}回超過
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  to="/yearlyAgreement36"
                                  css={css([styles.linkText, styles.textDecoration])}
                                  onClick={() => {
                                    sessionStorage.setItem("yearlyAgreement36.agreementsId", agreementsId);
                                    sessionStorage.setItem(
                                      "yearlyAgreement36.applyYear",
                                      String(myAlertAgreement36Data.agreementsMst.targetYear),
                                    );
                                    sessionStorage.setItem("yearlyAgreement36.orgCode", orgCode);
                                    sessionStorage.setItem("yearlyAgreement36.underFlag", String(underFlag));
                                    sessionStorage.setItem("yearlyAgreement36.overCount", String(item[0]));
                                  }}
                                >
                                  {item[1]}件
                                </Link>
                              </Table.Cell>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </FlexBoxItem>
                  </FlexBox>
                </React.Fragment>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </FormContents>
    </BlockUI>
  );
};

export default AttendAgreement36;
