import React, { useMemo, useState } from 'react';
import { css } from '@emotion/core';
import SalesMenuTypeDomain from 'domain/master/sales/salesMenuType';
import { Column } from 'react-table';
// import SelectForm from 'components/molecules/SelectForm';

import DataTable from 'components/organismos/DataTable/DataTable';
import { useOrgTreesByCategoryOptions } from 'hooks/useOrgTreesOptions';

import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import SalesMenuTypeAddForm from './SalesMenuTypeAddForm';
import useMenuTypeList from './hooks';

const SalesMenuTypeForm = () => {
  const [orgCode, setorgCode] = useState('');
  const [isCreate, setIsCreate] = useState(true);
  const [menuTypeObj, setMenuTypeObj] = useState(SalesMenuTypeDomain.generateInitial());

  const orgTreesOptions = useOrgTreesByCategoryOptions();
  const { menuTypeList, setMenuTypeList } = useMenuTypeList(orgCode);
  const [foodCode, setFoodCode] = useState('');
  const [drinkCode, setDrinkCode] = useState('');
  const [unDecided, setUnDecided] = useState(true);

  useMemo(() => {
    setorgCode(sessionStorage.getItem('selectedOrgCode')!);
  }, []);

  const columns: Array<Column<SalesMenuTypeDomain>> = useMemo(() => [
    {
      Header: 'フード',
      accessor: 'listFood',
    },
    {
      Header: 'ドリンク',
      accessor: 'listDrink',
    },
    {
      Header: '適用開始月',
      accessor: 'applyStartDateStr',
    },
    {
      Header: '適用終了月',
      accessor: 'applyEndDateStr',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: SalesMenuTypeDomain } }) => {
        const [isHoverEdit, setIsHoverEdit] = useState(false);
        return (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            <div
              onClick={() => {
                const data : SalesMenuTypeDomain = new SalesMenuTypeDomain(
                  { ...cell.row.original.getRawData() },
                );
                setIsCreate(false);
                setUnDecided(false);
                setMenuTypeObj(data);
              }}
            >
              <span
                style={{ marginLeft: '20px' }}
                onMouseEnter={() => setIsHoverEdit(true)}
                onMouseLeave={() => setIsHoverEdit(false)}
              >
                <Icon type="edit" color={isHoverEdit ? iconColor.grayhover : iconColor.gray} />
              </span>
            </div>
          </div>
        );
      },
    },
  ], []);
  return (
    <>
      <FlexBox
        customStyle={css({
          padding: '8px',
        })}
      >
        <FlexBoxItem basis="100px">
          <BodyText>
            組織
          </BodyText>
        </FlexBoxItem>
        <FlexBoxItem basis="400px">
          <Select
            name="orgCode"
            value={orgCode}
            setValue={(v) => {
              sessionStorage.setItem('selectedOrgCode', v);
              setorgCode(v);
              setIsCreate(true);
              setFoodCode('');
              setDrinkCode('');
              setUnDecided(true);
              setMenuTypeObj(SalesMenuTypeDomain.generateInitial());
            }}
            options={orgTreesOptions}
          />
        </FlexBoxItem>
      </FlexBox>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
        <DataTable
          columns={columns}
          data={menuTypeList}
        />
      </div>

      <div style={{ paddingRight: '12px' }}>
        <SalesMenuTypeAddForm
          menuTypeList={menuTypeList}
          setMenuTypeList={setMenuTypeList}
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          menuTypeObj={menuTypeObj}
          setMenuTypeObj={setMenuTypeObj}
          foodCode={foodCode}
          setFoodCode={setFoodCode}
          drinkCode={drinkCode}
          setDrinkCode={setDrinkCode}
          setUnDecided={setUnDecided}
          unDecided={unDecided}
        />
      </div>

    </>
  );
};

export default SalesMenuTypeForm;
