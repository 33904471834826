/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { useICCardRecorderEmptySpecifyCode } from "./hooks";
import ICCardRecorderUI from "./ICCardRecorderUI";

const ICCardRecorderEmptySpecifyCode: React.FC<{
  dataTimes: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHistoryData: any;
}> = ({
  dataTimes,
  setIsLoading,
  fetchHistoryData,
}) => {
  const {
    isConfirm,
    lastStampType,
    handleRecorder,
    detectCard,
    isConnectICCardUsb
  } = useICCardRecorderEmptySpecifyCode(
    dataTimes.resultCurrentTime,
    setIsLoading,
    fetchHistoryData
  );

  return (
    <ICCardRecorderUI
      dataTimes={dataTimes}
      isConfirm={isConfirm}
      lastStampType={lastStampType}
      handleRecorder={handleRecorder}
      detectCard={detectCard}
      isConnectICCardUsb={isConnectICCardUsb}
    />
  );
};

export default ICCardRecorderEmptySpecifyCode;
