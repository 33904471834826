/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import moment from "moment";
import React from "react";
import { IchangeLog } from "../interface";

const styleTable = css`
	th {
		width: 100px !important;
	}
	td {
		text-align: center;
	}
`;

const AttendHolidayworkModal: React.FC<{
	changeLogData: IchangeLog[];
	selectChangelog: string;
	customStyle?: any;
}> = ({ changeLogData, selectChangelog, customStyle }) => {
	return (
		<React.Fragment>
			<FormPadding customStyle={customStyle}>
				<div css={styleTable}>
					<Table customStyle={css({ width: "auto", padding: "14px 10px" })}>
						<thead>
							<tr>
								<Table.HeaderCell>対象日付</Table.HeaderCell>
								<Table.HeaderCell>休日種別</Table.HeaderCell>
							</tr>
						</thead>
						<tbody>
							{changeLogData && changeLogData[Number(selectChangelog)]?.holidayWorkData?.length! > 0 ? (
								changeLogData[Number(selectChangelog)]?.holidayWorkData?.slice(-1).map((ver, i) => (
									<tr key={ver.modifierApplicationId}>
										<Table.Cell>
											<BodyText>{ver.targetDate ? moment(ver.targetDate).format("YYYY/MM/DD") : "-"}</BodyText>
										</Table.Cell>
										<Table.Cell>
											<BodyText>{ver.legalName ? ver.legalName : "-"}</BodyText>
										</Table.Cell>
									</tr>
								))
							) : (
								<tr>
									<Table.Cell>
										<BodyText>
											{changeLogData[Number(selectChangelog)]?.targetDate
												? changeLogData[Number(selectChangelog)]?.targetDate
												: "-"}
										</BodyText>
									</Table.Cell>
									<Table.Cell>
										<BodyText>-</BodyText>
									</Table.Cell>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</FormPadding>
		</React.Fragment>
	);
};

export default AttendHolidayworkModal;
