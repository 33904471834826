import {
  getAttendApplicationApprovalDetailApi,
  getAttendApplicationApprovalDetailWorkPlanApi,
} from "api/attendApplicationApproval";
import useToastNotification from "hooks/useToastNotification";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  postApproveAllowance,
  postApproveOvertime,
  postApproveStamp,
  postApproveTransfer,
} from "../AttendApplicationList/api";
import moment from "moment";
import {
  postApproveAgreement36,
  postApproveHoliday,
  postApproveHolidayWork,
  postApproveWorkSchedule,
  postApproveStockPaidHoliday,
} from "../AttendApplicationApproval/api";

export const useAttendApplicationApprovalDetail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { applicationType, modifierApplicationId } = useParams();

  const [detail, setDetail] = useState<any>();
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [isHiddenButton, setIsHiddenButton] = useState(false);
  const [buttonType, setButtonType] = useState<number>(0);
  const [rejectReason, setRejectReason] = useState("");
  const { errorNotification, successNotification } = useToastNotification();
  const [changeLogModalOpen, setChangeLogModalOpen] = useState(false);
  const [changeLogData, setChangeLogData] = useState(false);

  const fetchData = () => {
    let apiFetch = "";
    let isWorkPlan = false;
    switch (applicationType) {
      case "attendStamp":
        apiFetch = "/v1/attendModifier/stampHistoryListByAppId";
        break;
      case "attendHoliday":
        apiFetch = "/v1/attendModifier/modifierHistoryListByAppId";
        break;
      case "attendOvertime":
        apiFetch = "/v1/attendOvertime/overtimeHistoryListByAppId";
        break;
      case "attendHolidaywork":
        apiFetch = "/v1/attendHoliday/holidayWorkHistoryListByAppId";
        break;
      case "attendAgreement36":
        apiFetch = "/v1/36Agreements/overtimeHistoryListByAppId";
        break;
      case "attendTransfer":
        apiFetch = "/v1/attendTransfer/transferHistoryListByAppId";
        break;
      case "attendWorkScheduleChange":
        apiFetch = "/v1/attendWorkPlan/workPlanHistoryList";
        isWorkPlan = true;
        break;
      case "attendStockPaidHoliday":
        apiFetch = "/v1/attendStockPaidHoliday/stockPaidHolidayHistoryListByAppId";
        break;
      case "attendAllowance":
        apiFetch = "/v1/attendAllowance/allowanceHistoryListByAppId";
        break;
      default:
        break;
    }

    if (modifierApplicationId && applicationType && !isWorkPlan) {
      setIsLoading(true);
      getAttendApplicationApprovalDetailApi(apiFetch, modifierApplicationId)
        .then((response) => {
          setDetail(response);
          setIsLoading(false);
        })
        .catch((error) => {
          errorNotification("申請はもう存在しません。");
          setIsLoading(false);
        });
    }

    if (modifierApplicationId && applicationType && isWorkPlan) {
      setIsLoading(true);
      getAttendApplicationApprovalDetailWorkPlanApi(apiFetch, modifierApplicationId)
        .then((response) => {
          setDetail(response);
          setIsLoading(false);
        })
        .catch((error) => {
          errorNotification("申請はもう存在しません。");
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  const extractSingleApproveData = (data: any) => ({
    modifierApplicationId: data.modifierApplicationId || "",
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    achievementId: data.achievementId || "",
    orgName: data.orgName || "",
    belongOrgName: data.belongOrgName || "",
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: rejectReason || "",
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentOrgCode: data.orgName || "",
    targetDate: moment(data.targetDate).format("YYYY/MM/DD"),
  });

  const extractSingleRejectData = (data: any) => ({
    modifierApplicationId: data.modifierApplicationId || "",
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    achievementId: data.achievementId || "",
    orgName: data.orgName || "",
    belongOrgName: data.belongOrgName || "",
    buttonType: 1,
    applicationStatus: 2,
    rejectReason: rejectReason || "",
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentOrgCode: data.orgName || "",
    targetDate: moment(data.targetDate).format("YYYY/MM/DD"),
  });

  const extractSingleApproveDataWorkPlan = (data: any) => ({
    modifierApplicationId: data.modifierApplicationId || "",
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    achievementId: data.achievementId || "",
    orgName: data.orgName || "",
    belongOrgName: data.belongOrgName || "",
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: rejectReason || "",
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentOrgCode: data.orgName || "",
    targetDate: moment(data.detailList[0].startTime).format("YYYY/MM/DD"),
  });

  const extractSingleRejectDataWorkPlan = (data: any) => ({
    modifierApplicationId: data.modifierApplicationId || "",
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    approvalStaffCode: "",
    achievementId: data.achievementId || "",
    orgName: data.orgName || "",
    belongOrgName: data.orgName || "",
    applicationStatus: 2,
    rejectReason: rejectReason || "",
    buttonType: 1,
    targetDate: moment(data.detailList[0].startTime).format("YYYY/MM/DD"),
  });

  const extractSingleApproveDataStockPaid = (data: any) => ({
    modifierApplicationId: data.modifierApplicationId || "",
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    achievementId: data.achievementId || "",
    orgName: data.orgName || "",
    belongOrgName: data.belongOrgName || "",
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: rejectReason || "",
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentOrgCode: data.orgName || "",
    applyTargetDateType: data.applyTargetDateType,
    applicationDate: moment(data.applicationDate).format("YYYY/MM/DD"),
    targetStartDate: moment(data.targetStartDate).format("YYYY/MM/DD"),
    targetEndDate: moment(data.targetEndDate).format("YYYY/MM/DD"),
  });

  const extractSingleRejectDataStockPaid = (data: any) => ({
    modifierApplicationId: data.modifierApplicationId || "",
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    achievementId: data.achievementId || "",
    orgName: data.orgName || "",
    belongOrgName: data.belongOrgName || "",
    buttonType: 1,
    applicationStatus: 2,
    rejectReason: rejectReason || "",
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    currentOrgCode: data.orgName || "",
    applyTargetDateType: data.applyTargetDateType,
    applicationDate: moment(data.applicationDate).format("YYYY/MM/DD"),
    targetStartDate: moment(data.targetStartDate).format("YYYY/MM/DD"),
    targetEndDate: moment(data.targetEndDate).format("YYYY/MM/DD"),
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (buttonType === 3) {
        switch (applicationType) {
          case "attendStamp":
            await postApproveStamp(extractSingleApproveData(detail[0]));
            break;
          case "attendHoliday":
            await postApproveHoliday(extractSingleApproveData(detail[0]));
            break;
          case "attendOvertime":
            await postApproveOvertime(extractSingleApproveData(detail[0]));
            break;
          case "attendHolidaywork":
            await postApproveHolidayWork(extractSingleApproveData(detail[0]));
            break;
          case "attendAgreement36":
            await postApproveAgreement36(extractSingleApproveData(detail[0]));
            break;
          case "attendTransfer":
            await postApproveTransfer(extractSingleApproveData(detail[0]));
            break;
          case "attendWorkScheduleChange":
            await postApproveWorkSchedule(extractSingleApproveDataWorkPlan(detail[0]));
            break;
          case "attendStockPaidHoliday":
            await postApproveStockPaidHoliday(extractSingleApproveDataStockPaid(detail[0]));
            break;
          case "attendAllowance":
            await postApproveAllowance(extractSingleApproveData(detail[0]));
            break;
          default:
            break;
        }

        fetchData();
        setIsLoading(false);
        setConfirmApplyModalOpen(false);
        successNotification("承認しました。");
        setIsHiddenButton(true);
      } else {
        if (!rejectReason) {
          setIsLoading(false);
          setConfirmApplyModalOpen(false);
          errorNotification("差戻コメントを入力してください。");
          return;
        }

        switch (applicationType) {
          case "attendStamp":
            await postApproveStamp(extractSingleRejectData(detail[0]));
            break;
          case "attendHoliday":
            await postApproveHoliday(extractSingleRejectData(detail[0]));
            break;
          case "attendOvertime":
            await postApproveOvertime(extractSingleRejectData(detail[0]));
            break;
          case "attendHolidaywork":
            await postApproveHolidayWork(extractSingleRejectData(detail[0]));
            break;
          case "attendAgreement36":
            await postApproveAgreement36(extractSingleRejectData(detail[0]));
            break;
          case "attendTransfer":
            await postApproveTransfer(extractSingleRejectData(detail[0]));
            break;
          case "attendWorkScheduleChange":
            await postApproveWorkSchedule(extractSingleRejectDataWorkPlan(detail[0]));
            break;
          case "attendStockPaidHoliday":
            await postApproveStockPaidHoliday(extractSingleRejectDataStockPaid(detail[0]));
            break;
          case "attendAllowance":
            await postApproveAllowance(extractSingleRejectData(detail[0]));
            break;
          default:
            break;
        }

        fetchData();
        setIsLoading(false);
        setConfirmApplyModalOpen(false);
        successNotification("差戻しました。");
      }
    } catch (error) {
      setIsLoading(false);
      setIsHiddenButton(false);
      setConfirmApplyModalOpen(false);
      if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        if (error.response.data && Array.isArray(error.response.data)) {
          const listErr = error.response.data;
          listErr.map((element: any) => errorNotification(element.defaultMessage));
          return;
        }
        if (error.response.data && !Array.isArray(error.response.data)) {
          const err = error.response.data.defaultMessage;
          errorNotification(err);
          return;
        }
        errorNotification("サーバー側でエラーが発生しました。");
      }
    }
  };

  return {
    isLoading,
    detail,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeConfirmApplyModal,
    rejectReason,
    setRejectReason,
    buttonType,
    setButtonType,
    handleSubmit,
    isHiddenButton,
    setIsHiddenButton,
    setDetail,
    changeLogModalOpen,
    setChangeLogModalOpen,
    changeLogData,
    setChangeLogData,
    errorNotification,
    setIsLoading,
  };
};

export default useAttendApplicationApprovalDetail;
