import { useState, useCallback, useEffect } from "react";
import { SalarySupportStaff } from "domain/master/general/salaryDataStaff";

export const useOrganizationSupportEdit = (
  support: SalarySupportStaff,
  setSupport: (support: SalarySupportStaff) => void,
) => {
  const [addHourSalary, setAddHourSalary] = useState<number>(support.addHourlySalary);
  const [supportTransportation, setSupportTransportation] = useState<number>(support.supportTransportation);

  useEffect(() => {
    setAddHourSalary(support.addHourlySalary);
    setSupportTransportation(support.supportTransportation);
  }, [support]);

  const changeAddHourlySalary = useCallback(
    (value: number) => {
      setAddHourSalary(value);
      setSupport({
        ...support,
        addHourlySalary: value,
      });
    },
    [setSupport, support],
  );

  const changeSupportTransportation = useCallback(
    (value: number) => {
      setSupportTransportation(value);
      setSupport({
        ...support,
        supportTransportation: value,
      });
    },
    [setSupport, support],
  );

  return {
    addHourSalary,
    supportTransportation,
    changeAddHourlySalary,
    changeSupportTransportation,
  };
};

export default {};
