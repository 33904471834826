/** @jsx jsx */
import React, { useState, Dispatch } from "react";
import { Link } from "react-router-dom";

import OrganizationDomain from "domain/organization";

import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { SecondaryButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import Checkbox from "components/molecules/Checkbox";
import Modal from "components/molecules/Modal";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import { useOrganizationAddForm } from "./hooks";
import { useOrganizationDelete } from "../OrganizationListTable/hooks";
import { InfoButton } from "components/atoms/Button";
import { css, jsx } from "@emotion/core";
import FormPadding from "components/atoms/Form/FormPadding";
import { grayScale, textFontSize } from "components/styles";
import FormLabel from "components/atoms/Form/FormLabel";

import usePrefectureOptions from "hooks/usePrefectureOptions";
import useEmploymentOptions from "hooks/useEmploymentOptions";
import useWorkingDayOptions from "hooks/useWorkingDayOptions";
import SelectForm from "components/molecules/SelectForm";
import Calendar from "components/molecules/CalendarV1";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";

const OrganizationInputForm: React.FC<{
  initialOrganizationDomain?: OrganizationDomain;
  setInitialOrganizationDomain: Dispatch<React.SetStateAction<OrganizationDomain>>;
  historyList: Array<OrganizationDomain>;
  isEditMode?: boolean;
  setHistoryList: (arg: Array<OrganizationDomain>) => void;
  isDisabledInfo?: boolean;
  isEditable?: number;
  setBlocking?: any;
  isFetchHistory?: any;
}> = ({
  initialOrganizationDomain,
  historyList,
  isEditMode = false,
  setHistoryList,
  isDisabledInfo = false,
  isEditable = 0,
  setBlocking,
  isFetchHistory,
}) => {
  const { formik, confirmModalOpen, closeConfirmModal, parentOrgCodeOptions } = useOrganizationAddForm(
    initialOrganizationDomain,
    isEditMode,
    historyList,
    setHistoryList,
    setBlocking,
    isFetchHistory,
  );
  const prefectureOptions = usePrefectureOptions();
  const employmentOptions = useEmploymentOptions();
  const workingDayOptions = useWorkingDayOptions();

  const {
    deleteTargetOrganization,
    setDeleteTargetOrganization,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteOrganization,
  } = useOrganizationDelete();

  const columns: Array<Column<OrganizationDomain>> = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "x",
        disableSortBy: true,
        id: "actionButton",
        Cell: (cell: { row: { isExpanded: boolean; original: OrganizationDomain } }) => {
          return (
            <FlexBox>
              <FlexBoxItem margin="0 auto">
                <InfoButton
                  text="編集"
                  minWidth={"40px"}
                  customStyle={css({
                    borderRadius: "3px",
                    fontSize: "12px",
                    lineHeight: "10px",
                    padding: "0 5px",
                    height: "24px",
                  })}
                  onClick={() => {
                    formik.setFieldValue("orgName", cell.row.original.orgName);
                    formik.setFieldValue("category", cell.row.original.category);
                    formik.setFieldValue("applyStartDate", cell.row.original.applyStartDate);
                    formik.setFieldValue("closeFlag", cell.row.original.closeFlag);
                    formik.setFieldValue("prefectureCode", cell.row.original.prefectureCode);
                    formik.setFieldValue("areaId", cell.row.original.areaId);
                    formik.setFieldValue("businessCategoryId", cell.row.original.businessCategoryId);
                    formik.setFieldValue("employmentId", cell.row.original.employmentId);
                    formik.setFieldValue("workingDaysId", cell.row.original.workingDaysId);
                    formik.setFieldValue("posCode", cell.row.original.posCode);
                    formik.setFieldValue("numberOfSeat", cell.row.original.numberOfSeat);
                    formik.setFieldValue("floorSpace", cell.row.original.floorSpace);
                    formik.setFieldValue("parentOrgCode", cell.row.original.parentOrgCode);
                  }}
                />
              </FlexBoxItem>
            </FlexBox>
          );
        },
      },
      {
        Header: "適用日",
        accessor: "applyStartDateText",
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "状態",
        accessor: "closeFlagLabel",
      },
    ],
    [formik],
  );

  const textLabel = {
    color: grayScale.gray60,
    fontSize: textFontSize.re,
  };

  return (
    <React.Fragment>
      {historyList && historyList.length !== 0 && (
        <div
          css={css`
            table th:nth-of-type(1) {
              width: 65px;
            }
            table th {
              text-align: left;
            }
          `}
        >
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <DataTable
              className="table-no-border-left-right"
              columns={columns}
              data={historyList}
              isGlobalFilter={false}
              minWidth="632px"
            />
          </div>
        </div>
      )}

      <FormPadding customStyle={{ padding: "0 25% 45px" }}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <FormField>
              <div style={{ marginRight: "12px" }}>
                <VerticalLabelFormLayout
                  label={<FormLabel label="適用日" required={true} />}
                  input={
                    <Calendar
                      date={formik.values.applyStartDate}
                      setDate={(date) => {
                        if (date && JSON.stringify(date) !== JSON.stringify(formik.values.applyStartDate)) {
                          formik.setFieldValue("applyStartDate", date);
                        }
                      }}
                      readOnly={isDisabledInfo}
                    />
                  }
                />
              </div>
            </FormField>

            <FormField>
              <TextForm
                name="orgCode"
                label="組織コード"
                value={formik.values.orgCode}
                onChange={formik.handleChange}
                errorMsg={formik.errors.orgCode}
                required={true}
                disable={isDisabledInfo || isEditMode}
              />
            </FormField>

            <FormField>
              <TextForm
                name="orgName"
                label="組織名"
                value={formik.values.orgName}
                onChange={formik.handleChange}
                errorMsg={formik.errors.orgName}
                required={true}
                disable={isDisabledInfo}
              />
            </FormField>

            <FormField>
              <TextForm
                name="orgNameKana"
                label="組織名（ふりがな）"
                value={formik.values.orgNameKana}
                onChange={formik.handleChange}
                errorMsg={formik.errors.orgNameKana}
              />
            </FormField>

            {/* PARENT ORG */}
            <FormField displayBlock={true}>
              <div style={textLabel}>上位組織</div>
              <SelectForm
                label=""
                name="parentOrgCode"
                value={String(formik.values.parentOrgCode)}
                setValue={(parentOrgCode) => {
                  formik.setFieldValue("parentOrgCode", parentOrgCode);
                }}
                disable={isDisabledInfo}
                options={parentOrgCodeOptions}
                widthLabel="0"
              />
            </FormField>

            <FormField displayBlock={true}>
              <div style={textLabel}>所在地</div>
              <SelectForm
                label=""
                name="prefectureCode"
                value={String(formik.values.prefectureCode)}
                setValue={(prefectureCode) => {
                  formik.setFieldValue("prefectureCode", prefectureCode);
                }}
                options={prefectureOptions}
                widthLabel="0"
              />
            </FormField>

            <FormField displayBlock={true}>
              <div style={textLabel}>所定労働日数</div>
              <SelectForm
                label=""
                name="workingDaysId"
                value={String(formik.values.workingDaysId)}
                setValue={(workingDaysId) => {
                  formik.setFieldValue("workingDaysId", workingDaysId);
                }}
                options={workingDayOptions}
                widthLabel="0"
              />
            </FormField>

            <FormField displayBlock={true}>
              <div style={textLabel}>雇用形態</div>
              <SelectForm
                label=""
                name="employmentId"
                value={String(formik.values.employmentId)}
                setValue={(employmentId) => {
                  formik.setFieldValue("employmentId", employmentId);
                }}
                options={employmentOptions}
                widthLabel="0"
              />
            </FormField>

            <FormField displayBlock={true}>
              <FormLabel label="状態" />

              <FlexBox>
                <Checkbox
                  id="closeFlag"
                  name="closeFlag"
                  value=""
                  label="廃止"
                  checked={formik.values.closeFlag}
                  onChange={formik.handleChange}
                />
              </FlexBox>
            </FormField>
          </div>

          <FormSubmitArea customStyle={css({ textAlign: "center", marginTop: "20px" })}>
            {isEditable === 1 && (
              <div>
                <Button minWidth="160px" text={isEditMode ? "更新" : "登録"} onClick={formik.handleSubmit} />

                {isEditMode && !isDisabledInfo && (
                  <SecondaryButton
                    text={"削除"}
                    onClick={() => setDeleteTargetOrganization(formik.values)}
                    minWidth="160px"
                    customStyle={css({ marginLeft: "10px" })}
                  />
                )}
              </div>
            )}
            <Link to="/organizations">
              <Button minWidth="160px" text="キャンセル" ghost={true} />
            </Link>
          </FormSubmitArea>
        </form>
      </FormPadding>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        title={`${isEditMode ? "組織マスタの更新" : "組織マスタの登録"}`}
        content={`${
          isEditMode ? "組織マスタを更新します。よろしいですか？" : "組織マスタを登録します。よろしいですか？"
        }`}
        submitText={isEditMode ? "更新" : "登録する"}
      />
      <Modal
        typeSubmit="reject"
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        submitText="削除"
        title="組織を削除"
        onSubmit={onSubmitDeleteOrganization}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: "normal",
            fontSize: "14px",
            color: "#666666",
            marginBottom: "15px",
          }}
        >
          {deleteTargetOrganization?.orgName}
          組織を削除します。よろしいですか？
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OrganizationInputForm;
