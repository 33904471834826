/** @jsx jsx */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";

import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import SelectForm from "components/molecules/SelectForm";
import { css, jsx } from "@emotion/core";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { useApplicationRoute } from "./hooks";
import Table from "components/molecules/Table";
import IconLabelButton from "components/molecules/IconLabelButton";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import PrimaryButton, { SubActionButton, SecondaryButton } from "components/atoms/Button";
import FormField from "components/atoms/Form/FormField";
import { Link, useParams } from "react-router-dom";
import ApplicationRouteRow from "./ApplicationRouteRow";
import ConfirmModal from "components/organismos/ConfirmModal";
import BlockUI from "components/molecules/BlockUi";
import getApprovalFlowList, { applyingAprrovelFlowList } from "api/approvalFlowList";
import { OptionType } from "components/atoms/Select";
import SelectFormV2 from "components/molecules/SelectFormV2";

const styles = {
  flexBox: css({
    marginLeft: "50px",
  }),
  text: css({
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  }),
  width: css({
    width: "100%",
  }),
  fixColumn: css({
    width: "120px",
  }),
  wrapTable: css(
    {},
    css`
      table tr td:nth-of-type(1) {
        text-align: center;
      }
      table thead tr th:nth-of-type(1) {
        width: 150px;
      }
      table thead tr th:nth-of-type(2) {
        width: 600px;
      }
    `,
  ),
  wrapFilter: css({
    maxWidth: "900px",
    marginLeft: "30px",
  }),
};

export const ACTION = {
  REGISTER: 0,
  UPDATE: 1,
  DELETE: 2,
  NORMAL: 3,
};

const listApprove = [
  {
    label: "打刻修正申請 ",
    value: "1",
  },
  {
    label: "休暇申請",
    value: "2",
  },
  {
    label: "残業申請",
    value: "3",
  },
  {
    label: "手当申請",
    value: "10",
  },
  {
    label: "休日出勤申請",
    value: "4",
  },
  {
    label: "振替申請",
    value: "5",
  },
  {
    label: "限度時間超過申請",
    value: "7",
  },
  {
    label: "勤務予定変更申請",
    value: "8",
  },
  {
    label: "積立有給申請",
    value: "9",
  },
];

const ApplicationRouteTemplate: React.FC<{ isEditable: number }> = ({ isEditable }) => {
  const { orgCode } = useParams();
  const {
    formik,
    orgTreeOptions,
    executiveOptions,
    confirmModalOpen,
    closeConfirmModal,
    setTargetApplication,
    setTargetApplicationRemain,
    targetApplication,
    targetApplicationRemain,
    isLoading,
    setIsCopy,
  } = useApplicationRoute(orgCode || "");

  const [targetApplicationItems, setTargetApplicationItems] = useState<Array<OptionType>>([]);
  const [targetApplicationRemainOptions, setTargetApplicationRemainOptions] = useState<Array<OptionType>>([]);

  useEffect(() => {
    applyingAprrovelFlowList(orgCode).then((aprrovelFlowList) => {
      const optionApproveFlowList: Array<OptionType> = [];
      aprrovelFlowList.map((aprrovelFlowItem: any) => {
        optionApproveFlowList.push({
          value: String(aprrovelFlowItem.applicationType),
          label: String(aprrovelFlowItem.applicationTypeName),
        });
        return true;
      });
      setTargetApplicationItems(optionApproveFlowList);
      let remainOptions = optionApproveFlowList.filter((e) => Number(e.value) !== targetApplication);
      setTargetApplicationRemainOptions(remainOptions);
      setTargetApplicationRemain(Number(remainOptions[0]?.value));
    });
  }, [orgCode, targetApplication]);

  const approvalFlowListData = formik.values.approvalFlowListData;
  const [targetApplicationLength, setTargetApplicationLength] = useState(approvalFlowListData.length || 0);

  // Set level
  const [levelApprove, setLevelApprove] = useState(1);
  const [btnDisable, setBtnDisable] = useState(true);

  useEffect(() => {
    const filter = approvalFlowListData.filter((val) => val.status !== ACTION.DELETE);
    setLevelApprove(Number(filter[filter.length - 1]?.approvalLevel) + 1 || 1);
  }, [setLevelApprove, approvalFlowListData]);

  const addNewApprover = () => {
    approvalFlowListData.push({
      privilegeId: "",
      orgCode: "",
      executiveId: "",
      executiveName: "",
      approvalLevel: levelApprove,
      status: 0,
      isHiddenLabel: false,
    });

    const filter = approvalFlowListData.filter((val) => val.status !== ACTION.DELETE);
    setLevelApprove(Number(filter[filter.length - 1]?.approvalLevel) + 1 || 1);

    // Update length
    const countApprovalActive: Array<String> = [];
    approvalFlowListData.filter((val: any) => {
      if (countApprovalActive && !countApprovalActive.includes(val.approvalLevel) && val.status !== ACTION.DELETE) {
        countApprovalActive.push(val.approvalLevel);
      }
      return true;
    });
    setTargetApplicationLength(countApprovalActive.length);
  };

  const copyApprove = () => {
    /* eslint-disable array-callback-return */
    approvalFlowListData.map((value: any, indexData: number) => {
      value.status = ACTION.DELETE;
    });
    setIsCopy(true);
    return false;
  };

  const clearData = (belongOrgCode: any, targetApplication: any) => {
    getApprovalFlowList(belongOrgCode, targetApplication).then((response: any) => {
      if (response) {
        const listShowArray: Array<number> = [];
        for (let i = 0; i <= response.length; i += 1) {
          if (!listShowArray.includes(response[i]?.approvalLevel) && response[i]) {
            listShowArray.push(response[i]?.approvalLevel);
            response[i].isHiddenLabel = false;
            response[i].exists = true;
          } else if (listShowArray.includes(response[i]?.approvalLevel)) {
            response[i].isHiddenLabel = true;
            response[i].exists = true;
          }
        }
      }
      formik.setFieldValue("approvalFlowListData", response);
    });
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContentFilter customStyles={styles.wrapFilter}>
        <FlexBox>
          <FlexBoxItem width="40%">
            <SelectFormV2
              label="対象申請"
              name="targetApplication"
              value={String(targetApplication)}
              setValue={(val: string) => {
                setTargetApplication(Number(val));
                if (Number(val) === targetApplicationRemain || !targetApplicationRemain) {
                  setTargetApplicationRemain(
                    Number(listApprove.filter((valueFilter) => valueFilter.value !== String(val))[0].value),
                  );
                }
                setBtnDisable(false);
              }}
              options={listApprove}
              widthLabel="80px"
            />
          </FlexBoxItem>
          <FlexBoxItem width="60%" customStyle={styles.flexBox}>
            <FlexBox>
              <FlexBoxItem width="400px">
                <SelectFormV2
                  label="承認ルートコピー"
                  name="approvalRouteCopy"
                  value={String(targetApplicationRemain)}
                  setValue={(val: string) => {
                    setBtnDisable(false);
                    setTargetApplicationRemain(Number(val));
                  }}
                  options={targetApplicationRemainOptions}
                  widthLabel="140px"
                />
              </FlexBoxItem>
              <FlexBoxItem marginLeft="15px">
                <SubActionButton action={0} disabled={btnDisable} text="適用" onClick={copyApprove} />
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
        </FlexBox>
      </FormContentFilter>

      {/* Table */}
      <div css={styles.wrapTable}>
        <Table customStyle={styles.width} className="table-bordered">
          <thead>
            <tr>
              <Table.HeaderCell> 承認段階 </Table.HeaderCell>
              <Table.HeaderCell> 組織 </Table.HeaderCell>
              <Table.HeaderCell> 役職 </Table.HeaderCell>
              <Table.HeaderCell customStyle={styles.fixColumn}> 編集 </Table.HeaderCell>
            </tr>
          </thead>
          <tbody>
            {formik.values.approvalFlowListData.length > 0 &&
              formik.values.approvalFlowListData.map((value, index) =>
                value.status !== ACTION.DELETE ? (
                  <ApplicationRouteRow
                    key={index}
                    fields={value}
                    index={index}
                    orgTreeOptions={orgTreeOptions}
                    executiveOptions={executiveOptions}
                    form={formik}
                    setTargetApplicationLength={setTargetApplicationLength}
                  />
                ) : (
                  ""
                ),
              )}
          </tbody>
        </Table>
      </div>

      {/* Add new item */}
      <div style={{ padding: "15px", width: "100px" }}>
        <IconLabelButton
          onClick={addNewApprover}
          iconType="addCircle"
          text="行追加"
          disabled={targetApplicationLength >= 10 ? true : false}
        />
      </div>

      {/* Submit */}
      <FlexBox justifyContent="center">
        <FormSubmitArea customStyle={css({ textAlign: "center", margin: "30px 0" })}>
          {isEditable === 1 && (
            <React.Fragment>
              <PrimaryButton text="登録" onClick={formik.handleSubmit} />
              <SecondaryButton
                text="クリア"
                customStyle={css({ marginLeft: "15px" })}
                minWidth="120px"
                onClick={() => {
                  return clearData(orgCode, targetApplication);
                }}
              />
            </React.Fragment>
          )}

          <FormField displayBlock={true}>
            <Link to="/organizations">
              <PrimaryButton text="キャンセル" ghost={true} />
            </Link>
          </FormField>
        </FormSubmitArea>
      </FlexBox>

      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        title="確認メッセージ"
        content="登録します。よろしいですか？"
        submitText="登録する"
      />
    </BlockUI>
  );
};

export default ApplicationRouteTemplate;
