/** @jsx jsx */
import React, { useEffect, useState, useCallback } from "react";
import { jsx, css } from "@emotion/core";
import axios from "axios";
import BlockUI from "components/molecules/BlockUi";
import { useHistory } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import LoginUserDomain from "domain/loginUser";
import { getToken, getLoginUserPeople } from "api/singleLogin";
import { getOAuthToken } from "hooks/usePKCEOAuth";
import Modal from "components/molecules/Modal";
import PanelDescription from "components/atoms/PanelDescription";
import { saveLoginHistory } from "api/login";

const domainUrl = process.env.REACT_APP_API_BASE_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;

const style = {
  wrapper: css({
    width: "100em",
    height: "100vh",
    margin: "0px auto",
    overflow: "hidden",
  }),
  center: css({
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "0em",
    marginLeft: "-50em",
    width: "100em",
    height: "10em",
  }),
  textCenter: css({
    textAlign: "center",
    color: "#007BC3",
    fontSize: "14px",
  }),
};

const CallbackLogin: React.FC = () => {
  const [blocking, setBlocking] = useState(true);
  const { errorNotification } = useToastNotification();
  const [loginUser, setLoginUser] = useState<LoginUserDomain | any>(null);
  const history = useHistory();
  let uri = window.location.toString();
  let url = new URL(uri);
  let code = url.searchParams.get("code") || "";
  let error = url.searchParams.get("error") || "";

  const fetchData = useCallback(async () => {
    try {
      let redirectURI = window.location.origin + "/callback";
      getOAuthToken(code, redirectURI)
        .then((response: any) => {
          if (response.status !== 200) {
            setBlocking(false);
            errorNotification("PEOPLEサイトからのログインに失敗しました。");
            saveLoginHistory("", false, "", "PEOPLEサイトからのログインに失敗しました。", "");
            setTimeout(function() {
              history.replace("/logout");
            }, 2000);
          }
          const peopleAccessToken = response.data["access_token"];
          localStorage.setItem("PEOPLE_ACCESS_TOKEN", peopleAccessToken);
          localStorage.setItem("PEOPLE_REFRESH_TOKEN", response.data["refresh_token"]);
          localStorage.setItem("PEOPLE_TENANT", response.data["tenant"]);
          localStorage.setItem("PEOPLE_USER_ID", response.data["user_id"]);
          let path = sessionStorage.getItem("path") || "";
          if (response.data["email"] === null || response.data["email"] === "null") {
            setBlocking(false);
            errorNotification("Email null");
            saveLoginHistory("", false, "", "無効なメール", "");
            setTimeout(function() {
              history.replace("/logout");
            }, 2000);
          }
          const mail = response.data["email"] || "";
          getLoginUserPeople(mail)
            .then((res: any) => {
              if (res.status !== 200) {
                setBlocking(false);
                errorNotification("PEOPLEサイトからUSERを取得できません");
                saveLoginHistory(mail, false, "", "PEOPLEサイトからUSERを取得できません", "");
                setTimeout(function() {
                  history.replace("/logout");
                }, 2000);
              }
              const hinataAccessToken = res.data["accessToken"];
              const hinataRefreshToken = res.data["refreshToken"];
              const hinataIdToken = res.data["idToken"];
              localStorage.setItem("ACCESS_TOKEN", hinataAccessToken);
              localStorage.setItem("REFRESH_TOKEN", hinataRefreshToken);
              localStorage.setItem("ID_TOKEN", hinataIdToken);
              saveLoginHistory(mail, true, "", "", "");
              history.push(path);
              return;
            })
            .catch((error) => {
              if (error.status !== 200) {
                let err = error.response.data;
                if (err.errors && err.errors.length > 0) {
                  const listErr = err.errors;
                  let stringErr = "";
                  listErr.map((element: any) => {
                    stringErr += `${element.defaultMessage} \n`;
                    return stringErr;
                  });
                  errorNotification(stringErr);
                  saveLoginHistory(mail, false, "", stringErr, "");
                } else if (err.defaultMessage) {
                  errorNotification(err.defaultMessage);
                  saveLoginHistory(mail, false, "", err.defaultMessage, "");
                } else {
                  errorNotification(err.message);
                  saveLoginHistory(mail, false, "", err.message, "");
                }
              } else {
                errorNotification("サーバー側でエラーが発生しました。");
                saveLoginHistory(mail, false, "", "サーバー側でエラーが発生しました。", "");
              }
              setBlocking(false);
              setTimeout(function() {
                history.replace("/logout");
              }, 2000);
            });
        })
        .catch((error) => {
          setBlocking(false);
          errorNotification("PEOPLEサイトからのログインに失敗しました。");
          saveLoginHistory("", false, "", "PEOPLEサイトからのログインに失敗しました。", "");
          setTimeout(function() {
            history.replace("/logout");
          }, 2000);
        });
    } catch (error) {
      setBlocking(false);
      errorNotification("PEOPLEサイトからのログインに失敗しました。");
      saveLoginHistory("", false, "", "PEOPLEサイトからのログインに失敗しました。", "");
      setTimeout(function() {
        history.replace("/logout");
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (error === "") {
      fetchData();
    } else {
      errorNotification(error);
      saveLoginHistory("", false, "", error, "");
      setTimeout(function() {
        history.push("/");
      }, 2000);
    }
  }, []);

  return (
    <BlockUI blocking={blocking}>
      <div css={style.wrapper}>
        <div css={style.center}>
          <h3 css={style.textCenter}></h3>
        </div>
      </div>
    </BlockUI>
  );
};

export default CallbackLogin;
