/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { ButtonProps } from "./BaseButton";
import { PrimaryButton } from "components/atoms/Button";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { utilityColor } from "components/styles";
import Icon, { IconType } from "components/atoms/Icon";
import BodyText from "components/atoms/BodyText";
import { notMinScreen } from "utility/mediaQueries";
const isIpad = window.matchMedia("(max-width: 1024px)").matches;

/**
 * プライマリボタン
 *
 * @see https://sketch.cloud/s/2b3Pe/a/k9qkrA
 */

interface RecorderButtonProps extends ButtonProps {
  icon: string;
  background: string;
  customStyle?: SerializedStyles;
  textMin?: string;
}

const RecorderButton: React.FC<RecorderButtonProps> = ({
  text,
  textMin,
  onClick,
  icon,
  background,
  disabled,
  customStyle = css({}),
}) => {
  const styles = {
    button: css({
      height: `${isIpad ? "80px !important" : "120px !important"}`,
      maxWidth: `${isIpad ? "230px" : "320px"}`,
      borderRadius: "3px",
      padding: "0",
      marginBottom: "20px",
      backgroundColor: background,
      ":hover": {
        backgroundColor: background,
      },
      whiteSpace: "pre-wrap",
      [notMinScreen("md")]: {
        height: "auto !important",
        marginBottom: "0px",
      },
      "& svg": {
        backgroundColor: "transparent",
        opacity: 0.7,
      },
    }),
    multiline: css({
      [notMinScreen("md")]: {
        lineHeight: "24px",
      },
    }),
    textStyle: css({
      fontSize: "24px",
      fontWeight: 700,
      color: "white",
      marginLeft: `${textMin ? "-10px" : "30px"}`,
      [notMinScreen("lg")]: {
        marginLeft: "20px",
        fontSize: "18px",
      },
      [notMinScreen("md")]: {
        marginLeft: "8px",
      },
    }),
    iconContainer: css({
      width: isIpad ? "40px" : "70px",
      alignSelf: "flex-end",
      margin: "10px",
      [notMinScreen("md")]: {
        alignSelf: "center",
      },
    }),
    content: css({
      justifyContent: "space-between",
      height: "100%",
      borderRadius: "3px",
      backgroundColor: background,
      [notMinScreen("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        height: "auto",
      },
    }),
    textMin: css({
      fontSize: "20px",
      color: "white",
      fontWeight: 600,
      lineHeight: 0,
      marginLeft: "40px",
      [notMinScreen("md")]: {
        marginLeft: "0px",
      },
    }),
  };

  return (
    <PrimaryButton
      text=""
      onClick={onClick}
      fullwidth={true}
      disabled={disabled}
      customStyle={css([styles.button, customStyle])}
    >
      <FlexBox customStyle={css(styles.content)}>
        <FlexBoxItem customStyle={css(styles.multiline)}>
          <BodyText customStyle={styles.textStyle}>{text}</BodyText>
          {textMin && <p css={styles.textMin}>{textMin}</p>}
        </FlexBoxItem>
        <FlexBoxItem customStyle={styles.iconContainer}>
          <Icon size={isIpad ? "35px" : "70px"} type={icon as IconType} color={utilityColor.white} />
        </FlexBoxItem>
      </FlexBox>
    </PrimaryButton>
  );
};

export default RecorderButton;
