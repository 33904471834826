/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import LoginFormContainer from 'components/organismos/login/LoginFormContainer';
import LoginInputForm from 'components/organismos/login/LoginForm';

const LoginPage: React.FC = () => (
  <React.Fragment>
    <LoginFormContainer titleForm='ログイン'>
      <LoginInputForm />
    </LoginFormContainer>
  </React.Fragment>
);

export default LoginPage;
