import ApiClient from './ApiClient';

export type Role = {
  roleId: string,
  companyCode: string,
  roleName: string,
  site: number,
  administrator: boolean,
  allOrganizationAccess: boolean,
  allStaffAccess: boolean,
  retired: boolean,
  paidControl: boolean,
  accountingRole: boolean,
  attendanceRole: boolean,
  createDate: Date,
  updateDate: Date,
}

type Id =  {
  companyCode: string,
  roleId: string,
}
export interface RoleFunctionData {
  [key: string]: any;
  functionMasterId: string;
  functionCode: string;
  functionName: string;
  roleDetailId?: any;
  menuGroup: string;
  available?: any;
  useEditable: number;
  editable?: any;
  useAccessRange: number;
  accessRange?: any;
  accessUnderOrganization?: any;
  useImport: number;
  isDefault: boolean;
  importFlag?: any;
  useDownload: number;
  downloadFlag?: any;
  usabilityGeneralItem1Name: string;
  usabilityGeneralItem1?: any;
  usabilityGeneralItem2Name: string;
  usabilityGeneralItem2?: any;
  usabilityGeneralItem3Name: string;
  usabilityGeneralItem3?: any;
  usabilityGeneralItem4Name: string;
  usabilityGeneralItem4?: any;
  usabilityGeneralItem5Name: string;
  usabilityGeneralItem5?: any;
  usabilityGeneralItem6Name: string;
  usabilityGeneralItem6?: any;
  usabilityGeneralItem7Name: string;
  usabilityGeneralItem7?: any;
  usabilityGeneralItem8Name: string;
  usabilityGeneralItem8?: any;
  usabilityGeneralItem9Name: string;
  usabilityGeneralItem9?: any;
  usabilityGeneralItem10Name: string;
  usabilityGeneralItem10?: any;
}

export interface DetailRoleFunction {
  id: Id;
  roleFunctinList: Array<RoleFunctionData>;
  roleCode: string;
  roleName: string;
  site: number;
  allOrganizationAccess: boolean;
  budgetManagement: boolean;
  paidControl: boolean;
  administrator: boolean;
  allStaffAccess: boolean;
  retired: boolean;
  accountingRole: boolean;
  attendanceRole: boolean;
  allApplicationAccess: boolean;
  accessLevel: number;
  createUser?: any;
  createDate: Date;
  updateUser?: any;
  updateDate: Date;
  roleDetailList?: any;
}

export type AllRole = {
  hqRoleList : Array<Role>,
  shopRoleList: Array<Role>,
  personalRoleList: Array<Role>,
}

export const getAllRoles = async (): Promise<AllRole> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/roles/all/${companyCode}`, {});
  return response.data;
};

export const getAllRolesV1 = async (): Promise<DetailRoleFunction[]> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    loginStaffCode
  }
  const response = await ApiClient.get(`/v2/roles/available/${companyCode}`, params);
  return response.data;
};


export default getAllRoles;
