export type MasterAddRole = {
  id: {	
    companyCode: string,	
    roleId: string|null
  },	
  roleCode: string,	
  roleName: string,	
  accessLevel: number,	
  administrator: boolean,	
}


export class MasterAddRoleDomain {
  constructor(private rawData: MasterAddRole) {
    // do nothing
  }

  static generateInitial(): MasterAddRoleDomain {
    return new MasterAddRoleDomain({
      id: {	
        companyCode: '',	
        roleId: null
      },	
      roleCode: '',	
      roleName: '',	
      accessLevel: 0,	
      administrator: false,
    });
  }

  getRawData(): MasterAddRole {
    return this.rawData;
  }

  get id(): any {
    return this.rawData.id;
  }

  set id(id: any) {
    this.rawData.id = id;
  }

  get roleCode(): string {
    return this.rawData.roleCode;
  }
  set roleCode(roleCode: string) {
    this.rawData.roleCode = roleCode;
  }

  get roleName(): string {
    return this.rawData.roleName;
  }
  set roleName(roleName: string) {
    this.rawData.roleName = roleName;
  }

  get accessLevel(): number {
    return this.rawData.accessLevel;
  }
  set accessLevel(accessLevel: number) {
    this.rawData.accessLevel = accessLevel;
  }
  get administrator(): boolean {
    return this.rawData.administrator;
  }

  set administrator(administrator: boolean) {
    this.rawData.administrator = administrator;
  }
 
}
