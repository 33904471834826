import {
  Column, useTable, useSortBy, usePagination, useFilters, useGlobalFilter,
} from 'react-table';

import { PaginationProps } from './Pagination';
import { GlobalFilterProps } from './GlobalFilter';

interface SortBy {
  id: string;
  desc: boolean;
}

export const useDataTable = (
  columns: Array<any>,
  data: Array<{ [key: string]: string }>,
  isGlobalFilter: any,
  initPageSize: number,
  initPageIndex: number,
  sortBy?: Array<SortBy>,
  globalFilterTxt?: string
) => {
  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    state,
    state: { pageIndex, pageSize },
    setPageSize,
    gotoPage,
    setGlobalFilter,
    setHiddenColumns
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.filter(col => col.show === false).map(col => col.accessor),
        pageSize: initPageSize,
        pageIndex: initPageIndex,
        sortBy: sortBy || [],
        globalFilter: globalFilterTxt,
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
  );

  const arrOverflow = columns.map((item) => {
    return Boolean(item.overFlow);
  });

  const filter: GlobalFilterProps = {
    state,
    setGlobalFilter,
  };

  const pagination: PaginationProps = {
    total: data.length,
    pageCount,
    pageSize,
    pageIndex,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    setPageSize,
    gotoPage: (pageNum: number) => gotoPage(pageNum - 1),
  };

  return {
    headerGroups,
    page,
    prepareRow,
    pagination,
    filter,
    arrOverflow
  };
};

export default {
  useDataTable,
};
