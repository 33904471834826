import ApiClient from './ApiClient';
import { stringify } from 'querystring';

export type attendAchievementOutput = {
    siteType: any;
    staffCode: any;
    orgCode: any;
    targetDate: any;
}

export const getOrganizationsTrees = async (
  siteType: any,
  staffCode: any,
  orgCode:any,
): Promise<Array<attendAchievementOutput>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    //siteType,
    staffCode,
    orgCode,
  };

  const response = await ApiClient.get(`/v2/organizations/trees/${companyCode}`, params);
  return response.data;
};

export const exportFilePDF = async (params: any): Promise<Array<attendAchievementOutput>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';

  params = {
    ...params,
    createUser: `${staffCode} ${staffName}` || ''
  };

  const response = await ApiClient.get(`/v1/attendAchievement/output/${companyCode}`, params, {});
  return response.data;
};

export const exportFilePDFv2 = async (
  orgCode: any,
  targetDateFrom: any,
  targetDateTo: any,
  staffCode: any,
): Promise<Array<attendAchievementOutput>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    staffCode,
    loginStaffCode,
  };

  const response = await ApiClient.getDownloadFile(`/v2/attendAchievement/output/${companyCode}`, params);
  return response.data;
};

export const exportFileCSVDay = async (
  orgCode: any,
  targetDate: any,
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate,
  };
  const queryStringParams = stringify(params);

  await ApiClient.downloadOriginalCsv(`/v1/attendAchievement/outputCsvDay/${companyCode}`, queryStringParams, '勤怠実績日数出力.csv');
};

export const exportFileCSVTime = async (
  orgCode: any,
  targetDate: any,
) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    orgCode,
    targetDate,
  };
  const queryStringParams = stringify(params);

  await ApiClient.downloadOriginalCsv(`/v1/attendAchievement/outputCsvTime/${companyCode}`, queryStringParams, '勤怠実績時間出力.csv');
};