
export const types = {
  SET_IS_CONNECT_IC_CARD_USB: 'ui/appBar/SET_IS_CONNECT_IC_CARD_USB' as const,
  SET_DISABLE_DETECT: 'ui/appBar/SET_DISABLE_DETECT' as const,
  SET_DEVICE_IC_CARD: 'ui/appBar/SET_DEVICE_IC_CARD' as const,
  SET_CARD_ID: 'ui/appBar/SET_CARD_ID' as const,
  SET_HARD_CARD_ID: 'ui/appBar/SET_HARD_CARD_ID' as const,
};

export const setIsConnectICCardUsb = (payload: boolean) => ({
  type: types.SET_IS_CONNECT_IC_CARD_USB,
  payload,
});

export const setDisabledDetect = (payload: boolean) => ({
  type: types.SET_DISABLE_DETECT,
  payload,
});

export const setDeviceICCard = (payload: USBDevice|null) => ({
  type: types.SET_DEVICE_IC_CARD,
  payload,
});

export const setCardId = (payload: string) => ({
  type: types.SET_CARD_ID,
  payload,
});

export const setHardCardId = (payload: string) => ({
  type: types.SET_HARD_CARD_ID,
  payload,
});

type Actions = (
  | ReturnType<typeof setIsConnectICCardUsb>
  | ReturnType<typeof setDisabledDetect>
  | ReturnType<typeof setDeviceICCard>
  | ReturnType<typeof setCardId>
  | ReturnType<typeof setHardCardId>
);

export default Actions;
