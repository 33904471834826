import moment, { Moment } from "moment";

export const listHours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const hourFormOptions = listHours.map((hour) => ({
  value: hour,
  label: hour,
}));

export const listTimes = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const timeFormOptions = listTimes.map((time) => ({
  value: time,
  label: time,
}));

export const convertDateStringToMoment = (dateStr: string) =>
  moment(dateStr, ["HH:mm", "HH:mm:ss", "YYYY/MM/DD HH:mm:ss"]);

export const convertMomentToYYYYMMDD = (momentObj: Moment) => momentObj.format("YYYY/MM/DD HH:mm:ss");

export const substractYearWithDate = (date: Date, yearNumber: number = 1) => {
  return (
    moment(date, "YYYY-MM-DD HH:mm:ss")
      .subtract(yearNumber, "years")
      .add(yearNumber, "days")
      .format("YYYY年MM月DD日") +
    "〜" +
    moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY年MM月DD日")
  );
};

export const calcTime = (usabilityGeneralItem1: number | null, holidayTimes: number) => {
  if (usabilityGeneralItem1 != null && usabilityGeneralItem1 === 1 && holidayTimes) {
    const minutes = holidayTimes / 60;
    return Number.isInteger(minutes) ? `${minutes}時間` : `${parseFloat(`${minutes}`).toFixed(1)}時間`;
  } else {
    return Number.isInteger(holidayTimes) ? `${holidayTimes}時間` : `${parseFloat(`${holidayTimes}`).toFixed(1)}時間`;
  }
};
export default {};
