/** @jsx jsx */
import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { productColor, textFontSize, utilityColor, grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import useToastNotification from "hooks/useToastNotification";
import FileSelectBox from "components/molecules/FileSelectBox";
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import TextForm from "components/molecules/TextForm";
import Cropper from 'react-easy-crop'
import PrimaryButton from 'components/atoms/Button';
import getCroppedImg from 'hooks/useCropImage';

const styles = {
  cropContainer: css({
    height: "250px",
    position: "relative",
    margin: "auto",
  }, css`
    .reactEasyCrop_CropArea::before, .reactEasyCrop_CropArea::after{
      border: 0;
    }  
  `),
  slider: css({
    background: "#007BC3",
    WebkitAppearance: "none",
    height: "2px",
    outline: "none !important",
    MozAppearance: "none",
    appearance: "none",
    border: "none",
    borderRadius: "30px",
    width: "400px",
    maxWidth: "100%",
    cursor: "pointer",
    margin: "40px 0 30px"
  }),
  imageCropped: css({
    cursor: "pointer",
    height: "100%",
    margin: "auto",
  }),
};

const CONTAINER_SIZE = 230;
const CONTAINER_OUT_SIZE = 250;

const CropperCustom: React.FC<{
  tempModalFile: string|undefined,
  onCroppedImage: any
}> = ({
  tempModalFile,
  onCroppedImage
}) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [minZoom, setMinZoom] = useState(1)

  const showCroppedImage = useCallback(async (): Promise<any> => {
    // setBeforeCroppedImage(tempModalFile);
    try {
      const croppedImageData = await getCroppedImg(
        tempModalFile,
        croppedAreaPixels,
        rotation
      )
      onCroppedImage(croppedImageData);
      // setCroppedImage(croppedImageData);
      return croppedImageData;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  return(
    <React.Fragment>
      <FlexBox customStyle={styles.cropContainer}>
        <Cropper
          image={tempModalFile}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1 / 1}
          minZoom={minZoom}
          maxZoom={3}
          cropSize={{
            width: 230,
            height: 230,
          }}
          style={{
            cropAreaStyle:{
              color: 'rgba(0, 0, 0, 0.3)',
            } as React.CSSProperties,
            mediaStyle: {
              maxWidth: '100%',
              maxHeight: '100%'
            },
            containerStyle:{
              width: "400px",
              margin: "auto"
            }
          }}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onMediaLoaded={(mediaSize) => {
            setCrop({ x: 0, y: 0 })
            // Adapt zoom based on media size to fit max height
            if(mediaSize.naturalHeight > CONTAINER_SIZE){
              setZoom(CONTAINER_SIZE/CONTAINER_OUT_SIZE);
              setMinZoom(CONTAINER_SIZE/CONTAINER_OUT_SIZE);
            }else{
              if(mediaSize.naturalHeight < mediaSize.naturalWidth){
                setZoom(CONTAINER_SIZE/mediaSize.naturalHeight);
                setMinZoom(CONTAINER_SIZE/mediaSize.naturalHeight);
              }else{
                setZoom(CONTAINER_SIZE/mediaSize.naturalWidth);
                setMinZoom(CONTAINER_SIZE/mediaSize.naturalWidth);
              }
            }
          }}
        />
        </FlexBox>
      <FlexBox justifyContent="center">
        <input 
          type="range" 
          css={styles.slider}
          aria-labelledby="Zoom"
          value={zoom}
          min={minZoom}
          max={3}
          step={0.01}
          onChange={(zoom) => setZoom(Number(zoom.target.value))}
        />
      </FlexBox>
      <FlexBox justifyContent="center">
        <PrimaryButton
          text={'登録'}
          onClick={() => showCroppedImage()}
          minWidth="200px"
          customStyle={css({fontWeight: 'bold'})}
        />
      </FlexBox>
    </React.Fragment>
    
  )
};

export default CropperCustom;
