import ApiClient from './ApiClient';

export const orgSelect = async (dayFrom?: string, dayTo?: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const orgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // const siteType = 'HQ';
  // const response = await ApiClient.get(`/v2/organizations/trees/${companyCode}?siteType=${siteType}&staffCode=${staffCode}&orgCode=${orgCode}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}&category=2`, {});
  const response = await ApiClient.get(`/v2/organizations/trees/${companyCode}?staffCode=${staffCode}&orgCode=${orgCode}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}&category=2`, {});
  return response.data;
};

export const getSalesBudgetYearly = async (orgCode?: string, targetYear?: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/salesBudgetYearly/${companyCode}?orgCode=${orgCode}&targetYear=${targetYear}`, {});
  return response.data;
};

export default {
  orgSelect,
};
