/** @jsx jsx */
import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BodyText from 'components/atoms/BodyText';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import SecondaryButton from 'components/atoms/Button/SecondaryButton';
import { css, jsx } from '@emotion/core';
import { productColor } from 'components/styles';
import TextForm from 'components/molecules/TextForm';
import { useParams } from 'react-router-dom';
import { useApprovaHolidayWorkApplication } from 'components/organismos/master/attend/ApprovalApplication/hooks';
import moment from 'moment';
import Modal from 'components/molecules/Modal';
import BlockUI from 'components/molecules/BlockUi';

const styles = {
  line: css({
    marginBottom: '15px',
  }),

  bodyTextTime: css({
    backgroundColor: productColor.primary,
    color: 'white',
    fontSize: '12px',
    padding: '5px',
    borderRadius: '5px',
  }),

  rejectInput: css`
    :not(input){
      font-weight: bold;
    }
    margin-top: 50px;
    
  `,
};

const ApprovalHolidayworkApplication = () => {
  const {
    applicationId,
  } = useParams();

  const {
    approvalData,
    workTimeData,
    applicationStatus,
    rejectReason,
    setRejectReason,
    setModalApproveOpen,
    setModalRejectOpen,
    modalApproveOpen,
    modalRejectOpen,
    closeApproveModal,
    closeRejectModal,
    callApproveHolidayWorkApplication,
    callRejectHolidayWorkApplication,
    isLoading,
  } = useApprovaHolidayWorkApplication(applicationId);


  const handleSubmitApproveModal = () => {
    callApproveHolidayWorkApplication();
    closeApproveModal();
  };
  const handleSubmitRejectModal = () => {
    callRejectHolidayWorkApplication();
    closeRejectModal();
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="組織名"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{`${approvalData.orgCode} ${approvalData.orgName}`}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="申請者"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{`${approvalData.applicationStaffCode} ${approvalData.applicationStaffName}`}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="申請日時"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.applicationDate != null
                  && (
                    moment(approvalData.applicationDate).isValid()
                      ? moment(approvalData.applicationDate).format('YYYY/MM/DD HH:mm:ss')
                      : ''
                  )}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="対象者"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{`${approvalData.staffCode} ${approvalData.staffName}`}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="対象日"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.targetDate != null
                  && (
                    moment(approvalData.targetDate).isValid()
                      ? moment(approvalData.targetDate).format('YYYY/MM/DD')
                      : ''
                  )}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="申請種別"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {approvalData.modifiyStatus === 0 && '申請'}
              {approvalData.modifiyStatus === 1 && '削除'}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="出勤時間"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {workTimeData !== undefined
                && workTimeData.startTime ? workTimeData.startTime : ''}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="退勤時間"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {workTimeData !== undefined
                && workTimeData.endTime ? workTimeData.endTime : ''}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="150px">
            <FormLabel
              label="申請理由"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>{approvalData.applicationReason}</BodyText>
          </FlexBoxItem>
        </FlexBox>
        <div css={styles.rejectInput}>
          <TextForm
            name="rejectReason"
            label={`${approvalData.applicationStatus === 2 ? '差戻者: ' : '承認者: '}  ${approvalData.approvalStaffCode}  ${approvalData.approvalStaffName}`}
            value={rejectReason || ''}
            disable={applicationStatus !== 0}
            onChange={(e) => setRejectReason(e.target.value)}
            placeHolder="差戻コメントを入力"
          />
        </div>
        <FlexBox customStyle={css({
          justifyContent: 'center',
          marginTop: '30px',
        })}
        >
          { applicationStatus === -1
                && (
                  <React.Fragment>
                    <BodyText color="#a94442" bold={true}>申請データが見つかりません。</BodyText>
                  </React.Fragment>
                )}
          { applicationStatus === 0
                && (
                  <React.Fragment>
                    <FlexBoxItem marginRight="5px">
                      <PrimaryButton
                        text="承認"
                        onClick={() => setModalApproveOpen(true)}
                        minWidth="60%"
                      />
                    </FlexBoxItem>
                    <FlexBoxItem marginLeft="5px">
                      <SecondaryButton
                        text="差戻"
                        onClick={() => setModalRejectOpen(true)}
                      />
                    </FlexBoxItem>
                  </React.Fragment>
                )}
          { applicationStatus === 1
                && (
                  <React.Fragment>
                    <BodyText color="#a94442" bold={true}>この申請は既に承認済みです</BodyText>
                  </React.Fragment>
                )}
          { applicationStatus === 2
                && (
                  <React.Fragment>
                    <BodyText color="#a94442" bold={true}>この申請は既に差戻済みです</BodyText>
                  </React.Fragment>
                )}
        </FlexBox>
      </FormContents>
      <Modal
        open={modalApproveOpen}
        closeHandler={closeApproveModal}
        title="承認します。よろしいですか？"
        submitText="承認"
        onSubmit={handleSubmitApproveModal}
        
      />
      <Modal
        open={modalRejectOpen}
        closeHandler={closeRejectModal}
        title="差戻します。よろしいですか？"
        submitText="差戻"
        onSubmit={handleSubmitRejectModal}
        
      />
    </BlockUI>
  );
};

export default ApprovalHolidayworkApplication;
