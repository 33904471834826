/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import { textFontSize, utilityColor, textColor } from 'components/styles';

const styles = {
  title: css({
    margin: '16px 35px',
    color: textColor.main,
  }),
  note: css({
    fontSize: textFontSize.sm,
    marginLeft: '4px',
  }),
};

const FormTitle: React.FC<{
  title: string;
  note?: string;
  noteColor?: string;
  bold?: boolean;
  customStyle?: SerializedStyles;
}> = ({ title, note, noteColor = utilityColor.error, bold= false, customStyle }) => (
  <h4 css={[styles.title, { fontWeight: bold ? "bold" : "normal"}, customStyle]}>
    {title}
    {note && <span css={css(styles.note, { color: noteColor })}>{note}</span>}
  </h4>
);

export default FormTitle;
