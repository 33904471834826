/* eslint-disable max-len */
/* eslint-disable no-shadow */
export interface TimeSetting {
  createUser: string;
  updateUser: string;
  settingId: string;
  timeDisplayFormat: number;
  roundMethodOfThirdDecimalPlace: number;
  dispStampTimeOrg: number;
  midnightBreakdownDisplay: boolean;
  longestWorkingHours: number;
  useFaceStamp: number;
  useBarcodeStamp: number;
  useICCardStamp: number;
  useRemindError: number;
  remindDayBeforeAndAfter: any;
  costRoundingFlag: number;
  monthlyProratedFlag: number;
  autoExtraHourlySalaryFlag: number;
  monthlyTransportationProratedFlag: number;
  supportTransportationFlag: number;
  dispStartHour: number;
  dispHour: number;
  mwsDisplayFlag: number;
  mwsDisplayStartDay1: number;
  mwsDisplayStartDow: number;
  hopeShiftLimitFlag: number;
  shiftCreateFlag: number;
  hopeShiftLimitFixedDay1: number;
  hopeShiftLimitFixedDay2: number;
  hopeShiftWeekStartDow: number;
  hopeShiftWeekOffset: number;
  hopeShiftWeekLimitDow: number;
  shiftStaffDisplayFlag: number;
  nursingYearStartMonth: number;
  nursingYearStartDay: number;
  nursingYearEndMonth: number;
  nursingYearEndDay: number;
  childNursingYearStartMonth: number;
  childNursingYearStartDay: number;
  childNursingYearEndMonth: number;
  childNursingYearEndDay: number;
  halhTimeHolidayDuplicateCheck: number;
  autoPaidUseAttendanceRate: number;
  attendanceRate: number;
  approverNotSkipApproveFlag: boolean;
}
export enum timeDisplayFormat {
  time10 = 0,
  time60 = 1,
}
export enum timeDisplayFormatLabel {
  "10進数" = 0,
  "60進数" = 1,
}
export enum roundMethodOfThirdDecimalPlace {
  roundDecimal0 = 0,
  roundDecimal1 = 1,
  roundDecimal2 = 2,
}
export enum roundMethodOfThirdDecimalPlaceLabel {
  "切り捨てる" = 0,
  "切り上げる" = 1,
  "四捨五入" = 2,
}
export enum dispStampTimeOrg {
  noDisp = 0,
  disp = 1,
}
export enum dispStampTimeOrgLabel {
  "しない" = 0,
  "する" = 1,
}
export enum midnightBreakdownDisplay {
  midnight0 = 0,
  midnight1 = 1,
}
export enum useRemindErrorDisplay {
  useRemindError0 = 0,
  useRemindError1 = 1,
}
export enum midnightBreakdownDisplayLabel {
  "しない" = 0,
  "する" = 1,
}
export enum useRemindErrorLabel {
  "使用しない" = 0,
  "使用する" = 1,
}
export enum useFaceStamp {
  useFaceStamp0 = 0,
  useFaceStamp1 = 1,
}
export enum useFaceStampLabel {
  "使用しない" = 0,
  "使用する" = 1,
}
export enum useBarcodeStamp {
  useBarcodeStamp0 = 0,
  useBarcodeStamp1 = 1,
}
export enum useBarcodeStampLabel {
  "使用しない" = 0,
  "使用する" = 1,
}

export enum useICCardStamp {
  useICCardStamp0 = 0,
  useICCardStamp1 = 1,
}
export enum useICCardStampLabel {
  "使用しない" = 0,
  "使用する" = 1,
}
export enum costRoundingFlag {
  costRoundingFlag0 = 0,
  costRoundingFlag1 = 1,
  costRoundingFlag4 = 4,
}
export enum costRoundingFlagLabel {
  "切り捨てる" = 0,
  "切り上げる" = 1,
  "四捨五入" = 4,
}
export enum monthlyProratedFlag {
  ProratedFlag0 = 0,
  ProratedFlag1 = 1,
  ProratedFlag2 = 2,
}
export enum monthlyProratedFlagLabel {
  "所定労働日数で日割" = 0,
  "対象月の日数で日割" = 1,
  "末日に一括計上" = 2,
}
// monthlyTransportationProratedFlag
export enum monthlyTransportationProratedFlag {
  TransportationProratedFlag0 = 0,
  TransportationProratedFlag1 = 1,
  TransportationProratedFlag2 = 2,
}
export enum monthlyTransportationProratedFlagLabel {
  "所定労働日数で日割" = 0,
  "対象月の日数で日割" = 1,
  "末日に一括計上" = 2,
}
// supportTransportationFlag
export enum supportTransportationFlag {
  supportTransportationFlag0 = 0,
  supportTransportationFlag1 = 1,
}
export enum supportTransportationFlagLabel {
  "支援先に計上" = 0,
  "所属組織に計上" = 1,
}
// mwsDisplayFlag
export enum mwsDisplayFlag {
  mwsDisplayFlag0 = 0,
  mwsDisplayFlag1 = 1,
}
export enum mwsDisplayFlagLabel {
  "1週間表示" = 0,
  "1ヶ月表示" = 1,
}
// mwsDisplayStartDow
export enum mwsDisplayStartDow {
  mwsDisplayStartDow0 = 0,
  mwsDisplayStartDow1 = 1,
  mwsDisplayStartDow2 = 2,
  mwsDisplayStartDow3 = 3,
  mwsDisplayStartDow4 = 4,
  mwsDisplayStartDow5 = 5,
  mwsDisplayStartDow6 = 6,
}
export enum mwsDisplayStartDowLabel {
  "日" = 0,
  "月" = 1,
  "火" = 2,
  "水" = 3,
  "木" = 4,
  "金" = 5,
  "土" = 6,
}
// export enum mwsDisplayStartDay1{
//   mwsDisplayStartDay0 = 0,
//   mwsDisplayStartDay1 = 1 ,
// }
// export enum mwsDisplayStartDay1Label{
//   '1週間表示' = 0,
//   '1ヶ月表示' = 1,
// }

// hopeShiftLimitFlag
export enum hopeShiftLimitFlag {
  hopeShiftLimitFlag0 = 0,
  hopeShiftLimitFlag1 = 1,
  hopeShiftLimitFlag2 = 2,
  hopeShiftLimitFlag3 = 3,
}
export enum hopeShiftLimitFlagLabel {
  "設定しない" = 0,
  "月1回固定日" = 1,
  "月2回固定日" = 2,
  "毎週" = 3,
}
export enum shiftCreateFlag {
  shiftCreateFlag0 = 0,
  shiftCreateFlag1 = 1,
}
export enum shiftCreateFlagLabel {
  "使用しない" = 0,
  "使用する" = 1,
}

export enum halhTimeHolidayDuplicateCheck {
  error = 0,
  noError = 1,
}

export enum halhTimeHolidayDuplicateCheckLabel {
  "エラーとする" = 0,
  "エラーとしない" = 1,
}

export enum autoPaidUseAttendanceRate {
  noUse = 0,
  use = 1,
}

export enum autoPaidUseAttendanceRateLabel {
  "考慮しない" = 0,
  "考慮する" = 1,
}

export enum costRoundingFlagCheckLabel {
  "切り上げる" = 0,
  "切り捨てる" = 1,
  "四捨五入" = 4,
}

export enum monthlyProratedFlagCheckLabel {
  "所定労働日数で日割" = 0,
  "対象月の日数で日割" = 1,
  "末日に一括計上" = 2,
}

export enum autoExtraHourlySalaryFlagCheckLabel {
  "自動算出しない" = 0,
  "月給・日給より自動算出する" = 1,
}

export enum supportTransportationFlagCheckLabel {
  "支援先に計上 " = 0,
  "所属店舗に計上" = 1,
}

export default class TimeSettingDomain {
  constructor(private rawData: TimeSetting) {
    // do nothing
  }

  static generateInitial(): TimeSettingDomain {
    return new TimeSettingDomain({
      createUser: "",
      updateUser: "",
      settingId: "",
      timeDisplayFormat: 0,
      roundMethodOfThirdDecimalPlace: 0,
      dispStampTimeOrg: 0,
      midnightBreakdownDisplay: false,
      longestWorkingHours: 0,
      useFaceStamp: 0,
      useBarcodeStamp: 0,
      useICCardStamp: 0,
      remindDayBeforeAndAfter: null,
      useRemindError: 0,
      costRoundingFlag: 0,
      monthlyProratedFlag: 0,
      autoExtraHourlySalaryFlag: 0,
      monthlyTransportationProratedFlag: 0,
      supportTransportationFlag: 0,
      dispStartHour: 0,
      dispHour: 0,
      mwsDisplayFlag: 0,
      mwsDisplayStartDay1: 0,
      mwsDisplayStartDow: 0,
      hopeShiftLimitFlag: 0,
      shiftCreateFlag: 0,
      hopeShiftLimitFixedDay1: 0,
      hopeShiftLimitFixedDay2: 0,
      hopeShiftWeekStartDow: 0,
      hopeShiftWeekOffset: 0,
      hopeShiftWeekLimitDow: 0,
      shiftStaffDisplayFlag: 0,
      nursingYearStartMonth: 4,
      nursingYearStartDay: 1,
      nursingYearEndMonth: 3,
      nursingYearEndDay: 31,
      childNursingYearStartMonth: 4,
      childNursingYearStartDay: 1,
      childNursingYearEndMonth: 3,
      childNursingYearEndDay: 31,
      halhTimeHolidayDuplicateCheck: 0,
      autoPaidUseAttendanceRate: 1,
      attendanceRate: 80,
      approverNotSkipApproveFlag: true,
    });
  }

  getRawData(): TimeSetting {
    return this.rawData;
  }

  getRawDataWithoutNullData(): TimeSetting {
    return {
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
      settingId: this.rawData.settingId,
      timeDisplayFormat: this.rawData.timeDisplayFormat ? this.rawData.timeDisplayFormat : 0,
      dispStampTimeOrg: this.rawData.dispStampTimeOrg ? this.rawData.dispStampTimeOrg : 0,
      midnightBreakdownDisplay: this.rawData.midnightBreakdownDisplay ? this.rawData.midnightBreakdownDisplay : false,
      roundMethodOfThirdDecimalPlace: this.rawData.roundMethodOfThirdDecimalPlace
        ? this.rawData.roundMethodOfThirdDecimalPlace
        : 0,
      longestWorkingHours: this.rawData.longestWorkingHours ? this.rawData.longestWorkingHours : 0,
      useFaceStamp: this.rawData.useFaceStamp ? this.rawData.useFaceStamp : 0,
      useRemindError: this.rawData.useRemindError ? this.rawData.useRemindError : 0,
      useBarcodeStamp: this.rawData.useBarcodeStamp ? this.rawData.useBarcodeStamp : 0,
      useICCardStamp: this.rawData.useICCardStamp ? this.rawData.useICCardStamp : 0,
      remindDayBeforeAndAfter:
        this.rawData.remindDayBeforeAndAfter && this.rawData.useRemindError === 1
          ? this.rawData.remindDayBeforeAndAfter
          : 0,
      costRoundingFlag: this.rawData.costRoundingFlag ? this.rawData.costRoundingFlag : 0,
      monthlyProratedFlag: this.rawData.monthlyProratedFlag ? this.rawData.monthlyProratedFlag : 0,
      autoExtraHourlySalaryFlag: this.rawData.autoExtraHourlySalaryFlag ? this.rawData.autoExtraHourlySalaryFlag : 0,
      monthlyTransportationProratedFlag: this.rawData.monthlyTransportationProratedFlag
        ? this.rawData.monthlyTransportationProratedFlag
        : 0,
      supportTransportationFlag: this.rawData.supportTransportationFlag ? this.rawData.supportTransportationFlag : 0,
      dispStartHour: this.rawData.dispStartHour ? this.rawData.dispStartHour : 0,
      dispHour: this.rawData.dispHour ? this.rawData.dispHour : 0,
      mwsDisplayFlag: this.rawData.mwsDisplayFlag ? this.rawData.mwsDisplayFlag : 0,
      mwsDisplayStartDay1: this.rawData.mwsDisplayStartDay1 ? this.rawData.mwsDisplayStartDay1 : 0,
      mwsDisplayStartDow: this.rawData.mwsDisplayStartDow ? this.rawData.mwsDisplayStartDow : 0,
      hopeShiftLimitFlag: this.rawData.hopeShiftLimitFlag ? this.rawData.hopeShiftLimitFlag : 0,
      shiftCreateFlag: this.rawData.shiftCreateFlag ? this.rawData.shiftCreateFlag : 0,
      hopeShiftLimitFixedDay1: this.rawData.hopeShiftLimitFixedDay1 ? this.rawData.hopeShiftLimitFixedDay1 : 0,
      hopeShiftLimitFixedDay2: this.rawData.hopeShiftLimitFixedDay2 ? this.rawData.hopeShiftLimitFixedDay2 : 0,
      hopeShiftWeekStartDow: this.rawData.hopeShiftWeekStartDow ? this.rawData.hopeShiftWeekStartDow : 0,
      hopeShiftWeekOffset: this.rawData.hopeShiftWeekOffset ? this.rawData.hopeShiftWeekOffset : 0,
      hopeShiftWeekLimitDow: this.rawData.hopeShiftWeekLimitDow ? this.rawData.hopeShiftWeekLimitDow : 0,
      shiftStaffDisplayFlag: this.rawData.shiftStaffDisplayFlag ? this.rawData.shiftStaffDisplayFlag : 0,
      nursingYearStartMonth: this.rawData.nursingYearStartMonth || 4,
      nursingYearStartDay: this.rawData.nursingYearStartDay || 1,
      nursingYearEndMonth: this.rawData.nursingYearEndMonth || 3,
      nursingYearEndDay: this.rawData.nursingYearEndDay || 31,
      childNursingYearStartMonth: this.rawData.childNursingYearStartMonth || 4,
      childNursingYearStartDay: this.rawData.childNursingYearStartDay || 1,
      childNursingYearEndMonth: this.rawData.childNursingYearEndMonth || 3,
      childNursingYearEndDay: this.rawData.childNursingYearEndDay || 31,
      halhTimeHolidayDuplicateCheck: this.rawData.halhTimeHolidayDuplicateCheck || 0,
      autoPaidUseAttendanceRate: this.rawData.autoPaidUseAttendanceRate || 1,
      attendanceRate: this.rawData.attendanceRate || 80,
      approverNotSkipApproveFlag: this.rawData.approverNotSkipApproveFlag || false,
    };
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get settingId(): string {
    return this.rawData.settingId;
  }

  set settingId(settingId: string) {
    this.rawData.settingId = settingId;
  }

  // timeDisplayFormat
  get timeDisplayFormat(): number {
    return this.rawData.timeDisplayFormat;
  }

  // eslint-disable-next-line no-shadow
  set timeDisplayFormat(timeDisplayFormat: number) {
    this.rawData.timeDisplayFormat = timeDisplayFormat;
  }

  get timeDisplayFormatLabel(): String {
    return timeDisplayFormatLabel[this.rawData.timeDisplayFormat];
  }

  // dispStampTimeOrg
  get dispStampTimeOrg(): number {
    return this.rawData.dispStampTimeOrg;
  }

  // eslint-disable-next-line no-shadow
  set dispStampTimeOrg(dispStampTimeOrg: number) {
    this.rawData.dispStampTimeOrg = dispStampTimeOrg;
  }

  get dispStampTimeOrgLabel(): String {
    return timeDisplayFormatLabel[this.rawData.dispStampTimeOrg];
  }

  // Label useFaceStamp
  get useFaceStamp(): number {
    return this.rawData.useFaceStamp;
  }

  set useFaceStamp(useFaceStamp: number) {
    this.rawData.useFaceStamp = useFaceStamp;
  }

  get useFaceStampLabel(): String {
    return useFaceStampLabel[this.rawData.useFaceStamp];
  }

  // useBarcodeStamp
  get useBarcodeStamp(): number {
    return this.rawData.useBarcodeStamp;
  }

  set useBarcodeStamp(useBarcodeStamp: number) {
    this.rawData.useBarcodeStamp = useBarcodeStamp;
  }

  // useRemindError
  get useRemindError(): number {
    return this.rawData.useRemindError;
  }

  set useRemindError(useRemindError: number) {
    this.rawData.useRemindError = useRemindError;
  }

  // remindDayBeforeAndAfter
  get remindDayBeforeAndAfter(): any {
    return this.rawData.remindDayBeforeAndAfter;
  }

  set remindDayBeforeAndAfter(remindDayBeforeAndAfter: any) {
    this.rawData.remindDayBeforeAndAfter = remindDayBeforeAndAfter;
  }

  // useICCardStamp
  get useICCardStamp(): number {
    return this.rawData.useICCardStamp;
  }

  set useICCardStamp(useICCardStamp: number) {
    this.rawData.useICCardStamp = useICCardStamp;
  }
  // get useBarcodeStampLabel(): String {
  //   return monthlyProratedFlagLabel[this.rawData.useBarcodeStamp];
  // }

  get costRoundingFlag(): number {
    return this.rawData.costRoundingFlag;
  }

  set costRoundingFlag(costRoundingFlag: number) {
    this.rawData.costRoundingFlag = costRoundingFlag;
  }

  get monthlyProratedFlag(): number {
    return this.rawData.monthlyProratedFlag;
  }

  set monthlyProratedFlag(monthlyProratedFlag: number) {
    this.rawData.monthlyProratedFlag = monthlyProratedFlag;
  }

  get autoExtraHourlySalaryFlag(): number {
    return this.rawData.autoExtraHourlySalaryFlag;
  }

  set autoExtraHourlySalaryFlag(autoExtraHourlySalaryFlag: number) {
    this.rawData.autoExtraHourlySalaryFlag = autoExtraHourlySalaryFlag;
  }

  get monthlyTransportationProratedFlag(): number {
    return this.rawData.monthlyTransportationProratedFlag;
  }

  set monthlyTransportationProratedFlag(monthlyTransportationProratedFlag: number) {
    this.rawData.monthlyTransportationProratedFlag = monthlyTransportationProratedFlag;
  }

  get supportTransportationFlag(): number {
    return this.rawData.supportTransportationFlag;
  }

  set supportTransportationFlag(supportTransportationFlag: number) {
    this.rawData.supportTransportationFlag = supportTransportationFlag;
  }

  get dispStartHour(): number {
    return this.rawData.dispStartHour;
  }

  set dispStartHour(dispStartHour: number) {
    this.rawData.dispStartHour = dispStartHour;
  }

  get dispHour(): number {
    return this.rawData.dispHour;
  }

  set dispHour(dispHour: number) {
    this.rawData.dispHour = dispHour;
  }

  get mwsDisplayFlag(): number {
    return this.rawData.mwsDisplayFlag;
  }

  set mwsDisplayFlag(mwsDisplayFlag: number) {
    this.rawData.mwsDisplayFlag = mwsDisplayFlag;
  }

  get mwsDisplayStartDay1(): number {
    return this.rawData.mwsDisplayStartDay1;
  }

  set mwsDisplayStartDay1(mwsDisplayStartDay1: number) {
    this.rawData.mwsDisplayStartDay1 = mwsDisplayStartDay1;
  }

  get mwsDisplayStartDow(): number {
    return this.rawData.mwsDisplayStartDow;
  }

  set mwsDisplayStartDow(mwsDisplayStartDow: number) {
    this.rawData.mwsDisplayStartDow = mwsDisplayStartDow;
  }

  get mwsDisplayStartDowLabel(): String {
    return mwsDisplayStartDowLabel[this.rawData.mwsDisplayStartDow];
  }

  get hopeShiftLimitFlag(): number {
    return this.rawData.hopeShiftLimitFlag;
  }

  set hopeShiftLimitFlag(hopeShiftLimitFlag: number) {
    this.rawData.hopeShiftLimitFlag = hopeShiftLimitFlag;
  }

  get shiftCreateFlag(): number {
    return this.rawData.shiftCreateFlag;
  }

  set shiftCreateFlag(shiftCreateFlag: number) {
    this.rawData.shiftCreateFlag = shiftCreateFlag;
  }

  get hopeShiftLimitFixedDay1(): number {
    return this.rawData.hopeShiftLimitFixedDay1;
  }

  set hopeShiftLimitFixedDay1(hopeShiftLimitFixedDay1: number) {
    this.rawData.hopeShiftLimitFixedDay1 = hopeShiftLimitFixedDay1;
  }

  get midnightBreakdownDisplay(): boolean {
    return this.rawData.midnightBreakdownDisplay;
  }

  set midnightBreakdownDisplay(midnightBreakdownDisplay: boolean) {
    this.rawData.midnightBreakdownDisplay = midnightBreakdownDisplay;
  }

  get roundMethodOfThirdDecimalPlace(): number {
    return this.rawData.roundMethodOfThirdDecimalPlace;
  }

  set roundMethodOfThirdDecimalPlace(roundMethodOfThirdDecimalPlace: number) {
    this.rawData.roundMethodOfThirdDecimalPlace = roundMethodOfThirdDecimalPlace;
  }

  get roundMethodOfThirdDecimalPlaceLabel(): String {
    return roundMethodOfThirdDecimalPlaceLabel[this.rawData.roundMethodOfThirdDecimalPlace];
  }

  // longestWorkingHours
  get longestWorkingHours(): number {
    return this.rawData.longestWorkingHours;
  }

  set longestWorkingHours(longestWorkingHours: number) {
    this.rawData.longestWorkingHours = longestWorkingHours;
  }

  get hopeShiftLimitFixedDay2(): number {
    return this.rawData.hopeShiftLimitFixedDay2;
  }

  set hopeShiftLimitFixedDay2(hopeShiftLimitFixedDay2: number) {
    this.rawData.hopeShiftLimitFixedDay2 = hopeShiftLimitFixedDay2;
  }

  get hopeShiftWeekStartDow(): number {
    return this.rawData.hopeShiftWeekStartDow;
  }

  set hopeShiftWeekStartDow(hopeShiftWeekStartDow: number) {
    this.rawData.hopeShiftWeekStartDow = hopeShiftWeekStartDow;
  }

  get hopeShiftWeekOffset(): number {
    return this.rawData.hopeShiftWeekOffset;
  }

  set hopeShiftWeekOffset(hopeShiftWeekOffset: number) {
    this.rawData.hopeShiftWeekOffset = hopeShiftWeekOffset;
  }

  get hopeShiftWeekLimitDow(): number {
    return this.rawData.hopeShiftWeekLimitDow;
  }

  set hopeShiftWeekLimitDow(hopeShiftWeekLimitDow: number) {
    this.rawData.hopeShiftWeekLimitDow = hopeShiftWeekLimitDow;
  }

  get shiftStaffDisplayFlag(): number {
    return this.rawData.shiftStaffDisplayFlag;
  }

  set shiftStaffDisplayFlag(shiftStaffDisplayFlag: number) {
    this.rawData.shiftStaffDisplayFlag = shiftStaffDisplayFlag;
  }

  get nursingYearStartMonth(): number {
    return this.rawData.nursingYearStartMonth;
  }

  set nursingYearStartMonth(nursingYearStartMonth: number) {
    this.rawData.nursingYearStartMonth = nursingYearStartMonth;
  }

  get nursingYearStartDay(): number {
    return this.rawData.nursingYearStartDay;
  }

  set nursingYearStartDay(nursingYearStartDay: number) {
    this.rawData.nursingYearStartDay = nursingYearStartDay;
  }

  get nursingYearEndMonth(): number {
    return this.rawData.nursingYearEndMonth;
  }

  set nursingYearEndMonth(nursingYearEndMonth: number) {
    this.rawData.nursingYearEndMonth = nursingYearEndMonth;
  }

  get nursingYearEndDay(): number {
    return this.rawData.nursingYearEndDay;
  }

  set nursingYearEndDay(nursingYearEndDay: number) {
    this.rawData.nursingYearEndDay = nursingYearEndDay;
  }

  get childNursingYearStartMonth(): number {
    return this.rawData.childNursingYearStartMonth;
  }

  set childNursingYearStartMonth(childNursingYearStartMonth: number) {
    this.rawData.childNursingYearStartMonth = childNursingYearStartMonth;
  }

  get childNursingYearStartDay(): number {
    return this.rawData.childNursingYearStartDay;
  }

  set childNursingYearStartDay(childNursingYearStartDay: number) {
    this.rawData.childNursingYearStartDay = childNursingYearStartDay;
  }

  get childNursingYearEndMonth(): number {
    return this.rawData.childNursingYearEndMonth;
  }

  set childNursingYearEndMonth(childNursingYearEndMonth: number) {
    this.rawData.childNursingYearEndMonth = childNursingYearEndMonth;
  }

  get childNursingYearEndDay(): number {
    return this.rawData.childNursingYearEndDay;
  }

  set childNursingYearEndDay(childNursingYearEndDay: number) {
    this.rawData.childNursingYearEndDay = childNursingYearEndDay;
  }

  get halhTimeHolidayDuplicateCheck(): number {
    return this.rawData.halhTimeHolidayDuplicateCheck;
  }

  set halhTimeHolidayDuplicateCheck(halhTimeHolidayDuplicateCheck: number) {
    this.rawData.halhTimeHolidayDuplicateCheck = halhTimeHolidayDuplicateCheck;
  }

  get approverNotSkipApproveFlag(): boolean {
    return this.rawData.approverNotSkipApproveFlag;
  }

  set approverNotSkipApproveFlag(approverNotSkipApproveFlag: boolean) {
    this.rawData.approverNotSkipApproveFlag = approverNotSkipApproveFlag;
  }

  get autoPaidUseAttendanceRate(): number {
    return this.rawData.autoPaidUseAttendanceRate;
  }

  set autoPaidUseAttendanceRate(autoPaidUseAttendanceRate: number) {
    this.rawData.autoPaidUseAttendanceRate = autoPaidUseAttendanceRate;
  }

  get attendanceRate(): number {
    return this.rawData.attendanceRate;
  }

  set attendanceRate(attendanceRate: number) {
    this.rawData.attendanceRate = attendanceRate;
  }
}
