import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import FlexBox from 'components/atoms/FlexBox';
import './index.css';

const CloseButton = (props: any) => {
  return (
    <div className="toastMsg">
      <FlexBox justifyContent="space-between">
        {props.msg}
        <div>
          <ClickableIconButton onClick={props.closeToast}>
            <span className='closeButton'>
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 16.299 16.299 21 10.5 21ZM10.5 9.79289L13.1464 7.14645C13.3417 6.95118 13.6583 6.95118 13.8536 7.14645C14.0488 7.34171 14.0488 7.65829 13.8536 7.85355L11.2071 10.5L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.6583 14.0488 13.3417 14.0488 13.1464 13.8536L10.5 11.2071L7.85355 13.8536C7.65829 14.0488 7.34171 14.0488 7.14645 13.8536C6.95118 13.6583 6.95118 13.3417 7.14645 13.1464L9.79289 10.5L7.14645 7.85355C6.95118 7.65829 6.95118 7.34171 7.14645 7.14645C7.34171 6.95118 7.65829 6.95118 7.85355 7.14645L10.5 9.79289ZM20 10.5C20 15.7467 15.7467 20 10.5 20C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5Z" fill="white" />
              </svg>
            </span>
          </ClickableIconButton>
        </div>
      </FlexBox>
    </div>
  )
}

const success = (message: string|ReactNode) => {
  toast.success(<CloseButton msg={message} />, { className: 'toastnotification--success', closeButton: false  });
};

export default success;
