/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize, textColor } from 'components/styles';

const styles = {
  content: css({
    fontSize: textFontSize.sm,
    width: '100%',
    color: textColor.subText01,
  }),
};

const FormHelp: React.FC<{
  content: string;
  customStyle?:any;
}> = ({ content,customStyle }) => (
  <React.Fragment>
    <small css={css(styles.content,customStyle)} dangerouslySetInnerHTML={{ __html: content }} />
  </React.Fragment>
);

export default FormHelp;
