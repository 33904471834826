/* eslint-disable max-len */
import { useEffect, useState, useCallback } from 'react';
import { getSelOrganize } from 'api/organization';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';

export const useOrgList = (
  addOrganization: (arg: Array<SelfOrganizationDomain>) => void,
  openHandler: (arg: boolean) => void,
) => {
  const [organizationList, setOrganizationList] = useState<Array<SelfOrganizationDomain>|null>(null);
  const [checkedOrgCodeList, setCheckedOrgCodeList] = useState<Array<string>>([]);

  useEffect(() => {
    const fetchStaffList = async () => {
      const fetchedStaffList = await getSelOrganize();
      setOrganizationList(fetchedStaffList.map((fetchStaff) => new SelfOrganizationDomain(fetchStaff)));
    };
    fetchStaffList();
  }, [setOrganizationList]);

  /**
   * チェックボックスのハンドラー
   */
  const checkHandler = useCallback((staffCode: string) => {
    const isChecked = checkedOrgCodeList.includes(staffCode);
    if (isChecked) {
      // チェック済みならリストからはずす
      setCheckedOrgCodeList(checkedOrgCodeList.filter(
        (checkedStaffCode) => checkedStaffCode !== staffCode,
      ));
      return;
    }
    setCheckedOrgCodeList(checkedOrgCodeList.concat(staffCode));
  }, [checkedOrgCodeList]);

  /**
   * 追加実行処理
   */
  const onSubmit = useCallback(() => {
    if (!organizationList) {
      return;
    }
    addOrganization(organizationList.filter((organization) => checkedOrgCodeList.includes(organization.orgCode)));

    // モーダルを閉じる
    openHandler(false);

    // reset checkbox
    setCheckedOrgCodeList([]);
  }, [addOrganization, checkedOrgCodeList, openHandler, organizationList]);

  return {
    organizationList,
    checkedOrgCodeList,
    checkHandler,
    onSubmit,
  };
};

export default useOrgList;
