/* eslint-disable eqeqeq */
import React from 'react';
import HeadquartersFinalForm from 'components/organismos/master/attend/HeadquartersFinalForm';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import moment from 'moment';
import { useGetSearchDateClosing } from './hooks';

const HeadquartersFinalPage = () => {
  const {
    targetMonthProp,
    targetDateFromProp,
    targetDateToProp,
  } = useGetSearchDateClosing();

  return (
    <SidebarTemplate pageTitle="勤怠確定">
      {
        (moment(targetMonthProp).format('YYYY-MM-DD') != '9999-12-31'
          && moment(targetDateFromProp).format('YYYY-MM-DD') != '9999-12-31' && moment(targetDateToProp).format('YYYY-MM-DD') != '9999-12-31') && (
          <HeadquartersFinalForm
            targetMonthProp={targetMonthProp}
            targetDateFromProp={targetDateFromProp}
            targetDateToProp={targetDateToProp}
          />
        )
      }
    </SidebarTemplate>
  );
};

export default HeadquartersFinalPage;
