let resizeObserver;
let observedElement;

function addListener(component) {
  component._resizeEventListener = {
    handleEvent: () => {
      setTimeout(() => {
        component.resize();
      }, 500);
    },
  };

  observedElement = document.querySelector(".content-container");

  resizeObserver = new ResizeObserver((_entries) => {
    component._resizeEventListener.handleEvent();
  });

  if (observedElement) {
    resizeObserver.observe(observedElement);
  }

  window.addEventListener("resize", component._resizeEventListener);
}

function removeListener(component) {
  window.removeEventListener("resize", component._resizeEventListener);

  if (resizeObserver && observedElement) {
    resizeObserver.unobserve(observedElement);
  }
}

export default { addListener, removeListener };
