/** @jsx jsx */
import React, { useEffect, useState, useCallback } from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import { useParams, useHistory } from 'react-router-dom';
import { getOutputBaseItems, reflectData } from 'api/timeOutputLayout';
import useOutputBaseItems from 'hooks/useOutputBaseItems';
import Checkbox from 'components/molecules/Checkbox';
import { css, jsx } from '@emotion/core';
import productColor, {
  utilityColor,
  grayScale,
  textFontSize,
} from 'components/styles';
import LableTitle from 'components/atoms/LableTitle';
import Button from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import { useTimeOutputLayoutDomainAddForm } from './hooks';
import ConfirmModal from 'components/organismos/ConfirmModal';
import useToastNotification from 'hooks/useToastNotification';

const baseItemHour = 1;
const checkboxOnChangePlus = 1;
const checkboxOnChangeMinus = 2;
// create style form 
const styles = {
  wrapper: css({
    display: 'flex',
    width: '150px',
    float: 'left',
  }),
  input: css({
    flex: 1,
    width: '100px',
    padding: '7px',
    fontSize: textFontSize.re,
    fontFamily: 'inherit',
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: 'none',
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: '4px 0 0 4px',
  }),
  addon: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '7px',
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
  }),
  cellTable: css({
    width: '100%',
    border: 'none',
    borderSpacing: '0px 1.5px',
    overflow: 'scroll',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: '4px',
    float: 'right',
    marginTop: '-37px',
  }),
  labelTitle: {
    marginTop: '-25px',
    display: 'block',
    paddingLeft: '30px',
    paddingBottom: '30px'
  },
  tableScroll: css({

  }, css`
        .table-scroll{
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
        }
    `),
  rows1: css({

  }, css`
        .sticky-table-cell{
            width:10%
        }
    `),
  thead: css({
    width: '50px',
    textAlign: 'center',
    position: 'relative'
  }),
  theadSpan: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }),
  tdBody: css({
    width: '50px',
    textAlign: 'center',
    height: '20px',
    position: 'relative'
  }),
};

const TimeOutputLayoutAddForm: React.FC = () => {
  const { layoutId } = useParams();
  const {
    setConfirmModalOpen,
    getOutputLayouts,
  } = useTimeOutputLayoutDomainAddForm(layoutId || '');

  const [baseItemType, setBaseItemType] = useState(baseItemHour);

  const outputBaseItemsOptions1 = useOutputBaseItems(baseItemType);

  const { successNotification, errorNotification } = useToastNotification();

  const [outputBaseItems, setOutputBaseItems] = useState(outputBaseItemsOptions1);

  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    getOutputBaseItems(baseItemHour).then((response) => {
      setOutputBaseItems(response);
    });
    setBaseItemType(baseItemHour);
  }, []);


  /**
  *  handle on change
  */
  const handleChangePlusAndMinus = async (checkboxOnChange: any, baseItemId: any, e: any) => {
    const items = [...outputBaseItems];
    items.filter((item: any) => {
      if (item.id.baseItemId === baseItemId) {
        if (checkboxOnChange === checkboxOnChangePlus) {
          if (item.plus === true) {
            item.plus = false;
          } else {
            item.plus = true;
            item.minus = false;
          }
        } else if (item.minus === true) {
          item.minus = false;
        } else {
          item.minus = true;
          item.plus = false;
        }
        return true;
      }
      return false;
    });
    setOutputBaseItems(items);
    setIsChecked(false);
    items.map((item: any) => {
      if (item.minus === true || item.plus === true) {
        setIsChecked(true);
      }
      return true;
    })
  };

  const history = useHistory();
  const handleBack = async () => {
    history.goBack();
  };

  /**
 *  handle submit
 */
  const handleSubmitAddOutputBaseItems = async (bItemType: any) => {
    let convertData = {};
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
    // let checkFlg = false; 
    outputBaseItems.forEach((item: any, index: number) => {
      delete (item as any).updateDate;
      delete (item as any).createDate;
      delete (item as any).createUser;
      delete (item as any).updateUser;
      delete (item as any).calculationType;
      delete (item as any).companyCode;
      delete (item as any).id.outputItemId;

      // if ((item as any).plus === true) {
      //   //
      //   (item as any).plus = 'on';
      //   checkFlg = true;
      // } else {
      //   delete (item as any).plus;
      // }

      // if ((item as any).minus === true) {
      //   //
      //   (item as any).minus = 'on';
      //   checkFlg = true;
      // } else {
      //   delete (item as any).minus;
      // }

      convertData = {
        ...convertData,
        [`baseItemList[${index}]`]: {
          ...item,
        },

      };
    });

    if (!isChecked) {
      setModalOpen(false);
      errorNotification('出力項目を選択してください。');
      return;
    }

    //
    convertData = {
      ...convertData,
      createUser: loginUser,
      updateUser: loginUser,
    };

    try {
      await reflectData(companyCode, convertData);
      getOutputLayouts(layoutId);
      // outputBaseItems.forEach((item: any, index: number) => {
      //   delete (item as any).minus;
      //   delete (item as any).plus;
      // });
      successNotification('登録しました。');
      setModalOpen(false);
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  return (
    <FormContents css={{ marginTop: '30px' }}>
      <strong style={styles.labelTitle}><LableTitle title="勤務時間項目追加" /></strong>
      <div css={styles.tableScroll}>
        <table style={{ width: '100%' }} className="table-bordered">
          <thead className="thead-row">
            <tr style={{ width: '100%' }}>
              <th css={styles.thead}><span css={styles.theadSpan}> ＋ </span></th>
              <th css={styles.thead}><span css={styles.theadSpan}> － </span></th>
              <th style={{ width: 'calc(100vw - 102px)', textAlign: 'left' }} ><span> 出力項目名 </span></th>
            </tr>
          </thead>
        </table>
        <div className="table-scroll" style={{ maxHeight: '60vh', overflow: 'auto' }}>
          <table style={{ width: '100%' }} className="table-bordered">
            <tbody>
              {outputBaseItems.map((item: any, index: any) => (
                <tr key={index} style={{ backgroundColor: '#ffffff' }}>
                  <td css={styles.tdBody}>
                    <span css={styles.theadSpan}>
                      <Checkbox
                        id={`baseItemPlus${item.id.baseItemId}`}
                        name={`baseItem${item.id.baseItemId}`}
                        value={item.id.baseItemId}
                        checked={Boolean(item.plus)}
                        onChange={(e) => handleChangePlusAndMinus(checkboxOnChangePlus, item.id.baseItemId, e)}
                      />
                    </span>
                  </td>
                  <td css={styles.tdBody}>
                    <span css={styles.theadSpan}>
                      <Checkbox
                        id={`baseItemMinus${item.id.baseItemId}`}
                        name={`baseItemMinus${item.id.baseItemId}`}
                        value={item.id.baseItemId}
                        checked={Boolean(item.minus)}
                        onChange={(e) => handleChangePlusAndMinus(checkboxOnChangeMinus, item.id.baseItemId, e)}
                      />
                    </span>
                  </td>
                  <td style={{ width: 'calc(100vw - 100px)', position: 'relative' }} >
                    <span style={{ position: 'absolute', top: '10px' }}>{item.baseItemName}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ textAlign: 'center', margin: '15px 0' }}>
        <div>
          <Button
            text="登録する"
            onClick={() => setModalOpen(true)}
          />
        </div>
        <div>
          <PrimaryButton
            text="キャンセル"
            onClick={() => handleBack()}
            ghost={true}
          />
        </div>
      </div>

      <ConfirmModal
        open={modalOpen}
        closeHandler={closeModal}
        onSubmit={() => handleSubmitAddOutputBaseItems(baseItemHour)}
        title="勤務時間項目の登録"
        content={`勤務時間項目を登録します。よろしいですか？`}
        submitText={`登録する`}
      />
    </FormContents>
  );
};

export default TimeOutputLayoutAddForm;
