import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationManagerEditForm from 'components/organismos/organization/OrganizationManagerEditForm';
// import PrimaryButton from 'components/atoms/Button';
// import FlexBox from 'components/atoms/FlexBox';
// import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormContents from 'components/atoms/Form/FormContents';
import { useOrganizationManagers } from './hooks';
import FormPadding from 'components/atoms/Form/FormPadding';
import TabControl from 'components/atoms/TabControl';
import { Row, Col } from 'react-grid-system';
import OrganizationInputForm from 'components/organismos/organization/OrganizationInputForm';
import { useOrganizationEdit } from '../OrganizationEditPage/hooks';
import FormTitle from 'components/atoms/Form/FormTitle';

type SelectType = 'basic'|'manager'|'specialSalary';
const SelectType: {[key in SelectType]: SelectType} = {
  basic: 'basic',
  manager: 'manager',
  specialSalary: 'specialSalary',
};

const OrganizationManagersPage: React.FC = () => {
  const {
    state, setManagerList, setSeniorManagerList,
  } = useOrganizationManagers();
  const {
    organizationDomain, setOrganizationDomain, historyList, setHistoryList
  } = useOrganizationEdit();
  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.manager);
  return (
    <SidebarTemplate
          pageTitle="マネージャー設定"
        >
    <FormContents>
        <FormTitle title="組織編集" bold={true} />
        <FormPadding customStyle={{ padding: '0 30px' }}>
          <Row>
            <Col md={7} xs={12}>
              <TabControl
                items={[
                  {
                    value: SelectType.basic,
                    display: '基本情報',
                  },
                  // {
                  //   value: SelectType.manager,
                  //   display: 'マネージャー管理情報',
                  // },
                  // {
                  //   value: SelectType.specialSalary,
                  //   display: '特賃日割当情報',
                  // },
                ]}
                setValue={setSelectType as (arg: string) => void}
                currentValue={selectType}
                isFixed={false}
              />
            </Col>
          </Row>
        </FormPadding>
          {selectType === SelectType.basic
              && (
                <>
                  {/* <OrganizationInfoTable
                    organizationDomain={organizationDomain}
                  /> */}
                  <OrganizationInputForm
                    initialOrganizationDomain={organizationDomain || undefined}
                    setInitialOrganizationDomain={setOrganizationDomain}
                    historyList={historyList}
                    setHistoryList={setHistoryList}
                    isEditMode={true}
                  />
                </>
              )}
          {selectType === SelectType.manager
              && (
                <OrganizationManagerEditForm
                managerList={state.managerList}
                seniorManagerList={state.seniorManagerList}
                setManagerList={setManagerList}
                setSeniorManagerList={setSeniorManagerList}
              />
          )}

      {/* <FlexBox>
        <FlexBoxItem marginLeft="16px">
          <PrimaryButton
            text="設定を保存"
            onClick={handler.onSubmit(false)}
          />
        </FlexBoxItem>
        <FlexBoxItem marginLeft="8px">
          <PrimaryButton
            text="保存して特賃日を設定"
            onClick={handler.onSubmit(true)}
          />
        </FlexBoxItem>
      </FlexBox> */}
  </FormContents>
  </SidebarTemplate>
  );
};

export default OrganizationManagersPage;
