/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, useContext, Dispatch
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain from 'domain/staff';
import { postStaffInfo,  } from 'api/staff';
import { LoadingContextAdd } from 'components/pages/master/general/staff/StaffAddPage/hooks'
import { getListICCardByStaff, postRegisterStaffBulkICCard } from 'api/iCCard';
import { ICCardByStaffDomain } from "domain/master/icCard/listICCardByStaff";
import { useParams } from 'react-router-dom';

export const useStaffAddForm = (
  initialStaffDomain: StaffDomain,
  isEditMode: boolean,
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>
) => {
  const history = useHistory();
  const contextObject = useContext(LoadingContextAdd);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [listICCardByStaff, setListICCardByStaff] = useState<Array<any>>([]);
  const { staffCode } = useParams<{ staffCode: string }>();

  const registerStaffBulkICCard = useCallback(async () => {
    let listCard = listICCardByStaff.map((card) => card.cardId);
    setIsLoading!(true);
    postRegisterStaffBulkICCard(
      listCard.toString(),
      staffCode || "",
    ).then((response: any) => {
      setIsLoading!(false);
    })
    .catch((error: any) => {
      setIsLoading!(false);
      if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }      
    });
  }, [staffCode, listICCardByStaff]);

  const onSubmit = async (values: StaffDomain) => {
    if (formik.values.staffName === '') {
      errorNotification('姓・名を入力してください。');
      return;
    }
    
    if (formik.values.staffCode === '') {
      errorNotification('スタッフコードを入力してください。');
      return;
    }

    if (formik.values.hireDate === undefined) {
      errorNotification('入社日を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      setConfirmModalOpen(false);
      setIsLoading!(true);
      const response = await postStaffInfo(isEditMode ? 1 : 0, values.getStaffInfoPostData(isEditMode));      
      setIsLoading!(false);
      const updatedStaff = new StaffDomain(response.staff);

      if(updatedStaff.editStaffCode === sessionStorage.getItem('loginUser.staffCode')){
        sessionStorage.setItem('loginUser.barcodeId', updatedStaff.barcodeId || 'null');
      }
      if (!isEditMode) {
        sessionStorage.setItem('selectedOrgCodeStaffMaster', response.staff.orgCode);
      } else {
        // Update organization info when update
        formik.setFieldValue('orgCode', updatedStaff.orgCode);
        formik.setFieldValue('orgName', updatedStaff.orgName);
        formik.setFieldValue('orgApplyDate', updatedStaff.orgApplyDate);
        formik.setFieldValue('organizationDataList', updatedStaff.organizationDataList);
        formik.setFieldValue('editStaffCode', updatedStaff.editStaffCode);
        formik.setFieldValue('employmentDataList', updatedStaff.employmentDataList);
        formik.setFieldValue('employmentId', updatedStaff.employmentId);
        formik.setFieldValue('empApplyDate', updatedStaff.empApplyDate);
       }
      successNotification(isEditMode ? '更新しました。' : '登録しました。');
      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      setIsLoading!(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStaffDomain]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    listICCardByStaff,
    setListICCardByStaff,
    contextObject
  };
};

export default useStaffAddForm;
