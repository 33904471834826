import { useEffect, useCallback, useState } from 'react';
import { getKaorioHistory } from 'api/stampHistory';
import StampHistoryListRecorderDomain from 'domain/timeRecorder/stampHistoryListRecorderDomain';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

const useKaioriHistory = (orgCode: string, staffCode: string, targetDateFrom: string, targetDateTo: string) => {
  const [kaioriHistory, setKaioriHistory] = useState<Array<StampHistoryListRecorderDomain>>([]);

  const [blocking, setBlocking] = useState(false);
  const [underFlag, setUnderFlag] = useState(0);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.kaoiroHistory);

  useEffect(() => {
    sessionStorage.setItem('isDisplayTemperature', String(detailRole.usabilityGeneralItem1 ? true : false));
    sessionStorage.setItem('isDisplayTemperatureStatus', String(detailRole.usabilityGeneralItem2 ? true : false));
  },[detailRole])
  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await getKaorioHistory(companyCode, orgCode, staffCode, targetDateFrom, targetDateTo, underFlag); 
    setKaioriHistory(response.map((result: any) => new StampHistoryListRecorderDomain(result)));
    setBlocking(false);
  }, [orgCode, staffCode, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, underFlag]);

  return { kaioriHistory, setKaioriHistory, blocking, underFlag, setUnderFlag };
};

export default useKaioriHistory;
