import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import PaymentMediaOrganizationDomain from 'domain/master/sales/paymentMediaOrganization';
import getListPaymentMediaOrganization, { createOrUpdatePaymentMediaOrganization } from 'api/paymentMediaOrganization';
import useToastNotification from 'hooks/useToastNotification';

type PaymentMediaOrganizationDomainKey = keyof Pick<PaymentMediaOrganizationDomain,
                                                      'paymentMediaCode'|'postType'|'importCode'>;

export const usePaymentImportForm = (orgCode?: string, importCode?: string, postType?: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const history = useHistory();

  const onSubmit = async (val: any) => {
    if (val.paymentMediaCode === undefined || val.paymentMediaCode === null) {
      errorNotification('支払メディアを入力してください。');
      return;
    }
    if (val.postType === undefined || val.postType === null) {
      errorNotification('POS種別を入力してください。');
      return;
    }
    if (val.importCode === undefined || val.importCode === null) {
      errorNotification('取込コードを入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    if (val.applicableEndMonthStr === '-') {
      setConfirmModalOpen(false);
      errorNotification('適用終了月を指定するか、または、未定をチェックしてください。');
      return;
    }
    const submitObj = {
      paymentMediaMstCode: val.paymentMediaCode,
      id: {
        companyCode,
        orgCode,
        posType: String(val.postType),
        paymentMediaCode: val.importCode,
      },
      applyStartDayStr: val.applicableStartMonthStr,
      applyEndDayStr: '',
      dispOrder: 1,
      createUser: staffName,
      updateUser: staffName,
    };
    if (val.applicableEndMonthStr !== '-' && val.applicableEndMonthStr !== '9999年12月') {
      submitObj.applyEndDayStr = val.applicableEndMonthStr;
    }

    createOrUpdatePaymentMediaOrganization(!importCode, submitObj)
      .then((response: any) => {
        successNotification('登録しました。');
        history.push('/salesPaymentMedia/paymentSetting');
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors.count !== 0) {
          errorNotification(error.response.data.errors[0].defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: PaymentMediaOrganizationDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (orgCode !== undefined && postType !== undefined && importCode !== undefined) {
      getListPaymentMediaOrganization(orgCode).then((response: Array<any>) => {
        response.filter((item) => {
          const parseItem = new PaymentMediaOrganizationDomain(item);
          if (Number(parseItem.postType) === Number(postType)
                && parseItem.importCode === importCode) {
            formik.setValues(parseItem);
            return true;
          }
          return false;
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, postType, importCode]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    history,
  };
};

export default usePaymentImportForm;
