import React from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import ManagerEdit from 'components/organismos/ManagerEdit';
import BodyText from 'components/atoms/BodyText';
import Table from 'components/molecules/Table';
import ManagerDomain from 'domain/manager';
import { css } from '@emotion/core';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormTitle from 'components/atoms/Form/FormTitle';

const OrganizationManagerList: React.FC<{
  prefix: string;
  managerList: Array<ManagerDomain>|null;
  deleteManager: (index: number) => () => void;
  setManager: (index: number) => (manager: ManagerDomain) => void;
  openModal: () => void;
}> = ({
   managerList, deleteManager, setManager, openModal,
}) => (
  <FormContents customStyle={{border: 'none'}}>
    <FormTitle title="マネージャー管理情報" customStyle={css({ fontSize: '14px' })}/>
    <Table className='table-no-border-left-right' customStyle={css({width: '100%', marginBottom: '10px'})}>
      <thead>
        <tr>
          <Table.HeaderCell customStyle={css({ width: '100px' })}></Table.HeaderCell>
          <Table.HeaderCell customStyle={css({ width: '180px', textAlign: 'left' })}>
            スタッフコード
          </Table.HeaderCell>
          <Table.HeaderCell customStyle={css({ width: '180px', textAlign: 'left' })}>
            スタッフ名
          </Table.HeaderCell>
          <Table.HeaderCell customStyle={css({ textAlign: 'left' })}>
            期間
          </Table.HeaderCell>
        </tr>
      </thead>
      <tbody>
        {managerList ? managerList.map((manager, index) => (
            <ManagerEdit
              key={JSON.stringify(manager)}
              manager={manager}
              deleteManager={deleteManager(index)}
              setManager={setManager(index)}
            />
        )) : <BodyText>Loading...</BodyText>}
      </tbody>
    </Table>

    <FlexBox>
      <FlexBoxItem>
        <IconLabelButton onClick={openModal} iconType="addCircle" text="追加"/>
      </FlexBoxItem>
    </FlexBox>
  </FormContents>
);

export default OrganizationManagerList;
