/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useContext } from "react";
import { grayScale } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import { SubActionButton } from "components/atoms/Button";
import { css, jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import moment from "moment";
import { ActionButton } from "components/atoms/Button/SubActionButton";
import { useHistory } from "react-router-dom";
import ApplyingHolidayDomain from "../domain/applyingHolidayDomain";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import IconLabelButton from "components/molecules/IconLabelButton";
import Modal from "components/molecules/Modal";
import { useApplyingTransferForm } from "./hook";
import { AttendApplicationListContext } from "../AttendApplicationListV3";

const HolidayAccordion: React.FC<{
  list: ApplyingHolidayDomain[];
  setApplyingHolidayList: any;
}> = ({ list, setApplyingHolidayList }) => {
  const history = useHistory();
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLogHoliday,
  } = useApplyingTransferForm(list);
  const { detailRole } = useContext(AttendApplicationListContext);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "decision",
        accessor: "decision",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
            {detailRole.editable === 1 && (
              <SubActionButton
                text="編集"
                onClick={() => {
                  sessionStorage.setItem(
                    "attendStampList.targetDateFrom",
                    moment(cell.row.original.stampListStartTime).format("YYYY-MM-DD"),
                  );
                  sessionStorage.setItem(
                    "attendStampList.targetDateTo",
                    moment(cell.row.original.stampListEndTime).format("YYYY-MM-DD"),
                  );
                  sessionStorage.setItem("attendStampList.orgCode", String(cell.row.original.orgCode));
                  sessionStorage.setItem("attendStampList.staffCode", String(cell.row.original.staffCode));

                  sessionStorage.setItem("application.achievementId", cell.row.original.achievementId || "");
                  sessionStorage.setItem(
                    "application.stampVersion",
                    cell.row.original.achievementId.stampVersion || "",
                  );
                  sessionStorage.setItem(
                    "application.modifierApplicationId",
                    cell.row.original.modifierApplicationId || "",
                  );

                  sessionStorage.setItem("application.orgCode", String(cell.row.original.orgCode));
                  sessionStorage.setItem("application.staffCode", String(cell.row.original.staffCode));

                  sessionStorage.setItem(
                    "application.dispStaffName",
                    `${cell.row.original.staffCode} ${cell.row.original.staffName}`,
                  );
                  sessionStorage.setItem(
                    "application.targetDate",
                    moment(cell.row.original.targetDate).format("YYYY-MM-DD"),
                  );

                  sessionStorage.setItem("application.returnDestination", window.location.pathname);
                  sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);

                  history.push("attendApplicationList/attendHolidayApplication");
                }}
                action={ActionButton.EDIT}
              />
            )}
          </FlexBox>
        ),
        disableSortBy: true,
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: (any: any) => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <div>
                <div>{cell.row.original.targetDate}</div>
                <IconLabelButton
                  onClick={() => {
                    fetchChangeLogHoliday(cell.row.original.modifierApplicationId);
                    setChangeLogModalOpen(true);
                    setSelectChangelog("0");
                  }}
                  text="更新履歴"
                  textAlign="center"
                  isIcon={false}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "休暇",
        accessor: "holidayName",
      },
      {
        Header: "休暇単位",
        accessor: "holidayDigestiveUnitName",
      },
      {
        Header: "開始時間",
        accessor: "holidayStartHm",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {(() => {
              if (
                (cell.row.original.holidayDigestiveUnit == 2 ||
                  cell.row.original.holidayDigestiveUnit == 3 ||
                  cell.row.original.holidayDigestiveUnit == 4) &&
                cell.row.original.holidayStartHm
              ) {
                if (
                  moment(cell.row.original.holidayStartHm).format("YYYYMMDD") !==
                  moment(cell.row.original.targetDate).format("YYYYMMDD")
                ) {
                  return `${moment(cell.row.original.holidayStartHm).format("HH:mm")}(翌)`;
                }
                return moment(cell.row.original.holidayStartHm).format("HH:mm");
              }
              return "";
            })()}
          </div>
        ),
      },
      {
        Header: "終了時間",
        accessor: "holidayEndHm",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {(() => {
              if (
                (cell.row.original.holidayDigestiveUnit == 2 ||
                  cell.row.original.holidayDigestiveUnit == 3 ||
                  cell.row.original.holidayDigestiveUnit == 4) &&
                cell.row.original.holidayEndHm
              ) {
                if (
                  moment(cell.row.original.holidayEndHm).format("YYYYMMDD") !==
                  moment(cell.row.original.targetDate).format("YYYYMMDD")
                ) {
                  return `${moment(cell.row.original.holidayEndHm).format("HH:mm")}(翌)`;
                }
                return moment(cell.row.original.holidayEndHm).format("HH:mm");
              }
              return "";
            })()}
          </div>
        ),
      },
      {
        Header: "取得時間",
        accessor: "holidayTimes",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {cell.row.original.holidayDigestiveUnit === 2 &&
              cell.row.original.holidayTimes &&
              (Number.isInteger(cell.row.original.holidayTimes)
                ? `${cell.row.original.holidayTimes}時間`
                : `${parseFloat(`${cell.row.original.holidayTimes}`).toFixed(1)}時間`)}
          </div>
        ),
      },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, fetchChangeLogHoliday, setChangeLogModalOpen, setSelectChangelog, detailRole.editable],
  );

  return (
    <React.Fragment>
      <div
        css={css`
          table th:nth-of-type(1) {
            width: 50px;
          }
          table th:nth-of-type(2) {
            width: 140px;
          }
          table th:nth-of-type(3) {
            width: 140px;
          }
          table th:nth-of-type(4) {
            width: 140px;
          }
          table th:nth-of-type(5) {
            width: 140px;
          }
          table th:nth-of-type(6) {
            width: 80px;
          }
          table th:nth-of-type(7) {
            width: 80px;
          }
          table th:nth-of-type(8) {
            width: 80px;
          }
          table th:nth-of-type(9) {
            width: 140px;
          }
          table th:nth-of-type(10) {
            width: 140px;
          }
          table th:nth-of-type(11) {
            width: 80px;
          }
          table th:nth-of-type(12) {
            width: 200px;
          }
        `}
      >
        <Accordion text={`休暇申請 (${list.length}件）`} callBack={() => {}}>
          <DataTable
            columns={columns}
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            data={list}
            isGlobalFilter={true}
            sortBy={[{ id: "targetDate", desc: true }]}
          />
        </Accordion>
      </div>
      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} isStamp={true} request="休暇申請" />
        </Modal>
      </div>
      {/* ./Change log  */}
    </React.Fragment>
  );
};

export default HolidayAccordion;
