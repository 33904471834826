import React from "react";

import FormContents from "components/atoms/Form/FormContents";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import PrimaryButton from "components/atoms/Button";
import { Row, Col } from "react-grid-system";
import FormPadding from "components/atoms/Form/FormPadding";
import TabControl from "components/atoms/TabControl";
import Warning from "components/atoms/Icon/Warning";
import BlockUI from "components/molecules/BlockUi";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import { useOrganizationCsvForm } from "./hooks";
import { css } from "@emotion/core";
import DataTable from "components/organismos/DataTable/DataTable";
import moment from "moment";
import { grayScale } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import FlexBox from "components/atoms/FlexBox";
import ImportCSVForm from "components/molecules/ImportCSVForm";
import { useHistory } from "react-router-dom";
import FormField from "components/atoms/Form/FormField";
import RadioSelector from "components/molecules/RadioSelector";

export type UploadType = "organization" | "manager" | "specialday";

type SelectType = "csv" | "csvHistory";
const SelectType: { [key in SelectType]: SelectType } = {
  csv: "csv",
  csvHistory: "csvHistory",
};

const styles = {
  table: css`
    table th:nth-of-type(1) {
      width: 90px;
    }
    table th:nth-of-type(2) {
      width: 100px;
    }
    table th:nth-of-type(3) {
      width: 100px;
    }
    table th:nth-of-type(4) {
      width: 100px;
    }
    table th:nth-of-type(5) {
      width: 100px;
    }
    table th:nth-of-type(6) {
      width: 140px;
    }
    .svg-red path:last-child {
      fill: red;
    }
  `,
  noteLabel: css({
    justifyContent: "center",
  }),
};

const OrganizationCsvAddForm: React.FC = () => {
  const {
    uploadFile,
    setUploadFile,
    uploadType,
    uploadTypeItems,
    setUploadType,
    onSubmit,
    errors,
    blocking,
    confirmModalOpen,
    closeConfirmModal,
    importHistory,
  } = useOrganizationCsvForm();
  const history = useHistory();

  const [selectType, setSelectType] = React.useState<SelectType>(SelectType.csv);
  const columns = React.useMemo(
    () => [
      {
        Header: () => "データ種別",
        id: "formatType",
        accessor: "formatType",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            {cell.row.original.formatType === 0
              ? "組織情報"
              : cell.row.original.formatType === 1
              ? "マネージャー権限"
              : "特賃日割当情報"}
          </div>
        ),
      },
      {
        Header: "ファイル名",
        accessor: "importFileName",
      },
      {
        Header: () => "処理結果",
        id: "result",
        accessor: "result",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "center" }} className={`svg-${cell.row.original.result === 1 && "red"}`}>
            {cell.row.original.result === 1 && <Warning />}
            <span style={{ marginTop: "-3px", marginLeft: "3px" }}>
              {cell.row.original.result === 1 ? "失敗" : "成功"}
            </span>
          </div>
        ),
      },
      {
        Header: "エラー内容",
        accessor: "errDetailDisp",
      },
      {
        Header: "実施日時",
        accessor: "updateDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div>
            {moment(cell.row.original.updateDate).format("YYYY/MM/DD")} <br />
            <span style={{ color: grayScale.gray50 }}>{moment(cell.row.original.updateDate).format("H:m:s")}</span>
          </div>
        ),
      },
      {
        Header: "実施者",
        accessor: "updateUser",
      },
    ],
    [],
  );

  return (
    <BlockUI blocking={blocking}>
      <FormContents>
        <FormTitle bold={true} title="組織インポート" />
        <FormPadding customStyle={{ padding: "0 30px" }}>
          <Row>
            <Col md={4} xs={12}>
              <TabControl
                items={[
                  {
                    value: SelectType.csv,
                    display: "インポート",
                  },
                  {
                    value: SelectType.csvHistory,
                    display: "履歴参照",
                  },
                ]}
                setValue={setSelectType as (arg: string) => void}
                currentValue={selectType}
                isFixed={false}
                customStyle={css({ margin: "10px 30px 10px 0" })}
              />
            </Col>
          </Row>
        </FormPadding>
        {selectType === SelectType.csv && (
          <FormPadding customStyle={{ padding: "0 30px" }}>
            <FormField>
              <RadioSelector
                name="uploadType"
                items={uploadTypeItems}
                value={uploadType}
                setValue={(e) => setUploadType(e.target.value as UploadType)}
              />
            </FormField>
            <ImportCSVForm uploadFile={uploadFile} setUploadFile={setUploadFile} errors={errors} />

            <FormSubmitArea customStyle={css({ textAlign: "center", margin: "30px 0" })}>
              <FlexBox justifyContent="center">
                <PrimaryButton text="インポート" onClick={onSubmit} disabled={!uploadFile} />
              </FlexBox>
              <FlexBox justifyContent="center">
                <PrimaryButton onClick={() => history.push("/organizations")} text="キャンセル" ghost={true} />
              </FlexBox>
            </FormSubmitArea>
          </FormPadding>
        )}
        {selectType === SelectType.csvHistory && (
          <div style={{ overflowX: "auto", whiteSpace: "nowrap", marginTop: "15px" }}>
            <div css={styles.table}>
              <DataTable
                iconSize="16px"
                className="table-no-border-left-right"
                globalSearchStyle={css({ marginLeft: "20px" })}
                columns={columns}
                data={importHistory}
                isGlobalFilter={true}
                minWidth="1600px"
              />
            </div>
          </div>
        )}
      </FormContents>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={onSubmit}
        actionType={ActionType.IMPORT}
      />
    </BlockUI>
  );
};

export default OrganizationCsvAddForm;
