/** @jsx jsx */
import React, { ReactNode, useEffect, useState } from "react";
import * as RadixPopover from "@radix-ui/react-popover";
import { css, jsx, keyframes } from "@emotion/core";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

// PopoverContentのスタイル
const popoverContentStyles = css({
  borderRadius: 4,
  border: "1px solid #007BC3",
  padding: 8,
  width: 250,
  backgroundColor: "white",
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  animationDuration: "400ms",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  willChange: "transform, opacity",
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: `${slideDownAndFade}` },
    '&[data-side="right"]': { animationName: `${slideLeftAndFade}` },
    '&[data-side="bottom"]': { animationName: `${slideUpAndFade}` },
    '&[data-side="left"]': { animationName: `${slideRightAndFade}` },
  },
});

// PopoverArrowのスタイル
const popoverArrowStyles = css({
  fill: "#007BC3",
  left: 8,
  position: "absolute",
});

interface PopoverProps {
  children: ReactNode;
  trigger: ReactNode;
}

export const Popover: React.FC<PopoverProps> = ({ children, trigger }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // スクロールしたらPopoverを閉じる
      setOpen(false);
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <RadixPopover.Root open={open} onOpenChange={setOpen}>
      <RadixPopover.Trigger id="trigger-element-id" asChild>
        {trigger}
      </RadixPopover.Trigger>
      <RadixPopover.PopoverContent side="top" css={popoverContentStyles} sideOffset={5}>
        <div css={{ display: "flex", flexDirection: "column" }}>{children}</div>
        <RadixPopover.PopoverArrow css={popoverArrowStyles} />
      </RadixPopover.PopoverContent>
    </RadixPopover.Root>
  );
};
