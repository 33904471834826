import React from "react";
import { utilityColor } from "components/styles";

const MobileContainer: React.FC = ({ children }) => (
  <div
    style={{
      backgroundColor: utilityColor.white,
      padding: "0.5rem",
      position: "relative",
    }}
  >
    {children}
  </div>
);

export default MobileContainer;
