export const types = {
  SET_LOGIN_USER: 'auth/SET_LOGIN_USER' as const,
};

export const setLoginUser = (payload: any) => ({
  type: types.SET_LOGIN_USER,
  payload,
});

type Actions = (
    | ReturnType<typeof setLoginUser>
);

export default Actions;
