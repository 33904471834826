import ApiClient from './ApiClient';

export const getStampList = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendModifier/stampList/${companyCode}`, {}, params);
  return response.data;
};

export const getAttendWorkPlanApplication = async (orgCode: any, targetDate: any, staffCode: any, shiftId:any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    shiftId,
    orgCode,
    targetDate,
    staffCode
  }
  const response = await ApiClient.get(`/v1/attendWorkPlan/workPlan/${companyCode}`, {}, params);
  return response.data;
};

export const createOrUpdateStampModification = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v2/attendModifier/stamp/${companyCode}`, {}, params);
  return response.data;
};

export const createOrUpdateStampWorkScheduleChangeApplication = async (params: any) => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.post(`/v1/attendWorkPlan/workPlan/${companyCode}`, {}, params);
  return response.data;
};

export const getStampHistoryListAchievementId = async (achievementId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/attendModifier/stampHistoryList/${companyCode}?achievementId=${achievementId}`, {});
  return response.data;
}

export const getReasonList = async (applicationType: number): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/applicationReason/list/${companyCode}?applicationType=${applicationType}`, {});
  return response.data;
}