import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendAllowanceCreateForm from 'components/organismos/master/labor/AttendAllowanceCreateForm';

const AttendAllowanceCreatePage: React.FC = () => {
  return (
    <SidebarTemplate>
      <AttendAllowanceCreateForm />
    </SidebarTemplate>
  );
};
export default AttendAllowanceCreatePage;
