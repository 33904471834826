import reducer from './reducer';
import * as loginUserActions from './actions';

export interface LoginUserState {
  loginUser: any
}

export {
  loginUserActions,
};

export default reducer;
