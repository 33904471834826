import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PreventClickOnDrag from '../interaction/PreventClickOnDrag'

class GroupRow extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func.isRequired,
    isEvenRow: PropTypes.bool.isRequired,
    style: PropTypes.object.isRequired,
    clickTolerance: PropTypes.number.isRequired,
    group: PropTypes.object.isRequired,
    horizontalLineClassNamesForGroup: PropTypes.func,
    rowNumber: PropTypes.number.isRequired
  }

  render() {
    const {
      onContextMenu,
      onDoubleClick,
      isEvenRow,
      style,
      onClick,
      clickTolerance,
      horizontalLineClassNamesForGroup,
      group,
      rowNumber
    } = this.props

    let classNamesForGroup = [];
    if (horizontalLineClassNamesForGroup) {
      classNamesForGroup = horizontalLineClassNamesForGroup(group);
    }
 
    return (
      <PreventClickOnDrag clickTolerance={clickTolerance} onClick={onClick}>
        {/* <div
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            className={(isEvenRow ? 'rct-hl-even ' : 'rct-hl-odd ') + (classNamesForGroup ? classNamesForGroup.join(' ') : '')}
            style={style}
          /> */}
        <div id={'dailyShift_row_' + (rowNumber + 1)}>
          <div
            rowid={1}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            className={(isEvenRow ? 'rct-hl-even ' : 'rct-hl-odd ') + (classNamesForGroup ? classNamesForGroup.join(' ') : '')}
            style={style}
          />
          <div
            rowid={2}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            className={(isEvenRow ? 'rct-hl-even ' : 'rct-hl-odd ') + (classNamesForGroup ? classNamesForGroup.join(' ') : '') + (group.isShiftConfirm || group.isAchievementConfirm || [0, 2, 3].includes(group.dateInfo[0].workSystem) || group.dateInfo[0].isAchievementConfirm ? '' : 'cursor-pointer on-hover')}
            style={style}
          />
          <div
            rowid={3}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
            className={(isEvenRow ? 'rct-hl-even ' : 'rct-hl-odd ') + (classNamesForGroup ? classNamesForGroup.join(' ') : '') +  ((group.isAchievementConfirm || group.dateInfo[0].isAchievementConfirm) ? '' : 'cursor-pointer on-hover')}
            style={style}
          />
        </div>
      </PreventClickOnDrag>
    )
  }
}

export default GroupRow
