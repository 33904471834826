/**
 * APIのデータ型
 */

export interface SpecialSalaryPattern {
  specialSalaryPatternId: string;
  specialSalaryPatternCode: string;
  specialSalaryPatternName: string;
  sameDays: any;
  fixedDays: any;
  patOrgList: any[];
  holidayFlag: boolean;
  beforeHolidayFlag: boolean;
  saturdayFlag: boolean;
  sundayFlag: boolean;
  mondayFlag: boolean;
  tuesdayFlag: boolean;
  wednesdayFlag: boolean;
  thursdayFlag: boolean;
  fridayFlag: boolean;
  createUser: string;
  updateUser: string;
}

export default class SpecialSalaryPatternDomain {
  constructor(private rawData: SpecialSalaryPattern) {
    // Todo
  }

  static generateInitial(): SpecialSalaryPatternDomain {
    return new SpecialSalaryPatternDomain({
      specialSalaryPatternId: "",
      specialSalaryPatternCode: "",
      specialSalaryPatternName: "",
      sameDays: [],
      fixedDays: [],
      holidayFlag: false,
      beforeHolidayFlag: false,
      saturdayFlag: false,
      sundayFlag: false,
      mondayFlag: false,
      tuesdayFlag: false,
      wednesdayFlag: false,
      thursdayFlag: false,
      fridayFlag: false,
      patOrgList: [],
      createUser: "",
      updateUser: "",
    });
  }

  getRawData(): SpecialSalaryPattern {
    return this.rawData;
  }

  getRawDataPost(): SpecialSalaryPattern {
    return {
      specialSalaryPatternId: this.rawData.specialSalaryPatternId,
      specialSalaryPatternCode: this.rawData.specialSalaryPatternCode,
      specialSalaryPatternName: this.rawData.specialSalaryPatternName,
      sameDays: this.rawData.sameDays,
      fixedDays: this.rawData.fixedDays,
      patOrgList: this.rawData.patOrgList,
      holidayFlag: this.rawData.holidayFlag,
      beforeHolidayFlag: this.rawData.beforeHolidayFlag,
      saturdayFlag: this.rawData.saturdayFlag,
      sundayFlag: this.rawData.sundayFlag,
      mondayFlag: this.rawData.mondayFlag,
      tuesdayFlag: this.rawData.tuesdayFlag,
      wednesdayFlag: this.rawData.wednesdayFlag,
      thursdayFlag: this.rawData.thursdayFlag,
      fridayFlag: this.rawData.fridayFlag,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  getRawDataSetting(): SpecialSalaryPattern {
    return {
      specialSalaryPatternId: this.rawData.specialSalaryPatternId,
      specialSalaryPatternCode: this.rawData.specialSalaryPatternCode,
      specialSalaryPatternName: this.rawData.specialSalaryPatternName,
      sameDays: this.rawData.sameDays,
      fixedDays: this.rawData.fixedDays,
      patOrgList: this.rawData.patOrgList,
      holidayFlag: this.rawData.holidayFlag,
      beforeHolidayFlag: this.rawData.beforeHolidayFlag,
      saturdayFlag: this.rawData.saturdayFlag,
      sundayFlag: this.rawData.sundayFlag,
      mondayFlag: this.rawData.mondayFlag,
      tuesdayFlag: this.rawData.tuesdayFlag,
      wednesdayFlag: this.rawData.wednesdayFlag,
      thursdayFlag: this.rawData.thursdayFlag,
      fridayFlag: this.rawData.fridayFlag,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
    };
  }

  get specialSalaryPatternCode(): string {
    return this.rawData.specialSalaryPatternCode;
  }

  set specialSalaryPatternCode(specialSalaryPatternCode: string) {
    this.rawData.specialSalaryPatternCode = specialSalaryPatternCode;
  }

  get specialSalaryPatternName(): string {
    return this.rawData.specialSalaryPatternName;
  }

  set specialSalaryPatternName(specialSalaryPatternName: string) {
    this.rawData.specialSalaryPatternName = specialSalaryPatternName;
  }

  get beforeHolidayFlag(): boolean {
    return this.rawData.beforeHolidayFlag;
  }

  set beforeHolidayFlag(beforeHolidayFlag: boolean) {
    this.rawData.beforeHolidayFlag = beforeHolidayFlag;
  }

  get holidayFlag(): boolean {
    return this.rawData.holidayFlag;
  }

  set holidayFlag(holidayFlag: boolean) {
    this.rawData.holidayFlag = holidayFlag;
  }

  get saturdayFlag(): boolean {
    return this.rawData.saturdayFlag;
  }

  set saturdayFlag(saturdayFlag: boolean) {
    this.rawData.saturdayFlag = saturdayFlag;
  }

  get sundayFlag(): boolean {
    return this.rawData.sundayFlag;
  }

  set sundayFlag(sundayFlag: boolean) {
    this.rawData.sundayFlag = sundayFlag;
  }

  get mondayFlag(): boolean {
    return this.rawData.mondayFlag;
  }

  set mondayFlag(mondayFlag: boolean) {
    this.rawData.mondayFlag = mondayFlag;
  }

  get tuesdayFlag(): boolean {
    return this.rawData.tuesdayFlag;
  }

  set tuesdayFlag(tuesdayFlag: boolean) {
    this.rawData.tuesdayFlag = tuesdayFlag;
  }

  get wednesdayFlag(): boolean {
    return this.rawData.wednesdayFlag;
  }

  set wednesdayFlag(wednesdayFlag: boolean) {
    this.rawData.wednesdayFlag = wednesdayFlag;
  }

  get thursdayFlag(): boolean {
    return this.rawData.thursdayFlag;
  }

  set thursdayFlag(thursdayFlag: boolean) {
    this.rawData.thursdayFlag = thursdayFlag;
  }

  get fridayFlag(): boolean {
    return this.rawData.fridayFlag;
  }

  set fridayFlag(fridayFlag: boolean) {
    this.rawData.fridayFlag = fridayFlag;
  }

  get sameDays(): any {
    return this.rawData.sameDays;
  }

  set sameDays(sameDays) {
    this.rawData.sameDays = sameDays;
  }

  get fixedDays(): any {
    return this.rawData.fixedDays;
  }

  set fixedDays(fixedDays) {
    this.rawData.fixedDays = fixedDays;
  }

  get patOrgList(): any {
    return this.rawData.patOrgList;
  }

  set patOrgList(patOrgList: any) {
    this.rawData.patOrgList = patOrgList;
  }
}
