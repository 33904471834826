/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { getStampList, createOrUpdateStampWorkScheduleChangeApplication, getStampHistoryListAchievementId, getAttendWorkPlanApplication } from 'api/stampModification';
import { OptionType } from 'components/atoms/Select';
import AttendStampModificationDomain, { ShiftPatternDetailInput } from 'domain/master/attend/attendStampModification';
import { useHistory } from 'react-router-dom';
import { useOrgAllSelect } from 'hooks/useOrgTreesOptions';
import { getBusinessAttendChoice } from 'api/businessMaster';
import { getRoundTimeList } from 'api/employment';
import moment from 'moment';
import { useFormik } from 'formik';
import useToastNotification from 'hooks/useToastNotification';
import { getTreeCurrentByStaff, getTreesOrgCode, getTreesOrgCodeNoRole } from 'api/organization';

type StampModificationKey = keyof Pick<AttendStampModificationDomain, 'applicationReason'>;

export const useStampWorkSchedule = () => {
    const history = useHistory();

    let ssAchievementId: any = '';
    if (sessionStorage.getItem('application.achievementId') && sessionStorage.getItem('application.achievementId') != 'null') {
        ssAchievementId = sessionStorage.getItem('application.achievementId');
    }
    let ssModifierApplicationId: any = '';
    if (sessionStorage.getItem('application.modifierApplicationId') && sessionStorage.getItem('application.modifierApplicationId') != 'null') {
        ssModifierApplicationId = sessionStorage.getItem('application.modifierApplicationId');
    }

    let ssVersion: any = '';
    if (sessionStorage.getItem('application.stampVersion') && sessionStorage.getItem('application.stampVersion') != 'null') {
        ssVersion = sessionStorage.getItem('application.stampVersion');
    }

    let ssTargetDate = sessionStorage.getItem('application.targetDate');
    if (ssTargetDate) {
        ssTargetDate = ssTargetDate.substr(0, 10);
    }

    const [achievementId] = useState(ssAchievementId || '');
    const [modifierApplicationId] = useState(ssModifierApplicationId || '');
    const [staffCode] = useState(sessionStorage.getItem('application.staffCode') || '');
    const [targetDate] = useState(ssTargetDate || '');
    const [staffName] = useState(sessionStorage.getItem('application.dispStaffName') || '');
    const [version] = useState(ssVersion || '');
    const [orgCode, setOrgCode] = useState(sessionStorage.getItem('application.orgCode') || '');
    const [shiftId] = useState(sessionStorage.getItem('application.shiftId') || '');
    const [toastMessage, setToastMessage] = useState('');
    const { successNotification, errorNotification } = useToastNotification();
    const [toastModalOpen, setToastModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [stampList, setStampList] = useState<Array<ShiftPatternDetailInput>>([]);
    const [attendBusinessOptions, setAttendBusinessOptions] = useState<Array<OptionType>>([]);
    const [listTimes, setListTimes] = useState([]);
    const [stampHistoryListAchievement, setStampHistoryListAchievement] = useState([]);
    const [selectChangelog, setSelectChangelog] = useState<string>("0");

    // get OrgCode options
    const targetDateFrom = moment(`${targetDate}`).format('YYYY-MM-DD');
    const targetDateTo = moment(`${targetDate}`).format('YYYY-MM-DD');
    const orgTreesOptions = useOrgAllSelect(false, targetDateFrom, targetDateTo);


    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
      setLoading(true);

      const targetDateStr = moment(`${targetDate}`, 'YYYY-MM-DD').format('YYYY/MM/DD');
      handleGetBusinessAttendChoice(staffCode, targetDateStr);

      const employmentId = '';
      handleGetRoundTimeList(employmentId, targetDate, staffCode);

      // getStampList
      try {
          getAttendWorkPlanApplication(orgCode, targetDate, staffCode, shiftId).then((response: any) => {
              const { detailList, historyList } = response;
              if (response && detailList.length) {
                  for (const item in response) {
                      if (response[item] === null) {
                          response[item] = '';
                      }
                  }
                  formik.setValues(new AttendStampModificationDomain(response));
                  
                  if (detailList.length) {
                      if (detailList[0].shiftId === null) {
                          stampList.map((shiftPatternDetail: ShiftPatternDetailInput) => {
                              if (shiftPatternDetail.startTime === null && shiftPatternDetail.endTime === null) {
                                  shiftPatternDetail.startTime = '00:00';
                                  shiftPatternDetail.endTime = '00:00';
                              } else {
                                  shiftPatternDetail.startTime = moment(shiftPatternDetail.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                                  shiftPatternDetail.endTime = moment(shiftPatternDetail.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                              }
                          });
                          setStampList(detailList);
                      } else {
                          setStampList(detailList.map((shiftPatternDetail: ShiftPatternDetailInput) => (shiftPatternDetail)));
                      }
                  } 
                  // Hide loading pin
                  setLoading(false);
              }

              if(historyList.length) {
                  setStampHistoryListAchievement(response.historyList);
              }
          });
      } catch (error) {
          setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (isReloader || orgCode) {
  //     setLoading(true);
  //     const targetDateStr = moment(`${targetDate}`, 'YYYY-MM-DD').format('YYYY/MM/DD');
  //     handleGetBusinessAttendChoice(staffCode, targetDateStr);
  //     const employmentId = '';
  //     handleGetRoundTimeList(employmentId, targetDate, staffCode);
      
  //     try {
  //       getAttendWorkPlanApplication(orgCode, targetDate, staffCode, shiftId).then((response: any) => {
  //         const { detailList, historyList } = response;
  //         if (response && detailList.length) {
  //           for (const item in response) {
  //             if (response[item] === null) {
  //               response[item] = '';
  //             }
  //           }
  //           formik.setValues(new AttendStampModificationDomain(response));

  //           if (detailList.length) {
  //             if (detailList[0].shiftId === null) {
  //               stampList.map((shiftPatternDetail: ShiftPatternDetailInput) => {
  //                 if (shiftPatternDetail.startTime === null && shiftPatternDetail.endTime === null) {
  //                   shiftPatternDetail.startTime = '00:00';
  //                   shiftPatternDetail.endTime = '00:00';
  //                 } else {
  //                   shiftPatternDetail.startTime = moment(shiftPatternDetail.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
  //                   shiftPatternDetail.endTime = moment(shiftPatternDetail.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
  //                 }
  //               });
  //               setStampList(detailList);
  //             } else {
  //               setStampList(detailList.map((shiftPatternDetail: ShiftPatternDetailInput) => (shiftPatternDetail)));
  //             }
  //           }
  //           // Hide loading pin
  //           setLoading(false);
  //         }

  //         if (historyList.length) {
  //           setStampHistoryListAchievement(response.historyList);
  //         }
  //       });
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //     setIsReloader(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orgCode, isReloader]);

  const handleGetBusinessAttendChoice = async (staffCode: any, targetDate: any) => {
    // await getBusinessAttendChoice(staffCode, targetDate).then((response: any) => {
    //     if (response && response.length) {
    //         let tmpAttendBusiness = response.map((business: any) => ({
    //             value: (business.businessId) === null ? '' : business.businessId,
    //             label: business.businessName === null ? '' : business.businessName,
    //         }));
    //         if (response.length <= 1) {
    //             tmpAttendBusiness = [
    //                 {
    //                     value: ' ',
    //                     label: '',
    //                 },
    //                 ...tmpAttendBusiness,
    //             ];
    //         }
    //         setAttendBusinessOptions(tmpAttendBusiness);
    //     }
    // });
    setAttendBusinessOptions([]);
  };

  const handleGetRoundTimeList = async (employmentId: string, targetDateStr: string, staffCode: string, isUpdate?: boolean) => {
    await getRoundTimeList(employmentId, targetDateStr, staffCode).then((response: any) => {
      if (response && response.roundTimeList) {
        setListTimes(response.roundTimeList);
      }
    });
  };

  const onSubmit = async (values: AttendStampModificationDomain) => {
    if (values.applicationReason === null) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const {
      staffCode, orgName, targetDate, version, buttonType, applicationReason, shiftId
    } = values.getRawData();

    const loggedInUser = sessionStorage.getItem('loginUser.staffCode') || '';
    const modifierApplicationId = sessionStorage.getItem('application.modifierApplicationId') || '';

    let dataPost = {
      modifierApplicationId: modifierApplicationId,
      shiftId,
      orgCode,
      targetDate: moment(targetDate).format("YYYY/MM/DD"),
      staffCode,
      version: version || '',
      applicationReason,
      buttonType,
      loginUserCode: loggedInUser,
    };

    // handel StampList
    let countBreak = 0;
    stampList.forEach((item: any, index: number) => {
      let startTimeStr: any = moment(targetDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
      let endTimeStr: any = moment(targetDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");

      if (item.isStartTimeNextDay === true) {
        startTimeStr = moment(`${targetDate}`, 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD');
      }

      if (item.isEndTimeNextDay === true) {
        endTimeStr = moment(`${targetDate}`, 'YYYY-MM-DD').add('days', 1).format('YYYY-MM-DD');
      }

      if (item.businessId) {
        countBreak += 1;
      }

      delete (item as any).createUser;
      delete (item as any).updateUser;
      delete (item as any).shiftPatternDetailId;
      delete (item as any).shiftPatternId;
      delete (item as any).version;
      delete (item as any).businessName;
      delete (item as any).updateDate;
      delete (item as any).createDate;

      const dateTimeFormat = (item.shiftId) ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm';
      const startHourForm = moment(item.startTime, dateTimeFormat).format('HH');
      const startTimeForm = moment(item.startTime, dateTimeFormat).format('mm');
      const endHourForm = moment(item.endTime, dateTimeFormat).format('HH');
      const endTimeForm = moment(item.endTime, dateTimeFormat).format('mm');

      const tmpDetail = {
        ...item,
        startTime: moment(`${startTimeStr} ${startHourForm}:${startTimeForm}:00`, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss'),
        endTime: moment(`${endTimeStr} ${endHourForm}:${endTimeForm}:00`, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss'),
        businessId: (item.businessId === null) ? '' : item.businessId,
      };

      dataPost = {
        ...dataPost,
        [`detailList[${index}]`]: {
          ...tmpDetail,
        },
      };
    });

    // submit form
    try {
      setLoading(true);
      setConfirmModalOpen(false);
      const response = await createOrUpdateStampWorkScheduleChangeApplication(dataPost);
      if (!response.errors) {
        history.push(sessionStorage.getItem('attendStampList.returnDestination')! || '');
      }
      successNotification('申請しました。');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} <br />`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: AttendStampModificationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    orgTreesOptions,
    orgCode,
    setOrgCode,
    staffName,
    attendBusinessOptions,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    setConfirmModalOpen,
    closeToastModal,
    closeConfirmModal,
    stampList,
    setStampList,
    listTimes,
    setListTimes,
    isLoading,
    modifierApplicationId,
    stampHistoryListAchievement,
    setStampHistoryListAchievement,
    selectChangelog,
    setSelectChangelog,
    // setIsReloader
  };
};

export default useStampWorkSchedule;
