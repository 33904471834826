import { useState, useCallback, useEffect } from 'react';

import { getListV2, getList } from 'api/organization';
import OrganizationDomain from 'domain/organization';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useOrganizationList = () => {
  const [organizationList, setOrganizationList] = useState<Array<OrganizationDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgStatus, setOrgStatus] = useState<'0'|'1'>('0');
  const [unsetTargetDate, setUnsetTargetDate] = useState<Date>(new Date());
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.organizations);

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const response = await getListV2(
      Number(orgStatus),
      new Date(),
    );
    setOrganizationList(response.map((result) => new OrganizationDomain(result)));
    setBlocking(false);
  }, [noManager, orgStatus, unsetTargetDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgStatus, unsetTargetDate]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  return {
    organizationList,
    fetchData,
    noManager,
    toggleNoManager,
    orgStatus,
    setOrgStatus,
    unsetTargetDate,
    setUnsetTargetDate,
    blocking,
    detailRole
  };
};

export default {};
