import React, { SVGProps } from 'react';

const YUI: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3085 42.4684C20.5714 42.4684 19.8644 42.177 19.3432 41.6584C18.822 41.1397 18.5292 40.4363 18.5292 39.7028V24.3263C18.5308 23.7204 18.4117 23.1203 18.1789 22.5605C17.9461 22.0007 17.6042 21.4923 17.1728 21.0648L0.813659 4.78844C0.292507 4.26963 -0.000173665 3.56607 7.73092e-08 2.83253C0.00017382 2.099 0.293188 1.39557 0.814583 0.87701C1.33598 0.35844 2.04304 0.06721 2.78024 0.0673829C3.51743 0.0675558 4.22435 0.359118 4.7455 0.877927L21.1029 17.1542C22.0524 18.0939 22.8051 19.2116 23.3175 20.4427C23.8299 21.6738 24.0917 22.9937 24.0879 24.3263V39.7028C24.0879 40.4363 23.7951 41.1397 23.2738 41.6584C22.7526 42.177 22.0457 42.4684 21.3085 42.4684Z" fill="url(#paint0_linear)"/>
    <path d="M15.7501 47.9998C13.5395 47.9973 11.4201 47.1224 9.85695 45.567C8.29376 44.0116 7.41451 41.9027 7.41211 39.7031C7.41211 38.9696 7.7049 38.2662 8.22617 37.7475C8.74736 37.2289 9.4543 36.9375 10.1914 36.9375C10.9286 36.9375 11.6355 37.2289 12.1567 37.7475C12.6779 38.2662 12.9708 38.9696 12.9708 39.7031C12.9708 40.4365 13.2636 41.14 13.7848 41.6586C14.306 42.1772 15.013 42.4686 15.7501 42.4686C16.4873 42.4686 17.1942 42.1772 17.7154 41.6586C18.2366 41.14 18.5295 40.4365 18.5295 39.7031C18.5295 38.9696 18.8222 38.2662 19.3435 37.7475C19.8647 37.2289 20.5717 36.9375 21.3088 36.9375C22.0459 36.9375 22.7529 37.2289 23.2741 37.7475C23.7953 38.2662 24.0881 38.9696 24.0881 39.7031C24.0857 41.9027 23.2064 44.0116 21.6433 45.567C20.0801 47.1224 17.9607 47.9973 15.7501 47.9998Z" fill="#995CE4"/>
    <path d="M30.6845 4.78889L14.3235 21.0689C14.2123 21.1795 14.1011 21.3067 13.9899 21.4174C13.3331 22.248 12.9744 23.2734 12.9708 24.3304V39.7033C12.9708 40.4367 12.678 41.1402 12.1568 41.6588C11.6356 42.1775 10.9286 42.4689 10.1915 42.4689C9.45442 42.4689 8.74748 42.1775 8.22621 41.6588C7.70501 41.1402 7.41216 40.4367 7.41216 39.7033V24.3304C7.40805 22.998 7.66961 21.6781 8.1817 20.447C8.69379 19.2159 9.44613 18.0981 10.3953 17.1583L11.8221 15.735L26.7564 0.880219C27.0109 0.608504 27.3177 0.39057 27.6586 0.239414C27.9996 0.0882598 28.3676 0.00698218 28.7408 0.000430417C29.1139 -0.00612148 29.4847 0.062187 29.8307 0.201281C30.1768 0.340374 30.4912 0.547402 30.7551 0.810018C31.019 1.07263 31.2271 1.38545 31.3669 1.72981C31.5067 2.07417 31.5753 2.44302 31.5687 2.81436C31.5621 3.18569 31.4804 3.5519 31.3285 3.89114C31.1766 4.23038 30.9576 4.5357 30.6845 4.78889Z" fill="url(#paint1_linear)"/>
    <path opacity="0.8" d="M19.676 15.7393L15.748 19.6478L16.3127 20.2096L20.2408 16.3011L19.676 15.7393Z" fill="#2673B8"/>
    <defs>
    <linearGradient id="paint0_linear" x1="6.15555" y1="2.26256" x2="24.2664" y2="37.0794" gradientUnits="userSpaceOnUse">
    <stop offset="0.406" stop-color="#00C8F4"/>
    <stop offset="0.967" stop-color="#995CE4"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="24.9146" y1="0.347385" x2="6.92753" y2="41.8134" gradientUnits="userSpaceOnUse">
    <stop offset="0.496" stop-color="#339AF6"/>
    <stop offset="0.967" stop-color="#995CE4"/>
    </linearGradient>
    </defs>
  </svg>
);

export default YUI;
