import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import EmploymentAddForm from 'components/organismos/employment/EmploymentAddForm';
import { useParams } from 'react-router-dom';

const EmploymentFormAddPage: React.FC = () => {
  const {
    isEdit,
    employmentId,
  } = useParams();

  return (
    <SidebarTemplate pageTitle={employmentId && isEdit === '2' ? '雇用形態マスタ編集' : '雇用形態マスタ作成'}>
      <EmploymentAddForm />
    </SidebarTemplate>
  );
};

export default EmploymentFormAddPage;
