/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import BodyText from "components/atoms/BodyText";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { ScheduleStatusColor, ScheduleStatus } from "api/schedule";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";

const ScheduleCalendarToolbar: React.FC<{
  date: Date;
  setDate: (arg: Date) => void;
  setDatePickerValue: (arg: Date) => void;
}> = ({ date, setDate, setDatePickerValue }) => (
  <FlexBox>
    <FlexBoxItem
      customStyle={css({
        margin: "0 24px",
      })}
    >
      <CalendarMonth
        date={date}
        setDate={(argDate) => {
          setDate(argDate);
          setDatePickerValue(argDate);
        }}
        openActionChevron
      />
    </FlexBoxItem>
    <FlexBoxItem>
      <FlexBox>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: ScheduleStatusColor[ScheduleStatus.ACTUAL],
            marginLeft: "10px",
            marginRight: "5px",
          }}
        />
        <BodyText>実績</BodyText>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: ScheduleStatusColor[ScheduleStatus.APPROVED],
            marginLeft: "10px",
            marginRight: "5px",
          }}
        />
        <BodyText>確定</BodyText>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: ScheduleStatusColor[ScheduleStatus.NOT_APPROVED],
            marginLeft: "10px",
            marginRight: "5px",
          }}
        />
        <BodyText>未確定</BodyText>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: ScheduleStatusColor[ScheduleStatus.CHANGE_REQUEST_IN_PROGRESS],
            marginLeft: "10px",
            marginRight: "5px",
          }}
        />
        <BodyText>申請中</BodyText>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: ScheduleStatusColor[ScheduleStatus.DAY_OFF],
            marginLeft: "10px",
            marginRight: "5px",
          }}
        />
        <BodyText>休暇</BodyText>
        <div
          style={{
            width: "23px",
            height: "23px",
            backgroundColor: ScheduleStatusColor[ScheduleStatus.UNSUBMITED],
            marginLeft: "10px",
            marginRight: "5px",
            border: "1px solid",
            borderColor: "#0D4796",
          }}
        />
        <BodyText>未提出</BodyText>
      </FlexBox>
    </FlexBoxItem>
  </FlexBox>
);

export default ScheduleCalendarToolbar;
