import React from "react"
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplication from "./WorkScheduleChangeApplicationAccordion";


import RejectedStampDomain from "../domain/rejectedStampDomain";
import RejectedHolidayDomain from "../domain/rejectedHolidayDomain";
import RejectedOvertimeDomain from "../domain/rejectedOvertimeDomain";
import RejectedHolidayWorkDomain from "../domain/rejectedHolidayWorkDomain";
import RejectedTransferDomain from "../domain/rejectedTransferDomain";
import Rejected36AgreementDomain from "../domain/rejected36AgreementDomain";
import RejectedWorkScheduleChangeDomain from "../domain/rejectedWorkScheduleChange";
import { OptionType } from 'components/atoms/Select';

const RejectedTab: React.FC<{
  stampList: RejectedStampDomain[]
  holidayList: RejectedHolidayDomain[]
  overtimeList: RejectedOvertimeDomain[]
  holidayWorkList: RejectedHolidayWorkDomain[]
  transferList: RejectedTransferDomain[]
  agreement36List: Rejected36AgreementDomain[];
  workScheduleChangeList: RejectedWorkScheduleChangeDomain[];
  reasonOptionList: OptionType[];
  reasonId:any;
  setReasonId:any;
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleChangeList,
  reasonOptionList,
  reasonId,
  setReasonId,
}) => {

  return (
    <div>
      <StampAccordion 
        list={stampList} 
        reasonOptionList={reasonOptionList}
        reasonId={reasonId}
        setReasonId={setReasonId}
      />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />

      <Agreement36Accordion list={agreement36List} />

      <WorkScheduleChangeApplication list={workScheduleChangeList} />

    </div>
  )
};

export default RejectedTab;
