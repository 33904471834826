import React from "react"
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";

import RejectedStampDomain from "../domain/rejectedStampDomain";
import RejectedHolidayDomain from "../domain/rejectedHolidayDomain";
import RejectedOvertimeDomain from "../domain/rejectedOvertimeDomain";
import RejectedHolidayWorkDomain from "../domain/rejectedHolidayWorkDomain";
import RejectedTransferDomain from "../domain/rejectedTransferDomain";
import RejectedAgreement36Domain from "../domain/rejectedAgreement36Domain";
import RejectedWorkScheduleChangeDomain from "../domain/rejectedWorkScheduleChange";


const RejectedTab: React.FC<{
  stampList: RejectedStampDomain[]
  holidayList: RejectedHolidayDomain[]
  overtimeList: RejectedOvertimeDomain[]
  holidayWorkList: RejectedHolidayWorkDomain[]
  transferList: RejectedTransferDomain[]
  agreement36List: RejectedAgreement36Domain[]
  workScheduleList: RejectedWorkScheduleChangeDomain[]
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleList
}) => {

  return (
    <div>
      <StampAccordion list={stampList} />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />

      <Agreement36Accordion list={agreement36List} />
      
      <WorkScheduleChangeApplicationAccordion list={workScheduleList} />
    </div>
  )
};

export default RejectedTab;
