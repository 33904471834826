/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

// create style form
const styles = {
  disabled: css({
    opacity: '0.5',
  }),
};

/**
 * フォームフィールドのレイアウト
 */
const FormField: React.FC<{
  displayBlock?: boolean;
  disabled?: boolean;
  justifyContent?: any;
  customStyle?: SerializedStyles;
  paddingBottom?: string
}> = ({ children, displayBlock = false, disabled = false, justifyContent, customStyle = css({}), paddingBottom }) => (
  <div
    css={[css({
      padding: '10px 0',
      display: displayBlock ? 'block' : 'flex',
      justifyContent,
      paddingBottom
    }, disabled ? styles.disabled : {}), customStyle]}
  >
    {children}
  </div>
);

export default FormField;
