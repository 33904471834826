/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useCallback } from 'react';
import { importFileCSV, Error } from 'api/holidayManagement';
import useToastNotification from 'hooks/useToastNotification';

export const useHolidayManagementImportCsvForm = (formatType?: string) => {
  const [uploadFile, setUploadFile] = useState<File>();
  const [errors, setErrors] = useState<Array<string>>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [inputValue, setValueInput] = useState('');
  const { successNotification, errorNotification } = useToastNotification();
  const [blocking, setBlocking] = useState(false);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  if (uploadFile) {
    const fileType = uploadFile.type;
    if (fileType !== 'text/csv' && fileType !== 'application/vnd.ms-excel') {
      errorNotification('CSVファイルを選択してください。');
      setUploadFile(undefined);
      setValueInput('');
    }
  }

  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }
    setErrors(undefined);
    setBlocking(true);
    setConfirmModalOpen(false);
    const formatTypeImport = (formatType === 'Paid') ? 0 : (formatType === 'StockPaid'? 2: 1); 
    importFileCSV(uploadFile, formatTypeImport)
      .then((response: any) => {
        setBlocking(false);
        setUploadFile(undefined);
        if (response.status !== 200) {
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification('インポートが完了しました。');
          setValueInput('');
        }
      }).catch((error: any) => {
        setUploadFile(undefined);
        setBlocking(false); 
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [errorNotification, formatType, successNotification, uploadFile]);

  const handleSubmit = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  return {
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
    blocking,
  };
};

export default useHolidayManagementImportCsvForm;
