/** @jsx jsx */
import React, { useState, useMemo, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import { useOrgTreesOptionsShopV1, useOrgTreesOptionsAllNoRole } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import SelectForm from 'components/molecules/SelectForm';
import StampHistoryDomain from 'domain/master/atendenceManagement/stampHistory';
import useToastNotification from 'hooks/useToastNotification';
import { getSearchDateClosing } from 'api/stampHistory';
import { useStaffOptionsTree, useStaffSelectBoxRange } from 'hooks/useEmploymentOptions';
import ClickableLink from 'components/atoms/ClickableLink';
import BlockUI from 'components/molecules/BlockUi';
import { css, jsx } from '@emotion/core';
import useGpsStampHistory from './hooks';
import FormPadding from 'components/atoms/Form/FormPadding';
import CalendarForm from 'components/molecules/CalendarForm';
import { grayScale } from 'components/styles';
import FormTitle from 'components/atoms/Form/FormTitle';
import TextTooltip from 'components/molecules/TextTooltip';
import ImageModel from 'components/organismos/ImageModel';
import CalendarRangeV2 from 'components/molecules/CalendarV1/CalendarRangeV2';
import { functionCode } from 'const';
import Checkbox from 'components/molecules/Checkbox';

const isIpad = window.matchMedia('(max-width: 1024px)').matches;
const GpsStampHistoryLayout = () => {
  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth();
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(y, m, 1));
  const [targetDateTo, setTargetDateTo] = useState(new Date(y, m + 1, 0));

  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getSearchDateClosing(companyCode).then((data) => {
      const dateFrom = new Date();
      setTargetDateFrom(new Date(data[0]));
      setTargetDateTo(new Date(data[1]));
      if (data[0] == null || data[1] == null) {
        setTargetDateFrom(new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1));
        setTargetDateTo(new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0));
      }
    });
  }, []);

  const { errorNotification } = useToastNotification();

  // const orgTreesOptions = useOrgTreesOptionsAll(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'), undefined, sessionStorage.getItem('loginUser.site') === '2', true);
  const orgTreesOptionsNoRole = useOrgTreesOptionsAllNoRole(moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'), undefined, sessionStorage.getItem('loginUser.site') === '2',true);
  const orgTreesOptions = useOrgTreesOptionsShopV1(true, functionCode.GpsStampHistory, moment(targetDateFrom).format('YYYY-MM-DD'), moment(targetDateTo).format('YYYY-MM-DD'));
  const [orgCode, setorgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');

  // const useStaffOptions = useStaffOptionsTree(orgCode, targetDateFrom, targetDateTo, false, true);
  const [staffCode, setStaffCode] = useState('');

  const { gpsStampHistory, blocking, underFlag, setUnderFlag } = useGpsStampHistory(orgCode || '', staffCode, moment(targetDateFrom).format('YYYY年MM月DD日'),
    moment(targetDateTo).format('YYYY年MM月DD日'));

  const useStaffOptions = useStaffSelectBoxRange(targetDateFrom, targetDateTo, orgCode, true, functionCode.GpsStampHistory, underFlag);


  // 対象日の範囲が１年を超えていた場合、エラーとする
  const [stampHistoryNull, setstampHistoryNull] = useState(true);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  useEffect(() => {
    const startDay = new Date(Date.parse(moment(targetDateFrom, 'YYYY年MM月DD日').format('YYYY/MM/DD')));
    const endDay = new Date(moment(targetDateTo, 'YYYY年MM月DD日').format('YYYY/MM/DD'));
    const startDayNextYear = new Date(
      startDay.getFullYear() + 1, startDay.getMonth(), startDay.getDate(),
    );
    if (endDay.getTime() >= startDayNextYear.getTime()) {
      errorNotification('期間の範囲は１年以内で入力してください');
      setstampHistoryNull(false);
    } else {
      setstampHistoryNull(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDateFrom, targetDateTo]);


  const columns: Array<Column<StampHistoryDomain>> = useMemo(() => [
    {
      Header: '氏名',
      accessor: 'staffName',
      Cell: (cell: { row: { isExpanded: boolean, original: any }, page: [] }) => {
        const { staffName } = cell.row.original;

        return (
          <React.Fragment>
            <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
              {staffName.length > 20
                ? <TextTooltip text={staffName} textDis={(
                  <div className="ellipsis headline-cell-width-120"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '150px'
                    }}>
                    <span>{staffName}</span>
                  </div>)} />
                : <span>{staffName}</span>
              }
            </div>
          </React.Fragment>
        );
      }
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
    },
    {
      Header: '組織コード',
      accessor: 'orgCode',
    },
    {
      Header: '組織名',
      accessor: 'orgName',
      Cell: (cell: { row: { isExpanded: boolean, original: any }, page: [] }) => {
        const { orgName } = cell.row.original;

        return (
          <React.Fragment>
            <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
              {orgName.length > 15 ? <TextTooltip text={orgName} textDis={(
                <div className="ellipsis headline-cell-width-120"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width:'128px'
                  }}>
                  <span>{orgName}</span>
                </div>
              )} /> : <span>{orgName}</span>
              }
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: '打刻種別',
      accessor: 'gpsStampKind',
      Cell: (cell: { row: { isExpanded: boolean, original: any } }) => {
        const { gpsStampKind, isRemote } = cell.row.original.rawData;
        return (
          <div>
            {gpsStampKind === '出勤' ? (isRemote ? gpsStampKind + `${companyCode === 'HPo6wkyuze' ? ' (在宅)' : ' (テレワーク)'}` : gpsStampKind + ' (オフィス)') : gpsStampKind}
          </div>
        );
      },
    },
    {
      Header: '打刻時刻',
      accessor: 'workTimeStr',
    },
    {
      Header: '打刻住所',
      id: 'address',
      accessor: 'address',
      Cell: (cell: { row: { isExpanded: boolean, original: any }, page: [] }) => {
        const { address } = cell.row.original;
        const { latitude } = cell.row.original;
        const { longitude } = cell.row.original;

        // eslint-disable-next-line no-shadow
        const openMapWindow = (latitude: string, longitude: string) => {
          let strUrl = '';
          strUrl += './map.html';
          strUrl += `?latitude=${latitude}`;
          strUrl += `&longitude=${longitude}`;

          let winPara = 'directories = no,';
          winPara += 'fullscreen = no,';
          winPara += 'location = no,';
          winPara += 'menubar = no,';
          winPara += 'scrollbars = no,';
          winPara += 'status = no,';
          winPara += 'titlebar = no,';
          winPara += 'toolbar = no,';
          winPara += 'resizable = yes,';
          winPara += 'width = 1024,';
          winPara += 'height = 768';

          window.open(strUrl, 'map', winPara);
        };

        return (
          <ClickableLink onClick={
            () => openMapWindow(latitude, longitude)
          }
            color="#003993"
            customCss={css({ fontSize: '12px' })}
          >
            {address}

          </ClickableLink>
        );
      },
    },
  ], []);

  return (
    <BlockUI blocking={blocking}>
      <div>
        <FormContents customStyle={{ marginBottom: '50px' }}>
          <FormTitle title="GPS打刻実績" bold={true} customStyle={css`margin: 16px 35px;`} />
          <FormPadding customStyle={{ padding: '0 0 0 35px' }}>
            <FormField>
              <FlexBox>
                <FlexBoxItem width="100px">
                  <FormLabel
                    label="期間"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <CalendarRangeV2 
                    width="auto"
                    startDate={targetDateFrom}
                    onChangeStart={setTargetDateFrom}
                    endDate={targetDateTo}
                    onChangeEnd={(date) => {
                      if(date){
                        setTargetDateTo(date)
                      }
                    }}
                  />
                </FlexBoxItem>
                {/* <FlexBoxItem width="160px">
                  <CalendarForm
                    required={true}
                    label=""
                    date={targetDateFrom}
                    dateFormat="YYYY年MM月DD日"
                    changeDate={(date: Date) => {
                      setTargetDateFrom(date);
                    }}
                  />
                </FlexBoxItem>
                <div css={css({ width: '50px', textAlign: 'center', color: grayScale.gray20 })}>
                  ～
              </div>
                <FlexBoxItem width="160px">
                  <CalendarForm
                    required={true}
                    label=""
                    date={targetDateTo}
                    dateFormat="YYYY年MM月DD日"
                    changeDate={(date: Date) => {
                      setTargetDateTo(date);
                    }}
                  />
                </FlexBoxItem> */}
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <FlexBox>
                <SelectForm
                  label="組織"
                  name="orgCode"
                  value={String(orgCode)}
                  setValue={(v) => {
                    setorgCode(v);
                  }}
                  options={orgTreesOptions}
                  description=""
                  width="900px"
                  widthLabel="100px"
                  marginRight="30px"
                />
                <Checkbox
                  id={'underFlag'}
                  name={'underFlag'}
                  label={"配下指定"}
                  value={`underFlag`}
                  checked={Boolean(underFlag)}
                  onChange={() => {
                    setUnderFlag(underFlag === 1 ? 0 : 1)
                  }}
                />
              </FlexBox>
            </FormField>
            <FormField displayBlock={true}>
              <SelectForm
                label="スタッフ"
                name="staffCode"
                value={String(staffCode)}
                setValue={(v) => { setStaffCode(v); }}
                options={useStaffOptions}
                description=""
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
            </FormField>
          </FormPadding>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 150px
              }
              table th:nth-of-type(2) {
                width: 130px;
              }
              table th:nth-of-type(3) {
                width: 150px;
              }
              table th:nth-of-type(4) {
                width: 128px;
              }
              table th:nth-of-type(5) {
                width: 130px;
              }
              table th:nth-of-type(6) {
                width: 150px;
              }
              table th:nth-of-type(7) {
                width: 200px;
              }
              table tbody td, table thead th{
                text-align: left;
                padding-left: ${isIpad ? '10px' : '10px'};
              }
              table thead th{
                font-weight: bold;
              }
              table{
                table-layout: auto;
              }
              table td{
                padding-top: 10px;
              }
              table button{
                padding: 0;
              }
              margin-top: 20px;
            `}
          >

            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <DataTable
                className="table-no-border-left-right"
                columns={columns}
                data={stampHistoryNull ? gpsStampHistory : []}
                isGlobalFilter={true}
                globalSearchStyle={css({ marginLeft: '35px' })}
                minWidth="1080px"
              />
            </div>
          </div>

        </FormContents>
      </div>
    </BlockUI>
  );
};

export default GpsStampHistoryLayout;
