export interface HolidayItem {
  attendType?: number,
  staffCode: string,
  updateUser: string,
  orgCode: string,
  targetDate: string,
  fromDate: string,
  toDate: string,
  modifierApplicationId: string,
  achievementId: string,
  modifiyStatus: number,
  version: string,
  holidayId: string,
  holidayUnit?: number,
  stampStartTime?: string,
  applicationReason: string,
  stampTime?: string,
  startHour:string,
  nextDay:Boolean,
  startTime:string,
  substituteId?: string;
  halfHolidayStartTimeHour: string,
  halfHolidayStartTimeMinute: string,
  halfHolidayStartTime?: string,
  halfHolidayStartTimeNextDay?: boolean,
  halfHolidayEndTimeHour?: string,
  halfHolidayEndTimeMinute?: string,
  halfHolidayEndTime?: string,
  halfHolidayEndTimeNextDay?: boolean,

}

export default class AttendHolidayApplicationDomain {
  constructor(private rawData: HolidayItem) {
    // do nothing
  }

  static generateInitial(): AttendHolidayApplicationDomain {
    return new AttendHolidayApplicationDomain({
      attendType: 0,
      staffCode: '',
      updateUser: '',
      orgCode: '',
      targetDate: '',
      fromDate: '',
      toDate: '',
      modifierApplicationId: '',
      achievementId: '',
      modifiyStatus: 0,
      version: '',
      holidayId: '',
      holidayUnit: 0,
      stampStartTime: '00:00',
      applicationReason: '',
      stampTime: '',
      startHour:'00',
      startTime:'00',
      nextDay:false,
      substituteId: '',
      halfHolidayStartTimeHour: '00',
      halfHolidayStartTimeMinute: '00',
      halfHolidayStartTime: '00:00',
      halfHolidayStartTimeNextDay: false,
      halfHolidayEndTime: '00:00',
      halfHolidayEndTimeHour: '00',
      halfHolidayEndTimeMinute: '00',
      halfHolidayEndTimeNextDay: false,
    });
  }

  getRawData(): HolidayItem {
    return this.rawData;
  }

  get attendType(): number {
    return Number(this.rawData.attendType);
  }

  set attendType(attendType: number) {
    this.rawData.attendType = attendType;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get targetDate(): string {
    return this.rawData.fromDate;
  }

  set targetDate(targetDate: string) {
    this.rawData.fromDate = targetDate;
  }

  get fromDate(): string {
    return this.rawData.fromDate;
  } 

  set fromDate(fromDate: string) {
    this.rawData.fromDate = fromDate;
  }

  get toDate(): string {
    return this.rawData.toDate;
  }

  set toDate(toDate: string) {
    this.rawData.toDate = toDate;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get achievementId(): string {
    return this.rawData.achievementId;
  }

  set achievementId(achievementId: string) {
    this.rawData.achievementId = achievementId;
  }

  get modifiyStatus(): number {
    return this.rawData.modifiyStatus;
  }

  set modifiyStatus(modifiyStatus: number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get version(): string {
    return this.rawData.version;
  }

  set version(version: string) {
    this.rawData.version = version;
  }

  get holidayId(): string {
    return this.rawData.holidayId;
  }

  set holidayId(holidayId: string) {
    this.rawData.holidayId = holidayId;
  }

  get holidayUnit(): number {
    return Number(this.rawData.holidayUnit);
  }

  set holidayUnit(holidayUnit: number) {
    this.rawData.holidayUnit = holidayUnit;
  }

  get stampStartTime(): string {
    return String(this.rawData.stampStartTime);
  }

  set stampStartTime(stampStartTime: string) {
    this.rawData.stampStartTime = stampStartTime;
  }

  get applicationReason(): string {
    return this.rawData.applicationReason;
  }

  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }

  get stampTime(): string {
    return String(this.rawData.stampTime);
  }

  set stampTime(stampTime: string) {
    this.rawData.stampTime = stampTime;
  }

  get startHour(): string {
    return String(this.rawData.startHour);
  }

  set startHour(startHour: string) {
    this.rawData.startHour = startHour;
  }

  get startTime(): string {
    return String(this.rawData.startTime);
  }
  set startTime(startTime: string) {
    this.rawData.startTime = startTime;
  }

  get nextDay(): Boolean {
    return this.rawData.nextDay;
  }

  set nextDay(nextDay: Boolean) {
    this.rawData.nextDay = nextDay;
  }

  get substituteId(): string {
    return String(this.rawData.substituteId);
  }

  set substituteId(substituteId: string) {
    this.rawData.substituteId = substituteId;
  }

  get halfHolidayStartTimeHour(): string {
    return String(this.rawData.halfHolidayStartTimeHour);
  }

  set halfHolidayStartTimeHour(halfHolidayStartTimeHour: string) {
    this.rawData.halfHolidayStartTimeHour = halfHolidayStartTimeHour;
  }

  get halfHolidayStartTimeMinute(): string {
    return String(this.rawData.halfHolidayStartTimeMinute);
  }

  set halfHolidayStartTimeMinute(halfHolidayStartTimeMinute: string) {
    this.rawData.halfHolidayStartTimeMinute = halfHolidayStartTimeMinute;
  }

  get halfHolidayStartTime(): string {
    return String(this.rawData.halfHolidayStartTime);
  }

  set halfHolidayStartTime(halfHolidayStartTime: string) {
    this.rawData.halfHolidayStartTime = halfHolidayStartTime;
  }

  get halfHolidayStartTimeNextDay(): boolean {
    return Boolean(this.rawData.halfHolidayStartTimeNextDay);
  }

  set halfHolidayStartTimeNextDay(halfHolidayStartTimeNextDay: boolean) {
    this.rawData.halfHolidayStartTimeNextDay = halfHolidayStartTimeNextDay;
  }

  get halfHolidayEndTimeHour(): string {
    return String(this.rawData.halfHolidayEndTimeHour);
  }

  set halfHolidayEndTimeHour(halfHolidayEndTimeHour: string) {
    this.rawData.halfHolidayEndTimeHour = halfHolidayEndTimeHour;
  }

  get halfHolidayEndTimeMinute(): string {
    return String(this.rawData.halfHolidayEndTimeMinute);
  }

  set halfHolidayEndTimeMinute(halfHolidayEndTimeMinute: string) {
    this.rawData.halfHolidayEndTimeMinute = halfHolidayEndTimeMinute;
  }

  get halfHolidayEndTime(): string {
    return String(this.rawData.halfHolidayEndTime);
  }

  set halfHolidayEndTime(halfHolidayEndTime: string) {
    this.rawData.halfHolidayEndTime = halfHolidayEndTime;
  }
  
  get halfHolidayEndTimeNextDay(): boolean {
    return Boolean(this.rawData.halfHolidayEndTimeNextDay);
  }

  set halfHolidayEndTimeNextDay(halfHolidayEndTimeNextDay: boolean) {
    this.rawData.halfHolidayEndTimeNextDay = halfHolidayEndTimeNextDay;
  }

}
