import { useState, useEffect, useRef } from "react";
import useToastNotification from "hooks/useToastNotification";
import { getHistoryRecorder } from "api/timeRecorder_v1";
import { getCurrentTime } from "api/attendStamp";
import StampHistoryListRecorderDomain from "domain/timeRecorder/stampHistoryListRecorderDomain";
import moment from "moment";
import { CurrentTimeDomain } from "domain/master/attend/currentTime";
import { useOrgCode } from "hooks/useSessionLoginUser";

const { errorNotification } = useToastNotification();

export const useRecorder = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const [stampHistoryList, setStampHistoryList] = useState<
    Array<StampHistoryListRecorderDomain>
  >([StampHistoryListRecorderDomain.generateInitial()]);

  // Time
  const resultCurrentTime: CurrentTimeDomain = CurrentTimeDomain.generateInitial();
  const [currentDay, setCurrentDay] = useState("");
  const [timeNow, setTimeNow] = useState("");
  const [second, setSecond] = useState("");
  const [underFlag, setUnderFlag] = useState(0);

  const [currentTime, setCurrentTime] = useState(
    CurrentTimeDomain.generateInitial()
  );
  const startTime = useRef(Date.now());

  //select org
  const [selectedOrg, setSelectedOrg] = useState(useOrgCode());

  // Get system time
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        !currentTime ||
        currentTime.dateString === "" ||
        currentTime.serverDateTime.getSeconds() === 0 ||
        Date.now() > startTime.current + 60 * 1000
      ) {
        getCurrentTime()
          .then((response: any) => {
            setCurrentDay(
              moment(new Date(response[2])).format("YYYY年MM月DD日 (ddd)")
            );
            setTimeNow(moment(new Date(response[2])).format("HH  :  mm"));
            setSecond(moment(currentTime.serverDateTime).format("ss"));
            resultCurrentTime.dateString = String(response[0]);
            resultCurrentTime.timeString = String(response[1]);
            resultCurrentTime.serverDateTime = new Date(response[2]);
            setCurrentTime(resultCurrentTime);
            startTime.current = Date.now();
          })
          .catch((error: any) => {
            currentTime.serverDateTime.setSeconds(
              currentTime.serverDateTime.getSeconds() + 1
            );
            setCurrentDay(
              moment(currentTime.serverDateTime).format("YYYY年MM月DD日 (ddd)")
            );
            setTimeNow(moment(currentTime.serverDateTime).format("HH  :  mm"));
            setSecond(moment(currentTime.serverDateTime).format("ss"));
          });
      } else {
        // currentTime.serverDateTime.setSeconds(
        //   currentTime.serverDateTime.getSeconds() + 1,
        // );
        currentTime.serverDateTime.setMilliseconds(
          currentTime.serverDateTime.getMilliseconds() +
            Date.now() -
            startTime.current
        );
        startTime.current = Date.now();
        setCurrentDay(
          moment(currentTime.serverDateTime).format("YYYY年MM月DD日 (ddd)")
        );
        setTimeNow(moment(currentTime.serverDateTime).format("HH  :  mm"));
        setSecond(moment(currentTime.serverDateTime).format("ss"));
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  const fetchHistoryData = () => {
    try {
      getHistoryRecorder(
        companyCode,
        selectedOrg === "all" ? "" : selectedOrg,
        underFlag
      ).then((responseHistory: any) => {
        setStampHistoryList(responseHistory);
      });
    } catch (error) {
      errorNotification("サーバー側でエラーが発生しました。");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg, underFlag]);

  return {
    stampHistoryList,
    currentDay,
    timeNow,
    second,
    resultCurrentTime,
    selectedOrg,
    setSelectedOrg,
    fetchHistoryData,
    underFlag,
    setUnderFlag
  };
};

export default useRecorder;
