import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';

const HelpPage = () => (
  <SidebarTemplate>
    HELP
  </SidebarTemplate>
);

export default HelpPage;
