
import React from 'react';

const HourlyReport: React.FC<{
  reportByTimeOfDay: any
}> = ({
  reportByTimeOfDay,
}) => (
  <div className="box-body" id="SalesDailyReport">
    <div className="row" id="layoutSale_02">
      <div className="col-md-12">
        <div id="resize-detect-container" className="tab-content">
          <div className="tab-pane" id="search_tab_3">
            <div className="col-xs-12">
              <div className="row">
                <div className="table-responsive no-padding">
                  <table className="table table-bordered table-striped table-condensed text-nowrap">
                    <thead>
                      <tr>
                        <th rowSpan={2} className="text-center">時間</th>
                        <th rowSpan={2} className="text-center">売上高</th>
                        <th rowSpan={2} className="text-center">標準原価%</th>
                        <th rowSpan={2} className="text-center">売上総利益</th>
                        <th rowSpan={2} className="text-center">組数</th>
                        <th rowSpan={2} className="text-center">客数</th>
                        <th rowSpan={2} className="text-center">回転率</th>
                        <th className="text-center" colSpan={3}>客単価</th>
                      </tr>
                      <tr>
                        <th className="text-center">売上高</th>
                        <th className="text-center">標準原価％</th>
                        <th className="text-center">売上総利益</th>
                      </tr>
                    </thead>
                    { reportByTimeOfDay && (
                      <tbody>
                        {
                          reportByTimeOfDay.map((reports: any, keyIndex: number) => (
                            <tr key={keyIndex}>
                              {reports
                                && reports.map((val: any, index: number) => (
                                  <td key={index} className={(index === 0) ? 'text-center' : 'text-right'}>{ val }</td>
                                ))}
                            </tr>
                          ))
                        }
                      </tbody>
                    )}

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default HourlyReport;
