import { createStore, combineReducers } from 'redux';

import sample from './sample';
import { SampleState } from './sample/reducer';
import ui, { UiState } from './ui';
import auth, { AuthState } from './auth';
import loginUser, { LoginUserState } from './loginUser';

export interface Store {
    sample: SampleState;
    ui: UiState;
    auth: AuthState;
    loginUser: LoginUserState;
}

export default createStore(combineReducers<Store>({ sample, ui, auth, loginUser }));
