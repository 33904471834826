import { useState, useEffect } from "react";

import { OptionType } from "components/atoms/Select";
import { getListTerm } from "api/businessMaster";

/**
 * 時間帯の選択ボックス用データを取得
 */
export const useTermMasterByCompanyCodeOptions = (): Array<OptionType> => {
  const [termOptions, setTermOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getListTerm().then((term) => {
      setTermOptions(
        term.map((item: any) => ({
          value: item.termId,
          label: item.termName,
        })),
      );
    });
  }, []);
  return termOptions;
};

export default useTermMasterByCompanyCodeOptions;
