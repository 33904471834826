import React from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import { utilityColor } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import ConfirmModal from 'components/organismos/ConfirmModal';
import useBusinessCategoryCsvForm from './hooks';


const BusinessCategoryImportForm: React.FC = () => {
  const {
    setUploadFile,
    uploadFile,
    onSubmit,
    errors,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
  } = useBusinessCategoryCsvForm();
  return (
    <div>
      <FormContents>
        <FormTitle title="登録するデータ" bold={true} />
        <BodyText color={utilityColor.error} size="md">※CSVファイルの１行目（ヘッダー行）はインポートされません。</BodyText>
        <FormField>
          <FileSelectButton
            text="CSVファイルをドラッグ＆ドロップ または クリックしてファイルを選択してください"
            ghost={true}
            setFile={setUploadFile}
            value={inputValue}
            accept="text/csv,.csv,application/vnd.ms-excel"
          />
        </FormField>

        {uploadFile || errors
          ? (
            <FormField
              displayBlock={true}
            >
              <div>
                {uploadFile?.name}
              </div>
              {errors && errors.map((error) => (
                <div key={error}>
                  <BodyText color={utilityColor.error}>
                    {error}
                  </BodyText>
                </div>
              ))}
            </FormField>
          )
          : null}
        <FormSubmitArea>
          <PrimaryButton
            text="インポート"
            onClick={handleSubmit}
            disabled={!uploadFile}
          />

          <PrimaryButton
            text="戻る"
            ghost={true}
            // eslint-disable-next-line no-restricted-globals
            onClick={() => history.back()}
          />
        </FormSubmitArea>
      </FormContents>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={onSubmit}
        content="ファイルをアップロードします。よろしいですか？"
        items={[]}
      />
    </div>
  );
};


export default BusinessCategoryImportForm;
