
import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterShopSpecifyLayout from 'components/organismos/master/labor/MasterShopSpecifyLayout';

const MasterShopSpecifyPage = () => (
  <SidebarTemplate pageTitle="端末への組織登録">
    <MasterShopSpecifyLayout />
  </SidebarTemplate>
);

export default MasterShopSpecifyPage;
