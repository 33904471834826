/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Select, { components, OptionTypeBase } from "react-select";
import { ValueType } from "react-select/src/types";

import { grayScale, productColor, textFontSize, textColor, utilityColor } from "components/styles";
import FormLabel from "components/atoms/Form/FormLabel";
import Icon from "components/atoms/Icon";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import FlexBox from "components/atoms/FlexBox";
import FormDescription from "components/atoms/Form/FormDescription";

export interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}

const styles = {
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: "4px",
  }),
};

const selectStyles = {
  control: (oldStyles: any) => ({
    ...oldStyles,
    "& > div": {
      padding: "4 15px",
    },
    border: `1px solid ${grayScale.gray03}`,
    borderRadius: "2px",
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${grayScale.gray03}`,
    },
  }),
  dropdownIndicator: (oldStyles: any, state: any) => ({
    ...oldStyles,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    cursor: "pointer",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: "40px",
  }),
  input: (oldStyles: any) => ({
    ...oldStyles,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: textFontSize.re,
    backgroundColor: (() => {
      if (state.isSelected) return productColor.primary;
      if (state.isFocused) return productColor.primaryM80;
      return "";
    })(),
    color: state.isSelected ? textColor.inversed : textColor.main,
  }),
  multiValue: (oldStyles: any) => ({
    ...oldStyles,
    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0D4796",
    borderRadius: "12px",
  }),
  multiValueLabel: (oldStyles: any) => ({
    ...oldStyles,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 10px",
    color: productColor.primary,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: "21px",
    paddingRight: "0px",
    ":hover": {
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  }),
  placeholder: (oldStyles: any) => ({
    ...oldStyles,
    fontSize: "14px",
  }),
  menu: (oldStyles: any) => ({ ...oldStyles, zIndex: 9999 }),
};

const hideMenuStyles = {
  dropdownIndicator: (oldStyles: any) => ({
    ...oldStyles,
    display: "none",
  }),
};

/**
 * 選択式フォーム
 * TODO: セレクトボックスのスタイルがデフォルトのままなので、ちゃんとカスタマイズする
 */
const VerticalLabelMutilSelectForm: React.FC<{
  label: string;
  name: string;
  values: Array<OptionType> | undefined;
  options: Array<OptionType>;
  setValue: (val: string) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  hideIndicator?: boolean;
  hidePlaceholder?: boolean;
  disable?: boolean;
  placeholderCus?: any;
}> = ({
  label,
  name,
  values,
  options,
  setValue,
  description,
  isMulti,
  note,
  menuIsOpen,
  hideIndicator,
  hidePlaceholder,
  disable = false,
  placeholderCus,
}) => {
  const getValue = (): ValueType<OptionType> | undefined => values;

  const onChange = (option: any) => {
    if (!option) {
      setValue("");
    } else if (Array.isArray(option) && option.length) {
      const array = option.map((opt) => (opt as OptionType).value);
      setValue(`${array.toString()},`);
    } else {
      setValue((option as OptionType).value);
    }
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Icon type="dropdown" color="white" />
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: any) => (
    <components.MultiValueRemove {...props}>
      <Icon type="close" color={productColor.primary} />
    </components.MultiValueRemove>
  );

  if (hidePlaceholder) {
    placeholderCus = "";
  }

  return (
    <div css={styles.container}>
      <VerticalLabelFormLayout
        label={label ? <FormLabel label={label} /> : null}
        input={
          <div css={styles.container}>
            <FlexBox>
              <div css={styles.select}>
                <Select
                  styles={hideIndicator ? { ...selectStyles, ...hideMenuStyles } : selectStyles}
                  name={name}
                  value={getValue()?.map((item: any) =>
                    item ? { value: item.value, label: item.label?.trim() } : item,
                  )}
                  onChange={onChange}
                  options={options}
                  placeholder={placeholderCus ? placeholderCus : "選択してください"}
                  isMulti={isMulti}
                  isClearable={false}
                  components={{ DropdownIndicator, IndicatorSeparator: () => null, MultiValueRemove }}
                  menuIsOpen={menuIsOpen}
                  isDisabled={disable}
                />
              </div>
            </FlexBox>
            <FlexBox>
              <div css={styles.select}>{description && <FormDescription description={description} />}</div>
            </FlexBox>
          </div>
        }
      />
      {note && (
        <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />
      )}
    </div>
  );
};

export default VerticalLabelMutilSelectForm;
