import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getDigestionItemsForSpecialHoliday,
  downloadSpecialHolidayDigestionCsv,
  getSpecialHolidayDigestionDetail,
} from 'api/holidayManagement';
import moment from 'moment';
import SpecialHolidayDigestionDomain from 'domain/master/holidayManagement/specialHolidayDigestion';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import { renderError } from 'utility/renderMessage';
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

export interface DetailItems {
  cnt: string,
  orgCode: string,
  orgName: string,
  staffCode: string,
  staffName: string,
  holidayId: string,
  holidayCode: string,
  holidayName: string,
  total: string,
  specialHolidayDigestionDetailList: [],
  fromDate: Date,
  toDate: Date,
  effectiveStartDate: Date
}

const { errorNotification } = useToastNotification();

export const useAcquisitionStatusDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [paidHolidayDigestedtHistoryList, setPaidHolidayDigestedtHistoryList] = useState<Array<SpecialHolidayDigestionDomain>>([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementSpecialHoliday.acquisitionStatus.orgCode") as [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  const date = moment(new Date()).format('YYYY/MM/DD');
  const [searchDate, setSearchDate] = useSaveStateStorage(date, "holidayManagementSpecialHoliday.acquisitionStatus.searchDate") as [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  const [modalOpen, setModalOpen] = useState(false);
  const [detailItems, setDetailItems] = useState<DetailItems>({
    cnt: '',
    orgCode: '',
    orgName: '',
    staffCode: '',
    staffName: '',
    holidayId: '',
    holidayCode: '',
    holidayName: '',
    total: '',
    specialHolidayDigestionDetailList: [],
    fromDate: new Date(),
    toDate: new Date(),
    effectiveStartDate: new Date()
  });

  const contextObject = useContext(HolidayManagementContext);
  const [underFlag, setUnderFlag] = useState(0);

  // データの取得
  const fetchData = useCallback(async () => {
    if (orgCode !== null) {
      contextObject.setIsLoading(true);
      try {
        const response = await getDigestionItemsForSpecialHoliday(
          orgCode !== 'all' ? orgCode : '',
          searchDate || date,
          underFlag
        );
        contextObject.setIsLoading(false);
        setPaidHolidayDigestedtHistoryList(response.map((result) => new SpecialHolidayDigestionDomain(result)));
      } catch (error) {
        const mess = renderError(error);
        errorNotification(mess);
      } finally {
        contextObject.setIsLoading(false);
      }
    }
  }, [orgCode, searchDate, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, searchDate, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetAcquisitionStatusDetail = useCallback(async (data: any) => {
    try {
      await getSpecialHolidayDigestionDetail(
        data.staffCode,
        searchDate || date,
        data.rawData.holidayId,
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification, searchDate]);

  return {
    paidHolidayDigestedtHistoryList,
    callGetAcquisitionStatusDetail,
    toggleNoManager,
    orgCode,
    setOrgCode,
    date,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagementAcquisitionStatus = (
  orgCode: string,
  targetDate: string,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadHolidayManagementAcquisitionStatus = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadSpecialHolidayDigestionCsv(
      isDownloadAll ? '' : orgCode,
      targetDate,
      underFlag
    ).then(() => {
      setBlocking(false);
    }).catch((error)=>{
      const mess = renderError(error);
      errorNotification(mess);
    }).finally(()=>{
      setBlocking(false);
    });
  }, [orgCode, targetDate, underFlag]);

  return {
    blocking,
    downloadHolidayManagementAcquisitionStatus
  };
};

export default {
  useAcquisitionStatusDomainForm,
};
