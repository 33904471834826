/** @jsx jsx */
import React, { useState } from "react";
import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import HourInput from "components/atoms/Form/HourInput";
import Checkbox from "components/molecules/Checkbox";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import { useHistory } from "react-router-dom";

import { grayScale, productColor } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import PaidHolidayStatusDomain from "domain/master/holidayManagement/paidHolidayStatus";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";

import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isIPad13, isTablet, isSafari } from "react-device-detect";
import { useManagementInformationDomainForm, useDownloadHolidayManagement } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import TextTooltip from "components/molecules/TextTooltip";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "150px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
  }),
  detail: css({
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  input: css({
    maxWidth: "500px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 20px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  table: css(
    {
      color: "#666666",
      margin: "0 auto",
    },
    css`
      table.tblPopup tr {
        display: inline-grid;
        margin-bottom: 10px;
      }
      table.tblPopup tr td {
        margin-bottom: 5px;
      }
    `,
  ),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
}

const ManagementInformationForm: React.FC<{
  employmentOptions: any;
  orgTreesOptions: any;
}> = ({ employmentOptions, orgTreesOptions }) => {
  const {
    paidHolidayStatusList,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    extinctionDaysStart,
    setExtinctionDaysStart,
    extinctionDaysEnd,
    setExtinctionDaysEnd,
    targetGrantTenDays,
    setTargetGrantTenDays,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    setExtinctionDaysStartTmp,
    extinctionDaysStartTmp,
    extinctionDaysEndTmp,
    setExtinctionDaysEndTmp,
    detailRole,
    underFlag,
    setUnderFlag,
    // setUntilNextGrantDate,
    // untilNextGrantDate
  } = useManagementInformationDomainForm();

  const { downloadPaidHolidayStatus, blocking } = useDownloadHolidayManagement(
    orgCode || "",
    extinctionDaysStart || "",
    extinctionDaysEnd || "",
    employmentId || "",
    targetGrantTenDays || false,
    underFlag || 0,
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: "",
    staffName: "",
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePaidHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callPaidHolidayStatusDetail(data.staffCode, data.paidHolidayGrantId);
    setModalOpen(true);
  };

  //
  const columns: Array<Column<PaidHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayStatusDomain };
          data: Array<PaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayStatusDomain };
          data: Array<PaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayStatusDomain };
          data: Array<PaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
            {detailRole.editable === 1 ? (
              <IconLabelButton
                onClick={() => handlePaidHolidayStatusDetail(cell.row.original)}
                text="編集"
                isIcon={false}
              />
            ) : (
              ""
            )}
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: PaidHolidayStatusDomain };
          data: Array<PaidHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "付与日",
        accessor: "effectiveStartDateToStr",
      },
      {
        Header: "前年繰越数",
        accessor: "carriedOverLastYear",
      },
      {
        Header: "当年付与数",
        accessor: "numberGrantedThisYear",
      },
      {
        Header: "消化数",
        accessor: "digestionNumber",
      },
      {
        Header: "残有給数",
        accessor: "remainingPaid",
      },
      {
        Header: "次回付与日",
        accessor: "nextGrantDate",
      },
      {
        Header: "次回予定付与日数",
        accessor: "nextGrantDaysToStr",
      },
      {
        Header: "次回繰越日数",
        accessor: "nextRolloverDays",
      },
      {
        Header: "次回消滅日数",
        accessor: "nextExpirationDate",
      },
    ],
    [handlePaidHolidayStatusDetail, detailRole],
  );

  const history = useHistory();
  return (
    <BlockUI blocking={blocking}>
      <Accordion text="管理情報" callBack={() => {}} defaultExpand={false}>
        <div css={styles.wrapAccordion}>
          <FormContentFilter maxWidth="100%">
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode001"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
              <Checkbox
                id={"underFlag"}
                name={"underFlag"}
                label={"配下指定"}
                value={`underFlag`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
            <SelectForm
              label="雇用形態"
              name="employmentId001"
              value={String(employmentId)}
              setValue={(val: string) => setOrgEmploymentId(val)}
              options={employmentOptions}
              width="900px"
              widthLabel="100px"
            />

            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="消化日数" />
                </div>
                <div css={styles.digestionDays}>
                  <HourInput
                    name="extinctionDaysStart"
                    value={extinctionDaysStart === "" ? "" : extinctionDaysStart}
                    onChange={(e) => setExtinctionDaysStart(e.target.value)}
                    onBlur={(e) => setExtinctionDaysStartTmp(extinctionDaysStart || "")}
                    label="日"
                    min={0}
                    max={99}
                    isInteger={true}
                  />
                </div>
                <div css={styles.betweenDays}> ～ </div>
                <div css={styles.digestionDays}>
                  <HourInput
                    name="extinctionDaysEnd"
                    value={extinctionDaysEnd === "" ? "" : extinctionDaysEnd}
                    onChange={(e) => setExtinctionDaysEnd(e.target.value)}
                    onBlur={(e) => setExtinctionDaysEndTmp(extinctionDaysEnd || "")}
                    label="日"
                    min={0}
                    max={99}
                    isInteger={true}
                  />
                </div>
              </FlexBox>
            </div>
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label=" " />
                </div>
                <div css={styles.select}>
                  <Checkbox
                    id="targetGrantTenDays"
                    name="targetGrantTenDays"
                    value=""
                    label="当年付与数10日以上のスタッフを対象とする"
                    checked={getBoolean(targetGrantTenDays)}
                    onChange={() => setTargetGrantTenDays(!targetGrantTenDays)}
                  />
                </div>
              </FlexBox>
            </div>
            {/* <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel
                    label="次回付与日までに"
                  />
                </div>
                <div css={styles.select}>
                  <HourInput
                    name="untilNextGrantDate"
                    value={(untilNextGrantDate === '') ? '' : Number(untilNextGrantDate)}
                    onChange={(e) => setUntilNextGrantDate(e.target.value)}
                    label="日"
                    min={0}
                    max={99}
                  />
                </div>
              </FlexBox>
            </div> */}
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 140px;
              }
              table th:nth-of-type(2) {
                width: 150px;
              }
              table th:nth-of-type(3) {
                width: 140px;
              }
              table th:nth-of-type(4) {
                width: 150px;
              }
              table th:nth-of-type(5) {
                width: 150px;
              }
              table th:nth-of-type(6) {
                width: 150px;
              }
              table th:nth-of-type(7) {
                width: 150px;
              }
              table th:nth-of-type(8) {
                width: 180px;
              }
              table th:nth-of-type(9) {
                width: 180px;
              }
              table th:nth-of-type(10) {
                width: 150px;
              }
              table th:nth-of-type(11) {
                width: 150px;
              }
              table th:nth-of-type(12) {
                width: 150px;
              }
              table th:nth-of-type(13) {
                width: 150px;
              }
              table tr td,
              table tr th {
                text-align: center;
              }
            `}
          >
            <FormSubmitArea customStyle={styles.customStyle}>
              {detailRole.editable === 1 && detailRole.importFlag === 1 ? (
                <IconLabelButton
                  onClick={() => history.push("/attendHolidayManagement/attendHolidayManagementImport/Paid")}
                  iconType="addCircle"
                  text="インポート"
                />
              ) : null}
              {detailRole.downloadFlag ? (
                <IconLabelButton onClick={() => downloadPaidHolidayStatus()} iconType="download" text="ダウンロード" />
              ) : null}
              {/* <IconLabelButton onClick={() => downloadPaidHolidayStatus(true)} iconType="download" text="全組織ダウンロード" /> */}
            </FormSubmitArea>
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={paidHolidayStatusList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={1}
              />
            </div>
          </div>

          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="有給編集"
            submitText="登録する"
            closeText="キャンセル"
            onSubmit={callSaveUseItemForPaidHolidayStatus}
          >
            {detailItems && (
              <div style={{ width: "350px", margin: "0 auto", fontSize: "14px" }} css={styles.table}>
                <VerticalLabelTextLayout label={`スタッフコード`} variable={dataDetail.staffCode} />
                <VerticalLabelTextLayout label={`スタッフ名`} variable={dataDetail.staffName} />

                <table className="tblPopup" style={{ width: "100%", fontSize: "14px" }}>
                  <tbody>
                    {detailItems.useLastFirstGrantFlg && (
                      <tr>
                        <td className="active">初回繰越数</td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px", marginRight: "15px" }}>
                              <HourInput
                                name="useItemsLastFirstGrantDaysNum"
                                value={Number(detailItems.lastFirstGrantDaysNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    lastFirstGrantDaysNum: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="useItemsLastFirstGrantTimesNum"
                                value={Number(detailItems.lastFirstGrantTimesNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    lastFirstGrantTimesNum: Number(e.target.value),
                                  })
                                }
                                label="時間"
                                min={0}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useFirstGrantFlg && (
                      <tr>
                        <td className="active">初回付与数</td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px", marginRight: "15px" }}>
                              <HourInput
                                name="useItemsFirstGrantDaysNum"
                                value={Number(detailItems.firstGrantDaysNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    firstGrantDaysNum: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="useItemsFirstGrantTimesNum"
                                value={Number(detailItems.firstGrantTimesNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    firstGrantTimesNum: Number(e.target.value),
                                  })
                                }
                                label="時間"
                                min={0}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useLastGrantFlg && (
                      <tr>
                        <td className="active" rowSpan={1} style={{ color: "#666666" }}>
                          前年繰越数
                        </td>
                        <td rowSpan={1}>
                          <FlexBox>
                            <div style={{ width: "120px", marginRight: "15px" }}>
                              <HourInput
                                name="useItemsLastGrantDaysNum"
                                value={Number(detailItems.lastGrantDaysNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    lastGrantDaysNum: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="useItemsLastGrantTimesNum"
                                value={Number(detailItems.lastGrantTimesNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    lastGrantTimesNum: Number(e.target.value),
                                  })
                                }
                                label="時間"
                                min={0}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useCurrentGrantFlg && (
                      <tr>
                        <td className="active" style={{ color: "#666666" }}>
                          当年付与数
                        </td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px", marginRight: "15px" }}>
                              <HourInput
                                name="useItemsGrantDaysNum"
                                value={Number(detailItems.grantDaysNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    grantDaysNum: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                step={0.5}
                              />
                            </div>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="useItemsGrantTimesNum"
                                value={Number(detailItems.grantTimesNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    grantTimesNum: Number(e.target.value),
                                  })
                                }
                                label="時間"
                                min={0}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </Modal>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default ManagementInformationForm;
