import React from "react";
import ApplyingStampDomain from "../domain/applyingStampDomain";
import ApplyingHolidayDomain from "../domain/applyingHolidayDomain";
import ApplyingOvertimeDomain from "../domain/applyingOvertimeDomain";
import ApplyingAttendAllowanceDomain from "../domain/applyingAttendAllowanceDomain";
import ApplyingHolidayWorkDomain from "../domain/applyingHolidayWorkDomain";
import ApplyingTransferDomain from "../domain/applyingTransferDomain";
import Applying36AgreementDomain from "../domain/applying36AgreementDomain";
import ApplyingWorkScheduleChangeAppDomain from "../domain/applyingWorkScheduleChangeAppDomain";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import OvertimeAccordion from "./OvertimeAccordion";
import AttendAllowanceAccordion from "./AttendAllowanceAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";
import StockPaidHoliday from "./StockPaidHoliday";
import ApplyingStockPaidDomain from "../domain/applyingStockPaidDomain";

const ApplyingTab: React.FC<{
  stampList: ApplyingStampDomain[];
  holidayList: ApplyingHolidayDomain[];
  overtimeList: ApplyingOvertimeDomain[];
  attendAllowanceList: ApplyingAttendAllowanceDomain[];
  holidayWorkList: ApplyingHolidayWorkDomain[];
  transferList: ApplyingTransferDomain[];
  agreement36List: Applying36AgreementDomain[];
  workScheduleList: ApplyingWorkScheduleChangeAppDomain[];
  stockPaidList: ApplyingStockPaidDomain[];

  setApplyingTransferList: any;
  setApplyingStampList: any;
  setApplyingHolidayList: any;
  setApplyingOvertimeList: any;
  setApplyingHolidayWorkList: any;
  setApplyingAgreement36List: any;
  setApplyingWorkScheduleList: any;
  setApplyingStockPaidList: any;
}> = ({
  stampList,
  holidayList,
  overtimeList,
  attendAllowanceList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleList,
  stockPaidList,

  setApplyingTransferList,
  setApplyingStampList,
  setApplyingHolidayList,
  setApplyingOvertimeList,
  setApplyingHolidayWorkList,
  setApplyingAgreement36List,
  setApplyingWorkScheduleList,
  setApplyingStockPaidList,
}) => {
  return (
    <div>
      <StampAccordion list={stampList} setApplyingStampList={setApplyingStampList} />

      <HolidayAccordion list={holidayList} setApplyingHolidayList={setApplyingHolidayList} />

      <OvertimeAccordion list={overtimeList} setApplyingOvertimeList={setApplyingOvertimeList} />

      <AttendAllowanceAccordion list={attendAllowanceList} />

      <HolidayWorkAccordion list={holidayWorkList} setApplyingHolidayWorkList={setApplyingHolidayWorkList} />

      <TransferAccordion list={transferList} setApplyingTransferList={setApplyingTransferList} />

      <Agreement36Accordion list={agreement36List} setApplyingAgreement36List={setApplyingAgreement36List} />

      <WorkScheduleChangeApplicationAccordion
        list={workScheduleList}
        setApplyingWorkScheduleList={setApplyingWorkScheduleList}
      />

      <StockPaidHoliday list={stockPaidList} setApplyingStockPaidList={setApplyingStockPaidList} />
    </div>
  );
};

export default ApplyingTab;
