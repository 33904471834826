/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const style = css({
  margin: '0',
  padding: '6px',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontFamily: 'inherit',
  textAlign: 'center',
  '&:disabled': {
    cursor: 'not-allowed'
  }
});

interface ClickableIconButtonProps {
  onClick: () => void;
  additionalCss?: SerializedStyles;
  disabled?: boolean;
}

const ClickableIconButton: React.FC<ClickableIconButtonProps> = ({
  children, onClick, additionalCss, disabled = false
}) => (
  <button type="button" css={additionalCss ? css(style, additionalCss) : style} onClick={onClick} disabled={disabled}>
    {children}
  </button>
);

export default ClickableIconButton;
