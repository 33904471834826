/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Appbar from "components/organismos/Appbar";
import BodyText from "components/atoms/BodyText";
import { grayScale, textFontSize } from "components/styles";
import { notMinScreen } from "utility/mediaQueries";

const styles = {
  formWrapper: css({
    background: "white",
    margin: "7em auto",
    padding: "50px 0 60px 0",
    boxShadow: "0px 4px 20px rgba(102, 102, 102, 0.3)",
    borderRadius: "3px",
    maxWidth: "570px",
    [notMinScreen("md")]: {
      margin: "3em 12px",
    },
  }),
  titleWrapper: css({
    textAlign: "center",
    marginBottom: "20px",
  }),
  title: css({
    textAlign: "center",
    padding: "0 0",
    margin: "auto",
  }),
  form: css({
    padding: "0 1.5em",
  }),
  formInputWrapper: css({
    margin: "auto",
    width: "340px",
    [notMinScreen("md")]: {
      width: "auto",
      margin: "0 8px",
    },
  }),
  noteForm: css({
    textAlign: "center",
    padding: "0 20px 0 20px",
    color: grayScale.gray60,
    fontSize: textFontSize.re,
    marginTop: "30px",
    [notMinScreen("md")]: {
      textAlign: "left",
    },
  }),
};

const LoginFormContainer: React.FC<{
  titleForm: string;
  noteForm?: string;
}> = ({ children, titleForm, noteForm = "" }) => {
  return (
    <React.Fragment>
      <Appbar staffName="" title={titleForm} />
      <div css={styles.formWrapper}>
        <div css={styles.titleWrapper}>
          <BodyText size="xxl" color={grayScale.gray100} customStyle={styles.title}>
            {titleForm}
          </BodyText>
        </div>

        <div css={styles.noteForm}>
          <p dangerouslySetInnerHTML={{ __html: noteForm }} />
        </div>

        <div css={styles.formInputWrapper}>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default LoginFormContainer;
