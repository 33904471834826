import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendHelpLayoutList from 'components/organismos/master/attend/AttendHelpList';

const AttendHelpListPage: React.FC = () => (
  <SidebarTemplate pageTitle="他組織支援">
    <AttendHelpLayoutList />
  </SidebarTemplate>
);

export default AttendHelpListPage;
