/** @jsx jsx */

import React, { useCallback, useState } from 'react';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import HourInput from 'components/atoms/Form/HourInput';
import productColor, { grayScale } from 'components/styles';
import { css, jsx} from '@emotion/core';
import { useParams } from 'react-router-dom';
import Button, { PrimaryButton, SecondaryButton } from 'components/atoms/Button';
import { getWorkingDaysPreviousYear, deleteWorkingDayById } from 'api/workingDay';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import useToastNotification from 'hooks/useToastNotification';
import { useWorkingDaysDomainAddForm } from './hooks';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormLabel from 'components/atoms/Form/FormLabel';
import Modal from 'components/molecules/Modal';
import BlockUI from 'components/molecules/BlockUi';

// create style form

const PrescribedDaysCreateForm: React.FC = () => {
  const { workingDaysId } = useParams();
  const { successNotification, errorNotification } = useToastNotification();
  const {
    formik, confirmModalOpen, setConfirmModalOpen, closeConfirmModal,
    handleGetWorkingDays, history, blocking, detailRole
  } = useWorkingDaysDomainAddForm(workingDaysId);
  /**
   *
   *
  */
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const closeConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModalOpen(false);
  }, []);

  const [selectedPrescribed, setSelectedPrescribed] = useState(
    WorkingDaysDomain.generateInitial()
  );
  const openConfirmDeleteModal = useCallback((workingDay: WorkingDaysDomain) => {
    setConfirmDeleteModalOpen(true);
    setSelectedPrescribed(workingDay);
  }, []);
  const handleChangeYear = async (year: any) => {
    // set year
    formik.setFieldValue('targetYearStr', year);

    // update working detail
    if (workingDaysId) {
      await handleGetWorkingDays(year, workingDaysId);
    }
  };

  const deletePrescribed = useCallback(() => {
    deleteWorkingDayById(selectedPrescribed.workingDaysId)
      .then(() => {
        setConfirmDeleteModalOpen(false);
        successNotification("削除しました。");
        history.push('/masterWorkingDays');
      })
      .catch((error) => {
        setConfirmDeleteModalOpen(false);
        if (
          error.response.data.errors != null &&
          error.response.data.errors.length !== 0
        ) {
          const listErr = error.response.data.errors;
          let stringErr = "";
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    selectedPrescribed.workingDaysId,
    successNotification,
    history
  ]);

  /**
   *  handle on click button 対象年の前年の所定労働時間を設定
   */
  const handleSetWorkingDays = async (e: any) => {
    //
    const rawData = formik.values.getRawData();
    // eslint-disable-next-line no-shadow
    const { targetYearStr, workingDaysId } = rawData;
    getWorkingDaysPreviousYear(targetYearStr, workingDaysId).then((response: any) => {
      if (response) {
        const { workingDaysDetails } = response;
        if (workingDaysDetails) {
          formik.setFieldValue('workingDaysDetails', workingDaysDetails);
        } else {
          formik.setFieldValue('workingDaysDetails', WorkingDaysDomain.generateInitial().workingDaysDetails);
        }
      }
    }).catch((error : any) => {
      setConfirmModalOpen(false);
      if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any, index: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    });
  };

  return (
    <BlockUI blocking={blocking}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{margin: '0 auto', width: "40%"}}>
          <FormField>
            <TextForm
              name="workingDaysPatternCode"
              label="パターンコード"
              value={String(formik.values.workingDaysPatternCode)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.workingDaysPatternCode}
            />
          </FormField>

          <FormField>
            <TextForm
              name="workingDaysPatternName"
              label="パターン名"
              value={String(formik.values.workingDaysPatternName)}
              onChange={formik.handleChange}
              errorMsg={formik.errors.workingDaysPatternName}
            />
          </FormField>

          <FormField customStyle={css({display: 'inline'})}>
            <div style={{ paddingRight: '10px', paddingBottom: '10px', paddingTop: '6px'}}>
              <FormLabel
              label="対象年">
              </FormLabel>
            </div>

            <div style={{ paddingRight: '10px', paddingBottom: '30px'}}>
              <YearPicker
                selectedYear={formik.values.targetYearStr}
                onChange={(year: Date) => {handleChangeYear(year)}}
              />
            </div>
            <div>
            { (workingDaysId && detailRole.editable === 1)
              ? (
                <div onClick={handleSetWorkingDays} style={{ color: productColor.primary, cursor: 'grab', paddingBottom: '20px' }}>
                <FormLabel
                  customStyle={css({color: '#007BC3', font: '12px'})}
                  label="前年の内容を取り込む">
                </FormLabel>
                </div>
              ) : ''}
            </div>
          </FormField>
          </div>
          <div css={css`
              table, th, td {
                border: 1px solid ${grayScale.gray10};
                padding: 7px;
              }
              table td:nth-of-type(1){
                width: 330px;
                text-align: center;
              }
              table td:nth-of-type(2) {
                padding-left: 70px;
              }
              table {
                border-collapse: collapse;
              }
            `}>
            <table className="table-no-border-left-right" style={{ width: '100%' }}>
              <thead>
                <tr style={{
                  height: '40px',
                }}
                >
                  <th style={{ fontWeight: 'inherit' }}><FormLabel customStyle={css({fontWeight: 'bold'})} label="月"></FormLabel></th>
                  <th style={{ fontWeight: 'inherit', textAlign: 'left', paddingLeft: '70px'}}><FormLabel customStyle={css({fontWeight: 'bold'})} label="所定労働日数"></FormLabel></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> 1月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays1"
                      value={formik.values.workingDaysDetails.workingDays1 !== '' && formik.values.workingDaysDetails.workingDays1 >= 0 ? formik.values.workingDaysDetails.workingDays1 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 2月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays2"
                      value={formik.values.workingDaysDetails.workingDays2 !== '' && formik.values.workingDaysDetails.workingDays2 >= 0 ? formik.values.workingDaysDetails.workingDays2 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={29}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 3月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays3"
                      value={formik.values.workingDaysDetails.workingDays3 !== '' && formik.values.workingDaysDetails.workingDays3 >= 0 ? formik.values.workingDaysDetails.workingDays3 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 4月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays4"
                      value={formik.values.workingDaysDetails.workingDays4 !== '' && formik.values.workingDaysDetails.workingDays4 >= 0 ? formik.values.workingDaysDetails.workingDays4 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 5月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays5"
                      value={formik.values.workingDaysDetails.workingDays5 !== '' && formik.values.workingDaysDetails.workingDays5 >= 0 ? formik.values.workingDaysDetails.workingDays5 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 6月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays6"
                      value={formik.values.workingDaysDetails.workingDays6 !== '' && formik.values.workingDaysDetails.workingDays6 >= 0 ? formik.values.workingDaysDetails.workingDays6 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 7月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays7"
                      value={formik.values.workingDaysDetails.workingDays7 !== '' && formik.values.workingDaysDetails.workingDays7 >= 0 ? formik.values.workingDaysDetails.workingDays7 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 8月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays8"
                      value={formik.values.workingDaysDetails.workingDays8 !== '' && formik.values.workingDaysDetails.workingDays8 >= 0 ? formik.values.workingDaysDetails.workingDays8 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 9月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays9"
                      value={formik.values.workingDaysDetails.workingDays9 !== '' && formik.values.workingDaysDetails.workingDays9 >= 0 ? formik.values.workingDaysDetails.workingDays9 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 10月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays10"
                      value={formik.values.workingDaysDetails.workingDays10 !== '' && formik.values.workingDaysDetails.workingDays10 >= 0 ? formik.values.workingDaysDetails.workingDays10 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 11月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays11"
                      value={formik.values.workingDaysDetails.workingDays11 !== '' && formik.values.workingDaysDetails.workingDays11 >= 0 ? formik.values.workingDaysDetails.workingDays11 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={30}
                    />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> 12月 </td>
                  <td>
                    <div style={{width: '80px'}}>
                    <HourInput
                      name="workingDaysDetails.workingDays12"
                      value={formik.values.workingDaysDetails.workingDays12 !== '' && formik.values.workingDaysDetails.workingDays12 >= 0 ? formik.values.workingDaysDetails.workingDays12 : 0}
                      label="日"
                      onChange={formik.handleChange}
                      min={0}
                      max={31}
                    />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        <div style={{paddingTop: '90px', margin: '0 auto', width: "40%"}}>
          <div style={{paddingBottom: '70px', display: 'table', margin: '0 auto'}}>
            <FormSubmitArea>
              <FlexBox>
                {detailRole.editable === 1 && (
                  <FlexBoxItem customStyle={ workingDaysId ? css({paddingRight: '15px'}) : css({})}>
                    <Button
                      customStyle = {css('width: 170px;')}
                      text={workingDaysId ? "更新" : "登録"}
                      onClick={formik.handleSubmit}
                    />
                  </FlexBoxItem>
                )}
                {(() => {
                  if (workingDaysId && detailRole.editable === 1) {
                    return (
                      <FlexBoxItem>
                      <SecondaryButton
                      customStyle = {css('width: 170px;')}
                      text="削除"
                      onClick={() => { openConfirmDeleteModal(formik.values) }}
                      />
                      </FlexBoxItem>
                    )
                  }
                  })()}
                </FlexBox>
              <div style={{padding: '5px 0px 0px 0px',  display: 'table', margin: '0 auto'}}>
                <PrimaryButton
                  text="キャンセル"
                  onClick={() => {
                    history.replace('/masterWorkingDays');}}
                  ghost={true}
                />
              </div>
            </FormSubmitArea>
            </div>
          </div>
      </form>

      <ConfirmModal
        title={workingDaysId ? "所定労働日数を更新" : "所定労働日数を登録"}
        content={workingDaysId ? "所定労働日数を更新します。よろしいですか？" : "所定労働日数を登録します。よろしいですか？"}
        submitText={workingDaysId ? "更新する" : "登録する"}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        items={[]}
      />

      <Modal
        open={confirmDeleteModalOpen}
        closeHandler={closeConfirmDeleteModal}
        title="所定労働日数を削除"
        submitText="削除する"
        note="所定労働日数を削除します。よろしいですか？"
        onSubmit={deletePrescribed}
        typeSubmit="reject">
        <div style={{ height: "20px" }}></div>
      </Modal>
    </BlockUI>
  );
};

export default PrescribedDaysCreateForm;
