/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import TextForm from 'components/molecules/TextForm';
import { RecorderButton, PrimaryButton } from 'components/atoms/Button';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { grayScale } from 'components/styles';
import BodyText from 'components/atoms/BodyText';
import { notMinScreen } from 'utility/mediaQueries';
import useIsMobile from 'hooks/useIsMobile';
import { styles } from 'components/organismos/RecorderV1/styles';
const isIpad = window.matchMedia('(max-width: 1024px)').matches;

const customStyle = {
  inputForm: css({
    width: '450px',
    margin: 'auto',
    justifyContent: 'space-between',
    [notMinScreen('lg')]: {
      width: '420px',
    },
    [notMinScreen('md')]: {
      width: 'auto',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '16px',
    },
  }),
};

const BarcodeRecorderUI: React.FC<{
  formik: any;
  dataTimes: any;
  isConfirm: boolean;
  handleSubmit: any;
  disabledRecorder: boolean;
  staffName: string;
  lastStampType: any;
  handleRecorder: any;
}> = ({ formik, dataTimes, isConfirm, handleSubmit, disabledRecorder, staffName, lastStampType, handleRecorder }) => {
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      <div css={styles.formContainer}>
        {dataTimes.currentDay ? (
          <React.Fragment>
            <FlexBox customStyle={styles.dateForm}>
              <BodyText size='lg' color={grayScale.gray100} bold={true}>
                {dataTimes.currentDay}
              </BodyText>
            </FlexBox>
            <FlexBox customStyle={styles.time}>
              <BodyText>{dataTimes.timeNow}</BodyText>
              <BodyText>{dataTimes.second}</BodyText>
            </FlexBox>
          </React.Fragment>
        ) : (
          <div
            style={{
              fontSize: isIpad ? '30px' : '80px',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            サーバ時間取得中・・・
          </div>
        )}

        {!isConfirm ? (
          <FlexBox customStyle={customStyle.inputForm}>
            <FlexBoxItem
              customStyle={css({
                basis: '270px',
                [notMinScreen('md')]: {
                  basis: 0,
                },
              })}
            >
              <TextForm
                name='inputBarcodeId'
                label=''
                value={formik.values.inputBarcodeId}
                onChange={formik.handleChange}
                placeHolder='IDを入力してください'
              />
            </FlexBoxItem>
            <PrimaryButton
              text='IDを入力'
              onClick={() => handleSubmit()}
              disabled={disabledRecorder}
              minWidth={isIpad ? '120px' : '160px'}
              customStyle={css({ fontWeight: 'bold' })}
            />
          </FlexBox>
        ) : (
          <React.Fragment>
            <div style={{ width: '100%', textAlign: 'center', marginBottom: '8px' }}>
              <BodyText color={grayScale.gray100} size={isMobile ? 'xl' : 'xxl'} bold={true}>
                {staffName}
                {(lastStampType === -1 || lastStampType === 3) && 'さん、出勤打刻を押してください。'}
                {(lastStampType === 0 || lastStampType === 2) && 'さん、退勤打刻または休憩開始を押してください。'}
                {lastStampType === 1 && 'さん、休憩終了を押してください。'}
              </BodyText>
            </div>
            <div css={styles.buttonContainer}>
              {(lastStampType === -1 || lastStampType === 3) && (
                <RecorderButton
                  text='出勤'
                  onClick={() => handleRecorder(0)}
                  icon='checkInOffice'
                  background='#007BC3'
                />
              )}
              {(lastStampType === 0 || lastStampType === 2) && (
                <RecorderButton text='退勤' onClick={() => handleRecorder(3)} icon='checkOut' background='#666666' />
              )}

              {(lastStampType === 0 || lastStampType === 2) && (
                <RecorderButton
                  text='休憩開始'
                  onClick={() => handleRecorder(1)}
                  icon='breakStart'
                  background='#00B172'
                />
              )}

              {lastStampType === 1 && (
                <RecorderButton
                  text='休憩終了'
                  onClick={() => handleRecorder(2)}
                  icon='breakEnd'
                  background='#8B2E9C'
                />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default BarcodeRecorderUI;
