import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AreasImportForm from 'components/organismos/master/sales/AreasImportForm';


const AreasImportPage: React.FC = () => (
  <SidebarTemplate pageTitle="エリア情報インポート">
    <AreasImportForm />
  </SidebarTemplate>
);


export default AreasImportPage;
