/** @jsx jsx */
import React, { useEffect, useReducer, useCallback, useState } from "react";
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import VerticalLabelSelectFormV2 from "components/molecules/VerticalLabelSelectFormV2";
import ModalForm from "components/molecules/ModalForm";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import VerticalLabelFormLayoutV2 from "components/atoms/Form/VerticalLabelFormLayoutV2";
import Separater from "components/atoms/Separator";
import ShiftPatternDetailList from "./ShiftPatternDetailList";
import { useDailyShiftContext } from "../contextHook";
import { textColor } from "components/styles";
import { getShift, Shift, postShiftV1 } from "api/shift";
import moment from "moment";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import useToastNotification from "hooks/useToastNotification";
import { getEmployment } from "api/achievementConfirm";
import SelectForm from "components/molecules/SelectForm";
import { useOrgAllSelect } from "hooks/useOrgTreesOptions";
import { useShiftDailyStaffOptions } from "hooks/useShiftDailyStaffOptions";
import { useRoundShiftPatternOptions } from "hooks/useRoundShiftPatternOptions";

const ModalRegisterStaff: React.FC = () => {
  const {
    stateFilter,
    remote,
    setLoading,
    openRegisterModal,
    setOpenRegisterModal,
    fetchEmploymentOptions,
    fetchOrg,
    fetchDrawingInfo,
    shiftPattern: { getWorkdayOptions },
    calendar: { selectedItem, fetchDailyShiftData },
  } = useDailyShiftContext();
  const { successNotification, errorNotification } = useToastNotification();
  const orgTreesOptions = useOrgAllSelect(
    false,
    moment(`${stateFilter.dateFromStr}`).format("YYYY-MM-DD"),
    moment(`${stateFilter.dateFromStr}`).format("YYYY-MM-DD"),
  );

  const [orgCode, setOrgCode] = useState(sessionStorage.getItem("application.orgCode") || "");

  const staffOptions = useShiftDailyStaffOptions(
    stateFilter.selectedOrg,
    orgCode,
    moment(stateFilter.dateFromStr).format("YYYY-MM-DD"),
  );

  const [employment, setEmployment] = useState<any | null>(null);

  const defaultShiftDetailList = [
    {
      startTime: moment(stateFilter.dateFromStr).format("YYYY/MM/DD HH:mm:ss"),
      isStartTimeNextDay: false,
      endTime: moment(stateFilter.dateFromStr).format("YYYY/MM/DD HH:mm:ss"),
      isEndTimeNextDay: false,
      businessId: "",
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
      shiftDetailId: "",
      isRemote: false,
    },
  ];

  useEffect(() => {
    if (orgTreesOptions.length > 0) {
      setOrgCode(orgTreesOptions[0].value);
    }
  }, [orgTreesOptions]);

  useEffect(() => {
    dispatch({
      type: "CHANGE_STAFF",
      payload: staffOptions.length > 0 ? staffOptions[0].value : "",
    });
  }, [staffOptions]);

  const defaultShift = {
    companyCode: "",
    createUser: sessionStorage.getItem("loginUser.staffName") || "",
    updateUser: sessionStorage.getItem("loginUser.staffName") || "",
    createDate: "",
    updateDate: "",
    shiftId: "",
    orgCode: stateFilter.selectedOrg,
    orgName: stateFilter.selectedOrg,
    belongOrgCode: "",
    staffCode: "",
    staffName: "",
    targetDate: moment(stateFilter.dateFromStr).format("YYYY/MM/DD HH:mm:ss"),
    isLegal: false,
    notifiedToStampForgot: false,
    startTime: moment(stateFilter.dateFromStr).format("YYYY/MM/DD HH:mm:ss"),
    endTime: moment(stateFilter.dateFromStr).format("YYYY/MM/DD HH:mm:ss"),
    shiftDetailList: defaultShiftDetailList,
    procType: 0,
    attendType: 0,
    closingHour: 0,
    distinctionHoliday: false,
    shopPlanId: "",
    shopPlan: "",
    shopPlanOrgCode: "",
    shopPlanTargetDate: "",
  };

  const shiftPatternOptions = useRoundShiftPatternOptions(
    stateFilter.selectedOrg,
    employment?.id?.employmentId,
    moment(stateFilter.dateFromStr).format("YYYY-MM-DD"),
  );

  const memorizedReducer = useCallback(
    (state: Shift, { type, payload }: any): Shift => {
      let shiftDetailList = [...state.shiftDetailList];

      switch (type) {
        case "SET_STATE":
          return payload;

        case "CHANGE_STAFF":
          const staff = staffOptions.find((staff) => staff.value === payload);

          state.staffCode = payload;
          state.staffName = staff?.label || "";
          return { ...state };
        case "START_HOUR_ONCHANGE":
          shiftDetailList[payload.index].startTime = payload.startTime;
          return { ...state, startTime: shiftDetailList[0].startTime, shiftDetailList };

        case "END_HOUR_ONCHANGE":
          if (shiftDetailList[payload.index + 1]) {
            shiftDetailList[payload.index + 1].startTime = payload.endTime;
          }
          shiftDetailList[payload.index].endTime = payload.endTime;
          return { ...state, endTime: shiftDetailList[shiftDetailList.length - 1].endTime, shiftDetailList };

        case "START_NEXTDAY_ONCHANGE":
          shiftDetailList[payload.index].isStartTimeNextDay = payload.isStartTimeNextDay;

          shiftDetailList[payload.index].startTime = payload.isStartTimeNextDay
            ? moment(shiftDetailList[payload.index].startTime)
                .add("day", 1)
                .format("YYYY/MM/DD HH:mm:ss")
            : moment(shiftDetailList[payload.index].startTime)
                .subtract("day", 1)
                .format("YYYY/MM/DD HH:mm:ss");

          return { ...state, shiftDetailList };

        case "END_NEXTDAY_ONCHANGE":
          if (shiftDetailList[payload.index + 1]) {
            shiftDetailList[payload.index + 1].isStartTimeNextDay = payload.isEndTimeNextDay;
          }
          shiftDetailList[payload.index].isEndTimeNextDay = payload.isEndTimeNextDay;

          shiftDetailList[payload.index].endTime = payload.isEndTimeNextDay
            ? moment(shiftDetailList[payload.index].endTime)
                .add("day", 1)
                .format("YYYY/MM/DD HH:mm:ss")
            : moment(shiftDetailList[payload.index].endTime)
                .subtract("day", 1)
                .format("YYYY/MM/DD HH:mm:ss");

          return { ...state, shiftDetailList };

        case "IS_BREAK_ONCHANGE":
          shiftDetailList[payload.index].businessId = payload.businessId;
          return { ...state, shiftDetailList };

        case "IS_REMOTE_ONCHANGE":
          shiftDetailList[payload.index].isRemote = payload.isRemote;
          return { ...state, shiftDetailList };

        case "WORKDAY_TYPE_ONCHANGE":
          if (!employment) return state;

          switch (payload) {
            case "legal":
              defaultShiftDetailList[0].isEndTimeNextDay = true;
              defaultShiftDetailList[0].startTime = moment(state.targetDate)
                .add("hour", employment.closingHour)
                .format("YYYY/MM/DD HH:mm:ss");
              defaultShiftDetailList[0].endTime = moment(state.targetDate)
                .add({ day: 1, hour: employment.closingHour })
                .format("YYYY/MM/DD HH:mm:ss");
              return {
                ...state,
                shiftPatternId: "",
                holidayId: "legal",
                isLegal: true,
                attendType: 1,
                shiftDetailList: defaultShiftDetailList,
              };

            case "scheduled":
              defaultShiftDetailList[0].isEndTimeNextDay = true;
              defaultShiftDetailList[0].startTime = moment(state.targetDate)
                .add("hour", employment.closingHour)
                .format("YYYY/MM/DD HH:mm:ss");
              defaultShiftDetailList[0].endTime = moment(state.targetDate)
                .add({ day: 1, hour: employment.closingHour })
                .format("YYYY/MM/DD HH:mm:ss");
              return {
                ...state,
                shiftPatternId: "",
                holidayId: "scheduled",
                isLegal: false,
                attendType: 2,
                shiftDetailList: defaultShiftDetailList,
              };

            default:
              defaultShiftDetailList[0].isEndTimeNextDay = false;
              defaultShiftDetailList[0].startTime = moment(state.targetDate)
                .startOf("day")
                .format("YYYY/MM/DD HH:mm:ss");
              defaultShiftDetailList[0].endTime = moment(state.targetDate)
                .startOf("day")
                .format("YYYY/MM/DD HH:mm:ss");
              return {
                ...state,
                shiftPatternId: "",
                holidayId: "",
                isLegal: false,
                attendType: 0,
                shiftDetailList: defaultShiftDetailList,
              };
          }

        case "SHIFTPATTERN_ONCHANGE":
          let shiftPattern = shiftPatternOptions.find((pattern) => pattern.shiftPatternId === payload);

          let newShiftDetailList;
          if (shiftPattern) {
            newShiftDetailList = shiftPattern.attendShiftPatternDetails.map((patternDetail) => {
              return {
                startTime: moment(`${state.targetDate.split(" ")[0]} ${patternDetail.startTime}`).format(
                  "YYYY/MM/DD HH:mm:ss",
                ),
                isStartTimeNextDay: patternDetail.isStartTimeNextDay,
                endTime: moment(`${state.targetDate.split(" ")[0]} ${patternDetail.endTime}`).format(
                  "YYYY/MM/DD HH:mm:ss",
                ),
                isEndTimeNextDay: patternDetail.isEndTimeNextDay,
                businessId: patternDetail.businessId || "",
                createUser: patternDetail.createUser,
                updateUser: patternDetail.updateUser,
                isRemote: patternDetail.isRemote,
              };
            });
          } else {
            newShiftDetailList = defaultShiftDetailList;
          }
          return { ...state, shiftPatternId: payload, shiftDetailList: newShiftDetailList };

        case "ADD_SHIFTDETAILLIST":
          let newDefaultShiftDetail = { ...defaultShiftDetailList[0] };
          newDefaultShiftDetail.startTime = state.shiftDetailList[state.shiftDetailList.length - 1].endTime;
          newDefaultShiftDetail.isStartTimeNextDay =
            state.shiftDetailList[state.shiftDetailList.length - 1].isEndTimeNextDay;
          let prevEndDate = moment(state.shiftDetailList[state.shiftDetailList.length - 1].endTime);
          newDefaultShiftDetail.endTime = !state.shiftDetailList[state.shiftDetailList.length]?.endTime
            ? prevEndDate.hour(Number(0)).format("YYYY/MM/DD HH:mm:ss")
            : state.shiftDetailList[state.shiftDetailList.length]?.endTime;
          return { ...state, shiftDetailList: [...state.shiftDetailList, newDefaultShiftDetail] };

        case "DELETE_SHIFTDETAILLIST":
          shiftDetailList.splice(payload, 1);
          return { ...state, shiftDetailList };

        default:
          return state;
      }
    },
    [employment, shiftPatternOptions],
  );

  const [state, dispatch] = useReducer(memorizedReducer, defaultShift);

  const resetShift = () => {
    dispatch({ type: "SET_STATE", payload: defaultShift });
  };

  const onSubmit = async () => {
    try {
      if (orgCode === state.orgCode) {
        errorNotification("画面表示組織と異なる組織を選択してください。");
        return;
      }

      if (!state.staffCode) {
        errorNotification("スタッフを選択してください。");
        return;
      }

      let newState = formatToSubmitObject(state);

      setLoading(true);
      await postShiftV1(newState);
      await fetchDailyShiftData();
      let employmentOptions = await fetchEmploymentOptions(stateFilter.selectedOrg, stateFilter.dateFromStr);
      await fetchOrg(stateFilter.dateFromStr);
      await fetchDrawingInfo(
        stateFilter.selectedOrg,
        stateFilter.dateFromStr,
        stateFilter.isAttendExists,
        employmentOptions,
      );
      successNotification("支援スタッフを追加しました。");
      //reset
      setOpenRegisterModal(false);
      resetShift();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const formatToSubmitObject = (state: Shift) => {
    return {
      cumulativeClosingDate: 0,
      staffCode: state.staffCode,
      staffName: state.staffName,
      attendType: state.attendType,
      orgCode: state.orgCode,
      orgName: state.orgName,
      shiftPatternId: state.shiftPatternId || "",
      shiftDetailList: state.shiftDetailList.map((detail) => {
        return {
          startHourForm: moment(detail.startTime).format("HH"),
          startTimeForm: moment(detail.startTime).format("mm"),
          startTime: detail.startTime,
          isStartTimeNextDay: detail.isStartTimeNextDay,
          endHourForm: moment(detail.endTime).format("HH"),
          endTimeForm: moment(detail.endTime).format("mm"),
          endTime: detail.endTime,
          isEndTimeNextDay: detail.isEndTimeNextDay,
          isEndTimeNextDayForm: detail.isEndTimeNextDay,
          businessId: detail.businessId || "",
          shiftId: state.shiftId,
          shiftDetailId: detail.shiftDetailId || "",
          createUser: detail.createUser,
          updateUser: detail.updateUser,
          isRemote: remote ? detail.isRemote : false,
        };
      }),
      shiftId: state.shiftId,
      targetDate: state.targetDate,
      startTime: state.shiftDetailList[0].startTime,
      endTime: state.shiftDetailList[state.shiftDetailList.length - 1].endTime,
      procType: state.procType,
      createUser: state.createUser,
      updateUser: state.updateUser,
    };
  };

  const formatShiftDate = (shift: Shift) => {
    return {
      companyCode: shift.companyCode,
      createUser: shift.createUser,
      updateUser: shift.updateUser,
      createDate: shift.createDate,
      updateDate: shift.updateDate,
      shiftId: shift.shiftId,
      orgCode: shift.orgCode,
      orgName: shift.orgName,
      belongOrgCode: shift.belongOrgCode,
      staffCode: shift.staffCode,
      staffName: shift.staffName,
      targetDate: moment(shift.targetDate).format("YYYY/MM/DD HH:mm:ss"),
      isLegal: shift.isLegal,
      notifiedToStampForgot: shift.notifiedToStampForgot,
      startTime: moment(shift.startTime).format("YYYY/MM/DD HH:mm:ss"),
      endTime: moment(shift.endTime).format("YYYY/MM/DD HH:mm:ss"),
      shiftDetailList: shift.holidayId
        ? [
            {
              startTime: moment(shift.startTime)
                .hour(shift.closingHour)
                .format("YYYY/MM/DD HH:mm:ss"),
              isStartTimeNextDay: false,
              endTime: moment(shift.endTime)
                .hour(shift.closingHour)
                .format("YYYY/MM/DD HH:mm:ss"),
              isEndTimeNextDay: true,
              businessId: "",
              createUser: shift.createUser,
              updateUser: shift.createUser,
              shiftDetailId: "",
              isRemote: false,
            },
          ]
        : shift.shiftDetailList.map((detail) => ({
            startTime: moment(detail.startTime).format("YYYY/MM/DD HH:mm:ss"),
            isStartTimeNextDay: detail.isStartTimeNextDay,
            endTime: moment(detail.endTime).format("YYYY/MM/DD HH:mm:ss"),
            isEndTimeNextDay: detail.isEndTimeNextDay,
            businessId: detail.businessId,
            createUser: detail.createUser,
            updateUser: detail.updateUser,
            shiftDetailId: detail.shiftDetailId || "",
            isRemote: detail.isRemote || false,
          })),
      procType: shift.procType,
      attendType: shift.attendType,
      closingHour: shift.closingHour,
      distinctionHoliday: shift.distinctionHoliday,
      shiftPatternId: shift.shiftPatternId,
      requestNote: shift.requestNote || "",
      holidayId: shift.holidayId || "",
      notifiedBeforeShiftStart: shift.notifiedBeforeShiftStart || "",
    };
  };

  useEffect(() => {
    const fetchShift = async (shiftId: string) => {
      try {
        setLoading(true);

        let shiftData = await getShift(shiftId);
        shiftData = formatShiftDate(shiftData);
        dispatch({ type: "SET_STATE", payload: shiftData });
      } catch (error) {
        errorNotification(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchEmployment = async (targetDateFrom: string, targetDateTo: string, staffCode: String) => {
      const params = {
        targetDateFrom: moment(new Date(targetDateFrom)).format("YYYY年MM月DD日"),
        targetDateTo: moment(new Date(targetDateTo)).format("YYYY年MM月DD日"),
        staffCode,
      };
      getEmployment(params).then((response: any) => {
        setEmployment(response);
      });
    };
    fetchEmployment(state.targetDate, state.targetDate, state.staffCode);

    if (selectedItem) {
      fetchShift(selectedItem.idToString);
    }
  }, [state.staffCode]);

  return (
    <ModalForm
      open={openRegisterModal}
      closeHandler={() => setOpenRegisterModal(false)}
      title="支援スタッフ追加"
      submitText="登録"
      onSubmit={onSubmit}
      closeText="キャンセル"
      width="800px"
      contentWidth="90%"
    >
      <VerticalLabelTextLayout label={`対象日`} variable={moment(state.targetDate).format("YYYY/MM/DD")} />

      <VerticalLabelFormLayout
        label="組織名"
        input={
          <div css={css({ width: "600px", marginBottom: "20px" })}>
            <SelectForm
              label=""
              name="orgCode"
              value={String(orgCode)}
              setValue={(val: string) => setOrgCode(val)}
              options={orgTreesOptions}
              width="100%"
            />
          </div>
        }
      />

      <VerticalLabelFormLayoutV2
        label="スタッフ"
        customCss={css({ color: textColor.main })}
        zIndex={10}
        input={
          <div css={css({ width: "600px", marginBottom: "20px" })}>
            <VerticalLabelSelectFormV2
              label=""
              placeholder="支援スタッフ"
              name="staff"
              value={state.staffCode}
              setValue={(staffCode: string) => {
                dispatch({
                  type: "CHANGE_STAFF",
                  payload: staffCode,
                });
              }}
              options={staffOptions}
            />
          </div>
        }
      />

      {employment && (
        <React.Fragment>
          <VerticalLabelFormLayoutV2
            label="勤務日種別"
            customCss={css({ color: textColor.main })}
            input={
              <div css={css({ width: "600px", marginBottom: "20px" })}>
                <VerticalLabelSelectFormV2
                  label=""
                  placeholder="通常"
                  name="workdayType"
                  value={state.holidayId && state.isLegal ? "legal" : state.holidayId ? "scheduled" : "normal"}
                  setValue={(type: string) => {
                    dispatch({
                      type: "WORKDAY_TYPE_ONCHANGE",
                      payload: type,
                    });
                  }}
                  options={getWorkdayOptions(employment.distinctionHoliday)}
                />
              </div>
            }
          />

          <VerticalLabelTextLayout label={`出勤組織`} variable={state?.orgName} />

          <VerticalLabelFormLayout
            label="シフトパターン"
            input={
              <div css={css({ width: "600px" })}>
                <VerticalLabelSelectForm
                  label=""
                  placeholder="通常出勤"
                  name="shiftpattern"
                  value={state.shiftPatternId || ""}
                  setValue={(shiftpatternId) => {
                    dispatch({
                      type: "SHIFTPATTERN_ONCHANGE",
                      payload: shiftpatternId,
                    });
                  }}
                  options={[{ label: "-", value: "" }].concat(
                    shiftPatternOptions.map((pattern) => ({
                      label: pattern.shiftPatternName,
                      value: pattern.shiftPatternId,
                    })),
                  )}
                  disable={state?.holidayId ? true : false}
                />
              </div>
            }
          />

          <Separater margin="0.5rem 0 0.8rem 0" />

          <BodyText customStyle={css({ display: "block" })}>シフト時間</BodyText>

          {state && (
            <ShiftPatternDetailList
              shiftDetail={state.shiftDetailList}
              isDisable={state.shiftPatternId || state.holidayId ? true : false}
              dispatch={dispatch}
              remote={remote}
            />
          )}
        </React.Fragment>
      )}
    </ModalForm>
  );
};

export default ModalRegisterStaff;
