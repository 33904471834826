import React from 'react'
import SidebarTemplate from 'components/templates/SidebarTemplate'; 
import AttendApplicationApprovalV3 from 'components/organismos/master/attend/AttendApplicationApprovalV3';

const AttendApplicationApprovalV3Page = () => {
  return(
    <SidebarTemplate pageTitle="勤怠承認">
      <AttendApplicationApprovalV3/>
    </SidebarTemplate>
  )
}

export default AttendApplicationApprovalV3Page