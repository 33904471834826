import { useEffect, useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { deleteSpecialSalary, getSpecialSalary, postSpecialSalary } from "api/organization";
import useToastNotification from "hooks/useToastNotification";
import { useFormik } from "formik";
import OrganizationSpecialSalaryDomain from "domain/master/labor/organizationSpecialSalary";

export const useSpecialSalary = (isEditMode: boolean = false) => {
  const history = useHistory();

  //blocking UI
  const [blocking, setBlocking] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const { orgCode } = useParams<{ orgCode: string }>();

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // 削除確認モーダルの開閉状態
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const { successNotification, errorNotification } = useToastNotification();

  // 保存
  const onSubmit = async (values: OrganizationSpecialSalaryDomain) => {
    if (!confirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setConfirmModalOpen(true);
      return;
    }

    if (!values.applyStartMonth) {
      formik.setFieldError("applyStartMonth", "適用開始月を入力してください");
      setConfirmModalOpen(false);
      return;
    }

    const postData = values.getRawDataPost();

    postSpecialSalary(postData)
      .then((response: any) => {
        setConfirmModalOpen(false);

        fetchData();

        successNotification("更新しました");
        // 新規登録の場合は組織一覧ページへ遷移
        if (!isEditMode) {
          history.push("/organizations/");
        }
      })
      .catch((error) => {
        setConfirmModalOpen(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} <br />`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  };

  // 削除
  const onSubmitDeleteSpecialSalary = async () => {
    if (!confirmDeleteModalOpen) {
      // 削除確認モーダルが表示されていなければ表示する
      setConfirmDeleteModalOpen(true);
      return;
    }

    deleteSpecialSalary(orgCode)
      .then(async (response: any) => {
        setConfirmDeleteModalOpen(false);

        await fetchData();

        successNotification("削除しました");
      })
      .catch((error) => {
        setConfirmDeleteModalOpen(false);
        errorNotification("サーバー側でエラーが発生しました。");
      });
  };

  const formik = useFormik({
    initialValues: OrganizationSpecialSalaryDomain.generateInitial(),
    onSubmit,
  });

  const addSpecialSalary = useCallback(() => {
    let tmpSpecialSalaryList = [...(formik.values.orgSpecialList ?? [])];
    tmpSpecialSalaryList.push({
      id: {
        specialSalaryPatternId: "",
        applyStartMonth: new Date(),
        companyCode: "",
        orgCode: "",
      },
      addSalary: 0,
      applyEndMonth: null,
      orgName: "",
      specialSalaryPatternCode: "",
      specialSalaryPatternName: "",
      patOrgCodes: [],
      applyStartMonthStr: "",
    });
    formik.setFieldValue("orgSpecialList", tmpSpecialSalaryList);
  }, [formik]);

  const deleteSpecialSalaryRow = useCallback(
    (index: number) => () => {
      if (!formik.values.orgSpecialList) {
        return;
      }
      formik.setFieldValue(
        "orgSpecialList",
        formik.values.orgSpecialList.filter((_: any, idx: number) => idx !== index),
      );
    },
    [formik],
  );

  const fetchData = async () => {
    setBlocking(true);

    const specialSalaryData = await getSpecialSalary(orgCode);

    const setData = new OrganizationSpecialSalaryDomain({
      ...specialSalaryData,
      applyStartMonth: specialSalaryData.orgSpecialList?.[0]?.id.applyStartMonth ?? new Date(),
      applyEndMonth: specialSalaryData.orgSpecialList?.[0]?.applyEndMonth ?? null,
    });

    setIsEdit((specialSalaryData.orgSpecialList?.length ?? 0) > 0);

    formik.setValues(setData);

    setBlocking(false);
  };

  // 初期データ取得
  useEffect(() => {
    fetchData();
  }, [orgCode]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  // 削除確認モーダル用
  const closeDeleteConfirmModal = useCallback(() => {
    setConfirmDeleteModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    confirmDeleteModalOpen,
    setConfirmDeleteModalOpen,
    closeDeleteConfirmModal,
    onSubmit,
    onSubmitDeleteSpecialSalary,
    addSpecialSalary,
    deleteSpecialSalaryRow,
    blocking,
    isEdit,
  };
};

export default {};
