/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { useDownload } from './hooks';

const ItemCategoryCntOrderTable: React.FC<{
    getItemsSalesOrder: any,
    targetPeriodData: any
    itemStartTime:any,
    itemEndTime:any,
    formik:any,
    orgCode:any,
    orgTreesOptions:any
  }> = ({
    getItemsSalesOrder,
    targetPeriodData,
    itemStartTime,
    itemEndTime,
    formik,
    orgCode,
    orgTreesOptions,
  }) => {
    const { downloadCategoryCntOrderReportCsv, downloadCategoryCntOrderReportExcel } = useDownload();

    const formatter = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 0,
    });
    const formatterPercent = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
    });
    const [getOrgCodeName, setOrgCodeName] = useState('');
    const orgCodeArr:any[] = [];
    const searchResult:any[] = [];
    const slitList = getItemsSalesOrder;
    useEffect(() => {
      const orgArr = orgCode.split(',');
      orgTreesOptions.filter((options:any) => {
        orgArr.map((orgArrItem:any) => {
          if (options.value === orgArrItem) {
            orgCodeArr.push(options.label.split(' ')[1]);
          }
        });
      });

      if (slitList.items) {
        slitList.items.map((slitData:any, index:any) => {
          searchResult.push([
            slitData.itemRank || '',
            formatter.format(slitData.itemOrder) || 0,
            slitData.smallDivisionCode || '',
            slitData.smallDivisionName || '',
            formatter.format(slitData.totalItem || 0),
            formatter.format(slitData.totalPrice || 0),
            formatterPercent.format(slitData.compositionRatio || 0),
            formatterPercent.format(slitData.cumulativeRatio || 0),
            formatter.format(slitData.totalCost || 0),
            formatter.format(slitData.profit || 0),
          ]);
        });
      }

      const str = orgCodeArr.join('、');
      setOrgCodeName(str);
    }, [formatter, formatterPercent, getItemsSalesOrder, orgCode, orgCodeArr, orgTreesOptions, searchResult, slitList]);

    const targetStoresData = `対象組織：${getOrgCodeName}`;
    const headerInformation = [
      [
        'ランク', '順位', '部門コード', '部門名', '数量', '金額', '数量構成比', '累計構成比', '合計原価', '利益',
      ],
    ];

    let targetDay = '';

    if (formik.values.itemPub === true) {
      targetDay += ' 祝';
    }
    if (formik.values.itemSat === true) {
      targetDay += ' 土';
    }
    if (formik.values.itemSun === true) {
      targetDay += ' 日';
    }
    if (formik.values.itemMon === true) {
      targetDay += ' 月';
    }
    if (formik.values.itemTue === true) {
      targetDay += ' 火';
    }
    if (formik.values.itemWed === true) {
      targetDay += ' 水';
    }
    if (formik.values.itemTur === true) {
      targetDay += ' 木';
    }
    if (formik.values.itemFri === true) {
      targetDay += ' 金';
    }

    const data = {
      formName: '部門別ABC数量順',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      targetDay: `対象曜日： ${targetDay}`,
      targetTime: `対象時間：${itemStartTime}時~${itemEndTime}時`,
      headerInformation,
      searchResult,
      totalInformation: [
        [
          '合計',
          '',
          '',
          '',
          formatter.format(slitList.slitTotal ? slitList.slitTotal.totalItem : 0),
          formatter.format(slitList.slitTotal ? slitList.slitTotal.totalPrice : 0),
          '-',
          '-',
          formatter.format(slitList.slitTotal ? slitList.slitTotal.totalCost : 0),
          formatter.format(slitList.slitTotal ? slitList.slitTotal.profit : 0),
        ],
      ],
    };

    const dataExcel = {
      formName: '部門別ABC数量順',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      targetDay: `対象曜日： ${targetDay}`,
      targetTime: `対象時間：${itemStartTime}時~${itemEndTime}時`,
      headerInformation,
      searchResult,
      totalInformation: [
        [
          '合計',
          '',
          '',
          '',
          formatter.format(slitList.slitTotal ? slitList.slitTotal.totalItem : 0),
          formatter.format(slitList.slitTotal ? slitList.slitTotal.totalPrice : 0),
          '-',
          '-',
          formatter.format(slitList.slitTotal ? slitList.slitTotal.totalCost : 0),
          formatter.format(slitList.slitTotal ? slitList.slitTotal.profit : 0),
        ],
      ],
      mergedRegions: [[(slitList.items ? slitList.items.length + 7 : 7), (slitList.items ? slitList.items.length + 7 : 7), 0, 3]],
      codeColumnIndex: [2],
    };
    const handleExportCSV = () => downloadCategoryCntOrderReportCsv(data);
    const handleExportExcel = () => downloadCategoryCntOrderReportExcel(dataExcel);

    const widthScrollbar = (22 * 110) + 100;
    const eventScroll = (e: { target: any }) => {
      const { target } = e;
      const wrapTblRight = document.getElementById('wrapTblRight');
      const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
      const jsScrollAction = document.getElementById('jsScrollAction');
      const wrapFootertblRight = document.getElementById('wrapFootertblRight');
      const scrollTableNotFound = document.getElementById('scrollTableNotFound');
      if (wrapTblRight) {
        wrapTblRight.scrollLeft = target.scrollLeft;
      }
      if (wrapTblHeadRight) {
        wrapTblHeadRight.scrollLeft = target.scrollLeft;
      }
      if (jsScrollAction) {
        jsScrollAction.scrollLeft = target.scrollLeft;
      }
      if (wrapFootertblRight) {
        wrapFootertblRight.scrollLeft = target.scrollLeft;
      }
      if (scrollTableNotFound) {
        scrollTableNotFound.scrollLeft = target.scrollLeft;
      }
    };
    const calcWidth = document.getElementById('jsCalcWidthFooter')?.offsetWidth;

    return (
      <div>
        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportCSV}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportExcel}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>
        <div className="wrap_sales_management_table_abc wrap_sales_management_table_cnt_order_abc " style={{ overflow: 'hidden', marginTop: '15px' }}>
          <div className="contentHead" style={{ display: 'flex' }}>
            <div className="tblLeft">
              <table>
                <thead>
                  <tr>
                    <th className="text-center"><span>ランク</span></th>
                    <th className="text-center"><span>順位</span></th>
                    <th className="text-center"><span>部門コード</span></th>
                    <th className="text-center"><span>部門名</span></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="tblRight" id="wrapTblHeadRight" onScroll={eventScroll} style={(slitList.items && slitList.items.length > 17) ? { width: 'calc(100% - 667px)' } : { width: 'calc(100% - 650px)' }}>
              <table style={{ textAlign: 'center', width: '100%' }}>
                <thead>
                  <tr>
                    <th className="text-center"><span style={{ textAlign: 'center' }}>数量</span></th>
                    <th className="text-center"><span style={{ textAlign: 'center' }}>金額</span></th>
                    <th className="text-center"><span style={{ textAlign: 'center' }}>数量構成比</span></th>
                    <th className="text-center"><span style={{ textAlign: 'center' }}>累計構成比</span></th>
                    <th className="text-center"><span style={{ textAlign: 'center' }}>合計原価</span></th>
                    <th className="text-center"><span style={{ textAlign: 'center' }}>利益</span></th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <div className="contentTable">
              {
                slitList.items && slitList.items.length > 0
                  ? (
                    <div className="tblLeft">
                      <table>
                        <tbody>
                          {
                            slitList.items.length > 0 ? slitList.items.map((slitData: any, index: any) => (
                              <tr key={index}>
                                <td className="text-center"><span>{slitData.itemRank}</span></td>
                                <td className="text-center"><span>{slitData.itemOrder}</span></td>
                                <td className="text-center"><span>{slitData.smallDivisionCode}</span></td>
                                <td className="text-center"><span>{slitData.smallDivisionName}</span></td>
                              </tr>
                            )) : null
                          }
                        </tbody>
                      </table>
                    </div>
                  )
                  : null
              }
              {
                slitList.items && slitList.items.length > 0
                  ? (
                    <div className="tblRight" id="wrapTblRight" onScroll={eventScroll}>
                      <table id="tblHeadTable" onScroll={eventScroll} style={{ width: '100%' }}>
                        <tbody>
                          {
                            slitList.items.length > 0 ? slitList.items.map((slitData: any, index: any) => (
                              <tr>
                                <td className="text-right"><span>{formatter.format(slitData.totalItem)}</span></td>
                                <td className="text-right"><span>{formatter.format(slitData.totalPrice)}</span></td>
                                <td className="text-right"><span>{formatterPercent.format(slitData.compositionRatio)}</span></td>
                                <td className="text-right"><span>{formatterPercent.format(slitData.cumulativeRatio)}</span></td>
                                <td className="text-right"><span>{formatter.format(slitData.totalCost)}</span></td>
                                <td className="text-right"><span>{formatter.format(slitData.profit)}</span></td>
                              </tr>
                            ))
                              : null
                          }
                        </tbody>
                      </table>
                    </div>
                  ) : null
              }
              {
                (slitList.items === null || slitList.items.length === 0) ? (
                  <div id="scrollTableNotFound" onScroll={eventScroll} style={{ width: `${calcWidth ? calcWidth + 650 : 650}px`, overflow: 'auto' }}>
                    <table className="tblNotFoundABC" onScroll={eventScroll} style={{ width: `${calcWidth ? calcWidth + 650 : 650}px` }}>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center' }}>データはありません。</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null
              }
            </div>
          </div>
          {
            slitList.items && slitList.items.length > 0 ? (
              <div className="scroller">
                <div className="scrollLeft" style={{ width: '325px' }} />
                <div className="scrollRight" id="jsScrollAction" style={{ width: 'calc(100% - 325px)' }} onScroll={eventScroll}>
                  <div className="scrollAction" style={{ width: `${widthScrollbar}px` }} />
                </div>
              </div>
            ) : null
          }
          <div className="contentFooter">
            <div className="tblLeft">
              <table>
                <tfoot>
                  <tr>
                    <th className="text-center"><span>合計</span></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="tblRight" id="wrapFootertblRight" onScroll={eventScroll} style={(slitList.items && slitList.items.length > 17) ? { width: 'calc(100% - 667px)' } : { width: 'calc(100% - 650px)' }}>
              <table style={{ width: '100%' }} id="jsCalcWidthFooter">
                <tfoot>
                  <tr>
                    <th className="text-right"><span>{formatter.format(slitList.slitTotal ? slitList.slitTotal.totalItem : 0)}</span></th>
                    <th className="text-right"><span>{formatter.format(slitList.slitTotal ? slitList.slitTotal.totalPrice : 0)}</span></th>
                    <th className="text-right"><span>-</span></th>
                    <th className="text-right"><span>-</span></th>
                    <th className="text-right"><span>{formatter.format(slitList.slitTotal ? slitList.slitTotal.totalCost : 0)}</span></th>
                    <th className="text-right"><span>{formatter.format(slitList.slitTotal ? slitList.slitTotal.profit : 0)}</span></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ItemCategoryCntOrderTable;
