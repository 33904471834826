
import axios from 'axios';
import ApiClient from './ApiClient';

const domainUrl = process.env.REACT_APP_API_BASE_URL;

// get Org by LocalStorage
export const getInfomationOrgLocalStorage = async (
  companyCode : string,
): Promise<Array<any>> => {
  const params = {
  };
  
  const response = await axios.get(`${domainUrl}/v1/whereami/${companyCode}`, {
    params,
    data: {},
  });
  
  return response.data;
};

// get current time
export const getCurrentTimeSystem = async (
): Promise<Array<any>> => {
  const params = {
  };
  const response = await axios.get(`${domainUrl}/v1/attendStamp/currentTime`, {
    params,
    data: {},
  });
  return response.data;
};

// get history recorder
export const getHistoryRecorder = async (
  companyCode: string,
  selectedOrg: string,
  underFlag: number
): Promise<Array<any>> => {
  const loginStaffCode  = sessionStorage.getItem('loginUser.staffCode') || '';

  const params = {
    orgCode: selectedOrg,
    loginStaffCode,
    underFlag
  };
  const response = await ApiClient.getNoAuth(selectedOrg, `/v2/attendStamp/history/${companyCode}`, params);

  return response.data;
};


// Recorder by Id
// export const handleRecorderById = async (companyCode: string,
//   data: any) => {
//   const params = data;
//   const response = await ApiClient.post(`v1/attendStamp/barcode/${companyCode}`, {}, params);
//   return response.data;
// };


export const handleRecorderById = async (
  shopCode: string,
  companyCode: string,
  data: any,
) : Promise<any> => {
  const response = await ApiClient.postMutipartDataNoAuth(`/v1/attendStamp/barcode/${companyCode}`, {}, data, shopCode);
  return response.data;
};

export const handleConfirmRecorder = async (
  companyCode: string,
  data: any,
) : Promise<any> => {
  const response = await ApiClient.postMutipartData(`/v1/attendStamp/stamp/${companyCode}`, {}, data);
  return response.data;
};

export const handleSubmitFaceImage = async (shopCode: string, companyCode: string, dataPost: any): Promise<any> => {
  // const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.postMutipartDataNoAuth(`/v1/attendStamp/${companyCode}`, {}, dataPost, shopCode);
  return response.data;
};


export default {
  getInfomationOrgLocalStorage,
};
