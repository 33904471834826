/** @jsx jsx */
import React, { useState, useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import productColor, { grayScale, textFontSize } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import SubstituteHolidayStatusDomain from "domain/master/holidayManagement/substituteHolidayStatus";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import moment from "moment";
import IconLabelButton from "components/molecules/IconLabelButton";
import CalendarForm from "components/molecules/CalendarForm";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import {
  useHolidayManagementHolidayDomainForm,
  useDownloadHolidayManagementHoliday,
} from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import Calendar from "components/molecules/CalendarV1";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    marginTop: "0px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  input: css({
    flex: 1,
    width: "100px",
    padding: "7px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: "none",
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px 0 0 4px",
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  selectDay: css({
    minWidth: "100px",
    width: "170px",
  }),
  detail: css({
    backgroundColor: "transparent",
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  inputWrap: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
    border: "1px solid #dcdcdc",
    paddingTop: "15px",
  }),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
      }
    `
  ),
  customStyle: css(
    {
      display: "flex",
      justifyContent:
        (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `
  ),
  note: css({
    paddingLeft: "5px",
    fontSize: "14px",
    color: "#666666",
    position: "relative",
  }),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
}

const HolidayManagementHolidayForm: React.FC<{ orgTreesOptions: any }> = ({
  orgTreesOptions,
}) => {
  const {
    substituteHolidayStatusList,
    orgCode,
    setOrgCode,
    date,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    callGetSubstituteDigestedtHistoryDetail,
    underFlag,
    setUnderFlag,
  } = useHolidayManagementHolidayDomainForm();

  const {
    downloadHolidayManagementHoliday,
    blocking,
  } = useDownloadHolidayManagementHoliday(
    orgCode || "",
    searchDate || date,
    underFlag || 0
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: "",
    staffName: "",
  });
  const contextObject = useContext(HolidayManagementContext);

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubstituteDigestedtHistoryDetail = async (data: any) => {
    setDataDetail(data);
    callGetSubstituteDigestedtHistoryDetail(data.staffCode);
    setModalOpen(true);
  };

  //
  const columns: Array<Column<SubstituteHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SubstituteHolidayStatusDomain };
          data: Array<SubstituteHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SubstituteHolidayStatusDomain };
          data: Array<SubstituteHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SubstituteHolidayStatusDomain };
          data: Array<SubstituteHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />

            <IconLabelButton
              onClick={() =>
                handleSubstituteDigestedtHistoryDetail(cell.row.original)
              }
              text="編集"
              isIcon={false}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SubstituteHolidayStatusDomain };
          data: Array<SubstituteHolidayStatusDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "付与日数",
        accessor: "grantDaysNumStr",
      },
      {
        Header: "消化日数",
        accessor: "digestedDaysNumStr",
      },
      {
        Header: "消滅日数",
        accessor: "extinctionDaysNumStr",
      },
    ],
    [handleSubstituteDigestedtHistoryDetail]
  );

  return (
    <BlockUI blocking={blocking}>
      <div css={css(styles.form)}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="100%">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                <div css={styles.selectDay}>
                  {/* <CalendarForm required={true} label="" date={new Date(searchDate)} dateFormat='YYYY年MM月DD日' changeDate={(date: Date) => setSearchDate(date)} /> */}
                  <Calendar
                    date={new Date(searchDate || date)}
                    setDate={(date) => {
                      if (date) {
                        setSearchDate(moment(date).format("YYYY/MM/DD"));
                      }
                    }}
                  />
                </div>
                <div css={styles.note}>から過去1年間</div>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode003"
                value={String(orgCode)}
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag8"}
                name={"underFlag8"}
                label={"配下指定"}
                value={`underFlag8`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 200px;
              }
              table th:nth-of-type(2) {
                width: 200px;
              }
              table th:nth-of-type(3) {
                width: 200px;
              }
              table th:nth-of-type(4) {
                width: 200px;
              }
              table th:nth-of-type(5) {
                width: 100px;
              }
              table th:nth-of-type(6) {
                width: 100px;
              }
              table th:nth-of-type(7) {
                width: 100px;
              }
              table tr th,
              table tr td {
                text-align: center;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementHoliday()}
                  iconType="download"
                  text="ダウンロード"
                />
                {/* <IconLabelButton onClick={() => downloadHolidayManagementHoliday(true)} iconType="download" text="全組織ダウンロード" /> */}
              </FormSubmitArea>
            ) : null}

            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={substituteHolidayStatusList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={7}
              />
            </div>
          </div>

          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="取得状況詳細"
            submitText=""
            onSubmit={closeModal}
            closeText={"閉じる"}
          >
            <div
              style={{
                width: "350px",
                fontSize: "14px",
                color: "#666666",
                margin: "0 auto",
              }}
              css={styles.table}
            >
              <VerticalLabelTextLayout
                label={`スタッフコード`}
                variable={dataDetail.staffCode}
              />
              <VerticalLabelTextLayout
                label={`スタッフ名`}
                variable={dataDetail.staffName}
              />
              <VerticalLabelTextLayout
                label={`期間`}
                variable={`${moment(detailItems.fromDate).format(
                  "YYYY年MM月DD日"
                )}～${moment(detailItems.toDate).format("YYYY年MM月DD日")}`}
              />
              {detailItems.effectiveStartDate && (
                <VerticalLabelTextLayout
                  label="有給付与日"
                  variable={moment(detailItems.effectiveStartDate).format(
                    "YYYY年MM月DD日"
                  )}
                />
              )}
              <table
                className="table-bordered"
                style={{ width: "100%", fontSize: "14px" }}
              >
                <thead>
                  <tr>
                    <th>日付</th>
                    <th>種別</th>
                    <th>日数</th>
                  </tr>
                </thead>
                <tbody>
                  {detailItems.substituteDigestedtHistoryList &&
                    detailItems.substituteDigestedtHistoryList.map(
                      (item: any) => (
                        <tr>
                          <td>{moment(item.days).format("YYYY年MM月DD日")}</td>
                          <td>{item.type}</td>
                          <td>{item.daysNum}日</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </div>
    </BlockUI>
  );
};
export default HolidayManagementHolidayForm;
