/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { fontFamily, grayScale, utilityColor } from "components/styles";

export interface OptionType {
  value: string;
  label: string;
}

const styles = {
  selectBox: (disabled: boolean) =>
    css({
      width: "100%",
      textAlignLast: "center",
      height: "42px",
      border: `1px solid ${grayScale.gray03}`,
      borderRadius: "2px",
      boxShadow: "none",
      backgroundColor: disabled ? grayScale.gray20 : utilityColor.white,
      color: "#333333",
      fontFamily: fontFamily,
      fontSize: "14px",
    }),
};

const MobileSelect: React.FC<{
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<OptionType>;
  disabled?: boolean;
}> = ({ id, value, onChange, options, disabled = false }) => {
  return (
    <select css={styles.selectBox(disabled)} id={id} disabled={disabled} onChange={onChange}>
      {options.map((item) => (
        <option selected={item.value === value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

export default MobileSelect;
