/**
 * API 祝日マスタ
 */

export interface ShiftPattern {
  companyCode: string;
  orgCode: string;
  shiftPatternId:string;
  patternIdStr:string;
  shiftPatternCode:string;
  shiftPatternName:string;
  shiftPatternNameDisp: string;
  startTime:string;
  endTime:string;
  attendShiftPatternDetails:Array<AttendShiftPatternDetails>,
  businessId: string,
  getshiftBreakList:any
  shortName:string;
  updateUser: string;
  createUser: string;
}
export type AttendShiftPatternDetails = {
  createUser: string,
  updateUser: string,
  shiftPatternId: string,
  shiftPatternDetailId: string,
  startTime: string,
  endTime: string,
  isStartTimeNextDay: boolean,
  isEndTimeNextDay: boolean,
  businessId: string,
  isRemote: boolean
}

export default class ShiftPatternDomain {
  constructor(private rawData: ShiftPattern) {
    // do nothing
  }

  static generateInitial(): ShiftPatternDomain {
    return new ShiftPatternDomain({
      companyCode: '',
      orgCode: '',
      shiftPatternId: '',
      patternIdStr: '',
      shiftPatternCode: '',
      shiftPatternName: '',
      shiftPatternNameDisp: '',
      startTime: '',
      endTime: '',
      attendShiftPatternDetails: [],
      businessId: '',
      getshiftBreakList: [],
      shortName: '',
      updateUser: '',
      createUser: '',
    });
  }

  getRawData(): ShiftPattern {
    return this.rawData;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get shiftPatternId(): string {
    return this.rawData.shiftPatternId;
  }

  set shiftPatternId(shiftPatternId: string) {
    this.rawData.shiftPatternId = shiftPatternId;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get shiftPatternCode(): string {
    return this.rawData.shiftPatternCode;
  }

  set shiftPatternCode(shiftPatternCode: string) {
    this.rawData.shiftPatternCode = shiftPatternCode;
  }

  get shiftPatternName(): string {
    return this.rawData.shiftPatternName;
  }

  set shiftPatternName(shiftPatternName: string) {
    this.rawData.shiftPatternName = shiftPatternName;
  }

  get shiftPatternNameDisp(): string {
    return this.rawData.shiftPatternNameDisp;
  }

  set shiftPatternNameDisp(shiftPatternNameDisp: string) {
    this.rawData.shiftPatternNameDisp = shiftPatternNameDisp;
  }

  get shortName(): string {
    return this.rawData.shortName;
  }

  set shortName(shortName: string) {
    this.rawData.shortName = shortName;
  }

  get startTime(): string {
    return this.rawData.startTime;
  }

  set startTime(startTime: string) {
    this.rawData.startTime = startTime;
  }

  get endTime(): string {
    return this.rawData.endTime;
  }

  set endTime(endTime: string) {
    this.rawData.endTime = endTime;
  }

  get attendShiftPatternDetails(): Array<AttendShiftPatternDetails> {
    return this.rawData.attendShiftPatternDetails;
  }

  set attendShiftPatternDetails(attendShiftPatternDetails: Array<AttendShiftPatternDetails>) {
    this.rawData.attendShiftPatternDetails = attendShiftPatternDetails;
  }

  get businessId(): string {
    return this.rawData.businessId;
  }

  set businessId(businessId: string) {
    this.rawData.businessId = businessId;
  }

  get getshiftBreakList(): string {
    return this.rawData.getshiftBreakList;
  }

  set getshiftBreakList(getshiftBreakList: string) {
    this.rawData.getshiftBreakList = getshiftBreakList;
  }
}
