import React from "react";
import { css } from "@emotion/core";
import Modal from "components/molecules/Modal";
import Table from "components/molecules/Table";
import moment from "moment";
import LableTitle from "components/atoms/LableTitle";
import { useShiftDetail } from "./hooks";

const ShiftDetailModal: React.FC<{
  time: String;
  targetDate: string;
  open: boolean;
  openHandler: (arg: boolean) => void;
}> = ({ time, targetDate, open, openHandler }) => {
  const { storeList } = useShiftDetail(targetDate);

  return (
    <Modal
      open={open}
      closeHandler={() => openHandler(false)}
      title={`${moment(targetDate).format("MM月DD日")} シフト詳細`}
      submitText=""
      onSubmit={() => {}}
    >
      <LableTitle title={`希望シフト： ${time}`} />

      <Table>
        <thead>
          <tr>
            <Table.HeaderCell customStyle={css({ width: "40px" })} />
            <Table.HeaderCell>出勤組織</Table.HeaderCell>
            <Table.HeaderCell>開始時間</Table.HeaderCell>
            <Table.HeaderCell>終了時間</Table.HeaderCell>
            <Table.HeaderCell>休憩</Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {storeList &&
            storeList.map((store, index) => (
              <tr key={index.toString()}>
                <Table.Cell customStyle={css({ width: "8px" })}>{index + 1}</Table.Cell>
                <Table.Cell>{store.orgName}</Table.Cell>
                <Table.Cell>{moment(store.startTime).format("HH:mm")}</Table.Cell>
                <Table.Cell>{moment(store.endTime).format("HH:mm")}</Table.Cell>
                <Table.Cell>{store.businessName}</Table.Cell>
              </tr>
            ))}
        </tbody>
      </Table>
    </Modal>
  );
};

export default ShiftDetailModal;
