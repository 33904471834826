/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';

const useMonthlyReport = (orgCode: string, monthlyStartDate: string,
  monthlyEndDate: string, monthlyComparePrevYear:number) => {
  const [monthlyReport, setmonthlyReport] = useState();
  const [getMediaMst, setMediaMst] = useState();

  const [getBudgetPerfomanceManagement, setBudgetPerfomanceManagement] = useState([]);
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const path = 'daySeparateSalesManagement';
    setIsLoading(true);
    api.getmonthlyReport(companyCode, orgCode, monthlyStartDate, monthlyEndDate, path, monthlyComparePrevYear).then((response: any) => {
      setmonthlyReport(response);
      setIsLoading(false);
    });

    api.getMediaMst(companyCode, orgCode, path).then((response: any) => {
      setMediaMst(response);
      setIsLoading(false);
    });

    const pathBudget = 'daySeparateBudgetPerformanceManagement';
    api.getBudgetPerfomanceManagement(companyCode, orgCode, monthlyStartDate, monthlyEndDate, pathBudget, monthlyComparePrevYear).then((response: any) => {
      setBudgetPerfomanceManagement(response);
      setIsLoading(false);
    });
  }, [monthlyComparePrevYear, monthlyEndDate, monthlyStartDate, orgCode]);
  return {
    getMediaMst, monthlyReport, setmonthlyReport, getBudgetPerfomanceManagement, getIsLoading,
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useGetListMonthlyReport = () => {
  const onSubmit = () => {

  };

  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [action, setAction] = useState<ActionType>(ActionType.getFetchOption);
  if (action === ActionType.getFetchOption) { }

  if (action === ActionType.getFetchOptionforeCast) {}
  return {
    action,
    setAction,
    ActionType,
    formik,
  };
};

export const useDownload = () => {
  const downloadMonthlyReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '月次日別売上管理表.csv');
  };

  const downloadBudgetPerfomanceManagementCSV = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '月次日別予実管理表.csv');
  };

  const downloadMonthlyReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '月次日別売上管理表.xlsx');
  };

  const downloadBudgetPerfomanceManagementExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '月次日別予実管理表.xlsx');
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadBudgetPerfomanceManagementCSV,
    downloadBudgetPerfomanceManagementExcel,
  };
};

export default useMonthlyReport;
