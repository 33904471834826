import {
  useState, useCallback, useEffect, useContext
} from 'react';

import {
  getGrantItemsForSpecialHolidayGrantHistory,
  downloadSpecialHolidayGrantHistoryCsv,
} from 'api/holidayManagement';
import SpecialHolidayGrantHistoryDomain from 'domain/master/holidayManagement/specialHolidayGrantHistory';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import useSaveStateStorage from 'hooks/useSaveFilterStorage';
import moment from 'moment';

export const useSpecialHolidayGrantHistoryDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [paidHolidayGrantHistoryList, setPaidHolidayGrantHistoryList] = useState<
  Array<SpecialHolidayGrantHistoryDomain>
  >([]);
  const [noManager, setNoManager] = useState(false);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorageOrgCode, "holidayManagementSpecialHoliday.grantHistory.orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const startYear = moment(new Date).startOf('year').format("YYYY/MM/DD");
  const endYear = moment(new Date).endOf('year').format("YYYY/MM/DD");
  const [targetDateFrom, setTargetDateFrom] = useSaveStateStorage(startYear, "holidayManagementSpecialHoliday.grantHistory.targetDateFrom") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [targetDateTo, setTargetDateTo] = useSaveStateStorage(endYear, "holidayManagementSpecialHoliday.grantHistory.targetDateTo") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [modalOpen, setModalOpen] = useState(false);

  const contextObject = useContext(HolidayManagementContext);
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "holidayManagementSpecialHoliday.grantHistory.underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];;

  // データの取得
  const fetchData = useCallback(async () => {
    if(orgCode !== null){
      contextObject.setIsLoading(true);
      const response = await getGrantItemsForSpecialHolidayGrantHistory(
        (orgCode !== 'all' && orgCode !== null) ? orgCode : '',
        targetDateFrom || startYear, 
        targetDateTo || endYear,
        underFlag || 0
      );
      setPaidHolidayGrantHistoryList(
        response.map((result) => new SpecialHolidayGrantHistoryDomain(result)),
      );
      contextObject.setIsLoading(false);
    }
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, noManager, orgCode, targetDateFrom, targetDateTo, underFlag]);

  const toggleNoManager = useCallback(() => {
    setNoManager(!noManager);
  }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    paidHolidayGrantHistoryList,
    toggleNoManager,
    orgCode,
    setOrgCode,
    startYear,
    endYear,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    modalOpen,
    setModalOpen,
    closeModal,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagementSpecialHolidayGrantHistory = (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  underFlag: number
) => {
  const [blocking , setBlocking] = useState(false);
  const downloadHolidayManagementSpecialHolidayGrantHistory = useCallback((
    isDownloadAll?: boolean,
  ) => {
    setBlocking(true);
    downloadSpecialHolidayGrantHistoryCsv(
      isDownloadAll ? '' : orgCode,
      targetDateFrom,
      targetDateTo,
      underFlag
    ).then(() => {
      setBlocking(false);
    });
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  return {
    blocking,
    downloadHolidayManagementSpecialHolidayGrantHistory
  };
};

export default {
  useSpecialHolidayGrantHistoryDomainForm,
};
