import React, { SVGProps } from 'react';
 
const FalsePerson: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{mixBlendMode: 'soft-light'}}>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.1275 40.6954C40.2567 41.5338 38.1828 42 36 42C33.8216 42 31.7517 41.5356 29.8838 40.7005L17.6379 46.2668C16.0314 46.997 15 48.5988 15 50.3635V52.5C15 54.9853 17.0147 57 19.5 57H34.5C35.3284 57 36 57.6716 36 58.5C36 59.3284 35.3284 60 34.5 60H19.5C15.3579 60 12 56.6421 12 52.5V50.3635C12 47.4224 13.719 44.7528 16.3965 43.5357L26.7665 38.8221C23.2563 36.0766 21 31.8018 21 27C21 18.7157 27.7157 12 36 12C44.2843 12 51 18.7157 51 27C51 31.7788 48.7653 36.0356 45.2839 38.7825L53.0898 42.1208C53.8515 42.4466 54.2049 43.3281 53.8792 44.0898C53.5534 44.8515 52.6719 45.2049 51.9102 44.8792L42.1275 40.6954ZM50.3787 58.5L42.4393 50.5607C41.8536 49.9749 41.8536 49.0251 42.4393 48.4393C43.0251 47.8536 43.9749 47.8536 44.5607 48.4393L52.5 56.3787L60.4393 48.4393C61.0251 47.8536 61.9749 47.8536 62.5607 48.4393C63.1464 49.0251 63.1464 49.9749 62.5607 50.5607L54.6213 58.5L62.5607 66.4393C63.1464 67.0251 63.1464 67.9749 62.5607 68.5607C61.9749 69.1464 61.0251 69.1464 60.4393 68.5607L52.5 60.6213L44.5607 68.5607C43.9749 69.1464 43.0251 69.1464 42.4393 68.5607C41.8536 67.9749 41.8536 67.0251 42.4393 66.4393L50.3787 58.5ZM48 27C48 33.6274 42.6274 39 36 39C29.3726 39 24 33.6274 24 27C24 20.3726 29.3726 15 36 15C42.6274 15 48 20.3726 48 27Z" fill="white"/>
    </g>
  </svg>

);

export default FalsePerson;