import React, { SVGProps } from 'react';

const SelfReport: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.16 17.88L29.07 21.02C30.85 21.83 32 23.61 32 25.57V27C32 29.76 29.76 32 27 32H5C2.24 32 0 29.76 0 27V25.58C0 23.62 1.15 21.84 2.93 21.03L9.84 17.89C7.5 16.05 6 13.2 6 10C6 4.48 10.48 0 16 0C21.52 0 26 4.48 26 10C26 13.2 24.5 16.05 22.16 17.88ZM11.92 19.13L3.76 22.84C2.69 23.33 2 24.4 2 25.58V27C2 28.66 3.34 30 5 30H27C28.66 30 30 28.66 30 27V25.58C30 24.4 29.31 23.34 28.24 22.85L20.08 19.14C18.83 19.69 17.45 20 16 20C14.55 20 13.17 19.69 11.92 19.13ZM24 10C24 14.42 20.42 18 16 18C11.58 18 8 14.42 8 10C8 5.58 11.58 2 16 2C20.42 2 24 5.58 24 10Z" fill="#007BC3"/>
    <path d="M30.2895 5.70048C29.8995 5.31048 29.9095 4.67048 30.2995 4.29048C30.6895 3.90048 31.3295 3.91048 31.7095 4.30048C34.8095 7.47048 34.8095 12.5305 31.7095 15.7005C31.3195 16.0905 30.6895 16.1005 30.2995 15.7105C29.9095 15.3205 29.8995 14.6905 30.2895 14.3005C32.6295 11.9105 32.6295 8.09048 30.2895 5.70048Z" fill="#003993"/>
    <path d="M34.3307 1.73968C33.9207 1.36968 33.8907 0.739678 34.2607 0.329678C34.6307 -0.0803221 35.2607 -0.110322 35.6707 0.259678C41.0507 5.10968 41.4807 13.3997 36.6307 18.7797C36.3307 19.1197 36.0107 19.4397 35.6707 19.7397C35.2607 20.1097 34.6307 20.0797 34.2607 19.6697C33.8907 19.2597 33.9207 18.6297 34.3307 18.2597C34.6207 17.9997 34.8907 17.7297 35.1407 17.4497C39.2607 12.8797 38.8907 5.84968 34.3307 1.73968Z" fill="#003993"/>
  </svg>
  
);

export default SelfReport;
