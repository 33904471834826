/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { grayScale } from 'components/styles';
import FullScreenOverlay from 'components/atoms/FullScreenOverlay';
import Panel from 'components/atoms/Panel';
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';

const styles = {
  wrapper: css({
    cursor: 'pointer',
  }),
  closeButton: css({
    marginLeft: 'auto',
    color: '#fff',
    fontWeight: 'bold',
    width: '30px',
    height: '30px',
    opacity: 0.7,
    outline: 0,
    transition: 'opacity .2s',
    marginBottom: '-30px'
  }),
  text: css({
    color: '#fff',
    fontsize: '13px',
    fontWeight: 700,
    textAlign: 'left'
  }),
  descript: css({
    display: 'flex',
    backgroundColor: 'red'
  }),
  image: css({
    width: '640px',
    height: '480',
    borderRight: '5px solid #fff',
    borderLeft: '5px solid #fff',
    borderTop: '5px solid #fff',
    borderBottom: '5px solid #fff',
    borderRadius: '4px',
  }),
  IconButton: css({
    padding: '0',
  }),
  stylePanel: css({
    background: 'none'
  })
};

/**
 * Image
 */
const ImageModel: React.FC<{
  open: boolean
  closeHandler: () => void
  imageURL: string
  descript: string
}> = ({
  open, closeHandler, imageURL = '', descript = '',
}) => (

  <FullScreenOverlay open={open}>
    <Panel customStyle={styles.stylePanel}>
      <div>
        <img css={styles.image} src={imageURL} alt={imageURL} width={650} height={490} />
        <div>     
          <div css={styles.closeButton}>     
            <ClickableIconButton onClick={closeHandler} additionalCss={styles.IconButton}>
              <Icon type="close" color={grayScale.gray05} size="50px" />
            </ClickableIconButton>
          </div>
          <div css={styles.text}>{descript}</div>
        </div>

      </div>
    </Panel> 
  </FullScreenOverlay>
);

export default ImageModel;
