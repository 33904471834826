import { useState, useCallback, useEffect, useContext } from "react";
import { useFormik } from "formik";
import { UpdateTimeSetting, getTimeSetting } from "api/timeSetting";
import TimeSettingDomain from "domain/master/labor/timeSetting";
import useToastNotification from "hooks/useToastNotification";
import { Context } from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/TimeSettingsPage/TimeSettingPage';

export const useSettingUpdateForm = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const { successNotification, errorNotification } = useToastNotification();
  // const [isLoading, setIsLoading] = useState(false);
  const {
    isLoading,
    setIsLoading,
    detailRole,
  } = useContext(Context);

  const onSubmit = async (val: any) => {
    const submitTimeSetting = val.getRawDataWithoutNullData();
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    setIsLoading(true);
    UpdateTimeSetting(companyCode, submitTimeSetting)
      .then((response: any) => {
        setConfirmModalOpen(false);
        successNotification("登録しました。");
        setIsLoading(false);
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        setIsLoading(false);
        if (
          error.response.data.errors != null &&
          error.response.data.errors.length !== 0
        ) {
          let msgError = "";
          error.response.data.errors.map(
            (iteam: { defaultMessage: string }) => {
              msgError += `${iteam.defaultMessage} \n`;
              return msgError;
            }
          );
          errorNotification(msgError);
        } else {
          errorNotification("Something wrong. please try again");
        }
      });
  };

  const formik = useFormik({
    initialValues: TimeSettingDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    setIsLoading(true);
    getTimeSetting(companyCode).then((response: any) => {
      setIsLoading(false);
      formik.setValues(new TimeSettingDomain(response));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    isLoading,
    detailRole,
  };
};

export default useSettingUpdateForm;
