import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendanceAddForm from 'components/organismos/master/attend/AttendanceForm/AttendanceAddForm';

import { useParams } from 'react-router-dom';

const AttendanceAddPage: React.FC = () => {
  const { stampIpId } = useParams();
  return (
    <SidebarTemplate>
      <AttendanceAddForm />
    </SidebarTemplate>
  );
};

export default AttendanceAddPage;
