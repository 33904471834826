import React, { useRef, useState, useEffect } from "react";
import { gridCol } from "components/styles";
import TextTooltip from "components/molecules/TextTooltip";

const DayTable: React.FC<{
  eventScroll: any;
  holidaySubtotalItem: Array<any>;
  subTotalDayList: Array<any>;
  useAllowance: number;
}> = ({ eventScroll, holidaySubtotalItem, subTotalDayList, useAllowance }) => {
  const refTableContent = useRef(Object());
  const refTable1 = useRef(Object());
  const refTable2 = useRef(Object());
  const [offsetWidthTable, setOffsetWidthTable] = useState(0);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  useEffect(() => {
    const width = refTable2.current ? refTable2.current.offsetWidth : 0;
    setOffsetWidthTable(width);
  }, [refTable2.current.offsetWidth]);

  useEffect(() => {
    if (refTableContent.current) {
      const width = refTableContent.current.offsetWidth - refTableContent.current.clientWidth;
      setScrollBarWidth(width);
    }
  }, [subTotalDayList]);

  useEffect(() => {
    const rowsTable1 = refTable1.current.querySelectorAll("tr");
    const rowsTable2 = refTable2.current.querySelectorAll("tr");

    rowsTable2.forEach((row: any, index: number) => {
      if (rowsTable1[index]) {
        const height = row.offsetHeight;
        rowsTable1[index].style.height = `${height}px`;
      }
    });
  }, [subTotalDayList]);

  return (
    <div className="wrap-attend-monthly-list">
      <div style={{ display: "flex" }}>
        <div className="top-left-area-time">
          <table
            className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
            style={{
              borderRight: "1px #d3d3d3 solid",
              borderTop: "1px #d3d3d3 solid",
            }}
          >
            <thead>
              <tr>
                <th className="headline-cell-width-120 head-line3-height text-center">
                  スタッフ
                  <br />
                  コード
                </th>
                <th className="headline-cell-width-120 head-line3-height text-center">スタッフ名</th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="topRightAreaTime" onScroll={eventScroll} style={{ overflow: "auto" }}>
          <div style={{ width: `${offsetWidthTable + scrollBarWidth}px` }}>
            <table
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
              id="top-right-time"
              style={{
                borderRight: "1px #d3d3d3 solid",
                borderTop: "1px #d3d3d3 solid",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={3}
                    className="active headline-cell-width-120 head-line1-height text-center border-left-none"
                  >{`出勤日数　(オフィス/${companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"})`}</th>
                  <th
                    colSpan={holidaySubtotalItem && holidaySubtotalItem.length}
                    className="active headline-cell-width-120 head-line1-height text-center"
                  >
                    休暇日数
                  </th>

                  <th rowSpan={2} className="active headline-cell-width-60 head-line1-height text-center">
                    遅刻
                  </th>
                  <th rowSpan={2} className="active headline-cell-width-60 head-line1-height text-center">
                    早退
                  </th>

                  {useAllowance === 1 && (
                    <th
                      rowSpan={2}
                      style={{ maxWidth: 160, minWidth: 160 }}
                      className="active headline-cell-width-120 head-line1-height text-center"
                    >
                      手当申請
                    </th>
                  )}
                </tr>

                <tr>
                  <th className="active headline-cell-width-80 head-line2-height text-center border-left-none">
                    平日
                  </th>
                  <th className="active headline-cell-width-80 head-line2-height text-center">
                    法定
                    <br />
                    休日
                  </th>
                  <th className="active headline-cell-width-80 head-line2-height text-center">
                    所定
                    <br />
                    休日
                  </th>
                  {holidaySubtotalItem &&
                    holidaySubtotalItem.map((item: any, index) => (
                      <th
                        key={String(index)}
                        className="active headline-cell-width-150 head-line2-height text-center"
                        style={{ wordBreak: "break-all" }}
                      >
                        {item.holidayName}
                        {item.holidayType === 0 && item.isLegal ? "（法定）" : ""}
                        {item.holidayType === 0 && !item.isLegal ? "（所定）" : ""}
                      </th>
                    ))}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div ref={refTableContent} className="table-content">
        <div style={{ display: "inline-flex", width: gridCol.grid12 }}>
          <div className="bottomLeftAreaTime">
            <table
              ref={refTable1}
              style={{
                borderRight: "1px #d3d3d3 solid",
              }}
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
            >
              {subTotalDayList.map((subTotalDay: any) => (
                <tbody key={subTotalDay.staffCode}>
                  <tr>
                    <td className="headline-cell-width-120 head-line1-height text-center">
                      <TextTooltip
                        text={subTotalDay.staffCode}
                        textDis={<div className="ellipsis headline-cell-width-120">{subTotalDay.staffCode}</div>}
                      />
                    </td>
                    <td className="headline-cell-width-120 head-line1-height text-center">
                      <TextTooltip
                        textDis={
                          <span
                            style={{
                              maxWidth: "110px",
                              overflow: "hidden",
                              display: "block",
                              margin: "0px auto",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {subTotalDay.staffName}
                          </span>
                        }
                        text={`${subTotalDay.employmentName ? `${subTotalDay.employmentName}<br>` : ""} ${
                          subTotalDay.staffName
                        }<br> ${subTotalDay.staffOrgName ? subTotalDay.staffOrgName : ""}`}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <div
            id="bottomRightAreaTime"
            style={{ width: "calc(100% - 241px)", overflow: "auto" }}
            onScroll={eventScroll}
          >
            <div style={{ width: `${offsetWidthTable}px` }}>
              <table
                ref={refTable2}
                style={{
                  borderRight: "1px #d3d3d3 solid",
                }}
                className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
                id="bottom-right-time"
              >
                {subTotalDayList.map((subTotalDay: any, index) => (
                  <tbody key={String(index)}>
                    <tr>
                      <td className="headline-cell-width-80 head-line1-height text-center border-left-none">
                        {subTotalDay.normalWork}
                        {parseInt(subTotalDay.normalWork) > 0
                          ? "(" + parseInt(subTotalDay.officeWork) + "/" + parseInt(subTotalDay.remoteWork) + ")"
                          : ""}
                      </td>
                      <td className="headline-cell-width-80 head-line1-height text-center">
                        {subTotalDay.legalHolidayWork}
                        {parseInt(subTotalDay.legalHolidayWork) > 0
                          ? "(" +
                            parseInt(subTotalDay.legalOfficeHolidayWork) +
                            "/" +
                            parseInt(subTotalDay.legalRemoteHolidayWork) +
                            ")"
                          : ""}
                      </td>
                      <td className="headline-cell-width-80 head-line1-height text-center">
                        {subTotalDay.nonLegalHolidayWork}
                        {parseInt(subTotalDay.nonLegalHolidayWork) > 0
                          ? "(" +
                            parseInt(subTotalDay.nonLegalOfficeHolidayWork) +
                            "/" +
                            parseInt(subTotalDay.nonLegalRemoteHolidayWork) +
                            ")"
                          : ""}
                      </td>
                      {subTotalDay.attendHolidaySubtotals.map((attendHolidaySubtotal: any, index2: any) => (
                        <td key={String(index2)} className="headline-cell-width-150 head-line1-height text-center">
                          {attendHolidaySubtotal.resultHoliday}
                        </td>
                      ))}
                      <td className="headline-cell-width-60 head-line1-height text-center">{subTotalDay.late}</td>
                      <td className="headline-cell-width-60 head-line1-height text-center">{subTotalDay.earlier}</td>
                      {useAllowance === 1 && (
                        <td
                          style={{ maxWidth: 160, minWidth: 160 }}
                          className="headline-cell-width-120 head-line1-height text-center"
                        >
                          {subTotalDay.allowanceDataList?.map((item: any) => (
                            <div
                              key={item.id}
                            >{`${item.allowanceName}： ${item.allowanceValue}${item.dispUnit} `}</div>
                          ))}
                        </td>
                      )}
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="content-scroll-bottom" onScroll={eventScroll}>
        <div
          id="scrollBottom"
          style={{
            width: "calc(100% - 248px)",
            float: "right",
            overflow: "auto",
          }}
        >
          {holidaySubtotalItem && (
            <div className="scroll" style={{ width: `${offsetWidthTable + scrollBarWidth}px` }} />
          )}
        </div>
      </div>
    </div>
  );
};
export default DayTable;
