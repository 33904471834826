import moment from 'moment';
import { IStamp } from '../interface';

/**
 * API getApprovedStampList
 */
export default class ProcessingStampDomain {
  constructor(private rawData: IStamp) {
    // do nothing
  }

  getRawData(): IStamp {
    return this.rawData;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get achievementId(): string {
    return this.rawData.achievementId;
  }

  set achievementId(achievementId: string) {
    this.rawData.achievementId = achievementId;
  }

  get applicationDate(): string {
    return moment(this.rawData.applicationDate).format('YYYY/MM/DD HH:mm');
  }

  set applicationDate(applicationDate: string) {
    this.rawData.applicationDate = moment(applicationDate).format('YYYY/MM/DD HH:mm');
  }

  get applicationStaffCode(): string {
    return this.rawData.applicationStaffCode;
  }

  set applicationStaffCode(applicationStaffCode: string) {
    this.rawData.applicationStaffCode = applicationStaffCode;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get applicationType(): number {
    return this.rawData.applicationType;
  }

  set applicationType(applicationType: number) {
    this.rawData.applicationType = applicationType;
  }

  get applicationStatus(): number {
    return this.rawData.applicationStatus;
  }

  set applicationStatus(applicationStatus: number) {
    this.rawData.applicationStatus = applicationStatus;
  }

  get modifiyStatus(): number {
    return this.rawData.modifiyStatus;
  }

  set modifiyStatus(modifiyStatus: number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get modifiyStatusName(): string {
    return this.rawData.modifiyStatusName;
  }

  set modifiyStatusName(modifiyStatusName: string) {
    this.rawData.modifiyStatusName = modifiyStatusName;
  }

  get applicationReason(): string {
    return this.rawData.applicationReason;
  }

  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }

  get rejectReason(): string | '' {
    if (this.rawData.rejectReason) {
      return this.rawData.rejectReason;
    }

    return '';
  }

  set rejectReason(rejectReason: string | '') {
    this.rawData.rejectReason = rejectReason;
  }

  get version(): number {
    return this.rawData.version;
  }

  set version(version: number) {
    this.rawData.version = version;
  }

  get approvalDate(): string {
    return moment(this.rawData.approvalDate).format('YYYY/MM/DD HH:mm');
  }

  set approvalDate(approvalDate: string) {
    this.rawData.approvalDate = moment(approvalDate).format('YYYY/MM/DD HH:mm');
  }

  get approvalStaffCode(): string {
    return this.rawData.approvalStaffCode;
  }

  set approvalStaffCode(approvalStaffCode: string) {
    this.rawData.approvalStaffCode = approvalStaffCode;
  }

  get targetDate(): string {
    return moment(this.rawData.targetDate).format('YYYY/MM/DD');
  }

  set targetDate(targetDate: string) {
    this.rawData.targetDate = moment(targetDate).format('YYYY/MM/DD');
  }

  get stampOrgCode(): string {
    return this.rawData.stampOrgCode;
  }

  set stampOrgCode(stampOrgCode: string) {
    this.rawData.stampOrgCode = stampOrgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get stampOrgName(): string {
    return this.rawData.stampOrgName;
  }

  set stampOrgName(stampOrgName: string) {
    this.rawData.stampOrgName = stampOrgName;
  }

  get staffName(): string {
    return this.rawData.staffName;
  }

  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }

  get applicationStaffName(): string {
    return this.rawData.applicationStaffName;
  }

  set applicationStaffName(applicationStaffName: string) {
    this.rawData.applicationStaffName = applicationStaffName;
  }

  get approvalStaffName(): string {
    return this.rawData.approvalStaffName;
  }

  set approvalStaffName(approvalStaffName: string) {
    this.rawData.approvalStaffName = approvalStaffName;
  }

  get authorizer(): string {
    return `${this.rawData.approvalStaffCode} ${this.rawData.approvalStaffName}`;
  }

  get applicant(): string {
    return `${this.rawData.applicationStaffCode} ${this.rawData.applicationStaffName}`;
  }

  get targetPerson(): string {
    return `${this.rawData.staffCode} ${this.rawData.staffName}`;
  }

  get stampListStartTime(): string {
    // const stampListTotal = this.rawData.stampList.length;
    // const startTime = this.rawData.stampList[stampListTotal - 1]?.startTime || '';

    // return moment(startTime).format('YYYY/MM/DD HH:mm');
    const mapStartTime = this.rawData.stampList.map((stamp) => moment(stamp.startTime).format('YYYY/MM/DD HH:mm'));
    return mapStartTime.join('\n');
  }

  get stampListEndTime(): string {
    // const stampListTotal = this.rawData.stampList.length;
    // const endTime = this.rawData.stampList[stampListTotal - 1]?.endTime || '';

    // return moment(endTime).format('YYYY/MM/DD HH:mm');
    const mapEndTime = this.rawData.stampList.map((stamp) => (stamp.endTime ? moment(stamp.endTime).format('YYYY/MM/DD HH:mm') : ''));
    return mapEndTime.join('\n');
  }

  get stampListBusinessName(): string {
    // const stampListTotal = this.rawData.stampList.length;
    // return this.rawData.stampList[stampListTotal - 1]?.businessName || '';
    const mapBusinessName = this.rawData.stampList.map((stamp) => (stamp.businessName ? stamp.businessName : ' '));
    return mapBusinessName.join('\n');
  }
}
