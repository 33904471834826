/**
 * APIのデータ型
 */

import { OrganizationSalaryBasic, Manager } from "api/organization";
import moment from "moment";

export type SpecialSalaryItem = {
  id: {
    companyCode: string;
    orgCode: string;
    applyStartMonth: Date;
    specialSalaryPatternId: string;
  };
  addSalary: number;
  applyEndMonth: Date | null;
  orgName: string;
  specialSalaryPatternCode: string;
  specialSalaryPatternName: string;
  patOrgCodes: string[] | null;
  applyStartMonthStr: string;
};

export interface OrganizationSpecialSalary {
  id: {
    companyCode: string;
    orgCode: string;
    applyStartDate: Date;
  };
  orgName: string;
  orgNameKana: string;
  applyStartMonth: Date | null;
  applyEndMonth: Date | null;
  applyEndDate: Date;
  closeFlag: boolean;
  posCode: string | null;
  specifyCode: string;
  prefectureCode: string;
  dispStartHour: number | null;
  dispHour: number | null;
  employmentId: string;
  areaId: string | null;
  businessCategoryId: string | null;
  numberOfSeat: number | null;
  floorSpace: number | null;
  category: string;
  createUser: string;
  createDate: Date;
  updateUser: string;
  updateDate: Date;
  parentOrgCode: string;
  editManagerList: Manager[] | null;
  editSeniorManagerList: Manager[] | null;
  managerList: Manager[] | null;
  seniorManagerList: Manager[] | null;
  orgSpecialList: SpecialSalaryItem[] | null;
  editSpecialList: SpecialSalaryItem[] | null;
  orgApplyStartDateHistoryLt: string[] | null;
  orgSpecialHistoryMap: {
    [key: string]: SpecialSalaryItem[];
  };
  workingDaysId: string | null;
  isExistOrganization: boolean;
  // createUser: string;
}

export default class OrganizationSpecialSalaryDomain {
  constructor(private rawData: OrganizationSpecialSalary) {
    // Todo
  }

  static generateInitial(): OrganizationSpecialSalaryDomain {
    return new OrganizationSpecialSalaryDomain({
      id: {
        orgCode: "",
        applyStartDate: new Date(),
        companyCode: "",
      },
      orgName: "",
      orgNameKana: "",
      category: "",
      closeFlag: false,
      areaId: null,
      prefectureCode: "",
      employmentId: "",
      workingDaysId: null,
      businessCategoryId: null,
      posCode: null,
      numberOfSeat: null,
      floorSpace: null,
      orgSpecialList: [],
      editSpecialList: [],
      applyStartMonth: null,
      applyEndMonth: null,
      applyEndDate: new Date(),
      specifyCode: "",
      dispStartHour: null,
      dispHour: null,
      createUser: "",
      createDate: new Date(),
      updateUser: "",
      updateDate: new Date(),
      parentOrgCode: "",
      editManagerList: null,
      editSeniorManagerList: null,
      managerList: null,
      seniorManagerList: null,
      orgApplyStartDateHistoryLt: null,
      orgSpecialHistoryMap: {},
      isExistOrganization: false,
    });
  }

  getRawDataPost(): OrganizationSalaryBasic {
    return {
      id: {
        // companyCode: this.rawData.id.companyCode,
        orgCode: this.rawData.id.orgCode,
        applyStartDate: moment(this.rawData.id.applyStartDate).format("YYYY/MM/DD"),
      },
      editSpecialList:
        this.rawData.orgSpecialList?.map((item) => ({
          id: {
            applyStartMonth: this.rawData.applyStartMonth
              ? moment(this.rawData.applyStartMonth).format("YYYY/MM/01")
              : null,
            specialSalaryPatternId: item.id.specialSalaryPatternId,
          },
          addSalary: item.addSalary,
          applyEndMonth: this.rawData.applyEndMonth ? moment(this.rawData.applyEndMonth).format("YYYY/MM/01") : null,
        })) ?? [],
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: Number(this.rawData.category),
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId ?? "",
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId ?? "",
      businessCategoryId: this.rawData.businessCategoryId ?? "",
      posCode: this.rawData.posCode ?? "",
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      // createUser: this.rawData.createUser,
      createUser: sessionStorage.getItem("loginUser.staffName") || "",
      updateUser: sessionStorage.getItem("loginUser.staffName") || "",
    };
  }

  getRawDataSetting(): OrganizationSpecialSalary {
    return {
      id: this.rawData.id,
      orgName: this.rawData.orgName,
      orgNameKana: this.rawData.orgNameKana,
      category: this.rawData.category,
      closeFlag: this.rawData.closeFlag,
      areaId: this.rawData.areaId,
      prefectureCode: this.rawData.prefectureCode,
      employmentId: this.rawData.employmentId,
      workingDaysId: this.rawData.workingDaysId,
      businessCategoryId: this.rawData.businessCategoryId,
      posCode: this.rawData.posCode,
      numberOfSeat: this.rawData.numberOfSeat,
      floorSpace: this.rawData.floorSpace,
      orgSpecialList: this.rawData.orgSpecialList,
      applyStartMonth: this.rawData.applyStartMonth,
      applyEndMonth: this.rawData.applyEndMonth,
      applyEndDate: this.rawData.applyEndDate,
      specifyCode: this.rawData.specifyCode,
      dispStartHour: this.rawData.dispStartHour,
      dispHour: this.rawData.dispHour,
      createUser: this.rawData.createUser,
      createDate: this.rawData.createDate,
      updateUser: this.rawData.updateUser,
      updateDate: this.rawData.updateDate,
      parentOrgCode: this.rawData.parentOrgCode,
      editManagerList: this.rawData.editManagerList,
      editSeniorManagerList: this.rawData.editSeniorManagerList,
      managerList: this.rawData.managerList,
      seniorManagerList: this.rawData.seniorManagerList,
      editSpecialList: this.rawData.editSpecialList,
      orgApplyStartDateHistoryLt: this.rawData.orgApplyStartDateHistoryLt,
      orgSpecialHistoryMap: this.rawData.orgSpecialHistoryMap,
      isExistOrganization: this.rawData.isExistOrganization,
    };
  }

  get orgCode(): string {
    return this.rawData.id.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.id.orgCode = orgCode;
  }

  get applyStartMonth(): Date | null {
    return this.rawData.applyStartMonth;
  }

  set applyStartMonth(date: Date | null) {
    this.rawData.applyStartMonth = date;
  }

  get applyEndMonth(): Date | null {
    return this.rawData.applyEndMonth;
  }

  set applyEndMonth(applyEndMonth: Date | null) {
    this.rawData.applyEndMonth = applyEndMonth;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get orgNameKana(): string {
    return this.rawData.orgNameKana;
  }

  set orgNameKana(orgNameKana: string) {
    this.rawData.orgNameKana = orgNameKana;
  }

  get category(): string {
    return this.rawData.category;
  }

  set category(category: string) {
    this.rawData.category = category;
  }

  get closeFlag(): boolean {
    return this.rawData.closeFlag;
  }

  set closeFlag(closeFlag: boolean) {
    this.rawData.closeFlag = closeFlag;
  }

  get areaId(): string | null {
    return this.rawData.areaId;
  }

  set areaId(areaId: string | null) {
    this.rawData.areaId = areaId;
  }

  get prefectureCode(): string {
    return this.rawData.prefectureCode;
  }

  set prefectureCode(prefectureCode: string) {
    this.rawData.prefectureCode = prefectureCode;
  }

  get employmentId(): string {
    return this.rawData.employmentId;
  }

  set employmentId(employmentId: string) {
    this.rawData.employmentId = employmentId;
  }

  get workingDaysId(): string | null {
    return this.rawData.workingDaysId;
  }

  set workingDaysId(workingDaysId: string | null) {
    this.rawData.workingDaysId = workingDaysId;
  }

  get businessCategoryId(): string | null {
    return this.rawData.workingDaysId;
  }

  set businessCategoryId(businessCategoryId: string | null) {
    this.rawData.businessCategoryId = businessCategoryId;
  }

  get posCode(): string | null {
    return this.rawData.posCode;
  }

  set posCode(posCode: string | null) {
    this.rawData.posCode = posCode;
  }

  get numberOfSeat(): number | null {
    return this.rawData.numberOfSeat;
  }

  set numberOfSeat(numberOfSeat: number | null) {
    this.rawData.numberOfSeat = numberOfSeat;
  }

  get floorSpace(): number | null {
    return this.rawData.floorSpace;
  }

  set floorSpace(floorSpace: number | null) {
    this.rawData.floorSpace = floorSpace;
  }

  get orgSpecialList(): SpecialSalaryItem[] | null {
    return this.rawData.orgSpecialList;
  }

  set orgSpecialList(orgSpecialList: SpecialSalaryItem[] | null) {
    this.rawData.orgSpecialList = orgSpecialList;
  }

  get orgApplyStartDateHistoryLt(): string[] | null {
    return this.rawData.orgApplyStartDateHistoryLt;
  }

  set orgApplyStartDateHistoryLt(orgApplyStartDateHistoryLt: string[] | null) {
    this.rawData.orgApplyStartDateHistoryLt = orgApplyStartDateHistoryLt;
  }

  get orgSpecialHistoryMap(): {
    [key: string]: SpecialSalaryItem[];
  } {
    return this.rawData.orgSpecialHistoryMap;
  }

  set orgSpecialHistoryMap(orgSpecialHistoryMap: { [key: string]: SpecialSalaryItem[] }) {
    this.rawData.orgSpecialHistoryMap = orgSpecialHistoryMap;
  }

  // get createUser(): string {
  //   return this.rawData.createUser;
  // }

  // set createUser(createUser: string) {
  //   this.rawData.createUser = createUser;
  // }
}
