
import LoginUserDomain from 'domain/loginUser';

export const types = {
  SET_LOGIN_USER: 'ui/general/SET_LOGIN_USER' as const,
};

export const setLoginUser = (payload: LoginUserDomain) => ({
  type: types.SET_LOGIN_USER,
  payload,
});

type Actions = (
  | ReturnType<typeof setLoginUser>
);

export default Actions;
