import Actions, { types } from './actions';
import { ICCardStateType } from '.';

const initialState: ICCardStateType = {
  isConnectICCardUsb: false,
  disabledDetect: false,
  deviceICCard: null,
  cardId: ''
};

const reducer = (state = initialState, action: Actions): ICCardStateType => {
  const newState = { ...state };
  switch (action.type) {
    case types.SET_IS_CONNECT_IC_CARD_USB:
      newState.isConnectICCardUsb = action.payload;
      return newState;
    case types.SET_DISABLE_DETECT:
      newState.disabledDetect = action.payload;
      return newState;
    case types.SET_DEVICE_IC_CARD:
      newState.deviceICCard = action.payload;
      return newState;
    case types.SET_CARD_ID:
      if(!newState.disabledDetect){
        newState.cardId = action.payload;
      }
      return newState;
    case types.SET_HARD_CARD_ID:
      newState.cardId = action.payload;
      return newState;
    default:
      return state;
  }
};

export default reducer;
