import { useState, useEffect, Dispatch, SetStateAction, useCallback } from "react";

import moment from "moment";

export interface ShiftPatternDetailInput {
  createUser: string;
  updateUser: string;
  shiftPatternDetailId: string;
  shiftPatternId: string;
  businessId: string;
  startTime: string;
  isStartTimeNextDay: boolean;
  endTime: string;
  isEndTimeNextDay: boolean;
  stampId?: string;
  isRemote?: boolean;
  shiftId?: string;
}

export interface ShiftPatternDetail {
  shiftPatternDetailId: string;
  shiftPatternId: string;
  businessId: string;
  startHourForm: string;
  startTimeForm: string;
  isStartTimeNextDay: boolean;
  endHourForm: string;
  endTimeForm: string;
  isEndTimeNextDay: boolean;
  createUser: string;
  updateUser: string;
  isRemote?: boolean;
}

export const useShiftPatternDetailDomainForm = (
  listTimes: any,
  shiftPatternDetailsList: any,
  setShiftPatternDetailsList: Dispatch<SetStateAction<Array<ShiftPatternDetailInput>>>,
  attendBusinessOptions: any,
  isCreate: boolean,
) => {
  const [detailList, setDetailList] = useState<Array<ShiftPatternDetail>>([
    {
      shiftPatternDetailId: "",
      shiftPatternId: "",
      businessId: "",
      startHourForm: "00",
      startTimeForm: "00",
      isStartTimeNextDay: false,
      endHourForm: "00",
      endTimeForm: "00",
      isEndTimeNextDay: false,
      createUser: "",
      updateUser: "",
      isRemote: false,
    },
  ]);

  let isCheckBusiness = "false";
  const [isHaveBusiness, setHaveBusiness] = useState<boolean>(isCheckBusiness === "true" ? true : false);

  // データの取得
  const fetchData = useCallback(async () => {
    if (shiftPatternDetailsList.length) {
      setDetailList(
        shiftPatternDetailsList.map((shiftPatternDetail: ShiftPatternDetailInput) => {
          const { stampId, shiftId } = shiftPatternDetail;
          let dateFormat = "HH:mm:ss";
          if (stampId || shiftId) {
            dateFormat = "YYYY-MM-DD HH:mm:ss";
          }

          return {
            shiftPatternDetailId: isCreate === true ? "" : shiftPatternDetail.shiftPatternDetailId,
            shiftPatternId: isCreate === true ? "" : shiftPatternDetail.shiftPatternId,
            businessId: shiftPatternDetail.businessId,
            startHourForm: moment(shiftPatternDetail.startTime, `${dateFormat}`).format("HH"),
            startTimeForm: moment(shiftPatternDetail.startTime, `${dateFormat}`).format("mm"),
            isStartTimeNextDay: shiftPatternDetail.isStartTimeNextDay,
            endHourForm: moment(shiftPatternDetail.endTime, `${dateFormat}`).format("HH"),
            endTimeForm: moment(shiftPatternDetail.endTime, `${dateFormat}`).format("mm"),
            isEndTimeNextDay: shiftPatternDetail.isEndTimeNextDay,
            createUser: shiftPatternDetail.createUser,
            updateUser: shiftPatternDetail.createUser,
            isRemote: shiftPatternDetail.isRemote,
          };
        }),
      );
    } else {
      setDetailList([
        {
          shiftPatternDetailId: "",
          shiftPatternId: "",
          businessId: "",
          // businessId: defaultBusinessId,
          startHourForm: "00",
          startTimeForm: "00",
          isStartTimeNextDay: false,
          endHourForm: "00",
          endTimeForm: "00",
          isEndTimeNextDay: false,
          createUser: "",
          updateUser: "",
          isRemote: false,
        },
      ]);
      convertDataOuput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendBusinessOptions, shiftPatternDetailsList]);

  const convertDataOuput = useCallback(
    async (items?: any) => {
      if (items) {
        setShiftPatternDetailsList(
          items.map((shiftPatternDetail: ShiftPatternDetail) => ({
            createUser: shiftPatternDetail.createUser,
            updateUser: shiftPatternDetail.updateUser,
            shiftPatternDetailId: isCreate === true ? "" : shiftPatternDetail.shiftPatternDetailId,
            shiftPatternId: isCreate === true ? "" : shiftPatternDetail.shiftPatternId,
            businessId: shiftPatternDetail.businessId,
            startTime: `${shiftPatternDetail.startHourForm}:${shiftPatternDetail.startTimeForm}`,
            isStartTimeNextDay: shiftPatternDetail.isStartTimeNextDay,
            endTime: `${shiftPatternDetail.endHourForm}:${shiftPatternDetail.endTimeForm}`,
            isEndTimeNextDay: shiftPatternDetail.isEndTimeNextDay,
            isRemote: shiftPatternDetail.isRemote,
          })),
        );
      } else {
        setShiftPatternDetailsList(
          detailList.map((shiftPatternDetail: ShiftPatternDetail) => ({
            createUser: shiftPatternDetail.createUser,
            updateUser: shiftPatternDetail.updateUser,
            shiftPatternDetailId: isCreate === true ? "" : shiftPatternDetail.shiftPatternDetailId,
            shiftPatternId: isCreate === true ? "" : shiftPatternDetail.shiftPatternId,
            businessId: shiftPatternDetail.businessId,
            startTime: `${shiftPatternDetail.startHourForm}:${shiftPatternDetail.startTimeForm}`,
            isStartTimeNextDay: shiftPatternDetail.isStartTimeNextDay,
            endTime: `${shiftPatternDetail.endHourForm}:${shiftPatternDetail.endTimeForm}`,
            isEndTimeNextDay: shiftPatternDetail.isEndTimeNextDay,
            isRemote: shiftPatternDetail.isRemote,
          })),
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [detailList],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    detailList,
    setDetailList,
    convertDataOuput,
    isHaveBusiness,
    setHaveBusiness,
  };
};

export default {
  useShiftPatternDetailDomainForm,
};
