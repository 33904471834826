/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useState, useEffect } from 'react';

import { searchDate } from 'api/achievementConfirm';
import {
  getApprovedTransferList,
  getApprovedStampList,
  getApprovedHolidayList,
  getApprovedOverTimeList,
  getApprovedHolidayWorkList,
  getApprovedAgreement36List,
  getApprovedWorkScheduleChangeList,
  getRejectedStampList,
  getRejectedHolidayList,
  getRejectedOverTimeList,
  getRejectedHolidayWorkList,
  getRejectedTransferList,
  getRejectedAgreement36List,
  getRejectedWorkScheduleChangeList,
  getApplyingStampList,
  getApplyingHolidayList,
  getApplyingHolidayWorkList,
  getApplyingOverTimeList,
  getApplyingAgreement36List,
  getApplyingWorkScheduleChangeList,
  getApplyingTransferList,
} from './api';
import ApprovedTransferDomain from './domain/approvedTransferDomain';
import ApprovedStampDomain from './domain/approvedStampDomain';
import ApprovedHolidayDomain from './domain/approvedHolidayDomain';
import ApprovedOvertimeDomain from './domain/approvedOvertimeDomain';
import Approved36AgreementDomain from './domain/approved36AgreementDomain';
import ApprovedHolidayWorkDomain from './domain/approvedHolidayWorkDomain';
import ApprovedWorkScheduleChangeDomain from './domain/approvedWorkScheduleChange';
import RejectedStampDomain from './domain/rejectedStampDomain';
import RejectedHolidayDomain from './domain/rejectedHolidayDomain';
import RejectedOvertimeDomain from './domain/rejectedOvertimeDomain';
import Rejected36AgreementDomain from './domain/rejected36AgreementDomain';
import RejectedHolidayWorkDomain from './domain/rejectedHolidayWorkDomain';
import RejectedTransferDomain from './domain/rejectedTransferDomain';
import RejectedWorkScheduleChangeDomain from './domain/rejectedWorkScheduleChange';
import ApplyingStampDomain from './domain/applyingStampDomain';
import ApplyingHolidayDomain from './domain/applyingHolidayDomain';
import ApplyingOvertimeDomain from './domain/applyingOvertimeDomain';
import Applying36AgreementDomain from './domain/applying36AgreementDomain';
import ApplyingWorkScheduleChangeAppDomain from './domain/applyingWorkScheduleChangeAppDomain';
import ApplyingHolidayWorkDomain from './domain/applyingHolidayWorkDomain';
import ApplyingTransferDomain from './domain/applyingTransferDomain';
import {
  IStamp,
  IHoliday,
  IOvertime,
  IHolidayWork,
  ITransfer,
  I36Agreement,
  IWorkSchedule
} from './interface';
import { getReasonList } from 'api/stampModification';
import { OptionType } from 'components/atoms/Select';


export const useAttendApplicationApproval = () => {
  const initDateFrom = sessionStorage.getItem('attendApplicationApproval.targetDateFrom')
  const initDateTo = sessionStorage.getItem('attendApplicationApproval.targetDateTo')
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('attendApplicationApproval.targetDateFrom')
      sessionStorage.removeItem('attendApplicationApproval.targetDateTo')
    }
  }, [])
  // Range Time
  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD');
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(new Date(startOfMonth));
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(endOfMonth));

  const [initialDateFrom, setInitialDateFrom] = useState<Date>(new Date(startOfMonth));
  const [initialDateTo, setInitialDateTo] = useState<Date>(new Date(endOfMonth));
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const [reasonOptionList, setReasonOptionList] = useState<Array<OptionType>>([]);

  // Applying tab
  const [applyingStampList, setApplyingStampList] = useState<ApplyingStampDomain[]>([]);
  const [applyingHolidayList, setApplyingHolidayList] = useState<ApplyingHolidayDomain[]>([]);
  const [applyingOvertimeList, setApplyingOvertimeList] = useState<ApplyingOvertimeDomain[]>([]);
  const [applyingHolidayWorkList, setApplyingHolidayWorkList] = useState<ApplyingHolidayWorkDomain[]>([]);
  const [applyingTransferList, setApplyingTransferList] = useState<ApplyingTransferDomain[]>([]);
  const [applyingAgreement36List, setApplyingAgreement36List] = useState<Applying36AgreementDomain[]>([]);
  const [applyingWorkScheduleChangeList, setApplyingWorkScheduleChangeList] = useState<ApplyingWorkScheduleChangeAppDomain[]>([]);
  const [applyingReasonId, setApplyingReasonId] = useState<string>();

  // Approved tab
  const [approvedStampList, setApprovedStampList] = useState<ApprovedStampDomain[]>([]);
  const [approvedHolidayList, setApprovedHolidayList] = useState<ApprovedHolidayDomain[]>([]);
  const [approvedOvertimeList, setApprovedOvertimeList] = useState<ApprovedOvertimeDomain[]>([]);
  const [approvedHolidayWorkList, setApprovedHolidayWorkList] = useState<ApprovedHolidayWorkDomain[]>([]);
  const [approvedTransferList, setApprovedTransferList] = useState<ApprovedTransferDomain[]>([]);
  const [approvedAgreement36List, setApprovedAgreement36List] = useState<Approved36AgreementDomain[]>([]);
  const [approvedWorkScheduleChangeList, setApprovedWorkScheduleChangeList] = useState<ApprovedWorkScheduleChangeDomain[]>([]);
  const [approvedReasonId, setApprovedReasonId] = useState<string>();

  // Rejected tab
  const [rejectedStampList, setRejectedStampList] = useState<RejectedStampDomain[]>([]);
  const [rejectedHolidayList, setRejectedHolidayList] = useState<RejectedHolidayDomain[]>([]);
  const [rejectedOvertimeList, setRejectedOvertimeList] = useState<RejectedOvertimeDomain[]>([]);
  const [rejectedHolidayWorkList, setRejectedHolidayWorkList] = useState<RejectedHolidayWorkDomain[]>([]);
  const [rejectedTransferList, setRejectedTransferList] = useState<RejectedTransferDomain[]>([]);
  const [rejectedAgreement36List, setRejectedAgreement36List] = useState<Rejected36AgreementDomain[]>([]);
  const [rejectedWorkScheduleChangeList, setRejectedWorkScheduleChangeList] = useState<RejectedWorkScheduleChangeDomain[]>([]);
  const [rejectedReasonId, setRejectedReasonId] = useState<string>();

  const fetchData = async () => {
    setIsLoading(true);

    let userPaymentDay = []; // empty on subsequent change
    if (isFirstRender) {
      userPaymentDay = await searchDate({ staffCode });
      if(initDateFrom){
        userPaymentDay[0] = initDateFrom
      }
      if(initDateTo){
        userPaymentDay[1] = initDateTo
      }
      setIsFirstRender(false);
      setInitialDateFrom(moment(userPaymentDay[0]).toDate());
      setInitialDateTo(moment(userPaymentDay[1]).toDate());
      setTargetDateFrom(moment(userPaymentDay[0]).toDate());
      setTargetDateTo(moment(userPaymentDay[1]).toDate());
      handleGetReasonList();
    }

    Promise.all([
      await fetchApplyingList(userPaymentDay[0], userPaymentDay[1]),
      await fetchApprovedList(userPaymentDay[0], userPaymentDay[1]),
      await fetchRejectedList(userPaymentDay[0], userPaymentDay[1]),
    ])
    .then(() => { 
      setIsLoading(false);
    })
  };

  const fetchApplyingReason= async (applyingReasonId:any) => {
    setIsLoading(true);
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      reasonId: applyingReasonId,
    };

    await getApplyingStampList(params).then((response:any) => {
      setApplyingStampList(response.map((data: IStamp) => new ApplyingStampDomain(data)));
    });
    setIsLoading(false);
  };

  const fetchApprovedReason= async (approvedReasonId:any) => {
    setIsLoading(true);
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      reasonId: approvedReasonId,
    };

    await getApprovedStampList(params).then((response:any) => {
      setApprovedStampList(response.map((data: IStamp) => new ApprovedStampDomain(data)));
    });
    setIsLoading(false);
  };

  const fetchRejectedReason= async (rejectedReasonId:any) => {
    setIsLoading(true);
    const params = {
      staffCode,
      targetDateFrom: moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: moment(targetDateTo).format('YYYY-MM-DD'),
      reasonId: rejectedReasonId,
    };

    await getRejectedStampList(params).then((response:any) => {
      setRejectedStampList(response.map((data: IStamp) => new RejectedStampDomain(data)));
    });
    setIsLoading(false);
  };

  const fetchSearchDate = async () => {
    const response = await searchDate({ staffCode });
    return response;
  };

  const fetchApplyingList = async (
    initialDateFrom: string | null = null,
    initialDateTo: string | null = null,
  ) => {
    const params = {
      staffCode,
      targetDateFrom: initialDateFrom
        ? moment(initialDateFrom).format('YYYY-MM-DD')
        : moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: initialDateTo
        ? moment(initialDateTo).format('YYYY-MM-DD')
        : moment(targetDateTo).format('YYYY-MM-DD'),
    };

    const paramStamp = {
      ...params,
      reasonId: applyingReasonId,
    };

    Promise.all([
      await getApplyingStampList(paramStamp),
      await getApplyingHolidayList(params),
      await getApplyingOverTimeList(params),
      await getApplyingAgreement36List(params),
      await getApplyingHolidayWorkList(params),
      await getApplyingTransferList(params),
      await getApplyingWorkScheduleChangeList(params),
    ]).then((result) => {
      const [
        stampList,
        holidayList,
        overtimeList,
        agreementList,
        holidayWorkList,
        transferList,
        workScheduleChangeList
      ] = result;

      setApplyingStampList(stampList.map((data: IStamp) => new ApplyingStampDomain(data)));
      setApplyingHolidayList(holidayList.map((data: IHoliday) => new ApplyingHolidayDomain(data)));
      setApplyingOvertimeList(overtimeList.map((data: IOvertime) => new ApplyingOvertimeDomain(data)));
      setApplyingAgreement36List(agreementList.map((data: I36Agreement) => new Applying36AgreementDomain(data)));
      setApplyingWorkScheduleChangeList(workScheduleChangeList.map((data: IWorkSchedule) => new ApplyingWorkScheduleChangeAppDomain(data)));
      setApplyingHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new ApplyingHolidayWorkDomain(data)));
      setApplyingTransferList(transferList.map((data: ITransfer) => new ApplyingTransferDomain(data)));
    });
  };

  const fetchApprovedList = async (
    initialDateFrom: any = null,
    initialDateTo: any = null,
  ) => {
    const params = {
      staffCode,
      targetDateFrom: initialDateFrom
        ? moment(initialDateFrom).format('YYYY-MM-DD')
        : moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: initialDateTo
        ? moment(initialDateTo).format('YYYY-MM-DD')
        : moment(targetDateTo).format('YYYY-MM-DD'),
    };

    const paramStamp = {
      ...params,
      reasonId: approvedReasonId,
    };

    await fetchApprovedStampList(paramStamp);
    await fetchApprovedHolidayList(params);
    await fetchApprovedOvertimeList(params);
    await fetchApprovedAgreement36List(params);
    await fetchApprovedHolidayWorkList(params);
    await fetchApprovedTransferList(params);
    await fetchApprovedWorkScheduleChangeList(params);
  };

  const fetchApprovedStampList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
        reasonId: approvedReasonId,
      };
    }
    const stampList = await getApprovedStampList(params);
    setApprovedStampList(stampList.map((data: IStamp) => new ApprovedStampDomain(data)));
  };
  const fetchApprovedHolidayList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayList = await getApprovedHolidayList(params);
    setApprovedHolidayList(holidayList.map((data: IHoliday) => new ApprovedHolidayDomain(data)));
  };
  const fetchApprovedOvertimeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const overtimeList = await getApprovedOverTimeList(params);
    setApprovedOvertimeList(overtimeList.map((data: IOvertime) => new ApprovedOvertimeDomain(data)));
  };
  const fetchApprovedAgreement36List = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const agreement36List = await getApprovedAgreement36List(params);
    setApprovedAgreement36List(agreement36List.map((data: I36Agreement) => new Approved36AgreementDomain(data)));
  };
  const fetchApprovedHolidayWorkList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayWorkList = await getApprovedHolidayWorkList(params);
    setApprovedHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new ApprovedHolidayWorkDomain(data)));
  };
  const fetchApprovedTransferList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const transferList = await getApprovedTransferList(params);
    setApprovedTransferList(transferList.map((data: ITransfer) => new ApprovedTransferDomain(data)));
  };
  const fetchApprovedWorkScheduleChangeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const workScheduleChangeList = await getApprovedWorkScheduleChangeList(params);
    setApprovedWorkScheduleChangeList(workScheduleChangeList.map((data: IWorkSchedule) => new ApprovedWorkScheduleChangeDomain(data)));
  };

  const fetchRejectedList = async (
    initialDateFrom: any = null,
    initialDateTo: any = null,
  ) => {
    const params = {
      staffCode,
      targetDateFrom: initialDateFrom
        ? moment(initialDateFrom).format('YYYY-MM-DD')
        : moment(targetDateFrom).format('YYYY-MM-DD'),
      targetDateTo: initialDateTo
        ? moment(initialDateTo).format('YYYY-MM-DD')
        : moment(targetDateTo).format('YYYY-MM-DD'),
    };

    const paramStamp = {
      ...params,
      reasonId: rejectedReasonId,
    };
    
    await fetchRejectedStampList(paramStamp);
    await fetchRejectedHolidayList(params);
    await fetchRejectedOvertimeList(params);
    await fetchRejectedAgreement36List(params);
    await fetchRejectedHolidayWorkList(params);
    await fetchRejectedTransferList(params);
    await fetchRejectedWorkScheduleChangeList(params);
  };

  const fetchRejectedStampList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
        reasonId: rejectedReasonId,
      };
    }
    const stampList = await getRejectedStampList(params);
    setRejectedStampList(stampList.map((data: IStamp) => new RejectedStampDomain(data)));
  };
  const fetchRejectedHolidayList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayList = await getRejectedHolidayList(params);
    setRejectedHolidayList(holidayList.map((data: IHoliday) => new RejectedHolidayDomain(data)));
  };
  const fetchRejectedOvertimeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const overtimeList = await getRejectedOverTimeList(params);
    setRejectedOvertimeList(overtimeList.map((data: IOvertime) => new RejectedOvertimeDomain(data)));
  };
  const fetchRejectedAgreement36List = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const agreement36List = await getRejectedAgreement36List(params);
    setRejectedAgreement36List(agreement36List.map((data: I36Agreement) => new Rejected36AgreementDomain(data)));
  };
  const fetchRejectedWorkScheduleChangeList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const workScheduleChangeList = await getRejectedWorkScheduleChangeList(params);
    setRejectedWorkScheduleChangeList(workScheduleChangeList.map((data: IWorkSchedule) => new RejectedWorkScheduleChangeDomain(data)));
  };
  const fetchRejectedHolidayWorkList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const holidayWorkList = await getRejectedHolidayWorkList(params);
    setRejectedHolidayWorkList(holidayWorkList.map((data: IHolidayWork) => new RejectedHolidayWorkDomain(data)));
  };
  const fetchRejectedTransferList = async (params: any) => {
    if (!params) {
      params = {
        staffCode,
        targetDateFrom: moment(initialDateFrom).format('YYYY-MM-DD'),
        targetDateTo: moment(initialDateTo).format('YYYY-MM-DD'),
      };
    }
    const transferList = await getRejectedTransferList(params);
    setRejectedTransferList(transferList.map((data: ITransfer) => new RejectedTransferDomain(data)));
  };
  const handleGetReasonList = async () => {
    await getReasonList(1).then((response:any) => {
      if (response && response.length) {
        let tmpReason = response.map((obj:any) => ({
          value: obj.reasonId === null ? '' : obj.reasonId,
          label: obj.applicationReason === null ? '' : obj.applicationReason,
        }));
        tmpReason.unshift({value:'all', label:'全て'});
        setReasonOptionList(tmpReason);
        setApplyingReasonId('all');
        setApprovedReasonId('all');
        setRejectedReasonId('all');
      }
    });
  };

  return {
    isLoading,
    setIsLoading,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    initialDateFrom,
    setInitialDateFrom,
    initialDateTo,
    setInitialDateTo,

    applyingReasonId,
    setApplyingReasonId,
    approvedReasonId,
    setApprovedReasonId,
    rejectedReasonId,
    setRejectedReasonId,
    reasonOptionList,
    setReasonOptionList,

    applyingStampList,
    applyingHolidayList,
    applyingOvertimeList,
    applyingHolidayWorkList,
    applyingTransferList,
    applyingAgreement36List,
    applyingWorkScheduleChangeList,

    setApplyingStampList,
    setApplyingHolidayList,
    setApplyingOvertimeList,
    setApplyingHolidayWorkList,
    setApplyingTransferList,
    setApplyingAgreement36List,
    setApplyingWorkScheduleChangeList,

    approvedStampList,
    approvedHolidayList,
    approvedOvertimeList,
    approvedHolidayWorkList,
    approvedTransferList,
    approvedAgreement36List,
    approvedWorkScheduleChangeList,

    rejectedStampList,
    rejectedHolidayList,
    rejectedOvertimeList,
    rejectedHolidayWorkList,
    rejectedTransferList,
    rejectedAgreement36List,
    rejectedWorkScheduleChangeList,

    fetchData,
    fetchSearchDate,
    fetchApplyingList,
    fetchApplyingReason,
    fetchApprovedReason,
    fetchRejectedReason,

    fetchApprovedList,
    fetchApprovedStampList,
    fetchApprovedHolidayList,
    fetchApprovedOvertimeList,
    fetchApprovedAgreement36List,
    fetchApprovedHolidayWorkList,
    fetchApprovedTransferList,
    fetchApprovedWorkScheduleChangeList,

    fetchRejectedList,
    fetchRejectedStampList,
    fetchRejectedHolidayList,
    fetchRejectedOvertimeList,
    fetchRejectedHolidayWorkList,
    fetchRejectedTransferList,
    fetchRejectedAgreement36List,
    fetchRejectedWorkScheduleChangeList
  };
};

export default useAttendApplicationApproval;
