/** @jsx jsx */

import React from "react";
import moment from "moment";
import { css, jsx } from "@emotion/core";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const PaidHolidayGranTable: React.FC<{
  paidHolidayGrantData: any;
}> = ({ paidHolidayGrantData }) => (
  <div
    css={css`
      table thead tr th {
        height: 46px;
      }
      table thead tr td div {
        text-align: center;
      }
      table tbody tr td {
        height: 32px;
      }
    `}
  >
    <table
      id="paidHolidayGranList"
      className="table table-bordered table-condensed"
      cellSpacing="0"
      style={{ whiteSpace: "nowrap", width: "100%" }}
    >
      <tbody>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>前年繰越数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.lastGrantDaysNumSum && paidHolidayGrantData.lastGrantDaysNumSum.toFixed(1)}日
                {paidHolidayGrantData.lastGrantTimesNumSum}
                時間
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>当年付与数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.grantDaysNumSum && paidHolidayGrantData.grantDaysNumSum.toFixed(1)}日
                {paidHolidayGrantData.grantTimesNumSum}
                時間
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>消化数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.digestedDaysNum && paidHolidayGrantData.digestedDaysNum.toFixed(1)}日
                {paidHolidayGrantData.digestedTimesNum}
                時間
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>残有給数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.remainDaysNum && paidHolidayGrantData.remainDaysNum.toFixed(1)}日
                {paidHolidayGrantData.remainTimesNum}
                時間
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>次回付与日</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.nextGrantDate == null
                  ? ""
                  : moment(paidHolidayGrantData.nextGrantDate).format("YYYY年MM月DD日")}
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>次回付与予定日数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.nextGrantDate == null ? "" : paidHolidayGrantData.nextGrantDays}日
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>次回繰越日数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {(paidHolidayGrantData.nextCarryOverDaysNum + paidHolidayGrantData.nextCarryOverFirstDaysNum).toFixed(
                  1,
                )}
                日{paidHolidayGrantData.nextCarryOverTimesNum + paidHolidayGrantData.nextCarryOverFirstTimesNum}
                時間
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>次回消滅日数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.nextGrantDate == null
                  ? ""
                  : paidHolidayGrantData.nextExtinctionDaysNum.toFixed(1)}
                日{paidHolidayGrantData.nextExtinctionTimesNum}
                時間
              </div>
            )}
          </td>
        </tr>
        <tr role="row">
          <td style={{ width: "170px" }}>
            <div css={thStyle}>積立有給残日数</div>
          </td>
          <td>
            {paidHolidayGrantData != null && (
              <div css={tdStyle}>
                {paidHolidayGrantData.stockPaidHolidayGrantDaysNum &&
                  paidHolidayGrantData.stockPaidHolidayGrantDaysNum.toFixed(1)}
                日
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
export default PaidHolidayGranTable;
