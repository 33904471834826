import moment from "moment";
import { IWorkSchedule } from "../interface";

const dateFormat = "YYYY/MM/DD HH:mm";

/**
 * API getApplyingOvertimeList
 */
export default class ApplyingWorkScheduleChangeAppDomain {
  constructor(private rawData: IWorkSchedule) {
    // do nothing
  }

  getRawData(): IWorkSchedule {
    return this.rawData;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }
  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get shiftId(): string {
    return this.rawData.shiftId;
  }
  set shiftId(shiftId: string) {
    this.rawData.shiftId = shiftId;
  }
  get applicationDate(): string {
    return moment(this.rawData.applicationDate).format(dateFormat);
  }
  set applicationDate(applicationDate: string) {
    this.rawData.applicationDate = moment(applicationDate).format(dateFormat);
  }
  get applicationStaffCode(): string {
    return this.rawData.applicationStaffCode;
  }
  set applicationStaffCode(applicationStaffCode: string) {
    this.rawData.applicationStaffCode = applicationStaffCode;
  }
  get orgCode(): string {
    return this.rawData.orgCode;
  }
  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }
  get staffCode(): string {
    return this.rawData.staffCode;
  }
  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }
  get applicationStatus(): number {
    return this.rawData.applicationStatus;
  }
  set applicationStatus(applicationStatus: number) {
    this.rawData.applicationStatus = applicationStatus;
  }
  get modifiyStatus(): number {
    return this.rawData.modifiyStatus;
  }
  set modifiyStatus(modifiyStatus: number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }
  get modifiyStatusName(): string {
    return this.rawData.modifiyStatusName;
  }
  set modifiyStatusName(modifiyStatusName: string) {
    this.rawData.modifiyStatusName = modifiyStatusName;
  }
  get applicationReason(): string {
    return this.rawData.applicationReason;
  }
  set applicationReason(applicationReason: string) {
    this.rawData.applicationReason = applicationReason;
  }
  get rejectReason(): any {
    return this.rawData.rejectReason;
  }
  set rejectReason(rejectReason: any) {
    this.rawData.rejectReason = rejectReason;
  }
  get version(): number {
    return this.rawData.version;
  }
  set version(version: number) {
    this.rawData.version = version;
  }
  get approvalDate(): string {
    return moment(this.rawData.approvalDate).format(dateFormat);
  }
  set approvalDate(approvalDate: string) {
    this.rawData.approvalDate = moment(approvalDate).format(dateFormat);
  }
  get approvalStaffCode(): any {
    return this.rawData.approvalStaffCode;
  }
  set approvalStaffCode(approvalStaffCode: any) {
    this.rawData.approvalStaffCode = approvalStaffCode;
  }
  get targetDate(): string {
    return moment(this.rawData.targetDate).format("YYYY/MM/DD");
  }
  set targetDate(targetDate: string) {
    this.rawData.targetDate = moment(targetDate).format("YYYY/MM/DD");
  }
  get shiftOrgCode(): string {
    return this.rawData.shiftOrgCode;
  }
  set shiftOrgCode(shiftOrgCode: string) {
    this.rawData.shiftOrgCode = shiftOrgCode;
  }
  get orgName(): string {
    return this.rawData.orgName;
  }
  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }
  get shiftOrgName(): string {
    return this.rawData.shiftOrgName;
  }
  set shiftOrgName(shiftOrgName: string) {
    this.rawData.shiftOrgName = shiftOrgName;
  }
  get staffName(): string {
    return this.rawData.staffName;
  }
  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }
  get applicationStaffName(): string {
    return this.rawData.applicationStaffName;
  }
  set applicationStaffName(applicationStaffName: string) {
    this.rawData.applicationStaffName = applicationStaffName;
  }
  get approvalStaffName(): any {
    return this.rawData.approvalStaffName;
  }
  set approvalStaffName(approvalStaffName: any) {
    this.rawData.approvalStaffName = approvalStaffName;
  }
  get detailList(): any {
    return this.rawData.detailList;
  }
  set detailList(detailList: any) {
    this.rawData.detailList = detailList;
  }
  
  get authorizer(): string {
    return `${this.rawData.approvalStaffCode} ${this.rawData.approvalStaffName}`;
  }

  get applicant(): string {
    return `${this.rawData.applicationStaffCode} ${this.rawData.applicationStaffName}`;
  }

  get targetPerson(): string {
    return `${this.rawData.staffCode} ${this.rawData.staffName}`;
  }

  get stampListStartTime(): string {
    const mapStartTime = this.rawData.detailList.map((stamp: any) => moment(stamp.startTime).format('YYYY/MM/DD HH:mm'));
    return mapStartTime.join('\n');
  }

  get stampListEndTime(): string {
    const mapEndTime = this.rawData.detailList.map((stamp: any) => (stamp.endTime ? moment(stamp.endTime).format('YYYY/MM/DD HH:mm') : ''));
    return mapEndTime.join('\n');
  }

}
