import ApiClient from './ApiClient';

export const getStampHistory = async (companyCode: string, orgCode: string,
  targetDateFrom: string, targetDateTo:string, underFlag: number): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    orgCode,
    targetDateFrom,
    targetDateTo,
    loginStaffCode,
    underFlag
  };
  const response = await ApiClient.get(`/v2/attendStamp/historyForRange/${companyCode}`, params);
  return response.data;
};

export const getGpsStampHistory = async (companyCode: string, orgCode: string, staffCode: string,
  targetDateFrom: string, targetDateTo:string, underFlag: number): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const params = {
    orgCode,
    staffCode,
    targetDateFrom,
    targetDateTo,
    loginStaffCode,
    underFlag
  };
  const response = await ApiClient.get(`/v2/attendStamp/gpsHistory/${companyCode}`, params);
  return response.data;
};

export const getKaorioHistory = async (companyCode: string, orgCode: string, staffCode: string,
  targetDateFrom: string, targetDateTo:string, underFlag: number): Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const params = {
    orgCode,
    staffCode,
    targetDateFrom,
    targetDateTo,
    loginStaffCode,
    underFlag
  };
  const response = await ApiClient.get(`/v2/attendStamp/kaoiroHistory/${companyCode}`, params);
  return response.data;
};


export const getSearchDateClosing = async (companyCode: string): Promise<any> => {
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const response = await ApiClient.get(`/v1/employments/searchDate/${companyCode}?staffCode=${staffCode}&closeDateFlg=false&targetDate=`, {});

  return response.data;
};

export default getStampHistory;
