import React, { useState, createContext, useEffect } from 'react';
import TimeSettingForm from 'components/organismos/master/labor/TimeSettingsForm';
import MasterOrganizeSettingLayout from 'components/organismos/master/labor/MasterOrganizeSettingLayout';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import { 
  DetailRole, 
} from 'api/masterRole';

type contextType = {
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  detailRole: DetailRole,
}

export const Context = createContext<contextType>({} as contextType);

const TimeSettingPage = () => {
  //loading UI
  const [isLoading, setIsLoading] = useState(false);

  const { detailRole, loadedRole } = useGetDetailRole(setIsLoading, functionCode.masterAttendSetting);
  // const [isOrgPage, setIsOrgPage] = useState<null|boolean>(null);

  // useEffect(() => {
  //   if(loadedRole){
  //     setIsOrgPage(detailRole.accessRange !== 3)
  //   }
  // }, [loadedRole, detailRole])
  return(
    <SidebarTemplate pageTitle="労務規約項目マスタ">
      <Context.Provider value={{
        isLoading,
        setIsLoading,
        detailRole,
      }}>
        <TimeSettingForm/>
      </Context.Provider>  
    </SidebarTemplate>
  )
};

export default TimeSettingPage;
