/** @jsx jsx */
import React, { useCallback, useEffect } from "react";
import { css, jsx } from "@emotion/core";

import { textFontSize, utilityColor, grayScale } from "components/styles";
import Icon from "components/atoms/Icon";
import BodyText from "components/atoms/BodyText";

const styles = {
  wrapper: css({
    width: "100%",
  }),
  content: css({
    display: "block",
    marginBottom: "15px",
    backgroundColor: utilityColor.white,
  }),
  button: css({
    border: "none",
    cursor: "pointer",
    outline: "none",
    fontFamily: "inherit",
    text: textFontSize.re,
    minWidth: "140px",
    width: "100%",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  }),
  hidden: css({
    display: "none",
  }),
  label: css({
    fontSize: "16px",
    fontWeight: "bold",
  }),
};

interface AccordionProps {
  text: string;
  color?: string;
  defaultExpand?: boolean;
  isHidden?: boolean;
  backgroundColor?: string;
  showBorder?: boolean;
  callBack: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  text,
  color = "white",
  defaultExpand = false,
  callBack,
  isHidden,
  backgroundColor = "#007BC3",
  showBorder = true,
}) => {
  const [isExpand, setExpand] = React.useState(defaultExpand);

  const onClick = useCallback(() => {
    setExpand(!isExpand);
    callBack();
  }, [callBack, isExpand]);

  useEffect(() => {
    if (isHidden) {
      setExpand(false);
    }
  }, [isHidden]);
  return (
    <React.Fragment>
      <div
        css={[
          css(styles.wrapper),
          css({
            margin: showBorder ? "16px 0 16px 0" : "0",
          }),
        ]}
      >
        <button
          type="button"
          css={[
            styles.button,
            { backgroundColor: backgroundColor, padding: showBorder ? "15px 0px" : "0 0 15px 0px" },
          ]}
          onClick={onClick}
        >
          {isExpand ? (
            <Icon type="chevronUp" color={color} size="15px" margin="0 20px 0 15px" verticalAlign="middle" />
          ) : (
            <Icon type="chevronDown" color={color} size="15px" margin="0 20px 0 15px" verticalAlign="middle" />
          )}
          <BodyText color={color} customStyle={styles.label}>
            {text}
          </BodyText>
        </button>

        <div
          css={[
            css(styles.content, !isExpand ? styles.hidden : ""),
            { border: showBorder ? `1px solid ${grayScale.gray20}` : "none" },
          ]}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Accordion;
