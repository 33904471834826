import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import TimeOutputLayoutItemForm from 'components/organismos/master/attend/TimeOutputLayout/TimeOutputLayoutItemForm';
import { useParams } from 'react-router-dom';

const TimeOutputItemPage: React.FC = () => {
  const {
    layoutId,
  } = useParams();

  return (
    <SidebarTemplate
      pageTitle={layoutId ? '勤怠出力レイアウト編集' : '新規勤怠出力レイアウト作成'}
    >
      <TimeOutputLayoutItemForm />
    </SidebarTemplate>
  );
};

export default TimeOutputItemPage;
