/* eslint-disable no-shadow */
export interface OrganizationSetting {
  createUser: string,
  updateUser: string,
  settingId: string,
  dispStartHour: number,
  dispHour: number,
  mwsDisplayFlag: number,
  mwsDisplayStartDay1: number,
  mwsDisplayStartDow: number,
  hopeShiftLimitFlag: number,
  shiftCreateFlag: number,
  hopeShiftLimitFixedDay1: number,
  hopeShiftLimitFixedDay2: number,
  hopeShiftWeekStartDow: number,
  hopeShiftWeekOffset: number,
  hopeShiftWeekLimitDow: number,
  shiftStaffDisplayFlag: number,
  nursingYearStartMonth: number,
  nursingYearStartDay: number,
  nursingYearEndMonth: number,
  nursingYearEndDay: number,
  childNursingYearStartMonth: number,
  childNursingYearStartDay: number,
  childNursingYearEndMonth: number,
  childNursingYearEndDay: number,
  useRemindError: number;
  remindDayBeforeAndAfter: any;
}

export enum mwsDisplayFlag {
  mwsDisplayFlag0 = 0,
  mwsDisplayFlag1 = 1,
}

export enum mwsDisplayFlagLabel {
  '1週間表示' = 0,
  '1ヶ月表示' = 1,
}
// mwsDisplayStartDow
export enum mwsDisplayStartDow {
  mwsDisplayStartDow0 = 0,
  mwsDisplayStartDow1 = 1,
  mwsDisplayStartDow2 = 2,
  mwsDisplayStartDow3 = 3,
  mwsDisplayStartDow4 = 4,
  mwsDisplayStartDow5 = 5,
  mwsDisplayStartDow6 = 6,
}
export enum mwsDisplayStartDowLabel {
  '日' = 0,
  '月' = 1,
  '火' = 2,
  '水' = 3,
  '木' = 4,
  '金' = 5,
  '土' = 6,
}

// hopeShiftLimitFlag
export enum hopeShiftLimitFlag {
  hopeShiftLimitFlag0 = 0,
  hopeShiftLimitFlag1 = 1,
  hopeShiftLimitFlag2 = 2,
  hopeShiftLimitFlag3 = 3,
}
export enum hopeShiftLimitFlagLabel {
  '設定しない' = 0,
  '月1回固定日' = 1,
  '月2回固定日' = 2,
  '毎週' = 3,
}
export enum shiftCreateFlag {
  shiftCreateFlag0 = 0,
  shiftCreateFlag1 = 1,

}
export enum shiftCreateFlagLabel {
  '使用しない' = 0,
  '使用する' = 1,
}

export default class OrganizationSettingDomain {
  constructor(private rawData: OrganizationSetting) {
    // do nothing
  }

  static generateInitial(): OrganizationSettingDomain {
    return new OrganizationSettingDomain({
      dispStartHour: 0,
      dispHour: 0,
      mwsDisplayFlag: 0,
      mwsDisplayStartDay1: 0,
      mwsDisplayStartDow: 0,
      hopeShiftLimitFlag: 0,
      shiftCreateFlag: 0,
      hopeShiftLimitFixedDay1: 0,
      hopeShiftLimitFixedDay2: 0,
      hopeShiftWeekStartDow: 0,
      hopeShiftWeekOffset: 0,
      hopeShiftWeekLimitDow: 0,
      shiftStaffDisplayFlag: 0,
      settingId: '',
      createUser: '',
      updateUser: '',
      nursingYearStartMonth: 4,
      nursingYearStartDay: 1,
      nursingYearEndMonth: 3,
      nursingYearEndDay: 31,
      childNursingYearStartMonth: 4,
      childNursingYearStartDay: 1,
      childNursingYearEndMonth: 3,
      childNursingYearEndDay: 31,
      remindDayBeforeAndAfter: null,
      useRemindError: 0,
    });
  }

  getRawData(): OrganizationSetting {
    return this.rawData;
  }

  getRawDataWithoutNullData(): OrganizationSetting {
    return {
      dispStartHour: this.rawData.dispStartHour ? this.rawData.dispStartHour : 0,
      dispHour: this.rawData.dispHour ? this.rawData.dispHour : 0,
      mwsDisplayFlag: this.rawData.mwsDisplayFlag ? this.rawData.mwsDisplayFlag : 0,
      mwsDisplayStartDay1: this.rawData.mwsDisplayStartDay1 ? this.rawData.mwsDisplayStartDay1 : 0,
      mwsDisplayStartDow: this.rawData.mwsDisplayStartDow ? this.rawData.mwsDisplayStartDow : 0,
      hopeShiftLimitFlag: this.rawData.hopeShiftLimitFlag ? this.rawData.hopeShiftLimitFlag : 0,
      shiftCreateFlag: this.rawData.shiftCreateFlag ? this.rawData.shiftCreateFlag : 0,
      hopeShiftLimitFixedDay1: this.rawData.hopeShiftLimitFixedDay1 ? this.rawData.hopeShiftLimitFixedDay1 : 0,
      hopeShiftLimitFixedDay2: this.rawData.hopeShiftLimitFixedDay2 ? this.rawData.hopeShiftLimitFixedDay2 : 0,
      hopeShiftWeekStartDow: this.rawData.hopeShiftWeekStartDow ? this.rawData.hopeShiftWeekStartDow : 0,
      hopeShiftWeekOffset: this.rawData.hopeShiftWeekOffset ? this.rawData.hopeShiftWeekOffset : 0,
      hopeShiftWeekLimitDow: this.rawData.hopeShiftWeekLimitDow ? this.rawData.hopeShiftWeekLimitDow : 0,
      shiftStaffDisplayFlag: this.rawData.shiftStaffDisplayFlag ? this.rawData.shiftStaffDisplayFlag : 0,
      settingId: this.rawData.settingId ? this.rawData.settingId : '',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      nursingYearStartMonth: this.rawData.nursingYearStartMonth || 4,
      nursingYearStartDay: this.rawData.nursingYearStartDay || 1,
      nursingYearEndMonth: this.rawData.nursingYearEndMonth || 3,
      nursingYearEndDay: this.rawData.nursingYearEndDay || 31,
      childNursingYearStartMonth: this.rawData.childNursingYearStartMonth || 4,
      childNursingYearStartDay: this.rawData.childNursingYearStartDay || 1,
      childNursingYearEndMonth: this.rawData.childNursingYearEndMonth || 3,
      childNursingYearEndDay: this.rawData.childNursingYearEndDay || 31,
      useRemindError: this.rawData.useRemindError ? this.rawData.useRemindError : 0,
      remindDayBeforeAndAfter: (this.rawData.remindDayBeforeAndAfter && this.rawData.useRemindError === 1) ? this.rawData.remindDayBeforeAndAfter : 0,
    };
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get settingId(): string {
    return this.rawData.settingId;
  }

  set settingId(settingId: string) {
    this.rawData.settingId = settingId;
  }


  get dispStartHour(): number {
    return this.rawData.dispStartHour;
  }

  set dispStartHour(dispStartHour: number) {
    this.rawData.dispStartHour = dispStartHour;
  }

  get dispHour(): number {
    return this.rawData.dispHour;
  }

  set dispHour(dispHour: number) {
    this.rawData.dispHour = dispHour;
  }

  get mwsDisplayFlag(): number {
    return this.rawData.mwsDisplayFlag;
  }

  set mwsDisplayFlag(mwsDisplayFlag: number) {
    this.rawData.mwsDisplayFlag = mwsDisplayFlag;
  }

  get mwsDisplayStartDay1(): number {
    return this.rawData.mwsDisplayStartDay1;
  }

  set mwsDisplayStartDay1(mwsDisplayStartDay1: number) {
    this.rawData.mwsDisplayStartDay1 = mwsDisplayStartDay1;
  }

  get mwsDisplayStartDow(): number {
    return this.rawData.mwsDisplayStartDow;
  }

  set mwsDisplayStartDow(mwsDisplayStartDow: number) {
    this.rawData.mwsDisplayStartDow = mwsDisplayStartDow;
  }

  get mwsDisplayStartDowLabel(): String {
    return mwsDisplayStartDowLabel[this.rawData.mwsDisplayStartDow];
  }

  get hopeShiftLimitFlag(): number {
    return this.rawData.hopeShiftLimitFlag;
  }

  set hopeShiftLimitFlag(hopeShiftLimitFlag: number) {
    this.rawData.hopeShiftLimitFlag = hopeShiftLimitFlag;
  }

  get shiftCreateFlag(): number {
    return this.rawData.shiftCreateFlag;
  }

  set shiftCreateFlag(shiftCreateFlag: number) {
    this.rawData.shiftCreateFlag = shiftCreateFlag;
  }

  get hopeShiftLimitFixedDay1(): number {
    return this.rawData.hopeShiftLimitFixedDay1;
  }

  set hopeShiftLimitFixedDay1(hopeShiftLimitFixedDay1: number) {
    this.rawData.hopeShiftLimitFixedDay1 = hopeShiftLimitFixedDay1;
  }

  get hopeShiftLimitFixedDay2(): number {
    return this.rawData.hopeShiftLimitFixedDay2;
  }

  set hopeShiftLimitFixedDay2(hopeShiftLimitFixedDay2: number) {
    this.rawData.hopeShiftLimitFixedDay2 = hopeShiftLimitFixedDay2;
  }

  get hopeShiftWeekStartDow(): number {
    return this.rawData.hopeShiftWeekStartDow;
  }

  set hopeShiftWeekStartDow(hopeShiftWeekStartDow: number) {
    this.rawData.hopeShiftWeekStartDow = hopeShiftWeekStartDow;
  }

  get hopeShiftWeekOffset(): number {
    return this.rawData.hopeShiftWeekOffset;
  }

  set hopeShiftWeekOffset(hopeShiftWeekOffset: number) {
    this.rawData.hopeShiftWeekOffset = hopeShiftWeekOffset;
  }

  get hopeShiftWeekLimitDow(): number {
    return this.rawData.hopeShiftWeekLimitDow;
  }

  set hopeShiftWeekLimitDow(hopeShiftWeekLimitDow: number) {
    this.rawData.hopeShiftWeekLimitDow = hopeShiftWeekLimitDow;
  }

  get shiftStaffDisplayFlag(): number {
    return this.rawData.shiftStaffDisplayFlag;
  }

  set shiftStaffDisplayFlag(shiftStaffDisplayFlag: number) {
    this.rawData.shiftStaffDisplayFlag = shiftStaffDisplayFlag;
  }

  
  get nursingYearStartMonth(): number {
    return this.rawData.nursingYearStartMonth;
  }

  set nursingYearStartMonth(nursingYearStartMonth: number) {
    this.rawData.nursingYearStartMonth = nursingYearStartMonth;
  } 

  get nursingYearStartDay(): number {
    return this.rawData.nursingYearStartDay;
  }

  set nursingYearStartDay(nursingYearStartDay: number) {
    this.rawData.nursingYearStartDay = nursingYearStartDay;
  } 

  get nursingYearEndMonth(): number {
    return this.rawData.nursingYearEndMonth;
  }

  set nursingYearEndMonth(nursingYearEndMonth: number) {
    this.rawData.nursingYearEndMonth = nursingYearEndMonth;
  } 

  get nursingYearEndDay(): number {
    return this.rawData.nursingYearEndDay;
  }

  set nursingYearEndDay(nursingYearEndDay: number) {
    this.rawData.nursingYearEndDay = nursingYearEndDay;
  } 
  
  get childNursingYearStartMonth(): number {
    return this.rawData.childNursingYearStartMonth;
  }

  set childNursingYearStartMonth(childNursingYearStartMonth: number) {
    this.rawData.childNursingYearStartMonth = childNursingYearStartMonth;
  } 

  get childNursingYearStartDay(): number {
    return this.rawData.childNursingYearStartDay;
  }

  set childNursingYearStartDay(childNursingYearStartDay: number) {
    this.rawData.childNursingYearStartDay = childNursingYearStartDay;
  } 

  get childNursingYearEndMonth(): number {
    return this.rawData.childNursingYearEndMonth;
  }

  set childNursingYearEndMonth(childNursingYearEndMonth: number) { 
    this.rawData.childNursingYearEndMonth = childNursingYearEndMonth;
  } 

  get childNursingYearEndDay(): number {
    return this.rawData.childNursingYearEndDay;
  }

  set childNursingYearEndDay(childNursingYearEndDay: number) {
    this.rawData.childNursingYearEndDay = childNursingYearEndDay;
  } 

  // useRemindError
  get useRemindError(): number {
    return this.rawData.useRemindError;
  }

  set useRemindError(useRemindError: number) {
    this.rawData.useRemindError = useRemindError;
  }

  // remindDayBeforeAndAfter
  get remindDayBeforeAndAfter(): any {
    return this.rawData.remindDayBeforeAndAfter;
  }

  set remindDayBeforeAndAfter(remindDayBeforeAndAfter: any) {
    this.rawData.remindDayBeforeAndAfter = remindDayBeforeAndAfter;
  }
}
