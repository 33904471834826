import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import BusinessCategoryListTable from 'components/organismos/master/sales/BusinessCategoryListTable/BusinessCategoryListTable';

const BusinessCategoriesPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="業態マスタ"
  >
    <BusinessCategoryListTable />
  </SidebarTemplate>
);

export default BusinessCategoriesPage;
