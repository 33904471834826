import React, { SVGProps } from "react";

const MenuHome: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_94_89)">
      <path d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" fill="#666666" />
    </g>
    <defs>
      <clipPath id="clip0_94_89">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MenuHome;
