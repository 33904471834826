import React from "react";

import SidebarTemplate from "components/templates/SidebarTemplate";
import PaidHolidayAlert from "components/organismos/master/attend/PaidHolidayAlert";

const PaidHolidayAlertPage: React.FC = () => (
  <SidebarTemplate pageTitle="有給アラート実績">
    <PaidHolidayAlert />
  </SidebarTemplate>
);

export default PaidHolidayAlertPage;
