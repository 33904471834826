/** @jsx jsx */
import React, { useEffect, useMemo, useState } from "react";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import moment from "moment";
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import Table from "components/molecules/Table";
import FormField from "components/atoms/Form/FormField";
import FormLabel from "components/atoms/Form/FormLabel";
import BodyText from "components/atoms/BodyText";
import HourInput from "components/atoms/Form/HourInput";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, DeleteButton } from "components/atoms/Button";
import TextForm from "components/molecules/TextForm";
import ConfirmModal from "components/organismos/ConfirmModal";
import useAttendAgreement36Application from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import productColor, { grayScale, textColor, textFontSize } from "components/styles";
import { Col, Row } from "react-grid-system";
import TabControl from "components/atoms/TabControl";
import AttendAgreement36Modal from "components/molecules/ChangeLogModal/Template/AttendAgreement36Modal";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import useIsMobile from "hooks/useIsMobile";
import { useHistory } from "react-router-dom";

const styles = {
  approvalWrap: css({
    padding: "0px",
    marginBottom: "15px",
    marginTop: "0px",
  }),
  formContent: css({
    paddingBottom: "20px",
  }),
  fieldForm: css({
    marginLeft: "35px",
    paddingTop: "0px",
  }),
  labelForm: css({
    width: "100px",
    paddingTop: "12px",
  }),
};

const AttendAgreement36Application: React.FC = () => {
  // アップロードファイル

  const {
    formik,
    targetDate,
    setTargetDate,
    confirmApplyModalOpen,
    closeConfirmApplyModal,
    isLoading,
    isGetOvertimeSucceed,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogAgreement36,
    selectOrgCode,
    selectOrgCodeName,
  } = useAttendAgreement36Application();
  const isMobile = useIsMobile();
  const history = useHistory();

  const [changeLogData, setChangeLogData] = useState<Array<any>>([]);

  useEffect(() => {
    if (formik.values.orgCode || formik.values.targetDate || formik.values.staffCode) {
      fetchChangeLogAgreement36(formik.values.orgCode, formik.values.targetDateStr, formik.values.staffCode).then(
        (res) => {
          setChangeLogData(res);
        },
      );
    }
  }, [formik.values.orgCode, formik.values.targetDate, formik.values.staffCode]);
  return (
    <BlockUI blocking={isLoading}>
      <FormContents
        customStyle={css({
          paddingBottom: isMobile ? "8px" : "",
        })}
      >
        <FormTitle
          title="限度時間超過申請"
          bold={true}
          customStyle={css(isMobile ? { padding: "8px 16px 0 16px", margin: 0 } : {})}
        />

        {isMobile ? (
          <FormPadding customStyle={css(isMobile ? { padding: "0 16px 16px 16px" } : { paddingLeft: "35px" })}>
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={12}>
                <BodyText>対象月</BodyText>
              </Col>
              <Col xs={12}>
                <div style={{ marginLeft: 32 }}>
                  <CalendarMonth openActionChevron={true} date={targetDate} setDate={setTargetDate} />
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={12}>
                <BodyText>所属組織</BodyText>
              </Col>
              <Col xs={12}>
                <BodyText>{`${selectOrgCode} ${selectOrgCodeName}`}</BodyText>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={12}>
                <BodyText>スタッフ</BodyText>
              </Col>
              <Col xs={12}>
                <BodyText>{`${sessionStorage.getItem("loginUser.staffCode")} ${sessionStorage.getItem(
                  "loginUser.staffName",
                )}`}</BodyText>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <BodyText bold>対象月</BodyText>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={12}>
                <BodyText bold>{moment(targetDate).format("YYYY年MM月")}</BodyText>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <BodyText bold>見込み残業時間</BodyText>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col style={{ width: "auto", flex: 0 }} xs={12}>
                <HourInput
                  name="applicationExpectedOvertimeHoursStr"
                  value={formik.values.applicationExpectedOvertimeHoursStr || 0}
                  label="時間"
                  min={0}
                  onChange={formik.handleChange}
                  wrapInput={css({
                    width: "120px",
                    background: "white",
                    margin: "auto",
                  })}
                  readOnly={formik.values.applicationStatus !== null && Number(formik.values.applicationStatus) === 0}
                />
              </Col>
            </Row>
            <Row>
              <Col
                md={2}
                xs={12}
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <BodyText>申請理由</BodyText>
              </Col>
              <Col md={6} xs={12}>
                <FormField>
                  <TextForm
                    required={false}
                    name="applicationReason"
                    label=""
                    value={String(formik.values.applicationReason)}
                    onChange={formik.handleChange}
                    errorMsg={formik.errors.applicationReason}
                    disable={formik.values.applicationStatus !== null && Number(formik.values.applicationStatus) === 0}
                  />
                </FormField>
              </Col>
            </Row>
          </FormPadding>
        ) : (
          <React.Fragment>
            <FormField customStyle={styles.fieldForm}>
              <FormLabel label="対象月" customStyle={styles.labelForm} />
              <FlexBox>
                <FlexBoxItem>
                  <CalendarMonth openActionChevron={true} date={targetDate} setDate={setTargetDate} />
                </FlexBoxItem>
              </FlexBox>
            </FormField>
            <FormField customStyle={styles.fieldForm}>
              <FormLabel label="所属組織" customStyle={styles.labelForm} />
              <BodyText customStyle={css({ paddingTop: "12px", color: grayScale.gray100 })}>
                {`${selectOrgCode} ${selectOrgCodeName}`}
              </BodyText>
            </FormField>
            <FormField customStyle={styles.fieldForm}>
              <FormLabel label="スタッフ" customStyle={styles.labelForm} />
              <BodyText customStyle={css({ paddingTop: "12px", color: grayScale.gray100 })}>
                {`${sessionStorage.getItem("loginUser.staffCode")} ${sessionStorage.getItem("loginUser.staffName")}`}
              </BodyText>
            </FormField>
            <div
              css={css`
                margin: 0px 16px;
                margin-top: 100px;
                td {
                  text-align: center;
                }
                input:read-only {
                  background-color: #f5f5f5;
                }
              `}
            >
              <Table>
                <thead>
                  <tr>
                    <Table.HeaderCell>対象月</Table.HeaderCell>
                    <Table.HeaderCell>見込み残業時間</Table.HeaderCell>
                    <Table.HeaderCell colSpan={4}>申請理由</Table.HeaderCell>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Table.Cell>{moment(targetDate).format("YYYY年MM月")}</Table.Cell>
                    <Table.Cell>
                      <HourInput
                        name="applicationExpectedOvertimeHoursStr"
                        value={formik.values.applicationExpectedOvertimeHoursStr || 0}
                        label="時間"
                        min={0}
                        onChange={formik.handleChange}
                        wrapInput={css({
                          width: "120px",
                          background: "white",
                          margin: "auto",
                        })}
                        readOnly={
                          formik.values.applicationStatus !== null && Number(formik.values.applicationStatus) === 0
                        }
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={4}>
                      <TextForm
                        required={false}
                        name="applicationReason"
                        label=""
                        value={String(formik.values.applicationReason)}
                        onChange={formik.handleChange}
                        errorMsg={formik.errors.applicationReason}
                        disable={
                          formik.values.applicationStatus !== null && Number(formik.values.applicationStatus) === 0
                        }
                      />
                    </Table.Cell>
                  </tr>
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        )}

        <FormSubmitArea>
          {isMobile ? (
            <FormPadding customStyle={css({ padding: "0 16px 16px 16px" })}>
              <div css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr);", gap: 8 }}>
                {isGetOvertimeSucceed &&
                  (formik.values.modifiyStatus === null ||
                    formik.values.modifiyStatus === 0 ||
                    (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0)) && (
                    <PrimaryButton
                      ghost={false}
                      disabled={formik.values.applicationStatus === 0 && formik.values.createDate !== null}
                      text="申請する"
                      onClick={() => {
                        formik.handleSubmit();
                        formik.setFieldValue("buttonType", 0);
                      }}
                    />
                  )}
                {formik.values.applicationStatus === 0 && (
                  <DeleteButton
                    ghost={false}
                    text="申請キャンセル"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.setFieldValue("buttonType", 1);
                    }}
                  />
                )}
                {formik.values.deleteAvailable === true && (
                  <DeleteButton
                    ghost={false}
                    text="削除申請"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.setFieldValue("buttonType", 2);
                    }}
                  />
                )}

                <Button
                  customStyle={css({
                    border: `1px solid ${productColor.primary}`,
                  })}
                  onClick={() => history.goBack()}
                  text="キャンセル"
                  ghost={true}
                />
              </div>
            </FormPadding>
          ) : (
            <div
              css={css`
                .btnAction:nth-of-type(2) {
                  margin-left: 10px;
                }
                .btnAction:nth-of-type(3) {
                  margin-left: 10px;
                }
              `}
            >
              <FormField
                customStyle={css({
                  justifyContent: "center",
                  marginTop: "50px",
                })}
              >
                {isGetOvertimeSucceed &&
                  (formik.values.modifiyStatus === null ||
                    formik.values.modifiyStatus === 0 ||
                    (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0)) && (
                    <div className="btnAction">
                      <PrimaryButton
                        ghost={false}
                        disabled={formik.values.applicationStatus === 0 && formik.values.createDate !== null}
                        text="申請する"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("buttonType", 0);
                        }}
                      />
                    </div>
                  )}
                {formik.values.applicationStatus === 0 && (
                  <div className="btnAction">
                    <DeleteButton
                      ghost={false}
                      text="申請キャンセル"
                      onClick={() => {
                        formik.handleSubmit();
                        formik.setFieldValue("buttonType", 1);
                      }}
                    />
                  </div>
                )}
                {formik.values.deleteAvailable === true && (
                  <div className="btnAction">
                    <DeleteButton
                      ghost={false}
                      text="削除申請"
                      onClick={() => {
                        formik.handleSubmit();
                        formik.setFieldValue("buttonType", 2);
                      }}
                    />
                  </div>
                )}
              </FormField>
              <FormField
                customStyle={css({
                  justifyContent: "center",
                  paddingTop: 0,
                })}
              >
                <PrimaryButton text="キャンセル" ghost={true} onClick={() => history.goBack()} />
              </FormField>
            </div>
          )}
        </FormSubmitArea>
        <ConfirmModal
          open={confirmApplyModalOpen}
          closeHandler={closeConfirmApplyModal}
          onSubmit={formik.handleSubmit}
          title={
            formik.values.buttonType === 0
              ? formik.values.modifiyStatus === 0
                ? "超過修正を申請"
                : "超過を申請"
              : formik.values.buttonType === 1
              ? "超過キャンセルを申請"
              : "削除超過を申請"
          }
          content={
            formik.values.buttonType === 0
              ? formik.values.modifiyStatus === 0
                ? "超過修正を申請します。よろしいですか？"
                : "超過を申請します。よろしいですか？"
              : formik.values.buttonType === 1
              ? "超過キャンセルを申請します。よろしいですか？"
              : "削除超過を申請します。よろしいですか？"
          }
          submitText="申請する"
          typeSubmit={formik.values.buttonType === 0 ? "confirm" : "reject"}
        />
      </FormContents>

      {!isMobile && (
        <div style={{ paddingTop: 20 }}>
          <FormContents customStyle={styles.formContent}>
            <Row style={{ padding: "10px 30px" }}>
              <Col
                md={2}
                xs={12}
                style={{
                  fontWeight: "bold",
                  fontSize: textFontSize.md,
                  lineHeight: "24px",
                  paddingBottom: "20px",
                  color: textColor.main,
                }}
              >
                更新履歴
              </Col>
            </Row>
            <TabControl
              isTabModal={true}
              isFixed={false}
              items={changeLogData.map((log, index: number) => {
                return {
                  value: String(index),
                  display: `ver${log.version}`,
                };
              })}
              setValue={setSelectChangelog as (arg: string) => void}
              currentValue={selectChangelog}
              styleNotActive={css({
                borderRadius: 0,
                border: "none",
                width: "100px",
                flex: "unset",
              })}
              styleActive={css({
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #007BC3",
                width: "100px",
                flex: "unset",
              })}
            />
            {changeLogData.length > 0 &&
              changeLogData.map((itemLog, index: number) => (
                <div
                  style={{
                    display: selectChangelog !== String(index) ? "none" : "block",
                  }}
                >
                  <React.Fragment>
                    <FormPadding
                      customStyle={{
                        fontSize: "14px",
                        color: textColor.mobileTitle,
                      }}
                    >
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請種別:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.modifiyStatus == 0 ? "申請" : "削除"}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請者:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationStaffCode && `${itemLog.applicationStaffCode} `}
                          {itemLog.applicationStaffName && itemLog.applicationStaffName}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請日時:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationDate && moment(itemLog.applicationDate).format("YYYY/MM/DD HH:mm:ss")}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          出勤組織:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.orgCode && itemLog.orgCode}
                          {itemLog.orgName && itemLog.orgName}
                        </Col>
                      </Row>
                      <Row>
                        <AttendAgreement36Modal
                          changeLogData={[itemLog]}
                          selectChangelog={String(index)}
                          customStyle={styles.approvalWrap}
                        />
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "10px",
                            color: textColor.main,
                          }}
                        >
                          修正理由:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationReason && itemLog.applicationReason}
                        </Col>
                      </Row>
                      {changeLogData.length > 0 && (
                        <Row>
                          <HistoryApprovalTable
                            historyData={itemLog.approvalHistoryList}
                            applicationStatus={itemLog.applicationStatus}
                          />
                        </Row>
                      )}
                    </FormPadding>
                  </React.Fragment>
                </div>
              ))}
          </FormContents>
        </div>
      )}
    </BlockUI>
  );
};

export default AttendAgreement36Application;
