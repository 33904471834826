/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import FormPadding from "components/atoms/Form/FormPadding";
import Table from "components/molecules/Table";
import moment from "moment";
import React from "react";
import { IchangeLog } from "../interface";

const styleTable = css`
	th {
		width: 100px !important;
	}
	td {
		text-align: center;
	}
`;

const AttendWorkPlanModal: React.FC<{
  changeLogData: IchangeLog[];
  selectChangelog: string;
  customStyle?:any;
}> = ({
  changeLogData,
  selectChangelog,
  customStyle
}) => {
    return (
      <React.Fragment>
        {
          changeLogData && changeLogData[Number(selectChangelog)]?.detailList?.length > 0 && (
            <FormPadding customStyle={customStyle}>
              <div>
                <Table customStyle={css({ width: "auto", marginBottom: "10px" })}>
                  <thead>
                    <tr>
                      <Table.HeaderCell customStyle={css({ width: "33.3%!important" })}>
                        開始時間
                      </Table.HeaderCell>
                      <Table.HeaderCell customStyle={css({ width: "33.3%!important" })}>
                        終了時間
                      </Table.HeaderCell>
                      <Table.HeaderCell customStyle={css({ width: "33.%!important" })}>
                        休憩
                      </Table.HeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      changeLogData &&
                      changeLogData[Number(selectChangelog)]?.detailList?.length > 0 &&
                      changeLogData[Number(selectChangelog)].detailList?.map((stamp, i) => (
                        <tr key={stamp.shiftId}>
                          <Table.Cell customStyle={css({ textAlign: "center", width: "33.3%" })}>
                            {moment(stamp.startTime).format("YYYY/MM/DD HH:mm:ss")}
                          </Table.Cell>
                          <Table.Cell customStyle={css({ textAlign: "center", width: "33.3%" })}>
                            {stamp.endTime ? moment(stamp.endTime).format("YYYY/MM/DD HH:mm:ss") : null}
                          </Table.Cell>
                          <Table.Cell customStyle={css({ textAlign: "center", width: "33.3%" })}>
                            {stamp.businessName}
                          </Table.Cell>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </FormPadding>
          )
        }
      </React.Fragment>
    );
  };

export default AttendWorkPlanModal;
