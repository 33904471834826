import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { IAttendAchievementError } from './interface';
import { getAttendAchievementError, getAttendAchievementErrorV3 } from './api';
import { useStaffsSelectBoxForOrgCodeOptions } from 'hooks/useStaffsOptions';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import { useStaffSelectBoxRange } from 'hooks/useEmploymentOptions';

const useAchievementErrorState = () => {
  const loginUserOrgCode = sessionStorage.getItem('attendError.orgCode') === 'all' ? 'all' : (sessionStorage.getItem('attendError.orgCode') || sessionStorage.getItem('loginUser.orgCode') || 'all');
  const now = new Date();
  let dateFrom = new Date(now.getFullYear(), now.getMonth(), 1); // get first date
  let dateTo = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); // get last date

  let targetDateFromStr = sessionStorage.getItem('attendError.targetDateFrom');
  let targetDateToStr = sessionStorage.getItem('attendError.targetDateTo');
  let isFilterStr = sessionStorage.getItem('attendError.isFilter') || "true";
  if (targetDateFromStr && targetDateToStr) {
    dateFrom = moment(targetDateFromStr).toDate();
    dateTo = moment(targetDateToStr).toDate();
  }
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(sessionStorage.getItem('attendError.directFromPopupAlert') === 'true' ? new Date(sessionStorage.getItem('loginUser.alertErrorStartDate') || "") : dateFrom);
  const [targetDateTo, setTargetDateTo] = useState<Date>(sessionStorage.getItem('attendError.directFromPopupAlert') === 'true' ? new Date(sessionStorage.getItem('loginUser.alertErrorEndDate') || "") : dateTo);
  const [orgCode, setOrgCode] = useState<string>(loginUserOrgCode);
  let [staffCode, setStaffCode] = useState(
    sessionStorage.getItem('attendError.staffCode')
    || sessionStorage.getItem('loginUser.staffCode') || "all");
  const [firstLoadStaff, setFirstLoadStaff] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(isFilterStr === "true" ? true : false);
  const [errorList, setErrorList] = useState<IAttendAchievementError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let isOtStr = sessionStorage.getItem('dataError.OT') || "true";
  const [isOT, setOT] = useState<boolean>(isOtStr === "true" ? true : false);
  let isHolidayWorkStr = sessionStorage.getItem('dataError.HolidayWork') || "true";
  const [isHolidayWork, setHolidayWork] = useState<boolean>(isHolidayWorkStr === "true" ? true : false);
  let isBreakStr = sessionStorage.getItem('dataError.Break') || "true";
  const [isBreak, setBreak] = useState<boolean>(isBreakStr === "true" ? true : false);
  let isWorkingTogetherStr = sessionStorage.getItem('dataError.WorkingTogether') || "true";
  const [isWorkingTogether, setWorkingTogether] = useState<boolean>(isWorkingTogetherStr === "true" ? true : false);
  let isUnder18Str = sessionStorage.getItem('dataError.Under18') || "true";
  const [isUnder18, setUnder18] = useState<boolean>(isUnder18Str === "true" ? true : false);
  let isForeignerStr = sessionStorage.getItem('dataError.Foreigner') || "true";
  const [isForeigner, setForeigner] = useState<boolean>(isForeignerStr === "true" ? true : false);
  let isIntervalStr = sessionStorage.getItem('dataError.Interval') || "true";
  const [isInterval, setInterval] = useState<boolean>(isIntervalStr === "true" ? true : false);

  const { detailRole, loadedRole } = useGetDetailRole(setIsLoading, functionCode.attendError);
  useEffect(() => {
    if(detailRole.accessRange === 0 && loadedRole){
      setStaffCode(sessionStorage.getItem('loginUser.staffCode') || "all")
    }
  }, [detailRole.accessRange, loadedRole])

  const [underFlag, setUnderFlag] = useState<any>(null);
  useEffect(() => {
    if(underFlag !== null){
      sessionStorage.setItem(`/sessionData${window.location.pathname}.underFlag`, String(underFlag));
    }
  }, [underFlag])
  useEffect(() => {
    let underFlagSession = sessionStorage.getItem(`/sessionData${window.location.pathname}.underFlag`);
    setUnderFlag(underFlagSession === "1" ? 1 : 0);
  }, [])

  const staffsForOrgCodeOptions = useStaffSelectBoxRange(targetDateFrom, targetDateTo, orgCode, true, functionCode.attendError, underFlag);

  // useMemo(() => {
  //   const params = {
  //     orgCode,
  //     targetDayFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
  //     targetDayTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
  //     attendStaff: 0,
  //     stateStatus: '1, 3, 4',
  //     employmentId: "",
  //     loginStaffCode: sessionStorage.getItem('loginUser.staffCode')
  //   };

  //   let staffListTmp;
  //   getListStaffSelectBox(params).then((response: any) => {
  //     staffListTmp = response.map((item: any) => ({
  //       value: String(item.staffCode),
  //       label: `${item.staffCode} ${item.staffName}`,
  //     }));
  //     staffListTmp.unshift({
  //       value: 'all',
  //       label: '-'
  //     });
  //     setStaffsForOrgCodeOptions(staffListTmp);
  //   });
  // },[orgCode, targetDateFrom, targetDateTo]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('attendError.directFromPopupAlert');
    }
  }, [])
  
  const fetchData = async () => {
    setIsLoading(true);
    targetDateFromStr = moment(targetDateFrom).format('YYYY-MM-DD');
    targetDateToStr = moment(targetDateTo).format('YYYY-MM-DD');
    sessionStorage.setItem('attendError.targetDateFrom', targetDateFromStr);
    sessionStorage.setItem('attendError.targetDateTo', targetDateToStr);

    const isGoBack = sessionStorage.getItem('isGoBack');
    if (isGoBack) {
      const attendErrorStaffCode = sessionStorage.getItem('attendError.staffCode');
      const loginUserStaffCode = sessionStorage.getItem('loginUser.staffCode');
      staffCode = attendErrorStaffCode || loginUserStaffCode || "";
    }

    setErrorList([]);
    const achievementErrors = await getAttendAchievementErrorV3(
      (staffCode === 'all' || staffCode === '') ? '' : staffCode,
      (orgCode === 'all' || orgCode === '') ? '' : orgCode,
      targetDateFromStr,
      targetDateToStr,
      isFilter ? 0 : 1,
      isOT,
      isHolidayWork,
      isBreak,
      isWorkingTogether,
      isUnder18,
      isForeigner,
      isInterval,
      underFlag
    );
    setErrorList(achievementErrors);
    setIsLoading(false);
  };

  useEffect(() => {
    setFirstLoadStaff(true);
    if (staffsForOrgCodeOptions.filter((item: any) => item.value === staffCode).length === 0 && firstLoadStaff) {
      setStaffCode('all');
    }
  }, [staffsForOrgCodeOptions])

  return {
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    isFilter,
    setIsFilter,
    errorList,
    setErrorList,
    isLoading,
    setIsLoading,
    fetchData,
    isOT,
    setOT,
    isHolidayWork,
    setHolidayWork,
    isBreak,
    setBreak,
    isWorkingTogether,
    setWorkingTogether,
    isUnder18,
    setUnder18,
    isForeigner,
    setForeigner,
    isInterval,
    setInterval,
    staffsForOrgCodeOptions,
    staffCode,
    setStaffCode,
    detailRole,
    underFlag,
    setUnderFlag
  };
};

export default useAchievementErrorState;
