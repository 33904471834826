/** @jsx jsx */

import React, {useState, createContext, Dispatch, SetStateAction} from "react";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from 'components/atoms/TabControl';
import ExecutiveImportCSV from "./ExecutiveImportCSV";
import ExecutiveHistoryImportCSV from "./ExecutiveHistoryImportCSV";
import BlockUI from 'components/molecules/BlockUi';
import FormTitle from "components/atoms/Form/FormTitle";
import { Row, Col } from 'react-grid-system';
import FormPadding from "components/atoms/Form/FormPadding";
import { css, jsx } from "@emotion/core";

type SelectType = "import" | "history";

const SelectType: { [key in SelectType]: SelectType } = {
  import: "import",
  history: "history",
};

type contextType = {
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export const LoadingContext = createContext<contextType>({} as contextType);

const ExecutiveFormImportLayoutPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectType, setSelectType] = React.useState<SelectType>(
    SelectType.import
  );

  return (
    <LoadingContext.Provider value={{setIsLoading}}> 
    <BlockUI blocking={isLoading}>
    <FormContents>
    <FormTitle bold={true} title="役職インポート" />
    <FormPadding customStyle={{ padding: '0 30px' }}>
      <Row>
        <Col md={4} xs={12}>
          <TabControl
            items={[
              {
                value: SelectType.import,
                display: "インポート",
              },
              {
                value: SelectType.history,
                display: "履歴参照",
              }
            ]}
            setValue={setSelectType as (arg: string) => void}
            currentValue={selectType}
            isFixed={false}
            customStyle={css({margin: '10px 30px 10px 0'})}
          />
        </Col>
      </Row>
    </FormPadding>
      {selectType === SelectType.import && <ExecutiveImportCSV/>}
      {selectType === SelectType.history && <ExecutiveHistoryImportCSV/>}
    </FormContents>
    </BlockUI>
    </LoadingContext.Provider>
  );
};

export default ExecutiveFormImportLayoutPage;
