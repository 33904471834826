/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import SidebarTemplate from "components/templates/SidebarTemplate";
import BlockUI from "components/molecules/BlockUi";
import FormContents from "components/atoms/Form/FormContents";
import Modal from "components/molecules/Modal";
import { monthlyShiftContext, useInitialState } from "./contextHook";
import TableMonthlyShift from "./TableMonthlyShift";
import Filters from "./Filters";
import ModalRegisterShift from "./Modals/ModalRegisterShift";
import moment from "moment";
import FormTitle from "components/atoms/Form/FormTitle";
import { css } from "@emotion/core";
import TableTotalValueMonthlyShift from "components/pages/master/shift/monthly/TableTotalValueMonthlyShift";
import Accordion from "components/molecules/Accordion";
import { grayScale } from "components/styles";

const MonthlyShift: React.FC = () => {
  const contextInitialState = useInitialState();
  const {
    detailRole,
    isLoading,
    useSales,
    openConfirmModal,
    setOpenConfirmModal,
    openDeleteModal,
    setOpenDeleteModal,
    filters: { stateFilter },
    monthlyCalendar: {
      monthlyShifts,
      deleteItem,
      selectedItem,
      shiftConfirm,
      submitShiftLockout,
      openDailyShiftConfirmModal,
      setDailyShiftConfirmModal,
      selectedDay,
      submitDayShiftLockout,
    },
    shiftPattern: { openShiftPatternModal },
  } = contextInitialState;

  const showLaborCost = detailRole.usabilityGeneralItem3 === 1 || detailRole.usabilityGeneralItem4 === 1;

  return (
    <SidebarTemplate pageTitle="シフト管理">
      <BlockUI blocking={isLoading}>
        <monthlyShiftContext.Provider value={contextInitialState}>
          {stateFilter.dateToStr && (
            <FormContents>
              <FormTitle
                title="月次勤怠"
                bold={true}
                customStyle={css`
                  margin: 16px 45px;
                `}
              />
              <Filters />

              {showLaborCost && (
                <Accordion
                  text="期間計"
                  backgroundColor="transparent"
                  color={grayScale.gray100}
                  callBack={() => {}}
                  defaultExpand
                  showBorder={false}
                >
                  <TableTotalValueMonthlyShift useSales={useSales} summaryInfo={monthlyShifts?.summaryInfo} />
                </Accordion>
              )}

              <TableMonthlyShift showLaborCost={showLaborCost} />
            </FormContents>
          )}

          {/* Confirm shift modal */}
          <Modal
            open={openConfirmModal}
            closeHandler={() => {
              setOpenConfirmModal(false);
            }}
            title="シフトの確定"
            note={
              shiftConfirm.isConfirmedAllDays
                ? "シフト確定を解除します。よろしいですか？"
                : "シフトを確定します。よろしいですか？"
            }
            submitText={shiftConfirm.isConfirmedAllDays ? "解除する" : "確定する"}
            onSubmit={submitShiftLockout}
            closeText="キャンセル"
            typeSubmit={
              (shiftConfirm.isConfirmedAllDays
                ? "シフト確定を解除します。よろしいですか？"
                : "シフトを確定します。よろしいですか？") === "シフト確定を解除します。よろしいですか？"
                ? "reject"
                : "confirm"
            }
          />

          {/* Confirm dailyShift modal */}
          <Modal
            open={openDailyShiftConfirmModal}
            closeHandler={() => {
              setDailyShiftConfirmModal(false);
            }}
            title="シフトの確定"
            note={`${moment(selectedDay).format("M月D日")}のシフトを確定します。よろしいですか？`}
            submitText="確定する"
            onSubmit={submitDayShiftLockout}
            closeText="キャンセル"
          />

          {/* Confirm delete modal */}
          <Modal
            open={openDeleteModal}
            closeHandler={() => {
              setOpenDeleteModal(false);
            }}
            title="シフトの削除"
            note="シフトを削除します。よろしいですか？"
            submitText="削除する"
            onSubmit={() => {
              deleteItem(selectedItem.id);
              setOpenDeleteModal(false);
            }}
            closeText="キャンセル"
            typeSubmit={"reject"}
          />

          {/* ShiftPattern */}
          {openShiftPatternModal && <ModalRegisterShift />}
        </monthlyShiftContext.Provider>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default MonthlyShift;
