import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate'; 
import StampWorkScheduleChangeApplication from 'components/organismos/master/attend/StampWorkScheduleChangeApplication';

const WorkScheduleChangeApplication: React.FC = () => (
  <SidebarTemplate pageTitle="打刻修正申請 承認・差戻">
    <StampWorkScheduleChangeApplication />
  </SidebarTemplate>
);

export default WorkScheduleChangeApplication;
