/** @jsx jsx */
import React, { ReactNode, ComponentProps } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { textFontSize, textColor, utilityColor } from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import RadioSelector from './RadioSelector';

const styles = {
  subLabel: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: '4px',
  }),
};

const RadioSelectForm: React.FC<{
  label?: string;
  subLabel?: string;
  note?: string|ReactNode;
  required?: boolean;
  readOnly?: boolean;
  noTitle?: boolean;
  additionStyle?:any;
  disabled?: boolean;
  showTooltip?: boolean;
  itemsTooltip?: any;
  customStyleLabel?: SerializedStyles
} & ComponentProps<typeof RadioSelector>> = ({
  label, subLabel, items, name, value, setValue, note, required, readOnly = false, noTitle = false, additionStyle, disabled = false, showTooltip, itemsTooltip, customStyleLabel
}) => (
  <div>
    {label
    && (
    <FormLabel
      label={label}
      subText={subLabel}
      required={required}
      customStyle={customStyleLabel}
    />
    )}
    <RadioSelector
      items={items}
      name={name}
      value={value}
      setValue={setValue}
      readOnly={readOnly}
      noTitle={noTitle}
      addon={additionStyle}
      disabled={disabled}
      showTooltip={showTooltip}
      itemsTooltip={itemsTooltip}
    />
    {note && <span css={styles.noteText}>{note}</span>}
  </div>
);

export default RadioSelectForm;
