import React, { SVGProps } from "react";

const Info: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 7.90552H13.5V9.90552H11.5V7.90552ZM11.5 11.9055H13.5V17.9055H11.5V11.9055ZM12.5 2.90552C6.98 2.90552 2.5 7.38552 2.5 12.9055C2.5 18.4255 6.98 22.9055 12.5 22.9055C18.02 22.9055 22.5 18.4255 22.5 12.9055C22.5 7.38552 18.02 2.90552 12.5 2.90552ZM12.5 20.9055C8.09 20.9055 4.5 17.3155 4.5 12.9055C4.5 8.49552 8.09 4.90552 12.5 4.90552C16.91 4.90552 20.5 8.49552 20.5 12.9055C20.5 17.3155 16.91 20.9055 12.5 20.9055Z"
      fill="#0075FF"
    />
  </svg>
);

export default Info;
