import { useState, useEffect, useCallback } from 'react';
import TimeZoneList from 'domain/master/labor/TimeZoneList';
import getList from 'api/timeZone';
import useToastNotification from 'hooks/useToastNotification';
import { useFormik } from 'formik';
// import { updateSortGetListTimeZone } from 'api/timeZone';
import { post } from 'api/termsMaster';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';


export const useTimeZone = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateConfirmModalOpen, setUpdateConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterTerm);

  const onSubmit = async (values: any) => {
    if (!updateConfirmModalOpen) {
      // 登録内容確認モーダルが表示されていなければ表示する
      setUpdateConfirmModalOpen(true);
      return;
    }
    
    let convertData = {};
    values.timeZoneItems.forEach((item: any, index: number) => {
      const ObjSubmit = {
        termOrder: index,
        termId: item.termId,
        termName: item.termName,
        targetType: item.targetType,
        startTime: item.startTime,
        endTime: item.endTime,
      };

      convertData = {
        ...convertData,
        [`terms[${index}]`]: {
          ...ObjSubmit,
        },
      };

      const loginUser = sessionStorage.getItem('loginUser.staffName') || '';
      convertData = {
        ...convertData,
        createUser: loginUser,
        updateUser: loginUser,
      };
    });

    setIsLoading(true);
    setUpdateConfirmModalOpen(false);
    try {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      const response = await post(companyCode, convertData);
      setIsLoading(false);
      if (!response.errors) {
        const timeZone = response.map((result: any, index: number) => {
          return {...result, ...{id: index}};
        });
        formik.setFieldValue('timeZoneItems', timeZone);
        successNotification('更新しました。');
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: TimeZoneList.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    setIsLoading(true);
    getList(companyCode).then((response: Array<any>) => {
      setIsLoading(false);
      if (isSubscribed) {
        const timeZone = response.map((result, index) => {
          return {...result, ...{id: index}};
        });
        formik.setFieldValue('timeZoneItems', timeZone);
      }
    });
    return () => { isSubscribed = false; };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeUpdateConfirmModal = useCallback(() => {
    setUpdateConfirmModalOpen(false);
  }, []);

  return { 
    formik,
    updateConfirmModalOpen,
    closeUpdateConfirmModal,
    successNotification,
    errorNotification,
    isLoading,
    setIsLoading,
    detailRole
  };
};

export default useTimeZone;
