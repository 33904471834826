import React, { SVGProps } from 'react';

const ICCard: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="250" height="280" viewBox="0 0 250 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8375 74.3436L19.1964 74.0448C12.3389 70.8488 12.9257 60.9151 20.1093 58.5903V58.5903" stroke="#22AEE5"/>
    <path d="M20.883 71.9136L20.3309 71.7131C15.0685 69.802 15.455 62.2354 20.883 60.9028V60.9028" stroke="#22AEE5"/>
    <path d="M21.4416 69.9605L21.1804 69.8905C17.6421 68.9415 17.8409 63.8546 21.4416 63.2082V63.2082" stroke="#22AEE5"/>
    <path d="M31.2091 58.5373L31.8503 58.8361C38.7077 62.032 38.1209 71.9657 30.9373 74.2905V74.2905" stroke="#22AEE5"/>
    <path d="M30.1639 60.967L30.716 61.1675C35.9783 63.0786 35.5919 70.6453 30.1639 71.9778V71.9778" stroke="#22AEE5"/>
    <path d="M29.6055 62.9201L29.8667 62.9901C33.4051 63.9392 33.2063 69.026 29.6055 69.6724V69.6724" stroke="#22AEE5"/>
    <ellipse cx="25.935" cy="66.8363" rx="2.05833" ry="1.9774" fill="#22AEE5"/>
    <rect x="51.5466" y="57.449" width="147.2" height="204.65" rx="4.5" fill="#22AEE5" stroke="#C4C4C4"/>
    <rect x="166.313" y="34.0117" width="16.4667" height="22.9379" fill="#22AEE5"/>
    <path d="M172.076 0H177.016V27.6836H172.076V0Z" fill="#22AEE5"/>
    <rect x="90.5667" y="93.3333" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="107.857" y="93.3333" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="125.146" y="93.3333" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="142.437" y="93.3333" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="90.5667" y="95.7061" width="14.2373" height="1.64667" transform="rotate(90 90.5667 95.7061)" fill="#FAFBFC"/>
    <rect x="90.5667" y="112.316" width="14.2373" height="1.64667" transform="rotate(90 90.5667 112.316)" fill="#FAFBFC"/>
    <rect x="90.5667" y="128.927" width="14.2373" height="1.64667" transform="rotate(90 90.5667 128.927)" fill="#FAFBFC"/>
    <rect x="90.5667" y="145.536" width="14.2373" height="1.64667" transform="rotate(90 90.5667 145.536)" fill="#FAFBFC"/>
    <rect x="90.5667" y="162.938" width="14.2373" height="1.64667" transform="rotate(90 90.5667 162.938)" fill="#FAFBFC"/>
    <rect x="90.5667" y="179.548" width="14.2373" height="1.64667" transform="rotate(90 90.5667 179.548)" fill="#FAFBFC"/>
    <rect x="90.5667" y="196.158" width="14.2373" height="1.64667" transform="rotate(90 90.5667 196.158)" fill="#FAFBFC"/>
    <rect x="90.5667" y="212.767" width="14.2373" height="1.64667" transform="rotate(90 90.5667 212.767)" fill="#FAFBFC"/>
    <rect x="90.5667" y="227.797" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="158.903" y="95.7061" width="14.2373" height="1.64667" transform="rotate(90 158.903 95.7061)" fill="#FAFBFC"/>
    <rect x="158.903" y="112.316" width="14.2373" height="1.64667" transform="rotate(90 158.903 112.316)" fill="#FAFBFC"/>
    <rect x="158.903" y="128.927" width="14.2373" height="1.64667" transform="rotate(90 158.903 128.927)" fill="#FAFBFC"/>
    <rect x="158.903" y="145.536" width="14.2373" height="1.64667" transform="rotate(90 158.903 145.536)" fill="#FAFBFC"/>
    <rect x="158.903" y="162.938" width="14.2373" height="1.64667" transform="rotate(90 158.903 162.938)" fill="#FAFBFC"/>
    <rect x="158.903" y="179.548" width="14.2373" height="1.64667" transform="rotate(90 158.903 179.548)" fill="#FAFBFC"/>
    <rect x="158.903" y="196.158" width="14.2373" height="1.64667" transform="rotate(90 158.903 196.158)" fill="#FAFBFC"/>
    <rect x="158.903" y="212.767" width="14.2373" height="1.64667" transform="rotate(90 158.903 212.767)" fill="#FAFBFC"/>
    <rect x="107.857" y="227.797" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="125.146" y="227.797" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <rect x="142.437" y="227.797" width="14.82" height="1.58192" fill="#FAFBFC"/>
    <path d="M180.374 27.6836L182.78 34.0113H166.313L168.515 27.6836H180.374Z" fill="#22AEE5"/>
    <path d="M180.374 27.6836L182.78 34.0113H166.313L168.515 27.6836H180.374Z" stroke="#22AEE5"/>
    <path d="M102.301 155.083C101.671 153.631 102.35 151.977 103.819 151.389C105.288 150.8 106.99 151.499 107.62 152.951L159.661 272.787C160.292 274.239 159.612 275.893 158.143 276.481C156.674 277.07 154.973 276.371 154.342 274.919L102.301 155.083Z" fill="#C4C4C4"/>
    <path d="M103.89 153.677C102.841 151.274 104.061 148.486 106.539 147.626L188.878 119.047C191.093 118.278 193.527 119.339 194.472 121.485L247.397 241.713C248.443 244.089 247.267 246.855 244.831 247.75L162.741 277.906C160.506 278.727 158.018 277.665 157.065 275.483L103.89 153.677Z" fill="#E7EBFC" stroke="#C4C4C4"/>
    <path d="M113.646 156.073C112.962 154.487 113.755 152.652 115.379 152.064L185.09 126.813C186.571 126.276 188.215 126.977 188.854 128.416L209.505 174.948C210.019 176.105 209.75 177.46 208.834 178.335L151.319 233.223C149.807 234.666 147.32 234.159 146.493 232.241L113.646 156.073Z" fill="#8CE77E" fillOpacity="0.8"/>
    <path d="M120.434 165.098L118.195 159.693L119.641 159.14L120.913 160.33L128.68 157.36L128.753 155.656L130.199 155.103L132.438 160.507L130.992 161.06L129.72 159.871L121.953 162.841L121.88 164.545L120.434 165.098ZM133.414 168.315C133.014 167.35 132.345 166.753 131.405 166.521C130.465 166.29 129.362 166.416 128.095 166.901C126.817 167.389 125.922 168.001 125.409 168.737C124.911 169.479 124.878 170.372 125.31 171.414C125.512 171.902 125.768 172.362 126.078 172.796C126.392 173.241 126.767 173.708 127.203 174.196L125.068 175.012C124.639 174.573 124.269 174.117 123.956 173.646C123.644 173.175 123.357 172.623 123.095 171.991C122.59 170.772 122.424 169.666 122.598 168.675C122.787 167.691 123.264 166.835 124.027 166.106C124.801 165.374 125.804 164.771 127.037 164.3C128.248 163.837 129.408 163.644 130.517 163.721C131.627 163.797 132.615 164.145 133.481 164.764C134.353 165.394 135.036 166.308 135.532 167.505C135.776 168.092 135.941 168.71 136.027 169.358C136.13 170.012 136.164 170.66 136.132 171.302L133.726 171.278C133.755 170.753 133.754 170.233 133.723 169.719C133.696 169.215 133.593 168.747 133.414 168.315Z" fill="#FAFBFC"/>
  </svg>
);

export default ICCard;
