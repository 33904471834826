import React, { SVGProps } from "react";

const Business: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_166_608)">
      <path
        d="M12 7.4538V3.4538H2V21.4538H22V7.4538H12ZM6 19.4538H4V17.4538H6V19.4538ZM6 15.4538H4V13.4538H6V15.4538ZM6 11.4538H4V9.4538H6V11.4538ZM6 7.4538H4V5.4538H6V7.4538ZM10 19.4538H8V17.4538H10V19.4538ZM10 15.4538H8V13.4538H10V15.4538ZM10 11.4538H8V9.4538H10V11.4538ZM10 7.4538H8V5.4538H10V7.4538ZM20 19.4538H12V17.4538H14V15.4538H12V13.4538H14V11.4538H12V9.4538H20V19.4538ZM18 11.4538H16V13.4538H18V11.4538ZM18 15.4538H16V17.4538H18V15.4538Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_166_608">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.453796)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Business;
