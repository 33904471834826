/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';

import Modal from 'components/molecules/Modal';
import DatePicker from 'components/molecules/DatePicker';
import BodyText from 'components/atoms/BodyText';

const ShiftConfirmModal: React.FC<{
  open: boolean;
  closehandler: () => void;
  shiftSubmit: () => void;
  dayFrom: Date;
  dayTo: Date;
  setDayTo: (arg: Date) => void;
}> = ({
  open, closehandler, shiftSubmit,
  dayFrom, dayTo, setDayTo,
}) => {
  const [openConfirmModal, setConfirmModalFlag] = React.useState(false);
  return (
    <React.Fragment>
      <Modal
        open={open}
        title="シフト確定"
        closeHandler={closehandler}
        submitText="確定"
        onSubmit={() => {
          setConfirmModalFlag(true);
        }}
        
        width="360px"
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BodyText>
            {moment(dayFrom).format('YYYY/MM/DD')}
            {' ~ '}
          </BodyText>
          <DatePicker
            date={dayTo}
            changeDate={setDayTo}
            // 月初の翌日
            minDate={moment(dayTo).startOf('month').add(1, 'day').toDate()}
            // 月末
            maxDate={moment(dayTo).endOf('month').toDate()}
          />
        </div>
      </Modal>
      <Modal
        open={openConfirmModal}
        title=""
        closeHandler={() => {
          setConfirmModalFlag(false);
        }}
        submitText="確定"
        onSubmit={() => {
          setConfirmModalFlag(false);
          shiftSubmit();
        }}
        
        width="360px"
      >
        <BodyText>
          指定の期間でシフト確定を行います。よろしいですか？
        </BodyText>
      </Modal>
    </React.Fragment>
  );
};

export default ShiftConfirmModal;
