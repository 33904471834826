/** @jsx jsx */
import React, { useState, useEffect } from 'react';

import FlexBox from 'components/atoms/FlexBox';
import SelectForm from 'components/molecules/SelectForm';
import { jsx, css } from '@emotion/core';
import Table from 'components/molecules/Table';
import { SubActionButton } from 'components/atoms/Button';
import { OptionType } from 'components/atoms/Select';
import { listByOrgCode } from 'api/approvalFlowList';

const character = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

const styles = {
  widthColumn: css({
    width: '120px'
  }, css`button{ white-space:nowrap;}`)
}

export const ACTION = {
  REGISTER: 0,
  UPDATE: 1,
  DELETE: 2,
  NORMAL: 3,
}

const ApplicationRouteRow: React.FC<{
  fields: any,
  index: number,
  orgTreeOptions: Array<OptionType>,
  executiveOptions: Array<OptionType>
  form: any,
  setTargetApplicationLength: any,
}> = ({
  fields,
  index,
  orgTreeOptions,
  executiveOptions,
  form: formik,
  setTargetApplicationLength,
}) => {
    const [countItem, setCountItem] = useState(0);
    const isEditItemChild = formik.values.approvalFlowListData[index];
    const [dataExecutiveOptions, setExecutiveOptions] = useState(executiveOptions);
    
    useEffect(() => {
      listByOrgCode(isEditItemChild.orgCode).then((executive) => {
        if (executive) {
          const executiveOption = executive.map((exe: any) => ({
            value: exe.executiveId,
            label: exe.executiveName,
          }));
          executiveOption.unshift({
            value: '',
            label: '-',
          }); 
          setExecutiveOptions(executiveOption);
        }
      })
    }, [isEditItemChild.orgCode]);
    
    const deleteRow = (idDelete: any) => {
      let approvalFlowListData = formik.values.approvalFlowListData;
      let labelActiveIndex = 0;
      if (approvalFlowListData.length > 0 && approvalFlowListData.filter((val: any) => val.status !== ACTION.DELETE)) {
        for (let i = 0; i <= approvalFlowListData.length; i += 1) {
          if (
            approvalFlowListData[i]?.status !== ACTION.DELETE
            && approvalFlowListData[i]?.approvalLevel === isEditItemChild.approvalLevel
          ) {
            labelActiveIndex = i;
            break;
          }
        }
      }

      formik.setFieldValue('approvalFlowListData[' + idDelete + '].isHiddenLabel', true);
      formik.setFieldValue('approvalFlowListData[' + labelActiveIndex + '].isHiddenLabel', false);

      // Update approvalLevel and Status
      if (
        approvalFlowListData
        && approvalFlowListData.filter((val: any) => val.approvalLevel === isEditItemChild.approvalLevel
          && val.status !== ACTION.DELETE).length === 0
      ) {
        approvalFlowListData.map((value: any, indexData: number) => {
          if (indexData > idDelete && isEditItemChild.approvalLevel !== value.approvalLevel && value.status !== ACTION.DELETE) {
            value.approvalLevel = value.approvalLevel - 1;
            value.status = ACTION.UPDATE;
          }
          return true;
        });
      }

      // Delete an item when the item doesn't exist in the database
      if (!isEditItemChild.exists) {
        approvalFlowListData = approvalFlowListData.filter((val: any, idxFilter: number) => idxFilter !== idDelete);
        formik.setFieldValue('approvalFlowListData', approvalFlowListData);
      }

      // Update length
      const countApprovalActive: Array<String> = [];
      approvalFlowListData.filter((val: any) => {
        if (countApprovalActive && !countApprovalActive.includes(val.approvalLevel) && val.status !== ACTION.DELETE) {
          countApprovalActive.push(val.approvalLevel);
        }
        return true;
      });

      setTargetApplicationLength(countApprovalActive.length);
    }

    const addItemChild = (idx: Number, approvalLevel: number) => {
      formik.values.approvalFlowListData.splice(Number(idx) + 1, 0, {
        privilegeId: '',
        orgCode: '',
        executiveId: '',
        executiveName: '',
        approvalLevel: approvalLevel,
        status: ACTION.REGISTER,
        isHiddenLabel: true
      });
      formik.setFieldValue('approvalFlowListData', formik.values.approvalFlowListData);
      setCountItem(Number(countItem) + 1);
    }

    return (
      <React.Fragment>
        {
          <tr key={index}>
            <Table.Cell>{isEditItemChild.isHiddenLabel ? '' : '第' + character[isEditItemChild.approvalLevel - 1] + '承認者'}  </Table.Cell>
            <Table.Cell>
              <SelectForm
                label=""
                name="targetApplication"
                value={isEditItemChild.orgCode || ''}
                setValue={(orgCode: string) => {
                  formik.setFieldValue('approvalFlowListData[' + index + '].orgCode', orgCode);
                  if (formik.values.approvalFlowListData[index].exists) {
                    formik.setFieldValue('approvalFlowListData[' + index + '].status', ACTION.UPDATE);
                  }
                  formik.setFieldValue('approvalFlowListData[' + index + '].executiveId', '');
                }}
                options={orgTreeOptions}
                isHiddenLabel={true}
              />
            </Table.Cell>
            <Table.Cell>
              <SelectForm
                label=""
                name="targetApplication"
                value={isEditItemChild.executiveId || ''}
                setValue={(executiveId: string) => {
                  formik.setFieldValue('approvalFlowListData[' + index + '].executiveId', executiveId);
                  if (formik.values.approvalFlowListData[index].exists) {
                    formik.setFieldValue('approvalFlowListData[' + index + '].status', ACTION.UPDATE);
                  }
                  const executiveName = executiveOptions.filter((val: any) => val.value === executiveId)[0]?.label;
                  if (executiveName) {
                    formik.setFieldValue('approvalFlowListData[' + index + '].executiveName', executiveName);
                  }
                }}
                options={dataExecutiveOptions}
                isHiddenLabel={true}
              />
            </Table.Cell>
            <Table.Cell customStyle={styles.widthColumn}>
              <FlexBox justifyContent="center">
                <SubActionButton
                  action={0}
                  text="追加"
                  width="50px"
                  onClick={() =>
                    addItemChild(index, fields.approvalLevel)
                  }
                  disabled={(!isEditItemChild.orgCode || !isEditItemChild.executiveId) ? true : false}
                />
                <div style={{ marginLeft: '10px' }}>
                  <SubActionButton
                    action={1}
                    text="削除"
                    width="50px"
                    onClick={() => {
                      formik.setFieldValue('approvalFlowListData[' + index + '].status', ACTION.DELETE);
                      deleteRow(index);
                    }}
                  />
                </div>
              </FlexBox>
            </Table.Cell>
          </tr>
        }
      </React.Fragment>
    );
  };

export default ApplicationRouteRow;
