import { AxiosResponse } from "axios";
import moment from "moment";
import { stringify } from "querystring";
// import OrganizationDomain from 'domain/organization';
import ApiClient from "./ApiClient";
import { OrganizationSpecialSalary, SpecialSalaryItem } from "domain/master/labor/organizationSpecialSalary";

export interface Organization {
  id: {
    companyCode?: string;
    orgCode: string;
    applyStartDate: string;
  };
  applyStartMonth?: Date;
  applyEndMonth?: Date;
  orgName: string;
  orgNameKana: string;
  category: number;
  managerSetting: any;
  closeFlag: boolean;
  areaId: string;
  prefectureCode: string;
  parentOrgCode: string;
  employmentId: string;
  workingDaysId: string;
  businessCategoryId?: string;
  posCode: string;
  numberOfSeat: number;
  floorSpace: number;
  dispStartHour: number;
  dispHour: number;
  managerList?: Array<Manager>;
  seniorManagerList?: Array<Manager>;
  orgSpecialList?: SpecialSalaryItem[] | null;
  editManagerList?: Array<EditManager>;
  editSeniorManagerList?: Array<EditManager>;
  editSpecial?: SpecialSalaryItem;
}

export interface OrganizationBasic {
  id: {
    companyCode?: string;
    orgCode: string;
    applyStartDate: string;
  };
  orgName: string;
  orgNameKana: string;
  category: number;
  closeFlag: boolean;
  areaId?: string;
  prefectureCode?: string;
  parentOrgCode?: string;
  employmentId?: string;
  workingDaysId?: string;
  businessCategoryId?: string;
  posCode?: string;
  numberOfSeat?: number;
  floorSpace?: number;
  dispStartHour?: number;
  dispHour?: number;
  createUser: string;
  updateUser: string;
}

export interface OrganizationManagerBasic {
  id: {
    companyCode?: string;
    orgCode: string;
    applyStartDate: string;
  };
  orgName: string;
  orgNameKana: string;
  category: number;
  closeFlag: boolean;
  areaId?: string;
  prefectureCode?: string;
  employmentId?: string;
  workingDaysId?: string;
  businessCategoryId?: string;
  posCode?: string;
  numberOfSeat?: number;
  floorSpace?: number;
  dispStartHour?: number;
  dispHour?: number;
  createUser: string;
  updateUser: string;
  editManagerList?: Array<EditManager>;
  editSeniorManagerList?: Array<EditManager>;
}

export interface OrganizationSalaryBasic {
  id: {
    companyCode?: string;
    orgCode: string;
    applyStartDate: string;
  };
  editSpecialList: any[];
  orgName: string;
  orgNameKana: string;
  category: number;
  closeFlag: boolean;
  areaId?: string;
  prefectureCode?: string;
  employmentId?: string;
  workingDaysId?: string;
  businessCategoryId?: string;
  posCode?: string;
  numberOfSeat: number | null;
  floorSpace: number | null;
  createUser: string;
  updateUser: string;
}

export type Manager = {
  orgCode: string;
  managerId: number;
  staffCode: string;
  staffName?: string;
  managerType: number;
  startDate: string;
  endDate?: string;
  hireDate?: string;
  retirementDate?: string;
  createUser?: string;
};

/**
 * マネージャー情報更新時にPOSTするデータ形式
 */
export type EditManager = {
  orgCode: string;
  staffCode: string;
  managerType: string;
  startDateStr: string;
  endDateStr?: string;
  hireDateStr?: string;
  retirementDateStr?: string;
};

export type CsvImportHistory = {
  // createDate: string
  // updateDate: string
  historyId: string;
  formatType: number;
  importFileName: string;
  result: number;
  errDetail: string;
  errDetailDisp: string;
};

export type Error = {
  error: string;
  defaultMessage: string;
};

export const getList = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  unsetTargetDate?: Date,
): Promise<Array<Organization>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format("YYYY-MM-DD"),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format("YYYY-MM-DD") : undefined,
  };
  const response = await ApiClient.get(`/v1/organizations/list/${companyCode}`, params, undefined);

  return response.data;
};

export const getListV2 = async (orgStatus: number, targetDate: Date): Promise<Array<Organization>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgStatus,
    targetDate: moment(targetDate).format("YYYY-MM-DD"),
    loginStaffCode,
  };
  const response = await ApiClient.get(`/v2/organizations/list/${companyCode}`, params, undefined);

  return response.data;
};

export const postOrganization = async (
  organization: OrganizationBasic,
  isEditMode: boolean,
): Promise<Array<Organization>> => {
  const query = {
    inputType: isEditMode ? 1 : 0,
    tabIndex: 0,
  };
  const response = await postBasic(organization, query);
  return response.data;
};

export const postManagers = async (organization: OrganizationManagerBasic) => {
  const query = {
    inputType: 1,
    tabIndex: 1,
  };
  const response = await postManagerBasic(organization, query);
  return response.data;
};

export const postSpecialSalary = async (organization: OrganizationSalaryBasic) => {
  const query = {
    inputType: 1,
    tabIndex: 2,
  };
  const response = await postSalaryBasic(organization, query);
  return response.data;
};

export const deleteSpecialSalary = async (orgCode: string) => {
  const response = await deleteSalaryBasic(orgCode);
  return response.data;
};

// const post = async (organization: Organization, query: {[key: string]: string|number}) => {
//   const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
//   const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
//   return response;
// };

const postBasic = async (organization: OrganizationBasic, query: { [key: string]: string | number }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
  return response;
};

const postManagerBasic = async (organization: OrganizationManagerBasic, query: { [key: string]: string | number }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
  return response;
};

const postSalaryBasic = async (organization: OrganizationSalaryBasic, query: { [key: string]: string | number }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.post(`/v1/organizations/${companyCode}`, query, organization);
  return response;
};

const deleteSalaryBasic = async (orgCode: string) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const response = await ApiClient.delete(`/v2/organizations/specialSalary/delete/${companyCode}`, {
    loginStaffCode,
    orgCode,
  });
  return response;
};

/**
 * CSVファイルインポート
 */
export const postCsvImport = async (uploadFile: File, formatType: string): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffName = sessionStorage.getItem("loginUser.staffName") || "";
  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE === "true" || false;
  const query = {
    formatType,
    createUser: staffName,
    updateUser: staffName,
    isAuthorizedFromPeople,
  };
  const response = await ApiClient.postFile(
    `/v1/organizations/csv/import/${companyCode}`,
    query,
    "uploadFile",
    uploadFile,
  );
  return response;
};

export const downloadOrganizationCsv = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  unsetTargetDate?: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format("YYYY-MM-DD"),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format("YYYY-MM-DD") : undefined,
  };
  await ApiClient.downloadCsv(`/v1/organizations/csv/${companyCode}`, params, getDownloadCsvFileName("組織情報_"));
};

export const downloadOrganizationCsvV1 = async (orgStatus: number, targetDate: Date): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgStatus,
    targetDate: moment(targetDate).format("YYYY-MM-DD"),
    loginStaffCode,
  };
  await ApiClient.downloadCsv(`/v2/organizations/csv/${companyCode}`, params, getDownloadCsvFileName("組織情報_"));
};

export const downloadManagerCsv = async (
  noManager: boolean,
  orgStatus: number,
  targetDate: Date,
  unsetTargetDate?: Date,
): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    noManager,
    orgStatus,
    targetDate: moment(targetDate).format("YYYY-MM-DD"),
    unsetTargetDate: unsetTargetDate ? moment(unsetTargetDate).format("YYYY-MM-DD") : undefined,
  };
  await ApiClient.downloadCsv(
    `/v1/organizations/manager/csv/${companyCode}`,
    params,
    getDownloadCsvFileName("組織マネージャー権限_"),
  );
};

export const getImportHistory = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/organizations/csv/import/history/${companyCode}`, {});
  return response.data;
};

export const downloadSpecialSalaryCsv = async (orgStatus: number, targetDate: Date): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const params = {
    orgStatus,
    targetDate: moment(targetDate).format("YYYY-MM-DD"),
    loginStaffCode,
  };
  await ApiClient.downloadCsv(
    `/v2/organizations/specialSalary/csv/${companyCode}`,
    params,
    getDownloadCsvFileName("特賃日割当情報_"),
  );
};

export const getHistory = async (orgCode: string): Promise<Array<Organization>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/organizations/history/${companyCode}`, {}, { orgCode });
  return response.data;
};

export const getSpecialSalary = async (orgCode: string): Promise<OrganizationSpecialSalary> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v2/organizations/specialSalary/${companyCode}`, {}, { orgCode });
  return response.data;
};

export const getTrees = async (dayFrom?: string, dayTo?: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  // const siteType = 'HQ';
  const response = await ApiClient.get(
    `/v2/organizations/trees/${companyCode}?orgCode=${orgCode}&staffCode=${staffCode}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}`,
    {},
  );
  return response.data;
};
export const getTreesOrgCode = async (
  targetDayFrom?: string,
  targetDayTo?: string,
  onlyManager?: boolean,
  siteTypeString?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";

  const query = {
    staffCode,
    orgCode,
    targetDayFrom,
    targetDayTo,
    onlyManager,
  };

  let response;
  response = await ApiClient.get(`/v2/organizations/trees/${companyCode}?`, query);
  return response.data;
};

export const getTreeCurrentByStaff = async (
  staffCode?: string,
  targetDayFrom?: string,
  targetDayTo?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    staffCode,
    targetDayFrom,
    targetDayTo,
  };

  let response;
  response = await ApiClient.get(`/v1/organizations/current/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgCodeNoRole = async (
  targetDayFrom?: string,
  targetDayTo?: string,
  onlyManager?: boolean,
  siteTypeString?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  // const siteType = siteTypeString || 'HQ';
  const query = {
    targetDayFrom,
    targetDayTo,
    onlyManager,
  };
  const response = await ApiClient.get(`/v1/organizations/trees/all/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgCodeByFunctionCode = async (
  addAllItem: boolean,
  functionCode: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const query = {
    staffCode,
    addAllItem,
    functionCode,
  };
  const response = await ApiClient.get(`/v3/organizations/select/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgCodeV1 = async (
  targetDayFrom?: string,
  targetDayTo?: string,
  onlyManager?: boolean,
  siteTypeString?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  // const siteType = siteTypeString || 'HQ';
  const query = {
    // siteType,
    staffCode,
    orgCode,
    targetDayFrom,
    targetDayTo,
    onlyManager,
  };
  const response = await ApiClient.get(`/v3/organizations/trees/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgSelect = async (addAllItem: boolean, functionCode: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const query = {
    staffCode,
    addAllItem,
    functionCode,
  };
  const response = await ApiClient.get(`/v3/organizations/select/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgSelectRange = async (
  addAllItem: boolean,
  functionCode: string,
  targetDayFrom?: string,
  targetDayTo?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  const query = {
    targetDayFrom,
    targetDayTo,
    staffCode,
    addAllItem,
    functionCode,
  };
  const response = await ApiClient.get(`/v3/organizations/select/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgAllSelectRange = async (
  addAllItem: boolean,
  targetDayFrom?: string,
  targetDayTo?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    targetDayFrom,
    targetDayTo,
    addAllItem,
  };
  const response = await ApiClient.get(`/v3/organizations/all/select/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgAllTableRange = async (
  addAllItem: boolean,
  targetDayFrom?: string,
  targetDayTo?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  const query = {
    targetDayFrom,
    targetDayTo,
    addAllItem,
    dispTable: 1,
  };
  const response = await ApiClient.get(`/v3/organizations/all/select/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgCSVCode = async (
  targetDayFrom: string,
  targetDayTo: string,
  addAllItem: boolean,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  const param = {
    // siteType,
    staffCode,
    orgCode,
    targetDayFrom,
    targetDayTo,
    addAllItem,
  };
  const response = await ApiClient.get(`/v2/organizations/trees/${companyCode}`, param);
  return response.data;
};

export const getTreesAllOrgCode = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  // const siteType = 'HQ';
  const query = {
    // siteType,
    staffCode,
    orgCode,
    allOrganize: true,
  };
  const response = await ApiClient.get(`/v2/organizations/trees/${companyCode}?`, query);
  return response.data;
};

export const getTreesByCategory = async (category?: number): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  // const siteType = 'HQ';

  const response = await ApiClient.get(
    `/v2/organizations/trees/${companyCode}?staffCode=${staffCode}&orgCode=${orgCode}&category=${category}`,
    {},
  );
  return response.data;
};

export const getTreesByCategoryAll = async (
  dayFrom?: string,
  dayTo?: string,
  category?: number,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  // const siteType = 'HQ';

  const response = await ApiClient.get(
    `/v2/organizations/trees/${companyCode}?staffCode=${staffCode}&orgCode=${orgCode}&targetDayFrom=${dayFrom}&targetDayTo=${dayTo}&category=${category}`,
    {},
  );
  return response.data;
};

export const getSelManager = async (orgCode: string): Promise<Array<Manager>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const query = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/organizations/selManager/${companyCode}`, {}, query);
  return response.data;
};

export const getOrgManged = async (): Promise<Array<any>> => {
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const targetDate = moment(new Date()).format("YYYY-MM-DD");
  const query = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/organizations/managed/${companyCode}`, {}, query);
  return response.data;
};

export const getSelOrganize = async (): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/organizations/selOrganize/${companyCode}?orgCodes=`, {});

  return response.data;
};

export const getSelectOrganizations = async (params: {
  targetDayFrom: string;
  targetDayTo: string;
  addAllItem: boolean;
}): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v3/organizations/all/select/${companyCode}`, params);

  return response.data;
};

export const getParentOrgCodeOptions = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v3/organizations/all/select/${companyCode}`, {}, params);

  return response.data;
};

export const deleteOrganization = async (orgCode: string): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  await ApiClient.delete(`/v1/organizations/delete/${companyCode}`, { orgCode, tabIndex: 0 });
};

export const deleteOrganizationSpecialSalary = async (orgCode: string): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  await ApiClient.delete(`/v1/organizations/delete/${companyCode}`, { orgCode, tabIndex: 2 });
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format("YYYYMMDDHHmmss")}.csv`;

export const getOrganizationInfo = async (orgCode: string, targetDate: string): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/organizations/${companyCode}`, { orgCode, targetDate });

  return response.data;
};

export const getTreesOrgSelectRangeAndAgreementsId = async (
  addAllItem: boolean,
  functionCode: string,
  targetDayFrom?: string,
  targetDayTo?: string,
  agreementsId?: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  const query = {
    targetDayFrom,
    targetDayTo,
    staffCode,
    addAllItem,
    functionCode,
    agreementsId,
  };
  const response = await ApiClient.get(`/v3/organizations/select/agreements/${companyCode}?`, query);
  return response.data;
};

export const getTreesOrgCodeForStaffMst = async (
  addAllItem: boolean,
  stateStatus: Array<number>,
  functionCode: string,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const query = {
    staffCode,
    addAllItem,
    stateStatus,
    functionCode,
  };
  const queryStringParams = stringify(query);
  const response = await ApiClient.get(`/v3/organizations/select/staff/${companyCode}`, {}, queryStringParams);
  return response.data;
};

export const getAllTreesCurrentAndFuture = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v3/organizations/all/select/currentAndFuture/${companyCode}`, {}, params);

  return response.data;
};

export default {};
