import { useMemo } from 'react';

import { OptionType } from 'components/atoms/Select';

/**
 * 勤務日種別のオプションリスト
 */
export const useAttendTypeOptions = (distinctionHoliday: boolean) => {
  const attendTypeOptions = useMemo(() => ([
    {
      value: '0',
      label: '通常',
    },
    {
      value: '1',
      label: distinctionHoliday ? '公休（法定）' : '公休',
    },
    distinctionHoliday ? {
      value: '2',
      label: '公休（所定）',
    } : undefined,
  ].filter((option) => !!option)) as Array<OptionType>, [distinctionHoliday]);
  return attendTypeOptions;
};

export default {};
