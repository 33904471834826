/** @jsx jsx */
/* eslint-disable max-len */
import React, { useMemo, useEffect } from "react";
import SelectForm from "components/molecules/SelectForm";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";
import TimeRecalculationDomain from "domain/master/atendenceManagement/timeRecalculation";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import { textColor } from "components/styles";
import { useEmploymentTypeOptions, useStaffAccessableOptionsV2 } from "hooks/useEmploymentOptions";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { css, jsx } from "@emotion/core";
import Button from "components/atoms/Button";
import ToastModal from "components/organismos/ToastModal";
import FormField from "components/atoms/Form/FormField";
import ConfirmModal from "components/organismos/ConfirmModal";
import FlexBox from "components/atoms/FlexBox";
import FormLabel from "components/atoms/Form/FormLabel";
import { OptionType } from "components/atoms/Select";

import BlockUI from "components/molecules/BlockUi";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import useTimeRecalculation, { useUpdateTimeRecalcution } from "./hooks";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import CalendarForm from "components/molecules/CalendarForm";
import Icon from "components/atoms/Icon";
import BodyText from "components/atoms/BodyText";
import FormTitle from "components/atoms/Form/FormTitle";
import FormContents from "components/atoms/Form/FormContents";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import { functionCode } from "const";
import moment from "moment";
import Checkbox from "components/molecules/Checkbox";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
    marginLeft: "30px",
  }),
  label1: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
    paddingLeft: "20px",
  }),
  digestionDays: css({
    width: "120px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
  }),
  formContent: {
    backgroundColor: "#fff",
    border: "1px solid #dcdcdc",
  },
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  formFilter: css({
    padding: "30px",
  }),
  title: css({
    marginTop: "0px",
    color: "#666666",
  }),
  formContentFilter: css({
    maxWidth: "100%",
    padding: "10px 35px",
  }),
};

const TimeRecalculationForm: React.FC = () => {
  const {
    timeRecalculation,
    setTimeRecalculation,
    isFinished,
    isLoading,
    setLoading,
    isDetailRoleLoading,
    setIsFinished,
    isRecalculateState,
    setIsRecalculateState,
    checkRecalculateFinished,
    handleReload,
    arrNecessaryShiftCalc,
    detailRole,
    underFlag,
    setUnderFlag,
  } = useTimeRecalculation();
  const {
    formik,
    toastModalOpen,
    toastMessage,
    closeToastModalOpen,
    setAction,
    confirmModalContent,
    confirmModalOpen,
    closeConfirmModal,
    submitConfirmModal,
    ActionType,
    checkTargetRange,
  } = useUpdateTimeRecalcution(
    setTimeRecalculation,
    timeRecalculation,
    isFinished,
    setIsFinished,
    isRecalculateState,
    setIsRecalculateState,
    checkRecalculateFinished,
    isLoading,
    setLoading,
    underFlag,
  );

  let orgTreesOptions: Array<OptionType> = [];
  let employmentOptions: Array<OptionType> = [];
  let staffOptions: Array<OptionType> = [];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  orgTreesOptions = useOrgTreesOptionsShopV1(
    true,
    functionCode.timeRecalculation,
    moment(formik.values.targetDateFrom).format("YYYY-MM-DD"),
    moment(formik.values.targetDateTo).format("YYYY-MM-DD"),
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  employmentOptions = useEmploymentTypeOptions(formik.values.targetDateFrom, formik.values.targetDateTo);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  staffOptions = useStaffAccessableOptionsV2(
    formik.values.targetDateFrom,
    formik.values.targetDateTo,
    formik.values.orgCode || "all",
    formik.values.employmentId,
    true,
    functionCode.timeRecalculation,
    underFlag,
  );

  // Set default selected org
  useMemo(() => {
    formik.setFieldValue("orgCode", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Array<Column<TimeRecalculationDomain>> = useMemo(
    () => [
      {
        Header: "対象期間",
        accessor: "targetEndDateStr",
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "雇用形態",
        accessor: "employmentName",
      },
      {
        Header: "スタッフコード",
        accessor: "staffCode",
      },
      {
        Header: "スタッフ",
        accessor: "staffName",
      },
      {
        Header: "シフト件数",
        accessor: "targetShiftCountStr",
      },
      {
        Header: "実績件数",
        accessor: "targetAchievementCount",
      },
      {
        Header: "状態",
        accessor: "stateFlgString",
      },
    ],
    [],
  );

  const handleCalcTarget = () => {
    setAction(ActionType.handleCalcTarget);
    // setIsFinished(!isFinished);
    formik.handleSubmit();
  };

  const handleClickCalc = () => {
    // setIsFinished(!isFinished);
    setAction(ActionType.handleClickCalc);
    formik.handleSubmit();
  };

  return (
    <div>
      <BlockUI blocking={isLoading || isDetailRoleLoading}>
        <FormContents customStyle={styles.formContent}>
          <FormTitle title="勤怠再計算" bold={true} />
          <FormContentFilter customStyles={styles.formContentFilter}>
            <form onSubmit={formik.handleSubmit}>
              {/* <BlockUI blocking={!isFinished}> */}
              <FormField displayBlock={true}>
                <FlexBox>
                  <div css={styles.label1}>
                    <FormLabel label="対象期間" />
                  </div>
                  <FlexBoxItem>
                    <FlexBox>
                      <CalendarMonth
                        date={formik.values.targetDateFrom}
                        setDate={(date: Date) => {
                          formik.setFieldValue("targetDateFrom", date || new Date());
                          checkTargetRange(date || new Date(), formik.values.targetDateTo);
                        }}
                      />
                      <span style={{ margin: "10px" }}>～</span>
                      <CalendarMonth
                        date={formik.values.targetDateTo}
                        setDate={(date: Date) => {
                          formik.setFieldValue("targetDateTo", date || new Date());
                          checkTargetRange(formik.values.targetDateFrom, date || new Date());
                        }}
                      />
                    </FlexBox>
                    {/* <CalendarRangeV2 
                      startDate={formik.values.targetDateFrom}
                      onChangeStart={(date: Date) => {
                        formik.setFieldValue('targetDateFrom', date || new Date());
                        checkTargetRange(date || new Date(), formik.values.targetDateTo);
                      }}
                      endDate={formik.values.targetDateTo}
                      onChangeEnd={(date) => {
                        if(date){
                          formik.setFieldValue('targetDateTo', date || new Date());
                          checkTargetRange(formik.values.targetDateFrom, date || new Date());
                        }
                      }}
                    /> */}
                  </FlexBoxItem>
                  {/* <div css={styles.digestionDays}>
                    <CalendarForm label="" date={formik.values.targetDateFrom} dateFormat='YYYY年MM月'
                      changeDate={(date: Date) => {
                        formik.setFieldValue('targetDateFrom', date || new Date());
                        checkTargetRange(date || new Date(), formik.values.targetDateTo);
                      }} />
                  </div>
                  <div css={styles.betweenDays}>
                    ～
                </div>
                  <div css={styles.digestionDays}>
                    <CalendarForm label="" date={formik.values.targetDateTo} dateFormat='YYYY年MM月'
                      changeDate={(date: Date) => {
                        formik.setFieldValue('targetDateTo', date || new Date());
                        checkTargetRange(formik.values.targetDateFrom, date || new Date());
                      }} />
                  </div> */}
                </FlexBox>
              </FormField>

              <FormField displayBlock={true}>
                <FlexBox>
                  <SelectForm
                    label="組織名"
                    name="orgCode"
                    width="900px"
                    widthLabel="100px"
                    marginRight="30px"
                    value={String(formik.values.orgCode)}
                    setValue={(v) => {
                      sessionStorage.setItem("selectedOrgCode", v);
                      formik.setFieldValue("orgCode", v);
                      formik.setFieldValue("staffCode", "all");
                    }}
                    readOnly={!isFinished}
                    options={orgTreesOptions}
                  />
                  <Checkbox
                    id={"underFlag"}
                    name={"underFlag"}
                    label={"配下指定"}
                    value={`underFlag`}
                    checked={Boolean(underFlag)}
                    onChange={() => {
                      setUnderFlag(underFlag === 1 ? 0 : 1);
                    }}
                  />
                </FlexBox>
              </FormField>

              <FormField displayBlock={true}>
                <FlexBox>
                  <SelectForm
                    label="雇用形態"
                    width="900px"
                    widthLabel="100px"
                    marginRight="30px"
                    name="employmentId"
                    value={formik.values.employmentId}
                    setValue={(v) => {
                      // sessionStorage.setItem('setEmploymentId', v);
                      // setEmploymentId(v);
                      formik.setFieldValue("employmentId", v);
                    }}
                    placeholder="ホール"
                    options={employmentOptions}
                    readOnly={!isFinished}
                  />
                </FlexBox>
              </FormField>

              <FormField displayBlock={true}>
                <FlexBox>
                  <FlexBoxItem>
                    <SelectForm
                      label="スタッフ"
                      name="staffCode"
                      value={formik.values.staffCode || ""}
                      setValue={(v) => {
                        formik.setFieldValue("staffCode", v);
                      }}
                      placeholder="スタッフを選択してください"
                      options={staffOptions}
                      readOnly={!isFinished}
                      width="620px"
                    />
                  </FlexBoxItem>
                  <FlexBoxItem width="calc(100% - 350px)">
                    <FormField displayBlock={true}>
                      <FlexBox>
                        <div css={styles.label}>
                          <BodyText>シフトの再計算</BodyText>
                        </div>
                        <div css={styles.select}>
                          <RadioSelectForm
                            items={arrNecessaryShiftCalc}
                            name="necessaryShiftCalc"
                            value={String(formik.values.necessaryShiftCalc)}
                            setValue={(e) => {
                              formik.setFieldValue("necessaryShiftCalc", e.target.value);
                            }}
                            readOnly={!isFinished}
                            noTitle={true}
                          />
                        </div>
                      </FlexBox>
                    </FormField>
                  </FlexBoxItem>
                </FlexBox>
              </FormField>

              <FormField
                displayBlock={true}
                customStyle={css(
                  {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  css`
                    svg:hover {
                      cursor: auto;
                    }
                  `,
                )}
              >
                <Icon type="warningYellow" color={textColor.main} />
                <BodyText
                  customStyle={css({
                    paddingLeft: "5px",
                    position: "relative",
                    top: "1px",
                  })}
                >
                  再計算を実行すると本部確定が解除されます。本部確定が必要な場合は、再度、本部確定を実行してください。
                </BodyText>
              </FormField>
              <FormField>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <FormSubmitArea>
                    {isFinished && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div style={{ marginRight: "12px" }}>
                          <Button text="再計算対象確認" onClick={handleCalcTarget} />
                        </div>
                        <div>
                          <Button text="再計算" onClick={handleClickCalc} />
                        </div>
                      </div>
                    )}

                    {!isFinished && <Button text="最新表示" onClick={handleReload} />}
                  </FormSubmitArea>
                </div>
              </FormField>
              {/* </BlockUI> */}
            </form>
          </FormContentFilter>
          <div
            css={css`
              table th {
                white-space: nowrap;
              }
              table td {
                white-space: pre-wrap;
                text-align: center;
              }
              table th:nth-child(5) {
                width: 150px;
              }
            `}
          >
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={timeRecalculation}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                })}
                globalSearchStyle={styles.globalSearch}
              />
            </div>
          </div>
        </FormContents>
      </BlockUI>
      <ToastModal open={toastModalOpen} closeHandler={closeToastModalOpen} title={toastMessage} />
      <ConfirmModal
        title="再計算の実行"
        content={confirmModalContent}
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={submitConfirmModal}
        submitText="実行する"
        items={[]}
      />
    </div>
  );
};

export default TimeRecalculationForm;
