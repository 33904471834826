/** @jsx jsx */
import React, { useEffect } from "react";
import { jsx, css } from "@emotion/core";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FlexBox from "components/atoms/FlexBox";
import PrimaryButton from "components/atoms/Button";
import { useStaffSetting } from "components/organismos/Appbar/hooks";
import { getAuthorizedFromDelious } from "utility";

const style = {
  wrapper: css({
    width: "100%",
    minHeight: "100vh",
    margin: "0px auto",
    backgroundImage: "url(/login-back-e6ac60b256d2f031c73e8613933e2e8e3e3c8988062d2c05ec8d76bfe535a8ce.svg)",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "cover",
    overflowX: "hidden",
    minWidth: "320px",
    height: "100%",
  }),
  center: css({
    top: "50%",
    minWidth: "350px",
    width: "100%",
    position: "absolute",
    left: "0%",
    height: "20rem",
    marginTop: "-16rem",
  }),
  textCenter1: css({
    textAlign: "center",
    color: "#007BC3",
    fontSize: "40px",
    marginBottom: "0px",
    marginTop: "10px",
    lineHeight: "50px",
    fontWeight: "bold",
    fontFamily:
      '"Noto Sans Japanese", 游ゴシック体, "Yu Gothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, sans-serif',
  }),
  textCenter2: css({
    textAlign: "center",
    color: "#8c8c8c",
    fontSize: "15px",
    lineHeight: "24px",
    fontFamily:
      '"Noto Sans Japanese", 游ゴシック体, "Yu Gothic", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, sans-serif;',
  }),
  paddingTop3: css({
    paddingTop: "3rem",
  }),
  logo: css({
    paddingTop: "12px",
  }),
};

const NotFound404: React.FC = () => {
  const { logoutAction } = useStaffSetting();
  const companyCode =
    localStorage.getItem("loginUser.companyCode") || sessionStorage.getItem("loginUser.companyCode") || "";
  useEffect(() => {
    document.body.style.overflowY = "scroll";
    return () => {
      document.body.style.overflowY = "null";
    };
  }, []);
  return (
    <div css={style.wrapper}>
      <FlexBox customStyle={css(style.center)} alignItems="center" direction="column" justifyContent="center">
        <FlexBoxItem customStyle={style.logo}>
          <img src="icon-cydas.svg" alt="404 Logo" />
        </FlexBoxItem>
        <FlexBoxItem customStyle={css(style.paddingTop3)}>
          <p css={style.textCenter1}>ご指定のページが見つかりませんでした。</p>
        </FlexBoxItem>
        <FlexBoxItem>
          <p css={style.textCenter2}>
            申し訳ございません。このページは、現在アクセスできません。
            <br /> ログイン画面のメニューより目的ページをお探してください。
          </p>
        </FlexBoxItem>
        {(!getAuthorizedFromDelious() || companyCode) && (
          <FlexBoxItem customStyle={css({ marginBottom: "20px", marginTop: "15px" })}>
            <PrimaryButton text="戻る" onClick={() => (window.location.href = "/")} />
          </FlexBoxItem>
        )}
        {(!getAuthorizedFromDelious() || companyCode) && (
          <FlexBoxItem>
            <PrimaryButton text="ログアウト" ghost={true} onClick={() => logoutAction()} />
          </FlexBoxItem>
        )}
      </FlexBox>
    </div>
  );
};

export default NotFound404;
