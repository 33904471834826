/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, useContext, Dispatch
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain, { StaffHoliday } from 'domain/staff';
import { postLongTermCareAndNurse } from 'api/staff'; 
import moment from 'moment';
import { LoadingContextAdd } from 'components/pages/master/general/staff/StaffAddPage/hooks'

type StaffDomainKey = keyof Pick<StaffDomain, 'orgApplyDate' | 'orgCode'>;
const StaffDomainSchema: { [key in StaffDomainKey]: any } = {
  orgCode: Yup.string().required('組織を入力してください。'),
  orgApplyDate: Yup.string().required('所属情報の異動日を入力してください。'),
};
const schema = Yup.object({ ...StaffDomainSchema });

export const useLongTermCareAndNurse = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading?: Dispatch<React.SetStateAction<any>>,
  holidayTypeOptions?: any
) => {
  const history = useHistory();
  const contextObject = useContext(LoadingContextAdd);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [openManagerAddModal, setOpenManagerAddModal] = useState<boolean>(false);

  // 1:Add, 2:Edit, 3:Delete
  const [isEditHoliday, setIsEditHoliday] = useState<Number>(1);
  const [isAddItem, setIsAddItem] = useState(false);

  const clearForm = () => {
    formik.setFieldValue('staffHolidayId', '');
    formik.setFieldValue('holidayId', '');
    formik.setFieldValue('applyStartDate', new Date());
    formik.setFieldValue('applyEndDate', '');
    formik.setFieldValue('yearUseDays', 0); 
  }

  const onSubmit = async (values: StaffDomain) => {
    console.log('dsadas')
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      setConfirmModalOpen(false);
      setIsLoading!(true);
      const postData = values.getStaffInfoPostData(isEditMode);
      let { applyStartDate, applyEndDate, yearUseDays , holidayId } = postData;
      if(!applyEndDate){
        postData.applyEndDate = new Date('9999/12/31');
        if(postData.staffHolidayData){
          postData.staffHolidayData.applyEndDate = '9999/12/31'; 
        }
      }
      
      if(!applyStartDate){
        formik.setFieldValue('applyStartDate', new Date());
      }
      if(!holidayId) {
        errorNotification("休暇を選択してください。");
        setIsLoading!(false);
        return false;
      } 
       
      if(applyStartDate && applyEndDate && applyStartDate > applyEndDate){ 
        errorNotification("対象期間終了日には対象期間開始日以降の日付を選択してください。");
        setIsLoading!(false);
        return false;
      }

      // if(moment(applyStartDate).format('YYYY/MM/DD') === moment(applyEndDate).format('YYYY/MM/DD')){
      //   errorNotification("対象期間が重複しています。");
      //   setIsLoading!(false);
      //   return false;
      // } 
      
      if(yearUseDays < 0 || yearUseDays > 366 ){
        errorNotification("１年度の取得可能日数は０〜３６６までの範囲で入力してください");
        setIsLoading!(false);
        return false;
      }
      
      const response = await postLongTermCareAndNurse(isEditMode ? 1 : 0, postData);  
      formik.setFieldValue('staffHolidayList',response.staff.staffHolidayList);
      successNotification(isAddItem ? '登録しました。' : '更新しました。');
      setIsLoading!(false);
      clearForm();
      setIsAddItem(true);
      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading!(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };
  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
    validateOnChange: false,
    validate: async (values) => {
      const errors: { [key: string]: string } = {};
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const validationError = error as Yup.ValidationError;
        validationError.inner.forEach((innerError) => {
          errors[innerError.path] = innerError.message;
        });
      }
      return errors;
    },
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
  }, [initialStaffDomain]);
 

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    openManagerAddModal,
    setOpenManagerAddModal,
    setIsEditHoliday,
    isEditHoliday,
    successNotification, 
    errorNotification,
    clearForm,
    isAddItem, 
    setIsAddItem,
    contextObject
  };
};

export default {
  useLongTermCareAndNurse,
};
