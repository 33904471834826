import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getList, getBusinessDataByEmploymentId, getBusinessAttendChoice } from 'api/businessMaster';

/**
 * 業務の選択ボックス用データを取得
 */
export const useBusinessMasterOptions = (): Array<OptionType> => {
  const [businessOptions, setBusinessOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getList().then((businesses) => {
      setBusinessOptions(businesses.map((business) => ({
        value: business.businessId,
        label: `${business.businessCode} ${business.businessName}`,
      })));
    });
  }, []);
  return businessOptions;
};

/**
 * 業務の選択ボックス用データを取得
 */
export const useBusinessMasterByEmploymentIdOptions = (employmentId: string): Array<OptionType> => {
  const [businessOptions, setBusinessOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getBusinessDataByEmploymentId(employmentId).then((businesses) => {
      setBusinessOptions(businesses.map((business: any) => ({
        value: business.businessId,
        label: business.businessName,
      })));
    });
  }, [employmentId]);
  return businessOptions;
};

export const useBusinessAttendChoiceOptions = (
  staffCode: string|undefined, targetDate: string,
): Array<OptionType> => {
  const [
    businessAttendChoiceOptions, setBusinessAttendChoiceOptions,
  ] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (!staffCode) {
      return;
    }
    getBusinessAttendChoice(staffCode, targetDate).then((businesses) => {
      setBusinessAttendChoiceOptions(businesses.map((business: any) => ({
        value: business.businessId,
        label: business.businessName,
      })));
    });
  }, [staffCode, targetDate]);
  return businessAttendChoiceOptions;
};


export default useBusinessMasterOptions;
