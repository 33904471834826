import React from "react";

import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import OrganizationSpecialSalaryEditForm from "components/organismos/organization/OrganizationSpecialSalaryEditForm/OrganizationSpecialSalaryEditForm";

const OrganizationSpecialSalaryPage: React.FC = () => (
  <SidebarTemplate pageTitle="特賃日設定">
    <FormContents>
      <OrganizationSpecialSalaryEditForm isEditable={1} isEditMode={false} />
    </FormContents>
  </SidebarTemplate>
);

export default OrganizationSpecialSalaryPage;
