/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useEffect } from "react";
import useToastNotification from "hooks/useToastNotification";
import { getDetailRole, 
  DetailRole, 
  getListRole, 
  Role, 
  downloadCSV,
  deleteRoleId
} from 'api/masterRole';

const { errorNotification, successNotification } = useToastNotification();

const defaultDetailRole = {
  roleId: '',
  functionCode: '005',		
  available: false,		
  editable: 0,		
  importFlag: 0,		
  downloadFlag: 0,		
  accessRange: 1,		
  linkAvailable1: false,
  linkAvailable2: false,
  linkAvailable3: false,
  linkAvailable4: false,
  linkAvailable5: false,
  usabilityGeneralItem1: null,
  usabilityGeneralItem2: null,
  usabilityGeneralItem3: null,
  usabilityGeneralItem4: null,
  usabilityGeneralItem5: null,
  usabilityGeneralItem6: null,
  usabilityGeneralItem7: null,
  usabilityGeneralItem8: null,
  usabilityGeneralItem9: null,
  usabilityGeneralItem10: null,
}

export const useMasterRoleSettingForm = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRoleID, setSelectedRoleID] = useState("");
  const [listRole, setListRole] = useState<Array<Role>>([]);
  const [detailRole, setDetailRole] = useState<DetailRole>(defaultDetailRole);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const downloadCsvRole = useCallback(() => {
    downloadCSV().then((response: any) => {
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
      if (error.status === 400) {
        if (error.data.errors && error.data.errors.length > 0) {
          errorNotification(
            error.data.errors.map((x: any) => x.defaultMessage)
          );
        } else if (error.data.defaultMessage) {
          errorNotification(error.data.defaultMessage);
        } else {
          errorNotification(error.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
  }, []);
  const handleDeleteRole = useCallback(() => {
    deleteRoleId(selectedRoleID).then((response: DetailRole) => {
      fetchListRole();
    })
    .catch((error: any) => {
      setIsLoading(false);
      if (error.response.status === 400) {
        const listErr = error.response.data;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    })
  }, [selectedRoleID]);

  const fetchFunctionRole = useCallback(async () => {
    setIsLoading(true);
    getDetailRole('005').then((response: DetailRole) => {
      if(response){
        setDetailRole(response);
      }
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, []);

  const fetchListRole = useCallback(async () => {
    setIsLoading(true);
    getListRole().then((response: Array<Role>) => {
      if(response && Array.isArray(response)){
        setListRole(response);
      }      
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    })
    ;

  }, []);

  useEffect(() => {
    fetchFunctionRole();
    fetchListRole();
  }, [])

  return {
    isLoading,
    setIsLoading,
    downloadCsvRole,
    listRole,
    detailRole,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    selectedRoleID,
    setSelectedRoleID,
    handleDeleteRole
  };
};
export default useMasterRoleSettingForm;
