/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import useIsMobile from "hooks/useIsMobile";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const MonthHourTable: React.FC<{
  employment: any;
  business: any;
  term: any;
  timeDisp: any;
  subTotalTime: any;
  remote: boolean;
}> = ({ employment, business, term, timeDisp, subTotalTime, remote }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const isMobile = useIsMobile();

  const getColSpan = () => {
    return !isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 ? 4 : 3;
  };

  return (
    <div
      css={css`
        table thead tr th {
          height: 46px;
        }
        table thead tr th div {
          text-align: center;
        }
        table tbody tr td {
          height: 32px;
        }
      `}
    >
      <table
        id="monthHourList"
        className="table table-bordered table-condensed"
        cellSpacing="0"
        style={{ whiteSpace: "nowrap", width: "100%" }}
      >
        <thead>
          <tr>
            <td>&nbsp;</td>
            {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
              <td>
                <div css={[thStyle, css({ textAlign: "center" })]}>{"所定日\n（実勤務）"}</div>
              </td>
            )}
            <td style={{ width: "76px" }}>
              <div css={[thStyle, css({ textAlign: "center" })]}>
                {employment.workSystem !== 2 ? "所定日" : "所定日\n（裁量）"}
              </div>
            </td>
            <td style={{ width: "76px" }}>
              <div css={[thStyle, css({ textAlign: "center" })]}>
                {employment.distinctionHoliday ? "法定\n休日" : "休日"}
              </div>
            </td>
            {employment.distinctionHoliday && (
              <td style={{ width: "76px" }}>
                <div css={[thStyle, css({ textAlign: "center" })]}>{"所定\n休日"}</div>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          <tr role="row">
            {/* <!--普通時間 --> */}
            <td>
              <div css={thStyle}>通常勤務</div>
            </td>
            {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytime)}</div>
              </td>
            )}

            <td>
              <div css={tdStyle}>{timeDisp(subTotalTime.daytime)}</div>
            </td>
            <td>
              <div css={tdStyle}>{timeDisp(subTotalTime.daytimeLegalHoliday)}</div>
            </td>
            {employment.distinctionHoliday && (
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeNotLegalHoliday)}</div>
              </td>
            )}
          </tr>
          {employment.handleLegalInOvertime === 0 && (
            <tr role="row">
              {/* <!--法定内残業時間（法定内の区別なし）--> */}
              <td>
                <div css={thStyle}>普通残業</div>
              </td>
              {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytimeWeekdayOvertime)}</div>
                </td>
              )}
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeWeekdayOvertime)}</div>
              </td>

              <td></td>
              {/* <!--法定内残業時間（法定内の区別あり） --> */}
              {/* {employment.handleLegalInOvertime === 1 && <td>-</td>} */}

              {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上する) --> */}
              {/* {employment.treatmentStatutoryHolidayOvertime === 0 && (
                  <td>{timeDisp(subTotalTime.daytimeLegalHolidayOvertime)}</td>
                )} */}
              {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上しない) --> */}
              {/* {employment.treatmentStatutoryHolidayOvertime === 1 && <td>-</td>} */}

              {employment.distinctionHoliday && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeNotLegalHolidayOvertime)}</div>
                </td>
              )}
            </tr>
          )}
          {/* <!--法定内残業時間（法定内の区別あり） --> */}
          {employment.handleLegalInOvertime === 1 && (
            <React.Fragment>
              <tr role="row">
                <td>
                  <div css={thStyle}>法定内残業</div>
                </td>
                {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytimeLegalOvertime)}</div>
                  </td>
                )}

                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeLegalOvertime)}</div>
                </td>

                {/* <!--法定内残業時間（法定内の区別あり） --> */}
                <td>
                  <div css={tdStyle}>-</div>
                </td>

                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeNotLegalOvertime)}</div>
                </td>
              </tr>
              <tr role="row">
                {/* <!--法定外残業時間（法定内の区別あり）--> */}
                <td>
                  <div css={thStyle}>法定外残業</div>
                </td>
                {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytimeWeekdayOvertime)}</div>
                  </td>
                )}
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeWeekdayOvertime)}</div>
                </td>

                {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上する・しない) --> */}
                <td>
                  <div css={tdStyle}>
                    {employment.treatmentStatutoryHolidayOvertime === 0
                      ? timeDisp(subTotalTime.daytimeLegalHolidayOvertime)
                      : employment.treatmentStatutoryHolidayOvertime === 1
                      ? "-"
                      : ""}
                  </div>
                </td>

                {employment.distinctionHoliday && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.daytimeNotLegalHolidayOvertime)}</div>
                  </td>
                )}
              </tr>
            </React.Fragment>
          )}
          {/* <!--割増残業時間1 --> */}
          {employment.useExtraOverTime && (
            <tr role="row">
              <td>
                <div css={thStyle}>割増残業1</div>
              </td>
              {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytimeWeekdayPremiumOvertime1)}</div>
                </td>
              )}

              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime1)}</div>
              </td>
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime1)}</div>
              </td>

              {employment.distinctionHoliday && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeNotLegalHolidayPremiumOvertime1)}</div>
                </td>
              )}
            </tr>
          )}
          {/* <!--割増残業時間2 --> */}
          {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
            <tr role="row">
              <td>
                <div css={thStyle}>割増残業2</div>
              </td>
              {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytimeWeekdayPremiumOvertime2)}</div>
                </td>
              )}

              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime2)}</div>
              </td>
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime2)}</div>
              </td>

              {employment.distinctionHoliday && (
                <td>{timeDisp(subTotalTime.daytimeNotLegalHolidayPremiumOvertime2)}</td>
              )}
            </tr>
          )}
          {/* <!--割増残業時間3 --> */}
          {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
            <tr role="row">
              <td>
                <div css={thStyle}>割増残業3</div>
              </td>
              {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.stampDaytimeWeekdayPremiumOvertime3)}</div>
                </td>
              )}

              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime3)}</div>
              </td>
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime3)}</div>
              </td>

              {employment.distinctionHoliday && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeNotLegalHolidayPremiumOvertime3)}</div>
                </td>
              )}
            </tr>
          )}
          {/* <!--深夜時間 --> */}
          <tr role="row">
            <td>
              <div css={thStyle}>深夜</div>
            </td>
            {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.stampMidnightWeekdayTotal)}</div>
              </td>
            )}

            <td>
              <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayTotal)}</div>
            </td>
            <td>
              <div css={tdStyle}>{timeDisp(subTotalTime.midnightLegalHolidayTotal)}</div>
            </td>

            {employment.distinctionHoliday && (
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.midnightNotLegalHolidayTotal)}</div>
              </td>
            )}
          </tr>
          {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
          {employment.handleLegalInOvertime === 0 && (
            <tr role="row">
              <td>
                <div css={thStyle}>深夜残業</div>
              </td>
              {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                <td>
                  <div css={timeDisp(subTotalTime.stampMidnightWeekdayOvertime)}></div>
                </td>
              )}

              <td>
                <div css={timeDisp(subTotalTime.midnightWeekdayOvertime)}></div>
              </td>

              {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
              <td>
                <div css={timeDisp(subTotalTime.midnightWeekdayOvertime)}>
                  {employment.treatmentStatutoryHolidayOvertime === 1
                    ? "-"
                    : employment.treatmentStatutoryHolidayOvertime === 0
                    ? timeDisp(subTotalTime.midnightLegalHolidayOvertime)
                    : ""}
                </div>
              </td>

              {employment.distinctionHoliday && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.midnightNotLegalHolidayOvertime)}</div>
                </td>
              )}
            </tr>
          )}
          {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
          {employment.handleLegalInOvertime === 1 && (
            <React.Fragment>
              <tr role="row">
                <td>
                  <div css={thStyle}>
                    深夜
                    <br />
                    法定内残業
                  </div>
                </td>
                {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.stampMidnightWeekdayLegalOvertime)}</div>
                  </td>
                )}

                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayLegalOvertime)}</div>
                </td>

                <td>
                  <div css={tdStyle}>-</div>
                </td>

                {employment.distinctionHoliday && (
                  <td>
                    <div css={tdStyle}> {timeDisp(subTotalTime.midnightNotLegalHolidayLegalOvertime)}</div>
                  </td>
                )}
              </tr>
              <tr role="row">
                <td>
                  <div css={thStyle}>
                    深夜
                    <br />
                    法定外残業
                  </div>
                </td>
                {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.stampMidnightWeekdayOvertime)}</div>
                  </td>
                )}

                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayOvertime)}</div>
                </td>

                <td>
                  <div css={tdStyle}>-</div>
                </td>

                {employment.distinctionHoliday && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.midnightNotLegalHolidayOvertime)}</div>
                  </td>
                )}
              </tr>
            </React.Fragment>
          )}
          {/* <!--深夜割増残業時間 --> */}
          {employment.useExtraOverTime && (
            <React.Fragment>
              <tr role="row">
                <td>
                  <div css={thStyle}>
                    深夜
                    <br />
                    割増残業1
                  </div>
                </td>
                {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.stampMidnightWeekdayPremiumOvertime1)}</div>
                  </td>
                )}

                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime1)}</div>
                </td>

                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime1)}</div>
                </td>

                {employment.distinctionHoliday && (
                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.midnightNotLegalHolidayPremiumOvertime1)}</div>
                  </td>
                )}
              </tr>
              {employment.extraOverTime2 > 0 && (
                <tr role="row">
                  <td>
                    <div css={thStyle}>
                      深夜
                      <br />
                      割増残業2
                    </div>
                  </td>
                  {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                    <td>
                      <div css={tdStyle}>{timeDisp(subTotalTime.stampMidnightWeekdayPremiumOvertime2)}</div>
                    </td>
                  )}

                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime2)}</div>
                  </td>

                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime2)}</div>
                  </td>

                  {employment.distinctionHoliday && (
                    <td>
                      <div css={tdStyle}>{timeDisp(subTotalTime.midnightNotLegalHolidayPremiumOvertime2)}</div>
                    </td>
                  )}
                </tr>
              )}
              {employment.extraOverTime3 > 0 && (
                <tr role="row">
                  <td>
                    <div css={thStyle}>
                      深夜
                      <br />
                      割増残業3
                    </div>
                  </td>
                  {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                    <td>
                      <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime3)}</div>
                    </td>
                  )}

                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.midnightWeekdayPremiumOvertime3)}</div>
                  </td>

                  <td>
                    <div css={tdStyle}>{timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime3)}</div>
                  </td>

                  {employment.distinctionHoliday && (
                    <td>
                      <div css={tdStyle}> {timeDisp(subTotalTime.midnightNotLegalHolidayPremiumOvertime3)}</div>
                    </td>
                  )}
                </tr>
              )}
            </React.Fragment>
          )}
          {/* <!--未承認残業時間 --> */}
          {employment.useOverTimeApplication !== 0 && (
            <tr role="row">
              <td>
                <div css={thStyle}>未承認残業</div>
              </td>
              {!isMobile && employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.daytimeUnapprovedOvertime)}</div>
                </td>
              )}

              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.daytimeUnapprovedOvertime)}</div>
              </td>
              <td>
                <div css={tdStyle}>{timeDisp(subTotalTime.leaglHolidayUnapprovedOvertime)}</div>
              </td>

              {employment.distinctionHoliday && (
                <td>
                  <div css={tdStyle}>{timeDisp(subTotalTime.notLeaglHolidayUnapprovedOvertime)}</div>
                </td>
              )}
            </tr>
          )}
          <tr role="row">
            {/* <!--休憩 --> */}
            <td>
              <div css={thStyle}>休憩</div>
            </td>
            <td colSpan={getColSpan()}>
              <div css={tdStyle}>{timeDisp(subTotalTime.recess)}</div>
            </td>
          </tr>

          {remote && (
            <React.Fragment>
              <tr role="row">
                {/* <!--オフィス --> */}
                <td>
                  <div css={thStyle}>オフィス</div>
                </td>
                <td colSpan={getColSpan()}>
                  <div css={tdStyle}>{timeDisp(subTotalTime.officeTime)}</div>
                </td>
              </tr>
              <tr role="row">
                {/* <!--テレワーク --> */}
                <td>
                  <div css={thStyle}>{companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"}</div>
                </td>
                <td colSpan={getColSpan()}>
                  <div css={tdStyle}>{timeDisp(subTotalTime.remoteTime)}</div>
                </td>
              </tr>
            </React.Fragment>
          )}

          <tr role="row">
            {/* <!--遅刻 --> */}
            <td>
              <div css={thStyle}>遅刻</div>
            </td>
            <td colSpan={getColSpan()}>
              <div css={tdStyle}>{timeDisp(subTotalTime.lateTime)}</div>
            </td>
          </tr>
          <tr role="row">
            {/* <!--早退 --> */}
            <td>
              <div css={thStyle}>早退</div>
            </td>
            <td colSpan={getColSpan()}>
              <div css={tdStyle}>{timeDisp(subTotalTime.earlierTime)}</div>
            </td>
          </tr>
          <tr role="row">
            {/* <!--みなし時間 --> */}
            <td>
              <div css={thStyle}>みなし時間</div>
            </td>
            <td colSpan={getColSpan()}>
              <div css={tdStyle}>{timeDisp(subTotalTime.deemedTime)}</div>
            </td>
          </tr>
          {employment.workSystem === 3 && (
            <tr role="row">
              {/* <!--月間所定労働時間 --> */}
              <td>
                <div css={thStyle}>
                  月間所定
                  <br />
                  労働時間
                </div>
              </td>
              <td colSpan={getColSpan()}>
                <div css={tdStyle}>{timeDisp(subTotalTime.workingHours)}</div>
              </td>
            </tr>
          )}

          <tr role="row">
            {/* <!--総労働時間 --> */}
            <td>
              <div css={thStyle}>総労働時間</div>
            </td>
            <td colSpan={getColSpan()}>
              <div css={tdStyle}>{timeDisp(subTotalTime.allWorkTime)}</div>
            </td>
          </tr>

          {employment.workSystem === 2 && subTotalTime.dispStampTotalFlag === 1 && (
            <tr role="row">
              {/* <!--実勤務総労働時間 --> */}
              <td>
                <div css={thStyle}>
                  実勤務
                  <br />
                  総労働時間
                </div>
              </td>
              <td colSpan={getColSpan()}>
                <div css={tdStyle}>{timeDisp(subTotalTime.stampAllWorkTime)}</div>
              </td>
            </tr>
          )}

          {business &&
            business.map((item: any, index: number) => (
              <tr role="row">
                <td>
                  <div css={thStyle}>{item.businessName}</div>
                </td>
                <td colSpan={getColSpan()} key={String(index)}>
                  <div css={tdStyle}>{timeDisp(subTotalTime.businessTimeSubTotal?.[index]?.subtotalTime)}</div>
                </td>
              </tr>
            ))}

          {term &&
            term.map((item: any, index: number) => (
              <tr role="row">
                <td>
                  <div css={thStyle}>{item.termName}</div>
                </td>
                <td colSpan={getColSpan()} key={String(index)}>
                  <div css={tdStyle}>{timeDisp(subTotalTime.termTimeSubTotal?.[index]?.subtotalTime)}</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default MonthHourTable;
