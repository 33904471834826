/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import React, { ReactNode } from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import Icon from "components/atoms/Icon";

const accordionRootStyle = css`
  border-radius: 6px;
  background-color: white;
`;

const accordionItemStyle = css`
  overflow: hidden;
  margin-top: 1px;

  &:first-of-type {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`;

const accordionHeaderStyle = css({
  display: "flex",
  backgroundColor: "#007bc3",
  margin: "0 0 8px 0",
});

const accordionTriggerStyle = css({
  all: "unset",
  transition: "all",
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold",

  fontFamily: "inherit",
  padding: "0 20px",
  height: "45px",
  '&[data-state="open"] > span > svg ': {
    transform: "rotate(180deg)",
  },
});

const keyboardArrowDownIconStyle = css({
  height: "1rem",
  width: "1rem",
  flexShrink: 0,
  transition: "transform 200ms",
});

const slideDown = keyframes`
  from { height: 0; }
  to { height: var(--radix-accordion-content-height); }
`;

const slideUp = keyframes`
  from { height: var(--radix-accordion-content-height); }
  to { height: 0; }
`;

const accordionContentStyle = css`
  overflow: hidden;
  font-size: 15px;

  &[data-state="open"] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state="closed"] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

interface AccordionProps {
  children: ReactNode;
  title: string;
  defaultOpen?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({ children, title, defaultOpen = false }) => {
  return (
    <RadixAccordion.Root css={accordionRootStyle} type="single" defaultValue={defaultOpen ? "item" : ""} collapsible>
      <RadixAccordion.Item css={accordionItemStyle} value="item">
        <RadixAccordion.Header css={accordionHeaderStyle}>
          <RadixAccordion.Trigger asChild>
            <div css={accordionTriggerStyle}>
              <div
                css={css({
                  color: "#FFF",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                })}
              >
                {title}
              </div>
              <Icon size="24px" type="arrowDown" css={keyboardArrowDownIconStyle} color="white" />
            </div>
          </RadixAccordion.Trigger>
        </RadixAccordion.Header>
        <RadixAccordion.Content css={accordionContentStyle}>{children}</RadixAccordion.Content>
      </RadixAccordion.Item>
    </RadixAccordion.Root>
  );
};
