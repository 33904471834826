import { useState, useCallback, useEffect } from "react";
import { postStaffCsvImport, Error, getImportHistory } from "api/staff";
import useToastNotification from "hooks/useToastNotification";
import { UploadType } from "./StaffCsvUploadForm";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

type UploadTypeItem = { label: string; value: UploadType };

export const useOrganizationCsvForm = () => {
  // アップロードタイプ
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploadType, setUploadType] = useState<UploadType>("0");
  const { successNotification, errorNotification } = useToastNotification();
  const [importHistory, setImportHistory] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.staffs);

  const uploadTypeItems: UploadTypeItem[] = [
    {
      label: "スタッフ情報",
      value: "0",
    },
    {
      label: "兼務情報",
      value: "2",
    },
    ...(detailRole.usabilityGeneralItem1 === 1
      ? ([
          {
            label: "人件費情報",
            value: "3",
          },
        ] as UploadTypeItem[])
      : []),
    {
      label: "介護・看護休暇情報",
      value: "1",
    },
  ];

  // アップロードファイル
  const [uploadFile, setUploadFile] = useState<File>();

  // アップロードエラー
  const [errors, setErrors] = useState<Array<string>>();

  if (uploadFile) {
    const fileType = uploadFile.type;
    if (
      fileType !== "text/csv" &&
      fileType !== "application/vnd.ms-excel" &&
      fileType !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "text/xls" &&
      fileType !== "text/xlsx"
    ) {
      // errorNotification('CSVファイルを選択してください。');
      setErrors(["CSVファイルを選択してください。"]);
      setUploadFile(undefined);
    }
  }

  // アップロード実行処理
  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    // エラー表示を初期化
    setErrors(undefined);
    setConfirmModalOpen(false);
    setBlocking(true);
    postStaffCsvImport(uploadType, uploadFile)
      .then((response) => {
        if (response.status !== 200) {
          let errorString = "";
          (response.data.errors as Array<Error>).map((err) => {
            errorString += `${err.defaultMessage} \n`;
            return errorString;
          });
          // setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
          errorNotification(errorString);
        } else {
          successNotification("インポートが完了しました。");
        }
        setUploadFile(undefined);
        setBlocking(false);
      })
      .catch((error) => {
        setUploadFile(undefined);
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
        setBlocking(false);
      });
  }, [confirmModalOpen, errorNotification, successNotification, uploadFile, uploadType]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  useEffect(() => {
    setBlocking(true);
    getImportHistory().then((response: any) => {
      setImportHistory(response);
      setBlocking(false);
    });
  }, []);

  return {
    confirmModalOpen,
    closeConfirmModal,
    uploadType,
    setUploadType,
    uploadTypeItems,
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    blocking,
    importHistory,
    setImportHistory,
  };
};

export default {};
