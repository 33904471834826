
import React, { useState, useEffect } from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { updateCommentForSale } from 'api/salesReport';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import { css } from '@emotion/core';
import useToastNotification from 'hooks/useToastNotification';
import FormLabel from 'components/atoms/Form/FormLabel';

const CashRegisterReport: React.FC<{
  dailyData: any,
  cashRegisterReport: any,
  setIsLoading: any,
  targetOrgCode: any,
  targetDay: any
}> = ({
  dailyData,
  cashRegisterReport,
  setIsLoading,
  targetOrgCode,
  targetDay,
}) => {
  const { successNotification, errorNotification } = useToastNotification();
  const [budgetProgressComment, setBudgetProgressComment] = useState('');
  const [storeOperationComment, setStoreOperationComment] = useState('');
  const [otherComment, setOtherComment] = useState('');

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num)) {
      return num;
    }
    const tmp = String(num).split('.');
    if (tmp[1]) {
      let fixed = 3;
      if (tmp[1].length < 3) {
        fixed = tmp[1].length;
      }
      return parseFloat(num).toFixed(fixed);
    }
    return formatter.format(num);
  };

  useEffect(() => {
    if (dailyData.cash_data) {
      setBudgetProgressComment(dailyData.cash_data.comment.budget_progress_comment);
      setStoreOperationComment(dailyData.cash_data.comment.store_operation_comment);
      setOtherComment(dailyData.cash_data.comment.other_comment);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyData]);


  const handleUpdateCommentForSale = () => {
    setIsLoading(true);

    updateCommentForSale(
      targetOrgCode,
      new Date(targetDay),
      budgetProgressComment,
      storeOperationComment,
      otherComment,

    ).then((data: any) => {
      setIsLoading(false);
      successNotification('更新しました。');
    })
      .catch((exception : any) => {
        setIsLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
  };

  return (
    <div className="box-body" id="SalesDailyReport">
      <div className="row" id="layoutSale_02">
        <div className="col-md-12">
          <div id="resize-detect-container" className="tab-content">
            {/* レジレポート */}
            <div className="tab-pane" id="search_tab_2">
              <div className="col-xs-12">
                <FlexBox customStyle={css({ alignItems: 'initial' })}>

                  <FlexBoxItem basis="49%">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-condensed text-nowrap">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-center">項目</th>
                            <th className="text-center">金額</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center" rowSpan={10}>売上情報</td>
                            <td className="text-center" rowSpan={3}>税抜</td>
                            <td className="text-center">合計</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.net_sales) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">イートイン</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.eat_in) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">テイクアウト</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.takeout) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">割引額</td>
                            <td className="text-center">合計</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.total_discount_amount) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center" rowSpan={3}>消費税</td>
                            <td className="text-center">合計</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.tax) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">イートイン</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.eat_in_tax) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">テイクアウト</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.takeout_tax) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center" rowSpan={3}>税込</td>
                            <td className="text-center">合計</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.total_sales) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">イートイン</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.eat_in_total_sales) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">テイクアウト</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.cash_register.takeout_total_sales) : ''}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </FlexBoxItem>
                  <FlexBoxItem basis="2%" />
                  <FlexBoxItem basis="49%">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-condensed text-nowrap">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-center">項目</th>
                            <th className="text-center">金額</th>
                          </tr>
                        </thead>

                        { (cashRegisterReport && cashRegisterReport.countPaymentMedia)
                          ? (
                            <tbody>
                              {
                                cashRegisterReport.checkoutInformation.map((info: any, index: number) => (
                                  <tr key={index}>
                                    { (index === 0)
                                      ? <td className="text-center" rowSpan={cashRegisterReport.countPaymentMedia}>精算情報</td>
                                      : ''}

                                    { (!info.category_name)
                                    && <td className="text-center" colSpan={info.colspan}>{ info.data.payment_media_mst_name }</td>}
                                    { (!info.category_name)
                                    && <td className="text-right">{ numberFormater(info.data.payment_media_amount) }</td>}

                                    { (info.category_name)
                                    && <td className="text-center" rowSpan={info.rowspan}>{ info.category_name }</td>}

                                    { (info.category_name)
                                    && <td className="text-center ">{ info.data.payment_media_mst_name }</td>}

                                    { (info.category_name)
                                    && <td className="text-right">{ numberFormater(info.data.payment_media_amount) }</td>}

                                  </tr>
                                ))
                              }
                            </tbody>
                          )
                          : ''}

                        { (cashRegisterReport && !cashRegisterReport.countPaymentMedia)
                           && (
                           <tbody>
                             <tr>
                               <td className="text-center">精算情報</td>
                               <td className="text-left" colSpan={2}>-</td>
                               <td className="text-center" />

                             </tr>
                           </tbody>
                           )}

                      </table>
                    </div>
                  </FlexBoxItem>
                </FlexBox>

                <FlexBox customStyle={css({ alignItems: 'initial', marginTop: '15px' })}>
                  <FlexBoxItem basis="49%">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-condensed text-nowrap">
                        <thead>
                          <tr>
                            <th colSpan={2} className="text-center">項目</th>
                            <th className="text-center">金額</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center" rowSpan={2}>入出金</td>
                            <td className="text-center">入金</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.total_payment) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">出金</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.total_withdrawal) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center" rowSpan={9}>ドロア情報</td>
                            <td className="text-center">釣銭</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.change_reserve) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">現金売上</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.cash_sales) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">現金残高</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.change_reserve - dailyData.cash_data.deposit_withdrawal.cash_sales) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">実現金残高</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.cash_stock) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">金券</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.cash_voucher_payment_total_amount) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">過不足</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.excess_and_deficiency) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">翌営業日釣銭</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.next_day_change) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">釣銭増減</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.next_day_change - dailyData.cash_data.deposit_withdrawal.change_reserve) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">銀行預入金</td>
                            <td className="text-right ">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.deposit_withdrawal.bank_deposit_amount) : ''}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </FlexBoxItem>
                  <FlexBoxItem basis="2%" />
                  <FlexBoxItem basis="49%">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-condensed text-nowrap">
                        <thead>
                          <tr>
                            <th colSpan={2} className="text-center">項目</th>
                            <th className="text-center">回数</th>
                            <th className="text-center">金額</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center" rowSpan={2}>アラート</td>
                            <td className="text-center">領収書</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.alert.receipt_cnt) : ''}</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.alert.receipt_amount) : ''}</td>
                          </tr>
                          <tr>
                            <td className="text-center">VOID</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.alert.void_cnt) : ''}</td>
                            <td className="text-right">{ dailyData.cash_data ? numberFormater(dailyData.cash_data.alert.void_amount) : ''}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </FlexBoxItem>
                </FlexBox>


                <div className="panel panel-default" style={{ marginTop: '20px' }}>
                  <div className="panel-heading">
                    <FormLabel
                      label="コメント"
                    />

                  </div>
                  <div className="panel-body">
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="form-control input-sm"
                            rows={5}
                            name="budget_progress_comment"
                            id="budgetProgressComment"
                            placeholder="予算進捗について"
                            defaultValue={budgetProgressComment}
                            onChange={(event) => setBudgetProgressComment(event.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control input-sm"
                            rows={5}
                            name="store_operation_comment"
                            id="storeOperationComment"
                            placeholder="組織運営について"
                            defaultValue={storeOperationComment}
                            onChange={(event) => setStoreOperationComment(event.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control input-sm"
                            rows={5}
                            name="other_comment"
                            id="otherComment"
                            placeholder="その他"
                            defaultValue={otherComment}
                            onChange={(event) => setOtherComment(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12">
                      <div className="text-center">
                        <FormSubmitArea>
                          <Button
                            text="コメントの更新 "
                            onClick={() => handleUpdateCommentForSale()}
                          />
                        </FormSubmitArea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CashRegisterReport;
