/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { useDownload } from './hooks';

const MonthlyForeCastManagemenTable: React.FC<{
    getBudgetPerfomanceManagement: any,
    targetPeriodData: any,
    orgLabel:any
  }> = ({
    getBudgetPerfomanceManagement,
    targetPeriodData,
    orgLabel,
  }) => {
    const { downloadBudgetPerfomanceManagementCSV, downloadBudgetPerfomanceManagementExcel } = useDownload();

    const formatter = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 0,
    });
    const formatterPercent = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
    });
    const [getOrgCodeName, setOrgCodeName] = useState('');
    const orgCodeArr:any[] = [];
    const searchResult:any[] = [];

    let orgCodeColumn: React.ReactNode;
    let orgNameColumn: React.ReactNode;
    let areaNameColumn: React.ReactNode;
    let businessCategoryNameColumn: React.ReactNode;
    let cumulativeSalesColumn: number;
    let cumulativeSalesBudgetColumn: number;
    let forecastDifferenceColumn: number;
    let achievementRateColumn: {} | null | undefined;
    let prevCumulativeSales: {} | null | undefined;
    let previousYearSalesColumn: {} | null | undefined;
    let yoYChangeColumn: {} | null | undefined;
    let numberOfCustomersColumn: {} | null | undefined;
    let targetNumberOfCustomers: {} | null | undefined;
    let achievementRateColumn2: {} | null | undefined;
    let customerUnitPrice: {} | null | undefined;
    let targetCustomerUnitPrice: {} | null | undefined;
    let customerUnitPriceDifference: {} | null | undefined;
    let previousYearSales: React.ReactNode;
    let purchase: {} | null | undefined;
    let purchaseBudget: {} | null | undefined;
    let costRate: {} | null | undefined;
    let targetCostRate: {} | null | undefined;
    let predictedDifference: {} | null | undefined;
    let laborCosts: {} | null | undefined;
    let laborBudget: {} | null | undefined;
    let laborCostRatio: {} | null | undefined;
    let targetLaborCostRatioColumn: {} | null | undefined;
    let predictedDifferenceColumn: {} | null | undefined;
    let fixedCostColumn: {} | null | undefined;
    let variableCostsColumn: {} | null | undefined;
    let profitColumn: {} | null | undefined;
    useEffect(() => {
      const str = orgLabel.join('、');
      setOrgCodeName(str);
    }, [getBudgetPerfomanceManagement, orgCodeArr, orgLabel]);

    const targetStoresData = `対象組織: ${getOrgCodeName}`;
    const headerInformation = [
      ['', '', '', '', '売上', '', '', '', '', '', '客数', '', '', '', '', '', '仕入', '', '', '', '', '人件費', '', '', '', '', '経費', '', '利益', '', ''],
      ['組織コード', '組織名', 'エリア', '業態', '純売上', '売上予算', '予実差額', '予実達成率', '前年売上', '前年対比', '客数', '目標客数', '予実達成率', '客単価', '目標客単価', '客単価差額', '仕入', '仕入予算', '原価率', '目標原価率', '予実差率', '人件費', '人件費予算', '人件費率', '目標人件費率', '予実差率', '固定費', '変動費', '利益', '目標利益', '予実達成率'],
    ];

    let mergedRegions:any[] = [];
    const codeColumnIndex:any[] = [];
    const data = {
      footer: '',
      formName: '月次予実管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
    };

    const dataExcel = {
      formName: '月次予実管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      headerInformation,
      searchResult,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportBudgetPerfomanceManagementCSV = () => { downloadBudgetPerfomanceManagementCSV(data); };
    const handleExportBudgetPerfomanceManagementExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([3, 3, 4, 9]);
      mergedRegions.push([3, 3, 10, 15]);
      mergedRegions.push([3, 3, 16, 20]);
      mergedRegions.push([3, 3, 21, 25]);
      mergedRegions.push([3, 3, 26, 27]);
      mergedRegions.push([3, 3, 28, 30]);

      mergedRegions.push([3, 4, 0, 0]);
      mergedRegions.push([3, 4, 1, 1]);
      mergedRegions.push([3, 4, 2, 2]);
      mergedRegions.push([3, 4, 3, 3]);
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [0];
      dataExcel.headerInformation = [
        ['組織コード', '組織名', 'エリア', '業態', '売上', '', '', '', '', '', '客数', '', '', '', '', '', '仕入', '', '', '', '', '人件費', '', '', '', '', '経費', '', '利益', '', ''],
        ['', '', '', '', '純売上', '売上予算', '予実差額', '予実達成率', '前年売上', '前年対比', '客数', '目標客数', '予実達成率', '客単価', '目標客単価', '客単価差額', '仕入', '仕入予算', '原価率', '目標原価率', '予実差率', '人件費', '人件費予算', '人件費率', '目標人件費率', '予実差率', '固定費', '変動費', '利益', '目標利益', '予実達成率'],
      ];
      return downloadBudgetPerfomanceManagementExcel(dataExcel);
    };

    const tblRows = ['純売上', '売上予算', '予実差額', '予実達成率', '前年売上', '前年対比', '客数', '目標客数', '予実達成率', '客単価', '目標客単価', '客単価差額', '仕入', '仕入予算', '原価率', '目標原価率', '予実差率', '人件費', '人件費予算', '人件費率', '目標人件費率', '予実差率', '固定費', '変動費', '利益', '目標利益', '予実達成率'];

    const widthScrollbar = (29 * 135) + 100;
    const [getFlagBorder, setFlagBorder] = useState(0);
    const eventScroll = (e: { target: any }) => {
      const { target } = e;
      const wrapTblRight = document.getElementById('wrapTblRight');
      const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
      const jsScrollAction = document.getElementById('jsScrollAction');

      if (Number(target.scrollLeft) > 0) {
        setFlagBorder(1);
      } else {
        setFlagBorder(0);
      }
      if (wrapTblRight) {
        wrapTblRight.scrollLeft = target.scrollLeft;
      }
      if (wrapTblHeadRight) {
        wrapTblHeadRight.scrollLeft = target.scrollLeft;
      }
      if (jsScrollAction) {
        jsScrollAction.scrollLeft = target.scrollLeft;
      }
    };

    return (
      <div>
        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportBudgetPerfomanceManagementCSV}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportBudgetPerfomanceManagementExcel}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>
        <div className="wrap_time_day_separate_sales wrap_sales_management_table" style={{ overflow: 'hidden', marginTop: '15px' }}>
          <div className="contentHead" style={{ display: 'flex' }}>
            <div className="tblLeft" style={getFlagBorder ? { borderRight: '1px solid' } : {}}>
              <table>
                <thead>
                  <tr>
                    <th rowSpan={2} className="text-center"><span>組織コード</span></th>
                    <th rowSpan={2} className="text-center"><span>組織名 </span></th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="tblRight" id="wrapTblHeadRight" style={getBudgetPerfomanceManagement.length > 17 ? { width: 'calc(100% - 342px)' } : {}} onScroll={eventScroll}>
              <table>
                <thead>
                  <tr>
                    <th rowSpan={2}><span>エリア</span></th>
                    <th rowSpan={2}><span>業態</span></th>
                    <th colSpan={6}><span style={{ width: '100%' }}>売上</span></th>
                    <th colSpan={6}><span style={{ width: '100%' }}>客数</span></th>
                    <th colSpan={5}><span style={{ width: '100%' }}>仕入</span></th>
                    <th colSpan={5}><span style={{ width: '100%' }}>人件費</span></th>
                    <th colSpan={2}><span style={{ width: '100%' }}>経費</span></th>
                    <th colSpan={3}><span style={{ width: '100%' }}>利益</span></th>
                  </tr>
                  <tr>
                    { tblRows.map((item:any, index:any) => (<th key={index} style={{ width: '100%' }}><span>{item}</span></th>)) }
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <div>
              <div className="contentTable">
                <div className="tblLeft">
                  <table>
                    <tbody>
                      {
                        getBudgetPerfomanceManagement.map((item: any, index: any) => {
                          // orgCodeColumn
                          orgCodeColumn = item.orgCode;
                          // orgNameColumn
                          orgNameColumn = item.orgName;
                          // areaNameColumn
                          areaNameColumn = item.areaName;
                          // businessCategoryNameColumn
                          businessCategoryNameColumn = item.businessCategoryName;
                          // cumulativeSalesColumn
                          cumulativeSalesColumn = item.cumulativeSales;
                          // cumulativeSalesBudgetColumn
                          cumulativeSalesBudgetColumn = item.cumulativeSalesBudget;
                          // forecastDifferenceColumn
                          forecastDifferenceColumn = item.cumulativeSales - item.cumulativeSalesBudget;
                          // achievementRateColumn
                          achievementRateColumn = formatterPercent.format(0);
                          if (item.cumulativeSalesBudget !== 0) {
                            achievementRateColumn = formatterPercent.format(item.cumulativeSales / item.cumulativeSalesBudget);
                          }

                          prevCumulativeSales = formatter.format(item.prevCumulativeSales);

                          previousYearSalesColumn = formatterPercent.format(0);
                          if (item.prevCumulativeSales !== 0) {
                            previousYearSalesColumn = formatterPercent.format(item.cumulativeSales / item.prevCumulativeSales);
                          }

                          yoYChangeColumn = formatter.format(item.guestCnt);
                          numberOfCustomersColumn = formatter.format(item.guestCntBudget);

                          targetNumberOfCustomers = formatterPercent.format(0);
                          if (item.guestCntBudget !== 0) {
                            targetNumberOfCustomers = formatterPercent.format(item.guestCnt / item.guestCntBudget);
                          }

                          achievementRateColumn2 = formatter.format(0);
                          if (item.guestCnt !== 0) {
                            achievementRateColumn2 = formatter.format(item.cumulativeSales / item.guestCnt);
                          }

                          customerUnitPrice = formatter.format(0);
                          if (item.guestCntBudget !== 0) {
                            customerUnitPrice = formatter.format(item.cumulativeSalesBudget / item.guestCntBudget);
                          }

                          targetCustomerUnitPrice = formatter.format(0);
                          if (item.guestCnt === 0 && item.guestCntBudget !== 0) {
                            targetCustomerUnitPrice = formatter.format(-(item.cumulativeSalesBudget / item.guestCntBudget));
                          } else if ((item.guestCnt !== 0 && item.guestCntBudget === 0)) {
                            targetCustomerUnitPrice = formatter.format((item.cumulativeSales / item.guestCnt));
                          } else if (item.guestCnt !== 0 && item.guestCntBudget !== 0) {
                            targetCustomerUnitPrice = formatter.format((item.cumulativeSales / item.guestCnt) - (item.cumulativeSalesBudget / item.guestCntBudget));
                          }

                          customerUnitPriceDifference = formatter.format(item.cumulativePurchase);
                          previousYearSales = formatter.format(item.cumulativePurchaseBudget);
                          purchase = formatterPercent.format(0);
                          if (item.cumulativeSales !== 0) {
                            purchase = formatterPercent.format(item.cumulativePurchase / item.cumulativeSales);
                          }

                          purchaseBudget = formatterPercent.format(0);
                          if (item.cumulativeSalesBudget !== 0) {
                            purchaseBudget = formatterPercent.format(item.cumulativePurchaseBudget / item.cumulativeSalesBudget);
                          }

                          costRate = formatterPercent.format(0);
                          if (item.cumulativeSales === 0 && item.cumulativeSalesBudget !== 0) {
                            costRate = formatterPercent.format(-(item.cumulativePurchaseBudget / item.cumulativeSalesBudget));
                          } else if (item.cumulativeSales !== 0 && item.cumulativeSalesBudget === 0) {
                            costRate = formatterPercent.format(item.cumulativePurchase / item.cumulativeSales);
                          } else if (item.cumulativeSales !== 0 && item.cumulativeSalesBudget !== 0) {
                            costRate = formatterPercent.format((item.cumulativePurchase / item.cumulativeSales) - (item.cumulativePurchaseBudget / item.cumulativeSalesBudget));
                          }

                          targetCostRate = formatter.format(item.cumulativeLaborCost);

                          predictedDifference = formatter.format(item.cumulativeLaborCostBudget);

                          laborCosts = formatterPercent.format(0);
                          if (item.cumulativeSales !== 0) {
                            laborCosts = formatterPercent.format(item.cumulativeLaborCost / item.cumulativeSales);
                          }

                          laborBudget = formatterPercent.format(0);
                          if (item.cumulativeSalesBudget !== 0) {
                            laborBudget = formatterPercent.format(item.cumulativeLaborCostBudget / item.cumulativeSalesBudget);
                          }

                          laborCostRatio = formatterPercent.format(0);

                          if (item.cumulativeSales === 0 && item.cumulativeSalesBudget !== 0) {
                            laborCostRatio = formatterPercent.format(-(item.cumulativeLaborCostBudget / item.cumulativeSalesBudget));
                          } else if (item.cumulativeSales !== 0 && item.cumulativeSalesBudget === 0) {
                            laborCostRatio = formatterPercent.format(item.cumulativeLaborCost / item.cumulativeSales);
                          } else if (item.cumulativeSales !== 0 && item.cumulativeSalesBudget !== 0) {
                            laborCostRatio = formatterPercent.format((item.cumulativeLaborCost / item.cumulativeSales) - (item.cumulativeLaborCostBudget / item.cumulativeSalesBudget));
                          }

                          targetLaborCostRatioColumn = formatter.format(item.cumulativeFixCost);

                          predictedDifferenceColumn = formatter.format(item.cumulativeVariableCost);

                          fixedCostColumn = formatter.format(item.cumulativeSales - item.cumulativePurchase - item.cumulativeLaborCost - item.cumulativeFixCost - item.cumulativeVariableCost);

                          variableCostsColumn = formatter.format(item.cumulativeSalesBudget - item.cumulativePurchaseBudget - item.cumulativeLaborCostBudget - item.cumulativeFixCost - item.cumulativeVariableCost);

                          profitColumn = formatterPercent.format(0);
                          if (item.cumulativeSalesBudget - item.cumulativePurchaseBudget - item.cumulativeLaborCostBudget - item.cumulativeFixCost - item.cumulativeVariableCost !== 0) {
                            profitColumn = formatterPercent.format((item.cumulativeSales - item.cumulativePurchase - item.cumulativeLaborCost - item.cumulativeFixCost - item.cumulativeVariableCost)
                                                          / (item.cumulativeSalesBudget - item.cumulativePurchaseBudget - item.cumulativeLaborCostBudget - item.cumulativeFixCost - item.cumulativeVariableCost));
                          }

                          searchResult.push([
                            orgCodeColumn || '',
                            orgNameColumn || '',
                            areaNameColumn || '',
                            businessCategoryNameColumn || '',
                            formatter.format(cumulativeSalesColumn) || 0,
                            formatter.format(cumulativeSalesBudgetColumn) || 0,
                            formatter.format(forecastDifferenceColumn) || 0,
                            achievementRateColumn || 0,
                            prevCumulativeSales || 0,
                            previousYearSalesColumn || 0,
                            yoYChangeColumn || 0,
                            numberOfCustomersColumn || 0,
                            targetNumberOfCustomers || 0,
                            achievementRateColumn2 || 0,
                            customerUnitPrice || 0,
                            targetCustomerUnitPrice || 0,
                            customerUnitPriceDifference || 0,
                            previousYearSales || 0,
                            purchase || 0,
                            purchaseBudget || 0,
                            costRate || 0,
                            targetCostRate || 0,
                            predictedDifference || 0,
                            laborCosts || 0,
                            laborBudget || 0,
                            laborCostRatio || 0,
                            targetLaborCostRatioColumn || 0,
                            predictedDifferenceColumn || 0,
                            fixedCostColumn || 0,
                            variableCostsColumn || 0,
                            profitColumn || 0,
                          ]);
                          return (
                            <tr key={index}>
                              <td style={{ width: '50%' }} className="text-center"><span>{orgCodeColumn}</span></td>
                              <td className="text-center"><span>{orgNameColumn}</span></td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="tblRight" id="wrapTblRight" onScroll={eventScroll}>
                  <table id="tblHeadTable" onScroll={eventScroll}>
                    <tbody>
                      {
                        searchResult.map((item: any, index: any) => (
                          <tr key={index}>
                            {
                                item.map((itemChild:any, indexChild:any) => {
                                  if (indexChild >= 2) {
                                    return (
                                      <td className={(indexChild === 2 || indexChild === 3) ? 'text-center' : 'text-right'}><span>{itemChild}</span></td>
                                    );
                                  }
                                })
                              }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {
            getBudgetPerfomanceManagement.length > 0 ? (
              <div className="scroller">
                <div className="scrollLeft" style={{ width: '325px' }} />
                <div className="scrollRight" id="jsScrollAction" style={{ width: 'calc(100% - 325px)' }} onScroll={eventScroll}>
                  <div className="scrollAction" style={{ width: `${widthScrollbar}px` }} />
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  };

export default MonthlyForeCastManagemenTable;
