/** @jsx jsx */
import React, { useCallback, useMemo } from "react";
import { Column, Row as tableRow } from "react-table";
import { Link } from "react-router-dom";
import PrimaryButton from "components/atoms/Button";
import SecondaryButton from "components/atoms/Button/SecondaryButton";
import TextTooltip from "components/molecules/TextTooltip";
import DataTable from "components/organismos/DataTable/DataTable";
import AttendAchievementLockDomain from "domain/master/attend/attendAchievementLockDomain";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Container, Row, Col } from "react-grid-system";
import { css, jsx } from "@emotion/core";
import moment from "moment";
import BodyText from "components/atoms/BodyText";
import BlockUI from "components/molecules/BlockUi";
import useToastNotification from "hooks/useToastNotification";
import { selectedRow, useAttendAchievementLock } from "./hooks";
import Checkbox from "components/molecules/Checkbox";
import Radio from "components/molecules/Radio";
import ConfirmModal from "components/organismos/ConfirmModal";
import SelectForm from "components/molecules/SelectForm";
import FormTitle from "components/atoms/Form/FormTitle";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import FormPadding from "components/atoms/Form/FormPadding";

const styles = {
  textWrapper: css({
    whiteSpace: "pre-wrap",
  }),
  table: css`
    table {
      table-layout: fixed;
      width: 100%;
      min-width: 1150px;
    }
    table tbody tr {
      height: 30px;
    }
    table th:nth-child(1) {
      width: 30px;
    }
    table th:nth-child(2) {
      width: 120px;
    }
    table th:nth-child(3) {
      width: 110px;
    }
    table th:nth-child(4) {
      width: auto;
    }
    table th:nth-child(5) {
      width: 100px;
    }
    table th:nth-child(6) {
      text-align: center;
      width: 100px;
    }
    table th:nth-child(7) {
      text-align: center;
      width: 100px;
    }
    table th:nth-child(8) {
      text-align: center;
      text-align: center;
      width: 100px;
    }
    table th:nth-child(9) {
      text-align: center;
      width: 100px;
    }
    table td:nth-child(6) {
      text-align: center;
      padding-right: 22px;
    }
    table td:nth-child(7) {
      text-align: center;
      padding-right: 32px;
    }
    table td:nth-child(8) {
      text-align: center;
      padding-right: 22px;
    }
    table td:nth-child(9) {
      text-align: center;
      padding-right: 32px;
    }
  `,
  rowPadding: css({
    paddingBottom: "16px",
  }),
};

const AttendAchievementLockForm = () => {
  const { errorNotification } = useToastNotification();
  const {
    detailRole,
    isLoading,
    orgTreesOptions,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    orgCode,
    setOrgCode,
    underFlag,
    setUnderFlag,
    lockStatus,
    setLockStatus,
    isError,
    setIsError,
    isAlertOvertime,
    setIsAlertOvertime,
    isAlertHolidaywork,
    setIsAlertHolidaywork,
    isDisapproval,
    setIsDisapproval,
    isLockable,
    setIsLockable,
    attendAchievementLockList,
    selectedRows,
    setSelectedRows,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    lockAttendAchievement,
    unlockAttendAchievement,
  } = useAttendAchievementLock();

  const getPageRows = (
    flatRows: Array<tableRow<AttendAchievementLockDomain>>,
    pageIndex: number,
    pageSize: number,
  ): selectedRow[] => {
    return flatRows.slice(pageIndex * pageSize, pageSize * (pageIndex + 1)).map(
      (r) =>
        ({
          rowIndex: parseInt(r.id),
          targetDate: r.original.targetDate,
          orgCode: r.original.orgCode,
          lockStatus: r.original.lockStatus,
          lockable: r.original.errorCount === 0 && r.original.disapprovalCount === 0,
        } as selectedRow),
    );
  };

  const columns: Array<Column<AttendAchievementLockDomain>> = useMemo(
    () => [
      {
        Header: (cell: { flatRows: Array<tableRow<AttendAchievementLockDomain>>; state: any }) => (
          <div style={{ textAlign: "center", maxHeight: "20px" }}>
            <Checkbox
              id="checkbox_all"
              name="checkbox_all"
              value=""
              checked={(() => {
                const pageRows = getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize);
                return pageRows.length > 0
                  ? pageRows.every((r) => selectedRows.some((s) => s.rowIndex === r.rowIndex))
                  : false;
              })()}
              onChange={(e) => {
                if (!e.target.checked) {
                  setSelectedRows(
                    selectedRows.filter(
                      (s) =>
                        !getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize).some(
                          (r) => r.rowIndex === s.rowIndex,
                        ),
                    ),
                  );
                } else {
                  setSelectedRows([
                    ...selectedRows.filter(
                      (s) =>
                        !getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize).some(
                          (r) => r.rowIndex === s.rowIndex,
                        ),
                    ),
                    ...getPageRows(cell.flatRows, cell.state.pageIndex, cell.state.pageSize),
                  ]);
                }
              }}
            />
          </div>
        ),
        disableSortBy: true,
        accessor: "checkbox",
        id: "",
        Cell: (cell: {
          row: { isExpanded: boolean; original: AttendAchievementLockDomain; index: number };
          data: any;
        }) => (
          <div style={{ textAlign: "center", maxHeight: "20px" }}>
            <Checkbox
              id={`checkbox_${cell.row.index}`}
              name="name"
              label=""
              value={`${cell.row.index}`}
              checked={selectedRows.some((r) => r.rowIndex === cell.row.index)}
              onChange={(e) => {
                if (!e.target.checked) {
                  setSelectedRows(selectedRows.filter((s) => s.rowIndex !== cell.row.index));
                } else {
                  setSelectedRows([
                    ...selectedRows,
                    {
                      rowIndex: cell.row.index,
                      targetDate: cell.row.original.targetDate,
                      orgCode: cell.row.original.orgCode,
                      lockStatus: cell.row.original.lockStatus,
                      lockable: cell.row.original.errorCount === 0 && cell.row.original.disapprovalCount === 0,
                    } as selectedRow,
                  ]);
                }
              }}
            />
          </div>
        ),
      },
      {
        Header: "日付",
        id: "targetDate",
        accessor: (r) => (r.targetDate ? `${moment(r.targetDate).format("YYYY/MM/DD (ddd)")}` : ""),
        sortType: "basic",
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
        sortType: "basic",
      },
      {
        Header: "組織名",
        accessor: "orgName",
        sortType: "basic",
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAchievementLockDomain; index: number } }) => (
          <TextTooltip
            textDis={
              <span
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  display: "block",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cell.row.original.orgName}
              </span>
            }
            text={cell.row.original.orgName}
          />
        ),
      },
      {
        Header: "ロック状態",
        accessor: "lockStatus",
        sortType: "basic",
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAchievementLockDomain } }) => (
          <strong style={cell.row.original.lockStatus === "1" ? { color: "blue" } : { color: "red" }}>
            {cell.row.original.lockStatus === "1" ? "ロック中" : "未ロック"}
          </strong>
        ),
      },
      {
        Header: "勤怠エラー",
        accessor: "errorCount",
        sortType: "basic",
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            {detailRole.linkAvailable1 && cell.row.original.availableDailyShift === 1 ? (
              <Link
                to={{
                  pathname: `/dailyShift/${cell.row.original.orgCode}/${moment(cell.row.original.targetDate).format(
                    "YYYY-MM-DD",
                  )}?isAlertClosingDate=false`,
                }}
                target="_blank"
              >
                {`${cell.row.original.errorCount.toLocaleString()} 件`}
              </Link>
            ) : (
              <span>{`${cell.row.original.errorCount.toLocaleString()} 件`}</span>
            )}
          </React.Fragment>
        ),
      },
      {
        Header: () => <div css={styles.textWrapper}>{`残業\n未申請\nアラート`}</div>,
        accessor: "alertOvertimeApplicationCount",
        sortType: "basic",
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            {detailRole.linkAvailable1 && cell.row.original.availableDailyShift === 1 ? (
              <Link
                to={{
                  pathname: `/dailyShift/${cell.row.original.orgCode}/${moment(cell.row.original.targetDate).format(
                    "YYYY-MM-DD",
                  )}?isAlertClosingDate=false`,
                }}
                target="_blank"
              >
                {`${cell.row.original.alertOvertimeApplicationCount.toLocaleString()} 件`}
              </Link>
            ) : (
              <span>{`${cell.row.original.alertOvertimeApplicationCount.toLocaleString()} 件`}</span>
            )}
          </React.Fragment>
        ),
      },
      {
        Header: () => <div css={styles.textWrapper}>{`休日出勤\n未申請\nアラート`}</div>,
        accessor: "alertHolidayworkApplicationCount",
        sortType: "basic",
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            {detailRole.linkAvailable1 && cell.row.original.availableDailyShift === 1 ? (
              <Link
                to={{
                  pathname: `/dailyShift/${cell.row.original.orgCode}/${moment(cell.row.original.targetDate).format(
                    "YYYY-MM-DD",
                  )}?isAlertClosingDate=false`,
                }}
                target="_blank"
              >
                {`${cell.row.original.alertHolidayworkApplicationCount.toLocaleString()} 件`}
              </Link>
            ) : (
              <span>{`${cell.row.original.alertHolidayworkApplicationCount.toLocaleString()} 件`}</span>
            )}
          </React.Fragment>
        ),
      },
      {
        Header: "承認待ち",
        accessor: "disapprovalCount",
        sortType: "basic",
        Cell: (cell: { row: { isExpanded: boolean; original: AttendAchievementLockDomain } }) => (
          <React.Fragment>
            {detailRole.linkAvailable2 && cell.row.original.disapprovalCount > 0 ? (
              <Link
                to={{
                  pathname: `/attendApplicationApproval/${moment(targetDateFrom).format("YYYY-MM-DD")}/${moment(
                    targetDateTo,
                  ).format("YYYY-MM-DD")}/daily?isAlertClosingDate=false`,
                }}
                target="_blank"
              >
                {`${cell.row.original.disapprovalCount.toLocaleString()} 件`}
              </Link>
            ) : (
              <span>{`${cell.row.original.disapprovalCount.toLocaleString()} 件`}</span>
            )}
          </React.Fragment>
        ),
      },
    ],
    [attendAchievementLockList, selectedRows],
  );

  const handleLock = useCallback(
    async (opneModal: boolean) => {
      if (opneModal) {
        if (selectedRows.length > 1000) {
          errorNotification("勤怠ロックの選択数は1000件までです。");
          return;
        }
        if (selectedRows.some((s) => !s.lockable)) {
          setConfirmModalOpen(true);
          return;
        }
      }
      await lockAttendAchievement();
    },
    [selectedRows, confirmModalOpen],
  );

  const handleUnlock = useCallback(async () => {
    await unlockAttendAchievement();
  }, [selectedRows]);

  const tableId = 1;
  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle bold={true} title="勤怠ロック" />

        <Container fluid={true}>
          <FormPadding customStyle={css({ paddingLeft: "35px", paddingBottom: 0 })}>
            <Row css={styles.rowPadding}>
              <Col lg={2} md={3}>
                <BodyText>対象期間</BodyText>
              </Col>
              <Col md={10}>
                <CalendarRangeV2
                  startDate={targetDateFrom}
                  onChangeStart={(date: Date) => {
                    setTargetDateFrom(date);
                    sessionStorage.setItem(
                      `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                      String(0),
                    );
                  }}
                  endDate={targetDateTo}
                  onChangeEnd={(date) => {
                    if (date) {
                      setTargetDateTo(date);
                      sessionStorage.setItem(
                        `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                        String(0),
                      );
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ marginTop: "12px" }}>
                  <BodyText>ロック状態</BodyText>
                </div>
              </Col>
              <Col md={10}>
                <FlexBox customStyle={css({ flexWrap: "wrap", marginTop: "12px" })}>
                  {[
                    { display: "未ロック", index: 0 },
                    { display: "ロック中", index: 1 },
                    { display: "全て", index: -1 },
                  ].map((item) => (
                    <FlexBoxItem marginRight="10px" key={item.index}>
                      <Radio
                        id={`lock_status_${String(item.index)}`}
                        name={String(item.index)}
                        label={item.display}
                        value={String(item.index)}
                        checked={lockStatus === String(item.index)}
                        onChange={() => {
                          setLockStatus(String(item.index));
                          sessionStorage.setItem(
                            `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                            String(0),
                          );
                        }}
                      />
                    </FlexBoxItem>
                  ))}
                </FlexBox>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ marginTop: "12px" }}>
                  <BodyText>勤怠実績</BodyText>
                </div>
              </Col>
              <Col md={10}>
                <FlexBox customStyle={css({ flexWrap: "wrap" })}>
                  <FlexBoxItem customStyle={css({ marginTop: "15px" })}>
                    <Checkbox
                      id="isError"
                      name="isError"
                      label="勤怠エラー"
                      value={String(isError)}
                      checked={isError}
                      onChange={(e) => {
                        setIsError(e.target.checked);
                        sessionStorage.setItem(
                          `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                          String(0),
                        );
                      }}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={css({ marginTop: "15px", marginLeft: "8px" })}>
                    <Checkbox
                      id="isAlertOvertime"
                      name="isAlertOvertime"
                      label="残業未申請"
                      value={String(isAlertOvertime)}
                      checked={isAlertOvertime}
                      onChange={(e) => {
                        setIsAlertOvertime(e.target.checked);
                        sessionStorage.setItem(
                          `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                          String(0),
                        );
                      }}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={css({ marginTop: "15px", marginLeft: "8px" })}>
                    <Checkbox
                      id="isAlertHolidaywork"
                      name="isAlertHolidaywork"
                      label="休日出勤未申請"
                      value={String(isAlertHolidaywork)}
                      checked={isAlertHolidaywork}
                      onChange={(e) => {
                        setIsAlertHolidaywork(e.target.checked);
                        sessionStorage.setItem(
                          `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                          String(0),
                        );
                      }}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={css({ marginTop: "15px", marginLeft: "8px" })}>
                    <Checkbox
                      id="isDisapproval"
                      name="isDisapproval"
                      label="承認待ち"
                      value={String(isDisapproval)}
                      checked={isDisapproval}
                      onChange={(e) => {
                        setIsDisapproval(e.target.checked);
                        sessionStorage.setItem(
                          `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                          String(0),
                        );
                      }}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem customStyle={css({ marginTop: "15px", marginLeft: "8px" })}>
                    <Checkbox
                      id="isLockable"
                      name="isLockable"
                      label="ロック可能"
                      value={String(isLockable)}
                      checked={isLockable}
                      onChange={(e) => {
                        setIsLockable(e.target.checked);
                        sessionStorage.setItem(
                          `sessionDataTable${tableId}${window.location.pathname}.initPageIndex`,
                          String(0),
                        );
                      }}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </Col>
            </Row>
            <Row>
              <Col md={1} xs={2}>
                <div style={{ marginTop: "12px" }}>
                  <BodyText>組織名</BodyText>
                </div>
              </Col>
              <Col md={3} xs={6}>
                <FlexBox customStyle={css({ marginTop: "12px" })}>
                  <FlexBoxItem>
                    <SelectForm
                      label=""
                      name="orgCode"
                      value={String(orgCode)}
                      setValue={(args) => {
                        setOrgCode(args);
                      }}
                      options={orgTreesOptions}
                      required={false}
                      width="900px"
                      widthLabel="100px"
                      marginRight="30px"
                    />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <Checkbox
                      id={"underFlag"}
                      name={"underFlag"}
                      label={"配下指定"}
                      value={`underFlag`}
                      checked={Boolean(underFlag)}
                      onChange={() => {
                        setUnderFlag(underFlag === 1 ? 0 : 1);
                      }}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </Col>
            </Row>
          </FormPadding>
        </Container>
        <div style={{ overflowX: "auto", whiteSpace: "nowrap", marginBottom: "32px" }}>
          <div css={styles.table}>
            <DataTable
              columns={columns}
              data={attendAchievementLockList}
              isGlobalFilter={true}
              minWidth="632px"
              containerStyle={css({
                overflowX: "auto",
              })}
              tableId={1}
              iconSize="16px"
              sortBy={[{ id: "targetDate", desc: false }]}
            />
          </div>
        </div>
        {detailRole.editable === 1 && attendAchievementLockList.length > 0 && (
          <Container>
            <Row style={{ paddingBottom: "32px" }}>
              <FlexBox alignItems="center" customStyle={css({ width: "100%", justifyContent: "center" })}>
                {detailRole.usabilityGeneralItem1 === 1 && (
                  <FlexBoxItem>
                    <PrimaryButton
                      disabled={selectedRows.length === 0}
                      onClick={async () => await handleLock(true)}
                      ghost={false}
                      text="ロック"
                    />
                  </FlexBoxItem>
                )}
                {detailRole.usabilityGeneralItem2 === 1 && (
                  <FlexBoxItem marginLeft="16px">
                    <SecondaryButton
                      disabled={selectedRows.length === 0}
                      onClick={handleUnlock}
                      ghost={false}
                      text="ロック解除"
                    />
                  </FlexBoxItem>
                )}
              </FlexBox>
            </Row>
          </Container>
        )}
      </FormContents>
      <ConfirmModal
        title="勤怠エラーあり"
        content="勤怠エラー未対応の行が選択されています。<br/>対応が完了するまで勤怠ロックの対象から外れます。"
        submitText="ロック"
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={async () => await handleLock(false)}
        typeSubmit="confirm"
      />
    </BlockUI>
  );
};

export default AttendAchievementLockForm;
