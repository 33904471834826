import React, { SVGProps } from 'react';
 
 const BreakStart: React.FC<SVGProps<SVGSVGElement>> = props => (
   <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g style={{mixBlendMode: 'soft-light'}}>
       <path fillRule="evenodd" clipRule="evenodd" d="M28.4994 3.75C28.4994 2.92157 29.171 2.25 29.9994 2.25C30.8278 2.25 31.4994 2.92157 31.4994 3.75V18.75C31.4994 19.5784 30.8278 20.25 29.9994 20.25C29.171 20.25 28.4994 19.5784 28.4994 18.75V3.75ZM12 26.25H48V38.25C48 48.1912 39.9411 56.25 30 56.25C20.0589 56.25 12 48.1912 12 38.25V26.25ZM9 26.25C9 24.5932 10.3431 23.25 12 23.25H48C49.1104 23.25 50.0799 23.8533 50.5986 24.75H52.5C57.4706 24.75 61.5 28.7794 61.5 33.75C61.5 38.7206 57.4706 42.75 52.5 42.75H50.5167C48.4567 52.1856 40.0534 59.25 30 59.25C18.402 59.25 9 49.848 9 38.25V26.25ZM50.9473 39.75H52.5C55.8137 39.75 58.5 37.0637 58.5 33.75C58.5 30.4363 55.8137 27.75 52.5 27.75H51V38.25C51 38.7544 50.9822 39.2546 50.9473 39.75ZM10.5 62.25C9.67157 62.25 9 62.9216 9 63.75C9 64.5785 9.67157 65.25 10.5 65.25H49.5C50.3284 65.25 51 64.5785 51 63.75C51 62.9216 50.3284 62.25 49.5 62.25H10.5ZM37.5006 5.25004C38.329 5.25004 39.0006 5.92161 39.0006 6.75004V15.75C39.0006 16.5785 38.329 17.25 37.5006 17.25C36.6722 17.25 36.0006 16.5785 36.0006 15.75V6.75004C36.0006 5.92161 36.6722 5.25004 37.5006 5.25004ZM23.9994 8.25C23.9994 7.42157 23.3278 6.75 22.4994 6.75C21.671 6.75 20.9994 7.42157 20.9994 8.25V14.25C20.9994 15.0784 21.671 15.75 22.4994 15.75C23.3278 15.75 23.9994 15.0784 23.9994 14.25V8.25Z" fill="white"/>
     </g>
   </svg>
 
 );
 
 export default BreakStart;