import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getList } from 'api/termsMaster';

/**
 * 業態の選択ボックス用データを取得
 */
export const useTermOptions = (): Array<OptionType> => {
  const [termsOptions, setTermsOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getList().then((Terms) => {
      const termOption = Terms.map((term) => ({
        value: term.termId,
        label: term.termName,
      }));
      termOption.unshift({
        value: '',
        label: '-',
      });
      setTermsOptions(termOption);
    });
  }, []);
  return termsOptions;
};

export default useTermOptions;
