import moment from 'moment';
/**
 * API StampHistory
 */


export interface StampHistory {
    StampHistoryId: string;
    staffCode: string,
    stampTime: Date;
    cardId: string;
    stampType: number,
    stampMode: number,
    stampModeName: string;
    stampName: string;
    photoFullpath: string,
    staffName: string;
    orgCode: string;
    orgName: string;
    errorMessage: string;

}


export default class StampHistoryDomain {
  constructor(private rawData: StampHistory) {
    // do nothing
  }

  static generateInitial(): StampHistoryDomain {
    return new StampHistoryDomain({
      StampHistoryId: '',
      cardId: '',
      stampTime: new Date(),
      stampModeName: '',
      stampName: '',
      staffName: '',
      staffCode: '',
      photoFullpath: '',
      stampType: 0,
      stampMode: 0,
      orgCode: '',
      orgName: '',
      errorMessage: '',

    });
  }

  getRawData(): StampHistory {
    return this.rawData;
  }


  get StampHistoryId(): string {
    return this.rawData.StampHistoryId;
  }

  set StampHistoryId(StampHistoryId: string) {
    this.rawData.StampHistoryId = StampHistoryId;
  }

  get stampTime(): Date {
    return this.rawData.stampTime;
  }

  get stampTimeStr(): string {
    return moment(moment(this.rawData.stampTime).toDate()).format('YYYY/MM/DD HH:mm:ss');
  }

  set stampTime(date: Date) {
    this.rawData.stampTime = date;
  }

  get stampModeName():string {
    return this.rawData.stampModeName;
  }

  set stampModeName(stampModeName:string) {
    this.rawData.stampModeName = stampModeName;
  }

  get stampName():string {
    return this.rawData.stampName;
  }

  set stampName(stampName:string) {
    this.rawData.stampName = stampName;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get cardId():string {
    return this.rawData.cardId;
  }

  set cardId(cardId:string) {
    this.rawData.cardId = cardId;
  }

  get photoFullpath():string {
    return this.rawData.photoFullpath;
  }

  set photoFullpath(photoFullpath:string) {
    this.rawData.photoFullpath = photoFullpath;
  }


  get stampType():number {
    return this.rawData.stampType;
  }

  get stampTypeStr():string {
    if (this.rawData.stampType === -1) {
      return '-';
    }
    return String(this.rawData.stampType);
  }

  set stampType(stampType:number) {
    this.rawData.stampType = stampType;
  }

  get stampMode():number {
    return this.rawData.stampMode;
  }

  set stampMode(stampMode:number) {
    this.rawData.stampMode = stampMode;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get errorMessage(): string {
    return this.rawData.errorMessage;
  }

  set errorMessage(errorMessage: string) {
    this.rawData.errorMessage = errorMessage;
  }
}
