/** @jsx jsx */

import React, { useEffect, useState } from "react";
import Appbar from "components/organismos/Appbar";
import useLoginUser from "hooks/useLoginUser";
import { useHistory, useLocation } from "react-router-dom";
import Icon, { IconType } from "components/atoms/Icon";
import { css, jsx } from "@emotion/core";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Store } from "modules/store";
import { menuList, MenuListType, defaultFunction } from "./menuDefault";
import { useContext } from "react";
import { PrivateContext } from "PrivateRoute";
import SideMenu from "components/molecules/SideMenu";
import { MenuItem } from "components/molecules/SideMenu/SideMenu";
import StaffSettingPanel from "components/organismos/Appbar/StaffSettingPanel";
import useComponentVisible from "hooks/useComponentVisible";
import useIsMobile from "hooks/useIsMobile";
import { MobileSidebar } from "components/templates/MobileSidebar";
import moment from "moment";

const styles = {
  menu: css({
    display: "flex",
    justifyContent: "row",
    position: "fixed",
    top: 56,
    left: 0,
    height: "calc(100dvh - 56px - 65px)",
    width: 292,
  }),
};

const checkActiveMenu = (list: Array<MenuListType>, group: string) => {
  return list.findIndex((menu: any) => {
    return menu.child.some((item: any) => {
      return item.value === group;
    });
  });
};

const filterMenuByFunctionList = (list: Array<MenuListType>, functionCodeList: Array<string>) => {
  let menuFilter = list
    .map((menu) => {
      menu.child = menu.child.filter((item) => {
        return functionCodeList?.includes(item.functionCode);
      });
      return menu;
    })
    .filter((i) => i.child.length > 0);
  return menuFilter;
};

const SidebarTemplate: React.FC<{
  pageTitle?: string;
}> = ({ children, pageTitle }) => {
  const { staffName } = useLoginUser();
  const { groupUrl } = useContext(PrivateContext);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const history = useHistory();

  let functionCodeList = useSelector((state: Store) => state.ui.general.loginUser.functionCodeList)?.concat(
    defaultFunction,
  );

  let menuListFilter = filterMenuByFunctionList(JSON.parse(JSON.stringify(menuList)), functionCodeList);

  const [menuActive, setMenuActive] = useState(menuListFilter);

  const [menuTop, setMenuTop] = useState<{ value: string; display: string; icon: IconType | undefined }[]>([]);
  const [itemTabbar, setItemTabbar] = useState<any>([]);
  const customRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const [sideBarWidth, setSideBarWidth] = useState(isMobile ? 0 : 292);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean | null>(null);

  const resetSession = () => {
    sessionStorage.setItem("attendStampList.attendFlag", String(0));
    sessionStorage.setItem("isGoBack", String(1));
    sessionStorage.setItem("attendError.orgCode", "");
    sessionStorage.removeItem("attendError.staffCode");

    // 月次勤怠用リセット
    sessionStorage.setItem(`attendStampList.monthlyShift.viewPeriod`, "");
    sessionStorage.setItem("attendStampList.monthlyShift.targetDateFrom", "");
    sessionStorage.setItem("attendStampList.monthlyShift.targetDateTo", "");
    sessionStorage.setItem(`attendStampList.monthlyShift.employmentOptions`, "all");
    sessionStorage.setItem("attendStampList.monthlyShift.orgCode", sessionStorage.getItem("loginUser.orgCode") ?? "");

    // 日次勤怠用リセット
    sessionStorage.setItem(
      `attendStampList.dailyShift.targetDateFrom`,
      moment()
        .startOf("day")
        .format("YYYY-MM-DD"),
    );
    sessionStorage.setItem("attendStampList.dailyShift.targetDateFrom", "");
    sessionStorage.setItem(`attendStampList.dailyShift.employmentOptions`, "all");
    sessionStorage.setItem("attendStampList.dailyShift.orgCode", sessionStorage.getItem("loginUser.orgCode") ?? "");
    sessionStorage.setItem("attendStampList.dailyShift.cumulativeClosingDate", "0");
  };

  useEffect(() => {
    setSideBarWidth(isMobile ? 0 : 292);
  }, [isMobile]);

  useEffect(() => {
    let menuTop = menuActive.map((menu, index) => {
      return {
        value: String(index),
        display: menu.label,
        icon: menu.icon,
      };
    });

    setMenuTop(menuTop);
    let tmpItemTabbar =
      menuActive
        .find((menu) => {
          return menu.child.some((item) => {
            return item.value === groupUrl;
          });
        })
        ?.child.map((i) => {
          return {
            value: i.value,
            display: i.label,
          };
        }) ??
      menuActive[0]?.child.map((i) => ({
        value: i.value,
        display: i.label,
      }));
    setItemTabbar(tmpItemTabbar || []);
  }, [menuActive, groupUrl]);

  return (
    <React.Fragment>
      <Appbar
        staffName={staffName}
        title={pageTitle ?? ""}
        showMobileMenu={showMobileMenu}
        changeVisibleEvent={(visible: boolean) => {
          if (isMobile) {
            setShowMobileMenu(visible);
          } else {
            setIsComponentVisible(visible);
          }
        }}
      />

      <div css={css({ display: "flex", flexDirection: "row" })}>
        {!isMobile && (
          <SideMenu handleOpen={(width) => setSideBarWidth(width)}>
            <div css={[styles.menu]}>
              <div
                css={{
                  overflowY: "auto",
                  width: "50%",
                }}
              >
                {menuTop.map((item) => (
                  <MenuItem
                    key={item.value}
                    css={css({
                      backgroundColor:
                        checkActiveMenu(menuActive, groupUrl).toString() === item.value ? "#87CEFA" : "",
                    })}
                    onClick={() => {
                      let targetURI: string;
                      if (Number(item.value) < menuActive.length) {
                        targetURI = menuActive[Number(item.value)]?.child[0].value;
                      } else {
                        if (JSON.stringify(menuActive) === JSON.stringify(menuListFilter)) {
                          targetURI = menuListFilter[0]?.child[0].value;
                        } else {
                          targetURI = menuListFilter[0]?.child[0].value;
                        }
                      }
                      toast.dismiss();

                      resetSession();

                      history.push(`/${targetURI}`);
                    }}
                  >
                    <div
                      css={css({
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      })}
                    >
                      {item.icon && <Icon type={item.icon} size="24px" color="" />}
                      {item.display}
                    </div>
                  </MenuItem>
                ))}
              </div>
              <div
                css={css({
                  height: "calc(100dvh - 56px - 65px)",
                  borderLeft: "1px solid #ddd",
                  overflowY: "auto",
                  width: "50%",
                })}
              >
                {itemTabbar.map((item: any) => (
                  <MenuItem
                    key={item.value}
                    css={css({
                      color: groupUrl === item.value ? "#0047FF" : "black",
                    })}
                    onClick={() => {
                      resetSession();

                      history.push(`/${item.value}`);
                      toast.dismiss();
                    }}
                  >
                    {item.display}
                  </MenuItem>
                ))}
              </div>
            </div>
          </SideMenu>
        )}

        <div
          ref={customRef}
          className="content-container"
          css={css({
            animation: "0.5s forwards",
            transition: "0.5s",
            boxSizing: "border-box",
            position: "fixed",
            top: 65,
            left: sideBarWidth,
            right: 0,
            bottom: 0,
            overflowY: "auto",
            zIndex: 100,
          })}
        >
          {children}
        </div>
      </div>

      <div ref={ref} css={css({ position: "absolute", top: 0, right: "2rem" })}>
        {isComponentVisible && <StaffSettingPanel staffName={staffName} />}
      </div>
      {isMobile && <MobileSidebar isMenuOpen={showMobileMenu} setMenuOpen={(open) => setShowMobileMenu(open)} />}
    </React.Fragment>
  );
};

export default SidebarTemplate;
