/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { useOrgTreesByCategoryNotAllOptions } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import useMonthlyReport, { useGetListMonthlyReport } from './hooks';
import MonthlySeparateForeCastManagemenTable from './MonthlySeparateForeCastManagemenTable';
import MonthlyDaySeparateSalesManagement from './MonthlyDaySeparateSalesManagement';
import SelectForm from 'components/molecules/SelectForm';

const SeparateBillLayout = () => {
  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0, 23, 59, 59)); // get last date
  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  const [isShowContentAction, setIsShowContentAction] = useState(false);

  const orgTreesOptions = useOrgTreesByCategoryNotAllOptions(
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
  );

  const [getOrgName, setOrgName] = useState('');
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : '');

  useEffect(() => {
    if (orgCode) {
      orgTreesOptions.map((data) => {
        if (data.value === orgCode) {
          return setOrgName(data.label);
        }
      });
    }
  });

  const [getMonthlyComparePrevYear, setMonthlyComparePrevYear] = useState(0);

  const {
    getMediaMst, monthlyReport, getBudgetPerfomanceManagement, getIsLoading,
  } = useMonthlyReport(String(orgCode), moment(targetDateFrom).format('YYYY/MM/DD'), moment(targetDateTo).format('YYYY/MM/DD'), getMonthlyComparePrevYear);
  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;

  const {
    action,
    setAction,
    ActionType,
  } = useGetListMonthlyReport();


  const { errorNotification } = useToastNotification();

  const getFetchOption = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      setIsShowContentAction(false);
      return false;
    }
    setAction(ActionType.getFetchOption);
    setIsShowContentAction(true);
  };

  const getFetchOptionforeCast = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      setIsShowContentAction(false);
      return false;
    }
    setAction(ActionType.getFetchOptionforeCast);
    setIsShowContentAction(true);
  };

  return (
    <div>
      <FormContents>
        <div>
          <FlexBox>
            <FlexBoxItem width="105px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => { setTargetDateFrom(date); }}
                isFullWidth={true}
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span style={{ margin: '0 30px' }}>～</span>
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateTo}
                changeDate={(date: Date) => { setTargetDateTo(date); }}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>
        </div>
        <FlexBox>
          <FlexBoxItem width="112px">
            <FormLabel
              label="組織名"
            />
          </FlexBoxItem>
          <FlexBoxItem width="100%">
            <SelectForm
              label=""
              name="orgCode"
              value={String(orgCode)}
              setValue={(val: any) => {
                setOrgCode(val);
              }}
              options={orgTreesOptions}
            />
          </FlexBoxItem>
        </FlexBox>
        <FlexBox>
          <FlexBoxItem width="105px">
            <FormLabel label="前年比較対象" />
          </FlexBoxItem>
          <FlexBoxItem>
            <RadioSelectForm
              label=""
              name="monthlyComparePrevYear"
              items={[
                { label: '同曜日', value: '0' },
                { label: '同日', value: '1' },
              ]}
              value={String(getMonthlyComparePrevYear)}
              setValue={(e) => setMonthlyComparePrevYear(Number(e.target.value))}
            />
          </FlexBoxItem>
        </FlexBox>
        <FormField>
          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <PrimaryButton
                text="売上管理表 "
                onClick={getFetchOption}
              />
            </div>
            <PrimaryButton
              text="予実管理表 "
              onClick={getFetchOptionforeCast}
            />
          </FormSubmitArea>
        </FormField>

        <div>
          {isShowContentAction && action === ActionType.getFetchOption
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlyDaySeparateSalesManagement
                   monthlyReport={monthlyReport}
                   targetPeriodData={targetPeriodData}
                   getMediaMst={getMediaMst}
                   compareToPreviousYear={(getMonthlyComparePrevYear)}
                   orgName={getOrgName}
                 />
               </BlockUI>
             </div>
           </div>
           )}

          {isShowContentAction && action === ActionType.getFetchOptionforeCast
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlySeparateForeCastManagemenTable
                   getBudgetPerfomanceManagement={getBudgetPerfomanceManagement}
                   targetPeriodData={targetPeriodData}
                   compareToPreviousYear={(getMonthlyComparePrevYear)}
                   orgName={getOrgName}
                 />
               </BlockUI>
             </div>
           </div>
           )}

        </div>

      </FormContents>
    </div>
  );
};

export default SeparateBillLayout;
