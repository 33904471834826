import { useState, useCallback, useEffect, useContext } from 'react';

import {
  getDigestionItemsForStockPaidHolidayDigestedtHistory,
  getStockDigestionItemsDetail,
  downloadStockPaidHolidayDigestionCsv
} from 'api/holidayManagement';
import StockPaidHolidayDigestedtHistoryDomain from 'domain/master/holidayManagement/stockpaidHolidayDigestedtHistory';
import useToastNotification from 'hooks/useToastNotification';
import { HolidayManagementContext } from './../../HolidayManagementForm'
import { useStaffOptions } from 'hooks/useEmploymentOptions';
import { OptionType } from 'components/atoms/Select';


export interface DetailItems {
  cnt: string,
  staffCode: string,
  staffName: string,
  orgCode: string,
  orgName: string,
  fromDate: Date,
  toDate: Date,
  stockPaidHolidayDigestionDetailList: [{
    targetDate: Date,
    digestionDaysNum: string
  }],
}
export interface DataDetail {
  staffCode: string,
  staffName: string,
}
export const useAcquisitionStatusDomainForm = () => {

  const [noManager, setNoManager] = useState(false);
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);
  const [modalOpen, setModalOpen] = useState(false);
  const yearCurrent = new Date().getFullYear().toString();
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(new Date(`${yearCurrent}/01/01`));
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(`${yearCurrent}/12/31`));

  const [paidHolidayDigestedtHistoryList, setPaidHolidayDigestedtHistoryList] = useState<Array<StockPaidHolidayDigestedtHistoryDomain>>([]);

  const [detailItems, setDetailItems] = useState<DetailItems>({
    cnt: '',
    staffCode: '',
    staffName: '',
    orgCode: '',
    orgName: '',
    fromDate: targetDateFrom,
    toDate: targetDateTo,
    stockPaidHolidayDigestionDetailList: [{
      targetDate: new Date(),
      digestionDaysNum: '1.5'
    }],
  });

  const [dataDetail, setDataDetail] = useState<DataDetail>({ staffCode: '', staffName: '' });
  let staffOptions: Array<OptionType> = useStaffOptions(orgCode || 'all', targetDateFrom, targetDateTo);
  const { errorNotification } = useToastNotification();

  const contextObject = useContext(HolidayManagementContext);
  const [underFlag, setUnderFlag] = useState(0);
  // データの取得
  const fetchData = useCallback(async () => {
    contextObject.setIsLoading(true);
    const response = await getDigestionItemsForStockPaidHolidayDigestedtHistory(
      orgCode !== 'all' ? orgCode : '',
      targetDateFrom,
      targetDateTo,
      underFlag,
    );
    contextObject.setIsLoading(false);
    setPaidHolidayDigestedtHistoryList(response.map((result) => new StockPaidHolidayDigestedtHistoryDomain(result)));
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, orgCode, underFlag]);

  // const toggleNoManager = useCallback(() => {
  //   setNoManager(!noManager);
  // }, [noManager]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //
  const callGetAcquisitionStatusDetail = useCallback(async (staffCode: string, targetDateFrom: Date, targetDateTo: Date) => {
    try {
      await getStockDigestionItemsDetail(
        staffCode,
        targetDateFrom,
        targetDateTo
      ).then((response: any) => {
        setDetailItems(response);
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }, [errorNotification]);

  return {
    paidHolidayDigestedtHistoryList,
    callGetAcquisitionStatusDetail,
    // toggleNoManager,
    orgCode,
    setOrgCode,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    staffOptions,
    underFlag, setUnderFlag,
    dataDetail,
    setDataDetail,
  };
};

export const useDownloadHolidayManagementAcquisitionStatus = (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  underFlag: number,

) => {

  const [blocking, setBlocking] = useState(false);

  const downloadHolidayManagementAcquisitionStatus = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadStockPaidHolidayDigestionCsv(
      isDownloadAll ? '' : orgCode,
      targetDateFrom,
      targetDateTo,
      underFlag,
    ).then((response) => {
      setBlocking(false);
    });
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);



  return {
    downloadHolidayManagementAcquisitionStatus,
    blocking,
  };
};

export default {
  useAcquisitionStatusDomainForm,
};
