/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-array-constructor */

import { useState, useEffect, useCallback } from 'react';
import { OptionType } from 'components/atoms/Select';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import AttendAgreement36ApplicationDomain from 'domain/master/attend/attendAgreement36Application';
import { getAgreement36Application, postAgreement36Application } from 'api/attendAgreement36Application';
import useToastNotification from 'hooks/useToastNotification';
import { useAllOrgTrees } from 'hooks/useOrgTreesOptions';
import { getApplyingAgreement36ListHistory } from 'api/getHistoryApplication';
import { getTreeCurrentByStaff } from 'api/organization';

export const useAttendAgreement36Application = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [targetDate, setTargetDate] = useState(new Date(sessionStorage.getItem('agreement36.applyTargetDate') || new Date()));
  const [dispStaffName] = useState('');
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');
  const [selectOrgCodeName, setSelectOrgCodeName] = useState(sessionStorage.getItem('loginUser.orgName') || '');

  const orgTreesOptions = useAllOrgTrees();
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [isGetOvertimeSucceed, setIsGetOvertimeSucceed] = useState(true);
  const [selectChangelog, setSelectChangelog] = useState<string>("0");

  // submit formik
  const onSubmit = async (values: any) => {

    if (!values.applicationReason) {
      errorNotification('申請理由を入力してください。');
      return;
    }

    if (!confirmApplyModalOpen) {
      setConfirmApplyModalOpen(true);
      return;
    }

    const {
      modifierApplicationId, version, orgName, buttonType, applicationReason, staffCode, orgCode,
      applicationExpectedOvertimeHoursStr, targetDateStr,
    } = values.getRawData();

    const dataPost = {
      modifierApplicationId: modifierApplicationId || '',
      version: version || '',
      orgCode,
      orgName,
      staffCode,
      buttonType,
      targetDateStr,
      applicationExpectedOvertimeHoursStr,
      updateUserCode: sessionStorage.getItem('loginUser.staffCode') || '',
      applicationReason,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };

    try {
      setLoading(true);
      setConfirmApplyModalOpen(false);
      const response = await postAgreement36Application(dataPost);
      if (!response.errors) {
        successNotification('申請しました。');
        if(history.location.pathname === '/attendApplicationList/attendAgreement36Application') {
            history.goBack();
        } else {
            history.push('/agreement36');
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmApplyModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }

  // config formik
  const formik = useFormik({
    initialValues: AttendAgreement36ApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const fetchChangeLogAgreement36 = async (
    orgCode?: string,
    targetDate?: string,
    staffCode?: string
  ): Promise<any[]> => {
    return await getApplyingAgreement36ListHistory({ orgCode, targetDate, staffCode });
  };

  useEffect(() => {
    setLoading(true);
    formik.setFieldValue('targetDate', targetDate); 
    const dateCurrent = moment().set('month', targetDate.getMonth()).format("YYYY-MM-DD");
    getTreeCurrentByStaff(sessionStorage.getItem('loginUser.staffCode') || '', dateCurrent, dateCurrent).then((orgCurrent) => {
      if(orgCurrent) {  
        setSelectOrgCode(orgCurrent[0].orgCode);
        setSelectOrgCodeName(orgCurrent[0].orgName);
      }
    });

    getAgreement36Application({
      orgCode: selectOrgCode,
      targetDate: moment(targetDate).format('YYYY-MM-01'),
      staffCode: sessionStorage.getItem('loginUser.staffCode'),
    }).then((data: any) => {
      formik.setValues(new AttendAgreement36ApplicationDomain(data));
      setIsGetOvertimeSucceed(true);
      setLoading(false);
    }).catch((error) => {
      formik.setValues(AttendAgreement36ApplicationDomain.generateInitial());
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }

      setIsGetOvertimeSucceed(false);
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgCode, sessionStorage.getItem('loginUser.staffCode'), targetDate]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  return {
    formik,
    orgTreesOptions,
    selectOrgCode,
    setSelectOrgCode,
    targetDate,
    setTargetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetOvertimeSucceed,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogAgreement36,
    selectOrgCodeName
  };
};

export default useAttendAgreement36Application;
