/** @jsx jsx */
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Column } from "react-table";
import { useHistory } from "react-router-dom";
import PrimaryButton, { SubActionButton } from "components/atoms/Button";
import { ActionButton } from "components/atoms/Button/SubActionButton";
import DataTable from "components/organismos/DataTable/DataTable";
import { sortItems } from "components/organismos/DataTable/SortTypeCustom";
import AttendHeadQuartersFinalDomain from "domain/master/attend/attendHeadquartersFinal";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Container, Row, Col } from "react-grid-system";
import { css, jsx } from "@emotion/core";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import IconLabelButton from "components/molecules/IconLabelButton";
import moment from "moment";
import BodyText from "components/atoms/BodyText";
import BlockUI from "components/molecules/BlockUi";
import useToastNotification from "hooks/useToastNotification";
import FormPadding from "components/atoms/Form/FormPadding";
import {
  useAchievementConfirmList,
  useGetClosingList,
  useGetEmployments,
  useGetOutputLayouts,
} from "./hooks";
import {
  achievementConfirm,
  getCsvOutput,
  getErrorInfoCsv,
  postProcessingDownloadAchievement,
} from "../../../../../api/achievementConfirm";
import CalendarForm from "components/molecules/CalendarForm";
import { grayScale, textFontSize } from "components/styles";
import { Dropdown } from "react-bootstrap";
import IconTooltip from "components/molecules/IconTooltip";
import { utilityColor } from "components/styles";
import TextTooltip from "components/molecules/TextTooltip";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import CalendarRangeV2 from "components/molecules/CalendarV1/CalendarRangeV2";
import SalaryHistoryDownloadForm from "./SalaryHistoryDownloadForm";
import { OptionType } from "components/atoms/Select";
import Modal from "components/molecules/Modal";

type contextType = {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const HeadquartersFinalFormContext = createContext<contextType>(
  {} as contextType
);

const HeadquartersFinalForm: React.FC<{
  targetMonthProp: Date;
  targetDateFromProp: Date;
  targetDateToProp: Date;
}> = ({ targetMonthProp, targetDateFromProp, targetDateToProp }) => {
  const history = useHistory();
  const [allConfirm, setAllConfirm] = useState(false);
  const [allCsvOutput, setAllCsvOutput] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [isAllConfirmProcessing, setIsAllConfirmProcessing] = useState(false);
  // const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [targetMonth, setTargetMonth] = useState(
    sessionStorage.getItem("headquartersFinal.searchTargetMonth")
      ? new Date(sessionStorage.getItem("headquartersFinal.searchTargetMonth")!)
      : targetMonthProp
  );
  const [targetDateFrom, setTargetDateFrom] = useState(
    sessionStorage.getItem("headquartersFinal.searchTargetDateFrom")
      ? new Date(
          sessionStorage.getItem("headquartersFinal.searchTargetDateFrom")!
        )
      : targetDateFromProp
  );
  const [targetDateTo, setTargetDateTo] = useState(
    sessionStorage.getItem("headquartersFinal.searchTargetDateTo")
      ? new Date(
          sessionStorage.getItem("headquartersFinal.searchTargetDateTo")!
        )
      : targetDateToProp
  );
  const [selectedClosingDate, setSelectedClosingDate] = useState(
    sessionStorage.getItem("headquartersFinal.selClosingDate") || "-1"
  );
  const [selectedEmploymentId, setSelectedEmploymentId] = useState(
    sessionStorage.getItem("headquartersFinal.searchEmploymentId") || ""
  );
  const [outputLayoutId, setOutputLayoutId] = useState(
    sessionStorage.getItem("headquartersFinal.searchOutputLayout") || ""
  );
  const [viewPeriod, setViewPeriod] = useState(
    sessionStorage.getItem("headquartersFinal.viewPeriod") || "monthly"
  );
  const { errorNotification, successNotification } = useToastNotification();
  const [isCancelRequest, setIsCancelRequest] = useState<boolean>(false);
  const { outputLayoutList } = useGetOutputLayouts(setOutputLayoutId);
  const { closingDateList } = useGetClosingList(
    viewPeriod,
    targetMonth,
    targetDateTo
  );
  const { employmentList } = useGetEmployments(
    viewPeriod,
    targetMonth,
    targetDateFrom,
    targetDateTo,
    selectedClosingDate
  );
  const {
    achievementConfirmList,
    setAchievementConfirmList,
    isLoading,
    setLoading,
    salaryHistoryDownloadList,
    setReloadHistory,
    setSalaryHistoryDownloadList,
    detailRole,
  } = useAchievementConfirmList(
    viewPeriod,
    setAllConfirm,
    setAllCsvOutput,
    targetMonth,
    targetDateFrom,
    targetDateTo,
    selectedEmploymentId,
    selectedClosingDate,
    errorNotification
  );

  const styles = {
    globalSearch: css({
      marginLeft: "30px",
      marginBottom: "10px",
    }),
  };

  const handleCsvOutput: (
    item: any,
    type: any,
    downloadAll?: number
  ) => void = useCallback(
    (item, type, downloadAll) => {
      let fileName = "勤怠データ(";
      if (viewPeriod === "monthly") {
        if (item) {
          fileName += `${moment(item.rawData.dateFrom, "YYYY-MM-DD").format(
            "YYYYMMDD"
          )}-${moment(item.rawData.dateTo, "YYYY-MM-DD").format("YYYYMMDD")})`;
        } else {
          fileName += `${moment(targetMonth).format("YYYYMM")})`;
        }
      } else {
        fileName += `${moment(targetDateFrom).format("YYYYMMDD")}-${moment(
          targetDateTo
        ).format("YYYYMMDD")})`;
      }

      if (item) {
        fileName = `${fileName}_${item.rawData.orgName}`;
      }

      const { label } = outputLayoutList.filter(
        (layout: OptionType) => layout.value === outputLayoutId
      )[0];
      if (label) {
        const labelLayout: string = label;
        fileName += "_" + labelLayout.replace(" ", "_");
      }

      if (Number(type) === 1) {
        fileName += "_" + "勤務スタッフ出力";
      } else {
        fileName += "_" + "所属スタッフ出力";
      }

      // Case download all org
      if (downloadAll) {
        fileName += "(全組織)";
      } else {
        fileName += "(組織単位)";
      }

      let dateFrom = moment(targetMonth).format("YYYY-MM-01");
      let dateTo = moment(targetMonth).format("YYYY-MM-01");
      let closingDate = selectedClosingDate;
      const createUser = sessionStorage.getItem("loginUser.staffCode") || "";
      if (item) {
        dateFrom = item.rawData.dateFrom;
        dateTo = item.rawData.dateTo;
        closingDate = item.rawData.closingDate;
      } else if (viewPeriod === "daily") {
        dateFrom = moment(targetDateFrom).format("YYYY-MM-DD");
        dateTo = moment(targetDateTo).format("YYYY-MM-DD");
      }

      let targetYmd = "";
      if (viewPeriod === "monthly") {
        targetYmd = moment(targetMonth).format("YYYY-MM-01");
      }

      let orgCode = "";
      if (item) {
        orgCode = item.rawData.orgCode;
      }

      const param = {
        targetDateFrom: dateFrom,
        targetDateTo: dateTo,
        attendFlag: type === 2 ? 0 : type,
        employmentId: selectedEmploymentId || "",
        viewPeriod,
        closingDate,
        orgCode,
        outputLayoutId,
        targetYmd,
        flagDownloadAll: downloadAll ? 1 : 0,
        pageId: 1,
        createUser: createUser,
        isUpdate: 0,
        underFlag: 0,
      };

      setLoading(true);
      postProcessingDownloadAchievement({
        ...param,
        ...{
          targetDate: "",
          staffCode: "",
          achievementDownloadId: "",
          fileName: fileName,
        },
      })
        .then((response: any) => {
          setReloadHistory(true);
          setLoading(false);
          getCsvOutput(
            {
              ...param,
              ...{
                achievementDownloadId:
                  response?.attendAchievementDownloadId || "",
                fileName: response?.file.split("/")[2],
              },
            },
            fileName
          )
            .then((response: any) => {
              setReloadHistory(true);
            })
            .catch((error) => {
              setLoading(false);
              setReloadHistory(true);
              const { response } = error;
              if (!isCancelRequest) {
                if (response.status === 400) {
                  if (response.data.errors && response.data.errors.length > 0) {
                    errorNotification(
                      response.data.errors
                        .map((x: any) => x.defaultMessage)
                        .join("<br/>")
                    );
                  } else if (response.data.defaultMessage) {
                    errorNotification(response.data.defaultMessage);
                  } else {
                    errorNotification(response.data.message);
                  }
                } else {
                  errorNotification("サーバー側でエラーが発生しました。");
                }
              } else {
                setIsCancelRequest(false);
              }
            });
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            let msgError = "";
            error.response.data.errors.map(
              (item: { defaultMessage: string }) => {
                msgError += `${item.defaultMessage} \n`;
                return msgError;
              }
            );
            errorNotification(msgError);
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
        });
    },
    [
      errorNotification,
      outputLayoutId,
      selectedClosingDate,
      selectedEmploymentId,
      setLoading,
      targetDateFrom,
      targetDateTo,
      targetMonth,
      viewPeriod,
    ]
  );

  const handleDispMonthlyList: (item: any, type: any) => void = useCallback(
    (item, type) => {
      sessionStorage.setItem(
        "headquartersFinal.searchTargetDateFrom",
        moment(targetDateFrom).format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "headquartersFinal.searchTargetDateTo",
        moment(targetDateTo).format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "headquartersFinal.searchTargetMonth",
        moment(targetMonth).format("YYYY-MM-01")
      );
      sessionStorage.setItem(
        "headquartersFinal.searchEmploymentId",
        selectedEmploymentId
      );
      sessionStorage.setItem(
        "headquartersFinal.searchOutputLayout",
        outputLayoutId
      );
      sessionStorage.setItem("headquartersFinal.viewPeriod", viewPeriod);
      sessionStorage.setItem(
        "headquartersFinal.selClosingDate",
        selectedClosingDate
      );
      sessionStorage.setItem(
        "headquartersFinal.selectOrgCode",
        item.rawData.orgCode
      );
      sessionStorage.setItem(
        "headquartersFinal.selectOrgName",
        item.rawData.orgName
      );

      sessionStorage.setItem(
        "headquartersFinal.monthlyList.searchTargetDateFrom",
        moment(item.rawData.dateFrom, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "headquartersFinal.monthlyList.searchTargetDateTo",
        moment(item.rawData.dateTo, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
      sessionStorage.setItem(
        "headquartersFinal.monthlyList.selClosingDate",
        item.rawData.closingDate
      );
      sessionStorage.setItem(
        "headquartersFinal.monthlyList.searchEmploymentId",
        selectedEmploymentId
      );
      sessionStorage.setItem(
        "headquartersFinal.monthlyList.selectOrgCode",
        item.rawData.orgCode
      );
      sessionStorage.setItem(
        "headquartersFinal.monthlyList.selectOrgName",
        item.rawData.orgName
      );
      sessionStorage.setItem("headquartersFinal.monthlyList.attendFlag", type);

      sessionStorage.setItem(
        "attendStampList.targetMonth",
        moment(targetMonth).format("YYYY-MM-01")
      );
      sessionStorage.setItem("attendStampList.viewPeriod", viewPeriod);
      history.push("/attendHeadquartersFinal/attendMonthlyList");
    },
    [
      history,
      outputLayoutId,
      selectedClosingDate,
      selectedEmploymentId,
      targetDateFrom,
      targetDateTo,
      targetMonth,
      viewPeriod,
    ]
  );

  const sortBy = React.useMemo(
    () => [
      {
        id: "orgCode",
        desc: false,
      },
    ],
    []
  );

  const handleConfirm: (item: any, type: any) => void = useCallback(
    (item, type) => {
      const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
      let param;

      if (item) {
        param = {
          targetDateFrom: item.rawData.dateFrom,
          targetDateTo: item.rawData.dateTo,
          staffCode,
          employmentId: selectedEmploymentId,
          closingDate: item.rawData.closingDate,
          orgCode: item.rawData.orgCode,
          searchClosingDate: selectedClosingDate,
        };
      } else {
        param = {
          targetDateFrom: moment(targetMonth).format("YYYY-MM-01"),
          targetDateTo: moment(targetMonth).format("YYYY-MM-01"),
          staffCode,
          employmentId: selectedEmploymentId,
          closingDate: selectedClosingDate,
        };
      }

      let url = "";
      if (type === 0) {
        url += "confirm";
      } else if (type === 1) {
        url += "unConfirm";
      } else if (type === 2) {
        url += "alllConfirm";
        setIsAllConfirmProcessing(true);
      }

      setLoading(true);
      setConfirmModalOpen(false);
      achievementConfirm(param, url)
        .then((response: any) => {
          setLoading(false);
          setAchievementConfirmList(
            response.confirmList.map(
              (result: any) => new AttendHeadQuartersFinalDomain(result)
            )
          );
          setAllConfirm(response.allConfirm);
          setAllCsvOutput(response.allCsvOutput);
          successNotification(type === 1 ? "解除しました。" : "確定しました。");
          setIsAllConfirmProcessing(false);
        })
        .catch((error) => {
          setLoading(false);
          setConfirmModalOpen(false);
          setIsAllConfirmProcessing(false);
          const { response } = error;
          if (response.status === 400) {
            if (response.data.errors && response.data.errors.length > 0) {
              errorNotification(
                response.data.errors
                  .map((x: any) => x.defaultMessage)
                  .join("<br/>")
              );
            } else if (response.data.defaultMessage) {
              errorNotification(response.data.defaultMessage);
            } else {
              errorNotification(response.data.message);
            }
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedClosingDate,
      selectedEmploymentId,
      setAchievementConfirmList,
      targetMonth,
    ]
  );

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  const handleErrorInfoOutput: () => void = useCallback(() => {
    let fileName = "確定エラー情報(";
    if (viewPeriod === "monthly") {
      fileName += `${moment(targetMonth).format("YYYYMM")})`;
    } else {
      return;
    }

    let dateFrom = moment(targetMonth).format("YYYY-MM-01");
    let dateTo = moment(targetMonth).format("YYYY-MM-01");
    let closingDate = selectedClosingDate;
    const createUser = sessionStorage.getItem("loginUser.staffCode") || "";

    let targetYmd = "";
    targetYmd = moment(targetMonth).format("YYYY-MM-01");

    let orgCode = "";

    const param = {
      targetDateFrom: dateFrom,
      targetDateTo: dateTo,
      attendFlag: 2,
      employmentId: selectedEmploymentId || "",
      closingDate,
      orgCode,
      targetYmd,
      flagDownloadAll: 2,
      pageId: 1,
    };

    setLoading(true);
    postProcessingDownloadAchievement({
      ...param,
      ...{
        viewPeriod,
        outputLayoutId,
        isUpdate: 0,
        underFlag: 0,
        targetDate: "",
        staffCode: "",
        achievementDownloadId: "",
        createUser: createUser,
        fileName: fileName,
      },
    })
      .then((response: any) => {
        setReloadHistory(true);
        setLoading(false);
        getErrorInfoCsv(
          {
            ...param,
            ...{
              achievementDownloadId:
                response?.attendAchievementDownloadId || "",
              fileName: response?.file.split("/")[2],
            },
          },
          fileName
        )
          .then((response: any) => {
            setReloadHistory(true);
          })
          .catch((error) => {
            setLoading(false);
            setReloadHistory(true);
            const { response } = error;
            if (!isCancelRequest) {
              if (response.status === 400) {
                if (response.data.errors && response.data.errors.length > 0) {
                  errorNotification(
                    response.data.errors
                      .map((x: any) => x.defaultMessage)
                      .join("<br/>")
                  );
                } else if (response.data.defaultMessage) {
                  errorNotification(response.data.defaultMessage);
                } else {
                  errorNotification(response.data.message);
                }
              } else {
                errorNotification("サーバー側でエラーが発生しました。");
              }
            } else {
              setIsCancelRequest(false);
            }
          });
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    outputLayoutId,
    selectedClosingDate,
    selectedEmploymentId,
    setLoading,
    targetDateFrom,
    targetDateTo,
    targetMonth,
    viewPeriod,
  ]);

  const columns: Array<Column<AttendHeadQuartersFinalDomain>> = useMemo(
    () => [
      {
        Header: "組織コード",
        accessor: "orgCode",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "組織名",
        accessor: "orgName",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "締め日",
        id: "closingDateStr",
        accessor: "closingDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          viewPeriod === "monthly" && (
            <span>
              {cell.row.original.closingDate === 0 && "末"}
              {cell.row.original.closingDate !== 0 &&
                `${cell.row.original.closingDate}`}
              日
            </span>
          ),
      },
      {
        Header: "期間",
        id: "periodStr",
        accessor: "orgCode",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          viewPeriod === "monthly" && (
            <span>
              {`${moment(cell.row.original.dateFrom, "YYYY-MM-DD").format(
                "YYYY/MM/DD"
              )}～${moment(cell.row.original.dateTo, "YYYY-MM-DD").format(
                "YYYY/MM/DD"
              )}`}
            </span>
          ),
      },
      {
        Header: "本部確定",
        id: "actionConfirm",
        accessor: "orgCode",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          const confirmData = cell.row.original.rawData;
          let msg = "";

          if (
            confirmData.employmentChangeErrorMsg != null &&
            confirmData.employmentChangeErrorMsg !== ""
          ) {
            msg = confirmData.employmentChangeErrorMsg;
          }
          if (
            confirmData.errorAchieventErrorMsg != null &&
            confirmData.errorAchieventErrorMsg !== ""
          ) {
            if (msg !== "") {
              msg += "<br>";
            }
            msg += confirmData.errorAchieventErrorMsg;
          }
          if (
            confirmData.applyingErrorMsg != null &&
            confirmData.applyingErrorMsg !== ""
          ) {
            if (msg !== "") {
              msg += "<br>";
            }
            msg += confirmData.applyingErrorMsg;
          }

          return (
            <div style={{ textAlign: "center" }}>
              {viewPeriod === "monthly" &&
                cell.row.original.confirmStatus === 0 && (
                  <span>
                    {detailRole.usabilityGeneralItem1 ? (
                      <SubActionButton
                        disabled={isAllConfirmProcessing}
                        action={ActionButton.Comfirm}
                        text="確定する"
                        onClick={() => handleConfirm(cell.row.original, 0)}
                      />
                    ) : null}
                  </span>
                )}
              {viewPeriod === "monthly" &&
                cell.row.original.confirmStatus === 1 && <span>確定済</span>}
              {viewPeriod === "monthly" &&
                (cell.row.original.confirmStatus === 2 ||
                  cell.row.original.confirmStatus === 5) && (
                  <div>
                    <IconTooltip
                      iconType="warning"
                      text={msg}
                      color={utilityColor.error}
                      wrapCustomCss={css({
                        display: "inline",
                        marginRight: "3px",
                      })}
                      customCss={css({ margin: "0" })}
                    />
                    <span>エラー</span>
                  </div>
                )}
            </div>
          );
        },
      },
      {
        Header: "解除",
        id: "actionUnConfirm",
        accessor: "orgCode",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "center" }}>
            {cell.row.original.unConfirmFlag === 1 &&
            cell.row.original.confirmStatus !== 2 &&
            detailRole.usabilityGeneralItem2 ? (
              <SubActionButton
                disabled={isAllConfirmProcessing}
                action={ActionButton.DELETE}
                text="確定解除"
                onClick={() => handleConfirm(cell.row.original, 1)}
              />
            ) : null}
          </div>
        ),
      },
      {
        Header: "所属スタッフ出力",
        id: "actionUnconfirmedBelong",
        accessor: "orgCode",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          const confirmData = cell.row.original.rawData;
          let msg = "";

          if (
            confirmData.employmentChangeBelong != null &&
            confirmData.employmentChangeBelong !== ""
          ) {
            msg = confirmData.employmentChangeBelong;
          }
          if (
            confirmData.errorAchieventBelong != null &&
            confirmData.errorAchieventBelong !== ""
          ) {
            if (msg !== "") {
              msg += "<br>";
            }
            msg += confirmData.errorAchieventBelong;
          }
          if (
            confirmData.applyingBelong != null &&
            confirmData.applyingBelong !== ""
          ) {
            if (msg !== "") {
              msg += "<br>";
            }
            msg += confirmData.applyingBelong;
          }

          return (
            <div style={{ textAlign: "center" }}>
              <TextTooltip
                textDis={
                  <div>
                    {cell.row.original.ownCnt > 0 && detailRole.downloadFlag ? (
                      <SubActionButton
                        disabled={
                          (!(cell.row.original.confirmStatus === 1) &&
                            viewPeriod === "monthly") ||
                          isAllConfirmProcessing
                        }
                        action={ActionButton.Comfirm}
                        text="CSV出力"
                        onClick={() => handleCsvOutput(cell.row.original, 0)}
                      />
                    ) : null}
                    &nbsp;
                    {cell.row.original.ownCnt > 0 && (
                      <SubActionButton
                        action={ActionButton.Comfirm}
                        text="勤怠確認"
                        onClick={() =>
                          handleDispMonthlyList(cell.row.original, 0)
                        }
                      />
                    )}
                  </div>
                }
                text={msg}
              />
            </div>
          );
        },
      },
      {
        Header: "勤務スタッフ出力",
        id: "actionUnconfirmedSupport",
        accessor: "orgCode",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          const confirmData = cell.row.original.rawData;
          let msg = "";

          if (
            confirmData.employmentChangeSupport != null &&
            confirmData.employmentChangeSupport !== ""
          ) {
            msg = confirmData.employmentChangeSupport;
          }
          if (
            confirmData.errorAchieventSupport != null &&
            confirmData.errorAchieventSupport !== ""
          ) {
            if (msg !== "") {
              msg += "<br>";
            }
            msg += confirmData.errorAchieventSupport;
          }
          if (
            confirmData.applyingSupport != null &&
            confirmData.applyingSupport !== ""
          ) {
            if (msg !== "") {
              msg += "<br>";
            }
            msg += confirmData.applyingSupport;
          }

          return (
            <div style={{ textAlign: "center" }}>
              <div
                data-toggle="tooltip"
                data-container="body"
                data-html="true"
                title={msg}
              >
                {detailRole.downloadFlag ? (
                  <SubActionButton
                    disabled={
                      (!(cell.row.original.confirmStatus === 1) &&
                        viewPeriod === "monthly") ||
                      isAllConfirmProcessing
                    }
                    action={ActionButton.Comfirm}
                    text="CSV出力"
                    onClick={() => handleCsvOutput(cell.row.original, 1)}
                  />
                ) : null}
                &nbsp;
                <SubActionButton
                  action={ActionButton.Comfirm}
                  text="勤怠確認"
                  onClick={() => handleDispMonthlyList(cell.row.original, 1)}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [
      handleConfirm,
      handleCsvOutput,
      handleDispMonthlyList,
      isAllConfirmProcessing,
      viewPeriod,
      detailRole,
    ]
  );

  return (
    <React.Fragment>
      <HeadquartersFinalFormContext.Provider value={{ isLoading, setLoading }}>
        <BlockUI blocking={isLoading}>
          <FormContents>
            <FormTitle
              title="勤怠確定"
              bold={true}
              customStyle={css`
                margin: 16px 45px;
              `}
            />
            <FormPadding>
              <Row>
                <Col md={2}>
                  <div style={{ marginTop: "10px" }}>
                    <BodyText>対象期間</BodyText>
                  </div>
                </Col>
                <Col md={10}>
                  <FlexBox>
                    <Col lg={2} md={3}>
                      <div style={{ marginTop: "-20px" }}>
                        <RadioSelectForm
                          label=""
                          name="monthly"
                          items={[
                            {
                              label: "月指定",
                              value: "monthly",
                            },
                          ]}
                          value={viewPeriod}
                          setValue={(e) => setViewPeriod(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={2} md={2}>
                      <FlexBox>
                        <CalendarMonth
                          openActionChevron={true}
                          date={targetMonth}
                          setDate={(date: Date) => setTargetMonth(date)}
                          readOnly={viewPeriod === "daily"}
                        />
                      </FlexBox>
                    </Col>
                  </FlexBox>
                  <FlexBox>
                    <Col lg={2} md={3}>
                      <div style={{ marginTop: "-20px" }}>
                        <RadioSelectForm
                          label=""
                          name="daily"
                          items={[
                            {
                              label: "期間指定",
                              value: "daily",
                            },
                          ]}
                          value={viewPeriod}
                          setValue={(e) => setViewPeriod(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <FlexBox>
                        <FlexBoxItem>
                          <CalendarRangeV2
                            startDate={targetDateFrom}
                            onChangeStart={(date: Date) =>
                              setTargetDateFrom(date)
                            }
                            endDate={targetDateTo}
                            onChangeEnd={(date) => {
                              if (date) {
                                setTargetDateTo(date);
                              }
                            }}
                            validateTime={(startDate, endDate) => {
                              let isValid =
                                moment(endDate).diff(startDate, "year") < 1;
                              if (!isValid) {
                                errorNotification(
                                  "対象期間の範囲は１年以内で入力してください"
                                );
                              }
                              return isValid;
                            }}
                            readOnly={viewPeriod === "monthly"}
                          />
                        </FlexBoxItem>
                      </FlexBox>
                    </Col>
                  </FlexBox>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                  <BodyText>締日・雇用形態</BodyText>
                </Col>
                <Col md={10}>
                  <Row>
                    <Col md={2}>
                      <FlexBox>
                        <FlexBoxItem grow={0.5}>
                          <VerticalLabelSelectForm
                            label=""
                            name="closingDate"
                            value={selectedClosingDate}
                            setValue={(v) => {
                              setSelectedEmploymentId("");
                              setSelectedClosingDate(v);
                            }}
                            options={[
                              ...[{ value: "-1", label: "-" }],
                              ...closingDateList,
                            ]}
                          />
                        </FlexBoxItem>
                        <span>&nbsp;日</span>
                      </FlexBox>
                    </Col>
                    <Col md={4}>
                      <VerticalLabelSelectForm
                        label=""
                        name="attendEmployment"
                        value={selectedEmploymentId}
                        setValue={(v) => setSelectedEmploymentId(v)}
                        options={[
                          ...[{ value: "", label: "-" }],
                          ...employmentList,
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={2} style={{ display: "flex", alignItems: "center" }}>
                  <BodyText>出力レイアウト</BodyText>
                </Col>
                <Col md={5}>
                  <SelectForm
                    label=""
                    name="outputLayout"
                    value={outputLayoutId}
                    setValue={(v) => setOutputLayoutId(v)}
                    options={outputLayoutList}
                  />
                </Col>
              </Row>
            </FormPadding>
            <div
              css={css`
                table th:nth-of-type(1) {
                  width: 120px;
                }
                table th:nth-of-type(2) {
                  width: 120px;
                }
                table th:nth-of-type(3) {
                  width: 80px;
                }
                table th:nth-of-type(4) {
                  width: 120px;
                }
                table th:nth-of-type(5) {
                  width: 60px;
                }
                table th:nth-of-type(6) {
                  width: 60px;
                }
                table th:nth-of-type(7) {
                  width: 140px;
                }
                table th:last-child {
                  width: 140px;
                }
              `}
            >
              <DataTable
                columns={columns}
                data={achievementConfirmList}
                containerStyle={css({
                  overflowX: "auto",
                })}
                isGlobalFilter={true}
                globalSearchStyle={styles.globalSearch}
                sortBy={sortBy}
              />
            </div>

            <Container>
              <Row>
                <p style={{ textAlign: "center", width: "100%" }}>
                  {isAllConfirmProcessing &&
                    detailRole.usabilityGeneralItem1 && (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        一括確定中です...
                      </span>
                    )}
                </p>
              </Row>
              <Row style={{ paddingBottom: "60px" }}>
                <FlexBox
                  alignItems="center"
                  customStyle={css({ width: "100%", justifyContent: "center" })}
                >
                  {detailRole.usabilityGeneralItem1 ? (
                    <FlexBoxItem>
                      <PrimaryButton
                        disabled={!allConfirm || isAllConfirmProcessing}
                        onClick={() => openConfirmModal()}
                        ghost={false}
                        text="一括確定"
                      />
                    </FlexBoxItem>
                  ) : null}

                  <FlexBoxItem marginLeft="10px">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {detailRole.downloadFlag ? (
                          <IconLabelButton
                            onClick={() => {}}
                            iconType="download"
                            text="一括CSV出力"
                          />
                        ) : null}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "auto" }}>
                        {[
                          {
                            value: "0",
                            label: "所属スタッフ出力",
                          },
                          {
                            value: "1",
                            label: "勤務スタッフ出力",
                          },
                        ].map((item, index) => (
                          <Dropdown.Item
                            disabled={!allCsvOutput || isAllConfirmProcessing}
                            style={{
                              color:
                                !allCsvOutput || isAllConfirmProcessing
                                  ? grayScale.gray03
                                  : grayScale.gray100,
                              fontSize: textFontSize.re,
                              textAlign: "center",
                            }}
                            key={index.toString()}
                            onClick={() => {
                              handleCsvOutput(null, item.value, 1);
                            }}
                          >
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </FlexBoxItem>
                  <FlexBoxItem>
                    {detailRole.downloadFlag && viewPeriod === "monthly" ? (
                      <IconLabelButton
                        onClick={() => {
                          handleErrorInfoOutput();
                        }}
                        iconType="download"
                        text="エラー情報"
                      />
                    ) : null}
                  </FlexBoxItem>
                </FlexBox>
              </Row>
            </Container>
            {detailRole.downloadFlag === 1 && (
              <FlexBox customStyle={css({ width: "100%" })}>
                <SalaryHistoryDownloadForm
                  salaryHistoryDownloadList={salaryHistoryDownloadList}
                  setSalaryHistoryDownloadList={setSalaryHistoryDownloadList}
                  setReloadHistory={setReloadHistory}
                  pageId={1}
                  isCancelRequest={isCancelRequest}
                  setIsCancelRequest={setIsCancelRequest}
                />
              </FlexBox>
            )}
          </FormContents>
          <Modal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            title={"一括確定"}
            submitText={"一括確定"}
            note={"一括確定します。よろしいですか?"}
            onSubmit={() => handleConfirm(null, 2)}
          >
            <div style={{ height: "40px" }}></div>
          </Modal>
        </BlockUI>
      </HeadquartersFinalFormContext.Provider>
    </React.Fragment>
  );
};

export default HeadquartersFinalForm;
