export const useOrgCode = (): string => {
  return sessionStorage.getItem("loginUser.orgCode") || "";
};

export const useOrgName = (): string => {
  return sessionStorage.getItem("loginUser.orgName") || "";
};

export const getBarcodeStamp = (): number => {
  return sessionStorage.getItem("loginUser.useBarcodeStamp") === "true" ? 1 : 0;
};

export const getBarcodeId = (): string => {
  return sessionStorage.getItem("loginUser.barcodeId") || "";
};

export const getFaceStamp = (): number => {
  return sessionStorage.getItem("loginUser.useFaceStamp") === "true" ? 1 : 0;
};

export const getCompanyCode = (): string => {
  return sessionStorage.getItem("loginUser.companyCode") || "";
};

export const getStaffCode = (): string => {
  return sessionStorage.getItem("loginUser.staffCode") || "";
};

export default {
  useOrgCode,
  useOrgName,
  getBarcodeStamp,
  getFaceStamp,
  getCompanyCode,
  getStaffCode,
};
