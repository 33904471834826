import { useState, useEffect } from "react";

import { getListClosingDate, getListEmployment, getTimeList } from "api/achievementConfirm";
import moment from "moment";

export const useGetClosingList = (targetDateFrom: Date) => {
  const [closingDateList, setClosingDateList] = useState([]);
  useEffect(() => {
    const params = {
      targetDate: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
    };

    getListClosingDate(params).then((response: any) => {
      setClosingDateList(
        response.map((item: any) => ({
          value: String(item.closingDate),
          label: item.closingName,
        })),
      );
    });
  }, [targetDateFrom]);
  return {
    closingDateList,
    setClosingDateList,
  };
};

export const useGetEmployments = (targetDateFrom: Date, targetDateTo: Date, selectedClosingDate: String) => {
  const [employmentList, setEmploymentList] = useState([]);
  useEffect(() => {
    const params = {
      targetDateFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
      targetDateTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
      closingDate: selectedClosingDate,
    };

    getListEmployment(params).then((response: any) => {
      setEmploymentList([]);
      if (response.length) {
        setEmploymentList(
          response.length &&
            response.map((item: any) => ({
              value: String(item.employmentId),
              label: `${item.employmentCode} ${item.employmentName}`,
            })),
        );
      }
    });
  }, [selectedClosingDate, targetDateFrom, targetDateTo]);
  return {
    employmentList,
    setEmploymentList,
  };
};

export const eventScroll = (e: { target: any }) => {
  const { target } = e;
  const contentRight = document.getElementById("bottomRightAreaTime");
  const headRight = document.getElementById("topRightAreaTime");
  const scrollBottom = document.getElementById("scrollBottom");
  if (contentRight && headRight && scrollBottom) {
    contentRight.scrollLeft = target.scrollLeft;
    headRight.scrollLeft = target.scrollLeft;
    scrollBottom.scrollLeft = target.scrollLeft;
  }
};

export const useGetTimeList = (
  setLoading: any,
  targetDateFrom: any,
  targetDateTo: any,
  selectedClosingDate: any,
  attendFlag: any,
  selectOrgCode: any,
  selectedEmploymentId: any,
) => {
  const [term, setTerm] = useState([]);
  const [business, setBusiness] = useState([]);
  const [attendHolidays, setAttendHolidays] = useState([]);
  const [subTotalTimeList, setSubTotalTimeList] = useState([]);
  const [subTotalDayList, setSubTotalDayList] = useState([]);
  const [holidaySubtotalItem, setHolidaySubtotalItem] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [detailFlag, setDetailFlag] = useState(0);
  const [useAllowance, setUseAllowance] = useState(0);

  useEffect(() => {
    const params = {
      targetDateFrom: moment(new Date(targetDateFrom)).format("YYYY-MM-DD"),
      targetDateTo: moment(new Date(targetDateTo)).format("YYYY-MM-DD"),
      orgCode: selectOrgCode,
      closingDate: selectedClosingDate,
      employmentId: selectedEmploymentId,
      attendFlag,
    };

    setLoading(true);
    getTimeList(params).then((response: any) => {
      setDetailFlag(response.detailFlag);
      setBusiness(response.business);
      setTerm(response.term);
      setAttendHolidays(response.attendHolidays);
      setSubTotalTimeList(response.attendStampSubTotalTime);
      setSubTotalDayList(response.attendStampSubTotalDay);
      setHolidaySubtotalItem(response.holidaySubtotalItem);
      setStaffList(response.staffList);
      setLoading(false);
      setUseAllowance(response.useAllowance);
    });
  }, [attendFlag, selectOrgCode, selectedClosingDate, selectedEmploymentId, setLoading, targetDateFrom, targetDateTo]);
  return {
    term,
    business,
    attendHolidays,
    subTotalTimeList,
    subTotalDayList,
    holidaySubtotalItem,
    staffList,
    detailFlag,
    useAllowance,
  };
};

export default {
  useGetEmployments,
  eventScroll,
};
