import { IAddShiftPattern } from './../components/pages/master/shift/type';
import { ShiftPattern } from 'domain/master/attend/shiftPattern';
import ApiClient from './ApiClient';

export const getList = async (companyCode: string, orgCode: string):
Promise<any> => {
  const params = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/shift/patterns/list/${companyCode}`, params);
  return response.data;
};

export const getListV1 = async (companyCode: string, orgCode: string, functionCode: string):
Promise<any> => {
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const params = {
    orgCode,
    loginStaffCode,
    functionCode
  };
  const response = await ApiClient.get(`/v2/shift/patterns/list/${companyCode}`, params);
  return response.data;
};

export const getAvailableShiftPattern = async (
  orgCode: string = sessionStorage.getItem('loginUser.orgCode')!,
): Promise<Array<ShiftPattern>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const params = {
    orgCode,
  };
  const response = await ApiClient.get(`/v1/shift/patterns/available/${companyCode}`, params);
  return response.data;
};

export const getListWorkType = async (): Promise<any> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/employments/selectList/${companyCode}`, {});
  return response.data;
};


export const deleteById = async (shiftPatternId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    shiftPatternId,
  };

  const response = await ApiClient.delete(`/v1/shift/patterns/${companyCode}`, params);
  return response.data;
};

export const fetchBreakUUID = async (): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {};

  const response = await ApiClient.get(`/v1/business/attendBreak/${companyCode}`, params);
  return response.data;
};

/**
 * createOrUpdateShiftPattern
 *
 * @param companyCode
 * @param dataShiftPattern
 */
export const createOrUpdateShiftPattern = async (companyCode: string, dataShiftPattern: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/shift/patterns/${companyCode}`, query, dataShiftPattern);
  return response.data;
};

/**
 * getShiftPatternById
 *
 * @param shiftPatternId
 */
export const getShiftPatternById = async (shiftPatternId: string): Promise<any> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    shiftPatternId,
  };
  const response = await ApiClient.get(`/v1/shift/patterns/${companyCode}`, query);
  return response.data;
};

export const addShiftPattern = async (
  orgCode: string = sessionStorage.getItem('loginUser.orgCode')!,
  staffCode: string,
  targetDate: string,
  shiftPatternId: string,
  createUser: string,
  updateUser: string,
): Promise<IAddShiftPattern> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const query = {
    orgCode,
    staffCode,
    targetDate,
    shiftPatternId,
    createUser,
    updateUser,
  };
  const response = await ApiClient.post(`/v1/shift/ShiftPattern/${companyCode}`, query, {});
  return response.data;
};

export default getList;
