export interface StockPaidHolidayDigestedtHistory {
    staffCode: string,
    staffName: string,
    orgCode: string,
    orgName: string,
    total: string,
    stockPaidHolidayDigestionDetailList: [{
      targetDate: Date,
      digestionDaysNum: string,
    }],
    fromDate: Date,
    toDate: Date,
}

export default class StockPaidHolidayDigestedtHistoryDomain {
  constructor(private rawData: StockPaidHolidayDigestedtHistory) {
    // do nothing
  }

  static generateInitial(): StockPaidHolidayDigestedtHistoryDomain {
    return new StockPaidHolidayDigestedtHistoryDomain({
      staffCode: '',
      staffName: '',
      orgCode: '',
      orgName: '',
      total: '',
      stockPaidHolidayDigestionDetailList: [{
        targetDate: new Date(),
        digestionDaysNum: '',
      }],
      fromDate: new Date(),
      toDate: new Date(),
    });
  }

  getRawData(): StockPaidHolidayDigestedtHistory {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get total(): string {
    return `${this.rawData.total}日`;
  }

  set total(total:string) {
    this.rawData.total = total;
  }
}
