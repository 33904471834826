import { useState, useEffect, useCallback } from 'react';
import { getList } from 'api/holidayMaster';
import HolidayMasterDomain from 'domain/master/labor/holidayMaster';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useHolidayMasterList = (orgCode: string, year: string) => {
  const [isLoading, setLoading] = useState(true);


  const { detailRole } = useGetDetailRole(setLoading, functionCode.holiday);

  const [holidayList, setHolidayList] = useState<Array<HolidayMasterDomain>>([]);

  const fetchData = useCallback(async () => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    setLoading(true);
    const response = await getList(companyCode, year, orgCode)
    setHolidayList(response.map((result: any) => new HolidayMasterDomain(result)));
    setLoading(false);
  }, [orgCode, year]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  // useEffect(() => {
  //   let isSubscribed = true;
  //   const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  //   getList(companyCode, year, orgCode).then((response: Array<any>) => {
  //     setLoading(true);
  //     if (isSubscribed) {
  //       setHolidayList(response.map((result) => new HolidayMasterDomain(result)));
  //     }
  //     setLoading(false);
  //   });
  //   return () => { isSubscribed = false; };
  // }, [orgCode, year]);
  return { holidayList, setHolidayList, isLoading, detailRole};
};

export default useHolidayMasterList;
