export interface Detail {
  allowanceApplicationDetailId: string;
  modifierApplicationId: string;
  allowanceId: string;
  allowanceValue: string;
  comment: string;
  allowanceName: string;
  dispMessage: string;
  dispUnit: string;
}

export interface History {
  version: string;
  historyType: number;
  historyTypeName: string;
  modifierApplicationId: string;
  orgCode: string;
  orgName: string;
  staffCode: string;
  staffName: string;
  applicationStatus: number;
  applicationDate: Date;
  applicationStaffCode: string;
  applicationStaffName: string;
  modifiyStatus: number;
  rejectReason: string | null;
  approvalDate: Date | null;
  approvalStaffCode: string | null;
  approvalStaffName: string | null;
  detailList: Detail[];
  approvalHistoryList: ApprovalHistory[];
}

export interface ApprovalHistory {
  approvalLevel: number;
  executiveName: string;
  orgName: string;
  status: number;
  approvalDate: Date | null;
  staffName: string | null;
  executiveId: string;
  rejectReason: string | null;
}

export interface AllowanceItem {
  modifierApplicationId: string;
  companyCode: string;
  orgCode: string;
  staffCode: string;
  targetDate: string;
  version: string;
  buttonType: number;
  applicationStatus: number;
  modifiyStatus: number;
  detailList: Detail[];
  historyList: History[];
  deleteAvailable: boolean;
}

export default class AttendAllowanceApplicationDomain {
  constructor(private rawData: AllowanceItem) {
    // do nothing
  }

  static generateInitial(): AttendAllowanceApplicationDomain {
    return new AttendAllowanceApplicationDomain({
      modifierApplicationId: "",
      companyCode: "",
      orgCode: "",
      staffCode: "",
      targetDate: "",
      version: "",
      buttonType: 0,
      applicationStatus: 0,
      modifiyStatus: 0,
      detailList: [],
      historyList: [],
      deleteAvailable: false,
    });
  }

  getRawData(): AllowanceItem {
    return this.rawData;
  }

  get modifierApplicationId(): string {
    return this.rawData.modifierApplicationId;
  }

  set modifierApplicationId(modifierApplicationId: string) {
    this.rawData.modifierApplicationId = modifierApplicationId;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }

  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get staffCode(): string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get targetDate(): string {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: string) {
    this.rawData.targetDate = targetDate;
  }

  get version(): string {
    return this.rawData.version;
  }

  set version(version: string) {
    this.rawData.version = version;
  }

  get buttonType(): number {
    return this.rawData.buttonType;
  }

  set buttonType(buttonType: number) {
    this.rawData.buttonType = buttonType;
  }

  get applicationStatus(): number {
    return this.rawData.applicationStatus;
  }

  set applicationStatus(applicationStatus: number) {
    this.rawData.applicationStatus = applicationStatus;
  }

  get modifiyStatus(): number {
    return this.rawData.modifiyStatus;
  }

  set modifiyStatus(modifiyStatus: number) {
    this.rawData.modifiyStatus = modifiyStatus;
  }

  get detailList(): Detail[] {
    return this.rawData.detailList;
  }

  set detailList(detailList: Detail[]) {
    this.rawData.detailList = detailList;
  }

  get historyList(): History[] {
    return this.rawData.historyList;
  }

  set historyList(historyList: History[]) {
    this.rawData.historyList = historyList;
  }

  get deleteAvailable(): boolean {
    return this.rawData.deleteAvailable;
  }

  set deleteAvailable(deleteAvailable: boolean) {
    this.rawData.deleteAvailable = deleteAvailable;
  }
}
