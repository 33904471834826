// import { business, BusinessCategory } from 'domain/master/sales/businessCategory';
import { Business } from "domain/master/attend/business";
import ApiClient from "./ApiClient";

export const getList = async (): Promise<Array<Business>> => {
  // TODO 仮リクエスト
  const params = {};
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/business/attends/list/${companyCode}`, params);
  return response.data;
};

export const post = async (companyCode: string, business: Business) => {
  const response = await ApiClient.post(`/v1/business/attends/list/${companyCode}`, {}, business);
  return response.data;
};

export const getListAttendBusiness = async (companyCode: string): Promise<any> => {
  const params = {};

  const response = await ApiClient.get(`/v1/business/attends/list/${companyCode}`, params);
  return response.data;
};

export const getListTerm = async (): Promise<any> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/term/list/${companyCode}`, {});
  return response.data;
};

export const deleteAttendBusinessById = async (companyCode: string, businessId: string): Promise<any> => {
  const params = {
    businessId,
  };

  const response = await ApiClient.delete(`/v1/business/attends/${companyCode}`, params);
  return response.data;
};

export const createOrUpdateAttendBusiness = async (
  companyCode: string,
  isAccepted: boolean,
  business: any,
): Promise<any> => {
  const params = {
    isAccepted,
  };
  const response = await ApiClient.post(`/v2/business/attends/${companyCode}`, params, business);
  return response.data;
};

export const getAttendBusiness = async (companyCode: string, businessId: string): Promise<any> => {
  const params = { businessId };

  const response = await ApiClient.get(`/v1/business/attends/${companyCode}`, params);
  return response.data;
};

export const getBusinessDataByEmploymentId = async (employmentId: string): Promise<any> => {
  const params = { employmentId };
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const response = await ApiClient.get(`/v1/business/${companyCode}`, params);
  return response.data;
};

export const getBusinessAttendChoice = async (staffCode: String, targetDate: String): Promise<any> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/business/attends/choices/${companyCode}`, params);
  return response.data;
};

export default getList;
