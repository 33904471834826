import { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import { getAchievementCsvLayout, exportAchievementCsvOutput } from "api/attendCSVOutput";
import { OptionType } from "components/atoms/Select";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";
import { getStaffAccessableOptions } from "api/timeRecalculation";
import { getSelectListDateRange } from "api/employment";
import { functionCode } from "const";
import SalaryHistoryDownload from "domain/master/attend/salaryHistoryDownload";
import { postProcessingDownloadAchievement } from "api/achievementConfirm";

export const useAttendCSVOutputForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [layoutIdOptions, setLayoutIdOptions] = useState<Array<OptionType>>([]);
  let orgTreesOptions: Array<any> = [];
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  const [underFlag, setUnderFlag] = useState(0);
  const [salaryHistoryDownloadList, setSalaryHistoryDownloadList] = useState<Array<SalaryHistoryDownload>>([]);
  const [reloadHistory, setReloadHistory] = useState(true);
  const { errorNotification } = useToastNotification();
  const [isCancelRequest, setIsCancelRequest] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    if (!values.orgCode) {
      errorNotification("組織を選択してください。");
      return;
    }

    const targetDateFrom =
      values.viewPeriod === "monthly"
        ? moment(values.targetDateFrom)
            .startOf("month")
            .format("YYYY-MM-DD")
        : moment(values.targetDateFrom).format("YYYY-MM-DD");

    const targetDateTo =
      values.viewPeriod === "monthly"
        ? moment(values.targetDateTo)
            .endOf("month")
            .format("YYYY-MM-DD")
        : moment(values.targetDateTo).format("YYYY-MM-DD");

    const submitObj = {
      loginStaffCode: values.loginStaffCode,
      viewPeriod: values.viewPeriod,
      targetDateFrom: targetDateFrom,
      targetDateTo: targetDateTo,
      orgCode: values.orgCode,
      employmentId: values.employmentId || "",
      staffCode: values.staffCode === "all" ? "" : values.staffCode,
      layoutId: values.layoutId,
      underFlag,
      createUser: `${values.loginStaffCode}`,
      pageId: 3,
    };

    let orgName = orgTreesOptions.filter((org: any) => {
      return org.value === values.orgCode;
    })[0].label;

    let layoutName = layoutIdOptions.filter((layout: any) => {
      return layout.value === values.layoutId;
    })[0].label;

    setIsLoading(true);
    const orgFileName = orgName.split(" ")[1] || orgName.split(" ")[0];

    const dateFormat = values.viewPeriod === "daily" ? "YYYYMMDD" : "YYYYMM";
    const nameFile =
      `${layoutName}_${moment(values.targetDateFrom).format(dateFormat)}_${moment(values.targetDateTo).format(
        dateFormat,
      )}_${orgFileName}` + (underFlag ? "(配下)" : "");

    try {
      setIsLoading(true);
      postProcessingDownloadAchievement({
        ...submitObj,
        ...{
          targetDate: "",
          closingDate: "",
          outputLayoutId: values.layoutId,
          attendFlag: 0,
          flagDownloadAll: 0,
          isUpdate: 0,
          achievementDownloadId: "",
          fileName: nameFile,
        },
      })
        .then((response: any) => {
          setIsLoading(false);
          setReloadHistory(true);
          exportAchievementCsvOutput({
            ...submitObj,
            ...{
              achievementDownloadId: response?.attendAchievementDownloadId || "",
              fileName: response?.file.split("/")[2],
              isUpdate: 0,
            },
          })
            .then((response: any) => {
              setIsLoading(false);
              setReloadHistory(true);
            })
            .catch((error) => {
              setIsLoading(false);
              setReloadHistory(true);
              if (!isCancelRequest) {
                if (error.response && error.response.data) {
                  errorNotification(error.response.data);
                } else {
                  errorNotification("サーバー側でエラーが発生しました。");
                }
              } else {
                setIsCancelRequest(false);
              }
            });
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.data && error.response.data.errors) {
            let msgError = "";
            error.response.data.errors.map((item: { defaultMessage: string }) => {
              msgError += `${item.defaultMessage} \n`;
              return msgError;
            });
            errorNotification(msgError);
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
        });
    } catch (error) {
      errorNotification("サーバー側でエラーが発生しました。");
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      loginStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
      targetDateFrom: new Date(),
      targetDateTo: new Date(),
      orgCode: sessionStorage.getItem("loginUser.orgCode"),
      employmentId: null,
      staffCode: "all",
      layoutId: "",
      viewPeriod: "monthly",
    },
    onSubmit,
  });

  orgTreesOptions = useOrgTreesOptionsShopV1(
    true,
    functionCode.attendCSVOutput,
    moment(formik.values.targetDateFrom).format("YYYY-MM-DD"),
    moment(formik.values.targetDateTo).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    if (formik.values.targetDateFrom === null || formik.values.targetDateTo === null) return;
    setIsLoading(true);
    const dateFrom = moment(formik.values.targetDateFrom)
      .startOf("month")
      .format("YYYY-MM-DD");
    const dateTo = moment(formik.values.targetDateTo)
      .endOf("month")
      .format("YYYY-MM-DD");
    getSelectListDateRange(dateFrom, dateTo).then((trees: any) => {
      const employment = trees.map((tree: any) => ({
        value: tree.employmentId,
        label: `${tree.employmentCode} ${tree.employmentName}`,
      }));
      employment.unshift({
        value: "",
        label: "-",
      });
      setEmploymentOptions(employment);
      setIsLoading(false);
    });
  }, [formik.values.targetDateFrom, formik.values.targetDateTo]);

  useEffect(() => {
    if (formik.values.targetDateFrom === null || formik.values.targetDateTo === null) return;
    setIsLoading(true);
    const dateFrom = moment(formik.values.targetDateFrom)
      .startOf("month")
      .format("YYYY-MM-DD");
    const dateTo = moment(formik.values.targetDateTo)
      .endOf("month")
      .format("YYYY-MM-DD");
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || "";
    const addAllItem: boolean = true;
    getStaffAccessableOptions(
      loginStaffCode,
      formik.values.orgCode || "all",
      dateFrom,
      dateTo,
      formik.values.employmentId || "",
      addAllItem,
      functionCode.attendCSVOutput,
      underFlag,
    ).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      setStaffOptions(staffOptions);
      setIsLoading(false);
    });
  }, [
    formik.values.orgCode,
    formik.values.targetDateFrom,
    formik.values.targetDateTo,
    formik.values.employmentId,
    employmentOptions,
    underFlag,
  ]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      getAchievementCsvLayout()
        .then((response: any) => {
          setLayoutIdOptions(
            response.map((item: any) => ({
              value: item.layoutId,
              label: item.layoutName,
            })),
          );
          formik.setFieldValue("layoutId", response[0].layoutId);
        })
        .catch((error: any) => {
          errorNotification("サーバー側でエラーが発生しました。");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      errorNotification("サーバー側でエラーが発生しました。");
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (orgTreesOptions.filter((org: any) => org.value === formik.values.orgCode).length === 0) {
      formik.setFieldValue("orgCode", "all");
    }
  }, [formik.values.targetDateFrom, formik.values.targetDateTo]);

  useEffect(() => {
    if (staffOptions.filter((staff: any) => staff.value === formik.values.staffCode).length === 0) {
      formik.setFieldValue("staffCode", "all");
    }
  }, [staffOptions]);
  return {
    isLoading,
    orgTreesOptions,
    staffOptions,
    formik,
    employmentOptions,
    layoutIdOptions,
    underFlag,
    setUnderFlag,
    setIsLoading,
    salaryHistoryDownloadList,
    setSalaryHistoryDownloadList,
    reloadHistory,
    setReloadHistory,
    isCancelRequest,
    setIsCancelRequest,
  };
};

export default useAttendCSVOutputForm;
