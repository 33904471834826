import { useState, useEffect } from 'react';
import { getList } from 'api/area';
import AreaDomain from 'domain/master/sales/area';

export const useAreaList = () => {
  const [areaList, setAreaList] = useState<Array<AreaDomain>>([]);
  useEffect(() => {
    getList().then((response: Array<any>) => {
      setAreaList(response.map((result) => new AreaDomain(result)));
    });
  }, []);
  return { areaList, setAreaList };
};

export default useAreaList;
