import React from 'react';
import { css } from '@emotion/core';
import Modal from 'components/molecules/Modal';
import Table from 'components/molecules/Table';
import Checkbox from 'components/molecules/Checkbox';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';
import useOrgList from './hooks';

const styles = {
  modal: css({
    maxWidth: '500px'
  }, css`
    table tr td{
      cursor:pointer;
    }
  `),
  column: css({
    width: '22px'
  })
}

const OrganizationAddModal: React.FC<{
  selectedOrgDaysList?: Array<any>;
  open: boolean;
  openHandler: (arg: boolean) => void;
  addOrganization: (arg: Array<SelfOrganizationDomain>) => void
}> = ({ open, openHandler, addOrganization, selectedOrgDaysList }) => {
  const {
    organizationList,
    checkedOrgCodeList,
    checkHandler,
    onSubmit,
  } = useOrgList(addOrganization, openHandler);
  if (!selectedOrgDaysList) {
    selectedOrgDaysList = [];
  }
  return (
    <Modal
      open={open}
      closeHandler={() => openHandler(false)}
      title="組織選択"
      submitText="追加"
      onSubmit={onSubmit}
      customStyle={styles.modal}
    >
      <Table>
        <thead>
          <tr>
            <Table.HeaderCell customStyle={styles.column} />
            <Table.HeaderCell>
              組織コード
            </Table.HeaderCell>
            <Table.HeaderCell>
              組織名
            </Table.HeaderCell>
          </tr>
        </thead>
        <tbody>
          {organizationList && (
            organizationList.filter((item)=> selectedOrgDaysList?.includes(item.orgCode) === false).map((organization, index) => (
              <tr key={index.toString()}>
                <Table.Cell customStyle={css({ width: '8px' })}>
                  <Checkbox
                    id={organization.orgCode}
                    name={organization.orgCode}
                    value={organization.orgCode}
                    checked={checkedOrgCodeList.includes(organization.orgCode)}
                    onChange={() => checkHandler(organization.orgCode)}
                  />
                </Table.Cell>
                <Table.Cell>
                  {organization.orgCode}
                </Table.Cell>
                <Table.Cell>
                  {organization.orgName}
                </Table.Cell>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Modal>
  );
};

export default OrganizationAddModal;
