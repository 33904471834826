
export const types = {
  CHANGE_AVATAR: 'ui/appBar/CHANGE_AVATAR' as const,
};

export const changeAvatar = (payload: string) => ({
  type: types.CHANGE_AVATAR,
  payload,
});

type Actions = (
  | ReturnType<typeof changeAvatar>
);

export default Actions;
