/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { useICCardRecorder } from "./hooks";
import ICCardRecorderUI from "./ICCardRecorderUI";

const ICCardRecorder: React.FC<{
  dataTimes: any;
  dataSettings: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHistoryData: any;
}> = ({
  dataTimes,
  dataSettings,
  setIsLoading,
  fetchHistoryData,
}) => {

  const {
    isConfirm,
    staffName,
    lastStampType,
    handleRecorder,
    detectCard,
    isConnectICCardUsb
  } = useICCardRecorder(
    dataSettings,
    dataTimes.resultCurrentTime,
    setIsLoading,
    fetchHistoryData
  );
        
  return (
    <ICCardRecorderUI
      dataTimes={dataTimes}
      isConfirm={isConfirm}
      staffName={staffName}
      lastStampType={lastStampType}
      handleRecorder={handleRecorder}
      detectCard={detectCard}
      isConnectICCardUsb={isConnectICCardUsb}
    />
  );
};

export default ICCardRecorder;
