import ApiClient from './ApiClient';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { MasterAddRole } from 'domain/master/general/masterRole/masterAddRole';
import { DetailRoleFunction } from 'components/organismos/master/masterRole/MasterRoleEditForm/type';

export interface DetailRole {
  roleId: string,		
  functionCode: string,		
  available: boolean,		
  editable: number,		
  importFlag: number,		
  downloadFlag: number,		
  accessRange: number,
  linkAvailable1: boolean,
  linkAvailable2: boolean,
  linkAvailable3: boolean,
  linkAvailable4: boolean,
  linkAvailable5: boolean,
  usabilityGeneralItem1: number | null,
  usabilityGeneralItem2: number | null,
  usabilityGeneralItem3: number | null,
  usabilityGeneralItem4: number | null,
  usabilityGeneralItem5: number | null,
  usabilityGeneralItem6: number | null,
  usabilityGeneralItem7: number | null,
  usabilityGeneralItem8: number | null,
  usabilityGeneralItem9: number | null,
  usabilityGeneralItem10: number | null,
}

export interface Role {
    id: {	
      companyCode: string,	
      roleId: string	
    },	
    roleCode: string,	
    roleName: string,	
    accessLevel: number,	
    systemRole: boolean,
}

export type Error = {
  error: string;
  defaultMessage: string;
}

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYY/MM/DD')}.csv`;

export const getDetailRole = async (functionCode: string): Promise<DetailRole> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    loginStaffCode,
    functionCode
  }
  const response = await ApiClient.get(`/v2/roles/staff/functionRole/${companyCode}`, params);
  return response.data;
};

export const getDetailFunctionRole = async (roleId: string): Promise<DetailRoleFunction> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    roleId
  }
  const response = await ApiClient.get(`/v2/roleFunction/${companyCode}`, params);
  return response.data;
};

export const getListRole = async (): Promise<Array<Role>> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v2/roles/${companyCode}`, {});
  return response.data;
};

export const importCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const params = {
    createUser: staffCode,
    updateUser: staffCode,
  };
  const response = await ApiClient.postFile(`/v2/role/csv/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export const downloadCSV = async (): Promise<void> => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const params = {
  };
  await ApiClient.downloadCsv(`/v2/role/csv/${companyCode}`, params, getDownloadCsvFileName('権限管理情報_'));
};

export const deleteRoleId = async (roleId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    roleId,
  };
  const response = await ApiClient.delete(`/v2/role/delete/${companyCode}`, params);
  return response.data;
};

export const postMasterAddRole = async (data: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    ...data,
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || ''
  };
  const response = await ApiClient.postMutipartData(`/v2/role/insert/${companyCode}`, {}, params);
  return response.data;
};

export const postMasterEditRole = async (data: any): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    ...data,
    loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || ''
  };
  const response = await ApiClient.postMutipartData(`/v2/role/update/${companyCode}`, {}, params);
  return response.data;
};