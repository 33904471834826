import React, { SVGProps } from 'react';

const Coffee: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="31" height="43" viewBox="0 0 31 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 36C19.95 36 24.88 31.68 25.83 26L28 24C28 24.68 27.95 25.35 27.86 26C26.89 32.78 21.05 38 14 38C6.95 38 1.11 32.78 0.14 26C0.05 25.35 0 24.68 0 24L2.17 26C3.12 31.68 8.05 36 14 36Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.49 34.6101C7.63 35.1401 7.31 35.6901 6.78 35.8301C5.46 36.1801 4.44 36.6101 3.79 37.0601C3.1 37.5301 3 37.8701 3 38.0001C3 38.1101 3.07 38.3801 3.56 38.7701C4.04 39.1501 4.8 39.5401 5.83 39.8801C7.88 40.5601 10.76 41.0001 14 41.0001C17.24 41.0001 20.12 40.5601 22.17 39.8801C23.2 39.5401 23.96 39.1501 24.44 38.7701C24.93 38.3801 25 38.1101 25 38.0001C25 37.9001 24.95 37.7001 24.63 37.3901C24.23 37.0101 24.21 36.3801 24.59 35.9801C24.97 35.5801 25.6 35.5601 26 35.9401C26.56 36.4701 27 37.1601 27 38.0001C27 38.9901 26.4 39.7701 25.68 40.3401C24.95 40.9201 23.95 41.3901 22.81 41.7701C20.5 42.5401 17.39 43.0001 14 43.0001C10.61 43.0001 7.5 42.5401 5.2 41.7801C4.06 41.4001 3.06 40.9201 2.33 40.3501C1.6 39.7701 1 38.9901 1 38.0001C1 36.8701 1.78 36.0001 2.67 35.4101C3.59 34.7901 4.84 34.2901 6.27 33.9101C6.8 33.7601 7.35 34.0801 7.49 34.6101Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.29 27.41C27.87 27.11 27.34 27 27 27V25C27.66 25 28.63 25.19 29.46 25.79C30.34 26.42 31 27.47 31 29C31 30.28 30.51 31.29 29.76 32.01C29.03 32.72 28.11 33.13 27.24 33.34C26.38 33.56 25.5 33.6 24.8 33.5C24.46 33.45 24.09 33.36 23.79 33.2C23.52 33.06 23 32.69 23 32H25C25 31.79 24.92 31.63 24.84 31.54C24.81 31.5 24.77 31.47 24.76 31.46C24.75 31.45 24.74 31.45 24.74 31.44H24.73C24.73 31.44 24.74 31.44 24.75 31.45C24.77 31.46 24.79 31.46 24.82 31.47C24.88 31.49 24.97 31.51 25.07 31.52C25.5 31.58 26.12 31.56 26.75 31.4C27.39 31.24 27.96 30.96 28.36 30.57C28.74 30.21 29 29.72 29 29C29 28.13 28.66 27.68 28.29 27.41Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 8C11.24 8 9 10.24 9 13C9 15.76 11.24 18 14 18C16.76 18 19 15.76 19 13C19 10.24 16.76 8 14 8ZM7 13C7 9.13 10.13 6 14 6C17.87 6 21 9.13 21 13C21 16.87 17.87 20 14 20C10.13 20 7 16.87 7 13Z" fill="#007BC3"/>
    <path d="M15 24C15 24.55 14.55 25 14 25C13.45 25 13 24.55 13 24C13 23.45 13.45 23 14 23C14.55 23 15 23.45 15 24Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11 20C11 19.42 11.24 18.9 11.64 18.54C11.99 18.2 12.47 18 13 18H15C15.53 18 16.01 18.2 16.36 18.54C16.76 18.9 17 19.42 17 20V22C17 23.1 16.1 24 15 24H13C11.9 24 11 23.1 11 22V20ZM15 20H13V22H15V20Z" fill="#007BC3"/>
    <path d="M13 1C13 0.45 13.45 0 14 0C14.55 0 15 0.45 15 1V3C15 3.55 14.55 4 14 4C13.45 4 13 3.55 13 3V1Z" fill="#007BC3"/>
    <path d="M25 9C25.55 9 26 9.45 26 10C26 10.55 25.55 11 25 11H23C22.45 11 22 10.55 22 10C22 9.45 22.45 9 23 9H25Z" fill="#007BC3"/>
    <path d="M5 9C5.55 9 6 9.45 6 10C6 10.55 5.55 11 5 11H3C2.45 11 2 10.55 2 10C2 9.45 2.45 9 3 9H5Z" fill="#007BC3"/>
    <path d="M20.7103 2.71047C21.1003 2.32047 21.7303 2.32047 22.1203 2.71047C22.5103 3.10047 22.5103 3.73047 22.1203 4.12047L20.7103 5.53047C20.3203 5.92047 19.6903 5.92047 19.3003 5.53047C18.9103 5.14047 18.9103 4.51047 19.3003 4.12047L20.7103 2.71047Z" fill="#007BC3"/>
    <path d="M7.29039 2.71047C6.90039 2.32047 6.27039 2.32047 5.88039 2.71047C5.49039 3.10047 5.49039 3.73047 5.88039 4.12047L7.29039 5.53047C7.68039 5.92047 8.31039 5.92047 8.70039 5.53047C9.09039 5.14047 9.09039 4.51047 8.70039 4.12047L7.29039 2.71047Z" fill="#007BC3"/>
  </svg>
);

export default Coffee;
