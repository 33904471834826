/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { jsx } from "@emotion/core";
import Table from "components/molecules/Table";
import { css } from "@emotion/core";
import moment from "moment";
import { textFontSize } from "components/styles";
import AttendHistoryApprovalModal from "components/molecules/ChangeLogModal/Template/AttendHistoryApprovalModal";
import AttendStampModal from "components/molecules/ChangeLogModal/Template/AttendStampModal";
import Modal from "components/molecules/Modal";
import TextForm from "components/molecules/TextForm";
import { ActionButton } from "components/atoms/Button/ButtonText";
import { SubActionButton } from "components/atoms/Button";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import { useParams } from "react-router-dom";
import AttendHolidayModal from "components/molecules/ChangeLogModal/Template/AttendHolidayModal";
import AttendOvertimeModal from "components/molecules/ChangeLogModal/Template/AttendOvertimeModal";
import AttendHolidayworkModal from "components/molecules/ChangeLogModal/Template/AttendHolidayworkModal";
import AttendAgreement36Modal from "components/molecules/ChangeLogModal/Template/AttendAgreement36Modal";
import AttendTransferModal from "components/molecules/ChangeLogModal/Template/AttendTransferModal";
import AttendWorkPlanModal from "components/molecules/ChangeLogModal/Template/AttendWorkPlanModal";
import AttendStockPaidModal from "components/molecules/ChangeLogModal/Template/AttendStockPaidModal";
import AttendAllowanceModal from "components/molecules/ChangeLogModal/Template/AttendAllowanceModal";

const styles = {
  wrapper: css(
    {
      margin: "45px",
      marginTop: "0px",
      marginBottom: "30px",
    },
    css`
      table.table-info tr td {
        border: 0px;
      }
      table.table-info tr td:first-child {
        padding-left: 0px;
        font-size: 14px;
        color: rgb(102, 102, 102);
        padding-top: 15px;
        margin-bottom: 10px;
      }
    `,
  ),
  tblDetail: css({
    marginTop: "20px",
    maxWidth: "600px",
  }),
  approvalWrap: css({
    padding: "0px",
  }),
};

const AttendStampTable: React.FC<{
  attendStamp: Array<any>;
  closeConfirmApplyModal: any;
  rejectReason: string;
  setRejectReason: any;
  confirmApplyModalOpen: boolean;
  handleSubmit: any;
  buttonType: number;
  setDetail: any;
  setChangeLogModalOpen: any;
  setChangeLogData: any;
  changeLogModalOpen: any;
}> = ({
  attendStamp,
  closeConfirmApplyModal,
  rejectReason,
  setRejectReason,
  confirmApplyModalOpen,
  handleSubmit,
  buttonType,
  setDetail,
  setChangeLogModalOpen,
  setChangeLogData,
  changeLogModalOpen,
}) => {
  const [detail, setDetailTmp] = useState<any>();
  useEffect(() => {
    if (attendStamp) {
      setDetailTmp(attendStamp[0]);
    }
  }, [attendStamp]);

  const { applicationType } = useParams();

  const getDetailStamp = (applicationType: any) => {
    if (applicationType) {
      switch (applicationType) {
        case "attendStamp":
          return (
            <AttendStampModal
              changeLogData={[detail]}
              selectChangelog={"0"}
              isStamp={true}
              customStyle={styles.approvalWrap}
            />
          );
        case "attendHoliday":
          return (
            <AttendHolidayModal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendOvertime":
          return (
            <AttendOvertimeModal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendHolidaywork":
          return (
            <AttendHolidayworkModal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendAgreement36":
          return (
            <AttendAgreement36Modal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendTransfer":
          return (
            <AttendTransferModal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendWorkScheduleChange":
          return (
            <AttendWorkPlanModal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendStockPaidHoliday":
          return (
            <AttendStockPaidModal changeLogData={[detail]} selectChangelog={"0"} customStyle={styles.approvalWrap} />
          );
        case "attendAllowance":
          return (
            <AttendAllowanceModal
              details={
                detail?.detailList.map((item: any) => ({
                  allowanceApplicationDetailId: item.allowanceApplicationDetailId,
                  modifierApplicationId: item.modifierApplicationId,
                  allowanceId: item.allowanceId,
                  allowanceName: item.allowanceName,
                  allowanceValue: item.allowanceValue,
                  dispUnit: item.dispUnit,
                  comment: item.comment,
                  dispMessage: item.dispMessage,
                })) ?? []
              }
              customStyle={{ padding: "16px 0" }}
            />
          );
        default:
          break;
      }
    }
  };

  const getDetailModalLog = (applicationType: any) => {
    if (applicationType) {
      switch (applicationType) {
        case "attendStamp":
          return <ChangeLogModal changeLogData={attendStamp} isStamp={true} />;
        case "attendHoliday":
          return <ChangeLogModal changeLogData={attendStamp} isStamp={true} request="休暇申請" />;
        case "attendOvertime":
          return <ChangeLogModal changeLogData={attendStamp} request="残業申請" />;
        case "attendHolidaywork":
          return <ChangeLogModal changeLogData={attendStamp} request="休日出勤申請" />;
        case "attendAgreement36":
          return <ChangeLogModal changeLogData={attendStamp} request="45時間超過申請" />;
        case "attendTransfer":
          return <ChangeLogModal changeLogData={attendStamp} request="振替申請" />;
        case "attendWorkScheduleChange":
          return <ChangeLogModal changeLogData={attendStamp} isWorkPlan={true} />;
        case "attendStockPaidHoliday":
          return <ChangeLogModal changeLogData={attendStamp} isStamp={true} request="積立有給申請" />;
        case "attendAllowance":
          return <ChangeLogModal changeLogData={attendStamp} request="手当申請" />;
        default:
          break;
      }
    }
  };
  return (
    <React.Fragment>
      <div css={styles.wrapper}>
        <Table className="table-info">
          <tbody>
            <tr>
              <Table.Cell customStyle={css({ width: "150px" })}>
                <span>申請種別:</span>
              </Table.Cell>
              <Table.Cell>
                <span>{detail?.historyTypeName || ""}</span>
              </Table.Cell>
            </tr>
            <tr>
              <Table.Cell>
                <span>申請者:</span>
              </Table.Cell>
              <Table.Cell>
                <span>{(detail?.applicationStaffCode || "") + " " + (detail?.applicationStaffName || "")}</span>
              </Table.Cell>
            </tr>
            <tr>
              <Table.Cell>
                <span>申請日時:</span>
              </Table.Cell>
              <Table.Cell>
                <span css={css({ marginRight: "15px" })}>
                  {detail?.applicationDate ? moment(detail?.applicationDate).format("YYYY/MM/DD") : ""}
                </span>
                {detail && (
                  <SubActionButton
                    action={ActionButton.COPY}
                    text="更新履歴"
                    onClick={() => {
                      setChangeLogModalOpen(true);
                    }}
                  />
                )}
              </Table.Cell>
            </tr>
            <tr>
              <Table.Cell>
                <span>出勤組織:</span>
              </Table.Cell>
              <Table.Cell>
                <span>{(detail?.orgCode || "") + " " + (detail?.orgName || "")}</span>
              </Table.Cell>
            </tr>
          </tbody>
        </Table>
        {getDetailStamp(applicationType)}
        {applicationType !== "attendAllowance" && (
          <Table className="table-info">
            <tbody>
              <tr>
                <Table.Cell customStyle={css({ width: "150px" })}>
                  <span>修正理由:</span>
                </Table.Cell>
                <Table.Cell>
                  <span>{detail?.applicationReason || ""}</span>
                </Table.Cell>
              </tr>
            </tbody>
          </Table>
        )}

        <div
          style={{
            fontSize: `${textFontSize.re}`,
            color: "#666666",
            paddingTop: "15px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          承認状況
        </div>
        <AttendHistoryApprovalModal
          changeLogData={attendStamp}
          selectChangelog={"0"}
          isStamp={true}
          customStyle={styles.approvalWrap}
        />
      </div>

      <Modal
        open={confirmApplyModalOpen}
        closeHandler={closeConfirmApplyModal}
        onSubmit={handleSubmit}
        title={buttonType === 3 ? "申請の承認" : buttonType === 1 ? "申請の差戻" : "確認メッセージ"}
        // eslint-disable-next-line no-nested-ternary
        typeSubmit={buttonType === 1 ? "reject" : "confirm"}
        // eslint-disable-next-line no-nested-ternary
        note={
          buttonType === 0
            ? "承認します。よろしいですか？"
            : buttonType === 1
            ? "申請を差戻します。よろしいですか？"
            : buttonType === 3
            ? "申請を承認します。よろしいですか？"
            : "一括承認します。よろしいですか？"
        }
        submitText={buttonType === 1 ? "差戻する" : buttonType === 3 ? "承認する" : "承認"}
      >
        {buttonType === 1 && (
          <React.Fragment>
            <div className="labelReject">
              <TextForm
                name="rejectReason"
                label="差戻コメント"
                value={rejectReason || ""}
                placeHolder=""
                errorMsg=""
                onChange={(e) => {
                  setRejectReason(e.target.value);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          {getDetailModalLog(applicationType)}
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default AttendStampTable;
