import { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import SpecialSalaryPatternDomain from "domain/master/labor/specialSalaryPattern";
import { postSpecialSalaryPattern, getSpecialSalaryPattern } from "api/specialSalaryPattern";
import useToastNotification from "hooks/useToastNotification";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

export const useSpecialSalaryPatternAddForm = () => {
  const { specialSalaryPatternId } = useParams();

  //blocking UI
  const [blocking, setBlocking] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();

  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterSpecialSalary);

  const onSubmit = async (values: SpecialSalaryPatternDomain) => {
    if (values.specialSalaryPatternCode === null) {
      errorNotification("パターンコードを入力してください");
      return;
    }
    if (values.specialSalaryPatternName === null) {
      errorNotification("パターン名を入力してください");
      return;
    }

    if (specialSalaryPatternId) {
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      const postData = values.getRawDataPost();
      postData.sameDays = postData.sameDays.map((item: any) => item.value).toString();
      postData.fixedDays = postData.fixedDays.map((item: any) => item.value).toString();
      postData.createUser = sessionStorage.getItem("loginUser.staffName") || "";
      postData.updateUser = sessionStorage.getItem("loginUser.staffName") || "";

      const response = await postSpecialSalaryPattern(postData);
      setConfirmModalOpen(false);
      if (!response.errors) {
        if (specialSalaryPatternId) {
          successNotification("更新しました。");
        } else {
          successNotification("登録しました。");
        }

        handleBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: SpecialSalaryPatternDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (specialSalaryPatternId) {
      setBlocking(true);

      getSpecialSalaryPattern(specialSalaryPatternId).then((response: any) => {
        response.sameDays = [];
        response.fixedDays = [];

        response.sameDayList.forEach((item: any) => {
          response.sameDays.push({
            value: `${item.sameMonth}/${item.sameDay}`,
            label: `${item.sameMonth}月${item.sameDay}日`,
          });
        });

        response.fixedList.forEach((item: any) => {
          response.fixedDays.push({
            value: moment(item.fixedDate).format("YYYY/MM/DD"),
            label: moment(item.fixedDate).format("YYYY年MM月DD日"),
          });
        });

        formik.setValues(new SpecialSalaryPatternDomain(response));

        setBlocking(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialSalaryPatternId]);

  const handleBack = async () => {
    history.goBack();
  };

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  return {
    formik,
    detailRole,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    handleBack,
    blocking,
  };
};

export default {
  useSpecialSalaryPatternAddForm,
};
