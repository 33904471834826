/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import { isMobileOnly } from 'react-device-detect';
import BlockUI from 'components/molecules/BlockUi';
import { useTimeRecorderConfirm } from './hooks';

const styles = {

  header: css({
    margin: '29px 0 32px 0',
    textAlign: 'center',
  }),

  container: css({
    padding: '0 20px 0 20px',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }),

  timeTitle: css({
    fontSize: '64px',
  }),

  imageUploadContainer: css({
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E5E5',
    marginBottom: isMobileOnly ? '0px' : '22px',
  }),

  justifyCenter: css({
    justifyContent: 'center',
  }),

  name: css({
    fontSize: isMobileOnly ? '36px' : '64px',
  }),

  userContainer: css({
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
  }),

  groupButton: css({
    marginBottom: isMobileOnly ? '20px' : '20px',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }),

  justifyBetween: css({
    justifyContent: 'space-between',
  }),

};

const buttonContainer = css`
  width: ${isMobileOnly ? '100%' : '100%'} ;
  margin-top: ${isMobileOnly ? '45px' : '0'};
  button {
    height: ${isMobileOnly ? '40px' : '60px'} ;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 21px;
  }
`;

const TimeRecorderGpsStampBtn: React.FC = () => {
  const {
    stampStaff,
    handleStampEvent,
    blocking,
  } = useTimeRecorderConfirm();


  return (
    <React.Fragment>
      {/* <BodyText>{stampStaff?.staffName}</BodyText> */}
      <BlockUI blocking={blocking}>
        <FlexBox alignItems="flex-start" customStyle={styles.container}>
          <div css={buttonContainer}>
            <FlexBoxItem basis="50%">
              {
                stampStaff?.businessList.length === 0
                && (stampStaff?.lastStampType === -1 || stampStaff?.lastStampType === 3)
                  ? (
                    <FlexBox customStyle={styles.groupButton}>
                      <FlexBoxItem>
                        <PrimaryButton
                          text="出勤"
                          onClick={() => handleStampEvent('in', '')}
                          minWidth="236px"
                        />
                      </FlexBoxItem>
                    </FlexBox>
                  ) : null
              }
              {
                stampStaff?.businessList.length && stampStaff?.businessList.length > 0
                  ? (
                    <FlexBox customStyle={styles.groupButton}>
                      { stampStaff.businessList.map((business, index) => (
                        <FlexBoxItem key={business.businessId}>
                          <PrimaryButton
                            text={`${business.businessName}の開始`}
                            onClick={() => handleStampEvent('business', business.businessId)}
                            minWidth="236px"
                          />
                        </FlexBoxItem>
                      ))}
                    </FlexBox>
                  ) : null
              }
              {
                stampStaff?.lastStampType === 0 || stampStaff?.lastStampType === 2
                  ? (
                    <FlexBox customStyle={styles.groupButton}>
                      <FlexBoxItem>
                        <PrimaryButton
                          text="退勤"
                          onClick={() => handleStampEvent('out', '')}
                          minWidth="236px"
                        />
                      </FlexBoxItem>
                    </FlexBox>
                  ) : null
              }
              {
                stampStaff?.useRecess
                && (stampStaff?.lastStampType === 0 || stampStaff?.lastStampType === 2)
                  ? (
                    <FlexBox customStyle={styles.groupButton}>
                      <FlexBoxItem>
                        <PrimaryButton
                          text="休憩開始"
                          onClick={() => handleStampEvent('recess_in', '')}
                          minWidth="236px"
                        />
                      </FlexBoxItem>
                    </FlexBox>
                  ) : null
              }
              {
                stampStaff?.useRecess
                 && (stampStaff?.businessList.length === 0 && stampStaff?.lastStampType === 1)
                  ? (
                    <FlexBox customStyle={styles.groupButton}>
                      <FlexBoxItem>
                        <PrimaryButton
                          text="休憩終了"
                          onClick={() => handleStampEvent('recess_out', '')}
                          minWidth="236px"
                        />
                      </FlexBoxItem>
                    </FlexBox>
                  ) : null
              }
              {
                 stampStaff?.lastStampType === 0 || stampStaff?.lastStampType === 1
                  || stampStaff?.lastStampType === 2
                   ? (
                     <FlexBox customStyle={styles.groupButton}>
                       <FlexBoxItem>
                         <PrimaryButton
                           text="現在地登録"
                           onClick={() => handleStampEvent('current', '')}
                           minWidth="236px"
                         />
                       </FlexBoxItem>
                     </FlexBox>
                  ) : null
              }
            </FlexBoxItem>
          </div>
        </FlexBox>
      </BlockUI>
    </React.Fragment>
  );
};

export default TimeRecorderGpsStampBtn;
