/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';

const StoreCloseFilter: React.FC<{
  orgStatus: string;
  setOrgStatus: (arg: '0'|'1') => void;
}> = ({ orgStatus, setOrgStatus }) => (
  <FlexBox
    customStyle={css({
      padding: '8px',
    })}
  >
    <FlexBoxItem>
      <BodyText>状態</BodyText>
    </FlexBoxItem>
    <FlexBoxItem marginLeft="24px" basis="170px">
      <Select
        name="orgState"
        value={String(orgStatus)}
        setValue={setOrgStatus as (arg: string) => void}
        options={[
          {
            label: '設立',
            value: '0',
          },
          {
            label: '廃止',
            value: '1',
          },
        ]}
      />
    </FlexBoxItem>
  </FlexBox>
);

export default StoreCloseFilter;
