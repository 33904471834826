import React from "react";
import { css } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BodyText from "components/atoms/BodyText";
import { grayScale } from "components/styles";
import useIsMobile from "hooks/useIsMobile";
import { Row, Col } from "react-grid-system";

const EmploymentFilter: React.FC<{
  employmentId?: string;
  setEmploymentId?: (employmentId: string) => void;
}> = () => {
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      {isMobile ? (
        <React.Fragment>
          <Row>
            <Col md={1.5} xs={12} style={{ display: "flex", alignItems: "center" }}>
              <BodyText>スタッフ</BodyText>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12}>
              <BodyText color={grayScale.gray100}>
                {`${sessionStorage.getItem("loginUser.staffCode")}  ${sessionStorage.getItem("loginUser.staffName")}`}{" "}
              </BodyText>
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <FlexBox
          customStyle={css({
            padding: "8px",
          })}
        >
          <FlexBoxItem basis="100px">
            <BodyText>スタッフ</BodyText>
          </FlexBoxItem>
          <FlexBoxItem basis="400px">
            <BodyText color={grayScale.gray100}>
              {`${sessionStorage.getItem("loginUser.staffCode")}  ${sessionStorage.getItem("loginUser.staffName")}`}{" "}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
      )}
    </React.Fragment>
  );
};
export default EmploymentFilter;
