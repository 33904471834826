/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import ClickableLink from "components/atoms/ClickableLink";
import productColor, { textFontSize, grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import useIsMobile from "hooks/useIsMobile";

const style = css({
  padding: "3px 5px",
  backgroundColor: "#ffff",
  fontSize: textFontSize.re,
  ":hover": {
    backgroundColor: productColor.primaryM95,
  },
});

export interface ContextMenuItemProps {
  /**
   * 表示名
   */
  label: string;
  /**
   * リンク先
   */
  linkTo?: string;
  /**
   * リンクでなければ必須
   */
  onClick?: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const ContextMenuItem: React.FC<ContextMenuItemProps> = ({ label, linkTo, onClick, disabled = false, icon }) => {
  const isMobile = useIsMobile();

  return (
    <div css={style}>
      {disabled ? (
        <BodyText
          customStyle={css({ padding: isMobile ? "2px 0px" : "6px 8px", display: "block" })}
          color={grayScale.gray50}
        >
          {label}
        </BodyText>
      ) : (
        <ClickableLink
          to={linkTo}
          onClick={onClick}
          color={grayScale.gray100}
          padding={isMobile ? "2px 0px" : "6px 8px"}
        >
          {icon} {label}
        </ClickableLink>
      )}
    </div>
  );
};

export default ContextMenuItem;
