import React, {
  useMemo, Dispatch, SetStateAction,
} from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { usePostTypeOptionsByName } from 'hooks/usePostTypeOptions';
import useTaxTypeOptions from 'hooks/useTaxTypeOptions';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { gridCol } from 'components/styles';
import TaxRateCaptureSettingDomain from 'domain/master/sales/taxRateCaptureSetting';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import { useTaxRateForm } from './hooks';
import SelectForm from 'components/molecules/SelectForm';

const TaxRateCaptureSettingAddForm: React.FC <{
   taxRateList: TaxRateCaptureSettingDomain[],
   setTaxRateList: Dispatch<SetStateAction<TaxRateCaptureSettingDomain[]>>,
   isCreateProp : boolean,
   setIsCreateProp: Dispatch<SetStateAction<boolean>>,
   taxRateObj: TaxRateCaptureSettingDomain;
   setTaxRateObj: Dispatch<SetStateAction<TaxRateCaptureSettingDomain>>,
  }> = ({
    taxRateList, setTaxRateList, isCreateProp, setIsCreateProp, taxRateObj, setTaxRateObj,
  }) => {
    const {
      formik, confirmModalOpen, closeConfirmModal, deleteTaxRate,
      confirmModalDeleteOpen, closeConfirmModalDelete,
    } = useTaxRateForm(taxRateList, setTaxRateList, isCreateProp,
      setIsCreateProp, taxRateObj, setTaxRateObj);
    const postTypeOptions = usePostTypeOptionsByName();
    const taxTypeOptions = useTaxTypeOptions();

    // Set default value
    useMemo(() => {
      if (formik.values.posName === '' && postTypeOptions.length !== 0) {
        formik.setFieldValue('posName', postTypeOptions[0].label);
      }
      if (formik.values.taxType1Str === '' && taxTypeOptions.length !== 0) {
        formik.setFieldValue('taxType1', taxTypeOptions[0].value);
        formik.setFieldValue('taxType1Str', taxTypeOptions[0].label);
      }
      if (formik.values.taxType2Str === '' && taxTypeOptions.length !== 0) {
        formik.setFieldValue('taxType2', taxTypeOptions[0].value);
        formik.setFieldValue('taxType2Str', taxTypeOptions[0].label);
      }
      if (formik.values.taxType1Str === '' && taxTypeOptions.length !== 0) {
        formik.setFieldValue('taxType3', taxTypeOptions[0].value);
        formik.setFieldValue('taxType3Str', taxTypeOptions[0].label);
      }
      if (formik.values.taxType1Str === '' && taxTypeOptions.length !== 0) {
        formik.setFieldValue('taxType4', taxTypeOptions[0].value);
        formik.setFieldValue('taxType4Str', taxTypeOptions[0].label);
      }
      if (formik.values.taxType1Str === '' && taxTypeOptions.length !== 0) {
        formik.setFieldValue('taxType5', taxTypeOptions[0].value);
        formik.setFieldValue('taxType5Str', taxTypeOptions[0].label);
      }
    }, [formik, postTypeOptions, taxTypeOptions]);


    return (
      <FormContents>
        <form onSubmit={formik.handleSubmit}>
          <div>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <VerticalLabelSelectForm
                  label="POS種別"
                  name="posName"
                  value={formik.values.posName}
                  options={postTypeOptions}
                  setValue={(val: string) => { formik.setFieldValue('posName', val); }}
                  errorMsg={formik.errors.posName}
                  required={true}
                  disable={!isCreateProp}
                />
              </div>
            </FormField>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <VerticalLabelSelectForm
                  label="税率１"
                  name="taxType1"
                  value={String(formik.values.taxType1)}
                  options={taxTypeOptions}
                  setValue={(val: string) => {
                    formik.setFieldValue('taxType1', Number(val));
                    formik.setFieldValue('taxType1Str', taxTypeOptions.find((item) => item.value === val)?.label);
                  }}
                  required={true}
                />
              </div>
            </FormField>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <VerticalLabelSelectForm
                  label="税率２"
                  name="taxType2"
                  value={String(formik.values.taxType2)}
                  options={taxTypeOptions}
                  setValue={(val: string) => {
                    formik.setFieldValue('taxType2', Number(val));
                    formik.setFieldValue('taxType2Str', taxTypeOptions.find((item) => item.value === val)?.label);
                  }}
                  required={true}
                />
              </div>
            </FormField>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <VerticalLabelSelectForm
                  label="税率３"
                  name="taxType3"
                  value={String(formik.values.taxType3)}
                  options={taxTypeOptions}
                  setValue={(val: string) => {
                    formik.setFieldValue('taxType3', Number(val));
                    formik.setFieldValue('taxType3Str', taxTypeOptions.find((item) => item.value === val)?.label);
                  }}
                  required={true}
                />
              </div>
            </FormField>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <VerticalLabelSelectForm
                  label="税率４"
                  name="taxType4"
                  value={String(formik.values.taxType4)}
                  options={taxTypeOptions}
                  setValue={(val: string) => {
                    formik.setFieldValue('taxType4', Number(val));
                    formik.setFieldValue('taxType4Str', taxTypeOptions.find((item) => item.value === val)?.label);
                  }}
                  required={true}
                />
              </div>

            </FormField>

            <FormField>
              <div style={{ width: gridCol.grid12 }}>
                <VerticalLabelSelectForm
                  label="税率５"
                  name="taxType5"
                  value={String(formik.values.taxType5)}
                  options={taxTypeOptions}
                  setValue={(val: string) => {
                    formik.setFieldValue('taxType5', Number(val));
                    formik.setFieldValue('taxType5Str', taxTypeOptions.find((item) => item.value === val)?.label);
                  }}
                  required={true}
                />
              </div>
            </FormField>
          </div>

          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <Button
                text={isCreateProp ? '登録' : '更新'}
                onClick={formik.handleSubmit}
              />
            </div>
            <div style={{ marginRight: '12px' }}>
              <Button
                text="削除"
                onClick={deleteTaxRate}
                disabled={isCreateProp}
              />
            </div>
            <Button
              text="クリア"
              onClick={() => {
                setIsCreateProp(true);
                setTaxRateObj(TaxRateCaptureSettingDomain.generateInitial());
              }}
            />

          </FormSubmitArea>
        </form>
        <ConfirmActionModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={formik.handleSubmit}
          actionType={isCreateProp ? ActionType.CREATE : ActionType.UPDATE}
        />
        <ConfirmActionModal
          open={confirmModalDeleteOpen}
          closeHandler={closeConfirmModalDelete}
          onSubmit={deleteTaxRate}
          actionType={ActionType.DELETE}
        />
      </FormContents>

    );
  };
export default TaxRateCaptureSettingAddForm;
