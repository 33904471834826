/* eslint-disable func-names */
/** @jsx jsx */
import React, { useRef, useEffect, useState, useCallback } from "react";
import FormContents from "components/atoms/Form/FormContents";
import SelectForm from "components/molecules/SelectForm";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { css, jsx } from "@emotion/core";
import FileSelectButton from "components/molecules/FileSelectButton";
import PrimaryButton, { RecorderButton, SecondaryButton } from "components/atoms/Button";
import Webcam from "react-webcam";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import Modal from "components/molecules/Modal";
import { useMasterShopFaceRegistrationForm } from "./hooks";
import { textFontSize, grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import FormTitle from "components/atoms/Form/FormTitle";
import Cropper from 'react-easy-crop'
import getCroppedImg from 'hooks/useCropImage';
import {
  Container, Row, Col,
} from 'react-grid-system';
import { isMobileOnly, isIPad13 } from 'react-device-detect';
import Checkbox from "components/molecules/Checkbox";

const styles = {
  formContent: {
    padding: "0 30px 30px 30px",
  },
  container: css({
    marginTop: "55px",
    paddingLeft: "100px",
    textAlign: "left",
  }),
  label: css({
    flexBasis: "100px",
  }),
  canvas: css({
    width: "auto",
    height: "100%",
    margin: "auto",
  }),
  video: {
    border: "0",
    maxWidth: "100%",
    height: "100%",
  },
  noteForm: css({
    fontSize: textFontSize.re,
    color: "#ED5D5D",
    height: "calc(100% - 120px)",
    lineHeight: "25px",
    margin: "0",
  }),
  submitContainer: css({
    width: "100%",
    textAlign: "center",
    marginTop: "110px",
  }),
  photoCap: css({
    width: "auto",
    height: "100%",
    margin: "auto",
    maxWidth: "100%",
  }),
  textMessage: css({
    fontSize: "24px",
    color: grayScale.gray100,
    textAlign: "center",
  }),
  photoDefault: css({
    position: "absolute",
    height: "100%",
    left: "50%",
    transform: "translateX(-50%)"
  }),
  isHover: css({
    opacity: '0.1',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
  }),
  fileUploadButton: css`  
    background: transparent;
    button{
      width: 100%;
      text-align: left;
    }
  `,
  uploadFile: css([{
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'transparent',
    width: "fit-content",
    paddingLeft: "50px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }, css`
    button{
      padding-left: 10px;
    }
  `]),
  cropContainer: css({
    height: "250px",
    position: "relative",
    margin: "auto",
  }, css`
    .reactEasyCrop_CropArea::before, .reactEasyCrop_CropArea::after{
      border: 0;
    }  
  `),
  slider: css({
    background: "#007BC3",
    WebkitAppearance: "none",
    height: "2px",
    outline: "none !important",
    MozAppearance: "none",
    appearance: "none",
    border: "none",
    borderRadius: "30px",
    width: "400px",
    maxWidth: "100%",
    cursor: "pointer",
    margin: "40px 0 30px"
  }),
  imageCropped: css({
    cursor: "pointer",
    height: "100%",
    margin: "auto",
  }),
  buttonAction: css({
    width: "fit-content", 
    margin: "0", 
    padding: "0", 
    minWidth: "fit-content",
  }),
  showMessage: css({
    position: "absolute",
    bottom: "-30px",
    left: "0",
    display: 'none',
  }),
  buttonBack: css({
    position: "absolute",
    bottom: "-30px",
    right: "0",
    minWidth: "fit-content",
    border: "0",
    lineHeight: "20px",
    padding: "0"
  }),
  noteImage: css({
    fontSize: "12px",
    color: "#ED5D5D",
    marginBottom: "15px",
    lineHeight: "22px",
  }),
  buttonCap: css({
    height: "120px",
    width: "280px",
    borderRadius: "3px",
    background: "rgb(69, 209, 209)",
    border: "0px",
    fontSize: "24px",
    '&:hover': {
      background: "#5cd6d6",
    },
    '&:focus': {
      background: "#5cd6d6",
    }
  })
};

const videoConstraints = {
  width: 400,
  height: 250,
  facingMode: "user",
};


const MasterShopFaceRegistrationForm: React.FC = () => {
  // アップロードファイル
  const canvasRef1 = useRef<HTMLCanvasElement>(null);
  const webcamRef = useRef(null);

  const [isConfirm, setIsConfirm] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [tempFile, setTempFile] = useState<any>();
  const [capturedPhoto, setCapturedPhoto] = useState(false);
  const [imageCap, setImageCap] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [imageUpload, setImageUpload] = useState<any>();
  const [blobCap, setBlobCap] = useState<any>();
  const [isHover, setIsHover] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [isCapture, setIsCapture] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [minZoom, setMinZoom] = useState(1)
  const [isNotExistImage, setIsNotExistImage] = useState(false);
  const fileInputRef = useRef(Object());

  const {
    orgCode,
    setOrgCode,
    orgTreesOptions,
    staffCode,
    setStaffCode,
    staffsForOrgCodeOptions,
    handleSubmit,
    isLoading,
    errorNotification,
    disableButtonCap,
    setDisableButtonCap,
    croppedImage,
    setCroppedImage,
    tempModalFile,
    setTempModalFile,
    initImage,
    beforeCroppedImage,
    setBeforeCroppedImage,
    detailRole,
  } = useMasterShopFaceRegistrationForm();
  
  const handleNotExistImage = (e: any) => {
    e.target.style.display="none";
    setIsNotExistImage(true);
  }
  const handleExistImage = (e: any) => {
    e.target.style.display="block";
    setIsNotExistImage(false);
  }

  const checkChangeImage = (cropUrl: string) => {
    return initImage.slice(0, initImage.lastIndexOf("?")) !== cropUrl.slice(0, cropUrl.lastIndexOf("?"))
  }

  const onCamera = () => {
    setOpenCamera(true);
    setTempFile("");
    setIsConfirm(false);
    setDisableButtonCap(false);
    setCapturedPhoto(false);
  };

  const resetForm = () => {
    setOpenCamera(false);
    setImageUpload(null);
    setTempFile("");
    setOpenCropModal(false);
    setIsCapture(false);
    setDisableButtonCap(false);
    setCapturedPhoto(false);
    setImageCap(null);
    if(checkChangeImage(croppedImage)){
      setCroppedImage(initImage);
      setTempModalFile(initImage);
    }else{
      setCroppedImage("");
      setTempModalFile("")
    }
    setBeforeCroppedImage("");
  };

  const showCroppedImage = useCallback(async (): Promise<any> => {
    setOpenCropModal(false);
    setBeforeCroppedImage(tempModalFile);
    try {
      const croppedImageData = await getCroppedImg(
        tempModalFile,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImageData);
      return croppedImageData;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const CONTAINER_SIZE = 230;
  const CONTAINER_OUT_SIZE = 250;
  
  const [count, setCount] = useState(3);
  const [showMessage, setShowMessage] = useState(false);
  const countDown = () => {
    setDisableButtonCap(true);
    setShowMessage(true);
    const funcInterval = setInterval(() => {
      setCount((prevCount) => {
        return prevCount - 1;
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(funcInterval);
      setCount(3);
      setShowMessage(false);
      setDisableButtonCap(false);
    }, 4000);
  };
  const countTimeCap = (webcamRef: any) => {
    setTimeout(() => {
      capture(webcamRef);
    }, 3000);
  };

  const capture = useCallback((webcamRef) => {
    setDisableButtonCap(true);
    setTempModalFile('');
    setOpenCropModal(false);
    const base64string = webcamRef.current?.getScreenshot();

    if (!canvasRef1.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef1.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }

    const img = new Image();
    img.crossOrigin = "";

    // eslint-disable-next-line no-loop-func
    img.onload = () => {
      const w = img.width;
      const h = img.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = w;
      canvas.height = h;
      ctx.transform(1, 0, 0, 1, 0, 0);
      ctx.drawImage(img, 0, 0, w, h);
      setImageCap(img);
      canvas.toBlob((blob: any) => {
        setTempModalFile(URL.createObjectURL(blob));
        setOpenCropModal(true);
        setDisableButtonCap(false);
        setCapturedPhoto(true);
        setIsCapture(false);
        setDisableButtonCap(false);
        setOpenCamera(false);
      }, "image/jpeg");
    };
    img.onerror = () => {
      setDisableButtonCap(false);
    };
    img.src = base64string;
  }, []);

  const onUserMediaError = (err: any) => {
    if (err) {
      errorNotification("カメラへのアクセスに失敗しました。");
      resetForm();
    }
  };

  const onImageChange = (file: any) => {
    // setOpenCamera(false);
    setTempModalFile(URL.createObjectURL(file));
    setOpenCropModal(true);
    setIsCapture(false);
    // var width = 400;
    // var img = document.getElementById("image");
    var reader = new FileReader();

    reader.onload = function (e) {
      setTempModalFile(e.target!!.result || "");
    };

    reader.readAsDataURL(file);
    if (!file.type.match(/image.*/)) {
      return false;
    }
    setCapturedPhoto(false);
    setImageCap(null);
    setImageUpload(file);
    return true;
  };

  useEffect(() => {
    setStaffCode(undefined);
  }, [setStaffCode, orgCode]);

  const handleUpdate = () => {
    if (!staffCode) {
      errorNotification("スタッフを選択してください。");
    } else {
      setOpenModal(true);
    }
  };

  return (
    <div>
      <BlockUI blocking={isLoading}>
        <FormContents customStyle={styles.formContent}>
          <FormTitle bold={true} title="顔写真登録" customStyle={css({marginLeft: '0'})} />
          <FlexBox customStyle={css({ marginBottom: '10px' })}>
            <SelectForm
              label="対象組織"
              name="orgCode"
              value={orgCode}
              setValue={setOrgCode}
              options={orgTreesOptions}
              width="900px"
              marginRight="30px"
            />
          </FlexBox>
          <div css={css({ width: "400px" })}>
            <SelectForm
              label="スタッフ"
              name="staffCode"
              placeholder="スタッフを選択"
              value={staffCode}
              setValue={setStaffCode}
              options={staffsForOrgCodeOptions}
              width="900px"
            />
          </div>
          <Container fluid={true}>
              <Row>
                <Col lg={12} md={12} xs={12} style={{ padding: '18px 0 0 100px' }}>
                  <Row>
                    <Col style={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      width: '400px',
                      flex: 'none', 
                      height: '250px', 
                      flexWrap: "wrap",
                      border: ( croppedImage && !isNotExistImage ) ? "none" : "1px solid rgb(220, 220, 220)"}}>
                      {
                        !isCapture 
                        ? (
                          <React.Fragment>
                            <div style={{height: "250px"}} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                              {croppedImage && (
                                <img 
                                  src={croppedImage}
                                  width={250} 
                                  alt="cropped image"
                                  css={css([styles.imageCropped, isHover ? styles.isHover : {}])}
                                  onError={handleNotExistImage} onLoad={handleExistImage}
                                />
                              )}
                              <FileSelectButton
                                text="ファイルを選択"
                                setFile={(file) => onImageChange(file)}
                                value=""
                                ghost={true}
                                accept="image/jpeg,image/png"
                                customStyle={styles.fileUploadButton}
                                customStyleContainer={styles.uploadFile}
                                hidden={!isHover && croppedImage !== "" && !isNotExistImage}
                              />
                            </div>
                            <FlexBox customStyle={css({
                              width: '250px',
                              margin: 'auto',
                              justifyContent: 'space-between'
                            })}>
                              <PrimaryButton
                                text="編集"
                                ghost={true}
                                customStyle={css([styles.buttonAction, 
                                  (croppedImage === "" || isNotExistImage || !checkChangeImage(croppedImage)) 
                                  ? {visibility: 'hidden'} : {}])}
                                onClick={() => {
                                  setOpenCropModal(true);
                                  if(beforeCroppedImage){
                                    setTempModalFile(beforeCroppedImage);
                                  }
                                }}
                                fullwidth={true}
                              />
                              <SecondaryButton
                                text={checkChangeImage(croppedImage) ? "キャンセル" : "削除"}
                                customStyle={css([styles.buttonAction, (croppedImage === "" || isNotExistImage) ? {display: 'none'} : {}])}
                                ghost={true}
                                onClick={() => {
                                  resetForm();
                                }}
                              />
                            </FlexBox>
                          </React.Fragment>
                        )
                        :(
                          <React.Fragment>
                            {isCapture && (
                              <React.Fragment>
                                <Webcam
                                  screenshotQuality={1}
                                  style={{
                                  }}
                                  audio={false}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  width={400}
                                  height={250}
                                  videoConstraints={videoConstraints}
                                  onUserMediaError={onUserMediaError}
                                  mirrored={true}
                                />
                                {showMessage ? (
                                  <FlexBox customStyle={css([styles.showMessage, (openCamera && showMessage) ? {display: 'block'}: {}])}>
                                    <BodyText size="re" color={grayScale.gray50}>
                                      撮影開始まで{count}秒
                                    </BodyText>
                                  </FlexBox>
                                )
                                :(
                                  <FlexBoxItem>
                                    <SecondaryButton
                                      text="キャンセル"
                                      customStyle={styles.buttonBack}
                                      onClick={() => {
                                        setIsCapture(false);
                                      }}
                                      ghost={true}
                                    />
                                  </FlexBoxItem>
                                )}
                                
                              </React.Fragment>
                            )}
                            <canvas style={{ display: "none", width: "auto", height: "300px" }} ref={canvasRef1} className="canvas" /> 
                          </React.Fragment>
                        )
                      }
                      
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', paddingLeft: '100px', flexDirection: 'column', justifyContent: 'flex-end' }}>
                      <Row style={{ width: '100%' }}>
                        <Col lg={12} md={12}>
                          <FlexBox>
                            <BodyText customStyle={styles.noteImage}>
                              ※明るく鮮明な写真をご用意ください<br/>
                              ※ファイル形式：jpg,jpeg,png
                            </BodyText>
                          </FlexBox>
                          {
                            isCapture ? (
                              <RecorderButton
                                text="撮影開始"
                                onClick={() => {
                                  countTimeCap(webcamRef);
                                  countDown();
                                }}
                                icon="capture"
                                background="#45D1D1"
                                disabled={disableButtonCap}
                                customStyle={css({
                                  marginBottom: '0',
                                  border: '0'
                                })}
                              />
                            )
                            :(
                              <RecorderButton
                                text="撮影する"
                                onClick={() => {
                                  onCamera();
                                  setIsCapture(true);
                                }}
                                icon="capture"
                                background="#45D1D1"
                                customStyle={css({
                                  marginBottom: '0',
                                  border: '0'
                                })}
                              />
                            )
                          }
                        </Col>
                        
                        <input style={{ display: 'none' }} ref={fileInputRef} type="file" onChange={(file) => onImageChange(file)} accept=".jpg,.png,image/jpeg,image/png" />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {detailRole.editable === 1 && (
                <Row>
                  <Col
                    xs={12}
                    style={{
                      display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '100px 0'
                    }}
                  >
                    <PrimaryButton
                      text="更新"
                      onClick={() => handleUpdate()}
                      disabled={!checkChangeImage(croppedImage)}
                    />
                  </Col>
                </Row>
              )}
            </Container>
        </FormContents>
        <Modal
          open={openModal}
          closeHandler={() => setOpenModal(false)}
          title="顔写真の更新"
          submitText="更新する"
          closeText="キャンセル"
          onSubmit={() => { 
            handleSubmit(croppedImage); 
            setOpenModal(false); 
            resetForm();
          }}
          note="顔写真を更新します。よろしいですか？"
        ></Modal>
        <Modal
          open={openModalMessage}
          closeHandler={() => {}}
          title=""
          submitText=""
          closeText=""
          onSubmit={() => {}}
        >
          <div css={styles.textMessage}>撮影画像アップロード中</div>
        </Modal>

        <Modal
            open={openCropModal}
            closeHandler={() => {
              setOpenCropModal(false);
              setCapturedPhoto(false);
            }}
            title={"顔写真の登録"}
            submitText={"切り取る"}
            onSubmit={() => showCroppedImage()}
            closeText={"キャンセル"}
          >
            <FlexBox customStyle={styles.cropContainer}>
            <Cropper
                image={tempModalFile}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1 / 1}
                minZoom={minZoom}
                maxZoom={3}
                cropSize={{
                  width: 230,
                  height: 230,
                }}
                style={{
                  cropAreaStyle:{
                    color: 'rgba(0, 0, 0, 0.3)',
                  } as React.CSSProperties,
                  mediaStyle: {
                    maxWidth: '100%',
                    maxHeight: '100%'
                  },
                  containerStyle:{
                    width: "400px",
                    margin: "auto"
                  }
                }}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onMediaLoaded={(mediaSize) => {
                  setCrop({ x: 0, y: 0 })
                  // Adapt zoom based on media size to fit max height
                  if(mediaSize.naturalHeight > CONTAINER_SIZE){
                    setZoom(CONTAINER_SIZE/CONTAINER_OUT_SIZE);
                    setMinZoom(CONTAINER_SIZE/CONTAINER_OUT_SIZE);
                  }else{
                    if(mediaSize.naturalHeight < mediaSize.naturalWidth){
                      setZoom(CONTAINER_SIZE/mediaSize.naturalHeight);
                      setMinZoom(CONTAINER_SIZE/mediaSize.naturalHeight);
                    }else{
                      setZoom(CONTAINER_SIZE/mediaSize.naturalWidth);
                      setMinZoom(CONTAINER_SIZE/mediaSize.naturalWidth);
                    }
                  }
                }}
              />
            </FlexBox>
            <FlexBox justifyContent="center">
              <input 
                type="range" 
                css={styles.slider}
                aria-labelledby="Zoom"
                value={zoom}
                min={minZoom}
                max={3}
                step={0.01}
                onChange={(zoom) => setZoom(Number(zoom.target.value))}
              />
            </FlexBox>
          </Modal>
      </BlockUI>
    </div>
  );
};

export default MasterShopFaceRegistrationForm;
