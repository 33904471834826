/**
 * APIのデータ型
 */
export interface StoreManagement {
    targetYear: string;
    listStoremanagement:{
      salesBudgetTotal: any;
      salesBudgetAdvertising: any;
      salesBudgetFree: any;
      costTotal: any,
      foodCost: any;
      drinkCost: any;
      grossProfit: any;
      laborCostTotal: any;
      employeeLaborCost: any;
      partLaborCost: any;
      advertisingMediaExpense: any;
      advertisingMediaExpenseItems: any;
      landRent: any;
      otherExpensesTotal: any;
      otherVariableCosts: any;
      otherFixedCost: any;
      operatingIncome: any;
      perCustomerPrice: any;
      guestCnt: any;
      numberOfSeat: any;
    }
    listAdvertising:any,
    listAdvertisingExpense:any
}

export default class StoreManagementDomain {
  constructor(private rawData: StoreManagement) {
    // do nothing
  }

  static generateInitial(): StoreManagementDomain {
    return new StoreManagementDomain({
      targetYear: '',
      listStoremanagement: {
        salesBudgetTotal: [],
        salesBudgetAdvertising: [],
        salesBudgetFree: [],
        costTotal: [],
        foodCost: [],
        drinkCost: [],
        grossProfit: [],
        laborCostTotal: [],
        employeeLaborCost: [],
        partLaborCost: [],
        advertisingMediaExpense: [],
        advertisingMediaExpenseItems:[],
        landRent: [],
        otherExpensesTotal: [],
        otherVariableCosts: [],
        otherFixedCost: [],
        operatingIncome: [],
        perCustomerPrice: [],
        guestCnt: [],
        numberOfSeat: [],
      },
      listAdvertising: [],
      listAdvertisingExpense:[]
    });
  }

  getRawData(): StoreManagement {
    return this.rawData;
  }

  get targetYear(): String {
    return this.rawData.targetYear;
  }

  set salesBudgetTotal(salesBudgetTotal: any) {
    this.rawData.listStoremanagement.salesBudgetTotal = salesBudgetTotal;
  }

  get salesBudgetTotal(): any {
    return this.rawData.listStoremanagement.salesBudgetTotal;
  }

  get salesBudgetAdvertising(): any {
    return this.rawData.listStoremanagement.salesBudgetAdvertising;
  }

  set salesBudgetAdvertising(salesBudgetAdvertising: any) {
    this.rawData.listStoremanagement.salesBudgetAdvertising = salesBudgetAdvertising;
  }

  get salesBudgetFree(): any {
    return this.rawData.listStoremanagement.salesBudgetFree;
  }

  set salesBudgetFree(salesBudgetFree: any) {
    this.rawData.listStoremanagement.salesBudgetFree = salesBudgetFree;
  }

  get costTotal(): any {
    return this.rawData.listStoremanagement.costTotal;
  }

  set costTotal(costTotal: any) {
    this.rawData.listStoremanagement.costTotal = costTotal;
  }

  get foodCost(): any {
    return this.rawData.listStoremanagement.foodCost;
  }

  set foodCost(foodCost: any) {
    this.rawData.listStoremanagement.foodCost = foodCost;
  }

  get drinkCost(): any {
    return this.rawData.listStoremanagement.drinkCost;
  }

  set drinkCost(drinkCost: any) {
    this.rawData.listStoremanagement.drinkCost = drinkCost;
  }

  get grossProfit(): any {
    return this.rawData.listStoremanagement.grossProfit;
  }

  set grossProfit(grossProfit: any) {
    this.rawData.listStoremanagement.grossProfit = grossProfit;
  }

  get laborCostTotal(): any {
    return this.rawData.listStoremanagement.laborCostTotal;
  }

  set laborCostTotal(laborCostTotal: any) {
    this.rawData.listStoremanagement.laborCostTotal = laborCostTotal;
  }

  get employeeLaborCost(): any {
    return this.rawData.listStoremanagement.employeeLaborCost;
  }

  set employeeLaborCost(employeeLaborCost: any) {
    this.rawData.listStoremanagement.employeeLaborCost = employeeLaborCost;
  }

  get partLaborCost(): any {
    return this.rawData.listStoremanagement.partLaborCost;
  }

  set partLaborCost(partLaborCost: any) {
    this.rawData.listStoremanagement.partLaborCost = partLaborCost;
  }

  get advertisingMediaExpense(): any {
    return this.rawData.listStoremanagement.advertisingMediaExpense;
  }

  set advertisingMediaExpense(advertisingMediaExpense: any) {
    this.rawData.listStoremanagement.advertisingMediaExpense = advertisingMediaExpense;
  }

  get landRent(): any {
    return this.rawData.listStoremanagement.landRent;
  }

  set landRent(landRent: any) {
    this.rawData.listStoremanagement.landRent = landRent;
  }

  get otherExpensesTotal(): any {
    return this.rawData.listStoremanagement.otherExpensesTotal;
  }

  set otherExpensesTotal(otherExpensesTotal: any) {
    this.rawData.listStoremanagement.otherExpensesTotal = otherExpensesTotal;
  }

  get otherVariableCosts(): any {
    return this.rawData.listStoremanagement.otherVariableCosts;
  }

  set otherVariableCosts(otherVariableCosts: any) {
    this.rawData.listStoremanagement.otherVariableCosts = otherVariableCosts;
  }

  get otherFixedCost(): any {
    return this.rawData.listStoremanagement.otherFixedCost;
  }

  set otherFixedCost(otherFixedCost: any) {
    this.rawData.listStoremanagement.otherFixedCost = otherFixedCost;
  }

  get operatingIncome(): any {
    return this.rawData.listStoremanagement.operatingIncome;
  }

  set operatingIncome(operatingIncome: any) {
    this.rawData.listStoremanagement.operatingIncome = operatingIncome;
  }

  get perCustomerPrice(): any {
    return this.rawData.listStoremanagement.perCustomerPrice;
  }

  set perCustomerPrice(perCustomerPrice: any) {
    this.rawData.listStoremanagement.perCustomerPrice = perCustomerPrice;
  }

  get guestCnt(): any {
    return this.rawData.listStoremanagement.guestCnt;
  }

  set guestCnt(guestCnt: any) {
    this.rawData.listStoremanagement.guestCnt = guestCnt;
  }

  get numberOfSeat(): any {
    return this.rawData.listStoremanagement.numberOfSeat;
  }

  set numberOfSeat(numberOfSeat: any) {
    this.rawData.listStoremanagement.numberOfSeat = numberOfSeat;
  }

  get listStoremanagement():object {
    return this.rawData.listStoremanagement;
  }

  set listAdvertising(listAdvertising: any) {
    this.rawData.listAdvertising = listAdvertising;
  }

  get listAdvertising():any {
    return this.rawData.listAdvertising;
  }

  set listAdvertisingExpense(listAdvertisingExpense: any) {
    this.rawData.listAdvertisingExpense = listAdvertisingExpense;
  }

  get listAdvertisingExpense():any {
    return this.rawData.listAdvertisingExpense;
  }

  set advertisingMediaExpenseItems(advertisingMediaExpenseItems: any) {
    this.rawData.listStoremanagement.advertisingMediaExpenseItems = advertisingMediaExpenseItems;
  }

  get advertisingMediaExpenseItems():any {
    return this.rawData.listStoremanagement.advertisingMediaExpenseItems;
  }
  

}
