import { useState, useCallback, useEffect,useContext } from 'react';
import { useFormik } from 'formik';
import { getAttendSettingOrganization, updateAttendSettingOrganization } from 'api/timeSetting';
import OrganizationSettingDomain from 'domain/master/labor/organizationSetting';
import useToastNotification from 'hooks/useToastNotification';
import { Context } from 'components/pages/master/laborAgreement/AgreementMaster/AgreementMasterPage/TimeSettingsPage/TimeSettingPage';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useSettingUpdateForm = (orgCode: string) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterAttendSetting);

  const onSubmit = async (organization: OrganizationSettingDomain) => {
    const OrganizationSetting = organization.getRawDataWithoutNullData();

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    setIsLoading(true);
    updateAttendSettingOrganization(companyCode, orgCode, OrganizationSetting)
      .then((response: any) => {
        setConfirmModalOpen(false);
        successNotification('登録しました。');
        getAttendSettingOrganization(companyCode, orgCode).then((response: any) => {
          formik.setValues(new OrganizationSettingDomain(response));
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          errorNotification('サーバー側でエラーが発生しました。');
        });
      })
      .catch((error: any) => {
        setConfirmModalOpen(false);
        setIsLoading(false);
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let msgError = '';
          error.response.data.errors.map((iteam: { defaultMessage: string; }) => {
            msgError += `${iteam.defaultMessage} \n`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  };

  const formik = useFormik({
    initialValues: OrganizationSettingDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    setIsLoading(true);
    getAttendSettingOrganization(companyCode, orgCode).then((response: any) => {
      setIsLoading(false);
      // formik.setValues(new TimeSettingDomain(response));
      formik.setValues(new OrganizationSettingDomain(response));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    isLoading,
    detailRole
  };
};


export default useSettingUpdateForm;
