/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import FormContents from 'components/atoms/Form/FormContents';
import FormTitle from 'components/atoms/Form/FormTitle';
import useYearlyAgreement36Form, { YearlyInfoAgreement36Data } from './hooks';
import CalendarForm from 'components/molecules/CalendarForm';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FlexBox from 'components/atoms/FlexBox';
import FormPadding from 'components/atoms/Form/FormPadding';
import FormLabel from 'components/atoms/Form/FormLabel';
import productColor from 'components/styles';
import SelectForm from "components/molecules/SelectForm";
import Checkbox from 'components/molecules/Checkbox';
import { Column } from 'react-table';
import DataTable from 'components/organismos/DataTable/DataTable';
import TextTooltip from 'components/molecules/TextTooltip';
import ModalDetailStaff from './../ModalDetailStaff'
import Modal from 'components/molecules/Modal';
import BodyText from 'components/atoms/BodyText';
import YearPicker from "components/molecules/YearPicker/YearPicker";
import ClickableIconButton from 'components/atoms/ClickableIconButton';
import Icon from 'components/atoms/Icon';
import moment from 'moment';
import RadioSelectForm from 'components/molecules/RadioSelectForm';

const YearlyAgreement36Form: React.FC = () => {
  // アップロードファイル


  const styles = {
    title: css({
      fontSize: "18px",
      color: "#333333",
      fontWeight: 700,
      marginLeft: '0'
    }),
    errText: css({
      color: productColor.deleteAction,
      textDecoration: 'underline',
      fontSize: '14px'
    }),
    alertLabel: css({
      margin: '0 15px'
    }),
    tableContainer: css({
      padding: '15px 35px',
      width: 'calc( 50% - 70px )',
      alignSelf: 'flex-start'
    }),
    tableContent: css`
      td{
        :last-child{
          color: ${productColor.primary}
        }
        text-align: center
      }
    `,
    titleTable: css({
      fontWeight: 'bold',
    }),
    errorNote: css({
      color: productColor.secondary
    }),
    warningNote: css({
      color: productColor.copyAction
    }),
    countLabel: css({
      fontSize: '17px',
      fontWeight: 'bold',
      margin: '0'
    }),
    detailLink: css({
      color: productColor.primary
    }),
    detailText: css({
      color: productColor.primary,
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer'
      }
    }),
    labelRadio: css({
      marginBottom: '10px'
    })
  }

  const getOverTimeFlag = (flag: number) => {
    let tmpStyle = {
      margin: '-14px -10px',
      padding: '14px'
    }
    switch (flag) {
      case 1:
        return { ...tmpStyle, backgroundColor: productColor.copyAction }
      case 2:
        return { ...tmpStyle, backgroundColor: productColor.secondary }
      default:
        return tmpStyle
    }
  }

  const getAveOverHolidayTimeFlag = (flag: number) => {
    let tmpStyle = {
      margin: '-14px -10px',
      padding: '14px'
    }
    switch (flag) {
      case 1:
        return { ...tmpStyle, backgroundColor: productColor.copyAction }
      case 2:
        return { ...tmpStyle, backgroundColor: productColor.secondary }
      default:
        return tmpStyle
    }
  }

  const getStyleLabel = (index: number, maxLength: number) => {
    switch (index) {
      case maxLength - 2:
        return css({ color: productColor.copyAction })
      case maxLength - 1:
        return css({ color: productColor.secondary })
      default:
        return css({})
    }
  }

 
  const {
    isLoading,
    applyYear,
    setApplyYear,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    yearlyAgreement36Data,
    yearlyInfoData,
    staffDetail,
    isOpenModal,
    setIsOpenModal,
    handleOpenDetail,
    underFlag,
    setUnderFlag,
    detailRole,
    agreementsId,
    setAgreementsId,
    agreementsOptions,
    overTime,
    setOverTime,
    overTimeOptions,
    averageOverTime,
    setAverageOverTime,
    averageOverTimeOptions,
    overCount,
    setOverCount,
    overCountOptions,
  } = useYearlyAgreement36Form();

  const columns: Array<Column<YearlyInfoAgreement36Data>> = React.useMemo(() => [
    {
      Header: "スタッフ名",
      accessor: 'x',
      disableSortBy: true,
      id: 'actionButton',
      Cell: (cell: { row: { isExpanded: boolean, original: YearlyInfoAgreement36Data } }) => {
        // eslint-disable-next-line
        return (
          <FlexBox>
            <TextTooltip text={cell.row.original.staffName} textDis={(
              <div className="ellipsis headline-cell-width-120"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '140px'
                }}>
                <span>{cell.row.original.staffName}</span>
              </div>
            )} />
            <BodyText customStyle={styles.detailText} onClick={() => handleOpenDetail(cell.row.original.staffCode, cell.row.original.agreementsId)}>
              詳細
            </BodyText>
          </FlexBox>
        );
      },
    },
    {
      Header: '組織名',
      accessor: 'orgName',
    },
    {
      Header: '年法定外時間',
      accessor: 'overTimeYear',
      Cell: (cell: { row: { isExpanded: boolean, original: YearlyInfoAgreement36Data } }) => {
        // eslint-disable-next-line
        return (
          <div css={css(getOverTimeFlag(cell.row.original.overTimeYearFlag))}>
            {cell.row.original.overTimeYearHour}
          </div>
        )
      },
    },
    {
      Header: () => (<React.Fragment>月平均残業<br />アラート回数</React.Fragment>),
      accessor: 'aveOverAlertCnt',
      Cell: (cell: { row: { isExpanded: boolean, original: YearlyInfoAgreement36Data } }) => {
        return (
            <div css={css(getAveOverHolidayTimeFlag(cell.row.original.aveOverFlag))}>
            {cell.row.original.aveOverAlertCnt < 0 ? '' : cell.row.original.aveOverAlertCnt + '回'}
          </div>
          )
      },
    },
    {
      Header: () => (<React.Fragment>{yearlyAgreement36Data.monthOverConditionsHour}時間超過回数<br />（法定外労働）</React.Fragment>),
      accessor: 'overTimeAlertCnt',
      Cell: (cell: { row: { isExpanded: boolean, original: YearlyInfoAgreement36Data } }) => {
        return (
          <div css={css(getOverTimeFlag(cell.row.original.overTimeFlag))}>
            {cell.row.original.overTimeAlertCnt}回
          </div>
        )
      },
    },
    {
      Header: () => (<React.Fragment>{yearlyAgreement36Data.monthlyOverAndHolidayTimeHour}時間超過回数<br />（法定外＋休日出勤）</React.Fragment>),
      accessor: 'overHolidayTimeAlertCnt',
      Cell: (cell: { row: { isExpanded: boolean, original: YearlyInfoAgreement36Data } }) => {
        return (
          <div css={css(getAveOverHolidayTimeFlag(cell.row.original.overHolidayTimeFlag))}>
            {cell.row.original.overHolidayTimeAlertCnt < 0 ? '' : cell.row.original.overHolidayTimeAlertCnt + '回'} 
          </div>
        )
      },
    },
  ], [yearlyAgreement36Data]);

  return (
    <BlockUI blocking={isLoading}>
      <FormContents customStyle={css({
        paddingBottom: '35px'
      })}>
        <FormTitle bold={true} title="年次" />
        <FormPadding customStyle={css({ marginBottom: '20px' })}>
          <FlexBox customStyle={css({ margin: '10px 0' })}>
            <FormLabel label="対象年" customStyle={css({ width: '100px', margin: '0' })} />
            {/* <FlexBoxItem>
              <ClickableIconButton onClick={() => {  
                const subtractYear = applyYear - 1;
                setApplyYear(subtractYear);
              }} additionalCss={css({ marginLeft: '-35px' })}>
                <Icon type="chevronLeft" color="" size="24px" />
              </ClickableIconButton>
            </FlexBoxItem> */}
            <FlexBoxItem>
              <YearPicker
                openActionChevron={true}
                selectedYear={applyYear}
                onChange={(year: any) => {
                  setApplyYear(year);
                }}
              />
            </FlexBoxItem>
            {/* <FlexBoxItem>
              <ClickableIconButton onClick={() => {
                const subtractYear = applyYear + 1;
                setApplyYear(subtractYear);
              }}>
                <Icon type="chevronRight" color="" size="24px" />
              </ClickableIconButton>
            </FlexBoxItem> */}
          </FlexBox>
          {detailRole.accessRange !== 0 && (
            <FlexBox customStyle={css({ margin: '10px 0px' })}>
                <SelectForm
                  label="36協定"
                  width="900px"
                  widthLabel="100px"
                  name="agreementsId"
                  value={String(agreementsId)}
                  setValue={(v) => {
                    setAgreementsId(v);
                  }}
                  options={agreementsOptions}
                />
            </FlexBox>
            )}
          {detailRole.accessRange !== 0 && (
            <FlexBox customStyle={css({ margin: '10px 0' })}>
              <SelectForm
                label="組織"
                name="orgCode"
                value={String(orgCode)}
                setValue={(v) => {
                  setOrgCode(v);
                }}
                options={orgTreesOptions}
                description=""
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
              <Checkbox
                id={'underFlag'}
                name={'underFlag'}
                label={"配下指定"}
                value={`underFlag`}
                checked={Boolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1)
                }}
              />
            </FlexBox>
          )}       
          <FlexBox customStyle={css({ margin: '10px 0' })}>
           <FormLabel label="年法定外時間" customStyle={css({ margin: '0', width: '120px' })} />
            <div css={styles.labelRadio}>
              <RadioSelectForm css={styles.labelRadio}
                label=""
                items={overTimeOptions}
                name="overTime"
                value={String(overTime)}
                setValue={(e) => {setOverTime(Number(e.target.value)); }}
            />
            </div>
          </FlexBox>
          <FlexBox customStyle={css({ margin: '10px 0' })}>
           <FormLabel label="月平均残業" customStyle={css({ margin: '0', width: '120px' })} />
            <div css={styles.labelRadio}>
              <RadioSelectForm css={styles.labelRadio}
                label=""
                items={averageOverTimeOptions}
                name="averageOverTime"
                value={String(averageOverTime)}
                setValue={(e) => {setAverageOverTime(Number(e.target.value)); }}
            />
            </div>
          </FlexBox>
          <FlexBox customStyle={css({ margin: '10px 0' })}>
           <FormLabel label="届出限度時間超過" customStyle={css({ margin: '0', width: '120px' })} />
            <div css={styles.labelRadio}>
              <RadioSelectForm css={styles.labelRadio}
                label=""
                items={overCountOptions}
                name="overCount"
                value={String(overCount)}
                setValue={(e) => {setOverCount(Number(e.target.value)); }}
            />
            </div>
          </FlexBox>
{/*          <FlexBox customStyle={css({ margin: '20px 0' })}>
            <FormLabel label="届出限度時間超過" customStyle={css({ margin: '0', width: '150px' })} />
            {yearlyAgreement36Data.monthlyOverConditionsCountList?.map((item, index) => (
              <FlexBoxItem customStyle={css({ marginRight: '20px' })}>
                <Checkbox
                  id={`checkboxThresholdCondition${index}`}
                  name={`checkboxThresholdCondition${index}`}
                  label={`${item}回超過`}
                  value={`checkboxThresholdCondition${index}`}
                  checked={checkboxThresholdCondition[index]}
                  onChange={() => {
                    let tmpList = [...checkboxThresholdCondition];
                    tmpList[index] = !tmpList[index];
                    setCheckboxThresholdCondition(tmpList);
                  }}
                  customStyleLabel={getStyleLabel(index, yearlyAgreement36Data.monthlyOverConditionsCountList?.length || 0)}
                />
              </FlexBoxItem>
            ))}
          </FlexBox>
                */}
        </FormPadding>
        <div css={css`
          overflow-x: auto;
          white-space: nowrap;
          table th:nth-of-type(1) {
            width: 200px;
          }
          table td:nth-of-type(n+3){
            text-align: center
          }
        `}>
          <DataTable className="table-no-border-left-right"
            columns={columns}
            data={yearlyInfoData || []}
            isGlobalFilter={false}
            minWidth="632px"
          />
        </div>

      </FormContents>
      <Modal
        open={isOpenModal}
        closeHandler={() => setIsOpenModal(false)}
        title="時間外労働一覧"
        submitText=""
        closeText="閉じる"
        onSubmit={() => { }}
      >
        {!isLoading && staffDetail && staffDetail.length > 0 && (
          <ModalDetailStaff
            staffDetail={staffDetail}
          />
        )}
      </Modal>
    </BlockUI>
  );
};

export default YearlyAgreement36Form;
