import React, { SVGProps } from "react";

const Analytics: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_191_30)">
      <path
        d="M19 3.4538H5C3.9 3.4538 3 4.3538 3 5.4538V19.4538C3 20.5538 3.9 21.4538 5 21.4538H19C20.1 21.4538 21 20.5538 21 19.4538V5.4538C21 4.3538 20.1 3.4538 19 3.4538ZM9 17.4538H7V12.4538H9V17.4538ZM13 17.4538H11V14.4538H13V17.4538ZM13 12.4538H11V10.4538H13V12.4538ZM17 17.4538H15V7.4538H17V17.4538Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_191_30">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.453796)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Analytics;
