import { useState, useEffect, useCallback, useMemo } from 'react';
import { getTableAgreement36Data, getAlertAgreement36, getTableAlertAgreement36Data } from 'api/agreement36';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import { getStampSubTotal } from 'api/achievementConfirm';
import { useOrgTreesOptionsForAgreements } from 'hooks/useOrgTreesOptions';
import { getTreeCurrentByStaff, getTreesOrgCodeNoRole } from 'api/organization';
import { OptionType } from 'components/atoms/Select';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import  AttendSetting36AgreementDomain from 'domain/master/attend/attendSetting36Agreement';
import { getAgreementsForSelectBoxByMonth } from 'api/master36Agreement';
import { useAgreementsOptionsByMonth } from 'hooks/useAgreementsOptions';

const { errorNotification, successNotification } = useToastNotification();

export type Agreement36Data = {
  allWorkTime: string,
  applying: boolean,
  daytimeWeekdayOvertime: string,
  dayTimeUnApprovedOverTime: string,
  effectiveStartDate: string,
  nextGrantDate: string,
  remainDaysNum: number,
  remainTimesNum: number,
  digestedDaysNum: number,
  digestedTimesNum: number,
  monthOverConditionsHour: number,
  monthlyOverApplicationStatus: number,
}

export type Agreement36Alert = {
  isApproval: number,
  approvalCount: number,
  errorCount: number,
  alertCount: number,
  agreementsId: string,
}

export type Attend36AgreementsMyData = {
  overtimeAlertCntAry: Array<any>,
  overConditionsAlertCntAry: Array<any>,
  averageTimeAlertCntAry: Array<any>,
  monthlyOvertimeAlertCntAry: Array<any>,
  overHolidayTimeAlertCntAry: Array<any>,
  agreementsMst: {
    targetYear: number
    monthOverConditionsHour: number,
    averageOvertimeHour: number,
    monthlyOverAndHolidayTimeHour: number,
  },
}

const dataTest = {
  aveOverAlertCnt: 100,
  overHolidayTimeAlertCnt: 200,
  overtimeAlertCntAry: [[300, 100], [400, 100], [400, 100]],
  overConditionsAlertCntAry: [[500, 100], [600, 200], [400, 100]],
  agreementsMst: {
    monthOverConditionsHour: 700,
    averageOvertimeHour: 800,
    monthlyOverAndHolidayTimeHour: 900,
  },
}

export const useAttendAgreement36 = () => {
  const closingDate = Number(sessionStorage.getItem('loginUser.closingDate'));
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || "";
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') || '');


  const [isLoading, setIsLoading] = useState(false);
  const [tableAgreement36Data, setTableAgreement36Data] = useState<Agreement36Data>();
  const [alertAgreement36Data, setAlertAgreement36Data] = useState<Agreement36Alert>();
  const [myAlertAgreement36Data, setMyAlertAgreement36Data] = useState<Attend36AgreementsMyData>();
  const [applyDate, setApplyDate] = useState(new Date(sessionStorage.getItem('agreement36.applyTargetDate') || new Date()) || new Date());
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  // const [orgTreesOptions, setOrgTreesOptions] = useState<Array<OptionType>>([]);
  const [isReloader, setIsReloader] = useState<boolean>(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.agreement36);
  const [underFlag, setUnderFlag] = useState(0);
  const [agreementsId, setAgreementsId] = useState('');
  const agreementsOptions = useAgreementsOptionsByMonth(applyDate, functionCode.agreement36);
  const orgTreesOptions = useOrgTreesOptionsForAgreements(true, functionCode.agreement36, agreementsId? agreementsId : '', moment(applyDate).format('YYYY-MM-DD'), moment(applyDate).format('YYYY-MM-DD'));
 
  useEffect(() => {
    const employmentCode = sessionStorage.getItem('loginUser.employmentCode');
    if (!employmentCode || employmentCode === 'null' || employmentCode === '') {
      errorNotification("雇用形態はまだ設定されません。管理者にご連絡ください。");
    }
  }, [])

  // useMemo(async () => {
  //   const startDate = moment().format('YYYY-MM-DD');
  //   await getTreesOrgCodeNoRole(String(startDate), String(startDate)).then((trees) => {
  //     getTreeCurrentByStaff(staffCode, String(startDate), String(startDate)).then((orgCurrent: any) => {
  //       if (orgCurrent) {
  //         setOrgCode(orgCurrent[0].orgCode);
  //       }
  //     });
  //     const listOrgCode = trees.map((tree) => ({
  //       value: tree.orgCode,
  //       label: `${tree.orgCode} ${tree.orgName}`,
  //     }));
  //     setOrgTreesOptions(listOrgCode);
  //     setIsReloader(true);
  //   });
  // }, [applyDate])

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const params = {
      orgCode: sessionStorage.getItem('loginUser.orgCode'),
      staffCode: sessionStorage.getItem('loginUser.staffCode'),
      periodFrom: moment(applyDate).startOf('month').format('YYYY-MM-DD'),
      periodTo: moment(applyDate).endOf('month').format('YYYY-MM-DD'),
      targetDate: moment(applyDate).startOf('month').format('YYYY-MM-DD')
    }; 
    const paramsAlert = {
      targetMonth: moment(applyDate).format('YYYY/MM/01')
    }; 
    try {
      getTableAgreement36Data(params).then((response: any) => {
        setTableAgreement36Data(response);
      }).catch((error: any) => {
        setIsLoading(false);
        if (error.status === 400) {
          if (error.data.errors && error.data.errors.length > 0) {
            errorNotification(
              error.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.data.defaultMessage) {
            errorNotification(error.data.defaultMessage);
          } else {
            errorNotification(error.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }  
      })
      getAlertAgreement36(paramsAlert).then((response: any) => {
        setDateFrom(response.dateFrom);
        setDateTo(response.dateTo);
        setAgreementsId(response.agreementsId);
        setAlertAgreement36Data(response);
      }).catch((error: any) => {
        if (error.status === 400) {
          if (error.data.errors && error.data.errors.length > 0) {
            errorNotification(
              error.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.data.defaultMessage) {
            errorNotification(error.data.defaultMessage);
          } else {
            errorNotification(error.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      }).finally(() => {
        setIsLoading(false);
      })
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [applyDate]);

  useEffect(() => {
    fetchData();
  }, [applyDate]);

  useEffect(() => {
      setIsLoading(true);
      const paramsTableAlert = {
        orgCode: detailRole.accessRange === 0 ? '' : orgCode,
        targetMonth: moment(applyDate).format('YYYY/MM/01'),
        underFlag,
        agreementsId,
      }; 
      getTableAlertAgreement36Data(paramsTableAlert).then((response: any) => {
        if(!response || response.aagreementsMst === null){
//          errorNotification('36協定アラートマスタが登録されていません');
        }
        setMyAlertAgreement36Data(response);
      }).catch((error: any) => {
        if (error.status === 400) {
          if (error.data.errors && error.data.errors.length > 0) {
            errorNotification(
              error.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.data.defaultMessage) {
            errorNotification(error.data.defaultMessage);
          } else {
            errorNotification(error.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }  
      }).finally(() => {
        setIsLoading(false);
      })
  }, [applyDate, orgCode, underFlag, agreementsId]);

  useEffect(() => {
    if (agreementsOptions && agreementsOptions.length && agreementsId) {
      setAgreementsId(agreementsOptions.some(b => b.value === agreementsId) ? agreementsId : agreementsOptions[0].value);
    } else {
      setAgreementsId('');
    }
  }, [agreementsOptions]);

  return {
    isLoading,
    tableAgreement36Data,
    applyDate,
    setApplyDate,
    alertAgreement36Data,
    staffCode,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    myAlertAgreement36Data,
    dateFrom,
    dateTo,
    setIsReloader,
    detailRole,
    underFlag,
    setUnderFlag,
    agreementsId,
    setAgreementsId,
    agreementsOptions,
  };
};

export default useAttendAgreement36;
