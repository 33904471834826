import React, { SVGProps } from "react";

const FactCheck: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_166_598)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3.4538H4C2.9 3.4538 2 4.3538 2 5.4538V19.4538C2 20.5538 2.9 21.4538 4 21.4538H20C21.1 21.4538 22 20.5538 22 19.4538V5.4538C22 4.3538 21.1 3.4538 20 3.4538ZM10 17.4538H5V15.4538H10V17.4538ZM10 13.4538H5V11.4538H10V13.4538ZM10 9.4538H5V7.4538H10V9.4538ZM14.82 15.4538L12 12.6138L13.41 11.2038L14.82 12.6238L17.99 9.4538L19.41 10.8738L14.82 15.4538Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_166_598">
        <rect width="24" height="24" fill="white" transform="translate(0 0.453796)" />
      </clipPath>
    </defs>
  </svg>
);

export default FactCheck;
