/* eslint-disable camelcase */
import React from "react";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BlockUI from "components/molecules/BlockUi";
import TabControl from "components/atoms/TabControl";
import FormLabel from "components/atoms/Form/FormLabel";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";

import moment from "moment";
import useSalesMonthlyReport from "./hooks";
import SalesReportTable from "./SalesReportTable";
import HourlyReport from "./HourlyReport";
import CashRegisterReport from "./CashRegisterReport";
import { css } from "@emotion/core";

type SelectType = "dailyReport" | "cashRegisterReport" | "hourlyReport";
const SelectType: { [key in SelectType]: SelectType } = {
  dailyReport: "dailyReport",
  cashRegisterReport: "cashRegisterReport",
  hourlyReport: "hourlyReport",
};

const SalesDailyReportLayout = () => {
  const {
    targetOrgCode,
    countAdvertising,
    generalData,
    dataKeyName,
    grandTotalnData,
    sameDayData,
    advertisingData,
    isLoading,
    setIsLoading,
    targetDay,
    cashRegisterReport,
    reportByTimeOfDay,
  } = useSalesMonthlyReport();

  const [selectType, setSelectType] = React.useState<SelectType>(
    SelectType.dailyReport
  );

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem basis="8%">
            <FormLabel label="営業日：" />
          </FlexBoxItem>
          <FlexBoxItem basis="1%" />

          <FlexBoxItem basis="20%">
            <FormLabel
              label={
                sessionStorage.getItem("SalesDailyReport.targetDay")
                  ? moment(
                      String(
                        sessionStorage.getItem("SalesDailyReport.targetDay")
                      )
                    ).format("YYYY年MM月DD日")
                  : ""
              }
            />
          </FlexBoxItem>
        </FlexBox>

        <FlexBox>
          <FlexBoxItem basis="8%">
            <FormLabel label="組織名：" />
          </FlexBoxItem>
          <FlexBoxItem basis="1%" />

          <FlexBoxItem basis="20%">
            <FormLabel
              label={
                sessionStorage.getItem("SalesDailyReport.targetOrgName")
                  ? String(
                      sessionStorage.getItem("SalesDailyReport.targetOrgName")
                    )
                  : ""
              }
            />
          </FlexBoxItem>
        </FlexBox>

        <div style={{ marginTop: "20px" }}>
          <BlockUI blocking={isLoading}>
            <TabControl
              items={[
                {
                  value: SelectType.dailyReport,
                  display: " レポート ",
                },
                {
                  value: SelectType.cashRegisterReport,
                  display: " レジレポート ",
                },
                {
                  value: SelectType.hourlyReport,
                  display: " 時間帯別レポート（入店） ",
                },
              ]}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              customStyle={css({
                margin: "0px",
                padding: "8px 16px",
                boxSizing: "border-box",
                maxWidth: "100%",
                overflow: "auto",
              })}
            />
            <div style={{ marginTop: "20px" }}>
              {/* 有給・管理情報 */}
              {selectType === SelectType.dailyReport && (
                <SalesReportTable
                  grandTotalnData={grandTotalnData}
                  sameDayData={sameDayData}
                  generalData={generalData}
                  dataKeyName={dataKeyName}
                  countAdvertising={countAdvertising}
                  advertisingData={advertisingData}
                  targetDay={targetDay}
                />
              )}
              {/* 特休・管理情報 */}
              {selectType === SelectType.cashRegisterReport && (
                <CashRegisterReport
                  dailyData={generalData}
                  cashRegisterReport={cashRegisterReport}
                  setIsLoading={setIsLoading}
                  targetOrgCode={targetOrgCode}
                  targetDay={targetDay}
                />
              )}

              {/* 代休・取得状況 */}
              {selectType === SelectType.hourlyReport && (
                <HourlyReport reportByTimeOfDay={reportByTimeOfDay} />
              )}
            </div>
          </BlockUI>
        </div>

        <FormSubmitArea>
          <Link to="/salesMonthlyReport">
            <Button text="戻る" ghost={true} />
          </Link>
        </FormSubmitArea>
      </FormContents>
    </div>
  );
};
export default SalesDailyReportLayout;
