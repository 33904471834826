/** @jsx jsx */
import React from "react";
import { grayScale } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import moment from "moment";
import RejectedHolidayDomain from "../domain/rejectedHolidayDomain";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useApplyingTransferForm } from "../ApplyingTab/hook";
import Modal from "components/molecules/Modal";

const HolidayAccordion: React.FC<{ list: RejectedHolidayDomain[] }> = ({ list }) => {
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLogHoliday,
  } = useApplyingTransferForm(list);

  const columns = React.useMemo(
    () => [
      {
        Header: "差戻日時",
        accessor: "approvalDate",
      },
      {
        Header: "差戻者",
        accessor: "authorizer",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: (any: any) => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <div>
                <div>{cell.row.original.targetDate}</div>
                <IconLabelButton
                  onClick={() => {
                    fetchChangeLogHoliday(cell.row.original.modifierApplicationId);
                    setChangeLogModalOpen(true);
                    setSelectChangelog("0");
                  }}
                  text="更新履歴"
                  textAlign="center"
                  isIcon={false}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "休暇",
        accessor: "holidayName",
      },
      {
        Header: "休暇単位",
        accessor: "holidayDigestiveUnitName",
      },
      {
        Header: "開始時間",
        accessor: "holidayStartHm",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {(() => {
              if (
                (cell.row.original.holidayDigestiveUnit == 2 ||
                  cell.row.original.holidayDigestiveUnit == 3 ||
                  cell.row.original.holidayDigestiveUnit == 4) &&
                cell.row.original.holidayStartHm
              ) {
                if (
                  moment(cell.row.original.holidayStartHm).format("YYYYMMDD") !==
                  moment(cell.row.original.targetDate).format("YYYYMMDD")
                ) {
                  return `${moment(cell.row.original.holidayStartHm).format("HH:mm")}(翌)`;
                }
                return moment(cell.row.original.holidayStartHm).format("HH:mm");
              }
              return "";
            })()}
          </div>
        ),
      },
      {
        Header: "終了時間",
        accessor: "holidayEndHm",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {(() => {
              if (
                (cell.row.original.holidayDigestiveUnit == 2 ||
                  cell.row.original.holidayDigestiveUnit == 3 ||
                  cell.row.original.holidayDigestiveUnit == 4) &&
                cell.row.original.holidayEndHm
              ) {
                if (
                  moment(cell.row.original.holidayEndHm).format("YYYYMMDD") !==
                  moment(cell.row.original.targetDate).format("YYYYMMDD")
                ) {
                  return `${moment(cell.row.original.holidayEndHm).format("HH:mm")}(翌)`;
                }
                return moment(cell.row.original.holidayEndHm).format("HH:mm");
              }
              return "";
            })()}
          </div>
        ),
      },
      {
        Header: "取得時間",
        accessor: "holidayTimes",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left" }}>
            {cell.row.original.holidayTimes ? `${cell.row.original.holidayTimes}時間` : ""}
          </div>
        ),
      },
      {
        Header: "差戻者コメント",
        accessor: "rejectReason",
      },
    ],
    [fetchChangeLogHoliday, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <React.Fragment>
      <div
        css={css`
          table th:nth-of-type(1) {
            width: 140px;
          }
          table th:nth-of-type(2) {
            width: 140px;
          }
          table th:nth-of-type(3) {
            width: 140px;
          }
          table th:nth-of-type(4) {
            width: 140px;
          }
          table th:nth-of-type(5) {
            width: 140px;
          }
          table th:nth-of-type(6) {
            width: 140px;
          }
          table th:nth-of-type(7) {
            width: 80px;
          }
          table th:nth-of-type(8) {
            width: 80px;
          }
          table th:nth-of-type(9) {
            width: 120px;
          }
          table th:nth-of-type(10) {
            width: 80px;
          }
          table th:nth-of-type(11) {
            width: 140px;
          }
          table th:nth-of-type(12) {
            width: 140px;
          }
          table th:nth-of-type(13) {
            width: 80px;
          }
          table th:nth-of-type(14) {
            width: 200px;
          }
        `}
      >
        <Accordion text={`休暇申請 (${list.length}件）`} callBack={() => {}}>
          <DataTable
            columns={columns}
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            data={list}
            isGlobalFilter={true}
            sortBy={[{ id: "approvalDate", desc: true }]}
          />
        </Accordion>
      </div>
      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} isStamp={true} request="休暇申請" />
        </Modal>
      </div>
      {/* ./Change log  */}
    </React.Fragment>
  );
};

export default HolidayAccordion;
