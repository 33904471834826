import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import Modal from "components/molecules/Modal";
import SelectPortal from "components/atoms/SelectPortal";
import useToastNotification from "hooks/useToastNotification";
import { ConcurrentType } from "domain/staff";
import FormLabel from "components/atoms/Form/FormLabel";
import SelfOrganizationDomain from "domain/master/general/selfOrganization";
import { OptionType } from "components/atoms/Select";

const styles = {
  modal: css(
    {
      maxWidth: "500px",
    },
    css`
      table tr td {
        cursor: pointer;
      }
    `,
  ),
  column: css({
    width: "22px",
  }),
  formInput: css({
    marginBottom: "30px",
  }),
};

const OrganizationSelectModal: React.FC<{
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  organizationOptions: Array<any>;
  onSubmit: (selectedOrganization: OptionType) => void;
}> = ({ openModal, setOpenModal, organizationOptions, onSubmit }) => {
  const [orgCode, setOrgCode] = useState(organizationOptions.length > 0 ? organizationOptions[0].value : "");
  const { errorNotification } = useToastNotification();

  useEffect(() => {
    if (organizationOptions.length > 0) {
      setOrgCode(organizationOptions[0].value);
    }
  }, [organizationOptions]);

  const handleSubmitAddConcurrent = () => {
    console.log("orgCode", orgCode);
    if (!orgCode) {
      errorNotification("Org empty");
      return;
    }
    setOpenModal(false);

    onSubmit({
      value: orgCode,
      label: organizationOptions?.find((item) => item.value === orgCode).label,
    });
  };
  return (
    <Modal
      open={openModal}
      closeHandler={() => setOpenModal(false)}
      title="組織選択"
      submitText="追加"
      closeText="キャンセル"
      onSubmit={() => handleSubmitAddConcurrent()}
      width="550px"
      customStyle={css({ padding: "20px 50px 0 50px" })}
    >
      <div css={styles.formInput}>
        <div>
          <FormLabel label="組織" required={true} />
        </div>
        <SelectPortal
          name="concurrentOrganization"
          value={orgCode}
          setValue={(v) => {
            setOrgCode(v);
          }}
          options={organizationOptions}
        />
      </div>
    </Modal>
  );
};

export default OrganizationSelectModal;
