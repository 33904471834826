import React, { SVGProps } from 'react';

const ReportDropdown: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.45961 33.9996C7.14961 34.5296 6.71961 34.9896 6.20961 35.3296C6.10961 35.3996 5.99961 35.4696 5.87961 35.5296C5.65961 35.6496 5.41961 35.7496 5.17961 35.8196L1.34961 34.9996C2.05961 35.6196 2.97961 35.9996 3.99961 35.9996C4.40961 35.9996 4.80961 35.9396 5.17961 35.8196L5.78961 35.9496C5.92961 35.9896 6.06961 35.9996 6.20961 35.9996H31.4296C31.7896 35.9996 32.1396 35.8596 32.3896 35.5996C33.1196 34.8696 32.8096 33.6396 31.8296 33.3296L10.5896 26.7996C9.29961 26.3996 7.99961 27.3596 7.99961 28.7096V31.9996C7.99961 32.7296 7.80961 33.4096 7.45961 33.9996ZM9.65961 33.9996H27.1996L9.99961 28.7096V31.9996C9.99961 32.6996 9.87961 33.3796 9.65961 33.9996Z" fill="#0071BC"/>
    <path d="M32 2H4C2.9 2 2 2.9 2 4V32C2 33.1 2.9 34 4 34H32L34 35.46C33.41 35.81 32.73 36 32 36H4C1.79 36 0 34.21 0 32V4C0 1.79 1.79 0 4 0H32C34.21 0 36 1.79 36 4V29L34 28V4C34 2.9 33.1 2 32 2Z" fill="#0071BC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 8C6.45 8 6 8.45 6 9C6 9.55 6.45 10 7 10H29C29.55 10 30 9.55 30 9C30 8.45 29.55 8 29 8H7ZM37.71 38.71C37.33 39.09 36.72 39.1 36.32 38.74L22.32 25.74C22.12 25.54 22 25.28 22 25V21C22 20.45 22.45 20 23 20H27C27.25 20 27.5 20.1 27.68 20.27L41.68 33.27C41.88 33.45 41.99 33.71 42 33.98C42.01 34.25 41.9 34.51 41.71 34.71L37.71 38.71ZM24 22V24.56L36.97 36.61L39.55 34.03L26.61 22H24ZM6 15C6 14.45 6.45 14 7 14H29C29.55 14 30 14.45 30 15C30 15.55 29.55 16 29 16H7C6.45 16 6 15.55 6 15ZM7 20C6.45 20 6 20.45 6 21C6 21.55 6.45 22 7 22H19C19.55 22 20 21.55 20 21C20 20.45 19.55 20 19 20H7Z" fill="#003993"/>
  </svg>
);

export default ReportDropdown;
