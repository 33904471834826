import { useEffect, useCallback, useState } from 'react';
import { getStampHistory } from 'api/stampHistory';
import StampHistoryDomain from 'domain/master/atendenceManagement/stampHistory';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';


const useStampHistory = (orgCode: string, targetDateFrom: string, targetDateTo: string) => {
  const [stampHistory, setstampHistory] = useState<Array<StampHistoryDomain>>([]);
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.agreement36);
  const [underFlag, setUnderFlag] = useState(0);

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await getStampHistory(companyCode, orgCode, targetDateFrom, targetDateTo, underFlag);
    setstampHistory(response.map((result: any) => new StampHistoryDomain(result)));
    setBlocking(false);
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, underFlag]);

  return { stampHistory, setstampHistory, blocking, detailRole, underFlag, setUnderFlag };
};

export default useStampHistory;
