/** @jsx jsx */
import React, { useState, createContext, Dispatch, SetStateAction, useEffect } from "react";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import TabControl from "components/atoms/TabControl";
import StaffInfoForm from "components/organismos/master/general/staff/StaffInfoForm";
import AffiliationInfoForm from "components/organismos/master/general/staff/AffiliationInfoForm";
import EmploymentInfoForm from "components/organismos/master/general/staff/EmploymentInfoForm";
import SalaryInfoForm from "components/organismos/master/general/staff/SalaryInfoForm";
import WorkPatternInfoForm from "components/organismos/master/general/staff/WorkPatternInfoForm";
import LongTermCareAndNurse from "components/organismos/master/general/staff/LongTermCareAndNurse";

import BlockUI from "components/molecules/BlockUi";
import useStaffEdit from "./hooks";
import { TablItem } from "components/atoms/TabBar";
import FormLabel from "components/atoms/Form/FormLabel";
import { css, jsx } from "@emotion/core";
import { LoadingContextAdd, useInitialStaffAddPageState } from "../StaffAddPage/hooks";

const styles = {
  pageTitle: css({
    marginLeft: "27px",
    fontWeight: "bold",
    marginBottom: "20px",
    display: "block",
    fontSize: "16px",
  }),
};
type SelectType = "staff" | "affiliation" | "employment" | "laborCost" | "workPattern" | "longTermCareandNurse";
const SelectType: { [key in SelectType]: SelectType } = {
  staff: "staff",
  affiliation: "affiliation",
  employment: "employment",
  laborCost: "laborCost",
  workPattern: "workPattern",
  longTermCareandNurse: "longTermCareandNurse",
};

type contextType = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const LoadingContext = createContext<contextType>({} as contextType);

const StaffEditPage: React.FC = () => {
  const contextInitialState = useInitialStaffAddPageState();
  const { selectType, setSelectType, isLoading, setIsLoading, detailRole } = contextInitialState;

  const {
    staffDomain,
    setStaffDomain,
    trialPeriodOptions,
    businessOptions,
    termOptions,
    executiveOptions,
    employmentOptions,
    setOrgTargetDate,
    setEmpTargetDate,
    breakBusiness,
    roleOptions,
    holidayOptions,
    holidayTypeOptions,
    modalOrgTreeOptions,
  } = useStaffEdit();

  const [tabItems, setTabItems] = useState<Array<TablItem>>([]);

  useEffect(() => {
    setTabItems([
      {
        value: SelectType.staff,
        display: "スタッフ情報",
      },
      {
        value: SelectType.affiliation,
        display: "所属情報",
      },
      {
        value: SelectType.employment,
        display: "雇用情報",
      },
      ...(detailRole.usabilityGeneralItem1 === 1
        ? [
            {
              value: SelectType.laborCost,
              display: "人件費情報",
            },
          ]
        : []),
      {
        value: SelectType.workPattern,
        display: "勤務パターン",
      },
      {
        value: SelectType.longTermCareandNurse,
        display: "介護・看護休暇情報",
      },
    ]);
  }, [detailRole.usabilityGeneralItem1]);

  const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE == "true" || false;
  return (
    <SidebarTemplate pageTitle="スタッフ 編集">
      <LoadingContextAdd.Provider value={contextInitialState}>
        <BlockUI blocking={isLoading}>
          <FormContents>
            <FormLabel label="スタッフ編集" customStyle={styles.pageTitle} />
            <TabControl
              items={tabItems}
              setValue={setSelectType as (arg: string) => void}
              currentValue={selectType}
              isFixed={true}
              type={0}
            />

            {selectType === SelectType.staff && (
              <StaffInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setOrgTargetDate={setOrgTargetDate}
                setEmpTargetDate={setEmpTargetDate}
                trialPeriodOptions={trialPeriodOptions}
                businessOptions={businessOptions}
                roleOptions={roleOptions}
                isDisabledInfo={isAuthorizedFromPeople}
                setIsLoading={setIsLoading}
              />
            )}
            {selectType === SelectType.affiliation && (
              <AffiliationInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setOrgTargetDate={setOrgTargetDate}
                executiveOptions={executiveOptions}
                isDisabledInfo={isAuthorizedFromPeople}
                setIsLoading={setIsLoading}
              />
            )}

            {selectType === SelectType.employment && (
              <EmploymentInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setEmpTargetDate={setEmpTargetDate}
                employmentOptions={employmentOptions}
                isDisabledInfo={isAuthorizedFromPeople}
                setIsLoading={setIsLoading}
              />
            )}
            {selectType === SelectType.laborCost && (
              <SalaryInfoForm
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                termOptions={termOptions}
                modalOrgTreeOptions={modalOrgTreeOptions}
                setIsLoading={setIsLoading}
              />
            )}

            {selectType === SelectType.workPattern && (
              <WorkPatternInfoForm
                setInitialStaffDomain={setStaffDomain}
                initialStaffDomain={staffDomain}
                isEditMode={true}
                breakBusiness={breakBusiness}
                isDisabledInfo={isAuthorizedFromPeople}
                setIsLoading={setIsLoading}
              />
            )}

            {selectType === SelectType.longTermCareandNurse && (
              <LongTermCareAndNurse
                initialStaffDomain={staffDomain}
                setInitialStaffDomain={setStaffDomain}
                isEditMode={true}
                tabItems={tabItems}
                setTabItems={setTabItems}
                setSelectType={setSelectType}
                setOrgTargetDate={setOrgTargetDate}
                executiveOptions={executiveOptions}
                isDisabledInfo={false}
                setIsLoading={setIsLoading}
                holidayOptions={holidayOptions}
                holidayTypeOptions={holidayTypeOptions}
              />
            )}
          </FormContents>
        </BlockUI>
      </LoadingContextAdd.Provider>
    </SidebarTemplate>
  );
};

export default StaffEditPage;
