import React from "react";

import productColor, { grayScale, textFontSize } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import FormField from "components/atoms/Form/FormField";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import TabControl from "components/atoms/TabControl";
import TextInput from "components/atoms/Form/TextInput";
import HourInput from "components/atoms/Form/HourInput";
import { css } from "@emotion/core";

import { useSalaryCsvSettingAddForm } from "./hooks";

// create style form
const styles = {
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  input: css({
    flex: 1,
    width: "100px",
    padding: "7px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: "none",
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px 0 0 4px",
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
  }),
  cellTable: css({
    width: "100%",
    border: "none",
    borderSpacing: "0px 1.5px",
    overflow: "scroll",
  }),
};

type SelectType = "base" | "attend" | "payment" | "deduction" | "netPayment";

const SelectType: { [key in SelectType]: SelectType } = {
  base: "base",
  attend: "attend",
  payment: "payment",
  deduction: "deduction",
  netPayment: "netPayment",
};

const SalaryCsvSettingInputAddForm: React.FC = () => {
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    optionsFormatType,
    detailList,
    setDetailList,
    getCsvPosition,
    getItemName,
    getItemIndex,
    setPayslipCsvFormat,
  } = useSalaryCsvSettingAddForm();

  const [selectType, setSelectType] = React.useState<SelectType>(
    SelectType.base
  );

  /**
   *  handle on change csv position for detail list
   */
  const handleChangeCsvPosition = async (itemIndex: any, e: any) => {
    const items = [...detailList];
    items.filter((item: any) => {
      if (item.itemIndex === itemIndex) {
        // eslint-disable-next-line no-param-reassign
        item.csvPosition = e.target.value;
        return true;
      }
      return false;
    });
    setDetailList(items);
  };

  /**
   *  handle on change item name  for detail list
   */
  const handleChangeItemName = async (itemIndex: any, e: any) => {
    const items = [...detailList];
    items.filter((item: any) => {
      if (item.itemIndex === itemIndex) {
        // eslint-disable-next-line no-param-reassign
        item.itemName = e.target.value;
        return true;
      }
      return false;
    });
    setDetailList(items);
  };

  /**
   *  handle on change
   */
  const handleChange = async (e: any) => {
    const val = e.target.value;
    formik.setFieldValue("formatType", val);
    setPayslipCsvFormat(val);
  };

  return (
    <div>
      <FormTitle title="フォーマットタイプ" />

      <form onSubmit={formik.handleSubmit}>
        <FormField>
          <RadioSelectForm
            label=""
            items={optionsFormatType}
            name="formatType"
            value={String(formik.values.formatType)}
            setValue={(e) => handleChange(e)}
          />
        </FormField>

        <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <TabControl
            items={[
              {
                value: SelectType.base,
                display: "基本情報",
              },
              {
                value: SelectType.attend,
                display: "勤怠項目",
              },
              {
                value: SelectType.payment,
                display: "支給項目",
              },
              {
                value: SelectType.deduction,
                display: "控除項目",
              },
              {
                value: SelectType.netPayment,
                display: "差引項目",
              },
            ]}
            setValue={setSelectType as (arg: string) => void}
            currentValue={selectType}
            customStyle={css({
              margin: "0px",
              padding: "8px 16px",
              boxSizing: "border-box",
              maxWidth: "100%",
              overflow: "auto",
            })}
          />
        </div>

        <div>
          {/* 基本情報 */}
          {selectType === SelectType.base && (
            <>
              <table
                css={styles.cellTable}
                style={{ width: "600px", marginTop: "20px" }}
              >
                <thead>
                  <tr
                    style={{
                      borderTop: "1px solid #CEDAEA",
                      borderBottom: "1px solid #CEDAEA",
                      backgroundColor: "#F2F5F9",
                      height: "40px",
                    }}
                  >
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "500px",
                      }}
                    >
                      明細項目名
                    </th>
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      CSV項目位置
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "500px" }}>
                      <TextInput
                        name="detailList[0].itemName"
                        value={String(getItemName(detailList[0]))}
                        onChange={(e) =>
                          handleChangeItemName(getItemIndex(detailList[0]), e)
                        }
                        placeHolder="必須　明細に出力するスタッフコード欄の名称を入力してください"
                      />
                    </td>
                    <td style={{ width: "100px" }}>
                      <HourInput
                        name="detailList[0].csvPosition"
                        value={Number(getCsvPosition(detailList[0]))}
                        onChange={(e) =>
                          handleChangeCsvPosition(
                            getItemIndex(detailList[0]),
                            e
                          )
                        }
                        isFullW={true}
                        readOnly={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "500px" }}>
                      <TextInput
                        name="detailList[1].itemName"
                        value={String(getItemName(detailList[1]))}
                        onChange={(e) =>
                          handleChangeItemName(getItemIndex(detailList[1]), e)
                        }
                        placeHolder="必須　明細に出力する氏名欄の名称を入力してください"
                      />
                    </td>
                    <td style={{ width: "100px" }}>
                      <HourInput
                        name="detailList[1].csvPosition"
                        value={Number(getCsvPosition(detailList[1]))}
                        onChange={(e) =>
                          handleChangeCsvPosition(
                            getItemIndex(detailList[1]),
                            e
                          )
                        }
                        isFullW={true}
                        readOnly={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "500px" }}>
                      <TextInput
                        name="detailList[2].itemName"
                        value={
                          String(getItemName(detailList[2])) || "支給対象年月"
                        }
                        readOnly={true}
                        onChange={(e) =>
                          handleChangeItemName(getItemIndex(detailList[2]), e)
                        }
                      />
                    </td>
                    <td style={{ width: "100px" }}>
                      <HourInput
                        name="detailList[2].csvPosition"
                        value={Number(getCsvPosition(detailList[2]))}
                        onChange={(e) =>
                          handleChangeCsvPosition(
                            getItemIndex(detailList[2]),
                            e
                          )
                        }
                        isFullW={true}
                        readOnly={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "500px" }}>
                      <TextInput
                        name="detailList[3].itemName"
                        value={
                          String(getItemName(detailList[3])) || "支給年月日"
                        }
                        readOnly={true}
                        onChange={(e) =>
                          handleChangeItemName(getItemIndex(detailList[3]), e)
                        }
                      />
                    </td>
                    <td style={{ width: "100px" }}>
                      <HourInput
                        name="detailList[3].csvPosition"
                        value={Number(getCsvPosition(detailList[3]))}
                        onChange={(e) =>
                          handleChangeCsvPosition(
                            getItemIndex(detailList[3]),
                            e
                          )
                        }
                        isFullW={true}
                        readOnly={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "500px" }}>
                      <TextInput
                        name="detailList[4].itemName"
                        value={String(getItemName(detailList[4]))}
                        onChange={(e) =>
                          handleChangeItemName(getItemIndex(detailList[4]), e)
                        }
                        placeHolder="明細に出力する所属コード欄の名称を入力してください"
                      />
                    </td>
                    <td style={{ width: "100px" }}>
                      <HourInput
                        name="detailList[4].csvPosition"
                        value={Number(getCsvPosition(detailList[4]))}
                        onChange={(e) =>
                          handleChangeCsvPosition(
                            getItemIndex(detailList[4]),
                            e
                          )
                        }
                        isFullW={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "500px" }}>
                      <TextInput
                        name="detailList[5].itemName"
                        value={String(getItemName(detailList[5]))}
                        onChange={(e) =>
                          handleChangeItemName(getItemIndex(detailList[5]), e)
                        }
                        placeHolder="明細に出力する所属組織（部署）欄の名称を入力してください"
                      />
                    </td>
                    <td style={{ width: "100px" }}>
                      <HourInput
                        name="detailList[5].csvPosition"
                        value={Number(getCsvPosition(detailList[5]))}
                        onChange={(e) =>
                          handleChangeCsvPosition(
                            getItemIndex(detailList[5]),
                            e
                          )
                        }
                        isFullW={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {/* 勤怠項目 */}
          {selectType === SelectType.attend && (
            <>
              <table
                css={styles.cellTable}
                style={{ width: "500px", marginTop: "20px" }}
              >
                <thead>
                  <tr
                    style={{
                      borderTop: "1px solid #CEDAEA",
                      borderBottom: "1px solid #CEDAEA",
                      backgroundColor: "#F2F5F9",
                      height: "40px",
                    }}
                  >
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "500px",
                      }}
                    >
                      明細項目名
                    </th>
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      CSV項目位置
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detailList.map(
                    (item, key_item) =>
                      key_item >= 6 &&
                      key_item < 26 && (
                        <tr>
                          <td style={{ width: "500px" }}>
                            <TextInput
                              name={`detailList[${key_item}]_itemName`}
                              value={String(getItemName(item))}
                              onChange={(e) =>
                                handleChangeItemName(getItemIndex(item), e)
                              }
                            />
                          </td>
                          <td style={{ width: "100px" }}>
                            <HourInput
                              name={`detailList[${key_item}]_csvPosition`}
                              value={Number(getCsvPosition(item))}
                              onChange={(e) =>
                                handleChangeCsvPosition(getItemIndex(item), e)
                              }
                              isFullW={true}
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
          {/* 支給項目 */}
          {selectType === SelectType.payment && (
            <>
              <table
                css={styles.cellTable}
                style={{ width: "500px", marginTop: "20px" }}
              >
                <thead>
                  <tr
                    style={{
                      borderTop: "1px solid #CEDAEA",
                      borderBottom: "1px solid #CEDAEA",
                      backgroundColor: "#F2F5F9",
                      height: "40px",
                    }}
                  >
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "500px",
                      }}
                    >
                      明細項目名
                    </th>
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      CSV項目位置
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detailList.map(
                    (item, key_item) =>
                      key_item >= 26 &&
                      key_item < 46 && (
                        <tr>
                          <td style={{ width: "500px" }}>
                            <TextInput
                              name={`detailList[${key_item}]_itemName`}
                              value={String(getItemName(item))}
                              onChange={(e) =>
                                handleChangeItemName(getItemIndex(item), e)
                              }
                            />
                          </td>
                          <td style={{ width: "100px" }}>
                            <HourInput
                              name={`detailList[${key_item}]_csvPosition`}
                              value={Number(getCsvPosition(item))}
                              onChange={(e) =>
                                handleChangeCsvPosition(getItemIndex(item), e)
                              }
                              isFullW={true}
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
          {/* 控除項目 */}
          {selectType === SelectType.deduction && (
            <>
              <table
                css={styles.cellTable}
                style={{ width: "500px", marginTop: "20px" }}
              >
                <thead>
                  <tr
                    style={{
                      borderTop: "1px solid #CEDAEA",
                      borderBottom: "1px solid #CEDAEA",
                      backgroundColor: "#F2F5F9",
                      height: "40px",
                    }}
                  >
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "500px",
                      }}
                    >
                      明細項目名
                    </th>
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      CSV項目位置
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detailList.map(
                    (item, key_item) =>
                      key_item >= 46 &&
                      key_item < 66 && (
                        <tr>
                          <td style={{ width: "500px" }}>
                            <TextInput
                              name={`detailList[${key_item}]_itemName`}
                              value={String(getItemName(item))}
                              onChange={(e) =>
                                handleChangeItemName(getItemIndex(item), e)
                              }
                            />
                          </td>
                          <td style={{ width: "100px" }}>
                            <HourInput
                              name={`detailList[${key_item}]_csvPosition`}
                              value={Number(getCsvPosition(item))}
                              onChange={(e) =>
                                handleChangeCsvPosition(getItemIndex(item), e)
                              }
                              isFullW={true}
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
          {/* 差引項目 */}
          {selectType === SelectType.netPayment && (
            <>
              <table
                css={styles.cellTable}
                style={{ width: "500px", marginTop: "20px" }}
              >
                <thead>
                  <tr
                    style={{
                      borderTop: "1px solid #CEDAEA",
                      borderBottom: "1px solid #CEDAEA",
                      backgroundColor: "#F2F5F9",
                      height: "40px",
                    }}
                  >
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "500px",
                      }}
                    >
                      明細項目名
                    </th>
                    <th
                      style={{
                        fontWeight: "inherit",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      CSV項目位置
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detailList.map(
                    (item, key_item) =>
                      key_item >= 66 &&
                      key_item < 76 && (
                        <tr>
                          <td style={{ width: "500px" }}>
                            <TextInput
                              name={`detailList[${key_item}]_itemName`}
                              value={String(getItemName(item))}
                              onChange={(e) =>
                                handleChangeItemName(getItemIndex(item), e)
                              }
                            />
                          </td>
                          <td style={{ width: "100px" }}>
                            <HourInput
                              name={`detailList[${key_item}]_csvPosition`}
                              value={Number(getCsvPosition(item))}
                              onChange={(e) =>
                                handleChangeCsvPosition(getItemIndex(item), e)
                              }
                              isFullW={true}
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>

        <FormSubmitArea>
          <Button
            text={formik.values.formatId ? "更新" : "登録"}
            onClick={formik.handleSubmit}
          />
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        title="確認メッセージ"
        content={`${
          formik.values.formatId ? "更新" : "登録"
        }します。よろしいですか？`}
        submitText={formik.values.formatId ? "更新" : "登録"}
      />

      {/* <ToastModal
        open={toastModalOpen}
        closeHandler={closeToastModal}
        title={toastModalTitle}
      /> */}
    </div>
  );
};

export default SalaryCsvSettingInputAddForm;
