/** @jsx jsx */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { grayScale, textFontSize } from "components/styles";
import Accordion from "components/molecules/Accordion";
import DataTable from "components/organismos/DataTable/DataTable";
import Checkbox from "components/molecules/Checkbox";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormLabel from "components/atoms/Form/FormLabel";
import PrimaryButton, { DeleteButton, InfoButton } from "components/atoms/Button";
import { css, jsx } from "@emotion/core";
import ToastModal from "components/organismos/ToastModal";
import Modal from "components/molecules/Modal";
import TabControl from "components/atoms/TabControl";
import moment from "moment";
import Table from "components/molecules/Table";
import useToastNotification from "hooks/useToastNotification";
import FormPadding from "components/atoms/Form/FormPadding";
import { Row, Col } from "react-grid-system";
import BlockUI from "components/molecules/BlockUi";
import { IchangeLog } from "../interface";
import { postApproveStamp, postBulkApproveStamp } from "../api";
import { useApplyingTransferForm } from "./hook";
import ApplyingStampDomain from "../domain/applyingStampDomain";
import TextForm from "components/molecules/TextForm";
import IconLabelButton from "components/molecules/IconLabelButton";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";

const { errorNotification, successNotification } = useToastNotification();

const StampAccordion: React.FC<{
  list: ApplyingStampDomain[];
  setApplyingStampList: any;
  fetchApprovedList: any;
  fetchRejectedList: any;
  reasonOptionList: any;
  reasonId: any;
  setReasonId: any;
}> = ({
  list,
  setApplyingStampList,
  fetchApprovedList,
  fetchRejectedList,
  reasonOptionList,
  reasonId,
  setReasonId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    toastMessage,
    setToastMessage,

    toastModalOpen,
    setToastModalOpen,

    confirmApplyModalOpen,
    setConfirmApplyModalOpen,

    closeConfirmApplyModal,

    extractSingleApproveData,
    extractBulkApproveData,
    extractSingleRejectData,

    rejectReasonOnchange,

    buttonType,
    setButtonType,
    selectedIndex,
    setSelectedIndex,

    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLog,
    changeReason,
    setChangeReason,
  } = useApplyingTransferForm(list, setApplyingStampList);
  const handleSubmit = async () => {
    const remainingList = list.filter((item, position) => !selectedIndex.includes(position)); // remove checked requests
    const checkedList = list.filter((item, position) => selectedIndex.includes(position)); // checked requests
    try {
      if (buttonType === 1) {
        rejectRequest(checkedList, remainingList);
      } else {
        approveRequest(checkedList, remainingList);
      }
    } catch (error) {
      setToastMessage(error.message);
    }
    setConfirmApplyModalOpen(false);
    setSelectedIndex([]);
  };

  const approveRequest = async (checkedList: any[], remainingList: any[]) => {
    setIsLoading(true);
    if (checkedList.length > 1) {
      try {
        await postBulkApproveStamp(extractBulkApproveData(checkedList));
        setIsLoading(false);
        successNotification("承認しました。");
        setApplyingStampList(remainingList); // set new list
        fetchApprovedList(); // refetch API
      } catch (error) {
        setIsLoading(false);
        if (error.response.status !== 500 && error.response && error.response.data) {
          const listErr = error.response.data;
          listErr.map((element: any) => errorNotification(element.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
          throw error;
        }
      }
    } else {
      try {
        await postApproveStamp(extractSingleApproveData(checkedList));
        setIsLoading(false);
        successNotification("承認しました。");
        setApplyingStampList(remainingList); // set new list
        fetchApprovedList(); // refetch API
      } catch (error) {
        setIsLoading(false);
        if (error.response.status !== 500 && error.response && error.response.data) {
          const listErr = error.response.data;
          listErr.map((element: any) => errorNotification(element.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
          throw error;
        }
      }
    }
  };

  const rejectRequest = async (checkedList: any[], remainingList: any[]) => {
    setIsLoading(true);
    if (!checkedList[0].rejectReason) {
      setIsLoading(false);
      errorNotification("差戻コメントを入力してください。");
      return;
    }

    await postApproveStamp(extractSingleRejectData(checkedList));
    setIsLoading(false);
    successNotification("差戻しました。");
    setApplyingStampList(remainingList); // set new list
    fetchRejectedList(); // refetch API
  };

  const textFontSizeSM = {
    fontSize: `${textFontSize.sm}`,
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(false);
    setSelectedIndex([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const columns = React.useMemo(
    () => [
      {
        Header: (row: { index: number }) => (
          <div>
            <Checkbox
              id="checkbox_stamp_all"
              name="checkbox_stamp_all"
              value=""
              checked={isChecked}
              isCheckBoxTable={true}
              onChange={() => {
                setIsChecked(!isChecked);
                if (!isChecked) {
                  const indexArr: any = [];
                  const data = document.querySelectorAll(".rowDataIndex0");
                  data.forEach((e) => {
                    indexArr.push(Number(e.getAttribute("row-data-index")));
                  });
                  setSelectedIndex(indexArr);
                  return false;
                }
                setSelectedIndex([]);
              }}
            />
          </div>
        ),
        disableSortBy: true,
        id: "",
        accessor: "checkbox",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              id={`checkbox_stamp_${cell.row.index}`}
              name="name"
              label=""
              value={`${cell.row.index}`}
              checked={selectedIndex.includes(cell.row.index)}
              isCheckBoxTable={true}
              onChange={() => {
                if (selectedIndex.includes(cell.row.index)) {
                  setSelectedIndex(selectedIndex.filter((selected) => selected !== cell.row.index));
                } else {
                  setSelectedIndex([...selectedIndex, cell.row.index]);
                }
              }}
            />
            <input type="hidden" className="rowDataIndex0" row-data-index={cell.row.index} />
          </div>
        ),
      },
      {
        Header: () => "決裁",
        id: "decision",
        accessor: "decision",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div>
            <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
              <InfoButton
                text="承認"
                onClick={() => {
                  setButtonType(3);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
                customStyle={css({
                  marginRight: "15px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  height: "34px",
                })}
                minWidth="40px"
              />
              <DeleteButton
                text="差戻"
                onClick={() => {
                  setChangeReason("");
                  setButtonType(1);
                  setSelectedIndex([cell.row.index]);
                  setConfirmApplyModalOpen(true);
                }}
                customStyle={css({
                  borderRadius: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                  height: "34px",
                })}
                minWidth="40px"
              />
            </FlexBox>
            {/* <TextForm
              name="rejectReason"
              label=""
              value={cell.data[cell.row.index].rejectReason}
              placeHolder=""
              errorMsg=""
              onChange={(e) => rejectReasonOnchange(cell.row.index, e)}
            /> */}
          </div>
        ),
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: () => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <div>
              <div>{cell.row.original.targetDate}</div>
              <IconLabelButton
                onClick={() => {
                  fetchChangeLog(cell.row.original.modifierApplicationId);
                  setChangeLogModalOpen(true);
                  setSelectChangelog("0");
                }}
                text="更新履歴"
                textAlign="center"
                isIcon={false}
              />
            </div>
          </div>
        ),
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "出勤組織",
        accessor: "stampOrgName",
      },
      {
        Header: "開始時間", // starttime
        accessor: "stampListStartTime", // .startTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>{cell.row.original.stampListStartTime}</div>
        ),
      },
      {
        Header: "終了時間", // endtime
        accessor: "stampListEndTime", // .endTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>
            {cell.row.original.stampListEndTime && cell.row.original.stampListEndTime}
          </div>
        ),
      },
      // {
      //   Header: '業務内容', // business content
      //   accessor: 'stampListBusinessName', // .businessName
      //   Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
      //     <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
      //       {cell.row.original.stampListBusinessName}
      //     </div>
      //   ),
      // },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, selectedIndex],
  );

  const colorTextLabel = {
    color: `${grayScale.gray50}`,
  };

  const colorText = {
    color: `${grayScale.gray100}`,
  };

  const fontSizeData = {
    fontSize: `${textFontSize.re}`,
  };

  return (
    <div
      css={css`
        table th:nth-of-type(1) {
          width: 40px;
        }
        table th:nth-of-type(2) {
          width: 170px;
        }
        table th:nth-of-type(3) {
          width: 140px;
        }
        table th:nth-of-type(4) {
          width: 140px;
        }
        table th:nth-of-type(5) {
          width: 120px;
        }
        table th:nth-of-type(6) {
          width: 140px;
        }
        table th:nth-of-type(7) {
          width: 80px;
        }
        table th:nth-of-type(8) {
          width: 80px;
        }
        table th:nth-of-type(9) {
          width: 140px;
        }
        table th:nth-of-type(10) {
          width: 140px;
        }
        table th:nth-of-type(11) {
          width: 140px;
        }
        table th:nth-of-type(12) {
          width: 200px;
        }

        .modal-table th:nth-of-type(1) {
          width: 25%;
        }
        .modal-table th:nth-of-type(2) {
          width: 25%;
        }
        .modal-table th:nth-of-type(3) {
          width: 25%;
        }
        .modal-table th:nth-of-type(4) {
          width: 25%;
        }
        .labelReject {
          margin-top: 40px;
          text-align: left;
          padding: 0 60px;
        }
        #rejectReason {
          height: 64px;
          margin-top: 10px;
          width: 100%;
          color: #333333;
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          border-radius: 2px;
          padding: 5px;
          font-size: 14px;
          font-family: "Noto Sans JP", sans-serif;
        }
        .rejectReason:focus {
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          border-radius: 2px;
        }
      `}
    >
      <Accordion text={`打刻修正申請 (${list.length}件）`} callBack={() => {}}>
        <FlexBox customStyle={css({ padding: "10px 45px 15px 35px" })}>
          <FlexBoxItem marginRight="15px">
            <FormLabel label="申請事由" />
          </FlexBoxItem>
          <FlexBoxItem basis="300px">
            <VerticalLabelSelectForm
              label=""
              name="reasonId"
              value={String(reasonId || "")}
              setValue={(v) => {
                setReasonId(v);
              }}
              options={reasonOptionList}
              isSearchable={false}
            />
          </FlexBoxItem>
        </FlexBox>
        <PrimaryButton
          text="一括承認"
          onClick={() => {
            setButtonType(2);
            setConfirmApplyModalOpen(true);
          }}
          disabled={selectedIndex.length === 0}
          customStyle={css({ float: "right", marginRight: "15px" })}
        />
        <BlockUI blocking={isLoading}>
          <DataTable
            onClickPagination={() => {
              setIsChecked(false);
              setSelectedIndex([]);
            }}
            iconSize="16px"
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            globalSearchStyle={css({ marginLeft: "20px" })}
            columns={columns}
            data={list}
            isGlobalFilter={true}
            sortBy={[{ id: "targetDate", desc: true }]}
          />
        </BlockUI>
      </Accordion>

      <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

      <Modal
        open={confirmApplyModalOpen}
        closeHandler={closeConfirmApplyModal}
        onSubmit={handleSubmit}
        title={buttonType === 3 ? "申請の承認" : buttonType === 1 ? "申請の差戻" : "確認メッセージ"}
        // eslint-disable-next-line no-nested-ternary
        typeSubmit={buttonType === 1 ? "reject" : "confirm"}
        // eslint-disable-next-line no-nested-ternary
        note={
          buttonType === 0
            ? "承認します。よろしいですか？"
            : buttonType === 1
            ? "申請を差戻します。よろしいですか？"
            : buttonType === 3
            ? "申請を承認します。よろしいですか？"
            : "一括承認します。よろしいですか？"
        }
        submitText={buttonType === 1 ? "差戻する" : buttonType === 3 ? "承認する" : "承認"}
      >
        {buttonType === 1 && (
          <React.Fragment>
            <div className="labelReject">
              <TextForm
                name="rejectReason"
                label="差戻コメント"
                value={changeReason}
                placeHolder=""
                errorMsg=""
                onChange={(e) => {
                  rejectReasonOnchange(selectedIndex[0], e);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>

      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} isStamp={true} />
        </Modal>
      </div>
      {/* ./Change log  */}
    </div>
  );
};

export default StampAccordion;
