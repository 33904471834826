import { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { getSetting36AgreementById, getSetting36Agreement, postSetting36Agreement, getSetting36AgreementPrevious } from "api/master36Agreement";
import useToastNotification from "hooks/useToastNotification";
import AttendSetting36AgreementDomain, {AttendSetting36Agreement} from 'domain/master/attend/attendSetting36Agreement';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import moment from 'moment';
import { getParentOrgCodeOptions} from 'api/organization';
import { useHistory } from "react-router";

const { errorNotification, successNotification } = useToastNotification();

export const useMasterSettingAgreement36Form = (agreementsId?:string) => {

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterSettingAgreement36);
  const [orgCodeOptions, setOrgCodeOptions] = useState([]);
  const history = useHistory();
  
  const listMonths = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09',
    '10', '11', '12'
  ];

  const monthOptions = listMonths.map((month) => ({
    value: month,
    label: month,
  }));
  
  const checkSorted = (arr: Array<number>) => {
    for(let i = 0; i < arr.length - 1; i++) {
      if(arr[i] >= arr[i+1]) {
         return false;
      }
    }
    return true;
  }
  
  const checkValidateArrThreshold = (arrThreshold: Array<number>, minValue: number, maxValue: number) => {
    let inRange = Math.max(...arrThreshold) <= maxValue && Math.min(...arrThreshold) >= minValue;
    let isSorted = checkSorted(arrThreshold);
    if(inRange && isSorted){
      return true;
    }
    return false
  }

  // submit formik
  const onSubmit = async (values: AttendSetting36AgreementDomain) => {
    

    setOpenModal(false);
    let postData = values.getPostData();
    if(!checkSorted(postData.monthlyOvertimeHourList)){
      errorNotification('当月の時間外労働時間の値が不正です。上の設定値よりも大きい値を入力してください');
      return;
    }
    if(!checkSorted(postData.yearOvertimeHourList)){
      errorNotification('当年の時間外労働時間の値が不正です。上の設定値よりも大きい値を入力してください');
      return;
    }
    if(!checkSorted(postData.monthlyOverConditionsCountList)){
      errorNotification('時間外労働届出の上限の値が不正です。上の設定値よりも大きい値を入力してください');
      return;
    }
    try {
      const response = await postSetting36Agreement(values.getPostData());
      successNotification(isEdit ? '更新しました。' : '登録しました。');
      history.goBack();
    } catch (error) {
      setOpenModal(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  }

  const formik = useFormik({
    initialValues: AttendSetting36AgreementDomain.generateInitial(),
    onSubmit,
  });
  
//  const fetchData = useCallback(async () => {
//    try {
//      if (formik.values.targetYear ){
//        setIsLoading(true);
//        await getSetting36Agreement(formik.values.targetYear, formik.values.agreementsCode).then((response: any) => {
//          let tmpDetail = new AttendSetting36AgreementDomain(response);
//          setIsEdit(tmpDetail.agreementsId !== null);
//          if (!tmpDetail.applyStartMonth) {
//            tmpDetail.targetYear =formik.values.targetYear;    
//          }
//          if(!tmpDetail.applyStartMonth){
//            tmpDetail.applyStartMonth = formik.values.applyStartMonth;          
//          }else{
//            tmpDetail.applyStartMonth = new Date(tmpDetail.applyStartMonth);          
//          }
//          formik.setValues(tmpDetail);
//          setIsLoading(false);
//        });
//      }
//    } catch (error) {
//      setIsLoading(false);
//      errorNotification('サーバー側でエラーが発生しました。');
//    }
//  }, [formik.values.targetYear]);


//  useEffect(() => {
//    fetchData();
//  }, [formik.values.targetYear])

 
  const fetchData = useCallback(async () => {
    try {
      if (agreementsId !== undefined && agreementsId !== '') {
        setIsLoading(true);
        getSetting36AgreementById(agreementsId? agreementsId : '').then((response: any) => {
          let tmpDetail = new AttendSetting36AgreementDomain(response);
          setIsEdit(tmpDetail.agreementsId !== null);
          if(!tmpDetail.targetYear){
            tmpDetail.targetYear = new Date().getFullYear();
          }
          if(!tmpDetail.applyStartMonth){
            tmpDetail.applyStartMonth = new Date(tmpDetail.targetYear, 3, 1);          
          }else{
           tmpDetail.applyStartMonth = new Date(tmpDetail.applyStartMonth);          
          }
          formik.setValues(tmpDetail);
        });
        setIsLoading(false);
      } else {
        formik.setFieldValue('targetYear', new Date().getFullYear());
        formik.setFieldValue('applyStartMonth', new Date(formik.values.targetYear, 3, 1)); 
        formik.setFieldValue('orgCode', 'all');
        formik.setFieldValue('underFlag', 1);
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    } finally {
      setIsLoading(false);
    }
  }, [agreementsId]);

  useEffect(() => {
    fetchData();
  }, [agreementsId]);



  const setPreviousYearData = async () => {
    const params = {
      targetYear: formik.values.targetYear,
      agreementsCode: formik.values.agreementsCode,
      orgCode: formik.values.orgCode,
      underFlag: formik.values.underFlag,
  }
    try {
      setIsLoading(true);
      await getSetting36AgreementPrevious(params).then((response: any) => {
        let tmpDetail = new AttendSetting36AgreementDomain(response);
        setIsEdit(tmpDetail.agreementsId !== null);
        if(!tmpDetail.applyStartMonth){
          tmpDetail.applyStartMonth = new Date(new Date().getFullYear(), 3, 1);          
        }else{
          tmpDetail.applyStartMonth = new Date(tmpDetail.applyStartMonth);          
        }
        formik.setValues(tmpDetail);
      });
      setIsLoading(false);
    } catch (error)  {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else if (error.response && error.response.data && error.response.data.defaultMessage) {
        errorNotification(error.response.data.defaultMessage);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }

  const fetchOrgCodeOptions = async (applyStartMonth: Date) => {
    setIsLoading(true);
    const params = {
      targetDayFrom: moment(applyStartMonth).format('YYYY-MM-DD'),
      targetDayTo: moment(applyStartMonth).format('YYYY-MM-DD'),
      addAllItem: true
    }
    getParentOrgCodeOptions(params).then((response: any) => {
      if(response){
        let orgList = response.map((org: any) => {
          return {
            label: org.orgName,
            value: org.orgCode
          }
        });
        setOrgCodeOptions(orgList)
      }

      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
      fetchOrgCodeOptions(formik.values.applyStartMonth);
  }, [formik.values.applyStartMonth]);

  return {
    isLoading,
    listMonths,
    monthOptions,
    formik,
    openModal,
    setOpenModal,
    isEdit,
    setPreviousYearData,
    detailRole,
    orgCodeOptions,
  };
};

export default useMasterSettingAgreement36Form;

