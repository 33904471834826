/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import { useBarcodeRecorderEmptySpecifyCode } from "./hooks";
import BarcodeRecorderUI from "./BarcodeRecorderUI";

const BarcodeRecorderEmptySpecifyCode: React.FC<{
  dataTimes: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchHistoryData: any;
}> = ({
  dataTimes,
  setIsLoading,
  fetchHistoryData,
}) => {
    const {
        formik,
        isConfirm,
        lastStampType,
        handleRecorder,
        handleSubmit
    } = useBarcodeRecorderEmptySpecifyCode(
        dataTimes.resultCurrentTime,
        setIsLoading,
        fetchHistoryData
    );
   
    return (
        <BarcodeRecorderUI
          formik={formik}
          dataTimes={dataTimes}
          isConfirm={isConfirm}
          handleSubmit={handleSubmit}
          disabledRecorder={false}
          lastStampType={lastStampType}
          handleRecorder={handleRecorder}
        />
    );
};

export default BarcodeRecorderEmptySpecifyCode;
