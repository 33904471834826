import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import BusinessCategoryAddForm from 'components/organismos/master/sales/BusinessCategoryAddForm';

const BusinessCategoryAddPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="業態作成"
  >
    <BusinessCategoryAddForm />
  </SidebarTemplate>
);

export default BusinessCategoryAddPage;
