import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PrescribedDaysCreateForm from 'components/organismos/master/labor/PrescribedDaysCreateForm';
import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import FormTitle from 'components/atoms/Form/FormTitle';

type SelectTab = 'add';
const SelectTab: {[key in SelectTab]: SelectTab} = {
  add: 'add',
};

const WorkingDaysCreatePage: React.FC = () => {
  const [selectTab, setSelectTab] = React.useState<SelectTab>(SelectTab.add);

  return (
    <SidebarTemplate>
      <FormContents>
      <FormTitle bold={true} title="所定労働日数作成" />
        <div style={{paddingLeft: '35px'}}>
        <TabControl
          items={[
            {
              value: SelectTab.add,
              display: '所定労働日数',
            },
          ]}
          setValue={setSelectTab as (arg: string) => void}
          currentValue={selectTab}
          isFixed={true}
          type={1}
        />
        </div>
        <PrescribedDaysCreateForm />
      </FormContents>
    </SidebarTemplate>
  );
};

export default WorkingDaysCreatePage;
