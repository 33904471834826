/** @jsx jsx */

import SidebarTemplate from "components/templates/SidebarTemplate";
import React, { useCallback, useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import FormContents from "components/atoms/Form/FormContents";
import BlockUI from "components/molecules/BlockUi";
import { ReactSortable } from "react-sortablejs";
import { deleteById } from "api/timeZone";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
// import DataTableSort from 'components/organismos/DataTableSort/DataTableSort';
import useTimeZone from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import { SubActionButton } from "components/atoms/Button";
import { ActionButton } from "components/atoms/Button/SubActionButton";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BodyText from "components/atoms/BodyText";
import FormLabel from "components/atoms/Form/FormLabel";
import TextInput from "components/atoms/Form/TextInput";
import SelectForm from "components/molecules/SelectForm";
import { OptionType } from "components/atoms/Select";
import IconLabelButton from "components/molecules/IconLabelButton";
import Button from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectFormV2 from "components/molecules/SelectFormV2";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const TimeZonePage = () => {
  const {
    formik,
    updateConfirmModalOpen,
    closeUpdateConfirmModal,
    successNotification,
    errorNotification,
    isLoading,
    setIsLoading,
    detailRole,
  } = useTimeZone();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(Object());
  const optionsTargetType = [
    {
      label: "平日・休日",
      value: "0",
    },
    {
      label: "平日のみ",
      value: "1",
    },
    {
      label: "休日のみ",
      value: "2",
    },
  ];

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback((timeZone: any) => {
    setConfirmModalOpen(true);
    setSelectedTimeZone(timeZone);
  }, []);

  const deleteTimeZone = useCallback(
    () => {
      setConfirmModalOpen(false);
      setIsLoading(true);
      const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
      deleteById(companyCode, selectedTimeZone.termId)
        .then((response: any) => {
          formik.setFieldValue(
            "timeZoneItems",
            formik.values.timeZoneItems.filter((value: any, idx: any) => value.id !== selectedTimeZone.id),
          );
          successNotification("削除しました。");
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            let msgError = "";
            error.response.data.errors.map((item: { defaultMessage: string }) => {
              msgError += `${item.defaultMessage} \n`;
              return msgError;
            });
            errorNotification(msgError);
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
          setIsLoading(false);
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, selectedTimeZone.termId, successNotification],
  );

  const addNewTimeZone = useCallback(() => {
    const tmpTimeZone = [...formik.values.timeZoneItems];
    tmpTimeZone.push({
      id: String(formik.values.timeZoneItems.length),
      termId: "",
      termName: "",
      targetType: 0,
      startTime: "00:00",
      endTime: "00:00",
    });
    formik.setFieldValue("timeZoneItems", tmpTimeZone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.timeZoneItems]);

  return (
    <SidebarTemplate>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle bold={true} title="時間帯マスタ" />
          <FormPadding customStyle={css({ paddingTop: "30px" })}>
            <div>
              {/* <LableTitle title="各時間帯で勤怠を集計することが出来るようになります。また、各時間帯で時給を加算させて人件費を計算することが出来るようになります。" /> */}
              {/* <PageTitle
            title=""
            rightContents={(
              <Link to="/masterTerm/add">
                <span style={{ marginRight: '12px' }}>
                  <PrimaryButton
                    ghost={false}
                    text="新規時間帯マスタ作成"
                  />
                </span>
              </Link>
            )}
          /> */}
              <div
                css={css`
                  .item-time-zone {
                    transform: none !important;
                  }
                `}
              >
                <div>
                  {/* <DataTableSort
                columns={columns}
                data={timeZoneList}
                setPageList={setPageList}
              /> */}
                  {//formik.setFieldValue('timeZoneItems', data)
                  formik.values.timeZoneItems.length > 0 && (
                    <ReactSortable
                      list={formik.values.timeZoneItems}
                      setList={(data: any) => formik.setFieldValue("timeZoneItems", data)}
                    >
                      {formik.values.timeZoneItems.map((item, index) => {
                        return (
                          <div className="item-time-zone" key={index}>
                            {
                              <FlexBox customStyle={css({ cursor: "pointer" })}>
                                <FlexBoxItem marginRight="40px">
                                  {detailRole.editable === 1 && (
                                    <SubActionButton
                                      action={ActionButton.DELETE}
                                      text="削除"
                                      onClick={() => {
                                        if (item.termId === "") {
                                          formik.setFieldValue(
                                            "timeZoneItems",
                                            formik.values.timeZoneItems.filter(
                                              (value: any, idx: any) => value.id !== item.id,
                                            ),
                                          );
                                        } else {
                                          openConfirmModal(item);
                                        }
                                      }}
                                    />
                                  )}
                                </FlexBoxItem>
                                <FlexBoxItem>
                                  <FormLabel
                                    label="名称"
                                    required={true}
                                    showRequiredLabel={true}
                                    customStyle={css({ marginBottom: "0px" })}
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem marginLeft="15px" marginRight="20px" width="160px">
                                  <TextInput
                                    name={`name-${index}`}
                                    value={item.termName}
                                    onChange={(e) => {
                                      formik.setFieldValue("timeZoneItems[" + index + "].termName", e.target.value);
                                    }}
                                    type="text"
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem>
                                  <BodyText>対象</BodyText>
                                </FlexBoxItem>
                                <FlexBoxItem marginLeft="15px" marginRight="20px" width="160px">
                                  <SelectFormV2
                                    customStyle={css({ width: "100%" })}
                                    label=""
                                    isHiddenLabel={true}
                                    name="orgCode"
                                    value={String(item.targetType)}
                                    setValue={(v: any) => {
                                      formik.setFieldValue("timeZoneItems[" + index + "].targetType", v);
                                    }}
                                    options={optionsTargetType}
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem>
                                  <FormLabel
                                    label="時間帯"
                                    required={true}
                                    showRequiredLabel={true}
                                    customStyle={css({ marginBottom: "0px" })}
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem width="70px" marginLeft="10px">
                                  <SelectFormV2
                                    customStyle={css({ minWidth: "70px" })}
                                    label=""
                                    isHiddenLabel={true}
                                    name="midnightStartTime"
                                    value={item.startTime.split(":")[0]}
                                    setValue={(val: string) => {
                                      formik.setFieldValue(
                                        "timeZoneItems[" + index + "].startTime",
                                        val + ":" + item.startTime.split(":")[1],
                                      );
                                    }}
                                    options={hourFormOptions}
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem marginLeft="7px" marginRight="7px">
                                  <span>:</span>
                                </FlexBoxItem>
                                <FlexBoxItem width="70px">
                                  <SelectFormV2
                                    customStyle={css({ minWidth: "70px" })}
                                    label=""
                                    isHiddenLabel={true}
                                    name="midnightStartTime2"
                                    value={item.startTime.split(":")[1]}
                                    setValue={(val: string) => {
                                      formik.setFieldValue(
                                        "timeZoneItems[" + index + "].startTime",
                                        item.startTime.split(":")[0] + ":" + val,
                                      );
                                    }}
                                    options={timeFormOptions}
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem marginLeft="20px" marginRight="20px">
                                  <span>〜</span>
                                </FlexBoxItem>
                                <FlexBoxItem width="70px">
                                  <SelectFormV2
                                    customStyle={css({ minWidth: "70px" })}
                                    label=""
                                    isHiddenLabel={true}
                                    name="midnightEndTime"
                                    value={item.endTime.split(":")[0]}
                                    setValue={(val: string) => {
                                      formik.setFieldValue(
                                        "timeZoneItems[" + index + "].endTime",
                                        val + ":" + item.endTime.split(":")[1],
                                      );
                                    }}
                                    options={hourFormOptions}
                                  />
                                </FlexBoxItem>
                                <FlexBoxItem marginLeft="7px" marginRight="7px">
                                  <span>:</span>
                                </FlexBoxItem>
                                <FlexBoxItem width="70px">
                                  <SelectFormV2
                                    customStyle={css({ minWidth: "70px" })}
                                    label=""
                                    isHiddenLabel={true}
                                    name="midnightEndTime2"
                                    value={item.endTime.split(":")[1]}
                                    setValue={(val: string) => {
                                      formik.setFieldValue(
                                        "timeZoneItems[" + index + "].endTime",
                                        item.endTime.split(":")[0] + ":" + val,
                                      );
                                    }}
                                    options={timeFormOptions}
                                  />
                                </FlexBoxItem>
                              </FlexBox>
                            }
                          </div>
                        );
                      })}
                    </ReactSortable>
                  )}
                  {detailRole.editable === 1 && (
                    <React.Fragment>
                      <div style={{ margin: "20px 0 30px -7px " }}>
                        <IconLabelButton iconType="clone" onClick={addNewTimeZone} text="行追加" width="auto" />
                      </div>

                      <div style={{ textAlign: "center", margin: "15px 0" }}>
                        <div>
                          <Button text="更新" onClick={() => formik.handleSubmit()} />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {/* <ConfirmActionModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={deleteTimeZone}
            actionType={ActionType.DELETE}
          /> */}
              <ConfirmModal
                open={confirmModalOpen}
                closeHandler={closeConfirmModal}
                onSubmit={deleteTimeZone}
                title="時間帯を削除"
                content="時間帯を削除します。よろしいですか？"
                submitText="削除"
                typeSubmit={"reject"}
              />
              <ConfirmModal
                open={updateConfirmModalOpen}
                closeHandler={closeUpdateConfirmModal}
                onSubmit={formik.handleSubmit}
                title="時間帯マスタを更新"
                content="時間帯マスタを更新します。よろしいですか？"
                submitText="更新する"
              />
            </div>
          </FormPadding>
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default TimeZonePage;
