import React, { SVGProps } from 'react';

const Cubic: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19 25C22.3137 25 25 22.3137 25 19C25 15.6863 22.3137 13 19 13C15.6863 13 13 15.6863 13 19C13 22.3137 15.6863 25 19 25ZM19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.6376 0.0680068C21.97 -0.0612801 22.3462 -0.00369006 22.6247 0.219143L37.6247 12.2191C37.9076 12.4455 38.046 12.8071 37.9864 13.1644L34.9864 31.1644C34.9282 31.5137 34.6895 31.8062 34.359 31.9334L21.359 36.9334C20.8435 37.1316 20.2649 36.8745 20.0667 36.359C19.8684 35.8435 20.1256 35.2649 20.641 35.0667L33.1076 30.2718L35.9172 13.4144L21.8253 2.1409L4.887 8.72803L2.08789 24.5897L15.2938 35.1544L18.0136 18.8356C18.0745 18.4702 18.3324 18.1685 18.6838 18.0513L30.6838 14.0513C31.2077 13.8767 31.7741 14.1598 31.9487 14.6838C32.1234 15.2077 31.8402 15.774 31.3163 15.9487L19.8874 19.7583L16.9864 37.1644C16.9281 37.5143 16.6887 37.8072 16.3574 37.934C16.0261 38.0608 15.6524 38.0025 15.3753 37.7809L0.37533 25.7809C0.0897773 25.5524 -0.0483097 25.1863 0.0152412 24.8262L3.01524 7.82623C3.07597 7.48211 3.31191 7.19466 3.63758 7.06801L21.6376 0.0680068Z" fill="#003993"/>
  </svg>
);

export default Cubic;
