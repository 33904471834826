/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { PrimaryButton, SecondaryButton } from 'components/atoms/Button';

const styles = {
  wrapper: css({
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
  }),
  buttonContainer: css({
    margin: '0 auto 15px auto',
    width: '100%',
  }),
};


/**
 * モーダルのフッター
 *  - Submitボタンを持つ
 */
const Footer: React.FC<{
  submitText?: string
  closeText: string
  closeHandler: () => void
  onSubmit: () => void
  typeSubmit: string
  customStyleFooter?: SerializedStyles,
  disabledSubmit: boolean
}> = ({
  submitText, onSubmit, closeHandler, closeText, typeSubmit, customStyleFooter, disabledSubmit
}) => (
  <div css={css([styles.wrapper, customStyleFooter])}>
    <div css={styles.buttonContainer}>
      {submitText && typeSubmit === 'confirm' && submitText
      && (
      <PrimaryButton
        text={submitText}
        onClick={onSubmit}
        minWidth="200px"
        customStyle={css({fontWeight: 'bold'})}
        disabled={disabledSubmit}
      />
      )}

      {submitText && typeSubmit === 'reject'
      && (
      <SecondaryButton
        text={submitText}
        onClick={onSubmit}
        minWidth="200px"
        customStyle={css({fontWeight: 'bold'})}
        disabled={disabledSubmit}
      />
      )}

    </div>
    <div css={styles.buttonContainer}>
      {typeSubmit === 'confirm'
      && closeText &&(
      <PrimaryButton
        text={closeText}
        onClick={closeHandler}
        minWidth="200px"
        ghost={true}
        customStyle={css({fontWeight: 'bold'})}
      />
      )}

      {typeSubmit === 'reject'
      && (
      <SecondaryButton
        text={closeText}
        onClick={closeHandler}
        minWidth="200px"
        ghost={true}
        customStyle={css({fontWeight: 'bold'})}
      />
      )}
    </div>
  </div>
);

export default Footer;
