import React, { SVGProps } from 'react';
 
 const BreakEnd: React.FC<SVGProps<SVGSVGElement>> = props => (
   <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g style={{mixBlendMode:'soft-light'}}>
       <path fillRule="evenodd" clipRule="evenodd" d="M30 10.5C30 11.3284 30.6716 12 31.5 12H55.5C57.9853 12 60 14.0147 60 16.5V55.5C60 57.9853 57.9853 60 55.5 60H31.5C30.6716 60 30 60.6716 30 61.5C30 62.3284 30.6716 63 31.5 63H55.5C59.6421 63 63 59.6421 63 55.5V16.5C63 12.3579 59.6421 9 55.5 9H31.5C30.6716 9 30 9.67157 30 10.5ZM23.5607 45.4393L14.1213 36H46.5C47.3284 36 48 35.3284 48 34.5C48 33.6716 47.3284 33 46.5 33H14.1213L23.5607 23.5607C24.1464 22.9749 24.1464 22.0251 23.5607 21.4393C22.9749 20.8536 22.0251 20.8536 21.4393 21.4393L9.43934 33.4393C8.85355 34.0251 8.85355 34.9749 9.43934 35.5607L21.4393 47.5607C22.0251 48.1464 22.9749 48.1464 23.5607 47.5607C24.1464 46.9749 24.1464 46.0251 23.5607 45.4393Z" fill="white"/>
     </g>
   </svg>
 
 
 );
 
 export default BreakEnd;