/** @jsx jsx */

import React from "react";
import moment from "moment";
import { css, jsx } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const NursingCareHolidayDataTable: React.FC<{
  nursingCareHolidayData: any;
}> = ({ nursingCareHolidayData }) => (
  <div
    css={css`
      table thead tr th {
        height: 46px;
      }
      table tbody tr td {
        height: 32px;
      }
    `}
  >
    <table
      id="nursingCareHolidayList"
      className="table table-bordered table-condensed"
      cellSpacing="0"
      style={{ whiteSpace: "nowrap", width: "100%" }}
    >
      <tbody>
        <tr role="row">
          <td>
            <div css={thStyle}>休暇コード</div>
          </td>
          <td>
            <div css={tdStyle}>{nursingCareHolidayData.holidayCode || ""}</div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>休暇名</div>
          </td>
          <td>
            <div css={tdStyle}>{nursingCareHolidayData.holidayName || ""}</div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>取得可能日数</div>
          </td>
          <td>
            <div css={tdStyle}>{nursingCareHolidayData.yearUseDays || 0}日</div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>取得日数</div>
          </td>
          <td>
            <div css={tdStyle}>
              {nursingCareHolidayData.digestedDaysNum || 0}日{nursingCareHolidayData.digestedTimeHour || 0}時間
            </div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>残日数</div>
          </td>
          <td>
            <div css={tdStyle}>
              {nursingCareHolidayData.remainDaysNum || 0}日{nursingCareHolidayData.remainTimesNum || 0}時間
            </div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>1年とする期間(開始)</div>
          </td>
          <td>
            <div css={tdStyle}>
              {nursingCareHolidayData.targetStartDate == null
                ? ""
                : moment(nursingCareHolidayData.targetStartDate).format("YYYY年MM月DD日")}
            </div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>1年とする期間(終了)</div>
          </td>
          <td>
            <div css={tdStyle}>
              {nursingCareHolidayData.targetEndDate == null
                ? ""
                : moment(nursingCareHolidayData.targetEndDate).format("YYYY年MM月DD日")}
            </div>
          </td>
        </tr>
        <tr role="row">
          <td>
            <div css={thStyle}>備考</div>
          </td>
          <td>
            <div css={tdStyle}>
              {nursingCareHolidayData.notes?.map((element: any) => {
                let targetDate = moment(moment(element.targetDate).toDate()).format("YYYY年MM月DD日") || "";
                return (
                  <FlexBox justifyContent="center">
                    {`${targetDate}：取得可能日数を${element.yearUseDays}日に変更`}
                  </FlexBox>
                );
              })}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
export default NursingCareHolidayDataTable;
