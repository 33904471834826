import { combineReducers } from 'redux';

import sidebar, { UiSidebarState } from './sidebar';
import appBar, { AppBarType } from './appBar';
import icCard, { ICCardStateType } from './icCard';
import general, { GeneralType } from './general';
export interface UiState {
  general: GeneralType,
  sidebar: UiSidebarState;
  appBar: AppBarType,
  icCard: ICCardStateType,
}

const reducer = combineReducers<UiState>({ sidebar, appBar, icCard, general });

export default reducer;
