import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { string } from 'yup';

export const getDigestionItemsForPaidHolidayStatus = async (
  orgCode: string,
  startDigestedDay: any,
  endDigestedDay: any,
  employmentId: string,
  isHasTenGrantDays: boolean,
  underFlag: number,
  untilNextGrantDate?: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    currentStaffCode,
    currentOrgCode,
    startDigestedDay,
    endDigestedDay,
    employmentId,
    isHasTenGrantDays,
    untilNextGrantDate,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForTransferHolidayStatus = async (
  orgCode: string,
  targetDate: any,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/transferHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForSpecialHolidayStatus = async (
  orgCode: string,
  employmentId: string,
  holidayId: string,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    employmentId,
    holidayId,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayStatus/${companyCode}`, params);
  return response.data;
};

/**
 * saveUseItem
 *
 * @param companyCode
 * @param dataShiftPattern
 */
export const saveUseItemForPaidHolidayStatus = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/holidayManagement/save/paidHolidayStatus/${companyCode}`, query, data);
  return response.data;
};

/**
 * saveUseItem
 *
 * @param companyCode
 * @param dataShiftPattern
 */
export const saveUseItemForSpecialHoliday = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/holidayManagement/save/specialHoliday/${companyCode}`, query, data);
  return response.data;
};

export const getGrantItemsForPaidHolidayGrantHistory = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  paidGrantResult: string,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    paidGrantResult,
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayGrantHistory/${companyCode}`, params);
  return response.data;
};


export const getGrantItemsForSpecialHolidayGrantHistory = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  underFlag: number
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayGrantHistory/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForSubstituteHolidayStatus = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/substituteHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForSpecialHoliday = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayDigestion/${companyCode}`, params);
  return response.data;
};

export const getDigestionItemsForPaidHolidayDigestedtHistory = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayDigestedtHistory/${companyCode}`, params);
  return response.data;
};

export const getPaidAttendManagementList = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // TODO 仮リクエスト
  const params = {
    orgCode,
    targetDate,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayManagementList/${companyCode}`, params);
  return response.data;
};

// Nurse Care Acquisition
export const getAcquisitionStatusNursingCareList = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  holidayType: number,
  underFlag: number
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    formatType: holidayType,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/nursingHolidayDigestion/${companyCode}`, params);
  return response.data;
};

export const downloadPaidAttendHolidayManagementExcel = async (data: any, fileName: any): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  await ApiClient.downloadExcelPost(`/v1/paidAttendHolidayManagementExportFile/excel/${companyCode}`, {}, data, getDownloadExcelFileName(fileName));
}

export const getDigestionItemsDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayDigestionDetail/${companyCode}`, params);
  return response.data;
};

export const getSpecialHolidayStatusDetail = async (
  staffCode: string,
  staffName: string,
  holidayId: string,
  holidayName: string,
  specialHolidayGrantId: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    holidayId,
    holidayName,
    staffName,
    specialHolidayGrantId,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayStatusDetail/${companyCode}`, params);
  return response.data;
};

export const getTransferHolidayStatusDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/transferDigestedtHistory/${companyCode}`, params);
  return response.data;
};

export const getPaidHolidayStatusDetail = async (
  staffCode: string,
  paidHolidayGrantId: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    paidHolidayGrantId: paidHolidayGrantId == null ? '' : paidHolidayGrantId,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/paidHolidayStatusDetail/${companyCode}`, params);
  return response.data;
};

export const getManagementNursingHolidayStatus = async (
  holidayType: string,
  orgCode: string,
  underFlag: number
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    formatType: holidayType,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/nursingHolidayStatus/${companyCode}`, params);
  return response.data;
};

export const getSpecialHolidayDigestionDetail = async (
  staffCode: string,
  targetDate: string,
  holidayId: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
    holidayId,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/specialHolidayDigestionDetail/${companyCode}`, params);
  return response.data;
};

export const getSubstituteDigestedtHistoryDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/substituteDigestedtHistory/${companyCode}`, params);
  return response.data;
};

export const getRestDigestedDetail = async (
  staffCode: string,
  targetDate: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    targetDate,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/staff/detail/${companyCode}`, params);
  return response.data;
};

export const getNursingCareDigestionItemsDetail = async (
  staffCode: string,
  holidayType: number,
  targetDateFrom: string,
  targetDateTo: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    formatType: holidayType,
    targetDateFrom,
    targetDateTo,
    currentStaffCode,
    currentOrgCode
  };
  const response = await ApiClient.get(`/v1/holidayManagement/nursingHolidayDigestionDetail/${companyCode}`, params);
  return response.data;
};

/**
 * CSVファイルインポート
 */

export const downloadPaidHolidayDigestionCsv = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    currentStaffCode,
    currentOrgCode,
    orgCode,
    targetDate: moment(targetDate).format('YYYY/MM/DD'),
    underFlag
  };
  const nameCsvExport = '有給取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/paidHolidayDigestion/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSubstituteHolidayCsv = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDate: moment(targetDate).format('YYYY/MM/DD'),
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '代休取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/substituteHoliday/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSpecialHolidayGrantHistoryCsv = async (
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '特休付与履歴_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/specialHolidayGrantHistory/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSpecialHolidayStatusCsv = async (
  orgCode: string,
  employmentId: string,
  holidayId: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    employmentId,
    holidayId,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '特休管理情報_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/specialHolidayStatus/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

// nursing manage csv
export const downloadNursingCareHolidayStatusCSV = async (
  holidayType: number,
  orgCode: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    formatType: holidayType,
    orgCode,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = holidayType === 7 ? '介護休暇管理情報' : '子の看護休暇管理情報';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/nursingHolidayStatus/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadPaidHolidayGrantHistoryCsv = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  paidGrantResult: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    paidGrantResult,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '有給付与履歴_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/paidHolidayGrantHistory/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadNursingCareHistoryCsv = async (
  holidayType: number,
  orgCode: string,
  targetDateFrom: string,
  targetDateTo: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    companyCode,
    formatType: holidayType,
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = holidayType === 7 ? '介護休暇取得状況_' : '子の看護休暇取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/nursingHolidayDigestion/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadPaidHolidayStatusCsv = async (
  orgCode: string,
  startDigestedDay: string,
  endDigestedDay: string,
  employmentId: string,
  isHasTenGrantDays: boolean,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    startDigestedDay,
    endDigestedDay,
    employmentId,
    isHasTenGrantDays,
    untilNextGrantDate: '',
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '有給管理情報_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/paidHolidayStatus/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadTransferHolidayCsv = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDate,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '振休取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/transferHoliday/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export const downloadSpecialHolidayDigestionCsv = async (
  orgCode: string,
  targetDate: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDate: moment(targetDate).format('YYYY/MM/DD'),
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '特休取得状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/specialHolidayDigestion/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};


/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File, formatType?: number): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    formatType: Number(formatType),
    createUser: staffName,
    updateUser: staffName,
    currentStaffCode,
    currentOrgCode
  };
  const response = await ApiClient.postFile(`/v1/holidayManagement/csv/import/history/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;
const getDownloadExcelFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.xlsx`;

export const getStockPaidHolidayStatus = async (orgCode: string, underFlag: number): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode: orgCode,
    currentOrgCode,
    currentStaffCode,
    underFlag
  }
  const response = await ApiClient.get(`/v1/holidayManagement/stockPaidHolidayStatus/${companyCode}`, params);
  return response.data;
}

export const getStockPaidHolidayStatusDetail = async (
  staffCode: string,
  // stockPaidlHolidayGrantId: string,
): Promise<Array<any>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
    staffCode,
    // stockPaidlHolidayGrantId
  };
  const response = await ApiClient.get(`/v1/holidayManagement/stockPaidHolidayStatusDetail/${companyCode}`, params);
  return response.data;
};

export const saveUseItemForStockPaidHolidayStatus = async (companyCode: string, data: any) => {
  // TODO パラメータ確認
  const query = {
  };
  const response = await ApiClient.post(`/v1/holidayManagement/save/stockPaidHoliday/${companyCode}`, query, data);
  return response.data;
};

export const downloadStockPaidHolidayStatusCsv = async (
  orgCode: string,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    currentStaffCode,
    currentOrgCode,
    underFlag
  };
  const nameCsvExport = '積立有給管理情報_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/stockPaidHolidayStatus/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};


export const getGrantItemsForStockPaidHolidayGrantHistory = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  underFlag: number
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag
  };
  const response = await ApiClient.get(`/v1/holidayManagement/stockPaidHolidayGrantHistory/${companyCode}`, params);
  return response.data;
};

export const downloadStockPaidHolidayGrantHistoryCsv = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  underFlag: number
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode,
    currentOrgCode,
    underFlag,
  };
  const nameCsvExport = '積立有給付与履歴_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/stockPaidHolidayGrantHistory/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

// tab 6 積立有給 -- accordian 取得状況
export const getDigestionItemsForStockPaidHolidayDigestedtHistory = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  underFlag: number,
): Promise<Array<any>> => {

  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';

  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag: underFlag,
  };
  const response = await ApiClient.get(`/v1/holidayManagement/stockPaidHolidayDigestion/${companyCode}`, params);
  return response.data;
};

export const getStockDigestionItemsDetail = async (
  staffCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    staffCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
  };
  const response = await ApiClient.get(`/v1/holidayManagement/stockPaidHolidayDigestionDetail/${companyCode}`, params);
  return response.data;
};

export const downloadStockPaidHolidayDigestionCsv = async (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  underFlag: number,
): Promise<void> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  const params = {
    orgCode,
    targetDateFrom: moment(targetDateFrom).format('YYYY/MM/DD'),
    targetDateTo: moment(targetDateTo).format('YYYY/MM/DD'),
    currentStaffCode: currentStaffCode,
    currentOrgCode: currentOrgCode,
    underFlag: underFlag,
  };
  const nameCsvExport = '積立有給消化状況_';
  await ApiClient.downloadCsvNoConvert(`/v1/holidayManagement/outputCsv/stockPaidHolidayDigestion/${companyCode}`, params, getDownloadCsvFileName(nameCsvExport));
};

export type Error = {
  error: string;
  defaultMessage: string;
}

export default getDigestionItemsForPaidHolidayStatus;
