import Actions, { types } from './actions';
import { State, RoleFunctionData } from './type';
import * as Yup from 'yup';
import { set } from "lodash";

const defaultDetailRoleFunction = {
  id: {
      companyCode: '',
      roleId: '',
  },
  roleFunctinList: [],
  roleCode: '',
  roleName: '',
  site: 0,
  allOrganizationAccess: false,
  budgetManagement: false,
  paidControl: false,
  administrator: false,
  allStaffAccess: false,
  retired: false,
  accountingRole: false,
  attendanceRole: false,
  allApplicationAccess: false,
  accessLevel: 0,
  createUser: null,
  createDate: new Date(),
  updateUser: null,
  updateDate: new Date(),
  roleDetailList: null,
}

export const initialState: State = {
  data: defaultDetailRoleFunction,
};

export const reducer = (state= initialState , action: Actions): State => {
  let newState = JSON.parse(JSON.stringify(state));
  
  switch (action.type) {
    case types.SET_STATE:
      newState = action.payload;
      break;
    case types.SET_DATA:
      console.log(action.payload)
      newState.data = action.payload;
      break;
    case types.ON_CHANGE:
      set(newState, 'data.'+ action.payload.path, action.payload.data);
      break;
    case types.ON_CHANGE_RADIO:
      newState
        .data
        .roleFunctinList
        .map((item: RoleFunctionData) => {
          if(item.functionMasterId === action.payload.functionMasterId){
            item[action.payload.label] = action.payload.data
          }
        });
      break;
    }
  return newState;
};

export default reducer;
