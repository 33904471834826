/* eslint-disable max-len */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize, grayScale, textColor } from 'components/styles';
import ClickableLink from 'components/atoms/ClickableLink';
// import Dropdown from 'components/molecules/Dropdown';
import Icon from 'components/atoms/Icon';
import ReactPaginate from 'react-paginate';
import { Dropdown } from 'react-bootstrap';

const style = {
  text: css({
    color: textColor.main,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    marginRight: '10px'
  }),
  wrap: css({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    fontSize: textFontSize.re,
  }),
  icon: css({
    position: 'relative',
    right: '-10px',
    top: '0px'
  })
};

export interface PaginationProps {
  total: number;
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  nextPage: () => void;
  canNextPage: boolean;
  previousPage: () => void;
  canPreviousPage: boolean;
  setPageSize: (pageSize: number) => void;
  gotoPage: (nextPage: number) => void;
  tableId?: number;
  onClickPagination?: () => void;
}

const PaginationCustom: React.FC<PaginationProps> = ({
  total, pageSize, pageIndex, pageCount, nextPage, canNextPage, previousPage,
  canPreviousPage, setPageSize, gotoPage, tableId, onClickPagination
}) => {
  const handlePageChange = (value: any) => {
    if (onClickPagination) {
      onClickPagination()
    }

    gotoPage(value + 1);
    sessionStorage.setItem(`${tableId}/sessionData${window.location.pathname}.initPageIndex`, String(value));
  };
  const itemPerPage = [20, 50, 100];
  return (
    <div css={style.wrap}>
      <div css={style.text}>表示件数</div>
      <div className="filter-number-item">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <span>
              <span style={{ color: '#333', position: 'relative' }}>{pageSize}</span>
              <span css={style.icon}>
                <Icon type="dropdown" color="#333" />
              </span>
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ zIndex: 100 }}>
            {
              itemPerPage && itemPerPage.map((value, index) => (
                <Dropdown.Item
                  key={index.toString()}
                  onClick={() => {
                    setPageSize(Number(value));
                    sessionStorage.setItem(`${tableId}/sessionData${window.location.pathname}.initPageSize`, String(value));
                  }}
                >
                  {value}

                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="pagination-custom">
        <ReactPaginate
          previousLabel={(
            <ClickableLink onClick={previousPage} disabled={!canPreviousPage} color={grayScale.gray100}>
              <Icon type="doubleArrowLeft" color="#333333" />
            </ClickableLink>
          )}
          nextLabel={(
            <ClickableLink onClick={nextPage} disabled={!canNextPage} color={grayScale.gray100}>
              <Icon type="doubleArrowRight" color="#333333" />
            </ClickableLink>
          )}
          breakLabel="..."
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={(e) => {
            handlePageChange(e.selected);
          }}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageIndex}
        />
      </div>
    </div>
  );
};

export default PaginationCustom;
