/** @jsx jsx */

import React, { useState, forwardRef, useEffect, useRef } from "react";
import DatePicker, { ReactDatePickerProps }  from "react-datepicker";
import Icon from 'components/atoms/Icon';
import { utilityColor, grayScale } from 'components/styles';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from 'react-dom'
import { components } from 'react-select';
import Select, { OptionType } from 'components/atoms/Select';
import { css, jsx } from '@emotion/core';
type TypeCalendar = "day" | "month" | "year";

const listMonth = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9',
  '10', '11', '12',
];

const MAX_VALUE = 2099;
const MIN_VALUE = 1900;

const CurrentYearv1: React.FC<{
  year: any, choiseYear: any
}> = ({
  year, choiseYear
}) => {

  const Option = (props: any) => {
    const ref = useRef<null | HTMLElement>(null);
    useEffect(() => {
        if (props.isSelected) {
            ref?.current?.scrollIntoView({block: 'nearest', inline: 'end' });
        }
    }, [props.isSelected]);

    return (
        <components.Option {...props} innerRef={ref} />
    );
};

  const Input = (props: any) => {
  
    return (
      <div className="input-container-react-select">
        <components.Input {...props} />
      </div>
    );
  };

  const IndicatorsContainer = (props: any) => {
    return (
      <div className="indicators-container">
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const MenuComponent = (props: any) => {
    return (
      <div className="menu-container">
        <components.MenuList {...props} />
      </div>
    );
  };

  interface Props {
    children: React.ReactNode
  }

  let optionsYear = [];
  for (let i = MIN_VALUE; i <= MAX_VALUE; i += 1) {
    optionsYear.push( {
      value: `${i}`,
      label: `${i}`
    });
  } 

    return (
      <div css={css`
        div{
          justify-content: center;
          border: 0;
          :hover{
            border: 0;
            cursor: pointer;
          }
        }
      `}>
      <Select
        name="select-month"
        className="calendar__year-value"
        value={String(year)}
        setValue={(arg) => choiseYear(Number(arg))}
        options={optionsYear}
        placeholder="チーフ"
        controlStyle={{
          width: '70px',
          border: '0',
          backgroundColor: 'transparent',
        }}
        components={{ Option, IndicatorsContainer, MenuComponent, Input }}
        maxMenuHeight={200}
        customMenuStyle={{
          marginTop: '0',
        }}
        isSearchable={false}
      />
      </div>
    );
  };

export default CurrentYearv1;