/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import SignUpInputForm from 'components/organismos/login/SignUpForm';
import LoginFormContainer from 'components/organismos/login/LoginFormContainer';

const SignUpPage: React.FC = () => (
  <React.Fragment>
    <LoginFormContainer 
      titleForm="ユーザー登録" 
      noteForm="
        HINATAを使用するためにユーザー登録の必要があります。<br/>
        HINATAのスタッフ情報に登録されているメールアドレスと任意のパスワード（6文字以上）を入力して下さい。
      ">      
      <SignUpInputForm />
    </LoginFormContainer>  
  </React.Fragment>
);

export default SignUpPage;
