import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterSettingAgreement36Form from 'components/organismos/master/MasterSettingAgreement36Form';
import { useParams } from 'react-router';

const MasterSettingAgreement36CreatePage : React.FC = () => {
    const { agreementsId } = useParams();
return (
    <SidebarTemplate pageTitle="36協定アラートマスタ設定">
        <MasterSettingAgreement36Form agreementsId={agreementsId || ''} />
    </SidebarTemplate>
    );
};

export default MasterSettingAgreement36CreatePage;
