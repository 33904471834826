/* eslint-disable react/no-danger */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { textFontSize, grayScale } from 'components/styles';

const style = css({
  padding: '0 0 16px 0',
  margin: '0',
  fontSize: textFontSize.re,
  textAlign: 'center',
  color: grayScale.gray01,
});

const PanelDescription: React.FC<{children: string}> = ({ children }) => (
  <div css={style}>
    <div dangerouslySetInnerHTML={{ __html: children }} />
  </div>
);

export default PanelDescription;
