import React, { SVGProps } from 'react'; 

const TimeRecorder: React.FC<SVGProps<SVGSVGElement>> = props => {
  return ( 
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM13.9069 12.7906C14.0674 12.5659 14.0153 12.2536 13.7906 12.0931L10.4355 9.69664L8.98238 4.36844C8.90972 4.10203 8.63485 3.94496 8.36844 4.01762C8.10203 4.09028 7.94496 4.36515 8.01762 4.63156L9.51762 10.1316C9.54792 10.2427 9.61566 10.3399 9.70938 10.4069L13.2094 12.9069C13.4341 13.0674 13.7464 13.0153 13.9069 12.7906Z" fill="#666666" />
    </svg> 
  )
}

export default TimeRecorder;
