/** @jsx jsx */
import React from "react";

import TextInput from "components/atoms/Form/TextInput";
import VerticalLabelFormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import { SerializedStyles, css, jsx } from "@emotion/core";
import productColor, { grayScale, textFontSize } from "components/styles";

const styles = {
  wrapper: css({
    display: "flex",
  }),
  input: css({
    flex: 1,
    width: "32px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    border: `1px solid ${grayScale.gray20}`,
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    border: `1px solid ${grayScale.gray20}`,
    borderRadius: "0 4px 4px 0",
  }),
};

const TextFormSearch: React.FC<{
  name: string;
  label: string;
  value: string;
  note?: string;
  type?: string;
  placeHolder?: string;
  groupText?: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg?: string;
  disable?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  appendLabel?: string;
  toolTip?: string;
  customStyle?: SerializedStyles;
}> = ({ name, label, type, value, onChange, placeHolder, groupText, disable, min, max, maxLength, customStyle }) => (
  <VerticalLabelFormLayout
    label={""}
    input={
      <div css={styles.wrapper}>
        <TextInput
          min={min}
          max={max}
          maxLength={maxLength}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          placeHolder={placeHolder}
          type={type}
          disable={disable}
          customStyle={disable ? css({ backgroundColor: grayScale.gray05 }) : css(customStyle)}
        />
        {groupText && <div css={styles.addon}> {groupText}</div>}
      </div>
    }
    errorMsg={""}
  />
);

export default TextFormSearch;
