
/**
 * API 時間帯マスタ
 */
export interface TimeZone{
  id: string;
  termId: string;
  termName: string;
  targetType:number;
  startTime: string;
  endTime:string;
}

export interface TimeZoneOption {
  timeZoneItems: Array<TimeZone>,
}

export default class TimeZoneList {
  constructor(private rawData: TimeZoneOption) {
    //
  }

  static generateInitial(): TimeZoneList {
    return new TimeZoneList({
      timeZoneItems: []
    });
  }

  getRowData(): TimeZoneOption {
    return this.rawData;
  }

  get timeZoneItems(): Array<TimeZone> {
    return this.rawData.timeZoneItems;
  }

  set timeZoneItems(timeZoneItems: Array<TimeZone>) {
    this.rawData.timeZoneItems = timeZoneItems;
  }
}
