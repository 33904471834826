/** @jsx jsx */
import React, { ReactNode, ComponentProps } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { textFontSize, textColor, utilityColor } from 'components/styles';
import FormLabel from 'components/atoms/Form/FormLabel';
import RadioSelector from './RadioSelector';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';

const styles = {
  subLabel: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: '4px',
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: '4px',
  }),
};

const RadioSelectFormHorizontal: React.FC<{
  label?: string;
  subLabel?: string;
  customStyleLabel?: SerializedStyles;
  note?: string|ReactNode;
  required?: boolean;
  readOnly?: boolean;
  noTitle?: boolean;
  additionStyle?:any;
  disabled?: boolean;
  showTooltip?: boolean;
  itemsTooltip?: any;
  marginItem?: string;
} & ComponentProps<typeof RadioSelector>> = ({
  label, subLabel, customStyleLabel, items, name, value, setValue, note, required, readOnly = false, noTitle = false, additionStyle, disabled = false, showTooltip, itemsTooltip, marginItem
}) => (
  <FlexBox customStyle={css({alignItems: 'baseline'})}>
    {label && (
      <FlexBoxItem alignSelf="baseline" customStyle={css([{minWidth: '100px'}, customStyleLabel])}>
          <FormLabel
            label={label}
            subText={subLabel}
            required={required}
          />
      </FlexBoxItem>
    )}
    <FlexBoxItem>
      <RadioSelector
        items={items}
        name={name}
        value={value}
        setValue={setValue}
        readOnly={readOnly}
        noTitle={noTitle}
        addon={additionStyle}
        disabled={disabled}
        showTooltip={showTooltip}
        itemsTooltip={itemsTooltip}
        marginItem={marginItem}
      />
      {note && <span css={styles.noteText}>{note}</span>}
    </FlexBoxItem>
  </FlexBox>
);

export default RadioSelectFormHorizontal;
