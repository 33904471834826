import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import ShiftViewForm from 'components/organismos/master/attend/ShiftViewForm';

const ShiftViewPage: React.FC = () => (
  <SidebarTemplate>
    <ShiftViewForm />
  </SidebarTemplate>
);

export default ShiftViewPage;
