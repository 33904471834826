import React, { useState } from "react";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Row, Col } from "react-grid-system";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import BodyText from "components/atoms/BodyText";
import TabControl from "components/atoms/TabControl";
import BlockUI from "components/molecules/BlockUi";
import moment from "moment";
import { eventScroll, useGetTimeList, useGetOrganizationsTrees } from "./hooks";
import ToastModal from "../../../ToastModal";
import DatePickerForm from "../../../../molecules/DatePickerForm";
import TimeTable from "./TimeTable";
import DayTable from "./DayTable";
import SelectForm from "components/molecules/SelectForm";
import { css } from "@emotion/core";

type SelectTab = "tab_time_table" | "tab_day_table";
const SelectTab: { [key in SelectTab]: SelectTab } = {
  tab_time_table: "tab_time_table",
  tab_day_table: "tab_day_table",
};

const AttendResultForm: React.FC = () => {
  const [toastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [selectTab, setSelectTab] = React.useState<SelectTab>(
    SelectTab.tab_time_table
  );
  const [isLoading, setLoading] = useState(false);

  const currentDate = new Date();
  const firstDayStr = moment(currentDate).format("YYYY-MM-01");
  const lastDayStr = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("YYYY-MM-DD");
  const [targetDateFrom, setTargetDateFrom] = useState(new Date(firstDayStr));
  const [targetDateTo, setTargetDateTo] = useState(new Date(lastDayStr));
  const [selectedClosingDate] = useState(
    sessionStorage.getItem("headquartersFinal.monthlyList.selClosingDate") ||
      "-1"
  );
  const [selectedEmploymentId] = useState(
    sessionStorage.getItem(
      "headquartersFinal.monthlyList.searchEmploymentId"
    ) || ""
  );
  const [attendFlag] = useState(
    sessionStorage.getItem("headquartersFinal.monthlyList.attendFlag") || "0"
  );
  const [selectOrgCode, setSelectOrgCode] = useState(
    sessionStorage.getItem("loginUser.orgCode") || ""
  );
  const { orgCodeList } = useGetOrganizationsTrees(
    targetDateFrom,
    targetDateTo,
    selectOrgCode
  );

  const {
    term,
    business,
    subTotalTimeList,
    subTotalDayList,
    holidaySubtotalItem,
  } = useGetTimeList(
    setLoading,
    targetDateFrom,
    targetDateTo,
    selectedClosingDate,
    attendFlag,
    selectOrgCode,
    selectedEmploymentId
  );

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <Row>
          <Col md={2}>
            <div style={{ marginTop: "10px" }}>
              <BodyText>対象期間</BodyText>
            </div>
          </Col>
          <Col md={10}>
            <FlexBox>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  isFullWidth={true}
                  date={targetDateFrom}
                  changeDate={(date: Date) => setTargetDateFrom(date)}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <span style={{ margin: "0 30px" }}>～</span>
              </FlexBoxItem>
              <FlexBoxItem>
                <DatePickerForm
                  dateFormat="yyyy年MM月dd日"
                  label=""
                  isFullWidth={true}
                  date={targetDateTo}
                  changeDate={(date: Date) => setTargetDateTo(date)}
                />
              </FlexBoxItem>
            </FlexBox>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", alignItems: "center" }} md={2}>
            <BodyText>組織名</BodyText>
          </Col>
          <Col md={10}>
            <Row>
              <Col md={4}>
                <SelectForm
                  label=""
                  name="selectOrgCode"
                  value={selectOrgCode}
                  setValue={(v) => {
                    setSelectOrgCode(v);
                  }}
                  options={orgCodeList}
                  placeholder=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ marginTop: "30px" }}>
          <TabControl
            items={[
              {
                value: SelectTab.tab_time_table,
                display: "集計時間データ",
              },
              {
                value: SelectTab.tab_day_table,
                display: "集計日数データ",
              },
            ]}
            setValue={setSelectTab as (arg: string) => void}
            currentValue={selectTab}
            isFixed={true}
            customStyle={css({
              margin: "0px",
              padding: "8px 16px",
              boxSizing: "border-box",
              maxWidth: "100%",
              overflow: "auto",
            })}
          />
          {selectTab === SelectTab.tab_time_table && (
            <div style={{ marginTop: "20px" }}>
              <TimeTable
                eventScroll={eventScroll}
                subTotalTimeList={subTotalTimeList}
                business={business || []}
                term={term}
                targetDateFrom={targetDateFrom}
                targetDateTo={targetDateTo}
                selectedEmploymentId={selectedEmploymentId}
                selectOrgCode={selectOrgCode}
                selectedClosingDate={selectedClosingDate}
                attendFlag={attendFlag}
              />
            </div>
          )}
          {selectTab === SelectTab.tab_day_table && (
            <div style={{ marginTop: "20px" }}>
              <DayTable
                eventScroll={eventScroll}
                holidaySubtotalItem={holidaySubtotalItem}
                subTotalDayList={subTotalDayList}
              />
            </div>
          )}
        </div>
        <ToastModal
          open={toastModalOpen}
          closeHandler={() => setToastModalOpen(false)}
          title={toastMessage}
        />
      </FormContents>
    </BlockUI>
  );
};

export default AttendResultForm;
