import { useState, useEffect, useCallback } from 'react';
import PersonalSettingApplicationDomain from 'domain/personalSetting';
import { getLoginStaff } from 'api/staff';
import {
  getAttendNoticeSetting, postAttendNoticeSetting, getBonusPayslipPdf, downloadOriginalPDF, getBonusPayslipPdfError, postPhoto,
} from 'api/personalSetting';
import { useFormik } from 'formik';
import moment from 'moment';
import useToastNotification from 'hooks/useToastNotification';
import { useDispatch } from 'react-redux';
import { uiAppBarActions } from 'modules/ui/appBar';

export const PersonalSettingForm = (
  croppedImage: any,
  resetForm: any,
  showCroppedImage: any,
  setCroppedImage: any,
  setChangeImage: any,
  setBeforeCroppedImage: any,
) => {
  // redux
  const dispatch = useDispatch();
  
  const [isLoading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [photoPath, setPhotoPath] = useState(sessionStorage.getItem('loginUser.photoPath') || "");
  const [initValueFormik, setInitValueFormik] = useState<any>(null);
  const onSubmit = async (values: PersonalSettingApplicationDomain) => {
    if (!confirmApplyModalOpen) {
      setConfirmApplyModalOpen(true);
      return;
    }

    const {
      attendNoticeSetting, staffCode, companyCode,
    } = values.getRawData();

    const dataPost = {
      id: {
        staffCode,
        companyCode,
      },
      notifyBeforeShiftStart: attendNoticeSetting.notifyBeforeShiftStart ? '1' : '0',
      minutesBeforeShiftStart: String(attendNoticeSetting.minutesBeforeShiftStart) === '0' ? '30' : attendNoticeSetting.minutesBeforeShiftStart,
      notifyToStampForgot: attendNoticeSetting.notifyToStampForgot ? '1' : '0',
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
    };

    try {
      setLoading(true);
      setConfirmApplyModalOpen(false);
      const response = await postAttendNoticeSetting(dataPost);
      if (!response.errors) {
        handlePostPhoto(croppedImage);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmApplyModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    } finally{
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: PersonalSettingApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  useEffect(() => {
    setLoading(true);
    getAttendNoticeSetting().then((setting: any) => {
      getLoginStaff().then((staff: any) => {
        formik.setFieldValue('staffName', staff.staffName);
        formik.setFieldValue('orgName', staff.orgName);
        formik.setFieldValue('staffCode', staff.id.staffCode);
        formik.setFieldValue('mail', staff.mail);
        formik.setFieldValue('companyCode', staff.id.companyCode);
        formik.setFieldValue('attendNoticeSetting', setting);
        formik.setFieldValue('personalRole', staff.personalRole);
        formik.setFieldValue('shopRole', staff.shopRole);
        formik.setFieldValue('hqRole', staff.hqRole);

        setLoading(false);
      }).catch(() => {
        errorNotification('サーバー側でエラーが発生しました。');
      }).finally(() => {
        let tmpFormik = {...formik.values.getRawData()};
        setInitValueFormik(tmpFormik);
      });
    }).catch(() => {
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrintPdf = useCallback(() => {
    const { formatType, paymentTarget } = formik.values;

    let fileName = '';
    if (formatType === '0') {
      fileName = 'salary_payslip.pdf';
    } else {
      fileName = 'bonus_payslip.pdf';
    }

    const params = {
      formatType,
      paymentTarget: moment(paymentTarget).format('YYYY/MM'),
    };

    setLoading(true);
    getBonusPayslipPdf(params).then((response: any) => {
      downloadOriginalPDF(response, fileName);
      setLoading(false);
    }).catch((e) => {
      getBonusPayslipPdfError(params).then((response: any) => {
        setLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      }).catch((error) => {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
          errorNotification(stringErr);
        } else if (error.response && error.response.data && error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      });
    });
  }, [errorNotification, formik.values]);
  
  const handlePostPhoto = useCallback(async (url) => {
    
    let blob = null; 
    if(url){
      blob = await fetch(url).then(r => {
        
        if(r.ok === false){
          console.log('dasdasdsa')
          return null;
        }
        return r.blob()
      });
    }

    const dataPost = {
      id: {
        staffCode: formik.values.staffCode,
      },
      nameplate: '1.jpg',
      personalRole: formik.values.personalRole,
      shopRole: formik.values.shopRole,
      hqRole: formik.values.hqRole,
      createUser: sessionStorage.getItem('loginUser.staffName') || '',
      updateUser: sessionStorage.getItem('loginUser.staffName') || '',
      photo1: blob,
      useSupportStaff: false,
    };

    setLoading(true);
    postPhoto(dataPost).then((response: any) => {
      successNotification('更新しました。');
      setLoading(false);
      const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL;
      const path = `${domainUrl
      }/${sessionStorage.getItem('loginUser.companyCode')
      }/${sessionStorage.getItem('loginUser.staffCode')
      }/1.jpg?${moment.now()}`;
      sessionStorage.setItem('loginUser.photoPath', path);
      setPhotoPath(path);
      setCroppedImage(path);
      setBeforeCroppedImage(path);
      setChangeImage(false);
      dispatch(uiAppBarActions.changeAvatar(path));
    }).catch((error) => {
      setLoading(false);
      if (error.response.status === 400) {
        if (error.response.data.message) {
          errorNotification(error.response.data.message);
        } else if (error.response.data.errors) {
          let msgErrorName = '';
          error.response.data.errors.map((item: { defaultMessage: string; }) => {
            msgErrorName += `${item.defaultMessage}<br />`;
            return msgErrorName;
          });
          errorNotification(msgErrorName);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    });
  }, [errorNotification, formik.values.hqRole, formik.values.personalRole, formik.values.shopRole, formik.values.staffCode, successNotification]);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  return {
    formik,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    handlePrintPdf,
    successNotification,
    errorNotification,
    setLoading,
    handlePostPhoto,
    photoPath,
    initValueFormik,
  };
};

export default PersonalSettingForm;
