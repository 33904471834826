/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import VerticalLabelMutilSelectForm from 'components/molecules/VerticalLabelMutilSelectForm';
import FlexBox from 'components/atoms/FlexBox';
import DatePickerForm from 'components/molecules/DatePickerForm';
import { useOrgTreesByCategoryNotAllOptions } from 'hooks/useOrgTreesOptions';
import moment from 'moment';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import FormLabel from 'components/atoms/Form/FormLabel';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import useToastNotification from 'hooks/useToastNotification';
import BlockUI from 'components/molecules/BlockUi';
import useMonthlyReport, { useGetListMonthlyReport } from './hooks';
import MonthlySalesManagementTable from './MonthlySalesManagementTable';
import MonthlyForeCastManagemenTable from './MonthlyForeCastManagemenTable';

const MonthlyReportLayout = () => {
  const fromdate = new Date();
  const datefrom = new Date(fromdate.getFullYear(), fromdate.getMonth(), 1); // get first date
  const dateTo = (new Date(fromdate.getFullYear(), fromdate.getMonth() + 1, 0, 23, 59, 59)); // get last date

  const [targetDateFrom, setTargetDateFrom] = useState(datefrom);
  const [targetDateTo, setTargetDateTo] = useState(dateTo);

  const st = new Date();
  st.setMonth(0);
  st.setDate(1);
  const ed = new Date(st);
  if (!targetDateFrom) {
    setTargetDateFrom(st);
  }

  if (!targetDateTo) {
    setTargetDateTo(ed);
  }

  console.log(ed);

  const [isShowContentAction, setIsShowContentAction] = useState(false);
  const orgTreesOptions = useOrgTreesByCategoryNotAllOptions(
    moment(targetDateFrom).format('YYYY-MM-DD'),
    moment(targetDateTo).format('YYYY-MM-DD'),
  );

  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('loginUser.orgCode') ? sessionStorage.getItem('loginUser.orgCode') : '');
  const { monthlyReport, getBudgetPerfomanceManagement, getIsLoading } = useMonthlyReport(String(orgCode), moment(targetDateFrom).format('YYYY/MM/DD'), moment(targetDateTo).format('YYYY/MM/DD'));

  const targetPeriodData = `対象期間：${moment(targetDateFrom).format('YYYY/MM/DD')}~${moment(targetDateTo).format('YYYY/MM/DD')}`;

  const orgLabel:any[] = [];
  orgTreesOptions.filter((data:any) => {
    const orgLoop = orgCode?.split(',');
    if (orgLoop) {
      orgLoop.map((orgItem:any) => {
        if (orgItem === data.value) {
          orgLabel.push(data.label.split(' ')[1]);
        }
      });
    }
  });

  const {
    action,
    setAction,
    ActionType,
  } = useGetListMonthlyReport();

  const { errorNotification } = useToastNotification();

  const getFetchOption = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      return false;
    }
    setAction(ActionType.getFetchOption);
    setIsShowContentAction(true);
  };

  const getFetchOptionforeCast = () => {
    if (!orgCode || orgTreesOptions.length === 0) {
      errorNotification('組織名を選択してください。');
      return false;
    }
    setAction(ActionType.getFetchOptionforeCast);
    setIsShowContentAction(true);
  };

  return (
    <div>
      <FormContents>
        <div>
          <FlexBox>
            <FlexBoxItem width="100px">
              <FormLabel
                label="対象期間"
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateFrom}
                changeDate={(date: Date) => { setTargetDateFrom(date); }}
                isFullWidth={true}
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <span style={{ margin: '0 30px' }}>～</span>
            </FlexBoxItem>
            <FlexBoxItem>
              <DatePickerForm
                dateFormat="yyyy年MM月dd日"
                label=""
                date={targetDateTo}
                changeDate={(date: Date) => { setTargetDateTo(date); }}
                isFullWidth={true}
              />
            </FlexBoxItem>
          </FlexBox>
        </div>
        <FlexBox>
          <FlexBoxItem width="107px">
            <FormLabel
              label="組織名"
            />
          </FlexBoxItem>
          <FlexBoxItem width="100%">
            <VerticalLabelMutilSelectForm
              label=""
              name="orgCode"
              values={orgTreesOptions.filter((option) => {
                if (orgCode?.split(',').includes(option.value)) {
                  return option;
                }
              })}
              setValue={(val: any) => {
                setOrgCode(val);
              }}
              options={orgTreesOptions}
              isMulti={true}
            />
          </FlexBoxItem>
        </FlexBox>

        <FormField>
          <FormSubmitArea>
            <div style={{ display: 'flex', marginTop: '-15px' }}>
              <div style={{ marginRight: '12px' }}>
                <PrimaryButton
                  text="売上管理表"
                  onClick={getFetchOption}
                />
              </div>
              <PrimaryButton
                text="予実管理表"
                onClick={getFetchOptionforeCast}
              />
            </div>
          </FormSubmitArea>
        </FormField>

        <div>
          {isShowContentAction && action === ActionType.getFetchOption
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlySalesManagementTable
                   monthlyReport={monthlyReport}
                   targetPeriodData={targetPeriodData}
                   orgLabel={orgLabel}
                 />
               </BlockUI>
             </div>
           </div>
           )}

          {isShowContentAction && action === ActionType.getFetchOptionforeCast
           && (
           <div style={{ marginTop: '30px' }}>
             <div className="row">
               <BlockUI blocking={getIsLoading}>
                 <MonthlyForeCastManagemenTable
                   getBudgetPerfomanceManagement={getBudgetPerfomanceManagement}
                   targetPeriodData={targetPeriodData}
                   orgLabel={orgLabel}
                 />
               </BlockUI>
             </div>
           </div>
           )}

        </div>

      </FormContents>
    </div>
  );
};

export default MonthlyReportLayout;
