/* eslint-disable func-names */
/** @jsx jsx */
import React, { useRef, useEffect, createContext, useState, useCallback } from "react";
import FormContents from "components/atoms/Form/FormContents";
import SelectForm from "components/molecules/SelectForm";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { css, jsx } from "@emotion/core";
import FileSelectButton from "components/molecules/FileSelectButton";
import FaceRegisterList from "components/molecules/FaceRegisterList";
import FaceRegisterBox from "components/molecules/FaceRegisterList/FaceRegisterBox";
import PrimaryButton, { RecorderButton, SecondaryButton } from "components/atoms/Button";
import Webcam from "react-webcam";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from "components/atoms/FlexBox";
import Modal from "components/molecules/Modal";
import { useMasterShopFaceRegistrationFormV1 } from "./hooks";
import { textFontSize, grayScale } from "components/styles";
import BodyText from "components/atoms/BodyText";
import FormTitle from "components/atoms/Form/FormTitle";
import Cropper from 'react-easy-crop'
import getCroppedImg from 'hooks/useCropImage';
import {
  Container, Row, Col,
} from 'react-grid-system';
import { isMobileOnly, isIPad13 } from 'react-device-detect';


const styles = {
  formContent: {
    padding: "0 30px 30px 30px",
    marginBottom: '30px'
  },
  noteImage: css({
    fontSize: "12px",
    color: "#ED5D5D",
    marginBottom: "15px",
    lineHeight: "22px",
  }),
};

const videoConstraints = {
  width: 400,
  height: 250,
  facingMode: "user",
};

type contextType = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoadingContext = createContext<contextType>({} as contextType);

const MasterShopFaceRegistrationFormV1: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const {
    orgCode,
    setOrgCode,
    orgTreesOptions,
    staffCode,
    setStaffCode,
    staffsForOrgCodeOptions,
    isLoading,
    setIsLoading,
    handleSubmit,
    state,
    dispatch,
    initFaceList,
    detailRole
  } = useMasterShopFaceRegistrationFormV1();

  return (
    <div>
      <LoadingContext.Provider value={{setIsLoading}}> 
        <BlockUI blocking={isLoading}>
          <FormContents customStyle={styles.formContent}>
            <FormTitle bold={true} title="顔写真登録" customStyle={css({ marginLeft: '0' })} />
            <FlexBox customStyle={css({
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              paddingRight: '40px'
            })}>
              <FlexBoxItem width="400px">
                <div css={css({ width: "400px", marginBottom: '10px' })}>
                  <SelectForm
                    label="対象組織"
                    customStyleContainer={css({padding: '0'})}
                    name="orgCode"
                    value={orgCode}
                    setValue={setOrgCode}
                    options={orgTreesOptions}
                    
                  />
                </div>
                <FlexBoxItem>
                  <FlexBoxItem>
                    <div css={css({ width: "400px" })}>
                      <SelectForm
                        label="スタッフ"
                        name="staffCode"
                        placeholder="スタッフを選択"
                        value={staffCode}
                        setValue={setStaffCode}
                        options={staffsForOrgCodeOptions}
                      />
                    </div>
                  </FlexBoxItem>
                </FlexBoxItem>
              </FlexBoxItem>
              <FlexBoxItem>
                <FaceRegisterBox
                  dispatch={dispatch}
                  face={state.listFace[0]}
                  index={0}
                  key={0}
                  hiddenDescription={true}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <BodyText customStyle={styles.noteImage}>
                  ※明るく鮮明な写真をご用意ください<br/>
                  ※ファイル形式：jpg,jpeg,png
                </BodyText>
              </FlexBoxItem>
            </FlexBox>
            <FaceRegisterList
              setIsLoading={setIsLoading}
              dispatch={dispatch}
              state={state}
            />
            {detailRole.editable === 1 && (
              <FlexBox justifyContent="center" customStyle={css({margin: '30px 0 20px 0'})}>
                <PrimaryButton  
                  text="更新"
                  disabled={JSON.stringify(state.listFace) === JSON.stringify(initFaceList)}
                  onClick={() => setOpenModal(true)}
                  // disabled={!checkChangeImage(croppedImage)}
                />
              </FlexBox>
            )}
          </FormContents>
          <Modal
            open={openModal}
            closeHandler={() => setOpenModal(false)}
            title="顔写真の更新"
            submitText="更新する"
            closeText="キャンセル"
            onSubmit={() => { 
              setOpenModal(false);
              handleSubmit();
            }}
            note="顔写真を更新します。よろしいですか？"
          ></Modal>
        </BlockUI>
      </LoadingContext.Provider>
    </div>
  );
};

export default MasterShopFaceRegistrationFormV1;
