/**
 * APIのデータ型
 */
export interface AttendanceMaster {
  ip: string;
  stampIpId: string;
  ipAddress: string;
  subnetMask: number;
  stampIPDetail: StampIPDetail[];
}

export interface StampIPDetail {
  orgCode: string;
  orgName: string;
  checkAll?: boolean;
}

export default class AttendanceMasterDomain {
  constructor(private rawData: AttendanceMaster) {
    // do nothing
  }

  static generateInitial(): AttendanceMasterDomain {
    return new AttendanceMasterDomain({
      ip: "",
      stampIpId: "",
      ipAddress: "",
      subnetMask: 0,
      stampIPDetail: [],
    });
  }

  getRawData(): AttendanceMaster {
    return this.rawData;
  }

  set ip(ip: string) {
    this.rawData.ip = ip;
  }

  get ip(): string {
    return this.rawData.ip;
  }

  set stampIpId(stampIpId: string) {
    this.rawData.stampIpId = stampIpId;
  }

  get stampIpId(): string {
    return this.rawData.stampIpId;
  }

  set ipAddress(ipAddress: string) {
    this.rawData.ipAddress = String(ipAddress);
  }

  get ipAddress(): string {
    return this.rawData.ipAddress;
  }

  set subnetMask(subnetMask: number) {
    this.rawData.subnetMask = Number(subnetMask);
  }

  get subnetMask(): number {
    return this.rawData.subnetMask;
  }

  get functionCodeList(): Array<StampIPDetail> {
    return this.rawData.stampIPDetail;
  }

  set stampIPDetail(stampIPDetail: Array<StampIPDetail>) {
    this.rawData.stampIPDetail = stampIPDetail;
  }

  get orgNameList(): string {
    let name = "";
    this.rawData.stampIPDetail.map((item) => {
      name += `${item.orgName}, `;
      return name;
    });
    name = name.substring(0, name.length - 2);
    return name;
  }

  get lengthOrgList (): number {
    return this.rawData.stampIPDetail.length;
  }
}
