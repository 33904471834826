import React from 'react';
import { Row, Col } from 'react-grid-system';
import moment from 'moment';

import Modal from 'components/molecules/Modal';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';

import { useBusinessAttendChoiceOptions } from 'hooks/useBusinessMasterOptions';
import { useAllOrgTrees } from 'hooks/useOrgTreesOptions';
import { useShiftDayCalendarContext, useShiftAddPanel } from './hooks';

const ShiftAddPanel: React.FC = () => {
  const {
    orgCode, date, shiftAddPanelState, hideShiftAddPanelAction,
  } = useShiftDayCalendarContext();
  const {
    state, setter, actions, options,
  } = useShiftAddPanel();

  // 組織一覧
  const orgOptions = useAllOrgTrees();
  // 業務オプション一覧
  const businessMasterOptions = useBusinessAttendChoiceOptions(
    shiftAddPanelState.staff?.staffCode || state.staffCode, moment(date).format('YYYY/MM/DD'),
  );

  return (
    <Modal
      open={true}
      title="シフト追加"
      closeHandler={hideShiftAddPanelAction}
      submitText="実行"
      onSubmit={actions.addShiftAction}
      
      width="800px"
    >
      <Row align="center">
        <Col md={12}>
          <BodyText>
            対象日
          </BodyText>
        </Col>
        <Col md={12}>
          <BodyText size="md">
            {moment(shiftAddPanelState.defaultStartDate).format('YYYY/MM/DD')}
          </BodyText>
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText>
            組織
          </BodyText>
        </Col>
        <Col md={12}>
          <BodyText size="md">
            {shiftAddPanelState.staff?.staffName ? (
              <BodyText>
                {orgOptions.find((orgOption) => orgOption.value === orgCode)?.label}
              </BodyText>
            ) : (
              <Select
                name="organizationOptions"
                value={state.targetOrgCode}
                setValue={setter.setTargetOrgCode}
                options={orgOptions}
              />
            )}
          </BodyText>
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText>
            対象者
          </BodyText>
        </Col>
        <Col md={12}>
          <BodyText size="md">
            {shiftAddPanelState.staff?.staffName || (
              <Select
                name="staffOptions"
                value={state.staffCode}
                setValue={setter.setStaffCode}
                options={options.shiftDailyStaffOptions}
              />
            )}
          </BodyText>
        </Col>
      </Row>

      {shiftAddPanelState.staff?.staffName ? null : (
        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              勤務日種別
            </BodyText>
          </Col>
          <Col md={12}>
            <Select
              name="shiftEditAttendType"
              value={state.attendType}
              setValue={setter.setAttendType}
              options={options.attendTypeOptions}
            />
          </Col>
        </Row>
      )}

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText>
            シフトパターン
          </BodyText>
        </Col>
        <Col md={12}>
          <Select
            name="shiftAddBusinessMasterOptions"
            value={state.shiftPatternId}
            setValue={(val) => setter.setShiftPatternId(val)}
            options={options.shiftPatternOptions}
            isDisabled={state.attendType !== '0'/* 勤務日種別が0:通常の場合のみ */}
          />
        </Col>
      </Row>

      <Row align="center" style={{ marginTop: '8px' }}>
        <Col md={12}>
          <BodyText size="sm">
            シフト時間
          </BodyText>
        </Col>
        <Col md={12}>
          <ShiftPatternDetails
            shiftPatternDetailsList={state.shiftDetailsList}
            setShiftPatternDetailsList={setter.setShiftDetailsList}
            attendBusinessOptions={businessMasterOptions}
            listTimes={['00', '15', '30', '45']}
            isDisableAll={!!state.shiftPatternId || state.isPublicHoliday}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ShiftAddPanel;
