import React from 'react';
import Button from '../Header/Button';
import ButtonArrow from '../Header/ButtonArrow';
import CurrentYear from '../Header/CurrentYear';
import CurrentYearv1 from '../Header/CurrentYearv1';
import JumpButton from '../Header/JumpButton';
import YearsList from '../Body/yearsList';

const MAX_VALUE = 2099;
const MIN_VALUE = 1900;


const validateYear = (year) => { 
  return   Number(year) >= MIN_VALUE 
        && Number(year) <= MAX_VALUE
}

function PickerPanel({
  selectedYear,
  isOpen,
  increaseYear,
  decreaseYear,
  jumpForward,
  jumpBackward,
  thisYear,
  choiseYear,
  optionsYear,
  top,
  left,
  onClose
}) {
  if (!isOpen) {
    return null;
  }

  const style = {
    top: `42px`,
    left: `0px`,
  };

  return (
    <div className="picker-panel popup-left" style={style}>
      <div className="header">
        {/* <JumpButton onClick={jumpBackward} direction="backward" /> */}
        {/* <Button onClick={decreaseYear} direction="backward" /> */}
        <ButtonArrow onClick={() => {
          if(validateYear(selectedYear - 1)){
            decreaseYear();
          }
        }} direction="backward" />
        <CurrentYearv1 year={selectedYear} choiseYear={choiseYear}/>
        <ButtonArrow onClick={() => {
          if(validateYear(selectedYear + 1)){
            increaseYear();
          }
        }} direction="forward" />
        {/* <Button onClick={increaseYear} direction="forward" /> */}
        {/* <JumpButton onClick={jumpForward} direction="forward" /> */}
      </div>
      <div className="body">
        <YearsList choiseYear={choiseYear} selectedYear={selectedYear} />
      </div>
      <div className="label-current" onClick={() => {
        choiseYear(new Date().getFullYear())
        onClose();
      }}>今年</div>
    </div>
  );
}

export default PickerPanel;
