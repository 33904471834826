/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Row, Col } from 'react-grid-system';
import moment from 'moment';

import Modal from 'components/molecules/Modal';
import BodyText from 'components/atoms/BodyText';
import Select from 'components/atoms/Select';
import ShiftPatternDetails from 'components/organismos/master/labor/shiftPattern/ShiftPatternDetails';

import { useBusinessAttendChoiceOptions } from 'hooks/useBusinessMasterOptions';
import { useShiftDayCalendarContext, useShiftEditPanel } from './hooks';

const ShiftEditPanel: React.FC = () => {
  const {
    date, shiftEditPanelState, hideShiftEditPanelAction,
  } = useShiftDayCalendarContext();
  const {
    state, setter, actions, options,
  } = useShiftEditPanel(shiftEditPanelState.shiftId);

  // 業務内容のオプションリスト
  const businessMasterOptions = useBusinessAttendChoiceOptions(state.staffCode, moment(date).format('YYYY/MM/DD'));

  return (
    <React.Fragment>
      <Modal
        open={true}
        title="シフト編集"
        closeHandler={hideShiftEditPanelAction}
        submitText="編集"
        onSubmit={actions.editShiftAction}
        width="800px"
      >
        <Row align="center">
          <Col md={12}>
            <BodyText size="sm">
              対象日
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              {state.targetDate}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              スタッフコード
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              {state.staffCode}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              対象者
            </BodyText>
          </Col>
          <Col md={12}>
            <BodyText size="md">
              {state.staffName}
            </BodyText>
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              勤務日種別
            </BodyText>
          </Col>
          <Col md={12}>
            <Select
              name="shiftEditAttendType"
              value={state.attendType}
              setValue={setter.setAttendType}
              options={options.attendTypeOptions}
            />
          </Col>
        </Row>

        <Row align="center" css={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              出勤組織
            </BodyText>
          </Col>
          <Col md={12}>
            {state.orgName}
          </Col>
        </Row>

        <Row align="center" style={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              シフトパターン
            </BodyText>
          </Col>
          <Col md={12}>
            <Select
              name="shiftEditBusinessMasterOptions"
              value={state.shiftPatternId}
              setValue={(val) => setter.setShiftPatternId(val)}
              options={options.shiftPatternOptions}
              isDisabled={state.attendType !== '0'/* 勤務日種別が0:通常の場合のみ */}
            />
          </Col>
        </Row>

        {/* シフト時間 */}
        <Row align="center" style={{ marginTop: '8px' }}>
          <Col md={12}>
            <BodyText size="sm">
              シフト時間
            </BodyText>
          </Col>
          <Col md={12}>
            <ShiftPatternDetails
              shiftPatternDetailsList={state.shiftDetailList}
              setShiftPatternDetailsList={setter.setShiftDetailList}
              attendBusinessOptions={businessMasterOptions}
              listTimes={['00', '15', '30', '45']}
              isDisableAll={!!state.shiftPatternId || state.isPublicHoliday}
            />
          </Col>
        </Row>
      </Modal>

      {/* 削除モーダル */}
      <Modal
        open={state.showDeleteConfirm}
        title="シフト削除"
        closeHandler={() => setter.setDeleteModalShowFlag(false)}
        submitText="削除"
        onSubmit={actions.deleteShiftAction}
        closeText="キャンセル"
        width="800px"
      >
        削除してよろしいですか
      </Modal>
    </React.Fragment>
  );
};

export default ShiftEditPanel;
