/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { grayScale, textFontSize } from "components/styles";

const style = css(
  {
    border: `1px solid ${grayScale.gray20}`,
    boxSizing: "border-box",
    borderRadius: "3px",
    width: "100%",
    fontSize: textFontSize.re,
    padding: "11px",
    marginTop: "4px",
    color: "#333333",
  },
  css`
    ::placeholder {
      color: #999999;
    }
  `
);

const PasswordInput: React.FC<{
  name: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeHolder?: string;
}> = ({ name, value, onChange, placeHolder = "" }) => (
  <input
    css={style}
    id={name}
    name={name}
    type="password"
    value={value}
    onChange={onChange}
    placeholder={placeHolder}
  />
);

export default PasswordInput;
