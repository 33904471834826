/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx} from '@emotion/core';
import Modal from "components/molecules/Modal";
import SelectPortal from 'components/atoms/SelectPortal';
import useToastNotification from 'hooks/useToastNotification';
import { ConcurrentType } from 'domain/staff';
import FormLabel from 'components/atoms/Form/FormLabel';

const styles = {
  modal: css({
    maxWidth: '500px',
  }, css`
    table tr td{
      cursor:pointer;
    }
  `),
  column: css({
    width: '22px'
  }),
  formInput: css({
    marginBottom: '30px'
  })
}

const ConcurrentModal: React.FC<{
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  organizationOptions: Array<any>,
  executiveOptions: Array<any>,
  onSubmit: (concurrent: ConcurrentType) => void
}> = ({ openModal, setOpenModal, organizationOptions, executiveOptions, onSubmit }) => {

  const [orgCode, setOrgCode] = useState(organizationOptions.length > 0 ? organizationOptions[0].value : '');
  const [executiveId, setExecutiveId] = useState(executiveOptions.length > 0 ? executiveOptions[0].value : '');
  const { successNotification, errorNotification } = useToastNotification();

  useEffect(() => {
    if (organizationOptions.length > 0) {
      setOrgCode(organizationOptions[0].value);
    }
  }, [organizationOptions])

  const handleSubmitAddConcurrent = () => {
    console.log('orgCode', orgCode)
    if(!orgCode) {
      errorNotification('Org empty');
      return;
    }
    setOpenModal(false);
    const concurrent:ConcurrentType = {
      staffConcurrentId: '',
      staffCode: '',
      staffName: '',
      staffOrgCode: '',
      staffOrgName: '',
      orgCode,
      orgName: organizationOptions?.find((item) => item.value === orgCode).label,
      executiveId: executiveId,
      executiveCode: '',
      executiveName: executiveOptions?.find((item) => item.value === executiveId).label,
      applyStartDate: new Date(),
      applyEndDate: undefined,
    }

    onSubmit(concurrent);
  }
  return (
    <Modal
      open={openModal}
      closeHandler={() => setOpenModal(false)}
      title="兼務設定"
      submitText="追加"
      closeText="キャンセル"
      onSubmit={() => handleSubmitAddConcurrent()}
      width="550px"
      customStyle={css({padding: '20px 50px 0 50px'})}
    >
      <div css={styles.formInput}>
        <div>
          <FormLabel label="組織" required={true}/>
        </div>
        <SelectPortal
          name="concurrentOrganization"
          value={orgCode}
          setValue={(v) => {
            setOrgCode(v);
          }}
          options={organizationOptions}
        />
      </div>
      <div css={styles.formInput}>
        <div>
          <FormLabel label="役職"/>
        </div>
        <SelectPortal
          name="concurrentExecutiveId"
          value={String(executiveId)}
          setValue={(v) => {
            setExecutiveId(v);
          }}
          options={executiveOptions}
        />
      </div>
    </Modal>
  );
};

export default ConcurrentModal;
