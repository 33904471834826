/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/** @jsx jsx */
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { SubActionButton } from "components/atoms/Button";
import ToastModal from "components/organismos/ToastModal";
import DataTable from "components/organismos/DataTable/DataTable";
import TimeOutputLayoutDomain from "domain/master/attend/timeOutputLayout";
import ConfirmModal from "components/organismos/ConfirmModal";
import {
  getOutputLayoutList,
  deleteAttendStampIPById,
} from "api/timeOutputLayout";
import useToastNotification from "hooks/useToastNotification";
import FormContents from "components/atoms/Form/FormContents";
import IconLabelButton from "components/molecules/IconLabelButton";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

const styles = {
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
};

const TimeOutputLayoutList = () => {
  const [timeOutputLayoutList, setTimeOutputLayoutList] = useState<
    Array<TimeOutputLayoutDomain>
  >([]);

  /**
   * useEffect
   *
   */
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [selectedTimeOutputLayout, setSelectedTimeOutputLayout] = useState(
    TimeOutputLayoutDomain.generateInitial()
  );
  const { successNotification, errorNotification } = useToastNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(
    setIsLoading,
    functionCode.masterTimeOutputLayout
  );

  /**
   * useEffect
   *
   */
  useEffect(() => {
    setIsLoading(true);
    getOutputLayoutList().then((response: Array<any>) => {
      setIsLoading(false);
      setTimeOutputLayoutList(
        response.map((result) => new TimeOutputLayoutDomain(result))
      );
    });
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const openConfirmModal = useCallback(
    (timeOutputLayout: TimeOutputLayoutDomain) => {
      setConfirmModalOpen(true);
      setSelectedTimeOutputLayout(timeOutputLayout);
    },
    []
  );

  /**
   * deleteTimeOutputLayoutMaster
   *
   * @param layoutId
   */
  const deleteTimeOutputLayoutMaster = useCallback(
    () => {
      deleteAttendStampIPById(selectedTimeOutputLayout.layoutId)
        .then((response: any) => {
          setTimeOutputLayoutList(
            timeOutputLayoutList.filter(
              (timeOutputLayout) =>
                timeOutputLayout.layoutId !== selectedTimeOutputLayout.layoutId
            )
          );
          setConfirmModalOpen(false);
          successNotification("削除しました。"); //success
        })
        .catch((error) => {
          setConfirmModalOpen(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const listErr = error.response.data.errors;
            let stringErr = "";
            listErr.forEach((element: any) => {
              stringErr += `${element.defaultMessage}<br />`;
            });
            errorNotification(stringErr);
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
            throw error;
          }
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTimeOutputLayout.layoutId, timeOutputLayoutList]
  );

  /**
   *
   *
   */
  const columns: Array<Column<TimeOutputLayoutDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; original: TimeOutputLayoutDomain };
          data: Array<TimeOutputLayoutDomain>;
        }) => {
          return (
            <div
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: "130px",
              }}
            >
              <Link
                to={`/masterTimeOutputLayout/edit/${cell.row.original.layoutId}`}
              >
                <SubActionButton
                  action={0}
                  text={detailRole.editable === 1 ? `編集` : `参照`}
                  onClick={() => console.log(1)}
                />
              </Link>
              {detailRole.editable === 1 ? (
                <span
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    openConfirmModal(cell.row.original);
                  }}
                >
                  <SubActionButton
                    action={1}
                    text="削除"
                    onClick={() => console.log(1)}
                  />
                </span>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: "レイアウトコード",
        accessor: "layoutCode",
      },
      {
        Header: "レイアウト名",
        accessor: "layoutName",
      },
    ],
    [openConfirmModal, detailRole]
  );

  const customStyle = {
    marginBottom: "30px",
    borderRadius: "5px",
    // padding: "0 30px",
  };

  return (
    <BlockUI blocking={isLoading}>
      <React.Fragment>
        <div>
          <FormContents customStyle={customStyle}>
            <FormTitle bold={true} title="勤怠確定出力レイアウト" />
            {detailRole.editable === 1 && (
              <div
                style={{
                  marginLeft: "27px",
                  width: "80px",
                  paddingBottom: "30px",
                }}
              >
                <Link
                  className="button-action"
                  to="/masterTimeOutputLayout/add"
                  style={{
                    textDecoration: "none",
                    color: "#007BC3",
                    display: "inline-block",
                  }}
                >
                  <IconLabelButton
                    onClick={() => console.log("edit")}
                    iconType="addCircle"
                    text="追加"
                  />
                </Link>
              </div>
            )}
            <div
              css={css`
                table {
                  table-layout: auto;
                }
                table th:nth-of-type(1) {
                  width: 80px;
                }
                table th:nth-of-type(2) {
                  width: 140px;
                  text-align: left;
                }
                table th:nth-of-type(3) {
                  width: calc(100% - 280px);
                  text-align: left;
                }
              `}
            >
              <div className="table-bordered">
                <DataTable
                  columns={columns}
                  data={timeOutputLayoutList}
                  isGlobalFilter={true}
                  globalSearchStyle={styles.filterSearch}
                  containerStyle={css({
                    overflowX: "auto",
                  })}
                />
              </div>
            </div>
            <ConfirmModal
              open={confirmModalOpen}
              closeHandler={closeConfirmModal}
              onSubmit={deleteTimeOutputLayoutMaster}
              title="出力レイアウトの削除"
              content="勤務確定出力レイアウトを削除します。よろしいですか？"
              submitText="削除する"
              items={[]}
              typeSubmit={`reject`}
            />
            <ToastModal
              open={toastModalOpen}
              closeHandler={() => setToastModalOpen(false)}
              title={toastMessage}
            />
          </FormContents>
        </div>
      </React.Fragment>
    </BlockUI>
  );
};

export default TimeOutputLayoutList;
