/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  useState, useCallback, useEffect, useContext, Dispatch 
} from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import useToastNotification from 'hooks/useToastNotification';
import StaffDomain from 'domain/staff';
import { postAffiliateInfo, getOrganizationDataList } from 'api/staff';
import SelfOrganizationDomain from 'domain/master/general/selfOrganization';
import { ManagerDataStaffDomain } from 'domain/master/general/managerDataStaff';
import moment from 'moment';
import { LoadingContextAdd } from 'components/pages/master/general/staff/StaffAddPage/hooks'
// import { LoadingContextAdd } from 'components/pages/master/general/staff/StaffAddPage/hooks'
import { getTreesOrgSelectRange } from 'api/organization';
import { functionCode } from 'const';
import { OptionType } from 'components/atoms/Select';

type StaffDomainKey = keyof Pick<StaffDomain, 'orgApplyDate'|'orgCode'>;
const StaffDomainSchema: {[key in StaffDomainKey]: any} = {
  orgCode: Yup.string().required('組織を入力してください。'),
  orgApplyDate: Yup.string().required('所属情報の異動日を入力してください。'),
};
const schema = Yup.object({ ...StaffDomainSchema });

export const useAffiliationForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setInitialStaffDomain: any,
  setIsLoading?: Dispatch<React.SetStateAction<any>>,
) => {
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const [openManagerAddModal, setOpenManagerAddModal] = useState<boolean>(false);
  const [openConcurrentAddModal, setOpenConcurrentAddModal] = useState<boolean>(false);
  const contextObject = useContext(LoadingContextAdd);
  const [orgTreeOptions, setOrgTreeOptions] = useState<Array<OptionType>>([]);
  const [modalOrgTreeOptions, setModalOrgTreeOptions] = useState<Array<OptionType>>([]);
  
  // const contextObject = useContext(isEditMode ? LoadingContext : LoadingContextAdd)
  // const contextObject = useContext(LoadingContextAdd);
  const onSubmit = async (values: StaffDomain) => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      setConfirmModalOpen(false);
      setIsLoading!(true);
      let postData = values.getStaffInfoPostData(isEditMode);
      // if(!(postData.shopRole && !postData.hqRole) && postData.managerList.length > 0){
      //   errorNotification('組織管理の権限が設定されていません。マネージャー権限の設定には、スタッフ情報で組織管理の権限設定をしてください。');
      //   setIsLoading!(false);
      //   return;
      // }
      const response = await postAffiliateInfo(isEditMode ? 1 : 0, values.getStaffInfoPostData(isEditMode));

      const updatedStaff = new StaffDomain(response.staff);
      successNotification(isEditMode ? '更新しました。' : '登録しました。');
      organizationDataList(postData.id?.staffCode);
      sessionStorage.setItem('selectedOrgCodeStaffMaster', updatedStaff.orgCode);
      setIsLoading!(false);
      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading!(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  };

const organizationDataList = async (staffCode: string) =>{
  await getOrganizationDataList(staffCode).then(res=>{
    formik.setFieldValue('organizationDataList', res.organizationDataList);
    formik.setFieldValue('weekShiftPatternList', res.weekShiftPatternList);
  }).catch(err=>{
    if (err.response && err.response.data && err.response.data.errors) {
      err.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
    } else {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  })
}

  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
    validateOnChange: false,
    validate: async (values) => {
      const errors: {[key: string]: string} = {};
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const validationError = error as Yup.ValidationError;
        validationError.inner.forEach((innerError) => {
          errors[innerError.path] = innerError.message;
        });
      }
      return errors;
    },
  });

  const onLoadTreesOrg = (orgApplyDate: Date | undefined) => {
    if(orgApplyDate){
      getTreesOrgSelectRange(false, functionCode.staffs, moment(orgApplyDate).format('YYYY-MM-DD'), moment(orgApplyDate).format('YYYY-MM-DD')).then((trees) => {
        const treesOrg = trees.map((tree) => ({
          value: tree.orgCode,
          label: `${tree.orgName}`,
        }));
        setOrgTreeOptions(treesOrg);

        if (!treesOrg.map((item) => item.value).includes(formik.values.orgCode) && formik.values.orgCode) {
          formik.setFieldValue('orgCode', treesOrg[0].value);
          setInitialStaffDomain(formik.values);
        }
      });
      
    }
  };

  useEffect(() => {
    // modalOrgTreeOptions
    getTreesOrgSelectRange(false, functionCode.staffs, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')).then((trees) => {
      const treesOrg = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));
      setModalOrgTreeOptions(treesOrg);
    });
  }, [])

  useEffect(() => {
    onLoadTreesOrg(initialStaffDomain.orgApplyDate)
  }, [initialStaffDomain]);

  // const getSelectedOrganizationInfo = useCallback(async () => {
  //   await getOrganizationInfo(formik.values.orgCode, moment(formik.values.hireDate).format('YYYY-MM-DD')).then((response: any) => {
  //     if (response.employmentId) {
  //       formik.setFieldValue('employmentId', response.employmentId);
  //     } else {
  //       formik.setFieldValue('employmentId', '');
  //     }
  //   });
  // }, [formik]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);


  const addManager = useCallback((orgList: Array<SelfOrganizationDomain>) => {
    if (!formik.values.managerDomainList) {
      return;
    }
    const managerDataList : Array<ManagerDataStaffDomain> = orgList.map((org) => new ManagerDataStaffDomain({
      managerId: '',
      staffCode: formik.values.staffCode,
      orgCode: org.orgCode,
      orgName: org.orgName,
      managerType: 0,
      startDateStr: moment(formik.values.orgApplyDate).format('YYYY年MM月DD日'),
      endDateStr: org.endDateAsDate ? moment(org.endDateAsDate).format('YYYY年MM月DD日') : null,
    }));

    formik.setFieldValue('managerDomainList', formik.values.managerDomainList.concat(managerDataList));
  }, []);

  const deleteOrganizationManager = useCallback((index: number) => () => {
    if (!formik.values.managerDomainList) {
      return;
    }
    formik.setFieldValue('managerDomainList', formik.values.managerDomainList.filter((manager, idx) => idx !== index));
  }, []);

  const setOrganizationManager = useCallback((index: number) => (newManager: ManagerDataStaffDomain) => {
    if (!formik.values.managerDomainList) {
      return;
    }
    formik.setFieldValue('managerDomainList', formik.values.managerDomainList.map((manager, idx) => (idx === index ? newManager : manager)));
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    openManagerAddModal,
    setOpenManagerAddModal,
    addManager,
    deleteOrganizationManager,
    setOrganizationManager,
    contextObject,
    openConcurrentAddModal,
    setOpenConcurrentAddModal,
    orgTreeOptions,
    onLoadTreesOrg,
    modalOrgTreeOptions
    // getSelectedOrganizationInfo,
  };
};

export default {
  useAffiliationForm,
};
