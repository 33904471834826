import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';

import MonthlyShiftImport from './MonthlyShiftImport';


const MonthlyShiftImportLayoutPage: React.FC = () => (
  <SidebarTemplate
    pageTitle="シフトインポート"
  >
    <MonthlyShiftImport />
  </SidebarTemplate>
);

export default MonthlyShiftImportLayoutPage;
