import { useEffect, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import TargetApplicationDomain from 'domain/targetApplication';
import { getAllTreesCurrentAndFuture } from 'api/organization';
import { OptionType } from 'components/atoms/Select';
import { getList } from 'api/executive';
import getApprovalFlowList, { aprrovelFlowConfiguration } from 'api/approvalFlowList';
import useToastNotification from 'hooks/useToastNotification';

export const ACTION = {
  REGISTER: 0,
  UPDATE: 1,
  DELETE: 2,
  NORMAL: 3,
}

export const useApplicationRoute = (belongOrgCode: any) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [targetApplication, setTargetApplication] = useState(1);
  const [targetApplicationRemain, setTargetApplicationRemain] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const { successNotification, errorNotification } = useToastNotification();

  const onSubmit = async () => {
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const data = formik.values.getRawData();

    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const updateUser = sessionStorage.getItem('loginUser.staffCode') || '';
    let convertData = {
      companyCode,
      updateUser,
      typeApplication: targetApplication,
      belongOrgCode
    };

    data.approvalFlowListData.forEach((item: any, index: number) => {
      const tmpDataApproval = {
        privilegeId: item.privilegeId,
        orgCode: item.orgCode,
        executiveId: item.executiveId,
        executiveName: item.executiveName,
        approvalLevel: item.approvalLevel,
        status: item.status,
      };

      convertData = {
        ...convertData,
        [`approvalFlowListData[${index}]`]: {
          ...tmpDataApproval,
        },
      };
    });

    setConfirmModalOpen(false);
    setIsLoading(true);
    try {
      const response = await aprrovelFlowConfiguration(companyCode, convertData);
      if (!response.errors) {
        successNotification('更新しました。');
        setIsLoading(false);
        if (response) {
          const listShowArray: Array<number> = [];
          for (let i = 0; i <= response.length; i += 1) {
            if (!listShowArray.includes(response[i]?.approvalLevel) && response[i]) {
              listShowArray.push(response[i]?.approvalLevel);
              response[i].isHiddenLabel = false;
              response[i].exists = true;
            } else if (listShowArray.includes(response[i]?.approvalLevel)) {
              response[i].isHiddenLabel = true;
              response[i].exists = true;
            }
          }
        }
        formik.setFieldValue('approvalFlowListData', response);

      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else if (error.response && error.response.status === 400 && error.response.data && error.response.data.defaultMessage) {
        errorNotification(error.response.data.defaultMessage);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  }

  const formik = useFormik({
    initialValues: TargetApplicationDomain.generateInitial(),
    onSubmit,
  });

  const [orgTreeOptions, setOrgTreeOptions] = useState<Array<OptionType>>([]);
  const [executiveOptions, setExecutiveOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    setIsLoading(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    Promise.all([
      getAllTreesCurrentAndFuture({addAllItem: false}),
      getList(companyCode),
    ]).then(([trees, executive]) => {
      if (trees) {
        const treesOrg = trees.map((tree: any) => ({
          value: tree.orgCode,
          label: tree.orgName,
        }));
        setOrgTreeOptions(treesOrg);
      }

      // Get Executive List
      if (executive) {
        const executiveOption = executive.map((exe: any) => ({
          value: exe.executiveId,
          label: exe.executiveName,
        }));
        executiveOption.unshift({
          value: '',
          label: '-',
        });
        setExecutiveOptions(executiveOption);
      }
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (targetApplicationRemain && isCopy) {
      setIsLoading(true);
      const approvalFlowListData = formik.values.approvalFlowListData.filter((value) => value.exists === true);
      formik.setFieldValue('approvalFlowListData', approvalFlowListData);
      getApprovalFlowList(belongOrgCode, targetApplicationRemain).then((response) => {
        if (response) {
          const listShowArray: Array<number> = [];
          for (let i = 0; i <= response.length; i += 1) {
            if (!listShowArray.includes(response[i]?.approvalLevel) && response[i]) {
              listShowArray.push(response[i]?.approvalLevel);
              response[i].status = ACTION.REGISTER;
              response[i].isHiddenLabel = false;
            } else if (listShowArray.includes(response[i]?.approvalLevel)) {
              response[i].status = ACTION.REGISTER;
              response[i].isHiddenLabel = true;
            }
          }
        }
        formik.setFieldValue('approvalFlowListData', [...formik.values.approvalFlowListData, ...response]);
        setIsLoading(false);
        setIsCopy(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetApplicationRemain, isCopy]);


  useEffect(() => {
    setIsLoading(true);
    getApprovalFlowList(belongOrgCode, targetApplication).then((response) => {
      if (response) {
        const listShowArray: Array<number> = [];
        for (let i = 0; i <= response.length; i += 1) {
          if (!listShowArray.includes(response[i]?.approvalLevel) && response[i]) {
            listShowArray.push(response[i]?.approvalLevel);
            response[i].isHiddenLabel = false;
            response[i].exists = true;
          } else if (listShowArray.includes(response[i]?.approvalLevel)) {
            response[i].isHiddenLabel = true;
            response[i].exists = true;
          }
        }
      }
      formik.setFieldValue('approvalFlowListData', response);
      setIsLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetApplication]);
  
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    orgTreeOptions,
    executiveOptions,
    confirmModalOpen,
    closeConfirmModal,
    setTargetApplication,
    targetApplication,
    setTargetApplicationRemain,
    targetApplicationRemain,
    isLoading,
    setIsCopy
  };
};

export default {};
