/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import BlockUi from "react-block-ui";
import PulseLoader from "react-spinners/PulseLoader";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";

interface BlockUiProps {
  blocking: boolean;
}
// Flag Logo
const logoFlag = process.env.REACT_APP_LOGO_FLAG;

const BlockUI: React.FC<BlockUiProps> = ({ children, blocking }) => (
  <div
    css={css`
      .block-ui-message-container {
        position: fixed;
      }
      .block-ui-container {
        cursor: auto;
        position: fixed;
        width: 100vw;
        height: 100vh;
      }
    `}
  >
    <BlockUi
      tag="div"
      blocking={blocking}
      className="block-ui-custom-container"
      // message={message}
      loader={
        <React.Fragment>
          {logoFlag === "0" ? (
            <PulseLoader color="#3145D1" loading={blocking} />
          ) : logoFlag === "2" ? (
            <React.Fragment>
              <img
                src="/cydas_loading_logo_2.gif"
                alt="loading"
                width={55}
                height={55}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img
                src="/cydas_people_loading.gif"
                alt="loading"
                width={55}
                height={30}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      {children}
    </BlockUi>
  </div>
);

export default BlockUI;
