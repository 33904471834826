import { useState, useEffect } from 'react';
import { OptionType } from 'components/atoms/Select';
import { getAvailableShiftPattern } from 'api/shiftPattern';
import { ShiftPattern } from 'domain/master/attend/shiftPattern';

/**
 * Get available shift pattern
 */
export const useShiftPatternOptions = (): Array<OptionType> => {
  const [shiftPatternOptions, setShiftPatternOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getAvailableShiftPattern().then((shiftPatterns) => {
      const patternsList = shiftPatterns.map(
        (shiftPattern: { shiftPatternCode: any; shiftPatternName: any; }) => ({
          value: shiftPattern.shiftPatternCode,
          label: `${shiftPattern.shiftPatternName}`,
        }),
      );
      patternsList.unshift({
        value: '',
        label: '出勤できません',
      });
      setShiftPatternOptions(patternsList);
    });
  }, []);
  return shiftPatternOptions;
};

export const useEmploymentShiftPatternOptions = (
  employmentId: string,
  belongOrgCode: string,
): {
    shiftPatterns: Array<ShiftPattern>;
    shiftPatternOptions : Array<OptionType>
} => {
  const [shiftPatterns, setShiftPatterns] = useState<Array<ShiftPattern>>([]);
  const [shiftPatternOptions, setShiftPatternOptions] = useState<Array<OptionType>>([]);

  useEffect(() => {
    if (!belongOrgCode) {
      return;
    }
    getAvailableShiftPattern(belongOrgCode).then((res) => {
      if (!employmentId) {
        return;
      }

      setShiftPatterns(res);

      const patternsList = res.map(
        (shiftPattern: { shiftPatternId: string; shiftPatternName: string; }) => ({
          value: shiftPattern.shiftPatternId,
          label: `${shiftPattern.shiftPatternName}`,
        }),
      );
      patternsList.unshift({
        value: '',
        label: '-',
      });
      setShiftPatternOptions(patternsList);
    }).catch(() => {
      setShiftPatternOptions([{
        value: '',
        label: '-',
      }]);
    });
  }, [belongOrgCode, employmentId]);

  return {
    shiftPatterns,
    shiftPatternOptions,
  };
};

export default useShiftPatternOptions;
