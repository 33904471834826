import { useEffect, useState } from "react";
import { StaffInfo } from "components/pages/master/shift/type";

const usePhotoPath = () => {
  const [myPhotoPath, setMyPhotoPath] = useState("");

  useEffect(() => {
    setMyPhotoPath(sessionStorage.getItem("loginUser.photoPath") || "");
  }, []);

  return {
    myPhotoPath,
  };
};

export const photoPath = (staff: StaffInfo) => {
  const domainUrl = process.env.REACT_APP_PHOTO_BASE_URL || "";
  return `${domainUrl}/${sessionStorage.getItem("loginUser.companyCode")}/${staff.staffCode}/${staff.nameplate || "1.jpg"}?${new Date().getTime()}`;
  
};

export default usePhotoPath;
