/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { grayScale, fontFamily } from "components/styles";
import useIsMobile from "hooks/useIsMobile";

export interface ButtonProps {
  /**
   * ボタンテキスト
   */
  text: string;
  /**
   * ボタンクリック時に実行する関数
   */
  onClick?: () => void;
  /**
   * ボタンの非活性化
   */
  disabled?: boolean;
  /**
   * ゴーストボタンかどうか
   */
  ghost?: boolean;
  fullwidth?: boolean;
  /**
   * ボタンの最小幅
   */
  minWidth?: string;

  isUploadFileButton?: boolean;

  customStyle?: SerializedStyles;
}

interface ButtonColor {
  /**
   * ベースの色
   */
  baseColor: string;
  /**
   * テキストの色
   */
  textColor: string;
  /**
   * ボーダーの色(指定がなければベースの色と同じになる)
   */
  borderColor?: string;
  /**
   * ゴーストボタンの色
   */
  ghostColor: string;
  /**
   * ゴーストボタンのテキストの色
   */
  ghostTextColor: string;
  /**
   * :hover時のボタンの色
   */
  hoverColor: string;
  /**
   * :hover時のボーダーの色(指定がなければ:hover時のボタンの色と同じになる)
   */
  hoverBorderColor?: string;
  /**
   * :focus時のボタンの色
   */
  focusColor: string;
}

interface BaseButtonProps extends ButtonProps {
  color: ButtonColor;
}

const baseButtonStyle = css({
  fontSize: "14px",
  lineHeight: "40px",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: "20px", // TODO Sketchで指定が見つかっていないためちゃんと確認する
  fontFamily,
  border: "1px solid white",
});
const disableStyle = css({
  backgroundColor: grayScale.gray20,
  borderRadius: "20px",
  color: "#FFFFFF",
  paddingLeft: "5px",
  paddingRight: "5px",
  border: "none",
});

const fullwidthStyle = css({
  width: "100%",
});

const minWidthStyle = (len: string) =>
  css({
    minWidth: len,
  });

const ghostStyle = (color: ButtonColor) =>
  css({
    backgroundColor: "transparent",
    color: color.ghostTextColor,
    border: "1px solid white",
  });
const additionalStyle = (color: ButtonColor) => {
  const { baseColor, textColor, borderColor } = color;
  return css({
    backgroundColor: baseColor,
    border: `1px solid ${borderColor || baseColor}`,
    color: textColor,
  });
};

const hoverStyle = (color: ButtonColor, ghost: boolean) =>
  css({
    ":hover": {
      backgroundColor: ghost ? "transparent" : color.hoverColor,
      color: ghost ? "" : color.textColor,
      opacity: ghost ? "0.8" : "",
      cursor: "pointer",
    },
    ":focus": {
      backgroundColor: ghost ? "transparent" : color.focusColor,
      opacity: ghost ? "0.8" : "",
      color: ghost ? "" : color.textColor,
    },
  });

/**
 * ボタンのベースとなるコンポーネント
 *
 * @see https://sketch.cloud/s/2b3Pe/a/ljLorx
 */
const BaseButton: React.FC<BaseButtonProps> = ({
  text,
  onClick,
  disabled,
  ghost = false,
  color,
  fullwidth,
  minWidth,
  isUploadFileButton,
  customStyle = {},
  children,
}) => {
  const isMobile = useIsMobile();

  const buttonCss = React.useMemo(() => {
    let buttonStyle = css(baseButtonStyle, ghost ? ghostStyle(color) : additionalStyle(color));

    if (fullwidth) {
      buttonStyle = css(buttonStyle, hoverStyle(color, ghost), fullwidthStyle);
    }

    if (minWidth) {
      buttonStyle = css(buttonStyle, hoverStyle(color, ghost), minWidthStyle(minWidth));
    }

    if (disabled) {
      return css(buttonStyle, disableStyle);
    }

    return css(buttonStyle, hoverStyle(color, ghost));
  }, [color, disabled, fullwidth, ghost, minWidth]);

  return (
    <button
      css={[buttonCss, customStyle]}
      type="button"
      onClick={onClick}
      disabled={disabled}
      style={
        isUploadFileButton
          ? { outline: "none", border: "none" }
          : isMobile
          ? { lineHeight: "normal", padding: "8px 0" }
          : {}
      }
    >
      {children}
      {text}
    </button>
  );
};

export default BaseButton;
