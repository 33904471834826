import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import AttendApplicationListV3 from 'components/organismos/master/attend/AttendApplicationListV3';

const AttendApplicationListV3Page = () => (
  <SidebarTemplate>
    <AttendApplicationListV3 />
  </SidebarTemplate>
);

export default AttendApplicationListV3Page;
