import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import {
  createOrUpdateAttendStampIp,
  getAttendSettingIPById,
  getAttendStampIpList,
} from "api/attendanceLayout";
import { useHistory } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";
import AttendanceMasterDomain from "domain/master/attend/attendanceMaster";
import { getListV2 } from "api/organization";
import OrganizationDomain from "domain/organization";
import { renderError } from "utility/renderMessage";
import { deleteAttendStampIPById } from "api/attendanceLayout";

export const useOrganizationList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgStatus] = useState<"0" | "1">("0");
  const [organizationList, setOrganizationList] = useState<Array<OrganizationDomain>>([]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await getListV2(Number(orgStatus), new Date());
    setOrganizationList(
      response.map((result) => new OrganizationDomain(result))
    );
    setIsLoading(false);
  }, [orgStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    setIsLoading,
    organizationList,
  };
};

export const useAttendanceLayoutDomainAddForm = (stampIpId?: string) => {
  const { organizationList } = useOrganizationList();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const { detailRole } = useGetDetailRole(
    setIsLoading,
    functionCode.stampIpSetting
  );
  const [selectedOrg, setSelectedOrg] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  useEffect(() => {
    if (stampIpId) {
      getAttendSettingIPById(stampIpId).then((response: any) => {
        if (response) {
          formik.setValues(new AttendanceMasterDomain(response));
          formik.setFieldValue(
            "ip",
            response.ipAddress + "/" + response.subnetMask
          );
          setSelectedOrg(
            response.stampIPDetail.map((val: any) => {
              return val.orgCode;
            })
          );
          if (selectedOrg.length === organizationList.length) {
            setIsCheckedAll(true);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stampIpId]);

  const validateIPaddress = (ipaddress: any) => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }
    errorNotification("IPアドレスを正しい形式で指定してください。");
    return false;
  };

  const checkSubnet = (sub: number) => {
    if (sub < 0 || sub > 32) {
      return false;
    }
    return true;
  };

  // handle submit form
  const onSubmit = async (values: AttendanceMasterDomain) => {
    const [ip, subnet] = formik.values.ip.split("/");
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    const loginUser = sessionStorage.getItem("loginUser.staffName") || "";

    if (values.ip === "") {
      errorNotification("IPアドレスを入力してください。");
      return;
    } else {
      if (!validateIPaddress(ip)) {
        return;
      }
    }

    if (!subnet) {
      errorNotification("IPの後ろにサブネットマスクを入力してください。");
      return;
    } else {
      if (!checkSubnet(Number(subnet))) {
        errorNotification(
          "サブネットマスクが不正です。数値は0~32の半角数字で指定してください。"
        );
        return;
      }
    }

    if (selectedOrg.length === 0) {
      errorNotification("組織を選択してください。");
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const dataPost = {
      companyCode: companyCode,
      createUser: loginUser,
      updateUser: loginUser,
      stampIpId: formik.values.stampIpId,
      ipAddress: ip,
      subnetMask: subnet,
      orgCodeList: selectedOrg,
    };

    try {
      setIsLoading(true);
      const response = await createOrUpdateAttendStampIp(companyCode, dataPost);
      setIsLoading(false);
      if (!response.errors) {
        successNotification(stampIpId ? "更新しました。" : "登録しました。");
        setConfirmModalOpen(false);
        setTimeout(() => {
          history.push("/stampIpSetting");
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
      setConfirmModalOpen(false);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        if (listErr) {
          listErr.map((element: any, index: any) => {
            stringErr += `${element.defaultMessage}<br />`;
          });
        } else {
          stringErr = error.response.data.message;
        }
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: AttendanceMasterDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    setConfirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    setToastModalOpen,
    isLoading,
    selectedOrg,
    setIsLoading,
    detailRole,
    organizationList,
    setSelectedOrg,
    isCheckedAll,
    setIsCheckedAll,
  };
};

export const useAttendList = () => {
  const history = useHistory();
  const [selectedAttendanceLayout, setselectedAttendanceLayout] = useState(AttendanceMasterDomain.generateInitial());
  const [attendanceLayoutList, setAttendanceLayoutList] = useState<Array<AttendanceMasterDomain>>([]);
  const { successNotification, errorNotification } = useToastNotification();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalOpenDelete, setConfirmModalOpenDelete] = useState(false);
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading,functionCode.stampIpSetting);
  const handleFetchAttendStampList = useCallback(async () => {
    try {
      setIsLoading(true);
      const orgCodeList = await getListV2(0, new Date());
      const response = await getAttendStampIpList();
      if (response) {
        setAttendanceLayoutList(
          response.map((item: any, index: any) => {
            if (item?.stampIPDetail?.length === orgCodeList.length) {
              response[index].stampIPDetail = [{ orgCode: "all", orgName: "全ての組織", checkAll: true }];
            }
            return new AttendanceMasterDomain(item);
          })
        );
      }
    } catch (error) {
      renderError(error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    handleFetchAttendStampList();
  }, []);

  /**
   * Delete IP setting
   */
  const deleteStampSettingIP = useCallback((stampIpId: string) => {
    deleteAttendStampIPById(stampIpId)
      .then((response: any) => {
        setAttendanceLayoutList(
          attendanceLayoutList.filter(
            (attend) => attend.stampIpId !== stampIpId
          )
        );
        setConfirmModalOpen(false);
        setConfirmModalOpenDelete(false);
        successNotification("削除しました。"); //success
        history.push("/stampIpSetting");
      })
      .catch((error) => {
        setConfirmModalOpen(false);
        setConfirmModalOpenDelete(false);
        const mess = renderError(error);
        errorNotification(mess);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attendanceLayoutList]
  );

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmModalDelete = useCallback(() => {
    setConfirmModalOpenDelete(false);
  }, []);

  const openConfirmModal = useCallback((stamp: AttendanceMasterDomain) => {
    setConfirmModalOpen(true);
    setselectedAttendanceLayout(stamp);
  }, []);

  return {
    selectedAttendanceLayout,
    setselectedAttendanceLayout,
    attendanceLayoutList,
    setAttendanceLayoutList,
    successNotification,
    errorNotification,
    isLoading,
    setIsLoading,
    deleteStampSettingIP,
    confirmModalOpen,
    setConfirmModalOpen,
    confirmModalOpenDelete,
    setConfirmModalOpenDelete,
    detailRole,
    toastModalOpen,
    setToastModalOpen,
    toastMessage,
    closeConfirmModal,
    openConfirmModal,
    closeConfirmModalDelete,
  };
};

export default useAttendanceLayoutDomainAddForm;
