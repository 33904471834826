/** @jsx jsx */
import React, { useCallback, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import PrimaryButton from 'components/atoms/Button';
import { isMobileOnly } from 'react-device-detect';
import useAttendTimeRecorder from '../hooks';

const styles = {

  header: css({
    margin: '29px 0 32px 0',
    textAlign: 'center',
  }),

  container: css({
    padding: '0 20px 0 20px',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }),

  timeTitle: css({
    fontSize: '64px',
  }),

  imageUploadContainer: css({
    width: '100%',
    height: '100%',
    backgroundColor: '#E5E5E5',
    marginBottom: isMobileOnly ? '0px' : '22px',
  }),

  justifyCenter: css({
    justifyContent: 'center',
  }),

  name: css({
    fontSize: isMobileOnly ? '36px' : '64px',
  }),

  userContainer: css({
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
  }),

  groupButton: css({
    marginBottom: isMobileOnly ? '20px' : '50px',
    justifyContent: 'space-between',
  }),

  justifyBetween: css({
    justifyContent: 'space-between',
  }),

  businessList: css({
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }),

};

const buttonContainer = css`
  width: ${isMobileOnly ? '100%' : '50%'} ;
  margin-top: ${isMobileOnly ? '45px' : '0'};
  button {
    height: ${isMobileOnly ? '60px' : '80px'} ;
    margin-bottom: 15px;
    font-size: ${isMobileOnly ? '16px' : '21px'} ;
    line-height: ${isMobileOnly ? '20px' : ''} ;
    width:100%;
  }
  button[disabled]:hover{
    background-color: #D6D6D6;
    border: 1px solid #EAEAEA;
    color: #ADADAD;
  }
  padding: ${isMobileOnly ? '0 15px 0 15px' : '0'} ;
`;


const AttendTimeRecorderConfirm: React.FC<{
  businessCnt: number,
  stampOrgCode: string,
  stampOrgName: string,
  lastStampType: number,
  setLastStampType: any,
  setIsConfirm: any,
  setStampHistoryList: any,
  businessList: Array<any>,
  barcodeId: string,
  staffName: string,
  inputBarcodeId: string,
  formik: any,
  serverTimeState: any,
  loginOrgName: string,
  loginOrgCode: string,
  stampMode: number,
  useRecess: boolean,
  tmpFilePath: string,
  faceId: string,
  similarity: string,
  mistake: any
  setPhotoFullpath: any
  setIsPhoto: any
}> = ({
  businessCnt,
  stampOrgCode,
  stampOrgName,
  lastStampType,
  setLastStampType,
  setIsConfirm,
  setStampHistoryList,
  businessList,
  barcodeId,
  staffName,
  inputBarcodeId,
  formik,
  serverTimeState,
  loginOrgName,
  loginOrgCode,
  stampMode,
  useRecess,
  tmpFilePath,
  faceId,
  similarity,
  mistake,
  setPhotoFullpath,
  setIsPhoto,
}) => {
  const {
    formikConfirm,
    formikConfirmFace,
    setIsSubmitting,
    isSubmitting,
    getHistoryRecorder,
    companyCode,
    shopCode,
    errorNotification,
    stampByBelongOrg,
  } = useAttendTimeRecorder();

  const handleRecorder = useCallback((stampType:number, businessId?: string) => {
    setIsSubmitting(true);
    if (stampMode === 3) {
      formikConfirm.setFieldValue('orgCode', stampOrgCode);
      formikConfirm.setFieldValue('orgName', stampOrgName);
      formikConfirm.setFieldValue('loginOrgName', loginOrgName);
      formikConfirm.setFieldValue('loginOrgCode', loginOrgCode);
      formikConfirm.setFieldValue('staffName', staffName);
      formikConfirm.setFieldValue('barcodeId', barcodeId);
      formikConfirm.setFieldValue('inputBarcodeId', inputBarcodeId);
      formikConfirm.setFieldValue('stampTime', String(serverTimeState));
      formikConfirm.setFieldValue('stampType', stampType);
      formikConfirm.setFieldValue('businessId', businessId);
      formikConfirm.setFieldValue('stampMode', stampMode);
      formikConfirm.submitForm();
    }
    if (stampMode === 1) {
      formikConfirmFace.setFieldValue('orgCode', stampOrgCode);
      formikConfirmFace.setFieldValue('orgName', stampOrgName);
      formikConfirmFace.setFieldValue('loginOrgName', loginOrgName);
      formikConfirmFace.setFieldValue('loginOrgCode', loginOrgCode);
      formikConfirmFace.setFieldValue('staffName', staffName);
      formikConfirmFace.setFieldValue('tmpFilePath', tmpFilePath);
      formikConfirmFace.setFieldValue('faceId', faceId);
      formikConfirmFace.setFieldValue('similarity', similarity);
      formikConfirmFace.setFieldValue('mistake', mistake || '');
      formikConfirmFace.setFieldValue('stampTime', String(serverTimeState));
      formikConfirmFace.setFieldValue('stampType', stampType);
      formikConfirmFace.setFieldValue('businessId', businessId);
      formikConfirmFace.setFieldValue('stampMode', stampMode);
      formikConfirmFace.submitForm();
    }
  }, [barcodeId, faceId, formikConfirm, formikConfirmFace, inputBarcodeId, loginOrgCode, loginOrgName, mistake, serverTimeState, setIsSubmitting, similarity, staffName, stampMode, stampOrgCode, stampOrgName, tmpFilePath]);


  useEffect(() => {
    if (isSubmitting === false) {
      formik.setFieldValue('inputBarcodeId', '');
      setPhotoFullpath('');
      setIsPhoto(false);
      console.log(`stampByBelongOrg = ${stampByBelongOrg}`);
      try {
        getHistoryRecorder(
          companyCode,
          stampByBelongOrg ? '' : shopCode,
        ).then((responseHistory: any) => {
          setStampHistoryList(responseHistory);
          setIsConfirm(false);
        });
      } catch (error) {
        errorNotification('サーバー側でエラーが発生しました。');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <React.Fragment>
      <div css={buttonContainer}>
        <FlexBoxItem>
          <FlexBox customStyle={styles.businessList}>
            { businessList
            && businessList.map((business :any) => (
              <FlexBoxItem basis="45%" key={business.businessId}>
                <PrimaryButton
                  text={`${business.businessName}を開始`}
                  onClick={() => { handleRecorder(4, business.businessId); }}
                  minWidth="100%"
                  disabled={isSubmitting}
                />
              </FlexBoxItem>
            ))}
          </FlexBox>
          <FlexBox customStyle={styles.groupButton}>
            { (businessCnt === 0 && (lastStampType === -1 || lastStampType === 3))
            && (
              <React.Fragment>
                <FlexBoxItem basis="45%">
                  <PrimaryButton
                    text="出勤"
                    onClick={() => handleRecorder(0)}
                    minWidth="100%"
                    disabled={isSubmitting}
                  />
                </FlexBoxItem>
                <FlexBoxItem basis="45%" />
              </React.Fragment>
            )}
            { (lastStampType === 0 || lastStampType === 2)
            && (
              <React.Fragment>
                <FlexBoxItem basis="45%">
                  <PrimaryButton
                    text="退勤"
                    onClick={() => handleRecorder(3)}
                    minWidth="100%"
                    disabled={isSubmitting}
                  />
                </FlexBoxItem>
                <FlexBoxItem basis="45%" />
              </React.Fragment>
            )}

          </FlexBox>
          <FlexBox customStyle={styles.justifyBetween}>
            { (useRecess && (lastStampType === 0 || lastStampType === 2))
            && (
              <React.Fragment>
                <FlexBoxItem basis="45%">
                  <PrimaryButton
                    text="休憩開始"
                    onClick={() => handleRecorder(1)}
                    minWidth="100%"
                    disabled={isSubmitting}
                  />
                </FlexBoxItem>
                <FlexBoxItem basis="45%" />
              </React.Fragment>
            )}
            { (useRecess && (businessCnt === 0 && lastStampType === 1))
            && (
              <React.Fragment>
                <FlexBoxItem basis="45%">
                  <PrimaryButton
                    text="休憩終了"
                    onClick={() => handleRecorder(2)}
                    minWidth="100%"
                    disabled={isSubmitting}
                  />
                </FlexBoxItem>
                <FlexBoxItem basis="45%" />
              </React.Fragment>
            )}
          </FlexBox>
        </FlexBoxItem>
      </div>
    </React.Fragment>
  );
};

export default AttendTimeRecorderConfirm;
