/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx } from '@emotion/core';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  input: css({
    marginTop: '0px',
    // zIndex: 22
  }),
  error: css({
    marginTop: '10px',
    color: 'red',
    fontSize: '12px'
  }),
  wrapperCenter: css({
    display: 'block',
    width: '40%',
    margin: '0 auto'
  })
};

/**
 * フォームのレイアウト
 *  - display: flexでレイアウト
 *  - ラベルとinputの間にマージンをとる
 */
const VerticalLabelFormLayout: React.FC<{
  label: ReactNode;
  input: ReactNode;
  note?: string;
  errorMsg?: string;
  inputCenter?: boolean;
}> = ({
  label, input, errorMsg, inputCenter=false, note
}) => (
  <div css={css( inputCenter ? styles.wrapperCenter : styles.wrapper)}>
    {label}
    <div css={styles.input}>
      {input}
    </div>
    {note && <span css={styles.error}>{note}</span>}
    { errorMsg ? <span css={styles.error}> {errorMsg} </span> : '' }
    
  </div>
);

export default VerticalLabelFormLayout;
