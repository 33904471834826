import { useState, useEffect, useCallback } from "react";

import { getAttendAlerts, AttendAlert } from "api/attendAlert";
import { getAttendErrorOrg, getAttendErrorPersonal, AttendErrorOgr, AttendErrorPersonal } from "api/attendError";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { uiSidebarActions } from "modules/ui/sidebar";
import { useDispatch } from "react-redux";
import { logout } from "api/login";
import clearLocalStorageAfterLogout from "hooks/useClearLocalStorageAfterLogout";
import { getAuthorizedFromDelious } from "utility";

export const useAttendAlert = () => {
  const [openAttendAlert, setOpenAttendAlert] = useState(false);
  const [attendAlertList, setAttendAlertList] = useState<Array<AttendAlert>>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAttendAlerts();
      setAttendAlertList(response);
    };
    fetch();
  }, []);

  return {
    openAttendAlert,
    setOpenAttendAlert,
    attendAlertList,
  };
};

export const useAttendErrorOrg = () => {
  const [attendErrorOrgList, setAttendErrorOrgList] = useState<Array<AttendErrorOgr>>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAttendErrorOrg();
      setAttendErrorOrgList(response);
    };
    fetch();
  }, []);

  return {
    attendErrorOrgList,
  };
};

export const useAttendErrorPersonal = () => {
  const [attendErrorPersonalList, setAttendErrorPersonalList] = useState<Array<AttendErrorPersonal>>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAttendErrorPersonal();
      setAttendErrorPersonalList(response);
    };
    fetch();
  }, []);

  return {
    attendErrorPersonalList,
  };
};

export const useStaffSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openStaffSetting, setOpenStaffSetting] = useState(false);
  const companyCode = sessionStorage.getItem("loginUser.companyCode");

  /**
   * sessionStorageをクリアしてログインページへ飛ばす
   */
  const logoutAction = useCallback(() => {
    const isAuthorizedFromPeople = process.env.REACT_APP_IS_AUTHORIZED_FROM_PEOPLE || false;
    let deliousToken = localStorage.getItem("DELIOUS_TOKEN") || "";
    if (isAuthorizedFromPeople === "true") {
      var tenant = localStorage.getItem("PEOPLE_TENANT") || "";
      var redirectPeopleLogout = process.env.REACT_APP_PEOPLE_URL || "";
      if (tenant !== "") {
        // redirectPeopleLogout += "/"+ "ppstg_hinata"+"/"+"logout";
        redirectPeopleLogout += "/" + tenant + "/" + "single_logout";
        logout()
          .then(() => {
            clearLocalStorageAfterLogout();
            sessionStorage.clear();

            window.location.href = redirectPeopleLogout;
          })
          .catch(() => {
            clearLocalStorageAfterLogout();
            sessionStorage.clear();
            window.location.href = redirectPeopleLogout;
          });
      }
    } else if (deliousToken) {
      // logout().finally(() => {

      // });

      dispatch(uiSidebarActions.setHierarchy(undefined));
      sessionStorage.clear();
      clearLocalStorageAfterLogout();
      window.removeEventListener("storage", () => {});

      history.push(`/login/${companyCode}`);
    } else {
      dispatch(uiSidebarActions.setHierarchy(undefined));
      Auth.signOut();
      sessionStorage.clear();
      clearLocalStorageAfterLogout();
      window.removeEventListener("storage", () => {});

      history.push(getAuthorizedFromDelious() ? `/login/${companyCode}` : "/login");
    }
  }, [dispatch, history]);

  return {
    openStaffSetting,
    setOpenStaffSetting,
    logoutAction,
  };
};

/*
export const usePhotoPath = () => {
  const domainUrl = document.domain === 'resduc.com'
    ? 'https://photo.win-board.net'
    : 'https://staging.photo.win-board.net';

  const [photoPath, setPhotoPath] = useState(
    `${domainUrl
    }/${sessionStorage.getItem('loginUser.companyCode')
    }/${sessionStorage.getItem('loginUser.staffCode')
    }/1.jpg?${now()}`,
  );
  return photoPath;
};
*/

export const useSearch = () => {
  const [searchString, setSearchString] = useState<string>("");

  const searchOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  return {
    searchString,
    setSearchString,
    searchOnchange,
  };
};

export default {};
