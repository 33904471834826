import { useState, useEffect, useCallback } from "react";
import {
  getStampList,
  createOrUpdateStampModification,
  getStampHistoryListAchievementId,
  getReasonList,
} from "api/stampModification";
import { OptionType } from "components/atoms/Select";
import AttendStampModificationDomain, { ShiftPatternDetailInput } from "domain/master/attend/attendStampModification";
import { ApplicationReason } from "domain/master/attend/applicationReason";
import { useHistory } from "react-router-dom";
import { getBusinessAttendChoice } from "api/businessMaster";
import { getRoundTimeList } from "api/employment";
import moment from "moment";
import { useFormik } from "formik";
import useToastNotification from "hooks/useToastNotification";
import { useOrgAllSelect } from "hooks/useOrgTreesOptions";

export const useStampModificationAddForm = () => {
  const history = useHistory();

  let ssAchievementId: any = "";
  if (
    sessionStorage.getItem("application.achievementId") &&
    sessionStorage.getItem("application.achievementId") != "null"
  ) {
    ssAchievementId = sessionStorage.getItem("application.achievementId");
  }
  let ssModifierApplicationId: any = "";
  if (
    sessionStorage.getItem("application.modifierApplicationId") &&
    sessionStorage.getItem("application.modifierApplicationId") != "null"
  ) {
    ssModifierApplicationId = sessionStorage.getItem("application.modifierApplicationId");
  }

  let ssVersion: any = "";
  if (
    sessionStorage.getItem("application.stampVersion") &&
    sessionStorage.getItem("application.stampVersion") != "null"
  ) {
    ssVersion = sessionStorage.getItem("application.stampVersion");
  }

  let ssTargetDate = sessionStorage.getItem("application.targetDate");
  if (ssTargetDate) {
    ssTargetDate = ssTargetDate.substr(0, 10);
  }

  const [achievementId] = useState(ssAchievementId || "");
  const [modifierApplicationId] = useState(ssModifierApplicationId || "");
  const [staffCode] = useState(sessionStorage.getItem("application.staffCode") || "");
  const [targetDate] = useState(ssTargetDate || "");
  const [staffName] = useState(sessionStorage.getItem("application.dispStaffName") || "");
  const [version] = useState(ssVersion || "");
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem("application.orgCode") || "");
  const [toastMessage, setToastMessage] = useState("");
  const { successNotification, errorNotification } = useToastNotification();
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [stampList, setStampList] = useState<Array<ShiftPatternDetailInput>>([]);
  const [attendBusinessOptions, setAttendBusinessOptions] = useState<Array<OptionType>>([]);
  const [listTimes, setListTimes] = useState([]);
  const [stampHistoryListAchievement, setStampHistoryListAchievement] = useState([]);
  const [selectChangelog, setSelectChangelog] = useState<string>("0");
  //use Remote
  const [remote, setRemote] = useState<boolean>(false);
  const [isRemote, setIsRemote] = useState<boolean>(false);

  // get OrgCode options
  const targetDateFrom = moment(`${targetDate}`).format("YYYY-MM-DD");
  const targetDateTo = moment(`${targetDate}`).format("YYYY-MM-DD");
  const orgTreesOptions = useOrgAllSelect(false, targetDateFrom, targetDateTo);

  // get reasonList
  const [reasonList, setReasonList] = useState<Array<ApplicationReason>>([]);
  const [reasonOptionList, setReasonOptionList] = useState<Array<OptionType>>([]);
  const [reasonId, setReasonId] = useState<string>();

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    // getStampList
    try {
      if (orgCode) {
        const employmentId = "";
        handleGetRoundTimeList(employmentId, targetDate, staffCode);
        setLoading(true);
        const targetDateStr = moment(`${targetDate}`, "YYYY-MM-DD").format("YYYY/MM/DD");
        handleGetBusinessAttendChoice(staffCode, targetDateStr);
        handleGetReasonList();
        const params = {
          achievementId,
          modifierApplicationId,
          version,
          staffCode,
          staffName,
          orgCode: orgCode,
          targetDate,
        };
        getStampList(params).then((response: any) => {
          if (response && response.stampList.length) {
            setRemote(response.useRemote);
            setIsRemote(Boolean(response.stampList[0].isRemote));
            for (const item in response) {
              if (response[item] === null) {
                response[item] = "";
              }
            }
            formik.setValues(new AttendStampModificationDomain(response));
            const { stampList } = response;
            if (stampList.length) {
              if (stampList[0].stampId === null) {
                stampList.map((shiftPatternDetail: ShiftPatternDetailInput) => {
                  if (shiftPatternDetail.startTime === null && shiftPatternDetail.endTime === null) {
                    shiftPatternDetail.startTime = "00:00";
                    shiftPatternDetail.endTime = "00:00";
                  } else {
                    shiftPatternDetail.startTime = moment(shiftPatternDetail.startTime, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm",
                    );
                    shiftPatternDetail.endTime = moment(shiftPatternDetail.endTime, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm",
                    );
                  }
                });
                setStampList(stampList);
              } else {
                setStampList(stampList.map((shiftPatternDetail: ShiftPatternDetailInput) => shiftPatternDetail));
              }
            }
            // Hide loading pin
            setLoading(false);
          }
        });

        getStampHistoryListAchievementId(achievementId).then((response: any) => {
          if (response && response.length) {
            setStampHistoryListAchievement(response);
          }
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode]);

  const handleGetBusinessAttendChoice = async (staffCode: any, targetDate: any) => {
    await getBusinessAttendChoice(staffCode, targetDate).then((response: any) => {
      if (response && response.length) {
        let tmpAttendBusiness = response.map((business: any) => ({
          value: business.businessId === null ? "" : business.businessId,
          label: business.businessName === null ? "" : business.businessName,
        }));
        if (response.length <= 1) {
          tmpAttendBusiness = [
            {
              value: " ",
              label: "",
            },
            ...tmpAttendBusiness,
          ];
        }
        setAttendBusinessOptions(tmpAttendBusiness);
      }
    });
  };

  const handleGetRoundTimeList = async (
    employmentId: string,
    targetDateStr: string,
    staffCode: string,
    isUpdate?: boolean,
  ) => {
    await getRoundTimeList(employmentId, targetDateStr, staffCode).then((response: any) => {
      if (response && response.roundTimeList) {
        setListTimes(response.roundTimeList);
      }
    });
  };

  const handleGetReasonList = async () => {
    await getReasonList(1).then((response: any) => {
      if (response && response.length) {
        let tmpReason = response.map((obj: any) => ({
          value: obj.reasonId === null ? "" : obj.reasonId,
          label: obj.applicationReason === null ? "" : obj.applicationReason,
        }));
        setReasonOptionList(tmpReason);
        setReasonList(response);
      }
    });
  };

  const onSubmit = async (values: AttendStampModificationDomain) => {
    if (values.applicationReason === null) {
      errorNotification("申請理由を入力してください。");
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    const data = values.getRawData();

    delete (data as any).stampList;

    const {
      achievementId,
      modifierApplicationId,
      staffCode,
      orgName,
      targetDate,
      targetDateStr,
      version,
      buttonType,
      applicationReason,
      reasonId,
      autoRecess,
      shiftRecess,
      displayAutoBreak,
    } = values.getRawData();

    const loggedInUser = sessionStorage.getItem("loginUser.staffCode") || "";
    let dataPost = {
      achievementId: achievementId || "",
      modifierApplicationId: modifierApplicationId || "",
      version: version || "",
      staffCode,
      orgCode,
      orgName,
      targetDate,
      targetDateStr,
      buttonType,
      loginUserCode: loggedInUser,
      applicationReason,
      createUser: loggedInUser,
      updateUser: loggedInUser,
      useRemote: isRemote,
      reasonId: reasonId || "",
      autoRecess: displayAutoBreak ? autoRecess || 0 : 0,
      shiftRecess: displayAutoBreak ? shiftRecess || 0 : 0,
    };
    // handel StampList
    stampList.forEach((item: any, index: number) => {
      let startTimeStr: any = targetDateStr;
      let endTimeStr: any = targetDateStr;

      if (item.isStartTimeNextDay === true) {
        startTimeStr = moment(`${targetDateStr}`, "YYYY-MM-DD")
          .add("days", 1)
          .format("YYYY-MM-DD");
      }
      if (item.isEndTimeNextDay === true) {
        endTimeStr = moment(`${targetDateStr}`, "YYYY-MM-DD")
          .add("days", 1)
          .format("YYYY-MM-DD");
      }

      delete (item as any).createUser;
      delete (item as any).updateUser;
      delete (item as any).shiftPatternDetailId;
      delete (item as any).shiftPatternId;
      delete (item as any).version;
      delete (item as any).businessName;
      delete (item as any).updateDate;

      const dateTimeFormat = item.stampId ? "YYYY-MM-DD HH:mm:ss" : "HH:mm";
      const startHourForm = moment(item.startTime, dateTimeFormat).format("HH");
      const startTimeForm = moment(item.startTime, dateTimeFormat).format("mm");
      const endHourForm = moment(item.endTime, dateTimeFormat).format("HH");
      const endTimeForm = moment(item.endTime, dateTimeFormat).format("mm");

      const tmpDetail = {
        ...item,
        startHourForm,
        startTimeForm,
        startTime: moment(`${startTimeStr} ${startHourForm}:${startTimeForm}:00`, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY/MM/DD HH:mm:ss",
        ),
        startTimeOrg: "",
        endHourForm,
        endTimeForm,
        endTime: moment(`${endTimeStr} ${endHourForm}:${endTimeForm}:00`, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY/MM/DD HH:mm:ss",
        ),
        endTimeOrg: "",
        businessId: item.businessId === null ? "" : item.businessId,
        stampId: item.stampId === null || item.stampId === undefined ? "" : item.stampId,
        isRemote,
      };

      dataPost = {
        ...dataPost,
        [`stampList[${index}]`]: {
          ...tmpDetail,
        },
      };
    });

    // submit form
    try {
      setLoading(true);
      setConfirmModalOpen(false);
      const response = await createOrUpdateStampModification(dataPost);
      if (!response.errors) {
        sessionStorage.setItem("highlightAction", "true");
        history.push(sessionStorage.getItem("attendStampList.returnDestination")! || "");
      }
      successNotification("申請しました。");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = "";
        error.response.data.errors.map((item: { defaultMessage: string }) => {
          msgError += `${item.defaultMessage} <br />`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: AttendStampModificationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    orgTreesOptions,
    orgCode,
    setOrgCode,
    staffName,
    attendBusinessOptions,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    setConfirmModalOpen,
    closeToastModal,
    closeConfirmModal,
    stampList,
    setStampList,
    listTimes,
    setListTimes,
    isLoading,
    modifierApplicationId,
    stampHistoryListAchievement,
    setStampHistoryListAchievement,
    selectChangelog,
    setSelectChangelog,
    remote,
    isRemote,
    setIsRemote,
    reasonList,
    reasonOptionList,
    reasonId,
    setReasonId,
  };
};

export default useStampModificationAddForm;
