/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import Amplify, { Auth } from "aws-amplify";

import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormField from "components/atoms/Form/FormField";
import PasswordForm from "components/molecules/PasswordForm";
import Button from "components/atoms/Button";
import { useHistory, useParams } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import TextForm from "components/molecules/TextForm";
import { generateCognitoInfo } from "utility/generateCognitoInfo";
import { getCognitoInfo } from "api/login";

const ActivationSchema = Yup.object().shape({
  roleCode: Yup.string().required("メールアドレスを入力してください"),
});

const ActivationInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const { mailAddress, companyCode } = useParams<any>();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: mailAddress !== undefined ? mailAddress : "",
      password: "",
    },
    onSubmit: (values) => {
      getCognitoInfo(companyCode ?? "", formik.values.email).then((res) => {
        localStorage.setItem("loginUser.companyCode", res.companyCode);
        sessionStorage.setItem("loginUser.companyCode", res.companyCode);

        // 設定情報をAmplifyに反映させる
        Amplify.configure(generateCognitoInfo(res));

        Auth.confirmSignUp(formik.values.email, formik.values.password)
          .then((error: any) => {
            successNotification("ユーザー登録が完了しました");
            history.push("/login");
          })
          .catch((exception: any) => {
            if (exception.code === "CodeMismatchException") {
              errorNotification("認証コードが間違っています");
            } else {
              errorNotification("ユーザー登録に失敗しました");
            }
          });
      });
    },
    validationSchema: ActivationSchema,
  });

  const resendCode = async () => {
    if (!formik.values.email) {
      errorNotification("認証コードを再発行するメールアドレスを入力してください。");
      return;
    }
    try {
      await Auth.resendSignUp(formik.values.email);
    } catch (err) {
      errorNotification(err.message);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <FormField>
        <TextForm
          name="email"
          label="メールアドレス"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </FormField>*/}
      {!mailAddress && (
        <FormField>
          <TextForm
            name="email"
            label=""
            placeHolder="メールアドレスを入力"
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMsg={formik.errors.email}
          />
        </FormField>
      )}
      <FormField>
        <PasswordForm
          name="password"
          label=""
          placeHolder="6桁の認証コードを入力"
          value={formik.values.password}
          onChange={formik.handleChange}
          errorMsg={formik.errors.password}
        />
      </FormField>

      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%", marginTop: "20px" })}>
          <Button text="認証" onClick={formik.handleSubmit} minWidth="160px" />
        </div>
      </FormSubmitArea>
      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%" })}>
          <Button text="認証コードを再送する" onClick={() => resendCode()} minWidth="160px" ghost={true} />
        </div>
      </FormSubmitArea>
    </form>
  );
};

export default ActivationInputForm;
