export interface StockPaidHolidayStatus {
    orgCode: string,
    orgName: string,
    staffCode: string,
    staffName: string,
    stockPaidHolidayGrantId: string,
    grantDaysNum: string,
    createUser: string,
    updateUser: string,

}

export default class StockPaidHolidayStatusDomain {
  constructor(private rawData: StockPaidHolidayStatus) {
    // do nothing
  }

  static generateInitial(): StockPaidHolidayStatusDomain {
    return new StockPaidHolidayStatusDomain({
      orgCode: '',
      orgName: '',
      staffCode: '',
      staffName: '',
      stockPaidHolidayGrantId: '',
      grantDaysNum: '',
      createUser: '',
      updateUser: '',
    });
  }

  getRawData(): StockPaidHolidayStatus {
    return this.rawData;
  }

  get orgCode():string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode:string) {
    this.rawData.orgCode = orgCode;
  }

  get orgName():string {
    return this.rawData.orgName;
  }

  set orgName(orgName:string) {
    this.rawData.orgName = orgName;
  }

  get staffCode():string {
    return this.rawData.staffCode;
  }

  set staffCode(staffCode:string) {
    this.rawData.staffCode = staffCode;
  }

  get staffName():string {
    return this.rawData.staffName;
  }

  set staffName(staffName:string) {
    this.rawData.staffName = staffName;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }

  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }

  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  // get value field
  get stockPaidHolidayGrantId(): string {
    return this.rawData.stockPaidHolidayGrantId;
  }
  
  get grantDaysNum(): string {
    return `${this.rawData.grantDaysNum}日`;
  }

  set grantDaysNum(grantDaysNum: string) {
    this.rawData.grantDaysNum = grantDaysNum;
  }
}