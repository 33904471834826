/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import Radio from "components/molecules/Radio";

const styles = {
  selector: css({
    display: "flex",
    // marginTop: '8px',
    marginLeft: "5px",
  }),
  item: css({
    marginRight: "12px",
  }),
};

export type RadioItem = {
  value: string;
  label: string;
  children?: React.ReactNode;
};

const RadioSelector: React.FC<{
  items: Array<RadioItem>;
  name: string;
  value: string;
  readOnly?: boolean;
  noTitle?: boolean;
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addon?: any;
  disabled?: boolean;
  showTooltip?: boolean;
  itemsTooltip?: any;
  marginItem?: string;
}> = ({
  items,
  name,
  value,
  setValue,
  readOnly = false,
  noTitle = false,
  addon,
  disabled = false,
  showTooltip,
  itemsTooltip,
  marginItem,
}) => (
  <div css={css(styles.selector, noTitle ? "margin-top:0px" : "", addon)}>
    {items.map((item, id) => (
      <div css={[styles.item, { marginRight: marginItem }]} key={item.value}>
        <Radio
          id={`${name}_${String(id)}`}
          name={name}
          label={item.label}
          value={item.value}
          checked={value === item.value}
          onChange={setValue}
          readOnly={readOnly}
          disabled={disabled}
          showTooltip={showTooltip}
          note={itemsTooltip ? itemsTooltip[id] : ""}
        />
        {value === item.value && item.children && item.children}
      </div>
    ))}
  </div>
);

export default RadioSelector;
