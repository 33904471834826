/** @jsx jsx */
import React, { useState, useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import productColor, { grayScale } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import SpecialHolidayDigestionDomain from "domain/master/holidayManagement/specialHolidayDigestion";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import moment from "moment";

import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import { useAcquisitionStatusDomainForm, useDownloadHolidayManagementAcquisitionStatus } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import Calendar from "components/molecules/CalendarV1";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  detail: css({
    backgroundColor: "transparent",
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  selectDay: css({
    minWidth: "100px",
    width: "170px",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.table-bordered tr td,
      table.table-bordered tr th {
        border: 1px solid #dcdcdc;
        text-align: center;
      }
    `,
  ),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  note: css({
    paddingLeft: "5px",
    fontSize: "14px",
    color: "#666666",
    position: "relative",
  }),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
  holidayName: string;
}

const AcquisitionStatusForm: React.FC<{
  orgTreesOptions: any;
  currentAccordionList: any;
  setCurrentAccordionList: any;
}> = ({ orgTreesOptions, currentAccordionList, setCurrentAccordionList }) => {
  const {
    paidHolidayDigestedtHistoryList,
    callGetAcquisitionStatusDetail,
    orgCode,
    setOrgCode,
    date,
    searchDate,
    setSearchDate,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    underFlag,
    setUnderFlag,
  } = useAcquisitionStatusDomainForm();
  const contextObject = useContext(HolidayManagementContext);

  const { downloadHolidayManagementAcquisitionStatus, blocking } = useDownloadHolidayManagementAcquisitionStatus(
    orgCode || "",
    searchDate || date,
    underFlag,
  );

  //
  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: "",
    staffName: "",
    holidayName: "",
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTransferHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callGetAcquisitionStatusDetail(data);
    setModalOpen(true);
  };

  //
  const columns: Array<Column<SpecialHolidayDigestionDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayDigestionDomain };
          data: Array<SpecialHolidayDigestionDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "250px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayDigestionDomain };
          data: Array<SpecialHolidayDigestionDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "250px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayDigestionDomain };
          data: Array<SpecialHolidayDigestionDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "250px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
            <IconLabelButton
              onClick={() => handleTransferHolidayStatusDetail(cell.row.original)}
              text="詳細"
              isIcon={false}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayDigestionDomain };
          data: Array<SpecialHolidayDigestionDomain>;
        }) => (
          <FlexBox>
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "250px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "休暇名",
        accessor: "holidayName",
      },
      {
        Header: "合計",
        accessor: "totalStr",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "center" }}>{cell.row.original.totalStr}</div>
        ),
      },
    ],
    [handleTransferHolidayStatusDetail],
  );

  //
  return (
    <BlockUI blocking={blocking}>
      <Accordion text="取得状況" callBack={() => {}} defaultExpand={currentAccordionList.indexOf("SPECIAL_AS") !== -1}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="100%">
            <div css={styles.container}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                <div css={styles.selectDay}>
                  {/* <CalendarForm required={true} label="" date={new Date(searchDate)} dateFormat="YYYY年MM月DD日" changeDate={(date: Date) => setSearchDate(date)} /> */}
                  <Calendar
                    date={new Date(searchDate || date)}
                    setDate={(date) => {
                      if (date) {
                        setSearchDate(moment(date).format("YYYY/MM/DD"));
                      }
                    }}
                  />
                </div>
                <div css={styles.note}>から過去1年間 </div>
              </FlexBox>
            </div>
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode003"
                value={String(orgCode)}
                width="900px"
                widthLabel="100px"
                marginRight="30px"
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
              />
              <Checkbox
                id={"underFlag6"}
                name={"underFlag6"}
                label={"配下指定"}
                value={`underFlag6`}
                checked={getBoolean(underFlag)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 200px;
              }
              table th:nth-of-type(2) {
                width: 200px;
              }
              table th:nth-of-type(3) {
                width: 200px;
              }
              table th:nth-of-type(4) {
                width: 200px;
              }
              table th:nth-of-type(5) {
                width: 200px;
              }
              table th:nth-of-type(6) {
                width: 80px;
              }
            `}
          >
            {contextObject.detailRole.downloadFlag ? (
              <FormSubmitArea customStyle={styles.customStyle}>
                <IconLabelButton
                  onClick={() => downloadHolidayManagementAcquisitionStatus()}
                  iconType="download"
                  text="ダウンロード"
                />
                {/* <IconLabelButton onClick={() => downloadHolidayManagementAcquisitionStatus(true)} iconType="download" text="全組織ダウンロード" /> */}
              </FormSubmitArea>
            ) : null}
            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={paidHolidayDigestedtHistoryList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={6}
              />
            </div>
          </div>
          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="取得状況詳細"
            submitText=""
            onSubmit={closeModal}
            closeText="閉じる"
          >
            <div style={{ width: "350px", margin: "0 auto", fontSize: "14px" }} css={styles.table}>
              <VerticalLabelTextLayout label="スタッフコード" variable={dataDetail.staffCode} />
              <VerticalLabelTextLayout label="スタッフ名" variable={dataDetail.staffName} />

              <VerticalLabelTextLayout
                label="期間"
                variable={`${moment(detailItems.fromDate).format("YYYY年MM月DD日")}～${moment(
                  detailItems.toDate,
                ).format("YYYY年MM月DD日")}`}
              />
              {detailItems.effectiveStartDate && (
                <VerticalLabelTextLayout
                  label="有給付与日"
                  variable={moment(detailItems.effectiveStartDate).format("YYYY年MM月DD日")}
                />
              )}
              <VerticalLabelTextLayout label="休暇名" variable={dataDetail.holidayName} />

              <table className="table-bordered" style={{ width: "100%", fontSize: "14px" }}>
                <thead>
                  <tr>
                    <th>日付</th>
                    <th>消化日数</th>
                  </tr>
                </thead>
                <tbody>
                  {detailItems.specialHolidayDigestionDetailList &&
                    detailItems.specialHolidayDigestionDetailList.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{moment(item.targetDate).format("YYYY年MM月DD日")}</td>
                        <td>{item.digestionDaysNum} 日</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default AcquisitionStatusForm;
