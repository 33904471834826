import moment from 'moment';
/**
 * API 勤怠再計算
 */

export interface AcquisitionStatusNursingCare {
    companyCode: string,
    digestedDaysNum: any,
    digestedNum: string,
    digestedTimeHour: any,
    digestedTimesNum: any,
    endDate: any,
    holidayCode: string,
    holidayId: string,
    holidayName: string,
    holidayType: number,
    id: number,
    orgCode: string,
    orgName: string,
    staffCode: string,
    staffName: string,
    startDate: any,
}


export default class AcquisitionStatusNursingCareDomain {
  constructor(private rawData: AcquisitionStatusNursingCare) {
    // do nothing
  }

  static generateInitial(): AcquisitionStatusNursingCareDomain {
    return new AcquisitionStatusNursingCareDomain({
        companyCode: "",
        digestedDaysNum: null,
        digestedNum: "",
        digestedTimeHour: null,
        digestedTimesNum: null,
        endDate: null,
        holidayCode: "",
        holidayId: "",
        holidayName: "",
        holidayType: 0,
        id: 0,
        orgCode: "",
        orgName: "",
        staffCode: "",
        staffName: "",
        startDate: null,
    });
  }

  getRawData(): AcquisitionStatusNursingCare {
    return this.rawData;
  }

  get digestedDaysNum(): any {
    return this.rawData.digestedDaysNum;
  }

  set digestedDaysNum(digestedDaysNum: any) {
    this.rawData.digestedDaysNum = digestedDaysNum;
  }

  get digestedNum(): string {
    return this.rawData.digestedNum;
  }
  set digestedNum(digestedNum: string) {
    this.rawData.digestedNum = digestedNum;
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }
  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get digestedTimeHour(): any {
    return this.rawData.digestedTimeHour;
  }
  set digestedTimeHour(digestedTimeHour: any) {
    this.rawData.digestedTimeHour = digestedTimeHour;
  }
  get digestedTimesNum(): any {
    return this.rawData.digestedTimesNum;
  }
  set digestedTimesNum(digestedTimesNum: any) {
    this.rawData.digestedTimesNum = digestedTimesNum;
  }
  get endDate(): any {
    return this.rawData.endDate;
  }
  set endDate(endDate: any) {
    this.rawData.endDate = endDate;
  }
  get holidayCode(): string {
    return this.rawData.holidayCode;
  }
  set holidayCode(holidayCode: string) {
    this.rawData.holidayCode = holidayCode;
  }
  get holidayId(): string {
    return this.rawData.holidayId;
  }
  set holidayId(holidayId: string) {
    this.rawData.holidayId = holidayId;
  }
  get holidayName(): string {
    return this.rawData.holidayName;
  }
  set holidayName(holidayName: string) {
    this.rawData.holidayName = holidayName;
  }
  get holidayType(): number {
    return this.rawData.holidayType;
  }
  set holidayType(holidayType: number) {
    this.rawData.holidayType = holidayType;
  }
  get id(): number {
    return this.rawData.id;
  }
  set id(id: number) {
    this.rawData.id = id;
  }
  get orgCode(): string {
    return this.rawData.orgCode;
  }
  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }
  get orgName(): string {
    return this.rawData.orgName;
  }
  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }
  get staffCode(): string {
    return this.rawData.staffCode;
  }
  set staffCode(staffCode: string) {
    this.rawData.staffCode = staffCode;
  }
  get staffName(): string {
    return this.rawData.staffName;
  }
  set staffName(staffName: string) {
    this.rawData.staffName = staffName;
  }
  get startDate(): any {
    return this.rawData.startDate;
  }
  set startDate(startDate: any) {
    this.rawData.startDate = startDate;
  }
}
