/* eslint-disable max-len */
/** @jsx jsx */
import React, { useContext } from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx, SerializedStyles } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { isSafari, isIPad13, isTablet } from 'react-device-detect';
import TextForm from 'components/molecules/TextForm';
import Checkbox from 'components/molecules/Checkbox';
import { EditRoleContext } from "./../MasterRoleEditForm";
import FormField from "components/atoms/Form/FormField";
import BodyText from 'components/atoms/BodyText';
import RadioSelectFormHorizontal from 'components/molecules/RadioSelectFormHorizontal';
import { RadioItem } from 'components/molecules/RadioSelector';
import {
    ChildInput,
    GroupInput,
    DataSettingRole,
} from './../type';
import {  optionsRadio } from './../data';
import { RoleFunctionData } from './../type';
import { types } from './../actions'
import FormLabel from 'components/atoms/Form/FormLabel';

// STYLE COMPONENT
const styleSetting = {
    marginItem: '50px',
    label: css({
        width: '200px',
    }),
    labelParent: css({
        width: '200px',
        fontWeight: 'bold'
    }),
    groupRadio: css({
        border: '1px solid rgb(220, 220, 220)',
        paddingBottom: '10px',
        maxHeight: '520px',
        overflowY: 'auto',
    }),
    groupInput: css({
        paddingLeft: '20px'
    })
}
type PropsTypeRadio = {
    label: string,
    name: string,
    items: Array<RadioItem>,
    value: string,
    setValue: any,
    disabled?: boolean,
    customStyleLabel?: SerializedStyles,
};
// STYLE COMPONENT


// COMPONENT
const RadioCustom = (props: PropsTypeRadio) => (
    <RadioSelectFormHorizontal
        label={props.label}
        name={props.name}
        customStyleLabel={props.customStyleLabel}
        items={props.items}
        value={props.value}
        marginItem={styleSetting.marginItem}
        setValue={props.setValue}
        disabled={props.disabled}
    />
)

const FormFieldCustom = (props: any) => (
    <FormField paddingBottom="0px" customStyle={styleSetting.groupInput}>
        {props.children}
    </FormField>
)
// COMPONENT

const MasterRole : React.FC<{
    menuGroup: number,
    editable: number
  }> = ({
    menuGroup,
    editable
  })=> {
    // GET CONTEXT STORAGE
    const context = useContext(EditRoleContext);
    const getListPageComponent = (func: RoleFunctionData) => {
        let arrIndex = Array.from(Array(10).keys()).map(i => i+1);
        let component: any = [];
        arrIndex.map((i, index) => {
            if(func[`usabilityGeneralItem${i}Name` as keyof typeof func]){
                component.push(
                    <FormFieldCustom key={index}>
                        <RadioCustom
                            label={func[`usabilityGeneralItem${i}Name` as keyof typeof func]}
                            customStyleLabel={styleSetting.label}
                            name={`usabilityGeneralItem${i}Name${func.functionCode}`}
                            items={optionsRadio.useOption}
                            value={func[`usabilityGeneralItem${i}` as keyof typeof func] ? "1" : "0"}
                            setValue={(e: any) => {
                                context.dispatch({
                                    type: types.ON_CHANGE_RADIO , 
                                    payload: { functionMasterId: func.functionMasterId, label: `usabilityGeneralItem${i}`, data: Number(e.target.value)}})
                            }}
                            disabled={editable !== 1}
                        />
                    </FormFieldCustom>
                )
            }
        })
        return component;
    }

    const listMenuGroup = context.state.data.roleFunctinList.filter((func) => {
        return String(func.menuGroup) === String(menuGroup);
    })
    return (
        <React.Fragment>
            {
                listMenuGroup.map((item, index) => {
                    return (
                        <div key={index} css={css({marginBottom: '30px'})}>
                            <FormFieldCustom customStyle={css({marginBottom: '20px', paddingBottom: '5px'})}>
                                <RadioCustom 
                                    label={item.functionName}
                                    customStyleLabel={styleSetting.labelParent}
                                    name={`available${index}`}
                                    items={item.isDefault ? optionsRadio.defaultOption : optionsRadio.useOption}
                                    value={item.available ? "1" : "0"}
                                    setValue={(e: any) => {
                                        context.dispatch({
                                            type: types.ON_CHANGE_RADIO,
                                            payload: { functionMasterId: item.functionMasterId, label: 'available', data: e.target.value === '1'}})
                                    }}
                                    disabled={editable !== 1}
                                />
                                
                            </FormFieldCustom>
                            {   item.available && (
                                    item.useEditable === 1
                                    || [1,2].indexOf(item.useAccessRange) !== -1
                                    || item.useAccessRange === 1
                                    || item.useDownload === 1
                                    || getListPageComponent(item).length > 0
                                )
                                ? (
                                    <div css={styleSetting.groupRadio}>       
                                        {item.useEditable === 1 && (
                                            <FormFieldCustom>
                                                <RadioCustom
                                                    label="編集権限"
                                                    customStyleLabel={styleSetting.label}
                                                    name={`editable${index}`}
                                                    items={optionsRadio.editableOptions}
                                                    value={item.editable ? "1" : "0"}
                                                    setValue={(e: any) => {
                                                        context.dispatch({
                                                            type: types.ON_CHANGE_RADIO , 
                                                            payload: { functionMasterId: item.functionMasterId, label: 'editable', data: Number(e.target.value)}})
                                                    }}
                                                    disabled={editable !== 1}
                                                />
                                            </FormFieldCustom>
                                        )}
                                        {[1,2].indexOf(item.useAccessRange) !== -1 && (
                                            <FormFieldCustom>
                                                <RadioCustom
                                                    label="アクセス範囲"
                                                    customStyleLabel={styleSetting.label}
                                                    name={`accessRange${index}`}
                                                    items={item.useAccessRange === 1 ? optionsRadio.scopeOptionType1 : optionsRadio.scopeOptionType2}
                                                    value={item.accessRange ? String(item.accessRange) : "0"}
                                                    setValue={(e: any) => {
                                                        context.dispatch({
                                                            type: types.ON_CHANGE_RADIO , 
                                                            payload: { functionMasterId: item.functionMasterId, label: 'accessRange', data: Number(e.target.value)}})
                                                    }}
                                                    disabled={editable !== 1}
                                                />
                                            </FormFieldCustom>
                                        )}

                                        {item.useImport === 1 && (
                                            <FormFieldCustom>
                                                <RadioCustom
                                                    label="インポート"
                                                    customStyleLabel={styleSetting.label}
                                                    name={`importFlag${index}`}
                                                    items={optionsRadio.useOption}
                                                    value={item.importFlag ? String(item.importFlag) : "0"}
                                                    setValue={(e: any) => {
                                                        context.dispatch({
                                                            type: types.ON_CHANGE_RADIO , 
                                                            payload: { functionMasterId: item.functionMasterId, label: 'importFlag', data: Number(e.target.value)}})
                                                    }}
                                                    disabled={editable !== 1}
                                                />
                                            </FormFieldCustom>
                                        )}
                                        {item.useDownload === 1 && (
                                            <FormFieldCustom>
                                                <RadioCustom
                                                    label="印刷・ダウンロード"
                                                    customStyleLabel={styleSetting.label}
                                                    name={`downloadFlag${index}`}
                                                    items={optionsRadio.useOption}
                                                    value={item.downloadFlag ? String(item.downloadFlag) : "0"}
                                                    setValue={(e: any) => {
                                                        context.dispatch({
                                                            type: types.ON_CHANGE_RADIO , 
                                                            payload: { functionMasterId: item.functionMasterId, label: 'downloadFlag', data: Number(e.target.value)}})
                                                    }}
                                                    disabled={editable !== 1}
                                                />
                                            </FormFieldCustom>
                                        )}
                                        {getListPageComponent(item)}
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })
            }
            {/* {input?.map((groupInput: GroupInput, index: number) => {
                let a = {...context.state.data};
                return (
                    <div key={index} css={css({marginBottom: '30px'})}>
                        <FormFieldCustom customStyle={css({marginBottom: '20px', paddingBottom: '5px'})}>
                            <RadioCustom 
                                label={groupInput.label}
                                customStyleLabel={styleSetting.labelParent}
                                name={groupInput.name}
                                items={optionsRadio.useOption}
                                value={String(
                                    context.state?.data?.roleFunctinList?.find((item) => item.functionCode === groupInput.functionCode)?.available ? "1" : "0"
                                )}
                                setValue={(e: any) => {
                                    context.dispatch({
                                        type: types.ON_CHANGE_RADIO , 
                                        payload: { functionCode: groupInput.functionCode, label: 'available', data: Boolean(e.target.value)}})
                                }}
                            />
                        </FormFieldCustom>
                        {groupInput?.child?.length > 1 && (
                            <div css={styleSetting.groupRadio}>
                                {groupInput?.child?.map((item: ChildInput) => (
                                    <FormFieldCustom>
                                        <RadioCustom
                                            label={item.label}
                                            customStyleLabel={styleSetting.label}
                                            name={item.name}
                                            items={item.items}
                                            value={String(
                                                (() => {
                                                    let func = context?.state?.data?.roleFunctinList?.find((item) => item.functionCode === groupInput.functionCode);
                                                    if(func){
                                                        return func[item.name as keyof typeof func] ? "1" : "0"
                                                    }
                                                    return "0"
                                                })()
                                            )}
                                            setValue={(e: any) => {
                                                context.dispatch({
                                                    type: types.ON_CHANGE_RADIO , 
                                                    payload: { functionCode: groupInput.functionCode, label: item.name, data: Boolean(e.target.value)}})
                                            }}
                                        />
                                    </FormFieldCustom>
                                ))}
                            </div>
                        )}
                    </div>
                )
            })} */}
        </React.Fragment>
    );
};

export default MasterRole;
