/** @jsx jsx */
import React, { useState } from "react";
import Calendar from "components/molecules/Calendar";
import { jsx } from "@emotion/core";
import { ScheduleStatus } from "api/schedule";
import ScheduleEventWrapper from "./ScheduleEventWrapper";
import ScheduleMobileDateHeader from "./ScheduleMobileDateHeader";
import { ShiftMonthCalendarEvent } from "components/organismos/master/attend/HopeShiftForm/hooks";

const ScheduleMobileCalendar: React.FC<{
  events: ShiftMonthCalendarEvent[];
  date: Date;
  datePickerValue: Date;
  setDate: (date: Date) => void;
  setDatePickerValue: (date: Date) => void;
  setIsHasActionBar: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ datePickerValue, setDate, setDatePickerValue, events, setIsHasActionBar }) => {
  // Events that dont have event can't edit & ready to submit
  const configEvents = events.filter(
    (item) => !(item.hopeShift?.isEditable === false && item.hopeShift.fixStatus === ScheduleStatus.UNSUBMITED),
  );

  const newEvents = configEvents.map((item) => {
    const targetDate = new Date(item.targetDate);

    const start = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate(),
      item.start?.getHours(),
      item.start?.getMinutes(),
    );

    const end = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate(),
      item.end?.getHours(),
      item.end?.getMinutes(),
    );

    return {
      ...item,
      start: start,
      end: end,
      title: "",
    };
  });

  let previousOrientation = window.orientation;

  const [height, setHeight] = useState(previousOrientation === 0 ? "calc(100vh - 250px)" : "calc(100vh)");

  const checkOrientation = () => {
    if (window.orientation !== previousOrientation) {
      previousOrientation = window.orientation;

      if (previousOrientation === 90) {
        setHeight("calc(100vh)");
      } else {
        setHeight("calc(100vh - 250px)");
      }
    }
  };

  window.addEventListener("resize", checkOrientation, false);
  window.addEventListener("orientationchange", checkOrientation, false);

  return (
    <Calendar
      height={height}
      date={datePickerValue}
      setDate={setDatePickerValue}
      events={newEvents}
      views={["month"]}
      onDrillDown={(selectDate) => {
        setDate(selectDate);
        setIsHasActionBar(true);
      }}
      components={{
        month: {
          dateHeader: ScheduleMobileDateHeader,
        },
        eventWrapper: ScheduleEventWrapper,
        toolbar: () => <div />,
      }}
      messages={{
        showMore: (count: number) => "",
      }}
    />
  );
};

export default ScheduleMobileCalendar;
