/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ClickableLink from "components/atoms/ClickableLink";
import Accordion from "components/molecules/Accordion";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import Modal from "components/molecules/Modal";
import DataTable from "components/organismos/DataTable/DataTable";
import { grayScale } from "components/styles";
import React from "react";
import { useApplyingTransferForm } from "../ApplyingTab/hook";
import ApprovedStampDomain from "../domain/approvedStampDomain";

const StampAccordion: React.FC<{ list: ApprovedStampDomain[] }> = ({ list }) => {
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLog,
  } = useApplyingTransferForm(list);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: '承認者',
      //   accessor: 'authorizer',
      // },
      {
        Header: "承認日時",
        accessor: "approvalDate",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: () => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <div>{cell.row.original.targetDate}</div>
            <ClickableLink
              customCss={css({ textAlign: "center" })}
              onClick={() => {
                fetchChangeLog(cell.row.original.modifierApplicationId);
                setChangeLogModalOpen(true);
                setSelectChangelog("0");
              }}
            >
              更新履歴
            </ClickableLink>
          </div>
        ),
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "出勤組織",
        accessor: "stampOrgName",
      },
      {
        Header: "開始時間", // starttime
        accessor: "stampListStartTime", // .startTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>{cell.row.original.stampListStartTime}</div>
        ),
      },
      {
        Header: "終了時間", // endtime
        accessor: "stampListEndTime", // .endTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>
            {cell.row.original.stampListEndTime && cell.row.original.stampListEndTime}
          </div>
        ),
      },
      // {
      //   Header: '業務内容', // business content
      //   accessor: 'stampListBusinessName', // .businessName
      //   Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
      //     <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
      //       {cell.row.original.stampListBusinessName}
      //     </div>
      //   ),
      // },
      {
        Header: "申請理由",
        accessor: "applicationReason",
      },
    ],
    [fetchChangeLog, setChangeLogModalOpen, setSelectChangelog],
  );

  return (
    <div
      css={css`
        table th:nth-of-type(1) {
          width: 120px;
        }
        table th:nth-of-type(2) {
          width: 140px;
        }
        table th:nth-of-type(3) {
          width: 120px;
        }
        table th:nth-of-type(4) {
          width: 140px;
        }
        table th:nth-of-type(5) {
          width: 80px;
        }
        table th:nth-of-type(6) {
          width: 80px;
        }
        table th:nth-of-type(7) {
          width: 140px;
        }
        table th:nth-of-type(8) {
          width: 140px;
        }
        table th:nth-of-type(9) {
          width: 140px;
        }
        table th:nth-of-type(10) {
          width: 200px;
        }
      `}
    >
      <Accordion text={`打刻修正申請 (${list.length}件）`} callBack={() => {}}>
        <DataTable
          columns={columns}
          containerStyle={css({
            overflowX: "auto",
            marginTop: "15px",
          })}
          data={list}
          isGlobalFilter={true}
          sortBy={[{ id: "targetDate", desc: true }]}
        />
      </Accordion>
      {/* Change log  */}
      <div css={css(">div>div{ width: 800px }")}>
        <Modal
          closeHandler={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          open={changeLogModalOpen}
          title="更新履歴"
          submitText="閉じる"
          onSubmit={() => {
            setChangeLogModalOpen(false);
            setChangeLogData([]);
          }}
          closeText=""
        >
          <ChangeLogModal changeLogData={changeLogData} isStamp={true} />
        </Modal>
      </div>
      {/* ./Change log  */}
    </div>
  );
};

export default StampAccordion;
