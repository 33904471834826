/** @jsx jsx */
import React, {useEffect} from "react";
import { css, jsx } from "@emotion/core";
import PrimaryButton from "components/atoms/Button";
import { useHistory } from "react-router-dom";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import useExecutiveCsvForm from './hooks'
import moment from "moment";

const isIpad = window.matchMedia("(max-width: 1024px)").matches;

const dataTable = css`
  margin: 24px 0 50px 0;
  table tbody td,
  table thead th {
    text-align: left;
    padding-left: ${isIpad ? "10px" : "30px"};
  }
  table thead th {
    font-weight: bold;
  }
  table tr td,
  table tr th {
    &:first-of-type {
      border-left: 0 !important;
    }
    &:last-of-type {
      border-right: 0 !important;
    }
  }
`;

const ExecutiveImportCSV: React.FC = () => {
  const history = useHistory();

  const { fetchHistoryImport, historyImport } = useExecutiveCsvForm();

  useEffect(() => {   
    fetchHistoryImport();
  }, [fetchHistoryImport])

  const columns: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: "ファイル名",
        accessor: "importFileName",
      },
      {
        Header: () => "処理結果",
        accessor: "result",
        id: "result",
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => cell.row.original.result === 0 ? "成功" : "失敗"
      },
      {
        Header: "エラー内容",
        accessor: "errDetail",
      },
      {
        Header: () => "実施日時",
        accessor: "updateDate",
        id: "updateDate",
        Cell: (cell: {
          row: { isExpanded: boolean; original: any };
          page: [];
        }) => moment(cell.row.original.updateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD HH:mm:ss')
      },
      {
        Header: "実施者",
        accessor: "updateUser",
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div css={dataTable}>
        <DataTable columns={columns} data={historyImport} isGlobalFilter={false} />
        <div style={{ width: "100%", textAlign: "center", margin: "70px 0 35px 0" }}>
          <PrimaryButton
            text="戻る"
            ghost={true}
            // eslint-disable-next-line no-restricted-globals
            onClick={() => history.push("/executive")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExecutiveImportCSV;
