/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useEffect, useState } from "react";
import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import FormLabel from "components/atoms/Form/FormLabel";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import HourInput from "components/atoms/Form/HourInput";
import FlexBox from "components/atoms/FlexBox";
import FormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import { gridCol } from "components/styles";
import Checkbox from "components/molecules/Checkbox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { OptionType } from "components/atoms/Select";
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import FormHelp from "components/atoms/Form/FormHelp";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const styles = {
  span: css({
    margin: "0px 10px",
    position: "relative",
    top: "-2px",
  }),
  wrapInput: css({
    maxWidth: "250px",
  }),
  label: css({
    marginTop: "10px",
  }),
  note: css({
    paddingBottom: "7px",
    color: "red",
    display: "block",
  }),
};

const FLEX_TIME = 3;

const listofWorkNotes = [
  "毎日の所定労働時間を管理者が設定し、日、週ごとに時間外労働を計算します。<br>休日労働時間、深夜労働時間も計算されます。",
  "毎日の所定労働時間を管理者が設定しますが、固定労働時間制とは異なり、所定労働時間を8時間を超えて設定することが可能です。<br>日、週、月で時間外労働を計算します。休日労働、深夜労働も計算されます。",
  "1日あたりのみなし労働時間を定め、実働時間にかかわらず、みなし労働時間分働いていたとみなします。<br>ただし、勤務実能を把握するため、実際の実働時間も記録されます。<br>時間外労働時間は労働日以外の日に労働をした場合のみ計上される場合があります。<br>休日労働時間、深夜労働時間も計算されます。",
  "毎日の労働時間に関してはコアタイムを定めますが1日にどれだけ労働するかはスタッフに委ねられます。<br>その月の総労働時間から、時間外労働時間を計算します。<br>休日労働時間、深夜労働時間も計算されます。",
];

const EmploymentWorkSystemAndBreakSettings: React.FC<{
  formik: any;
}> = ({ formik }) => {
  const autoRecessItems = [];
  const recessNum = 3;

  for (let i = 0; i < recessNum; ) {
    autoRecessItems.push(i);
    i += 1;
  }

  const [deemedWorkingTimeHour, setDeemedWorkingTimeHour] = useState("0");
  const [deemedWorkingTimeMinute, setDeemedWorkingTimeMinute] = useState("0");

  // Deem Times
  useEffect(() => {
    setDeemedWorkingTimeHour(
      formik.values.deemedWorkingHours ? String(Math.floor(formik.values.deemedWorkingHours / 60)) : "0",
    );
    setDeemedWorkingTimeMinute(formik.values.deemedWorkingHours ? String(formik.values.deemedWorkingHours % 60) : "0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deemedWorkingTimeHour && deemedWorkingTimeMinute) {
      let times = Number(deemedWorkingTimeHour) * 60 + Number(deemedWorkingTimeMinute);
      formik.setFieldValue("deemedWorkingHours", times);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deemedWorkingTimeHour, deemedWorkingTimeMinute]);

  useEffect(() => {
    if (String(formik.values.workSystem) !== "2") {
      formik.setFieldValue("deemedWorkingHours", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.workSystem]);

  const hourStartDefault = formik.values.startTimeMoment.format("HH") || "00:00";
  const timeStartDefault = formik.values.startTimeMoment.format("mm") || "00:00";
  const hourEndDefault = formik.values.endTimeMoment.format("HH") || "00:00";
  const timeEndDefault = formik.values.endTimeMoment.format("mm") || "00:00";

  const hoursRecessStartTimeDefault = formik.values.recessStartTime1Moment.format("HH") || "00:00";
  const timeRecessStartTimeDefault = formik.values.recessStartTime1Moment.format("mm") || "00:00";

  const hourRecessEndTimeDefault = formik.values.recessEndTime1Moment.format("HH") || "00:00";
  const timeRecessEndTimeDefault = formik.values.recessEndTime1Moment.format("mm") || "00:00";

  const hoursRecessStartTime2Default = formik.values.recessStartTime2Moment.format("HH") || "00:00";
  const timeRecessStartTime2Default = formik.values.recessStartTime2Moment.format("mm") || "00:00";

  const hourRecessEndTime2Default = formik.values.recessEndTime2Moment.format("HH") || "00:00";
  const timeRecessEndTime2Default = formik.values.recessEndTime2Moment.format("mm") || "00:00";

  const hoursRecessStartTime3Default = formik.values.recessStartTime3Moment.format("HH") || "00:00";
  const timeRecessStartTime3Default = formik.values.recessStartTime3Moment.format("mm") || "00:00";

  const hourRecessEndTime3Default = formik.values.recessEndTime3Moment.format("HH") || "00:00";
  const timeRecessEndTime3Default = formik.values.recessEndTime3Moment.format("mm") || "00:00";

  const [hourStart, setHourStart] = useState(hourStartDefault);
  const [timeStart, setTimeStart] = useState(timeStartDefault);

  const [hourEnd, setHourEnd] = useState(hourEndDefault);
  const [timeEnd, setTimeEnd] = useState(timeEndDefault);

  const [recessStartHour, setRecessStartHour] = useState(hoursRecessStartTimeDefault);
  const [recessStartTime, setRecessStartTime] = useState(timeRecessStartTimeDefault);

  const [recessEndHour, setRecessEndHour] = useState(hourRecessEndTimeDefault);
  const [recessEndTime, setRecessEndTime] = useState(timeRecessEndTimeDefault);

  const [recessStartHour2, setRecessStartHour2] = useState(hoursRecessStartTime2Default);
  const [recessStartTime2, setRecessStartTime2] = useState(timeRecessStartTime2Default);

  const [recessEndHour2, setRecessEndHour2] = useState(hourRecessEndTime2Default);
  const [recessEndTime2, setRecessEndTime2] = useState(timeRecessEndTime2Default);

  const [recessStartHour3, setRecessStartHour3] = useState(hoursRecessStartTime3Default);
  const [recessStartTime3, setRecessStartTime3] = useState(timeRecessStartTime3Default);

  const [recessEndHour3, setRecessEndHour3] = useState(hourRecessEndTime3Default);
  const [recessEndTime3, setRecessEndTime3] = useState(timeRecessEndTime3Default);

  return (
    <React.Fragment>
      <FormTitle title="勤務体系・休憩設定" bold={true} customStyle={css({ marginLeft: "0" })} />
      <div>
        <FormField>
          <RadioSelectForm
            label="勤務体系"
            name="workSystem"
            items={[
              {
                label: "標準",
                value: "0",
              },
              {
                label: "変形労働",
                value: "1",
              },
              {
                label: "裁量労働",
                value: "2",
              },
              {
                label: "フレックスタイム制",
                value: "3",
              },
            ]}
            itemsTooltip={listofWorkNotes}
            value={String(formik.values.workSystem)}
            setValue={(e) => {
              formik.setFieldValue("workSystem", e.target.value);
              if (e.target.value === "3") {
                formik.setFieldValue("useOverTimeApplication", "0");
              }
            }}
            showTooltip={true}
          />
        </FormField>

        <div style={[0, FLEX_TIME].includes(Number(formik.values.workSystem)) ? {} : { display: "none" }}>
          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: gridCol.grid12 }}>
                <FormLabel label={Number(formik.values.workSystem) === 3 ? "コアタイム" : "標準勤務時間"} />
              </div>
              {Number(formik.values.workSystem) === 3 && (
                <div style={{ width: gridCol.grid12 }}>
                  <FormHelp customStyle={styles.note} content="※ コアタイムを設定すると遅刻/早退の計算を行います。" />
                </div>
              )}

              <div style={{ float: "left" }}>
                <FlexBox>
                  {Number(formik.values.workSystem) === 3 && (
                    <FlexBoxItem width="45px">
                      <BodyText>開始</BodyText>
                    </FlexBoxItem>
                  )}

                  <FlexBoxItem width="70px">
                    <VerticalLabelSelectForm
                      label=""
                      name="startTime"
                      value={String(hourStart)}
                      setValue={(val: string) => {
                        setHourStart(val);
                        formik.setFieldValue("startTime", val ? val + ":" + timeStart : "00:00");
                      }}
                      options={hourFormOptions}
                    />
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <span css={styles.span}>:</span>
                  </FlexBoxItem>
                  <FlexBoxItem>
                    <FlexBox>
                      <VerticalLabelSelectForm
                        label=""
                        name="startTime2"
                        value={String(timeStart)}
                        setValue={(val: string) => {
                          setTimeStart(val);
                          formik.setFieldValue("startTime", val ? hourStart + ":" + val : "00:00");
                        }}
                        width="70px"
                        options={timeFormOptions}
                      />
                      {Number(formik.values.workSystem) === 3 && (
                        <span css={css({ margin: "0px 15px", color: "#666666" })}>時</span>
                      )}
                    </FlexBox>
                  </FlexBoxItem>
                </FlexBox>
              </div>
              {Number(formik.values.workSystem) !== 3 && (
                <div
                  style={{
                    width: "55px",
                    float: "left",
                    position: "relative",
                    top: "11px",
                    left: "11px",
                  }}
                >
                  <Checkbox
                    id="isStartTimeNextDay"
                    name="isStartTimeNextDay"
                    label="翌日"
                    value={String(formik.values.isStartTimeNextDay)}
                    checked={Boolean(formik.values.isStartTimeNextDay)}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
              <div
                style={
                  Number(formik.values.workSystem) === 3
                    ? {
                        width: "40px",
                        float: "left",
                        textAlign: "center",
                        position: "relative",
                        top: "13px",
                        marginLeft: "-12px",
                      }
                    : { width: "70px", float: "left", textAlign: "center", position: "relative", top: "11px" }
                }
              >
                {" "}
                〜{" "}
              </div>
              <div style={{ float: "left" }}>
                <FlexBox>
                  {Number(formik.values.workSystem) === 3 && (
                    <FlexBoxItem width="45px">
                      <BodyText>終了</BodyText>
                    </FlexBoxItem>
                  )}

                  <FlexBoxItem>
                    <VerticalLabelSelectForm
                      label=""
                      name="endTime"
                      value={String(hourEnd)}
                      width="70px"
                      setValue={(val: string) => {
                        setHourEnd(val);
                        formik.setFieldValue("endTime", val ? val + ":" + timeEnd : "00:00");
                      }}
                      options={hourFormOptions}
                    />
                  </FlexBoxItem>
                  <span css={styles.span}>:</span>
                  <FlexBoxItem>
                    <FlexBox>
                      <VerticalLabelSelectForm
                        label=""
                        name="endTime"
                        value={String(timeEnd)}
                        setValue={(val: string) => {
                          setTimeEnd(val);
                          formik.setFieldValue("endTime", val ? hourEnd + ":" + val : "00:00");
                        }}
                        width="70px"
                        options={timeFormOptions}
                      />
                      {Number(formik.values.workSystem) === 3 && (
                        <span css={css({ margin: "0px 15px", color: "#666666" })}>時</span>
                      )}
                    </FlexBox>
                  </FlexBoxItem>
                </FlexBox>
              </div>
              {Number(formik.values.workSystem) !== 3 && (
                <div
                  style={{
                    width: "55px",
                    float: "left",
                    position: "relative",
                    top: "11px",
                    left: "11px",
                  }}
                >
                  <Checkbox
                    id="isEndTimeNextDay"
                    name="isEndTimeNextDay"
                    label="翌日"
                    value={String(formik.values.isEndTimeNextDay)}
                    checked={Boolean(formik.values.isEndTimeNextDay)}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
            </div>
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === "2" ? {} : { display: "none" }}>
          <FormLabel label="1日のみなし労働時間" />
          <div style={{ display: "inline-flex", width: "100%", paddingBottom: "10px" }}>
            <HourInput
              name="deemedWorkingTimeHour"
              value={deemedWorkingTimeHour}
              label="時間"
              onChange={(e) => {
                setDeemedWorkingTimeHour(e.target.value);
              }}
              min={0}
              max={24}
            />
            <HourInput
              name="deemedWorkingTimeMinute"
              value={deemedWorkingTimeMinute}
              label="分"
              onChange={(e) => {
                setDeemedWorkingTimeMinute(e.target.value);
              }}
              min={0}
              max={59}
            />
          </div>
        </div>

        <div
          style={
            [0, FLEX_TIME].includes(Number(formik.values.workSystem)) ? { width: gridCol.grid06 } : { display: "none" }
          }
        >
          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <VerticalLabelSelectForm
                label="自動休憩機能"
                name="useAutoRecess"
                options={[
                  {
                    label: "自動で休憩を取得しない(打刻休憩のみ有効)",
                    value: "0",
                  },
                  {
                    label: "自動で休憩を取得する",
                    value: "1",
                  },
                  {
                    label:
                      Number(formik.values.workSystem) === 3
                        ? "フレックス勤務休憩を使用する"
                        : "標準勤務の休憩を使用する",
                    value: "2",
                  },
                ]}
                value={String(formik.values.useAutoRecess)}
                setValue={(val: any) => formik.setFieldValue("useAutoRecess", val)}
                isSearchable={false}
              />
            </div>
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === "1" ? {} : { display: "none" }}>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="自動休憩機能"
                name="useAutoRecess"
                options={[
                  {
                    label: "自動で休憩を取得しない(打刻休憩のみ有効)",
                    value: "0",
                  },
                  {
                    label: "自動で休憩を取得する",
                    value: "1",
                  },
                  {
                    label: "シフトの休憩を使用する",
                    value: "2",
                  },
                ]}
                value={String(formik.values.useAutoRecess)}
                setValue={(val: any) => formik.setFieldValue("useAutoRecess", val)}
                isSearchable={false}
              />
            </div>
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === "2" ? {} : { display: "none" }}>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="自動休憩機能"
                name="useAutoRecess"
                options={[
                  {
                    label: "自動で休憩を取得しない(打刻休憩のみ有効)",
                    value: "0",
                  },
                  {
                    label: "自動で休憩を取得する",
                    value: "1",
                  },
                ]}
                value={String(formik.values.useAutoRecess)}
                setValue={(val: any) => formik.setFieldValue("useAutoRecess", val)}
                isSearchable={false}
              />
            </div>
          </FormField>
        </div>

        {(String(formik.values.useAutoRecess) === "1" ||
          (String(formik.values.workSystem) === "1" && String(formik.values.useAutoRecess) === "2")) && (
          <React.Fragment>
            <FormField>
              <RadioSelectForm
                label="打刻修正申請画面で自動休憩無効を許可"
                name="isDisplayAutoBreak"
                items={[
                  {
                    label: "しない",
                    value: "0",
                  },
                  {
                    label: "する",
                    value: "1",
                  },
                ]}
                value={String(formik.values.isDisplayAutoBreak === true ? 1 : 0)}
                setValue={(e) => {
                  formik.setFieldValue("isDisplayAutoBreak", e.target.value === "1" ? true : false);
                }}
                showTooltip={true}
              />
              <span></span>
            </FormField>
            <FormHelp
              customStyle={styles.note}
              content="既に自動休憩を無効で申請しているデータについては、自動休憩が反映されていないので、再度打刻修正を申請してください。"
            />
          </React.Fragment>
        )}

        <div style={String(formik.values.useAutoRecess) === "1" ? {} : { display: "none" }}>
          <FormField displayBlock={true}>
            {autoRecessItems.map((value, key) => (
              <div key={key} className="col col-lg-6" style={{ marginRight: "12px", marginTop: "15px" }}>
                <FormLayout
                  label={<FormLabel label={`自動休憩${String(value + 1)}`} />}
                  input={
                    <FlexBox>
                      <FormLabel label="労働時間が" customStyle={styles.label} />
                      <div style={{ width: "250px", margin: "0 10px" }}>
                        <HourInput
                          name={`autoRecessWorkConditionTime${String(value + 1)}`}
                          value={
                            value === 0
                              ? formik.values.autoRecessWorkConditionTime1
                              : value === 1
                              ? formik.values.autoRecessWorkConditionTime2
                              : formik.values.autoRecessWorkConditionTime3
                          }
                          label="分を超える場合は"
                          min={0}
                          max={1440}
                          widthInput="100px"
                          wrapInput={styles.wrapInput}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div style={{ width: "250px", margin: "0 10px" }}>
                        <HourInput
                          name={`autoRecessGrantTime${String(value + 1)}`}
                          value={
                            value === 0
                              ? formik.values.autoRecessGrantTime1
                              : value === 1
                              ? formik.values.autoRecessGrantTime2
                              : formik.values.autoRecessGrantTime3
                          }
                          label="分の休憩を与える"
                          min={0}
                          max={1440}
                          widthInput="100px"
                          wrapInput={styles.wrapInput}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </FlexBox>
                  }
                />
              </div>
            ))}
          </FormField>

          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="自動休憩を引く項目の優先順位"
                name="autoRecessPriorityOfSubtract"
                options={[
                  {
                    label: "深夜 > 普通",
                    value: "1",
                  },
                  {
                    label: "普通 > 深夜",
                    value: "0",
                  },
                ]}
                value={String(formik.values.autoRecessPriorityOfSubtract)}
                setValue={(val) => formik.setFieldValue("autoRecessPriorityOfSubtract", val)}
                isSearchable={false}
              />
            </div>
          </FormField>
          <FormField>
            <div style={{ width: gridCol.grid06 }}>
              <VerticalLabelSelectForm
                label="打刻休憩と自動休憩の重複時"
                name="autoRecessDuplication"
                value={String(formik.values.autoRecessDuplication)}
                setValue={(val: string) => formik.setFieldValue("autoRecessDuplication", val)}
                options={[
                  {
                    label: "打刻休憩のみを休憩時間とする",
                    value: "0",
                  },
                  {
                    label: "自動休憩を休憩時間とする",
                    value: "1",
                  },
                  {
                    label: "打刻休憩と自動休憩を合算したものを休憩時間とする",
                    value: "2",
                  },
                ]}
                isSearchable={false}
              />
            </div>
          </FormField>
        </div>
      </div>

      {/* FlexTime */}
      <div
        style={
          String(formik.values.workSystem) === "3" && String(formik.values.useAutoRecess) === "2"
            ? {}
            : { display: "none" }
        }
      >
        <FormField>
          <div style={{ width: gridCol.grid12 }}>
            <div style={{ width: gridCol.grid12 }}>
              <FormLabel label={"フレックス勤務休憩時間1"} />
            </div>
            <div style={{ width: "200px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessStartTime1"
                    value={String(recessStartHour)}
                    setValue={(val: string) => {
                      setRecessStartHour(val);
                      formik.setFieldValue("recessStartTime1", val ? val + ":" + recessStartTime : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="startTime2"
                    value={String(recessStartTime)}
                    setValue={(val: string) => {
                      setRecessStartTime(val);
                      formik.setFieldValue("recessStartTime1", val ? recessStartHour + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "-15px",
              }}
            >
              <Checkbox
                id="isRecessStartTimeNextDay1"
                name="isRecessStartTimeNextDay1"
                label="翌日"
                value={String(formik.values.isRecessStartTimeNextDay1)}
                checked={Boolean(formik.values.isRecessStartTimeNextDay1)}
                onChange={formik.handleChange}
              />
            </div>
            <div style={{ width: "30px", float: "left", margin: "12px" }}> 〜 </div>
            <div style={{ width: "150px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime1"
                    value={String(recessEndHour)}
                    setValue={(val: string) => {
                      setRecessEndHour(val);
                      formik.setFieldValue("recessEndTime1", val ? val + ":" + recessEndTime : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime2"
                    value={String(recessEndTime)}
                    setValue={(val: string) => {
                      setRecessEndTime(val);
                      formik.setFieldValue("recessEndTime1", val ? recessEndHour + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "15px",
              }}
            >
              <Checkbox
                id="isRecessEndTimeNextDay1"
                name="isRecessEndTimeNextDay1"
                label="翌日"
                value={String(formik.values.isRecessEndTimeNextDay1)}
                checked={Boolean(formik.values.isRecessEndTimeNextDay1)}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid12 }}>
            <div style={{ width: gridCol.grid12 }}>
              <FormLabel label={"フレックス勤務休憩時間2"} />
            </div>
            <div style={{ width: "200px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessStartTime2"
                    value={String(recessStartHour2)}
                    setValue={(val: string) => {
                      setRecessStartHour2(val);
                      formik.setFieldValue("recessStartTime2", val ? val + ":" + recessStartTime2 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="startTime2"
                    value={String(recessStartTime2)}
                    setValue={(val: string) => {
                      setRecessStartTime2(val);
                      formik.setFieldValue("recessStartTime2", val ? recessStartHour2 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "-15px",
              }}
            >
              <Checkbox
                id="isRecessStartTimeNextDay2"
                name="isRecessStartTimeNextDay2"
                label="翌日"
                value={String(formik.values.isRecessStartTimeNextDay2)}
                checked={Boolean(formik.values.isRecessStartTimeNextDay2)}
                onChange={formik.handleChange}
              />
            </div>
            <div
              style={{
                width: "30px",
                float: "left",
                margin: "12px",
              }}
            >
              〜
            </div>
            <div style={{ width: "150px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime2"
                    value={String(recessEndHour2)}
                    setValue={(val: string) => {
                      setRecessEndHour2(val);
                      formik.setFieldValue("recessEndTime2", val ? val + ":" + recessEndTime2 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime2"
                    value={String(recessEndTime2)}
                    setValue={(val: string) => {
                      setRecessEndTime2(val);
                      formik.setFieldValue("recessEndTime2", val ? recessEndHour2 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "15px",
              }}
            >
              <Checkbox
                id="isRecessEndTimeNextDay2"
                name="isRecessEndTimeNextDay2"
                label="翌日"
                value={String(formik.values.isRecessEndTimeNextDay2)}
                checked={Boolean(formik.values.isRecessEndTimeNextDay2)}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid12 }}>
            <div style={{ width: gridCol.grid12 }}>
              <FormLabel label={"フレックス勤務休憩時間3"} />
            </div>
            <div style={{ width: "200px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessStartTime3"
                    value={String(recessStartHour3)}
                    setValue={(val: string) => {
                      setRecessStartHour3(val);
                      formik.setFieldValue("recessStartTime3", val ? val + ":" + recessStartTime3 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="startTime3"
                    value={String(recessStartTime3)}
                    setValue={(val: string) => {
                      setRecessStartTime3(val);
                      formik.setFieldValue("recessStartTime3", val ? recessStartHour3 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "-15px",
              }}
            >
              <Checkbox
                id="isRecessStartTimeNextDay3"
                name="isRecessStartTimeNextDay3"
                label="翌日"
                value={String(formik.values.isRecessStartTimeNextDay3)}
                checked={Boolean(formik.values.isRecessStartTimeNextDay3)}
                onChange={formik.handleChange}
              />
            </div>
            <div
              style={{
                width: "30px",
                float: "left",
                margin: "12px",
              }}
            >
              〜
            </div>
            <div style={{ width: "150px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime3"
                    value={String(recessEndHour3)}
                    setValue={(val: string) => {
                      setRecessEndHour3(val);
                      formik.setFieldValue("recessEndTime3", val ? val + ":" + recessEndTime3 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime3"
                    value={String(recessEndTime3)}
                    setValue={(val: string) => {
                      setRecessEndTime3(val);
                      formik.setFieldValue("recessEndTime3", val ? recessEndHour3 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "15px",
              }}
            >
              <Checkbox
                id="isRecessEndTimeNextDay3"
                name="isRecessEndTimeNextDay3"
                label="翌日"
                value={String(formik.values.isRecessEndTimeNextDay3)}
                checked={Boolean(formik.values.isRecessEndTimeNextDay3)}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </FormField>
      </div>

      {/* Coretime */}
      <div
        style={
          String(formik.values.workSystem) === "0" && String(formik.values.useAutoRecess) === "2"
            ? {}
            : { display: "none" }
        }
      >
        <FormField>
          <div style={{ width: gridCol.grid12 }}>
            <div style={{ width: gridCol.grid12 }}>
              <FormLabel
                label={
                  Number(formik.values.workSystem) === FLEX_TIME ? "フレックス勤務休憩時間1" : "標準勤務休憩時間1"
                }
              />
            </div>
            <div style={{ width: "200px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessStartTime1"
                    value={String(recessStartHour)}
                    setValue={(val: string) => {
                      setRecessStartHour(val);
                      formik.setFieldValue("recessStartTime1", val ? val + ":" + recessStartTime : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="startTime2"
                    value={String(recessStartTime)}
                    setValue={(val: string) => {
                      setRecessStartTime(val);
                      formik.setFieldValue("recessStartTime1", val ? recessStartHour + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "-15px",
              }}
            >
              <Checkbox
                id="isRecessStartTimeNextDay1"
                name="isRecessStartTimeNextDay1"
                label="翌日"
                value={String(formik.values.isRecessStartTimeNextDay1)}
                checked={Boolean(formik.values.isRecessStartTimeNextDay1)}
                onChange={formik.handleChange}
              />
            </div>
            <div style={{ width: "30px", float: "left", margin: "12px" }}> 〜 </div>
            <div style={{ width: "150px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime1"
                    value={String(recessEndHour)}
                    setValue={(val: string) => {
                      setRecessEndHour(val);
                      formik.setFieldValue("recessEndTime1", val ? val + ":" + recessEndTime : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime2"
                    value={String(recessEndTime)}
                    setValue={(val: string) => {
                      setRecessEndTime(val);
                      formik.setFieldValue("recessEndTime1", val ? recessEndHour + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "15px",
              }}
            >
              <Checkbox
                id="isRecessEndTimeNextDay1"
                name="isRecessEndTimeNextDay1"
                label="翌日"
                value={String(formik.values.isRecessEndTimeNextDay1)}
                checked={Boolean(formik.values.isRecessEndTimeNextDay1)}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid12 }}>
            <div style={{ width: gridCol.grid12 }}>
              <FormLabel
                label={
                  Number(formik.values.workSystem) === FLEX_TIME ? "フレックス勤務休憩時間2" : "標準勤務休憩時間2"
                }
              />
            </div>
            <div style={{ width: "200px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessStartTime2"
                    value={String(recessStartHour2)}
                    setValue={(val: string) => {
                      setRecessStartHour2(val);
                      formik.setFieldValue("recessStartTime2", val ? val + ":" + recessStartTime2 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="startTime2"
                    value={String(recessStartTime2)}
                    setValue={(val: string) => {
                      setRecessStartTime2(val);
                      formik.setFieldValue("recessStartTime2", val ? recessStartHour2 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "-15px",
              }}
            >
              <Checkbox
                id="isRecessStartTimeNextDay2"
                name="isRecessStartTimeNextDay2"
                label="翌日"
                value={String(formik.values.isRecessStartTimeNextDay2)}
                checked={Boolean(formik.values.isRecessStartTimeNextDay2)}
                onChange={formik.handleChange}
              />
            </div>
            <div
              style={{
                width: "30px",
                float: "left",
                margin: "12px",
              }}
            >
              〜
            </div>
            <div style={{ width: "150px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime2"
                    value={String(recessEndHour2)}
                    setValue={(val: string) => {
                      setRecessEndHour2(val);
                      formik.setFieldValue("recessEndTime2", val ? val + ":" + recessEndTime2 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime2"
                    value={String(recessEndTime2)}
                    setValue={(val: string) => {
                      setRecessEndTime2(val);
                      formik.setFieldValue("recessEndTime2", val ? recessEndHour2 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "15px",
              }}
            >
              <Checkbox
                id="isRecessEndTimeNextDay2"
                name="isRecessEndTimeNextDay2"
                label="翌日"
                value={String(formik.values.isRecessEndTimeNextDay2)}
                checked={Boolean(formik.values.isRecessEndTimeNextDay2)}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </FormField>
        <FormField>
          <div style={{ width: gridCol.grid12 }}>
            <div style={{ width: gridCol.grid12 }}>
              <FormLabel
                label={
                  Number(formik.values.workSystem) === FLEX_TIME ? "フレックス勤務休憩時間3" : "標準勤務休憩時間3"
                }
              />
            </div>
            <div style={{ width: "200px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessStartTime3"
                    value={String(recessStartHour3)}
                    setValue={(val: string) => {
                      setRecessStartHour3(val);
                      formik.setFieldValue("recessStartTime3", val ? val + ":" + recessStartTime3 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="startTime3"
                    value={String(recessStartTime3)}
                    setValue={(val: string) => {
                      setRecessStartTime3(val);
                      formik.setFieldValue("recessStartTime3", val ? recessStartHour3 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "-15px",
              }}
            >
              <Checkbox
                id="isRecessStartTimeNextDay3"
                name="isRecessStartTimeNextDay3"
                label="翌日"
                value={String(formik.values.isRecessStartTimeNextDay3)}
                checked={Boolean(formik.values.isRecessStartTimeNextDay3)}
                onChange={formik.handleChange}
              />
            </div>
            <div
              style={{
                width: "30px",
                float: "left",
                margin: "12px",
              }}
            >
              〜
            </div>
            <div style={{ width: "150px", float: "left" }}>
              <FlexBox>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime3"
                    value={String(recessEndHour3)}
                    setValue={(val: string) => {
                      setRecessEndHour3(val);
                      formik.setFieldValue("recessEndTime3", val ? val + ":" + recessEndTime3 : "00:00");
                    }}
                    options={hourFormOptions}
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <span css={styles.span}>:</span>
                </FlexBoxItem>
                <FlexBoxItem width="70px">
                  <VerticalLabelSelectForm
                    label=""
                    name="recessEndTime3"
                    value={String(recessEndTime3)}
                    setValue={(val: string) => {
                      setRecessEndTime3(val);
                      formik.setFieldValue("recessEndTime3", val ? recessEndHour3 + ":" + val : "00:00");
                    }}
                    options={timeFormOptions}
                  />
                </FlexBoxItem>
              </FlexBox>
            </div>
            <div
              style={{
                width: "55px",
                float: "left",
                marginTop: "12px",
                marginLeft: "15px",
              }}
            >
              <Checkbox
                id="isRecessEndTimeNextDay3"
                name="isRecessEndTimeNextDay3"
                label="翌日"
                value={String(formik.values.isRecessEndTimeNextDay3)}
                checked={Boolean(formik.values.isRecessEndTimeNextDay3)}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </FormField>
      </div>
    </React.Fragment>
  );
};

export default EmploymentWorkSystemAndBreakSettings;
