/* eslint-disable max-len */
/** @jsx jsx */
import React, { CSSProperties, useEffect } from 'react';
import { css, jsx } from "@emotion/core";
import ReactSelect, { OptionTypeBase } from 'react-select';
import { ValueType, MenuPlacement } from 'react-select/src/types';
import { createPortal } from "react-dom";
import productColor, { textFontSize, textColor, grayScale } from 'components/styles';
import { Portal } from "react-portal";

export interface OptionType extends OptionTypeBase {
  value: string,
  label: string,
}

const SelectPortal: React.FC<{
  name: string;
  options: Array<OptionType>;

  /**
   * 単数選択の場合の値
   * (isMulti=falseの場合は必須)
   */
  value?: string;
  setValue?: (val: string) => void;

  /**
   * 複数選択の場合の値
   * (isMulti=trueの場合は必須)
   */
  values?: Array<string>;
  setValues?: (vals: Array<string>) => void;
  isMulti?: boolean;

  /**
   * 読み取り専用
   */
  readOnly?: boolean;

  /**
   * 無効設定
   */
  isDisabled?: boolean;

  /**
   * placeHolderを指定する場合
   * デフォルトは'選択してください'
   */
  placeholder?: string;

  /**
   * optionがない場合の表示メッセージ
   * デフォルトは'選択できる項目はありません'
   */
  noOptionMessage?: string;

  /**
   * 幅
   */
  width?: string;

  /**
   * cssのカスタマイズ
   */
  controlStyle?: CSSProperties;

  /**
   * メニューの表示位置
   * デフォルトは'auto'
   */
  menuPlacement?: MenuPlacement;
  
  /**
   * Control menu
   */
  onMenuOpen?: () => void;
  onMenuClose?: () => void
}> = ({
  name,
  options,
  value, setValue,
  values, setValues,
  isMulti,
  readOnly,
  isDisabled,
  placeholder = '選択してください',
  noOptionMessage = '選択できる項目はありません',
  width,
  controlStyle,
  menuPlacement = 'auto',
  onMenuOpen,
  onMenuClose
}) => {
  let bodyNode = document.body as HTMLBodyElement;

  /**
   * 単数選択
   */
  const getValue = (): ValueType<OptionType> => {
    if (!options) {
      return '' as any;
    }
    if (value === undefined) {
      return '' as any;
    }

    const found = options.find((option) => option.value === value);
    if (!found) {
      return '' as any;
    }

    const newValue = {...found};
    newValue.label = newValue.label.trim()

    return newValue;
  };
  const onChange = React.useCallback((option) => {
    if (!setValue) {
      return;
    }
    setValue(
      (option as OptionType).value,
    );
  }, [setValue]);

  /**
   * 複数選択
   */
  const getValues = () : ValueType<OptionType> => {
    if (!options) {
      return [];
    }
    return options.filter((option) => values?.find((val) => option.value === val));
  };
  const onMultiChange = React.useCallback((changeOptions) => {
    if (!setValues) {
      return;
    }
    setValues((changeOptions as Array<OptionType>).map((option) => option.value));
  }, [setValues]);

  const MenuPortal = (props: any) => {
    const {
      children,
      appendTo,
      getStyles,
      controlElement,
      menuPosition: position,
      menuPlacement
    } = props;
  
    const isFixed = position === "fixed";
  
    if (!appendTo || !controlElement) {
      return null;
    }
  
    const scrollDistance = isFixed ? 0 : window.pageYOffset;
    const rect = controlElement.getBoundingClientRect();
    const bottom = controlElement.getBoundingClientRect().bottom;
    const offset = rect[menuPlacement] + scrollDistance;
    const state = { offset, position, rect };
    return (
      <Portal node={document && appendTo}>
        <div css={
          css([getStyles("menuPortal", { rect, position }), css({zIndex: 9999, top: bottom + window?.pageYOffset})])
        }>
          {children}
        </div>
      </Portal>
    );
  };

  return (
    <ReactSelect
      name={name}
      menuPortalTarget={document.getElementById("portalRoot")}
      components={{
        MenuPortal
      }}
      value={isMulti ? getValues() : getValue()}
      onChange={isMulti ? onMultiChange : onChange}
      options={options}
      placeholder={placeholder}
      isMulti={isMulti}
      readOnly={readOnly}
      isDisabled={isDisabled}
      noOptionsMessage={() => noOptionMessage}
      menuPlacement={menuPlacement}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      
    
      styles={{
        placeholder: (defaultStyles) => {
          return {
              ...defaultStyles,
              color: '#999999',
              fontSize: '14px',
          }
        },
        control: (provided) => ({
          ...provided,
          ...controlStyle,
          // borderColor: grayScale.gray10,
          ':hover': {
            borderColor: grayScale.gray20,
          },
          border: `1px solid ${grayScale.gray03}`,
          borderRadius: '2px',
          boxShadow: 'none',
          '&:hover': {
            border: `1px solid ${grayScale.gray03}`,
          }
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        container: (provided) => ({
          ...provided,
          width,
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: textFontSize.re,
          backgroundColor: (() => {
            if (state.isSelected) return productColor.primary;
            if (state.isFocused) return productColor.primaryM80;
            return '';
          })(),
          color: state.isSelected ? textColor.inversed : textColor.main,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: textFontSize.re,
        }),
        valueContainer: (provided) => ({
          ...provided,
          minHeight: '40px',
        }),
        indicatorSeparator: () => ({
          width: 0,
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 5,
        }),
      }}
    />
  );
};

export default SelectPortal;
