/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import SelectForm from 'components/molecules/SelectForm';
import PaymentMediaOrganizationDomain from 'domain/master/sales/paymentMediaOrganization';
import { deletePaymentMediaOrganization } from 'api/paymentMediaOrganization';
import { useOrgTreesByCategoryOptions } from 'hooks/useOrgTreesOptions';
import useToastNotification from 'hooks/useToastNotification';
import usePaymentMediaOrganizationList from './hooks';

const PaymentImportMasterPage: React.FC = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('');
  const [selectedObj, setSelectedObj] = useState(PaymentMediaOrganizationDomain.generateInitial());
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [isHover, setHover] = useState('');
  const [isHover2, setHover2] = useState('');
  const [orgCode, setorgCode] = useState('');
  const { successNotification, errorNotification } = useToastNotification();

  const orgTreesOptions = useOrgTreesByCategoryOptions();
  const {
    paymentMediaOrganizationList,
    setPaymentMediaOrganizationList,
  } = usePaymentMediaOrganizationList(orgCode);

  // Set default selected org
  useMemo(() => {
    setorgCode(sessionStorage.getItem('selectedOrgCode')!);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const openConfirmModal = useCallback((payment : PaymentMediaOrganizationDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(payment);
  }, []);

  const deletePaymentMediaOrg = useCallback(() => {
    const staffName = sessionStorage.getItem('loginUser.staffName') || '';
    const submitObj = {
      paymentMediaMstCode: selectedObj.paymentMediaCode,
      id: {
        companyCode: selectedObj.companyCode,
        orgCode,
        posType: selectedObj.postType.toString(),
        paymentMediaCode: selectedObj.importCode,
      },
      applyStartDayStr: selectedObj.applicableStartMonthStr,
      applyEndDayStr: selectedObj.applicableEndMonthStr,
      createUser: staffName,
      updateUser: staffName,
    };
    deletePaymentMediaOrganization(
      confirmModalResendOpen ? 1 : 0, submitObj,
    ).then((Response: any) => {
      setPaymentMediaOrganizationList(
        paymentMediaOrganizationList.filter(
          (
            paymentMediaOrganization,
          ) => paymentMediaOrganization.paymentMediaCode !== selectedObj.paymentMediaCode
        || paymentMediaOrganization.postType !== selectedObj.postType
         || paymentMediaOrganization.importCode !== selectedObj.importCode,
        ),
      );
      setConfirmModalOpen(false);
      // setConfirmModalResendOpen(false);
      // setToastMessage('削除しました。');
      // setToastModalOpen(true);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response.status === 406) {
        const errorString = '既にPOSデータが取り込まれています。削除するとレポートやリストで売上の金額が合わなくなる可能性がありますが、よろしいですか？';
        setConfirmModalResendOpen(true);
        setConfirmModalContent(errorString);
      } else {
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let errorString : string = '';

          error.response.data.errors.forEach((errorStr: { defaultMessage: any; }) => { errorString = `${errorString}${errorStr.defaultMessage}</br>`; });

          //   setToastMessage(errorString);
          // } else {
          //   setToastMessage('サーバー側でエラーが発生しました。');
          errorNotification(errorString);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setToastModalOpen(true);
      }
    });
  }, [confirmModalResendOpen, errorNotification, orgCode,
    paymentMediaOrganizationList, selectedObj.applicableEndMonthStr,
    selectedObj.applicableStartMonthStr, selectedObj.companyCode,
    selectedObj.importCode, selectedObj.paymentMediaCode,
    selectedObj.postType, setPaymentMediaOrganizationList, successNotification]);

  const columns: Array<Column<PaymentMediaOrganizationDomain>> = useMemo(() => [
    {
      Header: '支払メディア',
      accessor: 'paymentMediaStr',
    },
    {
      Header: 'POS',
      accessor: 'postTypeStr',
    },
    {
      Header: '取込コード',
      accessor: 'importCode',
    },
    {
      Header: '適用開始月',
      accessor: 'applicableStartMonthStr',
    },
    {
      Header: '適用終了月',
      accessor: 'applicableEndMonthStr',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: PaymentMediaOrganizationDomain } }) => (
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={`/salesPaymentMedia/paymentSetting/edit/${cell.row.original.importCode}/${cell.row.original.postType}/${orgCode}`}>
            <span
              style={{ marginLeft: '20px' }}
              onMouseOver={
                () => {
                  setHover(
                    cell.row.original.importCode.concat(
                      cell.row.original.postType, cell.row.original.paymentMediaCode,
                    ),
                  );
                }
              }
              onMouseOut={() => { setHover(''); }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              <Icon type="edit" color={(isHover && isHover === (cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.paymentMediaCode))) ? iconColor.grayhover : iconColor.gray} />
            </span>
          </Link>

          <span
            style={{ marginLeft: '20px', marginRight: '10px' }}
            onMouseOver={
              () => {
                setHover2(
                  cell.row.original.importCode.concat(
                    cell.row.original.postType, cell.row.original.paymentMediaCode,
                  ),
                );
              }
            }
            onMouseOut={() => { setHover2(''); }}
            onClick={() => { openConfirmModal(cell.row.original); }}
            role="presentation"
            onFocus={() => {}}
            onBlur={() => {}}
          >
            <Icon type="delete" color={(isHover2 && isHover2 === (cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.paymentMediaCode))) ? iconColor.grayhover : iconColor.gray} />
          </span>

        </div>
      ),
    },
  ], [isHover, isHover2, openConfirmModal, orgCode]);
  return (
    <div>
      <PageTitle
        title=""
        rightContents={(
          <Link to={`/salesPaymentMedia/paymentSetting/add/${orgCode}`}>
            <span style={{ marginRight: '12px' }}>
              <PrimaryButton
                ghost={false}
                text="新規登録"
              />
            </span>
          </Link>
    )}
      />

      <div style={{ marginRight: '12px', padding: '16px' }}>
        <SelectForm
          label="組織名："
          name="orgCode"
          value={orgCode}
          setValue={(v) => {
            sessionStorage.setItem('selectedOrgCode', v);
            setorgCode(v);
          }}
          options={orgTreesOptions}
          description=""
        />
      </div>

      <div
        css={css`
            table th:nth-of-type(1) {
              width: 200px;
            }
            table th:nth-of-type(2) {
              width: 100px;
            }
            table th:nth-of-type(3) {
              width: 100px;
            }
            table th:nth-of-type(4) {
              width: 100px;
            }
            table th:nth-of-type(5) {
              width: 100px;
            }
            table th:nth-of-type(6) {
              width: 80px;
            }
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={paymentMediaOrganizationList}
          />
        </div>
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deletePaymentMediaOrg}
        title="確認メッセージ"
        content="削除します。よろしいですか？"
        submitText="削除"
        items={[]}
      />
      <ConfirmModal
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={deletePaymentMediaOrg}
        title="確認"
        content={confirmModalContent}
        items={[]}
      />
      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />
    </div>
  );
};

export default PaymentImportMasterPage;
