/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { getSalesPlanMonthly, GeneralReport, GeneralReportMonth } from 'api/salesReport';
import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useOrgTreesByCategoryAllOptions } from 'hooks/useOrgTreesOptions';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';


// data general key name
const generalKey = [
  'amountOfSalesTotal',
  'amountOfSalesAdvertisement',
  'amountOfSalesFreeTotal',
  'standardCostTotal',
  'standardCostFood',
  'standardCostDrink',
  'standardCostRate',
  'grossProfitTotal',
  'grossProfitRate',
  'laborCostsTotal',
  'laborCostsEmployee',
  'laborCostsParttime',
  'laborCostsRate',
  'advertisingExpense',
  'rentTotal',
  'rentRate',
  'otherExpensesTotal',
  'otherExpensesVariableCosts',
  'otherExpensesFixedCost',
  'otherExpensesRate',
  'operatingIncomeTotal',
  'operatingIncomeRate',
  'rowBlank_01',
  'purchaseAmountTotal',
  'purchaseAmountFood',
  'purchaseAmountDrink',
  'purchaseAmountOhter',
  'rowBlank_02',
  'pricePerCustomer',
  'numberOfCustomers',
  'numberOfGroups',
  'averageNumberOfGuestPerGroup',
  'numberOfCustomersUpToBreakEvenSalesData',
  'numberOfCustomersToMonthlyBudgetSalesData',
  'TurnoverRate',
  'porofitMarginAmount',
  'porofitMarginRate',
  'contributionMarginAmount',
  'contributionMarginRate',
  'breakEvenPointSales',
  'breakEvenPointNumberOfGuest',
  'laborCostRateTotal',
  'laborCostRateEmployee',
  'laborCostRatPartTime',
  'totalWorkingHoursTotal',
  'totalWorkingHoursEmployee',
  'totalWorkingHoursPartTime',
  'numberOfEmployeesTotal',
  'numberOfEmployeesEmployee',
  'numberOfEmployeesPartTime',
  'whenPeopleAreHigh',
  'humanTimeProductivity',
  'numberOfPickUps',
  'laborProductivity',
  'laborShare',
  'FLCost',
  'FLRate',
  'FLACost',
  'FLARate',
  'FLARCost',
  'FLARRate',
  'FLAROCost',
  'FLARORate',
];

const formatter = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatterPercent = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  style: 'percent',
});

const getRatioLabel = (numerator: any, denominator: any) => {
  let result = 0;
  if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
    result = 0;
  } else if (isNaN(numerator) || isNaN(denominator)) {
    result = 0;
  } else if (numerator === 0 || denominator === 0) {
    result = 0;
  } else {
    result = numerator / denominator;
  }
  return formatterPercent.format(result);
};

const getDivision = (numerator: any, denominator: any, fixed?: any) => {
  let rsNumber = 0;
  let tmp: Array<any> = [];
  if (String(numerator).indexOf('%') !== -1) {
    tmp = String(numerator).split('%');
    numerator = tmp[0] / 100;
  }
  if (String(denominator).indexOf('%') !== -1) {
    tmp = String(denominator).split('%');
    denominator = tmp[0] / 100;
  }
  if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
    rsNumber = 0;
  } else if (isNaN(numerator) || isNaN(denominator)) {
    rsNumber = 0;
  } else if (numerator === 0 || denominator === 0) {
    rsNumber = 0;
  } else {
    rsNumber = numerator / denominator;
  }
  if (typeof fixed === 'undefined') {
    tmp = String(rsNumber).split('.');
    fixed = 0;
    if (tmp[1] && tmp[1].length >= 3) {
      fixed = 3;
    } else if (tmp[1]) {
      fixed = tmp[1].length;
    }
  }
  return Number(parseFloat(String(rsNumber)).toFixed(fixed));
};

export const useSalesMonthlyReport = () => {
  const { errorNotification } = useToastNotification();

  const sessionOrgCodeMonthlyReport = sessionStorage.getItem('sessionOrgCodeMonthlyReport') || '';
  const sessionSelectedMonthMonthlyReport = sessionStorage.getItem('sessionSelectedMonthMonthlyReport') || new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date(sessionSelectedMonthMonthlyReport));
  const orgTreesOptions = useOrgTreesByCategoryAllOptions(moment(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1)).format('YYYY-MM-DD'), moment(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1)).format('YYYY-MM-DD'));
  const [orgCode, setOrgCode] = useState(sessionOrgCodeMonthlyReport);

  //
  const [dataKeyName, setDataKeyName] = useState<Array<any>>([]);
  const [generalData, setGeneralData] = useState<GeneralReport|undefined>(undefined);
  const [monthlyPlanData, setMonthlyPlanData] = useState([]);
  const [dayPlanData, setDayPlanData] = useState([]);
  const [advertisingData, setAdvertisingData] = useState([]);
  const [countAdvertising, setCountAdvertising] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  // データの取得
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    getSalesPlanMonthly(
      orgCode || 'all',
      selectedMonth,
    ).then((data: any) => {
      setGeneralData(data);
      // handle data
      if (data) {
        updateCountDayAndAdvertising(data);
      }
      setIsLoading(false);
    })
      .catch((exception : any) => {
        setIsLoading(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, selectedMonth]);

  //
  const updateCountDayAndAdvertising = useCallback(async (data: any) => {
    setAdvertisingData(data.month.advertising);
    dataCalculation(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  const dataCalculation = useCallback(async (generalData: any) => {
    const tmpCountDay = generalData.days.length;
    let tmpCountAdvertising = 0;
    if (generalData.month && generalData.month.advertising) {
      tmpCountAdvertising = generalData.month.advertising.length;
    }
    setCountAdvertising(tmpCountAdvertising);
    // set default
    const tmpDataKeyName: any = [];
    const tmpMonthlyPlanData: any = [];
    const tmpDayPlanData: any = [];
    const tmpNumberOfCustomersToMonthlyBudgetSalesData: any = [];
    const tmpNumberOfCustomersUpToBreakEvenSalesData: any = [];
    let tmpNumberOfCustomersUpToBreakEvenSales = 0;
    setDataKeyName([]);


    // handle
    generalKey.map((value) => {
      if (value === 'amountOfSalesAdvertisement') {
        tmpDataKeyName.push('amountOfSalesAdvertisementTotal');
        tmpMonthlyPlanData.amountOfSalesAdvertisementTotal = [];
        tmpDayPlanData.amountOfSalesAdvertisementTotal = [];
        const tmpTotal = {
          achievement: 0,
          budget: 0,
          days: <number[]> [],
        };

        // push data 売上高>広告 for month
        if (tmpCountAdvertising) {
          generalData.month.advertising.map((obj_adv: any, key_adv: number) => {
            tmpTotal.achievement += obj_adv.advertising_media_amount;
            tmpTotal.budget += obj_adv.budget_advertising_media_amount;

            tmpDataKeyName.push(`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`);
            tmpMonthlyPlanData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`] = [];
            tmpMonthlyPlanData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`].push(
              obj_adv.advertising_media_amount,
              obj_adv.budget_advertising_media_amount,
              getRatioLabel(
                obj_adv.advertising_media_amount,
                obj_adv.budget_advertising_media_amount,
              ),
            );

            // push data  売上高>広告 for days
            if (tmpCountDay) {
              tmpDayPlanData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`] = [];
              generalData.days.map((obj_day: any, key_d: number) => {
                if (!tmpTotal.days[key_d]) {
                  tmpTotal.days[key_d] = 0;
                }
                tmpTotal.days[key_d] += obj_day.advertising[key_adv].advertising_media_amount;
                tmpDayPlanData[`amountOfSalesAdvertisement${obj_adv.advertising_media_mst_code}`].push(obj_day.advertising[key_adv].advertising_media_amount);
                return true;
              });
            }
            return true;
          });
        }

        // push data 売上高>広告>合計 for month
        tmpMonthlyPlanData.amountOfSalesAdvertisementTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
        );

        // push data  売上高>広告>合計 for days
        if (tmpCountDay) {
          generalData.days.map((obj_day: any, key_d: number) => {
            tmpDayPlanData.amountOfSalesAdvertisementTotal.push(tmpTotal.days[key_d] ? tmpTotal.days[key_d] : 0);
            return true;
          });
        }

      // push data 売上高>広告
      } else if (value === 'advertisingExpense') {
        tmpDataKeyName.push('advertisingExpenseTotal');

        tmpMonthlyPlanData.advertisingExpenseTotal = [];
        tmpDayPlanData.advertisingExpenseTotal = [];
        const tmpTotal = {
          achievement: 0,
          budget: 0,
          days: <number[]> [],
        };

        if (tmpCountAdvertising) {
          generalData.month.advertising.map((obj_adv: any, key_adv: number) => {
            tmpTotal.achievement += obj_adv.advertising_media_expense;
            tmpTotal.budget += obj_adv.budget_advertising_media_expense;

            tmpDataKeyName.push(`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`);
            tmpMonthlyPlanData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`] = [];
            tmpMonthlyPlanData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`].push(
              obj_adv.advertising_media_expense,
              obj_adv.budget_advertising_media_expense,
              getRatioLabel(
                obj_adv.advertising_media_expense,
                obj_adv.budget_advertising_media_expense,
              ),
            );

            // push data  広告宣伝費 for days
            if (tmpCountDay) {
              tmpDayPlanData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`] = [];
              generalData.days.map((obj_day: any, key_d: number) => {
                if (!tmpTotal.days[key_d]) {
                  tmpTotal.days[key_d] = 0;
                }
                tmpTotal.days[key_d] += obj_day.advertising[key_adv].budget_advertising_media_expense;
                tmpDayPlanData[`advertisingExpenseTotal${obj_adv.budget_advertising_media_expense}`].push(obj_day.advertising[key_adv].advertising_media_expense);
                return true;
              });
            }
            return true;
          });
        }

        // push data 広告宣伝費>合計 for month
        tmpMonthlyPlanData.advertisingExpenseTotal.push(
          tmpTotal.achievement,
          tmpTotal.budget,
          getRatioLabel(tmpTotal.achievement, tmpTotal.budget),
        );

        // push data  広告宣伝費>合計 for days
        if (tmpCountDay) {
          generalData.days.map((obj_day: any, key_d: number) => {
            tmpDayPlanData.advertisingExpenseTotal.push(tmpTotal.days[key_d] ? tmpTotal.days[key_d] : 0);
            return true;
          });
        }

        tmpDataKeyName.push('advertisingExpenseRate');
        tmpMonthlyPlanData.advertisingExpenseRate = [];
        tmpDayPlanData.advertisingExpenseRate = [];
        tmpMonthlyPlanData.advertisingExpenseRate.push(
          getRatioLabel(tmpMonthlyPlanData.advertisingExpenseTotal[0],
            tmpMonthlyPlanData.amountOfSalesTotal[0]),
          getRatioLabel(tmpMonthlyPlanData.advertisingExpenseTotal[1],
            tmpMonthlyPlanData.amountOfSalesTotal[1]), '-',
        );

        if (tmpCountDay) {
          generalData.days.map((obj_day: any, key_d: number) => {
            tmpDayPlanData.advertisingExpenseRate.push(
              getRatioLabel(
                tmpDayPlanData.advertisingExpenseTotal[key_d],
                tmpDayPlanData.amountOfSalesTotal[key_d],
              ),
            );
            return true;
          });
        }
      } else {
        tmpDataKeyName.push(value);
        tmpMonthlyPlanData[value] = [];
        tmpDayPlanData[value] = [];
        let tmpPerformance: any = 0;
        let tmpBudget: any = 0;
        let tmpTotal = 0;
        let tmpVal = 0;
        switch (value) {
          // 売上高>合計

          case 'amountOfSalesTotal':
            tmpMonthlyPlanData[value].push(
              generalData.month.budget.net_sales,
              generalData.month.budget.sales_budget_total,
              getRatioLabel(generalData.month.budget.net_sales, generalData.month.budget.sales_budget_total),
            );
            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.net_sales);
                return true;
              });
            }
            break;
          // 売上高>フリー>合計
          case 'amountOfSalesFreeTotal':
            tmpPerformance = tmpMonthlyPlanData.amountOfSalesTotal[0] - tmpMonthlyPlanData.amountOfSalesAdvertisementTotal[0];
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              generalData.month.budget.sales_budget_free,
              getRatioLabel(tmpPerformance, generalData.month.budget.sales_budget_free),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.amountOfSalesTotal[key_d] - tmpDayPlanData.amountOfSalesAdvertisementTotal[key_d]);
                return true;
              });
            }
            break;
          // 標準原価>合計
          case 'standardCostTotal':
            // tmpTotal = generalData.month.budget.food_menu_cost + generalData.month.budget.drink_menu_cost;
            tmpTotal = generalData.month.budget.menu_cost_total;
            tmpMonthlyPlanData[value].push(
              tmpTotal,
              generalData.month.budget.sales_budget_cost_total,
              getRatioLabel(tmpTotal, generalData.month.budget.sales_budget_cost_total),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                // tmpTotal = obj_day.budget.food_menu_cost + obj_day.budget.drink_menu_cost;
                tmpTotal = obj_day.budget.menu_cost_total;
                tmpDayPlanData[value].push(tmpTotal);
                return true;
              });
            }
            break;
          // 標準原価>フード
          case 'standardCostFood':
            tmpMonthlyPlanData[value].push(generalData.month.budget.food_menu_cost, generalData.month.budget.sales_budget_food_cost, getRatioLabel(generalData.month.budget.food_menu_cost, generalData.month.budget.sales_budget_food_cost));
            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.food_menu_cost);
                return true;
              });
            }
            break;
          // 標準原価>ドリンク
          case 'standardCostDrink':
            tmpMonthlyPlanData[value].push(generalData.month.budget.drink_menu_cost, generalData.month.budget.sales_budget_drink_cost, getRatioLabel(generalData.month.budget.drink_menu_cost, generalData.month.budget.sales_budget_drink_cost));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.drink_menu_cost);
                return true;
              });
            }
            break;
          // 標準原価>率
          case 'standardCostRate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.standardCostTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.standardCostTotal[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.standardCostTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }

            break;
          // 売上総利益>合計
          case 'grossProfitTotal':
            tmpPerformance = tmpMonthlyPlanData.amountOfSalesTotal[0] - tmpMonthlyPlanData.standardCostTotal[0];
            tmpBudget = tmpMonthlyPlanData.amountOfSalesTotal[1] - tmpMonthlyPlanData.standardCostTotal[1];
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpVal = tmpDayPlanData.amountOfSalesTotal[key_d] - tmpDayPlanData.standardCostTotal[key_d];
                tmpDayPlanData[value].push(tmpVal);
                return true;
              });
            }
            break;
          // 売上総利益>率
          case 'grossProfitRate':
            tmpMonthlyPlanData[value].push(
              getRatioLabel(
                tmpMonthlyPlanData.grossProfitTotal[0],
                tmpMonthlyPlanData.amountOfSalesTotal[0],
              ),
              getRatioLabel(
                tmpMonthlyPlanData.grossProfitTotal[1],
                tmpMonthlyPlanData.amountOfSalesTotal[1],
              ),
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.grossProfitTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;
          // 人件費>合計
          case 'laborCostsTotal':
            tmpPerformance = generalData.month.labor_cost.employee_labor_cost + generalData.month.labor_cost.part_labor_cost;
            tmpBudget = generalData.month.budget.sales_budget_employee_labor_cost + generalData.month.budget.sales_budget_part_labor_cost;
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpVal = obj_day.labor_cost.employee_labor_cost + obj_day.labor_cost.part_labor_cost;
                tmpDayPlanData[value].push(tmpVal);
                return true;
              });
            }
            break;
          // 人件費>社員
          case 'laborCostsEmployee':
            tmpMonthlyPlanData[value].push(generalData.month.labor_cost.employee_labor_cost, generalData.month.budget.sales_budget_employee_labor_cost, getRatioLabel(generalData.month.labor_cost.employee_labor_cost, generalData.month.budget.sales_budget_employee_labor_cost));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.employee_labor_cost);
                return true;
              });
            }
            break;
          // 人件費>バイト
          case 'laborCostsParttime':
            tmpMonthlyPlanData[value].push(generalData.month.labor_cost.part_labor_cost, generalData.month.budget.sales_budget_part_labor_cost, getRatioLabel(generalData.month.labor_cost.part_labor_cost, generalData.month.budget.sales_budget_part_labor_cost));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.part_labor_cost);
                return true;
              });
            }
            break;
          // 人件費>率
          case 'laborCostsRate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.laborCostsTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.laborCostsTotal[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.laborCostsTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;
          // 家賃>合計
          case 'rentTotal':
            tmpMonthlyPlanData[value].push(
              generalData.month.budget.land_rent,
              generalData.month.budget.sales_budget_land_rent,
              getRatioLabel(generalData.month.budget.land_rent, generalData.month.budget.sales_budget_land_rent),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.sales_budget_land_rent);
                return true;
              });
            }
            break;
          // 家賃>率
          case 'rentRate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.rentTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.rentTotal[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.rentTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;
          // その他経費>合計
          case 'otherExpensesTotal':
            // tmpPerformance = generalData.month.budget.sales_budget_other_fixed_cost + generalData.month.budget.sales_budget_other_variable_costs;
            tmpPerformance = generalData.month.budget.other_fixed_cost + generalData.month.budget.other_variable_costs;
            tmpBudget = generalData.month.budget.sales_budget_other_expenses_total;
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                // tmpVal = obj_day.budget.sales_budget_other_variable_costs + obj_day.budget.sales_budget_other_fixed_cost;
                tmpVal = obj_day.budget.sales_budget_other_variable_costs + obj_day.budget.sales_budget_other_fixed_cost;
                tmpDayPlanData[value].push(tmpVal);
                return true;
              });
            }
            break;
          // その他経費>変動費
          case 'otherExpensesVariableCosts':
            tmpMonthlyPlanData[value].push(
              generalData.month.budget.other_variable_costs,
              generalData.month.budget.sales_budget_other_variable_costs,
              getRatioLabel(generalData.month.budget.other_variable_costs, generalData.month.budget.sales_budget_other_variable_costs),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.sales_budget_other_variable_costs);
                return true;
              });
            }
            break;
          // その他経費>固定費
          case 'otherExpensesFixedCost':
            tmpMonthlyPlanData[value].push(
              generalData.month.budget.other_fixed_cost,
              generalData.month.budget.sales_budget_other_fixed_cost,
              getRatioLabel(generalData.month.budget.other_fixed_cost, generalData.month.budget.sales_budget_other_fixed_cost),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.sales_budget_other_fixed_cost);
                return true;
              });
            }
            break;
          // その他経費>率
          case 'otherExpensesRate':
            tmpMonthlyPlanData[value].push(
              getRatioLabel(tmpMonthlyPlanData.otherExpensesTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0]),
              getRatioLabel(tmpMonthlyPlanData.otherExpensesTotal[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.otherExpensesTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;
          // 営業利益>合計
          case 'operatingIncomeTotal':
            tmpPerformance = tmpMonthlyPlanData.amountOfSalesTotal[0] - tmpMonthlyPlanData.standardCostTotal[0] - tmpMonthlyPlanData.laborCostsTotal[0] - tmpMonthlyPlanData.advertisingExpenseTotal[0] - tmpMonthlyPlanData.rentTotal[0] - tmpMonthlyPlanData.otherExpensesTotal[0];
            tmpBudget = generalData.month.budget.sales_budget_operating_income;
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpVal = tmpDayPlanData.amountOfSalesTotal[key_d] - tmpDayPlanData.standardCostTotal[key_d] - tmpDayPlanData.laborCostsTotal[key_d] - tmpDayPlanData.advertisingExpenseTotal[key_d] - tmpDayPlanData.rentTotal[key_d] - tmpDayPlanData.otherExpensesTotal[key_d];
                tmpDayPlanData[value].push(tmpVal);
                return true;
              });
            }
            break;
          // 営業利益>率
          case 'operatingIncomeRate':
            /*
            tmpMonthlyPlanData[value].push(
              getRatioLabel(
                tmpMonthlyPlanData.operatingIncomeTotal[0],
                tmpMonthlyPlanData.amountOfSalesTotal[0]
              ),
              getRatioLabel(
                tmpMonthlyPlanData.operatingIncomeTotal[1],
                tmpMonthlyPlanData.amountOfSalesTotal[1]
              ),
              '-'
            );
            */
            tmpMonthlyPlanData[value].push(
              getRatioLabel(
                tmpMonthlyPlanData.operatingIncomeTotal[0],
                tmpMonthlyPlanData.grossProfitTotal[0],
              ),
              getRatioLabel(
                tmpMonthlyPlanData.operatingIncomeTotal[1],
                tmpMonthlyPlanData.grossProfitTotal[1],
              ),
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                // tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.operatingIncomeTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                tmpDayPlanData[value].push(
                  getRatioLabel(
                    tmpDayPlanData.operatingIncomeTotal[key_d],
                    tmpDayPlanData.grossProfitTotal[key_d],
                  ),
                );
                return true;
              });
            }
            break;

          // 仕入額>合計
          case 'purchaseAmountTotal':
            tmpMonthlyPlanData[value].push(generalData.month.budget.purchase_sales_total_cost, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.purchase_sales_total_cost);
                return true;
              });
            }
            break;

          // 仕入額>フード
          case 'purchaseAmountFood':
            tmpMonthlyPlanData[value].push(generalData.month.budget.purchase_food_cost, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.purchase_food_cost);
                return true;
              });
            }
            break;

          // 仕入額>ドリンク
          case 'purchaseAmountDrink':
            tmpMonthlyPlanData[value].push(generalData.month.budget.purchase_drink_cost, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.purchase_drink_cost);
                return true;
              });
            }
            break;

          // 仕入額>その他
          case 'purchaseAmountOhter':
            tmpMonthlyPlanData[value].push(generalData.month.budget.purchase_other_cost, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.purchase_other_cost);
                return true;
              });
            }
            break;

          // 客単価
          case 'pricePerCustomer':
            tmpPerformance = getDivision(tmpMonthlyPlanData.amountOfSalesTotal[0], generalData.month.budget.guest_cnt, 0);
            tmpBudget = 0;
            if (orgCode === 'all') {
              tmpBudget = getDivision(generalData.month.budget.sales_budget_total, generalData.month.budget.sales_budget_guest_cnt, 0);
            } else {
              tmpBudget = generalData.month.budget.per_customer_price;
            }
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.amountOfSalesTotal[key_d], obj_day.budget.guest_cnt, 0),
                );
                return true;
              });
            }
            break;

          // 客数
          case 'numberOfCustomers':
            tmpPerformance = generalData.month.budget.guest_cnt;
            tmpBudget = generalData.month.budget.sales_budget_guest_cnt;
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.guest_cnt);
                return true;
              });
            }
            break;

          // 組数
          case 'numberOfGroups':
            tmpMonthlyPlanData[value].push(
              generalData.month.budget.customers,
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.budget.customers);
                return true;
              });
            }
            break;

          // 組あたり平均人数
          case 'averageNumberOfGuestPerGroup':
            tmpMonthlyPlanData[value].push(
              getDivision(tmpMonthlyPlanData.numberOfCustomers[0], tmpMonthlyPlanData.numberOfGroups[0], 0),
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.numberOfCustomers[key_d], tmpDayPlanData.numberOfGroups[key_d], 0),
                );
                return true;
              });
            }
            break;

          // 回転率
          case 'TurnoverRate':
            tmpMonthlyPlanData[value].push(
              getDivision(generalData.month.budget.guest_cnt, generalData.month.budget.number_of_seat, 2),
              getDivision(generalData.month.budget.sales_budget_guest_cnt, generalData.month.budget.number_of_seat, 2),
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(obj_day.budget.guest_cnt, obj_day.budget.number_of_seat, 2),
                );
                return true;
              });
            }
            break;

          // 限界利益>額
          case 'porofitMarginAmount':
            tmpPerformance = tmpMonthlyPlanData.amountOfSalesTotal[0] - (tmpMonthlyPlanData.standardCostTotal[0] + tmpMonthlyPlanData.laborCostsParttime[0] + tmpMonthlyPlanData.otherExpensesVariableCosts[0]);
            tmpBudget = tmpMonthlyPlanData.amountOfSalesTotal[1] - (tmpMonthlyPlanData.standardCostTotal[1] + tmpMonthlyPlanData.laborCostsParttime[1] + tmpMonthlyPlanData.otherExpensesVariableCosts[1]);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.amountOfSalesTotal[key_d] - (tmpDayPlanData.standardCostTotal[key_d] + tmpDayPlanData.laborCostsParttime[key_d] + tmpDayPlanData.otherExpensesVariableCosts[key_d]));
                return true;
              });
            }
            break;

          // 限界利益>率
          case 'porofitMarginRate':
            tmpMonthlyPlanData[value].push(
              getRatioLabel(tmpMonthlyPlanData.porofitMarginAmount[0], tmpMonthlyPlanData.amountOfSalesTotal[0]),
              getRatioLabel(tmpMonthlyPlanData.porofitMarginAmount[1], tmpMonthlyPlanData.amountOfSalesTotal[1]),
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getRatioLabel(tmpDayPlanData.porofitMarginAmount[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]),
                );
                return true;
              });
            }
            break;

          // 貢献利益>その他
          case 'contributionMarginAmount':
            tmpPerformance = tmpMonthlyPlanData.porofitMarginAmount[0] - (tmpMonthlyPlanData.laborCostsEmployee[0] + tmpMonthlyPlanData.advertisingExpenseTotal[0] + tmpMonthlyPlanData.rentTotal[0] + tmpMonthlyPlanData.otherExpensesFixedCost[0]);
            tmpBudget = tmpMonthlyPlanData.porofitMarginAmount[1] - (tmpMonthlyPlanData.laborCostsEmployee[1] + tmpMonthlyPlanData.advertisingExpenseTotal[1] + tmpMonthlyPlanData.rentTotal[1] + tmpMonthlyPlanData.otherExpensesFixedCost[1]);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.porofitMarginAmount[key_d] - (tmpDayPlanData.laborCostsEmployee[key_d] + tmpDayPlanData.advertisingExpenseTotal[key_d] + tmpDayPlanData.rentTotal[key_d] + tmpDayPlanData.otherExpensesFixedCost[key_d]));
                return true;
              });
            }
            break;

          // 貢献利益>率
          case 'contributionMarginRate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.contributionMarginAmount[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.contributionMarginAmount[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.contributionMarginAmount[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;

          // 損益分岐点>売上
          case 'breakEvenPointSales':
            tmpPerformance = getDivision(
              (tmpMonthlyPlanData.laborCostsEmployee[0] + tmpMonthlyPlanData.advertisingExpenseTotal[0] + tmpMonthlyPlanData.rentTotal[0] + tmpMonthlyPlanData.otherExpensesFixedCost[0]),
              tmpMonthlyPlanData.porofitMarginRate[0],
              0,
            );
            tmpBudget = getDivision((tmpMonthlyPlanData.laborCostsEmployee[1] + tmpMonthlyPlanData.advertisingExpenseTotal[1] + tmpMonthlyPlanData.rentTotal[1] + tmpMonthlyPlanData.otherExpensesFixedCost[1]), tmpMonthlyPlanData.porofitMarginRate[1], 0);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision((tmpDayPlanData.laborCostsEmployee[key_d] + tmpDayPlanData.advertisingExpenseTotal[key_d] + tmpDayPlanData.rentTotal[key_d] + tmpDayPlanData.otherExpensesFixedCost[key_d]), tmpDayPlanData.porofitMarginRate[key_d], 0),
                );
                return true;
              });
            }
            break;

          // 損益分岐点>客数
          case 'breakEvenPointNumberOfGuest':
            tmpPerformance = getDivision(tmpMonthlyPlanData.breakEvenPointSales[0], tmpMonthlyPlanData.pricePerCustomer[0], 0);
            tmpBudget = getDivision(tmpMonthlyPlanData.breakEvenPointSales[1], tmpMonthlyPlanData.pricePerCustomer[1], 0);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              getRatioLabel(tmpPerformance, tmpBudget),
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.breakEvenPointSales[key_d], tmpDayPlanData.pricePerCustomer[key_d], 0),
                );
                return true;
              });
            }
            break;

          // 人件費率>合計
          case 'laborCostRateTotal':
            tmpPerformance = getRatioLabel(tmpMonthlyPlanData.laborCostsTotal[0], tmpMonthlyPlanData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpMonthlyPlanData.laborCostsTotal[1], tmpMonthlyPlanData.amountOfSalesTotal[1]);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getRatioLabel(tmpDayPlanData.laborCostsTotal[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]),
                );
                return true;
              });
            }
            break;

            // 人件費率>社員
          case 'laborCostRateEmployee':
            tmpPerformance = getRatioLabel(tmpMonthlyPlanData.laborCostsEmployee[0], tmpMonthlyPlanData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpMonthlyPlanData.laborCostsEmployee[1], tmpMonthlyPlanData.amountOfSalesTotal[1]);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getRatioLabel(tmpDayPlanData.laborCostsEmployee[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]),
                );
                return true;
              });
            }
            break;

            // 人件費率>バイト
          case 'laborCostRatPartTime':
            tmpPerformance = getRatioLabel(tmpMonthlyPlanData.laborCostsParttime[0], tmpMonthlyPlanData.amountOfSalesTotal[0]);
            tmpBudget = getRatioLabel(tmpMonthlyPlanData.laborCostsParttime[1], tmpMonthlyPlanData.amountOfSalesTotal[1]);
            tmpMonthlyPlanData[value].push(
              tmpPerformance,
              tmpBudget,
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getRatioLabel(tmpDayPlanData.laborCostsParttime[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]),
                );
                return true;
              });
            }
            break;

            // 総労働時間>合計
          case 'totalWorkingHoursTotal':
            tmpPerformance = generalData.month.labor_cost.employee_total_time + generalData.month.labor_cost.part_total_time;
            tmpMonthlyPlanData[value].push(tmpPerformance, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpVal = obj_day.labor_cost.employee_total_time + obj_day.labor_cost.part_total_time;
                tmpDayPlanData[value].push(tmpVal);
                return true;
              });
            }
            break;

            // 総労働時間>社員
          case 'totalWorkingHoursEmployee':
            tmpMonthlyPlanData[value].push(generalData.month.labor_cost.employee_total_time, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.employee_total_time);
                return true;
              });
            }
            break;

            // 総労働時間>バイト
          case 'totalWorkingHoursPartTime':
            tmpMonthlyPlanData[value].push(generalData.month.labor_cost.part_total_time, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.part_total_time);
                return true;
              });
            }
            break;

            // 勤務人数>合計
          case 'numberOfEmployeesTotal':
            tmpMonthlyPlanData[value].push(
              (generalData.month.labor_cost.employee_count + generalData.month.labor_cost.part_count),
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.employee_count + obj_day.labor_cost.part_count);
                return true;
              });
            }
            break;

            // 勤務人数>社員
          case 'numberOfEmployeesEmployee':
            tmpMonthlyPlanData[value].push(generalData.month.labor_cost.employee_count, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.employee_count);
                return true;
              });
            }
            break;

            // 勤務人数>バイト
          case 'numberOfEmployeesPartTime':
            tmpMonthlyPlanData[value].push(generalData.month.labor_cost.part_count, '-', '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(obj_day.labor_cost.part_count);
                return true;
              });
            }
            break;

            // 人時売上高
          case 'whenPeopleAreHigh':
            tmpMonthlyPlanData[value].push(
              getDivision(tmpMonthlyPlanData.amountOfSalesTotal[0], tmpMonthlyPlanData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.amountOfSalesTotal[key_d], tmpDayPlanData.totalWorkingHoursTotal[key_d], 0),
                );
                return true;
              });
            }
            break;

            // 人時生産性
          case 'humanTimeProductivity':
            tmpMonthlyPlanData[value].push(
              getDivision(tmpMonthlyPlanData.grossProfitTotal[0], tmpMonthlyPlanData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.grossProfitTotal[key_d], tmpDayPlanData.totalWorkingHoursTotal[key_d], 0),
                );
                return true;
              });
            }
            break;

            // 人時接客数
          case 'numberOfPickUps':
            tmpMonthlyPlanData[value].push(
              getDivision(tmpMonthlyPlanData.numberOfCustomers[0], tmpMonthlyPlanData.totalWorkingHoursTotal[0], 0),
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.numberOfCustomers[key_d], tmpDayPlanData.totalWorkingHoursTotal[key_d], 0),
                );
                return true;
              });
            }
            break;

            // 労働生産性
          case 'laborProductivity':
            tmpMonthlyPlanData[value].push(
              getDivision(tmpMonthlyPlanData.grossProfitTotal[0], tmpMonthlyPlanData.numberOfEmployeesTotal[0], 0),
              '-',
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getDivision(tmpDayPlanData.grossProfitTotal[key_d], tmpDayPlanData.numberOfEmployeesTotal[key_d], 0),
                );
                return true;
              });
            }
            break;

            // 労働分配率
          case 'laborShare':
            tmpMonthlyPlanData[value].push(
              getRatioLabel(tmpMonthlyPlanData.laborCostsTotal[0], tmpMonthlyPlanData.grossProfitTotal[0]),
              getRatioLabel(tmpMonthlyPlanData.laborCostsTotal[1], tmpMonthlyPlanData.grossProfitTotal[1]),
              '-',
            );

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(
                  getRatioLabel(tmpDayPlanData.laborCostsTotal[key_d], tmpDayPlanData.grossProfitTotal[key_d]),
                );
                return true;
              });
            }
            break;

            // FL>Cost
          case 'FLCost':
            tmpPerformance = tmpMonthlyPlanData.standardCostTotal[0] + tmpMonthlyPlanData.laborCostsTotal[0];
            tmpBudget = tmpMonthlyPlanData.standardCostTotal[1] + tmpMonthlyPlanData.laborCostsTotal[1];
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.standardCostTotal[key_d] + tmpDayPlanData.laborCostsTotal[key_d]);
                return true;
              });
            }
            break;

            // FL>Rate
          case 'FLRate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.FLCost[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.FLCost[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.FLCost[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;

            // FLA>Cost
          case 'FLACost':
            tmpPerformance = tmpMonthlyPlanData.standardCostTotal[0] + tmpMonthlyPlanData.laborCostsTotal[0] + tmpMonthlyPlanData.advertisingExpenseTotal[0];
            tmpBudget = tmpMonthlyPlanData.standardCostTotal[1] + tmpMonthlyPlanData.laborCostsTotal[1] + tmpMonthlyPlanData.advertisingExpenseTotal[1];
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.standardCostTotal[key_d] + tmpDayPlanData.laborCostsTotal[key_d] + tmpDayPlanData.advertisingExpenseTotal[key_d]);
                return true;
              });
            }
            break;

            // FLA>Rate
          case 'FLARate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.FLACost[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.FLACost[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.FLACost[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;

            // FLAR>Cost
          case 'FLARCost':
            tmpPerformance = tmpMonthlyPlanData.standardCostTotal[0] + tmpMonthlyPlanData.laborCostsTotal[0] + tmpMonthlyPlanData.advertisingExpenseTotal[0] + tmpMonthlyPlanData.rentTotal[0];
            tmpBudget = tmpMonthlyPlanData.standardCostTotal[1] + tmpMonthlyPlanData.laborCostsTotal[1] + tmpMonthlyPlanData.advertisingExpenseTotal[1] + tmpMonthlyPlanData.rentTotal[1];
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.standardCostTotal[key_d] + tmpDayPlanData.laborCostsTotal[key_d] + tmpDayPlanData.advertisingExpenseTotal[key_d] + tmpDayPlanData.rentTotal[key_d]);
                return true;
              });
            }
            break;

            // FLAR>Rate
          case 'FLARRate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.FLARCost[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.FLARCost[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.FLARCost[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;

            // FLARO>Cost
          case 'FLAROCost':
            tmpPerformance = tmpMonthlyPlanData.standardCostTotal[0] + tmpMonthlyPlanData.laborCostsTotal[0] + tmpMonthlyPlanData.advertisingExpenseTotal[0] + tmpMonthlyPlanData.rentTotal[0] + tmpMonthlyPlanData.otherExpensesTotal[0];
            tmpBudget = tmpMonthlyPlanData.standardCostTotal[1] + tmpMonthlyPlanData.laborCostsTotal[1] + tmpMonthlyPlanData.advertisingExpenseTotal[1] + tmpMonthlyPlanData.rentTotal[1] + tmpMonthlyPlanData.otherExpensesTotal[1];
            tmpMonthlyPlanData[value].push(tmpPerformance, tmpBudget, getRatioLabel(tmpPerformance, tmpBudget));

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(tmpDayPlanData.standardCostTotal[key_d] + tmpDayPlanData.laborCostsTotal[key_d] + tmpDayPlanData.advertisingExpenseTotal[key_d] + tmpDayPlanData.rentTotal[key_d] + tmpDayPlanData.otherExpensesTotal[key_d]);
                return true;
              });
            }
            break;

            // FLARO>Rate
          case 'FLARORate':
            tmpMonthlyPlanData[value].push(getRatioLabel(tmpMonthlyPlanData.FLAROCost[0], tmpMonthlyPlanData.amountOfSalesTotal[0]), getRatioLabel(tmpMonthlyPlanData.FLAROCost[1], tmpMonthlyPlanData.amountOfSalesTotal[1]), '-');

            if (tmpCountDay) {
              generalData.days.map((obj_day: any, key_d: number) => {
                tmpDayPlanData[value].push(getRatioLabel(tmpDayPlanData.FLAROCost[key_d], tmpDayPlanData.amountOfSalesTotal[key_d]));
                return true;
              });
            }
            break;

          default:
            break;
        }
      }
    });

    /**
     *
     * 損益分岐点売上高までの客数
     * 実績の客単価が0より大きい場合は実績の客単価から客数を算出する
     */
    let tmpPricePerCustomer = tmpMonthlyPlanData.pricePerCustomer[1];
    if (tmpMonthlyPlanData.pricePerCustomer[0] > 0) {
      tmpPricePerCustomer = tmpMonthlyPlanData.pricePerCustomer[0];
    }
    tmpNumberOfCustomersUpToBreakEvenSales = getDivision(tmpMonthlyPlanData.breakEvenPointSales[1], tmpPricePerCustomer, 0);
    let items = [
      tmpNumberOfCustomersUpToBreakEvenSales,
      '-',
      '-',
    ];
    tmpMonthlyPlanData.numberOfCustomersUpToBreakEvenSalesData = items;

    // 月次予算売上高までの客数
    // 実績の客単価が0より大きい場合は実績の客単価から客数を算出する
    let numberOfCustomersToMonthlyBudgetSales = tmpMonthlyPlanData.numberOfCustomers[1];
    if (tmpMonthlyPlanData.pricePerCustomer[0] > 0) {
      numberOfCustomersToMonthlyBudgetSales = getDivision(tmpMonthlyPlanData.amountOfSalesTotal[1], tmpMonthlyPlanData.pricePerCustomer[0], 0);
    }
    items = [
      numberOfCustomersToMonthlyBudgetSales,
      '-',
      '-',
    ];
    tmpMonthlyPlanData.numberOfCustomersToMonthlyBudgetSalesData = items;

    // 損益分岐点売上高までの客数 & 月次予算売上高までの客数 for Data
    if (tmpCountDay) {
      let tmpTotal = 0;
      generalData.days.map((obj_day: any, key_d: number) => {
        tmpTotal += tmpDayPlanData.numberOfCustomers[key_d];
        tmpNumberOfCustomersUpToBreakEvenSalesData.push(`${((tmpNumberOfCustomersUpToBreakEvenSales - tmpTotal) > 0) ? (tmpNumberOfCustomersUpToBreakEvenSales - tmpTotal) : 0} `);
        tmpNumberOfCustomersToMonthlyBudgetSalesData.push(`${(numberOfCustomersToMonthlyBudgetSales - tmpTotal) > 0 ? (numberOfCustomersToMonthlyBudgetSales - tmpTotal) : 0} `);
        return true;
      });

      tmpDayPlanData.numberOfCustomersUpToBreakEvenSalesData = tmpNumberOfCustomersUpToBreakEvenSalesData;
      tmpDayPlanData.numberOfCustomersToMonthlyBudgetSalesData = tmpNumberOfCustomersToMonthlyBudgetSalesData;
    }

    // set
    setDataKeyName(tmpDataKeyName);
    setMonthlyPlanData(tmpMonthlyPlanData);
    setDayPlanData(tmpDayPlanData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countAdvertising]);

  useEffect(() => {
    // get data
    fetchData();

    // sett session when back && reload
    sessionStorage.setItem('sessionOrgCodeMonthlyReport', orgCode);
    sessionStorage.setItem('sessionSelectedMonthMonthlyReport', selectedMonth.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, selectedMonth]);

  // const formatter = new Intl.NumberFormat('ja-JP', {
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 0,
  // });

  // const formatterPercent = new Intl.NumberFormat('ja-JP', {
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 2,
  //   style: 'percent',
  // });

  // const getRatioLabel = (numerator: any, denominator: any) => {
  //   let result = 0;
  //   if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
  //     result = 0;
  //   } else if (isNaN(numerator) || isNaN(denominator)) {
  //     result = 0;
  //   } else if (numerator === 0 || denominator === 0) {
  //     result = 0;
  //   } else {
  //     result = numerator / denominator;
  //   }
  //   return formatterPercent.format(result);
  // };

  // const getDivision = (numerator: any, denominator: any, fixed?: any) => {
  //   let rsNumber = 0;
  //   let tmp: Array<any> = [];
  //   if (String(numerator).indexOf('%') !== -1) {
  //     tmp = String(numerator).split('%');
  //     numerator = tmp[0] / 100;
  //   }
  //   if (String(denominator).indexOf('%') !== -1) {
  //     tmp = String(denominator).split('%');
  //     denominator = tmp[0] / 100;
  //   }
  //   if (typeof numerator === 'undefined' || typeof denominator === 'undefined') {
  //     rsNumber = 0;
  //   } else if (isNaN(numerator) || isNaN(denominator)) {
  //     rsNumber = 0;
  //   } else if (numerator === 0 || denominator === 0) {
  //     rsNumber = 0;
  //   } else {
  //     rsNumber = numerator / denominator;
  //   }
  //   if (typeof fixed === 'undefined') {
  //     tmp = String(rsNumber).split('.');
  //     fixed = 0;
  //     if (tmp[1] && tmp[1].length >= 3) {
  //       fixed = 3;
  //     } else if (tmp[1]) {
  //       fixed = tmp[1].length;
  //     }
  //   }
  //   return Number(parseFloat(String(rsNumber)).toFixed(fixed));
  // };


  return {
    orgTreesOptions,
    orgCode,
    setOrgCode,
    selectedMonth,
    setSelectedMonth,
    countAdvertising,
    formatter,
    formatterPercent,
    generalData,
    dataKeyName,
    dayPlanData,
    monthlyPlanData,
    advertisingData,
    isLoading,
  };
};

export const eventScroll = (e: { target: any; }) => {
  const { target } = e;
  const layout_sale_right_01 = document.getElementById('layout_sale_right_01');
  const layout_sale_right_02 = document.getElementById('layout_sale_right_02');
  const layout_sale_02_buttom_right_set_scroll = document.getElementById('layout_sale_02_buttom_right_set_scroll');
  if (layout_sale_right_01 && layout_sale_right_02 && layout_sale_02_buttom_right_set_scroll) {
    layout_sale_right_01.scrollLeft = target.scrollLeft;
    layout_sale_right_02.scrollLeft = target.scrollLeft;
    layout_sale_02_buttom_right_set_scroll.scrollLeft = target.scrollLeft;
  }
};

/**
 * グラフ用データを生成する
 */
export const useReportGraph = (month?: GeneralReportMonth) => {
  const reportGraphData = useMemo(() => {
    /** 売上高 */
    const netSales = month?.budget.net_sales || 0;
    /** 食材費 */
    const menuCost = month?.budget.menu_cost_total || 0;
    /** 人件費 */
    const laborCost = (month?.labor_cost.employee_labor_cost || 0) + (month?.labor_cost.part_labor_cost || 0);
    /** 宣伝費 */
    const advertisingExpenseCost = month?.advertising.reduce((acc, current) => acc + current.advertising_media_expense, 0) || 0;
    /** 家賃 */
    const landRentCost = month?.budget.land_rent || 0;
    /** その他 */
    const otherCost = month?.budget.other_expenses_total || 0;
    /** 営業利益 */
    const operatingIncome = netSales - (menuCost + laborCost + advertisingExpenseCost + landRentCost + otherCost);

    /** グラフ描画値（売上がない場合は全て『0』） */
    const menuCostGraphValue = netSales > 0
      ? menuCost
      : 0;
    const laborCostGraphValue = netSales > 0
      ? laborCost
      : 0;
    const advertisingExpenseCostGraphValue = netSales > 0
      ? advertisingExpenseCost
      : 0;
    const landRentCostGraphValue = netSales > 0
      ? landRentCost
      : 0;
    const otherCostGraphValue = netSales > 0
      ? otherCost
      : 0;
    const operatingIncomeGraphValue = netSales > 0
      ? operatingIncome
      : 0;

    /** legend値（売上がない場合は全て『0』） */
    const menuCostLegendValue = netSales > 0
      ? getRatioLabel(menuCost, netSales)
      : formatterPercent.format(0);
    const laborCostLegendValue = netSales > 0
      ? getRatioLabel(laborCost, netSales)
      : formatterPercent.format(0);
    const advertisingExpenseCostLegendValue = netSales > 0
      ? getRatioLabel(advertisingExpenseCost, netSales)
      : formatterPercent.format(0);
    const landRentCostLegendValue = netSales > 0
      ? getRatioLabel(landRentCost, netSales)
      : formatterPercent.format(0);
    const otherCostLegendValue = netSales > 0
      ? getRatioLabel(otherCost, netSales)
      : formatterPercent.format(0);
    const operatingIncomeLegendValue = netSales > 0
      ? getRatioLabel(
        operatingIncome,
        netSales - menuCost,
      )
      : formatterPercent.format(0);

    /** 宣伝費（予算） */
    const budgetAdvertisingExpenseTotal = month?.advertising.reduce((acc, current) => acc + current.budget_advertising_media_expense, 0) || 0;

    /** 現時点での客単価（売上があるなら実績客単価、ないなら予算客単価） */
    const unitPricePerCustomer = netSales > 0
      ? getDivision((month?.budget.net_sales || 0), (month?.budget.guest_cnt || 0), 0)
      : month?.budget.per_customer_price || 0;

    /** 予算達成までの客数 */
    const goalGuestCountBudget = getDivision((month?.budget.sales_budget_total || 0), unitPricePerCustomer, 0);

    /** 現時点での客数 */
    const guestCount = month?.budget.guest_cnt || 0;

    /** 損益分岐点までの客数 */
    // 限界利益
    const marginalProfit = month ? month.budget.sales_budget_total - (
      month?.budget.sales_budget_cost_total
       + month?.budget.sales_budget_part_labor_cost
       + month?.budget.sales_budget_other_variable_costs
    ) : 0;
    // 限界利益率
    const marginalProfitRatio = marginalProfit / (month?.budget.sales_budget_total || 0);
    // 損益分岐点売上高
    const breakevenPointSales = getDivision(
      (
        (month?.budget.sales_budget_employee_labor_cost || 0)
        + budgetAdvertisingExpenseTotal
        + (month?.budget.sales_budget_land_rent || 0)
        + (month?.budget.sales_budget_other_fixed_cost || 0)
      ),
      marginalProfitRatio,
      0,
    );

    /** 損益分岐点までの客数 */
    const breakevenPointCount = getDivision(breakevenPointSales, unitPricePerCustomer, 0);

    /** グラフ描画値 */
    /** 現時点での客数 */
    const guestCountGraphValue = goalGuestCountBudget > 0 ? guestCount : 0;
    let breakevenPointCountGraphValue = 0;
    let goalGuestCountGraphValue = 0;

    /** 損益分点までの客数 */
    if (goalGuestCountBudget > 0) {
      breakevenPointCountGraphValue = breakevenPointCount;
      if (breakevenPointCount > guestCount) {
        // 損益分岐点までの客数に達していない場合、損益分岐点までの客数 - 現時点での客数
        breakevenPointCountGraphValue = breakevenPointCount - guestCount;
      } else {
        // 損益分岐点までの客数を達成している場合『0』
        breakevenPointCountGraphValue = 0;
      }
    }

    /** 目標までの客数 */
    if (goalGuestCountBudget > 0) {
      if (guestCount > goalGuestCountBudget) {
        /** 予算達成までの客数を達成している場合『0』 */
        goalGuestCountGraphValue = 0;
      } else if (breakevenPointCount > guestCount) {
        // 損益分岐点までの客数に満たない場合、予算達成までの客数 - 損益分岐点までの客数
        goalGuestCountGraphValue = goalGuestCountBudget - breakevenPointCount;
      } else {
        // 損益分岐点までの客数を達成している場合、予算達成までの客数 - 現時点での客数
        goalGuestCountGraphValue = goalGuestCountBudget - guestCount;
      }
    }

    /** legend値 */
    const guestCountLegendValue = `${formatter.format(guestCount)}人`;
    const breakevenPointCountLegendValue = breakevenPointCount > guestCount
      ? `${formatter.format(breakevenPointCount - guestCount)}人`
      : '0人';
    const goalGuestCountLegendValue = goalGuestCountBudget > guestCount
      ? `${formatter.format(goalGuestCountBudget - guestCount)}人`
      : '0人';

    return {
      /** 左のグラフのデータ */
      netSales,
      operatingIncome,
      costData: [
        { name: '食材費', value: menuCostGraphValue },
        { name: '人件費', value: laborCostGraphValue },
        { name: '宣伝費', value: advertisingExpenseCostGraphValue },
        { name: '家賃', value: landRentCostGraphValue },
        { name: 'その他', value: otherCostGraphValue },
        { name: '営業利益', value: operatingIncomeGraphValue },
      ],
      costLegendData: [
        { name: '食材費', value: menuCostLegendValue },
        { name: '人件費', value: laborCostLegendValue },
        { name: '宣伝費', value: advertisingExpenseCostLegendValue },
        { name: '家賃', value: landRentCostLegendValue },
        { name: 'その他', value: otherCostLegendValue },
        { name: '営業利益', value: operatingIncomeLegendValue },
      ],
      /** 右のグラフのデータ */
      unitPricePerCustomer,
      goalGuestCountBudget,
      customersData: [
        { name: '現時点での客数', value: guestCountGraphValue },
        { name: '損益分岐点までの客数', value: breakevenPointCountGraphValue },
        { name: '目標までの客数', value: goalGuestCountGraphValue },
      ],
      customersLegendData: [
        { name: '現時点での客数', value: guestCountLegendValue },
        { name: '損益分岐点までの客数', value: breakevenPointCountLegendValue },
        { name: '目標までの客数', value: goalGuestCountLegendValue },
      ],
    };
  }, [month]);

  return reportGraphData;
};

export default useSalesMonthlyReport;
