/** @jsx jsx */

import React from "react";
import { Column } from "react-table";
import { css, jsx } from "@emotion/core";

import OrganizationDomain from "domain/organization";

import DataTable from "components/organismos/DataTable/DataTable";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import Modal from "components/molecules/Modal";
import StoreCloseFilter from "./StoreCloseFilter";
import ButtonNavigation from "./ButtonNavigation";

import { useOrganizationDelete, useDownload } from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import { InfoButton } from "components/atoms/Button";
import { Link } from "react-router-dom";
import FormTitle from "components/atoms/Form/FormTitle";
import { DetailRole } from "api/masterRole";

const OrganizationListTable: React.FC<{
  organizationList: Array<OrganizationDomain>;
  fetchData: () => Promise<void>;
  noManager: boolean;
  toggleNoManager: () => void;
  unsetTargetDate: Date;
  setUnsetTargetDate: (arg: Date) => void;
  orgStatus: string;
  setOrgStatus: (art: "0" | "1") => void;
  detailRole: DetailRole;
}> = ({
  organizationList,
  noManager,
  unsetTargetDate,
  orgStatus,
  setOrgStatus,
  detailRole,
}) => {
  const {
    deleteTargetOrganization,
    setDeleteTargetOrganization,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteOrganization,
  } = useOrganizationDelete();
  const {
    downloadOrganization,
    downloadManagerCsv,
    downloadSpecialSalaryCsv,
  } = useDownload(noManager, orgStatus, unsetTargetDate);

  const columns: Array<Column<OrganizationDomain>> = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "orgCode",
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: {
          row: { isExpanded: boolean; values: OrganizationDomain };
        }) => {
          return (
            <FlexBox
              customStyle={css({
                width: "0px",
                height: "0px",
                paddingLeft: "8px",
              })}
            >
              <FlexBoxItem margin="0 auto">
                <Link to={`/organizations/${cell.row.values.orgCode}`}>
                  <InfoButton
                    text={detailRole.editable ? `編集` : `参照`}
                    minWidth={"36px"}
                    customStyle={css({
                      padding: "0 5px",
                      borderRadius: "3px",
                      fontSize: "12px",
                      lineHeight: "20px",
                      height: "24px",
                      fontFamily: "'Noto Sans',sans-serif",
                    })}
                  />
                </Link>
              </FlexBoxItem>
            </FlexBox>
          );
        },
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [setDeleteTargetOrganization, detailRole]
  );

  return (
    <div>
      <FormTitle title="組織マスタ" bold={true} />
      <FormPadding customStyle={{ paddingLeft: "25px", paddingBottom: "0" }}>
        <div css={css({ paddingBottom: "20px" })}>
          <StoreCloseFilter orgStatus={orgStatus} setOrgStatus={setOrgStatus} />
        </div>

        <div css={css({ position: "relative" })}>
          <ButtonNavigation
            downloadOrganization={downloadOrganization}
            downloadManagerCsv={downloadManagerCsv}
            downloadSpecialSalaryCsv={downloadSpecialSalaryCsv}
            detailRole={detailRole}
          />
        </div>
      </FormPadding>
      <div
        css={css`
          table tr th:nth-child(1),
          table tr td:nth-child(1) {
            padding: 4px;
          }
          table tr td:nth-child(1) button {
            white-space: nowrap;
          }
          table th:nth-of-type(1) {
            width: 60px;
          }
          table th:nth-of-type(2) {
            width: 100px;
          }
          table th:nth-of-type(3) {
            width: calc(100% - 160px);
          }
          table th {
            text-align: left;
          }
        `}
      >
        <DataTable
          columns={columns}
          data={organizationList}
          isGlobalFilter={true}
          containerStyle={css({
            overflowX: "auto",
          })}
          sortBy={[
            { id: "orgCode", desc: true },
            { id: "orgName", desc: true },
          ]}
        />
      </div>

      <Modal
        open={isOpenDeleteModal}
        closeHandler={closeDeleteModal}
        submitText="削除"
        title="削除"
        onSubmit={onSubmitDeleteOrganization}
      >
        {deleteTargetOrganization?.orgName}
        を削除します。よろしいですか？
      </Modal>
    </div>
  );
};

export default OrganizationListTable;
