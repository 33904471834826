import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useFormik } from "formik";
import useToastNotification from "hooks/useToastNotification";
import { getApplyingHolidayListHistory } from "api/getHistoryApplication";
import { useOrgAllSelect } from "hooks/useOrgTreesOptions";
import AttendAllowanceApplicationDomain from "domain/master/attend/allowanceApplication";
import { createAllowanceApplication, getAllowance } from "api/attendAllowance";
import { useHistory } from "react-router-dom";

export const useAllowanceApplicationForm = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem("application.orgCode") || "");
  const [targetDate] = useState(sessionStorage.getItem("application.targetDate") || "");
  const [staffName] = useState(sessionStorage.getItem("application.dispStaffName") || "");
  const [staffCode] = useState(sessionStorage.getItem("application.staffCode") || "");
  const [toastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [selectedHoliday, setSelectedHoliday] = useState(Object());
  const [setStampTime] = useState("");
  const [selectChangelog, setSelectChangelog] = useState<string>("0");

  const [selectedSubstitute, setSelectedSubstitute] = useState("");
  const { successNotification, errorNotification } = useToastNotification();
  const selectStaffCode = sessionStorage.getItem("application.staffCode") || "";

  const orgTreesOptions = useOrgAllSelect(
    false,
    moment(`${targetDate}`).format("YYYY-MM-DD"),
    moment(`${targetDate}`).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    setLoading(true);
    getAllowance({
      orgCode: orgCode,
      targetDate,
      staffCode: selectStaffCode,
    })
      .then((data: any) => {
        formik.setValues(new AttendAllowanceApplicationDomain(data));
      })
      .catch(() => {
        errorNotification("サーバー側でエラーが発生しました。");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchChangeLogHoliday = async (applicationId?: string): Promise<any[]> => {
    return await getApplyingHolidayListHistory({ applicationId });
  };

  const onSubmit = async (values: AttendAllowanceApplicationDomain) => {
    let flagError = false;
    let msgError = "";

    const targetList = values.detailList.filter((item) => item.allowanceValue);

    if (targetList.length === 0) {
      flagError = true;
      msgError += "いずれかの申請値を入力してください。<br />";
    }

    if (flagError === true) {
      errorNotification(msgError);
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      setLoading(true);
      setConfirmModalOpen(false);
      const response = await createAllowanceApplication({
        orgCode: orgCode,
        targetDate: moment(`${values.targetDate}`).format("YYYY/MM/DD"),
        staffCode: values.staffCode,
        version: Number(values.version),
        buttonType: values.buttonType,
        modifierApplicationId: values.modifierApplicationId,
        detailList: targetList.map((item) => ({
          allowanceId: item.allowanceId,
          allowanceValue: item.allowanceValue,
          comment: item.comment,
        })),
      });
      if (!response.errors) {
        sessionStorage.setItem("highlightAction", "true");
        history.push(sessionStorage.getItem("attendStampList.returnDestination")! || "");
      }
      successNotification("申請しました。");
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error: any) => {
    setLoading(false);
    setConfirmModalOpen(false);
    if (error.response && error.response.data && error.response.data.errors) {
      let msgError = "";
      error.response.data.errors.map((item: { defaultMessage: string }) => {
        msgError += `${item.defaultMessage} <br />`;
        return msgError;
      });
      errorNotification(msgError);
    } else {
      errorNotification("サーバー側でエラーが発生しました。");
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: AttendAllowanceApplicationDomain.generateInitial(),
    validationSchema: false,
    validateOnChange: false,
    onSubmit,
  });

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    isLoading,
    formik,
    staffName,
    toastModalOpen,
    toastMessage,
    closeConfirmModal,
    confirmModalOpen,
    setToastModalOpen,
    selectedHoliday,
    setSelectedHoliday,
    setStampTime,
    onSubmit,
    selectedSubstitute,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogHoliday,
    setSelectedSubstitute,
    orgTreesOptions,
    orgCode,
    setOrgCode,
  };
};

export default useAllowanceApplicationForm;
