import { useState, useEffect } from "react";
import moment from "moment";

import { OptionType } from "components/atoms/Select";
import {
  getTrees,
  getTreesByCategory,
  getTreesOrgCode,
  getTreesByCategoryAll,
  getTreesAllOrgCode,
  getOrgManged,
  getTreesOrgCSVCode,
  getTreesOrgCodeV1,
  getTreesOrgCodeNoRole,
  getTreesOrgCodeByFunctionCode,
  getTreesOrgSelectRange,
  getTreesOrgAllSelectRange,
  getTreesOrgSelectRangeAndAgreementsId,
  getTreesOrgAllTableRange,
  getTreesOrgCodeForStaffMst,
} from "api/organization";

/**
 * 所定労働日数の選択ボックス用データを取得
 */
export const useOrgTreesOptions = (
  targetDateFrom?: String,
  targetDateTo?: String,
  isSelectAll?: boolean,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const dateFrom =
      targetDateFrom ||
      moment()
        .startOf("year")
        .format("YYYY-MM-DD");
    const dateTo =
      targetDateTo ||
      moment()
        .endOf("year")
        .format("YYYY-MM-DD");
    getTreesOrgCode(String(dateFrom), String(dateTo)).then((trees) => {
      const listOrgCode = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
        orgName: tree.orgName,
      }));
      if (isSelectAll) {
        listOrgCode.unshift({
          value: "",
          label: "-",
          orgName: "",
        });
      }
      setOrgTreesOptionsList(listOrgCode);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsByFunctionCode = (addAllItem: boolean, functionCode: string): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgCodeByFunctionCode(addAllItem, functionCode).then((trees) => {
      if (!trees) {
        console.error("組織一覧が取得できません");
        return;
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));

      setOrgTreesOptionsList(orgs);
    });
  }, []);
  return orgTreesOptions;
};

export const useOrgTreesOptionsAll = (
  targetDateFrom?: string,
  targetDateTo?: string,
  onlyManager?: boolean,
  isNotOptionAll?: boolean,
  isValueAllOption?: boolean,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgCode(targetDateFrom, targetDateTo, onlyManager).then((trees) => {
      if (!trees) {
        console.error("組織一覧が取得できません");
        return;
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      if (!isNotOptionAll) {
        orgs.unshift({
          value: "",
          label: "-",
        });
      }

      setOrgTreesOptionsList(orgs);
    });
  }, [isNotOptionAll, isValueAllOption, onlyManager, targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsAllNoRole = (
  targetDateFrom?: string,
  targetDateTo?: string,
  onlyManager?: boolean,
  isNotOptionAll?: boolean,
  isValueAllOption?: boolean,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgCodeNoRole(targetDateFrom, targetDateTo, onlyManager).then((trees) => {
      if (!trees) {
        console.error("組織一覧が取得できません");
        return;
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      if (!isNotOptionAll) {
        orgs.unshift({
          value: "",
          label: "-",
        });
      }

      setOrgTreesOptionsList(orgs);
    });
  }, [isNotOptionAll, isValueAllOption, onlyManager, targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsAllV1 = (
  targetDateFrom?: string,
  targetDateTo?: string,
  onlyManager?: boolean,
  isNotOptionAll?: boolean,
  isValueAllOption?: boolean,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgCodeV1(targetDateFrom, targetDateTo, onlyManager).then((trees) => {
      if (!trees) {
        console.error("組織一覧が取得できません");
        return;
      }
      const orgs = trees.map((tree) => ({
        value: tree.specifyCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      if (!isNotOptionAll) {
        orgs.unshift({
          value: "",
          label: "-",
        });
      }

      setOrgTreesOptionsList(orgs);
    });
  }, [isNotOptionAll, isValueAllOption, onlyManager, targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsShop = (
  targetDateFrom?: string,
  targetDateTo?: string,
  onlyManager?: boolean,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgCode(targetDateFrom, targetDateTo, onlyManager, "SHOP").then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [onlyManager, targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsShopV1 = (
  addAllItem: boolean,
  functionCode: string,
  targetDateFrom?: string,
  targetDateTo?: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgSelectRange(addAllItem, functionCode, targetDateFrom, targetDateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: tree.orgName,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsSpecifyCode = (
  addAllItem: boolean,
  functionCode: string,
  targetDateFrom?: string,
  targetDateTo?: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgSelectRange(addAllItem, functionCode, targetDateFrom, targetDateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.specifyCode || "all",
        label: tree.orgName,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgAllSelect = (
  addAllItem: boolean,
  targetDateFrom?: string,
  targetDateTo?: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgAllSelectRange(addAllItem, targetDateFrom, targetDateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgAllTable = (
  addAllItem: boolean,
  targetDateFrom?: string,
  targetDateTo?: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgAllTableRange(addAllItem, targetDateFrom, targetDateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsManaged = (): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getOrgManged().then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.specifyCode,
        label: `${tree.id.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, []);
  return orgTreesOptions;
};

export const useOrgTreesOptionsManagedCustom = (): Array<any> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<any>>([]);
  useEffect(() => {
    getOrgManged().then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.specifyCode,
        label: `${tree.id.orgCode} ${tree.orgName}`,
        orgCode: tree.id.orgCode,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, []);
  return orgTreesOptions;
};

export const useOrgNameTreesOptionsAll = (): Array<OptionType> => {
  const [orgNameTreesOptions, setOrgNameTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const dateFrom = moment()
      .startOf("year")
      .format("YYYY-MM-DD");
    const dateTo = moment()
      .endOf("year")
      .format("YYYY-MM-DD");
    getTrees(dateFrom, dateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));
      setOrgNameTreesOptionsList(orgs);
    });
  }, []);
  return orgNameTreesOptions;
};

export const useAllOrgTrees = (): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesAllOrgCode().then((trees) => {
      setOrgTreesOptionsList(
        trees.map((tree) => ({
          value: tree.orgCode,
          label: `${tree.orgCode} ${tree.orgName}`,
        })),
      );
    });
  }, []);
  return orgTreesOptions;
};

export const useOrgTreesByCategoryOptions = (): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const category: number = 2;
    getTreesByCategory(category).then((trees) => {
      setOrgTreesOptionsList(
        trees.map((tree) => ({
          value: tree.orgCode,
          label: `${tree.orgCode} ${tree.orgName}`,
        })),
      );
    });
  }, []);
  return orgTreesOptions;
};
export const useOrgTreesByOrgCode = (targetDateFrom: Date, targetDateTo: Date): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth() - 1, 1)).format(
      "YYYY-MM-DD",
    );
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format(
      "YYYY-MM-DD",
    );
    getTreesOrgCode(dateFrom, dateTo).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      orgs.unshift({
        value: "",
        label: "all 全組織",
      });
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesByOrgCSVCode = (
  targetDayFrom: Date,
  targetDayTo: Date,
  setIsLoading?: any,
  addAllItem?: boolean,
): any => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<any>>([]);
  const [treesOptions, setTreesOptions] = useState<any>([]);
  useEffect(() => {
    if (typeof setIsLoading == "function") {
      setIsLoading(true);
    }
    if (targetDayFrom === null || targetDayTo === null) return;
    const dateFrom = moment(targetDayFrom)
      .startOf("month")
      .format("YYYY-MM-DD");
    const dateTo = moment(targetDayTo)
      .endOf("month")
      .format("YYYY-MM-DD"); // get last date month
    getTreesOrgCSVCode(dateFrom, dateTo, addAllItem !== undefined ? addAllItem : true).then((trees) => {
      if (typeof setIsLoading == "function") {
        setIsLoading(false);
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
        orgName: tree.orgName,
      }));
      setOrgTreesOptionsList(orgs);
      setTreesOptions(trees);
    });
  }, [targetDayFrom, targetDayTo]);
  return orgTreesOptions;
};

export const useOrgTreesByYear = (applyYear: number, setIsLoading?: any, addAllItem?: boolean): any => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<any>>([]);
  const [treesOptions, setTreesOptions] = useState<any>([]);
  useEffect(() => {
    if (typeof setIsLoading == "function") {
      setIsLoading(true);
    }
    if (applyYear === null) return;
    const dateFrom = moment(new Date(applyYear, 0, 1)).format("YYYY-MM-DD");
    const dateTo = moment(new Date(applyYear + 1, 0, 0)).format("YYYY-MM-DD");
    getTreesOrgCSVCode(dateFrom, dateTo, addAllItem !== undefined ? addAllItem : true).then((trees) => {
      if (typeof setIsLoading == "function") {
        setIsLoading(false);
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
        orgName: tree.orgName,
      }));
      setOrgTreesOptionsList(orgs);
      setTreesOptions(trees);
    });
  }, [applyYear]);
  return orgTreesOptions;
};

export const useOrgTreesByCategoryAllOptions = (targetDateFrom?: string, targetDateTo?: string): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const category: number = 2;
    getTreesByCategoryAll(targetDateFrom, targetDateTo, category).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      orgs.unshift({
        value: "",
        label: "-",
      });
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesByCategoryNotAllOptions = (
  targetDateFrom?: string,
  targetDateTo?: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const category: number = 2;
    getTreesByCategoryAll(targetDateFrom, targetDateTo, category).then((trees) => {
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgCode} ${tree.orgName}`,
      }));
      setOrgTreesOptionsList(orgs);
    });
  }, [targetDateFrom, targetDateTo]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsForAgreements = (
  addAllItem: boolean,
  functionCode: string,
  agreementsId: string,
  targetDateFrom?: string,
  targetDateTo?: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getTreesOrgSelectRangeAndAgreementsId(addAllItem, functionCode, targetDateFrom, targetDateTo, agreementsId).then(
      (trees) => {
        const orgs = trees.map((tree) => ({
          value: tree.orgCode,
          label: tree.orgName,
        }));
        setOrgTreesOptionsList(orgs);
      },
    );
  }, [targetDateFrom, targetDateTo, agreementsId]);
  return orgTreesOptions;
};

export const useOrgTreesOptionsForStaffMst = (
  addAllItem: boolean,
  stateStatus: Array<number>,
  functionCode: string,
): Array<OptionType> => {
  const [orgTreesOptions, setOrgTreesOptionsList] = useState<Array<OptionType>>([]);

  useEffect(() => {
    getTreesOrgCodeForStaffMst(addAllItem, stateStatus, functionCode).then((trees) => {
      if (!trees) {
        console.error("組織一覧が取得できません");
        return;
      }
      const orgs = trees.map((tree) => ({
        value: tree.orgCode,
        label: `${tree.orgName}`,
      }));

      setOrgTreesOptionsList(orgs);
    });
  }, [stateStatus]);
  return orgTreesOptions;
};

export default useOrgTreesOptions;
