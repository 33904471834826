import { useState, useEffect } from 'react';

import getList from 'api/vacationMaster';
import VacationDomain from 'domain/vacation';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const useVacationMaster = () => {
  const [blocking, setBlocking] = useState(false);


  const { detailRole } = useGetDetailRole(setBlocking, functionCode.vacationMaster);

  const [vacationList, setVacationList] = useState<Array<VacationDomain>>([]);
  useEffect(() => {
    setBlocking(true);
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    getList(companyCode).then((response: Array<any>) => {
      setBlocking(false);
      if (isSubscribed) {
        setVacationList(response.map((result) => new VacationDomain(result)));
      }
    });
    return () => {
      setBlocking(false);
      isSubscribed = false; };
  }, []);
  return { vacationList, setVacationList , blocking, setBlocking, detailRole};
};

export default useVacationMaster;
