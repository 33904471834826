/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { SubActionButton } from "components/atoms/Button";
import { ActionButton } from "components/atoms/Button/SubActionButton";
import ClickableLink from "components/atoms/ClickableLink";
import FlexBox from "components/atoms/FlexBox";
import Accordion from "components/molecules/Accordion";
import ChangeLogModal from "components/molecules/ChangeLogModal";
import Modal from "components/molecules/Modal";
import DataTable from "components/organismos/DataTable/DataTable";
import { grayScale } from "components/styles";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useApplyingTransferForm } from "../ApplyingTab/hook";
import { AttendApplicationListContext } from "../AttendApplicationList";
import RejectedStampDomain from "../domain/rejectedStampDomain";

const StampAccordion: React.FC<{ list: RejectedStampDomain[] }> = ({ list }) => {
  const {
    changeLogData,
    setChangeLogData,
    changeLogModalOpen,
    setChangeLogModalOpen,
    setSelectChangelog,
    fetchChangeLog,
  } = useApplyingTransferForm(list);
  const history = useHistory();
  const { detailRole } = useContext(AttendApplicationListContext);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "edit",
        accessor: "edit",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <FlexBox customStyle={css({ justifyContent: "space-evenly" })}>
            {detailRole.editable === 1 && (
              <SubActionButton
                text="再申請"
                onClick={() => {
                  sessionStorage.setItem(
                    "attendStampList.targetDateFrom",
                    moment(cell.row.original.stampListStartTime).format("YYYY-MM-DD"),
                  );
                  sessionStorage.setItem(
                    "attendStampList.targetDateTo",
                    moment(cell.row.original.stampListEndTime).format("YYYY-MM-DD"),
                  );
                  sessionStorage.setItem("attendStampList.orgCode", String(cell.row.original.orgCode));
                  sessionStorage.setItem("attendStampList.staffCode", String(cell.row.original.staffCode));

                  sessionStorage.setItem("application.orgCode", String(cell.row.original.orgCode));
                  sessionStorage.setItem("application.staffCode", String(cell.row.original.staffCode));

                  sessionStorage.setItem(
                    "application.dispStaffName",
                    `${cell.row.original.staffCode} ${cell.row.original.staffName}`,
                  );
                  sessionStorage.setItem(
                    "application.targetDate",
                    moment(cell.row.original.targetDate).format("YYYY-MM-DD"),
                  );

                  sessionStorage.setItem("application.achievementId", cell.row.original.achievementId || "");
                  sessionStorage.setItem("application.stampVersion", cell.row.original.version || "");
                  sessionStorage.setItem(
                    "application.modifierApplicationId",
                    cell.row.original.modifierApplicationId || "",
                  );

                  sessionStorage.setItem("application.returnDestination", window.location.pathname);
                  sessionStorage.setItem("attendStampList.returnDestination", window.location.pathname);

                  history.push("attendApplicationList/attendStampModificationApplication");
                }}
                action={ActionButton.EDIT}
              />
            )}
          </FlexBox>
        ),
        disableSortBy: true,
      },
      {
        Header: "差戻者",
        accessor: "authorizer",
      },
      {
        Header: "差戻日時",
        accessor: "approvalDate",
      },
      {
        Header: "申請者",
        accessor: "applicant",
      },
      {
        Header: "申請日時",
        accessor: "applicationDate",
      },
      {
        Header: "対象者",
        accessor: "targetPerson",
      },
      {
        Header: () => "対象日付",
        id: "",
        accessor: "targetDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any; index: number }; data: any }) => (
          <div style={{ textAlign: "center" }}>
            <div>{cell.row.original.targetDate}</div>
            <ClickableLink
              customCss={css({ textAlign: "center" })}
              onClick={() => {
                fetchChangeLog(cell.row.original.modifierApplicationId);
                setChangeLogModalOpen(true);
                setSelectChangelog("0");
              }}
            >
              更新履歴
            </ClickableLink>
          </div>
        ),
      },
      {
        Header: "申請種別",
        accessor: "modifiyStatusName",
      },
      {
        Header: "出勤組織",
        accessor: "stampOrgName",
      },
      {
        Header: "開始時間", // starttime
        accessor: "stampListStartTime", // .startTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>{cell.row.original.stampListStartTime}</div>
        ),
      },
      {
        Header: "終了時間", // endtime
        accessor: "stampListEndTime", // .endTime
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <div style={{ textAlign: "left", whiteSpace: "pre" }}>
            {cell.row.original.stampListEndTime && cell.row.original.stampListEndTime}
          </div>
        ),
      },
      // {
      //   Header: '業務内容', // business content
      //   accessor: 'stampListBusinessName', // .businessName
      //   Cell: (cell: { row :{ isExpanded: boolean, original: any }}) => (
      //     <div style={{ textAlign: 'left', whiteSpace: 'pre' }}>
      //       {cell.row.original.stampListBusinessName}
      //     </div>
      //   ),
      // },
      {
        Header: "差戻者コメント",
        accessor: "rejectReason",
      },
    ],
    [fetchChangeLog, history, setChangeLogModalOpen, setSelectChangelog, detailRole.editable],
  );

  return (
    <React.Fragment>
      <div
        css={css`
          table th:nth-of-type(1) {
            width: 50px;
          }
          table th:nth-of-type(2) {
            width: 140px;
          }
          table th:nth-of-type(3) {
            width: 140px;
          }
          table th:nth-of-type(4) {
            width: 140px;
          }
          table th:nth-of-type(5) {
            width: 120px;
          }
          table th:nth-of-type(6) {
            width: 140px;
          }
          table th:nth-of-type(7) {
            width: 80px;
          }
          table th:nth-of-type(8) {
            width: 80px;
          }
          table th:nth-of-type(9) {
            width: 140px;
          }
          table th:nth-of-type(10) {
            width: 140px;
          }
          table th:nth-of-type(11) {
            width: 140px;
          }
          table th:nth-of-type(12) {
            width: 200px;
          }
        `}
      >
        <Accordion text={`打刻修正申請 (${list.length}件）`} callBack={() => {}}>
          <DataTable
            columns={columns}
            containerStyle={css({
              overflowX: "auto",
              marginTop: "15px",
            })}
            data={list}
            isGlobalFilter={true}
            sortBy={[{ id: "approvalDate", desc: true }]}
          />
        </Accordion>

        {/* Change log  */}
        <div css={css(">div>div{ width: 800px }")}>
          <Modal
            closeHandler={() => {
              setChangeLogModalOpen(false);
              setChangeLogData([]);
            }}
            open={changeLogModalOpen}
            title="更新履歴"
            submitText="閉じる"
            onSubmit={() => {
              setChangeLogModalOpen(false);
              setChangeLogData([]);
            }}
            closeText=""
          >
            <ChangeLogModal changeLogData={changeLogData} isStamp={true} />
          </Modal>
        </div>
        {/* ./Change log  */}
      </div>
    </React.Fragment>
  );
};

export default StampAccordion;
