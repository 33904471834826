/** @jsx jsx */
import moment from "moment";
import React from "react";
import { Column } from "react-table";
import { jsx, css } from "@emotion/core";
import DataTable from "components/organismos/DataTable/DataTable";
import FlexBox from "components/atoms/FlexBox";
import BodyText from "components/atoms/BodyText";
import BlockUI from "components/molecules/BlockUi";
import { SubActionButton } from "components/atoms/Button";
import { ActionButton } from "components/atoms/Button/SubActionButton";
import FormContents from "components/atoms/Form/FormContents";
import { ShiftViewDomain } from "domain/master/attend/shiftView";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormTitle from "components/atoms/Form/FormTitle";
import useShiftList, { useShiftDetail } from "./hooks";
import OrganizationFilter from "../HopeShiftForm/OrganizationFilter";
import EmploymentFilter from "../HopeShiftForm/EmploymentFilter";
import ShiftDetailModal from "./ShiftDetailModal";
import { utilityColor, textFontSize } from "components/styles";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import useIsMobile from "hooks/useIsMobile";
import { Container, Row, Col } from "react-grid-system";

function dayOfWeekAsString(dayIndex: number) {
  return ["日", "月", "火", "水", "木", "金", "土"][dayIndex];
}

const mobileColumns: Array<Column<ShiftViewDomain>> = [
  {
    Header: "日付",
    id: "date",
    Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => (
      <BodyText
        customStyle={css({
          display: "flex",
          placeContent: "center",
          color:
            cell.row.original.weekdayColor === "white"
              ? "black"
              : cell.row.original.weekdayColor === "rgba(255,182,193,0.25)"
              ? "#ED5D5D"
              : "#007BC3",
        })}
      >
        {`${moment(cell.row.original.targetDate).format("MM/DD")}(${dayOfWeekAsString(
          new Date(cell.row.original.targetDate).getDay(),
        )})`}
      </BodyText>
    ),
  },
  {
    Header: "出勤組織",
    id: "startTime",
    Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => {
      if (cell.row.original.dayStatus === 2 || cell.row.original.dayStatus === 3) {
        return <BodyText customStyle={css({ color: "#333333" })}>{cell.row.original.orgName}</BodyText>;
      }
      return <p />;
    },
  },
  {
    Header: "勤務予定",
    id: "endTime",
    Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => {
      const { storeList } = useShiftDetail(cell.row.original.targetDate);

      if (cell.row.original.dayStatus === 2 || cell.row.original.dayStatus === 3) {
        if (cell.row.original.holidayName) {
          return (
            <BodyText customStyle={css({ color: "#333333", display: "flex", placeContent: "center" })}>
              {cell.row.original.holidayName}
            </BodyText>
          );
        } else {
          if (cell.row.original.startTime && cell.row.original.endTime) {
            return (
              <div
                css={css({
                  display: "flex",
                  flexDirection: "column",
                })}
              >
                {(storeList ?? [])?.map((store) => (
                  <BodyText
                    customStyle={css({
                      color: "#333333",
                    })}
                  >
                    {`${moment(store.startTime).format("HH:mm")}〜${moment(store.endTime).format("HH:mm")}${
                      store.businessName ? ` ${store.businessName}` : ""
                    }`}
                  </BodyText>
                ))}
              </div>
            );
          }
        }
      } else if (cell.row.original.dayStatus === 1) {
        return <BodyText customStyle={css({ color: "#333333" })}>{cell.row.original.holidayName}</BodyText>;
      }
      return <p />;
    },
  },
];

const ShiftViewForm: React.FC = () => {
  const {
    selectedTimeString,
    setSelectedTimeString,
    selectedDate,
    setSelectedDate,
    detailShiftModalOpen,
    setDetailShiftModalOpen,
    shiftList,
    blocking,
    date,
    setDate,
  } = useShiftList();
  const isMobile = useIsMobile();

  const columns: Array<Column<ShiftViewDomain>> = React.useMemo(
    () => [
      {
        Header: "日付",
        id: "date",
        Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => (
          <BodyText
            customStyle={css({
              display: "flex",
              placeContent: "center",
              color:
                cell.row.original.weekdayColor === "white"
                  ? "black"
                  : cell.row.original.weekdayColor === "rgba(255,182,193,0.25)"
                  ? "#ED5D5D"
                  : "#007BC3",
            })}
          >
            {`${moment(cell.row.original.targetDate).format("YYYY/MM/DD ")}(${dayOfWeekAsString(
              new Date(cell.row.original.targetDate).getDay(),
            )})`}
          </BodyText>
        ),
      },
      {
        Header: "シフト確定状況",
        id: "status",
        Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => {
          if (cell.row.original.fixStatus == 1) {
            return (
              <FlexBox customStyle={css({ textAlign: "center" })}>
                <FlexBoxItem width="100%">
                  <div
                    style={{
                      backgroundColor: "#22AEE5",
                      fontSize: textFontSize.sm,
                      borderRadius: "3px",
                      padding: "5px",
                      color: utilityColor.white,
                    }}
                  >
                    シフト確定済
                  </div>
                </FlexBoxItem>
              </FlexBox>
            );
          } else {
            return <BodyText />;
          }
        },
      },
      {
        Header: "詳細",
        id: "details",
        Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => {
          if (cell.row.original.dayStatus === 2 || cell.row.original.dayStatus === 3) {
            return (
              <FlexBox customStyle={css({ display: "flex", placeContent: "center" })}>
                <SubActionButton
                  action={ActionButton.EDIT}
                  text="詳細"
                  onClick={() => {
                    setSelectedTimeString(
                      `(${moment(cell.row.original.startTime).format("HH:mm")} 〜 ${moment(
                        cell.row.original.endTime,
                      ).format("HH:mm")})`,
                    );
                    setSelectedDate(new Date(cell.row.original.targetDate));
                    setDetailShiftModalOpen(true);
                  }}
                />
              </FlexBox>
            );
          }

          return <p />;
        },
      },
      {
        Header: "出勤組織",
        id: "startTime",
        Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => {
          if (cell.row.original.dayStatus === 2 || cell.row.original.dayStatus === 3) {
            return (
              <BodyText customStyle={css({ paddingLeft: "6px", color: "#333333" })}>
                {cell.row.original.orgName}
              </BodyText>
            );
          }
          return <p />;
        },
      },
      {
        Header: "勤務予定",
        id: "endTime",
        Cell: (cell: { row: { isExpanded: boolean; original: ShiftViewDomain } }) => {
          if (cell.row.original.dayStatus === 2 || cell.row.original.dayStatus === 3) {
            if (cell.row.original.holidayName) {
              return (
                <BodyText
                  customStyle={css({ paddingLeft: "20px", color: "#333333", display: "flex", placeContent: "center" })}
                >
                  {cell.row.original.holidayName}
                </BodyText>
              );
            } else {
              if (cell.row.original.startTime && cell.row.original.endTime) {
                return (
                  <BodyText
                    customStyle={css({
                      paddingLeft: "20px",
                      color: "#333333",
                      display: "flex",
                      placeContent: "center",
                    })}
                  >
                    {`${moment(cell.row.original.startTime).format("HH:mm")}〜${moment(
                      cell.row.original.endTime,
                    ).format("HH:mm")}`}
                  </BodyText>
                );
              }
            }
          } else if (cell.row.original.dayStatus === 1) {
            return (
              <BodyText customStyle={css({ paddingLeft: "20px", color: "#333333" })}>
                {cell.row.original.holidayName}
              </BodyText>
            );
          }
          return <p />;
        },
      },
    ],
    [setDetailShiftModalOpen, setSelectedDate, setSelectedTimeString],
  );

  return (
    <React.Fragment>
      <BlockUI blocking={blocking}>
        <FormContents customStyle={css(isMobile ? { padding: "8px 0" } : {})}>
          <FormTitle title="シフト閲覧" bold={true} customStyle={css(isMobile ? { margin: "0 16px 8px 16px" } : {})} />
          <Container fluid={true} style={{ padding: isMobile ? "0 16px" : "0 30px" }}>
            <Row style={{ paddingLeft: isMobile ? 0 : "8px", paddingBottom: "10px" }}>
              <Col
                md={1}
                xs={12}
                style={{ display: "flex", alignItems: "center", marginBottom: isMobile ? "4px" : 0 }}
              >
                <BodyText>営業月</BodyText>
              </Col>
              <Col md={6} xs={12} style={{ paddingLeft: isMobile ? "24px" : "32px" }}>
                <CalendarMonth
                  openActionChevron={true}
                  date={date}
                  setDate={(d: Date) => {
                    sessionStorage.setItem("shiftView.targetDate", moment(d).format("YYYY/MM/DD"));
                    setDate(d);
                  }}
                />
              </Col>
            </Row>

            <OrganizationFilter />
            <EmploymentFilter />
          </Container>
          <div
            css={css({
              table: {
                th: isMobile
                  ? {
                      padding: isMobile ? "4px" : "14px 10px",
                      ":nth-of-type(1)": {
                        width: "70px",
                        cursor: "default",
                      },
                      ":nth-child(2)": {
                        width: "auto",
                        cursor: "default",
                      },
                      ":nth-of-type(3)": {
                        width: "120px",
                        cursor: "default",
                      },
                    }
                  : {
                      ":nth-of-type(1)": {
                        width: "15%",
                        cursor: "default",
                      },
                      ":nth-child(2)": {
                        width: "20%",
                        cursor: "default",
                      },
                      ":nth-of-type(3)": {
                        width: "10%",
                        cursor: "default",
                      },
                      ":nth-of-type(4)": {
                        width: "30%",
                        cursor: "default",
                      },
                      ":nth-of-type(5)": {
                        width: "25%",
                        cursor: "default",
                      },
                    },
                td: {
                  padding: isMobile ? "4px" : "14px 10px",
                  ":nth-of-type(1)": {
                    textAlign: "center",
                  },
                },
              },
            })}
          >
            <DataTable
              columns={isMobile ? mobileColumns : columns}
              data={shiftList}
              isGlobalFilter={false}
              isHiddenPagination={isMobile}
              initPageSize={isMobile ? 50 : 20}
              minWidth={isMobile ? "100%" : undefined}
            />
          </div>
          <ShiftDetailModal
            time={selectedTimeString}
            targetDate={selectedDate.toISOString()}
            open={detailShiftModalOpen}
            openHandler={setDetailShiftModalOpen}
          />
        </FormContents>
      </BlockUI>
    </React.Fragment>
  );
};

export default ShiftViewForm;
