import SidebarTemplate from 'components/templates/SidebarTemplate';
import PageTitle from 'components/atoms/PageTitle';
import React from 'react';
import AdvertisingMasterAddForm from 'components/organismos/master/sales/AdvertisingMasterAddForm';
import { useParams } from 'react-router-dom';

const AdvertisingAddPage = () => {
  const { advertisingMediaMstCode } = useParams();
  return (
    <SidebarTemplate>
      <PageTitle title={advertisingMediaMstCode ? '広告媒体編集' : '広告媒体作成'} />
      <AdvertisingMasterAddForm />
    </SidebarTemplate>
  );
};

export default AdvertisingAddPage;
