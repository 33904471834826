/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import useToastNotification from "hooks/useToastNotification";
import useBarcodeRecorder from "./hooks";
import BarcodeRecorderUI from "./BarcodeRecorderUI";

const BarcodeRecorder: React.FC<{
  dataTimes: any;
  dataSettings: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  disabledRecorder: boolean;
  fetchHistoryData: any;
}> = ({
  dataTimes,
  dataSettings,
  setIsLoading,
  disabledRecorder,
  fetchHistoryData,
}) => {
  const { errorNotification } = useToastNotification();
  const {
    handleSubmitBarcode,
    formik,
    isConfirm,
    lastStampType,
    handleRecorder,
  } = useBarcodeRecorder(
    dataSettings,
    dataTimes.resultCurrentTime,
    setIsLoading,
    fetchHistoryData
  );
  const handleSubmit = () => {
    if (formik.values.inputBarcodeId) {
      handleSubmitBarcode();
    } else {
      errorNotification("打刻IDを入力してください。");
    }
  };
  return (
    <BarcodeRecorderUI
      formik={formik}
      dataTimes={dataTimes}
      isConfirm={isConfirm}
      handleSubmit={handleSubmit}
      disabledRecorder={disabledRecorder}
      lastStampType={lastStampType}
      handleRecorder={handleRecorder}
    />
  );
};

export default BarcodeRecorder;
