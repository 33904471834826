/** @jsx jsx */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { Column } from "react-table";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import SalaryHistoryDownload from "domain/master/attend/salaryHistoryDownload";
import SubActionButton, {
  ActionButton,
} from "components/atoms/Button/SubActionButton";
import ConfirmModal from "components/organismos/ConfirmModal";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  cancelAchivementHistoryDownloadById,
  deleteAchivementHistoryDownloadById,
  getAchievementDownloadFileCSV,
  postProcessingDownloadAchievement,
} from "api/achievementConfirm";
import useToastNotification from "hooks/useToastNotification";
import { AttendAchivementLayoutContext } from "./AttendAchievementLayoutOutput";
import FormTitle from "components/atoms/Form/FormTitle";
import Icon from "components/atoms/Icon";
import { exportFilePDF } from "api/attendAchievementOutput";

const logoFlag = process.env.REACT_APP_LOGO_FLAG;

const styles = {
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  labelPrimary: css({
    color: "#007BC3",
  }),
  labelError: css({
    color: "#E95562",
    width: "100%",
    display: "block"
  }),
  labelErrorIcon: css({
    marginTop: "10px",
    display: "block",
    textAlign: "center",
    width: "100%",
    height: "15px"
  }),
  
};

const SalaryHistoryDownloadForm: React.FC<{
  salaryHistoryDownloadList: Array<SalaryHistoryDownload>;
  setSalaryHistoryDownloadList: Dispatch<
    SetStateAction<Array<SalaryHistoryDownload>>
  >;
  setReloadHistory: Dispatch<SetStateAction<boolean>>;
  pageId: number;
  isCancelRequest: boolean;
  setIsCancelRequest: Dispatch<SetStateAction<boolean>>
}> = ({
  salaryHistoryDownloadList,
  setSalaryHistoryDownloadList,
  setReloadHistory,
  pageId,
  isCancelRequest,
  setIsCancelRequest
}) => {
  const context = useContext(AttendAchivementLayoutContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [achivementHistoryId, setAchivementHistoryId] = useState("");
  const { successNotification, errorNotification } = useToastNotification();

  const deleteHistoryById = (achivementHistoryId: string) => {
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || '';
    const params = {
      pageId: pageId,
      achievementHistoryDownloadId: achivementHistoryId,
      loginStaffCode
    };
    context.setIsLoading(true);
    deleteAchivementHistoryDownloadById(params)
      .then((response) => {
        setSalaryHistoryDownloadList(
          response.map((result: any) => new SalaryHistoryDownload(result))
        );
        setReloadHistory(true);
        successNotification("勤怠データを削除しました。");
        setConfirmModalOpen(!confirmModalOpen);
        context.setIsLoading(false);
      })
      .catch(() => {
        setConfirmModalOpen(false);
        context.setIsLoading(false);
        errorNotification("勤怠データをまだ削除しません。");
      });
  };

  const cancelHistoryById = (achivementHistoryId: string) => {
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || '';
    const params = {
      pageId: pageId,
      achievementHistoryDownloadId: achivementHistoryId,
      loginStaffCode
    };
    context.setIsLoading(true);
    setIsCancelRequest(true);
    cancelAchivementHistoryDownloadById(params)
      .then((response) => {
        setSalaryHistoryDownloadList(
          response.map((result: any) => new SalaryHistoryDownload(result))
        );
        context.setIsLoading(false);
        setReloadHistory(true);
      })
      .catch(() => {
        context.setIsLoading(false);
      });
  };

  const handleDownload = (achivementHistoryId: string, fileName: string) => {
    const params = {
      companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
      achievementHistoryDownloadId: achivementHistoryId,
      pageId: pageId,
      fileName: fileName,
    };
    context.setIsLoading(true);
    getAchievementDownloadFileCSV(params, fileName)
      .then(() => {
        context.setIsLoading(false);
      })
      .catch((error) => {
        context.setIsLoading(false);
      });
  };

  const reloadDownload = (data: any) => { 
    const orgCode = data.orgName.split(" ")[0];
    const targetDate = moment(data.periodFrom).format("YYYY-MM-DD");
    const achievementDownloadId = data.attendAchievementDownloadId;
    const fileName = data.file.split("/")[2];
    const createUser = sessionStorage.getItem("loginUser.staffCode") || '';
    const initialParams = {
      targetDateFrom: "",
      targetDateTo: "",
      employmentId: "",
      viewPeriod: "",
      closingDate: "",
      orgCode: orgCode || "",
      outputLayoutId: "",
      targetYmd: "",
      pageId: 2,
      attendFlag: 0,
      flagDownloadAll: 0,
      createUser: createUser,
      achievementDownloadId: achievementDownloadId,
      underFlag: 0
    }; 
    
    postProcessingDownloadAchievement({
      ...initialParams,
      ...{
        targetDate: moment(targetDate).format("YYYY-MM-01"),
        staffCode: "",
        isUpdate: 1,
        fileName: fileName
      },
    }).then((response: any) => {
      context.setIsLoading(false);
      context.setReloadHistory(true);
      exportFilePDF({
        orgCode,
        targetDate: moment(targetDate).format("YYYY-MM-01"),
        achievementDownloadId: achievementDownloadId || "",
        fileName: fileName
      })
        .then((response: any) => {
          context.setIsLoading(false);
          context.setReloadHistory(true);
        })
        .catch((error) => {
          if(isCancelRequest) {
            setIsCancelRequest(false);
          }
          context.setIsLoading(false);
          setReloadHistory(true);
        });
    }); 
  };

  const columns: Array<Column<SalaryHistoryDownload>> = useMemo(
    () => [
      {
        Header: "営業月",
        accessor: "period",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          moment(cell.row.original.periodFrom).format("YYYY/MM") || "-",
      },
      {
        Header: "組織",
        accessor: "orgName",
      },
      {
        Header: "作成日時",
        accessor: "createDate",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) =>
          moment(cell.row.original.createDate).format("YYYY年MM月DD日 HH:mm:ss") ||
          "-",
      },
      {
        Header: "ステータス",
        accessor: "statusDownload",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => {
          return (
            <React.Fragment>

              {cell.row.original.statusDownload === 0 && logoFlag === "2" && (
                <div>
                   <img src="/cydas_loading_logo_2.gif" alt="loading" width={30} height={30}/>
                  <div>作成中</div>
                </div>
              )}
              {cell.row.original.statusDownload === 0 && logoFlag === "1" && (
                <div>
                  <img src="cydas_loading_logo.gif" height="30" />
                  <div>作成中</div>
                </div>
              )}
              {cell.row.original.statusDownload === 0 && logoFlag === "0" && (
                <div>
                  <img src="./am_loading_logo.gif" height="30" />
                  <div>作成中</div>
                </div>
              )}

              {cell.row.original.statusDownload === 1 && (
                <Link
                  to="#"
                  css={styles.labelPrimary}
                  onClick={() =>
                    handleDownload(
                      cell.row.original.attendAchievementDownloadId,
                      cell.row.original.file
                    )
                  }
                >
                  ダウンロードする
                </Link>
              )}

              {cell.row.original.statusDownload === 2 && (
                <div>
                  <Link to="#" css={styles.labelError} onClick={() => {}}>
                  作成が失敗しました
                  </Link>
                  <Icon customStyle={styles.labelErrorIcon} onClick={() => reloadDownload(cell.row.original.rawData)} type={"reload"} color={"#333333"}/>
                </div>
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: (cell: { row: { isExpanded: boolean; original: any } }) => (
          <React.Fragment>
            {(cell.row.original.statusDownload === 1 || cell.row.original.statusDownload === 2) && (
              <SubActionButton
                action={ActionButton.DELETE}
                text="削除"
                onClick={() => {
                  setConfirmModalOpen(!confirmModalOpen);
                  setAchivementHistoryId(
                    cell.row.original.attendAchievementDownloadId
                  );
                }}
              />
            )}
            {(cell.row.original.statusDownload === 0) && (
              <SubActionButton
                action={ActionButton.DELETE}
                text="キャンセルする"
                onClick={() => {
                  cancelHistoryById(cell.row.original.attendAchievementDownloadId)
                }}
              />
            )}
          </React.Fragment>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%" }}>
        <FormTitle
          title="ダウンロードする"
          bold={true}
          customStyle={css({ marginBottom: 0 })}
        />
        <DataTable
          className="table-no-border-left-right tbl-salary-download"
          columns={columns}
          data={salaryHistoryDownloadList}
          isGlobalFilter={false}
          initPageSize={10}
          tableId={1}
        />
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={() => setConfirmModalOpen(!confirmModalOpen)}
        onSubmit={() => deleteHistoryById(achivementHistoryId)}
        title={"勤怠データを削除"}
        // eslint-disable-next-line no-nested-ternary
        content={"勤怠データを削除します。よろしいですか？"}
        // eslint-disable-next-line no-nested-ternary
        submitText={"削除する"}
        typeSubmit={"reject"}
      />
    </React.Fragment>
  );
};

export default SalaryHistoryDownloadForm;
