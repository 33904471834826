import React, { SVGProps } from 'react';

const Org: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 4H11C11.55 4 12 4.45 12 5V7C12 7.55 11.55 8 11 8H9C8.45 8 8 7.55 8 7V5C8 4.45 8.45 4 9 4Z" fill="#003993"/>
    <path d="M17 4H15C14.45 4 14 4.45 14 5V7C14 7.55 14.45 8 15 8H17C17.55 8 18 7.55 18 7V5C18 4.45 17.55 4 17 4Z" fill="#003993"/>
    <path d="M21 4H23C23.55 4 24 4.45 24 5V7C24 7.55 23.55 8 23 8H21C20.45 8 20 7.55 20 7V5C20 4.45 20.45 4 21 4Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M29 14H18V12H16V14H11C9.34 14 8 15.34 8 17V18H10V17C10 16.45 10.45 16 11 16H29C29.55 16 30 16.45 30 17V18H32V17C32 15.34 30.66 14 29 14Z" fill="#003993"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25 0H7C5.34 0 4 1.34 4 3V9C4 10.66 5.34 12 7 12H16H18H25C26.66 12 28 10.66 28 9V3C28 1.34 26.66 0 25 0ZM26 9C26 9.55 25.55 10 25 10H7C6.45 10 6 9.55 6 9V3C6 2.45 6.45 2 7 2H25C25.55 2 26 2.45 26 3V9Z" fill="#007BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 18H10H8H3C1.34 18 0 19.34 0 21V23C0 24.66 1.34 26 3 26H15C16.66 26 18 24.66 18 23V21C18 19.34 16.66 18 15 18ZM16 23C16 23.55 15.55 24 15 24H3C2.45 24 2 23.55 2 23V21C2 20.45 2.45 20 3 20H15C15.55 20 16 20.45 16 21V23Z" fill="#007BC3"/>
    <path d="M7 32H6H4H3C1.34 32 0 33.34 0 35V37C0 38.66 1.34 40 3 40H7C8.66 40 10 38.66 10 37V35C10 33.34 8.66 32 7 32ZM8 37C8 37.55 7.55 38 7 38H3C2.45 38 2 37.55 2 37V35C2 34.45 2.45 34 3 34H7C7.55 34 8 34.45 8 35V37Z" fill="#007BC3"/>
    <path d="M21 32H20H18H17C15.34 32 14 33.34 14 35V37C14 38.66 15.34 40 17 40H21C22.66 40 24 38.66 24 37V35C24 33.34 22.66 32 21 32ZM22 37C22 37.55 21.55 38 21 38H17C16.45 38 16 37.55 16 37V35C16 34.45 16.45 34 17 34H21C21.55 34 22 34.45 22 35V37Z" fill="#007BC3"/>
    <path d="M35 32H34H32H31C29.34 32 28 33.34 28 35V37C28 38.66 29.34 40 31 40H35C36.66 40 38 38.66 38 37V35C38 33.34 36.66 32 35 32ZM36 37C36 37.55 35.55 38 35 38H31C30.45 38 30 37.55 30 37V35C30 34.45 30.45 34 31 34H35C35.55 34 36 34.45 36 35V37Z" fill="#007BC3"/>
    <path d="M37 18H32H30H25C23.34 18 22 19.34 22 21V23C22 24.66 23.34 26 25 26H30H32H37C38.66 26 40 24.66 40 23V21C40 19.34 38.66 18 37 18ZM38 23C38 23.55 37.55 24 37 24H25C24.45 24 24 23.55 24 23V21C24 20.45 24.45 20 25 20H37C37.55 20 38 20.45 38 21V23Z" fill="#007BC3"/>
    <path d="M7 28C5.34 28 4 29.34 4 31V32H6V31C6 30.45 6.45 30 7 30H18V32H20V30H31C31.55 30 32 30.45 32 31V32H34V31C34 29.69 33.17 28.58 32 28.17V26H30V28H7Z" fill="#003993"/>
  </svg>

);

export default Org;
