import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import SubActionButton from './SubActionButton';
import DeleteButton from './DeleteButton';
import InfoButton from './InfoButton';
import RecorderButton from './RecorderButton';
import ICCardConnectButton from './ICCardConnectButton';
import UploadButton from './UploadButton';
import DownLoadButton from './DownLoadButton';

export {
  PrimaryButton,
  SecondaryButton,
  SubActionButton,
  DeleteButton,
  InfoButton,
  RecorderButton,
  ICCardConnectButton,
  UploadButton,
  DownLoadButton
};

export default PrimaryButton;
