/* eslint-disable max-len */
/** @jsx jsx */
import React, { useContext } from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { isSafari, isIPad13, isTablet } from 'react-device-detect';
import TextForm from 'components/molecules/TextForm';
import Checkbox from 'components/molecules/Checkbox';
import { EditRoleContext } from "./../MasterRoleEditForm";
import FormField from "components/atoms/Form/FormField";
import BodyText from 'components/atoms/BodyText';
import { types } from './../actions'
import FormPadding from 'components/atoms/Form/FormPadding';

const MasterBaseRole: React.FC<{editable: number}> = ({ editable }) =>{
    // GET CONTEXT STORAGE
    const context = useContext(EditRoleContext);

    return (
        <FormPadding customStyle={css({padding: '0 25%'})}>
            <FormField>
                <TextForm
                    name="roleCode"
                    label="権限コード"
                    value={context.state.data.roleCode}
                    onChange={(e) => 
                        context.dispatch({type: types.ON_CHANGE , payload: {path: 'roleCode' , data: e.target.value}})
                    }
                    required={true}
                    disable={editable !== 1}
                />
            </FormField>
            <FormField>
                <TextForm
                    name="roleName"
                    label="権限名"
                    value={context.state.data.roleName}
                    onChange={(e) => 
                        context.dispatch({type: types.ON_CHANGE , payload: {path: 'roleName' , data: e.target.value}})
                    }
                    required={true}
                    disable={editable !== 1}
                />
            </FormField>
            <FormField customStyle={css({ width: '120px' })}>
                <TextForm
                    name="accessLevel"
                    label="権限レベル"
                    value={String(context.state.data.accessLevel)}
                    onChange={(e) => 
                        context.dispatch({type: types.ON_CHANGE , payload: {path: 'accessLevel' , data: Number(e.target.value)}})
                    }
                    required={true}
                    type="number"
                    disable={context.state.data.administrator === true || editable !== 1}
                />
            </FormField>
            <BodyText color='#007BC3'>値が小さいほど、上位レベルとなります</BodyText>
            <FormField customStyle={css({ marginTop: '20px' })}>
                <Checkbox
                    id="administrator"
                    name="administrator"
                    label="システム管理者用権限"
                    value={String(context.state.data.administrator)}
                    checked={Boolean(context.state.data.administrator)}
                    onChange={(e) => {
                        let isChecked = e.target.checked;
                        context.dispatch({type: types.ON_CHANGE , payload: {path: 'administrator' , data: isChecked}})
                    }}
                    disabled={editable !== 1}
                />
            </FormField>
            <BodyText color='#007BC3'>システム管理者用権限は全機能使用可能、全組織アクセス可能となります</BodyText>
        </FormPadding>
    );
};

export default MasterBaseRole;
