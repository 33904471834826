/** @jsx jsx */
import React from 'react';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import BodyText from 'components/atoms/BodyText';
import { css, jsx } from '@emotion/core';
import moment from 'moment';

const styles = {
  container: css({
    minWidth: '600px',
    marginTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
  }),
  line: css({
    marginBottom: '10px',
  }),
  tableStamp: css({
    width: '100%',
    fontSize: '12px',
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  }),
};

const HistoryModalModificationApplication:React.FC<{history:any}> = ({ history }) => {
  return (
    <div css={styles.container}>
      <FlexBox customStyle={styles.line}>
        <FlexBoxItem width="100px">
          <FormLabel
            label="申請種別 : "
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <BodyText>
            {history.historyTypeName}
          </BodyText>
        </FlexBoxItem>
      </FlexBox>
      <FlexBox customStyle={styles.line}>
        <FlexBoxItem width="100px">
          <FormLabel
            label="申請者： "
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <BodyText>
            {`${history.applicationStaffCode} ${history.applicationStaffName}`}
          </BodyText>
        </FlexBoxItem>
      </FlexBox>
      <FlexBox customStyle={styles.line}>
        <FlexBoxItem width="100px">
          <FormLabel
            label="申請日時： "
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <BodyText>
            {moment(history.applicationDate).format('YYYY/MM/DD HH:mm:ss')}
          </BodyText>
        </FlexBoxItem>
      </FlexBox>
      <FlexBox customStyle={styles.line}>
        <FlexBoxItem width="100px">
          <FormLabel
            label="出勤組織： "
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <BodyText>
            {`${history.orgCode} ${history.orgName}`}
          </BodyText>
        </FlexBoxItem>
      </FlexBox>
      <table css={styles.tableStamp}>
        <thead>
          <tr>
            <th css={css({ width: '20%' })}>開始時間</th>
            <th css={css({ width: '20%' })}>終了時間</th>
            <th css={css({ width: '20%' })}>休憩</th>
          </tr>
        </thead>
        <tbody>
          {history.stampList.map((stamp:any) => (
            <tr>
              <td css={css({ padding: '10px' })}>{stamp.startTime == null ? '' : moment(stamp.startTime).format('YYYY/MM/DD HH:mm:ss')}</td>
              <td css={css({ padding: '10px' })}>{stamp.endTime == null ? '' : moment(stamp.endTime).format('YYYY/MM/DD HH:mm:ss')}</td>
              <td css={css({ padding: '10px' })}>{stamp.businessName}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div css={css({ display: history.historyType === 0 ? 'none' : 'block' })}>
        <FlexBox customStyle={styles.line}>
          <FlexBoxItem width="100px">
            <FormLabel
              label="修正理由： "
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <BodyText>
              {history.applicationReason}
            </BodyText>
          </FlexBoxItem>
        </FlexBox>
        {history.applicationStatus === 2
          && (
          <FlexBox customStyle={styles.line}>
            <FlexBoxItem width="100px">
              <FormLabel
                label="差戻理由： "
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <BodyText>
                {history.rejectReason}
              </BodyText>
            </FlexBoxItem>
          </FlexBox>
          )}
        {history.applicationStatus === 1
          && (
          <FlexBox customStyle={styles.line}>
            <FlexBoxItem width="100px">
              <FormLabel
                label="承認日時： "
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <BodyText>
                {history.approvalDate ? moment(history.approvalDate).format('YYYY/MM/DD HH:mm:ss') : ''}
              </BodyText>
            </FlexBoxItem>
          </FlexBox>
          )}
        {history.applicationStatus === 2
          && (
          <FlexBox customStyle={styles.line}>
            <FlexBoxItem width="100px">
              <FormLabel
                label="差戻日時： "
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <BodyText>
                {history.approvalDate ? moment(history.approvalDate).format('YYYY/MM/DD HH:mm:ss') : ''}
              </BodyText>
            </FlexBoxItem>
          </FlexBox>
          )}
        {history.applicationStatus === 1
          && (
          <FlexBox customStyle={styles.line}>
            <FlexBoxItem width="100px">
              <FormLabel
                label="承認者： "
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <BodyText>
                {`${history.approvalStaffCode} ${history.approvalStaffName}`}
              </BodyText>
            </FlexBoxItem>
          </FlexBox>
          )}
        {history.applicationStatus === 2
          && (
          <FlexBox customStyle={styles.line}>
            <FlexBoxItem width="100px">
              <FormLabel
                label="差戻者： "
              />
            </FlexBoxItem>
            <FlexBoxItem>
              <BodyText>
                {`${history.approvalStaffCode} ${history.approvalStaffName}`}
              </BodyText>
            </FlexBoxItem>
          </FlexBox>
          )}
      </div>
    </div>
  );
};


export default HistoryModalModificationApplication;
