export interface Agreement36Application {
  modifierApplicationId: string,
  staffCode: string,
  orgCode: string,
  orgName: string,
  targetDate: string,
  targetDateStr: string,
  version: string,
  buttonType: number,
  workTimeData: any,
  applicationReason: string,
  createUser: string,
  updateUser: string,
  applicationExpectedOvertimeHoursStr: string
  modifiyStatus: any,
  applicationStatus: any,
  deleteAvailable: boolean,
  updateUserCode: string,
  historyList: any,
  createDate: any
}

export default class AttendAgreement36ApplicationDomain {
constructor(private rawData: Agreement36Application) {
  // do nothing
}

static generateInitial(): AttendAgreement36ApplicationDomain {
  return new AttendAgreement36ApplicationDomain({
    modifierApplicationId: '',
    staffCode: '',
    orgCode: '',
    orgName: '',
    targetDate: '',
    targetDateStr: '',
    version: '',
    workTimeData: null,
    buttonType: 0,
    applicationReason: '',
    createUser: '',
    updateUser: '',
    applicationExpectedOvertimeHoursStr: '',
    modifiyStatus: null,
    applicationStatus: null,
    deleteAvailable: false,
    updateUserCode: '',
    historyList: [],
    createDate: null
  });
}

get workTimeData(): any {
  return this.rawData.workTimeData;
}

set workTimeData(workTimeData: any) {
  this.rawData.workTimeData = workTimeData;
}

get createDate(): any {
  return this.rawData.createDate;
}

set createDate(createDate: any) {
  this.rawData.createDate = createDate;
}

getRawData(): Agreement36Application {
  return this.rawData;
}

get modifierApplicationId(): string {
  return this.rawData.modifierApplicationId;
}

set modifierApplicationId(modifierApplicationId: string) {
  this.rawData.modifierApplicationId = modifierApplicationId;
}

get deleteAvailable(): boolean {
  return this.rawData.deleteAvailable;
}

set deleteAvailable(deleteAvailable: boolean) {
  this.rawData.deleteAvailable = deleteAvailable;
}

get applicationExpectedOvertimeHoursStr(): string {
  return this.rawData.applicationExpectedOvertimeHoursStr || '';
}

set applicationExpectedOvertimeHoursStr(applicationExpectedOvertimeHoursStr: string) {
  this.rawData.applicationExpectedOvertimeHoursStr = applicationExpectedOvertimeHoursStr;
}

get updateUserCode(): string {
  return this.rawData.updateUserCode || '';
}

set updateUserCode(updateUserCode: string) {
  this.rawData.updateUserCode = updateUserCode;
}

get modifiyStatus(): any {
  return this.rawData.modifiyStatus;
}

set modifiyStatus(modifiyStatus: any) {
  this.rawData.modifiyStatus = modifiyStatus;
}

get applicationStatus(): any {
  return this.rawData.applicationStatus;
}

set applicationStatus(applicationStatus: any) {
  this.rawData.applicationStatus = applicationStatus;
}

get staffCode(): string {
  return this.rawData.staffCode;
}

set staffCode(staffCode: string) {
  this.rawData.staffCode = staffCode;
}

get orgCode(): string {
  return this.rawData.orgCode;
}

set orgCode(orgCode: string) {
  this.rawData.orgCode = orgCode;
}

get orgName(): string {
  return this.rawData.orgName;
}

set orgName(orgName: string) {
  this.rawData.orgName = orgName;
}

get targetDateStr(): string {
  return this.rawData.targetDateStr;
}

set targetDateStr(targetDateStr: string) {
  this.rawData.targetDateStr = targetDateStr;
}

get targetDate(): string {
  return this.rawData.targetDate;
}

set targetDate(targetDate: string) {
  this.rawData.targetDate = targetDate;
}

get applicationReason(): string {
  return this.rawData.applicationReason || '';
}

set applicationReason(applicationReason: string) {
  this.rawData.applicationReason = applicationReason;
}

get createUser(): string {
  return this.rawData.createUser;
}

set createUser(createUser: string) {
  this.rawData.createUser = createUser;
}

get updateUser(): string {
  return this.rawData.updateUser;
}

set updateUser(updateUser: string) {
  this.rawData.updateUser = updateUser;
}

get buttonType(): number {
  return this.rawData.buttonType;
}

set buttonType(buttonType: number) {
  this.rawData.buttonType = buttonType;
}

get historyList(): any {
  return this.rawData.historyList;
}

set historyList(historyList: any) {
  this.rawData.historyList = historyList; 
}
}
