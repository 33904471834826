/** @jsx jsx */

import React, { useState, useEffect, useRef, forwardRef } from "react";
import DatePicker from "react-datepicker";
import Icon from "components/atoms/Icon";
import { utilityColor } from "components/styles";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import Select, { OptionType } from "components/atoms/Select";
import { css, jsx } from "@emotion/core";
import { getDate, ModeAction, actionChevron, addAYear, subAYear } from "./Helpper";
import { Option, Input, IndicatorsContainer, MenuComponent } from "./Component";
import useIsMobile from "hooks/useIsMobile";

const listMonth = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

const chevronLeftStyle = css({
  position: "absolute",
  left: "-30px",
  top: "50%",
  transform: "translateY(-60%)",
});
const chevronRightStyle = css({
  position: "absolute",
  right: "-26px",
  top: "50%",
  transform: "translateY(-60%)",
});
const readOnlyStyle = css({
  pointerEvents: "none",
});

const MIN_YEAR = 1900;
const MAX_YEAR = 2099;

const CustomInput = forwardRef<any>(({ value, onChange }: any, ref) => (
  <input onChange={onChange} value={value} ref={ref} />
));

const Calendar: React.FC<{
  readOnly?: boolean;
  dateFormat?: string;
  date?: Date;
  setDate: (date: Date | null) => void;
  placeholderText?: string;
  openActionChevron?: boolean;
  modeAction?: ModeAction;
  limitYear?: number;
  maxYear?: number;
  canNull?: boolean;
  hiddenButtonRemove?: boolean;
}> = ({
  dateFormat = "yyyy/MM/dd",
  date,
  setDate,
  readOnly = false,
  placeholderText = "yyyy/mm/dd",
  modeAction = "day",
  openActionChevron = false,
  canNull = false,
  hiddenButtonRemove = false,
}) => {
  const isMobile = useIsMobile();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [optionsYear, setOptionsYear] = useState<Array<OptionType>>([]);
  const [optionsMonth, setOptionsMonth] = useState<Array<OptionType>>([]);
  const [dateInternal, setDateInternal] = useState(date);

  const wrapperRefContainer = useRef<HTMLDivElement>(null);

  const rangeLimitTime = {
    minTime: moment()
      .set("year", MIN_YEAR)
      .startOf("year")
      .toDate(),
    maxTime: moment()
      .set("year", MAX_YEAR)
      .endOf("year")
      .toDate(),
  };

  useEffect(() => {
    setDateInternal(date);
  }, [date]);

  useEffect(() => {
    setOptionsMonth(
      listMonth.map((Hours: any) => ({
        value: Hours,
        label: Hours,
      })),
    );
  }, []);

  useEffect(() => {
    var listYear = [];
    for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
      listYear.push(i);
    }
    setOptionsYear(
      listYear.map((year) => {
        return {
          value: `${year}`,
          label: `${year}`,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (!openCalendar) {
      if (dateInternal) {
        setDate(dateInternal);
        setDateInternal(dateInternal);
        return;
      }
      if (!dateInternal && !canNull) {
        setDate(new Date());
        setDateInternal(new Date());
        return;
      }
      setDateInternal(undefined);
      setDate(null);
    }
  }, [openCalendar]);

  const handleClickOutside = (event: any) => {
    if (
      wrapperRefContainer.current &&
      !wrapperRefContainer.current?.contains(event.target) &&
      document.activeElement?.className !== "react-datepicker-ignore-onclickoutside"
    ) {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const validateYear = (date: Date) => {
    if (date.getFullYear() < MIN_YEAR || date.getFullYear() > MAX_YEAR) {
      return false;
    }
    return true;
  };

  return (
    <div
      ref={wrapperRefContainer}
      className={`container-input-date-picker ${readOnly ? "calendar-date-picker__readOnly" : ""}`}
    >
      {openActionChevron && (
        <Icon
          type="chevronLeft"
          color=""
          size="24px"
          verticalAlign="middle"
          customStyle={css([chevronLeftStyle, readOnly && readOnlyStyle])}
          onClick={() => {
            if (dateInternal) {
              let prevDate = actionChevron(dateInternal, modeAction, "previous");
              if (validateYear(prevDate)) {
                setDateInternal(prevDate);
                setDate(prevDate);
              }
            }
          }}
        />
      )}

      <DatePicker
        readOnly={readOnly}
        strictParsing
        maxDate={rangeLimitTime.maxTime}
        minDate={rangeLimitTime.minTime}
        open={openCalendar}
        placeholderText={placeholderText}
        selected={dateInternal}
        onChange={(date) => {
          setDateInternal(getDate(date));
        }}
        onCalendarClose={() => {
          setOpenCalendar(false);
        }}
        onCalendarOpen={() => {
          setOpenCalendar(true);
        }}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
        dateFormat={dateFormat}
        onClickOutside={() => {
          setOpenCalendar(false);
        }}
        onSelect={(date, e: any) => {
          if (e.constructor.name !== "SyntheticEvent") {
            setOpenCalendar(false);
          }
        }}
        popperModifiers={{
          offset: {
            enabled: false,
          },
          flip: { enabled: false },
        }}
        showMonthDropdown
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
        }) => {
          return (
            <div>
              <div className="calendar__year">
                <span
                  className="calendar__arrow calendar__arrow--left"
                  onClick={() => {
                    let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                    tmpDate = subAYear(tmpDate);
                    if (validateYear(tmpDate)) {
                      decreaseYear();
                    }
                  }}
                ></span>
                <div
                  css={css`
                    div {
                      justify-content: center;
                      border: 0;
                      :hover {
                        border: 0;
                      }
                    }
                  `}
                >
                  <Select
                    name="select-month"
                    className="calendar__year-value"
                    value={String(date.getFullYear())}
                    setValue={(arg: string) => changeYear(Number(arg))}
                    options={optionsYear}
                    placeholder="チーフ"
                    controlStyle={{
                      width: "70px",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                    maxMenuHeight={200}
                    customMenuStyle={{
                      marginTop: "0",
                    }}
                    isSearchable={false}
                  />
                </div>
                <span
                  className="calendar__arrow calendar__arrow--right"
                  onClick={() => {
                    let tmpDate = new Date(JSON.parse(JSON.stringify(date)));
                    tmpDate = addAYear(tmpDate);
                    if (validateYear(tmpDate)) {
                      increaseYear();
                    }
                  }}
                ></span>
              </div>
              <div className="calendar__month">
                <span className="calendar__arrow calendar__arrow--left" onClick={decreaseMonth}></span>
                <div
                  css={css`
                    div {
                      justify-content: center;
                      border: 0;
                      :hover {
                        border: 0;
                        cursor: pointer;
                      }
                    }
                  `}
                >
                  <Select
                    name="select-month"
                    className="calendar__month-value"
                    value={String(date.getMonth() + 1)}
                    setValue={(arg: string) => changeMonth(Number(arg) - 1)}
                    options={optionsMonth}
                    placeholder="チーフ"
                    controlStyle={{
                      width: "50px",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    components={{ Option, IndicatorsContainer, MenuComponent, Input }}
                    maxMenuHeight={200}
                    customMenuStyle={{
                      marginTop: "0",
                    }}
                    isSearchable={false}
                  />
                </div>
                <span className="calendar__arrow calendar__arrow--right" onClick={increaseMonth}></span>
              </div>
            </div>
          );
        }}
        customInput={isMobile ? <CustomInput /> : undefined}
      >
        <div
          className="label-current"
          onClick={() => {
            setDateInternal(new Date());
            setOpenCalendar(false);
          }}
        >
          今日
        </div>
      </DatePicker>
      <div
        className="icf-calendar"
        onClick={() => {
          setOpenCalendar(true);
        }}
      >
        <Icon type="date" color={utilityColor.black} />
      </div>
      {canNull && !hiddenButtonRemove && (
        <div
          className="cancel-button-calendar"
          onClick={() => {
            setDate(null);
            setDateInternal(undefined);
          }}
        >
          <Icon type="cancel" color={utilityColor.black} size="10px" />
        </div>
      )}
      {openActionChevron && (
        <Icon
          type="chevronRight"
          color=""
          size="24px"
          verticalAlign="middle"
          customStyle={css([chevronRightStyle, readOnly && readOnlyStyle])}
          onClick={() => {
            if (dateInternal) {
              let nextDate = actionChevron(dateInternal, modeAction, "next");
              setDateInternal(nextDate);
              setDate(nextDate);
            }
          }}
        />
      )}
    </div>
  );
};

export default Calendar;
