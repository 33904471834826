import moment from 'moment';
 import { IAttendStockPaid } from '../interface';
 
 const dateFormat = 'YYYY/MM/DD HH:mm';
 
 /**
  * API getApplyingHolidayList
  */
 export default class ApprovedStockPaidDomain {
   constructor(private rawData: IAttendStockPaid) {
     // do nothing
   }
 
   getRawData(): IAttendStockPaid {
     return this.rawData;
   }
 
   get modifierApplicationId(): string {
     return this.rawData.modifierApplicationId;
   }
 
   set modifierApplicationId(modifierApplicationId: string) {
     this.rawData.modifierApplicationId = modifierApplicationId;
   }
 
   get applicationDate(): string {
     return moment(this.rawData.applicationDate).format(dateFormat);
   }
 
   set applicationDate(applicationDate: string) {
     this.rawData.applicationDate = moment(applicationDate).format(dateFormat);
   }
 
   get applicationStaffCode(): string {
     return this.rawData.applicationStaffCode;
   }
 
   set applicationStaffCode(applicationStaffCode: string) {
     this.rawData.applicationStaffCode = applicationStaffCode;
   }
 
   get orgCode(): string {
     return this.rawData.orgCode;
   }
 
   set orgCode(orgCode: string) {
     this.rawData.orgCode = orgCode;
   }
 
   get staffCode(): string {
     return this.rawData.staffCode;
   }
 
   set staffCode(staffCode: string) {
     this.rawData.staffCode = staffCode;
   }
 
   get applicationStatus(): number {
     return this.rawData.applicationStatus;
   }
 
   set applicationStatus(applicationStatus: number) {
     this.rawData.applicationStatus = applicationStatus;
   }
 
   get modifiyStatus(): number {
     return this.rawData.modifiyStatus;
   }
 
   set modifiyStatus(modifiyStatus: number) {
     this.rawData.modifiyStatus = modifiyStatus;
   }
 
   get modifiyStatusName(): string {
     return this.rawData.modifiyStatusName;
   }
 
   set modifiyStatusName(modifiyStatusName: string) {
     this.rawData.modifiyStatusName = modifiyStatusName;
   }
 
   get applicationReason(): string {
     return this.rawData.applicationReason;
   }
 
   set applicationReason(applicationReason: string) {
     this.rawData.applicationReason = applicationReason;
   }
 
   get rejectReason(): string | '' {
     if (this.rawData.rejectReason) {
       return this.rawData.rejectReason;
     }
 
     return '';
   }
 
   set rejectReason(rejectReason: string | '') {
     this.rawData.rejectReason = rejectReason;
   }
 
   get version(): number {
     return this.rawData.version;
   }
 
   set version(version: number) {
     this.rawData.version = version;
   }
 
   get approvalStaffCode(): string {
     return this.rawData.approvalStaffCode;
   }
 
   set approvalStaffCode(approvalStaffCode: string) {
     this.rawData.approvalStaffCode = approvalStaffCode;
   }
 
   get approvalDate(): string {
     if(!this.rawData.approvalDate) return '';
     return moment(this.rawData.approvalDate).format("YYYY/MM/DD");
   }
 
   set approvalDate(approvalDate: string) {
     this.rawData.approvalDate = moment(approvalDate).format("YYYY/MM/DD");
   }
 
   get holidayId(): string {
     return this.rawData.holidayId;
   }
 
   set holidayId(holidayId: string) {
     this.rawData.holidayId = holidayId;
   }
 
   get holidayDigestiveUnit(): number {
     return this.rawData.holidayDigestiveUnit;
   }
 
   set holidayDigestiveUnit(holidayDigestiveUnit: number) {
     this.rawData.holidayDigestiveUnit = holidayDigestiveUnit;
   }
 
    get holidayStartHm(): string | '' {
     if (this.rawData.holidayStartHm) {
       return this.rawData.holidayStartHm || '';
     }
     return '';
   }
 
   set holidayStartHm(holidayStartHm: string) {
     this.rawData.holidayStartHm = holidayStartHm;
   }
 
   get holidayEndHm(): string | '' {
     if (this.rawData.holidayEndHm) {
       return this.rawData.holidayEndHm;
     }
     return '';
   }
 
   set holidayEndHm(holidayEndHm: string) {
     this.rawData.holidayEndHm = holidayEndHm;
   }
 
   get holidayTimes(): string | '' {
     if (this.rawData.holidayTimes) {
       return this.rawData.holidayTimes;
     }
     return '';
   }
 
   set holidayTimes(holidayTimes: string) {
     this.rawData.holidayTimes = holidayTimes;
   }
 
  get targetStartDate(): string {
     return moment(this.rawData.targetStartDate).format('YYYY/MM/DD');
   }
 
   set targetStartDate(targetStartDate: string) {
     this.rawData.targetStartDate = moment(targetStartDate).format('YYYY/MM/DD');
   }
 
   get targetEndDate(): string {
     return moment(this.rawData.targetEndDate).format('YYYY/MM/DD');
   }
 
   set targetEndDate(targetEndDate: string) {
     this.rawData.targetEndDate = moment(targetEndDate).format('YYYY/MM/DD');
   }
 
    get holidayName(): string {
     return this.rawData.holidayName;
   }
 
   set holidayName(holidayName: string) {
     this.rawData.holidayName = holidayName;
   }
 
    get holidayDigestiveUnitName(): string {
     return this.rawData.holidayDigestiveUnitName;
   }
 
   set holidayDigestiveUnitName(holidayDigestiveUnitName: string) {
     this.rawData.holidayDigestiveUnitName = holidayDigestiveUnitName;
   }
 
     get orgName(): string {
     return this.rawData.orgName;
   }
 
   set orgName(orgName: string) {
     this.rawData.orgName = orgName;
   }
 
   get staffName(): string {
     return this.rawData.staffName;
   }
 
   set staffName(staffName: string) {
     this.rawData.staffName = staffName;
   }
 
   get applicationStaffName(): string {
     return this.rawData.applicationStaffName;
   }
 
   set applicationStaffName(applicationStaffName: string) {
     this.rawData.applicationStaffName = applicationStaffName;
   }
 
   get approvalStaffName(): string {
     if(!this.rawData.approvalStaffName) return '';
     return this.rawData.approvalStaffName;
   }
 
   set approvalStaffName(approvalStaffName: string) {
     this.rawData.approvalStaffName = approvalStaffName;
   }
 
   get authorizer(): string {
     return `${this.rawData.approvalStaffCode} ${this.rawData.approvalStaffName}`;
   }
 
   get applicant(): string {
     return `${this.rawData.applicationStaffCode} ${this.rawData.applicationStaffName}`;
   }
 
   get targetPerson(): string {
     return `${this.rawData.staffCode} ${this.rawData.staffName}`;
   }
   
   get applyTargetDateType(): number {
    return this.rawData.applyTargetDateType;
  }

  set applyTargetDateType(applyTargetDateType: number) {
    this.rawData.applyTargetDateType = applyTargetDateType;
  }
 }