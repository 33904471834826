import ApiClient from './ApiClient';

export const getList = async ():Promise<any> => {
  const params = {};
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/sales/master/advertisingMediaMaster/${companyCode}`, params);
  return response.data;
};

export const deleteById = async (advertisingMediaMstCode: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    advertisingMediaMstCode,
  };
  const reponse = await ApiClient.delete(`/v1/advertisingMedia/delete/${companyCode}`, params);
  return reponse.data;
};

export const AddOrUpdateAdvertising = async (isCreate: number, isConfirmed: number,
  advertising: any) : Promise<any> => {
  const prams = {
    isCreate,
    isConfirmed,
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const reponse = await ApiClient.post(`/v1/advertisingMedia/${companyCode}`, prams, advertising);
  return reponse.data;
};

export default getList;
