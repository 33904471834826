/* import { useState, useMemo, useCallback } from 'react';

import BusinessCategoryDomain from 'domain/master/sales/businessCategory';
import * as api from 'api/businessCategories';
*/
/**
 * 業態削除処理
 */
/*
export const useBusinessCategoryDelete = (fetchData: () => Promise<void>) => {
  const [deleteTargetBusinessCategory, setDeleteTargetBusinessCategory] = useState<BusinessCategoryDomain|null>(null);

  // 削除モーダルの開閉状態
  const isOpenDeleteModal = useMemo(() => !!deleteTargetBusinessCategory, [deleteTargetBusinessCategory]);

  // 削除モーダルを閉じる
  const closeDeleteModal = useCallback(() => setDeleteTargetBusinessCategory(null), []);

  // 削除実行
  const onSubmitDeleteBusinessCategory = useCallback(() => {
    if (!deleteTargetBusinessCategory) {
      return;
    }
    console.log('id')
    console.log(deleteTargetBusinessCategory)
    api.deleteBusinessCategory(deleteTargetBusinessCategory.businessCategoryId).then(() => {
      setDeleteTargetBusinessCategory(null);
      // 削除後にデータを再取得
      fetchData();
    });
  }, [deleteTargetBusinessCategory, fetchData]);

  return {
    deleteTargetBusinessCategory,
    setDeleteTargetBusinessCategory,
    isOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteBusinessCategory,
  };
};

export const useDownload = () => {
  const downloadBusinessCategory = useCallback(() => {
    api.downloadBusinessCategoryCsv();
  }, []);

  return {
    downloadBusinessCategory,
  };
};

export default {};
*/

import { useState, useEffect } from 'react';
import { getList } from 'api/businessCategories';
import BusinessCategoryDomain from 'domain/master/sales/businessCategory';

export const useBusinessCategoryList = () => {
  const [businessCategoryList, setBusinessCategoryList] = useState<Array<BusinessCategoryDomain>>([]);
  useEffect(() => {
    getList().then((response: Array<any>) => {
      setBusinessCategoryList(response.map((result) => new BusinessCategoryDomain(result)));
    });
  }, []);
  return { businessCategoryList, setBusinessCategoryList };
};

export default useBusinessCategoryList;
