import { useState, useEffect, useCallback } from "react";
import { OptionType } from "components/atoms/Select";
import { useFormik } from "formik";
import { createStockPaid, getCurrentStockPaid, getHistoryStockPaid, getEmployment } from "api/attendStockPaid";
import moment from "moment";
import useToastNotification from "hooks/useToastNotification";
import AttendStockPaidDomain from "domain/attendStockPaidDomain";
import { renderError } from "utility/renderMessage";
import { useHistory } from "react-router-dom";
import { getStockHolidays } from "api/attendStockPaid";

const listHolidayOption = [
  {
    value: "0",
    label: "全休",
  },
  {
    value: "3",
    label: "午前休",
  },
  {
    value: "4",
    label: "午後休",
  },
];

const listHolidayOptionFalse = [
  {
    value: "0",
    label: "全休",
  },
];

export const useStockPaid = () =>
  // applicationDate?:string
  {
    const history = useHistory();
    const { successNotification, errorNotification } = useToastNotification();
    const date = sessionStorage.getItem("application.targetDate") || "";

    const [targetDate, setTargetDate] = useState<Date>(new Date(date) || "");
    const [targetForm, setTargetFrom] = useState<Date>(new Date(date) || "");
    const [targetEnd, setTargetEnd] = useState<Date>(new Date(date) || "");

    const [isLoading, setLoading] = useState(false);
    const [companyCode, setCompanyCode] = useState(sessionStorage.getItem("loginUser.companyCode") || "");
    const [staffCode, setStaffCode] = useState(sessionStorage.getItem("application.staffCode") || "");
    const [orgCode, setOrgCode] = useState(sessionStorage.getItem("application.orgCode") || "");
    const [staffName, setStaffName] = useState(sessionStorage.getItem("application.dispStaffName") || "");
    const [stockModiferAppId, setStockModiferAppId] = useState(
      sessionStorage.getItem("application.modifierApplicationId") || "",
    );

    const [holidayOptions, setHolidayOptions] = useState<Array<OptionType>>([]);
    const [dataHistory, setDataHistory] = useState<Array<any>>([]);

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectChangelog, setSelectChangelog] = useState<string>("0");

    const [deemedMorningHalfHours, setDeemedMorningHalfHours] = useState(0);
    const [deemedAfternoonHalfHours, setDeemedAfternoonHalfHours] = useState(0);

    useEffect(() => {
      setLoading(true);
      handleGetCurrentStockPaid();
    }, []);

    useEffect(() => {
      handelGetHolidays();
      handelGetEmployment();
    }, [targetDate, targetForm]);

    const handelGetHolidays = async () => {
      let params = {
        staffCode,
        targetDateStr:
          formik.values.applyTargetDateType === 0
            ? moment(targetDate).format("YYYY-MM-DD")
            : moment(targetForm).format("YYYY-MM-DD"),
      };
      let response: any = await getStockHolidays(params);
      if (!response[0].unUsableOfHalfHoliday) {
        setHolidayOptions(listHolidayOptionFalse);
        if (formik.values.holidayUnit === null) {
          formik.setFieldValue("holidayUnit", 0);
        }
      } else {
        setHolidayOptions(listHolidayOption);
        if (formik.values.holidayUnit === null) {
          formik.setFieldValue("holidayUnit", 0);
        }
      }
    };

    const handelGetEmployment = async () => {
      try {
        let employmentParams = {
          orgCode,
          staffCode,
          targetDateFrom: moment(targetDate).format("YYYY年MM月DD日"),
          targetDateTo: moment(targetDate).format("YYYY年MM月DD日"),
        };
        let response: any = await getEmployment(employmentParams);
        if (response && response.id.employmentId) {
          setDeemedMorningHalfHours(response.deemedMorningHalfHours);
          setDeemedAfternoonHalfHours(response.deemedAfternoonHalfHours);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    const fetchChangeLogHoliday = async () => {
      let params = {
        applicationId: stockModiferAppId,
      };
      if (stockModiferAppId) {
        await getHistoryStockPaid(params)
          .then((res) => {
            setDataHistory(res);
          })
          .catch((err) => {
            const mess = renderError(err);
            errorNotification(mess);
          });
      }
    };

    function getHm(time: any, type: string) {
      let format: any = "";
      switch (type) {
        case "hour":
          format = time ? moment(time).get("hour") : 0;
          break;
        case "minute":
          format = time ? moment(time).get("minute") : 0;
          break;
        default:
          break;
      }
      return format < 10 ? `0${format}` : format;
    }

    const handleGetCurrentStockPaid = async () => {
      if (stockModiferAppId) {
        try {
          let params = {
            companyCode,
            staffCode: staffCode,
            targetDate: moment(targetDate).format("YYYY-MM-DD"),
            modifierApplicationId: stockModiferAppId,
          };
          let res: any = await getCurrentStockPaid(params);
          setStaffCode(res?.staffCode);
          setStaffName(res?.staffName);
          setStockModiferAppId(res?.modifierApplicationId);
          setTargetDate(new Date(res?.targetDate));
          setTargetFrom(new Date(res?.targetStartDate));
          setTargetEnd(new Date(res?.targetEndDate));
          formik.setValues(new AttendStockPaidDomain(res));

          var startDate = moment(res.holidayStartHm).format("YYYY-MM-DD");
          var endDate = moment(res.holidayEndHm).format("YYYY-MM-DD");
          var target = moment(res.targetDate).format("YYYY-MM-DD");

          formik.setFieldValue("halfHolidayStartTimeNextDay", new Date(startDate) > new Date(target) ? true : false);
          formik.setFieldValue("halfHolidayEndTimeNextDay", new Date(endDate) > new Date(target) ? true : false);
          formik.setFieldValue("halfHolidayStartTimeHour", getHm(res.holidayStartHm, "hour"));
          formik.setFieldValue("halfHolidayStartTimeMinute", getHm(res.holidayStartHm, "minute"));
          formik.setFieldValue("halfHolidayEndTimeHour", getHm(res.holidayEndHm, "hour"));
          formik.setFieldValue("halfHolidayEndTimeMinute", getHm(res.holidayEndHm, "minute"));
          formik.setFieldValue("holidayUnit", String(res.holidayDigestiveUnit));
        } catch (err) {
          setLoading(false);
          handleError(err);
        }
      }
      setLoading(false);
    };

    const handleError = (error: any) => {
      setLoading(false);
      setConfirmModalOpen(false);
      const mess = renderError(error);
      errorNotification(mess);
    };

    const onSubmit = async (values: AttendStockPaidDomain) => {
      let postData = values.getRawData();

      let flagError = false;
      let msgError = "";

      postData.halfHolidayStartTimeNextDay = values.halfHolidayStartTimeNextDay;
      postData.halfHolidayEndTimeNextDay = values.halfHolidayEndTimeNextDay;
      let halfHolidayStartTimeHour = 0;
      let halfHolidayEndTimeHour = 0;

      if (!postData.applicationReason) {
        msgError += "申請理由を入力してください。<br />";
        flagError = true;
      }
      if (
        formik.values.attendType === 0 &&
        postData.holidayUnit &&
        [3, 4].indexOf(Number(postData.holidayUnit)) !== -1
      ) {
        halfHolidayStartTimeHour =
          Number(postData.halfHolidayStartTimeHour) + (!postData.halfHolidayStartTimeNextDay ? 0 : 24);
        halfHolidayEndTimeHour =
          Number(postData.halfHolidayEndTimeHour) + (!postData.halfHolidayEndTimeNextDay ? 0 : 24);
        if (
          Number(halfHolidayStartTimeHour) > Number(halfHolidayEndTimeHour) ||
          (Number(halfHolidayStartTimeHour) === Number(halfHolidayEndTimeHour) &&
            Number(postData.halfHolidayStartTimeMinute) >= Number(postData.halfHolidayEndTimeMinute))
        ) {
          msgError += "終了時間の大小関係が不正です。";
          flagError = true;
        }
      }

      if (flagError === true) {
        errorNotification(msgError);
        return;
      }

      if (!confirmModalOpen) {
        setConfirmModalOpen(true);
        return;
      }

      const data = {
        ...postData,
        staffCode: staffCode,
        targetDate: moment(postData.targetDate).format("YYYY-MM-DD"),
        targetStartDate: moment(postData.targetStartDate).format("YYYY-MM-DD"),
        targetEndDate: moment(postData.targetEndDate).format("YYYY-MM-DD"),
        halfHolidayStartTime: `${postData.halfHolidayStartTimeHour}:${postData.halfHolidayStartTimeMinute}`,
        halfHolidayEndTime: `${postData.halfHolidayEndTimeHour}:${postData.halfHolidayEndTimeMinute}`,
      };
      delete data.halfHolidayStartTimeHour;
      delete data.halfHolidayStartTimeMinute;
      delete data.halfHolidayEndTimeHour;
      delete data.halfHolidayEndTimeMinute;
      try {
        setLoading(true);
        setConfirmModalOpen(false);
        const err = await createStockPaid(data);
        history.push(sessionStorage.getItem("attendStampList.returnDestination")! || "");
        successNotification("申請しました。");
      } catch (err) {
        handleError(err);
      } finally {
        setConfirmModalOpen(false);
        setLoading(false);
      }
    };

    const formik = useFormik({
      initialValues: AttendStockPaidDomain.generateInitial(),
      validationSchema: false,
      validateOnChange: false,
      onSubmit,
    });

    const closeConfirmModal = useCallback(() => {
      setConfirmModalOpen(false);
    }, []);

    return {
      isLoading,
      staffCode,
      staffName,
      stockModiferAppId,
      targetDate,
      setTargetDate,
      targetForm,
      setTargetFrom,
      targetEnd,
      setTargetEnd,
      dataHistory,
      setDataHistory,
      formik,
      holidayOptions,
      confirmModalOpen,
      closeConfirmModal,
      fetchChangeLogHoliday,
      selectChangelog,
      setSelectChangelog,
      handelGetHolidays,
      deemedMorningHalfHours,
      deemedAfternoonHalfHours,
    };
  };

export default useStockPaid;
