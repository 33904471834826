/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { grayScale, textFontSize } from 'components/styles';
import Icon from 'components/atoms/Icon';
import TextTooltip from './TextTooltip';

const styles = (disabled: boolean) => (
  {
    label: css({
      alignItems: 'center',
      display: 'inline-flex',
      cursor: 'pointer',
      color: disabled? grayScale.gray50: grayScale.gray100,
      fontSize: textFontSize.re,
      '& > span > svg': {
        marginRight: '5px',
      },
      ':hover':{
        cursor: disabled ? 'not-allowed' : 'pointer'
      },
    }),
    labelTxt: css({
      marginLeft: '5px'
    }),
    input: css({
      display: 'none',
    }),
  }
) 

const Radio: React.FC<{
  id: string;
  name: string;
  label: string;
  value: string;
  checked: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showTooltip?:boolean;
  note?: string;
}> = ({
  id, name, label, value, checked, onChange, readOnly = false, disabled = false, showTooltip, note
}) => (
  <div>
    <label
      css={css([styles(disabled).label])} 
      htmlFor={id}
    >
      {checked
        ? <Icon type="radioOn" color={disabled? grayScale.gray50: grayScale.gray100} size="16px" disabled={disabled}/>
        : <Icon type="radioOff" color={disabled? grayScale.gray50: grayScale.gray100} size="16px" disabled={disabled}/>}
      <input
        css={([styles(disabled).input])}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      <span css={([styles(disabled).labelTxt])}>
        {
          showTooltip ? <TextTooltip text={note || ''} textDis={label}/> : label
        } 
      </span>
    </label>
  </div>
);

export default Radio;
