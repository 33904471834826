import { useState, useEffect } from "react";

import { OptionType } from "components/atoms/Select";
import { getDailyAddStaffsV2 } from "api/shift";

export const useShiftDailyStaffOptions = (orgCode: string, targetOrgCode: string, targetDate: string) => {
  const [staffOptions, setStaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getDailyAddStaffsV2(orgCode, targetOrgCode, targetDate).then((dailyAddStaffs) => {
      setStaffOptions(
        dailyAddStaffs.map((staff) => ({
          value: staff.staffCode,
          label: `${staff.staffCode} ${staff.staffName}`,
        })),
      );
    });
  }, [orgCode, targetDate, targetOrgCode]);
  return staffOptions;
};
