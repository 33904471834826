import React from "react";
import FormTitle from "components/atoms/Form/FormTitle";
import FormField from "components/atoms/Form/FormField";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FormContents from "components/atoms/Form/FormContents";
import { grayScale } from "components/styles";
import { css } from "@emotion/core";

const styles = {
  help: css({
    color: "red",
    bottom: "-13px",
    left: "10px",
    position: "relative",
  }),
  span: css({
    margin: "0px 10px",
    position: "relative",
    top: "-2px",
  }),
  addon: css({
    color: grayScale.gray100,
    padding: "7px",
  }),
  formBody: css({
    padding: "5px 40px",
    margin: "0px",
    paddingBottom: "30px",
  }),
  formHeader: css({
    margin: "16px 0px",
  }),
  formDate: css({
    margin: "none",
    padding: "0px 20px",
    marginTop: "10px",
  }),
  formFieldDate: css({
    marginTop: "-25px",
  }),
};

const TakeCareChildForm: React.FC<{
  paidCalculationStandard: boolean;
  setPaidCalculationStandard: any;
  paidFlag: number;
  setPaidFlag: (paidFlag: number) => void;
  isWorkTime: number;
  setIsWorkTime: any;
  yearUseDays: number;
  setYearUseDays: any;
  unUsableOfHalfHoliday: boolean;
  setUnUsableOfHalfHoliday: any;
  unUsableOfTimeHoliday: boolean;
  setUnUsableOfTimeHoliday: any;
  unUsableOfEntring6months: boolean;
  setUnUsableOfEntring6months: any;
  unUsableOfTrialPeriod: boolean;
  setUnUsableOfTrialPeriod: any;
  useEmploymentDeemedWorkingHours?: boolean;
  setUseEmploymentDeemedWorkingHours?: any;
}> = ({
  paidCalculationStandard,
  setPaidCalculationStandard,
  paidFlag,
  setPaidFlag,
  isWorkTime,
  setIsWorkTime,
  yearUseDays,
  setYearUseDays,
  unUsableOfHalfHoliday,
  setUnUsableOfHalfHoliday,
  unUsableOfTimeHoliday,
  setUnUsableOfTimeHoliday,
  unUsableOfEntring6months,
  setUnUsableOfEntring6months,
  unUsableOfTrialPeriod,
  setUnUsableOfTrialPeriod,
}) => {
  return (
    <FormContents isSubForm={true} customStyle={styles.formBody}>
      <FormTitle title="子の看護休暇詳細設定" note="" customStyle={styles.formHeader} />
      <FormField>
        <RadioSelectForm
          label="有給算出基準：勤務日数への加算"
          subLabel=""
          items={[
            {
              label: "加算しない",
              value: "0",
            },
            {
              label: "加算する",
              value: "1",
            },
          ]}
          name="paidCalculationStandard"
          value={paidCalculationStandard ? "1" : "0"}
          setValue={(e) => {
            setPaidCalculationStandard(Boolean(e.target.value !== "0"));
          }}
        />
      </FormField>

      <FormField>
        <RadioSelectForm
          label="有給・無給区分"
          subLabel=""
          items={[
            {
              label: "無給",
              value: "0",
            },
            {
              label: "有給",
              value: "1",
            },
          ]}
          name="paidFlag"
          value={String(paidFlag)}
          setValue={(e) => {
            setPaidFlag(Number(e.target.value));
          }}
        />
      </FormField>
      {paidFlag === 1 && (
        <FormField>
          <RadioSelectForm
            label="みなし時間を労働時間に"
            subLabel=""
            items={[
              {
                label: "含まない",
                value: "0",
              },
              {
                label: "含む",
                value: "1",
              },
            ]}
            name="isWorkTime"
            value={isWorkTime ? "1" : "0"}
            setValue={(e) => {
              setIsWorkTime(Number(e.target.value !== "0"));
            }}
          />
        </FormField>
      )}
      <FormField>
        <RadioSelectForm
          label="年間取得日数"
          subLabel=""
          items={[
            {
              label: "無制限",
              value: "0",
            },
            {
              label: "日数を管理",
              value: "1",
            },
          ]}
          name="yearUseDays"
          value={yearUseDays ? "1" : "0"}
          setValue={(e) => {
            setYearUseDays(Number(e.target.value !== "0"));
          }}
        />
      </FormField>
      <FormField>
        <RadioSelectForm
          label="半休の使用可否"
          subLabel=""
          items={[
            {
              label: "使用不可",
              value: "0",
            },
            {
              label: "使用可",
              value: "1",
            },
          ]}
          name="unUsableOfHalfHoliday"
          value={unUsableOfHalfHoliday ? "1" : "0"}
          setValue={(e) => {
            setUnUsableOfHalfHoliday(Boolean(e.target.value !== "0"));
          }}
        />
      </FormField>
      <FormField>
        <RadioSelectForm
          label="時間単位の休暇の使用可否"
          subLabel=""
          items={[
            {
              label: "使用不可",
              value: "0",
            },
            {
              label: "使用可",
              value: "1",
            },
          ]}
          name="unUsableOfTimeHoliday"
          value={unUsableOfTimeHoliday ? "1" : "0"}
          setValue={(e) => {
            setUnUsableOfTimeHoliday(Boolean(e.target.value !== "0"));
          }}
        />
      </FormField>
      <FormField>
        <RadioSelectForm
          label="入社半年未満の使用可否"
          subLabel=""
          items={[
            {
              label: "使用不可",
              value: "0",
            },
            {
              label: "使用可",
              value: "1",
            },
          ]}
          name="unUsableOfEntring6months"
          value={unUsableOfEntring6months ? "1" : "0"}
          setValue={(e) => {
            setUnUsableOfEntring6months(Boolean(e.target.value !== "0"));
          }}
        />
      </FormField>
      <FormField>
        <RadioSelectForm
          label="試用期間中の使用可否"
          subLabel=""
          items={[
            {
              label: "使用不可",
              value: "1",
            },
            {
              label: "使用可",
              value: "0",
            },
          ]}
          name="unUsableOfTrialPeriod"
          value={unUsableOfTrialPeriod ? "1" : "0"}
          setValue={(e) => {
            setUnUsableOfTrialPeriod(Boolean(e.target.value !== "0"));
          }}
        />
      </FormField>
    </FormContents>
  );
};
export default TakeCareChildForm;
