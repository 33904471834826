import { useState, useEffect } from 'react';
import WorkingDaysDomain from 'domain/master/labor/workingDays';
import { getWorkingDayList, deleteWorkingDayById } from 'api/workingDay';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';

export const UsePrescribedWorkingDaysList = () => {
  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterWorkingDays);

  const [workingDayList, setWorkingDayList] = useState<Array<WorkingDaysDomain>>([]);
  useEffect(() => {
    setBlocking(true);
    let isSubscribed = true;
    getWorkingDayList().then((response: Array<any>) => {
      setBlocking(false);
      if (isSubscribed) {
        setWorkingDayList(response.map((result) => new WorkingDaysDomain(result)));
      }
    });
    return () => {
      setBlocking(false);
      isSubscribed = false; };
  }, []);
  return { workingDayList, setWorkingDayList, blocking, setBlocking, detailRole };
};

export const UseDeleteWorkingDaysById = (id: string) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  deleteWorkingDayById(id).then(() => {
    setIsSuccess(true);
    setErrorMessage('');
  }).catch((error) => {
    setIsSuccess(false);
    setErrorMessage(error.reponse.data.error[0].defaultMessage);
  });

  return { isSuccess, errorMessage};
};

export default UsePrescribedWorkingDaysList;
