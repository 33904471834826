import ApiClient from './ApiClient';

export const getAttendApplicationApprovalDetailApi = async (api: string, modifierApplicationId: string, isWorkPlan?: boolean): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

  const params = {
      applicationId: modifierApplicationId,
      loginUserCode: staffName,
      loginUserName: staffCode
  };

  const response = await ApiClient.get(api + '/' +companyCode, params);
  return response.data;
};



export const getAttendApplicationApprovalDetailWorkPlanApi = async (api: string, shiftId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

    const params = {
      shiftId,
      loginUserCode: staffName,
      loginUserName: staffCode
  };

  const response = await ApiClient.get(api + '/' +companyCode, params);
  return response.data;
};


export default getAttendApplicationApprovalDetailApi;
