import { useState, useCallback } from 'react';
import ManagerDomain from 'domain/manager';
import moment from 'moment';
import { isDeepStrictEqual } from 'util';

export const useManagerEdit = (manager: ManagerDomain, setManager: (manager: ManagerDomain) => void) => {
  const [startDate, setStartDate] = useState<Date>(manager.startDateAsDate || new Date());
  const [endDate, setEndDate] = useState<Date|null>(manager.startDateAsDate || null);
  const [isEndDateUndecided, setEndDateUndecided] = useState<boolean>(!manager.endDate);

  const changeStartDate = useCallback((date: Date) => {
    setStartDate(date);
    setManager(new ManagerDomain({
      ...manager.rawDate,
      startDate: moment(date).format('YYYY/MM/DD'),
    }));
  }, [manager, setManager]);

  const changeEndDate = useCallback((date: Date|null) => {
    setEndDate(date);
    if(date){
      setEndDateUndecided(false);
    }
    setManager(new ManagerDomain({
      ...manager.rawDate,
      endDate: date ? moment(date).format('YYYY/MM/DD') : undefined,
    }));
  }, [manager.rawDate, setManager]);

  const toggleEndDateUndecided = useCallback(() => {
    if (!isEndDateUndecided) {
      // 未定にしたら、endDateも空にする
      changeEndDate(null);
    }
    setEndDateUndecided(!isEndDateUndecided);
  }, [changeEndDate, isEndDateUndecided]);

  return {
    isEndDateUndecided,
    toggleEndDateUndecided,
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
  };
};

export default {};
