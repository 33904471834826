import React, { useMemo, useState } from 'react';

import FormContents from 'components/atoms/Form/FormContents';
import DataTable from 'components/organismos/DataTable/DataTable';
import AttendHelpListDomain from 'domain/master/attend/attendHelpList';
import { Column } from 'react-table';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import DatePickerForm from 'components/molecules/DatePickerForm';
import FlexBox from 'components/atoms/FlexBox';
import { css } from '@emotion/core';
import Table from 'components/molecules/Table';
import moment from 'moment';
import {
  useGetAttendHelpList, useGetSubTotalList, useAttendHelpList, useGetTrees,
} from './hooks';
import SelectForm from 'components/molecules/SelectForm';

const styles = css({
  width: '100%',
  border: 'none',
  borderSpacing: '0px 1.5px',
  marginTop: '46px',
  textAlign: 'center',
});

const AttendHelpLayoutList: React.FC = () => {
  const {
    formik,
  } = useAttendHelpList();

  const [getSupportSourceOrg, setSupportSourceOrg] = useState('');
  const [getSupportDestinationOrg, setSupportDestinationOrg] = useState('');

  const targetDateFrom = moment(new Date(formik.values.targetDateFrom)).format('YYYY-MM-DD');
  const targetDateTo = moment(new Date(formik.values.targetDateTo)).format('YYYY-MM-DD');
  // const dateFrom = moment([new Date(targetDateFrom).getFullYear(),
  //   new Date(targetDateFrom).getMonth(), new Date(targetDateFrom).getDate()]);
  // const dateTo = moment([new Date(targetDateTo).getFullYear(),
  //   new Date(targetDateTo).getMonth(), new Date(targetDateTo).getDate()]);

  const { getAttendHelpList } = useGetAttendHelpList(
    targetDateFrom, targetDateTo, getSupportSourceOrg, getSupportDestinationOrg,
  );

  console.log(getAttendHelpList);

  const { getSubTotalList } = useGetSubTotalList(
    targetDateFrom, targetDateTo, getSupportSourceOrg, getSupportDestinationOrg,
  );
  const { getOrgTree } = useGetTrees(targetDateFrom, targetDateTo);

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const columns: Array<Column<AttendHelpListDomain>> = useMemo(() => [
    {
      Header: '支援元組織コード',
      accessor: 'belongOrgCode',
    },
    {
      Header: '支援元組織名',
      accessor: 'belongOrgName',
    },
    {
      Header: '支援先組織コード',
      accessor: 'orgCode',
    },
    {
      Header: '支援先組織名',
      accessor: 'orgName',
    },
    {
      Header: 'スタッフコード',
      accessor: 'staffCode',
    },
    {
      Header: 'スタッフ名',
      accessor: 'staffName',
    },
    {
      Header: '出勤日数',
      accessor: 'subtotalDays',
    },
    {
      Header: '総労働時間',
      accessor: 'subtotalTimeDisp',
    },
    {
      Header: '人件費',
      accessor: 'totalCost',
      Cell: (cell: { row :{ isExpanded: boolean, original: AttendHelpListDomain },
        data: Array<AttendHelpListDomain> }) => (
          <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            { `${formatter.format(cell.row.original.totalCost)}円`}
          </div>
      ),
    },
  ], [formatter]);

  const sortBy = React.useMemo(
    () => [
      {
        id: 'totalCost',
        desc: true,
      },
    ],
    [],
  );

  return (
    <div>
      <br />
      <FormContents>
        <div style={{ width: '300px' }}>
          <FlexBox>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label="期間"
              date={formik.values.targetDateFrom}
              isFullWidth={true}
              changeDate={(date: Date) => { formik.setFieldValue('setTargetDateFrom', date); }}
            />
            <span style={{ margin: '0 30px', marginTop: '24px' }}>～</span>
            <DatePickerForm
              dateFormat="yyyy年MM月dd日"
              label="期間"
              isFullWidth={true}
              date={formik.values.targetDateTo}
              changeDate={(date: Date) => { formik.setFieldValue('setTargetDateTo', date); }}
            />
          </FlexBox>
        </div>
        <SelectForm
          label="支援元組織"
          name="orgCode"
          value={String(getSupportSourceOrg)}
          setValue={(val: string) => setSupportSourceOrg(val)}
          options={getOrgTree}
          required={false}
        />
        <SelectForm
          label="支援先組織"
          name="orgCode"
          value={String(getSupportDestinationOrg)}
          setValue={(val: string) => setSupportDestinationOrg(val)}
          options={getOrgTree}
          required={false}
        />
        <div
          css={css`
              table th:nth-of-type(1) {
                width: 140px;
              }
              table th:nth-of-type(2) {
                width: 140px;
              }
              table th:nth-of-type(3) {
                width: 140px;
              }
              table th:nth-of-type(4) {
                width: 140px;
              }
              table th:nth-of-type(5) {
                width: 120px;
              }
              table th:nth-of-type(6) {
                width: 120px;
              }
              table th:nth-of-type(7) {
                width: 80px;
              }
              table th:nth-of-type(8) {
                width: 80px;
              }
              table th:nth-of-type(9) {
                width: 80px;
              }
            `}
        >

          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <DataTable
              columns={columns}
              data={getAttendHelpList}
              sortBy={sortBy}
              minWidth="1112px"
            />
          </div>
        </div>
        <div className="total-table" style={{ maxWidth: '800px', margin: '0 auto', marginTop: '100px' }}>
          <Table css={styles}>
            <thead style={{ textAlign: 'center', backgroundColor: '#F2F5F9', padding: '15px 0' }}>
              <tr style={{
                textAlign: 'center', padding: '15px 0', fontSize: '14px', height: '30px', color: '#333333',
              }}
              >
                <td>スタッフ数合計</td>
                <td>出勤日数合計</td>
                <td>総労働時間合計</td>
                <td>人件費合計</td>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center', backgroundColor: '#F2F5F9' }}>
              <tr
                style={{
                  borderTop: '1px solid #CEDAEA', borderBottom: '1px solid #CEDAEA', height: '40px',
                }}
              >
                <td>
                  {getSubTotalList ? `${getSubTotalList.staffSubtotal}人` : 0}
                </td>
                <td>
                  {getSubTotalList ? `${getSubTotalList.attendanceDaysSubtotal}日` : ''}
                </td>
                <td>
                  {getSubTotalList ? getSubTotalList.allWorkTimeSubtotalDisp : ''}
                </td>
                <td>
                  {getSubTotalList ? `${getSubTotalList.totalCostDisp}円` : ''}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </FormContents>
    </div>
  );
};

export default AttendHelpLayoutList;
