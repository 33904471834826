import React, { useState } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FormField from 'components/atoms/Form/FormField';
import FileSelectButton from 'components/molecules/FileSelectButton';
import FormTitle from 'components/atoms/Form/FormTitle';
import BodyText from 'components/atoms/BodyText';
import { utilityColor } from 'components/styles';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import PrimaryButton from 'components/atoms/Button';
import ConfirmModal from 'components/organismos/ConfirmModal';
import useSaleBudgetImport from './hooks';

const SalesBudgetYearImportLayout = () => {
  if (!sessionStorage.getItem('dateSelect')) {
    sessionStorage.setItem('dateSelect', `${new Date().getFullYear()}`);
  }

  const dateSelect = sessionStorage.getItem('dateSelect') || '';
  const destinationOrg = sessionStorage.getItem('destination_org') || '';

  const [selectedYear] = useState(Number(dateSelect));

  const {
    inputValue, setUploadFile, uploadFile, onSubmit, errors, confirmModalOpen, closeConfirmModal, handleSubmit,
  } = useSaleBudgetImport();

  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
    sessionStorage.setItem('isBack', 'true');
  };

  return (
    <div>
      <br />
      <FormContents>
        <FormTitle title="登録するデータ" bold={true} />
        <p>
          会計年度：
          <strong>
            {selectedYear}
            {'年 '}
          </strong>
        </p>
        <p>
          組織名：
          <strong>
            {destinationOrg}
          </strong>
        </p>
        <FormField>
          <FileSelectButton
            text="Excelファイルをドラッグ＆ドロップ  または  クリックしてファイルを選択してください"
            ghost={true}
            setFile={setUploadFile}
            value={inputValue}
            accept=".xlsx,.xls"
          />
        </FormField>
        {uploadFile || errors
          ? (
            <FormField
              displayBlock={true}
            >
              <div>
                {uploadFile?.name}
              </div>
              {errors && errors.map((error) => (
                <div key={error}>
                  <BodyText color={utilityColor.error}>
                    {error}
                  </BodyText>
                </div>
              ))}
            </FormField>
          )
          : null}
        <FormSubmitArea>
          <PrimaryButton
            text="インポート"
            onClick={handleSubmit}
            disabled={!uploadFile}
          />

          <PrimaryButton
            text="戻る"
            ghost={true}
            onClick={handleClick}
          />
        </FormSubmitArea>
      </FormContents>
      <ConfirmModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        title="確認メッセージ"
        onSubmit={onSubmit}
        content="ファイルをアップロードします。よろしいですか？"
        items={[]}
      />
    </div>
  );
};
export default SalesBudgetYearImportLayout;
