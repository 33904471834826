import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import MasterRoleAddForm from 'components/organismos/master/masterRole/MasterRoleAddForm';

const MasterRoleAddPage: React.FC = () => {

  return (
    <SidebarTemplate pageTitle="権限インポート">
      <MasterRoleAddForm/>
    </SidebarTemplate>
  );
};

export default MasterRoleAddPage;
