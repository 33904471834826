import React from "react";
import Year from "./year";

function YearsList({ selectedYear, choiseYear }) {
  const startYear = selectedYear - 4;
  let yearsArray = Array.from(
    new Array(9),
    (val, index) => index + startYear
  );

  let yearList = [];

  for (let i = 0; i < yearsArray.length; i += 3) {
    yearList.push(
      <div className="year-row">
        <Year
          key={yearsArray[i]}
          choiseYear={choiseYear}
          selectedYear={selectedYear}
          year={yearsArray[i]}
        />
        <Year
          key={yearsArray[i+1]}
          choiseYear={choiseYear}
          selectedYear={selectedYear}
          year={yearsArray[i+1]}
        />
        <Year
          key={yearsArray[i+2]}
          choiseYear={choiseYear}
          selectedYear={selectedYear}
          year={yearsArray[i+2]}
        />
      </div>
    )
  } 

  return (
    <div>
      {yearList}
    </div>
  );
}

export default YearsList;
