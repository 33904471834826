/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { isIPad13, isSafari } from "react-device-detect";

const MonthHourTable: React.FC<{
  employment: any;
  business: any;
  term: any;
  timeDisp: any;
  subTotalTime: any;
  remote: boolean;
}> = ({ employment, business, term, timeDisp, subTotalTime, remote }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  return (
    <div className="table-responsive">
      <div
        css={css`
          table thead tr th {
            height: 46px;
          }
          table tbody tr td {
            height: 32px;
          }
        `}
      >
        <table
          id="monthHourList"
          className="table table-bordered table-condensed table-no-border-left-right"
          cellSpacing="0"
          style={{ whiteSpace: "nowrap", width: "100%" }}
        >
          <thead>
            <tr>
              <th style={{ width: "7%" }}>&nbsp;</th>
              {/* <!--普通時間 --> */}
              <th style={{ width: "7%" }}>通常勤務</th>
              {/* <!--法定内残業時間（法定内の区別なし） --> */}
              {employment.handleLegalInOvertime === 0 && (
                <th className="text-center" style={{ width: "6%" }}>
                  普通
                  <br />
                  残業
                </th>
              )}
              {/* <!--法定内残業時間（法定内の区別あり） --> */}
              {employment.handleLegalInOvertime === 1 && (
                <th style={{ width: "6%" }}>
                  法定内
                  <br />
                  残業
                </th>
              )}
              {employment.handleLegalInOvertime === 1 && (
                <th style={{ width: "6%" }}>
                  法定外
                  <br />
                  残業
                </th>
              )}
              {/* <!--割増残業時間 --> */}
              {employment.useExtraOverTime && (
                <th style={{ width: "6%" }}>
                  割増
                  <br />
                  残業1
                </th>
              )}
              {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
                <th style={{ width: "6%" }}>
                  割増
                  <br />
                  残業2
                </th>
              )}
              {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
                <th style={{ width: "6%" }}>
                  割増
                  <br />
                  残業3
                </th>
              )}
              {/* <!--深夜時間 --> */}
              <th style={{ width: "6%" }}>深夜</th>
              {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
              {employment.handleLegalInOvertime === 0 && (
                <th style={{ width: "6%" }}>
                  深夜
                  <br />
                  残業
                </th>
              )}
              {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
              {employment.handleLegalInOvertime === 1 && (
                <th style={{ width: "6%" }}>
                  深夜
                  <br />
                  法定内
                  <br />
                  残業
                </th>
              )}
              {employment.handleLegalInOvertime === 1 && (
                <th style={{ width: "6%" }}>
                  深夜
                  <br />
                  法定外
                  <br />
                  残業
                </th>
              )}
              {/* <!--深夜割増残業時間 --> */}
              {employment.useExtraOverTime && (
                <th style={{ width: "6%" }}>
                  深夜
                  <br />
                  割増
                  <br />
                  残業1
                </th>
              )}
              {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
                <th style={{ width: "6%" }}>
                  深夜
                  <br />
                  割増
                  <br />
                  残業2
                </th>
              )}
              {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
                <th style={{ width: "6%" }}>
                  深夜
                  <br />
                  割増
                  <br />
                  残業3
                </th>
              )}
              {/* <!--未承認残業時間 --> */}
              {employment.useOverTimeApplication !== 0 && (
                <th style={{ width: "6%" }}>
                  未承認
                  <br />
                  残業
                </th>
              )}
              <th style={{ width: "6%" }}>休憩</th>
              {remote && (
                <React.Fragment>
                  <th style={{ width: "6%" }}>オフィス</th>
                  <th style={{ width: "6%" }}>
                    {companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"}
                  </th>
                </React.Fragment>
              )}
              <th style={{ width: "6%" }}>遅刻</th>
              <th style={{ width: "6%" }}>早退</th>
              <th style={{ width: "6%" }}>みなし時間</th>
              {employment.workSystem === 3 && (
                <th style={{ width: "8%" }}>月間所定労働時間</th>
              )}
              <th style={{ width: "8%" }}>総労働時間</th>
              {employment.workSystem === 2 &&
                subTotalTime.dispStampTotalFlag === 1 && (
                  <th style={{ width: "8%" }}>実勤務総労働時間</th>
                )}
              {business &&
                business.map((item: any) => (
                  <th style={{ width: "8%" }} key={item.businessCode}>
                    {item.businessName}
                  </th>
                ))}
              {term &&
                term.map((item: any) => (
                  <th style={{ width: "8%" }} key={item.termId}>
                    {item.termName}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {employment.workSystem === 2 &&
              subTotalTime.dispStampTotalFlag === 1 && (
                <tr role="row">
                  <td>所定日（実勤務）</td>
                  {/* <!--普通時間 --> */}
                  <td>{timeDisp(subTotalTime.stampDaytime)}</td>
                  {/* <!--法定内残業時間（法定内の区別なし）--> */}
                  {employment.handleLegalInOvertime === 0 && (
                    <td>
                      {timeDisp(subTotalTime.stampDaytimeWeekdayOvertime)}
                    </td>
                  )}
                  {/* <!--法定内残業時間（法定内の区別あり）--> */}
                  {employment.handleLegalInOvertime === 1 && (
                    <td>{timeDisp(subTotalTime.stampDaytimeLegalOvertime)}</td>
                  )}
                  {/* <!--法定外残業時間（法定内の区別あり）--> */}
                  {employment.handleLegalInOvertime === 1 && (
                    <td>
                      {timeDisp(subTotalTime.stampDaytimeWeekdayOvertime)}
                    </td>
                  )}
                  {/* <!--割増残業時間 --> */}
                  {employment.useExtraOverTime && (
                    <td>
                      {timeDisp(
                        subTotalTime.stampDaytimeWeekdayPremiumOvertime1
                      )}
                    </td>
                  )}
                  {employment.useExtraOverTime &&
                    employment.extraOverTime2 > 0 && (
                      <td>
                        {timeDisp(
                          subTotalTime.stampDaytimeWeekdayPremiumOvertime2
                        )}
                      </td>
                    )}
                  {employment.useExtraOverTime &&
                    employment.extraOverTime3 > 0 && (
                      <td>
                        {timeDisp(
                          subTotalTime.stampDaytimeWeekdayPremiumOvertime3
                        )}
                      </td>
                    )}
                  {/* <!--深夜時間 --> */}
                  <td>{timeDisp(subTotalTime.stampMidnightWeekdayTotal)}</td>
                  {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
                  {employment.handleLegalInOvertime === 0 && (
                    <td>
                      {timeDisp(subTotalTime.stampMidnightWeekdayOvertime)}
                    </td>
                  )}
                  {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
                  {employment.handleLegalInOvertime === 1 && (
                    <td>
                      {timeDisp(subTotalTime.stampMidnightWeekdayLegalOvertime)}
                    </td>
                  )}
                  {employment.handleLegalInOvertime === 1 && (
                    <td>
                      {timeDisp(subTotalTime.stampMidnightWeekdayOvertime)}
                    </td>
                  )}
                  {/* <!--深夜割増残業時間 --> */}
                  {employment.useExtraOverTime && (
                    <td>
                      {timeDisp(
                        subTotalTime.stampMidnightWeekdayPremiumOvertime1
                      )}
                    </td>
                  )}
                  {employment.useExtraOverTime &&
                    employment.extraOverTime2 > 0 && (
                      <td>
                        {timeDisp(
                          subTotalTime.stampMidnightWeekdayPremiumOvertime2
                        )}
                      </td>
                    )}
                  {employment.useExtraOverTime &&
                    employment.extraOverTime3 > 0 && (
                      <td>
                        {timeDisp(subTotalTime.midnightWeekdayPremiumOvertime3)}
                      </td>
                    )}
                  {/* <!--未承認残業時間 --> */}
                  {employment.useOverTimeApplication !== 0 && (
                    <td>{timeDisp(subTotalTime.daytimeUnapprovedOvertime)}</td>
                  )}
                  {employment.workSystem === 2 && (
                    <React.Fragment>
                      <td rowSpan={4}>{timeDisp(subTotalTime.recess)}</td>
                      {remote && (
                        <React.Fragment>
                          <td rowSpan={4}>
                            {timeDisp(subTotalTime.officeTime)}
                          </td>
                          <td rowSpan={4}>
                            {timeDisp(subTotalTime.remoteTime)}
                          </td>
                        </React.Fragment>
                      )}
                      <td rowSpan={4}>{timeDisp(subTotalTime.lateTime)}</td>
                      <td rowSpan={4}>{timeDisp(subTotalTime.earlierTime)}</td>
                      <td rowSpan={4}>{timeDisp(subTotalTime.deemedTime)}</td>
                      <td rowSpan={4}>{timeDisp(subTotalTime.allWorkTime)}</td>
                      <td rowSpan={4}>
                        {timeDisp(subTotalTime.stampAllWorkTime)}
                      </td>
                      {subTotalTime.businessTimeSubTotal &&
                        subTotalTime.businessTimeSubTotal.map(
                          (item: any, index: any) => (
                            <td rowSpan={4} key={String(index)}>
                              {timeDisp(item.subtotalTime)}
                            </td>
                          )
                        )}
                      {subTotalTime.termTimeSubTotal &&
                        subTotalTime.termTimeSubTotal.map(
                          (item: any, index: any) => (
                            <td rowSpan={4} key={String(index)}>
                              {timeDisp(item.subtotalTime)}
                            </td>
                          )
                        )}
                    </React.Fragment>
                  )}
                </tr>
              )}
            <tr role="row">
              <td>
                {employment.workSystem !== 2 ? "所定日" : "所定日（裁量）"}
              </td>
              {/* <!--普通時間 --> */}
              <td>{timeDisp(subTotalTime.daytime)}</td>
              {/* <!--法定内残業時間（法定内の区別なし）--> */}
              {employment.handleLegalInOvertime === 0 && (
                <td>{timeDisp(subTotalTime.daytimeWeekdayOvertime)}</td>
              )}
              {/* <!--法定内残業時間（法定内の区別あり）--> */}
              {employment.handleLegalInOvertime === 1 && (
                <td>{timeDisp(subTotalTime.daytimeLegalOvertime)}</td>
              )}
              {/* <!--法定外残業時間（法定内の区別あり）--> */}
              {employment.handleLegalInOvertime === 1 && (
                <td>{timeDisp(subTotalTime.daytimeWeekdayOvertime)}</td>
              )}
              {/* <!--割増残業時間 --> */}
              {employment.useExtraOverTime && (
                <td>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime1)}</td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
                <td>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime2)}</td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
                <td>{timeDisp(subTotalTime.daytimeWeekdayPremiumOvertime3)}</td>
              )}
              {/* <!--深夜時間 --> */}
              <td>{timeDisp(subTotalTime.midnightWeekdayTotal)}</td>
              {/* <!--深夜法定内残業時間（法定内の区別なし） --> */}
              {employment.handleLegalInOvertime === 0 && (
                <td>{timeDisp(subTotalTime.midnightWeekdayOvertime)}</td>
              )}
              {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
              {employment.handleLegalInOvertime === 1 && (
                <td>{timeDisp(subTotalTime.midnightWeekdayLegalOvertime)}</td>
              )}
              {employment.handleLegalInOvertime === 1 && (
                <td>{timeDisp(subTotalTime.midnightWeekdayOvertime)}</td>
              )}
              {/* <!--深夜割増残業時間 --> */}
              {employment.useExtraOverTime && (
                <td>
                  {timeDisp(subTotalTime.midnightWeekdayPremiumOvertime1)}
                </td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
                <td>
                  {timeDisp(subTotalTime.midnightWeekdayPremiumOvertime2)}
                </td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
                <td>
                  {timeDisp(subTotalTime.midnightWeekdayPremiumOvertime3)}
                </td>
              )}
              {/* <!--未承認残業時間 --> */}
              {employment.useOverTimeApplication !== 0 && (
                <td>{timeDisp(subTotalTime.daytimeUnapprovedOvertime)}</td>
              )}
              {(employment.workSystem !== 2 ||
                subTotalTime.dispStampTotalFlag === 0) && (
                <React.Fragment>
                  <td rowSpan={3}>{timeDisp(subTotalTime.recess)}</td>
                  {remote && (
                    <React.Fragment>
                      <td rowSpan={3}>{timeDisp(subTotalTime.officeTime)}</td>
                      <td rowSpan={3}>{timeDisp(subTotalTime.remoteTime)}</td>
                    </React.Fragment>
                  )}
                  <td rowSpan={3}>{timeDisp(subTotalTime.lateTime)}</td>
                  <td rowSpan={3}>{timeDisp(subTotalTime.earlierTime)}</td>
                  <td rowSpan={3}>{timeDisp(subTotalTime.deemedTime)}</td>
                  {employment.workSystem === 3 && (
                    <td rowSpan={3}>{timeDisp(subTotalTime.workingHours)}</td>
                  )}
                  <td rowSpan={3}>{timeDisp(subTotalTime.allWorkTime)}</td>
                  {subTotalTime.businessTimeSubTotal &&
                    subTotalTime.businessTimeSubTotal.map(
                      (item: any, index: any) => (
                        <td rowSpan={3} key={String(index)}>
                          {timeDisp(item.subtotalTime)}
                        </td>
                      )
                    )}
                  {subTotalTime.termTimeSubTotal &&
                    subTotalTime.termTimeSubTotal.map(
                      (item: any, index: any) => (
                        <td rowSpan={3} key={String(index)}>
                          {timeDisp(item.subtotalTime)}
                        </td>
                      )
                    )}
                </React.Fragment>
              )}
            </tr>
            <tr role="row">
              {employment.distinctionHoliday && <td>法定休日</td>}
              {!employment.distinctionHoliday && <td>休日</td>}
              {/* <!--普通時間 --> */}
              <td>{timeDisp(subTotalTime.daytimeLegalHoliday)}</td>
              {/* <!--法定内残業時間（法定内の区別あり） --> */}
              {employment.handleLegalInOvertime === 1 && <td>-</td>}
              {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上する) --> */}
              {employment.treatmentStatutoryHolidayOvertime === 0 && (
                <td>{timeDisp(subTotalTime.daytimeLegalHolidayOvertime)}</td>
              )}
              {/* <!-- 法定外残業 or 残業(法定休日残業時間を計上しない) --> */}
              {employment.treatmentStatutoryHolidayOvertime === 1 && <td>-</td>}
              {/* <!--割増残業時間 --> */}
              {employment.useExtraOverTime && (
                <td>
                  {timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime1)}
                </td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
                <td>
                  {timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime2)}
                </td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
                <td>
                  {timeDisp(subTotalTime.daytimeLegalHolidayPremiumOvertime3)}
                </td>
              )}
              {/* <!--深夜時間 --> */}
              <td>{timeDisp(subTotalTime.midnightLegalHolidayTotal)}</td>
              {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
              {employment.handleLegalInOvertime === 1 && <td>-</td>}
              {employment.treatmentStatutoryHolidayOvertime === 1 && <td>-</td>}
              {employment.treatmentStatutoryHolidayOvertime === 0 && (
                <td>{timeDisp(subTotalTime.midnightLegalHolidayOvertime)}</td>
              )}
              {/* <!--深夜割増残業時間 --> */}
              {employment.useExtraOverTime && (
                <td>
                  {timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime1)}
                </td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime2 > 0 && (
                <td>
                  {timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime2)}
                </td>
              )}
              {employment.useExtraOverTime && employment.extraOverTime3 > 0 && (
                <td>
                  {timeDisp(subTotalTime.midnightLegalHolidayPremiumOvertime3)}
                </td>
              )}
              {/* <!--未承認残業時間 --> */}
              {employment.useOverTimeApplication !== 0 && (
                <td>{timeDisp(subTotalTime.leaglHolidayUnapprovedOvertime)}</td>
              )}
            </tr>
            {employment.distinctionHoliday && (
              <tr role="row">
                <td>所定休日</td>
                {/* <!--普通時間 --> */}
                <td>{timeDisp(subTotalTime.daytimeNotLegalHoliday)}</td>
                {/* <!--法定外残業時間（法定内の区別なし） --> */}
                {employment.handleLegalInOvertime === 0 && (
                  <td>
                    {timeDisp(subTotalTime.daytimeNotLegalHolidayOvertime)}
                  </td>
                )}
                {/* <!--法定内残業時間（法定内の区別あり） --> */}
                {employment.handleLegalInOvertime === 1 && (
                  <td>{timeDisp(subTotalTime.daytimeNotLegalOvertime)}</td>
                )}
                {/* <!--法定外残業時間（法定内の区別あり） --> */}
                {employment.handleLegalInOvertime === 1 && (
                  <td>
                    {timeDisp(subTotalTime.daytimeNotLegalHolidayOvertime)}
                  </td>
                )}
                {/* <!--割増残業時間 --> */}
                {employment.useExtraOverTime && (
                  <td>
                    {timeDisp(
                      subTotalTime.daytimeNotLegalHolidayPremiumOvertime1
                    )}
                  </td>
                )}
                {employment.useExtraOverTime &&
                  employment.extraOverTime2 > 0 && (
                    <td>
                      {timeDisp(
                        subTotalTime.daytimeNotLegalHolidayPremiumOvertime2
                      )}
                    </td>
                  )}
                {employment.useExtraOverTime &&
                  employment.extraOverTime3 > 0 && (
                    <td>
                      {timeDisp(
                        subTotalTime.daytimeNotLegalHolidayPremiumOvertime3
                      )}
                    </td>
                  )}
                {/* <!--深夜時間 --> */}
                <td>{timeDisp(subTotalTime.midnightNotLegalHolidayTotal)}</td>
                {/* <!--深夜法定外残業時間（法定内の区別なし） --> */}
                {employment.handleLegalInOvertime === 0 && (
                  <td>
                    {timeDisp(subTotalTime.midnightNotLegalHolidayOvertime)}
                  </td>
                )}
                {/* <!--深夜法定内残業時間（法定内の区別あり） --> */}
                {employment.handleLegalInOvertime === 1 && (
                  <td>
                    {timeDisp(
                      subTotalTime.midnightNotLegalHolidayLegalOvertime
                    )}
                  </td>
                )}
                {/* <!--深夜法定外残業時間（法定内の区別あり） --> */}
                {employment.handleLegalInOvertime === 1 && (
                  <td>
                    {timeDisp(subTotalTime.midnightNotLegalHolidayOvertime)}
                  </td>
                )}
                {/* <!--深夜割増残業時間 --> */}
                {employment.useExtraOverTime && (
                  <td>
                    {timeDisp(
                      subTotalTime.midnightNotLegalHolidayPremiumOvertime1
                    )}
                  </td>
                )}
                {employment.useExtraOverTime &&
                  employment.extraOverTime2 > 0 && (
                    <td>
                      {timeDisp(
                        subTotalTime.midnightNotLegalHolidayPremiumOvertime2
                      )}
                    </td>
                  )}
                {employment.useExtraOverTime &&
                  employment.extraOverTime3 > 0 && (
                    <td>
                      {timeDisp(
                        subTotalTime.midnightNotLegalHolidayPremiumOvertime3
                      )}
                    </td>
                  )}
                {/* <!--未承認残業時間 --> */}
                {employment.useOverTimeApplication !== 0 && (
                  <td>
                    {timeDisp(subTotalTime.notLeaglHolidayUnapprovedOvertime)}
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MonthHourTable;
