/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import BodyText from "components/atoms/BodyText";
import React from "react";
import { HopeShiftDomain } from "domain/master/general/hopeShift";
import { OptionType } from "components/atoms/Select";
import moment from "moment";
import { ScheduleStatus } from "api/schedule";
import { AttendShiftPatternDetails } from "domain/master/general/weekShiftPatternDataStaff";
import ShiftPatternDomain from "domain/master/attend/shiftPattern";
import FlexBox from "components/atoms/FlexBox";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import { grayScale, utilityColor } from "components/styles";
import TextForm from "components/molecules/TextForm";
import MobileSelect from "components/atoms/MobileSelect";
import { hourFormOptions, timeFormOptions } from "utility/dateUtil";

const ActionBar: React.FC<{
  setSelectedHopeShift?: React.Dispatch<React.SetStateAction<HopeShiftDomain | undefined>>;
  shiftPatternList?: ShiftPatternDomain[];
  selectedHopeShift?: HopeShiftDomain;
  shiftPatternOptions?: OptionType[];
  hopeShiftList: HopeShiftDomain[];
  setIsHasActionBar: (value: React.SetStateAction<boolean>) => void;
  state: any;
  setter: any;
}> = ({
  setSelectedHopeShift,
  shiftPatternList,
  selectedHopeShift,
  shiftPatternOptions,
  hopeShiftList,
  setIsHasActionBar,
  state,
  setter,
}) => {
  const valueSelectType =
    state.selectedHopeShift?.shiftPatternId &&
    state.shiftPatternOptions.map((item: any) => item.value).includes(state.selectedHopeShift?.shiftPatternId)
      ? state.selectedHopeShift?.shiftPatternId
      : state.flgCanWork
      ? "CANWORK"
      : state.selectedHopeShift?.hopeShiftPatternType === 1 ||
        state.selectedHopeShift?.fixStatus === ScheduleStatus.DAY_OFF
      ? "CANTWORK"
      : state.selectedHopeShift?.hopeShiftPatternType === 2
      ? "CANTWORK"
      : state.selectedHopeShift?.hopeShiftPatternType === 1
      ? "CANWORK"
      : state.selectedHopeShift?.startTime
      ? "CANWORK"
      : undefined;

  const checkBusinessOptions = state.businessOptions.filter((item: any) => {
    if (item.label === "休憩") {
      return false;
    }
    return true;
  });
  const issetBusinessOptions = !!checkBusinessOptions.length;

  const barStyle = {
    line: css({
      position: "fixed",
      bottom: issetBusinessOptions ? 185 : 145,
      width: "100%",
      height: "3px",
      background: "#0D4796",
      margin: "0 -0.5rem",
      zIndex: 100,
    }),
    lineTwo: css({
      position: "fixed",
      bottom: 110,
      width: "100%",
      height: "3px",
      background: "#0D4796",
      margin: "0 -0.5rem",
      zIndex: 100,
    }),
    fixedDateTitleContainer: css({
      position: "fixed",
      bottom: issetBusinessOptions ? 255 : 214,
      width: "100%",
      height: "40px",
      background: "white",
      margin: "0 -0.5rem",
      zIndex: 100,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    }),
    fixedDateTitleContainerTwo: css({
      position: "fixed",
      bottom: 113,
      width: "100%",
      height: "40px",
      background: "white",
      margin: "0 -0.5rem",
      zIndex: 100,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    }),
    fixedSelectBoxContainer: css({
      position: "fixed",
      bottom: issetBusinessOptions ? 197 : 157,
      width: "100%",
      height: "56px",
      margin: "0 -0.5rem",
      padding: "6px 10px",
      boxSizing: "border-box",
      background: "#353E43",
      textAlign: "center",
      zIndex: 100,
    }),
    fixedStartEndTimeContainer: css({
      position: "fixed",
      bottom: issetBusinessOptions ? 157 : 117,
      width: "100%",
      height: "42px",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 -0.5rem",
      padding: "0 10px",
      boxSizing: "border-box",
      background: "#353E43",
      textAlign: "center",
      zIndex: 100,
    }),
    fixedNoteContainer: css({
      position: "fixed",
      bottom: 35,
      width: "calc(100% - 20px)",
      height: "85px",
      background: "#353E43",
      margin: "0 -0.5rem",
      textAlign: "center",
      zIndex: 100,
      padding: "0 10px",
    }),
    fixedNoteContainerTwo: css({
      position: "fixed",
      bottom: 0,
      width: "calc(100% - 20px)",
      height: "85px",
      background: "#353E43",
      textAlign: "center",
      zIndex: 100,
      padding: "0 10px",
    }),
    fixedSelectBusinessIdContainer: css({
      position: "fixed",
      bottom: 105,
      width: "calc(100% - 20px)",
      height: "40px",
      background: "#353E43",
      margin: "0 -0.5rem",
      textAlign: "center",
      zIndex: 150,
      padding: "0 10px",
    }),

    fixedShiftPatternContainer: css({
      position: "fixed",
      bottom: 0,
      width: "100%",
      height: "40px",
      background: "white",
      margin: "0 -0.5rem",
      textAlign: "center",

      alignItems: "center",
      justifyContent: "center",
      display: "flex",

      zIndex: 100,
    }),
    fixedContentContainer: css({
      position: "fixed",
      bottom: 0,
      width: "100%",
      height: "80px",
      background: "white",
      margin: "0 -0.5rem",
      textAlign: "center",
      overflowY: "scroll",
      zIndex: 100,
    }),
    fixedContentContainerTwo: css({
      position: "fixed",
      bottom: 30,
      width: "100%",
      height: "80px",
      background: "white",
      margin: "0 -0.5rem",
      textAlign: "center",
      overflowY: "scroll",
      zIndex: 100,
    }),
  };
  const selectedHopeShiftList = hopeShiftList.filter((item) => item.targetDate === selectedHopeShift?.targetDate);

  return (
    <div>
      {shiftPatternOptions &&
        selectedHopeShift &&
        (selectedHopeShift.fixStatus === ScheduleStatus.UNSUBMITED ||
          selectedHopeShift.fixStatus === ScheduleStatus.NOT_APPROVED ||
          selectedHopeShift.fixStatus === ScheduleStatus.NO_REGISTER ||
          (selectedHopeShift.fixStatus === ScheduleStatus.DAY_OFF && selectedHopeShift.isEditable)) && (
          <div>
            <div css={barStyle.line}> </div>
            <div css={barStyle.fixedDateTitleContainer}>
              <BodyText size="md" customStyle={css({ textAlign: "center", width: "90%", paddingLeft: "10%" })}>
                {moment(selectedHopeShift.targetDate).format("YYYY年MM月DD日")}
              </BodyText>
              <ClickableIconButton
                onClick={() => {
                  setIsHasActionBar(false);
                }}
              >
                <Icon type="close" color={grayScale.gray100} />
              </ClickableIconButton>
            </div>
            <div css={barStyle.fixedSelectBoxContainer}>
              <MobileSelect
                id="pattern"
                onChange={(e) => {
                  setter.setFlgCanWork(false);
                  const val = e.target.value;
                  const selectedShiftPattern = shiftPatternList!.filter((item) => item.shiftPatternId === val)[0];
                  if (val === "CANTWORK") {
                    const editedHopeShift = new HopeShiftDomain({
                      ...selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: null,
                      endTime: null,
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 2,
                      note: "",
                    });
                    setSelectedHopeShift!(editedHopeShift);
                  } else if (val === "CANWORK") {
                    setter.setFlgCanWork(true);
                    const editedHopeShift = new HopeShiftDomain({
                      ...selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime:
                        selectedHopeShift!.startTime ||
                        moment(selectedHopeShift.targetDate)
                          .hour(state.timeSettings.startHour)
                          .minute(0)
                          .toDate(),
                      endTime:
                        selectedHopeShift!.endTime ||
                        moment(selectedHopeShift.targetDate)
                          .hour(state.timeSettings.endHour)
                          .minute(0)
                          .toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                    });
                    setSelectedHopeShift!(editedHopeShift);
                  } else {
                    // Get end time
                    const shiftDetailEnd: AttendShiftPatternDetails = selectedShiftPattern!.attendShiftPatternDetails[
                      selectedShiftPattern!.attendShiftPatternDetails.length - 1
                    ];
                    const [hours, minutes, seconds] = shiftDetailEnd.endTime.split(":");
                    const endTime = new Date(selectedHopeShift!.targetDate);

                    // NOTE: 翌日チェックはBack側でやる
                    // if (shiftDetailEnd.isEndTimeNextDay) {
                    //   endTime.setDate(endTime.getDate() + 1);
                    // }

                    endTime.setHours(Number(hours));
                    endTime.setMinutes(Number(minutes));
                    endTime.setSeconds(Number(seconds));
                    // Get start time
                    const shiftDetailStart: AttendShiftPatternDetails =
                      selectedShiftPattern.attendShiftPatternDetails[0];
                    const [h, m, s] = shiftDetailStart.startTime.split(":");
                    const startTime = new Date(selectedHopeShift!.targetDate);

                    // NOTE: 翌日チェックはBack側でやる
                    // if (shiftDetailStart.isStartTimeNextDay) {
                    //   startTime.setDate(endTime.getDate() + 1);
                    // }

                    startTime.setHours(Number(h));
                    startTime.setMinutes(Number(m));
                    startTime.setSeconds(Number(s));

                    const editedHopeShift = new HopeShiftDomain({
                      ...selectedHopeShift!.getRawData(),
                      shiftPatternId: selectedShiftPattern.shiftPatternId,
                      startTime,
                      endTime,
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 3,
                      note: "",
                    });
                    setSelectedHopeShift!(editedHopeShift);
                  }
                }}
                disabled={!selectedHopeShift.isEditable}
                options={[{ value: "", label: "-" }, ...shiftPatternOptions]}
                value={valueSelectType}
              />
            </div>

            <div css={barStyle.fixedStartEndTimeContainer}>
              <div style={{ flex: 1, display: "flex", alignItems: "center", flexDirection: "row" }}>
                <MobileSelect
                  id="midnightStartHour"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!selectedHopeShift.startTime || val === "") return;
                    const tmpVal = state.startTimeGlobal
                      .hour(Number(val))
                      .minutes(state.startTimeGlobal.toDate().getMinutes());
                    setter.setStartTimeGlobal(tmpVal);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: tmpVal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      businessId: state.businessId,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }}
                  disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                  options={hourFormOptions}
                  value={state.startTimeGlobal ? state.startTimeGlobal.format("HH") : "00"}
                />
                <BodyText color={utilityColor.white} customStyle={css({ margin: "0 2px 4px 2px" })}>
                  :
                </BodyText>
                <MobileSelect
                  id="midnightStartTime"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!selectedHopeShift.startTime || val === "") return;
                    const tmpVal = state.startTimeGlobal
                      .hour(state.startTimeGlobal.toDate().getHours())
                      .minutes(Number(val));
                    setter.setStartTimeGlobal(tmpVal);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: tmpVal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      businessId: state.businessId,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }}
                  disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                  options={timeFormOptions}
                  value={state.startTimeGlobal ? state.startTimeGlobal.format("mm") : "00"}
                />
              </div>

              <div style={{ height: 42, display: "flex", alignItems: "center" }}>
                <BodyText color={utilityColor.white} customStyle={css({ margin: "0 2px 4px 2px" })}>
                  〜
                </BodyText>
              </div>
              <div style={{ flex: 1, display: "flex", alignItems: "center", flexDirection: "row" }}>
                <MobileSelect
                  id="midnightEndHour"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!selectedHopeShift.endTime || val === "") return;
                    const tmpVal = state.endTimeGlobal
                      .hour(Number(val))
                      .minutes(state.endTimeGlobal.toDate().getMinutes());
                    setter.setEndTimeGlobal(tmpVal);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: tmpVal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      businessId: state.businessId,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }}
                  disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                  options={hourFormOptions}
                  value={state.endTimeGlobal ? state.endTimeGlobal.format("HH") : "00"}
                />
                <BodyText color={utilityColor.white} customStyle={css({ margin: "0 2px 4px 2px" })}>
                  :
                </BodyText>
                <MobileSelect
                  id="midnightEndTime"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!selectedHopeShift.endTime || val === "") return;
                    const tmpVal = state.endTimeGlobal
                      .hour(state.endTimeGlobal.toDate().getHours())
                      .minutes(Number(val));
                    setter.setEndTimeGlobal(tmpVal);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: tmpVal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      businessId: state.businessId,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }}
                  disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                  options={timeFormOptions}
                  value={state.endTimeGlobal ? state.endTimeGlobal.format("mm") : "00"}
                />
              </div>
            </div>

            {issetBusinessOptions && (
              <div css={barStyle.fixedSelectBusinessIdContainer}>
                <MobileSelect
                  id="businessId"
                  onChange={(e) => {
                    const val = e.target.value;
                    setter.setBusinessId(val);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      hopeShiftPatternType: 1,
                      businessId: val,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }}
                  disabled={!state.selectedHopeShift?.isEditable || valueSelectType !== "CANWORK"}
                  options={[
                    { value: "", label: "-" },
                    ...state.businessOptions.filter((item: any) => {
                      if (valueSelectType === "CANWORK" && item.label === "休憩") {
                        return false;
                      }
                      return true;
                    }),
                  ]}
                  value={state.businessId}
                />
              </div>
            )}

            <div css={barStyle.fixedNoteContainer}>
              <TextForm
                name="comment"
                label="コメント"
                value={state.note}
                onChange={(e) => {
                  setter.setNote(e.target.value);
                }}
                customStyleLabel={css({ color: "white", marginBottom: "2px", marginTop: "6px" })}
                onBlur={(e) => {
                  if (state.selectedHopeShift?.note !== e.target.value) {
                    setter.setNote(e.target.value);
                    const editedHopeShift = new HopeShiftDomain({
                      ...state.selectedHopeShift!.getRawData(),
                      shiftPatternId: state.selectedHopeShift ? state.selectedHopeShift.shiftPatternId : "",
                      startTime: state.startTimeGlobal.toDate(),
                      endTime: state.endTimeGlobal.toDate(),
                      fixStatus: ScheduleStatus.UNSUBMITED,
                      businessId: state.businessId,
                      note: e.target.value,
                    });
                    setter.setSelectedHopeShift(editedHopeShift);
                  }
                }}
                disable={!state.selectedHopeShift?.isEditable}
              />
            </div>

            <div css={barStyle.fixedShiftPatternContainer}>
              <BodyText size="md">{selectedHopeShift.orgName}</BodyText>
            </div>
          </div>
        )}

      {selectedHopeShift &&
        (selectedHopeShift.fixStatus === ScheduleStatus.ACTUAL ||
          selectedHopeShift.fixStatus === ScheduleStatus.APPROVED ||
          (selectedHopeShift.fixStatus === ScheduleStatus.DAY_OFF && !selectedHopeShift.isEditable)) && (
          <div>
            <div css={barStyle.lineTwo}> </div>
            <div css={barStyle.fixedDateTitleContainerTwo}>
              <BodyText size="md" customStyle={css({ textAlign: "center", width: "90%", paddingLeft: "10%" })}>
                {moment(selectedHopeShift.targetDate).format("YYYY年MM月DD日")}
              </BodyText>
              <ClickableIconButton
                onClick={() => {
                  setIsHasActionBar(false);
                }}
              >
                <Icon type="close" color={grayScale.gray100} />
              </ClickableIconButton>
            </div>
            <div css={barStyle.fixedContentContainerTwo}>
              {selectedHopeShiftList.map((item) => {
                if (item.fixStatus === ScheduleStatus.DAY_OFF && !item.isEditable) {
                  return (
                    <div>
                      <FlexBox>
                        <BodyText size="md" customStyle={css({ textAlign: "center", width: "100%" })}>
                          {item.holidayName}
                        </BodyText>
                      </FlexBox>
                      <div css={barStyle.fixedNoteContainerTwo}>
                        <TextForm
                          name="comment"
                          label="コメント"
                          value={state.note}
                          onChange={(e) => {
                            setter.setNote(e.target.value);
                          }}
                          customStyleLabel={css({ color: "white" })}
                          onBlur={(e) => {
                            if (state.selectedHopeShift?.note !== e.target.value) {
                              setter.setNote(e.target.value);
                              const editedHopeShift = new HopeShiftDomain({
                                ...state.selectedHopeShift!.getRawData(),
                                shiftPatternId: state.selectedHopeShift ? state.selectedHopeShift.shiftPatternId : "",
                                startTime: state.startTimeGlobal.toDate(),
                                endTime: state.endTimeGlobal.toDate(),
                                fixStatus: ScheduleStatus.UNSUBMITED,
                                businessId: state.businessId,
                                note: e.target.value,
                              });

                              setter.setSelectedHopeShift(editedHopeShift);
                            }
                          }}
                          disable={!state.selectedHopeShift?.isEditable}
                        />
                      </div>
                    </div>
                  );
                }
                return (
                  <FlexBox>
                    <BodyText size="md" customStyle={css({ textAlign: "center", width: "50%" })}>
                      {`${item.startTime ? `${moment(item.startTime!).format("HH:mm")} ~` : ""} ${
                        item.endTime ? moment(item.endTime!).format("HH:mm") : ""
                      }`}
                    </BodyText>
                    <BodyText size="md" customStyle={css({ textAlign: "center", width: "50%" })}>
                      {item.orgName}
                    </BodyText>
                  </FlexBox>
                );
              })}
            </div>
          </div>
        )}
    </div>
  );
};
export default ActionBar;
