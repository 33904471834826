import { useState, useEffect, useCallback } from 'react';
import { getMonthInfoAgreement36Data, getDetailStaffAgreement36 } from 'api/agreement36';
import useToastNotification from 'hooks/useToastNotification';
import moment from 'moment';
import { useOrgTreesOptionsForAgreements } from 'hooks/useOrgTreesOptions';
import { Attend36AgreementsAlertData } from './../type';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import { getSetting36AgreementById, getSetting36AgreementByMonthAndStaffCode } from 'api/master36Agreement';
import { OptionType } from 'components/atoms/Select';
import { useAgreementsOptionsByMonth } from 'hooks/useAgreementsOptions';

const { errorNotification, successNotification } = useToastNotification();

export type MonthlyAgreement36Data = {
  agreementsId: string;
  targetYear: number;
  applyStartMonth: Date;
  monthlyOvertimeList: Array<number>|null;
  monthlyOvertimeHourList: Array<number>|null;
  monthlyOverAndHolidayTimeList: Array<number>|null;
  monthlyOverAndHolidayTimeHourList: Array<number>|null;
}
export type MonthlyInfoAgreement36Data = {
  staffCode: string;
  staffName: string;
  orgCode: string;
  orgName: string;
  remainDays: number;
  overTimeHour: number;
  holidayTimeHour: number;
  overHolidayTimeHour: number;
  overTimeFlag: number;
  overHolidayTimeFlag: number;
  agreementsId: string;
}

const initMonthlyAgreement36Data: MonthlyAgreement36Data = {
  agreementsId: '',
  targetYear: 0,
  applyStartMonth: new Date(),
  monthlyOvertimeList: null,
  monthlyOvertimeHourList: null,
  monthlyOverAndHolidayTimeList: null,
  monthlyOverAndHolidayTimeHourList: null,
}

const testMonthlyAgreement36Data: MonthlyAgreement36Data = {
  agreementsId: '84518214-de1d-4750-9314-38136d1e3157',
  targetYear: 2020,
  applyStartMonth: new Date(),
  monthlyOvertimeList: [1800,2100,2400,3600],
  monthlyOvertimeHourList: [30,35,40,60],
  monthlyOverAndHolidayTimeList: [60000],
  monthlyOverAndHolidayTimeHourList: [100],
}

export const useMonthlyAgreement36Form = () => {
  const staffCode = sessionStorage.getItem('loginUser.staffCode') || "";
  const [orgCode, setOrgCode] = useState(sessionStorage.getItem('monthlyAgreement36.orgCode') || sessionStorage.getItem('loginUser.orgCode') || '');
  const [applyDate, setApplyDate] = useState(new Date(sessionStorage.getItem('monthlyAgreement36.applyDate') || new Date()));
  const [monthlyAgreement36Data, setMonthlyAgreement36Data] = useState<MonthlyAgreement36Data>(initMonthlyAgreement36Data);
  const [monthlyInfoData, setMonthlyInfoData] = useState<MonthlyInfoAgreement36Data[]>();
  const [staffDetail, setStaffDetail] = useState<Attend36AgreementsAlertData[]>();
  const [flag, setFlag] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [overTime, setOverTime] = useState(Number(sessionStorage.getItem('monthlyAgreement36.monthlyOverTime')) || 0 );
  const [overTimeOptions, setOverTimeOptions] = useState<Array<OptionType>>([]);
  const [overHolidayTime, setOverHolidayTime] = useState(Number(sessionStorage.getItem('monthlyAgreement36.monthlyOverHolidayTime')) || 0 );
  const [overHolidayTimeOptions, setOverHolidayTimeOptions] = useState<Array<OptionType>>([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.agreement36);
  const [underFlag, setUnderFlag] = useState(Number(sessionStorage.getItem('monthlyAgreement36.underFlag')) || 0 );
  const [agreementsId, setAgreementsId] = useState(sessionStorage.getItem('monthlyAgreement36.agreementsId') || '');
  const agreementsOptions = useAgreementsOptionsByMonth(applyDate, functionCode.agreement36);
  const orgTreesOptions = useOrgTreesOptionsForAgreements(true, functionCode.agreement36, agreementsId? agreementsId : '', moment(applyDate).startOf('month').format('YYYY-MM-DD'), moment(applyDate).endOf('month').format('YYYY-MM-DD'));

  const fetchMonthInfo = async (applyDate: Date) => {
    let params = {
      targetMonth: moment(applyDate).format('YYYY/MM/01'),
      orgCode: detailRole.accessRange === 0 ? '' : orgCode,
      overTime: overTime,
      overHolidayTime: overHolidayTime,
      underFlag,
      agreementsId,
    }
    try {
      if (flag) {
        setIsLoading(true);
        getMonthInfoAgreement36Data(params).then((response: any) => {
          setIsLoading(false);
          setMonthlyInfoData(response);
        }).catch((error: any) => {
          setIsLoading(false);
          if (error.status === 400) {
            if (error.data.errors && error.data.errors.length > 0) {
              errorNotification(
                error.data.errors.map((x: any) => x.defaultMessage)
              );
            } else if (error.data.defaultMessage) {
              errorNotification(error.data.defaultMessage);
            } else {
              errorNotification(error.data.message);
            }
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }  
        })
      } else {
        setMonthlyInfoData([]);
      }

    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      if (sessionStorage.getItem('monthlyAgreement36.agreementsId')) {
        sessionStorage.removeItem('monthlyAgreement36.agreementsId');
      }
      if (sessionStorage.getItem('monthlyAgreement36.applyDate')) {
        sessionStorage.removeItem('monthlyAgreement36.applyDate');
      }
      if (sessionStorage.getItem('monthlyAgreement36.orgCode')) {
        sessionStorage.removeItem('monthlyAgreement36.orgCode');
      }
      if (sessionStorage.getItem('monthlyAgreement36.underFlag')) {
        sessionStorage.removeItem('monthlyAgreement36.underFlag');
      }
      if (sessionStorage.getItem('monthlyAgreement36.monthlyOverTime')) {
        sessionStorage.removeItem('monthlyAgreement36.monthlyOverTime');
      }
      if (sessionStorage.getItem('monthlyAgreement36.monthlyOverHolidayTime')) {
        sessionStorage.removeItem('monthlyAgreement36.monthlyOverHolidayTime');
      }
    }
  };



  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setFlag(false);
    try {
      getSetting36AgreementById(agreementsId? agreementsId : '').then((response: any) => {
        if(!response || response.agreementsId === null){
          setAgreementsId('');
          setOverTimeOptions([{label:'指定なし', value:'0'}]);
          setOverTime(0);
          setOverHolidayTimeOptions([{label:'指定なし', value:'0'}]);
          setOverHolidayTime(0);
        } else {
          let overTimeOp = response.monthlyOvertimeHourList?.map((item: any) => ({
              value: String(item * 60),
              label: String(item)+'時間を超過',
            }));
          if (overTimeOp) {
            overTimeOp.unshift({label:'指定なし', value:'0'})
            setOverTimeOptions(overTimeOp);
            setOverTime(getValueRadio(overTime, response.monthlyOvertimeList));
          } else {
            setOverTimeOptions([{label:'指定なし', value:'0'}]);
            setOverTime(0);
          }

          let overAndHolidayTime = response.monthlyOverAndHolidayTimeHourList?.map((item: any) => ({
            value: String(item * 60),
            label: String(item)+'時間を超過',
          }));
          if (overAndHolidayTime) {
            overAndHolidayTime.unshift({label:'指定なし', value:'0'})
            setOverHolidayTimeOptions(overAndHolidayTime);
            setOverHolidayTime(getValueRadio(overHolidayTime, response.monthlyOverAndHolidayTimeList));
          } else {
            setOverHolidayTimeOptions([{label:'指定なし', value:'0'}]);
            setOverHolidayTime(0);
          }
          setFlag(true);
        }
      }).catch((error: any) => {
        setIsLoading(false);
        if (error.status === 400) {
          if (error.data.errors && error.data.errors.length > 0) {
            errorNotification(
              error.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.data.defaultMessage) {
            errorNotification(error.data.defaultMessage);
          } else {
            errorNotification(error.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }  
        setFlag(true);
      }).finally(() => {
      })
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
      setIsLoading(false);
    } finally{
      setIsLoading(false);
    }
  }, [agreementsId,]);


  const fetchDataInit = useCallback(async () => {
    setIsLoading(true);
    let params = {
      targetMonth: moment(applyDate).format('YYYY/MM/01'),
    }
    try {
      getSetting36AgreementByMonthAndStaffCode(params).then((response: any) => {
        if(!response){
          errorNotification('36協定アラートマスタが登録されていません');
          setAgreementsId(response.agreementsId);
        }
        setMonthlyAgreement36Data(response);
        setAgreementsId(response.agreementsId);
      }).catch((error: any) => {
        setIsLoading(false);
        if (error.status === 400) {
          if (error.data.errors && error.data.errors.length > 0) {
            errorNotification(
              error.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.data.defaultMessage) {
            errorNotification(error.data.defaultMessage);
          } else {
            errorNotification(error.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }  
      }).finally(() => {
      })
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
      setIsLoading(false);
    } finally{
      setIsLoading(false);
    }
  }, []);


  const fetchDataDetailStaff = useCallback(async (staffCode: string, id : string) => {
    setIsLoading(true);
    try {
      getDetailStaffAgreement36(staffCode, id).then((response: any) => {
        setStaffDetail(response);        
      }).catch((error: any) => {
        if (error.status === 400) {
          if (error.data.errors && error.data.errors.length > 0) {
            errorNotification(
              error.data.errors.map((x: any) => x.defaultMessage)
            );
          } else if (error.data.defaultMessage) {
            errorNotification(error.data.defaultMessage);
          } else {
            errorNotification(error.data.message);
          }
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }  
      }).finally(() => {
        setIsLoading(false);
      })
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    } finally{
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if(flag){
      fetchMonthInfo(applyDate);
    }
  }, [flag, applyDate, orgCode, underFlag, overTime, overHolidayTime])

  useEffect(() => {
    if (agreementsOptions && agreementsOptions.length && agreementsId !== '') {
      setAgreementsId(agreementsOptions.some(b => b.value === agreementsId) ? agreementsId : agreementsOptions[0].value);
    } else if (agreementsOptions && agreementsOptions.length){
      fetchDataInit();
    } else {
      setOverTimeOptions([{label:'指定なし', value:'0'}]);
      setOverTime(0);
      setOverHolidayTimeOptions([{label:'指定なし', value:'0'}]);
      setOverHolidayTime(0);
      setFlag(false);
      setMonthlyInfoData([]);
    }
  }, [agreementsOptions]);

  useEffect(() => {
      fetchData();
  }, [agreementsId]);

  const handleOpenDetail = (staffCode: string, id : string) => {
    setIsOpenModal(true);
    fetchDataDetailStaff(staffCode, id);
  }
 

  const getValueRadio = (curVal: number, optionList : Array<number>|null) => {
    if (optionList !== null) {
      for (let i = 0; i < optionList.length; i++) {
        if (optionList[i] === curVal) {
          return optionList[i];
        }
      }
      return optionList[optionList.length - 1];
    }
    return 0;
  }


  return {
    isLoading,
    applyDate,
    setApplyDate,
    staffCode,
    orgCode,
    setOrgCode,
    orgTreesOptions,
    monthlyInfoData,
    isOpenModal,
    setIsOpenModal,
    handleOpenDetail,
    staffDetail,
    detailRole,
    underFlag,
    setUnderFlag,
    agreementsId,
    setAgreementsId,
    agreementsOptions,
    overTime,
    setOverTime,
    overTimeOptions,
    overHolidayTime,
    setOverHolidayTime,
    overHolidayTimeOptions,
  };
};

export default useMonthlyAgreement36Form;
