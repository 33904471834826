/** @jsx jsx */

import { PropsWithChildren, ReactNode, useState } from "react";
import { css, jsx } from "@emotion/core";
import Select, { OptionTypeBase, createFilter, components, MenuPlacement } from "react-select";
import { FixedSizeList as List } from "react-window";
import FlexBox from "components/atoms/FlexBox";
import FormLabel from "components/atoms/Form/FormLabel";
import FormDescription from "components/atoms/Form/FormDescription";
import { grayScale, productColor, textFontSize, textColor, utilityColor } from "components/styles";
import Icon from "components/atoms/Icon";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import React from "react";

const shadow = "hsla(218, 50%, 10%, 0.1)";
const styles = {
  container: css({
    padding: "4px 0",
  }),
  menu: css({
    backgroundColor: "white",
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
    marginTop: 2,
    position: "absolute",
    zIndex: 888,
    width: "100%",
  }),
  dropdownIndicator: css({
    height: 28,
    width: 32,
  }),
  button: css(
    {
      width: "100% !important",
      border: "1px solid #DCDCDC",
      backgroundColor: "#fff",
      height: "42px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px 8px",
      fontFamily: '"Noto Sans JP", sans-serif',
    },
    css`
      span:last-child {
        justify-content: flex-end;
        padding-right: 3px;
      }
    `,
  ),
  dropdown: (disabled?: boolean | any) =>
    css(
      {
        position: "relative",
      },
      css`
        span {
          width: 100%;
          text-align: left;
          font-size: 14px;
          font-family: "Noto Sans JP", sans-serif;
          color: ${disabled ? "#999999" : "#333333"};
        }
        span span:nth-child(2) {
          width: auto;
        }
        .dropdown-menu-item:hover {
          background-color: #e8f1fd;
        }
        .dropdown-menu-item > div {
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      `,
    ),
  select: (label: any, isDisplayBlock?: boolean, islabelLong?: boolean) =>
    css(
      {
        width:
          label && islabelLong ? "calc(100% - 180px)" : label === "" || isDisplayBlock ? "100%" : "calc(100% - 100px)",
        minWidth: "70px",
      },
      css`
        button[disabled] {
          cursor: not-allowed;
          background: #f5f5f5;
        }
        // button[disabled] svg{
        //   display: none;
        // }
      `,
    ),
  blanket: css({
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
    position: "fixed",
    zIndex: 1,
  }),
  labelLong: css({
    width: "180px",
  }),
  label: css({
    width: "100px",
  }),
  noteText: css({
    fontSize: textFontSize.sm,
    color: textColor.subText01,
    marginLeft: "4px",
  }),
  error: css({
    marginTop: "4px",
    color: "red",
  }),
  labelText: css(
    {
      boxSizing: "border-box",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    css`
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
    `,
  ),
  iconArrow: css({
    marginBottom: "4px",
  }),
};

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    // minWidth: 240,
    margin: 8,
  }),
  menu: () => ({
    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
    fontSize: 10,
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    fontSize: 12,
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: 14,
  }),
  multiValueRemove: (oldStyles: any) => ({
    ...oldStyles,
    lineHeight: "21px",
    paddingRight: "6px",
    ":hover": {
      cursor: "pointer",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: textFontSize.re,
    backgroundColor: (() => {
      if (state.isSelected) return productColor.primary;
      if (state.isFocused) return productColor.primaryM80;
      return "";
    })(),
    color: state.isSelected ? textColor.inversed : textColor.main,
  }),
};

const ChevronDown = () => (
  <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5555 0.210666C12.9225 -0.103828 13.4748 -0.0613366 13.7893 0.305573C14.1038 0.672482 14.0613 1.22487 13.6944 1.53936L7.56944 6.78935C7.24176 7.07022 6.75824 7.07022 6.43056 6.78935L0.305573 1.53936C-0.0613366 1.22487 -0.103828 0.672482 0.210666 0.305573C0.52516 -0.0613366 1.07755 -0.103828 1.44446 0.210666L7 4.97256L12.5555 0.210666Z"
      fill="rgb(204, 204, 204)"
    />
  </svg>
);

const MenuList = (props: PropsWithChildren<any>) => {
  const height = 37;
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = children.length < 10 ? options.indexOf(value) : options.indexOf(value) * height;

  return (
    <List
      height={children.length < 10 || children.length == undefined ? (children.length || 0) * 37 : maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ style, index }: { style: any; index: any }) => (
        <div style={style} className="dropdown-menu-item">
          {children[index]}
        </div>
      )}
    </List>
  );
};

type DropdownProps = {
  isOpen: boolean;
  target: ReactNode;
  onClose: Function;
  disabled?: boolean;
};

export interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}

const OptimizedOption = (props: any) => {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
};

// Convert select to text
const ghostStyle = () => css`
  div {
    &:hover,
    &:focus {
      border: 0;
    }
    svg {
      display: none;
    }
    background-color: transparent;
    color: ${grayScale.gray100};
    border: 0;
    margin: 0;
    padding: 0;
  }
`;

const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <Icon type="close" color={grayScale.gray100} />
  </components.MultiValueRemove>
);

const SelectForm: React.FC<{
  name: string;
  value: string;
  options: Array<OptionType>;
  setValue: (val: string) => void;
  description?: string;
  note?: string;
  isMulti?: boolean;
  errorMsg?: string;
  required?: boolean;
  disable?: boolean;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
  zIndex?: number;
  readOnly?: boolean;
  islabelLong?: boolean;
  width?: string;
  customStyle?: any;
  customStyleContainer?: any;
  widthLabel?: string;
  isHiddenLabel?: boolean;
  ghost?: boolean;
  label?: string;
  onMenuOpen?: any;
  onMenuClose?: any;
  isDisplayBlock?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  marginRight?: string;
}> = ({
  options,
  value,
  setValue,
  islabelLong,
  widthLabel,
  label,
  description,
  disable,
  ghost,
  menuPlacement,
  note,
  isMulti,
  errorMsg,
  required,
  onMenuOpen,
  onMenuClose,
  isDisplayBlock,
  width,
  customStyleContainer,
  onChange = () => {},
  marginRight,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleOnChange = (option: any) => {
    toggleOpen();
    if (!option) {
      setValue("");
    } else if (Array.isArray(option) && option.length) {
      const array = option.map((opt) => (opt as OptionType).value);
      setValue(`${array.toString()},`);
    } else {
      setValue((option as OptionType).value);
    }
    onChange(option);
  };

  const getValue = (value: string) => {
    if (options) {
      const optionFilter = options.filter((option) => option.value === value);

      if (optionFilter.length === 0) {
        return { value: "", label: "-" };
      }
      return optionFilter[0];
    }
    return "" as any;
  };

  const rand = 1 + Math.random() * (1 - 10000);

  return (
    <div css={[styles.container, css({ width: width, marginRight }), customStyleContainer]}>
      <FlexBox customStyle={css({ display: isDisplayBlock ? "block" : "flex" })}>
        {label && (
          <div
            css={[
              islabelLong ? styles.labelLong : styles.label,
              css({
                width: widthLabel,
                marginBottom: isDisplayBlock ? "12px" : "0px",
              }),
            ]}
          >
            <FormLabel customStyle={css({ marginBottom: "0px" })} label={label} required={required} />
          </div>
        )}

        <div css={css(styles.select(label, isDisplayBlock, islabelLong))}>
          <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
              // @ts-ignore
              <Button onClick={toggleOpen} disabled={ghost || disable} css={styles.button}>
                <div css={styles.labelText}>
                  {ghost ? (
                    <span>{value ? getValue(value)?.label.trim() : "-"}</span>
                  ) : (
                    <React.Fragment>
                      <span data-for={`tooltip${rand}`} data-tip="true" css={label}>
                        {value ? getValue(value)?.label?.trim() : "-"}
                      </span>
                      <ReactTooltip html={true} id={`tooltip${rand}`} place={"top"}>
                        {value ? getValue(value)?.label.trim() : "-"}
                      </ReactTooltip>
                    </React.Fragment>
                  )}
                </div>
                <div css={styles.iconArrow}>
                  <ChevronDown />
                </div>
              </Button>
            }
            disabled={disable}
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{
                MenuList,
                DropdownIndicator,
                MultiValueRemove,
                IndicatorSeparator: null,
                Option: OptimizedOption,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={handleOnChange}
              options={options}
              isMulti={isMulti}
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              isDisabled={disable || ghost}
              placeholder={""}
              styles={selectStyles}
              tabSelectsValue={false}
              menuPlacement={menuPlacement}
              value={getValue(value) || null}
              filterOption={createFilter({ ignoreAccents: false })}
              css={ghost ? ghostStyle : {}}
            />
          </Dropdown>
        </div>
      </FlexBox>

      {errorMsg ? <span css={styles.error}> {errorMsg} </span> : ""}

      {description && (
        <FlexBox>
          <div css={styles.label} />
          <div css={styles.select}>{description && <FormDescription description={description} />}</div>
        </FlexBox>
      )}
      {note && (
        <span css={css(styles.noteText, { color: utilityColor.error })} dangerouslySetInnerHTML={{ __html: note }} />
      )}
    </div>
  );
};

const Menu = (props: any) => <div css={styles.menu} {...props} />;
const Blanket = (props: any) => <div css={styles.blanket} {...props} />;

const Dropdown = ({ children, isOpen, target, onClose, disabled }: PropsWithChildren<DropdownProps>) => (
  <div css={styles.dropdown(disabled)}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const DropdownIndicator = () => (
  <div css={styles.dropdownIndicator}>
    <Icon type="search" color="" />
  </div>
);

export default SelectForm;
