import React from 'react';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';

/**
 * 組織詳細項目
 */
const EmploymentInputHintDisplay: React.FC<{
	formik: any;
}> = ({
  formik,
}) => (
  <>
    <FormSubmitArea>
      {/* <Button
        text="雇用形態を作成"
        onClick={formik.handleSubmit}
      /> */}
    </FormSubmitArea>
  </>
);

export default EmploymentInputHintDisplay;
