/* eslint-disable array-callback-return */
import { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";

import EmploymentDomain, { Employment } from "domain/employment";
import { createOrUpdateEmployment, getEmployment, validateEmploymentForm } from "api/employment";
import useToastNotification from "hooks/useToastNotification";
import moment from "moment";
import getList from "api/termsMaster";
import { Terms } from "domain/master/attend/terms";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

type EmploymentDomainKey = keyof Pick<EmploymentDomain, "employmentCode" | "employmentName">;

export const useEmploymentAddForm = (isEdit?: number, employmentId?: string, applyStartDate?: string) => {
  const history = useHistory();
  const location = useLocation();

  const [stepper, setStepper] = useState(1);

  // 登録内容確認モーダルの開閉状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // Modal alert
  const { successNotification, errorNotification } = useToastNotification();

  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.masterEmploymentForm);

  const [editEmployment, setEditEmployment] = useState(EmploymentDomain.generateInitial(isEdit));

  const [termMst, setTermMst] = useState<Array<Terms>>([]);

  const [applicationDate, setApplicationDate] = useState(1);

  const [flagValidate, setFlagValidate] = useState(0);

  const [isSubmit, setIsSubmit] = useState(true);

  const [applyDateChoose, setApplyDateChoose] = useState(new Date());

  const onSubmit = async (values: EmploymentDomain, flagStepper?: any) => {
    let type = location.pathname.split("/")[2];
    try {
      const postData = values.getRawData();
      postData.employmentId = postData.id.employmentId;
      postData.createUser = sessionStorage.getItem("loginUser.staffName") || "";
      postData.updateUser = sessionStorage.getItem("loginUser.staffName") || "";
      postData.businessesIds = postData.businesses.map((business) => business.businessId);
      postData.attendHolidayIds = postData.holidays.map((holiday) => holiday.holidayId);
      postData.attendAllowanceIds = postData.allowances.map((allowance) => allowance.allowanceId);
      postData.staffCodes = postData.noticeStaffs.map((noticeStaff) => noticeStaff.staffCode);
      postData.contractWorkHourDay =
        parseInt(postData.contractWorkHourDayHours) * 60 + parseInt(postData.contractWorkHourDayMinutes);

      if (!postData.useRoundMonthlyStatistic) {
        postData.roundUnitMonthlyStatistic = 0;
        postData.roundMethodMonthlyStatistic = 0;
      }

      postData.timeHoliday = 0;
      postData.timeHolidayMaxHour = "0";
      postData.timeHolidayHourOfDay = "0";

      if (applicationDate === 0) {
        formik.setFieldValue("applyDate", applyDateChoose);
      } else {
        const objMonth = new Date();
        objMonth.setMonth(objMonth.getMonth() + 1);
        postData.applyDate = moment(objMonth).format("YYYY/MM/DD");
      }

      if (String(postData.workSystem) === "1") {
        postData.startTime = "00:00";
        postData.isStartTimeNextDay = false;
        postData.endTime = "00:00";
        postData.isEndTimeNextDay = false;
      }

      if (String(postData.workSystem) === "2" || String(postData.workSystem) === "3") {
        postData.roundShiftStartTime = 0;
        postData.roundShiftEndTime = 0;
      }

      if (String(postData.workSystem) === "2") {
        postData.useOverTimeApplication = 0;
      }

      if (String(postData.useAutoRecess) !== "1") {
        postData.autoRecessWorkConditionTime1 = 0;
        postData.autoRecessWorkConditionTime2 = 0;
        postData.autoRecessWorkConditionTime3 = 0;
        postData.autoRecessGrantTime1 = 0;
        postData.autoRecessGrantTime2 = 0;
        postData.autoRecessGrantTime3 = 0;
      }

      if (String(postData.useAutoRecess) !== "2") {
        postData.recessStartTime1 = "00:00";
        postData.recessEndTime1 = "00:00";
        postData.isRecessStartTimeNextDay1 = false;
        postData.isRecessEndTimeNextDay1 = false;
        postData.recessStartTime2 = "00:00";
        postData.recessEndTime2 = "00:00";
        postData.isRecessStartTimeNextDay2 = false;
        postData.isRecessEndTimeNextDay2 = false;
        postData.recessStartTime3 = "00:00";
        postData.recessEndTime3 = "00:00";
        postData.isRecessStartTimeNextDay3 = false;
        postData.isRecessEndTimeNextDay3 = false;
      } else {
        if (!postData.recessStartTime1) {
          postData.recessStartTime1 = "00:00";
        }
        if (!postData.recessEndTime1) {
          postData.recessEndTime1 = "00:00";
        }
        if (!postData.isRecessStartTimeNextDay1) {
          postData.isRecessStartTimeNextDay1 = false;
        }
        if (!postData.isRecessEndTimeNextDay1) {
          postData.isRecessEndTimeNextDay1 = false;
        }
        if (!postData.recessStartTime2) {
          postData.recessStartTime2 = "00:00";
        }
        if (!postData.recessEndTime2) {
          postData.recessEndTime2 = "00:00";
        }
        if (!postData.isRecessStartTimeNextDay2) {
          postData.isRecessStartTimeNextDay2 = false;
        }
        if (!postData.isRecessEndTimeNextDay2) {
          postData.isRecessEndTimeNextDay2 = false;
        }
        if (!postData.recessStartTime3) {
          postData.recessStartTime3 = "00:00";
        }
        if (!postData.recessEndTime3) {
          postData.recessEndTime3 = "00:00";
        }
        if (!postData.isRecessStartTimeNextDay3) {
          postData.isRecessStartTimeNextDay3 = false;
        }
        if (!postData.isRecessEndTimeNextDay3) {
          postData.isRecessEndTimeNextDay3 = false;
        }
      }

      if (![1, 3].includes(Number(postData.workSystem))) {
        postData.calcOverTimeInMonth = false;
        postData.calcOverTimeInWeek = false;
        postData.calcOverTimeInDay = false;
        delete (postData as any).workingHoursId;
      }

      if (String(postData.workSystem) === "1") {
        postData.calcLegalOverTime = 0;
      }

      if (String(postData.workSystem) === "3") {
        postData.calcOverTimeInMonth = true;
        postData.calcOverTimeInWeek = false;
        postData.calcOverTimeInDay = false;
      }

      if (postData.calcOverTimeInMonth !== true) {
        delete (postData as any).workingHoursId;
      }
      if (postData.calcOverTimeInWeek !== true) {
        postData.letShiftPredeterminedWorkTimeOfWeek = 0;
        postData.prescribedWorkHourOfWeek = 0;
      }
      if (
        String(postData.letShiftPredeterminedWorkTimeOfWeek) !== "0" &&
        String(postData.letShiftPredeterminedWorkTimeOfWeek) !== "2"
      ) {
        postData.prescribedWorkHourOfWeek = 0;
      }

      if (String(postData.workSystem) !== "3") {
        if (String(postData.workSystem) === "0" && postData.calcLegalOverTime !== 1) {
          postData.carryOverMonthMethod = 0;
        }
        if (String(postData.workSystem) === "1" && postData.calcOverTimeInWeek !== true) {
          postData.carryOverMonthMethod = 0;
        }
        if (String(postData.carryOverMonthMethod) === "0") {
          postData.carryOverMonthRounding = 4;
        }
      }

      if (postData.calcOverTimeInDay !== true && String(postData.workSystem) !== "2") {
        postData.letShiftPredeterminedWorkTimeOfDay = 0;
        postData.prescribedWorkHourOfDay = 0;
      }
      if (
        String(postData.letShiftPredeterminedWorkTimeOfDay) !== "0" &&
        String(postData.letShiftPredeterminedWorkTimeOfDay) !== "2"
      ) {
        postData.prescribedWorkHourOfDay = 0;
      }
      if (String(postData.letShiftPredeterminedWorkTimeOfDay) === "0") {
        postData.shiftStartOvertimeFlag = 0;
        postData.shiftEndOvertimeFlag = 0;
      }

      if (String(postData.useExtraOverTime) !== "true") {
        postData.extraOverTime1 = 0;
        postData.extraOverTime2 = 0;
        postData.extraOverTime3 = 0;
      }

      delete (postData as any).createDate;
      delete (postData as any).updateDate;

      postData.businesses.forEach((item: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        delete (item as any).createDate;
        // eslint-disable-next-line no-param-reassign
        delete (item as any).updateDate;
      });
      postData.holidays.forEach((item: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        delete (item as any).createDate;
        // eslint-disable-next-line no-param-reassign
        delete (item as any).updateDate;
      });
      postData.noticeStaffs.forEach((item: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        delete (item as any).createDate;
        // eslint-disable-next-line no-param-reassign
        delete (item as any).updateDate;
      });

      postData.terms.forEach((item2: any, index: number) => {
        // eslint-disable-next-line no-param-reassign
        item2.id.applyStartDate = moment(item2.id.applyStartDate).format("YYYY/MM/DD");
      });

      if (!postData.startTime && !postData.endTime) {
        postData.startTime = "00:00";
        postData.endTime = "00:00";
      }

      if (postData.timeHolidayMaxDay < 0 || postData.timeHolidayMaxDay > 99) {
        errorNotification("時間単位休暇の最大取得日数は、０〜９９までの範囲で入力してください。");
        return;
      }
      if (postData.periodTime <= 0) {
        errorNotification("振替休日の対象日の前後は1日以上入力してください。");
        return;
      }

      if (stepper !== 8) {
        try {
          await validateEmploymentForm(values.getRawData(), stepper !== 7 ? stepper : stepper + 1);
          if ((!employmentId || isEdit === 1) && stepper < 8) {
            setStepper(stepper + 1);
            return false;
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errors) {
            const listErr = error.response.data.errors;
            listErr.map((element: any) => {
              errorNotification(element.defaultMessage);
            });
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
          return false;
        }
      }
      const sessionStepper = sessionStorage.getItem("flagStepper");
      if (sessionStepper) {
        setStepper(Number(sessionStepper));
        sessionStorage.setItem("flagStepper", "");
        return false;
      }

      if (!isSubmit) {
        return false;
      }

      if (type === "edit" && isEdit === 2 && String(postData.workSystem) === "3" && !postData.workingHoursId) {
        errorNotification("月の所定労働時間&法定労働時間を選択してください。");
        return;
      }

      if (!confirmModalOpen) {
        // 登録内容確認モーダルが表示されていなければ表示する
        setConfirmModalOpen(true);
        return;
      }

      setConfirmModalOpen(false);
      setBlocking(true);

      if (String(postData.distinctionHoliday) === "false") {
        postData.letPubBeNonLegalHoliday = false;
        postData.letSatBeNonLegalHoliday = false;
        postData.letSunBeNonLegalHoliday = false;
        postData.letMonBeNonLegalHoliday = false;
        postData.letTueBeNonLegalHoliday = false;
        postData.letWedBeNonLegalHoliday = false;
        postData.letTurBeNonLegalHoliday = false;
        postData.letFriBeNonLegalHoliday = false;
      }

      const response = await createOrUpdateEmployment(postData);
      if (!response.errors) {
        setBlocking(false);
        successNotification(isEdit ? "更新しました" : "登録しました");

        setTimeout(() => {
          history.goBack();
        }, 300);
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        console.log("test", error);
        errorNotification("サーバー側でエラーが発生しました。");
      }
    } finally {
      setBlocking(false);
    }
  };

  const formik = useFormik({
    initialValues: EmploymentDomain.generateInitial(isEdit),
    onSubmit,
  });

  useEffect(() => {
    if (formik.values.useRoundMonthlyStatistic && formik.values.roundUnitMonthlyStatistic === 0) {
      formik.setFieldValue("roundUnitMonthlyStatistic", 1);
    }
  }, [formik.values.useRoundMonthlyStatistic]);

  useEffect(() => {
    setBlocking(true);
    if (employmentId !== undefined && applyStartDate !== undefined) {
      getEmployment(employmentId, applyStartDate).then((response: Employment) => {
        if (response) {
          if (!response.specifiedWorkingHours) {
            response = { ...response, specifiedWorkingHours: 0 };
          }

          const employmentDomain = new EmploymentDomain(response);
          employmentDomain.noticeStaffs.forEach((item: any) => {
            item.staffCode = item.pk.staffCode;
          });
          formik.setValues(employmentDomain);
          setEditEmployment(employmentDomain);
          if (isEdit === undefined || isEdit < 2) {
            formik.setFieldValue("employmentId", "");
            formik.setFieldValue("applyDate", new Date());
            formik.setFieldValue("employmentCode", "");
            formik.setFieldValue("employmentName", "");
          }
          getList().then((termList: Array<Terms>) => {
            setTermMst(termList);
          });
        }
      });
    } else {
      if (isEdit === undefined || isEdit < 2) {
        formik.setFieldValue("employmentId", "");
        formik.setFieldValue("applyDate", new Date());
        formik.setFieldValue("employmentCode", "");
        formik.setFieldValue("employmentName", "");
      }
      getList().then((termList: Array<Terms>) => {
        setTermMst(termList);
      });
    }
    setBlocking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentId]);

  useEffect(() => {
    if (applicationDate === 0) {
      formik.setFieldValue("applyDate", applyDateChoose);
    } else {
      const objMonth = new Date();
      objMonth.setMonth(objMonth.getMonth() + 1);
      formik.setFieldValue("applyDate", objMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationDate, applyDateChoose]);

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);
  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    blocking,
    editEmployment,
    termMst,
    stepper,
    setStepper,
    setApplicationDate,
    applicationDate,
    setFlagValidate,
    flagValidate,
    setIsSubmit,
    applyDateChoose,
    setApplyDateChoose,
    detailRole,
  };
};

export default {
  useEmploymentAddForm,
};
