/** @jsx jsx */

import React from "react";
import { css, jsx } from "@emotion/core";

const thStyle = css({
  padding: "12px 0",
  color: "#666",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "left",
});

const tdStyle = css({
  padding: "12px 4px",
  color: "#666",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18px",
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

const AllowanceTable: React.FC<{
  allowanceData: any;
}> = ({ allowanceData }) => (
  <div
    css={css`
      table thead tr th {
        height: 46px;
      }
      table thead tr td div {
        text-align: center;
      }
      table tbody tr td {
        height: 32px;
      }
    `}
  >
    <table
      id="allowanceList"
      className="table table-bordered table-condensed"
      cellSpacing="0"
      style={{ whiteSpace: "nowrap", width: "100%" }}
    >
      <thead>
        <tr role="row">
          <td style={{ width: "50%" }}>
            <div css={thStyle}>手当名</div>
          </td>
          <td style={{ width: "50%" }}>
            <div css={thStyle}>申請値合計</div>
          </td>
        </tr>
      </thead>
      <tbody>
        {allowanceData.map((data: any) => (
          <tr role="row" key={data.id}>
            <td>
              <div css={thStyle}>{data.allowanceName}</div>
            </td>

            <td>
              <div css={tdStyle}>{`${data.allowanceValue}${data.dispUnit}`}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
export default AllowanceTable;
