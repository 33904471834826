/* eslint-disable max-len */
import { useState, useCallback, useEffect, useContext, Dispatch } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import useToastNotification from "hooks/useToastNotification";
import StaffDomain from "domain/staff";
import { postEmploymentInfo, getOrganizationDataList } from "api/staff";
import { LoadingContextAdd } from "components/pages/master/general/staff/StaffAddPage/hooks";
// import { LoadingContextAdd } from 'components/pages/master/general/staff/StaffAddPage/hooks'

type StaffDomainKey = keyof Pick<StaffDomain, "employmentId">;
const StaffDomainSchema: { [key in StaffDomainKey]: any } = {
  employmentId: Yup.string().required("雇用形態を入力してください。"),
};
const schema = Yup.object({ ...StaffDomainSchema });

export const useEmploymentForm = (
  initialStaffDomain: StaffDomain = StaffDomain.generateInitial(),
  isEditMode: boolean = false,
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>,
) => {
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [detailMode, setDetailMode] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  // const contextObject = useContext(isEditMode ? LoadingContext : LoadingContextAdd);
  // const contextObject = useContext(LoadingContextAdd);
  const contextObject = useContext(LoadingContextAdd);

  const validate = () => {
    if (formik.values.useEmploymentDeemedWorkingHours === false && formik.values.deemedWorkingHours === 0) {
      formik.setFieldError("deemedWorkingHours", "みなし時間を入力してください");
      return false;
    }

    if (formik.values.timeHolidayHourOfDay > 24) {
      errorNotification("時間単位休暇の１日の時間数は、０〜２４までの範囲で入力してください");

      return false;
    }
    if (formik.values.timeHolidayMaximumHour > 24) {
      errorNotification("1日で使用できる時間休暇の最大時間数は、０〜２４までの範囲で入力してください");
      return false;
    }

    return true;
  };

  const onSubmit = async (values: StaffDomain) => {
    if (!validate()) {
      setConfirmModalOpen(false);
      return false;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    try {
      setConfirmModalOpen(false);
      setIsLoading!(true);
      const postData = values.getStaffInfoPostData(isEditMode);
      const response = await postEmploymentInfo(isEditMode ? 1 : 0, postData);
      const updatedStaff = new StaffDomain(response.staff);
      organizationDataList(postData.id?.staffCode);
      if (!isEditMode) {
        sessionStorage.setItem("selectedOrgCodeStaffMaster", updatedStaff.orgCode);
      }
      setIsLoading!(false);
      successNotification(isEditMode ? "更新しました。" : "登録しました。");

      if (!isEditMode && response) {
        history.goBack();
      }
    } catch (error) {
      setIsLoading!(false);
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.map((item: { defaultMessage: string }) => errorNotification(item.defaultMessage));
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    }
  };

  const organizationDataList = async (staffCode: string) => {
    await getOrganizationDataList(staffCode)
      .then((res) => {
        formik.setFieldValue("employmentDataList", res.employmentDataList);
        formik.setFieldValue("weekShiftPatternList", res.weekShiftPatternList);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          err.response.data.errors.map((item: { defaultMessage: string }) => errorNotification(item.defaultMessage));
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  };

  const formik = useFormik({
    initialValues: initialStaffDomain,
    onSubmit,
    validateOnChange: false,
    validate: async (values) => {
      const errors: { [key: string]: string } = {};
      try {
        await schema.validate(values, { abortEarly: false });
      } catch (error) {
        const validationError = error as Yup.ValidationError;
        validationError.inner.forEach((innerError) => {
          errors[innerError.path] = innerError.message;
        });
      }
      return errors;
    },
  });

  useEffect(() => {
    formik.setValues(initialStaffDomain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStaffDomain]);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    contextObject,
    validate,
  };
};

export default {
  useEmploymentForm,
};
