import Actions, { types } from './actions';
import { LoginUserState } from '.';

const initialState: LoginUserState = {
  loginUser: {}
};

const reducer = (state = initialState, action: Actions): LoginUserState => {
  const newState = { ...state };
  switch (action.type) {
    case types.SET_LOGIN_USER:
      newState.loginUser = action.payload;
      return newState;
    default:
      return state;
  }
};

export default reducer;
