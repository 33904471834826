/** @jsx jsx */
import React, { ComponentProps } from "react";
import { css, jsx } from "@emotion/core";
import { grayScale } from 'components/styles';
import FormLabel from "components/atoms/Form/FormLabel";
import FlexBox from "components/atoms/FlexBox";
import FormDescription from "components/atoms/Form/FormDescription";
import Select from "components/atoms/Select";

const styles = {
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  labelLong: css({
    flexBasis: "180px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "70px",
    // zIndex: 1,
  }),
};

// Convert select to text
const ghostStyle = css`
  div {
    &:hover,&:focus {
      border: 0;
    }
    svg{
      display: none;
    }
    background-color: transparent;
    color: ${grayScale.gray100};
    border: 0;
    margin: 0;
    padding: 0;
  }
`
/**
 * 選択式フォーム
 */
const SelectFormV2: React.FC<
  {
    label: string;
    description?: string;
    readOnly?: boolean;
    islabelLong?: boolean;
    placeholder?: string;
    width?: string;
    customStyle?: any;
    widthLabel?: string;
    isHiddenLabel?: boolean;
    disable? : boolean;
    ghost?: boolean
  } & ComponentProps<typeof Select>
> = ({
  label,
  name,
  value,
  options,
  setValue,
  description,
  isMulti,
  readOnly = false,
  islabelLong = false,
  placeholder,
  width = "",
  widthLabel = "",
  customStyle,
  disable,
  isHiddenLabel = false,
  ghost= false
}) => {

    return (
      <div css={css(styles.container, customStyle)}>
        <FlexBox>
          {isHiddenLabel === false ? (
            <div
              css={[
                islabelLong ? styles.labelLong : styles.label,
                css({ width: widthLabel, flexBasis: widthLabel }),
              ]}
            >
              <FormLabel customStyle={css({ marginBottom: '0px' })} label={label} />
            </div>
          ) : (
              ""
            )}
          <div css={css(styles.select, ghost ? ghostStyle: {})}>
            <Select
              name={name}
              value={value}
              setValue={setValue}
              options={options}
              isMulti={isMulti}
              readOnly={readOnly}
              placeholder={placeholder}
              width={width}
              isDisabled={disable||ghost}
            />
          </div>
        </FlexBox>
        <FlexBox>
          <div css={styles.label} />
          <div css={styles.select}>
            {description && <FormDescription description={description} />}
          </div>
        </FlexBox>
      </div>
    );
  };
export default SelectFormV2;