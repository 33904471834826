import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import MonthlyAgreement36Form from 'components/organismos/AttendAgreement36/MonthlyAgreement36Form';

const MonthlyAgreement36Page: React.FC = () => (
  <SidebarTemplate
    pageTitle=""
  >
    <MonthlyAgreement36Form />
  </SidebarTemplate>
);

export default MonthlyAgreement36Page;
