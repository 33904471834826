/** @jsx jsx */
import React from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';
import productColor, { grayScale } from 'components/styles';
import { textFontSize, textColor } from 'components/styles';

type FontSize = typeof textFontSize;

const hoverStyle = (color: string) => css({
  ':hover': {
    color: color + ' !important',
    cursor: 'pointer'
  },
  ':focus': {
    color: color+ ' !important',
  }
});

const BodyText: React.FC<{
  size?: keyof FontSize
  color?: string
  bold?: boolean
  customStyle?: SerializedStyles,
  isHover?: boolean,
  onClick?: ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void),
  disable?: boolean,
}> = ({
  children, size = 're', color = textColor.main, bold, customStyle, isHover = false, onClick, disable = false
}) => (
  <span
    css={css({
      fontSize: textFontSize[size],
      color: disable ? grayScale.gray50: color,
      fontFamily: 'inherit',
      fontWeight: bold ? 'bold' : 'normal',
      cursor: disable ? 'not-allowed': '',
    }, customStyle, isHover ? hoverStyle(productColor.primaryHoverText) : {})}
    onClick={onClick}
  >
    {children}
  </span>
);

export default BodyText;
