/** @jsx jsx */

import { useHistory } from "react-router-dom";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import { Container, Row, Col } from "react-grid-system";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import moment from "moment";
import BodyText from "components/atoms/BodyText";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { PrimaryButton, DeleteButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import ShiftPatternDetailsStamp from "components/organismos/master/labor/shiftPattern/ShiftPatternDetails/ShiftPatternDetailsStamp";
import TextForm from "components/molecules/TextForm";
import BlockUI from "components/molecules/BlockUi";
import productColor, { grayScale, textColor, textFontSize } from "components/styles";
import { css, jsx } from "@emotion/core";
import TabControl from "components/atoms/TabControl";
import FormPadding from "components/atoms/Form/FormPadding";
import { IchangeLog } from "../../../../organismos/master/attend/AttentStampForm/interface";
import { useStampModificationAddForm } from "./hooks";
import ToastModal from "../../../../organismos/ToastModal";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable";
import SelectForm from "components/molecules/SelectForm";
import AttendStampModal from "components/molecules/ChangeLogModal/Template/AttendStampModal";
import useIsMobile from "hooks/useIsMobile";
import FormTitle from "components/atoms/Form/FormTitle";
import React from "react";
import ShiftPatternDetails from "components/organismos/master/labor/shiftPattern/ShiftPatternDetails";

const styles = {
  formContent: {
    paddingBottom: "20px",
  },
  approvalWrap: css({
    padding: "0px",
    marginBottom: "15px",
    marginTop: "0px",
  }),
  rowPadding: css({
    paddingBottom: "16px",
  }),
};

enum ActionType {
  Add = 0,
  Modify = 1,
  Delete = 2,
}

const StampModificationPage: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const {
    formik,
    orgTreesOptions,
    staffName,
    attendBusinessOptions,
    targetDate,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmModalOpen,
    closeConfirmModal,
    stampList,
    setStampList,
    listTimes,
    isLoading,
    stampHistoryListAchievement,
    selectChangelog,
    setSelectChangelog,
    remote,
    isRemote,
    setIsRemote,
    reasonList,
    reasonOptionList,
    orgCode,
    setOrgCode,
  } = useStampModificationAddForm();

  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  return (
    <SidebarTemplate pageTitle="打刻追加申請">
      <BlockUI blocking={isLoading}>
        <FormContents
          customStyle={css({
            paddingBottom: isMobile ? "8px" : "",
          })}
        >
          <FormTitle
            title={formik.values.deleteApplicationAvailable ? "打刻修正申請" : "打刻追加申請"}
            bold={true}
            customStyle={css(isMobile ? { padding: "8px 16px 16px 16px", margin: 0 } : {})}
          />
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormPadding customStyle={css(isMobile ? { padding: "0 16px 16px 16px" } : { paddingLeft: "35px" })}>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>日時</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText color={textColor.mobileTitle}>
                      {moment(`${targetDate}`).format("YYYY年MM月DD日(ddd)")}
                    </BodyText>
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText color={textColor.mobileTitle}>{staffName}</BodyText>
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                    <BodyText>出勤組織</BodyText>
                  </Col>
                  <Col md={10}>
                    <Row>
                      <Col md={4}>
                        <SelectForm
                          label=""
                          name="orgCode"
                          value={String(orgCode)}
                          setValue={(val: string) => setOrgCode(val)}
                          options={orgTreesOptions}
                          width={isMobile ? "100%" : "800px"}
                          disable={formik.values.modifierApplicationId || formik.values.achievementId ? true : false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {remote && (
                  <Row css={styles.rowPadding}>
                    <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                      <BodyText>出勤場所</BodyText>
                    </Col>
                    <Col md={10}>
                      <Row>
                        <Col md={4}>
                          <RadioSelectForm
                            items={[
                              {
                                label: "オフィス",
                                value: "false",
                              },
                              {
                                label: `${companyCode === "HPo6wkyuze" ? "在宅勤務（自宅待機含む）" : "テレワーク"}`,
                                value: "true",
                              },
                            ]}
                            name="isRemote"
                            value={String(isRemote)}
                            setValue={(e: any) => {
                              setIsRemote(e.target.value === "true");
                            }}
                            disabled={formik.values.applicationStatus === 0}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {formik.values.displayAutoBreak && formik.values.useAutoRecess === 1 && (
                  <Row css={styles.rowPadding}>
                    <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                      <BodyText>自動休憩</BodyText>
                    </Col>
                    <Col md={10}>
                      <Row>
                        <Col md={4} xs={12}>
                          <VerticalLabelSelectForm
                            label=""
                            name="autoRecess"
                            value={String(formik.values.autoRecess || 0)}
                            setValue={(val: string) => {
                              formik.setFieldValue("autoRecess", val);
                            }}
                            options={[
                              {
                                label: "有効",
                                value: "0",
                              },
                              {
                                label: "無効",
                                value: "1",
                              },
                            ]}
                            disable={formik.values.applicationStatus === 0}
                            width={isMobile ? "100%" : "800px"}
                            isSearchable={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {formik.values.displayAutoBreak &&
                  formik.values.workSystem === 1 &&
                  formik.values.useAutoRecess === 2 && (
                    <Row css={styles.rowPadding}>
                      <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                        <BodyText>シフト休憩</BodyText>
                      </Col>
                      <Col md={10}>
                        <Row>
                          <Col md={4} xs={12}>
                            <VerticalLabelSelectForm
                              label=""
                              name="shiftRecess"
                              value={String(formik.values.shiftRecess || 0)}
                              setValue={(val: string) => {
                                formik.setFieldValue("shiftRecess", val);
                              }}
                              disable={formik.values.applicationStatus === 0}
                              options={[
                                {
                                  label: "有効",
                                  value: "0",
                                },
                                {
                                  label: "無効",
                                  value: "1",
                                },
                              ]}
                              width={isMobile ? "100%" : "800px"}
                              isSearchable={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                <Row css={styles.rowPadding}>
                  <Col style={{ display: "flex", alignItems: "center" }} md={2}>
                    <BodyText>申請事由</BodyText>
                  </Col>
                  <Col md={10}>
                    <Row>
                      <Col md={4} xs={12}>
                        <VerticalLabelSelectForm
                          label=""
                          name="reasonId"
                          value={String(formik.values.reasonId || "")}
                          setValue={(val: string) => {
                            const applicationReason = reasonList.find((obj) => obj.reasonId === val)!;
                            if (applicationReason !== undefined && applicationReason.syncFlag === 1) {
                              formik.setFieldValue("applicationReason", applicationReason.applicationReason);
                            } else if (applicationReason !== undefined && applicationReason.syncFlag === 0) {
                              formik.setFieldValue("applicationReason", "");
                            }
                            formik.setFieldValue("reasonId", val);
                          }}
                          options={reasonOptionList}
                          width={isMobile ? "100%" : "800px"}
                          disable={formik.values.applicationStatus === 0}
                          isSearchable={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <ShiftPatternDetailsStamp
                      shiftPatternDetailsList={stampList}
                      setShiftPatternDetailsList={setStampList}
                      attendBusinessOptions={attendBusinessOptions}
                      listTimes={listTimes}
                      remote={remote}
                      isDisableAll={formik.values.applicationStatus === 0}
                    />
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col
                    md={2}
                    xs={12}
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <BodyText
                      customStyle={css(
                        isMobile
                          ? {
                              marginBottom: "4px",
                            }
                          : {},
                      )}
                    >
                      申請理由
                    </BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <TextForm
                      name="applicationReason"
                      label=""
                      value={String(formik.values.applicationReason)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.applicationReason}
                      disable={formik.values.applicationStatus === 0}
                    />
                  </Col>
                </Row>
                {isMobile ? (
                  <div css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr);", gap: 8 }}>
                    {!(formik.values.applicationStatus === 0) && (
                      <PrimaryButton
                        ghost={false}
                        text="申請する"
                        disabled={formik.values.applicationStatus === 0}
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("buttonType", ActionType.Add);
                        }}
                      />
                    )}
                    {formik.values.applicationStatus === 0 && (
                      <DeleteButton
                        ghost={false}
                        text="申請キャンセル"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("buttonType", ActionType.Modify);
                        }}
                      />
                    )}

                    {formik.values.deleteApplicationAvailable === true && (
                      <DeleteButton
                        ghost={false}
                        text="削除申請"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("buttonType", ActionType.Delete);
                        }}
                      />
                    )}

                    <Button
                      customStyle={css({
                        border: `1px solid ${productColor.primary}`,
                      })}
                      onClick={() => history.goBack()}
                      text="キャンセル"
                      ghost={true}
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <Row>
                      <Col
                        md={6}
                        offset={{ md: 3 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormSubmitArea>
                          {!(formik.values.modifiyStatus === 1 && formik.values.applicationStatus === 0) && (
                            <span style={{ marginRight: "10px" }}>
                              <PrimaryButton
                                ghost={false}
                                text="申請する"
                                disabled={formik.values.applicationStatus === 0}
                                onClick={() => {
                                  formik.handleSubmit();
                                  formik.setFieldValue("buttonType", ActionType.Add);
                                }}
                              />
                            </span>
                          )}

                          {formik.values.applicationStatus === 0 && (
                            <DeleteButton
                              ghost={false}
                              text="申請キャンセル"
                              onClick={() => {
                                formik.handleSubmit();
                                formik.setFieldValue("buttonType", ActionType.Modify);
                              }}
                            />
                          )}

                          {formik.values.deleteApplicationAvailable === true && (
                            <DeleteButton
                              ghost={false}
                              text="削除申請"
                              onClick={() => {
                                formik.handleSubmit();
                                formik.setFieldValue("buttonType", ActionType.Delete);
                              }}
                            />
                          )}
                        </FormSubmitArea>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={6}
                        offset={{ md: 3 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ marginRight: "12px" }}>
                          <PrimaryButton onClick={() => history.goBack()} text="キャンセル" ghost={true} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </FormPadding>
            </Container>
          </form>
        </FormContents>

        {!isMobile && (
          <div style={{ paddingTop: 20 }}>
            <FormContents customStyle={styles.formContent}>
              <Row style={{ padding: "10px 30px" }}>
                <Col
                  md={2}
                  xs={12}
                  style={{
                    fontWeight: "bold",
                    fontSize: textFontSize.md,
                    lineHeight: "24px",
                    paddingBottom: "20px",
                    color: textColor.main,
                  }}
                >
                  更新履歴
                </Col>
              </Row>

              <TabControl
                isTabModal={true}
                isFixed={false}
                items={stampHistoryListAchievement.map((log: IchangeLog, index: number) => ({
                  value: String(index),
                  display: `ver${log.version}`,
                }))}
                setValue={setSelectChangelog as (arg: string) => void}
                currentValue={selectChangelog}
                styleNotActive={css({
                  borderRadius: 0,
                  border: "none",
                  width: "100px",
                  flex: "unset",
                })}
                styleActive={css({
                  borderRadius: 0,
                  border: "none",
                  borderBottom: "1px solid #007BC3",
                  width: "100px",
                  flex: "unset",
                })}
              />
              {stampHistoryListAchievement.length > 0 &&
                stampHistoryListAchievement.map((itemLog: IchangeLog, index: number) => {
                  return (
                    <div
                      style={{
                        display: selectChangelog !== String(index) ? "none" : "block",
                      }}
                    >
                      <React.Fragment>
                        <FormPadding
                          customStyle={{
                            fontSize: "14px",
                            color: textColor.mobileTitle,
                          }}
                        >
                          <Row>
                            <Col
                              md={2}
                              xs={12}
                              style={{
                                fontSize: textFontSize.re,
                                lineHeight: "24px",
                                paddingBottom: "20px",
                                color: `${grayScale.gray50}`,
                              }}
                            >
                              申請種別:
                            </Col>
                            <Col md={10} xs={12}>
                              {itemLog.historyTypeName && itemLog.historyTypeName}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              md={2}
                              xs={12}
                              style={{
                                fontSize: textFontSize.re,
                                lineHeight: "24px",
                                paddingBottom: "20px",
                                color: `${grayScale.gray50}`,
                              }}
                            >
                              申請者:
                            </Col>
                            <Col md={10} xs={12}>
                              {itemLog.applicationStaffCode && `${itemLog.applicationStaffCode} `}
                              {itemLog.applicationStaffName && itemLog.applicationStaffName}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              md={2}
                              xs={12}
                              style={{
                                fontSize: textFontSize.re,
                                lineHeight: "24px",
                                paddingBottom: "20px",
                                color: `${grayScale.gray50}`,
                              }}
                            >
                              申請日時:
                            </Col>
                            <Col md={10} xs={12}>
                              {itemLog.applicationDate &&
                                moment(itemLog.applicationDate).format("YYYY/MM/DD HH:mm:ss")}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              md={2}
                              xs={12}
                              style={{
                                fontSize: textFontSize.re,
                                lineHeight: "24px",
                                paddingBottom: "10px",
                                color: `${grayScale.gray50}`,
                              }}
                            >
                              出勤組織:
                            </Col>
                            <Col md={10} xs={12}>
                              {itemLog.orgName && itemLog.orgName}
                            </Col>
                          </Row>

                          <Row>
                            <AttendStampModal
                              changeLogData={[itemLog]}
                              selectChangelog={String(index)}
                              isStamp={true}
                              customStyle={styles.approvalWrap}
                            />
                          </Row>

                          <Row>
                            <Col
                              md={2}
                              xs={12}
                              style={{
                                fontSize: textFontSize.re,
                                lineHeight: "24px",
                                paddingBottom: "5px",
                                color: `${grayScale.gray50}`,
                              }}
                            >
                              修正理由:
                              {itemLog.approvalHistoryList.length > 0 ? (
                                <div
                                  style={{
                                    fontSize: `${textFontSize.re}`,
                                    color: "#666666",
                                    paddingTop: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  承認状況
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col md={10} xs={12}>
                              {itemLog.applicationReason && itemLog.applicationReason}
                            </Col>
                          </Row>
                          {itemLog.approvalHistoryList.length > 0 && (
                            <Row>
                              <Col xs={12}>
                                <HistoryApprovalTable
                                  historyData={itemLog.approvalHistoryList}
                                  applicationStatus={itemLog.applicationStatus}
                                />
                              </Col>
                            </Row>
                          )}
                        </FormPadding>
                      </React.Fragment>
                    </div>
                  );
                })}
            </FormContents>
          </div>
        )}

        <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

        <ConfirmModal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          onSubmit={() => {
            formik.handleSubmit();
          }}
          title={
            formik.values.buttonType === 0
              ? "打刻修正を申請"
              : formik.values.buttonType === 1
              ? "申請キャンセルを申請"
              : "削除申請を申請"
          }
          content={
            formik.values.buttonType === 0
              ? "打刻修正を申請します。よろしいですか？"
              : formik.values.buttonType === 1
              ? "申請キャンセルを申請します。よろしいですか？"
              : "削除申請を申請します。よろしいですか？"
          }
          submitText="申請する"
          typeSubmit={formik.values.buttonType === 0 ? "confirm" : "reject"}
        />
      </BlockUI>
    </SidebarTemplate>
  );
};

export default StampModificationPage;
