import ApiClient from './ApiClient';

export const getCurrentStockPaid = async (params: any): Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v1/attendStockPaidHoliday/currentHolidayApplid/${companyCode}`, {}, params);
    return response.data;
};

export const getHistoryStockPaid = async (params: any): Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v1/attendStockPaidHoliday/stockPaidHolidayHistoryListByAppId/${companyCode}`, {}, params);
    return response.data;
};

export const createStockPaid = async (data: any): Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const currentStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
    const currentOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
    const updateUser = sessionStorage.getItem('loginUser.staffCode') || '';

    data = {
        ...data,
        currentStaffCode,
        currentOrgCode,
        updateUser,
    }
    const response = await ApiClient.post(`/v1/attendStockPaidHoliday/save/${companyCode}`, {}, data);
    return response.data;
};

export const getStockHolidays = async (params: any): Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v1/holidays/attends/stockpaidholiday/byemployment/${companyCode}`, {}, params);
    return response.data;
};

export const getEmployment = async (params: any): Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await ApiClient.get(`/v1/employments/applied/${companyCode}`, {}, params);
    return response.data;
  };
  