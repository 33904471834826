/* eslint-disable max-len */
/** @jsx jsx */
import React, {useState, useEffect, useMemo, useCallback} from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { Column } from 'react-table';
import DataTable from 'components/organismos/DataTable/DataTable';
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import BodyText from 'components/atoms/BodyText';
import FormField from 'components/atoms/Form/FormField';
import FormPadding from 'components/atoms/Form/FormPadding';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { PrimaryButton } from "components/atoms/Button";
import SelectForm from 'components/molecules/SelectForm';
import { SubActionButton } from "components/atoms/Button";
import { useHistory } from "react-router-dom";
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import IconLabelButton from 'components/molecules/IconLabelButton';
import { isSafari, isIPad13, isTablet } from 'react-device-detect';
import { ICCardConnectButton } from "components/atoms/Button";
import { useMasterICCardSettingForm } from "./hooks";
import { StaffICCardDomain } from "domain/master/icCard/staffICCard";
import { downloadCSV } from "api/iCCard";
import ICCardList from 'components/molecules/ICCardList';
import Modal from "components/molecules/Modal";
import Checkbox from "components/molecules/Checkbox";
import { useDispatch } from 'react-redux';
import { icCardActions } from 'modules/ui/icCard';
import TextForm from 'components/molecules/TextForm';
import FormLabel from 'components/atoms/Form/FormLabel';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { postRegisterStaffICCard, postDeleteStaffICCard } from 'api/iCCard';
import useToastNotification from "hooks/useToastNotification";
import * as icCard from 'hooks/useICCard';
import useAudio from 'hooks/useAudio';
import { functionCode } from 'const';

const styles = {
    containerFormAdd: css({
        backgroundColor: '#F8F8F8',
        border: '1px solid #999999',
        padding: '10px',
        width: '570px',
        height: 'auto'
    }),
    labelFormAdd: css({
        fontSize: '16px',
        fontWeight: 'bold'
    }),
    icLabel: css({ 
        width: '88px', 
        fontSize: '12px',
        padding: '0',
        lineHeight: '22px',
        borderRadius: '3px',
        '&:disabled': {
            background: '#AFDCEE'
        }
    }),
    customStyle: css({
        display: 'flex',
        justifyContent: ((isSafari && isTablet) || isIPad13) ? 'flex-start' : 'flex-end',
        paddingRight: ((isSafari && isTablet) || isIPad13) ? '10px' : '30px',
    },
    css`
        button{
            width: auto;
            margin-left: 15px
        }
        button:hover{
            background: transparent
        }
        button svg{
            width:20px !important;
            height:20px !important;
        }
    `),
    inputListCard: css({
        border: '0'
    })
}

const AUTO_PAUSE_DETECT_CARD = false;

const { errorNotification, successNotification } = useToastNotification();

const MasterICCardSettingForm = () => {
    // redux
    const dispatch = useDispatch();
    
    const history = useHistory();
    const [staffCodeSelected, setStaffCodeSelected] = useState('');
    const [openModalAddCode, setOpenModalAddCode] = useState(false);
    const [idCode, setIdCode] = useState('');
    const isConnectICCardUsb = useSelector((state:Store) => state.ui.icCard.isConnectICCardUsb);
    const deviceICCard = useSelector((state:Store) => state.ui.icCard.deviceICCard);
    const cardId = useSelector((state:Store) => state.ui.icCard.cardId);
    const [actionDetect, setActionDetect] = useState(false);
    const playAudioSuccess = useAudio('/audio/soundDetectSuccess.mp3').playAudio;
    const [cardDelete, setCardDelete] = useState('');
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const {
        orgTreesOptions,
        orgCode,
        setOrgCode,
        listStaffICCard,
        isLoading,
        setIsLoading,
        registerStaffBulkICCard,
        fetchStaffICCard,
        filterListHaveNotCard,
        setFilterListHaveNotCard,
        listStaffICCardFilter,
        setListStaffICCard,
        setListStaffICCardFilter,
        detailRole,
        underFlag,
        setUnderFlag
    } = useMasterICCardSettingForm()

    const columns: Array<Column<StaffICCardDomain>> = useMemo(() => [
        {
            Header: 'スタッフ ID',
            accessor: 'staffCode',
        },
        {
            Header: 'スタッフ名',
            accessor: 'staffName',
        },
        {
            Header: '登録IDカード',
            id: 'cardNumber',
            accessor: 'cardNumber',
            Cell: (cell:{ row:{isExpanded: boolean, original: any}, page:[]}) => {
                let cardList = cell.row.original.cardNumber;
                return(
                    <ICCardList
                        listICCard={cardList === '' ? [] : cardList.split(',')}
                        staffCode={cell.row.original.staffCode}
                        customStyle={styles.inputListCard}
                        staffCodeSelected={staffCodeSelected}
                        setStaffCodeSelected={setStaffCodeSelected}
                        openModalAddCode={openModalAddCode}
                        setOpenModalAddCode={setOpenModalAddCode}
                        setCardDelete={setCardDelete}
                        setIsOpenDeleteModal={setIsOpenDeleteModal}
                        editable={detailRole.editable}
                    />
                )
            },
        },
    ], [orgTreesOptions, orgCode, staffCodeSelected, setStaffCodeSelected, openModalAddCode, setOpenModalAddCode, idCode, setIdCode, detailRole]);
    
    
    useEffect(() => {
        if(deviceICCard && actionDetect && dispatch){
            icCard.detectCard(deviceICCard, dispatch, AUTO_PAUSE_DETECT_CARD);
        }
    }, [deviceICCard, actionDetect, dispatch])
    
    useEffect(() => {
        return () =>{
            dispatch(icCardActions.setDisabledDetect(false));
            dispatch(icCardActions.setCardId(''));
        }
    }, [])

    useEffect(() => {
        if(openModalAddCode){
            dispatch(icCardActions.setDisabledDetect(false));
        }
        setIdCode('');
    }, [openModalAddCode])
  
    useEffect(() => {
        if(cardId !== ""  && openModalAddCode){
          playAudioSuccess();
          setIdCode(cardId);
        }
      }, [cardId, openModalAddCode])

    const downloadCsvICCard = useCallback(() => {
        downloadCSV(orgCode || '', functionCode.masterICCard, underFlag);
    }, [orgCode, underFlag]);

    const registerStaffICCard = useCallback(async (idCode: string) => {
        if(idCode === ""){
          errorNotification('ICカード番号をご入力ください。');
          return;
        }
        setIsLoading!(true);
        postRegisterStaffICCard(
          idCode,
          staffCodeSelected || "",
        ).then((response: any) => {
          dispatch(icCardActions.setHardCardId(''));
          setOpenModalAddCode(false);
          setIsLoading!(false);
          setIdCode('');
          successNotification('登録しました。');
          const listCard = [...listStaffICCard]
          listCard.map((list) => {
            if(list.staffCode === staffCodeSelected){
            list.cardNumber += list.cardNumber === '' ? idCode : (',' + idCode);
            }
        })
          setListStaffICCard(listCard);
          setListStaffICCardFilter(listCard.filter((staff: any) => staff.cardNumber === '' ));
          //fetchStaffICCard();
        })
        .catch((error: any) => {
          setIsLoading!(false);
          setIdCode('');
          dispatch(icCardActions.setHardCardId(''));
          if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
            const listErr = error.response.data.errors;
            listErr.map((element: any) => errorNotification(element.defaultMessage));
          } else {
            errorNotification('サーバー側でエラーが発生しました。');
          }      
        });
      }, [staffCodeSelected]);

    const deleteStaffICCard = useCallback(async () => {
        setIsLoading!(true);
        postDeleteStaffICCard(
            cardDelete,
            staffCodeSelected || "",
        ).then((response: any) => {
            setIsLoading!(false);
            successNotification('削除しました。');
            const listCard = [...listStaffICCard]
            listCard.map((list) => {
                if(list.staffCode === staffCodeSelected){
                    const arrNotDelete = list.cardNumber.split(',').filter((value) => value !== cardDelete);
                    list.cardNumber = arrNotDelete.join()
                }
            })
            setListStaffICCard(listCard);
            setListStaffICCardFilter(listCard.filter((staff: any) => staff.cardNumber === '' ));
            //fetchStaffICCard();
        })
        .catch((error: any) => {
            setIsLoading!(false);
            if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
            const listErr = error.response.data.errors;
            listErr.map((element: any) => errorNotification(element.defaultMessage));
            } else {
            errorNotification('サーバー側でエラーが発生しました。');
            }      
        });
    }, [staffCodeSelected, cardDelete]);
    
    const handleRegisterStaffICCard = async () => {
        setIsLoading!(true);
        let staff = listStaffICCard.filter((staffICCard) => staffICCard.staffCode === staffCodeSelected)[0];
        let listCard = staff.cardNumber === '' ? [] : staff.cardNumber.split(',')
        if (listCard.filter((cardNumber) => cardNumber === idCode).length > 0){
          setIsLoading!(false);
          errorNotification('スタッフはすでにこのカードを持っています。');
          return;
        };
        registerStaffICCard(idCode);
    }

    const detectCard = () => {
        try {
          if(!deviceICCard){
            icCard.connectDevice(dispatch);
          }
          setActionDetect(true);
          // icCard.detectCard(deviceICCard, dispatch);  
          // icCard.detectCard(setCodeICCard, setIsConnectUsb, dispatch);
        } catch (error) {
          errorNotification(error);
        }
    }


    return (
    <BlockUI blocking={isLoading}>
        <div>
        <FormContents customStyle={{ marginBottom: '50px' }}>
            <FormTitle title="ICカード登録" bold={true} customStyle={css`margin: 16px 35px;`} />
            <FormPadding customStyle={{ padding: '0 55px' }}>
                <FlexBox justifyContent='space-between'>
                    <FlexBoxItem>
                        <BodyText size='sm' color='#007BC3'>打刻に利用するカードと従業員の紐付きを設定できます。一人の従業員に複数のICカードを紐付けることも可能です。</BodyText>
                    </FlexBoxItem>
                </FlexBox>
            </FormPadding>
            <FlexBox>
                <FormField displayBlock={true} customStyle={css({margin: '50px 35px 40px 35px'})}>
                    <FlexBoxItem>
                        <FlexBox>
                            <SelectForm
                                label="対象組織"
                                name="orgCode"
                                value={String(orgCode)}
                                setValue={(v) => {
                                    setOrgCode(v);
                                }}
                                options={orgTreesOptions}
                                description=""
                                width="900px"
                                widthLabel="100px"         
                                marginRight="30px"                   
                            />
                            <Checkbox
                                id={'underFlag'}
                                name={'underFlag'}
                                label={"配下指定"}
                                value={`underFlag`}
                                checked={Boolean(underFlag)}
                                onChange={() => {
                                    setUnderFlag(underFlag === 1 ? 0 : 1)
                                }}
                            />
                        </FlexBox>
                    </FlexBoxItem>
                    <FlexBoxItem customStyle={css({margin: '15px 0 0 100px'})}>
                        <Checkbox
                            id="1"
                            name="filterListHaveNotCard"
                            label="ICカード未登録の人を表示する"
                            value={'1'}
                            checked={filterListHaveNotCard === '1'}
                            onChange={() => {
                                sessionStorage.setItem("masterICCard.filterListHaveNotCard", filterListHaveNotCard === "1" ? "0" : "1");
                                setFilterListHaveNotCard(filterListHaveNotCard === "1" ? "0" : "1");
                            }}
                        />
                    </FlexBoxItem>
                </FormField>
            </FlexBox>
            <div
                css={css`
                    table td {
                        padding: 4px 10px;
                    }
                    table th:nth-of-type(1), tbody tr td:nth-of-type(1) {
                        width: 150px;
                    }
                    table th:nth-of-type(2) {
                        width: 250px;
                    }
                `}
            >
                <FormSubmitArea customStyle={styles.customStyle}>
                    {detailRole.editable === 1 && (
                        <React.Fragment>
                            {detailRole.importFlag === 1 && (
                                 <IconLabelButton onClick={() => {
                                    history.push(`/masterICCard/import`);
                                }} iconType="addCircle" text="インポート" />
                            )}
                           
                        </React.Fragment>
                    )}
                    {detailRole.downloadFlag === 1 && (
                        <IconLabelButton onClick={() => downloadCsvICCard()} iconType="download" text="ダウンロード" />
                    )}
                </FormSubmitArea>
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    <DataTable
                        className="table-no-border-left-right"
                        columns={columns}
                        data={filterListHaveNotCard === '1' ? listStaffICCardFilter : listStaffICCard}
                        isGlobalFilter={true}
                        minWidth="824px"
                        globalSearchStyle={css({ marginLeft: '35px' })}
                        useSession={true}
                    />
                </div>
            </div>
        </FormContents>
        <Modal
            open={openModalAddCode}
            disabledSubmit={!/\S/.test(idCode)} 
            closeHandler={() => {
                dispatch(icCardActions.setDisabledDetect(true));
                dispatch(icCardActions.setHardCardId(''));
                setOpenModalAddCode(false);
                setIdCode('');
            }}
            title="ICカードIDで登録"
            submitText="保存"
            onSubmit={() => handleRegisterStaffICCard()}
            typeSubmit="confirm"
            customStyleFooter={css`
            button{
                min-width: 150px;
            }
            `}
            >
            <BodyText bold={true}>スタッフ</BodyText>
            <BodyText>： {staffCodeSelected} {listStaffICCard.filter((staff) => staff.staffCode === staffCodeSelected)[0]?.staffName} </BodyText>
            <div css={styles.containerFormAdd}>
                <FlexBox justifyContent="space-between">
                    <FormLabel label="カード番号(IDm)" customStyle={styles.labelFormAdd}/>
                    <ICCardConnectButton
                        disabled={isConnectICCardUsb}
                        onClick={() => {
                            detectCard();
                        }}
                    />
                </FlexBox>
                <BodyText color='#F21B1B' size="sm">カード番号を入力するかカードリーダでカードを読み取って登録してください</BodyText>
                <FlexBox customStyle={css({marginTop: '5px'})}>
                    <div style={{width: 'auto'}}>
                        <TextForm
                        required={false}
                        name="idCode"
                        label=""
                        value={idCode}
                        onKeyDown={(e) => {
                            if (e.key === " ") {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            if(e.currentTarget.value.includes(" ")) {
                                e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
                            }
                            setIdCode(e.target.value);
                            }
                        }
                        placeHolder="カード番号(IDm)"
                        customStyle={css({
                            width: '300px',
                            height: '40px',
                        })}
                        />
                    </div>
                </FlexBox>
            </div>
        </Modal>
        <Modal
            open={isOpenDeleteModal}
            closeHandler={() => setIsOpenDeleteModal(false)}
            title="確認メッセージ"
            note="ICカード番号を削除します。よろしいですか？"
            submitText="削除"
            onSubmit={() => {
                deleteStaffICCard();
                setIsOpenDeleteModal(false);
            }}
            typeSubmit="reject"
        >
        </Modal>
        </div>
    </BlockUI>
    );
};

export default MasterICCardSettingForm;
