import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import OrganizationListTable from 'components/organismos/organization/OrganizationListTable/OrganizationListTable';

import BlockUI from 'components/molecules/BlockUi';
import { useOrganizationList } from './hooks';
import FormContents from 'components/atoms/Form/FormContents';

const OrganizationsPage: React.FC = () => {
  const {
    organizationList,
    fetchData,
    noManager,
    toggleNoManager,
    unsetTargetDate,
    setUnsetTargetDate,
    orgStatus,
    setOrgStatus,
    blocking,
    detailRole
  } = useOrganizationList();

  return (
    <SidebarTemplate
      pageTitle="組織一覧"
    >
      <BlockUI blocking={blocking}>
        <FormContents>
        <OrganizationListTable
          organizationList={organizationList}
          fetchData={fetchData}
          noManager={noManager}
          toggleNoManager={toggleNoManager}
          unsetTargetDate={unsetTargetDate}
          setUnsetTargetDate={setUnsetTargetDate}
          orgStatus={orgStatus}
          setOrgStatus={setOrgStatus}
          detailRole={detailRole}
        />
        </FormContents>
      </BlockUI>
    </SidebarTemplate>
  );
};

export default OrganizationsPage;
