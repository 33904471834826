/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { grayScale, productColor, textColor } from "components/styles";
import BaseButton, { ButtonProps } from "./BaseButton";

/**
 * セカンダリボタン
 *
 * @see https://sketch.cloud/s/2b3Pe/a/k9qkrA
 */
const SecondaryButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled,
  ghost,
  children,
  minWidth = "120px",
  customStyle = css({}),
}) => (
  <BaseButton
    color={{
      baseColor: productColor.secondary,
      textColor: textColor.inversed,
      ghostColor: productColor.secondary,
      ghostTextColor: productColor.secondary,
      hoverColor: disabled ? grayScale.gray20 : productColor.secondaryHover,
      focusColor: productColor.secondary,
    }}
    text={text}
    onClick={onClick}
    disabled={disabled}
    ghost={ghost}
    minWidth={minWidth}
    customStyle={css([
      {
        ":disabled": {
          cursor: "not-allowed",
        },
      },
      customStyle,
    ])}
  >
    {children}
  </BaseButton>
);

export default SecondaryButton;
