import React, { SVGProps } from 'react';
 
 const CheckOut: React.FC<SVGProps<SVGSVGElement>> = props => (
   <svg width="71" height="67" viewBox="0 0 71 67" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path fillRule="evenodd" clipRule="evenodd" d="M37.7267 30.4397C37.1409 31.0255 37.1409 31.9752 37.7267 32.561L45.2267 40.061C45.8125 40.6468 46.7622 40.6468 47.348 40.061C47.9338 39.4752 47.9338 38.5255 47.348 37.9397L42.4088 33.0005L59.775 33.0005C60.4851 33.0005 61.0607 32.3289 61.0607 31.5005C61.0607 30.672 60.4851 30.0005 59.775 30.0005L42.4086 30.0005L47.348 25.061C47.9338 24.4752 47.9338 23.5255 47.348 22.9397C46.7622 22.3539 45.8125 22.3539 45.2267 22.9397L37.7267 30.4397Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M35.9998 1.50037C35.9998 0.671939 36.6713 0.000366211 37.4998 0.000366211L62.9987 0.000366211C67.1409 0.000366211 70.4987 3.35823 70.4987 7.50037V58.5004C70.4987 62.6425 67.1409 66.0004 62.9987 66.0004H37.4998C36.6713 66.0004 35.9998 65.3288 35.9998 64.5004C35.9998 63.6719 36.6713 63.0004 37.4998 63.0004H62.9987C65.484 63.0004 67.4987 60.9856 67.4987 58.5004V7.50037C67.4987 5.01508 65.484 3.00037 62.9987 3.00037H37.4998C36.6713 3.00037 35.9998 2.32879 35.9998 1.50037Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M8.93181 17.7972C9.55584 21.892 13.3813 24.7057 17.4761 24.0817C21.571 23.4576 24.3846 19.6322 23.7606 15.5374C23.1366 11.4425 19.3112 8.62883 15.2163 9.25286C11.1214 9.87689 8.30778 13.7023 8.93181 17.7972ZM17.0242 21.1159C14.5672 21.4903 12.272 19.8021 11.8976 17.3452C11.5232 14.8883 13.2114 12.593 15.6683 12.2186C18.1252 11.8442 20.4204 13.5324 20.7949 15.9893C21.1693 18.4462 19.4811 20.7415 17.0242 21.1159Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M21.8417 26.7918C22.725 27.2496 23.16 27.8732 23.2518 28.4755L25.1036 40.6272C25.2284 41.4462 24.6657 42.2112 23.8467 42.336L17.9152 43.24C17.0962 43.3648 16.3312 42.802 16.2064 41.9831L14.3545 29.8314C14.2627 29.2291 14.4923 28.5042 15.1991 27.8041C15.9047 27.1052 17.0128 26.524 18.3709 26.317C19.7291 26.1101 20.9599 26.3348 21.8417 26.7918ZM23.2222 24.1283C21.7189 23.3492 19.8367 23.059 17.9189 23.3513C16.0012 23.6435 14.2908 24.4812 13.0879 25.6727C11.8862 26.863 11.117 28.5001 11.3888 30.2833L13.2406 42.435C13.615 44.892 15.9103 46.5801 18.3672 46.2057L24.2987 45.3018C26.7556 44.9274 28.4438 42.6321 28.0694 40.1752L26.2176 28.0235C25.9458 26.2403 24.7239 24.9066 23.2222 24.1283Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M25.3142 42.0119C26.1362 41.9092 26.8859 42.4923 26.9886 43.3143L28.4008 54.6116C28.4297 54.8426 28.512 55.0638 28.6411 55.2576L34.2483 63.6683C34.7078 64.3576 34.5216 65.2889 33.8323 65.7484C33.143 66.2079 32.2117 66.0217 31.7521 65.3324L26.145 56.9217C25.7575 56.3404 25.5106 55.6769 25.424 54.9837L24.0118 43.6864C23.909 42.8644 24.4921 42.1147 25.3142 42.0119Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M18.6703 43.6587C19.4113 44.0292 19.7116 44.9302 19.3411 45.6712L15.0589 54.2358C14.9868 54.38 14.9384 54.5348 14.9156 54.6944L13.4844 64.7125C13.3673 65.5326 12.6075 66.1025 11.7874 65.9853C10.9673 65.8681 10.3974 65.1083 10.5146 64.2882L11.9457 54.2702C12.0141 53.7914 12.1593 53.3267 12.3756 52.8941L16.6579 44.3295C17.0283 43.5886 17.9293 43.2882 18.6703 43.6587Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M24.3001 27.6005C24.7972 26.9378 25.7374 26.8035 26.4001 27.3005L31.7272 31.2958C32.3874 31.791 32.8988 32.4582 33.2053 33.2245L35.8929 39.9434C36.2005 40.7126 35.8264 41.5856 35.0572 41.8932C34.2881 42.2009 33.4151 41.8268 33.1074 41.0576L30.4199 34.3387C30.3177 34.0832 30.1472 33.8608 29.9272 33.6958L24.6001 29.7005C23.9374 29.2035 23.8031 28.2633 24.3001 27.6005Z" fill="white"/>
     <path fillRule="evenodd" clipRule="evenodd" d="M18.937 28.8292C19.5839 29.3467 19.6888 30.2907 19.1713 30.9376L14.072 37.3116C13.2181 38.3791 11.9252 39.0005 10.5581 39.0005L1.50001 39.0005C0.671579 39.0005 5.29243e-06 38.3289 5.32864e-06 37.5005C5.36485e-06 36.6721 0.671577 36.0005 1.50001 36.0005L10.5581 36.0005C11.0138 36.0005 11.4448 35.7934 11.7294 35.4376L16.8287 29.0635C17.3462 28.4166 18.2902 28.3117 18.937 28.8292Z" fill="white"/>
   </svg>
 );
 
 export default CheckOut;