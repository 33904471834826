/* eslint-disable camelcase */
import React, { useState, useCallback } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import YearPicker from 'components/molecules/YearPicker/YearPicker';
import moment from 'moment';
import { exportFileExceltoBudgetYear } from 'api/salesBudgetYear';
import useToastNotification from 'hooks/useToastNotification';
import ConfirmModal from 'components/organismos/ConfirmModal';
import BlockUI from 'components/molecules/BlockUi';
import useSalesBudgetYear, { downloadOriginalFile, setFileName } from './hooks';
import BudgetYearTable from './BudgetYearTable';
import SelectForm from 'components/molecules/SelectForm';

const SalesBudgetYearLayout: React.FC<{
  getFiscalYear: any;
}> = ({ getFiscalYear }) => {
  let labelOrg = '';
  let org_code = '';
  const [getDisableValue, setDisableValue] = useState(false);
  // sessionStorage.setItem('dateSelect', '');

  const dateSelect = sessionStorage.getItem('dateSelect')
    ? sessionStorage.getItem('dateSelect')
    : getFiscalYear;
  const [selectedYear, setSelectedYear] = useState(Number(dateSelect));
  const [getSupportDestinationOrg, setSupportDestinationOrg] = useState(
    sessionStorage.getItem('orgCode') ? sessionStorage.getItem('org_code') : '',
  );

  // notification
  const { errorNotification } = useToastNotification();
  const {
    formik,
    getOptionsOrg,
    getListAdvertising,
    getListAdvertisingExpense,
    getSalesBudgetMonthly,
    getCheckExist,
    getTotal,
    getIsLoading,
    setIsLoading,
    calculatorTable,
    getListAdvertisingApplyPeriod,
    confirmModalOpen,
    closeConfirmModal,
  } = useSalesBudgetYear(selectedYear, getSupportDestinationOrg);

  let getInputDisable = false;
  if (!getSupportDestinationOrg) {
    getInputDisable = true;
  }

  getOptionsOrg.filter((item) => {
    if (item.value === getSupportDestinationOrg) {
      labelOrg = item.label;
      org_code = item.value;
    }
    return labelOrg;
  });

  // export
  const handleExport = useCallback(() => {
    const targetYear = `${selectedYear}/01/01`;
    const orgName = labelOrg;
    const procDateStr = moment(new Date()).format('YYYYMMDDHHmmss');
    setIsLoading(true);
    setDisableValue(true);
    exportFileExceltoBudgetYear(
      getSupportDestinationOrg || 'all',
      orgName,
      targetYear,
      procDateStr,
    )
      .then((response) => {
        const fileName = setFileName(org_code, dateSelect, procDateStr);
        downloadOriginalFile(response, fileName);
        setIsLoading(false);
        setDisableValue(false);
      })
      .catch(() => {
        setIsLoading(false);
        setDisableValue(false);
        errorNotification('サーバー側でエラーが発生しました。');
      });
  }, [dateSelect, errorNotification, getSupportDestinationOrg, labelOrg, org_code, selectedYear, setIsLoading]);

  sessionStorage.setItem('org_code', org_code);
  sessionStorage.setItem('orgCode', org_code);
  sessionStorage.setItem('destination_org', labelOrg);

  return (
    <div>
      <FormContents>
        <FlexBox>
          <YearPicker
            selectedYear={selectedYear}
            onChange={(year: number) => {
              setSelectedYear(year);
              sessionStorage.setItem('dateSelect', `${year}`);
            }}
          />
          <div style={{ width: '300px', marginLeft: '20px' }}>
            <SelectForm
              label=""
              name="orgCode"
              value={String(
                getOptionsOrg.length > 1 ? getSupportDestinationOrg : '',
              )}
              setValue={(val: string) => setSupportDestinationOrg(val)}
              options={getOptionsOrg}
              required={false}
            />
          </div>
          <FlexBoxItem marginLeft="auto">
            <Link to="/salesBudgetYearImport">
              <PrimaryButton
                disabled={!getSupportDestinationOrg}
                text="インポート "
              />
            </Link>
          </FlexBoxItem>
          <FlexBoxItem marginLeft="20px">
            <PrimaryButton
              disabled={getDisableValue}
              onClick={handleExport}
              text="ダウンロード "
            />
          </FlexBoxItem>
        </FlexBox>
      </FormContents>
      <BlockUI blocking={getIsLoading}>
        <FormContents>
          <BudgetYearTable
            listAdvertising={getListAdvertising}
            listAdvertisingExpense={getListAdvertisingExpense}
            salesBudgetMonthly={getSalesBudgetMonthly}
            CheckExist={getCheckExist}
            getTotal={getTotal}
            getInputDisable={getInputDisable}
            formik={formik}
            calculatorTable={calculatorTable}
            getListAdvertisingApplyPeriod={getListAdvertisingApplyPeriod}
          />
        </FormContents>
      </BlockUI>
      <div style={{ textAlign: 'center', margin: '15px' }}>
        <PrimaryButton
          disabled={getInputDisable}
          onClick={formik.handleSubmit}
          text="登録 "
        />
      </div>
      <ConfirmModal
        title="確認メッセージ"
        content="登録します。よろしいですか？"
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.submitForm}
        submitText="登録"
        items={[]}
      />
    </div>
  );
};
export default SalesBudgetYearLayout;
