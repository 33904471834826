/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';

const useMonthlyReport = (orgCode: string, monthlyStartDate: string,
  monthlyEndDate: string) => {
  const [monthlyReport, setmonthlyReport] = useState<Array<MonthlyReportDomain>>([]);
  const [getBudgetPerfomanceManagement, setBudgetPerfomanceManagement] = useState([]);
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const path = 'salesmanagemet';
    setIsLoading(true);
    api.getmonthlyReport(companyCode, orgCode, monthlyStartDate, monthlyEndDate, path).then((response: any) => {
      if (isSubscribed) {
        setmonthlyReport(response.map((result: any) => new MonthlyReportDomain(result)));
      }
      setIsLoading(false);
    });

    const pathBudget = 'budgetPerformanceManagement';
    api.getBudgetPerfomanceManagement(companyCode, orgCode, monthlyStartDate, monthlyEndDate, pathBudget).then((response: any) => {
      setBudgetPerfomanceManagement(response);
      setIsLoading(false);
    });
    return () => { isSubscribed = false; };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, monthlyStartDate, monthlyEndDate]);
  return {
    monthlyReport, setmonthlyReport, getBudgetPerfomanceManagement, getIsLoading,
  };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useGetListMonthlyReport = () => {
  const onSubmit = () => {

  };

  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [action, setAction] = useState<ActionType>(ActionType.getFetchOption);

  return {
    action,
    setAction,
    ActionType,
    formik,
  };
};

export const useDownload = () => {
  const downloadMonthlyReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '月次売上管理表.csv');
  };

  const downloadBudgetPerfomanceManagementCSV = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '月次予実管理表.csv');
  };

  const downloadMonthlyReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '月次売上管理表.xlsx');
  };

  const downloadBudgetPerfomanceManagementExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '月次予実管理表.xlsx');
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadBudgetPerfomanceManagementCSV,
    downloadBudgetPerfomanceManagementExcel,
  };
};

export default useMonthlyReport;
