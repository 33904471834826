import { useEffect, useCallback, useState } from 'react';
import { getGpsStampHistory } from 'api/stampHistory';
import GpsStampHistoryDomain from 'domain/master/atendenceManagement/gpsStampHistory';

const useGpsStampHistory = (orgCode: string, staffCode: string, targetDateFrom: string, targetDateTo: string) => {
  const [gpsStampHistory, setGpsstampHistory] = useState<Array<GpsStampHistoryDomain>>([]);

  const [blocking, setBlocking] = useState(false);
  const [underFlag, setUnderFlag] = useState(0);

  // データの取得
  const fetchData = useCallback(async () => {
    setBlocking(true);
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const response = await getGpsStampHistory(companyCode, orgCode, staffCode, targetDateFrom, targetDateTo, underFlag);
    setGpsstampHistory(response.map((result: any) => new GpsStampHistoryDomain(result)));
    setBlocking(false);
  }, [orgCode, staffCode, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, underFlag]);

  return { gpsStampHistory, setGpsstampHistory, blocking, underFlag, setUnderFlag };
};

export default useGpsStampHistory;
