/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable-next-line array-callback-return */
import React from 'react';
import IconTooltip from 'components/molecules/IconTooltip';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const TableSalesPlanDaily: React.FC<{
  formik: any
  getRows: any,
  handleChangeSelect: any,
  listBiasSettings: any,
  arrayIndexHoliday: any
}> = ({
  getRows,
  formik,
  handleChangeSelect,
}) => {
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'percent',
  });

  const eventScroll = (e: { target: any }) => {
    const { target } = e;
    const contentRight = document.getElementById('daily_layout_sale_right');
    const contentRight2 = document.getElementById('daily_layout_sale_right_2');
    const contentRight3 = document.getElementById('layout_sale_right_3');
    const contentRight4 = document.getElementById('layout_sale_right_4');
    const contentRight5 = document.getElementById('layout_sale_right_5');

    if (contentRight) {
      contentRight.scrollLeft = target.scrollLeft;
    }
    if (contentRight2) {
      contentRight2.scrollLeft = target.scrollLeft;
    }

    if (contentRight3) {
      contentRight3.scrollLeft = target.scrollLeft;
    }

    if (contentRight4) {
      contentRight4.scrollLeft = target.scrollLeft;
    }

    if (contentRight5) {
      contentRight5.scrollLeft = target.scrollLeft;
    }
  };

  const dateSelectType = (e: any) => {
    const { target } = e;
    formik.setFieldValue(`saleBudgetListData[${target.id}][20]`, Number(target.value));
    handleChangeSelect();
  };
  const isRetina = window.devicePixelRatio > 1;

  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };
  return (
    <div style={{ paddingBottom: '40px' }}>
      <div className="sales_plan_daily-settings__top">
        <div className="layout_sale_left" style={{ width: '343px' }}>
          <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td className="text-center active" style={{ height: '45px' }} colSpan={4}>ステータス</td>
              </tr>
              <tr>
                <td className="text-center active" style={{ height: '56px' }} colSpan={3}>項目</td>
                <td className="text-center active width-110" rowSpan={2} style={{ width: '89px' }}>合計</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="layout_sale_right" style={!isRetina ? { overflow: 'auto', width: 'calc(100% - 342px)' } : { overflow: 'auto', width: 'calc(100% - 342px)' }}>
          <div className="table-responsive no-padding layout-sale-right" id="daily_layout_sale_right" style={!isRetina ? { maxWidth: '100%', overflow: 'hidden', marginRight: '45px' } : { maxWidth: '100%', overflow: 'hidden', marginRight: '30px' }}>
            <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '3200px' }}>
              <thead>
                <tr>
                  {
                      formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                        <th key={index} className="text-center width_row_contents">
                          <select
                            className="form-control input-ms date-select-type"
                            id={index}
                            key={index}
                            onChange={dateSelectType}
                          >
                            <option value="0" selected={saleBudgetData[20] === 0}> 繁忙 </option>
                            <option value="1" selected={saleBudgetData[20] === 1}> 通常 </option>
                            <option value="2" selected={saleBudgetData[20] === 2}> 閑散 </option>
                            <option value="3" selected={saleBudgetData[20] === 3}> 定休 </option>
                          </select>
                        </th>
                      ))
                    }
                </tr>
                <tr>
                  {
                      formik.values.salesPlanDailyDateData.map((date: any, index: any) => (
                        <th key={index} className={date.weekday === '土' ? 'saturday text-center active width_row_contents' : date.weekday === '日' || date.weekday === '祝' ? 'text-center active red-day width_row_contents' : 'text-center active width_row_contents'}>
                          {moment(date.day, 'YYYY-MM-DD').format('DD')}
                        </th>
                      ))
                    }
                </tr>
                <tr>
                  {
                      formik.values.salesPlanDailyDateData.map((date: any, index: any) => (
                        <th key={index} className={date.weekday === '土' ? 'saturday text-center active width_row_contents' : date.weekday === '日' || date.weekday === '祝' ? 'text-center active red-day width_row_contents' : 'text-center active width_row_contents'}>
                          {date.weekday}
                        </th>
                      ))
                    }
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div />
      <div className="sales_plan_daily-settings__bottom" style={{ display: 'flex', maxHeight: '600px', overflow: 'auto' }}>
        <div className="no-padding layout_sale_left" id="daily_layout_sale_left" style={{ width: '350px' }}>
          <div className="box-body no-padding">
            <div className="table-responsive no-padding">
              <table className="table table-bordered table-striped table-condensed text-nowrap">
                <tbody>
                  <tr>
                    <td className="text-center active" rowSpan={formik.values.saleBudgetAdvertisingMonthData.length + 3}>売上高</td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計
                        <IconTooltip
                          iconType="help"
                          text="売上高（項目）＋ 売上高（フリー）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110 ">
                      <span id="sumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow">{formatter.format(numberFormater(getRows.getSumOfAdvertisingSaleAndsumOfSaleBudgetFreeRow) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={formik.values.saleBudgetAdvertisingMonthData.length + 1}>広告</td>
                    <td className="text-center active">
                      <span>
                        合計
                        <IconTooltip
                          iconType="help"
                          text="売上高（広告）項目の総計"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfAdvertisingSale">{formatter.format(numberFormater(getRows.getSumOfAdvertisingSale) || 0)}</span>
                    </td>
                  </tr>
                  {
                      formik.values.advertisingSaleData ? formik.values.advertisingSaleData.map((data: any, index: any) => {
                        if (data[1] !== 'total') {
                          return (
                            <tr key={index}>
                              {
                                (data[1].length < 8)
                                  ? <td className="text-center active">{data[1]}</td>
                                  : (
                                    <td className="text-center active">
                                      <p data-tip={data[1]} data-for="test">
                                        {data[1].slice(0, 8)}
                                        ...
                                      </p>
                                      <ReactTooltip />
                                    </td>
                                  )
                              }
                              <td className="text-right active width-110">
                                <span id={`sumOfAdvertisingSale${data[0]}`}>{formik.values.saleBudgetAdvertisingMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetAdvertisingMonthData[index][3])) : ''}</span>
                              </td>
                            </tr>
                          );
                        }
                      })
                        : ''
                    }
                  <tr>
                    <td className="text-center active">
                      <label className="control-label">フリー</label>
                    </td>
                    <td className="text-center active">
                      <label className="control-label">合計</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData03">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][3])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={3}>
                      <label className="control-label">標準原価</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="標準原価（フード）＋ 標準原価（ドリンク）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span className="sumOfTotalFoodCostAndDrinkCost">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][5] + formik.values.saleBudgetMonthData[0][6])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <label className="control-label">フード</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData05">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][5])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <label className="control-label">ドリンク</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData06">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][6])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">売上総利益</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="売上高（合計）- 標準原価（合計）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span className="sumOfListGrossProfit">
                        {formatter.format(numberFormater(getRows.getSumOfListGrossProfit)) || 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="売上総利益 / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="rateOfSumGrossProfitAndSumAmountOfSale">
                        {formatterPercent.format(numberFormater(getRows.getRateOfSumGrossProfitAndSumAmountOfSale) || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={3}>
                      <label className="control-label">人件費</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="人件費（社員）＋ 人件費（バイト）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span className="sumOfTotalEmployeeLaborCostAndPartLaborCost">
                        {formatter.format(numberFormater(getRows.getSumOfTotalEmployeeLaborCostAndPartLaborCost) || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <label className="control-label">社員</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData09">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][9])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <label className="control-label">バイト</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData10">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][10])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={formik.values.saleBudgetAdvertisingMonthData.length + 1}>広告宣伝費</td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="広告宣伝費項目の総計"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span className="sumOfAdvertisingExpense">
                        {formatter.format(numberFormater(getRows.getSumOfAdvertisingExpense) || 0)}
                      </span>
                    </td>
                  </tr>
                  {
                      formik.values.advertisingExpensesData ? formik.values.advertisingExpensesData.map((data: any, index: any) => {
                        if (data[1] !== 'total') {
                          return (
                            <tr key={index}>
                              {
                                (data[1].length < 8)
                                  ? <td className="text-center active" colSpan={2}>{data[1]}</td>
                                  : (
                                    <td className="text-center active" colSpan={2}>
                                      <p data-tip={data[1]} data-for="test">
                                        {data[1].slice(0, 8)}
                                        ...
                                      </p>
                                      <ReactTooltip />
                                    </td>
                                  )
                              }
                              <td className="text-right active width-110">
                                <span id={`sumOfAdvertisingExpense${data[0]}`}>{formik.values.saleBudgetAdvertisingMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetAdvertisingMonthData[index][4])) : ''}</span>
                              </td>
                            </tr>
                          );
                        }
                      })
                        : ''
                    }
                  <tr>
                    <td className="text-center active">
                      <label className="control-label">家賃</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <label className="control-label">合計</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData12">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][12])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={3}>
                      <label className="control-label">その他経費</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="その他経費（変動費）＋ その他経費（固定費）の総計"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span className="sumOfTotalOtherVariableCostsAndOtherFixedCosts">
                        {formatter.format(numberFormater(getRows.getSumOfTotalOtherVariableCostsAndOtherFixedCosts) || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active " colSpan={2}>
                      <label className="control-label">変動費</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData14">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][14])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active " colSpan={2}>
                      <label className="control-label">固定費</label>
                    </td>
                    <td className="text-right active width-110">
                      <span id="saleBudgetMonthData15">
                        {formik.values.saleBudgetMonthData.length > 0 ? formatter.format(numberFormater(formik.values.saleBudgetMonthData[0][15])) : 0}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">営業利益</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="売上総利益（合計）- 人件費（合計）- 広告宣伝費（合計）- 家賃（合計）- その他経費（合計）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="totalOfOperatingIncome">{formatter.format(numberFormater(getRows.getTotalOfOperatingIncome) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="営業利益 / 売上総利益（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="rateOfOperationIncome">{formatterPercent.format(numberFormater(getRows.getRateOfOperationIncome))}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center no-border split_sale_repport" colSpan={3}>&nbsp;</td>
                    <td className="text-right no-border split_sale_repport">&nbsp;</td>
                  </tr>
                  <tr>
                    <td className="text-center active " colSpan={3}>
                      <label className="control-label">客単価</label>
                    </td>
                    <td className="text-right active width-110">
                      <span className="perCustomerPrice">{formatter.format(numberFormater(getRows.getPerCustomerPrice) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={3}>
                      <span>
                        客数&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="売上高（合計）/ 客単価"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfNumberOfCustomers">{formatter.format(numberFormater(getRows.getSumOfNumberOfCustomers) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={3}>
                      <span>
                        回転率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="客数 / 座席数"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfTurnoverRate">{formatter.format(numberFormater(getRows.getSumOfTurnoverRate) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">限界利益</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        &nbsp;
                        <IconTooltip
                          iconType="help"
                          text="売上高（合計）-（標準原価（合計）＋ 人件費（バイト）＋ その他経費（変動費））"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfMarginalProfit">{formatter.format(numberFormater(getRows.getSumOfMarginalProfit) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="限界利益 / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfRateMarginalProfit">{formatterPercent.format(numberFormater(getRows.getSumOfRateMarginalProfit) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">貢献利益</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        &nbsp;
                        <IconTooltip
                          iconType="help"
                          text="限界利益 -（人件費（社員）＋ 広告宣伝費（合計）＋ 家賃（合計）＋ その他経費（固定費））"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfContributionMargin">{formatter.format(numberFormater(getRows.getSumOfContributionMargin) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="貢献利益 / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfRateContributionMargin">{formatterPercent.format(numberFormater(getRows.getSumOfRateContributionMargin) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">損益分岐点</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        売上&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="（人件費（社員）＋ 広告宣伝費（合計）＋ 家賃（合計）＋ その他経費（固定費））/ 限界利益（率）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfBreakEventPointSale">{formatter.format(numberFormater(getRows.getSumOfBreakEventPointSale) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        客数&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="損益分岐点売上 / 客単価"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfBreakEventPointNumber">{formatter.format(numberFormater(getRows.getSumOfBreakEventPointNumber) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={3}>
                      <label className="control-label">人件費率</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        合計&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="人件費（合計）/ 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfSumOfEmployeeAndPartCostRatio">{formatterPercent.format(numberFormater(getRows.getSumOfSumOfEmployeeAndPartCostRatio) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        社員&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="人件費（社員）/ 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfEmployeeCostRatio">{formatterPercent.format(numberFormater(getRows.getSumOfEmployeeCostRatio) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        バイト&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="人件費（バイト）/ 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfPartCostRatio">{formatterPercent.format(numberFormater(getRows.getSumOfPartCostRatio) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={3}>
                      <span>
                        労働分配率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="人件費（合計）/ 売上総利益（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfLaborShare">{formatterPercent.format(numberFormater(getRows.getSumOfLaborShare) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">FL</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        コスト&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="標準原価（合計）＋ 人件費（合計）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLCost">{formatter.format(numberFormater(getRows.getSumOfFLCost) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="FLコスト / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLCostRate">
                        {formatterPercent.format(numberFormater(getRows.getSumOfFLCostRate) || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">FLA</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        コスト&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="標準原価（合計）＋ 人件費（合計）＋ 広告宣伝費（合計）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLACost">
                        {formatter.format(numberFormater(getRows.getSumOfFLACost) || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="FLAコスト / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLACostRate">
                        {formatterPercent.format(numberFormater(getRows.getSumOfFLACostRate) || 0)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">FLAR</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        コスト&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="標準原価（合計）＋ 人件費（合計）＋ 広告宣伝費（合計）＋ 家賃（合計）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLARCost">{formatter.format(numberFormater(getRows.getSumOfFLARCost) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="FLARコスト / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLARCostRate">{formatterPercent.format(numberFormater(getRows.getSumOfFLARCostRate) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" rowSpan={2}>
                      <label className="control-label">FLARO</label>
                    </td>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        コスト&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="標準原価（合計）＋ 人件費（合計）＋ 広告宣伝費（合計）＋ 家賃（合計）＋ その他経費（合計）"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLAROCost">{formatter.format(numberFormater(getRows.getSumOfFLAROCost) || 0)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center active" colSpan={2}>
                      <span>
                        率&nbsp;
                        <IconTooltip
                          iconType="help"
                          text="FLAROコスト / 売上高（合計）の％表示"
                          color="#000"
                        />
                      </span>
                    </td>
                    <td className="text-right active width-110">
                      <span id="sumOfFLAROCostRate">{formatterPercent.format(numberFormater(getRows.getSumOfFLAROCostRate) || 0)}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="layout_sale_right_content1"
          id="daily_layout_sale_right_2"
          style={{
            width: 'calc(100% - 355px)', overflow: 'auto', borderLeft: '5px solid #d2d6de', height: '100%', marginRight: '30px',
          }}
          onScroll={eventScroll}
        >
          <div className="no-padding">
            <div className="box-body no-padding">
              <div className="table-responsive no-padding" style={{ maxWidth: '100%' }}>
                <table className="table table-bordered table-striped table-condensed text-nowrap" style={{ width: '3200px' }}>
                  <tbody>
                    <tr>
                      {getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree ? getRows.getListSumOfAdvertisingSaleAndSaleBudgetFree.map((sumOfAdvertisingSaleAndSaleBudgetFree: any, index: any) => (
                        <td key={index} className="text-right width_row_contents">
                          <span id={`sumOfAdvertisingSaleAndSaleBudgetFree${index}`}>{formatter.format(numberFormater(sumOfAdvertisingSaleAndSaleBudgetFree)) || 0}</span>
                        </td>
                      )) : null}
                    </tr>
                    <tr>
                      {
                          getRows.getListSumOfAdvertisingEachDay ? getRows.getListSumOfAdvertisingEachDay.map((sumOfAdvertisingEachDay: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              {sumOfAdvertisingEachDay !== undefined ? <span id={`sumOfAdvertisingEachDay${index}`}>{formatter.format(numberFormater(sumOfAdvertisingEachDay))}</span> : ''}
                            </td>
                          )) : null
                        }
                    </tr>
                    {formik.values.advertisingSaleData.map((data: any, number: any) => {
                      if (data[1] !== 'total') {
                        return (
                          <tr key={number}>
                            {data.map((dayData: any, index: any) => {
                              if (index !== 0 && index !== 1 && index !== 2 && dayData !== undefined && dayData !== null) {
                                return (
                                  <td className="text-right width_row_contents" key={index}>
                                    <span id={`advertisingSale${data[0] + [index - 3]}`}>{formatter.format(numberFormater(formik.values.advertisingSaleData[number][index]) || 0)}</span>
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`salesBudgetFree${index}`}>{formatter.format(numberFormater(saleBudgetData[3])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListSumOfFoodCostAndDrinkCost ? getRows.getListSumOfFoodCostAndDrinkCost.map((sumOfFoodCostAndDrinkCost: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`sumOfFoodCostAndDrinkCost${index}`}>{formatter.format(numberFormater(sumOfFoodCostAndDrinkCost)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`foodCost${index}`}>{formatter.format(numberFormater(saleBudgetData[5])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`drinkCost${index}`}>{formatter.format(numberFormater(saleBudgetData[6])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListGrossProfit ? getRows.getListGrossProfit.map((data: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`grossProfit${index}`}>{formatter.format(numberFormater(data)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListRateOfGrossProfit ? getRows.getListRateOfGrossProfit.map((rateOfGrossProfit: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`rateOfGrossProfit${index}`}>{formatterPercent.format(numberFormater(rateOfGrossProfit) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListSumOfEmployeeLaborCostAndPartLaborCost ? getRows.getListSumOfEmployeeLaborCostAndPartLaborCost.map((sumOfEmployeeLaborCostAndPartLaborCost: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`sumOfEmployeeLaborCostAndPartLaborCost${index}`}>{formatter.format(numberFormater(sumOfEmployeeLaborCostAndPartLaborCost) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`employeeLaborCost${index}`}>{formatter.format(numberFormater(saleBudgetData[9])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`partLaborCost${index}`}>{formatter.format(numberFormater(saleBudgetData[10])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListSumOfAdvertisingExpenseEachDay ? getRows.getListSumOfAdvertisingExpenseEachDay.map((sumOfAdvertisingExpenseEach: any, index: any) => {
                            if (sumOfAdvertisingExpenseEach !== null && sumOfAdvertisingExpenseEach !== undefined) {
                              return (
                                <td key={index} className="text-right width_row_contents">
                                  <span id={`sumOfAdvertisingExpenseEach${index}`}>{formatter.format(numberFormater(sumOfAdvertisingExpenseEach) || 0)}</span>
                                </td>
                              );
                            }
                          }) : null
                        }
                    </tr>
                    {formik.values.advertisingExpensesData.map((data: any, number: any) => {
                      if (data[1] !== 'total') {
                        return (
                          <tr key={number}>
                            {data.map((dayData: any, index: any) => {
                              if (index !== 0 && index !== 1 && index !== 2 && dayData !== undefined && dayData !== null) {
                                return (
                                  <td key={index} className="text-right width_row_contents">
                                    <span id={`advertisingExpense${data[1][index]}`}>{formatter.format(numberFormater(dayData) || 0)}</span>
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`landRent${index}`}>{formatter.format(numberFormater(saleBudgetData[12])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListSumOfOtherVariableCostsAndOtherFixedCosts ? getRows.getListSumOfOtherVariableCostsAndOtherFixedCosts.map((sumOfOtherVariableCostsAndOtherFixedCosts: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`sumOfOtherVariableCostsAndOtherFixedCosts${index}`}>{formatter.format(numberFormater(sumOfOtherVariableCostsAndOtherFixedCosts) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`otherVariableCosts${index}`}>{formatter.format(numberFormater(saleBudgetData[14])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`otherFixedCosts${index}`}>{formatter.format(numberFormater(saleBudgetData[15])) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfOperatingIncome ? getRows.getListOfOperatingIncome.map((operatingIncome: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`operatingIncome${index}`}>{formatter.format(numberFormater(operatingIncome)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfRateOperatingIncome ? getRows.getListOfRateOperatingIncome.map((rateOperatingIncome: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`rateOperatingIncome${index}`}>{formatterPercent.format(numberFormater(rateOperatingIncome) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>

                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span> </span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          formik.values.saleBudgetListData ? formik.values.saleBudgetListData.map((saleBudgetData: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`perCustomerPrice${index}`}>{formatter.format(numberFormater(getRows.getPerCustomerPrice)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListNumberOfCustomers ? getRows.getListNumberOfCustomers.map((numbersOfCustomers: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`numbersOfCustomers${index}`}>{formatter.format(numberFormater(numbersOfCustomers)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfTurnoverRate ? getRows.getListOfTurnoverRate.map((turnoverRate: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`turnoverRate${index}`}>{formatter.format(numberFormater(turnoverRate)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfMarginalProfit ? getRows.getListOfMarginalProfit.map((marginalProfit: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`marginalProfit${index}`}>{formatter.format(numberFormater(marginalProfit)) || 0}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListRateOfMarginalProfit ? getRows.getListRateOfMarginalProfit.map((rateOfMarginalProfit: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`rateOfMarginalProfit${index}`}>{formatterPercent.format(numberFormater(rateOfMarginalProfit) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfContributionMargin ? getRows.getListOfContributionMargin.map((contributionMargin: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`contributionMargin${index}`}>{formatter.format(numberFormater(contributionMargin) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfRateContributionMargin ? getRows.getListOfRateContributionMargin.map((rateContributionMargin: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`rateContributionMargin${index}`}>{formatterPercent.format(numberFormater(rateContributionMargin) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfBreakEventPointSale ? getRows.getListOfBreakEventPointSale.map((breakEventPointSale: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`breakEventPointSale${index}`}>{formatter.format(numberFormater(breakEventPointSale) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfBreakEventPointNumber ? getRows.getListOfBreakEventPointNumber.map((breakEventPointNumber: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`breakEventPointNumber${index}`}>{formatter.format(numberFormater(breakEventPointNumber) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListSumOfEmployeeAndPartCostRatio ? getRows.getListSumOfEmployeeAndPartCostRatio.map((sumOfEmployeeAndPartCostRatio: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`sumOfEmployeeAndPartCostRatio${index}`}>{formatterPercent.format(numberFormater(sumOfEmployeeAndPartCostRatio) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfEmployeeCostRatio ? getRows.getListOfEmployeeCostRatio.map((employeeCostRatio: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`employeeCostRatio${index}`}>{formatterPercent.format(numberFormater(employeeCostRatio) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfPartCostRatio ? getRows.getListOfPartCostRatio.map((partCostRatio: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`partCostRatio${index}`}>{formatterPercent.format(numberFormater(partCostRatio) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfLaborShare ? getRows.getListOfLaborShare.map((laborShare: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`laborShare${index}`}>{formatterPercent.format(numberFormater(laborShare) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLCost ? getRows.getListOfFLCost.map((fLCost: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLCost${index}`}>{formatter.format(numberFormater(fLCost) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {getRows.getListOfFLCostRate ? getRows.getListOfFLCostRate.map((fLCostRate: any, index: any) => (
                        <td key={index} className="text-right width_row_contents">
                          <span id={`fLCostRate${index}`}>{formatterPercent.format(numberFormater(fLCostRate) || 0)}</span>
                        </td>
                      )) : null}
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLACost ? getRows.getListOfFLACost.map((fLACost: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLACost${index}`}>{formatter.format(numberFormater(fLACost) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLACostRate ? getRows.getListOfFLACostRate.map((fLACostRate: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLACostRate${index}`}>{formatterPercent.format(numberFormater(fLACostRate) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLARCost ? getRows.getListOfFLARCost.map((fLARCost: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLARCost${index}`}>{formatter.format(numberFormater(fLARCost) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLARCostRate ? getRows.getListOfFLARCostRate.map((fLARCostRate: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLARCostRate${index}`}>{formatterPercent.format(numberFormater(fLARCostRate) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLAROCost ? getRows.getListOfFLAROCost.map((fLAROCost: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLAROCost${index}`}>{formatter.format(numberFormater(fLAROCost) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                    <tr>
                      {
                          getRows.getListOfFLAROCostRate ? getRows.getListOfFLAROCostRate.map((fLAROCostRate: any, index: any) => (
                            <td key={index} className="text-right width_row_contents">
                              <span id={`fLAROCostRate${index}`}>{formatterPercent.format(numberFormater(fLAROCostRate) || 0)}</span>
                            </td>
                          )) : null
                        }
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-bottom-daily-settings" id="layout_sale_right_3" style={{ marginTop: '4px' }} onScroll={eventScroll}>
        <div style={{ width: '350px', float: 'left' }} />
        <div
          style={{
            width: 'calc(100% - 350px)',
            float: 'right',
            height: '15px',
            overflow: 'auto',
          }}
          id="layout_sale_right_4"
        >
          <div className="scroll" id="layout_sale_right_5" />
        </div>
      </div>
    </div>
  );
};
export default TableSalesPlanDaily;
