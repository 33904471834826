/* eslint-disable max-len */
import { useState, useEffect } from 'react';

import { OptionType } from 'components/atoms/Select';
import { getEmploymentSelectList, getSelectListDateRange, getNecessaryData } from 'api/employment';
import { getEmploymentOptions, getStaffOptions, getStaffAccessableOptions, getStaffAccessableOptionsV2 } from 'api/timeRecalculation';
import moment from 'moment';
import { getStaffList, getStaffSelectBoxRange } from 'api/staff';

/**
 * 雇用形態の選択ボックス用データを取得
 */
export const useEmploymentOptions = (): Array<OptionType> => {
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    getEmploymentSelectList().then((employments) => {
      const employmentO = employments.map((employment) => ({
        value: employment.employmentId,
        label: `${employment.employmentCode} ${employment.employmentName}`,
      })); 
      employmentO.unshift({
        value:'',
        label: '-'
      });
      setEmploymentOptions(employmentO);
    });
  }, []);
  return employmentOptions;
};

// use Screen 勤怠再計算 - Time Recalculation
export const useEmploymentTypeOptions = (targetDateFrom : Date, targetDateTo: Date): Array<OptionType> => {
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth() - 1, 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');
    getEmploymentOptions(dateFrom, dateTo).then((trees) => {
      const employment = trees.map((tree) => ({
        value: tree.id.employmentId,
        label: `${tree.employmentCode} ${tree.employmentName}`,
      }));
      employment.unshift({
        value: '',
        label: '-',
      });
      setEmploymentOptions(employment);
    });
  }, [targetDateFrom, targetDateTo]);
  return employmentOptions;
};

// use screen 勤怠再計算 - Time Recalculation
export const useStaffOptions = (orgCode: string, targetDateFrom : Date, targetDateTo: Date): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth() - 1, 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');
    const stateStatus : number = 1;
    const targetMonthType: number = 1;
    getStaffOptions(orgCode, dateFrom, dateTo, stateStatus, targetMonthType).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      staffOptions.unshift({
        value: 'all',
        label: 'all 全スタッフ',
      });
      setstaffOptions(staffOptions);
    });
  }, [orgCode, targetDateFrom, targetDateTo]);
  return staffOption;
};

// use screen 勤怠再計算 - Time Recalculation
export const useStaffAccessableOptionsV2 = (targetDateFrom : Date, targetDateTo: Date, orgCode: string, employmentId: string, addAllItem: boolean, functionCode: string, underFlag: number): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom.getFullYear(), targetDateFrom.getMonth() - 1, 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo.getFullYear(), targetDateTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');
    getStaffAccessableOptionsV2(dateFrom, dateTo, orgCode, employmentId, addAllItem, functionCode, underFlag).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      setstaffOptions(staffOptions);
    });
  }, [orgCode, targetDateFrom, targetDateTo, functionCode, underFlag]);
  return staffOption;
};

export const useStaffSelectBoxRange = (targetDateFrom : Date, targetDateTo: Date, orgCode: string, addAllItem: boolean, functionCode: string, underFlag: number): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const params = {
      orgCode,
      targetDayFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDayTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      stateStatus: '1, 3, 4',
      addAllItem,
      functionCode,
      underFlag,
    };
    getStaffSelectBoxRange(params).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      if(addAllItem){
        staffOptions.unshift({
          value: 'all',
          label: '-'
        })
      }
      setstaffOptions(staffOptions);
    });
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);
  return staffOption;
};

// S
export const useStaffAccessableOptions = (orgCode: string, targetDayFrom: Date, targetDayTo: Date, employmentId: string|null, employmentOptions: any, functionCode: string, underFlag: number): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDayFrom === null || targetDayTo === null) return;
    const dateFrom = moment(new Date(targetDayFrom.getFullYear(), targetDayFrom.getMonth() - 1, 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDayTo.getFullYear(), targetDayTo.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DD');
    const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || "";
    const addAllItem: boolean = true;
    getStaffAccessableOptions(loginStaffCode, orgCode, dateFrom, dateTo, employmentId || "", addAllItem, functionCode, underFlag).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      setstaffOptions(staffOptions);
    });
  }, [orgCode, targetDayFrom, targetDayTo, employmentId, employmentOptions, functionCode, underFlag]);
  return staffOption;
};

export const useStaffOptionsTree = (orgCode: string, targetDateFrom : Date, targetDateTo: Date, isNotOptionAll?: boolean, isValueAllOption?: boolean): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (targetDateFrom === null || targetDateTo === null) return;
    const dateFrom = moment(new Date(targetDateFrom)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo)).format('YYYY-MM-DD');
    getStaffList(orgCode, dateFrom, dateTo).then((trees) => {
      const staffOptions = trees.map((tree) => ({
        value: tree.staffCode,
        label: `${tree.staffCode} ${tree.staffName}`,
      }));
      if (!isNotOptionAll) {
        staffOptions.unshift({
          value: isValueAllOption ? '' : 'all',
          label: '-',
        });
      }
      setstaffOptions(staffOptions);
    });
  }, [isNotOptionAll, isValueAllOption, orgCode, targetDateFrom, targetDateTo]);
  return staffOption;
};

export const useEmploymentListDateRange = (targetDateFrom : Date, targetDateTo: Date): Array<OptionType> => {
  const [staffOption, setstaffOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    const dateFrom = moment(new Date(targetDateFrom)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(targetDateTo)).format('YYYY-MM-DD');
    getSelectListDateRange(dateFrom, dateTo).then((trees) => {
      const employment = trees.map((tree: { employmentId: any; employmentCode: any; employmentName: any; }) => ({
        value: tree.employmentId,
        label: `${tree.employmentCode} ${tree.employmentName}`,
      }));
      setstaffOptions(employment);
    });
  }, [targetDateFrom, targetDateTo]);
  return staffOption;
};

/**
 *
 */
export const useEmploymentLatestOptions = (): any => {
  const [employmentOptions, setEmploymentOptions] = useState<Array<OptionType>>([]);
  const [attendEmploymentList, setAttendEmploymentList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await getNecessaryData().then((employments) => {
        const employmentOption = employments.map((employment: any) => ({
          value: employment.id.employmentId,
          label: `${employment.employmentCode} ${employment.employmentName}`,
        }));
        setEmploymentOptions(employmentOption);
        setAttendEmploymentList(employments);
      });
    };
    fetchData();
  }, []);
  return {
    employmentOptions,
    attendEmploymentList,
  };
};

export default useEmploymentOptions;
