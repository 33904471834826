/* eslint-disable max-len */
/** @jsx jsx */
import React, { useCallback, useState, useEffect } from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import BodyText from 'components/atoms/BodyText';
import FormPadding from 'components/atoms/Form/FormPadding';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import { PrimaryButton } from "components/atoms/Button";
import FileSelectButton from "components/molecules/FileSelectButton";
import useToastNotification from 'hooks/useToastNotification';
import FormField from "components/atoms/Form/FormField";
import { utilityColor } from "components/styles";
import Modal from "components/molecules/Modal";
import TextForm from 'components/molecules/TextForm';
import Checkbox from 'components/molecules/Checkbox';
import { useMasterRoleAddForm } from './hooks';

const styles = {
    containerForm: css({
        width: '80%',
        margin: 'auto',
    }),
};


const MasterRoleAddForm = () => {

    const {
        isLoading,
        history,
        confirmModalOpen,
        setConfirmModalOpen,
        formik
    } = useMasterRoleAddForm();

    useEffect(() => {
        if(formik.values.administrator === true){
            formik.setFieldValue('accessLevel', 0);
        }
    }, [formik.values.administrator])
    return (
    <BlockUI blocking={isLoading}>
        <div>
        <FormContents customStyle={{ paddingBottom: '50px' }}>
            <FormTitle title="権限作成" bold={true} customStyle={css`margin: 16px 35px;`} />
            <div css={styles.containerForm}>
                <FormField>
                    <TextForm
                        name="roleCode"
                        label="権限コード"
                        value={formik.values.roleCode}
                        onChange={formik.handleChange}
                        required={true}
                    />
                </FormField>
                <FormField>
                    <TextForm
                        name="roleName"
                        label="権限名"
                        value={formik.values.roleName}
                        onChange={formik.handleChange}
                        required={true}
                    />
                </FormField>
                <FormField customStyle={css({width: '120px'})}>
                    <TextForm
                        name="accessLevel"
                        label="権限レベル"
                        value={String(formik.values.accessLevel)}
                        onChange={(e) => formik.setFieldValue('accessLevel', Number(e.target.value))}
                        required={true}
                        type="number"
                        
                        disable={formik.values.administrator === true}
                    />
                </FormField>
                <BodyText color='#007BC3'>値が小さいほど、上位レベルとなります</BodyText>
                <FormField customStyle={css({marginTop: '20px'})}>
                    <Checkbox
                        id="administrator"
                        name="administrator"
                        label="システム管理者用権限"
                        value={String(formik.values.administrator)}
                        checked={Boolean(formik.values.administrator)}
                        onChange={(e) => {
                            let isChecked = e.target.checked;
                            formik.setFieldValue('administrator', isChecked);
                        }}
                    />
                </FormField>
                <BodyText color='#007BC3'>システム管理者用権限は全機能使用可能、全組織アクセス可能となります</BodyText>
                <FormSubmitArea customStyle={css({ marginTop: "15px" })}>
                    <FlexBox justifyContent="center">
                        <PrimaryButton
                            onClick={() => setConfirmModalOpen(true)}
                            text="登録"                            
                        />
                    </FlexBox>
                    <FlexBox justifyContent="center">
                        <PrimaryButton
                            onClick={() => history.push('/masterRoleSetting')}
                            text="キャンセル"
                            ghost={true}
                        />
                    </FlexBox>
                </FormSubmitArea>
                <Modal
                    open={confirmModalOpen}
                    closeHandler={() => setConfirmModalOpen(false)}
                    title="確認メッセージ"
                    submitText="登録する"
                    note="権限を登録します。よろしいですか？"
                    onSubmit={formik.submitForm}
                >
                </Modal>
            </div>
        </FormContents>
        </div>
    </BlockUI>
    );
};

export default MasterRoleAddForm;
