import React from 'react';

import FormLabel from 'components/atoms/Form/FormLabel';
import PasswordInput from 'components/atoms/Form/PasswordInput';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';

const PasswordForm: React.FC<{
  name: string;
  label: string;
  value: string;
  width?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg?: string;
  placeHolder?: string;
  showLabel?: boolean
}> = ({
  name, label, value, onChange, width, errorMsg, placeHolder = "", showLabel = true
}) => (
  <VerticalLabelFormLayout
    label={showLabel ? (
      <FormLabel
        label={label}
      />
      ) : ""}
    input={(
      <PasswordInput
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        placeHolder={placeHolder}
      />
      )}
    errorMsg={errorMsg}
  />
);

export default PasswordForm;
