/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import IconLabelButton from "components/molecules/IconLabelButton";
import FlexBox from "components/atoms/FlexBox";
import FormLabel from "components/atoms/Form/FormLabel";
import HourInput from "components/atoms/Form/HourInput";
import { productColor } from "components/styles";
import { SecondaryButton } from "components/atoms/Button";
import BodyText from "components/atoms/BodyText";

const styles = {
  buttonDelete: css({
    borderRadius: "3px",
    padding: "0",
    height: "24px",
    lineHeight: "0",
    margin: "8px 10px",
    fontSize: "12px",
  }),
  grayoutButton: css({
    opacity: "0.5",
    cursor: "not-allowed",
  }),
  isStartTimeNextDay: css({
    marginRight: "20px",
  }),
  formContainer: css({
    width: "650px",
    margin: "auto",
    border: "0",
  }),
  formLabel: css({
    paddingTop: "8px",
    width: "250px",
  }),
  formNote: css({
    margin: "0 0 2px 16px",
    color: "#333333",
  }),
  formInput: css({
    width: "65px",
    height: "40px",
  }),
  errorNote: css({
    color: productColor.secondary,
  }),
  errorMess: css({
    color: productColor.secondary,
    fontSize: "12px",
    width: "100%",
  }),
  warningNote: css({
    color: productColor.copyAction,
  }),
};

const ListWarningThreshold: React.FC<{
  listWarningThreshold: Array<number>;
  setListWarningThreshold: any;
  label: string;
  noteInput: string;
  maxLength?: number;
  maxLengthInput?: number;
}> = ({ listWarningThreshold, setListWarningThreshold, label, noteInput, maxLength = 1, maxLengthInput }) => {
  const [errList, setErrList] = useState<Array<number>>([]);

  const handleDeleteFormInput = () => {
    let tmpList = [...listWarningThreshold];
    tmpList.pop();
    setListWarningThreshold(tmpList);
  };

  // Change value
  const handleChangeList = (value: number, index: any) => {
    let tmpList = [...listWarningThreshold];
    tmpList[index] = value;
    setListWarningThreshold(tmpList);
  };

  useEffect(() => {
    let tmpValidate = [...listWarningThreshold];
    let validateList = checkValidateList(tmpValidate);
    if (JSON.stringify(validateList) !== JSON.stringify(tmpValidate)) {
      setListWarningThreshold(validateList);
    }
  }, [listWarningThreshold]);

  const checkValidateList = (tmpList: any) => {
    let errList: Array<number> = [];
    tmpList.map((item: any, index: number) => {
      let minVal = 1 + Number(listWarningThreshold[index - 1]);

      if (index > 0 && Number(item) < minVal) {
        errList.push(index);
      }
    });
    setErrList(errList);
    return tmpList;
  };

  const handleAddFormInput = () => {
    let tmpList = [...listWarningThreshold];
    tmpList.push(0);
    setListWarningThreshold(tmpList);
    return;
  };

  const getStyleLabel = (value: number, list: Array<number>, index: number) => {
    let tmpList = [...list];
    let maxValue = Math.max(...tmpList);
    tmpList = tmpList.filter((item) => item !== maxValue);
    let nearMaxValue = Math.max(...tmpList);

    switch (value) {
      case maxValue:
        return styles.errorNote;
      case nearMaxValue:
        return styles.warningNote;
      default:
        return {};
    }
  };

  return (
    <React.Fragment>
      {listWarningThreshold.map((item, index) => (
        <React.Fragment>
          <FlexBox customStyle={css({ marginBottom: "20px", flexWrap: "wrap" })}>
            {label && <FormLabel customStyle={styles.formLabel} label={index === 0 ? label : ""} />}
            <FlexBox customStyle={css({ flexWrap: "wrap", width: "300px" })}>
              <HourInput
                name={`hourMonthlyWarningThreshold${index + 1}`}
                value={String(listWarningThreshold[index])}
                label=""
                onChange={(e) => {
                  handleChangeList(Number(e.target.value), index);
                }}
                min={1 + (index > 0 ? Number(listWarningThreshold[index - 1]) : 0)}
                widthInput="50px"
                wrapInput={styles.formInput}
                maxLength={maxLengthInput}
              />
              <FormLabel
                customStyle={css([styles.formNote, getStyleLabel(item, listWarningThreshold, index)])}
                label={noteInput}
              />
              <br />
              {errList.includes(index) && (
                <BodyText customStyle={styles.errorMess}>上の設定値よりも大きい値を入力してください</BodyText>
              )}
            </FlexBox>
            {index === listWarningThreshold.length - 1 && index > 0 && (
              <SecondaryButton
                text="削除"
                onClick={() => handleDeleteFormInput()}
                minWidth="40px"
                customStyle={styles.buttonDelete}
              />
            )}
          </FlexBox>
        </React.Fragment>
      ))}
      <FlexBox>
        {label && <FormLabel customStyle={styles.formLabel} label="" />}
        {listWarningThreshold.length < maxLength && (
          <IconLabelButton
            iconType="clone"
            onClick={() => handleAddFormInput()}
            text="アラート値を追加する"
            width="auto"
            padding="0 0 0 80px"
          />
        )}
      </FlexBox>
    </React.Fragment>
  );
};

export default ListWarningThreshold;
