import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PageTitle from 'components/atoms/PageTitle';
import { useParams } from 'react-router-dom';
import BusinessCreateForm from 'components/organismos/master/labor/BusinessCreateForm';

const BusinessCreatePage: React.FC = () => {
  const { businessId } = useParams();

  return (
    <SidebarTemplate>
      <PageTitle title={businessId ? '業務マスタ編集' : '新規業務作成'} />
      <BusinessCreateForm />
    </SidebarTemplate>
  );
};
export default BusinessCreatePage;
