/* eslint-disable max-len */
/** @jsx jsx */
import React, { useMemo, useState, useCallback } from 'react';
import { Column } from 'react-table';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import DataTable from 'components/organismos/DataTable/DataTable';
import ConfirmModal from 'components/organismos/ConfirmModal';
import ToastModal from 'components/organismos/ToastModal';
import { iconColor } from 'components/styles';
import Icon from 'components/atoms/Icon';
import AdvertisingMediaOrganizationDomain from 'domain/master/sales/advertisingMediaOrganization';
import { deleteAdvertisingMediaOrganization } from 'api/advertisingMediaOrganization';
import SelectForm from 'components/molecules/SelectForm';
import { useOrgTreesByCategoryOptions } from 'hooks/useOrgTreesOptions';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import useToastNotification from 'hooks/useToastNotification';
import useAdvertisingMediaOrganizationList from './hooks';

const AdvertisingImportMasterPage: React.FC = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalResendOpen, setConfirmModalResendOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('');
  const [selectedObj, setSelectedObj] = useState(AdvertisingMediaOrganizationDomain.generateInitial());
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [toastMessage] = useState('');
  const [isHover, setHover] = useState('');
  const [isHover2, setHover2] = useState('');
  const [orgCode, setorgCode] = useState('');
  const { successNotification, errorNotification } = useToastNotification();

  const orgTreesOptions = useOrgTreesByCategoryOptions();
  const { advertisingMediaOrganizationList, setAdvertisingMediaOrganizationList } = useAdvertisingMediaOrganizationList(orgCode);

  // Set default selected org
  useMemo(() => {
    setorgCode(sessionStorage.getItem('selectedOrgCode')!);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeConfirmResendModal = useCallback(() => {
    setConfirmModalResendOpen(false);
  }, []);

  const openConfirmModal = useCallback((advertising : AdvertisingMediaOrganizationDomain) => {
    setConfirmModalOpen(true);
    setSelectedObj(advertising);
  }, []);

  const deleteUpdateHoliday = useCallback(() => {
    const staffName = sessionStorage.getItem('loginUser.staffName') || '';
    const submitObj = {
      advertisingMediaMstCode: selectedObj.advertisingMediaCode,
      id: {
        companyCode: selectedObj.companyCode,
        orgCode,
        posType: selectedObj.postType.toString(),
        advertisingMediaCode: selectedObj.importCode,
      },
      applyStartDayStr: selectedObj.applicableStartMonthStr,
      applyEndDayStr: selectedObj.applicableEndMonthStr,
      createUser: staffName,
      updateUser: staffName,
    };
    deleteAdvertisingMediaOrganization(confirmModalResendOpen ? 1 : 0, submitObj).then((Response: any) => {
      setAdvertisingMediaOrganizationList(advertisingMediaOrganizationList.filter(
        (advertisingMediaOrganization) => advertisingMediaOrganization.advertisingMediaCode !== selectedObj.advertisingMediaCode
        || advertisingMediaOrganization.postType !== selectedObj.postType || advertisingMediaOrganization.importCode !== selectedObj.importCode,
      ));
      setConfirmModalOpen(false);
      // setConfirmModalResendOpen(false);
      // setToastMessage('削除しました。');
      // setToastModalOpen(true);
      successNotification('削除しました。');
    }).catch((error) => {
      setConfirmModalOpen(false);
      if (error.response.status === 406) {
        const errorString = '既にPOSデータが取り込まれています。削除するとレポートやリストで売上の金額が合わなくなる可能性がありますが、よろしいですか？';
        setConfirmModalResendOpen(true);
        setConfirmModalContent(errorString);
      } else {
        if (error.response.data.errors != null && error.response.data.errors.length !== 0) {
          let errorString : string = '';

          error.response.data.errors.forEach((errorStr: { defaultMessage: any; }) => { errorString = `${errorString}${errorStr.defaultMessage}</br>`; });

          //   setToastMessage(errorString);
          // } else {
          //   setToastMessage('サーバー側でエラーが発生しました。');
          errorNotification(errorString);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
        setToastModalOpen(true);
      }
    });
  }, [advertisingMediaOrganizationList, confirmModalResendOpen, errorNotification, orgCode, selectedObj.advertisingMediaCode, selectedObj.applicableEndMonthStr, selectedObj.applicableStartMonthStr, selectedObj.companyCode, selectedObj.importCode, selectedObj.postType, setAdvertisingMediaOrganizationList, successNotification]);

  const columns: Array<Column<AdvertisingMediaOrganizationDomain>> = useMemo(() => [
    {
      Header: '広告媒体',
      accessor: 'advertisingMediaStr',
    },
    {
      Header: 'POS',
      accessor: 'postTypeStr',
    },
    {
      Header: '取込コード',
      accessor: 'importCode',
    },
    {
      Header: '適用開始月',
      accessor: 'applicableStartMonthStr',
    },
    {
      Header: '適用終了月',
      accessor: 'applicableEndMonthStr',
    },
    {
      Header: () => null,
      id: 'actionButton',
      disableSortBy: true,
      Cell: (cell: { row :{ isExpanded: boolean, original: AdvertisingMediaOrganizationDomain } }) => (
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={`/salesAdvertisingMedia/advertisingSetting/edit/${cell.row.original.importCode}/${cell.row.original.postType}/${orgCode}`}>
            <span
              style={{ marginLeft: '20px' }}
              onMouseOver={() => { setHover(cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.advertisingMediaCode)); }}
              onMouseOut={() => { setHover(''); }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              <Icon type="edit" color={(isHover && isHover === (cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.advertisingMediaCode))) ? iconColor.grayhover : iconColor.gray} />
            </span>
          </Link>

          <span
            style={{ marginLeft: '20px', marginRight: '10px' }}
            onMouseOver={() => { setHover2(cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.advertisingMediaCode)); }}
            onMouseOut={() => { setHover2(''); }}
            onClick={() => { openConfirmModal(cell.row.original); }}
            role="presentation"
            onFocus={() => {}}
            onBlur={() => {}}
          >
            <Icon type="delete" color={(isHover2 && isHover2 === (cell.row.original.importCode.concat(cell.row.original.postType, cell.row.original.advertisingMediaCode))) ? iconColor.grayhover : iconColor.gray} />
          </span>

        </div>
      ),
    },
  ], [isHover, isHover2, openConfirmModal, orgCode]);
  return (
    <div>
      <PageTitle
        title=""
        rightContents={(
          <Link to={`/salesAdvertisingMedia/advertisingSetting/add/${orgCode}`}>
            <span style={{ marginRight: '12px' }}>
              <PrimaryButton
                ghost={false}
                text="新規登録"
              />
            </span>
          </Link>
    )}
      />

      <div style={{ marginRight: '12px', padding: '16px' }}>
        <SelectForm
          label="組織名："
          name="orgCode"
          value={orgCode}
          setValue={(v) => {
            sessionStorage.setItem('selectedOrgCode', v);
            setorgCode(v);
          }}
          options={orgTreesOptions}
          description=""
        />
      </div>

      <div
        css={css`
            table th:nth-of-type(1) {
              width: 200px;
            }
            table th:nth-of-type(2) {
              width: 150px;
            }
            table th:nth-of-type(3) {
              width: 100px;
            }
            table th:nth-of-type(4) {
              width: 100px;
            }
            table th:nth-of-type(5) {
              width: 100px;
            }
            table th:nth-of-type(6) {
              width: 80px;
            }
          `}
      >
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', margin: '15px' }}>
          <DataTable
            columns={columns}
            data={advertisingMediaOrganizationList}
          />
        </div>
      </div>
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={deleteUpdateHoliday}
        actionType={ActionType.DELETE}
      />
      <ConfirmModal
        open={confirmModalResendOpen}
        closeHandler={closeConfirmResendModal}
        onSubmit={deleteUpdateHoliday}
        title="確認"
        content={confirmModalContent}
        items={[]}
      />
      <ToastModal
        open={toastModalOpen}
        closeHandler={() => setToastModalOpen(false)}
        title={toastMessage}
      />
    </div>
  );
};

export default AdvertisingImportMasterPage;
