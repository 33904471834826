/* eslint-disable max-len */
/** @jsx jsx */
import React, { Dispatch, useContext } from "react";
import { Link } from "react-router-dom";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import Button, { SubActionButton, SecondaryButton } from "components/atoms/Button";
import StaffDomain from "domain/staff";
import ConfirmActionModal, { ActionType } from "components/organismos/ConfirmActionModal";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import TextForm from "components/molecules/TextForm";
import {
  WeekShiftPatternDataStaffDomain,
  DayOfWeekLabel,
  DayOfWeek,
} from "domain/master/general/weekShiftPatternDataStaff";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import BodyText from "components/atoms/BodyText";
import MultipleCheckboxForm from "components/molecules/MultipleCheckboxForm";
import ShiftPatternDomain from "domain/master/attend/shiftPattern";
import ReactTooltip from "react-tooltip";
import { useAffiliationForm } from "./hooks";
import { css, jsx } from "@emotion/core";
import useStaffList from "components/pages/master/general/staff/StaffsPage/hooks";
import { useStaffDelete } from "../StaffListTable/hooks";
import FormLabel from "components/atoms/Form/FormLabel";
import StaffShiftPatternDetails from "components/organismos/master/labor/shiftPattern/ShiftPatternDetails/StaffShiftPatternDetails";
import { isIPad13 } from "react-device-detect";
import SelectForm from "components/molecules/SelectForm";
// import { formatDiagnostic } from 'typescript';

const styles = {
  form: css({
    maxWidth: "1000px",
    margin: "0 auto",
    marginTop: "30px",
    padding: "0px 30px",
  }),
  table: css(
    {},
    css`
      table tr th {
        text-align: left;
      }
    `,
  ),
  flexbox: css({
    maxWidth: "150px",
    justifyContent: "center",
  }),
  btnCopy: css({
    marginTop: "5px",
    marginLeft: "15px",
  }),
  flexboxCustom: css({
    margin: "20px 0px",
  }),
  formSubmitArea: css({
    margin: "0 auto",
    textAlign: "center",
    padding: "30px",
  }),
  centerBox: css({
    justifyContent: "center",
  }),
};

type SelectType = "staff" | "affiliation" | "employment" | "laborCost" | "workPattern" | "longTermCareandNurse";
const SelectType: { [key in SelectType]: SelectType } = {
  staff: "staff",
  affiliation: "affiliation",
  employment: "employment",
  laborCost: "laborCost",
  workPattern: "workPattern",
  longTermCareandNurse: "longTermCareandNurse",
};

const WorkPatternInfoForm: React.FC<{
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  initialStaffDomain?: StaffDomain;
  isEditMode: boolean;
  setIsEditMode?: Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
  breakBusiness: ShiftPatternDomain;
  isDisabledInfo?: boolean;
  tabItems?: any;
  setTabItems?: any;
  setSelectType?: any;
}> = ({
  setInitialStaffDomain,
  setIsEditMode,
  initialStaffDomain,
  isEditMode,
  breakBusiness,
  isDisabledInfo = false,
  setIsLoading,
  tabItems,
  setTabItems,
  setSelectType,
}) => {
  const {
    formik,
    businessOptions,
    shiftPatternOptions,
    confirmModalOpen,
    closeConfirmModal,
    selectedDayOfWeeks,
    setSelectedDayOfWeeks,
    registeredDayOfWeeks,
    setDeleteTargetWeekShift,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    closeDeleteModal,
    onSubmitDeleteStaff,
    remote,
    contextObject,
  } = useAffiliationForm(initialStaffDomain, isEditMode, setIsEditMode, setIsLoading);
  const columns: Array<Column<WeekShiftPatternDataStaffDomain>> = React.useMemo(
    () => [
      {
        Header: () => null,
        accessor: "x",
        id: "actionButton",
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean; original: WeekShiftPatternDataStaffDomain } }) => {
          return (
            <FlexBox customStyle={styles.flexbox}>
              <FlexBoxItem marginRight="10px">
                <SubActionButton
                  action={0}
                  text={contextObject.detailRole.editable === 1 ? "編集" : "参照"}
                  onClick={() => {
                    formik.values.selectWeekShiftPattern = new WeekShiftPatternDataStaffDomain({
                      ...cell.row.original.getRawData(),
                    });
                    formik.values.selectWeekShiftPattern.selectedDayOfWeeks = [cell.row.original.dayOfWeek];
                    setSelectedDayOfWeeks([cell.row.original.dayOfWeek]);
                    formik.setFieldValue(
                      "selectWeekShiftPattern",
                      new WeekShiftPatternDataStaffDomain({ ...cell.row.original.getRawData() }),
                    );
                    if (formik.values.selectWeekShiftPattern.attendType !== 0) {
                      let initShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                      initShiftPattern.staffWeekShiftPatternId =
                        formik.values.selectWeekShiftPattern.staffWeekShiftPatternId;
                      initShiftPattern.attendType = formik.values.selectWeekShiftPattern.attendType;
                      initShiftPattern.patternIdStr = formik.values.selectWeekShiftPattern.patternIdStr;
                      initShiftPattern.selectedDayOfWeeks = [cell.row.original.dayOfWeek];
                      formik.setFieldValue("selectWeekShiftPattern", initShiftPattern);
                    }
                    setInitialStaffDomain(formik.values);
                  }}
                />
              </FlexBoxItem>
              {contextObject.detailRole.editable === 1 && (
                <FlexBoxItem>
                  <SubActionButton
                    action={1}
                    text="削除"
                    onClick={() => {
                      setIsOpenDeleteModal(true);
                      setDeleteTargetWeekShift(cell.row.original);
                    }}
                  />
                </FlexBoxItem>
              )}
            </FlexBox>
          );
        },
      },
      {
        Header: "曜日",
        accessor: "dayOfWeekStr",
      },
      {
        Header: "シフト",
        accessor: "shiftPatternNameWithAll",
      },
      //      {
      //        Header: 'パターンコード',
      //        accessor: 'shiftPatternCode',
      //      },
      //      {
      //        Header: 'パターン名',
      //        accessor: 'shiftPatternNameWithAll',
      //      },
      {
        Header: "出勤時間",
        accessor: "startTimeStr",
      },
      {
        Header: "退勤時間",
        accessor: "endTimeStr",
      },
      {
        Header: "休憩時間",
        accessor: "breakTimeStr",
      },
    ],
    [
      formik,
      setDeleteTargetWeekShift,
      setInitialStaffDomain,
      setSelectedDayOfWeeks,
      setIsOpenDeleteModal,
      contextObject.detailRole,
    ],
  );

  const { fetchData } = useStaffList();
  const {
    setDeleteTargetStaff,
    isOpenDeleteModal: isOpenDeleteModalStaff,
    setIsOpenDeleteModal: setIsOpenDeleteModalStaff,
    closeDeleteModal: closeDeleteModalStaff,
    onSubmitDeleteStaff: onSubmitDeleteStaffForm,
  } = useStaffDelete(fetchData, setIsLoading);

  return (
    <React.Fragment>
      {formik.values.weekShiftPatternList && formik.values.weekShiftPatternList.length !== 0 && (
        <div className="table-bordered" css={styles.table}>
          <DataTable columns={columns} data={formik.values.weekShiftPatternDomainList} isHiddenPagination={false} />
        </div>
      )}

      <form onSubmit={formik.handleSubmit} css={styles.form}>
        <FlexBox>
          <FlexBoxItem basis="250px">
            <BodyText> 設定曜日 </BodyText>
          </FlexBoxItem>
          <FlexBoxItem basis="70%">
            <div style={{ marginBottom: "6px" }}>
              <MultipleCheckboxForm
                disableCheckboxs={registeredDayOfWeeks.map((item) => item.toString())}
                name="dayOfWeek"
                label=""
                items={[
                  {
                    id: DayOfWeek.HOLIDAY.toString(),
                    label: DayOfWeekLabel[DayOfWeek.HOLIDAY],
                    value: selectedDayOfWeeks.includes(DayOfWeek.HOLIDAY),
                  },
                  {
                    id: DayOfWeek.SAT.toString(),
                    label: DayOfWeekLabel[DayOfWeek.SAT],
                    value: selectedDayOfWeeks.includes(DayOfWeek.SAT),
                  },
                  {
                    id: DayOfWeek.SUN.toString(),
                    label: DayOfWeekLabel[DayOfWeek.SUN],
                    value: selectedDayOfWeeks.includes(DayOfWeek.SUN),
                  },
                  {
                    id: DayOfWeek.MON.toString(),
                    label: DayOfWeekLabel[DayOfWeek.MON],
                    value: selectedDayOfWeeks.includes(DayOfWeek.MON),
                  },
                  {
                    id: DayOfWeek.TUE.toString(),
                    label: DayOfWeekLabel[DayOfWeek.TUE],
                    value: selectedDayOfWeeks.includes(DayOfWeek.TUE),
                  },
                  {
                    id: DayOfWeek.WED.toString(),
                    label: DayOfWeekLabel[DayOfWeek.WED],
                    value: selectedDayOfWeeks.includes(DayOfWeek.WED),
                  },
                  {
                    id: DayOfWeek.THU.toString(),
                    label: DayOfWeekLabel[DayOfWeek.THU],
                    value: selectedDayOfWeeks.includes(DayOfWeek.THU),
                  },
                  {
                    id: DayOfWeek.FRI.toString(),
                    label: DayOfWeekLabel[DayOfWeek.FRI],
                    value: selectedDayOfWeeks.includes(DayOfWeek.FRI),
                  },
                ]}
                setValue={(e) => {
                  const selectDayOfWeek = Number(e.target.id);
                  if (!registeredDayOfWeeks.includes(selectDayOfWeek)) {
                    if (e.target.value === "true") {
                      formik.values.selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks.filter(
                        (item) => item !== selectDayOfWeek,
                      );

                      setSelectedDayOfWeeks(selectedDayOfWeeks.filter((item) => item !== selectDayOfWeek));
                      setInitialStaffDomain(formik.values);
                    } else {
                      formik.values.selectWeekShiftPattern.selectedDayOfWeeks = [
                        ...selectedDayOfWeeks,
                        selectDayOfWeek,
                      ];

                      setSelectedDayOfWeeks([...selectedDayOfWeeks, selectDayOfWeek]);
                      setInitialStaffDomain(formik.values);
                    }
                  }
                }}
              />
            </div>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.flexboxCustom}>
          <FlexBoxItem basis="250px">
            <FormLabel label="シフト選択" />
          </FlexBoxItem>
          <FlexBoxItem basis={isIPad13 ? "73%" : "630px"}>
            <FlexBox>
              <FlexBoxItem basis="80%">
                <SelectForm
                  label=""
                  name="selectWeekShiftPattern.patternIdStr"
                  value={formik.values.selectWeekShiftPattern.patternIdStr}
                  setValue={(val: string) => {
                    let selectWeekShiftPattern: any;
                    if (val === "") {
                      selectWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                      selectWeekShiftPattern.staffWeekShiftPatternId =
                        formik.values.selectWeekShiftPattern.staffWeekShiftPatternId;
                      selectWeekShiftPattern.attendType = 0;
                      selectWeekShiftPattern.daysOfWeek = [formik.values.selectWeekShiftPattern.dayOfWeek];
                      selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks;
                    } else if (val === "legal") {
                      selectWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                      selectWeekShiftPattern.patternIdStr = "legal";
                      selectWeekShiftPattern.attendType = 1;
                      selectWeekShiftPattern.daysOfWeek = [formik.values.selectWeekShiftPattern.dayOfWeek];
                      selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks;
                    } else if (val === "scheduled") {
                      selectWeekShiftPattern = WeekShiftPatternDataStaffDomain.generateInitial();
                      selectWeekShiftPattern.patternIdStr = "scheduled";
                      selectWeekShiftPattern.attendType = 2;
                      selectWeekShiftPattern.daysOfWeek = [formik.values.selectWeekShiftPattern.dayOfWeek];
                      selectWeekShiftPattern.selectedDayOfWeeks = selectedDayOfWeeks;
                    } else {
                      const shiftPattern = formik.values.shiftPatternList.find((item) => item.shiftPatternId === val)!;
                      selectWeekShiftPattern = {
                        companyCode: shiftPattern.companyCode,
                        createUser: shiftPattern.createUser,
                        updateUser: shiftPattern.updateUser,
                        orgCode: shiftPattern.orgCode,
                        shiftPatternId: shiftPattern.shiftPatternId,
                        patternIdStr: shiftPattern.patternIdStr,
                        shiftPatternCode: shiftPattern.shiftPatternCode,
                        shiftPatternName: shiftPattern.shiftPatternName,
                        shortName: shiftPattern.shortName,
                        staffWeekShiftPatternId: formik.values.selectWeekShiftPattern.staffWeekShiftPatternId,
                        daysOfWeek: [formik.values.selectWeekShiftPattern.dayOfWeek],
                        selectedDayOfWeeks,
                        attendShiftPatternDetails: shiftPattern.attendShiftPatternDetails,
                        attendType: 0,
                      };
                    }
                    formik.values.selectWeekShiftPattern = selectWeekShiftPattern;

                    formik.setFieldValue("selectWeekShiftPattern", selectWeekShiftPattern);
                    setInitialStaffDomain(formik.values);
                  }}
                  options={shiftPatternOptions}
                  errorMsg={formik.errors.orgCode}
                  required={true}
                />
              </FlexBoxItem>
              <FlexBoxItem basis="20%">
                <div
                  css={styles.btnCopy}
                  className="tooltip"
                  style={
                    formik.values.selectWeekShiftPattern.attendType !== 0 ||
                    formik.values.selectWeekShiftPattern.shiftPatternId === ""
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  <p data-tip="選択中のシフトパターンをもとに </br> 新規にパターンを作成します。">
                    <SubActionButton
                      action={2}
                      text="コピー"
                      onClick={() => {
                        formik.values.selectWeekShiftPattern.shiftPatternId = "";
                        formik.setFieldValue("selectWeekShiftPattern.shiftPatternId", "");
                        setInitialStaffDomain(formik.values);
                      }}
                    />
                  </p>
                  <ReactTooltip html={true} />
                </div>
              </FlexBoxItem>
            </FlexBox>
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.flexboxCustom}>
          <FlexBoxItem basis="250px">
            <FormLabel label="シフトパターンコード" />
          </FlexBoxItem>
          <FlexBoxItem width="504px">
            <TextForm
              name="selectWeekShiftPattern.shiftPatternCode"
              label=""
              value={formik.values.selectWeekShiftPattern.shiftPatternCode}
              required={true}
              onChange={(val) => {
                formik.values.selectWeekShiftPattern.shiftPatternCode = val.target.value;
                formik.setFieldValue("selectWeekShiftPattern.shiftPatternCode", val.target.value);
                setInitialStaffDomain(formik.values);
              }}
              disable={
                formik.values.selectWeekShiftPattern.shiftPatternId !== "" ||
                0 < formik.values.selectWeekShiftPattern.attendType
              }
              maxLength={10}
            />
          </FlexBoxItem>
        </FlexBox>
        <FlexBox customStyle={styles.flexboxCustom}>
          <FlexBoxItem basis="250px">
            <FormLabel label="シフトパターン名" />
          </FlexBoxItem>
          <FlexBoxItem width="504px">
            <TextForm
              name="selectWeekShiftPattern.shiftPatternName"
              label=""
              value={
                formik.values.selectWeekShiftPattern.orgCode === "all"
                  ? `${formik.values.selectWeekShiftPattern.shiftPatternName}(共通)`
                  : formik.values.selectWeekShiftPattern.shiftPatternName
              }
              required={true}
              onChange={(val) => {
                formik.values.selectWeekShiftPattern.shiftPatternName = val.target.value;

                formik.setFieldValue("selectWeekShiftPattern.orgCode", "");
                formik.setFieldValue("selectWeekShiftPattern.shiftPatternName", val.target.value);
                setInitialStaffDomain(formik.values);
              }}
              disable={
                formik.values.selectWeekShiftPattern.shiftPatternId !== "" ||
                0 < formik.values.selectWeekShiftPattern.attendType
              }
            />
          </FlexBoxItem>
        </FlexBox>
        {/* <FlexBox customStyle={styles.flexboxCustom}>
            <FlexBoxItem basis="250px">
              <FormLabel label="短縮名" />
            </FlexBoxItem>
            <FlexBoxItem width="504px">
              <TextForm
                name="selectWeekShiftPattern.shortName"
                label=""
                value={formik.values.selectWeekShiftPattern.shortName}
                required={true}
                onChange={(val) => {
                  formik.values.selectWeekShiftPattern.shortName = val.target.value;
                  formik.setFieldValue('selectWeekShiftPattern.shortName', val.target.value);
                  setInitialStaffDomain(formik.values);
                }}
                appendLabel="（※5文字まで）"
                disable={formik.values.selectWeekShiftPattern.shiftPatternId !== '' || 0 < formik.values.selectWeekShiftPattern.attendType}
                maxLength={5}
              />
              <BodyText color="#ED5D5D">5文字まで</BodyText>
            </FlexBoxItem>
          </FlexBox> */}

        <StaffShiftPatternDetails
          shiftPatternDetailsList={formik.values.selectWeekShiftPattern.attendShiftPatternDetails}
          setShiftPatternDetailsList={(data: any) => {
            formik.values.selectWeekShiftPattern.attendShiftPatternDetails = data;
            formik.setFieldValue("selectWeekShiftPattern.attendShiftPatternDetails", data);
            setInitialStaffDomain(formik.values);
          }}
          attendBusinessOptions={businessOptions}
          listTimes={formik.values.roundTimeList || ["00"]}
          isDisableAll={
            !(
              formik.values.selectWeekShiftPattern.shiftPatternId === "" &&
              0 === formik.values.selectWeekShiftPattern.attendType
            )
          }
          remote={remote}
        />

        <FormSubmitArea customStyle={styles.formSubmitArea}>
          {contextObject.detailRole.editable === 1 && (
            <div style={{ marginRight: "12px", textAlign: "center" }}>
              <FlexBox customStyle={styles.centerBox}>
                <Button text={isEditMode ? "勤務パターンを登録" : "登録"} onClick={formik.handleSubmit} />
                {isEditMode && !isDisabledInfo ? (
                  <div style={{ marginLeft: "12px", textAlign: "center" }}>
                    <SecondaryButton
                      minWidth="150px"
                      text="削除"
                      onClick={() => {
                        setIsOpenDeleteModalStaff(true);

                        setDeleteTargetStaff(formik.values);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {!isEditMode && (
                  <div style={{ marginLeft: "12px" }}>
                    <Button
                      text="休暇情報の入力へ"
                      onClick={() => {
                        if (formik.values.empApplyDate && formik.values.employmentId) {
                          // Set salary apply day
                          formik.values.selectSalary.applyStartDate = formik.values.hireDate;
                          if (
                            (contextObject.detailRole.usabilityGeneralItem1 === 1 && tabItems.length <= 5) ||
                            (contextObject.detailRole.usabilityGeneralItem1 === 0 && tabItems.length <= 4)
                          ) {
                            setInitialStaffDomain(formik.values);

                            if (contextObject.detailRole.usabilityGeneralItem1 === 1) {
                              setTabItems([
                                {
                                  value: SelectType.staff,
                                  display: "スタッフ情報",
                                },
                                {
                                  value: SelectType.affiliation,
                                  display: "所属情報",
                                },
                                {
                                  value: SelectType.employment,
                                  display: "雇用情報",
                                },
                                {
                                  value: SelectType.laborCost,
                                  display: "人件費情報",
                                },
                                {
                                  value: SelectType.workPattern,
                                  display: "勤務パターン",
                                },
                                {
                                  value: SelectType.longTermCareandNurse,
                                  display: "看護・介護休暇情報",
                                },
                              ]);
                            } else {
                              setTabItems([
                                {
                                  value: SelectType.staff,
                                  display: "スタッフ情報",
                                },
                                {
                                  value: SelectType.affiliation,
                                  display: "所属情報",
                                },
                                {
                                  value: SelectType.employment,
                                  display: "雇用情報",
                                },
                                {
                                  value: SelectType.workPattern,
                                  display: "勤務パターン",
                                },
                                {
                                  value: SelectType.longTermCareandNurse,
                                  display: "看護・介護休暇情報",
                                },
                              ]);
                            }
                          }
                          setSelectType(SelectType.longTermCareandNurse);
                        } else {
                          formik.validateForm();
                        }
                      }}
                    />
                  </div>
                )}
              </FlexBox>
            </div>
          )}
          <Link to="/staffs">
            <Button text="スタッフ一覧に戻る" ghost={true} />
          </Link>
        </FormSubmitArea>
      </form>

      {/* 登録内容確認モーダル */}
      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={ActionType.CREATE}
      />

      <ConfirmActionModal
        open={isOpenDeleteModal}
        closeHandler={() => {
          closeDeleteModal();
          setIsOpenDeleteModal(false);
        }}
        onSubmit={onSubmitDeleteStaff}
        actionType={ActionType.DELETE}
        typeSubmit="reject"
      />

      <ConfirmActionModal
        open={isOpenDeleteModalStaff}
        closeHandler={closeDeleteModalStaff}
        onSubmit={onSubmitDeleteStaffForm}
        actionType={ActionType.DELETE}
        typeSubmit="reject"
      />
    </React.Fragment>
  );
};

export default WorkPatternInfoForm;
