/** @jsx jsx */
import React, { useCallback, useState, useEffect } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import { productColor, textFontSize, utilityColor, grayScale } from 'components/styles';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import { ICCardByStaffDomain } from "domain/master/icCard/listICCardByStaff";
import Modal from "components/molecules/Modal";
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import TextForm from 'components/molecules/TextForm';
import FormLabel from 'components/atoms/Form/FormLabel';
import { PrimaryButton } from "components/atoms/Button";
import useToastNotification from "hooks/useToastNotification";
import * as icCard from 'hooks/useICCard';
import { getDetailCardId } from 'api/iCCard';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { useDispatch } from 'react-redux';
import { uiAppBarActions } from 'modules/ui/appBar';
import { icCardActions } from 'modules/ui/icCard';
import { ICCardConnectButton } from "components/atoms/Button";
import { postRegisterStaffICCard, postDeleteStaffICCard } from 'api/iCCard';


const styles = {
  containerInput: css({
    boxSizing: "border-box",
    width: "100%",
    fontSize: "14px",
    fontFamily: "inherit",
    padding: "2px",
    border: "1px solid #DCDCDC",
    borderRadius: "3px",
    color: "#333333",
  }),
  item: css({
    margin: "10px",
    display: "inline-block",
    padding: "7px 25px 7px 7px",
    position: "relative",
    backgroundColor: "hsl(0,0%,90%)",
    borderRadius: "12px",
    background: "#e6f2ff",
    color: "#007BC3"
  }),
  closeIcon: css({
    position: "absolute",
    right: "3px",
    top: "53%",
    transform: "translateY(-50%)",
  }),
  addIcon: css({
    "&:hover,&:focus": css({
      opacity: '0.8'
    }),
    "svg": css({
      marginBottom: "4px",
    })
  }),
  containerFormAdd: css({
    backgroundColor: '#F8F8F8',
    border: '1px solid #999999',
    padding: '10px',
    width: '570px',
    height: 'auto'
  }),
  labelFormAdd: css({
    fontSize: '16px',
    fontWeight: 'bold'
  }),
  buttonConnect: css({
    height: '38px',
    lineHeight: '0',
    width: '50px',
    minWidth: '100px',
    fontWeight: 'bold',
    padding: '0',
    marginLeft: '35px'
})
};

interface ICCardListProps {
  listICCard: Array<string>,
  staffCode: string,
  customStyle?: SerializedStyles,
  staffCodeSelected: string, 
  setStaffCodeSelected: React.Dispatch<React.SetStateAction<string>>,
  openModalAddCode: boolean,
  setOpenModalAddCode: React.Dispatch<React.SetStateAction<boolean>>,
  setCardDelete: React.Dispatch<React.SetStateAction<string>>,
  setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  editable: number
}

const ICCardList: React.FC<ICCardListProps> = ({
  listICCard, staffCode, customStyle, setStaffCodeSelected, setOpenModalAddCode,
  setCardDelete, setIsOpenDeleteModal, editable
}) => {
  

  const handleAddCode = () => {
    setOpenModalAddCode(true);
    setStaffCodeSelected(staffCode);
  }
  
  const handleDeleteCode = (cardNumber: string) => {
    setStaffCodeSelected(staffCode);
    setCardDelete(cardNumber);
    setIsOpenDeleteModal(true);
  }

  return (
    <React.Fragment>
      <div css={css([styles.containerInput, customStyle])}>
        {listICCard.map((cardNumber) => (
          <div css={styles.item} key={cardNumber}>
            <div>
              {cardNumber}
            </div>
            <span onClick={() => handleDeleteCode(cardNumber)}>
              <Icon type="close" color={productColor.primary} customStyle={styles.closeIcon} />
            </span>
          </div>
        ))}
            <div css={css({
              display: 'inline-block',
              margin: '10px'
            })}>
              {editable === 1 ? (
                <span onClick={() => handleAddCode()}>
                  <Icon size="23px" type={"addCircle"} color="" verticalAlign="middle" customStyle={styles.addIcon}/>
                </span>
              ): <div style={{height: '23px'}}></div>}

            </div>
      </div>
    </React.Fragment>
  );
};

export default ICCardList;
