import React, { useEffect } from "react";
import StampAccordion from "./StampAccordion";
import HolidayAccordion from "./HolidayAccordion";
import ApprovedTransferDomain from "../domain/approvedTransferDomain";
import ApprovedStampDomain from "../domain/approvedStampDomain";
import ApprovedHolidayDomain from "../domain/approvedHolidayDomain";
import ApprovedOvertimeDomain from "../domain/approvedOvertimeDomain";
import ApprovedHolidayWorkDomain from "../domain/approvedHolidayWorkDomain";
import Approved36AgreementDomain from "../domain/approved36AgreementDomain"; 
import ApprovedWorkScheduleChangeDomain from "../domain/approvedWorkScheduleChange"; 
import OvertimeAccordion from "./OvertimeAccordion";
import HolidayWorkAccordion from "./HolidayWorkAccordion";
import TransferAccordion from "./TransferAccordion";
import Agreement36Accordion from "./Agreement36Accordion";
import WorkScheduleChangeApplicationAccordion from "./WorkScheduleChangeApplicationAccordion";

const ApprovedTab: React.FC<{
  stampList: ApprovedStampDomain[];
  holidayList: ApprovedHolidayDomain[];
  overtimeList: ApprovedOvertimeDomain[];
  holidayWorkList: ApprovedHolidayWorkDomain[];
  transferList: ApprovedTransferDomain[];
  agreement36List: Approved36AgreementDomain[];
  workScheduleList: ApprovedWorkScheduleChangeDomain[];
}> = ({
  stampList,
  holidayList,
  overtimeList,
  holidayWorkList,
  transferList,
  agreement36List,
  workScheduleList
}) => {

  useEffect(() => {
    console.log('ApprovedTab')
  }, [
    stampList,
    holidayList,
    overtimeList,
    holidayWorkList,
    transferList
  ]);
  
  return (
    <div>
      <StampAccordion list={stampList} />

      <HolidayAccordion list={holidayList} />

      <OvertimeAccordion list={overtimeList} />

      <HolidayWorkAccordion list={holidayWorkList} />

      <TransferAccordion list={transferList} />

      <Agreement36Accordion list={agreement36List} />

      <WorkScheduleChangeApplicationAccordion list={workScheduleList} />

    </div>
  );
};

export default ApprovedTab;
