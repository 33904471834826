/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Table from "components/molecules/Table";
import { isIPad13 } from "react-device-detect";
import { IDailyShift } from "components/pages/master/shift/type";

const TableTotalValueDailyShift: React.FC<{ useSales: boolean; dailyShift: IDailyShift }> = ({
  useSales,
  dailyShift,
}) => {
  // const mainTable = useRef<any>(Object());

  // const prefix = "monthlyShift";
  // const highlightAction = sessionStorage.getItem("highlightAction") || false;
  // const highlightIndex = sessionStorage.getItem("highlightIndex") || "";
  // const elmntLine1 = document.getElementById(prefix + "_line1_" + highlightIndex);
  // const elmntLine2 = document.getElementById(prefix + "_line2_" + highlightIndex);
  // const elmntLine3 = document.getElementById(prefix + "_line3_" + highlightIndex);

  // useEffect(() => {
  //   if (highlightAction === "true") {
  //     if (elmntLine1 && elmntLine2 && elmntLine3) {
  //       elmntLine1.classList.add("hightlight");
  //       elmntLine2.classList.add("hightlight");
  //       elmntLine3.classList.add("hightlight");
  //       elmntLine3.scrollIntoView({ block: "center" });
  //       setTimeout(() => {
  //         elmntLine1.classList.remove("hightlight");
  //         elmntLine2.classList.remove("hightlight");
  //         elmntLine3.classList.remove("hightlight");
  //         sessionStorage.setItem("highlightAction", "false");
  //         sessionStorage.setItem("highlightIndex", "");
  //       }, 3000);
  //     }
  //   }
  // }, [elmntLine1, elmntLine2, elmntLine3, highlightAction]);

  return (
    <div
      id="main-table"
      // ref={mainTable}
      style={{
        overflowX: "auto",
        maxHeight: isIPad13 ? "auto" : "93vh",
        borderLeft: "1px solid #ccc",
      }}
    >
      <Table
        css={css({
          tableLayout: "auto",
          td: {
            textAlign: "center",
            lineHeight: "11px",
          },
          th: {
            minWidth: "65px", // セルの最小幅を設定
          },
          border: "none",
        })}
      >
        <colgroup>
          <col style={{ width: "65px" }} />
        </colgroup>

        <thead>
          <tr style={{ lineHeight: 0 }}>
            <Table.HeaderCell className="border-l-none" colSpan={useSales ? 11 : 7}>
              当日計
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={useSales ? 7 : 3}>累計</Table.HeaderCell>
          </tr>
          <tr style={{ lineHeight: 0 }}>
            <Table.HeaderCell rowSpan={2} className="border-t-none border-l-none"></Table.HeaderCell>
            {useSales && (
              <Table.HeaderCell
                rowSpan={2}
                className="border-t-none border-l-none"
                customStyle={css({ padding: "14px 0" })}
              >
                売上
              </Table.HeaderCell>
            )}

            <Table.HeaderCell
              colSpan={useSales ? 3 : 2}
              className="border-t-none border-l-none"
              customStyle={css({ padding: "14px 0" })}
            >
              正社員
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan={useSales ? 3 : 2}
              className="border-t-none border-l-none"
              customStyle={css({ padding: "14px 0" })}
            >
              アルバイト他
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan={useSales ? 3 : 2}
              className="border-t-none border-l-none"
              customStyle={css({ padding: "14px 0" })}
            >
              合計
            </Table.HeaderCell>
            {useSales && (
              <Table.HeaderCell
                rowSpan={2}
                className="border-t-none border-l-none"
                customStyle={css({ padding: "14px 0" })}
              >
                売上
              </Table.HeaderCell>
            )}

            <Table.HeaderCell
              colSpan={useSales ? 2 : 1}
              className="border-t-none border-l-none"
              customStyle={css({ padding: "14px 0" })}
            >
              正社員
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan={useSales ? 2 : 1}
              className="border-t-none border-l-none"
              customStyle={css({ padding: "14px 0" })}
            >
              アルバイト他
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan={useSales ? 2 : 1}
              className="border-t-none border-l-none"
              customStyle={css({ padding: "14px 0" })}
            >
              合計
            </Table.HeaderCell>
          </tr>
          <tr style={{ lineHeight: 0 }}>
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>勤務時間</Table.HeaderCell>
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
            {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>勤務時間</Table.HeaderCell>
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
            {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>勤務時間</Table.HeaderCell>
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
            {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}

            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
            {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
            {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
            <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費</Table.HeaderCell>
            {useSales && <Table.HeaderCell customStyle={css({ padding: "14px 0" })}>人件費率</Table.HeaderCell>}
          </tr>
        </thead>
        <tbody>
          {useSales && (
            <tr>
              <Table.Cell className="border-l-none">予算</Table.Cell>

              <Table.Cell rowSpan={2}>{dailyShift?.salesInfo?.salesBudgetShopDaily || 0}</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostBudgetShopDailyEmployee || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopDailyEmployee || "0.00%"}</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostBudgetShopDailyPart || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopDailyPart || "0.00%"}</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostBudgetShopDaily || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopDaily || "0.00%"}</Table.Cell>

              <Table.Cell rowSpan={2}>{dailyShift?.salesInfo?.salesBudgetShopMonthly || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostBudgetShopMonthlyEmployee || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopMonthlyEmployee || "0.00%"}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostBudgetShopMonthlyPart || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopMonthlyPart || "0.00%"}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostBudgetShopMonthly || 0}</Table.Cell>
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateBudgetShopMonthly || "0.00%"}</Table.Cell>
            </tr>
          )}
          {/* RowId2 */}
          <tr>
            <Table.Cell className="border-l-none">シフト</Table.Cell>

            <Table.Cell>{dailyShift?.workTimeInfo?.shiftEmployee || "0:00"}</Table.Cell>
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostShiftShopDailyEmployee || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateShiftShopDailyEmployee || "0.00%"}</Table.Cell>
            )}
            <Table.Cell>{dailyShift?.workTimeInfo?.shiftPart || "0:00"}</Table.Cell>
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostShiftShopDailyPart || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateShiftShopDailyPart || "0.00%"}</Table.Cell>
            )}

            <Table.Cell>{dailyShift?.workTimeInfo?.shift || "0:00"}</Table.Cell>
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostShiftShopDaily || 0}</Table.Cell>
            {useSales && <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateShiftShopDaily || "0.00%"}</Table.Cell>}

            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostShiftShopMonthlyEmployee || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateShiftShopMonthlyEmployee || "0.00%"}</Table.Cell>
            )}

            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostShiftShopMonthlyPart || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateShiftShopMonthlyPart || "0.00%"}</Table.Cell>
            )}

            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostShiftShopMonthly || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateShiftShopMonthly || "0.00%"}</Table.Cell>
            )}
          </tr>

          {/* RowId3 */}
          <tr>
            <Table.Cell className="border-l-none">実績</Table.Cell>

            {useSales && <Table.Cell>{dailyShift?.salesInfo?.netSalesShopDaily || 0}</Table.Cell>}

            <Table.Cell>{dailyShift?.workTimeInfo?.achievementEmployee || "0:00"}</Table.Cell>
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostAchievementShopDailyEmployee || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>
                {dailyShift?.laborCostInfo?.laborCostRateAchievementShopDailyEmployee || "0.00%"}
              </Table.Cell>
            )}
            <Table.Cell>{dailyShift?.workTimeInfo?.achievementPart || "0:00"}</Table.Cell>
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostAchievementShopDailyPart || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopDailyPart || "0.00%"}</Table.Cell>
            )}
            <Table.Cell>{dailyShift?.workTimeInfo?.achievement || "0:00"}</Table.Cell>
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostAchievementShopDaily || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopDaily || "0.00%"}</Table.Cell>
            )}

            {useSales && <Table.Cell>{dailyShift?.salesInfo?.netSalesShopMonthly || 0}</Table.Cell>}
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostAchievementShopMonthlyEmployee || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>
                {dailyShift?.laborCostInfo?.laborCostRateAchievementShopMonthlyEmployee || "0.00%"}
              </Table.Cell>
            )}
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostAchievementShopMonthlyPart || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopMonthlyPart || "0.00%"}</Table.Cell>
            )}
            <Table.Cell>{dailyShift?.laborCostInfo?.laborCostAchievementShopMonthly || 0}</Table.Cell>
            {useSales && (
              <Table.Cell>{dailyShift?.laborCostInfo?.laborCostRateAchievementShopMonthly || "0.00%"}</Table.Cell>
            )}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableTotalValueDailyShift;
