/** @jsx jsx */
import React from 'react';

import productColor, { utilityColor, customButtonColor } from 'components/styles';
import { ButtonProps } from './BaseButton';
import { css, jsx } from '@emotion/core';

interface ActionButtonProps extends ButtonProps {
  action: ActionButton;
  width?: string;
  marginLeft?: string;
}

export enum ActionButton {
  EDIT = 0,
  DELETE = 1,
  COPY = 2,
  Approve = 3,
  Comfirm = 4,
}

const hoverStyle = (color: string) => css({
  ':hover': {
    backgroundColor: color + ' !important',
    color: '#3145D1' + ' !important'
  },
  ':focus': {
    backgroundColor: color + ' !important',
    color: '#3145D1' + ' !important'
  },
});

const ButtonText: React.FC<ActionButtonProps> = ({
  children, text, onClick, disabled, width = "", marginLeft
}) => {
  let bgColorHover = '';

  return (
    <button
      type="button"
      onClick={() => (onClick ? onClick() : () => { })}
      disabled={disabled}
      style={{
        backgroundColor: 'transparent',
        color: '#007BC3',
        borderRadius: '3px',
        height: '24px',
        border: 'none',
        cursor: disabled ? 'not-allowed':'pointer',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        marginLeft: marginLeft? marginLeft : ""
      }}
      css={[hoverStyle(bgColorHover), width ? { width: width } : {}]}
    >
      {children || text}
    </button>
  );
}

export default ButtonText;
