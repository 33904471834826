export const optionsRadio = {
    useOption: [
        { label: '使用可', value: '1'},
        { label: '使用不可', value: '0'},
    ],
    defaultOption: [
        { label: '使用可', value: '1'},
    ],
    editableOptions: [
        { label: '編集可', value: '1'},
        { label: '参照のみ', value: '0'},
    ],
    scopeOptionType1: [
        { label: '全組織', value: '3'},
        { label: '所属・兼務', value: '2'},
        { label: '所属', value: '1'},
    ],
    scopeOptionType2: [
        { label: '全組織', value: '3'},
        { label: '所属・兼務', value: '2'},
        { label: '所属', value: '1'},
        { label: 'ログインユーザのみ', value: '0'},
    ],
}


//0:ログインユーザのみ 1:所属 2:所属と兼務 3:全組織