import { useState, useCallback, useEffect, Dispatch, SetStateAction } from "react";
import useToastNotification from "hooks/useToastNotification";
import { OptionType } from "components/atoms/Select";
import { HopeShiftDomain } from "domain/master/general/hopeShift";

import ShiftPatternDomain, { AttendShiftPatternDetails, ShiftPattern } from "domain/master/attend/shiftPattern";
import { getAvailableShiftPattern } from "api/shiftPattern";
import { postHopeShifts } from "api/hopeShift";
import moment from "moment";
import { ScheduleStatus, ScheduleStatusColor, getOperationInfo, getStaffWorkList } from "api/schedule";
import { getAttendSettingOrganization, getTimeSetting } from "api/timeSetting";
import OrganizationSettingDomain from "domain/master/labor/organizationSetting";
import TimeSettingDomain from "domain/master/labor/timeSetting";
import { getBusinessDataByEmploymentId } from "api/businessMaster";
import { Event } from "react-big-calendar";

export type ShiftMonthCalendarEvent = Event & {
  fixStatus: number;
  shiftPaternId: string;
  targetDate: string;
  color: string;
  hopeShift?: HopeShiftDomain;
};

export type TimeSettingType = {
  startHour: number;
  endHour: number;
};

export type ShiftMonthCalendarContext = {
  events: Array<ShiftMonthCalendarEvent>;
  setEvents: (events: Array<ShiftMonthCalendarEvent>) => void;
};

/**
 * Providerへ渡すContextの初期値
 */
export const useInitialShiftMonthCalendarContext = (): ShiftMonthCalendarContext => {
  const [events, setEvents] = useState<Array<ShiftMonthCalendarEvent>>([]);

  return {
    events,
    setEvents,
  };
};

/**
 * Get operation infor
 */
const useOperationInfo = (date: Date) => {
  const [operationInfo, setOperationInfo] = useState<any>("");
  useEffect(() => {
    if (!date) {
      return;
    }

    const fetchOperationInfo = async () => {
      const response = await getOperationInfo(date);
      setOperationInfo(response);
    };
    fetchOperationInfo();
  }, [date.getMonth()]);
  return {
    operationInfo,
  };
};

/**
 * Get available shift pattern
 */
const useShiftPatternOptions = (employmentId: string, orgCode: string) => {
  const [shiftPatternOptions, setShiftPatternOptions] = useState<Array<OptionType>>([]);
  const [shiftPatternList, setShiftPatternList] = useState<Array<ShiftPatternDomain>>([]);
  useEffect(() => {
    if (employmentId && orgCode) {
      getAvailableShiftPattern(orgCode)
        .then((shiftPatterns) => {
          // Set up shift pattern list
          setShiftPatternList(shiftPatterns.map((item: ShiftPattern) => new ShiftPatternDomain(item)));
          // Set up shift pattern options
          const patternsList = shiftPatterns.map((shiftPattern: ShiftPattern) => {
            // Get end time
            const shiftDetailEnd: AttendShiftPatternDetails =
              shiftPattern.attendShiftPatternDetails[shiftPattern.attendShiftPatternDetails.length - 1];
            const [hours, minutes] = shiftDetailEnd.endTime.split(":");
            // Get start time
            const shiftDetailStart: AttendShiftPatternDetails = shiftPattern.attendShiftPatternDetails[0];
            const [h, m] = shiftDetailStart.startTime.split(":");

            return {
              value: shiftPattern.shiftPatternId,
              label: `${shiftPattern.shiftPatternNameDisp}`,
              label2: `${shiftPattern.shiftPatternNameDisp} (${h}:${m} 〜 ${hours}:${minutes})`,
            };
          });

          patternsList.unshift(
            {
              value: "CANWORK",
              label: "出勤できます",
              label2: "出勤できます",
            },
            {
              value: "CANTWORK",
              label: "出勤できません",
              label2: "出勤できません",
            },
          );

          setShiftPatternOptions(patternsList);
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            setShiftPatternOptions([
              {
                value: "CANWORK",
                label: "出勤できます",
                label2: "出勤できます",
              },
              {
                value: "CANTWORK",
                label: "出勤できません",
                label2: "出勤できません",
              },
            ]);
          }
        });
    }
  }, [employmentId, orgCode]);
  return { shiftPatternList, shiftPatternOptions };
};

/**
 * シフト表示開始時間・シフト表示終了時間を取得
 * @param {string} orgCode 組織コード
 * @returns {TimeSettingType} シフト表示開始時間・シフト表示終了時間
 */
const useTimeSetting = (orgCode: string) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  const [startHour, setStartHour] = useState(0);
  const [endHour, setEndHour] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [resOrganizationSetting, resAttendSetting] = await Promise.all([
          getAttendSettingOrganization(companyCode, orgCode),
          getTimeSetting(companyCode),
        ]);
        const organizationSettingDomain = new OrganizationSettingDomain(resOrganizationSetting);
        const timeSettingDomain = new TimeSettingDomain(resAttendSetting);
        const startHour = organizationSettingDomain?.dispStartHour ?? timeSettingDomain?.dispStartHour ?? 0;
        const addHour = organizationSettingDomain?.dispHour ?? timeSettingDomain?.dispHour ?? 0;
        setStartHour(startHour);
        setEndHour(
          moment()
            .hour(startHour)
            .add(addHour, "hour")
            .hour(),
        );
      } catch (error) {
        console.error(`勤怠設定マスタ および 勤怠組織設定マスタの取得に失敗しました`);
        setStartHour(0);
        setEndHour(0);
      }
    };

    fetchData();
  }, [orgCode]);
  return { startHour, endHour } as TimeSettingType;
};

/**
 * 業務の選択ボックス用データを取得
 */
const useBusinessMasterByEmploymentIdOptions = (employmentId: string): Array<OptionType> => {
  const [businessOptions, setBusinessOptions] = useState<Array<OptionType>>([]);
  useEffect(() => {
    if (employmentId) {
      getBusinessDataByEmploymentId(employmentId).then((businesses) => {
        setBusinessOptions(
          businesses.map((business: any) => ({
            value: business.businessId,
            label: business.businessName,
          })),
        );
      });
    }
  }, [employmentId]);
  return businessOptions;
};

/**
 * 月データを取得
 */
export const useMonthCalendarEvents = (
  view: string,
  date: Date,
  setEvents: (events: Array<ShiftMonthCalendarEvent>) => void,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setHopeShiftList: Dispatch<SetStateAction<HopeShiftDomain[]>>,
) => {
  useEffect(() => {
    setIsLoading(true);
    getStaffWorkList(date)
      .then((monthlyShift: Array<any>) => {
        setIsLoading(false);
        if (monthlyShift.length > 0) {
          reloadCalendar(setIsLoading, monthlyShift, setEvents, setHopeShiftList);
        } else {
          reloadCalendar(setIsLoading, monthlyShift, setEvents, setHopeShiftList);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [date.getMonth(), setEvents, view]);
};

const reloadCalendar = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  shiftList: Array<any>,
  setEvents: (events: Array<ShiftMonthCalendarEvent>) => void,
  setHopeShiftList: Dispatch<SetStateAction<HopeShiftDomain[]>>,
) => {
  setIsLoading(false);

  setEvents(
    shiftList.map((shift) => {
      let hopeShiftPatternType = 0;
      if (shift.fixStatus === ScheduleStatus.DAY_OFF) {
        hopeShiftPatternType = 2;
      } else if (shift.fixStatus !== ScheduleStatus.DAY_OFF) {
        if (shift.shiftPatternId) {
          hopeShiftPatternType = 3;
        } else if (shift.startTime) {
          hopeShiftPatternType = 1;
        }
      }

      let titleStr: string = "";
      if (shift.fixStatus === ScheduleStatus.DAY_OFF) {
        titleStr = shift.holidayName;
      } else if (shift.startTime && shift.endTime) {
        titleStr = `${moment(shift.startTime).format("HH:mm")} - ${moment(shift.endTime).format("HH:mm")}`;
      }

      // Special case
      let customFixStatus: number = shift.fixStatus;
      if (
        shift.fixStatus === ScheduleStatus.DAY_OFF &&
        shift.holidayName === "公休" &&
        (moment(shift.startTime)
          .toDate()
          .getHours() !==
          moment(shift.endTime)
            .toDate()
            .getHours() ||
          moment(shift.startTime)
            .toDate()
            .getMinutes() !==
            moment(shift.endTime)
              .toDate()
              .getMinutes())
      ) {
        titleStr = `休日出勤  ${moment(shift.startTime).format("HH:mm")} - ${moment(shift.endTime).format("HH:mm")}`;
        customFixStatus = ScheduleStatus.ACTUAL;
      }
      // Config hope shift
      const hopeShift: HopeShiftDomain = new HopeShiftDomain({
        createUser: sessionStorage.getItem("loginUser.staffCode") || "",
        updateUser: sessionStorage.getItem("loginUser.staffCode") || "",
        hopeShiftId: "",
        orgCode: shift.orgCode || sessionStorage.getItem("loginUser.orgCode") || "",
        orgName: shift.orgName || sessionStorage.getItem("loginUser.orgName") || "",
        staffCode: shift.staffCode || sessionStorage.getItem("loginUser.staffCode") || "",
        targetDate: shift.targetDate,
        hopeShiftPatternType,
        shiftPatternId: shift.shiftPatternId,
        startTime: shift.startTime,
        endTime: shift.endTime,
        note: shift.note,
        fixStatus: customFixStatus,
        isEditable: shift.isEditable !== 0,
        distinctionHoliday: shift.distinctionHoliday,
        useHoliday: shift.useHoliday,
      });
      // Config event
      const event: ShiftMonthCalendarEvent = {
        allDay: false,
        title: titleStr,
        targetDate: shift.targetDate,
        color: ScheduleStatusColor[customFixStatus],
        start: shift.startTime ? moment(shift.startTime).toDate() : undefined,
        end: shift.endTime ? moment(shift.endTime).toDate() : undefined,
        shiftPaternId: shift.shiftPatternId,
        fixStatus: customFixStatus,
        hopeShift,
      };
      return event;
    }),
  );
  const hopeShiftLst = shiftList.map((shift) => {
    let hopeShiftPatternType = 0;
    if (shift.fixStatus === ScheduleStatus.DAY_OFF) {
      hopeShiftPatternType = 2;
    } else if (shift.fixStatus !== ScheduleStatus.DAY_OFF) {
      if (shift.shiftPatternId) {
        hopeShiftPatternType = 3;
      } else if (shift.startTime) {
        hopeShiftPatternType = 1;
      }
    }

    // Special case work on DAY OFF
    let customFixStatus: number = shift.fixStatus;
    if (
      shift.fixStatus === ScheduleStatus.DAY_OFF &&
      shift.holidayName === "公休" &&
      (moment(shift.startTime)
        .toDate()
        .getHours() !==
        moment(shift.endTime)
          .toDate()
          .getHours() ||
        moment(shift.startTime)
          .toDate()
          .getMinutes() !==
          moment(shift.endTime)
            .toDate()
            .getMinutes())
    ) {
      customFixStatus = ScheduleStatus.ACTUAL;
    }
    const hopeShift: HopeShiftDomain = new HopeShiftDomain({
      createUser: sessionStorage.getItem("loginUser.staffCode") || "",
      updateUser: sessionStorage.getItem("loginUser.staffCode") || "",
      hopeShiftId: "",
      orgCode: shift.orgCode || sessionStorage.getItem("loginUser.orgCode") || "",
      orgName: shift.orgName || sessionStorage.getItem("loginUser.orgName") || "",
      staffCode: shift.staffCode || sessionStorage.getItem("loginUser.staffCode") || "",
      targetDate: shift.targetDate,
      hopeShiftPatternType,
      shiftPatternId: shift.shiftPatternId,
      startTime: shift.startTime,
      endTime: shift.endTime,
      note: shift.note,
      fixStatus: customFixStatus,
      isEditable: shift.isEditable !== 0,
      holidayName: shift.fixStatus === ScheduleStatus.DAY_OFF ? shift.holidayName : undefined,
      employmentId: shift.employmentId,
      // businessId: shift.businessId ? shift.businessId : "",// TODO : 仮決め
      distinctionHoliday: shift.distinctionHoliday,
      useHoliday: shift.useHoliday,
    });
    return hopeShift;
  });
  sessionStorage.setItem("hopeShiftList", JSON.stringify(hopeShiftLst));
  setHopeShiftList(hopeShiftLst);
};

export const useSchedulePage = () => {
  const context = useInitialShiftMonthCalendarContext();
  const { events, setEvents } = context;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { successNotification, errorNotification } = useToastNotification();

  const [orgCode, setOrgCode] = useState("");
  const [employmentId, setEmploymentId] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [flgCanWork, setFlgCanWork] = useState(false);
  const [date, setDate] = useState(moment().toDate());
  const [datePickerValue, setDatePickerValue] = useState(moment().toDate());
  const [view, setView] = useState<"month">("month");
  const [note, setNote] = useState("");

  const [hopeShiftList, setHopeShiftList] = useState<HopeShiftDomain[]>([]);
  const [selectedHopeShift, setSelectedHopeShift] = useState<HopeShiftDomain | undefined>(undefined);

  const { operationInfo } = useOperationInfo(datePickerValue);
  const { shiftPatternList, shiftPatternOptions } = useShiftPatternOptions(employmentId, orgCode);

  /**
   * シフト表示開始時間・シフト表示終了時間
   */
  const timeSettings = useTimeSetting(orgCode);
  const [startTimeGlobal, setStartTimeGlobal] = useState(
    moment()
      // .hour(timeSettings.startHour)
      .hour(0)
      .minutes(0),
  );
  const [endTimeGlobal, setEndTimeGlobal] = useState(
    moment()
      // .hour(timeSettings.endHour)
      .hour(0)
      .minutes(0),
  );

  const businessOptions = useBusinessMasterByEmploymentIdOptions(employmentId);

  useMonthCalendarEvents(view, datePickerValue, setEvents, setIsLoading, setHopeShiftList);

  useEffect(() => {
    const selectedHopeShiftFromList = hopeShiftList.filter(
      (item) => item.targetDate === moment(date).format("YYYY-MM-DD") && item.shiftPatternId !== null,
    )[0];
    if (selectedHopeShiftFromList) {
      setEmploymentId(selectedHopeShiftFromList.employmentId!);
      setOrgCode(selectedHopeShiftFromList.orgCode!);
      setSelectedHopeShift(selectedHopeShiftFromList);
      if (selectedHopeShiftFromList.shiftPatternId && shiftPatternList) {
        const selectedShiftPattern = shiftPatternList.find(
          (item) => item.shiftPatternId === selectedHopeShiftFromList.shiftPatternId,
        );
        if (selectedShiftPattern?.attendShiftPatternDetails) {
          setBusinessId(selectedShiftPattern?.attendShiftPatternDetails[0].businessId);
          //
          const [hours, minutes, seconds] = selectedShiftPattern?.attendShiftPatternDetails[0].startTime.split(":");
          const startTime = new Date(selectedHopeShiftFromList!.targetDate);
          if (selectedShiftPattern?.attendShiftPatternDetails[0].isStartTimeNextDay) {
            startTime.setDate(startTime.getDate() + 1);
          }
          startTime.setHours(Number(hours));
          startTime.setMinutes(Number(minutes));
          startTime.setSeconds(Number(seconds));

          //
          const [h, m, s] = selectedShiftPattern?.attendShiftPatternDetails[
            selectedShiftPattern.attendShiftPatternDetails.length - 1
          ].endTime.split(":");
          const endTime = new Date(selectedHopeShiftFromList!.targetDate);
          if (selectedShiftPattern?.attendShiftPatternDetails[0].isStartTimeNextDay) {
            endTime.setDate(endTime.getDate() + 1);
          }
          endTime.setHours(Number(h));
          endTime.setMinutes(Number(m));
          endTime.setSeconds(Number(s));

          setStartTimeGlobal(moment(startTime));
          setEndTimeGlobal(moment(endTime));
          setNote(selectedHopeShiftFromList.note ?? "");
        }
      } else {
        // TODO: 仮決め
        // if (!selectedHopeShiftFromList.businessId) {
        //   if (businessOptions.length > 1) {
        //     setBusinessId(businessOptions[0].value);
        //   } else {
        //     setBusinessId("");
        //   }
        // } else {
        //   setBusinessId(selectedHopeShiftFromList.businessId);
        // }
        if (selectedHopeShiftFromList.hopeShiftPatternType === 1) {
          setFlgCanWork(true);
        } else {
          setFlgCanWork(false);
        }
        //
        if (moment(date).format("YY-MM-DD") === startTimeGlobal.format("YY-MM-DD")) {
          if (selectedHopeShiftFromList.startTime) {
            setStartTimeGlobal(moment(selectedHopeShiftFromList.startTime));
          } else {
            setStartTimeGlobal(
              moment(date)
                // .hour(timeSettings.startHour)
                .hour(0)
                .minutes(0),
            );
          }

          if (selectedHopeShiftFromList.endTime) {
            setEndTimeGlobal(moment(selectedHopeShiftFromList.endTime));
          } else {
            setEndTimeGlobal(
              moment(date)
                // .hour(timeSettings.endHour)
                .hour(0)
                .minutes(0),
            );
          }
          //
        } else {
          if (selectedHopeShiftFromList.startTime) {
            setStartTimeGlobal(moment(selectedHopeShiftFromList.startTime));
          } else {
            setStartTimeGlobal(
              moment(date)
                // .hour(timeSettings.startHour)
                .hour(0)
                .minutes(0),
            );
          }
          if (selectedHopeShiftFromList.endTime) {
            setEndTimeGlobal(moment(selectedHopeShiftFromList.endTime));
          } else {
            setEndTimeGlobal(
              moment(date)
                // .hour(timeSettings.endHour)
                .hour(0)
                .minutes(0),
            );
          }
        }

        setNote(
          selectedHopeShiftFromList && selectedHopeShiftFromList.note !== null ? selectedHopeShiftFromList.note : "",
        );
      }
    }
  }, [date, hopeShiftList]);

  useEffect(() => {
    const selectedHopeShiftFromList = hopeShiftList.filter(
      (item) => item.targetDate === moment(date).format("YYYY-MM-DD") && item.shiftPatternId !== null,
    )[0];
    if (selectedHopeShiftFromList?.holidayName === "出勤不可") {
      setStartTimeGlobal(
        moment(date)
          // .hour(timeSettings.startHour)
          .hour(0)
          .minutes(0),
      );
      setEndTimeGlobal(
        moment(date)
          // .hour(timeSettings.endHour)
          .hour(0)
          .minutes(0),
      );
      setBusinessId("");
      setNote(
        selectedHopeShiftFromList && selectedHopeShiftFromList.note !== null ? selectedHopeShiftFromList.note : "",
      );
      setFlgCanWork(false);
    }
  }, []);

  useEffect(() => {
    if (selectedHopeShift && selectedHopeShift.fixStatus === ScheduleStatus.UNSUBMITED) {
      // Reset hope shift list when edit selected shift pattern
      const indexEditedHopeShift = hopeShiftList.findIndex((item) => item.targetDate === selectedHopeShift.targetDate);

      setHopeShiftList(
        hopeShiftList.map((shift, index) => {
          if (index === indexEditedHopeShift) {
            return selectedHopeShift;
          }
          return shift;
        }),
      );

      // Reset view of calender (events) when change shift pattern of selected hope shift
      let titleStr: string = "";
      // Check case 出勤できません』
      if (selectedHopeShift.hopeShiftPatternType === 2) {
        titleStr = "出勤できません";
      } else if (selectedHopeShift.hopeShiftPatternType === 1) {
        titleStr = "出勤できます";
      } else if (selectedHopeShift.startTime && selectedHopeShift.endTime) {
        titleStr = `${moment(selectedHopeShift.startTime).format("HH:mm")} - ${moment(
          selectedHopeShift.endTime,
        ).format("HH:mm")}`;
      }
      const selectedTargetDate: Date = new Date(selectedHopeShift.targetDate);
      const editedEvent: ShiftMonthCalendarEvent = {
        allDay: false,
        title: titleStr,
        targetDate: selectedHopeShift.targetDate,
        color: ScheduleStatusColor[selectedHopeShift.fixStatus],
        start: selectedHopeShift.startTime
          ? moment(selectedHopeShift.startTime).toDate()
          : new Date(
              selectedTargetDate.getFullYear(),
              selectedTargetDate.getMonth(),
              selectedTargetDate.getDate(),
              0,
              0,
              0,
              0,
            ),
        end: selectedHopeShift.endTime
          ? moment(selectedHopeShift.endTime).toDate()
          : new Date(
              selectedTargetDate.getFullYear(),
              selectedTargetDate.getMonth(),
              selectedTargetDate.getDate() + 1,
              0,
              0,
              0,
              0,
            ),
        shiftPaternId: selectedHopeShift.shiftPatternId,
        fixStatus: selectedHopeShift.fixStatus,
        hopeShift: selectedHopeShift,
      };
      const indexEditedEvent = events.findIndex(
        (item) => item.targetDate === selectedHopeShift.targetDate && item.shiftPaternId !== null,
      );
      const newEvents = events.map((item, index) => {
        if (index === indexEditedEvent) {
          return editedEvent;
        }
        return item;
      });
      setEvents(newEvents);
    }
  }, [selectedHopeShift]);

  const onSubmitHopeShifts = useCallback(() => {
    const changedShift = hopeShiftList.filter((item) => item.fixStatus === 9);
    if (changedShift.length > 0) {
      setIsLoading(true);

      postHopeShifts(date, changedShift)
        .then((response: Array<any>) => {
          reloadCalendar(setIsLoading, response, setEvents, setHopeShiftList);

          successNotification("登録しました。");
          // 削除後にデータを再取得
          setIsLoading(false);
          setDate(date);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.data && error.response.data.errors) {
            let msgError = "";
            error.response.data.errors.map((item: { defaultMessage: string }) => {
              msgError += `${item.defaultMessage} \n`;
              return msgError;
            });
            errorNotification(msgError);
          } else {
            errorNotification("サーバー側でエラーが発生しました。");
          }
        });
    } else {
      errorNotification("提出可能希望シフトがありません。");
    }
  }, [errorNotification, successNotification]);

  return {
    state: {
      datePickerValue,
      date,
      view,
      context,
      events,
      isLoading,
      shiftPatternList,
      selectedHopeShift,
      hopeShiftList,
      operationInfo,
      shiftPatternOptions,
      businessOptions,
      businessId,
      flgCanWork,
      startTimeGlobal,
      endTimeGlobal,
      note,
      timeSettings,
    },
    setter: {
      setDatePickerValue,
      setDate,
      setView,
      setSelectedHopeShift,
      onSubmitHopeShifts,
      setFlgCanWork,
      setBusinessId,
      setStartTimeGlobal,
      setEndTimeGlobal,
      setNote,
    },
  };
};
