/** @jsx jsx */
import React, { useState, useContext } from "react";

import SelectForm from "components/molecules/SelectForm";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import HourInput from "components/atoms/Form/HourInput";
import FlexBox from "components/atoms/FlexBox";
import { useHistory } from "react-router-dom";
import { grayScale, productColor, textColor } from "components/styles";
import DataTable from "components/organismos/DataTable/DataTable";
import { css, jsx } from "@emotion/core";
import Accordion from "components/molecules/Accordion";
import SpecialHolidayStatusDomain from "domain/master/holidayManagement/specialHolidayStatus";
import { Column } from "react-table";
import Modal from "components/molecules/Modal";
import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelTextLayout from "components/atoms/Form/VerticalLabelTextLayout";
import FormContentFilter from "components/atoms/Form/FormContentFilter";
import { isSafari, isIPad13, isTablet } from "react-device-detect";
import { useManagementInformationDomainForm, useDownloadHolidayManagement } from "./hooks";
import BlockUI from "components/molecules/BlockUi";
import TextTooltip from "components/molecules/TextTooltip";
import { HolidayManagementContext } from "./../../HolidayManagementForm";
import Checkbox from "components/molecules/Checkbox";
import { getBoolean } from "utility";

// create style form
const styles = {
  form: css({
    paddingTop: "15px",
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  container: css({
    padding: "4px 0",
  }),
  label: css({
    flexBasis: "100px",
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
  }),
  digestionDays: css({
    width: "150px",
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center",
  }),
  detail: css({
    backgroundColor: textColor.inversed,
    borderRadius: "5px",
    textAlign: "center",
    marginLeft: "5px",
  }),
  detailButton: css({
    padding: "4px",
    color: productColor.primary,
    fontSize: "12px",
  }),
  globalSearch: css({
    marginLeft: "30px",
    marginBottom: "10px",
  }),
  backgroundWhite: css({
    backgroundColor: "#fff",
  }),
  input: css({
    maxWidth: "600px",
    padding: "30px",
    marginBottom: "30px",
  }),
  wrapAccordion: css({
    backgroundColor: "#fff",
    border: "1px solid #dcdcdc",
  }),
  center: css({
    textAlign: "center",
    justifyContent: "center",
  }),
  customStyle: css(
    {
      display: "flex",
      justifyContent: (isSafari && isTablet) || isIPad13 ? "flex-start" : "flex-end",
      paddingRight: (isSafari && isTablet) || isIPad13 ? "10px" : "30px",
      marginBottom: (isSafari && isTablet) || isIPad13 ? "0px" : "-55px",
    },
    css`
      button {
        width: auto;
        margin-left: 15px;
      }
      button:hover {
        background: transparent;
      }
      button svg {
        width: 20px !important;
        height: 20px !important;
      }
    `,
  ),
  table: css(
    {
      color: "#666666",
    },
    css`
      table.tblPopup tr {
        display: inline-grid;
        margin-bottom: 10px;
      }
      table.tblPopup tr td {
        margin-bottom: 5px;
      }
    `,
  ),
};

export interface DataDetail {
  staffCode: string;
  staffName: string;
  holidayName: string;
}

const ManagementInformationForm: React.FC<{
  employmentOptions: any;
  orgTreesOptions: any;
  specialHolidayOptions: any;
  currentAccordionList: any;
  setCurrentAccordionList: any;
}> = ({
  employmentOptions,
  orgTreesOptions,
  specialHolidayOptions,
  currentAccordionList,
  setCurrentAccordionList,
}) => {
  const {
    paidHolidayStatusList,
    orgCode,
    setOrgCode,
    employmentId,
    setOrgEmploymentId,
    modalOpen,
    setModalOpen,
    closeModal,
    detailItems,
    setDetailItems,
    callPaidHolidayStatusDetail,
    callSaveUseItemForPaidHolidayStatus,
    specialManageEmpSelect,
    setSpecialManageEmpSelect,
    underFlag,
    setUnderFlag,
  } = useManagementInformationDomainForm();

  const { downloadPaidHolidayStatus, blocking } = useDownloadHolidayManagement(
    orgCode || "",
    employmentId || "",
    specialManageEmpSelect || "",
    underFlag || 0,
  );

  const contextObject = useContext(HolidayManagementContext);

  const [dataDetail, setDataDetail] = useState<DataDetail>({
    staffCode: "",
    staffName: "",
    holidayName: "",
  });

  // handlePaidHolidayStatusDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePaidHolidayStatusDetail = async (data: any) => {
    setDataDetail(data);
    callPaidHolidayStatusDetail(
      data.staffCode,
      data.staffName,
      data.holidayId,
      data.holidayName,
      data.specialHolidayGrantId ? data.specialHolidayGrantId : "",
    );
    setModalOpen(true);
  };
  // SpecialHolidayStatusDomain
  const columns: Array<Column<SpecialHolidayStatusDomain>> = React.useMemo(
    () => [
      {
        Header: () => "組織コード",
        id: "orgCode",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayStatusDomain };
          data: Array<SpecialHolidayStatusDomain>;
        }) => (
          <FlexBox justifyContent="flex-start">
            <TextTooltip
              text={cell.row.original.orgCode}
              textDis={<span>{cell.row.original.orgCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "組織名",
        id: "orgName",
        accessor: "orgName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayStatusDomain };
          data: Array<SpecialHolidayStatusDomain>;
        }) => (
          <FlexBox justifyContent="flex-start">
            <TextTooltip
              text={cell.row.original.orgName}
              textDis={<span>{cell.row.original.orgName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフコード",
        id: "staffCode",
        accessor: "staffCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayStatusDomain };
          data: Array<SpecialHolidayStatusDomain>;
        }) => (
          <FlexBox justifyContent="flex-start">
            <TextTooltip
              text={cell.row.original.staffCode}
              textDis={<span>{cell.row.original.staffCode}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
            {contextObject.detailRole.editable === 1 ? (
              <IconLabelButton
                onClick={() => handlePaidHolidayStatusDetail(cell.row.original)}
                text="編集"
                isIcon={false}
              />
            ) : (
              ""
            )}
          </FlexBox>
        ),
      },
      {
        Header: () => "スタッフ名",
        id: "staffName",
        accessor: "staffName",
        Cell: (cell: {
          row: { isExpanded: boolean; original: SpecialHolidayStatusDomain };
          data: Array<SpecialHolidayStatusDomain>;
        }) => (
          <FlexBox justifyContent="flex-start">
            <TextTooltip
              text={cell.row.original.staffName}
              textDis={<span>{cell.row.original.staffName}</span>}
              customCss={css({
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100px",
                whiteSpace: "nowrap",
                display: "inline-block",
                margin: "0px auto",
              })}
            />
          </FlexBox>
        ),
      },
      {
        Header: "休暇コード",
        accessor: "holidayCode",
      },
      {
        Header: "休暇名",
        accessor: "holidayName",
      },
      {
        Header: "付与数",
        accessor: "grantDaysNumSumStr",
      },
      {
        Header: "消化数",
        accessor: "digestedDaysNumStr",
      },
      {
        Header: "残日数",
        accessor: "remainDaysNumStr",
      },
      {
        Header: "有効期間(開始)",
        accessor: "useStartDate",
      },
      {
        Header: "有効期間(終了)",
        accessor: "useEndDate",
      },
      {
        Header: "次回付与日",
        accessor: "nextGrantDate",
      },
      {
        Header: "次回予定付与日数",
        accessor: "nextGrantDaysStr",
      },
    ],
    [handlePaidHolidayStatusDetail, contextObject],
  );

  const history = useHistory();
  return (
    <BlockUI blocking={blocking}>
      <Accordion text="管理情報" callBack={() => {}}>
        <div css={styles.backgroundWhite}>
          <FormContentFilter maxWidth="100%">
            <FlexBox>
              <SelectForm
                label="組織名"
                name="orgCode001"
                value={String(orgCode)}
                setValue={(val: string) => setOrgCode(val)}
                options={orgTreesOptions}
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
              <Checkbox
                id={"underFlag4"}
                name={"underFlag4"}
                label={"配下指定"}
                value={`underFlag4`}
                checked={Boolean(underFlag || 0)}
                onChange={() => {
                  setUnderFlag(underFlag === 1 ? 0 : 1);
                }}
              />
            </FlexBox>

            <SelectForm
              label="雇用形態"
              name="employmentId001"
              width="900px"
              value={String(employmentId)}
              setValue={(val: string) => setOrgEmploymentId(val)}
              options={employmentOptions}
            />

            <SelectForm
              label="休暇"
              name="specialManageHolidaySelect"
              width="900px"
              value={String(specialManageEmpSelect)}
              setValue={(val: string) => setSpecialManageEmpSelect(val)}
              options={specialHolidayOptions}
            />
          </FormContentFilter>
          <div
            css={css`
              table th:nth-of-type(1) {
                width: 140px;
              }
              table th:nth-of-type(2) {
                width: 150px;
              }
              table th:nth-of-type(3) {
                width: 140px;
              }
              table th:nth-of-type(4) {
                width: 150px;
              }
              table th:nth-of-type(5) {
                width: 150px;
              }
              table tr td:nth-of-type(5) {
                text-align: left;
              }
              table th:nth-of-type(6) {
                width: 150px;
              }
              table tr td:nth-of-type(6) {
                text-align: left;
              }
              table th:nth-of-type(7) {
                width: 150px;
              }
              table th:nth-of-type(8) {
                width: 180px;
              }
              table th:nth-of-type(9) {
                width: 180px;
              }
              table th:nth-of-type(10) {
                width: 150px;
              }
              table th:nth-of-type(11) {
                width: 150px;
              }
              table th:nth-of-type(12) {
                width: 150px;
              }
              table th:nth-of-type(13) {
                width: 150px;
              }
              table tr td,
              table tr th {
                text-align: center;
              }
            `}
          >
            <FormSubmitArea customStyle={styles.customStyle}>
              {contextObject.detailRole.editable === 1 && contextObject.detailRole.importFlag === 1 ? (
                <IconLabelButton
                  onClick={() => history.push("/attendHolidayManagement/attendHolidayManagementImport/specialHoliday")}
                  iconType="addCircle"
                  text="インポート"
                />
              ) : null}
              {contextObject.detailRole.downloadFlag ? (
                <IconLabelButton onClick={() => downloadPaidHolidayStatus()} iconType="download" text="ダウンロード" />
              ) : null}
              {/* <IconLabelButton onClick={() => downloadPaidHolidayStatus(true)} iconType="download" text="全組織ダウンロード" /> */}
            </FormSubmitArea>

            <div className="table-bordered">
              <DataTable
                columns={columns}
                data={paidHolidayStatusList}
                isGlobalFilter={true}
                containerStyle={css({
                  overflowX: "auto",
                  marginTop: "10px",
                })}
                globalSearchStyle={styles.globalSearch}
                tableId={4}
              />
            </div>
          </div>
          {/* Form Detail */}
          <Modal
            open={modalOpen}
            closeHandler={closeModal}
            title="特休編集"
            submitText="登録する"
            closeText="キャンセル"
            onSubmit={callSaveUseItemForPaidHolidayStatus}
          >
            {detailItems && (
              <div
                style={{
                  width: "350px",
                  margin: "0 auto",
                  fontSize: "14px",
                  color: "#333",
                }}
                css={styles.table}
              >
                <VerticalLabelTextLayout label="スタッフコード" variable={dataDetail.staffCode} />
                <VerticalLabelTextLayout label="スタッフ名" variable={dataDetail.staffName} />
                <VerticalLabelTextLayout label="休暇名" variable={dataDetail.holidayName} />
                <table className="tblPopup" style={{ width: "100%", fontSize: "14px" }}>
                  <tbody>
                    {detailItems.useEnteringGrantFlg1 && (
                      <tr>
                        <td className="active" style={{ color: "#666666" }}>
                          入社時の付与数:
                        </td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="enteringGrantDay1"
                                value={Number(detailItems.enteringGrantDay1)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    enteringGrantDay1: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                max={99}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useEnteringGrantFlg2 && (
                      <tr>
                        <td className="active" style={{ color: "#666666" }}>
                          入社3ヶ月後の付与数
                        </td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="enteringGrantDay2"
                                value={Number(detailItems.enteringGrantDay2)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    enteringGrantDay2: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                max={99}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useEnteringGrantFlg3 && (
                      <tr>
                        <td className="active" style={{ color: "#666666" }}>
                          入社6ヶ月後の付与数
                        </td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px" }}>
                              <HourInput
                                name="enteringGrantDay3"
                                value={Number(detailItems.enteringGrantDay3)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    enteringGrantDay3: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                max={99}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}

                    {detailItems.useGrantFlg && (
                      <tr>
                        <td className="active" style={{ color: "#666666" }}>
                          付与数
                        </td>
                        <td>
                          <FlexBox>
                            <div style={{ width: "120px", marginRight: "15px" }}>
                              <HourInput
                                name="grantDaysNum"
                                value={Number(detailItems.grantDaysNum)}
                                onChange={(e) =>
                                  setDetailItems({
                                    ...detailItems,
                                    grantDaysNum: Number(e.target.value),
                                  })
                                }
                                label="日"
                                min={0}
                                max={99}
                                step={0.5}
                              />
                            </div>
                          </FlexBox>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </Modal>
        </div>
      </Accordion>
    </BlockUI>
  );
};
export default ManagementInformationForm;
