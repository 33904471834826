import {useState, useCallback, useEffect, useContext} from 'react';
import {
  getGrantItemsForStockPaidHolidayGrantHistory,
  downloadStockPaidHolidayGrantHistoryCsv,
} from 'api/holidayManagement';
import { HolidayManagementContext } from '../../HolidayManagementForm'
import StockPaidHolidayGrantHistoryDomain from 'domain/master/holidayManagement/stockpaidHolidayGrantHistory';
import { useStaffOptions } from 'hooks/useEmploymentOptions';
import { OptionType } from 'components/atoms/Select';

export const useGrantHistoryDomainForm = () => {
  const sessionStorageOrgCode = sessionStorage.getItem('loginUser.orgCode') || '';
  // const [paidHolidayGrantHistoryList, setPaidHolidayGrantHistoryList] = useState<Array<StockPaidHolidayGrantHistoryDomain>>([]);
  const [paidHolidayGrantHistoryList, setPaidHolidayGrantHistoryList] = useState<Array<StockPaidHolidayGrantHistoryDomain>>([]);
  const [orgCode, setOrgCode] = useState(sessionStorageOrgCode);

  const yearCurrent = new Date().getFullYear().toString();
  const [targetDateFrom, setTargetDateFrom] = useState<Date>(new Date(`${yearCurrent}/01/01`));
  const [targetDateTo, setTargetDateTo] = useState<Date>(new Date(`${yearCurrent}/12/31`));
  const [modalOpen, setModalOpen] = useState(false);
  const [underFlag, setUnderFlag] = useState(0);

  let staffOptions: Array<OptionType> = useStaffOptions(orgCode || 'all', targetDateFrom, targetDateTo);

  const contextObject = useContext(HolidayManagementContext);

  // データの取得
  const fetchData = useCallback(async () => {
    contextObject.setIsLoading(true);
    const response = await getGrantItemsForStockPaidHolidayGrantHistory(
      orgCode !== 'all' ? orgCode : '',
      targetDateFrom,
      targetDateTo,
      underFlag
    );
    contextObject.setIsLoading(false);
    setPaidHolidayGrantHistoryList(response.map((result) => new StockPaidHolidayGrantHistoryDomain(result)));
    
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, orgCode, targetDateFrom, targetDateTo]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    paidHolidayGrantHistoryList,
    orgCode,
    setOrgCode,
    targetDateFrom,
    setTargetDateFrom,
    targetDateTo,
    setTargetDateTo,
    modalOpen,
    setModalOpen,
    closeModal,
    staffOptions,
    underFlag,
    setUnderFlag
  };
};

export const useDownloadHolidayManagementGrantHistory = (
  orgCode: string,
  targetDateFrom: Date,
  targetDateTo: Date,
  underFlag: number
) => {
  const [blocking, setBlocking] = useState(false);
  const downloadHolidayManagementGrantHistory = useCallback((isDownloadAll?: boolean) => {
    setBlocking(true);
    downloadStockPaidHolidayGrantHistoryCsv(
      isDownloadAll ? '' : orgCode,
      targetDateFrom,
      targetDateTo,
      underFlag
    ).then((response) => {
      setBlocking(false);
    });
  }, [orgCode, targetDateFrom, targetDateTo, underFlag]);

  return {
    downloadHolidayManagementGrantHistory,
    blocking
  };
};

export default {
  useGrantHistoryDomainForm,
};
