/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import { useDownload } from './hooks';

const TimeDaySeparateProducts: React.FC<{
    argetAggregate:any,
    yearlyStartDate:any,
    yearlyEndDate:any,
    dispSlitList:any,
    itemStartTime:any,
    itemEndTime:any,
    formik:any,
    orgCode:any,
    orgTreesOptions:any,
    targetPeriodData:any
  }> = ({
    argetAggregate,
    yearlyStartDate,
    yearlyEndDate,
    dispSlitList,
    itemStartTime,
    itemEndTime,
    formik,
    orgCode,
    orgTreesOptions,
    targetPeriodData,
  }) => {
    const targetPeriod:any[] = [];
    let timeCnt = 0;
    let st = Number(itemStartTime);
    let ed = Number(itemEndTime);
    if (st > ed) {
      ed += 24;
    }

    for (; st <= ed; st += 1) {
      timeCnt += 1;
      targetPeriod.push(`${st >= 24 ? st - 24 : st}時`);
    }

    const targetDataColumn:any[] = [];
    for (let idx = 0; idx <= timeCnt; idx += 1) {
      targetDataColumn.push('数量');
      targetDataColumn.push('売上');
    }

    const { downloadCntOrderReportCsv, downloadCntOrderReportExcel } = useDownload();

    const formatter = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 0,
    });
    const formatterPercent = new Intl.NumberFormat('ja-JP', {
      minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
    });

    const numberFormater = (num: any) => {
      if (typeof num === 'undefined' || isNaN(num) || !isFinite(num)) {
        return 0;
      }
      return num;
    };

    const [getOrgCodeName, setOrgCodeName] = useState('');
    const orgCodeArr:any[] = [];
    const searchResult:any[] = [];
    useEffect(() => {
      const orgArr = orgCode.split(',');
      orgTreesOptions.filter((options:any) => {
        orgArr.map((orgArrItem:any) => {
          if (options.value === orgArrItem) {
            orgCodeArr.push(options.label.split(' ')[1]);
          }
        });
      });

      const str = orgCodeArr.join('、');
      setOrgCodeName(str);
    }, [formatter, formatterPercent, orgCode, orgCodeArr, orgTreesOptions, searchResult]);

    const targetStoresData = `対象組織：${getOrgCodeName}`;
    const headerInformation:any[] = [];
    let targetDay = '';
    if (formik.values.timePub === true) {
      targetDay += ' 祝';
    }
    if (formik.values.timeSun === true) {
      targetDay += ' 日';
    }
    if (formik.values.timeSat === true) {
      targetDay += ' 土';
    }
    if (formik.values.timeMon === true) {
      targetDay += ' 月';
    }
    if (formik.values.timeTue === true) {
      targetDay += ' 火';
    }
    if (formik.values.timeWed === true) {
      targetDay += ' 水';
    }
    if (formik.values.timeTur === true) {
      targetDay += ' 木';
    }
    if (formik.values.timeFri === true) {
      targetDay += ' 金';
    }

    const dispSlitListItems:any[] = [];
    if (dispSlitList.length > 0) {
      dispSlitList.map((data:any) => {
        const dispSlitData:any[] = [];
        dispSlitData.push(data.menuCode);
        dispSlitData.push(data.menuName);
        dispSlitData.push(data.unitPrice);
        data.items.forEach((item:any) => {
          dispSlitData.push(formatter.format(numberFormater(item.cnt)));
          dispSlitData.push(formatter.format(numberFormater(item.sales)));
        });
        dispSlitListItems.push(dispSlitData);
      });
    }

    const targetAggregateStr = (argetAggregate === 1) ? '注文時' : '会計時';
    const data = {
      footer: null,
      formName: '商品時間別',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      targetAggregate: `集計対象：${targetAggregateStr}`,
      targetDay: `対象曜日：${targetDay}`,
      targetTime: `対象時間：${itemStartTime}時~${itemEndTime}時`,
      headerInformation,
      searchResult: dispSlitListItems,
      totalInformation: [],
    };

    let mergedRegions:any[] = [];
    const dataExcel = {
      formName: '商品時間別',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      targetAggregate: `集計対象：${targetAggregateStr}`,
      targetDay: `対象曜日： ${targetDay}`,
      targetTime: `対象時間：${itemStartTime}時~${itemEndTime}時`,
      headerInformation,
      searchResult: dispSlitListItems,
      totalInformation: [],
      mergedRegions,
      codeColumnIndex: [0],
    };

    const headers:any[] = [];
    const handleExportCSV = () => {
      let subHeader = [];
      subHeader.push('', '', '', '合計', '');
      for (let i = 0; i < targetPeriod.length; i += 1) {
        subHeader.push(targetPeriod[i]);
        subHeader = subHeader.concat(Array((1)).fill(''));
      }
      headers.unshift(subHeader);

      const subHeader2:any[] = [];
      subHeader2[0] = '日付';
      targetDataColumn.map((dataHeader:any) => {
        subHeader2.push(dataHeader);
      });
      headers.push(subHeader2);
      data.headerInformation = headers;
      downloadCntOrderReportCsv(data);
    };
    const handleExportExcel = () => {
      const header:any[] = [];
      let subHeader = [];
      if (mergedRegions) {
        mergedRegions = [];
      }
      subHeader.push('商品コード', '商品名', '単価', '合計', '');
      for (let i = 0; i < targetPeriod.length; i += 1) {
        subHeader.push(targetPeriod[i]);
        subHeader = subHeader.concat(Array((1)).fill(''));
        mergedRegions.push([6, 6, 5 + i * 2, 5 + i * 2 + 1]);
      }
      header.unshift(subHeader);
      mergedRegions.push([6, 7, 0, 0]);
      mergedRegions.push([6, 7, 1, 1]);
      mergedRegions.push([6, 7, 2, 2]);
      mergedRegions.push([6, 6, 3, 4]);
      const subHeaderExcel:any[] = [];
      subHeaderExcel[0] = '';
      subHeaderExcel.push('商品名', '単価');
      targetDataColumn.map((dataHeader:any) => {
        subHeaderExcel.push(dataHeader);
      });
      header.push(subHeaderExcel);
      dataExcel.headerInformation = header;
      dataExcel.mergedRegions = mergedRegions;
      downloadCntOrderReportExcel(dataExcel);
    };

    const [getFlagBorder, setFlagBorder] = useState(0);
    const eventScroll = (e: { target: any }) => {
      const { target } = e;
      const wrapTblRight = document.getElementById('wrapTblRight');
      const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
      const jsScrollAction = document.getElementById('jsScrollAction');


      if (Number(target.scrollLeft) > 0) {
        setFlagBorder(1);
      } else {
        setFlagBorder(0);
      }
      if (wrapTblRight) {
        wrapTblRight.scrollLeft = target.scrollLeft;
      }
      if (wrapTblHeadRight) {
        wrapTblHeadRight.scrollLeft = target.scrollLeft;
      }
      if (jsScrollAction) {
        jsScrollAction.scrollLeft = target.scrollLeft;
      }
    };

    dispSlitListItems.sort((a:any, b:any) => a[0] - b[0]);
    const wrapTblRightWidth = document.getElementById('tblScroll')?.offsetWidth;
    return (
      <div>
        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportCSV}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportExcel}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>
        <div className="wrap_time_separate_sales wrap_time_separate_products_sales" style={{ overflow: 'hidden', marginTop: '15px' }}>
          <div className="contentHead" style={{ display: 'flex' }}>
            <div className="tblLeft" style={getFlagBorder ? { borderRight: '1px solid' } : {}}>
              <table className="column-width-fixed">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }} className="text-center">商品コード</th>
                    <th style={{ width: '55%' }} className="text-center">商品名</th>
                    <th className="text-center" style={{ width: '20%' }}>単価</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="tblRight " id="wrapTblHeadRight" style={dispSlitListItems.length > 17 ? { width: 'calc(100% - 467px)' } : { width: 'calc(100% - 450px)' }} onScroll={eventScroll}>
              <table id="tblScroll" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th colSpan={2} className="text-center"><span>合計</span></th>
                    {
                      targetPeriod.map((name:any, index:any) => (
                        <th key={index} colSpan={2} className="text-center"><span style={{ width: '100%' }}>{name}</span></th>
                      ))
                    }
                  </tr>
                  <tr>
                    {
                      targetDataColumn.map((name:any, index:any) => (
                        <th key={index} className="text-center"><span>{name}</span></th>
                      ))
                    }
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            {
               dispSlitList && dispSlitListItems.length > 0 ? (
                 <div>
                   <div className="contentTable" style={{ display: 'flex' }}>
                     <div className="tblLeft" style={{ width: '450px' }}>
                       <table className="tbl_fixcolumn column-width-fixed" style={{ width: '100%' }}>
                         <tbody>
                           {
                            dispSlitListItems.length > 0 ? dispSlitListItems.map((dispSlitData:any, index:any) => (
                              <tr key={index}>
                                {
                                    dispSlitData.map((dataItem:any, idx:any) => {
                                      if (idx <= 2) {
                                        return (
                                          <td key={idx} className="text-center"><span>{dataItem}</span></td>
                                        );
                                      }
                                    })
                                  }
                              </tr>
                            )) : null
                          }
                         </tbody>
                       </table>
                     </div>
                     <div className="tblRight" id="wrapTblRight" onScroll={eventScroll} style={{ width: 'calc(100% - 450px)' }}>
                       <table id="tblHeadTable" onScroll={eventScroll}>
                         <tbody>
                           {
                            dispSlitListItems.length > 0 ? dispSlitListItems.map((dispSlitData:any, index:any) => (
                              <tr key={index}>
                                {
                                  dispSlitData.map((dataItem:any, idx:any) => {
                                    if (idx > 2) {
                                      return (
                                        <td key={idx} className={`text-right ${idx === 0 ? 'fixed_column' : ''}`}><span>{dataItem}</span></td>
                                      );
                                    }
                                  })
                                }
                              </tr>
                            )) : null
                          }
                         </tbody>
                       </table>
                     </div>
                   </div>
                 </div>
               ) : null
            }
          </div>
          <div className="scroller">
            <div className="scrollLeft" />
            <div className="scrollRight" id="jsScrollAction" onScroll={eventScroll}>
              <div className="scrollAction" style={{ width: `${wrapTblRightWidth || 0}px` }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

export default TimeDaySeparateProducts;
