/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { grayScale, textFontSize } from "components/styles";

const styles = {
  style: css(
    {
      boxSizing: "border-box",
      width: "100%",
      fontSize: textFontSize.re,
      fontFamily: "inherit",
      padding: "11px",
      border: `1px solid ${grayScale.gray20}`,
      borderRadius: "3px",
      color: grayScale.gray100,
    },
    css`
      ::placeholder {
        color: #999999;
      }
      :disabled {
        cursor: not-allowed;
        color: #999999;
      }
    `,
  ),
  grayOut: css({
    background: "#fff",
  }),
};

const TextInput: React.FC<{
  name: string;
  label?: string;
  value: string;
  placeHolder?: string;
  type?: string;
  readOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  customStyle?: SerializedStyles;
  disable?: boolean;
  min?: string;
  max?: string;
  maxLength?: number;
  autoFocus?: boolean;
}> = ({
  name,
  type,
  value,
  onChange,
  onBlur,
  placeHolder,
  customStyle,
  disable,
  readOnly = false,
  min,
  max,
  maxLength,
  onKeyDown,
  autoFocus = false,
}) => (
  <input
    css={css(styles.style, readOnly ? styles.grayOut : {}, customStyle)}
    id={name}
    name={name}
    type={type}
    value={value}
    placeholder={placeHolder}
    autoFocus={autoFocus}
    onBlur={onBlur}
    onChange={onChange}
    onKeyDown={onKeyDown}
    readOnly={readOnly}
    disabled={disable}
    min={min}
    max={max}
    maxLength={maxLength}
  />
);

export default TextInput;
