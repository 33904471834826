/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';

import Select, { OptionType } from 'components/atoms/Select';
import IconLabelButton from 'components/molecules/IconLabelButton';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import DatePicker from 'components/molecules/DatePicker';

const styles = {
  label: css({
    margin: '0 12px',
    minWidth: '32px',
  }),
  select: css({
    width: '240px',
  }),
  attendSelect: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

const ShiftCalendarToolbar: React.FC<{
  date: Date;
  setDate: (arg: Date) => void;
  view: 'month'|'day';
  setView: (arg: 'month'|'day') => void;
  orgCode: string;
  setOrgCode: (arg: string) => void;
  orgOptions: Array<OptionType>;
  businessMasterOptions: Array<OptionType>;
  businessIds: Array<string>;
  setBusinessIds: (arg: Array<string>) => void;
}> = ({
  date, setDate, view, setView, orgOptions, orgCode, setOrgCode, businessMasterOptions, businessIds, setBusinessIds,
}) => (
  <Container>
    <Row>
      {/* 日付選択 */}
      <Col md={2} sm={4}>
        <DatePicker
          date={date}
          dateFormat={view === 'month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
          changeDate={(argDate) => {
            setDate(argDate);
          }}
          showMonthYearPicker={view === 'month'}
        />
      </Col>

      {view === 'day' && (
        <Col width="60px" sm={2}>
          <Row>
            <Col md={6} sm={6}>
              <IconLabelButton
                iconType="chevronLeft"
                onClick={() => setDate(moment(date).subtract(1, 'day').toDate())}
                padding="6px 4px"
                margin="0 0 0 4px"
              />
            </Col>
            <Col md={6} sm={6}>
              <IconLabelButton
                iconType="chevronRight"
                onClick={() => setDate(moment(date).add(1, 'day').toDate())}
                padding="6px 4px"
                margin="0 0 0 4px"
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col sm={5}>
        <FlexBox>
          {view === 'month' && (
          <BodyText customStyle={styles.label}>
            組織
          </BodyText>
          )}
          <div css={styles.select}>
            <Select
              name="shiftStoreSelect"
              value={orgCode}
              setValue={setOrgCode}
              options={orgOptions}
            />
          </div>
        </FlexBox>
      </Col>

      {/* 月・日の切替セレクトボックス */}
      <Col offset={{ md: 1, sm: 1 }} width={60} style={{ marginLeft: 'auto' }}>
        <Select
          name="calendarViewSelect"
          value={view}
          setValue={setView as (val: string) => void}
          options={[
            { label: '日', value: 'day' },
            { label: '月', value: 'month' },
          ]}
        />
      </Col>
    </Row>

    {/* 業務 */}
    {view === 'day' && (
      <Row style={{ marginTop: '8px' }}>
        <Col>
          <div css={styles.attendSelect}>
            <div>
              <BodyText customStyle={styles.label}>
                業務
              </BodyText>
            </div>
            <Select
              name="businessAttendList"
              values={businessIds}
              setValues={setBusinessIds}
              options={businessMasterOptions}
              isMulti={true}
              placeholder="-"
              controlStyle={{ minWidth: '400px' }}
            />
          </div>
        </Col>
      </Row>
    )}
  </Container>
);

export default ShiftCalendarToolbar;
