import React from 'react';
import SidebarTemplate from 'components/templates/SidebarTemplate';
import PageTitle from 'components/atoms/PageTitle';
import AdvertisingImportCreateForm from 'components/organismos/master/sales/AdvertisingImportCreateForm';

const AdvertisingImportCreatePage: React.FC = () => (
  <SidebarTemplate>
    <PageTitle title="広告媒体取込設定" />
    <AdvertisingImportCreateForm />

  </SidebarTemplate>
);
export default AdvertisingImportCreatePage;
