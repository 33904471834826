import { useState, useCallback, useEffect } from 'react';
import {
  getDataApprovalApplication,
  handleSubmitModificationApplication,
  handleSubmitOvertimeApplication,
  handleSubmitHolidayWorkApplication,
  handleSubmitTransferApplication,
  handleSubmitHolidayApplication,
  getHistoryApprovalApplication,
} from 'api/approval';
import useToastNotification from 'hooks/useToastNotification';
import ApprovalModificationApplicationDomain from 'domain/master/approval/ApprovalModificationApplicationDomain';
import ApprovalOverTimeApplicationDomain from 'domain/master/approval/ApprovalOverTimeApplicationDomain';
import ApprovaHolidayWorkApplicationDomain from 'domain/master/approval/ApprovaHolidayWorkApplicationDomain';
import ApprovalTransferApplicationDomain from 'domain/master/approval/ApprovalTransferApplicationDomain';
import ApprovalHolidayApplicationDomain from 'domain/master/approval/ApprovalHolidayApplicationDomain';

import moment from 'moment';

const { errorNotification, successNotification } = useToastNotification();

type TypeWorkApplication =
  | 'modification'
  | 'overtime'
  | 'transfer'
  | 'holidaywork'
  | 'holiday'
const typeWorkApplication: { [key in TypeWorkApplication]: string } = {
  modification: 'modifierApplication',
  overtime: 'overtimeApplication',
  transfer: 'transfer',
  holidaywork: 'holidayworkApplication',
  holiday: 'holiday',
};

type TypeApiVersion =
  | 'modification'
  | 'overtime'
  | 'transfer'
  | 'holidaywork'
  | 'holiday'
const typeApiVersion: { [key in TypeApiVersion]: string } = {
  modification: 'v1',
  overtime: 'v1',
  transfer: 'v1',
  holidaywork: 'v1',
  holiday: 'v2',
};

export const useApprovalModificationApplication = (applicationId?: string) => {
  const initApprovalData = ApprovalModificationApplicationDomain.generateInitial();
  const [approvalData, setApprovalData] = useState<ApprovalModificationApplicationDomain>(
    initApprovalData,
  );
  const [rejectReason, setRejectReason] = useState('');
  const typeUrl = typeWorkApplication.modification;
  const apiVersion = typeApiVersion.modification;
  const [modalHistoryOpen, setModalHistoryOpen] = useState(false);
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [historyList, setHistoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (applicationId !== undefined) {
        await getDataApprovalApplication(
          applicationId,
          sessionStorage.getItem('loginUser.staffCode') || '',
          sessionStorage.getItem('loginUser.staffName') || '',
          typeUrl,
          apiVersion,
        ).then((response: any) => {
          setApprovalData(response);
          setRejectReason(response.rejectReason);
          setApplicationStatus(response.applicationStatus);
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [apiVersion, applicationId, typeUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // get history
  const fetchDataHistoryStamp = useCallback(async () => {
    try {
      if (applicationId !== undefined) {
        await getHistoryApprovalApplication(
          applicationId,
        ).then((response: any) => {
          setHistoryList(response);
        });
      }
    } catch (error) {
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [applicationId]);

  useEffect(() => {
    fetchDataHistoryStamp();
  }, [fetchDataHistoryStamp]);

  const closeApproveModal = useCallback(() => {
    setModalApproveOpen(false);
  }, []);
  const closeRejectModal = useCallback(() => {
    setModalRejectOpen(false);
  }, []);
  const closeModalHistoryOpen = useCallback(() => {
    setModalHistoryOpen(false);
  }, []);

  const getStampTime = (startTime: any, endTime: any, targetDate: any) => {
    let result = '';
    if (moment(startTime).isValid() && moment(endTime).isValid()) {
      const date = moment(targetDate).format('yyyy/MM/dd');
      const startDispNextDay = moment(startTime).format('yyyy/MM/dd') === date ? '' : '(翌)';
      const endDispNextDay = moment(endTime).format('yyyy/MM/dd') === date ? '' : '(翌)';
      result = `${moment(startTime).format('HH:mm') + startDispNextDay}～${moment(endTime).format('HH:mm')}${endDispNextDay} `;
    } else if (moment(startTime).isValid()) {
      const date = moment(targetDate).format('yyyy/MM/dd');
      const startDispNextDay = moment(startTime).format('yyyy/MM/dd') === date ? '' : '(翌)';
      result = `${moment(startTime).format('HH:mm') + startDispNextDay}～ `;
    }
    return result;
  };
  // Approve application
  const callApproveStampApplication = useCallback(
    async () => {
      const { modifierApplicationId, belongOrgName, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 0,
        belongOrgName,
        orgName,
        rejectReason: rejectReason || '',
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
      };
      try {
        setLoading(true);
        await handleSubmitModificationApplication(params).then(
          (response: any) => {
            successNotification('承認しました');
            setModalApproveOpen(false);
            setApplicationStatus(1);
          },
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  const callRejectStampApplication = useCallback(
    async () => {
      const { modifierApplicationId, belongOrgName, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 1,
        belongOrgName,
        orgName,
        rejectReason: rejectReason || '',
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
      };
      try {
        setLoading(true);
        const response = await handleSubmitModificationApplication(params);
        setLoading(false);
        if (!response.errors) {
          successNotification('差戻しました。');
          setModalApproveOpen(false);
          setApplicationStatus(2);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  return {
    modalApproveOpen,
    setModalApproveOpen,
    closeApproveModal,
    modalRejectOpen,
    setModalRejectOpen,
    closeRejectModal,
    applicationId,
    approvalData,
    rejectReason,
    setRejectReason,
    callApproveStampApplication,
    callRejectStampApplication,
    applicationStatus,
    setApplicationStatus,
    modalHistoryOpen,
    historyList,
    closeModalHistoryOpen,
    setModalHistoryOpen,
    getStampTime,
    isLoading,
  };
};
export const useApprovalOvertimeApplication = (applicationId?: string) => {
  const initApprovalData = ApprovalOverTimeApplicationDomain.generateInitial();
  const [approvalData, setApprovalData] = useState<ApprovalOverTimeApplicationDomain>(
    initApprovalData,
  );
  const [workTimeData, setWorkTimeData] = useState();
  const [rejectReason, setRejectReason] = useState('');
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const typeUrl = typeWorkApplication.overtime;
  const apiVersion = typeApiVersion.overtime;

  // Get data application
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (applicationId !== undefined) {
        await getDataApprovalApplication(
          applicationId,
          sessionStorage.getItem('loginUser.staffCode') || '',
          sessionStorage.getItem('loginUser.staffName') || '',
          typeUrl,
          apiVersion,
        ).then((response: any) => {
          setApprovalData(response);
          setApplicationStatus(response.applicationStatus);
          setRejectReason(response.rejectReason);
          if (response.workTimeData !== null) {
            setWorkTimeData(response.workTimeData);
          }
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [apiVersion, applicationId, typeUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeApproveModal = useCallback(() => {
    setModalApproveOpen(false);
  }, []);
  const closeRejectModal = useCallback(() => {
    setModalRejectOpen(false);
  }, []);

  const callApproveOvertimeApplication = useCallback(
    async () => {
      const { modifierApplicationId, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 0,
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
        belongOrgName: '',
        orgName,
        rejectReason: rejectReason || '',
      };
      try {
        setLoading(true);
        await handleSubmitOvertimeApplication(params).then((response: any) => {
          setLoading(false);
          successNotification('承認しました');
          setModalApproveOpen(false);
          setApplicationStatus(1);
        });
      } catch (error) {
        setLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  const callRejectOvertimeApplication = useCallback(
    async () => {
      const { modifierApplicationId, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 1,
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
        belongOrgName: '',
        orgName,
        rejectReason: rejectReason || '',
      };
      try {
        setLoading(true);
        const response = await handleSubmitOvertimeApplication(params);
        setLoading(false);
        if (!response.errors) {
          successNotification('差戻しました。');
          setModalApproveOpen(false);
          setApplicationStatus(2);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  return {
    approvalData,
    workTimeData,
    setWorkTimeData,
    rejectReason,
    setRejectReason,
    applicationStatus,
    modalApproveOpen,
    setModalApproveOpen,
    modalRejectOpen,
    setModalRejectOpen,
    closeApproveModal,
    closeRejectModal,
    callApproveOvertimeApplication,
    callRejectOvertimeApplication,
    isLoading,
  };
};
export const useApprovaHolidayWorkApplication = (applicationId?: string) => {
  const initApprovalData = ApprovaHolidayWorkApplicationDomain.generateInitial();
  const [approvalData, setApprovalData] = useState<ApprovaHolidayWorkApplicationDomain>(
    initApprovalData,
  );
  const [workTimeData, setWorkTimeData] = useState();
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [rejectReason, setRejectReason] = useState('');
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const typeUrl = typeWorkApplication.holidaywork;
  const apiVersion = typeApiVersion.holidaywork;

  // Get data application
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (applicationId !== undefined) {
        await getDataApprovalApplication(
          applicationId,
          sessionStorage.getItem('loginUser.staffCode') || '',
          sessionStorage.getItem('loginUser.staffName') || '',
          typeUrl,
          apiVersion,
        ).then((response: any) => {
          setApprovalData(response);
          setApplicationStatus(response.applicationStatus);
          setRejectReason(response.rejectReason);
          if (response.workTimeData !== null) {
            setWorkTimeData(response.workTimeData);
          }
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [apiVersion, applicationId, typeUrl]);

  const closeApproveModal = useCallback(() => {
    setModalApproveOpen(false);
  }, []);
  const closeRejectModal = useCallback(() => {
    setModalRejectOpen(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const callApproveHolidayWorkApplication = useCallback(
    async () => {
      const { modifierApplicationId, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 0,
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
        belongOrgName: '',
        orgName,
        rejectReason: rejectReason || '',
      };
      try {
        setLoading(true);
        await handleSubmitHolidayWorkApplication(params).then((response: any) => {
          setLoading(false);
          successNotification('承認しました');
          setModalApproveOpen(false);
          setApplicationStatus(1);
        });
      } catch (error) {
        setLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  const callRejectHolidayWorkApplication = useCallback(
    async () => {
      const { modifierApplicationId, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 1,
        belongOrgName: '',
        orgName,
        rejectReason: rejectReason || '',
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
      };
      try {
        setLoading(true);
        const response = await handleSubmitHolidayWorkApplication(params);
        setLoading(false);
        if (!response.errors) {
          successNotification('差戻しました。');
          setModalApproveOpen(false);
          setApplicationStatus(2);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );
  return {
    approvalData,
    workTimeData,
    applicationStatus,
    rejectReason,
    setRejectReason,
    closeApproveModal,
    closeRejectModal,
    modalApproveOpen,
    modalRejectOpen,
    setModalApproveOpen,
    setModalRejectOpen,
    callApproveHolidayWorkApplication,
    callRejectHolidayWorkApplication,
    isLoading,
  };
};
export const useApprovalTransferApplication = (applicationId?: string) => {
  const initApprovalData = ApprovalTransferApplicationDomain.generateInitial();
  const [approvalData, setApprovalData] = useState<ApprovalTransferApplicationDomain>(
    initApprovalData,
  );
  const [workTimeData, setWorkTimeData] = useState();
  const [rejectReason, setRejectReason] = useState('');
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const typeUrl = typeWorkApplication.transfer;
  const apiVersion = typeApiVersion.transfer;

  // Get data application
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (applicationId !== undefined) {
        await getDataApprovalApplication(
          applicationId,
          sessionStorage.getItem('loginUser.staffCode') || '',
          sessionStorage.getItem('loginUser.staffName') || '',
          typeUrl,
          apiVersion,
        ).then((response: any) => {
          setApprovalData(response);
          setApplicationStatus(response.applicationStatus);
          setRejectReason(response.rejectReason);
          if (response.workTimeData !== null) {
            setWorkTimeData(response.workTimeData);
          }
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [apiVersion, applicationId, typeUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeApproveModal = useCallback(() => {
    setModalApproveOpen(false);
  }, []);
  const closeRejectModal = useCallback(() => {
    setModalRejectOpen(false);
  }, []);

  const callApproveTransferApplication = useCallback(
    async () => {
      const { modifierApplicationId, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 0,
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
        belongOrgName: '',
        orgName,
        rejectReason: rejectReason || '',
      };
      try {
        setLoading(true);
        await handleSubmitTransferApplication(params).then((response: any) => {
          setLoading(false);
          successNotification('承認しました');
          setModalApproveOpen(false);
          setApplicationStatus(1);
        });
      } catch (error) {
        setLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  const callRejectTransferApplication = useCallback(
    async () => {
      const { modifierApplicationId, orgName } = approvalData;
      const params = {
        modifierApplicationId,
        buttonType: 1,
        belongOrgName: '',
        orgName,
        rejectReason: rejectReason || '',
        staffCode: sessionStorage.getItem('loginUser.staffCode'),
      };
      try {
        setLoading(true);
        const response = await await handleSubmitTransferApplication(params);
        setLoading(false);
        if (!response.errors) {
          successNotification('差戻しました。');
          setModalApproveOpen(false);
          setApplicationStatus(2);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason],
  );

  return {
    approvalData,
    workTimeData,
    setWorkTimeData,
    rejectReason,
    setRejectReason,
    applicationStatus,
    modalApproveOpen,
    setModalApproveOpen,
    modalRejectOpen,
    setModalRejectOpen,
    closeApproveModal,
    closeRejectModal,
    callApproveTransferApplication,
    callRejectTransferApplication,
    isLoading,
  };
};
export const useApprovalHolidayApplication = (applicationId?: string) => {
  const initApprovalData = ApprovalHolidayApplicationDomain.generateInitial();
  const [approvalData, setApprovalData] = useState<ApprovalHolidayApplicationDomain>(
    initApprovalData,
  );
  const [nextDay, setNextDay] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [staffCode] = useState('');
  const [staffName] = useState('');
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const typeUrl = typeWorkApplication.holiday;
  const apiVersion = typeApiVersion.holiday;

  // Get data application
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (applicationId !== undefined) {
        await getDataApprovalApplication(
          applicationId,
          sessionStorage.getItem('loginUser.staffCode') || '',
          sessionStorage.getItem('loginUser.staffName') || '',
          typeUrl,
          apiVersion,
        ).then((response: any) => {
          setApprovalData(response);
          setApplicationStatus(response.applicationStatus);
          setRejectReason(response.rejectReason);
          const { targetDate, holidayStartHm } = response;
          if (moment(targetDate).isValid && moment(holidayStartHm).isValid) {
            if (moment(new Date(targetDate), 'YYYY-MM-DD HH:mm:ss').format('D')
                  < moment(new Date(holidayStartHm), 'YYYY-MM-DD HH:mm:ss').format('D')) {
              setNextDay(true);
            }
          }
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
  }, [apiVersion, applicationId, typeUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeApproveModal = useCallback(() => {
    setModalApproveOpen(false);
  }, []);
  const closeRejectModal = useCallback(() => {
    setModalRejectOpen(false);
  }, []);

  const callApproveHolidayApplication = useCallback(
    async () => {
      const { modifierApplicationId, achievementId } = approvalData;
      const params = {
        modifierApplicationId,
        achievementId,
        staffCode,
        approvalStaffCode: sessionStorage.getItem('loginUser.staffCode'),
        applicationStatus: 1,
        rejectReason: rejectReason || '',
      };

      try {
        setLoading(true);
        await handleSubmitHolidayApplication(params).then((response: any) => {
          setLoading(false);
          setModalApproveOpen(false);
          setApplicationStatus(1);
          successNotification('承認しました');
        });
      } catch (error) {
        setLoading(false);
        if (
          error.response
          && error.response.data
          && error.response.data.errors
        ) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.map((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
            return stringErr;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason, staffCode],
  );

  const callRejectHolidayApplication = useCallback(
    async () => {
      const { modifierApplicationId, achievementId } = approvalData;
      const params = {
        modifierApplicationId,
        achievementId,
        staffCode,
        approvalStaffCode: sessionStorage.getItem('loginUser.staffCode'),
        applicationStatus: 2,
        rejectReason: rejectReason || '',
      };

      try {
        setLoading(true);
        const response = await await handleSubmitHolidayApplication(params);
        setLoading(false);
        if (!response.errors) {
          setModalApproveOpen(false);
          successNotification('差戻しました。');
          setApplicationStatus(2);
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const listErr = error.response.data.errors;
          let stringErr = '';
          listErr.forEach((element: any) => {
            stringErr += `${element.defaultMessage}\n`;
          });
          errorNotification(stringErr);
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
          throw error;
        }
      }
    },
    [approvalData, rejectReason, staffCode],
  );

  return {
    approvalData,
    nextDay,
    staffCode,
    staffName,
    rejectReason,
    setRejectReason,
    applicationStatus,
    // setApplicationStatus,
    modalApproveOpen,
    setModalApproveOpen,
    modalRejectOpen,
    setModalRejectOpen,
    closeApproveModal,
    closeRejectModal,
    callApproveHolidayApplication,
    callRejectHolidayApplication,
    isLoading,
  };
};
export default {
  useApprovalModificationApplication,
  useApprovalOvertimeApplication,
  useApprovaHolidayWorkApplication,
  useApprovalTransferApplication,
};
