import React from 'react';

import FormField from 'components/atoms/Form/FormField';
import TextForm from 'components/molecules/TextForm';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button from 'components/atoms/Button';
import { Link, useParams } from 'react-router-dom';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';

import LableTitle from 'components/atoms/LableTitle';
import FormContents from 'components/atoms/Form/FormContents';
import { useBusinessCategoryAddForm } from './hooks';

const BusinessCategoryAddForm: React.FC = () => {
  const { businessCategoryId } = useParams();
  const {
    formik, confirmModalOpen, closeConfirmModal,
  } = useBusinessCategoryAddForm(businessCategoryId);
  return (
    <div>
      <LableTitle title="業態を登録します。業態コードと業態名を入力してください。" />
      <FormContents>
        <form>
          <FormField>
            <TextForm
              name="businessCategoryCode"
              label="業態コード"
              value={formik.values.businessCategoryCode}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.businessCategoryCode}
            />
          </FormField>
          <FormField>
            <TextForm
              name="businessCategoryName"
              label="業態名"
              value={formik.values.businessCategoryName}
              required={true}
              onChange={formik.handleChange}
              errorMsg={formik.errors.businessCategoryName}
            />
          </FormField>
          <FormSubmitArea>
            <div style={{ marginRight: '12px' }}>
              <Button
                text={businessCategoryId ? '更新' : '業態を登録'}
                onClick={formik.handleSubmit}
              />
            </div>
            <Link to="/businessCategories">
              <Button
                text="戻る"
                ghost={true}
              />
            </Link>
          </FormSubmitArea>
        </form>
      </FormContents>

      <ConfirmActionModal
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={formik.handleSubmit}
        actionType={businessCategoryId ? ActionType.UPDATE : ActionType.CREATE}
      />

    </div>
  );
};

export default BusinessCategoryAddForm;
