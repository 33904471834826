/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { utilityColor } from "components/styles";

const createStyle = (margin = "0", padding = "0", minWidth = "360px", minHeight = "240px") =>
  css({
    backgroundColor: utilityColor.white,
    minWidth,
    minHeight,
    margin,
    padding,
    borderRadius: "3px",
    paddingTop: "20px",
  });

const Panel: React.FC<{
  /** marginの調整 */
  margin?: string;
  /** paddingの調整 */
  padding?: string;
  /** widthの調整 */
  width?: string;
  /** heightの調整 */
  height?: string;
  /** その他のstyleの調整 */
  customStyle?: SerializedStyles;
}> = ({ children, customStyle, margin, padding, width, height }) => (
  <div css={css(createStyle(margin, padding, width, height), customStyle)}>{children}</div>
);

export default Panel;
