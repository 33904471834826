import { useState, useEffect, useCallback } from "react";

import SpecialDateMasterDomain from "domain/master/labor/specialDateMaster";

import getList from "api/specialDateMaster";
import useGetDetailRole from "hooks/useGetDetailRole";
import { functionCode } from "const";

export const useSpecialSalaryPattern = () => {
  const [specialList, setSpecialList] = useState<Array<SpecialDateMasterDomain>>([]);
  const [isLoading, setLoading] = useState(true);

  const { detailRole } = useGetDetailRole(setLoading, functionCode.masterSpecialSalary);

  const fetchData = useCallback(async () => {
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    setLoading(true);
    getList(companyCode).then((response: Array<any>) => {
      setSpecialList(response.map((result) => new SpecialDateMasterDomain(result)));
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { specialList, setSpecialList, isLoading, setLoading, detailRole };
};

export default useSpecialSalaryPattern;
