import React from 'react';

import SidebarTemplate from 'components/templates/SidebarTemplate';
import WorkingHoursAddForm from 'components/organismos/master/attend/WorkingHoursAddForm';
import { useParams } from 'react-router-dom';

const TermMasterAddPage: React.FC = () => {
  const {
    workingHoursId,
  } = useParams();

  return (
    <SidebarTemplate
      pageTitle={workingHoursId ? '所定労働時間マスタ編集' : '所定労働時間マスタ'}
    >
      <WorkingHoursAddForm />
    </SidebarTemplate>
  );
};

export default TermMasterAddPage;
