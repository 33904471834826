
export type ShiftView = {
  bgColor: string,
  dayStatus: number,
  endTime: Date,
  fixStatus: number,
  holidayBgcolor: string,
  holidayForecolor: string,
  holidayName: string,
  isHelp: boolean,
  mobileBgColor: string,
  orgCode: string,
  orgName: string,
  shiftPatterntName: string,
  startTime: Date,
  targetDate: string,
  weekday: number,
  weekdayColor: string,
  targetDateTmp: string,
  workSystem: number
}


export class ShiftViewDomain {
  constructor(private rawData: ShiftView) {
    // do nothing
  }

  static generateInitial(): ShiftViewDomain {
    return new ShiftViewDomain({
      bgColor: '#ffffff',
      dayStatus: 0,
      endTime: new Date(),
      fixStatus: 0,
      holidayBgcolor: '#ffffff',
      holidayForecolor: '#000000',
      holidayName: '',
      isHelp: false,
      mobileBgColor: '#ffffff',
      orgCode: sessionStorage.getItem('loginUser.orgCode') || '',
      orgName: sessionStorage.getItem('loginUser.orgName') || '',
      shiftPatterntName: '',
      startTime: new Date(),
      targetDate: '',
      weekday: 0,
      weekdayColor: 'white',
      targetDateTmp: '',
      workSystem: 0
    });
  }

  public getRawData() {
    return this.rawData;
  }

  get isHelp(): boolean {
    return this.rawData.isHelp;
  }

  set isHelp(isHelp: boolean) {
    this.rawData.isHelp = isHelp;
  }

  get bgColor(): string {
    return this.rawData.bgColor;
  }

  set bgColor(bgColor: string) {
    this.rawData.bgColor = bgColor;
  }

  get dayStatus(): number {
    return this.rawData.dayStatus;
  }

  set dayStatus(dayStatus: number) {
    this.rawData.dayStatus = dayStatus;
  }

  get holidayBgcolor(): string {
    return this.rawData.holidayBgcolor;
  }

  set holidayBgcolor(holidayBgcolor: string) {
    this.rawData.holidayBgcolor = holidayBgcolor;
  }


  get holidayForecolor(): string {
    return this.rawData.holidayForecolor;
  }

  set holidayForecolor(holidayForecolor: string) {
    this.rawData.holidayForecolor = holidayForecolor;
  }

  get mobileBgColor(): string {
    return this.rawData.mobileBgColor;
  }

  set mobileBgColor(mobileBgColor: string) {
    this.rawData.mobileBgColor = mobileBgColor;
  }


  get orgCode(): string {
    return this.rawData.orgCode;
  }

  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }


  get orgName(): string {
    return this.rawData.orgName;
  }

  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

  get holidayName(): string {
    return this.rawData.holidayName;
  }

  set holidayName(holidayName: string) {
    this.rawData.holidayName = holidayName;
  }


  get targetDate(): string {
    return this.rawData.targetDate;
  }

  set targetDate(targetDate: string) {
    this.rawData.targetDate = targetDate;
  }

  get startTime(): Date {
    return this.rawData.startTime;
  }

  set startTime(startTime: Date) {
    this.rawData.startTime = startTime;
  }

  get endTime(): Date {
    return this.rawData.endTime;
  }

  set endTime(endTime: Date) {
    this.rawData.endTime = endTime;
  }

  get fixStatus(): number {
    return this.rawData.fixStatus;
  }

  set fixStatus(fixStatus: number) {
    this.rawData.fixStatus = fixStatus;
  }

  get weekday(): number {
    return this.rawData.weekday;
  }

  set weekday(weekday: number) {
    this.rawData.weekday = weekday;
  }


  get weekdayColor(): string {
    return this.rawData.weekdayColor;
  }

  set weekdayColor(weekdayColor: string) {
    this.rawData.weekdayColor = weekdayColor;
  }

  get targetDateTmp(): string {
    return this.rawData.targetDateTmp;
  }

  set targetDateTmp(targetDateTmp: string) {
    this.rawData.targetDateTmp = targetDateTmp;
  }

  get workSystem(): number {
    return this.rawData.workSystem;
  }

  set workSystem(workSystem: number) {
    this.rawData.workSystem = workSystem;
  }
}
