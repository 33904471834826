/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import * as api from 'api/monthlyReport';
import MonthlyReportDomain from 'domain/master/storeManagement/monthlyReport';
import { useFormik } from 'formik';

const useMonthlyReport = (orgCode: string, yearlyStartDate: string,
  yearlyEndDate: string) => {
  const [monthlyReport, setmonthlyReport] = useState<Array<MonthlyReportDomain>>([]);
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
    const path = 'salesmanagemet';
    setIsLoading(true);
    api.getYearlyReport(companyCode, orgCode, yearlyStartDate, yearlyEndDate, path).then((response: any) => {
      if (isSubscribed) {
        setmonthlyReport(response);
      }
      setIsLoading(false);
    });

    return () => { isSubscribed = false; };
  }, [orgCode, yearlyEndDate, yearlyStartDate]);
  return { monthlyReport, getIsLoading };
};

export const useGetFiscalYearly = () => {
  const [getValueFiscalYear, setFiscalYear] = useState();
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  useEffect(() => {
    api.getFiscalYear(companyCode).then((response: any) => {
      setFiscalYear(response);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFiscalYear]);
  return { getValueFiscalYear };
};

type ActionType = 'getFetchOption' | 'getFetchOptionforeCast';

const ActionType :{[key in ActionType]: ActionType} = {
  getFetchOption: 'getFetchOption',
  getFetchOptionforeCast: 'getFetchOptionforeCast',
};

export const useGetListMonthlyReport = () => {
  const onSubmit = () => {

  };

  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit,
  });

  const [action, setAction] = useState<ActionType>(ActionType.getFetchOption);
  if (action === ActionType.getFetchOption) { }

  if (action === ActionType.getFetchOptionforeCast) {}
  return {
    action,
    setAction,
    ActionType,
    formik,
  };
};

export const useDownload = () => {
  const downloadMonthlyReportCsv = (monthlyReport: any) => {
    api.downloadMonthlyReportCsv(monthlyReport, '年次売上管理表.csv');
  };

  const downloadMonthlyReportExcel = (monthlyReport: any) => {
    api.downloadMonthlyReportExcel(monthlyReport, '年次売上管理表.xlsx');
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
  };
};

export default useMonthlyReport;
