import React from "react";
import { css } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import productColor from "components/styles";
import IconLabelButton from "components/molecules/IconLabelButton";
import { useHistory } from "react-router-dom";
import Dropdown from "components/atoms/Icon/Dropdown";
import DropdownBox from "components/molecules/Dropdown/DropdownBox";
import DropdownItem from "components/molecules/Dropdown/DropdownItem";
import ContextMenu from "components/molecules/ContextMenu";
import { ContextMenuType } from "components/molecules/ContextMenu/type";
import { DetailRole } from "api/masterRole";

const styles = {
  customStyle: css(
    {
      marginRight: " 30px",
      justifyContent: "flex-end",
      zIndex: "revert",
      position: "relative",
    },
    css`
      button:hover {
        background-color: transparent;
      }
    `,
  ),
  customButton: css({
    backgroundColor: "transparent",
  }),
};

const ButtonNavigation: React.FC<{
  downloadStaffCsv: () => void;
  downloadStaffSalaryCsv: () => void;
  downloadStaffManagerCsv: () => void;
  downloadStaffHolidayCsv: () => void;
  detailRole: DetailRole;
}> = ({ downloadStaffCsv, downloadStaffManagerCsv, downloadStaffSalaryCsv, downloadStaffHolidayCsv, detailRole }) => {
  const history = useHistory();

  const contextMenuButtons = [
    {
      isRed: false,
      isSelectable: true,
      isHidden: false,
      label: "スタッフ情報",
      stamptype: 0,
      onClick: () => {
        return downloadStaffCsv();
      },
    },
    {
      isRed: false,
      isSelectable: true,
      isHidden: false,
      label: "兼務情報",
      stamptype: 0,
      onClick: () => {
        return downloadStaffManagerCsv();
      },
    },
    ...(detailRole.usabilityGeneralItem1 === 1
      ? [
          {
            isRed: false,
            isSelectable: true,
            isHidden: false,
            label: "人件費情報",
            stamptype: 0,
            onClick: () => {
              return downloadStaffSalaryCsv();
            },
          },
        ]
      : []),
    {
      isRed: false,
      isSelectable: true,
      isHidden: false,
      label: "介護・看護休暇情報",
      stamptype: 0,
      onClick: () => {
        return downloadStaffHolidayCsv();
      },
    },
  ];

  return (
    <FlexBox customStyle={styles.customStyle}>
      {detailRole.editable === 1 && (
        <React.Fragment>
          <FlexBoxItem marginLeft="10px">
            <IconLabelButton
              textColor={productColor.primary}
              onClick={() => {
                history.push("/staffs/add");
              }}
              iconType="addCircle"
              text="追加"
            />
          </FlexBoxItem>
          {detailRole.importFlag === 1 && (
            <FlexBoxItem marginLeft="10px">
              <IconLabelButton
                textColor={productColor.primary}
                onClick={() => {
                  history.push("/staffs/csv");
                }}
                iconType="addCircle"
                text="インポート"
              />
            </FlexBoxItem>
          )}
        </React.Fragment>
      )}
      {detailRole.downloadFlag === 1 && (
        <FlexBoxItem marginLeft="10px">
          {/* <IconLabelButton onClick={() => downloadStaffCsv()} iconType="download" text="CSVダウンロード" /> */}
          <ContextMenu
            text="CSVダウンロード"
            buttons={contextMenuButtons}
            position="bottom"
            customStyle={styles.customButton}
            isButtonText={true}
            hiddenDropIcon={true}
          />
        </FlexBoxItem>
      )}
    </FlexBox>
  );
};

export default ButtonNavigation;
