import { useState, useEffect, useContext } from "react";
import { StampStaffDomain } from "domain/master/attend/stampStaff";
import {
  getAttendStampStaffItem,
  getGpsAddress,
  postGpsStamp,
} from "api/attendStamp";
import useToastNotification from "hooks/useToastNotification";
import { TimeRecorderContext } from "components/organismos/RecorderV1/RecorderV1";
// import Geocode from 'react-geocode';

// Geocode.setApiKey('AIzaSyDg0JgU73D5xrFeROkDxbWQd1wEB_m3i2E');
// Geocode.setRegion('ja');

export const useGPSRecorder = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  fetchHistoryData: any
) => {
  const [stampStaff, setStampStaff] = useState<StampStaffDomain | null>(null);
  const orgCode = sessionStorage.getItem("loginUser.orgCode") || "";
  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";
  const { successNotification, errorNotification } = useToastNotification();
  const [isRemote, setIsRemote] = useState<boolean>(false);
  const contextObject = useContext(TimeRecorderContext);
  let stampType = 0;
  let businessId = "";

  useEffect(() => {
    setIsLoading(true);
    getAttendStampStaffItem(orgCode, staffCode)
      .then((response: any) => {
        contextObject.setRemote(response.useRemote);
        setStampStaff(response);
        setIsLoading(false);
        setIsRemote(response.isRemote);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgCode, staffCode]);

  const handleStampEvent = (stamp: string, id: string, flagRemote?: boolean) => {
    setIsLoading(true);
    if (stamp === "in") {
      stampType = 0;
      businessId = "";
    } else if (stamp === "recess_in") {
      stampType = 1;
      businessId = "";
    } else if (stamp === "recess_out") {
      stampType = 2;
      businessId = "";
    } else if (stamp === "out") {
      stampType = 3;
      businessId = "";
    } else if (stamp === "business") {
      stampType = 4;
      businessId = id;
    } else if (stamp === "current") {
      stampType = 6;
      businessId = "";
    }
    // console.log('出社打刻');
    const geoOptions = {
      maximumAge: 0,
      timeout: 10000,
      enableHighAccuracy: true,
    };
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        successGeoLacation(pos, flagRemote)
      },
      failGeoLacation,
      geoOptions
    );
  };

  const successGeoLacation = (pos: any, flagRemote?: boolean) => {
    getGpsAddress(
      `${String(pos.coords.latitude)},${String(pos.coords.longitude)}`
    )
      .then((response: any) => {
        let gAddress: string = response.results[0].formatted_address;
        gAddress = gAddress.replace("日本、", "");
        const param = {
          orgCode,
          staffCode,
          procesFlg: stampType,
          businessId,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          gpsAddress: gAddress,
          isRemote: flagRemote === undefined ? isRemote : flagRemote
        };
        postGpsStamp(param)
          .then((result: any) => {
            getAttendStampStaffItem(orgCode, staffCode)
              .then((result2: any) => {
                setStampStaff(result2);
                fetchHistoryData();
                successNotification(
                  result.stampResultInfo
                    .split("<h4>")
                    .join("")
                    .split("</h4>")
                    .join("")
                );
                setIsLoading(false);
                setIsRemote(result2.isRemote);
              })
              .catch((error: any) => {
                setIsLoading(false);
                if (error.response.status === 400) {
                  if (error.response.data.errors && error.response.data.errors.length > 0) {
                    errorNotification(
                      error.response.data.errors.map((x: any) => x.defaultMessage)
                    );
                  } else if (error.response.data.defaultMessage) {
                    errorNotification(error.response.data.defaultMessage);
                  } else {
                    errorNotification(error.response.data.message);
                  }
                } else {
                  errorNotification("サーバー側でエラーが発生しました。");
                }
              });
          })
          .catch((error: any) => {
            setIsLoading(false);
            if (error.response.status === 400) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                errorNotification(
                  error.response.data.errors.map((x: any) => x.defaultMessage)
                );
              } else if (error.response.data.defaultMessage) {
                errorNotification(error.response.data.defaultMessage);
              } else {
                errorNotification(error.response.data.message);
              }
            } else {
              errorNotification("サーバー側でエラーが発生しました。");
            }
          });
      })
      .catch((err: any) => {
        errorNotification("位置情報の取得に失敗しました。");
        setIsLoading(false);
      });
  };

  const failGeoLacation = (err: any) => {
    if (err.code === 1) {
      errorNotification(
        "位置情報の利用が許可されていませんので、許可してください。"
      );
    } else if (err.code === 2) {
      errorNotification("位置情報が判定できませんでした。");
    } else if (err.code === 3) {
      errorNotification("タイムアウトしました。");
    } else {
      errorNotification("位置情報の取得に失敗しました。");
    }
    // console.log(err);
    setIsLoading(false);
  };

  return {
    stampStaff,
    handleStampEvent,
  };
};

export default useGPSRecorder;
