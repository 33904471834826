/** @jsx jsx */
import React, { useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { Row, Col } from "react-grid-system";
import FlexBox from "components/atoms/FlexBox";
import FormPadding from "components/atoms/Form/FormPadding";
import BodyText from "components/atoms/BodyText";
import PrimaryButton, { SecondaryButton } from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import { useDailyShiftContext } from "./contextHook";
import moment from "moment";
import Checkbox from "components/molecules/Checkbox";
import SelectForm from "components/molecules/SelectForm";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import Calendar from "components/molecules/CalendarV1";
import ModalRegisterStaff from "components/pages/master/shift/daily/Modals/ModalRegisterStaff";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import { OpenDate } from "api/employment";
import { utilityColor } from "components/styles";
const Filters: React.FC = () => {
  const {
    stateFilter,
    dispatch,
    fetchEmploymentOptions,
    fetchDrawingInfo,
    detailRole,
    openRegisterModal,
    setOpenRegisterModal,
    setOpenConfirmModal,
    dailyShift,
    employmentSelectOpenDateList,
    selectedEmploymentSelectOpenDate,
    showLaborCost,
    filters: { downloadPdf, checkall, setCheckall },
  } = useDailyShiftContext();

  return (
    <FormPadding isSubForm={window.screen.width < 800}>
      <Row>
        <Col md={2} lg={1}>
          <div css={css({ marginTop: "18px" })}>
            <BodyText>期間</BodyText>
          </div>
        </Col>
        <Col md={10} lg={11}>
          <FlexBox>
            <Col md={3} xl={2}>
              <FlexBox>
                <FlexBoxItem>
                  <Calendar
                    openActionChevron={true}
                    date={moment(stateFilter.dateFromStr).toDate()}
                    setDate={async (date) => {
                      if (date) {
                        let newDate = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
                        sessionStorage.setItem("attendStampList.dailyShift.targetDateFrom", newDate);
                        if (newDate !== stateFilter.dateFromStr) {
                          dispatch({
                            type: "ON_CHANGE_DATE_FROM",
                            payload: newDate,
                          });
                        }
                      }
                    }}
                  />
                </FlexBoxItem>
              </FlexBox>
            </Col>
            <Col md={9}>
              <div css={css({ display: "flex", alignItems: "baseline", marginLeft: "20px", marginTop: "-8px" })}>
                <Checkbox
                  id="1"
                  name="isAttendExists"
                  label="シフト・実績有る人のみ表示"
                  value={"1"}
                  checked={stateFilter.isAttendExists === "1" ? true : false}
                  onChange={() => {
                    sessionStorage.setItem(
                      "attendStampList.attendFlag",
                      stateFilter.isAttendExists === "1" ? "0" : "1",
                    );
                    fetchDrawingInfo(
                      stateFilter.selectedOrg,
                      stateFilter.dateFromStr,
                      stateFilter.isAttendExists === "1" ? "0" : "1",
                      stateFilter.employmentOptions,
                      stateFilter.cumulativeClosingDate,
                    );
                    dispatch({
                      type: "ON_CHANGE_ATTEND_EXISTS",
                      payload: stateFilter.isAttendExists === "1" ? "0" : "1",
                    });
                    // setAttendExists(isAttendExists === "1" ? "0" : "1");
                  }}
                />
              </div>
            </Col>
          </FlexBox>
        </Col>
      </Row>

      <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: "12px" })}>
            <BodyText>組織</BodyText>
          </div>
        </Col>
        <Col lg={3} md={3}>
          <SelectForm
            label=""
            width="800px"
            name="attendEmployment"
            value={stateFilter.selectedOrg}
            setValue={async (orgCode) => {
              // setSelectedOrg(orgCode);
              dispatch({
                type: "ON_CHANGE_SELECTED_ORG",
                payload: orgCode,
              });
              let employmentOptions = await fetchEmploymentOptions(orgCode, stateFilter.dateFromStr);
              await fetchDrawingInfo(
                orgCode,
                stateFilter.dateFromStr,
                stateFilter.isAttendExists,
                employmentOptions,
                stateFilter.cumulativeClosingDate,
              );
              sessionStorage.setItem("attendStampList.dailyShift.orgCode", orgCode);
            }}
            options={stateFilter.orgs}
            zIndex={90}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={1} md={2}>
          <div css={css({ marginTop: "8px" })}>
            <BodyText>雇用形態</BodyText>
          </div>
        </Col>
        <Col lg={11} md={10} css={css({ display: "flex", flexWrap: "wrap" })}>
          <div css={css({ padding: "8px 10px 5px 0" })}>
            {stateFilter.employmentOptions && stateFilter.employmentOptions?.length > 0 && (
              <Checkbox
                id={"all"}
                name={"checkall"}
                label="すべて"
                value={"1"}
                checked={checkall}
                onChange={(event) => {
                  let newEmploymentOptions = stateFilter.employmentOptions?.map((option) => ({
                    ...option,
                    checked: event.target.checked,
                  }));

                  dispatch({
                    type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
                    payload: newEmploymentOptions,
                  });

                  sessionStorage.setItem(`attendStampList.dailyShift.employmentOptions`, "all");

                  fetchDrawingInfo(
                    stateFilter.selectedOrg,
                    stateFilter.dateFromStr,
                    stateFilter.isAttendExists,
                    newEmploymentOptions,
                    stateFilter.cumulativeClosingDate,
                  );

                  setCheckall(!checkall);
                }}
              />
            )}
          </div>
          {stateFilter.employmentOptions?.length > 0 &&
            stateFilter.employmentOptions.map((option, key) => {
              return (
                <div key={key} css={css({ padding: "8px 10px 5px 0" })}>
                  <Checkbox
                    id={option.id}
                    name={option.label}
                    label={option.label}
                    value={option.value}
                    checked={option.checked}
                    onChange={(event) => {
                      let newEmploymentOptions = [...stateFilter.employmentOptions];
                      newEmploymentOptions[key].checked = event.target.checked;
                      dispatch({
                        type: "ON_CHANGE_EMPLOYMENT_OPTIONS",
                        payload: newEmploymentOptions,
                      });

                      sessionStorage.setItem(
                        `attendStampList.dailyShift.employmentOptions`,
                        newEmploymentOptions
                          .filter((option) => option.checked)
                          .map((option) => option.id)
                          .join(),
                      );

                      fetchDrawingInfo(
                        stateFilter.selectedOrg,
                        stateFilter.dateFromStr,
                        stateFilter.isAttendExists,
                        newEmploymentOptions,
                        stateFilter.cumulativeClosingDate,
                      );
                    }}
                  />
                </div>
              );
            })}
        </Col>
      </Row>

      {showLaborCost && (
        <Row>
          <Col lg={1} md={2}>
            <div css={css({ marginTop: "8px" })}>
              <BodyText>累計起算日</BodyText>
            </div>
          </Col>
          <Col lg={1} md={1} css={css({ display: "flex", flexWrap: "wrap", zIndex: 90 })}>
            <VerticalLabelSelectForm
              label=""
              name="cumulativeClosingDate"
              value={stateFilter.cumulativeClosingDate}
              setValue={(closingDate) => {
                dispatch({
                  type: "ON_CHANGE_CUMULATIVE_CLOSING_DATE",
                  payload: closingDate,
                });

                sessionStorage.setItem("attendStampList.dailyShift.cumulativeClosingDate", closingDate);

                fetchDrawingInfo(
                  stateFilter.selectedOrg,
                  stateFilter.dateFromStr,
                  stateFilter.isAttendExists,
                  stateFilter.employmentOptions,
                  closingDate,
                );
              }}
              options={employmentSelectOpenDateList.map((openDate: OpenDate) => ({
                value: openDate.closingDate.toString(),
                label: openDate.closingName,
              }))}
              width="100%"
            />
          </Col>
          <Col lg={10} md={9} css={css({ display: "flex", alignItems: "center" })}>
            <BodyText
              color={utilityColor.error}
            >{`（累計期間：${selectedEmploymentSelectOpenDate?.openDate}～${selectedEmploymentSelectOpenDate?.closeDate}）`}</BodyText>
          </Col>
        </Row>
      )}

      <Row>
        <FlexBox customStyle={css({ margin: "1rem 0", width: "100%", justifyContent: "flex-end" })}>
          <PrimaryButton
            customStyle={css({ marginRight: "10px" })}
            text="支援スタッフ追加"
            onClick={() => {
              setOpenRegisterModal(true);
            }}
          />

          {dailyShift?.isShiftConfirm ? (
            <React.Fragment>
              {detailRole.usabilityGeneralItem2 ? (
                <SecondaryButton
                  text="シフト確定解除"
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {detailRole.usabilityGeneralItem1 ? (
                <PrimaryButton
                  text="シフト確定"
                  disabled={dailyShift.isAchievementConfirm || dailyShift.isShiftConfirm}
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                />
              ) : null}
            </React.Fragment>
          )}
          {detailRole.downloadFlag ? (
            <ClickableIconButton
              onClick={downloadPdf}
              additionalCss={css({ margin: "0 0 0 10px", cursor: "pointer" })}
            >
              <span className="print">
                <Icon type="printer" color="" size="24px" />
              </span>
            </ClickableIconButton>
          ) : null}
        </FlexBox>
      </Row>
      {openRegisterModal && <ModalRegisterStaff />}
    </FormPadding>
  );
};

export default Filters;
