import ApiClient from './ApiClient';
import {AttendSetting36Agreement} from 'domain/master/attend/attendSetting36Agreement';
import { stringify } from 'querystring';

export const getSetting36Agreement = async (targetYear: number, agreementsCode: string): Promise<Array<AttendSetting36Agreement>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

    const params = {
        targetYear,
        agreementsCode,
    }

    const response = await ApiClient.get(`/v2/attend36Agreements/${companyCode}`, params);
    return response.data;
};

export const getSetting36AgreementPrevious = async (params: any): Promise<Array<AttendSetting36Agreement>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

    params = {
      ...params,
      loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || ''
    }

    const response = await ApiClient.post(`/v2/attend36Agreements/copy/${companyCode}`, {}, params);
    return response.data;
};

export const postSetting36Agreement = async (values: any): Promise<Array<any>> => {
    const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

    const response = await ApiClient.post(`/v2/attend36Agreements/${companyCode}`, values);
    return response.data;
};


export const getListAgreement = async (
  yearFrom: number,
  yearTo: number,
): Promise<Array<AttendSetting36Agreement>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
      loginStaffCode: sessionStorage.getItem('loginUser.staffCode') || '',
      yearFrom,
      yearTo
  };
  const response = await ApiClient.get(`/v2/attend36Agreements/list/${companyCode}`, params);
  return response.data;
};
  
  
export const deleteById = async (agreementsId: string): Promise<any> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const params = {
    agreementsId,
  };
  const reponse = await ApiClient.delete(`/v2/attend36Agreements/delete/${companyCode}`, params);
  return reponse.data;
};
  
export const getSetting36AgreementById = async (agreementsId: string): Promise<Array<AttendSetting36Agreement>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';

  const params = {
    agreementsId,
  }

  const response = await ApiClient.get(`/v2/attend36Agreements/id/${companyCode}`, params);
  return response.data;
};

export const getAgreementsForSelectBoxByYear = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v2/attend36Agreements/select/list/year/${companyCode}`, {}, params);
  return response.data;
};

export const getAgreementsForSelectBoxByMonth = async (params: any): Promise<Array<any>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }
  const response = await ApiClient.get(`/v2/attend36Agreements/select/list/month/${companyCode}`, {}, params);
  return response.data;
};

export const getSetting36AgreementByMonthAndStaffCode = async (params: any): Promise<Array<AttendSetting36Agreement>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }

  const response = await ApiClient.get(`/v2/attend36Agreements/month/staff/${companyCode}`, params);
  return response.data;
};

export const getSetting36AgreementByYearAndStaffCode = async (params: any): Promise<Array<AttendSetting36Agreement>> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const loginStaffCode = sessionStorage.getItem('loginUser.staffCode') || '';
  params = {
    ...params,
    loginStaffCode,
  }

  const response = await ApiClient.get(`/v2/attend36Agreements/year/staff/${companyCode}`, params);
  return response.data;
};


export default getSetting36Agreement;
