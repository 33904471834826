/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import moment from 'moment';

import Checkbox from 'components/molecules/Checkbox';
import DateRangePicker from 'components/molecules/DateRangePicker';
import Table from 'components/molecules/Table';
import ManagerDomain from 'domain/manager';
import { useManagerEdit } from './hooks';
import {SecondaryButton} from 'components/atoms/Button';
import CalendarRangeV2 from 'components/molecules/CalendarV1/CalendarRangeV2';

const ManagerEdit: React.FC<{
  manager: ManagerDomain;
  setManager: (manager: ManagerDomain) => void;
  deleteManager: () => void;
}> = ({
  manager, deleteManager, setManager,
}) => {
  const {
    isEndDateUndecided,
    toggleEndDateUndecided,
    changeStartDate,
    changeEndDate,
  } = useManagerEdit(manager, setManager);
  return (
    <React.Fragment>
      {
          <tr>
            <Table.Cell customStyle={css({ textAlign: 'center' })}>
              <SecondaryButton
                minWidth={'40px'}
                text="削除"
                onClick={deleteManager}
                customStyle={css({ borderRadius: '3px', fontSize: '12px', lineHeight: '20px', padding: '0 5px', height: '24px' })}
              />
            </Table.Cell>
            <Table.Cell customStyle={css({ textAlign: 'left' })}>
              {manager.staffCode}
            </Table.Cell>
            <Table.Cell customStyle={css({ textAlign: 'left' })}>
              {manager.staffName}
            </Table.Cell>
            <Table.Cell>
            {/* <DateRangePicker customStyle={css({ display: 'inline-flex', marginRight: '10px', width: '300px' })}
              startDate={moment(manager.startDate).isValid()
                ? moment(manager.startDate).toDate()
                : new Date()}
              setStartDate={changeStartDate}
              endDate={manager.endDate
                ? moment(manager.endDate).toDate()
                : undefined}
              setEndDate={changeEndDate}
              marginLeft="8px"
              showCharEquivalent={true}
              inlineDateRangePicker={true}
            /> */}
            <div style={{display : 'flex'}}>
              <CalendarRangeV2
                customStyle={css({
                  marginRight : '8px'
                })}
                startDate={moment(manager.startDate).isValid()
                ? moment(manager.startDate).toDate()
                : new Date()}
                canNullEndDate={true}
                endDate={manager.endDate
                  ? moment(manager.endDate).toDate()
                  : undefined}
                  onChangeStart={changeStartDate}
                  onChangeEnd={changeEndDate}
              />
              <Checkbox
                id={JSON.stringify(manager)}
                name={JSON.stringify(manager)}
                label="未定"
                value="check"
                checked={isEndDateUndecided}
                onChange={toggleEndDateUndecided}
              />
            </div>
            </Table.Cell>
          </tr>
      }
    </React.Fragment>
  );
};

export default ManagerEdit;
