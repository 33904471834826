/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import LoginFormContainer from 'components/organismos/login/LoginFormContainer';
import ForgotPasswordInputForm from 'components/organismos/login/ForgotPasswordForm';

const ForgotPasswordPage: React.FC = () => (
  <React.Fragment>
    <LoginFormContainer 
      titleForm="パスワードを忘れてしまいましたか？"
      noteForm= "パスワードをリセットするには、メールアドレスを入力して下さい。<br/>入力されたメールアドレスが正しい場合、パスワードをリセットする認証コードが届きます。"
    >
      <ForgotPasswordInputForm/>
    </LoginFormContainer>  
  </React.Fragment>
);

export default ForgotPasswordPage;
