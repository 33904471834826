/** @jsx jsx */
import React from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";

import { grayScale } from "components/styles";
import useIsMobile from "hooks/useIsMobile";
import { notMaxScreen } from "utility/mediaQueries";

const style = css({
  backgroundColor: "#FFFFFF",
  [notMaxScreen("md")]: {
    border: `1px solid ${grayScale.gray20}`,
  },
});

const subStyle = css({
  margin: "5px 10px",
  padding: "5px 10px",
  backgroundColor: "#FFFFFF",
  border: `1px solid ${grayScale.gray20}`,
});

/**
 * フォームのコンテンツ
 */
const FormContents: React.FC<{
  isSubForm?: boolean;
  customStyle?: any;
  titleForm?: string;
  customTitleStyle?: SerializedStyles;
}> = ({ children, isSubForm = false, customStyle, titleForm = "", customTitleStyle = css({}) }) => {
  const isMobile = useIsMobile();

  return (
    <div css={isSubForm ? css(subStyle, customStyle) : css(style, customStyle)}>
      <div
        css={[
          css({
            fontWeight: "bold",
            fontSize: "16px",
            marginBottom: isMobile ? 0 : "25px",
            color: grayScale.gray60,
          }),
          customTitleStyle,
        ]}
      >
        {titleForm}
      </div>
      {children}
    </div>
  );
};

export default FormContents;
