import {
  createContext, useContext, useEffect, useState,
} from 'react';
import moment from 'moment';
import { Event } from 'react-big-calendar';

import * as shiftApi from 'api/shift';

import { grayScale } from 'components/styles';
import { OptionType } from 'components/atoms/Select';

import useOrgTreesOptions from 'hooks/useOrgTreesOptions';

export type ShiftMonthCalendarEvent = Event & {
  isAlert: boolean;
  color: string;
}

type ShiftMonthCalendarContext = {
  storeValue: string;
  setStoreValue: (value: string) => void;
  storeOptions: Array<OptionType>;
  events: Array<ShiftMonthCalendarEvent>;
  setEvents: (events: Array<ShiftMonthCalendarEvent>) => void;
}

export const shiftMonthCalendarContext = createContext<ShiftMonthCalendarContext>(
  {} as ShiftMonthCalendarContext,
);

/**
 * シフト 月表示カレンダーのContextを取得
 * (子コンポーネントで使用する)
 */
export const useShiftMonthCalendarContext = (): ShiftMonthCalendarContext => {
  const context = useContext(shiftMonthCalendarContext);
  return context;
};

/**
 * Providerへ渡すContextの初期値
 */
export const useInitialShiftMonthCalendarContext = (): ShiftMonthCalendarContext => {
  const [storeValue, setStoreValue] = useState('');
  const storeOptions = useOrgTreesOptions();

  const [events, setEvents] = useState<Array<ShiftMonthCalendarEvent>>([]);

  return {
    storeValue,
    setStoreValue,
    storeOptions,
    events,
    setEvents,
  };
};

/**
 * 月データを取得
 */
export const useMonthCalendarEvents = (
  date: Date,
  orgCode: string,
  setEvents: (events: Array<ShiftMonthCalendarEvent>) => void,
) => {
  useEffect(() => {
    const fetchMonthryShift = async () => {
      const monthlyShift = await shiftApi.getMonthry(orgCode, moment(date).startOf('month').format('YYYY-MM-DD'), moment(date).endOf('month').format('YYYY-MM-DD'));
      setEvents(Object.keys(monthlyShift).map((day) => {
        const event: ShiftMonthCalendarEvent = {
          allDay: true,
          title: `${monthlyShift[day].number}名`,
          isAlert: monthlyShift[day].isAlert,
          color: moment().diff(moment(day), 'day') > 0 ? grayScale.gray20 : '',
          start: moment(day).toDate(),
          end: moment(day).toDate(),
        };
        return event;
      }));
    };
    if (orgCode) {
      fetchMonthryShift();
    }
  }, [date, orgCode, setEvents]);
};

export default {};
