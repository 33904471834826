/** @jsx jsx */
import React, { useState, useEffect, RefObject } from "react";
import { css, jsx } from "@emotion/core";
import { layer } from "components/styles";
import SubContextMenuBox from "./SubContextMenuBox";
import ContextMenuItem from "./ContextMenuItem";
import { ContextMenuType } from "./type";

const styles = {
  box: (left: number, top: number, minWidth: string) =>
    css({
      position: "absolute",
      top,
      left,
      whiteSpace: "nowrap",
      zIndex: 100,
      cursor: "pointer",
      boxShadow: layer.layer06,
      minWidth,
    }),
};

// Forked DropdownBox - add reponsive to position
const ContextMenuBox: React.FC<{
  isVisible: boolean;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  parentEle: HTMLElement | null;
  parentRef: RefObject<any>;
  items: ContextMenuType[];
  minWidth?: string;
  minusLeft?: number;
  position?: "bottom" | "right";
}> = ({
  items,
  isVisible,
  setIsComponentVisible,
  parentEle,
  parentRef,
  minWidth = "160px",
  minusLeft = 0,
  position,
}) => {
  const [boxElement, setBoxElement] = useState<HTMLElement | null>(null);
  const [boxLeft, setBoxLeft] = useState<number>(0);
  const [boxTop, setBoxTop] = useState<number>(0);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    if (!boxElement) {
      return;
    }

    const clientRect = boxElement && boxElement.getBoundingClientRect();
    const parentRect = parentEle?.getBoundingClientRect();
    if (!parentRect) {
      return;
    }
    const extraSpaceForDesktop = window.screen.availWidth > 1200 ? 100 : 50;
    const remainWidth = window.screen.availWidth - parentRect.right - extraSpaceForDesktop;
    const remainHeight = window.screen.availHeight - parentRect.bottom - extraSpaceForDesktop;

    // 画面外に要素が出る場合に位置を調整する
    if (remainWidth < clientRect.width) {
      setBoxLeft(parentRect.left - clientRect.width);
    } else {
      position == "bottom" ? setBoxLeft(parentRect.left) : setBoxLeft(parentRect.right);
    }

    if (remainHeight < clientRect.height) {
      setBoxTop(parentRect.bottom - clientRect.height + window.scrollY);
    } else {
      setBoxTop(parentRect.bottom + window.scrollY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxElement]);

  useEffect(() => {}, [textSearch]);
  return (
    <React.Fragment>
      {isVisible && (
        <div css={styles.box(boxLeft - minusLeft, boxTop, minWidth)} ref={(ref) => setBoxElement(ref)}>
          <div ref={parentRef}>
            {items
              .filter((item) => !item.isHidden)
              .map((item, index) => (
                <div key={index} style={{ position: "relative" }}>
                  {item.subMenu ? (
                    <SubContextMenuBox
                      text={item.label}
                      textSearch={textSearch}
                      setTextSearch={setTextSearch}
                      showSearchBox={item.showSearchBox}
                      stamptype={item.stamptype}
                    >
                      {textSearch
                        ? item.subMenu
                            .filter((val) => val.label.indexOf(textSearch) > -1)
                            .map((subItem) => (
                              <ContextMenuItem
                                key={subItem.label}
                                label={subItem.label}
                                disabled={!subItem.isSelectable}
                                onClick={() => {
                                  if (subItem.onClick) {
                                    subItem.onClick();
                                  }
                                  setIsComponentVisible(false);
                                }}
                              />
                            ))
                        : item.subMenu.map((subItem) => (
                            <ContextMenuItem
                              key={subItem.label}
                              label={subItem.label}
                              disabled={!subItem.isSelectable}
                              onClick={() => {
                                if (subItem.onClick) {
                                  subItem.onClick();
                                }
                                setIsComponentVisible(false);
                              }}
                            />
                          ))}
                    </SubContextMenuBox>
                  ) : (
                    <ContextMenuItem
                      label={item.label}
                      disabled={!item.isSelectable}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                        setIsComponentVisible(false);
                      }}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ContextMenuBox;
