import React, { SVGProps } from 'react';

const HrProgress: React.FC<SVGProps<SVGSVGElement>> = props => (
    <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M29 24C30.66 24 32 22.66 32 21C32 19.34 30.66 18 29 18C27.34 18 26 19.34 26 21C26 22.66 27.34 24 29 24ZM32.25 24.8C33.32 23.88 34 22.52 34 21C34 18.24 31.76 16 29 16C26.24 16 24 18.24 24 21C24 22.52 24.68 23.88 25.75 24.8C23.52 25.97 22 28.31 22 31H24C24 28.24 26.24 26 29 26C31.76 26 34 28.24 34 31H36C36 28.31 34.48 25.97 32.25 24.8ZM23 34C22.45 34 22 34.45 22 35C22 35.55 22.45 36 23 36H35C35.55 36 36 35.55 36 35C36 34.45 35.55 34 35 34H23ZM23 38C22.45 38 22 38.45 22 39C22 39.55 22.45 40 23 40H29C29.55 40 30 39.55 30 39C30 38.45 29.55 38 29 38H23Z" fill="#003993"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36 14H22C20.9 14 20 14.9 20 16V40C20 41.1 20.9 42 22 42H36C37.1 42 38 41.1 38 40V16C38 14.9 37.1 14 36 14ZM22 12C19.79 12 18 13.79 18 16V40C18 42.21 19.79 44 22 44H36C38.21 44 40 42.21 40 40V16C40 13.79 38.21 12 36 12H22Z" fill="#003993"/>
        <path d="M20 30H38V32H20V30Z" fill="#003993"/>
        <path d="M30 2H4C2.9 2 2 2.9 2 4V30C2 31.1 2.9 32 4 32H18V34H4C1.79 34 0 32.21 0 30V4C0 1.79 1.79 0 4 0H30C32.21 0 34 1.79 34 4V12H32V4C32 2.9 31.1 2 30 2Z" fill="#0071BC"/>
        <path d="M2 8H32V10H2V8Z" fill="#0071BC"/>
        <path d="M6 5C6 5.55 5.55 6 5 6C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4C5.55 4 6 4.45 6 5Z" fill="#0071BC"/>
        <path d="M10 5C10 5.55 9.55 6 9 6C8.45 6 8 5.55 8 5C8 4.45 8.45 4 9 4C9.55 4 10 4.45 10 5Z" fill="#0071BC"/>
        <path d="M14 5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5C12 4.45 12.45 4 13 4C13.55 4 14 4.45 14 5Z" fill="#0071BC"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 14H6V20H14V14ZM6 12C4.9 12 4 12.9 4 14V20C4 21.1 4.9 22 6 22H14C15.1 22 16 21.1 16 20V14C16 12.9 15.1 12 14 12H6Z" fill="#0071BC"/>
        <path d="M4 25C4 24.45 4.45 24 5 24H15C15.55 24 16 24.45 16 25C16 25.55 15.55 26 15 26H5C4.45 26 4 25.55 4 25Z" fill="#0071BC"/>
        <path d="M4 29C4 28.45 4.45 28 5 28H11C11.55 28 12 28.45 12 29C12 29.55 11.55 30 11 30H5C4.45 30 4 29.55 4 29Z" fill="#0071BC"/>
    </svg>

);

export default HrProgress;
