import React from "react";
import AttendStampForm from "components/organismos/master/attend/AttentStampForm";
import SidebarTemplate from "components/templates/SidebarTemplate";

const AttendStampList = () => {
  const attendFlag = sessionStorage.getItem("attendStampList.attendFlag") || "0";

  return (
    <SidebarTemplate pageTitle={attendFlag === "0" ? "打刻データ確認" : "勤務組織打刻データ確認"}>
      <AttendStampForm />
    </SidebarTemplate>
  );
};

export default AttendStampList;
