/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import useSpecialSalaryPatternOptions from "hooks/useSpecialSalaryPatternOptions";
import SelectForm from "components/molecules/SelectForm";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import { textFontSize } from "components/styles";
import { SpecialSalaryItem } from "domain/master/labor/organizationSpecialSalary";
import MoneyInput from "components/atoms/Form/MoneyInput";

const OrganizationSpecialSalaryEdit: React.FC<{
  formik: any;
  specialSalary: SpecialSalaryItem;
  index: number;
  deleteSpecialSalary: () => void;
}> = ({ formik, specialSalary, index, deleteSpecialSalary }) => {
  const specialSalaryPatternOptions = useSpecialSalaryPatternOptions();

  return (
    <tr>
      <td>
        <SelectForm
          label=""
          name="specialSalaryPatternId"
          value={specialSalary.id.specialSalaryPatternId}
          setValue={(val) => formik.setFieldValue(`orgSpecialList[${index}].id.specialSalaryPatternId`, val)}
          options={specialSalaryPatternOptions}
          isHiddenLabel={true}
        />
      </td>
      <td>
        <MoneyInput
          name="addSalary"
          value={specialSalary.addSalary}
          label="円"
          onChange={(e) => formik.setFieldValue(`orgSpecialList[${index}].addSalary`, Number(e.target.value))}
          min={0}
          max={999999999}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <ClickableIconButton onClick={deleteSpecialSalary}>
          <Icon type="delete" color={textFontSize.re} />
        </ClickableIconButton>
      </td>
    </tr>
  );
};

export default OrganizationSpecialSalaryEdit;
