import React, { SVGProps } from 'react';

const SafetyConfirmation: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="44" height="24" viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.335 24.0001C6.7856 24.0001 5.32427 23.6022 4.09116 22.8186C1.92519 21.4401 0.583978 18.9083 0.672058 16.3645C0.736116 14.56 1.51682 13.1374 2.46969 12.0001C1.53684 10.8789 0.732112 9.43613 0.672058 7.63975C0.583978 5.09588 1.92519 2.56005 4.09116 1.18161C6.22109 -0.176725 9.03163 -0.373644 11.7981 0.631045C14.1723 1.49508 16.1541 3.05837 17.9437 4.62167C20.0296 6.44216 23.4047 10.5453 23.5448 10.7221C24.1533 11.4656 24.1533 12.5346 23.5448 13.2781C23.4007 13.4509 20.0296 17.558 17.9437 19.3785C16.1541 20.9378 14.1723 22.5051 11.7981 23.3692C10.6331 23.7911 9.46002 24.0001 8.335 24.0001ZM8.33901 4.02287C7.54228 4.02287 6.81362 4.20774 6.2331 4.57746C5.26022 5.19635 4.63165 6.36982 4.67168 7.49909C4.70371 8.43144 5.28023 9.32361 6.60543 10.489C7.03783 10.8708 7.28605 11.4214 7.29006 11.9961C7.29006 12.5748 7.04183 13.1253 6.61344 13.5071C5.26822 14.6967 4.70772 15.5607 4.67569 16.5011C4.63565 17.6304 5.26422 18.8038 6.2371 19.4227C7.29806 20.0979 8.86348 20.1582 10.4329 19.5875C12.0944 18.9847 13.5878 17.8514 15.3173 16.3444C16.4303 15.3718 18.1679 13.4187 19.381 12.0001C18.256 10.6819 16.4904 8.68061 15.3173 7.65582C13.5878 6.14477 12.0944 5.0155 10.4329 4.41269C9.72026 4.15147 9.00361 4.02287 8.33901 4.02287Z" fill="url(#paint0_linear)"/>
    <path d="M35.6683 24C34.5432 24 33.3702 23.791 32.2051 23.365C29.831 22.501 27.8492 20.9377 26.0595 19.3744C23.9737 17.5539 20.5986 13.4507 20.4545 13.2739C19.8459 12.5304 19.8459 11.4614 20.4545 10.718C20.5986 10.5452 23.9696 6.43801 26.0595 4.61751C27.8492 3.05823 29.831 1.49092 32.2051 0.626887C34.9716 -0.38182 37.7822 -0.180882 39.9121 1.17746C42.0781 2.55589 43.4193 5.0877 43.3312 7.63158C43.2711 9.43198 42.4864 10.8586 41.5336 11.9959C42.4664 13.1172 43.2711 14.5599 43.3312 16.3563C43.4193 18.9002 42.0781 21.432 39.9121 22.8104C38.679 23.6021 37.2177 24 35.6683 24ZM24.6182 12C25.7433 13.3181 27.5089 15.3195 28.6819 16.3442C30.4115 17.8553 31.9048 18.9846 33.5663 19.5874C35.1358 20.158 36.7052 20.0978 37.7622 19.4226C38.735 18.8037 39.3636 17.6302 39.3236 16.501C39.2915 15.5686 38.715 14.6724 37.3898 13.511C36.9574 13.1292 36.7092 12.5787 36.7052 12.004C36.7052 11.4253 36.9534 10.8747 37.3818 10.4929C38.727 9.30338 39.2875 8.43533 39.3196 7.49896C39.3596 6.36969 38.731 5.19621 37.7581 4.57732C36.7012 3.90217 35.1318 3.84189 33.5623 4.41255C31.9008 5.01537 30.4075 6.14866 28.6779 7.65569C27.5729 8.62823 25.8353 10.5813 24.6182 12Z" fill="url(#paint1_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="0.668054" y1="12.0002" x2="24.0021" y2="12.0002" gradientUnits="userSpaceOnUse">
    <stop stop-color="#7FCCE2"/>
    <stop offset="0.1862" stop-color="#6DC6D4"/>
    <stop offset="0.5032" stop-color="#4FBDBF"/>
    <stop offset="0.7848" stop-color="#37B8B3"/>
    <stop offset="1" stop-color="#2CB6AE"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="43.3332" y1="12.0002" x2="19.9991" y2="12.0002" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00A29A"/>
    <stop offset="1" stop-color="#2CB6AE"/>
    </linearGradient>
    </defs>
  </svg>
  
);

export default SafetyConfirmation;
