import React, { SVGProps } from 'react';

const CareerDesign: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.5891 27.7803C23.4291 27.9703 23.2691 28.1603 23.1191 28.3503C23.2691 28.1603 23.4291 27.9703 23.5891 27.7803Z" fill="black"/>
    <path d="M22.2795 29.5303C22.1595 29.7203 22.0495 29.9103 21.9395 30.1003C22.0495 29.9103 22.1595 29.7203 22.2795 29.5303Z" fill="black"/>
    <path d="M29.9098 23.0498C29.3698 23.2898 28.8498 23.5498 28.3398 23.8398C28.8398 23.5498 29.3698 23.2798 29.9098 23.0498Z" fill="black"/>
    <path d="M21.4506 30.9805C21.3406 31.1905 21.2406 31.3905 21.1406 31.6005C21.2406 31.3905 21.3406 31.1805 21.4506 30.9805Z" fill="black"/>
    <path d="M27.7595 24.1699C27.5095 24.3299 27.2595 24.4899 27.0195 24.6599C27.2595 24.4899 27.4995 24.3299 27.7595 24.1699Z" fill="black"/>
    <path d="M26.3791 25.1201C26.1191 25.3201 25.8691 25.5101 25.6191 25.7201C25.8691 25.5101 26.1291 25.3201 26.3791 25.1201Z" fill="black"/>
    <path d="M20.5293 33.0401C20.5593 32.9701 20.5893 32.9001 20.6193 32.8301C20.5893 32.9001 20.5593 32.9701 20.5293 33.0401Z" fill="black"/>
    <path d="M24.1797 27.1098C24.3597 26.9198 24.5297 26.7298 24.7097 26.5498C24.5297 26.7398 24.3497 26.9298 24.1797 27.1098Z" fill="black"/>
    <path d="M10.2509 22.78C10.2509 22.75 10.2509 22.61 10.2509 22.58C10.2509 21.61 10.7109 20.7 11.4809 20.12L17.2909 15.77C18.2809 16.19 19.3609 16.42 20.5109 16.42C21.6509 16.42 22.7309 16.19 23.7109 15.77L29.5409 20.11C30.3109 20.69 30.7709 21.6 30.7709 22.57C30.7709 22.6 30.7709 22.7 30.7709 22.73L32.8109 22.12C32.6609 20.71 31.9209 19.33 30.7709 18.47L25.6209 14.63C27.5109 13.13 28.7209 10.81 28.7209 8.21C28.7209 3.68 25.0509 0 20.5109 0C15.9809 0 12.3009 3.67 12.3009 8.21C12.3009 10.81 13.5009 13.12 15.3909 14.62L10.2509 18.46C9.10094 19.32 8.36094 20.7 8.21094 22.11L10.2509 22.78ZM14.3609 8.22C14.3609 4.82 17.1209 2.07 20.5109 2.07C23.9109 2.07 26.6609 4.83 26.6609 8.22C26.6609 11.61 23.9009 14.37 20.5109 14.37C17.1109 14.37 14.3609 11.61 14.3609 8.22Z" fill="#007BC3"/>
    <path d="M13.6598 24.3996C13.6398 24.3796 13.6098 24.3696 13.5898 24.3496C13.6098 24.3696 13.6398 24.3896 13.6598 24.3996Z" fill="black"/>
    <path d="M27.0109 24.6602C26.8009 24.8102 26.5909 24.9602 26.3809 25.1202C26.5909 24.9602 26.8009 24.8102 27.0109 24.6602Z" fill="#003993"/>
    <path d="M24.7109 26.5597C25.0109 26.2697 25.3109 25.9897 25.6309 25.7197C25.3109 25.9897 25.0109 26.2697 24.7109 26.5597Z" fill="#003993"/>
    <path d="M30.3202 22.8701C30.1802 22.9301 30.0502 22.9901 29.9102 23.0501C30.0402 22.9901 30.1802 22.9201 30.3202 22.8701Z" fill="#003993"/>
    <path d="M28.33 23.8398C28.14 23.9498 27.94 24.0598 27.75 24.1698C27.94 24.0498 28.14 23.9498 28.33 23.8398Z" fill="#003993"/>
    <path d="M13.6602 24.4004C16.7902 26.4604 19.2202 29.4904 20.5102 33.0804C20.5202 33.0704 20.5202 33.0504 20.5302 33.0404C19.2302 29.4604 16.7902 26.4504 13.6602 24.4004Z" fill="#003993"/>
    <path d="M13.5898 24.3495C12.6198 23.7295 11.5898 23.1995 10.5098 22.7695C11.5898 23.1995 12.6198 23.7295 13.5898 24.3495Z" fill="#003993"/>
    <path d="M21.9392 30.0996C21.7692 30.3896 21.6092 30.6796 21.4492 30.9796C21.5992 30.6796 21.7692 30.3896 21.9392 30.0996Z" fill="#003993"/>
    <path d="M21.1391 31.5996C20.9491 31.9996 20.7791 32.4096 20.6191 32.8296C20.7791 32.4096 20.9491 31.9996 21.1391 31.5996Z" fill="#003993"/>
    <path d="M24.1798 27.1104C23.9798 27.3304 23.7798 27.5504 23.5898 27.7804C23.7798 27.5504 23.9798 27.3304 24.1798 27.1104Z" fill="#003993"/>
    <path d="M23.1193 28.3496C22.8193 28.7296 22.5393 29.1296 22.2793 29.5296C22.5393 29.1296 22.8193 28.7296 23.1193 28.3496Z" fill="#003993"/>
    <path d="M3.5 21.5504L7.9 17.1504C8.3 16.7504 8.3 16.1004 7.9 15.7004C7.5 15.3004 6.85 15.3004 6.45 15.7004L0.3 21.8504C-0.1 22.2504 -0.1 22.9004 0.3 23.3004L6.45 29.4504C6.85 29.8504 7.5 29.8504 7.9 29.4504C8.3 29.0504 8.3 28.4004 7.9 28.0004L3.5 23.6004H4.1C12.6 23.6004 19.48 30.4904 19.48 38.9804C19.48 39.5504 19.94 40.0104 20.51 40.0104C21.08 40.0104 21.54 39.5504 21.54 38.9804C21.54 30.4804 28.43 23.6004 36.92 23.6004H37.52L33.12 28.0004C32.72 28.4004 32.72 29.0504 33.12 29.4504C33.52 29.8504 34.17 29.8504 34.57 29.4504L40.72 23.3004C41.12 22.9004 41.12 22.2504 40.72 21.8504L34.57 15.7004C34.17 15.3004 33.52 15.3004 33.12 15.7004C32.72 16.1004 32.72 16.7504 33.12 17.1504L37.52 21.5504C37.52 21.5504 33.22 21.6704 30.31 22.8704C30.17 22.9304 30.04 22.9904 29.9 23.0504C29.36 23.2904 28.84 23.5504 28.33 23.8404C28.14 23.9504 27.94 24.0604 27.75 24.1704C27.5 24.3304 27.25 24.4904 27.01 24.6604C26.8 24.8104 26.59 24.9604 26.38 25.1204C26.12 25.3104 25.87 25.5104 25.62 25.7204C25.3 25.9904 25 26.2704 24.7 26.5604C24.52 26.7404 24.34 26.9304 24.17 27.1204C23.97 27.3404 23.77 27.5604 23.58 27.7904C23.42 27.9804 23.26 28.1704 23.11 28.3604C22.82 28.7404 22.53 29.1404 22.27 29.5404C22.15 29.7204 22.04 29.9204 21.93 30.1104C21.76 30.4004 21.59 30.6904 21.44 30.9904C21.33 31.2004 21.23 31.4004 21.13 31.6104C20.94 32.0104 20.77 32.4204 20.61 32.8404C20.58 32.9104 20.55 32.9804 20.52 33.0504C20.51 33.0604 20.51 33.0804 20.5 33.0904C19.21 29.5104 16.78 26.4704 13.65 24.4104C13.63 24.3904 13.6 24.3804 13.58 24.3604C12.62 23.7304 11.59 23.1804 10.5 22.7804C7.12 21.5504 3.5 21.5504 3.5 21.5504Z" fill="#003993"/>
  </svg>
);

export default CareerDesign;
