import React from "react";
import Calendar from "components/molecules/Calendar";
import { ScheduleStatus } from "api/schedule";
import EventWrapper from "./EventWrapper";
import MonthEvent from "./MonthEvent";
import MonthDateHeader from "./MonthDateHeader";
import { ShiftMonthCalendarEvent } from "components/organismos/master/attend/HopeShiftForm/hooks";

const ScheduleMonthCalendar: React.FC<{
  events: ShiftMonthCalendarEvent[];
  date: Date;
  datePickerValue: Date;
  setDate: (date: Date) => void;
  setDatePickerValue: (date: Date) => void;
}> = ({ date, datePickerValue, setDate, setDatePickerValue, events }) => {
  // Events that dont have event can't edit & ready to submit
  const configEvents = events.filter(
    (item) => !(item.hopeShift?.isEditable === false && item.hopeShift.fixStatus === ScheduleStatus.UNSUBMITED),
  );

  const newEvents = configEvents.map((item) => {
    const targetDate = new Date(item.targetDate);

    const start = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate(),
      item.start?.getHours(),
      item.start?.getMinutes(),
    );

    const end = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate(),
      item.end?.getHours(),
      item.end?.getMinutes(),
    );

    return {
      ...item,
      start: start,
      end: end,
      title: item.title,
    };
  });
  return (
    <>
      <Calendar
        date={datePickerValue}
        setDate={setDatePickerValue}
        events={newEvents}
        views={["month"]}
        onDrillDown={(selectDate) => {
          setDate(selectDate);
        }}
        components={{
          month: {
            event: MonthEvent,
            dateHeader: MonthDateHeader,
          },
          toolbar: () => <></>,
          eventWrapper: EventWrapper,
        }}
        messages={{
          showMore: (count: number) => `他${count}件`,
        }}
      />
    </>
  );
};
export default ScheduleMonthCalendar;
