/** @jsx jsx */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import FormLabel from 'components/atoms/Form/FormLabel';
import VerticalLabelFormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import './styles.css';
import moment from 'moment';
import Icon from 'components/atoms/Icon';
import { utilityColor, grayScale } from 'components/styles';
import ReactDOM from 'react-dom';

const styles = {
  datePicker: css({
    position: 'relative',
    padding: '4px 0',
  }),
  input: css({
    width: '100%',
    boxSizing: 'border-box',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '8px',
    border: `1px solid ${grayScale.gray20}`,
    borderRadius: '3px',
    color: grayScale.gray100,
  }),
};

const CalendarForm: React.FC<{
  label: string;
  required?: boolean;
  dateFormat?: string;
  date?: Date;
  changeDate: (event: any) => void;
  errorMsg?: string;
  readOnly?: boolean;
  isEmptyCalendarForm?: boolean;
  isEmpty?:boolean
}> = ({
  label, changeDate, required, errorMsg, dateFormat = 'YYYY/MM/DD', date, readOnly = false, isEmptyCalendarForm = false, isEmpty
}) => { 

    // disabled scroll function
    let rootPosition = document.getElementById('root')?.getBoundingClientRect();
    function noScroll() {
      window.scrollTo(rootPosition?.left ? -rootPosition?.left : 0 , rootPosition?.top ? -rootPosition?.top : 0);
    }

    let bodyNode = document.querySelector("body") as HTMLBodyElement;
    // const monthNames = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    const dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const newDate = new Date(date ? +date : new Date()); 
    const [dateChange, setDateChange] = useState(newDate);
    const el = useRef(Object());
    const parent = useRef(Object()); 
    const refWrapCalendar = useRef(Object()); 

    function getDaysInMonth(month: any, year: any) {
      // eslint-disable-next-line no-shadow
      const date = new Date(year, month, 1);
      const days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    }

    // function getMonthisInYear (year: any) {
    //   const date = new Date(year, 0, 1);
    //   const months = [];
    //   while(date.getFullYear() === year) {
    //     months.push(new Date(date));
    //     date.setMonth(date.getMonth() + 1);
    //   }
    //   return months;
    // }

    function toPrevYear() {
      setDateChange(new Date(dateChange.setFullYear(dateChange.getFullYear() - 1)));
      handleOpen();
    }

    function toNextYear() {
      setDateChange(new Date(dateChange.setFullYear(dateChange.getFullYear() + 1)));
      handleOpen();
    }

    function toPrevMonth() { 
      setDateChange(new Date(dateChange.setMonth(dateChange.getMonth() - 1)));
      handleOpen();
    }

    function toNextMonth() {
      dateChange.setDate(1);
      setDateChange(new Date(dateChange.setMonth(dateChange.getMonth() + 1)));
      handleOpen();
    }

    function hide() {
      // const calendarNode = parent.current.querySelector('.calendar');
      if (refWrapCalendar.current && refWrapCalendar.current.querySelector('.calendar')) {
        const calendarNode = refWrapCalendar.current.querySelector('.calendar');
        // document.documentElement.style.overflowY = "auto";
        // parent.current.removeChild(calendarNode);
        refWrapCalendar.current.removeChild(calendarNode);
        window.removeEventListener('scroll', noScroll);
        window.removeEventListener('wheel', noScroll);
      }
    }

    function removeInputStyle() {
      if(el.current){
        el.current.style.removeProperty('position');
        el.current.style.removeProperty('z-index');
      }

      // const calendarBgNode = parent.current.querySelectorAll('.calendar-bg');
      if(refWrapCalendar.current && refWrapCalendar.current.querySelectorAll('.calendar-bg')){
        const calendarBgNode = refWrapCalendar.current.querySelectorAll('.calendar-bg');
        calendarBgNode.forEach((item: any) => {
          item.remove();
        });
      }
    }

    const handleClose = useCallback((event) => {
      const keycode = (event.keyCode ? event.keyCode : event.which); 
      if (keycode === 13) { 
        hide();
      }
      if (keycode === 8 && !isEmptyCalendarForm && !required) { 
        changeDate('');
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleOpen() {
      // document.documentElement.style.overflowY = "hidden";
      // el.current.style.zIndex = 10000;
      el.current.style.position = 'relative';
      hide();
      // const isExistCalendarBg = parent.current.querySelector('.calendar-bg');
      const isExistCalendarBg = refWrapCalendar.current.querySelector('.calendar-bg');
      if (!isExistCalendarBg) {
        const $calendarBg = document.createElement('div');
        $calendarBg.classList.add('calendar-bg');
        $calendarBg.style.position = 'fixed';
        $calendarBg.style.top = '0';
        $calendarBg.style.right = '0';
        $calendarBg.style.bottom = '0';
        $calendarBg.style.left = '0';
        $calendarBg.style.zIndex = '9999';
        $calendarBg.addEventListener('click', () => {
          removeInputStyle();
          hide();
        });
        // parent.current.appendChild($calendarBg);
        refWrapCalendar.current.appendChild($calendarBg);
      }

      const $calendarNode = document.createElement('div');
      $calendarNode.classList.add('calendar');

      // const elPosition = el.current?.getBoundingClientRect();
      const clientRect = el.current?.getBoundingClientRect();

      // when using jquery it's equal: $input.offset().top + 340
      // const topChangeValue = (() => {
      //   const rect = el.current.getBoundingClientRect();
      //   const { scrollY } = window;
      //   return rect.y + scrollY + 340;
      // })();

      // if (window.innerHeight < topChangeValue) _top = -340;


      $calendarNode.style.left = `${clientRect.left}px`;
      // $calendarNode.style.left = `0`;

      // 画面外に要素が出る場合に位置を調整する
      const parentElement = parent.current.offsetParent;
      const parentRect = parentElement?.getBoundingClientRect();
      if (!parentRect) {
        return;
      }
      const remainHeight = window.screen.availHeight - clientRect.top - 400
      if(remainHeight < 0){
        $calendarNode.style.top = `${clientRect.top - 337}px`;
      } else {
        $calendarNode.style.top = `${clientRect.top + 45}px`;
      }

      refWrapCalendar.current.appendChild($calendarNode);
      const year = dateChange.getFullYear() || (new Date()).getFullYear();
      const month = dateChange.getMonth();
      const daysInMonth = getDaysInMonth(month, year);
      // const months = getMonthisInYear(year);
      // const dtm = new Date(year, month, 1);
      // const prevM = new Date(dtm.setMonth(month - 1));
      // const nextM = new Date(dtm.setMonth(month + 1));
      // const daysPrevMonth = getDaysInMonth(
      //   prevM.getMonth(),
      //   prevM.getFullYear()
      // );
      // const daysNextMonth = getDaysInMonth(
      //   nextM.getMonth(),
      //   nextM.getFullYear()
      // );

      // render year section
      const $yearNode = document.createElement('div');
      $yearNode.classList.add('calendar__year');
      const $yearLeftArrow = document.createElement('span');
      $yearLeftArrow.classList.add(
        'calendar__arrow',
        'calendar__arrow--left',
      );
      $yearLeftArrow.addEventListener('click', () => {
        toPrevYear();
      });
      $yearNode.appendChild($yearLeftArrow);

      const $yearTitle = document.createElement('span');
      $yearTitle.classList.add('calendar__year-value');
      $yearTitle.addEventListener('click', (e) => {
        const tmpYear = new Date(Number($yearTitle.innerHTML), 0, 1)
        
        el.current.value = moment(tmpYear).format(dateFormat);
        handleChange(new Date(tmpYear));

        hide();
        removeInputStyle();
      });

      $yearTitle.innerText = String(year);
      $yearNode.appendChild($yearTitle);
      const $yearRightArrow = document.createElement('span');
      $yearRightArrow.classList.add(
        'calendar__arrow',
        'calendar__arrow--right',
      );
      $yearRightArrow.addEventListener('click', () => {
        toNextYear();
      });
      $yearNode.appendChild($yearRightArrow);
      $calendarNode.appendChild($yearNode);

      // render month section
      const $monthNode = document.createElement('div');
      $monthNode.classList.add('calendar__month');
      const $monthLeftArrow = document.createElement('span');
      $monthLeftArrow.classList.add(
        'calendar__arrow',
        'calendar__arrow--left',
      );
      $monthLeftArrow.addEventListener('click', () => {
        toPrevMonth();
      });
      $monthNode.appendChild($monthLeftArrow);

      const $monthTitle = document.createElement('span');
      $monthTitle.addEventListener('click', (e) => {
        const tmpMonth = new Date(Number($yearTitle.innerHTML), Number($monthTitle.innerHTML) - 1, 1)
        
        el.current.value = moment(tmpMonth).format(dateFormat);
        handleChange(new Date(tmpMonth));

        hide();
        removeInputStyle();
      });
      $monthTitle.classList.add('calendar__month-value'); 
      $monthTitle.innerText = String(month + 1);
      $monthNode.appendChild($monthTitle);
      const $monthRightArrow = document.createElement('span');
      $monthRightArrow.classList.add(
        'calendar__arrow',
        'calendar__arrow--right',
      );
      $monthRightArrow.addEventListener('click', () => {
        toNextMonth();
      });
      $monthNode.appendChild($monthRightArrow);
      $calendarNode.appendChild($monthNode);

      // render dow
      const $dowNode = document.createElement('div');
      $dowNode.classList.add('calendar__dow');
      $dowNode.innerHTML = dayNames
        .map((day) => `<div class="calendar__dow-item">${day}</div>`)
        .join('');
      $calendarNode.appendChild($dowNode);

      // render day
      const $daysNode = document.createElement('div');
      $daysNode.classList.add('calendar__day');

      // Add blank days to fill in before first day

      const skipLength = daysInMonth[0].getDay();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < skipLength; i++) {
        const $dayNode = document.createElement('div');
        $dayNode.classList.add('calendar__day-item', 'calendar__day-dummy');
        $daysNode.appendChild($dayNode);
      }

      // Place a day for each day of the month
      daysInMonth.forEach((day, i) => {
        const today = new Date(
          new Date().setHours(0, 0, 0, 0),
        );
        const $dayNode = document.createElement('div');
        $dayNode.classList.add('calendar__day-item');
        $dayNode.setAttribute('data-date', String(day));
        const dayValue = i + 1;
        $dayNode.innerText = String(dayValue);
        const dow = day.getDay();
        const dateParsed = Date.parse(String(day));
        const todayParsed = Date.parse(String(today));
        if (dateParsed === todayParsed) {
          $dayNode.classList.add(
            'calendar__day-item',
            'calendar__day-item--today',
          );
          $dayNode.innerHTML = `<div class="calendar__day-item-value"><span>${dayValue}</span></div>`;
        }
        if (dateParsed > todayParsed) {
          $dayNode.classList.add('calendar__day-item--future');
        }
        if (dateParsed < todayParsed) {
          $dayNode.classList.add('calendar__day-item--past');
        }

        if (dow === 0 || dow === 6) {
          $dayNode.classList.add('calendar__day-item--weekend');
        }
        if (
          !$dayNode.classList.contains('calendar__day-dummy')
        ) {
          $dayNode.addEventListener('click', (e) => {
            
            const eT = e.target as HTMLInputElement;
            const ecT = e.currentTarget as HTMLInputElement;
            let tmpDate = new Date();
            if (eT.getAttribute('data-date')) {
              tmpDate = new Date(String(eT.getAttribute('data-date')));
            } else if (ecT.getAttribute('data-date')) {
              tmpDate = new Date(String(ecT.getAttribute('data-date')));
            }

            el.current.value = moment(tmpDate).format(dateFormat);
            handleChange(new Date(tmpDate));
            hide();
            removeInputStyle();
          });
        }
        $daysNode.appendChild($dayNode);
      });

      // Add blank days to fill in after last day
      $calendarNode.appendChild($daysNode);

      window.addEventListener('scroll', noScroll, { passive: true });
      window.addEventListener('wheel', noScroll, { passive: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    function handleChange(e: Date) {
      changeDate(e); 
    } 

    useEffect(() => {
      if (!isEmptyCalendarForm && date){
        const tmpDate = new Date(+date);
        setDateChange(tmpDate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    return (
      <VerticalLabelFormLayout
        label={(
          label ?
            <FormLabel
              label={label}
              required={required}
            /> : ''
        )}
        input={(
          <div ref={parent} css={styles.datePicker}>
            <input
              ref={el}
              css={styles.input}
              onFocus={() => { handleOpen(); }}
              onKeyUp={(e) => { handleClose(e); }}
              value={date ? moment(date).format(dateFormat) : ''}
              type="text"
              onChange={(e) => console.log(e.target.value)}
              disabled={readOnly}
              onKeyDown={(e) => {
                const key = e.keyCode || e.charCode;
                if( (!required || isEmpty === true) && (key == 8 || key == 46) ){
                  changeDate('');
                  return false;
                }
              }}
            />
            <div className="icf-calendar">
              <Icon type="date" color={utilityColor.black} />
            </div>
            <div ref={refWrapCalendar} className="wrapCalendarForm">
            </div>
            {ReactDOM.createPortal(
              <div ref={refWrapCalendar} className="wrapCalendarForm">
              </div>,
              bodyNode,
            )}
          </div>
        )}
        errorMsg={errorMsg}
      />
    );
  };

export default CalendarForm;
