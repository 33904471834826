/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

const MonthDayTable: React.FC<{
  subTotalDay: any;
  holidaySubtotalItem: any;
  countHolidayColspan: any;
  remote: boolean;
}> = ({ subTotalDay, holidaySubtotalItem, countHolidayColspan, remote }) => {
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
  return (
    <div className="table-responsive">
      <div
        css={css`
          table thead tr th {
            height: 46px;
          }
          table tbody tr td {
            height: 32px;
          }
        `}
      >
        <table
          id="monthDayList"
          className="table table-bordered table-condensed table-no-border-left-right"
          cellSpacing="0"
          style={{ whiteSpace: "nowrap", width: "100%" }}
        >
          <thead>
            <tr role="row">
              {subTotalDay.distinctionHoliday && (
                <th style={{ width: "9%" }} colSpan={3}>
                  出勤日数{remote && `　(オフィス/${companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"})`}
                </th>
              )}
              {!subTotalDay.distinctionHoliday && (
                <th style={{ width: "9%" }} colSpan={2}>
                  出勤日数{remote && `　(オフィス/${companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"})`}
                </th>
              )}
              <th
                style={{ width: "9%" }}
                colSpan={countHolidayColspan(subTotalDay.distinctionHoliday, holidaySubtotalItem)}
              >
                休暇日数
              </th>
            </tr>
            <tr role="row">
              <th style={{ width: "9%" }}>平日</th>
              {/* {remote && (
            <React.Fragment>
              <th style={{ width: '9%' }}>オフィス</th>
              <th style={{ width: '9%' }}>テレワーク</th>
            </React.Fragment>
          )} */}
              {subTotalDay.distinctionHoliday && <th style={{ width: "9%" }}>法定休日</th>}
              {subTotalDay.distinctionHoliday && <th style={{ width: "9%" }}>所定休日</th>}
              {!subTotalDay.distinctionHoliday && <th style={{ width: "9%" }}>休日</th>}
              {holidaySubtotalItem &&
                holidaySubtotalItem.map((item: any, index: any) => (
                  <th key={String(index)} style={{ width: "9%" }}>
                    {item.holidayName}
                    {subTotalDay.distinctionHoliday && item.holidayType === 0 && item.isLegal ? "（法定）" : ""}
                    {subTotalDay.distinctionHoliday && item.holidayType === 0 && !item.isLegal ? "（所定）" : ""}
                  </th>
                ))}
              <th style={{ width: "9%" }}>遅刻</th>
              <th style={{ width: "9%" }}>早退</th>
            </tr>
          </thead>
          <tbody>
            {subTotalDay != null && (
              <tr role="row">
                <td>
                  {subTotalDay.normalWork}
                  {remote && parseInt(subTotalDay.normalWork) > 0
                    ? "(" + parseInt(subTotalDay.officeWork) + "/" + parseInt(subTotalDay.remoteWork) + ")"
                    : ""}
                </td>
                {/* {remote && (
              <React.Fragment>
                <td>{subTotalDay.officeWork}</td>
                <td>{subTotalDay.remoteWork}</td>
              </React.Fragment>
            )} */}
                {subTotalDay.distinctionHoliday && (
                  <td>
                    {subTotalDay.legalHolidayWork}
                    {remote && parseInt(subTotalDay.legalHolidayWork) > 0
                      ? "(" +
                        parseInt(subTotalDay.legalOfficeHolidayWork) +
                        "/" +
                        parseInt(subTotalDay.legalRemoteHolidayWork) +
                        ")"
                      : ""}
                  </td>
                )}
                {subTotalDay.distinctionHoliday && (
                  <td>
                    {subTotalDay.nonLegalHolidayWork}
                    {remote && parseInt(subTotalDay.nonLegalHolidayWork) > 0
                      ? "(" +
                        parseInt(subTotalDay.nonLegalOfficeHolidayWork) +
                        "/" +
                        parseInt(subTotalDay.nonLegalRemoteHolidayWork) +
                        ")"
                      : ""}
                  </td>
                )}
                {!subTotalDay.distinctionHoliday && (
                  <td>
                    {subTotalDay.holidayWork}
                    {remote && parseInt(subTotalDay.legalHolidayWork) > 0
                      ? "(" +
                        parseInt(subTotalDay.legalOfficeHolidayWork) +
                        "/" +
                        parseInt(subTotalDay.legalRemoteHolidayWork) +
                        ")"
                      : ""}
                  </td>
                )}

                {subTotalDay.attendHolidaySubtotals &&
                  subTotalDay.attendHolidaySubtotals.map((holidaySubtotalDays: any, index: any) => (
                    <td key={String(index)}>{holidaySubtotalDays.resultHoliday}</td>
                  ))}
                <td>{subTotalDay.late}</td>
                <td>{subTotalDay.earlier}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MonthDayTable;
