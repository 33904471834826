/* eslint-disable max-len */
/** @jsx jsx */
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormField from 'components/atoms/Form/FormField';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import Button, { PrimaryButton, SecondaryButton, SubActionButton } from 'components/atoms/Button';
import FormTitle from 'components/atoms/Form/FormTitle';
import StaffDomain from 'domain/staff';
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import ConfirmActionModal, { ActionType } from 'components/organismos/ConfirmActionModal';
import DataTable from 'components/organismos/DataTable/DataTable';
import { Column } from 'react-table';
import { OrganizationDataStaffDomain } from 'domain/master/general/organizationDataStaff';
import moment from 'moment';
import { useLongTermCareAndNurse } from './hooks';
import { TablItem } from 'components/atoms/TabBar';
import CalendarForm from 'components/molecules/CalendarForm';
import { css, jsx } from '@emotion/core';
import FlexBox from 'components/atoms/FlexBox';
import useStaffList from 'components/pages/master/general/staff/StaffsPage/hooks';
import { useStaffDelete } from '../StaffListTable/hooks';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import FormLabel from 'components/atoms/Form/FormLabel';
import HourInput from 'components/atoms/Form/HourInput';
import { deleteStaffHoliday } from 'api/staff';
import IconLabelButton from 'components/molecules/IconLabelButton';
import SelectForm from 'components/molecules/SelectForm';
import CalendarRangeV2 from 'components/molecules/CalendarV1/CalendarRangeV2';


type SelectType = 'staff' | 'affiliation' | 'employment' | 'laborCost' | 'workPattern' | 'longTermCareandNurse';
const SelectType: { [key in SelectType]: SelectType } = {
  staff: 'staff',
  affiliation: 'affiliation',
  employment: 'employment',
  laborCost: 'laborCost',
  workPattern: 'workPattern',
  longTermCareandNurse: 'longTermCareandNurse'
};

const styles = {
  form: css({
    maxWidth: '600px',
    margin: '0 auto',
    padding: '30px'
  }),
  table: css({
    width: '100%'
  }, css`
    tr td,
    tr th{
      text-align:left;
    }
  `),
  note: css({
    margin: '50px 0px 20px',
    paddingLeft: '30px',
    display: 'block'
  }),
  buttonAdd: css({
    maxWidth: '190px',
    marginLeft: '25px',
    marginTop: '15px'
  }),
  buttonSubmit: css({
    margin: '0 auto'
  }),
  centerBox: css({
    justifyContent: 'center'
  }),
  equivalent: css({
    display: 'block',
    textAlign: 'center'
  }),
  flexboxCustom: css({
    margin: '20px 0px'
  }),
  flexbox: css({
    maxWidth: '150px',
    justifyContent: 'center'
  }),
}

interface holidayType {
  holidayId: string,
  holidayType: number
}

const LongTermCareAndNurse: React.FC<{
  initialStaffDomain?: StaffDomain;
  isEditMode?: boolean;
  tabItems: TablItem[];
  setInitialStaffDomain: Dispatch<React.SetStateAction<StaffDomain>>;
  setSelectType: Dispatch<React.SetStateAction<SelectType>>;
  setTabItems: Dispatch<React.SetStateAction<TablItem[]>>;
  setOrgTargetDate?: React.Dispatch<React.SetStateAction<Date>>;
  executiveOptions: Array<any>;
  isDisabledInfo?: boolean
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
  holidayOptions: Array<any>,
  holidayTypeOptions: Array<holidayType>
}> = ({
  initialStaffDomain, isEditMode = false, tabItems,
  setInitialStaffDomain, setSelectType, setTabItems,
  isDisabledInfo = false,
  setIsLoading,
  holidayOptions,
  holidayTypeOptions
}) => {
    const {
      formik,
      confirmModalOpen,
      closeConfirmModal,
      setIsEditHoliday,
      successNotification,
      errorNotification,
      clearForm,
      isAddItem,
      setIsAddItem,
      contextObject
    } = useLongTermCareAndNurse(
      initialStaffDomain,
      isEditMode,
      setIsLoading,
      holidayTypeOptions
    );

    const { fetchData } = useStaffList();
    const { setDeleteTargetStaff, isOpenDeleteModal, setIsOpenDeleteModal, closeDeleteModal, onSubmitDeleteStaff } = useStaffDelete(fetchData, setIsLoading);

    // status modal delete holiday
    const [isOpenDeleteHolidayModal, setIsOpenDeleteHolidayModal] = useState(false);

    const [staffCodeTmp, setStaffCodeTmp] = useState('');
    const [staffHolidayId, setStaffHolidayId] = useState('');
    const [isShowForm, setIsShowForm] = useState(false);
    const onEditHoliday = useCallback((staffHolidayId: any) => {
      setIsAddItem(false);
      const staffHolidayFilter = formik.values.staffHolidayList.filter((staffHolidayItem: any) => staffHolidayItem.staffHolidayId === staffHolidayId);
      if (staffHolidayFilter[0]) {
        const { staffHolidayId, holidayId, applyStartDate, applyEndDate, yearUseDays } = staffHolidayFilter[0];
        formik.setFieldValue('staffHolidayId', staffHolidayId);
        formik.setFieldValue('holidayId', holidayId);
        formik.setFieldValue('applyStartDate', new Date(moment(applyStartDate).format('YYYY/MM/DD')));
        formik.setFieldValue('applyEndDate', applyEndDate ? new Date(moment(applyEndDate).format('YYYY/MM/DD')) : '');
        formik.setFieldValue('yearUseDays', yearUseDays);
      }
      setIsEditHoliday(2);
      setIsShowForm(true);
    }, []);

    const onDeleteHoliday = async (staffHolidayId: string, staffCode: string) => {
      try {
        const companyCode = sessionStorage.getItem('companyCode') || '';
        const params = {
          companyCode,
          staffHolidayId,
          staffCode
        };
        setIsLoading!(true);
        await deleteStaffHoliday(params).then((response) => {
          formik.setFieldValue('staffHolidayList', response);
          errorNotification('削除しました。');
          setIsLoading!(false);
          clearForm();
          setIsAddItem(true);
          setIsOpenDeleteHolidayModal(!isOpenDeleteHolidayModal);
        });
      } catch (error) {
        setIsLoading!(false);
        setIsOpenDeleteHolidayModal(!isOpenDeleteHolidayModal);
        if (error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.map((item: { defaultMessage: string; }) => errorNotification(item.defaultMessage));
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      }

      return false;
    }


    const columns: Array<Column<OrganizationDataStaffDomain>> = React.useMemo(() => [
      {
        Header: () => null,
        accessor: 'x',
        id: 'actionButton',
        disableSortBy: true,
        Cell: (cell: { row: { isExpanded: boolean, original: StaffDomain } }) => {
          const { staffCode, staffHolidayId } = cell.row.original;
          return (
            <FlexBox customStyle={styles.flexbox}>
              <FlexBoxItem marginRight="10px">
                <SubActionButton action={0} text={contextObject.detailRole.editable === 1 ? "編集" : "参照"} onClick={() => onEditHoliday(staffHolidayId)} />
              </FlexBoxItem>
              {contextObject.detailRole.editable === 1 && (
                <FlexBoxItem>
                  <SubActionButton action={1} text="削除" onClick={() => {
                    setStaffCodeTmp(staffCode);
                    setStaffHolidayId(staffHolidayId);
                    setIsOpenDeleteHolidayModal(true);
                  }} />
                </FlexBoxItem>
              )}
              
            </FlexBox>
          );
        },
      },
      {
        Header: '休暇タイプ',
        accessor: 'holidayTypeStr',
      },
      {
        Header: '休暇コード',
        accessor: 'holidayCode',
      },
      {
        Header: '休暇名',
        accessor: 'holidayName',
      },
      {
        Header: '対象期間',
        accessor: 'applyStartDate',
        Cell: (cell: { row: { isExpanded: boolean, original: StaffDomain } }) => {
          const applyStartDate = cell.row.original.applyStartDate;
          let applyEndDate: any = cell.row.original.applyEndDate;
          return moment(applyStartDate).format('YYYY/MM/DD') + ' ～ ' + (applyEndDate ? moment(applyEndDate).format('YYYY/MM/DD') : '');
        },
      },
      {
        Header: '１年度の取得可能日数',
        accessor: 'yearUseDays',
      }
    ], [contextObject]);

    useEffect(() => {
    }, [isAddItem, formik.values.staffHolidayList]);
    return (
      <React.Fragment>
        { formik.values.staffHolidayList && (
          <div className="table-bordered table-employment-domain-list" css={styles.table}>
            <DataTable
              columns={columns}
              data={formik.values.staffHolidayList}
              isHiddenPagination={false}
            />
          </div>
        )}
        <div style={{ marginLeft: '20px' }}>
          <IconLabelButton
            iconType="clone"
            onClick={() => {
              clearForm();
              setIsShowForm(true);
              setIsAddItem(true);
            }}
            text="追加"
            width="auto"
          />
        </div>
        {
          isShowForm && (
            <form onSubmit={formik.handleSubmit} css={styles.form}>
              <FormTitle
                title="基本情報"
                bold={true}
                customStyle={css({ marginLeft: '0' })}
              />
              <FlexBox>
                <FlexBoxItem width="200px">
                  <FormLabel label="休暇" />
                </FlexBoxItem>
                <FormField displayBlock={true}>
                  <VerticalLabelSelectForm
                    label=""
                    name="holidayId"
                    value={formik.values.holidayId || ''}
                    setValue={(val: string) => {
                      formik.setFieldValue('holidayId', val);
                      const holidayTypeFilter = holidayTypeOptions.filter((holidayTypeOption) => holidayTypeOption.holidayId == val);
                      if (holidayTypeFilter.length > 0) {
                        formik.setFieldValue('holidayType', holidayTypeFilter[0].holidayType);
                      }
                    }}
                    options={holidayOptions}
                    required={true}
                    width={"400px"}
                  />
                </FormField>
              </FlexBox>
              <FlexBox>
                <FlexBoxItem width="200px">
                  <FormLabel label="対象期間" />
                </FlexBoxItem>
                <FormField displayBlock={true}>
                  <FlexBox>
                    <FlexBoxItem>
                      <CalendarRangeV2 
                        startDate={formik.values.applyStartDate}
                        onChangeStart={(date: Date) => {
                          formik.setFieldValue('applyStartDate', date)
                        }}
                        endDate={formik.values.applyEndDate}
                        onChangeEnd={(date) => {
                          formik.setFieldValue('applyEndDate', date);
                        }}
                        canNullEndDate={true}
                      />
                    </FlexBoxItem>
                    {/* <FlexBoxItem width="140px">
                      <CalendarForm
                        dateFormat="YYYY/MM/DD"
                        label=""
                        date={formik.values.applyStartDate}
                        changeDate={(date: Date) => {
                          formik.setFieldValue('applyStartDate', date)
                        }}
                        required={true}
                      />
                    </FlexBoxItem>
                    <FlexBoxItem width="50px">
                      <span css={styles.equivalent}>〜</span>
                    </FlexBoxItem>
                    <FlexBoxItem width="140px">
                      <CalendarForm
                        dateFormat="YYYY/MM/DD"
                        label=""
                        date={formik.values.applyEndDate}
                        changeDate={(date: Date) => {
                          formik.setFieldValue('applyEndDate', date);
                        }}
                        required={false}
                      />
                    </FlexBoxItem> */}
                  </FlexBox>
                </FormField>
              </FlexBox>
              <FlexBox customStyle={styles.flexboxCustom}>
                <FlexBoxItem width="200px">
                  <FormLabel label="１年度の取得可能日数" />
                </FlexBoxItem>
                <FlexBoxItem>
                  <HourInput
                    name="selectStaffHoliday.year_use_days"
                    label=""
                    value={String(formik.values.yearUseDays || 0)}
                    onChange={(val) => {
                      const { value } = val.target;
                      formik.setFieldValue('yearUseDays', Number(value));
                    }}
                    min={0}
                    max={366}
                    isFullW={true}
                  />
                </FlexBoxItem>
              </FlexBox>

            </form>
          )
        }

        <FormSubmitArea customStyle={css({ padding: '30px' })}>
          {contextObject.detailRole.editable === 1 && (
            <div style={{ marginRight: '12px', textAlign: 'center' }}>
              <FlexBox customStyle={styles.centerBox}>
                {
                  !isDisabledInfo && isShowForm && isEditMode ?
                  <Button
                    text={!isAddItem ? '休暇情報を更新' : (!isEditMode ? '登録' : '休暇情報を登録')}
                    onClick={formik.handleSubmit}
                    minWidth="150px"
                  />
                  : ''
                }

                {
                  !isEditMode ?
                    <Button
                      text={!isEditMode ? '登録' : ''}
                      onClick={formik.handleSubmit}
                      minWidth="150px"
                    />
                    : ''
                }
                {
                  isEditMode && formik.values.staffHolidayList ?
                    <div style={{ marginLeft: '12px', textAlign: 'center' }}>
                      <SecondaryButton
                        minWidth="150px"
                        text='削除'
                        onClick={() => {
                          setIsAddItem(true);
                          setIsOpenDeleteModal(true);
                          setDeleteTargetStaff(formik.values)
                        }}
                      />
                    </div>
                    : ''
                }
              </FlexBox>
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            <Link to="/staffs">
              <PrimaryButton
                text="スタッフ一覧に戻る"
                ghost={true}
                minWidth="150px"
              />
            </Link>
          </div>
        </FormSubmitArea>
        {/* 登録内容確認モーダル */}

        {
          !isEditMode ?
          <ConfirmActionModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={formik.handleSubmit}
            actionType={isEditMode ? ActionType.UPDATE : ActionType.CREATE}
          />
          : <ConfirmActionModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={formik.handleSubmit}
            actionType={!isAddItem ? ActionType.UPDATE : ActionType.CREATE}
          />
        }

        <ConfirmActionModal
          open={isOpenDeleteHolidayModal}
          closeHandler={() => setIsOpenDeleteHolidayModal(!isOpenDeleteHolidayModal)}
          onSubmit={() => onDeleteHoliday(staffHolidayId, staffCodeTmp)}
          actionType={ActionType.DELETE}
          typeSubmit="reject"
        />

        <ConfirmActionModal
          open={isOpenDeleteModal}
          closeHandler={closeDeleteModal}
          onSubmit={onSubmitDeleteStaff}
          actionType={ActionType.DELETE}
          typeSubmit="reject"
        />
      </React.Fragment>
    );
  };

export default LongTermCareAndNurse;
