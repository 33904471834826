/* eslint-disable no-shadow */
import React, { ReactElement, useEffect } from 'react';
import DatePicker from 'components/molecules/DatePicker';

import FormContents from 'components/atoms/Form/FormContents';
import TabControl from 'components/atoms/TabControl';
import { css } from '@emotion/core';
import BlockUI from 'components/molecules/BlockUi';
import WorkRecord from './WorkRecord';
import Result from './Result';
import Vacation from './Vacation';
import useWorkingStatusData, { SelectType } from './hook';

const WorkingStatusLayout: React.FC = (): ReactElement => {
  const {
    isLoading,
    fetchList,
    selectType,
    setSelectType,
    getTargetDate,
    setTargetDate,
    getLoginStaffCcde,
    getLoginStaffName,
    staffStampList,
    employment,
    applyResult,
    tabTypes,
    leaveStatus,
    yearMonth,
  } = useWorkingStatusData();

  useEffect(() => {
    fetchList();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTargetDate]);

  const renderTab = (selectType: SelectType) => {
    switch (selectType) {
      case tabTypes.result:
        return <Result list={applyResult} />;
      case tabTypes.vacation:
        return <Vacation list={leaveStatus} />;

      default:
        return (
          <WorkRecord list={{
            staffStampList, employment, getLoginStaffCcde, getLoginStaffName,
          }}
          />
        );
    }
  };

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <DatePicker
          dateFormat="yyyy/MM"
          date={yearMonth}
          changeDate={(date: Date) => {
            setTargetDate(date);
          }}
          showMonthYearPicker={true}
          customStyle={css({ width: 'fit-content' })}
        />

        <TabControl
          items={[
            {
              value: tabTypes.workRecord,
              display: '勤務実績',
            },
            {
              value: tabTypes.result,
              display: '申請結果',
            },
            {
              value: tabTypes.vacation,
              display: '休暇状況',
            },
          ]}
          setValue={setSelectType as (arg: string) => void}
          currentValue={selectType}
        />
        <div>{renderTab(selectType)}</div>
      </FormContents>
    </BlockUI>
  );
};
export default WorkingStatusLayout;
