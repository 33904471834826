/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import Amplify, { Auth } from "aws-amplify";

import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import FormField from "components/atoms/Form/FormField";
import TextForm from "components/molecules/TextForm";
import Button, { PrimaryButton } from "components/atoms/Button";
import { useHistory, Link, useParams } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import { getCognitoInfo } from "api/login";
import { generateCognitoInfo } from "utility/generateCognitoInfo";
import { getAuthorizedFromDelious } from "utility";

const ActivationSchema = Yup.object().shape({
  email: Yup.string()
    .email("形式がemailではありません")
    .required("メールアドレスを入力してください"),
});

const ForgotPasswordInputForm: React.FC = () => {
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();
  const { companyCode } = useParams<any>();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      getCognitoInfo(companyCode ?? "", formik.values.email).then((res) => {
        localStorage.setItem("loginUser.companyCode", res.companyCode);
        sessionStorage.setItem("loginUser.companyCode", res.companyCode);

        // 設定情報をAmplifyに反映させる
        Amplify.configure(generateCognitoInfo(res));

        Auth.forgotPassword(formik.values.email)
          .then((error: any) => {
            successNotification(`${formik.values.email} に認証コードを送信しました`);
            history.push(
              getAuthorizedFromDelious()
                ? `/forgotPasswordSubmit/${companyCode}/${formik.values.email}`
                : `/forgotPasswordSubmit/${formik.values.email}`,
            );
          })
          .catch((err: any) => {
            if (err.code === "UserNotFoundException") {
              errorNotification("入力されたメールアドレスのユーザーは登録されておりません");
            } else {
              errorNotification("認証コードの送信に失敗しました");
            }
          });
      });
    },
    validationSchema: ActivationSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormField>
        <TextForm
          name="email"
          label=""
          placeHolder="メールアドレスを入力"
          value={formik.values.email}
          onChange={formik.handleChange}
          errorMsg={formik.errors.email}
        />
      </FormField>

      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%", marginTop: "20px" })}>
          <Button text="次へ" onClick={formik.handleSubmit} />
        </div>
      </FormSubmitArea>
      <FormSubmitArea>
        <div css={css({ textAlign: "center", width: "100%" })}>
          <Link to={getAuthorizedFromDelious() ? `/login/${companyCode}` : "/login"}>
            <PrimaryButton ghost={true} text="キャンセル" />
          </Link>
        </div>
      </FormSubmitArea>
    </form>
  );
};

export default ForgotPasswordInputForm;
