/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useCallback } from "react";
import { getApplyingStampListHistory } from "../api";
import { IchangeLog } from "../interface";
import {
  getApplyingHolidayListHistory,
  getApplyingOverTimeListHistory,
  getApplyingHistoryWorkListHistory,
  getApplyingTransferListHistory,
  getApplyingAgreement36ListHistory,
  getApplyingWorkScheduleChangeListHistory,
  getStockPaidListHistory,
  getApplyingAllowanceListHistory,
} from "api/getHistoryApplication";

export const useApplyingTransferForm = (list: any) => {
  const [isLoading, setLoading] = useState(false);
  const [targetDate] = useState(sessionStorage.getItem("application.targetDate") || "");
  const [staffName] = useState(sessionStorage.getItem("application.dispStaffName") || "");
  // const [selectStaffCode] = useState(
  //   sessionStorage.getItem("application.staffCode") || ""
  // );
  const [dispStaffName] = useState(sessionStorage.getItem("application.dispStaffName") || "");
  const [selectOrgCode, setSelectOrgCode] = useState(sessionStorage.getItem("application.orgCode") || "");
  // const orgTreesOptions = useAllOrgTrees();
  const [toastMessage, setToastMessage] = useState("");
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmApplyModalOpen, setConfirmApplyModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [buttonType, setButtonType] = useState<number>(0);

  const [changeLogModalOpen, setChangeLogModalOpen] = useState<boolean>(false);
  const [changeLogData, setChangeLogData] = useState<IchangeLog[]>([]);
  const [selectChangelog, setSelectChangelog] = useState<string>("0");
  const [changeReason, setChangeReason] = useState("");

  const rejectReasonOnchange = (index: number, event: any) => {
    let rejectReason = event.target.value;
    setChangeReason(rejectReason);
    let newList = [...list];
    newList[index].rejectReason = rejectReason;
  };

  const extractBulkApproveData = (data: any[]) => {
    let approvalDataList = data.map((item: any) => ({
      staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
      buttonType: 0,
      modifierApplicationId: item.modifierApplicationId,
      appStaffName: "",
      targetDate: item.targetDate,
      belongOrgName: item.orgName,
      orgName: item.orgName,
    }));
    return {
      approvalDataList,
      applicationTransferList_length: approvalDataList.length * 5,
    };
  };

  const extractBulkHolidayApproveData = (data: any[]) => {
    let approvalDataList = data.map((item: any) => ({
      approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
      buttonType: 0,
      modifierApplicationId: item.modifierApplicationId,
      appStaffName: "",
      targetDate: item.targetDate,
      belongOrgName: item.orgName,
      orgName: item.orgName,
      staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    }));
    return {
      approvalDataList,
      applicationTransferList_length: approvalDataList.length * 5,
    };
  };

  const extractBulkStockPaidApproveData = (data: any[]) => {
    let approvalDataList = data.map((item: any) => ({
      modifierApplicationId: item.modifierApplicationId,
      staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
      buttonType: 1,
      applyTargetDateType: item.applyTargetDateType,
      targetStartDate: item.targetStartDate,
      targetEndDate: item.targetEndDate,
      rejectReason: item.rejectReason,
    }));
    return {
      approvalDataList,
      applicationTransferList_length: approvalDataList.length * 5,
    };
  };

  const extractSingleApproveData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    approvalStaffCode: data[0].approvalStaffCode || "",
    achievementId: data[0].achievementId || "",
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: data[0].rejectReason,
  });

  const extractSingleStockPaidApproveData = (data: any[]) => ({
    companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
    modifierApplicationId: data[0].modifierApplicationId,
    applicationStatus: 1,
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    rejectReason: data[0].rejectReason,
    currentStaffCode: data[0].staffCode,
    currentOrgCode: data[0].orgCode,
  });

  const extractSingleHolidayApproveData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    // approvalStaffCode: data[0].approvalStaffCode || "",
    achievementId: data[0].achievementId || "",
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    buttonType: 0,
    applicationStatus: 1,
    rejectReason: data[0].rejectReason,
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
  });

  const extractSingleRejectData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    approvalStaffCode: data[0].approvalStaffCode || "",
    achievementId: data[0].achievementId || "",
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    applicationStatus: 2,
    rejectReason: data[0].rejectReason,
    buttonType: 1,
  });

  const extractSingleStockPaidRejectData = (data: any[]) => ({
    companyCode: sessionStorage.getItem("loginUser.companyCode") || "",
    modifierApplicationId: data[0].modifierApplicationId,
    applicationStatus: 2,
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    rejectReason: data[0].rejectReason,
    currentStaffCode: data[0].staffCode,
    currentOrgCode: data[0].orgCode,
  });

  const extractSingleHolidayRejectData = (data: any[]) => ({
    modifierApplicationId: data[0].modifierApplicationId,
    approvalStaffCode: sessionStorage.getItem("loginUser.staffCode") || "",
    // approvalStaffCode: data[0].approvalStaffCode || "",
    achievementId: data[0].achievementId || "",
    orgName: data[0].orgName,
    belongOrgName: data[0].orgName,
    applicationStatus: 2,
    rejectReason: data[0].rejectReason,
    buttonType: 1,
    staffCode: sessionStorage.getItem("loginUser.staffCode") || "",
  });

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  const closeConfirmApplyModal = useCallback(() => {
    setConfirmApplyModalOpen(false);
  }, []);

  const fetchChangeLog = async (applicationId: string) => {
    let history = await getApplyingStampListHistory({ applicationId });
    setChangeLogData(history);
  };

  const fetchChangeLogHoliday = async (applicationId: string) => {
    let history = await getApplyingHolidayListHistory({ applicationId });
    setChangeLogData(history);
  };

  const fetchStockPaid = async (applicationId: string) => {
    let history = await getStockPaidListHistory({ applicationId });
    setChangeLogData(history);
  };

  const fetchChangeLogHolidayWork = async (application: any) => {
    const param = {
      orgCode: application.orgCode,
      targetDate: application.targetDate,
      staffCode: application.staffCode,
    };
    let history = await getApplyingHistoryWorkListHistory(param);
    setChangeLogData(history);
  };

  const fetchChangeLogOverTime = async (application: any) => {
    const param = {
      orgCode: application.orgCode,
      targetDate: application.targetDate,
      staffCode: application.staffCode,
    };
    let history = await getApplyingOverTimeListHistory(param);
    setChangeLogData(history);
  };

  const fetchChangeLogAllowance = async (application: any) => {
    const param = {
      orgCode: application.orgCode,
      targetDate: application.targetDate,
      staffCode: application.staffCode,
    };
    let history = await getApplyingAllowanceListHistory(param);
    setChangeLogData(history);
  };

  const fetchChangeLogAgreement36 = async (application: any) => {
    const param = {
      orgCode: application.orgCode,
      targetDate: application.targetDate,
      staffCode: application.staffCode,
    };
    let history = await getApplyingAgreement36ListHistory(param);
    setChangeLogData(history);
  };

  const fetchChangeLogWorkScheduleChange = async (application: any) => {
    const param = {
      shiftId: application.shiftId,
    };
    let history = await getApplyingWorkScheduleChangeListHistory(param);
    setChangeLogData(history);
  };

  const fetchChangeLogTransfer = async (application: any) => {
    const param = {
      orgCode: application.orgCode,
      targetDate: application.targetDate,
      staffCode: application.staffCode,
    };
    let history = await getApplyingTransferListHistory(param);
    setChangeLogData(history);
  };

  return {
    // formik,
    // orgTreesOptions,
    selectOrgCode,
    setSelectOrgCode,
    staffName,
    targetDate,
    toastMessage,
    setToastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    setConfirmApplyModalOpen,
    closeToastModal,
    closeConfirmApplyModal,
    isLoading,
    setLoading,
    dispStaffName,

    extractSingleApproveData,
    extractSingleHolidayApproveData,
    extractSingleStockPaidApproveData,
    extractBulkApproveData,
    extractBulkHolidayApproveData,
    extractBulkStockPaidApproveData,
    extractSingleRejectData,
    extractSingleHolidayRejectData,
    extractSingleStockPaidRejectData,

    rejectReasonOnchange,

    buttonType,
    setButtonType,
    selectedIndex,
    setSelectedIndex,

    changeLogModalOpen,
    setChangeLogModalOpen,
    changeLogData,
    setChangeLogData,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLog,
    changeReason,
    setChangeReason,
    fetchChangeLogHolidayWork,
    fetchChangeLogOverTime,
    fetchChangeLogAllowance,
    fetchChangeLogTransfer,
    fetchChangeLogHoliday,
    fetchChangeLogAgreement36,
    fetchChangeLogWorkScheduleChange,
    fetchStockPaid,
  };
};

// export default useApplyingTransferForm;
