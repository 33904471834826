/** @jsx jsx */
import React from "react";

import TextForm from "components/molecules/TextForm";
import Button, { DeleteButton } from "components/atoms/Button";
import PrimaryButton from "components/atoms/Button";
import productColor, {
  utilityColor,
  grayScale,
  textFontSize,
} from "components/styles";
import { css, jsx } from "@emotion/core";
import ConfirmModal from "components/organismos/ConfirmModal";
import { useHistory } from "react-router-dom";
import Checkbox from "components/molecules/Checkbox";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import FormLabel from "components/atoms/Form/FormLabel";
import FormTitle from "components/atoms/Form/FormTitle";
import FormContents from "components/atoms/Form/FormContents";
import BlockUI from "components/molecules/BlockUi";
import { useAttendanceLayoutDomainAddForm, useOrganizationList } from "./hooks";
import { useAttendList } from "./hooks";
import DataTable from "components/organismos/DataTable/DataTable";
import OrganizationDomain from "domain/organization";
import { Column } from "react-table";
// create style form
const styles = {
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left",
  }),
  input: css({
    flex: 1,
    width: "100px",
    padding: "7px",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    color: productColor.primary,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: "none",
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: `1px solid ${grayScale.gray10}`,
    borderRadius: "4px 0 0 4px",
  }),
  addon: css({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "7px",
    color: productColor.primary,
    backgroundColor: productColor.primaryM95,
    borderTop: `1px solid ${grayScale.gray10}`,
    borderRight: `1px solid ${grayScale.gray10}`,
    borderBottom: `1px solid ${grayScale.gray10}`,
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
  }),
  cellTable: css(
    {
      ".sortable-ghost": {
        border: `dotted ${productColor.primary}`,
      },
      width: "100%",
      border: "none",
      borderSpacing: "0px 1.5px",
      overflow: "scroll",
    },
    css`
      @media only screen and (min-width: 1580px) {
        .widthDesktopLarge {
          width: calc(100vw - 500px) !important;
        }
      }
    `
  ),
  noteText: css({
    fontSize: textFontSize.sm,
    color: utilityColor.error,
    marginLeft: "4px",
    float: "right",
    marginTop: "-37px",
  }),
  title: css({
    paddingLeft: "30px",
    marginTop: "30px",
    marginBottom: "0px",
    fontSize: "16px",
    color: "#666666",
  }),
  customCheckbox: css({
    path: {
      fill: productColor.primary,
    },
  }),
  tableScroll: css({
    overflowY: "auto",
    borderCollapse: "collapse",
    borderTop: `1px solid ${grayScale.gray03}`,
    tableLayout: "auto",
    "thead th": {
      position: "sticky",
      top: -1,
      zIndex: 2,
      borderTop: "0!important",
      borderBottom: "0!important",
      boxShadow: `inset 0 -1px 0 ${grayScale.gray03}`,
    },
    "tbody th:first-child": {
      width: "50px",
    },
    "tbody tr td:first-child": {
      width: "50px",
    },
  }),
};

const AttendanceInputAddForm: React.FC<{ stampIpId: string }> = ({
  stampIpId,
}) => {
  const { organizationList } = useOrganizationList();
  const {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    detailRole,
    selectedOrg,
    setSelectedOrg,
    isCheckedAll,
    isLoading,
    setIsCheckedAll,
  } = useAttendanceLayoutDomainAddForm(stampIpId);

  const {
    confirmModalOpenDelete,
    setConfirmModalOpenDelete,
    closeConfirmModalDelete,
    deleteStampSettingIP,
  } = useAttendList();
  const history = useHistory();
  const handleBack = async () => {
    history.push("/stampIpSetting");
  };

  const columns: Array<Column<OrganizationDomain>> = React.useMemo(
    () => [
      {
        Header: () => (
          <Checkbox
            id="checkbox_all"
            name="checkbox_all"
            value="all"
            checked={
              isCheckedAll &&
              selectedOrg.length === organizationList.length &&
              organizationList.length !== 0
            }
            isCheckBoxTable={true}
            onChange={(e) => {
              setIsCheckedAll(!isCheckedAll);
              if (!isCheckedAll) {
                setSelectedOrg(
                  organizationList.map((val) => {
                    return val.orgCode;
                  })
                );
                return false;
              }
              setSelectedOrg([]);
            }}
          />
        ),
        disableSortBy: true,
        id: "",
        width: "100px",
        accessor: "checkbox",
        Cell: (cell: {
          row: { isExpanded: boolean; original: any; index: number };
          data: any;
        }) => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              id={`checkbox_${cell.row.original.orgCode}`}
              name="name"
              className="checks"
              value={`${cell.row.original.orgCode}`}
              checked={selectedOrg.includes(cell.row.original.orgCode)}
              isCheckBoxTable={true}
              onChange={() => {
                if (selectedOrg.includes(cell.row.original.orgCode)) {
                  setSelectedOrg(
                    selectedOrg.filter(
                      (selected) => selected !== cell.row.original.orgCode
                    )
                  );
                } else {
                  setSelectedOrg([...selectedOrg, cell.row.original.orgCode]);
                  if (selectedOrg.length === organizationList.length - 1) {
                    setIsCheckedAll(true);
                  }
                }
              }}
            />
            <input
              type="hidden"
              className="rowDataIndex1"
              row-data-index={cell.row.index}
            />
          </div>
        ),
      },
      {
        Header: "組織コード",
        accessor: "orgCode",
      },
      {
        Header: "組織名",
        accessor: "orgName",
      },
    ],
    [organizationList, selectedOrg, detailRole]
  );

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle
          bold={true}
          title={stampIpId ? "打刻設定編集" : "打刻設定作成"}
        />

        <form onSubmit={formik.handleSubmit}>
          <div>
            <div style={{ backgroundColor: "#fff" }}>
              <div style={{ padding: "30px", maxWidth: "400px" }}>
                <div>
                  <FlexBox customStyle={css({ marginTop: "12px" })}>
                    <FlexBoxItem width="150px">
                      <FormLabel label="IPアドレス" />
                    </FlexBoxItem>
                    <FlexBoxItem>
                      <TextForm
                        name="ip"
                        label=""
                        placeHolder="222.222.222.222/8"
                        value={formik.values.ip}
                        onChange={formik.handleChange}
                      />
                    </FlexBoxItem>
                  </FlexBox>
                </div>
              </div>

              <div className="message-note">
                <p
                  style={{
                    color: productColor.primary,
                    fontSize: "14px",
                    marginLeft: 30,
                  }}
                >
                  表示されているIPアドレスを割り当てる組織をチェックしてください。
                </p>
              </div>
              <div className="table-scroll">
                <DataTable
                  columns={columns}
                  data={organizationList}
                  isGlobalFilter={true}
                  minWidth="632px"
                  customStyle={styles.tableScroll}
                  containerStyle={css({
                    overflowX: "auto",
                  })}
                  sortBy={[
                    { id: "orgCode", desc: true },
                    { id: "orgName", desc: true },
                  ]}
                />
              </div>
            </div>
            <div style={{ textAlign: "center", margin: "15px auto" }}>
              {detailRole.editable === 1 && (
                <FlexBox justifyContent="center">
                  <FlexBoxItem>
                    <Button
                      text={stampIpId ? "更新" : "登録"}
                      onClick={formik.handleSubmit}
                    />
                  </FlexBoxItem>
                  {stampIpId && (
                    <FlexBoxItem marginLeft="12px">
                      <DeleteButton
                        text="削除"
                        onClick={() => setConfirmModalOpenDelete(true)}
                      />
                    </FlexBoxItem>
                  )}
                </FlexBox>
              )}
              <div>
                <PrimaryButton
                  text="キャンセル"
                  onClick={() => handleBack()}
                  ghost={true}
                />
              </div>
            </div>
          </div>

          <ConfirmModal
            open={confirmModalOpen}
            closeHandler={closeConfirmModal}
            onSubmit={() => {
              formik.handleSubmit();
            }}
            title={stampIpId ? "IPアドレス範囲指定" : "IPアドレス範囲指定"}
            content={
              stampIpId
                ? `IPアドレスを更新します。よろしいですか？`
                : `IPアドレスを登録します。よろしいですか？`
            }
            submitText={stampIpId ? "更新する" : "登録する"}
          />

          <ConfirmModal
            open={confirmModalOpenDelete}
            closeHandler={closeConfirmModalDelete}
            onSubmit={() => deleteStampSettingIP(stampIpId)}
            title="IPアドレスの削除"
            content="IPアドレスを削除します。よろしいですか？"
            submitText="削除する"
            items={[]}
            typeSubmit={`reject`}
          />
        </form>
      </FormContents>
    </BlockUI>
  );
};

export default AttendanceInputAddForm;
