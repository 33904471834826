import { useContext } from 'react';
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useCallback } from 'react';
import { importFileCSV, Error, getHistoryImportCsv } from 'api/executive';
import useToastNotification from 'hooks/useToastNotification';
import { LoadingContext } from './ExecutiveFormImportLayoutPage'

export const useExecutiveCsvForm = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const [errors, setErrors] = useState<Array<string>>();
  const [toastMessage, setToastMessage] = useState('');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [inputValue, setValueInput] = useState('');
  const { successNotification, errorNotification } = useToastNotification();

  const contextObject = useContext(LoadingContext);
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  if (uploadFile) {
    const fileType = uploadFile.type;
    if (fileType !== 'text/csv' && fileType !== 'application/vnd.ms-excel') {
      setToastModalOpen(true);
      setToastMessage('CSVファイルを選択してください。');
      setUploadFile(undefined);
      setValueInput('');
    }
  }

  const onSubmit = useCallback(() => {
    if (!uploadFile) {
      return;
    }
    setConfirmModalOpen(false);
    setErrors(undefined);
    contextObject.setIsLoading(true);

    importFileCSV(uploadFile)
      .then((response: any) => {
        setUploadFile(undefined);
        contextObject.setIsLoading(false);
        if (response.status !== 200) {
          setErrors((response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        } else {
          successNotification('インポートが完了しました');
        }
      }).catch((error: any) => {
        setUploadFile(undefined);
        contextObject.setIsLoading(false);
        setErrors((error.response.data.errors as Array<Error>).map((err) => err.defaultMessage));
        if (error.response && error.response.data && error.response.data.errors) {
        } else {
          errorNotification('サーバー側でエラーが発生しました。');
        }
      });
  }, [errorNotification, successNotification, uploadFile, contextObject]);

  const handleSubmit = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);


  // History
  const [historyImport, setHistoryImport] = useState([])
  const fetchHistoryImport = useCallback(async () => {
    contextObject.setIsLoading(true);

    try {
      getHistoryImportCsv().then((response: any) => {
        setHistoryImport(response);
        contextObject.setIsLoading(false);
      })
    } catch (error) {
      contextObject.setIsLoading(false);
      errorNotification('サーバー側でエラーが発生しました。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    uploadFile,
    setUploadFile,
    onSubmit,
    errors,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    setToastMessage,
    confirmModalOpen,
    closeConfirmModal,
    handleSubmit,
    inputValue,
    historyImport,
    fetchHistoryImport
  };
};

export default useExecutiveCsvForm;
