/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useCallback, useContext, useEffect } from "react";
import useToastNotification from "hooks/useToastNotification";
import * as icCard from 'hooks/useICCard';
import { ICCardByStaffDomain } from "domain/master/icCard/listICCardByStaff";
import { getListICCardByStaff, postRegisterStaffICCard, getUnRegisterCard, postDeleteStaffICCard } from 'api/iCCard';
import { useParams } from 'react-router-dom';
import useAudio from 'hooks/useAudio';
import { useSelector } from 'react-redux';
import { Store } from 'modules/store';
import { useDispatch } from 'react-redux';
import { uiAppBarActions } from 'modules/ui/appBar';
import { icCardActions } from 'modules/ui/icCard';

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";
const AUTO_PAUSE_DETECT_CARD = false;

const { errorNotification, successNotification } = useToastNotification();

export const useMasterICCardStaffSettingForm = () => {
  
  const [isConnectUsb, setIsConnectUsb] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteCode, setDeleteCode] = useState('');
  const [codeICCard, setCodeICCard] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [listICCardByStaff, setListICCardByStaff] = useState<Array<ICCardByStaffDomain>>([]);
  const [listICCardUnRegister, setListICCardUnRegister] = useState<Array<any>>([]);
  const [openModalAddCode, setOpenModalAddCode] = useState(false);
  const [openModalSelectCode, setOpenModalSelectCode] = useState(false);
  const { staffCode } = useParams();
  const staffLabel = sessionStorage.getItem('masterICCard.staff');
  const orgLabel = sessionStorage.getItem('masterICCard.organization');
  const [idCode, setIdCode] = useState('');
  const playAudioSuccess = useAudio('/audio/soundDetectSuccess.mp3').playAudio;

  // redux
  const dispatch = useDispatch();

  const isConnectICCardUsb = useSelector((state:Store) => state.ui.icCard.isConnectICCardUsb);
  const deviceICCard = useSelector((state:Store) => state.ui.icCard.deviceICCard);
  const cardId = useSelector((state:Store) => state.ui.icCard.cardId);
  const [actionDetect, setActionDetect] = useState(false);

  const detectCard = () => {
    try {
      if(!deviceICCard){
        icCard.connectDevice(dispatch);
      }
      setActionDetect(true);
      // icCard.detectCard(deviceICCard, dispatch);  
      // icCard.detectCard(setCodeICCard, setIsConnectUsb, dispatch);
    } catch (error) {
      errorNotification(error);
    }
  }
  
  useEffect(() => {
    if(deviceICCard && actionDetect && dispatch){
      icCard.detectCard(deviceICCard, dispatch, AUTO_PAUSE_DETECT_CARD);
    }
  }, [deviceICCard, actionDetect, dispatch])
  
  useEffect(() => {
    return () =>{
      dispatch(icCardActions.setDisabledDetect(false));
      dispatch(icCardActions.setCardId(''));
    }
  }, [])

  useEffect(() => {
    if(cardId){
      playAudioSuccess();
      setIdCode(cardId);
    }
  }, [cardId])

  const fetchUnRegisterCard = useCallback(async () => {
    setIsLoading(true);
    getUnRegisterCard().then((response: any) => {
      setListICCardUnRegister(response.map((card: ICCardByStaffDomain) => {
        return {
          value: card.cardId,
          label: card.cardId
        }
    }));
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, []);

  const fetchStaffICCard = useCallback(async () => {
    setIsLoading(true);
    getListICCardByStaff(
      staffCode || ""
    ).then((response: any) => {
      setListICCardByStaff(response)
      setIsLoading(false);
    })
    .catch((error: any) => {
      setIsLoading(false);
    });
  }, [staffCode]);

  useEffect(() => {
    
    if(staffCode){  
      fetchStaffICCard();
    }
  }, [staffCode])

  const registerStaffICCard = useCallback(async (idCode: string) => {
    if(idCode === ""){
      errorNotification('ICカード番号をご入力ください。');
      return;
    }
    setIsLoading(true);
    postRegisterStaffICCard(
      idCode,
      staffCode || "",
    ).then((response: any) => {
      setIsLoading(false);
      setIdCode('');
      successNotification('登録しました。');
      fetchStaffICCard();
    })
    .catch((error: any) => {
      setIsLoading(false);
      setIdCode('');
      if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }      
    });
  }, [staffCode]);
  
  const deleteStaffICCard = useCallback(async (idCode: string) => {
    setIsLoading(true);
    postDeleteStaffICCard(
      idCode,
      staffCode || "",
    ).then((response: any) => {
      setIsLoading(false);
      successNotification('削除しました。');
      fetchStaffICCard();
    })
    .catch((error: any) => {
      setIsLoading(false);
      if (error.response.data.status !== 500 && error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        listErr.map((element: any) => errorNotification(element.defaultMessage));
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }      
    });
  }, [staffCode]);

  return {
    isConnectUsb,
    setIsConnectUsb,
    codeICCard,
    setCodeICCard,
    detectCard,
    listICCardByStaff,
    registerStaffICCard,
    isLoading,
    setIsLoading,
    fetchUnRegisterCard,
    listICCardUnRegister,
    staffLabel,
    orgLabel,
    idCode,
    setIdCode,
    openModalAddCode,
    setOpenModalAddCode,
    openModalSelectCode,
    setOpenModalSelectCode,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    deleteCode,
    setDeleteCode,
    deleteStaffICCard,
    cardId,
    isConnectICCardUsb
  };
};
export default useMasterICCardStaffSettingForm;
