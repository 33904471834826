import {
  useState, useCallback, useEffect,
} from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import FiscalYearDomain from 'domain/master/sales/fiscalYear';
import { postFiscalYear, getFiscalYear } from 'api/fiscalYear';
import useToastNotification from 'hooks/useToastNotification';

type FiscalYearDomainKey = keyof Pick<FiscalYearDomain, 'fiscalYear'|'fiscalStartMonth'|'fiscalEndMonth'>;

export const useFiscalYearAddForm = () => {
  const { fiscalYear } = useParams();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState('登録します。よろしいですか？');
  const [confirmModalSubmitText, setConfirmModalSubmitText] = useState('登録');
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();
  const history = useHistory();

  const onSubmit = async (values: FiscalYearDomain) => {
    if (values.fiscalYear === null) {
      errorNotification('パターンコードを入力してください。');
      return;
    }
    if (values.fiscalStartMonth === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }
    if (values.fiscalEndMonth === null) {
      errorNotification('パターン名を入力してください。');
      return;
    }

    if (fiscalYear) {
      setConfirmModalContent('更新します。よろしいですか？');
      setConfirmModalSubmitText('更新');
    }
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }

    try {
      const postData = values.getRawDataPost();
      if (fiscalYear) {
        postData.isCreate = false;
      }
      postData.createUser = sessionStorage.getItem('loginUser.staffName') || '';
      postData.updateUser = sessionStorage.getItem('loginUser.staffName') || '';

      const response = await postFiscalYear(postData);
      setConfirmModalOpen(false);
      if (!response.errors) {
        successNotification('登録しました。');
        handleBack();
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: FiscalYearDomain.generateInitial(),
    onSubmit,
  });

  useEffect(() => {
    if (fiscalYear) {
      getFiscalYear(fiscalYear).then((response: any) => {
        formik.setValues(new FiscalYearDomain(response));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYear]);

  const handleBack = async () => {
    history.push('/masterFiscalYear');
  };

  // 登録内容確認モーダル用データ
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const closeToastModal = useCallback(() => {
    setToastModalOpen(false);
  }, []);

  return {
    formik,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    closeToastModal,
    handleBack,
    fiscalYear,
    confirmModalContent,
    confirmModalSubmitText,
  };
};

export default {
  useFiscalYearAddForm,
};
