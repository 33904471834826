import React, { useCallback, useRef, useState, useEffect } from "react";
import { gridCol, utilityColor } from "components/styles";
import { useHistory } from "react-router-dom";
import Icon from "components/atoms/Icon";
import moment from "moment";
import TextTooltip from "components/molecules/TextTooltip";
import IconLabelButton from "components/molecules/IconLabelButton";
import { DetailRole } from "api/masterRole";

const TimeTable: React.FC<{
  eventScroll: any;
  subTotalTimeList: Array<any>;
  business: Array<any>;
  term: Array<any>;
  targetDateFrom: Date;
  targetDateTo: Date;
  selectedEmploymentId: String;
  selectOrgCode: String;
  selectedClosingDate: String;
  attendFlag: String;
  detailRole: DetailRole;
  detailFlag: number;
}> = ({
  eventScroll,
  subTotalTimeList,
  business,
  term,
  targetDateFrom,
  targetDateTo,
  selectedEmploymentId,
  selectOrgCode,
  selectedClosingDate,
  attendFlag,
  detailRole,
  detailFlag,
}) => {
  const history = useHistory();
  const refTableScroll = useRef(Object());
  const refTableContent = useRef(Object());
  const [offsetWidthTable, setOffsetWidthTable] = useState(0);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";

  useEffect(() => {
    const width = refTableScroll.current ? refTableScroll.current.offsetWidth : 0;
    setOffsetWidthTable(width);
  }, [refTableScroll.current.offsetWidth]);

  useEffect(() => {
    if (refTableContent.current) {
      console.log(refTableContent.current.offsetWidth);
      console.log(refTableContent.current.clientWidth);
      const width = refTableContent.current.offsetWidth - refTableContent.current.clientWidth;
      setScrollBarWidth(width);
    }
  }, [subTotalTimeList]);

  const handleDispDetail: (item: any) => void = useCallback(
    (item) => {
      // 打刻データ確認のセッション情報をセット
      sessionStorage.setItem("headquartersFinal.viewPeriod", "daily");
      // sessionStorage.setItem("attendStampList.viewPeriod", "daily");
      sessionStorage.setItem("attendStampList.isGoBack", "true");

      sessionStorage.setItem(
        "headquartersFinal.monthlyList.searchTargetDateFrom",
        moment(targetDateFrom).format("YYYY-MM-DD"),
      );
      sessionStorage.setItem(
        "headquartersFinal.monthlyList.searchTargetDateTo",
        moment(targetDateTo).format("YYYY-MM-DD"),
      );
      sessionStorage.setItem("headquartersFinal.monthlyList.searchEmploymentId", String(selectedEmploymentId));
      sessionStorage.setItem("headquartersFinal.monthlyList.returnDestination", "/attendMonthlyList");

      sessionStorage.setItem("attendStampList.orgCode", String(selectOrgCode));
      sessionStorage.setItem("attendStampList.staffCode", item.staffCode);
      sessionStorage.setItem("attendStampList.targetDateFrom", moment(targetDateFrom).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.targetDateTo", moment(targetDateTo).format("YYYY-MM-DD"));
      sessionStorage.setItem("attendStampList.closingDate", String(selectedClosingDate));
      sessionStorage.setItem("attendStampList.employmentId", String(selectedEmploymentId));
      sessionStorage.setItem("attendStampList.attendFlag", String(attendFlag));

      history.push("/attendStampList");
    },
    [attendFlag, history, selectOrgCode, selectedClosingDate, selectedEmploymentId, targetDateFrom, targetDateTo],
  );

  function timeDisp(time: any) {
    if (time === 0 || !time) {
      return "-";
    }
    return time;
  }

  const uiStaffCode = (id: any) => {
    return (
      <>
        {id?.length > 8 ? (
          <TextTooltip text={id} textDis={<div className="ellipsis headline-cell-width-90">{id}</div>} />
        ) : (
          <span>{id}</span>
        )}
      </>
    );
  };
  return (
    <div className="wrap-attend-monthly-list">
      <div style={{ display: "flex" }}>
        <div className="top-left-area-time">
          <table
            className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
            style={{
              borderRight: "1px #d3d3d3 solid",
              borderTop: "1px #d3d3d3 solid",
            }}
          >
            <thead>
              <tr>
                <th className="headline-cell-width-90 head-line3-height padding-vertical-4 text-center">
                  スタッフ
                  <br />
                  コード
                </th>
                <th className="headline-cell-width-120 head-line3-height text-center">スタッフ名</th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="topRightAreaTime" onScroll={eventScroll} style={{ overflow: "auto" }}>
          <div style={{ width: `${offsetWidthTable + scrollBarWidth}px` }}>
            <table
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
              id="top-right-time"
              style={{
                borderRight: "1px #d3d3d3 solid",
                borderTop: "1px #d3d3d3 solid",
              }}
            >
              <thead>
                <tr>
                  <th className="active headline-cell-width-110 head-line3-height text-center border-left-none">
                    勤務日種別
                  </th>
                  <th className="active headline-cell-width-70 text-center">普通</th>
                  <th className="active headline-cell-width-70 text-center">
                    法定内
                    <br />
                    残業
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    法定外
                    <br />
                    残業
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    割増
                    <br />
                    残業1
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    割増
                    <br />
                    残業2
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    割増
                    <br />
                    残業3
                  </th>
                  <th className="active headline-cell-width-70 text-center">深夜</th>
                  <th className="active headline-cell-width-70 text-center">
                    深夜
                    <br />
                    法定内
                    <br />
                    残業
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    深夜
                    <br />
                    法定外
                    <br />
                    残業
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    深夜
                    <br />
                    割増
                    <br />
                    残業1
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    深夜
                    <br />
                    割増
                    <br />
                    残業2
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    深夜
                    <br />
                    割増
                    <br />
                    残業3
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    未承認
                    <br />
                    残業
                  </th>
                  <th className="active headline-cell-width-70 text-center">休憩</th>
                  <React.Fragment>
                    <th className="active headline-cell-width-70 text-center">オフィス</th>
                    <th className="active headline-cell-width-70 text-center">
                      {companyCode === "HPo6wkyuze" ? "在宅" : "テレワーク"}
                    </th>
                  </React.Fragment>
                  <th className="active headline-cell-width-70 text-center">遅刻</th>
                  <th className="active headline-cell-width-70 text-center">早退</th>
                  <th className="active headline-cell-width-70 text-center">
                    みなし
                    <br />
                    時間
                  </th>
                  <th className="active headline-cell-width-70 text-center">
                    総労働
                    <br />
                    時間
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

      <div className="table-content" ref={refTableContent}>
        <div style={{ display: "inline-flex", width: gridCol.grid12 }}>
          <div className="bottomLeftAreaTime">
            <table
              style={{ borderBottom: "1px #d3d3d3 solid" }}
              className="table-condensed nowrap time-table margin-bottom-0 fixedTable border-left-none border-right-none"
            >
              {subTotalTimeList.map((subTotalTime: any) => (
                <tbody key={subTotalTime.staffCode}>
                  <tr>
                    {!subTotalTime.hasError && !subTotalTime.hasAlert && (
                      <td
                        rowSpan={3}
                        className="headline-cell-width-90 head-line3-height text-center padding-vertical-4 border-right-none"
                      >
                        {uiStaffCode(subTotalTime.staffCode)}
                        {detailRole.linkAvailable1 && Boolean(detailFlag) && (
                          <IconLabelButton
                            onClick={() => handleDispDetail(subTotalTime)}
                            text="詳細"
                            textAlign="center"
                            isIcon={false}
                          />
                        )}
                      </td>
                    )}
                    {subTotalTime.hasError && !subTotalTime.hasAlert && (
                      <td className="headline-cell-width-90 head-line3-height text-center padding-vertical-4 border-right-none">
                        {uiStaffCode(subTotalTime.staffCode)}
                        <Icon type="remove" color={utilityColor.error} size="20px" />
                        <br />
                        {detailRole.linkAvailable1 && Boolean(detailFlag) && (
                          <IconLabelButton
                            onClick={() => handleDispDetail(subTotalTime)}
                            text="詳細"
                            textAlign="center"
                            isIcon={false}
                          />
                        )}
                      </td>
                    )}
                    {!subTotalTime.hasError && subTotalTime.hasAlert && (
                      <td
                        rowSpan={3}
                        className="headline-cell-width-90 head-line3-height text-center padding-vertical-4 border-right-none"
                      >
                        {uiStaffCode(subTotalTime.staffCode)}
                        <Icon type="warning" color={utilityColor.yellow} size="20px" />
                        <br />
                        {detailRole.linkAvailable1 && Boolean(detailFlag) && (
                          <IconLabelButton
                            onClick={() => handleDispDetail(subTotalTime)}
                            text="詳細"
                            textAlign="center"
                            isIcon={false}
                          />
                        )}
                      </td>
                    )}
                    {subTotalTime.hasError && subTotalTime.hasAlert && (
                      <td
                        rowSpan={3}
                        className="headline-cell-width-90 head-line3-height text-center padding-vertical-4 border-right-none"
                      >
                        {uiStaffCode(subTotalTime.staffCode)}
                        <Icon type="warning" color={utilityColor.yellow} size="20px" />
                        <Icon type="remove" color={utilityColor.error} size="20px" />
                        <br />
                        {detailRole.linkAvailable1 && Boolean(detailFlag) && (
                          <IconLabelButton
                            onClick={() => handleDispDetail(subTotalTime)}
                            text="詳細"
                            textAlign="center"
                            isIcon={false}
                          />
                        )}
                      </td>
                    )}
                    <td
                      style={{ borderRight: "1px #d3d3d3 solid" }}
                      rowSpan={3}
                      className="headline-cell-width-120 head-line3-height text-center"
                    >
                      <TextTooltip
                        text={`${subTotalTime.employmentName}<br /> ${subTotalTime.staffName}<br /> ${subTotalTime.staffOrgName}`}
                        textDis={
                          <div>
                            <div className="ellipsis headline-cell-width-120">{subTotalTime.employmentName}</div>
                            <div className="ellipsis headline-cell-width-120">{subTotalTime.staffName}</div>
                            <div className="ellipsis headline-cell-width-120">{subTotalTime.staffOrgName}</div>
                          </div>
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <div id="bottomRightAreaTime" style={{ overflow: "auto" }} onScroll={eventScroll}>
            <div style={{ width: `${offsetWidthTable}px` }}>
              <table
                style={{
                  borderRight: "1px #d3d3d3 solid",
                }}
                ref={refTableScroll}
                className="table-condensed nowrap time-table margin-bottom-0 fixedTable"
                id="bottom-right-time"
              >
                {subTotalTimeList.map((subTotalTime: any) => (
                  <tbody key={subTotalTime.staffCode}>
                    <tr>
                      <td className="headline-cell-width-110 head-line1-height text-center border-left-none">
                        所定日
                      </td>
                      {/* <!--普通時間 --> */}
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytime}</td>
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytimeLegalOvertime}</td>
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytimeWeekdayOvertime}</td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeWeekdayPremiumOvertime1}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeWeekdayPremiumOvertime2}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeWeekdayPremiumOvertime3}
                      </td>
                      {/* <!--深夜時間 --> */}
                      <td className="headline-cell-width-70 text-center">{subTotalTime.midnightWeekdayTotal}</td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightWeekdayLegalOvertime}
                      </td>
                      <td className="headline-cell-width-70 text-center">{subTotalTime.midnightWeekdayOvertime}</td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightWeekdayPremiumOvertime1}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightWeekdayPremiumOvertime2}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightWeekdayPremiumOvertime3}
                      </td>
                      {/* <!--未承認残業時間 --> */}
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytimeUnapprovedOvertime}</td>
                      <td rowSpan={3} className="headline-cell-width-70 text-center">
                        {subTotalTime.recess}
                      </td>

                      <React.Fragment>
                        <td rowSpan={3} className="headline-cell-width-70 text-center">
                          {timeDisp(subTotalTime.officeTime)}
                        </td>
                        <td rowSpan={3} className="headline-cell-width-70 text-center">
                          {timeDisp(subTotalTime.remoteTime)}
                        </td>
                      </React.Fragment>

                      <td rowSpan={3} className="headline-cell-width-70 text-center">
                        {subTotalTime.lateTime}
                      </td>
                      <td rowSpan={3} className="headline-cell-width-70 text-center">
                        {subTotalTime.earlierTime}
                      </td>
                      <td rowSpan={3} className="headline-cell-width-70 text-center">
                        {subTotalTime.deemedTime}
                      </td>
                      <td rowSpan={3} className="headline-cell-width-70 text-center">
                        {subTotalTime.allWorkTime}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline-cell-width-110 head-line1-height text-center border-left-none">
                        法定休日
                      </td>
                      {/* <!--普通時間 --> */}
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytimeLegalHoliday}</td>
                      <td className="headline-cell-width-70 text-center">-</td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeLegalHolidayOvertime}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeLegalHolidayPremiumOvertime1}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeLegalHolidayPremiumOvertime2}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeLegalHolidayPremiumOvertime3}
                      </td>
                      {/* <!--深夜時間 --> */}
                      <td className="headline-cell-width-70 text-center">{subTotalTime.midnightLegalHolidayTotal}</td>
                      <td className="headline-cell-width-70 text-center">-</td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightLegalHolidayOvertime}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightLegalHolidayPremiumOvertime1}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightLegalHolidayPremiumOvertime2}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightLegalHolidayPremiumOvertime3}
                      </td>
                      {/* <!--未承認残業時間 --> */}
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.leaglHolidayUnapprovedOvertime}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline-cell-width-110 head-line1-height text-center border-left-none">
                        所定休日
                      </td>
                      {/* <!--普通時間 --> */}
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytimeNotLegalHoliday}</td>
                      <td className="headline-cell-width-70 text-center">{subTotalTime.daytimeNotLegalOvertime}</td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeNotLegalHolidayOvertime}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeNotLegalHolidayPremiumOvertime1}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeNotLegalHolidayPremiumOvertime2}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.daytimeNotLegalHolidayPremiumOvertime3}
                      </td>
                      {/* <!--深夜時間 --> */}
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightNotLegalHolidayTotal}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightNotLegalHolidayLegalOvertime}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightNotLegalHolidayOvertime}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightNotLegalHolidayPremiumOvertime1}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightNotLegalHolidayPremiumOvertime2}
                      </td>
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.midnightNotLegalHolidayPremiumOvertime3}
                      </td>
                      {/* <!--未承認残業時間 --> */}
                      <td className="headline-cell-width-70 text-center">
                        {subTotalTime.notLeaglHolidayUnapprovedOvertime}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="content-scroll-bottom" onScroll={eventScroll}>
        <div
          id="scrollBottom"
          style={{
            width: "calc(100% - 224px)",
            float: "right",
            overflow: "auto",
          }}
        >
          {business && term && <div className="scroll" style={{ width: `${offsetWidthTable + scrollBarWidth}px` }} />}
        </div>
      </div>
    </div>
  );
};
export default TimeTable;
