/** @jsx jsx */
import React, { ReactNode } from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  input: (zIndex: number) => css({
    marginTop: '0px',
    zIndex: zIndex
  }),
  error: css({
    marginTop: '4px',
    color: 'red',
  }),
};

/**
 * フォームのレイアウト
 *  - display: flexでレイアウト
 *  - ラベルとinputの間にマージンをとる
 */
const VerticalLabelFormLayoutV2: React.FC<{
  label: ReactNode;
  input: ReactNode;
  errorMsg?: string;
  customCss?: SerializedStyles;
  zIndex?: number;
}> = ({
  label, input, errorMsg, customCss, zIndex = 2
}) => (
  <div css={css(styles.wrapper, customCss)}>
    {label}
    <div css={styles.input(zIndex)}>
      {input}
    </div>
    { errorMsg ? <span css={styles.error}> {errorMsg} </span> : '' }
    
  </div>
);

export default VerticalLabelFormLayoutV2;
