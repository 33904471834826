import React, {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
  createContext,
} from "react";

import FormContents from "components/atoms/Form/FormContents";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import PrimaryButton from "components/atoms/Button";
import { exportFilePDF } from "api/attendAchievementOutput";
import moment from "moment";
import { useGetSupportSourceOrg, downloadOriginalPDF } from "./hooks";
import useToastNotification from "hooks/useToastNotification";
import FormPadding from "components/atoms/Form/FormPadding";
import CalendarForm from "components/molecules/CalendarForm";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import { Row, Col } from "react-grid-system";
import { css } from "@emotion/core";
import { grayScale, textFontSize } from "components/styles";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";
import ClickableIconButton from "components/atoms/ClickableIconButton";
import Icon from "components/atoms/Icon";
import FlexBox from "components/atoms/FlexBox";
import FormLabel from "components/atoms/Form/FormLabel";
import CalendarMonth from "components/molecules/CalendarV1/CalendarMonth";
import SalaryHistoryDownload from "domain/master/attend/salaryHistoryDownload";
import {
  getAchivementHistoryDownloadList,
  postProcessingDownloadAchievement,
} from "api/achievementConfirm";
import SalaryHistoryDownloadForm from "./SalaryHistoryDownloadForm";
import { functionCode } from "const";
import { useOrgTreesOptionsShopV1 } from "hooks/useOrgTreesOptions";

type contextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  reloadHistory: boolean;
  setReloadHistory: Dispatch<SetStateAction<boolean>>;
};

export const AttendAchivementLayoutContext = createContext<contextType>(
  {} as contextType
);

const AttendHelpLayoutList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const orgCode = !sessionStorage.getItem("orgCode")
    ? sessionStorage.getItem("loginUser.orgCode")
    : sessionStorage.getItem("orgCode");
  const [getSupportSourceOrg, setSupportSourceOrg] = useState(orgCode);
  const [targetDate, setTargetDate] = useState(
    sessionStorage.getItem("targetDate") || new Date()
  );
  // const { organizationsTrees } = useGetSupportSourceOrg();
  const organizationsTrees = useOrgTreesOptionsShopV1(false, functionCode.attendAchievementOutput, moment(targetDate).format('YYYY-MM-DD'), moment(targetDate).format('YYYY-MM-DD'));

  const { errorNotification } = useToastNotification();
  const [salaryHistoryDownloadList, setSalaryHistoryDownloadList] = useState<Array<SalaryHistoryDownload>>([]);
  const [reloadHistory, setReloadHistory] = useState(true);
  const [isCancelRequest, setIsCancelRequest] = useState<boolean>(false);

  useEffect(() => {
    const loginStaffCode = sessionStorage.getItem("loginUser.staffCode") || '';
    const params = {
      pageId: 2,
      loginStaffCode
    };
    if (reloadHistory) {
      getAchivementHistoryDownloadList(params).then((response) => {
        setSalaryHistoryDownloadList(
          response.map((result: any) => new SalaryHistoryDownload(result))
        );
        setReloadHistory(false);
      });
    }
  }, [reloadHistory]);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    const createUser = sessionStorage.getItem("loginUser.staffCode") || '';
    const initialParams = {
      targetDateFrom: "",
      targetDateTo: "",
      employmentId: "",
      viewPeriod: "",
      closingDate: "",
      orgCode: getSupportSourceOrg || "",
      outputLayoutId: "",
      targetYmd: "",
      pageId: 2,
      attendFlag: 0,
      flagDownloadAll: 0,
      createUser: createUser,
      underFlag: 0
    };

    const orgFilter = organizationsTrees.filter((value) => value.value === getSupportSourceOrg)[0].label;
    const splitOrgName = orgFilter.split(" ")[1] || orgFilter.split(" ")[0];
    const fileName = "勤怠実績出力" + "_" + moment(targetDate).format("YYYYMM") + "_" + splitOrgName; 
    postProcessingDownloadAchievement({
      ...initialParams,
      ...{
        targetDate: moment(targetDate).format("YYYY-MM-01"),
        staffCode: "",
        isUpdate: 0,
        achievementDownloadId: "",
        fileName: fileName
      },
    }).then((response: any) => { 
      setReloadHistory(true);
      setIsLoading(false);
      exportFilePDF({
        orgCode: getSupportSourceOrg,
        targetDate: moment(targetDate).format("YYYY-MM-01"),
        achievementDownloadId: response?.attendAchievementDownloadId || "",
        fileName: response?.file.split("/")[2]
      })
        .then((response: any) => {
          setIsLoading(false);
          setReloadHistory(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setReloadHistory(true);
          if(!isCancelRequest) {
            errorNotification(
              "対象月、対象組織において在籍するスタッフがいませんでした。"
            );
          }
          
        });
    }).catch((error) => {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        let msgError = '';
        error.response.data.errors.map((item: { defaultMessage: string; }) => {
          msgError += `${item.defaultMessage} \n`;
          return msgError;
        });
        errorNotification(msgError);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      } 
    });
  }, [getSupportSourceOrg, errorNotification, targetDate]);

  useEffect(() => {
  },[salaryHistoryDownloadList])

  return (
    <AttendAchivementLayoutContext.Provider value={{ isLoading, setIsLoading, setReloadHistory, reloadHistory }}>
      <BlockUI blocking={isLoading}>
        <FormContents>
          <FormTitle title="勤怠実績印刷" bold={true} />
          <FormPadding customStyle={css({ padding: "10px 45px 15px 35px" })}>
            <FlexBox>
              <FlexBoxItem width="100px">
                <FormLabel
                  label="営業月"
                  customStyle={css({ width: "100px", margin: "0" })}
                />
              </FlexBoxItem>
              <FlexBoxItem>
                <FlexBox>
                  <FlexBoxItem>
                    <CalendarMonth
                      openActionChevron={true}
                      date={new Date(targetDate)}
                      setDate={(date: Date) => {
                        sessionStorage.setItem("targetDate", String(date));
                        setTargetDate(date);
                      }}
                    />
                  </FlexBoxItem>
                </FlexBox>
              </FlexBoxItem>
            </FlexBox>
            <FlexBox>
              <SelectForm
                label="組織"
                name="orgCode"
                value={String(getSupportSourceOrg)}
                setValue={(val: string) => setSupportSourceOrg(val)}
                options={organizationsTrees}
                required={false}
                placeholder=""
                width="900px"
                widthLabel="100px"
                marginRight="30px"
              />
            </FlexBox>
            <div style={{ marginTop: "15px", textAlign: "center" }}>
              <PrimaryButton
                ghost={false}
                text="印刷"
                onClick={handleClick}
                customStyle={css({
                  marginRight: "5px",
                })}
              />
            </div>
          </FormPadding>
          <FlexBox customStyle={css({ width: "100%" })}>
            <SalaryHistoryDownloadForm
              salaryHistoryDownloadList={salaryHistoryDownloadList}
              setSalaryHistoryDownloadList={setSalaryHistoryDownloadList}
              setReloadHistory={setReloadHistory}
              pageId={2}
              isCancelRequest={isCancelRequest} 
              setIsCancelRequest={setIsCancelRequest}
            />
          </FlexBox>
        </FormContents>
      </BlockUI>
    </AttendAchivementLayoutContext.Provider>
  );
};

export default AttendHelpLayoutList;
