/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useCallback, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { handleRecorderById, handleConfirmRecorder, handleConfirmRecorder_v1 } from "api/timeRecorder";
import useToastNotification from "hooks/useToastNotification";
import ConfirmRecorderByBarcodeIdDomain from "domain/timeRecorder/confirmRecorderByBarcodeIdDomain";
import RecorderByBarcodeIdDomain from "domain/timeRecorder/recorderByBarcodeIdDomain";

import InfoStaffTimeRecorderDomain from "domain/timeRecorder/infoStaffTimeRecorderDomain";
import { CurrentTimeDomain } from "domain/master/attend/currentTime";
import { TimeRecorderContext } from "components/organismos/RecorderV1/RecorderV1";

const LOCAL_STORAGE_SHOP_INFO_KEY_NAME = "win-board-localstorage-shop-info";

const { errorNotification, successNotification } = useToastNotification();

export const useBarcodeRecorder = (
  setOpenModal: any,
  setIsLoading: any,
  fetchHistoryData: any
) => {

  // get local storage
  const contextObject = useContext(TimeRecorderContext);  

  useEffect(() => {
    contextObject.setRemote(false);
  }, [])

  const [isConfirm, setIsConfirm] = useState(false);

  //Use New UI Remote
  const [newUiRemote, setNewUiRemote] = useState<boolean>(false);
  const [isRemote, setIsRemote] = useState<boolean>(false);

  // ID
  const [inputBarcodeId, setInputBarcodeId] = useState("");
  const [barcodeId, setBarcodeId] = useState("");

  // Staff
  const [stampMode, setStampMode] = useState(0);
  const [staffName, setStaffName] = useState("");
  const [staffCode, setStaffCode] = useState("");

  // type
  const [lastStampType, setLastStampType] = useState<any>();
  const [stampType, setStampType] = useState<any>();

  
  const [businessList, setBusinessList] = useState<any>();
  const [businessName, setBusinessName] = useState("")

  const [orgCode, setOrgCode] = useState("");
  const [orgName, setOrgName] = useState("");

  // Stamp Time 
  const [serverDateTime, setServerDateTime] = useState<Date|null >(null);

  const onSubmit = async (values: ConfirmRecorderByBarcodeIdDomain) => {
    setIsLoading(true);
    try {
      await handleRecorderById(
        "",
        contextObject.companyCode,
        values.getRawData()
      ).then(
        (response: InfoStaffTimeRecorderDomain) => {
          setIsConfirm(true);
          const stampStaffData = response.stampStaffList[0];
          setLastStampType(stampStaffData.lastStampType);
          setBusinessCnt(stampStaffData.businessList.length);
          setBusinessList(stampStaffData.businessList);
          setStaffName(stampStaffData.staffName);
          setStaffCode(stampStaffData.staffCode);
          setBarcodeId(stampStaffData.barcodeId);
          setOrgCode(stampStaffData.orgCode);
          setOrgName(stampStaffData.orgName);
          setNewUiRemote(stampStaffData.useRemote);
          setIsRemote(stampStaffData.isRemote);
          contextObject.setRemote(stampStaffData.useRemote);
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.message) {
        errorNotification(error.response.data.message);
        return;
      }
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = "";
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: ConfirmRecorderByBarcodeIdDomain.generateInitial(),
    onSubmit,
  });
  const handleSubmitBarcode = useCallback(() => {
    setIsLoading(true);
    setStampMode(3);
    setServerDateTime(contextObject.resultCurrentTime.serverDateTime);
    formik.setFieldValue("stampMode", stampMode);
    formik.setFieldValue("stampByBelongOrg", contextObject.stampByBelongOrg);
    formik.setFieldValue("orgName", contextObject.stampOrgName);
    formik.setFieldValue("orgCode", contextObject.stampOrgCode);
    formik.setFieldValue("loginOrgName", contextObject.loginOrgName);
    formik.setFieldValue("loginOrgCode", contextObject.loginOrgCode);
    formik.setFieldValue("staffName", contextObject.staffName);
    formik.setFieldValue("barcodeId", contextObject.barcodeId);
    formik.setFieldValue(
      "stampTime",
      String(contextObject.resultCurrentTime.serverDateTime)
    );
    formik.submitForm();
  }, [
    contextObject, formik, staffName, stampMode
  ]);

  const [businessCnt, setBusinessCnt] = useState(0);
  //submit
  const handleRecorder = useCallback(
    (stampType: number, businessId?: string, businessName?: string, flagRemote?: boolean) => {
      if(flagRemote){
        formikConfirm.setFieldValue("isRemote", true);  
      }else{
        formikConfirm.setFieldValue("isRemote", isRemote);  
      }
      setBusinessName(businessName||"");
      setStampType(stampType);
      formikConfirm.setFieldValue("companyCode", contextObject.companyCode);
      formikConfirm.setFieldValue("staffCode", staffCode);
      formikConfirm.setFieldValue("stampMode", stampMode);
      formikConfirm.setFieldValue("stampType", stampType);
      formikConfirm.setFieldValue("orgCode", orgCode);
      formikConfirm.setFieldValue("orgName", orgName);
      formikConfirm.setFieldValue("loginOrgName", orgName);
      formikConfirm.setFieldValue("loginOrgCode", orgCode);
      formikConfirm.setFieldValue("staffName", staffName);
      formikConfirm.setFieldValue("barcodeId", inputBarcodeId);
      formikConfirm.setFieldValue(
        "stampTime",
        String(contextObject.resultCurrentTime.serverDateTime)
      );
      formikConfirm.submitForm();
      setOpenModal(true);
    },
    [ inputBarcodeId, contextObject, staffName, stampMode ]
  );

  const onSubmitConfirm = async (values: RecorderByBarcodeIdDomain) => {
    setIsLoading(true);
    handleConfirmRecorder_v1(contextObject.companyCode, values.getRawData()).then(
      (response: any) => {
        successNotification(
          response.stampResultInfo.replace("<h4>", "").replace("</h4>", "")
        );
        setIsConfirm(false);
        fetchHistoryData();
        formik.submitForm();
        formikConfirm.resetForm();
        setOpenModal(false);
        setIsLoading(false);
        contextObject.setRemote(false);
      }
    ).catch((error: any) => {
      setIsLoading(false);
      setIsConfirm(false);
      setOpenModal(false);
      contextObject.setRemote(false);
      if (error.response.status === 400) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          errorNotification(
            error.response.data.errors.map((x: any) => x.defaultMessage)
          );
        } else if (error.response.data.defaultMessage) {
          errorNotification(error.response.data.defaultMessage);
        } else {
          errorNotification(error.response.data.message);
        }
      } else {
        errorNotification("サーバー側でエラーが発生しました。");
      }
    });
  };

  const formikConfirm = useFormik({
    initialValues: RecorderByBarcodeIdDomain.generateInitial(),
    onSubmit: (values) => {
      onSubmitConfirm(values);
    },
  });

  const handleSubmit = () => {
    if (formik.values.inputBarcodeId) {
      setInputBarcodeId(formik.values.inputBarcodeId);
      handleSubmitBarcode();
    } else {
      errorNotification("打刻IDを入力してください。");
    }
  };

  useEffect(() => {
    if(sessionStorage.getItem('loginUser.barcodeId') !== 'null'){
      formik.setFieldValue('inputBarcodeId', sessionStorage.getItem('loginUser.barcodeId'));
      handleSubmit();
    }else{
      errorNotification('打刻IDは設定されていません、管理者に確認してください。');
    }
  }, [])

  return {
    handleSubmitBarcode,
    formik,
    lastStampType,
    setIsConfirm,
    isConfirm,
    staffName,
    businessCnt,
    formikConfirm,
    handleRecorder,
    stampType,
    setInputBarcodeId,
    contextObject,
    serverDateTime,
    newUiRemote,
    handleSubmit
  };
};
export default useBarcodeRecorder;
