/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
/* eslint-disable no-return-assign */
import { orgSelect, getSalesBudgetYearly } from 'api/storeManagement';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { OptionType } from 'components/atoms/Select';
import { useFormik } from 'formik';
import StoreManagementDomain from 'domain/master/storeManagement/salesBudgetYear';
import {
  updateSalesBudgetYear,
  getFiscalYearFromDate,
} from 'api/salesBudgetYear';
import useToastNotification from 'hooks/useToastNotification';

export const useSalesBudgetYear = (year: any, orgCode: any) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const onSubmit = async (values: StoreManagementDomain) => {
    setConfirmModalOpen(true);
    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
    } else {
      let listData = '';
      const createUser = sessionStorage.getItem('loginUser.staffName') || '';
      // const updateUser = sessionStorage.getItem('loginUser.staffName') || '';
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      const orgCode = sessionStorage.getItem('orgCode') || '';
      // const targetMonth = `${sessionStorage.getItem('dateSelect')}-01-01` || '';
      if (getSalesBudgetMonthly) {
        getSalesBudgetMonthly.map((element: any, i: any) => {
          const _salesBudgetTotal = formik.values.salesBudgetAdvertising[i] + formik.values.salesBudgetFree[i];
          const _costTotal = formik.values.foodCost[i] + formik.values.drinkCost[i];
          const operatingIncomeItem = (_salesBudgetTotal - _costTotal) - (formik.values.employeeLaborCost[i] + formik.values.partLaborCost[i]) - (formik.values.advertisingMediaExpense[i]) - (formik.values.landRent[i]) - (formik.values.otherVariableCosts[i] + formik.values.otherFixedCost[i]);
          listData += `&listSalesBudgetMonthly[${i}].createUser=${createUser}`;
          listData += `&listSalesBudgetMonthly[${i}].updateUser=${createUser}`;
          listData += `&listSalesBudgetMonthly[${i}].id.companyCode=${companyCode}`;
          listData += `&listSalesBudgetMonthly[${i}].id.orgCode=${orgCode}`;
          listData += `&listSalesBudgetMonthly[${i}].id.targetMonth=${element.id.targetMonth}`;
          listData += `&listSalesBudgetMonthly[${i}].salesBudgetTotal=${formik.values.salesBudgetTotal[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].salesBudgetAdvertising=${formik.values.salesBudgetAdvertising[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].salesBudgetFree=${formik.values.salesBudgetFree[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].costTotal=${formik.values.foodCost[i] + formik.values.drinkCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].foodCost=${formik.values.foodCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].drinkCost=${formik.values.drinkCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].grossProfit=${formik.values.grossProfit[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].laborCostTotal=${formik.values.employeeLaborCost[i] + formik.values.partLaborCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].employeeLaborCost=${formik.values.employeeLaborCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].partLaborCost=${formik.values.partLaborCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].advertisingMediaExpense=${formik.values.advertisingMediaExpense[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].landRent=${formik.values.landRent[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].otherExpensesTotal=${formik.values.otherVariableCosts[i] + formik.values.otherFixedCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].otherVariableCosts=${formik.values.otherVariableCosts[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].otherFixedCost=${formik.values.otherFixedCost[i]}`;
          listData += `&listSalesBudgetMonthly[${i}].operatingIncome=${operatingIncomeItem}`;
          listData += `&listSalesBudgetMonthly[${i}].perCustomerPrice=${formik.values.perCustomerPrice[i]}`;
          /*
          listData += `&listSalesBudgetMonthly[${i}].guestCnt=${Math.round(
            formik.values.guestCnt[i],
          )}`;
          */
          listData += `&listSalesBudgetMonthly[${i}].guestCnt=${Math.round(
            formik.values.guestCnt[i],
          )}`;
          getListAdvertising.map((item: any, j: any) => {
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.advertisingMediaMstCode=${formik.values.listAdvertising[j][0]}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.companyCode=${companyCode}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.orgCode=${orgCode}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].id.targetMonth=${element.id.targetMonth}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].createUser=${createUser}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].updateUser=${createUser}`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].advertisingMediaAmount=${
              formik.values.listAdvertising[j][i + 3]
            }`;
            listData += `&listMonthlyAdvertisingMedia[${j}][${i}].advertisingMediaExpense=${
              formik.values.listAdvertisingExpense[j][i + 3]
            }`;
          });
        });
      }

      const response = await updateSalesBudgetYear(companyCode, listData);
      if (!response.errors) {
        setConfirmModalOpen(false);
        successNotification('登録しました。');
      } else if (response && response.data && response.data.errors) {
        const listErr = response.data.errors;
        let stringErr = '';
        listErr.forEach((element: any) => {
          stringErr += `${element.defaultMessage}<br />`;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
      }
      return false;
    }
  };

  const formik = useFormik({
    initialValues: StoreManagementDomain.generateInitial(),
    onSubmit,
  });

  const [getOptionsOrg, setOptionsOrg] = useState<Array<OptionType>>([]);
  const [getListAdvertisingExpense, setListAdvertisingExpense] = useState([]);
  const [getListAdvertising, setListAdvertising] = useState([]);
  const [getSalesBudgetMonthly, setSalesBudgetMonthly] = useState([]);
  const [
    getListAdvertisingApplyPeriod,
    setListAdvertisingApplyPeriod,
  ] = useState([]);
  const [getCheckExist, setCheckExist] = useState(0);
  // Set total
  const [getSalesBudgetTotal, setSalesBudgetTotal] = useState(0);
  const [getSalesBudgetAdvertising, setSalesBudgetAdvertising] = useState(0);

  const [getSalesBudgetFree, setSalesBudgetFree] = useState(0);
  const [getAdvertisingTotal, setAdvertisingTotal] = useState();
  const [getCostTotal, setCostTotal] = useState(0);
  const [getFoodTotal, setFoodTotal] = useState(0);
  const [getDrinkCostTotal, setDrinkCostTotal] = useState(0);
  const [getRateGrossProfit, setRateGrossProfit] = useState(0);
  const [getLaborCostTotals, setLaborCostTotals] = useState(0);
  const [getEmployeeLaborCost, setEmployeeLaborCost] = useState(0);
  const [getPartLaborCost, setPartLaborCost] = useState(0);
  const [
    getAdvertisingMediaExpenseTotal,
    setAdvertisingMediaExpenseTotal,
  ] = useState(0);
  const [getAdvertisingExpenseTotal, setAdvertisingExpenseTotal] = useState();
  const [getLandRent, setLandRent] = useState(0);
  const [getOtherVariableCostsTotal, setOtherVariableCostsTotal] = useState(0);
  const [getOtherFixedCostTotal, setOtherFixedCostTotal] = useState(0);

  const [getGuestCntTotal, setGuestCntTotal] = useState(0);
  const [getTurnoverRateTotal, setTurnoverRateTotal] = useState(0);
  const [getMarginalProfitTotal, setMarginalProfitTotal] = useState(0);
  const [getMarginalProfitRate, setMarginalProfitRate] = useState(0);
  const [getContributionMarginTotal, setContributionMarginTotal] = useState(0);
  const [getContributionMarginRate, setContributionMarginRate] = useState(0);
  const [
    getContributionMarginRateItems,
    setContributionMarginRateItems,
  ] = useState();
  const [getBePointSales, setBePointSales] = useState(0);
  const [getBePointSalesItems, setBePointSalesItems] = useState();
  const [getBePointGuestCnt, setBePointGuestCnt] = useState(0);
  const [
    getBePointGuestCntCalculatorItems,
    setBePointGuestCntCalculatorItems,
  ] = useState();
  const [getTotalPersonRate, setTotalPersonRate] = useState(0);
  const [getEmployeePersonRate, setEmployeePersonRate] = useState(0);
  const [getPartPersonRate, setPartPersonRate] = useState(0);
  const [getLaborShare, setLaborShare] = useState(0);
  const [getCostFL, setCostFL] = useState(0);
  const [getRateFL, setRateFL] = useState(0);
  const [getCostFLA, setCostFLA] = useState(0);
  const [getRateFLA, setRateFLA] = useState(0);
  const [getCostFLAR, setCostFLAR] = useState(0);
  const [getRateFLAR, setRateFLAR] = useState(0);
  const [getCostFLARO, setCostFLARO] = useState(0);
  const [getRateFLARO, setRateFLARO] = useState(0);
  const [getRateFLAROItems, setRateFLAROItems] = useState();
  const [getCostFLAROItems, setCostFLAROItems] = useState();
  const [getRateFLARItems, setRateFLARItems] = useState();
  const [getCostFLARItems, setCostFLARItems] = useState();
  const [getRateFLAItems, setRateFLAItems] = useState();
  const [getRateFLItems, setRateFLItems] = useState();
  const [getPartPersonRateItems, setPartPersonRateItems] = useState();
  const [getEmployeePersonRateItems, setEmployeePersonRateItems] = useState();
  const [getPersonRateItems, setPersonRateItems] = useState();
  const [getMarginalProfitRateItems, setMarginalProfitRateItems] = useState();
  const [getRateGrossProfitItems, setRateGrossProfitItems] = useState();
  const [getLaborShareItems, setLaborShareItems] = useState();
  const [getRateOperatingIncomeItems, setRateOperatingIncomeItems] = useState();
  const [getOperatingIncomeItems, setOperatingIncomeItems] = useState();
  const [getMarginalProfitTotalItems, setMarginalProfitTotalItems] = useState();
  const [getContributionMarginTotalItems, setContributionMarginTotalItems] = useState();
  const [getCostFLItems, setCostFLItems] = useState();
  const [getCostFLAItems, setCostFLAItems] = useState();
  const [getPerCustomerPriceItems, setPerCustomerPriceItems] = useState();
  const [getGuestCntItems, setGuestCntItems] = useState();
  const [getBePointGuestCntTotal, setBePointGuestCntTotal] = useState();
  const [getTurnoverRateTotalItems, setTurnoverRateTotalItems] = useState();

  const [getIsLoading, setIsLoading] = useState(true);
  let salesBudgetTotal = 0;
  let salesBudgetAdvertisingTotal = 0;
  let costTotal = 0;
  let foodCostTotal = 0;
  let foodDrinkCostTotal = 0;
  let grossProfitTotal = 0;
  let laborCostTotals = 0;
  let employeeLaborCostTotal = 0;
  let setPartLaborCostTotal = 0;
  let advertisingMediaExpenseTotal = 0;
  const advertisingMediaExpenseTotalItems: any[] = [];
  let landRentTotal = 0;
  let otherExpensesTotals = 0;
  let otherVariableCostsTotal = 0;
  let otherFixedCostTotals = 0;
  let operatingIncomeTotals = 0;
  let guestCntTotal = 0;
  let numberOfSeatTotal = 0;
  let salesBudgetFree = 0;
  let marginalProfitCalculator = 0;
  let contributionMarginCalculator = 0;
  let bePointSalesCalculator = 0;
  let bePointSalesItem = 0;
  let calculatorCostFL = 0;
  let calculatorCostFLAR = 0;
  let calculatorCostFLARO = 0;
  let bePointGuestCntTotal = 0;

  const contributionMarginRateItems: any[] = [];
  const bePointSalesItems: any[] = [];
  const bePointGuestCntCalculatorItems: any[] = [];
  const perCustomerPriceItems: any[] = [];
  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const arrSalesBudgetTotal: any = [];
  const arrSalesBudgetAdvertising: any = [];
  const arrSalesBudgetFree: any = [];
  const arrCostTotal: any = [];
  const arrFoodCost: any = [];
  const arrDrinkCost: any = [];
  const arrGrossProfit: any = [];
  const arrLaborCostTotal: any = [];
  const arrEmployeeLaborCost: any = [];
  const arrPartLaborCost: any = [];
  const arrAdvertisingMediaExpense: any = [];
  const arrLandRent: any = [];
  const arrOtherExpensesTotal: any = [];
  const arrOtherVariableCosts: any = [];
  const arrOtherFixedCost: any = [];
  const arrOperatingIncome: any = [];
  const arrPerCustomerPrice: any = [];
  const arrGuestCnt: any = [];
  const arrNumberOfSeat: any = [];
  const listAdvertisingTotal: any[] = [];
  // totalAdvertisingTotal
  const totalAdvertisingTotal: any[] = [];
  const totalRateFLAROItems: any[] = [];
  const totalCostFLAROItems: any[] = [];
  const totalrateFLARItems: any[] = [];
  const totalCostFLARItems: any[] = [];
  const totalRateFLAItems: any[] = [];
  const totalRateFLItems: any[] = [];
  const totalPartPersonRateItems: any[] = [];
  const totalEmployeePersonRateItems: any[] = [];
  const totalPersonRateItems: any[] = [];
  const totalMarginalProfitRateItems: any[] = [];
  const rateGrossProfitItems: any[] = [];
  const laborShareItems: any[] = [];
  const rateOperatingIncomeItems: any[] = [];
  const operatingIncomeItems: any[] = [];
  const marginalProfitTotalItems: any[] = [];
  const contributionMarginTotalItems: any[] = [];
  const costFLItems: any[] = [];
  const costFLAItems: any[] = [];
  const guestCntItems:any[] = [];
  const turnoverRateTotalItems:any[] = [];

  // totalAdvertisingExpenseTotal
  const totalAdvertisingExpenseTotal: any[] = [];

  const calculatorTable = (data?: any) => {
    if (formik.values.listAdvertising.length > 0) {
      formik.values.listAdvertising.map((item: any, index: any) => {
        // salesBudgetAdvertisingTotal
        item.map((itemChild: any, indexChild: any) => {
          if (
            indexChild !== 0
            && indexChild !== 1
            && indexChild !== 2
            && itemChild !== undefined
            && itemChild !== null
          ) {
            if (!listAdvertisingTotal[indexChild - 3]) {
              listAdvertisingTotal[indexChild - 3] = 0;
            }
            salesBudgetAdvertisingTotal += parseInt(itemChild);
            listAdvertisingTotal[indexChild - 3] += parseInt(itemChild);
          }
        });
      });
      formik.setFieldValue('salesBudgetAdvertising', listAdvertisingTotal);
      listAdvertisingTotal.map((item: any, index: any) => {
        // salesBudgetTotal
        salesBudgetTotal += item + formik.values.salesBudgetFree[index];
      });
    }

    formik.values.salesBudgetFree.map((item: any, index: any) => {
      salesBudgetFree += parseInt(item);
      // formik.setFieldValue(`guestCnt[${index}]`, numberFormater((listAdvertisingTotal[index] + item) / formik.values.perCustomerPrice[index]));
    });
    formik.values.costTotal.map((item: any) => {
      costTotal += parseInt(item);
    });

    formik.values.foodCost.map((item: any) => {
      foodCostTotal += parseInt(item);
    });

    formik.values.drinkCost.map((item: any, index: any) => {
      // DrinkCost
      foodDrinkCostTotal += parseInt(item);
    });
    grossProfitTotal = foodCostTotal + foodDrinkCostTotal;

    formik.values.employeeLaborCost.map((item: any) => {
      // setEmployeeLaborCost
      employeeLaborCostTotal += parseInt(item);
    });
    formik.values.partLaborCost.map((item: any) => {
      // setPartLaborCost
      setPartLaborCostTotal += parseInt(item);
    });
    // laborCostTotal
    laborCostTotals = employeeLaborCostTotal + setPartLaborCostTotal;

    formik.values.landRent.map((item: any) => {
      // setLandRent
      landRentTotal += parseInt(item);
    });

    formik.values.otherExpensesTotal.map((item: any) => {
      // setOtherExpensesTotal
      otherExpensesTotals += parseInt(item);
    });

    formik.values.otherVariableCosts.map((item: any) => {
      // setOtherVariableCosts
      otherVariableCostsTotal += parseInt(item);
    });

    formik.values.otherFixedCost.map((item: any) => {
      // setOtherFixedCost
      otherFixedCostTotals += parseInt(item);
    });

    formik.values.numberOfSeat.map((item: any) => {
      // setNumberOfSeatTotal
      numberOfSeatTotal += parseInt(item);
    });

    formik.values.listAdvertising.map((item: any, index: any) => {
      let tmpTotal = 0;
      item.map((itemChild: any, indexChild: any) => {
        if (
          indexChild !== 0
          && indexChild !== 1
          && indexChild !== 2
          && itemChild !== undefined
          && itemChild !== null
        ) {
          tmpTotal += Number(itemChild);
        }
        return tmpTotal;
      });
      totalAdvertisingTotal.push(tmpTotal);
    });

    formik.values.listAdvertisingExpense.map((item: any, index: any) => {
      let tmpTotalExpense = 0;
      item.map((itemChild: any, indexChild: any) => {
        if (
          indexChild !== 0
          && indexChild !== 1
          && indexChild !== 2
          && itemChild !== undefined
          && itemChild !== null
        ) {
          tmpTotalExpense += Number(itemChild);

          if (!advertisingMediaExpenseTotalItems[indexChild - 3]) {
            advertisingMediaExpenseTotalItems[indexChild - 3] = 0;
          }
          advertisingMediaExpenseTotalItems[indexChild - 3] += parseInt(
            itemChild,
          );
        }
      });

      formik.setFieldValue(
        'advertisingMediaExpense',
        advertisingMediaExpenseTotalItems,
      );

      totalAdvertisingExpenseTotal.push(tmpTotalExpense);
    });
    // console.log(formik.values.numberOfSeat);
    let _guestCntTotal = 0;
    advertisingMediaExpenseTotal = 0;
    formik.values.guestCnt.map((item: any, index: any) => {
      const _salesBudgetTotal = formik.values.salesBudgetAdvertising[index] + formik.values.salesBudgetFree[index];
      const _costTotal = formik.values.foodCost[index] + formik.values.drinkCost[index];
      const _partLaborCost = formik.values.partLaborCost[index];
      const _otherVariableCosts = formik.values.otherVariableCosts[index];
      advertisingMediaExpenseTotal += formik.values.advertisingMediaExpense[index];


      formik.setFieldValue(`salesBudgetTotal[${index}]`, _salesBudgetTotal);
      formik.setFieldValue(
        `grossProfit[${index}]`,
        _salesBudgetTotal - _costTotal,
      );
      const laborCostTotal = formik.values.employeeLaborCost[index] + formik.values.partLaborCost[index];
      const laborShareItem = (formik.values.employeeLaborCost[index]
        + formik.values.partLaborCost[index])
        / (_salesBudgetTotal - _costTotal);
      laborShareItems.push(
        laborShareItem === -Infinity || laborShareItem === Infinity
          ? 0
          : laborShareItem,
      );

      const rateGrossProfitItem = (_salesBudgetTotal - _costTotal) / _salesBudgetTotal;
      rateGrossProfitItems.push(!rateGrossProfitItem ? 0 : rateGrossProfitItem);

      // setMarginalProfitRateItems
      const marginalProfitRateItem = (_salesBudgetTotal
        - (_costTotal
          + formik.values.partLaborCost[index]
          + formik.values.otherVariableCosts[index]))
        / _salesBudgetTotal;
      totalMarginalProfitRateItems.push(
        marginalProfitRateItem === Infinity ? 0 : marginalProfitRateItem,
      );

      // setPerCustomerPrice
      const perCustomerPrices = parseInt(_salesBudgetTotal) / parseInt(item);
      console.log(perCustomerPrices);

      perCustomerPriceItems.push(parseInt(formik.values.perCustomerPrice[index]));

      const guestCntItem = _salesBudgetTotal / parseInt(formik.values.perCustomerPrice[index]);
      _guestCntTotal += numberFormater(Math.round(guestCntItem));
      guestCntItems.push(!guestCntItem || guestCntItem === Infinity ? 0 : formatter.format(guestCntItem));

      formik.setFieldValue(
        `guestCnt[${index}]`,
        guestCntItem,
      );

      const turnoverRateTotalItem = Math.round(guestCntItem) / formik.values.numberOfSeat[index];
      turnoverRateTotalItems.push(turnoverRateTotalItem);

      // setContributionMarginRateItems
      const marginalProfitCalculatorItem = _salesBudgetTotal
        - (_costTotal
          + formik.values.partLaborCost[index]
          + formik.values.otherVariableCosts[index]);

      const contributionMarginRateItem = (marginalProfitCalculatorItem
        - (formik.values.employeeLaborCost[index]
          + formik.values.advertisingMediaExpense[index]
          + formik.values.landRent[index]
          + formik.values.otherFixedCost[index]))
        / _salesBudgetTotal;

      contributionMarginRateItems.push(
        contributionMarginRateItem === Infinity ? 0 : contributionMarginRateItem,
      );
      // setBePointSalesItems
      bePointSalesItem = (formik.values.employeeLaborCost[index]
        + formik.values.advertisingMediaExpense[index]
        + formik.values.landRent[index]
        + formik.values.otherFixedCost[index])
        / (marginalProfitCalculatorItem / _salesBudgetTotal);
      bePointSalesItems.push(bePointSalesItem);

      // setBePointSalesCalculatorItem
      const bePointGuestCntCalculatorItem = bePointSalesItem / parseInt(formik.values.perCustomerPrice[index]);
      bePointGuestCntCalculatorItems.push(bePointGuestCntCalculatorItem);
      bePointGuestCntTotal += bePointGuestCntCalculatorItem;

      // const totalPersonRateItem = formik.values.laborCostTotal[index] / _salesBudgetTotal;
      const totalPersonRateItem = laborCostTotal / _salesBudgetTotal;
      totalPersonRateItems.push(
        totalPersonRateItem === Infinity ? 0 : totalPersonRateItem,
      );

      const totalEmployeePersonRateItem = formik.values.employeeLaborCost[index] / _salesBudgetTotal;
      totalEmployeePersonRateItems.push(
        totalEmployeePersonRateItem === Infinity
          ? 0
          : totalEmployeePersonRateItem,
      );

      const totalPartPersonRateItem = formik.values.partLaborCost[index] / _salesBudgetTotal;
      totalPartPersonRateItems.push(
        totalPartPersonRateItem === Infinity ? 0 : totalPartPersonRateItem,
      );

      const totalRateFLItem = (laborCostTotal + _costTotal) / _salesBudgetTotal;
      totalRateFLItems.push(!totalRateFLItem ? 0 : totalRateFLItem);

      const costFLA = laborCostTotal + _costTotal + formik.values.advertisingMediaExpense[index];

      const rateFLAItem = costFLA / _salesBudgetTotal;
      totalRateFLAItems.push(!rateFLAItem ? 0 : rateFLAItem);

      const costFLAItem = laborCostTotal + _costTotal + formik.values.advertisingMediaExpense[index];
      costFLAItems.push(!costFLAItem ? 0 : costFLAItem);

      const costFLARItem = costFLA + formik.values.landRent[index];
      totalCostFLARItems.push(!costFLARItem ? 0 : costFLARItem);

      const rateFLARItem = costFLARItem / _salesBudgetTotal;
      totalrateFLARItems.push(!rateFLARItem ? 0 : rateFLARItem);
      const otherExpensesTotalCalculator = formik.values.otherVariableCosts[index] + formik.values.otherFixedCost[index];
      const totalCostFLAROItem = costFLA + formik.values.landRent[index] + otherExpensesTotalCalculator;
      totalCostFLAROItems.push(totalCostFLAROItem);

      const totalRateFLAROItem = totalCostFLAROItem / _salesBudgetTotal;
      totalRateFLAROItems.push(!totalRateFLAROItem ? 0 : totalRateFLAROItem);

      const operatingIncomeCalculator = _salesBudgetTotal
        - _costTotal
        - formik.values.laborCostTotal[index]
        - formik.values.advertisingMediaExpense[index]
        - formik.values.landRent[index]
        - (formik.values.otherVariableCosts[index]
          + formik.values.otherFixedCost[index]);

      formik.setFieldValue(
        `operatingIncome[${index}]`,
        operatingIncomeCalculator,
      );

      const grossProfitTotalCalculator = parseInt(_salesBudgetTotal) - parseInt(_costTotal);

      const rateOperatingIncomeItem = (grossProfitTotalCalculator - laborCostTotal - formik.values.advertisingMediaExpense[index] - formik.values.landRent[index] - otherExpensesTotalCalculator) / grossProfitTotalCalculator;
      rateOperatingIncomeItems.push(!rateOperatingIncomeItem ? 0 : rateOperatingIncomeItem);

      const operatingIncomeItem = grossProfitTotalCalculator - laborCostTotal - (formik.values.advertisingMediaExpense[index]) - (formik.values.landRent[index]) - otherExpensesTotalCalculator;
      operatingIncomeItems.push(!operatingIncomeItem ? 0 : operatingIncomeItem);

      const marginalProfitTotalItem = parseInt(_salesBudgetTotal) - (parseInt(_costTotal) + parseInt(formik.values.partLaborCost[index]) + parseInt(formik.values.otherVariableCosts[index]));
      marginalProfitTotalItems.push(!marginalProfitTotalItem ? 0 : marginalProfitTotalItem);
      marginalProfitCalculator += marginalProfitTotalItem;

      const contributionMarginTotalItem = marginalProfitTotalItem - (formik.values.employeeLaborCost[index] + parseInt(formik.values.advertisingMediaExpense[index]) + parseInt(formik.values.landRent[index]) + parseInt(formik.values.otherFixedCost[index]));
      contributionMarginTotalItems.push(!contributionMarginTotalItem ? 0 : contributionMarginTotalItem);

      const costFLItem = laborCostTotal + _costTotal;
      costFLItems.push(!costFLItem ? 0 : costFLItem);

      calculatorCostFL += costFLItem;
      const calculatorCostFLARCalculator = laborCostTotal + _costTotal + formik.values.advertisingMediaExpense[index] + formik.values.landRent[index];
      calculatorCostFLAR += calculatorCostFLARCalculator;
      calculatorCostFLARO += calculatorCostFLARCalculator + otherExpensesTotalCalculator;
    });
    guestCntTotal = (salesBudgetAdvertisingTotal + salesBudgetFree) / ((salesBudgetAdvertisingTotal + salesBudgetFree) / _guestCntTotal);
    calculatorBudgetMonthly();
    return false;
  };


  const numberFormater = (num: any) => {
    if (typeof num === 'undefined' || isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };

  const calculatorBudgetMonthly = () => {
    // setSalesBudgetAdvertising
    setSalesBudgetAdvertising(salesBudgetAdvertisingTotal);

    setSalesBudgetTotal(salesBudgetAdvertisingTotal + salesBudgetFree);

    setSalesBudgetFree(salesBudgetFree);

    // setCostTotal
    setCostTotal(costTotal);

    // setFoodTotal
    setFoodTotal(foodCostTotal);

    // setDrinkCostTotal
    setDrinkCostTotal(foodDrinkCostTotal);

    // setRateGrossProfit

    setRateGrossProfit((salesBudgetTotal - (foodCostTotal + foodDrinkCostTotal)) / salesBudgetTotal);

    // setLaborCostTotals
    setLaborCostTotals(laborCostTotals);

    // setEmployeeLaborCostTotal
    setEmployeeLaborCost(employeeLaborCostTotal);

    // setPartLaborCost
    setPartLaborCost(setPartLaborCostTotal);

    // setAdvertisingMediaExpense
    setAdvertisingMediaExpenseTotal(advertisingMediaExpenseTotal);

    // setLandRent
    setLandRent(landRentTotal);

    // setOtherVariableCostsTotal
    setOtherVariableCostsTotal(otherVariableCostsTotal);

    // setOtherFixedCostTotal
    setOtherFixedCostTotal(otherFixedCostTotals);

    // setGuestCnt
    setGuestCntTotal(guestCntTotal);

    // setTurnoverRateTotal
    setTurnoverRateTotal(guestCntTotal / numberOfSeatTotal);

    setMarginalProfitTotal(marginalProfitCalculator);

    // setMarginalProfitRate
    setMarginalProfitRate(marginalProfitCalculator / salesBudgetTotal);

    // setContributionMarginTotal
    contributionMarginCalculator = marginalProfitCalculator
      - (employeeLaborCostTotal
        + advertisingMediaExpenseTotal
        + landRentTotal
        + otherFixedCostTotals);
    setContributionMarginTotal(contributionMarginCalculator);

    // setContributionMarginRate
    setContributionMarginRate(contributionMarginCalculator / salesBudgetTotal);

    // setBePointSales
    bePointSalesCalculator = (employeeLaborCostTotal
      + advertisingMediaExpenseTotal
      + landRentTotal
      + otherFixedCostTotals)
      / (marginalProfitCalculator / salesBudgetTotal);
    setBePointSales(bePointSalesCalculator);
    setBePointSalesItems(bePointSalesItems);

    // setbePointGuestCnt
    const calculator = formatter.format((salesBudgetAdvertisingTotal + salesBudgetFree) / guestCntTotal);

    setBePointGuestCnt(getBePointGuestCnt / parseFloat(calculator.replace(/,/g, '')));

    setBePointGuestCntCalculatorItems(bePointGuestCntCalculatorItems);

    // setTotalPersonRate
    setTotalPersonRate(laborCostTotals / salesBudgetTotal);

    // setEmployeePersonRate
    setEmployeePersonRate(employeeLaborCostTotal / salesBudgetTotal);

    // setPartPersonRate
    setPartPersonRate(setPartLaborCostTotal / salesBudgetTotal);

    // setLaborShare
    const laborShareTotal = (employeeLaborCostTotal + setPartLaborCostTotal)
      / (salesBudgetAdvertisingTotal
        + salesBudgetFree
        - (foodCostTotal + foodDrinkCostTotal));
    setLaborShare(laborShareTotal);

    // setCostFL
    setCostFL(calculatorCostFL);

    // setRateFL
    setRateFL(calculatorCostFL / salesBudgetTotal);

    // setCostFLA
    const calculatorCostFLA = calculatorCostFL + advertisingMediaExpenseTotal;
    setCostFLA(calculatorCostFLA);

    // setRateFLA
    setRateFLA(calculatorCostFLA / salesBudgetTotal);

    // setCostFLAR
    setCostFLAR(calculatorCostFLAR);

    // setRateFLAR
    setRateFLAR(calculatorCostFLAR / salesBudgetTotal);

    // setCostFLARO
    setCostFLARO(calculatorCostFLARO);

    // setRateFLARO
    setRateFLARO(calculatorCostFLARO / salesBudgetTotal);

    if (laborShareItems.length) {
      setLaborShareItems(laborShareItems);
    }

    if (rateGrossProfitItems.length) {
      setRateGrossProfitItems(rateGrossProfitItems);
    }

    if (totalMarginalProfitRateItems.length) {
      setMarginalProfitRateItems(totalMarginalProfitRateItems);
    }

    if (contributionMarginRateItems.length) {
      setContributionMarginRateItems(contributionMarginRateItems);
    }

    if (totalPersonRateItems.length) {
      setPersonRateItems(totalPersonRateItems);
    }

    if (totalEmployeePersonRateItems.length) {
      setEmployeePersonRateItems(totalEmployeePersonRateItems);
    }

    if (totalPartPersonRateItems.length) {
      setPartPersonRateItems(totalPartPersonRateItems);
    }

    if (totalRateFLItems.length) {
      setRateFLItems(totalRateFLItems);
    }

    if (totalRateFLAItems.length) {
      setRateFLAItems(totalRateFLAItems);
    }

    if (totalrateFLARItems.length) {
      setCostFLARItems(totalCostFLARItems);
    }

    if (totalrateFLARItems.length) {
      setRateFLARItems(totalrateFLARItems);
    }

    if (totalCostFLAROItems.length) {
      setCostFLAROItems(totalCostFLAROItems);
    }

    if (totalRateFLAROItems.length) {
      setRateFLAROItems(totalRateFLAROItems);
    }

    if (totalAdvertisingExpenseTotal.length > 0) {
      setAdvertisingExpenseTotal(totalAdvertisingExpenseTotal);
    }

    if (totalAdvertisingTotal.length > 0) {
      setAdvertisingTotal(totalAdvertisingTotal);
    }

    setRateOperatingIncomeItems(rateOperatingIncomeItems);
    setOperatingIncomeItems(operatingIncomeItems);
    setMarginalProfitTotalItems(marginalProfitTotalItems);
    setContributionMarginTotalItems(contributionMarginTotalItems);
    setCostFLItems(costFLItems);
    setCostFLAItems(costFLAItems);
    setPerCustomerPriceItems(perCustomerPriceItems);
    setGuestCntItems(guestCntItems);
    setBePointGuestCntTotal(bePointGuestCntTotal / ((salesBudgetAdvertisingTotal + salesBudgetFree) / guestCntTotal));
    setTurnoverRateTotalItems(turnoverRateTotalItems);
  };

  useEffect(() => {
    const dayFrom = moment(new Date(`${year}`))
      .startOf('year')
      .format('YYYY-MM-DD');
    const dateTo = moment(new Date(`${year}`))
      .endOf('year')
      .format('YYYY-MM-DD');
    orgSelect(dayFrom, dateTo).then((response) => {
      const orgs = response.map((res) => ({
        value: res.orgCode,
        label: `${res.orgCode} ${res.orgName}`,
      }));
      orgs.unshift({
        value: '',
        label: '-',
      });
      setOptionsOrg(orgs);
    });

    setIsLoading(true);
    getSalesBudgetYearly(
      orgCode || sessionStorage.getItem('org_code') || 'all',
      `${year}/01/01`,
    ).then((response: any) => {
      setCheckExist(response.checkExist);
      // get List
      setListAdvertisingExpense(response.listAdvertisingExpense);
      setListAdvertising(response.listAdvertising);
      setSalesBudgetMonthly(response.salesBudgetMonthly);
      setListAdvertisingApplyPeriod(response.listAdvertisingApplyPeriod);

      // salesBudgetMonthly
      formik.setFieldValue('listAdvertising', []);
      response.listAdvertising.map((item: any, index: any) => {
        formik.setFieldValue(`listAdvertising[${index}]`, item);
      });

      formik.setFieldValue('listAdvertisingExpense', []);
      response.listAdvertisingExpense.map((item: any, index: any) => {
        formik.setFieldValue(`listAdvertisingExpense[${index}]`, item);
      });

      response.salesBudgetMonthly.map((item: any, index: any) => {
        arrSalesBudgetTotal.push(item.salesBudgetTotal);
        arrSalesBudgetAdvertising.push(item.salesBudgetAdvertising);
        arrSalesBudgetFree.push(item.salesBudgetFree);
        arrCostTotal.push(item.costTotal);
        arrFoodCost.push(item.foodCost);
        arrDrinkCost.push(item.drinkCost);
        arrGrossProfit.push(item.grossProfit);
        arrLaborCostTotal.push(item.partLaborCost + item.employeeLaborCost);
        arrEmployeeLaborCost.push(item.employeeLaborCost);
        arrPartLaborCost.push(item.partLaborCost);
        arrAdvertisingMediaExpense.push(item.advertisingMediaExpense);
        arrLandRent.push(item.landRent);
        arrOtherExpensesTotal.push(item.otherExpensesTotal);
        arrOtherVariableCosts.push(item.otherVariableCosts);
        arrOtherFixedCost.push(item.otherFixedCost);
        arrOperatingIncome.push(item.operatingIncome);
        arrPerCustomerPrice.push(item.perCustomerPrice);
        arrGuestCnt.push(item.guestCnt);
        arrNumberOfSeat.push(item.numberOfSeat);

        // salesBudgetTotal
        salesBudgetTotal += item.salesBudgetTotal;

        // salesBudgetAdvertisingTotal
        salesBudgetAdvertisingTotal += parseInt(item.salesBudgetAdvertising);

        // costTotal
        costTotal += item.costTotal;

        // foodCost
        foodCostTotal += item.foodCost;

        // DrinkCost
        foodDrinkCostTotal += item.drinkCost;

        // grossProfit
        grossProfitTotal += parseInt(item.salesBudgetTotal) - parseInt(item.costTotal);

        // laborCostTotal
        laborCostTotals += item.partLaborCost + item.employeeLaborCost;

        // setEmployeeLaborCost
        employeeLaborCostTotal += item.employeeLaborCost;

        // setPartLaborCost
        setPartLaborCostTotal += item.partLaborCost;

        // salesBudgetFree
        salesBudgetFree += item.salesBudgetFree;

        // setAdvertisingMediaExpense
        advertisingMediaExpenseTotal += item.advertisingMediaExpense;

        // setLandRent
        landRentTotal += item.landRent;

        // setOtherExpensesTotal
        otherExpensesTotals += item.otherExpensesTotal;

        // setOtherVariableCosts
        otherVariableCostsTotal += item.otherVariableCosts;

        // setOtherFixedCost
        otherFixedCostTotals += item.otherFixedCost;

        // setOperatingIncome
        operatingIncomeTotals += item.operatingIncome;

        // setGuestCntTotal
        guestCntTotal += item.guestCnt;

        // setNumberOfSeatTotal
        numberOfSeatTotal += item.numberOfSeat;

        const _salesBudgetTotal = item.salesBudgetAdvertising + item.salesBudgetFree;

        const _costTotal = item.foodCost + item.drinkCost;

        const laborShareItem = (item.employeeLaborCost + item.partLaborCost)
          / (_salesBudgetTotal - _costTotal);
        laborShareItems.push(!laborShareItem ? 0 : laborShareItem);

        const rateGrossProfitItem = (_salesBudgetTotal - _costTotal) / _salesBudgetTotal;
        rateGrossProfitItems.push(!rateGrossProfitItem ? 0 : rateGrossProfitItem);
        // setMarginalProfitRateItems
        const marginalProfitRateItem = (_salesBudgetTotal
          - (_costTotal + item.partLaborCost + item.otherVariableCosts))
          / _salesBudgetTotal;
        totalMarginalProfitRateItems.push(
          !marginalProfitRateItem ? 0 : marginalProfitRateItem,
        );


        // setPerCustomerPrice
        const perCustomerPrices = parseInt(item.salesBudgetTotal) / parseInt(item.guestCnt);

        perCustomerPriceItems.push(item.perCustomerPrice);

        const guestCntItem = _salesBudgetTotal / perCustomerPrices;
        guestCntItems.push(!guestCntItem ? 0 : guestCntItem);

        const turnoverRateTotalItem = guestCntItem / item.numberOfSeat;
        turnoverRateTotalItems.push(turnoverRateTotalItem);
        // setContributionMarginRateItems
        const marginalProfitCalculatorItem = _salesBudgetTotal
          - (_costTotal + item.partLaborCost + item.otherVariableCosts);
        const contributionMarginRateItem = (marginalProfitCalculatorItem
          - (item.employeeLaborCost
            + item.advertisingMediaExpense
            + item.landRent
            + item.otherFixedCost))
          / _salesBudgetTotal;
        contributionMarginRateItems.push(
          !contributionMarginRateItem ? 0 : contributionMarginRateItem,
        );

        // setBePointSalesItems
        const bePointSalesItem = (item.employeeLaborCost
          + item.advertisingMediaExpense
          + item.landRent
          + item.otherFixedCost)
          / (marginalProfitCalculatorItem / _salesBudgetTotal);
        bePointSalesItems.push(bePointSalesItem);

        // setBePointSalesCalculatorItem
        const bePointGuestCntCalculatorItem = bePointSalesItem / perCustomerPrices;
        bePointGuestCntCalculatorItems.push(bePointGuestCntCalculatorItem);

        const laborCostTotal = item.employeeLaborCost + item.partLaborCost;

        const totalPersonRateItem = laborCostTotal / _salesBudgetTotal;
        totalPersonRateItems.push(!totalPersonRateItem ? 0 : totalPersonRateItem);

        const totalEmployeePersonRateItem = item.employeeLaborCost / _salesBudgetTotal;
        totalEmployeePersonRateItems.push(
          !totalEmployeePersonRateItem ? 0 : totalEmployeePersonRateItem,
        );

        const totalPartPersonRateItem = item.partLaborCost / _salesBudgetTotal;
        totalPartPersonRateItems.push(
          !totalPartPersonRateItem ? 0 : totalPartPersonRateItem,
        );

        const totalRateFLItem = (laborCostTotal + item.costTotal) / _salesBudgetTotal;
        totalRateFLItems.push(!totalRateFLItem ? 0 : totalRateFLItem);

        const costFLA = laborCostTotal + _costTotal + item.advertisingMediaExpense;

        const rateFLAItem = costFLA / _salesBudgetTotal;
        totalRateFLAItems.push(!rateFLAItem ? 0 : rateFLAItem);

        const costFLAItem = laborCostTotal + _costTotal + item.advertisingMediaExpense;
        costFLAItems.push(!costFLAItem ? 0 : costFLAItem);

        const costFLARItem = costFLA + item.landRent;
        totalCostFLARItems.push(!costFLARItem ? 0 : costFLARItem);

        const costFLItem = laborCostTotal + _costTotal;
        costFLItems.push(!costFLItem ? 0 : costFLItem);

        const rateFLARItem = costFLARItem / _salesBudgetTotal;
        totalrateFLARItems.push(!rateFLARItem ? 0 : rateFLARItem);

        const otherExpensesTotalCalculator = item.otherVariableCosts + item.otherFixedCost;
        const totalCostFLAROItem = costFLA + item.landRent + otherExpensesTotalCalculator;
        totalCostFLAROItems.push(totalCostFLAROItem);

        const totalRateFLAROItem = totalCostFLAROItem / _salesBudgetTotal;
        totalRateFLAROItems.push(!totalRateFLAROItem ? 0 : totalRateFLAROItem);

        const grossProfitTotalCalculator = parseInt(_salesBudgetTotal) - parseInt(_costTotal);

        const rateOperatingIncomeItem = (grossProfitTotalCalculator - laborCostTotal - item.advertisingMediaExpense - item.landRent - otherExpensesTotalCalculator) / grossProfitTotalCalculator;
        rateOperatingIncomeItems.push(!rateOperatingIncomeItem ? 0 : rateOperatingIncomeItem);

        const operatingIncomeItem = grossProfitTotalCalculator - laborCostTotal - (item.advertisingMediaExpense) - (item.landRent) - otherExpensesTotalCalculator;
        operatingIncomeItems.push(!operatingIncomeItem ? 0 : operatingIncomeItem);

        const marginalProfitTotalItem = parseInt(_salesBudgetTotal) - (parseInt(_costTotal) + parseInt(item.partLaborCost) + parseInt(item.otherVariableCosts));

        marginalProfitTotalItems.push(!marginalProfitTotalItem ? 0 : marginalProfitTotalItem);
        marginalProfitCalculator += marginalProfitTotalItem;

        const contributionMarginTotalItem = marginalProfitTotalItem - (item.employeeLaborCost + parseInt(item.advertisingMediaExpense) + parseInt(item.landRent) + parseInt(item.otherFixedCost));
        contributionMarginTotalItems.push(!contributionMarginTotalItem ? 0 : contributionMarginTotalItem);

        calculatorCostFL += costFLItem;
        const calculatorCostFLARCalculator = laborCostTotal + _costTotal + item.advertisingMediaExpense + item.landRent;
        calculatorCostFLAR += calculatorCostFLARCalculator;
        calculatorCostFLARO += calculatorCostFLARCalculator + otherExpensesTotalCalculator;

        return true;
      });

      formik.setFieldValue('salesBudgetTotal', arrSalesBudgetTotal);
      formik.setFieldValue('salesBudgetAdvertising', arrSalesBudgetAdvertising);
      setListAdvertisingExpense(response.listAdvertisingExpense);

      formik.setFieldValue('salesBudgetFree', arrSalesBudgetFree);
      formik.setFieldValue('costTotal', arrCostTotal);
      formik.setFieldValue('foodCost', arrFoodCost);
      formik.setFieldValue('drinkCost', arrDrinkCost);
      formik.setFieldValue('grossProfit', arrGrossProfit);
      formik.setFieldValue('laborCostTotal', arrLaborCostTotal);
      formik.setFieldValue('employeeLaborCost', arrEmployeeLaborCost);
      formik.setFieldValue('partLaborCost', arrPartLaborCost);
      formik.setFieldValue('advertisingMediaExpense', arrAdvertisingMediaExpense);
      formik.setFieldValue('landRent', arrLandRent);
      formik.setFieldValue('otherExpensesTotal', arrOtherExpensesTotal);
      formik.setFieldValue('otherVariableCosts', arrOtherVariableCosts);
      formik.setFieldValue('otherFixedCost', arrOtherFixedCost);
      formik.setFieldValue('operatingIncome', arrOperatingIncome);
      formik.setFieldValue('perCustomerPrice', arrPerCustomerPrice);
      formik.setFieldValue('guestCnt', arrGuestCnt);
      formik.setFieldValue('numberOfSeat', arrNumberOfSeat);
      // calculator total budgetMonthly
      calculatorBudgetMonthly();

      response.listAdvertising.map((item: any, index: any) => {
        let tmpTotal = 0;
        item.map((itemChild: any, indexChild: any) => {
          if (
            indexChild !== 0
            && indexChild !== 1
            && indexChild !== 2
            && itemChild !== undefined
            && itemChild !== null
          ) {
            tmpTotal += Number(itemChild);
          }
          return tmpTotal;
        });
        totalAdvertisingTotal.push(tmpTotal);
        return totalAdvertisingTotal;
      });

      response.listAdvertisingExpense.map((item: any, index: any) => {
        let tmpTotal = 0;
        item.map((itemChild: any, indexChild: any) => {
          if (
            indexChild !== 0
            && indexChild !== 1
            && indexChild !== 2
            && itemChild !== undefined
            && itemChild !== null
          ) {
            tmpTotal += Number(itemChild);
          }
          return tmpTotal;
        });
        totalAdvertisingExpenseTotal.push(tmpTotal);
        return totalAdvertisingExpenseTotal;
      });

      if (totalAdvertisingExpenseTotal.length > 0) {
        setAdvertisingExpenseTotal(totalAdvertisingExpenseTotal);
      }

      if (totalAdvertisingTotal.length > 0) {
        setAdvertisingTotal(totalAdvertisingTotal);
      }

      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, orgCode]);
  return {
    getOptionsOrg,
    formik,
    getListAdvertisingExpense,
    getListAdvertising,
    getSalesBudgetMonthly,
    getCheckExist,
    getListAdvertisingApplyPeriod,
    getTotal: {
      getSalesBudgetTotal,
      getSalesBudgetAdvertising,
      getAdvertisingTotal,
      getCostTotal,
      getFoodTotal,
      getDrinkCostTotal,
      getRateGrossProfit,
      getLaborCostTotals,
      getEmployeeLaborCost,
      getPartLaborCost,
      getAdvertisingMediaExpenseTotal,
      getAdvertisingExpenseTotal,
      getLandRent,
      getOtherVariableCostsTotal,
      getOtherFixedCostTotal,
      getGuestCntTotal,
      getTurnoverRateTotal,
      getMarginalProfitTotal,
      getMarginalProfitRate,
      getContributionMarginTotal,
      getContributionMarginRate,
      getContributionMarginRateItems,
      getBePointSales,
      getBePointSalesItems,
      getBePointGuestCnt,
      getBePointGuestCntCalculatorItems,
      getTotalPersonRate,
      getEmployeePersonRate,
      getPartPersonRate,
      getLaborShare,
      getCostFL,
      getRateFL,
      getCostFLA,
      getRateFLA,
      getCostFLAR,
      getRateFLAR,
      getCostFLARO,
      getRateFLARO,
      getSalesBudgetFree,
      getRateFLAROItems,
      getCostFLAROItems,
      getRateFLARItems,
      getCostFLARItems,
      getRateFLAItems,
      getRateFLItems,
      getPartPersonRateItems,
      getEmployeePersonRateItems,
      getPersonRateItems,
      getMarginalProfitRateItems,
      getRateGrossProfitItems,
      getLaborShareItems,
      getRateOperatingIncomeItems,
      getOperatingIncomeItems,
      getMarginalProfitTotalItems,
      getContributionMarginTotalItems,
      getCostFLItems,
      getCostFLAItems,
      getPerCustomerPriceItems,
      getGuestCntItems,
      getBePointGuestCntTotal,
      getTurnoverRateTotalItems,
    },
    getIsLoading,
    setIsLoading,
    calculatorTable,
    confirmModalOpen,
    closeConfirmModal,
  };
};

export const useGetFiscalYear = () => {
  const [getFiscalYear, setFiscalYear] = useState();
  useEffect(() => {
    getFiscalYearFromDate().then((response) => {
      setFiscalYear(response.id.fiscalYear);
    });
  }, []);
  return { getFiscalYear };
};

export const downloadOriginalFile = async (
  response: any,
  downloadFileName: string,
) => {
  const file = new Blob([response], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(file, downloadFileName);
};
// eslint-disable-next-line camelcase
export const setFileName = (
  orgCode: string,
  dateSelect: any,
  procDateStr: any,
) => {
  const fileName = `${
    orgCode === ''
      ? '全組織'
      : orgCode
        .trim()
        .replace(/\\/g, '￥')
        .replace(/\//g, '／')
        .replace(/:/g, '：')
        .replace(/\*/g, '＊')
        .replace(/\?/g, '？')
        .replace(/\/"/g, '”')
        .replace(/</g, '＜')
        .replace(/>/g, '＞')
        .replace(/\|/g, '｜')
  }_年間月次予算${dateSelect}_${procDateStr}${
    orgCode === '' ? '.zip' : '.xlsx'
  }`;
  return fileName;
};

export const eventScroll = (e: { target: any }) => {
  const { target } = e;
  const contentRight = document.getElementById('contentRight');
  const headRight = document.getElementById('headRight');
  const scrollBottom = document.getElementById('scrollBottom');

  if (contentRight) {
    contentRight.scrollLeft = target.scrollLeft;
  }

  if (headRight) {
    headRight.scrollLeft = target.scrollLeft;
  }

  if (scrollBottom) {
    scrollBottom.scrollLeft = target.scrollLeft;
  }
};

export default useSalesBudgetYear;
