import moment from 'moment'
export interface AttendSetting36Agreement {
  companyCode: string;
  createUser: string;
  createDate: Date;
  updateUser: string;
  updateDate: Date;
  agreementsId: string;
  targetYear: number;
  applyStartMonth: Date;
  monthOverConditions: number;
  monthOverConditionsHour: number;
  monthlyOvertimeHourList: Array<number>;
  yearOvertimeHourList: Array<number>;
  averageOvertimeHourList: Array<number>;
  monthlyOverAndHolidayTimeHourList: Array<number>;
  monthlyOverConditionsCountList: Array<number>;
  agreementsCode: string;
  agreementsName: string;
  orgCode: string;
  underFlag: number;
  startMonth: number;
  orgName: string;
}

export default class AttendSetting36AgreementDomain {
  constructor(private rawData: AttendSetting36Agreement) {
    this.rawData.companyCode = sessionStorage.getItem('loginUser.companyCode') || "";
    this.rawData.createUser = sessionStorage.getItem('loginUser.staffCode') || "";
    this.rawData.updateUser = sessionStorage.getItem('loginUser.staffCode') || "";
    
    // do nothing
    if(!this.rawData.monthlyOvertimeHourList){
      this.rawData.monthlyOvertimeHourList = [0];          
    }
    if(!this.rawData.yearOvertimeHourList){
      this.rawData.yearOvertimeHourList = [0];          
    }
    if(!this.rawData.averageOvertimeHourList){
      this.rawData.averageOvertimeHourList = [0];          
    }
    if(!this.rawData.monthlyOverAndHolidayTimeHourList){
      this.rawData.monthlyOverAndHolidayTimeHourList = [0];          
    }
    if(!this.rawData.monthlyOverConditionsCountList){
      this.rawData.monthlyOverConditionsCountList = [0];          
    }
    if(!this.rawData.monthOverConditions){
      this.rawData.monthOverConditions = 0;          
    }
    if(!this.rawData.monthOverConditionsHour){
      this.rawData.monthOverConditionsHour = 0;          
    }
  }

  static generateInitial(): AttendSetting36AgreementDomain {
    return new AttendSetting36AgreementDomain({
      companyCode: '',
      createUser: '',
      createDate: new Date(),
      updateUser: '',
      updateDate: new Date(),
      agreementsId: '',
      targetYear: new Date().getFullYear(),
      applyStartMonth: new Date(),
      monthOverConditions: 0,
      monthOverConditionsHour: 0,
      monthlyOvertimeHourList: [0],
      yearOvertimeHourList: [0],
      averageOvertimeHourList: [0],
      monthlyOverAndHolidayTimeHourList: [0],
      monthlyOverConditionsCountList: [0],
      agreementsCode: '',
      agreementsName: '',
      orgCode: '',
      underFlag: 0,
      startMonth: 0,
      orgName: '',
    });
  }
  
  getPostData(): any {
    return {
      companyCode: this.rawData.companyCode,
      createUser: this.rawData.createUser,
      updateUser: this.rawData.updateUser,
      agreementsId: this.rawData.agreementsId,
      targetYear: this.rawData.targetYear,
      applyStartMonth: moment(this.rawData.applyStartMonth).format('YYYY/MM/DD'),
      monthOverConditions: this.rawData.monthOverConditionsHour * 60,
      monthOverConditionsHour: this.rawData.monthOverConditionsHour,
      monthlyOvertimeHourList: this.rawData.monthlyOvertimeHourList,
      yearOvertimeHourList: this.rawData.yearOvertimeHourList,
      averageOvertimeHourList: this.rawData.averageOvertimeHourList,
      monthlyOverAndHolidayTimeHourList: this.rawData.monthlyOverAndHolidayTimeHourList,
      monthlyOverConditionsCountList: this.rawData.monthlyOverConditionsCountList,
      agreementsCode: this.rawData.agreementsCode,
      agreementsName: this.rawData.agreementsName,
      orgCode: this.rawData.orgCode,
      underFlag: this.rawData.underFlag,
    };
  }

  get companyCode(): string {
    return this.rawData.companyCode;
  }
  set companyCode(companyCode: string) {
    this.rawData.companyCode = companyCode;
  }

  get createUser(): string {
    return this.rawData.createUser;
  }
  set createUser(createUser: string) {
    this.rawData.createUser = createUser;
  }

  get createDate(): Date {
    return this.rawData.createDate;
  }
  set createDate(createDate: Date) {
    this.rawData.createDate = createDate;
  }

  get updateUser(): string {
    return this.rawData.updateUser;
  }
  set updateUser(updateUser: string) {
    this.rawData.updateUser = updateUser;
  }

  get updateDate(): Date {
    return this.rawData.updateDate;
  }
  set updateDate(updateDate: Date) {
    this.rawData.updateDate = updateDate;
  }

  get agreementsId(): string {
    return this.rawData.agreementsId;
  }
  set agreementsId(agreementsId: string) {
    this.rawData.agreementsId = agreementsId;
  }

  get targetYear(): number {
    return this.rawData.targetYear;
  }
  set targetYear(targetYear: number) {
    this.rawData.targetYear = targetYear;
  }

  get applyStartMonth(): Date {
    return this.rawData.applyStartMonth;
  }
  set applyStartMonth(applyStartMonth: Date) {
    this.rawData.applyStartMonth = applyStartMonth;
  }

  get monthOverConditions(): number {
    return this.rawData.monthOverConditions;
  }
  set monthOverConditions(monthOverConditions: number) {
    this.rawData.monthOverConditions = monthOverConditions;
  }

  get monthOverConditionsHour(): number {
    return this.rawData.monthOverConditionsHour;
  }
  set monthOverConditionsHour(monthOverConditionsHour: number) {
    this.rawData.monthOverConditionsHour = monthOverConditionsHour;
  }

  get monthlyOvertimeHourList(): Array<number> {
    return this.rawData.monthlyOvertimeHourList;
  }
  set monthlyOvertimeHourList(monthlyOvertimeHourList: Array<number>) {
    this.rawData.monthlyOvertimeHourList = monthlyOvertimeHourList;
  }

  get yearOvertimeHourList(): Array<number> {
    return this.rawData.yearOvertimeHourList;
  }
  set yearOvertimeHourList(yearOvertimeHourList: Array<number>) {
    this.rawData.yearOvertimeHourList = yearOvertimeHourList;
  }

  get averageOvertimeHourList(): Array<number> {
    return this.rawData.averageOvertimeHourList;
  }
  set averageOvertimeHourList(averageOvertimeHourList: Array<number>) {
    this.rawData.averageOvertimeHourList = averageOvertimeHourList;
  }

  get monthlyOverAndHolidayTimeHourList(): Array<number> {
    return this.rawData.monthlyOverAndHolidayTimeHourList;
  }

  set monthlyOverAndHolidayTimeHourList(monthlyOverAndHolidayTimeHourList: Array<number>) {
    this.rawData.monthlyOverAndHolidayTimeHourList = monthlyOverAndHolidayTimeHourList;
  }

  get monthlyOverConditionsCountList(): Array<number> {
    return this.rawData.monthlyOverConditionsCountList;
  }

  set monthlyOverConditionsCountList(monthlyOverConditionsCountList: Array<number>) {
    this.rawData.monthlyOverConditionsCountList = monthlyOverConditionsCountList;
  }

  get agreementsCode(): string {
    return this.rawData.agreementsCode;
  }
  set agreementsCode(agreementsCode: string) {
    this.rawData.agreementsCode = agreementsCode;
  }

  get agreementsName(): string {
    return this.rawData.agreementsName;
  }
  set agreementsName(agreementsName: string) {
    this.rawData.agreementsName = agreementsName;
  }

  get orgCode(): string {
    return this.rawData.orgCode;
  }
  set orgCode(orgCode: string) {
    this.rawData.orgCode = orgCode;
  }

  get underFlag(): number {
    return this.rawData.underFlag;
  }
  set underFlag(underFlag: number) {
    this.rawData.underFlag = underFlag;
  }

  get startMonth(): number {
    return this.rawData.startMonth;
  }
  set startMonth(startMonth: number) {
    this.rawData.startMonth = startMonth;
  }

  get orgName(): string {
    return this.rawData.orgName;
  }
  set orgName(orgName: string) {
    this.rawData.orgName = orgName;
  }

}
