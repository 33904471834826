/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import { useDownload } from './hooks';

const MonthlySalesManagementTable: React.FC<{
  orgCodeNames: any,
  monthlyReport: any,
  targetPeriodData: any,
  targetPeriod: any,
  targetColumn: any,
  targetDataColumn: any,
  yearlyStartDate: any,
  yearlyEndDate: any
}> = ({
  orgCodeNames,
  monthlyReport,
  targetPeriodData,
  targetPeriod,
  targetColumn,
  targetDataColumn,
  yearlyStartDate,
  yearlyEndDate,
}) => {
  const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();

  const formatter = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 0,
  });

  const formatterPercent = new Intl.NumberFormat('ja-JP', {
    minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'percent',
  });

  const [getOrgCodeName, setOrgCodeName] = useState('');
  const [getDispSlitList, setDispSlitList] = useState();

  const orgCodeArr: any[] = [];
  const searchResult: any[] = [];
  const dispSlitList: any[] = [];
  const [widthScrollbar, setWidthScrollbar] = useState(0);
  useEffect(() => {
    monthlyReport.map((data: any, index: any) => {
      const dispSlitData = [];
      dispSlitData.push(data.orgCode);
      dispSlitData.push(data.orgName);
      dispSlitData.push(data.areaName || '');
      dispSlitData.push(data.businessCategoryName || '');
      data.items.map((item: any) => {
        dispSlitData.push(formatter.format(item.netSales));
        dispSlitData.push(formatter.format(item.salesBudget));
        if (item.salesBudget > 0 && item.netSales > 0) {
          dispSlitData.push(formatterPercent.format(item.netSales / item.salesBudget));
        } else {
          dispSlitData.push(formatterPercent.format(0));
        }
        dispSlitData.push(formatter.format(item.netSalesPreviousYear));
        if (item.netSalesPreviousYear > 0 && item.netSales > 0) {
          dispSlitData.push(formatterPercent.format(item.netSales / item.netSalesPreviousYear));
        } else {
          dispSlitData.push(formatterPercent.format(0));
        }
      });
      dispSlitList.push(dispSlitData);
      orgCodeArr.push(data.orgName);
    });
    setDispSlitList(dispSlitList);
    const str = orgCodeArr.join('、');
    setOrgCodeName(str);
    if (dispSlitList.length > 0) {
      setWidthScrollbar(((dispSlitList[0].length - 2) * 135) + 50);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyReport, widthScrollbar]);

  const targetStoresData = `対象組織：${getOrgCodeName}`;
  const headerInformationCSV: any[] = [];
  const headerInformationExcel: any[] = [];

  let mergedRegions: any[] = [];
  const data = {
    footer: '',
    formName: '年次売上管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    headerInformation: headerInformationCSV,
    searchResult,
    totalInformation: [],
  };

  const dataExcel = {
    formName: '年次売上管理表',
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    compareToPreviousYear: false,
    headerInformation: headerInformationExcel,
    searchResult,
    totalInformation: [],
    mergedRegions,
    codeColumnIndex: [0],
  };

  const handleExportCSV = () => {
    if (headerInformationCSV) {
      const headers: any[] = [];
      const subHeader1: any[] = ['組織コード', '組織名', 'エリア', '業態'];
      targetDataColumn.map((name: any) => {
        subHeader1.push(name);
      });
      let subHeader2: any[] = [];
      subHeader2 = subHeader2.concat(Array((4)).fill(''));
      subHeader2.push('売上');
      subHeader2 = subHeader2.concat(Array((4)).fill(''));
      for (let i = 0; i < targetPeriod.length; i += 1) {
        subHeader2.push(targetPeriod[i]);
        subHeader2 = subHeader2.concat(Array((4)).fill(''));
      }
      headers.push(subHeader2, subHeader1);
      data.headerInformation = headers;
    }
    data.searchResult = getDispSlitList;
    downloadMonthlyReportCsv(data);
  };

  const handleExportExcel = () => {
    let startDate = new Date(yearlyStartDate);
    const endDate = new Date(yearlyEndDate);
    let monthCnt = 0;
    let targetColumns: any[] = [];
    let targetPeriod: any[] = [];
    if (mergedRegions) {
      mergedRegions = [];
    }
    for (; startDate.getTime() <= endDate.getTime(); startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0)) {
      mergedRegions.push([4, 4, 9 + monthCnt * 5, 9 + monthCnt * 5 + 4]);
      monthCnt += 1;
      targetPeriod = targetPeriod.concat([moment(startDate, 'YYYY/MM/DD').format('YYYY年MM月'), '', '', '', '']);
      targetColumns = targetColumns.concat('純売上', '売上予算', '予実達成率', '前年売上', '前年対比');
    }
    mergedRegions.push([4, 5, 0, 0]);
    mergedRegions.push([4, 5, 1, 1]);
    mergedRegions.push([4, 5, 2, 2]);
    mergedRegions.push([4, 5, 3, 3]);
    mergedRegions.push([4, 4, 4, 8]);

    let subHeader = [];
    const headers: any[] = [];
    subHeader.push('組織コード', '組織名', 'エリア', '業態', '期間合計', '', '', '', '');
    subHeader = subHeader.concat(targetPeriod);
    headers.push(subHeader);
    subHeader = [];
    subHeader.push('', '', '', '', '純売上', '売上予算', '予実達成率', '前年売上', '前年対比');
    subHeader = subHeader.concat(targetColumns);
    headers.push(subHeader);
    dataExcel.headerInformation = headers;
    dataExcel.searchResult = getDispSlitList;
    return downloadMonthlyReportExcel(dataExcel);
  };

  const [getFlagBorder, setFlagBorder] = useState(0);
  const eventScroll = (e: { target: any }) => {
    const { target } = e;
    const wrapTblRight = document.getElementById('wrapTblRight');
    const wrapTblHeadRight = document.getElementById('wrapTblHeadRight');
    const jsScrollAction = document.getElementById('jsScrollAction');

    if (Number(target.scrollLeft) > 0) {
      setFlagBorder(1);
    } else {
      setFlagBorder(0);
    }
    if (wrapTblRight) {
      wrapTblRight.scrollLeft = target.scrollLeft;
    }
    if (wrapTblHeadRight) {
      wrapTblHeadRight.scrollLeft = target.scrollLeft;
    }
    if (jsScrollAction) {
      jsScrollAction.scrollLeft = target.scrollLeft;
    }
  };
  return (
    <div>
      <FlexBox>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportCSV}
            iconType="download"
            text=" CSV出力"
          />
        </FlexBoxItem>
        <FlexBoxItem>
          <IconLabelButton
            onClick={handleExportExcel}
            iconType="download"
            text=" EXCEL出力"
          />
        </FlexBoxItem>
      </FlexBox>
      <div className="wrap_time_day_separate_sales wrap_sales_management_table" style={{ overflow: 'hidden', marginTop: '15px' }}>
        <div className="contentHead" style={{ display: 'flex' }}>
          <div className="tblLeft" style={getFlagBorder ? { borderRight: '1px solid' } : {}}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} className="text-center">組織コード</th>
                  <th rowSpan={2} className="text-center">組織名</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tblRight" id="wrapTblHeadRight" style={monthlyReport.length > 17 ? { width: 'calc(100% - 342px)' } : {}} onScroll={eventScroll}>
            <table style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th rowSpan={2} className="text-center"><span>エリア</span></th>
                  <th rowSpan={2} className="text-center"><span>業態</span></th>
                  <th colSpan={5} className="text-center"><span style={{ width: '100%' }}>期間合計</span></th>
                  {
                    targetPeriod.map((name:any, index:any) => (
                      <th key={index} colSpan={5} className="text-center"><span style={{ width: '100%' }}>{name}</span></th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    targetDataColumn.map((name:any, index:any) => (
                      <th key={index} className="text-center"><span>{name}</span></th>
                    ))
                  }
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <div>
            <div className="contentTable">
              <div className="tblLeft" style={{ width: '325px' }}>
                <table>
                  <tbody>
                    {
                      getDispSlitList ? getDispSlitList.map((dispSlitData:any, index:any) => (
                        <tr key={index}>
                          {
                            dispSlitData.map((dataChild:any, idx:any) => {
                              if (idx < 2) {
                                return (
                                  <td key={idx} style={{ width: '50%' }}>{dataChild}</td>
                                );
                              }
                            })
                          }
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
              <div className="tblRight" id="wrapTblRight" style={{ width: 'calc(100% - 325px)' }} onScroll={eventScroll}>
                <table id="tblHeadTable" onScroll={eventScroll}>
                  <tbody>
                    {
                      getDispSlitList ? getDispSlitList.map((dispSlitData:any, index:any) => (
                        <tr key={index}>
                          {
                            dispSlitData.map((dataChild:any, idx:any) => {
                              if (idx >= 2) {
                                return (
                                  <td key={idx} className={(idx === 2 || idx === 3) ? 'text-center' : 'text-right'}><span>{dataChild}</span></td>
                                );
                              }
                            })
                          }
                        </tr>
                      )) : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {
            getDispSlitList ? (
              <div className="scroller">
                <div className="scrollLeft" style={{ width: '325px' }} />
                <div className="scrollRight" id="jsScrollAction" style={{ width: 'calc(100% - 325px)' }} onScroll={eventScroll}>
                  <div className="scrollAction" style={{ width: `${widthScrollbar}px` }} />
                </div>
              </div>
            ) : null
          }
      </div>
    </div>
  );
};

export default MonthlySalesManagementTable;
