/* eslint-disable max-len */
/** @jsx jsx */
import React, {useState, useEffect} from 'react';
import FormContents from "components/atoms/Form/FormContents";
import FormTitle from "components/atoms/Form/FormTitle";
import { css, jsx } from "@emotion/core";
import BlockUI from "components/molecules/BlockUi";
import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import { OptionType } from 'components/atoms/Select';
import BodyText from 'components/atoms/BodyText';
import FlexBox from 'components/atoms/FlexBox';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import { utilityColor, grayScale, textFontSize, productColor } from "components/styles";
import SelectFormV2 from "components/molecules/SelectFormV2";
import FormField from "components/atoms/Form/FormField";
import FormLayout from "components/atoms/Form/VerticalLabelFormLayout";
import FormLabel from "components/atoms/Form/FormLabel";
import YearPickerForm from 'components/molecules/YearPickerForm';
import HourInput from 'components/atoms/Form/HourInput';
import IconLabelButton from "components/molecules/IconLabelButton";
import ListWarningThreshold from './ListWarningThreshold';
import Button, { DeleteButton } from 'components/atoms/Button';
import PrimaryButton from 'components/atoms/Button';
import useMasterSettingAgreement36Form from './hooks';
import Modal from 'components/molecules/Modal';
import TextForm from 'components/molecules/TextForm';
import { Link, useParams } from 'react-router-dom';
import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
import SelectForm from 'components/molecules/SelectForm';
import Checkbox from 'components/molecules/Checkbox';

const styles = {
    formContainer: css({
      width: "70%",
      margin: "auto",
      border: "0",
    }),
    formLabel: css({
        paddingTop: '8px', 
        width: '250px'
    }),
    formNote: css({
        margin: '0 0 2px 16px', 
        color: '#333333'
    }),
    formInput: css({
        width: '65px', 
        height: '40px'
    }),
    errorNote: css({
        color: productColor.secondary
    }),
    warningNote: css({
        color: productColor.copyAction
    }),
    formTitle: css({
        margin: '50px 0 20px'
    }),
    textLabel: css({
        color: grayScale.gray60,
        fontSize: textFontSize.re
    }),
}

const MasterSettingAgreement36Form: React.FC<{agreementsId?: string}> = ({
    agreementsId, 
}) => {
    const {
        isLoading,
        listMonths,
        monthOptions,
        formik,
        openModal,
        setOpenModal,
        isEdit,
        setPreviousYearData,
        detailRole,
        orgCodeOptions,
    } = useMasterSettingAgreement36Form(agreementsId);

    const getMonthSelectValue = (date: Date|null) => {
        let monthValue = (date ? date.getMonth() : 0) + 1;
        return 10 <= monthValue  ? String(monthValue) : `0${monthValue}`
    }

     return (
        <BlockUI blocking={isLoading}>
            <FormContents
                customStyle={{
                paddingBottom: "65px",
                marginBottom: "135px",
                }}
            >
                <FormTitle bold={true} title="36協定アラート設定" />
                <form css={styles.formContainer}　>
                    <FormField>
                        <TextForm
                            name="agreementsCode"
                            label="コード"
                            value={formik.values.agreementsCode}
                            onChange={formik.handleChange}
                            errorMsg={formik.errors.agreementsCode}
                        />
                    </FormField>
                    <FormField>
                        <TextForm
                            name="agreementsName"
                            label="名称"
                            value={formik.values.agreementsName}
                            onChange={formik.handleChange}
                            errorMsg={formik.errors.agreementsName}
                        />
                    </FormField>
                    {!isEdit && (
                    <FormField>
                        <YearPickerForm
                            name="year"
                            label="対象年度"
                            selectedYear={formik.values.targetYear}
                            onChange={(year: any) => {
                                // setTargetYear(year);
                                formik.setFieldValue('targetYear', year)
                            }}
                            addChild={
                                <PrimaryButton
                                    text="前年の内容を取り込む"
                                    onClick={() => setPreviousYearData()}
                                    ghost={true}
                                />
                            }
                        />                        
                    </FormField>
                    )}
                    {isEdit && (
                    <FormField>
                    <FlexBox>
                    <div style={{ width: "65px" }}>
                    <TextForm
                            name="year"
                            label="対象年度"
                            value={String(formik.values.targetYear)}
                            disable={true}
                            onChange={formik.handleChange}
                            errorMsg={formik.errors.agreementsName}
                        />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                        <PrimaryButton
                            text="前年の内容を取り込む"
                            onClick={() => setPreviousYearData()}
                            ghost={true}
                        />
                        </div>
                        </FlexBox>
                    </FormField>
                    )}
                    <FormField>
                        <FormLayout
                            label={<FormLabel label="起算月"/>}
                            input={
                            <FlexBox>
                                <div style={{ width: "65px" }}>
                                <SelectFormV2
                                    label=""
                                    name="month"
                                    value={getMonthSelectValue(formik.values.applyStartMonth)}
                                    options={monthOptions}
                                    setValue={(val: string) => {
                                        formik.setFieldValue('applyStartMonth', new Date(formik.values.targetYear, Number(val) - 1, 1))
                                    }}
                                    description=""
                                    width="65px"
                                />
                                </div>
                                <div style={{ marginLeft: "12px" }}>
                                    <BodyText color={grayScale.gray60}>月</BodyText>
                                </div>
                            </FlexBox>
                            }
                        />
                    </FormField>
                    <FormField displayBlock={true}>
                    <div style={{color: grayScale.gray60, fontSize: textFontSize.re}}>上位組織</div>
                    <FlexBox>
                        <SelectForm
                            label=""
                            name="orgCode"
                            value={String(formik.values.orgCode)}
                            setValue={(orgCode) => {
                            formik.setFieldValue('orgCode', orgCode);
                            }}
                            options={orgCodeOptions}
                            description=""
                            width="900px"
                            widthLabel="0"
                            marginRight="30px"
                            />
                        <Checkbox
                        id={'underFlag'}
                        name={'underFlag'}
                        label={"配下指定"}
                        value={'underFlag'}
                        checked={Boolean(formik.values.underFlag)}
                        onChange={() => {
                            formik.setFieldValue('underFlag', formik.values.underFlag === 1 ? 0 : 1)
                          }}
                                  />
                   </FlexBox>
                    </FormField>
                    <FormLabel label="月次時間外労働時間アラート" customStyle={styles.formTitle}/>
                    <ListWarningThreshold 
                        listWarningThreshold={formik.values.monthlyOvertimeHourList}
                        setListWarningThreshold={
                            (monthlyOvertimeHourList: Array<number>) => formik.setFieldValue('monthlyOvertimeHourList', monthlyOvertimeHourList)
                        }
                        label="当月の時間外労働時間が"
                        noteInput="時間を超える残業件数を表示する"
                        maxLength={4}
                        maxLengthInput={2}
                    />
                    <FormField customStyle={css({marginTop: '40px'})}></FormField>
                    <ListWarningThreshold  
                        listWarningThreshold={formik.values.monthlyOverAndHolidayTimeHourList}
                        setListWarningThreshold={
                            (monthlyOverAndHolidayTimeHourList: Array<number>) => formik.setFieldValue('monthlyOverAndHolidayTimeHourList', monthlyOverAndHolidayTimeHourList)
                        }
                        label="当月の時間外と休日の労働時間合計が"
                        noteInput="時間を超える残業件数を表示する"
                        maxLength={4}
                        maxLengthInput={3}
                    />

                    <FormLabel label="年次時間外労働時間アラート" customStyle={styles.formTitle}/>
                    <ListWarningThreshold 
                        listWarningThreshold={formik.values.yearOvertimeHourList}
                        setListWarningThreshold={
                            (yearOvertimeHourList: Array<number>) => formik.setFieldValue('yearOvertimeHourList', yearOvertimeHourList)
                        }
                        label="当年の時間外労働時間が"
                        noteInput="時間を超える残業件数を表示する"
                        maxLength={4}
                        maxLengthInput={3}
                    />
                   
                   <FormField customStyle={css({marginTop: '40px'})}></FormField>
                    <ListWarningThreshold 
                        listWarningThreshold={formik.values.averageOvertimeHourList}
                        setListWarningThreshold={
                            (averageOvertimeHourList: Array<number>) => formik.setFieldValue('averageOvertimeHourList', averageOvertimeHourList)
                        }
                        label="2~6ヶ月の時間外労働平均時間が"
                        noteInput="時間を超える残業件数を表示する"
                        maxLength={4}
                        maxLengthInput={3}
                    />
                    

                    <FormLabel label="月の時間外労働時間超過回数アラート" customStyle={css({marginTop: '50px'})}/>

                    <FormField>
                        <FormLabel customStyle={styles.formLabel} label="時間外労働届出の上限"/>
                        <FlexBox customStyle={css({
                            height: 'fit-content',
                            marginRight: '20px'
                        })}>
                            <HourInput
                                name={'hourOvertimeMonthly'}
                                value={String(formik.values.monthOverConditionsHour)}
                                label=""
                                onChange={(e) => { 
                                    formik.setFieldValue('monthOverConditionsHour', Number(e.target.value))
                                }}
                                min={0}
                                widthInput="50px"
                                wrapInput={styles.formInput}
                            />
                            <FormLabel customStyle={styles.formNote} label="時間を"/>
                        </FlexBox>
                        <FlexBoxItem>
                            <ListWarningThreshold 
                                listWarningThreshold={formik.values.monthlyOverConditionsCountList}
                                setListWarningThreshold={
                                    (monthlyOverConditionsCountList: Array<number>) => formik.setFieldValue('monthlyOverConditionsCountList', monthlyOverConditionsCountList)
                                }
                                label=""
                                noteInput="回を超える残業回数を表示する"
                                maxLength={4}
                                maxLengthInput={1}
                            />
                        </FlexBoxItem>
                    </FormField>
                    <FormSubmitArea customStyle={css({ textAlign: 'center', marginTop: '20px' })}>
                    {detailRole.editable === 1 && (
                        <div>
                            <PrimaryButton
                                text={isEdit ? "更新" : "登録"}
                                onClick={() => setOpenModal(true)}
                            />
                            </div>
                    )}
                        <Link to="/masterSettingAgreement36">
                            <Button
                            text="キャンセル"
                            ghost={true}
                            />
                        </Link>
                    </FormSubmitArea>
                </form>
            </FormContents>
            <Modal
                open={openModal}
                closeHandler={() => setOpenModal(false)}
                title={isEdit ? "36協定アラート設定の更新" : "36協定アラート設定の登録"}
                note={isEdit ? "36協定アラート設定マスタを更新します。よろしいですか？" : "36協定アラート設定マスタを登録します。よろしいですか？"}
                submitText={isEdit ? "更新" : "登録"}
                onSubmit={() => formik.handleSubmit()}
            >
            </Modal>
        </BlockUI>
  );
};

export default MasterSettingAgreement36Form;
