import React, { useEffect } from "react";
import { css } from "@emotion/core";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import BodyText from "components/atoms/BodyText";
import Select from "components/atoms/Select";
import useExecutiveOptions from "hooks/useExecutiveOptions";
import SelectForm from "components/molecules/SelectForm";

const ExecutiveFilter: React.FC<{
    executiveId: string;
    setExecutiveId: (executiveId: string) => void;
    id?: string;
    defaultValue?: any;
}> = ({ executiveId, setExecutiveId, id = "0", defaultValue }) => {
    const executiveOptions = useExecutiveOptions();

    // useEffect(() => {
    //   if(executiveId !== null){
    //     sessionStorage.setItem(`${id}/sessionData${window.location.pathname}.selectedExecutiveIdStaffMaster`, executiveId);
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [executiveId])

    // useEffect(() => {
    //   let session = sessionStorage.getItem(`${id}/sessionData${window.location.pathname}.selectedExecutiveIdStaffMaster`);
    //   if(session !== null){
    //     setExecutiveId(session);
    //   }else{
    //     setExecutiveId(defaultValue || "");
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    return (
        <FlexBox
            customStyle={css({
                padding: "8px",
            })}
        >
            <FlexBoxItem basis="100px">
                <BodyText> 役職 </BodyText>
            </FlexBoxItem>
            <FlexBoxItem basis="800px">
                <SelectForm
                    name="orgState"
                    value={executiveId}
                    setValue={(arg: string) => {
                        setExecutiveId(arg);
                    }}
                    options={executiveOptions}
                    placeholder="チーフ"
                    label=""
                />
            </FlexBoxItem>
        </FlexBox>
    );
};

export default ExecutiveFilter;
