/** @jsx jsx */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SidebarTemplate from "components/templates/SidebarTemplate";
import FormContents from "components/atoms/Form/FormContents";
import { css, jsx } from "@emotion/core";
import { Container, Row, Col } from "react-grid-system";
import moment from "moment";
import BodyText from "components/atoms/BodyText";
import Button, { PrimaryButton, DeleteButton } from "components/atoms/Button";
import ConfirmModal from "components/organismos/ConfirmModal";
import TextForm from "components/molecules/TextForm";
import BlockUI from "components/molecules/BlockUi";
import ToastModal from "../../../../organismos/ToastModal";
import { TransferApplicationForm } from "./hooks";
import FormPadding from "components/atoms/Form/FormPadding";
import FlexBox from "components/atoms/FlexBox";
import productColor, { textFontSize, textColor, grayScale } from "components/styles";
import FormTitle from "components/atoms/Form/FormTitle";
import HistoryApprovalTable from "components/molecules/HistoryApprovalTable";
import AttendTransferModal from "components/molecules/ChangeLogModal/Template/AttendTransferModal";
import TabControl from "components/atoms/TabControl";
import Calendar from "components/molecules/CalendarV1";
import SelectForm from "components/molecules/SelectForm";
import useIsMobile from "hooks/useIsMobile";

const styles = {
  container: {
    marginTop: "30px",
  },
  tableContent: css({
    justifyContent: "center",
    alignItems: "center",
  }),
  colorText: css({
    color: grayScale.gray100,
  }),
  approvalWrap: css({
    padding: "0px",
    marginBottom: "15px",
    marginTop: "0px",
  }),
  formContent: {
    paddingBottom: "20px",
  },
  rowPadding: css({
    paddingBottom: "16px",
  }),
};

const TransferApplicationPage: React.FC = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const {
    formik,
    targetDate,
    toastMessage,
    toastModalOpen,
    setToastModalOpen,
    confirmApplyModalOpen,
    closeConfirmApplyModal,
    isLoading,
    dispStaffName,
    isGetTransferSuccees,
    selectChangelog,
    setSelectChangelog,
    fetchChangeLogTransfer,
    orgTreesOptions,
  } = TransferApplicationForm();

  const [changeLogData, setChangeLogData] = useState<Array<any>>([]);

  useEffect(() => {
    if (formik.values.orgCode || formik.values.targetDate || formik.values.staffCode) {
      fetchChangeLogTransfer(formik.values.orgCode, formik.values.targetDateStr, formik.values.staffCode).then(
        (res) => {
          setChangeLogData(res);
        },
      );
    }
  }, [formik.values.orgCode, formik.values.targetDate, formik.values.staffCode]);
  return (
    <SidebarTemplate pageTitle="振替申請">
      <BlockUI blocking={isLoading}>
        <FormContents
          customStyle={css({
            paddingBottom: isMobile ? "8px" : "",
          })}
        >
          <FormTitle
            title="振替申請"
            bold={true}
            customStyle={css(isMobile ? { padding: "8px 16px 16px 16px", margin: 0 } : {})}
          />
          <form onSubmit={formik.handleSubmit}>
            <Container fluid={true}>
              <FormPadding customStyle={css(isMobile ? { padding: "0 16px 16px 16px" } : { paddingLeft: "35px" })}>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>日時</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText customStyle={styles.colorText}>
                      {moment(`${targetDate}`).format("YYYY年MM月DD日(ddd)")}
                    </BodyText>
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col style={{ display: "flex", alignItems: "center" }} md={2} xs={12}>
                    <BodyText>出勤組織</BodyText>
                  </Col>
                  <Col md={3} xs={12}>
                    <SelectForm
                      label=""
                      name="orgCode"
                      value={String(formik.values.orgCode)}
                      setValue={(val: string) => {
                        formik.setFieldValue("orgCode", val);
                      }}
                      options={orgTreesOptions}
                      required={false}
                      disable={formik.values.modifierApplicationId ? true : false}
                      width={isMobile ? "100%" : "800px"}
                    />
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12}>
                    <BodyText>スタッフ</BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <BodyText customStyle={styles.colorText}>{dispStaffName}</BodyText>
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <BodyText
                      customStyle={css(
                        isMobile
                          ? {
                              marginBottom: "4px",
                            }
                          : {},
                      )}
                    >
                      振替休日
                    </BodyText>
                  </Col>
                  <Col md={3} xs={12}>
                    <Calendar
                      readOnly={formik.values.applicationStatus === 0}
                      date={
                        moment(formik.values.transferHolidayDate).format("YYYYMMDD") === "19700101"
                          ? new Date(targetDate)
                          : formik.values.transferHolidayDate
                      }
                      setDate={(date) => {
                        if (date) {
                          formik.setFieldValue("transferHolidayDate", date);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row css={styles.rowPadding}>
                  <Col md={2} xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <BodyText
                      customStyle={css(
                        isMobile
                          ? {
                              marginBottom: "4px",
                            }
                          : {},
                      )}
                    >
                      申請理由
                    </BodyText>
                  </Col>
                  <Col md={6} xs={12}>
                    <TextForm
                      required={false}
                      name="applicationReason"
                      label=""
                      disable={formik.values.applicationStatus === 0}
                      value={String(formik.values.applicationReason)}
                      onChange={formik.handleChange}
                      errorMsg={formik.errors.applicationReason}
                    />
                  </Col>
                </Row>
              </FormPadding>

              {isMobile ? (
                <FormPadding customStyle={css({ padding: "0 16px 16px 16px" })}>
                  <div css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr);", gap: 8 }}>
                    {isGetTransferSuccees &&
                      (formik.values.modifiyStatus === null ||
                        formik.values.modifiyStatus === 0 ||
                        (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0)) && (
                        <PrimaryButton
                          ghost={false}
                          disabled={formik.values.applicationStatus === 0 && formik.values.createDate != null}
                          text="申請する"
                          onClick={() => {
                            formik.handleSubmit();
                            formik.setFieldValue("buttonType", 0);
                          }}
                        />
                      )}
                    {formik.values.applicationStatus === 0 && (
                      <DeleteButton
                        ghost={false}
                        text="申請キャンセル"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("buttonType", 1);
                        }}
                      />
                    )}
                    {formik.values.deleteAvailable === true && (
                      <DeleteButton
                        ghost={false}
                        text="削除申請"
                        onClick={() => {
                          formik.handleSubmit();
                          formik.setFieldValue("buttonType", 2);
                        }}
                      />
                    )}

                    <Button
                      customStyle={css({
                        border: `1px solid ${productColor.primary}`,
                      })}
                      onClick={() => history.goBack()}
                      text="キャンセル"
                      ghost={true}
                    />
                  </div>
                </FormPadding>
              ) : (
                <React.Fragment>
                  <Row>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        css={css`
                          .btnAction:nth-of-type(2) {
                            margin-left: 10px;
                          }
                          .btnAction:nth-of-type(3) {
                            margin-left: 10px;
                          }
                        `}
                      >
                        <FlexBox>
                          {isGetTransferSuccees &&
                            (formik.values.modifiyStatus === null ||
                              formik.values.modifiyStatus === 0 ||
                              (formik.values.modifiyStatus === 1 && formik.values.applicationStatus !== 0)) && (
                              <div className="btnAction">
                                <PrimaryButton
                                  ghost={false}
                                  disabled={formik.values.applicationStatus === 0 && formik.values.createDate != null}
                                  text="申請する"
                                  onClick={() => {
                                    formik.handleSubmit();
                                    formik.setFieldValue("buttonType", 0);
                                  }}
                                />
                              </div>
                            )}
                          {formik.values.applicationStatus === 0 && (
                            <div className="btnAction">
                              <DeleteButton
                                ghost={false}
                                text="申請キャンセル"
                                onClick={() => {
                                  formik.handleSubmit();
                                  formik.setFieldValue("buttonType", 1);
                                }}
                              />
                            </div>
                          )}
                          {formik.values.deleteAvailable === true && (
                            <div className="btnAction">
                              <DeleteButton
                                ghost={false}
                                text="削除申請"
                                onClick={() => {
                                  formik.handleSubmit();
                                  formik.setFieldValue("buttonType", 2);
                                }}
                              />
                            </div>
                          )}
                        </FlexBox>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <FlexBox>
                        <Button onClick={() => history.goBack()} text="キャンセル" ghost={true} />
                      </FlexBox>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Container>
          </form>

          <ToastModal open={toastModalOpen} closeHandler={() => setToastModalOpen(false)} title={toastMessage} />

          <ConfirmModal
            open={confirmApplyModalOpen}
            closeHandler={closeConfirmApplyModal}
            onSubmit={formik.handleSubmit}
            title={
              formik.values.buttonType === 0
                ? formik.values.modifiyStatus === 0
                  ? "振替修正を申請"
                  : "振替を申請"
                : formik.values.buttonType === 1
                ? "振替キャンセルを申請"
                : "削除振替を申請"
            }
            content={
              formik.values.buttonType === 0
                ? formik.values.modifiyStatus === 0
                  ? "振替修正を申請します。よろしいですか？"
                  : "振替を申請します。よろしいですか？"
                : formik.values.buttonType === 1
                ? "振替キャンセルを申請します。よろしいですか？"
                : "削除振替を申請します。よろしいですか？"
            }
            submitText="申請する"
            typeSubmit={formik.values.buttonType === 0 ? "confirm" : "reject"}
          />
        </FormContents>
      </BlockUI>

      {!isMobile && (
        <div style={{ paddingTop: 20 }}>
          <FormContents customStyle={styles.formContent}>
            <Row style={{ padding: "10px 30px" }}>
              <Col
                md={2}
                xs={12}
                style={{
                  fontWeight: "bold",
                  fontSize: textFontSize.md,
                  lineHeight: "24px",
                  paddingBottom: "20px",
                  color: textColor.main,
                }}
              >
                更新履歴
              </Col>
            </Row>
            <TabControl
              isTabModal={true}
              isFixed={false}
              items={changeLogData.map((log, index: number) => {
                return {
                  value: String(index),
                  display: `ver${log.version}`,
                };
              })}
              setValue={setSelectChangelog as (arg: string) => void}
              currentValue={selectChangelog}
              styleNotActive={css({
                borderRadius: 0,
                border: "none",
                width: "100px",
                flex: "unset",
              })}
              styleActive={css({
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #007BC3",
                width: "100px",
                flex: "unset",
              })}
            />
            {changeLogData.length > 0 &&
              changeLogData.map((itemLog, index: number) => (
                <div
                  style={{
                    display: selectChangelog !== String(index) ? "none" : "block",
                  }}
                >
                  <React.Fragment>
                    <FormPadding
                      customStyle={{
                        fontSize: "14px",
                        color: textColor.mobileTitle,
                      }}
                    >
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請種別:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.modifiyStatus == 0 ? "申請" : "削除"}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請者:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationStaffCode && `${itemLog.applicationStaffCode} `}
                          {itemLog.applicationStaffName && itemLog.applicationStaffName}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "20px",
                            color: textColor.main,
                          }}
                        >
                          申請日時:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationDate && moment(itemLog.applicationDate).format("YYYY/MM/DD HH:mm:ss")}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={2}
                          xs={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: textFontSize.re,
                            lineHeight: "24px",
                            paddingBottom: "10px",
                            color: textColor.main,
                          }}
                        >
                          修正理由:
                        </Col>
                        <Col md={10} xs={12}>
                          {itemLog.applicationReason && itemLog.applicationReason}
                        </Col>
                      </Row>
                      <Row>
                        <AttendTransferModal
                          changeLogData={changeLogData}
                          selectChangelog={String(selectChangelog)}
                          customStyle={styles.approvalWrap}
                        />
                      </Row>
                      {changeLogData.length > 0 && (
                        <Row>
                          <HistoryApprovalTable
                            historyData={itemLog.approvalHistoryList}
                            applicationStatus={itemLog.applicationStatus}
                          />
                        </Row>
                      )}
                    </FormPadding>
                  </React.Fragment>
                </div>
              ))}
          </FormContents>
        </div>
      )}
    </SidebarTemplate>
  );
};

export default TransferApplicationPage;
