/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import FormContents from 'components/atoms/Form/FormContents';
import { useOrgTreesOptionsManagedCustom } from 'hooks/useOrgTreesOptions';
import SelectForm from 'components/molecules/SelectForm';
import FormField from 'components/atoms/Form/FormField';
import BodyText from 'components/atoms/BodyText';
import { utilityColor, grayScale } from 'components/styles';
// import Checkbox from 'components/molecules/Checkbox';
import PrimaryButton, { SecondaryButton } from 'components/atoms/Button';
import useToastNotification from 'hooks/useToastNotification';
import ConfirmModal from 'components/organismos/ConfirmModal';
import FormPadding from 'components/atoms/Form/FormPadding';
import { Row, Col } from 'react-grid-system';
import { css } from '@emotion/core';
import FormTitle from 'components/atoms/Form/FormTitle';
import { useOrgTreesOptionsSpecifyCode } from 'hooks/useOrgTreesOptions';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import BlockUI from 'components/molecules/BlockUi';

const checkSameSpecifyCode = (specifyCode: string, storageCode: string) => {
  return specifyCode === storageCode || (specifyCode === "all" && storageCode === "");
}
const MasterShopSpecifyLayout = () => {
  const LOCAL_STORAGE_SHOP_INFO_KEY_NAME: string = 'win-board-localstorage-shop-info';
  const storageEncode = localStorage.getItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
  const storage: any = storageEncode ? JSON.parse(storageEncode) : '';
  const [specifyCode, setSpecifyCode] = useState((storage && storage.specifyCode !== "") ? storage.specifyCode : 'all');
  const [isLoading, setIsLoading] = useState(false);
  const orgTreesOptions = useOrgTreesOptionsSpecifyCode(true, functionCode.masterShopSpecify, undefined, undefined);
  const { detailRole } = useGetDetailRole(setIsLoading, functionCode.masterShopSpecify);
  useEffect(() => {
    if(orgTreesOptions){
      let objectOrg = orgTreesOptions.filter((item) => {
        return item.orgCode === sessionStorage.getItem('loginUser.orgCode')
      })[0];
      if (objectOrg){
        setSpecifyCode(objectOrg.value);
      }
    }
  }, [orgTreesOptions])
  const [stampByBelongOrg, setStampByBelongOrg] = useState(!!(Number(storage.stampByBelongOrg) === 1));
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const { successNotification, errorNotification } = useToastNotification();

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmClearModal, setConfirmClearModal] = useState(false);
  const [disabled, setDisabled] = useState(!storage);

  const handleAdd = useCallback(() => {
    setConfirmModal(false);
    setDisabled(false);
    localStorage.removeItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);

    const registeredMap = {
      specifyCode: specifyCode !== "all" ? specifyCode : "",
      companyCode,
      stampByBelongOrg: stampByBelongOrg ? 1 : 0,
    };

    localStorage.setItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME, JSON.stringify(registeredMap));
    successNotification('組織を登録しました。');
  },
  [companyCode, errorNotification, specifyCode, stampByBelongOrg, successNotification]);

  const handleClear = () => {
    setSpecifyCode('');
    localStorage.removeItem(LOCAL_STORAGE_SHOP_INFO_KEY_NAME);
    setConfirmClearModal(false);
    setStampByBelongOrg(false);
    successNotification('組織情報をクリアしました。');
    setDisabled(true);
  };

  // function handleOnChange(option:any) {
  //   // if(option.label === '-'){
  //   //   setDisabled(true);
  //   // }
  //   setDisabled(false);
  // }

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle title="端末への組織登録" bold={true} customStyle={css`margin: 16px 45px;`}/>
        <FormPadding>
          <Row>
            <Col lg={4} md={12}>
              <FormField displayBlock={true}>
                <SelectForm
                  label="組織名"
                  placeholder="組織を選択してください"
                  name="specifyCode"
                  value={specifyCode}
                  setValue={(code) => {
                    setSpecifyCode(code || "all");
                  }}
                  options={orgTreesOptions}
                  description=""
                  width="900px"
                />
              </FormField>
            </Col>
          </Row>

          <div style={{ marginLeft: '100px' }}>
            <div>
              <BodyText size="sm" color={utilityColor.error}>端末に組織情報を登録します。</BodyText>
            </div>
            <div>
              <BodyText size="sm" color={utilityColor.error}>組織情報を登録することで、組織サイトへの自動ログイン、タイムレコーダーが使用できるようになります。</BodyText>
            </div>
            <div>
              <BodyText size="sm" color={utilityColor.error}>全ての組織の場合は打刻時の出勤店舗はスタッフの所属組織になります。</BodyText>
            </div>
          </div>
          {detailRole.editable === 1 && (
            <div style={{ textAlign: 'center', margin: '30px 0' }}>
              <div>
                <PrimaryButton 
                minWidth="160px" 
                text="登録する" 
                onClick={() => setConfirmModal(true)}
                disabled={checkSameSpecifyCode(specifyCode, storage.specifyCode) && companyCode === storage.companyCode}
                />
              </div>
              <div>
                <SecondaryButton
                  minWidth="160px" 
                  onClick={() => setConfirmClearModal(true)}
                  text="クリア"
                  disabled={disabled}
                  customStyle={css`
                    margin-top: 5px;
                  `}
                />
              </div>
            </div>
          )}
         
        </FormPadding>

        <ConfirmModal
          title="端末設定"
          content="端末に組織を登録します。よろしいですか？"
          submitText="登録する"
          open={confirmModal}
          closeHandler={() => setConfirmModal(false)}
          onSubmit={handleAdd}
        />

        <ConfirmModal
          title="確認メッセージ"
          content="端末に登録されている組織情報をクリアします。</br>
          タイムレコーダーが使用できなくなりますが、よろしいですか？"
          submitText="クリアする"
          open={confirmClearModal}
          closeHandler={() => setConfirmClearModal(false)}
          onSubmit={handleClear}
          typeSubmit="reject"
        />
      </FormContents>
    </BlockUI>
  );
};

export default MasterShopSpecifyLayout;
