import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';

import { createOrUpdateBusinessCategory, getBusinessCategoryById } from 'api/businessCategories';
import BusinessCategoryDomain from 'domain/master/sales/businessCategory';
import useToastNotification from 'hooks/useToastNotification';
import { useHistory } from 'react-router-dom';


type BusinessCategoryDomainKey = keyof Pick<BusinessCategoryDomain, 'businessCategoryCode'|'businessCategoryName'>;

export const useBusinessCategoryAddForm = (businessCategoryId?: string) => {
  let isEdit = false;
  if (businessCategoryId) {
    isEdit = true;
  } else {
    isEdit = false;
  }

  const [detailMode, setDetailMode] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // modal confirm
  const [toastModalOpen, setToastModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  // Modal alert
  const history = useHistory();
  const onSubmit = async (values: BusinessCategoryDomain) => {
    if (values.businessCategoryCode === null) {
      errorNotification('業態コードを入力してください。');
      return;
    }

    if (values.businessCategoryName === null) {
      errorNotification('業態名を入力してください。');
      return;
    }

    if ((/^ *$/.test(values.businessCategoryCode) || /^ *$/.test(values.businessCategoryName)) && (values.businessCategoryName !== '' && values.businessCategoryCode !== '')) {
      errorNotification('コードに半角、全角スペースは入力できません。');
      return;
    }

    if (!confirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    try {
      const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
      const response = await createOrUpdateBusinessCategory(
        companyCode, false, values.getRawData(), isEdit,
      );
      if (!response.errors) {
        successNotification(isEdit ? '更新しました。' : '登録しました。');
        history.push('/businessCategories');
      }
    } catch (error) {
      setConfirmModalOpen(false);
      if (error.response && error.response.data && error.response.data.errors) {
        const listErr = error.response.data.errors;
        let stringErr = '';
        listErr.map((element: any) => {
          stringErr += `${element.defaultMessage} \n`;
          return stringErr;
        });
        errorNotification(stringErr);
      } else {
        errorNotification('サーバー側でエラーが発生しました。');
        throw error;
      }
    }
  };

  const formik = useFormik({
    initialValues: BusinessCategoryDomain.generateInitial(),
    validationSchema: false,
    onSubmit,
  });

  useEffect(() => {
    if (businessCategoryId) {
      getBusinessCategoryById(businessCategoryId).then((response: any) => {
        if (response) {
          formik.setValues(new BusinessCategoryDomain(response));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCategoryId]);


  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  return {
    formik,
    detailMode,
    setDetailMode,
    confirmModalOpen,
    closeConfirmModal,
    toastModalOpen,
    setToastModalOpen,
  };
};

export default {
  useBusinessCategoryAddForm,
};
