import moment from 'moment';
import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';

interface Area {
  areaId: string;
  areaCode: string;
  areaName: string;
}

export const getList = async (): Promise<Array<Area>> => {
  // TODO: companyCode取得処理はどこかでラップしたほうがよいかも
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  // TODO 仮リクエスト
  const params = {
  };
  const response = await ApiClient.get(`/v1/areas/${companyCode}`, params);
  return response.data;
};

export default getList;

export const getAreaByAreaId = async (areaId: string): Promise<any> => {
  const params = {
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.get(`/v1/areas/${companyCode}/${areaId}`, params);
  return response.data;
};

export const createOrUpdateArea = async (companyCode: string, isAccepted: boolean,
  data: any, isEdit: boolean) => {
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    createUser: staffName,
    updateUser: staffName,
    areaCode: data.areaCode.trim(),
    areaName: data.areaName.trim(),
    areaId: data.areaId,
  };
  const query = {
    isAccepted,
  };
  const response = await ApiClient.post(`/v1/areas/${companyCode}`, query, params);
  return response.data;
};

export const deleteById = async (areaId: string): Promise<any> => {
  const params = {
  };
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const response = await ApiClient.delete(`/v1/areas/${companyCode}/${areaId}`, params);
  return response.data;
};

export const downloadCSV = async (
  companyCode: string,
): Promise<void> => {
  const params = {};
  await ApiClient.downloadCsv(`/v1/area/outputCsv/${companyCode}`, params, getDownloadCsvFileName('エリア情報_'));
};
const getDownloadCsvFileName = (prefix: string) => `${prefix}${moment().format('YYYYMMDDHHmmss')}.csv`;

/**
 * CSVファイルインポート
 */
export const importFileCSV = async (uploadFile: File): Promise<AxiosResponse> => {
  const companyCode = sessionStorage.getItem('loginUser.companyCode') || '';
  const staffName = sessionStorage.getItem('loginUser.staffName') || '';
  const params = {
    formatType: 0,
    createUser: staffName,
    updateUser: staffName,
  };
  const response = await ApiClient.postFile(`/v1/area/csv/import/${companyCode}`, params, 'uploadFile', uploadFile);
  return response;
};

export type Error = {
  error: string;
  defaultMessage: string;
}
