/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/** @jsx jsx */
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { Column } from "react-table";
import FormContents from "components/atoms/Form/FormContents";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";

import DataTable from "components/organismos/DataTable/DataTable";
import IconLabelButton from "components/molecules/IconLabelButton";
import VerticalLabelSelectForm from "components/molecules/VerticalLabelSelectForm";
import FlexBox from "components/atoms/FlexBox";
// import SelectForm from 'components/molecules/SelectForm';

import ShiftPatternDomain from "domain/master/attend/shiftPattern";
import { useOrgTreesOptionsShopV1, useOrgNameTreesOptionsAll } from "hooks/useOrgTreesOptions";
import moment from "moment";
import { deleteById } from "api/shiftPattern";
import useToastNotification from "hooks/useToastNotification";
import { SubActionButton, SecondaryButton } from "components/atoms/Button";

import FlexBoxItem from "components/atoms/FlexBoxItem";
import Select from "components/atoms/Select";
import BodyText from "components/atoms/BodyText";
import {
  useShiftPatternList,
  useWorkTypeAll,
  findMinimumHour,
  findMaximunHour,
  useBreakList,
  findBreakShift,
} from "./hooks";
import productColor from "components/styles";
import { useHistory } from "react-router-dom";
import Modal from "components/molecules/Modal";
import BlockUI from "components/molecules/BlockUi";
import FormTitle from "components/atoms/Form/FormTitle";
import SelectForm from "components/molecules/SelectForm";
import { functionCode } from "const";

const styles = {
  editBtn: css`
    background-color: ${productColor.editAction};
    &:hover,
    &:focus {
      background-color: ${productColor.editAction};
    }
  `,
  deleteBtn: css`
    padding: 0;
    text-align: center;
    height: 24px;
    border-radius: 3px;
    color: white;
    margin-left: 4px;
    line-height: 0;
    font-size: 12px;
    &:hover,
    &:focus {
      opacity: 0.9;
      cursor: pointer;
    }
    background-color: ${productColor.deleteAction};
    &:hover,
    &:focus {
      background-color: ${productColor.deleteAction};
    }
  `,
  dataTable: css`
    overflow-x: auto;
    whitespace: nowrap;
  `,
  filterSearch: css({
    marginLeft: "37px",
    marginBottom: "10px",
  }),
};

const ShiftPatternLayoutList: React.FC = () => {
  const history = useHistory();

  const { shiftBreakList } = useBreakList();
  const orgCodeSlected = sessionStorage.getItem("shiftPatternOrgCode")
    ? sessionStorage.getItem("shiftPatternOrgCode")
    : sessionStorage.getItem("loginUser.orgCode") || "all";
  //  const employmentIdSelected = sessionStorage.getItem("employmentIdSelected")
  //    ? sessionStorage.getItem("employmentIdSelected")
  //    : String("all");
  const [orgCode, setorgCode] = useState(orgCodeSlected);
  //  const [employmentId, setEmploymentId] = useState(employmentIdSelected);
  sessionStorage.setItem("shiftPatternOrgCode", String(orgCode));
  //  sessionStorage.setItem("employmentIdSelected", String(employmentId));

  const { shiftPatternList, setShiftPatternList, isLoading, setIsLoading, detailRole } = useShiftPatternList(
    String(orgCode),
    //    String(employmentId)
  );

  const orgTreesOptions = useOrgTreesOptionsShopV1(true, functionCode.masterShiftPattern, undefined, undefined);

  const { shiftPatternWorkType, getName } = useWorkTypeAll();
  const orgNameTreesOptions = useOrgNameTreesOptionsAll();
  const closeConfirmModal = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const [valueShiftPatternId, setValue] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { successNotification, errorNotification } = useToastNotification();

  const openConfirmModal = useCallback((item: ShiftPatternDomain) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setValue(item.shiftPatternId);
    setConfirmModalOpen(true);
  }, []);

  const deleteShiftPatternbyID = useCallback(() => {
    setIsLoading(true);
    deleteById(valueShiftPatternId)
      .then((response: any) => {
        setShiftPatternList(
          shiftPatternList.filter((shiftPattern) => shiftPattern.shiftPatternId !== valueShiftPatternId),
        );
        setConfirmModalOpen(false);
        successNotification("削除しました。");
        setIsLoading(false);
      })
      .catch((error) => {
        setConfirmModalOpen(false);
        setIsLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          let msgError = "";
          error.response.data.errors.map((item: { defaultMessage: string }) => {
            msgError += `${item.defaultMessage} </br>`;
            return msgError;
          });
          errorNotification(msgError);
        } else {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      });
  }, [
    errorNotification,
    setShiftPatternList,
    shiftPatternList,
    successNotification,
    valueShiftPatternId,
    setIsLoading,
  ]);

  const columns: Array<Column<ShiftPatternDomain>> = useMemo(
    () => [
      {
        Header: () => null,
        disableSortBy: true,
        id: "actionButton",
        Cell: (cell: {
          row: { isExpanded: boolean; original: ShiftPatternDomain };
          data: Array<ShiftPatternDomain>;
        }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SubActionButton
              action={0}
              text={detailRole.editable === 1 ? `編集` : `参照`}
              onClick={() => {
                history.push(`/masterShiftPattern/edit/${cell.row.original.shiftPatternId}`);
              }}
              width="36px"
            />
            {detailRole.editable === 1 ? (
              <React.Fragment>
                {cell.row.original.orgCode !== "all" || detailRole.accessRange === 3 ? (
                  <SecondaryButton
                    onClick={() => {
                      openConfirmModal(cell.row.original);
                    }}
                    minWidth="36px"
                    customStyle={styles.deleteBtn}
                    text="削除"
                  />
                ) : null}
                <SubActionButton
                  action={2}
                  text="コピー"
                  onClick={() => {
                    history.push(`/masterShiftPattern/copy/${cell.row.original.shiftPatternId}`);
                  }}
                  width="48px"
                  marginLeft="4px"
                />
              </React.Fragment>
            ) : null}
          </div>
        ),
      },
      {
        Header: "組織名",
        accessor: "orgCode",
        Cell: (cell: {
          row: { isExpanded: boolean; original: ShiftPatternDomain };
          data: Array<ShiftPatternDomain>;
        }) => {
          let label = "";
          if (cell.row.original.orgCode === "all") {
            return "全組織名";
          }
          // eslint-disable-next-line array-callback-return
          orgNameTreesOptions.filter((response) => {
            if (response.value === cell.row.original.orgCode) {
              label = `${response.value} ${response.label}`;
            }
          });
          return label;
        },
      },
      {
        Header: "パターンコード",
        accessor: "shiftPatternCode",
      },
      {
        Header: "パターン名",
        accessor: "shiftPatternName",
      },
      {
        Header: "出勤時間",
        accessor: "endTime",
        Cell: (cell: {
          row: { isExpanded: boolean; original: ShiftPatternDomain };
          data: Array<ShiftPatternDomain>;
        }) => {
          const text = findMinimumHour(cell.row.original.attendShiftPatternDetails)[1] ? "(翌)" : "";
          const time = `${moment(findMinimumHour(cell.row.original.attendShiftPatternDetails)[0], "HH:mm:ss").format(
            "HH:mm",
          )}`;
          return `${time} ${text}`;
        },
      },
      {
        Header: "退勤時間",
        accessor: "startTime",
        Cell: (cell: {
          row: { isExpanded: boolean; original: ShiftPatternDomain };
          data: Array<ShiftPatternDomain>;
        }) => {
          const text = findMaximunHour(cell.row.original.attendShiftPatternDetails)[1] ? "(翌)" : "";
          const time = `${moment(findMaximunHour(cell.row.original.attendShiftPatternDetails)[0], "HH:mm:ss").format(
            "HH:mm",
          )}`;
          return `${time} ${text}`;
        },
      },
      {
        Header: "休憩時間",
        accessor: "free",
        Cell: (cell: {
          row: { isExpanded: boolean; original: ShiftPatternDomain };
          data: Array<ShiftPatternDomain>;
        }) => {
          let breakTimeText = "";
          if (cell.row.original.attendShiftPatternDetails) {
            const listBreak = findBreakShift(cell.row.original.attendShiftPatternDetails, shiftBreakList);
            if (listBreak.length) {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < listBreak.length; index++) {
                const { isStartTimeNextDay, startTime, endTime, isEndTimeNextDay } = listBreak[index];
                const valStartTime = moment(startTime, "HH:mm:ss").format("HH:mm");
                const valIsStartTimeNextDay = isStartTimeNextDay ? " (翌)" : "";
                const valEndTime = moment(endTime, "HH:mm:ss").format("HH:mm");
                const valIsEndTimeNextDay = isEndTimeNextDay ? " (翌)" : "";

                if (index === 0) {
                  breakTimeText = `${valStartTime + valIsStartTimeNextDay} ～ ${valEndTime}${valIsEndTimeNextDay}`;
                }

                if (index !== 0) {
                  breakTimeText += `<br> ${valStartTime +
                    valIsStartTimeNextDay} ～ ${valEndTime}${valIsEndTimeNextDay}`;
                }
              }
            }
          }
          return <div dangerouslySetInnerHTML={{ __html: breakTimeText }} />;
        },
      },
    ],
    [getName, openConfirmModal, orgNameTreesOptions, shiftBreakList, history, detailRole],
  );

  // useEffect(() => {
  //   if(orgTreesOptions) {
  //     orgTreesOptions[0] = {
  //       value: "all",
  //       label: "-"
  //     }
  //   }
  // }, [orgTreesOptions])

  return (
    <BlockUI blocking={isLoading}>
      <FormContents>
        <FormTitle bold={true} title="シフトパターンマスタ" />
        <FlexBox
          customStyle={css({
            padding: "8px",
            marginLeft: "25px",
          })}
        >
          <FlexBoxItem basis="100px">
            <BodyText>組織名</BodyText>
          </FlexBoxItem>
          <FlexBoxItem basis="400px">
            <SelectForm
              name="orgCode"
              value={String(orgCode)}
              setValue={(v) => {
                setorgCode(v);
              }}
              options={orgTreesOptions}
              width="800px"
              label=""
            />
          </FlexBoxItem>
        </FlexBox>
        {detailRole.editable === 1 && (
          <FormSubmitArea customStyle={css({ margin: "8px 0 0 25px" })}>
            <IconLabelButton
              onClick={() => history.push("/masterShiftPattern/add")}
              iconType="addCircle"
              text="追加"
              width="auto"
            />
          </FormSubmitArea>
        )}
        <div
          css={css`
            table {
              table-layout: auto;
            }
            table th:nth-of-type(1) {
              width: 140px;
            }
            @media only screen and (max-width: 1200px) {
              table th:nth-of-type(1) {
                width: 190px;
              }
            }
            table th:nth-of-type(2) {
              width: calc(100% - 700px);
              text-align: left;
            }
            table th:nth-of-type(3) {
              width: 200px;
              text-align: left;
            }
            table th:nth-of-type(4) {
              width: 200px;
              text-align: left;
            }
          `}
        >
          <div className="table-bordered" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <DataTable
              columns={columns}
              data={shiftPatternList}
              isGlobalFilter={true}
              globalSearchStyle={styles.filterSearch}
              // isHiddenPagination={true}
            />
          </div>
        </div>
        <Modal
          open={confirmModalOpen}
          closeHandler={closeConfirmModal}
          title="シフトパターンマスタの削除"
          submitText="削除する"
          note="シフトパターンマスタの削除をします。よろしいですか？"
          onSubmit={deleteShiftPatternbyID}
          typeSubmit="reject"
        />
      </FormContents>
    </BlockUI>
  );
};

export default ShiftPatternLayoutList;
