/** @jsx jsx */
import React, {
  useCallback, ComponentProps, ChangeEvent, useState, useRef
} from 'react';
import { css, jsx, SerializedStyles } from '@emotion/core';

import PrimaryButton from 'components/atoms/Button';
import FileDnDLayer from 'components/molecules/FileDnDLayer';
import SelectFileButton from 'components/atoms/Button/SelectFileButton';
import Icon from 'components/atoms/Icon';
import useToastNotification from 'hooks/useToastNotification';

const { errorNotification } = useToastNotification();

type FileSelectBoxProps = {
  setFile: (file: File) => void,
  value?: string,
  accept?: string,
  fullWidth?: boolean,
  backgroundColor? : string,
  customStyle?: SerializedStyles,
  customStyleContainer?: SerializedStyles,
  hidden?: boolean,
  onClick?: () => void
} & Omit<ComponentProps<typeof PrimaryButton>, 'onClick'> // onClickを除いたProps

const styles = {
  button: css({
    backgroundColor: '#F8F8F8',
    display:'flex',
    alignItems:'center',
    borderRadius: '2px',
    boxSizing: 'border-box',
    justifyContent:'center',
    lineHeight:'24px',
  }),
  container: css({
    width: '100%'
  })
}

/**
 * ファイル選択/ドラッグアンドドロップを受け入れるボタン
 */
const FileSelectBox: React.FC<FileSelectBoxProps> = ({
  setFile, text, disabled, ghost, value = null, accept = null, fullWidth = false, backgroundColor = '', customStyle = css({}), customStyleContainer = css({}), hidden, onClick
}) => {
  // ボタンクリック時にファイル選択ダイアログを表示させる
  const fileInputRef = useRef(Object());

  
  const uploadFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);


  
  // ファイル選択後、stateにファイルを保存
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.item(0)) {
      return;
    }
    let regexValidate = accept?.split(',');
    console.log('type', event.target.files[0]);
    if (!regexValidate?.includes(event.target.files[0].type)) {
      errorNotification('アップロードしたファイルのフォーマットが間違いました。');
      return false;
    }
    setFile(event.target.files.item(0)!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFile, accept]);

  // DnDアクティブ状態
  const [isActive, setActive] = useState(true);

  return (
    <div css={css([{
      width: '100%', 
      display: hidden ? 'none' : 'flex',
      height: '100%',
      alignItems: 'center',
    }, customStyleContainer])}>
      <FileDnDLayer
        setFile={setFile}
        setActive={setActive}
        fullWidth={fullWidth}
        customStyle={styles.container}
      >
        <label
          htmlFor=""
        >
          <input
            style={{ display: 'none' }} 
            ref={fileInputRef} 
            type="file" 
            value={value || ''}
            onChange={(e) => onChange(e)} 
            accept={accept || ''}/>
  {/* 
          <input
            type="file"
            
            css={css({ display: 'none'})}
            ref={fileInputRef}
            value={value || ''}
            onChange={(e) => onChange(e)}
            accept={accept || ''}
          /> */}

          <div css={[styles.button, css({backgroundColor: backgroundColor}, customStyle)]}>
            <Icon type="uploadFile" color="#007BC3"/>
            <SelectFileButton  
              text={text}
              disabled={disabled || isActive}
              ghost={ghost}
              onClick={() => {
                if(onClick){
                  onClick();
                  return;
                }
                uploadFile();
              }}
              minWidth={""}
              fullwidth={fullWidth}
              customStyle={css({
                padding: '0 0 0 10px'
              })}
            />
          </div>
        </label>
      </FileDnDLayer>
    </div>
  );
};

export default FileSelectBox;
