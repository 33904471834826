import { useState, useEffect } from 'react';

import {
  getListClosingDate, getListEmployment, getTimeList,
  getOrganizationsTrees,
} from 'api/achievementConfirm';
import moment from 'moment';


export const useGetClosingList = (targetDateFrom: Date) => {
  const [closingDateList, setClosingDateList] = useState([]);
  useEffect(() => {
    const params = {
      targetDate: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
    };

    getListClosingDate(params).then((response:any) => {
      setClosingDateList(response.map((item: any) => ({
        value: String(item.closingDate),
        label: item.closingName,
      })));
    });
  }, [targetDateFrom]);
  return {
    closingDateList,
    setClosingDateList,
  };
};

export const useGetOrganizationsTrees = (targetDateFrom: Date, targetDateTo: Date, orgCode: String) => {
  const [orgCodeList, setOrgCodeList] = useState([]);
  useEffect(() => {
    const staffCode = sessionStorage.getItem('loginUser.staffCode') || '';

    const params = {
      orgCode,
      targetDayFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDayTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      staffCode,
    };

    getOrganizationsTrees(params).then((response:any) => {
      setOrgCodeList(response.map((item: any) => ({
        value: String(item.orgCode),
        label: `${item.orgCode} ${item.orgName}`,
      })));
    });
  }, [orgCode, targetDateFrom, targetDateTo]);
  return {
    orgCodeList,
    setOrgCodeList,
  };
};

export const useGetEmployments = (targetDateFrom: Date, targetDateTo: Date, selectedClosingDate: String) => {
  const [employmentList, setEmploymentList] = useState([]);
  useEffect(() => {
    const params = {
      targetDateFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDateTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      closingDate: selectedClosingDate,
    };

    getListEmployment(params).then((response:any) => {
      setEmploymentList([]);
      if (response.length) {
        setEmploymentList(response.length && response.map((item: any) => ({
          value: String(item.employmentId),
          label: `${item.employmentCode} ${item.employmentName}`,
        })));
      }
    });
  }, [selectedClosingDate, targetDateFrom, targetDateTo]);
  return {
    employmentList,
    setEmploymentList,
  };
};

export const eventScroll = (e: { target: any; }) => {
  const { target } = e;
  const contentRight = document.getElementById('bottomRightAreaTime');
  const headRight = document.getElementById('topRightAreaTime');
  const scrollBottom = document.getElementById('scrollBottom');
  if (contentRight && headRight && scrollBottom) {
    contentRight.scrollLeft = target.scrollLeft;
    headRight.scrollLeft = target.scrollLeft;
    scrollBottom.scrollLeft = target.scrollLeft;
  }
};

export const useGetTimeList = (setLoading: any, targetDateFrom: any, targetDateTo:any, selectedClosingDate:any, attendFlag: any, selectOrgCode: any, selectedEmploymentId: any) => {
  const [term, setTerm] = useState([]);
  const [business, setBusiness] = useState([]);
  const [attendHolidays, setAttendHolidays] = useState([]);
  const [subTotalTimeList, setSubTotalTimeList] = useState([]);
  const [subTotalDayList, setSubTotalDayList] = useState([]);
  const [holidaySubtotalItem, setHolidaySubtotalItem] = useState([]);

  useEffect(() => {
    const params = {
      targetDateFrom: moment(new Date(targetDateFrom)).format('YYYY-MM-DD'),
      targetDateTo: moment(new Date(targetDateTo)).format('YYYY-MM-DD'),
      orgCode: selectOrgCode,
      closingDate: selectedClosingDate,
      employmentId: selectedEmploymentId,
      attendFlag,
    };

    setLoading(true);
    getTimeList(params).then((response:any) => {
      setBusiness(response.business);
      console.log(response.business);
      setTerm(response.term);
      setAttendHolidays(response.attendHolidays);
      setSubTotalTimeList(response.attendStampSubTotalTime);
      setSubTotalDayList(response.attendStampSubTotalDay);
      setHolidaySubtotalItem(response.holidaySubtotalItem);
      setLoading(false);
    });
  }, [attendFlag, selectOrgCode, selectedClosingDate, selectedEmploymentId, setLoading, targetDateFrom, targetDateTo]);
  return {
    term,
    business,
    attendHolidays,
    subTotalTimeList,
    subTotalDayList,
    holidaySubtotalItem,
  };
};

export default {
  useGetEmployments,
  eventScroll,
  useGetOrganizationsTrees,
};
