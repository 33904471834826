import { useState, useCallback, useEffect } from 'react';
import { getListStaffV1 } from 'api/staff';
import StaffDomain from 'domain/staff';
import useGetDetailRole from 'hooks/useGetDetailRole';
import { functionCode } from 'const';
import ToastNotification from 'components/molecules/ToastNotification';
import useSaveStateStorage from 'hooks/useSaveFilterStorage';

const defaultStateStatus = [1, 3, 4];
export const useStaffList = () => {

  const [staffList, setStaffList] = useState<Array<StaffDomain>>([]);
  const [orgCode, setOrgCode] = useSaveStateStorage(sessionStorage.getItem("loginUser.orgCode") || "", "orgCode") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [executiveId, setExecutiveId] = useSaveStateStorage("", "executiveId") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [employmentId, setEmploymentId] = useSaveStateStorage("", "employmentId") as [string|null, React.Dispatch<React.SetStateAction<string|null>>];
  const [stateStatus, setStateStatus] = useSaveStateStorage(defaultStateStatus, "stateStatus") as [Array<number>|null, React.Dispatch<React.SetStateAction<Array<number>|null>>];
  const [underFlag, setUnderFlag] = useSaveStateStorage(0, "underFlag") as [number|null, React.Dispatch<React.SetStateAction<number|null>>];

  const [blocking, setBlocking] = useState(false);
  const { detailRole } = useGetDetailRole(setBlocking, functionCode.staffs);

  function renderArray(data:any) {
    let newStateStatus = data;
    if(!Array.isArray(data)){
      newStateStatus = eval(String(stateStatus));
    }
    return newStateStatus;
  }

  // Get list staff
  const fetchData = useCallback(async () => {
    if([orgCode, employmentId, executiveId, stateStatus].every(x=>x!==null)){
      setBlocking(true);
      if (stateStatus?.length === 0) {
        setBlocking(false);
        ToastNotification.error('状態を選択してください。');
        return;
      }
      const response = await getListStaffV1(
        orgCode || "",
        executiveId || "",
        employmentId || "",
        renderArray(stateStatus) || defaultStateStatus,
        functionCode.staffs,
        underFlag || undefined
      );
      setStaffList(response.map((result) => new StaffDomain(result)));
      setBlocking(false);
    }

  }, [employmentId, executiveId, orgCode, stateStatus, underFlag]);

  useEffect(() => {
    fetchData();
  }, [fetchData, underFlag]);

  return {
    staffList,
    fetchData,
    executiveId,
    setExecutiveId,
    employmentId,
    setEmploymentId,
    orgCode,
    setOrgCode,
    stateStatus,
    setStateStatus,
    blocking,
    detailRole,
    underFlag,
    setUnderFlag,
    defaultStateStatus
  };
};

export default useStaffList;
